import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';

import { get } from 'lodash';
import { Button, Space, Table } from 'antd';

import { ACCOUNTS, COMPONENT_MODES } from '~/constants/defaultValue';
import { EditableCell, EditableRow } from '~/components/WhAppointment/WhAppointmentsByBlockTable/components/EditableComponents';
import { floorFormatter } from '~/utils/helper';
import { MinusCircleOutlined, PlusCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';


const AccountingDetailsTablePartner = forwardRef(({
  dataSource,
  mode,
}, ref) => {
  const [clonedDataSource, setClonedDataSource] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (Array.isArray(dataSource)) {
      setClonedDataSource(dataSource?.map((item, index) => ({
        ...item,
        key: index,
      })))
      setCount(dataSource?.length);
    }
  }, [dataSource]);
  const columns = [
    {
      display: "editOnly",
      width: 200,
      height : 100,
      title: 'Diễn giải',
      dataIndex: 'content',
      key: 'content',
      render: (text, record, index) => text || "(Chưa nhập diễn giải)",
      editable: true,
      component: 'Input',
    },
    {
      // display: "editOnly",
      width: 250,
      title: 'TK Nợ',
      dataIndex: 'debitAccount',
      key: 'debitAccount',
      render: (text, record, index) => text ? `${text} - ${ACCOUNTS[text]}` : "(Chưa chọn tài khoản)",
      editable: true,
      component: 'Select',
      options: Object.keys(ACCOUNTS).map(key => ({ value: key, label: `${key} - ${ACCOUNTS[key]}` })),
    },
    {
      // display: "editOnly",
      title: 'TK Có',
      width: 250,
      dataIndex: 'creditAccount',
      key: 'creditAccount',
      render: (text, record, index) => text ? `${text} - ${ACCOUNTS[text]}` : "(Chưa chọn tài khoản)",
      editable: true,
      component: 'Select',
      options: Object.keys(ACCOUNTS).map(key => ({ value: key, label: `${key} - ${ACCOUNTS[key]}` })),
    },
    {
      title: 'Số tiền',
      align: 'right',
      width: 200,
      dataIndex: 'amountOfMoney',
      key: 'amountOfMoney',
      render: (text) => floorFormatter(text),
      editable: true,
      component: 'InputNumber',
    },
    {
        display: "editOnly",
        width: 200,
        title: 'Đối tượng',
        dataIndex: 'partnerId',
        key: 'partnerId',
        render: (text, record, index) => text || "(Chưa có mã nhân viên)",
        editable: true,
        component: 'Input',
      },
      {
        display: "editOnly",
        width: 200,
        title: 'Tên đối tượng',
        dataIndex: 'fullName',
        key: 'fullName',
        render: (text, record, index) => text || "(Chưa có Tên nhân viên)",
        editable: true,
        component: 'Input',
      } 
    
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const tableProps = {
    // override default components to editable components in edit mode
    // ...(mode === COMPONENT_MODES.EDIT && { components }),
    ...(true && { components }),

    // pass extra props to every single editable cell in edit mode
    // columns: mode === COMPONENT_MODES.EDIT ?
    columns: true ?
      columns.filter(col => !col.display || col.display !== "viewOnly")
        .map((col) => {
          if (!col.editable) {
            return col;
          }
          return {
            ...col,
            onCell: (record, rowIndex) => {
              let options = null;
              let optionsLoading = null;
              let triggerFetchingOptions = null;
              let availableTime = null;

              if (col.options) {
                options = col.options;
              }
              if (col.computedOptions) {
                options = get(record, col.computedOptions)
              }

              return {
                record,
                rowIndex,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,

                component: col.component,
                options,
                optionsLoading,
                triggerFetchingOptions,
                availableTime,
                required: col.required,

                handleSave,
              }
            },
          };
        }) : columns.filter(col => !col.display || col.display !== "editOnly"),

    // use cloned data source so that it can be submitted when complete
    dataSource: mode === COMPONENT_MODES.EDIT
      ? clonedDataSource
      : dataSource,
  }

  const handleAdd = () => {
    const newData = {
      key: count,
      content: null,
      debitAccount: null,
      creditAccount: null,
      amountOfMoney: 0,
    };
    setClonedDataSource([...clonedDataSource, newData]);
    setCount(count + 1);
  }

  const handleDelete = (index) => {
    const newData = clonedDataSource.filter((item, idx) => idx !== index);
    setClonedDataSource(newData);
  };

  const handleSave = (row, dataIndex) => {
    const newData = [...clonedDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    const computedRow = {
      ...row,
    }
    newData.splice(index, 1, { ...item, ...computedRow });
    setClonedDataSource(newData);
  };

  const renderSummary = pageData => {
    const totalAmountOfMoney = pageData.reduce((prev, curr) => {
      return prev + Number(get(curr, "amountOfMoney"));
    }, 0);

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell align="right" colSpan={mode === COMPONENT_MODES.EDIT ? 2 : 2}>
          <h5>Tổng cộng</h5>
        </Table.Summary.Cell>
        <Table.Summary.Cell align="left" colSpan={mode === COMPONENT_MODES.EDIT ? 4 : 4}>
          <h5>{floorFormatter(totalAmountOfMoney)}</h5>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }

  useImperativeHandle(ref, () => ({
    getAccountingDetailsData() {
      return clonedDataSource;
    }
  }));

  return (
    <Space direction='vertical'>
      <Button
        // disabled={form.getFi eldValue("services").length >= whServices.length}
        icon={<PlusCircleOutlined />}
        onClick={handleAdd}
      >
        Thêm dòng
      </Button>
      <Table
        {...tableProps}
        footer={null}
        pagination={false}
        scroll={{ x: '100%' }}
        // size="small"
        summary={renderSummary}
      />
    </Space>
  )
})

export default AccountingDetailsTablePartner;
