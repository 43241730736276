import { clone, forIn, get, groupBy, head, keys, omit } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import toastr from 'toastr';
import api from '~/api';
import { FORMAT_DATE_TIME, INSTANCE_STATUSES, TYPE_VOUCHER_WAREHOUSE, TYPE_VOUCHER_WAREHOUSE_VI, TYPE_WAREHOUSE, WAREHOUSE_STATUS_KEY } from '~/constants/defaultValue';
import {
  getSelectors,
  useBranchIdSessionStorage,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess,
  vietnamMoment
} from '~/hooks/utils';
import {
  createWarehouseVoucher,
  createWarehouseVoucherOfPartner,
  deleteWarehouseVoucher,
  employeeConfirmVoucherWarehouse,
  employeeRejectVoucherWarehouse,
  getWarehouseVoucher,
  getWarehouseVoucherOfPartner,
  getWarehouseVouchers,
  getWarehouseVouchersOfPartner,
  partnerConfirmVoucherWarehouse,
  partnerRejectVoucherWarehouse,
  resetActionWarehouseVoucherState,
  resetWarehouseVoucherState,
  supplierConfirmVoucherWarehouse,
  supplierRejectVoucherWarehouse,
  updateWarehouseVoucher
} from '~/redux/action';
import ErrorBase from '~/utils/ErrorBase';
import { getExistProp } from '~/utils/helper';
import * as ErrorHandle from './error';
import { haveEmptyQuantity } from './hookListProduct';
const getSelector = (key) => (state) => state.warehouseVoucher[key];
const pagingSelector = getSelector('paging');
export const useWarehouseVoucherPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadingGetWarehouseVoucherSelector = getSelector('isGetWarehouseVoucherLoading');
const getWarehouseVouchersFailedSelector = getSelector('getWarehouseVouchersFailed');
const getWarehouseVoucherFailedSelector = getSelector('getWarehouseVoucherFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const warehouseVouchersSelector = getSelector('warehouseVouchers');
const warehouseVoucherSelector = getSelector('warehouseVoucher');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const partnerCreateSuccessSelector = getSelector('partnerCreateSuccess');
const partnerCreateFailedSelector = getSelector('partnerCreateFailed');

const partnerConfirmSuccessSelector = getSelector('partnerConfirmSuccess');
const partnerConfirmFailedSelector = getSelector('partnerConfirmFailed');
const partnerRejectSuccessSelector = getSelector('partnerRejectSuccess');
const partnerRejectFailedSelector = getSelector('partnerRejectFailed');

const employeeConfirmSuccessSelector = getSelector('employeeConfirmSuccess');
const employeeConfirmFailedSelector = getSelector('employeeConfirmFailed');
const employeeRejectSuccessSelector = getSelector('employeeRejectSuccess');
const employeeRejectFailedSelector = getSelector('employeeRejectFailed');

const supplierConfirmSuccessSelector = getSelector('supplierConfirmSuccess');
const supplierConfirmFailedSelector = getSelector('supplierConfirmFailed');
const supplierRejectSuccessSelector = getSelector('supplierRejectSuccess');
const supplierRejectFailedSelector = getSelector('supplierRejectFailed');

export const useWarehouseVoucherQueryParams = (whPartnerId) => {
  const query = useQueryParams();
  const [branchId] = useBranchIdSessionStorage();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const codeCommerPurchVoucher = query.get('codeCommerPurchVoucher')
  const User = query.get('User')
  const client = query.get('client')
  const note = query.get('note')
  const typeVoucher = query.get('typeVoucher')
  const partnerConfirmWarehouseVoucherSuccess = useSelector(partnerConfirmSuccessSelector);
  const partnerCreateWarehouseVoucherSuccess = useSelector(partnerCreateSuccessSelector);
  const createWarehouseVoucherSuccess = useSelector(createSuccessSelector);
  const partnerRejectWarehouseVoucherSuccess = useSelector(partnerRejectSuccessSelector);
  const employeeConfirmWarehouseVoucherSuccess = useSelector(employeeConfirmSuccessSelector);
  const employeeRejectWarehouseVoucherSuccess = useSelector(employeeRejectSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      // keyword,
      codeCommerPurchVoucher,
      User,
      client,
      note,
      typeVoucher,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    codeCommerPurchVoucher,
    User,
    client,
    note,
    typeVoucher,
    whPartnerId,
    partnerConfirmWarehouseVoucherSuccess,
partnerRejectWarehouseVoucherSuccess,
employeeConfirmWarehouseVoucherSuccess,
employeeRejectWarehouseVoucherSuccess,
partnerCreateWarehouseVoucherSuccess,
createWarehouseVoucherSuccess,
    branchId,
  ]);
};

export const useUpdateWarehouseVoucherParams = (query, listOptionSearch) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword])
  const onParamChange = (param) => {
    const groupByKey = groupBy(listOptionSearch, (e) => get(e, 'value'))
    forIn(query, (values, key, obj) => {
      if (groupByKey[key] && (keys(param)?.some(e => groupByKey[e]))) {
        obj[key] = null
      }
    })
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: path,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};
export const useWarehouseVouchers = (query) => {
  return useFetchByParam({
    action: getWarehouseVouchers,
    loadingSelector,
    dataSelector: warehouseVouchersSelector,
    failedSelector: getWarehouseVouchersFailedSelector,
    param: query
  });
};
export const useWarehouseVouchersOfPartner = (query) => {
  return useFetchByParam({
    action: getWarehouseVouchersOfPartner,
    loadingSelector,
    dataSelector: warehouseVouchersSelector,
    failedSelector: getWarehouseVouchersFailedSelector,
    param: query
  });
};
export const useWarehouseVoucher = (id) => {
  return useFetchByParam({
    action: getWarehouseVoucher,
    loadingSelector: loadingGetWarehouseVoucherSelector,
    dataSelector: warehouseVoucherSelector,
    failedSelector: getWarehouseVoucherFailedSelector,
    param: id
  });
};
export const useWarehouseVoucherOfPartner = (id) => {
  return useFetchByParam({
    action: getWarehouseVoucherOfPartner,
    loadingSelector: loadingGetWarehouseVoucherSelector,
    dataSelector: warehouseVoucherSelector,
    failedSelector: getWarehouseVoucherFailedSelector,
    param: id
  });
};
export const useCreateWarehouseVoucher = (callback, typeVoucher) => {
  useSuccess(
    createSuccessSelector,
    `Tạo ${TYPE_VOUCHER_WAREHOUSE_VI[typeVoucher]} thành công`,
    callback
  );
  useFailed(createFailedSelector, `Tạo ${TYPE_VOUCHER_WAREHOUSE_VI[typeVoucher]} thất bại`);
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWarehouseVoucher
  });
};

// CREATE BY PARTNER
export const useCreateWarehouseVoucherOfPartner = (callback, typeVoucher) => {
  useSuccess(
    partnerCreateSuccessSelector,
    `Tạo ${TYPE_VOUCHER_WAREHOUSE_VI[typeVoucher]} thành công`,
    callback
  );
  useFailed(partnerCreateFailedSelector, `Tạo ${TYPE_VOUCHER_WAREHOUSE_VI[typeVoucher]} thất bại`);
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWarehouseVoucherOfPartner
  });
};
export const useUpdateWarehouseVoucher = (callback, typeVoucher) => {
  useSuccess(
    updateSuccessSelector,
    `Cập nhât ${TYPE_VOUCHER_WAREHOUSE[typeVoucher]} thành công`,
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWarehouseVoucher
  });
};
export const useDeleteWarehouseVoucher = (callback, typeVoucher) => {
  useSuccess(
    deleteSuccessSelector,
    `Xoá ${TYPE_VOUCHER_WAREHOUSE[typeVoucher]} thành công`,
    callback
  );
  useFailed(deleteFailedSelector, `Xoá ${TYPE_VOUCHER_WAREHOUSE[typeVoucher]} thất bại`);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteWarehouseVoucher
  });
};

// CONFIRM, REJECT FROM PARTNER
export const usePartnerConfirmVoucherWarehouse = (callback) => {
  useSuccess(partnerConfirmSuccessSelector, 'Xác nhận phiếu thành công', callback);
  useFailed(partnerConfirmFailedSelector, 'Xác nhận phiếu thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: partnerConfirmVoucherWarehouse
  });
};

export const usePartnerRejectVoucherWarehouse = (callback) => {
  useSuccess(partnerRejectSuccessSelector, 'Từ chối phiếu thành công', callback);
  useFailed(partnerRejectFailedSelector, 'Từ chối phiếu thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: partnerRejectVoucherWarehouse
  });
};
//

// CONFIRM, REJECT FROM EMPLOYEE
export const useEmployeeConfirmVoucherWarehouse = (callback) => {
  useSuccess(employeeConfirmSuccessSelector, 'Xác nhận phiếu thành công', callback);
  useFailed(employeeConfirmFailedSelector, 'Xác nhận phiếu thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: employeeConfirmVoucherWarehouse
  });
};

export const useEmployeeRejectVoucherWarehouse = (callback) => {
  useSuccess(employeeRejectSuccessSelector, 'Từ chối phiếu thành công', callback);
  useFailed(employeeRejectFailedSelector, 'Từ chối phiếu thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: employeeRejectVoucherWarehouse
  });
};
//

// CONFIRM, REJECT FROM SUPPLIER
export const useSupplierConfirmVoucherWarehouse = (callback) => {
  useSuccess(supplierConfirmSuccessSelector, 'Xác nhận phiếu thành công', callback);
  useFailed(supplierConfirmFailedSelector, 'Xác nhận phiếu thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: supplierConfirmVoucherWarehouse
  });
};

export const useSupplierRejectVoucherWarehouse = (callback) => {
  useSuccess(supplierRejectSuccessSelector, 'Từ chối phiếu thành công', callback);
  useFailed(supplierRejectFailedSelector, 'Từ chối phiếu thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: supplierRejectVoucherWarehouse
  });
};
//

export const useResetWarehouseVoucher = () => {
  useResetState(resetWarehouseVoucherState);
};

export const useResetActionWarehouseVoucher = () => {
  useResetState(resetActionWarehouseVoucherState);
};

export const getListPartners = async (query, setData) => {
  let listPartners = await api.whPartner.getWhPartners(query)
  const partners = get(listPartners, "docs").map(item => {
    return {
      ...item,
      label: `${get(item, "partnerCode")} - ${get(item, "name")}`
    }
  });
  setData(partners)
}
/**
 * mode = EDIT will call this function
 * 
 * 
 * @param {String} mode mode action of voucher
 * @param {Function} setValue set value for form
 * @return {Number}  is the new Code voucher
 */
export const useGetNewCodeVoucherWarehouse = (setValue, condition) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchLastVoucher = async () => {
      try {
        setLoading(true)
        const res = await api.wareHouseVoucher.getLastSeq();
        console.log(res,'res');
        const newCode = get(res, 'data.checkNumber', 10000);
        setData((newCode || 10000) + 1)
        setLoading(false)
        if (setValue && typeof setValue === 'function') {
          setValue((newCode || 10000) + 1)
        }
      } catch (error) {
        setLoading(false)
        toastr.error("Lấy dữ liệu mã thất bại")
      }
    }
    condition && fetchLastVoucher()
  }, [])
  return [data, loading]
}

export const checkIsMomentAndGetData = (time) => {
  if (!time) return null
  return moment.isMoment(time) ? time : moment(time).format(FORMAT_DATE_TIME.YYYYMMDDHHmmss)
}

/**
 * 
 * @param {Array} listProduct 
 * @returns {ErrorBase}
 */
export const validateFieldsListProduct = (listProduct) => {
  if (!listProduct?.length) return new ErrorHandle.CannotEmptyListProduct();
  let isError = false;

  // Check Empty payment Date
  listProduct?.some((product) => {
    // Business logic only type product is WORLDHEALTH have validation r_date and p_date
    if (get(product, 'typeMedicalDeviceInventory') === TYPE_WAREHOUSE.WORLDHEALTH) {
      if (!get(product, 'r_date') || !get(product, 'p_date')) {
        isError = true;
        return true
      }
    }
  })
  if (isError) return new ErrorHandle.CannotEmptyPaymentDate();
  return { msg: "", status: false }
}
export const validateFieldsListProductConfirmVoucher = (listProduct,id) => {
  if (!listProduct?.length && !id) return new ErrorHandle.CannotEmptyListProduct();
  return { msg: "", status: false }
}

export const checkErrorProductInVoucherWarehouse = (product) => {
  let error = { status: false, msg: "" };
  if(!product) return error;
  const {action,status : statusProduct,whPartnerId,supplierId} = product;
  switch (true) {
    case action === INSTANCE_STATUSES.INACTIVE:
      return new ErrorHandle.productIsDisabled();
    case ![WAREHOUSE_STATUS_KEY.ASSIGNED,WAREHOUSE_STATUS_KEY.READY].includes(statusProduct):
      return new ErrorHandle.productIsDelivered();
    case whPartnerId === supplierId:
      return new ErrorHandle.partnerIsOwner();
    default:
      return error;
  }
}

export const checkErrorInProductConfirm = (product) => {
  let error = { status: false, msg: "" };
  const {supplierIdSelected,supplierId} = product;

  switch (true) {
    case !!supplierIdSelected && (String(supplierIdSelected) !== String(supplierId)):
      return new ErrorHandle.NotSameSupplier();
    default:
      return error
  }
}

/**
 * 
 * @param {Array} data 
 * @returns {Array} 
 */
export const convertDateToSubmit = (data) => {
  return data?.map(item => {
    if (get(item, 'typeMedicalDeviceInventory') === TYPE_WAREHOUSE.CLINIC) return item
    // Business logic only type product is WORLDHEALTH have validation r_date and p_date
    if (get(item, 'typeMedicalDeviceInventory') === TYPE_WAREHOUSE.WORLDHEALTH) {
      return {
        ...item,
        r_date: get(item, 'r_date') ? moment(get(item, 'r_date')).format(FORMAT_DATE_TIME.YYYYMMDDHHmmss) : null,
        p_date: get(item, 'p_date') ? moment(get(item, 'p_date')).format(FORMAT_DATE_TIME.YYYYMMDDHHmmss) : null,
      }
    }
  })
}

/**
 * 
 * @param {Array} data 
 * @param {Date} date 
 * @returns 
 */
export const addEndDateForData = (data, date) => {
  return data?.map(item => {
    if (get(item, 'typeMedicalDeviceInventory') === TYPE_WAREHOUSE.CLINIC) return item
    // Business logic only type product is WORLDHEALTH have validation r_date and p_date
    if (get(item, 'typeMedicalDeviceInventory') === TYPE_WAREHOUSE.WORLDHEALTH) {
      return {
        ...item,
        p_date: moment.isMoment(date) ? moment(date).format(FORMAT_DATE_TIME.YYYYMMDDHHmmss) : null,
      }
    }
  })
}




  export const useSelectBatch = () => {
    const [visibleSelectBatch, setVisibleSelectBatch] = useState(false);
    const [dataSelectBatch,setDataSelectBatch] = useState(null);
    const onOpenModalSelectBatch = (data) => {
      setVisibleSelectBatch(true);
      setDataSelectBatch(data);
    }
    const onCloseModalSelectBatch = () => {
      setVisibleSelectBatch(false);
      setDataSelectBatch(null);
    }
    return {
      onOpenModalSelectBatch,
      onCloseModalSelectBatch,
      visibleSelectBatch,
      dataSelectBatch,
      setDataSelectBatch
    }
  }

  export const useModalVoucherWarehouse = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [id, setId] = useState(null);
    const handleOpenModal = (id) => {
      setIsOpenModal(true);
      if (id) {
        setId(id);
      }
    };
    const handleCloseModal = () => {
      setIsOpenModal(false);
      setId(null);
    };
    return {
      isOpenModal,
      id,
      handleOpenModal,
      handleCloseModal,
    }
  }

export const mergeSameProductAndMaterial = (list = []) => {
  if(!list?.length) return [];
  let listReturn = [];
  // Group By productId
  const groupByIdProduct = groupBy(list,e => get(e,'productId'));

  forIn(groupByIdProduct,(value) => {
      //Because Group By productId So can Get First Product to use
    let firstElement = head(value);

    // Collect all whAppointment 
    let whAppointments = value?.map(product => get(product,'whAppointment',{}));

      // If product is Material Will Sum all Quantity 
    if(get(firstElement,'medicalEquipmentType.type') === TYPE_WAREHOUSE.CLINIC){
      const quantity = value?.reduce((sum,curr) => sum + get(curr,'quantity',1),0);
      
      listReturn.push({...firstElement,whAppointments,quantity});
    }else{
      listReturn.push({...firstElement,whAppointments});
    }
  })
  return listReturn
}
