import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

//board
function* getSprints({payload: query}) {
    try {
        const data = yield call(Api.workSprint.getSprints, query);
        yield put({ type: Types.GET_BOARD_SPRINT_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: Types.GET_BOARD_SPRINT_FAILED, payload: error.message });
    }
}
function* getSprintById({payload}) {
    try {
        const response = yield call(Api.workSprint.getOne, payload);

        yield put({ type: Types.GET_BOARD_SPRINT_BY_ID_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: Types.GET_BOARD_SPRINT_BY_ID_FAILED, payload: error.message });
    }
}
function* deleteSprint({payload}) {
    try {
        const response = yield call(Api.workSprint.delete, payload);
        yield put({ type: Types.DELETE_BOARD_SPRINT_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: Types.DELETE_BOARD_SPRINT_FAILED, payload: error.message });
    }
}
function* createSprint(action) {
    try {
        const response = yield call(Api.workSprint.createSprint, action.payload);
        yield put({ type: Types.CREATE_BOARD_SPRINT_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: Types.CREATE_BOARD_SPRINT_FAILED, payload: error.message });
    }
}
function* updateSprint(action) {
    try {
        const response = yield call(Api.workSprint.updateSprint, action.payload);
        yield put({ type: Types.UPDATE_BOARD_SPRINT_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: Types.UPDATE_BOARD_SPRINT_FAILED, payload: error.message });
    }
}
export default function* workSprintTask() {
    //board
    yield takeLatest(Types.GET_BOARD_SPRINT_REQUEST, getSprints);
    yield takeLatest(Types.GET_BOARD_SPRINT_BY_ID_REQUEST, getSprintById);
    yield takeLatest(Types.CREATE_BOARD_SPRINT_REQUEST, createSprint);
    yield takeLatest(Types.UPDATE_BOARD_SPRINT_REQUEST, updateSprint);
    yield takeLatest(Types.DELETE_BOARD_SPRINT_REQUEST, deleteSprint);

}
