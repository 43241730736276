import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  useFailed, useFetchByParam, useQueryParams, useResetState, useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  getSystem, getSystems, resetSystemState,
  updateSystem
} from '~/redux/action';
import { getExistProp } from '~/utils/helper';

const getSelector = (key) => (state) => state.system[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useSystemPaging = () => useSelector(pagingSelector);

export const useSystemQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');


  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword]);
};

export const useSystems = (query) => {
  return useFetchByParam({
    action: getSystems,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};


export const useUpdateSystem = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật Phiên bản App thành công',
    callback
  );
  useFailed(updateFailedSelector, 'Cập nhật Phiên bản App thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateSystem
  });
};


export const useSystem = (id) => {
  return useFetchByParam({
    action: getSystem,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useUpdateSystemParams = (query) => {
  const history = useHistory();
  const {path} = useRouteMatch();
  const [keyword, setKeyword] = useState(query?.keyword);

  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: path,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};


export const useResetSystem = () => {
  useResetState(resetSystemState);
};
