import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getWhCustomersManagement({ payload: query }) {
  try {
    const data = yield call(Api.whCustomerManagement.getAll, query);
    yield put({ type: Types.GET_WH_CUSTOMERS_MANAGEMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_CUSTOMERS_MANAGEMENT_FAILED,
      payload: error.message
    });
  }
}

function* getWhCustomerManagementHistory({ payload: query }) {
  try {
    const data = yield call(Api.whCustomerManagement.getCustomerHistory, query);
    yield put({ type: Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_FAILED,
      payload: error.message
    });
  }
};

function* getRefWhCustomersManagement({ payload: query }) {
  try {
    const data = yield call(Api.whCustomerManagement.getRef, query);
    yield put({ type: Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_FAILED,
      payload: error
    });
  }
}

function* getWhCustomerManagement({ payload: id }) {
  try {
    const whCustomerManagement = yield call(Api.whCustomerManagement.getById, id);
    yield put({ type: Types.GET_WH_CUSTOMER_MANAGEMENT_SUCCESS, payload: whCustomerManagement });
  } catch (error) {
    yield put({ type: Types.GET_WH_CUSTOMER_MANAGEMENT_FAILED, payload: error.message });
  }
}

function* createWhCustomerManagement(action) {
  try {
    const data = yield call(Api.whCustomerManagement.create, action.payload);
    yield put({ type: Types.CREATE_WH_CUSTOMER_MANAGEMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_WH_CUSTOMER_MANAGEMENT_FAILED,
      payload: error.message
    });
  }
}

function* updateWhCustomerManagement(action) {
  try {
    const data = yield call(Api.whCustomerManagement.update, action.payload);
    yield put({ type: Types.UPDATE_WH_CUSTOMER_MANAGEMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_CUSTOMER_MANAGEMENT_FAILED,
      payload: error.message
    });
  }
}

function* updateNoteWhCustomerManagement(action) {
  try {
    const data = yield call(Api.whCustomerManagement.updateNote, action.payload);
    yield put({ type: Types.UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_FAILED,
      payload: error.message
    });
  }
}

function* deleteWhCustomerManagement({ payload }) {
  try {
    yield call(Api.whCustomerManagement.delete, payload);
    yield put({ type: Types.DELETE_WH_CUSTOMER_MANAGEMENT_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_CUSTOMER_MANAGEMENT_FAILED,
      payload: error.message
    });
  }
}

//Address
function* getWhCustomersManagementManyAddress({ payload: query }) {
  try {
    const data = yield call(Api.whCustomerManagement.getMeAddress, query);
    yield put({ type: Types.GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_FAILED,
      payload: error.message
    });
  }
};
function* getWhCustomersManagementAddress({ payload: query }) {
  try {
    const data = yield call(Api.whCustomerManagement.getByIdAddress, query);
    yield put({ type: Types.GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_FAILED,
      payload: error.message
    });
  }
};

function* updateWhCustomerManagementAddress(action) {
  try {
    const data = yield call(Api.whCustomerManagement.updateAddress, action.payload);
    yield put({ type: Types.UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_FAILED,
      payload: error
    });
  }
};

function* deleteWhCustomerManagementAddress({ payload }) {
  try {
    yield call(Api.whCustomerManagement.deleteAddress, payload);
    yield put({ type: Types.DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_FAILED,
      payload: error
    });
  }
};

function* resetPassword({ payload }) {
  try {
    const data = yield call(Api.whCustomerManagement.resetPassword, payload);
    yield put({ type: Types.RESET_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.RESET_PASSWORD_FAILED,
      payload: error.message
    });
  }
};

export default function* whCustomerManagement() {
  yield takeLatest(Types.GET_WH_CUSTOMERS_MANAGEMENT_REQUEST, getWhCustomersManagement);
  yield takeLatest(Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_REQUEST, getRefWhCustomersManagement);
  yield takeLatest(Types.GET_WH_CUSTOMER_MANAGEMENT_REQUEST, getWhCustomerManagement);
  yield takeLatest(Types.DELETE_WH_CUSTOMER_MANAGEMENT_REQUEST, deleteWhCustomerManagement);
  yield takeLatest(Types.CREATE_WH_CUSTOMER_MANAGEMENT_REQUEST, createWhCustomerManagement);
  yield takeLatest(Types.UPDATE_WH_CUSTOMER_MANAGEMENT_REQUEST, updateWhCustomerManagement);
  yield takeLatest(Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_REQUEST, getWhCustomerManagementHistory);

  yield takeLatest(Types.GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_REQUEST, getWhCustomersManagementManyAddress);
  yield takeLatest(Types.GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_REQUEST, getWhCustomersManagementAddress);
  yield takeLatest(Types.DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_REQUEST, deleteWhCustomerManagementAddress);
  yield takeLatest(Types.UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_REQUEST, updateWhCustomerManagementAddress);
  yield takeLatest(Types.UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_REQUEST, updateNoteWhCustomerManagement);
  yield takeLatest(Types.RESET_PASSWORD_REQUEST, resetPassword);
}
