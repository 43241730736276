import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DollarCircleFilled,
  GiftOutlined,
  PlusCircleFilled,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  // Switch,
  // Switch
} from 'antd';
import SkeletonInput from 'antd/lib/skeleton/Input';
import {  forIn, get, head, isNaN, keys, pick, transform } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import toastr from 'toastr';
import {
  ACTOR_REFERRAL,
  ACTOR_REFERRAL_VI,
  CONFIG_CAMPAIGN_CONDITION,
  CONFIG_CAMPAIGN_CONDITION_VI,
  CONFIG_CAMPAIGN_STATUS,
  CONFIG_CAMPAIGN_UNIT_CHANGE,
  CONFIG_CAMPAIGN_UNIT_UN_CHANGE,
  CONFIG_CAMPAIGN_UNIT_VI,
  MAX_PAGINATION_LIMIT,
  MAX_VALUE_CONFIG_CAMPAIGN,
  TYPE_OF_MONEY_REWARD_ITEM_VI,
  TYPE_OF_POINT_REWARD_ITEM_VI,
  TYPE_REWARD_ITEM_REFERRAL,
  TYPE_REWARD_ITEM_REFERRAL_VI
} from '~/constants/defaultValue';
import {  useActivityReferrals, useResetActivityReferral } from '~/hooks/activityReferral';
import {
  useConfigCampaign,
  useCreateConfigCampaign,
  useResetConfigCampaignAction,
} from '~/hooks/configCampaign';
import { useRankings } from '~/hooks/ranking';
import {  formatNumberThreeComma, useOptionsSelectAntd } from '~/hooks/utils';
import { floorFormatter } from '~/utils/helper';
import RepeatAction from '../component/RepeatAction';
import RepeatTime from '../component/RepeatTime';
import FormAddActivity from './FormAddActivity';
import './index.scss';
const optionsActor = [
  { label: ACTOR_REFERRAL_VI.user, value: ACTOR_REFERRAL.user },
  { label: ACTOR_REFERRAL_VI.partner, value: ACTOR_REFERRAL.partner }
];

const rules = (name) => [{ required: true, message: `Xin mời ${name}` }];
export default function ConfigCampaignForm({
  id,
  handleCloseModal,
  loadingRewardItem,
  rewardItems,
  updateConfigCampaign,
  triggerFetch
}) {
  
  const [form] = Form.useForm();
  // const history = useHistory()
  const optionsTypeCondition = useOptionsSelectAntd(
    CONFIG_CAMPAIGN_CONDITION_VI
  );

  const optionsUnit = useOptionsSelectAntd(CONFIG_CAMPAIGN_UNIT_VI);
  const handleClose = () => {
    handleCloseModal();
    form.resetFields();
  };
  const [types, setTypes] = useState(get(head(optionsActor), 'value'));
  const [isOpenAddActivity,setIsIsOpenAddActivity] = useState(false)
  const [reFetchActivity,setReFetchActivity] = useState(false)
  const [typeCondition, setTypeCondition] = useState(
    get(head(optionsTypeCondition), 'value')
  );
  // HOOK
  const [submitLoading, createConfigCampaign] =
    useCreateConfigCampaign(handleClose);

  const [ConfigCampaign, loading] = useConfigCampaign(id);
  //
  //HOOK GET ACTIVITY
  const query = useMemo(() => ({page : 1 ,limit : MAX_PAGINATION_LIMIT}),[reFetchActivity])
  const [activitis,isLoading] = useActivityReferrals(query)
  let CONFIG_CAMPAIGN_ACTIVITY_ALL = useMemo(() => {
   return transform(activitis,(result,cur) => {
      result[get(cur,'value','')] = pick(cur,['subCondition','typeCondition','value','vi','_id'])
     },{})
  },[activitis])
  let optionsActivityInit = [];
  for (const [key, value] of Object.entries(CONFIG_CAMPAIGN_ACTIVITY_ALL)) {
    optionsActivityInit.push({
      label: CONFIG_CAMPAIGN_ACTIVITY_ALL[key]?.vi,
      value: CONFIG_CAMPAIGN_ACTIVITY_ALL[key]?.value
    });
  }
  useResetActivityReferral()
  useResetConfigCampaignAction()
   //HOOK GET ACTIVITY
  //HOOK GET Ranking
  // const queryRanking = useMemo(() => ({page : 1 ,limit : MAX_PAGINATION_LIMIT}),[])
  const [rankings,isLoadingRanking] = useRankings(query)
  const optionsRankings = useMemo(() => rankings?.map(e => ({label : get(e,'name',''),value : get(e,'_id','')})),[rankings])
   //HOOK GET Ranking

  const [activity, setActivity] = useState(
    get(head(optionsActivityInit), 'value')
  );
  const onValuesChange = (value) => {
    const keyChange = keys(value)[0];
    const valueChange = value[keyChange];
    switch (keyChange) {
      case 'activity':
        setTypeCondition(CONFIG_CAMPAIGN_ACTIVITY_ALL[valueChange].typeCondition);
        form.setFieldsValue({ condition: null });
        form.setFieldsValue({
          condition: {
            type: CONFIG_CAMPAIGN_ACTIVITY_ALL[valueChange].typeCondition
          }
        });
        break;
        case 'rankings':
        if(valueChange?.some(e => e === 'all')){
          form.setFieldsValue({rankings : ['all']})
        }
          break
      default:
        break;
    }
  };
  const getDescriptionRange = (value) => {
    const min = form.getFieldValue(['condition', 'value', 'keyValue', 'min']);
    const max = form.getFieldValue(['condition', 'value', 'keyValue', 'max']);
    if (min || max) {
      if (max) {
        const result = `Từ ${floorFormatter(min)} ${CONFIG_CAMPAIGN_UNIT_VI[value]} Đến ${floorFormatter(max)} ${CONFIG_CAMPAIGN_UNIT_VI[value]}`;
        form.setFieldsValue({
          condition: {
            description: result
          }
        });
      } else {
        const result = `Lớn hơn ${floorFormatter(min)} ${CONFIG_CAMPAIGN_UNIT_VI[value]}`;
        form.setFieldsValue({ condition: { description: result } });
      }
    }
  };
  const getDescriptionWorth = (value) => {
    const min = form.getFieldValue(['condition', 'value', 'keyValue']);
    if (min) {
      const result = `${floorFormatter(min)} ${CONFIG_CAMPAIGN_UNIT_VI[value]}`;
      form.setFieldsValue({ condition: { description: result } });
    }
  };
  const handleChangeUnitRange = (value) => {
    getDescriptionRange(value);
  };
  const handleChangeUnitWorth = (value) => {
    getDescriptionWorth(value);
  };
  const onBlurValueRange = () => {
    const unit = form.getFieldValue(['condition', 'unit']);
    if (unit) {
      getDescriptionRange(unit);
    }
  };
  const onBlurValueWorth = () => {
    const unit = form.getFieldValue(['condition', 'unit']);
    if (unit) {
      getDescriptionWorth(unit);
    }
  };
  const handleSelectType = ({ target: { value } }) => {
    setTypeCondition(value);
    form.setFieldsValue({ condition: null });
    form.setFieldsValue({
      condition: {
        type: value
      }
    });
  };
  const onDropdownChange = async (open) => {
    try {
     if(open) setReFetchActivity(!reFetchActivity)
    } catch (error) {}
  };
  const handleOpenAddActivity = () => {
    setIsIsOpenAddActivity(true)
  }
  const handleCloseAddActivity = () => {
    setIsIsOpenAddActivity(false)
  }
  // const fetcherActivity = (text) => setOptionsActivity(optionsActivityInit.filter(option => (option.label).toLowerCase().includes(text.toLowerCase())))
  // const onSearchActivity = debounce(fetcherActivity,500)
  const onFinish = (values) => {
    if (
      form.getFieldValue(['condition', 'endDate']) && 
     ( form.getFieldValue(['condition', 'startDate']) >
      form.getFieldValue(['condition', 'endDate']))
    ) {
      return toastr.error(
        'Ngày kết thúc đang bé hơn ngày bắt đầu vui lòng kiểm tra lại'
      );
    }
    if (
      typeCondition === CONFIG_CAMPAIGN_CONDITION.RANGE &&
     ( get(values, 'condition.value.keyValue.min') >
     get(values, 'condition.value.keyValue.max'))
     && get(values, 'condition.value.keyValue.max')
    ) {
      return toastr.error(
        'Giá trị bắt đầu đang lớn hơn giá trị kết thúc vui lòng kiểm tra lại'
      );
    }
    let condition = {...values.condition};
    switch (get(values, 'condition.type', '')) {
      case CONFIG_CAMPAIGN_CONDITION.BOOLEAN:
        condition = {
          ...values.condition,
          value: { keyValue: true }
        };
        break;
      case CONFIG_CAMPAIGN_CONDITION.RANGE:
        condition = {
          ...values.condition,
          value: {
            keyValue: {
              min: CONFIG_CAMPAIGN_UNIT_CHANGE[get(values, 'condition.unit')](
                get(values, 'condition.value.keyValue.min')
              ),
              max:
                CONFIG_CAMPAIGN_UNIT_CHANGE[get(values, 'condition.unit')](
                  get(values, 'condition.value.keyValue.max')
                ) || MAX_VALUE_CONFIG_CAMPAIGN
            }
          }
        };
        break;
      case CONFIG_CAMPAIGN_CONDITION.WORTH:
        condition = {
          ...values.condition,
          value: {
            keyValue: CONFIG_CAMPAIGN_UNIT_CHANGE[
              get(values, 'condition.unit')
            ](get(values, 'condition.value.keyValue'))
          }
        };
        break;
      case CONFIG_CAMPAIGN_CONDITION.REPEAT_ACTION:
        condition = {
          ...values.condition,
          value : {...condition.value,isInfiniteCount : !get(condition,'value.isInfiniteCount') ? false : true}
        };
        break;
      default:
        break;
    }
    let subCondition ={}
    if(CONFIG_CAMPAIGN_ACTIVITY_ALL[activity].subCondition.length > 0){
      forIn(get(values,'condition.subCondition'), function(value, key) {
        const findOneCondition = get(CONFIG_CAMPAIGN_ACTIVITY_ALL,`${activity}.subCondition`,[]).find(e => get(e,'key') === key)
        subCondition[key] = {
          value,
          desc : get(findOneCondition,'desc','')
        }
      });
    }
    const submitData = {
      ...values,
      activityId : get(CONFIG_CAMPAIGN_ACTIVITY_ALL[activity],'_id'),
      status: CONFIG_CAMPAIGN_STATUS.ACTIVE,
      condition:{...condition,subCondition},
      date: moment().format('YYYY-MM-DD')
    };
    if (id) {
      updateConfigCampaign({ ...submitData, _id: id });
    } else {
      createConfigCampaign(submitData);
    }
  };
  useEffect(() => {
    form.resetFields();
    if (!!id && ConfigCampaign) {
      const { activity : activitys, actor, typeReward, condition, name , rankings } = ConfigCampaign;
      const {subCondition} = condition
      let newSubCondition = transform(subCondition, function(result, value, key) {
        result[key] = get(value,'value')
      }, {});
      const changeValueUnit = (value) =>
        get(condition, 'unit') &&
        CONFIG_CAMPAIGN_UNIT_UN_CHANGE[get(condition, 'unit')](value);
      const conditionRange = {
        ...condition,
        subCondition : newSubCondition,
        value: {
          keyValue: {
            min: changeValueUnit(get(condition, 'value.keyValue.min')),
            max:
              get(condition, 'value.keyValue.max') === MAX_VALUE_CONFIG_CAMPAIGN
                ? null
                : changeValueUnit(get(condition, 'value.keyValue.max'))
          }
        },
        startDate: moment(get(condition, 'startDate', null)),
        endDate: get(condition, 'endDate') ? moment(get(condition, 'endDate')) : null,
        description: get(condition, 'description', ''),
        unit: get(condition, 'unit', ''),
        type: get(condition, 'type', '')
      };
      const conditionBoolean = {
        ...condition,
        subCondition : newSubCondition,
        value: {
          keyValue: true
        },
        startDate: moment(get(condition, 'startDate', null)),
        endDate: get(condition, 'endDate') ? moment(get(condition, 'endDate')) : null,
        type: get(condition, 'type', ''),
        description: get(condition, 'description', '')
      };
      const conditionWorth = {
        ...condition,
        subCondition : newSubCondition,
        value: {
          keyValue: changeValueUnit(get(condition, 'value.keyValue'))
        },
        startDate: moment(get(condition, 'startDate', null)),
        endDate: get(condition, 'endDate') ? moment(get(condition, 'endDate')) : null,
        unit: get(condition, 'unit', ''),
        type: get(condition, 'type', ''),
        description: get(condition, 'description', '')
      };
      const conditionRepeatTime = {
        ...condition,
        subCondition : newSubCondition,
        startDate: moment(get(condition, 'startDate', null)),
        endDate: get(condition, 'endDate') ? moment(get(condition, 'endDate')) : null,
        type: get(condition, 'type', ''),
        description: get(condition, 'description', ''),
      };
      const conditionRepeatAction = {
        ...condition,
        subCondition : newSubCondition,
        startDate: moment(get(condition, 'startDate', null)),
        endDate: get(condition, 'endDate') ? moment(get(condition, 'endDate')) : null,
        type: get(condition, 'type', ''),
        description: get(condition, 'description', ''),
        // value : {...condition.value,isInfiniteCount : !get(condition,'value.isInfiniteCount') ? false : true}
      };
      let conditionNew
      switch (get(condition, 'type', '')) {
        case CONFIG_CAMPAIGN_CONDITION.BOOLEAN:
          conditionNew = { ...conditionBoolean }
          break;
        case CONFIG_CAMPAIGN_CONDITION.RANGE:
          conditionNew = { ...conditionRange }
          break;
        case CONFIG_CAMPAIGN_CONDITION.WORTH:
          conditionNew = { ...conditionWorth }
          break;
        case CONFIG_CAMPAIGN_CONDITION.REPEAT_TIME:
          conditionNew = {...conditionRepeatTime}
          break;
        case CONFIG_CAMPAIGN_CONDITION.REPEAT_ACTION:
          conditionNew = {...conditionRepeatAction}
          break;
      
        default:
          break;
      }
      form.setFieldsValue({
        activity:activitys,
        actor,
        name,
        rankings,
        typeReward: get(typeReward, '_id', ''),
        condition:{...conditionNew}
      });
      setTypeCondition(get(condition, 'type', ''));
      setActivity(activitys);
    }
    else{
      form.setFieldsValue({rankings : ['all']})
    }
  }, [ConfigCampaign, form, id]);
  const renderInput = (InputComponent) =>
    loading ? <SkeletonInput active /> : InputComponent;
  return (
  <>
      <Form
      className="form-config-campaign"
      form={form}
      autoComplete="off"
      onFinish={onFinish}
      scrollToFirstError
      requiredMark={false}
      validateTrigger="onChange"
      initialValues={{ condition: { type: typeCondition  } }}
      onValuesChange={onValuesChange}
      labelCol={{ sm: 24, md: 24, lg: 6 }}
      wrapperCol={{ sm: 24, md: 24, lg: 18 }}
    >
      <Form.Item label="Đối tượng áp dụng" name="actor" rules={rules('chọn đối tượng')}>
        {renderInput(
          <Select
            loading={loading}
            onChange={(value) => setTypes(value)}
            options={optionsActor}
          />
        )}
      </Form.Item>
     <Form.Item shouldUpdate noStyle>
      {() =>  <Form.Item  label="Loại bậc đối tượng áp dụng" name="rankings" rules={rules('chọn Loại bậc đối tượng')}>
        { isLoadingRanking ? <SkeletonInput active /> : (
          <Checkbox.Group>
          <Checkbox value={'all'}>Mọi đối tượng</Checkbox>
            {optionsRankings?.map(e => <Checkbox disabled={!form.getFieldValue(['rankings']) || form.getFieldValue(['rankings'])[0]==='all'} value={get(e,'value')}>{get(e,'label','')}</Checkbox>)}
          </Checkbox.Group>
        )}
      </Form.Item>}
     </Form.Item>
        
          {/* <Select
          mode='multiple'
            loading={isLoadingRanking}
            options={optionsRankings}
             showSearch
            autoComplete="off"
                      filterOption={filterSelectAntd}

          /> */}
      <div className="activityRow">
      <Form.Item
         
        label="Hoạt động"
        name="activity"
        
        rules={rules('chọn hoạt động')}
      >
            
        {renderInput(
          <Select
          showSearch
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onDropdownVisibleChange={onDropdownChange}
            loading={loading || isLoading}
            onChange={(value) => setActivity(value)}
            options={optionsActivityInit}
          />
          
        )}


        {/* {renderInput(<AutoComplete
        
          options={optionsActivityInit}
          style={{
            width: '100%'
          }}
          filterOption={(inputValue, option) =>
            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          // onSearch={(text) => onSearchActivity(text)}
        />)} */}
      </Form.Item>
      <div onClick={handleOpenAddActivity} className='activityRow--buttonPlug'>
       <PlusCircleFilled />
       </div>
      </div>
      
      <Form.Item
        label="Tên chiến dịch"
        name="name"
        rules={rules('nhập tên chiến dịch')}
      >
        {renderInput(<Input />)}
      </Form.Item>
      <Divider>Điều kiện</Divider>
      <Form.Item label="Loại điều kiện" name={['condition', 'type']}>
        {renderInput(
          <Radio.Group
          disabled
            optionType="button"
            options={optionsTypeCondition}
            onChange={handleSelectType}
          />
        )}
      </Form.Item>
      {typeCondition === CONFIG_CAMPAIGN_CONDITION.WORTH && (
        <>
          <Form.Item
            labelCol={{ lg: 6, md: 6, xs: 6, xl: 6 }}
            label="Đơn vị tính"
            name={['condition', 'unit']}
            rules={rules('chọn đơn vị tính')}
          >
            {renderInput(
              <Select onChange={handleChangeUnitWorth} options={optionsUnit} />
            )}
          </Form.Item>
          <Form.Item
            label="Giá trị"
            name={['condition', 'value', 'keyValue']}
            rules={rules('nhập giá trị')}
          >
            {renderInput(
              <InputNumber
                onBlur={onBlurValueWorth}
                formatter={(value) => formatNumberThreeComma(value)}
                min={0}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Mô tả"
            name={['condition', 'description']}
            rules={rules('nhập mô tả')}
          >
            {renderInput(<Input disabled/>)}
          </Form.Item>
          <Form.Item
            label="Ngày bắt đầu"
            name={['condition', 'startDate']}
            rules={rules('chọn ngày bắt đầu')}
          >
            {renderInput(<DatePicker format="DD-MM-YYYY" />)}
          </Form.Item>
          <Form.Item
            label="Ngày kết thúc"
            name={['condition', 'endDate']}
            // rules={rules('chọn ngày kết thúc')}
          >
            {renderInput(<DatePicker format="DD-MM-YYYY" />)}
          </Form.Item>
        </>
      )}
      {typeCondition === CONFIG_CAMPAIGN_CONDITION.BOOLEAN && (
        <>
          <Form.Item
            label="Ngày bắt đầu"
            name={['condition', 'startDate']}
            rules={rules('chọn ngày bắt đầu')}
          >
            {renderInput(<DatePicker format="DD-MM-YYYY" />)}
          </Form.Item>
          <Form.Item
            label="Ngày kết thúc"
            name={['condition', 'endDate']}
            // rules={rules('chọn ngày kết thúc')}
          >
            {renderInput(<DatePicker format="DD-MM-YYYY" />)}
          </Form.Item>
        </>
      )}
      {typeCondition === CONFIG_CAMPAIGN_CONDITION.RANGE && (
        <>
          <Form.Item
            labelCol={{ lg: 6, md: 6, xs: 6, xl: 6 }}
            label="Đơn vị tính"
            name={['condition', 'unit']}
            rules={rules('chọn đơn vị tính')}
          >
            {renderInput(
              <Select onChange={handleChangeUnitRange} options={optionsUnit} />
            )}
          </Form.Item>
          <Row>
            {' '}
            <Col span={12}>
              <Form.Item
                labelCol={{ lg: 12, md: 12, xs: 12, xl: 12 }}
                label={'Từ'}
                name={['condition', 'value', 'keyValue', 'min']}
                rules={rules('nhập')}
              >
                {renderInput(
                  <InputNumber
                    onBlur={onBlurValueRange}
                    formatter={(value) => formatNumberThreeComma(value)}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ lg: 12, md: 12, xs: 12, xl: 12 }}
                label={'Đến'}
                className="align-label-right"
                name={['condition', 'value', 'keyValue', 'max']}
              >
                {renderInput(
                  <InputNumber
                    onBlur={onBlurValueRange}
                    formatter={(value) => formatNumberThreeComma(value)}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Mô tả"
            name={['condition', 'description']}
            rules={rules('nhập mô tả')}
          >
            {renderInput(<Input disabled/>)}
          </Form.Item>
          <Form.Item
            label="Ngày bắt đầu"
            name={['condition', 'startDate']}
            rules={rules('chọn ngày bắt đầu')}
          >
            {renderInput(<DatePicker format="DD-MM-YYYY" />)}
          </Form.Item>
          <Form.Item
            label="Ngày kết thúc"
            name={['condition', 'endDate']}
            // rules={rules('chọn ngày kết thúc')}
          >
            {renderInput(<DatePicker format={'DD-MM-YYYY'}/>)}
          </Form.Item>
        </>
      )}
      {typeCondition === CONFIG_CAMPAIGN_CONDITION.REPEAT_TIME && <RepeatTime rules={rules} renderInput={renderInput}  form={form}/>}
      {typeCondition === CONFIG_CAMPAIGN_CONDITION.REPEAT_ACTION && <RepeatAction id={id} activity={activity} CONFIG_CAMPAIGN_ACTIVITY_ALL={CONFIG_CAMPAIGN_ACTIVITY_ALL} rules={rules} renderInput={renderInput}  form={form}/>}
      {get(CONFIG_CAMPAIGN_ACTIVITY_ALL,`${activity}.subCondition`)?.length > 0 && <>
      <Divider>Điều kiện phụ</Divider>
      {get(CONFIG_CAMPAIGN_ACTIVITY_ALL,`${activity}.subCondition`,[])?.map(e => 
        <Form.Item
            label={get(e,'desc')}
            name={['condition', 'subCondition',get(e,'key')]}
            rules={rules(`Nhập ${get(e,'desc')}`)}
          >
            { get(e,'typeValue') === 'Number' ? renderInput(<InputNumber style={{width : '50%'}} formatter={value => floorFormatter(value)}/>) : renderInput(<Input />)}
          </Form.Item>
      )}
      </>}
      <Divider>Giá trị cấu hình</Divider>
      <div className='activityRow'>
      <Form.Item
        label="Loại phần thưởng"
        name="typeReward"
        rules={rules('chọn loại phần thưởng')}
      >
        {renderInput(
          <Select
          showSearch
          onDropdownVisibleChange={(open) =>{
            if(open){
              triggerFetch()
            }
          }}
          filterOption={(input, option) =>{
            return get(option,'children.props.children[1]','').toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
            loading={loadingRewardItem}
          >
            {rewardItems?.map((e) => {
              const labelPoint = get(e, 'types', '')=== TYPE_REWARD_ITEM_REFERRAL.point ? TYPE_OF_POINT_REWARD_ITEM_VI[get(e, 'typesOfPoint', '')] : ''
              const labelMoney = get(e, 'types', '')=== TYPE_REWARD_ITEM_REFERRAL.money ? TYPE_OF_MONEY_REWARD_ITEM_VI[get(e, 'typesOfMoney', '')] : ''
              const valueReward = get(e, get(e, 'types', ''),'')
              
              const label = ` ${
                TYPE_REWARD_ITEM_REFERRAL_VI[get(e, 'types', '')]
              } : (${formatNumberThreeComma(
                get(e, get(e, 'types', ''),'')
              )})`;
              const value = get(e, '_id', '');
              if (!!get(e, 'item'))
                return (
                  <Select.Option value={value}>
                    <Row align="middle">
                      <Col span={2}>
                        <GiftOutlined />
                      </Col>
                      {label}
                    </Row>
                  </Select.Option>
                );
              if (!isNaN(get(e, 'point')) && get(e, 'point') >= 0) // thưởng
                return (
                  <Select.Option value={value}>
                    <Row align="middle">
                      <Col span={2}>
                        <ArrowUpOutlined style={{ color: 'green' }} />
                      </Col>
                      {`${labelPoint} : (${formatNumberThreeComma(valueReward)})`}
                    </Row>
                  </Select.Option>
                );
              if (get(e, 'point') && get(e, 'point') < 0) // phạt
                return (
                  <Select.Option value={value}>
                    <Row align="middle">
                      <Col span={2}>
                        <ArrowDownOutlined style={{ color: 'red' }} />
                      </Col>
                      {`${labelPoint} : (${formatNumberThreeComma(valueReward)})`}
                    </Row>
                  </Select.Option>
                );
                if (get(e, 'money')) // tiền mặt
                return (
                  <Select.Option value={value}>
                    <Row align="middle">
                      <Col span={2}>
                        <DollarCircleFilled  />
                      </Col>
                      {`${labelMoney} : (${formatNumberThreeComma(valueReward)})`}
                    </Row>
                  </Select.Option>
                );
            })}
          </Select>
        )}
      </Form.Item>
      <a href='/reward-item' target='_blank' className='activityRow--buttonPlug'>
       <PlusCircleFilled />
       </a>
       </div>
      <Row justify="space-around" className="groupButtonSubmit">
        <Col span={8}>
          <Button onClick={handleClose}>Huỷ</Button>
        </Col>
        <Col span={8}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            {id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
        </Col>
      </Row>
    </Form>
    <Modal 
    width={800}
    title="Tạo hoạt động cho chiến dịch"
    closable={false}
      visible={isOpenAddActivity}
        onCancel={handleCloseAddActivity}
        footer={null}>
    <FormAddActivity handleCloseAddActivity={handleCloseAddActivity}/>
    </Modal>
  </>
  );
}
