import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Upload } from 'antd';
import { pick } from 'lodash';
import { useState } from 'react';
import toastr from 'toastr';
import { BASE_URL } from '~/constants/defaultValue';
const DEFAULT_ACTION = `${BASE_URL}/api/v1/course-training-partner/file`;


export default function UploadFile({ setFiles, files = [], apiAction = DEFAULT_ACTION, mode = 'multiple' }) {
  const [loading, setLoading] = useState(false);
  const actionMode = {
    multiple: {
      onRemove: (info) => setFiles(files?.filter(e => e.uid !== info.uid)),
      onChange: (info) => {
        if(info.file.status === "uploading"){
          setLoading(true);
        }
        if (info.file.status === 'done') {
          setFiles([...files, { ...pick(info.file, ['uid', 'name', 'response']) }]);
          setLoading(false);
        }
        if (info.file.status === 'error') {
          toastr.error("Có lỗi xảy ra");
          setLoading(false);
        }
      },
    },
    single: {
      onRemove: () => setFiles(null),
      onChange: (info) => {
        if (info.file.status === 'done') {
          setFiles({
            ...pick(info?.file, ['uid', 'name', 'response'])
          })
        }
      },
    },
  };

  const propss = {
    name: 'file',
    action: apiAction,
    onChange(info) {
      actionMode[mode].onChange(info);
    },
    ...mode === 'single' && { maxCount: 1 },
  };
  return (
    <>
      <Upload  showUploadList={false} multiple={mode === 'multiple'} {...propss} >
        <Button loading={loading} icon={<UploadOutlined />}>Tải file lên</Button>
      </Upload>
      {files?.map((item, index) => <Row style={{ marginBottom: 3 }} align='middle' wrap={false}>
        <Col >
          {index + 1}.
        </Col>
        <Col flex={1}>
          <span>{item.name}</span>
        </Col>
        <Col span={1}>
          <Button onClick={() => actionMode[mode].onRemove(item)} size='small' danger>
            <DeleteOutlined />
          </Button>
        </Col>
      </Row>)}
    </>
  )
}
