export const GET_REWARD_ITEMS_REQUEST =
  'GET_REWARD_ITEMS_REQUEST';
export const GET_REWARD_ITEMS_SUCCESS =
  'GET_REWARD_ITEMS_SUCCESS';
export const GET_REWARD_ITEMS_FAILED = 'GET_REWARD_ITEMS_FAILED';

export const GET_REWARD_ITEM_REQUEST = 'GET_REWARD_ITEM_REQUEST';
export const GET_REWARD_ITEM_SUCCESS = 'GET_REWARD_ITEM_SUCCESS';
export const GET_REWARD_ITEM_FAILED = 'GET_REWARD_ITEM_FAILED';

export const CREATE_REWARD_ITEM_REQUEST =
  'CREATE_REWARD_ITEM_REQUEST';
export const CREATE_REWARD_ITEM_SUCCESS =
  'CREATE_REWARD_ITEM_SUCCESS';
export const CREATE_REWARD_ITEM_FAILED =
  'CREATE_REWARD_ITEM_FAILED';

export const UPDATE_REWARD_ITEM_REQUEST =
  'UPDATE_REWARD_ITEM_REQUEST';
export const UPDATE_REWARD_ITEM_SUCCESS =
  'UPDATE_REWARD_ITEM_SUCCESS';
export const UPDATE_REWARD_ITEM_FAILED =
  'UPDATE_REWARD_ITEM_FAILED';

export const DELETE_REWARD_ITEM_REQUEST =
  'DELETE_REWARD_ITEM_REQUEST';
export const DELETE_REWARD_ITEM_SUCCESS =
  'DELETE_REWARD_ITEM_SUCCESS';
export const DELETE_REWARD_ITEM_FAILED =
  'DELETE_REWARD_ITEM_FAILED';


export const RESET_REWARD_ITEM_STATE = 'RESET_REWARD_ITEM_STATE';
export const RESET_REWARD_ITEM_STATE_ACTION = 'RESET_REWARD_ITEM_STATE_ACTION';
