import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Skeleton,
  Space,
  Tabs
} from 'antd';

import { get } from 'lodash';
import { BaseBorderBox } from '~/components/Common';
import Api from '~/api'
const Printer = ({ url }) => {
  const docs = [
    { uri: url }
    // { uri: "https://url-to-my-pdf.pdf" },
    // { uri: require("./example-files/pdf.pdf") }, // Local File
  ];

  return <DocViewer documents={docs} />;
};
const FormItem = Form.Item;
const { confirm } = Modal;
const DEFAULT_ACCOUNT = 1111;
const mainRowGutter = 24;

const ModalPartnerTransaction = ({ transaction }) => {

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [amountNumberTabs, setAmountNumberTabs] = useState()

   // -----------Props of Tabs---------------
  
  const billId = transaction ? transaction?.receiptVoucher?.whBillItemId : null
  
  return (
          <div className="page-wraper">
            <div className="container-fluid">
              <Form
                initialValues
                // wrapperCol={{ sm: 24, md: 24, lg: 18 }}
                autoComplete="off"
                form={form}
                labelAlign="left"
                labelCol={{ sm: 24, md: 24, lg: 4 }}
                onValuesChange
                requiredMark={false}
              // scrollToFirstError
              >
                <Row
                  align="top"
                  className="staff-form__logo-row"
                  justify="space-between"
                >
                  <Col span={24}>
                    <BaseBorderBox title={'Thông tin khách hàng'}>
                      <Row gutter={36}>
                        <Col span={12}>
                          <FormItem
                            label={'Mã khách hàng'}
                            labelCol={{ lg: 8 }}
                            name="customerNumber"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem
                            // label={<>Tên khách hàng{` `}<code>customerName</code></>}
                            label="Tên khách hàng"
                            labelCol={12}
                            name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </BaseBorderBox>

                    <BaseBorderBox title={'Thông tin buổi hẹn'}>
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label="Số buổi"
                            labelCol={{ lg: 8 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Mã buổi hẹn"
                            labelCol={{ lg: 7 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Gói dịch vụ"
                            labelCol={{ lg: 7 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label="Thêm giờ"
                            labelCol={{ lg: 8 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Giờ"
                            labelCol={{ lg: 7 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Ngày"
                            labelCol={{ lg: 7 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label="Tổng thời lượng"
                            labelCol={{ lg: 8 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Buổi"
                            labelCol={{ lg: 7 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Dự kiến xong"
                            labelCol={{ lg: 7 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label="Thời gian checkin"
                            labelCol={{ lg: 10 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Thời gian checkout"
                            labelCol={{ lg: 10 }}
                          // name="customerName"
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={36}></Row>

                      <Row gutter={36}>
                        <Col span={24}>
                          <FormItem
                            // label={<>Địa chỉ{` `}<code>customerAddressId</code></>}
                            labelCol={18}
                            label="Địa chỉ"
                            name={['customerAddress', 'street']}
                          >
                            {isLoading === false ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input disabled />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </BaseBorderBox>
                    {/* <Row gutter={36}>
                
              </Row> */}
                  </Col>
                </Row>
                <BaseBorderBox title={'Giá tiền'}>
                  <Row gutter={36}>
                    <Col span={8}>
                      <FormItem
                        label="Giá ban đầu"
                        // name={['note']}
                        labelCol={{ lg: 6 }}
                      >
                        {isLoading === false ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input disabled />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Giá cộng thêm"
                        // name={['note']}
                        labelCol={{ lg: 7 }}
                      >
                        {isLoading === false ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input disabled />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Tạm tính"
                        // name={['note']}
                        labelCol={{ lg: 5 }}
                      >
                        {isLoading === false ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input disabled />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </BaseBorderBox>
                <BaseBorderBox title={'Thông tin đính kèm'}>
                  <Row>
                    <Col span={24}>
                      <FormItem
                        label="Thông tin"
                        name={['note']}
                        labelCol={{ lg: 2 }}
                      >
                        {isLoading === false ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input disabled />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </BaseBorderBox>
                <BaseBorderBox title={'Tổng'}>
                  <Row>
                    <Col span={24}>
                      <FormItem
                        label="Tổng thu"
                        name={['note']}
                        labelCol={{ lg: 2 }}
                      >
                        {isLoading === false ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input disabled />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </BaseBorderBox>
              </Form>
            </div>

            {/* <Modal
      visible={isOpenViewer}  
      // onCancel={() => setIsOpenViewer(false)}
    >
      <Printer url={url} />
    </Modal> */}
          </div>
  );
};
export default ModalPartnerTransaction;
