import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getTimeSheets({ payload: query }) {
    try {
      const response = yield call(Api.TimeSheet.getAll, query);
      yield put({ type: Types.GET_TIME_SHEETS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_TIME_SHEETS_FAILED, payload: error });
    }
  }
function* getTimeSheet({ payload: id }) {
    try {
      const response = yield call(Api.TimeSheet.getOne, id);
      yield put({ type: Types.GET_TIME_SHEET_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_TIME_SHEET_FAILED, payload: error });
    }
  }
  function* createTimeSheet(action) {
    try {
      const data = yield call(Api.TimeSheet.create, action.payload);
      yield put({ type: Types.CREATE_TIME_SHEET_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_TIME_SHEET_FAILED, payload: error });
    }
  }
  function* updateTimeSheet(action) {
    try {
      const data = yield call(Api.TimeSheet.update, action.payload);
      yield put({ type: Types.UPDATE_TIME_SHEET_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_TIME_SHEET_FAILED, payload: error });
    }
  }
  function* updateCourseTrainingInTimeSheet(action) {
    try {
      const data = yield call(Api.TimeSheet.updateCourse, action.payload);
      yield put({ type: Types.UPDATE_COURSE_TRAINING_IN_TIME_SHEET_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_COURSE_TRAINING_IN_TIME_SHEET_FAILED, payload: error });
    }
  }
  function* deleteTimeSheet(action) {
    try {
      const data = yield call(Api.TimeSheet.delete, action.payload);
      yield put({ type: Types.DELETE_TIME_SHEET_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_TIME_SHEET_FAILED, payload: error });
    }
  }
export default function* TimeSheets() {
    yield takeLatest(Types.GET_TIME_SHEETS_REQUEST, getTimeSheets);
    yield takeLatest(Types.GET_TIME_SHEET_REQUEST, getTimeSheet);
    yield takeLatest(Types.CREATE_TIME_SHEET_REQUEST, createTimeSheet);
    yield takeLatest(Types.UPDATE_TIME_SHEET_REQUEST, updateTimeSheet);
    yield takeLatest(Types.UPDATE_COURSE_TRAINING_IN_TIME_SHEET_REQUEST, updateCourseTrainingInTimeSheet);
    yield takeLatest(Types.DELETE_TIME_SHEET_REQUEST, deleteTimeSheet);
  }