import { SendOutlined } from '@ant-design/icons';
import { Button, Table, Tag } from 'antd'
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import React, { useState } from 'react'
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { useJobQuestionPaging, useJobQuestions } from '~/hooks/jobQuestion';
import FlexCenter from '../Common/FlexCenter';

export default function TableSource({ onPick, data }) {
    const [query, setQuery] = useState({ page: 1, limit: MAX_PAGINATION_LIMIT });
    const [jobQuestion, isLoading] = useJobQuestions(query);
    const paging = useJobQuestionPaging();
    const columns = [
        {
            title: <Search allowClear onSearch={(value) => setQuery({...query,keyword : value})}/>,
            dataIndex: 'question',
            key: 'question',
        },
        {
            title: '',
            dataIndex: '_id',
            key: '_id',
            render: (_id, rc) => {
                const isPicked = data?.some((item) => item?._id === _id);
                if (isPicked) return <Tag color='success'>Đã chọn</Tag>
                else return <Button ghost type='primary' onClick={() => onPick({
                    _id,
                    question: get(rc, 'question')
                })}><FlexCenter.Row>Chọn <SendOutlined /> </FlexCenter.Row></Button>
                
            }
        }
    ];
    return (
        <Table
            title={() => "Danh sách câu hỏi"}
            rowKey={(rc) => rc._id}
            columns={columns}
            loading={isLoading}
            dataSource={jobQuestion}
            size="small"
            onChange={({ current, pageSize }) => setQuery({ page: current, limit: pageSize })}
            pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `,
                showSizeChanger: true
            }}
        />
    )
}
