import { Button, Col, Form, Input, Row } from 'antd'
import moment from 'moment'
import React from 'react'

export default function FormRefuse({selectRequest,onCancel,handleUpdateRequest,loading}) {
    const [form] = Form.useForm()
    const onFinish = (values) => {
        const submitData = {...selectRequest, ...values,dateApproved : moment().format("YYYY-MM-DD HH:mm:ss")}
        handleUpdateRequest(submitData)
    }
  return (
  <Form form={form} onFinish={onFinish}>
    <Form.Item name="messageFromAdmin" label="Lời nhắn cho đối tác">
        <Input.TextArea autoSize />
    </Form.Item>
    <Row justify='center'> 
        <Col span={6}><Button onClick={onCancel} block>Huỷ</Button></Col>
        <Col span={6}><Button loading={loading} type='primary' htmlType='submit' block>Xác nhận</Button></Col>
    </Row>
  </Form>
  )
}
