import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getWhBanners() {
  try {
    const data = yield call(Api.banner.getAll);
    yield put({ type: Types.GET_WH_BANNERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_BANNERS_FAILED,
      payload: error.message
    });
  }
}

function* getWhBanner({ payload: id }) {
  try {
    const whBanner = yield call(Api.banner.getById, id);
    yield put({ type: Types.GET_WH_BANNER_SUCCESS, payload: whBanner });
  } catch (error) {
    yield put({ type: Types.GET_WH_BANNER_FAILED, payload: error.message });
  }
}

function* createWhBanner(action) {
  console.log('saga start');
  try {
    const data = yield call(Api.banner.create, action.payload);
    yield put({ type: Types.CREATE_WH_BANNER_SUCCESS, payload: data });
  } catch (error) {
    console.log('sagaFailed');
    yield put({
      type: Types.CREATE_WH_BANNER_FAILED,
      payload: error.message
    });
  }
}

function* updateWhBanner(action) {
  try {
    const data = yield call(Api.banner.update, action.payload);
    yield put({ type: Types.UPDATE_WH_BANNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_BANNER_FAILED,
      payload: error.message
    });
  }
}

function* deleteWhBanner({ payload }) {
  try {
    yield call(Api.banner.delete, payload);
    yield put({ type: Types.DELETE_WH_BANNER_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_BANNER_FAILED,
      payload: error.message
    });
  }
}

export default function* whBanner() {
  yield takeLatest(Types.GET_WH_BANNERS_REQUEST, getWhBanners);
  yield takeLatest(Types.GET_WH_BANNER_REQUEST, getWhBanner);
  yield takeLatest(Types.DELETE_WH_BANNER_REQUEST, deleteWhBanner);
  yield takeLatest(Types.CREATE_WH_BANNER_REQUEST, createWhBanner);
  yield takeLatest(Types.UPDATE_WH_BANNER_REQUEST, updateWhBanner);
}
