import requester from './requester';

const whPaymentVoucher = {
  create: whPaymentVoucher => requester.post('/wh-payment-voucher', whPaymentVoucher),
  createPaymentPartner: whPaymentVoucher => requester.post('/wh-payment-voucher-partner', whPaymentVoucher),
  delete: id => requester.delete(`/wh-payment-voucher/${id}`),
  getById: id => requester.get(`/wh-payment-voucher/${id}`),
  getByIdNotBillId: id => requester.get(`/wh-payment-voucher-partner/${id}`),
  getWhPaymentVouchers: query => requester.get('/wh-payment-voucher', query),
  getWhPaymentVouchersCancel: query => requester.get('/type-payment-customer', query),
  update: whPaymentVoucher => requester.put(`/wh-payment-voucher/${whPaymentVoucher.id}`, whPaymentVoucher),
  upload: file => requester.postFormData(`/file/vouchers`, file),
  confirm: whReceiptVoucher => requester.put(`/wh-payment-voucher/${whReceiptVoucher.id}/confirm`, whReceiptVoucher),
  approve: whReceiptVoucher => requester.put(`/wh-payment-voucher/${whReceiptVoucher.id}/approved`, whReceiptVoucher),
  reject: whReceiptVoucher => requester.put(`/wh-payment-voucher/${whReceiptVoucher.id}/reject`, whReceiptVoucher),
  confirmNotBillId: whReceiptVoucher => requester.put(`/wh-payment-voucher-partner/${whReceiptVoucher.id}`, whReceiptVoucher),
  approveNotBillId: whReceiptVoucher => requester.put(`/wh-payment-voucher-partner/${whReceiptVoucher.id}`, whReceiptVoucher),
  rejectNotBillId: whReceiptVoucher => requester.put(`/wh-payment-voucher-partner/${whReceiptVoucher.id}`, whReceiptVoucher),
};

export default whPaymentVoucher;
