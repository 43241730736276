import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  checkInvalidDate,
  useBranchIdSessionStorage,
  useFetch
} from '~/hooks/utils';
import { useSelector } from 'react-redux';

import { createSprint, deleteSprint, getSprintById, getSprints, updateSprint } from '~/redux/workSprint/actions';

const getSelector = key => state => state.workSprint[key];

const loadingSelector = getSelector('isLoading');
const dataSprintSelector = getSelector('list');
const dataSprintFailSelector = getSelector('getListFailed');


const dataByIdSelector = getSelector('byId');
const loadingByIdSelector = getSelector('isGetByIdLoading');
const dataByIdFailSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');


const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');


const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const copySuccessSelector = getSelector('copySuccess');

const pagingSelector = getSelector('paging');
const pagingServiceBranchSelector = getSelector('pagingBranch');

export const useWhServicePaging = () => useSelector(pagingSelector);
export const useWhServiceBranchPaging = () => useSelector(pagingServiceBranchSelector);


export const useCreateSprint =()=>{
  useSuccess(createSuccessSelector,'Tạo phân đoạn thành công!' );
  useFailed(createFailedSelector,'Tạo phân đoạn thất bại!');
  return useSubmit({
    loadingSelector,
    action: createSprint,
  })
}


export const useGetSprints = query => {
  return useFetchByParam({
    action: getSprints,
    loadingSelector: loadingSelector,
    dataSelector: dataSprintSelector,
    failedSelector: dataSprintFailSelector,
    param: query
  });
};

export const useUpdateSprint =()=>{
  useSuccess(updateSuccessSelector,'Cập nhật phân đoạn thành công!' );
  useFailed(updateFailedSelector,'Cập nhật phân đoạn thất bại!');
  return useSubmit({
    loadingSelector,
    action: updateSprint,
  })
}
export const useDeleteSprint =()=>{
  useSuccess(updateSuccessSelector,'Xoá phân đoạn thành công!' );
  useFailed(updateFailedSelector,'Xoá phân đoạn thất bại!');
  return useSubmit({
    loadingSelector,
    action: deleteSprint,
  })
}
export const useGetSprintInfo =(query)=>{
  return useFetchByParam({
    action: getSprintById,
    loadingSelector: loadingByIdSelector,
    dataSelector: dataByIdSelector,
    failedSelector: dataByIdFailSelector,
    param: query
  });
}
