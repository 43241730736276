import React, { useState, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Tooltip, Badge } from 'antd';
import Text from 'antd/lib/typography/Text';
import { InfoCircleOutlined } from '@ant-design/icons';

//Import Breadcrumb
import Breadcrumbs from '../Common/Breadcrumb';

//Import Components
import MiniWidget from './mini-widget';
import SalesAnalyticsChart from './salesanalytics-chart';
//Import Image
import setupanalytics from '../../assets/images/setup-analytics-amico.svg';
import {
  useClinicOverviewToday,
  useClinicOverviewDaily,
  useClinicOverviewMonthly,
  useClinicOverviewQuarterly,
  useClinicOverviewYearly
} from '~/hooks';
import MostDaily from './mostDaily';
import MostMonthly from './mostMonthly';
import MostQuarterly from './mostQuarterly';
import MostYearly from './mostYearly';
import MostAppointment from './mostAppointment';

const series1 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
  }
];

const options1 = {
  fill: {
    colors: ['#5b73e8']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    }
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series2 = [70];

const options2 = {
  fill: {
    colors: ['#34c38f']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series3 = [55];

const options3 = {
  fill: {
    colors: ['#5b73e8']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series4 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
  }
];

const options4 = {
  fill: {
    colors: ['#f1b44c']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    }
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const TODAY = 'today';
const MONTHLY = 'monthly';
const WEEKLY = 'weekly';
const YEARLY = 'yearly';
const QUARTERLY = 'quarterly';

const ClinicDashboard = () => {
  const [viewMode, setViewMode] = useState(WEEKLY);
  const [overviewClinicToday] = useClinicOverviewToday();
  const [overviewClinicDaily] = useClinicOverviewDaily();
  const [overviewClinicMonthly] = useClinicOverviewMonthly();
  const [overviewClinicQuarterly] = useClinicOverviewQuarterly();
  const [overviewClinicYearly] = useClinicOverviewYearly();

  const data = useMemo(() => {
    const source =
      viewMode === TODAY
        ? overviewClinicToday
        : viewMode === WEEKLY
        ? overviewClinicDaily
        : viewMode === MONTHLY
        ? overviewClinicMonthly
        : viewMode === QUARTERLY
        ? overviewClinicQuarterly
        : overviewClinicYearly;
    return source;
  }, [
    viewMode,
    overviewClinicToday,
    overviewClinicDaily,
    overviewClinicMonthly,
    overviewClinicQuarterly,
    overviewClinicYearly
  ]);

  const reports = [
    {
      id: 1,
      title: 'Tổng số lịch hẹn',
      value: data?.sumTotalAppointment?.totalOrder || 0,
      prefix: '',
      suffix: ' lần',
      decimal: 0,
      charttype: 'bar',
      chartheight: 40,
      chartwidth: 70,
      color: 'success',
      series: series1,
      options: options1
    },
    {
      id: 2,
      title: 'Lịch hẹn sắp tới',
      value: data?.appointmentComingUp?.totalOrder || 0,
      decimal: 0,
      charttype: 'radialBar',
      chartheight: 45,
      chartwidth: 45,
      prefix: '',
      suffix: ' lần',
      color: 'danger',
      series: series2,
      options: options2
    },
    {
      id: 3,
      title: 'Lịch hẹn đã hoàn thành',
      value: data?.appointmentComplete?.totalOrder || 0,
      decimal: 0,
      prefix: '',
      suffix: ' lần',
      charttype: 'radialBar',
      chartheight: 45,
      chartwidth: 45,
      color: 'danger',
      series: series3,
      options: options3
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Clinic Dashboard" />
          <Row>
          <div className="float-end">
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  className="text-reset"
                  id="dropdownMenuButton5"
                  caret
                  href="#"
                  style={{ float: 'right', marginBottom: '1.5rem' }}
                >
                  <span className="fw-semibold" style={{ color: '#3481FF' }}>
                    Dữ liệu theo:
                  </span>{' '}
                  <span className="text-muted">
                    {viewMode === TODAY
                      ? 'Hôm nay'
                      : viewMode === WEEKLY
                      ? 'Tuần'
                      : viewMode === MONTHLY
                      ? 'Tháng'
                      : viewMode === QUARTERLY
                      ? 'Quý'
                      : 'Năm'}
                    <i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem href="#" onClick={() => setViewMode(TODAY)}>
                    Hôm nay
                  </DropdownItem>
                  <DropdownItem href="#" onClick={() => setViewMode(WEEKLY)}>
                    Tuần
                  </DropdownItem>
                  <DropdownItem href="#" onClick={() => setViewMode(MONTHLY)}>
                    Tháng
                  </DropdownItem>
                  <DropdownItem href="#" onClick={() => setViewMode(QUARTERLY)}>
                    Quý
                  </DropdownItem>
                  <DropdownItem href="#" onClick={() => setViewMode(YEARLY)}>
                    Năm
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <MiniWidget reports={reports} />
          </Row>

          <Row>
            <Col xl={12}>
              <SalesAnalyticsChart />
            </Col>
          </Row>

          <Row>
            {/* <MostAppointment /> */}
            <MostDaily />
            <MostMonthly />
          </Row>
          <Row>
            <MostQuarterly />
            <MostYearly />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClinicDashboard;
