import { Button, Form, Input, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { LANGUAGE, requireRules } from '~/constants/defaultValue';
import { useCompanyJob, useCreateCompanyJob, useResetActionCompanyJob, useUpdateCompanyJob } from '~/hooks/companyJob';
import { renderLoading } from '~/utils/helper';
import LangFormItem from '../Common/LangFormItem';
import LangSelect from '../Common/LangSelect';
import UploadImage from '../Utils/UploadImage';

export default function CompanyJobForm({ id, onCancel }) {
    const [language,setLanguage] = useState(LANGUAGE.VI);
    const [form] = Form.useForm();
    const [isSubmitLoading, onCreate] = useCreateCompanyJob(onCancel);
    const [, onUpdate] = useUpdateCompanyJob(onCancel);
    const [companyJob, loading] = useCompanyJob(id);
    const onFinish = useCallback((values) => {
        if (id) {
            onUpdate({
                ...values,
                _id: id
            });
        } else {
            onCreate(values)
        }
    }, [id]);
    useEffect(() => {
        if (id && companyJob) {
            form.setFieldsValue({ ...companyJob })
        }
    }, [companyJob, id]);
    // useResetActionCompanyJob();
    return (
        <Form labelCol={{ span: 8 }} form={form} onFinish={onFinish}>
        <LangSelect language={language} setLanguage={setLanguage}/>
            <LangFormItem label="Tên công ty" fieldName={"name"} language={language}>
                {renderLoading(<Input />, loading)}
            </LangFormItem>
            <Form.Item shouldUpdate noStyle>
                {() => <Form.Item label="Hình ảnh đại diện" name={"avatar"}>
                    {renderLoading(<UploadImage imgUrl={form.getFieldValue('avatar')} onChange={(avatar) => form.setFieldsValue({ avatar })} />, loading)}
                </Form.Item>}
            </Form.Item>

            <Row justify='end'>
                <Button loading={isSubmitLoading} htmlType='submit' type='primary'>
                    {id ? "Cập nhật" : "Tạo mới"}
                </Button>
            </Row>
        </Form>
    )
}
