import { useMemo, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce, get } from 'lodash';
import { getExistProp } from '~/utils/helper';
import {
  useBranchIdSessionStorage,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import { convertQuotation, copyQuotation, createQuotation, deleteQuotation, getQuotation, getQuotations, resetQuotationState, updateBillItemOfQuotation, updateQuotation } from '~/redux/quotations/actions';
import api from '~/api';
//console.log();

const getSelector = (key) => (state) => state.quotations[key];

const loadingSelector = getSelector('isLoading');
const isCopyLoadingSelector = getSelector('isLoadingCopy');
const quotationsSelector = getSelector('quotations');
const getQuotationsFailedSelector = getSelector('getQuotationsFailed');

const isGetQuotationLoadingSelector = getSelector('isGetQuotationLoading');
const quotationSelector = getSelector('quotation');
const getQuotationFailedSelector = getSelector('getQuotationFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const copySuccessSelector = getSelector('copySuccess');
const copyFailedSelector = getSelector('copyFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const convertSuccessSelector = getSelector('convertSuccess');
const convertFailedSelector = getSelector('convertFailed');

const pagingSelector = getSelector('paging');

export const useQuotationPaging = () => useSelector(pagingSelector);

export const useQuotationsQueryParams = (keywordParam) => {
  const query = useQueryParams();
  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const keyword = query.get(keywordParam);
  const state = query.get('state');
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const whCategory = query.get('whCategory') || null;

  const onTableChange = ({ current, pageSize }) => {
    setPage(current);
    setLimit(pageSize);
  };

  const deleteQuotationSuccess = useSelector(deleteSuccessSelector);
  const createQuotationSuccess = useSelector(createSuccessSelector);
  const updateSuccessSuccess = useSelector(updateSuccessSelector);
  const convertSuccessSuccess = useSelector(convertSuccessSelector);
  const copySuccess = useSelector(copySuccessSelector);
  const [branchId] = useBranchIdSessionStorage();

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      state,
      [keywordParam]:keyword,
      startDate,
      endDate,
      whCategory,
    };
    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    state,
    startDate,
    endDate,
    deleteQuotationSuccess,
    createQuotationSuccess,
    updateSuccessSuccess,
    convertSuccessSuccess,
    keyword,
    copySuccess,
    branchId,
    whCategory,
  ]);
};

export const useUpdateQuotationParams = (query) => {
  const history = useHistory();
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/wh-quotation',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [ { onParamChange }];
};

export const useQuotations = (query) => {
  return useFetchByParam({
    action: getQuotations,
    loadingSelector,
    dataSelector: quotationsSelector,
    failedSelector: getQuotationsFailedSelector,
    param: query
  });
};

export const useUpdateQuotation = (onSuccess) => {
  useSuccess(updateSuccessSelector, 'Cập nhật đơn hàng tạm thành công',onSuccess);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateQuotation
  });
};
export const useCreateQuotation = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo đơn hàng tạm thành công',callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createQuotation
  });
};
export const useConvertQuotation = (onSuccess) => {
  useSuccess(convertSuccessSelector, 'Chuyển đổi đơn hàng tạm thành công',onSuccess);
  useFailed(convertFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: convertQuotation
  });
};

export const useDeleteQuotation = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá dịch vụ thành công',callback);
  useFailed(deleteFailedSelector, 'Xoá dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteQuotation
  });
};
export const useCopyQuotation = () => {
  useSuccess(copySuccessSelector, 'Sao chép dịch vụ thành công');
  useFailed(copyFailedSelector, 'Sao chép dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isCopyLoadingSelector,
    action: copyQuotation
  });
};

export const useQuotation = (id) => {
  return useFetchByParam({
    action: getQuotation,
    loadingSelector: isGetQuotationLoadingSelector,
    dataSelector: quotationSelector,
    failedSelector: getQuotationFailedSelector,
    param: id
  });
};

export const useResetQuotation = () => {
  useResetState(resetQuotationState);
};

export const useValidateCustomer = (form) => {
    // Check Phone Customer
    const [customer,setCustomer] = useState();
    const [loading,setLoading] = useState(false);
    const [openModalCustomer,setOpenModalCustomer] = useState(false);
    const onFindCustomer = async(defaultPhone) => {
      const phoneNumber = defaultPhone ?? form.getFieldValue('phone');
        if(phoneNumber?.length === 10){
          try {
            setLoading(true);
            const res = await api.whCustomerManagement.getInfoCustomerByPhone({phoneNumber});
            const isExists = get(res,'isExists');
            if(isExists){
              setCustomer(res);
            };
            setLoading(false);
          } catch (error) {
            setLoading(false);
            toastr.error("Có lỗi gì đó xảy ra")
          }
        }else{
          setCustomer(null);
        }
    
    }
    const onCancelModalCustomer = useCallback(() => {
      setOpenModalCustomer(false);
    },[]);
    const onOpenModalCustomer = useCallback(() => {
      setOpenModalCustomer(true);
      onFindCustomer();
    },[]);
    const onFindCustomerDebounce = debounce(onFindCustomer,300);
    //

    return {
      customer,
      onFindCustomerDebounce,
      onCancelModalCustomer,
      onOpenModalCustomer,
      openModalCustomer,
      loading,
    }
}
