import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Form, Input, Row, Button, Skeleton, Col, Select } from 'antd';
import { LANGUAGE, MAX_PAGINATION_LIMIT, PLACE_DID_SERVICE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';

import {
  useCreateHospitalList,
  useUpdateHospitalList,
  useResetHospitalList,
  useInitHospitalList,
  useHospitalList
} from '~/hooks/hospitalList';
import { useServicePlaces, useServicePlaceQueryParams } from '~/hooks';
import { useCities, useDistricts, useWards } from '~/hooks/geo';
import { filterAcrossAccents, filterActiveInstances } from '~/hooks/utils';
import { get, omit } from 'lodash';

const { Option } = Select;
const FormItem = Form.Item;

const HospitalListForm = ({ specialityOptions, onClose, hospitalListId,updateHospitalList,isSubmitLoading }) => {
  const [form] = Form.useForm();
  const queryGetServicePlace = useMemo(() => ({limit : MAX_PAGINATION_LIMIT , state : 'ACTIVE'}),[])
  const [servicePlaces, isServicePlacesLoading] =
  useServicePlaces(queryGetServicePlace);
  const handleAfterFinish = () =>{
    onClose();
    form.resetFields()
    setSelectedCityCode(null)
    setSelectedDistrictCode(null)
  }
  const [, handleCreate] = useCreateHospitalList(handleAfterFinish);
  const [hospitalList, isLoading] = useHospitalList(hospitalListId);
  const initHospitalList = useInitHospitalList(hospitalList);

  const [selectedCityCode, setSelectedCityCode] = useState(null);
  const [selectedDistrictCode, setSelectedDistrictCode] = useState(null);

  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(selectedCityCode);
  const [wards, isWardsLoading] = useWards(selectedDistrictCode);

  useResetHospitalList();

  useEffect(() => {
    form.resetFields();
    if (hospitalListId && initHospitalList) {
      const { address,healthcareFacilityId } = initHospitalList;
      if (address) {
        setSelectedCityCode(address.cityId);
        setSelectedDistrictCode(address.districtId);
      }
      form.setFieldsValue({...initHospitalList,healthcareFacilityId : get(healthcareFacilityId,'_id')});
    }
  }, [initHospitalList, hospitalListId]);

  const onValuesChange = ({ address }) => {
    const shouldResetDistrictAndWards = address && address.cityId;
    if (shouldResetDistrictAndWards) {
      form.setFieldsValue({
        address: {
          districtId: null,
          wardId: null
        }
      });
    }

    const shouldResetWards = address && address.districtId;
    if (shouldResetWards) {
      form.setFieldsValue({
        address: {
          wardId: null
        }
      });
    }
  };

  const onFinish = (values) => {
    const {note} = values
    if(!get(note,'vi')) note.vi = ''
    if(!get(note,'en')) note.en = ''
      if (hospitalListId) {
        updateHospitalList({ ...values, _id : hospitalListId,...values.address});
      } else {
        handleCreate({...values.address,...omit(values,['address']) });
      }
    
    }
  
  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="Customer-type page-wraper hospital-form-page-content">
      <h1 style={{ margin: '40px' }}>
        {hospitalListId ? ' Cập nhật' : 'Thêm mới'} Bệnh viện
      </h1>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          onValuesChange={onValuesChange}
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
        >
          <Col span={24}>
            <FormItem
              label="Nơi thực hiện dịch vụ"
              name="healthcareFacilityId"
              rules={[
                {
                  required: true,
                  message: 'Xin vui lòng chọn Nơi thực hiện dịch vụ'
                }
              ]}
              labelCol={{ sm: 24, md: 24, lg: 3 }}
              wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            >
              {false ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  disabled={isServicePlacesLoading}
                  loading={isServicePlacesLoading}
                  showSearch
                  allowClear
                  autoComplete="off"
                  filterOption={filterAcrossAccents}
                  s
                  placeholder="Nơi thực hiện dịch vụ"
                  style={{ width: '100%' }}
                >
                  {servicePlaces?.filter(e => get(e,'slug') !== PLACE_DID_SERVICE.home.value)?.map(({ id, _id, name }) => (
                    <Option key={id || _id} value={id || _id}>
                      {get(name,'vi','')}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Tên bệnh viện (vi)"
                name={['nameHealthCare', 'vi']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Tên bệnh viện vi!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Tên bệnh viện (en)"
                name={['nameHealthCare', 'en']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Tên bệnh viện en!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input />
                )}
              </FormItem>
            </Col>

      
          </Row>
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Thành Phố/Tỉnh"
                name={['address', 'cityId']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    onChange={setSelectedCityCode}
                    disabled={isCitiesLoading}
                    loading={isCitiesLoading}
                    showSearch
                    autoComplete="off"
                    value={get(form.getFieldValue('address'), 'cityId', '')}
                    filterOption={filterAcrossAccents}
                  >
                    {cities.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Quận/Huyện"
                name={['address', 'districtId']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Quận/Huyện!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isDistrictsLoading}
                    disabled={!form.getFieldValue(['address', 'cityId'])}
                    onChange={setSelectedDistrictCode}
                    showSearch
                    autoComplete="off"
                    value={get(form.getFieldValue('address'), 'districtId', '')}
                    filterOption={filterAcrossAccents}
                  >
                    {districts.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Phường/Xã"
                name={['address', 'wardId']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Phường/Xã!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isWardsLoading}
                    disabled={!form.getFieldValue(['address', 'districtId'])}
                    showSearch
                    value={get(form.getFieldValue('address'), 'wardId', '')}
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {wards.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Địa chỉ" name={['address', 'street']}>
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>
          <FormItem
            label="Ghi chú (vi)"
            name={['note', LANGUAGE.VI]}
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
          >
            {renderInput(<Input />)}
          </FormItem>
          <FormItem
            label="Ghi chú (en)"
            name={['note', LANGUAGE.EN]}
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
          >
            {renderInput(<Input />)}
          </FormItem>
          <Row className="form__submit-box">
            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {hospitalListId ? 'Cập nhật' : 'Thêm mới'}
            </Button>

            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.hospitalList.root}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default HospitalListForm;
