import produce from 'immer';
import * as Types from '../../constants/actionTypes';

const initState = {
  orderSelect:null,
  userSelect:null,
  addressSelect:null,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    // CERTIFICATE
    case Types.ADD_ORDER_SELECT:
      state.orderSelect = payload
      return;
    case Types.ADD_USER_SELECT:
      state.userSelect = payload
      return;
    case Types.ADD_ADDRESS_SELECT:
      state.addressSelect = payload
      return;

   
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
