import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getProductCatalogues({ payload }) {
  try {
    const {isAll,...query} = payload;
    let data;
    if(isAll){
    data = yield call(Api.productCatalogue.getAllNoAuth, query);
    }else{
    data = yield call(Api.productCatalogue.getAll, query);
    }
    yield put({ type: Types.GET_PRODUCT_CATALOGUES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_CATALOGUES_FAILED,
      payload: error.message
    });
  }
}

function* getProductCatalogue({ payload }) {
  try {
    const res = yield call(Api.productCatalogue.getById, payload);
    yield put({
      type: Types.GET_PRODUCT_CATALOGUE_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_CATALOGUE_FAILED,
      payload: error.message
    });
  }
}

function* getProductCatalogueStatus({ payload }) {
  try {
    const res = yield call(Api.productCatalogue.getByStatus, payload);
    yield put({
      type: Types.GET_PRODUCT_CATALOGUE_STATUS_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_CATALOGUE_STATUS_FAILED,
      payload: error.message
    });
  }
}

function* createProductCatalogue(action) {
  try {
    const data = yield call(Api.productCatalogue.create, action.payload);
    yield put({ type: Types.CREATE_PRODUCT_CATALOGUE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PRODUCT_CATALOGUE_FAILED,
      payload: error.message
    });
  }
}

function* updateProductCatalogue(action) {
  try {
    const data = yield call(Api.productCatalogue.update, action.payload);
    yield put({ type: Types.UPDATE_PRODUCT_CATALOGUE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PRODUCT_CATALOGUE_FAILED,
      payload: error.message
    });
  }
}
function* updateProductCatalogueStatus(action) {
  try {
    const data = yield call(Api.productCatalogue.update, action.payload);
    yield put({ type: Types.UPDATE_PRODUCT_CATALOGUE_STATUS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PRODUCT_CATALOGUE_STATUS_FAILED,
      payload: error
    });
  }
}

function* deleteProductCatalogue({ payload }) {
  try {
    yield call(Api.productCatalogue.delete, payload);
    yield put({ type: Types.DELETE_PRODUCT_CATALOGUE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_PRODUCT_CATALOGUE_FAILED,
      payload: error.message
    });
  }
}

export default function* partner() {
  yield takeLatest(Types.GET_PRODUCT_CATALOGUES_REQUEST, getProductCatalogues);
  yield takeLatest(Types.GET_PRODUCT_CATALOGUE_REQUEST, getProductCatalogue);
  yield takeLatest(Types.GET_PRODUCT_CATALOGUE_STATUS_REQUEST, getProductCatalogueStatus);
  yield takeLatest(Types.DELETE_PRODUCT_CATALOGUE_REQUEST, deleteProductCatalogue);
  yield takeLatest(Types.CREATE_PRODUCT_CATALOGUE_REQUEST, createProductCatalogue);
  yield takeLatest(Types.UPDATE_PRODUCT_CATALOGUE_REQUEST, updateProductCatalogue);
  yield takeLatest(Types.UPDATE_PRODUCT_CATALOGUE_STATUS_REQUEST, updateProductCatalogueStatus);
}
