import requester from './requester';

const companyJob = {
  getAll: (query) => requester.get(`/job-company`, query),
  getById: (id) => requester.get(`/job-company/${id}?raw=true`),
  create: (companyJob) => requester.post(`/job-company`, companyJob),
  update: (companyJob) => requester.put(`/job-company/${companyJob._id}`, companyJob),
  delete: (id) => requester.delete(`/job-company/${id}`)
};

export default companyJob;
