import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useMatchOrPolicy, useMatchPolicy, useUserPolicy } from '~/hooks';
import { Spin } from 'antd';
import { REDIRECT } from '~/constants/defaultValue';

const WithPolicies = ({ children, permission, layout: Layout,permissionOr }) => {
  let isMatchPolicy = useMatchPolicy(permission);
  let isMatchOrPolicy = useMatchOrPolicy(permissionOr);
  const [_, y, policies] = useUserPolicy();
  const shouldMount = useMemo(() => !!Object.keys(policies || {}).length, [
    policies
  ]);
  if (!shouldMount) {
    return (
      <Layout>
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin size="large" tip="Loading..." />
        </div>
      </Layout>
    );
  }

  if (permission ? isMatchPolicy : isMatchOrPolicy) {
    return <>{children}</>;
  }
  return <Redirect to={REDIRECT.unauthorized} />;
};

export default WithPolicies;
