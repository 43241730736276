import { Alert, Badge, Button, Col, Descriptions, Form, Input, InputNumber, Modal, Row, Select, Spin, Switch, Table, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import './wh-order-temp.scss';
import { formatter } from '~/utils/helper';
import LoadingWhBill from './LoadingWhBill';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from '~/api';
import { get, isNil } from 'lodash';
import { MIN_BILL_TO_HAVE_PREPAYMENT, MIN_PERCENT_BILL_DEPOSIT, queryFetchAll, SERVICE_GROUP_TYPES, VAT, WALLET_TYPE } from '~/constants/defaultValue';
import Text from 'antd/lib/typography/Text';
import { v4 } from 'uuid';
import { useGetMyRanking } from '~/hooks/memberRanking';
import { useGetMyPointCanUse, useGetMyPointCanUseModeFetch, usePointSystemsPartner } from '~/hooks/pointSystem';
import { formatNumberThreeComma, formatNumberThreeCommaToFixed } from '~/hooks/utils';
import toastr from 'toastr';
import ExchangePointRefund from '../Workspace/wallet/ExchangePointRefund';
import { useExchangePoints } from '~/hooks/exchangePoint';
import { useWalletCustomer } from '~/hooks/wallet';
import TableListDiscount from './TableListDiscount';
const descriptionItemProps = {
  contentStyle: {
    fontWeight: "bold",
    textAlign: "right",
    maxWidth: '100px'
  },
  labelStyle: {
    width: '50%'
  }
}
export default function ConvertQuotationStepThree({ convertQuotation, isSubmitLoadingSelector, quotation }) {
  const { id } = useSelector(
    (state) => state.quotation.userSelect
  );
  const ref = useRef();
  const [form] = Form.useForm();
  const { billItems, prePayAmount, vat } = quotation;
  const [prePayAmountTemp,setPrePayAmountTemp] = useState(prePayAmount);
  const { quantity, price, minimumQuantity, whServiceId,whService = {} } = billItems[0]
  const addressSelect = useSelector(state => state.quotation.addressSelect);
  const [coupons, setCoupons] = useState([])
  const [couponSelectedCode, setCouponSelectedCode] = useState(null)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [refund, setRefund] = useState(0)
  const [isFirstBill, setIsFirstBill] = useState(false);
  const [isUseDiscountFromFE, setIsUseDiscountFromFE] = useState(false);
  const [visibleExchangepoint, setVisibleExchangepoint] = useState(false);
  const [isOpenModalDiscount, setIsOpenModalDiscount] = useState(false);
  const [isOpenDeal, setIsOpenDeal] = useState(false);
  const [dealPrice, setDealPrice] = useState(0);
  // GET POINT AND RANKING
  const { data: myRanking, isLoading: isLoadingRanking } = useGetMyRanking(id);
  const [myWallet, isLoadingWallet, mutateWallet] = useWalletCustomer(id);
  const { data: dataPoint, mutate } = useGetMyPointCanUseModeFetch(id, get(myRanking, 'maxRefund', 0))
  ////////////////////////////////////////////////////////////////
  const queryfetch = useMemo(() => queryFetchAll, [])
  const [exchangePoint, loadingExchangepoint] = useExchangePoints(queryfetch)
  const prePayAmountActually = useMemo(() => prePayAmountTemp ,[prePayAmountTemp]);
  
  const handleOpenModalExchangePoint = () => {
    setVisibleExchangepoint(true)
  }
  const handleCloseModalExchangePoint = () => {
    setVisibleExchangepoint(false)
  }
  const onFinish = (values) => {
    const {dealPrice : d_price} = values;
    setDealPrice(d_price);
    setIsOpenDeal(false);
  }

  // VALUEs
  const totalPriceRoot = useMemo(() => quantity * price * minimumQuantity, [quantity, price, minimumQuantity]);
  const total = useMemo(() => dealPrice > 0 ? dealPrice : quantity * price * minimumQuantity, [quantity, price, minimumQuantity,dealPrice]);
  const difDeal = useMemo(() => dealPrice - totalPriceRoot,[dealPrice,totalPriceRoot]);
  const minTotalBillAmount = useMemo(() => {
    if (isFirstBill) return 0
    return total * MIN_PERCENT_BILL_DEPOSIT / 100
  }, [total, isFirstBill]);
  const discountedRates = useMemo(() => {
    if (get(myRanking, 'name')) { // check is have ranking
      const { maxDiscountRate, percentDiscount } = myRanking
      if (maxDiscountRate) {
        if (maxDiscountRate <= total * percentDiscount / 100) {
          return maxDiscountRate
        }
      }
      return total * percentDiscount / 100;
    }
    return 0

  }, [total, myRanking]);
  const bonus = useMemo(() => get(whService,'bonus',0) * minimumQuantity * quantity,[whService,minimumQuantity,quantity])
  const totalAfterDiscount = useMemo(() => {
    return total - discountAmount - discountedRates - refund + bonus
  }, [total, discountedRates, discountAmount, refund,whService])
  const vatAmount = useMemo(() => {
    return totalAfterDiscount * VAT;
  }, [totalAfterDiscount]);

  const totalAfterVat = useMemo(() => {
    return totalAfterDiscount + vatAmount
  }, [totalAfterDiscount, vatAmount]);

  const havePrePaymentInThisStep = useMemo(() => 
  totalPriceRoot >= MIN_BILL_TO_HAVE_PREPAYMENT || dealPrice >= MIN_BILL_TO_HAVE_PREPAYMENT
  ,[dealPrice,totalPriceRoot]);
  useEffect(() => {
    const discountCanUse = totalAfterVat + discountAmount - minTotalBillAmount
    if (discountAmount >= discountCanUse) {
      setDiscountAmount(discountCanUse)
      setIsUseDiscountFromFE(true)
    }
    else {
      setIsUseDiscountFromFE(false)
    }
  }, [discountAmount, minTotalBillAmount]);
  useEffect(() => {
    const getBillCustomer = async () => {
      const bills = await api.whBill.getAll({ customerId: id, page: 1, limit: 1 })
      if (get(bills, 'totalDocs', 0) > 0) {
        setIsFirstBill(false)
      }
      else {
        setIsFirstBill(true)
      }
    }
    id && getBillCustomer()
  }, [id])

  useEffect(() => {
    if(totalPriceRoot < MIN_BILL_TO_HAVE_PREPAYMENT){
      if(dealPrice < MIN_BILL_TO_HAVE_PREPAYMENT){
        setPrePayAmountTemp(0);
      }
    }
  },[dealPrice,totalPriceRoot]);
  const calculatePriceUseCoupon = (coupon) => {
    const { discountPercent, maxDiscount, discountValue } = coupon
    if (discountValue) {
      return discountValue
    }
    else {
      const discount = discountPercent * total / 100
      if (maxDiscount && discount > maxDiscount) {
        return maxDiscount
      }
      else {
        return discount
      }
    }
  }
  const handleSelectCoupon = (couponSelect) => {
    if(!couponSelect) return
    const couponFind = coupons?.find(coupon => get(coupon, 'value') === couponSelect)
    const discountAmount = calculatePriceUseCoupon(couponFind)
    setDiscountAmount(discountAmount)
    setCouponSelectedCode(couponSelect)
  }
  const handleUseRefund = (open) => {
    const refundCanUse = totalAfterVat + refund - minTotalBillAmount
    let maxRefundCanUseByRanking = get(myRanking,'maxRefundUse')
    if (get(myRanking, 'maxRefund', 0) === 0) { // check 
      return toastr.error("Khách hàng không được điểm thưởng, hãy nâng cấp loại tài khoản")
    }
    if (refundCanUse === 0) { // check
      return toastr.error("Khách hàng phải thanh toán ít nhất 45% giá trị đơn hàng")
    }
    if (open) { // handle use
      let totalRefundCanUseThisRank = total * get(myRanking, 'maxRefund', 0) / 100
      if (totalRefundCanUseThisRank > get(myWallet, 'totalRefund', 0)) {
        totalRefundCanUseThisRank = get(myWallet, 'totalRefund', 0)
      }
      
      if (totalRefundCanUseThisRank > refundCanUse) {
        totalRefundCanUseThisRank = refundCanUse
      }
      if (maxRefundCanUseByRanking && totalRefundCanUseThisRank > maxRefundCanUseByRanking) {
        totalRefundCanUseThisRank = maxRefundCanUseByRanking
      }
      setRefund(totalRefundCanUseThisRank)
    }
    else { // handle Off
      setRefund(0)
    }
  }
  const handleCheckOut = async () => {
    if(havePrePaymentInThisStep && dealPrice >= MIN_BILL_TO_HAVE_PREPAYMENT && prePayAmountActually <= 0){
      // Focus and Scroll to the Input
      ref.current?.focus();
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      return toastr.error("Vui lòng nhập số tiền tạm ứng");
    };
    if(dealPrice > 0 && prePayAmountActually > dealPrice){
        // Focus and Scroll to the Input
        ref.current?.focus();
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      return toastr.error("Số tiền tạm ứng không được lớn hơn " + formatNumberThreeCommaToFixed(dealPrice));
    }
    const newBillItems = billItems.map(e => ({
      ...e,
      startDate: moment(e.startDate),
      endDate: moment(e.endDate),
      bonus,
      dealPrice : dealPrice > 0 ? difDeal : 0,
    }))
    const submitData = {
      billItems: newBillItems,
      customerAddress: {
        ...addressSelect

      },
      customerAddressId: addressSelect._id,
      customerId: id,
      paymentMethod: quotation.paymentMethod,
      prePay: prePayAmountActually > 0,
      prePayAmount: prePayAmountActually,
      vat: quotation.vat,
      idQuotation: quotation._id,
      ...(couponSelectedCode && {
        discountCode: couponSelectedCode,
      }),
      ...isUseDiscountFromFE && {
        discountAmountUse: discountAmount
      },
      refund,
      discountedRates
    };
    convertQuotation(submitData)

  };
  return (
    <>
      <div className="page-content__main">
        {isSubmitLoadingSelector && <LoadingWhBill open={isSubmitLoadingSelector} />}
        <div className="wh-orderTemp-Box" style={{ width: '95%' }}>
          {/* <LoadingWhBill open={loading} /> */}
          <Row style={{ width: '100%', margin: '10px 0' }} align='bottom'>

            <Col span={24}>
              <Descriptions bordered column={1}>
                <Descriptions.Item
                  {...descriptionItemProps}
                  label={<div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>Tổng tiền dịch vụ</span>
                  <Button onClick={() => setIsOpenDeal(true)}>Deal giá</Button>
                  </div>}
                >
                  {dealPrice > 0 ? <Row justify='end'>
                  <span style={{textDecoration : 'line-through',fontSize : 12}}>{formatter(totalPriceRoot)}</span> 
                    <span>{formatter(dealPrice)}</span>
                  </Row>
                  : formatter(total)}
                </Descriptions.Item>

                {havePrePaymentInThisStep ? <Descriptions.Item
                  {...descriptionItemProps}
                  label={<div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>Tạm ứng</span>
                  </div>}
                >
                  <InputNumber ref={ref} min={0} max={total + bonus} formatter={formatter} value={prePayAmountTemp} onChange={(value) => setPrePayAmountTemp(value)} style={{ width: 200 }} placeholder='Nhập số tiền tạm ứng' />
                </Descriptions.Item> : null}

                <Descriptions.Item
                  {...descriptionItemProps}
                  label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Phụ thu</span>
                  </div>}
                >
                  {formatter(bonus)}
                </Descriptions.Item>

                <Descriptions.Item
                  {...descriptionItemProps}
                  label={<div style={{ display: "flex", justifyContent: "space-between" }}><Tooltip
                    zIndex={10000}
                    placement="topRight"
                    title={get(myRanking, 'name') ? `Khách hàng đang ở bậc ${get(myRanking, 'name', 'Chưa có')?.trim()}, được chiết khấu ${get(myRanking, 'percentDiscount', 0)}% giá trị đơn hàng${get(myRanking, 'maxDiscountRate') ? `, Tối đa ${formatter(get(myRanking, 'maxDiscountRate'))}.` : ""}`
                      : `Bạn chưa đạt cấp bậc nào nên chưa được chiết khấu.`}
                  >
                    <Badge
                      size="small"
                      color="#9B9999"
                      offset={[0, -4]}
                      count={<InfoCircleOutlined />}
                    >
                      <span> Chiết khấu KHTT</span>
                    </Badge>
                  </Tooltip>
                    {/* <code>subTotal</code> */}
                  </div>}
                >
                  - {formatter(discountedRates)}
                </Descriptions.Item>
                <Descriptions.Item
                  {...descriptionItemProps}
                  label={
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span>Mã giảm giá</span>{' '}
                      {/* <code>actualTotalAmountPaid</code> */}
                    </div>
                  }
                >
                  <Row justify='end' align='middle' gutter={16}>
                    <Col flex={1} >
                      {/* <Select value={couponSelectedCode} onChange={handleSelectCoupon} loading={loadingGetCoupon} style={{ width: '100%' }} onDropdownVisibleChange={(open) => {
                        if (open) {
                          fetchCoupon()
                        }
                      }}>
                        {loadingGetCoupon ? <Select.Option style={{ pointerEvents: 'none' }} value={v4()}><Row justify='center'><Col align='center'><Spin /></Col></Row></Select.Option> 
                        : coupons?.length > 0 && coupons?.map(item => 
                        <Select.Option disabled={get(item, 'disabled')} value={get(item, 'value')} key={get(item, 'value')} >
                          {get(item, 'label')} (
                          {get(item, 'discountValue') && <Text strong>{formatter(get(item, 'discountValue'))}</Text>}
                          {get(item, 'discountPercent') && <Text strong>{formatter(get(item, 'discountPercent'))}%</Text>}
                          )
                        </Select.Option>)}
                      </Select> */}
                      <Typography.Link onClick={() => setIsOpenModalDiscount(true)}>
                        {"Xem danh sách mã khuyến mãi"}
                      </Typography.Link>
                    </Col>
                    <Col>
                      <Text strong>{couponSelectedCode && `-${formatter(discountAmount)}`}</Text>
                    </Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item
                  {...descriptionItemProps}
                  label={
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Tooltip
                        zIndex={10000000}
                        placement="topRight"
                        title={get(myRanking, 'name') ? `Khách hàng đang ở bậc ${get(myRanking, 'name', 'Chưa có')?.trim()}, chỉ được dùng ${get(myRanking, 'maxRefund', 0)}% điểm cho đơn hàng ${get(myRanking, 'maxRefundUse') ? `, tối đa ${formatter(get(myRanking, 'maxRefundUse'))}.` : ''}`
                          : `Bạn chưa đạt cấp bậc nào nên chưa được dùng điểm thưởng.`
                        }
                      >
                        <Badge
                          size="small"
                          color="#9B9999"
                          offset={[0, 0]}
                          count={<InfoCircleOutlined />}
                        >
                          <span>Dùng điểm thưởng</span>
                          <p>(Số dư: {formatNumberThreeCommaToFixed(get(myWallet, 'totalRefund', 0), 2)})</p>
                        </Badge>
                      </Tooltip>
                      <Button onClick={handleOpenModalExchangePoint} type='primary'>Đổi thêm điểm</Button>
                    </div>
                  }
                >
                  {refund !== 0 && `- ${formatNumberThreeComma(refund)}`}<Switch checked={refund > 0} onChange={handleUseRefund} />
                </Descriptions.Item>



                <Descriptions.Item
                  {...descriptionItemProps}
                  // label="Tổng tiền (chưa VAT)"
                  label={
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span>Tổng tiền (chưa VAT)</span>{' '}
                      {/* <code>subTotal - discountAmount</code> */}
                    </div>
                  }
                >
                  {formatter(totalAfterDiscount)}
                </Descriptions.Item>

                <Descriptions.Item
                  {...descriptionItemProps}
                  // label={`VAT (${get(whBill, "vat") * 100}%)`}
                  label={
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span>VAT ({VAT * 100}%)</span>{' '}
                      {/* <code>vat</code> */}
                    </div>
                  }
                >
                  {formatter(vatAmount)}
                </Descriptions.Item>

                <Descriptions.Item
                  {...descriptionItemProps}
                  // label="Tổng tiền (đã VAT)"
                  label={
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span>Tổng tiền (đã VAT)</span>
                      {/* <code>grandTotal</code> */}
                    </div>
                  }
                  span={2}
                >
                  {/* {((quantity * price * minimumQuantity) - (quantity * price * minimumQuantity) * vat / 100)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} */}
                  {formatter(totalAfterVat)}
                </Descriptions.Item>

                <Descriptions.Item
                  {...descriptionItemProps}
                  // label="Tiền khách hàng muốn tạm ứng"
                  label={
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span>Tiền khách hàng muốn tạm ứng</span>{' '}
                    </div>
                  }
                >
                  {formatter(prePayAmountActually || 0)}
                </Descriptions.Item>

                <Descriptions.Item
                  {...descriptionItemProps}
                  // label="Số tiền còn lại"
                  label={
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span>Số tiền còn lại</span>
                      {/* <code>remainingAmount</code> */}
                    </div>
                  }
                >
                  {formatter(totalAfterVat)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24}>
              {isFirstBill ? <Alert message={<Text strong>Khách hàng được giảm tối đa 100% cho đơn hàng đầu tiên khi dùng mã giảm giá và điểm thưởng</Text>} type="info" /> : <Alert style={{ textAlign: 'left' }} message={"Khách hàng phải chi trả tối thiểu 45% giá trị của đơn hàng"} type="error" />}
            </Col>
          </Row>
          <Button loading={isSubmitLoadingSelector} onClick={handleCheckOut} type="primary">
            Đặt dịch vụ
          </Button>
        </div>
      </div>
      <Modal width={600} onCancel={handleCloseModalExchangePoint} footer={null} visible={visibleExchangepoint}>
        <ExchangePointRefund userId={id} type={WALLET_TYPE.REFUND} mutate={() => {
          mutate()
          handleCloseModalExchangePoint()
          mutateWallet()
        }} dataExchange={exchangePoint} point={get(dataPoint, 'remainingPointCanUse', 0)} />
      </Modal>
      <Modal title={"Danh sách mã giảm giá"} footer={null} width={1000} closable={false} destroyOnClose visible={isOpenModalDiscount} onCancel={() => setIsOpenModalDiscount(false)}>
        <TableListDiscount submitData={{
          address: addressSelect,
          serviceGroupType: SERVICE_GROUP_TYPES.HOME_HEALTH_CARE,
          whServiceIds: [whServiceId],
          userId: id
        }}
          setCoupons={setCoupons}
          coupons={coupons}
          onCancel={() => setIsOpenModalDiscount(false)}
          handleSelectCoupon={handleSelectCoupon}
          total={total}
          calculatePriceUseCoupon={calculatePriceUseCoupon}
        />
      </Modal>
      <Modal footer={
        [
          <Button onClick={() => {
            setDealPrice(0);
            setIsOpenDeal(false)
          }}>Huỷ Deal</Button>,
          <Button type='primary' onClick={() => form.submit()}>Ok</Button>,
        ]
      } 
      cancelText="Huỷ deal" title={`Nhập giá muốn deal`} destroyOnClose visible={isOpenDeal} onCancel={() => setIsOpenDeal(false)}>
        <Form initialValues={{ dealPrice }} form={form} onFinish={onFinish}>
          <Form.Item name={'dealPrice'}>
            <InputNumber style={{width : '200px'}} formatter={formatter} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
