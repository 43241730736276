import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import {
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAILED,
  GET_STAFFS_REQUEST,
  GET_STAFFS_SUCCESS,
  GET_STAFFS_FAILED,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  CREATE_STAFF_FAILED,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  GET_STAFF_FAILED,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_FAILED
} from '../../constants/actionTypes';

function* getStaffs({ payload: query }) {
  try {
    const response = yield call(Api.staff.getStaffsAuthor, query);
    yield put({ type: GET_STAFFS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_STAFFS_FAILED, payload: error.message });
  }
}

function* getStaff({ payload: id }) {
  try {
    const staff = yield call(Api.staff.getById, id);
    yield put({ type: GET_STAFF_SUCCESS, payload: staff });
  } catch (error) {
    yield put({ type: GET_STAFF_FAILED, payload: error.message });
  }
}

function* createStaff(action) {
  try {
    const data = yield call(Api.staff.create, action.payload);
    yield put({ type: CREATE_STAFF_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_STAFF_FAILED, payload: error.message });
  }
}

function* updateStaff(action) {
  try {
    const data = yield call(Api.staff.update, action.payload);
    yield put({ type: UPDATE_STAFF_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_STAFF_FAILED, payload: error.message });
  }
}

function* deleteHopital({ payload }) {
  try {
    yield call(Api.staff.delete, payload);
    yield put({ type: DELETE_STAFF_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_STAFF_FAILED, payload: error.message });
  }
}

export default function* staff() {
  yield takeLatest(GET_STAFFS_REQUEST, getStaffs);
  yield takeLatest(GET_STAFF_REQUEST, getStaff);
  yield takeLatest(DELETE_STAFF_REQUEST, deleteHopital);
  yield takeLatest(CREATE_STAFF_REQUEST, createStaff);
  yield takeLatest(UPDATE_STAFF_REQUEST, updateStaff);
}
