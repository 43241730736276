import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useBranchIdSessionStorage,
    useFetchByParam,
    useQueryParams, useResetState
} from '~/hooks/utils';
import { getWhReportCustomer, getWhReportCustomers, resetWhReportCustomerState } from '~/redux/whReportCustomer/actions';
import { getExistProp } from '~/utils/helper';



const getSelector = key => state => state.whReportCustomer[key];

const loadingSelector = getSelector('isLoading');
const whReportCustomersSelector = getSelector('whReportCustomers');
const getWhReportCustomersFailedSelector = getSelector('getWhReportCustomersFailed');

const loadingWhReportCustomerSelector = getSelector('isLoadingWhReportCustomer');
const whReportCustomerSelector = getSelector('whReportCustomer');
const getWhReportCustomerFailedSelector = getSelector('getWhReportCustomerFailed');


const pagingSelector = getSelector('paging');

export const useWhReportCustomerPaging = () => useSelector(pagingSelector);

export const useWhReportCustomerQueryParams = (date) => {
  const query = useQueryParams();
  const [branchId] = useBranchIdSessionStorage();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const fullName = query.get('fullName');
  const customerId = query.get('customerId');
  const phoneNumber = query.get('phoneNumber');
  const managementArea = query.get('managementArea');
  // const startDate = query.get('startDate') ;
  // const endDate = query.get('endDate');
  const startDate = query.get('startDate') || get(date, 'startDate');
  const endDate = query.get('endDate') || get(date, 'endDate');

  return useMemo(() => {
    const queryParams = {
      limit,
      page,
      startDate,
      endDate,
      fullName,
      customerId,
      phoneNumber,
      managementArea
    };

    return [queryParams];

    //eslint-disable-next-line
  }, [
    limit,
    page,
    endDate,
    startDate,
    fullName,
      customerId,
      phoneNumber,
      date,
      managementArea,
      branchId,
  ]);
};
export const useUpdateReportCustomerParams = (query) => {
    const history = useHistory();
    const SearchBy = useMemo(
      () => ['fullName', 'customerId', 'phoneNumber','managementArea'].find((e) => query[e]),
      []
    );
    const resetQueryParams = {
      fullName: null,
      customerId: null,
      phoneNumber: null,
      managementArea:null,
      page: 1,
      limit: 10,
    };
    const [keyword, setKeyword] = useState(query[SearchBy]);
    const onParamChange = (param) => {
      if (param['modules'] && !param['modules'].length) {
        param = { modules: undefined };
      }
      history.push({
        pathname: '/wh-report-customer',
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword, setKeyword, SearchBy,{  onParamChange ,resetQueryParams}];
  };
export const useWhReportCustomers = query => {
  return useFetchByParam({
    action: getWhReportCustomers,
    loadingSelector,
    dataSelector: whReportCustomersSelector,
    failedSelector: getWhReportCustomersFailedSelector,
    param: query
  });
};
export const useWhReportCustomer = query => {
  return useFetchByParam({
    action: getWhReportCustomer,
    loadingSelector:loadingWhReportCustomerSelector,
    dataSelector: whReportCustomerSelector,
    failedSelector: getWhReportCustomerFailedSelector,
    param: query
  });
};

export const useResetWhReportCustomer = () => {
  useResetState(resetWhReportCustomerState);
};
