import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import {
  DELETE_WH_APPOINTMENT_REQUEST,
  DELETE_WH_APPOINTMENT_SUCCESS,
  DELETE_WH_APPOINTMENT_FAILED,
  GET_WH_APPOINTMENTS_REQUEST,
  GET_WH_APPOINTMENTS_SUCCESS,
  GET_WH_APPOINTMENTS_FAILED,
  CREATE_WH_APPOINTMENT_REQUEST,
  CREATE_WH_APPOINTMENT_SUCCESS,
  CREATE_WH_APPOINTMENT_FAILED,
  GET_WH_APPOINTMENT_REQUEST,
  GET_WH_APPOINTMENT_SUCCESS,
  GET_WH_APPOINTMENT_FAILED,
  UPDATE_WH_APPOINTMENT_REQUEST,
  UPDATE_WH_APPOINTMENT_SUCCESS,
  UPDATE_WH_APPOINTMENT_FAILED,
  CHECK_IN_WH_APPOINTMENT_REQUEST,
  CHECK_OUT_WH_APPOINTMENT_REQUEST,
  UPDATE_LIST_PRODUCT_APPOINTMENT_REQUEST,
  UPDATE_LIST_PRODUCT_APPOINTMENT_SUCCESS,
  UPDATE_LIST_PRODUCT_APPOINTMENT_FAILED,
} from '../../constants/actionTypes';

function* getWhAppointments({ payload: query }) {
  try {
    const response = yield call(Api.whAppointment.getWhAppointments, query);
    yield put({ type: GET_WH_APPOINTMENTS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_APPOINTMENTS_FAILED, payload: error.message });
  }
}

function* getWhAppointment({ payload: id }) {
  try {
    const whAppointment = yield call(Api.whAppointment.getById, id);
    yield put({ type: GET_WH_APPOINTMENT_SUCCESS, payload: whAppointment });
  } catch (error) {
    yield put({ type: GET_WH_APPOINTMENT_FAILED, payload: error.message });
  }
}

function* createWhAppointment(action) {
  try {
    const data = yield call(Api.whAppointment.create, action.payload);
    yield put({ type: CREATE_WH_APPOINTMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_WH_APPOINTMENT_FAILED, payload: error.message });
  }
}

function* updateWhAppointment(action) {
  try {
    const data = yield call(Api.whAppointment.update, action.payload);
    yield put({ type: UPDATE_WH_APPOINTMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_APPOINTMENT_FAILED, payload: error.message });
  }
}

function* checkInWhAppointment(action) {
  try {
    const data = yield call(Api.whAppointment.checkIn, action.payload);
    yield put({ type: UPDATE_WH_APPOINTMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_APPOINTMENT_FAILED, payload: error.message });
  }
}

function* checkOutWhAppointment(action) {
  try {
    const data = yield call(Api.whAppointment.checkOut, action.payload);
    yield put({ type: UPDATE_WH_APPOINTMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_APPOINTMENT_FAILED, payload: error.message });
  }
}

function* deleteHopital({ payload }) {
  try {
    yield call(Api.whAppointment.delete, payload);
    yield put({ type: DELETE_WH_APPOINTMENT_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_WH_APPOINTMENT_FAILED, payload: error.message });
  }
}

function* updateListProductAppointment(action) {
  try {
    const data = yield call(Api.whAppointment.updateListProduct, action.payload);
    yield put({ type: UPDATE_LIST_PRODUCT_APPOINTMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: UPDATE_LIST_PRODUCT_APPOINTMENT_FAILED,
      payload: error
    });
  }
}

export default function* whAppointment() {
  yield takeLatest(GET_WH_APPOINTMENTS_REQUEST, getWhAppointments);
  yield takeLatest(GET_WH_APPOINTMENT_REQUEST, getWhAppointment);
  yield takeLatest(DELETE_WH_APPOINTMENT_REQUEST, deleteHopital);
  yield takeLatest(CREATE_WH_APPOINTMENT_REQUEST, createWhAppointment);
  yield takeLatest(UPDATE_WH_APPOINTMENT_REQUEST, updateWhAppointment);
  yield takeLatest(CHECK_IN_WH_APPOINTMENT_REQUEST, checkInWhAppointment);
  yield takeLatest(CHECK_OUT_WH_APPOINTMENT_REQUEST, checkOutWhAppointment);
  yield takeLatest(UPDATE_LIST_PRODUCT_APPOINTMENT_REQUEST, updateListProductAppointment);

}
