import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  useBranchIdSessionStorage
} from '~/hooks/utils';

import {
  getShippingVendors,
  createShippingVendor,
  deleteShippingVendor,
  getShippingVendorDetail,
  resetShippingVendorState,
  updateShippingVendor
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const SHIPPING_VENDOR = 'shippingVendor';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(SHIPPING_VENDOR);

export const useShippingVendors = (query) => {
  return useFetchByParam({
    action: getShippingVendors,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateShippingVendor = (callback) => {
  useSuccess(
    createSuccessSelector,
    'Tạo mới nhà vận chuyển thành công',
    callback
  );
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createShippingVendor
  });
};

export const useUpdateShippingVendor = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật nhà vận chuyển thành công',
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateShippingVendor
  });
};

export const useDeleteShippingVendor = (onDeleteSuccess) => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá nhà vận chuyển thành công',
    onDeleteSuccess
  );
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector,
    action: deleteShippingVendor
  });
};

export const useShippingVendorDetail = (params) => {
  return useFetchByParam({
    action: getShippingVendorDetail,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitShippingVendor = (shippingVendor, id) => {
  return useMemo(() => {
    if (!shippingVendor || !id) {
      return {
        discountType: 'value'
      };
    }

    const initShippingVendor = {
      ...shippingVendor
    };

    return initShippingVendor;
  }, [shippingVendor, id]);
};

export const useUpdateShippingVendorParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    history.push({
      pathname: '/shipping-vendor',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useShippingVendorQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  const [branchId] = useBranchIdSessionStorage();

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      cityId
    });

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    cityId,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    branchId
  ]);
};

export const useShippingVendorPaging = () => useSelector(pagingSelector);

export const useResetShippingVendor = () => {
  useResetState(resetShippingVendorState);
};
