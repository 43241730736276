import React, { useEffect, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { Modal, Row, Space, Table } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import api from '~/api';
import moment from 'moment';
import { get } from 'lodash';
import { notifyHttpError } from '~/utils/helper';
import { LANGUAGE } from '~/constants/defaultValue';

// const { Panel } = Collapse;
function callback(key) {
  console.log(key);
}

const ServiceOfHistory = ({ whBill}) => {
  
  const mergedWhBillItems = useMemo(() => {
    return get(whBill, "billItems")?.map(item => ({
      ...item,
      snapshotService: {
        ...get(item, "snapshotService"),
      },
    }))
  }, [whBill]);


  const columns = [
    {
      title: 'ID Dịch vụ',
      key: 'code',
      width: '150px',
      render: (record) =>{
        return `${get(record, "code")}`
      }
    },

    {
      title: 'Dịch vụ',
      key: 'name',
      width: '350px',
      render: (record) =>
      {
        return `${get(record, "snapshotService.name.vi")} - ${get(record, "snapshotService.detailName.vi")} - 
                ${get(record, "snapshotService.code")}
                `
      }
    },

    {
      title: 'Loại dịch vụ',
      key: 'packageLevelName',
      width: '270px',
      render: (record) => {
        const renderType = record => {
          return `
          ${get(record, "snapshotService.package.packageLevelName.vi")}`
        }
        return renderType(record)
      }
    },
    {
      title: 'Thời gian thực hiện',
      key: 'time',
      width: '250px',
      render: (record) => {
        const renderPeriod = (record) => {
          return `
            ${moment(get(record, 'startDate')).format('DD/MM/YYYY')} - 
            ${moment(get(record, 'endDate')).format('DD/MM/YYYY')}
          `
        }
        return renderPeriod(record);
      }
    },

    {
      title: 'Số lượng (lần)',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '170px',
      render : (record)=>{
        return record;
      }
    },

    {
      title: 'Thành tiền',
      dataIndex: 'grandTotal',
      key: 'grandTotal',
      width: '120px',
      render: (record) => {
        return record.toLocaleString();
      }
    },

    {
      title: 'Điểm thưởng',
      // dataIndex: 'point',
      key: 'point',
      width: '90px'
    },

    {
      title: 'Đánh giá',
      // dataIndex: 'rate',
      key: 'rate',
      width: '120px'
    }
  ];

  return (
    <div
      className="container-fluid"
      // style={{overflowX:'auto',width:'1200px'}}
    >
    <>
      <div className="page-content__main">
      
        <Table
          style={{overflowX:'auto'}}
          width="50%"
          rowKey={(rc) => rc._id}
          columns={columns}
          dataSource={mergedWhBillItems}
          size="small"
          // pagination={false}
          footer={null}
          pagination={{
            showTotal: (total) => `Tổng cộng: ${total} `,
          }}
        />
        
      </div>
      </>
    </div>
  );
};

export default ServiceOfHistory;
