import { get } from "lodash";

export default function TableRenderWordConfirmDeliveryVoucher(listProduct) {
    const styleP = "font-family: Times New Roman;margin : 0 auto;";
    const styleFlex = "width : 100% ; display : flex;flex-direction : column;align-items:center;justify-content:center;gap : 1px;"
    const styleTd = "border: 1px solid black;padding: 8px;";
    const styleTdTotal = "border: 1px solid black;padding: 8px; text-align: center; font-weight: bold;";
    const styleTrHeader = "border-color : black ; border-style : solid ; border-width : 1;font-family: serif;padding : 5px 0;";
  const total = listProduct?.length;
    let listItem = ""
  listProduct?.forEach((item, index) => {
    listItem += `<tr>
      <td style="${styleTd}"><p style="${styleP}">${index + 1}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'product.name', '')}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'codeProductOld', '')}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'product.code', '')}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${`Cái`}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${`1`}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'product.valueDepreciation', '')}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'note', '')}</p></td>
    </tr>`
  }
  );
  const totalRow = `<tr>
  <td style="${styleTdTotal}" colspan="8"><p style="${styleP}">${`Tổng cộng: ${total}` }</p></td>
</tr>`;
  return `<table style="border-collapse: collapse;font-family: serif;width : 100%;">
  <thead >
    <tr >
    <th style="${styleTrHeader}"><p style="font-family: serif;">STT</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Tên sản phẩm, hàng hoá</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Mã thiết bị (kho ban đầu)</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Mã thiết bị (kho nhận)</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Đơn vị tính</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Số lượng</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Giá khấu hao</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Ghi chú</p></th>
    </tr>
    </thead>
    ${listItem}
    ${totalRow}
    </table>`
}
