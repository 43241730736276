import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getInfoCompanys({ payload: query }) {
    try {
      const response = yield call(Api.infoCompany.getAll, query);
      yield put({ type: Types.GET_INFO_COMPANYS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_INFO_COMPANYS_FAILED, payload: error });
    }
  }
function* getInfoCompany({ payload: id }) {
    try {
      const response = yield call(Api.infoCompany.getById, id);
      yield put({ type: Types.GET_INFO_COMPANY_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_INFO_COMPANY_FAILED, payload: error });
    }
  }
  function* updateInfoCompany(action) {
    try {
      const data = yield call(Api.infoCompany.update, action.payload);
      yield put({ type: Types.UPDATE_INFO_COMPANY_SUCCESS, payload: data });
      yield put({ type: Types.RESET_INFO_COMPANY_ACTION});
    } catch (error) {
      yield put({ type: Types.UPDATE_INFO_COMPANY_FAILED, payload: error });
    }
  }
  function* createInfoCompany(action) {
    try {
      const data = yield call(Api.infoCompany.create, action.payload);
      yield put({ type: Types.CREATE_INFO_COMPANY_SUCCESS, payload: data });
      yield put({ type: Types.RESET_INFO_COMPANY_ACTION});
    } catch (error) {
      yield put({ type: Types.CREATE_INFO_COMPANY_FAILED, payload: error });
    }
  }
  function* deleteInfoCompany(action) {
    try {
      const data = yield call(Api.infoCompany.delete, action.payload);
      yield put({ type: Types.DELETE_INFO_COMPANY_SUCCESS, payload: data });
      yield put({ type: Types.RESET_INFO_COMPANY_ACTION});
    } catch (error) {
      yield put({ type: Types.DELETE_INFO_COMPANY_FAILED, payload: error });
    }
  }
export default function* InfoCompanys() {
    yield takeLatest(Types.GET_INFO_COMPANYS_REQUEST, getInfoCompanys);
    yield takeLatest(Types.GET_INFO_COMPANY_REQUEST, getInfoCompany);
    yield takeLatest(Types.UPDATE_INFO_COMPANY_REQUEST, updateInfoCompany);
    yield takeLatest(Types.CREATE_INFO_COMPANY_REQUEST, createInfoCompany);
    yield takeLatest(Types.DELETE_INFO_COMPANY_REQUEST, deleteInfoCompany);
  }
