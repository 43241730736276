import { CheckCircleTwoTone } from '@ant-design/icons'
import { Col, Row } from 'antd'
import Text from 'antd/lib/typography/Text'
import { get } from 'lodash'
export default function ItemProcessBoolean({ process,layout }) {
  return (
    <Row justify='space-between' gutter={16}>
      <Col { ...layout.left }>
      <Text strong>{get(process, 'name', '')}</Text>
        <RewardItem process={process} />
      </Col>
      <Col {...layout.right}>
        <Text>{get(process, 'countProcess', 0) > 0 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : "Chưa nhận"}</Text>
      </Col>
    </Row>
  )
}
