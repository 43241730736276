import React from 'react';

import { Space } from 'antd';
import { get } from 'lodash';

import { useUserWorkspace } from '~/hooks';

import { COMPONENT_MODES, WH_BILL_ITEM_STATUS } from '~/constants/defaultValue';

import WhPartnerBillItemCardCollapse from './WhBillItem/WhPartnerBillItemCardCollapse/index';
import WhPartnerBillItemCardsList from './WhBillItem/WhPartnerBillItemCardsList';
import WhPartnerMiniForm from './WhPartner/WhPartnerMiniForm';

const WorkspaceManageTasks = ({
}) => {
  const [profile] = useUserWorkspace();
  const id = get(profile, "_id");

  const itemComponentProps = {
    allowCheckInOut: true,
    whPartnerId: id,
  }

  return (
    <div className='page-wraper page-content page-content-workSpace'>
      <Space direction='vertical' style={{ width: "100%" }} size="middle">
        <WhPartnerMiniForm
          whPartnerId={id}
          showAssignedServicesTable={true}
          showWorkingDaysTable={false}
          mode={COMPONENT_MODES.VIEW}
        />
        <WhPartnerBillItemCardsList
          whPartnerId={id}
          statuses={[
            WH_BILL_ITEM_STATUS.CONFIRMED,
            WH_BILL_ITEM_STATUS.IN_PROGRESS,
          ]}
          itemComponent={WhPartnerBillItemCardCollapse}
          itemComponentProps={itemComponentProps}
        />
      </Space>
    </div>
  )
}

export default WorkspaceManageTasks;
