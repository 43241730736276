import produce from 'immer';
import { get, pick } from 'lodash';
import moment from 'moment';
import { FORMAT_DATE_TIME } from '~/constants/defaultValue';
import { STATUS_WAREHOUSE_VOUCHER } from '~/constants/warehouse';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getWarehouseVouchersFailed: null,
  getWarehouseVoucherFailed: null,
  warehouseVouchers: [],
  warehouseVoucher: null,
  isSubmitLoading: false,
  isGetWarehouseVoucherLoading: false,
  WarehouseVoucher: null,

  updateFailedCourse: null,
  updateSuccessCourse: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

  partnerCreateSuccess: null,
  partnerCreateFailed: null,

  partnerConfirmSuccess: null,
  partnerConfirmFailed: null,

  partnerRejectSuccess: null,
  partnerRejectFailed: null,

  employeeConfirmSuccess: null,
  employeeConfirmFailed: null,

  employeeRejectSuccess: null,
  employeeRejectFailed: null,

  supplierConfirmSuccess: null,
  supplierConfirmFailed: null,

  supplierRejectSuccess: null,
  supplierRejectFailed: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WAREHOUSE_VOUCHERS_REQUEST:
    case Types.GET_WAREHOUSE_VOUCHERS_PARTNER_REQUEST:
      state.isLoading = true;
      state.getWarehouseVouchersFailed = null;
      return;

    case Types.GET_WAREHOUSE_VOUCHERS_SUCCESS:
    case Types.GET_WAREHOUSE_VOUCHERS_PARTNER_SUCCESS:
      state.isLoading = false;
      state.warehouseVouchers = get(payload, 'docs', []);
      state.getWarehouseVouchersFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_WAREHOUSE_VOUCHERS_FAILED:
    case Types.GET_WAREHOUSE_VOUCHERS_PARTNER_FAILED:
      state.isLoading = false;
      state.warehouseVouchers = [];
      state.getWarehouseVouchersFailed = payload;
      return;
    case Types.GET_WAREHOUSE_VOUCHER_REQUEST:
    case Types.GET_WAREHOUSE_VOUCHER_PARTNER_REQUEST:
      state.isGetWarehouseVoucherLoading = true;
      state.warehouseVoucher = null
      state.getWarehouseVoucherFailed = null;
      return;

    case Types.GET_WAREHOUSE_VOUCHER_SUCCESS:
    case Types.GET_WAREHOUSE_VOUCHER_PARTNER_SUCCESS:
      state.isGetWarehouseVoucherLoading = false;
      state.warehouseVoucher = payload;
      state.getWarehouseVoucherFailed = null;
      return;

    case Types.GET_WAREHOUSE_VOUCHER_FAILED:
    case Types.GET_WAREHOUSE_VOUCHER_PARTNER_FAILED:
      state.isGetWarehouseVoucherLoading = false;
      state.warehouseVoucher = null;
      state.getWarehouseVoucherFailed = payload;
      return;

      //CREATE
    case Types.CREATE_WAREHOUSE_VOUCHER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_WAREHOUSE_VOUCHER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_WAREHOUSE_VOUCHER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    // CREATE BY PARTNER
    case Types.CREATE_WAREHOUSE_VOUCHER_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.partnerCreateSuccess = null;
      state.partnerCreateFailed = null;
      return;
    case Types.CREATE_WAREHOUSE_VOUCHER_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.partnerCreateSuccess = payload;
      return;

    case Types.CREATE_WAREHOUSE_VOUCHER_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.partnerCreateFailed = payload;
      return;
    //
      case Types.UPDATE_WAREHOUSE_VOUCHER_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_WAREHOUSE_VOUCHER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.warehouseVoucher = payload;
      return;

    case Types.UPDATE_WAREHOUSE_VOUCHER_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_WAREHOUSE_VOUCHER_REQUEST:
      state.isSubmitLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_WAREHOUSE_VOUCHER_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_WAREHOUSE_VOUCHER_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

      // PARTNER_CONFIRM
      case Types.PARTNER_CONFIRM_WAREHOUSE_VOUCHER_REQUEST:
        state.isSubmitLoading = true;
        state.partnerConfirmSuccess = null;
        state.partnerConfirmFailed = null;
        return;
  
      case Types.PARTNER_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS:
        state.isSubmitLoading = false;
        state.partnerConfirmSuccess = payload;
        state.warehouseVoucher = { ...state.warehouseVoucher,
          ...pick(get(payload,'data',{}),['status','confirmPartner']), // Field Change
          //  confirmPartner : STATUS_WAREHOUSE_VOUCHER.CONFIRM,
           dateConfirmPartner : moment().format(FORMAT_DATE_TIME.YYYYMMDDHHmmss)
           };
        return;
  
      case Types.PARTNER_CONFIRM_WAREHOUSE_VOUCHER_FAILED:
        state.isSubmitLoading = false;
        state.partnerConfirmFailed = payload;
        return;
      //
      // PARTNER_REJECT
      case Types.PARTNER_REJECT_WAREHOUSE_VOUCHER_REQUEST:
        state.isSubmitLoading = true;
        state.partnerRejectSuccess = null;
        state.partnerRejectFailed = null;
        return;
  
      case Types.PARTNER_REJECT_WAREHOUSE_VOUCHER_SUCCESS:
        state.isSubmitLoading = false;
        state.partnerRejectSuccess = payload;
        state.warehouseVoucher = { ...state.warehouseVoucher,
          ...pick(get(payload,'data',{}),['status','confirmPartner']), // Field Change
          //  confirmPartner : STATUS_WAREHOUSE_VOUCHER.REJECT,
           dateConfirmPartner : moment().format(FORMAT_DATE_TIME.YYYYMMDDHHmmss)
           };
        return;
  
      case Types.PARTNER_REJECT_WAREHOUSE_VOUCHER_FAILED:
        state.isSubmitLoading = false;
        state.partnerRejectFailed = payload;
        return;
      //

      // EMPLOYEE_CONFIRM
      case Types.EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_REQUEST:
        state.isSubmitLoading = true;
        state.employeeConfirmSuccess = null;
        state.employeeConfirmFailed = null;
        return;
  
      case Types.EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS:
        state.isSubmitLoading = false;
        state.employeeConfirmSuccess = payload;
        state.warehouseVoucher = { ...state.warehouseVoucher,
          ...pick(get(payload,'data',{}),['status','confirmEmployee','User']), // Field Change
          dateConfirmEmployee : moment().format(FORMAT_DATE_TIME.YYYYMMDDHHmmss)
           };
        return;
  
      case Types.EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_FAILED:
        state.isSubmitLoading = false;
        state.employeeConfirmFailed = payload;
        return;
      //
      // EMPLOYEE_REJECT
      case Types.EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_REQUEST:
        state.isSubmitLoading = true;
        state.employeeRejectSuccess = null;
        state.employeeRejectFailed = null;
        return;
  
      case Types.EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_SUCCESS:
        state.isSubmitLoading = false;
        state.employeeRejectSuccess = payload;
        state.warehouseVoucher = { ...state.warehouseVoucher,
          ...pick(get(payload,'data',{}),['status','confirmEmployee','User']), // Field Change
           dateConfirmEmployee : moment().format(FORMAT_DATE_TIME.YYYYMMDDHHmmss)
           };
        return;
  
      case Types.EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_FAILED:
        state.isSubmitLoading = false;
        state.employeeRejectFailed = payload;
        return;
      //


      // SUPPLIER_CONFIRM
      case Types.SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_REQUEST:
        state.isSubmitLoading = true;
        state.supplierConfirmSuccess = null;
        state.supplierConfirmFailed = null;
        return;
  
      case Types.SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS:
        state.isSubmitLoading = false;
        state.supplierConfirmSuccess = payload;
        state.warehouseVoucher = { ...state.warehouseVoucher,
          ...pick(get(payload,'data',{}),['status','confirmSupplier']), // Field Change
          dateConfirmSupplier : moment().format(FORMAT_DATE_TIME.YYYYMMDDHHmmss)
           };
        return;
  
      case Types.SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_FAILED:
        state.isSubmitLoading = false;
        state.supplierConfirmFailed = payload;
        return;
      //
      // SUPPLIER_REJECT
      case Types.SUPPLIER_REJECT_WAREHOUSE_VOUCHER_REQUEST:
        state.isSubmitLoading = true;
        state.supplierRejectSuccess = null;
        state.supplierRejectFailed = null;
        return;
  
      case Types.SUPPLIER_REJECT_WAREHOUSE_VOUCHER_SUCCESS:
        state.isSubmitLoading = false;
        state.supplierRejectSuccess = payload;
        state.warehouseVoucher = { ...state.warehouseVoucher,
          ...pick(get(payload,'data',{}),['status','confirmSupplier']), // Field Change
           dateConfirmSupplier : moment().format(FORMAT_DATE_TIME.YYYYMMDDHHmmss)
           };
        return;
  
      case Types.SUPPLIER_REJECT_WAREHOUSE_VOUCHER_FAILED:
        state.isSubmitLoading = false;
        state.supplierRejectFailed = payload;
        return;
      //


    case Types.RESET_ACTION_WAREHOUSE_VOUCHER_STATE:
      state.getWarehouseVouchersFailed = null;
      state.getWarehouseVoucherFailed = null;
      state.warehouseVoucher = null;
      state.createSuccess = null;
      state.createFailed = null;
      state.updateSuccess = null;
      state.updateFailed = null;
      state.partnerCreateSuccess = null;
      state.partnerCreateFailed = null;
      state.partnerConfirmSuccess = null;
      state.partnerRejectSuccess = null;
      state.employeeConfirmSuccess = null;
      state.employeeRejectSuccess = null;
      state.supplierConfirmSuccess = null;
      state.supplierRejectSuccess = null;
      return;

    case Types.RESET_STORE:
    case Types.RESET_WAREHOUSE_VOUCHER_STATE:
      return initState;

    default:
      return;
  }
}, initState);
