import React, { useState } from "react";
import SkeletonTable from "../Utils/SkeletonTable";
import { Col, Input, Row, Select, Table } from "antd";
import { Breadcrumb } from "../Common";
import { useContactParams, useWhContactPaging, useWhContactQueryParams, useWhContacts } from "~/hooks/whContact";
import moment from "moment";

const { Search } = Input;
const {Option} = Select

const WhContact = () => {
  const [searchBy, setSearchBy] = useState('name')

  const [queryParams, onTableChange] = useWhContactQueryParams()
  const [keyword, { setKeyword, onParamChange }] = useContactParams(queryParams)
  const [whContacts, isLoading ] = useWhContacts(queryParams)

  const paging = useWhContactPaging()
  const columns = [
    {
      title: 'STT',
      key: 'name',
      width: 50,
      align: 'center',
      render: (item, record, index) => <span>{index + 1}</span>
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Lời nhắn',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Ngày gửi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm') 
    },
  ];
  
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách chờ liên hệ" />
        
        <div className="page-wraper__header">
          {/* Search Input*/}

          <Row justify="start" gutter={36}>
          <Col sm={12} md={6}>
            <Select
              value={searchBy}
              style={{ width: '100%' }}
              onChange={(val) => {
                onParamChange({
                  name: null,
                  phoneNumber: null,
                  email: null,
                  page: 1
                });
                setSearchBy(val);
              }}
            >
              <Option value="name">Tên khách hàng</Option>
              <Option value="phoneNumber">Số điện thoại</Option>
              <Option value="email">Email</Option>
            </Select>
            </Col>
            <Col sm={8} md={6}>
            {
              {
                name: (
                  <Search
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Tìm theo tên khách hàng"
                    enterButton
                    onSearch={() =>
                      onParamChange({ [searchBy]: keyword, page: 1 })
                    }
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                ),
                phoneNumber: (
                  <Search
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Tìm theo số điện thoại"
                    enterButton
                    onSearch={() =>
                      onParamChange({ [searchBy]: keyword, page: 1 })
                    }
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                ),
                email: (
                  <Search
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Tìm theo đối email"
                    enterButton
                    onSearch={() =>
                      onParamChange({ [searchBy]: keyword, page: 1 })
                    }
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                ),
                }[searchBy]
              }
            </Col>
          </Row>
          </div>

      {isLoading ? (
      <SkeletonTable
        rowCount={5}
        columns={columns}
        pagination={paging}
      />
    ) : (
      <Table
        rowKey={(rc) => rc._id}
        dataSource={whContacts || []}
        columns={columns}
              onChange={(curPage) => {
                console.log(curPage,'pageSize')
                onTableChange(curPage)
                onParamChange({
                  page: 1,
                  limit: curPage.pageSize
                })
              }}
        size="middle"
        pagination={{
          ...paging,
          showTotal: (total) => `Tổng cộng: ${total} `
        }}
      />
    )}
      </div>
    </div>
  )
    ;
}

export default WhContact;