import { DoubleRightOutlined } from '@ant-design/icons';
import { Col, Dropdown, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import api from '~/api';
import POLICIES from '~/constants/policy';
import { useMatchOrPolicy } from '~/hooks';

export default function ControlRating({ infoRating, rating, id, mutateAppointment, billItemId, billId }) {
    const [loading,setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [rate, setRate] = useState();
    const [reason, setReason] = useState('');
    const canUpdate = useMatchOrPolicy([POLICIES.UPDATE_WH_APPOINTMENT, POLICIES.UPDATE_WHBILL])
    const item = infoRating?.find(e => e?.rate === rating);
    const onOpen = useCallback((value) => {
        setIsOpen(true);
        setRate(value);
    }, [])
    const onClose = useCallback(() => {
        setIsOpen(false);
        setRate(null);
        setReason('')
    }, [])
    const onChangeRating = async () => {
      try {
        setLoading(true);
        await api.whAppointment.updateRating({ id, rating: rate, billItemId, billId, reason });
        mutateAppointment();
        onClose();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    return (
        <>
            <Dropdown overlay={canUpdate ? <div className='updateRating'>{infoRating?.filter(e => get(e, 'rate') !== rating)?.map(rate => <img className='updateRating--img' onClick={() => onOpen(get(rate, 'rate'))} src={rate?.iconLink || ""} width="50px" height={"50px"} title='icon' />)}</div> : null}
                trigger={['hover']}>
                <img className='updateRating--img' src={item?.iconLink || ""} width='50px' height={'50px'} title='icon' />
            </Dropdown>
            <Modal okButtonProps={{ disabled: !reason?.trim(),loading }} title="Xác nhận đổi đánh giá" okText="Xác nhận" onOk={onChangeRating} visible={isOpen} onCancel={onClose} destroyOnClose forceRender={false} >
                <Row gutter={16} style={{ marginBottom: 20 }} align='middle'>
                    <Col>
                        <img className='updateRating--img' src={item?.iconLink || ""} width='50px' height={'50px'} title='icon' />
                    </Col>
                    <Col>
                        <DoubleRightOutlined />
                    </Col>
                    <Col>
                        <img className='updateRating--img' src={get(infoRating?.find(r => get(r, 'rate') === rate), 'iconLink') || ""} width='50px' height={'50px'} title='icon' />
                    </Col>
                </Row>
                <TextArea placeholder='Vui lòng nhập lý do đổi đánh giá' onChange={(e) => setReason(e.target.value)} value={reason} />
            </Modal>
        </>

    )
}
