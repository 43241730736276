import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getConfigCampaigns({ payload: query }) {
    try {
      const response = yield call(Api.configCampaign.getAll, query);
      yield put({ type: Types.GET_CONFIG_CAMPAIGNS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_CONFIG_CAMPAIGNS_FAILED, payload: error });
    }
  }
function* getConfigCampaign({ payload: id }) {
    try {
      const response = yield call(Api.configCampaign.getById, id);
      yield put({ type: Types.GET_CONFIG_CAMPAIGN_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_CONFIG_CAMPAIGN_FAILED, payload: error });
    }
  }
  function* createConfigCampaign(action) {
    try {
      const data = yield call(Api.configCampaign.create, action.payload);
      yield put({ type: Types.CREATE_CONFIG_CAMPAIGN_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_CONFIG_CAMPAIGN_FAILED, payload: error });
    }
  }
  function* updateConfigCampaign(action) {
    try {
      const data = yield call(Api.configCampaign.update, action.payload);
      yield put({ type: Types.UPDATE_CONFIG_CAMPAIGN_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_CONFIG_CAMPAIGN_FAILED, payload: error });
    }
  }
  function* deleteConfigCampaign(action) {
    try {
      const data = yield call(Api.configCampaign.delete, action.payload);
      yield put({ type: Types.DELETE_CONFIG_CAMPAIGN_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_CONFIG_CAMPAIGN_FAILED, payload: error.message });
    }
  }
export default function* ConfigCampaigns() {
    yield takeLatest(Types.GET_CONFIG_CAMPAIGNS_REQUEST, getConfigCampaigns);
    yield takeLatest(Types.GET_CONFIG_CAMPAIGN_REQUEST, getConfigCampaign);
    yield takeLatest(Types.CREATE_CONFIG_CAMPAIGN_REQUEST, createConfigCampaign);
    yield takeLatest(Types.UPDATE_CONFIG_CAMPAIGN_REQUEST, updateConfigCampaign);
    yield takeLatest(Types.DELETE_CONFIG_CAMPAIGN_REQUEST, deleteConfigCampaign);
  }
