import React, {
  useState
} from 'react';

import { CheckCircleFilled, PictureOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Button, Col, Image, Modal, Row, Space, Tag } from 'antd';
import { get } from 'lodash';

import LocationSvg from '~/components/WhPartner/LocationSvg';

const CheckInOut = ({ record }) => {
  const [isOpen, setIsOpen] = useState(false);

  const formatTime = (time) => moment(time).format("HH:mm - DD/MM/YYYY")

  if (get(record, "checkIn.time")) return (
    <Row align='middle'>
      <Col span={20}>
        <p>{formatTime(get(record, "checkIn.time"))}</p>
        {get(record, "checkOut.time") && <p>{formatTime(get(record, "checkOut.time"))}</p>}
      </Col>
      <Col span={4}>
        <PictureOutlined onClick={() => setIsOpen(true)} color='#3481FF' style={{ fontSize: '20px', color: '#3481FF' }} />
        <Modal
          visible={isOpen}
          onOk={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}
          footer={null}
        >
          <Row justify='center'>
            <Col span={24} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Space direction='vertical' size="large">
                <Space direction='vertical' size="small">
                  <Tag color="success" icon={<CheckCircleFilled />} style={{ display: "flex", alignItems: "center" }}>
                    Check in: {formatTime(get(record, "checkIn.time"))}
                  </Tag>
                  <Image width={200} src={get(record, "checkIn.picture")} style={{ marginLeft: "auto", marginRight: "auto" }} />
                  <Row justify='center' align='middle'>
                    <Button
                      type='link'
                      icon={
                        <LocationSvg
                          style={{ width: '16px', height: '16px', marginRight: '4px', marginBottom: '2px' }}
                        />
                      }
                      onClick={() => window.open(`https://maps.google.com/?q=${get(record, "checkIn.latitude")},${get(record, "checkIn.longitude")}`, "_blank")}
                    >
                      Vị trí check in
                    </Button>
                  </Row>
                </Space>

                {get(record, "checkOut.time") &&
                  <Space direction='vertical' size="small">
                    <Tag color="success" icon={<CheckCircleFilled />} style={{ display: "flex", alignItems: "center" }}>
                      Check out: {formatTime(get(record, "checkOut.time"))}
                    </Tag>
                    <Image width={200} src={get(record, "checkOut.picture")} style={{ marginLeft: "auto", marginRight: "auto" }} />
                    <Row justify='center' align='middle'>
                      <Button
                        type='link'
                        icon={
                          <LocationSvg
                            style={{ width: '16px', height: '16px', marginRight: '4px', marginBottom: '4px' }}
                          />
                        }
                        onClick={() => window.open(`https://maps.google.com/?q=${get(record, "checkOut.latitude")},${get(record, "checkOut.longitude")}`, "_blank")}
                      >
                        Vị trí check out
                      </Button>
                    </Row>
                  </Space>
                }
              </Space>
            </Col>
          </Row>
        </Modal>
      </Col>
    </Row>
  )
  return <></>
}

export default CheckInOut;
