import React, { useEffect } from 'react';

import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Skeleton, Space } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { get } from 'lodash';

import { filterAcrossAccents } from '~/hooks/utils';
import { useInviteWhPartner, useInitWhPartner, useResetWhPartner, useWhPartner } from '~/hooks/whPartner';
import { useSpecialities } from '~/hooks/speciality';
import { useStaffGroups } from '~/hooks/staffGroup';
import { useUserWorkspace } from '~/hooks';

import { Breadcrumb } from '../Common';
import { PATH_APP } from '~/routes/paths';

import "./WorkspaceInviteWhPartnerForm.scss";
import { toJSONInviteWhPartnerFormFieldsOnly } from '../WhPartner/parser';

const FormItem = Form.Item;
const { Option } = Select;

const mainRowGutter = 12;
// const mainColLayout = {
//   md: { span: 24 },
//   lg: { span: 12 },
//   xl: { span: 10 }
// };

const verticalLayout = {
  wrapperCol: { sm: 16, md: 16, lg: 18 },
  labelCol: { sm: 8, md: 8, lg: 6 }
};

const WorkspaceInviteWhPartnerForm = ({ }) => {
  useResetWhPartner();

  const [form] = Form.useForm();

  const [profile] = useUserWorkspace();
  const id = get(profile, "_id");

  // const [whPartner, isGetWhPartnerLoading] = useWhPartner(); // invite new partner so no need to put id in useWhPartner
  // const initWhPartner = useInitWhPartner(whPartner);
  const initWhPartner = {};

  const [specialities, isSpecialitiesLoading] = useSpecialities();
  const [staffGroups, isStaffGroupLoading] = useStaffGroups();

  const [isSubmitLoading, handleInvite] = useInviteWhPartner();

  // const isLoading = isGetWhPartnerLoading;
  const isLoading = false;

  // useEffect(() => {
  //   form.resetFields();
  // }, [initWhPartner, form]);
  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = (values) => {
    const newWhPartner = toJSONInviteWhPartnerFormFieldsOnly({
      ...values,
    });

    handleInvite({ id, newWhPartner })
  };
  const onValuesChange = () => { };

  return (
    <div className='page-wraper page-content page-content-workSpace'>
      <Breadcrumb
        // title={`${id ? 'Cập nhật' : 'Thêm'} nhân viên`}
        title={`${'Thêm'} nhân viên`}
        routes={[{ path: PATH_APP.whPartnerWorkspace.myWorkforce, title: 'Nhân sự của tôi' }]}
      />
      <Form
        className='workspace-invite-wh-partner-form'
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        scrollToFirstError
        requiredMark={false}
        initialValues={initWhPartner}
        // initialValues={mergedInitWhPartner}
        {...verticalLayout}
      >
        <Row
          gutter={mainRowGutter}
          align="middle"
          justify="space-between"
          className="workspace-invite-wh-partner-form__row"
        >
          <Col span={24}>
            <FormItem
              label="Tên đối tác"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên đối tác!',
                }
              ]}
              {...verticalLayout}
            >
              {isLoading ? <Skeleton.Input active /> : <Input />}
            </FormItem>
          </Col>
        </Row>

        <Row
          gutter={mainRowGutter}
          align="middle"
          justify="space-between"
          className="workspace-invite-wh-partner-form__row"
        >
          <Col span={24}>
            <FormItem
              label="Giới tính"
              name="gender"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn giới tính!',
                }
              ]}
              {...verticalLayout}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Select>
                  <Option value="M" key="male">
                    Nam
                  </Option>
                  <Option value="F" key="female">
                    Nữ
                  </Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row
          gutter={mainRowGutter}
          align="middle"
          justify="space-between"
          className="workspace-invite-wh-partner-form__row"
        >
          <Col span={24}>
            <FormItem
              label="Email"
              name="email"
              {...verticalLayout}
            >
              {isLoading ? <Skeleton.Input active /> : <Input />}
            </FormItem>
          </Col>
        </Row>
        <Row
          gutter={mainRowGutter}
          align="middle"
          justify="space-between"
          className="workspace-invite-wh-partner-form__row"
        >
          <Col span={24}>
            <FormItem
              label="Số điện thoại"
              name="phone"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/^[0-9]{10,11}$/),
                  message: 'Xin vui lòng nhập đúng số điện thoại!'
                }
              ]}
              {...verticalLayout}
            >
              {isLoading ? <Skeleton.Input active /> : <Input />}
            </FormItem>
          </Col>
        </Row>
        <Row
          gutter={mainRowGutter}
          align="middle"
          justify="space-between"
          className="workspace-invite-wh-partner-form__row"
        >
          <Col span={24}>
            <FormItem
              label="Nhóm nhân sự"
              name="employeeGroupId"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn nhóm nhân sự'
                }
              ]}
              {...verticalLayout}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  loading={isStaffGroupLoading}
                  showSearch
                  autoComplete="off"
                  filterOption={filterAcrossAccents}
                >
                  {staffGroups.map(({ id, _id, name }) => (
                    <Option key={id || _id} value={id || _id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row
          gutter={mainRowGutter}
          align="middle"
          justify="space-between"
          className="workspace-invite-wh-partner-form__row"
        >
          <Col span={24}>
            <FormItem
              label="Chuyên môn"
              name="specialityIds"
              {...verticalLayout}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  mode="multiple"
                  placeholder=""
                  style={{ width: '100%' }}
                  loading={isSpecialitiesLoading}
                  disabled={isSpecialitiesLoading}
                >
                  {specialities.map(({ name, id }) => (
                    <Option value={id} key={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row
          gutter={mainRowGutter}
          align="middle"
          justify="space-between"
          className="workspace-invite-wh-partner-form__row"
        >
          <Col span={24}>
            <FormItem
              label="Thông tin khác"
              name={['description']}
              className={`wh-service-form__description`}
              labelCol={{ sm: 24, md: 24, lg: 3 }}
              wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Input.TextArea rows={4} />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row className="workspace-invite-wh-partner-form__row" justify='end'>
          <Space>
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.whPartnerWorkspace.myWorkforce}>
                <Button>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {/* {id ? 'Cập nhật' : 'Tạo mới'} */}
              {'Tạo mới'}
            </Button>
          </Space>
        </Row>
      </Form>
    </div>
  )
}

export default WorkspaceInviteWhPartnerForm;
