import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createAdv,
  deleteAdv,
  getAdv,
  getAdvs,
  resetAdvState,
  updateAdv
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.adv[key];
const pagingSelector = getSelector('paging');
export const useAdvPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetAdvSelector = getSelector('isGetAdvLoading');
const getAdvsFailedSelector = getSelector('getAdvsFailed');
const getAdvFailedSelector = getSelector('getAdvFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const AdvsSelector = getSelector('Advs');
const AdvSelector = getSelector('Adv');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useAdvQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const createAdvSuccess = useSelector(createSuccessSelector);
  const updateAdvSuccess = useSelector(updateSuccessSelector);
  const deleteAdvSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createAdvSuccess,
    deleteAdvSuccess,
    updateAdvSuccess,
  ]);
};

export const useUpdateAdvParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.uiClient.adv,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};

export const useCreateAdv = (callback) => {
  
  useSuccess(createSuccessSelector, 'Tạo mới phần mềm thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới phần mềm thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createAdv
  });
};

export const useAdvs = (query) => {
  return useFetchByParam({
    action: getAdvs,
    loadingSelector,
    dataSelector: AdvsSelector,
    failedSelector: getAdvsFailedSelector,
    param: query
  });
};
export const useAdv = (id) => {
  return useFetchByParam({
    action: getAdv,
    loadingSelector: loadinggetAdvSelector,
    dataSelector: AdvSelector,
    failedSelector: getAdvFailedSelector,
    param: id
  });
};
export const useUpdateAdv = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât Ứng tuyển thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateAdv
  });
};
export const useDeleteAdv = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Ứng tuyển thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật Ứng tuyển thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteAdv
  });
};

export const useResetAdv = () => {
  useResetState(resetAdvState);
};
