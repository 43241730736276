import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getCustomersType,
  createCustomerType,
  deleteCustomerType,
  getCustomerType,
  resetCustomerTypeState,
  updateCustomerType
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const getSelector = key => state => state.customerType[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');


// const customersTypeLoadingSelector = getSelector('isCustomersTypeLoading');
// const customersTypeSelector = getSelector('customersType');
// const getCustomersTypeFailedSelector = getSelector('getCustomersTypeFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useCustomerTypePaging = () => useSelector(pagingSelector);

export const useCustomerTypeQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteCustomerTypeSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteCustomerTypeSuccess]);
};

export const useCustomersType = (query) => {
  return useFetchByParam({
    action: getCustomersType,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param : query
  });
};

export const useCreateCustomerType = (callback) => {
  const history = useHistory();
  // const callback = () => history.push('/wh-category');

  useSuccess(
    createSuccessSelector,
    'Tạo mới loại khách hàng thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới loại khách hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createCustomerType
  });
};

export const useUpdateCustomerType = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật loại khách hàng thành công',callback);
  useFailed(updateFailedSelector, 'Cập nhật loại khách hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateCustomerType
  });
};

export const useDeleteCustomerType = () => {
  useSuccess(deleteSuccessSelector, 'Xoá loại khách hàng thành công');
  useFailed(deleteFailedSelector, 'Xoá loại khách hàng thất bại');

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: deleteCustomerType
  });
};

export const useCustomerType = id => {
  return useFetchByParam({
    action: getCustomerType,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useUpdateCustomerTypeParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    history.push({
      pathname: '/customerType',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useInitCustomerType = customerType =>
  useMemo(() => {
    if (!customerType) {
      return {
        image: {
        }
      };
    }

    return customerType;
  }, [customerType]);

export const useResetCustomerType = () => {
  useResetState(resetCustomerTypeState);
};
