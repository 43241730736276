import { MessageOutlined, MessageTwoTone } from '@ant-design/icons';
import { Badge, Col, Row, Table, Tag } from 'antd'
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react'
import SkeletonTable from '~/components/Utils/SkeletonTable'
import { STATUS_PARTNER_CANCEL_BILL, TYPE_CANCEL_PARTNER } from '~/constants/defaultValue';

export default function TableListCancel({ dataSource, loading }) {
  const columns = [
    {
      title: 'Mã phiếu huỷ',
      dataIndex: 'requestNumberCancel',
      key: 'requestNumberCancel',
      render: (item, record) =>
        <Row  style={{flexDirection : 'column' , rowGap : 5}}>
          <Col  >
            <Text strong>{item}</Text>
          </Col>
          <Col  >
            {get(record, 'isLate') ? <Tag color='red'>Huỷ trễ</Tag> : <Tag color='blue'>Huỷ sớm</Tag>}
          </Col>
          <Col >
            <span  className={`request-status ${get(record, 'status', '')}`}>{STATUS_PARTNER_CANCEL_BILL[get(record, 'status', '')].vi}</span>
          </Col>
        </Row>
    },
    {
      title: 'Yêu cầu huỷ',
      dataIndex: 'type',
      key: 'type',
      align : 'center',
      width : '50%',
      render: (item, record) => {
        return <>
          {(item === TYPE_CANCEL_PARTNER.appointment.value) && <Text>Huỷ {TYPE_CANCEL_PARTNER.appointment.vi} <span >{get(record, 'whAppointment.code', '')}</span></Text>}
         {(item === TYPE_CANCEL_PARTNER.block.value) && <Text>Huỷ {TYPE_CANCEL_PARTNER.block.vi}{get(record, 'blockNumber')} <span>{get(record, 'whBillItem.code', '')}</span></Text>}
         {(item === TYPE_CANCEL_PARTNER.bill.value) && <Text>Huỷ {TYPE_CANCEL_PARTNER.bill.vi} <span>DV{get(record, 'whBill.billNumber', '')}</span></Text>}
          <Text style={{display : 'block'}} type='secondary'>{moment(get(record, 'createdAt')).format("DD-MM-YYYY HH:mm:ss")}</Text>
        </>
      
        
      }
    },
  ]
  const ExpandedRowRender = (record) => {
    return (<>
      <Text>Tin nhắn từ quản trị : {get(record, 'messageFromAdmin', '')}</Text>
      <Text type='secondary'> {get(record, 'dateApproved') && `(${moment(get(record, 'dateApproved')).format("DD-MM-YYYY HH:mm:ss")})`}</Text>
    </>)
  }
  return (
    <>
      {loading ? <SkeletonTable rowCount={5} columns={columns} /> : <Table rowKey={(rc) => rc._id}  expandable={{
        expandedRowRender: ExpandedRowRender,
        expandIcon: ({ expanded, onExpand, record }) => expanded ? (
          <MessageTwoTone style={{fontSize : '22px'}} onClick={e => onExpand(record, e)} />
        ) : (
          <Badge dot={!!get(record, 'messageFromAdmin')}>
            <MessageOutlined style={{fontSize : '22px'}} onClick={e => onExpand(record, e)} />
          </Badge>
        ),
        defaultExpandAllRows : true
      }} size='small' columns={columns} dataSource={dataSource} />}
    </>
  )
}
