import * as Types from '../../constants/actionTypes';

export const getStaffGroups = (query) => ({
  type: Types.GET_STAFF_GROUPS_REQUEST,
  payload: query
});

export const getStaffGroup = id => ({
  type: Types.GET_STAFF_GROUP_REQUEST,
  payload: id
});

export const createStaffGroup = staffGroup => ({
  type: Types.CREATE_STAFF_GROUP_REQUEST,
  payload: staffGroup
});


export const updateStaffGroup = staffGroup => ({
  type: Types.UPDATE_STAFF_GROUP_REQUEST,
  payload: staffGroup
});


export const deleteStaffGroup = id => ({
  type: Types.DELETE_STAFF_GROUP_REQUEST,
  payload: id
});

export const resetStaffGroupState = () => ({
  type: Types.RESET_STAFF_GROUP_STATE
});

