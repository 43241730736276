import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getWallets({ payload: query }) {
    try {
      const response = yield call(Api.wallet.getOneUser, query);
      yield put({ type: Types.GET_WALLETS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WALLETS_FAILED, payload: error });
    }
  }
export default function* Wallets() {
    yield takeLatest(Types.GET_WALLETS_REQUEST, getWallets);
  }
