import produce from 'immer';
import * as Types from '../../constants/actionTypes';
import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  isLoadingCategory: false,
  isLoadingAuthor: false,
  getListFailed: undefined,
  list: [],
  listCategory:[],
  listAuthor: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  isSubmitLoadingCategory: false,
  isSubmitLoadingAuthor: false,
  isSubmitIdLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  updateIdSuccess: null,
  updateIdFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_NEWSES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_NEWSES_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_NEWSES_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      state.list = [];
      return;

    case Types.GET_NEWS_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_NEWS_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_NEWS_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_NEWS_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_NEWS_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_NEWS_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_NEWS_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_NEWS_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_NEWS_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.UPDATE_NEWS_ID_REQUEST:
      state.isSubmitIdLoading = true;
      state.updateIdSuccess = null;
      state.updateIdFailed = null;
      return;

    case Types.UPDATE_NEWS_ID_SUCCESS:
      state.isSubmitIdLoading = false;
      state.updateIdSuccess = payload;
      let findIndexItemChange = state.list.findIndex(({_id})=>_id===payload._id)
      if(findIndexItemChange!==-1){
        state.list[findIndexItemChange] = {...state.list[findIndexItemChange] ,...payload};
      }
      return;

    case Types.UPDATE_NEWS_ID_FAILED:
      state.isSubmitIdLoading = false;
      state.updateIdFailed = payload;
      return;

    case Types.DELETE_NEWS_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_NEWS_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_NEWS_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

      //search Category
    case Types.FETCH_SEARCH_CATEGORY_REQUEST:
      state.isLoadingCategory = true;
      state.isSubmitLoadingCategory =true;
      return;

    case Types.FETCH_SEARCH_CATEGORY_SUCCESS:
      state.isLoadingCategory = false;
      state.listCategory = payload;
      return;

    case Types.FETCH_SEARCH_CATEGORY_FAILED:
      state.isLoadingCategory = false;
      state.isSubmitLoadingCategory = false;
      return;
      //search author
    case Types.FETCH_SEARCH_AUTHOR_REQUEST:
      state.isLoadingAuthor = true;
      state.isSubmitLoadingAuthor =true;
      return;

    case Types.FETCH_SEARCH_AUTHOR_SUCCESS:
      
      state.listAuthor = payload;
      state.isLoadingAuthor = false;
      return;

    case Types.FETCH_SEARCH_AUTHOR_FAILED:
      state.isLoadingAuthor = false;
      state.isSubmitLoadingAuthor = false;
      return;

    case Types.RESET_NEWS_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
