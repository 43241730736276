import requester from './requester';

const whCategory = {
  getAll: (query) => requester.get('/wh-category', query),
  getAllCategory: (query) => requester.get('/wh-category-all-dashboard', query),
  getById: (id) => requester.get(`/wh-category/${id}?raw=true`),
  delete: (id) => requester.delete(`/wh-category/${id}`),
  create: (whCategory) => requester.post('/wh-category', whCategory),
  getWhCategoryAuthen: (body) => requester.post('/wh-service-branch/wh-category',body),
  update: (whCategory) => {
    return requester.put(`/wh-category/${whCategory.id}`, whCategory);
  },
  getWhCategoryByAuthenByPermission: (query) => requester.get('/wh-category-authen', query),
}; 

export default whCategory;
