import * as Types from '../../constants/actionTypes';

export const getServicePlaces = (query) => {
  return {
    type: Types.GET_SERVICE_PLACES_REQUEST,
    payload: query
  };
};

export const getServicePlace = (id) => ({
  type: Types.GET_SERVICE_PLACE_REQUEST,
  payload: id
});

export const createServicePlace = (id) => ({
  type: Types.CREATE_SERVICE_PLACE_REQUEST,
  payload: id
});

export const updateServicePlace = (id) => ({
  type: Types.UPDATE_SERVICE_PLACE_REQUEST,
  payload: id
});

export const deleteServicePlace = (id) => ({
  type: Types.DELETE_SERVICE_PLACE_REQUEST,
  payload: id
});

export const resetServicePlaceState = () => ({
  type: Types.RESET_SERVICE_PLACE_STATE
});
