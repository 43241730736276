import { Slider , Form } from 'antd'
import React from 'react'
import { useEffect } from 'react'
// import Form from '~/components/Appointment/Form'

function FormSize({form,setVSetting,vSetting}) {
    useEffect(()=>{
        form.setFieldsValue(vSetting)
    },[form,vSetting])
    return (
        <Form
            form={form}
            onValuesChange={(e) => {
                setVSetting((value) =>{
                    const  ky = Object.keys(e)[0]
                   return {...value, [ky]: { ...value[ky] ,...e[ky]}}})
             }}
            layout='horizontal'

        >
            <Form.Item label={'v0'} style={{ width: '175px' }} name={['v0','size']}><Slider min={80} max={160} /></Form.Item>
            <Form.Item label={'v1'} style={{ width: '175px' }} name={['v1','size']}><Slider min={150} max={250} /></Form.Item>
            <Form.Item label={'v2'} style={{ width: '175px' }} name={['v2','size']}><Slider min={250} max={400} /></Form.Item>
            <Form.Item label={'v3'} style={{ width: '175px' }} name={['v3','size']}><Slider min={400} max={600} /></Form.Item>
        </Form>
    )
}

export default FormSize
