import requester from './requester';

const PointSystem = {
  getByIdPartner: (query) => requester.get(`/point-system/calculatorPartner/${query.id}`,query),
  getById: (id) => requester.get(`/point-system/${id}`),
  create: (config) => requester.post(`/point-system`, config),
  update:(config) => requester.put(`/point-system/${config._id}`,config),
  getAll : (query) => requester.get(`/point-system`,query),
  delete: (id) => requester.delete(`/point-system/${id}`),
  getPointSystemCustomerById: id => requester.get(`/point-system/calculatorCustomer/${id}`),
};

export default PointSystem;
