import * as Types from '../../constants/actionTypes';
  
  export const getUIs = query => ({
    type: Types.GET_UIS_REQUEST,
    payload: query
  });
  export const updateUI = instance => ({
    type: Types.UPDATE_UI_REQUEST,
    payload: instance
  });
  export const resetUIState = () => ({
    type: Types.RESET_UI_STATE
  });
