import { CheckCircleTwoTone } from '@ant-design/icons'
import { Col, Row } from 'antd'
import Text from 'antd/lib/typography/Text'
import { get } from 'lodash'
import { formatNumberThreeComma } from '~/hooks/utils'
import RewardItem from './RewardItem'
export default function ItemProcessWorth({process,layout}) {
  const valueCondition = get(process, 'condition.value.keyValue')
  const count = get(process, 'countProcess',0)
  return (
    <Row justify='space-between' gutter={16}>
            <Col {...layout.left}>
            <Text strong>{get(process, 'name', '')}</Text>
            <RewardItem process={process} />
            </Col>
            <Col {...layout.right}>
              {(count >= valueCondition && count > 0) ?<CheckCircleTwoTone twoToneColor="#52c41a" /> :   <Text>{formatNumberThreeComma(get(process, 'countProcess',0))}/{formatNumberThreeComma(valueCondition)}</Text>}
            </Col>
        </Row>
  )
}
