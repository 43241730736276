import React from 'react';

import Icon from '@ant-design/icons';
import { Row, Space } from 'antd';
import { get } from 'lodash';

import ServiceSvg from '~/components/WhPartner/ServiceSvg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const WhBillItemServiceInfo = ({
  whBill,
  whBillItem,
}) => {
  const history = useHistory()
  const billNumber = get(whBillItem, "whBill.billNumber") || get(whBill, "billNumber")
  const nameService = (whBillItem) =>{
    if (get(whBillItem, "snapshotService.detailName.vi"))
          return `${get(whBillItem, "snapshotService.code")} - 
          ${get(whBillItem, "snapshotService.name.vi")} - 
          ${get(whBillItem, "snapshotService.detailName.vi")} : `
        else 
          return `${get(whBillItem, "snapshotService.code")} - 
          ${get(whBillItem, "snapshotService.name.vi")} : `
  }
  return (
    <>
      <Row align='middle'>
        <Space>
          <Icon component={ServiceSvg} />
          <h2 className=''>
            {nameService(whBillItem)}
          </h2>
          <h2 style={{cursor : 'pointer' , color : '#1890FF'}} onClick={() => history.push({
            pathname : '/wh-bill',
            search : `?page=1&limit=10&keyword=${billNumber}&withBillItems=true`
          })}>{billNumber}</h2>
        </Space>
      </Row>
    </>
  )
}

export default WhBillItemServiceInfo;
