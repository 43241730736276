import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import {
  DELETE_WH_PARTNER_REQUEST,
  DELETE_WH_PARTNER_SUCCESS,
  DELETE_WH_PARTNER_FAILED,
  GET_WH_PARTNERS_REQUEST,
  GET_WH_PARTNERS_SUCCESS,
  GET_WH_PARTNERS_FAILED,
  CREATE_WH_PARTNER_REQUEST,
  CREATE_WH_PARTNER_SUCCESS,
  CREATE_WH_PARTNER_FAILED,
  INVITE_WH_PARTNER_REQUEST,
  INVITE_WH_PARTNER_SUCCESS,
  INVITE_WH_PARTNER_FAILED,
  GET_WH_PARTNER_REQUEST,
  GET_WH_PARTNER_SUCCESS,
  GET_WH_PARTNER_FAILED,
  UPDATE_WH_PARTNER_REQUEST,
  UPDATE_WH_PARTNER_SUCCESS,
  UPDATE_WH_PARTNER_FAILED,
  APPROVE_WH_PARTNER_REQUEST,
  APPROVE_WH_PARTNER_SUCCESS,
  APPROVE_WH_PARTNER_FAILED,
  UPDATE_WH_PARTNER_TIPS_REQUEST,
  UPDATE_WH_PARTNER_TIPS_SUCCESS,
  UPDATE_WH_PARTNER_TIPS_FAILED,
  CREATE_WH_PARTNER_SERVICE_REQUEST,
  CREATE_WH_PARTNER_SERVICE_SUCCESS,
  CREATE_WH_PARTNER_SERVICE_FAILED,
  DELETE_WH_PARTNER_SERVICE_SUCCESS,
  DELETE_WH_PARTNER_SERVICE_FAILED,
  DELETE_WH_PARTNER_SERVICE_REQUEST,
  UPDATE_WH_PARTNER_SERVICE_REQUEST,
  UPDATE_WH_PARTNER_SERVICE_FAILED,
  UPDATE_WH_PARTNER_SERVICE_SUCCESS,
  GET_WH_PARTNERS_FOR_REPORT_REQUEST,
  GET_WH_PARTNERS_FOR_REPORT_SUCCESS,
  GET_WH_PARTNERS_FOR_REPORT_FAILED,
  UPDATE_WORKING_TIME_WH_PARTNER_SUCCESS,
  UPDATE_WORKING_TIME_WH_PARTNER_FAILED,
  UPDATE_WORKING_TIME_WH_PARTNER_REQUEST,
  CONVERT_TO_PARTNER_SUCCESS,
  CONVERT_TO_PARTNER_FAILED,
  CONVERT_TO_PARTNER_REQUEST
} from '../../constants/actionTypes';

function* getWhPartners({ payload: query }) {
  try {
    const response = yield call(Api.whPartner.getWhPartnersReport, query);
    yield put({ type: GET_WH_PARTNERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_PARTNERS_FAILED, payload: error.message });
  }
}
function* getWhPartnersReport({ payload: query }) {
  try {
    const response = yield call(Api.whPartner.getWhPartnersReport, query);
    yield put({ type: GET_WH_PARTNERS_FOR_REPORT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_PARTNERS_FOR_REPORT_FAILED, payload: error.message });
  }
}

function* getWhPartner({ payload: id }) {
  try {
    const whPartner = yield call(Api.whPartner.getById, id);
    yield put({ type: GET_WH_PARTNER_SUCCESS, payload: whPartner });
  } catch (error) {
    yield put({ type: GET_WH_PARTNER_FAILED, payload: error.message });
  }
}

function* createWhPartner(action) {
  try {
    const data = yield call(Api.whPartner.create, action.payload);
    yield put({ type: CREATE_WH_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_WH_PARTNER_FAILED, payload: error });
  }
}

function* createWhPartnerService(action) {
  try {
    const data = yield call(Api.whPartner.createService, action.payload);
    yield put({ type: CREATE_WH_PARTNER_SERVICE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_WH_PARTNER_SERVICE_FAILED, payload: error.message });
  }
}

function* inviteWhPartner(action) {
  try {
    const data = yield call(Api.whPartner.invite, action.payload);
    yield put({ type: INVITE_WH_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: INVITE_WH_PARTNER_FAILED, payload: error });
  }
}

function* updateWhPartner(action) {
  try {
    const data = yield call(Api.whPartner.update, action.payload);
    yield put({ type: UPDATE_WH_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_PARTNER_FAILED, payload: error });
  }
}

function* updateWorkingTimeWhPartner(action) {
  try {
    const data = yield call(Api.whPartner.updateWorkingTime, action.payload);
    yield put({ type: UPDATE_WORKING_TIME_WH_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WORKING_TIME_WH_PARTNER_FAILED, payload: error });
  }
}

function* updateWhPartnerTips(action) {
  try {
    const data = yield call(Api.whPartner.updateTips, action.payload);
    yield put({ type: UPDATE_WH_PARTNER_TIPS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_PARTNER_TIPS_FAILED, payload: error });
  }
}
function* updateWhPartnerService(action) {
  try {
    const data = yield call(Api.whPartner.updateService, action.payload);
    yield put({ type: UPDATE_WH_PARTNER_SERVICE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_PARTNER_SERVICE_FAILED, payload: error });
  }
}

function* approveWhPartner(action) {
  try {
    // firstly save the partner
    const response = yield call(Api.whPartner.update, action.payload);
    yield put({ type: UPDATE_WH_PARTNER_SUCCESS, payload: response });

    // then approve the partner
    const data = yield call(Api.whPartner.approve, action.payload);
    yield put({ type: APPROVE_WH_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: APPROVE_WH_PARTNER_FAILED, payload: error });
  }
}

function* deleteHopital({ payload }) {
  try {
    yield call(Api.whPartner.delete, payload);
    yield put({ type: DELETE_WH_PARTNER_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_WH_PARTNER_FAILED, payload: error.message });
  }
}
function* deleteService({ payload }) {
  try {
    yield call(Api.whPartner.deleteService, payload);
    yield put({ type: DELETE_WH_PARTNER_SERVICE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_WH_PARTNER_SERVICE_FAILED, payload: error });
  }
}

function* convertToPartner(action) {
  try {
    const data = yield call(Api.whPartner.convertToPartner, action.payload);
    yield put({ type: CONVERT_TO_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CONVERT_TO_PARTNER_FAILED, payload: error });
  }
}

export default function* whPartner() {
  yield takeLatest(GET_WH_PARTNERS_REQUEST, getWhPartners);
  yield takeLatest(GET_WH_PARTNERS_FOR_REPORT_REQUEST, getWhPartnersReport);
  yield takeLatest(GET_WH_PARTNER_REQUEST, getWhPartner);
  yield takeLatest(DELETE_WH_PARTNER_REQUEST, deleteHopital);
  yield takeLatest(DELETE_WH_PARTNER_SERVICE_REQUEST, deleteService);
  yield takeLatest(CREATE_WH_PARTNER_REQUEST, createWhPartner);
  yield takeLatest(INVITE_WH_PARTNER_REQUEST, inviteWhPartner);
  yield takeLatest(UPDATE_WH_PARTNER_REQUEST, updateWhPartner);
  yield takeLatest(UPDATE_WH_PARTNER_TIPS_REQUEST, updateWhPartnerTips);
  yield takeLatest(UPDATE_WH_PARTNER_SERVICE_REQUEST, updateWhPartnerService);
  yield takeLatest(APPROVE_WH_PARTNER_REQUEST, approveWhPartner);
  yield takeLatest(CREATE_WH_PARTNER_SERVICE_REQUEST, createWhPartnerService);
  yield takeLatest(UPDATE_WORKING_TIME_WH_PARTNER_REQUEST, updateWorkingTimeWhPartner);
  yield takeLatest(CONVERT_TO_PARTNER_REQUEST, convertToPartner);
}
