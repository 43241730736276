import { CopyTwoTone, ExclamationCircleOutlined, MenuFoldOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, Divider, Form, Input, Modal, Radio, Row, Select, Skeleton, Space, Table, Tabs, Tag, Typography } from 'antd'
import { get, keys, omit } from 'lodash';
import React, { useEffect, useState } from 'react'
import { CONFIG_CAMPAIGN_ACTIVITY_ALL, CONFIG_CAMPAIGN_CONDITION_VI } from '~/constants/defaultValue';
import { useOptionsSelectAntd } from '~/hooks/utils';
import { StringToSlug } from '~/utils/helper';
import toastr from 'toastr'
import { useActivityReferral, useCreateActivityReferral, useUpdateActivityReferral } from '~/hooks/activityReferral';
import Text from 'antd/lib/typography/Text';
import TableSubConditon from './TableSubConditon';
const listSubCondition = [
  {
    value : "Số ngày chậm",
    typeValue : "Số",
    activesUse : [CONFIG_CAMPAIGN_ACTIVITY_ALL['THIEU-UY-TIN-TRONG-THANH-TOAN'].vi]
  },
  {
    value : "Giá trị phiếu tối thiểu",
    typeValue : "Số",
    activesUse : [CONFIG_CAMPAIGN_ACTIVITY_ALL['THIEU-UY-TIN-TRONG-THANH-TOAN'].vi]
  },
  {
    value : "Số đối tác thực hiện đơn",
    typeValue : "Số",
    activesUse : [CONFIG_CAMPAIGN_ACTIVITY_ALL['DOI-TAC-HOAN-THANH-DON-HANG'].vi]
  },
]
export default function FormAddActivity({handleCloseAddActivity,id}) {
  const [form] = Form.useForm()
  const [oldCode,setOldCode] = useState(null)
  const [isOpenModalConfirm,setIsOpenModalConform] = useState(false)
  const [isOpenModalListSubcondition,setIsOpenModalListSubcondition] = useState(false)
  const handleClose = () => {
    handleCloseAddActivity()
    setIsOpenModalConform(false)
    form.resetFields()
  }
  // HOOK ACTIVITY //
  const [ActivityReferral,loading] = useActivityReferral(id)
  const [loadingSubmitActivity,createActivity] = useCreateActivityReferral(handleClose)
  const [,updateActivity] = useUpdateActivityReferral(handleClose)
   // HOOK ACTIVITY //
    const optionsTypeCondition = useOptionsSelectAntd(
        CONFIG_CAMPAIGN_CONDITION_VI
      );
      useEffect(() => {
        form.resetFields()
        if(!!id){
          form.setFieldsValue({
           ...ActivityReferral,
          })
          setOldCode(get(ActivityReferral,'value'))
        }
      },[id,ActivityReferral])
      const onUpdateActivity = async (approve) => {
        await form.validateFields()
        const fullValues = form.getFieldsValue(true);
        if(approve){
          updateActivity({...fullValues,_id:id})
        }
        else{
          updateActivity({...omit(fullValues,'value'),_id:id})
        }
        
      };
      const handleOpenModalConfirm = () => {
        setIsOpenModalConform(true)
    }
    const handleCloseModalConfirm = () => {
      setIsOpenModalConform(false)
    }
      const handleOpenModalListSubCondtion = () => {
        setIsOpenModalListSubcondition(true)
    }
      const handleCloseModalListSubCondtion = () => {
        setIsOpenModalListSubcondition(false)
    }
    const onFinish = (values) => {
      const {value} = values;
      const subCondition = get(values,'subCondition',[]).map((e,i) => ({...e,key:`${value}_sub_${i+1}`}))
      const submitData = {...values,subCondition}
      if(id){
        if(value === oldCode){
          updateActivity({...submitData,_id:id})
        }
        else{
          handleOpenModalConfirm()
         
        }
       
       
      }
      else{
        createActivity(submitData)
      }
      
    }
    const handleCopyCode = () => {
        navigator.clipboard.writeText(form.getFieldValue(['key']));
        toastr.success('Đã copy');
      };

    const onValuesChange = (valueChange,AllValue) => {
        const keyChange = keys(valueChange)[0];
        const value = valueChange[keyChange];
        if(keyChange === 'vi'){
            const slug = StringToSlug(value)
            form.setFieldsValue({value : slug.toUpperCase()})
        }
    }
    const renderInput = InputComponent =>
    loading ? <Skeleton.Input active /> : InputComponent; 
  return (
   <>
     <Form
    className='form-add-activity'
    form={form}
    onFinish={onFinish}
    onValuesChange={onValuesChange}
    >
    <Form.Item name='vi' label='Tên hoạt động'>
    {renderInput(<Input />)}
    </Form.Item>
    <Form.Item shouldUpdate={(prevValue,curValue) => prevValue?.vi !== curValue?.vi} noStyle>
    {() => <Form.Item name='value' label='Mã hoạt động'>
    {renderInput(<Input  disabled/>)}
    </Form.Item>}
    </Form.Item>
    <Form.Item label="Loại điều kiện" name={'typeCondition'}>
         {renderInput( <Radio.Group
            optionType="button"
            options={optionsTypeCondition}
          />)}
      </Form.Item>
      <Divider>Điều kiện phụ</Divider>
      <Button style={{margin : '10px 0'}} onClick={handleOpenModalListSubCondtion}><Row align='middle'><MenuFoldOutlined style={{marginRight : 5}}/> <span>Danh sách điều kiện phụ</span></Row></Button>
      <Form.List name="subCondition">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            renderInput(<Space
              key={key}
              style={{
                display: 'flex',
                marginBottom: 8,
                width : '100%',
                // justifyContent : 'space-between',
              }}
              align='baseline'
              
            >
              <Form.Item
                {...restField}
                name={[name, 'desc']}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập tiêu đề điều kiện',
                  },
                ]}
              >
                {/* {<Input style={{width : '300px'}} placeholder="Tiêu đề điều kiện" />} */}
                {<AutoComplete style={{width : '300px'}}  options={listSubCondition}
    filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }/>}
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'typeValue']}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn loại giá trị',
                  },
                ]}
              >
                <Select placeholder='Loại giá trị' style={{width : '150px'}} options={[
                    {label : 'ký tự',value : 'String'},
                    {label : 'Số',value : 'Number'},
                ]}/>
              </Form.Item>
              {/* <Form.Item
                {...restField}
                name={[name, 'value']}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập giá trị',
                  },
                ]}
              >
                <Input placeholder="Giá trị" />
              </Form.Item> */}
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>)
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Thêm điều kiện phụ
            </Button>
          </Form.Item>
        </>
      )}
    
    </Form.List>
    <Button loading={loadingSubmitActivity} block htmlType='submit' type='primary'>Xác nhận</Button>
    </Form>
    <Divider>Có thể bạn chưa biết</Divider>
    <Tabs defaultActiveKey='1'>
        <Tabs.TabPane tab='Loại điều kiện' key='1'>
          <Row>
            <Col  span={24}>
              <Row>
                <Col span={6}>
                  <Tag>Khoảng</Tag>
                </Col>
                <Col flex={1}>
                  <Text strong>Ví dụ sử dụng dịch vụ từ 10 triệu {"<"} x {"<"} 20 triệu</Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <Tag>Xảy ra 1 lần</Tag>
                </Col>
                <Col flex={1}>
                  <Text strong>Ví dụ : Xác thực khuôn mặt</Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <Tag>Giá trị chính xác</Tag>
                </Col>
                <Col flex={1}>
                  <Text strong>Ví dụ Thiếu uy tín trong thanh toán 2 lần</Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <Tag>Thời gian Lặp lại</Tag>
                </Col>
                <Col flex={1}>
                  <Text strong>Ví dụ tặng quà ngày 8-3 mỗi năm</Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <Tag>Hành động lặp lại</Tag>
                </Col>
                <Col flex={1}>
                  <Text strong>Ví dụ Lặp lại sau mỗi lần đăng ký dịch vụ</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab='Điều kiện phụ' key='2'>
          <Text style={{marginBottom : '1rem'}} strong>Điều kiện phụ là điều kiện kết hợp với điều kiện chính ví dụ : <br/></Text>
          <Text style={{marginBottom : '1rem'}} strong>Thiếu uy tín trong thanh toán ở đây điều kiện phụ chính là số ngày chậm thanh toán để xác định đó là thiếu uy tín trong thanh toán </Text>
          <Text style={{marginBottom : '1rem'}} strong>Đối tác hoàn thành đơn hàng điều kiện phụ là số đối tác thực hiện đơn </Text>
        </Tabs.TabPane> */}
      </Tabs>
    <Row>
      <Col span={24}>

      </Col>
    </Row>
    <Modal width={600} visible={isOpenModalConfirm} onCancel={handleCloseModalConfirm} footer={null} title= {`Chú ý`}>
    <Typography.Title level={5}>Bạn có muốn chỉnh sửa lại mã hoạt động (Lưu ý : nếu mã không đúng sẽ không sử dụng được)?</Typography.Title>
          <Button onClick={handleCloseModalConfirm}>Huỷ</Button>
          <Button onClick={() => onUpdateActivity(true)} type='primary'>Tôi muốn đổi mã hoạt động</Button>
          <Button onClick={() => onUpdateActivity(false)} type='dashed'>Tôi không muốn đổi mã hoạt động</Button>
    </Modal>
    <Modal width={600} visible={isOpenModalListSubcondition} onCancel={handleCloseModalListSubCondtion} footer={null} title= {`Danh sách điều kiện phụ`}>
<TableSubConditon dataSource={listSubCondition}/>
    </Modal>
   </>
  )
}
