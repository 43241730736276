import { Modal } from 'antd'
import React, { useMemo } from 'react'
import { useWhReportCustomer } from '~/hooks/whReportCustomer'
import TableDidServiceCustomer from './TableDidServiceCustomer'

export default function ModalDetailCustomer({date,isModalDetailCustomerOpen,setIsModalDetailCustomerOpen,idCustomer,setInitDate}) {
    const handleCancel = () => {
      setInitDate()
        setIsModalDetailCustomerOpen(false)
    }
    const query = useMemo(() => idCustomer ? ({customerId:idCustomer,...date}) : null,[date,idCustomer])
    const [whReportCustomer,loading] = useWhReportCustomer(query)
  return (
    <Modal destroyOnClose title="Chi tiết" width="90vw" visible={isModalDetailCustomerOpen} footer={[]} onCancel={handleCancel} closable={false}>
        <div className="container-fluid">
        <TableDidServiceCustomer whReportCustomer={whReportCustomer} loading={loading}/>
        </div>
      </Modal>
  )
}
