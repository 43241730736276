import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getCoreValues({ payload: query }) {
    try {
      const response = yield call(Api.coreValue.getAll, query);
      yield put({ type: Types.GET_CORE_VALUES_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_CORE_VALUES_FAILED, payload: error });
    }
  }
function* getCoreValue({ payload: id }) {
    try {
      const response = yield call(Api.coreValue.getById, id);
      yield put({ type: Types.GET_CORE_VALUE_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_CORE_VALUE_FAILED, payload: error });
    }
  }
  function* updateCoreValue(action) {
    try {
      const data = yield call(Api.coreValue.update, action.payload);
      yield put({ type: Types.UPDATE_CORE_VALUE_SUCCESS, payload: data });
      yield put({ type: Types.RESET_CORE_VALUE_ACTION});
    } catch (error) {
      yield put({ type: Types.UPDATE_CORE_VALUE_FAILED, payload: error });
    }
  }
  function* createCoreValue(action) {
    try {
      const data = yield call(Api.coreValue.create, action.payload);
      yield put({ type: Types.CREATE_CORE_VALUE_SUCCESS, payload: data });
      yield put({ type: Types.RESET_CORE_VALUE_ACTION});
    } catch (error) {
      yield put({ type: Types.CREATE_CORE_VALUE_FAILED, payload: error });
    }
  }
  function* deleteCoreValue(action) {
    try {
      const data = yield call(Api.coreValue.delete, action.payload);
      yield put({ type: Types.DELETE_CORE_VALUE_SUCCESS, payload: data });
      yield put({ type: Types.RESET_CORE_VALUE_ACTION});
    } catch (error) {
      yield put({ type: Types.DELETE_CORE_VALUE_FAILED, payload: error });
    }
  }
export default function* CoreValues() {
    yield takeLatest(Types.GET_CORE_VALUES_REQUEST, getCoreValues);
    yield takeLatest(Types.GET_CORE_VALUE_REQUEST, getCoreValue);
    yield takeLatest(Types.UPDATE_CORE_VALUE_REQUEST, updateCoreValue);
    yield takeLatest(Types.CREATE_CORE_VALUE_REQUEST, createCoreValue);
    yield takeLatest(Types.DELETE_CORE_VALUE_REQUEST, deleteCoreValue);
  }
