export const CASE_UPDATE = {
  isFeatured :'isFeatured',
  status :'status',
  highlight :'highlight',
}

export const stylesheetNews ={
  colorRed:{color:'red'} ,
  colorGreen:{color:'green'},
  colorBlue:{color:'blue'},
  colorOrange:{color:'orange'}
}

export const optionsNewsStatus = [
  { label: 'Tất cả', value: 'PUBLISHED,PRIVATE,EDITING' },
  { label: <span style={stylesheetNews.colorGreen}>Công khai</span> , value: 'PUBLISHED' },
  { label:  <span style={stylesheetNews.colorBlue}>Không công khai</span> , value: 'PRIVATE' },
  { label: <span style={stylesheetNews.colorRed}>Đang sửa</span>, value: 'EDITING' },
];
export const optionsNewsIsFeatured = [
  { label: 'Tất cả', value: '' },
  { label: <span style={stylesheetNews.colorGreen}>Nổi bật</span>, value: 'true' },
  { label: 'Không Nổi bật', value: 'false' },
];
export const optionsDateNews = [
  { label: 'Lọc theo ngày', value: '' },
  { label: 'Lọc theo tuần', value: 'week' },
  { label: 'Lọc theo tháng', value: 'month' },
  { label: 'Lọc theo quý', value: 'quarter' },
  { label: 'Lọc theo năm', value: 'year' },
  { label: 'Lọc theo Khoảng ngày', value: 'ranger' },
];
