import { get } from "lodash";

// require('dotenv/config');
const devConfigJson = '{ "REACT_APP_DOMAIN": "http://localhost:3000" }';

let config = {};
try {
  config = JSON.parse(devConfigJson);
  // console.log(config);
} catch { }

export const devconfig = {
  appDomain: get(process.env, 'REACT_APP_DOMAIN', 'config.REACT_APP_DOMAIN'),
};

// export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
// export const GOOGLE_MAPS_API_KEY = 'REACT_APP_GOOGLE_MAPS_API_KEY';
export const HERE_MAPS_API_KEY =  get(process.env, 'REACT_APP_HERE_MAPS_API_KEY' , 'REACT_APP_HERE_MAPS_API_KEY') ;
