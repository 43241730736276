import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getAppointmentsAssociate({ payload: query }) {
  try {
    const data = yield call(Api.appointmentAssociate.getAll, query);
    yield put({ type: Types.GET_APPOINTMENTS_ASSOCIATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_APPOINTMENTS_ASSOCIATE_FAILED, payload: error.message });
  }
}

function* getAppointmentAssociate({ payload: id }) {
  try {
    const data = yield call(Api.appointmentAssociate.getById, id);
    yield put({ type: Types.GET_APPOINTMENT_ASSOCIATE_REQUEST, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_APPOINTMENT_ASSOCIATE_REQUEST, payload: error.message });
  }
}

function* createAppointmentAssociate(action) {
  try {
    const data = yield call(Api.appointmentAssociate.create, action.payload);
    yield put({ type: Types.CREATE_APPOINTMENT_ASSOCIATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_APPOINTMENT_ASSOCIATE_FAILED,
      payload: error.message
    });
  }
}

function* updateAppointmentAssociate(action) {
  try {
    const data = yield call(Api.appointmentAssociate.update, action.payload);
    yield put({ type: Types.UPDATE_APPOINTMENT_ASSOCIATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_APPOINTMENT_ASSOCIATE_FAILED,
      payload: error.message
    });
  }
}
function* deleteAppointmentAssociate({ payload }) {
  try {
    yield call(Api.appointmentAssociate.delete, payload);
    yield put({ type: Types.DELETE_APPOINTMENT_ASSOCIATE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_APPOINTMENT_ASSOCIATE_FAILED,
      payload: error.message
    });
  }
}

export default function* appointmentAssociate() {
  yield takeLatest(Types.GET_APPOINTMENTS_ASSOCIATE_REQUEST, getAppointmentsAssociate);
  yield takeLatest(Types.GET_APPOINTMENT_ASSOCIATE_REQUEST, getAppointmentAssociate);
  yield takeLatest(Types.CREATE_APPOINTMENT_ASSOCIATE_REQUEST, createAppointmentAssociate);
  yield takeLatest(Types.UPDATE_APPOINTMENT_ASSOCIATE_REQUEST, updateAppointmentAssociate);
  yield takeLatest(Types.DELETE_APPOINTMENT_ASSOCIATE_REQUEST, deleteAppointmentAssociate);
}
