import * as Types from '../../constants/actionTypes';
  
  export const getWarehouseVouchers = query => ({
    type: Types.GET_WAREHOUSE_VOUCHERS_REQUEST,
    payload: query
  });
  export const getWarehouseVouchersOfPartner = query => ({
    type: Types.GET_WAREHOUSE_VOUCHERS_PARTNER_REQUEST,
    payload: query
  });
  export const getWarehouseVoucher = id => ({
    type: Types.GET_WAREHOUSE_VOUCHER_REQUEST,
    payload: id
  });
  export const getWarehouseVoucherOfPartner = id => ({
    type: Types.GET_WAREHOUSE_VOUCHER_PARTNER_REQUEST,
    payload: id
  });
  export const partnerConfirmVoucherWarehouse = (id) => ({
    type: Types.PARTNER_CONFIRM_WAREHOUSE_VOUCHER_REQUEST,
    payload : id
  });
  export const partnerRejectVoucherWarehouse = (id) => ({
    type: Types.PARTNER_REJECT_WAREHOUSE_VOUCHER_REQUEST,
    payload : id
  });
  export const employeeConfirmVoucherWarehouse = (id) => ({
    type: Types.EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_REQUEST,
    payload : id
  });
  export const employeeRejectVoucherWarehouse = (id) => ({
    type: Types.EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_REQUEST,
    payload : id
  });

  export const supplierConfirmVoucherWarehouse = (id) => ({
    type: Types.SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_REQUEST,
    payload : id
  });
  export const supplierRejectVoucherWarehouse = (id) => ({
    type: Types.SUPPLIER_REJECT_WAREHOUSE_VOUCHER_REQUEST,
    payload : id
  });

  export const createWarehouseVoucher = instance => ({
    type: Types.CREATE_WAREHOUSE_VOUCHER_REQUEST,
    payload: instance
  });
  export const createWarehouseVoucherOfPartner = instance => ({
    type: Types.CREATE_WAREHOUSE_VOUCHER_PARTNER_REQUEST,
    payload: instance
  });
  export const updateWarehouseVoucher = instance => ({
    type: Types.UPDATE_WAREHOUSE_VOUCHER_REQUEST,
    payload: instance
  });
  export const deleteWarehouseVoucher = id => ({
    type: Types.DELETE_WAREHOUSE_VOUCHER_REQUEST,
    payload: id
  });
  export const resetWarehouseVoucherState = () => ({
    type: Types.RESET_WAREHOUSE_VOUCHER_STATE
  });
  export const resetActionWarehouseVoucherState = () => ({
    type: Types.RESET_ACTION_WAREHOUSE_VOUCHER_STATE
  });
