import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useBranchIdSessionStorage,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhPartners,
  createWhPartner,
  inviteWhPartner,
  deleteWhPartner,
  getWhPartner,
  resetWhPartnerState,
  updateWhPartner,
  approveWhPartner,
  updateWhPartnerTips,
  updateWhPartnerService,
  createWhPartnerService,
  deleteWhPartnerService,
  getWhPartnersReport,
  updateWorkingTimeWhPartner,
  convertToPartner,
} from '~/redux/action';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { WEEKDAYS } from '~/constants/defaultValue';
import { fromJSON } from '~/components/WhPartner/parser';
import { getExistProp } from '~/utils/helper';
import { get, isString } from 'lodash';
import api from '~/api';

const getSelector = (key) => (state) => state.whPartner[key];

const loadingSelector = getSelector('isLoading');
const whPartnersSelector = getSelector('whPartners');
const getWhPartnersFailedSelector = getSelector('getWhPartnersFailed');

const whPartnersReportSelector = getSelector('whPartnersReport');
const getWhPartnersReportFailedSelector = getSelector(
  'getWhPartnersReportFailed'
);

const isGetWhPartnerLoadingSelector = getSelector('isGetWhPartnerLoading');
const whPartnerSelector = getSelector('whPartner');
const getWhPartnerFailedSelector = getSelector('getWhPartnerFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const createServiceSSuccessSelector = getSelector('createServiceSuccess');
const createServiceFailedSelector = getSelector('createServiceFailed');

const isInviteLoadingSelector = getSelector('isInviteLoading');
const inviteSuccessSelector = getSelector('inviteSuccess');
const inviteFailedSelector = getSelector('inviteFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const updateTipsSuccessSelector = getSelector('updateTipsSuccess');
const updateTipsFailedSelector = getSelector('updateTipsFailed');

const updateServiceSuccessSelector = getSelector('updateServiceSuccess');
const updateServiceFailedSelector = getSelector('updateServiceFailed');

const isApproveLoadingSelector = getSelector('isApproveLoading');
const approveSuccessSelector = getSelector('approveSuccess');
const approveFailedSelector = getSelector('approveFailed');

const updateWorkingTimeSuccessSelector = getSelector('updateWorkingTimeSuccess');
const updateWorkingTimeFailedSelector = getSelector('updateWorkingTimeFailed');

const convertToPartnerSuccessSelector = getSelector('convertToPartnerSuccess');
const convertToPartnerFailedSelector = getSelector('convertToPartnerFailed');

const pagingSelector = getSelector('paging');

export const useWhPartnerPaging = () => useSelector(pagingSelector);

export const useWhPartnerQueryParams = () => {
  const [branchId] = useBranchIdSessionStorage();
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const waitingApproval = query.get('waitingApproval');
  const name = query.get('name');
  const tabs = query.get('tabs') || '1';
  const partnerCodeSeq = query.get('partnerCodeSeq');
  const phone = query.get('phone');
  const status = query.get('status');
  const fullName = query.get('fullName');
  const code = query.get('code');
  const employeeGroup = query.get('employeeGroup');
  const services = query.get('services');
  const email = query.get('email');
  const managementArea = query.get('managementArea');
  const startDate =
    query.get('startDate') === 'Invalid date' ? null : query.get('startDate');
  const endDate =
    query.get('endDate') === 'Invalid date' ? null : query.get('endDate');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current);
    setLimit(pageSize);
  }; 

  const deleteWhPartnerSuccess = useSelector(deleteSuccessSelector);
  const updateServiceSuccess = useSelector(updateServiceSuccessSelector);
  const createServiceSuccess = useSelector(createServiceSSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      waitingApproval,
      name,
      code,
      employeeGroup,
      services,
      managementArea,
      keyword: keyword || undefined,
      partnerCodeSeq,
      phone,
      status,
      fullName,
      tabs,
      startDate,
      endDate,
      email,
      branchId,
    };

    return [queryParams,onTableChange];

    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    waitingApproval,
    name,
    code,
    employeeGroup,
    services,
    managementArea,
    deleteWhPartnerSuccess,
    partnerCodeSeq,
    phone,
    status,
    fullName,
    tabs,
    updateServiceSuccess,
    createServiceSuccess,
    startDate,
    endDate,
    email,
    branchId,
  ]);
};
export const useUpdateWhPartnerParams = (query) => {
  const history = useHistory();
  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
  
    history.push({
      pathname: get(param, 'customPathName') || '/wh-partner',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [{ onParamChange }];
};

export const useWhPartnerReferralParams = (query) => {
  const history = useHistory();
  const {path, url} = useRouteMatch();
  const [keyword, setKeyword] = useState(null);
  const onParamChange = (param) => {

    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

     for (const key in param) {
        param[key]=  isString(param[key]) ? param[key].trim() : param[key]
     }
    history.push({
      pathname: `${path}`,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};
export const useReportWhPartnerQueryParams = () => {
  const query = useQueryParams();
  const [branchId] = useBranchIdSessionStorage();
  const keyword = query.get('keyword');
  const name = query.get('name');
  const code = query.get('code');
  const phone = query.get('phone');
  const managementArea = query.get('managementArea');

  const page = query.get('page') || 1;
  const limit = query.get('limit') || 10;
  // const onTableChange = ({ current, pageSize }) => {
  //   setPage(current), setLimit(pageSize);
  // };

  const deleteWhPartnerSuccess = useSelector(deleteSuccessSelector);
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      name,
      managementArea,
      keyword: keyword || undefined,
      code,
      phone
    };

    return [queryParams];

    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    name,
    code,
    managementArea,
    deleteWhPartnerSuccess,
    phone,
    branchId,
  ]);
};
export const useUpdateReportWhPartnerParams = (query) => {
  const SearchBy = useMemo(
    () => ['name', 'code', 'phone', 'managementArea'].find((e) => query[e]),
    []
  );
  const resetQueryParams = {
    name: null,
    code: null,
    phone: null,
    managementArea: null,
    page: 1,
    limit: 10,
    keyword: null
  };
  const history = useHistory();
  const [keyword, setKeyword] = useState(query[SearchBy]);
  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: '/wh-report-partner',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, SearchBy, { onParamChange, resetQueryParams }];
};
export const useWhPartners = (query) => {
  return useFetchByParam({
    action: getWhPartners,
    loadingSelector,
    dataSelector: whPartnersSelector,
    failedSelector: getWhPartnersFailedSelector,
    param: query
  });
};
export const useWhPartnersReport = (query) => {
  return useFetchByParam({
    action: getWhPartnersReport,
    loadingSelector,
    dataSelector: whPartnersReportSelector,
    failedSelector: getWhPartnersReportFailedSelector,
    param: query
  });
};

export const useCreateWhPartner = () => {
  const history = useHistory();
  const callback = () => history.push('/wh-partner');

  useSuccess(createSuccessSelector, 'Tạo mới đối tác thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhPartner
  });
};

//Create New Service in whPartner List Service
export const useCreateWhPartnerService = (callback) => {
  useSuccess(createServiceSSuccessSelector, 'Tạo mới dịch vụ thành công',callback);
  useFailed(createServiceFailedSelector, 'Tạo mới dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhPartnerService
  });
};

export const useInviteWhPartner = () => {
  const history = useHistory();
  const callback = () => history.push('/workspace/myWorkforce');

  useSuccess(inviteSuccessSelector, 'Mời đối tác thành công', callback);
  useFailed(inviteFailedSelector);

  return useSubmit({
    loadingSelector: isInviteLoadingSelector,
    action: inviteWhPartner
  });
};

export const useUpdateWhPartner = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật đối tác thành công');
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhPartner
  });
};
export const useUpdateTipsWhPartner = () => {
  useSuccess(
    updateTipsSuccessSelector,
    'Cập nhật thưởng phạt đối tác thành công'
  );
  useFailed(updateTipsFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhPartnerTips
  });
};

//Update New Service in whPartner List Service
export const useUpdateWhPartnerService = () => {
  useSuccess(updateServiceSuccessSelector, 'Cập nhật dịch vụ thành công');
  useFailed(updateServiceFailedSelector, 'Cập nhật dịch vụ thất bại');
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhPartnerService
  });
};

export const useApproveWhPartner = (callback) => {
  useSuccess(approveSuccessSelector, 'Xác nhận duyệt đối tác thành công',callback);
  useFailed(approveFailedSelector);

  return useSubmit({
    loadingSelector: isApproveLoadingSelector,
    action: approveWhPartner
  });
};

export const useDeleteWhPartner = () => {
  useSuccess(deleteSuccessSelector, 'Xoá đối tác thành công');
  useFailed(deleteFailedSelector, 'Xoá đối tác thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhPartner
  });
};
export const useDeleteWhPartnerService = () => {
  // useSuccess(deleteSuccessSelector, 'Xoá dịch vụ thành công');
  // useFailed(deleteFailedSelector, 'Xoá dịch vụ thất bại');

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: deleteWhPartnerService
  });
};

export const useWhPartner = (id) => {
  return useFetchByParam({
    action: getWhPartner,
    loadingSelector: isGetWhPartnerLoadingSelector,
    dataSelector: whPartnerSelector,
    failedSelector: getWhPartnerFailedSelector,
    param: id
  });
};

export const useInitWhPartner = (whPartner) => {
  return useMemo(() => {
    if (!whPartner) {
      return {
        services: [],
        availableTime: Object.keys(WEEKDAYS).map((key) => ({
          sessionsOfDay: [],
          status: false
        })),
        extraRate: 0
      };
    }
    const { address, ...rest } = whPartner;
    const initValues = {
      ...fromJSON(rest),
      address
    };

    return initValues;
  }, [whPartner]);
};

export const useGetRatingOnePartner = ({ id, startDate, endDate }) => {
  const [data, setData] = useState({
    data: [],
    loading: false
  });
  useEffect(() => {
    const fetch = async () => {
      try {
        setData({ data: [], loading: true });
        const res = await api.reportPartner.getRatingPartner({
          id,
          startDate,
          endDate
        });
        setData({
          data: res,
          loading: false
        });
      } catch (error) {
        setData({
          data: [],
          loading: false
        });
      }
    };
    if (id && startDate && endDate) {
      fetch();
    }
  }, [id, startDate, endDate]);
  return [get(data, 'data', []), get(data, 'loading', 'false')];
};
export const useResetWhPartner = () => {
  useResetState(resetWhPartnerState);
};

export const useUpdateWorkingTimeWhPartner = () => {
  useSuccess(updateWorkingTimeSuccessSelector, 'Cập nhật đối tác thành công');
  useFailed(updateWorkingTimeFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWorkingTimeWhPartner
  });
};

export const useConvertToPartner = () => {
  useSuccess(convertToPartnerSuccessSelector, 'Chuyển đối tác thành công');
  useFailed(convertToPartnerFailedSelector);
  
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: convertToPartner
  })
}

export const useFetchWhPartner = () => {
  const [data,setData] = useState();
  const [loading,setLoading] = useState(false);
  useEffect(() => {
    const fetch = async() => {
      try {
        setLoading(true);
        const res = await api.whPartner.getAll();
        setData(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error,'error');
      }
    };
    fetch()
  },[]);
  return [data,loading];
}
