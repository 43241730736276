import { PlusCircleFilled } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Switch,
  Tag
} from 'antd';
import { get, head, keys, range } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import {
  MAX_PAGINATION_LIMIT,
  STATUS_GIVE_COUPON
} from '~/constants/defaultValue';
import { useCoupons } from '~/hooks';
import {
  useCreateGiveCoupon,
  useGiveCoupon,
  useUpdateGiveCoupon
} from '~/hooks/giveCoupon';
import { vietnamMoment } from '~/hooks/utils';
import { getExistProp, StringToSlug } from '~/utils/helper';
import './index.scss'
const formatTime = 'YYYY-MM-DD HH:mm:ss';
const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="processing"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3
      }}
    >
      {label?.split('-')[0] || ''}
    </Tag>
  );
};
export default function GiveCouponForm({ id, handleCloseModal ,updateGiveCoupon}) {
  const [form] = Form.useForm();
  const [reFetch,setRefetch] = useState(false)
  // const [types,setTypes] = useState(Object.keys(TYPE_RANKING_REFERRAL_VI)[0])
  // const options = useOptionsSelectAntd(TYPE_RANKING_REFERRAL_VI)
  const handleFinish = () => {
    handleCloseModal();
    form.resetFields();
  };
  // HOOK
  const [submitLoading, createGiveCoupon] = useCreateGiveCoupon(handleFinish);
  const [GiveCoupon, loading] = useGiveCoupon(id);
  //

  // hook get coupon//
  const queryGetCoupon = useMemo(
    () => ({ page: 1, limit: MAX_PAGINATION_LIMIT }),
    [reFetch]
  );
  const [coupons, isLoadingGetCoupon] = useCoupons(queryGetCoupon);
  const options = useMemo(
    () =>
      coupons?.map((e) => ({
        label: `${get(e, 'code', '')} - ${get(e, 'name', '')}`,
        value: get(e, '_id', '')
      })),
    [coupons]
  );
  // hook get coupon//
  const onFinish = (values) => {
    const { startDate, endDate,listCoupons,status ,activity} = values;
    // const submitData = getExistProp({
    //   ...values,
    //   listCoupons : listCoupons?.map((coupon) => ({couponId : coupon})),
    //   status: STATUS_GIVE_COUPON.ACTIVE,
    //   startDate: startDate ? moment(startDate).format(formatTime) : null,
    //   endDate: endDate ? moment(endDate).format(formatTime) : null
    // });
    const submitData = {
      ...values,
      listCoupons : listCoupons?.map((coupon) => ({couponId : coupon})),
      status: get(GiveCoupon,'status') || STATUS_GIVE_COUPON.ACTIVE,
      startDate: startDate ? vietnamMoment(startDate) : null,
      endDate: endDate ? vietnamMoment(endDate) : null,
      slug : StringToSlug(activity)
    };
    if(id){
      updateGiveCoupon({...submitData,_id : id})
    }
    else{
      createGiveCoupon({...submitData})
    }
  };

  useEffect(() => {
    form.resetFields()
    if(!!id && GiveCoupon){
      const {listCoupons,startDate,endDate} = GiveCoupon
      form.setFieldsValue({
       ...GiveCoupon,
       listCoupons : listCoupons?.map((coupon) => get(coupon,'couponId._id','')),
       startDate : vietnamMoment(startDate),
       endDate : endDate ? vietnamMoment(endDate) : null,
      })
    }
  },[id,GiveCoupon])
  const onValuesChange = (value) => {
    const firstKey = head(keys(value));
    if (firstKey === 'startDate') {
      if (form.getFieldValue('startDate') > form.getFieldValue('endDate')) {
        form.setFieldsValue({ endDate: null });
      }
    }
  };
  const renderInput = (component) =>
    loading ? <Skeleton.Input active /> : component;
    function disabledTime(date) {
      if (moment(date).format("YYYY-MM-DD") === moment(form.getFieldValue(['startDate'])).format("YYYY-MM-DD")) {
        return {
          disabledHours: () => range(0, 24).slice(0,(moment(form.getFieldValue(['startDate'])).format("HH"))),
          disabledMinutes: () => range(0, 60).slice(0,(moment(form.getFieldValue(['startDate'])).format("MM"))),
          disabledSeconds: () => range(0, 60).slice(0,(moment(form.getFieldValue(['startDate'])).format("ss"))),
        };
      }
        
      return {};
    }
  return (
    <Form
      className="appointment-form"
      form={form}
      autoComplete="off"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      scrollToFirstError
      requiredMark={false}
      labelCol={{ sm: 24, md: 24, lg: 8 }}
      wrapperCol={{ sm: 24, md: 24, lg: 16 }}
    >
      <Form.Item
        label="Mã hoạt động"
        name="code"
      >
        {renderInput(<Input disabled placeholder='Mã tự động'/>)}
      </Form.Item>
      <Form.Item
        label="Tên hoạt động"
        name="activity"
        rules={[{ required: true, message: 'Vui lòng nhập tên hoạt động' }]}
      >
        {renderInput(<Input />)}
      </Form.Item>
      <div className='FormRow'>
      <Form.Item
        label="Chọn mã giảm giá"
        name="listCoupons"
        rules={[{ required: true, message: 'Vui lòng chọn mã giảm giá' }]}
      >
        {renderInput(
          <Select
            loading={isLoadingGetCoupon}
            mode="multiple"
            showArrow
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onDropdownVisibleChange={(open) => {
              if (open) {
                setRefetch(!reFetch)
              }
            }}
            tagRender={tagRender}
            style={{
              width: '100%'
            }}
            options={options || []}
          />
        )}
      </Form.Item>
     <a target='_blank' className='FormRow--buttonRight' href='/coupon'>
        <PlusCircleFilled />
      </a>
      </div>
      
      <Form.Item
        name="startDate"
        label="Ngày bắt đầu"
        rules={[{ required: true, message: 'Vui lòng chọn ngày bắt đầu' }]}
      >
        {renderInput(
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Chọn ngày bắt đầu"
            format={formatTime}
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          />
        )}
      </Form.Item>
      <Form.Item 
      shouldUpdate
      // shouldUpdate={(prevValue, curValue) => prevValue.startDate !== curValue.startDate} 
      noStyle>
        {() => (
          <Form.Item name="endDate" label="Ngày kết thúc">
            {renderInput(
              <DatePicker
                style={{ width: '100%' }}
                format={formatTime}
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                disabledDate={(current) =>
                  current < moment(form.getFieldValue(['startDate']))
                }
                disabledTime={disabledTime}
                showNow={false}
                placeholder="Chọn ngày kết thúc"
              />
            )}
          </Form.Item>
        )}
      </Form.Item>
      <Row justify="center" className="groupButtonSubmit">
        <Col span={4}>
          <Button onClick={handleCloseModal}>Huỷ</Button>
        </Col>
        <Col span={4}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            {id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
