import { Button, Col, Form, Input, Row, Skeleton, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import api from '~/api';
import TooltipTitle from '~/components/Common/TooltipTitle';
import { useStopDeliveryProduct } from '~/hooks/productList';
import toastr from 'toastr'


const formItemLayout = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 10 },
    lg: { span: 10 },
    xl: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 14 },
    lg: { span: 14 },
    xl: { span: 14 }
  }
};
export default function ConfirmGetOut({ id, setIsOpenFormGetOut }) {
  const [ form ] = Form.useForm();
  const [voucher, setVoucher] = useState({});
  const [isLoadingStopDelivery, handleStop] = useStopDeliveryProduct();
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState(null);
  const [codeVoucher, setCodeVoucher] = useState(null);
  const [isGetOut, setIsGetOut] = useState(false);

  const getInfoDeliveryVoucher = async () => {
    setLoading(true);
    const res = await api.productList.getDeliveryVoucherInfo(id);
    setVoucher(res);
    setIsGetOut(res?.status === 'NEW' ? false : true);
    setLoading(false);
    form.setFieldsValue({
      note: null,
    });
    setNote(null);
  };

  const handleGetOut = () => {
    const body = {
      note: note,
      id: id
    };

    if (!note) {
      toastr.error('Vui lòng nhập lý do trước khi rút thiết bị')
    } else {
      handleStop(body)
      setIsOpenFormGetOut(false);
    }
  };
  useEffect(() => {
    id && getInfoDeliveryVoucher()
  }, [id]);
  useEffect(() => {
    setCodeVoucher(voucher?.code,)
    const product = voucher?.deliveryVoucher?.find((item) => item.productId === id);
    const data = {
      name: product?.product?.name,
      code: voucher?.code,
      warehouseFrom: voucher?.from?.warehouseInfo?.name,
      warehouseTo: voucher?.to?.warehouseInfo?.name,
    };
    form.setFieldsValue(data);
  },[voucher]);

  const renderLoading = (component) => (loading ? <Skeleton.Input active /> : component);
  const renderButton = (component1, component2) => {
    const checkDevice = voucher?.deliveryVoucher?.filter((item) => item.status !== 'CANCEL');
    return checkDevice?.length > 1 ? component1 : component2
  };
  return (
    <div>
      <Form
        form={form}
        autoComplete="off"
      >
      <Row>
        <Col span={24}>
            <Form.Item
            name={'name'}
            {...formItemLayout}
            label = {'Tên thiết bị luân chuyển'}
          >
            {renderLoading(<Input disabled/>)}
          </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item
            name={'code'}
            {...formItemLayout}
            label = {'Mã phiếu luân chuyển'}
          >
            {renderLoading(<Input disabled/>)}
          </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item
            name={'warehouseFrom'}
            {...formItemLayout}
            label = {'Kho chuyển luân chuyển'}
          >
            {renderLoading(<Input disabled/>)}
          </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item
            name={'warehouseTo'}
            {...formItemLayout}
            label = {'Kho nhận luân chuyển'}
          >
            {renderLoading(<Input disabled/>)}
          </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item
              name={'note'}
            {...formItemLayout}
              label={'Lý do'}
              rules={[
                {
                  required: true,
                  message:'Vui lòng nhập lý do rút thiết bị'
                }
              ]}
          >
              {renderLoading(<Input.TextArea
            value={note} onChange={(e) => setNote(e.target.value)} rows={3} />)}
          </Form.Item>
        </Col>
      </Row>
        <Row align='center'>
          {
            !isGetOut ? (
              <>
                  <Col span={10}>
                  {
                    !loading && (
                      renderButton((<Button type='primary' onClick={() => handleGetOut()}>Xác nhận rút thiết bị</Button>), (
                        <TooltipTitle
                            content={
                              <Button type='primary'>
                                <Link target='_blank' to={`/delivery-coupon?page=1&limit=10&codeVoucher=${codeVoucher}`}>Chuyển trang</Link>
                              </Button>
                            }
                            titleTooltip = 'Đây là thiết bị cuối cùng trong phiếu luân chuyển, vui lòng chuyển trang để huỷ phiếu !'
                          />
                      ))
                    )
                  }
              </Col>
              <Col span = {4}>
                  <Button type='dashed' style={{border: '1px solid red'}} onClick={() => setIsOpenFormGetOut(false)}>
                      Huỷ
                  </Button>
              </Col>
              </>
            ) : (
                <p style={{textAlign:'center'}}>Phiếu luân chuyển này đã được xác nhận,không thể tạm ngưng luân chuyển thiết bị này</p>
            )
          }
        
        </Row>
      </Form>
    </div>
  )
}
