import requester from './requester';

const partner = {
  getAll: (query) => requester.get(`/partner`, query),
  getAllClinic: (query) => requester.get(`/partner-all/CLINIC`, query),
  getById: (id) => requester.get(`/partner/${id}?raw=true`),
  create: (partner) => requester.post('/partner', partner),
  update: (partner) => requester.put(`/partner/${partner._id}`, partner),
  delete: (id) => requester.delete(`/partner/${id}`),
  getBranches: ({id}) => requester.get(`/partner/${id}/branch`),
  getClinic: ({id}) => requester.get(`/partner/${id}/clinic`),
  getResourcePartner: (partnerId) => requester.get(`/resource-partner-contractor`,{partnerId}),
  getListAccessPermissionPartner: (partnerId) => requester.get(`/resource-partner`,{partnerId}),
  accessPermissionPartner: (payload) => requester.put(`/resource-partner`,payload),
  denyPermissionPartner: (payload) => requester.delete(`/resource-partner`,payload),

  getResourceClinic: (partnerId) => requester.get(`/resource-clinic-contractor`,{partnerId}),
  getListAccessPermissionClinic: (partnerId) => requester.get(`/resource-clinic`,{partnerId}),
  accessPermissionClinic: (payload) => requester.put(`/resource-clinic`,payload),
  denyPermissionClinic: (payload) => requester.delete(`/resource-clinic`,payload),
};

export default partner;
// /${query.type}
