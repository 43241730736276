import * as Types from '../../constants/actionTypes';
  
  export const getPartnerCancelAppointments = query => ({
    type: Types.GET_PARTNER_CANCEL_APPOINTMENTS_REQUEST,
    payload: query
  });
  export const getPartnerCancelAppointment = id => ({
    type: Types.GET_PARTNER_CANCEL_APPOINTMENT_REQUEST,
    payload: id
  });
  export const createPartnerCancelAppointment = instance => ({
    type: Types.CREATE_PARTNER_CANCEL_APPOINTMENT_REQUEST,
    payload: instance
  });
  export const updatePartnerCancelAppointment = instance => ({
    type: Types.UPDATE_PARTNER_CANCEL_APPOINTMENT_REQUEST,
    payload: instance
  });
  export const deletePartnerCancelAppointment = id => ({
    type: Types.DELETE_PARTNER_CANCEL_APPOINTMENT_REQUEST,
    payload: id
  });
  export const resetPartnerCancelAppointmentState = () => ({
    type: Types.RESET_PARTNER_CANCEL_APPOINTMENT_STATE
  });
