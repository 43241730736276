import { Col, Row, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import api from '~/api';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import toastr from 'toastr';
import Search from 'antd/lib/input/Search';

export default function ReferralPartner({ whPartnerId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [valueSearch, setValueSearch] = useState('');
  const [paging, setPaging] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const query = useMemo(
    () => ({
      page: page,
      limit: limit,
      type: 'PARTNER',
      id: whPartnerId,
      keyword: keyword.trim()
    }),
    [page, limit, whPartnerId, keyword]
  );

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await api.whPartner.getAllReferral(query);
      if (res?.docs) {
        setData(res?.docs);
        setPaging({
          current: res?.page,
          pageSize: res?.limit,
          total: res?.totalDocs
        });
      }
    } catch (error) {
      toastr.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    whPartnerId && getData();
  }, [page, limit, whPartnerId, keyword]);

  const columns = [
    {
      title: 'Mã đối tác',
      dataIndex: 'partnerCode',
      key: 'partnerCode'
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone'
    }
  ];
  return isLoading ? (
    <SkeletonTable columns={columns} rowCount={3} />
  ) : (
    <>
      <Row>
        <Col span={8}>
          <Search
            allowClear
            placeholder="Tìm bất kỳ..."
            value={valueSearch}
            onSearch={(value) => {
              setKeyword(value);
            }}
            onChange={(e) => {
              setValueSearch(e.target.value);
              if (e.target.value === '') {
                setKeyword('');
              }
            }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data ?? []}
        pagination={{
          ...paging,
          showTotal: (total) => `Tổng cộng: ${total}`
        }}
        size="small"
        onChange={({ current, pageSize }) => {
          setPage(current);
          setLimit(pageSize);
        }}
      />
    </>
  );
}
