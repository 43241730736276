import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getJobs({ payload: query }) {
    try {
      const response = yield call(Api.job.getAll, query);
      yield put({ type: Types.GET_JOBS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_JOBS_FAILED, payload: error });
    }
  }
function* getJob({ payload: id }) {
    try {
      const response = yield call(Api.job.getOne, id);
      yield put({ type: Types.GET_JOB_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_JOB_FAILED, payload: error });
    }
  }
  function* createJob(action) {
    try {
      const data = yield call(Api.job.create, action.payload);
      yield put({ type: Types.CREATE_JOB_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_JOB_FAILED, payload: error });
    }
  }
  function* updateJob(action) {
    try {
      const data = yield call(Api.job.update, action.payload);
      yield put({ type: Types.UPDATE_JOB_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_JOB_FAILED, payload: error });
    }
  }
  function* deleteJob(action) {
    try {
      const data = yield call(Api.job.delete, action.payload);
      yield put({ type: Types.DELETE_JOB_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_JOB_FAILED, payload: error });
    }
  }
export default function* Jobs() {
    yield takeLatest(Types.GET_JOBS_REQUEST, getJobs);
    yield takeLatest(Types.GET_JOB_REQUEST, getJob);
    yield takeLatest(Types.CREATE_JOB_REQUEST, createJob);
    yield takeLatest(Types.UPDATE_JOB_REQUEST, updateJob);
    yield takeLatest(Types.DELETE_JOB_REQUEST, deleteJob);
  }
