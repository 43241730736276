import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Select, Skeleton, Col, Button } from 'antd';
import { useCities, useDistricts, useWards, useWhCustomerManagementAddress } from '~/hooks';
import { filterAcrossAccents } from '~/hooks/utils';
import { get } from 'lodash';
const { Option } = Select;

export default function ChangeAddressForm({ handleCancel, handleUpdate, id, isLoadingUpdate, typeAddressDefault, addressDefault }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCityCode, setSelectedCityCode] = useState();
  const [selectedDistrictCode, setSelectedDistrictCode] = useState();
  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(selectedCityCode);
  const [wards, isWardsLoading] = useWards(selectedDistrictCode);
  const [address, isLoadingAddress] = useWhCustomerManagementAddress(id);

  useEffect(() => {
    if (id && !typeAddressDefault) {
      setSelectedCityCode(get(address, 'cityId'));
      setSelectedDistrictCode(get(address, 'districtId'));
      form.setFieldsValue(address)
    }else if(!id && typeAddressDefault){
      setSelectedCityCode(get(addressDefault, 'cityId'));
      setSelectedDistrictCode(get(addressDefault, 'districtId'));
      form.setFieldsValue(addressDefault)
    }
  }, [address]);

  const onValuesChange = ({ cityId, districtId }) => {
    if (cityId) {
      setSelectedCityCode(cityId);
      setSelectedDistrictCode(null);
      form.setFieldsValue({
        districtId: null,
        wardId: null
      });
    };
    if (districtId) {
      setSelectedDistrictCode(districtId);
      form.setFieldsValue({
        wardId: null
      });
    };
  };
  
  const onFinish = (values) => {
    const data = {
      ...values,
      id: id
    };
    handleUpdate(data);
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onValuesChange={onValuesChange}
      autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item
        label={'Thành Phố/Tỉnh'}
        name={'cityId'}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập địa chỉ Thành Phố/Tỉnh'
          }
        ]}
      >
        {isLoadingAddress ? (
          <Skeleton.Input active />
        ) : (
          <Select
            onChange={setSelectedCityCode}
            disabled={isCitiesLoading}
            loading={isCitiesLoading}
            showSearch
            autoComplete="off"
            filterOption={filterAcrossAccents}
          >
            {cities.map(({ code, name }) => (
              <Option key={code} value={code}>
                {name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        label={'Quận/Huyện'}
        name={'districtId'}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập địa chỉ Quận/Huyện'
          }
        ]}
      >
        {isLoadingAddress ? (
          <Skeleton.Input active />
        ) : (
          <Select
            loading={isDistrictsLoading}
            disabled={!form.getFieldValue('cityId')}
            onChange={setSelectedDistrictCode}
            showSearch
            autoComplete="off"
            filterOption={filterAcrossAccents}
          >
            {districts.map(({ code, name }) => (
              <Option key={code} value={code}>
                {name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        label={'Phường/Xã'}
        name={'wardId'}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập địa chỉ Phường/Xã'
          }
        ]}
      >
        {isLoadingAddress ? (
          <Skeleton.Input active />
        ) : (
          <Select
            loading={isWardsLoading}
            disabled={!form.getFieldValue('districtId')}
            showSearch
            autoComplete="off"
            filterOption={filterAcrossAccents}
          >
            {wards.map(({ code, name }) => (
              <Option key={code} value={code}>
                {name}
              </Option>
            ))}
          </Select>
        )}
     </Form.Item>
      <Form.Item
        label={'Đường phố'}
        name={'street'}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập địa chỉ Đường phố'
          }
        ]}
      >
        <Input placeholder="Vui lòng nhập địa chỉ cụ thể" />
      </Form.Item>
      <Row align="center">
        <Col span={6}>
          <Button type="primary" htmlType="submit" loading={isLoadingUpdate}>
            Cập nhật
          </Button>
        </Col>
        <Col span={6}>
          <Button type="primary" onClick={handleCancel} loading={isLoadingUpdate}>
            Huỷ
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
