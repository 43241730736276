import React, { useState, useEffect, useMemo } from 'react';

import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  notification
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { get, head } from 'lodash';

import { LANGUAGE, MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';

import { useFormItemMargin, filterActiveInstances } from '~/hooks/utils';

import {
  useCreateWhService,
  useInitWhService,
  useResetWhService,
  useUpdateWhService,
  useWhService
} from '~/hooks/whService';
import { useWhCategories } from '~/hooks/whCategory';
import { useWhPackageLevels } from '~/hooks/whPackageLevel';
import {
  useWhSessionsOfDay,
  useWhSessionOfDayQueryParams
} from '~/hooks/whSessionOfDay';

import { toJSON } from '../UserEmployee/parser';

import './index.scss';
import UploadServiceImage from './UploadServiceImage/UploadServiceImage';

const FormItem = Form.Item;

const FamilyHealthyCart = ({ index }) => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState(LANGUAGE.VI);

  const [whPackageLevels, isWhPackageLevelsLoading] = useWhPackageLevels();

  const [querySessions, onPagingChange] =
    useWhSessionOfDayQueryParams(MAX_PAGINATION_LIMIT);
  const [whSessionsOfDay, isWhSessionsOfDayLoading] =
    useWhSessionsOfDay(querySessions);

  const [isSubmitLoading, handleCreate] = useCreateWhService();
  const [, handleUpdate] = useUpdateWhService();

  const { id } = useParams();
  const [whService, isGetWhServiceLoading] = useWhService(id);
  const initWhService = useInitWhService(whService);

  const activeWhSessionsOfDay = useMemo(() => {
    return filterActiveInstances(whSessionsOfDay);
  }, [whSessionsOfDay]);

  const blankQuestion = {
    title: {},
    options: [{ title: {}, image: '' }]
  };

  const blankSessionOfDay = {
    duration: null,
    extraDuration: null,
    extraPrice: null,
    extraTimeUnit: null,
    minimumQuantity: null,
    price: null,
    status: false,
    timeUnit: null
  };

  const blankPackageLevel = useMemo(() => {
    return {
      sessionPrices: activeWhSessionsOfDay.map((item, index) => ({
        ...blankSessionOfDay,
        ...(index === 0 && { status: true }),
        whSessionOfDay: { ...item },
        whSessionOfDayId: item.id
      })),
      status: true
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWhSessionsOfDay]);

  useEffect(() => {
    form.resetFields();
  }, [initWhService, form]);

  const mergedInitWhService = useMemo(() => {
    if (!id) {
      return {
        ...initWhService,
        packages: [
          {
            ...blankPackageLevel,
            whPackageLevelId: get(head(whPackageLevels), 'id')
          }
        ]
      };
    }

    return {
      ...initWhService,
      packages: get(initWhService, 'packages')?.map((item) => {
        // find the existing session prices available in this package of the service, for example: "day" and "night"
        const existingSessionPrices = get(item, 'sessionPrices')?.map(
          (item) => ({
            ...item,
            status: get(item, 'status') === 'ACTIVE' ? true : false
          })
        );

        // there should be other session of days that were not created for this package of the service, for example: "midnight"
        // their ids should not be in the existingSessionPrices certainly
        const blankSessionPrices = activeWhSessionsOfDay
          .filter(
            (i) =>
              !existingSessionPrices.find(
                (j) => get(j, 'whSessionOfDayId') === get(i, 'id')
              )
          )
          .map((item) => ({
            ...blankSessionOfDay,

            // the status must be false because this is the NEW session that user may want to create
            // status is set to false initially not to trigger the validation of the form
            // and user has to enable this session manually if they want to create it
            status: false,

            whSessionOfDay: { ...item },
            whSessionOfDayId: item.id
          }));

        // return the package with both existing session prices and the remaining available session prices
        // so that user can create new on their demand
        return {
          ...item,
          status: get(item, 'status') === 'ACTIVE' ? true : false,
          sessionPrices: existingSessionPrices.concat(blankSessionPrices)
        };
      })
    };
  }, [
    id,
    initWhService,
    whPackageLevels,
    activeWhSessionsOfDay,
    blankPackageLevel
  ]);

  useEffect(() => {
    form.setFieldsValue(mergedInitWhService);
  }, [form, mergedInitWhService]);

  // useResetWhService();

  const setWhServiceQuestionOptionImg = (image, questionIdx) => {
    form.setFieldsValue({
      questions: form.getFieldValue('questions').map((question, qIdx) => ({
        ...question,
        options: question.options.map((option, oIdx) => ({
          ...option
        }))
      }))
    });
  };

  const onFinish = (values) => {
    const whService = toJSON({
      ...values
    });

    if (id) {
      handleUpdate({ ...whService, id });
    } else {
      handleCreate(whService);
    }
  };

  const onValuesChange = (changes) => {};

  const isLoading = isGetWhServiceLoading;

  return (
    <Form
      form={form}
      autoComplete="off"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      scrollToFirstError
      requiredMark={false}
      // initialValues={initWhService}
      initialValues={mergedInitWhService}
      labelCol={{ sm: 24, md: 24, lg: 6 }}
      wrapperCol={{ sm: 24, md: 24, lg: 18 }}
    >
      {/* <Row>
        <h1>Cart</h1>
        {console.log('Index', index)}
      </Row> */}
      <Form.Item
        label={index === undefined ? 'Cart 1' : `Cart ${index.name + 2}`}
      />
      <Row gutter={0} align="middle" justify="space-between">
        <Col span={12}>
          <FormItem label="Tên dịch vụ">
            <Input />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={0} align="middle" justify="space-between">
        <Col span={12}>
          <FormItem label="Mô tả dịch vụ">
            <Input />
          </FormItem>
        </Col>
      </Row>

      <h3 className="wh-service-form__section-title">Hình ảnh</h3>
      <Form.List name="questions">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => {
              const props = {
                fieldKey,
                form,
                index,
                key,
                language,
                name,
                remove,
                setWhServiceQuestionOptionImg
              };
              return (
                <UploadServiceImage
                  style={{ width: '100%' }}
                  title="Hình ảnh"
                  imgUrl={form.getFieldValue([index, 'image'])}
                  onChange={(img) => setWhServiceQuestionOptionImg(img, index)}
                  key={key}
                  {...props}
                />
              );
            })}
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default FamilyHealthyCart;
