import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getServicePlaces,
  createServicePlace,
  deleteServicePlace,
  getServicePlace,
  resetServicePlaceState,
  updateServicePlace
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const getSelector = (key) => (state) => state.servicePlace[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useServicePlacePaging = () => useSelector(pagingSelector);

export const useServicePlaceQueryParams = (defaultLimit) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || defaultLimit || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const onTableChange = ({ current }) => setPage(current);

  const deleteServicePlaceSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteServicePlaceSuccess]);
};

export const useServicePlaces = (query) => {
  return useFetchByParam({
    action: getServicePlaces,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateServicePlace = () => {
  const history = useHistory();
  const callback = () => history.push('/service-place');

  useSuccess(
    createSuccessSelector,
    'Tạo mới nơi thực hiện dịch vụ thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới nơi thực hiện dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createServicePlace
  });
};

export const useUpdateServicePlace = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật nơi thực hiện dịch vụ thành công',
    callback
  );
  useFailed(updateFailedSelector, 'Cập nhật nơi thực hiện dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateServicePlace
  });
};

export const useDeleteServicePlace = () => {
  useSuccess(deleteSuccessSelector, 'Xoá nơi thực hiện dịch vụ thành công');
  useFailed(deleteFailedSelector, 'Xoá nơi thực hiện dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteServicePlace
  });
};

export const useServicePlace = (id) => {
  return useFetchByParam({
    action: getServicePlace,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useUpdateServicePlaceParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    history.push({
      pathname: '/service-place',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useInitServicePlace = (servicePlace) =>
  useMemo(() => {
    if (!servicePlace) {
      return {
        image: {}
      };
    }

    return servicePlace;
  }, [servicePlace]);

export const useResetServicePlace = () => {
  useResetState(resetServicePlaceState);
};
