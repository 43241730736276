import {
  TOGGLE_THEME,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LOGIN_WORKSPACE_REQUEST,
  LOGOUT_WORKSPACE_REQUEST,
  SELECT_BRANCH,
  GET_PROFILE_REQUEST,
  GET_USER_POLICY_REQUEST,
  RESET_STORE,
  GET_DATA_FOR_NAVBAR,
  CHANGE_BRANCH_ID_SESSION,
  PINED_NAVBAR,
} from '~/constants/actionTypes';

export const toggleTheme = () => ({
  type: TOGGLE_THEME
});

export const loginRequest = (user) => ({
  type: LOGIN_REQUEST,
  payload: user
});

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST
});

export const loginWorkspaceRequest = (user) => ({
  type: LOGIN_WORKSPACE_REQUEST,
  payload: user
});

export const logoutWorkspaceRequest = () => ({
  type: LOGOUT_WORKSPACE_REQUEST
});

export const selectBranch = (branchId) => ({
  type: SELECT_BRANCH,
  payload: branchId
});

export const getProfile = (token) => ({
  type: GET_PROFILE_REQUEST,
  payload: token
});

export const getPolicy = (branchId) => ({
  type: GET_USER_POLICY_REQUEST,
  payload: branchId
});

export const getNavbar = (dataNavbar) => ({
  type: GET_DATA_FOR_NAVBAR,
  payload: dataNavbar
});

export const pinedNavbar = (accessKey) => ({
  type: PINED_NAVBAR,
  payload: accessKey
});

export const resetStore = () => ({
  type: RESET_STORE
});

export const changeBranchIdSession = (branchId) => ({
  type: CHANGE_BRANCH_ID_SESSION,
  payload: branchId
});
