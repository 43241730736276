import { Badge, Col, Input, Row, Tabs } from 'antd'
import { Suspense, lazy, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { useGetBoardById, useUpdateTask } from '~/hooks'
import { get, head } from 'lodash'
// import TodoList from './Detail/TodoList'
// import Assigner from './Detail/Assigner'
import Text from 'antd/lib/typography/Text'
import { useGetTaskById, useResetComment, useUpdateProgress } from '~/hooks/workTask'
// import Description from './Detail/Description';
import { TaskItemProvider } from '~/storeContext/TaskItem'
// import ActivityTask from './Detail/ActivityTask'
import moment from 'moment'
import EstimateAndDate from './Detail/EstimateAndDate.js'
// import ComponentComment from './Detail/ComponentComment'
// import UploadfileTaskItem from './Detail/Upload'
// import RelationTask from './Detail/RelationTask';
const UploadfileTaskItem= lazy(()=>import('./Detail/Upload/index.js'));
const SelectStatusTask = lazy(()=>import('./common/SelectStatusTask.js'));
const RelationTask = lazy(()=>import('./Detail/RelationTask.js'));
const ActivityTask = lazy(()=>import('./Detail/ActivityTask.js'));
const TodoList= lazy(()=>import('./Detail/TodoList.js'));
const Assigner= lazy(()=>import('./Detail/Assigner.js'));
const Description = lazy(()=>import('./Detail/Description.js'));
const ComponentComment = lazy(()=>import('./Detail/ComponentComment.js'));
export default function TaskItem({ idTask }) {
  const { id } = useParams();
  const [dataTask, isLoading] = useGetTaskById(idTask || id);
  const [isSubmit, updateTask] = useUpdateTask();
  const [isLoadingProgress, updateProgressTask] = useUpdateProgress();
  const [boardById,] = useGetBoardById(dataTask?.boardId);
  const [ ,handleResetComment ]= useResetComment();
  const [inputValue, setInputValue] = useState(get(dataTask, 'name', ''));
  const [fileList_, setFileList] =useState([]);
  const [selected, setSelected] = useState(get(dataTask?.statusId, '_id'));
  useEffect(()=>{
    if(dataTask?.fileList){
      setFileList(dataTask?.fileList??[])
    }
  },[dataTask]);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = async (ev) => {
    if (!ev.target.value) {
      setInputValue(get(dataTask, 'name', ''));
    }
    if (ev.target.value === get(dataTask, 'name', '')) {
      return
    }

    updateTask({ name: ev.target.value ?? get(dataTask, 'name', ''), id: idTask || id });
  };
  useEffect(()=>{
      return ()=>{
        handleResetComment()
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dataTask?._id])
   useEffect(() => {
    const statusItem = boardById?.listStatusConfig.find(item => item?._id === dataTask?.statusId?._id);
  
    if (statusItem) {
      setSelected(statusItem._id);
    } else if(typeof dataTask?.statusId === 'object'){
      setSelected(get(dataTask?.statusId,'value','Không xác định'));
    }
    else{
      setSelected('Không xác định');
    }
  }, [boardById?.listStatusConfig, dataTask?.statusId])
  const handlFinshed = useCallback((val, key) => {
    updateTask({
      [key]: val,
      id: idTask || id
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleChange = (value) => {
    setSelected(value);
    handlFinshed(value, 'statusId')
  };
  // eslint-disable-next-line arrow-body-style
  return (
    <TaskItemProvider dataTask={dataTask}>
      <div className="branch-detail page-wraper page-content page-task-detail">
        <div className='page-task-detail_container' >
          <div className='task-detail-header'>
            <Row align='middle' gutter={16} style={{ width: '100%' }} >
              <Col>
              <Suspense fallback={<div>Trạng thái...</div>}>
                <SelectStatusTask  handleChange={handleChange} value={selected} defaultValue={selected} initStatusValue={get(dataTask,'statusId',{})} listStatus={boardById?.listStatusConfig} />
              </Suspense>
              </Col>
              <Col>
                <Text strong>#{get(dataTask, 'code', '')} </Text>
              </Col>
              <Col sm={{ flex: 1 }} md={{ span: 9 }} >
                <Input
                  type="text"
                  size="small"
                  className='input-title-boardConfig'
                  style={{
                    width: '100%',
                    height: 30,
                    fontSize: '18px',
                    fontWeight: 700
                  }}
                  value={inputValue || get(dataTask, 'name', '')}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              </Col>
            </Row>

          </div>
          <Row >
            <Col >
              <span> Ngày tạo:  {moment(get(dataTask, 'createdAt', '')).format('YYYY-MM-DD')}</span>
            </Col>
          </Row>
          <div className='task-detail-content'>
            <Row gutter={[16, 16]} justify='center' wrap={false}>
              <Col flex={1} style={{maxWidth:1300,minWidth:'370px'}}>
                <div className='task-detail-content-left'>
                  <Tabs  type="card"  defaultActiveKey='description' className='tabs_detail_task'>
                    <Tabs.TabPane tab={'Ghi chú công việc'} key="description">
                    <Suspense fallback={<div>Mô tả...</div>}>
                        <Description handlFinshed={handlFinshed} dataTask={dataTask} />
                    </Suspense>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Badge count={fileList_?.length??0}><p style={{padding :'2px 6px'}}>Tệp đính kèm</p></Badge>} key="files" style={{height:'100%'}}>
                    <Suspense fallback={<div>Tải File...</div>}>
                        <UploadfileTaskItem dataTask={dataTask} handlFinshed={handlFinshed}  fileList_={fileList_}  setFileList={setFileList}/>
                    </Suspense>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab='Lịch sử chỉnh sửa' key={'activity'}  > 
                    <Suspense fallback={<div>Lịch sử...</div>}>
                      <ActivityTask />
                    </Suspense>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab='Công việc liên quan' key={'relation'}  > 
                    <Suspense fallback={<div>Công việc liên quan...</div>}>
                      <RelationTask />
                    </Suspense>
                    </Tabs.TabPane>
                  </Tabs>
                  <Suspense fallback={<div>Trạng thái...</div>}><Assigner dataTask={dataTask} /></Suspense>
                  <EstimateAndDate data={dataTask} onUpdate={(updatePayload) => updateTask({id: idTask || id,...updatePayload})}/>
                  <Suspense fallback={<div>Trạng thái...</div>}><TodoList updateProgressTask={updateProgressTask} dataTask={dataTask} /></Suspense>
                  
                  
                </div>
              </Col>
                </Row>
              </div>
              <Row justify='center'>
                <Col flex={1} style={{maxWidth:1300,minWidth:'370px'}}>
                  <Tabs defaultActiveKey={'comment'} type="card" className='task-detail-history' style={{ marginTop: 20,marginBottom:20 }} >
                  <Tabs.TabPane tab='Nội dung trao đổi' key={'comment'}>
                  <Suspense fallback={<div>Trao đổi...</div>}>
                    <ComponentComment/>
                    </Suspense>
                  </Tabs.TabPane>
                
                </Tabs>
                </Col>
              </Row>
              
          </div>
        </div>
    </TaskItemProvider>
  )
}
