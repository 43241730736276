import requester from './requester';

const employeeGroup = {
  getAll: (branchId) => requester.get(`/user-group`, branchId),

  getById: ({ groupId }) => requester.get(`/user-group/${groupId}`),
  create: (employeeGroup) => requester.post('/user-group', employeeGroup),
  update: (employeeGroup) =>
    requester.put(`/user-group/${employeeGroup._id}`, employeeGroup),
  delete: (id) => requester.delete(`/user-group/${id}`),

  assignPermission: ({ groupId, ...rest }) =>
    requester.put(`/user-group/${groupId}/permission`, { ...rest }),

  removePermission: ({ groupId, ...rest }) =>
    requester.delete(`/user-group/${groupId}/permission`, { ...rest }),

  getResources: (branchId) => requester.get('/resource-permission', branchId)
};

export default employeeGroup;
