import { Table, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';

const { TabPane } = Tabs;

export default function WhMedicalEquipmentExpandle({
  groupProductInits,
  materialInits
}) {
  const [activeKey, setActiveKey] = useState('1');
  const [dataEquipment, setDataEquipment] = useState([]);
  const [dataMedical, setDataMedical] = useState([]);
  const [pagingEquipment, setPagingEquipment] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const [pagingMaterial, setPagingMaterial] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const defaultMaterialType = {
    PRODUCT: 'Hàng hoá',
    MEDICINE: 'Thuốc'
  };

  const onTableChangeEquipment = ({current, pageSize}) => {
    setPagingEquipment({
      current,
      pageSize,
      total:groupProductInits?.length
    });
  };

  const onTableChangeMaterial = ({current, pageSize}) => {
    setPagingMaterial({
      current,
      pageSize,
      total:materialInits?.length
    });
  };

  useEffect(() => {
    const filterData = (groupProductInits || []).map((item) => {
      return {
        groupEquipmentId: item?.groupEquipment?.name?.vi,
        amount: item?.amount,
        unit: item?.unit,
        note: item?.note
      };
    });
    setDataEquipment(filterData);
    setPagingEquipment({
      current: 1,
      pageSize: 10,
      total: filterData?.length
    });
    
  }, [groupProductInits]);

  useEffect(() => {
    const filterData = (materialInits || []).map((item) => {
      return {
        category: item?.category,
        name: item?.name,
        amount: item?.amount,
        unit: item?.unit,
        note: item?.note
      };
    });
    setDataMedical(filterData);
    setPagingMaterial({
      current: 1,
      pageSize: 10,
      total: filterData?.length
    });
  }, [materialInits]);
  const onTabClick = (key) => {
    setActiveKey(key);
  };

  const columnsEquipment = [
    {
      title: 'Tên nhóm thiết bị',
      key: 'groupEquipmentId',
      dataIndex: 'groupEquipmentId'
    },
    {
      title: 'Số lượng',
      key: 'amount',
      dataIndex: 'amount'
    },
    {
      title: 'Đơn vị',
      key: 'unit',
      dataIndex: 'unit'
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note'
    }
  ];

  const columnsMaterial = [
    {
      title: 'Nhóm nguyên vật liệu',
      dataIndex: 'category',
      render: (value) => defaultMaterialType[value]
    },
    {
      title: 'Tên nguyên vật liệu',
      dataIndex: 'name'
    },
    {
      title: 'Số lượng',
      dataIndex: 'amount'
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit'
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note'
    }
  ];

  return (
    <>
      <Tabs
        destroyInactiveTabPane
        onChange={() => {}}
        onTabClick={onTabClick}
        type="card"
        activeKey={activeKey}
        style={{ width: '100%' }}
      >
        <TabPane tab="Công cụ dụng cụ" key={'1'}>
          <Table
            columns={columnsEquipment}
            dataSource={dataEquipment}
            size="middle"
            pagination={{
              ...pagingEquipment,
              showTotal: (total)=> `Tổng cộng: ${total}`,
            }}
            onChange={onTableChangeEquipment}
          />
        </TabPane>
        <TabPane tab="Nguyên vật liệu" key={'2'}>
          <Table
            columns={columnsMaterial}
            dataSource={dataMedical}
            size="middle"
            pagination={{
              ...pagingMaterial,
              showTotal: (total)=> `Tổng cộng: ${total}`,
            }}
            onChange={onTableChangeMaterial}
          />
        </TabPane>
      </Tabs>
    </>
  );
}
