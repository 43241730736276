import {
  GET_NEWSES_REQUEST,
  GET_NEWS_REQUEST,
  CREATE_NEWS_REQUEST,
  UPDATE_NEWS_REQUEST,
  DELETE_NEWS_REQUEST,
  RESET_NEWS_STATE,
  UPDATE_NEWS_ID_REQUEST,
  FETCH_SEARCH_CATEGORY_REQUEST,
  FETCH_SEARCH_AUTHOR_REQUEST,
} from '../../constants/actionTypes';

export const getNewses = query => ({
  type: GET_NEWSES_REQUEST,
  payload: query
});

export const getNews = id => ({
  type: GET_NEWS_REQUEST,
  payload: id
});

export const createNews = news => ({
  type: CREATE_NEWS_REQUEST,
  payload: news
});

export const updateNews = news => ({
  type: UPDATE_NEWS_REQUEST,
  payload: news
});
export const updateNewsid = news => ({
  type: UPDATE_NEWS_ID_REQUEST,
  payload: news
});

export const deleteNews = id => ({
  type: DELETE_NEWS_REQUEST,
  payload: id
});

export const searchCatogoryNewsState = (payload) => ({
  type: FETCH_SEARCH_CATEGORY_REQUEST,
  payload
});

export const searchAuthorNewsState = (payload) => ({
  type: FETCH_SEARCH_AUTHOR_REQUEST,
  payload
});

export const resetNewsState = () => ({
  type: RESET_NEWS_STATE
});
