import * as Types from '~/constants/actionTypes';

export const getPharmacyOverViewReport = () => ({
  type: Types.GET_PHARMACY_OVER_VIEW_REPORT_REQUEST
});

export const getPharmacyYearlyReport = () => ({
  type: Types.GET_PHARMACY_YEARLY_REPORT_REQUEST
});

export const getPharmacyQuarterlyReport = () => ({
  type: Types.GET_PHARMACY_QUARTERLY_REPORT_REQUEST
});

export const getPharmacyMonthlyReport = () => ({
  type: Types.GET_PHARMACY_MONTHLY_REPORT_REQUEST
});

export const getPharmacyDailyReport = () => ({
  type: Types.GET_PHARMACY_DAILY_REPORT_REQUEST
});

export const getPharmacyMostCustomerOrder = () => ({
  type: Types.GET_PHARMACY_MOST_CUSTOMER_ORDER_REPORT_REQUEST
});

export const getPharmacyOverviewToday = () => ({
  type: Types.GET_PHARMACY_OVERVIEW_TODAY_REQUEST
})

export const getPharmacyOverviewDaily = () => ({
  type: Types.GET_PHARMACY_OVERVIEW_DAILY_REQUEST
})

export const getPharmacyOverviewMonthly = () => ({
  type: Types.GET_PHARMACY_OVERVIEW_MONTHLY_REQUEST
})

export const getPharmacyOverviewQuarterly = () => ({
  type: Types.GET_PHARMACY_OVERVIEW_QUARTERLY_REQUEST
})

export const getPharmacyOverviewYearly = () => ({
  type: Types.GET_PHARMACY_OVERVIEW_YEARLY_REQUEST
})

export const resetPharmacyDashboardState = () => ({
  type: Types.RESET_PHARMACY_DASHBOARD_STATE
});
