import requester from './requester';

const servicePlace = {
  getAll: query => requester.get('/healthcare-facility', query),
  getById: id => {
    return requester.get(`/healthcare-facility/${id}?raw=true`)
  },
  delete: id => requester.delete(`/healthcare-facility/${id}`),
  create: servicePlace => requester.post('/healthcare-facility', servicePlace),
  update: servicePlace =>
    requester.put(`/healthcare-facility/${servicePlace.id}`, servicePlace)
};

export default servicePlace;