import {
  GET_APPOINTMENTS_REQUEST,
  DELETE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_REQUEST,
  RESET_APPOINTMENT,
  UPDATE_LIST_PRODUCT_APPOINTMENT_REQUEST,
  GET_APPOINTMENTS_OF_HOSPITAL_DELETED_REQUEST
} from '../../constants/actionTypes';

export const getAppointments = query => ({
  type: GET_APPOINTMENTS_REQUEST,
  payload: query
});
export const getAppointmentssOfHistoryDeleted = query => {
  return ({
  type: GET_APPOINTMENTS_OF_HOSPITAL_DELETED_REQUEST,
  payload: query
})};

export const getAppointment = id => ({
  type: GET_APPOINTMENT_REQUEST,
  payload: id
});

export const createAppointment = appointment => ({
  type: CREATE_APPOINTMENT_REQUEST,
  payload: appointment
});

export const updateAppointment = appointment => ({
  type: UPDATE_APPOINTMENT_REQUEST,
  payload: appointment
});

export const deleteAppointment = id => ({
  type: DELETE_APPOINTMENT_REQUEST,
  payload: id
});

export const resetAppointmentState = () => ({
  type: RESET_APPOINTMENT
});
