import { Table } from 'antd'
import React from 'react'

const columns = [
    {
        title : 'Câu hỏi',
        dataIndex : 'question',
        key : 'question',
    },
    {
        title : 'Trả lời',
        dataIndex : 'answer',
        key : 'answer',
    },
]
export default function ANQ({data}) {
  return (
    <Table
        style={{marginTop : 20}}
        columns={columns}
        dataSource={data}
        size='small'
    />
  )
}
