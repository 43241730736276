import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createWhCategoryShort,
  getWhCategoriesShort,
  getWhCategoryShort,
  updateWhCategoryShort
} from '~/redux/whCategoryShort/actions';

const getSelector = (key) => (state) => state.whCategoryShort[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useWhCategoryPaging = () => useSelector(pagingSelector);

export const useWhCategoryShort = (id) => {
  return useFetchByParam({
    action: getWhCategoryShort,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useWhCategoriesShort = (query) => {
  return useFetchByParam({
    action: getWhCategoriesShort,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};
export const useCreateWhCategoryShort = () => {
  const history = useHistory();
  // const callback = () => history.push(`/wh-category`);

  useSuccess(createSuccessSelector, 'Tạo mới mô tả ngắn thành công');
  useFailed(createFailedSelector, 'Tạo mới mô tả ngắn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhCategoryShort
  });
};
export const useUpdateWhCategoryShort = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật mô tả ngắn thành công');
  useFailed(updateFailedSelector, 'Cập nhật mô tả ngắn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhCategoryShort
  });
};

//** */

export const useInitWhCategorySort = (whCategoryShort) =>
  useMemo(() => {
    if (!whCategoryShort) {
      return {
        image: {}
      };
    }

    return whCategoryShort;
  }, [whCategoryShort]);
