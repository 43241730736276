import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Skeleton,
  Space,
  Tabs
} from 'antd';

import { get,groupBy } from 'lodash';
import { BaseBorderBox } from '~/components/Common';
import Api from '~/api'
import { floorFormatter, formatter } from '~/utils/helper';
import moment from 'moment';
import { useWhAppointments, useWhPackageLevels, useWhSessionOfDayQueryParams, useWhSessionsOfDay } from '~/hooks';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
const Printer = ({ url }) => {
  const docs = [
    { uri: url }
    // { uri: "https://url-to-my-pdf.pdf" },
    // { uri: require("./example-files/pdf.pdf") }, // Local File
  ];

  return <DocViewer documents={docs} />;
};
const FormItem = Form.Item;

const ModalPartnerTransaction = ({ whBillItem }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [modalTransaction, setModalTransaction] = useState({})

  useEffect(() => {
    setIsLoading(!whBillItem);
    const { actualCost, checkIn, checkOut, price, vat, extraPrice, extraQuantity, grandTotal, subTotal, date, whReceiptVoucher, whPaymentVoucher, snapshotService, ...rest } = whBillItem[0]
    form.setFieldsValue({...rest})
    const checkInTime =checkIn.time
    const checkOutTime = checkOut.time
    // form.setFieldsValue({customerNumber: get(whBillItem, 'whReceiptVoucher[0].customerNumber')  ?? get(whBillItem, 'whPaymentVoucher[0].customerNumber')})
    form.setFieldsValue({ customerName: whReceiptVoucher[0].customerName ?? whPaymentVoucher[0].customerName })
    form.setFieldsValue({ customerNumber: whReceiptVoucher[0].customerNumber ?? whPaymentVoucher[0].customerNumber })
    form.setFieldsValue({packageLevelName: snapshotService.package.packageLevelName.vi})
    form.setFieldsValue({serviceName: snapshotService.name.vi})
    form.setFieldsValue({serviceCode: snapshotService.code})
    form.setFieldsValue({date : moment(date).format('DD-MM-YYYY')})
    form.setFieldsValue({setPrice: floorFormatter(price * (1 + vat))})
    form.setFieldsValue({setMorePrice: floorFormatter(extraPrice * extraQuantity * (1 + vat))})
    form.setFieldsValue({ grandTotalPrice: formatter(grandTotal)})
    form.setFieldsValue({ totalPrice: formatter(subTotal)})
    form.setFieldsValue({ pricePayable: formatter(actualCost) })
    form.setFieldsValue({checkIn : moment(checkInTime).format('DD-MM-YYYY HH:mm')})
    form.setFieldsValue({checkOut : moment(checkOutTime).format('DD-MM-YYYY HH:mm')})
}, [whBillItem])
  
  return (
          <div className="page-wraper">
            <div className="container-fluid">
              <Form
                initialValues
                // wrapperCol={{ sm: 24, md: 24, lg: 18 }}
                autoComplete="off"
                form={form}
                labelAlign="left"
                labelCol={{ sm: 24, md: 24, lg: 4 }}
                onValuesChange
                requiredMark={false}
              // scrollToFirstError
              >
                <Row
                  align="top"
                  className="staff-form__logo-row"
                  justify="space-between"
                >
                  <Col span={24}>
                    <BaseBorderBox title={'Thông tin khách hàng'}>
                      <Row gutter={36}>
                        <Col span={12}>
                          <FormItem
                            label={'Mã khách hàng'}
                            labelCol={{ lg: 8 }}
                            name="customerNumber"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem
                            // label={<>Tên khách hàng{` `}<code>customerName</code></>}
                            label="Tên khách hàng"
                            labelCol={12}
                            name="customerName"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </BaseBorderBox>

              <BaseBorderBox title={'Thông tin buổi hẹn'}>
                <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label="Tên dịch vụ"
                            labelCol={{ lg: 8 }}
                            name={"serviceName"}  
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Mã dịch vụ"
                            labelCol={{ lg: 7 }}
                            name="serviceCode"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                  </Col>
                  <Col span={8}>
                          <FormItem
                            label="Gói dịch vụ"
                            labelCol={{ lg: 7 }}
                            name="packageLevelName"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label="Số buổi"
                            labelCol={{ lg: 8 }}
                          name={"ordinalNumber"}  
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Mã buổi hẹn"
                            labelCol={{ lg: 7 }}
                          // name="customerName"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                     
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label="Thêm giờ"
                            labelCol={{ lg: 8 }}
                          // name="customerName"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Giờ"
                            labelCol={{ lg: 7 }}
                          name="time"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Ngày"
                            labelCol={{ lg: 7 }}
                            name="date"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label="Tổng thời lượng"
                            labelCol={{ lg: 8 }}
                          // name="customerName"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Buổi"
                            labelCol={{ lg: 7 }}
                          // name="customerName"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Dự kiến xong"
                            labelCol={{ lg: 7 }}
                          // name="customerName"
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col span={8}>
                          <FormItem
                            label="Thời gian checkin"
                            labelCol={{ lg: 10 }}
                            name={'checkIn'}
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Thời gian checkout"
                            labelCol={{ lg: 10 }}
                            name={"checkOut"}
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={36}></Row>

                      {/* <Row gutter={36}>
                        <Col span={24}>
                          <FormItem
                            // label={<>Địa chỉ{` `}<code>customerAddressId</code></>}
                            labelCol={18}
                            label="Địa chỉ"
                            name={'customerAddress'}
                          >
                            {isLoading ? (
                              <Skeleton.Input active />
                            ) : (
                              <Input style ={{color: "#333"}} disabled bordered={false} />
                            )}
                          </FormItem>
                        </Col>
                      </Row> */}
                    </BaseBorderBox>
                    {/* <Row gutter={36}>
                
              </Row> */}
                  </Col>
                </Row>
                <BaseBorderBox title={'Giá tiền'}>
                  <Row gutter={36}>
                    <Col span={8}>
                      <FormItem
                        label="Giá ban đầu"
                        name={"setPrice"}
                        labelCol={{ lg: 6 }}
                      >
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input style ={{color: "#333"}} disabled bordered={false} />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Giá cộng thêm"
                        name={'setMorePrice'}
                        labelCol={{ lg: 7 }}
                      >
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input style ={{color: "#333"}} disabled bordered={false} />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Tạm tính"
                        name={'grandTotalPrice'}
                        labelCol={{ lg: 5 }}
                      >
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input style ={{color: "#333"}} disabled bordered={false} />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  {/* <Row gutter={36}>
                    <Col span={8}>
                      <FormItem
                        label="Đã cọc công ty"
                        name={"paidForCompany"}
                        labelCol={{ lg: 10 }}
                      >
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input style ={{color: "#333"}} disabled bordered={false} />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Phải thu"
                        name={'pricePayable'}
                        labelCol={{ lg: 5 }}
                      >
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input style ={{color: "#333"}} disabled bordered={false} />
                        )}
                      </FormItem>
                    </Col>
                  </Row> */}
                </BaseBorderBox>
                <BaseBorderBox title={'Thông tin đính kèm'}>
                  <Row>
                    <Col span={24}>
                      <FormItem
                        label="Thông tin"
                        name={'note'}
                        labelCol={{ lg: 2 }}
                      >
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input style ={{color: "#333"}} disabled bordered={false} />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </BaseBorderBox>
                <BaseBorderBox title={'Tổng'}>
                  <Row gutter={36}>
                    <Col span={8}>
                      <FormItem
                        label="Tổng tiền dịch vụ"
                        name={'totalPrice'}
                        labelCol={{ lg: 10 }}
                      >
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input style ={{color: "#333"}} disabled bordered={false} />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Thực thu/chi"
                        name={'pricePayable'}
                        labelCol={{ lg: 6 }}
                      >
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          <Input style ={{color: "#333"}} disabled bordered={false} />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </BaseBorderBox>
              </Form>
            </div>

            {/* <Modal
      visible={isOpenViewer}  
      // onCancel={() => setIsOpenViewer(false)}
    >
      <Printer url={url} />
    </Modal> */}
          </div>
  );
};
export default ModalPartnerTransaction;
