import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

export default function YoutubeEmbed({ id, poster = 'maxresdefault', title = '' }) {
    return (
        <div style={{borderRadius : 16,overflow : 'hidden'}}>
            <div className='video-responsive'>
                <LiteYouTubeEmbed
                    id={id}
                    poster={poster}
                    title={title}
                    loading='lazy'
                />
            </div>
        </div>
    )
}
