import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getWhPartnersImport({ payload: query }) {
  try {
    const data = yield call(Api.whPartnerImport.getAll, query);
    yield put({ type: Types.GET_WH_PARTNERS_IMPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_PARTNERS_IMPORT_FAILED,
      payload: error.message
    });
  }
}

function* getWhPartnerImport({ payload: id }) {
  try {
    const whPartnerImport = yield call(Api.whPartnerImport.getById, id);
    yield put({
      type: Types.GET_WH_PARTNER_IMPORT_SUCCESS,
      payload: whPartnerImport
    });
  } catch (error) {
    yield put({
      type: Types.GET_WH_PARTNER_IMPORT_FAILED,
      payload: error.message
    });
  }
}

function* createWhPartnerImport(action) {
  try {
    const data = yield call(Api.whPartnerImport.create, action.payload);
    yield put({ type: Types.CREATE_WH_PARTNER_IMPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_WH_PARTNER_IMPORT_FAILED,
      payload: error.message
    });
  }
}

function* updateWhPartnerImport(action) {
  try {
    const data = yield call(Api.whPartnerImport.update, action.payload);
    yield put({ type: Types.UPDATE_WH_PARTNER_IMPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_PARTNER_IMPORT_FAILED,
      payload: error.message
    });
  }
}

function* deleteWhPartnerImport({ payload }) {
  try {
    yield call(Api.whPartnerImport.delete, payload);
    yield put({ type: Types.DELETE_WH_PARTNER_IMPORT_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_PARTNER_IMPORT_FAILED,
      payload: error.message
    });
  }
}

function* convertWhPartnerImport({ payload }) {
  try {
    const data = yield call(Api.whPartnerImport.convert, payload);
    yield put({
      type: Types.CONVERT_WH_PARTNER_IMPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.CONVERT_WH_PARTNER_IMPORT_FAILED,
      payload: error
    });
  }
}

export default function* whPartnerImport() {
  yield takeLatest(Types.GET_WH_PARTNERS_IMPORT_REQUEST, getWhPartnersImport);
  yield takeLatest(Types.GET_WH_PARTNER_IMPORT_REQUEST, getWhPartnerImport);
  yield takeLatest(
    Types.DELETE_WH_PARTNER_IMPORT_REQUEST,
    deleteWhPartnerImport
  );
  yield takeLatest(
    Types.CREATE_WH_PARTNER_IMPORT_REQUEST,
    createWhPartnerImport
  );
  yield takeLatest(
    Types.UPDATE_WH_PARTNER_IMPORT_REQUEST,
    updateWhPartnerImport
  );
  yield takeLatest(
    Types.CONVERT_WH_PARTNER_IMPORT_REQUEST,
    convertWhPartnerImport
  );
}
