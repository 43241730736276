import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { compact, find, get, head } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import Icon, { ClockCircleOutlined, CreditCardOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Form, Input, InputNumber, Modal, Row, Space, Table, Tag, Tooltip } from 'antd';

import { HOURS_TO_COLLECT_PREPAYMENT, MAX_PAGINATION_LIMIT, PLACE_DID_SERVICE, WH_BILL_ITEM_STATUS, WH_BILL_STATUS, WH_BILL_STATUS_EN, WH_PAYMENT_METHOD } from '~/constants/defaultValue';
import { formatter } from '~/utils/helper';
import { useCities, useMatchPolicy,useResetWhBillConfirm, useServicePlaces } from '~/hooks';
import POLICY, { CORE_ACTION, GROUP_POLICY } from '~/constants/policy';

import { useWhSessionsOfDay, useWhSessionOfDayQueryParams } from '~/hooks/whSessionOfDay';

import MoneySvg from '../WhPartner/MoneySvg';
import WhBillItemModal from '../WhBillItem/WhBillItemModal';
import WhPaymentVoucherForm from '../WhVoucher/Form/WhPaymentVoucherForm';
import WhReceiptVoucherForm from '../WhVoucher/Form/WhReceiptVoucherForm';
import WhVoucherTabs from '../WhVoucher';

import './wh-bills-processing.scss';
import HistoryLogs from './HistoryLog';
import { getNamePlaceDidService, formatNumberThreeComma } from '~/hooks/utils';
import { WithOrPermission } from '../Common';
import AddressCustomerBill from './AddressCustomerBill';
import TooltipTitle from '../Common/TooltipTitle';
import { getReward } from './quotation/service';

const descriptionItemProps = {
  contentStyle: {
    fontWeight: "bold",
    textAlign: "right",
  }
}

const Countdown = ({ whBill }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let timeoutId
    if (seconds > 0) {
      timeoutId = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds('Đã hết thời gian chờ nhận tạm ứng');
    }
    return () => {
      clearTimeout(timeoutId);
    }
  })

  useEffect(() => {
    const deadline = moment(get(whBill, "createdAt")).add(HOURS_TO_COLLECT_PREPAYMENT, "hour");
    const now = moment();
    const remainingInSeconds = moment.duration(deadline.diff(now)).asSeconds();
    setSeconds(remainingInSeconds);
  }, [whBill])

  return seconds > 0
    ? (
      <Space size="middle" direction="horizontal">
        <h4>
          <ClockCircleOutlined style={{ color: '#1890ff', marginRight: '8px' }} />
          {moment.utc(moment.duration(seconds, 'second').as('milliseconds')).format('HH:mm:ss')}
        </h4>
      </Space>
    ) : (
      <Space size="middle" direction="horizontal">
        <h4>
          <ClockCircleOutlined style={{ color: '#1890ff', marginRight: '8px' }} />
          00:00:00
        </h4>
      </Space>
    )
}

const WhBillConsole = ({
  cancelWhBillSuccess,
  confirmPrepaymentWhBillSuccess,
  customerCancelWhBillSuccess,
  handleCancelBill,
  handleConfirmPrepayment,
  handleCustomerCancel,
  initCancelValues,
  initPrepaymentValues,
  isCancelBillLoading,
  isConfirmPrepaymentLoading,
  isCustomerCancelWhBillLoading,
  isOpenCancelModal,
  isOpenConfirmPrepaymentModal,
  isOpenCustomerCancelWhBillModal, // TODO: Not implemented. Discussing with BE team whether a wh-bill can have status \"CUSTOMER_CANNCEL\" or not.
  isSubmitLoading,
  setIsOpenCancelModal,
  setIsOpenConfirmPrepaymentModal,
  setIsOpenCustomerCancelWhBillModal,
  whBill,
  isHandlingConfirmWhBill = false,
  customerAddress,
  isShowAddress,
}) => {
  const editableStatus = useMatchPolicy(POLICY.UPDATE_WHBILL);
  const [form] = Form.useForm();
  const [cancelForm] = Form.useForm();
  const [selectedBillItem, setSelectedBillItem] = useState();
  const [selectedBillItemIdx, setSelectedBillItemIdx] = useState();
  const [isOpenWhBillItemModal, setIsOpenWhBillItemModal] = useState(false);
  const [isOpenAllVouchersModal, setIsOpenAllVouchersModal] = useState(false);
  const [isOpenReceiptVouchersModal, setIsOpenReceiptVouchersModal] = useState(false);
  const [isOpenReceiptVouchersWhBillModal, setIsOpenReceiptVouchersWhBillModal] = useState(false);
  const [isOpenPaymentVouchersModal, setIsOpenPaymentVouchersModal] = useState(false);
  const [querySessions,] = useWhSessionOfDayQueryParams(MAX_PAGINATION_LIMIT);
  const [whSessionsOfDay, isWhSessionsOfDayLoading] = useWhSessionsOfDay(querySessions);
  const [cities, isCitiesLoading] = useCities()
  const queryFetchPlaceDidService = useMemo(() => ({limit : MAX_PAGINATION_LIMIT , state : 'ACTIVE'}),[])
  const [servicePlaces, isLoadingPlace] = useServicePlaces(queryFetchPlaceDidService); 
  const [newCustomerAddress, setCustomerAddress] = useState(customerAddress);
  const [isOpenModalUpdateAddress, setIsOpenModalUpdateAddress] = useState(false);
  const [amountMoney, setAmountMoney] = useState(0);
  const history = useHistory();
  useEffect(() => {
    form.setFieldsValue({
      actualPrepaymentAmount: formatNumberThreeComma(get(whBill, "prePayAmount")),
    })

    if (get(whBill, "actualPrepaymentAmount") > 0) {
      setIsOpenConfirmPrepaymentModal(false);
    }
  }, [whBill]);

  useEffect(() => {
    if (!isConfirmPrepaymentLoading && confirmPrepaymentWhBillSuccess) {
      setIsOpenConfirmPrepaymentModal(false);
    }
  }, [isConfirmPrepaymentLoading, confirmPrepaymentWhBillSuccess])

  useEffect(() => {
    if (!isCancelBillLoading && cancelWhBillSuccess) {
      setIsOpenCancelModal(false);
    }
  }, [isCancelBillLoading, cancelWhBillSuccess]);

  useEffect(() => {
    if (!isCustomerCancelWhBillLoading && customerCancelWhBillSuccess) {
      setIsOpenCustomerCancelWhBillModal(false);
    }
  }, [isCustomerCancelWhBillLoading, customerCancelWhBillSuccess]);

  const mergedWhBillItems = useMemo(() => {
    return get(whBill, "billItems")?.map(item => ({
      ...item,
      whSessionOfDay: find(whSessionsOfDay, { _id: get(item, "whSessionOfDayId") }),
    }))
  }, [whBill, whSessionsOfDay]);
const widthColumn = 5
  const columns = [
    {
      title: 'ID Dịch vụ',
      key: 'code',
      width : widthColumn,
      align : 'center',
      render: (text, record, index) => {
        const isAvailable = !get(record, 'messageErrorService',[])?.length;
        const message = get(record, 'messageErrorService',[])?.join(',');
        return <div>
        {
          get(record, "status") === WH_BILL_ITEM_STATUS.NEW ? get(record, "snapshotService.code")
        : <Link to={`/wh-bill-item/${get(record, "_id")}/update-appointments`}>
          {get(record, "snapshotService.code")}
        </Link>
        }
        {isHandlingConfirmWhBill && <Tooltip zIndex={9999} title={message}>
          {isAvailable ? <p>
          <Tag bordered={false} color="success">
        Được phép tiếp nhận
      </Tag>
        </p> : <p>
          <Tag bordered={false} color="error">
        Không được phép tiếp nhận
      </Tag>
        </p>}
        </Tooltip>}
      </div>
      }
    },
    {
      // title: 'Dịch vụ',
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <span>Dịch vụ</span>
        </div>
      ),
      key: 'name',
      width : 10,
      render: (record) => {
        if (get(record, "snapshotService.detailName.vi"))
          return `${get(record, "snapshotService.name.vi")} - ${get(record, "snapshotService.detailName.vi")}`
        else 
          return get(record, "snapshotService.name.vi")
      },
    },
    {
      // title: 'Loại dịch vụ',
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <span>Loại dịch vụ</span>
        </div>
      ),
      key: 'type',
      width : widthColumn,
      render: (text, record, index) => {
        const renderType = record => {
          return `
            ${get(record, "snapshotService.package.packageLevelName.vi",'')} - 
            ${get(record, "whSessionOfDay.name.vi",'')}
          `
        }
        return renderType(record)
      }
    },
    {
      title: 'Thời gian thực hiện',
      key: 'period',
      width : widthColumn,
      render: (text, record, index) => {
        const renderPeriod = (record) => {
          return `
            ${moment(get(record, 'startDate')).format('DD/MM/YYYY')} - 
            ${moment(get(record, 'endDate')).format('DD/MM/YYYY')}
          `
        }
        return renderPeriod(record);
      }
    },
    {
      title: 'Nơi thực hiện dịch vụ',
      key: 'placeDidService',
      width : widthColumn,
      render: (text, record, index) => {
       return getNamePlaceDidService(record)
      }
    },
    
    {
      title: 'Buổi hẹn tặng kèm',
      key: 'bonusWhAppointments',
      dataIndex:'bonusWhAppointments',
      width : widthColumn,
      render: (bonusWhAppointments, record, index) => {
       return getReward(head(bonusWhAppointments));
      }
    },

    {
      // title: 'Số lượng',
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <span>Số lượng</span>
          {/* <code>quantity</code> */}
        </div>
      ),
      width : widthColumn,
      key: 'quantity',
      dataIndex: 'quantity'
    },
    {
      // title: 'Thành tiền',
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <span>Thành tiền</span>
          {/* <code>grandTotal</code> */}
        </div>
      ),
      key: 'grandTotal',
      dataIndex: 'grandTotal',
      width : widthColumn,
      render: (text) => formatter(text  || 0)
    },
    {
      // title: 'Đã thanh toán và xác nhận',
      title: 'Đã thanh toán và xác nhận',
      width : 7,
      key: 'actualTotalAmountPaid',
      dataIndex: 'actualTotalAmountPaid',
      render: (text,record) => formatter(text  || 0)
    },

    {
      title: 'Tiền đã chi trả khách hàng',
      width : 7,
      key: 'totalPaymentVoucherBillAmount',
      dataIndex: 'totalPaymentVoucherBillAmount',
      render: (text,record) => formatter(get(record,'totalPaymentVoucherBillAmount',0)) || 0
    },

    {
      // title: 'Số tiền còn lại',
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
        
          <span>Số tiền còn lại</span>
          {/* <code>remainingAmount</code> */}
        </div>
      ),
      width : widthColumn,
      key: 'remainingAmount',
      dataIndex: 'remainingAmount',
      render: (text) => formatter(text) || 0
    },
    // {
    //   title: 'Chứng từ',
    //   key: 'vouchers',
    //   fixed: 'right',
    //   width: 100,
    //   render: (text, record, index) => (
    //     <Row direction='column'  justify='center'>
    //       <Button
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           setSelectedBillItem(record);
    //           setSelectedBillItemIdx(index);
    //           setIsOpenReceiptVouchersModal(true);
    //         }}
    //       >
    //         Phiếu thu
    //       </Button>
    //       <Button
    //       style={{margin : 0}}
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           setSelectedBillItem(record);
    //           setSelectedBillItemIdx(index);
    //           setIsOpenPaymentVouchersModal(true);
    //         }}
    //       >
    //         Phiếu chi
    //       </Button>
    //       <Button
    //       style={{margin : 0}}
    //         type="link"
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           setSelectedBillItem(record);
    //           setSelectedBillItemIdx(index);
    //           setIsOpenAllVouchersModal(true);
    //         }}
    //       >
    //         Xem chi tiết
    //       </Button>
    //     </Row>
    //   )
    // }
    
    /* {
      // title: 'Actual cost',
      title: <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}><span>Actual cost</span> <code>actualCost</code></div>,
      key: 'actualCost',
      dataIndex: 'actualCost',
      render: (text) => formatter(text) || 0
    },
    {
      // title: 'Tiền đã thanh toán dịch vụ',
      title: <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}><span>Tiền đã thanh toán dịch vụ</span> <code>totalAmountPaid</code></div>,
      key: 'totalAmountPaid',
      dataIndex: 'totalAmountPaid',
      render: (text) => formatter(text) || 0
    },
    {
      // title: 'Số tiền còn lại',
      title: <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}><span>Số tiền còn lại</span> <code>remainingAmount</code></div>,
      key: 'remainingAmount',
      dataIndex: 'remainingAmount',
      render: (text) => formatter(text) || 0
    }, */
    // {
    //   title: 'DV trừ tạm ứng',
    // },
  ];

  if(editableStatus && !isHandlingConfirmWhBill){
    columns.push({
      title: 'Thao tác',
      key: 'confirm',
      width : (history.location.pathname === '/wh-bill/processing' ? 6 : 3 ),
      fixed: 'right',
      render: (text, record, index) => {
          // bill là NEW và ko prePay
          // bill là PREPAYMENT_CONFIRMED
          // bill là IN_PROGRESS
          // (
          //   (get(whBill, "status") === "NEW" && !get(whBill, "prePay")) ||
          //   (["PREPAYMENT_CONFIRMED", "IN_PROGRESS"].includes(get(whBill, "status")))
          // ) &&

          // record là NEW
          return (
            <Button
              disabled={get(record, 'status') !== WH_BILL_ITEM_STATUS.NEW}
              type="primary"
              onClick={() => {
                if(get(record, 'status') === WH_BILL_ITEM_STATUS.NEW){
                  setSelectedBillItem(record);
                setSelectedBillItemIdx(index);
                setIsOpenWhBillItemModal(true);
                }
                else{
                }
               
              }}
              loading={isSubmitLoading}
            >
              {'Xử lý'}
            </Button>
          );
        }
    })
  }
  const onConfirmPrepaymentAmount = () => {
    const values = form.getFieldsValue();
    handleConfirmPrepayment({ ...values, _id: get(whBill, "_id") });
  }
  const handleOpenFormVoucherReceipt = () => {
    setIsOpenReceiptVouchersWhBillModal(true)

  }

  const onCancelBill = () => {
    const { message } = cancelForm.getFieldsValue();
    handleCancelBill({ _id: get(whBill, "_id"), message });
  }

  const onCustomerCancel = () => {
    const submitData = { _id: get(whBill, "_id")}
    handleCustomerCancel(submitData);
  }

  const onCloseReceiptVouchersModal = () => {
    setIsOpenReceiptVouchersModal(false);
    setIsOpenCustomerCancelWhBillModal(false);
    setIsOpenReceiptVouchersWhBillModal(false);
    setSelectedBillItem(null);
    setSelectedBillItemIdx(null);
    setAmountMoney(0);
  }

  const onClosePaymentVouchersModal = () => {
    setIsOpenPaymentVouchersModal(false);
    setSelectedBillItem(null);
    setSelectedBillItemIdx(null);
  }
  return (
    <>
      <Space direction='vertical' size="middle" style={{ width: "100%" }}>
        {!isHandlingConfirmWhBill && <Row justify='space-between'>
          <Col>
            <Row>
              <Space align='top'>
                <h3>THÔNG TIN LỊCH HẸN</h3>
                <WithOrPermission permission={GROUP_POLICY(CORE_ACTION.WRITE)?.MANAGE_VOUCHER_IN_WHBILL}>
                  <Button onClick={() => {
                    setAmountMoney(get(whBill, 'remainingAmount',0) - get(whBill, 'totalPaymentVoucherBillAmount',0));
                    setIsOpenReceiptVouchersModal(true);
                  }}>Phiếu thu</Button>
                  <Button onClick={() => {
                    setIsOpenPaymentVouchersModal(true);
                  }}>Phiếu chi</Button>
                </WithOrPermission>
                <WithOrPermission permission={GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_VOUCHER_IN_WHBILL}>
                <Button type="link" onClick={() => setIsOpenAllVouchersModal(true)}>Xem chi tiết</Button>
                </WithOrPermission>
              </Space>
            </Row>
          </Col>
          {
            get(whBill, "status") === 'NEW' && get(whBill, "prePay") && (
              <Countdown whBill={whBill} />
            )
          }
        </Row>}
        <Table
          rowKey={(rc) => get(rc, "_id") || get(rc, "id")}
          columns={columns}
          scroll={{ x: 2500 }}
          dataSource={mergedWhBillItems}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                if(!isHandlingConfirmWhBill){
                setSelectedBillItem(record);
                setSelectedBillItemIdx(rowIndex);
                setIsOpenWhBillItemModal(true);
                }
              },
            };
          }}
        />

        <Row align='bottom'>
      
          <Col span={16}>
            <Descriptions bordered column={1}>
              <Descriptions.Item
                {...descriptionItemProps}
                label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Tổng tiền dịch vụ {get(whBill,'dealPrice') ? <Tag color='processing'>Giá đã deal</Tag>:null}</span> 
                </div>}
              >
                {get(whBill,'dealPrice') ? <Row justify='end'>
                  <span style={{textDecoration : 'line-through',fontSize : 12}}>{formatter(get(whBill, "subTotal"))}</span> 
                    <span>{formatter(get(whBill, "subTotal") + get(whBill,'dealPrice'))}</span>
                  </Row>
                    : formatter(get(whBill, "subTotal"))}
                
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Tổng phụ thu</span> 
                </div>}
              >
                {formatter(get(whBill, "bonus",0))}
              </Descriptions.Item>
              
              {formatter(get(whBill, "discountAmount") >0) &&
                <Descriptions.Item
                {...descriptionItemProps}
                // label="Giảm giá"
                label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Giảm giá {get(whBill, "discountCode")}</span> 
                {/* <code>discountAmount</code> */}
                </div>}
              >
                {formatter(get(whBill, "discountAmount") || 0)}
              </Descriptions.Item>
              }
              <Descriptions.Item
                {...descriptionItemProps}
                label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Dùng điểm thưởng</span> 
                </div>}
              >
                {formatter(get(whBill, "refund",0))}
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Chiết khấu</span> 
                </div>}
              >
                {formatter(get(whBill, "discountedRates",0))}
              </Descriptions.Item>


              <Descriptions.Item
                {...descriptionItemProps}
                // label="Tổng tiền (chưa VAT)"
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Tổng tiền (chưa VAT)</span>{' '}
                    {/* <code>subTotal - discountAmount</code> */}
                  </div>
                }
              >
                {formatter(
                  get(whBill, 'subTotal') - get(whBill, 'discountAmount') - get(whBill, 'refund',0) - get(whBill, "discountedRates",0) + get(whBill, "bonus",0) + get(whBill,'dealPrice',0)
                )}
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                // label={`VAT (${get(whBill, "vat") * 100}%)`}
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>VAT ({get(whBill, 'vat') * 100}%)</span>{' '}
                    {/* <code>vatAmount</code> */}
                  </div>
                }
              >
                {formatter(get(whBill, 'vatAmount'))}
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                // label="Tổng tiền (đã VAT)"
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Tổng tiền (đã VAT)</span> 
                    {/* <code>grandTotal</code> */}
                  </div>
                }
                span={2}
              >
                {formatter(get(whBill, 'grandTotal'))}
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                // label="Tiền khách hàng muốn tạm ứng"
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Tiền khách hàng muốn tạm ứng</span>{' '}
                    {/* <code>prePayAmount</code> */}
                  </div>
                }
              >
                {formatter(get(whBill, 'prePayAmount') || 0)}
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                // label="Tiền tạm ứng đã xác nhận"
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Tiền tạm ứng đã xác nhận</span>{' '}
                    {/* <code>actualPrepaymentAmount</code> */}
                  </div>
                }
              >
                {formatter(get(whBill, 'actualPrepaymentAmount') || 0)}
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                // label="Tiền đã thanh toán dịch vụ"
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Tiền đã thanh toán dịch vụ</span>{' '}
                    {/* <code>totalAmountPaid</code> */}
                  </div>
                }
              >
                {formatter(get(whBill, 'totalAmountPaid') || 0)}
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                // label="Đã thanh toán và xác nhận"
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Đã thanh toán và xác nhận</span>{' '}
                    {/* <code>actualTotalAmountPaid</code> */}
                  </div>
                }
              >
                {formatter(get(whBill, 'actualTotalAmountPaid') + get(whBill,'totalReceiptVoucherBillAmount',0) - get(whBill,'actualPrepaymentAmount',0) || 0)}
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                // label="Đã thanh toán và xác nhận"
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Tiền đã chi trả đơn hàng</span>{' '}
                    {/* <code>actualTotalAmountPaid</code> */}
                  </div>
                }
              >
                {formatter(get(whBill, 'totalPaymentVoucherBillAmount',0) + get(whBill, 'totalPaymentVoucherBillItemAmount',0) || 0)}
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                // label="Số tiền còn lại"
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Số tiền còn lại</span> 
                    {/* <code>remainingAmount</code> */}
                  </div>
                }
              >
                {formatter(get(whBill, 'remainingAmount') - get(whBill, 'totalPaymentVoucherBillAmount',0))}
              </Descriptions.Item>

              <Descriptions.Item
                {...descriptionItemProps}
                // label="Số tiền còn lại"
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Doanh thu</span> 
                    {/* <code>remainingAmount</code> */}
                  </div>
                }
              >
                {formatter(get(whBill, 'actualTotalAmountPaid') - get(whBill, 'totalPaymentVoucherBillAmount',0) + get(whBill,'totalReceiptVoucherBillAmount',0))}
              </Descriptions.Item>
              <Descriptions.Item
                {...descriptionItemProps}
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Số tiền trả lại cho khách hàng(nếu có) </span> 
                  </div>
                }
              >
                {formatter(get(whBill, 'sumAppointmentCancel', '0'))}
              </Descriptions.Item>

            </Descriptions>
          </Col>
          <Col span={16}>
            <Descriptions bordered column={1}>
              <Descriptions.Item
                {...descriptionItemProps}
                label={
                  <Space>
                    {
                      {
                        [WH_PAYMENT_METHOD.COD]: <Icon component={MoneySvg} style={{ transform: 'scale(0.9)', margin: '2px 0 0 2px' }} />,
                        [WH_PAYMENT_METHOD.TRANSFER]: <CreditCardOutlined style={{ color: "#3481FF" }} />,
                      }[get(whBill, "paymentMethod")]
                    }
                    <span>Phương thức thanh toán</span>
                  </Space>
                }
              >
                {
                  {
                    [WH_PAYMENT_METHOD.COD]: "Tiền mặt",
                    [WH_PAYMENT_METHOD.TRANSFER]: "Chuyển khoản",
                  }[get(whBill, "paymentMethod")]
                }
              </Descriptions.Item>
              {get(whBill, "status") === "CANCELLED" && <Descriptions.Item
                {...descriptionItemProps}
                label={
                  <Space>
                    {
                      {
                        ["CANCELLED"]: <StopOutlined style={{ color: "#ff4d4f" }} />,
                      }[get(whBill, "status")]
                    }
                    <span>Lý do hủy</span>
                  </Space>
                }
              >
                {
                  {
                    ["CANCELLED"]: get(whBill, `reasonOfStatuses.CANCELLED`),
                  }[get(whBill, "status")]
                }
              </Descriptions.Item>}
            </Descriptions>
          </Col>
        </Row>
      { isShowAddress && <Row>
          <Col span={16}>
            <Descriptions column={1} bordered>
              <Descriptions.Item
                labelStyle ={{ display: "flex", justifyContent: "space-between"}}
                {...descriptionItemProps}
                label={
                  <div style={{ display: "flex", justifyContent: "space-between"}}><span>
                    Địa chỉ</span></div>
                  }>
                {compact([newCustomerAddress?.street, newCustomerAddress?.ward, newCustomerAddress?.district, newCustomerAddress?.city]).join(", ")}
                  {(whBill?.status === WH_BILL_STATUS_EN.NEW || whBill?.status === WH_BILL_STATUS_EN.PREPAYMENT_CONFIRMED)&& <EditOutlined style={{marginLeft: '10px', cursor: 'pointer', color: '#50a5f1'}} onClick={() => setIsOpenModalUpdateAddress(true)}/>}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        }
      </Space>

      {get(whBill, 'historyLogs', [])?.length ?  <HistoryLogs historyLogs={get(whBill, 'historyLogs', [])}></HistoryLogs> : null}

      <WhBillItemModal
        onCancel={() => {
          setIsOpenWhBillItemModal(false);
          setSelectedBillItem(null);
          setSelectedBillItemIdx(null);
        }}
        cities={cities}
        isCitiesLoading={isCitiesLoading}
        servicePlaces={servicePlaces}
        isLoadingPlace={isLoadingPlace}
        visible={isOpenWhBillItemModal}
        whBillItem={selectedBillItem}
        whBillItemIdx={selectedBillItemIdx}
      />

      <Modal
        visible={isOpenCancelModal}
        onOk={onCancelBill}
        onCancel={() => setIsOpenCancelModal(false)}
        title={"Bạn có chắc chắn muốn xác nhận hủy đơn hàng này?"}
        confirmLoading={isCancelBillLoading}
        closable={false}
      >
        <Form
          form={cancelForm}
          initialValues={initCancelValues}
        >
          <Form.Item
            name="message"
            label="Lý do hủy đơn hàng"
            wrapperCol={{ xs: 24, md: 24 }}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={isOpenConfirmPrepaymentModal}
        onOk={handleOpenFormVoucherReceipt}
        onCancel={() => setIsOpenConfirmPrepaymentModal(false)}
        title={
          get(whBill, 'isPrepayFull')
          ? "Bạn có chắc chắn khách hàng đã thanh toán cho đơn hàng này"
          : "Bạn có chắc chắn muốn xác nhận khách hàng đã tạm ứng cho đơn hàng này?"
        }
        // confirmLoading={isConfirmPrepaymentLoading}
        closable={false}
      >
        {/* <p className="blink-me" style={{ color: "orange", fontSize: "12px" }}>
          <Badge color="orange" />Warning: Hiện tại không cần xác nhận theo một số tiền ngẫu nhiên, có thể bỏ qua modal này
        </p> */}
        <Form
          form={form}
          initialValues={initPrepaymentValues}
        >
          <Form.Item
            name="actualPrepaymentAmount"
            label={get(whBill, 'isPrepayFull') ? "Số tiền thanh toán" : "Số tiền đã tạm ứng"}
            wrapperCol={{ xs: 24, md: 24 }}
          >
            <InputNumber
              disabled // at the moment we don't allow confirming random actualPrepaymentAmount
              min={0}
              max={get(whBill, "grandTotal")}
              formatter={(value) => formatNumberThreeComma(value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        footer={false}
        onCancel={() => setIsOpenAllVouchersModal(false)}
        onOk={() => { }}
        title={"Sổ quỹ"}
        visible={isOpenAllVouchersModal}
        width={1500}
      >
        <WhVoucherTabs
          noShowAtHome
          whBill={whBill}
          showTopPadding={false}
          useLocalPagination={true}
          history={history}
          noShowExportButton
          noShowSearchTime
          noShowAllVoucher
          noShowTitle 
          useTime 
        />
      </Modal>

      <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={onCloseReceiptVouchersModal}
        onOk={() => {}}
        title={`Phiếu thu - Đơn hàng ${get(whBill, 'billNumber')} - ${get(
          whBill,
          'customerAddress.fullName'
        )}`}
        visible={isOpenReceiptVouchersModal}
        width={1500}
      >
        <WhReceiptVoucherForm
          whBill={whBill}
          whBillItem={selectedBillItem}
          onCancel={onCloseReceiptVouchersModal}
          amountMoney={amountMoney}
        />
      </Modal>
      <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={onCloseReceiptVouchersModal}
        onOk={() => {}}
        title={`Phiếu thu -Chấm dứt Đơn hàng ${get(whBill, 'billNumber')} - ${get(
          whBill,
          'customerAddress.fullName'
        )}`}
        visible={isOpenCustomerCancelWhBillModal}
        width={1500}
      >
        <WhReceiptVoucherForm
          whBill={whBill}
          CustomerCancel
          onCustomerCancel={onCustomerCancel}
          whBillItem={selectedBillItem}
          onCancel={onCloseReceiptVouchersModal}
          amountMoney={amountMoney}
        />
      </Modal>
      <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={onCloseReceiptVouchersModal}
        onOk={() => {}}
        title={`Phiếu thu - Đơn hàng ${get(whBill, 'billNumber')} - ${get(
          whBill,
          'customerAddress.fullName'
        )}`}
        visible={isOpenReceiptVouchersWhBillModal}
        width={1500}
      >
        <WhReceiptVoucherForm
          whBill={whBill}
          // handleConfirmPrepayment={handleConfirmPrepayment}
          customerIds = {whBill?.customerId}
          onCloseReceiptVouchersModal={onCloseReceiptVouchersModal}
          initPrepaymentValues={initPrepaymentValues}
          onCancel={onCloseReceiptVouchersModal}
          noWhBillItem
          amountMoney={amountMoney}
        />
      </Modal>

      <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={onClosePaymentVouchersModal}
        onOk={() => { }}
        title={`Phiếu chi - Đơn hàng ${get(whBill, "billNumber")} - ${get(whBill, 'customerAddress.fullName')}`}
        width={1500}
        visible={isOpenPaymentVouchersModal}
      >
        <WhPaymentVoucherForm
          whBill={whBill}
          whBillItem={selectedBillItem}
          onCancel={onClosePaymentVouchersModal}
          amountMoney={amountMoney}
        />
      </Modal>
      <Modal
        destroyOnClose
        visible={isOpenModalUpdateAddress}
        onCancel={() => {
          setIsOpenModalUpdateAddress(false);
        }}
        onOk={() => setIsOpenModalUpdateAddress(false)}
        title={"Cập nhật địa chỉ khách hàng"}
        width={1000}
        footer={false}
      >
        <AddressCustomerBill
          customerId={whBill?.customerId}
          whBillId={whBill?._id}
          setCustomerAddress = {setCustomerAddress}
          newCustomerAddress={newCustomerAddress}
          setIsOpenModalUpdateAddress={setIsOpenModalUpdateAddress}
          whBill = {whBill}
        />
      </Modal>
    </>
  )
}

export default WhBillConsole;
