import React, { useEffect, useCallback, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Button,
  Skeleton,
  Col,
  Select,
  InputNumber,
  DatePicker
} from 'antd';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import { removeAccents } from '~/hooks/utils';
import {
  useInitWhCustomerImport,
  useCreateWhCustomerImport,
  useWhCustomerImport,
  useResetWhCustomerImport,
  useUpdateWhCustomerImport
} from '~/hooks';
import { useCities, useDistricts, useWards } from '~/hooks/geo';
import { filterAcrossAccents } from '~/hooks/utils';
import moment from 'moment';
import { get, transform } from 'lodash';
import { useMemo } from 'react';
const FormItem = Form.Item;
const { Option } = Select;


const WhCustomersImportForm = ({ onClose, id }) => {
  const [form] = Form.useForm();
  const [isSubmitLoading, handleCreate] = useCreateWhCustomerImport();
  const [, handleUpdate] = useUpdateWhCustomerImport();
  const [whCustomerImport, isLoading] = useWhCustomerImport(id);
  const initWhCustomerImport = useInitWhCustomerImport(whCustomerImport);
  useResetWhCustomerImport();
  const [selectedCityCode, setSelectedCityCode] = useState();
  const [selectedDistrictCode, setSelectedDistrictCode] = useState();

  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(selectedCityCode);
  const [wards, isWardsLoading] = useWards(selectedDistrictCode);

  useEffect(() => {
    if (!id) {
      form.resetFields();
    } else {
      // console.log(initWhCustomerImport)
      form.setFieldsValue(initWhCustomerImport);
      form.setFieldsValue({
        dateOfBirth: moment(form.getFieldValue(['dateOfBirth']))
      });
          if(initWhCustomerImport){
            const { address } = initWhCustomerImport;

            if (address) {
              setSelectedCityCode(address.cityId);
              setSelectedDistrictCode(address.districtId);
            }
        }
    }
  }, [initWhCustomerImport, id, form]);

  const onValuesChange = ({ address }) => {
  const shouldResetDistrictAndWards = address && address.cityId;
    if (shouldResetDistrictAndWards) {
      form.setFieldsValue({
        address: {
          districtId: null,
          wardId: null
        }
      });
    }

    const shouldResetWards = address && address.districtId;
    if (shouldResetWards) {
      form.setFieldsValue({
        address: {
          wardId: null
        }
      });
    }
  };

  const onFinish = useCallback(
    (values) => {
      if (id) {
        handleUpdate({ ...values, id: id });
      } else {
        handleCreate({ ...values });
      }
      onClose();
    },
    [handleCreate, handleUpdate, id]
  );

  const RenderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="customer-import page-wraper hospital-form-page-content">
      <h4 style={{ margin: '20px 0 40px 20px' }}>
        {id ? ' Cập nhật' : 'Thêm mới'} khách hàng
      </h4>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          onValuesChange={onValuesChange}
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
        >
          <FormItem
            label="Mã khách hàng"
            name="customerId"
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
          >
            <Input disabled />
          </FormItem>
          <FormItem
            label="Họ và tên"
            name="fullName"
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            rules={[{ required: true, message: 'Xin vui lòng nhập họ và tên' }]}
          >
            <Input />
          </FormItem>
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Email"
                name="email"
                // labelCol={{ sm: 24, md: 24, lg: 3 }}
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
                rules={[
                  !id  &&
                  {
                    required: true,
                    pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
                    message: 'Xin vui lòng nhập đúng email'
                  }
                ]}
              >
                <Input />
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Giới tính"
                name="gender"
                // labelCol={{ sm: 24, md: 24, lg: 3 }}
                wrapperCol={{ sm: 14, md: 14, lg: 11 }}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select>
                    <Option value="M" key="male">
                      Nam
                    </Option>
                    <Option value="F" key="female">
                      Nữ
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Số điện thoại"
                name="phoneNumber"
                // labelCol={{ sm: 24, md: 24, lg: 3 }}
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]/),
                    message: 'Xin vui lòng nhập đúng số điện thoại!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input maxLength={15} minLength={10} />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={'Ngày sinh'}
                name={'dateOfBirth'}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập ngày tháng năm sinh'
                  }
                ]}
                // labelCol={{ sm: 24, md: 24, lg: 3 }}
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
              >
                <DatePicker
                  placeholder="Ngày tháng năm sinh"
                  allowClear
                  format="DD-MM-YYYY"
                />
              </FormItem>
            </Col>
          </Row>

          <FormItem
            label="Bệnh lý chính"
            name="mainDisease"
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            // rules={[
            //   { required: true, message: 'Xin vui lòng nhập nhóm đối tượng' }
            // ]}
          >
            <Input />
          </FormItem>

          <FormItem
            label="Bệnh lý kèm theo"
            name="acceptedDisease"
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            // rules={[
            //   { required: true, message: 'Xin vui lòng nhập nhóm đối tượng' }
            // ]}
          >
            <Input />
          </FormItem>

          <FormItem
            label="Tính cách"
            name="genitive"
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            // rules={[
            //   { required: true, message: 'Xin vui lòng nhập nhóm đối tượng' }
            // ]}
          >
            <Input />
          </FormItem>

          <FormItem
            label="Thẻ BHYT"
            name="healthInsuranceCard"
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            // rules={[{ required: true, message: 'Xin vui lòng nhập thẻ BHYT' }]}
          >
            <Input />
          </FormItem>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Thành Phố/Tỉnh"
                name={['address', 'cityId']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    onChange={setSelectedCityCode}
                    disabled={isCitiesLoading}
                    loading={isCitiesLoading}
                    showSearch
                    autoComplete="off"
                    value={get(form.getFieldValue("address"),"cityId", "")}
                    filterOption={filterAcrossAccents}
                  >
                    {cities.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name} 
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Quận/Huyện"
                name={['address', 'districtId']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Quận/Huyện!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isDistrictsLoading}
                    disabled={!form.getFieldValue(['address', 'cityId'])}
                    onChange={setSelectedDistrictCode}
                    showSearch
                    autoComplete="off"
                    value={get(form.getFieldValue("address"),"districtId", "")}
                    filterOption={filterAcrossAccents}
                  >
                    {districts.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Phường/Xã"
                name={['address', 'wardId']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Phường/Xã!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isWardsLoading}
                    disabled={!form.getFieldValue(['address', 'districtId'])}
                    showSearch
                    value={get(form.getFieldValue("address"),"wardId", "")}
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {wards.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Địa chỉ" name={['address', 'street']}>
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label="Tên thân nhân" name="familyName">
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="SĐT thân nhân"
                name="familyPhone"
                rules={[
                  {
                    // required: true,
                    pattern: new RegExp(/^[0-9]/),
                    message: 'Xin vui lòng nhập đúng số điện thoại!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input maxLength={15} minLength={10} />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="ĐC thân nhân"
                name="addressOfFamily"
                // rules={[
                //   { required: true, message: 'Xin vui lòng nhập nhóm đối tượng' }
                // ]}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Mối quan hệ"
                name="relationship"
                // rules={[
                //   { required: true, message: 'Xin vui lòng nhập nhóm đối tượng' }
                // ]}
              >
                <Input />
              </FormItem>
            </Col>
          </Row>

          <Row className="form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.whCustomerImport.root}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default WhCustomersImportForm;
