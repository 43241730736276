import { Checkbox, Modal, Table } from 'antd';
import { get, rest } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { formatNumberThreeComma } from '~/hooks/utils';
import SkeletonTable from '../Utils/SkeletonTable';
import WhPaymentVoucherSaleSystem from './WhPaymentVoucherSaleSystem';
import WhReceiptVoucherSaleSystem from './WhReceiptVoucherSaleSystem';

export default function TableSalesSystem({
  type,
  paging,
  onPagingChange,
  dataSource,
  isLoading,
  callback,
}) {
  const [title, setTitle] = useState('');
  const [isOpenReceipt, setIsOpenReceipt] = useState(false);
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [modeFetch,setModeFetch] = useState('receipts')
  const [whReceiptVoucherId, setWhReceiptVoucherId] = useState(null);
  const [noBillId, setNoBillId] = useState(false);
  const [ids, setIds] = useState([])

  const handleOpenVoucher = (id) => {
    switch (modeFetch) {
      case 'receipts':
      case 'receiptsCustomer':
        setIsOpenReceipt(true);
        break;
      case 'payment':
      case 'paymentCustomer':
        setIsOpenPayment(true);
        break;
    
      default:
        break;
    }
    
    setWhReceiptVoucherId(id);
  };
  const handleClose = () => {
    setIsOpenReceipt(false);
    setIsOpenPayment(false);
    setWhReceiptVoucherId(null);
  };
  useEffect(() => {
    switch (type) {
      case 'receipts':
        setModeFetch('receipts')
        setTitle('Mã phiếu thu');
        break;
      case 'receiptsCustomer':
        setModeFetch('receiptsCustomer')
        setTitle('Mã phiếu thu');
        break;
      case 'payment':
        setModeFetch('payment')
        setTitle('Mã phiếu chi');
        break
      case 'paymentCustomer':
        setModeFetch('paymentCustomer')
        setTitle('Mã phiếu chi');
        break;
      default:
        break;
    }
  }, [type]);
  
  const onChangeCheckBox = (e, id) => {
    if (e) {
      setIds([...ids, id])
    } else {
      const getIndex = ids.findIndex((itemId, index) => itemId === id)
      const newArr = ids.toSpliced(getIndex, 1)
      setIds(newArr)
    }
  } 
  useEffect(() => {
    callback(ids)
  },[ids])
  
  const columns = [
    {
      title: title,
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              handleOpenVoucher(get(record, '_id'))
              if(get(record, 'whBillId')){
                setNoBillId(false)
              }
              else{
                setNoBillId(true)
              }
              }}
            href="#"
          >
            {text || ''}
          </a>
        );
      }
    },
    {
      title: 'Số chứng từ',
      dataIndex: 'issueNumber',
      key: 'issueNumber',
      render: (text, record) => <span>{text || ''}</span>
    },
    {
      // title: ['payment','paymentCustomer'].includes(modeFetch) ? 'Mã đơn hàng/Lý do chi trả' : 'Mã đơn hàng' ,
      title: 'Mã đơn hàng' ,
      dataIndex: 'whBill',
      key: 'whBill',
      render: (text, record) => (
        <span>
          {
            get(record, 'whBill.billNumber','')
            
            }
        </span>
      )
    },
    {
      title: 'Nội dung',
      dataIndex: 'whAppointment',
      key: 'whAppointment',
      render: (text, record) => {
        // return (
        //   <span>{`${get(text, 'snapshotService.code', '')} - ${get(
        //     text,
        //     'snapshotService.name.vi',
        //     ''
        //   )} - ${get(text, 'snapshotService.detailName.vi', '')}`}</span>
        // )
        return (
          <span>{get(record,'reason','')}</span>
        )
      }
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (item, record) => <span>{moment(item).format("DD-MM-YYYY HH:mm:ss")}</span>
    },
    {
      title: 'ID buổi hẹn',
      dataIndex: 'whAppointment',
      key: 'whAppointment',
      render: (text, record) => <span>{get(text, 'code', '')}</span>
    },
    {
      title: 'Số tiền',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text, record) => <span>{formatNumberThreeComma(text) || 0}</span>
    },
    {
      title: 'Lựa chọn',
      key: '_id',
      width: 80,
      align: 'center',
      render: (item, record) =>
      {
        const id = record._id
        return <Checkbox
        defaultChecked= {ids.includes(id)}
        onChange={(e)=>onChangeCheckBox(e.target.checked, id)}
      />}
    },
  ];
  const columnsReceiptsCustomer = [
    {
      title: 'Tên khách hàng',
      dataIndex: 'customerName',
      key: 'customerName',
      render: (text, record) => {
        return (
          <span>{get(record,'customerName','')}</span>
        )
      }
    },
    {
      // title: ['payment','paymentCustomer'].includes(modeFetch) ? 'Mã đơn hàng/Lý do chi trả' : 'Mã đơn hàng' ,
      title: 'Mã đơn hàng' ,
      dataIndex: 'whBill',
      key: 'whBill',
      render: (text, record) => (
        <span>
          {
            get(record, 'whBill.billNumber','')
            
            }
        </span>
      )
    },
    {
      title: 'Nội dung',
      dataIndex: 'whAppointment',
      key: 'whAppointment',
      render: (text, record) => {
        return (
          <span>{get(record,'reason','')}</span>
        )
      }
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (item, record) => <span>{moment(item).format("DD-MM-YYYY HH:mm:ss")}</span>
    },
    {
      title: 'ID buổi hẹn',
      dataIndex: 'whAppointment',
      key: 'whAppointment',
      render: (text, record) => <span>{get(text, 'code', '')}</span>
    },
    {
      title: 'Số tiền',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text, record) => <span>{formatNumberThreeComma(text) || 0}</span>
    },
    {
      title: 'Giá trị đơn hàng',
      dataIndex: 'whBill',
      key: 'whBill',
      render: (item, record) => <span>{formatNumberThreeComma(get(item,'grandTotal',0))}</span>
    },
    {
      title: 'Đã thanh toán và xác nhận',
      dataIndex: 'whBill',
      key: 'whBill',
      render: (item, record) => <span>{formatNumberThreeComma(get(item,'actualTotalAmountPaid',0))}</span>
    },
    {
      title: 'Còn lại',
      dataIndex: 'whBill',
      key: 'whBill',
      render: (item, record) => <span>{formatNumberThreeComma(get(item,'remainingAmount',0))}</span>
    },
    {
      title: 'Lựa chọn',
      key: '_id',
      width: 80,
      align: 'center',
      render: (item, record) =>
      {
        const id = record._id
        return <Checkbox
        defaultChecked= {ids.includes(id)}
        onChange={(e)=>onChangeCheckBox(e.target.checked, id)}
      />}
    },
  ];

  return (
    <>
      {isLoading ? (
        <SkeletonTable columns={type === 'receiptsCustomer' ? columnsReceiptsCustomer  : columns} rowCount={5} />
      ) : (
        <Table
          dataSource={dataSource || []}
          scroll={{x :1500}}
          size='middle'
          onChange={onPagingChange}
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
          columns={type === 'receiptsCustomer' ? columnsReceiptsCustomer  : columns}
          rowKey={(rc) => rc._id}
        />
      )}
      <Modal
        centered
        footer={null}
        onCancel={handleClose}
        onOk={handleClose}
        title={`Phiếu thu`}
        visible={isOpenReceipt}
        width={1366}
      >
        <WhReceiptVoucherSaleSystem
          whReceiptVoucherId={whReceiptVoucherId}
          onClose={handleClose}
          onCancel={() => {
                handleClose;
          }}
        />
      </Modal>
      <Modal
        centered
        footer={null}
        onCancel={handleClose}
        onOk={handleClose}
        title={`Phiếu chi`}
        visible={isOpenPayment}
        width={1366}
      >
        <WhPaymentVoucherSaleSystem
          whPaymentVoucherId={whReceiptVoucherId}
          onClose={handleClose}
          onCancel={handleClose}
          noBillId={noBillId}
        />
      </Modal>
    </>
  );
}
