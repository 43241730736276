export const GET_RATE_CONFIGS_OF_CERTIFICATE_REQUEST = 'GET_RATE_CONFIGS_OF_CERTIFICATE_REQUEST';
export const GET_RATE_CONFIGS_OF_CERTIFICATE_SUCCESS = 'GET_RATE_CONFIGS_OF_CERTIFICATE_SUCCESS';
export const GET_RATE_CONFIGS_OF_CERTIFICATE_FAILED = 'GET_RATE_CONFIGS_OF_CERTIFICATE_FAILED';

export const GET_RATE_CONFIGS_OF_POSITION_REQUEST = 'GET_RATE_CONFIGS_OF_POSITION_REQUEST';
export const GET_RATE_CONFIGS_OF_POSITION_SUCCESS = 'GET_RATE_CONFIGS_OF_POSITION_SUCCESS';
export const GET_RATE_CONFIGS_OF_POSITION_FAILED = 'GET_RATE_CONFIGS_OF_POSITION_FAILED';

export const GET_RATE_CONFIGS_OF_EXPERIENCE_REQUEST = 'GET_RATE_CONFIGS_OF_EXPERIENCE_REQUEST';
export const GET_RATE_CONFIGS_OF_EXPERIENCE_SUCCESS = 'GET_RATE_CONFIGS_OF_EXPERIENCE_SUCCESS';
export const GET_RATE_CONFIGS_OF_EXPERIENCE_FAILED = 'GET_RATE_CONFIGS_OF_EXPERIENCE_FAILED';

export const GET_RATE_CONFIGS_OF_DEGREE_REQUEST = 'GET_RATE_CONFIGS_OF_DEGREE_REQUEST';
export const GET_RATE_CONFIGS_OF_DEGREE_SUCCESS = 'GET_RATE_CONFIGS_OF_DEGREE_SUCCESS';
export const GET_RATE_CONFIGS_OF_DEGREE_FAILED = 'GET_RATE_CONFIGS_OF_DEGREE_FAILED';

export const GET_RATE_CONFIG_REQUEST = 'GET_RATE_CONFIG_REQUEST';
export const GET_RATE_CONFIG_SUCCESS = 'GET_RATE_CONFIG_SUCCESS';
export const GET_RATE_CONFIG_FAILED = 'GET_RATE_CONFIG_FAILED';

export const CREATE_RATE_CONFIG_REQUEST = 'CREATE_RATE_CONFIG_REQUEST';
export const CREATE_RATE_CONFIG_SUCCESS = 'CREATE_RATE_CONFIG_SUCCESS';
export const CREATE_RATE_CONFIG_FAILED = 'CREATE_RATE_CONFIG_FAILED';

export const UPDATE_RATE_CONFIG_REQUEST = 'UPDATE_RATE_CONFIG_REQUEST';
export const UPDATE_RATE_CONFIG_SUCCESS = 'UPDATE_RATE_CONFIG_SUCCESS';
export const UPDATE_RATE_CONFIG_FAILED = 'UPDATE_RATE_CONFIG_FAILED';

export const DELETE_RATE_CONFIG_REQUEST = 'DELETE_RATE_CONFIG_REQUEST';
export const DELETE_RATE_CONFIG_SUCCESS = 'DELETE_RATE_CONFIG_SUCCESS';
export const DELETE_RATE_CONFIG_FAILED = 'DELETE_RATE_CONFIG_FAILED';

export const RESET_RATE_CONFIG_STATE = 'RESET_RATE_CONFIG_STATE';
