import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getRequestVouchersFailed: null,
  getRequestVoucherFailed:null,
  RequestVouchers: [],
  RequestVoucher: null,
  isSubmitLoading : false,
  isGetRequestVoucherLoading: false,
  RequestVoucher: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_REQUEST_VOUCHERS_REQUEST:
      state.isLoading = true;
      state.getRequestVouchersFailed = null;
      return;

    case Types.GET_REQUEST_VOUCHERS_SUCCESS:
      state.isLoading = false;
      state.RequestVouchers = payload.docs;
      state.getRequestVouchersFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_REQUEST_VOUCHERS_FAILED:
      state.isLoading = false;
      state.RequestVouchers = [];
      state.getRequestVouchersFailed = payload;
      return;
    case Types.GET_REQUEST_VOUCHER_REQUEST:
      state.isGetRequestVoucherLoading = true;
      state.RequestVoucher = null
      state.getRequestVoucherFailed = null;
      return;

    case Types.GET_REQUEST_VOUCHER_SUCCESS:
      state.isGetRequestVoucherLoading = false;
      state.RequestVoucher = payload;
      state.getRequestVoucherFailed = null;
      return;

    case Types.GET_REQUEST_VOUCHER_FAILED:
      state.isGetRequestVoucherLoading = false;
      state.RequestVoucher = null;
      state.getRequestVoucherFailed = payload;
      return;
    case Types.CREATE_REQUEST_VOUCHER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_REQUEST_VOUCHER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_REQUEST_VOUCHER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_REQUEST_VOUCHER_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_REQUEST_VOUCHER_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.RequestVoucher = get(payload,'data');
        return;
  
      case Types.UPDATE_REQUEST_VOUCHER_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_REQUEST_VOUCHER_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_REQUEST_VOUCHER_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_REQUEST_VOUCHER_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_REQUEST_VOUCHER_STATE:
      return initState;

    default:
      return;
  }
}, initState);
