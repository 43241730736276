import { usePartnerQueryParams } from '~/hooks';
import Partners from '.';

function Pharmacy() {
  return (
    <>
      <Partners module="PHARMACY" name={'đối tác'} permission="PHARMACY"/>
    </>
  );
}

export default Pharmacy;
