import React, { useEffect, useState } from 'react';

import { Card, Col, Image, Row, Select, Skeleton, Space } from 'antd';
import { findIndex, get } from 'lodash';

import { INSTANCE_STATUSES, LANGUAGE } from '~/constants/defaultValue.js';
import { filterAcrossAccents } from '~/hooks/utils';

import WhServiceSessionView from './WhServiceSessionView.js';
import WhMediaPackageExpandle from './WhMediaPackageExpandle.js';
import WhMedicalEquipmentExpandle from './WhMedicalEquipmentExpandle.js';

const { Option } = Select;

const DataRow = ({
  label,
  value,
  language = LANGUAGE.VI,
}) => {
  return (
    <Row>
      <Space>
        <p>{label}: </p>
        <p style={{ fontWeight: "bold" }}>{value}</p>
      </Space>
    </Row>
  )
}

const WhServicePackageLevelView = ({
  index,
  isLoading = false,
  language = LANGUAGE.VI,
  packageLevel,
  whSessionsOfDay,
}) => {
  const { sessionPrices, whPackageLevel, media, groupProductInits, materialInits } = packageLevel;
  const [selectedSessionOfDay, setSelectedSessionOfDay] = useState();
  const [, setSelectedSessionOfDayIdx] = useState();

  useEffect(() => {
    const idx = findIndex(whSessionsOfDay, (item => { return item.id === selectedSessionOfDay; }))
    if (idx !== -1) setSelectedSessionOfDayIdx(idx)
  }, [selectedSessionOfDay, whSessionsOfDay]);

  useEffect(() => {
    setSelectedSessionOfDay(get(whSessionsOfDay, "0.id"));
  }, [whSessionsOfDay]);

  return (
    <div>
    <Card className='card'>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row gutter={48} align={index === 0 && 'middle'}>
          <Col sm={12} lg={12}>
            <DataRow
              label="Gói dịch vụ"
              value={get(whPackageLevel, `name.${language}`)}
            />
          </Col>
          <Col sm={12} lg={12}>
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                // disabled={isWhCategoriesLoading}
                // loading={isWhCategoriesLoading}
                autoComplete="off"
                filterOption={filterAcrossAccents}
                onChange={setSelectedSessionOfDay}
                placeholder="Thời gian trong ngày"
                showSearch
                style={{width: "100%"}}
                value={selectedSessionOfDay}
              >
                {/* {form.getFieldValue(["packages", index, "sessionPrices"])?.map(({ _id, whSessionOfDay }) => (
                  <Option key={_id} value={_id}>
                    {get(whSessionOfDay, 'name')}
                  </Option>
                ))} */}
                {whSessionsOfDay
                  ?.filter(item => {
                    const activeWhSessionOfDayIds = sessionPrices
                      .filter(item => get(item, "status") === INSTANCE_STATUSES.ACTIVE)
                      .map(item => get(item, "whSessionOfDayId"))
                    return activeWhSessionOfDayIds.includes(get(item, "id"))
                  })
                  ?.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {get(name, 'vi')}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        </Row>
        {sessionPrices
          ?.filter(item => item.whSessionOfDayId === selectedSessionOfDay)
          ?.map((item, index) => (
            <WhServiceSessionView session={item} />
          )
          )
          }
        <Row gutter={48} justify="start">
          </Row>
          <Row> 
            <WhMediaPackageExpandle media={media} />
          </Row>
          <Row> 
            <WhMedicalEquipmentExpandle groupProductInits={groupProductInits} materialInits={materialInits}/>
          </Row>
      </Space>
    </Card>
    </div>
  )
}

export default WhServicePackageLevelView;
