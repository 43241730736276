import {
  CheckOutlined,
  ClearOutlined,
  DeleteTwoTone,
  EditTwoTone
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import {
  COMPONENT_MODES,
  TYPE_TIPS,
  TYPE_TIPS_VI
} from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import {
  useCreateTipsPartner,
  useDeleteTipsPartner,
  useResetTipsPartner,
  useTipsPartnerAllQueryParams,
  useTipsPartnerQueryParams,
  useTipsPartners,
  useUpdateTipsPartner
} from '~/hooks/tipsPartner';
import { formatNumberThreeComma } from '~/hooks/utils';
import { WithPermission } from '../Common';
import './index.scss'
import TableDetail from './TableDetail';

const EditableContext = createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  indexAction,
  rowIndex,
  content,
  setContent,
  money,
  setMoney,
  count,
  setCount,
  mode,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    if (rowIndex === 0) {
      switch (dataIndex) {
        case 'content':
          childNode = (
            <Input
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          );
          break;
        case 'money':
          if (mode === COMPONENT_MODES.VIEW) {
            childNode = null
          }else{

            childNode = (
              <InputNumber
              max={1000000000}
              style={{width : '100%'}}
              formatter={(value) => formatNumberThreeComma(value)}
              value={money}
              onChange={(value) => setMoney(value)}
            />
          );
        }
          break;
        case 'count':
          childNode = (
            <InputNumber
              max={100000000}
              style={{width : '50%'}}
              formatter={(value) => formatNumberThreeComma(value)}
              value={count}
              onChange={(value) => setCount(value)}
            />
          );
          break;
        default:
          break;
      }
    } else {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
        >
          {dataIndex !== 'money' ? (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          ) : (
            <InputNumber
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              formatter={(value) => formatNumberThreeComma(value)}
            />
          )}
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
          onClick={() => {
            if (indexAction === record.key) toggleEdit();
          }}
        >
          {typeof children[1] === 'number'
            ? <Text type={record.type === TYPE_TIPS.punish ? 'danger' : 'success'}>{formatNumberThreeComma(children[1])}</Text>
            : children}
        </div>
      );
    }
  }
  return <td {...restProps}>{childNode}</td>;
};
const TableTipsPartner = ({ id, date, mode, setTotalDetailAll }) => {
  const [dataSource, setDataSource] = useState([]);
  const [indexAction, setIndexAction] = useState(null);
  const [action, setAction] = useState(null);
  const [initValue, setInitValue] = useState(null);
  const [query] = useTipsPartnerAllQueryParams(id, date);
  const [tips, isLoading] = useTipsPartners(query);
  const [type, setType] = useState(TYPE_TIPS.punish);
  const [content, setContent] = useState('');
  const [money, setMoney] = useState(0);
  const [count, setCount] = useState(1);
  const [dateCreate, setDateCreate] = useState(moment());
  const checkFirstIndexAndModeEdit = (index) =>
    index === 0 && mode === COMPONENT_MODES.EDIT;
  const checkFirstIndexAndModeView = (index) =>
    index === 0 && mode === COMPONENT_MODES.VIEW;
  const handleChange = (value) => {
    setType(value);
  };
  useResetTipsPartner();
  useEffect(() => {
    if (id && tips.partnerTips) {
      let count = 1;
      let newTips = get(tips, 'partnerTips', []).map((e) => ({
        ...e,
        key: count++
      }));
      setDataSource(newTips);
      setTotalDetailAll('extraIncome', get(tips, 'totalTips', 0));
    }
  }, [id, tips]);
  const handleChangeAction = (action, key, record) => {
    setAction(action);
    setIndexAction(key);
    if (action === 'update') {
      setInitValue(record);
    }
  };
  const handleResetAction = () => {
    setAction(null);
    setIndexAction(null);
    setInitValue(null);
  };

  const handleResetValue = () => {
    setContent('');
    setMoney(0);
    setCount(1);
    setDateCreate(moment());
    handleResetAction();
  };
  const [isSubmitLoading, handleCreate] =
    useCreateTipsPartner(handleResetValue);

  const [, handleEdit] = useUpdateTipsPartner(handleResetAction);
  const [, handleDelete] = useDeleteTipsPartner(handleResetAction);
  const handleCancel = () => {
    handleSave(initValue);
    handleResetAction();
  };
  const handleSubmitEdit = (record) => {
    const submitData = { ...record, id: record._id };
    handleEdit(submitData);
  };
  const handleSubmitCreate = () => {
    const submitData = {
      type,
      content,
      money,
      count,
      date: moment(dateCreate).format('YYYY-MM-DD'),
      partnerId: id
    };
    handleCreate(submitData);
  };
  const defaultColumns = [
    {
      title: 'Hình thức',
      key: 'type',
      width : '20%',
      dataIndex: 'type',
      align: 'center',
      render: (text, record, index) =>
        index !== 0 ? (
          <span>{TYPE_TIPS_VI[text] || ''}</span>
        ) : mode === COMPONENT_MODES.EDIT ? (
          <Select
            style={{ width: '150px' }}
            onChange={handleChange}
            value={type}
            options={[
              { label: TYPE_TIPS_VI.reward, value: TYPE_TIPS.reward },
              { label: TYPE_TIPS_VI.punish, value: TYPE_TIPS.punish }
            ]}
          />
        ) : null
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      width: '40%',
      editable: mode === COMPONENT_MODES.EDIT ? true : false
    },
    {
      title: 'Số tiền',
      dataIndex: 'money',
      width: '20%',
      align: 'center',
      editable: true
    },
    {
      title: 'Số lần',
      dataIndex: 'count',
      width: '15%',
      align: 'center',
      editable: mode === COMPONENT_MODES.EDIT ? true : false
    },
    {
      title: 'Ngày áp dụng',
      dataIndex: 'date',
      width: '20%',
      render: (item, record, index) => {
        if (index === 0 && mode === COMPONENT_MODES.EDIT) {
          return (
            <DatePicker
              allowClear={false}
              value={dateCreate}
              onChange={(date, dateString) => setDateCreate(date)}
            />
          );
        } else if (index !== 0) {
          return (
            <DatePicker
              allowClear={false}
              disabled={record.key !== indexAction}
              value={moment(item)}
              onChange={(date, dateString) => {
                const newData = [...dataSource];
                newData.splice(index, 1, {
                  ...record,
                  date: dateString
                });
                setDataSource(newData);
              }}
            />
          );
        } else return null;
      }
    },
    {
      title: 'Thao tác',
      key: 'action',
      align: 'center',
      dataIndex: 'action',
      width: '15%',
      render: (item, record, index) => {
        if (mode === COMPONENT_MODES.VIEW) return null;
        if (index === 0) {
          return (
            <Row>
              <WithPermission permission={POLICIES.WRITE_WHTIPS}>
              <Col span={12}>
                <Button
                  disabled={content === '' || money === ''}
                  loading={isSubmitLoading}
                  onClick={handleSubmitCreate}
                  style={{ borderColor: '#52c41a' }}
                >
                  <CheckOutlined style={{ color: '#52c41a' }} />
                </Button>
              </Col>
              </WithPermission>
              <Col span={12}>
                <Button onClick={handleResetValue}>
                  <ClearOutlined />
                </Button>
              </Col>
            </Row>
          );
        }
        if (action === 'update') {
          if (record.key === indexAction) {
            return (
              <Row>
                <Col span={12}>
                  <Button
                    disabled={record.content === '' || record.money === ''}
                    loading={isSubmitLoading}
                    type="primary"
                    onClick={() => handleSubmitEdit(record)}
                  >
                    Lưu
                  </Button>
                </Col>
                <Col span={12}>
                  <Button onClick={handleCancel}>Huỷ</Button>
                </Col>
              </Row>
            );
          }
        }
        if (!!!action && !!!indexAction)
          return (
            <Row>
            <WithPermission permission={POLICIES.UPDATE_WHTIPS}>
              <Col span={12}>
                <Button
                  onClick={() =>
                    handleChangeAction('update', record.key, record)
                  }
                >
                  <EditTwoTone />
                </Button>
              </Col>
            </WithPermission>

            <WithPermission permission={POLICIES.DELETE_WHTIPS}>
              <Col span={12}>
                <Button
                  loading={isSubmitLoading}
                  onClick={() => handleDelete(record._id)}
                >
                  <DeleteTwoTone />
                </Button>
              </Col>
              </WithPermission>
            </Row>
          );
      }
    }
  ];
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record) => ({
          record,
          indexAction
        })
      };
    }
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        rowIndex,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        indexAction,
        handleSave,
        content,
        setContent,
        money,
        setMoney,
        count,
        setCount,
        mode
      })
    };
  });
  const summaryFooter = () => (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell align="center">TC</Table.Summary.Cell>
        <Table.Summary.Cell align="center">
          {get(tips, 'partnerTips', []).length || 0}
        </Table.Summary.Cell>
        <Table.Summary.Cell align="center">
         <Text strong type='warning'>
         {formatNumberThreeComma(get(tips, 'totalTips', 0))}
         </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell align="center"></Table.Summary.Cell>
        <Table.Summary.Cell align="center"></Table.Summary.Cell>
        <Table.Summary.Cell align="center"></Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
  return (
    <div>
      {/* {isLoading ? (
        <SkeletonTable columns={columns} rowCount={10} />
      ) : ( */}
        <TableDetail components={components} scrollWidth={1000}  rowClassName={'editable-row'} loading={isLoading} pagination={false} dataSource={[1, ...dataSource]} columns={columns} title={'Thu nhập thêm/giảm: thưởng/phạt (D)'} footer={summaryFooter}/>

        {/* <Table
          className="tableTips"
          size="small"
          title={() => 'Thu nhập thêm/giảm: thưởng/phạt (D)'}
          components={components}
          scroll={{y : 500}}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={[1, ...dataSource]}
          columns={columns}
          pagination={false}
          summary={summaryFooter}
        /> */}
      {/* )} */}
    </div>
  );
};
export default TableTipsPartner;
