import { forIn, get, groupBy, pick, uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import api from '~/api';
import { useWhPartners, useWhPartnersReport } from '../whPartner';
import toastr from 'toastr';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import moment from 'moment';
export const useReportById = (id,date) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const query = {id,date}
     
      const res = await api.reportPartner.getCountDidServiceById(query);
      // const mapListServicePartnersWithSeasionPrice = res?.listServicePartners?.map(item => {
      //   const indexFind = item?.snapshotService?.package?.sessionPrices?.find(e => e._id === item.whSessionPriceItemId)
      //   if(indexFind) {
      //     return {
      //       ...item,snapshotSeasionPrice : indexFind
      //     }
      //   }
      //   return item;
      // })
      // setData({...res,listServicePartners : mapListServicePartnersWithSeasionPrice});


      const cloneMergeDataDoubleCase = res?.data
      let rs = []
      const cloneMergeDataDoubleCaseFinal = res?.data?.map(e => ({...e,rowSpan : 0}))
      const mergeDataDoubleCase = cloneMergeDataDoubleCaseFinal?.map(e => { 
        const arrFilter = cloneMergeDataDoubleCase?.filter(value => value.whBill._id === e.whBill._id)
        const flag = rs?.some(values => values._id === arrFilter[0]._id)
        if(flag) return 
        if(arrFilter.length > 1) {
          arrFilter[0] = {...arrFilter[0],rowSpan : arrFilter.length}
          arrFilter.forEach(v => rs.push(v))
        }
        else{
          arrFilter.forEach(v => rs.push({...v,rowSpan : 1}))
        }
      })
      const finalyData = rs.sort((a,b) => get(b,'whBill.billNumber') - get(a,'whBill.billNumber'))
      setData({...res,data : finalyData})
      setLoading(false);
    };
    if (id) fetchData();
  }, [id,date]);
  return [data, loading];
};
export const useReportCoureTrainingById = (id,date) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await api.reportPartner.getReportCourseTraining({id,...date});
      setData(res);
      setLoading(false);
    };
    if (id) fetchData();
  }, [id,date]);
  return [data, loading];
};
export const useReportMedicinesById = (id,date) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await api.reportPartner.getReportCourseTraining({id,...date});
      setData(res);
      setLoading(false);
    };
    if (id) fetchData();
  }, [id,date]);
  return [data, loading];
};
export const useReportKpIById = (partnerId,date) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await api.reportPartner.getKpiById(partnerId,date);
        setData(result)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error,'error');
      }
    };
    if (partnerId) fetchData();
  }, [partnerId,date]);
  return [data, loading];
};
export const useAllReportPartner = (query) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await api.reportPartner.getAlltotalSale({...query});
      setData(result)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error,'error');
    }
  },[query])
  useEffect(() => {
   (get(query,'startDate') !== 'Invalid date') && (get(query,'endDate') !== 'Invalid date') &&  fetchData();
  }, [fetchData]);
  return [data, loading];
};
export const useReportIntroById = (id,date) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
     try {
      setLoading(true)
      const res = await api.reportPartner.getCountDidServiceOfIntroPartnerById({id,date});
      const newMergePartner = get(res,'dataChillPartners',[]).map(e => {
        const findOne = get(res,'listCheckPartner').find(value => value._id === e._id)
        if(findOne){
          return {...e,receiptVoucher:findOne}
        }
        return e
      })
      const partnerSaleList = get(res,'newGroupChildParent',[]).map(e => { // merge get listPartner 
        let listPartner = []
        newMergePartner.forEach(value => {
          if(get(value,'employeeGroup') === get(e,'employeeGroup[0]._id')){
            listPartner.push(value)
          }
          else{
            if(get(value,'_id') === get(e,'_id')){
              listPartner.push(value)
            }
          }
        })
        return {...e,listPartner}
      })
      const mergePartnerWithSale = partnerSaleList?.map(e => { // mergeg listPartner with sale
          const transFormPartner = get(e,'listPartner',[]).map(partner => {
           const salePartner = res?.data?.find(itemData => get(itemData,'data[0].partnerSnapShort._id') === get(partner,'_id'))
           if(salePartner){
             return {...partner,totalSale : get(salePartner,'totalAllMoney',0)}
           }
           return partner
          })
        return {...e,listPartner : transFormPartner}

      })
      setData({...res,newGroupChildParent:mergePartnerWithSale})
      setLoading(false)
      // const introSystems = await api.reportPartner.getIntroSystemById(id);
      // const ids = res.map(e => e._id);
      // let promises = ids?.map(async(e) => await api.reportPartner.getCountDidServiceById({id :e,date}))
      // Promise.allSettled(promises).then(item => {
      //   let result = []
      //   item.forEach(_item => {
      //     if(_item.status === 'fulfilled'){
      //       result.push(_item.value)
      //     }
      //   })
      //   setLoading(false)
      // const newIntroSystem = introSystems?.map(f => {
      //   let totalSale = 0
      //   let countResult = 0
      //     const arrFind = result?.filter(_e=> _e?.partner?.employeeGroup === f._id);
      //     if(arrFind.length > 0){
      //       arrFind?.forEach(_arrFind => {
      //         totalSale += _arrFind?.totalPrice?.totalPrices
      //         if(_arrFind?.totalPrice?.totalPrices > 0) countResult += 1
             
      //       })
      //     }
      //     return {...f, totalSale,countResult }
      //   })
      //   setData(newIntroSystem)
      // })
     } catch (error) {
      setLoading(false)
      console.log(error);
     }
    };
    if (id) fetchData();
  }, [id,date]);
  return [data, loading];
};
export const useMergeListPartnerWithService = (query,date,dataReport,loading,id) => {
  const [whPartners, isLoading] = useWhPartnersReport(query);
  const [services, setServices] = useState([]);
  const [rating, setRating] = useState([]);
  const [whPartnerMergeService,setWhPartnerMergeService] = useState([])
  const fetchService = useCallback(async() => {
    try {
      const res = await  api.whService.getWhServices({limit : MAX_PAGINATION_LIMIT})
      setServices(res)
    } catch (error) {
      console.log(error);
      toastr.error("Something went wrong")
    }
  },[])
  useEffect(() => {
    fetchService()
  },[])

  const fetchRating = useCallback(async() => {
    try {
      const res = await api.reportPartner.getAllRatingPartner({...date})
     setRating(res)
    } catch (error) {
      console.log(error);
      toastr.error("Something went wrong")
    }
    
  },[date])
  useEffect(() => {
    fetchRating()
  },[fetchRating])
  useEffect(() => {
    const mergeWhPartnerService = whPartners?.map(e => {
      let arrSeriveOfPartner = e?.services?.map(service => {
        const findSevice =  services?.docs?.find(s => s._id === service)
        if(findSevice){
          return findSevice
        }
      })
      const ratingPartner = rating?.find(rating => rating?.partnerId === e._id)
      const reportOfPartner = dataReport?.find(report => report?.partnerId === e._id)
      return {...e,listServiceName : arrSeriveOfPartner,rating : ratingPartner,reportOfPartner}
    })
    setWhPartnerMergeService(mergeWhPartnerService)
  },[whPartners,services,query,rating,date,dataReport])
  const isLoadingMerge = isLoading
  return [whPartnerMergeService,isLoadingMerge]
}
function splitRangeToMonths(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const months = [];

  // Start at the beginning of the start month
  let currentMonth = startMoment.clone().endOf('month');

  // Iterate until the end of the end month
  while (currentMonth.isSameOrBefore(endMoment, 'month')) {
    months.push(currentMonth.format('YYYY-MM-DD'));
    currentMonth.add(1, 'month');
  }

  return months;
}

export const useReportIntroCustomerById = (id,date) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      const res = await api.reportPartner.getSaleIntroCustomer({partnerId:id,...date});
      let dataSource = {}
      const groupListBillByIdWhBill = uniqBy(get(res,'listBill',[]),(e => get(e,'whBillId'))) 
      splitRangeToMonths(get(date,'startDate'),get(date,'endDate'))?.forEach(e => dataSource[e] = {listCustomer : []})
      forIn(dataSource,(value,key) => { // group listcustomer by time , Find List customer have created date < date key
        get(res,'listCustomer',[])?.forEach(e => {
          if(moment(key).isSameOrAfter(moment(get(e,'customerAccount.createdAt')))){
            value.listCustomer = [...get(value,'listCustomer',[]),{...pick(get(e,'customerAccount'),['fullName','customerId','_id','createdAt']),totalAmount : 0}]
          }
        })
      })
      forIn(dataSource,(value,key) => { // findCustomer and month and sum 
        groupListBillByIdWhBill?.forEach(e => {
          if(moment(get(e,'completedTime')).isBetween(moment(key).startOf('month'),moment(key).endOf('month'),null,'[]')) {
          const newListCustomer =  value.listCustomer?.map(customer => {
            if(get(customer,'_id') === get(e,'whBill.customerId')){
              return {...customer,totalAmount : get(customer,'totalAmount',0) + get(e,'whBill.grandTotal',0) -  get(e,'whBill.totalPaymentVoucherBillAmount',0)}
            }
            return customer
          })
          value.listCustomer = [...newListCustomer]
          }
        })
      })
      let data = []
      forIn(dataSource,(value,key) => {
        let totalSum = value?.listCustomer?.reduce((sum,curr) => sum + get(curr,'totalAmount',0),0)
        data.push({date : key,listCustomer:[...get(value,'listCustomer',0)],totalSum})
      })
      
      setData(data);
      setLoading(false);
    };
    if (id) fetchData();
  }, [id,date]);
  return [data, loading];
};

export const useReportWarehouse = (partnerId,date) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await api.reportPartner.getReportWarehouse({...date,partnerId});
        setData(result)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error,'error');
      }
    };
    if (partnerId) fetchData();
  }, [partnerId,date]);
  return [data, loading];
}
