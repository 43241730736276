import requester from './requester';
const groupCategory = {
  getAll: (query) => requester.get(`/user-group-service`, query),
  getById: ({ groupId }) => requester.get(`/user-group-service/${groupId}`),
  create: (groupCategory) => requester.post(`/user-group-service`, groupCategory),
  update: (groupCategory) => requester.put(`/user-group-service/${groupCategory._id}`, groupCategory),
  delete: (id) => requester.delete(`/user-group-service/${id}`),
  updatePermission: ({groupId, ...rest}) => requester.put(`/user-group-service/${groupId}/permission`, { ...rest }),
  deletePermission: ({ groupId, ...rest }) => requester.delete(`/user-group-service/${groupId}/permission`, { ...rest }),
  getPermission: (query) => requester.get(`/group-service-resource-permission`, query),
};
export default groupCategory;
