import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Popconfirm, Row, Space, Tag, Tooltip } from 'antd';
import { Suspense, lazy, useMemo, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import api from '~/api';
import { useFormTaskContext } from './WorkList';
import { WithOrPermission } from '../Common';
import POLICIES from '~/constants/policy';
const Task =lazy(()=>import('./Workitem.js'))
export default function BoardConfig({
  name,
  id,
  dataBoardConfigItem,
}) {

  const tasks = useMemo(()=>dataBoardConfigItem,[dataBoardConfigItem])
  const {openForm,handleDeleteWork,sprintId}=useFormTaskContext();
  const [inputValue, setInputValue] = useState(name);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = async (ev) => {
    if(!ev.target.value){
      setInputValue(name);
    }
    if(ev.target.value===name){
      return
    }
    try {
      await api.workFlow.updateWorkFlow({ name: ev.target.value??name, id });
    } catch (error) {
      console.log(error);
    }

  };

  return (
    <div className="work-list-main">
      <div className="work-list-column work-list-column_header">
        <Space direction='vertical' style={{width:'100%'}}>
          <Row className='work-item-top' justify='center' align='center' gutter={4}>
            <Col flex={1}>
              <Input
                  type="text"
                  size="small"
                  className='input-title-boardConfig'
                  style={{
                    width: '100%',
                    height: 30,
                    fontSize: '18px',
                    fontWeight: 700,
                    background:'transparent'
                  }}
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              </Col>
              <WithOrPermission permission={[POLICIES.ADMIN_TODOLIST,POLICIES.WRITE_TODOLIST]}>
                <Col style={{width:20}} className='work-item-top_delete-button'>
                  <Popconfirm
                    title="Bạn có chắc chắn muốn cột này ?"
                    onConfirm={() => { handleDeleteWork({id,sprintId}) }}
                    okText="Xác nhận"
                    cancelText="Huỷ"
                  >
                    <DeleteFilled style={{ color: '#DC3535' }} />
                  </Popconfirm>
                </Col>
              </WithOrPermission>
          </Row>
          {/* <WithOrPermission permission={[POLICIES.ADMIN_TODOLIST,POLICIES.WRITE_TODOLIST]}> */}
            <Tooltip title='Thêm mới công việc' color='blue' placement="bottom" mouseEnterDelay={0.2}>
            <Button
              type="primary"
              onClick={() => {
                openForm(id)
              }}
              className='add-task'
              icon={<PlusOutlined />}
            />
            </Tooltip>
          {/* </WithOrPermission> */}
          
        </Space>
      </div>
      <Droppable key={id} droppableId={id} type={'ROW'}>
        {(provided) => (
          <div
            key={id}
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="work-list-column work-list-column_body"
            style={{
              height: '100%'
            }}
          >
            <div className="task-list">
              {tasks?.map((task, index) => (
                <Draggable key={task._id} draggableId={task._id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      key={task._id} 
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="task-list__item"
                    >
                      <Suspense fallback={<div >...</div>}>
                      <Task
                        key={task._id}
                        task={task}
                        idBoard={id}
                        nameBoard={name}
                        newTask={tasks}
                      />
                       </Suspense>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

