import requester from './requester';

const trainingCoursePartner = {
  create: (course) => requester.post('/course-training-partner', course),
  submit: (course) => requester.post('/wh-time-sheet', course),
  getCourses: (courseId) => requester.post('/course-training-partner/listCoursePartner', courseId), // By CourseId
  getAll: (id) => requester.get(`/course-training-partner/${id}`),
  getAllCourseReadySubmit: (id) => requester.get(`/course-training-partner/checkCourse/${id}`),
  getOne: (id) => requester.get(`/course-training-partner/${id}/coursePartner`),
  getNoteByIdPartner: (id) => requester.get(`/course-training-partner/${id}/note/timeSheet`),
  update: (course) => requester.put(`/course-training-partner/${course._id}`, course),
  delete: (query) => requester.post(`/course-training-partner/${query?.id}`,query),
  getAllTrainingCourseOfPartner: (course) => requester.get(`/course-training-partner/${course._id}/all`, course),
};
export default trainingCoursePartner;
