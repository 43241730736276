export const GET_POINT_SYSTEMS_REQUEST =
  'GET_POINT_SYSTEMS_REQUEST';
export const GET_POINT_SYSTEMS_SUCCESS =
  'GET_POINT_SYSTEMS_SUCCESS';
export const GET_POINT_SYSTEMS_FAILED = 'GET_POINT_SYSTEMS_FAILED';

export const GET_POINT_SYSTEMS_PARTNER_REQUEST =
  'GET_POINT_SYSTEMS_PARTNER_REQUEST';
export const GET_POINT_SYSTEMS_PARTNER_SUCCESS =
  'GET_POINT_SYSTEMS_PARTNER_SUCCESS';
export const GET_POINT_SYSTEMS_PARTNER_FAILED = 'GET_POINT_SYSTEMS_PARTNER_FAILED';

export const GET_POINT_SYSTEM_REQUEST = 'GET_POINT_SYSTEM_REQUEST';
export const GET_POINT_SYSTEM_SUCCESS = 'GET_POINT_SYSTEM_SUCCESS';
export const GET_POINT_SYSTEM_FAILED = 'GET_POINT_SYSTEM_FAILED';

export const CREATE_POINT_SYSTEM_REQUEST =
  'CREATE_POINT_SYSTEM_REQUEST';
export const CREATE_POINT_SYSTEM_SUCCESS =
  'CREATE_POINT_SYSTEM_SUCCESS';
export const CREATE_POINT_SYSTEM_FAILED =
  'CREATE_POINT_SYSTEM_FAILED';

export const UPDATE_POINT_SYSTEM_REQUEST =
  'UPDATE_POINT_SYSTEM_REQUEST';
export const UPDATE_POINT_SYSTEM_SUCCESS =
  'UPDATE_POINT_SYSTEM_SUCCESS';
export const UPDATE_POINT_SYSTEM_FAILED =
  'UPDATE_POINT_SYSTEM_FAILED';

export const DELETE_POINT_SYSTEM_REQUEST =
  'DELETE_POINT_SYSTEM_REQUEST';
export const DELETE_POINT_SYSTEM_SUCCESS =
  'DELETE_POINT_SYSTEM_SUCCESS';
export const DELETE_POINT_SYSTEM_FAILED =
  'DELETE_POINT_SYSTEM_FAILED';


export const RESET_POINT_SYSTEM_STATE = 'RESET_POINT_SYSTEM_STATE';
