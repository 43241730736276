import { Button, Form, Input, Row } from 'antd'
import { get, pick } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import { BASE_URL, LANGUAGE } from '~/constants/defaultValue';
import { useCreatePositionJob, usePositionJob, useUpdatePositionJob } from '~/hooks/positionJob';
import { renderLoading } from '~/utils/helper';
import LangFormItem from '../Common/LangFormItem';
import LangSelect from '../Common/LangSelect';
import Editor from '../Utils/Editor';
import UploadFile from '../Workspace/WhManagerTrainingCourse.js/UploadFile';

export default function FormPositionJob({ id, onCancel }) {
    const [language,setLanguage] = useState(LANGUAGE.VI);
    const [form] = Form.useForm();
    const [isSubmitLoading, onCreate] = useCreatePositionJob(onCancel);
    const [, onUpdate] = useUpdatePositionJob(onCancel);
    const [positionJob, loading] = usePositionJob(id);
    const onFinish = useCallback((values) => {
        console.log(values,'values');
        if (id) {
            onUpdate({
                ...values,
                _id: id
            });
        } else {
            onCreate({
                ...values,
            })
        }
    }, [id]);
    useEffect(() => {
      if (id && positionJob) {
            form.setFieldsValue({ ...positionJob });
        }
    }, [positionJob, id]);

    return (
        <Form initialValues={{files : []}} labelCol={{ span: 4 }} form={form} onFinish={onFinish}>
        <LangSelect language={language} setLanguage={setLanguage}/>
            <LangFormItem label="Tên vị trí" fieldName={"title"} language={language}>
                {renderLoading(<Input />, loading)}
            </LangFormItem>
            <Form.Item
                label="Nội dung gửi email"
                name={'email'}
            >
                {renderLoading(<Editor />, loading)}
            </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() =>   <Form.Item
                name={'files'}
                label="File đính kèm"
                help="Vui lòng chọn file dưới 3MB"
                tooltip="File đính kèm khi gửi Email"
            >
                <UploadFile
                    apiAction={`${BASE_URL}/api/v1/job-posting/file`}
                    files={form.getFieldValue('files')}
                    setFiles={(value) => form.setFieldsValue({files : value?.map((item) => pick(item, ['name', 'response', 'uid']))})}
                />
            </Form.Item>}
          </Form.Item>
            <Row justify='end'>
                <Button loading={isSubmitLoading} htmlType='submit' type='primary'>
                    {id ? "Cập nhật" : "Tạo mới"}
                </Button>
            </Row>
        </Form>
    )
}
