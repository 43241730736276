import React, { useEffect, useCallback, useState } from 'react';
import { Form, Input, Row, Button, Skeleton, Col, Select } from 'antd';
import { RATE_CONFIG_TYPES } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import {
  useRateConfig,
  useCreateRateConfig,
  useUpdateRateConfig,
  useInitRateConfig,
  useResetRateConfig
} from '~/hooks/rateConfig';
import { useStaffGroups } from '~/hooks';
import { get } from 'lodash';

const FormItem = Form.Item;
const { Option } = Select;
const PositionForm = ({ specialityOptions, onClose, positionId }) => {
  const [staffGroups, isLoading] = useStaffGroups();
  const [form] = Form.useForm();
  const [isSubmitLoading, handleCreate] = useCreateRateConfig(onClose);
  const [, handleUpdate] = useUpdateRateConfig(onClose);
  const [position] = useRateConfig(positionId);
  const initPosition = useInitRateConfig(position);
  useResetRateConfig();
  useEffect(() => {
    if (!positionId) {
      form.resetFields();
    } else {
      const tmp = {
        ...initPosition,
        employeeGroup: get(initPosition, 'employeeGroup.0._id')
      };
      form.setFieldsValue(tmp);
    }
  }, [initPosition, positionId, form]);

  const onFinish = useCallback(
    (values) => {
      if (positionId) {
        handleUpdate({ ...values, id: positionId });
      } else {
        handleCreate({ ...values, configType: RATE_CONFIG_TYPES.POSITION });
      }
      onClose();
    },
    [handleCreate, handleUpdate, positionId]
  );
  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;
  return (
    <div className="position page-wraper hospital-form-page-content">
      <h4 style={{margin: '20px 0 40px 20px'}}>
        {positionId ? ' Cập nhật' : 'Thêm mới'} chức vụ
      </h4>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          // initialValues={initPosition}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <Col span={24}>
            <FormItem
              label="Nhóm đối tác"
              name="employeeGroup"
              rules={[
                {
                  required: true,
                  message: 'Xin vui lòng chọn nhóm đối tác'
                }
              ]}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  // loading={isStaffGroupLoading}
                  showSearch
                  autoComplete="off"
                  placeholder="Nhóm đối tác"
                  style={{ width: '100%' }}
                  // style={{marginLeft: '150px'}}
                  // filterOption={filterAcrossAccents}
                >
                  {staffGroups.map(({ id, _id, name }) => {
                    return (
                      <Option key={id || _id} value={id || _id}>
                        {name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
          <FormItem
            label="Chức vụ đảm nhận(vi) "
            name={['name', 'vi']}
            rules={[
              { required: true, message: 'Xin vui lòng nhập chức vụ đảm nhận' }
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="Chức vụ đảm nhận(en)"
            name={['name', 'en']}
            rules={[
              { required: true, message: 'Xin vui lòng nhập chức vụ đảm nhận' }
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="Hệ số"
            name="rate"
            rules={[{ required: true, message: 'Xin vui lòng nhập hệ số' }]}
          >
            <Input />
          </FormItem>
          <Row className="form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.rateConfig.position}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {positionId ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default PositionForm;
