import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Input, Popconfirm, Table, Tag, Checkbox, Tooltip } from "antd";
import { useMatchPolicy, useProfile } from "~/hooks";
import { useCreateStatusConfig, useUpdateStatusConfig,useStatusConfigQueryParams, useGetListStatusConfig, useDeleteStatusConfig } from "~/hooks";
import StatusEditForm from "./StatusEditForm";
import { compact } from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import POLICY from '~/constants/policy';
import { WithPermission } from "~/components/Common";
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  ...restProps
}) => {
  const [, updateStatusConfig] = useUpdateStatusConfig();
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (editable) {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  };
  

  const save = (field)=>async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if((record[field]) !== (values[field])){
        updateStatusConfig({...values,id: record._id});
      }
      setEditing(false)

    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editing) {
    switch (dataIndex) {
      case "backgroundColor":
        childNode = (<StatusEditForm field={'backgroundColor'} inputRef={inputRef} save={save('backgroundColor')} form={form}/>);
        break;
      case "color":
        childNode = (<StatusEditForm field={'color'} inputRef={inputRef} save={save('color')} form={form}/>);
        break;
      default:
        childNode = (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            <Input ref={inputRef} onBlur={save('value')} onPressEnter={save('value')} />
          </Form.Item>
        );
        break;
    }
  } else {
        childNode = (
          <div
            // className="editable-cell-value-wrap"
            style={{
              // paddingRight: 24,
              cursor: 'pointer',
              outline: 'none',
              border: 'none',

            }}
            onClick={toggleEdit}
          >
            {children}
          </div>
        );
  }

  return <td {...restProps}>{childNode}</td>;
};
const StatusConfigList = () => {
  // const [dataSouce,setDataSource] =setDataSource([null]);
   const [{isSuperAdmin}] =useProfile();
  const canDelete = useMatchPolicy(POLICY.DELETE_TODOCONFIGSTATUS)
  const canUpdate = useMatchPolicy(POLICY.UPDATE_TODOCONFIGSTATUS)
  const [, createStatusConfig] = useCreateStatusConfig();
  const [, updateStatusConfig] = useUpdateStatusConfig();
  const [, deleteStatusConfig] = useDeleteStatusConfig();
  const [query] = useStatusConfigQueryParams();
  const [listStatusConfig] =useGetListStatusConfig(query);
  const handleDelete = (id) => {
    deleteStatusConfig({id});
  };

  const defaultColumns = [
    {
      title: "Trạng thái",
      dataIndex: "value",
      width: "100px",
      key:'valueStatus',
      align: "center",
      // editable: true,
      render: (value, record) => (
        <Tag color={record.backgroundColor} style={{ color: record.color }}>
          {value}
        </Tag>
      )
    },
    {
      title: "Tên trạng thái",
      dataIndex: "value",
      // width: "100px",
      key:'value',
      align: "center",
      editable: canUpdate,
    },
    {
      title: <Tooltip title='Giá trị trạng thái này sẽ được ưu tiên chọn khi công việc được tạo mới'><span style={{cursor:'pointer'}}>Ưu tiên <ExclamationCircleOutlined/></span></Tooltip>,
      dataIndex: "priority",
      width: "100px",
      key:'priority',
      align: "center",
      render:(value,record)=>(
          <Checkbox
            checked={value}
            onChange={(e) => {
              if (value) return;
              updateStatusConfig({
                priority: e.target.checked,
                isDefault:true,
                statusHidden:false,
                id: record._id
              });
            }}
          />
        )      
    },
    {
      title: "Màu nền",
      dataIndex: "backgroundColor",
      align: "center",
      key:'backgroundColor',
      editable: canUpdate,
      width:180,
      render: (value, record) => (
        <Input value={value}  readOnly addonBefore={<div style={{backgroundColor:record.backgroundColor, width: 30, height: 30,border: '1px solid #c1bdbde8',cursor:'pointer'}} ></div>}/>
      )
    },
    {
      title: "Màu chữ",
      dataIndex: "color",
      align: "center",
      key:'color',
      editable: canUpdate,
      width:180,
      render: (value, record) => (
        <Input value={value} readOnly addonBefore={<div style={{backgroundColor:record.color, width: 30, height: 30,border: '1px solid #c1bdbde8',cursor:'pointer'}} ></div>}/>
      )
    },

    {
      title:'Mặc định',
      dataIndex:'isDefault',
      key:'isDefault',
      align: "center",
      width:80,
      render: (value, record) => {
        const disable = Boolean(record.priority) &&Boolean(record.isDefault)
        const title = disable? 'Không thể thực hiện thao tác vì trạng thái hiện tại đang được ưu tiên' :'';
        return (
          <Tooltip title={title} >
            <Checkbox
            disabled={disable}
              checked={value}
              onChange={(e) => {
                updateStatusConfig({
                  isDefault: e.target.checked,
                  id: record._id
                });
              }}
            />
          </Tooltip>
        );
      }
    },
    {
      title:'Quyền quản trị',
      dataIndex:'justAdmin',
      key:'justAdmin',
      align: "center",
      width:80,
      render: (value, record) => (
        <Checkbox checked={value} onChange={(e)=>{
          if(!canUpdate) return 
          updateStatusConfig({justAdmin:e.target.checked,id:record._id})
        }}/>
      )
    },
    {
      title:'Ẩn',
      dataIndex:'statusHidden',
      key:'statusHidden',
      align: "center",
      width:60,
      render: (value, record) => (
        <Tooltip align={'center'} title={!record?.justAdmin? 'Quyền quản trị phải được bật': (record.priority === true )? 'Không thể thực hiện thao tác vì trạng thái này là "Ưu tiên"':''}>
          <Checkbox disabled={!record?.justAdmin||record.priority} checked={value} defaultChecked={value} onChange={(e)=>{
            if(!canUpdate) return 
            updateStatusConfig({statusHidden:e.target.checked,id:record._id})
          }}/>
        </Tooltip>
      )
    },
    canDelete?
    {
      title: "Hành động",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width:80,
      render: (_, record) =>
      listStatusConfig?.length >= 1 ? (
          <Popconfirm
            title="Xác nhận xoá!"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button type="dashed" style={{color:'red'}} size="small">Xoá</Button>
          </Popconfirm>
        ) : null,
    }:'',
  ];

  const handleAdd = () => {
    const newData = {
      value: 'Mặc định',
      key:'DEFAULT',
      backgroundColor: "#0040ffff",
      color: '#ffffffff',
      justAdmin:false,
      isDefault:false,
    };
    createStatusConfig(newData)
  };

// useEffect(()=>{
//   let dataSouce = listStatusConfig.reverse();
//   setDataSource(dataSouce);
// },[listStatusConfig])
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = compact(defaultColumns).map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
    
      }),
    };
  });
  return (
    <div>
      <WithPermission permission={POLICY.WRITE_TODOCONFIGSTATUS}>
              <Button
          onClick={handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          Thêm cấu hình trạng thái
        </Button>
       </WithPermission>

      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        size="small"
        pagination={false}
        dataSource={listStatusConfig}
        columns={columns}
      /> 
    </div>
  );
};

export default StatusConfigList;
