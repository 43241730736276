import * as Types from '../../constants/actionTypes';
  
  export const getTimeSheets = query => ({
    type: Types.GET_TIME_SHEETS_REQUEST,
    payload: query
  });
  export const getTimeSheet = id => ({
    type: Types.GET_TIME_SHEET_REQUEST,
    payload: id
  });
  export const createTimeSheet = instance => ({
    type: Types.CREATE_TIME_SHEET_REQUEST,
    payload: instance
  });
  export const updateTimeSheet = instance => ({
    type: Types.UPDATE_TIME_SHEET_REQUEST,
    payload: instance
  });
  export const updateTrainingCoursePartnerInTimeSheet = instance => ({ //
    type: Types.UPDATE_COURSE_TRAINING_IN_TIME_SHEET_REQUEST,
    payload: instance
  });
  export const deleteTimeSheet = id => ({
    type: Types.DELETE_TIME_SHEET_REQUEST,
    payload: id
  });
  export const resetTimeSheetState = () => ({
    type: Types.RESET_TIME_SHEET_STATE
  });