import * as Types from '~/constants/actionTypes';

export const getWareHouseList   = (query) => ({
    type: Types.GET_WAREHOUSE_LISTS_REQUEST,
    payload:query
})
export const getWareHouseById = (id) => ({
    type: Types.GET_WAREHOUSE_BYID_REQUEST,
    payload:id
})
export const createWareHouse = (data) => ({  
    type: Types.CREATE_WAREHOUSE_REQUEST,
    payload:data
})
export const updateWareHouse = (data) => ({
    type: Types.UPDATE_WAREHOUSE_REQUEST,
    payload:data
})
export const deleteWareHouse = (id) => ({
    type: Types.DELETE_WAREHOUSE_REQUEST,
    payload:id
})

export const getProductListByWareHouseId = (data) => ({
    type:Types.GET_WAREHOUSE_PRODUCTLIST_REQUEST,
    payload:data
})