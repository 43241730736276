import React, { useEffect, useState } from "react";
import SkeletonTable from "../Utils/SkeletonTable";
import { Checkbox, Modal, Table } from "antd";
import WhPaymentVoucherForm from "./Form/WhPaymentVoucherForm";
import WhReceiptVoucherForm from "./Form/WhReceiptVoucherForm";
import api from "~/api";
import { Button } from "reactstrap";
import { get } from "lodash";
import getPaging from "~/utils/getPaging";
import WhVoucherStatusTag from "./components/WhVoucherStatusTag";
import { useQueryParams } from "~/hooks/utils";
import { useWhAllVoucherPaging, useWhAllVoucherQueryParams, useWhAllVouchers } from "~/hooks/whAllVouhers";
import moment from "moment";
import ShowTotalMoney from "./ShowTotalMoney";
import { useProfile } from "~/hooks";

const WhAllVoucher = ({setArrIds,setQueryAllVoucher, noShowTitle,branchIds}) => {
  const [isOpenReceipt, setIsOpenReceipt] = useState(false)
  const [isOpenPayment, setIsOpenPayment] = useState(false)
  const [arrCheckBox, setArrCheckBox] = useState([])
  const [profile] = useProfile();
  const [whVoucherId, setWhVoucherId] = useState(null)
  
  const [query, onTableChange] = useWhAllVoucherQueryParams({branchIdsDefault : branchIds})

  const [allVouchers, isLoading] = useWhAllVouchers(query)

  const paging = useWhAllVoucherPaging()

  //Featch Data
  // const getData = async () => {
  //   const res = await api.whAllVoucher.getAll()
  //   setInitData(res)
  // }

  // useEffect(() => { 
  //   getData()
  // }, [])
  // Get list Ids
  const onChangeCheckBox = (e, id) => {
    if (e) {
    setArrCheckBox([...arrCheckBox, id])
    } else {
      const getIndex = arrCheckBox.findIndex((itemId, index) => itemId === id)
      const newArr = arrCheckBox.toSpliced(getIndex, 1)
      setArrCheckBox(newArr)
    }
  } 

  useEffect(() => {
    setQueryAllVoucher(query)
  }, [query])
  
  useEffect(() => {
    if (typeof setArrIds === 'function') {
      setArrIds(arrCheckBox)
    }
  }, [arrCheckBox, setArrIds])
  
  const nameService = (record) =>{
    if (get(record, "whBillItem.snapshotService.detailName.vi"))
          return `${get(record, "whBillItem.snapshotService.code")} - ${get(record, "whBillItem.snapshotService.name.vi")} - ${get(record, "whBillItem.snapshotService.detailName.vi")}`
        else 
          return `${get(record, "whBillItem.snapshotService.code")} - ${get(record, "whBillItem.snapshotService.name.vi")}`
  }
  const columns = [
    {
      title: 'Mã phiếu thu - chi',
      dataIndex: 'code',
      key: 'code',
      render: (text, record, index) => {
        return (
          <Button
            type='link'
            style={{ background: '0 0',boxShadow: 'none',borderColor:'transparent', color:'#1890ff'}}
            onClick={() => {
              setWhVoucherId(get(record , '_id'))
              if (record?.code.includes('PT')) {
                setIsOpenReceipt(true);
              } else { 
                setIsOpenPayment(true)
              }
            }}
          >
            {text}
          </Button>
        )
      }
    },
    {
      title: 'Số chứng từ',
      dataIndex: 'issueNumber',
      key: 'issueNumber',
      render: text => text || "-",
    },
    {
      title: 'Mã đơn hàng',
      key: 'billNumber',
      dataIndex : 'whBill',
      render: (text, record, index) => get(record, "whBill.billNumber") ? get(record, "whBill.billNumber",'') : get(record,"reason",''),
    },
    {
      title: 'Nội dung',
      key: 'reason',
      dataIndex: 'reason',
      render: (text, record, index) =>  get(record,"reason",''),
    },
    // {
    //   title: 'ID dịch vụ',
    //   dataIndex: 'code',
    //   key: 'code',
    //   render: (text, record, index) => {
    //     return get(record, "whBillItem?.code") ? `DV${get(record, "whBillItem?.code", '')} ` : "-"
    //   }
    // },
    {
      title: 'Số tiền',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text, record, index) => text?.toLocaleString(),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text)?.format('DD-MM-YYYY HH:mm:ss') ,
    },
    {
      title: 'Ngày duyệt',
      dataIndex: 'dateApproved',
      key: 'dateApproved',
      render: (text, record, index) => text && moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => <WhVoucherStatusTag status={text} />
    },
    {
      title: 'Lựa chọn',
      key: '_id',
      width: 80,
      align: 'center',
      render: (item, record) =>
      {
        const id = record._id
        return <Checkbox
       defaultChecked= {arrCheckBox.includes(id)}
        onChange={(e)=>onChangeCheckBox(e.target.checked, id)}
      />}
    },
  ];

  if(get(profile,'isSuperAdmin')){
    columns.push({
      title: 'Chi nhánh',
      dataIndex: 'branch',
      key: 'branch',
      fixed:'right',
      align : 'center',
      render: (branch, record, index) => get(branch,'name',''),
    },)
  };
  const keySum = {
    totalAmount: 'totalAmount',
    All: 'All'
  }
  return (
    <div>
    <div className="container-fluid">
        <ShowTotalMoney branchIds={branchIds} totalMoneyPage={allVouchers} keySumPage={keySum.totalAmount} keyPageName={keySum.All} queryProp={query} />
      {isLoading ? (
        <SkeletonTable
          // rowCount={allVouchers.length}
          columns={columns}
          // pagination={paging}
        />
        ) : (
            <>
              
              <Table
                // title={()=> !noShowTitle &&  ShowTotalMoney(allVouchers, keySum.totalAmount, keySum.All) }
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={allVouchers}
                onChange={onTableChange}
                size="middle"
                pagination={{
                  ...paging,
                  // ...(useLocalPagination && { current }),
                  showTotal: (totalDocs) => `Tổng cộng: ${totalDocs} `
                }}
              />
            </>
      )}
    </div>
    <Modal
      centered
      footer={null}
      onCancel={()=> setIsOpenPayment(false)}
      onOk={()=> setIsOpenPayment(false)}
      title={`Phiếu chi`}
      visible={isOpenPayment}
      width={1366}
    >
        <WhPaymentVoucherForm
        // noBillId={noBillId}
        whPaymentVoucherId={whVoucherId}
        onClose={()=> setIsOpenPayment(false)}
        onCancel={()=> setIsOpenPayment(false)}
        />
        
      </Modal>
      <Modal
      centered
      footer={null}
      onCancel={()=> setIsOpenReceipt(false)}
      onOk={()=> setIsOpenReceipt(false)}
      title={`Phiếu thu`}
      visible={isOpenReceipt}
      width={1366}
      >
          <WhReceiptVoucherForm
          whReceiptVoucherId={whVoucherId}
          onClose={() =>{
            setIsOpenReceipt(false)}}
          onCancel={() => {
            setIsOpenReceipt(false);
          }}
        />
        </Modal>
  </div>
  )
}
export default WhAllVoucher
