import React, { useEffect, useMemo, useState } from "react"
import { Col, Row, Spin } from "antd"
import './index.scss'
import Api from "~/api"
import { useParams ,useHistory} from "react-router-dom"
import { get } from "lodash"
const ShowTotalMoney = ({ totalMoneyPage, keySumPage, keyPageName, billNumber, branchIds, useLocalPagination, queryProp }) => {
  const [totalAll, setTotalAll] = useState(null);
  const [loading, setLoading] = useState(true);
  const query = useMemo(() => ({
    totalSum: true,
    ...queryProp
  }), [queryProp]);
  // const filterQuery = useMemo(() => query?.split('&')?.filter((item) => (!item.includes('page=') && !item.includes('limit='))),[query])
  // const _newQuery = useMemo(() => filterQuery.reduce((acc, curr) => {
  //   const [key, value] = curr.split('=') ;
  //   acc[key] = value;
  //   return acc;
  // }, {}),[filterQuery])
  const newQuery = useMemo(()=>({...query, billNumber: billNumber || null,branchIds,status : !useLocalPagination ? get(query,'status') : null}),[query, billNumber,useLocalPagination]);
  //Handle total money by page
  const sumTotalMoney = () => {
    let total = 0;
    if (keyPageName === 'All') {
      let sumPayment = 0;
      let sumReceipt = 0;
      const totalPaymentData = totalMoneyPage?.filter((item) => item?.type === 'PAYMENT');
        for (let i = 0; i < (totalPaymentData?.length ?? []); i++) {
          sumPayment += totalPaymentData[i][keySumPage]
      };
      const totalReceiptData = totalMoneyPage?.filter((item) => item?.type !== 'PAYMENT');
      for (let i = 0; i < (totalReceiptData?.length ?? []); i++) {
        sumReceipt += totalReceiptData[i][keySumPage]
      };
       total = sumReceipt - sumPayment;
    } else {
      for (let i = 0; i < (totalMoneyPage?.length ?? []); i++) {
        total += totalMoneyPage[i][keySumPage]
      };
    };
    return total;
  };
  //Featch Data total money all database

  useEffect(() => {
    const getData = async () => {
      try {
        if (newQuery?.startDate || newQuery?.endDate) {
          const res = await Api.whReceiptVoucher.getTotalMoney(newQuery)
          setTotalAll(res?.[0])
          setLoading(false)
        } else {
          setLoading(false)
        };
      } catch {
        console.log('featch data failed')
        setLoading(false)
      };
    };
    getData();
    // newQuery  && getData() 
  },[newQuery, query])
  const cantShowTotalMoneyPage = totalMoneyPage ? true : false
  //handle by page
  const handleSum = () => {
    let total = '';
    if (keyPageName === 'All') {
      total = ` Tổng tiền theo thời gian: ${totalAll ? (totalAll?.sumtotalAmountReceipt - totalAll?.sumtotalAmountPayment)?.toLocaleString() : '0' }đ`
    } else {
      total = ` Tổng tiền theo thời gian: ${totalAll ? totalAll[`sumtotalAmount${keyPageName}`]?.toLocaleString() : '0'}đ`
    }
    return total
  }
  return (
  <Row className="whVoucher-table__title" style={{backgroundColor: 'red !importand', color:' rgba(0, 0, 0, 0.85)'}} gutter = {36}>
      <Col span={12}>
        <h5
          style={{backgroundColor: '#fff7e6', color:'#fa8c16',border:'1px solid #ffd591 ', padding: '5px 10px'}}
        >{loading ? <Spin /> : handleSum()}</h5>
      </Col>
      <Col span={12}>
      {loading ? <Spin /> : <h5
          style={{backgroundColor: '#fff7e6', color:'#F45050',border:'1px solid #ffd591 ', padding: '5px 10px'}}
        >{cantShowTotalMoneyPage ? ` Tổng tiền theo trang hiện tại: ${sumTotalMoney()?.toLocaleString()}đ ` : ''}</h5>}
      </Col>
  </Row>)
}
export default ShowTotalMoney
