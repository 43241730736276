import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import locale from 'antd/lib/date-picker/locale/vi_VN';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInitOptionNewsSearchSelect, useSearchNews } from '~/hooks';
import { getNewses } from '~/redux/action';
import { getDateByTypePicker, getExistProp } from '~/utils/helper';
import { monthCellRender } from '../Common/VietNameMonthTimpicker';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { PATH_APP } from '~/routes/paths';
import { optionsDateNews, optionsNewsIsFeatured, optionsNewsStatus, stylesheetNews } from './constant';

const handleConvertOption = (label, value) => {
  const _ = (__) => String(__).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  return _(label).includes(_(value));
}

function FormSearch({onTableChange}) {
  const [form] = Form.useForm();
  const history = useHistory()
  const [checkFieldDate, setCheck] = useState(true);
  const [optiondate, setOptionDate] = useState('');
  const [firstFocusSelect, setFirstForcusSelect] = useState(false);

  const dispatch = useDispatch();
  

  const [[dataCategory, dataAuthor],
    // [handleGetCategory, handleGetAuthor]
  ] = useSearchNews(firstFocusSelect)
  useEffect(()=>{
    history.push(PATH_APP.news.root,null)
  },[])
  const [
    optionAuthor,
    optionCategory
  ] = useInitOptionNewsSearchSelect(
    {
      dataAuthor,
      dataCategory
    }
  );


  const onTypingSearch = useCallback((value) => {
    dispatch(getNewses({ keyword: value }))
  }, [])

  const handleChangeField = (e, object) => {
    object= getExistProp(object)
    for (const key in object) {
      switch (key) {
        case 'filterDate': {
          object[key] = getDateByTypePicker(object[key],optiondate);
          }
        break;

        default:
          object[key] =  object[key].toString();
        break;
      }
    }
    dispatch(getNewses(object))
    onTableChange({current:1})
    history.push(PATH_APP.news.root,object)
  }
  // const handleSearchAuthor = (value) => {
  //   handleGetAuthor({ keyword: compact([value]).toString() })
  // }

  // const handleSearchCategory = (value) => {
  //   handleGetCategory({ keyword: compact([value]).toString() })
  // }
  const resetTime = ()=>{
    form.setFieldsValue({filterDate:null})
  }

  const propsDatePicker = {  style: { width: '100%' },locale:locale,  monthCellRender:monthCellRender  }


  return (
    <div className='form-search-news'>
      <RowForm label='Tìm kiếm'>
        <Input.Search
          enterButton
          allowClear
          placeholder='Tìm tên bài viết'
          onSearch={onTypingSearch}
        />
      </RowForm>
      <hr />
      <Form
        form={form}
        onValuesChange={handleChangeField}
        layout='vertical'
        initialValues={{
          status: 'PUBLISHED,PRIVATE,EDITING',
          isFeatured: ''
        }}
      >
        <RowForm label='Lọc theo nổi bật' name='isFeatured'>
          <Select
              style={{ width: '100%' }}
              options={optionsNewsIsFeatured}
            />
        </RowForm>
        <RowForm label='Lọc theo trạng thái' name='status'>
          <Select
            style={{ width: '100%' }}
            options={optionsNewsStatus}
          />
        </RowForm>
        <RowForm label='Lọc theo tác giả' name='author'>
          <Select
            mode='multiple'
            placeholder='Tìm tên tác giả'
            // onSearch={debounce(handleSearchAuthor, 700)}
            onFocus={() => !firstFocusSelect ? setFirstForcusSelect(true) : ''}
            filterOption={(value, option) => {
              return handleConvertOption(option.label, value)
            }}
            style={{ width: '100%' }}
            options={optionAuthor}
          />
        </RowForm>
        <RowForm label='Lọc theo danh mục' name='category'>
          <Select
            placeholder='Tìm tên danh mục'
            mode='multiple'
            onFocus={() => !firstFocusSelect ? setFirstForcusSelect(true) : ''}
            // onSearch={debounce(handleSearchCategory, 700)}
            filterOption={(value, option) => {
              return handleConvertOption(option.label, value)
            }}
            style={{ width: '100%' }}
            options={optionCategory}
          />
        </RowForm>
        <RowForm label={
          <Select 
            // placeholder='Lọc theo ngày'
            defaultValue={''}
            style={{ width: '100%' }}
            options={optionsDateNews}
            onSelect={(ranger)=>{
              setOptionDate(ranger);
              resetTime()
              ranger==='ranger'? setCheck(false):setCheck(true);
            }}
          ></Select>
      } name='filterDate' >
          {
            checkFieldDate ?
              <DatePicker 
                picker={optiondate}
                placeholder={optionsDateNews.find(e=>e.value===optiondate).label} 
               {...propsDatePicker}
                /> :
              <DatePicker.RangePicker placeholder={['Từ ngày','Đến ngày']} format='DD-MM-YYYY' {...propsDatePicker} />
          }
        </RowForm>
      </Form>
    </div>
  )
}


function RowForm({ label, children, name, span = 24 }) {
  return (
    <Row align='middle' justify='center' style={{ marginBottom: 18 }}>
      <Col span={span}>
        {label}
      </Col>
      <Col span={span} style={{ marginBottom: '4px' }}>
      </Col>
      <Col span={span}  >
        <Form.Item style={{ marginBottom: 0 }} labelAlign='right' name={name}>
          {children}
        </Form.Item>
      </Col>
    </Row>
  )
}
RowForm.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.element
}
RowForm.defaultProps = {
  label: 'label',
  children: <div></div>
}


export default FormSearch
