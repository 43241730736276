export const ACCEPT_TYPE = {
    NEW:'NEW',
    ACCEPT:'ACCEPT',
    DENY:'DENY',
  }
export const ACCEPT_TYPE_VI = {
    NEW:'Chưa phê duyệt',
    ACCEPT:'Đã phê duyệt',
    DENY:'Đã từ chối',
  }
