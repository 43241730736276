import { Col, Row, Table, Tag } from 'antd'
import Text from 'antd/lib/typography/Text'
import { get } from 'lodash';
import React, { useMemo } from 'react'
import { TYPE_WAREHOUSE_VI } from '~/constants/defaultValue';
import { floorFormatter } from '~/utils/helper';

export default function TableDetail({ dataSource, dataWhBill }) {
    const columnsFirst = useMemo(() =>
        [{
            name: "Doanh thu hệ thống",
            depreciation: get(dataWhBill, 'grandTotal', 0),
            percentDepreciation: "-"
        }]
        , [dataWhBill]);
    const renderSummary = pageData => {
        const totalDepreciation = pageData.reduce((prev, curr, index) => {
            if (index === 0) return prev; // remove First columnsFirst
            return prev + parseFloat(get(curr, "depreciation"));
        }, 0);
        const totalPercent = pageData.reduce((prev, curr, index) => {
            if (index === 0) return prev; // remove First columnsFirst
            return prev + parseFloat(get(curr, "percentDepreciation"));
        }, 0);

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell align="right" colSpan={2}>
                    <span>Tổng chi phí khấu hao</span>
                    <h5>{floorFormatter(totalDepreciation)}</h5>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" colSpan={1}>
                    <span>Tổng % khấu hao</span>
                    <h5>{totalPercent?.toFixed(1)}</h5>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        )
    }
    const columns = [
        {
            title: 'Loại khấu hao',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => <Row justify='space-between' >
                <Col span={18}>
                    {value}
                </Col>
                {
                    get(record, 'typeWarehouse') && <Col>
                        <Tag color='processing'>
                            {TYPE_WAREHOUSE_VI?.[get(record, 'typeWarehouse')]}
                        </Tag>
                    </Col>
                }
            </Row>
        },
        {
            title: 'Chi phí khấu hao',
            dataIndex: 'depreciation',
            key: 'depreciation',
            align: 'center',
            render: (value) => value?.toLocaleString()
        },
        {
            title: 'Tính % khấu hao',
            dataIndex: 'percentDepreciation',
            key: 'percentDepreciation',
            align: 'center',
            render: (value) => <Text strong>{value}</Text>
        },
    ]
    return (
        <Table
            columns={columns}
            dataSource={[...columnsFirst, ...dataSource]}
            pagination={false}
            size="small"
            summary={renderSummary}

        />
    )
}
