import React, { useEffect, useCallback, useState, useRef } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Skeleton,
  Space,
  Popconfirm,
  Select
} from 'antd';
import { LANGUAGE, TYPE_CATEGORY } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import {
  useCreateWhCategory,
  useWhCategory,
  useInitWhCategory,
  useResetWhCategory,
  useUpdateWhCategory
} from '~/hooks/whCategory';
import { useParams } from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import UploadImage from '~/components/Utils/UploadImage';

import './form.scss';
  import { MinusCircleOutlined } from '@ant-design/icons';
import { get } from 'lodash';

const FormItem = Form.Item;
const optionType = []
for (const [key, value] of Object.entries(TYPE_CATEGORY)) {
  optionType.push({label : value.vi,value : key})
}
const WhCategoryForm = () => {
  const [form] = Form.useForm();
  const [desktopImg, setDesktopImg] = useState();
  const [mobileImg, setMobileImg] = useState();
  const [smallImg, setSmallImg] = useState();

  const [desktopImgShort, setDesktopImgShort] = useState();
  const [mobileImgShort, setMobileImgShort] = useState();

  const [isSubmitLoading, handleCreate] = useCreateWhCategory();
  const [, handleUpdate] = useUpdateWhCategory();

  const { id } = useParams();
  const [whCategory, isLoading] = useWhCategory(id);
  const initWhCategory = useInitWhCategory(whCategory);

  useEffect(() => {
    form.resetFields();
    const {
      image,
      shortImage,
      smallImage
    } = initWhCategory;
    if (get(image,'desktop')) setDesktopImg(get(image,'desktop'));
    if (get(image,'mobile')) setMobileImg(get(image,'mobile'));
    if (smallImage) setSmallImg(smallImage);
    if (get(shortImage,'desktop')) setDesktopImgShort(get(shortImage,'desktop'));
    if (get(shortImage,'mobile')) setMobileImgShort(get(shortImage,'mobile'));
  }, [initWhCategory, form]);
  useResetWhCategory();

  const onFinish = useCallback(
    (values) => {
      if (id) {
        handleUpdate({
          ...values,
          id,
          image: {
            desktop: desktopImg,
            mobile: mobileImg
          },
          smallImage: smallImg,
          shortImage: {
            desktop: desktopImgShort,
            mobile: mobileImgShort  
          }
        });
      } else {
        handleCreate({
          ...values,
          image: {
            desktop: desktopImg,
            mobile: mobileImg
          },
          smallImage: smallImg,
          shortImage: {
            desktop: desktopImgShort,
            mobile: mobileImgShort  
          }
        });
      }
    },
    [handleCreate, handleUpdate, desktopImg, mobileImg, smallImg, id, mobileImgShort, desktopImgShort]
  );
  const renderInput = (InputComponent) => {
    return isLoading ? <Skeleton.Input active /> : InputComponent;
  };

  return (
    <div className="wh-category-form page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} nhóm dịch vụ`}
          routes={[
            {
              path: PATH_APP.whCategory.root,
              title: 'Danh sách nhóm dịch vụ'
            }
          ]}
        />

        <Form
          form={form}
          labelCol={{ sm: 24, md: 7, lg: 5, xl: 4 }}
          wrapperCol={{ sm: 24, md: 17, lg: 19, xl: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initWhCategory}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Row justify="start">
              <Col span={24}>
                <FormItem
                  label="Tên nhóm dịch vụ (vi)"
                  name={['name', LANGUAGE.VI]}
                  rules={[
                    { required: true, message: 'Xin vui lòng tên nhóm dịch vụ' }
                  ]}
                >
                  {renderInput(<Input />)}
                </FormItem>

                <FormItem
                  label="Tên nhóm dịch vụ (en)"
                  name={['name', LANGUAGE.EN]}
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng chọn nhóm dịch vụ'
                    }
                  ]}
                >
                  {renderInput(<Input />)}
                </FormItem>
                <FormItem
                  label="Tên nhóm dịch vụ cho App (vi)"
                  name={['nameApp', LANGUAGE.VI]}
                  rules={[
                    {
                      required: true,
                      message:
                        'Xin vui lòng nhập tên nhóm dịch vụ cho giao diện App'
                    }
                  ]}
                >
                  {renderInput(<Input maxLength={40} />)}
                </FormItem>

                <FormItem
                  label="Tên nhóm dịch vụ cho App (en)"
                  name={['nameApp', LANGUAGE.EN]}
                  rules={[
                    {
                      required: true,
                      message:
                        'Xin vui lòng nhập tên nhóm dịch vụ cho giao diện App'
                    }
                  ]}
                >
                  {renderInput(<Input maxLength={40} />)}
                </FormItem>
              </Col>
            </Row>
            <Row justify="start">
              <Col span={24}>
                <FormItem
                  label="Loại nhóm dịch vụ"
                  name={['typeSpecial']}
                  rules={[
                    { required: true, message: 'Xin vui chọn loại nhóm dịch vụ' }
                  ]}
                >
                  {renderInput(<Select options={optionType}/>)}
                </FormItem>
              </Col>
            </Row>
            <Row justify="start">
              <Col span={24}>
                <FormItem
                  label="Mô tả nhóm dịch vụ (vi)"
                  name={['description', LANGUAGE.VI]}
                >
                  {renderInput(<Input.TextArea rows={3} />)}
                </FormItem>

                <FormItem
                  label="Mô tả nhóm dịch vụ (en)"
                  name={['description', LANGUAGE.EN]}
                >
                  {renderInput(<Input.TextArea rows={3} />)}
                </FormItem>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={6} className="wh-category-form__image">
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: '100%' }}
                >
                  <UploadImage onChange={setDesktopImg} imgUrl={desktopImg} />
                  <p style={{ textAlign: 'center' }}>Web banner</p>
                </Space>
              </Col>
              <Col span={6} className="wh-category-form__image">
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: '100%' }}
                >
                  <UploadImage onChange={setMobileImg} imgUrl={mobileImg} />
                  <p style={{ textAlign: 'center' }}>Mobile banner</p>
                </Space>
              </Col>
              <Col span={6} className="wh-category-form__image">
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: '100%' }}
                >
                  <UploadImage onChange={setSmallImg} imgUrl={smallImg} />
                  <p style={{ textAlign: 'center' }}>Image category</p>
                </Space>
              </Col>
            </Row>
            <Row
              justify="start"
              className='wh-category-short'
            >
              <Col span={24}>
                <FormItem
                  label="Mô tả ngắn nhóm dịch vụ (vi)"
                  name={['shortDescription', LANGUAGE.VI]}
                >
                  {renderInput(<Input />)}
                </FormItem>

                <FormItem
                  label="Mô tả ngắn nhóm dịch vụ (en)"
                  name={['shortDescription', LANGUAGE.EN]}
                >
                  {renderInput(<Input />)}
                </FormItem>
              </Col>
            </Row>
            <Row
              justify="center"
              className='wh-category-short__image'
            >
              <Col span={12} className="wh-category-form__image">
              <Space
                className="upload-image"
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <UploadImage
                  onChange={setDesktopImgShort}
                  imgUrl={desktopImgShort}
                />
                {desktopImgShort && (
                    <Popconfirm
                    className='icon-comfirm'
                    title={'Bạn muốn xoá hình ảnh?'}
                    onConfirm={(e) => {
                      e.stopPropagation();
                      return setDesktopImgShort(null);
                    }}
                    onCancel={(e) => {
                      e.stopPropagation();
                    }}
                    okText="Xác nhận"
                    cancelText="Huỷ"
                  >
                    <MinusCircleOutlined
                      className="upload-image-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        return;
                      }}
                    />
                  </Popconfirm>
                )}
                <p style={{ textAlign: 'center' }}>Web banner</p>
                </Space>
                </Col>
                <Col span={12} className="wh-category-form__image">
              
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <UploadImage
                  onChange={setMobileImgShort}
                  imgUrl={mobileImgShort}
                />
                {mobileImgShort && (
                  <Popconfirm
                    title={'Bạn muốn thay đổi hình ảnh?'}
                    onConfirm={(e) => {
                      e.stopPropagation();
                      return setMobileImgShort(null);
                    }}
                    onCancel={(e) => {
                      e.stopPropagation();
                    }}
                    okText="Xác nhận"
                    cancelText="Huỷ"
                  >
                    <MinusCircleOutlined
                      className="upload-image-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        return;
                      }}
                    />
                  </Popconfirm>
                )}
                <p style={{ textAlign: 'center' }}>Mobile banner</p>
                </Space>
                </Col>
            </Row>
            {/*  */}
            <Row className="form__submit-box">
              {isSubmitLoading ? (
                <Button disabled>Huỷ</Button>
              ) : (
                <Link to={PATH_APP.whCategory.root}>
                  <Button>Huỷ</Button>
                </Link>
              )}

              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitLoading}
                // onClick={handleSubmitShort}
              >
                {id ? 'Cập nhật' : 'Tạo mới'}
              </Button>
            </Row>
          </Space>
          {/* // setImg={setImg}
          // img={img}
          // setDescriptions={setDescriptions}
          // descriptions={descriptions}
          // handleGetSortDescription={handleGetSortDescription} */}
        </Form>

      </div>
    </div>
  );
};

export default WhCategoryForm;
