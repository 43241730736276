import { AndroidOutlined, AppleOutlined, EditFilled } from '@ant-design/icons';
import { Button, Input, Modal, Switch, Table, Tag, Tooltip } from 'antd';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { KEY_SYSTEM, KEY_SYSTEM_VI } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import {
  useSystemPaging, useSystemQueryParams, useSystems,
  useUpdateSystem, useUpdateSystemParams
} from '~/hooks/system';
import { WithPermission } from '../Common';
import SystemForm from './SystemForm';
const { Search } = Input;
const System = ({ }) => {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_SYSTEM);
    const [query, onPagingChange] = useSystemQueryParams();
    const [systems, isLoading] = useSystems(query);
    const [keyword, { setKeyword, onParamChange }] =
        useUpdateSystemParams(query);
    const [isSubmitLoading, updateSystem] = useUpdateSystem(onCloseForm);
    const [systemId, setSystemId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const paging = useSystemPaging();

    const onOpenForm = useCallback((id) => {
        setSystemId(id);
        setIsOpen(true);
    }, [])
    function onCloseForm() {
        setSystemId(null);
        setIsOpen(false);
    };

    const onChangeForceUpgrade = useCallback((record, checked) => {
        const submitData = { id: get(record, '_id'), value: { ...get(record, 'value'), isForceUpgrade: checked } }
        updateSystem(submitData)
    }, [])

    const columns = [
        {
            title: "Hệ điều hành",
            dataIndex: "key",
            key: "key",
            align: 'center',
            render: (key) => {
                if(key === KEY_SYSTEM.VER_ANDROID){
                    return <Tag icon={<AndroidOutlined />} color='success'>{KEY_SYSTEM_VI[key]}</Tag>
                }
                if(key === KEY_SYSTEM.VER_IOS){
                    return <Tag icon={<AppleOutlined />} color='processing'>{KEY_SYSTEM_VI[key]}</Tag>
                }
                return <></>
            }
        },
        {
            title: "Tên phiên bản",
            dataIndex: "name",
            align: 'center',
            key: "name",
        },
        {
            title: "Mô tả",
            dataIndex: "description",
            align: 'center',
            key: "description",
        },
        {
            title: "Phiên bản",
            dataIndex: "value",
            align: 'center',
            key: "version",
            render: (value) => get(value, 'version', '')
        },
        {
            title: "Buộc nâng cấp",
            dataIndex: "value",
            align: 'center',
            key: "isForceUpgrade",
            render: (value, record) => <Tooltip title={!canUpdate ? "Bạn không có quyền" : ""}><Switch disabled={!canUpdate} loading={isSubmitLoading} onChange={(checked) => onChangeForceUpgrade(record, checked)} checked={get(value, 'isForceUpgrade')} /></Tooltip>
        },
        {
            title: "Thao tác",
            key: "action",
            align: 'center',
            render: (item, record) => <WithPermission permission={POLICIES.UPDATE_SYSTEM}>
                <Button onClick={() => onOpenForm(get(record, '_id'))} type='link'><EditFilled /></Button>
            </WithPermission>
        }
    ];


    return (
        <div className="page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb title="Quản lý phiên bản (App)" />
                <div className="page-content__main" style={{ display: 'block' }}>
                    <div className="page-wraper__header">
                        <Search
                            style={{ width: 520 }}
                            placeholder="Tìm bất kì..."
                            enterButton
                            allowClear
                            onSearch={() => onParamChange({ keyword: keyword?.trim() })}
                            onChange={(e) => {
                                setKeyword(e.target.value)
                                if (e.target.value === "") {
                                    onParamChange({ keyword: null })
                                }
                            }}
                            value={keyword}
                        />

                    </div>
                    {isLoading ? (
                        <SkeletonTable
                            rowCount={systems.length}
                            columns={columns}
                            pagination={paging}
                        />
                    ) : (
                        <Table
                            rowKey={(rc) => rc._id || rc.id}
                            columns={columns}
                            dataSource={systems}
                            onChange={onPagingChange}
                            size="small"
                            pagination={{
                                ...paging,
                                showTotal: (total) => `Tổng cộng: ${total} `
                            }}
                        />
                    )}
                </div>
            </div>
            <Modal
                visible={isOpen}
                onCancel={() => setIsOpen(false)}
                onOk={() => setIsOpen(false)}
                footer={null}
                destroyOnClose
            >
                <SystemForm
                    id={systemId}
                    onCancel={onCloseForm}
                    updateSystem={updateSystem}
                    isSubmitLoading={isSubmitLoading}
                />
            </Modal>
        </div>
    );
};

export default System;
