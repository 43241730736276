import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getIntroUsingRefCodesFailed: null,
  getIntroUsingRefCodeFailed:null,
  introUsingRefCodes: [],
  introUsingRefCode: null,
  isSubmitLoading : false,
  isGetIntroUsingRefCodeLoading: false,
  IntroUsingRefCode: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_INTRO_USING_REF_CODES_REQUEST:
      state.isLoading = true;
      state.getIntroUsingRefCodesFailed = null;
      return;

    case Types.GET_INTRO_USING_REF_CODES_SUCCESS:
      state.isLoading = false;
      state.introUsingRefCodes = payload.docs;
      state.getIntroUsingRefCodesFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_INTRO_USING_REF_CODES_FAILED:
      state.isLoading = false;
      state.introUsingRefCodes = [];
      state.getIntroUsingRefCodesFailed = payload;
      return;
    case Types.GET_INTRO_USING_REF_CODE_REQUEST:
      state.isGetIntroUsingRefCodeLoading = true;
      state.introUsingRefCode = null
      state.getIntroUsingRefCodeFailed = null;
      return;

    case Types.GET_INTRO_USING_REF_CODE_SUCCESS:
      state.isGetIntroUsingRefCodeLoading = false;
      state.introUsingRefCode = payload;
      state.getIntroUsingRefCodeFailed = null;
      return;

    case Types.GET_INTRO_USING_REF_CODE_FAILED:
      state.isGetIntroUsingRefCodeLoading = false;
      state.introUsingRefCode = null;
      state.getIntroUsingRefCodeFailed = payload;
      return;
    case Types.CREATE_INTRO_USING_REF_CODE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_INTRO_USING_REF_CODE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_INTRO_USING_REF_CODE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_INTRO_USING_REF_CODE_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_INTRO_USING_REF_CODE_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.introUsingRefCode = get(payload,'data',{});
        state.introUsingRefCodes = state.introUsingRefCodes?.map(e => get(e,'_id') === get(payload,'data._id',{}) ? get(payload,'data',{}) : e)
        return;
  
      case Types.UPDATE_INTRO_USING_REF_CODE_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_INTRO_USING_REF_CODE_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_INTRO_USING_REF_CODE_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_INTRO_USING_REF_CODE_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;
      case Types.RESET_INTRO_USING_REF_CODE_STATE_ACTION:
        state.getIntroUsingRefCodesFailed = initState.getIntroUsingRefCodesFailed;
        state.getIntroUsingRefCodeFailed = initState.getIntroUsingRefCodeFailed;
        state.introUsingRefCode = initState.introUsingRefCode;
        state.IntroUsingRefCode = initState.IntroUsingRefCode;
        state.createSuccess = initState.createSuccess;
        state.createFailed = initState.createFailed;
        state.deleteSuccess = initState.deleteSuccess;
        state.deleteFailed = initState.deleteFailed;
        state.updateSuccess = initState.updateSuccess;
        state.updateFailed = initState.updateFailed;
        return;

    case Types.RESET_STORE:
    case Types.RESET_INTRO_USING_REF_CODE_STATE:
      return initState;

    default:
      return;
  }
}, initState);
