import requester from './requester';

const ListSoftware = {
  create: (payload) => requester.post('/software', payload),
  update: (query) => requester.put(`/software/${query?._id}`,query),
  delete: (id) => requester.delete(`/software/${id}`),
  getAll: (query) => requester.get('/software', query),
  getOne: (id) => requester.get(`/software/${id}`),
};
export default ListSoftware;

