export const SETTING_NAVBAR = 'setting_navbar';
export const MODE_NAVBAR = {
    modeNavbarDrawer : 'modeNavbarDrawer',
    modeRowHome : 'modeRowHome',
}
export const DEFAULT_MODE_NAVBAR = {
    modeNavbarDrawer : 'inline',
    modeRowHome : 'wrap'
}

export const getModeNavbar = () => {
    try {
    const settingNavbar = JSON.parse(localStorage.getItem(SETTING_NAVBAR));
    if(!settingNavbar){
      localStorage.setItem(SETTING_NAVBAR,(DEFAULT_MODE_NAVBAR))
      return {data:DEFAULT_MODE_NAVBAR,isNew:true}
    }else{
      return {data : settingNavbar,isNew:false}
    }
    } catch (error) {
        localStorage.setItem(SETTING_NAVBAR,JSON.stringify(DEFAULT_MODE_NAVBAR));
        return {data:DEFAULT_MODE_NAVBAR,isNew:true}
    }
}
export const setModeNavbar = (key,newMode) => {
    try {
        const settingNavbar = JSON.parse(localStorage.getItem(SETTING_NAVBAR));
        localStorage.setItem(SETTING_NAVBAR,JSON.stringify({...settingNavbar,[key] : newMode}));
    } catch (error) {
        localStorage.setItem(SETTING_NAVBAR,JSON.stringify(DEFAULT_MODE_NAVBAR))
    }
  
}
