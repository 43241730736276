import { get } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useBranchIdSessionStorage,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createMedicine,
  deleteMedicine,
  getMedicine,
  getMedicines,
  resetMedicineState,
  updateMedicine
} from '~/redux/action';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.whMedicine[key];
const pagingSelector = getSelector('paging');
export const useMedicinePaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetMedicineSelector = getSelector('isGetMedicineLoading');
const getMedicinesFailedSelector = getSelector('getMedicinesFailed');
const getMedicineFailedSelector = getSelector('getMedicineFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const medicinesSelector = getSelector('medicines');
const medicineSelector = getSelector('medicine');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

export const useMedicineQueryParams = () => {
  const [branchId] = useBranchIdSessionStorage();
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  // const startDate = query.get('startDate');
  // const endDate = query.get('endDate');
  // const keyword = query.get('keyword')
  const name = query.get('name');
  const price = query.get('price');
  const sortPrice = query.get('sortPrice');
  const sortDiscount = query.get('sortDiscount');
  const createMedicineSuccess = useSelector(createSuccessSelector);
  const updateMedicineSuccess = useSelector(updateSuccessSelector);
  const deleteMedicineSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      name,
      price,
      sortPrice,
      sortDiscount,
      branchId,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    createMedicineSuccess,
    updateMedicineSuccess,
    deleteMedicineSuccess,
    name,
    price,
    sortPrice,
    sortDiscount,
    branchId,
  ]);
};

export const useUpdateMedicineParams = (query) => {
  const history = useHistory();
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/wh-medicines',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [{ onParamChange }];
};
export const useMedicines = (query) => {
  return useFetchByParam({
    action: getMedicines,
    loadingSelector,
    dataSelector: medicinesSelector,
    failedSelector: getMedicinesFailedSelector,
    param: query
  });
};
export const useMedicine = (id) => {
  return useFetchByParam({
    action: getMedicine,
    loadingSelector: loadinggetMedicineSelector,
    dataSelector: medicineSelector,
    failedSelector: getMedicineFailedSelector,
    param: id
  });
};
export const useCreateMedicine = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo Thiết bị y tế thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createMedicine
  });
};
export const useUpdateMedicine = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật Thiết bị y tế thành công',
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateMedicine
  });
};
export const useDeleteMedicine = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Thiết bị y tế thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật Thiết bị y tế thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteMedicine
  });
};

export const useResetMedicine = () => {
  useResetState(resetMedicineState);
};
