import {
    useFailed,
    useSubmit,
    useSuccess,
    useResetState,
    useFetchByParam,
    getSelectors,
    useQueryParams,
    checkInvalidDate,
    useBranchIdSessionStorage,
    useFetch
  } from '~/hooks/utils';
  import { useSelector } from 'react-redux';
import { createStatusConfig, deleteStatusConfig, getListStatusConfig, getStatusConfigById, updateStatusConfig } from '~/redux/action';
import { useMemo, useRef } from 'react';
import { getExistProp } from '~/utils/helper';
const STATUS_CONFIG ='statusConfig'
const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
} = getSelectors(STATUS_CONFIG);

const getSelector = (key) => (state) => state[STATUS_CONFIG][key];
  export const useStatusConfigQueryParams = (module) => {

    const prevKeyword = useRef(null);
    const query = useQueryParams();
    const page = query.get('page') || 1;
    const limit = query.get('limit') || 10;

    
      const createSuccess = useSelector(createSuccessSelector);
      const updateSuccess = useSelector(updateSuccessSelector);
      const deleteSuccess = useSelector(deleteSuccessSelector);

      return useMemo(() => {
          const queryParams = getExistProp({
            page,
            limit,
          }); 
          return [queryParams]
      }, [page,limit, createSuccess, updateSuccess, deleteSuccess]);

}
  export const useGetListStatusConfig = (query) => {
      return useFetchByParam({
          action: getListStatusConfig,
          loadingSelector,
          dataSelector: listSelector,
          failedSelector: getListFailedSelector,
          param: query
      })
  }
  export const useGetStatusConfigById = (params) => {
      return useFetchByParam({
          action: getStatusConfigById,
          loadingSelector: getByIdLoadingSelector,
          dataSelector: getByIdSelector,
          failedSelector: getByIdFailedSelector,
          param: params
      })
  }
  export const useCreateStatusConfig = () => {

    useSuccess(createSuccessSelector, );
    useFailed(createFailedSelector, );
  
      return useSubmit({
        loadingSelector: isSubmitLoadingSelector,
        action: createStatusConfig
      })
  }
  export const useUpdateStatusConfig = () => {
    useSuccess(updateSuccessSelector, );
    useFailed(updateFailedSelector, );
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateStatusConfig,
    });
  }
  export const useDeleteStatusConfig = () => {
    useSuccess(deleteSuccessSelector, );
    useFailed(deleteFailedSelector,);
  
    return useSubmit({ 
      loadingSelector,
      action: deleteStatusConfig,
    });
  };