import React, { useEffect, useMemo, useState } from 'react';
import { Col, DatePicker, Input, Row, Space, Spin, Table } from 'antd';
import api from '~/api';
import { notifyHttpError } from '~/utils/helper';
import ServiceOfHistory from './ServiceOfHistory';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useWhCustomerManagementHistoryQueryParams, useWhCustomersManagementHistory, useWhCustomersManagementQueryParams } from '~/hooks';
const { Search } = Input;

function callback(key) {
  console.log(key);
}

const History = ({ status, statuses, whBill, _id, data }) => {
  const { id } = useParams()
  // const featchOrderBill = async () => {
  //   const res = await api.order.getAllOrders(id);
  // };
  // useEffect(() => {
  //   featchOrderBill()
  // }, []);

  const [queryParams, onTableChange] = useWhCustomerManagementHistoryQueryParams(id);
  // const [histories, isLoadingHistory] = useWhCustomersManagementHistory(queryParams);
  const [itemActive, setItemActive] = useState(null);
  const [whBillItems, setWhBillItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const defaultDate = useMemo(() => ([
    moment().startOf('month').format('YYYY-MM-DD'),
    moment().endOf('month').format('YYYY-MM-DD')
  ]), [])
  const [date, setDate] = useState(defaultDate?.join(','))

  const [valueSearch, setValueSearch] = useState(null);

  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const pageSize = 10;

  const baseQuery = {
    ...(statuses && { statuses }),
    ...(status && { status }),
    customerId: data._id,
    populate: true,
    filterDate: date,
    // limit: pageSize,
    // page: 1,
  };

  const getRecordsLocally = async (query) => {
    setIsLoading(true);
    try {
      const { docs, totalDocs,page } = await api.whBill.getAll({
        ...query
      });
      setWhBillItems(docs);
      setTotal(totalDocs);
      setCurrent(page)
    } catch (error) {
      notifyHttpError(error);
      setWhBillItems([]);
    } finally {
      setIsLoading(false);
    }
  };
  const onPagingChange = ({ current, pageSize }) => {
    setCurrent(current);
    getRecordsLocally({
      ...baseQuery,
      page: current,
      limit: pageSize,
      filterDate: date,
    });
  };

  const onSearch = () => {
    // let newDate = date
    // for (const key in newDate) {
    //   if(moment.isMoment(newDate[key])){
    //     newDate[key] = newDate[key].format('YYYY-MM-DD')
    //   }
    //   if(isArray(newDate[key])){
    //     newDate[key].forEach((element) => {
    //       if(moment.isMoment(element)){
    //         valueClone[key] = [...(valueClone?.[key]??[]),element?.format('YYYY-MM-DD')]
    //       }
    //     });
    //   }
    // }
  const regex = /[0-9.]/g
    let newValueSearch = valueSearch?.trim()
    if (regex.test(valueSearch)) {
      newValueSearch= newValueSearch?.replace(/[. ]/g,'')
    }
   valueSearch !== 'null' && getRecordsLocally({
      ...baseQuery,
      page: 1,
      limit: pageSize,
      keyword: newValueSearch,
      filterDate: date,
    });
  }//

  useEffect(() => {
    getRecordsLocally({ ...baseQuery });
  }, [whBill]);
  
  useEffect(() => {
    onSearch()
  }, [date]);//

  const columns = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'billNumber',
      key: 'billNumber',
      width: '410px',
      render: (record) => {
        return record;
      }
    },

    {
      title: 'Số tiền',
      dataIndex: 'grandTotal',
      key: 'grandTotal',
      width: '410px',
      render: (record) => {
        return record?.toLocaleString();
      }
    },

    {
      title: 'Nhóm dịch vụ',
      dataIndex: 'point',
      key: 'point',
      width: '310px'
      // render: (record) => record.phoneNumber
    }
  ];
  return (
    <>
     <div className="container-fluid" style={{ margin: '0 auto' }}>
      {/* <div className="page-content__main"> */}
        <Row gutter={36} >
          <Col span={8}>
          <Search
            allowClear
            style={{ width: '300px' }}
            placeholder="Tìm bất kỳ"
            enterButton
            onSearch={ onSearch}
              onChange={(e) => {
                if (!e.target.value) {
                  getRecordsLocally({
                    ...baseQuery,
                    page: 1,
                    limit: pageSize,
                    filterDate: date,
                    keyword: null
                  });
                }
                setValueSearch(e.target.value)
              }}
            value={valueSearch}
          />
          </Col>
          <Col span={16}>
            <Row gutter={16} align='middle'>
              <Col>
              <span style={{textAlign:'center'}}>Thời gian thực hiện: </span>
              </Col>
              {/* <Col >
               <span> Từ: </span> 
                <DatePicker
                    allowClear ={false}
                    defaultValue={moment(date.startDate)}
                    onChange={(e)=> setDate({...date,startDate: moment(e).format("YYYY-MM-DD")})}
                />
              </Col> */}
              <Col>
              {/* <span> Đến: </span>  */}
                {/* <DatePicker
                    allowClear ={false}
                    defaultValue={moment(date.endDate)}
                    onChange={(e) => {
                      setDate({ ...date, endDate: moment(e).format("YYYY-MM-DD") })
                  }}
                /> */}
                <DatePicker.RangePicker
                  defaultValue={[moment(defaultDate[0]), moment(defaultDate[1])]}
                  allowClear={false} allowEmpty format={"YYYY-MM-DD"}
                  onChange={(e,el) => setDate(el.join(','))} />
              </Col>
            </Row>
          </Col>
        </Row>
          {isLoading ? (
            <SkeletonTable
              columns={columns}
              rowCount={5}
              // pagination={paging}
            />
          ) : (
            <Table
              // style={{overflow:'auto'}}
              // style={{ maxWidth: '100%' }}
              rowKey={(rc) => rc._id}
              columns={columns}
              dataSource={whBillItems}
              size="middle"
              onChange={onPagingChange}
              pagination={{
                total,
                pageSize,
                current,
                showTotal: (total) => `Tổng cộng: ${total} `
              }}
              onExpand={(expanded, record) => {
                expanded ? setItemActive(record._id) : setItemActive(null);
              }}
              expandable={{
                expandedRowRender: (record) => (
                  <ServiceOfHistory whBill={record}></ServiceOfHistory>
                ),
                expandedRowKeys: [itemActive]
              }}
            />
          )}
        {/* </div> */}
        </div>
        </>
  );
};

export default History;
