import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/reducer';
import hospitalReducer from './hospital/reducer';
import appointment from './appointment/reducer';
import degree from './degree/reducer';
import speciality from './speciality/reducer';
import geo from './geo/reducer';
import staff from './staff/reducer';
import service from './service/reducer';
import newsCategory from './newsCategory/reducer';
import news from './news/reducer';
import staffGroup from './staffGroup/reducer';
import layout from './layout/reducer';
import branch from './branch/reducer';
import employee from './employee/reducer';
import employeeGroup from './employeeGroup/reducer';
import partner from './partner/reducer';
import order from './order/reducer';
import userEmployee from './userEmployee/reducer';
import partnership from './partnership/reducer';
import coupon from './coupon/reducer';
import policy from './policy/reducer';
import dashboard from './dashboard/reducer';
import shippingVendor from './shippingVendor/reducer';
import whBill from './whBill/reducer';
import whCategory from './whCategory/reducer';
import whPackageLevel from './whPackageLevel/reducer';
import whService from './whService/reducer';
import whSessionOfDay from './whSessionOfDay/reducer';
import whPartner from './whPartner/reducer';
import whReceiptVoucher from './whReceiptVoucher/reducer';
import whPaymentVoucher from './whPaymentVoucher/reducer';
import whBillItem from './whBillItem/reducer';
import whAppointment from './whAppointment/reducer';
import customerType from './customerType/reducer';
import rateConfig from './rateConfig/reducer';
import whCustomerManagement from './whCustomerManagement/reducer';
import whCustomerImport from './whCustomerImport/reducer';
import importWhCustomerExcel from './importWhCustomerExcel/reducer';
import evaluate from './evaluate/reducer';
import systemConfiguration from './systemConfiguration/reducer';
import notificationBotManagement from './notificationBotManagement/reducer';
import quotation from './quotation/reducer';
import banner from './banner/reducer';
import whCategoryShort from './whCategoryShort/reducer';
import quotations from './quotations/reducer';
import whPartnerImport from './whPartnerImport/reducer';
import trainingCourse from './trainingCourse/reducer';
import trainingCoursePartner from './trainingCoursePartner/reducer';
import timeSheet from './timeSheet/reducer';
import configCampaign from './configCampaign/reducer';
import rewardItem from './rewardItem/reducer';
import pointSystem from './pointSystem/reducer';
import whWorkNorm from './whWorkNorm/reducer';
import tipsPartner from './tipsPartner/reducer';
import reportSystem from './reportSystem/reducer';
import whReportCustomer from './whReportCustomer/reducer';
import whMedicine from './whMedicine/reducer';
import whReportMedicines from './whReportMedicines/reducer';
import highlight from './whHighlight/reducer';
import transactions from './transaction/reducer';
import appointmentAssociate from './appointmentAssociate/reducer';
import giveCoupon from  './giveCoupon/reducer'; //
import ranking from  './ranking/reducer';
import keywordSearch from  './keywordSearch/reducer';//ss
import hospitalList from './hospitalList/reducer';
import servicePlace from './servicePlace/reducer';
import medicalEquipment from './medicalEquipment/reducer';
import productCatalogue from './productCatalogue/reducer';
import productList from './productList/reducer';
import warehouseVoucher from './warehouseVoucher/reducer';
import bonusIntroCustomer from './bonusIntroCustomer/reducer';
import partnerCancelAppointment from './partnerCancelAppointment/reducer';
import whAllVouchers from './whAllVouchers/reducer';
import exchangePoint from  './exchangePoint/reducer';
import activityReferral from  './activityReferral/reducer';//
import requestVoucher from  './requestVoucher/reducer';//
import wallet from  './wallet/reducer';//
import clinicDashboard from './clinicDashboard/reducer';
import pharmacyOnlineDashboard from './pharmacyOnlineDashboard/reducer';
import percentChangeCustomerToPartner from  './percentChangeCustomerToPartner/reducer';//
import whContact from  './whContact/reducer';
import confirmVoucherWarehouse from  './confirmVoucherWarehouse/reducer';//
import unConfirmWhBill from  './unConfirmWhBill/reducer';//
import history from  './history/reducer';//

import listWareHouse from './listWareHouse/reducer'
import myNotification from  './myNotification/reducer';//

import deliveryVoucher from './deliveryVoucher/reducer'
import introUsingRefCode from  './introUsingRefCode/reducer';//
import system from  './system/reducer';//

import workList from './workList/reducer';
import workListTask from './workTask/reducer';
import workSprint from './workSprint/reducer';
import statusConfig from './statusConfig/reducer';
import groupCategory from './groupCategory/reducer';
import ui from './UI/reducer';
import job from './job/reducer';
import applyJob from './applyJob/reducer';
import positionJob from './positionJob/reducer';
import SettingTutorialJob from './SettingTutorialJob/reducer';
import configWhPartnerRegisterTypeForm from './configWhPartnerRegisterTypeForm/reducer';
import whPartnerRegister from './whPartnerRegister/reducer';
import companyJob from './companyJob/reducer';
import infoCompany from './infoCompany/reducer';
import coreValue from './coreValue/reducer';
import listSoftware from './listSoftware/reducer';
import listTrial from './listTrial/reducer';
import uiClient from './uiClient/reducer';
import jobQuestion from './jobQuestion/reducer';
import jobForm from './jobForm/reducer';
import adv from './adv/reducer';
import serviceByLocation from './serviceByLocation/reducer';

const userPersistConfig = {
  key: 'user',
  storage: storage,
  blacklist: [
    'loginFailed',
    'isLoading',
    'isGetProfileLoading',
    'isGetPolicyLoading',
    'getPolicySuccess',
    'policy',
    'getPolicyFailed',
    'getProfileFailed'
  ]
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  hospital: hospitalReducer,
  appointment,
  degree,
  speciality,
  geo,
  staff,
  service,
  newsCategory,
  news,
  staffGroup,
  layout,
  branch,
  employee,
  employeeGroup,
  partner,
  order,
  userEmployee,
  partnership,
  coupon,
  policy,
  dashboard,
  shippingVendor,
  whBill,
  whCategory,
  whPackageLevel,
  whService,
  whSessionOfDay,
  whPartner,
  whReceiptVoucher,
  whPaymentVoucher,
  whBillItem,
  whAppointment,
  rateConfig,
  customerType,
  whCustomerManagement,
  whCustomerImport,
  importWhCustomerExcel,
  evaluate,
  systemConfiguration,
  notificationBotManagement,
  quotation,
  banner,
  whCategoryShort,
  quotations,
  whPartnerImport,
  trainingCourse,
  trainingCoursePartner,
  timeSheet,
  configCampaign,
  rewardItem,
  pointSystem,
  whWorkNorm,
  timeSheet,
  tipsPartner,
  reportSystem,
  whReportCustomer,
  whMedicine,
  whReportMedicines,
  highlight,
  transactions,
  appointmentAssociate,
  giveCoupon,//
  ranking,
  keywordSearch,//ss
  hospitalList,
  servicePlace,
  bonusIntroCustomer,
  partnerCancelAppointment,
  whAllVouchers,
  exchangePoint,
  giveCoupon,//
  appointmentAssociate,
  hospitalList,
  servicePlace,
  activityReferral,//s
  requestVoucher,//s
  wallet,//s
  whAllVouchers,
  // partnerCancelAppointment,
  clinicDashboard,
  pharmacyOnlineDashboard,
  keywordSearch,//ss
  percentChangeCustomerToPartner,//s
  medicalEquipment,
  productCatalogue,
  productList,
  warehouseVoucher,
  bonusIntroCustomer,
  whContact,
  history,//
  partnerCancelAppointment,
  exchangePoint,
  confirmVoucherWarehouse,//s
  unConfirmWhBill,//s
  listWareHouse,//
  workList,
  workListTask,
  myNotification,//s
  deliveryVoucher,//

  introUsingRefCode,//s
  system,//s
  workSprint,
  statusConfig,
  groupCategory,
  ui,
  job,
  applyJob,
  positionJob,
  SettingTutorialJob,
  configWhPartnerRegisterTypeForm,
  whPartnerRegister,
  companyJob,
  infoCompany,
  coreValue,
  listSoftware,
  listTrial,
  uiClient,
  jobQuestion,
  jobForm,
  adv,
  serviceByLocation,
});

export default rootReducer;
