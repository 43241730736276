import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getJobForms({ payload: query }) {
    try {
      const response = yield call(Api.jobForm.getAll, query);
      yield put({ type: Types.GET_JOB_FORMS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_JOB_FORMS_FAILED, payload: error });
    }
  }
function* getJobForm({ payload: id }) {
    try {
      const response = yield call(Api.jobForm.getOne, id);
      yield put({ type: Types.GET_JOB_FORM_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_JOB_FORM_FAILED, payload: error });
    }
  }
  function* createJobForm(action) {
    try {
      const data = yield call(Api.jobForm.create, action.payload);
      yield put({ type: Types.CREATE_JOB_FORM_SUCCESS, payload: data });
      yield put({ type: Types.RESET_JOB_FORM_STATE_ACTION });
    } catch (error) {
      yield put({ type: Types.CREATE_JOB_FORM_FAILED, payload: error });
    }
  }
  function* updateJobForm(action) {
    try {
      const data = yield call(Api.jobForm.update, action.payload);
      yield put({ type: Types.UPDATE_JOB_FORM_SUCCESS, payload: data });
      yield put({ type: Types.RESET_JOB_FORM_STATE_ACTION });
    } catch (error) {
      yield put({ type: Types.UPDATE_JOB_FORM_FAILED, payload: error });
    }
  }
  function* deleteJobForm(action) {
    try {
      const data = yield call(Api.jobForm.delete, action.payload);
      yield put({ type: Types.DELETE_JOB_FORM_SUCCESS, payload: data });
      yield put({ type: Types.RESET_JOB_FORM_STATE_ACTION });
    } catch (error) {
      yield put({ type: Types.DELETE_JOB_FORM_FAILED, payload: error });
    }
  }
export default function* JobForms() {
    yield takeLatest(Types.GET_JOB_FORMS_REQUEST, getJobForms);
    yield takeLatest(Types.GET_JOB_FORM_REQUEST, getJobForm);
    yield takeLatest(Types.CREATE_JOB_FORM_REQUEST, createJobForm);
    yield takeLatest(Types.UPDATE_JOB_FORM_REQUEST, updateJobForm);
    yield takeLatest(Types.DELETE_JOB_FORM_REQUEST, deleteJobForm);
  }
