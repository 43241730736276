import { Input, Table } from 'antd';
import { get } from 'lodash';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { useResetWhPartnerRegister, useUpdateWhPartnerRegisterParams, useWhPartnerRegisterPaging, useWhPartnerRegisterQueryParams, useWhPartnerRegisters } from '~/hooks/whPartnerRegister';
const { Search } = Input;

const RegisterWhPartnerList = ({ }) => {

    const [query] = useWhPartnerRegisterQueryParams();
    const [WhPartnerRegisters, isLoading] = useWhPartnerRegisters(query);
    const [keyword, setKeyword, { onParamChange }] = useUpdateWhPartnerRegisterParams(query);

    const paging = useWhPartnerRegisterPaging();
    useResetWhPartnerRegister();
    const columns = [
        {
            title: 'Tên',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Lời nhắn',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Mã giới thiệu',
            dataIndex: 'referCode',
            key: 'referCode',
        },
        {
            title: 'Loại đối tác',
            dataIndex: 'typeForm',
            key: 'typeForm',
            render(typeForm) {
              return get(typeForm,'name','')
            },
        },
    ];
    const onSearch = (kw) => onParamChange({ keyword: kw?.trim() })
    return (
        <div className="page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb title="Danh sách đối tác đăng ký" />

                <div className="page-wraper__header">
                    <Search
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Tìm kiếm..."
                        enterButton
                        onSearch={onSearch}
                    // onSearch={onSearch}
                    />
                </div>

                <Table
                    loading={isLoading}
                    className="wh-category-table-striped-rows"
                    rowKey={(rc) => rc._id || rc.id}
                    columns={columns}
                    dataSource={WhPartnerRegisters}
                    onChange={({ current }) => onParamChange({ page: current })}
                    pagination={{
                        ...paging,
                        showTotal: (total) => `Tổng cộng: ${total} `
                    }}
                    size="small"
                />
            </div>
        </div>
    );
};

export default RegisterWhPartnerList;
