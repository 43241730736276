import { Col, Row, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react'
import { TYPE_POINT_SYSTEM_REFERRAL, WITH_DRAW_MONEY_STATUS_VI } from '~/constants/defaultValue';
import { usePointSystemPaging, usePointSystemPartnerQueryParams, usePointSystemsPartner, useUpdatePointSystemPartnerParams } from '~/hooks/pointSystem';
import { formatNumberThreeComma } from '~/hooks/utils';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Search from 'antd/lib/input/Search';
export default function TabsPointSystem({ userId, reFetch, mode = 'query' ,width , allowSearch = false}) {
  const [query] = usePointSystemPartnerQueryParams(userId, reFetch);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [keywordState,setKeywordState] = useState(null)
  const queryState = useMemo(() => ({ page, limit, id: userId,keyword : keywordState }), [page, limit, userId,keywordState])
  const [pointSystems, isLoading] = usePointSystemsPartner(mode === 'query' ? query : queryState);
  const paging = usePointSystemPaging(query);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdatePointSystemPartnerParams(query);
  const columns = [
    {
      title: 'Số điểm',
      dataIndex: 'amount',
      key: 'amount',
      render: (value, record) => {
        const isCredit = get(record, 'type', '') === TYPE_POINT_SYSTEM_REFERRAL.credit
        return <Row>
          <Col span={24}>
            <Text type={isCredit ? 'success' : 'danger'} strong>{`${isCredit ? "+" : "-"} ${formatNumberThreeComma(value || 0)}`}</Text>
          </Col>
          <Col span={24}>
            <Text type='secondary'>{moment(get(record, 'createdAt')).format('DD-MM-YYYY HH:mm:ss')}</Text>
          </Col>
        </Row>
      }
    },
    {
      title: 'Tên chiến dịch',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'Loại điểm thưởng',
      dataIndex: 'withdrawMoneyStatus',
      key: 'withdrawMoneyStatus',
      render: (value) => WITH_DRAW_MONEY_STATUS_VI[value]
    },
    // {
    //     title:'Ngày nhận',
    //     dataIndex:'createdAt',
    //     key:'createdAt',
    //     render:(date,record) => moment(date).format("DD-MM-YYYY HH:mm:ss")
    // },
  ]
  return (
    <>
      {allowSearch && <Search
          allowClear
          style={{ width: "300px" }}
          placeholder="Tìm bất kì..."
          enterButton
          onSearch={(value) => setKeywordState(value ? value?.trim() : '')}
        />}
      {isLoading ? (
        <SkeletonTable columns={columns} rowCount={10} />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          style={{width : width ?  width : '100%'}}
          dataSource={get(pointSystems, 'docs', [])}
          size="middle"
          onChange={({ current }) => {
            if (mode === 'query') {
              onParamChange({ page: current })
            }
            if (mode === 'state') {
              setPage(current)
            }
          }}
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
        />
      )}
    </>
  )
}
