import * as Types from '../../constants/actionTypes';

export const getWhPackageLevels = () => ({
  type: Types.GET_WH_PACKAGE_LEVELS_REQUEST
});

export const getWhPackageLevel = id => ({
  type: Types.GET_WH_PACKAGE_LEVEL_REQUEST,
  payload: id
});

export const createWhPackageLevel = staffGroup => ({
  type: Types.CREATE_WH_PACKAGE_LEVEL_REQUEST,
  payload: staffGroup
});

export const updateWhPackageLevel = staffGroup => ({
  type: Types.UPDATE_WH_PACKAGE_LEVEL_REQUEST,
  payload: staffGroup
});

export const deleteWhPackageLevel = id => ({
  type: Types.DELETE_WH_PACKAGE_LEVEL_REQUEST,
  payload: id
});

// export const resetWhPackageLevelState = () => ({
//   type: Types.RESET_WH_PACKAGE_LEVEL_STATE
// });
