import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  useAppointmentEvents,
  useAppointmentQueryParams,
  useUpdateAppointment
} from '~/hooks/appointment';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import viLocale from '@fullcalendar/core/locales/vi';
import { Modal, Row, Button, Select, Form, Col } from 'antd';
import { useSpecialityOptions } from '~/hooks/speciality';
import AppointmentIsSytemForm from './IsSystemAdminForm';
import './index.scss';
import { PlusOutlined } from '@ant-design/icons';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import {
  APPOINTMENT_STATUS,
  CARD_STYLE,
  MAX_PAGINATION_LIMIT
} from '~/constants/defaultValue';
import POLICY from '~/constants/policy';
import { useBranch, useHospitals, useProfile, useSelectBranch } from '~/hooks';
import api from '~/api';
import { AppointmentIsPartnerForm } from './IsPartnerForm';
import { get } from 'lodash';
import TabBranch from '../Common/TabBranch';
import { WithOrPermission } from '../Common';
import { filterAcrossAccents } from '~/hooks/utils';
const { Option } = Select;

function renderEventContent(eventInfo) {
  const { hospital } = eventInfo.event.extendedProps;
  return (
    <div className="apponintment-item">
      <div className="apponintment-item__title-box">
        <b>{eventInfo.timeText}</b>
        <p className="apponintment-item__title">{eventInfo.event.title}</p>
      </div>

      <p className="apponintment-item__hospital-name">
        {hospital?.hospitalName}
      </p>
    </div>
  );
}

const Appointments = ({ history }) => {
  const [brandId] = useSelectBranch();

  const [keyword, setKeyword] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [dateDefault, setDateDefault] = useState({
    startTime: null,
    endTime: null
  });
  const query = useAppointmentQueryParams(dateDefault);
  const [appointmentEvents, appointments, isLoading, onChangeDate] =
    useAppointmentEvents(query);

  const id = brandId;
  const [branchInfo, loading] = useBranch(id);
  const [profile] = useProfile();
  const IdUser = profile.branchId;

  const specialityOptions = useSpecialityOptions();
  const [isOpen, setIsOpen] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const onClose = () => {
    setIsOpen(false);
    setAppointmentId(null);
  };
  const [isSubmitLoading, handleUpdate] = useUpdateAppointment(onClose);
  const [initStartTime, setInitStartTime] = useState(moment());
  const [allHostpital, setAllHostpital] = useState([]);
  const [hospitalId, setHospitalId] = useState([]);
  const [status, setStatus] = useState(null);
  const [source, setSource] = useState(null);
  const [specialityId, setSpecialityId] = useState(null);
  const [phoneNumberSearch, setPhoneNumberSearch] = useState(null);
  const [isReload, setIsReload] = useState(false);

  const queryHospital = useMemo(
    () => ({ page: 1, limit: MAX_PAGINATION_LIMIT }),
    []
  );
  const [hospitals, isHospitalLoading] = useHospitals(queryHospital);

  const arrStatus = ['CREATED', 'CONFIRMED', 'CANCEL', 'ARRIVED'];

  const onAppointmentClick = (clickInfo) => {
    setIsOpen(true);
    setAppointmentId(clickInfo.event.id);
  };

  const onCreate = () => {
    setIsOpen(true);
    setAppointmentId(null);
  };

  const getClinics = async () => {
    const res = await api.partner.getAll({ modules: 'CLINIC' });
    setAllHostpital(res?.docs);
  };
  useEffect(() => {
    getClinics();
  }, []);
  useEffect(() => {
    const onSearch = () => {
      let searchParams = `?page=${query.page}&limit=${query.limit}`;
      if (hospitalId && hospitalId.length > 0)
        searchParams += `&hospitalId=${hospitalId}`;
      if (status) searchParams += `&status=${status}`;
      if (source) searchParams += `&source=${source}`;
      if (specialityId) searchParams += `&specialityId=${specialityId}`;
      history.push({
        pathname: '/appointment',
        search: searchParams
      });
    };
    onSearch();
  }, [
    hospitalId,
    status,
    source,
    specialityId,
    history,
    query.limit,
    query.page
  ]);

  const onAppointmentChange = ({ event }) => {
    const updatedAppointment = appointments.find(({ _id }) => _id === event.id);
    handleUpdate({
      ...updatedAppointment,
      date: event.startStr,
      id: event.id
    });
  };

  const handleDateSelect = (selectInfo) => {
    // Lấy thông tin về phạm vi lựa chọn
    const start = selectInfo.start;
    const end = selectInfo.end;
    const startTime = moment(start)?.format('YYYY-MM-DD');
    const endTime = moment(end)?.format('YYYY-MM-DD');
    onChangeDate(startTime, endTime);
  };

  return (
    <div className="page-wraper page-content appointment">
      <TabBranch>
        <div className="container-fluid">
          <Breadcrumb title="Danh sách lịch hẹn" />

          <div className="page-content__main">
            <div className="page-content__left" style={CARD_STYLE}>
              <Row style={{ justifyContent: 'flex-end', marginBottom: 10 }}>
                {/* <Button type="primary"
                // onClick={onSearch}
              >
                <SearchOutlined />
                Tìm kiếm
              </Button> */}

                <WithPermission permission={POLICY.WRITE_APPOINTMENT}>
                  <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                      setInitStartTime(moment());
                      onCreate();
                    }}
                  >
                    <PlusOutlined />
                    Thêm mới
                  </Button>
                </WithPermission>
              </Row>
              <Form layout="vertical" className="appointment-filter-box">
                <Row>
                  <WithPermission permission={POLICY.READ_PARTNERCLINIC}>
                    <Col span={24}>
                      <Form.Item
                        label="Phòng khám"
                        className="appointment-filter-box__hospital"
                      >
                        <Select
                          mode="multiple"
                          value={hospitalId}
                          allowClear
                          onChange={(value) => setHospitalId(value)}
                          showSearch
                          autoComplete="off"
                          filterOption={filterAcrossAccents}
                        >
                          {hospitals.map(({ _id, name }) => (
                            <Option key={_id} value={_id}>
                              {name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </WithPermission>

                  <Col span={24}>
                    <Form.Item
                      label="Dịch vụ"
                      className="appointment-filter-box__status"
                    >
                      {
                        <Select
                          allowClear
                          showSearch
                          value={specialityId}
                          onChange={(value) => setSpecialityId(value)}
                        >
                          {specialityOptions}
                        </Select>
                      }
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Form.Item
                    label="Trạng thái"
                    className="appointment-filter-box__status"
                  >
                    <Select
                      value={status}
                      onChange={(value) => setStatus(value)}
                      allowClear
                    >
                      <Option value="CREATED" key="CREATED">
                        Đã tạo
                      </Option>
                      <Option value="CONFIRMED" key="CONFIRMED">
                        Đã xác nhận
                      </Option>
                      <Option value="CANCEL" key="CANCEL">
                        Đã huỷ
                      </Option>
                      <Option value="ARRIVED" key="ARRIVED">
                        Đã hoàn thành
                      </Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Nguồn"
                    className="appointment-filter-box__status"
                  >
                    <Select
                      value={keyword}
                      onChange={(value) => {
                        setKeyword(value), setSource(value);
                      }}
                      allowClear
                    >
                      <Option value="CALL" key="CALL">
                        Gọi điện
                      </Option>
                      <Option value="WEBSITE" key="WEBSITE">
                        Website
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Chú thích"
                    className="appointment-filter-box__status"
                  >
                    {arrStatus.map((stt) => {
                      return (
                        <Row className="appointment-note__row">
                          <Col span={12}>
                            <div
                              className={`appointment-note__${stt.toLowerCase()}`}
                            ></div>
                          </Col>
                          <Col className="appointment-note__wrapper" span={12}>
                            <p className="appointment-note__title">
                              {APPOINTMENT_STATUS[stt]?.name}
                            </p>
                          </Col>
                        </Row>
                      );
                    })}
                  </Form.Item>
                </Row>
              </Form>
            </div>
            {
              <div className="page-content__right" style={CARD_STYLE}>
                <FullCalendar
                  headerToolbar={{
                    left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                    center: 'title',
                    right: 'today prev,next'
                  }}
                  plugins={[
                    listPlugin,
                    dayGridPlugin,
                    timelinePlugin,
                    timeGridPlugin,
                    interactionPlugin
                  ]}
                  locales={[viLocale]}
                  locale="vi"
                  initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  events={appointmentEvents}
                  eventContent={renderEventContent}
                  eventClick={(e) => {
                    onAppointmentClick(e);
                  }}
                  eventChange={onAppointmentChange}
                  eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: 'short'
                  }}
                  eventDisplay={'block'}
                  datesSet={(e) => {
                    handleDateSelect(e);
                  }}
                />
              </div>
            }
          </div>
        </div>

        <Modal
          visible={isOpen}
          onCancel={() => {
            setIsOpen(false);
            setIsReload(!isReload);
          }}
          onOk={() => {
            setIsOpen(false);
            setIsReload(!isReload);
          }}
          footer={null}
        >
          <h1>{appointmentId ? ' Cập nhật' : 'Tạo mới'} lịch hẹn</h1>
          {get(profile, 'isSuperAdmin') ? (
            <AppointmentIsSytemForm
              allHostpital={allHostpital}
              specialityOptions={specialityOptions}
              appointmentId={appointmentId}
              onClose={() => {
                setAppointmentId(null);
                setIsOpen(false);
                setIsReload(!isReload);
              }}
              initStartTime={initStartTime}
              clinics={allHostpital}
              branchId={brandId}
              isOpen={isOpen}
              phoneNumberSearch={phoneNumberSearch}
              setPhoneNumberSearch={setPhoneNumberSearch}
              handleUpdate={handleUpdate}
              isSubmitLoading={isSubmitLoading}
              isReload = {isReload}
            />
          ) : (
            <AppointmentIsPartnerForm
              allHostpital={allHostpital}
              specialityOptions={specialityOptions}
              appointmentId={appointmentId}
              onClose={() => {
                setIsOpen(false);
                setAppointmentId(null);
                setIsReload(!isReload);
              }}
              initStartTime={initStartTime}
              clinics={allHostpital}
              branchId={brandId}
              phoneNumberSearch={phoneNumberSearch}
              setPhoneNumberSearch={setPhoneNumberSearch}
              handleUpdate={handleUpdate}
              isSubmitLoading={isSubmitLoading}
              isReload = {isReload}
            />
          )}
        </Modal>
      </TabBranch>
    </div>
  );
};

export default Appointments;
