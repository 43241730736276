import { EnterOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useRef } from 'react';

export default function FormSettingDocs({ initialValues, onOk }) {
  const [form] = Form.useForm();
  const ref = useRef();
  const onFinish = (values) => {
    onOk(values);
  };
  const handleCLickEnter = () => {
    form.setFieldsValue({
      address: form.getFieldValue(['address']) + '\n'
    });
    ref.current.focus();
  };
  return (
    <Form initialValues={initialValues} onFinish={onFinish} form={form}>
      <Row style={{ flexDirection: 'column' }}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Vui lòng điền tên công ty'
            }
          ]}
          labelCol={{ span: 6 }}
          label="Tên công ty"
          name="name"
        >
          <TextArea />
        </Form.Item>
        <Row>
          <Col xs={22}>
            <Form.Item
              labelCol={{ span: 6 }}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập địa chỉ công ty'
                }
              ]}
              label="Địa chỉ công ty"
              name="address"
            >
              <TextArea ref={ref} autoSize autoCapitalize />
            </Form.Item>
          </Col>
          <Col xs={2}>
            <Button onClick={handleCLickEnter}>
              <EnterOutlined />
            </Button>
          </Col>
        </Row>
        <Button style={{ justifySelf: 'end' }} htmlType="submit" type="primary">
          Xác nhận
        </Button>
      </Row>
    </Form>
  );
}
