import React from 'react';

import Icon, { ClockCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Card, Col, Row, Space, Tag } from 'antd';
import { get } from 'lodash';

import { formatter } from '~/utils/helper';

import LocationSvg from '../WhPartner/LocationSvg';
import MoneySvg from '../WhPartner/MoneySvg';
import PhoneSvg from '../WhPartner/PhoneSvg';

import './wh-bill-card-compact.scss';

const WhBillCardCompact = ({
  isActive,
  whBill,
  onClick,
}) => {
  const mapBillStatusTag = {
    NEW: { name: "Mới", color: "geekblue"},
    PREPAYMENT_CONFIRMED: { name: "Xác nhận cọc", color: "orange"},
    IN_PROGRESS: { name: "Đang thực hiện", color: "processing"},
    COMPLETED: { name: "Đã hoàn thành", color: "success"},
    CANCELLED: { name: "Đã hủy", color: "error"},
  }

  const address = get(whBill, "customerAddress");

  return (
    <Card
      className={`wh-bill-card-compact card ${isActive ? 'active' : ''}`}
      onClick={onClick}
      bordered={isActive}
      hoverable
    >
      <Row>
        <Col span={24}>
          <Space direction='vertical' style={{ width: "100%" }}>
            <Row align='middle' justify='space-between'>
              <p className=''><strong>{
                `${get(whBill, "customer.customerIdFull")} - ${get(whBill, "customerAddress.fullName")}`
              }</strong></p>
              <Tag
                color={get(mapBillStatusTag, `${get(whBill, "status")}.color`)}
              >
                {get(mapBillStatusTag, `${get(whBill, "status")}.name`)}
              </Tag>
            </Row>

            <Row align='middle'>
              <Space>
                {/* <Icon component={FileTextOutlined} color="#3481FF" style={{ transform: 'scale(1)' }} /> */}
                <FileTextOutlined style={{ color: "#3481FF", width: "23px" }} />
                <p className=''>
                  {`${get(whBill, "billNumber")}`}
                </p>
              </Space>
            </Row>

            <Row align='middle'>
              <Space>
                <Icon component={LocationSvg} color="#3481FF" style={{ transform: 'scale(0.65)' }} />
                <p className=''>
                  {`${address.street}, ${address.ward}, ${address.district}, ${address.city}`}
                </p>
              </Space>
            </Row>

            <Row align='middle'>
              <Space>
                <Icon component={PhoneSvg} style={{ transform: 'scale(0.65)' }} />
                <p className=''>{get(whBill, "customerAddress.phoneNumber")}</p>
              </Space>
            </Row>

            <Row align='middle'>
              <Space>
                <Icon component={MoneySvg} style={{ transform: 'scale(0.9)', margin: '2px 0 0 2px' }} />
                <p className=''>{formatter(get(whBill, "grandTotal"))}</p>
              </Space>
            </Row>

            <Row align='middle' justify='end'>
              <Space>
                <ClockCircleOutlined />
                <p className=''>{moment(get(whBill, "createdAt")).format('HH:mm DD/MM/YYYY')}</p>
              </Space>
            </Row>
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

export default WhBillCardCompact;
