import PropTypes from 'prop-types';
import React from 'react';
import { Col, Card, CardBody, CardTitle } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { useMostMonthly } from '~/hooks';

const MostMonthly = () => {
  const [mostMonthly] = useMostMonthly();
  let countArray = [];
  let nameArray = [];
  let countArr = mostMonthly?.map((key, value) => {
    let x = countArray.push(key?.hospital?.totalAppointment);
    let y = nameArray.push(key?.hospital?.hospitalName);
  });

  const series = [
    {
      name: 'Số lần đặt',
      type: 'column',
      data: mostMonthly?.map(({ hospital }) => hospital?.totalAppointment)
    },
    {
      name: 'Tên phòng khám',
      type: 'column',
      data: mostMonthly?.map(({ hospital }) => hospital?.hospitalName)
    }
  ];

  const options = {
    // fill: {
    //   colors: ['#f1b44c']
    // },
    chart: {
      type: 'column',
      height: 350
    },
    plotOptions: {
      bar: {
        columnWidth: '40%'
      }
    },
    stroke: {
      width: [0, 4]
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: mostMonthly.map(({ monthYear }) => monthYear),
      labels: {
        trim: true,
        rotateAlways: true,
        rotate: -45
      }
    },
    yaxis: [
      {
        title: {
          text: 'Số lần đặt'
        }
      }
    ]
  };
  return (
    <React.Fragment>
      <Col md={6} xl={3} style={{ width: '50%' }}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4 h4">
              Phòng khám đặt lịch hẹn nhiều nhất theo tháng
            </CardTitle>
            <div className="mt-3">
              <ReactApexChart
                options={options}
                series={series}
                height="339"
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MostMonthly;

MostMonthly.propTypes = {
  reports: PropTypes.array
};
