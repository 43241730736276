import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getConfigCampaignsFailed: null,
  getConfigCampaignFailed:null,
  configCampaigns: [],
  configCampaign: null,
  isSubmitLoading : false,
  isGetConfigCampaignLoading: false,
  ConfigCampaign: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_CONFIG_CAMPAIGNS_REQUEST:
      state.isLoading = true;
      state.getConfigCampaignsFailed = null;
      return;

    case Types.GET_CONFIG_CAMPAIGNS_SUCCESS:
      state.isLoading = false;
      state.configCampaigns = payload.docs;
      state.getConfigCampaignsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_CONFIG_CAMPAIGNS_FAILED:
      state.isLoading = false;
      state.configCampaigns = [];
      state.getConfigCampaignsFailed = payload;
      return;
    case Types.GET_CONFIG_CAMPAIGN_REQUEST:
      state.isGetConfigCampaignLoading = true;
      state.configCampaign = null
      state.getConfigCampaignFailed = null;
      return;

    case Types.GET_CONFIG_CAMPAIGN_SUCCESS:
      state.isGetConfigCampaignLoading = false;
      state.configCampaign = payload;
      state.getConfigCampaignFailed = null;
      return;

    case Types.GET_CONFIG_CAMPAIGN_FAILED:
      state.isGetConfigCampaignLoading = false;
      state.configCampaign = null;
      state.getConfigCampaignFailed = payload;
      return;
    case Types.CREATE_CONFIG_CAMPAIGN_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_CONFIG_CAMPAIGN_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_CONFIG_CAMPAIGN_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_CONFIG_CAMPAIGN_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_CONFIG_CAMPAIGN_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.configCampaign = get(payload,'data',{});
        state.configCampaigns = state.configCampaigns?.map(e => get(e,'_id') === get(payload,'data._id',{}) ? get(payload,'data',{}) : e)
        return;
  
      case Types.UPDATE_CONFIG_CAMPAIGN_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_CONFIG_CAMPAIGN_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_CONFIG_CAMPAIGN_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_CONFIG_CAMPAIGN_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;
      case Types.RESET_CONFIG_CAMPAIGN_STATE_ACTION:
        state.createSuccess = null;
        state.updateFailed = null;
        state.configCampaign = null;
        return;

    case Types.RESET_STORE:
    case Types.RESET_CONFIG_CAMPAIGN_STATE:
      return initState;

    default:
      return;
  }
}, initState);
