import React, { useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Empty, Select, Spin } from "antd";


function DebounceSelectV2({
  fetchOptions,
  debounceTimeout = 800,
  initOptions,
  value,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  useEffect(() => {
    if (initOptions) {
      setOptions(initOptions);
    }
  }, [initOptions]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
    
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        // For fetch Have Options
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);


  return (
    <Select
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
      {...props}
      options={options}
      {...value && {value}}
      showSearch
      style={{ minWidth: 300 }}
      onFocus={() => {
        if(!initOptions){
        debounceFetcher();
        }
      }}
    />
  );
}
export default DebounceSelectV2;
// --------EXAMPLE---------
// const [value, setValue] = useState([]);

// const fetchOptions = async (keyword: string) => {
//     const res = await apis.getAll({ keyword });
//     const options = get(res,'docs',[])?.map((item:any) => ({label : get(item,'name.vi'),value : get(item,'_id')}))
//     return options;
//   };
//  <DebounceSelectV2
// showSearch
// value={value}
// placeholder="Select users"
// fetchOptions={fetchOptions}
// onChange={(newValue) => {
//   setValue(newValue);
// }}
// style={{ width: "100%" }}
// /> 
