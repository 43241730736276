import moment from 'moment'
import React from 'react'
import { Col, List, Row, Typography } from 'antd'
import { clone } from 'lodash'

export default function HistoryLogs({historyLogs}) {
  return <div className='history-logs-wrap' style={{marginTop: '4rem', overflow: 'scroll', maxHeight: '500px'}}>
  <List
      
      header={<h2>Logs</h2>}
      bordered
      
      dataSource={clone(historyLogs) || []}
      renderItem={item => (
        
        <List.Item>
          <Row className='history-log-item' wrap={false} gutter={[40]} style={{marginTop: '10px'}} key={item._id} >
      <Col>
      
        <span>
        <Typography.Text mark>{moment(item.timestamp).format("DD/MM/YYYY HH:mm")}</Typography.Text>

        </span>
        </Col>
        <Col><span>{item.username || item.userId}</span></Col>
        <Col><span>{item.message}</span></Col>
        
      
    </Row>
        </List.Item>
      )}
      >

      </List>
  
      </div>
  
}
