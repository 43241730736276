import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getSelectors,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createPercentChangeCustomerToPartner,
  getPercentChangeCustomerToPartner,
  getPercentChangeCustomerToPartners,
  resetPercentChangeCustomerToPartnerState,
  updatePercentChangeCustomerToPartner
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.percentChangeCustomerToPartner[key];
const pagingSelector = getSelector('paging');
export const usePercentChangeCustomerToPartnerPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetPercentChangeCustomerToPartnerSelector = getSelector('isGetPercentChangeCustomerToPartnerLoading');
const getPercentChangeCustomerToPartnersFailedSelector = getSelector('getPercentChangeCustomerToPartnersFailed');
const getPercentChangeCustomerToPartnerFailedSelector = getSelector('getPercentChangeCustomerToPartnerFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const PercentChangeCustomerToPartnersSelector = getSelector('PercentChangeCustomerToPartners');
const PercentChangeCustomerToPartnerSelector = getSelector('PercentChangeCustomerToPartner');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
export const usePercentChangeCustomerToPartnerQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;

  const keyword = query.get('keyword');
  const createPercentChangeCustomerToPartnerSuccess = useSelector(createSuccessSelector);
  const updatePercentChangeCustomerToPartnerSuccess = useSelector(updateSuccessSelector);
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createPercentChangeCustomerToPartnerSuccess,
    updatePercentChangeCustomerToPartnerSuccess
  ]);
};

export const useUpdatePercentChangeCustomerToPartnerParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/exchange-point',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};
export const usePercentChangeCustomerToPartners = (query) => {
  return useFetchByParam({
    action: getPercentChangeCustomerToPartners,
    loadingSelector,
    dataSelector: PercentChangeCustomerToPartnersSelector,
    failedSelector: getPercentChangeCustomerToPartnersFailedSelector,
    param: query
  });
};
export const usePercentChangeCustomerToPartner = (id) => {
  return useFetchByParam({
    action: getPercentChangeCustomerToPartner,
    loadingSelector: loadinggetPercentChangeCustomerToPartnerSelector,
    dataSelector: PercentChangeCustomerToPartnerSelector,
    failedSelector: getPercentChangeCustomerToPartnerFailedSelector,
    param: id
  });
};
export const useCreatePercentChangeCustomerToPartner = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo Phần trăm quy đổi thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createPercentChangeCustomerToPartner
  });
};
export const useUpdatePercentChangeCustomerToPartner = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật Phần trăm quy đổi thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updatePercentChangeCustomerToPartner
  });
};

export const useResetPercentChangeCustomerToPartner = () => {
  useResetState(resetPercentChangeCustomerToPartnerState);
};
