import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { STATUS_APPLY_JOB_OPTION } from '~/constants/detaultValueJob';
import { useCities } from '~/hooks';
import { filterAcrossAccents, getExistProp } from '~/utils/helper';
import FlexCenter from '../Common/FlexCenter';
import SelectCompanyJob from '../CompanyJob/SelectCompanyJob';
import SelectPositionJob from '../PositionJob/SelectPositionJob';

export default function SearchDraw({ open, onCancel, onParamChange,query }) {
    const [form] = Form.useForm();
    const [cities, isCitiesLoading] = useCities();

    const onFinish = (values) => {
        const payloadSubmit = {
            ...values,
            keyword: values?.keyword?.trim() ?? null,
            startDate: values?.startDate && moment(values?.startDate).format("YYYY-MM-DD"),
            endDate: values?.endDate && moment(values?.endDate).format("YYYY-MM-DD"),
            managementArea: values?.managementArea?.join(',')
        }
        onParamChange(payloadSubmit);
        onCancel();
    };
    useEffect(() => {
        const queryClear = getExistProp(query);
        form.setFieldsValue({
            ...queryClear,
            startDate : queryClear?.startDate ? moment(queryClear.startDate) : null,
            endDate : queryClear?.endDate ? moment(queryClear.endDate) : null,
            status : queryClear?.status?.split(','),
            category : queryClear?.category?.split(','),
            companyJobId : queryClear?.companyJobId?.split(','),
            managementArea : queryClear?.managementArea?.split(','),
        });
    },[query]);
    return (
        <Modal
            title="Tìm kiếm"
            onCancel={onCancel}
            visible={open}
            width='auto'
            footer={[
                <Button onClick={() => form.resetFields()}>
                    <FlexCenter.Row>
                        <ClearOutlined /> Làm mới
                    </FlexCenter.Row>
                </Button>
                ,

                <Button type='primary' onClick={() => form.submit()}>
                <FlexCenter.Row>
                    <SearchOutlined /> Tìm kiếm
                </FlexCenter.Row>
                </Button>
                ]}
        >
            <Form onFinish={onFinish} form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 10 }}>
                <Row style={{ width: '100%' }}>
                    <Col span={12}>
                        <Form.Item label="Từ khoá" name={'keyword'}>
                            <Input
                                allowClear
                                placeholder="Nhập bất kì..."
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <Form.Item name={'status'} label="Trạng thái">
                            <Select
                                listHeight={'auto'}
                                dropdownMatchSelectWidth={150}
                                mode='multiple'
                                allowClear
                            >
                                {STATUS_APPLY_JOB_OPTION.map(({ label, value, color }) =>
                                    <Select.Option value={value}><Badge status={color} />{label}</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'startDate'} label="Từ">
                            <DatePicker allowClear style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <Form.Item name={'endDate'} label="Đến">
                            <DatePicker allowClear style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item shouldUpdate={(pre, next) => pre?.category !== next?.category} noStyle>
                            {({ getFieldValue }) => <SelectPositionJob mode='multiple' required={false} canCreate={false} value={getFieldValue('category')?.join(',')} />}
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <Form.Item name={'managementArea'} label="Khu vực">
                        <Select
                                            mode='multiple'
                                            loading={isCitiesLoading}
                                            showSearch
                                            autoComplete="disabled"
                                            filterOption={filterAcrossAccents}
                                        >
                                            {cities.map(({ code, name }) => (
                                                <Select.Option key={code} value={code}>
                                                    {name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <SelectCompanyJob mode='multiple' required={false} canCreate={false} />
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
