import * as Types from '~/constants/actionTypes';

export const getProductList = (query) => ({
  type: Types.GET_PRODUCT_LISTS_REQUEST,
  payload: query
});

export const getProductListOfPartner = (query) => ({
  type: Types.GET_PRODUCT_OF_WHPARTNER_REQUEST,
  payload: query
});

export const getProductListById = (id) => ({
  type: Types.GET_PRODUCT_LIST_REQUEST,
  payload: id
});

export const getProductListHistory = (id) => ({
  type: Types.GET_PRODUCT_LIST_HISTORY_REQUEST,
  payload: id
});

export const createProductList = (pro) => ({
  type: Types.CREATE_PRODUCT_LIST_REQUEST,
  payload: pro
});

export const updateProductList = (pro) => ({
  type: Types.UPDATE_PRODUCT_LIST_REQUEST,
  payload: pro
});

export const stopDeliveryProduct = (pro) => ({
  type: Types.STOP_DELIVERY_PRODUCT_REQUEST,
  payload: pro
});

export const deleteProductList = (id) => ({
  type: Types.DELETE_PRODUCT_LIST_REQUEST,
  payload: id
});

export const resetProductListState = () => ({
  type: Types.RESET_PRODUCT_LIST_STATE
});

export const resetProductListStateAction = () => ({
  type: Types.RESET_PRODUCT_LIST_STATE_ACTION
});

export const getProductListPartnerBorrowed = (query) => ({
  type: Types.GET_PRODUCT_LISTS_PARTNER_BORROWED_REQUEST,
  payload: query
});
