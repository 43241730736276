
export const GET_WH_HIGHLIGHT_REQUEST = 'GET_WH_HIGHLIGHT_REQUEST';
export const GET_WH_HIGHLIGHT_SUCCESS = 'GET_WH_HIGHLIGHT_SUCCESS';
export const GET_WH_HIGHLIGHT_FAILED = 'GET_WH_HIGHLIGHT_FAILED';

export const CREATE_WH_HIGHLIGHT_REQUEST = 'CREATE_WH_HIGHLIGHT_REQUEST';
export const CREATE_WH_HIGHLIGHT_SUCCESS = 'CREATE_WH_HIGHLIGHT_SUCCESS';
export const CREATE_WH_HIGHLIGHT_FAILED = 'CREATE_WH_HIGHLIGHT_FAILED';

export const UPDATE_WH_HIGHLIGHT_REQUEST = 'UPDATE_WH_HIGHLIGHT_REQUEST';
export const UPDATE_WH_HIGHLIGHT_SUCCESS = 'UPDATE_WH_HIGHLIGHT_SUCCESS';
export const UPDATE_WH_HIGHLIGHT_FAILED = 'UPDATE_WH_HIGHLIGHT_FAILED';

export const DELETE_WH_HIGHLIGHT_REQUEST = 'DELETE_WH_HIGHLIGHT_REQUEST';
export const DELETE_WH_HIGHLIGHT_SUCCESS = 'DELETE_WH_HIGHLIGHT_SUCCESS';
export const DELETE_WH_HIGHLIGHT_FAILED = 'DELETE_WH_HIGHLIGHT_FAILED';

export const SET_WH_HIGHLIGHT = 'SET_WH_HIGHLIGHT';

