import requester from './requester';

const RewardItem = {
  getById: (id) => requester.get(`/reward-item/${id}`),
  create: (config) => requester.post(`/reward-item`, config),
  update:(config) => requester.put(`/reward-item/${config._id}`,config),
  getAll : (query) => requester.get(`/reward-item`,query),
  delete: (id) => requester.delete(`/reward-item/${id}`),
  
};

export default RewardItem;
