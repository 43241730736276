import * as Types from '../../constants/actionTypes';

export const getServicesByLocation = (query) => {
  return {
    type: Types.GET_SERVICES_BY_LOCATION_REQUEST,
    payload: query
  };
};

export const getServiceByLocation = (query) => ({
  type: Types.GET_SERVICE_BY_LOCATION_REQUEST,
  payload: query
});

export const createServiceByLocation = (id) => ({
  type: Types.CREATE_SERVICE_BY_LOCATION_REQUEST,
  payload: id
});

export const updateServiceByLocation = (id) => ({
  type: Types.UPDATE_SERVICE_BY_LOCATION_REQUEST,
  payload: id
});

export const deleteServiceByLocation = ({ locationIndex, serviceId }) => ({
  type: Types.DELETE_SERVICE_BY_LOCATION_REQUEST,
  payload: { locationIndex, serviceId },
});

export const resetServiceByLocationState = () => ({
  type: Types.RESET_SERVICE_BY_LOCATION_STATE
});
