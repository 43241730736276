import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getListSoftwares({ payload: query }) {
    try {
      const response = yield call(Api.listSoftware.getAll, query);
      yield put({ type: Types.GET_LIST_SOFTWARES_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_LIST_SOFTWARES_FAILED, payload: error });
    }
  }
function* getListSoftware({ payload: id }) {
    try {
      const response = yield call(Api.listSoftware.getOne, id);
      yield put({ type: Types.GET_LIST_SOFTWARE_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_LIST_SOFTWARE_FAILED, payload: error });
    }
  }
  function* updateListSoftware(action) {
    try {
      const data = yield call(Api.listSoftware.update, action.payload);
      yield put({ type: Types.UPDATE_LIST_SOFTWARE_SUCCESS, payload: data });
      yield put({ type: Types.RESET_LIST_SOFTWARE_ACTION});
    } catch (error) {
      yield put({ type: Types.UPDATE_LIST_SOFTWARE_FAILED, payload: error });
    }
  }
  function* createListSoftware(action) {
    try {
      const data = yield call(Api.listSoftware.create, action.payload);
      yield put({ type: Types.CREATE_LIST_SOFTWARE_SUCCESS, payload: data });
      yield put({ type: Types.RESET_LIST_SOFTWARE_ACTION});
    } catch (error) {
      yield put({ type: Types.CREATE_LIST_SOFTWARE_FAILED, payload: error });
    }
  }
  function* deleteListSoftware(action) {
    try {
      const data = yield call(Api.listSoftware.delete, action.payload);
      yield put({ type: Types.DELETE_LIST_SOFTWARE_SUCCESS, payload: data });
      yield put({ type: Types.RESET_LIST_SOFTWARE_ACTION});
    } catch (error) {
      yield put({ type: Types.DELETE_LIST_SOFTWARE_FAILED, payload: error });
    }
  }
export default function* ListSoftwares() {
    yield takeLatest(Types.GET_LIST_SOFTWARES_REQUEST, getListSoftwares);
    yield takeLatest(Types.GET_LIST_SOFTWARE_REQUEST, getListSoftware);
    yield takeLatest(Types.UPDATE_LIST_SOFTWARE_REQUEST, updateListSoftware);
    yield takeLatest(Types.CREATE_LIST_SOFTWARE_REQUEST, createListSoftware);
    yield takeLatest(Types.DELETE_LIST_SOFTWARE_REQUEST, deleteListSoftware);
  }
