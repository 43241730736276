import { get } from "lodash";
import moment from "moment";
import { FORMAT_DATE_TIME, TYPE_VOUCHER_WAREHOUSE, TYPE_WAREHOUSE } from "~/constants/defaultValue";

export const adapterSelectProduct = (product,typeVoucher) => {
  const {name,groupProductId,valueDepreciation,_id,r_date:r_dateInput,quantity} = product;
  return {
    name,
    quantity,
    status : '',
    typeMedicalDeviceInventory : TYPE_WAREHOUSE.WORLDHEALTH,
    productPortfolioId : groupProductId,
    depreciatedValue : valueDepreciation,
    productId : _id,
    note : '',
    r_date :typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG ? moment().format(FORMAT_DATE_TIME.YYYYMMDDHHmmss) : moment(r_dateInput).format(FORMAT_DATE_TIME.YYYYMMDDHHmmss),
    p_date : typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG ? null : moment().format(FORMAT_DATE_TIME.YYYYMMDDHHmmss),
    
  }
}
export const adapterSelectMaterial = (material) => {
  const {price,refId,name,batchId,quantity} = material;
  // const batchSelect = get(material,'batches',[])?.find((batch) => get(batch, '_id') === batchId);
  // const {availableTotal} = batchSelect;
  
  return {
    // Use for Create Main listProduct of voucherWarehouse
    name, 
    quantity,
    status : '',
    typeMedicalDeviceInventory : TYPE_WAREHOUSE.CLINIC,
    depreciatedValue : price,
    productId:refId, // Use RefId for ProductId of ListProduct warehouse (productId is the id of clinic)
    note : '',
    batchId,

    // // Use Check Quantity
    // availableTotal, // Total quantity available , Use To check quantity when change quantity of Material
    // productIdCommon : productId, // Use for Check quantity when change quantity of Material,
    // exchangeValue , // Use for Check quantity when change quantity of Material,
  }
}

export const haveEmptyQuantity = (dataSource) => {
  return dataSource?.some(data => get(data,'quantity',0) <= 0);
}


export const adapterSelectProductInConfirmVoucher = (productSelected) => {
  const {name,groupEquipmentId,cost,productId,medicalEquipmentType,batchId,product,quantity,newName} = productSelected;
  switch (get(medicalEquipmentType,'type')) {
    case TYPE_WAREHOUSE.WORLDHEALTH:
      return {
        name:newName || name,
        groupProductId:groupEquipmentId,
        valueDepreciation:cost,
        _id:productId,
      }
    case TYPE_WAREHOUSE.CLINIC:
    return {
      name: newName || name,
      price:cost,
      refId:productId,
      batchId,
      quantity,
    }    
  
    default:
      break;
  }
    
}
/**
 * 
 * @param {Array} listProduct 
 * @param {String} partnerId 
 * @returns 
 */
export const removeListProductOfPartner = (listProduct,partnerId) => {
  return listProduct?.filter(product => get(product,'supplierId') !== partnerId);
}
