import React, { createRef, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import Icon, { CheckCircleOutlined, HeartOutlined, SnippetsFilled, SyncOutlined, UploadOutlined, UserOutlined, } from '@ant-design/icons';
import moment from 'moment';
import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Modal,
  Row,
  Select,
  Space,
  Upload,
} from 'antd';
import { find, get, groupBy, head, keys, pick, sortBy } from 'lodash';

import api from '~/api';
import { useWhService } from '~/hooks/whService';

import { DEFAULT_UPLOAD_ACTION, WH_APPOINTMENT_GIVE_AWAY, MAX_PAGINATION_LIMIT, STATUS_PARTNER_CANCEL_BILL, TOTAL_HOURS_TO_LATE, TYPE_CANCEL_PARTNER, WH_APPOINTMENT_STATUS } from '~/constants/defaultValue';
import { formatter, notifyHttpError } from '~/utils/helper';
import { fromJSON } from '~/components/WhAppointment/parser';
import {
  useProfile,
} from '~/hooks';

import LocationSvg from '../../../WhPartner/LocationSvg';
import PhoneSvg from '../../../WhPartner/PhoneSvg';
import ServiceSvg from '../../../WhPartner/ServiceSvg';

import CheckInOut from './CheckInOut';

import WhReceiptVoucherForm from '~/components/WhVoucher/Form/WhReceiptVoucherForm';
import { HealthDeclaration } from '~/components/Common';
import { toJSON } from '~/components/WhVoucher/parser';

import "./index.scss";
import FormReasonCancel from './FormReasonCancel';
import TableListCancel from '../TableListCancel';

import { useHistory } from 'react-router-dom';
import Distance from './Distance';
import TooltipTitle from '~/components/Common/TooltipTitle';
import CheckOutProduct from './CheckOutProduct';

const { Panel } = Collapse;
const { OptGroup, Option } = Select;

const getDiffAppointment = (item, today) => {
  const diff = moment.duration(
    moment(get(item, "date"))
      .set({
        hour: moment(get(item, "time"), "HH:mm").hour(),
        minute: moment(get(item, "time"), "HH:mm").minute(),
      })
      .diff(today)
    ).asMinutes();
    return diff;
}

const WhPartnerBillItemCardCollapse = ({
  // whPackageLevels,
  whBillItem,
  whPartnerId,
  whSessionsOfDay,
  allowCheckInOut,
  // handleOpenCancel,
  // handleOpenListCancel : openListCancel,
  showRequestCancel,
  checkIsHaveQuestAndConfirm
}) => {
  const [profile] = useProfile();
  const history = useHistory();
  const [dataCancel,setDataCancel] = useState(null)
    const [visibleCancel,setVisibleCancel] = useState(false)
    const [dataListCancel,setDataListCancel] = useState([])
  const [visibleListCancel, setVisibleListCancel] = useState(false)

    const handleOpenCancel = (data) => {
      setVisibleCancel(true)
      const typeCancel = get(data,'typeCancel')
      let infoItemCancel =''
      switch (typeCancel) {
        case TYPE_CANCEL_PARTNER.appointment.value:
        case TYPE_CANCEL_PARTNER.bill.value:
          infoItemCancel = `${get(TYPE_CANCEL_PARTNER[typeCancel],'vi','')} ${get(data,'data.code')}`
          break;
          case TYPE_CANCEL_PARTNER.block.value:
            infoItemCancel = `${get(TYPE_CANCEL_PARTNER[typeCancel],'vi','')} ${get(data,'data.selectedBlock')}`
          break;
        default:
          break;
      }
      setDataCancel({...data,infoItemCancel})
    }
    const handleCloseCancel = () => {
      setVisibleCancel(false)
      setDataCancel(null)
    }
    const openListCancel = (data) => {
      setVisibleListCancel(true)
      setDataListCancel(data)
    }
    const handleCloseListCancel = () => {
      setVisibleListCancel(false)
      setDataListCancel(null)
    }
    const handleCancelBill = (e,data,typeCancel) => { // data is Dynamically
      e.stopPropagation()
      handleOpenCancel({data,typeCancel,whBillItem,whPartnerId})
       
    }

  const {isHaveCanceledTypeAppointment,isHaveCanceledTypeBlock,isHaveConfirmBlock,isHaveConfirmAppointment,isHaveCanceledTypeBill,isHaveConfirmBill} = checkIsHaveQuestAndConfirm
  const PanelContent = ({}) => {
    const [whAppointments, setWhAppointments] = useState([]);
    const [isWhAppointmentsLoading, setIsWhAppointmentsLoading] = useState(false);
    const idGetService = useMemo(() => get(whBillItem, "whServiceId"),[])
    const [whService, isGetWhServiceLoading] = useWhService(idGetService);
    const [selectedWhAppointment, setSelectedWhAppointment] = useState();
    const [selectedBlock, setSelectedBlock] = useState();
    const [fileList, setFileList] = useState([]);
    const [isOpenDistanceModal, setIsOpenDistanceModal] = useState(false);
    const [isOpenCheckOutProductModal, setIsOpenCheckOutProductModal] = useState(false);
    const [whAppointmentCurrent, setwhAppointmentCurrent] = useState({});
    const [isOpenReceiptVoucherModal, setIsOpenReceiptVoucherModal] = useState(false);
    const [disabledCheckout, setDisabledCheckout] = useState(false);
    const getRecordsLocally = async (query) => {
      setIsWhAppointmentsLoading(true);
      try {
        // const response = await api.whAppointment.getWhAppointments({
        const response = await api.whAppointment.getWhAppointmentsForCurrentWhPartnerOnly({
          ...query,
        });
        let whAppointments = get(response, "docs");
        if (Array.isArray(whAppointments)) {
          whAppointments.sort((a, b) => get(a, "ordinalNumber") >= get(b, "ordinalNumber") ? 1 : -1);
        }
        setWhAppointments(whAppointments);
      } catch (error) {
        notifyHttpError(error);
        setWhAppointments([]);
      } finally {
        setIsWhAppointmentsLoading(false);
      }
    }

    useEffect(() => {
      getRecordsLocally({
        // status: "NEW", // FIXME: 
        whPartnerId,
        whBillItemId: get(whBillItem, "_id"),
        limit: MAX_PAGINATION_LIMIT,
        page: 1,
        populate: true,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [whBillItem]);

    const mergedWhAppointments = useMemo(() => {
      return whAppointments.map(item => {
        const whAppointment = fromJSON(item);
        return {
          ...whAppointment,
          // whPackageLevel: whPackageLevels?.find(wPL => item.whPackageLevelId === wPL._id),
          whSessionOfDay: whSessionsOfDay?.find(wSD => item.whSessionOfDayId === wSD._id),

          label: `${get(item, "code")}`,
          value: get(item, "_id"),
          ref: createRef(),
          disabled: get(item, "whPartnerId") !== whPartnerId,
        }
      })
    }, [whAppointments]);

    const blocks = useMemo(() => {
      return groupBy(mergedWhAppointments, 'blockNumber')
      // const grouped = groupBy(mergedWhAppointments, item => {
      //   if (item.Type) {
      //       return 'GIVE_AWAY';
      //   } else {
      //       return item.blockNumber;
      //   }
      // });
      // return grouped;
    }, [mergedWhAppointments]);

    // when blocks length change (from 0 => a positive integer because mergedWhAppointments loaded),
    // select the first block by default
    useEffect(() => {
      const today = moment();
      const upcomingAppointment = find(sortBy(mergedWhAppointments, "date"), item => getDiffAppointment(item, today) >= 0);
      if (upcomingAppointment) {
        const upcomingBlockNumber = get(upcomingAppointment, "blockNumber");
        setSelectedBlock(upcomingBlockNumber);
      } else {
        setSelectedBlock(head(Object.keys(blocks)));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keys(blocks).length])

    // when selected block is changed,
    // select the first appointment of the block by default
    useEffect(() => {
      const today = moment();
      const currentAppointmentsOfSelectedBlock = blocks[selectedBlock];
      const upcomingAppointment = find(sortBy(currentAppointmentsOfSelectedBlock, "date"), item => getDiffAppointment(item, today) >= 0);
      if (upcomingAppointment) {
        setSelectedWhAppointment(upcomingAppointment);
        setwhAppointmentCurrent(upcomingAppointment);
      } else {
        setSelectedWhAppointment(head(blocks[selectedBlock]))
        setwhAppointmentCurrent(head(blocks[selectedBlock]));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBlock])

    // when blocks is changed (because mergedWhAppointments loaded, or an appointment is checked in/out),
    // trigger onWhAppointmentChange with the current select appointment id
    // so it could update the new appointment to state `selectedWhAppointment`
    useEffect(() => {
      if (get(selectedWhAppointment, "id")) onWhAppointmentChange(get(selectedWhAppointment, "id"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocks])

    useEffect(() => {
      setFileList(get(selectedWhAppointment, "attachments") || []);
    }, [selectedWhAppointment]);

    const total = useMemo(() => {
      return blocks[selectedBlock]?.reduce((prev, curr) => {
        return prev + get(curr, "grandTotal");
      }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBlock])

    const onBlockChange = (value, option) => {
      setSelectedBlock(value);
    }

    const onFileChange = async ({ file, fileList }) => {
      if (file.status !== 'uploading') {
        if (Array.isArray(fileList)) {
          const newFileList = fileList.map(item => {
            if (!get(item, "response")) return item;
            return {
              ...item,
              url: get(item, "response.url"),
            }
          });

          try {
            const { attachments } = await api.whAppointment.updateAttachments({
              id: get(selectedWhAppointment, "id"),
              attachments: newFileList,
            })
            setSelectedWhAppointment({
              ...selectedWhAppointment,
              attachments,
            })
          } catch (error) {
            notifyHttpError(error);
          }
        }
      } else {
        setSelectedWhAppointment({
          ...selectedWhAppointment,
          attachments: [...fileList],
        });
      }
    }

    const onWhAppointmentChange = (value, option) => {
      // the option should contain only `label` and `value` so it's not a whAppointment
      // setSelectedWhAppointment(option);

      // find the whAppointment from the selected block instead
      const whAppointment = blocks[selectedBlock]?.find(item => get(item, "id") === value)
      setwhAppointmentCurrent(whAppointment);
      if (whAppointment) setSelectedWhAppointment(whAppointment)
    }
    console.log(selectedWhAppointment,'selectedWhAppointment')
    const onWhAppointmentCheckInOut = async (whAppointment, action) => {
      const newWhAppointments = whAppointments.map(item => {
        if (get(item, "_id") === get(whAppointment, "_id")) {
          return whAppointment
        }
        return item;
      })

      if (action !== "checkOut") {
        setWhAppointments(newWhAppointments);
      } else {
        const { actualCost, whBillId, whBillItemId, id: whAppointmentId } = whAppointment;
        const { customerAddress, customerAddressId, customerId, customer,  } = get(whBillItem, "whBill");

        try {
          if (get(whBillItem, 'remainingAmount') > 0  && get(selectedWhAppointment, 'Type') !== WH_APPOINTMENT_GIVE_AWAY) {
          const newWhReceiptVoucher = await api.whReceiptVoucher.createByWhPartner(
            toJSON({
              accountingDate: moment(),
              accountingDetails: [
                {
                  content: `Thu tiền buổi hẹn ${get(selectedWhAppointment, "code")}, dịch vụ ${get(whBillItem, "snapshotService.code")}, đơn hàng ${get(whBillItem, "whBill.billNumber")}`,
                  debitAccount: 1111,
                  creditAccount: 131,
                  amountOfMoney: actualCost,
                }
              ],
              customerAddress,
              customerAddressId,
              customerId,
              customerName: get(customer, "fullName"),
              customerNumber: get(customer, "customerIdFull"),
              dateOfIssue: moment(),
              // employeeId: get(profile, "_id"), // get id user not partner
              // issueNumber, // will be generated by API automatically
              reason: `Thu tiền buổi hẹn ${get(selectedWhAppointment, "code")}, dịch vụ ${get(whBillItem, "snapshotService.code")}, đơn hàng ${get(whBillItem, "whBill.billNumber")}`,
              whAppointmentId,
              whBillId,
              whBillItemId,
            })
          );
          const newWhAppointments = whAppointments.map(item => {
            if (get(item, "_id") === get(selectedWhAppointment, "_id")) {
              return {
                ...whAppointment,
                whReceiptVoucher: [newWhReceiptVoucher]
              }
            }
            return item;
          });
            setWhAppointments(newWhAppointments);
            
            setTimeout(() => {
              setIsOpenReceiptVoucherModal(true);
              }, 200);
          } else {
            setDisabledCheckout(true);
          };
        } catch (error) {
          console.error(error);
        }
      }
    }

    const onPassedWhReceiptVoucherChange = (newWhReceiptVoucher) => {
      const newWhAppointments = whAppointments.map(item => {
        if (get(item, "_id") === get(selectedWhAppointment, "_id")) {
          return {
            ...item,
            whReceiptVoucher: [newWhReceiptVoucher]
          }
        }
        return item;
      });
      setWhAppointments(newWhAppointments);
    }
    const handleOpenDistanceModal = (id) => {
      setIsOpenDistanceModal(true);
    };

    const handleOpenCheckOutProductModal = () => {
      setIsOpenCheckOutProductModal(true);
    };

    const checkExistCommercialVoucher = useMemo(() => (selectedWhAppointment?.checkExistCommercialVoucher), [selectedWhAppointment]);
    let messenger = "";
    return (
      <div className="pending-bill-item-card__panel">
        <Divider style={{ margin: "0 0 18px 0" }} />
        <Space direction='vertical' style={{ width: "100%" }} size="middle">
          {allowCheckInOut && (
            <Row gutter={16} align='middle' justify='space-around'>
              <TooltipTitle
                titleTooltip={(!checkExistCommercialVoucher && !get(selectedWhAppointment, "checkIn.time")) ? 'Vui lòng nhận thiết bị trước khi check in buổi hẹn !' : ''}
                content={
                  <Button

                    type="primary"
                    disabled={checkExistCommercialVoucher ? get(selectedWhAppointment, "checkIn.time") : true}
                    onClick={() => selectedWhAppointment?.ref?.current?.openCheckInOutModal('checkIn')}
                  >
                    Check in
                  </Button>
                }
              />

              <Button
                type="primary"
                disabled={checkExistCommercialVoucher ? (disabledCheckout ? disabledCheckout :  get(selectedWhAppointment, "checkOut.time") ): true}
                onClick={() => {
                  selectedWhAppointment?.ref?.current?.openCheckInOutModal("checkOut")
                }}
              >
                Check out
              </Button>
            </Row>
          )}

          <Row gutter={16} align='middle' >
            <Col xs={6} sm={12}>
              <h6 style={{ marginBottom: 0 }}>Block</h6>
            </Col>
            <Col xs={18} sm={12}>
              <Row justify='space-between'>
                <Col flex={1}>
                  <Select
                    onChange={onBlockChange}
                    // options={blocks[selectedBlock]}
                    style={{ width: "100%" }}
                    value={selectedBlock}
                  >
                    {Object.keys(blocks).map((blockNumber, index) => (
                      <Select.Option key={index} value={blockNumber}>{blockNumber}</Select.Option>
                    ))}
                  </Select>
                </Col>
                {showRequestCancel &&
                  <Col style={{ textAlign: 'end' }} lg={6} md={10} sm={10} xs={10}>
                    <Button className='buttonCancel' onClick={(e) => {
                      isHaveCanceledTypeBlock.some(e => parseInt(get(e, 'blockNumber')) === parseInt(selectedBlock)) ? handleOpenListCancel(e, whBillItem) : handleCancelBill(e, { blocks, selectedBlock }, TYPE_CANCEL_PARTNER.block.value)
                    }} danger={!isHaveCanceledTypeBlock.some(e => parseInt(get(e, 'blockNumber')) === parseInt(selectedBlock))}>{isHaveCanceledTypeBlock.some(e => parseInt(get(e, 'blockNumber')) === parseInt(selectedBlock)) ? <Badge dot={isHaveConfirmBlock}>Đã gửi huỷ block</Badge> : "Huỷ block"}</Button>
                  </Col>}
              </Row>
            </Col>
          </Row>

          <Row gutter={16} align='middle' justify='space-between'>
            <Col xs={6} sm={12}>
              <h6 style={{ marginBottom: 0 }}>SỐ LẦN</h6>
            </Col>
            <Col xs={18} sm={12}>
                  <Row justify='space-between'>
                    <Col flex={1}>
                    <Select
                // defaultActiveFirstOption
                onChange={onWhAppointmentChange}
                // options={selectedBlock ? blocks[selectedBlock]?.map(item => pick(item, ['label', 'value', 'disabled'])) : []}
                optionLabelProp="label"
                style={{ width: "100%" }}
                value={get(selectedWhAppointment, "_id") || null}
              >
                <OptGroup key="prev" label="Gần đây">
                  {selectedBlock
                    ? blocks[selectedBlock]?.filter(item => getDiffAppointment(item, moment()) < 0)?.map(item => (
                      <Option style={{ color: "grey" }} {...pick(item, ['label', 'value', 'disabled'])}>
                        <Row align='middle'>
                          <p>{get(item, "label")}</p>
                          {get(item, "status") === WH_APPOINTMENT_STATUS.COMPLETED && <CheckCircleOutlined style={{ color: "#52c41a", marginLeft: "8px" }} />}
                          {get(item, "status") === WH_APPOINTMENT_STATUS.IN_PROGRESS && <SyncOutlined spin style={{ color: "##1890ff", marginLeft: "8px" }} />}
                        </Row>
                      </Option>
                    ))
                    : []
                  }
                </OptGroup>
                <OptGroup key="curr" label="Hiện tại">
                  {selectedBlock
                    ? blocks[selectedBlock]?.filter(item => getDiffAppointment(item, moment()) >= 0)?.map(item => (
                      <Option {...pick(item, ['label', 'value', 'disabled'])}>
                        <Row align='middle'>
                          <p>{get(item, "label")}</p>
                          {get(item, "status") === WH_APPOINTMENT_STATUS.COMPLETED && <CheckCircleOutlined style={{ color: "#52c41a", marginLeft: "8px" }} />}
                          {get(item, "status") === WH_APPOINTMENT_STATUS.IN_PROGRESS && <SyncOutlined spin style={{ color: "#1890ff", marginLeft: "8px" }} />}
                        </Row>
                      </Option>
                    ))
                    : []
                  }
                </OptGroup>
              </Select>
                    </Col>
                    {showRequestCancel && <Col style={{textAlign : 'end'}} lg={6} md={10} sm={10} xs={10}>
                    <Button className='buttonCancel' danger={!isHaveCanceledTypeAppointment.some(e => get(e,'whAppointmentId') === get(selectedWhAppointment,'_id'))}   onClick={(e) => isHaveCanceledTypeAppointment.some(e => get(e,'whAppointmentId') === get(selectedWhAppointment,'_id'))  ? handleOpenListCancel(e,whBillItem) :handleCancelBill(e,selectedWhAppointment,TYPE_CANCEL_PARTNER.appointment.value)} >
                    {isHaveCanceledTypeAppointment.some(e => get(e,'whAppointmentId') === get(selectedWhAppointment,'_id')) ? <Badge dot={isHaveConfirmAppointment}>Đã gửi Huỷ</Badge> : "Huỷ buổi hẹn"}
                    </Button>
                    </Col>}
                  </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Descriptions bordered size="small" column={1}>
                <Descriptions.Item label="Ngày">{selectedWhAppointment && moment(get(selectedWhAppointment, "date")).format("DD/MM/YYYY")}</Descriptions.Item>
                <Descriptions.Item label="Giờ">{get(selectedWhAppointment, "time")}</Descriptions.Item>
                <Descriptions.Item label="Thêm giờ">{get(selectedWhAppointment, "extraQuantityAndExtraDurationAndExtraTimeUnit")}</Descriptions.Item>
                <Descriptions.Item label="Gói dịch vụ">{get(selectedWhAppointment, "snapshotService.package.packageLevelName.vi")}</Descriptions.Item>
                <Descriptions.Item label="Buổi">{get(selectedWhAppointment, "whSessionOfDay.name.vi")}</Descriptions.Item>
                <Descriptions.Item label="Giá ban đầu">{selectedWhAppointment && formatter(get(selectedWhAppointment, "priceAndVat"))}</Descriptions.Item>
                {/* <Descriptions.Item label="Phụ thu">{selectedWhAppointment && formatter(get(selectedWhAppointment, "bonus",0))}</Descriptions.Item> */}
                <Descriptions.Item label="Giá cộng thêm">{get(selectedWhAppointment, "extraPriceAndExtraQuantityAndVat")}</Descriptions.Item>
                <Descriptions.Item label="Tạm tính">{selectedWhAppointment && formatter(get(selectedWhAppointment, "grandTotal"))}</Descriptions.Item>
                <Descriptions.Item label='CCDC/NVL'>
                  <p
                    style={{
                      color: '#5b73e8',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      handleOpenDistanceModal()
                    }}
                  >Xem chi tiết</p>
                </Descriptions.Item>
                <Descriptions.Item label='Sử dụng Công cụ dụng cụ'>
                  <p
                    style={{
                      color: '#5b73e8',
                      cursor: 'pointer'
                    }}
                    onClick={handleOpenCheckOutProductModal}
                  >Xem chi tiết</p>
                </Descriptions.Item>
                <Descriptions.Item label={
                  <>
                    <p>Số tiền phải thu </p>
                    <p>(mỗi buổi)</p>
                  </>
                }>{selectedWhAppointment && formatter(get(selectedWhAppointment, "actualCost"))}</Descriptions.Item>
                <Descriptions.Item label={
                  <>
                    <p>Thời gian</p>
                    <p>check in /</p>
                    <p>check out</p>
                  </>
                }>
                  <CheckInOut
                    ref={selectedWhAppointment?.ref}
                    record={selectedWhAppointment}
                    onWhAppointmentCheckInOut={onWhAppointmentCheckInOut}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<p>File đính kèm</p>}
                  span={1}
                  style={{ width: "50%" }}
                >
                  <Upload
                    className='pending-bill-item-card__panel--upload'
                    action={`${DEFAULT_UPLOAD_ACTION}/appointment`}
                    // defaultFileList={get(selectedWhAppointment, "attachments") || []}
                    // defaultFileList={[
                    //   {
                    //     uid: '1',
                    //     name: 'xxx.png',
                    //     // status: 'done',
                    //     // response: 'Server Error 500',
                    //     // custom error message to show
                    //     url: 'http://www.baidu.com/xxx.png',
                    //   },
                    //   {
                    //     uid: '2',
                    //     name: 'yyy.png',
                    //     // status: 'done',
                    //     url: 'http://www.baidu.com/yyy.png',
                    //   },
                    // ]}
                    // fileList={get(selectedWhAppointment, "attachments")?.map(item => ({
                    //   ...item,
                    //   uid: get(item, "_id"),
                    // })) || []}
                    fileList={fileList}
                    onChange={onFileChange}
                  >
                    <Button icon={<UploadOutlined />}>Tải lên</Button>
                  </Upload>
                </Descriptions.Item>
                {get(selectedWhAppointment, "whReceiptVoucher.length") && (
                  <Descriptions.Item
                    label={<p>Chứng từ</p>}
                    span={1}
                    style={{ width: "50%" }}
                  >
                    <Button
                      onClick={e => {
                        e.preventDefault();
                        setIsOpenReceiptVoucherModal(true);
                      }}
                    >
                      Phiếu thu
                    </Button>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Descriptions bordered size="small">
                <Descriptions.Item label={`Tổng cộng (block ${selectedBlock})`} style={{ fontWeight: "bold" }}>{formatter(total)}</Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          <Row justify='end' align='top'>
            <Col span={3}>
              <SnippetsFilled color='#3481FF' style={{ fontSize: '24px', color: '#3481FF' }} />
            </Col>
            <Col span={21}>
              <HealthDeclaration
                whBillItem={whBillItem}
                whService={whService}
              />
            </Col>
          </Row>
        </Space>

        <Modal
          centered
          destroyOnClose
          footer={null}
          onCancel={() => setIsOpenReceiptVoucherModal(false)}
          onOk={() => setIsOpenReceiptVoucherModal(false)}
          title={`Phiếu thu`}
          visible={isOpenReceiptVoucherModal}
          width={'95vw'}
        >
          <WhReceiptVoucherForm
            isHandledByWhPartner={true}
            whReceiptVoucherId={get(selectedWhAppointment, "whReceiptVoucher.0._id")}
            onPassedWhReceiptVoucherChange={onPassedWhReceiptVoucherChange}
            passedWhReceiptVoucher={get(selectedWhAppointment, "whReceiptVoucher.0")}
            whBill={get(whBillItem, "whBill")}
            whBillItem={whBillItem}
            whAppointment={selectedWhAppointment}
            onClose={() => setIsOpenReceiptVoucherModal(false)}
            onCancel={() => setIsOpenReceiptVoucherModal(false)}
          />
        </Modal>
        <Modal
          title={'Khoảng cách đến các đơn vị cung cấp thiết bị'}
          visible={isOpenDistanceModal}
          onCancel={() => setIsOpenDistanceModal(false)}
          footer={null}
          width={'900px'}
          destroyOnClose
        // centered
        >
          <Distance
            whAppointmentCurrent={whAppointmentCurrent}
          />
        </Modal>

        <Modal
          title={'Chọn máy để sử dụng công cụ dụng cụ'}
          visible={isOpenCheckOutProductModal}
          onCancel={() => setIsOpenCheckOutProductModal(false)}
          footer={null}
          width={'800px'}
          destroyOnClose
          bodyStyle={{ padding: '10px' }}
        // centered
        >
          <CheckOutProduct
            whAppointmentCurrent={whAppointmentCurrent}
            setwhAppointmentCurrent={setwhAppointmentCurrent}
            setSelectedWhAppointment={setSelectedWhAppointment}
            selectedWhAppointment={selectedWhAppointment}
          />
        </Modal>

      </div>
    )
  }

  const Header = () => {
   
    const nameService = (whBillItem) =>{
      if (get(whBillItem, "snapshotService.detailName.vi"))
            return (`${get(whBillItem, "snapshotService.code")} - ${get(whBillItem, "snapshotService.name.vi")} - ${get(whBillItem, "snapshotService.detailName.vi")} : ${get(whBillItem, "whBill.billNumber")}`)
          else 
            return (`${get(whBillItem, "snapshotService.code")} - ${get(whBillItem, "snapshotService.name.vi")} : ${get(whBillItem, "whBill.billNumber")}`)
    }
    return (
    <Row className="pending-bill-item-card__header">
  {!showRequestCancel ? null :isHaveCanceledTypeBill ?   <Button  onClick={(e) => handleOpenListCancel(e,whBillItem)}  className='row-cancel--cancelButton buttonCancel'><Badge dot={isHaveConfirmBill}>Đã gửi huỷ đơn hàng</Badge></Button>
  :  <Button disabled={isHaveCanceledTypeBill} onClick={(e) => handleCancelBill(e,whBillItem,TYPE_CANCEL_PARTNER.bill.value)} danger className='row-cancel--cancelButton buttonCancel'>Huỷ đơn hàng</Button>}
      <Col span={24}>
        <Space direction='vertical' style={{ width: "100%" }}>
          <Row align='middle'>
            <Space>
              <UserOutlined color='#3481FF' style={{ color: '#3481FF' }} />
              <p className=''>{
                `${get(whBillItem, "whBill.customer.customerIdFull")} - ${get(whBillItem, "whBill.customerAddress.fullName")}`
              }</p>
            </Space>
          </Row>

          <Row align='middle'>
            <Space>
              <HeartOutlined color='#3481FF' style={{ color: '#3481FF' }} />
              <p className=''>{get(whBillItem, "snapshotService.whCategory.name.vi")}</p>
            </Space>
          </Row>

          <Row align='middle'>
            <Space>
              <Icon component={ServiceSvg} color="#3481FF" style={{ transform: 'scale(0.65)' }} />
              <p className=''>{nameService(whBillItem)}</p>
            </Space>
          </Row>

          <Row align='middle'>
            <Space>
              <Icon component={LocationSvg} color="#3481FF" style={{ transform: 'scale(0.65)' }} />
              <p className=''>{get(whBillItem, "whBill.customerAddress.street")}</p>
            </Space>
          </Row>

          <Row align='middle'>
            <Space>
              <Icon component={PhoneSvg} style={{ transform: 'scale(0.65)' }} />
              <p className=''>{get(whBillItem, "whBill.customerAddress.phoneNumber")}</p>
            </Space>
          </Row>
        </Space>
      </Col>
    
    </Row>
  )
}
  return (
    <Card className='pending-bill-item-card card'>
      <Collapse
        defaultActiveKey={get(history,'location.state.whBillItemId','') === get(whBillItem,'_id') ? ['1'] : []}
        ghost
      >
        <Panel
          header={<Header />}
          className='row-cancel'
          key="1"
          showArrow={false}
        >
          <PanelContent />
        </Panel>
      </Collapse>
      <Modal footer={null} title={`Huỷ ${get(dataCancel,'infoItemCancel')}`} visible={visibleCancel} destroyOnClose onCancel={handleCloseCancel} >
        <FormReasonCancel handleClose={handleCloseCancel} {...dataCancel}/>
      </Modal>
      <Modal footer={null} title={`Chi tiết yêu cầu huỷ cuộc hẹn đơn hàng ${get(dataListCancel,'whBill.billNumber','')}` } visible={visibleListCancel} destroyOnClose onCancel={handleCloseListCancel} >
      <TableListCancel loading={false} dataSource={get(dataListCancel,'appointmentCancelPartner',[])}/>
      </Modal>
    </Card>
  )
}

export default WhPartnerBillItemCardCollapse;
