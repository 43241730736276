import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Row } from 'antd';
import { pick } from 'lodash';
import { useCallback, useState } from 'react';
import toastr from 'toastr';
import api from '~/api';
import { BASE_URL } from '~/constants/defaultValue';
import { renderLoading } from '~/utils/helper';
import Editor from '../Utils/Editor';
import UploadFile from '../Workspace/WhManagerTrainingCourse.js/UploadFile';
export default function FormSendMail({ id, onCancel }) {
    const [loading,setLoading] = useState(false);
    const [form] = Form.useForm();
    const onFinish = useCallback(async(values) => {
        try {
            setLoading(true);
            const submitData = {
                jobPostingId : id,
                ...values
            };
            await api.applyJob.sendMail(submitData);
            toastr.success("Thành công");
            onCancel();
            setLoading(false);
        } catch (error) {
            toastr.error(error?.resonpse?.data?.message || "Có lỗi xảy ra");
            setLoading(false);
            
        }
    }, [id]);

    return (
        <Form labelCol={{ span: 4 }} form={form} onFinish={onFinish}>
            <Form.Item
                label="Nội dung"
                name={'content'}
            >
              {renderLoading(<Editor />,loading)}
            </Form.Item>
            <Form.Item
          label="File đính kèm"
          name="files"
          help="Vui lòng chọn file dưới 3MB"
          tooltip="File đính kèm khi gửi Email"
        >
          <UploadFile
            apiAction={`${BASE_URL}/api/v1/job-posting/file`}
            files={form.getFieldValue('files')}
            setFiles={(value) => form.setFieldsValue({files : value?.map((item) => pick(item,['name','response','uid']))})}
          />
        </Form.Item>
            <Row justify='end'>
                <Button shape='round' loading={loading} htmlType='submit' type='primary'>
                <Row align='middle'>
                    Gửi Email
                <SendOutlined style={{marginLeft : 10}}/>
                </Row>
                </Button>
            </Row>
        </Form>
    )
}
