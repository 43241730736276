import { List, Button, Popconfirm } from 'antd';
import { get } from 'lodash';
import React, { useState, useMemo } from 'react';
import {
  useBranches,
  useBranchQueryParams,
  useConvertToPartner
} from '~/hooks';

const FormConvertPartner = ({ id, onConfirm, isLoadingConfirm }) => {
  const [queryBranches] = useBranchQueryParams();
  const [branches, isLoading] = useBranches(queryBranches);
  return (
    <div >
      <h4 >Chuyển đổi chi nhánh</h4>
      <div className="container-fluid">
        <List className="list-branch" size="small" loading={isLoading}>
          {branches?.map((item, index) => {
            return (
              <List.Item
                key={index}
                actions={[
                  <Popconfirm
                    title="Chuyển đối tác này sang chi nhánh khác?"
                    onConfirm={() =>
                      onConfirm({
                        whPartnerId: id,
                        branchId: get(item, '_id')
                      })
                    }
                    okText="Xác nhận"
                    cancelText="Huỷ"
                    zIndex={9999}
                  >
                    <Button
                      // disabled={!isAvailable}
                      className="rounded"
                      type="primary"
                      loading={isLoadingConfirm}
                    >
                      Chọn
                    </Button>
                  </Popconfirm>
                ]}
              >
                {get(item, 'name')}
              </List.Item>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default FormConvertPartner;
