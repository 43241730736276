export const GET_KEYWORD_SEARCHS_REQUEST =
  'GET_KEYWORD_SEARCHS_REQUEST';
export const GET_KEYWORD_SEARCHS_SUCCESS =
  'GET_KEYWORD_SEARCHS_SUCCESS';
export const GET_KEYWORD_SEARCHS_FAILED = 'GET_KEYWORD_SEARCHS_FAILED';

export const GET_KEYWORD_SEARCH_REQUEST = 'GET_KEYWORD_SEARCH_REQUEST';
export const GET_KEYWORD_SEARCH_SUCCESS = 'GET_KEYWORD_SEARCH_SUCCESS';
export const GET_KEYWORD_SEARCH_FAILED = 'GET_KEYWORD_SEARCH_FAILED';

export const CREATE_KEYWORD_SEARCH_REQUEST =
  'CREATE_KEYWORD_SEARCH_REQUEST';
export const CREATE_KEYWORD_SEARCH_SUCCESS =
  'CREATE_KEYWORD_SEARCH_SUCCESS';
export const CREATE_KEYWORD_SEARCH_FAILED =
  'CREATE_KEYWORD_SEARCH_FAILED';

export const UPDATE_KEYWORD_SEARCH_REQUEST =
  'UPDATE_KEYWORD_SEARCH_REQUEST';
export const UPDATE_KEYWORD_SEARCH_SUCCESS =
  'UPDATE_KEYWORD_SEARCH_SUCCESS';
export const UPDATE_KEYWORD_SEARCH_FAILED =
  'UPDATE_KEYWORD_SEARCH_FAILED';

export const DELETE_KEYWORD_SEARCH_REQUEST =
  'DELETE_KEYWORD_SEARCH_REQUEST';
export const DELETE_KEYWORD_SEARCH_SUCCESS =
  'DELETE_KEYWORD_SEARCH_SUCCESS';
export const DELETE_KEYWORD_SEARCH_FAILED =
  'DELETE_KEYWORD_SEARCH_FAILED';


export const RESET_KEYWORD_SEARCH_STATE = 'RESET_KEYWORD_SEARCH_STATE';
