import { Timeline, Typography } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'

export default function HistoryTimeLine({ data }) {
    if(!data?.length) return <Typography.Text type='secondary'>(Chưa có sự thay đổi trạng thái nào)</Typography.Text>
    return (
        <div style={{width : 400,maxHeight : 500,overflowY : 'scroll',paddingTop : 5,paddingRight : 20}}>
            <Timeline
            mode='left'
        >
            {data?.map((item,index) => <Timeline.Item key={index} label={moment(get(item,'dateHistory')).format("DD-MM-YYYY HH:mm")}>{get(item,'message','')}</Timeline.Item>)}
        </Timeline>
        </div>
    )
}
