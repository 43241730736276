import {
    Card,
    Col, Image, Row, Skeleton, Space
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import { useRating } from '~/hooks/trainingCoursePartner';
import angry from "../../assets/images/emojis/angry.svg";
import cry from "../../assets/images/emojis/cry.svg";
import happy from "../../assets/images/emojis/happy.svg";
import like from "../../assets/images/emojis/like.svg";
import love from "../../assets/images/emojis/love.svg";
import './WorkSpaceTimeTrain.scss';

export default function Rating({id}) {
    const [rating,isLoadingRating] = useRating(id);
  return (
    <Row className='boxRating' gutter={48}>
          <Col span={24}>
            <Card className='card'>
              <h3>Điểm khách hàng đánh giá</h3>
              <Space direction='vertical' size='middle' style={{ width: "100%" }}>
              <Row >
                <div style={{marginRight : '5px'}}>Rate:</div>
                <Text strong>{isLoadingRating ? <Skeleton.Button active={true} size='small' /> :  get(rating, 'resultSum')}</Text>
               
              </Row>
              <Row justify='space-between'>
                <Col span={3}>
                  <Row style={{flexDirection : 'column', alignItems  : 'center'}}>
                  <Space>
                    <Image preview={false} src={love} />
                    <div>{isLoadingRating ? <Skeleton.Button active={true} size='small' /> : get(rating,'ratingLevel.LOVE')}</div>
                  </Space>

                  <Text strong>Rất hài lòng</Text>
                  </Row>
                </Col>
                <Col span={3}>
                  <Row style={{flexDirection : 'column', alignItems  : 'center'}}>
                  <Space>
                    <Image preview={false} src={like} />
                    <div>{isLoadingRating ? <Skeleton.Button active={true} size='small' /> : get(rating,'ratingLevel.LIKE')}</div>
                    </Space>
                    <Text strong>Hài lòng</Text>
                    </Row>
                </Col>
                <Col span={3}>
                  <Row style={{flexDirection : 'column', alignItems  : 'center'}}>
                  <Space>
                    <Image preview={false} src={happy} />
                    <div>{isLoadingRating ? <Skeleton.Button active={true} size='small' /> : get(rating,'ratingLevel.ACCEPTABLE')}</div>
                    </Space>
                    <Text strong>Bình thường</Text>
                    </Row>
                </Col>
                <Col span={3}>
                  <Row style={{flexDirection : 'column', alignItems  : 'center'}}>
                  <Space>
                    <Image preview={false} src={cry} />
                    <div>{isLoadingRating ? <Skeleton.Button active={true} size='small' /> : get(rating,'ratingLevel.SAD')}</div>
                  </Space>
                  <Text strong>Không hài lòng</Text>
                  </Row>
                </Col>
                <Col span={3}>
                  <Row style={{flexDirection : 'column', alignItems  : 'center'}}>
                  <Space>
                    <Image preview={false} src={angry} />
                    <div>{isLoadingRating ? <Skeleton.Button active={true} size='small' /> : get(rating,'ratingLevel.ANGRY')}</div>
                  </Space>
                  <Text strong>Rất không hài lòng</Text>
                  </Row>
                </Col>
              </Row>
              </Space>
            </Card>
          </Col>
        </Row>
  )
}
