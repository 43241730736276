import React, { useState } from 'react';
import { PATH_APP } from '~/routes/paths';
import {
  usePositions,
  useUpdateRateConfig,
  useDeleteRateConfig,
  useRateConfigQueryParams
} from '~/hooks/rateConfig';
import { get } from 'lodash';
import PositionForm from './Form';
import { Table, Button, Input, Switch, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { LANGUAGE } from '~/constants/defaultValue';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { EditFilled } from '@ant-design/icons';
import { useSpecialityOptions } from '~/hooks/speciality';
import { useMatchPolicy } from '~/hooks';
import POLICY from '~/constants/policy';
import WithPermission from '~/components/Common/WithPermission';

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const { Search } = Input;
const Position = ({ history }) => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHPOSITION);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHPOSITION);
  const [query, onPagingChange] = useRateConfigQueryParams();
  const [positions, isLoading] = usePositions(query);
  const [specialityOptions] = useSpecialityOptions();
  const [keyword, setKeyword] = useState(query.keyword);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [, deletePosition] = useDeleteRateConfig();
  const [isSubmitLoading, updatePosition] = useUpdateRateConfig();
  const [positionId, setPositionId] = useState(null);
  const [, setIsOpenForm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(1);

  const ColumnActions = ({
    id,
    state,
    deletePosition,
    updatePosition,
    isSubmitLoading,
    ...restProps
  }) => {
    return (
      <div className="custom-table__actions">
        <WithPermission permission={POLICY.UPDATE_WHPOSITION}>
          <EditFilled
            onClick={() => {
              setIsOpen(true);
              setPositionId(id);
            }}
            style={{ color: '#1f89e5' }}
          />
        </WithPermission>
        <WithPermission permission={POLICY.UPDATE_WHPOSITION}>
          <WithPermission permission={POLICY.DELETE_WHPOSITION}>
            <p style={{ marginLeft: '10px' }}>|</p>
          </WithPermission>
        </WithPermission>
        <WithPermission permission={POLICY.DELETE_WHPOSITION}>
          <Switch
            checked={state === 'ACTIVE'}
            onChange={(value) =>
              updatePosition({ state: value ? 'ACTIVE' : 'INACTIVE', id })
            }
            // loading={isSubmitLoading}
          />
        </WithPermission>
      </div>
    );
  };

  const onPagingChangeLocal = ({ current, pageSize, total }, someObj) => {
    setCurrent(current);
  };

  const onSearch = () => {
    let searchParams = `?page=${query.page}&limit=${query.limit}`;
    if (keyword) searchParams += `&keyword=${keyword}`;

    history.push({
      pathname: '/position',
      search: searchParams
    });
  };
  const isOpenForm = (id) => {
    setPositionId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setPositionId(null);
    setIsOpenForm(false);
  };
  const columns = [
    {
      title: 'STT',
      key: 'number',
      width: '70px',
      render: (text, record, index) => {
        return (+pagination.page - 1) * pagination.limit + index + 1;
      }
    },

    {
      title: 'Nhóm đối tác',
      key: 'employeeGroup',
      width: '250px',
      render: (record) => {
        return record.employeeGroup.map((Item) => Item?.name?.vi);
      }
    },

    {
      title: 'Chức vụ đảm nhận',
      key: 'name',
      width: '450px',
      render: (record) => {
        return get(record.name, LANGUAGE.VI);
      }
    },

    {
      title: 'Hệ số',
      key: 'rate',
      width: '170px',
      render: (record) => {
        return record.rate;
      }
    },
    ...(canDelete || canUpdate
      ? [
          {
            title: 'Thao tác',
            key: 'action',
            width: '110px',
            render: (record) => {
              record = {
                ...record,
                state: record.state,
                id: record._id
              };
              return (
                <ColumnActions {...record} updatePosition={updatePosition} />
              );
            }
          }
        ]
      : [])
  ];
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách chức vụ" />
        <div className="page-content__main" style={{ display: 'block' }}>
          <div className="page-wraper__header">
            <Search
              style={{ width: 300 }}
              placeholder="Tìm chức vụ"
              enterButton
              allowClear
              onSearch={onSearch}
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
            <WithPermission permission={POLICY.WRITE_WHPOSITION}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => {
                  setIsOpen(true);
                  setPositionId(null);
                }}
              >
                {' '}
                Thêm mới
              </Button>
            </WithPermission>
          </div>
          {isLoading ? (
            <SkeletonTable rowCount={positions.length} columns={columns} />
          ) : (
            <Table
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={positions}
              onChange={onPagingChangeLocal} // as API /rate-config does not have pagination, we paginate locally
              size="middle"
              pagination={{
                // ...paging,
                // total: positions.length,
                current,
                showTotal: (total) => `Tổng cộng: ${total} `,
                onChange: (page) => {
                  setPagination({ ...pagination, page: page });
                }
              }}
            />
          )}
        </div>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <PositionForm
          specialityOptions={specialityOptions}
          positionId={positionId}
          onClose={() => setIsOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default Position;
