import { Typography } from 'antd'
import React from 'react'
import NoData from '~/assets/images/NoData.svg';
export default function EmptyList({children}) {
  return (
    <div className='empty-list'>
        <img src={NoData}/>
        {children ?? <Typography.Text strong type='primary'>Trống</Typography.Text>}
    </div>
  )
}
