import { CheckCircleOutlined, CloseCircleFilled, ExclamationCircleFilled, ExclamationCircleTwoTone, SyncOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tag, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { BaseBorderBox } from '~/components/Common';
import { CONFIRM_VOUCHER_STATUS } from '~/constants/confirmVoucherWarehouse';
import { useCancelConfirmVoucherWarehouse, useChangeStatusConfirmVoucherWarehouse, useConfirmConfirmVoucherWarehouse } from '~/hooks/confirmVoucherWarehouse';
import { renderLoading } from '~/utils/helper';
const layout = {
    md : 24,
    sm : 24,
    xs : 24,
    xl : 8,
    lg : 8
  }

const RowInfo = ({ title, content }) => {
    return  <Row style={{ margin: 5 }} justify='space-between'>
        <Col>
            <span>{title}: </span>
        </Col>
        <Col>
            {content}
        </Col>
    </Row>
};
const TagConfirm = ({ status }) => {
    if(status === CONFIRM_VOUCHER_STATUS.NEW){
        return <Tag className='tagConfirm' icon={<SyncOutlined spin />} color="processing">
        Đang chờ xác nhận
    </Tag>
    }
    if(status === CONFIRM_VOUCHER_STATUS.CONFIRM){
        return <Tag className='tagConfirm' icon={<CheckCircleOutlined />} color="success">
        Đã xác nhận
    </Tag>
    }
    if(status === CONFIRM_VOUCHER_STATUS.CANCEL){
        return <Tag className='tagConfirm' icon={<CloseCircleFilled />} color="error">
      Đã từ chối
    </Tag>
    }
    return null
}
export default function ConfirmBox({ infoEmployee, infoWhPartner, id, status,handleByPartner,loading }) {
    // const [submitLoading, handleChangeStatus] = useChangeStatusConfirmVoucherWarehouse()
    const [submitLoading, handleConfirm] = useConfirmConfirmVoucherWarehouse();
    const [, handleCancel] = useCancelConfirmVoucherWarehouse();
    const showConfirm = (status) => {
    confirm({
      title: `${status === CONFIRM_VOUCHER_STATUS.CONFIRM ? "Xác nhận" : "Từ chối"} sử dụng thiết bị?`,
      icon: <ExclamationCircleTwoTone />,
      content: 'Xác nhận lưu ?',
      onOk() {
        switch (status) {
            case CONFIRM_VOUCHER_STATUS.CONFIRM:
                handleConfirm(id)
                break;
            case CONFIRM_VOUCHER_STATUS.CANCEL:
                handleCancel(id)
                break;
        
            default:
                break;
        }
      },
      okText : "Xác nhận",
      cancelText : "Huỷ"
    });
  };
    return (
        <Row justify='space-between'>
            <Col style={{marginTop : 20}} {...layout}>
                <BaseBorderBox title={'Xác nhận từ nhân viên'}>
                    <RowInfo
                        title={"Trạng thái"}
                        content={
                            <Tag className='tagConfirm' icon={<CheckCircleOutlined />} color="success">
                                Đã xác nhận
                            </Tag>
                        }
                    />
                    <RowInfo
                        title={"Tên nhân viên"}
                        content={<Typography.Text strong>{get(infoEmployee, 'name', '')}</Typography.Text>}
                    />
                    <RowInfo
                        title={"Ngày xác nhận"}
                        content={
                            <Tag className='tagConfirm' bordered={false} color="blue">{get(infoEmployee, 'dateConfirm', '')}</Tag>
                        }
                    />

                </BaseBorderBox>
            </Col>
            <Col style={{marginTop : 20}} {...layout}>
                <BaseBorderBox title={'Xác nhận từ đối tác'}>
                    {renderLoading(<RowInfo
                        title={"Trạng thái"}
                        content={  <TagConfirm status={status} />}
                    />,loading)}
                    {renderLoading(<RowInfo
                        title={"Tên nhân viên"}
                        content={<Typography.Text strong>{get(infoWhPartner, 'name', '')}</Typography.Text>}
                    />,loading)}
                    {renderLoading(<RowInfo
                        title={"Ngày xác nhận"}
                        content={
                            get(infoWhPartner, 'dateConfirm') && <Tag className='tagConfirm' bordered={false} color="blue">{get(infoWhPartner, 'dateConfirm', '')}</Tag>
                        }
                    />,loading)}
                    {status === CONFIRM_VOUCHER_STATUS.NEW && handleByPartner && <div className='action'>
                    <Button
                        onClick={() => { showConfirm(CONFIRM_VOUCHER_STATUS.CONFIRM) }}
                        loading={submitLoading}
                        type='primary'>
                        Xác nhận
                    </Button>
                    <Button
                        onClick={() => { showConfirm(CONFIRM_VOUCHER_STATUS.CANCEL) }}
                        loading={submitLoading}
                        danger
                    >
                        Từ chối
                    </Button>
                    </div>}
                </BaseBorderBox>
            </Col>
        </Row>
    )
}
