import { Divider, Image, Row, Typography } from 'antd';
import React from 'react';
import { TYPE_FILE } from '~/constants/defaultValue';
import EXCEL from '~/assets/images/typeFile/excel.png';
import WORD from '~/assets/images/typeFile/word.jpeg';
import POWERPOINT from '~/assets/images/typeFile/powerPoint.png';
import IMAGE from '~/assets/images/typeFile/image.png';
import PDF from '~/assets/images/typeFile/pdf.png';
export default function LinkFile({ url, href }) { // url is Name
  let IMAGE_FILE = {
    EXCEL,
    IMAGE,
    PDF,
    POWERPOINT,
    WORD
  };

  return (
    <>
      {/* {type !== TYPE_FILE.IMAGE ? ( */}
        <Row align="start" justify="start">
          {
            href 
            ? <Typography.Link style={{maxWidth : '100%' , margin : '2px 0'}} href={href || ''}>{url || ''}</Typography.Link>
            : <Typography.Text style={{maxWidth : '100%' , margin : '2px 0'}} type='danger'>{url || ''} (File bị lỗi)</Typography.Text>
            }
          {/* <Divider style={{margin : 0}}/> */}
          {/* <Image
            preview={false}
            style={{ width: '25px', height: '25px', marginLeft: '10px' }}
            src={IMAGE_FILE[image]}
            alt="image"
          /> */}
        </Row>
      {/* ) : (
        <Image
          style={{ width: '25px', height: '25px', marginLeft: '10px' }}
          src={href || ''}
          alt="image"
        />
      )} */}
    </>
  );
}
