import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Menu, Modal, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { forIn, get, head, keys } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import {
  TYPE_ACTION_PERMISSIONS,
  TYPE_OF_MONEY_REWARD_ITEM_VI,
  TYPE_OF_POINT_REWARD_ITEM_VI,
  TYPE_REWARD_ITEM_REFERRAL,
  TYPE_REWARD_ITEM_REFERRAL_VI,
} from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import {
  useDeleteRewardItem,
  useResetRewardItem,
  useRewardItemPaging,
  useRewardItemQueryParams,
  useRewardItems,
  useUpdateRewardItem,
  useUpdateRewardItemParams
} from '~/hooks/rewardItem';
import { formatNumberThreeComma } from '~/hooks/utils';
import { Breadcrumb } from '../Common';
import ModalConfirm from '../Common/ModalConfirm';
import PermissionAction from '../Utils/PermissionAction';
import PermissionToolTip from '../Utils/PermissionToolTip';
import SkeletonTable from '../Utils/SkeletonTable';
import RewardItemForm from './Form/RewardItemForm';
const listOptionSearch = [
  // {
  //   value: 'types',
  //   label: 'Loại'
  // },
  {
    value: 'point',
    label: 'Số điểm'
  },
  {
    value: 'item',
    label: 'Vật phẩm'
  }
];
export default function RewardItem() {
  const [form] = Form.useForm()
  const [selectSearch, setSelectSearch] = useState(
    head(listOptionSearch).value
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [id, setId] = useState(null);
  const handleOpenModal = (id) => {
    setIsOpenModal(true);
    if (id) {
      setId(id);
    }
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setId(null);
  };
  // hook

  const [query] = useRewardItemQueryParams();
  const [rewardItems, isLoading] = useRewardItems(query);
  const paging = useRewardItemPaging(query);
  const [keyword, { setKeyword, onParamChange },listSearch] =
    useUpdateRewardItemParams(query);
  useResetRewardItem();
  //

  //////////////////////////////// DELETE
  const handleOpenModalDelete = (id) => {
    setId(id)
    setIsOpenModalDelete(true)
  }
  const handleCloseModalDelete = () => {
    setId(null)
    setIsOpenModalDelete(false)
  }
  const handleDelete = () => {
    deleteRewardItem(id);
  }
  const [isSubmitLoading, deleteRewardItem] = useDeleteRewardItem(handleCloseModalDelete);
  ////////////////////////////////
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('');
  };
  const onValuesChange = (value,allValues) => {
    // forIn(allValues,(values,key,obj) =>{obj[key] = null})
    onParamChange({...allValues,...value})
  }
  useEffect(() => {
    let value = listOptionSearch?.find(e => query[e.value])
    setSelectSearch(get(value,'value',head(listOptionSearch).value))
    setKeyword(query[get(value,'value','')])
  },[])
  useEffect(() => {
    const {types,sortPoint} = query
    form.setFieldsValue({types:types || null,sortPoint:parseInt(sortPoint || 0)  || null})
  },[query])
  const columns = [
    {
      title: 'Loại',
      dataIndex: 'types',
      key: 'types',
      render: (item, record, index) => {
        if(item === TYPE_REWARD_ITEM_REFERRAL.point){
          return TYPE_OF_POINT_REWARD_ITEM_VI[get(record,'typesOfPoint')]
        }
        if(item === TYPE_REWARD_ITEM_REFERRAL.money){
          return TYPE_OF_MONEY_REWARD_ITEM_VI[get(record,'typesOfMoney')]
        }
        return TYPE_REWARD_ITEM_REFERRAL_VI[item] || ''
      }
    },
    {
      title: 'Giá trị',
      dataIndex: 'types',
      key: 'point',
      render: (item, record, index) =>{
       return item === TYPE_REWARD_ITEM_REFERRAL.item
        ? get(record, 'item', '')
        : formatNumberThreeComma(get(record, item, 0))
      }
       
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      width: '100px',
      key: 'action',
      render: (item, record, index) => (
        <Dropdown
          overlayStyle={{ width: 150 }}
          trigger={['click']}
          placement="bottomCenter"
          overlay={
            <Menu>
              <PermissionAction key={v4()} type={TYPE_ACTION_PERMISSIONS.UPDATE.value} handle={() => {handleOpenModal(get(record,'_id'))}} permission={POLICIES.UPDATE_REFERRAL}  />
              <PermissionAction key={v4()} type={TYPE_ACTION_PERMISSIONS.DELETE.value} handle={() => {handleOpenModalDelete(get(record,'_id'))}} permission={POLICIES.DELETE_REFERRAL} />
            </Menu>
          }
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
      )
    }
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Loại phần thưởng" />
        <div className="page-content__main">
          <div className="page-content__left">
          <Form
             form={form}
             autoComplete="off"
             onFinish={() => {}}
             scrollToFirstError
             onValuesChange={onValuesChange}
             requiredMark={false}
             labelCol={{ sm: 24, md: 24, lg: 24 }}
           >
             <Form.Item  label='Sắp xếp Điểm' name='sortPoint'>
                <Select allowClear  options={[
                    {label:'Tăng dần',value : 1},
                    {label:'Giảm dần',value : -1},
                ]}/>
             </Form.Item>
             <Form.Item  label='Loại phần thưởng' name='types'>
                <Select allowClear  options={keys(TYPE_REWARD_ITEM_REFERRAL)?.map(type => ({value : type,label : TYPE_REWARD_ITEM_REFERRAL_VI[type]}))}/>
             </Form.Item>
           </Form>
          </div>
          <div className="page-content__right" style={{ width: '80%' }}>
            <div
              className="page-wraper__header"
              style={{ justifyContent: 'space-between', display: 'flex' }}
            >
              <div>
                <Select
                  value={selectSearch}
                  style={{
                    width: 170
                  }}
                  onChange={handleChangeSelectSearch}
                  options={listOptionSearch}
                />
                {/* {selectSearch === 'types' ? (
                  <Select style={{width : 170}}
                  placeholder='Tìm theo loại'
                  allowClear
                  value={keyword || ""}
                  onChange={(value) => onParamChange({...listSearch, [selectSearch]: value })}
                    options={[
                      {
                        label: TYPE_REWARD_ITEM_REFERRAL_VI.point,
                        value: TYPE_REWARD_ITEM_REFERRAL.point
                      },
                      {
                        label: TYPE_REWARD_ITEM_REFERRAL_VI.item,
                        value: TYPE_REWARD_ITEM_REFERRAL.item
                      },
                      {
                        label: TYPE_REWARD_ITEM_REFERRAL_VI.money,
                        value: TYPE_REWARD_ITEM_REFERRAL.money
                      },
                      {label : "Tất cả",value : ''},
                    ]}
                  />
                ) : ( */}
                  <Search
                    style={{ width: 300 }}
                    placeholder={`Nhập ${
                      listOptionSearch.find((e) => e.value === selectSearch)
                        .label
                    } để tìm...`}
                    enterButton
                    allowClear
                    onSearch={(value) => {
                      onParamChange({ ...listSearch,[selectSearch]: value?.trim() });
                    }}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                {/* )} */}
              </div>

              <PermissionToolTip permission={POLICIES.WRITE_REFERRAL} textButton={"Thêm vật phẩm - điểm thưởng"} textPermission={"Bạn không có quyền tạo"} >
              <Button onClick={() => handleOpenModal()} type="primary">
                Thêm vật phẩm - điểm thưởng
              </Button>
              </PermissionToolTip>

            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={rewardItems}
                size="middle"
                onChange={({current}) => onParamChange({page : current})}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose
        visible={isOpenModal}
        closable={false}
        footer={null}
        onCancel={handleCloseModal}
      >
        <RewardItemForm id={id} handleCloseModal={handleCloseModal} />
      </Modal>
      <ModalConfirm
        text="Xác nhận Xoá bậc"
        textOk="Xác nhận"
        type="delete"
        loading={isSubmitLoading}
        visible={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        handleOk={handleDelete}
      />
    </div>
  );
}
