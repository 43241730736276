import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getGroupCategories({ payload: query }) {
  try {
    const data = yield call(Api.groupCategory.getAll, query);
    yield put({ type: Types.GET_GROUP_CATEGORIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_GROUP_CATEGORIES_FAILED,
      payload: error.message
    });
  }
}

function* getGroupCategory({ payload }) {
  try {
    const data = yield call(Api.groupCategory.getById, payload);
    yield put({ type: Types.GET_GROUP_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_GROUP_CATEGORY_FAILED,
      payload: error.message
    });
  }
}

function* createGroupCategory(action) {
  try {
    const data = yield call(Api.groupCategory.create, action.payload);
    yield put({ type: Types.CREATE_GROUP_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_GROUP_CATEGORY_FAILED,
      payload: error.message
    });
  }
}

function* updateGroupCategory(action) {
  console.log('====================================');
  console.log(action, "NGOAI");
  console.log('====================================');
  try {
    const data = yield call(Api.groupCategory.update, action.payload);
    yield put({ type: Types.UPDATE_GROUP_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_GROUP_CATEGORY_FAILED,
      payload: error.message
    });
  }
}

function* deleteGroupCategory({ payload }) {
  try {
    yield call(Api.groupCategory.delete, action.payload);
    yield put({ type: Types.DELETE_GROUP_CATEGORY_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_GROUP_CATEGORY_FAILED,
      payload: error.message
    });
  }
}

function* getPermission({ payload }) {
  try {
    const resources = yield call(Api.groupCategory.getPermission, payload);
    yield put({ type: Types.GET_PERMISSION_SUCCESS, payload: resources });
  } catch (error) {
    yield put({
      type: Types.GET_PERMISSION_FAILED,
      payload: error.message
    });
  }
}

function* updatePermissionCategory({ payload }) {

  try {
    const { isAssgined, companyId, groupId, ...rest } = payload;
    const request = isAssgined
      ? Api.groupCategory.updatePermission
      : Api.groupCategory.deletePermission;
      console.log('====================================');
      console.log(isAssgined, "NGOAI");
      console.log('====================================');
    yield call(request, { ...rest, groupId });
    yield put({
      type: Types.UPDATE_PERMISSION_SUCCESS
    });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PERMISSION_FAILED,
      payload: error.message
    });
  }
}

export default function* groupCategory() {
  yield takeLatest(Types.GET_GROUP_CATEGORIES_REQUEST, getGroupCategories);
  yield takeLatest(Types.GET_GROUP_CATEGORY_REQUEST, getGroupCategory);
  yield takeLatest(Types.CREATE_GROUP_CATEGORY_REQUEST, createGroupCategory);
  yield takeLatest(Types.UPDATE_GROUP_CATEGORY_REQUEST, updateGroupCategory);
  yield takeLatest(Types.DELETE_GROUP_CATEGORY_REQUEST, deleteGroupCategory);
  yield takeLatest(Types.GET_PERMISSION_REQUEST, getPermission);
  yield takeLatest(Types.UPDATE_PERMISSION_REQUEST, updatePermissionCategory);
}
