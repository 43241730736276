import { Badge, Tooltip } from 'antd'
import React from 'react'

export default function TooltipTitle({zIndex= 9999,titleTooltip="",content="",count = null,offset = [9, 3],dot=false,styleTooltip={},styleBadge={},classNameToolTip='',classNameBadge=''}) {
  return (
    <Tooltip className={classNameToolTip} style={{...styleTooltip}} zIndex={zIndex} title={titleTooltip}>
            <Badge className={classNameBadge} style={{...styleBadge}} offset={offset} count={count} dot={dot}>
              {content}
            </Badge>
          </Tooltip>
  )
}
