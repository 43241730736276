import * as Types from '../../constants/actionTypes';
  
  export const getMedicines = query => ({
    type: Types.GET_WH_MEDICINES_REQUEST,
    payload: query
  });
  export const getMedicine = id => ({
    type: Types.GET_WH_MEDICINE_REQUEST,
    payload: id
  });
  export const createMedicine = instance => ({
    type: Types.CREATE_WH_MEDICINE_REQUEST,
    payload: instance
  });
  export const updateMedicine = instance => ({
    type: Types.UPDATE_WH_MEDICINE_REQUEST,
    payload: instance
  });
  export const updateTrainingCoursePartnerInMedicine = instance => ({ //
    type: Types.UPDATE_COURSE_TRAINING_IN_WH_MEDICINE_REQUEST,
    payload: instance
  });
  export const deleteMedicine = id => ({
    type: Types.DELETE_WH_MEDICINE_REQUEST,
    payload: id
  });
  export const resetMedicineState = () => ({
    type: Types.RESET_WH_MEDICINE_STATE
  });