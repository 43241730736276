import * as Types from '~/constants/actionTypes';

export const getMedicalEquipments = (query) => ({
  type: Types.GET_MEDICAL_EQUIPMENTS_REQUEST,
  payload: query
});

export const getMedicalEquipment = (id) => ({
  type: Types.GET_MEDICAL_EQUIPMENT_REQUEST,
  payload: id
});

export const createMedicalEquipment = (equipment) => ({
  type: Types.CREATE_MEDICAL_EQUIPMENT_REQUEST,
  payload: equipment
});

export const updateMedicalEquipment = (equipment) => ({
  type: Types.UPDATE_MEDICAL_EQUIPMENT_REQUEST,
  payload: equipment
});

export const deleteMedicalEquipment = (id) => ({
  type: Types.DELETE_MEDICAL_EQUIPMENT_REQUEST,
  payload: id
});

export const resetMedicalEquipmentState = () => ({
  type: Types.RESET_MEDICAL_EQUIPMENT_STATE
});
