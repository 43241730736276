import { Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { get } from 'lodash';
import { isValidElement, useCallback } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetDataNavbar } from '~/hooks';
import TitleSubmenu from '../Home/TitleSubmenu';

export default function MenuGlass({ keyItem }) {
  const { list: dataNavbar } = useGetDataNavbar();
  const WithPermissions = useCallback((component, isMatchPolicy) => isMatchPolicy ? component : null, []);
  const validIcon = (icon) => isValidElement(icon) ? icon : <i className="uil-apps me-2"></i>;

  return (
    <div className='homepage'>
      <Sider >

        {/* <Drag/> */}
        <Menu mode={'inline'} defaultSelectedKeys={keyItem}
          className='menu-homepage'
        >
          {dataNavbar?.map((group) => {
            const { accessKey, children, titleGroup, icon, isMatchPolicy } = group;
            {/* If Not have Child will Render Item Else will Render Submenu */ }
            if (!children.length) {
              return WithPermissions(<Menu.Item icon={validIcon(icon)}
                key={get(group, 'path')}>
                <Link to={get(group, 'path')}>
                  {titleGroup}
                </Link>
              </Menu.Item>, isMatchPolicy)
            }

            return WithPermissions(<Menu.SubMenu popupClassName='draw-custom--menu-draw__submenu' key={accessKey} icon={validIcon(icon)} title={
              <TitleSubmenu accessKey={accessKey} group={group}/> 
            }>

              {group?.children?.map((item) => {
                const childrens = get(item, 'children', []);
                const isMatchPolicy2 = get(item, 'isMatchPolicy');
                const { title, path } = item;
                {/* If Not have children will Render Item  */ }
                if (!childrens.length) {
                  return WithPermissions(<Menu.Item
                    key={path}>
                    <Link to={path}>
                      {title}
                    </Link>

                  </Menu.Item>, isMatchPolicy2)
                }
                else {
                  {/* If Not have Child will Render Item Else will Render Submenu */ }
                  return WithPermissions(<Menu.SubMenu popupClassName='draw-custom--menu-draw__submenu' title={title}>
                    {
                      childrens?.map(({ title, path: pathItem, isMatchPolicy: isMatchPolicy3 }) =>
                        WithPermissions(<Menu.Item
                          key={pathItem}
                        >
                          <Link to={pathItem}>
                            {title}
                          </Link>

                        </Menu.Item>, isMatchPolicy3)
                      )}
                  </Menu.SubMenu>, isMatchPolicy2)
                }
              })}
            </Menu.SubMenu>, isMatchPolicy)
          })}
        </Menu>
      </Sider>
    </div>
  )
}
