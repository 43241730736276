import { Button, Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd'
import SkeletonInput from 'antd/lib/skeleton/Input'
import { get, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { MAX_VALUE_CONFIG_CAMPAIGN, TYPE_RANKING_REFERRAL_VI } from '~/constants/defaultValue'
import { useCreateRanking, useRanking, useResetRankingAction, useUpdateRanking } from '~/hooks/ranking'
import { formatNumberThreeComma, useOptionsSelectAntd } from '~/hooks/utils'

export default function RankingForm({id,handleCloseModal}) {
    const [form] = Form.useForm()
    // const [types,setTypes] = useState(Object.keys(TYPE_RANKING_REFERRAL_VI)[0])
    // const options = useOptionsSelectAntd(TYPE_RANKING_REFERRAL_VI)
    const handleFinish = () => {
        handleCloseModal()
        form.resetFields()
    }
    // HOOK
    const [submitLoading,createRanking] = useCreateRanking(handleFinish)
    const [,updateRanking] = useUpdateRanking(handleFinish)
    const [Ranking,loading] = useRanking(id)
    //
    const onFinish = (values) => {
      const submitData  = {...values,
        endPoint : get(values,'endPoint') || MAX_VALUE_CONFIG_CAMPAIGN,
        percentDiscount : get(values,'percentDiscount',0),
      }
      if(id){
        updateRanking({...submitData,_id : id})
      }
      else{
        createRanking(submitData)
      }
    }
    useResetRankingAction()
    useEffect(() => {
      form.resetFields()
      if(!!id){
        form.setFieldsValue({
         ...Ranking,
         endPoint : get(Ranking,'endPoint',0) === MAX_VALUE_CONFIG_CAMPAIGN ? null : get(Ranking,'endPoint',0)
        })
      }
    },[id,Ranking])
    const rules = (name) => [{ required: true, message: `Xin mời ${name}` }];
  return (
    <Form
    form={form}
    className='form-add-activity'
    autoComplete="off"
    onFinish={onFinish}
    scrollToFirstError
    requiredMark={false}
    labelCol={{ sm: 24, md: 24, lg: 6 }}
    wrapperCol={{ sm: 24, md: 24 }}
  >
    <Form.Item label='Tên Bậc' name='name' rules={rules("Nhập tên bậc")}>
         {/* <Select loading={loading} onChange={(value) => setTypes(value)} defaultValue={types} options={options}/> */}
         {loading ? <SkeletonInput active /> :  <Input/>} 
    </Form.Item>
    <Divider >Điểm</Divider>
   <Row>
    <Col span={12}>
    <Form.Item labelCol={{lg : 12,md : 12 , xs : 12,xl : 12}} label={"Từ"} rules={rules("Nhập điểm bắt đầu")} name='startPoint'>
        {loading ? <SkeletonInput active /> : <InputNumber formatter={(value) => formatNumberThreeComma(value)} style={{width : '100px'}} />} 
    </Form.Item>
   
    </Col>
    <Col span={12}>
    <Form.Item labelCol={{lg : 12,md : 12 , xs : 12,xl : 12}} label={"Đến"} rules={rules("Nhập điểm kết thúc")}  name='endPoint'>
        {loading ? <SkeletonInput active /> : <InputNumber formatter={(value) => formatNumberThreeComma(value)} style={{width : '100px'}} />} 
    </Form.Item>
   
    </Col>
   </Row>
   <Divider>Chiết khấu đơn hàng</Divider>
   <Row>
    <Col span={12}>
    <Form.Item labelCol={{lg : 12,md : 12 , xs : 12,xl : 12}}  label={"Chiết khấu (%)"} name='percentDiscount'>
        {loading ? <SkeletonInput active /> : <InputNumber min={0} max={100} formatter={(value) => formatNumberThreeComma(value)} style={{width : '150px'}} />} 
    </Form.Item>
    </Col>
    <Col span={12}>
    <Form.Item labelCol={{lg : 12,md : 12 , xs : 12,xl : 12}}  label={"Chiết khấu tối đa"} name='maxDiscountRate'>
        {loading ? <SkeletonInput active /> : <InputNumber formatter={(value) => formatNumberThreeComma(value)} style={{width : '150px'}} />} 
    </Form.Item>
    </Col>
   </Row>
    <Divider>Điểm thưởng</Divider>
    <Row>
    <Col span={12}>
    <Form.Item labelCol={{lg : 12,md : 12 , xs : 12,xl : 12}}  label={"% Quy đổi điểm thưởng"} name='maxRefund'>
        {loading ? <SkeletonInput active /> : <InputNumber min={0} max={100} formatter={(value) => formatNumberThreeComma(value)} style={{width : '150px'}} />} 
    </Form.Item>
    </Col>
    <Col span={12}>
    <Form.Item  labelCol={{lg : 12,md : 12 , xs : 12,xl : 12}} label={"Dùng điểm thưởng tối đa"} name='maxRefundUse'>
        {loading ? <SkeletonInput active /> : <InputNumber formatter={(value) => formatNumberThreeComma(value)} style={{width : '150px'}} />} 
    </Form.Item>
    </Col>
   </Row>
    <Row justify='space-around' className='groupButtonSubmit'>
      <Col  span={8}>
      <Button onClick={handleCloseModal}>Huỷ</Button>
      </Col>
      <Col span={8}>
      <Button loading={submitLoading} type='primary' htmlType='submit'>{id ? "Cập nhật" : "Tạo mới"}</Button>
      </Col>
    </Row>
  </Form>
  )
}
