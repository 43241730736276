import {
  Button,
  Form,
  Input,
  Radio,
  Select,
  Col,
  Row,
  TreeSelect,
  Skeleton,
} from 'antd';
import React, { useState,useEffect, useMemo } from 'react';
import { useCities, useDistricts, useWards } from '~/hooks/geo';
import { UseCreateWareHouse,UseupdateWareHouse,useGetWareHouseById } from '~/hooks/listWareHouse';
import { useProductLists } from '~/hooks/productList';
import { filterAcrossAccents,  removeAccents } from '~/hooks/utils';
const ListWareHouseForm = ({
   id,
  handleCloseForm,
  isOpenForm,
}) => {
  const [form]= Form.useForm();
  const hanldActionFinish=()=>{
    handleCloseForm()
    form.resetFields()
  }
  const [selectedCityCode, setSelectedCityCode] = useState();
    const [selectedDistrictCode, setSelectedDistrictCode] = useState();
   const [isSubmitLoading, createWareHouse,,dataSelector] = UseCreateWareHouse();
   const [wareHouse,isLoading] = useGetWareHouseById(id)
  //  console.log(wareHouse,'wareHouseByid')
    const [cities, isCitiesLoading] = useCities();
    const [districts, isDistrictsLoading] = useDistricts(selectedCityCode);
    const [wards, isWardsLoading] = useWards(selectedDistrictCode);
   const [isSubmitLoadingUpdate, updateWareHouse] = UseupdateWareHouse()
  const [componentSize, setComponentSize] = useState('default');

  const query = useMemo(() => ({
    page: 1,
    limit: 10,
    warehouseId: id
  }), [id])
  const [products, isLoadingProducts] = useProductLists(query);
  useEffect(() => {
       if(wareHouse){
    const {code,name,address,description,status}=wareHouse;
    form.setFieldsValue({
    name,
    address:{
      cityId:address.cityId,
      districtId:address.districtId,
      wardId:address.wardId,
      street:address?.street
    },
     description:description,
     status:status
    });
   };
   setSelectedCityCode(wareHouse?.address?.cityId);
   setSelectedDistrictCode(wareHouse?.address?.districtId);
    
  },[wareHouse])
  useEffect(()=>{
    if (!id) form.resetFields()
      
    
  },[form,id])
  const { Option } = Select;
  const filterOption = (input, option, handleClose) =>(
    removeAccents(option.children.toLowerCase()).indexOf(input.toLowerCase()) >=
  0
  )
  const onFinish = (values) => {
    if (id) {
      updateWareHouse({ ...values, id });
      handleCloseForm();

    } else {
      createWareHouse(values);
      hanldActionFinish();
    
    }
  };
  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;
  return (
    <>
  
     <Form
    labelCol={{ sm: 24, md: 24, lg: 6 }}
    wrapperCol={{ sm: 24, md: 24, lg: 18 }}
      form={form}
      
      layout="horizontal"

      requiredMark={false}
      size={componentSize}
      onFinish={onFinish}
    >
      
      <Form.Item label="Mã kho" name="code"
         labelCol={{ sm: 24, md: 24, lg: 3 }}
         wrapperCol={{ sm: 24, md: 24, lg: 21 }}
      >
        <Input  disabled/>
      </Form.Item>
      <Form.Item label="Tên kho"
        name="name"
        labelCol={{ sm: 24, md: 24, lg: 3 }}
        wrapperCol={{ sm: 24, md: 24, lg: 21 }}
        rules={[{ required: true, message: 'Xin vui lòng nhập tên kho' }]}
      >{isLoading ? <Skeleton.Input active /> : (
        <Input/>  )}
      </Form.Item>
      <Row gutter={48}  align="middle" justify="space-between">
        <Col span={12}>
        <Form.Item label="Thành phố/Tỉnh"
      name={['address','cityId']}
      wrapperCol={{ sm: 24, md: 24, lg: 21 }}
      rules={[
        {
          required: true,
          message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
        }
      ]}
      >
       {isLoading||isCitiesLoading ? <Skeleton.Input active /> : (
         <Select
         onChange={setSelectedCityCode}
         disabled={isCitiesLoading}
         loading={isCitiesLoading}
         showSearch
         autoComplete="off"
         filterOption={filterAcrossAccents}
       >
         {cities.map(({ code, name }) => (
           <Option key={code} value={code}>
             {name}
           </Option> 
          ))}
       </Select>
     )}
       
      </Form.Item>
        </Col >
        <Col span={12}>
        <Form.Item label="Quận/huyện"
      name ={['address','districtId']}
      wrapperCol={{ sm: 24, md: 24, lg: 21 }}
      rules={[
        {
          required: true,
          message: 'Xin vui lòng chọn  Quận/Huyện!'
        }
      ]}
      >
        {isLoading||isDistrictsLoading ? <Skeleton.Input active /> : (
               <Select
               onChange={setSelectedDistrictCode}
              //  disabled={}
               loading={isDistrictsLoading}
               showSearch
               autoComplete="off"
               filterOption={filterAcrossAccents}
             >
               {districts.map(({ code, name }) => (
                 <Option key={code} value={code}>
                   {name}
                 </Option> 
                ))}
             </Select>
        )}
      </Form.Item>
        </Col>

      

      </Row>
      
      <Form.Item label="Phường/Xã"
      name ={['address','wardId']}
      labelCol={{ sm: 24, md: 24, lg: 3 }}
      wrapperCol={{ sm: 24, md: 24, lg: 21 }}
      rules={[
        {
          required: true,
          message: 'Xin vui lòng chọn Phường/Xã!'
        }
      ]}
      >
        {isLoading||isWardsLoading ? <Skeleton.Input active /> : (
               <Select
               disabled={isWardsLoading}
             
               loading={isWardsLoading}
               showSearch
               autoComplete="off"
               filterOption={filterAcrossAccents}
             >
               {wards.map(({ code, name }) => (
                 <Option key={code} value={code}>
                   {name}
                 </Option> 
                ))}
             </Select>
        )}
      </Form.Item>
      <Form.Item label="Địa chỉ" name={['address', 'street']}
         labelCol={{ sm: 24, md: 24, lg: 3 }}
         wrapperCol={{ sm: 24, md: 24, lg: 21 }}
         rules={[
          {
            required: true,
            message: 'Xin vui lòng chọn địa chỉ số nhà/ tên đường!'
          }
        ]}
      >
                {isLoading||isWardsLoading ? <Skeleton.Input active /> : <Input />}
              </Form.Item>
      <Form.Item label="Mô tả" name='description'
         labelCol={{ sm: 24, md: 24, lg: 3 }}
         wrapperCol={{ sm: 24, md: 24, lg: 21 }}
      >
        <Input />
      </Form.Item>
      
      {id ? (
        <Form.Item defaultValue={'ACTIVE'} name="status" label="Trạng thái">
        <Radio.Group>
          <Radio value="ACTIVE">Đang sử dụng</Radio>
          <Radio disabled ={products?.length > 0} value="INACTIVE">Ngưng sử dụng</Radio>
        </Radio.Group>
      </Form.Item>
      ):( '')}
      
      <Form.Item >
      <Row align='center' style={{ width: '976px'}}>
        {isSubmitLoading? (
          <Col span={4} align='center'>  <Button disabled>Huỷ</Button> </Col> 
           ):(
          <Col span={4} align='center'>    <Button   onClick={handleCloseForm}  > Huỷ</Button></Col>   
             )}
          <Col span={4} align='center'>
          <Button   loading={isSubmitLoading} htmlType="submit" type="primary"  >
              {id ? 'Cập nhật' : 'Thêm mới'}</Button>
          </Col>  
            </Row>
      </Form.Item>
    </Form>
    
    </>
   
  );
};
export default ListWareHouseForm;