import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getListSoftwaresFailed: null,
  getListSoftwareFailed: null,
  ListSoftwares: [],
  ListSoftware: null,
  isSubmitLoading: false,
  isGetListSoftwareLoading: false,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,

  createSuccess: null,
  createFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_LIST_SOFTWARES_REQUEST:
      state.isLoading = true;
      state.getListSoftwaresFailed = null;
      return;

    case Types.GET_LIST_SOFTWARES_SUCCESS:
      state.isLoading = false;
      state.ListSoftwares = get(payload, 'docs', []);
      state.getListSoftwaresFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_LIST_SOFTWARES_FAILED:
      state.isLoading = false;
      state.ListSoftwares = [];
      state.getListSoftwaresFailed = payload;
      return;
    case Types.GET_LIST_SOFTWARE_REQUEST:
      state.isGetListSoftwareLoading = true;
      state.ListSoftware = null
      state.getListSoftwareFailed = null;
      return;

    case Types.GET_LIST_SOFTWARE_SUCCESS:
      state.isGetListSoftwareLoading = false;
      state.ListSoftware = payload;
      state.getListSoftwareFailed = null;
      return;

    case Types.GET_LIST_SOFTWARE_FAILED:
      state.isGetListSoftwareLoading = false;
      state.ListSoftware = null;
      state.getListSoftwareFailed = payload;
      return;
    case Types.CREATE_LIST_SOFTWARE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_LIST_SOFTWARE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_LIST_SOFTWARE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_LIST_SOFTWARE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_LIST_SOFTWARE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.ListSoftwares = state.ListSoftwares.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.ListSoftware = payload;
      return;

    case Types.UPDATE_LIST_SOFTWARE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_LIST_SOFTWARE_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_LIST_SOFTWARE_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_LIST_SOFTWARE_ACTION:
      state.getListSoftwaresFailed = null;
      state.getListSoftwareFailed = null;
      state.ListSoftware = null;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      state.updateSuccess = null;
      state.updateFailed = null;
      state.createSuccess = null;
      state.createFailed = null;
      return ;

    case Types.RESET_STORE:
    case Types.RESET_LIST_SOFTWARE_STATE:
      return initState;

    default:
      return;
  }
}, initState);
