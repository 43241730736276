import { Table } from 'antd'
import { get } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { usePinedNavbar } from '~/hooks';
import { PATH_APP } from '~/routes/paths';

export default function TableStickyTop({ ...props }) {
    const [accessKeyPined] = usePinedNavbar();
    const history = useHistory();

    const [height, setHeight] = useState('150px');
    useEffect(() => {
        if (accessKeyPined && get(history, 'location.pathname') !== PATH_APP.main.home) {
            setHeight('205px');
        } else {
            setHeight('150px');
        }
    }, [accessKeyPined]);
    return (
        <div
            style={{
                height: `calc(100vh - ${height})`,
                overflow: "auto"
            }}
        >
            <Table
                {...props}
                sticky
                size="small"
            />
        </div>
    )
}
