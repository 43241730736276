import React, { useEffect, useState } from 'react';

import { Row, Space, Button, Card, Pagination, Empty } from 'antd';
import { find, get } from 'lodash';
import { Link, useHistory } from 'react-router-dom';

import { PATH_APP } from '~/routes/paths';
import FilterWhBillStatus from './Filter/FilterWhBillStatus';
import WhBillCardCompact from './WhBillCardCompact';
import WhBillConsole from './WhBillConsole';
import CustomerAndBillStatus from './CustomerAndBillStatus';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import {
  useCancelWhBill,
  useCancelWhBillStatus,
  useConfirmPrepaymentWhBill,
  useConfirmPrepaymentWhBillStatus,
  useCustomerCancelWhBillStatus,
  useUpdateWhBill,
  useUpdateWhBillParams,
  useWhBillPaging,
  useWhBills,
  useWhBillsQueryParams,
} from '~/hooks/whBill';

import './wh-bills-processing.scss';

const WhBillsProcessing = ({

}) => {
  const isMatchPolicy = useMatchPolicy(POLICY.WHUPDATEBILLSTATUS);

  const [selectedWhBill, setSelectedWhBill] = useState();
  const [query] = useWhBillsQueryParams({ processing: true });
  const [keyword, { setKeyword, onParamChange }] = useUpdateWhBillParams(query);
  const [whBills, isLoading] = useWhBills(query);
  const paging = useWhBillPaging();
  const [isSubmitLoading, updateWhBill] = useUpdateWhBill();

  const [isCancelBillLoading, handleCancelBill] = useCancelWhBill();
  // const [isConfirmPrepaymentLoading, handleConfirmPrepayment] = useConfirmPrepaymentWhBill();
  // const [isCustomerCancelWhBillLoading, handleCustomerCancel] = useCustomerCancelWhBill();

  const [cancelWhBillSuccess,] = useCancelWhBillStatus();
  const [confirmPrepaymentWhBillSuccess,] = useConfirmPrepaymentWhBillStatus();
  // const [customerCancelWhBillSuccess,] = useCustomerCancelWhBillStatus();

  const [isOpenConfirmPrepaymentModal, setIsOpenConfirmPrepaymentModal] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  // const [isOpenCustomerCancelWhBillModal, setIsOpenCustomerCancelWhBillModal] = useState(false);

  // in case we will allow admin confirm a random amount of prepayment,
  // there should be a modal with form using this `initPrepaymentValues`
  const [initPrepaymentValues, setInitPrepaymentValues] = useState({
    actualPrepaymentAmount: 0,
    // otherFields: null,
  });
  const [initCancelValues, setInitCancelValues] = useState({});

  useEffect(() => {
    const selectedId = get(selectedWhBill, "_id")
    if (selectedId) {
      const updatedSelectedWhBill = find(whBills, { _id: selectedId })
      updatedSelectedWhBill && setSelectedWhBill(updatedSelectedWhBill);
    }
  }, [whBills]);

  const onWhBillSelect = (whBill) => {
    setSelectedWhBill(whBill);
  }

  const onWhBillStatusChange = (value) => {
    console.log('whBill status change...');
    console.log(value);
  }

  const MyPagination = () => (
    <Pagination
      {...paging}
      onChange={(current, pageSize) => {
        onParamChange({ page: current, customPathName: "/wh-bill/processing" })
      }}
      showSizeChanger = {false}
      showTotal={(total) => `Tổng cộng: ${total} `}
      size="small"
      style={{ display: "flex", justifyContent: "center" }}
    />
  )

  return (
    <div className="page-wraper page-content wh-bills-processing-page">
      <div className="container-fluid">
        <div className="page-wraper__header">
          <Row justify='space-between'>
            <h3>LỊCH HẸN ĐANG XỬ LÝ</h3>
            <FilterWhBillStatus
              style={{ minWidth: "250px" }}
              showLabel={false}
              value={query.status}
              onChange={(value) => onParamChange({ status: value, customPathName: "/wh-bill/processing" })}
              showOnlyKeys={["NEW", "PREPAYMENT_CONFIRMED", "IN_PROGRESS"]}
            />
          </Row>
        </div>
        <div className="page-content__main">
          <div className="page-content__left">
            <Space direction='vertical' size="middle" style={{ width: "100%" }}>
              <MyPagination />
              {whBills.map((item, index) => (
                <WhBillCardCompact
                  isActive={get(selectedWhBill, "_id") === get(item, "_id")}
                  key={index}
                  whBill={item}
                  onClick={() => onWhBillSelect(item)}
                />
              ))}
              <MyPagination />
            </Space>
          </div>
          <div className="page-content__right">
            {!selectedWhBill 
              ? <Empty
                  description={(
                    <span>
                      Vui lòng chọn đơn hàng để xử lý
                    </span>
                  )}
                />
              : (
                <Card
                  title={
                    <Row align="middle" justify="space-between">
                      <p className="">
                        <strong>{
                          `${selectedWhBill?.billNumber} - ${get(selectedWhBill, 'customerAddress.fullName')}`
                        }</strong>
                      </p>
                      <CustomerAndBillStatus
                        whBill={selectedWhBill}
                        // editable={isMatchPolicy}
                        editable={false}
                        updateWhBill={updateWhBill}
                      />
                    </Row>
                  }
                >
                  <Space direction='vertical' style={{ width: "100%" }} size="large">
                    <WhBillConsole
                      cancelWhBillSuccess={cancelWhBillSuccess}
                      confirmPrepaymentWhBillSuccess={confirmPrepaymentWhBillSuccess}
                      // customerCancelWhBillSuccess={customerCancelWhBillSuccess}
                      editableStatus={isMatchPolicy}
                      handleCancelBill={handleCancelBill}
                      // isConfirmPrepaymentLoading={isConfirmPrepaymentLoading}
                      // handleConfirmPrepayment={handleConfirmPrepayment}
                      // handleCustomerCancel={handleCustomerCancel}
                      initCancelValues={initCancelValues}
                      initPrepaymentValues={initPrepaymentValues}
                      isCancelBillLoading={isCancelBillLoading}
                      // isCustomerCancelWhBillLoading={isCustomerCancelWhBillLoading}
                      isOpenCancelModal={isOpenCancelModal}
                      isOpenConfirmPrepaymentModal={isOpenConfirmPrepaymentModal}
                      // isOpenCustomerCancelWhBillModal={isOpenCustomerCancelWhBillModal}
                      isSubmitLoading={isSubmitLoading}
                      setIsOpenCancelModal={setIsOpenCancelModal}
                      setIsOpenConfirmPrepaymentModal={setIsOpenConfirmPrepaymentModal}
                      // setIsOpenCustomerCancelWhBillModal={setIsOpenCustomerCancelWhBillModal}
                      updateWhBill={updateWhBill}
                      whBill={selectedWhBill}
                    />
                    <Row justify='center'>
                      <Space>
                        {
                          (get(selectedWhBill, "prePay") && get(selectedWhBill, "status") === "NEW") && isMatchPolicy && (
                            <Button
                              onClick={() => {
                                setInitPrepaymentValues({
                                  prepaymentAmount: get(selectedWhBill, "prePayAmount"),
                                });
                                setIsOpenConfirmPrepaymentModal(true);
                              }}
                              // disabled={isConfirmPrepaymentLoading}
                            >
                              {get(selectedWhBill, 'isPrepayFull') ? "Đã nhận thanh toán" : "Đã nhận tạm ứng"}
                            </Button>
                          )
                        }

                        {
                          (get(selectedWhBill, "status") !== "CANCELLED") && isMatchPolicy && (
                            <Button
                              danger
                              onClick={() => {
                                setInitCancelValues(null);
                                setIsOpenCancelModal(true);
                              }}
                              disabled={isSubmitLoading}
                            >
                              Hủy
                            </Button>
                          )
                        }

                        {/* {isSubmitLoading ? (
                          <Button disabled>Đóng</Button>
                        ) : (
                          <Link to={PATH_APP.whBill.root}>
                            <Button>Đóng</Button>
                          </Link>
                        )} */}
                      </Space>
                    </Row>
                  </Space>
                </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhBillsProcessing;
