import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getRequestVouchers({ payload: query }) {
    try {
      const response = yield call(Api.requestVoucher.getAll, query);
      yield put({ type: Types.GET_REQUEST_VOUCHERS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_REQUEST_VOUCHERS_FAILED, payload: error });
    }
  }
function* getRequestVoucher({ payload: id }) {
    try {
      const response = yield call(Api.requestVoucher.getById, id);
      yield put({ type: Types.GET_REQUEST_VOUCHER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_REQUEST_VOUCHER_FAILED, payload: error });
    }
  }
  function* createRequestVoucher(action) {
    try {
      const data = yield call(Api.requestVoucher.create, action.payload);
      yield put({ type: Types.CREATE_REQUEST_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_REQUEST_VOUCHER_FAILED, payload: error });
    }
  }
  function* updateRequestVoucher(action) {
    try {
      const data = yield call(Api.requestVoucher.update, action.payload);
      yield put({ type: Types.UPDATE_REQUEST_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_REQUEST_VOUCHER_FAILED, payload: error });
    }
  }
  function* deleteRequestVoucher(action) {
    try {
      const data = yield call(Api.requestVoucher.delete, action.payload);
      yield put({ type: Types.DELETE_REQUEST_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_REQUEST_VOUCHER_FAILED, payload: error });
    }
  }
export default function* RequestVouchers() {
    yield takeLatest(Types.GET_REQUEST_VOUCHERS_REQUEST, getRequestVouchers);
    yield takeLatest(Types.GET_REQUEST_VOUCHER_REQUEST, getRequestVoucher);
    yield takeLatest(Types.CREATE_REQUEST_VOUCHER_REQUEST, createRequestVoucher);
    yield takeLatest(Types.UPDATE_REQUEST_VOUCHER_REQUEST, updateRequestVoucher);
    yield takeLatest(Types.DELETE_REQUEST_VOUCHER_REQUEST, deleteRequestVoucher);
  }
