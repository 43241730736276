import { FrownTwoTone, GiftTwoTone } from '@ant-design/icons'
import { get } from 'lodash'
import React from 'react'
import { getNameReward } from '~/hooks/processCampaign'
import { formatNumberThreeCommaToFixed } from '~/hooks/utils'

export default function RewardItem({process}) {
    const valueReward = get(process, 'rewardItem')[get(process, 'rewardItem.types')]
  return (
    <div className='process-configCampaign--name'>{valueReward > 0 ? <GiftTwoTone twoToneColor="#52c41a" /> : <FrownTwoTone twoToneColor="#eb2f96" />} : {formatNumberThreeCommaToFixed(valueReward)} {getNameReward(get(process, 'rewardItem', ''))}</div>
  )
}
