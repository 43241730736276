import React, { useState, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { get } from 'lodash';
import {
  useUserEmployees,
  useDeleteUserEmployee,
  useUserEmployeeQueryParams,
  useUserEmployeePaging,
  useUpdateUserEmployeeParams,
  useResetUserEmployee,
  useUser,
  useEmployeeGroups
} from '~/hooks';
import { Button, Form, Grid, Input, Modal, Popconfirm, Select, Table, Tag, TreeSelect } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy, useProfile } from '~/hooks';
import { CARD_STYLE, RELATIVE_POSITION } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import UserForm from './UserForm';
import { GeoTreeSelect } from '~/components/Common';
import { filterOptionSlug } from '~/utils/helper';

const { Search } = Input;
const { useBreakpoint } = Grid;

const ColumnActions = ({
  _id,
  deleteUserEmployee,
  shouldShowDevider,
  onOpenForm
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_USER}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      {shouldShowDevider && <p>|</p>}
      <WithPermission permission={POLICY.DELETE_USER}>
        <Popconfirm
          title="Bạn muốn xoá người dùng này?"
          onConfirm={() => deleteUserEmployee(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const UserEmployees = () => {
  const [form] = Form.useForm();
  const [, token] = useUser();
  const [profile] = useProfile(token);
  const [query, onPagingChange] = useUserEmployeeQueryParams();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateUserEmployeeParams(query);
  const [userEmployees, isLoading] = useUserEmployees(query);

  const branchIdParam = useMemo(() => ({}), []);

  const [groups, isLoadingGroup] = useEmployeeGroups(branchIdParam);
  const optionsGroups = useMemo(() => groups?.map(group => ({
    label : get(group, 'name',''),
    value : get(group, '_id',''),
  })),[groups]);
  const [userId, setUserEmployeeId] = useState(null);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const paging = useUserEmployeePaging();

  const hasWrite = useMatchPolicy(POLICY.UPDATE_USER);
  const hasDelete = useMatchPolicy(POLICY.DELETE_USER);
  const shouldShowDevider = hasWrite && hasDelete;

  const numberOfUserEmployees = userEmployees.length;

  const isRootAdmin = get(profile, 'isSuperAdmin') === true;
  const managementPaths = useMemo(() => {
    return get(profile, 'managementArea')?.map((item) => get(item, 'path'));
  }, [profile]);
  const managementAreaQuery = useMemo(() => {
    return JSON.parse(query.managementArea);
  }, [query]);

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfUserEmployees === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteUserEmployee] = useDeleteUserEmployee(onDeleteSuccess);

  useResetUserEmployee();

  const onOpenForm = (id) => {
    setUserEmployeeId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setUserEmployeeId(null);
    setIsOpenForm(false);
  };

  const onValuesChange = (values,allValues) => {
    const keyChange = Object.keys(values)[0];
    switch (keyChange) {
      case 'groupsIds':
        onParamChange({groupsIds: values[keyChange]?.join(',') });

        break;
      case 'managementArea':
        onParamChange({managementArea: JSON.stringify(values.managementArea) });

        break;
    
      default:
        break;
    }

  };

  const columns = [
    {
      title: 'Tên nhân viên',
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'username',
      key: 'username'
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },

    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },

    {
      title: 'Nhóm người dùng',
      dataIndex: 'groups',
      key: 'groups',
      render: (groups) => (
        <div className="speciality-tags">
          {groups.map((group, index) => {
            return (
              <Tag color="blue" key={index}>
                {group.name}
              </Tag>
            );
          })}
        </div>
      )
    },

    {
      title: 'Nhóm dịch vụ',
      dataIndex: 'groupServices',
      key: 'groupServices',
      render: (groupServices) => (
        <div className="speciality-tags">
          {groupServices?.map((groupService, index) => {
            return (
              <Tag color="blue" key={index}>
                {groupService.name}
              </Tag>
            );
          })}
        </div>
      )
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return (
          <ColumnActions
            {...record}
            deleteUserEmployee={deleteUserEmployee}
            shouldShowDevider={shouldShowDevider}
            onOpenForm={onOpenForm}
          />
        );
      }
    }
  ];

  return (
    <div className="page-content__main">
      <div className="page-content__left" style={CARD_STYLE}>
        <Form
          form={form}
          autoComplete="off"
          onFinish={() => {}}
          scrollToFirstError
          onValuesChange={onValuesChange}
          requiredMark={false}
          initialValues={{ managementArea: managementAreaQuery }}
        >
          <Form.Item
            label="Khu vực"
            name={['managementArea']}
            labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
          >
            <GeoTreeSelect
              autoClearSearchValue
              checkablePositions={[RELATIVE_POSITION.IS_CHILD]}
              enabledValues={isRootAdmin ? null : managementPaths}
              labelInValue={true}
              // listHeight={320}
              listItemHeight={200}
              multiple={true}
              onSelect={() => {}}
              showCheckedStrategy={TreeSelect.SHOW_ALL}
              showEnabledValuesOnly={true}
              showSearch={true}
              size="large"
              treeCheckStrictly={true}
              treeCheckable={true}
              treeDefaultExpandedKeys={['1', '2', '3']}
            />
          </Form.Item>
          <Form.Item
            label="Nhóm người dùng"
            name={['groupsIds']}
            labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
          >
          <Select 
            mode="multiple" 
            options={optionsGroups}
            showSearch
            filterOption={filterOptionSlug}
            allowClear
          />
          </Form.Item>
          {/* <FilterCity
          value={query.cityId}
          onChange={(value) => onParamChange({ cityId: value })}
          isDisabled={isLoading}
        /> */}
        </Form>
      </div>

      <div className="page-content__right" style={CARD_STYLE}>
        <div className="page-wraper__header">
          <Search
            style={{ width: 300 }}
            placeholder="Tìm người dùng"
            enterButton
            onSearch={() => onParamChange({ keyword : keyword?.trim()})}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

          <WithPermission permission={POLICY.WRITE_USER}>
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={() => onOpenForm()}
            >
              Thêm mới
            </Button>
          </WithPermission>
        </div>

        {isLoading ? (
          <SkeletonTable
            columns={columns}
            rowCount={userEmployees.length}
            pagination={paging}
          />
        ) : (
          <Table
            rowKey={(rc) => rc._id}
            columns={columns}
            dataSource={userEmployees}
            onChange={onPagingChange}
            pagination={{
              ...paging,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
            size="middle"
          />
        )}
      </div>
      <Modal
      visible={isOpenForm}
      onCancel={onCloseForm}
      destroyOnClose
      width={1020}
      footer={[]}
      className="form-modal"
      >
      <UserForm  onClose={onCloseForm} id={userId} />
      </Modal>
    </div>
  );
};

export default UserEmployees;
