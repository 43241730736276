import requester from './requester';

const infoCompany = {
  getAll: (query) => requester.get(`/info-company`, query),
  getById: (id) => requester.get(`/info-company/${id}?raw=true`),
  create: (infoCompany) => requester.post(`/info-company`, infoCompany),
  update: (infoCompany) => requester.put(`/info-company/${infoCompany._id}`, infoCompany),
  delete: (id) => requester.delete(`/info-company/${id}`)
};

export default infoCompany;
