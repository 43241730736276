import { get } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import { createWhReportMedicine, deleteWhReportMedicine, getWhReportMedicine, getWhReportMedicines, resetWhReportMedicineState, updateWhReportMedicine } from '~/redux/action';;
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.whReportMedicines[key];
const pagingSelector = getSelector('paging');
export const useWhReportMedicinePaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetWhReportMedicineSelector = getSelector('isGetWhReportMedicineLoading');
const getWhReportMedicinesFailedSelector = getSelector('getWhReportMedicinesFailed');
const getWhReportMedicineFailedSelector = getSelector('getWhReportMedicineFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const whReportMedicinesSelector = getSelector('whReportMedicines');
const whReportMedicineSelector = getSelector('whReportMedicine');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useWhReportMedicineQueryParams = (partnerId,date) => {
  const startDate = date?.startDate || null;
  const endDate = date?.endDate || null;
//   const createWhReportMedicineSuccess = useSelector(createSuccessSelector);
//   const updateWhReportMedicineSuccess = useSelector(updateSuccessSelector);
//   const deleteWhReportMedicineSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      startDate,
endDate,
partnerId
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    startDate,
endDate,
partnerId,
    // createWhReportMedicineSuccess,
    // updateWhReportMedicineSuccess,
    // deleteWhReportMedicineSuccess,
  ]);
};

// export const useUpdateWhReportMedicineParams = (query) => {
//   const history = useHistory();
//   const onParamChange = (param) => {
//     if (!param.page) {
//       query.page = 1;
//     }
//     history.push({
//       pathname: get(param, 'customPathName'),
//       search: new URLSearchParams(
//         getExistProp({
//           ...query,
//           ...param
//         })
//       ).toString()
//     });
//   };

//   return [{ onParamChange }];
// };
export const useWhReportMedicines = (query) => {
  return useFetchByParam({
    action: getWhReportMedicines,
    loadingSelector,
    dataSelector: whReportMedicinesSelector,
    failedSelector: getWhReportMedicinesFailedSelector,
    param: query
  });
};
export const useWhReportMedicine = (id) => {
  return useFetchByParam({
    action: getWhReportMedicine,
    loadingSelector: loadinggetWhReportMedicineSelector,
    dataSelector: whReportMedicineSelector,
    failedSelector: getWhReportMedicineFailedSelector,
    param: id
  });
};
export const useCreateWhReportMedicine = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo Thu nhập thiết bị y tế thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhReportMedicine
  });
};
export const useUpdateWhReportMedicine = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật Thu nhập thiết bị y tế thành công',
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhReportMedicine
  });
};
export const useDeleteWhReportMedicine = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Thu nhập thiết bị y tế thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá Thu nhập thiết bị y tế thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteWhReportMedicine
  });
};

export const useResetWhReportMedicine = () => {
  useResetState(resetWhReportMedicineState);
};
