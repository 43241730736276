
import { Form, Input, Radio, Row, Switch } from 'antd';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { LANGUAGE } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useUiClients, useUpdateUiClient } from '~/hooks/UiClient';
import { handleSubmit } from '~/hooks/utils';
import { Breadcrumb } from '../Common';
import Editor from '../Utils/Editor';
import LoadingWhBill from '../WhBill/LoadingWhBill';
import BtnUpdate from './BtnUpdate';
const key = 'softwareBenefit';
const SoftwareBenefit = () => {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_BANNER);
    const [form] = Form.useForm();
    const [UiClient, isLoading] = useUiClients(key);
    const [isSubmitLoading, update] = useUpdateUiClient();
    const initValue = useMemo(() => get(UiClient, ['value', key]), [UiClient])
    const disabled = useMemo(() => !canUpdate, [canUpdate]);
    const onSubmit = handleSubmit()
    const onFinish = (values) => {
        update({ key, ...values })
    };
    const [language, setLanguage] = useState(LANGUAGE.VI);

    useEffect(() => {
        form.setFieldsValue({
            ...initValue
        })
    }, [initValue]);

    return (
        <div className="wh-service-form page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb title={'Lợi ích khi sử dụng phần mềm'} routes={[{ path: '', title: 'CMS' }]} />
                {isLoading && <LoadingWhBill open={true} />}
                <Form
                    form={form}
                    autoComplete="off"
                    initialValues={{}}
                    scrollToFirstError
                    requiredMark={false}
                    onFinish={onFinish}
                    onFinishFailed={onSubmit}
                    labelCol={{ sm: 24, md: 24, lg: 2 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 22 }}
                >
                    <Row justify="end">
                        <Radio.Group
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.VI}
                            >
                                VI
                            </Radio.Button>
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.EN}
                            >
                                EN
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                    <Form.Item label="Hiển thị" valuePropName="checked" name={['active']} >
                        <Switch disabled={disabled} />
                    </Form.Item>
                    <Form.Item hidden={language === LANGUAGE.EN} name={["title", LANGUAGE.VI]} label="Tiêu đề VI">
                        <Input disabled={disabled} placeholder='Nhập Tiêu đề' />
                    </Form.Item>
                    <Form.Item hidden={language === LANGUAGE.VI} name={["title", LANGUAGE.EN]} label="Tiêu đề EN">
                        <Input disabled={disabled} placeholder='Nhập Tiêu đề' />
                    </Form.Item>
                    <Form.Item hidden={language === LANGUAGE.EN} name={["content", LANGUAGE.VI]} label="Nội dung VI">
                        <Editor disabled={disabled} />
                    </Form.Item>
                    <Form.Item hidden={language === LANGUAGE.VI} name={["content", LANGUAGE.EN]} label="Nội dung EN">
                        <Editor disabled={disabled} />
                    </Form.Item>

                    <BtnUpdate loading={isSubmitLoading} />
                </Form>
            </div>
        </div>
    );
};
export default SoftwareBenefit;
