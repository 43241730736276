import {put,call,takeLatest}   from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getWareHouseList({payload:query}) {
    try {
        const data = yield call(Api.listWareHouse.getAll, query);
        yield put({ type: Types.GET_WAREHOUSE_LISTS_SUCCESS, payload: data });
    } catch (error) {
        yield put({
            type: Types.GET_WAREHOUSE_LISTS_FAILED,
            payload: error.message
        });
    }
}
function* getWareHouseById({payload}) {

    try {
        const data = yield call(Api.listWareHouse.getById, payload);
      
        yield put({ type: Types.GET_WAREHOUSE_BYID_SUCCESS, payload: data });
    } catch (error) {
        yield put({
            type: Types.GET_WAREHOUSE_BYID_FAILED,
            payload: error.message
        })
    }
}

function* getProductListByWareHouseId({payload}) {
    
    try {
        const data = yield call(Api.listWareHouse.getProductListByWareHouseId, payload);
        console.log(data,'data wareHouse product')
        yield put({type: Types.GET_WAREHOUSE_PRODUCTLIST_SUCCESS, payload: data });
    } catch (error) {
        yield put({
            type: Types.GET_WAREHOUSE_PRODUCTLIST_FAILED,
            payload: error
        })
    }
}
//create
function* createWareHouse({payload}) {
    try {
        yield  call(Api.listWareHouse.create, payload);
      
        yield put({ type: Types.CREATE_WAREHOUSE_SUCCESS, payload });
    } catch (error) {
        yield put({
            type: Types.CREATE_WAREHOUSE_FAILED,
            payload: error
        })
    }
}
//update
function* updateWareHouse({payload}) {
    try {
     const data=   yield call(Api.listWareHouse.update, payload);
        yield put({ type: Types.UPDATE_WAREHOUSE_SUCCESS, payload:data });
    } catch (error) {
        yield put({
            type: Types.UPDATE_WAREHOUSE_FAILED,
            payload: error
        })
    }
}

function* deleteWareHouse({payload:id}) {
    try {
        const data = yield call(Api.listWareHouse.delete, id);
        yield put({ type: Types.DELETE_WAREHOUSE_SUCCESS, payload: data });
    } catch (error) {
        yield put({
            type: Types.DELETE_WAREHOUSE_FAILED,
            payload: error.message
        })
    }
}

export default function* watchWareHouseList() {
    yield takeLatest(Types.GET_WAREHOUSE_LISTS_REQUEST, getWareHouseList);
    yield takeLatest(Types.GET_WAREHOUSE_BYID_REQUEST, getWareHouseById);
    yield takeLatest(Types.DELETE_WAREHOUSE_REQUEST, deleteWareHouse);
    yield takeLatest(Types.UPDATE_WAREHOUSE_REQUEST, updateWareHouse);
    yield takeLatest(Types.CREATE_WAREHOUSE_REQUEST, createWareHouse);
}
