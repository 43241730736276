import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getWhCategories({ payload: query }) {
  try {
    const data = yield call(Api.whCategory.getAll, query);
    yield put({ type: Types.GET_WH_CATEGORIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_CATEGORIES_FAILED,
      payload: error.message
    });
  }
}
function* getWhCategoriesList({ payload: query }) {
  try {
    const data = yield call(Api.whCategory.getAllCategory, query);
    yield put({ type: Types.GET_WH_CATEGORIES_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_CATEGORIES_LIST_FAILED,
      payload: error.message
    });
  }
}
function* getWhCategory({ payload: id }) {
  try {
    const whCategory = yield call(Api.whCategory.getById, id);
    yield put({ type: Types.GET_WH_CATEGORY_SUCCESS, payload: whCategory });
  } catch (error) {
    yield put({ type: Types.GET_WH_CATEGORY_FAILED, payload: error.message });
  }
}

function* createWhCategory(action) {
  try {
    const data = yield call(Api.whCategory.create, action.payload);
    yield put({ type: Types.CREATE_WH_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_WH_CATEGORY_FAILED,
      payload: error.message
    });
  }
}

function* updateWhCategory(action) {
  try {
    const data = yield call(Api.whCategory.update, action.payload);
    yield put({ type: Types.UPDATE_WH_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_CATEGORY_FAILED,
      payload: error.message
    });
  }
}

function* deleteWhCategory({ payload }) {
  try {
    yield call(Api.whCategory.delete, payload);
    yield put({ type: Types.DELETE_WH_CATEGORY_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_CATEGORY_FAILED,
      payload: error.message
    });
  }
}

export default function* whCategory() {
  yield takeLatest(Types.GET_WH_CATEGORIES_REQUEST, getWhCategories);
  yield takeLatest(Types.GET_WH_CATEGORY_REQUEST, getWhCategory);
  yield takeLatest(Types.DELETE_WH_CATEGORY_REQUEST, deleteWhCategory);
  yield takeLatest(Types.CREATE_WH_CATEGORY_REQUEST, createWhCategory);
  yield takeLatest(Types.UPDATE_WH_CATEGORY_REQUEST, updateWhCategory);
  yield takeLatest(Types.GET_WH_CATEGORIES_LIST_REQUEST, getWhCategoriesList);
}
