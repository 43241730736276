import React from 'react';
import { useMatchPolicy } from '~/hooks';

const WithPermissionDisabled = ({ children, permission }) => {
  const isMatchPolicy = useMatchPolicy(permission);
  if (!Array.isArray(children)) 
      {children=[children]}
  return (
    <>
      {
        children.map(child => {
        if (React.isValidElement(child) ) {
          return React.cloneElement(child, { disabled: !isMatchPolicy });
        }
        return child;
      })
      }
    </>
  );
};

export default WithPermissionDisabled;