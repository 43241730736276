import { TreeSelect } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { useWarehouseLists } from '~/hooks/listWareHouse';
const { SHOW_PARENT, SHOW_CHILD, SHOW_ALL } = TreeSelect;
export default function GeoTreeSelectWarehouse({
  warehouseControlSelect,
  branchId,
  setWarehouseControlSelect
}) {
  const isRoot = 'admin'; // This is a declaration in case the user wants to create an account for an object with the right to manage all warehouses
  
  const [value, setValue] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const queryWareHouse = useMemo(
    () => ({
      page: 1,
      limit: MAX_PAGINATION_LIMIT,
      branchIds: branchId
    }),
    [branchId]
  );
  const [warehouseList, isLoadingWareHouse] = useWarehouseLists(queryWareHouse);
  const treeData = [
    {
      title: "Tất cả",
      key: "all",
      value: isRoot,
      children: warehouseList.map(warehouse => ({
        title: warehouse.name,
        key: warehouse._id,
        value: warehouse._id,
        disabled: isDisabled // disabled children if value includes isRoot
      }))
    }
  ];
  /**
   *Function handle change value select.
   *
   * @param {Array} newValue - description of parameter
   */
  const onChange = (newValue) => {
    if (newValue.includes(isRoot)) {  // if value includes isRoot
      setIsDisabled(true);
      setValue([isRoot]);
      setWarehouseControlSelect([isRoot]);
    } else if (newValue.length === 0) {  // if value is empty
      setValue([]);
      setIsDisabled(false);
    } else {
      setWarehouseControlSelect(newValue);
      setValue(newValue);
    }
  };

  useEffect(() => {
    warehouseControlSelect?.length && setValue(warehouseControlSelect); // then id is exits
  }, [warehouseControlSelect]);

  return (
    <TreeSelect
      showSearch
      style={{
        width: '100%'
      }}
      value={value}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto'
      }}
      treeCheckable={true}
      showCheckedStrategy={{ SHOW_CHILD }}
      placeholder="Chọn kho"
      allowClear
      multiple
      treeDefaultExpandAll
      onChange={onChange}
      treeData={treeData}
      filterTreeNode
    />
  );
}
