import requester from './requester';

const configWhPartnerRegisterTypeForm = {
  getAll: (query) => requester.get('/configWhPartnerRegisterTypeForm', query),
  getById: (id) => requester.get(`/configWhPartnerRegisterTypeForm/${id}`),
  create: (configWhPartnerRegisterTypeForm) => requester.post('/configWhPartnerRegisterTypeForm', configWhPartnerRegisterTypeForm),
  update: (configWhPartnerRegisterTypeForm) => requester.put(`/configWhPartnerRegisterTypeForm/${configWhPartnerRegisterTypeForm._id}`, configWhPartnerRegisterTypeForm),
  delete: (id) => requester.delete(`/configWhPartnerRegisterTypeForm/${id}`)
};

export default configWhPartnerRegisterTypeForm;
