import { Button, Col, Empty, Modal, Row, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { get, head } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { UNIT_PROVIDE, WAREHOUSE_STATUS } from '~/constants/defaultValue';
import { useProfile } from '~/hooks';
import { formatNumberThreeCommaToFixed } from '~/hooks/utils';
import { handlePreBooking, useGetEquipment } from '~/hooks/wareHouse';
import useAssignWarehouseAppointmentStore from '~/storeContext/AssignWarehouseWhAppointment';
import ConfirmPreBooking from './ConfirmPreBooking';
export default function FindEquiment({ id, setDataSource, onCancel, whAppointmentId, dataSource }) {
  const {dataSourceWhBillItem} = useAssignWarehouseAppointmentStore();
  const [profile] = useProfile();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modePreBooking, setModePreBooking] = useState(false);
  const [submitDataPreBooking, setSubmitDataPreBooking] = useState(null);
  const [isVisibleConfirmPreBooking, setIsVisibleConfirmPreBooking] = useState(false);
  const { setKeyword, loading, data, selectSearch, setSelectSearch, listOptionSearch } = useGetEquipment(id, modePreBooking, dataSourceWhBillItem,whAppointmentId);
  const handleOpenModePreBooking = () => {
    setModePreBooking(true);
  }
  const onCloseConfirmPreBooking = () => {
    setIsVisibleConfirmPreBooking(false);
    setSubmitDataPreBooking(null);
    setModePreBooking(false);
  }
  const onOpenConfirmPreBooking = (data) => {
    setIsVisibleConfirmPreBooking(true);
    setSubmitDataPreBooking(data);
  }
  const callbackHandleSave = (newData) => {
    setDataSource(newData, 'preBooking');
    onCloseConfirmPreBooking();
  }

  const handleSave = () => {
    if (modePreBooking) {
      const productSelect = head(selectedRows)
      const submitData = {
        productId: get(productSelect, '_id'),
        whAppointmentId,
        employeeId: get(profile, '_id'),
        groupEquipmentId: get(productSelect, 'groupProductId'),
        medicalEquipmentTypeId: get(productSelect, 'groupProduct.medicalEquipmentId')
      }
      onOpenConfirmPreBooking(submitData)
    }
    else {
      setDataSource(head(selectedRows))
    }
  }

  const columns = [
    {
      title: 'Mã định danh sản phẩm',
      align: 'start',
      dataIndex: 'code',
      key: 'code',
      render: (value, record) => {
        return value
      }
    },
    {
      title: 'Tên sản phẩm',
      align: 'start',
      dataIndex: 'name',
      key: 'name',
      // render: (value) => get(value, 'vi')
    },
    {
      title: 'Nhóm sản phẩm',
      align: 'start',
      dataIndex: 'groupProduct',
      key: 'groupProduct',
      render: (value) => get(value, 'name.vi', '')
    },
    {
      title: 'Hãng sản xuất',
      align: 'start',
      dataIndex: 'manufacturer',
      key: 'manufacturer'
    },
    {
      title: 'Đơn vị cung cấp',
      align: 'start',
      dataIndex: 'supplierId',
      key: 'supplierId',
      render: (value, record) => value === UNIT_PROVIDE.worldhealth ? value : get(record, 'supplierInfo.name', '')
    },
    {
      title: 'Ngày sản xuất',
      align: 'start',
      dataIndex: 'dateManufacturer',
      key: 'dateManufacturer',
      render: (value) => moment(value).format("DD-MM-YYYY HH:mm:ss")
    },
    {
      title: 'Số lần khấu hao',
      align: 'start',
      dataIndex: 'countUsing',
      key: 'countUsing',
      render: (value) => formatNumberThreeCommaToFixed(value)
    },
    {
      title: 'Giá trị khấu hao',
      align: 'start',
      dataIndex: 'valueDepreciation',
      key: 'valueDepreciation',
      render: (value) => formatNumberThreeCommaToFixed(value)
    },
    {
      title: 'Đơn vị khấu hao',
      align: 'start',
      dataIndex: 'unitDepreciation',
      key: 'unitDepreciation'
    },
    {
      title: 'Số lần sử dụng',
      align: 'start',
      dataIndex: 'countUsing',
      key: 'countUsing',
      render: (value) => formatNumberThreeCommaToFixed(value)
    },
    {
      title: 'Trạng thái',
      align: 'start',
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        return (
          <span style={{ color: WAREHOUSE_STATUS[value]?.colorStyle }}>
            {WAREHOUSE_STATUS[value]?.name}
          </span>
        );
      }
    }
  ];
  const emptyText = () => {
    const findGroupEquipment = dataSource?.find(item => get(item, 'groupEquipmentId._id') === id)
    const canPreBooking = !!!get(findGroupEquipment, 'preBooking')
    return (
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 60,
        }}
        description={
          canPreBooking ? <span>
            Hiện tại không có máy nào sẵn sàng, bạn có muốn đặt trước máy?
          </span> :
            <span>
              Hiện tại không có máy nào sẵn sàng.
            </span>

        }
      >
        {canPreBooking && <Button onClick={handleOpenModePreBooking} type='primary'>Đặt trước máy</Button>}
      </Empty>
    )
  }
  return (
    <>
      <Row justify='end'>
        <Col span={24}>
          <div style={{marginBottom : 5}}>
            <Select
              value={selectSearch}
              style={{
                width: 170
              }}
              onChange={(value) => setSelectSearch(value)}
              options={listOptionSearch}
            />
            <Search
              style={{ width: 300 }}
              placeholder={`Tìm ${listOptionSearch.find((e) => e.value === selectSearch).label
                }`}
              enterButton
              allowClear
              onSearch={async (value) => setKeyword(value)
              }
            />
          </div>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: 2000 }}
            title={() => `Danh sách sản phẩm`}
            columns={columns}
            dataSource={data || []}
            pagination={false}
            rowKey={(rc) => rc._id}
            size="middle"
            loading={loading}
            rowSelection={{
              type: 'radio',
              selectedRowKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
            locale={{
              emptyText: emptyText
            }}
          />
        </Col>
        <Row>
          <Button onClick={() => {
            onCancel()
            onCloseConfirmPreBooking()
          }} style={{ marginTop: 20 }} >Huỷ</Button>
          <Button onClick={handleSave} style={{ marginTop: 20 }} type='primary'>Xác nhận</Button>
        </Row>
      </Row>
      <Modal title={"Xác nhận đặt trước Công cụ dụng cụ"} width={'auto'} destroyOnClose visible={isVisibleConfirmPreBooking} onCancel={onCloseConfirmPreBooking} closable={false} onOk={() => {
        handlePreBooking(submitDataPreBooking, callbackHandleSave)
      }}>
        <ConfirmPreBooking whAppointmentId={whAppointmentId} productId={get(submitDataPreBooking, 'productId')} />
      </Modal>
    </>
  )
}
