import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import {
  GET_DELIVERY_VOUCHERS_REQUEST,
  GET_DELIVERY_VOUCHERS_SUCCESS,
  GET_DELIVERY_VOUCHERS_FAILED,
  GET_DELIVERY_VOUCHER_REQUEST,
  GET_DELIVERY_VOUCHER_SUCCESS,
  GET_DELIVERY_VOUCHER_FAILED,
  CREATE_DELIVERY_VOUCHER_REQUEST,
  CREATE_DELIVERY_VOUCHER_SUCCESS,
  CREATE_DELIVERY_VOUCHER_FAILED,
  UPDATE_DELIVERY_VOUCHER_REQUEST,
  UPDATE_DELIVERY_VOUCHER_SUCCESS,
  UPDATE_DELIVERY_VOUCHER_FAILED,
  DELETE_DELIVERY_VOUCHER_REQUEST,
  DELETE_DELIVERY_VOUCHER_SUCCESS,
  DELETE_DELIVERY_VOUCHER_FAILED
} from '~/constants/actionTypes';

function* getDeliveryVouchers({ payload: query }) {
  try {
    const response = yield call(Api.deliveryVoucher.getAll, query);
    yield put({ type: GET_DELIVERY_VOUCHERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_DELIVERY_VOUCHERS_FAILED, payload: error.message });
  }
}

function* getDeliveryVoucher({ payload: id }) {
  try {
    const data = yield call(Api.deliveryVoucher.getById, id);
    yield put({ type: GET_DELIVERY_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_DELIVERY_VOUCHER_FAILED, payload: error.message });
  }
}

function* createDeliveryVoucher(action) {
  try {
    const data = yield call(Api.deliveryVoucher.create, action.payload);
    yield put({ type: CREATE_DELIVERY_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_DELIVERY_VOUCHER_FAILED, payload: error.message });
  }
}

function* updateDeliveryVoucher(action) {
  try {
    const data = yield call(Api.deliveryVoucher.update, action.payload);
    yield put({ type: UPDATE_DELIVERY_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_DELIVERY_VOUCHER_FAILED, payload: error.message });
  }
}

function* deleteDeliveryVoucher({ payload }) {
  try {
    yield call(Api.deliveryVoucher.update, payload);
    yield put({ type: DELETE_DELIVERY_VOUCHER_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_DELIVERY_VOUCHER_FAILED, payload: error.message });
  }
}

export default function* deliveryVoucher() {
  yield takeLatest(GET_DELIVERY_VOUCHERS_REQUEST, getDeliveryVouchers);
  yield takeLatest(GET_DELIVERY_VOUCHER_REQUEST, getDeliveryVoucher);
  yield takeLatest(DELETE_DELIVERY_VOUCHER_REQUEST, deleteDeliveryVoucher);
  yield takeLatest(CREATE_DELIVERY_VOUCHER_REQUEST, createDeliveryVoucher);
  yield takeLatest(UPDATE_DELIVERY_VOUCHER_REQUEST, updateDeliveryVoucher);
}
