import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext
} from 'react';

import { notification, Select, Spin } from 'antd';
import { debounce, get, isObject, set, transform } from 'lodash';
import { connect } from 'echarts';
import { filterAcrossAccentsService, ToastrCustom } from '~/hooks/utils';
import toastr from 'toastr'
const DebounceSelectService = (
  {
    fetchOptions,
    debounceTimeout = 500,
    labelKey = 'name',
    valueKey = 'id',
    searchKey = 'keyword',
    initOptions,
    actionDispatch,
    reload,
    value,
    dispatchImport,
    dispatchUpdate,
    createService,
    updateService,
    idAfterSlice,
    isLoadingSelect,
    serviceExits,
    ...props
  },
  ref
) => {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueServiceBefore, setValueServiceBefore] = React.useState(null);
  const fetchRef = React.useRef(0);
  const [isServiceExits, setIsServiceIsExits] = useState(false);
  const [inputSelect, setInputSelect] = useState('');

  useEffect(() => {
    if (initOptions && props.value) {
      const newOptions = initOptions.map((option) => ({
        label: get(option, [labelKey, 'vi']),
        value: get(option, valueKey)
      }));
      setOptions(newOptions);
    }
  }, [initOptions]);

  const transformValue = (value) => {
    if (!isObject(value) && initOptions) {
      const currentOption = initOptions.find(
        (item) => get(item, valueKey) === value
      );
      if (currentOption)
        return {
          value,
          label: currentOption.description
            ? `${ get(currentOption, "code","") } - ${get(currentOption, labelKey)} `  : get(currentOption, labelKey)
        };
      return value;
    }
    return value;
  };
  // Fetch API select
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions().then((response) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        const newOptions = response.map((option) => {
          const showOption =  {label: option.detailName
          ? `${get(option, "code")} - ${get(option, labelKey)}${get(option, 'detailName.vi') ?` (${get(option,'detailName.vi')})`: '' }`
          : `${get(option, "code")} - ${get(option, labelKey)}`,
            value: get(option, valueKey) }
          return {
            ...option,
            ...showOption
          };
        });
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  // Handle Post Service By ID
  const handlePostService = dispatchImport
    ? (e) => {
        const param = {
          id: idAfterSlice,
          serviceId: e.value
        };
        if (!!!valueServiceBefore) {
          createService(param);
        } else {
          const data = {
            id: idAfterSlice,
            param: {
              serviceId: valueServiceBefore.value ?? valueServiceBefore,
              serviceIdNew: e.value
            }
          };
          updateService(data);
        }
      }
    : () => { };
  
  const loadingFeatch = () => {
    let obj = []
    if (isLoadingSelect) {
      return obj = [{
        label: "Loading...",
        value: 0,
        disabled: true,
      }]
    } else if (!isLoadingSelect && options.length === 0) {
        return obj = [{
          label: "Không tồn tại dịch vụ này !",
          value: 0,
          disabled: true,
        }]
    } else if (!isLoadingSelect && options.length > 0) {
      return obj = options
    }
    return obj
  };
  
//   // useEffect(() => {
//   //   const check =  serviceExits?.find((item) => {
//   //     const test = filterAcrossAccentsService(inputSelect, item)
//   //     return test
//   //   });
//   //   if (!!check && options?.length) {
//   //     toastr.error('Dịch vụ đã tồn tại');
//   //   }
//   // },[inputSelect]);
//   const checkIsExits = (input, option) => {
//     const data = filterAcrossAccentsService(input, option);
//     const check =  serviceExits?.find((item) => {
//         const test = filterAcrossAccentsService(input, item)
//       return test
//     });
//     //   if (!!check && !data) {
//     //   ToastrCustom('Dịch vụ đã tồn tại','error');
//     // }
// }
  
  return (
    <Select
      ref={ref}
      showSearch
      labelInValue
      onSelect={(e, index) => {
        handlePostService(e, index);
      }}
      filterOption={(input, option) => {
        // checkIsExits(input);
        return filterAcrossAccentsService(input, option);
      }}
      onFocus={() => {
        setValueServiceBefore(value);
        debounceFetcher();
      }}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      value={transformValue(value)}
      options={loadingFeatch()}
    />
  );
};

export default forwardRef(DebounceSelectService);
