import { Button, Col, Form, Input, Row, Select, Tag } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { v4 } from 'uuid';
import api from '~/api';
import LoadingWhBill from '../WhBill/LoadingWhBill';
import toastr from 'toastr'

const FormItem = Form.Item;
function FormCreateCourse(
  { createTrainingCourse, isLoading, updateTrainingCourse,trainingCourse,id, onCancel,isloadingGetTrainingCourse },
  ref
) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [listPartnerSelect, setListPartnerSelect] = useState([])
  const [data, setData] = useState({
    partner: [],
    employees: []
  });
  const deleteTag = async(e,value) => {
    e.preventDefault();
    const res = await api.TrainingCourse.checkPartnerHasCourse({partnerId : value,courseId : trainingCourse._id})
    if(res.status){
      toastr.error("Partner này đã đăng ký khoá này , không được xoá")
    }
    else{
      setListPartnerSelect(listPartnerSelect?.filter(item => item.value !== value))
    }
  }
  const resetForm = () => {
    form.resetFields();
    setListPartnerSelect([])
  };
  useImperativeHandle(ref, () => ({
    resetForm
  }));
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      Promise.all([
        api.TrainingCourse.getAllPartner(),
        api.TrainingCourse.getAllEmployees()
      ]).then((res) => {
        setData({
          partner: res[0],
          employees: res[1]
        });
        setLoading(false);
      });
    };
    fetch();
  }, []);
  useEffect(() => {
    form.resetFields()
    if (id) {
      setListPartnerSelect(trainingCourse?.partners?.map(e => ({
        value : e.partnerId._id,
        label : e.partnerId.name,
      })))
      form.setFieldsValue({
        responsibleUser: trainingCourse?.responsibleUser || ''
      });
    }
  }, [id,trainingCourse]);

  const onFinish = (values) => {
    if(listPartnerSelect.length === 0){
      toastr.error("Vui lòng chọn đối tác")
    }
    else{
      if (id) {
        updateTrainingCourse({ ...values, _id: trainingCourse._id,partners : listPartnerSelect?.map(e => ({partnerId : e.value})) });
      } else {
        createTrainingCourse({...values,partners : listPartnerSelect?.map(e => ({partnerId : e.value}))});
      }
    }
  };

  const handleChangeResponsibility = (value) => {
    console.log(value);
  };
  const undertakePartner = (value,{label}) => {
    if(listPartnerSelect.find(e => e.value === value)){
      toastr.error("Đối tác này đã tồn tại")
    }
    else{

      setListPartnerSelect([...listPartnerSelect,{value,label}])
    }
  };
  return (
    <>
      <Form
        labelCol={{ sm: 10, md: 10, lg: 10, xl: 10 }}
        wrapperCol={{ sm: 14, md: 14, lg: 14, xl: 14 }}
        form={form}
        onFinish={onFinish}
      >
      <FormItem
          label="Các đối tác đảm nhận"
          // name='listPartnerSelect'
          rules={[
            {
              required: true,
              message: 'Xin vui lòng chọn đối tác đảm nhận'
            }
          ]}
        >
          {listPartnerSelect?.map(item => <Tag key={v4()} color="blue" closable onClose={(e) => deleteTag(e,item?.value)}>{item.label}</Tag>)}
        </FormItem>
        <FormItem
          label="Đối tác đảm nhận"
          name="selectPartner"
         

        >
          <Select
          loading={isloadingGetTrainingCourse}
            onChange={undertakePartner}
            showSearch
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={data?.partner?.map((e) => ({
              label: e.name,
              value: e._id
            }))}
          />
        </FormItem>
        <FormItem
          label="Người chịu trách nhiệm"
          name="responsibleUser"
          rules={[
            {
              required: true,
              message: 'Xin vui lòng chọn người chịu trách nhiệm'
            }
          ]}
        >
          <Select
          loading={isloadingGetTrainingCourse}
            onChange={handleChangeResponsibility}
            showSearch
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={data?.employees?.map((e) => ({
              label: e.fullName,
              value: e._id
            }))}
          />
        </FormItem>
        <Row style={{ width: '50%', margin: '0 auto' }}>
          <Col span={12}>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              {id ? "Cập nhật" : "Tạo mới"}
            </Button>
          </Col>
          <Col span={12}>
            <Button onClick={onCancel}>Huỷ</Button>
          </Col>
        </Row>
      </Form>
      <LoadingWhBill open={loading} />
    </>
  );
}
export default forwardRef(FormCreateCourse);
