import React, { forwardRef, useState } from 'react';

import { InputNumber } from "antd";

import { humanize } from '~/utils/helper';

const DurationInputNumber = ({
  value,
  onChange,
  duration,
  timeUnit,
  ...restProps
}, ref) => {
  const [addonValue, setAddonValue] = useState(value);

  const onInputNumberChange = (value) => {
    setAddonValue(value);
    onChange(value);
  };

  const AddonAfter = ({ value }) => {
    return (
      <>
        lần x {duration} {timeUnit} ={" "}
        {humanize(timeUnit, value * duration)}
      </>
    );
  };

  return (
    <>
      <InputNumber
        {...restProps}
        ref={ref}
        value={value}
        onChange={onInputNumberChange}
        // addonAfter={<AddonAfter value={addonValue} />}
      />
      <AddonAfter value={addonValue} />
    </>
  );
};

const DurationInputNumberWrapper = forwardRef(DurationInputNumber)

export default DurationInputNumberWrapper;
