import {
  AlertOutlined,
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  DollarOutlined,
  DownOutlined,
  WalletTwoTone
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Menu,
  Modal,
  Row,
  Space,
  Statistic,
  Table,
  Tabs
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { get, head, keys } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import CountUp from 'react-countup';
import {
  MAX_PAGINATION_LIMIT, WALLET_TYPE,
} from '~/constants/defaultValue';
import { useUserWorkspace } from '~/hooks';
import {
  useGetMyPointCanUse,
  usePointSystemPartnerQueryParams,
  usePointSystemsPartner,
} from '~/hooks/pointSystem';
import InfoPartner from './WhPartner/InfoPartner';
import './WorkSpaceTimeTrain.scss';

import { paymentWallet } from '~/common/data/chat';
import ChartLine from '../Utils/ChartLine';
import { useExchangePoints } from '~/hooks/exchangePoint';
import { useWalletParnter, useWallets } from '~/hooks/wallet';
import ExchangePointRefund from './wallet/ExchangePointRefund';
import './wallet.scss'
import WithDrawForm from './wallet/WithDrawForm';
import TabsWallet from './wallet/TabsWallet';
import TabsPointSystem from './wallet/TabsPointSystem';
import TabsRequestVoucher from './wallet/TabsRequestVoucher';
import { useHistory } from 'react-router-dom';
import { useRequestVoucherParnter } from '~/hooks/requestVoucher';
import { useGetMyRanking } from '~/hooks/memberRanking';
import NoticeUsePoint from './wallet/NoticeUsePoint';
import ProcessPartner from './ProcessConfigCampaign/ProcessPartner';
import { formatNumberThreeComma } from '~/hooks/utils';
const styleCard = {
  margin: '10px 0',
  background: '#F0F2F5', borderRadius: 20, boxShadow: 'rgb(9 9 9 / 24%) 0px 0px 15px 0px'
}
const styleInCard = { background: '#F0F2F5', borderRadius: 20, border: 'none' }

const formatter = (value) => <CountUp formattingFn={value => formatNumberThreeComma(value,2)} decimals={0} end={value} separator="," />;
export default function WorkSpaceWallet() {
  const [profile] = useUserWorkspace();
  const history = useHistory()
  // hook
  const [reFetch, setReFetch] = useState(false)
  const [visibleModalWithDraw, setVisibleModalWithDraw] = useState(false)
  const [query] = usePointSystemPartnerQueryParams(get(profile, '_id'), reFetch);
  const [pointSystems, isLoading] = usePointSystemsPartner(query);
  const {data :myRanking, isLoading : isLoadingRanking} = useGetMyRanking(get(profile, '_id'));
  const {data,mutate} = useGetMyPointCanUse(get(pointSystems,'caculate'))
  const queryfetch = useMemo(() => ({ limit: MAX_PAGINATION_LIMIT }), [])
  const [exchangePoint, loadingExchangepoint] = useExchangePoints(queryfetch)
  const [dataWallet, loadingWallet] = useWalletParnter(get(profile, '_id'), reFetch)
  const [dataRequestVoucher, loadingRequestVoucher] = useRequestVoucherParnter(get(profile, '_id'), reFetch)
  ////////////////////////////////
  const handleChangeTabs = (tab) => {
    history.push('/workspace/wallet')
  }
  useEffect(() => {
    history.push('/workspace/wallet')
  }, [])

  const handleOpenModalWithDraw = () => {
    setVisibleModalWithDraw(true)
  }
  const handleReRender = () => setReFetch(!reFetch)
  const handleCloseModalWithDraw = () => {
    setVisibleModalWithDraw(false)
  }

  return (
    <div className="page-wraper page-content page-content-workSpace">
      <InfoPartner profile={profile} />
      {/* <NoticeUsePoint maxRefund={get(myRanking,'maxRefund',0)}/> */}
      <Row style={{ margin: '20px 0' }} justify="space-around">

      <Col style={{ ...styleCard }} xs={23} sm={23} md={7} lg={7}>
          <Card
            style={{ ...styleInCard }}
            bordered={false}
          >
            <Statistic
              title="Tổng số điểm kiếm được"
              loading={isLoading}
              value={get(data, 'totalPoint', 0)}
              precision={0}
              formatter={formatter}
              valueStyle={{
                color: '#3f8600'
              }}
              prefix={<DollarOutlined />}
              suffix="Điểm"
            />
          </Card>
        </Col>
        <Col style={{ ...styleCard }} xs={23} sm={23} md={7} lg={7}>
          <Card
            style={{ ...styleInCard }}
            bordered={false}
          >
            <Statistic
              title="Số dư điểm thưởng có thể dùng còn lại"
              loading={isLoading}
              value={get(data, 'remainingPointCanUse', 0)}
              precision={0}
              formatter={formatter}
              valueStyle={{
                color: '#3f8600'
              }}
              prefix={<DollarOutlined />}
              suffix="Điểm"
            />
          </Card>
        </Col>
        <Col style={{ ...styleCard }} xs={23} sm={23} md={7} lg={7}>
          <Card
            style={{ ...styleInCard }}
            bordered={false}
          >
            <Statistic
              title="Điểm đã dùng"
              loading={isLoading}
              value={get(data, 'pointUsed', 0)}
              precision={0}
              formatter={formatter}
              valueStyle={{
                color: '#3f8600'
              }}
              prefix={<DollarOutlined />}
              suffix="Điểm"
            />
          </Card>
        </Col>
      
        <Col style={{ ...styleCard }} xs={23} sm={23} md={7} lg={7}>
          <Card
            style={{ ...styleInCard }}
            bordered={false}
          >
            <Statistic
              title="Đang chờ duyệt rút tiền ở ví"
              value={dataRequestVoucher}
              precision={0}
              loading={loadingRequestVoucher}
              formatter={formatter}
              prefix={<AlertOutlined />}
              suffix="VND"
            />
          </Card>
        </Col>
        <Col style={{ ...styleCard }} xs={23} sm={23} md={7} lg={7}>
          <Card
            style={{ ...styleInCard }}
            bordered={false}
            className="wallet-card"
          >
            <Statistic
              title="Số dư ví"
              value={
                dataWallet
              }
              loading={loadingWallet}
              precision={0}
              formatter={formatter}
              prefix={<WalletTwoTone />}
              suffix="VND"
            />
            <div className='wallet-card--buttonWithDraw'>
              <Button type='dashed' onClick={handleOpenModalWithDraw} style={{ width: '100%', height: '100%', fontSize: '24px', borderRadius: '0 16px 0px 16px', display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center' }}>
                {/* <Text style={{fontSize : '14px' , fontStyle : 'oblique'}} strong>Rút tiền</Text> */}
                <Image width={47} height={47} preview={false} src={paymentWallet} />
                <ArrowRightOutlined />
              </Button>
            </div>
          </Card>
        </Col>
        <Col span={24} align='center'>
          <ExchangePointRefund userId={get(profile,'_id')} type={WALLET_TYPE.CASH} mutate={handleReRender} dataExchange={exchangePoint} point={get(data, 'remainingPointCanUse', 0)} />
        </Col>
        {/* <Col style={{...styleCard}} xs={23} sm={23} md={7} lg={7}>
       
        </Col> */}
      </Row>
      {/* <ChartLine data={pointSystemAll}/> */}
      <Tabs destroyInactiveTabPane onChange={handleChangeTabs}>
        <Tabs.TabPane key={'wallet'} tab="Lịch sử ví">
          <TabsWallet reFetch={reFetch} userId={get(profile, '_id')} />
        </Tabs.TabPane>
        <Tabs.TabPane key={'pointSystem'} tab='Lịch sử điểm thưởng'>
          <TabsPointSystem reFetch={reFetch} userId={get(profile, '_id')} />
        </Tabs.TabPane>
        <Tabs.TabPane key={'requestVoucher'} tab='Yêu cầu rút tiền'>
          <TabsRequestVoucher reFetch={reFetch} userId={get(profile, '_id')} />
        </Tabs.TabPane>
      </Tabs>
      <ProcessPartner ranking={myRanking}/>
      <Modal destroyOnClose footer={null} visible={visibleModalWithDraw} onCancel={handleCloseModalWithDraw}>
        <WithDrawForm mutate={handleReRender} profile={profile} onClose={handleCloseModalWithDraw} dataWallet={dataWallet} />
      </Modal>
    </div>
  );
}
