export const GET_UNCONFIRM_WH_BILLS_REQUEST = 'GET_UNCONFIRM_WH_BILLS_REQUEST';
export const GET_UNCONFIRM_WH_BILLS_SUCCESS = 'GET_UNCONFIRM_WH_BILLS_SUCCESS';
export const GET_UNCONFIRM_WH_BILLS_FAILED = 'GET_UNCONFIRM_WH_BILLS_FAILED';

export const GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_REQUEST = 'GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_REQUEST';
export const GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_SUCCESS = 'GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_SUCCESS';
export const GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_FAILED = 'GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_FAILED';

export const GET_UNCONFIRM_WH_BILL_REQUEST = 'GET_UNCONFIRM_WH_BILL_REQUEST';
export const GET_UNCONFIRM_WH_BILL_SUCCESS = 'GET_UNCONFIRM_WH_BILL_SUCCESS';
export const GET_UNCONFIRM_WH_BILL_FAILED = 'GET_UNCONFIRM_WH_BILL_FAILED';

export const GET_LIST_BRANCH_CONFIRM_REQUEST = 'GET_LIST_BRANCH_CONFIRM_REQUEST';
export const GET_LIST_BRANCH_CONFIRM_SUCCESS = 'GET_LIST_BRANCH_CONFIRM_SUCCESS';
export const GET_LIST_BRANCH_CONFIRM_FAILED = 'GET_LIST_BRANCH_CONFIRM_FAILED';

export const CONFIRM_UNCONFIRM_WH_BILL_REQUEST = 'CONFIRM_UNCONFIRM_WH_BILL_REQUEST';
export const CONFIRM_UNCONFIRM_WH_BILL_SUCCESS = 'CONFIRM_UNCONFIRM_WH_BILL_SUCCESS';
export const CONFIRM_UNCONFIRM_WH_BILL_FAILED = 'CONFIRM_UNCONFIRM_WH_BILL_FAILED';

export const RESET_UNCONFIRM_WH_BILL_STATE = 'RESET_UNCONFIRM_WH_BILL_STATE';

export const SET_NEW_BILL = 'SET_NEW_BILL';
