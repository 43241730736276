
import { FileWordOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Col, Divider, Form,
  Input, Row,
  Select,
  Skeleton,
  Typography
} from 'antd';
import { get, omit, pick } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from '~/api';
import {
  BaseBorderBox, WithPermission
} from '~/components/Common';
import { REF_COLLECTION_VOUCHER_WAREHOUSE, REF_COLLECTION_VOUCHER_WAREHOUSE_VI, TYPE_USE_WAREHOUSE_VOUCHER, TYPE_VOUCHER_WAREHOUSE, TYPE_VOUCHER_WAREHOUSE_DOWNLOAD_VI, TYPE_VOUCHER_WAREHOUSE_VI } from '~/constants/defaultValue';
import DebounceSelectVoucher from './DebounceSelectVoucher';
import './index.scss';
import TableListProducts from './TableListProducts';
import templateDelivery from '~/assets/templates/Biên_bản_bàn_giao_template.docx';
import templateRefund from '~/assets/templates/Biên_bản_hoàn_trả_template.docx';
import createReport from 'docx-templates';
import { useCreateWarehouseVoucher, useCreateWarehouseVoucherOfPartner, useGetNewCodeVoucherWarehouse, useResetActionWarehouseVoucher, useUpdateWarehouseVoucher, useWarehouseVoucher, useWarehouseVoucherOfPartner, validateFieldsListProduct } from '~/hooks/warehouseVoucher';
import moment from 'moment';
import toastr from 'toastr';
import { useProfile, useUser, useUserWorkspace, useWhAppointment } from '~/hooks';
import TableRenderWord from './TableRenderWord';
import { useOptionsSelectAntd } from '~/hooks/utils';
import { setEmployee, setClient, setInitStaff } from '~/hooks/warehouseVoucher/hookVoucher';
import ConfirmBox from './ConfirmBox';
import { STATUS_CONFIRM_WAREHOUSE_VOUCHER, STATUS_WAREHOUSE_VOUCHER } from '~/constants/warehouse';
import POLICIES from '~/constants/policy';
const mainRowGutter = 24;
const layoutFull = {
  md: 24,
  sm: 24,
  xs: 24,
}
const FormItem = Form.Item;
export default function FormVoucherWarehouse({ onCancel, mutate, id, typeVoucher, handleByPartner, showTitle = true }) {
  const [branchId] = useUser();
  const optionsRefCollection = useOptionsSelectAntd(REF_COLLECTION_VOUCHER_WAREHOUSE_VI, {
    callback: (value) => value === 'customer_account',
    keyCheck: 'key'
  });

  const [form] = Form.useForm();
  const ref = useRef();
  const [profile] = useProfile(handleByPartner && null);
  const [profilePartner] = useUserWorkspace(!handleByPartner && null);
  const [listClientsInit, setListClientsInit] = useState([]);
  const [employeeIdInits, setEmployeeIdInits] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [voucherWarehouse, loadingVoucher] = useWarehouseVoucher(!!!handleByPartner && id);
  const [voucherWarehousePartner, isLoadingPartner] = useWarehouseVoucherOfPartner(handleByPartner && id);
  const [voucher, isLoading] = useMemo(() => {
    if (id) {
      if (handleByPartner) {
        return [voucherWarehousePartner, isLoadingPartner]
      } else {
        return [voucherWarehouse, loadingVoucher]
      }
    }
    return [null, false];
  }, [handleByPartner, id, isLoadingPartner, loadingVoucher, voucherWarehouse, voucherWarehousePartner]);
  const handleCreateByPartner = useMemo(() => !id && handleByPartner, [handleByPartner, id]);
  const handleViewByPartner = useMemo(() => id && handleByPartner, [handleByPartner, id]);
  const handleViewVoucherProcessed = useMemo(() => id && get(voucher, 'status') !== STATUS_WAREHOUSE_VOUCHER.NEW, [id, voucher]);
  const handleConfirmByEmployee = useMemo(() => id && get(voucher, 'confirmEmployee') === STATUS_CONFIRM_WAREHOUSE_VOUCHER.NEW && !handleByPartner, [handleByPartner, id, voucher])
  const handleConfirmBySupplier = useMemo(() => id && !(handleByPartner), [handleByPartner, id])
  const handleConfirmBySupplierPartner = useMemo(() => id && handleByPartner && get(voucher, 'supplier.userId') === get(profilePartner,'_id'), [handleByPartner, id,profilePartner,voucher])
  const handleConfirmByClient = useMemo(() => id && get(voucher, 'client.userId') === get(profilePartner,'_id'), [profilePartner, id, voucher])
  const typeVoucherInit = useMemo(() => typeVoucher ?? get(voucher, 'typeVoucher'), [typeVoucher, voucher])
  const callbackAfterCreate = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    }
    if (mutate && typeof mutate === 'function') {
      mutate(ref?.current?.getDataListProduct());
    }
  }
  const [loadingSubmit, createVoucherWarehouse] = useCreateWarehouseVoucher(callbackAfterCreate, typeVoucherInit)
  const [, createVoucherWarehouseOfPartner] = useCreateWarehouseVoucherOfPartner(callbackAfterCreate, typeVoucherInit)
  const [, updateVoucherWarehouse] = useUpdateWarehouseVoucher(callbackAfterCreate, typeVoucherInit)
  const [newCodeVoucher, loading] = useGetNewCodeVoucherWarehouse((code) => form.setFieldsValue({ codeCommerPurchVoucher: `${typeVoucherInit}${code}` }), handleCreateByPartner || !id)
  useResetActionWarehouseVoucher();
  const onPrint = async () => {
    const listProduct = ref?.current?.getDataListProduct()
    const { msg, status } = validateFieldsListProduct(listProduct)
    if (status) {
      return toastr.error(msg)
    }
    const downloadURL = (data, fileName) => {
      const a = document.createElement('a');
      a.href = data;
      a.download = fileName;
      document.body.appendChild(a);
      a.style = 'display: none';
      a.click();
      a.remove();
    };

    const saveDataToFile = (data, fileName, mimeType) => {
      const blob = new Blob([data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      downloadURL(url, fileName, mimeType);
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    };

    setIsPrinting(true);
    const template = await fetch(typeVoucherInit === TYPE_VOUCHER_WAREHOUSE.PBG ? templateDelivery : templateRefund).then(res => res.arrayBuffer());

    try {
      const submitData = {
        ...form.getFieldsValue(),
        listProduct,
        ...voucher
      };
      let nameFile = `${typeVoucherInit === TYPE_VOUCHER_WAREHOUSE.PBG ? TYPE_VOUCHER_WAREHOUSE_DOWNLOAD_VI.PBG : TYPE_VOUCHER_WAREHOUSE_DOWNLOAD_VI.PHT}${get(submitData, 'codeCommerPurchVoucher', '')} - `
      nameFile += moment(get(submitData, 'createdAt')).format("DD-MM-YYYY HH:mm:ss")
      await form.validateFields();
      const report = await createReport({
        template,
        cmdDelimiter: ['{#', '#}'],
        data: {
          employeeName: get(submitData, 'supplier.supplierName', ''),
          clientName: !id ? get(submitData, 'client.clientName', '') : get(submitData, 'whPartner.name', ''),
          date: moment(get(submitData, 'createdAt')).format("DD"),
          month: moment(get(submitData, 'createdAt')).format("MM"),
          year: moment(get(submitData, 'createdAt')).format("YYYY"),
          listProduct: JSON.stringify(listProduct),
          table: `<meta charset="UTF-8">
          <body>
          ${TableRenderWord(listProduct)}
          </body>`
        },
      });
      saveDataToFile(
        report,
        `${nameFile}.docx`,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      );

    } catch (error) {
      console.error(error);
    } finally {
      setIsPrinting(false);
    }
  };
  const onFinish = (values) => {
    const listProduct = ref?.current?.getDataListProduct();
    const { msg, status } = validateFieldsListProduct(listProduct);
    if (status) {
      return toastr.error(msg)
    };
    if (id) { // Update
      const submitData = {
        ...values,
        listProduct,
        id
      };
      updateVoucherWarehouse(submitData)
    }
    else { // Create
      const submitData = {
        ...values,
        listProduct,
        typeVoucher: typeVoucherInit,
        note: get(values, 'note', ''),
        typeOfUse: TYPE_USE_WAREHOUSE_VOUCHER.wh_bill,
      };
      if (handleCreateByPartner) { // PARTNER CREATE PHT
        createVoucherWarehouseOfPartner(submitData)
      } else {
        createVoucherWarehouse(submitData)
      }
    }

  }
  const onClientIdChange = (value, option) => {
    const { value: userId, label } = value;
    setListClientsInit([value])
    form.setFieldsValue({
      client: {
        userId,
        clientName: get(option, 'name')
      }
    })
  }
  const onEmployeeIdChange = (value, option) => {
    const { value: employeeId } = value;
    form.setFieldsValue({ employeeId })
  }
  const getListPartners = useCallback(async (query) => {

    let listPartners = await api.whPartner.getWhPartnersReport({ ...query, isGetCurrentBrachId: branchId })
    const partners = get(listPartners, "docs").map(item => {
      return {
        ...item,
        label: `${get(item, "partnerCode", "")} - ${get(item, "name", "")}`
      }
    });
    return partners
  }, [])
  const getStaffs = useCallback(async query => {
    const response = await api.userEmployee.getAll(({ ...query, isGetCurrentBrachId: branchId }));
    const whCustomerAccounts = get(response, "docs").map(item => ({
      ...item,
      label: `${get(item, "fullName")}`,
    }));
    return whCustomerAccounts;
  }, []);
  useEffect(() => {
    // UPDATE
    if (id) {
      if (voucher) {
        const { typeVoucher: typeVoucherFetch, codeCommerPurchVoucher, createdAt, User, whPartner } = voucher;
        if (User) {
          setEmployee(setEmployeeIdInits, User);
        } else {
          if (handleConfirmByEmployee) {
            setEmployee(setEmployeeIdInits, profile);
            form.setFieldsValue({
              employeeId: get(profile, '_id')
            });
          }
        }
        setClient(setListClientsInit, whPartner);
        form.setFieldsValue({
          ...voucher,
          codeCommerPurchVoucher: `${typeVoucherFetch}${codeCommerPurchVoucher}`,
          createdAt: moment(createdAt)?.format("DD-MM-YYYY HH:mm:ss"),
        });
      }
    } else {// CREATE
      if (!handleByPartner) { // HANDLE BY ADMIN
        setInitStaff(get(profile, '_id'), setEmployeeIdInits, form);
        form.setFieldsValue({
          createdAt: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      } else {  // HANDLE BY PARTNER
        if (profilePartner) {
          setClient(setListClientsInit, profilePartner);
          form.setFieldsValue({
            client: {
              refCollection: REF_COLLECTION_VOUCHER_WAREHOUSE.wh_partner,
              userId: get(profilePartner, '_id'),
              clientName: get(profilePartner, 'name'),
            },
            createdAt: moment().format("DD-MM-YYYY HH:mm:ss"),

          });
        }
      }

    }
  }, [form, profile, id, handleByPartner, voucher, profilePartner, handleConfirmByEmployee]);
  const renderLoading = (component) => isLoading ? (
    <Skeleton.Input active />
  ) : (
    component
  );
  return (
    <div className="page-wraper">
      {showTitle && renderLoading(<Divider orientation='left'>
        <Typography.Title level={4}>{`${id ? "Cập nhật" : "Tạo"} ${TYPE_VOUCHER_WAREHOUSE_VI[typeVoucherInit]}`}</Typography.Title>
      </Divider>)}
      <div className="container-fluid">
        <Form
          autoComplete="off"
          form={form}
          labelAlign="left"
          // labelCol={{ sm: 24, md: 24, lg: 4 }}
          onFinish={onFinish}
          requiredMark={false}
          scrollToFirstError
        >
          <Row
            align="top"
            className="staff-form__logo-row"
            gutter={mainRowGutter}
            justify="space-between"
          >
            <Col lg={9} {...layoutFull}
            >
              <BaseBorderBox title={'Thông tin chung'}>
                <Row >
                  <Col span={24}>
                    <FormItem
                      label={"Loại đối tượng"}
                      name={["client", "refCollection"]}
                      labelCol={{span : 8}}
                    wrapperCol={{span : 16}}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn loại đối tượng!'
                        }
                      ]}
                    >
                      {renderLoading(<Select disabled={!!id || handleByPartner}  options={optionsRefCollection} />)}
                    </FormItem>
                    <FormItem hidden name={['client', 'clientName']}>

                    </FormItem>
                    <FormItem
                      labelCol={{span : 8}}
                    wrapperCol={{span : 16}}
                      label={"Đối tượng"}
                      name={["client", "userId"]}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn đối tượng!'
                        }
                      ]}
                    >
                      {renderLoading(
                        <DebounceSelectVoucher
                          disabled={!!id || handleByPartner}
                          // disabled={true}
                          initOptions={listClientsInit}
                          fetchOptions={query => getListPartners({ ...query, page: 1, limit: 10 })}
                          labelKey="label"
                          searchKey="name"
                          valueInit={listClientsInit}
                          valueKey={"_id"}
                          onSelect={onClientIdChange}
                        />
                      )}
                    </FormItem>
                    <FormItem
                    name="employeeId"
                    labelCol={{span : 8}}
                    wrapperCol={{span : 16}}
                    label="Nhân viên thực hiện"
                  >
                    {renderLoading(
                      <DebounceSelectVoucher
                        disabled={true}
                        initOptions={employeeIdInits}
                        fetchOptions={query => getStaffs({ ...query, page: 1, limit: 10 })}
                        labelKey="label"
                        searchKey="keyword"
                        valueInit={employeeIdInits}
                        valueKey={"_id"}
                        onSelect={onEmployeeIdChange}
                      />
                    )}
                  </FormItem>
                  </Col>

                
                </Row>
              </BaseBorderBox>
            </Col>
            <Col lg={9} {...layoutFull}
            >
              <BaseBorderBox title={'Thông tin nhà cung cấp tài sản'}>
                <Row >
                  <Col span={24}>
                    <FormItem
                      hidden
                      name={["supplier", "userId"]}
                    >
                    </FormItem>
                    <FormItem
                      hidden
                      name={["supplier", "refCollection"]}
                    >
                    </FormItem>
                  <FormItem shouldUpdate noStyle>
                    {() =>   <FormItem
                      labelCol={{span : 8}}
                    wrapperCol={{span : 16}}
                      label={"Tên nhà cung cấp"}
                      name={["supplier", "supplierName"]}
                    >
                      {renderLoading(
                        <Input disabled/>
                      )}
                    </FormItem>}
                  </FormItem>
                  </Col>

                
                </Row>
              </BaseBorderBox>
            </Col>


            <Col
              lg={6} {...layoutFull}
            >
              <BaseBorderBox title={'Chứng từ'}>
                <Row gutter={36}>
                  <Col span={24}>

                    <FormItem
                      label="Mã số phiếu"
                      name="codeCommerPurchVoucher"
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem
                      label="Ngày tạo"
                      name="createdAt"
                      labelCol={{ lg: 8 }}
                    >
                      {renderLoading(
                        <Input disabled bordered={false} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>
          </Row>

          <BaseBorderBox title={'Ghi chú'}>
            <Row>
              <Col span={24}>
                <FormItem
                  label="Ghi chú"
                  name={"note"}
                  labelCol={{ lg: 8 }}
                >
                  {renderLoading(<Input.TextArea disabled={handleViewVoucherProcessed || handleViewByPartner} />)}
                </FormItem>
              </Col>
            </Row>
          </BaseBorderBox>

          <BaseBorderBox title={"Thông tin tài sản"}>
            <Row>
              <Col span={24}>
                <Form.Item shouldUpdate={(prevValues, curValues) => get(prevValues,'supplier') !== get(curValues,'supplier')}>
                    {() => <TableListProducts
                  isLoading={isLoading}
                  typeVoucher={typeVoucherInit}
                  handleByPartner={handleByPartner}
                  dataSource={get(voucher, 'listProduct', [])}
                  disabled={handleViewByPartner || handleViewVoucherProcessed}
                  ref={ref}
                  id={id}
                  handleConfirmByEmployee={handleConfirmByEmployee}
                  handleViewByPartner={handleViewByPartner}
                  idPartnerSelected={form.getFieldValue(['client', 'userId'])}
                  setForm={(value) => form.setFieldsValue(value)}
                  supplierId={form.getFieldValue(['supplier', 'userId'])}
                  handleCreateByPartner={handleCreateByPartner}
                />}
                </Form.Item>
              </Col>
            </Row>
          </BaseBorderBox>

          {id && <BaseBorderBox style={{ marginTop: 15 }} title={'Thông tin xác thực'} >
            {renderLoading(<ConfirmBox
              id={id}
              infoEmployee={
                handleConfirmByEmployee
                  ? {
                    name: get(profile, 'fullName', ''),
                    dateConfirm: get(voucher, 'dateConfirmEmployee') ? moment(get(voucher, 'dateConfirmEmployee')).format('DD-MM-YYYY HH:mm:ss') : null,
                    confirmStatus: get(voucher, 'confirmEmployee')
                  }
                  : {
                    name: get(voucher, 'User.fullName', ''),
                    dateConfirm: get(voucher, 'dateConfirmEmployee') ? moment(get(voucher, 'dateConfirmEmployee')).format('DD-MM-YYYY HH:mm:ss') : null,
                    confirmStatus: get(voucher, 'confirmEmployee')
                  }}
              infoWhPartner={{
                name: get(voucher, 'whPartner.name', ''),
                dateConfirm: get(voucher, 'dateConfirmPartner') ? moment(get(voucher, 'dateConfirmPartner')).format('DD-MM-YYYY HH:mm:ss') : null,
                confirmStatus: get(voucher, 'confirmPartner')
              }}

              infoSupplier={{
                name: get(voucher, 'supplier.supplierName', ''),
                dateConfirm: get(voucher, 'dateConfirmSupplier') ? moment(get(voucher, 'dateConfirmSupplier')).format('DD-MM-YYYY HH:mm:ss') : null,
                confirmStatus: get(voucher, 'confirmSupplier')
              }}
              handleByPartner={handleByPartner}
              handleConfirmByClient={handleConfirmByClient}
              handleConfirmBySupplier={handleConfirmBySupplier}
              status={get(voucher,'status')}
              handleConfirmBySupplierPartner={handleConfirmBySupplierPartner}
            />)}
          </BaseBorderBox>}

          <Row className="wh-service-form__submit-box">
            {/* Button For Admin (Voucher is Completed is cannot update)*/}
            {!handleByPartner &&  !handleViewVoucherProcessed && 
            <WithPermission permission={id ? POLICIES.UPDATE_WAREHOUSE : POLICIES.WRITE_WAREHOUSE}> {/* have Id Check Permission Update , Not Id is Check permission Create */}
              <Button
              icon={<SaveOutlined />}
              type="primary"
              onClick={() => form.submit()}
              loading={loadingSubmit}
            >
              Lưu
            </Button>
            </WithPermission>}

            {/* Button For partner (Partner Just Can Create)*/}
            {handleByPartner && !id && <Button
              icon={<SaveOutlined />}
              type="primary"
              onClick={() => form.submit()}
              loading={loadingSubmit}
            >
              Lưu
            </Button>}
            <Button
              icon={<FileWordOutlined />}
              loading={isPrinting}
              onClick={() => onPrint()}
            >
              Tải về file Docx
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  )
}
