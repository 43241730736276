export const CONFIRM_VOUCHER_STATUS = {
  NEW: 'NEW',
  CONFIRM: 'CONFIRM',
  COMPLETED: 'COMPLETED',
  CANCEL: 'CANCEL',
}

export const CONFIRM_VOUCHER_STATUS_VI = {
  NEW: 'Mới',
  CONFIRM: 'Đã xác nhận',
  COMPLETED: 'Hoàn thành',
  CANCEL: 'Đã từ chối',
}

export const CONFIRM_VOUCHER_PRODUCT_STATUS = {
  NEW: 'NEW',
  CONFIRM: 'CONFIRM',
  COMPLETED: 'COMPLETED',
  CANCEL: 'CANCEL',
}

export const CONFIRM_VOUCHER_PRODUCT_STATUS_VI = {
  NEW: 'Mới',
  CONFIRM: 'Đã xác nhận',
  COMPLETED: 'Hoàn thành',
  CANCEL: 'Đã từ chối',
}
