import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  deletePositionJob,
  getPositionJob,
  getPositionJobs,
  resetPositionJobState,
  updatePositionJob,
  createPositionJob,
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.positionJob[key];
const pagingSelector = getSelector('paging');
export const usePositionJobPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const isGetPositionJobLoading = getSelector('isGetPositionJobLoading');
const getPositionJobsFailedSelector = getSelector('getPositionJobsFailed');
const getPositionJobFailedSelector = getSelector('getPositionJobFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const PositionJobsSelector = getSelector('PositionJobs');
const PositionJobSelector = getSelector('PositionJob');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const usePositionJobQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const createPositionJobSuccess = useSelector(createSuccessSelector);
  const deletePositionJobSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createPositionJobSuccess,
    deletePositionJobSuccess,
  ]);
};

export const useUpdatePositionJobParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.positionJob.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};
export const usePositionJobs = (query) => {
  return useFetchByParam({
    action: getPositionJobs,
    loadingSelector,
    dataSelector: PositionJobsSelector,
    failedSelector: getPositionJobsFailedSelector,
    param: query
  });
};
export const usePositionJob = (id) => {
  return useFetchByParam({
    action: getPositionJob,
    loadingSelector: isGetPositionJobLoading,
    dataSelector: PositionJobSelector,
    failedSelector: getPositionJobFailedSelector,
    param: id
  });
};
export const useUpdatePositionJob = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât Vị trí ứng tuyển thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updatePositionJob
  });
};

export const useCreatePositionJob = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới Vị trí ứng tuyển thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createPositionJob
  });
};
export const useDeletePositionJob = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Vị trí ứng tuyển thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật Vị trí ứng tuyển thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deletePositionJob
  });
};

export const useResetPositionJob = () => {
  useResetState(resetPositionJobState);
};
