import produce from 'immer';
import { find, get } from 'lodash';
import {
  GET_WH_APPOINTMENTS_REQUEST,
  GET_WH_APPOINTMENTS_SUCCESS,
  GET_WH_APPOINTMENTS_FAILED,
  DELETE_WH_APPOINTMENT_REQUEST,
  DELETE_WH_APPOINTMENT_SUCCESS,
  DELETE_WH_APPOINTMENT_FAILED,
  CREATE_WH_APPOINTMENT_REQUEST,
  CREATE_WH_APPOINTMENT_SUCCESS,
  CREATE_WH_APPOINTMENT_FAILED,
  ADD_WH_APPOINTMENTS_LOCALLY,
  REMOVE_WH_APPOINTMENTS_LOCALLY,
  GET_WH_APPOINTMENT_REQUEST,
  GET_WH_APPOINTMENT_SUCCESS,
  GET_WH_APPOINTMENT_FAILED,
  UPDATE_WH_APPOINTMENT_REQUEST,
  UPDATE_WH_APPOINTMENT_SUCCESS,
  UPDATE_WH_APPOINTMENT_FAILED,
  RESET_WH_APPOINTMENT_STATE,
  RESET_STORE,
  UPDATE_LIST_PRODUCT_APPOINTMENT_REQUEST,
  UPDATE_LIST_PRODUCT_APPOINTMENT_SUCCESS,
  UPDATE_LIST_PRODUCT_APPOINTMENT_FAILED,
  RESET_LIST_PRODUCT_WH_APPOINTMENT_STATE
} from '../../constants/actionTypes';
import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getWhAppointmentsFailed: undefined,
  whAppointments: [],

  isGetWhAppointmentLoading: false,
  whAppointment: null,
  getWhAppointmentFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  updateListProductSuccess: null,
  updateListProductFailed: null,
  loadingUpdateListproduct: false,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_WH_APPOINTMENTS_REQUEST:
      state.isLoading = true;
      state.getWhAppointmentsFailed = null;
      return;

    case GET_WH_APPOINTMENTS_SUCCESS:
      state.isLoading = false;
      const whAppointments = get(payload, 'docs', []).sort((a, b) => get(a, "ordinalNumber") >= get(b, "ordinalNumber") ? 1 : -1);
      // Function to find a confirmed product in the list
      const getProductConfirmListProduct = (listConfirmed, productId) => {
        return find(listConfirmed, (item) => get(item, 'productId') === productId);
      }
      // Map over each appointment and update the list of products
      const newWhAppointment = whAppointments?.map(whAppointment => {
        const listConfirmed = get(whAppointment, 'listConfirmed', []);
        // Update the listProduct with the new appointments
        const newListProduct = get(whAppointment, 'listProduct', [])?.map(product => {
          const productId = get(product, 'refId');
          const productConfirmed = getProductConfirmListProduct(listConfirmed, productId);
          return { ...product, 
            statusConfirm: get(productConfirmed, 'status'),
            confirmVoucherId : get(productConfirmed,'confirmVoucherId'), 
          };
        });
        return { ...whAppointment, listProduct: newListProduct }
      });
      // Update the state with the new appointments

      state.whAppointments = newWhAppointment;
      state.getWhAppointmentsFailed = null;
      state.paging = getPaging(payload);
      return;

    case GET_WH_APPOINTMENTS_FAILED:
      state.isLoading = false;
      state.whAppointments = [];
      state.getWhAppointmentsFailed = null;
      return;

    case GET_WH_APPOINTMENT_REQUEST:
      state.isGetWhAppointmentLoading = true;
      state.whAppointment = null;
      state.getWhAppointmentFailed = null;
      return;

    case GET_WH_APPOINTMENT_SUCCESS:
      state.isGetWhAppointmentLoading = false;
      state.whAppointment = payload;
      return;

    case GET_WH_APPOINTMENT_FAILED:
      state.isGetWhAppointmentLoading = false;
      state.getWhAppointmentFailed = payload;
      return;

    case DELETE_WH_APPOINTMENT_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_WH_APPOINTMENT_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_WH_APPOINTMENT_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_WH_APPOINTMENT_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_WH_APPOINTMENT_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_WH_APPOINTMENT_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case ADD_WH_APPOINTMENTS_LOCALLY:
      state.whAppointments = state.whAppointments.concat(payload);
      return;

    case REMOVE_WH_APPOINTMENTS_LOCALLY:
      const func = payload;
      state.whAppointments = state.whAppointments.filter(item => !func(item));
      return;

    case UPDATE_WH_APPOINTMENT_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_WH_APPOINTMENT_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.whAppointment = payload;
      state.whAppointments = state.whAppointments.map(item => {
        if (item._id === payload._id) {
          return { ...item, ...payload };
        }
        return item
      })
      return;

    case UPDATE_LIST_PRODUCT_APPOINTMENT_REQUEST:
      state.loadingUpdateListproduct = true;
      state.updateListProductSuccess = null;
      state.updateListProductFailed = null;
      return;

    case UPDATE_LIST_PRODUCT_APPOINTMENT_SUCCESS:
      state.loadingUpdateListproduct = false;
      state.updateListProductSuccess = get(payload, 'data');
      // state.whAppointments = state.whAppointments.map(item => {
      //   console.log(item,'item');
      //   if (item._id === get(payload,'data._id')) {
      //     return {...item, listProduct : get(payload,'data.listProduct',[])};
      //   }
      //   return item
      // })
      return;

    case UPDATE_LIST_PRODUCT_APPOINTMENT_FAILED:
      state.loadingUpdateListproduct = false;
      state.updateListProductFailed = payload;
      return;

    case UPDATE_WH_APPOINTMENT_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case RESET_LIST_PRODUCT_WH_APPOINTMENT_STATE:
      state.updateListProductSuccess = null;
      state.updateListProductFailed = null;
      return
    case RESET_WH_APPOINTMENT_STATE:
    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
