import React from 'react';

import { Button, Col, Form, Input, Row, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { LANGUAGE } from '~/constants/defaultValue';

import WhServiceQuestionOptionEdit from './WhServiceQuestionOptionEdit';

import './index.scss';

const WhServiceQuestionEdit = ({
  className,
  fieldKey,
  form,
  index,
  language,
  name,
  remove,
  setWhServiceQuestionOptionImg
}) => {
  return (
    <div className={`wh-service-form__question ${className}`}>
      {/* <Space direction='vertical' size="middle"> */}
      <Row gutter={[48, 0]} className="wh-service-form__question--text">
        <Col sm={22} lg={22}>
          <Form.Item
            wrapperCol={{ sm: 24 }}
            // {...restField}
            // label={index === 0 && 'Câu hỏi'}
            name={[name, 'title', LANGUAGE.VI]}
            className={`${language !== LANGUAGE.VI && 'hiden'}`}
            fieldKey={[fieldKey, 'title', LANGUAGE.VI]}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập câu hỏi'
              }
            ]}
            // {...verticalLayout}
          >
            <Input placeholder="Câu hỏi" />
          </Form.Item>
          <Form.Item
            wrapperCol={{ sm: 24 }}
            // {...restField}
            // label={index === 0 && 'Câu hỏi'}
            name={[name, 'title', LANGUAGE.EN]}
            className={`${language !== LANGUAGE.EN && 'hiden'}`}
            fieldKey={[fieldKey, 'title', LANGUAGE.EN]}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập câu hỏi bằng tiếng Anh'
              }
            ]}
            // {...verticalLayout}
          >
            <Input placeholder="Câu hỏi" />
          </Form.Item>
        </Col>
        <Col span={2}>
          <MinusCircleOutlined
            onClick={() => remove(name)}
            // className={index === 0 && 'top-5'}
          />
        </Col>
      </Row>
      <Row
        gutter={[48, 0]}
        justify="flex-start"
        className="wh-service-form__question--options-wrapper"
      >
        <Form.List name={[name, 'options']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(
                ({ key, name, fieldKey, ...restField }, optionIdx) => {
                  const props = {
                    fieldKey,
                    form,
                    index,
                    key,
                    language,
                    name,
                    optionIdx,
                    remove,
                    setWhServiceQuestionOptionImg
                  };
                  return <WhServiceQuestionOptionEdit key={key} {...props} />;
                }
              )}
              {fields.length >= 8 ? null : (
                <Col span={6}>
                  <Button
                    className="wh-service-form__question--btn-add-option"
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Thêm lựa chọn
                  </Button>
                </Col>
              )}
            </>
          )}
        </Form.List>
      </Row>
      {/* </Space> */}
    </div>
  );
};

export default WhServiceQuestionEdit;
