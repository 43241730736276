import { LoadingOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Image,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Typography
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '~/api';
import { WH_RATE_VALUE } from '~/constants/bill';
import angry from '../../assets/images/emojis/angry.svg';
import cry from '../../assets/images/emojis/cry.svg';
import happy from '../../assets/images/emojis/happy.svg';
import like from '../../assets/images/emojis/like.svg';
import love from '../../assets/images/emojis/love.svg';
import './WorkSpaceTimeTrain.scss';
// const { Link } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function RatingExistData({
  data,
  partner,
  date,
  loading,
  isPartnerHandle
}) {
  const [detailRating, setDetailRating] = useState([]);
  const [isOpenDetailRating, setIsOpenDetailRating] = useState(false);
  const getDetailRating = async (data) => {
    const res = await api.reportPartner.getAllRatingPartnerDetail({
      id: get(partner, '_id', ''),
      rating: WH_RATE_VALUE[data],
      ...date
    });
    setIsOpenDetailRating(true);
    setDetailRating(res);
  };
  const onCancel = () => {
    setDetailRating([]);
    setIsOpenDetailRating(false);
  };
  const columns = [
    {
      title: 'ID dịch vụ',
      key: 'whBillItemId',
      dataIndex: 'whBillItemId',
      align: 'center',
      render: (item) =>
        isPartnerHandle ? (
          <Text>{get(item, 'code', '')}</Text>
        ) : (
          <Link to={`/wh-bill-item/${get(item, '_id', '')}/update-appointments`}
          target='_blank'
          >
            {get(item, 'code', '')}
          </Link>
        )
    },
    {
      title: 'Dịch vụ',
      key: 'snapshotService',
      dataIndex: 'snapshotService',
      align: 'center',
      render: (item) =>
        `${get(item, 'code', '')} - ${get(item, 'name.vi', '')} `
    },
    {
      title: 'Thời gian',
      key: 'checkOut',
      dataIndex: 'checkOut',
      align: 'center',
      render: (item) =>
        moment(get(item, 'time',0)).format('YYYY-MM-DD hh:mm:ss')
    }
  ];
  return (
    <Row className="boxRating" gutter={48}>
      <Col span={24}>
        <Card className="card">
          <h3>Điểm khách hàng đánh giá</h3>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Row>
              <div style={{ marginRight: '5px' }}>Rate:</div>
              {loading ? (
                <Spin indicator={antIcon} />
              ) : (
                <div>{get(data, 'resultSumRating')}</div>
              )}
            </Row>
            <Row justify="space-between">
              <Col xs={24} lg={3}>
                <Row align='center'>
                    <Row className="Flex-center-column">
                    <Space>
                      <Image
                        className="imoteAnimation"
                        onClick={() => getDetailRating('LOVE')}
                        preview={false}
                        src={love}
                      />
                      {loading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <Text>{get(data, 'ratingLevel.LOVE')}</Text>
                    )}
                    </Space>
                      <Text strong>Rất hài lòng</Text>
                    </Row>
                    {/* {loading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <Text>{get(data, 'ratingLevel.LOVE')}</Text>
                    )} */}
                </Row>
              </Col>
              <Col xs={24} lg={3}>
                <Row align='center'>
                  <Space>
                    <Row className="Flex-center-column">
                      <Space>
                      <Image
                        className="imoteAnimation"
                        onClick={() => getDetailRating('LIKE')}
                        preview={false}
                        src={like}
                      />
                    {loading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <div>{get(data, 'ratingLevel.LIKE')}</div>
                    )}
                    </Space>
                      <Text strong>Hài lòng</Text>
                    </Row>
                  </Space>
                </Row>
              </Col>
              <Col xs={24} lg={3}>
                <Row align='center'>
                  <Space>
                    <Row className="Flex-center-column">
                      <Space>
                      <Image
                        className="imoteAnimation"
                        onClick={() => getDetailRating('ACCEPTABLE')}
                        preview={false}
                        src={happy}
                      />
                    {loading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <div>{get(data, 'ratingLevel.ACCEPTABLE')}</div>
                    )}
                    </Space>
                      <Text strong>Bình thường</Text>
                    </Row>
                  </Space>
                </Row>
              </Col>
              <Col xs={24} lg={3}>
                <Row align='center'>
                  <Space>
                    <Row className="Flex-center-column">
                      <Space>
                      <Image
                        className="imoteAnimation"
                        onClick={() => getDetailRating('SAD')}
                        preview={false}
                        src={cry}
                      />
                    {loading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <div>{get(data, 'ratingLevel.SAD')}</div>
                    )}
                    </Space>
                      <Text strong>Không hài lòng</Text>
                    </Row>
                  </Space>
                </Row>
              </Col>
              <Col xs={24} lg={3}>
                <Row align='center'>
                  <Space>
                    <Row className="Flex-center-column">
                      <Space>
                      <Image
                        className="imoteAnimation"
                        onClick={() => getDetailRating('ANGRY')}
                        preview={false}
                        src={angry}
                      />
                    {loading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <div>{get(data, 'ratingLevel.ANGRY')}</div>
                    )}
                    </Space>
                      <Text strong>Rất không hài lòng</Text>
                    </Row>
                  </Space>
                </Row>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
      <Modal
        width={500}
        visible={isOpenDetailRating}
        closable={false}
        onCancel={onCancel}
        footer={null}
      >
        <Table columns={columns} dataSource={detailRating} pagination={false} />
      </Modal>
    </Row>
  );
}
