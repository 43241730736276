import * as Types from '../../constants/actionTypes';
  
  export const getInfoCompanys = query => ({
    type: Types.GET_INFO_COMPANYS_REQUEST,
    payload: query
  });
  export const getInfoCompany = id => ({
    type: Types.GET_INFO_COMPANY_REQUEST,
    payload: id
  });
  export const updateInfoCompany = instance => ({
    type: Types.UPDATE_INFO_COMPANY_REQUEST,
    payload: instance
  });
  export const createInfoCompany = instance => ({
    type: Types.CREATE_INFO_COMPANY_REQUEST,
    payload: instance
  });
  export const deleteInfoCompany = id => ({
    type: Types.DELETE_INFO_COMPANY_REQUEST,
    payload: id
  });
  export const resetInfoCompanyState = () => ({
    type: Types.RESET_INFO_COMPANY_STATE
  });
