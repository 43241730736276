import { forIn, get, omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getSelectors,
    useBranchIdSessionStorage,
    useFailed,
    useFetchByParam,
    useQueryParams,
    useResetState,
    useSubmit,
    useSuccess
} from '~/hooks/utils';
import { createPartnerCancelAppointment, deletePartnerCancelAppointment, getPartnerCancelAppointment, getPartnerCancelAppointments, getPartnerCancelAppointmentsPartner, resetPartnerCancelAppointmentState, updatePartnerCancelAppointment } from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.partnerCancelAppointment[key];
const pagingSelector = getSelector('paging');
export const usePartnerCancelAppointmentPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetPartnerCancelAppointmentSelector = getSelector('isGetPartnerCancelAppointmentLoading');
const getPartnerCancelAppointmentsFailedSelector = getSelector('getPartnerCancelAppointmentsFailed');
const getPartnerCancelAppointmentFailedSelector = getSelector('getPartnerCancelAppointmentFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const partnerCancelAppointmentsSelector = getSelector('partnerCancelAppointments');
const partnerCancelAppointmentSelector = getSelector('partnerCancelAppointment');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');



export const usePartnerCancelAppointmentQueryParams = (listOptionSearch) => {
    const query = useQueryParams();
    const limit = query.get('limit') || 10;
    const page = query.get('page') || 1;
    const status = query.get('status');
    const isLate = query.get('isLate');
    let queryParamsSearch = {}
    let queryParamsDependency = []
    listOptionSearch.forEach(e => {
        queryParamsSearch[get(e,'value')] = query.get(get(e,'value'))
        queryParamsDependency.push(query.get(get(e,'value')))
    })
    const [branchId] = useBranchIdSessionStorage();
    // const createPartnerCancelAppointmentSuccess = useSelector(createSuccessSelector);
    // const updatePartnerCancelAppointmentSuccess = useSelector(updateSuccessSelector);
    const deletePartnerCancelAppointmentSuccess = useSelector(deleteSuccessSelector);
    return useMemo(() => {
      const queryParams = {
        page,
        limit,
        ...queryParamsSearch,
        status,
        isLate,
      };
      return [queryParams];
      //eslint-disable-next-line
    }, [
      page,
      limit,
      // createPartnerCancelAppointmentSuccess,
      // updatePartnerCancelAppointmentSuccess,
      deletePartnerCancelAppointmentSuccess,
      ...queryParamsDependency,
      status,
      isLate,
      branchId
    ]);
  };

  export const useUpdatePartnerCancelAppointmentParams = (query,listOptionSearch) => {
    const history = useHistory();
    const [keyword, setKeyword] = useState(get(query,'keyword'));
    useEffect(() => {
      setKeyword(get(query,'keyword'));
    },[query.keyword])
    const onParamChange = (param) => {
      if (!param.page) {
        query.page = 1;
      }
      if(!param.status && !param.isLate){
        forIn(query,() => {
          listOptionSearch.forEach(option => query[get(option,'value')] = null)
        })
      }
      history.push({
        pathname: get(param, 'customPathName') || PATH_APP.referral.PartnerCancelAppointment,
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword ,{setKeyword, onParamChange }];
  };
  ////////////////////////////////////////////////////////////////

// export const usePartnerCancelAppointmentPartnerQueryParams = (id) => {
//   const query = useQueryParams();
//   const limit = query.get('limit') || 10;
//   const page = query.get('page') || 1;
//   const keyword = query.get('keyword')
//   return useMemo(() => {
//     const queryParams = {
//       page,
//       limit,
//       keyword,
//       id
//     };
//     return [queryParams];
//     //eslint-disable-next-line
//   }, [
//     page,
//     limit,
//     keyword,
//     id
//   ]);
// };

// export const useUpdatePartnerCancelAppointmentPartnerParams = (query) => {
//   const history = useHistory();
//   const [keyword, setKeyword] = useState(get(query,'keyword'));
//   useEffect(() => {
//     setKeyword(get(query,'keyword'));
//   },[query.keyword])
//   const onParamChange = (param) => {
//     if (!param.page) {
//       query.page = 1;
//     }

//     const newQuery = omit(query, 'id')
//     history.push({
//       pathname: get(param, 'customPathName') || '/workspace/wallet',
//       search: new URLSearchParams(
//         getExistProp({
//           ...newQuery,
//           ...param
//         })
//       ).toString()
//     });
//   };

//   return [keyword ,{setKeyword, onParamChange }];
// };
  ////////////////////////////////
  export const usePartnerCancelAppointments = (query) => {
    return useFetchByParam({
      action: getPartnerCancelAppointments,
      loadingSelector,
      dataSelector: partnerCancelAppointmentsSelector,
      failedSelector: getPartnerCancelAppointmentsFailedSelector,
      param: query
    });
  };
  export const usePartnerCancelAppointment = (id) => {
    return useFetchByParam({
      action: getPartnerCancelAppointment,
      loadingSelector:loadinggetPartnerCancelAppointmentSelector,
      dataSelector: partnerCancelAppointmentSelector,
      failedSelector: getPartnerCancelAppointmentFailedSelector,
      param: id
    });
  };
export const useCreatePartnerCancelAppointment = (callback) => {
    useSuccess(createSuccessSelector, 'Tạo Yêu cầu huỷ hẹn thành công',callback);
    useFailed(createFailedSelector, 'Tạo Yêu cầu huỷ hẹn thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: createPartnerCancelAppointment
    });
  };
export const useUpdatePartnerCancelAppointment = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhật Yêu cầu huỷ hẹn thành công',callback);
    useFailed(updateFailedSelector, 'Cập nhật Yêu cầu huỷ hẹn thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updatePartnerCancelAppointment
    });
  };
export const useDeletePartnerCancelAppointment = (callback) => {
    useSuccess(deleteSuccessSelector, 'Xoá Yêu cầu huỷ hẹn thành công',callback);
    useFailed(deleteFailedSelector, 'Xoá Yêu cầu huỷ hẹn thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: deletePartnerCancelAppointment
    });
  };

  export const useResetPartnerCancelAppointment = () => {
    useResetState(resetPartnerCancelAppointmentState);
  };
