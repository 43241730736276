import { Button, Form, Input, Row } from 'antd';
import { useCallback, useEffect } from 'react';
import UploadImageForm from '~/components/Utils/UploadImageForm';
import { useAdv, useCreateAdv } from '~/hooks/adv';
import { renderLoading } from '~/utils/helper';

export default function FormAdv({ id, onCancel,onUpdate }) {
    const [form] = Form.useForm();
    const [isSubmitLoading, onCreate] = useCreateAdv(onCancel);
    const [adv, loading] = useAdv(id);
    const onFinish = useCallback((values) => {
        if (id) {
            onUpdate({
                ...values,
                _id: id
            });
        } else {
            onCreate(values)
        }
    }, [id]);
    useEffect(() => {
        if (id && adv) {
            form.setFieldsValue({ ...adv })
        }
    }, [adv, id]);

    return (
        <Form labelCol={{ span: 7 }} form={form} onFinish={onFinish}>
            <Form.Item label="Link" name={"link"}>
                {renderLoading(<Input />, loading)}
            </Form.Item>
            <Form.Item label='Icon' name={'icon'}>
                <UploadImageForm form={form} loading={loading} field='icon' />
            </Form.Item>
            <Form.Item label='Hình ảnh quảng cáo' name={'image'}>
                <UploadImageForm form={form} loading={loading} />
            </Form.Item>
            <Row justify='end'>
                <Button loading={isSubmitLoading} htmlType='submit' type='primary'>
                    {id ? "Cập nhật" : "Tạo mới"}
                </Button>
            </Row>
        </Form>
    )
}
