import { Button } from 'antd';
import { useEffect, useState } from 'react';
const COUNT_DOC_SHOW = 2;
export default function ListExpand({ itemRender, dataSource, loading, numberShow = COUNT_DOC_SHOW }) {
    const [isExpand, setIsExpand] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setData(dataSource);
    }, [dataSource]);
    useEffect(() => {
        if (dataSource?.length > numberShow) {
            if (isExpand) {
                setData(dataSource);
            } else {
                setData(dataSource?.slice(0, numberShow));
            }
        }
    }, [isExpand,numberShow]);
    
    if(loading){
        return <span>Đang tải...</span>
    }
    if(!dataSource?.length){
        return <></>
    }
    return (
        <ul
            style={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'start',
                paddingLeft : '14px'
            }}
        >
            {data?.map((e) => itemRender(e))}
            {(dataSource?.length > numberShow) && <Button shape='round' type='primary' ghost size='small' onClick={() => setIsExpand(!isExpand)}>{isExpand ? "Ẩn bớt" :"Xem thêm"}</Button>}
        </ul>
    )
}
