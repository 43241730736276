import { omit } from 'lodash';
import requester from './requester';

const news = {
  getAll: query => requester.get('/news', query),
  getById: id => requester.get(`/news/${id}?raw=true`),
  delete: id => requester.delete(`/news/${id}`),
  create: news => requester.post('/news', news),
  update: news => requester.put(`/news/${news.id ?? news._id}`, news),
  search: query => requester.get(`/news-search/${query.resource}`,omit(query,'resource')),
};

export default news;
