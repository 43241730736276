import React, { useEffect, useMemo, useState } from 'react';

import { compact, get, union } from 'lodash';
import { Input, Switch, Table, Tabs, Tag, Modal, Button } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import {
  useConvertToPartner,
  useDeleteWhPartner,
  useUpdateWhPartner,
  useWhPartner,
  useWhPartnerPaging,
  useWhPartnerQueryParams,
  useWhPartners,
  useUpdateWhPartnerParams
} from '~/hooks/whPartner';
import { useSpecialities } from '~/hooks/speciality';
import { useWhCategories, useWhCategoryQueryParams } from '~/hooks/whCategory';
import { useWhPackageLevels } from '~/hooks/whPackageLevel';
import { useWhServices } from '~/hooks/whService';
import {
  useWhSessionsOfDay,
  useWhSessionOfDayQueryParams
} from '~/hooks/whSessionOfDay';

import SkeletonTable from '~/components/Utils/SkeletonTable';
import {
  MAX_PAGINATION_LIMIT,
  WH_BILL_ITEM_STATUS,
  WH_PARTNER_STATUS
} from '~/constants/defaultValue';
import POLICY from '~/constants/policy';

import Children from './Children';
import WhPartnerBillItemsView from './WhPartnerBillItemsView';
import WhPartnerDetails from './WhPartnerDetails';
import { fromJSON, toJSONStatusOnly } from './parser';

import './index.scss';
import WhPartnerTransaction from './FormTransaction';
import { getRankingByPoint, useMatchPolicy, useProfile } from '~/hooks';
import {
  formatNumberThreeComma,
  formatNumberThreeCommaToFixed,
  useQueryFetchAll
} from '~/hooks/utils';
import { useRankings } from '~/hooks/ranking';
import { floorFormatter } from '~/utils/helper';
import Text from 'antd/lib/typography/Text';
import TabsPointSystem from '../Workspace/wallet/TabsPointSystem';
import ListRef from '../WhCustomerManagement/Detail/ListRef';
import TabsWallet from '../Workspace/wallet/TabsWallet';
import WhBoxServicePartner from './WhBoxServicePartner';
import FormConvertPartner from './FormConvertPartner';

const { Search } = Input;
const { TabPane } = Tabs;

const ColumnActions = ({
  id,
  status,
  waitingApproval,
  deleteWhPartner,
  updateWhPartner,
  ...restProps
}) => {
  return (
    <div className="custom-table__actions">
      <Link
        to={`/wh-partner/edit/${id}${
          waitingApproval ? '&waitingApproval=true' : ''
        }`}
      >
        {/* <p>Sửa</p> */}
        <EditFilled />
      </Link>
      <p>|</p>
      {/* <Popconfirm
        title="Bạn muốn xoá đối tác này?"
        onConfirm={() => deleteWhPartner(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '} */}
      <Switch
        checked={status}
        onChange={(status) => updateWhPartner(toJSONStatusOnly({ status, id }))}
        // loading={isSubmitLoading}
      />
    </div>
  );
};

const WhPartnerExpanded = ({ whPartner, ...restProps }) => {
  return (
    <Tabs defaultActiveKey="1" destroyInactiveTabPane onChange={() => {}}>
      <TabPane tab="Thông tin" key="1">
        <WhPartnerDetails whPartner={whPartner} {...restProps} />
      </TabPane>

      <TabPane tab="Việc đang thực hiện" key="2">
        {/* <p className="blink-me" style={{ color: "orange", fontSize: "12px" }}><Badge color="orange" />Warning: listing các bill item CONFIRMED hoặc IN_PROGRESS, của riêng đối tác này trên hệ thống</p> */}
        <WhPartnerBillItemsView
          whPartner={whPartner}
          statuses={[
            WH_BILL_ITEM_STATUS.CONFIRMED,
            WH_BILL_ITEM_STATUS.IN_PROGRESS
          ]}
          {...restProps}
        />
      </TabPane>

      <TabPane tab="Việc đã hoàn thành" key="3">
        {/* <p className="blink-me" style={{ color: "orange", fontSize: "12px" }}><Badge color="orange" />Warning: listing các bill item COMPLETED, của riêng đối tác này trên hệ thống</p> */}
        <WhPartnerBillItemsView
          whPartner={whPartner}
          status={WH_BILL_ITEM_STATUS.COMPLETED}
          {...restProps}
        />
      </TabPane>

      <TabPane tab="Nhân sự" key="4">
        <Children
          hideColumns={['specialities']}
          parent={whPartner}
          preloadChildren={true}
          size="middle"
          {...restProps}
        />
      </TabPane>

      <TabPane tab="Khách hàng được giới thiệu" key="5">
        <ListRef
          width={'100%'}
          openFetch={true}
          userId={get(whPartner, '_id')}
          hidePartner={true}
        />
      </TabPane>

      <TabPane tab="Giao dịch" key="6">
        <WhPartnerTransaction
          hideColumns={['specialities']}
          parent={whPartner}
          size="middle"
          {...restProps}
        />
      </TabPane>

      <TabPane tab="Lịch sử điểm thưởng" key="7">
        <TabsPointSystem
          userId={get(whPartner, '_id')}
          mode="state"
          allowSearch
        />
      </TabPane>
      <TabPane tab="Lịch sử ví" key="8">
        <TabsWallet mode="state" userId={get(whPartner, '_id')} allowSearch />
      </TabPane>
    </Tabs>
  );
};

const WhPartners = ({ history, status, onParamChange }) => {
  const [query, onPagingChange] = useWhPartnerQueryParams();
  // if (status === WH_PARTNER_STATUS.WAITING) query.waitingApproval = true;
  const [whPartners, isLoading] = useWhPartners(query);
  const queryGetAllRanking = useQueryFetchAll({ moreField: { sortPoint: 1 } });
  const [rankings, isLoadingRankings] = useRankings(queryGetAllRanking);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHPARTNER);
  const [queryWhServices, setQueryWhServices] = useState();
  const [whServices, isWhServicesLoading] = useWhServices(queryWhServices);
  const [specialities, isSpecialitiesLoading] = useSpecialities();
  const [whPackageLevels, isWhPackageLevelsLoading] = useWhPackageLevels();
  const [querySessions] = useWhSessionOfDayQueryParams(MAX_PAGINATION_LIMIT);
  const [whSessionsOfDay, isWhSessionsOfDayLoading] =
    useWhSessionsOfDay(querySessions);
  const [queryCategories] = useWhCategoryQueryParams(MAX_PAGINATION_LIMIT);
  const [whCategories, isWhCategoriesLoading] =
    useWhCategories(queryCategories);
  // const [{ onParamChange }] = useUpdateWhPartnerParams(query);
  const [keyword, setKeyword] = useState(query.keyword);
  const [, deleteWhPartner] = useDeleteWhPartner();
  const [isSubmitLoading, updateWhPartner] = useUpdateWhPartner();
  const paging = useWhPartnerPaging();

  const [profile] = useProfile();

  const [id, setId] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [confirmLoading, handleConfirm] = useConvertToPartner();

  const onConfirm = ({ whPartnerId, branchId }) =>
    handleConfirm({ id, branchId });

  const [whPartner, isWhPartnerLoading] = useWhPartner(id);

  const handleOpenModal = (id) => {
    setIsOpenModal(true);
    if (id) {
      setId(id);
    }
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setId(null);
  };

  useEffect(() => {
    const ids = compact(
      union(...whPartners.map((item) => get(item, 'services')))
    );
    setQueryWhServices({ ids, limit: MAX_PAGINATION_LIMIT });
  }, [whPartners]);

  const mergedWhPartners = useMemo(() => {
    return whPartners.map((item) => {
      // Data flow: API => redux reducer => hook => parser "fromJSON" => this component.
      // The parser is to help dynamically adapt to API changes.
      // Please consider changing data flow to: API => redux reducer => parser "fromJSON" => hook => this component
      // so it should be no need to implement parser in every single component.
      const whPartner = fromJSON(item);
      return {
        ...whPartner,
        specialities: get(whPartner, 'specialityIds').map((id) => {
          return specialities.find((item) => item.id === id);
        }),
        services: get(whPartner, 'services', [])?.map((whService) => ({
          ...whService,
          ...whServices.find((item) => item.id === whService.whServiceId)
        })),
        ranking: getRankingByPoint(item, rankings)
      };
    });
  }, [specialities, whPartners, whServices, rankings]);
  // const onSearch = () => {
  //   let searchParams = `?page=${query.page}&limit=${query.limit}`;
  //   if (keyword) searchParams += `&keyword=${keyword}`;

  //   history.push({
  //     pathname: '/wh-partner/list',
  //     search: searchParams
  //   });
  // };
  const nameService = (item) => {
    if (get(item, 'detailName.vi'))
      return `${get(item, 'name.vi')} - ${get(item, 'detailName.vi')}`;
    else return get(item, 'name.vi');
  };

  const columns = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'partnerCode',
      key: 'partnerCode'
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Nhóm đối tác',
      dataIndex: 'employeeGroup',
      key: 'employeeGroup',
      render: (employeeGroup) => {
        return get(employeeGroup, 'name.vi');
      }
    },
    {
      title: 'Bậc tài khoản',
      dataIndex: 'ranking',
      key: 'ranking',
      render: (ranking) => {
        return get(ranking, 'name', 'Chưa có');
      }
    },
    {
      title: 'Điểm thưởng hiện có',
      dataIndex: 'typePoint',
      key: 'typePoint',
      render: (point) => {
        const pointCanUse = get(point, 'transactions.credit', 0);
        const pointUsed = Math.abs(get(point, 'transactions.debit', 0));
        const remainingPointCanUse = pointCanUse - pointUsed;
        return (
          <Text strong>
            {formatNumberThreeCommaToFixed(remainingPointCanUse, 2)}
          </Text>
        );
      }
    },
    {
      title: 'Dịch vụ',
      dataIndex: 'services',
      width: '300px',
      key: 'services',
      render: (services) => <WhBoxServicePartner services={services} />
    },
    {
      title: 'Khu vực hoạt động',
      dataIndex: 'managementArea',
      key: 'managementArea',
      render: (managementArea) => {
        return get(managementArea, 'fullAddress');
      }
    },
    {
      title: 'Chuyển đổi',
      key: 'convert',
      render: (iem, record) => {
        return (
          (canUpdate && <Button
            disabled={status === WH_PARTNER_STATUS.WAITING}
            onClick={() => {
              setIsOpenModal(true);
              setId(get(record, '_id', ''));
            }}
            loading={confirmLoading}
            style={{ borderRadius: '8px' }}
            type="primary"
          >
            Chuyển
          </Button>)
        );
      }
    },

    {
      title: 'Zalo ID',
      dataIndex: 'zaloId',
      key: 'zaloId',
    },
    {
      title: 'Thao tác',
      key: 'action',
      fixed: 'right',
      width: 200,
      render: (record) =>
        canUpdate && (
          <ColumnActions
            {...record}
            deleteWhPartner={deleteWhPartner}
            updateWhPartner={updateWhPartner}
          />
        )
    }
  ];

  //Log
  return (
    // <div className="page-wraper page-content">
    <div className="container-fluid">
      {/* <Breadcrumb title="Danh sách đối tác" /> */}

      {/* <div className="page-wraper__header">
          <Search
            style={{ width: 200 }}
            placeholder="Tìm đối tác"
            enterButton
            onSearch={onSearch}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

          <Link to={PATH_APP.whPartner.create}>
            <Button type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </Link>
        </div> */}

      {isLoading ? (
        <SkeletonTable
          rowCount={whPartners.length}
          columns={columns}
          pagination={paging}
        />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          dataSource={mergedWhPartners}
          // onChange={({ current, pageSize }) => onParamChange({ page: current, limit: pageSize })}
          onChange={onPagingChange}
          scroll={{ x: 'auto' }}
          size="middle"
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
          // Read more: https://ant.design/components/table/#expandable
          expandable={{
            expandedRowRender: (record) => (
              <WhPartnerExpanded
                whPartner={record}
                whCategories={whCategories}
                whPackageLevels={whPackageLevels}
                whSessionsOfDay={whSessionsOfDay}
                specialities={specialities}
              />
            ),
            rowExpandable: (record) => status !== WH_PARTNER_STATUS.WAITING
          }}
        />
      )}
      <Modal
        destroyOnClose
        visible={isOpenModal}
        onOk={handleCloseModal}
        footer={null}
        onCancel={handleCloseModal}
      >
        <FormConvertPartner
          id={whPartner?._id}
          onConfirm={onConfirm}
          isLoadingConfirm={confirmLoading}
        />
      </Modal>
    </div>
    // </div>
  );
};

export default WhPartners;
