import requester from './requester';

const systemConfiguration = {
  getAll: (query) => requester.get('/system-configuration', query),
  getById: id => requester.get(`/system-configuration/${id}?raw=true`),
  delete: id => requester.delete(`/system-configuration/${id}`),
  create: systemConfiguration => requester.post('/system-configuration', systemConfiguration),
  update: systemConfiguration =>
    requester.put(`/system-configuration/${systemConfiguration.id}`, systemConfiguration)
};

export default systemConfiguration;
