import {
  GET_WH_APPOINTMENTS_REQUEST,
  GET_WH_APPOINTMENT_REQUEST,
  CREATE_WH_APPOINTMENT_REQUEST,
  ADD_WH_APPOINTMENTS_LOCALLY,
  REMOVE_WH_APPOINTMENTS_LOCALLY,
  UPDATE_WH_APPOINTMENT_REQUEST,
  CHECK_IN_WH_APPOINTMENT_REQUEST,
  CHECK_OUT_WH_APPOINTMENT_REQUEST,
  DELETE_WH_APPOINTMENT_REQUEST,
  RESET_WH_APPOINTMENT_STATE,
  UPDATE_LIST_PRODUCT_APPOINTMENT_REQUEST,
  RESET_LIST_PRODUCT_WH_APPOINTMENT_STATE,
} from '../../constants/actionTypes';

export const getWhAppointments = query => ({
  type: GET_WH_APPOINTMENTS_REQUEST,
  payload: query
});

export const getWhAppointment = id => ({
  type: GET_WH_APPOINTMENT_REQUEST,
  payload: id
});

export const createWhAppointment = instance => ({
  type: CREATE_WH_APPOINTMENT_REQUEST,
  payload: instance
});

export const addWhAppointmentsLocally = instances => ({
  type: ADD_WH_APPOINTMENTS_LOCALLY,
  payload: instances
});

export const removeWhAppointmentsLocally = func => ({
  type: REMOVE_WH_APPOINTMENTS_LOCALLY,
  payload: func
});

export const updateWhAppointment = instance => ({
  type: UPDATE_WH_APPOINTMENT_REQUEST,
  payload: instance
});

export const checkInWhAppointment = instance => ({
  type: CHECK_IN_WH_APPOINTMENT_REQUEST,
  payload: instance
});

export const checkOutWhAppointment = instance => ({
  type: CHECK_OUT_WH_APPOINTMENT_REQUEST,
  payload: instance
});

export const deleteWhAppointment = id => ({
  type: DELETE_WH_APPOINTMENT_REQUEST,
  payload: id
});

export const resetWhAppointmentState = () => ({
  type: RESET_WH_APPOINTMENT_STATE
});

// export const getWhAppointmentGroups = () => ({
//   type: GET_WH_APPOINTMENT_GROUPS_REQUEST
// });

export const updateListProductAppointment = appointment => ({
  type: UPDATE_LIST_PRODUCT_APPOINTMENT_REQUEST,
  payload: appointment
});

export const resetListProductWhAppointmentState = () => ({
  type: RESET_LIST_PRODUCT_WH_APPOINTMENT_STATE
});
