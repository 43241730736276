import produce from 'immer';
import {
  GET_APPOINTMENTS_ASSOCIATE_REQUEST,
  GET_APPOINTMENTS_ASSOCIATE_SUCCESS,
  GET_APPOINTMENTS_ASSOCIATE_FAILED,
  GET_APPOINTMENT_ASSOCIATE_REQUEST,
  GET_APPOINTMENT_ASSOCIATE_SUCCESS,
  GET_APPOINTMENT_ASSOCIATE_FAILED,
  DELETE_APPOINTMENT_ASSOCIATE_REQUEST,
  DELETE_APPOINTMENT_ASSOCIATE_SUCCESS,
  DELETE_APPOINTMENT_ASSOCIATE_FAILED,
  CREATE_APPOINTMENT_ASSOCIATE_REQUEST,
  CREATE_APPOINTMENT_ASSOCIATE_SUCCESS,
  CREATE_APPOINTMENT_ASSOCIATE_FAILED,
  UPDATE_APPOINTMENT_ASSOCIATE_REQUEST,
  UPDATE_APPOINTMENT_ASSOCIATE_SUCCESS,
  UPDATE_APPOINTMENT_ASSOCIATE_FAILED,
  RESET_APPOINTMENT_ASSOCIATE
} from '../../constants/actionTypes';

const INIT_STATE = {
  isLoading: false,
  getAppointmentsAssociateFailed: undefined,
  appointmentsAssociate: [],

  isGetAppointmentAssociateLoading: false,
  appointmentAssociate: null,
  getAppointmentAssociateFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_APPOINTMENTS_ASSOCIATE_REQUEST:
      state.isLoading = true;
      state.getAppointmentsAssociateFailed = null;
      return;

    case GET_APPOINTMENTS_ASSOCIATE_SUCCESS:
      state.isLoading = false;
      state.appointmentsAssociate = payload;
      return;

    case GET_APPOINTMENTS_ASSOCIATE_FAILED:
      state.isLoading = false;
      state.getAppointmentsAssociateFailed = payload;
      return;
    case GET_APPOINTMENT_ASSOCIATE_REQUEST:
      state.isGetAppointmentAssociateLoading = true;
      state.appointmentAssociate = null;
      state.getAppointmentAssociateFailed = null;
      return;

    case GET_APPOINTMENT_ASSOCIATE_SUCCESS:
      state.isGetAppointmentAssociateLoading = false;
      state.appointmentAssociate = payload;
      return;

    case GET_APPOINTMENT_ASSOCIATE_FAILED:
      state.isGetAppointmentAssociateLoading = false;
      state.getAppointmentAssociateFailed = payload;
      return;

    case DELETE_APPOINTMENT_ASSOCIATE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_APPOINTMENT_ASSOCIATE_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_APPOINTMENT_ASSOCIATE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_APPOINTMENT_ASSOCIATE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_APPOINTMENT_ASSOCIATE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_APPOINTMENT_ASSOCIATE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case UPDATE_APPOINTMENT_ASSOCIATE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_APPOINTMENT_ASSOCIATE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.hospital = payload;
      return;

    case UPDATE_APPOINTMENT_ASSOCIATE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case RESET_APPOINTMENT_ASSOCIATE:
      state.isLoading = false;
      state.getAppointmentsAssociateFailed = undefined;

      state.isGetAppointmentAssociateLoading = false;
      state.appointmentAssociate = null;
      state.getAppointmentAssociateFailed = null;

      state.deleteSuccess = null;
      state.deleteFailed = null;

      state.isSubmitLoading = false;
      state.createSuccess = null;
      state.createFailed = null;

      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    default:
      return;
  }
}, INIT_STATE);
