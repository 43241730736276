import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getWhContacts({ payload: query }) {
  try {
    const data = yield call(Api.whContact.getAll, query);
    yield put({ type: Types.GET_WH_CONTACTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_CONTACTS_FAILED,
      payload: error.message
    });
  }
}

// function* getWhContact({ payload: id }) {
//   try {
//     const whContact = yield call(Api.whContact.getById, id);
//     yield put({ type: Types.GET_WH_CONTACT_SUCCESS, payload: whContact });
//   } catch (error) {
//     yield put({ type: Types.GET_WH_CONTACT_FAILED, payload: error.message });
//   }
// }

// function* createWhContact(action) {
//   try {
//     const data = yield call(Api.whContact.create, action.payload);
//     yield put({ type: Types.CREATE_WH_CONTACT_SUCCESS, payload: data });
//   } catch (error) {
//     yield put({
//       type: Types.CREATE_WH_CONTACT_FAILED,
//       payload: error.message
//     });
//   }
// }

// function* updateWhContact(action) {
//   try {
//     const data = yield call(Api.whContact.update, action.payload);
//     yield put({ type: Types.UPDATE_WH_CONTACT_SUCCESS, payload: data });
//   } catch (error) {
//     yield put({
//       type: Types.UPDATE_WH_CONTACT_FAILED,
//       payload: error.message
//     });
//   }
// }

// function* deleteWhContact({ payload }) {
//   try {
//     yield call(Api.whContact.delete, payload);
//     yield put({ type: Types.DELETE_WH_CONTACT_SUCCESS, payload });
//   } catch (error) {
//     yield put({
//       type: Types.DELETE_WH_CONTACT_FAILED,
//       payload: error.message
//     });
//   }
// }

export default function* whContact() {
  yield takeLatest(Types.GET_WH_CONTACTS_REQUEST, getWhContacts);
  // yield takeLatest(Types.GET_WH_CONTACT_REQUEST, getWhContact);
  // yield takeLatest(Types.DELETE_WH_CONTACT_REQUEST, deleteWhContact);
  // yield takeLatest(Types.CREATE_WH_CONTACT_REQUEST, createWhContact);
  // yield takeLatest(Types.UPDATE_WH_CONTACT_REQUEST, updateWhContact);
}
