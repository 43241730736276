import React, { useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { FilterDatePicker } from '~/components/Common';
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Tabs,
  Table,
  Modal,
  Upload,
  message
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import WhCustomersImportForm from './Form';
import ImportForm from './ImportForm';
import { PATH_APP } from '~/routes/paths';
import WithPermission from '~/components/Common/WithPermission';
// import './index.scss';

const { Search } = Input;
function callback(key) {
  // console.log(key);
}
function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const genders = [
  {
    id: 'F',
    gender: 'Female'
  },
  {
    id: 'M',
    gender: 'Male'
  }
];
const data = [
  {
    id: '1',
    fullName: 'Huynh Duy Khanh',
    birthYear: '2002',
    gender: 'Male',
    phoneNumber: '0912345678',
    address: 'Da Nang',
    createDay: '16/10/2022',
    objectGroup: 'Thong tin 1'
  },
  {
    id: '2',
    fullName: 'Mai Ngoc Anh',
    birthYear: '2005',
    gender: 'Female',
    phoneNumber: '0912345456',
    address: 'Da Nang',
    createDay: '17/10/2022',
    objectGroup: 'Thong tin 2'
  },
  {
    id: '3',
    fullName: 'Le Huong Truc',
    birthYear: '2003',
    gender: 'Female',
    phoneNumber: '0912345123',
    address: 'Da Nang',
    createDay: '18/10/2022',
    objectGroup: 'Thong tin 3'
  },
  {
    id: '4',
    fullName: 'Thuy Trang',
    birthYear: '2004',
    gender: 'Female',
    phoneNumber: '0912345091',
    address: 'Da Nang',
    createDay: '19/10/2022',
    objectGroup: 'Thong tin 4'
  }
];

const ColumnActions = ({ _id, onOpenForm }) => {
  return (
    <div className="custom-table__actions">
      <WithPermission >
        <Button onClick={() => onOpenForm(_id)}> Convert</Button>
      </WithPermission>
    </div>
  );
};

const WhCustomersImport = () => {
  const [fileList, setFileList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [importId, setImportId] = useState(null);
  const [searchBy, setSearchBy] = useState('fullName');
  const [current, setCurrent] = useState(1);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const onOpenForm = (id) => {
    setImportId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setImportId(null);
    setIsOpenForm(false);
  };

  //   const onSearch = () => {
  //     let searchParams = `?page=${query.page}&limit=${query.limit}`;
  //     if (keyword) searchParams += `&${searchBy}=${keyword}`;
  //     if (activeKey === '2') {
  //       searchParams += `&waitingApproval=true`;
  //     }

  //     history.push({
  //       pathname: '/wh-partner',
  //       search: searchParams
  //     });
  //   };
  const props = {
    beforeUpload: (file) => {
      const isXLS = file.type === 'file/xls';
      if (!isXLS) {
        message.error(`${file.name} is not a xls file`);
      }
      return isXLS || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    }
  };
  const onPagingChangeLocal = ({ current, pageSize, total }, someObj) => {
    setCurrent(current);
  };

  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '70px',
      render: (text, record, index) => {
        return (+pagination.page - 1) * pagination.limit + index + 1;
      }
    },

    {
      title: 'Họ và tên',
      key: 'fullName',
      width: '250px',
      render: (record) => record.fullName
    },

    {
      title: 'Năm sinh',
      key: 'birthYear',
      with: '150px',
      render: (record) => record.birthYear
    },

    {
      title: 'Giới tính',
      key: 'gender',
      width: '100px',
      render: (record) => record.gender
    },
    {
      title: 'Số điện thoại',
      key: 'phoneNumber',
      render: (record) => {
        return record.phoneNumber;
      }
    },

    {
      title: 'Nhóm đối tượng',
      key: 'objectGroup',
      render: (record) => record.objectGroup
    },

    {
      title: 'Địa chỉ',
      key: 'address',
      width: '270px',
      render: (record) => record.address
    },
    {
      title: 'Ngày tạo',
      key: 'createDay',
      render: (record) => record.createDay
    },

    {
      title: 'Ghi chú',
      key: 'notes',
      width: '170px',
      render: (record) => record.notes
    },
    {
      title: 'Hành động',
      key: 'action',
      width: '110px',
      render: (record) => {
        record = {
          ...record,
          id: record._id
        };
        return <ColumnActions {...record} onOpenForm={onOpenForm} />;
      }
    }
  ];
  return (
    <div className="page-wrapper page-content whCustomerManagements-page">
      <div className="container-fluid">
        <Breadcrumb title="Khách hàng tiềm năng" />
        <div className="page-wraper__header">
          <Row justify="start" gutter={36}>
            <Col sm={24} md={6}>
              <Select
                value={searchBy}
                style={{ width: '100%' }}
                onChange={(val) => setSearchBy(val)}
              >
                <Option value="fullName">Họ và tên</Option>
                <Option value="birthYear">Năm sinh</Option>
                <Option value="gender">Giới tính</Option>
                <Option value="phoneNumber">Số điện thoại</Option>
                <Option value="address">Địa chỉ</Option>
                <Option value="createDay">Ngày tạo</Option>
                <Option value="objectGroup">Nhóm đối tượng</Option>
              </Select>
            </Col>

            <Col sm={24} md={6}>
              {
                {
                  fullName: (
                    <Search
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Tìm theo tên"
                      enterButton
                      //   onSearch={onSearch}
                      //   onChange={(e) => setKeyword(e.target.value)}
                      //   value={keyword}
                    />
                  ),
                  birthYear: (
                    <Search
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Tìm theo năm sinh"
                      enterButton
                      //   onSearch={onSearch}
                      //   onChange={(e) => setKeyword(e.target.value)}
                      //   value={keyword}
                    />
                  ),

                  gender: (
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      //   loading={isWhServicesLoading}
                      showSearch
                      autoComplete="off"
                      onChange={(e) => setKeyword(e)}
                    >
                      {/* fake api */}
                      {genders.map(({ id, gender }) => (
                        <Option value={id}>{gender}</Option>
                      ))}
                    </Select>
                  ),
                  phoneNumber: (
                    <Search
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Tìm theo số điện thoại"
                      enterButton
                      //   onSearch={onSearch}
                      //   onChange={(e) => setKeyword(e.target.value)}
                      //   value={keyword}
                    />
                  ),

                  address: (
                    <Search
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Tìm theo địa chỉ"
                      enterButton
                      //   onSearch={onSearch}
                      //   onChange={(e) => setKeyword(e.target.value)}
                      //   value={keyword}
                    />
                  ),

                  createDay: (
                    <Search
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Tìm theo ngày tạo"
                      enterButton
                      //   onSearch={onSearch}
                      //   onChange={(e) => setKeyword(e.target.value)}
                      //   value={keyword}
                    />
                  ),

                  objectGroup: (
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      //   loading={isStaffGroupLoading}
                      showSearch
                      autoComplete="off"
                      onChange={(e) => setKeyword(e)}
                    >
                      {data.map(({ id, objectGroup }) => (
                        <Option value={id}>{objectGroup}</Option>
                      ))}
                    </Select>
                  )
                }[searchBy]
              }
            </Col>

            <Col sm={24} md={12}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => {
                  setIsOpen(true);
                  setImportId(null);
                }}
              >
                {' '}
                Thêm mới
              </Button>

              <div>
                <Button
                  type="primary"
                  style={{ float: 'right', marginRight: '10px' }}
                  onClick={() => onOpenForm()}
                >
                  {' '}
                  Import
                </Button>
                {/* <ImportForm isOpen={isOpenForm} onClose={onCloseForm}/> */}
              </div>
            </Col>
          </Row>
        </div>
        <Table
          className="wh-degree-table-striped-rows"
          //   rowKey={(rc) => rc._id || rc.id}
          columns={columns}
          dataSource={data}
          onChange={onPagingChangeLocal}
          size="middle"
          pagination={{
            current,
            showTotal: (total) => `Tổng cộng: ${total} `,
            onChange: (page) => {
              setPagination({ ...pagination, page: page });
            }
          }}
        />
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <WhCustomersImportForm
          //   specialityOptions={specialityOptions}
          id={importId}
          onClose={() => setIsOpen(false)}
        />
      </Modal>
      {/* <ImportForm isOpen={isOpenForm} onClose={onCloseForm} id={importId}/> */}
    </div>
  );
};

export default WhCustomersImport;
