import requester from './requester';

const whSessionOfDay = {
  getAll: query => requester.get('/wh-session-of-day', query),
  getById: id => requester.get(`/wh-session-of-day/${id}?raw=true`),
  delete: id => requester.delete(`/wh-session-of-day/${id}`),
  create: whSessionOfDay => requester.post('/wh-session-of-day', whSessionOfDay),
  update: whSessionOfDay =>
    requester.put(`/wh-session-of-day/${whSessionOfDay.id}`, whSessionOfDay)
};

export default whSessionOfDay;
