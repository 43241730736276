import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  deleteCompanyJob,
  getCompanyJob,
  getCompanyJobs,
  resetCompanyJobState,
  updateCompanyJob,
  createCompanyJob,
  resetCompanyJobStateAction,
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.companyJob[key];
const pagingSelector = getSelector('paging');
export const useCompanyJobPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const isGetCompanyJobLoading = getSelector('isGetCompanyJobLoading');
const getCompanyJobsFailedSelector = getSelector('getCompanyJobsFailed');
const getCompanyJobFailedSelector = getSelector('getCompanyJobFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const CompanyJobsSelector = getSelector('CompanyJobs');
const CompanyJobSelector = getSelector('CompanyJob');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useCompanyJobQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const createCompanyJobSuccess = useSelector(createSuccessSelector);
  const deleteCompanyJobSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createCompanyJobSuccess,
    deleteCompanyJobSuccess,
  ]);
};

export const useUpdateCompanyJobParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.companyJob.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};
export const useCompanyJobs = (query) => {
  return useFetchByParam({
    action: getCompanyJobs,
    loadingSelector,
    dataSelector: CompanyJobsSelector,
    failedSelector: getCompanyJobsFailedSelector,
    param: query
  });
};
export const useCompanyJob = (id) => {
  return useFetchByParam({
    action: getCompanyJob,
    loadingSelector: isGetCompanyJobLoading,
    dataSelector: CompanyJobSelector,
    failedSelector: getCompanyJobFailedSelector,
    param: id
  });
};
export const useUpdateCompanyJob = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât công ty thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateCompanyJob
  });
};

export const useCreateCompanyJob = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới công ty thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createCompanyJob
  });
};
export const useDeleteCompanyJob = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá công ty thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật công ty thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteCompanyJob
  });
};

export const useResetCompanyJob = () => {
  useResetState(resetCompanyJobState);
};
export const useResetActionCompanyJob = () => {
  useResetState(resetCompanyJobStateAction);
};
