export const GET_WAREHOUSE_VOUCHERS_REQUEST =
  'GET_WAREHOUSE_VOUCHERS_REQUEST';
export const GET_WAREHOUSE_VOUCHERS_SUCCESS =
  'GET_WAREHOUSE_VOUCHERS_SUCCESS';
export const GET_WAREHOUSE_VOUCHERS_FAILED = 'GET_WAREHOUSE_VOUCHERS_FAILED';

export const GET_WAREHOUSE_VOUCHERS_PARTNER_REQUEST =
  'GET_WAREHOUSE_VOUCHERS_PARTNER_REQUEST';
export const GET_WAREHOUSE_VOUCHERS_PARTNER_SUCCESS =
  'GET_WAREHOUSE_VOUCHERS_PARTNER_SUCCESS';
export const GET_WAREHOUSE_VOUCHERS_PARTNER_FAILED = 'GET_WAREHOUSE_VOUCHERS_PARTNER_FAILED';

export const GET_WAREHOUSE_VOUCHER_REQUEST = 'GET_WAREHOUSE_VOUCHER_REQUEST';
export const GET_WAREHOUSE_VOUCHER_SUCCESS = 'GET_WAREHOUSE_VOUCHER_SUCCESS';
export const GET_WAREHOUSE_VOUCHER_FAILED = 'GET_WAREHOUSE_VOUCHER_FAILED';

export const GET_WAREHOUSE_VOUCHER_PARTNER_REQUEST = 'GET_WAREHOUSE_VOUCHER_PARTNER_REQUEST';
export const GET_WAREHOUSE_VOUCHER_PARTNER_SUCCESS = 'GET_WAREHOUSE_VOUCHER_PARTNER_SUCCESS';
export const GET_WAREHOUSE_VOUCHER_PARTNER_FAILED = 'GET_WAREHOUSE_VOUCHER_PARTNER_FAILED';

export const CREATE_WAREHOUSE_VOUCHER_REQUEST =
  'CREATE_WAREHOUSE_VOUCHER_REQUEST';
export const CREATE_WAREHOUSE_VOUCHER_SUCCESS =
  'CREATE_WAREHOUSE_VOUCHER_SUCCESS';
export const CREATE_WAREHOUSE_VOUCHER_FAILED =
  'CREATE_WAREHOUSE_VOUCHER_FAILED';

export const CREATE_WAREHOUSE_VOUCHER_PARTNER_REQUEST =
  'CREATE_WAREHOUSE_VOUCHER_PARTNER_REQUEST';
export const CREATE_WAREHOUSE_VOUCHER_PARTNER_SUCCESS =
  'CREATE_WAREHOUSE_VOUCHER_PARTNER_SUCCESS';
export const CREATE_WAREHOUSE_VOUCHER_PARTNER_FAILED =
  'CREATE_WAREHOUSE_VOUCHER_PARTNER_FAILED';

export const UPDATE_WAREHOUSE_VOUCHER_REQUEST =
  'UPDATE_WAREHOUSE_VOUCHER_REQUEST';
export const UPDATE_WAREHOUSE_VOUCHER_SUCCESS =
  'UPDATE_WAREHOUSE_VOUCHER_SUCCESS';
export const UPDATE_WAREHOUSE_VOUCHER_FAILED =
  'UPDATE_WAREHOUSE_VOUCHER_FAILED';

  export const DELETE_WAREHOUSE_VOUCHER_REQUEST =
  'DELETE_WAREHOUSE_VOUCHER_REQUEST';
export const DELETE_WAREHOUSE_VOUCHER_SUCCESS =
  'DELETE_WAREHOUSE_VOUCHER_SUCCESS';
export const DELETE_WAREHOUSE_VOUCHER_FAILED =
  'DELETE_WAREHOUSE_VOUCHER_FAILED';


export const RESET_WAREHOUSE_VOUCHER_STATE = 'RESET_WAREHOUSE_VOUCHER_STATE';
export const RESET_ACTION_WAREHOUSE_VOUCHER_STATE = 'RESET_ACTION_WAREHOUSE_VOUCHER_STATE';

export const PARTNER_CONFIRM_WAREHOUSE_VOUCHER_REQUEST =
  'PARTNER_CONFIRM_WAREHOUSE_VOUCHER_REQUEST';
export const PARTNER_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS =
  'PARTNER_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS';
export const PARTNER_CONFIRM_WAREHOUSE_VOUCHER_FAILED =
  'PARTNER_CONFIRM_WAREHOUSE_VOUCHER_FAILED';

  export const PARTNER_REJECT_WAREHOUSE_VOUCHER_REQUEST =
  'PARTNER_REJECT_WAREHOUSE_VOUCHER_REQUEST';
export const PARTNER_REJECT_WAREHOUSE_VOUCHER_SUCCESS =
  'PARTNER_REJECT_WAREHOUSE_VOUCHER_SUCCESS';
export const PARTNER_REJECT_WAREHOUSE_VOUCHER_FAILED =
  'PARTNER_REJECT_WAREHOUSE_VOUCHER_FAILED';

export const EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_REQUEST =
  'EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_REQUEST';
export const EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS =
  'EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS';
export const EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_FAILED =
  'EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_FAILED';

  export const EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_REQUEST =
  'EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_REQUEST';
export const EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_SUCCESS =
  'EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_SUCCESS';
export const EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_FAILED =
  'EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_FAILED';


export const SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_REQUEST =
  'SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_REQUEST';
export const SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS =
  'SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS';
export const SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_FAILED =
  'SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_FAILED';

  export const SUPPLIER_REJECT_WAREHOUSE_VOUCHER_REQUEST =
  'SUPPLIER_REJECT_WAREHOUSE_VOUCHER_REQUEST';
export const SUPPLIER_REJECT_WAREHOUSE_VOUCHER_SUCCESS =
  'SUPPLIER_REJECT_WAREHOUSE_VOUCHER_SUCCESS';
export const SUPPLIER_REJECT_WAREHOUSE_VOUCHER_FAILED =
  'SUPPLIER_REJECT_WAREHOUSE_VOUCHER_FAILED';
