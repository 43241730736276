import React from 'react';

function Row({ style, children,className="" }) {
  return (
    <div className={className} style={{ display: 'flex', alignItems: 'center',gap : 5, ...style }}>{children}</div>
  )
};

function Columns({ style, children }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column',gap : 5, ...style }}>{children}</div>
  )
}


export default {
  Row,
  Columns
}
