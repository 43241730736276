import { EditOutlined, MinusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import LinkFile from '~/components/Common/TypeFile/LinkFileImage';
import { WH_COURSE_TRAINING_STATUS, WH_COURSE_TRAINING_STATUS_VI } from '~/constants/defaultValue';
import { useProfile } from '~/hooks';
import { useUpdateTrainingCoursePartnerInTimeSheet } from '~/hooks/timeSheet';
import {
  useGetDateExists,
  useUpdateTrainingCoursePartner
} from '~/hooks/trainingCoursePartner';
const FormItem = Form.Item;
export default function DetailCorseOfTimeTraining({ dataSource, record }) {
  const [form] = Form.useForm();
  const [profile] = useProfile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [isModalDenyOpen, setIsModalDenyOpen] = useState(false);
  const [selectCourse, setSelectCourse] = useState(null);
  const query = selectCourse
    ? { courseId: get(selectCourse, 'courseId'), month: moment().format('MM') }
    : { courseId: null, month: null };
  const [dateExist, loading] = useGetDateExists(query);
  const handleOpenModal = (selectCourse) => {
    setIsModalOpen(true);
    setSelectCourse(selectCourse);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectCourse(null);
  };
  const handleOpenModalDeny = (selectCourse) => {
    setIsModalDenyOpen(true);
    setSelectCourse(selectCourse);
  };
  const handleCloseModalDeny = () => {
    setIsModalDenyOpen(false);
    setSelectCourse(null);
  };
  const [loadingUpdate, updateCoursePartner] =
    useUpdateTrainingCoursePartnerInTimeSheet(() => {
      handleCloseModalDeny();
      handleCloseModal();
    });
  const onFinish = (values) => {
    const submitData = {
      ...values,
      date: moment(values.date).format('YYYY-MM-DD'),
      courseId: get(record, 'course_training._id', ''),
      courseIdFull: get(record, 'courseId', ''),
      files: get(record, 'files', []),
      partnerId: get(record, 'partnerId', ''),
      typeOf: 'admin',
      userIdAction: get(profile, '_id', ''),
      _id: get(selectCourse, '_id', ''),
      userId: get(record, 'userId', '')
    };
    updateCoursePartner(submitData);
  };
  const handleDeny = () => {
    const dataActpec = get(record, 'course', [])
      .filter(
        (e) =>
          get(e, '_id') !== get(selectCourse, '_id') &&
          get(e, 'status', '') !== WH_COURSE_TRAINING_STATUS.DENIED
      )
      .reduce(
        (sum, arr) => {
          sum.totalHours += arr.time;
          sum.totalReceived += arr.time * arr.snapShortrateTraining;
          return sum;
        },
        { totalHours: 0, totalReceived: 0 }
      );
    const submitData = {
      ...selectCourse,
      userIdAction: get(profile, '_id', ''),
      typeOf: 'admin',
      status: WH_COURSE_TRAINING_STATUS.DENIED,
      comment,
      ...dataActpec,
      idTimeSheet: get(record, '_id')
    };
    updateCoursePartner(submitData);
  };
  useEffect(() => {
    form.resetFields();
    if (selectCourse) {
      const { date, time, content } = selectCourse;
      form.setFieldsValue({
        date: moment(date),
        time,
        content,
        courseId: get(record, 'course_training.courseIdFull', ''),
        userId: get(record, 'user.fullName', '')
      });
    }
  }, [selectCourse]);
  const handleChangeDate = (date, dateString) => {};
  const columns = [
    {
      title: 'STT',
      dataIndex: null,
      key: null,
      render: (text, record, index) => <p>{index + 1}</p>
    },
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => (
        <p>{moment(text).format('DD/MM/YYYY') || ''}</p>
      )
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
      render: (text, record, index) => <p>{text || ''}</p>
    },
    {
      title: 'File đính kèm',
      dataIndex: 'files',
      key: 'files',
      render: (text, record, index) => (
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'start'
          }}
        >
          {text?.map((e) => {
            return (
              <LinkFile
                key={v4()}
                href={get(e, 'response.url')}
                url={get(e, 'name')}
              />
            );
          })}
        </div>
      )
    },
    {
      title: 'Số giờ',
      dataIndex: 'time',
      align: 'center',
      key: 'time',
      render: (text, record, index) => <h6>{text || ''}</h6>
    },
    {
      title: 'Ghi chú',
      dataIndex: 'comment',
      align: 'center',
      key: 'comment',
      render: (text, record, index) => <h6>{text}</h6>
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      align: 'center',
      key: 'status',
      render: (text, record, index) => (
        <span className={`course-status ${text}`}>{WH_COURSE_TRAINING_STATUS_VI[text] || ''}</span>
      )
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      render: (text, course, index) => (
        <>
          <Button
            disabled={[
              WH_COURSE_TRAINING_STATUS.APPROVED,
              WH_COURSE_TRAINING_STATUS.DENIED
            ].includes(get(course, 'status', '')) ||
            [
              WH_COURSE_TRAINING_STATUS.DENIED
            ].includes(get(course, 'status', ''))}
            danger
            onClick={() => handleOpenModalDeny(course)}
          >
            <MinusOutlined />
          </Button>
          <Button
            disabled={[
              WH_COURSE_TRAINING_STATUS.APPROVED,
              WH_COURSE_TRAINING_STATUS.DENIED
            ].includes(get(course, 'status', ''))}
            onClick={() => handleOpenModal(course)}
          >
            <EditOutlined />
          </Button>
        </>
      )
    }
  ];
  return (
    <>
      <Table
        rowKey={(rc) => rc._id}
        columns={columns}
        dataSource={dataSource || []}
        size="middle"
      />
      <Modal
        footer={[]}
        onCancel={handleCloseModal}
        visible={isModalOpen}
        closable={false}
      >
        <Form
          labelCol={{ sm: 10, md: 10, lg: 10, xl: 10 }}
          wrapperCol={{ sm: 14, md: 14, lg: 14, xl: 14 }}
          form={form}
          onFinish={onFinish}
        >
          <FormItem
            label="Khoá đào tạo"
            name="courseId"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng chọn Khoá đào tạo'
              }
            ]}
          >
            <Input disabled bordered={false} />
          </FormItem>
          <FormItem label="Người chịu trách nhiệm" name="userId">
            <Input disabled bordered={false} />
          </FormItem>
          <FormItem
            label="Ngày"
            name="date"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng chọn ngày'
              }
            ]}
          >
            <DatePicker
              allowClear={false}
              onChange={handleChangeDate}
              className="home-service-form__form-item--date-picker custom-input-remove"
              // disabled={!form.getFieldValue('courseId')}
              disabledDate={(current) =>
                current <= moment().startOf('month') ||
                current >= moment().endOf('month') ||
                dateExist?.some(
                  (e) =>
                    moment(current).format('YYYY-MM-DD') ===
                    moment(e.date).format('YYYY-MM-DD')
                )
              }
              format="DD/MM/YYYY"
              placeholder="Chọn ngày"
            />
          </FormItem>
          <FormItem
            label="Số giờ"
            name="time"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập số giờ'
              }
            ]}
          >
            <InputNumber min={0} max={24} />
          </FormItem>
          <FormItem
            label="Nội dung"
            name="content"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập nội dung'
              }
            ]}
          >
            <Input />
          </FormItem>
          <Row className="group-buttonMy">
            <Col span={10}>
              <Button loading={loadingUpdate} htmlType="submit" type="primary">
                Cập nhật
              </Button>
            </Col>
            <Col span={10}>
              <Button onClick={handleCloseModal}>Huỷ</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Từ chối khoá học"
        footer={null}
        onCancel={handleCloseModalDeny}
        visible={isModalDenyOpen}
        closable={false}
      >
        <TextArea
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Lý do từ chối"
        />
        <Row
          style={{ marginTop: '10px' }}
          justify="space-around"
          align="center"
        >
          <Col span={8}>
            <Button
              onClick={handleDeny}
              loading={loadingUpdate}
              type="primary"
              style={{ width: '100%' }}
            >
              Xác nhận
            </Button>
          </Col>
          <Col span={8}>
            <Button onClick={handleCloseModalDeny} style={{ width: '100%' }}>
              Huỷ
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
