import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getRewardItems({ payload: query }) {
    try {
      const response = yield call(Api.rewardItem.getAll, query);
      yield put({ type: Types.GET_REWARD_ITEMS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_REWARD_ITEMS_FAILED, payload: error });
    }
  }
function* getRewardItem({ payload: id }) {
    try {
      const response = yield call(Api.rewardItem.getById, id);
      yield put({ type: Types.GET_REWARD_ITEM_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_REWARD_ITEM_FAILED, payload: error });
    }
  }
  function* createRewardItem(action) {
    try {
      const data = yield call(Api.rewardItem.create, action.payload);
      yield put({ type: Types.CREATE_REWARD_ITEM_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_REWARD_ITEM_FAILED, payload: error });
    }
  }
  function* updateRewardItem(action) {
    try {
      const data = yield call(Api.rewardItem.update, action.payload);
      yield put({ type: Types.UPDATE_REWARD_ITEM_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_REWARD_ITEM_FAILED, payload: error });
    }
  }
  function* deleteRewardItem(action) {
    try {
      const data = yield call(Api.rewardItem.delete, action.payload);
      yield put({ type: Types.DELETE_REWARD_ITEM_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_REWARD_ITEM_FAILED, payload: error.message });
    }
  }
export default function* RewardItems() {
    yield takeLatest(Types.GET_REWARD_ITEMS_REQUEST, getRewardItems);
    yield takeLatest(Types.GET_REWARD_ITEM_REQUEST, getRewardItem);
    yield takeLatest(Types.CREATE_REWARD_ITEM_REQUEST, createRewardItem);
    yield takeLatest(Types.UPDATE_REWARD_ITEM_REQUEST, updateRewardItem);
    yield takeLatest(Types.DELETE_REWARD_ITEM_REQUEST, deleteRewardItem);
  }
