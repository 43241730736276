import ErrorBase from "~/utils/ErrorBase";


class NotEquipment extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Không đủ CCDC";
    }
}
class NotMedical extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Không đủ nguyên vật liệu";
    }
}
class NotPartner extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Không có đối tác";
    }
}
class WhAppointmentCompleted extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Cuộc hẹn đã hoàn thành";
    }
}
class WhAppointmentNotCompleted extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Cuộc hẹn chưa hoàn thành";
    }
}
class NotEquipmentAndMedical extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Không có CCDC/NVL";
    }
}
class NotEquipmentToCreateVoucherDelivery extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Không có máy nào có thể tạo phiếu bàn giao";
    }
}
class NotEquipmentToCreateVoucherRefund extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Không có máy nào có thể tạo phiếu hoàn trả";
    }
}
class isHaveRefundTypeWhBillVoucher extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Đã có phiếu bàn giao";
    }
}
class isDelivering extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Đã được bàn giao";
    }
}
class GroupInitIsBlock extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Nhóm thiết bị đã ngưng sử dụng";
    }
}
class WhAppointmentCompletedAndDelivery extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Cuộc hẹn đã hoàn thành và đã bàn giao";
    }
}
class isHaveVoucher extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Cuộc hẹn đã được tạo phiếu bàn giao";
    }
}
class NotHaveGroupMedical extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Không có nhóm Nguyên vật liệu được thiết lập";
    }
}
class actioningOrtherAction extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Đang thực hiện hành động khác";
    }
}
class isFullEquipmentAndCompletedAppointment extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Tất cả Thiết bị đã được bàn giao và cuộc hẹn đã hoàn thành";
    }
}
class isHaveWhAppointmentHaveSameProduct extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Có buổi hẹn khác đã hoàn thành và có thiết bị giống với buổi hẹn này, vui lòng dùng chế độ gộp phiếu để hoàn trả";
    }
}
class haveConfirmVoucher extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Đã có phiếu xác nhận";
    }
}

export default {
    NotEquipment,
    NotMedical,
    NotPartner,
    WhAppointmentCompleted,
    NotEquipmentAndMedical,
    NotEquipmentToCreateVoucherDelivery,
    isHaveRefundTypeWhBillVoucher,
    WhAppointmentNotCompleted,
    isDelivering,
    GroupInitIsBlock,
    WhAppointmentCompletedAndDelivery,
    isHaveVoucher,
    NotHaveGroupMedical,
    isFullEquipmentAndCompletedAppointment,
    actioningOrtherAction,
    NotEquipmentToCreateVoucherRefund,
    isHaveWhAppointmentHaveSameProduct,
    haveConfirmVoucher,
}
