import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getListTrials({ payload: query }) {
    try {
      const response = yield call(Api.listTrial.getAll, query);
      yield put({ type: Types.GET_LIST_TRIALS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_LIST_TRIALS_FAILED, payload: error });
    }
  }
function* getListTrial({ payload: id }) {
    try {
      const response = yield call(Api.listTrial.getOne, id);
      yield put({ type: Types.GET_LIST_TRIAL_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_LIST_TRIAL_FAILED, payload: error });
    }
  }
  function* updateListTrial(action) {
    try {
      const data = yield call(Api.listTrial.update, action.payload);
      yield put({ type: Types.UPDATE_LIST_TRIAL_SUCCESS, payload: data });
      yield put({ type: Types.RESET_LIST_TRIAL_ACTION});
    } catch (error) {
      yield put({ type: Types.UPDATE_LIST_TRIAL_FAILED, payload: error });
    }
  }
  function* createListTrial(action) {
    try {
      const data = yield call(Api.listTrial.create, action.payload);
      yield put({ type: Types.CREATE_LIST_TRIAL_SUCCESS, payload: data });
      yield put({ type: Types.RESET_LIST_TRIAL_ACTION});
    } catch (error) {
      yield put({ type: Types.CREATE_LIST_TRIAL_FAILED, payload: error });
    }
  }
  function* deleteListTrial(action) {
    try {
      const data = yield call(Api.listTrial.delete, action.payload);
      yield put({ type: Types.DELETE_LIST_TRIAL_SUCCESS, payload: data });
      yield put({ type: Types.RESET_LIST_TRIAL_ACTION});
    } catch (error) {
      yield put({ type: Types.DELETE_LIST_TRIAL_FAILED, payload: error });
    }
  }
export default function* ListTrials() {
    yield takeLatest(Types.GET_LIST_TRIALS_REQUEST, getListTrials);
    yield takeLatest(Types.GET_LIST_TRIAL_REQUEST, getListTrial);
    yield takeLatest(Types.UPDATE_LIST_TRIAL_REQUEST, updateListTrial);
    yield takeLatest(Types.CREATE_LIST_TRIAL_REQUEST, createListTrial);
    yield takeLatest(Types.DELETE_LIST_TRIAL_REQUEST, deleteListTrial);
  }
