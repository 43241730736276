import {
  CheckOutlined,
  ClearOutlined,
  DeleteTwoTone,
  EditTwoTone
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Table
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { compact, get } from 'lodash';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  COMPONENT_MODES,
  MAX_PAGINATION_LIMIT
} from '~/constants/defaultValue';
import { useMedicines } from '~/hooks';
import { formatNumberThreeComma, Permissions } from '~/hooks/utils';
import {
  useCreateWhReportMedicine,
  useDeleteWhReportMedicine,
  useResetWhReportMedicine,
  useUpdateWhReportMedicine,
  useWhReportMedicineQueryParams,
  useWhReportMedicines
} from '~/hooks/whReportMedicines';
import ModalConfirm from '../Common/ModalConfirm';
import './index.scss';
import TableDetail from './TableDetail';

const EditableContext = createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  indexAction,
  rowIndex,
  content,
  setContent,
  editSelect,
  times,
  setTimes,
  mode,
  selectMedicine,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    if (rowIndex === 0) {
      switch (dataIndex) {
        case 'price':
          if (mode === COMPONENT_MODES.VIEW) {
            childNode = null;
          } else {
            childNode = (
              <InputNumber
                max={1000000000}
                disabled
                style={{ width: '100%' }}
                formatter={(value) => formatNumberThreeComma(value)}
                value={get(selectMedicine, 'price', 0)}
              />
            );
          }
          break;
        case 'discountPercentage':
          if (mode === COMPONENT_MODES.VIEW) {
            childNode = null;
          } else {
            childNode = (
              <InputNumber
                max={100}
                disabled
                style={{ width: '100%' }}
                formatter={(value) => formatNumberThreeComma(value)}
                value={get(selectMedicine, 'discountPercentage', 0)}
              />
            );
          }
          break;
        case 'totalPrice':
          if (mode === COMPONENT_MODES.VIEW) {
            childNode = null;
          } else {
            childNode = (
              <InputNumber
                disabled
                style={{ width: '100%' }}
                formatter={(value) => formatNumberThreeComma(value)}
                value={formatNumberThreeComma(
                  (
                    (get(selectMedicine, 'discountPercentage', 0) / 100) *
                    get(selectMedicine, 'price', 0) *
                    times
                  ).toFixed(0)
                )}
              />
            );
          }
          break;
        case 'times':
          if (mode === COMPONENT_MODES.VIEW) {
            childNode = null;
          } else {
            childNode = (
              <InputNumber
                max={100000000}
                style={{ width: '100%' }}
                formatter={(value) => formatNumberThreeComma(value)}
                value={times}
                onChange={(value) => setTimes(value)}
              />
            );
          }
          break;
        default:
          break;
      }
    } else {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
        >
          <InputNumber
            style={{ width: '100%' }}
            disabled={dataIndex !== 'times'}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            formatter={(value) => formatNumberThreeComma(value)}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
          onClick={() => {
            if (indexAction === record.key && dataIndex === 'times')
              toggleEdit();
          }}
        >
          <Text>{formatNumberThreeComma(children[1])}</Text>
        </div>
      );
    }
  }
  return <td {...restProps}>{childNode}</td>;
};
const TableMedicines = ({ id, date, mode, setTotalDetailAll, partner }) => {
  const {canCreate,canDelete,canUpdate} = Permissions("WHMEDICALEQUIPMENT")
  const [dataSource, setDataSource] = useState([]);
  const [indexAction, setIndexAction] = useState(null);
  const [action, setAction] = useState(null);
  const [initValue, setInitValue] = useState(null);
  const [query] = useWhReportMedicineQueryParams(id, date);
  const [whReportMedicines, isLoading] = useWhReportMedicines(query);
  const [listExist, setListExist] = useState([]);
  const [editSelect, setEditSelect] = useState(null);

  const [deleteSelect, setDeleteSelect] = useState(null);
  const [isOpenDelete, setisOpenDelete] = useState(false);


  const queryGetALlMedicines = useMemo(
    () => ({ page: 1, limit: MAX_PAGINATION_LIMIT }),
    []
  );
  const [medicines, isLoadingMedicines] = useMedicines(queryGetALlMedicines);
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [times, setTimes] = useState(1);
  const [selectMedicine, setSelectMedicine] = useState(null);
  // const checkFirstIndexAndModeEdit = (index) =>
  //   index === 0 && mode === COMPONENT_MODES.EDIT;
  // const checkFirstIndexAndModeView = (index) =>
  //   index === 0 && mode === COMPONENT_MODES.VIEW;
  const handleChange = (value) => {
    setName(value);
    setSelectMedicine(medicines.find((e) => e._id === value));
  };
  const handleOpenDelete = (record) => {
    setDeleteSelect(record)
    setisOpenDelete(true)
  }
  const handleCloseDelete = () => {
    setDeleteSelect(null)
    setisOpenDelete(false)
  }
  useResetWhReportMedicine();
  useEffect(() => {
    if (id && whReportMedicines) {
      let count = 1;
      let totalPrice = 0;
      let newWhReportMedicines = whReportMedicines?.map((e) => {
        totalPrice += e.totalPrice;
        return {
          ...e,
          key: count++
        };
      });

      setDataSource(newWhReportMedicines);
      setListExist(
        compact(whReportMedicines?.map((e) => get(e, 'snapShotMedical._id')))
      );
      setTotalDetailAll('medicines', totalPrice);
    }
  }, [id, whReportMedicines]);
  const handleChangeAction = (action, key, record) => {
    setAction(action);
    setIndexAction(key);
    if (action === 'update') {
      setInitValue(record);
    }
  };
  const handleResetAction = () => {
    setAction(null);
    setIndexAction(null);
    setInitValue(null);
  };

  const handleResetValue = () => {
    setTimes(1);
    setName('')
    setSelectMedicine(null)
    handleResetAction();
  };
  const [isSubmitLoading, handleCreate] =
    useCreateWhReportMedicine(handleResetValue);

  const [, handleEdit] = useUpdateWhReportMedicine(handleResetAction);
  const [, handleDelete] = useDeleteWhReportMedicine(() => {
    handleCloseDelete()
    handleResetAction()
  });
  const handleCancel = () => {
    handleSave(initValue);
    handleResetAction();
  };
  const handleSubmitEdit = (record) => {
    const submitData = { ...record, id: record._id };
    handleEdit(submitData);
  };
  const handleSubmitCreate = () => {
    const submitData = {
      times,
      snapShotMedical: selectMedicine,
      snapShortPartner: partner,
      partnerId: id
    };
    handleCreate(submitData);
  };
  const defaultColumns = [
    {
      title: 'Thiết bị y tế',
      key: 'snapShotMedical',
      width: '50%',
      dataIndex: 'snapShotMedical',
      align: 'center',
      render: (text, record, index) =>
        index !== 0 ? (
          <Text strong>{get(text, 'name', '')}</Text>
        ) : mode === COMPONENT_MODES.EDIT ? (
          <Select
            showSearch
            loading={isLoadingMedicines}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '90%' }}
            onChange={handleChange}
            value={name}
            options={compact(
              medicines?.map((value, index) => {
                const findOne = listExist.some((e) => e === value._id);
                if (!findOne)
                  return {
                    label: get(value, 'name'),
                    value: get(value, '_id')
                  };
              })
            )}
          />
        ) : null
    },
    {
      title: 'Số lượng',
      dataIndex: 'times',
      width: '14%',
      key: 'times',
      align: 'center',
      editable: mode === COMPONENT_MODES.EDIT ? true : false
    },
    {
      title: 'Giá bán',
      dataIndex: 'price',
      width: '20%',
      align: 'center',
      editable: true
      // render: (item, record, index) => <div>Thành tiền</div>
    },
    {
      title: 'Chiết khấu(%)',
      dataIndex: 'discountPercentage',
      width: '15%',
      align: 'center',
      editable: true
      // render: (item, record, index) => <div>Thành tiền</div>
    },
    {
      title: 'Thành tiền',
      dataIndex: 'totalPrice',
      width: '20%',
      align: 'center',
      editable: true
      // render: (item, record, index) => <div>Thành tiền</div>
    },
    {
      title: 'Thao tác',
      key: 'action',
      align: 'center',
      dataIndex: 'action',
      width: '15%',
      render: (item, record, index) => {
        if (mode === COMPONENT_MODES.VIEW) return null;
        if (index === 0) {
          return (
            <Row>
              <Col span={12}>
                <Button
                  disabled={!canCreate ||!selectMedicine}
                  loading={isSubmitLoading}
                  onClick={handleSubmitCreate}
                  style={{ borderColor: '#52c41a' }}
                >
                  <CheckOutlined style={{ color: '#52c41a' }} />
                </Button>
              </Col>
              <Col span={12}>
                <Button onClick={handleResetValue}>
                  <ClearOutlined />
                </Button>
              </Col>
            </Row>
          );
        }
        if (action === 'update') {
          if (record.key === indexAction) {
            return (
              <Row>
                <Col span={12}>
                  <Button
                    disabled={!canUpdate || !record.times}
                    loading={isSubmitLoading}
                    type="primary"
                    onClick={() => handleSubmitEdit(record)}
                  >
                    Lưu
                  </Button>
                </Col>
                <Col span={12}>
                  <Button onClick={handleCancel}>Huỷ</Button>
                </Col>
              </Row>
            );
          }
        }
        if (!!!action && !!!indexAction)
          return (
            <Row>
              <Col span={12}>
                <Button
                disabled={!canUpdate}
                loading={isSubmitLoading}
                  onClick={() => {
                    handleChangeAction('update', record.key, record);
                    setEditSelect(record);
                  }}
                >
                  <EditTwoTone />
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  disabled={!canDelete}
                  loading={isSubmitLoading}
                  onClick={() => handleOpenDelete(record)}
                >
                  <DeleteTwoTone />
                </Button>
              </Col>
            </Row>
          );
      }
    }
  ];
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record) => ({
          record,
          indexAction
        })
      };
    }
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        rowIndex,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        indexAction,
        handleSave,
        content,
        setContent,
        // price,
        editSelect,
        times,
        setTimes,
        mode,
        selectMedicine
      })
    };
  });
  const summaryFooter = (current) => {
    let total = current?.reduce(
      (sum, current) => ({
        price: get(sum, 'price', 0 )+ get(current, 'price', 0),
        totalPrice: get(sum, 'totalPrice', 0) + get(current, 'totalPrice', 0),
      }),
      { price:0, totalPrice :0}
    );
    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell align="center">TC</Table.Summary.Cell>
          <Table.Summary.Cell align="center">
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center">
            <Text strong>
              {formatNumberThreeComma(get(total, 'price', 0))}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center"></Table.Summary.Cell>
          <Table.Summary.Cell align="center">
            <Text strong>
              {formatNumberThreeComma(get(total, 'totalPrice'))}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center"></Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };
  return (
    <div>
      <TableDetail
        components={components}
        scrollWidth={1000}
        rowClassName={'editable-row'}
        loading={isLoading}
        pagination={false}
        dataSource={[1, ...dataSource]}
        columns={columns}
        title={'Thu nhập bán thiết bị y tế (H)'}
        footer={summaryFooter}
      />
            <ModalConfirm
        text={`Xác nhận xoá thiết bị`}
        subText={get(deleteSelect,'name','')}
        textOk="Xác nhận"
        type="delete"
        loading={isSubmitLoading}
        visible={isOpenDelete}
        onCancel={handleCloseDelete}
        handleOk={() =>  handleDelete(get(deleteSelect,'_id'))}
      />
    </div>
  );
};
export default TableMedicines;
