import { Button, Col, Modal, Row, Table } from 'antd'
import { get, head } from 'lodash';
import moment from 'moment'
import React, { useState } from 'react'
import { formatNumberThreeComma, formatNumberThreeCommaToFixed } from '~/hooks/utils';
import toastr from 'toastr';
import { removeBatchEmpty } from '~/hooks';
export default function SelectBatch({ listBatchs, handleSave, onCancel,quantityInput ,exchangeValue}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = [
    {
      title: "Tên lô",
      dataIndex: 'lotNumber',
      key: 'lotNumber',
    },
    {
      title: "Số lượng khả dụng",
      dataIndex: 'availableTotal',
      key: 'availableTotal',
      render : (availableTotal) => formatNumberThreeCommaToFixed(availableTotal / exchangeValue)
    },
    {
      title: "Ngày hết hạn",
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      render: (item) => moment(item).format("DD-MM-YYYY")
    },
  ]
  const handleSelectBatch = () => {
    const selected = head(selectedRows)
    const quantitySelect= get(selected, 'availableTotal', 0)
    if(quantitySelect < quantityInput){
      return toastr.error("Lô này không đủ số lượng")
    }
    const dataSaved =   { lotNumber: get(selected, 'lotNumber', ''),batchId: get(selected, '_id', ''),quantityOfBatchSelect :  quantitySelect };
    handleSave(dataSaved,'batch')
    onCancel()
  }
  return (
    <Row>
      <Col span={24}>
        <Table
          title={() => `Danh sách lô`}
          columns={columns} dataSource={removeBatchEmpty(listBatchs,quantityInput)}
          pagination={false}
          rowKey={(rc) => rc._id}
          size="middle"
          rowSelection={{
            type: 'radio',
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowKeys(selectedRowKeys);
              setSelectedRows(selectedRows);
            },
          }}
        />
      </Col>
      <Col><Button onClick={handleSelectBatch} type='primary'>Chọn lô</Button></Col>
    </Row>
  )
}
