import requester from './requester';

const TimeSheet = {
  create: (course) => requester.post('/wh-time-sheet', course),
  update: (query) => requester.post(`/wh-time-sheet/${query.id}`,query),
  updateCourse: (course) => requester.put(`/course-training-partner/${course._id}`, course),
  delete: (id) => requester.delete(`/wh-time-sheet/${id}`),
  getAll: (query) => requester.get('/wh-time-sheet', query),
  getOne: (id) => requester.get(`/wh-time-sheet/${id}`),
};
export default TimeSheet;

