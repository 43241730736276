import requester from './requester';

const theme = {
  login: (user) => requester.post('staff-login', user),
  loginWorkspace: (user) => requester.post('wh-partner/login', user),
  getPolicy: () => requester.get('user-policy'),
  getProfile: (token) => requester.get('staff-profile', token)
};

export default theme;
