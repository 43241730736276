import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getTipsPartners({ payload: query }) {
    try {
      const response = yield call(Api.TipsPartner.getAll, query);
      yield put({ type: Types.GET_TIPS_PARTNERS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_TIPS_PARTNERS_FAILED, payload: error });
    }
  }
function* getLogsTipsPartners({ payload: id }) {
    try {
      const response = yield call(Api.TipsPartner.getAllLogsTips, id);
      yield put({ type: Types.GET_LOGS_TIPS_PARTNER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_LOGS_TIPS_PARTNER_FAILED, payload: error });
    }
  }
function* getTipsPartner({ payload: id }) {
    try {
      const response = yield call(Api.TipsPartner.getOne, id);
      yield put({ type: Types.GET_TIPS_PARTNER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_TIPS_PARTNER_FAILED, payload: error });
    }
  }
  function* createTipsPartner(action) {
    try {
      const data = yield call(Api.TipsPartner.create, action.payload);
      yield put({ type: Types.CREATE_TIPS_PARTNER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_TIPS_PARTNER_FAILED, payload: error });
    }
  }
  function* updateTipsPartner(action) {
    try {
      const data = yield call(Api.TipsPartner.update, action.payload);
      yield put({ type: Types.UPDATE_TIPS_PARTNER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_TIPS_PARTNER_FAILED, payload: error });
    }
  }
  function* deleteTipsPartner(action) {
    try {
      const data = yield call(Api.TipsPartner.delete, action.payload);
      yield put({ type: Types.DELETE_TIPS_PARTNER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_TIPS_PARTNER_FAILED, payload: error });
    }
  }
export default function* TipsPartners() {
    yield takeLatest(Types.GET_TIPS_PARTNERS_REQUEST, getTipsPartners);
    yield takeLatest(Types.GET_LOGS_TIPS_PARTNER_REQUEST, getLogsTipsPartners);
    yield takeLatest(Types.GET_TIPS_PARTNER_REQUEST, getTipsPartner);
    yield takeLatest(Types.CREATE_TIPS_PARTNER_REQUEST, createTipsPartner);
    yield takeLatest(Types.UPDATE_TIPS_PARTNER_REQUEST, updateTipsPartner);
    yield takeLatest(Types.DELETE_TIPS_PARTNER_REQUEST, deleteTipsPartner);
  }