import { forIn, get, groupBy, keys } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import { createIntroUsingRefCode, deleteIntroUsingRefCode, getIntroUsingRefCode, getIntroUsingRefCodes, resetIntroUsingRefCodeState, resetIntroUsingRefCodeStateACtion, updateIntroUsingRefCode } from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.introUsingRefCode[key];
const pagingSelector = getSelector('paging');
export const useIntroUsingRefCodePaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadingGetIntroUsingRefCodeSelector = getSelector('isGetIntroUsingRefCodeLoading');
const getIntroUsingRefCodesFailedSelector = getSelector('getIntroUsingRefCodesFailed');
const getIntroUsingRefCodeFailedSelector = getSelector('getIntroUsingRefCodeFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const introUsingRefCodesSelector = getSelector('introUsingRefCodes');
const introUsingRefCodeSelector = getSelector('introUsingRefCode');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useIntroUsingRefCodeQueryParams = () => {
    const query = useQueryParams();
    const limit = query.get('limit') || 10;
    const page = query.get('page') || 1;
    const keyword = query.get('keyword');
    const createIntroUsingRefCodeSuccess = useSelector(createSuccessSelector);
    const deleteIntroUsingRefCodeSuccess = useSelector(deleteSuccessSelector);
  
    return useMemo(() => {
      const queryParams = {
        page,
        limit,
        keyword,
      };
      return [queryParams];
      //eslint-disable-next-line
    }, [
      page,
      limit,
      createIntroUsingRefCodeSuccess,
      deleteIntroUsingRefCodeSuccess,
      keyword,
    ]);
  };

  export const useUpdateIntroUsingRefCodeParams = (query,listOptionSearch) => {
    const history = useHistory();
    const onParamChange = (param) => {
      const groupByKey = groupBy(listOptionSearch , (e) => get(e,'value'));
      forIn(query,(values,key,obj) =>{
        if(groupByKey[key] && (keys(param)?.some(e => groupByKey[e]))){
          obj[key] = null
        }
      })
      if (!param.page) {
        query.page = 1;
      }
      history.push({
        pathname: get(param, 'customPathName') || PATH_APP.referral.IntroUsingRefCode,
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [ onParamChange ];
  };
  export const useIntroUsingRefCodes = (query) => {
    return useFetchByParam({
      action: getIntroUsingRefCodes,
      loadingSelector,
      dataSelector: introUsingRefCodesSelector,
      failedSelector: getIntroUsingRefCodesFailedSelector,
      param: query
    });
  };
  export const useIntroUsingRefCode = (id) => {
    return useFetchByParam({
      action: getIntroUsingRefCode,
      loadingSelector:loadingGetIntroUsingRefCodeSelector,
      dataSelector: introUsingRefCodeSelector,
      failedSelector: getIntroUsingRefCodeFailedSelector,
      param: id
    });
  };
export const useCreateIntroUsingRefCode = (callback) => {
    useSuccess(createSuccessSelector, 'Tạo hướng dẫn sử dụng mã giới thiệu thành công',callback);
    useFailed(createFailedSelector);
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: createIntroUsingRefCode
    });
  };
export const useUpdateIntroUsingRefCode = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhật hướng dẫn sử dụng mã giới thiệu thành công',callback);
    useFailed(updateFailedSelector, 'Cập nhật hướng dẫn sử dụng mã giới thiệu thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateIntroUsingRefCode
    });
  };
export const useDeleteIntroUsingRefCode = (callback) => {
    useSuccess(deleteSuccessSelector, 'Xoá hướng dẫn sử dụng mã giới thiệu thành công',callback);
    useFailed(deleteFailedSelector, 'Xoá hướng dẫn sử dụng mã giới thiệu thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: deleteIntroUsingRefCode
    });
  };

  export const useResetIntroUsingRefCode = () => {
    useResetState(resetIntroUsingRefCodeState);
  };


export const useResetIntroUsingRefCodeAction = () => {
  useResetState(resetIntroUsingRefCodeStateACtion);
};
