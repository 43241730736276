import { Button, Form, Input } from 'antd'
import React from 'react'

export default function FormResetPassword({onOk}) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        onOk(values)
    }
  return (
    <div>
        <h6 style={{textAlign : 'center'}}>Thay đổi mật khẩu</h6>
        <Form form={form} onFinish={onFinish}>
            <Form.Item name="password" rules={[{ required: true, message: 'Vui lòng nhập!' }]}>
                <Input placeholder='Nhập mật khẩu mới' />
            </Form.Item>
            <Button block size='small' htmlType='submit' type='primary'>
                Xác nhận
             </Button>
        </Form>
    </div>
  )
}
