export const GET_HOSPITALS_LIST_REQUEST = 'GET_HOSPITALS_LIST_REQUEST';
export const GET_HOSPITALS_LIST_SUCCESS = 'GET_HOSPITALS_LIST_SUCCESS';
export const GET_HOSPITALS_LIST_FAILED = 'GET_HOSPITALS_LIST_FAILED';

export const GET_HOSPITAL_LIST_REQUEST = 'GET_HOSPITAL_LIST_REQUEST';
export const GET_HOSPITAL_LIST_SUCCESS = 'GET_HOSPITAL_LIST_SUCCESS';
export const GET_HOSPITAL_LIST_FAILED = 'GET_HOSPITAL_LIST_FAILED';

export const CREATE_HOSPITAL_LIST_REQUEST = 'CREATE_HOSPITAL_LIST_REQUEST';
export const CREATE_HOSPITAL_LIST_SUCCESS = 'CREATE_HOSPITAL_LIST_SUCCESS';
export const CREATE_HOSPITAL_LIST_FAILED = 'CREATE_HOSPITAL_LIST_FAILED';

export const UPDATE_HOSPITAL_LIST_REQUEST = 'UPDATE_HOSPITAL_LIST_REQUEST';
export const UPDATE_HOSPITAL_LIST_SUCCESS = 'UPDATE_HOSPITAL_LIST_SUCCESS';
export const UPDATE_HOSPITAL_LIST_FAILED = 'UPDATE_HOSPITAL_LIST_FAILED';

export const DELETE_HOSPITAL_LIST_REQUEST = 'DELETE_HOSPITAL_LIST_REQUEST';
export const DELETE_HOSPITAL_LIST_SUCCESS = 'DELETE_HOSPITAL_LIST_SUCCESS';
export const DELETE_HOSPITAL_LIST_FAILED = 'DELETE_HOSPITAL_LIST_FAILED';

export const RESET_HOSPITAL_LIST_STATE = 'RESET_HOSPITAL_LIST_STATE';
