import * as Types from '../../constants/actionTypes';

export const getWhReportMedicines = query => ({
  type: Types.GET_WH_REPORT_MEDICINES_REQUEST,
  payload: query
});
export const getWhReportMedicine = query => ({
  type: Types.GET_WH_REPORT_MEDICINE_REQUEST,
  payload: query
});
export const createWhReportMedicine = query => ({
  type: Types.CREATE_WH_REPORT_MEDICINE_REQUEST,
  payload: query
});
export const updateWhReportMedicine = query => ({
  type: Types.UPDATE_WH_REPORT_MEDICINE_REQUEST,
  payload: query
});
export const deleteWhReportMedicine = query => ({
  type: Types.DELETE_WH_REPORT_MEDICINE_REQUEST,
  payload: query
});


export const resetWhReportMedicineState = () => ({
  type: Types.RESET_WH_REPORT_MEDICINE_STATE
});

// export const getWhReceiptVoucherGroups = () => ({
//   type: GET_WH_RECEIPT_VOUCHER_GROUPS_REQUEST
// });
