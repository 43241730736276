export const GET_SERVICE_PLACES_REQUEST = 'GET_SERVICE_PLACES_REQUEST';
export const GET_SERVICE_PLACES_SUCCESS = 'GET_SERVICE_PLACES_SUCCESS';
export const GET_SERVICE_PLACES_FAILED = 'GET_SERVICE_PLACES_FAILED';

export const GET_SERVICE_PLACE_REQUEST = 'GET_SERVICE_PLACE_REQUEST';
export const GET_SERVICE_PLACE_SUCCESS = 'GET_SERVICE_PLACE_SUCCESS';
export const GET_SERVICE_PLACE_FAILED = 'GET_SERVICE_PLACE_FAILED';

export const CREATE_SERVICE_PLACE_REQUEST = 'CREATE_SERVICE_PLACE_REQUEST';
export const CREATE_SERVICE_PLACE_SUCCESS = 'CREATE_SERVICE_PLACE_SUCCESS';
export const CREATE_SERVICE_PLACE_FAILED = 'CREATE_SERVICE_PLACE_FAILED';

export const UPDATE_SERVICE_PLACE_REQUEST = 'UPDATE_SERVICE_PLACE_REQUEST';
export const UPDATE_SERVICE_PLACE_SUCCESS = 'UPDATE_SERVICE_PLACE_SUCCESS';
export const UPDATE_SERVICE_PLACE_FAILED = 'UPDATE_SERVICE_PLACE_FAILED';

export const DELETE_SERVICE_PLACE_REQUEST = 'DELETE_SERVICE_PLACE_REQUEST';
export const DELETE_SERVICE_PLACE_SUCCESS = 'DELETE_SERVICE_PLACE_SUCCESS';
export const DELETE_SERVICE_PLACE_FAILED = 'DELETE_SERVICE_PLACE_FAILED';

export const RESET_SERVICE_PLACE_STATE = 'RESET_SERVICE_PLACE_STATE';

