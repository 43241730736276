import {
  CheckOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Carousel,
  Col,
  Form,
  InputNumber,
  Progress,
  Result,
  Row,
  Statistic,
  Steps
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import toastr from 'toastr';
import api from '~/api';
import {
  REQUEST_VOUCHER_REFCOLLECTION,
  REQUEST_VOUCHER_STATUS,
  REQUEST_VOUCHER_TYPE,
  WH_PAYMENT_VOUCHER_ACTION
} from '~/constants/defaultValue';
import { formatNumberThreeComma } from '~/hooks/utils';
import { floorFormatter } from '~/utils/helper';
import SuggestMoney from './SuggestMoney';
const minWithDraw = 500000
const stepMoney = 500000
export default function WithDrawForm({ dataWallet, onClose, profile, mutate }) {
  const [form] = Form.useForm();
  const [isChangeSuccess, setIschangeSuccess] = useState(false);
  const [steps, setSteps] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPercent, setCurrentPercent] = useState(0);
  const [requestNumber, setRequestNumber] = useState(null)
  const [indexActive, setIndexActive] = useState(null)
  const carouselRef = useRef(null); // Create a ref for the Carousel component

  const handleNext = () => {
    carouselRef.current.next(); // Call next() on the Carousel ref
  };
  const onIncrease = () => {
    const amount = form.getFieldValue(['amount'])
    form.setFieldsValue({amount :amount + stepMoney})
    setIndexActive(null)
  }
  const onDecrease = () => {
    const amount = form.getFieldValue(['amount'])
    form.setFieldsValue({amount :amount - stepMoney})
    setIndexActive(null)
  }
  useEffect(() => {
    setTimeout(() => {
      if (currentPercent <= 100 && !isLoading && isChangeSuccess) {
        setCurrentPercent(currentPercent + 1);
      }
      if (currentPercent === 100) {
        setTimeout(() => {
          setSteps(1);
          handleNext()
          mutate()
        }, 1000);
      }
    }, 2);
  }, [isChangeSuccess, currentPercent, isLoading]);
  const onFinish = async (values) => {
    try {
      if (
        get(values, 'amount', 0) > dataWallet
      ) {
        return toastr.error('Số tiền vượt quá ví của bạn');
      }
      setIsLoading(true);
      const submitData = {
        action: WH_PAYMENT_VOUCHER_ACTION.PAYMENT_WALLET,
        userId: get(profile, '_id'),
        refCollection: REQUEST_VOUCHER_REFCOLLECTION.wh_partner,
        totalAmount: get(values, 'amount', 0),
        typeVoucher: REQUEST_VOUCHER_TYPE.PC,
        status: REQUEST_VOUCHER_STATUS.NEW,
        creditAccount: 1111,
        debitAccount: 331,
        reason: 'Khách hàng rút tiền từ ví'
      };
      const res = await api.requestVoucher.create(submitData);
      if (get(res, 'status')) {
        setIschangeSuccess(true);
        setRequestNumber(get(res, 'data.requestNumber', ''))
        // onClose()
      } else {
        toastr.error(get(res, 'message', 'Some error occured'));
      }
      setIsLoading(false)
    } catch (error) {
      toastr.error("Some error occured")
      console.log(error);
      setIsLoading(false)
    }
  };
  const onValuesChange = (valueChange) => {
    const {amount} = valueChange
    if(amount  >= dataWallet){
      form.setFieldsValue({amount: dataWallet})
    }
    if(amount <= 0){
      form.setFieldsValue({amount: 0})
    }
    setIndexActive(null)
  }
  return (
    <div className="WithDrawForm">
      <Steps current={steps}>
        <Steps.Step title="Tạo yêu cầu" />
        <Steps.Step title="Đợi admin duyệt" />
      </Steps>
      <>
        <Carousel dots={false} ref={carouselRef}>
        <div>
            <Card
              bodyStyle={{ padding: 10 }}
              style={{
                background: '#F0F2F5',
                borderRadius: 20,
                margin: '10px',
              }}
              bordered={false}
            >
              <Statistic
                style={{ textAlign: 'center' }}
                title="Số dư ví"
                value={dataWallet}
                precision={0}
                // prefix={<WalletTwoTone />}
                suffix="VND"
              />
            </Card>
            <SuggestMoney indexActive={indexActive} setIndexActive={setIndexActive} form={form} total={dataWallet} />
            <div className="WithDrawForm--amount"></div>
            <Form onValuesChange={onValuesChange} onFinish={onFinish} initialValues={{amount : 0}} form={form}>
          <Row justify='space-between' align='middle'>
            <Col span={20}>
            <Form.Item name="amount" label="Số tiền muốn rút"
                rules={[
                  ({ }) => ({
                    validator(_, value) {
                      if (
                        value &&
                        value >= minWithDraw
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(`Vui lòng nhập số tiền tối thiểu là ${floorFormatter(minWithDraw)}`));
                    }
                  })
                ]}
              >
                <InputNumber
                  // className='input-amount'
                  formatter={value => formatNumberThreeComma(value)}
                  min={0}
                  // inputmode="numeric"
                  readOnly
                  bordered={false}
                  style={{
                    width: '100%',
                    borderBottom: '1px solid #999',
                    fontSize: '26px',
                    lineHeight : 1,
                    
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
                <Form.Item shouldUpdate noStyle>
                  {() =>   <Row>
                    <Col flex={1}><Button disabled={form.getFieldValue(['amount']) + stepMoney >= dataWallet} onClick={onIncrease}  type='primary' block>+</Button></Col>
                    <Col flex={1}><Button disabled={form.getFieldValue(['amount']) <= 0} onClick={onDecrease} block>-</Button></Col>
                  </Row>}
                </Form.Item>
            </Col>
          </Row>
              <Text type='secondary'>Số tiền tối thiểu : {floorFormatter(minWithDraw)}</Text>
              <Row justify='center'>
                <Col span={18}>
                  <Button
                    style={{ height: 'auto', borderRadius: '20px',pointerEvents : steps === 1 ? 'none' : 'auto'}}
                    size="large"
                    block
                    htmlType={isLoading ? 'button' : 'submit'}
                    
                  >
                    {isLoading && !isChangeSuccess ? (
                      <Loading3QuartersOutlined spin style={{ fontSize: 30 }} />
                    ) : !isLoading && isChangeSuccess ? (
                      <Progress
                        strokeColor={{
                          '0%': '#108ee9',
                          '100%': '#87d068'
                        }}
                        format={(percent) => percent === 100 && <CheckOutlined className='animationCheck' />}
                        width={40}
                        type="circle"
                        percent={currentPercent}
                      />
                    ) : (
                      'Rút tiền'
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          {steps === 1 && <Result
            className='WithDrawForm--result'
            status="success"
            title={`Yeah , bạn vừa gửi yêu cầu rút ${floorFormatter(form.getFieldValue(['amount']) || 0)}đ thành công`}
            subTitle={`Mã số yêu cầu của bạn là ${requestNumber} , vui lòng đợi hệ thống duyệt sau đó chúng tôi sẽ liên hệ lại bạn`}
          // extra={[<Button key="show">Xem danh sách yêu cầu (Not yet implemented)</Button>]}
          />}
        </Carousel>
      </>
    </div>
  );
}
