import { Button, Col, Modal, notification, Row, Typography } from 'antd';
import { get, head } from 'lodash';
import React, { useMemo, useState } from 'react';
import api from '~/api';
import { DEPOSIT_THRESHOLDS, NUMBER_OF_DIGITS_TO_ROUND, WALLET_ACTION, WALLET_REFCOLLECTION, WALLET_STATUS, WALLET_TYPE } from '~/constants/defaultValue';
// import { floorFormatter } from '~/utils/helper';
import ExchangePointRefundItem from './ExchangePointRefundItem';
import ExchangePointRefundItemInput from './ExchangePointRefundItemInput';
import toastr from 'toastr'
import './index.scss'
import { formatNumberThreeCommaToFixed } from '~/hooks/utils';
export default function ExchangePointRefund({dataExchange,point,mutate,type,userId}) {
  // let arrPoint = [10*point/100, 20*point/100, 30*point/100,point];
  const [selectIndex, setSelectIndex] = useState(null);
  const [visibleModal,setVisibleModal] = useState(false);
  const [othersPoint,setOtherPoint] = useState(0)
  const [loadingSubmit,setLoadingSubmit] = useState(false)
  const onHandleClick = idex => {
    setSelectIndex(idex);
    setOtherPoint(0)
  };
  const handleOpenModal = () => {
    if(pointExchange <=0 ) return
    setVisibleModal(true)
  }
  const handleCloseModal = () => {
    setVisibleModal(false)
    setOtherPoint(0)
    setSelectIndex(null)
  }
  const arrPoint = useMemo(() => {
    if(point <= 0) return [0,0,0,0]
    return DEPOSIT_THRESHOLDS.map(
        threshold =>
            Math.round(
                (point * threshold) /
                Math.pow(10, NUMBER_OF_DIGITS_TO_ROUND),
                NUMBER_OF_DIGITS_TO_ROUND
            ) * Math.pow(10, NUMBER_OF_DIGITS_TO_ROUND)
    );
}, [point]);
  const onOk = async() => {
  try {
    setLoadingSubmit(true)
    let submitData
    switch (type) {
      case WALLET_TYPE.CASH:
        submitData = {
          desc : "Đối tác Đổi điểm sang tiền",
          money : (pointExchange * coefficient).toFixed(2),
          type:WALLET_TYPE.CASH,
          userId,
          refCollection:WALLET_REFCOLLECTION.wh_partner,
          action:WALLET_ACTION.PAY_IN,
          status:WALLET_STATUS.COMPLETED,
          point:pointExchange
    }
        break;
      case WALLET_TYPE.REFUND:
        submitData = {
          desc : "Khách hàng Đổi tiền thưởng",
          money : (pointExchange * coefficient).toFixed(2),
          type:WALLET_TYPE.REFUND,
          userId,
          refCollection:WALLET_REFCOLLECTION.customer_account,
          action:WALLET_ACTION.PAY_IN,
          status:WALLET_STATUS.COMPLETED,
          point:pointExchange
    }
        break;
    
      default:
        break;
    }
     
    const res = await api.wallet.create(submitData)
    if(get(res,'status')){
      if(mutate && typeof mutate === 'function'){
        mutate()
        toastr.success("Đổi điểm thành công")
      }
      handleCloseModal()
    }
    setLoadingSubmit(false)
  } catch (error) {
    toastr.error(get(error,'message','Something went wrong'))
    setLoadingSubmit(false)
  }
    
  }
  const onchangeOtherPoint = (value) => {
    if(value){
        setOtherPoint(value)
      setSelectIndex(null)
    }
   
  }
  const modalFooter = [
    <Button key="cancel" className='btn btn--tag' onClick={handleCloseModal}>
      Cancel
    </Button>,
    <Button key="ok" loading={loadingSubmit} className='btn submit-btn' type="primary" onClick={onOk}>
      OK
    </Button>
  ];
  const pointExchange = useMemo(() => othersPoint !== 0 ? othersPoint : arrPoint[selectIndex],[othersPoint,selectIndex])
  const coefficient = useMemo(() => dataExchange ? get(head(dataExchange),'coefficient',1) : 1,[dataExchange])
  return (
  <>
      <Row justify='space-between'  style={{maxWidth : '550px'}} wrap className='Group-content__exchange'>
      {/* <Refund /> */}
      <Col span={24} >
        <Typography.Title className='Group-content__exchange__title' level={3}>
          Đổi điểm thưởng sang ví 
        </Typography.Title>
      </Col>
      {arrPoint?.map((e, index) => (
        <ExchangePointRefundItem
          onClick={() => onHandleClick(index)}
          value={e}
          coefficient={coefficient}
          active={index === selectIndex}
        />
      ))}
      <ExchangePointRefundItemInput point={point} coefficient={coefficient} onchangeOtherPoint={onchangeOtherPoint} otherPoint={othersPoint}/>
      <Col  align="center" className='Group-content__exchange__action' span={24}>
        <Button disabled={point === 0 || (selectIndex === null && othersPoint === 0)} onClick={handleOpenModal} className=''>Xác nhận</Button>
      </Col>
    </Row>
    <Modal className='custom-modal' closable={false} footer={modalFooter} visible={visibleModal} onCancel={handleCloseModal} onOk={onOk}>
      <Typography.Title style={{textAlign : 'center'}}  level={5}>
        Xác nhận đổi : <Typography.Text  style={{fontSize : '20px'}}>{formatNumberThreeCommaToFixed(pointExchange,2)}</Typography.Text>  điểm <br/> thành : <Typography.Text  style={{fontSize : '20px'}}>{(formatNumberThreeCommaToFixed((pointExchange * coefficient),2))}</Typography.Text> tiền thưởng
      </Typography.Title>
    </Modal>
  </>
  );
}
