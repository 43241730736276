import { DoubleRightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react'
import InfomationProduct from './InfomationProduct'
import InfomationWhAppointment from './InfomationWhAppointment';

export default function ConfirmPreBooking({productId,whAppointmentId}) {
  return (
    <Row align='middle' justify='space-around'>
    <Col span={10}>
        <InfomationProduct id={productId}/>
    </Col>
    <Col span={2}>
    <DoubleRightOutlined style={{fontSize : 48}}/>
    </Col>
    <Col span={10}>
    <InfomationWhAppointment id={whAppointmentId}/>
    </Col>
    </Row>
  )
}
