import * as Types from '../../constants/actionTypes';
  
  export const getPercentChangeCustomerToPartners = query => ({
    type: Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_REQUEST,
    payload: query
  });
  export const getPercentChangeCustomerToPartner = id => ({
    type: Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST,
    payload: id
  });
  export const createPercentChangeCustomerToPartner = instance => ({
    type: Types.CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST,
    payload: instance
  });
  export const updatePercentChangeCustomerToPartner = instance => ({
    type: Types.UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST,
    payload: instance
  });
  export const resetPercentChangeCustomerToPartnerState = () => ({
    type: Types.RESET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_STATE
  });
