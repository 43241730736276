import { Button, Col, Form, Input, Modal, Row, Select, Skeleton } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import SkeletonInput from 'antd/lib/skeleton/Input';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { useMedicalEquipments } from '~/hooks/medicalEquipment';
import {
  useCreateProductCatalogue,
  useProductCatalogue,
  useResetProductCatalogueForm,
  useUpdateProductCatalogue
} from '~/hooks/productCatalogue';
import { filterAcrossAccents, formatNumberThreeComma } from '~/hooks/utils';
// import './index.scss';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    lg: { span: 10 },
    xl: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    lg: { span: 14 },
    xl: { span: 14 }
  }
};
const fullWidthStyle = {
  width: '100%'
};
export default function ProductCatalogueForm({
  id,
  setId,
  handleCloseForm,
  isOpen,
  products
  
}) {
  useResetProductCatalogueForm();
  const [form] = Form.useForm();
  const [loadingSubmit, createProductCatalogue] = useCreateProductCatalogue(() => {
    handleCloseForm(),
      form.resetFields()
  });
  const [isLoadingUpdate, updateProductCatalogue] = useUpdateProductCatalogue(() => {
    handleCloseForm(),
      form.resetFields()
  });
  const [product, loading] = useProductCatalogue(id);
  const [valueSelect, setValueSelect] = useState('')
  const queryMedical = useMemo(() => ({
    // page: 1,
    limit: MAX_PAGINATION_LIMIT
  }),[])
  
  const [equipments, isLoading] = useMedicalEquipments(queryMedical);
  const [medicalEquipment, setMedicalEquipment] = useState([])
  const [initValues, setInitValues] = useState({})

  //Set initValues for form
  // useEffect(() => {
  //   const e = equipments?.find((item) => {
  //     const res = item.type === 'WORLDHEALTH'
  //     return res
  //   })
  //   setMedicalEquipment([{ ...e }])
  
  // }, [equipments])

  useEffect(() => {
    form.resetFields()
    if (product) {
      const {deviceInventory,...rest} = product
      form.setFieldsValue({ ...rest });
      form.setFieldsValue({ 
        medicalEquipmentId: deviceInventory?.name?.vi
      });
    } else {
      form.setFieldsValue({
        medicalEquipmentId: (equipments?.find((item) => item.type === 'WORLDHEALTH'))?.name?.vi
      })
    }
  }, [form, product, isLoadingUpdate, products]);
  
  useEffect(() => {
    if (!id) {
      form.resetFields()
      form.setFieldsValue({ medicalEquipmentId: (equipments?.find((item) => item.type === 'WORLDHEALTH'))?.name?.vi })
    };
  }, [product, id, equipments, form]);

  const onFinish = (values) => {
    const data = {
      ...values,
      medicalEquipmentId: (equipments?.find((item) => item.type === 'WORLDHEALTH'))?._id,
    };
    if (id) {
      updateProductCatalogue({ ...data, id });
    } else {
      createProductCatalogue(data);
    };
  };
  
  return (
  
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError
        requiredMark={false}
        className="warehouse__form"
        initialValues={initValues}
      >

        <Row span={36}>
          <Col span={24}>
          <FormItem
            {...formItemLayout}
            label="Loại thiết bị y tế"
            name={'medicalEquipmentId'}
            >
            {loading ||isLoadingUpdate ? (
              <SkeletonInput />
            ) : (
          //     <Select
          //     onChange={((value) => setValueSelect(value))}
          //     value={valueSelect}
          //     filterOption={filterAcrossAccents}
          //     showSearch
          //     autoComplete="off"
          //     placeholder={'Vui lòng chọn loại danh mục thiết bị y tế'}
          //   >
          // {(medicalEquipment ?? [])?.map((op) =>(
          //   <Option key={op?._id} value={op?._id}>{op?.name?.vi}</Option>
          // ))}
                // </Select>
                <Input style={{ ...fullWidthStyle }} disabled/>
            )}
            </FormItem>
          </Col>
      </Row>
        <FormItem
          {...formItemLayout}
          label="Tên danh mục sản phẩm(vi)"
          name={['name', 'vi']}
          rules={[
            {
              required: true,
              message: 'Xin mời nhập tên danh mục sản phẩm (tiếng việt)'
            }
          ]}
        >
          {loading ||isLoadingUpdate ? (
            <SkeletonInput />
          ) : (
            <Input
              style={{ ...fullWidthStyle }}
              formatter={(value) => formatNumberThreeComma(value)}
            />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Tên danh mục sản phẩm(en)"
          name={['name', 'en']}
          rules={[
            { 
              required: true,
              message: 'Xin mời nhập tên danh mục sản phẩm (tiếng anh)',
            }
          ]}
        >
          {loading ||isLoadingUpdate ? (
            <SkeletonInput />
          ) : (
              <Input
              style={{ ...fullWidthStyle }}
            />
          )}
        </FormItem>
        <Row
          align='center'
        >
          <Col
          align='center'
          span={4}>
            <Button loading={loadingSubmit} htmlType="submit" type="primary" >
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Col>
          <Col
          align='center'
          span={4}>
            <Button onClick={handleCloseForm}>Huỷ</Button>
          </Col>
        </Row>
      </Form>
  );
}
