import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  checkInvalidDate,
  useBranchIdSessionStorage
} from '~/hooks/utils';
import { useSelectBranch, useUser } from '../user';
import {
    getWareHouseList,
    getWareHouseById,
    deleteWareHouse,
    createWareHouse,
    updateWareHouse,
    getProductListByWareHouseId
    
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { clearQuerySearch, getExistProp } from '~/utils/helper';
import { clone, find, get, isString, union, unionBy } from 'lodash';
import moment from 'moment';
import api from '~/api';
import { PATH_APP } from '~/routes/paths';
const WAREHOUSE_LIST = 'listWareHouse';
const {
    loadingSelector,
    listSelector,
    getListFailedSelector,
    deleteSuccessSelector,
    deleteFailedSelector,
    getByIdLoadingSelector,
    getByIdSelector,
    getByIdFailedSelector,
    createSuccessSelector,
    createFailedSelector,
    isSubmitLoadingSelector,
    updateSuccessSelector,
    updateFailedSelector,


  

} = getSelectors(WAREHOUSE_LIST);
const getSelector = (key) => (state) => state[WAREHOUSE_LIST][key];
const pagingSelector = getSelector('paging');
const ProductListByWarehouseSelector = getSelector('listProductByWareHouse');
export const useWareHouseListQueryParams = (module) => {
  const [branchId] = useUser();

  const branchIds = module?.branchId || undefined;
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const name = query.get('name');
  const code = query.get('code');
  const page = query.get('page') || 1;
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const warehouseId = query.get('warehouseId');
  const status = query.get("status");
  const startDate = query.get("startDate") || null
  const endDate = query.get("endDate")|| null
  // const search = query.get(param)

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = getExistProp({
      code,
     name,
     keyword,
    page,
    limit,
    branchIds,
    status,
    warehouseId
    })
    return [queryParams];
}, [code, name, keyword,page,status,limit,warehouseId, createSuccess, updateSuccess,deleteSuccess, branchIds]);
  
}
export const useUpdateListWareHouseParams =(query,listoptenSearch)=>{
  const history = useHistory()

  const { path, url } = useRouteMatch()
  const [keyword,setKeyword] = useState(get(query,'keyword',''))

  useEffect(() => {
      setKeyword(get(query,'keyword'));
    },[query.keyword])
    const onParamChange = (param) => {
      if (param['modules'] && !param['modules'].length) {
        param = { modules: undefined };
      }
      if(!param.code)
       for (const key in param) {
          param[key]=  isString(param[key]) ? (param[key].trim()).replace(/[.]/g,'') : param[key]
       }
      history.push({
        pathname: `${path}`,
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword ,{setKeyword, onParamChange }];
  };
export const useWareHousetListPaging = () => useSelector(pagingSelector);

export const useWarehouseLists = (query) => {
  
    return useFetchByParam({
      action: getWareHouseList,
      loadingSelector,
      dataSelector: listSelector,
      failedSelector: getListFailedSelector,
      param: query
    });
  };
export const useGetWareHouseById = (params) => {
  return useFetchByParam({
    action: getWareHouseById,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  })
}
export const UseCreateWareHouse = () => {
  
  useSuccess(createSuccessSelector,'Tạo kho thành công');
  useFailed(createFailedSelector);
  return useSubmit({
    loadingSelector:isSubmitLoadingSelector,
    action: createWareHouse
  })
}
//update
export const UseupdateWareHouse = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật kho thành công',callback);
  useFailed(updateFailedSelector);
  return useSubmit({
    loadingSelector:isSubmitLoadingSelector,
    action: updateWareHouse
  })
}
  export const useDeleteWareHouse = () => {
    useSuccess(deleteSuccessSelector,'Xóa kho thành công' );
    useFailed(deleteFailedSelector);
  
    return useSubmit({
      loadingSelector:isSubmitLoadingSelector,
      action: deleteWareHouse
    });
  }

  export const UseProductListByWareHouseId = (params) => {
    return useFetchByParam({
      action: getProductListByWareHouseId,
      loadingSelector: getByIdLoadingSelector,
      dataSelector: ProductListByWarehouseSelector,
      failedSelector: getByIdFailedSelector,
      param: params
    })
  }