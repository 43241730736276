import { Button, Col, Row, Typography } from 'antd'
import Text from 'antd/lib/typography/Text';
import React, { useMemo, useState } from 'react'
import { DEPOSIT_THRESHOLDS, NUMBER_OF_DIGITS_TO_ROUND } from '~/constants/defaultValue';
import { floorFormatter } from '~/utils/helper'
const ButtonSuggest = ({ active, onClick, value }) => <Col span={11}>
    <Button style={{ borderRadius: '10px', fontWeight: '400', boxShadow: 'rgb(9 9 9 / 16%) 0px 0px 8px 0px', borderWidth: '.5px' }} onClick={onClick} className={`Group-content__exchange__item ${active ? 'active' : 'unActive'}`} >
        <p className='Group-content__exchange__item__text'>{floorFormatter(value)} VNĐ</p>
    </Button>
</Col>
export default function SuggestMoney({ total, form, setIndexActive, indexActive }) {
    const prePaySuggestions = useMemo(() => {
        return DEPOSIT_THRESHOLDS.map(
            threshold =>
                Math.round(
                    (total * threshold) /
                    Math.pow(10, 6),
                    6
                ) * Math.pow(10, 6)
        );
    }, [total]);

    const handleSelectPrice = (valueSelect, index) => {
        form.setFieldsValue({ amount: valueSelect })
        setIndexActive(index)
    }
    return (
        <>
      <p style={{textAlign : 'center'}}>Chọn mức muốn rút</p>
      <Typography.Paragraph style={{textAlign : 'center',color : 'red'}}>(Chỉ được rút bội số của 500.000)</Typography.Paragraph>
        <Row justify='space-between'>
            {prePaySuggestions?.map((e, index) => <ButtonSuggest value={e} onClick={() => handleSelectPrice(e, index)} active={indexActive === index} />)}
        </Row>
        </>
    )
}
