import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getPointSystemsFailed: null,
  getPointSystemFailed:null,
  pointSystems: [],
  pointSystem: null,
  isSubmitLoading : false,
  isGetPointSystemLoading: false,
  PointSystem: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,


  pointSystemsPartner : [],
  getPointSystemsPartnerFailed: null,
  isLoadingPartner : false

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_POINT_SYSTEMS_REQUEST:
      state.isLoading = true;
      state.getPointSystemsFailed = null;
      return;

    case Types.GET_POINT_SYSTEMS_SUCCESS:
      state.isLoading = false;
      state.pointSystems = payload.docs;
      state.getPointSystemsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_POINT_SYSTEMS_FAILED:
      state.isLoading = false;
      state.pointSystems = [];
      state.getPointSystemsFailed = payload;
      return;

    case Types.GET_POINT_SYSTEMS_PARTNER_REQUEST:
      state.isLoadingPartner = true;
      state.getPointSystemsPartnerFailed = null;
      return;

    case Types.GET_POINT_SYSTEMS_PARTNER_SUCCESS:
      state.isLoadingPartner = false;
      state.pointSystemsPartner = payload;
      state.getPointSystemsPartnerFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_POINT_SYSTEMS_PARTNER_FAILED:
      state.isLoadingPartner = false;
      state.pointSystemsPartner = [];
      state.getPointSystemsPartnerFailed = payload;
      return;
    case Types.GET_POINT_SYSTEM_REQUEST:
      state.isGetPointSystemLoading = true;
      state.pointSystem = null
      state.getPointSystemFailed = null;
      return;

    case Types.GET_POINT_SYSTEM_SUCCESS:
      state.isGetPointSystemLoading = false;
      state.pointSystem = payload;
      state.getPointSystemFailed = null;
      return;

    case Types.GET_POINT_SYSTEM_FAILED:
      state.isGetPointSystemLoading = false;
      state.pointSystem = null;
      state.getPointSystemFailed = payload;
      return;
    case Types.CREATE_POINT_SYSTEM_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_POINT_SYSTEM_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_POINT_SYSTEM_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_POINT_SYSTEM_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_POINT_SYSTEM_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.pointSystem = payload;
        return;
  
      case Types.UPDATE_POINT_SYSTEM_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_POINT_SYSTEM_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_POINT_SYSTEM_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_POINT_SYSTEM_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_POINT_SYSTEM_STATE:
      return initState;

    default:
      return;
  }
}, initState);
