import React, { useState } from 'react';
import {
  usePartnerships,
  useDeletePartnership,
  usePartnershipQueryParams,
  useUpdatePartnershipParams,
  useResetPartnership,
  usePartnershipPaging
} from '~/hooks';
import { Table, Popconfirm, Input, Button } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { CARD_STYLE } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import PartnershipForm from './Form';

const { Search } = Input;

const ColumnActions = ({ _id, deletePartnership, onOpenForm }) => {
  return (
    <div className="custom-table__actions">
    <WithPermission permission={POLICY.UPDATE_LEADINGPARTNER}>
      <p onClick={() => onOpenForm(_id)}>Sửa</p>
    </WithPermission>
    <WithPermission permission={POLICY.UPDATE_LEADINGPARTNER}>
        <WithPermission permission={POLICY.DELETE_LEADINGPARTNER}>
        <p>|</p>
        </WithPermission>
    </WithPermission>
    <WithPermission permission={POLICY.DELETE_LEADINGPARTNER}>
      <Popconfirm
        title="Bạn muốn xoá đối tác này?"
        onConfirm={() => deletePartnership(_id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </WithPermission>
    </div>
  );
};

const Partnerships = () => {
  const [query, onPagingChange] = usePartnershipQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdatePartnershipParams(
    query
  );
  const [partnerships, isLoading] = usePartnerships(query);
  const paging = usePartnershipPaging();
  const [partnershipId, setPartnershipId] = useState(null);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const numberOfPartnerships = partnerships.length;

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfPartnerships === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deletePartnership] = useDeletePartnership(onDeleteSuccess);

  useResetPartnership();

  const onOpenForm = (id) => {
    setPartnershipId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setPartnershipId(null);
    setIsOpenForm(false);
  };

  const columns = [
    {
      title: 'Tên Đối tác',
      key: 'name',
      dataIndex: 'name'
    },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address'
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },

    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description'
    }
  ];

  columns.push({
    title: 'Thao tác',
    key: 'action',
    width: '110px',
    render: (record) => {
      return (
        <ColumnActions
          {...record}
          deletePartnership={deletePartnership}
          onOpenForm={onOpenForm}
        />
      );
    }
  });

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách đối tác hàng đầu" />

        <div className="page-content__main">
          <div className="page-content__left" style={CARD_STYLE}>
            <FilterCity
              value={query.cityId}
              // onChange={(value) => onParamChange({ cityId: value })}
              isDisabled={isLoading}
            />
          </div>

          <div className="page-content__right" style={CARD_STYLE}>
            <div className="page-wraper__header">
              <Search
                style={{ width: 300 }}
                placeholder="Tìm đối tác"
                enterButton
                onSearch={() => onParamChange({ keyword })}
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />

              <WithPermission permission={POLICY.WRITE_LEADINGPARTNER}>
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={() => onOpenForm()}
                >
                  Thêm mới
                </Button>
              </WithPermission>
            </div>

            {isLoading ? (
              <SkeletonTable
                rowCount={partnerships.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={partnerships}
                onChange={onPagingChange}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>

      <PartnershipForm
        isOpen={isOpenForm}
        onClose={onCloseForm}
        id={partnershipId}
      />
    </div>
  );
};

export default Partnerships;
