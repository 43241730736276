import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Popover, Row, Select } from 'antd'
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react'
import api from '~/api';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import CompanyJobForm from './CompanyJobForm';
export default function SelectCompanyJob({canCreate=true,required = true,...props}) {
    const [data, setData] = useState([]);
    const [reFetch,setReFetch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const hide = () => {
      setOpen(false);
    };
    const handleOpenChange = (newOpen) => {
      setOpen(newOpen);
    };
    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                const res = await api.companyJob.getAll({ page: 1, limit: MAX_PAGINATION_LIMIT });
                setData(get(res, 'docs', []));
                setLoading(false);
            } catch (error) {
                setLoading(false);

            }
        }
        fetch();
    }, [reFetch]);
    const options = useMemo(() => data?.map((item) => ({
        label: get(item, 'name', ''),
        value: get(item, '_id')
    })), [data])
    return (
        <Row>
            <Col flex={1}>
                <Form.Item
                    label="Công ty"
                    name="companyJobId"
                    rules={[
                        {
                            required,
                            message: 'Xin vui lòng chọn Công ty!'
                        }
                    ]}
                >
                    <Select {...props} allowClear options={options} loading={loading} placeholder="Vui lòng chọn Công ty" />
                </Form.Item>
            </Col>
          {canCreate &&  <Col>
            <Popover  visible={open} onVisibleChange={handleOpenChange} trigger="click" destroyTooltipOnHide overlayStyle={{width  : 450}} content={<CompanyJobForm onCancel={() => {
                hide();
                setReFetch(!reFetch);
            }}/>}>
                <Button icon={<PlusOutlined />} />
            </Popover>
            </Col>}
        </Row>

    )
}
