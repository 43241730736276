import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getWalletsFailed: null,
  wallets: [],

  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WALLETS_REQUEST:
      state.isLoading = true;
      state.getWalletsFailed = null;
      return;

    case Types.GET_WALLETS_SUCCESS:
      state.isLoading = false;
      state.wallets = get(payload,'docs',[]);
      state.getWalletsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_WALLETS_FAILED:
      state.isLoading = false;
      state.wallets = [];
      state.getWalletsFailed = payload;
      return;

    case Types.RESET_STORE:
    case Types.RESET_WALLET_STATE:
      return initState;

    default:
      return;
  }
}, initState);
