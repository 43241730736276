import { Button, Modal, Table, Typography } from 'antd'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import FormShowMail from './FormShowMail';

export default function HistoryMail({data}) {
    const [dataShow,setDataShow] = useState();
    const [show,setShow] = useState(false);
    const onOpen = useCallback((data_) => {
        setShow(true);
        setDataShow(data_)
    },[]);
    const onClose = useCallback(() => {
        setShow(false);
        setDataShow(null)
    },[]);
    const columns = [
        {
            title : "Ngày gửi",
            dataIndex : 'createdAt',
            key : 'createdAt',
            align : 'center',
            render : (createdAt) => <Typography.Text type='secondary'>{moment(createdAt).format("DD-MM-YYYY HH:mm:ss")}</Typography.Text>
        },
        {
            title : "Xem nội dung",
            dataIndex : 'content',
            key : 'content',
            render : (value,rc) => <Button size='small' onClick={() => onOpen(rc)} type='primary' ghost>Xem nội dung</Button>
        }
    ];
  return (
    <div>
        <Table size='small' dataSource={data} columns={columns}/>
        <Modal title="Nội dung mail đã gửi" width={'auto'} visible={show} footer={null} closable onCancel={onClose} destroyOnClose centered>
            <FormShowMail initData={dataShow}/>
        </Modal>
    </div>
  )
}
