import * as Types from '../../constants/actionTypes';
  
  export const getWallets = query => ({
    type: Types.GET_WALLETS_REQUEST,
    payload: query
  });
  
  export const resetWalletState = () => ({
    type: Types.RESET_WALLET_STATE
  });
