import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getAllOrders({ payload: query }) {
  try {
    const response = yield call(Api.order.getAllOrders, query);
    yield put({ type: Types.GET_ALL_ORDERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_ALL_ORDERS_FAILED,
      payload: error.message
    });
  }
}

function* getPendingOrders({ payload: query }) {
  try {
    const response = yield call(Api.order.getPendingOrders, query);
    yield put({ type: Types.GET_PENDING_ORDERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_PENDING_ORDERS_FAILED,
      payload: error.message
    });
  }
}

function* getOrder({ payload: id }) {
  try {
    const order = yield call(Api.order.getById, id);
    yield put({ type: Types.GET_ORDER_SUCCESS, payload: order });
  } catch (error) {
    yield put({ type: Types.GET_ORDER_FAILED, payload: error.message });
  }
}

function* getShippingVendor() {
  try {
    const shippingVendors = yield call(Api.order.getShippingVendors);
    yield put({
      type: Types.GET_SHIPPING_VENDOR_SUCCESS,
      payload: shippingVendors
    });
  } catch (error) {
    yield put({
      type: Types.GET_SHIPPING_VENDOR_FAILED,
      payload: error.message
    });
  }
}

function* addMedicineToOrder(action) {
  try {
    const data = yield call(Api.order.addMedicineToOrder, action.payload);
    yield put({ type: Types.ADD_MEDICINE_TO_ORDER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.ADD_MEDICINE_TO_ORDER_FAILED,
      payload: error.message
    });
  }
}

function* updateOrderMedicine(action) {
  try {
    const data = yield call(Api.order.updateOrderMedicine, action.payload);
    yield put({ type: Types.UPDATE_ORDER_MEDICINE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_ORDER_MEDICINE_FAILED,
      payload: error.message
    });
  }
}

function* deleteOrderMedicine({ payload }) {
  try {
    yield call(Api.order.deleteOrderMedicine, payload);
    yield put({ type: Types.DELETE_ORDER_MEDICINE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_ORDER_MEDICINE_FAILED,
      payload: error.message
    });
  }
}

function* deleteOrder({ payload }) {
  try {
    yield call(Api.order.delete, payload);
    yield put({ type: Types.DELETE_ORDER_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_ORDER_FAILED,
      payload: error.message
    });
  }
}

export default function* order() {
  yield takeLatest(Types.GET_ALL_ORDERS_REQUEST, getAllOrders);
  yield takeLatest(Types.GET_PENDING_ORDERS_REQUEST, getPendingOrders);
  yield takeLatest(Types.GET_ORDER_REQUEST, getOrder);
  yield takeEvery(Types.ADD_MEDICINE_TO_ORDER_REQUEST, addMedicineToOrder);
  yield takeEvery(Types.UPDATE_ORDER_MEDICINE_REQUEST, updateOrderMedicine);
  yield takeEvery(Types.DELETE_ORDER_MEDICINE_REQUEST, deleteOrderMedicine);
  yield takeLatest(Types.DELETE_ORDER_REQUEST, deleteOrder);
  yield takeLatest(Types.GET_SHIPPING_VENDOR_REQUEST, getShippingVendor);
}
