import { get, omit } from 'lodash';
import requester from './requester';

const whCustomerManagement = {
  getAll: query => requester.get('/customer-account', query),
  getById: id => requester.get(`/customer-account/${id}?raw=true`),
  getCustomerHistory: query => requester.get(`/history-customer`, query),
  delete: id => requester.delete(`/customer-account/${id}`),
  getRef: query => requester.get(`/customer-account-parent-child/${get(query,'id','')}`,omit(query,['id'])),
  create: whCustomerManagement => requester.post('/customer-account', whCustomerManagement),
  update: whCustomerManagement =>
  requester.put(`/customer-account/${whCustomerManagement.id}/change-state`, whCustomerManagement),
  refreshLink: id => requester.put(`/customer-account/${id}/refresh`),

  getMeAddress: query => requester.get('/me/addressv2', query),
  getByIdAddress: id => requester.get(`/me/addressv2/${id}`),
  deleteAddress: id => requester.delete(`/me/addressv2/${id}`),
  updateAddress: body => requester.put(`/me/addressv2/${body.id}`, omit(body, ['id'])),
  updateAddressAccount: body => requester.put(`/account-customer/${body.customerId}`, omit(body, ['customerId'])),
  updateNote: body => requester.put(`/customer-account-note/${body.id}`, omit(body, ['id'])),
  getNote: query => requester.get(`/customer-account-note/${query.id}`, omit(query, ['id'])),
  getInfoCustomerByPhone: query => requester.post(`/customer-account/info`,query),
  resetPassword: query => requester.post(`/customer-account/update-password`,query),

};

export default whCustomerManagement;

