import { Button, Input, Modal, Table, Image, Row, Col, Typography, Popconfirm } from 'antd';
import { useCallback, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useCompanyJobPaging, useCompanyJobQueryParams, useCompanyJobs, useDeleteCompanyJob, useResetCompanyJob, useUpdateCompanyJob, useUpdateCompanyJobParams } from '~/hooks/companyJob';
import CompanyJobForm from './CompanyJobForm';
const { Search } = Input;

const CompanyJob = ({ }) => {
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_JOB);
  const canDelete = useMatchPolicy(POLICY.DELETE_JOB);
  const [query] = useCompanyJobQueryParams();
  const [CompanyJobs, isLoading] = useCompanyJobs(query);
  const [keyword, setKeyword, { onParamChange }] = useUpdateCompanyJobParams(query);

  const [, deleteCompanyJob] = useDeleteCompanyJob();
  const paging = useCompanyJobPaging();
  useResetCompanyJob();

  const onOpen = useCallback((_id) => {
    setOpen(true);
    if (_id) setId(_id);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
    setId(null);
  }, []);
  const columns = [
    {
      title: 'Tên công ty',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Hình ảnh đại diện',
      dataIndex: 'avatar',
      key: 'avatar',
      render(h) {
        return <Image src={h} alt="avatar" width={50} />;
      },
    },
    {
      title: "Thao tác",
      dataIndex: '_id',
      key: '_id',
      width: 150,
      align: 'center',
      render: (_id) => <Row gutter={16} wrap={false} justify='center'>
        <Col>
          <WithPermission permission={POLICY.UPDATE_JOB}>
            <Typography.Link onClick={() => onOpen(_id)}>
              Cập nhật
            </Typography.Link>
          </WithPermission>
        </Col>
        <Col>
          <WithPermission permission={POLICY.DELETE_JOB}>
            <Popconfirm onConfirm={() => deleteCompanyJob(_id)} title="Xác nhận xoá">
              <Typography.Link type='danger'>
                Xoá
              </Typography.Link>
            </Popconfirm>
          </WithPermission>
        </Col>
      </Row>
    }
  ];
  const onSearch = (kw) => onParamChange({ keyword: kw?.trim() })
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách công ty tuyển dụng" />

        <div className="page-wraper__header">
          <Search
            allowClear
            style={{ width: 200 }}
            placeholder="Tìm công ty tuyển dụng"
            enterButton
            onSearch={onSearch}
          // onSearch={onSearch}
          />
          <WithPermission permission={POLICY.WRITE_JOB}>
            <Button onClick={() => onOpen()} type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </WithPermission>
        </div>

        <Table
          loading={isLoading}
          className="wh-category-table-striped-rows"
          rowKey={(rc) => rc._id || rc.id}
          columns={columns}
          dataSource={CompanyJobs}
          onChange={({ current, pageSize }) => onParamChange({ page: current, limit: pageSize })}
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `,
            showSizeChanger: true
          }}
          size="small"
        />
      </div>
      <Modal title={(id ? "Cập nhật" : "Tạo mới") + " công ty"} visible={open} destroyOnClose centered footer={null} onCancel={onClose}>
        <CompanyJobForm id={id} onCancel={onClose} />
      </Modal>
    </div>
  );
};

export default CompanyJob;
