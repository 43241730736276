import { put, call, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import Api from '../../api';
import {
  DELETE_QUOTATION_REQUEST,
  DELETE_QUOTATION_SUCCESS,
  DELETE_QUOTATION_FAILED,
  GET_QUOTATIONS_REQUEST,
  GET_QUOTATIONS_SUCCESS,
  GET_QUOTATIONS_FAILED,
  GET_QUOTATION_REQUEST,
  GET_QUOTATION_SUCCESS,
  GET_QUOTATION_FAILED,
  UPDATE_QUOTATION_REQUEST,
  UPDATE_QUOTATION_SUCCESS,
  UPDATE_QUOTATION_FAILED,
  CUSTOMER_CANCEL_QUOTATION_REQUEST,
  CUSTOMER_CANCEL_QUOTATION_SUCCESS,
  CUSTOMER_CANCEL_QUOTATION_FAILED,
  UPDATE_QUOTATION_ITEM_REQUEST,
  UPDATE_QUOTATION_ITEM_SUCCESS,
  UPDATE_QUOTATION_ITEM_FAILED,
  CONFIRM_PREPAYMENT_QUOTATION_REQUEST,
  CONFIRM_PREPAYMENT_QUOTATION_SUCCESS,
  CONFIRM_PREPAYMENT_QUOTATION_FAILED,
  CANCEL_QUOTATION_REQUEST,
  CANCEL_QUOTATION_SUCCESS,
  CANCEL_QUOTATION_FAILED,
  CONVERT_QUOTATION_SUCCESS,
  CONVERT_QUOTATION_FAILED,
  CONVERT_QUOTATION_REQUEST,
  CREATE_QUOTATION_SUCCESS,
  CREATE_QUOTATION_FAILED,
  CREATE_QUOTATION_REQUEST,
  COPY_QUOTATION_SUCCESS,
  COPY_QUOTATION_FAILED,
  COPY_QUOTATION_REQUEST
} from '../../constants/actionTypes';

function* getQuotations({ payload: query }) {
  try {
    const response = yield call(Api.quotations.getAll, query);
    yield put({ type: GET_QUOTATIONS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_QUOTATIONS_FAILED, payload: error });
  }
}

function* getQuotation({ payload: id }) {
  try {
    const Quotation = yield call(Api.quotations.getById, id);
    yield put({ type: GET_QUOTATION_SUCCESS, payload: Quotation });
  } catch (error) {
    yield put({ type: GET_QUOTATION_FAILED, payload: error });
  }
}

function* updateQuotation(action) {
  try {
    const data = yield call(Api.quotations.update, action.payload);
    yield put({ type: UPDATE_QUOTATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_QUOTATION_FAILED, payload: error });
  }
}

function* createQuotation(action) {
  try {
    const data = yield call(Api.quotations.create, action.payload);
    yield put({ type: CREATE_QUOTATION_SUCCESS, payload: data });
  } catch (error) {
    console.log(error,'error');
    yield put({ type: CREATE_QUOTATION_FAILED, payload: error });
  }
}
function* convertQuotation(action) {
  try {
    const data = yield call(Api.quotations.convert, action.payload);
    yield put({ type: CONVERT_QUOTATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CONVERT_QUOTATION_FAILED, payload: error });
  }
}

function* deleteQuotation({ payload }) {
  try {
   const data = yield call(Api.quotations.delete, payload);
    yield put({ type: DELETE_QUOTATION_SUCCESS, payload :data });
  } catch (error) {
    yield put({ type: DELETE_QUOTATION_FAILED, payload: error });
  }
}
function* copyQuotation({ payload }) {
  try {
  const data =  yield call(Api.quotations.copy, payload);
    yield put({ type: COPY_QUOTATION_SUCCESS, payload : data });
  } catch (error) {
    yield put({ type: COPY_QUOTATION_FAILED, payload: error });
  }
}

export default function* Quotation() {
  yield takeLatest(GET_QUOTATIONS_REQUEST, getQuotations);
  yield takeLatest(GET_QUOTATION_REQUEST, getQuotation);
  yield takeLatest(DELETE_QUOTATION_REQUEST, deleteQuotation);
  yield takeLatest(UPDATE_QUOTATION_REQUEST, updateQuotation);
  yield takeLatest(CONVERT_QUOTATION_REQUEST, convertQuotation);
  yield takeLatest(CREATE_QUOTATION_REQUEST, createQuotation);
  yield takeLatest(COPY_QUOTATION_REQUEST, copyQuotation);
}
