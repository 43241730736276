import React, { useEffect, useCallback, useState } from 'react';
import { Form, Input, Row, Button, Skeleton, Col } from 'antd';
import { LANGUAGE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import {
  useCreateEvaluate,
  useEvaluate,
  useInitEvaluate,
  useResetEvaluate,
  useUpdateEvaluate
} from '~/hooks/evaluate';
import UploadImage from '../Utils/UploadImage';
// import moment from 'moment';

const FormItem = Form.Item;
const format = 'HH:mm';

const EvaluateForm = ({ specialityOptions, evaluateId, onClose}) => {
  const [form] = Form.useForm();
  // const [query] = useEvaluateQueryParams();
  const [isSubmitLoading, handleCreate] = useCreateEvaluate(onClose);
  const [, handleUpdate] = useUpdateEvaluate(onClose);
  const [evaluate, isLoading] = useEvaluate(evaluateId);
  const initEvaluate = useInitEvaluate(evaluate);
  const [iconLink, setIconLink] = useState();

  useResetEvaluate();
  useEffect(() => {
    if (!evaluateId) {
      form.resetFields();
    } else {
      form.setFieldsValue(initEvaluate);
    }
    const { iconLink } = initEvaluate;
    if (iconLink) setIconLink(iconLink);
  }, [initEvaluate, evaluateId, form]);
  const onFinish = useCallback(
    (values) => {
      if (evaluateId) {
        handleUpdate({
          ...values,
          id: evaluateId,
          iconLink: iconLink,
        });
      } else {
        handleCreate({
          ...values,
          iconLink: iconLink,
        });
      }
      onClose();
    },
    [handleCreate, handleUpdate, evaluateId, iconLink]
  );

  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="evaluate page-wraper hospital-form-page-content">
      <h4 style={{ margin: '20px 0 40px 20px' }}>
        {evaluateId ? ' Cập nhật' : 'Thêm mới'} đánh giá
      </h4>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initEvaluate}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <FormItem
            label="Hệ số"
            name="rate"
            rules={[{ required: true, message: 'Xin vui lòng nhập hệ số' }]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="Icon"
            name="iconLink"
            // rules={[{ required: true, message: 'Xin vui lòng tải icon lên' }]}
          >
            <Col span={4} className="staff-form__upload-logo">
              <UploadImage
                onChange={setIconLink}
                imgUrl={iconLink}
                title="Icon Image"
              />
            </Col>
          </FormItem>
          <Row className="form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.evaluate.root}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}

            <Button Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {evaluateId ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default EvaluateForm;
