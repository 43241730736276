import produce from 'immer';
import {
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILED,
  RESET_STORE
} from '../../constants/actionTypes';

const initState = {
  isLoading: false,
  getServicesFailed: undefined,
  services: [],

  isGetStaffLoading: false,
  staff: null,
  getStaffFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_SERVICES_REQUEST:
      state.isLoading = true;
      state.services = [];
      state.getServicesFailed = null;
      return;

    case GET_SERVICES_SUCCESS:
      state.isLoading = false;
      state.services = payload;
      state.getServicesFailed = null;
      return;

    case GET_SERVICES_FAILED:
      state.isLoading = false;
      state.services = [];
      state.getServicesFailed = null;
      return;

    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
