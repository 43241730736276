export const GET_INFO_COMPANYS_REQUEST =
    'GET_INFO_COMPANYS_REQUEST';
export const GET_INFO_COMPANYS_SUCCESS =
    'GET_INFO_COMPANYS_SUCCESS';
export const GET_INFO_COMPANYS_FAILED = 'GET_INFO_COMPANYS_FAILED';

export const GET_INFO_COMPANY_REQUEST = 'GET_INFO_COMPANY_REQUEST';
export const GET_INFO_COMPANY_SUCCESS = 'GET_INFO_COMPANY_SUCCESS';
export const GET_INFO_COMPANY_FAILED = 'GET_INFO_COMPANY_FAILED';

export const CREATE_INFO_COMPANY_REQUEST =
    'CREATE_INFO_COMPANY_REQUEST';
export const CREATE_INFO_COMPANY_SUCCESS =
    'CREATE_INFO_COMPANY_SUCCESS';
export const CREATE_INFO_COMPANY_FAILED =
    'CREATE_INFO_COMPANY_FAILED';

export const UPDATE_INFO_COMPANY_REQUEST =
    'UPDATE_INFO_COMPANY_REQUEST';
export const UPDATE_INFO_COMPANY_SUCCESS =
    'UPDATE_INFO_COMPANY_SUCCESS';
export const UPDATE_INFO_COMPANY_FAILED =
    'UPDATE_INFO_COMPANY_FAILED';


export const DELETE_INFO_COMPANY_REQUEST =
    'DELETE_INFO_COMPANY_REQUEST';
export const DELETE_INFO_COMPANY_SUCCESS =
    'DELETE_INFO_COMPANY_SUCCESS';
export const DELETE_INFO_COMPANY_FAILED =
    'DELETE_INFO_COMPANY_FAILED';


export const RESET_INFO_COMPANY_STATE = 'RESET_INFO_COMPANY_STATE';
export const RESET_INFO_COMPANY_ACTION = 'RESET_INFO_COMPANY_ACTION';
