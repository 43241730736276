import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  useBranchIdSessionStorage
} from '~/hooks/utils';
import { useHistory } from 'react-router-dom';
import { isString, get } from 'lodash';
import { getExistProp } from '~/utils/helper';
import { getTransactions, getTransactionsPartner } from '~/redux/transaction/action';
import { useSelector } from 'react-redux';

const getSelector = (key) => (state) => state.transactions[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getTransactionsFailed');

const getPartnerLoadingSelector = getSelector('isGetTransactionPartnerLoading');
const getPartnerSelector = getSelector('listTransactionsPartner');
const getFailedPartnerSelector = getSelector('getTransactionPartnerFailed');

const pagingSelector = getSelector('paging');

export const useTransactionsPaging = () => useSelector(pagingSelector)

export const useTransactionsParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query?.keyword);
  const onParamChange = (param) => {

    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

     for (const key in param) {
        param[key]=  isString(param[key]) ? param[key].trim() : param[key]
     }

    history.push({
      pathname: get(param,'/wh-transactions' || '/workspace/transactions'),
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useTransactionsQueryParams = (param) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const search = query.get(param)
  const startTime = query.get('startTime');
  const endTime = query.get('endTime');
  const whCategory = query.get('whCategory') || null;

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };
  const [branchId] = useBranchIdSessionStorage();

  const regex = /[0-9.]/g

  let newSearch = search
  if (regex.test(search)) {
    newSearch= newSearch.replace(/[. ]/g,'')
  }
  
  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  useEffect(() => {}, [keyword]);
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      startTime,
      endTime,
      [param]: newSearch,
      whCategory,
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    startTime,
    endTime,
    search,
    branchId,
    whCategory
  ]);
};
//Get Transaction
export const useTransactions = (query) => {
  return useFetchByParam({
    action: getTransactions,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};
export const useTransactionsPartner = (query) => {
  return useFetchByParam({
    action: getTransactionsPartner,
    loadingSelector: getPartnerLoadingSelector,
    dataSelector: getPartnerSelector,
    failedSelector: getFailedPartnerSelector,
    param: query
  });
};