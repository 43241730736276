import requester from './requester';

const TrainingCourse = {
  create: (course) => requester.post('/wh-course-training', course),
  update: (query) => requester.put(`/wh-course-training/${query._id}`,query),
  delete: (id) => requester.delete(`/wh-course-training/${id}`),
  getAll: (query) => requester.get('/wh-course-training', query),
  getOne: (id) => requester.get(`/wh-course-training/${id}`),
  getAllPartner:() => requester.get('/wh-course-training/wh-partner'),
  getAllEmployees:() => requester.get('/wh-course-training/user'),
  checkPartnerHasCourse:(query) => requester.post('/wh-course-training/checkPartnerExists',query),
};
export default TrainingCourse;
