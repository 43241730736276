import { useMemo, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { getExistProp } from '~/utils/helper';
import {
  useBranchIdSessionStorage,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess,
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import { createFund, getAllSaleReportSystems, getReportSystems, updateFund } from '~/redux/action';
import { RESET_REPORT_SYSTEM_STATE } from '~/constants/actionTypes';
import moment from 'moment';
import api from '~/api';
//console.log();

const getSelector = (key) => (state) => state.reportSystem[key];

const loadingSelector = getSelector('isLoading');
const ReportSystemsSelector = getSelector('reportSystems');
const getReportSystemsFailedSelector = getSelector('getReportSystemFailed');

const loadingAllSaleSelector = getSelector('isLoadingAllSale');

const loadingSubmitSelector = getSelector('isSubmitLoading');
const createFundSuccessSelector = getSelector('createFundSuccess');
const createFundFailedSelector = getSelector('createFundFailed');
const updateFundSuccessSelector = getSelector('updateFundSuccess');
const updateFundFailedSelector = getSelector('updateFundFailed');

const AllSaleReportSystemsSelector = getSelector('allSale');
const getAllSaleReportSystemsFailedSelector = getSelector('getAllSaleReportSystemFailed');

// const ReportSystemsPaymentSelector = getSelector('reportSystemsPayment');
// const getReportSystemsPaymentFailedSelector = getSelector('getReportSystemFailedPayment');


const pagingSelector = getSelector('paging');

export const useReportSystemPaging = () => useSelector(pagingSelector);

export const useReportSystemsQueryParams = (defaultType,date,branchIdDefault) => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const tabs = query.get('tabs') || '1';
  const category = query.get('category') || null;
  const branchIds = branchIdDefault;
  const type = query.get('type') || defaultType;
  const startDate =  get(date,'startDate',moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss"));
  const endDate = get(date,'endDate',moment().endOf('month').format("YYYY-MM-DDTHH:mm:ss"));
  const whCategory = query.get('whCategory') || null;
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      startDate,
      endDate,
      type,
      tabs,
      category,
      branchIds,
      whCategory,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    startDate,
    endDate,
    type,
    tabs,
    category,
    branchIds,
    whCategory,
  ]);
};

export const useUpdateReportSystemParams = (query) => {
  const history = useHistory();
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/wh-report-system',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [ { onParamChange }];
};

export const useReportSystems= (query) => {
  return useFetchByParam({
    action: getReportSystems,
    loadingSelector,
    dataSelector: ReportSystemsSelector,
    failedSelector: getReportSystemsFailedSelector,
    param: query
  });
};
export const useGetFund = (branchIds) => {
  const createFundSuccess = useSelector(createFundSuccessSelector);
  const updateFundSuccess = useSelector(updateFundSuccessSelector);
    const [data,setData] = useState({
      fund : null,
      loading : false
    })

    useEffect(() => {
      const fetch = async () => {
        setData({
          ...data,
          loading : true
        })
        const res = await api.reportSystem.getFund({branchIds});
        setData({
          fund : res,
          loading : false
        })
      }
        fetch();
    },[createFundSuccess,updateFundSuccess,branchIds])
   
    return [get(data,'fund'),get(data,'loading')]
}

export const useAllSaleReportSystems= (query) => {
  return useFetchByParam({
    action: getAllSaleReportSystems,
    loadingSelector : loadingAllSaleSelector,
    dataSelector: AllSaleReportSystemsSelector,
    failedSelector: getAllSaleReportSystemsFailedSelector,
    param: query
  });
};

// export const useReportSystems= (query) => {
//   return useFetchByParam({
//     action: getReportSystems,
//     loadingSelector,
//     dataSelector: ReportSystemsSelector,
//     failedSelector: getReportSystemsFailedSelector,
//     param: query
//   });
// };
// export const useReportSystemsPayment = (query) => {
//   return useFetchByParam({
//     action: getReportSystemsPayment,
//     loadingSelector,
//     dataSelector: ReportSystemsPaymentSelector,
//     failedSelector: getReportSystemsPaymentFailedSelector,
//     param: query
//   });
// };
export const useCreateFund = (callback) => {
  useSuccess(createFundSuccessSelector, 'Tạo quỹ đầu kì thành công',callback);
  useFailed(createFundFailedSelector, 'Tạo quỹ đầu kì thất bại');

  return useSubmit({
    loadingSelector: loadingSubmitSelector,
    action: createFund
  });
};
export const useUpdateFund = () => {
  useSuccess(updateFundSuccessSelector,'Cập nhật quỹ đầu kì thành công');
  useFailed(updateFundFailedSelector);

  return useSubmit({
    loadingSelector: loadingSubmitSelector,
    action: updateFund
  });
};
export const useCategoryAll = () => {
  const [data,setData] = useState([])
  const [loading,setLoading] = useState(false)
  useEffect(() => {
    const fetchWhCategories = async () => {
      setLoading(true)
      const res = await api.whCategory.getAll({limit : 200})
      if(res.docs){
        const result = res?.docs?.filter(e => e.status === "ACTIVE")
        setData(result)
        setLoading(false)
      }
      else{
        setData([])
        setLoading(false)
      }
    }
    fetchWhCategories()
  },[])
  return [data,loading]
}

export const useResetReportSystem = () => {
  useResetState(RESET_REPORT_SYSTEM_STATE);
};
