import { Form, Input } from 'antd'
import React from 'react'

export default function FormItemYoutube(props) {
    return (
        <Form.Item tooltip="Link Youtube nên Có dạng https://www.youtube.com/watch?v=mftSWpgydoo&" name={["youtubeId"]} label="Link Youtube">
            <Input {...props} />
        </Form.Item>
    )
}
