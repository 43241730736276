import React, { useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import TabEquipment from './TabEquipment';
import TabMaterials from './TabMedical';
import { useMedicalEquipments } from '~/hooks/medicalEquipment';
import toastr from 'toastr'
import api from '~/api';
import { useParams } from 'react-router-dom';

const { TabPane } = Tabs;
export default function TabsMedicalEquipments({
  setWhGroupMedicalEquipment,
  props,
  initWhService,
}) {
  const { index, whPackageLevels, ...rest } = props;
  const whPackageLevelId = whPackageLevels[index]?._id;
  const { id: whServiceId } = useParams();
  const [activeKey, setActiveKey] = useState('1');
  const [dataEquipment, setDataEquipment] = useState([]);
  const [dataMedical, setDataMedical] = useState([]);
  const [isTabClick, setIsTabClick] = useState(true);
  const query = useMemo(() => ({ page: 1, limit: 10 }), []);
  /**
   * @param {Object} query
   */
  const [medicalEquipment, loading] = useMedicalEquipments(query);

  // Get the id of the tool group
  const findTypeWorldHealth = medicalEquipment?.find((item) => item.type === 'WORLDHEALTH');
  const medicalEquipmentTypeId = findTypeWorldHealth?._id; //
  
  // If CCDC/NVL has not saved information, it will not be allowed to switch tabs
  const onTabClick = (key) => {
    // if (isTabClick) {
      setDataEquipment(dataEquipment?.filter((item) => item.groupEquipmentId));
      setDataMedical(dataMedical?.filter((item) => item?.name));
      setActiveKey(key);
      return;
    // } else {
    //   toastr.error('Vui lòng hoàn thành cập nhật CCDC/NVL trước khi chuyển tab');
    //   return;
    // }
  };
/**
 * @param {Array} dataEquipment This is data get from TabEquipment
 * @param {Array} dataMedical This is data get from TabMaterials
 * @param {String} index This is the index of the service package
 */
  useEffect(() => {
    const newData = (dataEquipment?.map((item) => ({ ...item, medicalEquipmentTypeId: medicalEquipmentTypeId }))?.filter((item) => item.groupEquipmentId));
    const newDataMedical = dataMedical?.filter((item) => item?.name);
    setWhGroupMedicalEquipment(newData, newDataMedical, index);
  }, [dataEquipment, dataMedical]);
  useEffect(() => {
    const equipments = initWhService?.packages[index]?.groupProductInits;
    equipments?.length > 0 && setDataEquipment(equipments);
    const materials = initWhService?.packages[index]?.materialInits;
    materials?.length > 0 && setDataMedical(materials);
  },[initWhService])

  return (
    <Tabs
      destroyInactiveTabPane
      onChange={() => {}}
      onTabClick={onTabClick}
      type="card"
      activeKey= {activeKey}
    >
      <TabPane tab={'Công dụ dụng cụ'} key={'1'}>
        <TabEquipment
          props={props} 
          setDataEquipment={setDataEquipment} 
          dataEquipment={dataEquipment} 
          medicalEquipmentTypeId={medicalEquipmentTypeId} 
          setIsTabClick={setIsTabClick} 
          initWhService={initWhService} 
          whPackageLevelId = {whPackageLevelId}
          whServiceId = {whServiceId}
        />
      </TabPane>
      <TabPane tab={'Nguyên vật liệu'} key={'2'}>
        <TabMaterials 
        props={props} 
        setDataMedical={setDataMedical} 
        dataMedical = {dataMedical} 
        setIsTabClick = {setIsTabClick} 
        initWhService={initWhService} 
          whPackageLevelId = {whPackageLevelId}
          whServiceId = {whServiceId}
        />
      </TabPane>
    </Tabs>
  );
}
