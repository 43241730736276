import { InfoCircleOutlined } from '@ant-design/icons';
import { Badge, Col, InputNumber, Row, Tooltip } from 'antd';
import SkeletonInput from 'antd/lib/skeleton/Input';
import Text from 'antd/lib/typography/Text';
import { formatNumberThreeCommaToFixed } from '~/hooks/utils';
import { floorFormatter } from '~/utils/helper';

export default function ExchangePointRefundItemInput({
  coefficient,
  onchangeOtherPoint,
  otherPoint,
  point
}) {
  return (
    <>
      <Col span={24}>
        <Row style={{ margin: '10px 0' }} align='middle'>
          <Col span={4}>
            <Text strong>Số khác</Text>
          </Col>
          <Col span={6}>
            {false ? (
              <SkeletonInput active />
            ) : (
              <InputNumber
                bordered={false}
                min={0}
                max={point}
                inputmode="numeric"
                step={10}
                onChange={value => onchangeOtherPoint(value)}
                value={otherPoint}
                formatter={value => formatNumberThreeCommaToFixed(value,2)
                  }
                style={{ width: '95%', borderBottom: '1px solid #999' }}
              />
            )}
          </Col>

          <Col span={4}>
            <span className='POINT-Exchange'>Điểm</span>
          </Col>
          <Col span={1}>
            <Text style={{ textAlign: 'center' }}>=</Text>
          </Col>
          <Col span={6} style={{ justifySelf: 'flex-end' }}>
            <Text strong>{floorFormatter(coefficient * otherPoint)}</Text>
          </Col>
          <Col  span={2}>
            <span className='VND-Exchange'>VNĐ</span>
          </Col>
        </Row>
      </Col>
    </>
  );
}
