import { useEffect, useMemo } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getNewsCategories,
  createNewsCategory,
  deleteNewsCategory,
  getNewsCategory,
  resetNewsCategoryState,
  updateNewsCategory,
  updateSecurityNewsCategory
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { get, reduce } from 'lodash';

const getSelector = key => state => state.newsCategory[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

export const useNewsCategoryQueryParams = () => {
  const query = useQueryParams();
  const page = query.get('page') || 1;
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const security = query.get('security') || 'PUBLISHED,PRIVATE';

  const deleteNewsCategorySuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    return {
      page,
      limit,
      keyword: keyword || undefined,
      security,
    };
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteNewsCategorySuccess,security]);
};

export const useNewsCategories = query => {
  return useFetchByParam({
    action: getNewsCategories,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateNewsCategory = () => {
  const history = useHistory();
  const callback = () => history.push('/news-category');

  useSuccess(
    createSuccessSelector,
    'Tạo mới danh mục tin tức thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới danh mục tin tức thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createNewsCategory
  });
};

export const useUpdateNewsCategory = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật danh mục tin tức thành công');
  useFailed(updateFailedSelector, 'Cập nhật danh mục tin tức thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateNewsCategory
  });
};

export const useDeleteNewsCategory = () => {
  useSuccess(deleteSuccessSelector, 'Xoá danh mục tin tức thành công');
  useFailed(deleteFailedSelector, 'Xoá danh mục tin tức thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteNewsCategory
  });
};

export const useUpdateSecurityNewsCategory = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật danh mục thành công');
  useFailed(updateFailedSelector, 'Cập nhật danh mục thất bại');

  return useSubmit({
    loadingSelector:isSubmitLoadingSelector,
    action: updateSecurityNewsCategory
  });
};

export const useNewsCategory = id => {
  return useFetchByParam({
    action: getNewsCategory,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};
export const SECURITY_BOOLEAN = (key)=> key!=='PRIVATE' ? true : false;
export const SECURITY_VALUE = (key)=> key ? 'PUBLISHED' : 'PRIVATE';
 
export const useInitNewsCategory = newsCategory =>
  useMemo(() => newsCategory !==null ? {...newsCategory,security : SECURITY_BOOLEAN(newsCategory.security)} : {}, [newsCategory]);

export const useResetNewsCategory = () => {
  useResetState(resetNewsCategoryState);
};

export const useCustomEditorToolbar =(refEditor,defaultBottomMargin)=>{
  useEffect(() => {
    const heightScreen = window.innerHeight;
    const topBar = document.querySelectorAll('#page-topbar > *');
    const tootal = reduce(topBar,(sum,{offsetHeight}) => sum + (offsetHeight || 0),0)
    const elment= refEditor.current;
    var rect = elment.getBoundingClientRect();
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const stickyPanel = entry.target.querySelector('.ck-sticky-panel');
        const editor__main = entry.target.querySelector('.ck-editor__main');
        entry.target.classList.toggle('stickyHearderBarEditor',entry.isIntersecting)
        if (entry.isIntersecting) {
          stickyPanel.style.cssText = `transform: translateY(${tootal}px)`;
          editor__main.style.marginTop = stickyPanel.offsetHeight + 'px';
        } else {
          stickyPanel.style.cssText = 'transform: unset';
          editor__main.style.marginTop = 0+ 'px';  
        }
      });
    },{rootMargin: `0px 0px -${defaultBottomMargin ?? heightScreen - (rect.top - heightScreen) }px 0px`,});
  
    if (elment) {
      observer.observe(elment);
    }
  
    return () => {
      if (elment) {
        observer.unobserve(elment);
      }
    };
  }, [refEditor]); 
}
