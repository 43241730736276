import requester from './requester';

const Job = {
  create: (course) => requester.post('/job-question', course),
  update: (query) => requester.put(`/job-question/${query?._id}`,query),
  delete: (id) => requester.delete(`/job-question/${id}`),
  getAll: (query) => requester.get('/job-question', query),
  getOne: (id) => requester.get(`/job-question/${id}`),
};
export default Job;

