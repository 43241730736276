import React, { useEffect, useMemo, useRef, useState } from 'react';

import { get, set, union } from 'lodash';
import { Col, Input, Modal, Row, Select, Switch, Table, Tag, Tabs, Button, DatePicker } from 'antd';

import Api from '~/api';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import ModalPartnerTransaction from './ModalPartnerTransaction';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useUserWorkspace } from '~/hooks';
import { getExistProp } from '~/utils/helper';
import { useQueryParams } from '~/hooks/utils';
import { TRANSACTIONS, TRANSFER } from '~/constants/defaultValue';
import { FilterDatePicker } from '~/components/Common';
import WhTransactionBill from '~/components/Transaction/WhTransactionBill';
import ModalPartnerReceipt from './ModalPartnerReceipt';

const { Search } = Input;
const TransactionPartner = () => {
  const history = useHistory()
  const [searchBy, setSearchBy] = useState('date');
  const [whBillItem, setWhBillItem] = useState('');
  const [whBillItemId, setWhBillItemId] = useState(null);
  const [whBill, setWhBill] = useState(null);
  const [employeeInfo, setEmployInfo] = useState('');
  // const [actualPrice, setActualPrice] = useState('');

  const [profile] = useUserWorkspace()
  const id = profile?._id

  const [keyword, setKeyword] = useState('');

  const [isOpenPartnerTransaction, setIsOpenPartnerTransaction] = useState(false);
  const [isOpenReceiptForm, setIsOpenReceiptForm] = useState(false);
  const [changeModal, setChangeModal] = useState(false);

  const [transaction, setTransaction] = useState([])
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1)
  const pageSize = 10

  //search

  const getTransactions = async (value) => {
    const query = value ? value :{ partnerId:id}
    const res = await Api.Transaction.getTransactionById(query)
    setTransaction(res?.docs)
    setTotal(get(res,'totalDocs'))
  }
  useEffect(() => {
    getTransactions()
  }, [])

  const onPagingChange = ({ current, pageSize, total }, someObj) => {
    setCurrent(current);
    getTransactions(id, { page: current, limit: pageSize })
  }
  const onSearch = (value,multi) => {
    let searchParams = `?page=${current}&limit=${pageSize}`;
    const regex = /[0-9.]/g
    let newKeyword = keyword
    if (regex.test(keyword)) {
      newKeyword= newKeyword.replace(/[. ]/g,'')
    }
    if (newKeyword) searchParams += `&${searchBy}=${newKeyword}`;
    const queryParams = !multi ? {
      partnerId: id,
      page: current,
      limit: pageSize,
      [searchBy]: newKeyword || value,
    } : {
      partnerId: id,
      page: current,
      limit: pageSize,
      startTime:value.startTime,
      endTime : value.endTime
    }
     history.push({
      pathname: '/workspace/transactions',
       search: new URLSearchParams(
        getExistProp(queryParams)
       ).toString()
      
    });
    getTransactions(queryParams)

  };

  const { TabPane } = Tabs;

  //--------------Props of Table--------------
  const columns = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transactionCodes',
      key: 'transactionCodes',
      render: (value, record) => (
        // value
        <Button type='link'
          onClick={async() => {
            if (record?.typePartner && record.whPaymentVoucher) {
              const whBill = record?.whPaymentVoucher ?? record?.whReceiptVoucher
              setEmployInfo(record?.employeeUser)
              setIsOpenReceiptForm(true)
              setWhBill(whBill)
            } else {
              const whBillItemId = (record?.whPaymentVoucher?.whBillItemId ?? record?.whReceiptVoucher?.whBillItemId)
              const query = {
                whBillItemId: whBillItemId,
                limit: 200,
                page: 1,
                populate: true
                }
                const getData = await Api.Transaction.getTransactionAppointment(query)
                setWhBillItem(getData?.docs)
                setIsOpenPartnerTransaction(true);
            }
          }
          }
        >
          {value}
        </Button>
      )
    },
    {
      title: 'Thời gian',
      key: 'date',
      dataIndex: 'date',
      render: (value) => moment(value).format('HH:mm DD/MM/YYYY')
    },
    {
      title: 'Số tiền',
      dataIndex: 'amountOfMoney',
      key: 'amountOfMoney',
      render: (value) => {
      return `${value.toLocaleString()}`
      }
    },
    {
      title: 'Loại thu chi',
      dataIndex: 'typePartner',
      key: 'typePartner',
      render: (value) => TRANSFER[value]
    },
    {
      title: 'Thông tin',
      dataIndex: 'tag',
      key: 'tag',
      width: '30%',
      render: (value, record) => record?.whPaymentVoucher?.reason ?? record?.whReceiptVoucher?.reason
    },
    {
      title: 'Trạng thái  ',
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        return (
          <span
            className={`partner__transaction-status ${value?.toLowerCase()}`}
          >
           {TRANSACTIONS[value]}
          </span>
        );
      }
    }
  ];
  return (
    <div className="page-wraper page-content page-content-workSpace">
      <div className="container-fluid">
      <Row>
          <Col sm={12} md={6}>
            <Select
              value={searchBy}
              style={{ width: '100%' }}
              onChange={(val) => {
                history.push({
                  pathname: '/workspace/transactions',
                });
                setSearchBy(val);
                setKeyword(null)
              }}
            >
              <Option value="date">Thời gian</Option>
              <Option value="typePartner">Loại thu chi</Option>
              <Option value="amountOfMoney">Số tiền</Option>
              
            </Select>
          </Col>
          <Col sm={8} md={6}>
            {
              {
                date: (
                  <div  style={{display:"flex", flexDirection:"row", marginLeft: "10px", marginRight: "10px"}}
                  >
                    <DatePicker.RangePicker
                    placeholder={['Thời gian bắt đầu','Thời gian kết thúc']}
                      format={"YYYY-MM-DD"}
                      onChange={([startTime, endTime]) => {
                        onSearch({startTime:moment(startTime).format("YYYY-MM-DD"),endTime:moment(endTime).format("YYYY-MM-DD")},true)
                      }}
                    />

                  </div>
                ),
                typePartner: (
                  <Select
                    placeholder={"Chọn loại thu/chi"}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      setSearchBy('typePartner')
                      onSearch(value)
                    }}
                  >
                    <Option value="PAY_IN">Thu</Option>
                    <Option value="PAY_OUT">Chi</Option>
                  </Select>
                ),
                amountOfMoney: (
                  <Search
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Tìm theo số tiền"
                    enterButton
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                    onSearch = {()=> onSearch()}
                  />
                ),
              }[searchBy]
            }
          </Col>
        </Row>
        <Table
          dataSource={transaction}
          onChange={onPagingChange}
          columns={columns}
          size="large"
            scroll={{
              x: 1500
            }}
          pagination={{
            total,
            pageSize,
            current,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
        />
        <Modal
          visible={isOpenPartnerTransaction}
          width={1366}
          footer={null}
          onCancel={() =>
            setIsOpenPartnerTransaction(false)
          }
          onOk={() =>
            setIsOpenPartnerTransaction(false)
          }
        >
          <ModalPartnerTransaction
            whBillItem={whBillItem}
            // actualPrice = {actualPrice}
            onClose={() =>
              setIsOpenPartnerTransaction(false)
            }
            onCancel={() => {
              setIsOpenPartnerTransaction(false);
            }}
            changeModal ={changeModal}
          />
        
        </Modal>
        <Modal
          visible={isOpenReceiptForm}
          width={1366}
          footer={null}
          onCancel={() => setIsOpenReceiptForm(false)}
          onOk={() => setIsOpenReceiptForm(false)}
        >
           <ModalPartnerReceipt
            whBill={whBill}
            employeeInfo={employeeInfo}
            onClose={() => setIsOpenReceiptForm(false)}
            onCancel={() => {
              setIsOpenReceiptForm(false);
            }}
            transaction={transaction}
          />
        </Modal>
          
      </div>
    </div>
  );
};
export default TransactionPartner;
