export const GET_WH_CUSTOMERS_IMPORT_REQUEST = 'GET_WH_CUSTOMERS_IMPORT_REQUEST';
export const GET_WH_CUSTOMERS_IMPORT_SUCCESS = 'GET_WH_CUSTOMERS_IMPORT_SUCCESS';
export const GET_WH_CUSTOMERS_IMPORT_FAILED = 'GET_WH_CUSTOMERS_IMPORT_FAILED';

export const GET_WH_CUSTOMER_IMPORT_REQUEST = 'GET_WH_CUSTOMER_IMPORT_REQUEST';
export const GET_WH_CUSTOMER_IMPORT_SUCCESS = 'GET_WH_CUSTOMER_IMPORT_SUCCESS';
export const GET_WH_CUSTOMER_IMPORT_FAILED = 'GET_WH_CUSTOMER_IMPORT_FAILED';

export const CREATE_WH_CUSTOMER_IMPORT_REQUEST = 'CREATE_WH_CUSTOMER_IMPORT_REQUEST';
export const CREATE_WH_CUSTOMER_IMPORT_SUCCESS = 'CREATE_WH_CUSTOMER_IMPORT_SUCCESS';
export const CREATE_WH_CUSTOMER_IMPORT_FAILED = 'CREATE_WH_CUSTOMER_IMPORT_FAILED';

export const UPDATE_WH_CUSTOMER_IMPORT_REQUEST = 'UPDATE_WH_CUSTOMER_IMPORT_REQUEST';
export const UPDATE_WH_CUSTOMER_IMPORT_SUCCESS = 'UPDATE_WH_CUSTOMER_IMPORT_SUCCESS';
export const UPDATE_WH_CUSTOMER_IMPORT_FAILED = 'UPDATE_WH_CUSTOMER_IMPORT_FAILED';

export const DELETE_WH_CUSTOMER_IMPORT_REQUEST = 'DELETE_WH_CUSTOMER_IMPORT_REQUEST';
export const DELETE_WH_CUSTOMER_IMPORT_SUCCESS = 'DELETE_WH_CUSTOMER_IMPORT_SUCCESS';
export const DELETE_WH_CUSTOMER_IMPORT_FAILED = 'DELETE_WH_CUSTOMER_IMPORT_FAILED';

export const CONVERT_WH_CUSTOMER_IMPORT_REQUEST = 'CONVERT_WH_CUSTOMER_IMPORT_REQUEST';
export const CONVERT_WH_CUSTOMER_IMPORT_SUCCESS = 'CONVERT_WH_CUSTOMER_IMPORT_SUCCESS';
export const CONVERT_WH_CUSTOMER_IMPORT_FAILED = 'CONVERT_WH_CUSTOMER_IMPORT_FAILED';

export const RESET_WH_CUSTOMER_IMPORT_STATE = 'RESET_WH_CUSTOMER_IMPORT_STATE';