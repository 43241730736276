export const GET_BONUS_INTRO_CUSTOMERS_REQUEST =
  'GET_BONUS_INTRO_CUSTOMERS_REQUEST';
export const GET_BONUS_INTRO_CUSTOMERS_SUCCESS =
  'GET_BONUS_INTRO_CUSTOMERS_SUCCESS';
export const GET_BONUS_INTRO_CUSTOMERS_FAILED = 'GET_BONUS_INTRO_CUSTOMERS_FAILED';

export const GET_BONUS_INTRO_CUSTOMER_REQUEST = 'GET_BONUS_INTRO_CUSTOMER_REQUEST';
export const GET_BONUS_INTRO_CUSTOMER_SUCCESS = 'GET_BONUS_INTRO_CUSTOMER_SUCCESS';
export const GET_BONUS_INTRO_CUSTOMER_FAILED = 'GET_BONUS_INTRO_CUSTOMER_FAILED';

export const CREATE_BONUS_INTRO_CUSTOMER_REQUEST =
  'CREATE_BONUS_INTRO_CUSTOMER_REQUEST';
export const CREATE_BONUS_INTRO_CUSTOMER_SUCCESS =
  'CREATE_BONUS_INTRO_CUSTOMER_SUCCESS';
export const CREATE_BONUS_INTRO_CUSTOMER_FAILED =
  'CREATE_BONUS_INTRO_CUSTOMER_FAILED';

export const UPDATE_BONUS_INTRO_CUSTOMER_REQUEST =
  'UPDATE_BONUS_INTRO_CUSTOMER_REQUEST';
export const UPDATE_BONUS_INTRO_CUSTOMER_SUCCESS =
  'UPDATE_BONUS_INTRO_CUSTOMER_SUCCESS';
export const UPDATE_BONUS_INTRO_CUSTOMER_FAILED =
  'UPDATE_BONUS_INTRO_CUSTOMER_FAILED';

export const DELETE_BONUS_INTRO_CUSTOMER_REQUEST =
  'DELETE_BONUS_INTRO_CUSTOMER_REQUEST';
export const DELETE_BONUS_INTRO_CUSTOMER_SUCCESS =
  'DELETE_BONUS_INTRO_CUSTOMER_SUCCESS';
export const DELETE_BONUS_INTRO_CUSTOMER_FAILED =
  'DELETE_BONUS_INTRO_CUSTOMER_FAILED';


export const RESET_BONUS_INTRO_CUSTOMER_STATE = 'RESET_BONUS_INTRO_CUSTOMER_STATE';
