export const GET_APPOINTMENTS_ASSOCIATE_REQUEST = 'GET_APPOINTMENTS_ASSOCIATE_REQUEST';
export const GET_APPOINTMENTS_ASSOCIATE_SUCCESS = 'GET_APPOINTMENTS_ASSOCIATE_SUCCESS';
export const GET_APPOINTMENTS_ASSOCIATE_FAILED = 'GET_APPOINTMENTS_ASSOCIATE_FAILED';

export const GET_APPOINTMENT_ASSOCIATE_REQUEST = 'GET_APPOINTMENT_ASSOCIATE_REQUEST';
export const GET_APPOINTMENT_ASSOCIATE_SUCCESS = 'GET_APPOINTMENT_ASSOCIATE_SUCCESS';
export const GET_APPOINTMENT_ASSOCIATE_FAILED = 'GET_APPOINTMENT_ASSOCIATE_FAILED';

export const CREATE_APPOINTMENT_ASSOCIATE_REQUEST = 'CREATE_APPOINTMENT_ASSOCIATE_REQUEST';
export const CREATE_APPOINTMENT_ASSOCIATE_SUCCESS = 'CREATE_APPOINTMENT_ASSOCIATE_SUCCESS';
export const CREATE_APPOINTMENT_ASSOCIATE_FAILED = 'CREATE_APPOINTMENT_ASSOCIATE_FAILED';

export const UPDATE_APPOINTMENT_ASSOCIATE_REQUEST = 'UPDATE_APPOINTMENT_ASSOCIATE_REQUEST';
export const UPDATE_APPOINTMENT_ASSOCIATE_SUCCESS = 'UPDATE_APPOINTMENT_ASSOCIATE_SUCCESS';
export const UPDATE_APPOINTMENT_ASSOCIATE_FAILED = 'UPDATE_APPOINTMENT_ASSOCIATE_FAILED';

export const DELETE_APPOINTMENT_ASSOCIATE_REQUEST = 'DELETE_APPOINTMENT_ASSOCIATE_REQUEST';
export const DELETE_APPOINTMENT_ASSOCIATE_SUCCESS = 'DELETE_APPOINTMENT_ASSOCIATE_SUCCESS';
export const DELETE_APPOINTMENT_ASSOCIATE_FAILED = 'DELETE_APPOINTMENT_ASSOCIATE_FAILED';

export const RESET_APPOINTMENT_ASSOCIATE = 'RESET_APPOINTMENT_ASSOCIATE';
