import requester from './requester';

const userEmployee = {
  getAll: (query) =>
    requester.get(`/user`, {
      ...query,
      managementArea: query.managementArea
        ? JSON.stringify(
            JSON.parse(query.managementArea).map((item) => item.value)
          )
        : []
    }),
  getById: (id) => requester.get(`/user/${id}?raw=true`),
  getAddressUserById: (customerId) => requester.get(`/address/${customerId}`),
  getUserByNameorPhone: (query) => requester.post(`/customerbynameorid`,query),
  register: (query) => requester.post(`/quotations/${query.billNumber}/registerCustomer`,query),
  updateAddress: (query) => requester.post(`/address/${query.idCustomer}/add`,query),
  create: (user) => requester.post(`/user`, user),
  update: (user) => requester.put(`/user/${user._id}`, user),
  getProfileMe: () => requester.get(`/profile-me`),
  updateProfile: (user) => requester.put(`/profile-me`, user),
  delete: (id) => requester.delete(`/user/${id}`),
  validateUsername: query => requester.post(`/user-valid-username`,query),
};

export default userEmployee;
