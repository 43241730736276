export const GET_UI_CLIENTS_REQUEST =
  'GET_UI_CLIENTS_REQUEST';
export const GET_UI_CLIENTS_SUCCESS =
  'GET_UI_CLIENTS_SUCCESS';
export const GET_UI_CLIENTS_FAILED = 'GET_UI_CLIENTS_FAILED';

export const UPDATE_UI_CLIENT_REQUEST =
  'UPDATE_UI_CLIENT_REQUEST';
export const UPDATE_UI_CLIENT_SUCCESS =
  'UPDATE_UI_CLIENT_SUCCESS';
export const UPDATE_UI_CLIENT_FAILED =
  'UPDATE_UI_CLIENT_FAILED';


export const RESET_UI_CLIENT_STATE = 'RESET_UI_CLIENT_STATE';
