import * as Types from '../../constants/actionTypes';


 //board
export const getSprints = (query) => ({
  type: Types.GET_BOARD_SPRINT_REQUEST,
  payload: query
})
//get by id
export const getSprintById = (id) => ({
  type: Types.GET_BOARD_SPRINT_BY_ID_REQUEST,
  payload: id
})
//create
export const createSprint = (params) => ({
  type: Types.CREATE_BOARD_SPRINT_REQUEST,
  payload: params
})
//update
export const updateSprint = (params) => ({
  type: Types.UPDATE_BOARD_SPRINT_REQUEST,
  payload: params
})
//delete
export const deleteSprint = (id) => ({
  type: Types.DELETE_BOARD_SPRINT_REQUEST,
  payload: id
})
