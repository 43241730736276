import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getGiveCouponsFailed: null,
  getGiveCouponFailed:null,
  GiveCoupons: [],
  GiveCoupon: null,
  isSubmitLoading : false,
  isGetGiveCouponLoading: false,
  GiveCoupon: null,

  loadingUpdateCourse : false,
  updateFailedCourse : null,
  updateSuccessCourse : null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_GIVE_COUPONS_REQUEST:
      state.isLoading = true;
      state.getGiveCouponsFailed = null;
      return;

    case Types.GET_GIVE_COUPONS_SUCCESS:
      state.isLoading = false;
      state.GiveCoupons = get(payload,'docs',[]);
      state.getGiveCouponsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_GIVE_COUPONS_FAILED:
      state.isLoading = false;
      state.GiveCoupons = [];
      state.getGiveCouponsFailed = payload;
      return;
    case Types.GET_GIVE_COUPON_REQUEST:
      state.isGetGiveCouponLoading = true;
      state.GiveCoupon = null
      state.getGiveCouponFailed = null;
      return;

    case Types.GET_GIVE_COUPON_SUCCESS:
      state.isGetGiveCouponLoading = false;
      state.GiveCoupon = payload;
      state.getGiveCouponFailed = null;
      return;

    case Types.GET_GIVE_COUPON_FAILED:
      state.isGetGiveCouponLoading = false;
      state.GiveCoupon = null;
      state.getGiveCouponFailed = payload;
      return;
    case Types.CREATE_GIVE_COUPON_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_GIVE_COUPON_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_GIVE_COUPON_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_GIVE_COUPON_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_GIVE_COUPON_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.GiveCoupons = state.GiveCoupons.map(e => get(e,'_id') === get(payload,'data._id',{}) ? get(payload,'data',{}) : e)
        state.GiveCoupon = get(payload,'data',{});
        return;
  
      case Types.UPDATE_GIVE_COUPON_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;

  
      case Types.DELETE_GIVE_COUPON_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_GIVE_COUPON_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_GIVE_COUPON_STATE:
      return initState;

    default:
      return;
  }
}, initState);
