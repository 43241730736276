import React, { useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
// import WhCustomerManagementDetails from '../WhCustomerManagementDetails';
import { FilterDatePicker } from '~/components/Common';
import { Table,  Input, Switch, Space , Tabs} from 'antd';
import {
  useWhCustomersManagement,
  useDeleteWhCustomerManagement,
  useUpdateWhCustomerManagement,
  useWhCustomersManagementQueryParams,
  useWhCustomerManagementPaging,
  useUpdateCustomerManagementParams,
  useResetWhCustomerManagement
} from '~/hooks/whCustomerManagement';
import { useMatchPolicy } from '~/hooks';
import POLICY from '~/constants/policy';
import WithPermission from '~/components/Common/WithPermission';
import History from './Detail/History';
import Information from './Detail/Information';
import './index.scss';

const { TabPane } = Tabs;
const { Search } = Input;
function callback(key) {
  // console.log(key);
}
function onChange(checked) {
  console.log(`switch to ${checked}`);
}
const ColumnActions = ({ id, state,  isSubmitLoading,updateWhCustomerManagement, ...restProps }) => {

  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.DELETE_WHSETTING}>
        <Switch
          checked={state === 'ACTIVE'}
          onChange={(value) => updateWhCustomerManagement({ state: value ? 'ACTIVE' : 'INACTIVE', id })}
        />
      </WithPermission>
    </div>
  );
};

const WhCustomersManagement = () => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHSETTING);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHSETTING);
  const [query, onPagingChange] = useWhCustomersManagementQueryParams();
  const [isSubmitLoading, updateWhCustomerManagement] = useUpdateWhCustomerManagement();
  const [keyword, { setKeyword, onParamChange }] = useUpdateCustomerManagementParams(query);
  const [whCustomersManagement, isLoading] = useWhCustomersManagement(query);
  // const [current, setCurrent] = useState(1);
  // const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  useResetWhCustomerManagement();
  const paging = useWhCustomerManagementPaging();

  

  const columns = [
    {
      title: 'Mã khách hàng',
      dataIndex: 'customerIdFull',
      key: 'customerIdFull',
      width : '120px',
      render: (record) => {
        return record;
      }
    },

    {
      title: 'Tên',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '150px',
      render: (record) => {
        return record;
      }
    },

    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width : '130px',
      render: (record) => {
        return record;
      }
    },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      width : '400px',
      render: (value) => {
        
        var str = ""
        if (value) {
          var strStreet = value.street ? value.street + ", " : " "
          var strWard = value.ward ? value.ward + ", ": " "
          var strDistrict = value.district ? value.district+", ": " "
          var strCity = value.city ? value.city +", ": " "
          str = strStreet + strWard + strDistrict + strCity
        } else {
          return ""
        }
        return str
      }
      
    },
    {
      title: 'Loại khách hàng',
      dataIndex: 'customerType',
      width: '110px',
      key: 'customerType'
    },
    {
      title: 'Điểm thưởng',
      dataIndex: 'point',
      key: 'point',
      width: '110px',
      align: 'center'
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        record={
          ...record,
          state:record.state,
          id:record._id,
        }
        return (
          <ColumnActions 
            {...record}
            updateWhCustomerManagement={updateWhCustomerManagement}
             />
        );
      }
    }
  ]
  return (
    <div className="page-wrapper page-content whCustomerManagements-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách khách hàng" />
        <div className="page-content__main">
          <div className="page-content__left">
            <div className="page-wrapper__header">
              <Search
            style={{ width: 200 }}
            placeholder="Tên, Loại khách hàng"
            enterButton
            allowClear
            onSearch={() => onParamChange({ keyword })}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />
            </div>
            {/* <FilterWhCustomerManagementStatus
              value={query.state}
              onChange={(value) => onParamChange({ state: value })}
            /> */}
            <FilterDatePicker
              label="Từ"
              value={query.createdAt}
              onChange={(value) => onParamChange({ createdAt: value })}
            />
            <FilterDatePicker
              label="Đến"
              value={query.updatedAt}
              onChange={(value) => onParamChange({ updatedAt: value })}
            />
          </div>
          <div className="page-content__right" >
            {isLoading ? (
              //<SkeletonTable columns={columns} rowCount={10} />
              <SkeletonTable
                columns={columns}
                rowCount={whCustomersManagement.length}
                pagination={paging}
              />
            ) : (
              <Table
              style={{ maxWidth: '100%' }}
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={whCustomersManagement}
                onChange={onPagingChange}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                size="middle"
                expandable={{
                  expandedRowRender: (record) => 
                  <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="Thông tin" key="1">
                      <Information 
                      data={record}
                      ></Information>
                    </TabPane>
                    <TabPane tab="Lịch sử" key="2">
                      <History 
                      {...record}
                      style={{ margin: 0 }} 
                      data={record}
                      >
                      </History>
                    </TabPane>
                </Tabs>
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhCustomersManagement;
