import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getApplyJobs({ payload: query }) {
    try {
      const response = yield call(Api.applyJob.getAll, query);
      yield put({ type: Types.GET_APPLY_JOBS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_APPLY_JOBS_FAILED, payload: error });
    }
  }
function* getApplyJob({ payload: id }) {
    try {
      const response = yield call(Api.applyJob.getById, id);
      yield put({ type: Types.GET_APPLY_JOB_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_APPLY_JOB_FAILED, payload: error });
    }
  }
  function* updateApplyJob(action) {
    try {
      const data = yield call(Api.applyJob.update, action.payload);
      yield put({ type: Types.UPDATE_APPLY_JOB_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_APPLY_JOB_FAILED, payload: error });
    }
  }
  function* deleteApplyJob(action) {
    try {
      const data = yield call(Api.applyJob.delete, action.payload);
      yield put({ type: Types.DELETE_APPLY_JOB_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_APPLY_JOB_FAILED, payload: error });
    }
  }
export default function* ApplyJobs() {
    yield takeLatest(Types.GET_APPLY_JOBS_REQUEST, getApplyJobs);
    yield takeLatest(Types.GET_APPLY_JOB_REQUEST, getApplyJob);
    yield takeLatest(Types.UPDATE_APPLY_JOB_REQUEST, updateApplyJob);
    yield takeLatest(Types.DELETE_APPLY_JOB_REQUEST, deleteApplyJob);
  }
