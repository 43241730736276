export const GET_REPORT_SYSTEMS_REQUEST =
  'GET_REPORT_SYSTEMS_REQUEST';
export const GET_REPORT_SYSTEMS_SUCCESS =
  'GET_REPORT_SYSTEMS_SUCCESS';
export const GET_REPORT_SYSTEMS_FAILED = 'GET_REPORT_SYSTEMS_FAILED';
export const GET_REPORT_SYSTEMS_RECEIVE_REQUEST =
  'GET_REPORT_SYSTEMS_RECEIVE_REQUEST';
export const GET_REPORT_SYSTEMS_RECEIVE_SUCCESS =
  'GET_REPORT_SYSTEMS_RECEIVE_SUCCESS';
export const GET_REPORT_SYSTEMS_RECEIVE_FAILED = 'GET_REPORT_SYSTEMS_RECEIVE_FAILED';

export const GET_REPORT_SYSTEMS_PAYMENT_REQUEST =
  'GET_REPORT_SYSTEMS_PAYMENT_REQUEST';
export const GET_REPORT_SYSTEMS_PAYMENT_SUCCESS =
  'GET_REPORT_SYSTEMS_PAYMENT_SUCCESS';
export const GET_REPORT_SYSTEMS_PAYMENT_FAILED = 'GET_REPORT_SYSTEMS_PAYMENT_FAILED';


export const GET_ALL_SALE_REPORT_SYSTEMS_REQUEST =
  'GET_ALL_SALE_REPORT_SYSTEMS_REQUEST';
export const GET_ALL_SALE_REPORT_SYSTEMS_SUCCESS =
  'GET_ALL_SALE_REPORT_SYSTEMS_SUCCESS';
export const GET_ALL_SALE_REPORT_SYSTEMS_FAILED = 'GET_ALL_SALE_REPORT_SYSTEMS_FAILED';

export const GET_REPORT_SYSTEMS_FUND_REQUEST =
  'GET_REPORT_SYSTEMS_FUND_REQUEST';
export const GET_REPORT_SYSTEMS_FUND_SUCCESS =
  'GET_REPORT_SYSTEMS_FUND_SUCCESS';
export const GET_REPORT_SYSTEMS_FUND_FAILED = 'GET_REPORT_SYSTEMS_FUND_FAILED';
export const CREATE_REPORT_SYSTEMS_FUND_REQUEST =
  'CREATE_REPORT_SYSTEMS_FUND_REQUEST';
export const CREATE_REPORT_SYSTEMS_FUND_SUCCESS =
  'CREATE_REPORT_SYSTEMS_FUND_SUCCESS';
export const CREATE_REPORT_SYSTEMS_FUND_FAILED = 'CREATE_REPORT_SYSTEMS_FUND_FAILED';
export const UPDATE_REPORT_SYSTEMS_FUND_REQUEST =
  'UPDATE_REPORT_SYSTEMS_FUND_REQUEST';
export const UPDATE_REPORT_SYSTEMS_FUND_SUCCESS =
  'UPDATE_REPORT_SYSTEMS_FUND_SUCCESS';
export const UPDATE_REPORT_SYSTEMS_FUND_FAILED = 'UPDATE_REPORT_SYSTEMS_FUND_FAILED';

export const RESET_REPORT_SYSTEM_STATE = 'RESET_REPORT_SYSTEM_STATE';
