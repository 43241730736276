import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, InputNumber, Row, Select, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { filter, get } from 'lodash';
import { useParams } from 'react-router-dom';

import { PATH_APP } from '~/routes/paths';

const FormItem = Form.Item;
const { Option } = Select;

const TableRankingForm = ({
  initialValues,
  onClose,
  onFormSubmit,
  ratesFromStaffGroups,
  staffGroups,
  whPackageLevels,
}) => {
  const [form] = Form.useForm();

  const [filteredWhPackageLevels, setFilteredWhPackageLevels] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    form.resetFields();
    if (!initialValues) {
      setFilteredWhPackageLevels(whPackageLevels);
      // form.resetFields();
    } else {
      const { employeeGroupId } = initialValues;
      setFilteredWhPackageLevels(getAvailableWhPackageLevels(employeeGroupId))
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form])

  // const renderInput = (InputComponent) =>
  //   isLoading
  //     ? <Skeleton.Input active />
  //     : InputComponent;

  const onValuesChange = (change) => {
    const { employeeGroupId } = change;
    if (employeeGroupId) {
      setFilteredWhPackageLevels(getAvailableWhPackageLevels(employeeGroupId))
    }
  }

  const getExistingLevels = employeeGroupId => {
    return filter(ratesFromStaffGroups, item => item.id === employeeGroupId)
      .map(item => get(item, "whPackageLevel.level"))
  }

  const getAvailableWhPackageLevels = employeeGroupId => {
    return whPackageLevels.filter(item => !getExistingLevels(employeeGroupId).includes(item.level))
  }

  const onFinish = (values) => {
    onFormSubmit(values);
  }

  return (
    <div className="table-ranking page-wraper hospital-form-page-content">
      <div className="container-fluid">
        <Form
          // initialValues={initDegree}
          autoComplete="off"
          form={form}
          initialValues={initialValues || {}}
          onValuesChange={onValuesChange}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          onFinish={onFinish}
          requiredMark={false}
          scrollToFirstError
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <h4 style={{margin: '20px 0px 40px 0px'}}>{initialValues ? ' Cập nhật' : 'Thêm mới'}</h4>
          <Col span={24}>
            <FormItem
              label="Nhóm đối tác"
              name="employeeGroupId"
              rules={[
                {
                  required: true,
                  message: 'Xin vui lòng chọn nhóm đối tác'
                }
              ]}

            >
              {false ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  // filterOption={filterAcrossAccents}
                  // loading={isStaffGroupLoading}
                  autoComplete="off"
                  placeholder="Nhóm đối tác"
                  showSearch
                  style={{ width: '100%' }}
                  disabled={!!initialValues}
                >
                  {staffGroups.map(({ id, _id, name }) => (
                    <Option key={id || _id} value={id || _id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>

          <FormItem
            label="Hệ số"
            name="rate"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập hệ số'
              }
            ]}
          >
            <Input />
          </FormItem>

          <Col span={24}>
            <FormItem
            label = "Gói dịch vụ"
              name="level"
              rules={[
                {
                  required: true,
                  message: 'Xin vui lòng chọn gói dịch vụ'
                }
              ]}
            >
              {false ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  // filterOption={filterAcrossAccents}
                  // loading={isStaffGroupLoading}
                  autoComplete="off"
                  placeholder="Gói dịch vụ"
                  showSearch
                  style={{ width: '100%' }}
                  disabled={!!initialValues}
                >
                  {
                    ((!initialValues) ? filteredWhPackageLevels : whPackageLevels)
                      .map(({ id, _id, name, level }) => (
                        <Option key={level} value={level || id || _id}>
                          {get(name, "vi")}
                        </Option>
                      ))
                  }
                </Select>
              )}
            </FormItem>
          </Col>

          <FormItem
            label="Thưởng"
            name="bonus"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập Thưởng'
              }
            ]}
          >
            <InputNumber max={100}/>
          </FormItem>

          <FormItem
            label="Phần trăm thu nhập"
            name="percentIncome"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập Thưởng'
              }
            ]}
          >
            <Input />
          </FormItem>

          <Row className="form__submit-box">
            {false ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.tableRanking.root}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}

            <Button Button type="primary" htmlType="submit" >
              {initialValues ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default TableRankingForm;
