export const GET_APPLY_JOBS_REQUEST =
    'GET_APPLY_JOBS_REQUEST';
export const GET_APPLY_JOBS_SUCCESS =
    'GET_APPLY_JOBS_SUCCESS';
export const GET_APPLY_JOBS_FAILED = 'GET_APPLY_JOBS_FAILED';

export const GET_APPLY_JOB_REQUEST = 'GET_APPLY_JOB_REQUEST';
export const GET_APPLY_JOB_SUCCESS = 'GET_APPLY_JOB_SUCCESS';
export const GET_APPLY_JOB_FAILED = 'GET_APPLY_JOB_FAILED';

export const CREATE_APPLY_JOB_REQUEST =
    'CREATE_APPLY_JOB_REQUEST';
export const CREATE_APPLY_JOB_SUCCESS =
    'CREATE_APPLY_JOB_SUCCESS';
export const CREATE_APPLY_JOB_FAILED =
    'CREATE_APPLY_JOB_FAILED';

export const UPDATE_APPLY_JOB_REQUEST =
    'UPDATE_APPLY_JOB_REQUEST';
export const UPDATE_APPLY_JOB_SUCCESS =
    'UPDATE_APPLY_JOB_SUCCESS';
export const UPDATE_APPLY_JOB_FAILED =
    'UPDATE_APPLY_JOB_FAILED';


export const DELETE_APPLY_JOB_REQUEST =
    'DELETE_APPLY_JOB_REQUEST';
export const DELETE_APPLY_JOB_SUCCESS =
    'DELETE_APPLY_JOB_SUCCESS';
export const DELETE_APPLY_JOB_FAILED =
    'DELETE_APPLY_JOB_FAILED';


export const RESET_APPLY_JOB_STATE = 'RESET_APPLY_JOB_STATE';
