import * as Types from '~/constants/actionTypes';

export const getPartners = (query) => ({
  type: Types.GET_PARTNERS_REQUEST,
  payload: query
});

export const getPartner = (id) => ({
  type: Types.GET_PARTNER_REQUEST,
  payload: id
});

export const createPartner = (employee) => ({
  type: Types.CREATE_PARTNER_REQUEST,
  payload: employee
});
export const createBranchInPartner = (employee) => ({
  type: Types.CREATE_BRANCH_IN_PARTNER_REQUEST,
  payload: employee
});

export const updatePartner = (employee) => ({
  type: Types.UPDATE_PARTNER_REQUEST,
  payload: employee
});

export const updateBranchInPartner = (company) => ({
  type: Types.UPDATE_BRANCH_IN_PARTNER_REQUEST,
  payload: company
});

export const updateBranchStatus = (company) => ({
  type: Types.UPDATE_BRANCH_STATUS_REQUEST,
  payload: company
});
export const deletePartner = (id) => ({
  type: Types.DELETE_PARTNER_REQUEST,
  payload: id
});
export const deleteBranchInPartner = (id) => ({
  type: Types.DELETE_BRANCH_IN_PARTNER_REQUEST,
  payload: id
});

export const getPartnerBranches = (payload) => ({
  type: Types.GET_PARTNER_BRANCHES_REQUEST,
  payload,
});

export const resetPartnerState = () => ({
  type: Types.RESET_PARTNER_STATE
});

export const getListAccessPermissionPartner = (query) => ({
  type: Types.GET_LIST_ACCESS_PERMISSION_PARTNER_REQUEST,
  payload: query
});

export const accessPermissionPartner = (payload) => ({
  type: Types.ACCESS_PERMISSION_PARTNER_REQUEST,
  payload
});

export const denyPermissionPartner = (payload) => ({
  type: Types.DENY_PERMISSION_PARTNER_REQUEST,
  payload
});

export const getResourcePartner = (query) => ({
  type: Types.GET_RESOURCE_PARTNER_REQUEST,
  payload: query
});

export const getListAccessPermissionClinic = (query) => ({
  type: Types.GET_LIST_ACCESS_PERMISSION_CLINIC_REQUEST,
  payload: query
});

export const accessPermissionClinic = (payload) => ({
  type: Types.ACCESS_PERMISSION_CLINIC_REQUEST,
  payload
});

export const denyPermissionClinic = (payload) => ({
  type: Types.DENY_PERMISSION_CLINIC_REQUEST,
  payload
});

export const getResourceClinic = (query) => ({
  type: Types.GET_RESOURCE_CLINIC_REQUEST,
  payload: query
});
