import { get } from 'lodash';
import moment from 'moment';
import { put, call, takeLatest } from 'redux-saga/effects';
import { setEndofDate } from '~/utils/helper';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getReportSystemReceive({ payload }) {
  const {type,endDate} = payload
  const newPayload = {...payload,endDate:setEndofDate(endDate)}
  try {
    let data 
     switch (type) {
          case 'receipts':
            data = yield call(Api.reportSystem.getWhReceiptVoucherReports, newPayload);
            break;
          case 'payment':
            data = yield call(Api.reportSystem.getWhPaymentVoucherReports, newPayload);
            break;
          case 'receiptsCustomer':
            data = yield call(Api.reportSystem.getWhReceiptCustomerReports, newPayload);
            break;
          case 'paymentCustomer':
            data = yield call(Api.reportSystem.getWhPaymentCustomerReports, newPayload);
            break;
          case 'customerCancel':
            data = yield call(Api.reportSystem.getWhPaymentCustomerCancelReports, newPayload);
            break;
        
          default:
            break;
        }
     
    yield put({ type: Types.GET_REPORT_SYSTEMS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_REPORT_SYSTEMS_FAILED,
      payload: error
    });
  }
}
function* getAllSaleReportSystem({ payload }) {
  const newPayload = {...payload,endDate:setEndofDate(get(payload,'endDate',0))}
  try {
    const data = yield call(Api.reportSystem.getAllSale, newPayload);
    yield put({ type: Types.GET_ALL_SALE_REPORT_SYSTEMS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_ALL_SALE_REPORT_SYSTEMS_FAILED,
      payload: error
    });
  }
}
function* getFund({ payload }) {
  const newPayload = {...payload,endDate:setEndofDate(get(payload,'endDate',0))}
  try {
    const data = yield call(Api.reportSystem.getFund, newPayload);
    yield put({ type: Types.GET_REPORT_SYSTEMS_FUND_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_REPORT_SYSTEMS_FUND_FAILED,
      payload: error
    });
  }
}
function* createFund({ payload }) {
  try {
    const data = yield call(Api.reportSystem.createFund, payload);
    yield put({ type: Types.CREATE_REPORT_SYSTEMS_FUND_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_REPORT_SYSTEMS_FUND_FAILED,
      payload: error
    });
  }
}
function* updateFund({ payload }) {
  try {
    const data = yield call(Api.reportSystem.updateFund, payload);
    yield put({ type: Types.UPDATE_REPORT_SYSTEMS_FUND_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_REPORT_SYSTEMS_FUND_FAILED,
      payload: error
    });
  }
}


export default function* rateConfig() {
  yield takeLatest(Types.GET_REPORT_SYSTEMS_REQUEST, getReportSystemReceive);
  yield takeLatest(Types.GET_REPORT_SYSTEMS_FUND_REQUEST, getFund);
  yield takeLatest(Types.CREATE_REPORT_SYSTEMS_FUND_REQUEST, createFund);
  yield takeLatest(Types.UPDATE_REPORT_SYSTEMS_FUND_REQUEST, updateFund);
  yield takeLatest(Types.GET_ALL_SALE_REPORT_SYSTEMS_REQUEST, getAllSaleReportSystem);
  
}
