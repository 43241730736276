import { Tabs } from 'antd'
import React from 'react'
import { useConvertTableDepreciations } from '~/hooks';
import TableDetail from './TableDetail';

const {TabPane} = Tabs;
export default function TabDetail({data}) {
    const {whBill,whBillItem,blocks,whAppointments,listProduct} = useConvertTableDepreciations(data);
  return (
    <div>
    <Tabs defaultActiveKey="1" >
    <TabPane tab="Đơn hàng" key="1">
    <TableDetail dataSource={whBill} dataWhBill={data}/>
    </TabPane>
    <TabPane tab="Dịch vụ" key="2">
    <TableDetail dataSource={whBillItem} dataWhBill={data}/>
    </TabPane>
    <TabPane tab="Block" key="3">
    <TableDetail dataSource={blocks} dataWhBill={data}/>
    </TabPane>
    <TabPane tab="Cuộc hẹn" key="4">
    <TableDetail dataSource={whAppointments} dataWhBill={data}/>
    </TabPane>
    <TabPane tab="Công cụ dụng cụ/NVL" key="5">
    <TableDetail dataSource={listProduct} dataWhBill={data}/>
    </TabPane>
  </Tabs>
    </div>
  )
}
