import React, { useEffect, useCallback } from 'react';
import { Form, Input, Row, Button, Skeleton, Select, Col } from 'antd';
import {
  useCreateRateConfig,
  useRateConfig,
  useInitRateConfig,
  useResetRateConfig,
  useUpdateRateConfig
} from '~/hooks/rateConfig';
import { RATE_CONFIG_TYPES } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import { useStaffGroups } from '~/hooks';
import { get } from 'lodash';
const FormItem = Form.Item;
const { Option } = Select;

const ExperienceForm = ({ onClose, experienceId, specialityOptions }) => {
  const [staffGroups] = useStaffGroups();
  const [form] = Form.useForm();
  const [isSubmitLoading, handleCreate] = useCreateRateConfig(onClose);
  const [, handleUpdate] = useUpdateRateConfig(onClose);
  const [experience, isLoading] = useRateConfig(experienceId);
  const initExperience = useInitRateConfig(experience);
  useResetRateConfig();
  useEffect(() => {
    if (!experienceId) {
      form.resetFields();
    } else {
      const tmp = {
        ...initExperience,
        employeeGroup: get(initExperience, 'employeeGroup.0._id')
      };
      form.setFieldsValue(tmp);
    }
  }, [initExperience, experienceId, form]);

  const onFinish = useCallback(
    (values) => {
      if (experienceId) {
        handleUpdate({
          ...values,
          id: experienceId
        });
      } else {
        handleCreate({
          ...values,

          configType: RATE_CONFIG_TYPES.EXPERIENCE
        });
      }
      onClose();
    },
    [handleCreate, handleUpdate, experienceId]
  );
  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;
  return (
    <div className="Experience page-wraper hospital-form-page-content">
      <h4 style={{ margin: '20px 0 40px 20px' }}>
        {experienceId ? ' Cập nhật' : 'Thêm mới'} kinh nghiệm{' '}
      </h4>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          // initialValues={initExperience}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <Col span={24}>
            <FormItem
              label="Nhóm đối tác"
              name="employeeGroup"
              rules={[
                {
                  required: true,
                  message: 'Xin vui lòng chọn nhóm đối tác'
                }
              ]}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  // loading={isStaffGroupLoading}
                  showSearch
                  allowClear
                  autoComplete="off"
                  placeholder="Nhóm đối tác"
                  style={{ width: '100%' }}
                  // filterOption={filterAcrossAccents}
                >
                  {staffGroups.map(({ id, _id, name }) => (
                    <Option key={id || _id} value={id || _id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>

          <FormItem
            label="Kinh nghiệm"
            name="value"
            rules={[
              { required: true, message: 'Xin vui lòng nhập kinh nghiệm' }
            ]}
          >
            <Input />
          </FormItem>

          <FormItem
            label="Hệ số"
            name="rate"
            rules={[{ required: true, message: 'Xin vui lòng nhập hệ số' }]}
          >
            <Input />
          </FormItem>

          <Row className="form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.rateConfig.experience}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {experienceId ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default ExperienceForm;
