import React, { useState } from 'react';
import { PATH_APP } from '~/routes/paths';
import { useStaffGroups, useDeleteStaffGroup, useStaffGroupQueryParams, useUpdateStaffGroupParams, useStaffGroupsByParam } from '~/hooks/staffGroup';
import { Table, Popconfirm, Button } from 'antd';
import { Link } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import moment from 'moment';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { formatNumberThreeComma } from '~/hooks/utils';
import { WithPermission } from '../Common';
import POLICIES from '~/constants/policy';
import { get } from 'lodash';

const ColumnActions = ({ id, deleteStaffGroup }) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICIES.UPDATE_CONFIGURATION}>
      <Link to={`/staff-group/edit/${id}`}>
        <p>Sửa</p>
      </Link>
      </WithPermission>
      <WithPermission permission={POLICIES.UPDATE_CONFIGURATION}>
        <WithPermission permission={POLICIES.DELETE_CONFIGURATION}>
          <p>|</p>
        </WithPermission>
      </WithPermission>
      <WithPermission permission={POLICIES.DELETE_CONFIGURATION}>
      <Popconfirm
        title="Bạn muốn xoá nhóm nhân viên này?"
        onConfirm={() => deleteStaffGroup(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const StaffGroups = () => {
  const [query] = useStaffGroupQueryParams()
  const [staffGroups, isLoading] = useStaffGroupsByParam(query);
  // const [{onParamChange}] = useUpdateStaffGroupParams(query)
  const [, deleteStaffGroup] = useDeleteStaffGroup();
  const columns = [
    {
      title: 'Tên nhóm nhân viên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <div>
        <p>Tiền thưởng</p>
        <p>(Giới thiệu nhân sự)</p>
      </div>,
      align:'center',
      dataIndex: 'bonusIntro',
      key: 'bonusIntro',
      render : (text) => <span>{formatNumberThreeComma(text) || 0}</span> 
    },
    {
      title: <div>
        <p>Phần trăm thu nhập</p>
        <p>(Tổng thu nhập của NS đã giới thiệu)</p>
      </div>,
      align:'center',
      dataIndex: 'percentIncomeIntro',
      key: 'percentIncomeIntro',
      render : (text) => <span>{formatNumberThreeComma(text) || 0}</span> 
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('DD-MM-YYYY')
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return (
          <ColumnActions {...record} deleteStaffGroup={deleteStaffGroup} />
        );
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách nhóm nhân viên" />

        <div className="page-wraper__header">
          <WithPermission permission={POLICIES.WRITE_CONFIGURATION}>
          <Link to={PATH_APP.config.createStaffGroup}>
            <Button type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </Link>
          </WithPermission>
        </div>

        {isLoading ? (
          <SkeletonTable columns={columns} rowCount={staffGroups.length} />
        ) : (
          <Table
            rowKey={(rc) => rc._id || rc.id}
            columns={columns}
            dataSource={staffGroups}
            pagination={{
              total: staffGroups.length,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
            size="middle"
          />
        )}
      </div>
    </div>
  );
};

export default StaffGroups;
