import { useEffect, useMemo, useRef, useState } from 'react';

import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  Tabs
} from 'antd';
import { Link } from 'react-router-dom';

import { useCreateWhPaymentVoucher, useWhPaymentVouchers } from '~/hooks/whPaymentVoucher';
import toastr from 'toastr'
import { BaseBorderBox, DebounceSelect } from '~/components/Common';
import {
  COMPONENT_MODES,
  MAX_PAGINATION_LIMIT,
  WH_PAYMENT_VOUCHER_ACTION
} from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';

import AccountingDetailsTablePartner from './AccountingDetailsTablePartner';
import './index.scss';
import moment from 'moment';
import { useProfile, useWhPartner } from '~/hooks';
import api from '~/api';
import { get, head, isObject, parseInt } from 'lodash';
import { v4 } from 'uuid';

const FormItem = Form.Item;
const { TabPane } = Tabs;
const { confirm } = Modal;

const DEFAULT_ACCOUNT = 1111;
const mainRowGutter = 24;

// const mainColLayout = {
//   md: { span: 24 },
//   lg: { span: 12 },
//   xl: { span: 10 }
// };

// const verticalLayout = {
//   wrapperCol: { sm: 24, md: 12 },
//   labelCol: { sm: 24, md: 5, lg: 8 }
// };

const WhReportPaymentVoucherForm = ({ onCancel, id, saleRowSelect }) => {
  const [form] = Form.useForm();
  const [profile] = useProfile()
  const [isOpenViewer, setIsOpenViewer] = useState(false);
  const [activeKey, setActiveKey] = useState(1);
  const [loadingSubmit,setLoadingSubmit] = useState(false)
  const queryGetOnePaymentLast = useMemo(() => ({page : 1,limit :1}),[id])
  const [whPaymentVoucher, isGetWhPaymentVoucherLoading] = useWhPaymentVouchers(queryGetOnePaymentLast)
  const issueNumber = useMemo(() => {
    const splitString = get(head(whPaymentVoucher),'code','')
    const Numbers = splitString.split("PC")
    if(Numbers[1]){
      const String = splitString.split(Numbers[1] || '')
      if(head(String))return `${head(String)}${parseInt(Numbers[1])+1}`
      
    }
   return ''
  },[whPaymentVoucher])
  const [isSubmitLoading, handleCreate] = useCreateWhPaymentVoucher();
  const ref = useRef();
  const getStaffs = async (query) => {
    const response = await api.userEmployee.getAll(query);
    const whCustomerAccounts = get(response, 'docs').map((item) => ({
      ...item
    }));
    return whCustomerAccounts;
  };
  const [initStaffs, setInitStaffs] = useState([]);
  const [whPartner, isLoading] = useWhPartner(get(saleRowSelect,'partnerId'));
  const [dataSource, setDataSource] = useState([
    {
      content: `Chi trả tiền lương tháng ${moment().format('MM')}`,
      debitAccount: 334,
      creditAccount: DEFAULT_ACCOUNT,
      amountOfMoney: 0,
      partnerId: null,
      fullName: null
    }
  ]);
  useEffect(() => {
    form.resetFields();
    const getInitStaff = async (employeeId) => {
      try {
        const response = await api.userEmployee.getById(employeeId);
        setInitStaffs([get(response,'data')]);
      } catch (error) {
        console.error(error);
      }
    }
    if (whPartner) {
      const { partnerCode, name, address } = whPartner;
      getInitStaff( get(profile,'_id',''))
      setDataSource([
        {
          ...dataSource[0],
          partnerId: whPartner.partnerCode,
          fullName: whPartner.name,
          amountOfMoney: get(saleRowSelect, 'result', 0),
        }
      ]);
      form.setFieldsValue({
        customerNumber: partnerCode,
        customerName: name,
        customerAddress: {
          street: address
        },
        reason: `Chi trả tiền lương tháng ${moment().format('MM')}`,
        accountingDate: moment(),
        dateOfIssue: moment(),
        originalDocument: 0,
        employeeId : get(profile,'_id','')
      });
    }
  }, [whPartner, id]);
  const onTabClick = (key) => {
    setActiveKey(key);
  };
  const onFinish = async(values) => {
    try {
      setLoadingSubmit(true)
      const submitData = {
        ...values,
        issueNumber,
        accountingDate: moment(get(values, 'accountingDate')).format('YYYY-MM-DD'),
        dateOfIssue: moment(get(values, 'dateOfIssue')).format('YYYY-MM-DD'),
        employeeId : isObject(get(values, 'employeeId')) ? get(values, 'employeeId.value') : get(values, 'employeeId'),
        accountingDetails : ref.current.getAccountingDetailsData() || [],
        customerId : get(whPartner,'_id'),
        totalAmount:ref.current.getAccountingDetailsData().reduce((sum,acc) => sum += get(acc,'amountOfMoney',0),0),
        customerAddress : {
          ...values.customerAddress,
          wardId : 'null',
          districtId : 'null',
          cityId : 'null',
          phoneNumber : whPartner.phone,
          fullName : whPartner.name
        },
        action: WH_PAYMENT_VOUCHER_ACTION.PAYMENT_SALARY
        // whBillId : '',
        // whBillItemId : '',
      };
      const res = await api.whPaymentVoucher.createPaymentPartner(submitData)
      if(res){
        onCancel()
        toastr.success("Tạo phiếu chi lương thành công")
      }
      setLoadingSubmit(false)
    } catch (error) {
      setLoadingSubmit(false)
      console.log(error);
      toastr.error("Tạo phiếu chi lương thất bại")
    }


  };
  return (
    <div className="page-wraper">
      <div className="container-fluid">
        <Form
          autoComplete="off"
          form={form}
          labelAlign="left"
          labelCol={{ sm: 24, md: 24, lg: 4 }}
          onFinish={onFinish}
          requiredMark={false}
          scrollToFirstError
        >
          <Row
            align="top"
            className="staff-form__logo-row"
            gutter={mainRowGutter}
            justify="space-between"
          >
            <Col span={16}>
              <BaseBorderBox title={'Thông tin chug'}>
                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      label={'Đối tượng'}
                      labelCol={{ lg: 8 }}
                      name="customerNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập mã khách hàng!'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled placeholder="Mã nhân viên" />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Tên nhân viên"
                      labelCol={12}
                      name="customerName"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn tên khách hàng!'
                        }
                      ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input disabled/>}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem label="Người nhận" name="customerName">
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled placeholder="Tên nhân viên" />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      label="Địa chỉ"
                      name={['customerAddress', 'street']}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input />}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem name="reason" label="Lý do chi">
                      {isLoading ? <Skeleton.Input active /> : <Input />}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      name="employeeId"
                      label="Nhân viên chi"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn nhân viên'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <DebounceSelect
                          initOptions={initStaffs}
                          fetchOptions={(query) =>
                            getStaffs({ ...query, limit: MAX_PAGINATION_LIMIT })
                          }
                          valueKey="_id"
                          labelKey="fullName"
                          searchKey="keyword"
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Kèm theo"
                      name="originalDocument"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập số chứng từ kèm theo!'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input
                          type="number"
                          addonAfter="chứng từ gốc"
                          width={100}
                          min={0}
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>

            <Col span={8}>
              <BaseBorderBox title={'Chứng từ'}>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      label="Ngày hạch toán"
                      name="accountingDate"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày hạch toán!'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <DatePicker
                          format={'DD/MM/YYYY'}
                          placeholder="Ngày hạch toán"
                        />
                      )}
                    </FormItem>
                    <FormItem
                      label="Ngày chứng từ"
                      name="dateOfIssue"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày chứng từ!'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <DatePicker
                          format={'DD/MM/YYYY'}
                          placeholder="Ngày chứng từ"
                        />
                      )}
                    </FormItem>

                    <FormItem
                      label="Số chứng từ"
                      name="issueNumber"
                      labelCol={{ lg: 8 }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Vui lòng nhập số chứng từ!'
                      //   }
                      // ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled placeholder={issueNumber || ''} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>
          </Row>

          <Tabs
            defaultActiveKey={1}
            destroyInactiveTabPane
            onChange={() => {}}
            onTabClick={onTabClick}
          >
            <TabPane tab="Hạch toán" key={1}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <AccountingDetailsTablePartner
                  ref={ref}
                  mode={COMPONENT_MODES.EDIT}
                  dataSource={dataSource}
                />
              </Space>
            </TabPane>
          </Tabs>

          <Row className="wh-service-form__submit-box">
            <Button
              icon={<SaveOutlined />}
              // onClick={onFinish}
              loading={loadingSubmit}
              type="primary"
              htmlType="submit"
            >
              Lưu
            </Button>
            {isSubmitLoading ? (
              <Button disabled>Đóng</Button>
            ) : onCancel ? (
              <Button icon={<CloseCircleOutlined />} onClick={onCancel}>
                Đóng
              </Button>
            ) : (
                <Button onClick={onCancel} icon={<CloseCircleOutlined />}>Đóng</Button>
            )}
          </Row>
        </Form>
      </div>
      <Modal
        visible={isOpenViewer}
        onCancel={() => setIsOpenViewer(false)}
      ></Modal>
    </div>
  );
};

export default WhReportPaymentVoucherForm;
