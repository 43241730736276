import PropTypes from 'prop-types';
import React from 'react';
import { Col, Card, CardBody, CardTitle } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { useMostCustomerAppointment } from '~/hooks';

const MostCustomerAppointment = ({ query }) => {
  const [mostCustomerAppointment] = useMostCustomerAppointment(query);
  let countArray = [];
  let nameArray = [];
  let countArr = mostCustomerAppointment?.slice(0, 10).map((key, value) => {
    let x = countArray.push(key.count);
    let y = nameArray.push(key?.customerAccount?.fullName);
  });
  const series = [
    {
      name: 'Số lần đặt',
      type: 'column',
      data: countArray
    }
  ];

  const options = {
    fill: {
      colors: ['#ff4560']
    },
    chart: {
      type: 'column',
      height: 350
    },
    plotOptions: {
      bar: {
        columnWidth: '40%'
      }
    },
    stroke: {
      width: [0, 4]
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: nameArray,
      labels: {
        trim: true,
        rotateAlways: true,
        rotate: -45
      }
    },
    yaxis: {
      title: {
        text: 'Số lần đặt'
      }
    }
  };
  return (
    <React.Fragment>
      <Col md={6} xl={3} style={{ width: '50%' }}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4 h4">
              Top 10 khách hàng đặt dịch vụ nhiều nhất
            </CardTitle>
            <div className="mt-3">
              <ReactApexChart
                options={options}
                series={series}
                height="339"
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MostCustomerAppointment;

MostCustomerAppointment.propTypes = {
  reports: PropTypes.array
};
