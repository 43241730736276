import requester from './requester';

const clinicDashboard = {
  getOverViewReport: () => requester.get('/chart-dashboard-clinic/overview'),

  getClinicOverviewToday: () => requester.get('/chart-dashboard-clinic/overviewToday'),
  getClinicOverviewDaily: () => requester.get('/chart-dashboard-clinic/overviewDaily'),
  getClinicOverviewMonthly: () => requester.get('/chart-dashboard-clinic/overviewMonthly'),
  getClinicOverviewQuarterly: () => requester.get('/chart-dashboard-clinic/overviewPrecious'),
  getClinicOverviewYearly: () => requester.get('/chart-dashboard-clinic/overviewYearly'),

  getDailyReport: () => requester.get('/chart-dashboard-clinic-general/daily'),
  getMonthlyReport: () => requester.get('/chart-dashboard-clinic-general/monthly'),
  getQuarterlyReport: () => requester.get('/chart-dashboard-clinic-general/precious'),
  getYearlyReport: () => requester.get('/chart-dashboard-clinic-general/yearly'),
  getMostDaily: () => requester.get('/chart-dashboard-clinic/daily'),
  getMostMonthly: () => requester.get('/chart-dashboard-clinic/monthly'),
  getMostQuarterly: () => requester.get('/chart-dashboard-clinic/precious'),
  getMostYearly: () => requester.get('/chart-dashboard-clinic/yearly')
};

export default clinicDashboard;
