import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import SimpleBar from 'simplebar-react';

//i18n
import { BellFilled, BellOutlined } from '@ant-design/icons';
import { Badge, Divider } from 'antd';
import { debounce, keys } from 'lodash';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SkeletonList from '~/components/SkeletonList';
import { useOnMessageNewWhBillFirebase } from '~/firebase/broadCastChanel/firebaseChanel';
import {
  useChangeStatusNotification,
  useCountUnreadMyNotification,
  useMergeInitNotifications,
  useMyNotifications
} from '~/hooks/myNotification';
import { getMyNotifications } from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import GroupButtonNotify from './GroupButtonNotify';
import NotificationItem from './NotificationItem';
import { useSetNewBill } from '~/hooks/unConfirmWhBill';
import moment from 'moment';

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [status, setStatus] = useState(null);
  const [inputValue, setInputValue] = useState([null, null]);
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const startDate = moment(inputValue[0]).isValid()
    ? moment(inputValue[0]).format('YYYY-MM-DD')
    : null;
  const endDate = moment(inputValue[1]).isValid()
    ? moment(inputValue[1]).format('YYYY-MM-DD')
    : null;

  const query = useMemo(
    () => ({
      page: 1,
      limit: 10,
      status,
      startDate,
      endDate
    }),
    [status, startDate, endDate]
  );

  const [MyNotifications, isLoading] = useMyNotifications(query);
  const notifications = useMergeInitNotifications(MyNotifications)
  const countUnread = useCountUnreadMyNotification();
  const [setNewBill] = useSetNewBill();
  const triggerRefresh = () => {
    dispatch(getMyNotifications(query));
    setNewBill(true)
  }
  useOnMessageNewWhBillFirebase(debounce(triggerRefresh, 800));
  const [,updateStatus] = useChangeStatusNotification();

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => {}}
        className="dropdown d-inline-block"
        tag="li"
        style={{ marginRight: 10 }}
      >
        <DropdownToggle
          onClick={() => {
            if (path === PATH_APP.myNotification.root) return;
            setMenu(!menu);
          }}
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
          style={{ width: 60 }}
        >
          {path === PATH_APP.myNotification.root ? (
            <BellFilled
              style={{
                color: '#3D7FF3',
                backgroundColor: '#EAF5FF',
                fontSize: 22,
                borderRadius: '50%',
                padding: 8
              }}
            />
          ) : (
            <Badge overflowCount={99} size="small" count={countUnread}>
              <BellOutlined
                style={{
                  color: 'white',
                  fontSize: 22
                }}
              />
            </Badge>
          )}
        </DropdownToggle>

        <DropdownMenu style={{zIndex:1601,width: 360}} className="dropdown-menu-lg dropdown-menu-end p-0 z">
          <div className="p-3">
            <Row align='middle' justify='space-between'>
              <Col>
                <h6 className="m-0 font-size-16"> {props.t('Thông báo')} </h6>
              </Col>
              {/* <Col >
                <DropdownAntd className='mx-auto' overlay={
                  <Menu className='notification-item--actionGroup__menu'>
                    <Menu.Item key="0" icon={<CheckOutlined />}>
                      <span>Đánh dấu tất cả là đã đọc</span>
                    </Menu.Item>
                  </Menu>} trigger={['click']}>
                  <div className='notification-item--actionGroup__btn'>
                    <EllipsisOutlined style={{ fontSize: 18 }} />
                  </div>
                </DropdownAntd>
              </Col> */}
            </Row>
          </div>
          <GroupButtonNotify status={status} setStatus={setStatus}  setInputValue={setInputValue}
              inputValue={inputValue} />
          <SimpleBar style={{ height: "400px" }}>
            {isLoading
              ? <SkeletonList rowCount={10} />
              : keys(notifications)?.map((date, index) => <React.Fragment key={date}>
                <Divider orientation="left" orientationMargin="0"
                  style={{ margin: 0, position: 'sticky', zIndex: index + 1, backgroundColor: 'white', top: 0 }}
                >
                  {date}
                </Divider>
                {notifications?.[date]?.map(notification => <NotificationItem updateStatus={updateStatus} onClickItem={debounce(triggerRefresh, 800)} data={notification} />)}
              </React.Fragment>)}
          </SimpleBar>

          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to={PATH_APP.myNotification.root}
            >
              <i className="uil-arrow-circle-right me-1"></i>{' '}
              {props.t('Xem tất cả')}{' '}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};
