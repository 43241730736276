import * as Types from '../../constants/actionTypes';

export const getSystemConfigurationBookingInstructions = (query) => {
  return {
    type: Types.GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_REQUEST,
    payload: query
  };
};
export const getSystemConfigurationChangeBanner = (query) => {
  return {
    type: Types.GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_REQUEST,
    payload: query
  };
};

export const getSystemConfigurationVATs = (query) => {
  return {
    type: Types.GET_SYSTEM_CONFIGURATIONS_OF_VAT_REQUEST,
    payload: query
  };
};

export const getSystemConfiguration = (id) => ({
  type: Types.GET_SYSTEM_CONFIGURATION_REQUEST,
  payload: id
});

export const createSystemConfiguration = (id) => ({
  type: Types.CREATE_SYSTEM_CONFIGURATION_REQUEST,
  payload: id
});

export const updateSystemConfiguration = (id) => ({
  type: Types.UPDATE_SYSTEM_CONFIGURATION_REQUEST,
  payload: id
});

export const deleteSystemConfiguration = (id) => ({
  type: Types.DELETE_SYSTEM_CONFIGURATION_REQUEST,
  payload: id
});

export const resetSystemConfigurationState = () => ({
  type: Types.RESET_SYSTEM_CONFIGURATION_STATE
});
