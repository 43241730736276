import { put, call, takeLatest, takeEvery, select } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';
import { get,omit } from 'lodash';

//board
function* getBoards({ payload: query }) {
  try {
    const data = yield call(Api.workFlow.getAll, query);
    yield put({ type: Types.GET_BOARDS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_BOARDS_FAILED, payload: error.message });
  }
}
function* getBoardById({ payload }) {
  try {
    const response = yield call(Api.workFlow.getById, payload);
    yield put({ type: Types.GET_BOARD_BY_ID_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_BOARD_BY_ID_FAILED, payload: error.message });
  }
}
function* deleteBoard({ payload }) {
  try {
    const response = yield call(Api.workFlow.delete, payload);
    yield put({ type: Types.DELETE_BOARD_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.DELETE_BOARD_FAILED, payload: error.message });
  }
}
function* createBoard(action) {
  try {
    const response = yield call(Api.workFlow.create, action.payload);
    yield put({ type: Types.CREATE_BOARD_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.CREATE_BOARD_FAILED, payload: error.message });
  }
}
function* updateBoard(action) {
  try {
    const response = yield call(Api.workFlow.update, action.payload);
    yield put({ type: Types.UPDATE_BOARD_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.UPDATE_BOARD_FAILED, payload: error.message });
  }
}
//get work flow
function* getWorkList({ payload: query }) {
  try {
    const response = yield call(Api.workFlow.getListWorkFlow, query);
    yield put({ type: Types.GET_WORK_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_WORK_LIST_FAILED, payload: error.message });
  }
}
function* getListBoardConfig({ payload }) {
  try {
    const response = yield call(Api.workFlow.getListBoardConfig, payload);
    yield put({ type: Types.GET_LIST_BOARD_CONFIG_SUCCESS, payload: response });
    for (const res of response) {
      yield put({
        type: Types.ADD_BOARD_CONFIG_ITEM_REQUEST,
        payload: { id: res._id,...omit(payload,['sprintId']) }
      });
    }
  } catch (error) {
    yield put({
      type: Types.GET_LIST_BOARD_CONFIG_FAILED,
      payload: error.message
    });
  }
}

function* getBoardConfigItem({ payload }) {
  try {
    const response = yield call(Api.workFlow.getAllTask, payload);
    yield put({
      type: Types.ADD_BOARD_CONFIG_ITEM_SUCCESS,
      payload: { id: payload.id, data: response }
    });
  } catch (error) {
    yield put({
      type: Types.ADD_BOARD_CONFIG_ITEM_FAILED,
      payload: error.message
    });
  }
}
function* filterBoardConfigItem({ payload: query }) {
  try {
    const response = yield call(Api.workFlow.filterTask, query);
    const dataList  =state=>state.workList.list
    const listSprint = yield select(dataList);
    const groupedData = {};
    response.forEach((item) => {
      const boardConfigId = item.boardConfigId;

      if (!groupedData[boardConfigId]) {
        groupedData[boardConfigId] = [];
      }
      
      groupedData[boardConfigId].push(item);
    });
    for (const item of listSprint) {
      yield put({
        type: Types.ADD_BOARD_CONFIG_ITEM_SUCCESS,
        payload: { id: item._id, data: groupedData[item._id] ?? [] },
      });
    }
  } catch (error) {
    console.log(error);
  }
}
function* getWorkById({ payload }) {
  try {
    const response = yield call(Api.workFlow.getListWorkFlowById, payload);
    yield put({ type: Types.GET_WORK_LIST_BY_ID_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_WORK_LIST_BY_ID_FAILED,
      payload: error.message
    });
  }
}

function* createWork(action) {
  try {
    const response = yield call(Api.workFlow.createWorkFlow, action.payload);
    yield put({ type: Types.CREATE_WORK_SUCCESS, payload: response });
    yield put({
      type: Types.GET_LIST_BOARD_CONFIG_REQUEST,
      payload: {
        sprintId: action.payload.sprintId
      }
    });
  } catch (error) {
    yield put({ type: Types.CREATE_WORK_FAILED, payload: error.message });
  }
}
function* updateWork(action) {
  try {
    const response = yield call(Api.workFlow.updateWorkFlow, action.payload);
    yield put({ type: Types.UPDATE_WORK_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.UPDATE_WORK_FAILED, payload: error.message });
  }
}
function* deleteWork({ payload }) {
  const { id, boardId, sprintId } = payload;
  try {
    const data = yield call(Api.workFlow.deleteWorkFlow, id);
    yield put({ type: Types.DELETE_WORK_SUCCESS, payload: data });
    yield put({ type: Types.GET_LIST_BOARD_CONFIG_REQUEST, payload:payload});
  } catch (error) {
    yield put({ type: Types.DELETE_WORK_FAILED, payload: error.message });
  }
}
//manage || staff
function* getListManages({ payload: query }) {
  try {
    const response = yield call(Api.workFlow.getAllManagers, query);
    yield put({ type: Types.GET_LIST_MANAGERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_LIST_MANAGERS_FAILED, payload: error.message });
  }
}
function* getListManagersByIdBoard({ payload }) {
  try {
    const response = yield call(Api.workFlow.getAllManagersByIdBoard, payload);
    yield put({
      type: Types.GET_LIST_MANAGERS_BY_ID_BOARD_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: Types.GET_LIST_MANAGERS_BY_ID_BOARD_FAILED,
      payload: error.message
    });
  }
}
function* getListStaffs({ payload: query }) {
  try {
    const response = yield call(Api.workFlow.getAllStaffs, query);
    yield put({ type: Types.GET_LIST_STAFFS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_LIST_STAFFS_FAILED, payload: error.message });
  }
}
function* getListStaffsByIdBoard({ payload }) {
  try {
    const response = yield call(Api.workFlow.getAllStaffsByIdBoard, payload);
    yield put({
      type: Types.GET_LIST_STAFFS_BY_ID_BOARD_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: Types.GET_LIST_STAFFS_BY_ID_BOARD_FAILED,
      payload: error.message
    });
  }
}

function* getBoardGroup({ payload }) {
  try {
    const response = yield call(Api.workFlow.getBoardGroup, payload);
    yield put({ type: Types.GET_BOARD_GROUP_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_BOARD_GROUP_FAILED, payload: error.message });
  }
}
function* getListTaskBySprints({ payload }) {
  try {
    const response = yield call(Api.workFlow.getTaskInSprints, payload);
    yield put({
      type: Types.GET_LIST_TASK_BY_SPRINTS_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: Types.GET_LIST_TASK_BY_SPRINTS_FAILED,
      payload: error.message
    });
  }
}
export default function* workList() {
  //board
  yield takeLatest(Types.GET_BOARDS_REQUEST, getBoards);
  yield takeLatest(Types.GET_BOARD_BY_ID_REQUEST, getBoardById);
  yield takeLatest(Types.DELETE_BOARD_REQUEST, deleteBoard);
  yield takeLatest(Types.CREATE_BOARD_REQUEST, createBoard);
  yield takeLatest(Types.UPDATE_BOARD_REQUEST, updateBoard);
  //workflow
  yield takeLatest(Types.GET_WORK_LIST_REQUEST, getWorkList);
  yield takeLatest(Types.GET_LIST_BOARD_CONFIG_REQUEST, getListBoardConfig);
  yield takeEvery(Types.ADD_BOARD_CONFIG_ITEM_REQUEST, getBoardConfigItem);
  yield takeLatest(Types.GET_WORK_LIST_BY_ID_REQUEST, getWorkById);
  yield takeLatest(Types.CREATE_WORK_REQUEST, createWork);
  yield takeLatest(Types.UPDATE_WORK_REQUEST, updateWork);
  yield takeLatest(Types.DELETE_WORK_REQUEST, deleteWork);
  //managers||staffs
  yield takeLatest(Types.GET_LIST_MANAGERS_REQUEST, getListManages);
  yield takeLatest(Types.GET_LIST_STAFFS_REQUEST, getListStaffs);
  yield takeLatest(
    Types.GET_LIST_MANAGERS_BY_ID_BOARD_REQUEST,
    getListManagersByIdBoard
  );
  yield takeLatest(
    Types.GET_LIST_STAFFS_BY_ID_BOARD_REQUEST,
    getListStaffsByIdBoard
  );
  yield takeLatest(
    Types.GET_LIST_STAFFS_BY_ID_BOARD_REQUEST,
    getListStaffsByIdBoard
  );

  yield takeLatest(Types.GET_BOARD_GROUP_REQUEST, getBoardGroup);
  yield takeLatest(
    Types.GET_LIST_TASK_BY_SPRINTS_REQUEST,
    getListTaskBySprints
  );
  yield takeLatest(
    Types.FILTER_BOARD_CONFIG_ITEM_REQUEST,
    filterBoardConfigItem
  );
}
