import { Button, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect } from 'react';
import { requireRules } from '~/constants/defaultValue';
import { useJobQuestion, useCreateJobQuestion, useUpdateJobQuestion } from '~/hooks/jobQuestion';
import { renderLoading } from '~/utils/helper';
import UploadImage from '../Utils/UploadImage';

export default function JobQuestionForm({ id, onCancel,onUpdate }) {
    const [form] = Form.useForm();
    const [isSubmitLoading, onCreate] = useCreateJobQuestion(onCancel);
    const [jobQuestion,loading] = useJobQuestion(id);
    const onFinish = useCallback((values) => {
        if (id) {
            onUpdate({
                ...values,
                _id: id
            });
        } else {
            onCreate(values)
        }
    }, [id]);
    useEffect(() => {
        if (id && jobQuestion) {
            form.setFieldsValue({ ...jobQuestion })
        }
    }, [jobQuestion,id]);

    return (
        <Form labelCol={{ span: 4 }} form={form} onFinish={onFinish}>
            <Form.Item label="Câu hỏi" name={"question"}>
                {renderLoading(<TextArea />,loading)}
            </Form.Item>
            <Row justify='end'>
                <Button loading={isSubmitLoading} htmlType='submit' type='primary'>
                    {id ? "Cập nhật" : "Tạo mới"}
                </Button>
            </Row>
        </Form>
    )
}
