import produce from 'immer';
import { clone, get, uniqBy } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const calculateProgress = (progressList = []) => {
    const newProgressList = progressList?.map(progress => {
        const listDone = get(progress, 'progress',[])?.filter(item => !!get(item,'[1].check'));
        const progressValue = parseInt(listDone?.length ?? 0) / parseInt(get(progress, 'progress',[])?.length) * 100;
        return {...progress,progressValue : isNaN(progressValue) ? 0 : progressValue.toFixed(0)}
    })
    return newProgressList
}

const initState = {
    isLoadingHistory: false,
    getListHistoryFailed: undefined,
    listHistory: [],


    isLoading: false,
    getListFailed: undefined,
    list: [],

    isLoadingTask: false,
    getListTaskFailed: undefined,
    listTask: [],

    isGetByIdLoading: false,
    byId: null,
    getByIdFailed: null,

    deleteSuccess: null,
    deleteFailed: null,

    isSubmitLoading: false,
    createSuccess: null,
    createFailed: null,

    updateSuccess: null,
    updateFailed: null,

    assignSuccess: null,
    assignFailed: null,
    isLoadingAssign: null,

    updateProgressSuccess: null,
    updateProgressFailed: null,

    isLoadingComment: false,
    commentSuccess: null,
    commentFailed: null,

    isLoadingCommentList:false,
    listComment:[],

    listTaskSearch: [],
    isloadingSearchTask: false,
    getSearchListTaskFailed:null,

    listTaskRelation: [],
    isloadingRelationTask: false,
    getListTaskRelationFailed:null,

    isUpdateRelation: false,
    updateRelationSuccess: null,
    updateRelationFailed: null,

    paging: null


};
export default produce((state, { type, payload }) => {
    switch (type) {



        //task
        case Types.GET_ALL_TASKS_REQUEST:
            state.isLoading = true;
            state.getListFailed = null;
            return;
        case Types.GET_ALL_TASKS_SUCCESS:
            state.isLoading = false;
            state.listTask = payload;

            return;
        case Types.GET_ALL_TASKS_FAILED:
            state.isLoading = false;
            state.getListFailed = payload;
            state.listTask = [];
            return;

        case Types.GET_TASK_BY_ID_REQUEST:
            state.isGetByIdLoading = true;
            state.getByIdFailed = null;
            return;
        case Types.GET_TASK_BY_ID_SUCCESS:
            state.isGetByIdLoading = false;
            state.byId = {...payload,progressListShow : calculateProgress(get(payload,'progressListShow',[]))};
            state.getByIdFailed = null;
            return;
        case Types.GET_TASK_BY_ID_FAILED:
            state.isGetByIdLoading = false;
            state.getByIdFailed = payload;
            state.byId = null;
            return;
        case Types.CREATE_TASK_REQUEST:
            state.isSubmitLoading = true;
            state.createFailed = null;
            return;
        case Types.CREATE_TASK_SUCCESS:
            state.isSubmitLoading = false;
            state.createSuccess = payload;
            return;
        case Types.CREATE_TASK_FAILED:
            state.isSubmitLoading = false;
            state.createFailed = payload;
            return;

        case Types.UPDATE_TASK_REQUEST:
            state.isSubmitLoading = true;
            state.updateFailed = null;
            return;
        case Types.UPDATE_TASK_SUCCESS: // details update
            state.isSubmitLoading = false;
            const data = payload;
            state.byId = {...data,progressListShow : calculateProgress(get(data,'progressListShow'))};
            state.updateSuccess = payload;
            return;
        case Types.UPDATE_TASK_FAILED:
            state.isSubmitLoading = false;
            state.updateFailed = payload;
            return;

            // Update progress Task
        case Types.UPDATE_PROGRESS_TASK_REQUEST:
            state.isSubmitLoading = true;
            state.updateProgressFailed = null;
            return;
        case Types.UPDATE_PROGRESS_TASK_SUCCESS:
            state.isSubmitLoading = false;
            state.byId = {...payload,progressListShow : calculateProgress(get(payload,'progressListShow'))};
            state.updateProgressSuccess = payload;
            return;
        case Types.UPDATE_PROGRESS_TASK_FAILED:
            state.isSubmitLoading = false;
            state.updateProgressFailed = payload;
            return;

        case Types.DELETE_TASK_REQUEST:
            state.isSubmitLoading = true;
            state.deleteFailed = null;
            return;
        case Types.DELETE_TASK_SUCCESS:
            state.isSubmitLoading = false;
            state.deleteSuccess = payload;
            state.deleteFailed = null;
            return;
        case Types.DELETE_TASK_FAILED:
            state.isSubmitLoading = false;
            state.deleteFailed = payload;
            state.deleteSuccess = null;
            return;
        case Types.COPY_TASK_REQUEST:
            state.isSubmitLoading = true;
            state.createSuccess = null;
            return;
        case Types.COPY_TASK_SUCCESS:
            state.isSubmitLoading = false;
            state.createSuccess = payload.data;
            state.createFailed = null;
            return;
        case Types.COPY_TASK_FAILED:
            state.isSubmitLoading = false;
            state.createFailed = payload;
            state.createSuccess = null;
            return;

        //historySpentTime
        
        case Types.GET_HISTORY_ACTIVITY_REQUEST:
            state.isLoadingHistory = true;
            state.getListHistoryFailed = null;
            return;
        case Types. GET_HISTORY_ACTIVITY_SUCCESS:
            state.isLoadingHistory = false;
            state.listHistory = payload;
            return;
        case Types.GET_HISTORY_ACTIVITY_FAILED:
            state.isLoadingHistory = false;
            state.getListHistoryFailed = payload;
            state.listHistory = [];
            return;

            // ASSIGN TASK
        case Types.ASSIGN_TASK_REQUEST:
            state.isLoadingAssign = true;
            state.assignFailed = null;
            return;
        case Types.ASSIGN_TASK_SUCCESS:
            state.isLoadingAssign = false;
            state.byId = {...state.byId,assignUser : get(payload,'data.assignUser')}
            state.assignSuccess = payload;

            return;
        case Types.ASSIGN_TASK_FAILED:
            state.isLoadingAssign = false;
            state.assignFailed = payload;
            return;

        case Types.COMMENT_LIST_REQUEST: {
          state.isLoadingCommentList = true;
          break;
        }
        case Types.COMMENT_LIST_SAVE: {
          state.listComment = [...state.listComment,...payload]
          break;
        }
        case Types.COMMENT_LIST_PUSH: {
          state.listComment = [payload,...state.listComment]
          break;
        }
        case Types.COMMENT_LIST_EMOTION: {

          const index = state.listComment.findIndex((item)=>item._id===payload._id)
          if(index<0){
            state.listComment = [payload,...state.listComment ]
          }else{
            state.listComment[index] = payload
          }
          break;
        }

        case Types.COMMENT_DELETE: {
        const index = state.listComment.findIndex((item)=>item._id===payload._id)
          if(index>=0){
            state.listComment.splice(index, 1)
          }
          break;
        }
        case Types.COMMENT_UPDATE: {
        const index = state.listComment.findIndex((item)=>item._id===payload._id)
        if(index>=0){
          state.listComment[index] = payload;
          }
          break;
        }

        case Types.COMMENT_LIST_SUCCESS: {
          state.isLoadingCommentList = false;
          state.listComment = payload
          break;
        }
        case Types.COMMENT_REQUEST:
            state.isLoadingComment = true;
            state.commentFailed= null;
            return;
        case Types.COMMENT_SUCCESS:
            state.isLoadingComment = false;
            state.commentSuccess = payload;
            return;
        case Types.COMMENT_FAILED:
            state.isLoadingComment = false;
            state.commentFailed = payload;
            return;
        case Types.COMMENT_RESET:{
          state.listComment=[]
          }
        return

        case Types.SEARCH_TASK_REQUEST:
          state.isloadingSearchTask = true;
          state.getListTaskFailed= null;
          return;
      case Types.SEARCH_TASK_SUCCESS:
          state.isloadingSearchTask = false;
          state.listTaskSearch = payload;
          return;
      case Types.SEARCH_TASK_FAILED:
          state.isloadingSearchTask = false;
          state.getSearchListTaskFailed = payload;
          return;

        case Types.UPDATE_RELATION_REQUEST:
          state.isUpdateRelation = true;
          state.updateRelationSuccess= null;
          state.updateRelationFailed= null;
          return;
      case Types.UPDATE_RELATION_SUCCESS:
          state.isUpdateRelation = false;
          state.updateRelationSuccess = payload;
          return;
      case Types.UPDATE_RELATION_FAILED:
          state.isUpdateRelation = false;
          state.updateRelationFailed = payload;
          return;

        case Types.GET_RELATION_REQUEST:
          state.isloadingRelationTask = true;
          state.updateRelationSuccess= null;
          state.updateRelationFailed= null;
          return;
      case Types.GET_RELATION_SUCCESS:
          state.isloadingRelationTask = false;
          state.listTaskRelation = payload;
          return;
      case Types.GET_RELATION_FAILED:
          state.isloadingRelationTask = false;
          state.getListTaskRelationFailed = payload;
          return;

        case Types.RESET_STORE:
            return initState;

        default:
            return;
    }
}, initState);
