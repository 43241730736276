import * as Types from '../../constants/actionTypes';

export const getWhCustomersImport = (query) => ({
  type: Types.GET_WH_CUSTOMERS_IMPORT_REQUEST,
  payload: query
});

export const getWhCustomerImport = (id) => ({
  type: Types.GET_WH_CUSTOMER_IMPORT_REQUEST,
  payload: id
});

export const createWhCustomerImport = (whCustomerImport) => ({
  type: Types.CREATE_WH_CUSTOMER_IMPORT_REQUEST,
  payload: whCustomerImport
});

export const updateWhCustomerImport = (whCustomerImport) => ({
  type: Types.UPDATE_WH_CUSTOMER_IMPORT_REQUEST,
  payload: whCustomerImport
});

export const deleteWhCustomerImport = (id) => ({
  type: Types.DELETE_WH_CUSTOMER_IMPORT_REQUEST,
  payload: id
});

export const convertWhCustomerImport = (whCustomerImport) => ({
  type: Types.CONVERT_WH_CUSTOMER_IMPORT_REQUEST,
  payload: whCustomerImport
});

export const resetWhCustomerImportState = () => ({
  type: Types.RESET_WH_CUSTOMER_IMPORT_STATE
});
