import * as Types from '../../constants/actionTypes';

export const getWhCategories = (query) => ({
  type: Types.GET_WH_CATEGORIES_REQUEST,
  payload: query
});
export const getWhCategoriesList = (query) => ({
  type: Types.GET_WH_CATEGORIES_LIST_REQUEST,
  payload: query
})
export const getWhCategory = (id) => ({
  type: Types.GET_WH_CATEGORY_REQUEST,
  payload: id
});

export const createWhCategory = (staffGroup) => ({
  type: Types.CREATE_WH_CATEGORY_REQUEST,
  payload: staffGroup
});

export const updateWhCategory = (staffGroup) => ({
  type: Types.UPDATE_WH_CATEGORY_REQUEST,
  payload: staffGroup
});

export const deleteWhCategory = (id) => ({
  type: Types.DELETE_WH_CATEGORY_REQUEST,
  payload: id
});

export const resetWhCategoryState = () => ({
  type: Types.RESET_WH_CATEGORY_STATE
});
