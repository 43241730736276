import { Button, Col, Modal, Row, Table } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import ModalConfirm from '~/components/Common/ModalConfirm';
import {
  WH_COURSE_TRAINING_STATUS,
  WH_COURSE_TRAINING_STATUS_VI
} from '~/constants/defaultValue';
import LogTimeList from './LogTimeList';

export default function WhPartnerTimeTrainingDetails({
  record,
  loadingSubmit,
  updateTimeSheet,
  userId,
  logTimeSheet,
  isLoadingTimeSheet
}) {
  const [note, setNote] = useState(get(record, 'note'));
  const [isOpenModalDeny, setIsOpenModalDeny] = useState(false);
  const [isOpenModalApproved, setIsOpenModalApproved] = useState(false);
  const columns = [
    {
      title: 'STT',
      dataIndex: null,
      key: null,
      render: (text, record, index) => <p>{index + 1}</p>
    },
    {
      title: 'Ngày đào tạo',
      dataIndex: null,
      key: null,
      render: (text, record) =>
        `${moment(get(record, 'startDate')).format('DD/MM/YYYY')} - ${moment(
          get(record, 'endDate')
        ).format('DD/MM/YYYY')}`
    },
    {
      title: 'Thời gian',
      dataIndex: 'totalHours',
      key: 'totalHours',
      render: (text, record) => text
    },
    {
      title: 'Số tiền',
      dataIndex: 'partner',
      key: 'partner',
      render: (text, record) => {
        return !isNaN(
          get(text, 'rateTraining') * parseInt(get(record, 'totalHours'))
        )
          ? (get(text, 'rateTraining') * parseInt(get(record, 'totalHours')))
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : `0 (Chưa có Rate Đào tạo)`;
      }
    }
  ];
  const handleUpdate = (status) => {
    const ids = get(record,'course',[]).filter(e => get(e,'status','') !== WH_COURSE_TRAINING_STATUS.DENIED)
    const submitData = {
      id: record._id,
      note,
      status,
      courseIdFull: get(record, 'course_training.courseIdFull'),
      userId,
      ids
    };
    updateTimeSheet(submitData);
  };
  return (
    <div>
      <ModalConfirm
        textOk="Từ chối"
        type="delete"
        text="Xác nhận từ chối"
        loading={loadingSubmit}
        visible={isOpenModalDeny}
        onCancel={() => setIsOpenModalDeny(false)}
        handleOk={() => handleUpdate(WH_COURSE_TRAINING_STATUS.DENIED)}
      />
      <ModalConfirm
        textOk="Duyệt"
        type="approved"
        text="Xác nhận Duyệt"
        loading={loadingSubmit}
        visible={isOpenModalApproved}
        onCancel={() => setIsOpenModalApproved(false)}
        handleOk={() => handleUpdate(WH_COURSE_TRAINING_STATUS.APPROVED)}
      />
      <Table
        columns={columns}
        dataSource={[record]}
        pagination={false}
        size="small"
      />
      <Row style={{ alignItems: 'center' }}>
        <Col span={2}>
          <h5>Ghi chú : </h5>
        </Col>
        <Col span={22}>
          {get(record, 'status', '') === WH_COURSE_TRAINING_STATUS.APPROVED ||
          get(record, 'status', '') === WH_COURSE_TRAINING_STATUS.DENIED ? (
            <Text strong>{note}</Text>
          ) : (
            <TextArea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              rows={2}
            />
          )}
        </Col>
      </Row>
      {get(record, 'status') === WH_COURSE_TRAINING_STATUS.NEW ? (
        <Row style={{ width: '200px', margin: '20px auto' }}>
          <Col span={12}>
            <Button onClick={() => setIsOpenModalApproved(true)} type="primary">
              Duyệt
            </Button>
          </Col>
          <Col span={12}>
            <Button onClick={() => setIsOpenModalDeny(true)}>Từ chối</Button>
          </Col>
        </Row>
      ) : (
        <Row style={{ width: '200px', margin: '20px auto' }}>
          <span className={`course-status ${get(record, 'status')}`}>
            {WH_COURSE_TRAINING_STATUS_VI[get(record, 'status')]}
          </span>
        </Row>
      )}
      <LogTimeList notes={logTimeSheet} LoadingNote={isLoadingTimeSheet} />
    </div>
  );
}
