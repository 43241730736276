import { CheckCircleOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Table, Tag, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { debounce, get, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import api from '~/api';
import TooltipTitle from '~/components/Common/TooltipTitle';
import InfomationProduct from '~/components/WhAppointment/WhAppointmentsByBlockTable/components/InfomationProduct';
import { TYPE_VOUCHER_WAREHOUSE, TYPE_WAREHOUSE } from '~/constants/defaultValue';
import { useProductListPartnerBorrowed, useProductListPartnerBorrowedPaging, useResetProductListAction } from '~/hooks/productList';
import { ToastrCustom } from '~/hooks/utils';
import { checkErrorInProductConfirm } from '~/hooks/warehouseVoucher';

export default function SearchProductList({ handleSelect, clonedDataSource, typeVoucher, handleCreateByPartner, idPartnerSelected,setForm,supplierId }) {
    // const [query, setQuery] = useState(null); // USE FOR PBG VOUCHER
    const [queryPartner, setQueryPartner] = useState(null); // USE FOR PHT VOUCHER
    const [idViewProduct, setIdViewProduct] = useState(null);
    const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);
    const [loadingSelect, setLoadingSelect] = useState(false);

    // // Effect INIT Query PBG Voucher
    // useEffect(() => {
    //         if (typeVoucher && typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG) {
    //             setQuery({
    //                 page: 1, limit: 5,
    //                 status: WAREHOUSE_STATUS_KEY.READY,
    //                 isGetCurrentBrachId: true
    //             });
    //         }
    // }, [typeVoucher]);

    // Effect Controller Query PHT Voucher
    useEffect(() => {
        if (typeVoucher) {
            if (typeVoucher === TYPE_VOUCHER_WAREHOUSE.PHT) {
                idPartnerSelected && setQueryPartner({ page: 1, limit: 5, id: idPartnerSelected,isCheckCreated : true });
            }
        }
    }, [typeVoucher, idPartnerSelected])
    const handleOpenModalViewProduct = (id) => {
        setIsOpenViewProduct(true);
        setIdViewProduct(id);
    };
    const handleCloseModalViewProduct = () => {
        setIsOpenViewProduct(false);
        setIdViewProduct(null);
    };

    // HOOK GET LIST PRODUCT
    // const [products, isLoading] = useProductLists(typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG && query); // GET WHEN CREATE PBG VOUCHER
    const [productsPartner, isLoadingPartner] = useProductListPartnerBorrowed(typeVoucher === TYPE_VOUCHER_WAREHOUSE.PHT && queryPartner); // GET WHEN CREATE PHT VOUCHER

    // const paging = useProductListPaging();
    const pagingPartner = useProductListPartnerBorrowedPaging();
    useResetProductListAction();

    // HANDLE ONCHANGE SEARCH
    const handleSearchDebounce = debounce((value) => {
        // if (typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG) {
        //     setQuery({ ...query, keyword: value?.trim() })
        // } else {
            setQueryPartner({ ...queryPartner, keyword: value?.trim() })
        // }
    }, 500);

    const removeDuplicate = (list) => uniqBy(list, (e) => get(e, '_id'));

    //  HANDLE SELECT TO LIST VOUCHER
    const onSelect = async (record) => {
        try {
            const {supplier} = record;
            // if (typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG) {
            //     return handleSelect(record, TYPE_WAREHOUSE.WORLDHEALTH);
            // }
            if (typeVoucher === TYPE_VOUCHER_WAREHOUSE.PHT) {
                setLoadingSelect(true);
                const voucher = await api.wareHouseVoucher.getVoucherByIdProduct(get(record, '_id'));
                const r_date = get(voucher, 'createdAt');
                handleSelect({ ...record, r_date }, TYPE_WAREHOUSE.WORLDHEALTH);
                setLoadingSelect(false);
                setForm({
                    supplier : {
                        ...supplier
                    }
                })
            }
        } catch (error) {
            const message = get(error, 'response.data.message', 'Có lỗi gì đó xảy ra');
            ToastrCustom(message, 'error')
            setLoadingSelect(false);
        }

    }
    const columns = [
        {
            title: "Mã thiết bị",
            dataIndex: 'code',
            key: 'code',
            render: (item, record) => handleCreateByPartner
                ? <Typography.Text onClick={() => handleOpenModalViewProduct(get(record, '_id'))}>
                    {item}
                </Typography.Text>
                : <Typography.Link onClick={() => handleOpenModalViewProduct(get(record, '_id'))}>
                    {item}
                </Typography.Link>
        },
        {
            title: "Tên thiết bị",
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Thao tác",
            key: 'action',
            render: (item, record, index) => {
                const { msg, status } = checkErrorInProductConfirm({ ...record, supplierIdSelected: supplierId });
                const isExist = clonedDataSource?.some(e => get(e, 'productId') === get(record, '_id'));
                if (isExist) return <Tag className='tagChecked' icon={<CheckCircleOutlined className='checked' />} color="success">Đã chọn</Tag>
                return   <TooltipTitle
          titleTooltip={msg}
          content={<Button disabled={status} loading={loadingSelect} onClick={() => onSelect(record)}><SendOutlined /></Button>}
        />
                
            }
        },

    ];
    return (
        <div>
            <Search
                allowClear
                style={{ width: '100%' }}
                placeholder="Tìm theo tên, mã thiết bị..."
                onChange={(e) => handleSearchDebounce(e.target.value)}
                enterButton
            />
            <Table
                rowKey={(rc) => rc._id}
                dataSource={removeDuplicate(productsPartner)}
                loading={isLoadingPartner}
                columns={columns}
                onChange={({ current, pageSize }) => {
                    // if (handleCreateByPartner) {
                        setQueryPartner({ ...queryPartner, page: current, limit: pageSize })
                    // } 
                    // else {
                        // setQueryPartner({ ...queryPartner, page: current, limit: pageSize })
                    // }
                }}
                size='small'
                pagination={{
                    ...pagingPartner,
                    showSizeChanger: true
                }}
                locale={{
                    emptyText: <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                            height: 60,
                        }}
                        description={
                            <>
                                <p>Trống</p>
                                <span>
                                    Chưa chọn đối tác hoặc đối tác không còn trang bị để trả/bàn giao
                                </span>
                            </>
                        }
                    >
                    </Empty>
                }}
            />
            <Modal
                destroyOnClose
                title={`Thông tin thiết bị`}
                width={700}
                visible={isOpenViewProduct}
                closable={false}
                footer={null}
                onCancel={handleCloseModalViewProduct}>
                <InfomationProduct id={idViewProduct} />
            </Modal>
        </div>
    )
}
