import * as Types from '../../constants/actionTypes';
  
  export const getListSoftwares = query => ({
    type: Types.GET_LIST_SOFTWARES_REQUEST,
    payload: query
  });
  export const getListSoftware = id => ({
    type: Types.GET_LIST_SOFTWARE_REQUEST,
    payload: id
  });
  export const updateListSoftware = instance => ({
    type: Types.UPDATE_LIST_SOFTWARE_REQUEST,
    payload: instance
  });
  export const createListSoftware = instance => ({
    type: Types.CREATE_LIST_SOFTWARE_REQUEST,
    payload: instance
  });
  export const deleteListSoftware = id => ({
    type: Types.DELETE_LIST_SOFTWARE_REQUEST,
    payload: id
  });
  export const resetListSoftwareState = () => ({
    type: Types.RESET_LIST_SOFTWARE_STATE
  });
