import { DeleteOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import FlexCenter from '../Common/FlexCenter';

export default function TableTarget({ data, onRemove }) {
  const columns = [
    {
      title: 'Câu hỏi',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      render: (_id) => {
        return <Button danger type='primary' onClick={() => onRemove(_id)}><FlexCenter.Row>Gỡ <DeleteOutlined /> </FlexCenter.Row></Button>

      }
    }
  ];
  return (
    <Table columns={columns} size='small' dataSource={data} title={() => "Câu hỏi đã được chọn"} />
  )
}
