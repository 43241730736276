import React, { useState, useMemo } from 'react';
import { usePartnerBranches, useDeleteBranch, useBranchTree, useUpdateBranch, useResetBranch, useResetPartner, useDeleteBranchInPartner, useUpdateBranchStatusPartner, useUpdateBranchStatus } from '~/hooks';
import { Table, Popconfirm, Input, Button, Tag, Switch, Modal } from 'antd';
import { Link, useParams } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import FilterModules from '~/components/Common/FilterModules';
import { searchByName } from '~/utils/helper';
import BranchForm from './BranchForm';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const { Search } = Input;

const ColumnOperation = ({
  _id,
  deleteBranch,
  shouldShowDevider,
  onOpenForm,
  handleUpdate,
  canUpdate,
  ...record
}) => {
  const { action, isPublic, ...rest } = record
  const data = {
    ...rest
  }
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_BRANCH}>
        <p onClick={() => onOpenForm(_id)}>Sửa</p>
      </WithPermission>
      {shouldShowDevider && <p>|</p>}

      <WithPermission permission={POLICY.DELETE_BRANCH}>
        <Popconfirm
          title="Bạn muốn xoá chi nhánh này?"
          onConfirm={() => deleteBranch(_id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
      {canUpdate && <p>|</p>}
      <WithPermission permission={POLICY.UPDATE_BRANCH}>
        <Switch
          checked={record?.action === 'ACTIVE'}
          onChange={(value) =>
          {
            handleUpdate({
              action: value ? 'ACTIVE' : 'INACTIVE',
              _id,
              // ...data
            })}
          }
        />
      </WithPermission>
    </div>
  );
};
const TYPE_GET = {
  BRANCH : "BRANCH",
  CLINIC : "CLINIC",
}
const Branches = ({typeGet = "BRANCH"}) => {
  const { id: partnerId } = useParams();
  const [branches, isLoading] = usePartnerBranches(partnerId,typeGet);
  const branchTree = useBranchTree(branches);
  const [, deleteBranch] = useDeleteBranchInPartner();

  const canUpdate = useMatchPolicy(POLICY.UPDATE_BRANCH);
  const canDelete = useMatchPolicy(POLICY.DELETE_BRANCH);
  const shouldShowDevider = canUpdate && canDelete;
  const [searchText, setSearchText] = useState('');

  const data = useMemo(() => searchByName(branches, searchText), [
    branches,
    searchText
  ]);
  const [branchId, setBranchId] = useState(null);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const onOpenForm = (id) => {
    setBranchId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setBranchId(null);
    setIsOpenForm(false);
  };

  const handleUpdateAction = (data) => {
    updateBranch(data)
  }

  const [, updateBranch] = useUpdateBranchStatus(onCloseForm)
  useResetBranch()
  
  const columns = [
    {
      title: 'Tên chi nhánh',
      key: 'branchName',
      render: (rc) => <Link to={`/branch/detail/${typeGet}/${rc._id}`}>{get(rc, 'name.vi',get(rc, 'name', '') ) }</Link>
    },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      render: (address) => (
        <p>{address && `${address?.street}, ${address?.ward}, ${address?.district}, ${address?.city}`}</p>
      )
    },
  ];

  if(typeGet === "")
  if (canUpdate || canDelete) {
    columns.push({
      title: 'Thao tác',
      key: 'action',
      width: '210px',
      render: (record) => {
        return (
          <ColumnOperation
            {...record}
            deleteBranch={deleteBranch}
            shouldShowDevider={shouldShowDevider}
            onOpenForm={onOpenForm}
            handleUpdate={handleUpdateAction}
            canUpdate ={canUpdate}
          />
        );
      }
    });
  }

  return (
    <div className="">
      <div className="">
        <div className="page-content__main">
          <div className="page-content__left" style={CARD_STYLE}>
            <FilterCity
              // value={query.cityId}
              // onChange={(value) => onParamChange({ cityId: value })}
              isDisabled={isLoading}
            />
            {/* <FilterModules
            // value={query?.modules?.split(',')}
            // onChange={(value) => onParamChange({ modules: value })}
            /> */}
          </div>
          <div className="page-content__right" style={CARD_STYLE}>
            <div className="page-wraper__header">
              <Search
                style={{ width: 300 }}
                placeholder="Tìm chi nhánh"
                enterButton
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
              />

              <WithPermission permission={POLICY.WRITE_BRANCH}>
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={() => onOpenForm(null)}
                >
                  {' '}
                  Thêm mới
                </Button>
              </WithPermission>
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={data?.length} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={branchTree}
                  size="middle"
                  scroll={{ x: 'auto'}}
                pagination={{
                  total: data?.length,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>
      </div>
    
        <BranchForm branchType={typeGet === TYPE_GET.BRANCH ? "PHARMACY" : "CLINIC"} isOpen={isOpenForm} onClose={onCloseForm} id={branchId} handleUpdate = {useUpdateBranchStatus} />
    </div>
  );
};

export default Branches;
