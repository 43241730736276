import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getEvaluates() {
  try {
    const data = yield call(Api.evaluate.getAll);
    yield put({ type: Types.GET_EVALUATES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_EVALUATES_FAILED,
      payload: error.message
    });
  }
}

function* getEvaluate({ payload: id }) {
  try {
    const evaluate = yield call(Api.evaluate.getById, id);
    
    yield put({ type: Types.GET_EVALUATE_SUCCESS, payload: evaluate });
  } catch (error) {
    yield put({ type: Types.GET_EVALUATE_FAILED, payload: error.message });
  }
}

function* createEvaluate(action) {
  try {
    const data = yield call(Api.evaluate.create, action.payload);
    yield put({ type: Types.CREATE_EVALUATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_EVALUATE_FAILED,
      payload: error.message
    });
  }
}

function* updateEvaluate(action) {
  try {
    const data = yield call(Api.evaluate.update, action.payload);
    yield put({ type: Types.UPDATE_EVALUATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_EVALUATE_FAILED,
      payload: error.message
    });
  }
}

function* deleteEvaluate({ payload }) {
  try {
    yield call(Api.evaluate.delete, payload);
    yield put({ type: Types.DELETE_EVALUATE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_EVALUATE_FAILED,
      payload: error.message
    });
  }
}

export default function* evaluate() {
  yield takeLatest(Types.GET_EVALUATES_REQUEST, getEvaluates);
  yield takeLatest(Types.GET_EVALUATE_REQUEST, getEvaluate);
  yield takeLatest(Types.DELETE_EVALUATE_REQUEST, deleteEvaluate);
  yield takeLatest(Types.CREATE_EVALUATE_REQUEST, createEvaluate);
  yield takeLatest(Types.UPDATE_EVALUATE_REQUEST, updateEvaluate);
}
