import requester from './requester';
export const dataHighlight = {
  v1: [
      // { keyId:'v1-1' , size: 60, rotate: 30, shortTitle: 'toa lao', title:'', detail : '' , backgroundCircle: '#3A7CFF', backgroundTitle: '' },
      // { keyId:'v1-2' , size: 60, rotate: 90, shortTitle: 'toa lao', title:'', detail : '' , backgroundCircle: '#3A7CFF', backgroundTitle: '' },
      // { keyId:'v1-3' , size: 60, rotate: 200, shortTitle: 'toa lao', title:'', detail : '' , backgroundCircle: '#3A7CFF', backgroundTitle: '' },
      // { keyId:'v1-4' , size: 60, rotate: 260, shortTitle: 'toa lao', title:'', detail : '' , backgroundCircle: '#3A7CFF', backgroundTitle: '' },
  ],
  v2: [
      // {keyId: 'v2-1', size: 80, rotate: 0, shortTitle: 'toa lao', title:'', detail : '' , backgroundCircle: '#3A7CFF', backgroundTitle: '' },
      // {keyId: 'v2-2', size: 80, rotate: 100, shortTitle: 'toa lao', title:'', detail : '' , backgroundCircle: '#3A7CFF', backgroundTitle: '' },
      // {keyId: 'v2-3', size: 80, rotate: 165, shortTitle: 'toa lao', title:'', detail : '' , backgroundCircle: '#3A7CFF', backgroundTitle: '' },
  ],
  v3: [
      // {keyId:'v3-1', size: 100, rotate: 0, shortTitle: 'toa lao', title:'', detail : '' , backgroundCircle: '#3A7CFF', backgroundTitle: '' },
      // {keyId:'v3-2', size: 100, rotate: 50, shortTitle: 'toa lao', title:'', detail : '' , backgroundCircle: '#3A7CFF', backgroundTitle: '' },
      // {keyId:'v3-3', size: 100, rotate: 100, shortTitle: 'toa lao', title:'', detail : '' , backgroundCircle: '#3A7CFF', backgroundTitle: '' },
  ],
  v0:{
    key:'v0',
    shortTitle:'',
    title : '',
    detail : '',
  },
  vSetting:{
    v1: {
        size:242,
        duration:0,
    },
    v2: {
        size:242,
        duration:0,
    },
    v3: {
        size:242,
        duration:0,
    },
  }
}

const promise_ = new Promise((result,resolve)=>{
      setTimeout(()=>{
       return result({...dataHighlight})
      },500)
})
const requesterDemo = {
  get: async ()=>{
    return await promise_
  },
  post: async ()=>{

  },
  delete: async ()=>{

  },
}



const highlight = {
  getAll: (highlight) => requester.get(`/highlight`, highlight),
  // delete: (id) => requester.delete(`/highlight/${id}`),
  create: (highlight) => requester.post(`/highlight`, highlight),
  update: (highlight) => requester.put(`/highlight/update`, highlight)
};

export default highlight;
