import * as Types from '../../constants/actionTypes';
  
  export const getPointSystems = query => ({
    type: Types.GET_POINT_SYSTEMS_REQUEST,
    payload: query
  });
  export const getPointSystemsPartner = query => ({
    type: Types.GET_POINT_SYSTEMS_PARTNER_REQUEST,
    payload: query
  });
  export const getPointSystem = id => ({
    type: Types.GET_POINT_SYSTEM_REQUEST,
    payload: id
  });
  export const createPointSystem = instance => ({
    type: Types.CREATE_POINT_SYSTEM_REQUEST,
    payload: instance
  });
  export const updatePointSystem = instance => ({
    type: Types.UPDATE_POINT_SYSTEM_REQUEST,
    payload: instance
  });
  export const deletePointSystem = id => ({
    type: Types.DELETE_POINT_SYSTEM_REQUEST,
    payload: id
  });
  export const resetPointSystemState = () => ({
    type: Types.RESET_POINT_SYSTEM_STATE
  });