import React, { useEffect, useMemo, useState } from 'react';

import {
  Card,
  Col,
  Form,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
  notification,
  Popover,
  Button,
  Checkbox
} from 'antd';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import _, { get, findIndex, pick, head } from 'lodash';

import { filterAcrossAccents } from '~/hooks/utils';
// import { useWhPackageLevels } from '~/hooks/whPackageLevel';

import WhServiceSessionEdit from './WhServiceSessionEdit';
import WhServiceMediaEdit from './WhServiceMediaEdit';

import './index.scss';
import { WH_SERVICE_TYPESHOW, WH_SERVICE_TYPESHOW_VI } from '~/constants/defaultValue';
import WhMedicalEquipments from './MedicalEquipment/WhMedicalEquipments';
import TabsMedicalEquipments from './MedicalEquipment';

const { Option } = Select;
const FormItem = Form.Item;

const WhServicePackageLevelEdit = ({
  fieldKey,
  form,
  id, // = the whServiceId
  index,
  isLoading,
  isWhPackageLevelsLoading,
  isWhSessionsOfDayLoading,
  language,
  name,
  remove,
  whPackageLevels,
  whSessionsOfDay,
  setWhServicMediaImg,
  whMedicalEquipments,
  setWhMedicalEquipment,
  setWhGroupMedicalEquipment,
  initWhService
}) => {
  // const [whPackageLevels, isWhPackageLevelsLoading] = useWhPackageLevels();
  const _props = {
    fieldKey,
    form,
    index,
    isLoading,
    language,
    name,
    whPackageLevels,
  }
  const [selectedSessionOfDay, setSelectedSessionOfDay] = useState();
  const [selectedSessionOfDayIdx, setSelectedSessionOfDayIdx] = useState();
  const [copyFromValue, setCopyFromValue] = useState(null);
  const [checked, setChecked] = useState(false);
  const onChangeDisplaySession = (e) => {
    setChecked(e.target.checked);
  };
 
  useEffect(() => {
    const idx = findIndex(whSessionsOfDay, (item) => {
      return item.id === selectedSessionOfDay;
    });
    if (idx !== -1) setSelectedSessionOfDayIdx(idx);
  }, [selectedSessionOfDay, whSessionsOfDay]);

  useEffect(() => {
    if (whSessionsOfDay && whSessionsOfDay.length > 0) {
      setSelectedSessionOfDay(whSessionsOfDay[0].id);
    }
  }, [whSessionsOfDay]);

  useEffect(() => {
    if (copyFromValue) {
      setCopyFromValue(null);
    }
  }, [copyFromValue]);

  const onSessionOfDayStatusChange = (e) => form.validateFields();

  const onCopyFromChange = (value, option) => {
    form.setFieldsValue({
      packages: form.getFieldValue('packages').map((packageLevel, pIdx) => ({
        ...packageLevel,
        sessionPrices: packageLevel.sessionPrices.map((session, sIdx) => {
          if (index === pIdx && selectedSessionOfDayIdx === sIdx) {
            const sessionPrices = form.getFieldValue([
              'packages',
              index,
              'sessionPrices'
            ]);
            const source = sessionPrices.find(
              (item) => get(item, 'whSessionOfDayId') === value
            );
            if (source) {
              const dataToCopy = {
                ...source,
                ...pick(session, ['_id', 'whSessionOfDayId', 'whSessionOfDay'])
              };
              return dataToCopy;
            }
            notification.error({
              message: 'Lỗi',
              description: 'Không thể copy dữ liệu'
            });
          }
          return session;
        })
      }))
    });
  };

  return (
    <div>
      <Row gutter={48} align="top" justify="space-between">
        <Col span={8}>
          <FormItem shouldUpdate>
            {() => (
              <FormItem
                label=""
                // name={['whCategoryId']}
                name={[name, 'whPackageLevelId']}
                fieldKey={[fieldKey, 'whPackageLevelId']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn gói dịch vụ'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  // business logic: first package level can not be deactivate
                  <Select
                    // onChange={setSelectedCityCode}
                    autoComplete="off"
                    disabled={
                      // index === 0 ||
                      isWhPackageLevelsLoading ||
                      form.getFieldValue(['packages', index, '_id'])
                    }
                    filterOption={filterAcrossAccents}
                    loading={isWhPackageLevelsLoading}
                    placeholder="Gói dịch vụ"
                      showSearch
                  >
                    {whPackageLevels
                      .filter(
                        ({ id }) =>
                          !form
                            .getFieldValue('packages')
                            ?.slice(0, index)
                            .map((item) => item.whPackageLevelId)
                            .includes(id)
                      )
                      .map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {get(name, language)}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            )}
          </FormItem>
        </Col>
        <Col span={8}>
        
        </Col>
        <Col span={8}>
          <Row justify="end">
            <FormItem label="" name={[name, 'status']} valuePropName="checked">
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                // business logic: first package level can not be deactivate
                <Switch disabled={index === 0} />
              )}
            </FormItem>
          </Row>
        </Col>
      </Row>
      <Card className="card">
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row gutter={48} align={index === 0 && 'middle'}>
            <Col sm={8} lg={8}>
              {/* <FormItem
              label=""
              name={['whCategoryId']}
              rules={[
                { required: true, message: 'Xin vui lòng chọn thời gian trong ngày' },
              ]}
            > */}
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  // disabled={isWhCategoriesLoading}
                  // loading={isWhCategoriesLoading}
                  value={selectedSessionOfDay}
                  autoComplete="off"
                  filterOption={filterAcrossAccents}
                  onChange={setSelectedSessionOfDay}
                  placeholder="Thời gian trong ngày"
                  showSearch
                  style={{ width: '100%' }}
                >
                  {/* {form.getFieldValue(["packages", index, "sessionPrices"])?.map(({ _id, whSessionOfDay }) => (
                    <Option key={_id} value={_id}>
                      {get(whSessionOfDay, 'name')}
                    </Option>
                  ))} */}
                  {whSessionsOfDay?.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {get(name, 'vi')}
                    </Option>
                  ))}
                </Select>
              )}
              {/* </FormItem> */}
            </Col>
            <Col sm={8} lg={8}>
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Popover
                  content={
                    <Select
                      autoComplete="off"
                      filterOption={filterAcrossAccents}
                      onChange={onCopyFromChange}
                      placeholder="Copy dữ liệu từ..."
                      style={{ width: '100%' }}
                      value={copyFromValue}
                    >
                      {whSessionsOfDay?.map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {get(name, 'vi')}
                        </Option>
                      ))}
                    </Select>
                  }
                  placement="rightBottom"
                >
                  <CopyOutlined style={{ color: '#1890FF' }} />
                </Popover>
              )}
            </Col>

            <Col sm={8} lg={8}>
              <Row justify="end">
                <FormItem
                  label=""
                  name={[
                    name,
                    'sessionPrices',
                    selectedSessionOfDayIdx,
                    'status'
                  ]}
                  valuePropName="checked"
                >
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <Switch onChange={onSessionOfDayStatusChange} />
                  )}
                </FormItem>
              </Row>
            </Col>
            <Col sm={24} lg={8}>
              <FormItem
                labelAlign="left"
                label="Hiển thị"
                name={[
                  name,
                  'sessionPrices',
                  selectedSessionOfDayIdx,
                  'displaySession'
                ]}
                labelCol={{ sm: 24, md: 24, lg: 16 }}
                wrapperCol={{ sm: 24, md: 24, lg: 8 }}
                valuePropName="checked"
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Checkbox
                    checked={checked}
                    onChange={onChangeDisplaySession}
                  />
                )}
              </FormItem>
            </Col>
            {/* {index !== 0 && <Col span={2}>
            <MinusCircleOutlined
              onClick={() => remove(name)}
              className={index === 0 && 'top-5'}
            />
          </Col>} */}
          </Row>
          <Form.List name={[name, 'sessionPrices']}>
            {(fields, { add, remove }) => (
              <>
                {/* <Space direction="vertical" size="middle" style={{ width: "100%" }}> */}
                {fields.map(
                  (
                    { key, name, fieldKey, whSessionOfDayId, ...restField },
                    sessionIndex
                  ) => {
                    const props = {
                      form,
                      index,
                      isLoading,
                      language,
                      name,
                      sessionIndex
                    };
                    return (
                      <WhServiceSessionEdit
                        key={key}
                        className={`${
                          selectedSessionOfDay !==
                            form.getFieldValue([
                              'packages',
                              index,
                              'sessionPrices',
                              sessionIndex,
                              'whSessionOfDayId'
                            ]) && 'hiden'
                        }`}
                        {...props}
                      />
                    );
                  }
                )}
                {/* </Space> */}
              </>
            )}
          </Form.List>
          <Row gutter={[48, 0]} justify="flex-start">
            <Form.List name={[name, 'media']}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, mediaIdx) => {
                      const props = {
                        fieldKey,
                        form,
                        index,
                        key,
                        language,
                        name,
                        mediaIdx,
                        remove,
                        setWhServicMediaImg
                      };
                      return <WhServiceMediaEdit key={key} {...props} />;
                    }
                  )}
                  {fields.length >= 100 ? null : (
                    <Col span={6}>
                      <Button
                        className="wh-service-form__question--btn-add-option"
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Thêm ảnh
                      </Button>
                    </Col>
                  )}
                </>
              )}
            </Form.List>
          </Row>
            <Row>
                  <Space direction="vertical" size={64} style={{ width: '100%' }}>
              {/* <WhMedicalEquipments
                      whMedicalEquipments = {whMedicalEquipments}
                      setWhMedicalEquipment={setWhMedicalEquipment}
                      setWhGroupMedicalEquipment={setWhGroupMedicalEquipment}
                      props={{ ..._props }}
                      initWhService = {initWhService}
                    /> */}
                    <TabsMedicalEquipments
                      whMedicalEquipments = {whMedicalEquipments}
                      setWhMedicalEquipment={setWhMedicalEquipment}
                      setWhGroupMedicalEquipment={setWhGroupMedicalEquipment}
                      props={{ ..._props }}
                      initWhService={initWhService}
                
                    />
            </Space>
            </Row>
        </Space>
      </Card>
    </div>
  );
};

export default WhServicePackageLevelEdit;
