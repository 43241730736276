export const GET_JOB_FORMS_REQUEST =
    'GET_JOB_FORMS_REQUEST';
export const GET_JOB_FORMS_SUCCESS =
    'GET_JOB_FORMS_SUCCESS';
export const GET_JOB_FORMS_FAILED = 'GET_JOB_FORMS_FAILED';

export const GET_JOB_FORM_REQUEST = 'GET_JOB_FORM_REQUEST';
export const GET_JOB_FORM_SUCCESS = 'GET_JOB_FORM_SUCCESS';
export const GET_JOB_FORM_FAILED = 'GET_JOB_FORM_FAILED';

export const CREATE_JOB_FORM_REQUEST =
    'CREATE_JOB_FORM_REQUEST';
export const CREATE_JOB_FORM_SUCCESS =
    'CREATE_JOB_FORM_SUCCESS';
export const CREATE_JOB_FORM_FAILED =
    'CREATE_JOB_FORM_FAILED';

export const UPDATE_JOB_FORM_REQUEST =
    'UPDATE_JOB_FORM_REQUEST';
export const UPDATE_JOB_FORM_SUCCESS =
    'UPDATE_JOB_FORM_SUCCESS';
export const UPDATE_JOB_FORM_FAILED =
    'UPDATE_JOB_FORM_FAILED';


export const DELETE_JOB_FORM_REQUEST =
    'DELETE_JOB_FORM_REQUEST';
export const DELETE_JOB_FORM_SUCCESS =
    'DELETE_JOB_FORM_SUCCESS';
export const DELETE_JOB_FORM_FAILED =
    'DELETE_JOB_FORM_FAILED';


export const RESET_JOB_FORM_STATE = 'RESET_JOB_FORM_STATE';
export const RESET_JOB_FORM_STATE_ACTION = 'RESET_JOB_FORM_STATE_ACTION';
