export const GET_DELIVERY_VOUCHERS_REQUEST = 'GET_DELIVERY_VOUCHERS_REQUEST';
export const GET_DELIVERY_VOUCHERS_SUCCESS = 'GET_DELIVERY_VOUCHERS_SUCCESS';
export const GET_DELIVERY_VOUCHERS_FAILED = 'GET_DELIVERY_VOUCHERS_FAILED';

export const GET_DELIVERY_VOUCHER_REQUEST = 'GET_DELIVERY_VOUCHER_REQUEST';
export const GET_DELIVERY_VOUCHER_SUCCESS = 'GET_DELIVERY_VOUCHER_SUCCESS';
export const GET_DELIVERY_VOUCHER_FAILED = 'GET_DELIVERY_VOUCHER_FAILED';

export const CREATE_DELIVERY_VOUCHER_REQUEST = 'CREATE_DELIVERY_VOUCHER_REQUEST';
export const CREATE_DELIVERY_VOUCHER_SUCCESS = 'CREATE_DELIVERY_VOUCHER_SUCCESS';
export const CREATE_DELIVERY_VOUCHER_FAILED = 'CREATE_DELIVERY_VOUCHER_FAILED';

export const UPDATE_DELIVERY_VOUCHER_REQUEST = 'UPDATE_DELIVERY_VOUCHER_REQUEST';
export const UPDATE_DELIVERY_VOUCHER_SUCCESS = 'UPDATE_DELIVERY_VOUCHER_SUCCESS';
export const UPDATE_DELIVERY_VOUCHER_FAILED = 'UPDATE_DELIVERY_VOUCHER_FAILED';

export const DELETE_DELIVERY_VOUCHER_REQUEST = 'DELETE_DELIVERY_VOUCHER_REQUEST';
export const DELETE_DELIVERY_VOUCHER_SUCCESS = 'DELETE_DELIVERY_VOUCHER_SUCCESS';
export const DELETE_DELIVERY_VOUCHER_FAILED = 'DELETE_DELIVERY_VOUCHER_FAILED';

export const RESET_DELIVERY_VOUCHER_STATE = 'RESET_DELIVERY_VOUCHER_STATE';
