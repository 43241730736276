import { useMemo } from 'react';
import {
  useFailed,
  useFetch,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  useQueryParams
} from '~/hooks/utils';

import {
  getStaffGroups,
  createStaffGroup,
  deleteStaffGroup,
  getStaffGroup,
  resetStaffGroupState,
  updateStaffGroup
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { PATH_APP } from '~/routes/paths';
import { useSelector } from 'react-redux';
import { getExistProp } from '~/utils/helper';
import { get } from 'lodash';

const getSelector = key => state => state.staffGroup[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
export const useStaffGroupQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const deleteStaffGroupSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    deleteStaffGroupSuccess,
  ]);
};
export const useStaffGroups = () => {
  return useFetch({
    action: getStaffGroups,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector
  });
};
export const useUpdateStaffGroupParams = (query) => {
  const history = useHistory();
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/staff-group',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [{ onParamChange }];
};

export const useStaffGroupsByParam = (query) => { // add new for page staffGroup
  return useFetchByParam({
    action: getStaffGroups,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};
export const useCreateStaffGroup = (callback) => {
  // const history = useHistory();
  // const callback = () => history.push({
  //   pathname : PATH_APP.config.staffGroup
  // });

  useSuccess(
    createSuccessSelector,
    'Tạo mới nhóm nhân viên thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createStaffGroup
  });
};

export const useUpdateStaffGroup = (callback) => {
  // const history = useHistory();
  // const callback = () => {
  //   history.push({
  //     pathname : PATH_APP.config.staffGroup
  //   })
  // }
  useSuccess(updateSuccessSelector, 'Cập nhật nhóm nhân viên thành công',callback);
  useFailed(updateFailedSelector, 'Cập nhật nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateStaffGroup
  });
};

export const useDeleteStaffGroup = () => {
  useSuccess(deleteSuccessSelector, 'Xoá nhóm nhân viên thành công');
  useFailed(deleteFailedSelector, 'Xoá nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteStaffGroup
  });
};

export const useStaffGroup = id => {
  return useFetchByParam({
    action: getStaffGroup,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitStaffGroup = staffGroup =>
  useMemo(() => {
    if (!staffGroup) {
      return {};
    }

    return staffGroup;
  }, [staffGroup]);

export const useResetStaffGroup = () => {
  useResetState(resetStaffGroupState);
};
