import React from 'react';

import moment from 'moment';
import { Button, Col, Form, Modal, Row, Switch, Table, Tag, TimePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { get } from 'lodash';

import { COMPONENT_MODES, LANGUAGE, WEEKDAYS } from '~/constants/defaultValue';

const FormItem = Form.Item;

const WorkingDaysTable = ({
  dataSource,
  form,
  initSession,
  isOpenModal,
  mode,
  sessionForm,
  sessionIdx,
  setDayIdx,
  setIsOpenModal,
  setSessionIdx,
  setTime,
}) => {
  const onKeyDownTime = (e,key) => {
    const {value} = e.target
    const time = document.getElementById(key)
    if(e.keyCode !== 8 && value.length === 2){
      time.value = value+":"
    } 
  }
  const SessionTag = ({ session, onClick }) => (
    <Tag
      color="blue"
      onClick={onClick}
      style={{ display: "flex", alignItems: "center" }}
    >
      {`${get(session, "startTime")} - ${get(session, "endTime")}`}
    </Tag>
  )

  const SessionTagView = ({ session }) => (
    <SessionTag session={session} onClick={() => {}} />
  )

  const SessionTagEdit = ({
    dayIdx,
    sessionIdx,
  }) => (
    <Form.Item key={sessionIdx} shouldUpdate noStyle>
      {() => {
        const session = form.getFieldValue(["availableTime", dayIdx, "sessionsOfDay", sessionIdx]);
        return (
          <SessionTag session={session} onClick={() => editSession(dayIdx, sessionIdx, session)} />
        )
      }}
    </Form.Item>
  )

  const WorkingDaysTableRow = ({ dayIdx, text, record }) => {
    return (
      <Row justify='space-between' align='middle'>
        <Col span={22}>
          <Row>
            {
              {
                [COMPONENT_MODES.VIEW]: (
                  get(record, "sessionsOfDay")?.map((item, index) => (
                    <SessionTagView key={index} session={item} />
                  ))
                ),

                [COMPONENT_MODES.EDIT]: (
                  <Form.List name={["availableTime", dayIdx, "sessionsOfDay"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restFields }, sessionIdx) => {
                          return <SessionTagEdit key={sessionIdx} dayIdx={dayIdx} sessionIdx={sessionIdx} />
                        })}
                        <Button
                          // className='wh-service-form__question--btn-add-option'
                          type="dashed"
                          onClick={() => addSession(dayIdx)}
                          icon={<PlusOutlined />}
                        />
                      </>
                    )}
                  </Form.List>
                )
              }[mode]
            }
          </Row>
        </Col>

        <Col span={2}>
          {
            {
              [COMPONENT_MODES.VIEW]: (
                <Switch disabled checked={!!get(record, "status")} />
              ),

              [COMPONENT_MODES.EDIT]: (
                <FormItem name={["availableTime", dayIdx, "status"]} valuePropName="checked">
                  <Switch />
                </FormItem>
              )
            }[mode]
          }
        </Col>
      </Row>
    )
  }

  const columns = [
    {
      title: 'Buổi',
      dataIndex: 'name',
      key: 'name',
      width: '100px',
      render: (text, record, index) => get(WEEKDAYS[index], "name.vi"),
    },
  
    {
      title: 'Thời gian',
      key: 'sessionsOfDay',
      render: (text, record, index) => (
        <WorkingDaysTableRow
          dayIdx={index}
          text={text}
          record={record}
        />
      )
    }
  ];

  /**
   * Add a new session to given day.
   *
   * @param {Number} dayIdx
   */
  const addSession = dayIdx => {
    sessionForm.setFieldsValue({ startTime: null, endTime: null });
    setDayIdx(dayIdx);
    setSessionIdx(-1);
    setIsOpenModal(true);
  }

  /**
   * Update the given session on given day with provided data.
   *
   * @param {Number} dayIdx
   * @param {Number} sessionIdx
   * @param {Object} session
   */
  const editSession = (dayIdx, sessionIdx, session) => {
    // if using <TimePicker /> instead of <Input />, session must be converted to Moment object.
    // sessionForm.setFieldsValue({ ...session });
    sessionForm.setFieldsValue({
      startTime: moment(get(session, "startTime"), "HH:mm"),
      endTime: moment(get(session, "endTime"), "HH:mm"),
    });

    setDayIdx(dayIdx);
    setSessionIdx(sessionIdx);
    setIsOpenModal(true);
  }

  const onOk = async () => {
    try {
      await sessionForm.validateFields();
      setTime();
      setIsOpenModal(false);
    } catch (error) {
      console.error(error);
    }
  }

  const onRemove = () => {
    setTime({ remove: true });
    setIsOpenModal(false);
  }

  const onCancel = () => {
    setIsOpenModal(false);
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={mode === COMPONENT_MODES.EDIT
          ? form.getFieldValue(["availableTime"])
          : dataSource
        }
        pagination={{ position: ["none", "none"] }}
        // rowKey={(rc) => rc._id}
        rowKey={(rc) => Math.random()}
        size="small"
      />
      <Modal
        visible={isOpenModal}
        onCancel={onCancel}
        onOk={onOk}
        closable
        destroyOnClose
        footer={[
          <Button onClick={onCancel}>
            Cancel
          </Button>,
          sessionIdx !== -1 && <Button danger onClick={onRemove}>
            Remove
          </Button>,
          <Button type="primary" onClick={onOk}>
            Ok
          </Button>,
        ]}
      >
        <Form
          form={sessionForm}
          initialValues={initSession}
          labelCol={{ sm: 24, md: 6 }}
          wrapperCol={{ sm: 24, md: 24 }}
        >
          <Row>
            <Col sm={22} md={22}>
              <FormItem
                name={"startTime"}
                label="Bắt đầu"
                rules={[
                  // rule if using <Input />
                  // {
                  //     required: true,
                  //     pattern: new RegExp("^([0-1][0-9]|2[0-3]):[0-5][0-9]$"),
                  //     message: 'Vui lòng nhập thời gian bắt đầu. Ví dụ: 07:15',
                  // },
                ]}
              >
                {/* <Input /> */}
                <TimePicker onKeyDown={(e) => onKeyDownTime(e,'startTime')} format="HH:mm" />
              </FormItem>

              <FormItem
                name={"endTime"}
                label="Kết thúc"
                dependencies={['startTime']}
                rules={[
                  // rule if using <Input />
                  // {
                  //     required: true,
                  //     pattern: new RegExp("^([0-1][0-9]|2[0-3]):[0-5][0-9]$"),
                  //     message: 'Vui lòng nhập thời gian kết thúc. Ví dụ: 08:45',
                  // },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        value &&
                        value > getFieldValue('startTime') &&
                        moment.duration(value.diff(getFieldValue('startTime'))).asMinutes() >= 119
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Thời gian kết thúc phải lớn hơn thời gian bắt đầu ít nhất 120 phút'));
                    }
                  })
                ]}
              >
                {/* <Input /> */}
                <TimePicker onKeyDown={(e) => onKeyDownTime(e,'endTime')} format="HH:mm" />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default WorkingDaysTable;
