import * as Types from '../../constants/actionTypes';

export const getHighlights = (query) => ({
  type: Types.GET_WH_HIGHLIGHT_REQUEST,
  payload: query
});

export const createHighlight = (company) => ({
  type: Types.CREATE_WH_HIGHLIGHT_REQUEST,
  payload: company
});

export const updateHighlight = (company) => ({
  type: Types.UPDATE_WH_HIGHLIGHT_REQUEST,
  payload: company
});
export const updateHighlightReduce = (company) => ({
  type: Types.UPDATE_WH_HIGHLIGHT_SUCCESS,
  payload: company
});

export const deleteHighlight = (id) => ({
  type: Types.DELETE_WH_HIGHLIGHT_REQUEST,
  payload: id
});

export const setDataHighlight = (data) => ({
  type: Types.SET_WH_HIGHLIGHT,
  payload: data
});


