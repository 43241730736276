export const GET_LOG_COURSE_TRAININGS_REQUEST = 'GET_LOG_COURSE_TRAININGS_REQUEST';
export const GET_LOG_COURSE_TRAININGS_SUCCESS = 'GET_LOG_COURSE_TRAININGS_SUCCESS';
export const GET_LOG_COURSE_TRAININGS_FAILED = 'GET_LOG_COURSE_TRAININGS_FAILED';


export const CREATE_LOG_COURSE_TRAINING_REQUEST = 'CREATE_LOG_COURSE_TRAINING_REQUEST';
export const CREATE_LOG_COURSE_TRAINING_SUCCESS = 'CREATE_LOG_COURSE_TRAINING_SUCCESS';
export const CREATE_LOG_COURSE_TRAINING_FAILED = 'CREATE_LOG_COURSE_TRAINING_FAILED';

export const RESET_LOG_COURSE_TRAINING_STATE = 'RESET_LOG_COURSE_TRAINING_STATE';
