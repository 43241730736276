import { Button, Form, Input, Row, Select, Skeleton } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useCities } from '~/hooks';
import { filterAcrossAccents } from '~/hooks/utils';

const { Option } = Select;
const FormItem = Form.Item;

const AssignLocationForm = ({ id, onClose, handleCreate, isSubmitLoading}) => {
  const [form] = Form.useForm();
  const [cities, isCitiesLoading] = useCities();
  useEffect(() => {
    if (!id) {
      form.resetFields();
    } else {
      form.setFieldsValue({ serviceId: id });
    }
  });
  
  const onFinish = useCallback(
    (values) => {
      const locationByService = {
        ...values,
        serviceId: id
      };

      handleCreate({ ...locationByService });
      onClose();
      form.resetFields();
    },
    [handleCreate, id, form]
  );
  const renderInput = (InputComponent) =>
    isCitiesLoading ? <Skeleton.Input active /> : InputComponent;
  return (
    <div className="position page-wraper hospital-form-page-content">
      <h4 style={{ margin: '20px 0 40px 20px' }}>
        Thêm tỉnh/ thành phố ở trong dịch vụ
      </h4>
      <div className="container-fluid"></div>

      <Form
        form={form}
        scrollToFirstError
        initialValues={{ status: 'ACTIVE' }}
        requiredMark={false}
        labelCol={{ sm: 24, md: 24, lg: 4 }}
        wrapperCol={{ sm: 24, md: 24, lg: 20 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <FormItem
          label="Dịch vụ"
          name={"serviceId"}
          hidden
        >
          {renderInput(<Input disabled />)}
        </FormItem>
        <FormItem
          label="Thành Phố/Tỉnh"
          name="locationIndexes"
          rules={[
            {
              required: true,
              message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
            }
          ]}
        >
          {isCitiesLoading ? (
            <Skeleton.Input active />
          ) : (
            <Select
              allowClear
              // onChange={(value) => setSelectedCityCode(value)}
              mode='multiple'
              loading={isCitiesLoading}
              showSearch
              autoComplete="off"
              filterOption={filterAcrossAccents}
            >
              {cities.map(({ code, name }) => (
                <Option key={code} value={code}>
                  {name}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>
        <Row className="coupon-form__submit-box">
          {isSubmitLoading ? (
            <Button disabled>Huỷ</Button>
          ) : (
            <Button onClick={onClose}>Huỷ</Button>
          )}

          <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
            {id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AssignLocationForm;
