import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  getUiClients,
  resetUiClientState,
  updateUiClient
} from '~/redux/action';
const getSelector = (key) => (state) => state.uiClient[key];

const loadingSelector = getSelector('isLoading');
const getUiClientsFailedSelector = getSelector('getUiClientsFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const UiClientsSelector = getSelector('UiClients');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
export const useUiClientQueryParams = () => {
    const query = useQueryParams();
    const key = query.get('key');
    const updateUiClientSuccess = useSelector(updateSuccessSelector);

    return useMemo(() => {
        const queryParams = {
            key,
        };
        return [queryParams];
        //eslint-disable-next-line
    }, [
        key,
        updateUiClientSuccess,
    ]);
};


export const useUiClients = (query) => {
    return useFetchByParam({
        action: getUiClients,
        loadingSelector,
        dataSelector: UiClientsSelector,
        failedSelector: getUiClientsFailedSelector,
        param: query
    });
};

export const useUpdateUiClient = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhât thành công', callback);
    useFailed(updateFailedSelector);

    return useSubmit({
        loadingSelector: isSubmitLoadingSelector,
        action: updateUiClient
    });
};

export const useResetUiClient = () => {
    useResetState(resetUiClientState);
};
