import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getCompanyJobs({ payload: query }) {
    try {
      const response = yield call(Api.companyJob.getAll, query);
      yield put({ type: Types.GET_COMPANY_JOBS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_COMPANY_JOBS_FAILED, payload: error });
    }
  }
function* getCompanyJob({ payload: id }) {
    try {
      const response = yield call(Api.companyJob.getById, id);
      yield put({ type: Types.GET_COMPANY_JOB_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_COMPANY_JOB_FAILED, payload: error });
    }
  }
  function* updateCompanyJob(action) {
    try {
      const data = yield call(Api.companyJob.update, action.payload);
      yield put({ type: Types.UPDATE_COMPANY_JOB_SUCCESS, payload: data });
      yield put({ type: Types.RESET_COMPANY_JOB_ACTION});
    } catch (error) {
      yield put({ type: Types.UPDATE_COMPANY_JOB_FAILED, payload: error });
    }
  }
  function* createCompanyJob(action) {
    try {
      const data = yield call(Api.companyJob.create, action.payload);
      yield put({ type: Types.CREATE_COMPANY_JOB_SUCCESS, payload: data });
      yield put({ type: Types.RESET_COMPANY_JOB_ACTION});
    } catch (error) {
      yield put({ type: Types.CREATE_COMPANY_JOB_FAILED, payload: error });
    }
  }
  function* deleteCompanyJob(action) {
    try {
      const data = yield call(Api.companyJob.delete, action.payload);
      yield put({ type: Types.DELETE_COMPANY_JOB_SUCCESS, payload: data });
      yield put({ type: Types.RESET_COMPANY_JOB_ACTION});
    } catch (error) {
      yield put({ type: Types.DELETE_COMPANY_JOB_FAILED, payload: error });
    }
  }
export default function* CompanyJobs() {
    yield takeLatest(Types.GET_COMPANY_JOBS_REQUEST, getCompanyJobs);
    yield takeLatest(Types.GET_COMPANY_JOB_REQUEST, getCompanyJob);
    yield takeLatest(Types.UPDATE_COMPANY_JOB_REQUEST, updateCompanyJob);
    yield takeLatest(Types.CREATE_COMPANY_JOB_REQUEST, createCompanyJob);
    yield takeLatest(Types.DELETE_COMPANY_JOB_REQUEST, deleteCompanyJob);
  }
