import produce from 'immer';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getTipsPartnersFailed: null,
  getTipsPartnerFailed:null,
  tipsPartners: [],
  tipsPartner: null,
  isSubmitLoading : false,
  isGetTipsPartnerLoading: false,
  TipsPartner: null,

  logsTipsPartners: [],
  getLogsTipsPartnersFailed: null,
  isLoadingLogs: false,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_TIPS_PARTNERS_REQUEST:
      state.isLoading = true;
      state.getTipsPartnersFailed = null;
      return;

    case Types.GET_TIPS_PARTNERS_SUCCESS:
      state.isLoading = false;
      state.tipsPartners = payload.docs;
      state.getTipsPartnersFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_TIPS_PARTNERS_FAILED:
      state.isLoading = false;
      state.tipsPartners = [];
      state.getTipsPartnersFailed = payload;
      return;
    case Types.GET_LOGS_TIPS_PARTNER_REQUEST:
      state.isLoadingLogs = true;
      state.getLogsTipsPartnersFailed = null;
      return;

    case Types.GET_LOGS_TIPS_PARTNER_SUCCESS:
      state.isLoadingLogs = false;
      state.logsTipsPartners = payload;
      state.getLogsTipsPartnersFailed = null;
      return;

    case Types.GET_LOGS_TIPS_PARTNER_FAILED:
      state.isLoadingLogs = false;
      state.logsTipsPartners = [];
      state.getLogsTipsPartnersFailed = payload;
      return;
    case Types.GET_TIPS_PARTNER_REQUEST:
      state.isGetTipsPartnerLoading = true;
      state.tipsPartner = null
      state.getTipsPartnerFailed = null;
      return;

    case Types.GET_TIPS_PARTNER_SUCCESS:
      state.isGetTipsPartnerLoading = false;
      state.tipsPartner = payload;
      state.getTipsPartnerFailed = null;
      return;

    case Types.GET_TIPS_PARTNER_FAILED:
      state.isGetTipsPartnerLoading = false;
      state.tipsPartner = null;
      state.getTipsPartnerFailed = payload;
      return;
    case Types.CREATE_TIPS_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_TIPS_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_TIPS_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_TIPS_PARTNER_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_TIPS_PARTNER_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.tipsPartner = payload;
        return;
  
      case Types.UPDATE_TIPS_PARTNER_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_TIPS_PARTNER_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_TIPS_PARTNER_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_TIPS_PARTNER_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_TIPS_PARTNER_STATE:
      return initState;

    default:
      return;
  }
}, initState);
