import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Switch
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import SkeletonInput from 'antd/lib/skeleton/Input';
import { get , omit} from 'lodash';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { WithPermission } from '~/components/Common';
import {
  useCreateProductList,
  useProductList,
  useUpdateProductList
} from '~/hooks/productList';
import {
  filterAcrossAccents,
  formatNumberThreeComma,
  useBranchIdSessionStorage
} from '~/hooks/utils';
import { useProductCatalogues } from '~/hooks/productCatalogue';
import api from '~/api';
import DebounceSelectVoucher from '../FormVoucher/DebounceSelectVoucher';
import moment from 'moment';
import {
  MAX_PAGINATION_LIMIT,
  WAREHOUSE_STATUS,
  WAREHOUSE_STATUS_KEY
} from '~/constants/defaultValue';
import toastr from 'toastr';
import POLICY from '~/constants/policy';
import { useBranch, useSelectBranch, useUser } from '~/hooks';
// import './index.scss';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 },
    lg: { span: 6 },
    xl: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 18 },
    sm: { span: 18 },
    lg: { span: 18 },
    xl: { span: 18 }
  }
};

const formItemLayoutSort = {
  labelCol: {
    xs: { span: 13 },
    sm: { span: 13 },
    lg: { span: 13 },
    xl: { span: 13 }
  },
  wrapperCol: {
    xs: { span: 11 },
    sm: { span: 11 },
    lg: { span: 11 },
    xl: { span: 11 }
  }
};
const fullWidthStyle = {
  width: '100%'
};

const optionsUnit = [
  {
    label: 'Lần',
    value: 'Lần'
  }
];

const numberPattern = /^[0-9]+$/;
const ColumnActions = ({
  id,
  defaultAction,
  setDefaultAction,
  statusProduct,
  disabled
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.DELETE_WAREHOUSE}>
        <Switch
          checked={defaultAction === 'ACTIVE'}
          disabled={disabled}
          onChange={() => {
            if (
              statusProduct === `${WAREHOUSE_STATUS_KEY.READY}` ||
              statusProduct === `${WAREHOUSE_STATUS_KEY.UNREADY}` ||
              !id
            ) {
              setDefaultAction(
                defaultAction === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
              );
            } else {
              toastr.error(
                `Thiết bị ${WAREHOUSE_STATUS[
                  statusProduct
                ]?.name?.toLowerCase()} nên không thể thực hiện thao tác này!`
              );
            }
          }}
        />
      </WithPermission>
    </div>
  );
};

export default function ProductListForm({
  id,
  handleCloseForm,
  isOpenForm,
  statusProduct
}) {
  const [branchId] = useUser();
  const [form] = Form.useForm();
  // const [branchId] = useBranchIdSessionStorage();
  // const [brandId] = useSelectBranch();
  // const idBranch = useMemo(() => (branchId), [branchId]);
  const [branchInfo] = useBranch(branchId);
  const [isLoadingUpdate, updateProductList] =
    useUpdateProductList(() => (
      handleCloseForm(),
      form.resetFields()
    ));
  const [product, loading] = useProductList(id);
  const [valueSelect, setValueSelect] = useState(null);
  const [valueSupplier, setValueSupplier] = useState([
    { label: String, value: String }
  ]);
  const [loadingSubmit, createProductList] = useCreateProductList(() => {
      handleCloseForm(), form.resetFields(), setValueSupplier(null);setShowWarehouse(false);setWarehouseId(null);
  });
  const [defaultAction, setDefaultAction] = useState('ACTIVE');
  const [initValues, setInitValues] = useState({
    unitDepreciation: optionsUnit[0]?.value
  });
  const [isShowWarehouse, setShowWarehouse] = useState(false);
  const [warehouseId, setWarehouseId] = useState(null);
  const [warehouseList, setWarehouseList] = useState([]);
  const queryGroup = useMemo(
    () => ({
      page: 1,
      limit: MAX_PAGINATION_LIMIT,
      action: 'ACTIVE'
    }),
    []
  );

  // const disableItem = (product?.status === 'READY' || product?.status === 'UNREADY') ? false : true
  const disableItem = useMemo(() => {
    const isDisable =
      statusProduct === 'ASSIGNED' || statusProduct === 'PROCESSING'
        ? true
        : false;
    return !!isDisable;
  }, [id, statusProduct]);

  const [groupProducts, isGroupProductsLoading] =
    useProductCatalogues(queryGroup);

  // Featch whPartner lists
  const getListPartners = async (query, branchInfo) => {
    let res = await api.whPartner.getWhPartnersReport({...query,isGetCurrentBranchId : branchId});
    const data = get(res, 'docs');

    let listPartner = [];
    const defautSupplier = {
      _id: `${branchInfo?._id}`,
      name: `${branchInfo?.name?.vi}`,
      partnerCode: `WH${branchInfo?._id}`
    };

    listPartner = defautSupplier ? [defautSupplier, ...data] : [...data];
    const partners = listPartner.map((item) => {
      return {
        ...item,
        label: `${get(item, 'partnerCode')} - ${get(item, 'name')}`
      };
    });
    return partners;
  };

  const getWarehouse = async (query) => {
    const res = await api.listWareHouse.getAll(query);
    const filterOptionWareHouse = res?.docs?.map((item) => ({
      label: item.name,
      _id: item._id
    }));
    setWarehouseList(filterOptionWareHouse);
  };

  useEffect(() => {
    isShowWarehouse &&  getWarehouse({
      page: 1,
      limit: MAX_PAGINATION_LIMIT,
      branchIds: branchInfo?._id,
      status: 'ACTIVE'})
  },[branchInfo?._id, isShowWarehouse])

  useEffect(() => {
    if (!id) {
      form.resetFields();
      setValueSupplier(null);
    }
  }, [form, id]);
  const onClientIdChange = (values) => {
    setValueSupplier([values]);
    const isCheckShowWarehouse =
      get(values, 'value', '') === String(branchInfo?._id);
    setShowWarehouse(isCheckShowWarehouse);
  };

  useEffect(() => {
    if (product && id) {
      const {
        dateManufacturer,
        action,
        supplierInfo,
        supplierId,
        productList,
        ...rest
      } = product;
      setDefaultAction(action);
      form.setFieldsValue({ ...rest });
      form.setFieldsValue({ dateManufacturer: moment(dateManufacturer) });
      const _supplier = {
        value: get(supplierInfo, '_id'),
        label: `WH${product?.warehouse ? get(supplierInfo, '_id', '') : get(supplierInfo,'partnerCodeSeq','')} - ${get(supplierInfo, 'name')}`
      };
      const isShow = String(get(supplierInfo, '_id', '')) === String(rest?.branchId);
      setShowWarehouse(isShow);
      isShow && getWarehouse({ page: 1, limit: 10, branchIds: rest?.branchId});
      setValueSupplier([_supplier]);
      setWarehouseId({
        value: get(rest, 'warehouseInfo._id', ''),
        label: get(rest, 'warehouseInfo.name', '')
      });
      form.setFieldsValue({
        supplierId: _supplier?.value,
      });
    } else{
      form.resetFields();
      setValueSupplier(null);
      setShowWarehouse(false);
      setWarehouseId(null);
    }
  }, [product, id, form, isOpenForm, branchId]);

  const onFinish = (values) => {
    const { action, dateManufacturer, supplierId, warehouse, ...rest } = values;
    const product = {
      ...rest,
      action: defaultAction,
      dateManufacturer: moment(dateManufacturer).format('YYYY-MM-DD'),
      supplierId: typeof (supplierId) === 'object' ? String(supplierId.value) : String(supplierId), //Control select error, it will take time to fix it as the value is always just an id
      warehouse:  typeof (warehouse) === 'object' ? (warehouse?.value ?? warehouse?.warehouseId) : warehouse,
    };
    if (id) {
      updateProductList({...omit(product, 'warehouse'), id,});
    } else {
      createProductList({
        ...product,
      });
    }
  };
  
  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError
      requiredMark={false}
      initialValues={initValues}
    >
      <FormItem
        name={'groupProductId'}
        label="Nhóm sản phẩm"
        {...formItemLayout}
        rules={[
          {
            required: true,
            message: 'Vui lòng lựa chọn nhóm sản phẩm'
          }
        ]}
      >
        {loading || isLoadingUpdate ? (
          <Skeleton.Input active />
        ) : (
          <Select
            onChange={(value) => {
              setValueSelect(value);
            }}
            filterOption={filterAcrossAccents}
            showSearch
            autoComplete="off"
            value={valueSelect}
            placeholder={'Tên'}
            disabled={disableItem}
          >
            {groupProducts?.map((op) => (
              <Option key={op?._id} value={op?._id}>
                {op?.name?.vi}
              </Option>
            ))}
          </Select>
        )}
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Tên"
        name={'name'}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tên sản phẩm'
          }
        ]}
      >
        {loading || isLoadingUpdate ? (
          <Skeleton.Input active />
        ) : (
          <Input
            disabled={disableItem}
            style={{ ...fullWidthStyle }}
            formatter={(value) => formatNumberThreeComma(value)}
          />
        )}
      </FormItem>
      <FormItem
        {...formItemLayout}
        label="Hãng"
        name={'manufacturer'}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tên hãng sản xuất'
          }
        ]}
      >
        {loading || loadingSubmit || isLoadingUpdate ? (
          <Skeleton.Input active />
        ) : (
          <Input
            disabled={disableItem}
            style={{ ...fullWidthStyle }}
          />
        )}
      </FormItem>

      <FormItem
        {...formItemLayout}
        name={'supplierId'}
        label="Đơn vị cung cấp"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn đơn vị cung cấp'
          }
        ]}
      >
        {loading || loadingSubmit || isLoadingUpdate ? (
          <Skeleton.Input active />
        ) : (
          <DebounceSelectVoucher
            // disabled={disableItem}
            disabled={id}
            fetchOptions={(query) => {
              return (
                getListPartners({ ...query, page: 1, limit: 10 }, branchInfo) ||
                []
              );
            }}
            labelKey="label"
            searchKey="name"
            valueKey={'_id'}
            onSelect={(e) => {
              onClientIdChange(e);
            }}
            valueInit={valueSupplier}
          />
        )}
      </FormItem>

      {isShowWarehouse ? (
        <FormItem
          {...formItemLayout}
          name={'warehouse'}
          label="Kho lưu trữ"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn kho lưu trữ'
            }
          ]}
        >
          {loading || loadingSubmit || isLoadingUpdate ? (
            <Skeleton.Input active />
          ) : (
              <DebounceSelectVoucher
              disabled={id}
              fetchOptions={()=> warehouseList}
              labelKey="label"
              searchKey="name"
              valueKey={'_id'}
              onSelect={(e) => {
                setWarehouseId(e);
              }}
              valueInit={warehouseId}
            />
          )}
        </FormItem>
      ): null}

      <FormItem {...formItemLayout} label="Tình trạng sản phẩm" name={'note'}>
        {loading || isLoadingUpdate ? (
          <Skeleton.Input active />
        ) : (
          <Input
            disabled={disableItem}
            style={{ ...fullWidthStyle }}
          />
        )}
      </FormItem>
      <Row gutter={36}>
        <Col span={12}>
          <FormItem
            {...formItemLayoutSort}
            label={'Ngày sản xuất'}
            name={'dateManufacturer'}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn ngày sản xuất'
              }
            ]}
          >
            {loading || isLoadingUpdate ? (
              <Skeleton.Input active />
            ) : (
              <DatePicker
                disabled={disableItem}
                style={{ width: '100%' }}
                disabledDate={(current) => {
                  return (
                    current > dayjs().endOf('day') && [
                      current?.format('YYYYMMDD')
                    ]
                  );
                }}
              />
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Đơn vị khấu hao"
            name={'unitDepreciation'}
            {...formItemLayoutSort}
          >
            {loading || isLoadingUpdate ? (
              <Skeleton.Input active />
            ) : (
              <Select
                disabled={disableItem}
                options={optionsUnit.map((item) => ({
                  label: item.label,
                  value: item.value
                }))}
                style={{ ...fullWidthStyle }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={36}>
        <Col span={12}>
          <FormItem
            label="Giá trị khấu hao"
            name={'valueDepreciation'}
            {...formItemLayoutSort}
            rules={[
              {
                required: true,
                pattern: numberPattern,
                message: 'Xin vui lòng nhập giá trị là số!'
              }
            ]}
          >
            {loading || isLoadingUpdate ? (
              <Skeleton.Input active />
            ) : (
              <InputNumber
                // controls ={false}
                disabled={disableItem}
                style={{ ...fullWidthStyle }}
                formatter={(value) => formatNumberThreeComma(value)}
              />
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            {...formItemLayoutSort}
            label={'Trạng thái sử dụng'}
            name={'action'}
          >
            {' '}
            {loading || isLoadingUpdate ? (
              <Skeleton.Input active />
            ) : (
              <ColumnActions
                disabled={disableItem}
                statusProduct={statusProduct}
                defaultAction={defaultAction}
                setDefaultAction={setDefaultAction}
              />
            )}
          </FormItem>
        </Col>
      </Row>

      <Row align="center">
        <Col align="center" span={4}>
          <Button
            disabled={disableItem}
            loading={loadingSubmit}
            htmlType="submit"
            type="primary"
          >
            {id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
        </Col>
        <Col span={4} align="center">
          <Button onClick={handleCloseForm}>Huỷ</Button>
        </Col>
      </Row>
    </Form>
  );
}
