import React, { useState } from 'react';

import Icon, { ClockCircleOutlined, HeartOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Card, Col, Row, Space, Tag } from 'antd';
import { get } from 'lodash';

import { MAP_WH_BILL_ITEM_STATUS_TAG } from '~/constants/defaultValue';
import { formatter } from '~/utils/helper';

import LocationSvg from '../../WhPartner/LocationSvg';
import MoneySvg from '../../WhPartner/MoneySvg';
import PhoneSvg from '../../WhPartner/PhoneSvg';
import ServiceSvg from '~/components/WhPartner/ServiceSvg';
const WhPartnerBillItemCardCompact = ({
  whPartner,
  whBillItem,
}) => {
  const nameService = (whBillItem) =>{
    if (get(whBillItem, "snapshotService.detailName.vi"))
          return (`${get(whBillItem, "snapshotService.code")} - ${get(whBillItem, "snapshotService.name.vi")} - ${get(whBillItem, "snapshotService.detailName.vi")} : ${get(whBillItem, "whBill.billNumber")}`)
        else 
          return (`${get(whBillItem, "snapshotService.code")} - ${get(whBillItem, "snapshotService.name.vi")} : ${get(whBillItem, "whBill.billNumber")}`)
  }
  return (
    <Card className='card'>
      <Row>
        <Col span={24}>
          <Space direction='vertical' style={{ width: "100%" }}>
            <Row align='middle' justify='space-between'>
            <Space>
              <UserOutlined color='#3481FF' style={{ color: '#3481FF' }} />
              <p className=''>{
                `${get(whBillItem, "whBill.customer.customerIdFull")} - ${get(whBillItem, "whBill.customerAddress.fullName")}`
              }</p>
            </Space>
                <Tag
                  color={get(MAP_WH_BILL_ITEM_STATUS_TAG, `${get(whBillItem, "status")}.color`)}
                >
                  {get(MAP_WH_BILL_ITEM_STATUS_TAG, `${get(whBillItem, "status")}.name`)}
                </Tag>
            </Row>

            <Row align='middle'>
            <Space>
              <HeartOutlined color='#3481FF' style={{ color: '#3481FF' }} />
              <p className=''>{get(whBillItem, "snapshotService.whCategory.name.vi")}</p>
            </Space>
            </Row>
            <Row align='middle'>
            <Space>
              <Icon component={ServiceSvg} color="#3481FF" style={{ transform: 'scale(0.65)' }} />
              <p className=''>{nameService(whBillItem)}</p>
            </Space>
            </Row>
            <Row align='middle'>
              <Space>
                <Icon component={LocationSvg} color="#3481FF" style={{ transform: 'scale(0.65)' }} />
                <p className=''>{get(whBillItem, "whBill.customerAddress.street")}</p>
              </Space>
            </Row>

            <Row align='middle'>
              <Space>
                <Icon component={PhoneSvg} style={{ transform: 'scale(0.65)' }} />
                <p className=''>{get(whBillItem, "whBill.customerAddress.phoneNumber")}</p>
              </Space>
            </Row>

            <Row align='middle'>
              <Space>
                <Icon component={MoneySvg} style={{ transform: 'scale(0.9)', margin: '2px 0 0 2px' }} />
                <p className=''>{formatter(get(whBillItem, "grandTotal"))}</p>
              </Space>
            </Row>

            <Row align='middle' justify='end'>
              <Space>
                <ClockCircleOutlined />
                <p className=''>{moment(get(whBillItem, "createdAt")).format('HH:mm DD/MM/YYYY')}</p>
              </Space>
            </Row>
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

export default WhPartnerBillItemCardCompact;
