import React from 'react';

import { Col, Form, Input, Space } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

import UploadImage from '~/components/Utils/UploadImage';
import { LANGUAGE } from '~/constants/defaultValue';

import './index.scss';

const WhServiceMediaEdit = ({
  className = '',
  fieldKey,
  form,
  index,
  language,
  mediaIdx,
  remove,
  setWhServicMediaImg,
}) => {
  return (
    <Col className={`wh-service-form__question--option ${className}`} sm={12} lg={6}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <UploadImage
        style={{ width: "100%" }}
        title="Hình ảnh"
        imgUrl={form.getFieldValue(["packages", index, "media", mediaIdx])}
        onChange={(img) => setWhServicMediaImg(img, index, mediaIdx)}
      >
        <MinusCircleOutlined
          onClick={e => {
            e.stopPropagation();
            remove(mediaIdx);
          }}
          style={{ position: "absolute", right: 16, top: 16 }}
        />
      </UploadImage>
      </Space>
    </Col>
  )
}

export default WhServiceMediaEdit;
