import { Button, Modal, Row } from 'antd'
import React from 'react'

export default function ModalDeleteQuotation({isModalOpen,handleCancel,isSubmitLoadingSelector,handleDelete}) {
  return (
    <Modal style={{textAlign : 'center'}} visible={isModalOpen} footer={null} onCancel={handleCancel}>
        <h5>Bạn có muốn xoá ?</h5>
        <Row className='FooterModal'>
        <Button onClick={handleCancel}>Huỷ</Button>
        <Button loading={isSubmitLoadingSelector} onClick={handleDelete} type='danger'>Xoá</Button>
        </Row>
      </Modal>
  )
}
