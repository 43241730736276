import { Table } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import Api from '~/api';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { useProductListHistoryPaging, useProductListHistoryQueryParams } from '~/hooks/productList';

const defaultLimit = 10
function History({ product, form }) {
  const {_id, ...rest} = product
  // const [query, onTableChange] = useProductListHistoryQueryParams(_id)
  const [page, setPage] = useState(1)
  const queryFeatch = useMemo(() => ({page: page,limit: defaultLimit ,id: _id }), [_id, page])

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paging, setPaging] = useState({})
  
  const featchData = async () => {
    const res = await Api.productList.getAllHistory(queryFeatch)
    setData(res.docs)
    setIsLoading(res.length)
    if (res) {
      setPaging({
        current: res.page,
        pageSize: res.limit,
        total: res.totalDocs
          })
    }
  
  }
  useEffect(() => {
    featchData()
  },[queryFeatch])

  const columns = [
    {
      title: 'STT',
      align: 'center',
      key: 'stt',
      width: "100px",
      render: (item, record, index) => (
        <p>
          {get(paging, 'pageSize', 0) * (get(paging, 'current', 0) - 1) +
            index +
            1}
        </p>
      )
    },
    {
      title: 'Ngày sử dụng',
      align: 'start',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) =>  moment(value).format('YYYY-MM-DD')
    },
    {
      title: 'Dịch vụ sử dụng',
      align: 'start',
      key: 'nameService',
      render: (value, record) => get(record, 'whService.name.vi') ?? get(record, 'whAppointment.snapshotService.name.vi')
    },
    {
      title: 'Đối tác sử dụng',
      align: 'start',
      key: 'partner',
      render: (value, record) => get(record, 'whPartner.name', '')
    },
  
  ]
  return (
    <React.Fragment>
      {isLoading ? (
        <SkeletonTable
          rowCount={data.length}
          columns={columns}
          pagination={paging}
        />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          dataSource={data}
          onChange={(e)=> setPage(e)}
          size="middle"
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
        />
      )}
    </React.Fragment>
  );
}

export default History;
