import React, { useEffect, useState } from 'react';

import { compact, get, set, union } from 'lodash';
import { Input, Row, Switch, Table, Tag } from 'antd';

import api from '~/api';

import SkeletonTable from '~/components/Utils/SkeletonTable';

import { fromJSON } from './parser';

import './index.scss';
import { useHistory } from 'react-router-dom';

const { Search } = Input;

const Children = ({
  // history,
  // status,
  paginationPosition,
  parent,
  preloadChildren,
  hideColumns,
  selectColumns,
  showRootChildrenOnly,
  size,
  specialities,
}) => {
  const [whPartners, setWhPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [didPreloadRootChildren, setDidPreloadRootChildren] = useState(false);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const pageSize = 10;
  const history = useHistory()
  // const [keyword, setKeyword] = useState(query.keyword);
  const [keyword, setKeyword] = useState("");

  const getChildren = async (parent, query) => {
    const parentPath = get(parent, "path");
    if (!parentPath) setIsLoading(true);
    const response = await api.whPartner.getChildren(
      get(parent, "id"),
      (!parentPath && query) ? { page: query.page, limit: query.pageSize , ...keyword && {keyword:keyword?.trim()} } : {}
    );
// console.log(response,'response');
    if (!parentPath) {
      const totalDocs = get(response, "totalDocs");
      setTotal(totalDocs);
    }

    let children = get(response, "docs");
    
    const ids = union(...children.map(item => get(item, "services")))
    const whServicesResponse = await api.whService.getWhServices({ ids :compact(ids) });
    const whServices = get(whServicesResponse, "docs");

    children = children.map((item, index) => {
      const whPartner = fromJSON(item);
      return {
        ...whPartner,
        specialities: get(whPartner, "specialityIds").map(id => {
          return specialities?.find(item => item.id === id);
        }),
        services: get(whPartner, "services")?.map(whService => ({
          ...whService,
          ...whServices.find(item => item.id === whService.whServiceId)
        })),
        ...(!showRootChildrenOnly && { children: [] }),
        path: (parentPath ? `${parentPath}.` : '') + String(index),
      }
    })

    if (parentPath) {
      set(whPartners, `${parentPath}.children`, children);
    } else {
      setWhPartners(children);
      setIsLoading(false);
    }
  }

  const onPagingChange = ({ current, pageSize, total }, someObj) => {
    setCurrent(current);
    setDidPreloadRootChildren(false);
    getChildren(parent, { page: current, pageSize })
  }

  useEffect(() => {
    getChildren(parent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent, specialities]);

  useEffect(() => {
    if (preloadChildren) {
      setDidPreloadRootChildren(true);
      whPartners.forEach(item => getChildren(item))
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whPartners.length]);

  useEffect(() => {
    if (preloadChildren && !didPreloadRootChildren) {
      setDidPreloadRootChildren(true);
      whPartners.forEach(item => getChildren(item))
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whPartners]);

  const onSearch = () => {
    const query = {page : current,pageSize,keyword}
    getChildren(parent,query)
  };

  const columns = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'partnerCode',
      key: 'partnerCode'
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Chuyên môn',
      dataIndex: 'specialities',
      key: 'specialities',
      render: (specialities) => specialities?.map((item, index) => <Tag key={index} color="blue">{get(item, "name")}</Tag>)
    },
    {
      title: 'Nhóm đối tác',
      dataIndex: 'employeeGroup',
      key: 'employeeGroup',
      render: (employeeGroup) => {
        return get(employeeGroup, "name.vi")
      },
    },
    {
      title: 'Dịch vụ',
      dataIndex: 'services',
      width: '300px',
      key: 'services',
      render: (services) => {
        return services?.map((item, index) => <Tag key={index} color="blue">{get(item, "name.vi")}</Tag>);
      },
    },
    {
      title: 'Khu vực hoạt động',
      dataIndex: 'managementArea',
      key: 'managementArea',
      render: (managementArea) => {
        return get(managementArea, "fullAddress");
      },
    },
  ];

  let displayColumns = selectColumns
    ? columns.filter(item => selectColumns.includes(item.key))
    : columns

  if (hideColumns) {
    displayColumns = displayColumns.filter(item => !hideColumns.includes(item.key))
  }

  return (
    <div className="container-fluid">

      <div className="page-wraper__header">
        <Row align='middle'>
          <Search
            style={{ width: 300 }}
            placeholder="Tìm đối tác"
            enterButton
            onSearch={onSearch}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

          {/* Tải trước children */}
          {/* <Switch /> */}
        </Row>

      </div>

      {isLoading ? (
        <SkeletonTable
          rowCount={whPartners.length}
          columns={columns}
        // pagination={paging}
        />
      ) : (
        <Table
          // dataSource={mergedWhPartners}
          onChange={onPagingChange}
          columns={displayColumns}
          dataSource={whPartners}
          rowKey={(rc) => rc._id}
          size={size}

          pagination={{
            // ...paging,
            total,
            pageSize,
            current,
            position: paginationPosition || ["topRight"],
            showTotal: (total) => `Tổng cộng: ${total} `
          }}

          // Read more: https://ant.design/components/table/#expandable
          expandable={{
            // rowExpandable: record => status !== WH_PARTNER_STATUS.WAITING,
            onExpand: (expanded, record) => {
              if (preloadChildren) {
                if (Array.isArray(get(record, "children")) && get(record, "children").length) {
                  record.children.forEach(item => getChildren(item))
                }
              } else {
                getChildren(record);
              }
            }
          }}
        />
      )}
    </div>
  );
};

export default Children;
