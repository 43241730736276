import React, { useState } from 'react';
import {
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
  Redirect,
  useParams
} from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { PATH_APP } from '~/routes/paths';
import { NavItem, Nav } from 'reactstrap';
import Info from './Info';
import Branches from './Branches';
import { usePartner } from '~/hooks';
import PermissionGate from '~/routes/middleware/PermissionGate';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import './index.scss';
import Branch from '~/components/Branch';
import PermissionAccess from './PermissionAccess';

const Partner = () => {
  const { path, url } = useRouteMatch();
  const {
    location: { pathname }
  } = useHistory();

  const { id } = useParams();
  const [partner, isLoading] = usePartner(id);

  const className = (path) => {
    return `nav-link ${pathname.split('/').includes(path) && 'active'}`;
  };

  return (
    <div className={"partner-detail page-wraper page-content"}>
      <div className="container-fluid">
        <Breadcrumb
          title="Thông tin đối tác"
          routes={[{ path: PATH_APP.partner.root, title: 'Danh sách đối tác' }]}
        />

        <div className="partner-detail__navbar">
          <Nav
            tabs
            className="nav-tabs-custom nav-justified"
            style={{
              width: 1000,
              margin : '0 auto'
            }}
          >
            <NavItem>
              <Link to={`${url}/info`} className={className('info')}>
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Thông tin chi tiết</span>
              </Link>
            </NavItem>

            <WithPermission permission={POLICY.READ_BRANCH}>
              <NavItem>
                <Link to={`${url}/clinics`} className={className('clinics')}>
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Phòng khám</span>
                </Link>
              </NavItem>
            </WithPermission>

            <WithPermission permission={POLICY.READ_RESOURCECLINIC}>
              <NavItem>
                <Link to={`${url}/permissionClinic`} className={className('permissionClinic')}>
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Chức năng phòng khám</span>
                </Link>
              </NavItem>
            </WithPermission>

              <NavItem>
                <Link to={`${url}/branches`} className={className('branches')}>
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Chi nhánh</span>
                </Link>
              </NavItem>

            <WithPermission permission={POLICY.READ_RESOURCEPARTNER}>
              <NavItem>
                <Link to={`${url}/permissionBranch`} className={className('permissionBranch')}>
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Chức năng chi nhánh</span>
                </Link>
              </NavItem>
            </WithPermission>

          </Nav>
        </div>

        <Switch>
          <Route
            path={`${path}/info`}
            component={() => <Info partner={partner} isLoading={isLoading} />}
          />

          <PermissionGate
            path={`${path}/clinics`}
            component={() => <Branches typeGet='CLINIC'/>}
            permission={POLICY.READ_BRANCH}
          />

          <PermissionGate
            path={`${path}/permissionClinic`}
            component={() => <PermissionAccess type={'Clinic'}/>}
            permission={POLICY.READ_RESOURCECLINIC}
          />

          <Route
            path={`${path}/branches`}
            component={() => <Branches typeGet='BRANCH'/>}
          />

          <PermissionGate
            path={`${path}/permissionBranch`}
            component={() => <PermissionAccess type={'Branch'}/>}
            permission={POLICY.READ_RESOURCEPARTNER}
          />
          
          <Redirect to={`${path}/info`}></Redirect>
        </Switch>
      </div>
    </div>
  );
};

export default Partner;
