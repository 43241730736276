
import { Divider, Form, Input, Radio, Row, Switch } from 'antd';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { LANGUAGE, requireRules } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useUiClients, useUpdateUiClient } from '~/hooks/UiClient';
import { handleSubmit } from '~/hooks/utils';
import { Breadcrumb } from '../Common';
import Editor from '../Utils/Editor';
import UploadImage from '../Utils/UploadImage';
import LoadingWhBill from '../WhBill/LoadingWhBill';
import BtnAdd from './BtnAdd';
import BtnRemove from './BtnRemove';
import BtnUpdate from './BtnUpdate';
const key = 'appDownload';
const AppDownload = () => {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_BANNER)
    const [form] = Form.useForm();
    const [UiClient, isLoading] = useUiClients(key);
    const [isSubmitLoading, update] = useUpdateUiClient();
    const initValue = useMemo(() => get(UiClient, ['value', key]), [UiClient])
    const disabled = useMemo(() => !canUpdate, [canUpdate]);
    const [language, setLanguage] = useState(LANGUAGE.VI);
    const onSubmit = handleSubmit();
    const onFinish = (values) => {
        update({ key, ...values })
    };

    useEffect(() => {
        form.setFieldsValue({
            ...initValue
        })
    }, [initValue]);

    return (
        <div className="wh-service-form page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb title={'Thông tin tải ứng dụng'} routes={[{ path: '', title: 'CMS' }]} />
                {isLoading && <LoadingWhBill open={true} />}
                <Form
                    form={form}
                    autoComplete="off"
                    initialValues={{}}
                    scrollToFirstError
                    requiredMark={false}
                    onFinish={onFinish}
                    onFinishFailed={onSubmit}
                    labelCol={{ sm: 24, md: 24, lg: 4 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 20 }}
                >
                    <Row justify="end" style={{ marginBottom: 20 }}>
                        <Radio.Group
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.VI}
                            >
                                VI
                            </Radio.Button>
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.EN}
                            >
                                EN
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                    <Form.Item label="Hiển thị" valuePropName="checked" name={['active']} >
                        <Switch disabled={disabled} />
                    </Form.Item>
                    <Form.Item label="Thông tin App">

                        <Form.List name={'items'}>
                            {(fields, { add, remove }) => <>
                                {fields?.map((field, index) => {
                                    const image = form.getFieldValue(['items', index, 'image']);
                                    const itemAtHere = form.getFieldValue(['items', index])
                                    const onChange = (value) => {
                                        const items = [...form.getFieldValue(['items'])];
                                        items?.splice(index, 1, {
                                            ...itemAtHere,
                                            image: value
                                        });
                                        form.setFieldsValue({ items })
                                    };
                                    return <>
                                        <Divider>App {index + 1} <BtnRemove danger type='primary' onClick={() => remove(index)} />
                                        </Divider>
                                        <Form.Item rules={requireRules} label="Hình ảnh" name={[index, 'image']}>
                                            <UploadImage disabled={initValue?.items?.length > index && disabled} onChange={onChange} imgUrl={image} title={''} />
                                        </Form.Item>
                                        <Form.Item rules={requireRules} hidden={language === LANGUAGE.EN} label="Tiêu đề VI" name={[index, 'title', LANGUAGE.VI]}>
                                            <Input disabled={initValue?.items?.length > index && disabled} />
                                        </Form.Item>
                                        <Form.Item rules={requireRules} hidden={language === LANGUAGE.VI} label="Tiêu đề EN" name={[index, 'title', LANGUAGE.EN]}>
                                            <Input disabled={initValue?.items?.length > index && disabled} />
                                        </Form.Item>
                                        <Form.Item rules={requireRules} hidden={language === LANGUAGE.EN} label="Mô tả VI" name={[index, 'content', LANGUAGE.VI]}>
                                            <Editor disabled={initValue?.items?.length > index && disabled} />
                                        </Form.Item>
                                        <Form.Item rules={requireRules} hidden={language === LANGUAGE.VI} label="Mô tả EN" name={[index, 'content', LANGUAGE.EN]}>
                                            <Editor disabled={initValue?.items?.length > index && disabled} />
                                        </Form.Item>
                                        <Divider />
                                    </>

                                })}
                                <BtnAdd onClick={() => add()} />
                            </>

                            }

                        </Form.List>
                    </Form.Item>


                    <BtnUpdate loading={isSubmitLoading} />
                </Form>
            </div>
        </div>
    );
};
export default AppDownload;
