import * as Types from '~/constants/actionTypes';

export const getGroupCategories = (groupCategory) => ({
  type: Types.GET_GROUP_CATEGORIES_REQUEST,
  payload: groupCategory
});

export const getGroupCategory = (groupCategory) => ({
  type: Types.GET_GROUP_CATEGORY_REQUEST,
  payload: groupCategory
});

export const createGroupCategory = (groupCategory) => ({
  type: Types.CREATE_GROUP_CATEGORY_REQUEST,
  payload: groupCategory
});

export const updateGroupCategory = (groupCategory) => ({
  type: Types.UPDATE_GROUP_CATEGORY_REQUEST,
  payload: groupCategory
});

export const deleteGroupCategory = (id) => ({
  type: Types.DELETE_GROUP_CATEGORY_REQUEST,
  payload: id
});

export const resetGroupCategoryState = () => ({
  type: Types.RESET_GROUP_CATEGORY_STATE
});

export const getPermission = (groupCategory) => ({
  type: Types.GET_PERMISSION_REQUEST,
  payload: groupCategory
});

export const updatePermission = (payload) => ({
  type: Types.UPDATE_PERMISSION_REQUEST,
  payload
});

export const updateGroupCategoryRedux = (groupCategory) => ({
  type: Types.UPDATE_GROUP_CATEGORY_REDUX,
  payload: groupCategory
});

export const updatePermissionRedux = (permission) => ({
  type: Types.UPDATE_PERMISSION_REDUX,
  payload: permission
});
