import requester from './requester';

const productCatalogue = {
  getAll: (query) => requester.get(`product-portfolio`, query),
  getAllNoAuth: (query) => requester.get(`product-portfolio-all`, query),
  getByStatus: (query) => requester.get(`product-portfolio-status/${query?.id}`, query),
  getById: (id) => requester.get(`product-portfolio/${id}?raw=true`),
  create: (device) => requester.post('product-portfolio', device),
  update: (device) => requester.put(`product-portfolio/${device.id}`, device),
  delete: (id) => requester.delete(`product-portfolio/${id}`),
};

export default productCatalogue;
