
import React from 'react';
import { Col, Image, Row } from 'antd';

export default function WhMediaPackageExpandle({media}) {
  return (
    <>
      <h5>Media</h5>
      <Col span={24}>
        <div
          style={{
            margin: '10px',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row'
          }}
        >
          {media?.map((item, index) => (
            <div
              style={{
                margin: '10px',
                boxShadow: 'rgba(0, 0, 3, 0.1) 11px -3px 10px -3px'
              }}
            >
              <Image width={150} src={`${item}`} />
            </div>
          ))}
        </div>
      </Col>
    </>
  );
}
