import produce from 'immer';
import { get } from 'lodash';
import { STATUS_SERVICE, STATUS_SERVICE_BRANCH, WH_BILL_STATUS_EN } from '~/constants/defaultValue';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getUnConfirmWhBillsFailed: null,
  getUnConfirmWhBillFailed:null,
  unConfirmWhBills: [],
  unConfirmWhBill: null,
  isSubmitLoading : false,
  isGetUnConfirmWhBillLoading: false,
  UnConfirmWhBill: null,

  confirmSuccess: null,
  confirmFailed: null,

  paging: null,

  isLoadingListBranchConfirm: false,
  getListBranchConfirmFailed: null,
  listBranchConfirm: [],

  isNewBill : false,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_UNCONFIRM_WH_BILLS_REQUEST:
      state.isLoading = true;
    case Types.GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_REQUEST:
      state.getUnConfirmWhBillsFailed = null;
      return;

    case Types.GET_UNCONFIRM_WH_BILLS_SUCCESS:
      state.isLoading = false;
    case Types.GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_SUCCESS:
      const myBranchId = get(payload,'branchId');
      const dataSource = get(payload,'docs',[]);
      const newDataSource = dataSource?.map(whBill => {
        // NEW BIlL ITEM
        const newBillItems = get(whBill,'billItems',[])?.map(item => {
          const availableService = get(item,'avaibleService',[])?.find(service => get(service,'branchId') === myBranchId);
          let messageErrorService = [];
          // // Check whService is Disabled
          // if(get(item,'whService.status') === STATUS_SERVICE.INACTIVE){
          //   return {...item,messageErrorService : ['Hệ thống tạm dừng hoạt động dịch vụ này.']}; 
          // }
            // Check not Register Service Branch
          if(get(whBill,'status') === WH_BILL_STATUS_EN.CANCELLED){
            messageErrorService.push("Đơn hàng đã bị huỷ")
          }
          if(!availableService){
            return {...item,messageErrorService : ['Chi nhánh chưa đăng ký hoạt động dịch vụ.']};
          }else{   
            // Check Disabled Register Service Branch
            if(get(availableService,'status') === STATUS_SERVICE_BRANCH.INACTIVE){
              messageErrorService.push("Dịch vụ đang ngừng hoạt động tại chi nhánh của bạn.") 
            }
          }
            // Check Area Service branch In Address WhBill
            if(get(availableService,'areaAvailble',[])?.length){
              const isAvailable = get(availableService,'areaAvailble',[])?.some(area => {
                const addressPathCustomer = get(whBill,'addressPath','');
                const path = get(area,'path')?.toString();
                const regex = new RegExp('^' + path, 'i');
                return regex.test(addressPathCustomer);
              });
              if(!isAvailable){
                messageErrorService.push("Dịch vụ không khả dụng trong khu vực của bạn.")
              }
            }

          return {...item,messageErrorService}
        });

        // ERROR LIST

        let listErrorService = [];
        newBillItems?.forEach(e => {
          if(get(e,'messageErrorService',[])?.length){
            listErrorService.push({code : get(e,'snapshotService.code'),message : get(e,'messageErrorService',[])?.join(',')})
          }
        })
        return {...whBill,billItems : newBillItems,listErrorService}
      });
      state.unConfirmWhBills = newDataSource;
      state.getUnConfirmWhBillsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_UNCONFIRM_WH_BILLS_FAILED:
      state.isLoading = false;
    case Types.GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_FAILED:
      state.unConfirmWhBills = [];
      state.getUnConfirmWhBillsFailed = payload;
      return;
    case Types.GET_UNCONFIRM_WH_BILL_REQUEST:
      state.isGetUnConfirmWhBillLoading = true;
      state.unConfirmWhBill = null
      state.getUnConfirmWhBillFailed = null;
      return;

    case Types.GET_UNCONFIRM_WH_BILL_SUCCESS:
      state.isGetUnConfirmWhBillLoading = false;
      state.unConfirmWhBill = payload;
      state.getUnConfirmWhBillFailed = null;
      return;

    case Types.GET_UNCONFIRM_WH_BILL_FAILED:
      state.isGetUnConfirmWhBillLoading = false;
      state.unConfirmWhBill = null;
      state.getUnConfirmWhBillFailed = payload;
      return;

      // LIST BRANCH CONFIRM
      case Types.GET_LIST_BRANCH_CONFIRM_REQUEST:
      state.isLoadingListBranchConfirm = true;
      state.getListBranchConfirmFailed = null;
      return;

    case Types.GET_LIST_BRANCH_CONFIRM_SUCCESS:
      state.isLoadingListBranchConfirm = false;
      state.listBranchConfirm = payload;
      return;

    case Types.GET_LIST_BRANCH_CONFIRM_FAILED:
      state.isLoadingListBranchConfirm = false;
      state.listBranchConfirm = [];
      state.getListBranchConfirmFailed = payload;
      return;
      //


    case Types.CONFIRM_UNCONFIRM_WH_BILL_REQUEST:
      state.isSubmitLoading = true;
      state.confirmSuccess = null;
      state.confirmFailed = null;
      return;
    case Types.CONFIRM_UNCONFIRM_WH_BILL_SUCCESS:
      state.isSubmitLoading = false;
      state.confirmSuccess = payload;
      return;

    case Types.CONFIRM_UNCONFIRM_WH_BILL_FAILED:
      state.isSubmitLoading = false;
      state.confirmFailed = payload;
      return;

    case Types.SET_NEW_BILL:
      state.isNewBill = payload;
      return;

    case Types.RESET_STORE:
    case Types.RESET_UNCONFIRM_WH_BILL_STATE:
      return initState;

    default:
      return;
  }
}, initState);
