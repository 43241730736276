import React from 'react';
import { Form, Input, Row, Col, DatePicker, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

let onAdd = () => {};

const History = ({ name, verticalLayout }) => {
  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        className="employee-form__section-title"
      >
        <h4>Quá trình công tác</h4>
        <Col>
          <Button
            type="dashed"
            onClick={() => onAdd()}
            block
            icon={<PlusOutlined />}
          >
            Thêm công tác
          </Button>
        </Col>
      </Row>

      <Form.List name={name}>
        {(fields, { add, remove }) => {
          onAdd = add;
          return (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Row gutter={48} align={index === 0 && 'middle'} key={key}>
                  <Col sm={12} lg={6}>
                    <Form.Item
                      {...restField}
                      label={index === 0 && 'Chức vụ'}
                      name={[name, 'role']}
                      fieldKey={[fieldKey, 'role']}
                      {...verticalLayout}
                    >
                      <Input placeholder="Chức vụ" />
                    </Form.Item>
                  </Col>

                  <Col sm={12} lg={6}>
                    {' '}
                    <Form.Item
                      {...restField}
                      label={index === 0 && 'Địa điểm'}
                      name={[name, 'location']}
                      fieldKey={[fieldKey, 'location']}
                      {...verticalLayout}
                    >
                      <Input placeholder="Địa điểm" />
                    </Form.Item>
                  </Col>

                  <Col sm={12} lg={5}>
                    <Form.Item
                      {...restField}
                      label={index === 0 && 'Thời gian bắt đầu'}
                      name={[name, 'startTime']}
                      fieldKey={[fieldKey, 'startTime']}
                      {...verticalLayout}
                    >
                      <DatePicker placeholder="Thời gian bắt đầu" />
                    </Form.Item>
                  </Col>

                  <Col sm={10} lg={5}>
                    <Form.Item
                      {...restField}
                      label={index === 0 && 'Thời gian kết thúc'}
                      name={[name, 'endTime']}
                      fieldKey={[fieldKey, 'endTime']}
                      {...verticalLayout}
                    >
                      <DatePicker placeholder="Thời gian cấp" />
                    </Form.Item>
                  </Col>

                  <Col span={2} style={{ paddingTop: index == 0 ? 20 : 5 }}>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      className={index === 0 && 'top-5'}
                    />
                  </Col>
                </Row>
              ))}
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default History;
