import React, { useEffect, useMemo, useState } from 'react';

import { Col, DatePicker, Input, Row, Select, Tabs, Tag, Form } from 'antd';
import { get, isNil, omitBy, transform } from 'lodash';

import {
  useWhReceiptVoucherQueryParams,
  useWhPaymentVoucherQueryParams,
  useUser,
  useProfile,
  useWhAllVoucherQueryParams
} from '~/hooks';

import WhReceiptVouchers from './WhReceiptVouchers';
import WhPaymentVouchers from './WhPaymentVouchers';
import { useParams } from 'react-router-dom';
import ExportExcelButton from '../Common/ExportExcel';
import {
  MAP_WH_VOUCHER_STATUS_TAG,
  WH_VOUCHER_STATUS_VI,
  RELATIVE_POSITION,
  WH_VOUCHER_STATUS
} from '~/constants/defaultValue';


import { GeoTreeSelect, WithPermission } from '../Common';
import WhAllVoucher from './WhAllVoucher';
import moment from 'moment';
import './index.scss';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useBranchesDefault, useQueryParams } from '~/hooks/utils';
import TabBranch from '../Common/TabBranch';
import SelectMultiBranch from '../Common/SelectMultiBranch';
import { convertQueryString } from '~/utils/helper';
import POLICIES from '~/constants/policy';
dayjs.extend(customParseFormat);
import WhPaymentVouchersCancel from './WhPaymentVouchersCancel';
import DebounceSelectByCategory from '../WhCategory/SearchByCategory';
// import toastr from 'toastr';

const { TabPane } = Tabs;
const { Search } = Input;

const WhVoucherTabs = ({
  history,
  whBill,
  noShowAtHome,
  showTopPadding = true,
  useLocalPagination = false,
  noShowExportButton,
  noShowSearchTime,
  noShowAllVoucher,
  noShowTitle,
  useTime,
}) => {
  const [form] = Form.useForm()
  const defaultDate = useMemo(() => ({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  }), [])
  const [date, setDate] = useState(defaultDate);

  const [branchIds,setBranchIds] = useBranchesDefault();


  const [keyword, setKeyword] = useState();
  const [queryReceipt, setQueryReceipt] = useState({}); //
  const [queryPayment, setQueryPayment] = useState({});
  const [queryAllVoucher, setQueryAllVoucher] = useState({});
  const [queryPaymentCancel, setQueryPaymentCancel] = useState({});

  const [keywordP, setKeywordP] = useState(queryPayment.keyword); // FIXME: consider removing this line

  const [searchBy, setSearchBy] = useState('billNumber');
  const [activeKey, setActiveKey] = useState('1');
  const [, token] = useUser();
  const [profile] = useProfile(token);
  const isRootAdmin = get(profile, 'isSuperAdmin') === true;
  const managementPaths = useMemo(() => {
    return get(profile, 'managementArea')?.map((item) => get(item, 'path'));
  }, [profile]);
  const [arrIds, setArrIds] = useState([]);
  const [getPaging, setPaging] = useState({});
  const [whCategory, setWhCategory] = useState(null);

  useEffect(() => {
    onSearch();
  }, [date,branchIds]);

  const onSearch = () => {
    let query = {};

    // Set query by tab
    switch (activeKey) {
      case '1':
        query = queryReceipt;
        break;
      case '2':
        query = queryPayment;
        break;
      case '3':
        query = queryReceipt;
        break;
      case '4':
        query = queryAllVoucher;
        break
      case "5":
        query = queryPaymentCancel;
        break
      default:
        break;

    };
    let searchParams = `?page=1&limit=${query.limit || 10}`;

    const assignSearchParams = (key,value) => {
      let newKeyword = value?.toString();
      if(!['keyword']?.includes(key)){ // keyword not need clear space
        const regex = /[0-9.]/g;
      if (newKeyword && regex.test(newKeyword)) {
        newKeyword = newKeyword?.replace(/[. ]/g, '');
      };
      };
      searchParams += `&${key}=${newKeyword}`;
    };

    if (!date.startDate || date.startDate === 'Invalid date') {
      assignSearchParams('startDate',date?.defaultDate?.startDate)
    } else {
      assignSearchParams('startDate',date?.startDate)

      if (!date.endDate || date.endDate === 'Invalid date') {
        assignSearchParams('endDate',defaultDate?.endDate)
      } else {
        assignSearchParams('endDate', date?.endDate)
      }
      if (keyword) {
        assignSearchParams(searchBy,keyword);
      } else {
        searchParams = searchParams;
      }
      if(whBill){ // Get BranchId when open in ConsoleLog whBill
        assignSearchParams('branchIds',get(whBill,'branchId'));
      }else{ // Get BranchId when Search in Page WhVoucher
        assignSearchParams('branchIds',branchIds);
      }
      
      !useLocalPagination && history.push({
        pathname: '/wh-voucher',
        search: searchParams
      });
    };
  }

  const handleChangeStatus = (status) => {
    const newKeyword = status.toString();
    setKeyword(newKeyword);
    let query = {};

    // Set query by tab
    switch (activeKey) {
      case '1':
        query = queryReceipt;
        break;
      case '2':
        query = queryPayment;
        break;
      case '3':
        query = queryReceipt;
        break;
      case '4':
        query = queryAllVoucher;
        break
      case "5":
        query = queryPaymentCancel;
        break
      default:
        break;

    };
    // let searchParams = `?page=${query.page}&limit=${query.limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`;
  //  if(status.length !== 0) searchParams += `&${searchBy}=${newKeyword}`;
   let searchParams = convertQueryString(
    {
      ...query,
      [searchBy] : newKeyword
    }
   )
    history.push({
      pathname: '/wh-voucher',
      search: searchParams
    });
  };

  const onSearchByWhCategory = (category) => {
    const newKeyword = category.toString();
    setKeyword(newKeyword);
    let query = {};

    // Set query by tab
    switch (activeKey) {
      case '1':
        query = queryReceipt;
        break;
      case '2':
        query = queryPayment;
        break;
      case '3':
        query = queryReceipt;
        break;
      case '4':
        query = queryAllVoucher;
        break
      case "5":
        query = queryPaymentCancel;
        break
      default:
        break;

    };

   let searchParams = convertQueryString(
    {
      ...query,
      [searchBy] : newKeyword
    }
   )
    history.push({
      pathname: '/wh-voucher',
      search: searchParams
    });
  };

  useEffect(() => {
    setKeyword('');
  }, [searchBy]);
  useEffect(() => {
    if (searchBy === 'managementArea') {
      onSearch();
    }
  }, [keyword]);
  const onTabClick = (key) => {
    setKeyword('');
    setActiveKey(key);
  };

  // props for exportExcel Button
  const setQueryExport = (query) => {
    const filterQuery = omitBy(query, isNil);
    let jornQuery = Object.entries(filterQuery)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    return jornQuery;
  };

  const WhReceiptVouchers_ = '/wh-receipt-voucher';
  const WhPaymentVouchers_ = '/wh-payment-voucher';
  const WhCustomerCancel = '/wh-receipt-voucher-customer/customer-cancel';
  const WhAllVoucherUrl = '/wh-all-voucher';
  const WhPaymentCancel = '/type-payment-customer';
  let url = '';
  let keyExport = '';
  let fileName_ = '';
  let queryExport = '';
  switch (activeKey) {
    case '1': 
      url = WhReceiptVouchers_
      keyExport = "Receipt"
      fileName_ = 'Phiếu thu'
      queryExport = queryReceipt
      break
    case '2': 
      url = WhPaymentVouchers_
      keyExport = "Payment"
      fileName_ = 'Phiếu chi'
      queryExport = queryPayment
      break
    case '3': 
      url = WhCustomerCancel
      keyExport = 'ReceiptFinishSystem'
      fileName_ = "Phiếu thu(Đơn hàng đã chấm dứt)"
      queryExport = queryReceipt
      break
    case '4': 
      url = WhAllVoucherUrl
      keyExport = 'AllVoucher'
      fileName_ = "Tổng phiếu thu chi"
      queryExport = queryAllVoucher
      break
    case '5': 
      url = WhPaymentCancel
      keyExport = 'PaymentCancel'
      fileName_ = "Phiếu chi(Đơn hàng đã huỷ)"
      queryExport = queryPaymentCancel
      break
    default:
      toastr.clear();
      toastr.error('Không thể thực hiện được thao tác tải về');
      break;
  }

  const propsButton = {
    url: url,
    keyExport: keyExport,
    fileName_: fileName_,
    ids: arrIds,
    searchBy: setQueryExport(queryExport)
  };
  const onValuesChange = (valueChange, allValue) => {
    const { startDate, endDate } = allValue;
    if (!startDate) {
      form.setFieldsValue({ startDate: null });
    }
    if (!endDate) {
      form.setFieldsValue({ endDate: null });
    }
  };
  return (
    <div className={`page-wraper ${showTopPadding ? 'page-content' : ''}`}>
      {/* <div className="page-wraper"> */}
      <div className="container-fluid">
        {/* <Breadcrumb title="Sổ quỹ" /> */}

        {!whBill && (
          <div className="page-wraper__header">
            <Row justify="start" gutter={36}>

                {/* Select Branch */}
                <Col span={24} >
                  <SelectMultiBranch value={branchIds} onChange={(value) => setBranchIds(value)} style={{width : '23%',marginBottom : 5}}/>
                </Col>

              <Col sm={24} md={6}>
                <Select
                  value={searchBy}
                  style={{ width: '100%' }}
                  onChange={(val) => setSearchBy(val)}
                >
                  {activeKey === '4' && <Option value="keyword">Tất cả</Option>}
                  <Option value="billNumber">Mã đơn hàng</Option>
                  <Option value="managementArea">Khu vực</Option>
                  <Option value="whCategory">Nhóm dịch vụ</Option>
                  {(activeKey !== '3') && (
                    <>
                    { (activeKey !== '4') &&  <Option value="serviceCode">ID Dịch vụ</Option>}
                      <Option value="status">Trạng thái</Option>
                      
                    </>
                  )}
                  {
                      {
                        1: <Option value="code">Mã phiếu thu</Option>,
                        2: <Option value="code">Mã phiếu chi</Option>,
                        4: <Option value="code">Mã phiếu thu hoặc chi</Option>,
                        5: <Option value="code">Mã phiếu chi</Option>,
                      }[activeKey]
                    }
                  </Select>
                </Col>

                <Col sm={24} md={6}>
                  {
                    {
                      keyword: (
                        <Search
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Tìm tất cả"
                          enterButton
                          onSearch={onSearch}
                          onChange={(e) => setKeyword(e.target.value)}
                          value={keyword}
                        />
                      ),
                      billNumber: (
                        <Search
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Tìm theo mã đơn hàng"
                          enterButton
                          onSearch={onSearch}
                          onChange={(e) => setKeyword(e.target.value)}
                          value={keyword}
                        />
                      ),

                      serviceCode: (
                        <Search
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Tìm theo ID dịch vụ"
                          enterButton
                          onSearch={onSearch}
                          onChange={(e) => setKeyword(e.target.value)}
                          value={keyword}
                        />
                      ),

                      code: (
                        <Search
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Tìm theo mã phiếu thu"
                          enterButton
                          onSearch={onSearch}
                          onChange={(e) => setKeyword(e.target.value)}
                          value={keyword}
                        />
                      ),
                      status: (
                        <Select
                          mode="multiple"
                          allowClear
                          style={{
                            width: '100%'
                          }}
                          placeholder="Vui lòng chọn trạng thái"
                          onChange={handleChangeStatus}
                        >
                          {transform(
                            MAP_WH_VOUCHER_STATUS_TAG,
                            (result, value, key) => {
                              result.push({
                                label: get(value, 'name', ''),
                                value: key,
                                color: get(value, 'colorStyle', '')
                              });
                            },
                            []
                          )?.map((e) => (
                            <Select.Option value={get(e, 'value')}>
                              <p style={{ color: `${e.color}` }}>
                                {get(e, 'label')}
                              </p>
                            </Select.Option>
                          ))}
                        </Select>
                      ),

                      //
                      managementArea: (
                        <GeoTreeSelect
                          style={{ width: '200px' }}
                          onChange={(e) => {
                            setKeyword(e);
                          }}
                          autoClearSearchValue
                          checkablePositions={[
                            RELATIVE_POSITION.IS_CHILD,
                            RELATIVE_POSITION.IS_EQUAL
                          ]}
                          enabledValues={isRootAdmin ? null : managementPaths}
                          listItemHeight={200}
                          showEnabledValuesOnly={true}
                          showSearch={true}
                          treeDefaultExpandedKeys={['1', '2', '3']}
                        />
                    ),
                      //
                    whCategory: (
                      <DebounceSelectByCategory
                      onChange={(e) => {
                          setWhCategory(e);
                          onSearchByWhCategory(e);
                      }}
                      value={whCategory}
                      // isFirstFetch={!!whCategory}
                      placeholder="Tìm nhóm dịch vụ"
                      style={{ minWidth: '300px' }}
                    />
                    )
                    }[searchBy]
                  }
                </Col>

                {/* <Col sm={24} md={12}>
            <Col sm={24} md={6}>
              {
                {
                  "billNumber": (
                    <Search
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Tìm theo mã đơn hàng"
                      enterButton
                      onSearch={onSearch}
                      onChange={(e) => setKeyword(e.target.value)}
                      value={keyword}
                    />
                  ),

                  "serviceCode": (
                    <Search
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Tìm theo ID dịch vụ"
                      enterButton
                      onSearch={onSearch}
                      onChange={(e) => setKeyword(e.target.value)}
                      value={keyword}
                    />
                  ),

                  "code": (
                    <Search
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Tìm theo mã phiếu thu"
                      enterButton
                      onSearch={onSearch}
                      onChange={(e) => setKeyword(e.target.value)}
                      value={keyword}
                    />
                  ),


                  "managementArea": ( //
                    <GeoTreeSelect
                      style={{ width: '200px' }}
                      onChange={(e) => {
                        setKeyword(e)

                      }}
                      autoClearSearchValue
                      checkablePositions={[
                        RELATIVE_POSITION.IS_CHILD,
                        RELATIVE_POSITION.IS_EQUAL
                      ]}
                      enabledValues={isRootAdmin ? null : managementPaths}
                      listItemHeight={200}
                      showEnabledValuesOnly={true}
                      showSearch={true}
                      treeDefaultExpandedKeys={['1', '2', '3', '4']}
                    />
                  ),

                }[searchBy]
              }
            </Col>

            {/* <Col sm={24} md={12}>
              <Link to={PATH_APP.whPartner.create}>
                <Button type="primary" style={{ float: 'right' }}>
                  {' '}
                  Thêm mới
                </Button> 
              </Link>
            </Col> */}
            </Row>
           
          </div>
          )}
        { !noShowSearchTime &&
        ( <div className='select-date'>
            <Form form={form} onValuesChange={onValuesChange}>
            <Row  justify={'space-between'} style={{width: '100%'}}>
              <Col flex={1}>
                <Row>
                      <Col span={12} >
                  <Form.Item
                      label={'Từ ngày'}
                      name="startDate"
                    >
                      <DatePicker
                        defaultValue={moment(date.startDate)}
                        onChange={(e) => setDate({ ...date, startDate: moment(e).format("YYYY-MM-DD") })}
                      />
                      
                  </Form.Item>
                      </Col>
                      <Col span={12}>
                  <Form.Item
                      label={'Đến ngày'}
                      name="endDate"
                  >
                      <DatePicker
                        defaultValue={moment(date.endDate)}
                          onChange={(e)=> setDate({...date,endDate: moment(e).format("YYYY-MM-DD")})}
                      />
                    </Form.Item>
                      </Col>
                </Row>
              </Col>
          <WithPermission permission={POLICIES.DOWNLOAD_WHVOUCHERS}>
              <Col span={12} align = 'end'>
                {!noShowExportButton &&
                  <ExportExcelButton
                    propsButton = {propsButton}
                  />}
              </Col>
            </WithPermission>
              </Row>
            </Form>
        </div>)}

        <Tabs onTabClick={onTabClick} defaultActiveKey="1" destroyInactiveTabPane onChange={() => {
          setSearchBy('billNumber')
          setKeyword('')
          !useLocalPagination &&  history.push({
      pathname: '/wh-voucher',
          });
        }}>
            {!noShowAtHome &&<TabPane tab="Phiếu thu (Đơn hàng đã chấm dứt)" key="3">
            <WhReceiptVouchers
              setArrIds = {setArrIds}
              whBill={whBill}
              modeCustomerCancel
              useLocalPagination={useLocalPagination}
              setPaging={setPaging}
              setQueryReceipt={setQueryReceipt}
              noShowTitle={noShowTitle}
              activeKey={activeKey}
              useTime = {!useTime}
              billNumber = {whBill?.billNumber}
              branchIds={whBill ? get(whBill,'branchId') : branchIds}
            />
          </TabPane>}
          <TabPane tab="Phiếu chi (Đơn hàng đã huỷ)" key={'5'}>
            <WhPaymentVouchersCancel
              setQueryPaymentCancel={setQueryPaymentCancel}
              useTime
              branchIds={whBill ? get(whBill,'branchId') : branchIds}
            />
          </TabPane>
          <TabPane tab="Phiếu thu" key="1">
            <WhReceiptVouchers
              setArrIds = {setArrIds}
              whBill={whBill}
              useLocalPagination={useLocalPagination}
              setPaging={setPaging}
              setQueryReceipt = {setQueryReceipt}
              noShowTitle={noShowTitle}
              activeKey={activeKey}
              useTime={!useTime}
              billNumber = {whBill?.billNumber}
              branchIds={whBill ? get(whBill,'branchId') : branchIds}
            />
          </TabPane>
          <TabPane tab="Phiếu chi" key="2">
            <WhPaymentVouchers
              setArrIds = {setArrIds}
              whBill={whBill}
              useLocalPagination={useLocalPagination}
              setPaging = {setPaging}
              setQueryPayment={setQueryPayment}
              noShowTitle={noShowTitle}
              useTime = {!useTime}
              billNumber = {whBill?.billNumber}
              branchIds={whBill ? get(whBill,'branchId') : branchIds}
            />
          </TabPane>
            {!noShowAllVoucher && <TabPane tab="Tổng phiếu thu - chi" key="4">
            <WhAllVoucher
              setArrIds = {setArrIds}
              whBill={whBill}
              useLocalPagination={useLocalPagination}
              // setPaging = {setPaging}
              setQueryAllVoucher = {setQueryAllVoucher}
              noShowTitle={noShowTitle}
              branchIds={whBill ? get(whBill,'branchId') : branchIds}
            />
          </TabPane>}
        </Tabs>
      </div>
    </div>
  );
};

export default WhVoucherTabs;
