import React, { useState, memo, useEffect, useCallback, useMemo } from 'react';
import {useHistory} from "react-router-dom"
import {
  Form,
  Select,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Skeleton,
  Cascader
} from 'antd';
import { filterAcrossAccents } from '~/hooks/utils';
import {
  useAppointment,
  useInitAppointment,
  useUpdateAppointment,
  useCreateAppointment,
  useResetAppointment
} from '~/hooks/appointment';
import moment from 'moment';
import dayjs from 'dayjs'
import { useHospitals, useHospitalAvailableTimes } from '~/hooks/hospital';
import {
  useBranch,
  useCities,
  useDistricts,
  useProfile,
  useSelectBranch,
  useUpdateAppointmentAssociate,
  useWhCustomersManagement
} from '~/hooks';
import { removeAccents } from '~/hooks/utils';
import { get, groupBy, rest, transform } from 'lodash';
const { Option } = Select;
const FormItem = Form.Item;
import POLICY from '~/components/Policy'
import { WithOrPermission } from '../Common';

const FORMAT_DATE = 'YYYYMMDD';

export const AssociateAppointmentUpdate = ({
  specialityOptions,
  appointmentId,
  onClose,
  initStartTime,
  branchId,
  handleOkUpdate,
  isOpen,
  handleUpdate,
  isSubmitLoading,
}) => {
  const [brandId] = useSelectBranch();
  const id = brandId;
  const [branchInfo, loading] = useBranch(id);
  const [form] = Form.useForm();
  const [profile] = useProfile();
  const [appointment, isLoading] = useAppointment(appointmentId);
  const [selectedDistrictCode, setSelectedDistrictCode] = useState(null);
  const [defaultDistrictId, setDefaultDistrictCode] = useState(null);
  const [selectedCityCode, setSelectedCityCode] = useState(null);
  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(selectedCityCode);
  const [selectedSpecialityId, setSelectedSpecialityId] = useState({
    specialityId: null,
    cityId: null,
    districtId: null
  });
  const [hospitals, isHospitalLoading] = useHospitals(selectedSpecialityId);
  const [valueSelectPartner, setValueSelectPartner] = useState('clinic');
  const [selectedHospitalId, setSelectedHospitalId] = useState(null);
  const [availableTimes, isAvailableTimesLoading] =
    useHospitalAvailableTimes(selectedHospitalId);
  const [specialityId, setSpecialityId] = useState('');
  const [optionsPharmacies, setOptionsPharmacies] = useState([]);
  const [valueDate, setValueDate] = useState('')
  const [valueTime, setValueTime] = useState('')

  const [phoneNumberSearch, setPhoneNumberSearch] = useState(null)
  const [customerId, setCustomerId] = useState(null)
  const queryFeatch = useMemo(() => ({
    page: 1,
    limit: 10,
    keyword: phoneNumberSearch
  }), [phoneNumberSearch]);
  const [customer, isLoadingCustomer] = useWhCustomersManagement(queryFeatch);

  const [initDateLocal, setInitDateLocal] = useState(null)

  // const filterOption = (input, option, handleClose) =>
  //   removeAccents(option.children.toLowerCase()).indexOf(input.toLowerCase()) >=
  //   0;

  const [availableTimesInDay, setAvailableTimesInDay] = useState([]);

  useEffect(() => {
    if (typeof availableTimes ==='object'&& get(appointment, 'date') ) {
      const _ = get(availableTimes, moment(get(appointment, 'date')).format("YYYYMMDD"), [])
      setAvailableTimesInDay(_)
      setValueDate({ _d: new Date(get(appointment, 'date')) })
    };
  }, [availableTimes,appointment]);
  useResetAppointment();

  const createSuccesCallback = () => {
    form.resetFields();
    onClose();
  };
  const [, handleCreate] = useCreateAppointment(createSuccesCallback);
  const initAppointment = useInitAppointment(
    form,
    appointment,
    appointmentId,
    initStartTime,
    setSelectedHospitalId,
    setSelectedSpecialityId
  );
  //Set availibleTimesInDay when exit hospital

  useEffect(() => {
    const getDateLocal  = form.getFieldValue('dateLocal')
    setAvailableTimesInDay(availableTimes[getDateLocal?.format(FORMAT_DATE)]);
  }, [appointmentId, availableTimes])
  //Find pharmacies exit specialityId
  useEffect(() => {
    let newPharmacies = (hospitals??[]).map((item) => {
      item.speciality.filter((itemss) => itemss._id === specialityId);
      return item;
    });
    const timeExitInForm = form.getFieldValue('dateLocal');
    if (timeExitInForm) {
      const getDayOfWeek = new Date(timeExitInForm).getDay();
      const filterIsOpen = newPharmacies.filter((clinic) => {
        let checkTime = true
        if (valueTime) {
          const startTime = clinic?.workingHours[getDayOfWeek]?.startTime
          const startDate = new Date(`2000-01-01T${startTime}:00Z`);
          const endTime = clinic?.workingHours[getDayOfWeek]?.endTime
          const endDate = new Date(`2000-01-01T${endTime}:00Z`);
          const currentDate = new Date(`2000-01-01T${valueTime}:00Z`)
          if (startDate <= currentDate && endDate >= currentDate) {
            checkTime = true;
          } else {
            checkTime = false;
          }
        };

        return clinic?.workingHours[getDayOfWeek]?.isOpen === true && checkTime;
      });
      newPharmacies = filterIsOpen;
    };

    const filterByCity = groupBy(newPharmacies, 'address.city');
    const setNameOptions = transform(
      filterByCity,
      (result, value, key) => {
        result.push({
          label: key.toString(),
          value: key.toString(),
          children: value
        });
      },
      []
    );

    const result = (setNameOptions??[]).map((item) => {
      const filterByDistrict = groupBy(item.children, 'address.district');

      const handleOptions = transform(
        filterByDistrict,
        (result, values, key) => {
          const newV = values.map((el)=>({value:el?._id,label:el.name}))
          result.push({
            label: key.toString(),
            value: key.toString(),
            children: newV
          });
        },
        []
      );
      return {
        label: item.label.toString(),
        value: item.value.toString(),
        children: handleOptions
      };
    });
  
      setOptionsPharmacies(result);
  }, [hospitals, specialityId, appointmentId, valueDate,valueTime, selectedHospitalId]);
  
  // Set value for form when appointment is exited
  useEffect(() => {
    // Find name branch pharmacy
    if (!!appointment) {
      setSelectedSpecialityId({
        specialityId: get(appointment, 'speciality.id'),
        cityId: get(appointment, 'address.cityId'),
        districtId: get(appointment, 'address.districtId')
      });
      form.setFieldsValue(appointment)
      form.setFieldsValue({
        branch: get(appointment, 'branch.name', ''),
        type: get(appointment, 'type'),
        specialityId: get(appointment, 'speciality._id'),
        dateLocal: moment(get(appointment, 'date')),
        date_: moment(get(appointment, 'date')).format('HH:mm'),
        hospitalId:  appointment?.hospitalId ?  [get(appointment, 'hospital.address.city', ''),
        get(appointment, 'hospital.address.district', ''),
        get(appointment, 'hospital._id', '')] : null,
        address: {
          cityId: !get(appointment, 'hospitalId') ? get(appointment, 'address.cityId') : get(appointment, 'hospital.address.cityId'),
          districtId:!get(appointment, 'hospitalId') ? get(appointment, 'address.districtId') : get(appointment, 'hospital.address.districtId'),
        },
      });
      setSelectedCityCode(get(appointment, 'address.cityId'));
      setSelectedDistrictCode(get(appointment, 'address.districtId'));
      setInitDateLocal(moment(get(appointment, 'date')))
      setSelectedHospitalId(get(appointment, 'hospital.id'))
      setSpecialityId(get(appointment, 'speciality.id'))
      return
    } else {
      form.resetFields();
      const setHospitalId = branchInfo?._id === 99999 ?[ get(branchInfo, "name.vi")] : [
        get(branchInfo, 'address.city'),
        get(branchInfo, 'address.district'),
        get(branchInfo, '_id','').toString()
      ]
      if (valueSelectPartner === 'clinic') {
        form.setFieldsValue({
          address: {
          city: get(branchInfo, 'address.city'),
          cityId: get(branchInfo, 'address.cityId'),
          district: get(branchInfo, 'address.district'),
          districtId: get(branchInfo, 'address.district'),
          },
          hospitalId: setHospitalId,
          dateLocal: null,
          // date: null
        });
        const getHospitalId = get(appointment, 'hospitalId.id', null);
        getHospitalId ? setSelectedHospitalId(getHospitalId): setSelectedHospitalId(null)
        // setSelectedHospitalId(get(branchInfo, '_id'));
        setDefaultDistrictCode(get(branchInfo, 'address.districtId'))

      } else {
        form.setFieldsValue({
          address: {
            cityId: null,
            districtId: null
          },
          hospitalId: null,
          dateLocal: null,
          date: null
        });
      }
      return
    }
  }, [appointment, valueSelectPartner, isOpen, onClose]);

  const filterOptionNumberSearch = useCallback(() => {
    let options = []
    const newOption = customer.map((item) => ({
      value: item?.phoneNumber.replace('+84', '0'),
      label: `${item?.phoneNumber.replace('+84', '0')} - ${item?.fullName}`,
      _id: item?._id || '',
      fullName: item.fullName
    }))
    const optionDefault = {
      value: phoneNumberSearch,
      label: phoneNumberSearch
    }
    const findCustomer = newOption.some((item) => item.phoneNumber !== phoneNumberSearch)
    !findCustomer ? options = [...newOption, optionDefault] : options = [...newOption]
    return options
  }, [customer, phoneNumberSearch]);

  // Default the value when the form changes
  const onValuesChange = ({
    specialityId,
    hospitalId,
    dateLocal,
    cityId,
    address,
    districtId,
  }) => {
    if (specialityId) {
      setSelectedSpecialityId({
        specialityId,
        cityId: selectedCityCode,
        districtId: selectedDistrictCode
      })
      if (!appointmentId) {
        form.setFieldsValue({
          hospitalId: [],
          dateLocal: null,
          date_: null,
        })
      } else {
        form.setFieldsValue({
          hospitalId: null,
        })
      }
    
      // switch (valueSelectPartner) {
      //   case 'clinic':
      //     break;
      //   case 'partner':
      //     setSelectedSpecialityId({
      //       specialityId,
      //       cityId: selectedCityCode,
      //       districtId: selectedDistrictCode
      //     }),
      //       form.setFieldsValue({
      //         hospitalId: null,
      //         dateLocal: null,
      //         date: null,
      //         name: null
      //       })
      //     break;
      //   default:
      // }
    }
    if (hospitalId) {

      if (!appointmentId) {
          setSelectedHospitalId(hospitalId.at(-1))
          form.setFieldsValue({
            dateLocal: null,
            date_: null
          })
      } else {
        setSelectedHospitalId(hospitalId.at(-1))
      }
    }
    if (dateLocal) {
      setAvailableTimesInDay(availableTimes[dateLocal.format(FORMAT_DATE)]);
      form.setFieldsValue({
        date_: null
      });
    };
    if (address) {
      setSelectedSpecialityId({
        specialityId: form.getFieldValue('specialityId'),
        cityId: form.getFieldValue(['address','cityId']),
        districtId: form.getFieldValue(['address','districtId']),
      })
        form.setFieldsValue({
          hospitalId: null,
          // specialityId: null,
        });
      if (address.cityId) {
        form.setFieldsValue({ address:{districtId: null}})
      }
      else { 
      return
      };
    };

  };

  const onFinish = (values) => {
    const { address, hospitalId, branch, date_, ...rest } = values;
    const localTime = values.dateLocal.format('YYYY-MM-DD') + 'T' + values.date_;
    const utcTime = moment(localTime, 'YYYY-MM-DDTHH:mm');
    const setHospitalId = hospitalId?.length ? '' : hospitalId.at(-1)
    if (appointmentId) {
      const {type, ...rests} = {...rest}
      handleUpdate({
        ...rests,
        address: {
          cityId: selectedCityCode,
          districtId: selectedDistrictCode
        },
        hospitalId: setHospitalId ,
        date: utcTime,
        id: appointmentId,
        makeByWorldhealth: "WH",
      });
      setPhoneNumberSearch(null);
      handleOkUpdate(false);
    };
    // else {
    //   handleCreate({
    //     ...values,
    //     address: {
    //       ...address,
    //       districtId:defaultDistrictId
    //     },
    //     // hospitalId: setHospitalId,
    //     branchId,
    //     date: utcTime,
    //     makeByWorldhealth: "WH",
    //   });
    //   setPhoneNumberSearch(null)
    // }
  };

  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  const displayRender = (labels) => labels[labels.length - 1];
  
  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      className="appointment-form"
      requiredMark={false}
      initialValues={initAppointment}
      onValuesChange={onValuesChange}
    >
        {!appointmentId 
          ?
         <Row gutter={36}>
          <Col span={12}>
            <FormItem label="Tạo lịch hẹn cho ai?" name={'type'}>
              {renderInput(
                (<Select
                  onSelect={(value) => setValueSelectPartner(value)}
                  defaultValue={valueSelectPartner}
                >
                  {/* <Option value="worldhealth" key="worldhealth">
                    Worldhealth
                  </Option> */}
                  <Option value="clinic" key="clinic">
                    Phòng khám của bạn
                  </Option>
                    <Option value="partner" key="partner">
                      Đối tác
                    </Option>
                </Select>)
              )}
        
            </FormItem>
          </Col>
          </Row>
        : 
        <Row gutter={36}>
          <Col span={24}>
            <FormItem label="Nguồn" name={'branch'}>
              {renderInput(
                <Input />
              )}
            </FormItem>
            </Col>
          </Row>
        }
      <Row gutter={36}>
        <Col span={12}>
          <FormItem
            label="Tên Khách hàng"
            name={'customerName'}
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập tên!'
              }
            ]}
          >
            {renderInput(<Input />)}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label="Số điện thoại"
            name={'phoneNumber'}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[0-9]{10,11}$/),
                message: 'Xin vui lòng nhập đúng số điện thoại!'
              }
            ]}
          >
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
                <Select
                showSearch
                placeholder="Nhập số điện thoại để tìm"
                optionFilterProp="children"
                maxLength={11}
                  filterOption={(input, option) => {
                    return (option?.label ?? '').toLowerCase()?.includes(input.toLowerCase())
                  }
                }
                  options={filterOptionNumberSearch()}
                onSearch={(e) => {
                  setPhoneNumberSearch(e)
                }}
                  onChange={(e, el) => {
                    el.fullName ? form.setFieldsValue({ customerName: el.fullName}) : ()=>{},
                    setCustomerId(el._id)
                  }}
                value={phoneNumberSearch}
              />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={48} align="middle" justify="space-between">
        <Col span={12}>
          <FormItem
            label="Thành Phố/Tỉnh"
            name={['address', 'cityId']}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
            //   }
            // ]}
          >
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
              <Select
              allowClear
                onChange={(value) => setSelectedCityCode(value)}
                disabled={isCitiesLoading}
                loading={isCitiesLoading}
                showSearch
                autoComplete="off"
                  filterOption={filterAcrossAccents}
              >
                {cities.map(({ code, name }) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label="Quận/Huyện"
            name={['address', 'districtId']}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Xin vui lòng chọn Quận/Huyện!'
            //   }
            // ]}
          >
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
                <Select
                allowClear
                loading={isDistrictsLoading}
                disabled={!form.getFieldValue(['address', 'cityId'])}
                  onChange={(value) => {
                    setSelectedDistrictCode(value)
                  }}
                showSearch
                autoComplete="off"
                filterOption={filterAcrossAccents}
              >
                  {districts.map(({ code, name }) => {
                  return  (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    )
                  })}
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={36}>
        <Col span={12}>
          <FormItem
            label="Dịch vụ"
            name="specialityId"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng chọn dịch vụ!'
              }
            ]}
          >
            {renderInput(
              <Select onChange={(value) => setSpecialityId(value)} showSearch>
                {specialityOptions}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem noStyle shouldUpdate>
            {() => (
              <FormItem
                label="Ngày"
                name="dateLocal"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn ngày!' ,
                  },
                
                ]}
                shouldUpdate
              >
                {renderInput(
                  <DatePicker
                    onChange={(value) => setValueDate(value)}
                    allowClear={false}
                    format="DD-MM-YYYY"
                    span={12}
                    loading={isAvailableTimesLoading}
                    // disabled={(!form.getFieldValue('hospitalId'))}
                    disabledDate={(current) =>
                    {
                      return selectedHospitalId ? ( current && !availableTimes[current.format(FORMAT_DATE)]) : ( current < dayjs().endOf('day') && !availableTimes[current?.format(FORMAT_DATE)])
                    }
                    }
                  />
                )}
              </FormItem>
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={36}>

        <Col span={12}>
          <FormItem noStyle shouldUpdate >
            {() => (
              <FormItem
                label="Giờ"
                name="date_"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn giờ!',
                  },
                ]}
              >
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                    loading={isHospitalLoading}
                    disabled={!form.getFieldValue('dateLocal')}
                    onChange={(e) => 
                      setValueTime(e)
                  }
                  >
                  {availableTimesInDay &&
                    (availableTimesInDay??[]).map((time) => {
                        const currentDate = new Date();
                        const optionTime = valueDate ? new Date((valueDate?._d)?.toDateString() + ' ' + time) : ()=>{}
                        const isDisabled = optionTime < currentDate;
                       return (
                      <Option disabled = {isDisabled} value={time} key={time}>
                        {time}
                      </Option>
                       )
                    }
                    )}
                  </Select>
              </FormItem>
            )}
          </FormItem>
        </Col>

        <Col span={12}>
            <FormItem noStyle shouldUpdate>
              {() =>
              (<FormItem
                label="Phòng khám"
                name="hospitalId"
                dependencies={optionsPharmacies}
                // rules={
                //   [
                //     {
                //       required: form.getFieldValue("type") === 'clicic' ? false : true,
                //       message: 'Xin vui lòng chọn phòng khám!'
                //     }
                //   ]}
              >
                {renderInput(
                  <Cascader
                    disabled={
                      !form.getFieldValue('specialityId')
                    }
                    options={optionsPharmacies}
                    expandTrigger="hover"
                    displayRender={displayRender}
                    // onChange = {(value)=>{setSelectedHospitalId(value)}}
                  />
                )}
              </FormItem>)
              }
            </FormItem>
          </Col>
      </Row>
      <Row gutter={36}>
        <Col span={12}>
          <FormItem label="Nguồn" name="source">
            {renderInput(
              <Select>
                <Option value="CALL" key="CALL">
                  Gọi điện
                </Option>
                <Option value="WEBSITE" key="WEBSITE">
                  Website
                </Option>
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem label="Trạng thái" name="status">
            {renderInput(
              <Select>
                <Option value="CREATED" key="CREATED">
                  Đã tạo
                </Option>
                <Option value="CONFIRMED" key="CONFIRMED">
                  Đã xác nhận
                </Option>
                <Option value="CANCEL" key="CANCEL">
                  Đã huỷ
                </Option>
                <Option value="ARRIVED" key="ARRIVED">
                  Đã hoàn thành
                </Option>
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <FormItem name="message" label="Lời nhắn">
            <Input.TextArea rows={3}></Input.TextArea>
          </FormItem>
        </Col>
      </Row>

      <Row justify="end">
        <Button style={{ marginRight: 20 }} onClick={onClose}>
          Huỷ
        </Button>
        <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
          {appointmentId ? 'Cập nhật' : 'Tạo mới'}
          </Button>
      </Row>
    </Form>
  );
};

export default memo(AssociateAppointmentUpdate);
