import { Badge, Table, Tabs, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import api from '~/api';
import LinkFile from '~/components/Common/TypeFile/LinkFileImage';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { WH_COURSE_TRAINING_STATUS, WH_COURSE_TRAINING_STATUS_VI } from '~/constants/defaultValue';
import { useProfile } from '~/hooks';
import {
  useLogCourseTrainingOfCourse,
  useResetTimeSheet,
  useTimeSheetPaging,
  useTimeSheetQueryParams,
  useTimeSheets,
  useUpdateTimeSheet
} from '~/hooks/timeSheet';
import { formatNumberThreeComma } from '~/hooks/utils';
import DetailCorseOfTimeTraining from './DetailCorseOfTimeTraining';
import WhPartnerTimeTrainingDetails from './WhPartnerTimeTrainingDetails';
const { TabPane } = Tabs;
const WhPartnerExpanded = ({
  record,
  updateTimeSheet,
  loadingSubmit,
  userId,
  isLoadingTimeSheet,
  logTimeSheet
}) => {
  return (
    <Tabs defaultActiveKey="1" destroyInactiveTabPane onChange={() => {}}>
      <TabPane tab="Thông tin chi tiết" key="1">
        <WhPartnerTimeTrainingDetails
          logTimeSheet={logTimeSheet}
          isLoadingTimeSheet={isLoadingTimeSheet}
          userId={userId}
          updateTimeSheet={updateTimeSheet}
          loadingSubmit={loadingSubmit}
          record={record}
        />
      </TabPane>
      <TabPane tab="Chi tiết khoá học" key="2">
        <DetailCorseOfTimeTraining
          record={record}
          dataSource={get(record, 'course', [])}
        />
      </TabPane>
    </Tabs>
  );
};
export default function TimeTraining({ onParamChange, searchBy }) {
  const [query] = useTimeSheetQueryParams(searchBy);
  const [profile] = useProfile();
  const [itemActive, setItemActive] = useState(null);
  const [logTimeSheet, isLoadingTimeSheet] =
    useLogCourseTrainingOfCourse(itemActive);
  useResetTimeSheet();
  const [loadingSubmit, updateTimeSheet] = useUpdateTimeSheet();
  const [timeSheets, isLoading] = useTimeSheets(query);
  const paging = useTimeSheetPaging(query);
  const columns = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'partner',
      key: 'partner',
      render: (text, record) => `${get(text, 'partnerCode','')}`
    },
    {
      title: 'Mã khoá đào tạo',
      dataIndex: 'course_training',
      key: 'course_training',
      render: (text, record) => get(text, 'courseIdFull', '')
    },
    {
      title: 'Tên',
      dataIndex: 'partner',
      key: 'partner',
      render: (text, record) => get(text, 'name')
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'partner',
      key: 'partner',
      render: (text, record) => get(text, 'phone')
    },
    {
      title: 'Nhóm đối tác',
      dataIndex: 'employee_group',
      key: 'employee_group',
      render: (text, record) => get(text, 'name.vi')
    },
    {
      title: 'Tổng số giờ',
      dataIndex: 'totalHours',
      key: 'totalHours',
      render: (text, record) => <span>{text}</span>
    },
    {
      title: 'Số tiền thực nhận',
      dataIndex: 'totalReceived',
      key: 'totalReceived',
      render: (text, record) => {
        return !isNaN(
        text
        )
          ? formatNumberThreeComma(text)
          : `0 (Chưa có Rate Đào tạo)`;
      }
    },
    {
      title: 'File đính kèm',
      dataIndex: 'files',
      key: 'files',
      render: (text, record, index) => (
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'start'
          }}
        >
          {text?.map((e) => {
            return (
              <LinkFile href={get(e, 'response.url')} url={get(e, 'name')} />
            );
          })}
        </div>
      )
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record, index) => {
        const hasDeny = get(record,'course',[]).some(e => get(e,'status','') === WH_COURSE_TRAINING_STATUS.DENIED)
        if(hasDeny){
          return  <Tooltip color='black' title='Có khoá đào tạo đã bị từ chối'>
            <Badge dot>
            <span className={`course-status ${text}`}>
          {WH_COURSE_TRAINING_STATUS_VI[text] || ''}
        </span>
          </Badge>
          </Tooltip>
        }
        return (
          <span className={`course-status ${text}`}>
            {WH_COURSE_TRAINING_STATUS_VI[text] || ''}
          </span>
        )
      }
    },
    {
      title: 'NV chịu trách nhiệm',
      dataIndex: 'user',
      key: 'user',
      render: (text, record) => get(text, 'fullName')
    }
  ];
  return (
    <div className="container-fluid">
      {isLoading ? (
        <SkeletonTable rowCount={10} columns={columns} />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          dataSource={timeSheets}
          onChange={({ current, pageSize }) => onParamChange({ page: current, limit: pageSize })}
          scroll={{ x: 'auto' }}
          size="middle"
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
          onExpand={(expanded, record) => {
            expanded ? setItemActive(record._id) : setItemActive(null);
          }}
          expandable={{
            expandedRowRender: (record) => (
              <WhPartnerExpanded
                logTimeSheet={logTimeSheet}
                isLoadingTimeSheet={isLoadingTimeSheet}
                userId={get(profile, '_id')}
                record={record}
                loadingSubmit={loadingSubmit}
                updateTimeSheet={updateTimeSheet}
              />
            ),
            expandedRowKeys: [itemActive]
            //   rowExpandable: (record) => status !== WH_PARTNER_STATUS.WAITING
          }}
        />
      )}
    </div>
  );
}
