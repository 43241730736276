import { Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import api from '~/api';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { ORDER_STATUS_NAME, PAYMENT_METHOD } from '~/constants/defaultValue';
import './index.scss';
import toastr from 'toastr';
import { formatNumberThreeComma } from '~/hooks/utils';
import { compact, get } from 'lodash';

export default function WhOrder({ id, keyword, statusOrder }) {
  //Handle when data is not yet exits
  const customerId = id;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  //Handle when data is exits
  const [paging, setPaging] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const query = useMemo(
    () => ({
      page: page || 1,
      limit: limit || 10,
      customerId: customerId,
      keyword: keyword || null,
      status: statusOrder || null
    }),
    [customerId, page, limit,keyword,statusOrder]
  );
  const getData = async () => {
    setIsLoading(true);
    const res = await api.order.getByIdCustomer(query);
    const data = res?.docs;
    setData(data);
    setPaging({
      current: res?.page,
      pageSize: res?.limit,
      total: res?.totalDocs
    });
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, [id,  page, limit,keyword, statusOrder]);

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      dataIndex: 'stt',
      render: (text, record, index) => {
        return  (paging?.current - 1) * paging?.pageSize +  index + 1;
      }
    },
    {
      title: 'Mã đơn hàng',
      key: 'orderNumber',
      dataIndex: 'orderNumber',
      render: (value)=> value
    },
    {
      title: 'Nhà thuốc',
      key: 'branch',
      dataIndex: 'branch',
      render: (value)=> value?.name
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value)=> moment(value)?.format('HH:mm DD/MM/YYYY')
    },
    {
      title: 'Giá trị đơn hàng',
      key: 'subTotal',
      dataIndex: 'subTotal',
      render: (value)=> formatNumberThreeComma(value || 0)
    },
    {
      title: 'Phí vận chuyển',
      key: 'shippingFee',
      dataIndex: 'shippingFee',
      render: (value)=> formatNumberThreeComma(value || 0)
    },
    {
      title: 'Giảm giá',
      key: 'discountAmount',
      dataIndex: 'discountAmount',
      render: (value)=> formatNumberThreeComma(value || 0)
    },
    {
      title: 'VAT',
      key: 'vatAmount',
      dataIndex: 'vatAmount',
      render: (value)=> formatNumberThreeComma(value || 0)
    },
    {
      title: 'Tổng đơn hàng',
      key: 'grandTotal',
      dataIndex: 'grandTotal',
      render: (value)=> formatNumberThreeComma(value || 0)
    },
    {
      title: 'Hình thức thanh toán',
      key: 'paymentStatus',
      dataIndex: 'paymentStatus',
      render: (value)=> PAYMENT_METHOD[value]
    },
    {
      title: 'Trạng thái đơn hàng',
      key: 'status',
      dataIndex: 'status',
      render: (value) => {
        return (
          <span className={`wh-order-status ${(value)?.toLowerCase()}`}>
          {ORDER_STATUS_NAME[value]}
        </span>)
      }
    },
    {
      title: 'Địa chỉ nhận hàng',
      dataIndex: 'shippingAddress',
      // key: 'shippingAddress',
      render: (value) => {
        return compact([value?.street, value?.ward, value?.district, value?.city]).join(', ')
      }
    },
    {
      title: 'Đơn vị vận chuyển',
      dataIndex: 'shippingInfo',
      key: 'shippingVendor',
      render: (value, record) => {
        return value?.shippingVendor
      }
    },
      {
      title: 'Người vận chuyển',
      dataIndex: 'shippingInfo',
      key: 'shipperName',
        render: (value) => {
        return value?.shipperName
      }
    },
      {
      title: 'SĐT người vận chuyển',
      dataIndex: 'shippingInfo',
      key: 'shipperPhoneNumber',
      render: (value)=> value?.shipperPhoneNumber
    },
    {
      title: 'Mã theo dõi đơn hàng',
      dataIndex: 'shippingInfo',
      key: 'trackingId',
      render: (value)=> value?.trackingId
    },
  ];
  return isLoading ? (
    <SkeletonTable columns={columns} rowCount={3} />
  ) : (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{ ...paging, showTotal: (total) => `Tổng cộng ${total}` }}
        size="small"
        onChange={({current,pageSize}) => {
          setPage(current);
          setLimit(pageSize);
        }}
        scroll={{ x: 'max-content' }}
    />
  );
}
