import requester from "./requester";

const notificationBotManagement = {

  getNotifyAccounts: () => requester.get('/notify-account'),
  updateNotifyAccounts: (newNotifyAccounts) => requester.post('/update-notify-account', newNotifyAccounts),


  
  
}

export default notificationBotManagement;
