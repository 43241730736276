export const STATUS_WAREHOUSE_VOUCHER = {
    NEW: 'NEW',
    CONFIRM: 'CONFIRM',
    REJECT : 'REJECT',
  };
export const STATUS_CONFIRM_WAREHOUSE_VOUCHER = {
    NEW: 'NEW',
    CONFIRM: 'CONFIRM',
    REJECT : 'REJECT',
  };
export const STATUS_WAREHOUSE_VOUCHER_VI = {
    NEW: ' Mới',
    CONFIRM: 'Đã xác nhận',
    REJECT : 'Đã từ chối',
  }
