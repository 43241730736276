import * as Types from '../../constants/actionTypes';

export const addOrderSelect = (query) => ({
    type: Types.ADD_ORDER_SELECT,
    payload : query
  });
export const addUserSelect = (query) => ({
    type: Types.ADD_USER_SELECT,
    payload : query
  });
export const addAddressSelect = (query) => ({
    type: Types.ADD_ADDRESS_SELECT,
    payload : query
  });
export const resetStoreQuotation = () => ({
    type: Types.RESET_STORE,
  });
export const addQueryFetch = (query) => ({
    type: Types.ADD_QUERY_FETCH,
    payload : query
  });
export const addAllQueryFetch = (query) => ({
    type: Types.ADD_ALL_QUERY_FETCH,
    payload : query
  });



  
  