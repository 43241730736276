import produce from 'immer';
import {
  TOGGLE_THEME,
  TOGGLE_THEME_SUCCESS,
  TOGGLE_THEME_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_REQUEST,
  LOGIN_WORKSPACE_REQUEST,
  LOGIN_WORKSPACE_SUCCESS,
  LOGIN_WORKSPACE_FAILED,
  LOGOUT_WORKSPACE_REQUEST,
  SELECT_BRANCH,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILED,
  GET_USER_POLICY_REQUEST,
  GET_USER_POLICY_SUCCESS,
  GET_USER_POLICY_FAILED,
  RESET_STORE,
  GET_DATA_FOR_NAVBAR,
  PINED_NAVBAR,
  CHANGE_BRANCH_ID_SESSION,
  SUBSCRIBE_FCM_FIREBASE_SUCCESS,
} from '~/constants/actionTypes';

import {
  removeAxiosToken,
  setAxiosCompanyId,
  setAxiosToken
} from '~/api/requester';
import { subscribeToken, unSubscribeToken } from '~/hooks';

const INIT_STATE = {
  isLoading: false,
  darkMode: false,
  error: undefined,

  token: null,
  loginFailed: null,
  branchId: null,

  profile: [],
  isGetProfileLoading: false,
  getProfileFailed: null,

  policy: null,
  isGetPolicyLoading: false,
  getPolicySuccess: null,
  getPolicyFailed: null,
  dataNavbar:[],

  branchIdSession:null,

  tokenFcm:null,
  accessKeyPined:'worldhealth',
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case TOGGLE_THEME:
      state.isLoading = true;
      return;

    case TOGGLE_THEME_SUCCESS:
      state.darkMode = !state.darkMode;
      state.isLoading = false;
      return;

    case TOGGLE_THEME_FAILED:
      state.error = payload;
      state.isLoading = false;
      return;

    case LOGIN_REQUEST:
      state.isLoading = true;
      state.token = null;
      state.loginFailed = null;
      return;

    case LOGIN_SUCCESS:
      state.token = payload.token;
      state.profile = payload.profile;
      state.branchId = payload.branchId + '';
      setAxiosToken(payload.token);
      return;

    case SUBSCRIBE_FCM_FIREBASE_SUCCESS:
      state.tokenFcm = payload;
      subscribeToken(payload)
      return;


    case LOGIN_FAILED:
      state.loginFailed = payload;
      state.isLoading = false;
      return;

    case LOGOUT_REQUEST:
      unSubscribeToken();
      removeAxiosToken();
      localStorage.clear();
      sessionStorage.clear();
      return INIT_STATE;

    case LOGIN_WORKSPACE_REQUEST:
      state.isLoading = true;
      state.token = null;
      state.loginFailed = null;
      return;

    case LOGIN_WORKSPACE_SUCCESS:
      state.token = payload.token;
      state.profile = payload.profile;
      state.branchId = payload.branchId + '';
      setAxiosToken(payload.token);
      return;

    case LOGIN_WORKSPACE_FAILED:
      state.loginFailed = payload;
      state.isLoading = false;
      return;

    case LOGOUT_WORKSPACE_REQUEST:
      removeAxiosToken();
      localStorage.clear();
      sessionStorage.clear();
      return INIT_STATE;

    case SELECT_BRANCH:
      state.branchId = payload;
      setAxiosCompanyId(payload);

    case GET_PROFILE_REQUEST:
      state.isGetProfileLoading = true;
      state.getProfileFailed = null;
      return;

    case GET_PROFILE_SUCCESS:
      state.isGetProfileLoading = false;
      state.profile = payload;
      return;

    case GET_PROFILE_FAILED:
      state.isGetProfileLoading = false;
      state.getProfileFailed = payload;
      return;

    case GET_USER_POLICY_REQUEST:
      state.isGetPolicyLoading = true;
      state.getPolicyFailed = null;
      return;

    case GET_USER_POLICY_SUCCESS:
      state.isGetPolicyLoading = false;
      const policy = Object.keys(payload).reduce((dict, policiKey) => {
        const policyDict = payload[policiKey].reduce(
          (permissionDict, permision) => {
            return {
              ...permissionDict,
              [permision]: true
            };
          },
          {}
        );

        return {
          ...dict,
          [policiKey]: policyDict
        };
      }, {});

      const shouldReload = !!Object.keys(state.policy || {}).length;
      if (shouldReload) {
        state.getPolicySuccess = policy;
      }

      state.policy = { ...policy };

      return;

    case GET_USER_POLICY_FAILED:
      state.isGetPolicyLoading = false;
      state.getPolicyFailed = payload;
      return;

    case GET_DATA_FOR_NAVBAR:
      state.dataNavbar = payload;
      return;

    case CHANGE_BRANCH_ID_SESSION:
      state.branchIdSession = payload;
      return;

    case PINED_NAVBAR:
      state.accessKeyPined = payload;
      return;
      
    case CHANGE_BRANCH_ID_SESSION:
      state.branchIdSession = payload;
      return;

    case RESET_STORE:
      state.isGetPolicyLoading = false;
      state.isLoading = false;
      state.isGetProfileLoading = false;
      state.getPolicySuccess = null;

    default:
      return;
  }
}, INIT_STATE);
