import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, BreadcrumbItem } from 'reactstrap';

const Breadcrumb = ({ routes = [], title }) => {
  return (
    <Row className="page-wraper__title">
      <Col className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h1 className="mb-0">{title}</h1>

          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="/">Worldcare</Link>
              </BreadcrumbItem>
              {routes.map(({ path, title }, index) => (
                <BreadcrumbItem
                  key={index}
                  active={index === routes.length - 1}
                >
                  <Link to={path}>{title}</Link>
                </BreadcrumbItem>
              ))}

              <BreadcrumbItem>
                <Link to="#">{title}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
