import React, { useEffect } from 'react';

import { Button, Col, Form, Input, InputNumber, Row, Select, Skeleton } from 'antd';
import { Link } from 'react-router-dom';

import { PATH_APP } from '~/routes/paths';
import {   useGetEmployeeGroupWhWorkNormsFetch } from '~/hooks';
import { INSTANCE_STATUSES } from '~/constants/defaultValue';
import { formatNumberThreeComma } from '~/hooks/utils';
import { get } from 'lodash';

const FormItem = Form.Item;
const { Option } = Select;

const WhWorkNormForm = ({
  initialValues,
  onClose,
  update,
  loading,
  handleCreate,
  Norms
}) => {
  const [form] = Form.useForm();

  const [EmployeeGroup,isLoading] = useGetEmployeeGroupWhWorkNormsFetch()
  useEffect(() => {
    form.resetFields();
    if (!!initialValues) {
        form.setFieldsValue(initialValues)
        form.setFieldsValue({minimumNorm : 0})
    }
  }, [initialValues, form])

  const onFinish = (values) => {
    const { employeeGroupId,maximumRating,minimumNorm } = values;
    const submitData = {
      employeeGroupId,
      id : employeeGroupId,
      JobQuota: {
          JobQuotaDetail: {
              maximumRating,
              minimumNorm,
              state : initialValues ? initialValues.state : INSTANCE_STATUSES.ACTIVE
          }
      }
  }
  if(!!initialValues){
    update(submitData)
  }
  else{
    handleCreate(submitData)
  }
  }

  return (
    <div className="table-ranking page-wraper hospital-form-page-content">
      <div className="container-fluid">
        <Form
          autoComplete="off"
          form={form}
          initialValues={initialValues || {}}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          onFinish={onFinish}
          requiredMark={false}
          scrollToFirstError
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <h4 style={{margin: '20px 0px 40px 0px'}}>{initialValues?.employeeGroupId ? ' Cập nhật' : 'Thêm mới'}</h4>
          <Col span={24}>
            <FormItem
              label="Nhóm nhân viên"
              name="employeeGroupId"
              rules={[
                {
                  required: true,
                  message: 'Xin vui lòng chọn nhóm nhân viên'
                }
              ]}

            >
              {false ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  // filterOption={filterAcrossAccents}
                  loading={isLoading}
                  autoComplete="off"
                  placeholder="Nhóm nhân viên"
                  showSearch
                  style={{ width: '100%' }}
                  disabled={!!initialValues}
                >
                  {EmployeeGroup?.filter(e =>!initialValues ? !Norms?.some(f => get(f,'_id') === get(e,'_id')) : true)?.map(({ id, _id, name }) => (
                    <Option key={id || _id} value={id || _id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>

          <FormItem
            label="Định mức tối đa"
            name="maximumRating"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập định mức tối đa'
              }
            ]}
          >
            <InputNumber style={{width : '200px'}} formatter={value => formatNumberThreeComma(value)}/>
          </FormItem>

          {/* <FormItem
            label="Định mức tối thiểu"
            name="minimumNorm"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Xin vui lòng nhập định mức tối thiểu'
            //   }
            // ]}
          >
            <Input disabled/>
          </FormItem> */}

          <Row className="form__submit-box">
            {false ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.whWorkNorm.root}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}

            <Button loading={loading} Button type="primary" htmlType="submit" >
              {initialValues ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default WhWorkNormForm;
