import ErrorBase from "~/utils/ErrorBase";

export class CannotEmptyListProduct extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Danh sách sản phẩm trống";
    }
}
export class CannotEmptyPaymentDate extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Không được để trống ngày nhận hoặc trả";
    }
}

export class productIsDisabled extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Sản phẩm đã ngưng sử dụng";
    }
};
export class productIsDelivered extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Sản phẩm đã được bàn giao";
    }
};
export class partnerIsOwner extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Sản phẩm là của đối tác đang chọn";
    }
};
export class NotSameSupplier extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Không cùng chủ sở hữu đã chọn";
    }
};

