import React, { useEffect, useMemo, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { FilterDatePicker, WithPermission } from '~/components/Common';
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Tabs,
  Table,
  Modal,
  Upload,
  message,
  Popconfirm,
  Menu,
  Dropdown
} from 'antd';

import { useSpecialityOptions } from '~/hooks/speciality';
import api from '~/api';
import MenuAction from './MenuAction';
import {  useAppointmentAssociatePaging, useAppointmentAssociateQueryParams, useAppointmentsAssociate, useAssociateQueryParams, useDeleteAppointment, useDeleteAppointmentAssociate, useMatchPolicy, useProfile, useUpdateAppointmentAssociate } from '~/hooks';
import { get, groupBy, transform } from 'lodash';
import moment from 'moment';
import { APPOINTMENT_STATUS, MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import POLICY from '~/constants/policy';
import { AppointmentIsSytemForm } from './IsSystemAdminForm';
import { CaretDownOutlined } from '@ant-design/icons';
import { AssociateAppointmentUpdate } from './AssociateAppointmentIsUpdateSystem';
import {useHistory} from 'react-router-dom'
const { Search } = Input;

const AssociateApointment = () => {
  //Hook
  const [searchBy, setSearchBy] = useState('fullName');
  const [queryParams] = useAssociateQueryParams(searchBy)
  const [keyword, { setKeyword, onParamChange }] = useAppointmentAssociateQueryParams(queryParams)
  const [associatesApointment, isLoading] = useAppointmentsAssociate(queryParams)
  // const [keyword, setKeyword] = useState()
  const [, handleDelete] = useDeleteAppointmentAssociate()
  const history = useHistory()
  const [isSubmitLoading, handleUpdate] = useUpdateAppointmentAssociate();
  const [appointmentId, setAppointmentId] = useState(null)
  const paging = useAppointmentAssociatePaging();

  const specialityOptions = useSpecialityOptions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConvertOpen, setIsModalConvertOpen] = useState(false);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_AFFILIATEDAPPOINTMENT);
  const canDelete = useMatchPolicy(POLICY.DELETE_AFFILIATEDAPPOINTMENT);

  const [isOpen, setIsOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setAppointmentId(null)
    handleDelete(appointmentId);
    setIsModalOpen(false);
  };
  const showModalConvert = () => {
    setIsModalConvertOpen(true);
  };
  const handleOkConvert = () => {
    convertAction();
    setIsModalConvertOpen(false);
  };
  const handleOkUpdate = (value) => {
    setAppointmentId(null)
    setIsModalUpdateOpen(value);
  };
  const showModalUpdate = () => {
    setIsModalUpdateOpen(true);
  };
  const handleCancel = () => {
    setAppointmentId(null)
    setIsModalOpen(false);
    setIsModalUpdateOpen(false)
  };
  const handleCancelConvert = () => {
    setIsModalConvertOpen(false);
  };
  useEffect(() => {
    isModalOpen === false ?  setAppointmentId(null) : ()=>{}
  }, [isModalOpen])
  const menu = (
    <Menu>
      {/* <WithPermission permission={POLICY.WRITE_ASSOCIATE_APPOINTMENT}>
        <Menu.Item
          // disabled={disableConvert}
          // key={v4()}
          onClick={showModalConvert}
        >
          <p>Chuyển đổi</p>
        </Menu.Item>
      </WithPermission> */}
      <WithPermission permission={POLICY.UPDATE_AFFILIATEDAPPOINTMENT}>
        <Menu.Item
          // key={v4()}
          onClick={showModalUpdate}
        >
          <p>Cập nhật</p>
        </Menu.Item>
      </WithPermission>
      <WithPermission permission={POLICY.DELETE_AFFILIATEDAPPOINTMENT}>
        <Menu.Item
          // disabled={disableDelete}
          // key={v4()}
          onClick={showModal}
        >
          <p>Xoá</p>
        </Menu.Item>
      </WithPermission>
    </Menu>
  );

  const columns = [
    {
      title: 'STT',
      key: 'index',
      dataIndex: 'index',
      width: '70px',
      render: (text, record, index) => {
        return (queryParams.page - 1) * queryParams.limit + index + 1;
      }
    },
    // {
    //   title: 'Mã lịch hẹn',
    //   key: 'associateApointmentCode',
    //   width: '100px',
    //   render: (value) => value.associateApointmentCode
    // },
    {
      title: 'Họ và tên',
      key: 'customerName',
      dataIndex: 'customerName',
      // width: '300px',
    },
    {
      title: 'Số điện thoại',
      key: 'phoneNumber',
      width: '150px',
      dataIndex: 'phoneNumber',
    },
    // {
    //   title: 'Email',
    //   key: 'email',
    //   with: '350px',
    //   render: (value) => {
    //     return value.email;
    //   }
    // },
    {
      title: 'Đối tác giới thiệu',
      key: 'branch',
      dataIndex: 'branch',
      width: '200px',
      render: (value,record) => {
        return get(record, 'branch.name','')
      }
    },
    {
      title: 'Đối tác đã phân bổ',
      key: 'hospital',
      dataIndex: 'hospital',
      width: '200px',
      render: (value,record) => {
        return get(record, 'hospital.name.vi', '')
      }
    },
    {
      title: 'Nguồn',
      key: 'source',
      dataIndex: 'source',
      render: (value) => 
      value === 'CALL' ? "Gọi điện" : "Website"
      // width: '200px',
    },
    {
      title: 'Lời nhắn',
      key: 'message',
      with: '200px',
      render: (value) => {
        return value.message;
      }
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      with: '200px',
      render: (value) => moment(value).format('HH:mm DD/MM/YYYY')
    },
    {
      title: 'Ngày hẹn',
      key: 'date',
      dataIndex : 'date',
      with: '200px',
      render: (value) => moment(value).format('HH:mm DD/MM/YYYY')
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex : 'status',
      with: '200px',
      render: (value) =>
      (  <span  className={`appointment-status ${value.toLowerCase()}`}>
        {APPOINTMENT_STATUS[value].name}
      </span>)
    },
    (canDelete || canUpdate) ?
       {
      title: 'Action',
      key: 'createdAt',
      fixed: 'right',
      dataIndex: 'createdAt',
      width: '150px',
      render: (text, record, index) => {
        return (
          <Dropdown
            onClick = {()=> setAppointmentId(get(record, '_id'))}
            overlayStyle={{ width: '140px' }}
            trigger={['click']}
            overlay={menu}
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
        );
      }
    } : []
  ];

  const options = [
    // {
    // label:"Mã lịch hẹn",
    // value: 'associateApointmentCode'
    // },
    {
    label:"Tên",
    value: 'fullName'
    },
    {
    label:"Số điện thoại",
    value: 'phoneNumber'
    },
    {
    label:"Đối tác giới thiệu",
    value: 'presenterName'
    },
    {
    label:"Nguồn",
    value: 'source'
    },
    {
    label:"Thời gian",
    value: 'date'
    },
  ]
  return (
    <div className="page-wrapper page-content whPartnerImports-page">
      <div className="container-fluid">
        <Breadcrumb title="Lịch hẹn được liên kết" />
        <div className="page-wraper__header">
          {/* Search Input*/}

          <Row justify="start" gutter={36}>
          <Col sm={12} md={6}>
            <Select
              value={searchBy}
              style={{ width: '100%' }}
              onChange={(val) => {
                onParamChange({
                  date: null,
                  presenterName: null,
                  phoneNumber: null,
                  fullName: null,
                  startTime: null,
                  endTime: null,
                  source: null,
                  page: 1
                });
                setSearchBy(val);
              }}
            >
              {/* <Option value="appointmentCode">Mã lịch hẹn</Option> */}
              <Option value="fullName">Tên khách hàng</Option>
              <Option value="date">Thời gian hẹn</Option>
              <Option value="source">Nguồn</Option>
              <Option value="phoneNumber">Số điện thoại</Option>
              <Option value="presenterName">Đối tác giới thiệu</Option>
            </Select>
          </Col>
          <Col sm={8} md={6}>
            {
              {
                // appointmentCode: (
                //   <Search
                //     allowClear
                //     style={{ width: '100%' }}
                //     placeholder="Tìm theo mã lịch hẹn"
                //     enterButton
                //     onSearch={() =>
                //       onParamChange({ [searchBy]: keyword, page: 1 })
                //     }
                //     onChange={(e) => setKeyword(e.target.value)}
                //     value={keyword}
                //   />
                // ),
                date: (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: '10px',
                      marginRight: '10px'
                    }}
                  >
                    <FilterDatePicker
                      label=""
                      value={queryParams.startTime}
                      onChange={(value) =>
                        onParamChange({ startTime: value, page: 1 })
                      }
                    />
                    <FilterDatePicker
                      label=""
                      value={queryParams.endTime}
                      onChange={(value) =>
                        onParamChange({ endTime: value, page: 1 })
                      }
                    />
                  </div>
                ),
                source: (
                  <Select
                    placeholder={'Chọn nguồn'}
                    style={{ width: '100%' }}
                    onChange={(val) => {
                      onParamChange({ source: val, page: 1 });
                    }}
                  >
                    <Option value="WEBSITE">Website</Option>
                    <Option value="CALL">Gọi điện</Option>
                  </Select>
                  ),
                  fullName: (
                  <Search
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Tìm theo tên"
                    enterButton
                    onSearch={() =>
                      onParamChange({ [searchBy]: keyword, page: 1 })
                    }
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                ),
                phoneNumber: (
                  <Search
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Tìm theo số điện thoại"
                    enterButton
                    onSearch={() =>
                      onParamChange({ [searchBy]: keyword, page: 1 })
                    }
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                ),
                presenterName: (
                  <Search
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Tìm theo đối tác giới thiệu"
                    enterButton
                    onSearch={() =>
                      onParamChange({ [searchBy]: keyword, page: 1 })
                    }
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                ),
                }[searchBy]
              }
            </Col>
          </Row>
        </div>
        {isLoading ? (
          <SkeletonTable
            columns={columns}
            // rowCount={}
            pagination={paging}
          />
        ) : (
          <Table
            // rowKey={(rc) => rc._id || rc.id}
            columns={columns}
            dataSource={get(associatesApointment, 'docs', [])}
            onChange={(current)=> onParamChange(current)}
            size="middle"
            scroll={{ x: 'auto' }}
            pagination={{
              ...paging,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
          />
        )}
      </div>
{/* Modal Form */}
      <>
      <Modal
        style={{ textAlign: 'center' }}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h5>Bạn có muốn xoá lịch hẹn này?</h5>
      </Modal>
      <Modal
        style={{ textAlign: 'center' }}
        visible={isModalConvertOpen}
        onOk={handleOkConvert}
        onCancel={handleCancelConvert}
      >
        <h5>Bạn có muốn chuyển đổi lịch hẹn này?</h5>
      </Modal>
      <Modal
        style={{ textAlign: 'center' }}
        visible={isModalUpdateOpen}
        footer={null}
        // onOk={handleOkUpdate}
        onCancel={handleCancel}
      >
        <AssociateAppointmentUpdate
          onClose = {handleCancel}
          handleOkUpdate = {(value)=> handleOkUpdate(value)}
          specialityOptions ={specialityOptions}
          appointmentId={appointmentId}
          isModalOpen={isModalOpen}
            handleUpdate={handleUpdate}
            isSubmitLoading={isSubmitLoading}
          
          // onClose={() => setIsModalUpdateOpen(false)}
        />
      </Modal>
      </>
    </div>
  );
};

export default AssociateApointment;
