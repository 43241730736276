import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  useBranchIdSessionStorage
} from '~/hooks/utils';

import {
  getProductCatalogues,
  createProductCatalogue,
  deleteProductCatalogue,
  getProductCatalogue,
  resetProductCatalogueState,
  updateProductCatalogue,
  getuseSupplier,
  updateProductCatalogueStatus,
  getProductCatalogueStatus,
  resetProductCatalogueFormState,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { isString } from 'lodash';

const PRODUCT_CATALOGUE = 'productCatalogue';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(PRODUCT_CATALOGUE);

const getSelector = (key) => (state) => state[PRODUCT_CATALOGUE][key];
const updateStatusSuccessSelector = getSelector('updateStatusSuccess')
const updateStatusFailedSelector = getSelector('updateStatusFailed')

// const isLoadingByStatusSelector  = getSelector('isLoadingByStatus')
// const getListByStatusFailedSelector = getSelector('getListByStatusFailed')
// const listByStatusSelector = getSelector('listByStatus')

const pagingSelector = getSelector('paging');


export const useProductCatalogueQueryParams = (module) => {
  const prevKeyword = useRef(null);
  const [branchId] = useBranchIdSessionStorage();
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const code = query.get('code');
  const name = query.get('name');
  const action = query.get('action') || undefined;
  const page = query.get('page') || 1;

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      name,
      action,
      code
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    name,
    action,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    code,
    branchId,
  ]);
};

export const useUpdateProductCatalogueParams = (query) => {

  const {path, url} = useRouteMatch()
  const history = useHistory();
  const [keyword, setKeyword] = useState(query?.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    for (const key in param) {
      param[key]=  isString(param[key]) ? (param[key].trim()).replace(/[.]/g,'') : param[key]
   }
    
    history.push({
      pathname: `${path}`,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};


export const useProductCataloguePaging = () => useSelector(pagingSelector);

export const useProductCatalogues = (query) => {
  return useFetchByParam({
    action: getProductCatalogues,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

// export const useProductCatalogueStatus = (query) => {
//   return useFetchByParam({
//     action: getProductCatalogueStatus,
//     isLoadingByStatusSelector,
//     dataSelector: listByStatusSelector,
//     failedSelector: getListByStatusFailedSelector,
//     param: query
//   });
// };


export const useCreateProductCatalogue = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới danh mục sản phẩm thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới danh mục sản phẩm thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createProductCatalogue
  });
};

export const useUpdateProductCatalogue = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật danh mục sản phẩm thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật danh mục sản phẩm thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateProductCatalogue
  });
};

export const useUpdateProductCatalogueStatus = (callback) => {
  useSuccess(updateStatusSuccessSelector, 'Cập nhật trạng thái sản phẩm thành công', callback);
  useFailed(updateStatusFailedSelector, 'Cập nhật trạng thái sản phẩm thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateProductCatalogueStatus
  });
};

export const useDeleteProductCatalogue = () => {
  useSuccess(deleteSuccessSelector);
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector,
    action: deleteProductCatalogue
  });
};

export const useProductCatalogue = (params) => {
  return useFetchByParam({
    action: getProductCatalogue,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useResetProductCatalogue = () => {
  useResetState(resetProductCatalogueState);
};

export const useResetProductCatalogueForm = () => {
  useResetState(resetProductCatalogueFormState);
};
