import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Skeleton,
  Modal,
  DatePicker,
  InputNumber,
  Switch,
  Select,
  Radio,
  TreeSelect,
  Badge
} from 'antd';
import moment from 'moment';
import { onFormAddressChange } from '~/utils/helper';
import {
  useCreateCoupon,
  useCoupon,
  useInitCoupon,
  useUpdateCoupon,
  useCities,
  useWards,
  useDistricts,
  useCouponServiceCategories,
  useProfile,
  useUser,
  useResetActionCoupon
} from '~/hooks';
import './index.scss';
import { filterAcrossAccents, formatNumberThreeComma } from '~/hooks/utils';
import { get, map } from 'lodash';
import { COUPON_DISTRIBUTION_TYPE, RELATIVE_POSITION, SERVICE_GROUP_TYPES, SERVICE_GROUP_TYPES_VI } from '~/constants/defaultValue';
import { GeoTreeSelect } from '~/components/Common';
import SelectService from '../SelectService';

const FormItem = Form.Item;

const longLabelCol = { sm: 24, md: 24, lg: 10, xl: 10 };
const longWrapperCol = { sm: 24, md: 24, lg: 14, xl: 14 };

const CouponForm = ({ onClose, id, whServices }) => {
  const [form] = Form.useForm();
  const [cities, isCitiesLoading] = useCities();
  const [isSubmitLoading, handleCreate] = useCreateCoupon(onClose);
  const [, handleUpdate] = useUpdateCoupon(onClose);
  useResetActionCoupon();
  const [couponServiceCategories, isCouponServiceCateLoading] = useCouponServiceCategories();
  const [coupon, isLoading] = useCoupon(id);
  const initCoupon = useInitCoupon(coupon, id);
  // const [whServices, setWhServices] = useState([]);
  // const [isFollowService, setIsFollowService] = useState(!!get(coupon, 'whServiceId'));
  // const [groupServiceType, setGroupServiceType] = useState(null);
  const [distributionType, setDistributionType] = useState(null);

  const [openSelectService, setOpenSelectService] = useState(false);

  const onOpenService = useCallback(() => setOpenSelectService(true), []);
  const onCloseService = useCallback(() => setOpenSelectService(false), []);

  const [, token] = useUser();
  const [profile] = useProfile(token);
  const isRootAdmin = get(profile, 'isSuperAdmin') === true;
  const managementPaths = useMemo(() => {
    return get(profile, "managementArea")?.map(item => get(item, "path"));
  }, [profile]);

  const couponCategoryOptions = useMemo(() => {
    return map(couponServiceCategories, (service) => {

      return { label: SERVICE_GROUP_TYPES_VI[service], value: service }

    })
  }, [couponServiceCategories]);
  useEffect(() => {
    form.resetFields();


  }, [initCoupon, form]);
  useEffect(() => {
    const { areaAvailble, ...rest } = initCoupon;
    const newAddress = areaAvailble?.map(item => {
      const newItem = {
        label: item?.fullAddress,
        value: item?.path
      };
      return newItem;
    });
    form.setFieldsValue({ areaAvailble: newAddress })
  }, [form, initCoupon])
  // useEffect(() => {
  //   form.resetFields(['whServiceId']);
  //   const getInitWhServices = async () => {
  //     const whInitServices = await api.whService.getWhServices({ limit: MAX_PAGINATION_LIMIT });
  //     setWhServices(whInitServices.docs);
  //   }
  //   // console.log(isFollowService,"isFollowServices");
  //   // if(!!get(coupon, 'whServiceId') && whServices?.length === 0){
  //   // }
  //   getInitWhServices();
  // }, [])

  const onFinish = (values) => {
    const { areaAvailble, ...rest } = values;
    const newAddress = areaAvailble?.map(item => {
      const newItem = {
        fullAddress: item.label,
        path: item.value
      };
      return newItem;
    });
    const coupon = {
      ...rest,
      startTime: values.startTime || null,
      endTime: values.endTime || null,
      // createdAccountTimeRange : [moment(get(values,'createdAccountTimeRange.startTime',undefined)).format("YYYY-MM-DD HH:mm:ss"),moment(get(values,'createdAccountTimeRange.endTime',undefined)).format("YYYY-MM-DD HH:mm:ss")],
      createdAccountTimeRange: [get(values, 'createdAccountTimeRange.startTime', null), get(values, 'createdAccountTimeRange.endTime', null)],
      areaAvailble: newAddress ?? [],
    };
    if (id) {
      handleUpdate({ ...coupon, _id: id });
    } else {
      handleCreate(coupon);
    }
  };


  const onFormChange = (changes, allFields) => {
    if (changes?.serviceGroupType) {
      if (changes?.serviceGroupType === SERVICE_GROUP_TYPES.ONLINE_PHARMACY) {
        form.setFieldsValue({
          whServiceId: []
        })
      }
      // form.resetFields(['whServiceId']);
      // const getInitWhServices = async () => {
      //   const whInitServices = await api.whService.getWhServices({ limit: 50 });
      //   setWhServices(whInitServices.docs);
      // }
      // if (changes?.serviceGroupType === SERVICE_GROUP_TYPES.HOME_HEALTH_CARE && !whServices?.length) {
      //   getInitWhServices();
      // }
    }

    if (changes?.distributionType) {
      setDistributionType(changes?.distributionType);
    }
  }

  return (
      <div className="coupon-form">
        <Form

          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={(values, allValues) => {
            onFormChange(values, allValues);
            onFormAddressChange(values, form);
          }}
          scrollToFirstError
          requiredMark={false}
          initialValues={initCoupon}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 10, xl: 10 }}
          wrapperCol={{ sm: 24, md: 24, lg: 14, xl: 14 }}
        >
          <Row style={{ marginBottom: 20 }}>
            <h4 style={{ marginRight: 'auto' }}>
              {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} phiếu giảm giá
            </h4>
          </Row>
          <Row
            gutter={60}
            align="middle"
            justify="space-between"
            className="coupon-form__logo-row"
          >
            <Col span={12}>
              <FormItem
                label="Tên giảm giá"
                name="name"
                rules={[
                  { required: true, message: 'Xin mời nhập tên phiếu giảm giá' }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input.TextArea />}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Mã giảm giá"
                name="code"
                rules={[
                  { required: true, message: 'Xin mời nhập mã giảm giá' }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Thời gian bắt đầu"
                name="startTime"
                rules={[
                  { required: true, message: 'Xin mời nhập thời gian bắt đầu' }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <DatePicker
                    style={{ width: '100%' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Thời gian kết thúc"
                name="endTime"
              // rules={[
              //   { required: true, message: 'Xin mời nhập thời gian kết thúc' }
              // ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <DatePicker
                    style={{ width: '100%' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem label="Hình thức giảm giá" name="discountType">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select>
                    <Select.Option value="value">Theo số tiền</Select.Option>
                    <Select.Option value="percent">
                      Theo phần trăm
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {() =>
                  form.getFieldValue('discountType') === 'value' ? (
                    <FormItem
                      label="Số tiền giảm"
                      name="discountValue"
                      rules={[
                        { required: true, message: 'Xin mời nhập số tiền giảm' }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <InputNumber min={0} formatter={(value) => formatNumberThreeComma(value)} />
                      )}
                    </FormItem>
                  ) : (
                    <FormItem
                      label="Số % giảm"
                      name="discountPercent"
                      rules={[
                        { required: true, message: 'Xin mời nhập số % giảm' }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <InputNumber min={0} max={100} />
                      )}
                    </FormItem>
                  )
                }
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormItem shouldUpdate noStyle>
                {
                  () => form.getFieldValue('discountType') !== 'value' &&
                    <FormItem label="Số tiền giảm tối đa" name="maxDiscount">
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <InputNumber min={0} formatter={(value) => formatNumberThreeComma(value)} />
                      )}
                    </FormItem>
                }
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                labelCol={longLabelCol}
                wrapperCol={longWrapperCol}
                label="Số lần sử dụng tối đa"
                name="maxUsage"
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber min={0} />
                )}
              </FormItem>
            </Col>

            {/* <Col span={12}>
              <FormItem
                labelCol={longLabelCol}
                wrapperCol={longWrapperCol}
                label="Giảm giá ship tối đa"
                name="maxShippingDiscount"
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber min={0} />
                )}
              </FormItem>
            </Col> */}

            {/* <Col span={12}>
              <FormItem label="Mô tả ngắn" name="description">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col> */}

            <Col span={12}>
              <FormItem label="Loại phân phối mã giảm giá" name="distributionType">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select defaultValue={'PUBLIC'}>
                    <Select.Option value="PRIVATE">PRIVATE</Select.Option>
                    <Select.Option value="PUBLIC">
                      PUBLIC
                    </Select.Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Giá trị đơn tối thiểu"
                name="minAmountOfBill"
              // rules={[
              //   { required: true, message: 'Xin mời nhập Đơn tối thiểu' }
              // ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber min={0} formatter={(value) => formatNumberThreeComma(value)} />
                )}
              </FormItem>
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {
                  () => form.getFieldValue('distributionType') === COUPON_DISTRIBUTION_TYPE.PRIVATE
                    && <FormItem
                      labelCol={longLabelCol}
                      wrapperCol={longWrapperCol}
                      label="Thời hạn tối đa kể từ lúc tạo tài khoản (Đơn vị: ngày)"
                      name="numberOfDaySinceCreateAccount"
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <InputNumber placeholder='Thời hạn tối đa kể từ lúc tạo tài khoản (Đơn vị: ngày)' min={-1} />
                      )}
                    </FormItem>
                }
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {
                  () => form.getFieldValue('distributionType') === COUPON_DISTRIBUTION_TYPE.PRIVATE
                    &&
                    <FormItem

                      label={"Tài khoản tạo từ: "}
                      name={["createdAccountTimeRange", "startTime"]}>
                      {/* <DatePicker.RangePicker
                      ranges={{
                        Today: [moment().startOf('day'), moment().endOf('day')],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      style={{width: '100%'}}
                      showTime={{ format: 'HH:mm', defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                      format="YYYY-MM-DD HH:mm"
                    /> */}
                      <DatePicker
                        style={{ width: '100%' }}
                        showTime={{ format: 'HH:mm', defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        format="YYYY-MM-DD HH:mm"
                      />
                    </FormItem>
                }
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {
                  () => form.getFieldValue('distributionType') === COUPON_DISTRIBUTION_TYPE.PRIVATE
                    &&
                    <FormItem

                      label={"Đến ngày: "}
                      name={["createdAccountTimeRange", "endTime"]}>
                      <DatePicker
                        style={{ width: '100%' }}
                        showTime={{ format: 'HH:mm', defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        format="YYYY-MM-DD HH:mm"
                      />
                    </FormItem>
                }
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormItem label="Theo Dịch Vụ sử dụng" name="serviceGroupType">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Radio.Group options={couponCategoryOptions}>

                  </Radio.Group>
                  // <Switch onChange={(checked) => {setIsFollowService(checked)}} defaultChecked={isFollowService} checkedChildren="Có" unCheckedChildren="Không" />
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <Form.Item shouldUpdate noStyle>
                {
                  () => {
                    return form.getFieldValue('serviceGroupType') === SERVICE_GROUP_TYPES.HOME_HEALTH_CARE &&
                      <FormItem label="Dịch Vụ sử dụng" name="whServiceId">
                        {/* {!whServices.length ? (
                          <Skeleton.Input active />
                        ) : (
                          <Select defaultValue={[]} mode='multiple'>
                            {map(whServices, (service) => {
                              return <Select.Option value={get(service, '_id')}>{get(service, 'name.vi')}</Select.Option>
                            })}
                          </Select>
                        )} */}
                        {/* <DebounceSelectV2 
                          fetchOptions={fetchOptionService}
                          mode="multiple"
                          style={{width : '100%'}}
                          initOptions={initOptionService}
                          value={form.getFieldValue('whServiceId')}
                        /> */}
                        <Badge showZero overflowCount={9999} count={form.getFieldValue('whServiceId')?.length || 0}>
                        <Button type='primary' onClick={onOpenService} block>
                          Xem chi tiết
                        </Button>
                        </Badge>
                        <Modal style={{ top: 10 }} visible={openSelectService} onCancel={onCloseService} footer={null} width={"auto"}   >
                          <SelectService
                            data={form.getFieldValue('whServiceId')}
                            setData={(ids) => form.setFieldsValue({ whServiceId: ids })}
                            defaultData={get(coupon,'listWhService',[])}
                            onCancel={onCloseService}
                          />
                        </Modal>
                      </FormItem>
                  }
                }
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormItem shouldUpdate={(prevValue, curValue) => get(prevValue, 'serviceGroupType') !== get(curValue, 'serviceGroupType')} noStyle>
                {() => form.getFieldValue(['serviceGroupType']) === "ONLINE_PHARMACY" ? <FormItem
                  label="Free ship"
                  name="isFreeShipping"
                  valuePropName="checked"
                >
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <Switch checkedChildren="Có" unCheckedChildren="Không" />
                  )}
                </FormItem> : null}
              </FormItem>

            </Col>
          </Row>
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={24}>
              <FormItem
                label="Khu vực áp dụng"
                name={['areaAvailble']}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              // rules={[
              //   {
              //     required: true, // ALl Account must be have managementArea
              //     message: 'Xin mời nhập khu vực áp dụng!',
              //   }
              // ]}
              >
                {isLoading
                  ? <Skeleton.Input active />
                  : <GeoTreeSelect
                    autoClearSearchValue
                    checkablePositions={[RELATIVE_POSITION.IS_CHILD]}
                    // disabled={!isRootAdmin && id === get(profile, "_id")}
                    enabledValues={isRootAdmin ? null : managementPaths}
                    labelInValue={true}
                    // listHeight={360}
                    listItemHeight={200}
                    multiple={true}
                    showCheckedStrategy={TreeSelect.SHOW_ALL}
                    showEnabledValuesOnly={true}
                    showSearch={true}
                    onSelect={(e) => console.log(e)}
                    size="large"
                    treeCheckStrictly={true}
                    treeCheckable={true}
                    treeDefaultExpandedKeys={["1", "2", "3"]}
                  />
                }
              </FormItem>
            </Col>

          </Row>
          <Row className="coupon-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>

      </div>
  );
};

export default CouponForm;
