import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getConfigWhPartnerRegisterTypeFormsFailed: null,
  getConfigWhPartnerRegisterTypeFormFailed: null,
  ConfigWhPartnerRegisterTypeForms: [],
  ConfigWhPartnerRegisterTypeForm: null,
  isSubmitLoading: false,
  isGetConfigWhPartnerRegisterTypeFormLoading: false,

  loadingUpdateCourse: false,
  updateFailedCourse: null,
  updateSuccessCourse: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_CONFIGWHPARTNERREGISTERTYPEFORMS_REQUEST:
      state.isLoading = true;
      state.getConfigWhPartnerRegisterTypeFormsFailed = null;
      return;

    case Types.GET_CONFIGWHPARTNERREGISTERTYPEFORMS_SUCCESS:
      state.isLoading = false;
      state.ConfigWhPartnerRegisterTypeForms = get(payload, 'docs', []);
      state.getConfigWhPartnerRegisterTypeFormsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_CONFIGWHPARTNERREGISTERTYPEFORMS_FAILED:
      state.isLoading = false;
      state.ConfigWhPartnerRegisterTypeForms = [];
      state.getConfigWhPartnerRegisterTypeFormsFailed = payload;
      return;
    case Types.GET_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST:
      state.isGetConfigWhPartnerRegisterTypeFormLoading = true;
      state.ConfigWhPartnerRegisterTypeForm = null
      state.getConfigWhPartnerRegisterTypeFormFailed = null;
      return;

    case Types.GET_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS:
      state.isGetConfigWhPartnerRegisterTypeFormLoading = false;
      state.ConfigWhPartnerRegisterTypeForm = payload;
      state.getConfigWhPartnerRegisterTypeFormFailed = null;
      return;

    case Types.GET_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED:
      state.isGetConfigWhPartnerRegisterTypeFormLoading = false;
      state.ConfigWhPartnerRegisterTypeForm = null;
      state.getConfigWhPartnerRegisterTypeFormFailed = payload;
      return;
    case Types.CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.ConfigWhPartnerRegisterTypeForms = state.ConfigWhPartnerRegisterTypeForms.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.ConfigWhPartnerRegisterTypeForm = payload;
      return;

    case Types.UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_STORE:
    case Types.RESET_CONFIGWHPARTNERREGISTERTYPEFORM_STATE:
      return initState;
    case Types.RESET_CONFIGWHPARTNERREGISTERTYPEFORM_STATE_ACTION:
      state.createSuccess = null;
      state.updateFailed = null;
      state.ConfigWhPartnerRegisterTypeForm = null;
      state.updateSuccess = null;

      return;
    default:
      return;
  }
}, initState);
