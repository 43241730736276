import produce from 'immer';
import { GET_WH_ALL_VOUCHERS_FAILED, GET_WH_ALL_VOUCHERS_REQUEST, GET_WH_ALL_VOUCHERS_SUCCESS,RESET_WH_ALL_VOUCHER_STATE } from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';
import * as Types from '../../constants/actionTypes';

const initState = {
  isLoading: false,
  getWhAllVouchersFailed: undefined,
  whAllVouchers: [],

  paging: null

}

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_WH_ALL_VOUCHERS_REQUEST:
      state.isLoading = true;
      state.getWhAllVouchersFailed = null;
      return;
    
    case GET_WH_ALL_VOUCHERS_SUCCESS:
        state.isLoading = false;
        state.whAllVouchers = payload.docs;
        state.getWhAllVouchersFailed = null;
        state.paging = getPaging(payload);
        return;
  
      case GET_WH_ALL_VOUCHERS_FAILED:
        state.isLoading = false;
        state.whAllVouchers = [];
        state.getWhAllVouchersFailed = null;
      return;
    
      case Types.RESET_WH_ALL_VOUCHER_STATE:
        return initState;
      default:
  }
}, initState);
