import { useEffect } from 'react';
import { Divider } from 'antd';
const WhBillItemNoteCompleted = ({
  whBillItem,
  setNoteCompleted,
  noteCompleted,
  noteCompleteShow,
  setNoteCompletedShow,
  id
}) => {
  useEffect(() => {
    const noteWhBillItem = whBillItem?.noteCompleted
    setNoteCompletedShow(noteWhBillItem ? noteWhBillItem : noteCompleteShow);
  }, [whBillItem, id]);
  return (
    <>
      <Divider plain style={{ marginTop: 0, marginBottom: 0 }}>
        Lý do hoàn thành dịch vụ
      </Divider>
      <div
        className='wh-service-detail__description--paragraph'
      >
        {
          !noteCompleteShow ? <p>(không có ghi chú)</p> : <p>{noteCompleteShow}</p>
        }
      </div>
    </>
  )
};

export default WhBillItemNoteCompleted;
