import { get } from 'lodash';
import { TYPE_WAREHOUSE } from '~/constants/defaultValue';
import * as Error from './Error';
import { CONFIRM_VOUCHER_PRODUCT_STATUS } from '~/constants/confirmVoucherWarehouse';

import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  useBranchIdSessionStorage
} from '~/hooks/utils';

import {
  getConfirmVoucherWarehouses,
  createConfirmVoucherWarehouse,
  deleteConfirmVoucherWarehouse,
  getConfirmVoucherWarehouse,
  resetConfirmVoucherWarehouseState,
  updateConfirmVoucherWarehouse,
  getConfirmVoucherWarehousesWhPartner,
  resetActionConfirmVoucherWarehouseState,
  changeStatusConfirmVoucherWarehouse,
  confirmConfirmVoucherWarehouse,
  cancelConfirmVoucherWarehouse,
  getConfirmVoucherWarehouseWhPartner,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { clearQuerySearch, getExistProp } from '~/utils/helper';

const CONFIRM_VOUCHER_WAREHOUSE = 'confirmVoucherWarehouse';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(CONFIRM_VOUCHER_WAREHOUSE);


const getSelector = (key) => (state) => state[CONFIRM_VOUCHER_WAREHOUSE][key];


const listWhPartnerSelector = getSelector('listWhPartner');
const changeStatusSuccessSelector = getSelector('changeStatusSuccess');
const changeStatusFailedSelector = getSelector('changeStatusFailed');
const confirmSuccessSelector = getSelector('confirmSuccess');
const confirmFailedSelector = getSelector('confirmFailed');
const cancelSuccessSelector = getSelector('cancelSuccess');
const cancelFailedSelector = getSelector('cancelFailed');
const getByIdPartnerSelector = getSelector('byIdPartner');
const getByIdPartnerFailedSelector = getSelector('getByIdFailed');
const getListWhPartnerFailedSelector = getSelector('getListWhPartnerFailed');

const pagingSelector = getSelector('paging');


export const useConfirmVoucherWarehouseQueryParams = (whPartnerId) => {
  const query = useQueryParams();
  const [branchId] = useBranchIdSessionStorage();
  const limit = query.get('limit') || 10;
  const codeSequence = query.get('codeSequence');
  const employee = query.get('employee');
  const whPartner = query.get('whPartner');
  const note = query.get('note');
  const whBill = query.get('whBill');
  const whAppointment = query.get('whAppointment');
  const status = query.get('status');
  const page = query.get('page') || 1;
  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);
  const confirmSuccess = useSelector(confirmSuccessSelector);
  const cancelSuccess = useSelector(cancelSuccessSelector);

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      whPartnerId,
      codeSequence,
      employee,
      whPartner,
      note,
      whBill,
      whAppointment,
      status,
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    whPartnerId,
    codeSequence,
    employee,
    whPartner,
    note,
    whBill,
    whAppointment,
    status,
    confirmSuccess,
    cancelSuccess,
    branchId,
  ]);
};

export const useUpdateConfirmVoucherWarehouseParams = (query,listOptionSearch) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [keyword, setKeyword] = useState(query?.keyword);

  const onParamChange = (param) => {
    clearQuerySearch(listOptionSearch, query, param)
    if (!param.page) {
      query.page = 1;
    }

    history.push({
      pathname: `${path}`,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};


export const useConfirmVoucherWarehousePaging = () => useSelector(pagingSelector);

export const useConfirmVoucherWarehouses = (query) => {
  return useFetchByParam({
    action: getConfirmVoucherWarehouses,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

// WH PARTNER
export const useConfirmVoucherWarehousesWhPartner = (query) => {
  return useFetchByParam({
    action: getConfirmVoucherWarehousesWhPartner,
    loadingSelector,
    dataSelector: listWhPartnerSelector,
    failedSelector: getListWhPartnerFailedSelector,
    param: query
  });
};

export const useCreateConfirmVoucherWarehouse = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới phiếu xác nhận sử dụng thiết bị thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới phiếu xác nhận sử dụng thiết bị thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createConfirmVoucherWarehouse
  });
};

export const useUpdateConfirmVoucherWarehouse = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật phiếu xác nhận sử dụng thiết bị thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật phiếu xác nhận sử dụng thiết bị thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateConfirmVoucherWarehouse
  });
};

export const useDeleteConfirmVoucherWarehouse = () => {
  useSuccess(deleteSuccessSelector);
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector,
    action: deleteConfirmVoucherWarehouse
  });
};

export const useChangeStatusConfirmVoucherWarehouse = (callback) => {
  useSuccess(changeStatusSuccessSelector, 'Cập nhật phiếu xác nhận sử dụng thiết bị thành công', callback);
  useFailed(changeStatusFailedSelector, 'Cập nhật phiếu xác nhận sử dụng thiết bị thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: changeStatusConfirmVoucherWarehouse
  });
};

export const useConfirmConfirmVoucherWarehouse = (callback) => {
  useSuccess(confirmSuccessSelector, 'Xác nhận phiếu xác nhận sử dụng thiết bị thành công', callback);
  useFailed(confirmFailedSelector, 'Xác nhận phiếu xác nhận sử dụng thiết bị thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: confirmConfirmVoucherWarehouse
  });
};

export const useCancelConfirmVoucherWarehouse = (callback) => {
  useSuccess(cancelSuccessSelector, 'Từ chối phiếu xác nhận sử dụng thiết bị thành công', callback);
  useFailed(cancelFailedSelector, 'Từ chối phiếu xác nhận sử dụng thiết bị thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: cancelConfirmVoucherWarehouse
  });
};

export const useConfirmVoucherWarehouse = (params) => {
  return useFetchByParam({
    action: getConfirmVoucherWarehouse,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useConfirmVoucherWarehousePartner = (params) => {
  return useFetchByParam({
    action: getConfirmVoucherWarehouseWhPartner,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdPartnerSelector,
    failedSelector: getByIdPartnerFailedSelector,
    param: params
  });
};

export const useResetConfirmVoucherWarehouse = () => {
  useResetState(resetConfirmVoucherWarehouseState);
};

export const useResetActionConfirmVoucherWarehouse = () => {
  useResetState(resetActionConfirmVoucherWarehouseState);
};

export const checkErrorSelectWhAppointment = (record) => {
  let error = { status: false, msg: "" };
  switch (true) {
    case get(record, 'whPartnerSelected') && (get(record, 'whPartnerSelected._id') !== get(record, 'whPartner._id')):
      error = new Error.DifferenceWhPartner();
      break;
    case !get(record, 'listAvailableConfirm',[])?.length:
      error = new Error.listProductNotAvailable();
      break;
    default:
      break;
  }
  return error
}
export const adapterSelectListProduct = (listProduct) => {
  const convertedListProduct = listProduct?.map((product) => {
    const { ref, medicalDeviceInventory, whAppointmentId, groupEquipmentId, quantity, refId, batchId, whAppointmentCode, medicalEquipmentTypeId } = product;
    switch (get(medicalDeviceInventory, 'type')) {
      case TYPE_WAREHOUSE.CLINIC:
        return {
          name: get(ref, 'product.name', ''),
          productId: refId,
          cost: get(ref, 'price', 0),
          batchId,
          whAppointmentId,
          quantity,
          whAppointmentCode,
          medicalEquipmentTypeId,
          medicalDeviceInventory,
        }
      case TYPE_WAREHOUSE.WORLDHEALTH:
        return {
          name: get(ref, 'name', ''),
          productId: refId,
          cost: get(ref, 'valueDepreciation', 0),
          whAppointmentId,
          groupEquipmentId,
          supplierId: get(ref, 'supplierId'),
          quantity,
          whAppointmentCode,
          medicalEquipmentTypeId,
          medicalDeviceInventory,
          actualQuantity : get(product,'actualQuantity',0)
        }
      default:
        break;
    }
  })
  return convertedListProduct
}

export const adapterInitFetchListProduct = (listProduct) => {
  const convertedListProduct = listProduct?.map((product) => {
    const { product:ref, medicalEquipmentType, whAppointmentId,whAppointment, groupEquipmentId, quantity, batchId, medicalEquipmentTypeId,cost,productId,name } = product;
    switch (get(medicalEquipmentType, 'type')) {
      case TYPE_WAREHOUSE.CLINIC:
        return {
          name: get(ref, 'name', name || ""),
          productId,
          cost,
          batchId,
          whAppointmentId,
          quantity,
          whAppointmentCode: get(whAppointment,'code', ''),
          medicalEquipmentTypeId,
          medicalDeviceInventory:medicalEquipmentType,
        }
      case TYPE_WAREHOUSE.WORLDHEALTH:
        return {
          name: get(ref, 'name', ''),
          productId,
          cost,
          whAppointmentId,
          groupEquipmentId,
          supplierId: get(ref, 'supplierId'),
          quantity,
          whAppointmentCode: get(whAppointment,'code', ''),
          medicalEquipmentTypeId,
          medicalDeviceInventory:medicalEquipmentType,
          actualQuantity : get(product,'actualQuantity',0)
        }
      default:
        break;
    }
  })
  return convertedListProduct
}
