import { CheckCircleFilled, EditFilled, StopFilled } from '@ant-design/icons';
import { Modal, Table, Tag, Typography } from 'antd'
import { get } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react'

const USER = {
  admin : 'admin',
  partner : 'partner',
}
const USER_VI = {
  admin : 'Admin',
  partner : 'Đối tác',
}
export default function HistoryLog({dataSource}) {
    const [isOpen,setIsOpen] = useState(false);
    const onOpenModal = () => {
        setIsOpen(true);
    };
    const onCloseModal = () => {
        setIsOpen(false);
    };
    const columns = [
        {
            title : 'Người thực hiện',
            dataIndex : 'username',
            key : 'username',
            render : (username,record) => {
              if(get(record,'typeOf') === USER.admin ) return <Tag color='#2db7f5'>{USER_VI.admin}: {username}</Tag>
              if(get(record,'typeOf') === USER.partner ) return <Tag color='#87d068'>{USER_VI.partner}: {username}</Tag>
              return username
            }
        },
        {
            title : 'Chi tiết',
            dataIndex : 'message',
            key : 'message',
        },
        // {
        //     title : 'Thời gian',
        //     dataIndex : 'timeStamp',
        //     key : 'timeStamp',
        //     render : (timeStamp,record) => {
        //       if(timeStamp){
        //         moment(timeStamp).format('HH:mm:ss DD/MM/YYYY');
        //       }
        //       return null;
        //     }
        // },
    ]
  return (
    <div>
        <Typography.Link onClick={onOpenModal}>
            (Xem chi tiết Logs)
        </Typography.Link>
        <Modal
        title={`Lịch sử chỉnh sửa`}
        width={700}
        visible={isOpen}
        closable={false}
        footer={null}
        onCancel={onCloseModal}
        destroyOnClose
        
      >
        <Table 
        size='small'
            columns={columns}
            dataSource={dataSource}
        />
      </Modal>
    </div>
  )
}
