import React, { useEffect, useMemo, useState } from 'react';

import { get, set } from 'lodash';
import { Button, Checkbox, Col, Modal, Row, Table, Tag } from 'antd';

import {
  useDeleteWhReceiptVoucher,
  useResetWhReceiptVoucher,
  useUpdateWhReceiptVoucher,
  useWhReceiptVoucherPaging,
  useWhReceiptVoucherQueryParams,
  useWhReceiptVouchers,
} from '~/hooks/whReceiptVoucher';

import SkeletonTable from '~/components/Utils/SkeletonTable';

import { fromJSON } from './parser';
import WhReceiptVoucherForm from './Form/WhReceiptVoucherForm';
import WhVoucherStatusTag from './components/WhVoucherStatusTag';
import moment from 'moment';
import ShowTotalMoney from './ShowTotalMoney';
import { useProfile } from '~/hooks';

const  WhReceiptVouchers = ({
  history,
  status,
  modeCustomerCancel,
  whBill,
  useLocalPagination,
  setPaging,
  setArrIds,
  setQueryReceipt,
  noShowTitle,
  activeKey,
  useTime,
  billNumber, //
  branchIds,
  // to avoid conflict the `page` url param when another component is using it
}) => {
  const [current, setCurrent] = useState(1);
  const [arrCheckBox, setArrCheckBox] = useState([])

  const [profile] = useProfile();
  const [query, onPagingChange] = useWhReceiptVoucherQueryParams(
    get(whBill, "billNumber"),
    useLocalPagination && current,
    modeCustomerCancel,
    useTime,
    branchIds,
    useLocalPagination,
  );
  const [whReceiptVouchers, isLoading] = useWhReceiptVouchers(query);
  const [, deleteWhReceiptVoucher] = useDeleteWhReceiptVoucher();
  // const [isSubmitLoading, updateWhReceiptVoucher] = useUpdateWhReceiptVoucher();
  const paging = useWhReceiptVoucherPaging();
    useResetWhReceiptVoucher();
  const [isOpen, setIsOpen] = useState(false);
  const [whReceiptVoucherId, setWhReceiptVoucherId] = useState();

  const onPagingChangeLocal = ({ current, pageSize, total }, someObj) => {
    setCurrent(current);
  };

  const mergedWhReceiptVouchers = useMemo(() => {
    return whReceiptVouchers.map(item => {

      // Data flow: API => redux reducer => hook => parser "fromJSON" => this component.
      // The parser is to help dynamically adapt to API changes.
      // Please consider changing data flow to: API => redux reducer => parser "fromJSON" => hook => this component
      // so it should be no need to implement parser in every single component.
      const whReceiptVoucher = fromJSON(item);

      return {
        ...whReceiptVoucher,
      }
    })
  }, [
    whReceiptVouchers,
  ])
  useEffect(() => {
    setPaging(paging)
  }, [paging])
  
  useEffect(() => {
    setQueryReceipt(query)
  },[query])

  // const onSearch = () => {
  //   let searchParams = `?page=${query.page}&limit=${query.limit}`;
  //   if (keyword) searchParams += `&keyword=${keyword}`;

  //   history.push({
  //     pathname: '/wh-partner/list',
  //     search: searchParams
  //   });
  // };
  const nameService = (record) =>{
    if (get(record, "whBillItem.snapshotService.detailName.vi"))
          return `${get(record, "whBillItem.snapshotService.code")} - ${get(record, "whBillItem.snapshotService.name.vi")} - ${get(record, "whBillItem.snapshotService.detailName.vi")}`
        else 
          return `${get(record, "whBillItem.snapshotService.code")} - ${get(record, "whBillItem.snapshotService.name.vi")}`
  }

  // Get list Ids
  const onChangeCheckBox = (e, id) => {
    if (e) {
    setArrCheckBox([...arrCheckBox, id])
    } else {
      const getIndex = arrCheckBox.findIndex((itemId, index) => itemId === id)
      const newArr = arrCheckBox.toSpliced(getIndex, 1)
      setArrCheckBox(newArr)
    }
  } 

  useEffect(() => {
    if (typeof setArrIds === 'function') {
      setArrIds(arrCheckBox)
    }
  },[arrCheckBox,setArrIds])
  

  const columns = [
    {
      title: 'Mã phiếu thu',
      dataIndex: 'code',
      key: 'code',
      render: (text, record, index) => {
        return (
          <Button
            type='link'
            onClick={() => {
              setWhReceiptVoucherId(get(record, "_id"));
              setIsOpen(true);
            }}
          >
            {text}
          </Button>
        )
      }
    },
    {
      title: 'Số chứng từ',
      dataIndex: 'issueNumber',
      key: 'issueNumber',
      render: text => text || "-",
    },
    {
      title: 'Mã đơn hàng',
      // dataIndex: 'whBill.billNumber',
      key: 'billNumber',
      render: (text, record, index) => get(record, "whBill.billNumber"),
    },
    {
      title: 'Nội dung',
      dataIndex: 'whServiceId',
      key: 'whServiceId',
      render: (text, record, index) => {
        // const messHaveService = get(record, "whBillItem.snapshotService")
        // if(messHaveService) return nameService(record)
        return record.reason
      }
      
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (item, record) => <span>{moment(item).format("DD-MM-YYYY HH:mm:ss")}</span>
    },
    {
      title: 'Số tiền',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text, record, index) => text?.toLocaleString(),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record, index) => moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: 'Ngày duyệt',
      dataIndex: 'dateApproved',
      key: 'dateApproved',
      render: (text, record, index) => text && moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => <WhVoucherStatusTag status={text} />
    },
    {
      title: 'Lựa chọn',
      key: '_id',
      width: 80,
      align: 'center',
      render: (item, record) =>
      {
        const id = record._id
        return <Checkbox
      defaultChecked= {arrCheckBox.includes(id)}
        onChange={(e)=>onChangeCheckBox(e.target.checked, id)}
      />}
    },
  ];

  if(get(profile,'isSuperAdmin')){
    columns.push({
      title: 'Chi nhánh',
      dataIndex: 'branch',
      key: 'branch',
      fixed:'right',
      align : 'center',
      render: (branch, record, index) => get(branch,'name',''),
    },)
  };

  const keySum = {
    totalAmount: 'totalAmount',
    Receipt: activeKey === '1' ? 'Receipt' : 'ReceiptCancel'
  }
  return (
    // <div className="page-wraper page-content">
    <div>
      <div className="container-fluid">
        {/* <Breadcrumb title="Danh sách đối tác" /> */}

        {/* <div className="page-wraper__header">
          <Search
            style={{ width: 200 }}
            placeholder="Tìm đối tác"
            enterButton
            onSearch={onSearch}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

          <Link to={PATH_APP.whReceiptVoucher.create}>
            <Button type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </Link>
        </div> */}
        <ShowTotalMoney totalMoneyPage={whReceiptVouchers} keySumPage={keySum.totalAmount} keyPageName={keySum.Receipt}
              billNumber = {billNumber}
              branchIds={branchIds}
          useLocalPagination={useLocalPagination}
          queryProp = {query}
              />
        {isLoading ? (
          <SkeletonTable
            rowCount={whReceiptVouchers.length}
            columns={columns}
            pagination={paging}
          />
        ) : (
            <>
            
              <Table
                // title={()=> !noShowTitle &&  ShowTotalMoney(whReceiptVouchers, keySum.totalAmount, keySum.Receipt) }
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={mergedWhReceiptVouchers}
                // onChange={onPagingChange}
                onChange={useLocalPagination
                  ? onPagingChangeLocal
                  : onPagingChange
                }
                size="middle"
                pagination={{
                  ...paging,
                  // ...(useLocalPagination && { current }),
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            </>
        )}
      </div>
      <Modal
        destroyOnClose
        centered
        footer={null}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        title={`Phiếu thu`}
        visible={isOpen}
        width={1366}
      >
        <WhReceiptVoucherForm
          whReceiptVoucherId={whReceiptVoucherId}
          onClose={() => setIsOpen(false)}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default WhReceiptVouchers;
