
import { Button, Col, DatePicker, Input, Row, Select, Table, Checkbox } from 'antd';
import { forIn, get, head } from 'lodash';
import moment from 'moment';
import {useHistory} from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RELATIVE_POSITION } from '~/constants/defaultValue';
import { formatNumberThreeComma } from '~/hooks/utils';
import { useUpdateReportCustomerParams, useWhReportCustomerPaging, useWhReportCustomerQueryParams, useWhReportCustomers } from '~/hooks/whReportCustomer';
import ExportExcelButton from '../Common/ExportExcel';
import { Breadcrumb, GeoTreeSelect,WithPermission } from '../Common';
import { monthCellRender } from '../Common/VietNameMonthTimpicker';
import SkeletonTable from '../Utils/SkeletonTable';
import ModalDetailCustomer from './ModalDetailCustomer';
import ModalMonth from './ModalMonth';
import './wh-report.scss'
import TabBranch from '../Common/TabBranch';
import POLICIES from '~/constants/policy';
const { Search } = Input;
const { RangePicker } = DatePicker;
const optionSearch = [
  { label: 'Tên khách hàng', value: 'fullName' },
  { label: 'Mã khách hàng', value: 'customerId' },
  { label: 'Số điện thoại', value: 'phoneNumber' },
  { label: 'Khu vực', value: 'managementArea' },
];
const optionFilterDate = [
  { label: 'Theo ngày', value: 'date' },
  { label: 'Theo tháng', value: 'month' },
  { label: 'Theo quý', value: 'quarter' },
  { label: 'Theo năm', value: 'year' }
];
const WhReportParter = () => {
  const history = useHistory()
  const [arrCheckBox, setArrCheckBox] = useState([])
  const defaultDate = useMemo(() => ({
    // startDate : moment().startOf('month').format('YYYY-MM-DD'),
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  }), [])


  const [isModalMonthOpen, setIsModalMonthOpen] = useState(false);
  const [isModalDetailCustomerOpen, setIsModalDetailCustomerOpen] = useState(false);
  const [isModalReVenueOpen, setIsModalReVenueOpen] = useState(false);
  const [idCustomer, setIdCustomer] = useState(null);
  const [query] = useWhReportCustomerQueryParams(defaultDate);
  const [keyword, setKeyword, SearchBy, { onParamChange, resetQueryParams }]
    = useUpdateReportCustomerParams(query)
  const [whReportCustomer, isLoading] = useWhReportCustomers(query)
  //Search
  const [dateFilter, setDateFilter] = useState({ // date filter all
    startDate: get(query, 'startDate'),
    endDate: get(query, 'endDate')
  });
  const [date, setDate] = useState({ // date filter detail
    startDate: get(query, 'startDate'),
    endDate: get(query, 'endDate')
  })
  const [searchBy, setSearchBy] = useState(SearchBy || 'fullName');
  const [filterDateBy, setFilterDateBy] = useState(
    head(optionFilterDate).value
  );
  const handleChangeSearch = (value) => {
    setSearchBy(value);
  };
  const handleChangeFilterDate = (value) => {
    setFilterDateBy(value);
  };
  const onchangeDate = (value, dateString) => {
    if (filterDateBy === 'date') { // ranger date
      const [startDate, endDate] = dateString;
      if (startDate === 'Invalid date' && endDate === 'Invalid date') {
        if (endDate !== get(dateFilter, 'endDate')) {
          setDateFilter(defaultDate);
          onParamChange(defaultDate)
          setDate(defaultDate)
        }
      } else {
        if (endDate !== get(dateFilter, 'endDate')) {
          setDateFilter({ startDate, endDate });
          onParamChange({ startDate, endDate })
          setDate({ startDate, endDate })
        }

      }


    } else {
      const startDate = moment(value)
        .startOf(filterDateBy)
        .format('YYYY-MM-DD');
      const endDate = moment(value).endOf(filterDateBy).format('YYYY-MM-DD');
      if (startDate === 'Invalid date' && endDate === 'Invalid date') {
        setDateFilter(defaultDate);
        onParamChange(defaultDate)
        setDate(defaultDate)
      }
      else {
        setDateFilter({ startDate, endDate });
        onParamChange({ startDate, endDate })
        setDate({ startDate, endDate })
      }

    }
  };
  const onSearch = () => {
    onParamChange({
      ...resetQueryParams,
      [searchBy]: keyword.toString().trim()
    });
  };
  //Search
  const handleOnpenModalDetail = (id) => {
    setIsModalDetailCustomerOpen(true)
    setIdCustomer(id)
  }

  const paging = useWhReportCustomerPaging()
  const setInitDate = useCallback(() => {
    setDate({
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD')
    })
  }, [])

  const openModalMonth = (id) => {
    setIsModalMonthOpen(true);
    setIdCustomer(id)
  }

  useEffect(() => {
    if ((!dateFilter.startDate && !dateFilter.endDate) || (dateFilter.startDate === 'Invalid date' && dateFilter.endDate === 'Invalid date')) {
      setDateFilter(defaultDate)
    }
  }, [dateFilter])
  // const monthCellRender = (value) => {
  //   const month = moment(value).format("MM")
  //   return <div style={{width : '80px'}} className='ant-picker-cell-inner'>Tháng {parseInt(month)}</div>;
  // };
  // Get list Ids
  const onChangeCheckBox = (e, id) => {
    if (e) {
      setArrCheckBox([...arrCheckBox, id])
    } else {
      const getIndex = arrCheckBox.findIndex((itemId, index) => itemId === id)
      const newArr = arrCheckBox.toSpliced(getIndex, 1)
      setArrCheckBox(newArr)
    }
  }
  const columns = [
    {
      title: 'STT',
      key: 'billNumber',
      width: 50,
      align: 'center',
      render: (item, record, index) => (
        <span>
          {index + 1}
        </span>
      )
    },
    {
      title: 'Lựa chọn',
      key: '_id',
      width: 80,
      align: 'center',
      render: (item, record) => {
        const id = record._id
        return <Checkbox
          defaultChecked={arrCheckBox.includes(id)}
          onChange={(e) => onChangeCheckBox(e.target.checked, id)}
        />
      }
    },
    {
      title: 'Thời gian',
      key: 'billNumber',
      align: 'center',
      render: (item, record, index) => {
        const date = new Date();
        return (
          <Button
            disabled={date.getMonth() !== moment(get(dateFilter, 'startDate')).month()}
            onClick={() => openModalMonth(get(record, 'customerAccount._id'))} type='link'>
            Tháng {moment(get(dateFilter, 'startDate')).month() + 1}
          </Button>
        )
      }
    },
    {
      title: 'Mã KH',
      key: 'customerAccount',
      dataIndex: 'customerAccount',
      align: 'center',
      render: (item, record, index) =>
      (
        <span>
          WHC{get(item, 'customerId', '')}
        </span>
      )

    },
    {
      title: 'Tên KH',
      key: 'customerAccount',
      dataIndex: 'customerAccount',
      align: 'center',
      render: (item, record, index) =>
      (
        <span>
          {get(item, 'fullName', '')}
        </span>
      )

    },
    {
      title: 'SDT',
      key: 'customerAccount',
      dataIndex: 'customerAccount',
      align: 'center',
      render: (item, record, index) => (
        <span>
          {get(item, 'phoneNumber', '').replace("+84", '0')}
        </span>
      )
    },
    {
      title: 'Địa chỉ',
      key: 'address',
      dataIndex: 'address',
      align: 'center',
      render: (item, record, index) => (
        <span>
          {`${get(record, 'customerAddress.street', '')},${get(item, 'ward', '')},${get(item, 'district', '')},${get(item, 'city', '')}`}
        </span>
      )
    },
    {
      title: 'Tổng doanh thu',
      key: 'totalResult',//
      dataIndex: 'totalResult',//
      align: 'center',
      render: (item, record, index) => (
        <span>
          {formatNumberThreeComma(item) || 0}
        </span>
      )
    },
    {
      title: 'Thao tác',
      align: 'center',
      key: 'billNumber',
      fixed: 'right',
      width: 150,
      render: (item, record, index) => (
        <Button onClick={() => handleOnpenModalDetail(get(record, 'customerAccount._id', ''))} type='default'>
          Xem chi tiết
        </Button>
      )
    }
  ];

  // props button export file excel
  const searchParams = history?.location?.search
  const handleQueryExport = useCallback(() => {
    const splitPamras = searchParams && searchParams?.split('&')
    const endDateFind = moment(splitPamras?.find(item => item.includes('endDate='))).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    const filterParams = (splitPamras?.filter((item) => !item.includes('endDate='))).join('&')
    const newParams = filterParams?.concat('&').concat('endDate=').concat(endDateFind)
    return newParams
  }, [searchParams])

  // const jornDate = () => {
  //   let newDate = ''
  //   forIn({ ...dateFilter }, (value, key) => {
  //     newDate = newDate + key + '=' + value +"&"
  //   })
  //   if (newDate.endsWith("&")) {
  //     newDate = newDate.slice(0, -1).concat('+23:59:59');
  //   }
  //   return newDate
  // }
  const propsButton = {
    url: "/wh-receipt-voucher-customer/calculator",
    keyExport: "CustomerPartnerWhReceiptVoucher",
    fileName_: 'Doanh thu khách hàng',
    ids: arrCheckBox,
    searchBy: searchParams ? handleQueryExport() : `?page=1&limit=10&startDate=${date?.startDate}&endDate=${date?.endDate}`
    // searchBy:  history ? `?page=1&limit=10&startDate=${date?.startDate}&endDate=${date?.endDate}` : get(history,'location.search')
  }
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <TabBranch useBoxShadow={false}>


          <Breadcrumb title="Doanh thu khách hàng" />
          <h5>Tìm kiếm </h5>
          <Row>
            <Col md={12} lg={4} xs={12}>
              <Select
                value={searchBy}
                style={{ width: '100%' }}
                onChange={handleChangeSearch}
                options={optionSearch}
              />
            </Col>
            <Col md={12} lg={6} xs={12}>
              {optionSearch?.map((e) => {
                if (e.value === 'managementArea')
                  return {
                    [e.value]: (
                      <GeoTreeSelect
                        style={{ width: '200px' }}
                        onChange={(e) => onParamChange({ managementArea: e })}
                        autoClearSearchValue
                        checkablePositions={[
                          RELATIVE_POSITION.IS_CHILD,
                          RELATIVE_POSITION.IS_EQUAL
                        ]}
                        // enabledValues={isRootAdmin ? null : managementPaths}
                        listItemHeight={200}
                        showEnabledValuesOnly={true}
                        showSearch={true}
                        treeDefaultExpandedKeys={['1', '2', '3']}
                      />
                    )
                  }[searchBy];
                else {
                  return {
                    [e.value]: (
                      <Search
                        allowClear
                        style={{ width: '100%' }}
                        placeholder={`Tìm theo ${e.label}`}
                        enterButton
                        onSearch={onSearch}
                        onChange={(e) => setKeyword(e.target.value)}
                        value={keyword}
                      />
                    )
                  }[searchBy];
                }
              })}
            </Col>
          </Row>
          <h5 style={{ margin: '10px 0' }}>Tìm kiếm theo thời gian</h5>
          <Row style={{ width: '100%' }} justify='space-between'>
            <Col md={12} lg={10} xs={12}>
              <Row style={{ width: '100%' }} >
                <Col span={12}>
                  <Select
                    value={filterDateBy}
                    style={{ width: '100%' }}
                    onChange={handleChangeFilterDate}
                    options={optionFilterDate}
                  />
                </Col>
                <Col span={12}>
                  {filterDateBy === 'date' && (
                    <RangePicker
                      allowClear
                      onChange={onchangeDate}
                      value={[
                        moment(get(dateFilter, 'startDate')),
                        moment(get(dateFilter, 'endDate'))
                      ]}
                    />
                  )}
                  {filterDateBy !== 'date' && (
                    <DatePicker
                      monthCellRender={monthCellRender}
                      allowClear
                      onChange={onchangeDate}
                      style={{ width: 200 }}
                      placeholder={`Tìm ${optionFilterDate?.find((e) => e.value === filterDateBy).label
                        }`}
                      picker={filterDateBy}
                    />
                  )}
                </Col>
              </Row>

            </Col>
          <WithPermission permission={POLICIES.DOWNLOAD_WHREVENUECUSTOMER}>
            <Col align='end' md={12} lg={6} xs={12}>
              <ExportExcelButton
                stylesButton={{ top: "180px" }}
                propsButton={propsButton}
              />
              </Col>
              </WithPermission>
          </Row>
          <div className="page-content__main">
            {isLoading ? (
              <SkeletonTable style={{ width: '100%' }} columns={columns} rowCount={10} />
            ) : (
              <>
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={whReportCustomer}
                scroll={{ x: 2000 }}
                onChange={({ current, pageSize }) => onParamChange({ page: current, limit: pageSize })}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `,
                  // position :['bottomCenter']
                }}
                size="middle"
                />
              </>
            )}
          </div>
        </TabBranch>
      </div>
      <ModalMonth setIdCustomer={setIdCustomer} setIsModalDetailCustomerOpen={setIsModalDetailCustomerOpen} setDate={setDate} isModalMonthOpen={isModalMonthOpen} setInitDate={setInitDate} setIsModalReVenueOpen={setIsModalReVenueOpen} setIsModalMonthOpen={setIsModalMonthOpen} />
      <ModalDetailCustomer setInitDate={() => setDate({ ...dateFilter })} date={date} idCustomer={idCustomer} isModalDetailCustomerOpen={isModalDetailCustomerOpen} setIsModalDetailCustomerOpen={setIsModalDetailCustomerOpen} />
    </div>
  );
};

export default WhReportParter;
