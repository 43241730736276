import React, { useEffect, useCallback, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Button,
  Skeleton,
  Col,
  Select,
  InputNumber
} from 'antd';
import { RATE_CONFIG_TYPES } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import { useCities, useDistricts, useWards } from '~/hooks/geo';
import { filterAcrossAccents, useFormItemMargin } from '~/hooks/utils';
const FormItem = Form.Item;
const { Option } = Select;

const data = [
  { id: '1', objectGroup: 'Bac si' },
  { id: '2', objectGroup: 'Y ta' },
  { id: '3', objectGroup: 'Duoc si' },
  { id: '4', objectGroup: 'Bac si 2' }
];

const WhCustomersImportForm = ({ onClose, id }) => {
  const [form] = Form.useForm();

  const [selectedCityCode, setSelectedCityCode] = useState();
  const [selectedDistrictCode, setSelectedDistrictCode] = useState();

  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(selectedCityCode);
  const [wards, isWardsLoading] = useWards(selectedDistrictCode);

  const onFinish = () => {
    console.log();
  };

  //   const onFinish = useCallback(
  //     (values) => {
  //       if (id) {
  //         handleUpdate({ ...values, id: id });
  //       } else {
  //         handleCreate({ ...values});
  //       }
  //       onClose();
  //     },
  //     [handleCreate, handleUpdate, id]
  //   );

  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="customer-import page-wraper hospital-form-page-content">
      <h4 style={{ margin: '20px 0 40px 20px' }}>
        {id ? ' Cập nhật' : 'Thêm mới'} khách hàng
      </h4>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          // initialValues={initDegree}
          //   labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          //   wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
        >
          <FormItem
            label="Họ và tên"
            name="fullName"
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            rules={[{ required: true, message: 'Xin vui lòng nhập họ và tên' }]}
          >
            <Input />
          </FormItem>
          {/* </Col> */}

          <Col span={12}>
            <FormItem
              label="Năm sinh"
              name="number"
              rules={[
                { required: true, message: 'Xin vui lòng nhập năm sinh' }
              ]}
            >
              <InputNumber className="ant-input" min={1900} max={2010} />
            </FormItem>
          </Col>
          <FormItem
            label="Giới tính"
            name="gender"
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
          >
            {false ? (
              <Skeleton.Input active />
            ) : (
              <Select>
                <Option value="M" key="male">
                  Nam
                </Option>
                <Option value="F" key="female">
                  Nữ
                </Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            label="Số điện thoại"
            name="phoneNumber"
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[0-9]{10,11}$/),
                message: 'Xin vui lòng nhập đúng số điện thoại!'
              }
            ]}
          >
            {false ? <Skeleton.Input active /> : <Input maxLength={11} />}
          </FormItem>

          <FormItem
            labelCol={{ sm: 24, md: 24, lg: 3 }}
            wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            label="Nhóm đối tượng"
            name="employeeGroup"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng chọn nhóm đối tượng'
              }
            ]}
          >
            {false ? (
              <Skeleton.Input active />
            ) : (
              <Select
                // loading=  {isLoading}
                showSearch
                allowClear
                autoComplete="off"
                placeholder="Nhóm đối tượng"
                style={{ width: '100%' }}
                filterOption={filterAcrossAccents}
              >
                {data?.map(({ id, objectGroup }) => (
                  <Option key={id} value={id}>
                    {objectGroup}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          {/* <AddressFormSection
            // isLoading={isLoading}
            form={form}
            cityCode={cityCode}
            setCityCode={setCityCode}
            districtCode={districtCode}
            setDistrictCode={setDistrictCode}
          /> */}
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Thành Phố/Tỉnh"
                name={['address', 'city']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
                  }
                ]}
              >
                {false ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    onChange={setSelectedCityCode}
                    disabled={isCitiesLoading}
                    loading={isCitiesLoading}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {cities.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Quận/Huyện"
                name={['address', 'district']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Quận/Huyện!'
                  }
                ]}
              >
                {false ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isDistrictsLoading}
                    disabled={!form.getFieldValue(['address', 'city'])}
                    onChange={setSelectedDistrictCode}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {districts.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Phường/Xã"
                name={['address', 'ward']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Phường/Xã!'
                  }
                ]}
              >
                {false ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isWardsLoading}
                    disabled={!form.getFieldValue(['address', 'district'])}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {wards.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Đường phố" name={['address', 'street']}>
                {false ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row className="form__submit-box">
            {false ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.rateConfig.degreeList}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}

            <Button
              type="primary"
              htmlType="submit"
              // loading={isSubmitLoading}
            >
              {id ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default WhCustomersImportForm;
