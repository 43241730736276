import React, { useRef, useState, useEffect, useMemo } from 'react';

import DocViewer from 'react-doc-viewer';
import createReport from 'docx-templates';
import moment from 'moment';
import readNumber from 'read-vn-number';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Tabs,
  Typography
} from 'antd';
import {
  CheckOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileWordOutlined,
  PrinterOutlined,
  SaveOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { get, head, omit, sumBy, uniq } from 'lodash';
import { useSelector } from 'react-redux';

import {
  updateByWhPartnerSuccessSelector,
  useCreateWhReceiptVoucher,
  useCreateWhReceiptVoucherByWhPartner,
  useInitWhReceiptVoucher,
  useResetWhReceiptVoucher,
  useUpdateWhReceiptVoucher,
  useUpdateWhReceiptVoucherByWhPartner,
  useConfirmWhReceiptVoucher,
  useApproveWhReceiptVoucher,
  useRejectWhReceiptVoucher,
  useWhReceiptVoucher,
  useResetWhReceiptVoucherAction
} from '~/hooks/whReceiptVoucher';
import { useMatchOrPolicy, useMatchPolicy, useResetWhPaymentVoucher } from '~/hooks';
import POLICY, { CORE_ACTION, GROUP_POLICY } from '~/constants/policy';
import api from '~/api';
import myFile from '~/assets/templates/PT_TEMPLATE_V2.docx';
import {
  BaseBorderBox,
  DebounceSelect,
  WithPermission
} from '~/components/Common';
import {
  COMPONENT_MODES,
  CURRENCY,
  CURRENCY_STRING,
  LANGUAGE,
  MAX_PAGINATION_LIMIT,
  WH_BILL_ITEM_STATUS,
  WH_PAYMENT_METHOD,
  WH_VOUCHER_ACTION_NAME,
  WH_VOUCHER_STATUS
} from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';

import { fromJSON, toJSON } from '../parser';
import AccountingDetails from '../AccountingDetailsTable/AccountingDetailsTable';

import './index.scss';
import { useConfirmPrepaymentWhBill, useCreateWhPaymentVoucher, useGetNewIssueNumber, useProfile, useResetWhBillConfirm } from '~/hooks';
import { floorFormatter } from '~/utils/helper';
import FormSettingDocs from '../components/FormSettingDocs';
import { capitalizeFirstLetter } from '~/hooks/utils';
import LoadingWhBill from '~/components/WhBill/LoadingWhBill';

import toastr from 'toastr'
import HistoryLogs from '~/components/WhBill/HistoryLog';

const FormItem = Form.Item;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Option } = Select;

const DEFAULT_ACCOUNT = 1111;
const mainRowGutter = 24;

// const mainColLayout = {
//   md: { span: 24 },
//   lg: { span: 12 },
//   xl: { span: 10 }
// };

// const verticalLayout = {
//   wrapperCol: { sm: 24, md: 12 },
//   labelCol: { sm: 24, md: 5, lg: 8 }
// };

const Printer = ({ url }) => {
  const docs = [
    { uri: url }
    // { uri: "https://url-to-my-pdf.pdf" },
    // { uri: require("./example-files/pdf.pdf") }, // Local File
  ];

  return <DocViewer documents={docs} />;
};

const WhReceiptVoucherForm = ({
  isHandledByWhPartner,
  onCancel,
  onPassedWhReceiptVoucherChange,
  passedWhReceiptVoucher, // only use when isHandledByWhPartner
  whAppointment,
  whBill,
  whBillItem,
  whReceiptVoucherId,
  initPrepaymentValues,
  noWhBillItem, //  tạm ứng
  CustomerCancel, // Chấm dứt đơn hàng
  onCustomerCancel,
  onCloseReceiptVouchersModal,
  additional,
  customerIds,
  amountMoney
}) => {
  const isHaveUpdateVoucher = useMatchOrPolicy(GROUP_POLICY(CORE_ACTION.UPDATE)?.MANAGE_VOUCHER_IN_WHBILL)
  const isHaveCreateVoucher = useMatchOrPolicy(GROUP_POLICY(CORE_ACTION.WRITE)?.MANAGE_VOUCHER_IN_WHBILL)
  const config = {
    getReason : () => {
      if(noWhBillItem) return "Tạm ứng"
      if(onCustomerCancel) return "Chấm dứt"
      return "Thanh toán"
    },
    getAmountMoneyContent : () => {
      if(noWhBillItem) return get(initPrepaymentValues, 'prepaymentAmount', 0)
      if(onCustomerCancel) return get(whBill, 'subTotal', 0)
      return 0
    }

  }
  const [form] = Form.useForm();
  const ref = useRef();
  const [profile] = useProfile();
  const history = useHistory();

  const [voucher,setVoucher] = useState(null)
  const [loadingCreatePaymentVoucher,setLoadingCreateLoadingVoucher] = useState(false)
  const callbackAfterCreateVoucher = async() => {
    const dataNow = moment().format('YYYY-MM-DD')
// if Total Amount of voucher > total amount of Bill Will Create payment voucher
if(get(voucher,'whBillId')){
  setLoadingCreateLoadingVoucher(true)
  const bill = await api.whBill.getById(get(voucher,'whBillId'))
  if(bill){
   
    const amoutBill = get(bill,'remainingAmount',0)
    const sumAccountDetail =  sumBy([...ref.current.getAccountingDetailsData()],(item) => get(item,'amountOfMoney',0))
    const ElderAmout = sumAccountDetail - amoutBill
    if(ElderAmout > 0){
      const whPaymentVoucherWhBill = {...omit(voucher,['issueNumber']),reason : `Khách hàng thanh toán dư đơn hàng ${get(bill,'billNumber','')}`,accountingDate : dataNow,dateOfIssue : dataNow,accountingDetails : [{amountOfMoney : ElderAmout,content:"Khách hàng thanh toán dư",creditAccount : 811,debitAccount : 1111,key : 0}]}
      handleCreatePayment(whPaymentVoucherWhBill)
    }
    else{
      history.push('/wh-voucher');
      setLoadingCreateLoadingVoucher(false)
    }
  }
}
  }
  const [isSubmitLoading, handleCreate] = useCreateWhReceiptVoucher(callbackAfterCreateVoucher);
  const [isSubmitLoadingPayment, handleCreatePayment] = useCreateWhPaymentVoucher(() => {
    setLoadingCreateLoadingVoucher(false)
    toastr.success("Đã tạo phiếu chi do khách hàng thanh toán dư")
  });
  const [isSubmitByWhPartnerLoading, handleCreateByWhPartner] =
    useCreateWhReceiptVoucherByWhPartner();
    const [isConfirmPrepaymentLoading, handleConfirmPrepayment] = useConfirmPrepaymentWhBill(onCloseReceiptVouchersModal);
  const [, handleUpdate] = useUpdateWhReceiptVoucher();
  const [, handleUpdateByWhPartner] = useUpdateWhReceiptVoucherByWhPartner(); // FIXME
  const [issueNumber,loadingGetIssueNumber] = useGetNewIssueNumber(whBill)
  const [, handleConfirm] = useConfirmWhReceiptVoucher();
  const [, handleApprove] = useApproveWhReceiptVoucher();
  const [, handleReject] = useRejectWhReceiptVoucher();

  const id = whReceiptVoucherId;
  const [whReceiptVoucher, isGetWhReceiptVoucherLoading] = useWhReceiptVoucher(
    id,
    isHandledByWhPartner
  ); // since partner can not call this API, we want it to mute the toast when failed
  const initWhReceiptVoucher = useInitWhReceiptVoucher(whReceiptVoucher);
  const updateByWhPartnerSuccess = useSelector(
    updateByWhPartnerSuccessSelector
  );

  const [activeKey, setActiveKey] = useState(1);
  const [isPrinting, setIsPrinting] = useState(false);
  const [initCustomerAccounts, setInitCustomerAccounts] = useState([]);
  const [initStaffs, setInitStaffs] = useState([]);
  const [isOpenViewer, setIsOpenViewer] = useState(false);
  const [url, setUrl] = useState();
  const [settingDocs,setSettingDocs] = useState({
    name : 'CÔNG TY TNHH WORLDCARE MIỀN TRUNG',
    address:'559 Lê Văn Hiến, P. Khuê Mỹ, Q. Ngũ Hành Sơn,\nTP Đà Nẵng',
    isVisibleSettings: false,
  });
  const onCancelModalSetting = () => {
    setSettingDocs({...settingDocs,isVisibleSettings : false})
  }
  const onOkModalSetting = (data) => {
    setSettingDocs({...data,isVisibleSettings : false})
  }
  const onOpenSettingDocs = () => {
    setSettingDocs({...settingDocs,isVisibleSettings : true})
  }
  const queryFeatchCustomer = useMemo(() => ({
    page: 1,
    limit: MAX_PAGINATION_LIMIT,
    customerIds: customerIds ? customerIds : null
  }), [customerIds]);

  useEffect(() => {
    const getInitCustomerAccount = async (customerId) => {
      try {
        const { data } = await api.whCustomerManagement.getById(customerId);
        setInitCustomerAccounts(
          [data].map((item) => {
            return {
              ...item,
              label: `${get(item, 'customerIdFull')} - ${get(item, 'fullName')}`
            };
          })
        );
      } catch (error) {
        console.error(error);
      }
    };
    const getInitStaff = async (employeeId) => {
      // try {
      //   let response =null
      //   response = await api.userEmployee.getById(employeeId);
      //   if(!!response.data){
      //     response = get(response,'data')
      //   }
      //   else{
      //     response = await api.employee.getById(employeeId);

      //   }
      //   setInitStaffs([response]);
      // } catch (error) {
      //   console.error(error);
      // }
       try {
        let response = await api.userEmployee.getById(employeeId);
          setInitStaffs([get(response,'data')]);
      } catch (error) {
        console.error(error);
      }
    };
    // form.resetFields();

    let { customerId, employeeId } = initWhReceiptVoucher;
    if (customerId) getInitCustomerAccount(customerId);
    if (employeeId){
      getInitStaff(employeeId)
    }
    else{
      getInitStaff(get(profile,'_id'));
    }
  }, [initWhReceiptVoucher, form,id,profile]);

  // Check for the id, if the id is provided, this form is to display an existing wh-voucher.
  // Otherwise this form is to create a new wh-voucher,
  // then use the provided whBill and whBillItem to prefill some fields in the form.
  useEffect(() => {
    const getCustomerAccount = async (customerAccountId) => {
      try {
        // const { data } = await api.whCustomerManagement.getById(
        //   customerAccountId
        // );
        const res = await api.whCustomerManagement.getById(customerAccountId);
        const { data } = res;
        form.setFieldsValue({
          // ở phiếu thu chi wh-voucher thì Mã Khách Hàng lưu ở `customerNumber`
          customerNumber: data?.customerIdFull,
          customerName: data?.fullName
        });
        setInitCustomerAccounts([data].map((item) => {
          return {
            ...item,
            label: `${get(item, 'customerIdFull')} - ${get(item, 'fullName')}`
          };
        }))
      } catch (error) {
        console.error('error...');
        console.error(error);
      }
    };

    let reason;

    if (id && initWhReceiptVoucher) {
      const { accountingDetails } = initWhReceiptVoucher;
      setDataSource(accountingDetails);
      if(!get(initWhReceiptVoucher,'employeeId')){
        form.setFieldsValue({employeeId:get(profile,'_id')})
      }
    } else {
      if (whBill) {
        const { customerAddress, customerAddressId, customerId } = whBill;
        form.setFieldsValue({
          accountingDate: CustomerCancel ? moment() : moment(get(whBill, 'createdAt',null)),
          customerAddress,
          customerAddressId,
          customerId,
          issueNumber,
          employeeId : get(profile,'_id'),
          // customerName: get(customerAddress, "fullName"),
          /* customerNumber: {
            value: customerId,
            label: customerId.substring(customerId.length - 4, customerId.length),
          }, */
          dateOfIssue: CustomerCancel ? moment() : moment(get(whBill, 'createdAt',null))
        });
        if (whBill?.customer) {
          const data = whBill?.customer;
          form.setFieldsValue({
            customerName: get(data, 'fullName'),
            customerNumber: get(data, 'customerIdFull'),
          });
          setInitCustomerAccounts([
            {
              value: get(data, 'customerIdFull'),
              label: `${get(data, 'customerIdFull')} - ${get(data, 'fullName')}`
            }
          ]);
        } else {
        getCustomerAccount(customerId);
        };
      };

      if (whBillItem) {
        const whServiceCode = get(whBillItem, 'snapshotService.code') || '';
        const whServiceName =
          (get(whBillItem, 'snapshotService.detailName.vi')
            ? `${get(whBillItem, 'snapshotService.name.vi')} - ${get(
                whBillItem,
                'snapshotService.detailName.vi'
              )}`
            : get(whBillItem, 'snapshotService.name.vi')) || '';
        reason = `Thanh toán đơn hàng ${get(
          whBill,
          'billNumber'
        )}, dịch vụ ${whServiceCode} - ${whServiceName}`;
      } else {
        reason =`${config.getReason()} đơn hàng ${get(whBill, 'billNumber')}`;
      }
      if(whAppointment){
        reason += ` - cuộc hẹn ${get(whAppointment, 'code','')}`
      }

      form.setFieldsValue({ reason });
    }
  }, [id, initWhReceiptVoucher, whBill, whBillItem,issueNumber]);

  useEffect(() => {
    if (isHandledByWhPartner && updateByWhPartnerSuccess) {
      onPassedWhReceiptVoucherChange(updateByWhPartnerSuccess);
    }
  }, [isHandledByWhPartner, updateByWhPartnerSuccess]);

  // use initWhReceiptVoucher to merge with other data that should be fetched from the API
  const mergedInitWhReceiptVoucher = useMemo(() => {
    if (!id) {
      return {
        ...initWhReceiptVoucher,
        originalDocument: 0
      };
    }

    // use data from prop, not hook
    if (isHandledByWhPartner && passedWhReceiptVoucher) {
      return { ...fromJSON(passedWhReceiptVoucher) };
    }

    return {
      ...initWhReceiptVoucher,
    };
  }, [id, initWhReceiptVoucher, isHandledByWhPartner, passedWhReceiptVoucher]);
  useEffect(() => {
    form.setFieldsValue(mergedInitWhReceiptVoucher);
    form.setFieldsValue({ paymentMethod: get(mergedInitWhReceiptVoucher, 'paymentMethod') });
  }, [form, mergedInitWhReceiptVoucher]);
  // useResetWhReceiptVoucher();
  // useResetWhPaymentVoucher();
  useResetWhBillConfirm()
  useResetWhReceiptVoucherAction()
  const [dataSource, setDataSource] = useState([
    {
      content: `${config.getReason()} đơn hàng ${get(whBill, 'billNumber')}`,
      debitAccount: DEFAULT_ACCOUNT,
      creditAccount: noWhBillItem ? 141 : null,
      // amountOfMoney: get(whBillItem, "remainingAmount") || get(whBill, "remainingAmount") || 0,
      amountOfMoney: amountMoney ? amountMoney : config.getAmountMoneyContent(),
      // amountOfMoney: 0
    }
  ]);

  const isLoading = isGetWhReceiptVoucherLoading;
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const getCustomerAccounts = async (query) => {
    const response = await api.whCustomerManagement.getAll(query);
    const whCustomerAccounts = get(response, 'docs').map((item) => {
      return {
        ...item,
        label: `${get(item, 'customerIdFull')} - ${get(item, 'fullName')}`
      };
    });
    return whCustomerAccounts;
  };

  const getStaffs = async (query) => {
    // let whCustomerAccounts = []
    //  await Promise.allSettled([
    //   // await api.employee.getAll(query),
    //   await api.userEmployee.getAll(query)
    // ]).then(([
    //   // employee
    //   userEmployee]) => {
    // //  if(get(employee,'status','') === "fulfilled"){
    // //   whCustomerAccounts = [...whCustomerAccounts,...get(employee,'value.docs',[])]
    // //  }
    //  if(get(userEmployee,'status','') === "fulfilled"){
    //   whCustomerAccounts = [...whCustomerAccounts,...get(userEmployee,'value.docs',[])]
    //  }
    // })
    // console.log(whCustomerAccounts,"whCustomerAccounts");
    // return whCustomerAccounts
    const response = await api.userEmployee.getAll(query);
    const whCustomerAccounts = get(response, 'docs').map((item) => ({
      ...item
    }));
    return whCustomerAccounts;
  };
  const updateAction = async (action) => {
    try {
      await form.validateFields();
      const fullValues = form.getFieldsValue(true);

      const whReceiptVoucher = toJSON({
        whBillId: get(whBill, '_id'),
        ...(whBillItem && { whBillItemId: get(whBillItem, '_id') }),
        ...(whAppointment && { whAppointmentId: get(whAppointment, '_id') }),
        ...fullValues,
        ...(!isHandledByWhPartner && {
          accountingDetails: [...ref.current.getAccountingDetailsData()]
        })
      });
    } catch (error) {
      console.error(error);
    }
  };
  const onFinish = async (values) => {
    const dataNow = moment().format('YYYY-MM-DD')
    if(id){ // UPDATING
      if(!isHandledByWhPartner && !isHaveUpdateVoucher) return toastr.error("Bạn không có quyền chỉnh sửa")
    }else{ // CREATE
      if(!isHandledByWhPartner && !isHaveCreateVoucher) return toastr.error("Bạn không có quyền Tạo phiếu")
    }
    try {
      await form.validateFields();
      const fullValues = form.getFieldsValue(true);

      const whReceiptVoucher = toJSON({
        whBillId: get(whBill, '_id'),
        ...(whBillItem && { whBillItemId: get(whBillItem, '_id') }),
        ...(whAppointment && { whAppointmentId: get(whAppointment, '_id') }),
        ...(additional && { additional }), // add additional payment or receipt
        ...fullValues,
        ...(!isHandledByWhPartner && {
          accountingDetails: [...ref.current.getAccountingDetailsData()]
        })
      });
      const whReceiptCustomerCancelVoucher = toJSON({
        whBillId: get(whBill, '_id'),
        ...(whBillItem && { whBillItemId: get(whBillItem, '_id') }),
        ...(whAppointment && { whAppointmentId: get(whAppointment, '_id') }),
        ...fullValues,
        ...(!isHandledByWhPartner && {
          accountingDetails: [...ref.current.getAccountingDetailsData()]
        }),
        status: WH_VOUCHER_STATUS.CUSTOMER_CANCEL,
        dateApproved: moment(),
        totalAmount: config.getAmountMoneyContent(),
      });
      const whReceiptVoucherWhBill = toJSON({
        whBillId: get(whBill, '_id'),
        ...(whBillItem && { whBillItemId: get(whBillItem, '_id') }),
        ...(whAppointment && { whAppointmentId: get(whAppointment, '_id') }),
        ...fullValues,
        ...(!isHandledByWhPartner && {
          accountingDetails: [
            {
              ...ref.current.getAccountingDetailsData()[0],
              amountOfMoney: get(initPrepaymentValues, 'prepaymentAmount', 0)
            }
          ]
        }),
        status: WH_VOUCHER_STATUS.APPROVED,
        dateApproved: moment(),
        totalAmount: get(initPrepaymentValues, 'prepaymentAmount', 0),
        // whAppointmentId:null,
        // whBillItemId:null,
        _id:get(whBill, '_id')
      });

     
      if (noWhBillItem) {
        handleConfirmPrepayment(whReceiptVoucherWhBill); // No whBillItem
      } else if(CustomerCancel){
        onCustomerCancel(whReceiptCustomerCancelVoucher) // Customer cancel
      } else {
        if (id) {
          if (!isHandledByWhPartner) { // Update voucher is not handled by whPartner
            handleUpdate({ ...whReceiptVoucher, id: head(id.split('&')) });
          } else {
            handleUpdateByWhPartner({ // Update voucher is handled by whPartner
              ...whReceiptVoucher,
              id: head(id.split('&')),
              employeeId:null
            });
          }
        } else {
          if (!isHandledByWhPartner) {// Create voucher
            setVoucher(whReceiptVoucher)
            handleCreate(whReceiptVoucher);
              // if Total Amount of voucher > total amount of Bill Will Create payment voucher
      if(get(whReceiptVoucher,'whBillId')){
        const bill = await api.whBill.getById(get(whReceiptVoucher,'whBillId'))
        if(bill){
          const amoutBill = get(bill,'remainingAmount',0)
          const sumAccountDetail =  sumBy([...ref.current.getAccountingDetailsData()],(item) => get(item,'amountOfMoney',0))
          const ElderAmout = sumAccountDetail - amoutBill
          if(ElderAmout > 0){
            const whPaymentVoucherWhBill = {...whReceiptVoucher,reason : "Khách hàng thanh toán dư",accountingDate : dataNow,dateOfIssue : dataNow,accountingDetails : [{amountOfMoney : ElderAmout,content:"Khách hàng thanh toán dư",creditAccount : 811,debitAccount : 1111,key : 0}]}
            handleCreatePayment(whPaymentVoucherWhBill)
          }
        }
      }
      
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onTabClick = (key) => {
    setActiveKey(key);
  };
  const onPrint = async (viewOnly = false) => {
    const downloadURL = (data, fileName) => {
      const a = document.createElement('a');
      a.href = data;
      a.download = fileName;
      document.body.appendChild(a);
      a.style = 'display: none';
      a.click();
      a.remove();
    };

    const saveDataToFile = (data, fileName, mimeType) => {
      const blob = new Blob([data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      downloadURL(url, fileName, mimeType);
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    };

    const renderFile = async (data, fileName, mimeType) => {
      const blob = new Blob([data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);

      // const res = await api.whReceiptVoucher.upload(blob); // cái này okie
      // const res = await api.whReceiptVoucher.upload(url);
      // setUrl(get(res, "url"));
      setUrl('https://calibre-ebook.com/downloads/demos/demo.docx');
      setIsOpenViewer(true);
    };

    setIsPrinting(true);
    const template = await fetch(myFile).then((res) => res.arrayBuffer());

    try {
      await form.validateFields();

      const {
        customerAddress,
        customerName,
        issueNumber,
        originalDocument,
        reason,
        accountingDate,
        employeeId
      } = form.getFieldsValue();
      const employee = await api.userEmployee.getById(employeeId)
      const totalAmountOfMoney = sumBy(dataSource, (item) =>
        Number(get(item, 'amountOfMoney'))
      );
      const totalAmountOfMoneyString = `${readNumber(totalAmountOfMoney)} ${
        CURRENCY_STRING[CURRENCY.VND]
      }`;
      const debitAccounts = uniq(
        dataSource.map((item) => get(item, 'debitAccount'))
      ).join(', ');
      const creditAccounts = uniq(
        dataSource.map((item) => get(item, 'creditAccount'))
      ).join(', ');

      // const accountingDateDD = moment.isMoment(get(initWhReceiptVoucher,'dateApproved'))
      //   ? get(initWhReceiptVoucher,'dateApproved').date()
      //   : moment(get(initWhReceiptVoucher,'createdAt')).date();
      // const accountingDateMM =
      //   (moment.isMoment(get(initWhReceiptVoucher,'dateApproved'))
      //     ? get(initWhReceiptVoucher,'dateApproved').month()
      //     : moment(get(initWhReceiptVoucher,'createdAt')).month()) + 1; // month in Moment is zero-indexed
      // const accountingDateYY = moment.isMoment(get(initWhReceiptVoucher,'dateApproved'))
      //   ? get(initWhReceiptVoucher,'dateApproved').year()
      //   : moment(get(initWhReceiptVoucher,'createdAt')).year();
        const accountingDateDD = get(initWhReceiptVoucher,'dateApproved') ?  moment(get(initWhReceiptVoucher,'dateApproved')).date() : moment(get(initWhReceiptVoucher,'createdAt')).date()
      const accountingDateMM = get(initWhReceiptVoucher,'dateApproved') ?  moment(get(initWhReceiptVoucher,'dateApproved')).month() + 1 : moment(get(initWhReceiptVoucher,'createdAt')).month() + 1
      const accountingDateYY = get(initWhReceiptVoucher,'dateApproved') ?  moment(get(initWhReceiptVoucher,'dateApproved')).year() : moment(get(initWhReceiptVoucher,'createdAt')).year()
      const report = await createReport({
        template,
        cmdDelimiter: ['{#', '#}'],
        data: {
          accountingDateDD,
          accountingDateMM,
          accountingDateYY,
          creditAccounts,
          customerAddress: get(customerAddress, 'street') || customerAddress,
          customerName,
          debitAccounts,
          issueNumber,
          originalDocument,
          reason,
          totalAmountOfMoney : floorFormatter(totalAmountOfMoney),
          totalAmountOfMoneyString : capitalizeFirstLetter(totalAmountOfMoneyString),
          EmployeeName:get(employee,'data.fullName',''),
          nameCompany : get(settingDocs,'name',''),
          addressCompany : get(settingDocs,'address','')
        }
      });

      if (viewOnly) {
        renderFile(
          report,
          `${issueNumber}.docx`,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      } else {
        saveDataToFile(
          report,
          `${issueNumber}.docx`,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsPrinting(false);
    }
  };

  const onCustomerIdChange = (value, option) => {
    const { id } = option;
    if (id)
      form.setFieldsValue({
        customerId: id
      });
  };

  const onWhVoucherStatusChange = async (status) => {
    if(!isHaveUpdateVoucher) return toastr.error("Bạn không có quyền chỉnh sửa")
    await form.validateFields()
    confirm({
      title: `Bạn có muốn ${
        WH_VOUCHER_ACTION_NAME[status][LANGUAGE.VI]
      } phiếu này?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        try {
         
          const fullValues = form.getFieldsValue(true);

          const whReceiptVoucher = toJSON({
            whBillId: get(whBill, '_id'),
            ...(whBillItem && { whBillItemId: get(whBillItem, '_id') }),
            ...(whAppointment && {
              whAppointmentId: get(whAppointment, '_id')
            }),
            ...fullValues,
            ...(!isHandledByWhPartner && {
              accountingDetails: [...ref.current.getAccountingDetailsData()]
            }),
            totalAmount:sumBy([...ref.current.getAccountingDetailsData()],(item) => get(item,'amountOfMoney',0))
          });
          switch (status) {
            case WH_VOUCHER_STATUS.CONFIRMED:
              handleConfirm({ ...whReceiptVoucher, id: head(id.split('&')) });
              break;
            case WH_VOUCHER_STATUS.APPROVED:
              handleApprove({ ...whReceiptVoucher, id: head(id.split('&')) });
              break;
            case WH_VOUCHER_STATUS.REJECT:
              handleReject({ ...whReceiptVoucher, id: head(id.split('&')) });
              break;
            default:
              break;
          }
        } catch (error) {
          console.error(error);
        }

        // setIsUpdatingStatus(true);
        // try {
        //   await new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       // setIsUpdatingStatus(false);
        //       resolve(); // nếu không resolve ở đây thì `finally` không chạy
        //     }, 3000);
        //   });
        // } catch (error) {
        //   console.error("error...");
        //   console.error(error);
        // } finally {
        //   setIsUpdatingStatus(false);
        // }
      },
      onCancel() {}
    });
  };

  const onValuesChange = (changes) => {
    const { customerNumber, employeeGroupId } = changes;

    if (customerNumber) {
      const label = get(form.getFieldValue('customerNumber'), 'label');
      const customerName = get(label.split(' - '), '1');
      form.setFieldsValue({
        // customerNumber: { value: get(customerNumber, "value"), label: get(sth.split(" - "), "0") },
        customerName
      });
    }

    // if (address && head(address)) {
    //   const { lat, lng, address: fullAddress } = head(address);
    //   setDefaultCenter({ lat, lng });
    // }

    if (employeeGroupId) {
      // TODO: should refactor this code
      form.setFieldsValue({
        literacy: {
          ...form.getFieldValue('literacy')
        }
      });
    }
  };
  return (
    // <div className="wh-service-form page-wraper page-content">
    <div className="page-wraper">
      <div className="container-fluid">
        {/* <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} phiếu thu`}
          routes={[{ path: PATH_APP.whVoucher.root, title: 'Danh sách phiếu thu chi' }]}
        /> */}
        <Form
          // initialValues={initWhReceiptVoucher}
          // wrapperCol={{ sm: 24, md: 24, lg: 18 }}
          autoComplete="off"
          form={form}
          initialValues={mergedInitWhReceiptVoucher}
          labelAlign="left"
          labelCol={{ sm: 24, md: 24, lg: 4 }}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          requiredMark={false}
          scrollToFirstError
        >
          <Row
            align="top"
            className="staff-form__logo-row"
            gutter={mainRowGutter}
            justify="space-between"
          >
            <Col
              span={isHandledByWhPartner ? 24 : 16}
              order={isHandledByWhPartner ? 2 : null}
            >
              <BaseBorderBox title={'Thông tin chung'}>
                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      // label={<>Mã khách hàng{` `}<code>customerNumber</code></>}
                      // wrapperCol={{ lg: 20 }}
                      label={'Mã khách hàng'}
                      labelCol={{ lg: 8 }}
                      name="customerNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập mã khách hàng!'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <DebounceSelect
                          initOptions={initCustomerAccounts}
                          fetchOptions={(query) =>
                            getCustomerAccounts({
                              ...query,
                              limit: MAX_PAGINATION_LIMIT,
                              // Gửi thêm customerIds nếu như phiếu thu được gọi từ việc xử lý hành động tạm ứng đơn hàng
                              customerIds: customerIds ? customerIds : null
                            })
                          }
                          labelKey="label"
                          searchKey="keyword"
                          valueKey="customerIdFull"
                          onSelect={onCustomerIdChange}
                          disabled={isHandledByWhPartner}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      // label={<>Tên khách hàng{` `}<code>customerName</code></>}
                      label="Tên khách hàng"
                      labelCol={12}
                      name="customerName"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn tên khách hàng!'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      // label={<>Tên khách hàng{` `}<code>customerName</code></>}
                      // labelCol={12}
                      label="Người nộp"
                      name="customerName"
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                {/* <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      // label={<>Địa chỉ{` `}<code>customerAddressId</code></>}
                      // labelCol={12}
                      label="Địa chỉ"
                      name="customerAddressId"
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input disabled />}
                    </FormItem>
                  </Col>
                </Row> */}

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      // label={<>Địa chỉ{` `}<code>customerAddressId</code></>}
                      // labelCol={12}
                      label="Địa chỉ"
                      name={['customerAddress', 'street']}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      name="reason"
                      label="Lý do nộp"
                      // label={<>Lý do nộp{` `}<code>reason</code></>}
                      // labelCol={12}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled={isHandledByWhPartner} />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  {!isHandledByWhPartner && (
                    <Col span={12}>
                      <FormItem
                        name="employeeId"
                        label="Nhân viên thu"
                        // label={<>Nhân viên thu{` `}<code>employeeId</code></>}
                        labelCol={{ lg: 8 }}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng chọn nhân viên thu'
                          }
                        ]}
                      >
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          <DebounceSelect
                            disabled={isHandledByWhPartner}
                            initOptions={initStaffs}
                            fetchOptions={(query) =>
                              getStaffs({
                                ...query,
                                limit: MAX_PAGINATION_LIMIT
                              })
                            }
                            valueKey="_id"
                            labelKey="fullName"
                            searchKey="keyword"
                          />
                        )}
                      </FormItem>
                    </Col>
                  )}
                  <Col span={12}>
                    <Space>
                      <FormItem
                        label="Kèm theo"
                        name="originalDocument"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập số chứng từ kèm theo!'
                          }
                        ]}
                      >
                        {isLoading ? (
                          <Skeleton.Input active />
                        ) : (
                          <InputNumber
                            min={0}
                            disabled={isHandledByWhPartner}
                          />
                        )}
                      </FormItem>
                      <p>chứng từ gốc</p>
                    </Space>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>

            <Col
              span={isHandledByWhPartner ? 24 : 8}
              order={isHandledByWhPartner ? 1 : null}
            >
              <BaseBorderBox title={'Chứng từ'}>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      label="Ngày hạch toán"
                      name="accountingDate"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày hạch toán!'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <DatePicker
                          format={'DD/MM/YYYY'}
                          placeholder="Ngày hạch toán"
                          disabled={isHandledByWhPartner}
                        />
                      )}
                    </FormItem>
                    <FormItem
                      label="Ngày chứng từ"
                      name="dateOfIssue"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày chứng từ!'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <DatePicker
                          format={'DD/MM/YYYY'}
                          placeholder="Ngày chứng từ"
                          disabled={isHandledByWhPartner}
                        />
                      )}
                    </FormItem>

                    <FormItem
                      label="Số chứng từ"
                      name="issueNumber"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập số chứng từ!'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled />
                      )}
                    </FormItem>
                    {(!isHandledByWhPartner) && <FormItem label="Phương thức thanh toán" name="paymentMethod" labelCol={{ lg: 12 }}>
                        <Select defaultValue={WH_PAYMENT_METHOD.COD} style={{ width: '100%' }}>
                        <Option value={WH_PAYMENT_METHOD.COD} key={WH_PAYMENT_METHOD.COD}>Tiền mặt</Option>
                        <Option value={WH_PAYMENT_METHOD.TRANSFER} key={WH_PAYMENT_METHOD.TRANSFER}>Chuyển khoản</Option>
                      </Select>
                    </FormItem>}
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>
          </Row>

          {!isHandledByWhPartner && (
            <BaseBorderBox title={'Ghi chú'}>
              <Row>
                <Col span={24}>
                  <FormItem
                    label="Ghi chú"
                    name={['note']}
                    labelCol={{ lg: 8 }}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
              </Row>
            </BaseBorderBox>
          )}

          {!isHandledByWhPartner && (
                            <Tabs
                  defaultActiveKey={1}
                  destroyInactiveTabPane
                  onChange={() => {}}
                  onTabClick={onTabClick}
                >
                  <TabPane tab="Hạch toán" key={1}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <AccountingDetails
                        ref={ref}
                        mode={COMPONENT_MODES.EDIT}
                        dataSource={dataSource}
                        amountMoney={amountMoney}
                        isReceipt
                      />
                    </Space>
                  </TabPane>
{/* <TabPane tab="Phiếu chi" key="2">
                <WhPaymentVouchers status={WH_PARTNER_STATUS.WAITING} />
              </TabPane> */}
                </Tabs>
                        )}

          {isHandledByWhPartner && (
            <>
              <BaseBorderBox title={'Hạch toán'}>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Diễn giải"
                      name={['accountingDetails', '0', 'content']}
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled={isHandledByWhPartner} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Số tiền"
                      name={['accountingDetails', '0', 'amountOfMoney']}
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled={isHandledByWhPartner} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Tổng cộng"
                      name={['totalAmount']}
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input disabled={isHandledByWhPartner} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>

              <BaseBorderBox title={'Ghi chú'}>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Ghi chú"
                      name={['note']}
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input />}
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>
            </>
          )}
          
          <WithPermission permission={POLICY.READ_WHHISTORYLOGVOUCHER}>
          <Collapse style={{backgroundColor : 'transparent'}} bordered={false}>
            <Collapse.Panel showArrow={false} style={{backgroundColor : 'transparent',borderBottomColor : 'transparent',marginTop : 0}} header={<Typography.Link>{"(Xem chi tiết Logs)"}</Typography.Link>} key="1">
            <HistoryLogs historyLogs={get(whReceiptVoucher,'historyLogs',[])}/>
            </Collapse.Panel>
          </Collapse>
          </WithPermission>
        

          <Row className="wh-service-form__submit-box">
            <Button
              icon={<SaveOutlined />}
              onClick={onFinish}
              loading={isSubmitLoading || isConfirmPrepaymentLoading || loadingCreatePaymentVoucher}
              type="primary"
              disabled={
                (!isHandledByWhPartner && !isHaveUpdateVoucher && !isHaveCreateVoucher) ||
                (isHandledByWhPartner &&
                get(mergedInitWhReceiptVoucher, 'status') !==
                  WH_VOUCHER_STATUS.CREATED)
              }
            >
              Lưu
            </Button>

            {id &&
              (!get(mergedInitWhReceiptVoucher, 'status') ||
                get(mergedInitWhReceiptVoucher, 'status') ===
                  WH_VOUCHER_STATUS.CREATED) && (
                <WithPermission
                  permission={
                    POLICY.UPDATE_WHUPDATERECEIPTANDPAYMENTVOUCHERSTATUS
                  }
                >
                  <Button
                  disabled={!isHaveUpdateVoucher}
                    icon={<CheckOutlined />}
                    loading={isUpdatingStatus}
                    onClick={() =>
                      onWhVoucherStatusChange(WH_VOUCHER_STATUS.CONFIRMED)
                    }
                  >
                    {
                      WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.CONFIRMED][
                        LANGUAGE.VI
                      ]
                    }
                  </Button>
                </WithPermission>
              )}

            {id &&
              get(mergedInitWhReceiptVoucher, 'status') ===
                WH_VOUCHER_STATUS.CONFIRMED && (
                <Space>
                  <WithPermission
                    permission={
                      POLICY.UPDATE_WHUPDATERECEIPTANDPAYMENTVOUCHERSTATUS
                    }
                  >
                    <Button
                    disabled={!isHaveUpdateVoucher}
                      icon={<CheckOutlined />}
                      loading={isUpdatingStatus}
                      onClick={() =>
                        onWhVoucherStatusChange(WH_VOUCHER_STATUS.APPROVED)
                      }
                    >
                      {
                        WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.APPROVED][
                          LANGUAGE.VI
                        ]
                      }
                    </Button>
                  </WithPermission>
                  <WithPermission
                    permission={
                      POLICY.UPDATE_WHUPDATERECEIPTANDPAYMENTVOUCHERSTATUS
                    }
                  >
                    <Button
                    disabled={!isHaveUpdateVoucher}
                      icon={<CheckOutlined />}
                      loading={isUpdatingStatus}
                      onClick={() =>
                        onWhVoucherStatusChange(WH_VOUCHER_STATUS.REJECT)
                      }
                    >
                      {
                        WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.REJECT][
                          LANGUAGE.VI
                        ]
                      }
                    </Button>
                  </WithPermission>
                </Space>
              )}

            {!isHandledByWhPartner && (
              <div className='buttonSaveFile'>
              <Button
                icon={<FileWordOutlined />}
                loading={isPrinting}
                onClick={() => onPrint()}
              >
                Tải về file Docx
              </Button>
              <Button onClick={onOpenSettingDocs} className='buttonSaveFile--addAfter'><SettingOutlined /></Button>
              </div>
            )}

            {isSubmitLoading || isSubmitByWhPartnerLoading ? (
              <Button disabled>Đóng</Button>
            ) : onCancel ? (
              <Button icon={<CloseCircleOutlined />} onClick={onCancel}>
                Đóng
              </Button>
            ) : (
              <Link to={PATH_APP.whVoucher.root}>
                <Button icon={<CloseCircleOutlined />}>Đóng</Button>
              </Link>
            )}

            {/* <Button
              icon={<EyeOutlined />}
              loading={isPrinting}
              onClick={() => onPrint(true)}
            >
              Xem trước file
            </Button> */}
          </Row>
        </Form>
      </div>
      <Modal visible={isOpenViewer} onCancel={() => setIsOpenViewer(false)}>
        <Printer url={url} />
      </Modal>
      <LoadingWhBill title={'Đang kiểm tra số tiền khách hàng trả'} open={loadingCreatePaymentVoucher}/>
      <Modal width={600} footer={null} title='Thiếp lập file word' visible={get(settingDocs,'isVisibleSettings',false)} onOk={onOkModalSetting} onCancel={onCancelModalSetting}>
        <FormSettingDocs  onOk={onOkModalSetting} initialValues={{...settingDocs}}/>
      </Modal>
    </div>
  );
};

export default WhReceiptVoucherForm;
