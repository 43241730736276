import { Col, Divider, Row } from 'antd'
import SkeletonInput from 'antd/lib/skeleton/Input'
import { range } from 'lodash'
import React from 'react'
import { useGetMyProcessCampaign } from '~/hooks/processCampaign'
import ItemProcess from './ItemProcess'
const Skeleton = (props) => <Row style={{margin : '5px 0'}} justify='space-between' gutter={16}>
<Col span={18}><SkeletonInput  active /></Col>
<Col span={6}><SkeletonInput  active /></Col>
<Divider />
</Row>
export default function ProcessPartner({ ranking }) {
  const { data, isLoading } = useGetMyProcessCampaign(ranking)

  return (
    <div style={{marginTop : 20}}>
    <h3>Danh sách chiến dịch</h3>
    <div style={{maxHeight: '500px',overflowY : 'scroll',marginTop : 20}}>
      {isLoading ? range(5)?.map(e => <Skeleton />) : data?.map(e => <>
        <ItemProcess process={e} />
        <Divider />
      </>)}
    </div>
    </div>
  )
}
