import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Menu,
  Modal,
  Row,
  Table,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, {  useState } from 'react';
import { v4 } from 'uuid';
import {  WH_COURSE_TRAINING_STATUS, WH_COURSE_TRAINING_STATUS_VI } from '~/constants/defaultValue';
import { useUserWorkspace } from '~/hooks';
import {
  useDeleteTrainingCoursePartner,
  useResetTrainingCoursePartner,
  useTrainingCoursePartner,
  useTrainingCoursePartnerPaging,
  useTrainingCoursePartnerQueryParams,
  useTrainingCoursesOfPartner,
  useTrainingCoursesPartner,
  useUpdateTrainingCoursePartnerParams
} from '~/hooks/trainingCoursePartner';
import LinkFile from '../Common/TypeFile/LinkFileImage';
import SkeletonTable from '../Utils/SkeletonTable';
import FormCreate from './WhManagerTrainingCourse.js/FormCreate';
import FormSubmit from './WhManagerTrainingCourse.js/FormSubmit';
import InfoPartner from './WhPartner/InfoPartner';
import './WorkSpaceTimeTrain.scss';
export default function WorkspaceTimeTrain() {
  const [profile] = useUserWorkspace();
  const [id, setId] = useState(null);
  const [OneTrainingCourse, loadingOneTrainingCourse] =
    useTrainingCoursePartner(id);
  const [idDelete, setIdDelete] = useState(null);
  const [query] = useTrainingCoursePartnerQueryParams(profile._id);
  const paging = useTrainingCoursePartnerPaging(query);
  useResetTrainingCoursePartner();
  const [{ onParamChange }] = useUpdateTrainingCoursePartnerParams(query);
  const [trainingCourses, isLoadingTraining] = useTrainingCoursesPartner(
    profile._id
  );
  const [trainingCoursesOfPartner, isLoadingTrainingOfPartner] =
    useTrainingCoursesOfPartner(query);
  const [ismodalCreateVisible, setIsmodalCreateVisible] = useState(false);
  const [ismodalSubmitVisible, setIsmodalSubmitVisible] = useState(false);
  const [isOpenModalDelete, setIsOpenDelete] = useState(false);

  const onCancelDelete = () => {
    setIsOpenDelete(false);
    setIdDelete(null);
  };
  const [loadingDelete, deleteTrainingCoursePartner] =
    useDeleteTrainingCoursePartner(onCancelDelete);
  const onConfirmDelete = () => {
    deleteTrainingCoursePartner({id:idDelete,typeOf : 'partner',userIdAction:get(profile, '_id')});
  };
  const handleOpenModalCreate = () => {
    setIsmodalCreateVisible(true);
  };
  const handleCloseModalCreate = () => {
    setIsmodalCreateVisible(false);
    setId(null);
  };
  const handleOpenModalSubmit = () => {
    setIsmodalSubmitVisible(true);
  };
  const handleCloseModalSubmit = () => {
    setIsmodalSubmitVisible(false);
  };
  const onOpenDelete = (id) => {
    setIsOpenDelete(true);
    setIdDelete(id);
  };
  const onChangeSearchMonth = (date, dateString) => {
    onParamChange({ month: dateString,date:null });
  };
  const onChangeSearchDate = (date, dateString) => {
    onParamChange({ date:dateString,month:null });
  };
  const columns = [
    {
      title: 'STT',
      dataIndex: null,
      key: null,
      render: (text, record, index) => <p>{index + 1}</p>
    },
    {
      title: 'ID khoá đào tạo',
      dataIndex: 'courseId',
      key: 'courseId',
      render: (text, record, index) => <p>{get(text, 'courseIdFull', '')}</p>
    },
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => (
        <p>{moment(text).format('DD/MM/YYYY') || ''}</p>
      )
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
      render: (text, record, index) => <p>{text || ''}</p>
    },
    {
      title: 'File đính kèm',
      dataIndex: 'files',
      key: 'files',
      render: (text, record, index) => (
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'start'
          }}
        >
          {text?.map((e) => {
                return (
                  <LinkFile
                    key={v4()}
                    href={get(e, 'response.url')}
                    url={get(e, 'name')}
                  />
                );
          })}
        </div>
      )
    },
    {
      title: 'Lời nhắn từ người duyệt',
      dataIndex: 'comment',
      align: 'center',
      key: 'comment',
      render: (text, record, index) => <h6>{text || ''}</h6>
    },
    {
      title: 'Số giờ',
      dataIndex: 'time',
      align: 'center',
      key: 'time',
      render: (text, record, index) => <h6>{text || ''}</h6>
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record, index) => (
        <span className={`course-status ${text}`}>{WH_COURSE_TRAINING_STATUS_VI[text] || ''}</span>
      )
    },
    {
      title: 'Thao tác',
      dataIndex: null,
      key: null,
      render: (text, record, index) => (
        <Dropdown
          overlayStyle={{ width: 150 }}
          trigger={['click']}
          placement="bottomCenter"
          overlay={
            <Menu>
              <Menu.Item
                disabled={record.status !== WH_COURSE_TRAINING_STATUS.NEW}
                key={v4()}
                onClick={() => {
                  setId(record._id);
                  setIsmodalCreateVisible(true);
                }}
              >
                <p>Cập nhật</p>
              </Menu.Item>
              <Menu.Item
                disabled={record.status !== WH_COURSE_TRAINING_STATUS.NEW}
                key={v4()}
                onClick={() => onOpenDelete(record._id)}
              >
                <p>Xoá</p>
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
      )
    }
  ];
  return (
    <div className="page-wraper page-content page-content-workSpace">
      <Modal
        footer={null}
        visible={ismodalCreateVisible}
        closable={false}
        onCancel={handleCloseModalCreate}
      >
        <FormCreate
          id={id}
          profile={profile}
          onParamChange={onParamChange}
          OneTrainingCourse={OneTrainingCourse}
          loadingOneTrainingCourse={loadingOneTrainingCourse}
          trainingCourses={trainingCourses}
          isLoadingTraining={isLoadingTraining}
          handleCloseModalCreate={handleCloseModalCreate}
          typeOf='partner'
        />
      </Modal>
      <Modal
        footer={null}
        visible={ismodalSubmitVisible}
        closable={false}
        onCancel={handleCloseModalSubmit}
      >
        <FormSubmit
          onParamChange={onParamChange}
          trainingCourses={trainingCourses}
          isLoadingTraining={isLoadingTraining}
          handleCloseModalSubmit={handleCloseModalSubmit}
          partnerId={get(profile, '_id')}
          partner={profile}
        />
      </Modal>
      <Modal
        style={{ textAlign: 'center' }}
        visible={isOpenModalDelete}
        onCancel={onCancelDelete}
        closable={false}
        footer={null}
      >
        <h5>Bạn có muốn xoá ?</h5>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <Button onClick={onCancelDelete}>Huỷ</Button>
          </Col>
          <Col span={12}>
            <Button
              loading={loadingDelete}
              onClick={onConfirmDelete}
              type="danger"
            >
              Xoá
            </Button>
          </Col>
        </Row>
      </Modal>
      <InfoPartner profile={profile}/>

      <Row justify="space-between">
        <Row align="center">
          <Col style={{ marginRight: '20px' }}>
            <i style={{ fontSize: '16px' }} className="uil uil-schedule"></i>
          </Col>
          <Col>
            <h5 style={{ marginBottom: 0 }}>Quản lý thời gian đào tạo</h5>
          </Col>
        </Row>
        <Button onClick={handleOpenModalCreate} type="primary">
          <PlusOutlined />
        </Button>
      </Row>
      <Row style={{ alignItems: 'center',width:'300px', margin: '10px 0',justifyContent : 'space-between' }}>
        <h6 style={{ marginRight: '10px' }}>Tìm Theo Tháng</h6>
        <DatePicker
          onChange={onChangeSearchMonth}
          value={get(query,'month')  ? moment(get(query,'month')) : null}
          placeholder="Chọn tháng"
          picker="month"
          disabledDate={(current) => parseInt(moment(current).format("YYYY")) !== moment().year()}
        />
      </Row>
      <Row style={{ alignItems: 'center',width:'300px', margin: '10px 0',justifyContent : 'space-between' }}>
        <h6 style={{ marginRight: '10px' }}>Tìm Theo ngày</h6>
        <DatePicker
          onChange={onChangeSearchDate}
          placeholder="Chọn ngày"
          value={get(query,'date')  ? moment(get(query,'date')) : null}
          picker="date"
          disabledDate={(current) => parseInt(moment(current).format("YYYY")) !== moment().year()}
        />
      </Row>
      {isLoadingTrainingOfPartner ? (
        <SkeletonTable columns={columns} rowCount={10} />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          dataSource={trainingCoursesOfPartner || []}
          scroll={{x : 1000}}
          onChange={({ current }) => onParamChange({ page: current })}
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
          size="middle"
        />
      )}
      <Row justify="center" style={{ width: '100%' }}>
        <Button onClick={handleOpenModalSubmit}>Submit</Button>
      </Row>
    </div>
  );
}
