import requester from './requester';

const partnership = {
  getAll: (query) => requester.get('/partnership', query),
  getById: (id) => requester.get(`/partnership/${id}`),
  create: (partnership) => requester.post('/partnership', partnership),
  update: (partnership) =>
    requester.put(`/partnership/${partnership._id}`, partnership),
  delete: (id) => requester.delete(`/partnership/${id}`)
};

export default partnership;
