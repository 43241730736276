import * as Types from '../../constants/actionTypes';
  
  export const getListTrials = query => ({
    type: Types.GET_LIST_TRIALS_REQUEST,
    payload: query
  });
  export const getListTrial = id => ({
    type: Types.GET_LIST_TRIAL_REQUEST,
    payload: id
  });
  export const updateListTrial = instance => ({
    type: Types.UPDATE_LIST_TRIAL_REQUEST,
    payload: instance
  });
  export const createListTrial = instance => ({
    type: Types.CREATE_LIST_TRIAL_REQUEST,
    payload: instance
  });
  export const deleteListTrial = id => ({
    type: Types.DELETE_LIST_TRIAL_REQUEST,
    payload: id
  });
  export const resetListTrialState = () => ({
    type: Types.RESET_LIST_TRIAL_STATE
  });
