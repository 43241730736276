import produce from 'immer';
import { clone, get, uniqBy } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoadingHistory: false,
  getListHistoryFailed: undefined,
  listHistory: [],

  isLoading: false,
  getListFailed: undefined,
  list: [],

  listProductByWareHouse: [],
  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null,

  listPartnerBorrowed: [],
  isLoadingPartnerBorrowed: false,
  getListPartnerBorrowedFailed: undefined,
  pagingPartnerBorrowed: null,
  pagingHistory: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WAREHOUSE_LISTS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_WAREHOUSE_LISTS_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_WAREHOUSE_LISTS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;
    //
    case Types.GET_WAREHOUSE_BYID_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;
    case Types.GET_WAREHOUSE_BYID_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      state.getByIdFailed = null;
      return;
    case Types.GET_WAREHOUSE_BYID_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      state.byId = null;
      return;  
      case Types.GET_WAREHOUSE_PRODUCTLIST_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.listProductByWareHouse = null;
      return;
    case Types.GET_WAREHOUSE_PRODUCTLIST_SUCCESS:
      state.isGetByIdLoading = false;
      state.listProductByWareHouse = payload;
      state.getByIdFailed = null;
      return;
    case Types.GET_WAREHOUSE_PRODUCTLIST_FAILED:
      state.isGetByIdLoading = false;
      // state.getByIdFailed = payload;
      state.listProductByWareHouse = null;
      return;
      //create
    case Types.CREATE_WAREHOUSE_REQUEST:
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_WAREHOUSE_SUCCESS:
      state.createSuccess = payload;
      state.createFailed = null;
      return;
    case Types.CREATE_WAREHOUSE_FAILED:
      state.createFailed = payload;
      state.createSuccess = null;
      return;
    //update
    case Types.UPDATE_WAREHOUSE_REQUEST:
      state.updateSuccess = null;
      state.updateFailed = null;
      return;
    case Types.UPDATE_WAREHOUSE_SUCCESS:
      state.updateSuccess = payload;
      state.updateFailed = null;
      return;
    case Types.UPDATE_WAREHOUSE_FAILED:
      state.updateFailed = payload;
      state.updateSuccess = null;
      return;
      //delete
    case Types.DELETE_WAREHOUSE_REQUEST:
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;
    case Types.DELETE_WAREHOUSE_SUCCESS:
      state.deleteSuccess = payload;
      state.deleteFailed = null;
      return;
    case Types.DELETE_WAREHOUSE_FAILED:
      state.deleteFailed = payload;
      state.deleteSuccess = null;
      return;
    // return initState;
    default:
      return;
  }
}, initState);
