import { CheckCircleOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Table, Tag, Typography } from 'antd';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import TooltipTitle from '~/components/Common/TooltipTitle';
import InfomationProduct from '~/components/WhAppointment/WhAppointmentsByBlockTable/components/InfomationProduct';
import { CONFIRM_VOUCHER_STATUS } from '~/constants/confirmVoucherWarehouse';
import { MAX_PAGINATION_LIMIT, REF_COLLECTION_VOUCHER_WAREHOUSE, TYPE_WAREHOUSE } from '~/constants/defaultValue';
import { useModalConfirmVoucher } from '~/hooks';
import { useConfirmVoucherWarehousesWhPartner } from '~/hooks/confirmVoucherWarehouse';
import { useGetInfoCurrentBranch } from '~/hooks/utils';
import { checkErrorInProductConfirm, checkErrorProductInVoucherWarehouse, mergeSameProductAndMaterial } from '~/hooks/warehouseVoucher';
import { adapterSelectProductInConfirmVoucher, removeListProductOfPartner } from '~/hooks/warehouseVoucher/hookListProduct';
import FormConfirmVoucherWarehouse from '../ConfirmVoucherWarehouse/FormConfirmVoucherWarehouse/FormConfirmVoucherWarehouse';

export default function TableConfirmProduct({ whPartnerId, clonedDataSource, handleSelect, setForm, handleByPartner, supplierId }) {
  const [idViewProduct, setIdViewProduct] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [branch] = useGetInfoCurrentBranch(handleByPartner && null); // 
  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);
  const { idConfirmVoucher, onCloseConfirmVoucher, onOpenConfirmVoucher, visibleConfirmVoucher } = useModalConfirmVoucher();
  const query = useMemo(() => ({
    page: 1,
    limit: MAX_PAGINATION_LIMIT,
    whPartnerId,
    status: [CONFIRM_VOUCHER_STATUS.CONFIRM, CONFIRM_VOUCHER_STATUS.COMPLETED]?.join(',')
  }), [whPartnerId])
  const [confirmVoucherWarehouse, isLoading] = useConfirmVoucherWarehousesWhPartner(whPartnerId && query);

  useEffect(() => {
    let listProduct = [];
    confirmVoucherWarehouse?.forEach(voucher => {
      const { codeSequence, _id: idVoucher } = voucher;
      get(voucher, 'listProduct', [])?.forEach(productVoucher => {
        const { name, product, medicalEquipmentType, batchId } = productVoucher;
        switch (get(medicalEquipmentType, 'type')) {
          case TYPE_WAREHOUSE.CLINIC:
            const batch = get(product, 'batches', [])?.find(item => get(item, '_id') === batchId)
            const nameMaterial = `${name} (${get(product, 'unit.name')}) - Lô (${get(batch, 'lotNumber', '')})`;
            listProduct.push({
              ...productVoucher, codeSequence, idVoucher, newName: nameMaterial,
              supplier: {
                userId: get(branch, '_id'),
                refCollection: REF_COLLECTION_VOUCHER_WAREHOUSE.branch,
                supplierName: get(branch, 'name', '')
              },
              supplierId: get(branch, '_id')
            });
            // Business Logic: Material will get Branch current 

            break;

          case TYPE_WAREHOUSE.WORLDHEALTH:
            const { status } = checkErrorProductInVoucherWarehouse({...product,whPartnerId});
            if (!status) { // No Error
              listProduct.push({ ...productVoucher, codeSequence, idVoucher, newName: name });
            }
            break;

          default:
            break;
        }

      });
    });
    const mergeList = mergeSameProductAndMaterial(listProduct);
    setDataSource(mergeList);
  }, [confirmVoucherWarehouse]);
  useEffect(() => {
    setDataSource([])
  }, [])
  const handleOpenModalViewProduct = (id) => {
    setIsOpenViewProduct(true);
    setIdViewProduct(id);
  };
  const handleCloseModalViewProduct = () => {
    setIsOpenViewProduct(false);
    setIdViewProduct(null);
  };
  const onSelect = (record) => {
    const { supplier } = record;
    if (setForm) {
      let newSupplier = {
        supplier: {
          ...supplier
        }
      }
      setForm(newSupplier);
    }
    const dataConverted = adapterSelectProductInConfirmVoucher(record);
    handleSelect(dataConverted, get(record, 'medicalEquipmentType.type'));
  }
  const columns = [
    {
      title: "Mã thiết bị/NVL",
      dataIndex: 'product',
      key: 'code',
      render: (product, record) => get(record, 'medicalEquipmentType.type') === TYPE_WAREHOUSE.WORLDHEALTH
        ? <Typography.Link onClick={() => handleOpenModalViewProduct(get(record, 'productId'))}>
          {get(product, 'code', '')}
        </Typography.Link>
        : <Typography.Text>
          {get(product, 'variantCode', '')}
        </Typography.Text>

    },
    {
      title: "Tên thiết bị/NVL",
      dataIndex: 'newName',
      key: 'newName',
    },
    {
      title: "Đơn vị cung cấp",
      dataIndex: 'supplier',
      key: 'supplier',
      render: (supplier) => get(supplier, 'supplierName', '')
    },
    {
      title: "Mã phiếu",
      dataIndex: 'codeSequence',
      key: 'codeSequence',
      align: 'center',
      render: (codeSequence, record) => <Typography.Link onClick={() => onOpenConfirmVoucher(get(record, 'idVoucher'))}>
        {codeSequence}
      </Typography.Link>
    },
    {
      title: "Số lượng",
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: "Buổi hẹn",
      dataIndex: 'whAppointments',
      key: 'whAppointments',
      align: 'center',
      render: (whAppointments) => whAppointments?.map(whAppointment => <p>
        <Typography.Link onClick={() => window.open(`wh-bill-item/${get(whAppointment, 'whBillItemId')}/update-appointments`)} strong>{get(whAppointment, 'code', '')}</Typography.Link>
      </p>)
    },
    {
      title: "Chọn",
      key: 'action',
      render: (item, record, index) => {
        const { msg, status } = checkErrorInProductConfirm({ ...record, supplierIdSelected: supplierId });
        const isExist = clonedDataSource?.some(e => get(e, 'productId') === get(record, 'productId'));
        if (isExist) return <Tag className='tagChecked' icon={<CheckCircleOutlined className='checked' />} color="success">Đã chọn</Tag>
        return <TooltipTitle
          titleTooltip={msg}
          content={<Button disabled={status} onClick={() => onSelect(record)}><SendOutlined /></Button>}
        />
      }
    },

  ];
  return (
    <div>
      <Table
        rowKey={(rc) => rc._id}
        dataSource={removeListProductOfPartner(dataSource, whPartnerId)}
        loading={isLoading}
        columns={columns}
        size='small'
        pagination={false}
        style={{ maxHeight: '400px', overflowY: 'scroll' }}
        locale={{
          emptyText: <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={
              <>
                <p>Trống</p>
                <span>
                  Chưa chọn đối tác hoặc đối tác không có trang bị đã xác nhận
                </span>
              </>
            }
          >
          </Empty>
        }}
      />
      <Modal
        destroyOnClose
        title={`Thông tin thiết bị`}
        width={700}
        visible={isOpenViewProduct}
        closable={false}
        footer={null}
        onCancel={handleCloseModalViewProduct}>
        <InfomationProduct id={idViewProduct} />
      </Modal>

      <Modal
        width={'auto'}
        visible={visibleConfirmVoucher}
        footer={null}
        onCancel={onCloseConfirmVoucher}
        destroyOnClose
      >
        <FormConfirmVoucherWarehouse id={idConfirmVoucher} onCancel={onCloseConfirmVoucher} />
      </Modal>
    </div>
  )
}
