import React from 'react';

import { get } from 'lodash';
import { Col, Row, Space } from 'antd';

import { LANGUAGE } from '~/constants/defaultValue';

import WhServiceQuestionOptionView from './WhServiceQuestionOptionView';

const WhServiceQuestionView = ({
  language = LANGUAGE.VI,
  className,
  question,
}) => {
  const { title, options } = question;
  return (
    <div className={className}>
      <Space direction='vertical' size="middle">
        <Row gutter={[48, 0]}>
          <Col sm={24} lg={24}>
            <p>{get(title, language)}</p>
          </Col>
        </Row>
        <Row gutter={[48, 0]} justify='flex-start'>
          {options?.map((item, index) => {
            return <WhServiceQuestionOptionView key={index} option={item} language={LANGUAGE.VI} />
          })}
        </Row>
      </Space>
    </div>
  )
}

export default WhServiceQuestionView;
