import { InfoCircleOutlined } from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { formatNumberThreeComma } from '~/hooks/utils';
import { useReportKpIById } from '~/hooks/whReport';
import TableDetail from './TableDetail';
const styleFontSize = { fontSize: 'calc(0.2vw + 0.65rem)' };
export default function TableKpi({
  id: partnerId,
  setTotalDetailAll,
  date,
  partner,
}) {
  const [data, loading] = useReportKpIById(partnerId, date);
  useEffect(() => {
    const totalKpi = data?.reduce(
      (sum, curr) => (sum += get(curr, 'sumPrice', 0)),
      0
    );
    setTotalDetailAll('kpi', parseInt(totalKpi));
  }, [partnerId, data]);
  const dataNow = useMemo(
    () => ({
      createdAt: moment().format('YYYY-MM-DD'),
      date: moment().format('YYYY-MM-DD'),
      diffPrice: 0,
      maximumRating: get(
        partner,
        'employeeGroup[0].JobQuota.JobQuotaDetail.maximumRating',
        0
      ),
      sumPrice: 0,
      totalAllMoneyOfService: 0
    }),
    [partner]
  );
  const addMonthNow = useMemo(() => {
    const dataCheck = data?.some(
      (e) =>
        {
          return moment(get(e, 'createdAt')).format('MM-YYYY') === // data have Today
          moment().format('MM-YYYY') 
        }
    );
    if (dataCheck){
      return data;
    }else{
      if(moment().isBetween(moment(get(date,'startDate')),moment(get(date,'endDate')))){
        return [...data, dataNow];
      }
      else{
        return data;
      }
    } 
  }, [data,date]);
  const columns = [
    {
      title: 'STT',
      key: null,
      width: 50,
      align: 'center',
      render: (item, record, index) => <span>{index + 1}</span>
    },
    {
      title: 'Tháng',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      render: (item, record) => <span>{moment(item).format('MM')}</span>
    },
    {
      title: 'Định mức tối đa',
      key: 'maximumRating',
      dataIndex: 'maximumRating',
      align: 'center',
      render: (item, record) => {
        return (
          <span>
            {formatNumberThreeComma(get(record, 'maximumRating', 0)?.toFixed(0))}
          </span>
        );
      }
    },
    {
      title: 'Thu nhập',
      key: 'totalAllMoneyOfService',
      dataIndex: 'totalAllMoneyOfService',
      align: 'center',
      render: (item, record) => {
        return (
          <span>
            {formatNumberThreeComma(
              get(record, 'totalAllMoneyOfService', 0)?.toFixed(0)
            )}
          </span>
        );
      }
    },
    {
      title: (
        <Tooltip
          placement="topLeft"
          zIndex={2001}
          title={
            <p style={styleFontSize}>
              Vượt định mức = Thu nhập - Định mức tối đa
            </p>
          }
        >
          <Badge
            size="small"
            color="#9B9999"
            // offset={[9, 3]}
            count={<InfoCircleOutlined />}
          >
            <Text>Vượt định mức</Text>
          </Badge>
        </Tooltip>
      ),
      key: 'diffPrice',
      dataIndex: 'diffPrice',
      align: 'center',
      render: (item, record) => {
        return (
          <span>
            {formatNumberThreeComma(get(record, 'diffPrice', 0).toFixed(0))}
          </span>
        );
      }
    },
    {
      title: (
        <Tooltip
          // placement='leftTop'
          zIndex={2001}
          title={
            <p style={styleFontSize}>
              Thưởng = (Thu nhập - Định mức tối đa) * 1.4
            </p>
          }
        >
          <Badge
            size="small"
            color="#9B9999"
            // offset={[9, 3]}
            count={<InfoCircleOutlined />}
          >
            <Text>Thưởng</Text>
          </Badge>
        </Tooltip>
      ),
      key: 'bouns',
      dataIndex: 'bouns',
      align: 'center',
      render: (item, record) => {
        return (
          <span>
            {formatNumberThreeComma(
              (get(record, 'diffPrice', 0) * 1.4).toFixed(0)
            )}
          </span>
        );
      }
    },
    {
      title: (
        <Tooltip
          placement="topLeft"
          zIndex={2001}
          title={
            <p style={styleFontSize}>Thành tiền = Thưởng + Thu nhập</p>
          }
        >
          <Badge
            size="small"
            color="#9B9999"
            // offset={[9, 3]}
            count={<InfoCircleOutlined />}
          >
            <Text>Thành tiền</Text>
          </Badge>
        </Tooltip>
      ),
      key: 'sumPrice',
      dataIndex: 'sumPrice',
      align: 'center',
      render: (item, record) => {
        return (
          <span>
            {formatNumberThreeComma(get(record, 'sumPrice', 0).toFixed(0))}
          </span>
        );
      }
    }
  ];
  return (
    <div>
      <TableDetail
        scrollWidth={1000}
        loading={loading}
        pagination={false}
        dataSource={addMonthNow}
        columns={columns}
        title={<Tooltip
          placement="topLeft"
          zIndex={2001}
          title={
            <p style={styleFontSize}>Hệ thống sẽ tính thu nhập vào 3 ngày cuối tháng</p>
          }
        >
          <Badge
            size="small"
            style={{color: 'white'}}
            offset={[9, 3]}
            count={<InfoCircleOutlined />}
          >
            <Text   style={{color: 'white'}}>Thu nhập theo KPI (F)</Text>
          </Badge>
        </Tooltip>}
        footer={null}
      />
    </div>
  );
}

