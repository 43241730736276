import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import toastr from 'toastr';
import api from '~/api';
import { WH_APPOINTMENT_STATUS } from '~/constants/defaultValue';

export default function ModalConfirmCancel({
  onCloseConfirmCancel,
  whBillItemId,
  whBillId,
  setWhAppointments,
  whAppointments,
  onOpenPaymentVouchersModal,
  setTotalMoneyCancel,
  setNote,
  note,
  setIsShowButtonCancel,
  setNoteShow,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
    const body = {
      whBillId,
      whBillItemId,
      message: note
    };
    try {
      setIsLoading(true);
      const res = await api.whBillItem.cancelWhAppointments(body);
      toastr.success(res?.message ?? 'Đã huỷ buổi hẹn thành công');
      const totalAmountPaid = res?.totalAmountPaid;
      if (totalAmountPaid > 0) {
        setTotalMoneyCancel(totalAmountPaid);
        setTimeout(() => onOpenPaymentVouchersModal(), 200);
      };
      onCloseConfirmCancel();
      const filterData = whAppointments?.map((item) => {
        if (item?.status === 'NEW') {
          return {
            ...item,
            status: 'CANCELLED'
          }
        } else {
          return item
        };
      });
      setWhAppointments(filterData);
      setNoteShow(note);
      setIsShowButtonCancel(false);
    } catch (error) {
      toastr.error('Huỷ buổi hẹn thất bại');
    };
    setIsLoading(false);
  };

  const validation = () => {
    if (!note) {
      toastr.error('Vui lòng nhập lý do huỷ');
    } else {
      handleConfirm();
    };
    return true;
  };

  return (
    <>
      <Form.Item label={'Lý do'}>
        <Input.TextArea value={note} rows={3} onChange={(e) => setNote(e.target.value)}/>
      </Form.Item>
      <Row align="center">
        <Col span={4}>
          <Button loading = {isLoading} type='primary' onClick={() => {
            onCloseConfirmCancel(()=> setNote(''));
          }}>Huỷ</Button>
        </Col>
        <Col span={4}>
          <Button loading = {isLoading} type='primary' onClick={validation}>Xác nhận</Button>
        </Col>
      </Row>
    </>
  );
}
