import React from 'react';
import { Form, Select } from 'antd';
import { WH_BILL_STATUS } from '~/constants/defaultValue';

const FilterWhBillStatus = ({ onChange, value, showLabel = true, style, showOnlyKeys }) => {
  let whBillStatusKeys = Object.keys(WH_BILL_STATUS)
  if (showOnlyKeys) {
    whBillStatusKeys = whBillStatusKeys.filter(key => showOnlyKeys.includes(key));
  }

  return (
    <Form.Item
      label={`${showLabel ? "Trạng thái:" : ""}`}
      labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
    >
      <Select
        allowClear
        value={value}
        onChange={onChange}
        showSearch
        autoComplete="off"
        placeholder="Tất cả"
        style={style}
      >
        {whBillStatusKeys.map((status) => (
          <Select.Option key={status} value={status}>
            {WH_BILL_STATUS[status]}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FilterWhBillStatus;
