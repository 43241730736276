import React, { useState, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Tooltip, Badge } from 'antd';
import Text from 'antd/lib/typography/Text';
import { InfoCircleOutlined } from '@ant-design/icons';

//Import Breadcrumb
import Breadcrumbs from '../Common/Breadcrumb';

//Import Components
import MiniWidget from './mini-widget';
import SalesAnalyticsChart from './salesanalytics-chart';

//Import Image
import {
  usePharmacyOverviewToday,
  usePharmacyOverviewDaily,
  usePharmacyOverviewMonthly,
  usePharmacyOverviewQuarterly,
  usePharmacyOverviewYearly
} from '~/hooks';
import MostCustomerOrder from './mostCustomerOrder';

const series1 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
  }
];

const options1 = {
  fill: {
    colors: ['#5b73e8']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    }
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series2 = [55];

const options2 = {
  fill: {
    colors: ['#34c38f']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series3 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
  }
];

const options3 = {
  fill: {
    colors: ['#f1b44c']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    }
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series4 = [70];

const options4 = {
  fill: {
    colors: ['#5b73e8']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series5 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
  }
];

const options5 = {
  fill: {
    colors: ['#ffacac']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    }
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const TODAY = 'today';
const MONTHLY = 'monthly';
const WEEKLY = 'weekly';
const YEARLY = 'yearly';
const QUARTERLY = 'quarterly';

const PharmacyOnlineDashboard = () => {
  const [viewMode, setViewMode] = useState(WEEKLY);
  const [overviewPharmacyToday] = usePharmacyOverviewToday();
  const [overviewPharmacyDaily] = usePharmacyOverviewDaily();
  const [overviewPharmacyMonthly] = usePharmacyOverviewMonthly();
  const [overviewPharmacyQuarterly] = usePharmacyOverviewQuarterly();
  const [overviewPharmacyYearly] = usePharmacyOverviewYearly();

  const data = useMemo(() => {
    const source =
      viewMode === TODAY
        ? overviewPharmacyToday
        : viewMode === WEEKLY
        ? overviewPharmacyDaily
        : viewMode === MONTHLY
        ? overviewPharmacyMonthly
        : viewMode === QUARTERLY
        ? overviewPharmacyQuarterly
        : overviewPharmacyYearly;
    return source;
  }, [
    viewMode,
    overviewPharmacyToday,
    overviewPharmacyDaily,
    overviewPharmacyMonthly,
    overviewPharmacyQuarterly,
    overviewPharmacyYearly
  ]);

  const reports = [
    {
      id: 1,
      title: (
        <Tooltip placement="rightBottom" title={'Tất cả trạng thái đơn hàng'} overlayClassName="ant-tooltip-inner__case-chart">
          <Badge
            size="small"
            color="#9B9999"
            offset={[9, 3]}
            count={<InfoCircleOutlined style={{ color: '#9B9999' }} />}
          >
            <Text type="secondary">
              Tổng số đơn hàng
            </Text>
          </Badge>
        </Tooltip>
      ),
      value: data?.totalAllStatus?.totalOrder || 0,
      decimal: 0,
      suffix: ' đơn hàng',
      charttype: 'bar',
      chartheight: 40,
      chartwidth: 70,
      color: 'success',
      series: series1,
      options: options1
    },
    {
      id: 2,
      // icon:
      //   data?.thisWeekReport?.orderChange > 0
      //     ? 'mdi mdi-arrow-up-bold'
      //     : 'mdi mdi-arrow-down-bold',
      title: (
        <Tooltip
          placement="rightBottom"
          overlayClassName="ant-tooltip-inner__case-chart"
          title={'Trạng thái đơn hàng (Mới, Đã tiếp nhận)'}
        >
          <Badge
            size="small"
            color="#9B9999"
            offset={[9, 3]}
            count={<InfoCircleOutlined style={{ color: '#9B9999' }} />}
          >
            <Text type="secondary">
              Tổng số đơn hàng Mới
            </Text>
          </Badge>
        </Tooltip>
      ),
      value: data?.totalRevenueNew?.totalOrder || 0,
      decimal: 0,
      prefix: '',
      suffix: ' đơn hàng',
      charttype: 'radialBar',
      chartheight: 45,
      chartwidth: 45,
      // badgeValue: `${data?.thisWeekReport?.orderChange || 0}%`,
      // color:
      //   data?.thisWeekReport?.orderChange > 0
      //     ? 'success'
      //     : 'danger',
      // desc: 'so với tuần trước',
      series: series2,
      options: options2
    },
    {
      id: 3,
      // icon:
      //   data?.thisWeekReport?.revenueChange > 0
      //     ? 'mdi mdi-arrow-up-bold'
      //     : 'mdi mdi-arrow-down-bold',
      title: (
        <Tooltip
          placement="rightBottom"
          overlayClassName="ant-tooltip-inner__case-chart"
          title={
            'Trạng thái ĐH (Đang xử lý, Đã xử lý, Đã xác nhận, Đã đóng gói, Đang giao)'
          }
        >
          <Badge
            size="small"
            color="#9B9999"
            offset={[9, 3]}
            count={<InfoCircleOutlined style={{ color: '#9B9999' }} />}
          >
            <Text type="secondary">
              Tổng số ĐH Đang thực hiện
            </Text>
          </Badge>
        </Tooltip>
      ),
      value: data?.totalRevenueInprogress?.totalOrder || 0,
      decimal: 0,
      prefix: '',
      suffix: ' đơn hàng',
      charttype: 'bar',
      chartheight: 40,
      chartwidth: 70,
      // badgeValue: `${data?.thisWeekReport?.revenueChange || 0}%`,
      // color:
      //   data?.thisWeekReport?.revenueChange > 0
      //     ? 'success'
      //     : 'danger',
      // desc: 'so với tuần trước',
      series: series3,
      options: options3
    },
    {
      id: 4,
      title: (
        <Tooltip
          placement="rightBottom"
          overlayClassName="ant-tooltip-inner__case-chart"
          title={'Trạng thái đơn hàng (Đã nhận)'}
        >
          <Badge
            size="small"
            color="#9B9999"
            offset={[9, 3]}
            count={<InfoCircleOutlined style={{ color: '#9B9999' }} />}
          >
            <Text type="secondary">
              Tổng số ĐH Đã hoàn thành
            </Text>
          </Badge>
        </Tooltip>
      ),
      value: data?.totalRevenueComplete?.totalOrder || 0,
      decimal: 0,
      prefix: '',
      suffix: ' đơn hàng',
      charttype: 'radialBar',
      chartheight: 45,
      chartwidth: 45,
      series: series4,
      options: options4
    },
    {
      id: 5,
      title: 'Tổng doanh thu',
      value: data?.totalRevenueComplete?.totalRevenue || 0,
      prefix: '',
      suffix: ' VND',
      decimal: 0,
      charttype: 'bar',
      chartheight: 40,
      chartwidth: 70,
      color: 'success',
      series: series5,
      options: options5
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pharmacy Online Dashboard" />
          <Row>
            <div className="float-end">
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  className="text-reset"
                  id="dropdownMenuButton5"
                  caret
                  href="#"
                  style={{ float: 'right', marginBottom: '1.5rem' }}
                >
                  <span className="fw-semibold" style={{ color: '#3481FF' }}>
                    Dữ liệu theo:
                  </span>{' '}
                  <span className="text-muted">
                    {viewMode === TODAY
                      ? 'Hôm nay'
                      : viewMode === WEEKLY
                      ? 'Tuần'
                      : viewMode === MONTHLY
                      ? 'Tháng'
                      : viewMode === QUARTERLY
                      ? 'Quý'
                      : 'Năm'}
                    <i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem href="#" onClick={() => setViewMode(TODAY)}>
                    Hôm nay
                  </DropdownItem>
                  <DropdownItem href="#" onClick={() => setViewMode(WEEKLY)}>
                    Tuần
                  </DropdownItem>
                  <DropdownItem href="#" onClick={() => setViewMode(MONTHLY)}>
                    Tháng
                  </DropdownItem>
                  <DropdownItem href="#" onClick={() => setViewMode(QUARTERLY)}>
                    Quý
                  </DropdownItem>
                  <DropdownItem href="#" onClick={() => setViewMode(YEARLY)}>
                    Năm
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <MiniWidget reports={reports} />
          </Row>

          <Row>
            <Col xl={12}>
              <SalesAnalyticsChart />
            </Col>
          </Row>
          <Row>
            <MostCustomerOrder />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PharmacyOnlineDashboard;
