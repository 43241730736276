import produce from 'immer';
import { get } from 'lodash';
import {
  APPROVE_WH_PAYMENT_VOUCHER_FAILED,
  APPROVE_WH_PAYMENT_VOUCHER_REQUEST,
  APPROVE_WH_PAYMENT_VOUCHER_SUCCESS,
  CONFIRM_WH_PAYMENT_VOUCHER_FAILED,
  CONFIRM_WH_PAYMENT_VOUCHER_REQUEST,
  CONFIRM_WH_PAYMENT_VOUCHER_SUCCESS,
  CREATE_WH_PAYMENT_VOUCHER_FAILED,
  CREATE_WH_PAYMENT_VOUCHER_REQUEST,
  CREATE_WH_PAYMENT_VOUCHER_SUCCESS,
  DELETE_WH_PAYMENT_VOUCHER_FAILED,
  DELETE_WH_PAYMENT_VOUCHER_REQUEST,
  DELETE_WH_PAYMENT_VOUCHER_SUCCESS,
  GET_WH_PAYMENT_VOUCHERS_CANCEL_FAILED,
  GET_WH_PAYMENT_VOUCHERS_CANCEL_REQUEST,
  GET_WH_PAYMENT_VOUCHERS_CANCEL_SUCCESS,
  GET_WH_PAYMENT_VOUCHERS_FAILED,
  GET_WH_PAYMENT_VOUCHERS_REQUEST,
  GET_WH_PAYMENT_VOUCHERS_SUCCESS,
  GET_WH_PAYMENT_VOUCHER_FAILED,
  GET_WH_PAYMENT_VOUCHER_NOT_BILLID_REQUEST,
  GET_WH_PAYMENT_VOUCHER_REQUEST,
  GET_WH_PAYMENT_VOUCHER_SUCCESS,
  REJECT_WH_PAYMENT_VOUCHER_FAILED,
  REJECT_WH_PAYMENT_VOUCHER_REQUEST,
  REJECT_WH_PAYMENT_VOUCHER_SUCCESS,
  RESET_STORE,
  RESET_WH_PAYMENT_VOUCHER_STATE,
  RESET_WH_PAYMENT_VOUCHER_STATE_ACTION,
  UPDATE_WH_PAYMENT_VOUCHER_FAILED,
  UPDATE_WH_PAYMENT_VOUCHER_REQUEST,
  UPDATE_WH_PAYMENT_VOUCHER_SUCCESS,
} from '../../constants/actionTypes';
import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getWhPaymentVouchersFailed: undefined,
  whPaymentVouchers: [],

  isLoadingCancel: false,
  getWhPaymentVouchersCancelFailed: undefined,
  whPaymentVouchersCancel: [],

  isGetWhPaymentVoucherLoading: false,
  whPaymentVoucher: null,
  getWhPaymentVoucherFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  isConfirmLoading: false,
  confirmSuccess: null,
  confirmFailed: null,

  isApproveLoading: false,
  approveSuccess: null,
  approveFailed: null,

  isRejectLoading: false,
  rejectSuccess: null,
  rejectFailed: null,

  paging: null,
  pagingCancel: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_WH_PAYMENT_VOUCHERS_REQUEST:
      state.isLoading = true;
      state.getWhPaymentVouchersFailed = null;
      return;

    case GET_WH_PAYMENT_VOUCHERS_SUCCESS:
      state.isLoading = false;
      state.whPaymentVouchers = payload.docs;
      state.getWhPaymentVouchersFailed = null;
      state.paging = getPaging(payload);
      return;

    case GET_WH_PAYMENT_VOUCHERS_FAILED:
      state.isLoading = false;
      state.whPaymentVouchers = [];
      state.getWhPaymentVouchersFailed = null;
      return;
    
    case GET_WH_PAYMENT_VOUCHERS_CANCEL_REQUEST:
      state.isLoadingCancel = true;
      state.getWhPaymentVouchersCancelFailed = null;
      return;

    case GET_WH_PAYMENT_VOUCHERS_CANCEL_SUCCESS:
      state.isLoadingCancel = false;
      state.whPaymentVouchersCancel = payload.docs;
      state.getWhPaymentVouchersCancelFailed = null;
      state.pagingCancel = getPaging(payload);
      return;

    case GET_WH_PAYMENT_VOUCHERS_CANCEL_FAILED:
      state.isLoadingCancel = false;
      state.whPaymentVouchersCancel = [];
      state.getWhPaymentVouchersCancelFailed = null;
      return;

    case GET_WH_PAYMENT_VOUCHER_REQUEST:
      state.isGetWhPaymentVoucherLoading = true;
      state.whPaymentVoucher = null;
      state.getWhPaymentVoucherFailed = null;
      return;

    case GET_WH_PAYMENT_VOUCHER_SUCCESS:
      state.isGetWhPaymentVoucherLoading = false;
      state.whPaymentVoucher = payload;
      return;

    case GET_WH_PAYMENT_VOUCHER_FAILED:
      state.isGetWhPaymentVoucherLoading = false;
      state.getWhPaymentVoucherFailed = payload;
      return;
    case GET_WH_PAYMENT_VOUCHER_NOT_BILLID_REQUEST:
      state.isGetWhPaymentVoucherLoading = true;
      state.whPaymentVoucher = null;
      state.getWhPaymentVoucherFailed = null;
      return;


    case DELETE_WH_PAYMENT_VOUCHER_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_WH_PAYMENT_VOUCHER_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_WH_PAYMENT_VOUCHER_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_WH_PAYMENT_VOUCHER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_WH_PAYMENT_VOUCHER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_WH_PAYMENT_VOUCHER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    /* case INVITE_WH_PAYMENT_VOUCHER_REQUEST:
      state.isInviteLoading = true;
      state.inviteSuccess = null;
      state.inviteFailed = null;
      return;

    case INVITE_WH_PAYMENT_VOUCHER_SUCCESS:
      state.isInviteLoading = false;
      state.inviteSuccess = payload;
      return;

    case INVITE_WH_PAYMENT_VOUCHER_FAILED:
      state.isInviteLoading = false;
      state.inviteFailed = payload;
      return; */

    case UPDATE_WH_PAYMENT_VOUCHER_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_WH_PAYMENT_VOUCHER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      // state.whPaymentVoucher = payload; // at the moment, update API returns a different form of response and causes issue here. We disabled this temporarily.
      state.whPaymentVouchers = state.whPaymentVouchers.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case UPDATE_WH_PAYMENT_VOUCHER_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    // CONFIRM
    case CONFIRM_WH_PAYMENT_VOUCHER_REQUEST:
      state.isConfirmLoading = true;
      state.confirmSuccess = null;
      state.confirmFailed = null;
      return;

    case CONFIRM_WH_PAYMENT_VOUCHER_SUCCESS:
      state.isConfirmLoading = false;
      state.confirmSuccess = payload;
      state.whPaymentVoucher = payload;
      state.whPaymentVouchers = state.whPaymentVouchers.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case CONFIRM_WH_PAYMENT_VOUCHER_FAILED:
      state.isConfirmLoading = false;
      state.confirmFailed = payload;
      return;

    // APPROVE
    case APPROVE_WH_PAYMENT_VOUCHER_REQUEST:
      state.approveLoading = true;
      state.approveSuccess = null;
      state.approveFailed = null;
      return;

    case APPROVE_WH_PAYMENT_VOUCHER_SUCCESS:
      state.approveLoading = false;
      state.approveSuccess = payload;
      state.whPaymentVoucher = payload;
      state.whPaymentVouchers = state.whPaymentVouchers.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case APPROVE_WH_PAYMENT_VOUCHER_FAILED:
      state.approveLoading = false;
      state.approveFailed = payload;
      return;

    // REJECT
    case REJECT_WH_PAYMENT_VOUCHER_REQUEST:
      state.isRejectLoading = true;
      state.rejectSuccess = null;
      state.rejectFailed = null;
      return;

    case REJECT_WH_PAYMENT_VOUCHER_SUCCESS:
      state.isRejectLoading = false;
      state.rejectSuccess = payload;
      state.whPaymentVoucher = payload;
      state.whPaymentVouchers = state.whPaymentVouchers.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case REJECT_WH_PAYMENT_VOUCHER_FAILED:
      state.isRejectLoading = false;
      state.rejectFailed = payload;
      return;

    case RESET_WH_PAYMENT_VOUCHER_STATE_ACTION:
            state.updateSuccess = null;
            state.createSuccess = null;
            state.confirmSuccess = null;
            state.approveSuccess = null;
            state.rejectSuccess =null;
      return ;
    case RESET_WH_PAYMENT_VOUCHER_STATE:
    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
