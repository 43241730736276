import {  Form, Modal } from 'antd';
import { get } from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import api from '~/api';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { useCities } from '~/hooks';
import FormFillInfo from './FormFillInfo';
import './wh-order-temp.scss';
function ModalFormInformationOrderTemp({
  isModalConfirmInfomationOpen,
  setIsModalConfirmInfomationOpen,
  createQuotation,
  isSubmitLoadingSelector,
},ref) {
  const closeModal = () => {
    setTotalBill(0)
    form.resetFields();
    setIsModalConfirmInfomationOpen(false);
  };
  const [whCategories,setWhCategories] = useState([])
  const [servicePlaces,setServicePlaces] = useState([])
  const [isLoading,setIsLoading] = useState(false)
  const [form] = Form.useForm();
  const [totalBill,setTotalBill] = useState(0);

  useImperativeHandle(ref,() => ({
    closeModal
  }))

  useEffect(() => {
    const fetchWhCategories = async () => {
      setIsLoading(true)
      const res = await api.whCategory.getWhCategoryAuthen({status: 'ACTIVE'});
      if (res) {
        setWhCategories(res.map((item)=> ({...item, id: get(item, '_id')})))
        setIsLoading(false)
      }
      else {
        setWhCategories([])
        setIsLoading(false)
      };
    };
    const fetchServicePlaces = async () => {
      setIsLoading(true)
      const res = await api.servicePlace.getAll({ limit: MAX_PAGINATION_LIMIT, state: 'ACTIVE' })
      if (get(res, 'docs')) {
        setServicePlaces(get(res, 'docs'))
        setIsLoading(false)
      }
      else {
        setServicePlaces([])
        setIsLoading(false)
      }
    };
    fetchServicePlaces()
    fetchWhCategories()
  }, []);
  const [cities,isLoadingCities] = useCities()
  return (
    <Modal
    width={600}
      onCancel={closeModal}
      className="WhModal-modal"
      visible={isModalConfirmInfomationOpen}
      footer={null}
    >
      <h5>Tạo mới đơn hàng tạm</h5>
      <FormFillInfo
      form={form}
      totalBill={totalBill}
      setTotalBill={setTotalBill}
        createQuotation={createQuotation}
        isSubmitLoadingSelector={isSubmitLoadingSelector}
        isLoading={isLoading}
        whCategories={whCategories}
        closeModal={closeModal}
        cities={cities}
        isLoadingCities={isLoadingCities}
        servicePlaces={servicePlaces}
      />
    </Modal>
  );
}
export default  forwardRef(ModalFormInformationOrderTemp)
