import { Button, Form, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect, useState } from 'react';
import { LANGUAGE, requireRules } from '~/constants/defaultValue';
import { useCoreValue, useCreateCoreValue, useUpdateCoreValue } from '~/hooks/coreValue';
import { renderLoading } from '~/utils/helper';
import LangFormItem from '../Common/LangFormItem';
import LangSelect from '../Common/LangSelect';
import UploadImage from '../Utils/UploadImage';

export default function CoreValueForm({ id, onCancel }) {
    const [language,setLanguage] = useState(LANGUAGE.VI);
    const [form] = Form.useForm();
    const [isSubmitLoading, onCreate] = useCreateCoreValue(onCancel);
    const [, onUpdate] = useUpdateCoreValue(onCancel);
    const [coreValue,loading] = useCoreValue(id);
    const onFinish = useCallback((values) => {
        if (id) {
            onUpdate({
                ...values,
                _id: id
            });
        } else {
            onCreate(values)
        }
    }, [id]);
    useEffect(() => {
        if (id && coreValue) {
            form.setFieldsValue({ ...coreValue })
        }
    }, [coreValue,id]);

    return (
        <Form labelCol={{ span: 4 }} form={form} onFinish={onFinish}>
          <LangSelect language={language} setLanguage={setLanguage}/>
          <Form.Item shouldUpdate noStyle>
            {() =>  <Form.Item  label="Hình ảnh" name={"avatar"}>
                {renderLoading(<UploadImage imgUrl={form.getFieldValue('avatar')} onChange={(avatar) => form.setFieldsValue({avatar})} />,loading)}
            </Form.Item>}
          </Form.Item>
            <LangFormItem  label="Nội dung" fieldName={'description'} language={language}>
                {renderLoading(<TextArea />,loading)}
            </LangFormItem>
            <Row justify='end'>
                <Button loading={isSubmitLoading} htmlType='submit' type='primary'>
                    {id ? "Cập nhật" : "Tạo mới"}
                </Button>
            </Row>
        </Form>
    )
}
