import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getJobQuestions({ payload: query }) {
    try {
      const response = yield call(Api.jobQuestion.getAll, query);
      yield put({ type: Types.GET_JOB_QUESTIONS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_JOB_QUESTIONS_FAILED, payload: error });
    }
  }
function* getJobQuestion({ payload: id }) {
    try {
      const response = yield call(Api.jobQuestion.getOne, id);
      yield put({ type: Types.GET_JOB_QUESTION_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_JOB_QUESTION_FAILED, payload: error });
    }
  }
  function* createJobQuestion(action) {
    try {
      const data = yield call(Api.jobQuestion.create, action.payload);
      yield put({ type: Types.CREATE_JOB_QUESTION_SUCCESS, payload: data });
      yield put({ type: Types.RESET_JOB_QUESTION_STATE_ACTION });
    } catch (error) {
      yield put({ type: Types.CREATE_JOB_QUESTION_FAILED, payload: error });
    }
  }
  function* updateJobQuestion(action) {
    try {
      const data = yield call(Api.jobQuestion.update, action.payload);
      yield put({ type: Types.UPDATE_JOB_QUESTION_SUCCESS, payload: data });
      yield put({ type: Types.RESET_JOB_QUESTION_STATE_ACTION });
    } catch (error) {
      yield put({ type: Types.UPDATE_JOB_QUESTION_FAILED, payload: error });
    }
  }
  function* deleteJobQuestion(action) {
    try {
      const data = yield call(Api.jobQuestion.delete, action.payload);
      yield put({ type: Types.DELETE_JOB_QUESTION_SUCCESS, payload: data });
      yield put({ type: Types.RESET_JOB_QUESTION_STATE_ACTION });
    } catch (error) {
      yield put({ type: Types.DELETE_JOB_QUESTION_FAILED, payload: error });
    }
  }
export default function* JobQuestions() {
    yield takeLatest(Types.GET_JOB_QUESTIONS_REQUEST, getJobQuestions);
    yield takeLatest(Types.GET_JOB_QUESTION_REQUEST, getJobQuestion);
    yield takeLatest(Types.CREATE_JOB_QUESTION_REQUEST, createJobQuestion);
    yield takeLatest(Types.UPDATE_JOB_QUESTION_REQUEST, updateJobQuestion);
    yield takeLatest(Types.DELETE_JOB_QUESTION_REQUEST, deleteJobQuestion);
  }
