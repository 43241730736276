
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Switch } from 'antd';
import { get, omit } from 'lodash';
import { useEffect } from 'react';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useUIs, useUpdateUI } from '~/hooks/UI';
import { Breadcrumb, WithOrPermission } from '../Common';
import UploadImage from '../Utils/UploadImage';
import LoadingWhBill from '../WhBill/LoadingWhBill';
const Gallery = () => {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_BANNER);
    const [form] = Form.useForm();
    const [UI, isLoading] = useUIs('homepageGallery');
    const [isSubmitLoading, update] = useUpdateUI();
    const onFinish = (values) => {
        update({ key: 'homepageGallery', ...get(values, 'UI.homepageGallery') })
    };

    useEffect(() => {
        form.setFieldsValue({
            ...UI
        })
    }, [UI]);
    return (
        <div className="wh-service-form page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb title={'Ấn phẩm WorldPharma'} routes={[{ path: '', title: 'CMS' }]} />
                {isLoading && <LoadingWhBill open={true} />}
                <Form
                    form={form}
                    autoComplete="off"
                    initialValues={{}}
                    scrollToFirstError
                    requiredMark={false}
                    onFinish={onFinish}
                    labelCol={{ sm: 24, md: 24, lg: 6 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 18 }}
                >
                    <h5>Hiển thị</h5>
                    <Form.Item valuePropName="checked" name={['UI', 'homepageGallery', 'active']} >
                        <Switch disabled={!canUpdate}/>
                    </Form.Item>
                    <h5>Máy tính</h5>
                    <Form.List name={['UI', 'homepageGallery', 'desktop']}>
                        {(fields, { add, remove }) => (
                            <>
                                <Row
                                    gutter={[48, 0]}
                                    justify="flex-start"
                                    className="wh-service-form__question--options-wrapper"
                                >
                                    {fields.map((field, index) => {
                                        const UIHere = form.getFieldValue('UI');

                                        const image = form.getFieldValue(['UI', 'homepageGallery', 'desktop', index]);
                                        const onChange = (url) => {
                                            const desktopClone = [...UIHere.homepageGallery.desktop];
                                            desktopClone.splice(index, 1, url)
                                            form.setFieldsValue({
                                                UI: {
                                                    ...UIHere,
                                                    homepageGallery: {
                                                        ...UIHere?.homepageGallery,
                                                        desktop: desktopClone
                                                    },
                                                }
                                            })
                                        }
                                        const deleteItem = () => {
                                            form.setFieldsValue({
                                                UI: {
                                                    ...UIHere,
                                                    homepageGallery: {
                                                        ...UIHere?.homepageGallery,
                                                        desktop: UIHere?.homepageGallery?.desktop.filter((item, i) => i !== index)
                                                    },
                                                }
                                            })
                                        }
                                        return (
                                            <Col span={6}>
                                                <Form.Item
                                                    name={[index]}
                                                >
                                                    <UploadImage
                                                        disabled={UI?.UI?.homepageGallery?.desktop?.length > index && !canUpdate}
                                                        style={{ width: '100%' }}
                                                        title="Hình ảnh"
                                                        imgUrl={image && image}
                                                        onChange={(url) => {
                                                            onChange(url);
                                                        }}
                                                    >
                                                        <WithOrPermission permission={[POLICIES.DELETE_BANNER]}>
                                                            <MinusCircleOutlined
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    remove(index);
                                                                    deleteItem();
                                                                    return;
                                                                }}
                                                                style={{ position: 'absolute', right: 5, top: 5 }}
                                                            />
                                                        </WithOrPermission>
                                                    </UploadImage>
                                                </Form.Item>
                                            </Col>
                                        )
                                    })}
                                    <Col span={6}>
                                        <WithOrPermission permission={[POLICIES.WRITE_BANNER]}>
                                            <Button
                                                className="wh-service-form__question--btn-add-option"
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Thêm lựa chọn
                                            </Button>
                                        </WithOrPermission>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.List>
                    <h5 style={{ marginTop: 10 }}>Điện thoại</h5>
                    <Form.List name={['UI', 'homepageGallery', 'mobile']}>
                        {(fields, { add, remove }) => (
                            <>
                                <Row
                                    gutter={[48, 0]}
                                    justify="flex-start"
                                    className="wh-service-form__question--options-wrapper"
                                >
                                    {fields.map((field, index) => {
                                        const UIHere = form.getFieldValue('UI');
                                        const image = form.getFieldValue(['UI', 'homepageGallery', 'mobile', index]);
                                        const onChange = (url) => {
                                            const mobileClone = [...UIHere.homepageGallery.mobile];
                                            mobileClone.splice(index, 1, url);
                                            form.setFieldsValue({
                                                UI: {
                                                    ...UIHere,
                                                    homepageGallery: {
                                                        ...UIHere?.homepageGallery,
                                                        mobile: mobileClone
                                                    },
                                                }
                                            })
                                        }
                                        const deleteItem = () => {
                                            form.setFieldsValue({
                                                UI: {
                                                    ...UIHere,
                                                    homepageGallery: {
                                                        ...UIHere?.homepageGallery,
                                                        mobile: UIHere?.homepageGallery?.mobile.filter((item, i) => i !== index)
                                                    },

                                                }
                                            })
                                        }
                                        return (
                                            <Col span={6}>
                                                <Form.Item
                                                    name={[index]}
                                                >
                                                    <UploadImage
                                                        disabled={UI?.UI?.homepageGallery?.mobile?.length > index && !canUpdate}
                                                        style={{ width: '100%' }}
                                                        title="Hình ảnh"
                                                        imgUrl={image && image}
                                                        onChange={(url) => {
                                                            onChange(url);
                                                        }}
                                                    >
                                                        <WithOrPermission permission={[POLICIES.DELETE_BANNER]}>
                                                            <MinusCircleOutlined
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    remove(index);
                                                                    deleteItem();
                                                                    return;
                                                                }}
                                                                style={{ position: 'absolute', right: 5, top: 5 }}
                                                            />
                                                        </WithOrPermission>

                                                    </UploadImage>
                                                </Form.Item>
                                            </Col>
                                        )
                                    })}
                                    <Col span={6}>
                                        <WithOrPermission permission={[POLICIES.WRITE_BANNER]}>
                                            <Button
                                                className="wh-service-form__question--btn-add-option"
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Thêm lựa chọn
                                            </Button>
                                        </WithOrPermission>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.List>

                    <Row className="wh-service-form__submit-box">
                        <WithOrPermission permission={[POLICIES.UPDATE_BANNER, POLICIES.WRITE_BANNER, POLICIES.DELETE_BANNER]}>
                            <Button
                                loading={isSubmitLoading}
                                type="primary"
                                htmlType="submit"
                            >
                                Cập nhật
                            </Button>
                        </WithOrPermission>
                    </Row>
                </Form>
            </div>
        </div>
    );
};
export default Gallery;
