import * as Types from '../../constants/actionTypes';
  
  export const getKeywordSearchs = query => ({
    type: Types.GET_KEYWORD_SEARCHS_REQUEST,
    payload: query
  });
  export const getKeywordSearch = id => ({
    type: Types.GET_KEYWORD_SEARCH_REQUEST,
    payload: id
  });
  export const createKeywordSearch = instance => ({
    type: Types.CREATE_KEYWORD_SEARCH_REQUEST,
    payload: instance
  });
  export const updateKeywordSearch = instance => ({
    type: Types.UPDATE_KEYWORD_SEARCH_REQUEST,
    payload: instance
  });
  export const deleteKeywordSearch = id => ({
    type: Types.DELETE_KEYWORD_SEARCH_REQUEST,
    payload: id
  });
  export const resetKeywordSearchState = () => ({
    type: Types.RESET_KEYWORD_SEARCH_STATE
  });
