import { compact, get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '~/api';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import {
    getSelectors,
    useFailed,
    useFetchByParam,
    useQueryParams,
    useResetState,
    useSubmit,
    useSuccess
} from '~/hooks/utils';
import { createBonusIntroCustomer, deleteBonusIntroCustomer, getBonusIntroCustomer, getBonusIntroCustomers, resetBonusIntroCustomerState, updateBonusIntroCustomer } from '~/redux/action';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.bonusIntroCustomer[key];
const pagingSelector = getSelector('paging');
export const useBonusIntroCustomerPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetBonusIntroCustomerSelector = getSelector('isGetBonusIntroCustomerLoading');
const getBonusIntroCustomersFailedSelector = getSelector('getBonusIntroCustomersFailed');
const getBonusIntroCustomerFailedSelector = getSelector('getBonusIntroCustomerFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const bonusIntroCustomersSelector = getSelector('bonusIntroCustomers');
const bonusIntroCustomerSelector = getSelector('bonusIntroCustomer');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useBonusIntroCustomerQueryParams = () => {
    const query = useQueryParams();
    const limit = query.get('limit') || 10;
    const page = query.get('page') || 1;
    const createBonusIntroCustomerSuccess = useSelector(createSuccessSelector);
    const updateBonusIntroCustomerSuccess = useSelector(updateSuccessSelector);
    const deleteBonusIntroCustomerSuccess = useSelector(deleteSuccessSelector);
  
    return useMemo(() => {
      const queryParams = {
        page,
        limit,
      };
      return [queryParams];
      //eslint-disable-next-line
    }, [
      page,
      limit,
      createBonusIntroCustomerSuccess,
      updateBonusIntroCustomerSuccess,
      deleteBonusIntroCustomerSuccess,
    ]);
  };

  export const useUpdateBonusIntroCustomerParams = (query) => {
    const history = useHistory();
    const [keyword, setKeyword] = useState(get(query,'keyword'));
    useEffect(() => {
      setKeyword(get(query,'keyword'));
    },[query.keyword])
    const onParamChange = (param) => {
      if (!param.page) {
        query.page = 1;
      }
      history.push({
        pathname: get(param, 'customPathName') || '/wh-report-partner',
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword ,{setKeyword, onParamChange }];
  };
  export const useBonusIntroCustomers = (query) => {
    return useFetchByParam({
      action: getBonusIntroCustomers,
      loadingSelector,
      dataSelector: bonusIntroCustomersSelector,
      failedSelector: getBonusIntroCustomersFailedSelector,
      param: query
    });
  };
  export const useBonusIntroCustomer = (id) => {
    return useFetchByParam({
      action: getBonusIntroCustomer,
      loadingSelector:loadinggetBonusIntroCustomerSelector,
      dataSelector: bonusIntroCustomerSelector,
      failedSelector: getBonusIntroCustomerFailedSelector,
      param: id
    });
  };
export const useCreateBonusIntroCustomer = (callback) => {
    useSuccess(createSuccessSelector, 'Tạo cấu hình thưởng khách hàng thành công',callback);
    useFailed(createFailedSelector, 'Tạo cấu hình thưởng khách hàng thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: createBonusIntroCustomer
    });
  };
export const useUpdateBonusIntroCustomer = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhật cấu hình thưởng khách hàng thành công',callback);
    useFailed(updateFailedSelector, 'Cập nhật cấu hình thưởng khách hàng thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateBonusIntroCustomer
    });
  };
export const useDeleteBonusIntroCustomer = (callback) => {
    useSuccess(deleteSuccessSelector, 'Xoá cấu hình thưởng khách hàng thành công',callback);
    useFailed(deleteFailedSelector, 'Xoá cấu hình thưởng khách hàng thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: deleteBonusIntroCustomer
    });
  };
  export const useResetBonusIntroCustomer = () => {
    useResetState(resetBonusIntroCustomerState);
  };
