import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getHospitalsList({ payload: query }) {
  try {
    const data = yield call(Api.hospitalList.getAll, query);
    yield put({ type: Types.GET_HOSPITALS_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_HOSPITALS_LIST_FAILED,
      payload: error.message
    });
  }
}

function* getHospitalList({ payload: id }) {
  try {
    const hospitalList = yield call(Api.hospitalList.getById, id);
    yield put({ type: Types.GET_HOSPITAL_LIST_SUCCESS, payload: hospitalList });
  } catch (error) {
    yield put({ type: Types.GET_HOSPITAL_LIST_FAILED, payload: error.message });
  }
}

function* createHospitalList(action) {
  try {
    const data = yield call(Api.hospitalList.create, action.payload);
    yield put({ type: Types.CREATE_HOSPITAL_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_HOSPITAL_LIST_FAILED,
      payload: error.message
    });
  }
}

function* updateHospitalList(action) {
  try {
    const data = yield call(Api.hospitalList.update, action.payload);
    yield put({ type: Types.UPDATE_HOSPITAL_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_HOSPITAL_LIST_FAILED,
      payload: error
    });
  }
}

function* deleteHospitalList({ payload }) {
  try {
    yield call(Api.hospitalList.delete, payload);
    yield put({ type: Types.DELETE_HOSPITAL_LIST_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_HOSPITAL_LIST_FAILED,
      payload: error.message
    });
  }
}

export default function* hospitalList() {
  yield takeLatest(Types.GET_HOSPITALS_LIST_REQUEST, getHospitalsList);
  yield takeLatest(Types.GET_HOSPITAL_LIST_REQUEST, getHospitalList);
  yield takeLatest(Types.DELETE_HOSPITAL_LIST_REQUEST, deleteHospitalList);
  yield takeLatest(Types.CREATE_HOSPITAL_LIST_REQUEST, createHospitalList);
  yield takeLatest(Types.UPDATE_HOSPITAL_LIST_REQUEST, updateHospitalList);
}
