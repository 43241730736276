import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getPositionJobs({ payload: query }) {
    try {
      const response = yield call(Api.positionJob.getAll, query);
      yield put({ type: Types.GET_POSITION_JOBS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_POSITION_JOBS_FAILED, payload: error });
    }
  }
function* getPositionJob({ payload: id }) {
    try {
      const response = yield call(Api.positionJob.getById, id);
      yield put({ type: Types.GET_POSITION_JOB_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_POSITION_JOB_FAILED, payload: error });
    }
  }
  function* updatePositionJob(action) {
    try {
      const data = yield call(Api.positionJob.update, action.payload);
      yield put({ type: Types.UPDATE_POSITION_JOB_SUCCESS, payload: data });
      yield put({ type: Types.RESET_POSITION_JOB_ACTION});
    } catch (error) {
      yield put({ type: Types.UPDATE_POSITION_JOB_FAILED, payload: error });
    }
  }
  function* createPositionJob(action) {
    try {
      const data = yield call(Api.positionJob.create, action.payload);
      yield put({ type: Types.CREATE_POSITION_JOB_SUCCESS, payload: data });
      yield put({ type: Types.RESET_POSITION_JOB_ACTION});
    } catch (error) {
      yield put({ type: Types.CREATE_POSITION_JOB_FAILED, payload: error });
    }
  }
  function* deletePositionJob(action) {
    try {
      const data = yield call(Api.positionJob.delete, action.payload);
      yield put({ type: Types.DELETE_POSITION_JOB_SUCCESS, payload: data });
      yield put({ type: Types.RESET_POSITION_JOB_ACTION});
    } catch (error) {
      yield put({ type: Types.DELETE_POSITION_JOB_FAILED, payload: error });
    }
  }
export default function* PositionJobs() {
    yield takeLatest(Types.GET_POSITION_JOBS_REQUEST, getPositionJobs);
    yield takeLatest(Types.GET_POSITION_JOB_REQUEST, getPositionJob);
    yield takeLatest(Types.UPDATE_POSITION_JOB_REQUEST, updatePositionJob);
    yield takeLatest(Types.CREATE_POSITION_JOB_REQUEST, createPositionJob);
    yield takeLatest(Types.DELETE_POSITION_JOB_REQUEST, deletePositionJob);
  }
