import requester from './requester';

const speciality = {
  getAll: query => requester.get('/speciality', query),
  getById: id => requester.get(`/speciality/${id}?raw=true`),
  create: speciality => requester.post('/speciality', speciality),
  update: speciality =>
    requester.put(`/speciality/${speciality.id}`, speciality),
  delete: id => requester.delete(`speciality/${id}`)
};

export default speciality;
