import React, { useRef, useState } from 'react';

import moment from 'moment';
import { Table, Input, Button, Form, Select, Checkbox, Row , Col} from 'antd';

import { FilterDatePicker, WithPermission } from '~/components/Common';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import './wh-bill.scss';
import { PlusOutlined } from '@ant-design/icons';
import ModalFormInformationOrderTemp from './ModalFormInformationOrderTemp';
import WhBillOrderTemp from './WhBillOrderTemp';
import ModalUpdateQuotation from './ModalUpdateQuotation';
import MenuAction from './MenuAction';
import { v4 } from 'uuid';
import FilterWhQuotationStatus from './Filter/FilterWhQuotationStatus';
import { ORDER_QUOTATION_STATUS_NAME } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useConvertQuotation, useCopyQuotation, useCreateQuotation, useDeleteQuotation, useQuotation, useQuotationPaging, useQuotations, useQuotationsQueryParams, useResetQuotation, useUpdateQuotation, useUpdateQuotationParams } from '~/hooks/quotations';
import { useHistory } from 'react-router-dom';
import LoadingWhBill from './LoadingWhBill';
import ModalDeleteQuotation from './ModalDeleteQuotation';
import ExportExcelButton from '../Common/ExportExcel';
import TabBranch from '../Common/TabBranch';
import DebounceSelectByCategory from '../WhCategory/SearchByCategory';
import { get } from 'lodash';
const { Search } = Input;
const listOptionSearch = [
  {
    value: 'billNumber',
    label: 'Mã đơn hàng tạm'
  },
  {
    value: 'phoneNumber',
    label: 'Số điện thoại'
  },
  {
    value: 'fullName',
    label: 'Tên'
  }
];
const WhBillOrderTempList = () => {
  const history = useHistory();
  const [selectSearch, setSelectSearch] = useState('billNumber');
  const [idSelect,setIdSelect] = useState(null);
  const refCreateQuotation = useRef()
  const refConvertQuotation = useRef()
  const [query, onTableChange] = useQuotationsQueryParams(selectSearch);
  const [{ onParamChange }] = useUpdateQuotationParams(query);
  const [quotations, isLoading] = useQuotations(query);
  
  const paging = useQuotationPaging();
  // test
  const [form] = Form.useForm();
  const [keywords, setKeywords] = useState('');
  const [isModalUpdateQuotationOpen, setIsModalUpdateQuotationOpen] =
  useState(false);

  useResetQuotation() 
  // modal delete
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idDelete,setIdDelete] = useState(null)
  const handleCancel = () => {
    setIsModalOpen(false);
    setIdDelete(null)
  };
  //
  const [, updateQuotation] = useUpdateQuotation(() => {setIsModalUpdateQuotationOpen(false);setIdSelect(null)});
  const [isSubmitLoadingSelector, createQuotation] = useCreateQuotation(() => refCreateQuotation.current.closeModal())
  const [, deleteQuotation] = useDeleteQuotation(handleCancel)
  const [quotation,] = useQuotation(idSelect)
  const [, convertQuotation] = useConvertQuotation(() => refConvertQuotation.current.closeModal());
  const [isLoadingCopy, copyQuotation] = useCopyQuotation();
  const [
    isModalConfirmInfomationUserOpen,
    setIsModalConfirmInfomationUserOpen
  ] = useState(false);
  const handleDelete = () => {
    deleteQuotation(idDelete)
  }
  const [isModalConfirmInfomationOpen, setIsModalConfirmInfomationOpen] =
    useState(false);
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
  };

  const [arrCheckBox, setArrCheckBox] = useState([])
  const [whCategory, setWhCategory] = useState(null);

  const openFormUpdate = (id) => {
    setIdSelect(id)
    setIsModalUpdateQuotationOpen(true);
  }
  const openFormConvert = (id) => {
    setIdSelect(id)
    setIsModalConfirmInfomationUserOpen(true);
  }
  const onChangeCheckBox = (e, id) => {
    if (e) {
    setArrCheckBox([...arrCheckBox, id])
    } else {
      const getIndex = arrCheckBox.findIndex((itemId, index) => itemId === id)
      const newArr = arrCheckBox.toSpliced(getIndex, 1)
      setArrCheckBox(newArr)
    }
  } 
  const setValueSearch =  history?.location?.search 

  const propsButton = {
    url: "/search/quotations/all",
    keyExport: "ListQuotations",
    fileName_: 'Danh sách đơn hàng tạm',
    searchBy:  setValueSearch ? setValueSearch : `page=${query?.page}&limit=${query?.limit}`,
    ids: arrCheckBox
  }

  const columns = [
    {
      title: 'Lựa chọn',
      key: '_id',
      width: 80,
      align: 'center',
      render: (item, record) =>
      {
        const id = record._id
      return  <Checkbox
      defaultChecked= {arrCheckBox.includes(id)}
        onChange={(e)=>onChangeCheckBox(e.target.checked, id)}
      />}
    },
    {
      title: 'Mã đơn hàng tạm',
      key: 'billNumber',
      align: 'center',
      render: (item) => (
        <a href='#'
          onClick={(event) => onParamChange({billNumber: item.billNumber})
          }
        >
          {item.billNumber}
        </a>
      )
    },
    {
      title: 'Mã đơn hàng',
      key: 'billNumberOrder',
      align: 'center',
      render: (item) => {
        return (
          item?.billNumberOrder && <a href='#'
          onClick={(event) => history.push({
            pathname : '/wh-bill',
            search : `?page=1&limit=10&keyword=${item?.billNumberOrder}&withBillItems=true`
          })}
        >
          {item.billNumberOrder || ""}
        </a>
        )
      }
    },

    {
      title: 'ID dịch vụ',
      dataIndex: 'billItems',
      key: 'code',
      align: 'center',
      render: (value) =>
        value.map((billItem) => <div key={v4()}>{billItem.code}</div>)
    },
    {
      title: 'Tên',
      dataIndex: 'fullName',
      key: 'fullName',
      align: 'center',
      render: (value, record) => record?.fullName
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
      render: (value, record) => record?.phoneNumber
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (value, record) => (
        <span className={`bill-status ${record?.state.toLowerCase()}`}>
          {ORDER_QUOTATION_STATUS_NAME[record?.state]}
        </span>
      )
    },
    {
      title: 'Loại dịch vụ',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (value, record) => (
        <span className={`bill-status`}>
          {record?.billItems[0]?.whPackageLevel?.name.vi || ''}
        </span>
      )
    },
    {
      title: 'Số lượng',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (value, record) => <span>{record?.billItems[0]?.quantity}</span>
    },
    {
      title: 'Giá trị đơn hàng',
      key: 'grandTotal',
      dataIndex: 'grandTotal',
      align: 'right',
      width: '140px',
      render: (value, record) =>
        {
          const {price,minimumQuantity} = record?.billItems[0]
          return ((price * minimumQuantity) + get(record,'dealPrice',0))?.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    },
    {
      title: 'Số tiền tạm ứng',
      key: 'grandTotal',
      dataIndex: 'grandTotal',
      align: 'right',
      width: '200px',
      render: (value, record) =>
        (record?.prePayAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    {
      title: 'Thành tiền',
      key: 'remainingAmount',
      dataIndex: 'remainingAmount',
      align: 'right',
      width: '140px',
      render: (value, record) => {
        const {price,minimumQuantity,quantity} = record?.billItems[0];
        return (
        <span>
          {(
            price * minimumQuantity * quantity -record?.prePayAmount + get(record,'dealPrice',0)
          )
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </span>
      )}
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: '150px',
      render: (value) => moment(value).format('HH:mm DD/MM/YYYY')
    },
    {
      title: 'Action',
      key: 'createdAt',
      fixed: 'right',
      dataIndex: 'createdAt',
      width: '150px',
      render: (text, record, index) => {
        return (
          <MenuAction
            record={record}
            setIsModalOpen={setIsModalOpen}
            setIdDelete={setIdDelete}
            quotation={quotation}
            updateAction={() => openFormUpdate(record._id)}
            convertAction={() => openFormConvert(record._id)}
            copyOrder={() => copyQuotation(record._id)}
          />
        );
      }
    }
  ];

  const onValuesChange = (values) => {
  };
  return (
    <div className="page-wraper page-content wh-bills-page">
     <LoadingWhBill fixed open={isLoadingCopy}/>
      {isModalUpdateQuotationOpen && (
        <ModalUpdateQuotation
        idSelect={idSelect}
        quotation={quotation}
          updateQuotation={updateQuotation}
          isSubmitLoadingSelector={isSubmitLoadingSelector}
          isModalUpdateQuotationOpen={isModalUpdateQuotationOpen}
          setIsModalUpdateQuotationOpen={setIsModalUpdateQuotationOpen}
        />
      )}
      {quotation && isModalConfirmInfomationUserOpen && (
        <WhBillOrderTemp
        setIdSelect={setIdSelect}
        idSelect={idSelect}
        quotation={quotation}
        ref={refConvertQuotation}
        convertQuotation={convertQuotation}
        isSubmitLoadingSelector={isSubmitLoadingSelector}
          isModalConfirmInfomationOpen={isModalConfirmInfomationUserOpen}
          setIsModalConfirmInfomationOpen={setIsModalConfirmInfomationUserOpen}
        />
      )}
      {<ModalDeleteQuotation 
        isModalOpen={isModalOpen} handleCancel={handleCancel} isSubmitLoadingSelector={isSubmitLoadingSelector} handleDelete={handleDelete}
      />}
      <TabBranch>
    
      <div className="container-fluid">
     
        <Breadcrumb title="Danh sách đơn hàng tạm" />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form
              form={form}
              autoComplete="off"
              onFinish={() => {}}
              scrollToFirstError
              onValuesChange={onValuesChange}
              requiredMark={false}
            >
              <FilterWhQuotationStatus
                value={query.state}
                onChange={(value) => onParamChange({state : value}) }
              />
              <FilterDatePicker
                label="Từ"
                value={query.startDate}
                onChange={(value) =>
                {
                  onParamChange({startDate : value})
                  // dispatch(addQueryFetch({ key: 'startDate', value: value }))
                }
                }
              />
              <FilterDatePicker
                label="Đến"
                value={query.endDate}
                onChange={(value) =>
                {
                    onParamChange({endDate : value})

                  // dispatch(addQueryFetch({ key: 'endDate', value: value }))
                  }
                }
                />
                <Form.Item
                label={`Nhóm dịch vụ`}
                labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
              >
                <DebounceSelectByCategory
                  onChange={(e) => {
                    onParamChange({ whCategory: e });
                    setWhCategory(e)
                  }}
                  value={whCategory}
                  isFirstFetch={!!whCategory}
                />
              </Form.Item>
            </Form>
          </div>
          <div className="page-content__right" style={{ width: '80%' }}>
            <div
              className="page-wraper__header"
              style={{ justifyContent: 'space-between', display: 'flex' }}
            >
              <div style={{width: '100%'}}>
                <Row style={{width: '100%'}} align='center'>
                  <Col span={12}>
                    <Row>
                      <Col span={8}>
                            <Select
                            defaultValue="billNumber"
                            style={{
                              width: 170
                            }}
                            onChange={handleChangeSelectSearch}
                            options={listOptionSearch}
                          />
                      </Col>
                      <Col span={16}>
                          <Search
                            style={{ width: 300 }}
                            placeholder={`Tìm ${
                              listOptionSearch.find((e) => e.value === selectSearch).label
                            }`}
                            enterButton
                            allowClear
                            onSearch={async (value) => {
                                onParamChange({[selectSearch] : value});
                              // dispatch(
                              //   addQueryFetch({ key: selectSearch, value: value })
                              // );
                            }}
                            onChange={(e) => setKeywords(e.target.value)}
                            value={keywords}
                        />
                      </Col>
                    </Row>
                  </Col>
                <WithPermission permission={POLICIES.DOWNLOAD_QUOTATIONS}>
                  <Col span = {12} align = {'end'}>
                      <ExportExcelButton
                      propsButton ={propsButton}
                    />
                  </Col>
                  </WithPermission>
                </Row>
              </div>
              <WithPermission permission={POLICIES.WRITE_QUOTATIONS}>
                <Button
                  onClick={() => setIsModalConfirmInfomationOpen(true)}
                  type="primary"
                >
                  {' '}
                  <PlusOutlined />
                  Thêm Đơn hàng
                </Button>
              </WithPermission>
              <ModalFormInformationOrderTemp
              ref={refCreateQuotation}
              createQuotation={createQuotation}
              isSubmitLoadingSelector={isSubmitLoadingSelector}
                isModalConfirmInfomationOpen={isModalConfirmInfomationOpen}
                // reRenderList={() => {
                //   reRenderList();
                //   dispatch(resetStoreQuotation());
                // }}
                setIsModalConfirmInfomationOpen={setIsModalConfirmInfomationOpen}
              />
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={paging?.pageSize} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                scroll={{ x: 2000 }}
                dataSource={quotations}
                onChange={onTableChange}
                pagination={{
                  defaultPageSize: (paging?.pageSize),
                  current: (paging?.current),
                  showSizeChanger: true,
                  showTotal: (total) => `Tổng cộng: ${total} `,
                  total: paging?.total
                }}
                size="middle"
              />
            )}
          </div>
        </div>
      </div>
      </TabBranch>
    </div>
  );
};

export default WhBillOrderTempList;

