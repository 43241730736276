import produce from 'immer';
import * as Types from '../../constants/actionTypes';
import getPaging from '~/utils/getPaging';
import { get } from 'lodash';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isLoadingHistory: false,
  getListHistoryFailed: undefined,
  listHistory: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null,

  listRef : [],
  isLoadingRef: false,
  getListRefFailed: undefined,
  pagingRef :null,
  ref: null,
  
  //Address
  isSubmitLoadingAddress: false,
  
  isLoadingAddress: false,
  getListAddressFailed: undefined,
  listAddress: [],

  isGetByIdAddressLoading: false,
  byIdAddress: null,
  getByIdAddressFailed: null,

  updateAddressSuccess: null,
  updateAddressFailed: null,
  
  isSubmitUpdateNoteLoading: false,
  updateNoteSuccess: null,
  updateNoteFailed: null,

  deleteAddressSuccess: null,
  deleteAddressFailed: null,

  resetPasswordSuccess: null,
  resetPasswordFailed: null,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WH_CUSTOMERS_MANAGEMENT_REQUEST:
      state.isLoading = true;
      state.list = [];
      state.getListFailed = null;
      return;

    case Types.GET_WH_CUSTOMERS_MANAGEMENT_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_WH_CUSTOMERS_MANAGEMENT_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;
    case Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_REQUEST:
      state.isLoadingHistory = true;
      state.listHistory = [];
      state.getListHistoryFailed = null;
      return;

    case Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_SUCCESS:
      state.isLoadingHistory = false;
      state.listHistory = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_FAILED:
      state.isLoadingHistory = false;
      state.getListHistoryFailed = payload;
      state.listHistory = [];
      return;
    case Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_REQUEST:
      state.isLoadingRef = true;
      state.listRef = [];
      state.getListRefFailed = null;
      return;

    case Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_SUCCESS:
      state.isLoadingRef = false;
      state.listRef = get(payload,'docs',[]);
      state.ref = get(payload,'parent')
      state.pagingRef = getPaging(payload);
      return;

    case Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_FAILED:
      state.isLoadingRef = false;
      state.getListRefFailed = payload;
      state.listRef = [];
      return;

    case Types.GET_WH_CUSTOMER_MANAGEMENT_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_WH_CUSTOMER_MANAGEMENT_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_WH_CUSTOMER_MANAGEMENT_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_WH_CUSTOMER_MANAGEMENT_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_WH_CUSTOMER_MANAGEMENT_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      state.list = state.list.concat(payload);
      return;

    case Types.CREATE_WH_CUSTOMER_MANAGEMENT_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_WH_CUSTOMER_MANAGEMENT_REQUEST:
      state.isSubmitLoading = true;
      state.byId = payload;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_WH_CUSTOMER_MANAGEMENT_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      state.list = state.list.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case Types.UPDATE_WH_CUSTOMER_MANAGEMENT_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_WH_CUSTOMER_MANAGEMENT_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_WH_CUSTOMER_MANAGEMENT_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_WH_CUSTOMER_MANAGEMENT_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;
    
    //Address

    case Types.GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_REQUEST:
      state.isLoadingAddress = true;
      state.listAddress = [];
      state.getListAddressFailed = null;
      return;

    case Types.GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_SUCCESS:
      state.isLoadingAddress = false;
      state.listAddress = payload;
      return;

    case Types.GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_FAILED:
      state.isLoadingAddress = false;
      state.getListAddressFailed = payload;
      state.listAddress = [];
      return;
      case Types.GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_REQUEST:
        state.isGetByIdAddressLoading = true;
        state.byIdAddress = null;
        state.getByIdAddressFailed = null;
        return;
  
      case Types.GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_SUCCESS:
        state.isGetByIdAddressLoading = false;
        state.byIdAddress = payload;
        return;
  
      case Types.GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_FAILED:
        state.isGetByIdAddressLoading = false;
        state.getByIdAddressFailed = payload;
        return;

      case Types.UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_REQUEST:
        state.isSubmitLoadingAddress = true;
        state.byIdAddress = payload;
        state.updateAddressSuccess = null;
        state.updateAddressFailed = null;
        return;
  
      case Types.UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_SUCCESS:
        state.isSubmitLoadingAddress = false;
        state.updateAddressSuccess = payload;
        state.byIdAddress = payload;
        state.listAddress = state.listAddress.map(item => {
          if (item._id === payload._id) {
            return {...item, ...payload};
          }
          return item
        })
        return;
  
      case Types.UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_FAILED:
        state.isSubmitLoadingAddress = false;
        state.updateAddressFailed = payload;
        return;
  
      case Types.DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_REQUEST:
        state.isLoadingAddress = true;
        state.deleteAddressSuccess = null;
        state.deleteAddressFailed = null;
        return;
  
      case Types.DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_SUCCESS:
      state.deleteAddressSuccess = payload;
      state.isLoadingAddress = false;
        return;
  
      case Types.DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_FAILED:
        state.isLoadingAddress = false;
        state.deleteAddressFailed = payload;
      return;
    
    //Update Note
    case Types.UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_REQUEST:
      state.isSubmitUpdateNoteLoading = true;
      state.updateNoteSuccess = null;
      state.updateNoteFailed = null;
      return;

    case Types.UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_SUCCESS:
      state.isSubmitUpdateNoteLoading = false;
      state.updateNoteSuccess = payload;
      return;

    case Types.UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_FAILED:
      state.isSubmitUpdateNoteLoading = false;
      state.updateNoteFailed = payload;
      return;

    //Reset password
    case Types.RESET_PASSWORD_REQUEST:
      state.isSubmitLoading = true;
      state.resetPasswordSuccess = null;
      state.resetPasswordFailed = null;
      return;

    case Types.RESET_PASSWORD_SUCCESS:
      state.isSubmitLoading = false;
      state.resetPasswordSuccess = payload;
      return;

    case Types.RESET_PASSWORD_FAILED:
      state.isSubmitLoading = false;
      state.resetPasswordFailed = payload;
      return;
      

    case Types.RESET_WH_CUSTOMER_MANAGEMENT_STATE:

    case Types.RESET_NOTE_WH_CUSTOMER_MANAGEMENT_STATE:
      return initState;

    case Types.RESET_STORE:
      return initState;
    
    default:
      return;
  }
}, initState);
