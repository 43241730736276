import { useParams } from 'react-router-dom'
import React, { useEffect, useMemo, useState } from 'react'
import { useMatchPolicy, useResetWhCustomerManagement, useUpdateNoteWhCustomerManagement } from '~/hooks';
import toastr from 'toastr'
import { Button, Col, Input, List, Row, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import POLICY from '~/constants/policy';
import api from '~/api';

export default function NoteCustomer() {
  const { id } = useParams();
  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  useResetWhCustomerManagement();
  const canUpdate = useMatchPolicy(POLICY.UPDATE_CUSTOMER);

  const getNote = async () => {
    try {
      setLoading(true)
      const res = await api.whCustomerManagement.getNote({ id });
      setItems([...res?.note]);
      setLoading(false);
    } catch (error) {
      toastr.error('Lấy ghi chú người dùng thất bại');
      setLoading(false);
    };
  };
  const [isLoading, updateNote] = useUpdateNoteWhCustomerManagement(getNote);
  
  const handleSubmit = () => {
    const body = {
      id: id,
      note: [...items]
    };
    updateNote(body);
  }
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleEnterPress = () => {
    if (inputValue.trim() !== '') {
      setItems([inputValue,...items]);
      setInputValue('');
    }
  };
  const handleDelete = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const ulItem = useMemo(() => {
    return items?.map((item, index) => {
      return (
        <li style={{ color: 'rgba(0,0,0,0.85)' }} key={index}>
          {item}
        {canUpdate &&  <span
            className='note-customer__delete'
            onClick={() => {
              handleDelete(index);
            }}
          >X</span>}
        </li>
      );
    });
  }, [items]);

  useEffect(() => {
    id &&  getNote();
  }, [id])
  
  const columns = [
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      width: '80%',
      render: (text,record) => <span>{record}</span>,
    },
    ...(canUpdate ?
      [{title: '',
      dataIndex: 'delete',
      key: 'delete',
      width: '20%%',
      render: (text, record, index) => (
        <Button
          type="link"
          onClick={() => {
            handleDelete(index);
          }}
        >
          Xoá
        </Button>
      ),
    }] : [])
  ];
  return (
    <div
    style={{
      position: 'relative',
      }}>
    {canUpdate &&  <Input
        style={{
          width: '300px',
          margin: '10px 0px'
        }}
        allowClear
          value={inputValue}
          onChange={handleInputChange}
          onPressEnter={handleEnterPress}
          placeholder="Thêm ghi chú cho khách hàng..."
        />}
      {/* <List
      // style={{width:'80%', margin: '5px auto '}}
      size="small"
      header={null}
      footer={null}
      bordered = {false}
      dataSource={items}
        renderItem={(item, index) => (
          <List.Item>
            <Row style={{width:'100%'}}>
              <Col span={12}>{item}</Col>
              <Col span={4} align="right">
                <DeleteOutlined
                  style={{color: 'red'}}
                  onClick={() => {
                    handleDelete(index);
                  }}
                />
              </Col>
            </Row>
          </List.Item>
        )}
      /> */}
      <Table
        columns={columns}
        dataSource={items}
        style={{ width: '800px' }}
        pagination={false}
        scroll={{ y: 300 }}
      />
    {canUpdate &&  <Button
        loading={isLoading}
        onClick={handleSubmit}
        type='primary'
        style={{
          position: 'sticky',
          bottom: 0,
          marginTop: '10px',
        }}
      >Cập nhật</Button>}
    </div>
  )
}
