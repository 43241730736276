import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  useBranchIdSessionStorage
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhCustomersImport,
  createWhCustomerImport,
  deleteWhCustomerImport,
  getWhCustomerImport,
  resetWhCustomerImportState,
  updateWhCustomerImport,
  convertWhCustomerImport
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import moment from 'moment';
// import { fromJSON } from '~/components/UserEmployee/parser';

const getSelector = (key) => (state) => state.whCustomerImport[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const convertSuccessSelector = getSelector('convertSuccess');
const convertFailedSelector = getSelector('convertFailed');

const pagingSelector = getSelector('paging');

export const useWhCustomerImportPaging = () => useSelector(pagingSelector);

export const useUpdateCustomerImportParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    history.push({
      pathname: `/potential-customer`,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useWhCustomersImportQueryParams = (param) => {
  const [branchId] = useBranchIdSessionStorage();
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const search = query.get(param);
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const status = 'customer';
  const startTime = query.get('startTime');
  const endTime = query.get('endTime');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteWhCustomerImportSuccess = useSelector(deleteSuccessSelector);
  const createWhCustomerImportSuccess = useSelector(createSuccessSelector);
  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      startTime,
      endTime,
      [param]: search,
      status,
      keyword,
      branchId
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    deleteWhCustomerImportSuccess,
    startTime,
    endTime,
    search,
    createWhCustomerImportSuccess,
    branchId,
  ]);
};

export const useWhCustomersImport = (query) => {
  return useFetchByParam({
    action: getWhCustomersImport,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateWhCustomerImport = () => {
  // const history = useHistory();
  // const callback = () => history.push('/potential-customer');
  useSuccess(createSuccessSelector, 'Thêm mới khách hàng thành công');
  useFailed(createFailedSelector, 'Thêm mới khách hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhCustomerImport
  });
};

export const useUpdateWhCustomerImport = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật khách hàng thành công');
  useFailed(updateFailedSelector, 'Cập nhật khách hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhCustomerImport
  });
};

export const useDeleteWhCustomerImport = () => {
  useSuccess(deleteSuccessSelector, 'Xoá khách hàng thành công');
  useFailed(deleteFailedSelector, 'Xoá khách hàng thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhCustomerImport
  });
};

export const useConvertWhCustomerImport = () => {
  useSuccess(convertSuccessSelector, 'Convert khách hàng thành công');
  useFailed(convertFailedSelector);

  return useSubmit({
    loadingSelector,
    action: convertWhCustomerImport
  });
};

export const useWhCustomerImport = (id) => {
  return useFetchByParam({
    action: getWhCustomerImport,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitWhCustomerImport = (whCustomerImport) =>
  useMemo(() => {
    if (!whCustomerImport) {
      return {};
    }
    const initValues = { ...whCustomerImport };
    return initValues.data;
  }, [whCustomerImport]);

export const useResetWhCustomerImport = () => {
  useResetState(resetWhCustomerImportState);
};
