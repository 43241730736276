import requester from './requester';

const appointmentAssociate = {
  getAll: query => requester.get('/appointment-associate',query),
  getById: id => requester.get(`appointment-associate/${id}`),
  delete: id => requester.delete(`appointment-associate/${id}`),
  create: appointmentAssociate => requester.post('appointment-associate', appointmentAssociate),
  update: appointmentAssociate => requester.put(`appointment-associate/${appointmentAssociate.id}`, appointmentAssociate)
};

export default appointmentAssociate;
