import { put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';
import { get} from 'lodash';
import { TaskRelationOption } from '~/components/WorkFlow/Detail/RelationTask';
import { Empty } from 'antd';

//board



//task
function* getAllTask({ payload }) {
    try {
        const response = yield call(Api.workFlow.getAllTask, payload);
        yield put({ type: Types.GET_ALL_TASKS_SUCCESS, payload: response });
    } catch (error) {
        yield put({ type: Types.GET_ALL_TASKS_FAILED, payload: error.message });
    }
}
function* getTaskById({ payload }) {
    try {
        const profile = yield select((state) => state.user.profile);
        const response = yield call(Api.workFlow.getByIdTask, payload);
        const managers = yield call(Api.workFlow.getAllManagersByIdBoard, get(response, 'boardId'));
        // Check if User is a Super admin or manager assigned
        if (!!get(profile, 'isSuperAdmin') || managers?.some(item => get(item, '_id') === get(profile, '_id'))) {
            yield put({ type: Types.GET_TASK_BY_ID_SUCCESS, payload: { ...response, progressListShow: get(response, 'progressList') } });
        } else {
            const progressListShow = get(response, 'progressList', [])?.map(item => {
              //if job not assign anyone then show everyone see task 
              // if job has assign someone then show for user
                let progressShow = get(item, 'progress', [])?.filter(progress => get(progress, '[0].assign', '')?.includes(get(profile, '_id'))||get(progress, '[0].assign', '')==='');
                return { ...item, progress: progressShow }
            });
            yield put({ type: Types.GET_TASK_BY_ID_SUCCESS, payload: { ...response, progressListShow } });
        }
    } catch (error) {
        yield put({ type: Types.GET_TASK_BY_ID_FAILED, payload: error.message });
    }
}
function* createTask(action) {
    try {
        const response = yield call(Api.workFlow.createTask, action.payload);
        yield put({ type: Types.CREATE_TASK_SUCCESS, payload: response });
        yield put({ type: Types.ADD_BOARD_CONFIG_ITEM_REQUEST, payload: { id: action.payload.boardConfigId } });

    } catch (error) {
        yield put({ type: Types.CREATE_TASK_FAILED, payload: error.message });
    }
}

function* updateTask({ payload }) {
    try {
        const data = yield call(Api.workFlow.updateTask, payload);
        yield put({ type: Types.ADD_BOARD_CONFIG_ITEM_SUCCESS, payload: get(data.data, 'boardConfigId')});
        yield put({ type: Types.UPDATE_TASK_INIT_SUCCESS, payload: {dataTask:data.data,boardId: get(data.data, 'boardConfigId'),idTask :get(data.data,'_id')}});
        const profile = yield select((state) => state.user.profile);
        const response= get(data,'data')
        const managers = yield call(Api.workFlow.getAllManagersByIdBoard, get(response, 'boardId'));
        // const res = yield call(Api.workFlow.getByIdTask, get(response, '_id'));
      
        // Check if User is a Super admin or manager assigned
        if (!!get(profile, 'isSuperAdmin') || managers?.some(item => get(item, '_id') === get(profile, '_id'))) {
            yield put({ type: Types.UPDATE_TASK_SUCCESS, payload: { ...response, progressListShow: get(response, 'progressList') } });
        } else {
            const progressListShow = get(response, 'progressList', [])?.map(item => {
                let progressShow = get(item, 'progress', [])?.filter(progress => get(progress, '[0].assign', '')?.includes(get(profile, '_id'))||get(progress, '[0].assign', '')==='');
                return { ...item, progress: progressShow }
            });
            yield put({ type: Types.UPDATE_TASK_SUCCESS, payload: { ...response, progressListShow } });
        }
    } catch (error) {
        yield put({ type: Types.UPDATE_TASK_FAILED, payload: error.message });
    }
}
function* deleteTask({ payload }) {
    try {
        const data = yield call(Api.workFlow.deleteTask, payload.id);
        yield put({ type: Types.DELETE_TASK_SUCCESS, payload: data });
        yield put({ type: Types.ADD_BOARD_CONFIG_ITEM_REQUEST, payload: { id: payload.boardConfigId } });
    } catch (error) {
        yield put({ type: Types.DELETE_TASK_FAILED, payload: error.message });
    }
}
//
function* getHistoryActivityTaskById({ payload }) {
    try {
        const data = yield call(Api.workFlow.getHistoryTaskById, payload.id);
        yield put({ type: Types.GET_HISTORY_ACTIVITY_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: Types.GET_HISTORY_ACTIVITY_FAILED, payload: error.message });
    }
}

function* onAssign({ payload }) {
    try {
        const data = yield call(Api.workFlow.updateTask, payload);
        yield put({ type: Types.ASSIGN_TASK_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: Types.ASSIGN_TASK_FAILED, payload: error });
    }
}

function* updateProgressTask({ payload }) {
    try {
        const data = yield call(Api.workFlow.updateProgressTask, payload);
        const profile = yield select((state) => state.user.profile);
        const response = get(data, 'data');
        const managers = yield call(Api.workFlow.getAllManagersByIdBoard, get(response, 'boardId'));
        // Check if User is a Super admin or manager assigned
        if (!!get(profile, 'isSuperAdmin') || managers?.some(item => get(item, '_id') === get(profile, '_id'))) {
            yield put({ type: Types.UPDATE_PROGRESS_TASK_SUCCESS, payload: { ...response, progressListShow: get(response, 'progressList') } });
        } else {
            const progressListShow = get(response, 'progressList', [])?.map(item => {
                let progressShow = get(item, 'progress', [])?.filter(progress => get(progress, '[0].assign', '')?.includes(get(profile, '_id'))||get(progress, '[0].assign', '')==='');
                return { ...item, progress: progressShow }
            });
            yield put({ type: Types.UPDATE_PROGRESS_TASK_SUCCESS, payload: { ...response, progressListShow } });
        }



        // yield put({ type: Types.UPDATE_PROGRESS_TASK_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: Types.UPDATE_PROGRESS_TASK_FAILED, payload: error });
    }
}
function* copyTask ({ payload }) {
    try {
        const data = yield call(Api.workFlow.copyTask, payload);
        yield put({type:Types.COPY_TASK_SUCCESS,payload:{data:data?.data}});
        yield put({ type: Types.ADD_BOARD_CONFIG_ITEM_REQUEST, payload: { id: data?.data.boardConfigId } });
    } catch (error) {
        yield put({ type: Types.COPY_TASK_FAILED, payload: error });
    }
}


function* commentPushTask({payload}){
  try {
    const data = yield call(Api.workFlow.pushComment, payload);
    yield put({ type: Types.COMMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.COMMENT_FAILED, payload: error.message });
  }
}

function* pushEmtionTask({payload}){
  try {
    const data = yield call(Api.workFlow.pushEmotion, payload);
    yield put({ type: Types.EMOTION_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.EMOTION_FAILED, payload: error.message });
  }
}
function* deleteCommentTask({payload}){
  try {
    const data = yield call(Api.workFlow.deleteComment, payload);
    yield put({ type: Types.COMMENT_DELETE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.COMMENT_DELETE_FAILED, payload: error.message });
  }
}
function* updateCommentTask({payload}){
  try {
    const data = yield call(Api.workFlow.updateCommentById, payload);
    yield put({ type: Types.COMMENT_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.COMMENT_UPDATE_FAILED, payload: error.message });
  }
}

function* searchTaskItemTask({payload}){
  try {
    const data = yield call(Api.workFlow.searchTaskByBoardId, payload);
    let convertDatatoOption = data.map((task)=>({
      value:task._id,
      label:<TaskRelationOption task={task}/>
    }))
    if(!data.length){
      convertDatatoOption=[{value:'unkow',label:<Empty imageStyle={{width:40}} />}]
    }
    payload.action(convertDatatoOption)
    yield put({ type: Types.SEARCH_TASK_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.SEARCH_TASK_FAILED, payload: error.message });
  }
}

function* updateRelationTaskItemTask({payload}){
  try {
    const data = yield call(Api.workFlow.updateRelationTask, payload);
    const listTask = yield call(Api.workFlow.getRelationTask, get(payload,'taskItemId',''));
    yield put({ type: Types.GET_RELATION_SUCCESS, payload: listTask });
    yield put({ type: Types.UPDATE_RELATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.UPDATE_RELATION_FAILED, payload: error.message });
  }
}

function* getRelationTaskItemTask({payload}){
  try {
    const data = yield call(Api.workFlow.getRelationTask, get(payload,'taskId',''));
    yield put({ type: Types.GET_RELATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_RELATION_FAILED, payload: error.message });
  }
}



export default function* workListTask() {


    //task
    yield takeLatest(Types.GET_ALL_TASKS_REQUEST, getAllTask);
    yield takeLatest(Types.GET_TASK_BY_ID_REQUEST, getTaskById);
    yield takeLatest(Types.CREATE_TASK_REQUEST, createTask);
    yield takeLatest(Types.UPDATE_TASK_REQUEST, updateTask);
    yield takeLatest(Types.DELETE_TASK_REQUEST, deleteTask);
    yield takeLatest(Types.GET_HISTORY_ACTIVITY_REQUEST, getHistoryActivityTaskById);
    yield takeLatest(Types.ASSIGN_TASK_REQUEST, onAssign);
    yield takeLatest(Types.UPDATE_PROGRESS_TASK_REQUEST, updateProgressTask);
    yield takeLatest(Types.COPY_TASK_REQUEST, copyTask);
    yield takeLatest(Types.COMMENT_REQUEST, commentPushTask);
    yield takeLatest(Types.EMOTION_REQUEST, pushEmtionTask);
    yield takeLatest(Types.COMMENT_DELETE_REQUEST, deleteCommentTask);
    yield takeLatest(Types.COMMENT_UPDATE_REQUEST, updateCommentTask);
    yield takeLatest(Types.SEARCH_TASK_REQUEST, searchTaskItemTask);
    yield takeLatest(Types.UPDATE_RELATION_REQUEST, updateRelationTaskItemTask);
    yield takeLatest(Types.GET_RELATION_REQUEST, getRelationTaskItemTask);
    // yield takeLatest(Types.UPDATE_TASK_REQUEST, updateTaskInit);

    // yield takeLatest(Types.UPDATE_TASK_REQUEST, updateTaskInit);
    
}
