import requester from './requester';

const importExcel = {
  postFile: (file) =>
    requester.post('/importExcel', file, {
      'Content-Type': 'multipart/form-data'
    })
};

export default importExcel;
