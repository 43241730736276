import { Form, Input, Popover } from 'antd';
import React from 'react';
import { ColorPickerModule } from './Form/StatusForm';

function StatusEditForm({ form, inputRef, save,field }) {
  return (
    <Popover
      style={{ borderRadius: 20 }}
      destroyTooltipOnHide
      content={
        <ColorPickerModule
          initval={form.getFieldValue(field)}
          onChange={(e) => {
            form.setFieldsValue({ [field]: e });
          }}
        />
      }
      title="Color"
      visible
    >
      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
        {() => (
          <Form.Item name={field} style={{ marginBottom: 0 }}>
            <Input
              style={{ outline: 'none', border: 'none' }}
              onPressEnter={save}
              onBlur={save}
              ref={inputRef}
              readOnly
              addonBefore={
                <div
                  style={{
                    backgroundColor:
                      form.getFieldValue(field) || '#0000ff',
                    width: 30,
                    height: 30,
                    // marginLeft: 26,
                    border: '1px solid #c1bdbde8',
                    cursor: 'pointer'
                  }}
                ></div>
              }
            />
          </Form.Item>
        )}
      </Form.Item>
    </Popover>
  );
}

export default StatusEditForm;
