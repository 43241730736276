import produce from 'immer';
import { get, omit } from 'lodash';
import {
  GET_WH_PARTNERS_REQUEST,
  GET_WH_PARTNERS_SUCCESS,
  GET_WH_PARTNERS_FAILED,
  DELETE_WH_PARTNER_REQUEST,
  DELETE_WH_PARTNER_SUCCESS,
  DELETE_WH_PARTNER_FAILED,
  CREATE_WH_PARTNER_REQUEST,
  CREATE_WH_PARTNER_SUCCESS,
  CREATE_WH_PARTNER_FAILED,
  INVITE_WH_PARTNER_REQUEST,
  INVITE_WH_PARTNER_SUCCESS,
  INVITE_WH_PARTNER_FAILED,
  GET_WH_PARTNER_REQUEST,
  GET_WH_PARTNER_SUCCESS,
  GET_WH_PARTNER_FAILED,
  UPDATE_WH_PARTNER_REQUEST,
  UPDATE_WH_PARTNER_SUCCESS,
  UPDATE_WH_PARTNER_FAILED,
  UPDATE_WH_PARTNER_SERVICE_REQUEST,
  UPDATE_WH_PARTNER_SERVICE_SUCCESS,
  UPDATE_WH_PARTNER_SERVICE_FAILED,
  APPROVE_WH_PARTNER_REQUEST,
  APPROVE_WH_PARTNER_SUCCESS,
  APPROVE_WH_PARTNER_FAILED,
  RESET_WH_PARTNER_STATE,
  RESET_STORE,
  UPDATE_WH_PARTNER_TIPS_REQUEST,
  UPDATE_WH_PARTNER_TIPS_SUCCESS,
  UPDATE_WH_PARTNER_TIPS_FAILED,
  CREATE_WH_PARTNER_SERVICE_REQUEST,
  CREATE_WH_PARTNER_SERVICE_SUCCESS,
  CREATE_WH_PARTNER_SERVICE_FAILED,
  DELETE_WH_PARTNER_SERVICE_REQUEST,
  DELETE_WH_PARTNER_SERVICE_SUCCESS,
  DELETE_WH_PARTNER_SERVICE_FAILED,
  GET_WH_PARTNERS_FOR_REPORT_REQUEST,
  GET_WH_PARTNERS_FOR_REPORT_SUCCESS,
  GET_WH_PARTNERS_FOR_REPORT_FAILED,
  UPDATE_WORKING_TIME_WH_PARTNER_REQUEST,
  UPDATE_WORKING_TIME_WH_PARTNER_SUCCESS,
  UPDATE_WORKING_TIME_WH_PARTNER_FAILED,
  CONVERT_TO_PARTNER_REQUEST,
  CONVERT_TO_PARTNER_SUCCESS,
  CONVERT_TO_PARTNER_FAILED
} from '../../constants/actionTypes';
import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getWhPartnersFailed: undefined,
  whPartners: [],

  getWhPartnersReportFailed: undefined,
  whPartnersReport: [],

  isGetWhPartnerLoading: false,
  whPartner: null,
  getWhPartnerFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  createServiceSuccess: null,
  createServiceFailed: null,

  updateSuccess: null,
  updateFailed: null,

  updateTipsSuccess: null,
  updateTipsFailed: null,
  updateServiceSuccess: null,
  updateServiceFailed: null,

  isApproveLoading: false,
  approveSuccess: null,
  approveFailed: null,

  updateWorkingTimeSuccess: null,
  updateWorkingTimeFailed: null,

  convertToPartnerSuccess: null,
  convertToPartnerFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_WH_PARTNERS_REQUEST:
      state.isLoading = true;
      state.getWhPartnersFailed = null;
      return;

    case GET_WH_PARTNERS_SUCCESS:
      state.isLoading = false;
      state.whPartners = payload.docs;
      state.getWhPartnersFailed = null;
      state.paging = getPaging(payload);
      return;

    case GET_WH_PARTNERS_FAILED:
      state.isLoading = false;
      state.whPartners = [];
      state.getWhPartnersFailed = null;
      return;
    case GET_WH_PARTNERS_FOR_REPORT_REQUEST:
      state.isLoading = true;
      state.getWhPartnersReportFailed = null;
      return;

    case GET_WH_PARTNERS_FOR_REPORT_SUCCESS:
      state.isLoading = false;
      state.whPartnersReport = payload.docs;
      state.getWhPartnersReportFailed = null;
      state.paging = getPaging(payload);
      return;

    case GET_WH_PARTNERS_FOR_REPORT_FAILED:
      state.isLoading = false;
      state.whPartnersReport = [];
      state.getWhPartnersReportFailed = null;
      return;

    case GET_WH_PARTNER_REQUEST:
      state.isGetWhPartnerLoading = true;
      state.whPartner = null;
      state.getWhPartnerFailed = null;
      return;

    case GET_WH_PARTNER_SUCCESS:
      state.isGetWhPartnerLoading = false;
      state.whPartner = get(payload, 'docs.0');
      return;

    case GET_WH_PARTNER_FAILED:
      state.isGetWhPartnerLoading = false;
      state.getWhPartnerFailed = payload;
      return;

    case DELETE_WH_PARTNER_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_WH_PARTNER_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_WH_PARTNER_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case DELETE_WH_PARTNER_SERVICE_REQUEST:
      state.isSubmitLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_WH_PARTNER_SERVICE_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case DELETE_WH_PARTNER_SERVICE_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_WH_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_WH_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_WH_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    // CREATE SERVICE IN WH_PARTNER_LIST_SERVICE
    case CREATE_WH_PARTNER_SERVICE_REQUEST:
      state.isSubmitLoading = true;
      state.createServiceSuccess = null;
      state.createServiceFailed = null;
      return;

    case CREATE_WH_PARTNER_SERVICE_SUCCESS:
      state.isSubmitLoading = false;
      state.createServiceSuccess = payload;
      return;

    case CREATE_WH_PARTNER_SERVICE_FAILED:
      state.isSubmitLoading = false;
      state.createServiceFailed = payload;
      return;

    case INVITE_WH_PARTNER_REQUEST:
      state.isInviteLoading = true;
      state.inviteSuccess = null;
      state.inviteFailed = null;
      return;

    case INVITE_WH_PARTNER_SUCCESS:
      state.isInviteLoading = false;
      state.inviteSuccess = payload;
      return;

    case INVITE_WH_PARTNER_FAILED:
      state.isInviteLoading = false;
      state.inviteFailed = payload;
      return;

    case UPDATE_WH_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_WH_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.whPartner = omit(payload, ['password']); // at the moment, update API returns a different form of response and causes issue here. We disabled this temporarily. (UPDATE 10/05/2023 : AT THIT moment API return NEW DATA)
      state.whPartners = state.whPartners.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...omit(payload, ['password']) };
        }
        return item;
      });
      return;

    case UPDATE_WH_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;
    case UPDATE_WH_PARTNER_TIPS_REQUEST:
      state.isSubmitLoading = true;
      state.updateTipsSuccess = null;
      state.updateTipsFailed = null;
      return;

    case UPDATE_WH_PARTNER_TIPS_SUCCESS:
      state.isSubmitLoading = false;
      state.updateTipsSuccess = payload;
      return;

    case UPDATE_WH_PARTNER_TIPS_FAILED:
      state.isSubmitLoading = false;
      state.updateTipsFailed = payload;
      return;

    case UPDATE_WH_PARTNER_SERVICE_REQUEST:
      state.isSubmitLoading = true;
      state.updateServiceSuccess = null;
      state.updateServiceFailed = null;
      return;
    case UPDATE_WH_PARTNER_SERVICE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateServiceSuccess = payload;
      return;
    case UPDATE_WH_PARTNER_SERVICE_FAILED:
      state.isSubmitLoading = false;
      state.updateServiceFailed = payload;
      return;
    case APPROVE_WH_PARTNER_REQUEST:
      state.isApproveLoading = true;
      state.approveSuccess = null;
      state.approveFailed = null;
      return;

    case APPROVE_WH_PARTNER_SUCCESS:
      state.isApproveLoading = false;
      state.approveSuccess = payload;
      state.whPartner = {
        ...state.whPartner,
        waitingApproval: false
      };

      // not sure it is neccessary to update the single instance in the list or not
      // state.whPartners = state.whPartners.map(item => {
      //   if (item._id === payload._id) {
      //     return {...item, ...payload};
      //   }
      //   return item
      // });
      return;

    case APPROVE_WH_PARTNER_FAILED:
      state.isApproveLoading = false;
      state.approveFailed = payload;
      return;

    case UPDATE_WORKING_TIME_WH_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.updateWorkingTimeSuccess = null;
      state.updateWorkingTimeFailed = null;
      return;

    case UPDATE_WORKING_TIME_WH_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateWorkingTimeSuccess = payload;
      state.whPartner = omit(payload, ['password']); // at the moment, update API returns a different form of response and causes issue here. We disabled this temporarily. (UPDATE 10/05/2023 : AT THIT moment API return NEW DATA)
      state.whPartners = state.whPartners.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...omit(payload, ['password']) };
        }
        return item;
      });
      return;

    case UPDATE_WORKING_TIME_WH_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.updateWorkingTimeFailed = payload;
      return;

    case CONVERT_TO_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.convertToPartnerSuccess = null;
      state.convertToPartnerFailed = null;
      return;

    case CONVERT_TO_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.convertToPartnerSuccess = payload;
      return;

    case CONVERT_TO_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.convertToPartnerFailed = payload;
      return;

    case RESET_WH_PARTNER_STATE:
    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
