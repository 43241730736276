export const GET_PARTNER_CANCEL_APPOINTMENTS_REQUEST =
  'GET_PARTNER_CANCEL_APPOINTMENTS_REQUEST';
export const GET_PARTNER_CANCEL_APPOINTMENTS_SUCCESS =
  'GET_PARTNER_CANCEL_APPOINTMENTS_SUCCESS';
export const GET_PARTNER_CANCEL_APPOINTMENTS_FAILED = 'GET_PARTNER_CANCEL_APPOINTMENTS_FAILED';

export const GET_PARTNER_CANCEL_APPOINTMENTS_PARTNER_REQUEST =
  'GET_PARTNER_CANCEL_APPOINTMENTS_PARTNER_REQUEST';
export const GET_PARTNER_CANCEL_APPOINTMENTS_PARTNER_SUCCESS =
  'GET_PARTNER_CANCEL_APPOINTMENTS_PARTNER_SUCCESS';
export const GET_PARTNER_CANCEL_APPOINTMENTS_PARTNER_FAILED = 'GET_PARTNER_CANCEL_APPOINTMENTS_PARTNER_FAILED';

export const GET_PARTNER_CANCEL_APPOINTMENT_REQUEST = 'GET_PARTNER_CANCEL_APPOINTMENT_REQUEST';
export const GET_PARTNER_CANCEL_APPOINTMENT_SUCCESS = 'GET_PARTNER_CANCEL_APPOINTMENT_SUCCESS';
export const GET_PARTNER_CANCEL_APPOINTMENT_FAILED = 'GET_PARTNER_CANCEL_APPOINTMENT_FAILED';

export const CREATE_PARTNER_CANCEL_APPOINTMENT_REQUEST =
  'CREATE_PARTNER_CANCEL_APPOINTMENT_REQUEST';
export const CREATE_PARTNER_CANCEL_APPOINTMENT_SUCCESS =
  'CREATE_PARTNER_CANCEL_APPOINTMENT_SUCCESS';
export const CREATE_PARTNER_CANCEL_APPOINTMENT_FAILED =
  'CREATE_PARTNER_CANCEL_APPOINTMENT_FAILED';

export const UPDATE_PARTNER_CANCEL_APPOINTMENT_REQUEST =
  'UPDATE_PARTNER_CANCEL_APPOINTMENT_REQUEST';
export const UPDATE_PARTNER_CANCEL_APPOINTMENT_SUCCESS =
  'UPDATE_PARTNER_CANCEL_APPOINTMENT_SUCCESS';
export const UPDATE_PARTNER_CANCEL_APPOINTMENT_FAILED =
  'UPDATE_PARTNER_CANCEL_APPOINTMENT_FAILED';

export const DELETE_PARTNER_CANCEL_APPOINTMENT_REQUEST =
  'DELETE_PARTNER_CANCEL_APPOINTMENT_REQUEST';
export const DELETE_PARTNER_CANCEL_APPOINTMENT_SUCCESS =
  'DELETE_PARTNER_CANCEL_APPOINTMENT_SUCCESS';
export const DELETE_PARTNER_CANCEL_APPOINTMENT_FAILED =
  'DELETE_PARTNER_CANCEL_APPOINTMENT_FAILED';


export const RESET_PARTNER_CANCEL_APPOINTMENT_STATE = 'RESET_PARTNER_CANCEL_APPOINTMENT_STATE';
