import produce from 'immer';
import { get } from 'lodash';
import {
  GET_WH_BILL_ITEMS_REQUEST,
  GET_WH_BILL_ITEMS_SUCCESS,
  GET_WH_BILL_ITEMS_FAILED,
  DELETE_WH_BILL_ITEM_REQUEST,
  DELETE_WH_BILL_ITEM_SUCCESS,
  DELETE_WH_BILL_ITEM_FAILED,
  CREATE_WH_BILL_ITEM_REQUEST,
  CREATE_WH_BILL_ITEM_SUCCESS,
  CREATE_WH_BILL_ITEM_FAILED,
  GET_WH_BILL_ITEM_REQUEST,
  GET_WH_BILL_ITEM_SUCCESS,
  GET_WH_BILL_ITEM_FAILED,
  UPDATE_WH_BILL_ITEM_REQUEST,
  UPDATE_WH_BILL_ITEM_SUCCESS,
  UPDATE_WH_BILL_ITEM_FAILED,
  CUSTOMER_CANCEL_WH_BILL_ITEM_REQUEST,
  CUSTOMER_CANCEL_WH_BILL_ITEM_SUCCESS,
  CUSTOMER_CANCEL_WH_BILL_ITEM_FAILED,
  CUSTOMER_COMPLETED_WH_BILL_ITEM_REQUEST,
  CUSTOMER_COMPLETED_WH_BILL_ITEM_SUCCESS,
  CUSTOMER_COMPLETED_WH_BILL_ITEM_FAILED,
  UPDATE_WH_APPOINTMENTS_REQUEST,
  UPDATE_WH_APPOINTMENTS_SUCCESS,
  UPDATE_WH_APPOINTMENTS_FAILED,
  RESET_WH_BILL_ITEM_STATE,
  RESET_STORE,
  CREATE_CANCEL_APPOINTMENT_REQUEST,
  CREATE_CANCEL_APPOINTMENT_SUCCESS,
  CREATE_CANCEL_APPOINTMENT_FAILED,
  RESET_CREATE_CANCEL
} from '../../constants/actionTypes';
import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getWhBillItemsFailed: undefined,
  whBillItems: [],

  isGetWhBillItemLoading: false,
  whBillItem: null,
  getWhBillItemFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  isCustomerCancelLoading: false,
  customerCancelSuccess: null,
  customerCancelFailed: null,

  isCustomerCompletedLoading: false, 
  customerCompletedSuccess: null,
  customerCompletedFailed: null,

  isUpdateWhAppointmentsLoading: null,
  updateWhAppointmentsSuccess: null,
  updateWhAppointmentsFailed: null,

  isCancelWhAppointmentsLoading: false,
  cancelWhAppointmentsSuccess: null,
  cancelWhAppointmentsFailed: null,
  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_WH_BILL_ITEMS_REQUEST:
      state.isLoading = true;
      state.getWhBillItemsFailed = null;
      return;

    case GET_WH_BILL_ITEMS_SUCCESS:
      state.isLoading = false;
      state.whBillItems = payload.docs;
      state.getWhBillItemsFailed = null;
      state.paging = getPaging(payload);
      return;

    case GET_WH_BILL_ITEMS_FAILED:
      state.isLoading = false;
      state.whBillItems = [];
      state.getWhBillItemsFailed = payload;
      return;

    case GET_WH_BILL_ITEM_REQUEST:
      state.isGetWhBillItemLoading = true;
      state.whBillItem = null;
      state.getWhBillItemFailed = null;
      return;

    case GET_WH_BILL_ITEM_SUCCESS:
      state.isGetWhBillItemLoading = false;
      state.whBillItem = payload;
      return;

    case GET_WH_BILL_ITEM_FAILED:
      state.isGetWhBillItemLoading = false;
      state.getWhBillItemFailed = payload;
      return;

    case DELETE_WH_BILL_ITEM_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_WH_BILL_ITEM_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_WH_BILL_ITEM_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_WH_BILL_ITEM_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_WH_BILL_ITEM_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_WH_BILL_ITEM_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case UPDATE_WH_BILL_ITEM_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_WH_BILL_ITEM_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.whBillItem = payload;
      state.whBillItems = state.whBillItems.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case UPDATE_WH_BILL_ITEM_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case CUSTOMER_CANCEL_WH_BILL_ITEM_REQUEST:
      state.isCustomerCancelLoading = true;
      state.customerCancelSuccess = null;
      state.customerCancelFailed = null;
      return;

    case CUSTOMER_CANCEL_WH_BILL_ITEM_SUCCESS:
      state.isCustomerCancelLoading = false;
      state.customerCancelSuccess = payload;
      state.whBillItem = payload;
      state.whBillItems = state.whBillItems.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case CUSTOMER_CANCEL_WH_BILL_ITEM_FAILED:
      state.isCustomerCancelLoading = false;
      state.customerCancelFailed = payload;
      return;

    case CUSTOMER_COMPLETED_WH_BILL_ITEM_REQUEST:
      state.isCustomerCompletedLoading = true;
      state.customerCompletedSuccess = null;
      state.customerCompletedFailed = null;
      return;

    case CUSTOMER_COMPLETED_WH_BILL_ITEM_SUCCESS:
      state.isCustomerCompletedLoading = false;
      state.customerCompletedSuccess = payload;
      state.whBillItem = payload;
      state.whBillItems = state.whBillItems.map(item => {
        if(item._id===item._id) return {...item, ...payload};
        return item
      });
      return;
    case CUSTOMER_COMPLETED_WH_BILL_ITEM_FAILED:
      state.isCustomerCompletedLoading = false;
      state.customerCompletedFailed = payload;
      return;

    case UPDATE_WH_APPOINTMENTS_REQUEST:
      state.isUpdateWhAppointmentsLoading = true;
      state.updateWhAppointmentsSuccess = null;
      state.updateWhAppointmentsFailed = null;
      return;

    case UPDATE_WH_APPOINTMENTS_SUCCESS:
      state.isUpdateWhAppointmentsLoading = false;
      state.updateWhAppointmentsSuccess = payload;
      return;

    case UPDATE_WH_APPOINTMENTS_FAILED:
      state.isUpdateWhAppointmentsLoading = false;
      state.updateWhAppointmentsFailed = payload;
      return;
    case CREATE_CANCEL_APPOINTMENT_REQUEST:
      state.isCancelWhAppointmentsLoading = true
      state.cancelWhAppointmentsSuccess = null;
      state.cancelWhAppointmentsFailed = null;
      return;
    case CREATE_CANCEL_APPOINTMENT_SUCCESS:
      state.isCancelWhAppointmentsLoading = false
      state.cancelWhAppointmentsSuccess = payload;
      state.whBillItems = state.whBillItems.map(item => {
        if (item._id === get(payload,'whBillItemId')) {
          return {...item,appointmentCancelPartner : [...get(item,'appointmentCancelPartner',[]),payload]};
        }
        return item
      })
      return;
    case CREATE_CANCEL_APPOINTMENT_FAILED:
      state.isCancelWhAppointmentsLoading = false
      state.cancelWhAppointmentsFailed = payload;
      return;

    case RESET_CREATE_CANCEL:
      state.cancelWhAppointmentsSuccess = null;
      state.cancelWhAppointmentsFailed = null;
      return 

    case RESET_WH_BILL_ITEM_STATE:
    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
