import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getTrainingCourses({ payload: query }) {
    try {
      const response = yield call(Api.TrainingCourse.getAll, query);
      yield put({ type: Types.GET_TRAINING_COURSES_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_TRAINING_COURSES_FAILED, payload: error });
    }
  }
function* getTrainingCourse({ payload: id }) {
    try {
      const response = yield call(Api.TrainingCourse.getOne, id);
      yield put({ type: Types.GET_TRAINING_COURSE_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_TRAINING_COURSE_FAILED, payload: error });
    }
  }
  function* createTrainingCourse(action) {
    try {
      const data = yield call(Api.TrainingCourse.create, action.payload);
      yield put({ type: Types.CREATE_TRAINING_COURSE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_TRAINING_COURSE_FAILED, payload: error });
    }
  }
  function* updateTrainingCourse(action) {
    try {
      const data = yield call(Api.TrainingCourse.update, action.payload);
      yield put({ type: Types.UPDATE_TRAINING_COURSE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_TRAINING_COURSE_FAILED, payload: error });
    }
  }
  function* deleteTrainingCourse(action) {
    try {
      const data = yield call(Api.TrainingCourse.delete, action.payload);
      yield put({ type: Types.DELETE_TRAINING_COURSE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_TRAINING_COURSE_FAILED, payload: error});
    }
  }
export default function* TrainingCourses() {
    yield takeLatest(Types.GET_TRAINING_COURSES_REQUEST, getTrainingCourses);
    yield takeLatest(Types.GET_TRAINING_COURSE_REQUEST, getTrainingCourse);
    yield takeLatest(Types.CREATE_TRAINING_COURSE_REQUEST, createTrainingCourse);
    yield takeLatest(Types.UPDATE_TRAINING_COURSE_REQUEST, updateTrainingCourse);
    yield takeLatest(Types.DELETE_TRAINING_COURSE_REQUEST, deleteTrainingCourse);
  }