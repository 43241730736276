import React, { useState } from 'react';
import {
  useExperiences,
  useUpdateRateConfig,
  useRateConfigQueryParams
} from '~/hooks/rateConfig';
import { Table, Button, Input, Switch, Modal } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import ExperienceForm from './Form';
import { EditFilled } from '@ant-design/icons';
import { useSpecialityOptions } from '~/hooks/speciality';
import Item from 'antd/lib/list/Item';
import { set } from 'lodash';
import { useMatchPolicy } from '~/hooks';
import POLICY from '~/constants/policy';
import WithPermission from '~/components/Common/WithPermission';

function onChange(checked) {
  console.log(`switch to ${checked}`);
}
const { Search } = Input;
const Experience = ({ history }) => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHEXPERIENCE);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHEXPERIENCE);
  const [query, onPagingChange] = useRateConfigQueryParams();
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [experiences, isLoading] = useExperiences(query);
  const specialityOptions = useSpecialityOptions();
  const [keyword, setKeyword] = useState(query.keyword);
  // const [, deleteExperience] = useDeleteRateConfig();
  const [isSubmitLoading, updateExperience] = useUpdateRateConfig();
  const [experienceId, setExperienceId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(1);

  const ColumnActions = ({
    id,
    state,
    deleteExperience,
    updateExperience,
    isSubmitLoading,
    ...restProps
  }) => {
    return (
      <div className="custom-table__actions">
        <WithPermission permission={POLICY.UPDATE_WHEXPERIENCE}>
          <EditFilled
            onClick={() => {
              setIsOpen(true);
              setExperienceId(id);
            }}
            style={{ color: '#1f89e5' }}
          />
        </WithPermission>
        <WithPermission permission={POLICY.UPDATE_WHEXPERIENCE}>
          <WithPermission permission={POLICY.DELETE_WHEXPERIENCE}>
            <p style={{ marginLeft: '10px' }}>|</p>
          </WithPermission>
        </WithPermission>
        <WithPermission permission={POLICY.DELETE_WHEXPERIENCE}>
          <Switch
            checked={state === 'ACTIVE'}
            onChange={(value) =>
              updateExperience({ state: value ? 'ACTIVE' : 'INACTIVE', id })
            }
            // loading={isSubmitLoading}
          />
        </WithPermission>
      </div>
    );
  };

  const onPagingChangeLocal = ({ current, pageSize, total }, someObj) => {
    setCurrent(current);
  };

  const onSearch = () => {
    let searchParams = `?page=${query.page}&limit=${query.limit}`;
    if (keyword) searchParams += `&keyword=${keyword}`;

    history.push({
      pathname: '/experience',
      search: searchParams
    });
  };
  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '70px',
      render: (text, record, index) => {
        return (+pagination.page - 1) * pagination.limit + index + 1;
      }
    },

    {
      title: 'Nhóm đối tác',
      key: 'employeeGroup',
      width: '250px',
      render: (record) => {
        return record.employeeGroup.map((Item) => Item?.name?.vi);
      }
    },
    {
      title: 'Kinh nghiệm',
      key: 'value',
      width: '250px',
      render: (record) => {
        return record.value;
      }
    },

    {
      title: 'Hệ số',
      key: 'rate',
      width: '170px',
      render: (record) => {
        return record.rate;
      }
    },
    ...(canDelete || canUpdate
      ? [
          {
            title: 'Thao tác',
            key: 'action',
            width: '110px',
            render: (record) => {
              record = {
                ...record,
                state: record.state,
                id: record._id
              };
              return (
                <ColumnActions
                  {...record}
                  updateExperience={updateExperience}
                />
              );
            }
          }
        ]
      : [])
  ];

  return (
    <div className="page-wraper page-content experience">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách kinh nghiệm" />
        <div className="page-content__main" style={{ display: 'block' }}>
          <div className="page-wraper__header">
            <Search
              style={{ width: 520 }}
              placeholder="Tìm số năm kinh nghiệm"
              enterButton
              allowClear
              onSearch={onSearch}
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
          </div>
          <div>
            <WithPermission permission={POLICY.WRITE_WHEXPERIENCE}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => {
                  setIsOpen(true);
                  setExperienceId(null);
                }}
              >
                {' '}
                Thêm mới
              </Button>
            </WithPermission>
          </div>

          {isLoading ? (
            <SkeletonTable rowCount={experiences.length} columns={columns} />
          ) : (
            <Table
              className="wh-experience-table-striped-rows"
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={experiences}
              onChange={onPagingChangeLocal} // as API /rate-config does not have pagination, we paginate locally
              size="middle"
              pagination={{
                // ...paging,
                // total: experiences.length,
                current,
                showTotal: (total) => `Tổng cộng: ${total} `,
                onChange: (page) => {
                  setPagination({ ...pagination, page: page });
                }
              }}
            />
          )}
        </div>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <ExperienceForm
          specialityOptions={specialityOptions}
          experienceId={experienceId}
          onClose={() => setIsOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default Experience;
