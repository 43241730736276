import { Input, Tabs } from 'antd';
import Breadcrumb from '~/components/Common/Breadcrumb';
import ExpandTabs from './Detail/ExpandTabs';
import { useState } from 'react';
import { FilterDatePicker } from '../Common';

const { TabPane } = Tabs;
const { Search } = Input;

const WhInfoCustomer = () => {
  const [keyword, setKeyword] = useState('');
  return (
    <div className="page-wrapper page-content whCustomerManagements-page">
      <div className="container-fluid">
        <Breadcrumb title="Thông tin khách hàng" />
        {/* <div className="page-content__main"> */}
          {/* <div className="page-content__left">
            <div className="page-wrapper__header">
              <Search
                style={{ width: 200 }}
                placeholder="Tên, Loại khách hàng"
                enterButton
                allowClear
                // onSearch={() => onParamChange({ keyword })}
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
            </div>
            <FilterDatePicker
              label="Từ"
              onChange ={(value)=> console.log(value)}
              // value={query.createdAt}
              // onChange={(value) => onParamChange({ createdAt: value })}
            />
            <FilterDatePicker
              label="Đến"
              onChange ={(value)=> console.log(value)}
              // value={query.updatedAt}
              // onChange={(value) => onParamChange({ updatedAt: value })}
            />
          </div> */}
          <ExpandTabs />
        {/* </div> */}
      </div>
    </div>
  );
};
export default WhInfoCustomer;
