import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getAdvs({ payload: query }) {
    try {
      const response = yield call(Api.adv.getAll, query);
      yield put({ type: Types.GET_ADVS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_ADVS_FAILED, payload: error });
    }
  }
function* getAdv({ payload: id }) {
    try {
      const response = yield call(Api.adv.getById, id);
      yield put({ type: Types.GET_ADV_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_ADV_FAILED, payload: error });
    }
  }
  function* updateAdv(action) {
    try {
      const data = yield call(Api.adv.update, action.payload);
      yield put({ type: Types.UPDATE_ADV_SUCCESS, payload: data });
      yield put({ type: Types.RESET_ADV_ACTION});
    } catch (error) {
      yield put({ type: Types.UPDATE_ADV_FAILED, payload: error });
    }
  }
  function* createAdv(action) {
    try {
      const data = yield call(Api.adv.create, action.payload);
      yield put({ type: Types.CREATE_ADV_SUCCESS, payload: data });
      yield put({ type: Types.RESET_ADV_ACTION});
    } catch (error) {
      yield put({ type: Types.CREATE_ADV_FAILED, payload: error });
    }
  }
  function* deleteAdv(action) {
    try {
      const data = yield call(Api.adv.delete, action.payload);
      yield put({ type: Types.DELETE_ADV_SUCCESS, payload: data });
      yield put({ type: Types.RESET_ADV_ACTION});
    } catch (error) {
      yield put({ type: Types.DELETE_ADV_FAILED, payload: error });
    }
  }
export default function* Advs() {
    yield takeLatest(Types.GET_ADVS_REQUEST, getAdvs);
    yield takeLatest(Types.GET_ADV_REQUEST, getAdv);
    yield takeLatest(Types.UPDATE_ADV_REQUEST, updateAdv);
    yield takeLatest(Types.CREATE_ADV_REQUEST, createAdv);
    yield takeLatest(Types.DELETE_ADV_REQUEST, deleteAdv);
  }
