import requester from './requester';

const ExchangePoint = {
  getById: (id) => requester.get(`/exchange-point/${id}`),
  create: (point) => requester.post(`/exchange-point`, point),
  update:(point) => requester.put(`/exchange-point/${point._id}`,point),
  getAll : (query) => requester.get(`/exchange-point`,query),
  delete: (id) => requester.delete(`/exchange-point/${id}`),
  
};

export default ExchangePoint;
