import { Button, Col, Form, Input, Row } from 'antd';
import { useState } from 'react';
import toastr from 'toastr';
import api from '~/api';

export default function ModalConfirmCompleted({
  onCloseConfirmCompeleted,
  setNoteCompleted,
  noteCompleted,
  noteCompletedShow,
  setNoteCompletedShow,
  handleCustomerCompletedWhBillItem,
  whAppointments,
  setWhAppointments,
  setWhBillItemData,
  whBillItemData,

  // isLoading,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
    const handleConfirm = async  () => {
    const body = {
      whBillId: props.whBillId,
      noteCompleted: noteCompleted,

    };
    try {
      setIsLoading(true);
      const res = await api.whBillItem.updateCompletedWhBillItem(body);
      toastr.success(res?.message ?? 'Đã hoàn thành đơn hàng thành công');
      onCloseConfirmCompeleted();
      setWhBillItemData({...whBillItemData,status:'COMPLETED'});
      const filterData = whAppointments?.map((item) => {
        if (item?.status === 'IN_PROGRESS'||item?.status === 'NEW'||item?.status === 'CONFIRMED') {
          return {
            ...item,
            status: 'COMPLETED'
          }
        } else {
          return item
        };
      });
      setWhAppointments(filterData);
      setNoteCompletedShow(noteCompleted);
    } catch (error) {
      setNoteCompleted('');
      onCloseConfirmCompeleted();
      toastr.error(error?.response?.data?.message|| 'Hoàn thành dịch vụ thất bại');
    };
    setIsLoading(false);
  };

  const validation = () => {
    if (!noteCompleted) {
      toastr.error('Vui lòng nhập lý do hoàn thành');
    } else {
      handleConfirm();
    };
    return true;
  };

  return (
    <>
      <Form.Item label={'Lý do hoàn thành'}>
        <Input.TextArea value={noteCompleted} rows={3} onChange={(e) => setNoteCompleted(e.target.value)}/>
      </Form.Item> 
      <Row align="center">
        <Col span={4}>
          <Button loading = {isLoading} type='danger' onClick={() => {
            onCloseConfirmCompeleted(()=> setNoteCompleted(''));
          }}>Huỷ</Button>
        </Col>
        <Col span={4}>
          <Button loading = {isLoading} type='primary' onClick={validation}>Xác nhận hoàn thành</Button>
        </Col>
      </Row>
    </>
  );
}
