import * as Types from '../../constants/actionTypes';

export const getWhSessionsOfDay = query => ({
  type: Types.GET_WH_SESSIONS_OF_DAY_REQUEST,
  payload: query
});

export const getWhSessionOfDay = id => {
  return ({
  type: Types.GET_WH_SESSION_OF_DAY_REQUEST,
  payload: id
});}

export const createWhSessionOfDay = whSessionOfDay => ({
  type: Types.CREATE_WH_SESSION_OF_DAY_REQUEST,
  payload: whSessionOfDay
});

export const updateWhSessionOfDay = (whSessionOfDay) => ({
  type: Types.UPDATE_WH_SESSION_OF_DAY_REQUEST,
  payload: whSessionOfDay
});

export const deleteWhSessionOfDay = id => ({
  type: Types.DELETE_WH_SESSION_OF_DAY_REQUEST,
  payload: id
});

export const resetWhSessionOfDayState = () => ({
  type: Types.RESET_WH_SESSION_OF_DAY_STATE
});
