import { Col, Row, Select, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import Text from 'antd/lib/typography/Text';
import { get, head } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { useUserWorkspace } from '~/hooks';
import { useProductListPartnerBorrowed, useProductListPartnerBorrowedPaging, useProductListPartnerBorrowedQueryParams, useUpdateProductListPartnerBorrowedParams } from '~/hooks/productList';
const listOptionSearch = [
  {
    value: 'groupProductName',
    label: 'Nhóm sản phẩm'
  },
  {
    value: 'code',
    label: 'Mã sản phẩm'
  },
  {
    value: 'name',
    label: 'Tên sản phẩm'
  },
  {
    value: 'billNumber',
    label: 'Mã đơn hàng'
  },
  {
    value: 'codeWhAppointment',
    label: 'Mã cuộc hẹn'
  },
];
export default function TableEquipmentBorrow({active}) { // active to fetch to controller tab 
    const history = useHistory()
    const [profile] = useUserWorkspace()
    const [selectSearch, setSelectSearch] = useState(
      head(listOptionSearch).value
    );
    const [query] = useProductListPartnerBorrowedQueryParams(get(profile,'_id'));
    const [keyword, { setKeyword, onParamChange }] =
    useUpdateProductListPartnerBorrowedParams(query,listOptionSearch);
    const queryFetch = useMemo(() => active ? ({ ...query}) : null, [query,active]);
    const [dataSource, isLoading] = useProductListPartnerBorrowed(queryFetch);
    const paging = useProductListPartnerBorrowedPaging();
    const handleChangeSelectSearch = (value) => {
      setSelectSearch(value);
      setKeyword('');
    };
    ////////////////////////////////
    useEffect(() => {
      let value = listOptionSearch?.find(e => query[e.value])
      setSelectSearch(get(value, 'value', head(listOptionSearch).value))
      setKeyword(query[get(value, 'value', '')])
    }, [])
    const handleClickAppointment = (record) => {
      history.push({
        pathname :'/workspace/manageTasks',
        state : { // pass state to open colapse
          whBillItemId : get(record,'whAppointment.whBillItemId','') 
        }
      })
    }
    const columns = [
        {
          title: 'STT',
          dataIndex: 'index',
          key: 'index',
          render: (item, record, index) => index + 1
        },
        {
            title: 'Nhóm sản phẩm',
            dataIndex: 'groupProduct',
            key: 'groupProduct',
            render: (item, record, index) =>get(item,'name.vi')
          },
        {
            title: 'Mã sản phẩm',
            dataIndex: 'code',
            key: 'code',
            align : 'center',
            render: (item, record, index) => <Text strong>{item}</Text>
          },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            key: 'name',
          },
        // {
        //     title: 'Thông tin đơn hàng',
        //     dataIndex: 'whBill',
        //     key: 'whBill',
        //     render: (item, record, index) => <Row>
        //       <Col span={24}>Đơn hàng {get(item,'billNumber','')}</Col>
              
        //       <Col span={24}>Cuộc hẹn <Typography.Link
        //         onClick={() => handleClickAppointment(record)}
        //       >{get(record,'whAppointment.code','')}</Typography.Link>
        //       </Col>
        //     </Row>
        //   },
      ];
  return (
    <>
      <div
        className="page-wraper__header"
        style={{ justifyContent: 'space-between', display: 'flex' }}
      >
        <div>
          <Select
            value={selectSearch}
            style={{
              width: 170
            }}
            onChange={handleChangeSelectSearch}
            options={listOptionSearch}
          />

          <Search
            style={{ width: 300 }}
            placeholder={`Tìm ${get(listOptionSearch.find((e) => e.value === selectSearch), 'label', '')
              }`}
            enterButton
            allowClear
            onSearch={(value) => {
              onParamChange({ [selectSearch]: value?.trim() });
            }}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

        </div>
      </div>
          {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                scroll={{x : '100%'}}
                dataSource={dataSource}
                size="middle"
                onChange={({current}) => onParamChange({page : current})}
                pagination={{
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
    </>
  )
}
