import requester from './requester';

const geo = {
  getAreas: () => requester.get('/area'),
  getCities: () => requester.get('/city'),
  getDistricts: cityCode => requester.get(`/city/${cityCode}/district`),
  getWards: districtCode => requester.get(`/district/${districtCode}/ward`)
};

export default geo;
