import React from 'react';

import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { useUserWorkspace } from '~/hooks';
import { useSpecialities } from '~/hooks/speciality';

import Children from '../WhPartner/Children';
import { Breadcrumb } from '../Common';
import { PATH_APP } from '~/routes/paths';

const WorkspaceManageTasks = () => {
  const [profile] = useUserWorkspace();
  const id = get(profile, "_id");

  const [specialities, isSpecialitiesLoading] = useSpecialities();

  return (
    <div className='page-wraper page-content page-content-workSpace'>
      <Breadcrumb title="Nhân sự của tôi" />
      <Link to={PATH_APP.whPartnerWorkspace.inviteWhPartner}>
        <Button type="primary" style={{ float: 'right', marginLeft: '8px' }}>
          {' '}
          Thêm mới
        </Button>
      </Link>
      <Children
        paginationPosition={["bottomRight"]}
        parent={{ id }}
        preloadChildren={false}
        specialities={specialities}
        size="small"
        showRootChildrenOnly={true}
        selectColumns={[
          "partnerCode",
          "name",
          "phone",
          "specialities",
        ]}
      />
    </div>
  )
}

export default WorkspaceManageTasks;
