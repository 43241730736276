import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import toastr from 'toastr';

import moment from 'moment';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Space,
  Switch,
  Input,
  Select,
  InputNumber,
  Modal,
  Popconfirm
} from 'antd';
import './OptionForm.scss';
import CalendarPreColorsDashboard from './CalendarPrefixColors';
import LoadingWhBill from './LoadingWhBill';
import {
  PLACE_DID_SERVICE,
  VAT,
  WH_PAYMENT_METHOD,
  TYPE_CATEGORY,
  MAX_PAGINATION_LIMIT,
  MIN_BILL_TO_HAVE_PREPAYMENT
} from '~/constants/defaultValue';
import api from '~/api';
import { compact, forIn, get } from 'lodash';
import { useCities, useUser } from '~/hooks';
import { filterAcrossAccents } from '~/hooks/utils';
import SelectService from './quotation/SelectService';
import { getReward } from './quotation/service';
import { CloseCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { useValidateCustomer } from '~/hooks/quotations';
import CardCustomer from './CardCustomer';
import SelectPartner from './SelectPartner';
import { shouldUpdateAntDiff } from '~/utils/helper';

const FormItem = Form.Item;
let optionsHospital = []; // only use CNBS
forIn(PLACE_DID_SERVICE, (value) =>
  optionsHospital.push({ value: value.value, label: value.vi })
);
const FormFillInfo = ({
  whCategories,
  isLoading,
  createQuotation,
  isSubmitLoadingSelector,
  totalBill,
  setTotalBill,
  form,
  cities,
  isLoadingCities : isCitiesLoading,
  servicePlaces
}) => {
  const optionsPlaceDidService = useMemo(() => {
    const options = servicePlaces?.map(e => ({
      label : get(e,`name.vi`,''),
      value : get(e,'_id')
    }))
    return options
  },[servicePlaces])
  const [listCategory, setListCategory] = useState(whCategories);
  const [statusNext, setStatusFetchNext] = useState(false);
  const [bonusWhAppointments, setBonusWhAppointments] = useState();
  const [openBonusWhAppointments,setOpenBonusWhAppointments] = useState(false);
  const [dateS, SetDate] = useState({ startDate: '', endDate: '' });
  // Bonus WhAppointment
  const initialValues = {
    extraData: {
      advanced: false,
      quantity: 1
    },
    paymentMethod: WH_PAYMENT_METHOD.COD,
    prePayAmount: 0
  };
  const [AllSelect, setAllSelect] = useState({
    categorySelect: {
      value: null,
      order: 1,
      listOption: listCategory
    },
    seriveSelect: {
      value: null,
      order: 2,
      listOption: []
    },
    packageLevelSelect: {
      value: null,
      order: 3,
      listOption: []
    },
    whSessionOfDaySelect: {
      value: null,
      order: 4,
      listOption: []
    }
  });
  //CSNB only use
  const [selectCity, setSelectCity] = useState(null);
  const [allHospital, setAllHospital] = useState([]);
  const [hospital, setHospital] = useState(null);
  const [isAtHospital, setIsAtHospital] = useState(false);
  const [loadingGetHospitals, setLoadingGetHospitals] = useState(false);
  const [optionHospital, setOptionHospital] = useState([]);
  const [isCSNB,setIsCSNB] = useState(false);
  const [branchId] = useUser();
  const handleChangeCity = (cityCode) => {
    setSelectCity(cityCode)
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        setLoadingGetHospitals(true);
        const res = await api.hospitalList.getByAddress({addressHospital : selectCity ,healthcareFacilityId : form.getFieldValue(['placeDidService']) })
        if (res) {
          const options = res?.map(e => ({
            value : get(e,'_id'),
            label : `${get(e,'nameHealthCare.vi','')} - ${get(e,'street','')}`
          }))
          setOptionHospital(options);
          setAllHospital(res);
        }
        setLoadingGetHospitals(false);
      } catch (error) {
        console.log(error, 'error');
        setLoadingGetHospitals(false);
      }
    };
    selectCity && fetch();
  }, [selectCity]);
  const handleChangePlaceDidService = (placeDidService) => { // only use CSNB
    const findOnePlace = servicePlaces?.find(e => get(e,'_id') === placeDidService)
    if(get(findOnePlace,'slug','') === PLACE_DID_SERVICE.home.value){
      setIsAtHospital(false)
      setSelectCity(null)
      setHospital(null)
    }
    else{
      setIsAtHospital(true)
    }
  }
  //

  // Check Phone Customer
  const {customer,onFindCustomerDebounce,onCancelModalCustomer,openModalCustomer,onOpenModalCustomer,loading:loadingFindCustomer} = useValidateCustomer(form);
  //
  const onChangeDateEnd = (date, dateString) => {
    SetDate({ ...dateS, endDate: { dateString, date } });
  };
  const onChangeDateStart = (date, dateString) => {
    SetDate({ startDate: { dateString, date } });
    form.setFieldsValue({ extraData: { endDate: null } });
  };
  const [valuePaymentMethod, setValuePaymentMethod] = useState(1);
  const onChangePaymentMethod = (e) => {
    setValuePaymentMethod(e.target.value);
  };
  const handleChange = ({ item, value, listNext }) => {
    form.setFieldsValue({ prePayAmount: 0 });
    const itemSelect = AllSelect[item];
    let nextitem = '';
    let AllSelectTemp = { ...AllSelect };
    for (const key in AllSelect) {
      if (AllSelect[key].order === itemSelect.order + 1) nextitem = key;
      if (AllSelect[key].order > itemSelect.order) {
        AllSelectTemp[key].value = null;
        form.setFieldsValue({ [key]: null });
      }
    }
    if (nextitem !== '') {
      let next = AllSelectTemp[nextitem];
      setAllSelect({
        ...AllSelectTemp,
        [item]: { ...itemSelect, value },
        [nextitem]: { ...next, listOption: listNext }
      });
    } else {
      setAllSelect({ ...AllSelectTemp, [item]: { ...itemSelect, value } });
    }
  };
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    // Validate
    try{
    if (totalBill > MIN_BILL_TO_HAVE_PREPAYMENT && form.getFieldValue(['prePayAmount']) <= 10000) {
      setStatusFetchNext(true);
      toastr.error(
        'Nếu đơn hàng của bạn vượt quá 1 triệu, bạn vui lòng tạm ứng cho WorldHealthVN , số tiền tạm ứng phải > 10,000Đ'
      );
    } else if (form.getFieldValue(['prePayAmount']) > totalBill) {
      setStatusFetchNext(true);
      toastr.error('Số tiền tạm ứng không được lớn hơn tổng số tiền');
    } else {
      setStatusFetchNext(false);
      const {
        whPackageLevel,
        whPackageLevelId,
        _id: whPackageId
      } = JSON.parse(values.packageLevelSelect);
      const { _id: idService, code } = JSON.parse(values.seriveSelect);
      const { _id } = JSON.parse(AllSelect.whSessionOfDaySelect.value);
      const dataCSNB = { // only use when category is CSNB
        placeDidService : get(values,'placeDidService'),
        hospitalId:get(values,'hospitalId')
      }

      const billItems = {
        ...JSON.parse(values.whSessionOfDaySelect),
        ...isCSNB && dataCSNB,
        whPackageLevel,
        whPackageLevelId,
        whPackageId,
        advanced: values.extraData.advanced,
        whServiceId: idService,
        quantity: values.extraData.quantity,
        startDate: dateS.startDate.date,
        endDate: dateS.endDate.date,
        whSessionPriceItemId: _id,
        code,
        whCategoryId: values.category
      };
      const submitObject = {
        billItems: [billItems],
        bonusWhAppointments: compact([bonusWhAppointments])?.length ? compact([bonusWhAppointments]) : null,
        fullName: values.name,
        phoneNumber: values.phone,
        note: values.note,
        prePay: form.getFieldValue(['prePayAmount']) > 0 ? true : false,
        prePayAmount: form.getFieldValue(['prePayAmount']),
        paymentMethod: values.paymentMethod,
        vat: VAT,
        ... values?.referral && {referral: values?.referral},
      };
      createQuotation(submitObject);
      setBonusWhAppointments(undefined);
    }
  } catch (error) {
    console.log(error,'error');
    toastr.error(error?.response?.data?.message  ?? "Tạo đơn hàng thất bại")

  }
  };
  const formRowGutter = 48;
  const formColSpan = 24;
  const widthInput = '100%';
  // Change category Select
  const handleChangeCategory = async (value) => {
    // only use CSNB
    const findOneCategorySelect = listCategory.find(e => get(e,'_id','') === value )
    if(findOneCategorySelect && get(findOneCategorySelect,'typeSpecial','') ===  TYPE_CATEGORY.CSNB.value){
      setIsCSNB(true)
    }
    else{
      setHospital(null)
      setSelectCity(null)
      setIsCSNB(false)
    }
    //
  try {
    setLoading(true);
    const res = await api.whService.getWhServiceAvailableByBranch({
      status: 'ACTIVE',
      branchId : parseInt(branchId),
      whCategoryId: value
    });
    const listService = res?.map((e) => ({
      value: JSON.stringify(e),
      label: e.name.vi
    }));
    setLoading(false);
    handleChange({ item: 'categorySelect', value, listNext: listService });
  } catch (error) {
    toastr.error('Lấy dữ liệu dịch vụ bị lỗi')
    setLoading(false);
  }
  };
  
    // Change Service Select

  const handleChangeService = async (value) => {
    try {
      setLoading(true);
    const res = await api.whService.getById(JSON.parse(value)._id);
    const listNext = res.packages?.map((item) => ({
      value: JSON.stringify(item),
      label: get(item, 'whPackageLevel.name.vi', '')
    }));
    handleChange({ item: 'seriveSelect', value, listNext });
    setLoading(false);
    } catch (error) {
      toastr.error('Lấy dữ liệu dịch vụ bị lỗi')
      setLoading(false);
    }
  };

    // Change Package Select
  const handleChangePackageLevel = (value) => {
    const sessionDay = AllSelect.packageLevelSelect.listOption.find(
      (e) => JSON.stringify(e.value) === JSON.stringify(value)
    );
    const listNext = JSON.parse(sessionDay.value).sessionPrices.map((item) => ({
      value: JSON.stringify(item),
      label: get(item, 'whSessionOfDay.name', '')
    }));
    handleChange({ item: 'packageLevelSelect', value, listNext });
  };

    // Change Days Select
  const handleChangeDays = (value) => {
    const { minimumQuantity } = JSON.parse(value) || '';
    handleChange({ item: 'whSessionOfDaySelect', value });
    form.setFieldsValue({ minimumQuantity });
  };

  const increaseQuantity = (value) => {
    form.setFieldsValue({
      extraData: {
        quantity: form.getFieldValue(['extraData', 'quantity']) + value
      }
    });
  };
  const switchHandler = (value) => {
    form.setFieldsValue({ extraData: { advanced: value } });
  };
  const handleSetTotalBill = () => {
    const quantity = form.getFieldValue(['extraData', 'quantity']);
    const minimumQuantity = form.getFieldValue(['minimumQuantity']);
    const bonus = get(JSON.parse(AllSelect.seriveSelect?.value),'bonus',0);
    setTotalBill(
      AllSelect.whSessionOfDaySelect.value
        ? quantity *
            (JSON.parse(AllSelect.whSessionOfDaySelect?.value).price *
            minimumQuantity)
              + (bonus * quantity * minimumQuantity)
        : 0
    );
  }
  const changeTotalBill = () => {
    handleSetTotalBill();
  };
  useEffect(() => {
    handleSetTotalBill();
  }, [AllSelect]);
  const onOpenBonusWhAppointments = () => { 
    setOpenBonusWhAppointments(true);
  }
  const onCloseBonusWhAppointments = () => { 
    setOpenBonusWhAppointments(false);
  }
  return (
    <>
      <LoadingWhBill open={loading || isLoading} />
      <section className="mark-1-page__options mark-1-page-options">
        <div className="mark-1-page__options--content">
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Row
              align="top"
              className="home-service-form__row"
              gutter={formRowGutter}
              justify="center"
            >
              <Col
                className="home-service-form__row--left-col"
                xs={24}
                sm={24}
                md={24}
                lg={24}
              >
                <Form
                  colon={false}
                  className="home-service-form"
                  autoComplete="off"
                  form={form}
                  initialValues={initialValues}
                  labelCol={{ sm: 8, md: 8, lg: 8, xl: 8 }}
                  onFinish={onFinish}
                  requiredMark={false}
                  scrollToFirstError
                  wrapperCol={{ sm: 16, md: 16, lg: 16, xl: 16 }}
                >
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={formRowGutter}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Tên"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: 'Xin vui lòng nhập tên'
                          }
                        ]}
                      >
                        <Input style={{ width: widthInput }} />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={formRowGutter}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Số điện thoại"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(/^[0-9]{10,10}$/),
                            message: 'Xin vui lòng nhập đúng số điện thoại!'
                          }
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            onFindCustomerDebounce(e.target.value)
                          }
                          style={{ width: widthInput }}
                          className="remove-padding-addonAfter"
                          addonAfter={
                            <Button onClick={onOpenModalCustomer} className="d-flex align-items-center">
                              <SearchOutlined />
                            </Button>
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={formRowGutter}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Chọn nhóm dịch vụ"
                        name="category"
                        rules={[
                          {
                            required: true,
                            message: 'Xin vui lòng chọn nhóm dịch vụ'
                          }
                        ]}
                      >
                        <Select
                          style={{ width: widthInput }}
                          onChange={handleChangeCategory}
                          options={listCategory.map((e) => ({
                            value: e.id,
                            label: e.name.vi
                          }))}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  {isCSNB && <>
                    <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={formRowGutter}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Chọn nơi thực hiện dịch vụ"
                        name="placeDidService"
                        rules={[
                          {
                            required: true,
                            message: 'Xin vui lòng chọn nơi thực hiện dịch vụ'
                          }
                        ]}
                      >
                      <Select  onChange={handleChangePlaceDidService} options={optionsPlaceDidService}/>
                      </FormItem>
                    </Col>
                  </Row>
                 { isAtHospital && <>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={formRowGutter}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Tỉnh,thành phố"
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: 'Xin vui lòng chọn tỉnh,thành phố'
                          }
                        ]}
                      >
                        <Select
                          value={selectCity}
                          style={{ width: '100%' }}
                          onChange={handleChangeCity}
                          disabled={isCitiesLoading}
                          loading={isCitiesLoading}
                          showSearch
                          autoComplete="disabled"
                          filterOption={filterAcrossAccents}
                        >
                          {cities.map(({ code, name }) => (
                            <Select.Option key={code} value={code}>
                              {name}
                            </Select.Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={formRowGutter}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Bệnh viện"
                        name="hospitalId"
                        rules={[
                          {
                            required: true,
                            message: 'Xin vui lòng chọn Bệnh viện'
                          }
                        ]}
                      >
                        <Select
                          loading={loadingGetHospitals}
                          style={{ width: '100%' }}
                          options={optionHospital}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                 </>}
                
                  </>}
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={formRowGutter}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Chọn dịch vụ"
                        name="seriveSelect"
                        rules={[
                          {
                            required: true,
                            message: 'Xin vui lòng chọn dịch vụ'
                          }
                        ]}
                      >
                        <Select
                          value={AllSelect.seriveSelect.value}
                          showSearch
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: widthInput }}
                          onChange={handleChangeService}
                          options={AllSelect.seriveSelect.listOption}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={formRowGutter}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Gói dịch vụ"
                        name="packageLevelSelect"
                        rules={[
                          {
                            required: true,
                            message: 'Xin vui lòng chọn gói dịch vụ'
                          }
                        ]}
                      >
                        <Select
                          value={AllSelect.packageLevelSelect.value}
                          style={{ width: widthInput }}
                          onChange={handleChangePackageLevel}
                          options={AllSelect.packageLevelSelect.listOption}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={formRowGutter}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Thời gian thực hiện"
                        name="whSessionOfDaySelect"
                        rules={[
                          {
                            required: true,
                            message: 'Xin vui lòng chọn thời gian thực hiện'
                          }
                        ]}
                      >
                        <Select
                          value={AllSelect.whSessionOfDaySelect.value}
                          style={{ width: widthInput }}
                          onChange={handleChangeDays}
                          options={AllSelect.whSessionOfDaySelect.listOption}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={formRowGutter}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Số lần thực hiện"
                        name="minimumQuantity"
                      >
                        <InputNumber disabled={true} />
                      </FormItem>
                    </Col>
                  </Row>

                  <Row
                    align="middle"
                    className="home-service-form__row sm-1-line"
                    gutter={formRowGutter}
                    justify="space-between"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Số lượng"
                        // name="count"
                        shouldUpdate
                        labelCol={{ sm: 8 }}
                        wrapperCol={{ sm: 24 }}
                      >
                        {() => (
                          <>
                            <Button
                              // className="home-service-form__form-item--decrease-button"
                              onClick={() => {
                                increaseQuantity(-1);
                                form.setFieldsValue({ prePayAmount: 0 });
                                changeTotalBill();
                              }}
                              disabled={
                                form.getFieldValue(['extraData', 'quantity']) <=
                                1
                              }
                            >
                              -
                            </Button>
                            <FormItem
                              name={['extraData', 'quantity']}
                              noStyle
                              labelCol={{ sm: 8 }}
                              wrapperCol={{ sm: 24 }}
                            >
                              <p className="home-service-form__form-item--input-number">
                                {form.getFieldValue(['extraData', 'quantity'])}
                              </p>
                            </FormItem>
                            <Button
                              type="primary"
                              // className="home-service-form__form-item--increase-button"
                              onClick={() => {
                                increaseQuantity(1);
                                form.setFieldsValue({ prePayAmount: 0 });
                                changeTotalBill();
                              }}
                            >
                              +
                            </Button>
                          </>
                        )}
                      </FormItem>
                    </Col>
                  </Row>

                  <Row
                    align="middle"
                    className="home-service-form__row sm-1-line"
                    gutter={formRowGutter}
                    justify="space-between"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Ngày bắt đầu"
                        labelCol={{ sm: 8, md: 8 }}
                        wrapperCol={{ sm: 16, md: 16 }}
                      >
                        <CalendarPreColorsDashboard className="home-service-form__form-item--prefix" />
                        <FormItem
                          name={['extraData', 'startDate']}
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: 'Xin vui lòng chọn ngày bắt đầu'
                            }
                          ]}
                        >
                          <DatePicker
                            allowClear={false}
                            onChange={onChangeDateStart}
                            className="home-service-form__form-item--date-picker custom-input-remove"
                            disabledDate={(current) =>
                              current < moment().endOf('day')
                            }
                            format="DD/MM/YYYY"
                            placeholder="Chọn ngày"
                          />
                        </FormItem>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row sm-1-line"
                    gutter={formRowGutter}
                    justify="space-between"
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Ngày kết thúc"
                      >
                        <CalendarPreColorsDashboard className="home-service-form__form-item--prefix" />
                        <FormItem
                          noStyle
                          name={['extraData', 'endDate']}
                          rules={[
                            {
                              required: true,
                              message: 'Xin vui lòng chọn ngày kết thúc'
                            }
                          ]}
                        >
                          <DatePicker
                            allowClear={false}
                            onChange={onChangeDateEnd}
                            className="home-service-form__form-item--date-picker custom-input-remove"
                            disabledDate={(current) =>
                              current <= dateS.startDate.date
                            }
                            format="DD/MM/YYYY"
                            placeholder="Chọn ngày"
                          />
                        </FormItem>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={0}
                    justify="start"
                  >
                    <h4 className="instruction">
                      Bạn có muốn gia tăng thời gian sử dụng dịch vụ?
                    </h4>
                    <FormItem
                      clasName="home-service-form__form-item--switch"
                      name={['extraData', 'advanced']}
                      noStyle
                      valuePropName="checked"
                    >
                      <Switch onChange={switchHandler} />
                    </FormItem>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={0}
                    style={{ margin: '10px 0' }}
                  >
                    <Col span={8} align='start'>
                      <span>
                        Bonus
                      </span>
                    </Col>
                    <Col>
                      <Button onClick={onOpenBonusWhAppointments}>
                        Tặng buổi hẹn
                      </Button>
                    </Col>

                  </Row>
                  {bonusWhAppointments && <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={0}
                    style={{ margin: '10px 0' }}
                  >
                    <Col span={8} align='start'>
                      <span>
                       Buổi tặng kèm
                      </span>
                    </Col>
                    <Col span={16} align='start'>
                    <span>
                    {getReward(bonusWhAppointments)}
                    <Popconfirm
                  zIndex={9999}
                  title="Xác nhận huỷ tặng kèm"
                  onConfirm={() => setBonusWhAppointments(null)}
                  okText="Xác nhận"
                    >
                    <Button icon={<CloseCircleTwoTone twoToneColor='red' />} type='text'/>
                    </Popconfirm>
                    
                    </span>
                    </Col>

                  </Row>}
                  
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={`formRowGutter`}
                    justify="start"
                    style={{ marginTop: '20px' }}
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Thông tin khác"
                        name="note"
                      >
                        <Input.TextArea rows={4} />
                      </FormItem>
                    </Col>
                  </Row>
                  {totalBill >= 1000000 && (
                    <Row
                      align="middle"
                      className="home-service-form__row"
                      justify="start"
                      style={{ marginTop: '20px' }}
                    >
                      <Col span={formColSpan}>
                        <FormItem
                          className="home-service-form__form-item"
                          label="Số tiền tạm ứng"
                          name="prePayAmount"
                          validateStatus={!statusNext ? 'success' : 'error'}
                          // help={!statusNext ? '':"Nếu đơn hàng của bạn vượt quá 1 triệu, bạn vui lòng tạm ứng cho WorldHealthVN , số tiền tạm ứng phải > 10,000Đ"}
                          rules={[
                            {
                              required: true,
                              message: 'Xin vui lòng nhập số tiền trả trước'
                            }
                          ]}
                        >
                          <InputNumber
                            style={{ width: '100px' }}
                            min={1}
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  )}
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={`formRowGutter`}
                    justify="start"
                    style={{ marginTop: '20px' }}
                  >
                    <Col span={formColSpan}>
                      <FormItem
                        className="home-service-form__form-item"
                        label="Phương thức thanh toán"
                        name="paymentMethod"
                      >
                        <Radio.Group
                          onChange={onChangePaymentMethod}
                          value={valuePaymentMethod}
                        >
                          <Radio value={WH_PAYMENT_METHOD.COD}>Tiền mặt</Radio>
                          <Radio value={WH_PAYMENT_METHOD.TRANSFER}>
                            Chuyển khoản
                          </Radio>
                        </Radio.Group>
                      </FormItem>
                    </Col>
                  </Row>

                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={`formRowGutter`}
                    justify="start"
                    style={{ marginTop: '20px' }}
                  >
                    <Col span={formColSpan}>
                      <FormItem shouldUpdate={(pre,next) => shouldUpdateAntDiff(pre,next,'referral')} noStyle>
                      {({getFieldValue,setFieldsValue}) => !customer && <FormItem
                        className="home-service-form__form-item"
                        label="Mã giới thiệu"
                        name="referral"
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]{10,10}$/),
                            message: 'Xin vui lòng nhập đúng số điện thoại!'
                          }
                        ]}
                      >
                        <Input 
                        disabled={!!customer} 
                        style={{ width: '100%' }} 
                        addonAfter={<SelectPartner 
                        value={getFieldValue('referral')} 
                        onChange={(referral) => setFieldsValue({referral})}/>}
                        onFocus={() => onFindCustomerDebounce()}
                        />
                      </FormItem>}
                      </FormItem>
                    </Col>
                  </Row>

                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={0}
                    justify="start"
                  >
                    <Col span={formColSpan}>
                      <Divider />
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={0}
                    justify="start"
                  >
                    <Col span={8} align='start'>
                      <h6
                      >
                        Số buổi
                      </h6>
                    </Col>
                    <Col span={16}>
                      <FormItem shouldUpdate noStyle>
                        {() => (
                          <h5 className="home-service-form__total--value">
                            {(form.getFieldValue(['extraData', 'quantity']) || 0) * (form.getFieldValue(['minimumQuantity']) || 0) + get(bonusWhAppointments,'minimumQuantity',0)}
                          </h5>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    className="home-service-form__row"
                    gutter={0}
                    justify="start"
                  >
                    <Col span={8} align='start'>
                      {/* <CustomLabel popoverContent="Nếu đơn hàng của bạn vượt quá 1 triệu, bạn vui lòng tạm ứng cho WorldHealthVN"> */}
                      <h6
                        style={{ fontSize: '1.5rem' }}
                        className="home-service-form__total--label"
                      >
                        Thành tiền
                      </h6>
                      {/* </CustomLabel> */}
                    </Col>
                    <Col span={16}>
                      <FormItem shouldUpdate noStyle>
                        {() => (
                          <h3 className="home-service-form__total--value">
                            {totalBill
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}{' '}
                            Đ
                          </h3>
                        )}
                      </FormItem>
                    </Col>
                  </Row>

                  <Row
                    align="middle"
                    className="home-service-form__row--actions"
                    gutter={0}
                    justify="center"
                  >
                    <Button
                      loading={isSubmitLoadingSelector}
                      type="primary"
                      htmlType="submit"
                    >
                      Đặt lịch ngay
                    </Button>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Space>
        </div>
      </section>
      <Modal
        onCancel={onCloseBonusWhAppointments}
        footer={null}
        destroyOnClose
        title="Chọn dịch vụ tặng kèm"
        visible={openBonusWhAppointments}
      >
        <SelectService
          whCategoryIdQuotation={AllSelect?.categorySelect?.value}
          onCancel={onCloseBonusWhAppointments}
          listCategory={listCategory?.filter(
            (item) => get(item, 'typeSpecial') !== 'CSNB'
          )}
          bonusWhAppointments={bonusWhAppointments}
          setBonusWhAppointments={setBonusWhAppointments}
        />
      </Modal>
      <Modal
        centered
        onCancel={onCancelModalCustomer}
        footer={null}
        title="Thông tin khách hàng"
        visible={openModalCustomer}
      >
        <CardCustomer data={customer} loading={loadingFindCustomer}/>
      </Modal>
    </>
  );
};

export default FormFillInfo;
