import { CheckCircleTwoTone } from '@ant-design/icons'
import { Col, Row } from 'antd'
import Text from 'antd/lib/typography/Text'
import { get } from 'lodash'
import { formatNumberThreeComma } from '~/hooks/utils'
import RewardItem from './RewardItem'
const RowAcountActiveBack = (count) => count > 0 ? <Text>Đã nhận</Text> : <Text>Chưa nhận</Text>
const RowIsSuccess = (success, count) => <>
    {success ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <Text>Đã đạt {formatNumberThreeComma(count)}</Text>}
    {success && <Text>Đã đạt : {formatNumberThreeComma(count)}</Text>}
</>
export default function ItemProcessRange({ process, layout }) {
    const activity = get(process, 'activity')
    const countProcess = get(process, 'countProcess', 0)
    const min = get(process, 'condition.value.keyValue.min')
    const max = get(process, 'condition.value.keyValue.max')
    const isSuccess = countProcess >= max || (countProcess >= min && countProcess <= max && countProcess !== 0)
    return (
        <Row justify='space-between' gutter={16}>
            <Col {...layout.left}>
                <Text strong>{get(process, 'name', '')}</Text>
                <RewardItem process={process} />
                <p>Mốc {formatNumberThreeComma(min)} đến {formatNumberThreeComma(max)}</p>
            </Col>
            <Col {...layout.right}>
                {['KHACH-HANG-HOAN-THANH-DON-HANG'].includes(activity)
                    ? <Text>Đã nhận {formatNumberThreeComma(countProcess)} lần</Text>
                    : ['TAI-KHOAN-HOAT-DONG-TRO-LAI'].includes(activity)
                        ? RowAcountActiveBack(countProcess)
                        : RowIsSuccess(isSuccess, countProcess)}

            </Col>
        </Row>
    )
}
