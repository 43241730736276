import * as Types from '../../constants/actionTypes';

export const getWhContacts = (query) => ({
  type: Types.GET_WH_CONTACTS_REQUEST,
  payload: query
});

export const getWhContact = (id) => ({
  type: Types.GET_WH_CONTACT_REQUEST,
  payload: id
});

export const createWhContact = (staffGroup) => ({
  type: Types.CREATE_WH_CONTACT_REQUEST,
  payload: staffGroup
});

export const updateWhContact = (staffGroup) => ({
  type: Types.UPDATE_WH_CONTACT_REQUEST,
  payload: staffGroup
});

export const deleteWhContact = (id) => ({
  type: Types.DELETE_WH_CONTACT_REQUEST,
  payload: id
});

export const resetWhContactState = () => ({
  type: Types.RESET_WH_CONTACT_STATE
});
