import * as Types from '~/constants/actionTypes';

export const getOverViewReport = () => ({
  type: Types.GET_OVER_VIEW_REPORT_REQUEST
});

export const getYearlyReport = () => ({
  type: Types.GET_YEARLY_REPORT_REQUEST
});

export const getQuarterlyReport = () => ({
  type: Types.GET_QUARTERLY_REPORT_REQUEST
});

export const getMonthlyReport = () => ({
  type: Types.GET_MONTHLY_REPORT_REQUEST
});

export const getDailyReport = () => ({
  type: Types.GET_DAILY_REPORT_REQUEST
});

export const getMostService = () => ({
  type: Types.GET_MOST_SERVICE_REQUEST
})

export const getMostRevenueService = () => ({
  type: Types.GET_MOST_REVENUE_SERVICE_REQUEST
})

export const getMostPartnerAppointment = () => ({
  type: Types.GET_MOST_PARTNER_APPOINTMENT_REQUEST
})

export const getMostCustomerAppointment = () => ({
  type: Types.GET_MOST_CUSTOMER_APPOINTMENT_REQUEST
})

export const getOverviewToday = () => ({
  type: Types.GET_OVERVIEW_TODAY_REQUEST
})

export const getOverviewDaily = () => ({
  type: Types.GET_OVERVIEW_DAILY_REQUEST
})

export const getOverviewMonthly = () => ({
  type: Types.GET_OVERVIEW_MONTHLY_REQUEST
})

export const getOverviewQuarterly = () => ({
  type: Types.GET_OVERVIEW_QUARTERLY_REQUEST
})

export const getOverviewYearly = () => ({
  type: Types.GET_OVERVIEW_YEARLY_REQUEST
})

export const resetDashboardState = () => ({
  type: Types.RESET_DASHBOARD_STATE
});
