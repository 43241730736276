import * as Types from '../../constants/actionTypes';
  
  export const getRankings = query => ({
    type: Types.GET_RANKINGS_REQUEST,
    payload: query
  });
  export const getRanking = id => ({
    type: Types.GET_RANKING_REQUEST,
    payload: id
  });
  export const createRanking = instance => ({
    type: Types.CREATE_RANKING_REQUEST,
    payload: instance
  });
  export const updateRanking = instance => ({
    type: Types.UPDATE_RANKING_REQUEST,
    payload: instance
  });
  export const deleteRanking = id => ({
    type: Types.DELETE_RANKING_REQUEST,
    payload: id
  });
  export const resetRankingState = () => ({
    type: Types.RESET_RANKING_STATE
  });
  export const resetRankingStateAction = () => ({
    type: Types.RESET_RANKING_STATE_ACTION
  });
