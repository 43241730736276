import requester from './requester';
const whContact = {
  getAll: (query) => requester.get('/contact', query),
  // getById: (id) => requester.get(`/contact/${id}`),
  // delete: (id) => requester.delete(`/wh-category/${id}`),
  // create: (whContact) => requester.post('/wh-category', whContact),
  // update: (data) => {
  //   return requester.put(`/wh-category/${data.id}`, data);
  // }
};
export default whContact;
