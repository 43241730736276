import { Tabs } from 'antd'
import { get, head } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import TabsPointSystem from '~/components/Workspace/wallet/TabsPointSystem'
import TabsWallet from '~/components/Workspace/wallet/TabsWallet'
import { useRefWhCustomerManagement, useRefWhCustomerManagementPaging, useRefWhCustomerManagementQueryParams, useWhCustomerManagement, useWhCustomersManagement, useWhCustomersManagementQueryParams } from '~/hooks'
import History from './History'
import Information from './Information'
import ListRef from './ListRef'
import { useParams } from 'react-router-dom'
import HistoryCustomer from './History/index'
import NoteCustomer from './History/NoteCustomer'
const { TabPane } = Tabs
export default function ExpandTabs({ callback, expanded, record }) {
  
  const { id } = useParams()
  const [query, onPagingChange] = useWhCustomersManagementQueryParams();
  const [customerManagement, isLoading] = useWhCustomerManagement(id);
  const dataSource = customerManagement?.data

  // const [query, onPagingChange] = useRefWhCustomerManagementQueryParams(get(record,'_id'));
  // const [refWhCustomersManagements, isLoading] = useRefWhCustomerManagement(expanded ? query :null);
  const parent = useSelector(state => state.whCustomerManagement.ref)
  // const paging = useRefWhCustomerManagementPaging()
  return (

    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Thông tin" key="1">
        <Information parent={expanded && parent} expanded={expanded} data={dataSource} id = {id}></Information> 
      </TabPane>
      <TabPane tab="Lịch sử" key="2"> 
        <HistoryCustomer
          // {...dataSource}
          style={{ margin: 0 }}
          data={dataSource}
      />
      </TabPane>
      <TabPane tab="Người được giới thiệu" key="3">
        <ListRef openFetch showTotal userId={get(dataSource, '_id')} width={'100vw'} /> 
      </TabPane>
      <TabPane tab="Lịch sử điểm thưởng" key="4">
        <TabsPointSystem
          userId={get(dataSource, '_id')}
          mode='state'
          width={'100vw'} 
          allowSearch
        />
      </TabPane>
      <TabPane tab="Lịch sử ví" key="5">
        <TabsWallet   width={'100vw'} mode='state' userId={get(dataSource, '_id')} allowSearch/> 
      </TabPane>
      <TabPane tab="Ghi chú" key="6">
        <NoteCustomer/>
      </TabPane>
    </Tabs>
    
  )
}
