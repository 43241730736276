import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Form, Row, Col, Button, Input, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useCreateWhBanner, useWhBanners } from '~/hooks/whBanner';
import { Breadcrumb, WithOrPermission, WithPermission } from '../Common';
import BannerAddImage from './BannerAddImage';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { get, omit } from 'lodash';
import { requireRules } from '~/constants/defaultValue';
import AutoGetCodeYoutube from '../AddYoutube/AutoGetCodeYoutube';
import UploadImage from '../Utils/UploadImage';
import BtnAdd from '../UiClient/BtnAdd';
import UploadVideo from '../Common/Upload/UploadVideo';
import FormItemYoutube from './FormItemYoutube';
const options = [
  {
    label: 'Video Youtube',
    value: 'video',
  },
  {
    label: 'Thước phim',
    value: 'gallery',
  },

];
const Banner = () => {
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_BANNER)
  const canDelete = useMatchPolicy(POLICIES.DELETE_BANNER)
  const [getDataBanner] = useWhBanners();
  const [isSubmitLoading, handleCreate] = useCreateWhBanner();
  const [banner, setBanner] = useState([]);
  const [form] = Form.useForm();
  //Get data from API
  useEffect(() => {
    if (getDataBanner[0]) {
      const valueData = getDataBanner[0]?.images;
      setBanner(valueData);
      form.setFieldsValue({ addImage: [...valueData], ...getDataBanner[0] });
    }
  }, [getDataBanner]);
  const indexEndDisabled = useMemo(() => {
    return !canUpdate ? get(getDataBanner, '[0].images.length', 0) : 0
  }, [getDataBanner, canUpdate])
  // Add data from children component to Form
  const setWhServiceQuestionOptionImg = (image, type, index) => {
    const tempBanner = [{ image }];
    if (banner[index]) {
      const newBanner = banner.map((e, i) => {
        const v = {};
        v.image = i === index ? image : e?.image;
        v.type = i === index ? type : e?.type;
        return v;
      });
      setBanner(newBanner);
    } else {
      setBanner([...banner, { image, type }]);
    }
  };
  //Handle Change image and type image

  function handleChangeType(index, value) {
    const getTypeImg = banner?.map((item, indexx) => {
      if (index === indexx) {
        item.type = value;
      }
      return item;
    });
    setBanner(getTypeImg);
  }
  const deleteItemBanner = useCallback(
    (index) => setBanner((cur) => cur.filter((i, indexs) => indexs !== index)),
    [banner]
  );

  const onFinish = (values) => {
    const newBanner = { images: banner };
    handleCreate({
      ...newBanner,
      ...omit(values, ['addImage']),
    });
  };
  return (
    <div className="wh-service-form page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title={'Banner'} routes={[{ path: '', title: 'CMS' }]} />
        <Form
          form={form}
          autoComplete="off"
          initialValues={{ questions: [], typeShow: 'video' }}
          scrollToFirstError
          requiredMark={false}
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
          onFinish={onFinish}
        >
            <FormItemYoutube disabled={!canUpdate} />
          <Form.Item shouldUpdate={(pre,next) => pre?.shortVideoUrl !== next?.shortVideoUrl} noStyle>
            {({getFieldValue}) => <Form.Item name={["shortVideoUrl","mp4"]} label="Video Ngắn Mp4">
            <UploadVideo accept="video/mp4" value={getFieldValue(['shortVideoUrl','mp4'])} onChange={(url) => form.setFieldsValue({shortVideoUrl : {
              ...getFieldValue(['shortVideoUrl','mp4']),
              mp4 : url
            }})}/>
            </Form.Item>}
          </Form.Item>
          <Form.Item shouldUpdate={(pre,next) => pre?.shortVideoUrl !== next?.shortVideoUrl} noStyle>
            {({getFieldValue}) => <Form.Item name={["shortVideoUrl","webm"]} label="Video Ngắn Webm">
            <UploadVideo accept="video/webm" value={getFieldValue(['shortVideoUrl','webm'])} onChange={(url) => form.setFieldsValue({shortVideoUrl : {
              ...getFieldValue(['shortVideoUrl','mp4']),
              webm : url
            }})}/>
            </Form.Item>}
          </Form.Item>

          {/* <Form.Item name={["youtubeId"]} label="Mã Video Youtube">
            <Input disabled={!canUpdate} className='remove-padding-addonAfter' placeholder='Mã Video Youtube' addonAfter={<AutoGetCodeYoutube onChange={(value) => form.setFieldsValue({
              youtubeId: value
            })} />} />
          </Form.Item> */}

          <Form.Item shouldUpdate noStyle>
            {() => <Form.Item label="Khám phá Gallery">
              <Form.List name={['gallery']}>
                {(fields, { add, remove }) => (
                  <>
                    <Row gutter={8}>
                      {fields.map((field, index) => {
                        const image = form.getFieldValue(['gallery', index]);
                        return (
                          <Col span={6}>
                            <Form.Item name={[index]}>
                              <UploadImage disabled={(getDataBanner?.[0]?.gallery?.length > (index)) && !canUpdate} onClose={canDelete ? () => remove(index) : null} imgUrl={image} title={""} />
                            </Form.Item>
                          </Col>
                        )
                      })}
                      <Col span={5}>
                        <BtnAdd onClick={() => add()} />
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Form.Item>}
          </Form.Item>
          <Form.Item label="Hiển thị" name={'typeShow'}>
            <Radio.Group disabled={!canUpdate} options={options} />
          </Form.Item>
          <Row
            gutter={[48, 0]}
            justify="flex-start"
            className="wh-service-form__question--options-wrapper"
          >
            <Form.List name="addImage">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((e, index) => {
                    const props = {
                      item: e,
                      form,
                      index,
                      remove,
                      type: banner?.[index]?.type,
                      image: banner?.[index]?.image,
                      deleteItemBanner,
                      handleChangeType,
                      setWhServiceQuestionOptionImg,
                      disabled: index < indexEndDisabled
                    };
                    return <BannerAddImage key={index} {...props} />;
                  })}
                  {fields.length >= 8 ? null : (
                    <Col span={6}>
                      <WithOrPermission permission={[POLICIES.WRITE_BANNER]}>
                        <Button
                          className="wh-service-form__question--btn-add-option"
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Thêm lựa chọn
                        </Button>
                      </WithOrPermission>
                    </Col>
                  )}
                </>
              )}
            </Form.List>
          </Row>
          <Row className="wh-service-form__submit-box">
            <WithOrPermission permission={[POLICIES.UPDATE_BANNER, POLICIES.DELETE_BANNER, POLICIES.WRITE_BANNER]}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitLoading}
              >
                Cập nhật
              </Button>
            </WithOrPermission>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default Banner;
