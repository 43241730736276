export const GET_COMPANY_JOBS_REQUEST =
    'GET_COMPANY_JOBS_REQUEST';
export const GET_COMPANY_JOBS_SUCCESS =
    'GET_COMPANY_JOBS_SUCCESS';
export const GET_COMPANY_JOBS_FAILED = 'GET_COMPANY_JOBS_FAILED';

export const GET_COMPANY_JOB_REQUEST = 'GET_COMPANY_JOB_REQUEST';
export const GET_COMPANY_JOB_SUCCESS = 'GET_COMPANY_JOB_SUCCESS';
export const GET_COMPANY_JOB_FAILED = 'GET_COMPANY_JOB_FAILED';

export const CREATE_COMPANY_JOB_REQUEST =
    'CREATE_COMPANY_JOB_REQUEST';
export const CREATE_COMPANY_JOB_SUCCESS =
    'CREATE_COMPANY_JOB_SUCCESS';
export const CREATE_COMPANY_JOB_FAILED =
    'CREATE_COMPANY_JOB_FAILED';

export const UPDATE_COMPANY_JOB_REQUEST =
    'UPDATE_COMPANY_JOB_REQUEST';
export const UPDATE_COMPANY_JOB_SUCCESS =
    'UPDATE_COMPANY_JOB_SUCCESS';
export const UPDATE_COMPANY_JOB_FAILED =
    'UPDATE_COMPANY_JOB_FAILED';


export const DELETE_COMPANY_JOB_REQUEST =
    'DELETE_COMPANY_JOB_REQUEST';
export const DELETE_COMPANY_JOB_SUCCESS =
    'DELETE_COMPANY_JOB_SUCCESS';
export const DELETE_COMPANY_JOB_FAILED =
    'DELETE_COMPANY_JOB_FAILED';


export const RESET_COMPANY_JOB_STATE = 'RESET_COMPANY_JOB_STATE';
export const RESET_COMPANY_JOB_ACTION = 'RESET_COMPANY_JOB_ACTION';
