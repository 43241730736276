export const GET_CLINIC_OVER_VIEW_REPORT_REQUEST = 'GET_CLINIC_OVER_VIEW_REPORT_REQUEST';
export const GET_CLINIC_OVER_VIEW_REPORT_SUCCESS = 'GET_CLINIC_OVER_VIEW_REPORT_SUCCESS';
export const GET_CLINIC_OVER_VIEW_REPORT_FAILED = 'GET_CLINIC_OVER_VIEW_REPORT_FAILED';

export const GET_CLINIC_YEARLY_REPORT_REQUEST = 'GET_CLINIC_YEARLY_REPORT_REQUEST';
export const GET_CLINIC_YEARLY_REPORT_SUCCESS = 'GET_CLINIC_YEARLY_REPORT_SUCCESS';
export const GET_CLINIC_YEARLY_REPORT_FAILED = 'GET_CLINIC_YEARLY_REPORT_FAILED';

export const GET_CLINIC_QUARTERLY_REPORT_REQUEST = 'GET_CLINIC_QUARTERLY_REPORT_REQUEST';
export const GET_CLINIC_QUARTERLY_REPORT_SUCCESS = 'GET_CLINIC_QUARTERLY_REPORT_SUCCESS';
export const GET_CLINIC_QUARTERLY_REPORT_FAILED = 'GET_CLINIC_QUARTERLY_REPORT_FAILED';

export const GET_CLINIC_MONTHLY_REPORT_REQUEST = 'GET_CLINIC_MONTHLY_REPORT_REQUEST';
export const GET_CLINIC_MONTHLY_REPORT_SUCCESS = 'GET_CLINIC_MONTHLY_REPORT_SUCCESS';
export const GET_CLINIC_MONTHLY_REPORT_FAILED = 'GET_CLINIC_MONTHLY_REPORT_FAILED';

export const GET_CLINIC_DAILY_REPORT_REQUEST = 'GET_CLINIC_DAILY_REPORT_REQUEST';
export const GET_CLINIC_DAILY_REPORT_SUCCESS = 'GET_CLINIC_DAILY_REPORT_SUCCESS';
export const GET_CLINIC_DAILY_REPORT_FAILED = 'GET_CLINIC_DAILY_REPORT_FAILED';

export const GET_MOST_DAILY_REQUEST = 'GET_MOST_DAILY_REQUEST';
export const GET_MOST_DAILY_SUCCESS = 'GET_MOST_DAILY_SUCCESS';
export const GET_MOST_DAILY_FAILED = 'GET_MOST_DAILY_FAILED';

export const GET_MOST_MONTHLY_REQUEST = 'GET_MOST_MONTHLY_REQUEST';
export const GET_MOST_MONTHLY_SUCCESS = 'GET_MOST_MONTHLY_SUCCESS';
export const GET_MOST_MONTHLY_FAILED = 'GET_MOST_MONTHLY_FAILED';

export const GET_MOST_QUARTERLY_REQUEST = 'GET_MOST_QUARTERLY_REQUEST';
export const GET_MOST_QUARTERLY_SUCCESS = 'GET_MOST_QUARTERLY_SUCCESS';
export const GET_MOST_QUARTERLY_FAILED = 'GET_MOST_QUARTERLY_FAILED';

export const GET_MOST_YEARLY_REQUEST = 'GET_MOST_YEARLY_REQUEST';
export const GET_MOST_YEARLY_SUCCESS = 'GET_MOST_YEARLY_SUCCESS';
export const GET_MOST_YEARLY_FAILED = 'GET_MOST_YEARLY_FAILED';

export const GET_CLINIC_OVERVIEW_TODAY_REQUEST = 'GET_CLINIC_OVERVIEW_TODAY_REQUEST';
export const GET_CLINIC_OVERVIEW_TODAY_SUCCESS = 'GET_CLINIC_OVERVIEW_TODAY_SUCCESS';
export const GET_CLINIC_OVERVIEW_TODAY_FAILED = 'GET_CLINIC_OVERVIEW_TODAY_FAILED';

export const GET_CLINIC_OVERVIEW_DAILY_REQUEST = 'GET_CLINIC_OVERVIEW_DAILY_REQUEST';
export const GET_CLINIC_OVERVIEW_DAILY_SUCCESS = 'GET_CLINIC_OVERVIEW_DAILY_SUCCESS';
export const GET_CLINIC_OVERVIEW_DAILY_FAILED = 'GET_CLINIC_OVERVIEW_DAILY_FAILED';

export const GET_CLINIC_OVERVIEW_MONTHLY_REQUEST = 'GET_CLINIC_OVERVIEW_MONTHLY_REQUEST';
export const GET_CLINIC_OVERVIEW_MONTHLY_SUCCESS = 'GET_CLINIC_OVERVIEW_MONTHLY_SUCCESS';
export const GET_CLINIC_OVERVIEW_MONTHLY_FAILED = 'GET_CLINIC_OVERVIEW_MONTHLY_FAILED';

export const GET_CLINIC_OVERVIEW_QUARTERLY_REQUEST = 'GET_CLINIC_OVERVIEW_QUARTERLY_REQUEST';
export const GET_CLINIC_OVERVIEW_QUARTERLY_SUCCESS = 'GET_CLINIC_OVERVIEW_QUARTERLY_SUCCESS';
export const GET_CLINIC_OVERVIEW_QUARTERLY_FAILED = 'GET_CLINIC_OVERVIEW_QUARTERLY_FAILED';

export const GET_CLINIC_OVERVIEW_YEARLY_REQUEST = 'GET_CLINIC_OVERVIEW_YEARLY_REQUEST';
export const GET_CLINIC_OVERVIEW_YEARLY_SUCCESS = 'GET_CLINIC_OVERVIEW_YEARLY_SUCCESS';
export const GET_CLINIC_OVERVIEW_YEARLY_FAILED = 'GET_CLINIC_OVERVIEW_YEARLY_FAILED';

export const RESET_CLINIC_DASHBOARD_STATE = 'RESET_CLINIC_DASHBOARD_STATE';
