import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getMedicinesFailed: null,
  getMedicineFailed:null,
  medicines: [],
  medicine: null,
  isSubmitLoading : false,
  isGetMedicineLoading: false,
  Medicine: null,

  loadingUpdateCourse : false,
  updateFailedCourse : null,
  updateSuccessCourse : null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WH_MEDICINES_REQUEST:
      state.isLoading = true;
      state.getMedicinesFailed = null;
      return;

    case Types.GET_WH_MEDICINES_SUCCESS:
      state.isLoading = false;
      state.medicines = payload.docs;
      state.getMedicinesFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_WH_MEDICINES_FAILED:
      state.isLoading = false;
      state.medicines = [];
      state.getMedicinesFailed = payload;
      return;
    case Types.GET_WH_MEDICINE_REQUEST:
      state.isGetMedicineLoading = true;
      state.medicine = null
      state.getMedicineFailed = null;
      return;

    case Types.GET_WH_MEDICINE_SUCCESS:
      state.isGetMedicineLoading = false;
      state.medicine = payload;
      state.getMedicineFailed = null;
      return;

    case Types.GET_WH_MEDICINE_FAILED:
      state.isGetMedicineLoading = false;
      state.medicine = null;
      state.getMedicineFailed = payload;
      return;
    case Types.CREATE_WH_MEDICINE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_WH_MEDICINE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_WH_MEDICINE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_WH_MEDICINE_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_WH_MEDICINE_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.medicine = payload;
        return;
  
      case Types.UPDATE_WH_MEDICINE_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;

      case Types.DELETE_WH_MEDICINE_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_WH_MEDICINE_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_WH_MEDICINE_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_WH_MEDICINE_STATE:
      return initState;

    default:
      return;
  }
}, initState);
