import { Table, Tag } from 'antd'
import { get } from 'lodash'
import React from 'react'

export default function TableSubConditon({dataSource}) {
    const columns = [
        {
            title: 'Tên điều kiện phụ',
            dataIndex : 'value',
            key : 'value',
        },
        {
            title: 'Loại giá trị',
            dataIndex : 'typeValue',
            key : 'typeValue',
        },
        {
            title: 'Hoạt động sử dụng',
            dataIndex : 'activesUse',
            key : 'activesUse',
            render : (list) => list?.map(e => <Tag color='blue'>{e}</Tag>)
        },
    ]
  return (
    <>
        <Table columns={columns} dataSource={dataSource}/>
    </>
  )
}
