import { InfoCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Row, Table, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { CALCULATE_REPORT } from '~/constants/defaultValue';
import { formatNumberThreeComma } from '~/hooks/utils';
import { useReportIntroById } from '~/hooks/whReport';
import TableDetail from './TableDetail'

export default function TableIntroducePartner({id,setTotalDetailAll,date}) {
  const [data,loading] = useReportIntroById(id,date)
  useEffect(() => {
    setTotalDetailAll('introPartner',parseInt(get(data,'totalMoneyAllIntroPartner',0)))
  },[id,data])
    const columns = [
        {
          title: 'STT',
          key: 'null',
          width: 50,
          align : 'center',
          render: (item,record,index) => (
            <span>
              {index + 1}
            </span>
          )
        },
        {
          title: 'Nhóm đối tác',
          key: 'employeeGroup',
          width : '150px',
          dataIndex: 'employeeGroup',
          align : 'center',
          render: (item,record,index) => (
            <span>
              {get(item[0],'name.vi') || ''}
            </span>
          )
        },
        {
          title: 'Thưởng',
          key: 'bonusIntro',
          width : '100px',
          dataIndex: 'snapShortEmployeeGroup',
          align : 'center',
          render: (item) => (
            <span>
              {formatNumberThreeComma(get(item,'bonusIntro')) || 0}
            </span>
          )
        },
        {
          title: 'Số lượng NS phát triển',
          key: 'count',
          width : '200px',
          dataIndex: 'count',
          align : 'center',
          render: (item) => (
            <span>
              {item || 0}
            </span>
          )
        },
        {
          title: 'Doanh thu NS trong tháng',
          key: 'totalMoney',
          width : '200px',
          dataIndex: 'totalMoney',
          align : 'center',
          render: (item) => (
            <span>
              {formatNumberThreeComma(item.toFixed(0)) || 0}
            </span>
          )
        },
        {
          title: '% thu nhập',
          key: 'percentIncomeIntro',
          width : '100px',
          dataIndex: 'snapShortEmployeeGroup',
          align : 'center',
          render: (item) => (
            <span>
              {formatNumberThreeComma(get(item,'percentIncomeIntro')) || 0}%
            </span>
          )
        },
        {
          title: <Tooltip
          
          placement='leftTop'
          zIndex={2001}
          title={<span style={{fontSize: 'calc(0.2vw + 0.65rem)'}}>Thành tiền = (DTNSTT : 1.1 * % TN)+(Thưởng * SLNSPT)</span>}
        >
          <Badge
            size="small"
            color="#9B9999"
            // offset={[9, 3]}
            count={<InfoCircleOutlined />}
          >
           <Text>Thành tiền</Text>
          </Badge>
        </Tooltip>,
          key: 'billNumber',
          width : '100px',
          align : 'center',
          render: (item,record,index) => (
              <span>
                  {formatNumberThreeComma(CALCULATE_REPORT.introPartner(record))}
              </span>
            )
          
        }
      ];
      const summaryFooter = (curr) =>  <Table.Summary>
      <Table.Summary.Row>
      <Table.Summary.Cell align='center' >
        
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center' >
        TC
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center' >
        {curr.length || 0}
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        {formatNumberThreeComma(get(data,'totalMoneyAllIntroPartner')) || 0}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
    const columnExpan = [
      {
        title: 'STT',
        key: 'null',
        width: 50,
        align : 'center',
        render: (item,record,index) => (
          <span>
            {index + 1}
          </span>
        )
      },
      {
        title: 'Tên',
        key: 'name',
        dataIndex: 'name',
        align : 'center',
        render: (item,record,index) => (
          <span>
            {item || ''}
          </span>
        )
      },
      {
        title: 'Thời gian giới thiệu đối tác',
        key: 'createdAt',
        dataIndex: 'createdAt',
        align : 'center',
        render: (item,record,index) => (
          <span>
            {moment(item).format("DD-MM-YYYY") || ''}
          </span>
        )
      },
      {
        title: 'Thời gian đối tác hoàn thành đơn hàng đầu tiên',
        key: 'receiptVoucher',
        dataIndex: 'receiptVoucher',
        align : 'center',
        render: (item,record,index) => {
          return (
            <span>
              {(get(record,'receiptVoucher.receiptVoucher.dateApproved')) ? moment(get(record,'receiptVoucher.receiptVoucher.dateApproved')).format("DD-MM-YYYY") :''}
            </span>
          )
        }
      },
      {
        title: 'Doanh thu',
        key: 'receiptVoucher',
        dataIndex: 'receiptVoucher',
        align : 'center',
        render: (item,record,index) => {
          return (
            <span>
              {formatNumberThreeComma((get(record,'totalSale',0)))}
            </span>
          )
        }
      },
    ]
    const ExpandedRowRender = (record) =>{
      return ( <Table
        columns={columnExpan}
        dataSource={get(record,'listPartner')}
        pagination={false}
      />)
    }
  return (
    <div>
        <TableDetail scrollWidth={1000} ExpandedRowRender={ExpandedRowRender} isRowExpandable loading={loading} pagination={false} dataSource={get(data,'newGroupChildParent')} columns={columns} title={"Thu nhập theo giới thiệu đối tác (B)"} footer={summaryFooter}/>
    </div>
  )
}
