import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import { SYSTEM_CONFIGURATION_REDUCER_MAP, SYSTEM_CONFIGURATION_TYPES } from '~/constants/defaultValue';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  branches: null,
  isGetBranchesLoading: false,
  getBranchesFailed: null,

  paging: null,

  bookingInstructions: [],
  isBookingInstructionsLoading: false,
  getBookingInstructionsFailed: null,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    // BOOKING_INSTRUCTIONS
    case Types.GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_REQUEST:
      state.isBookingInstructionsLoading = true;
      state.getBookingInstructionsFailed = null;
      return;

    case Types.GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_SUCCESS:
      // state.isLoading = false;
      state.isBookingInstructionsLoading = false;

      // state.list = payload;
      state.bookingInstructions = Array.isArray(payload)
        ? payload.filter(item => item.key === SYSTEM_CONFIGURATION_TYPES.BOOKING_INSTRUCTIONS)
        : [];

      // state.paging = getPaging(payload); // response does not contain any pagination info
      return;

    case Types.GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_FAILED:
      state.isBookingInstructionsLoading = false;
      state.getBookingInstructionsFailed = payload;
      state.bookingInstructions = [];
      return;

    // single item
    case Types.GET_SYSTEM_CONFIGURATION_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_SYSTEM_CONFIGURATION_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_SYSTEM_CONFIGURATION_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_SYSTEM_CONFIGURATION_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_SYSTEM_CONFIGURATION_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;

      const { key } = payload;
      const branch = SYSTEM_CONFIGURATION_REDUCER_MAP[key];

      // state.list = state.list.concat(payload);
      state[branch] = state[branch].concat(payload);
      return;

    case Types.CREATE_SYSTEM_CONFIGURATION_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_SYSTEM_CONFIGURATION_REQUEST:
      state.isSubmitLoading = true;
      state.byId = payload;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_SYSTEM_CONFIGURATION_SUCCESS: {
    
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;

      const { key } = payload;
      const branch = SYSTEM_CONFIGURATION_REDUCER_MAP[key];
      state[branch] = state[branch].map(item => {
        if (item.id === payload._id) {
          return { ...item, ...payload, id: payload._id };
        }
        return item
      });
      return;
    }

    // TODO
    case Types.UPDATE_SYSTEM_CONFIGURATION_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_SYSTEM_CONFIGURATION_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_SYSTEM_CONFIGURATION_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_SYSTEM_CONFIGURATION_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_SYSTEM_CONFIGURATION_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
