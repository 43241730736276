import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import api from '~/api';
import { WH_COURSE_TRAINING_STATUS } from '~/constants/defaultValue';
import { useCreateTimeSheet } from '~/hooks/timeSheet';
import UploadFile from './UploadFile';
import toastr from 'toastr';
import LoadingWhBill from '~/components/WhBill/LoadingWhBill';
import { useFetchCourseReadyPartner, useSubmitTrainingCoursePartner, useTrainingCourseReadyPartnerQueryParams, useTrainingCoursesReadySubmitOfPartner } from '~/hooks/trainingCoursePartner';
import LinkFile from '~/components/Common/TypeFile/LinkFileImage';
import './form.scss';
const FormItem = Form.Item;
export default function FormSubmit({ onParamChange,isLoadingTraining, trainingCourses,handleCloseModalSubmit,partnerId,partner }) {
  const [form] = Form.useForm();
  const [loadingUpLoadFile,setLoadingUpLoadFile] = useState(false)
  const [loading,setLoading] = useState(false)
  const initData = useMemo(()=>({
    startDate: null,
    endDate: null,
    totalHours : null,
    ids:[],
    courseId:null,
    userId:null,
    courseIdFull :null,
    snapShortrateTrainging : 0
  }),[])

  const [data, setData] = useState(initData);
  const [files, setFiles] = useState([]);
  const callbackSubmit = () => {
    handleCloseModalSubmit()
    form.resetFields()
    setData(initData)
    onParamChange({page : null,limit : null,keyword : null})
  }
  const[loadingSubmit,submitTimeSheet] = useSubmitTrainingCoursePartner(callbackSubmit)
  const [queryReadyCourse] = useTrainingCourseReadyPartnerQueryParams(partnerId)

  const[courseReady,isLoadingCourseReady] = useFetchCourseReadyPartner(queryReadyCourse)
  const onFinish = (values) => {
    const submitData = {...data,note:values.note,files,partnerId,totalReceived : get(data,'snapShortrateTrainging',0) * get(data,'totalHours',0)}
    submitTimeSheet(submitData)
  };
  const MonthNow = useMemo(() => new Date().getMonth() + 1,[])
  const [optionUser, setOptionUser] = useState([]);
  const handleChangeIdCourse = async (value,{label}) => {
    setLoading(true)
    const res = await api.TrainingCoursePartner.getCourses({ courseId: value });
    let sumHours = 0
    let ids = []
    const arrNow = res
      .filter((e) => {
        if(parseInt(moment(e.date).format('MM')) === MonthNow && e.status === WH_COURSE_TRAINING_STATUS.NEW)
        {
          sumHours += e.time
          ids.push(e._id)
          return true
        }
      })
      .sort(
        (a, b) =>
          parseInt(moment(a.date).format('DD')) -
          parseInt(moment(b.date).format('DD'))
      );
      if(arrNow.length > 0){
        setData({
          startDate: arrNow[0].date,
          endDate: arrNow[arrNow.length - 1].date,
          totalHours : sumHours,
          ids,
          courseId : value,
          userId : get(arrNow[0],'userId._id'),
          courseIdFull : label,
          snapShortrateTrainging :get(res[0],'snapShortrateTraining',0)
        });
        form.setFieldsValue({totalHours : sumHours,userId : get(arrNow[0],'userId._id'),})
        const newOp = trainingCourses?.find(e => e._id === value)
        setOptionUser([{label : get(newOp,'responsibleUser.fullName'),value : get(newOp,'responsibleUser._id')}])
        form.setFieldsValue({userId : get(newOp,'responsibleUser._id')})
        let listFile = []
        arrNow.forEach(e => get(e,'files').forEach(value => listFile.push(value)));
        setFiles(listFile)
      }
      else{
        toastr.error("Khoá này không còn gì để Submit")
        setData(initData)
        form.resetFields()
        setOptionUser([])
        setFiles([])
      }
      setLoading(false)
  };
  return (
    <>
    <LoadingWhBill open={loading}/>
      <Divider>
        <h3 style={{ marginBottom: 0 }}>Submit khoá đào tạo</h3>
      </Divider>
      <Form
        labelCol={{ sm: 10, md: 10, lg: 10, xl: 10 }}
        wrapperCol={{ sm: 14, md: 14, lg: 14, xl: 14 }}
        form={form}
        onFinish={onFinish}
      >
        <FormItem
          label="Khoá đào tạo"
          name="idCourse"
          rules={[
            {
              required: true,
              message: 'Xin vui lòng chọn Khoá đào tạo'
            }
          ]}
        >
          <Select
            loading={isLoadingCourseReady}
            onChange={handleChangeIdCourse}
            showSearch
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={courseReady?.map((e) => ({
              label: e.courseIdFull,
              value: e._id
            }))}
            
          />
        </FormItem>
        <FormItem label="Thời gian" name="time">
          {data.startDate && <>
            <span>
            {moment(get(data, 'startDate')).format('DD/MM/YYYY')} -{' '}
          </span>
          <span>
            {moment(get(data, 'endDate')).format('DD/MM/YYYY')}
          </span>
          </>}
        </FormItem>
        <FormItem
          label="Số giờ"
          name="totalHours"
        >
         <span>{get(data,'totalHours','')}</span>
        </FormItem>
        
        <FormItem
          label="Người chịu trách nhiệm"
          name="userId"
        >
          <Select disabled={true} options={optionUser} />
        </FormItem>
        <FormItem
          label="Ghi chú"
          name="note"
        >
          <Input />
        </FormItem>
        <FormItem
          label="File đính kèm"
          name="file"
        >
        {files?.map(e => <LinkFile url={get(e,'name','')} href={get(e,'response.url','')}/>)}
        </FormItem>
        <Row className='group-buttonMy'>
          <Col span={10}>
            <Button disabled={loadingUpLoadFile} loading={loadingSubmit} htmlType="submit" type="primary" >
              Xong
            </Button>
          </Col>
          <Col span={10}>
            <Button onClick={handleCloseModalSubmit}>Huỷ</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
