import * as Types from '../../constants/actionTypes';
  
  export const getUiClients = query => ({
    type: Types.GET_UI_CLIENTS_REQUEST,
    payload: query
  });
  export const updateUiClient = instance => ({
    type: Types.UPDATE_UI_CLIENT_REQUEST,
    payload: instance
  });
  export const resetUiClientState = () => ({
    type: Types.RESET_UI_CLIENT_STATE
  });
