export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILED = 'GET_COUPONS_FAILED';

export const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAILED = 'GET_COUPON_FAILED';

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAILED = 'CREATE_COUPON_FAILED';

export const UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAILED = 'UPDATE_COUPON_FAILED';

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAILED = 'DELETE_COUPON_FAILED';

export const RESET_COUPON_STATE = 'RESET_COUPON_STATE';
export const RESET_COUPON_STATE_ACTION = 'RESET_COUPON_STATE_ACTION';

export const GET_COUPON_SERVICE_CATEGORY_REQUEST = 'GET_COUPON_SERVICE_CATEGORY_REQUEST';
export const GET_COUPON_SERVICE_CATEGORY_SUCCESS = 'GET_COUPON_SERVICE_CATEGORY_SUCCESS';
export const GET_COUPON_SERVICE_CATEGORY_FAILED = 'GET_COUPON_SERVICE_CATEGORY_FAILED';
