import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getNewses({ payload: query }) {
  try {
    const response = yield call(Api.news.getAll, query);
    yield put({ type: Types.GET_NEWSES_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_NEWSES_FAILED,
      payload: error.message
    });
  }
}
function* searchCategoryNewses({ payload: query }) {
  try {
    const response = yield call(Api.news.search, {...query,resource:'category'});
    yield put({ type: Types.FETCH_SEARCH_CATEGORY_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.FETCH_SEARCH_CATEGORY_FAILED,
      payload: error.message
    });
  }
}
function* searchAuthorgetNewses({ payload: query }) {
  try {
    const response = yield call(Api.news.search, {...query,resource:'author'});
    yield put({ type: Types.FETCH_SEARCH_AUTHOR_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.FETCH_SEARCH_AUTHOR_FAILED,
      payload: error.message
    });
  }
}

function* getNews({ payload: id }) {
  try {
    const news = yield call(Api.news.getById, id);
    yield put({ type: Types.GET_NEWS_SUCCESS, payload: news });
  } catch (error) {
    yield put({ type: Types.GET_NEWS_FAILED, payload: error.message });
  }
}

function* createNews(action) {
  try {
    const data = yield call(Api.news.create, action.payload);
    yield put({ type: Types.CREATE_NEWS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_NEWS_FAILED,
      payload: error.message
    });
  }
}

function* updateNews(action) {
  try {
    const data = yield call(Api.news.update, action.payload);
    yield put({ type: Types.UPDATE_NEWS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_NEWS_FAILED,
      payload: error.message
    });
  }
}

function* updateNewsid(action) {
  try {
    let {isFeatured ,status , _id ,category,highlight} = action.payload
    const data = yield call(Api.news.update, {isFeatured, status, _id, raw:true,category,highlight}); // # raw : BE will convert response toJSON!
    yield put({ type: Types.UPDATE_NEWS_ID_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_NEWS_ID_FAILED,
      payload: error.message
    });
  }
}

function* deleteNews({ payload }) {
  try {
    yield call(Api.news.delete, payload);
    yield put({ type: Types.DELETE_NEWS_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_NEWS_FAILED,
      payload: error.message
    });
  }
}

export default function* news() {
  yield takeLatest(Types.GET_NEWSES_REQUEST, getNewses);
  yield takeLatest(Types.GET_NEWS_REQUEST, getNews);
  yield takeLatest(Types.DELETE_NEWS_REQUEST, deleteNews);
  yield takeLatest(Types.CREATE_NEWS_REQUEST, createNews);
  yield takeLatest(Types.UPDATE_NEWS_REQUEST, updateNews);
  yield takeLatest(Types.UPDATE_NEWS_ID_REQUEST, updateNewsid);
  yield takeLatest(Types.FETCH_SEARCH_CATEGORY_REQUEST, searchCategoryNewses);
  yield takeLatest(Types.FETCH_SEARCH_AUTHOR_REQUEST, searchAuthorgetNewses);
}
