export const STATUS_APPLY_JOB = {
    //Chưa xem
    UNSEEN: 'UNSEEN',
    //xem
    SEEN:'SEEN',
    //Đạt yêu cầu
    SATISFACTORY:'SATISFACTORY',
    //Phỏng vấn
    INTERVIEW:'INTERVIEW',
    //Thử việc
    PROBATIONARY:'PROBATIONARY',
    //Cộng tác viên
    CTV: 'CTV',
    //Chăm sóc viên
    CSV: 'CSV',
    //Nhân viên Y tế
    NVYT: 'NVYT',
    //Chính thức
    OFFICIAL:'OFFICIAL',
    //từ chối
    REFUSE:'REFUSE',
    //Làm lại hồ sơ
    UPDATE_THE_PROFILE:'UPDATE_THE_PROFILE',
    //Sa thải
    DISMISS:'DISMISS',
  }
export const STATUS_APPLY_JOB_VI = {
    UNSEEN: 'Chưa xem',
    SEEN:'Đã xem',
    SATISFACTORY:'Đạt yêu cầu',
    INTERVIEW:'Phỏng vấn',
    PROBATIONARY:'Thử việc',
    CTV:'Cộng tác viên',
    CSV:'Chăm sóc viên',
    NVYT:'Nhân viên y tế',
    OFFICIAL:'Chính thức',
    REFUSE:'Loại',
    UPDATE_THE_PROFILE:'Làm lại hồ sơ',
    DISMISS:'Sa thải',
  }
  export const STATUS_APPLY_JOB_PROCESSING = [
    STATUS_APPLY_JOB.UNSEEN,
    STATUS_APPLY_JOB.SEEN
  ];
  export const STATUS_APPLY_JOB_COMPLETED = [
    STATUS_APPLY_JOB.SATISFACTORY,
    STATUS_APPLY_JOB.INTERVIEW,
    STATUS_APPLY_JOB.PROBATIONARY,
    STATUS_APPLY_JOB.OFFICIAL,
    STATUS_APPLY_JOB.CTV,
    STATUS_APPLY_JOB.CSV,
    STATUS_APPLY_JOB.NVYT,
  ];
  export const STATUS_APPLY_JOB_REJECT = [
    STATUS_APPLY_JOB.REFUSE,
    STATUS_APPLY_JOB.UPDATE_THE_PROFILE,
    STATUS_APPLY_JOB.DISMISS
  ];
export const STATUS_APPLY_JOB_OPTION = Object.keys(STATUS_APPLY_JOB_VI).map((k) => {
  let color = 'default';
  switch (true) {
    case STATUS_APPLY_JOB_PROCESSING.includes(k):
      color = 'processing'
      break;
    case STATUS_APPLY_JOB_COMPLETED.includes(k):
      color = 'success'
      break;
    case STATUS_APPLY_JOB_REJECT.includes(k):
      color = 'error'
      break;
  
    default:
      break;
  }
  return ({
    label : STATUS_APPLY_JOB_VI[k],
    value : k,
    color
})
})
