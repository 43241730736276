import { useMemo, useEffect, useState } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useBranchIdSessionStorage
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getAppointments,
  deleteAppointment,
  getAppointment,
  createAppointment,
  updateAppointment,
  resetAppointmentState,
  deleteAppointmentAssociate,
  getAppointmentssOfHistoryDeleted,
} from '~/redux/action';
import moment from 'moment';
import { useSelectBranch } from '../user';

const getSelector = key => state => state.appointment[key];

const loadingSelector = getSelector('isLoading');
const appointmentsSelector = getSelector('appointments');
const getAppointmentsFailedSelector = getSelector('getAppointmentsFailed');

const loadingOfHospitalDeletedSelector = getSelector('isLoadingOfHospitalDeleted');
const appointmentsOfHospitalDeletedSelector = getSelector('appointmentsOfHospitalDeleted');
const getAppointmentsOfHospitalDeletedFailedSelector = getSelector('appointmentsOfHospitalDeletedFailed');

const isGetAppointmentLoadingSelector = getSelector('isGetAppointmentLoading');
const appointmentSelector = getSelector('appointment');
const getAppointmentFailedSelector = getSelector('getAppointmentFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');



export const useAppointmentQueryParams = () => {
  // const [brandId] = useSelectBranch();
  // const  branchUserId = brandId
  const query = useQueryParams();
  const page = query.get('page') || 1;
  const limit = 200;
  const hospitalId = query.get('hospitalId');
  const status = query.get('status');
  const source = query.get('source');
  const specialityId = query.get('specialityId');
  const startTime = query.get('startTime');
  const endTime = query.get('endTime');
  const [branchId] = useBranchIdSessionStorage();

  const deleteAppointmentSuccess = useSelector(deleteSuccessSelector);
  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);

  return useMemo(() => {
    return {
      page,
      limit,
      // branchUserId : branchUserId,
      hospitalId: hospitalId || undefined,
      status: status || undefined,
      source: source || undefined,
      specialityId: specialityId || undefined,
      startTime,
      endTime
    };
    //eslint-disable-next-line
  }, [
    page,
    limit,
    hospitalId,
    status,
    source,
    specialityId,
    deleteAppointmentSuccess,
    createSuccess,
    updateSuccess,
    startTime,
    endTime,
    branchId
  ]);
};
export const useAppointmentOfHospitalDeletedQueryParams = () => {
  const query = useQueryParams();
  const [page, setPage] = useState( query.get('page') || 1)
  const [limit, setLimit] = useState( query.get('limit') || 10)
  // const hospitalId = query.get('hospitalId');
  // const status = query.get('status');
  // const source = query.get('source');
  // const specialityId = query.get('specialityId');
  // const startTime = query.get('startTime');
  // const endTime = query.get('endTime');
  // const [branchId] = useBranchIdSessionStorage();
  const onTableChange = ({current, pageSize}) => {
    setPage(current);
    setLimit(pageSize);
  }
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      // hospitalId: hospitalId || undefined,
      // status: status || undefined,
      // source: source || undefined,
      // specialityId: specialityId || undefined,
      // startTime,
      // endTime
    };
    return [queryParams, onTableChange]
    
    //eslint-disable-next-line
  }, [
    page,
    limit,
    // hospitalId,
    // status,
    // source,
    // specialityId,
    // startTime,
    // endTime,
    // branchId
  ]);
};


export const useAppointments = query => {
  return useFetchByParam({
    action: getAppointments,
    loadingSelector,
    dataSelector: appointmentsSelector,
    failedSelector: getAppointmentsFailedSelector,
    param: query
  });
};

export const useAppointmentsOfHistoryDeleted = query => {
  return useFetchByParam({
    action: getAppointmentssOfHistoryDeleted,
    loadingSelector: loadingOfHospitalDeletedSelector,
    dataSelector: appointmentsOfHospitalDeletedSelector,
    failedSelector: getAppointmentsOfHospitalDeletedFailedSelector,
    param: query
  });
};

const EVENT_COLORS = {
  CREATED: '#1890ff ',
  CANCEL: '#E40017',
  CONFIRMED: '#35D2B9',
  ARRIVED: '#FEC230'
};

export const useAppointmentEvents = query => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const onChangeDate = (start, end) => {
    if (start || end) {
      setStartTime(start);
      setEndTime(end);
    };
  };
  const queryFeatch = useMemo(() => {
    let newQuery = { ...query };
    if (startTime || endTime) {
      return newQuery = {
        ...query,
        startTime,
        endTime,
      };
    };
  }, [query, startTime, endTime]);
  const [appointments, isLoading] = useAppointments(queryFeatch);
  const appointmentEvents = useMemo(
    () =>
      appointments?.docs?.map(({ _id, customerName, date, hospital, status }) => {
         return ({
        id: _id,
        title: customerName || '',
        start: moment(date).format('YYYY-MM-DDTHH:mm'),
        extendedProps: { hospital },
        color: EVENT_COLORS[status]
      })}
      ),
    [appointments]
  );

  return [appointmentEvents, appointments, isLoading,onChangeDate];
};

export const useAppointment = id => {
  return useFetchByParam({
    action: getAppointment,
    loadingSelector: isGetAppointmentLoadingSelector,
    dataSelector: appointmentSelector,
    failedSelector: getAppointmentFailedSelector,
    param: id
  });
};

export const useCreateAppointment = callBack => {
  useSuccess(createSuccessSelector, 'Tạo mới lịch hẹn thành công', callBack);
  useFailed(createFailedSelector, 'Tạo mới lịch hẹn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createAppointment
  });
};

export const useUpdateAppointment = callBack => {
  useSuccess(updateSuccessSelector, 'Cập nhật lịch hẹn thành công', callBack);
  useFailed(updateFailedSelector, 'Cập nhật lịch hẹn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateAppointment
  });
};

export const useDeleteAppointment = () => {
  useSuccess(deleteSuccessSelector, 'Xoá lịch hẹn thành công');
  useFailed(deleteFailedSelector, 'Xoá lịch hẹn thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteAppointmentAssociate
  });
};

export const useInitAppointment = (
  form,
  appointment,
  appointmentId,
  initStartTime,
  setSelectedHospitalId,
  setSelectedSpecialityId
) => {
  const { hospital, speciality } = appointment || {};

  const initAppointment = useMemo(() => {
    const {
      customer,
      source,
      status,
      time: dateTime,
      hospital,
      speciality,
      message,
    } = appointment || {};

    const localDateTime = moment(dateTime).toISOString(true);
    const [date, time] = (localDateTime || '').split('T');

    return {
      customer,
      source,
      status,
      date: moment(date, 'YYYY-MM-DD'),
      time: moment(time, 'HH:mm:zzsS').format('HH:mm'),
      hospitalId: hospital?._id,
      specialityId: speciality?.id,
      message,
    };
  }, [appointment]);

  useEffect(() => {
    form.resetFields();
    setSelectedHospitalId(hospital?.id);
    setSelectedSpecialityId({ specialityId: speciality?.id });
  }, [
    initAppointment,
    form,
    appointmentId,
    initStartTime,
    setSelectedHospitalId,
    hospital,
    speciality,
    setSelectedSpecialityId
  ]);

  return appointmentId ? initAppointment : { date: null };
};

export const useResetAppointment = () => {
  useResetState(resetAppointmentState);
};


