import produce from 'immer';
import getPaging from '~/utils/getPaging';
import * as Types from '../../constants/actionTypes';

const initState = {
  isLoading: false,
  getTransactionsFailed: undefined,
  list: [],

  isGetTransactionPartnerLoading: false,
  getTransactionPartnerFailed: undefined,
  listTransactionsPartner: [],

  paging: null,
  pagingPartner: null
};
export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_TRANSACTIONS_REQUEST:
      state.isLoading = true;
      state.list = [];
      state.getListFailed = null;
      return;

    case Types.GET_TRANSACTIONS_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_TRANSACTIONS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;
    case Types.GET_TRANSACTIONS_PARTNER_REQUEST:
      state.isGetTransactionPartnerLoading = true;
      state.listTransactionsPartner = [];
      state.getTransactionPartnerFailed = null;
      return;

    case Types.GET_TRANSACTIONS_PARTNER_SUCCESS:
      state.isGetTransactionPartnerLoading = false;
      state.listTransactionsPartner = payload.docs;
      state.pagingPartner = getPaging(payload);
      return;

    case Types.GET_TRANSACTIONS_PARTNER_FAILED:
      state.isGetTransactionPartnerLoading = false;
      state.getTransactionPartnerFailed = payload;
      state.listTransactionsPartner = [];
      return;

    case Types.RESET_TRANSACTIONS:
      return initState;

    default:
      return;
  }
}, initState);
