export const GET_RANKINGS_REQUEST =
  'GET_RANKINGS_REQUEST';
export const GET_RANKINGS_SUCCESS =
  'GET_RANKINGS_SUCCESS';
export const GET_RANKINGS_FAILED = 'GET_RANKINGS_FAILED';

export const GET_RANKING_REQUEST = 'GET_RANKING_REQUEST';
export const GET_RANKING_SUCCESS = 'GET_RANKING_SUCCESS';
export const GET_RANKING_FAILED = 'GET_RANKING_FAILED';

export const CREATE_RANKING_REQUEST =
  'CREATE_RANKING_REQUEST';
export const CREATE_RANKING_SUCCESS =
  'CREATE_RANKING_SUCCESS';
export const CREATE_RANKING_FAILED =
  'CREATE_RANKING_FAILED';

export const UPDATE_RANKING_REQUEST =
  'UPDATE_RANKING_REQUEST';
export const UPDATE_RANKING_SUCCESS =
  'UPDATE_RANKING_SUCCESS';
export const UPDATE_RANKING_FAILED =
  'UPDATE_RANKING_FAILED';

export const DELETE_RANKING_REQUEST =
  'DELETE_RANKING_REQUEST';
export const DELETE_RANKING_SUCCESS =
  'DELETE_RANKING_SUCCESS';
export const DELETE_RANKING_FAILED =
  'DELETE_RANKING_FAILED';


export const RESET_RANKING_STATE = 'RESET_RANKING_STATE';
export const RESET_RANKING_STATE_ACTION = 'RESET_RANKING_STATE_ACTION';
