import { PATH_APP } from "~/routes/paths";
export const BASE_URL = 'https://api.congtyso.com'; 
// export const BASE_URL = 'http://192.168.77.157:8003'; 
export const LANGUAGE = {
  VI: 'vi',
  EN: 'en'
};

export const GENDER = {
  M: { vi: 'Nam', en: 'Male' },
  F: { vi: 'Nữ', en: 'Female' }
};

export const SERVICES_TYPES = [
  'DOCTOR_AT_HOME',
  'MEDICAL_TEST',
  'CLINIC_APPOINTMENT'
];

export const SERVICES_NAME = {
  DOCTOR_AT_HOME: 'Điều dưỡng tại nhà',
  CLINIC_APPOINTMENT: 'Khám tại phòng khám',
  MEDICAL_TEST: 'Xét nghiệm'
};

export const PHARMACY_TYPES = [
  "DISTRICT_LEVEL",
  "CITY_LEVEL",
  "REGIONS",
];
export const PHARMACY_LEVEL = {
  DISTRICT_LEVEL: 'Cấp Quận',
  CITY_LEVEL: 'Cấp thành phố',
  REGIONS: 'Cấp vùng'
};

export const CARD_STYLE = { boxShadow: '0 2px 4px rgb(15 34 58 / 12%)' };

export const ORDER_STATUS = {
  NEW: 'NEW',
  RECEIVED: 'RECEIVED',
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
  CONFIRMED: 'CONFIRMED',
  PACKAGED: 'PACKAGED',
  SHIPPING: 'SHIPPING',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED'
};
export const ORDER_STATUS_KEY_SEARCH_COLOR = {
  [ORDER_STATUS.NEW]: { name: 'Mới', color: '#f0f0f0',colorStyle: '#02030e' },
  [ORDER_STATUS.RECEIVED]: { name: 'Đã tiếp nhận', color: '#242ddf',colorStyle: '#242ddf' },
  [ORDER_STATUS.PROCESSING]: { name: 'Đang xử lý', color: '#249451',colorStyle: '#249451' },
  [ORDER_STATUS.PROCESSED]: { name: 'Đã xử lý', color: '#eee952',colorStyle: '#eee952' },
  [ORDER_STATUS.CONFIRMED]: { name: 'Đã xác nhận', color: '#ce8217',colorStyle: '#ce8217' },
  [ORDER_STATUS.PACKAGED]: { name: 'Đã đóng gói', color: '#e03bae',colorStyle: '#e03bae' },
  [ORDER_STATUS.SHIPPING]: { name: 'Đang giao', color: '#e0a98c',colorStyle: '#e0a98c' },
  [ORDER_STATUS.DELIVERED]: { name: 'Đã nhận', color: '#60d308',colorStyle: '#60d308' },
  [ORDER_STATUS.CANCELLED]:{ name: 'Đã huỷ', color: '#f5222d',colorStyle: '#f5222d' },
};
export const ORDER_QUOTATION_STATUS = {
  PROCESSING: 'PROCESSING',
  CANCELLED: 'CANCELLED',
  CONVERTED: 'CONVERTED',
  CREATE: 'CREATE'
};
export const ORDER_QUOTATION_STATUS_NAME = {
  PROCESSING: 'Đang xử lí',
  CANCELLED: 'Đã huỷ',
  CONVERTED: 'Đã chuyển đổi',
  CREATE: 'Mới tạo'
};

export const ORDER_STATUS_NAME = {
  NEW: 'Mới',
  RECEIVED: 'Đã tiếp nhận',
  PROCESSING: 'Đang xử lý',
  PROCESSED: 'Đã xử lý',
  CONFIRMED: 'Đã xác nhận',
  PACKAGED: 'Đã đóng gói',
  SHIPPING: 'Đang giao',
  DELIVERED: 'Đã nhận',
  CANCELLED: 'Đã hủy',
  CONVERTED: 'Đã chuyển đổi'
};
export const TRANSFER = {
  PAY_IN: 'Thu',
  PAY_OUT: 'Chi'
};
export const PAYMENT_METHOD = {
  COD: 'COD'
};

export const WH_PAYMENT_METHOD = {
  COD: 'COD',
  TRANSFER: 'TRANSFER'
};

export const WH_PAYMENT_METHOD_VI = {
  COD: 'Tiền mặt',
  TRANSFER: 'Chuyển khoản'
};

export const PAYMENT_STATUS = {
  AWAITING: 'AWAITING',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED,'
};

export const ORDER_ACTION_NAME = {
  ASSIGN: 'Phân bổ',
    UPDATE_LINE_ITEM: 'Cập nhật thuốc',
    PROCESS: 'Xử lý',
  CONFIRM: 'Xác nhận',
  PACKAGE: 'Đóng gói',
  SHIPPING: 'Giao hàng',
  CANCEL: 'Hủy bỏ',
  REJECT: 'Từ chối',
  COMPLETE: 'Hoàn thành',
  };

export const ORDER_ACTIONS = {
  ASSIGN: 'ASSIGN',
  UPDATE_LINE_ITEM: 'UPDATE-ITEM',
  PROCESS: 'PROCESS',
  CONFIRM: 'CONFIRM',
  PACKAGE: 'PACKAGE',
  SHIPPING: 'SHIPPING',
  CANCEL: 'CANCEL',
  REJECT: 'REJECT',
  ORDER_COMPLETION: 'COMPLETE'
};

export const ORDER_TRANSITIONS = [
  {
    name: ORDER_ACTIONS.REJECT,
    from: ORDER_STATUS.CONFIRMED,
    to: ORDER_STATUS.NEW
  },
  {
    name: ORDER_ACTIONS.REJECT,
    from: ORDER_STATUS.PROCESSED,
    to: ORDER_STATUS.NEW
  },
  {
    name: ORDER_ACTIONS.REJECT,
    from: ORDER_STATUS.PACKAGED,
    to: ORDER_STATUS.NEW
  },
  {
    name: ORDER_ACTIONS.REJECT,
    from: ORDER_STATUS.RECEIVED,
    to: ORDER_STATUS.NEW
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.NEW,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.RECEIVED,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.PROCESSED,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.CONFIRMED,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.PACKAGED,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.CANCEL,
    from: ORDER_STATUS.SHIPPING,
    to: ORDER_STATUS.CANCELLED
  },
  {
    name: ORDER_ACTIONS.ASSIGN,
    from: ORDER_STATUS.NEW,
    to: ORDER_STATUS.RECEIVED
  },
  {
    name: ORDER_ACTIONS.PROCESS,
    from: ORDER_STATUS.RECEIVED,
    to: ORDER_STATUS.PROCESSED
  },
  {
    name: ORDER_ACTIONS.UPDATE_LINE_ITEM,
    from: ORDER_STATUS.RECEIVED,
    to: ORDER_STATUS.RECEIVED
  },
  {
    name: ORDER_ACTIONS.CONFIRM,
    from: ORDER_STATUS.PROCESSED,
    to: ORDER_STATUS.CONFIRMED
  },
  {
    name: ORDER_ACTIONS.PACKAGE,
    from: ORDER_STATUS.CONFIRMED,
    to: ORDER_STATUS.PACKAGED
  },
  {
    name: ORDER_ACTIONS.SHIPPING,
    from: ORDER_STATUS.PACKAGED,
    to: ORDER_STATUS.SHIPPING
  },
  {
    name: ORDER_ACTIONS.ORDER_COMPLETION,
    from: ORDER_STATUS.SHIPPING,
    to: ORDER_STATUS.DELIVERED
  }
];

export const INSTANCE_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const APPOINTMENT_STATUS = {
  CREATED: { name: 'Mới', color: '#1890ff' },
  CONFIRMED: { name: 'Đã xác nhận', color: '#E40017' },
  CANCEL: { name: 'Đã huỷ', color: '#35D2B9' },
  ARRIVED: { name: 'Đã hoàn thành', color: '#FEC230' },
};

export const APPOINTMENT_STATUS_KEY = {
  CREATED: 'CREATED',
  CONFIRMED: 'CONFIRMED',
  CANCEL: 'CANCEL',
  ARRIVED: 'ARRIVED',
};

export const APPOINTMENT_STATUS_SEARCH_CUSTOMER = {
  [APPOINTMENT_STATUS_KEY.CREATED]: { name: 'Mới', color: '#1890ff',colorStyle: '#1890ff' },
  [APPOINTMENT_STATUS_KEY.CONFIRMED]: { name: 'Đã xác nhận', color: '#35D2B9',colorStyle: '#35D2B9' },
  [APPOINTMENT_STATUS_KEY.CANCEL]: { name: 'Đã huỷ', color: '#E40017',colorStyle: '#E40017' },
  [APPOINTMENT_STATUS_KEY.ARRIVED]: { name: 'Đã hoàn thành', color: '#FEC230',colorStyle: '#FEC230' },
};

export const MINUTES_PER_HOUR = 60;

export const TIME_UNIT = {
  MINUTE: 'Phút',
  HOUR: 'Giờ'
};

export const TIME_UNITS = [
  { text: { vi: 'Phút', en: 'Minute' }, value: 'Phút' },
  { text: { vi: 'Giờ', en: 'Hour' }, value: 'Giờ' }
];

export const VAT = 0;

export const SALE_UNITS = ['viên', 'vỉ', 'lọ', 'hộp'];

export const RATE_CONFIG_TYPES = {
  CERTIFICATE: 'CERTIFICATE',
  DEGREE: 'DEGREE',
  EXPERIENCE: 'EXPERIENCE',
  POSITION: 'POSITION'
};

export const RATE_CONFIG_REDUCER_MAP = {
  [RATE_CONFIG_TYPES.CERTIFICATE]: 'certificates',
  [RATE_CONFIG_TYPES.DEGREE]: 'degrees',
  [RATE_CONFIG_TYPES.EXPERIENCE]: 'experiences',
  [RATE_CONFIG_TYPES.POSITION]: 'positions'
};

export const SYSTEM_CONFIGURATION_TYPES = {
  BOOKING_INSTRUCTIONS: 'BOOKING_INSTRUCTION',
  VAT: 'VAT'
};

export const SYSTEM_CONFIGURATION_REDUCER_MAP = {
  [SYSTEM_CONFIGURATION_TYPES.BOOKING_INSTRUCTIONS]: 'bookingInstructions',
  [SYSTEM_CONFIGURATION_TYPES.VAT]: 'vats'
};

export const IMPORT_WH_CUSTOMER_EXCEL_TYPES = {
  IMPORT_WH_CUSTOMERS_EXCEL: 'IMPORT_WH_CUSTOMER_EXCEL'
};

export const IMPORT_WH_CUSTOMER_EXCEL_REDUCER_MAP = {
  [IMPORT_WH_CUSTOMER_EXCEL_TYPES.IMPORT_WH_CUSTOMERS_EXCEL]:
    'importWhCustomersExcel'
};

export const COMPONENT_MODES = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  CREATE: 'CREATE'
};

export const WEEKDAYS = {
  0: { name: { vi: 'Thứ hai' } },
  1: { name: { vi: 'Thứ ba' } },
  2: { name: { vi: 'Thứ tư' } },
  3: { name: { vi: 'Thứ năm' } },
  4: { name: { vi: 'Thứ sáu' } },
  5: { name: { vi: 'Thứ bảy' } },
  6: { name: { vi: 'Chủ nhật' } }
};

export const MAX_PAGINATION_LIMIT = 200;

export const WH_PARTNER_STATUS = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED'
};
export const TRANSACTIONS = {
  SUCCESS: 'Đã hoàn thành' 
};
export const WH_PARTNER_STATUS_ACTION = {
  CREATE: 'Mới tạo',
  PROCESSING: 'Đang xử lý',
  CANCELLED: 'Đã huỷ',
  CONVERTED: 'Đã chuyển đổi'
};

export const WH_BILL_STATUS = {
  NEW: 'Mới',
  PREPAYMENT_CONFIRMED: 'Xác nhận cọc',
  IN_PROGRESS: 'Đang thực hiện',
  CANCELLED: 'Đã hủy',
  CUSTOMER_CANCEL: 'Đã chấm dứt',
  COMPLETED : 'Đã hoàn thành'
};

export const WH_BILL_STATUS_EN = {
  NEW: 'NEW',
  PREPAYMENT_CONFIRMED: 'PREPAYMENT_CONFIRMED',
  IN_PROGRESS: 'IN_PROGRESS',
  CANCELLED: 'CANCELLED',
  CUSTOMER_CANCEL: 'CUSTOMER_CANCEL',
  COMPLETED: 'COMPLETED',
};

export const WH_BILL_ITEM_STATUS = {
  NEW: 'NEW',
  CONFIRMED: 'CONFIRMED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  CUSTOMER_CANCEL: 'CUSTOMER_CANCEL'
};

export const WH_VOUCHER_STATUS = {
  CREATED: 'CREATED',
  CONFIRMED: 'CONFIRMED',
  APPROVED: 'APPROVED',
  REJECT: 'REJECT',
  CUSTOMER_CANCEL: 'CUSTOMER_CANCEL'
};
export const WH_VOUCHER_STATUS_VI = {
  CREATED: 'Mới',
  CONFIRMED: 'Đã xác nhận',
  APPROVED: 'Đã duyệt',
  REJECT: 'Đã từ chối',
  CUSTOMER_CANCEL: 'Đã chấm dứt',
};

export const WH_VOUCHER_ACTION_NAME = {
  [WH_VOUCHER_STATUS.CONFIRMED]: {
    [LANGUAGE.VI]: 'Xác nhận'
  },
  [WH_VOUCHER_STATUS.APPROVED]: {
    [LANGUAGE.VI]: 'Duyệt'
  },
  [WH_VOUCHER_STATUS.REJECT]: {
    [LANGUAGE.VI]: 'Từ chối'
  },
  [WH_VOUCHER_STATUS.CREATED]: {
    [LANGUAGE.VI]: 'Mới'
  },
  [WH_VOUCHER_STATUS.CUSTOMER_CANCEL]: {
    [LANGUAGE.VI]: 'Đã chấm dứt'
  },
};

export const WH_RECEIPT_VOUCHER_CODE_PREFIX = 'PT';

export const WH_PAYMENT_VOUCHER_CODE_PREFIX = 'PC';

export const MAP_WH_BILL_ITEM_STATUS_TAG = {
  [WH_BILL_ITEM_STATUS.NEW]: { name: 'Mới', color: 'geekblue' },
  [WH_BILL_ITEM_STATUS.CONFIRMED]: { name: 'Chưa thực hiện', color: 'orange' },
  [WH_BILL_ITEM_STATUS.IN_PROGRESS]: {
    name: 'Đang thực hiện',
    color: 'processing'
  },
  [WH_BILL_ITEM_STATUS.COMPLETED]: { name: 'Hoàn thành', color: 'success' },
  [WH_BILL_ITEM_STATUS.CANCELLED]: { name: 'Đã hủy', color: 'error' },
  [WH_BILL_ITEM_STATUS.CUSTOMER_CANCEL]: {
    name: 'Đã chấm dứt',
    color: '#ad8b00'
  } // yellow-8
};

export const MAP_WH_VOUCHER_STATUS_TAG = {
  [WH_VOUCHER_STATUS.CREATED]: { name: 'Mới', color: 'geekblue',colorStyle : '#1d39c4' },
  [WH_VOUCHER_STATUS.CONFIRMED]: { name: 'Đã xác nhận', color: 'processing' ,colorStyle : '#1890ff'},
  [WH_VOUCHER_STATUS.APPROVED]: { name: 'Đã duyệt', color: 'success',colorStyle : '#52c41a' },
  [WH_VOUCHER_STATUS.REJECT]: { name: 'Đã từ chối', color: 'error',colorStyle : '#f5222d' },
  [WH_VOUCHER_STATUS.CUSTOMER_CANCEL]: { name: 'Đã chấm dứt', color: 'default',colorStyle : 'rgba(0, 0, 0, 0.85)' },
};

export const WH_APPOINTMENT_STATUS = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED'
};

export const WH_APPOINTMENT_STATUS_IN_HISTORY = {
  CREATED: {
    name: 'Mới',
    color: 'created'
  },
  CONFIRMED: {
    name: 'Đã xác nhận',
    color: 'processing'
  },
  ARRIVED: {
    name: 'Hoàn thành',
    color: 'success'
  },
  CANCEL: {
    name: 'Đã hủy',
    color: 'error'
  },
};

export const UPDATABLE_WH_APPOINTMENT_STATUSES = [
  WH_APPOINTMENT_STATUS.NEW,
  WH_APPOINTMENT_STATUS.IN_PROGRESS
];

export const CURRENCY = {
  VND: 'VND'
};

export const CURRENCY_STRING = {
  [CURRENCY.VND]: 'đồng'
};

export const HOURS_TO_COLLECT_PREPAYMENT = 12;

export const RELATIVE_POSITION = {
  IS_CHILD: 'IS_CHILD',
  IS_EQUAL: 'IS_EQUAL',
  IS_PARENT: 'IS_PARENT'
};

export const DEFAULT_UPLOAD_ACTION = `${BASE_URL}/api/v1/file`;

export const MAX_UPLOAD_FILE_SIZE_IN_MB = 2;

export const WH_BILL_ITEM_NOTES = {
  addTime: {
    vi: 'Thêm thời gian'
  },
  simultaneousServices: {
    vi: 'Thực hiện các dịch vụ cùng lúc'
  },
  splitToMultipleTimes: {},
  other: {
    vi: 'Khác'
  }
};

export const HISTORY_KEY = {
  WHSERVICE: 'Dịch vụ'
};


export const ACCOUNTS = {
  1111: 'Tiền Việt Nam ',
  1112: 'Ngoại tệ',
  1121: 'Tiền Việt Nam ',
  1122: 'Ngoại tệ',
  121: 'Chứng khoán kinh doanh',
  1281: 'Tiền gửi có kỳ hạn',
  1288: 'Đầu tư nắm giữ đến ngày đáo hạn',
  131: 'Phải thu của khách hàng',
  1331: 'Thuế GTGT được khấu trừ của hàng hóa; dịch vụ ',
  1332: 'Thuế GTGT được khấu trừ của TSCĐ',
  1361: 'Vốn kinh doanh ở đơn vị trực thuộc ',
  1368: 'Phải thu nội bộ khác',
  1381: 'Tài sản thiếu chờ xử lý',
  1386: 'Cầm cố; thế chấp; ký quỹ; ký cược',
  1388: 'Phải thu khác',
  141: 'Tạm ứng',
  151: 'Hàng mua đang đi đường',
  152: 'Nguyên liệu; vật liệu',
  153: 'Công cụ; dụng cụ',
  154: 'Chi phí sản xuất; kinh doanh dở dang',
  155: 'Thành phẩm',
  156: 'Hàng hóa',
  157: 'Hàng gửi đi bán',
  21111: 'Nhà cửa; vật kiến trúc',
  21112: 'Máy móc thiết bị',
  21113: 'Phương tiện vận tải; truyền dẫn',
  21114: 'Thiết bị dụng cụ quản lý',
  21115: 'Cây lâu năm; súc vật làm việc và cho sản phẩm',
  21116: 'Các TSCĐ là kết cấu hạ tầng; có giá trị',
  21118: 'TSCĐ khác',
  2112: 'TSCĐ thuê tài chính ',
  21131: 'Quyền sử dụng đất',
  21132: 'Quyền phát hành',
  21133: 'Bản quyền; bằng sáng chế',
  21134: 'Nhãn hiệu hàng hoá',
  21135: 'Phần mềm máy vi tính',
  21136: 'Giấy phép và giấy chuyển nhượng quyền ',
  21138: 'TSCĐ vô hình khác',
  2141: 'Hao mòn TSCĐ hữu hình',
  2142: 'Hao mòn TSCĐ thuê tài chính',
  2143: 'Hao mòn TSCĐ vô hình',
  2147: 'Hao mòn bất động sản đầu tư',
  217: 'Bất động sản đầu tư',
  2281: 'Đầu tư vào công ty liên doanh; liên kết',
  2288: 'Đầu tư khác ',
  2291: 'Dự phòng giảm giá chứng khoán kinh doanh',
  2292: 'Dự phòng tổn thất đầu tư vào đơn vị khác',
  2293: 'Dự phòng phải thu khó đòi',
  2294: 'Dự phòng giảm giá hàng tồn kho',
  2411: 'Mua sắm TSCĐ',
  2412: 'Xây dựng cơ bản',
  2413: 'Sửa chữa lớn TSCĐ',
  242: 'Chi phí trả trước',
  331: 'Phải trả cho người bán',
  33311: 'Thuế GTGT đầu ra',
  33312: 'Thuế GTGT hàng nhập khẩu',
  3332: 'Thuế tiêu thụ đặc biệt',
  3333: 'Thuế xuất; nhập khẩu',
  3334: 'Thuế thu nhập doanh nghiệp',
  3335: 'Thuế thu nhập cá nhân',
  3336: 'Thuế tài nguyên',
  3337: 'Thuế nhà đất; tiền thuê đất',
  33381: 'Thuế bảo vệ môi trường',
  33382: 'Các loại thuế khác',
  3339: 'Phí; lệ phí và các khoản phải nộp khác',
  334: 'Phải trả người lao động',
  335: 'Chi phí phải trả',
  3361: 'Phải trả nội bộ về vốn kinh doanh',
  3368: 'Phải trả nội bộ khác',
  3381: 'Tài sản thừa chờ giải quyết',
  3382: 'Kinh phí công đoàn',
  3383: 'Bảo hiểm xã hội',
  3384: 'Bảo hiểm y tế',
  3385: 'Bảo hiểm thất nghiệp',
  3386: 'Nhận ký quỹ; ký cược',
  3387: 'Doanh thu chưa thực hiện',
  3388: 'Phải trả; phải nộp khác',
  3411: 'Các khoản đi vay ',
  3412: 'Nợ thuê tài chính',
  3521: 'Dự phòng bảo hành sản phẩm hàng hoá',
  3522: 'Dự phòng bảo hành công trình xây dựng',
  3524: 'Dự phòng phải trả khác',
  3531: 'Quỹ khen thưởng',
  3532: 'Quỹ phúc lợi',
  3534: 'Quỹ thưởng ban quản lý điều hành công ty',
  3561: 'Quỹ phát triển khoa học và công nghệ',
  3562: 'Quỹ phát triển khoa học và công nghệ',
  4111: 'Vốn góp của chủ sỡ hữu',
  4112: 'Thặng dư vốn cổ phần',
  4118: 'Vốn khác ',
  413: 'Chênh lệch tỷ giá hối đoái',
  418: 'Các quỹ thuộc vốn chủ sỡ hữu',
  419: 'Cổ phiếu quỹ',
  4211: 'Lợi nhuận sau thuế chưa phân phối năm trước',
  4212: 'Lợi nhuận sau thuế chưa phân phối năm nay',
  5111: 'Doanh thu bán hàng hoá',
  5113: 'Doanh thu cung cấp dịch vụ',
  5118: 'Doanh thu khác',
  515: 'Doanh thu hoạt động tài chính',
  611: 'Mua hàng',
  632: 'Giá vốn hàng bán',
  635: 'Chi phí tài chính',
  6421: 'Chi phí bán hàng',
  6422: 'Chi phí quản lý doanh nghiệp',
  711: 'Thu nhập khác',
  811: 'Chi phí khác',
  821: 'Chi phí thuế thu nhập doanh nghiệp',
  632: 'Giá vốn hàng bán',
  635: 'Chi phí tài chính',
  6421: 'Chi phí bán hàng',
  6422: 'Chi phí quản lý doanh nghiệp',
  711: 'Thu nhập khác',
  811: 'Chi phí khác',
  821: 'Chi phí thuế thu nhập doanh nghiệp',
  911: 'Xác định kết quả kinh doanh '
};

export const NEW_ORDER_NOTIFY_EMAIL = 'newOrderNotifyEmail';
export const PARTNER_CANCEL_NOTIFY_EMAIL = 'newPartnerCancelNotifyEmail';
export const NEW_DEV_CONFIG_CAMPAGIN_EMAIL = 'newDevConfigCampaginEmail';
export const NEW_ORDER_NOTIFY_ZALO = 'newOrderNotifyZalo';
export const NEW_PARTNER_NOTIFY_EMAIL = 'newPartnerNotifyEmail';
export const NEW_PARTNER_NOTIFY_ZALO = 'newPartnerNotifyZalo';
export const ASSIGNED_PARTNER_NOTIFY_ZALO = 'assignedPartnerNotifyZalo';
export const PREPAY_CONFIRMED_ORDER_NOTIFY_ZALO =
  'prepayConfirmedOrderNotifyZalo';
export const PARTNER_CHECKIN_NOTIFY_ZALO = 'partnerCheckinNotifyZalo';
export const PARTNER_CHECKOUT_NOTIFY_ZALO = 'partnerCheckoutNotifyZalo';
export const NEW_APPOINTMENTS_NOTIFY_ZALO = 'newAppointmentNotifyZalo';
export const NEW_APPOINTMENTS_NOTIFY_EMAIL = 'newAppointmentNotifyEmail';

export const NEW_REQUEST_VOUCHER = 'newRequestVoucher';

export const NEW_PARTNER_CANCEL_NOTIFY_ZALO = 'newPartnerCancelNotifyZalo';
export const REQUEST_CONVERT_ACCOUNT_CUSTOMER_TO_PARTNER = 'requestConvertAccountCustomerToPartnerEmail';

export const UPDATE_WORKING_TIME = 'updatePartnerWorkingTimeEmail';


export const WH_ERROR_OF_PHARMACY = 'whErrorOfPharmacy';

export const NEW_DELIVERY_COUPON_NOTIFY_ZALO = 'newProductDeliveryVoucherZalo';

export const REMINDER_TO_RETURN_THE_DEVICE_NOTIFY_EMAIL = 'reminderToReturnTheDeviceEmail';
export const REMINDER_TO_RETURN_THE_DEVICE_NOTIFY_ZALO = 'reminderToReturnTheDeviceZalo';
export const resetPassword_ZALO = 'resetPassword_ZALO';

export const jobEmail = 'jobEmail';

export const resetPassword_EMAIL = 'resetPassword_EMAIL';



export const SERVICE_GROUP_TYPES = {
  ONLINE_PHARMACY: 'ONLINE_PHARMACY',
  HOME_HEALTH_CARE: 'HOME_HEALTH_CARE'
};


export const COUPON_DISTRIBUTION_TYPE = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
};
export const TYPE_FILE = {
  EXCEL: 'application/vnd.ms-excel',
  IMAGE: 'image',
  PDF: 'application/pdf',
  POWERPOINT:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  WORD: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
};
export const WH_COURSE_TRAINING_STATUS = {
  NEW: 'NEW',
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED'
};
export const WH_COURSE_TRAINING_STATUS_VI = {
  NEW: 'Mới',
  SUBMITTED: 'Đã submit',
  APPROVED: 'Đã Duyệt',
  DENIED: 'Đã từ chối'
};
export const USERTYPE_REFERRAL = {
  customer_account: 'customer_account',
  wh_partner: 'wh_partner'
};
export const USERTYPE_REFERRAL_VI = {
  customer_account: 'Khách hàng',
  wh_partner: 'Đối tác'
};
export const TYPE_REWARD_ITEM_REFERRAL = {
  point: 'point',
  item: 'item',
  money:'money',
};
export const TYPE_REWARD_ITEM = {
  REFUND: 'REFUND',
  WALLET: 'WALLET'
}
export const TYPE_OF_POINT_REWARD_ITEM_VI = {
  REFUND: 'Điểm thưởng',
  WALLET: 'Điểm rút tiền'
}
export const TYPE_OF_MONEY_REWARD_ITEM_VI = {
  REFUND: 'Tiền thưởng',
  WALLET: 'Tiền rút tiền',
  '':''
}
export const TYPE_REWARD_ITEM_REFERRAL_VI = {
  point: 'Điểm',
  item: 'Vật phẩm',
  money : 'Tiền thưởng',

};
export const TYPE_POINT_SYSTEM_REFERRAL = {
  debit: 'debit',
  credit: 'credit'
};
export const TYPE_POINT_SYSTEM_REFERRAL_VI = {
  debit: 'Điểm trừ',
  credit: 'Điểm cộng'
};
export const ACTOR_REFERRAL = {
  user: 'user',
  partner: 'partner'
};
export const ACTOR_REFERRAL_VI = {
  user : 'Khách hàng',
  partner:'Đối tác'
}
export const CONFIG_CAMPAIGN_ACTIVITY = {
  // REGISTER_CUSTOMER: 'REGISTER_CUSTOMER',
  // REGISTER_PARTNER:'REGISTER_PARTNER',
  REGISTER_SERVICE: 'REGISTER_SERVICE',
    LIMIT_SERVICE:'LIMIT_SERVICE',
    INTRO_REFERRAL:'INTRO_REFERRAL',
    REVENUE_REFERRAL: 'REVENUE_REFERRAL',
    ACCOUNT_BALANCE: 'ACCOUNT_BALANCE',
    ACCOUNT_ACTIVE_BACK: 'ACCOUNT_ACTIVE_BACK',
    CANCEL_SERVICE: 'CANCEL_SERVICE',
    LOT_OF_PRESTIGE_IN_PAYING: 'LOT_OF_PRESTIGE_IN_PAYING',
    VERIFICATION_CARD : "VERIFICATION_CARD"
}
export const CONFIG_CAMPAIGN_ACTIVITY_VI = {
  // REGISTER_CUSTOMER: 'Khách hàng đăng ký',
  // REGISTER_PARTNER:'Đối tác đăng ký',
  REGISTER_SERVICE: 'Đăng kí dịch vụ',
    LIMIT_SERVICE:'Hạn mức sử dụng dịch vụ',
    INTRO_REFERRAL:'Giới thiệu Refferral',
    REVENUE_REFERRAL: 'Doanh thu từ REFERRAL',
    ACCOUNT_BALANCE: 'Số dư tài khoản',
    ACCOUNT_ACTIVE_BACK: 'Tài khoản hoạt động trở lại',
    CANCEL_SERVICE: 'Huỷ dịch vụ',
    LOT_OF_PRESTIGE_IN_PAYING: 'Thiếu uy tín trong thanh toán',
    VERIFICATION_CARD: 'Xác thực CMND/CCCD',
}

// export const CONFIG_CAMPAIGN_ACTIVITY = {
//   // REGISTER_CUSTOMER: 'REGISTER_CUSTOMER',
//   // REGISTER_PARTNER:'REGISTER_PARTNER',
//   REGISTER_SERVICE: 'REGISTER_SERVICE',
//   LIMIT_SERVICE: 'LIMIT_SERVICE',
//   INTRO_REFERRAL_CUSTOMER: 'INTRO_REFERRAL_CUSTOMER',
//   INTRO_REFERRAL_PARTNER: 'INTRO_REFERRAL_PARTNER',
//   REVENUE_REFERRAL: 'REVENUE_REFERRAL',
//   ACCOUNT_BALANCE: 'ACCOUNT_BALANCE',
//   ACCOUNT_ACTIVE_BACK: 'ACCOUNT_ACTIVE_BACK',
//   CANCEL_SERVICE: 'CANCEL_SERVICE',
//   LOT_OF_PRESTIGE_IN_PAYING: 'LOT_OF_PRESTIGE_IN_PAYING',
//   VERIFICATION_CARD: 'VERIFICATION_CARD'
// };

export const CONFIG_CAMPAIGN_CONDITION = {
  RANGE: 'RANGE',
  BOOLEAN: 'BOOLEAN',
  WORTH: 'WORTH',
  REPEAT_TIME: 'REPEAT_TIME',
  REPEAT_ACTION: 'REPEAT_ACTION',
};

export const CONFIG_CAMPAIGN_ACTIVITY_ALL = { // not use in production
//   'DANG-KY-DICH-VU': {
//     value: 'DANG-KY-DICH-VU',
//     vi: 'Đăng kí dịch vụ',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.BOOLEAN
//   },
//   'HAN-MUC-SU-DUNG-DICH-VU': {
//     value: 'HAN-MUC-SU-DUNG-DICH-VU',
//     vi: 'Hạn mức sử dụng dịch vụ',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.RANGE
//   },
//   'GIOI-THIEU-KHACH-HANG': {
//     value: 'GIOI-THIEU-KHACH-HANG',
//     vi: 'Giới thiệu khách hàng',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.RANGE
//   },
//   'GIOI-THIEU-DOI-TAC': {
//     value: 'GIOI-THIEU-DOI-TAC',
//     vi: 'Giới thiệu đối tác',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.RANGE
//   },
//   'DOANH-THU-TU-REFERRAL': {
//     value: 'DOANH-THU-TU-REFERRAL',
//     vi: 'Doanh thu từ REFERRAL',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.RANGE
//   },
//   'SO-DU-TAI-KHOAN': {
//     value: 'SO-DU-TAI-KHOAN',
//     vi: 'Số dư tài khoản',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.RANGE
//   },
//   'TAI-KHOAN-HOAT-DONG-TRO-LAI': {
//     value: 'TAI-KHOAN-HOAT-DONG-TRO-LAI',
//     vi: 'Tài khoản hoạt động trở lại',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.RANGE
//   },
//   'HUY-DICH-VU': {
//     value: 'HUY-DICH-VU',
//     vi: 'Huỷ dịch vụ',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.RANGE
//   },
  'THIEU-UY-TIN-TRONG-THANH-TOAN': {
    value: 'THIEU-UY-TIN-TRONG-THANH-TOAN',
    vi: 'Thiếu uy tín trong thanh toán',
    typeCondition: CONFIG_CAMPAIGN_CONDITION.WORTH,
    // subCondition: [
    //   { key: 'THIEU-UY-TIN-TRONG-THANH-TOAN_sub_1', desc: 'Số ngày chậm', value: 7,typeValue : 'Number' },
    //   { key: 'THIEU-UY-TIN-TRONG-THANH-TOAN_sub_2', desc: 'Giá trị phiếu', value: 10000,typeValue : 'Number' },
    // ]
  },
  'DOI-TAC-HOAN-THANH-DON-HANG': {
    value: 'DOI-TAC-HOAN-THANH-DON-HANG',
    vi: 'Đối tác hoàn thành đơn hàng',
    typeCondition: CONFIG_CAMPAIGN_CONDITION.REPEAT_ACTION,
  },
//   'XAC-THUC-CMND/CCCD/HO-CHIEU': {
//     value: 'XAC-THUC-CMND/CCCD/HO-CHIEU',
//     vi: 'Xác thực CMND',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.BOOLEAN
//   },
//   'XAC-THUC-KHUON-MAT': {
//     value: 'XAC-THUC-KHUON-MAT',
//     vi: 'Xác thực khuôn mặt',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.BOOLEAN
//   },
//   'TANG-QUA-DIP-LE': {
//     value: 'TANG-QUA-DIP-LE',
//     vi: 'Tặng quà dịp lễ',
//     typeCondition: CONFIG_CAMPAIGN_CONDITION.REPEAT
//   },
};

// export const CONFIG_CAMPAIGN_ACTIVITY_VI = {
//   // REGISTER_CUSTOMER: 'Khách hàng đăng ký',
//   // REGISTER_PARTNER:'Đối tác đăng ký',
//   REGISTER_SERVICE: 'Đăng kí dịch vụ',
//   LIMIT_SERVICE: 'Hạn mức sử dụng dịch vụ',
//   INTRO_REFERRAL_CUSTOMER: 'Giới thiệu khách hàng',
//   INTRO_REFERRAL_PARTNER: 'Giới thiệu đối tác',
//   REVENUE_REFERRAL: 'Doanh thu từ REFERRAL',
//   ACCOUNT_BALANCE: 'Số dư tài khoản',
//   ACCOUNT_ACTIVE_BACK: 'Tài khoản hoạt động trở lại',
//   CANCEL_SERVICE: 'Huỷ dịch vụ',
//   LOT_OF_PRESTIGE_IN_PAYING: 'Thiếu uy tín trong thanh toán',
//   VERIFICATION_CARD: 'Xác minh CMND'
// };
export const WH_PARTNER_WAY_WORKING = {
  parttime: 'parttime',
  fulltime: 'fulltime'
};
export const WH_PARTNER_WAY_WORKING_EN = {
  parttime: 'Parttime',
  fulltime: 'Fulltime'
};
export const TYPE_TIPS = {
  reward: 'reward',
  punish: 'punish'
};
export const TYPE_TIPS_VI = {
  reward: 'Thưởng',
  punish: 'Phạt'
};
export const CALCULATE_REPORT = {
  introPartner: (record) => {
    const { totalMoney, snapShortEmployeeGroup, count } = record;
    const { bonusIntro, percentIncomeIntro } = snapShortEmployeeGroup || {};
    const bouns = bonusIntro || 0;
    const percentIncome = percentIncomeIntro || 0;
    return (bouns * count + (totalMoney / 1.1) * (percentIncome / 100)).toFixed(
      0
    );
  },
  totalSaleFullTime: (record) => {
    const { introPartner, trainingSystem, extraIncome, kpi } = record;
    return introPartner + trainingSystem + extraIncome + kpi;
  },
  totalSalePartTime: (record) => {
    const { introPartner, trainingSystem, extraIncome, didService } = record;
    return introPartner + trainingSystem + extraIncome + didService;
  }
};
export const WH_SERVICE_TYPESHOW = {
  TIMES: 'TIMES',
  MINUTES: 'MINUTES',
  SESSION: 'SESSION',
};
export const WH_SERVICE_TYPESHOW_VI = {
  TIMES: 'Lần',
  MINUTES: 'Phút',
  SESSION: 'Buổi',
  
};
export const TYPE_RANKING_REFERRAL = {
  member: 'member',
  copper: 'copper',
  silver: 'silver',
  Yellow: 'Yellow',
  platinum: 'platinum',
  diamond: 'diamond',
  rhodium: 'rhodium'
};
export const TYPE_RANKING_REFERRAL_VI = {
  member: 'Thành viên',
  copper: 'Đồng',
  silver: 'Bạc ',
  Yellow: 'Vàng',
  platinum: 'Bạch kim',
  diamond: 'Kim cương',
  rhodium: 'Tinh tuý'
};

export const CONFIG_CAMPAIGN_CONDITION_VI = {
  RANGE: 'Khoảng', //
  BOOLEAN: 'Xảy ra 1 lần',
  WORTH: 'Giá trị chính xác',
  REPEAT_TIME:'Thời gian lặp lại',
  REPEAT_ACTION : 'Hành động lặp lại'
};
export const CONFIG_CAMPAIGN_UNIT = {
  MILLION: 'MILLION',
  HUNDRED_THOUSAND: 'HUNDRED_THOUSAND',
  THOUSAND: 'THOUSAND',
  TIMES: 'TIMES',
  HOUR: 'HOUR',
  MINUTE: 'MINUTE',
  SECOND: 'SECOND',
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
};
export const CONFIG_CAMPAIGN_UNIT_VI = {
  // MILLION: 'Triệu',
  // HUNDRED_THOUSAND: 'Trăm nghìn',
  // THOUSAND: 'Nghìn',
  VNĐ:"VNĐ",
  TIMES: 'Lần',
  HOUR: 'Giờ',
  MINUTE: 'Phút',
  SECOND: 'Giây',
  DAY: 'Ngày',
  MONTH: 'Tháng',
  YEAR: 'Năm',
  PEOPLE: 'Người',
  ITEM:"Cái"
};
export const CONFIG_CAMPAIGN_UNIT_CHANGE = {
  MILLION: (input) => input * 1000000,
  HUNDRED_THOUSAND: (input) => input * 100000,
  THOUSAND: (input) => input * 1000,
  TIMES: (input) => input,
  VNĐ: (input) => input,
  HOUR: (input) => input,
  MINUTE: (input) => input,
  SECOND: (input) => input,
  DAY: (input) => input,
  MONTH: (input) => input,
  YEAR: (input) => input,
  PEOPLE: (input) => input,
  ITEM: (input) => input,
};
export const CONFIG_CAMPAIGN_UNIT_UN_CHANGE = {
  MILLION: (input) => input / 1000000,
  HUNDRED_THOUSAND: (input) => input / 100000,
  THOUSAND: (input) => input / 1000,
  TIMES: (input) => input,
  VNĐ: (input) => input,
  HOUR: (input) => input,
  MINUTE: (input) => input,
  SECOND: (input) => input,
  DAY: (input) => input,
  MONTH: (input) => input,
  YEAR: (input) => input,
  PEOPLE: (input) => input,
  ITEM: (input) => input,
};
export const CONFIG_CAMPAIGN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const STATUS_GIVE_COUPON = {
  ACTIVE:'ACTIVE',
  INACTIVE:'INACTIVE'
}

export const SERVICE_GROUP_TYPES_VI = {
  ONLINE_PHARMACY: 'Nhà thuốc online',
  HOME_HEALTH_CARE: 'World Health',
};
export const PRIORITY_KEYWORD_SEARCH = {
  "4" : 'Cao nhất',
  "3" : 'Cao',
  "2" : 'Vừa',
  "1" : 'Bình thường',
}
export const KEYWORD_SEARCH_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}
export const MAX_VALUE_CONFIG_CAMPAIGN = 9999999999999

export const TRANSFER_POINT_TO_MONEY = 1000;

export const ROLE_REF = {
  "WH-PARTNER" : {
    vi : "Đối tác",
    en : "WH-PARTNER"
  },
  "CUSTOMER" : {
    vi : "Khách hàng",
    en : "CUSTOMER"
  },
}


export const TYPE_CATEGORY = {
  NORMAL : {value : 'NORMAL',vi : 'Thường'},
  CSNB : {value : 'CSNB',vi : 'Chăm sóc nuôi bệnh'},
}

export const PLACE_DID_SERVICE = {
  hospital : {value : 'hospital',vi : "Bệnh viện",en : "Hospital"},
  home : {value : 'tai-nha',vi : 'Tại nhà',en : 'At Home'},
}
export const REQUEST_VOUCHER_STATUS = {
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
}; 
export const REQUEST_VOUCHER_STATUS_VI = {
  NEW: 'Mới',
  COMPLETED: 'Đã duyệt',
  CANCELLED: 'Đã huỷ',
}; 
// wallet
export const WALLET_TYPE = {
  CASH:'CASH',
  REFUND:'REFUND'
}

export const WALLET_ACTION = {
  PAY_IN:'PAY_IN',
  PAY_OUT:'PAY_OUT'
}

export const WALLET_STATUS = {
  COMPLETED : 'COMPLETED',
  PENDING:'PENDING',
  CANCEL:'CANCEL'
}
export const WALLET_STATUS_VI = {
  COMPLETED : 'Đã hoàn thành',
  PENDING:'Đang chờ duyệt',
  CANCEL:'Đã huỷ'
}
export const WALLET_REFCOLLECTION = {
  customer_account: 'customer_account',
  wh_partner:'wh_partner'
}
export const WH_PAYMENT_VOUCHER_ACTION = {
  PAYMENT_BILL:'PAYMENT_BILL',
  PAYMENT_SALARY:'PAYMENT_SALARY',
  PAYMENT_WALLET:'PAYMENT_WALLET'
}
export const REQUEST_VOUCHER_REFCOLLECTION = {
  customer_account : 'customer_account',
  wh_partner : 'wh_partner'
}
export const REQUEST_VOUCHER_TYPE = {
  PT: 'PT',
  PC: 'PC'
}

export const WITH_DRAW_MONEY_STATUS = {
  NOT_ABLE_TO_GET_CASH: 'NOT_ABLE_TO_GET_CASH',
  CASH_OUT: 'CASH_OUT'
}
export const WITH_DRAW_MONEY_STATUS_VI = {
  NOT_ABLE_TO_GET_CASH: 'Điểm thưởng',
  CASH_OUT: 'Điểm rút tiền'
}

export const CONFIG_CAMPAIGN_REWARD_ITEM_VI = {
  point: 'Điểm thưởng',
  item: 'Vật phẩm'
};

export const queryFetchAll = {
  page : 1,
  limit : MAX_PAGINATION_LIMIT
}

export const WAREHOUSE_STATUS_KEY = {
READY: 'READY',
ASSIGNED: 'ASSIGNED',
PROCESSING: 'PROCESSING',
UNREADY: 'UNREADY',
DELIVERY: 'DELIVERY'
}
export const WAREHOUSE_STATUS = {
  READY: {  name: "Sẵn sàng",  colorStyle: "#52c41a" , value: "READY"},
  ASSIGNED: {  name: "Đã bàn giao",  colorStyle: "#6096B4",value : "ASSIGNED"},
  PROCESSING: {  name: "Đang sử dụng",  colorStyle: "#1890ff", value: "PROCESSING"},
  UNREADY: {  name: "Ngưng sử dụng",  colorStyle: "#f5222d",value : "UNREADY"},
  DELIVERY: {  name: "Đang luân chuyển",  colorStyle: "#f5222d",value : "DELIVERY"},
}

export const ACTIVE_KEY = {
ACTIVE: 'ACTIVE',
INACTIVE: 'INACTIVE',
}
export const ACTIVE_WAREHOUSE = {
  ACTIVE: {  name: "Sẵn sàng",  colorStyle: "#52c41a"},
  INACTIVE: {  name: "Không sẵn sàng",  colorStyle: "#f5222d"},
}
export const STATUS_WAREHOUSE = {
  ACTIVE: {name:'Đang sử dụng',colorStyle: "#52c41a",value: "ACTIVE"},
  INACTIVE: {name:'Ngưng sử dụng',colorStyle: "#f5222d",value: "INACTIVE"},
}
export const TYPE_PORTFOLIO = {
  MEDICAL_MATERIALS : "medical materials",
  EQUIPMENT : "EQUIPMENT"
}

export const TYPE_VOUCHER_WAREHOUSE = {
  PBG : 'PBG',
  PHT: 'PHT'
}

export const TYPE_VOUCHER_WAREHOUSE_VI = {
  PBG : 'Phiếu bàn giao',
  PHT: 'Phiếu hoàn trả'
}
export const TYPE_DELIVERY_WAREHOUSE_PRODUCT = {
  PXP:'Phiếu xuất ',
  PNP:'Phiếu nhận'
}

export const TYPE_VOUCHER_WAREHOUSE_DOWNLOAD_VI = {
  PBG : 'Phieubangiao',
  PHT: 'Phieuhoantra'
}

export const REF_COLLECTION_VOUCHER_WAREHOUSE = {
  wh_partner: 'wh_partner',
  // customer_account:'customer_account',
  branch:'branch',
}
export const REF_COLLECTION_VOUCHER_WAREHOUSE_VI = {
  wh_partner: 'Đối tác',
  // customer_account:'Khách hàng(Not implemented)'
  // branch:'branch',
}

export const TYPE_USE_WAREHOUSE_VOUCHER = {
  wh_bill : 'wh_bill',
  refund : 'refund',
}

export const TYPE_WAREHOUSE = { // dont Change Please (THIS IS CORE)
  WORLDHEALTH : "WORLDHEALTH",
  CLINIC : "CLINIC",
}

export const TYPE_WAREHOUSE_VI = { // dont Change Please (THIS IS CORE)
  WORLDHEALTH : "Công cụ dụng cụ",
  CLINIC : "Nguyên vật liệu",
}

export const MODE_VOUCHER_WAREHOUSE = {
  EDIT : "EDIT",
  VIEW : "VIEW",
  CREATE : "CREATE",
}

export const UNIT_PROVIDE = {
  worldhealth  :"worldhealth"
}

export const ACTION_LIST_PRODUCT = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const CATEGORY_TYPE_CLINIC = {
  PRODUCT : {vi : "Hàng hoá",value : "PRODUCT"},
  MEDICINE : {vi : "Thuốc",value : "MEDICINE"},
}

export const ACTION_ASSIGN_EQUIPMENT= {
  refund : 'refund'
}
export const DEPOSIT_THRESHOLDS = [0.2, 0.3, 0.5 , 1];

export const NUMBER_OF_DIGITS_TO_ROUND = 1;

export const TYPE_REWARD_POINTSYSTEM = {
  wallet : {value : 'wallet',vi : 'Ví'},
  pointSystem : {value : 'pointSystem',vi : 'Điểm thưởng'},
}

export const WALLET_ACTION_NAME = {
  PAY_IN: {vi : 'Tiền vào ví',value : 'PAY_IN'},
  PAY_OUT: {vi : 'Tiền ra ví',value : 'PAY_OUT'}
};

export const ADDITIONAL_VOUCHER = {
  whBillItem : 'whBillItem',
}

export const TYPE_COUPON = {
  PRIVATE : "PRIVATE",
  PUBLIC : "PUBLIC",
}

export const WH_LIST_PRODUCT_ACTION = {
  PRE_BOOKING: {
    value : 'PRE_BOOKING',
    name : 'Đặt trước',
    color : '#fec230',
    desc:'Thiết bị đặt trước cho buổi hẹn'
  },
  CONFIRMED: {
    value : 'CONFIRMED',
    name : 'Đã xác nhận sử dụng',
    color : '#35D2B9',
    desc : 'Thiết bị đã được xác nhận phiếu sử dụng thiết bị',
  },
  USED: {
    value : 'USED',
    name : 'Đã sử dụng',
    color : '#C496EF',
    desc : 'Thiết bị đã được sử dụng',
  },

  ASSIGNED: {
    value : 'ASSIGNED',
    name : 'Đã bàn giao',
    color : '#1890ff',
    desc : 'Thiết bị đã được tạo phiếu bàn giao (Chưa xác nhận phiếu bàn giao)',
  },

  DELIVERED: {
    value : 'DELIVERED',
    name : 'Đã xuất kho',
    color : '#e40017',
    desc : 'Thiết bị đã được xuất kho(Đã xác nhận phiếu bàn giao)',
  },
  RETURNED: {
    value : 'RETURNED',
    name : 'Đã hoàn trả',
    color : '#52C41A',
    desc : 'Thiết bị đã được hoàn trả về kho',
  },
}


export const STATUS_PARTNER_CANCEL_BILL = {
  CREATE : {value : "CREATE" , vi : "Mới tạo",disabled: ["APPROVED","REFUSE"]},
  APPROVED : {value : "APPROVED" , vi : "Đã hoàn thành",disabled: ["REFUSE","CREATE"]},
  REFUSE : {value : "REFUSE" , vi : "Từ chối",disabled: ["APPROVED","CREATE"]},
  PROCESSING : {value : "PROCESSING" , vi : "Đang xử lý",disabled: ["APPROVED","REFUSE"]},

}

export const TOTAL_HOURS_TO_LATE = 4

export const TYPE_CANCEL_PARTNER = {
  bill: {value : "bill" , vi : "Dịch vụ"},
  block: {value : "block" , vi : "block"},
  appointment: {value : "appointment" , vi : "Cuộc hẹn"},
}
export const MIN_PERCENT_BILL_DEPOSIT = 45; //set MinPercent bill remaining

export const PERCENT_CHANGE_STATE = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};


export const FORMAT_DATE_TIME = {
  YYYYMMDDHHmmss : "YYYY-MM-DD HH:mm:ss",
  YYYYMMDDHHmm : "YYYY-MM-DD HH:mm",
  YYYYMMDD : "YYYY-MM-DD",
  DDMMYYYYHHmmss:"DD-MM-YYYY HH:mm:ss",
}


export const TYPE_ACTION_PERMISSIONS = {
  UPDATE : {vi : "Cập nhật",value : "UPDATE"},
  DELETE : {vi : "Xoá",value : "DELETE"},
  UNKNOW : {vi : "",value : "UNKNOW"}
}


export const KIND_VOUCHER_WAREHOUSE = {
  SINGLE_APPOINTMENT : "SINGLE_APPOINTMENT",
  MULTIPLE_APPOINTMENT : "MULTIPLE_APPOINTMENT",
  WHBILL : "WHBILL",
  WHBILLITEM : "WHBILLITEM",
  BLOCK : "BLOCK",
}
export const MAP_WH_BILL_STATUS_TAG = {
  [WH_BILL_STATUS_EN.NEW]: { name: WH_BILL_STATUS.NEW, color: 'default',colorStyle : 'orange' },
  [WH_BILL_STATUS_EN.PREPAYMENT_CONFIRMED]: { name: WH_BILL_STATUS.PREPAYMENT_CONFIRMED, color: 'processing' ,colorStyle : '#1890ff'},
  [WH_BILL_STATUS_EN.IN_PROGRESS]: { name: WH_BILL_STATUS.IN_PROGRESS, color: 'geekblue',colorStyle : '#1d39c4' },
  [WH_BILL_STATUS_EN.COMPLETED]: { name: WH_BILL_STATUS.COMPLETED, color: 'success',colorStyle : '#52c41a' },
  [WH_BILL_STATUS_EN.CUSTOMER_CANCEL]: { name: WH_BILL_STATUS.CUSTOMER_CANCEL, color: 'default',colorStyle : 'rgba(0, 0, 0, 0.85)' },
  [WH_BILL_STATUS_EN.CANCELLED]: { name: WH_BILL_STATUS.CANCELLED, color: 'error',colorStyle : '#f5222d' },
};

export const KIND_WH_PARTNER = {
  internalPartner: 'internalPartner',
  individualPartner: 'individualPartner', 
  legalEntityPartner: 'legalEntityPartner'  
}

export const KIND_WH_PARTNER_VI = {
  internalPartner: 'Nội bộ',
  individualPartner: 'Thể nhân', 
  legalEntityPartner: 'Pháp nhân',
}
  
export const REDIRECT = {
  unauthorized : PATH_APP.errorAccess.unauthorized,
  pageNotFound : PATH_APP.errorAccess.pageNotFound
}

export const STATUS_SERVICE_BRANCH = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const STATUS_SERVICE = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const STATUS_DELIVERY_PRODUCT = {
  OUT: 'Đang luân chuyển',
  IN: ''
};

export const STATUS_DELIVERY_PRODUCT_KEY = {
  IN_PROGRESS: {
    name: 'Đang luân chuyển',
    color: '#FFB000',
  },
  COMPLETED: {
    name: 'Đã hoàn thành',
    color: '#8BC34A'
  },
  CANCEL: {
    name: 'Đã huỷ',
    color: '#F94C10'
  },
  NEW: {
    name: 'Mới tạo',
    color: '#068FFF'
  },
};

export const TASK_ITEM_STATUS = {
  NEW: 'NEW',
  CONFIRMED: 'CONFIRMED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  ON_HOLD: 'ON_HOLD',
  REJECTED: 'REJECTED',
}
export const BOARD_STATUS={
  DEFAULT: {
    value:'Mặc định',
    name:'DEFAULT',
  },
  MAKETING: {
    value:'Marketing',
    name:'MAKETING',
  },
  CLINIC: {
    value:'Phòng khám',
    name:'CLINIC',
  }
}
export const TASK_ITEM_STATUS_NAME = {
  NEW: {
    value:'Mới',
    bg:'#1198AD',
    color:'white',
    name:'NEW',
  },
  CONFIRMED: {
    value:'Đã xác nhận',
    bg:'#42496F',
    color:'white',
    name:'CONFIRMED',
  },
  IN_PROGRESS: {
    value:'Đang thực hiện',
    bg:'#70E85D',
    color:'black',
    name:'IN_PROGRESS',
  },
  COMPLETED: {
    value:'Đã hoàn thành',
    bg:'#FFEA00',
    color:'black',
    name:'COMPLETED',
  },
  ON_HOLD: {
    value:'Đang chờ',
    bg:'#FF6200',
    color:'black',
    name:'ON_HOLD',
  },
  REJECTED: {
    value:'Từ chối',
    bg:'#DEE2E6',
    color:'black',
    name:'REJECTED',
  },
}

export const TASK_ITEM_PRIORITY = {
  LOW: 'LOW',
  NORMAL: 'NORMAL',
  HIGH: 'HIGH',
  IMMEDIATE: 'IMMEDIATE',
}
export const TASK_ITEM_PRIORITY_NAME = {
  LOW:'Thấp',
  NORMAL:'Trung bình',
  HIGH:'Cao',
  IMMEDIATE:'Ngay lập tức',
}
export const SPENT_TIME_ACTIVITY = {
  Management: 'Management',
  Specification: 'Specification',
  Development: 'Development',
  Testing: 'Testing',
  Support: 'Support',
  Other: 'Other'
}
export const SPENT_TIME_ACTIVITY_NAME = {
  Management: 'Quản lý',
Specification: 'Xác định',
Development: 'Phát triển',
Testing: 'Kiểm thử',
Support: 'Hỗ trợ',
Other: 'Khác'
}
export const HISTORY_TASK_ITEM_TYPE={
  assignUser:'thêm thành viên',
  status:'thay đổi trạng thái',
  description:'ghi chú',
  progressList:'danh sách công việc',
  'progressList.check':'trạng thái công việc',
  'progressList.content':'nội dung công việc',
  'progressList.assgin':'chỉ định công việc',
}

export const BOARD_SECURITY = {
  public:'Công khai',
  private:'Riêng tư',
}
// export const TASK_ITEM_TYPES ={

// }
export const ROOT_COMPANYID = 99999

export const KEY_SYSTEM = {
  VER_IOS : "VER_IOS",
  VER_ANDROID : "VER_ANDROID",
}

export const KEY_SYSTEM_VI = {
  VER_IOS : "Ios",
  VER_ANDROID : "Android",
}

export const SOURCE_BOOKING = {
  WEBSITE: 'Website',
  CALL: 'Gọi điện'
};
export const TYPE_PAYMENT_VOUCHER_CUSTOMER = {
  CANCELLED: 'PAY_CUSTOMER_CANCEL',
  SUCCESS: 'SUCCESS'
};
export const TYPE_PAYMENT_VOUCHER_CUSTOMER_VI = {
  PAY_CUSTOMER_CANCEL: 'Buổi hẹn đã huỷ',
  SUCCESS: 'Buổi hẹn đã thành công'
};

export const TASK_ITEM_TYPE_REQUEST = {
  add: 'add',
  remove: 'remove',
}

export const WH_APPOINTMENT_GIVE_AWAY = 'GIVE_AWAY';
export const MIN_BILL_TO_HAVE_PREPAYMENT = 1000000;
export const requireRules = [
  {
      required : true,
      message : "Vui lòng nhập"
  }
];

export const ROLE_HIGHTLIGHT = {
  SOFTWARE : "SOFTWARE",
  HEALTH : "HEALTH",
}
export const ROLE_HIGHTLIGHT_VI = {
  SOFTWARE : "Phần mềm",
  HEALTH : "Chăm sóc sức khoẻ",
}
export const ROLE_HIGHTLIGHT_EN = {
  SOFTWARE : "Software",
  HEALTH : "Health",
}
