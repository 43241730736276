import * as Types from '~/constants/actionTypes';

export const getCoupons = (query) => ({
  type: Types.GET_COUPONS_REQUEST,
  payload: query
});

export const getCoupon = (id) => ({
  type: Types.GET_COUPON_REQUEST,
  payload: id
});

export const createCoupon = (coupon) => ({
  type: Types.CREATE_COUPON_REQUEST,
  payload: coupon
});

export const updateCoupon = (coupon) => ({
  type: Types.UPDATE_COUPON_REQUEST,
  payload: coupon
});

export const deleteCoupon = (id) => ({
  type: Types.DELETE_COUPON_REQUEST,
  payload: id
});

export const resetCouponState = () => ({
  type: Types.RESET_COUPON_STATE
});
export const resetCouponActionState = () => ({
  type: Types.RESET_COUPON_STATE_ACTION
});

export const getCouponServiceCategories = () => ({
  type: Types.GET_COUPON_SERVICE_CATEGORY_REQUEST
})
