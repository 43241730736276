import requester from './requester';

const pharmacyOnlineDashboard = {
  getPharmacyOverViewReport: () => requester.get('/order-report/overview'),

  getPharmacyOverviewToday: () => requester.get('/chart-dashboard-pharmacy/overviewToday'),
  getPharmacyOverviewDaily: () => requester.get('/chart-dashboard-pharmacy/overviewDaily'),
  getPharmacyOverviewMonthly: () => requester.get('/chart-dashboard-pharmacy/overviewMonthly'),
  getPharmacyOverviewQuarterly: () => requester.get('/chart-dashboard-pharmacy/overviewPrecious'),
  getPharmacyOverviewYearly: () => requester.get('/chart-dashboard-pharmacy/overviewYearly'),

  getPharmacyDailyReport: () => requester.get('/order-report/daily'),
  getPharmacyMonthlyReport: () => requester.get('/order-report/monthly'),
  getPharmacyQuarterlyReport: () => requester.get('/order-report/precious'),
  getPharmacyYearlyReport: () => requester.get('/order-report/yearly'),
  getPharmacyMostCustomerOrderReport: () => requester.get('/order-report/mostCustomerOrder')
};

export default pharmacyOnlineDashboard;
