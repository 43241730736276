import { forIn, get, groupBy, keys } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getSelectors,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createActivityReferral,
  deleteActivityReferral,
  getActivityReferral,
  getActivityReferrals,
  resetActivityReferralState,
  updateActivityReferral
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.activityReferral[key];
const pagingSelector = getSelector('paging');
export const useActivityReferralPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetActivityReferralSelector = getSelector('isGetActivityReferralLoading');
const getActivityReferralsFailedSelector = getSelector('getActivityReferralsFailed');
const getActivityReferralFailedSelector = getSelector('getActivityReferralFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const ActivityReferralsSelector = getSelector('ActivityReferrals');
const ActivityReferralSelector = getSelector('ActivityReferral');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useActivityReferralQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const vi = query.get('vi');
  const value = query.get('value');

  const createActivityReferralSuccess = useSelector(createSuccessSelector);
  const updateActivityReferralSuccess = useSelector(updateSuccessSelector);
  const deleteActivityReferralSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      vi,
      value
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    createActivityReferralSuccess,
    updateActivityReferralSuccess,
    deleteActivityReferralSuccess,
    vi,
    value,
  ]);
};

export const useUpdateActivityReferralParams = (query,listOptionSearch) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    const groupByKey = groupBy(listOptionSearch , (e) => get(e,'value'))
    forIn(query,(values,key,obj) =>{
      if(groupByKey[key] && (keys(param)?.some(e => groupByKey[e]))){
        obj[key] = null
      }
    })
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/activity-config-campaign',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};
export const useActivityReferrals = (query) => {
  return useFetchByParam({
    action: getActivityReferrals,
    loadingSelector,
    dataSelector: ActivityReferralsSelector,
    failedSelector: getActivityReferralsFailedSelector,
    param: query
  });
};
export const useActivityReferral = (id) => {
  return useFetchByParam({
    action: getActivityReferral,
    loadingSelector: loadinggetActivityReferralSelector,
    dataSelector: ActivityReferralSelector,
    failedSelector: getActivityReferralFailedSelector,
    param: id
  });
};
export const useCreateActivityReferral = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo Hoạt động chiến dịch thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createActivityReferral
  });
};
export const useUpdateActivityReferral = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật Hoạt động chiến dịch thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateActivityReferral
  });
};
export const useDeleteActivityReferral = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Hoạt động chiến dịch thành công', callback);
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteActivityReferral
  });
};

export const useResetActivityReferral = () => {
  useResetState(resetActivityReferralState);
};
