import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { get, head, keys, uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Breadcrumb } from '~/components/Common';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import ExpandableVoucher from '~/components/WareHouse/ConfirmVoucherWarehouse/ExpandableVoucher';
import FormConfirmVoucherWarehouse from '~/components/WareHouse/ConfirmVoucherWarehouse/FormConfirmVoucherWarehouse/FormConfirmVoucherWarehouse';
import { CONFIRM_VOUCHER_STATUS, CONFIRM_VOUCHER_STATUS_VI } from '~/constants/confirmVoucherWarehouse';
import { REF_COLLECTION_VOUCHER_WAREHOUSE, TYPE_VOUCHER_WAREHOUSE, TYPE_VOUCHER_WAREHOUSE_VI } from '~/constants/defaultValue';
import { useUserWorkspace } from '~/hooks';
import { useConfirmVoucherWarehousePaging, useConfirmVoucherWarehouseQueryParams, useConfirmVoucherWarehouses, useConfirmVoucherWarehousesWhPartner, useResetConfirmVoucherWarehouse, useUpdateConfirmVoucherWarehouseParams } from '~/hooks/confirmVoucherWarehouse';
import {useHistory} from 'react-router-dom'

const listOptionSearch = [
  {
    value: 'codeSequence',
    label: 'Mã phiếu'
  },
  // {
  //   value: 'employee',
  //   label: 'Người tạo phiếu'
  // },
  {
    value: 'whPartner',
    label: 'Đối tác'
  },
  {
    value: 'note',
    label: 'Ghi chú'
  },
  {
    value: 'whBill',
    label: 'Mã đơn hàng'
  },
  {
    value: 'whAppointment',
    label: 'Mã cuộc hẹn'
  },
];
export default function TabConfirmVoucher() {
  const [profile] = useUserWorkspace();
  const history = useHistory()
 
  const [selectSearch, setSelectSearch] = useState(
    head(listOptionSearch).value
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [id, setId] = useState(null);
  const handleOpenModal = (id) => {
    setIsOpenModal(true);
    if (id) {
      setId(id);
    }
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setId(null);
  };
  // hook

  const [query] = useConfirmVoucherWarehouseQueryParams(get(profile,'_id'));
  const [confirmVoucherWarehouse, isLoading] = useConfirmVoucherWarehousesWhPartner(query);
  const paging = useConfirmVoucherWarehousePaging();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateConfirmVoucherWarehouseParams(query, listOptionSearch);
  useResetConfirmVoucherWarehouse();
  //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('');
  };
  ////////////////////////////////
  // useEffect(() => {
  //   const { status } = query
  //   form.setFieldsValue({ status: status || null })
  // }, [query]);
  useEffect(() => {
    let value = listOptionSearch?.find(e => query[e.value])
    setSelectSearch(get(value, 'value', head(listOptionSearch).value))
    setKeyword(query[get(value, 'value', '')])
  }, [])
  const onValuesChange = (value, allValues) => {
    onParamChange({ ...allValues, ...value })
  }
  const handleClickAppointment = (record) => {
    history.push({
      pathname :'/workspace/manageTasks',
      state : { // pass state to open colapse
        whBillItemId : get(record,'appointment.whBillItemId','') 
      }
    })
  }
  const columns = [
    {
      title: 'Mã phiếu',
      dataIndex: 'codeSequence',
      key: 'codeSequence',
      width : 150,
      align: 'center',
      render: (item, record, index) => <div className='d-flex flex-column align-items-center'>
        <Typography.Link onClick={() => handleOpenModal(record._id)}>{item}</Typography.Link>
        <span className={`confirm-voucher-status ${get(record,'status').toLowerCase()}`}>
      {CONFIRM_VOUCHER_STATUS_VI[get(record,'status')]}
    </span>
    <p>{ moment(get(record,'createdAt')).format("DD-MM-YYYY HH:mm:ss")}</p>
      </div>

    },
  
    
    // {
    //   title: 'Người tạo phiếu',
    //   dataIndex: 'User',
    //   key: 'User',
    //   render: (item, record) => get(item, 'fullName', '')
    // },
    // {
    //   title: 'Ghi chú',
    //   dataIndex: 'note',
    //   key: 'note',
    //   width : '100px',
    // },
    // {
    //   title: 'Mã cuộc hẹn',
    //   dataIndex: 'appointmentIds',
    //   key: 'appointmentIds',
    //   align : 'center',
    //   render: (item, record, index) => {
    //     return uniqBy(item,(e) => get(e,'appointmentId'))?.map(whAppointment => {
    //         return <p>
    //            <Typography.Link onClick={() => handleClickAppointment(whAppointment)} strong>{get(whAppointment,'appointment.code','')}</Typography.Link>
    //         </p>
    //     })
      
    //   }
    // },
  ];
  return (
    <div>
        <div className="page-content__main">
          {/* <div className="page-content__left">
            <Form
              form={form}
              autoComplete="off"
              onFinish={() => { }}
              scrollToFirstError
              onValuesChange={onValuesChange}
              requiredMark={false}
              labelCol={{ sm: 24, md: 24, lg: 24 }}
            >
                <Form.Item label='Trạng thái' name='status'>
                <Select allowClear options={keys(CONFIRM_VOUCHER_STATUS)?.map(option => ({ label: CONFIRM_VOUCHER_STATUS_VI[option], value: option }))} />
              </Form.Item>
            </Form>
          
          </div> */}
          <div className="page-content__right" style={{ flex : 1 , padding : 'unset' }}>
            <div
              className="page-wraper__header"
              style={{ justifyContent: 'space-between', display: 'flex' }}
            >
              <div>
                <Select
                  value={selectSearch}
                  style={{
                    width: 170
                  }}
                  onChange={handleChangeSelectSearch}
                  options={listOptionSearch}
                />

                <Search
                  style={{ width: 300 }}
                  placeholder={`Tìm ${get(listOptionSearch.find((e) => e.value === selectSearch), 'label', '')
                    }`}
                  enterButton
                  allowClear
                  onSearch={(value) => {
                    onParamChange({ [selectSearch]: value });
                  }}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />

              </div>
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={confirmVoucherWarehouse}
                size="small"
                onChange={({ current }) => onParamChange({ page: current })}
                // scroll={{x : 1000}}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                // onExpand={(expanded, record) => {
                //   expanded ? setItemActive(record._id) : setItemActive(null);
                // }}
                // expandable={{
                //   expandedRowRender: (record) => <ExpandableVoucher dataSource={get(record,'listProduct',[])}/>,
                //   expandedRowKeys: [itemActive]
                // }}
              />
            )}
          </div>
        </div>
      <Modal
        width={'auto'}
        visible={isOpenModal}
        footer={null}
        onCancel={handleCloseModal}
      >
        <FormConfirmVoucherWarehouse handleByPartner id={id} onCancel={handleCloseModal}/>
      </Modal>
    </div>
  );
}
