import * as Types from '../../constants/actionTypes';
  
  export const getMyNotifications = query => ({
    type: Types.GET_MY_NOTIFICATIONS_REQUEST,
    payload: query
  });

  export const updateStatusNotification = payload => ({
    type: Types.CHANGE_STATUS_NOTIFICATION_REQUEST,
    payload
  });

  export const updateManyStatusNotification = payload => ({
    type: Types.CHANGE_MANY_STATUS_NOTIFICATION_REQUEST,
    payload
  });
  
  export const resetMyNotificationState = () => ({
    type: Types.RESET_MY_NOTIFICATION_STATE
  });
