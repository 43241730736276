import {
  CheckCircleTwoTone,
  CheckSquareTwoTone,
  CloseCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  PlusCircleTwoTone
} from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '~/api';
import { WH_COURSE_TRAINING_STATUS } from '~/constants/defaultValue';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createTrainingCoursePartner,
  deleteTrainingCoursePartner,
  getNoteByIdPartner,
  getTrainingCoursePartner,
  getTrainingCoursesOfPartner,
  getTrainingCoursesOfPartnerReady,
  getTrainingCoursesPartner,
  resetTrainingCoursePartnerState,
  submitTrainingCoursePartner,
  updateTrainingCoursePartner
} from '~/redux/action';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.trainingCoursePartner[key];
const pagingSelector = getSelector('paging');
export const useTrainingCoursePartnerPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadingSelectorTrainingCourse = getSelector('isLoadingTrainingCourse');
//
const loadingReadySelector = getSelector('isLoadingReady');
const trainingCourseReadySelector = getSelector('TrainingCourseReady');
const getTrainingCoursesFailedFailedSelector = getSelector(
  'getTrainingCoursesReadyFailed'
);
//
const loadingSelectorTrainingCoursePartner = getSelector(
  'isGetTrainingCoursePartnerLoading'
);
const trainingCoursePartnerSelector = getSelector('trainingCoursePartner');

const getTrainingCoursesPartnerFailedSelector = getSelector(
  'getTrainingCoursesPartnerFailed'
);
const getTrainingCoursePartnerFailedSelector = getSelector(
  'getTrainingCoursePartnerFailed'
);
const getTrainingCoursesOfPartnerFailedSelector = getSelector(
  'getTrainingCoursesOfPartnerFailed'
);
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const trainingCoursesPartnerSelector = getSelector('trainingCoursesPartner');
const trainingCoursesOfPartnerSelector = getSelector(
  'trainingCoursesOfPartner'
);

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const submitSuccessSelector = getSelector('submitSuccess');
const submitFailedSelector = getSelector('submitFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const loadingNoteSelector = getSelector('loadingNote');
const notes = getSelector('notes');
const getNoteFailedSeletor = getSelector('getNoteFailed');
export const useTrainingCoursePartnerQueryParams = (_id) => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const month = query.get('month');
  const date = query.get('date');
  const createTrainingCorseSuccess = useSelector(createSuccessSelector);
  const updateTrainingCorseSuccess = useSelector(updateSuccessSelector);
  const deleteTrainingCorseSuccess = useSelector(deleteSuccessSelector);
  const submitTrainingCorseSuccess = useSelector(submitSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      month,
      _id,
      date
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    createTrainingCorseSuccess,
    updateTrainingCorseSuccess,
    deleteTrainingCorseSuccess,
    submitTrainingCorseSuccess,
    month,
    _id,
    date
  ]);
};
export const useTrainingCourseReadyPartnerQueryParams = (id) => {
  const createTrainingCorseSuccess = useSelector(createSuccessSelector);
  const updateTrainingCorseSuccess = useSelector(updateSuccessSelector);
  const submitTrainingCorseSuccess = useSelector(submitSuccessSelector);
  return useMemo(() => {
    const queryParams = {
      id,
      createTrainingCorseSuccess,
      updateTrainingCorseSuccess,
      submitTrainingCorseSuccess
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    id,
    updateTrainingCorseSuccess,
    createTrainingCorseSuccess,
    submitTrainingCorseSuccess,
  ]);
};
export const useFetchCourseReadyPartner = (query) => {
  const [data, setData] = useState({
    data: [],
    loading: false
  });
  useEffect(() => {
    const fetch = async () => {
      setData({
        data: [],
        loading: true
      });
      const res = await api.TrainingCoursePartner.getAllCourseReadySubmit(
        query.id
      );
      setData({
        data: res,
        loading: false
      });
    };
    fetch();
  }, [query]);
  return [get(data,'data',[]),get(data,'loading',[])];
};
export const useLogCourseTraining = (id) => {
  // get log by Id partner
  const createTrainingCorseSuccess = useSelector(createSuccessSelector);
  const updateTrainingCorseSuccess = useSelector(updateSuccessSelector);
  const deleteTrainingCorseSuccess = useSelector(deleteSuccessSelector);
  const submitTrainingCorseSuccess = useSelector(submitSuccessSelector);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      const res = await api.LogCourseTraining.getAll(id);
      setLogs(res);
      setLoading(false);
    };
    id && fetchLogs();
  }, [
    createTrainingCorseSuccess,
    updateTrainingCorseSuccess,
    deleteTrainingCorseSuccess,
    submitTrainingCorseSuccess
  ]);
  return [logs, loading];
};

export const useUpdateTrainingCoursePartnerParams = (query) => {
  const history = useHistory();
  // const [keyword, setKeyword] = useState(get(query,'keyword'));
  // useEffect(() => {
  //   setKeyword(get(query,'keyword'));
  // },[query.keyword])
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/workspace/timeTrain',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [{ onParamChange }];
};
export const useTrainingCoursesPartner = (id) => {
  // get training courses Assign for partner
  return useFetchByParam({
    action: getTrainingCoursesPartner,
    loadingSelector: loadingSelectorTrainingCourse,
    dataSelector: trainingCoursesPartnerSelector,
    failedSelector: getTrainingCoursesPartnerFailedSelector,
    param: id
  });
};
export const useTrainingCoursesOfPartner = (query) => {
  // get all training courses of partner
  return useFetchByParam({
    action: getTrainingCoursesOfPartner,
    loadingSelector,
    dataSelector: trainingCoursesOfPartnerSelector,
    failedSelector: getTrainingCoursesOfPartnerFailedSelector,
    param: query
  });
};
export const useTrainingCoursesReadySubmitOfPartner = (query) => {
  // get all training courses of partner ready submit
  return useFetchByParam({
    action: getTrainingCoursesOfPartnerReady,
    loadingSelector: loadingReadySelector,
    dataSelector: trainingCourseReadySelector,
    failedSelector: getTrainingCoursesFailedFailedSelector,
    param: query
  });
};
export const useTrainingCoursePartner = (id) => {
  // get one TrainingCourse of Partner
  return useFetchByParam({
    action: getTrainingCoursePartner,
    loadingSelector: loadingSelectorTrainingCoursePartner,
    dataSelector: trainingCoursePartnerSelector,
    failedSelector: getTrainingCoursePartnerFailedSelector,
    param: id
  });
};
export const useNotePartner = (id) => {
  // get Note By Id parrter
  return useFetchByParam({
    action: getNoteByIdPartner,
    loadingSelector: loadingNoteSelector,
    dataSelector: notes,
    failedSelector: getNoteFailedSeletor,
    param: id
  });
};
export const useInitTrainingCoursePartner = (partner, id) => {
  return useMemo(() => {
    if (!partner || !id) {
      return {};
    }

    const initCompany = {
      ...partner
    };

    return initCompany;
  }, [partner, id]);
};
export const useCreateTrainingCoursePartner = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo Khoá đào tạo thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createTrainingCoursePartner
  });
};
export const useSubmitTrainingCoursePartner = (callback) => {
  useSuccess(submitSuccessSelector, 'Submit Khoá đào tạo thành công', callback);
  useFailed(submitFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: submitTrainingCoursePartner
  });
};

export const useUpdateTrainingCoursePartner = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật Khoá đào tạo thành công',
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateTrainingCoursePartner
  });
};
export const useDeleteTrainingCoursePartner = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Khoá đào tạo thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá Khoá đào tạo thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteTrainingCoursePartner
  });
};
export const IconAction = {
  create: <PlusCircleTwoTone />,
  update: <EditTwoTone />,
  submitted: <CheckSquareTwoTone />,
  delete: <DeleteTwoTone twoToneColor="#eb2f96" />,
  denied: <CloseCircleTwoTone twoToneColor="red" />,
  approved: <CheckCircleTwoTone twoToneColor="#52c41a" />
};
export const useSpecialityWorkSpace = (ids) => {
  const [speciality, setSpeciality] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    let promiseSpeci = [];
    let listSpe = [];
    ids?.forEach((e) => promiseSpeci.push(api.speciality.getById(e)));
    // Promise.allSettled(promiseSpeci).then((res) => { // not support ins afari 12.5
    //   console.log(res,'res');
    //   res.forEach((promise) => {
    //     if (promise.status === 'fulfilled') {
    //       listSpe.push(promise.value);
    //     }
    //   });
    //   setSpeciality(listSpe);
    //   setLoading(false);
    // });
    Promise.all(promiseSpeci).then((res) => {
      try {
        if(res){
          setSpeciality(res);
        }
      } catch (error) {
        console.log(error);
        toastr.error("Something went wrong")
      }
      setSpeciality(res);
      setLoading(false);
    });
  }, []);
  return [speciality, loading];
};
export const useRating = (id) => {
  const [rating, setRating] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getRatingById = async () => {
     try {
      setLoading(true);
      const res = await api.whPartner.getRatingById(id);
      setRating(res);
      setLoading(false);
     } catch (error) {
      setLoading(false);
     }
    };
    getRatingById();
  }, [id]);
  return [rating, loading];
};
export const useGetDateExists =({courseId,month}) => {
    const [data,setData] = useState({
      dateExist : [],
      loading : false
    })
    const fetch = async () => {
      setData({
        dateExist : [],
        loading : true
      })
      const res = await api.TrainingCoursePartner.getCourses({courseId});
      let dateExist = res?.filter(e => {
        if(moment(e.date).format("MM") === month){
          if(get(e,'status') !== WH_COURSE_TRAINING_STATUS.DENIED)
          // return moment(e.date).format("YYYY-MM-DD")
          return true
        }
        return false
      })
      setData({
        dateExist,
        loading : false
      })
    }
    useEffect(() => {
      if(!!courseId && !!month){
        fetch()
      }
    },[courseId,month])
   
    return [get(data,'dateExist',[]),get(data,'loading')]
}
export const useResetTrainingCoursePartner = () => {
  useResetState(resetTrainingCoursePartnerState);
};
