import { Tag } from 'antd'
import Search from 'antd/lib/input/Search'
import Text from 'antd/lib/typography/Text'
import { get } from 'lodash'
import { useCallback, useState } from 'react'
import { StringToSlug } from '~/utils/helper'

export default function WhBoxServicePartner({ services }) {
  const [listSearch, setListSearch] = useState([]);
  const [keyword, setKeyWord] = useState('');
  const onSearch = (value) => {
    const resultSearch = services?.filter(service =>
      StringToSlug(get(service, 'slug', '')).toUpperCase().includes(StringToSlug(value).toUpperCase())
      || StringToSlug(get(service, 'name.vi', '')).toUpperCase().includes(StringToSlug(value).toUpperCase())
      || StringToSlug(get(service, 'code', '')).toUpperCase().includes(StringToSlug(value).toUpperCase())

    );

    setListSearch(resultSearch)
  }

  // const nameService = (item) =>{
  //     if (get(item, "detailName.vi"))
  //           return `${get(item, "name.vi")} - ${get(item, "detailName.vi")}`
  //         else 
  //           return get(item, "name.vi")
  //   }
  const nameService = useCallback((item) => {
    return get(item, "name.vi")
  }, []);

  const goDetail = useCallback((idService) => window.open(`/wh-service/edit/${idService}`), [])
  return (
    <>
      {services?.length !== 0 && <div className='box-service-partner'>
        <div className='box-service-partner__item'>
          <Search
            placeholder="Nhập tên dịch vụ"
            onSearch={onSearch}
            onChange={(e) => {
              onSearch(e.target.value);
              setKeyWord(e.target.value)
            }}
            enterButton
            size='small'
            allowClear
            style={{
              width: 200,
            }}
          />
        </div>
        {
          keyword
            ? listSearch?.length ? listSearch?.map((item, index) => (
              <Tag onClick={() => goDetail(get(item, '_id'))} className='box-service-partner__item' key={index} color="blue">
                {nameService(item)}
              </Tag>
            )) : <Text>Không tìm thấy</Text>
            : services?.map((item, index) => (
              <Tag onClick={() => goDetail(get(item, '_id'))} className='box-service-partner__item' key={index} color="blue">
                {nameService(item)}
              </Tag>
            ))
        }

      </div>}
    </>

  )
}
