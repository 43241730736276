import { CaretDownOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  TreeSelect,
  DatePicker
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { forIn, get, head } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {useHistory} from 'react-router-dom'
import { v4 } from 'uuid';
import {
  RELATIVE_POSITION,
  WH_PARTNER_WAY_WORKING_EN
} from '~/constants/defaultValue';
import {
  useMatchPolicy,
  useProfile,
  useReportWhPartnerQueryParams,
  useUpdateReportWhPartnerParams,
  useUpdateWhPartnerParams,
  useUser,
  useWhPartnerPaging,
  useWhPartnerQueryParams
} from '~/hooks';
import { formatNumberThreeComma } from '~/hooks/utils';
import {
  useAllReportPartner,
  useMergeListPartnerWithService
} from '~/hooks/whReport';
import { Breadcrumb, GeoTreeSelect, WithPermission } from '../Common';
import ExportExcelButton from '../Common/ExportExcel';
import SkeletonTable from '../Utils/SkeletonTable';
import ModalDetailRevenue from './ModalDetailRevenue';
import ModalMonth from './ModalMonth';
import './wh-report.scss';
import WhReportPaymentVoucherForm from './WhReportPaymentVoucherForm';
const { Search } = Input;
const { RangePicker } = DatePicker;
import POLICY from '~/constants/policy';
import { monthCellRender } from '../Common/VietNameMonthTimpicker';
import TabBranch from '../Common/TabBranch';
import POLICIES from '~/constants/policy';

const optionSearch = [
  { label: 'Tên nhân viên', value: 'name' },
  { label: 'Mã nhân viên', value: 'code' },
  { label: 'Khu vực hoạt động', value: 'managementArea' }
];
const optionFilterDate = [
  // { label: 'Ngày', value: 'date' }, // wating BE Confirm use date
  { label: 'Tháng', value: 'month' },
  { label: 'Quý', value: 'quarter' },
  { label: 'Năm', value: 'year' },
];
const WhReportParter = () => {
  const history = useHistory()
  const [, token] = useUser();
  const [profile] = useProfile(token);
  const isRootAdmin = get(profile, 'isSuperAdmin');
  const isHaveCreateVoucher = useMatchPolicy(POLICY.WRITE_WHVOUCHERS)
  const managementPaths = useMemo(() => {
    return get(profile, 'managementArea')?.map((item) => get(item, 'path'));
  }, [profile]);
  const [isModalMonthOpen, setIsModalMonthOpen] = useState(false);
  const [isModalReVenueOpen, setIsModalReVenueOpen] = useState(false);
  const [partnerSelected, setPartnerSelected] = useState(null);
  const [saleRowSelect, setSaleRowSelect] = useState(null);
  const [isOpenPaymentVouchersModal, setIsOpenPaymentVouchersModal] =
    useState(false);
    const [filterDateBy, setFilterDateBy] = useState(
      head(optionFilterDate).value
    );
  const [typeFilter, setTypeFilter] = useState('') 
  
  const [arrCheckBox, setArrCheckBox] = useState([])
  const [dateFilter, setDateFilter] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  });

  // const [dataReport, loading] = useAllReportPartner(dateFilter); //
  // const [query] = useReportWhPartnerQueryParams();
  const [query] = useReportWhPartnerQueryParams();
  const [keyword, setKeyword, SearchBy, { onParamChange, resetQueryParams }] =
    useUpdateReportWhPartnerParams(query);
  const [searchBy, setSearchBy] = useState(SearchBy || 'name');
  const [id, setId] = useState(null);
  const [title, setTitle] = useState('');
  // const [date, setDate] = useState({
  //   startDate: moment().startOf('month').format('YYYY-MM-DD'),
  //   endDate: moment().endOf('month').format('YYYY-MM-DD')
  // });

  const [dataReport, loading] = useAllReportPartner(dateFilter); //
  const setInitDateFilter = useCallback(() => {
    setDateFilter({
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD')
    });
  }, []);
  useEffect(() => {
    if (!!!keyword) onParamChange(resetQueryParams);
    if (!!keyword && searchBy === 'managementArea')
      onParamChange({
        ...resetQueryParams,
        [searchBy]: keyword.toString().trim()
      });
  }, [keyword]);
  useEffect(() => {
    if (
      (!get(dateFilter, 'startDate') && !get(dateFilter, 'endDate')) ||
      (get(dateFilter, 'startDate') === 'Invalid date' &&
        get(dateFilter, 'endDate') === 'Invalid date')
    )
      setInitDateFilter();
  }, [dateFilter]);
  useEffect(() => {
    setTypeFilter(filterDateBy)
  },[filterDateBy])
  const [ whPartnerMergeService, isLoading] = useMergeListPartnerWithService(
    query,
    dateFilter,
    dataReport,
    loading,
    id
  );
  const paging = useWhPartnerPaging();
  const onSearch = () => {
    onParamChange({
      ...resetQueryParams,
      [searchBy]: keyword.toString().trim()
    });
  };
  const handleChangeSearch = (value) => {
    setSearchBy(value);
  };
  const handleChangeFilterDate = (value) => {
    setFilterDateBy(value);
  };
  const openModalMonth = () => {
    setIsModalMonthOpen(true);
  };
  const openDetailRevenue = (record) => {
    setIsModalReVenueOpen(true);
    setId(record._id);
    setTitle(`Tháng ${moment().format('MM')}`);
    setPartnerSelected(record);
  };
  const onClosePaymentVouchersModal = () => {
    setIsOpenPaymentVouchersModal(false);
  };
  const onOpenPaymentVouchersModal = (id, record) => {
    setIsOpenPaymentVouchersModal(true);
    setId(id);
    setSaleRowSelect(get(record, 'reportOfPartner', {}));
  };

  // Get list Ids
  const onChangeCheckBox = (e, id) => {
    if (e) {
    setArrCheckBox([...arrCheckBox, id])
    } else {
      const getIndex = arrCheckBox.findIndex((itemId, index) => itemId === id)
      const newArr = arrCheckBox.toSpliced(getIndex, 1)
      setArrCheckBox(newArr)
    }
  } 
  // props for export file excel 
  const searchParams = history.location.search
  const jornDate = () => {
    let newDate = ''
    forIn({ ...dateFilter }, (value, key) => {
      newDate = newDate + key + '=' + value +"&"
    })
    if (newDate.endsWith("&")) {
      newDate = newDate.slice(0, -1).concat('+23:59:59');
    }
    return newDate
  }
  const propsButton = {
    url: "/wh-partner-area",
    keyExport: "AreaPartner",
    fileName_: 'Doanh thu đối tác',
    searchBy: searchParams && (searchParams.concat('&')?.concat(jornDate())),
    ids: arrCheckBox,
  }
  const onchangeDate = (value, dateString) => {
    if (filterDateBy === 'date') {
      const [startDate, endDate] = dateString;
      setDateFilter({ startDate, endDate });
    } else {
      const startDate = moment(value)
        .startOf(filterDateBy)
        .format('YYYY-MM-DD');
      const endDate = moment(value).endOf(filterDateBy).format('YYYY-MM-DD');
      setDateFilter({ startDate, endDate });
    }
  };
  const handleCancel = () => {
    setIsModalReVenueOpen(false);
    setId(null);
    // setInitDateFilter();
    setIsModalMonthOpen(false);
  };
  const columns = [
    {
      title: 'STT',
      key: 'billNumber',
      width: 50,
      align: 'center',
      render: (item, record, index) => <span>{index + 1}</span>
    },
    {
      title: 'Lựa chọn',
      key: '_id',
      width: 80,
      align: 'center',
      render: (item, record) =>
      {
        const id = record._id
        return <Checkbox
       defaultChecked= {arrCheckBox.includes(id)}
        onChange={(e)=>onChangeCheckBox(e.target.checked, id)}
      />}
    },
    {
      title: 'Thời gian',
      key: 'time',
      align: 'center',
      render: (item, record, index) => {
        const date = new Date();
        return (
          <Button
          disabled={date.getMonth() !== moment(get(dateFilter,'startDate')).month()}
            onClick={() => {
              setId(record._id);
              setPartnerSelected(record);
              openModalMonth();
            }}
            type="link"
          >
            Tháng {moment(get(dateFilter,'startDate')).month() + 1}
          </Button>
        );
      }
    },
    {
      title: 'Mã nhân viên',
      key: 'partnerCode',
      name: 'partnerCode',
      align: 'center',
      render: (item) => {
        return <span>{item?.partnerCode}</span>;
      }
    },
    {
      title: 'Tên nhân viên',
      key: 'name',
      name: 'name',
      align: 'center',
      render: (item, record) => <span>{item?.name}</span>
    },
    {
      title: 'Số điện thoại',
      key: 'phone',
      name: 'phone',
      align: 'center',
      render: (item, record) => <span>{item?.phone}</span>
    },
    {
      title: 'Nhóm đối tác',
      key: 'employeeGroup',
      align: 'center',
      render: (item, record, index) => (
        <span>{item?.employeeGroup[0]?.name?.vi}</span>
      )
    },
    {
      title: 'Dịch vụ',
      key: 'listServiceName',
      width: '500px',
      dataIndex: 'listServiceName',
      align: 'center',
      render: (item, record, index) => (
        <span>
          {item?.map((e) => (
            <Tag className="tag-threeDot" color="blue">
              {get(e, 'name.vi')}
            </Tag>
          ))}
        </span>
      )
    },
    {
      title: 'Khu vực hoạt động',
      key: 'managementArea',
      dataIndex: 'managementArea',
      align: 'center',
      render: (item, record, index) => (
        <span>{get(item, '[0].fullAddress')}</span>
      )
    },
    {
      title: 'Tổng thu nhập',
      key: 'reportOfPartner',
      dataIndex: 'reportOfPartner',
      align: 'center',
      render: (item, record, index) => (
        <Text strong>
          {loading ? (
            <Loading3QuartersOutlined spin />
          ) : (
            formatNumberThreeComma(get(item, 'result', 0))
          )}
        </Text>
      )
    },
    {
      title: 'Khách hàng đánh giá',
      width: 200,
      key: 'rating',
      dataIndex: 'rating',
      align: 'center',
      render: (item, record, index) => (
        <Text strong>
         {loading ? (
            <Loading3QuartersOutlined spin />
          ) : (
            get(item, 'resultSumRating')
          )}
        </Text>
      )
    },
    {
      title: 'Hình thức làm việc',
      key: 'wayWorking',
      dataIndex: 'wayWorking',
      align: 'center',
      render: (item, record, index) => (
        <span>{WH_PARTNER_WAY_WORKING_EN[item] || ''}</span>
      )
    },
    {
      title: 'Thao tác',
      key: 'billNumber',
      // align : 'center',
      fixed: 'right',
      render: (item, record, index) => {
        return (
          <Dropdown
            overlayStyle={{ width: '130px' }}
            // placement="bottom"
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item key={v4()} onClick={() => openDetailRevenue(record)}>
                  <p>Xem chi tiết</p>
                </Menu.Item>
                <Menu.Item
                  key={v4()}
                  disabled={!isHaveCreateVoucher}
                  onClick={() => onOpenPaymentVouchersModal(record._id, record)}
                >
                  <p>Tạo chứng từ</p>
                </Menu.Item>
              </Menu>
            }
          >
            <a className="ant-dropdown-link" href="#">
              Action <CaretDownOutlined />
            </a>
          </Dropdown>
        );
      }
    }
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <TabBranch useBoxShadow={false}>
        <Breadcrumb title="Doanh thu đối tác" />
        <h5>Tìm kiếm </h5>
        <Row>
          <Col md={12} lg={4} xs={12}>
            <Select
              value={searchBy}
              style={{ width: '100%' }}
              onChange={handleChangeSearch}
              options={optionSearch}
            />
          </Col>
          <Col md={12} lg={6} xs={12}>
            {optionSearch?.map((e) => {
              if (e.value === 'managementArea')
                return {
                  [e.value]: (
                    <GeoTreeSelect
                      style={{ width: '200px' }}
                      onChange={(e) => onParamChange({ managementArea: e })}
                      autoClearSearchValue
                      checkablePositions={[
                        RELATIVE_POSITION.IS_CHILD,
                        RELATIVE_POSITION.IS_EQUAL
                      ]}
                      enabledValues={isRootAdmin ? null : managementPaths}
                      listItemHeight={200}
                      showEnabledValuesOnly={true}
                      showSearch={true}
                      treeDefaultExpandedKeys={['1', '2', '3']}
                    />
                  )
                }[searchBy];
              else {
                return {
                  [e.value]: (
                    <Search
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={`Tìm theo ${e.label}`}
                      enterButton
                      onSearch={onSearch}
                      onChange={(e) => setKeyword(e.target.value)}
                      value={keyword}
                    />
                  )
                }[searchBy];
              }
            })}
          </Col>
        </Row>
        <h5 style={{ margin: '10px 0' }}>Tìm kiếm theo thời gian</h5>
        <Row style={{width: '100%'}} justify='space-between'>
          <Col md={12} lg={10} xs={12}>
            <Row style={{width: '50%'}}>
              <Col span={12}>
                <Select
                value={filterDateBy}
                style={{ width: '100%' }}
                onChange={handleChangeFilterDate}
                options={optionFilterDate}
              />
              </Col>
              <Col span={12}>
                  {/* {filterDateBy === 'date' && (
                <RangePicker
                  allowClear
                  onChange={onchangeDate}
                  value={[
                    moment(get(dateFilter, 'startDate')),
                    moment(get(dateFilter, 'endDate'))
                  ]}
                />
              )} */}
              {/* {filterDateBy !== 'date' && ( */}
                <DatePicker
                allowClear
                value={moment(get(dateFilter, 'startDate'))}
                monthCellRender={monthCellRender}
                  onChange={onchangeDate}
                  style={{ width: 200 }}
                  placeholder={`Tìm theo${
                    optionFilterDate?.find((e) => e.value === filterDateBy).label
                  }`}
                  picker={filterDateBy}
                />
              {/* )} */}
              </Col>
            </Row>
          </Col>
          <WithPermission permission={POLICIES.DOWNLOAD_WHREVENUEPARTNER}>
            <Col align = 'end' md={12} lg={6} xs={12}>
            <ExportExcelButton propsButton={propsButton} />
          </Col>
          </WithPermission>
        </Row>
        <div className="page-content__main">
          {isLoading ? (
            <SkeletonTable columns={columns} rowCount={paging} />
          ) : (
            <Table
              rowKey={(rc) => rc._id}
              columns={columns}
              dataSource={whPartnerMergeService}
              loading={loading}
              scroll={{ x: 2000 }}
              onChange={({ current, pageSize }) =>
                onParamChange({ page: current, limit: pageSize })
              }
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `,
                // position: ['bottomCenter']
              }}
              size="middle"
            />
          )}
        </div>
        </TabBranch>
      </div>
      <ModalMonth
        setInitDate={setInitDateFilter}
        setTitle={setTitle}
        setId={setId}
        setDate={setDateFilter}
        isModalMonthOpen={isModalMonthOpen}
        setIsModalReVenueOpen={setIsModalReVenueOpen}
        setIsModalMonthOpen={setIsModalMonthOpen}
        setTypeFilter={setTypeFilter}
      />
       <Modal
      title="Chi tiết thu nhập"
      width="90vw"
      visible={isModalReVenueOpen}
      footer={[]}
      destroyOnClose
      onCancel={handleCancel}
      closable={false}
    >
<ModalDetailRevenue
        setTitle={setTitle}
        // setDate={setDateFilter}
        // setIsModalMonthOpen={setIsModalMonthOpen}
        // partnerSelected={partnerSelected}
        // setInitDate={setInitDateFilter}
        // setId={setId}
        title={title}
        date={dateFilter}
        id={id}
        // isModalReVenueOpen={isModalReVenueOpen}
        // setIsModalReVenueOpen={setIsModalReVenueOpen}
        filterDateBy={filterDateBy}
        typeFilter={typeFilter}
        // setTypeFilter=
        optionFilterDate={[...optionFilterDate,{label : 'Tuần',value:'weeks'}]}
      />
    </Modal>
      

      <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={onClosePaymentVouchersModal}
        onOk={() => {}}
        title={`Phiếu chi`}
        width={1500}
        visible={isOpenPaymentVouchersModal}
      >
        <WhReportPaymentVoucherForm
          onCancel={onClosePaymentVouchersModal}
          saleRowSelect={saleRowSelect}
        />
      </Modal>
    </div>
  );
};

export default WhReportParter;
