import {
  GET_WH_PARTNERS_REQUEST,
  GET_WH_PARTNER_REQUEST,
  CREATE_WH_PARTNER_REQUEST,
  CREATE_WH_PARTNER_SERVICE_REQUEST,
  INVITE_WH_PARTNER_REQUEST,
  UPDATE_WH_PARTNER_REQUEST,
  UPDATE_WH_PARTNER_SERVICE_REQUEST,
  DELETE_WH_PARTNER_REQUEST,
  DELETE_WH_PARTNER_SERVICE_REQUEST,
  APPROVE_WH_PARTNER_REQUEST,
  RESET_WH_PARTNER_STATE,
  UPDATE_WH_PARTNER_TIPS_REQUEST,
  GET_WH_PARTNERS_FOR_REPORT_REQUEST,
  UPDATE_WORKING_TIME_WH_PARTNER_REQUEST,
  CONVERT_TO_PARTNER_REQUEST,
  // GET_WH_PARTNER_GROUPS_REQUEST
} from '../../constants/actionTypes';

export const getWhPartners = query => ({
  type: GET_WH_PARTNERS_REQUEST,
  payload: query
});
export const getWhPartnersReport = query => ({
  type: GET_WH_PARTNERS_FOR_REPORT_REQUEST,
  payload: query
});

export const getWhPartner = id => ({
  type: GET_WH_PARTNER_REQUEST,
  payload: id
});

export const createWhPartner = instance => ({
  type: CREATE_WH_PARTNER_REQUEST,
  payload: instance
});

export const createWhPartnerService = instance => ({
  type: CREATE_WH_PARTNER_SERVICE_REQUEST,
  payload: instance
});

export const inviteWhPartner = ({ id, newWhPartner }) => ({
  type: INVITE_WH_PARTNER_REQUEST,
  payload: { id, newWhPartner },
});

export const updateWhPartner = instance => ({
  type: UPDATE_WH_PARTNER_REQUEST,
  payload: instance
});
export const updateWhPartnerTips = instance => ({
  type: UPDATE_WH_PARTNER_TIPS_REQUEST,
  payload: instance
});

export const updateWhPartnerService = instance => ({
  type: UPDATE_WH_PARTNER_SERVICE_REQUEST,
  payload: instance
});

export const approveWhPartner = instance => ({
  type: APPROVE_WH_PARTNER_REQUEST,
  payload: instance
});

export const deleteWhPartner = id => ({
  type: DELETE_WH_PARTNER_REQUEST,
  payload: id
});
export const deleteWhPartnerService = instance => ({
  type: DELETE_WH_PARTNER_SERVICE_REQUEST,
  payload: instance
});

export const resetWhPartnerState = () => ({
  type: RESET_WH_PARTNER_STATE
});

// export const getWhPartnerGroups = () => ({
//   type: GET_WH_PARTNER_GROUPS_REQUEST
// });

export const updateWorkingTimeWhPartner = instance => ({
  type: UPDATE_WORKING_TIME_WH_PARTNER_REQUEST,
  payload: instance
});

export const convertToPartner = instance => ({
  type: CONVERT_TO_PARTNER_REQUEST,
  payload: instance
})
