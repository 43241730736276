import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row } from 'antd';
import { get } from 'lodash';
import LangFormItem from '../Common/LangFormItem';
import UploadFile from '../Workspace/WhManagerTrainingCourse.js/UploadFile';
const requireRules = [
    {
        required: true,
        message: "Vui lòng nhập"
    }
]
export default function FormItem({ form, name,language }) {
    return (
        <Row>
            <Col span={16}>
                <Form.Item shouldUpdate noStyle>
                    {() => <Form.List name={[name, 'files']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => {
                                    const files = form.getFieldValue([name, 'files', index]);

                                    const onChangeFiles = (newField) => {
                                        console.log(newField, 'newField')
                                        // Get New File
                                        const newFiles = { ...files, ...newField };

                                        // Set New File
                                        form.setFieldsValue({
                                            [name]: {
                                                ...form.getFieldValue('name'),
                                                files: form.getFieldValue([name, 'files'])?.map((item, ind) => ind === index ? newFiles : item)
                                            }
                                        })
                                    }
                                    return (
                                        <Row>
                                            <Col span={1}>
                                                <Button onClick={() => remove(index)} shape='circle'>
                                                    <MinusOutlined />
                                                </Button>
                                            </Col>
                                            <Col span={12}>
                                                <LangFormItem
                                                    fieldName={[index, 'title']}
                                                    language={language}
                                                >
                                                    <Input placeholder='Mô tả' />
                                                </LangFormItem>

                                            </Col>
                                            <Col span={9}>
                                                <Form.Item
                                                    rules={requireRules}
                                                    name={[index, 'file']}
                                                >
                                                    <UploadFile style={{ width: '100%' }} setFiles={(file) => onChangeFiles({ file })} mode='single' files={get(files, ['file']) && [get(files, ['file'])]} />
                                                </Form.Item>
                                            </Col>


                                        </Row>
                                    )
                                })}

                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                >
                                    Thêm file
                                </Button>

                            </>
                        )}
                    </Form.List>}
                </Form.Item>

            </Col>
            <Col>
                <Divider type='vertical' />
            </Col>
            <Col flex={1}>
                <Form.Item shouldUpdate noStyle>
                    {() => <Form.Item label="Link Youtube" name={[name, 'youtubeId']}>
                        <Input />
                    </Form.Item>}
                </Form.Item>
            </Col>
        </Row>
    )
}
