import { compact, forIn, get, groupBy, keys } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '~/api';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import {
    getSelectors,
    useFailed,
    useFetchByParam,
    useQueryParams,
    useResetState,
    useSubmit,
    useSuccess
} from '~/hooks/utils';
import { createConfigCampaign, deleteConfigCampaign, getConfigCampaign, getConfigCampaigns, resetConfigCampaignState, resetConfigCampaignStateACtion, updateConfigCampaign } from '~/redux/action';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.configCampaign[key];
const pagingSelector = getSelector('paging');
export const useConfigCampaignPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadingGetConfigCampaignSelector = getSelector('isGetConfigCampaignLoading');
const getConfigCampaignsFailedSelector = getSelector('getConfigCampaignsFailed');
const getConfigCampaignFailedSelector = getSelector('getConfigCampaignFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const configCampaignsSelector = getSelector('configCampaigns');
const configCampaignSelector = getSelector('configCampaign');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useConfigCampaignQueryParams = () => {
    const query = useQueryParams();
    const limit = query.get('limit') || 10;
    const page = query.get('page') || 1;
    const activity = query.get('activity');
    const name = query.get('name');
    const item = query.get('item');
    const point = query.get('point');
    const sortPoint = query.get('sortPoint');
    const actor = query.get('actor');
    const createConfigCampaignSuccess = useSelector(createSuccessSelector);
    // const updateConfigCampaignSuccess = useSelector(updateSuccessSelector);
    const deleteConfigCampaignSuccess = useSelector(deleteSuccessSelector);
  
    return useMemo(() => {
      const queryParams = {
        page,
        limit,
        activity,
        sortPoint,
        item,
        point,
        name,
        actor
      };
      return [queryParams];
      //eslint-disable-next-line
    }, [
      page,
      limit,
      createConfigCampaignSuccess,
      // updateConfigCampaignSuccess,
      deleteConfigCampaignSuccess,
      activity,
      sortPoint,
      item,
      point,
      name,
      actor
    ]);
  };

  export const useUpdateConfigCampaignParams = (query,listOptionSearch) => {
    const history = useHistory();
    const [keyword, setKeyword] = useState(get(query,'keyword'));
    useEffect(() => {
      setKeyword(get(query,'keyword'));
    },[query.keyword])
    const onParamChange = (param) => {
      const groupByKey = groupBy(listOptionSearch , (e) => get(e,'value'));
      forIn(query,(values,key,obj) =>{
        if(groupByKey[key] && (keys(param)?.some(e => groupByKey[e]))){
          obj[key] = null
        }
      })
      if (!param.page) {
        query.page = 1;
      }
      history.push({
        pathname: get(param, 'customPathName') || '/config-campaign',
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword ,{setKeyword, onParamChange }];
  };
  export const useConfigCampaigns = (query) => {
    return useFetchByParam({
      action: getConfigCampaigns,
      loadingSelector,
      dataSelector: configCampaignsSelector,
      failedSelector: getConfigCampaignsFailedSelector,
      param: query
    });
  };
  export const useConfigCampaign = (id) => {
    return useFetchByParam({
      action: getConfigCampaign,
      loadingSelector:loadingGetConfigCampaignSelector,
      dataSelector: configCampaignSelector,
      failedSelector: getConfigCampaignFailedSelector,
      param: id
    });
  };
export const useCreateConfigCampaign = (callback) => {
    useSuccess(createSuccessSelector, 'Tạo cấu hình chiến dịch thành công',callback);
    useFailed(createFailedSelector);
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: createConfigCampaign
    });
  };
export const useUpdateConfigCampaign = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhật cấu hình chiến dịch thành công',callback);
    useFailed(updateFailedSelector, 'Cập nhật cấu hình chiến dịch thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateConfigCampaign
    });
  };
export const useDeleteConfigCampaign = (callback) => {
    useSuccess(deleteSuccessSelector, 'Xoá cấu hình chiến dịch thành công',callback);
    useFailed(deleteFailedSelector, 'Xoá cấu hình chiến dịch thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: deleteConfigCampaign
    });
  };
export const useGetConfigCampaignNotExist = async(list) => {
  try {
    const res = await api.configCampaign.getAll({page : 1,limit : MAX_PAGINATION_LIMIT,status:'ACTIVE'})
    if(res){
      const listShow = list?.map(e => {
        const findOne = get(res,'docs',[]).find(doc => get(doc,'activity','') === get(e,'value'))
        if(findOne) return false
        return e
      })
      listShow = compact(listShow)
    }
  } catch (error) {
    console.log(error);
  }
    
}
  export const useResetConfigCampaign = () => {
    useResetState(resetConfigCampaignState);
  };
export const useMergeConfigCampaignsWithRankings = (configCampaigns,rankings) => {
  const result = useMemo(() => {
    const mergeList = configCampaigns?.map(config => {
      if(get(config,'rankings[0]') === 'all')return {...config}
    const listRanking =   get(config,'rankings',[]).map(rank => {
      const findOneRanking = rankings?.find(r => get(r,'_id') === rank)
      return findOneRanking
    })
      return {...config,rankings:[...listRanking]}
    })
    return mergeList
  },[configCampaigns,rankings])
  return result
}

export const useResetConfigCampaignAction = () => {
  useResetState(resetConfigCampaignStateACtion);
};
