import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getUIs({ payload: query }) {
    try {
      const response = yield call(Api.UI.getUIs, query);
      yield put({ type: Types.GET_UIS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_UIS_FAILED, payload: error });
    }
  }
  function* updateUI(action) {
    try {
      const data = yield call(Api.UI.updateUI, action.payload);
      yield put({ type: Types.UPDATE_UI_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_UI_FAILED, payload: error });
    }
  }
export default function* UIs() {
    yield takeLatest(Types.GET_UIS_REQUEST, getUIs);
    yield takeLatest(Types.UPDATE_UI_REQUEST, updateUI);
  }
