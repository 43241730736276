import { Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get, unionBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { formatNumberThreeComma } from '~/hooks/utils';
import { floorFormatter } from '~/utils/helper';
import SkeletonTable from '../Utils/SkeletonTable';
import TableDetail from './TableDetail';

export default function TableDidServiceCustomer({ whReportCustomer, loading }) {
  const getRemain = (num) =>  num < 0 ? num : 0
  // const getRemain = (num) =>  num < 0 ? num : 0
  const [totalSummary, setTotalSummary] = useState({
    sumTotal: 0,
    sumTotalPaid: 0,
    sumTotalPayment: 0,
    sumTotalDiscount: 0,
    sumTotalSale: 0,
    sumRefund: 0,
    sumDiscountRates : 0,
    sumSinglePrice : 0,
    sumTotalNeedToPay: 0,
    sumPrepay : 0,
  });
  useEffect(() => {
    const singlePrice = whReportCustomer?.reduce((sum,curr) => sum + get(curr,'subTotal'),0)
    const total = unionBy(whReportCustomer,(e => get(e,'whBillId')))?.reduce(
      (sum, curr) => {
        const subTotal = get(curr, 'whBill.subTotal', 0);
        const totalAmountNeedToPaid =
          get(curr, 'whBill.subTotal', 0) -
          get(curr, 'whBill.discountAmount', 0) -
          get(curr, 'whBill.discountedRates', 0) - 
          get(curr, 'whBill.refund', 0) ;
        const totalPaymentVoucherBillAmount = get(curr,'whBill.totalPaymentVoucherBillAmount',0) + get(curr,'whBill.totalPaymentVoucherBillItemAmount',0);
        const totalReceiptVoucherBillAmount = get(curr,'whBill.totalReceiptVoucherBillAmount',0) + get(curr,'whBill.totalReceiptVoucherBillItemAmount',0);
        const totalPaid = get(curr,'whBill.actualTotalAmountPaid',0) + totalReceiptVoucherBillAmount + getRemain(get(curr,'totalRemainingAmount'))
        const discountAmount = get(curr, 'whBill.discountAmount', 0);
        const discountRates = get(curr, 'whBill.discountedRates', 0);
      // const  sumTotalSale =  get(curr,'whBill.actualTotalAmountPaid', 0) +
      //         -  totalPaymentVoucherBillAmount +
      //         totalReceiptVoucherBillAmount + 
      //           // get(curr,'whBill.discountedRates',0) + 
      //           // get(curr,'whBill.refund',0) + 
      //           // get(curr,'whBill.discountAmount',0)
      //           + getRemain(get(curr,'totalRemainingAmount'))
      const  sumTotalSale =  get(curr,'whBill.grandTotal', 0) 
              -  totalPaymentVoucherBillAmount 
              // totalReceiptVoucherBillAmount + 
                // get(curr,'whBill.discountedRates',0) + 
                // get(curr,'whBill.refund',0) + 
                // get(curr,'whBill.discountAmount',0)
                // + getRemain(get(curr,'totalRemainingAmount'))
        const totalRefund = get(curr, 'whBill.refund', 0)
        const totalPrepay = get(curr, 'whBill.actualPrepaymentAmount', 0)
        return {
          sumTotal: (sum.sumTotal += subTotal),
          sumTotalPaid: (sum.sumTotalPaid += totalPaid),
          sumTotalPayment: (sum.sumTotalPayment +=
            totalPaymentVoucherBillAmount),
          sumTotalDiscount: (sum.sumTotalDiscount += discountAmount),
          sumTotalSale: (sum.sumTotalSale += sumTotalSale),
          sumRefund: (sum.sumRefund += totalRefund),
          sumDiscountRates : (sum.sumDiscountRates += discountRates),
          sumTotalNeedToPay : (sum.sumTotalNeedToPay += totalAmountNeedToPaid),
          sumPrepay : (sum.sumPrepay += totalPrepay)
        };
      },
      {
        sumTotal: 0,
        sumTotalPaid: 0,
        sumTotalPayment: 0,
        sumTotalDiscount: 0,
        sumTotalSale: 0,
        sumRefund: 0,
        sumDiscountRates : 0,
        sumTotalNeedToPay :0,
        sumPrepay: 0
      }
    );
    setTotalSummary({...total,sumSinglePrice : singlePrice});
  }, [whReportCustomer]);
  const columns = [
    {
      title: 'STT',
      key: 'index',
      dataIndex: 'index',
      width: 70,
      align: 'center',
      render: (item, record, index) => ({
        children: index + 1,
        props: {
          rowSpan: get(record, 'rowSpan')
        }
      })
    },
    {
      title: 'Mã đơn hàng',
      key: 'whBill',
      dataIndex: 'whBill',
      align: 'center',
      render: (item, record, index) => ({
        children: <span>{get(item, 'billNumber', '')}</span>,
        props: {
          rowSpan: get(record, 'rowSpan')
        }
      })
    },
    {
      title: 'Tên dịch vụ',
      key: 'snapshotService',
      dataIndex: 'snapshotService',
      width: '20%',
      align: 'center',
      render: (item, record, index) => (
        <span>
          {`${get(item, 'code', '')} - ${get(item, 'name.vi', '')} - ${get(
            item,
            'detailName.vi',
            ''
          )}`}
        </span>
      )
    },
    {
      title: 'Gói DV',
      key: 'snapshotService',
      width: 'max-content',
      dataIndex: 'snapshotService',
      align: 'center',
      render: (item, record, index) => <span>{get(item, 'package.packageLevelName.vi', '')}</span>
    },
    {
      title: 'Đơn giá',
      key: 'subTotal',
      width: 'max-content',
      dataIndex: 'subTotal',
      align: 'center',
      render: (item, record, index) => <Text strong>{formatNumberThreeComma(item || 0)}</Text>
    },
    {
      title: 'Tổng giá trị',
      key: 'whBill',
      dataIndex: 'whBill',
      align: 'center',
      render: (item, record, index) => {
        return {
          children: (
            <Text strong>{formatNumberThreeComma(get(item, 'subTotal', 0))}</Text>
          ),
          props: {
            rowSpan: get(record, 'rowSpan')
          }
        };
      }
    },
    {
      title: 'CTKM',
      width: 200,
      key: 'whBill',
      dataIndex: 'whBill',
      align: 'center',
      render: (item, record, index) => {
        return {
          children: get(item, 'discountCode', '') && <Text strong>{get(item, 'discountCode', '')}: {formatNumberThreeComma(get(item, 'discountAmount', 0))}</Text>,
          props: {
            rowSpan: get(record, 'rowSpan')
          }
        };
      }
    },
    {
      title: 'Dùng điểm thưởng',
      width: 200,
      key: 'whBill',
      dataIndex: 'whBill',
      align: 'center',
      render: (item, record, index) => {
        return {
          children: <Text>{floorFormatter(get(item, 'refund', 0))}</Text>,
          props: {
            rowSpan: get(record, 'rowSpan')
          }
        };
      }
    },
    {
      title: 'Tiền chiết khấu',
      key: 'whBill',
      dataIndex: 'whBill',
      align: 'center',
      render: (item, record, index) => {
        return {
          children: (
            <Text strong>
              {formatNumberThreeComma(get(item, 'discountedRates', 0))}
            </Text>
          ),
          props: {
            rowSpan: get(record, 'rowSpan')
          }
        };
      }
    },
    {
      title: 'Tiền khách đã trả trước',
      key: 'whBill',
      dataIndex: 'whBill',
      align : 'center',
      render: (item,record,index) => (
        <Text strong>
          {formatNumberThreeComma(get(item,'actualPrepaymentAmount',0))}
        </Text>
      )
    },
    {
      title: 'Tổng số tiền cần thanh toán',
      key: 'whBill',
      dataIndex: 'whBill',
      align: 'center',
      render: (item, record, index) => {
        return {
          children: (
            <Text strong>
              {formatNumberThreeComma(
                get(item, 'subTotal', 0) - get(item, 'discountAmount', 0) - get(item, 'refund', 0) - get(item, 'discountedRates', 0)  
              )}
            </Text>
          ),
          props: {
            rowSpan: get(record, 'rowSpan')
          }
        };
      }
    },
    {
      title: 'Tổng số tiền đã thanh toán',
      key: 'whBill',
      dataIndex: 'whBill',
      align: 'center',
      render: (item, record, index) => {
        return {
          children: (
            <Text strong>
              {formatNumberThreeComma(
                get(item, 'actualTotalAmountPaid', 0)
                + get(item,'totalReceiptVoucherBillAmount',0)
                + get(item,'totalReceiptVoucherBillItemAmount',0)
                +  getRemain(get(record,'totalRemainingAmount'))
              )}
            </Text>
          ),
          props: {
            rowSpan: get(record, 'rowSpan')
          }
        };
      }
    },
    {
      title: 'Tổng số tiền đã chi',
      key: 'whBill',
      dataIndex: 'whBill',
      align: 'center',
      render: (item, record, index) => {
        return {
          children: (
            <Text strong>
              {formatNumberThreeComma(
                get(item, 'totalPaymentVoucherBillItemAmount', 0) + get(item,'totalPaymentVoucherBillAmount',0)
              )}
            </Text>
          ),
          props: {
            rowSpan: get(record, 'rowSpan')
          }
        };
      }
    },

    {
      title: 'Doanh thu',
      key: 'whBill',
      dataIndex: 'whBill',
      align: 'center',
      fixed:'right',
      render: (item, record, index) => {
        return {
          children: (
            <Text strong>
              {formatNumberThreeComma(
              get(record,'whBill.grandTotal',0)
              - get(record,'whBill.totalPaymentVoucherBillAmount',0)
              )}
            </Text>
          ),
          props: {
            rowSpan: get(record, 'rowSpan')
          }
        };
      }
    }
  ];
  const summaryFooter = (current) => {
    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell align="center">TC</Table.Summary.Cell>
          <Table.Summary.Cell align="center">
            {unionBy(whReportCustomer,(e => get(e,'whBillId')))?.length || 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center"></Table.Summary.Cell>
          <Table.Summary.Cell align="center"></Table.Summary.Cell>
  
          <Table.Summary.Cell align="center">
            <Text strong>
              {formatNumberThreeComma(get(totalSummary, 'sumSinglePrice', 0))} {/* Tổng đơn giá */}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center">
            <Text strong>
              {formatNumberThreeComma(get(totalSummary, 'sumTotal', 0))} {/* Tổng giá trị*/}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center">
          <Text strong>
              {formatNumberThreeComma(get(totalSummary, 'sumTotalDiscount', 0))} {/*Tổng dùng mã giảm giá*/}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align='center'>
            {formatNumberThreeComma(get(totalSummary, 'sumRefund', 0))} {/*Tổng dùng điểm thưởng*/}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center">
          {formatNumberThreeComma(get(totalSummary, 'sumDiscountRates', 0))} {/*Tổng chiết khấu*/}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center">
          {formatNumberThreeComma(get(totalSummary, 'sumPrepay', 0))} {/*Tổng trả trước*/}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center">
            <Text strong>
              {formatNumberThreeComma(get(totalSummary, 'sumTotalNeedToPay', 0))} {/* Tổng cần thanh toán */}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center">
                {formatNumberThreeComma(get(totalSummary, 'sumTotalPaid', 0))} {/*Đã thanh toán*/}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center">
          {formatNumberThreeComma(get(totalSummary, 'sumTotalPayment', 0))} {/*Đã chi*/}
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center">
            <Text strong>
              {formatNumberThreeComma(get(totalSummary, 'sumTotalSale', 0))} {/*Tổng doanh thu*/}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    )
  };
  return (
    <div>
      {loading ? (
        <SkeletonTable rowCount={5} columns={columns} />
      ) : (
        <TableDetail
          scrollWidth={3000}
          pagination={false}
          dataSource={whReportCustomer || []}
          columns={columns}
          title={'Thu nhập tính theo số lần đặt dịch vụ'}
          footer={summaryFooter}
        />
      )}
    </div>
  );
}
