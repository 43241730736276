import { find, get } from "lodash";
import moment from "moment";
import { TIME_UNIT } from "~/constants/defaultValue";

export const getPackageLevelOfService = (whService, whPackageLevelId) => {
  const whPackages = get(whService, "packages");
  const whPackageLevel = find(whPackages, { whPackageLevelId });
  return whPackageLevel;
}

export const getSessionPrice = (whService, whPackageLevelId, whSessionOfDayId) => {
  const whPackageLevel = getPackageLevelOfService(whService, whPackageLevelId);
  const sessionPrices = get(whPackageLevel, "sessionPrices");
  const whSessionOfDay = find(sessionPrices, { whSessionOfDayId })
  return whSessionOfDay
}

export const getExpectEndTime = ({sessionPrice,whAppointment}) => {
  const {time,date,extraQuantity} = whAppointment;
  const duration = get(sessionPrice, "duration");
  const timeUnit = get(sessionPrice, "timeUnit");
  const durationInMinutes = moment.duration(
    duration, timeUnit === TIME_UNIT.HOUR ? "hour" : "minute"
  ).asMinutes()
  const extraDuration = get(sessionPrice, "extraDuration");
  const extraTimeUnit = get(sessionPrice, "extraTimeUnit");
  const extraDurationInMinutes = moment.duration(
    extraDuration * extraQuantity,
    extraTimeUnit === TIME_UNIT.HOUR ? "hour" : "minute"
  ).asMinutes();
  const totalDurationInMinutes = durationInMinutes + extraDurationInMinutes;
  const expectedEndTime = moment(time, "HH:mm").add(totalDurationInMinutes, "minute");
  const expectedEndDate = moment(date).startOf('d').add(moment(time, "HH:mm").hours(),'h').add(totalDurationInMinutes, "minute");
  const startDate = moment(date).startOf('d').add(moment(time, "HH:mm").hours(),'h');
  return {
    expectedEndTime,
    expectedEndDate,
    totalDurationInMinutes,
    startDate,
  }
}

export const isRangeTimeIsBetweenRangeTime = (startTimeRange1,endTimeRange1,startTimeRange2,endTimeRange2) =>
(startTimeRange1.isBetween(startTimeRange2, endTimeRange2) || endTimeRange1.isBetween(startTimeRange2, endTimeRange2))
||
(startTimeRange2.isBetween(startTimeRange1, endTimeRange1) || endTimeRange2.isBetween(startTimeRange1, endTimeRange1));

export const convertWhAppointmentProcessing = ({appointments,row,maxEndTime}) => {
  const {date} = row;
  let appointmentRemoveDoubleCase = appointments?.filter((e, index) => {
    const findIndex = appointments?.findIndex((f) => f.startDate === e.startDate && f.expectedEndDate === e.expectedEndDate)
    return index === findIndex
  });
  const findWhAppointmentProcessingIncludeDateRow = appointmentRemoveDoubleCase?.filter(item => {
    const dateSelect = moment(date).startOf('d');
    const startDate = moment(get(item,'startDate'));
    const endDate = moment(get(item,'expectedEndDate'));

    


    // Check startDate and endDate is In Date with Date Select;
    return dateSelect.format("DD") >= startDate.format("DD") && dateSelect.format("DD") <= endDate.format("DD");
  });
  const convertTimeInDateRow = findWhAppointmentProcessingIncludeDateRow?.map(item => {
    let timeStart = '';
    let timeEnd = '';
    const dateStartSelect = moment(date).startOf('d');
    const dateEndSelect = moment(date).endOf('d');
    const startDate = moment(get(item,'startDate'));
    const time = get(item,'time');
    const endTime = get(item,'endTime');
    const endDate = moment(get(item,'expectedEndDate'));
    if(startDate.isBefore(dateStartSelect)){
      timeStart = "00:00";
    }else{
      timeStart = time;
    };

    if(endDate.isAfter(dateEndSelect)){
       timeEnd = maxEndTime;
    }else{
      timeEnd = endTime;
    };

    return {
      time : timeStart,
      endTime : timeEnd
    }
  });

  return convertTimeInDateRow;
}
