import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import { get } from 'lodash';
import React from 'react'
import { usePinedNavbar } from '~/hooks';

export default function TitleSubmenu({group,accessKey}) {
    const [accessKeyPined, onPined] = usePinedNavbar();
    const onClickPined = (accessKey) => {
      if(accessKeyPined === accessKey){
        onPined(null);
      }else{
        onPined(accessKey);
      }
      }
  return (
    <Row justify='space-between' align='middle'>
                <Col>
                  {get(group, 'titleGroup', '')}
                </Col>
                <Col>
                  <Tooltip title="Gim Thanh điều hướng trên màn hình">
                    <Button size='small' className='draw-custom--menu-draw__submenu__pined' onClick={(e) => {
                      e.stopPropagation();
                      onClickPined(accessKey)
                    }} type='text' >{accessKeyPined === accessKey ? <PushpinFilled /> : <PushpinOutlined />}</Button>
                  </Tooltip>
                </Col>
              </Row>
  )
}
