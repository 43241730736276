import { Spin, Typography } from 'antd'
import React from 'react'
import './wh-order-temp.scss'
export default function LoadingWhBill({open,fixed,title}) {
  return (
    <div className='loadingWh' style={{display : open ? 'flex' : 'none',position : fixed ? 'fixed' : 'absolute'}}>
       <div>
       <Spin/>
        <Typography.Title level={5} style={{color :'white'}}>
          {title ? title : ""}
        </Typography.Title>
       </div>
    </div>
  )
}
