import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Radio,
  Select,
  Space,
  Button,
  DatePicker,
  Divider,
  Row,
  Col
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { get, range } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
const typeRepeat = [
  { label: 'Mỗi ngày', value: 'everyDate' },
  { label: 'Mỗi tháng', value: 'month' },
  // {label : "Mỗi quý",value : 'quarter'},
  { label: 'Mỗi năm', value: 'year' }
];
export default function RepeatTime({ rules, renderInput, form }) {
  const [optionSelectDateYear, setOptionSelectDateYear] = useState([]);
  useEffect(() => {
    form.setFieldsValue({
      condition: {
        value: {
          arrValue: optionSelectDateYear
        },
        description:
        
          optionSelectDateYear.length > 0
            ? `Lặp lại vào ngày ${optionSelectDateYear?.map((e) => e)} mỗi năm`
            : form.getFieldValue(['condition', 'value', 'typeRepeat']) === 'everyDate' ? "Lặp lại mỗi ngày" : ""
      }
    });
  }, [optionSelectDateYear]);
  return (
    <>
      <Form.Item
        label={'Tần suất lặp lại'}
        name={['condition', 'value', 'typeRepeat']}
        rules={rules(`Chọn tần suất lặp lại`)}
      >
        <Radio.Group
          onChange={(e) => {
            form.setFieldsValue({ condition: { value: { arrValue: [] } , description :  e.target.value === 'everyDate' ? "Lặp lại mỗi ngày" :'' } });
            setOptionSelectDateYear([]);
          }}
        >
          {typeRepeat?.map((e) => (
            <Radio value={get(e, 'value')}>{get(e, 'label', '')}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      {form.getFieldValue(['condition', 'value', 'typeRepeat']) === 'month' && (
        <Divider orientation="left">Những ngày lặp</Divider>
      )}
      <Form.Item shouldUpdate noStyle>
        {() =>
          form.getFieldValue(['condition', 'value', 'typeRepeat']) ===
            'month' && (
            <Form.Item
              label="Những ngày thực thi"
              name={['condition', 'value', 'arrValue']}
              rules={rules('Chọn ngày thực thi')}
            >
              {renderInput(
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%'
                  }}
                  onChange={(value) => {
                    form.setFieldsValue({
                      condition: {
                        description:
                          value?.length > 0
                            ? `Lặp lại vào ngày ${value?.map(
                                (e) => e
                              )} mỗi tháng`
                            : ''
                      }
                    });
                  }}
                  placeholder="Ngày lặp lại hằng tháng"
                  options={range(1, 31)?.map((e) => ({
                    label: `Ngày ${e}`,
                    value: e
                  }))}
                />
              )}
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() =>
          form.getFieldValue(['condition', 'value', 'typeRepeat']) ===
            'year' && (
            <>
              <Form.Item label="Thêm ngày">
                <DatePicker
                  format={'DD-MM'}
                  placeholder="Chọn ngày"
                  disabledDate={(currentDate) =>
                    optionSelectDateYear?.some(
                      (date) =>
                        moment(date).format('DDMMYYYY') ===
                        moment(currentDate).format('DDMMYYYY')
                    )
                  }
                  onChange={(value, dateString) => {
                    dateString &&
                      setOptionSelectDateYear([
                        ...optionSelectDateYear,
                        dateString
                      ]);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Những ngày lặp lại"
                name={['condition', 'value', 'arrValue']}
                rules={rules('Chọn ngày lặp lại')}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    margin: '10px 0'
                  }}
                  onChange={(value) => {
                    setOptionSelectDateYear(value);
                  }}
                  placeholder="Ngày lặp lại hằng năm"
                  options={optionSelectDateYear.map((e) => ({
                    label: e,
                    value: e
                  }))}
                />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        <Form.Item name={['condition', 'description']} label="Mô tả điều kiện">
          {renderInput(<Input disabled />)}
        </Form.Item>
      </Form.Item>
      <Divider orientation="left" dashed>
        Thời gian chạy chương trình
      </Divider>
      <Form.Item
        label="Ngày bắt đầu chương trình"
        name={['condition', 'startDate']}
        rules={rules('chọn ngày bắt đầu')}
      >
        {renderInput(
          <DatePicker placeholder="Chọn ngày" format="DD-MM-YYYY" />
        )}
      </Form.Item>
      <Form.Item
        label="Ngày kết thúc chương trình"
        name={['condition', 'endDate']}
        // rules={rules('chọn ngày kết thúc')}
      >
        {renderInput(
          <DatePicker placeholder="Chọn ngày" format={'DD-MM-YYYY'} />
        )}
      </Form.Item>
      {/* <Form.List
      
        name={['condition', 'value', 'arrValue']}
        rules={rules('Chọn Những ngày chạy trong năm')}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            renderInput(<Space
              key={key}
              style={{
                display: 'flex',
                marginBottom: 8,
                width : '100%',
                // justifyContent : 'space-between',
              }}
              align='baseline'  
              
            >
              <Form.Item
              // noStyle
              label="Ngày lặp lại"
              labelCol={{span : 6}}
                {...restField}
                name={[name]}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn ngày',
                  },
                ]}
              >
                {<DatePicker format={"DD-MM-YYYY"} style={{width : '100%'}}/>}
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>)
          ))}
          <Form.Item>
           <Row justify='center'>
            <Col span={24}>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Thêm ngày
            </Button>
            </Col>
           </Row>
          </Form.Item>
        </>
      )}
    </Form.List> */}
    </>
  );
}
