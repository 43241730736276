import { useEffect, useMemo, useRef, useState } from 'react';

import { Col, DatePicker, Form, Input, InputNumber, Modal, Row, Skeleton, Space, Table, Tabs } from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import {
    useInitWhPaymentVoucher, useResetWhPaymentVoucher, useWhPaymentVoucher
} from '~/hooks/whPaymentVoucher';

import api from '~/api';
import { BaseBorderBox, DebounceSelect } from '~/components/Common';
import { ACCOUNTS, MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';


import './index.scss';
import { floorFormatter } from '~/utils/helper';

const FormItem = Form.Item;
const { TabPane } = Tabs;

const DEFAULT_ACCOUNT = 1111;
const mainRowGutter = 24;

// const mainColLayout = {
//   md: { span: 24 },
//   lg: { span: 12 },
//   xl: { span: 10 }
// };

// const verticalLayout = {
//   wrapperCol: { sm: 24, md: 12 },
//   labelCol: { sm: 24, md: 5, lg: 8 }
// };
const columns = [

    {
      title: 'Diễn giải',
      dataIndex: 'content',
      key: 'content',
      width : '30%',
      render: (item,record) => item
    },
    {
      title: 'TK nợ',
      dataIndex: 'debitAccount',
      key: 'debitAccount',
      render: (item,record) => item + " - " +ACCOUNTS[item]
    },
    {
      title: 'TK có',
      dataIndex: 'creditAccount',
      key: 'creditAccount',
      render: (item,record) => item + " - " +ACCOUNTS[item]
    },
    {
      title: 'Số tiền',
      dataIndex: 'amountOfMoney',
      key: 'amountOfMoney',
      render: (item,record) => floorFormatter(item)
    },
  ];
const WhPaymentVoucherForm = ({
  onCancel,
  whPaymentVoucherId,
  whBill,
  whBillItem,
  noBillId
}) => {
  const [form] = Form.useForm();

  const id = whPaymentVoucherId;
  const query = useMemo(() => whBill ? null : ({id,noBillId}),[id,noBillId])
  const [whPaymentVoucher, isGetWhPaymentVoucherLoading] = useWhPaymentVoucher(query)
  const initWhPaymentVoucher = useInitWhPaymentVoucher(whPaymentVoucher);

  const [initCustomerAccounts, setInitCustomerAccounts] = useState([]);
  const [initStaffs, setInitStaffs] = useState([]);


  useEffect(() => {
    const getInitCustomerAccount = async (customerId) => {
      try {
        const { data } = await api.whCustomerManagement.getById(customerId);
        setInitCustomerAccounts([data].map(item => {
          return {
            ...item,
            label: `${get(item, "customerIdFull")} - ${get(item, "fullName")}`,
          }
        }
        ));
      } catch (error) {
        console.error(error);
      }
    }
    const getInitStaff = async (employeeId) => {
      try {
        const response = await api.userEmployee.getById(employeeId);
        form.setFieldsValue({employeeId : get(response,'data.fullName','')})
        setInitStaffs([response]);
      } catch (error) {
        console.error(error);
      }
    }

    form.resetFields();

    let { customerId, employeeId } = initWhPaymentVoucher;

    if (customerId) getInitCustomerAccount(customerId);
    if (employeeId) getInitStaff(employeeId);
  }, [
    initWhPaymentVoucher,
    form,
  ]);

  // Check for the id, if the id is provided, this form is to display an existing wh-voucher.
  // Otherwise this form is to create a new wh-voucher,
  // then use the provided whBill and whBillItem to prefill some fields in the form.
  useEffect(() => {
    const getCustomerAccount = async (customerAccountId) => {
      try {
        const { data } = await api.whCustomerManagement.getById(customerAccountId);
        const { customerIdFull, fullName } = data; // customerId là Mã Khách Hàng theo số tự nhiên
        form.setFieldsValue({
          // ở phiếu thu chi wh-voucher thì Mã Khách Hàng lưu ở `customerNumber`
          customerNumber: {
            value: customerIdFull,
            label: `${customerIdFull} - ${fullName}`,
          },
          customerName: fullName,
        })
      } catch (error) {
        console.error("error...");
        console.error(error);
      }
    }

    let reason;

    if (id && initWhPaymentVoucher) {
      const { accountingDetails } = initWhPaymentVoucher;
      setDataSource(accountingDetails);
    } else {
      if (whBill) {
        const {
          customerAddress,
          customerAddressId,
          customerId,
        } = whBill;

        getCustomerAccount(customerId);

        form.setFieldsValue({
          accountingDate: get(whBill, "createdAt") ? moment(get(whBill, "createdAt")) : null,
          customerAddress,
          customerAddressId,
          customerId,
          // customerName: get(customerAddress, "fullName"),
          /* customerNumber: {
            value: customerId,
            label: customerId.substring(customerId.length - 4, customerId.length),
          }, */
          dateOfIssue: get(whBill, "createdAt") ? moment(get(whBill, "createdAt")) : null,
        });
      }

      if (whBillItem) {
        const whServiceCode = get(whBillItem, "snapshotService.code") || "";
        const whServiceName = (get(whBillItem, "snapshotService.detailName.vi") ? `${get(whBillItem, "snapshotService.name.vi")} - ${get(whBillItem, "snapshotService.detailName.vi")}` : get(whBillItem, "snapshotService.name.vi") ) || "";
        reason = `Chi trả đơn hàng ${get(whBill, "billNumber")}, dịch vụ ${whServiceCode} - ${whServiceName}`
      } else {
        reason = `Chi trả đơn hàng ${get(whBill, "billNumber")}`;
      }

      form.setFieldsValue({ reason });
    }
  }, [
    id,
    initWhPaymentVoucher,
    whBill,
    whBillItem,
  ]);

  // use initWhPaymentVoucher to merge with other data that should be fetched from the API
  const mergedInitWhPaymentVoucher = useMemo(() => {
    if (!id) {
      return {
        ...initWhPaymentVoucher,
        accountingDate :  moment(get(initWhPaymentVoucher,'createdAt',0)).format("DD-MM-YYYY"),
        dateOfIssue :  moment(get(initWhPaymentVoucher,'dateOfIssue',0)).format("DD-MM-YYYY"),
        originalDocument: 0,
      }
    } else {
      // do nothing
    }
    return {
        ...initWhPaymentVoucher,
        accountingDate :  moment(get(initWhPaymentVoucher,'createdAt',0)).format("DD-MM-YYYY"),
        dateOfIssue :  moment(get(initWhPaymentVoucher,'dateOfIssue',0)).format("DD-MM-YYYY"),
      };
  }, [
    id,
    initWhPaymentVoucher,
  ]);

  useEffect(() => {
    form.setFieldsValue(mergedInitWhPaymentVoucher);
  }, [form, mergedInitWhPaymentVoucher,id]);

  useResetWhPaymentVoucher();

  const [dataSource, setDataSource] = useState([
    {
      content: `Chi trả đơn hàng ${get(whBill, "billNumber")}`,
      debitAccount: null,
      creditAccount: DEFAULT_ACCOUNT,
      // amountOfMoney: get(whBillItem, "remainingAmount") || get(whBill, "remainingAmount") || 0,
      amountOfMoney: 0,
    }
  ]);

  const isLoading = isGetWhPaymentVoucherLoading;


  return (
    // <div className="wh-service-form page-wraper page-content">
    <div className="page-wraper">
      <div className="container-fluid">
        {/* <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} phiếu chi`}
          routes={[{ path: PATH_APP.whVoucher.root, title: 'Danh sách phiếu thu chi' }]}
        /> */}
        <Form
          // initialValues={initWhPaymentVoucher}
          // wrapperCol={{ sm: 24, md: 24, lg: 18 }}
          autoComplete="off"
          form={form}
          initialValues={mergedInitWhPaymentVoucher}
          labelAlign="left"
          labelCol={{ sm: 24, md: 24, lg: 4 }}
          requiredMark={false}
          scrollToFirstError
        >
          <Row
            align="top"
            className="staff-form__logo-row"
            gutter={mainRowGutter}
            justify="space-between"
          >
            <Col span={16}>
              <BaseBorderBox title={"Thông tin chung"}>
                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      // label={<>Mã khách hàng{` `}<code>customerNumber</code></>}
                      // wrapperCol={{ lg: 20 }}
                      label={"Mã khách hàng"}
                      labelCol={{ lg: 8 }}
                      name="customerNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập mã khách hàng!',
                        }
                      ]}
                    >
                      {isLoading
                        ? <Skeleton.Input active />
                        : <Input style={{pointerEvents : 'none'}}  bordered={false}/>
                      }
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      // label={<>Tên khách hàng{` `}<code>customerName</code></>}
                      label="Tên khách hàng"
                      labelCol={12}
                      name="customerName"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn tên khách hàng!',
                        }
                      ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      // label={<>Tên khách hàng{` `}<code>customerName</code></>}
                      // labelCol={12}
                      label="Người nộp"
                      name="customerName"
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                  </Col>
                </Row>

                {/* <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      // label={<>Địa chỉ{` `}<code>customerAddressId</code></>}
                      // labelCol={12}
                      label="Địa chỉ"
                      name="customerAddressId"
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input disabled />}
                    </FormItem>
                  </Col>
                </Row> */}

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      // label={<>Địa chỉ{` `}<code>customerAddressId</code></>}
                      // labelCol={12}
                      label="Địa chỉ"
                      name={["customerAddress", "street"]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      name="reason"
                      label="Lý do nộp"
                    // label={<>Lý do nộp{` `}<code>reason</code></>}
                    // labelCol={12}
                    >
                      {isLoading ? <Skeleton.Input active /> :<Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      name="employeeId"
                      label="Nhân viên"
                      // label={<>Nhân viên{` `}<code>employeeId</code></>}
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn nhân viên'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style={{pointerEvents : 'none'}}  bordered={false}/>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <Space>
                      <FormItem
                        label="Kèm theo"
                        labelCol={16}
                        name="originalDocument"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập số chứng từ kèm theo!',
                          }
                        ]}
                      >
                        {isLoading
                          ? <Skeleton.Input active />
                          : (
                            <Input className='addonAfterInput' addonAfter="chứng từ gốc" style={{pointerEvents : 'none'}}  bordered={false}/>
                          )
                        }
                      </FormItem>
                    </Space>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>

            <Col span={8}>
              <BaseBorderBox title={"Chứng từ"}>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      label="Ngày hạch toán"
                      name="accountingDate"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày hạch toán!',
                        }
                      ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                    <FormItem
                      label="Ngày chứng từ"
                      name="dateOfIssue"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày chứng từ!',
                        }
                      ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>

                    <FormItem
                      label="Số chứng từ"
                      name="issueNumber"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập số chứng từ!',
                        }
                      ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>
          </Row>

          <Tabs
            defaultActiveKey={1}
            destroyInactiveTabPane
            onChange={() => { }}
          >
            <TabPane tab="Hạch toán" key={1}>
            <Table dataSource={dataSource} columns={columns}/>

            </TabPane>
            {/* <TabPane tab="Phiếu chi" key="2">
              <WhPaymentVouchers status={WH_PARTNER_STATUS.WAITING} />
            </TabPane> */}
          </Tabs>

        </Form>
      </div>
    </div>
  );
};

export default WhPaymentVoucherForm;
