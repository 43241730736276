import { get } from "lodash"
import { setEndofDate } from "~/utils/helper"
import requester from "./requester"

const whReportMedicines = {
    create :  (medicine) => requester.post('/partner-medical-equipment', medicine),
  getAll :  (query) => requester.get('/partner-medical-equipment', {...query,endDate : setEndofDate(get(query,'endDate',0))}),
  getOne :  (id) => requester.get(`/partner-medical-equipment/${id}`),
  update :  (medicine) => requester.put(`/partner-medical-equipment/${medicine._id}`, medicine),
  delete :  (id) => requester.delete(`/partner-medical-equipment/${id}`),
}
export default whReportMedicines
