import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  useCreateServiceByLocation,
  useDeleteServiceByLocation,
  useServiceByLocation,
  useServiceByLocationDetailPaging,
  useWhCategories
} from '~/hooks';
import SkeletonTable from '../Utils/SkeletonTable';
import { Breadcrumb, WithPermission } from '../Common';
import { Button, Input, Modal, Popconfirm, Table, Typography } from 'antd';
import ServiceByLocationForm from './Form';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import POLICIES from '~/constants/policy';
import { useSelector } from 'react-redux';

const getSelector = (key) => (state) => state.serviceByLocation[key];
const deleteSuccessSelector = getSelector('deleteSuccess');
const createSuccessSelector = getSelector('createSuccess');

const ColumnActions = ({
  locationIndex,
  serviceId,
  deleteServiceByLocation,
  ...restProps
}) => {
  return (
    <div className="custom-table__actions">
    <WithPermission permission={POLICIES.DELETE_LOCATIONSERVICE}>
      <Popconfirm
        title="Bạn muốn xoá dịch vụ này?"
        onConfirm={() => deleteServiceByLocation({ locationIndex, serviceId })}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <Typography.Paragraph style={{ cursor: 'pointer' }} type="danger">
          Xoá
        </Typography.Paragraph>
      </Popconfirm>
      </WithPermission>
    </div>
  );
};

const ServiceByLocationDetail = () => {
  const { id } = useParams();
  const [query, setQuery] = useState({ page: 1, limit: 10 });
  const [isOpen, setIsOpen] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const queryGetCategories = useMemo(
    () => ({ page: 1, limit: MAX_PAGINATION_LIMIT }),
    []
  );
  const [categories, isLoadingCate] = useWhCategories(queryGetCategories);
  const deleteServiceByLocationSuccess = useSelector(deleteSuccessSelector); // Create Success Change After Create
  const createServiceByLocationSuccess = useSelector(createSuccessSelector); 

  const newQuery = useMemo(
    () => ({ locationIndex: id, ...query }),
    [id, query, deleteServiceByLocationSuccess, createServiceByLocationSuccess]
  );
  const [serviceByLocation, isLoading] = useServiceByLocation(newQuery);
  
  const onOpenForm = (id) => {
    setIsOpen(true);
    if (id) {
      setLocationId(id);
    }
  };

  const onCloseForm = () => {
    setIsOpen(false);
  };
  const onParamChange = (newQuery) => {
    setQuery({ ...query, ...newQuery });
  };
  const [isSubmitLoading, handleCreate] = useCreateServiceByLocation(onCloseForm);
  const [, deleteServiceByLocation] = useDeleteServiceByLocation();
  const paging = useServiceByLocationDetailPaging();
  const [searchKeyword, setSearchKeyword] = useState('');

  const onSearch = (e) => {
    setSearchKeyword(e.target.value.toLowerCase());
  };

  const filteredData = useMemo(() => {
    if (!searchKeyword) return serviceByLocation;
    return serviceByLocation.filter((item) =>
      get(item, 'service.name.vi', '').toLowerCase().includes(searchKeyword)
    );
  }, [searchKeyword, serviceByLocation]);

  const columns = [
    {
      title: 'Mã dịch vụ',
      dataIndex: 'service',
      key: 'service',
      width: 200,
      render: (item) => `HC${get(item, 'code')}`
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'service',
      key: 'service',
      width: 600,
      render: (item) => `${get(item, 'name.vi')}`
    },
    {
      title: 'Nhóm dịch vụ',
      dataIndex: 'service',
      key: 'service',
      render: (record) => {
        record = {
          ...record,
          name: categories.find(
            (item) => item._id === get(record, 'whCategoryId')
          )?.name
        };
        return get(record, 'name.vi');
      }
    },

    // {
    //   title: 'Trạng thái',
    //   key: 'status',
    //   dataIndex: 'status',
    //   align: 'center',
    //   width: 200,
    //   render: (status, record) => {
    //     return (
    //       // <WithPermission permission={POLICIES.UPDATE_}>
    //         <Switch
    //           checked={status === 'ACTIVE'}
    //           onChange={(value) =>
    //             onChangeStatus(
    //               get(record, '_id'),
    //               value ? 'ACTIVE' : 'INACTIVE',
    //               isLoading,
    //               record
    //             )
    //           }
    //         />
    //       // </WithPermission>
    //     );
    //     // ) : (
    //     //   <Switch checked={status === 'ACTIVE'} />
    //     // );
    //   }
    // },
    {
      title: 'Thao tác',
      key: 'action',
      width: 100,
      align: 'center',
      render: (record) => {
        record = {
          ...record,
          locationIndex: record.locationIndex,
          serviceId: record.serviceId
        };
        return (
          <ColumnActions
            {...record}
            deleteServiceByLocation={deleteServiceByLocation}
          />
        );
      }
    }
  ];
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={`Chi tiết ${get(serviceByLocation[0], 'location.name')}`}
          routes={[
            {
              path: PATH_APP.serviceByLocation.root,
              title: 'Dịch vụ theo tỉnh/ thành phố'
            }
          ]}
        />
        <div className="page-content__main" style={{ display: 'block' }}>
          <div className="page-wraper__header">
          <Input
            placeholder="Tìm kiếm..."
            onChange={onSearch} 
            style={{ width: 300, marginRight: '10px' }}
            allowClear
          />
          <WithPermission permission={POLICIES.DELETE_LOCATIONSERVICE}>
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={() => {
                setIsOpen(true);
                setLocationId(null);
              }}
            >
              {' '}
              Thêm mới dịch vụ
            </Button>
            </WithPermission>
          </div>
          {isLoading ? (
            <SkeletonTable
              rowCount={filteredData.length}
              columns={columns}
            />
          ) : (
            <Table
              loading={isLoading}
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={filteredData}
              pagination={{
                ...paging,
                onChange(page, pageSize) {
                  onParamChange({ page, limit: pageSize });
                },
                showSizeChanger: true,
                showTotal: (total) => `Tổng cộng: ${total} `
              }}
              size="middle"
            />
          )}
        </div>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <ServiceByLocationForm
          onClose={onCloseForm}
          id={id}
          query={newQuery}
          handleCreate={handleCreate}
          isSubmitLoading={isSubmitLoading}
        />
      </Modal>
    </div>
  );
};

export default ServiceByLocationDetail;
