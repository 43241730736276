import requester from './requester';

const whService = {
  getWhServices: query => requester.get('/wh-service', query),
  getWhServicesDasboard: query => requester.get('/wh-service-all-dashboard', query),
  getWhServicesAvailable: body => requester.post('/wh-service/availableList', body),
  getWhServicesBranch: query => requester.get('/wh-service-branch', query),
  getById: id => requester.get(`/wh-service/${id}?populate=true&raw=true`),
  delete: id => requester.delete(`/wh-service/${id}`),
  create: whService => requester.post('/wh-service', whService),
  update: whService => requester.put(`/wh-service/${whService.id}`, whService),
  copyService: id => requester.post(`/wh-service/${id}/copy`),
  updateServiceBranch: query => requester.put(`/wh-service-branch/update/${query.id}`, query),
  acceptServiceBranch: query => requester.put(`/wh-service-branch/accept/${query.id}`, query),
  createServiceBranch: query => requester.post(`/wh-service-branch/create`, query),
  deleteServiceBranch: query => requester.delete(`/wh-service-branch/delete/${query.id}`, query),
  checkDevice: device => requester.post(`/wh-service-medical-supplier`, device),
  getWhServiceAvailableByBranch: query => requester.post('/wh-service/byCategory', query),
  getWhServiceAvailableByBranchV2: query => requester.post('/wh-service/available-list', query),
  getWhCategoryServiceBranch: query => requester.post('/wh-service-branch/wh-category', query),
};

export default whService;
