import requester from './requester';

const importExcelPotentialPartner = {
  postFilePotential: (file) =>
    requester.post('/importExcelPartner', file, {
      'Content-Type': 'multipart/form-data'
    })
};

export default importExcelPotentialPartner;
