import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getPartners,
  createPartner,
  deletePartner,
  getPartner,
  resetPartnerState,
  updatePartner,
  getPartnerBranches,
  updateBranchInPartner,
  deleteBranchInPartner,
  updateBranchStatusPartner,
  updateBranchStatus,
  createBranchInPartner,
  accessPermissionPartner,
  denyPermissionPartner,
  getListAccessPermissionPartner,
  getResourcePartner,
  accessPermissionClinic,
  denyPermissionClinic,
  getListAccessPermissionClinic,
  getResourceClinic,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { get } from 'lodash';

const PARTNER_MODULE = 'partner';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(PARTNER_MODULE);

const getSelector = (key) => (state) => state[PARTNER_MODULE][key];

const getBranchesLoadingSelector = getSelector('isGetBranchesLoading');
const branchesSelector = getSelector('branches');
const getBranchesFailedSelector = getSelector('getBranchesFailed');

const updateBranchSuccessSelector = getSelector('updateBranchSuccess');
const updateBranchFailedSelector = getSelector('updateBranchFailed');

const isSubmitBranchLoadingSelector = getSelector('isSubmitBranchLoading');
const createBranchSuccessSelector = getSelector('createBranchSuccess');
const createBranchFailedSelector = getSelector('createBranchFailed');

const useUpdateBranchStatusFailedSelector = getSelector('updateBranchStatusFailed');
const useUpdateBranchStatusSuccessSelector = getSelector('updateBranchStatusSuccess');

const deleteBranchSuccessSelector = getSelector('deleteBranchSuccess');
const deleteBranchFailedSelector = getSelector('deleteBranchFailed');

const getListAccessPermissionPartnerSelector = getSelector('getListAccessPermissionPartner');
const loadingGetListAccessPermissionPartnerSelector = getSelector('loadingGetListAccessPermissionPartner');
const getListAccessPermissionPartnerFailedSelector = getSelector('getListAccessPermissionPartnerFailed');

const accessPermissionPartnerSuccessSelector = getSelector('accessPermissionPartnerSuccess');
const accessPermissionPartnerFailedSelector = getSelector('accessPermissionPartnerFailed');

const denyPermissionPartnerSuccessSelector = getSelector('denyPermissionPartnerSuccess');
const denyPermissionPartnerFailedSelector = getSelector('denyPermissionPartnerFailed');

const loadingGetResourcePartnerSelector = getSelector('loadingGetResourcePartner');
const resourcePartnerSelector = getSelector('resourcePartner');
const getResourcePartnerFailedSelector = getSelector('getResourcePartnerFailed');

// Clinic
const getListAccessPermissionClinicSelector = getSelector('getListAccessPermissionClinic');
const loadingGetListAccessPermissionClinicSelector = getSelector('loadingGetListAccessPermissionClinic');
const getListAccessPermissionClinicFailedSelector = getSelector('getListAccessPermissionClinicFailed');

const accessPermissionClinicSuccessSelector = getSelector('accessPermissionClinicSuccess');
const accessPermissionClinicFailedSelector = getSelector('accessPermissionClinicFailed');

const denyPermissionClinicSuccessSelector = getSelector('denyPermissionClinicSuccess');
const denyPermissionClinicFailedSelector = getSelector('denyPermissionClinicFailed');

const loadingGetResourceClinicSelector = getSelector('loadingGetResourceClinic');
const resourceClinicSelector = getSelector('resourceClinic');
const getResourceClinicFailedSelector = getSelector('getResourceClinicFailed');


const pagingSelector = getSelector('paging');

export const usePartners = (query) => {
  return useFetchByParam({
    action: getPartners,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreatePartner = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới đối tác thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createPartner
  });
};

export const useUpdatePartner = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật đối tác thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật đối tác thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updatePartner
  });
};

export const useDeletePartner = (onDeleteSuccess) => {
  useSuccess(deleteSuccessSelector, 'Xoá đối tác thành công', onDeleteSuccess);
  useFailed(deleteFailedSelector, 'Xoá đối tác thất bại');

  return useSubmit({
    loadingSelector,
    action: deletePartner
  });
};

export const useDeleteBranchInPartner = (onDeleteSuccess) => {
  useSuccess(deleteBranchSuccessSelector, 'Xoá chi nhánh thành công', onDeleteSuccess);
  useFailed(deleteBranchFailedSelector, 'Chưa thể xoá phòng khám này');

  return useSubmit({
    loadingSelector,
    action: deleteBranchInPartner
  });
};

export const useCreateBranchInPartner = (callback) => {
  useSuccess(createBranchSuccessSelector, 'Tạo mới chi nhánh thành công', callback);
  useFailed(createBranchFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitBranchLoadingSelector,
    action: createBranchInPartner
  });
};

export const useUpdateBranchInPartner = (callback) => {
  useSuccess(updateBranchSuccessSelector, 'Cập nhật chi nhánh thành công', callback);
  useFailed(updateBranchFailedSelector, 'Cập nhật chi nhánh thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateBranchInPartner
  });
};

export const usePartner = (params) => {
  return useFetchByParam({
    action: getPartner,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitPartner = (partner, id) => {
  return useMemo(() => {
    if (!partner || !id) {
      return {};
    }

    const initCompany = {
      ...partner
    };

    return initCompany;
  }, [partner, id]);
};

const getBranchSelector = (key) => (state) => state.branch[key];

export const usePartnerBranches = (id,typeGet = 'BRANCH') => {
  // const createBranchSuccess = useSelector(getBranchSelector('createSuccess'));
  const updateBranchSuccess = useSelector(getBranchSelector('updateSuccess'));
  const deleteBranchSuccess = useSelector(deleteBranchSuccessSelector);
  const updateBranchInPartnerSuccess = useSelector(updateBranchSuccessSelector)
  const createBranchSuccess = useSelector(createBranchSuccessSelector)

  //eslint-disable-next-line
  const param = useMemo(
    () => (id ? { id,typeGet } : null),
    [id, createBranchSuccess, updateBranchSuccess, deleteBranchSuccess,updateBranchInPartnerSuccess,typeGet]
  );
  return useFetchByParam({
    action: getPartnerBranches,
    loadingSelector: getBranchesLoadingSelector,
    dataSelector: branchesSelector,
    failedSelector: getBranchesFailedSelector,
    param
  });
};

export const useUpdatePartnerParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    let path = '';
    query.modules === 'PHARMACY' ? (path = 'pharmacies') : (path = 'clinics');
    history.push({
      pathname: `/${path}`,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const usePartnerQueryParams = (module) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  const modules = module;
  // const page = query.get('page');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);
  const deleteBranchSuccess = useSelector(deleteBranchSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
    
    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      cityId,
      modules
    });

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    cityId,
    modules,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    deleteBranchSuccess
  ]);
};

export const useUpdateBranchStatus = (callback) => {
  useSuccess(useUpdateBranchStatusSuccessSelector, 'Cập nhật trạng thái chi nhánh thành công', callback);
  useFailed(useUpdateBranchStatusFailedSelector);

  return useSubmit({
    loadingSelector,
    action: updateBranchStatus
  });
};

export const useGetListAccessPermissionPartner = (params) => {
  return useFetchByParam({
    action: getListAccessPermissionPartner,
    loadingSelector: loadingGetListAccessPermissionPartnerSelector,
    dataSelector: getListAccessPermissionPartnerSelector,
    failedSelector: getListAccessPermissionPartnerFailedSelector,
    param: params
  });
};

export const useGetResourcePartner = (params) => {
  return useFetchByParam({
    action: getResourcePartner,
    loadingSelector: loadingGetResourcePartnerSelector,
    dataSelector: resourcePartnerSelector,
    failedSelector: getResourcePartnerFailedSelector,
    param: params
  });
};

export const useAccessPermissionPartner = (callback) => {
  useSuccess(accessPermissionPartnerSuccessSelector, '', callback);
  useFailed(accessPermissionPartnerFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: accessPermissionPartner
  });
};

export const useDenyPermissionPartner = (callback) => {
  useSuccess(denyPermissionPartnerSuccessSelector, '', callback);
  useFailed(denyPermissionPartnerFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: denyPermissionPartner
  });
};

// Branch
export const useGetListAccessPermissionClinic = (params) => {
  return useFetchByParam({
    action: getListAccessPermissionClinic,
    loadingSelector: loadingGetListAccessPermissionClinicSelector,
    dataSelector: getListAccessPermissionClinicSelector,
    failedSelector: getListAccessPermissionClinicFailedSelector,
    param: params
  });
};

export const useGetResourceClinic = (params) => {
  return useFetchByParam({
    action: getResourceClinic,
    loadingSelector: loadingGetResourceClinicSelector,
    dataSelector: resourceClinicSelector,
    failedSelector: getResourceClinicFailedSelector,
    param: params
  });
};

export const useAccessPermissionClinic = (callback) => {
  useSuccess(accessPermissionClinicSuccessSelector, '', callback);
  useFailed(accessPermissionClinicFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: accessPermissionClinic
  });
};

export const useDenyPermissionClinic = (callback) => {
  useSuccess(denyPermissionClinicSuccessSelector, '', callback);
  useFailed(denyPermissionClinicFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: denyPermissionClinic
  });
};

export const usePartnerPaging = () => useSelector(pagingSelector);

export const useResetPartner = () => {
  useResetState(resetPartnerState);
};
