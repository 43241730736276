import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getCoupons({ payload: query }) {
  try {
    const data = yield call(Api.coupon.getAll, query);
    yield put({ type: Types.GET_COUPONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_COUPONS_FAILED,
      payload: error.message
    });
  }
}

function* getCoupon({ payload }) {
  try {
    const { data: coupon } = yield call(Api.coupon.getById, payload);
    yield put({
      type: Types.GET_COUPON_SUCCESS,
      payload: coupon
    });
  } catch (error) {
    yield put({
      type: Types.GET_COUPON_FAILED,
      payload: error.message
    });
  }
}

function* createCoupon(action) {
  try {
    const data = yield call(Api.coupon.create, action.payload);
    yield put({ type: Types.CREATE_COUPON_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_COUPON_FAILED,
      payload: error
    });
  }
}

function* updateCoupon(action) {
  try {
    const data = yield call(Api.coupon.update, action.payload);
    yield put({ type: Types.UPDATE_COUPON_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_COUPON_FAILED,
      payload: error
    });
  }
}

function* deleteCoupon({ payload }) {
  try {
    yield call(Api.coupon.delete, payload);
    yield put({ type: Types.DELETE_COUPON_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_COUPON_FAILED,
      payload: error
    });
  }
}

function* getCouponServiceCategories({payload}){
  try {
    const data = yield call(Api.coupon.getCouponServiceCategories, payload);
    yield put({ type: Types.GET_COUPON_SERVICE_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_COUPON_SERVICE_CATEGORY_FAILED,
      payload: error
    });
  }
}

export default function* coupon() {
  yield takeLatest(Types.GET_COUPONS_REQUEST, getCoupons);
  yield takeLatest(Types.GET_COUPON_REQUEST, getCoupon);
  yield takeLatest(Types.DELETE_COUPON_REQUEST, deleteCoupon);
  yield takeLatest(Types.CREATE_COUPON_REQUEST, createCoupon);
  yield takeLatest(Types.UPDATE_COUPON_REQUEST, updateCoupon);
  yield takeLatest(Types.GET_COUPON_SERVICE_CATEGORY_REQUEST, getCouponServiceCategories)
}
