export const GET_POSITION_JOBS_REQUEST =
    'GET_POSITION_JOBS_REQUEST';
export const GET_POSITION_JOBS_SUCCESS =
    'GET_POSITION_JOBS_SUCCESS';
export const GET_POSITION_JOBS_FAILED = 'GET_POSITION_JOBS_FAILED';

export const GET_POSITION_JOB_REQUEST = 'GET_POSITION_JOB_REQUEST';
export const GET_POSITION_JOB_SUCCESS = 'GET_POSITION_JOB_SUCCESS';
export const GET_POSITION_JOB_FAILED = 'GET_POSITION_JOB_FAILED';

export const CREATE_POSITION_JOB_REQUEST =
    'CREATE_POSITION_JOB_REQUEST';
export const CREATE_POSITION_JOB_SUCCESS =
    'CREATE_POSITION_JOB_SUCCESS';
export const CREATE_POSITION_JOB_FAILED =
    'CREATE_POSITION_JOB_FAILED';

export const UPDATE_POSITION_JOB_REQUEST =
    'UPDATE_POSITION_JOB_REQUEST';
export const UPDATE_POSITION_JOB_SUCCESS =
    'UPDATE_POSITION_JOB_SUCCESS';
export const UPDATE_POSITION_JOB_FAILED =
    'UPDATE_POSITION_JOB_FAILED';


export const DELETE_POSITION_JOB_REQUEST =
    'DELETE_POSITION_JOB_REQUEST';
export const DELETE_POSITION_JOB_SUCCESS =
    'DELETE_POSITION_JOB_SUCCESS';
export const DELETE_POSITION_JOB_FAILED =
    'DELETE_POSITION_JOB_FAILED';


export const RESET_POSITION_JOB_STATE = 'RESET_POSITION_JOB_STATE';
export const RESET_POSITION_JOB_ACTION = 'RESET_POSITION_JOB_ACTION';
