export const GET_PRODUCT_LISTS_REQUEST = 'GET_PRODUCT_LISTS_REQUEST';
export const GET_PRODUCT_LISTS_SUCCESS = 'GET_PRODUCT_LISTS_SUCCESS';
export const GET_PRODUCT_LISTS_FAILED = 'GET_PRODUCT_LISTS_FAILED';

export const GET_PRODUCT_LIST_REQUEST = 'GET_PRODUCT_LIST_REQUEST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAILED = 'GET_PRODUCT_LIST_FAILED';

export const GET_PRODUCT_LIST_HISTORY_REQUEST = 'GET_PRODUCT_LIST_HISTORY_REQUEST';
export const GET_PRODUCT_LIST_HISTORY_SUCCESS = 'GET_PRODUCT_LIST_HISTORY_SUCCESS';
export const GET_PRODUCT_LIST_HISTORY_FAILED = 'GET_PRODUCT_LIST_HISTORY_FAILED';

export const CREATE_PRODUCT_LIST_REQUEST = 'CREATE_PRODUCT_LIST_REQUEST';
export const CREATE_PRODUCT_LIST_SUCCESS = 'CREATE_PRODUCT_LIST_SUCCESS';
export const CREATE_PRODUCT_LIST_FAILED = 'CREATE_PRODUCT_LIST_FAILED';

export const UPDATE_PRODUCT_LIST_REQUEST = 'UPDATE_PRODUCT_LIST_REQUEST';
export const UPDATE_PRODUCT_LIST_SUCCESS = 'UPDATE_PRODUCT_LIST_SUCCESS';
export const UPDATE_PRODUCT_LIST_FAILED = 'UPDATE_PRODUCT_LIST_FAILED';

export const STOP_DELIVERY_PRODUCT_REQUEST = 'STOP_DELIVERY_PRODUCT_REQUEST';
export const STOP_DELIVERY_PRODUCT_SUCCESS = 'STOP_DELIVERY_PRODUCT_SUCCESS';
export const STOP_DELIVERY_PRODUCT_FAILED = 'STOP_DELIVERY_PRODUCT_FAILED';

export const DELETE_PRODUCT_LIST_REQUEST = 'DELETE_PRODUCT_LIST_REQUEST';
export const DELETE_PRODUCT_LIST_SUCCESS = 'DELETE_PRODUCT_LIST_SUCCESS';
export const DELETE_PRODUCT_LIST_FAILED = 'DELETE_PRODUCT_LIST_FAILED';

export const RESET_PRODUCT_LIST_STATE = 'RESET_PRODUCT_LIST_STATE';
export const RESET_PRODUCT_LIST_STATE_ACTION = 'RESET_PRODUCT_LIST_STATE_ACTION';

export const GET_PRODUCT_LISTS_PARTNER_BORROWED_REQUEST = 'GET_PRODUCT_LISTS_PARTNER_BORROWED_REQUEST';
export const GET_PRODUCT_LISTS_PARTNER_BORROWED_SUCCESS = 'GET_PRODUCT_LISTS_PARTNER_BORROWED_SUCCESS';
export const GET_PRODUCT_LISTS_PARTNER_BORROWED_FAILED = 'GET_PRODUCT_LISTS_PARTNER_BORROWED_FAILED';


export const GET_PRODUCT_OF_WHPARTNER_REQUEST = 'GET_PRODUCT_OF_WHPARTNER_REQUEST';
export const GET_PRODUCT_OF_WHPARTNER_SUCCESS = 'GET_PRODUCT_OF_WHPARTNER_SUCCESS';
export const GET_PRODUCT_OF_WHPARTNER_FAILED = 'GET_PRODUCT_OF_WHPARTNER_FAILED';
