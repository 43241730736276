import { useMemo, useState } from 'react';

import { Button, Input, Modal, Popconfirm, Table, Typography } from 'antd';
import { get } from 'lodash';

import { WithPermission } from '~/components/Common';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import POLICIES from '~/constants/policy';
import {
  useCreateServiceByLocation,
  useDeleteServiceByLocation,
  useServiceByLocation,
  useServiceByLocationDetailPaging
} from '~/hooks';
import AssignLocationForm from './Form';
import { useSelector } from 'react-redux';

const getSelector = (key) => (state) => state.serviceByLocation[key];
const deleteSuccessSelector = getSelector('deleteSuccess');
const createSuccessSelector = getSelector('createSuccess');

const AssignLocation = ({ id }) => {
  const deleteServiceByLocationSuccess = useSelector(deleteSuccessSelector); // Create Success Change After Create
  const createServiceByLocationSuccess = useSelector(createSuccessSelector); 
  const [query, setQuery] = useState({ page: 1, limit: 10 });
  const [isOpenForm, setIsOpenForm] = useState(false);

  const [serviceId, setServiceId] = useState(id);

  const newQuery = useMemo(
    () => ({ serviceId: serviceId, ...query }),
    [serviceId, query, deleteServiceByLocationSuccess, createServiceByLocationSuccess] // Dependencies Trigger Fetch 
  );
  const [serviceByLocation, isLoading] = useServiceByLocation(newQuery);

  const onCloseForm = () => {
    setIsOpenForm(false);
  };

  const onParamChange = (newQuery) => {
    setQuery({ ...query, ...newQuery });
  };

  const [isSubmitLoading, handleCreate] = useCreateServiceByLocation(onCloseForm);
  const [, deleteServiceByLocation] = useDeleteServiceByLocation();
  const paging = useServiceByLocationDetailPaging();
  const [searchKeyword, setSearchKeyword] = useState('');

  const onSearch = (e) => {
    setSearchKeyword(e.target.value.toLowerCase());
  };

  const filteredData = useMemo(() => {
    if (!searchKeyword) return serviceByLocation;
    return serviceByLocation.filter((item) =>
      get(item, 'location.full_name', '').toLowerCase().includes(searchKeyword)
    );
  }, [searchKeyword, serviceByLocation]);
  
  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '50px',
      render: (text, record, index) => {
        return (+query.page - 1) * query.limit + index + 1;
      }
    },
    {
      title: 'Tỉnh, thành phố',
      dataIndex: 'location',
      key: 'location',
      render: (location) => get(location, 'full_name')
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 100,
      align: 'center',
      render: (record) => {
        const { locationIndex, serviceId } = record;

        record = {
          ...record,
          locationIndex: record.locationIndex,
          serviceId: record.serviceId
        };
        return (
          <div className="custom-table__actions">
            <WithPermission permission={POLICIES.DELETE_LOCATIONSERVICE}>
              <Popconfirm
                title="Bạn muốn xoá tỉnh, thành phố này?"
                onConfirm={() =>
                  deleteServiceByLocation({ locationIndex, serviceId })
                }
                okText="Xoá"
                cancelText="Huỷ"
              >
                <Typography.Paragraph
                  style={{ cursor: 'pointer' }}
                  type="danger"
                >
                  Xoá
                </Typography.Paragraph>
              </Popconfirm>
            </WithPermission>
          </div>
        );
      }
    }
  ];
  

  return (
    <>
      <div className="page-content__main" style={{ display: 'block' }}>
        <div className="page-wraper__header">
          <Input
            placeholder="Tìm kiếm tỉnh, thành phố"
            onChange={onSearch} 
            style={{ width: 300, marginRight: '10px' }}
            allowClear
          />
          <WithPermission permission={POLICIES.DELETE_LOCATIONSERVICE}>
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={() => {
                setIsOpenForm(true);
                setServiceId(serviceId);
              }}
            >
              {' '}
              Thêm mới tỉnh, thành phố
            </Button>
          </WithPermission>
        </div>
        {isLoading ? (
          <SkeletonTable
            rowCount={serviceByLocation.length}
            columns={columns}
          />
        ) : (
          <Table
            loading={isLoading}
            rowKey={(rc) => rc._id || rc.id}
            columns={columns}
            dataSource={filteredData} 
            pagination={{
              ...paging,
              onChange(page, pageSize) {
                onParamChange({ page, limit: pageSize });
              },
              showSizeChanger: true,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
            size="middle"
          />
        )}
      </div>
      <Modal
        width={1000}
        visible={isOpenForm}
        onCancel={() => setIsOpenForm(false)}
        onOk={() => setIsOpenForm(false)}
        footer={null}
        destroyOnClose
      >
        <AssignLocationForm
          onClose={onCloseForm}
          id={serviceId}
          // query={newQuery}
          handleCreate={handleCreate}
          isSubmitLoading={isSubmitLoading}
        />
      </Modal>
    </>
  );
};

export default AssignLocation;
