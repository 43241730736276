import TabBranch from '~/components/Common/TabBranch';
import StatusConfigList from './StatusConfig';
export default function StatusConfig() {
  return (
    <div className="branch-detail page-wraper  page-content page-workflow">
      <div className="container-fluid">
        <h5>Danh sách cấu hình trạng thái</h5>
        <TabBranch>
          <StatusConfigList />
        </TabBranch>
      </div>
    </div>
  );
}
