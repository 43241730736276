import * as Types from '../../constants/actionTypes';

export const getWhPartnersImport = (query) => ({
  type: Types.GET_WH_PARTNERS_IMPORT_REQUEST,
  payload: query
});

export const getWhPartnerImport = (id) => ({
  type: Types.GET_WH_PARTNER_IMPORT_REQUEST,
  payload: id
});

export const createWhPartnerImport = (whPartnerImport) => ({
  type: Types.CREATE_WH_PARTNER_IMPORT_REQUEST,
  payload: whPartnerImport
});

export const updateWhPartnerImport = (whPartnerImport) => ({
  type: Types.UPDATE_WH_PARTNER_IMPORT_REQUEST,
  payload: whPartnerImport
});

export const deleteWhPartnerImport = (id) => ({
  type: Types.DELETE_WH_PARTNER_IMPORT_REQUEST,
  payload: id
});

export const convertWhPartnerImport = (whPartnerImport) => ({
  type: Types.CONVERT_WH_PARTNER_IMPORT_REQUEST,
  payload: whPartnerImport
});

export const resetWhPartnerImportState = () => ({
  type: Types.RESET_WH_PARTNER_IMPORT_STATE
});
