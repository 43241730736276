import requester from './requester';

const order = {
  getPharmacies: (query) => requester.get('/pharmacy', query),
  getAllOrders: (query) =>
    requester.get('/order', {
      ...query,
      sortBy: 'createdAt',
      sortDirection: 'desc'
    }),
  getPendingOrders: (query) =>
    requester.get('/order/pending', {
      ...query,
      sortBy: 'createdAt',
      sortDirection: 'desc'
    }),
  getShippingVendors: () => requester.get('/shipping-vendor'),
  getById: (id) => requester.get(`/order/${id}`),
  assignOrder: (id, data) => requester.put(`/order/${id}/assign`, data),
  updateItem: (id, data) => requester.put(`/order/${id}/update-item`, data),
  processOrder: (id) => requester.put(`/order/${id}/process`),
  rejectOrder: (id) => requester.put(`/order/${id}/reject`),
  confirmOrder: (id) => requester.put(`/order/${id}/confirm`),
  packageOrder: (id) => requester.put(`/order/${id}/package`),
  shippingOrder: (id, data) => requester.put(`/order/${id}/shipping`, data),
  completeOrder: (id) => requester.put(`/order/${id}/complete`),
  cancelOrder: (id, data) => requester.put(`/order/${id}/cancel`, data),
  delete: (id) => requester.delete(`/order/${id}`),
  addMedicineToOrder: (news) => requester.post('/news', news),
  updateOrderMedicine: (news) => requester.put(`/news/${news.id}`, news),
  deleteOrderMedicine: (id) => requester.delete(`/news/${id}`),
  getByIdCustomer: (query) => requester.get(`/order-customer`, query)
};

export default order;
