import requester from './requester';

const PreBooking = {
  getById: (id) => requester.get(`/pre-booking/${id}`),
  create: (config) => requester.post(`/pre-booking`, config),
  update:(config) => requester.put(`/pre-booking/${config._id}`,config),
  getAll : (query) => requester.get(`/pre-booking`,query),
  delete: (id) => requester.delete(`/pre-booking/${id}`),
  
};

export default PreBooking;
