import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getServices({ payload: query }) {
  try {
    const { docs } = yield call(Api.service.getAll, query);
    yield put({ type: Types.GET_SERVICES_SUCCESS, payload: docs });
  } catch (error) {
    yield put({ type: Types.GET_SERVICES_FAILED, payload: error.message });
  }
}

export default function* service() {
  yield takeLatest(Types.GET_SERVICES_REQUEST, getServices);
}
