import produce from 'immer';
import {
  GET_DELIVERY_VOUCHERS_REQUEST,
  GET_DELIVERY_VOUCHERS_SUCCESS,
  GET_DELIVERY_VOUCHERS_FAILED,
  GET_DELIVERY_VOUCHER_REQUEST,
  GET_DELIVERY_VOUCHER_SUCCESS,
  GET_DELIVERY_VOUCHER_FAILED,
  CREATE_DELIVERY_VOUCHER_REQUEST,
  CREATE_DELIVERY_VOUCHER_SUCCESS,
  CREATE_DELIVERY_VOUCHER_FAILED,
  DELETE_DELIVERY_VOUCHER_REQUEST,
  DELETE_DELIVERY_VOUCHER_SUCCESS,
  DELETE_DELIVERY_VOUCHER_FAILED,
  UPDATE_DELIVERY_VOUCHER_REQUEST,
  UPDATE_DELIVERY_VOUCHER_SUCCESS,
  UPDATE_DELIVERY_VOUCHER_FAILED,
  RESET_DELIVERY_VOUCHER_STATE,
  RESET_STORE
} from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_DELIVERY_VOUCHERS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case GET_DELIVERY_VOUCHERS_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case GET_DELIVERY_VOUCHERS_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      return;

    case GET_DELIVERY_VOUCHER_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case GET_DELIVERY_VOUCHER_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case GET_DELIVERY_VOUCHER_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case DELETE_DELIVERY_VOUCHER_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_DELIVERY_VOUCHER_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_DELIVERY_VOUCHER_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_DELIVERY_VOUCHER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_DELIVERY_VOUCHER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_DELIVERY_VOUCHER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case UPDATE_DELIVERY_VOUCHER_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_DELIVERY_VOUCHER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      return;

    case UPDATE_DELIVERY_VOUCHER_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case RESET_DELIVERY_VOUCHER_STATE:
    case RESET_STORE:
      state.isLoading = false;
      state.getListFailed = undefined;

      state.isGetByIdLoading = false;
      state.byId = null;
      state.getByIdFailed = null;

      state.deleteSuccess = null;
      state.deleteFailed = null;

      state.isSubmitLoading = false;
      state.createSuccess = null;
      state.createFailed = null;

      state.updateSuccess = null;
      state.updateFailed = null;
      return ;

    default:
      return;
  }
}, initState);
