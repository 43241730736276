import * as Types from '../../constants/actionTypes';
  
  export const getConfigWhPartnerRegisterTypeForms = query => ({
    type: Types.GET_CONFIGWHPARTNERREGISTERTYPEFORMS_REQUEST,
    payload: query
  });
  export const getConfigWhPartnerRegisterTypeForm = id => ({
    type: Types.GET_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST,
    payload: id
  });
  export const createConfigWhPartnerRegisterTypeForm = instance => ({
    type: Types.CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST,
    payload: instance
  });
  export const updateConfigWhPartnerRegisterTypeForm = instance => ({
    type: Types.UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST,
    payload: instance
  });
  export const deleteConfigWhPartnerRegisterTypeForm = id => ({
    type: Types.DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST,
    payload: id
  });
  export const resetConfigWhPartnerRegisterTypeFormState = () => ({
    type: Types.RESET_CONFIGWHPARTNERREGISTERTYPEFORM_STATE
  });
  export const resetConfigWhPartnerRegisterTypeFormStateAction = () => ({
    type: Types.RESET_CONFIGWHPARTNERREGISTERTYPEFORM_STATE_ACTION
  });
