import { forIn, get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getSelectors,
    useFailed,
    useFetchByParam,
    useQueryParams,
    useResetState,
    useSubmit,
    useSuccess
} from '~/hooks/utils';
import { createRewardItem, deleteRewardItem, getRewardItem, getRewardItems, resetRewardItemState, resetRewardItemStateAction, updateRewardItem } from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.rewardItem[key];
const pagingSelector = getSelector('paging');
export const useRewardItemPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetRewardItemSelector = getSelector('isGetRewardItemLoading');
const getRewardItemsFailedSelector = getSelector('getRewardItemsFailed');
const getRewardItemFailedSelector = getSelector('getRewardItemFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const rewardItemsSelector = getSelector('rewardItems');
const rewardItemSelector = getSelector('rewardItem');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useRewardItemQueryParams = () => {
    const query = useQueryParams();
    const limit = query.get('limit') || 10;
    const page = query.get('page') || 1;
    const types = query.get('types');
    const point = query.get('point');
    const item = query.get('item');
    const sortPoint = query.get('sortPoint');
    const keyword = query.get('keyword')
    const createRewardItemSuccess = useSelector(createSuccessSelector);
    const updateRewardItemSuccess = useSelector(updateSuccessSelector);
    const deleteRewardItemSuccess = useSelector(deleteSuccessSelector);
  
    return useMemo(() => {
      const queryParams = {
        page,
        limit,
        keyword,
        types,
        point,
        item,
        sortPoint
      };
      return [queryParams];
      //eslint-disable-next-line
    }, [
      page,
      limit,
      createRewardItemSuccess,
      updateRewardItemSuccess,
      deleteRewardItemSuccess,
      keyword,
      types,
        point,
        item,
        sortPoint
    ]);
  };

  export const useUpdateRewardItemParams = (query) => {
    const history = useHistory();
    const [keyword, setKeyword] = useState(get(query,'keyword'));
    const listSearch = {item:null,point:null}
    useEffect(() => {
      setKeyword(get(query,'keyword'));
    },[query.keyword])
    const onParamChange = (param) => {
      if (!param.page) {
        query.page = 1;
      }
      history.push({
        pathname: get(param, 'customPathName') || PATH_APP.referral.RewardItem,
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword ,{setKeyword, onParamChange },listSearch];
  };
  export const useRewardItems = (query) => {
    return useFetchByParam({
      action: getRewardItems,
      loadingSelector,
      dataSelector: rewardItemsSelector,
      failedSelector: getRewardItemsFailedSelector,
      param: query
    });
  };
  export const useRewardItem = (id) => {
    return useFetchByParam({
      action: getRewardItem,
      loadingSelector:loadinggetRewardItemSelector,
      dataSelector: rewardItemSelector,
      failedSelector: getRewardItemFailedSelector,
      param: id
    });
  };
export const useCreateRewardItem = (callback) => {
    useSuccess(createSuccessSelector, 'Tạo vật phẩm phần thưởng thành công',callback);
    useFailed(createFailedSelector);
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: createRewardItem
    });
  };
export const useUpdateRewardItem = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhật vật phẩm phần thưởng thành công',callback);
    useFailed(updateFailedSelector);
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateRewardItem
    });
  };
export const useDeleteRewardItem = (callback) => {
    useSuccess(deleteSuccessSelector, 'Xoá vật phẩm phần thưởng thành công',callback);
    useFailed(deleteFailedSelector, 'Xoá vật phẩm phần thưởng thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: deleteRewardItem
    });
  };

  export const useResetRewardItem = () => {
    useResetState(resetRewardItemState);
  };
  export const useResetRewardItemAction = () => {
    useResetState(resetRewardItemStateAction);
  };
