import { CheckOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Col, Dropdown, List, Menu, Row } from 'antd';
import { debounce, get } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { STATUS_READ } from '~/constants/myNotification';
import { useChangeManyStatusNotification, useChangeStatusNotification, useMyNotificationPaging, useMyNotificationQueryParams, useMyNotifications, useResetMyNotification, useUpdateMyNotificationParams } from '~/hooks/myNotification';
import { getMyNotifications } from '~/redux/action';
import { Breadcrumb } from '../Common';
import GroupButtonNotify from '../CommonForBoth/TopbarDropdown/GroupButtonNotify';
import NotificationItem from '../CommonForBoth/TopbarDropdown/NotificationItem';
import SkeletonList from '../SkeletonList';

export default function MyNotification() {
  const dispatch = useDispatch();
  const [query] = useMyNotificationQueryParams();
  const [MyNotifications, isLoading] = useMyNotifications(query);
  const [{ onParamChange }] = useUpdateMyNotificationParams(query);
  const paging = useMyNotificationPaging();
  useResetMyNotification();
  const triggerRefresh = () => {
    dispatch(getMyNotifications(query))
  };
  const [, updateStatus] = useChangeStatusNotification();
  const [, updateMany] = useChangeManyStatusNotification();

  const onUpdateCurrentPage = () => {
    const notificationUnread = MyNotifications?.filter(notification => get(notification, 'status') === STATUS_READ.unread);
    if (!!notificationUnread && notificationUnread?.length) {
      let ids = notificationUnread?.map(i => get(i, '_id'))?.join(',');
      updateMany({ ids, status: STATUS_READ.read })
    }
  }
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">

        <Breadcrumb title="Thông báo của tôi" />
        <div className="page-content__main" >
          <div className="page-content__right" style={{ width: '50%', margin: 'auto' }}>
            <Row justify='space-between'>
              <Col>
                <GroupButtonNotify status={get(query, 'status')} setStatus={(stt) => onParamChange({ status: stt })} />
              </Col>
              <Col>
                <Dropdown className='mx-auto' overlay={
                  <Menu className='notification-item--actionGroup__menu'>
                    <Menu.Item key="0" onClick={onUpdateCurrentPage} icon={<CheckOutlined />}>
                      <span>Đánh dấu trang hiện tại là đã đọc</span>
                    </Menu.Item>
                    {/* <Menu.Item key="1" icon={<CheckOutlined />}>
                      <span>Đánh dấu tất cả là đã đọc</span>
                    </Menu.Item> */}
                  </Menu>} trigger={['click']}>
                  <div className='notification-item--actionGroup__btn'>
                    <EllipsisOutlined style={{ fontSize: 18 }} />
                  </div>
                </Dropdown>
              </Col>
            </Row>
            {isLoading ?
              <SkeletonList rowCount={10} />
              : <List
                style={{ border: 'none' }}
                className='list-custom--hover'
                bordered
                loading={false}
                pagination={{
                  position: 'bottom',
                  align: 'center',
                  ...paging,
                  onChange: (current, pageSize) => {
                    onParamChange({ page: current, limit: pageSize })
                  }
                }}
              >
                {MyNotifications?.map((item, index) => <NotificationItem updateStatus={updateStatus} onClickItem={debounce(triggerRefresh, 800)} data={item} key={index} />)}
              </List>}

          </div>
        </div>
      </div>
    </div>
  )
}
