import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import {
  DELETE_WH_SERVICE_REQUEST,
  DELETE_WH_SERVICE_SUCCESS,
  DELETE_WH_SERVICE_FAILED,
  GET_WH_SERVICES_REQUEST,
  GET_WH_SERVICES_SUCCESS,
  GET_WH_SERVICES_FAILED,
  GET_WH_SERVICES_AVAILABLE_REQUEST,
  GET_WH_SERVICES_AVAILABLE_SUCCESS,
  GET_WH_SERVICES_AVAILABLE_FAILED,
  CREATE_WH_SERVICE_REQUEST,
  CREATE_WH_SERVICE_SUCCESS,
  CREATE_WH_SERVICE_FAILED,
  GET_WH_SERVICE_REQUEST,
  GET_WH_SERVICE_SUCCESS,
  GET_WH_SERVICE_FAILED,
  UPDATE_WH_SERVICE_REQUEST,
  UPDATE_WH_SERVICE_SUCCESS,
  UPDATE_WH_SERVICE_FAILED,
  COPY_WH_SERVICE_REQUEST,
  COPY_WH_SERVICE_SUCCESS,
  COPY_WH_SERVICE_FAILED,
  GET_WH_SERVICES_BRANCH_REQUEST,
  GET_WH_SERVICES_BRANCH_SUCCESS,
  GET_WH_SERVICES_BRANCH_FAILED,
  UPDATE_WH_SERVICE_BRANCH_REQUEST,
  UPDATE_WH_SERVICE_BRANCH_SUCCESS,
  UPDATE_WH_SERVICE_BRANCH_FAILED,
  CREATE_WH_SERVICE_BRANCH_REQUEST,
  CREATE_WH_SERVICE_BRANCH_SUCCESS,
  CREATE_WH_SERVICE_BRANCH_FAILED,
  DELETE_WH_SERVICE_BRANCH_SUCCESS,
  DELETE_WH_SERVICE_BRANCH_FAILED,
  DELETE_WH_SERVICE_BRANCH_REQUEST,
  ACCEPT_WH_SERVICE_BRANCH_REQUEST,
  ACCEPT_WH_SERVICE_BRANCH_SUCCESS,
  ACCEPT_WH_SERVICE_BRANCH_FAILED,
  GET_WH_SERVICES_DASHBOARD_REQUEST,
  GET_WH_SERVICES_DASHBOARD_SUCCESS,
  GET_WH_SERVICES_DASHBOARD_FAILED,
} from '../../constants/actionTypes';

function* getWhServices({ payload: query }) {
  try {
    const response = yield call(Api.whService.getWhServices, query);
    yield put({ type: GET_WH_SERVICES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_SERVICES_FAILED, payload: error.message });
  }
}
function* getWhServicesDasboard({ payload: query }) {
  try {
    const response = yield call(Api.whService.getWhServicesDasboard, query);
    yield put({ type: GET_WH_SERVICES_DASHBOARD_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_SERVICES_DASHBOARD_FAILED, payload: error.message });
  }
}
function* getWhServicesAvailable({ payload: query }) {
  try {
    const response = yield call(Api.whService.getWhServicesAvailable, query);
    yield put({ type: GET_WH_SERVICES_AVAILABLE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_SERVICES_AVAILABLE_FAILED, payload: error.message });
  }
}
//Get Service by branch
function* getWhServicesBranch({ payload: query }) {
  try {
    const response = yield call(Api.whService.getWhServicesBranch, query);
    yield put({ type: GET_WH_SERVICES_BRANCH_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_SERVICES_BRANCH_FAILED, payload: error.message });
  }
}

function* getWhService({ payload: id }) {
  try {
    const whService = yield call(Api.whService.getById, id);
    yield put({ type: GET_WH_SERVICE_SUCCESS, payload: whService });
  } catch (error) {
    yield put({ type: GET_WH_SERVICE_FAILED, payload: error.message });
  }
}

function* createWhService(action) {
  try {
    const data = yield call(Api.whService.create, action.payload);
    yield put({ type: CREATE_WH_SERVICE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_WH_SERVICE_FAILED, payload: error.message });
  }
}
//Create service for branch
function* createWhServiceBranch(action) {
  try {
    const data = yield call(Api.whService.createServiceBranch, action.payload);
    yield put({ type: CREATE_WH_SERVICE_BRANCH_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_WH_SERVICE_BRANCH_FAILED, payload: error.message });
  }
}

function* updateWhService(action) {
  try {
    const data = yield call(Api.whService.update, action.payload);
    yield put({ type: UPDATE_WH_SERVICE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_SERVICE_FAILED, payload: error });
  }
}
//Update WhService for branch
function* updateWhServiceBranch(action) {
  try {
    const data = yield call(Api.whService.updateServiceBranch, action.payload);
    yield put({ type: UPDATE_WH_SERVICE_BRANCH_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_SERVICE_BRANCH_FAILED, payload: error.message });
  }
}
//accept WhService for branch
function* acceptWhServiceBranch(action) {
  try {
    const data = yield call(Api.whService.acceptServiceBranch, action.payload);
    yield put({ type: ACCEPT_WH_SERVICE_BRANCH_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: ACCEPT_WH_SERVICE_BRANCH_FAILED, payload: error.message });
  }
}

function* copyWhService({ payload: id }) {
  try {
    const data = yield call(Api.whService.copyService, id);
    yield put({ type: COPY_WH_SERVICE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: COPY_WH_SERVICE_FAILED, payload: error.message });
  }
}

function* deleteHopital({ payload }) {
  try {
    yield call(Api.whService.delete, payload);
    yield put({ type: DELETE_WH_SERVICE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_WH_SERVICE_FAILED, payload: error.message });
  }
}

function* deleteServiceBranch({ payload }) {
  try {
    yield call(Api.whService.deleteServiceBranch, payload);
    yield put({ type: DELETE_WH_SERVICE_BRANCH_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_WH_SERVICE_BRANCH_FAILED, payload: error.message });
  }
}

export default function* whService() {
  yield takeLatest(GET_WH_SERVICES_REQUEST, getWhServices);
  yield takeLatest(GET_WH_SERVICES_AVAILABLE_REQUEST, getWhServicesAvailable);
  yield takeLatest(GET_WH_SERVICES_BRANCH_REQUEST, getWhServicesBranch);
  yield takeLatest(GET_WH_SERVICE_REQUEST, getWhService);
  yield takeLatest(DELETE_WH_SERVICE_REQUEST, deleteHopital);
  yield takeLatest(DELETE_WH_SERVICE_BRANCH_REQUEST, deleteServiceBranch);
  yield takeLatest(CREATE_WH_SERVICE_REQUEST, createWhService);
  yield takeLatest(CREATE_WH_SERVICE_BRANCH_REQUEST, createWhServiceBranch);
  yield takeLatest(UPDATE_WH_SERVICE_REQUEST, updateWhService);
  yield takeLatest(COPY_WH_SERVICE_REQUEST, copyWhService);
  yield takeLatest(UPDATE_WH_SERVICE_BRANCH_REQUEST, updateWhServiceBranch);
  yield takeLatest(ACCEPT_WH_SERVICE_BRANCH_REQUEST, acceptWhServiceBranch);
  yield takeLatest(GET_WH_SERVICES_DASHBOARD_REQUEST, getWhServicesDasboard);
}
