import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getActivityReferrals({ payload: query }) {
    try {
      const response = yield call(Api.activityReferral.getAll, query);
      yield put({ type: Types.GET_ACTIVITY_REFERRALS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_ACTIVITY_REFERRALS_FAILED, payload: error });
    }
  }
function* getActivityReferral({ payload: id }) {
    try {
      const response = yield call(Api.activityReferral.getById, id);
      yield put({ type: Types.GET_ACTIVITY_REFERRAL_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_ACTIVITY_REFERRAL_FAILED, payload: error });
    }
  }
  function* createActivityReferral(action) {
    try {
      const data = yield call(Api.activityReferral.create, action.payload);
      yield put({ type: Types.CREATE_ACTIVITY_REFERRAL_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_ACTIVITY_REFERRAL_FAILED, payload: error });
    }
  }
  function* updateActivityReferral(action) {
    try {
      const data = yield call(Api.activityReferral.update, action.payload);
      yield put({ type: Types.UPDATE_ACTIVITY_REFERRAL_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_ACTIVITY_REFERRAL_FAILED, payload: error });
    }
  }
  function* deleteActivityReferral(action) {
    try {
      const data = yield call(Api.activityReferral.delete, action.payload);
      yield put({ type: Types.DELETE_ACTIVITY_REFERRAL_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_ACTIVITY_REFERRAL_FAILED, payload: error });
    }
  }
export default function* ActivityReferrals() {
    yield takeLatest(Types.GET_ACTIVITY_REFERRALS_REQUEST, getActivityReferrals);
    yield takeLatest(Types.GET_ACTIVITY_REFERRAL_REQUEST, getActivityReferral);
    yield takeLatest(Types.CREATE_ACTIVITY_REFERRAL_REQUEST, createActivityReferral);
    yield takeLatest(Types.UPDATE_ACTIVITY_REFERRAL_REQUEST, updateActivityReferral);
    yield takeLatest(Types.DELETE_ACTIVITY_REFERRAL_REQUEST, deleteActivityReferral);
  }
