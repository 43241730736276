import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getWhPackageLevels() {
  try {
    const data = yield call(Api.whPackageLevel.getAll);
    yield put({ type: Types.GET_WH_PACKAGE_LEVELS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_PACKAGE_LEVELS_FAILED,
      payload: error.message
    });
  }
}

function* getWhPackageLevel({ payload: id }) {
  try {
    const whPackageLevel = yield call(Api.whPackageLevel.getById, id);
    yield put({ type: Types.GET_WH_PACKAGE_LEVEL_SUCCESS, payload: whPackageLevel });
  } catch (error) {
    yield put({ type: Types.GET_WH_PACKAGE_LEVEL_FAILED, payload: error.message });
  }
}

function* createWhPackageLevel(action) {
  try {
    const data = yield call(Api.whPackageLevel.create, action.payload);
    yield put({ type: Types.CREATE_WH_PACKAGE_LEVEL_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_WH_PACKAGE_LEVEL_FAILED,
      payload: error.message
    });
  }
}

function* updateWhPackageLevel(action) {
  try {
    const data = yield call(Api.whPackageLevel.update, action.payload);
    yield put({ type: Types.UPDATE_WH_PACKAGE_LEVEL_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_PACKAGE_LEVEL_FAILED,
      payload: error.message
    });
  }
}

function* deleteWhPackageLevel({ payload }) {
  try {
    yield call(Api.whPackageLevel.delete, payload);
    yield put({ type: Types.DELETE_WH_PACKAGE_LEVEL_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_PACKAGE_LEVEL_FAILED,
      payload: error.message
    });
  }
}

export default function* whPackageLevel() {
  yield takeLatest(Types.GET_WH_PACKAGE_LEVELS_REQUEST, getWhPackageLevels);
  yield takeLatest(Types.GET_WH_PACKAGE_LEVEL_REQUEST, getWhPackageLevel);
  yield takeLatest(Types.DELETE_WH_PACKAGE_LEVEL_REQUEST, deleteWhPackageLevel);
  yield takeLatest(Types.CREATE_WH_PACKAGE_LEVEL_REQUEST, createWhPackageLevel);
  yield takeLatest(Types.UPDATE_WH_PACKAGE_LEVEL_REQUEST, updateWhPackageLevel);
}
