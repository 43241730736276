import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  // getRateConfigs,
  createRateConfig,
  deleteRateConfig,
  getRateConfig,
  resetRateConfigState,
  updateRateConfig,
  getRateConfigCertificates,
  getRateConfigDegrees,
  getRateConfigExperiences,
  getRateConfigPositions,
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

const getSelector = key => state => state.rateConfig[key];

const certificatesLoadingSelector = getSelector('isCertificatesLoading');
const certificatesSelector = getSelector('certificates');
const getCertificatesFailedSelector = getSelector('getCertificatesFailed');

const degreesLoadingSelector = getSelector('isDegreesLoading');
const degreesSelector = getSelector('degrees');
const getDegreesFailedSelector = getSelector('getDegreesFailed');

const experiencesLoadingSelector = getSelector('isExperiencesLoading');
const experiencesSelector = getSelector('experiences');
const getExperiencesFailedSelector = getSelector('getExperiencesFailed');

const positionsLoadingSelector = getSelector('isPositionsLoading');
const positionsSelector = getSelector('positions');
const getPositionsFailedSelector = getSelector('getPositionsFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useRateConfigPaging = () => useSelector(pagingSelector);

export const useRateConfigQueryParams = (defaultLimit = 10) => {
  
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || defaultLimit;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || defaultLimit);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteWhServiceSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteWhServiceSuccess]);
};

export const useCertificates = () => {
  
  // return useFetch({
  //   action: getRateConfigCertificates,
  //   loadingSelector: certificatesLoadingSelector,
  //   dataSelector: certificatesSelector,
  //   failedSelector: getCertificatesFailedSelector,
  // });
  return useFetch({
    action: getRateConfigCertificates,
    loadingSelector: certificatesLoadingSelector,
    dataSelector: certificatesSelector,
    failedSelector: getCertificatesFailedSelector,
  });
};

export const useDegrees = query => {
  return useFetchByParam({
    action: getRateConfigDegrees,
    loadingSelector: degreesLoadingSelector,
    dataSelector: degreesSelector,
    failedSelector: getDegreesFailedSelector,
    param : query
  });
};

export const useExperiences = query => {
  return useFetchByParam({
    action: getRateConfigExperiences,
    loadingSelector: experiencesLoadingSelector,
    dataSelector: experiencesSelector,
    failedSelector: getExperiencesFailedSelector,
    param : query
  });
};

export const usePositions = query => {
  return useFetchByParam({
    action: getRateConfigPositions,
    loadingSelector: positionsLoadingSelector,
    dataSelector: positionsSelector,
    failedSelector: getPositionsFailedSelector,
    param : query
  });
};

export const useCreateRateConfig = (callback) => {
  useSuccess(
    createSuccessSelector,
    'Tạo mới hệ số thành công',
    callback,
  );
  useFailed(createFailedSelector, 'Tạo mới hệ số thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createRateConfig
  });
};

export const useUpdateRateConfig = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật hệ số thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật hệ số thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateRateConfig
  });
};

export const useDeleteRateConfig = () => {
  useSuccess(deleteSuccessSelector, 'Xoá hệ số thành công');
  useFailed(deleteFailedSelector, 'Xoá hệ số thất bại');

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: deleteRateConfig
  });
};

export const useRateConfig = id => {
  return useFetchByParam({
    action: getRateConfig,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitRateConfig = rateConfig =>

  useMemo(() => {
    if (!rateConfig) {
      return {
        image: {
        }
      };
    }

    return rateConfig;
  }, [rateConfig]);

export const useResetRateConfig = () => {
  useResetState(resetRateConfigState);
};
