import produce from 'immer';
import { get } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  isLoadingByStatus: false,
  getListByStatusFailed: undefined,
  listByStatus: [],

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  updateStatusSuccess: null,
  updateStatusFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {

    //FEATCH ALL
    case Types.GET_PRODUCT_CATALOGUES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_PRODUCT_CATALOGUES_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_PRODUCT_CATALOGUES_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;
    
    //FEATCH BY ID

    case Types.GET_PRODUCT_CATALOGUE_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;
    
    case Types.GET_PRODUCT_CATALOGUE_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_PRODUCT_CATALOGUE_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_PRODUCT_CATALOGUE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_PRODUCT_CATALOGUE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_PRODUCT_CATALOGUE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_PRODUCT_CATALOGUE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_PRODUCT_CATALOGUE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_PRODUCT_CATALOGUE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;
    
    case Types.UPDATE_PRODUCT_CATALOGUE_STATUS_REQUEST:
      // state.isSubmitLoading = true;
      state.updateStatusSuccess = null;
      state.updateStatusFailed = null;
      return;

    case Types.UPDATE_PRODUCT_CATALOGUE_STATUS_SUCCESS:
      // state.isSubmitLoading = false;
      state.updateStatusSuccess = payload;
      state.list = state.list?.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case Types.UPDATE_PRODUCT_CATALOGUE_STATUS_FAILED:
      // state.isSubmitLoading = false;
      state.updateStatusFailed = payload;
      return;

    case Types.DELETE_PRODUCT_CATALOGUE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_PRODUCT_CATALOGUE_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_PRODUCT_CATALOGUE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    //FEATCH BY STATUS

    case Types.GET_PRODUCT_CATALOGUE_STATUS_REQUEST:
      state.isLoadingByStatus = true;
      state.listByStatus = null;
      state.getListByStatusFailed = null;
      return;
    
    case Types.GET_PRODUCT_CATALOGUE_STATUS_SUCCESS:
      state.isLoadingByStatus = false;
      state.listByStatus = payload;
      return;

    case Types.GET_PRODUCT_CATALOGUE_STATUS_FAILED:
      state.isLoadingByStatus = false;
      state.getListByStatusFailed = payload;
      return;
    
    case Types.RESET_PRODUCT_CATALOGUE_STATE:
      return initState;
    
    case Types.RESET_PRODUCT_CATALOGUE_FORM_STATE:
      state.isGetByIdLoading = false;
      state.byId = null;
      state.getByIdFailed = null;
      return;
      
      case Types.RESET_STORE:
        return initState;
        
    default:
      return;
  }
}, initState);
