export const GET_WH_CATEGORIES_SHORT_REQUEST =
  'GET_WH_CATEGORIES_SHORT_REQUEST';
export const GET_WH_CATEGORIES_SHORT_SUCCESS =
  'GET_WH_CATEGORIES_SHORT_SUCCESS';
export const GET_WH_CATEGORIES_SHORT_FAILED =
  'GET_WH_CATEGORIES_SHORT_CATEGORIES_FAILED';

export const GET_WH_CATEGORY_SHORT_REQUEST = 'GET_WH_CATEGORY_SHORT_REQUEST';
export const GET_WH_CATEGORY_SHORT_SUCCESS = 'GET_WH_CATEGORY_SHORT_SUCCESS';
export const GET_WH_CATEGORY_SHORT_FAILED = 'GET_WH_CATEGORY_SHORT_FAILED';

export const CREATE_WH_CATEGORY_SHORT_REQUEST =
  'CREATE_WH_CATEGORY_SHORT_REQUEST';
export const CREATE_WH_CATEGORY_SHORT_SUCCESS =
  'CREATE_WH_CATEGORY_SHORT_SUCCESS';
export const CREATE_WH_CATEGORY_SHORT_FAILED =
  'CREATE_WH_CATEGORY_SHORT_FAILED';

export const UPDATE_WH_CATEGORY_SHORT_REQUEST =
  'UPDATE_WH_CATEGORY_SHORT_REQUEST';
export const UPDATE_WH_CATEGORY_SHORT_SUCCESS =
  'UPDATE_WH_CATEGORY_SHORT_SUCCESS';
export const UPDATE_WH_CATEGORY_SHORT_FAILED =
  'UPDATE_WH_CATEGORY_SHORT_FAILED';

export const DELETE_WH_CATEGORY_SHORT_REQUEST =
  'DELETE_WH_CATEGORY_SHORT_REQUEST';
export const DELETE_WH_CATEGORY_SHORT_SUCCESS =
  'DELETE_WH_CATEGORY_SHORT_SUCCESS';
export const DELETE_WH_CATEGORY_SHORT_FAILED =
  'DELETE_WH_CATEGORY_SHORT_FAILED';

export const RESET_WH_CATEGORY_SHORT_STATE = 'RESET_WH_CATEGORY_SHORT_STATE';
