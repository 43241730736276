import * as Types from '../../constants/actionTypes';
  
  export const getTipsPartners = query => ({
    type: Types.GET_TIPS_PARTNERS_REQUEST,
    payload: query
  });
  export const getTipsPartner = id => ({
    type: Types.GET_TIPS_PARTNER_REQUEST,
    payload: id
  });
  export const getLogsTipsPartner = id => ({
    type: Types.GET_LOGS_TIPS_PARTNER_REQUEST,
    payload: id
  });
  export const createTipsPartner = instance => ({
    type: Types.CREATE_TIPS_PARTNER_REQUEST,
    payload: instance
  });
  export const updateTipsPartner = instance => ({
    type: Types.UPDATE_TIPS_PARTNER_REQUEST,
    payload: instance
  });
  export const deleteTipsPartner = id => ({
    type: Types.DELETE_TIPS_PARTNER_REQUEST,
    payload: id
  });
  export const resetTipsPartnerState = () => ({
    type: Types.RESET_TIPS_PARTNER_STATE
  });