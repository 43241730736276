import { get } from 'lodash';
import { setEndofDate } from '~/utils/helper';
import requester from './requester';

const whReportCustomer = {
  getwhReportCustomers: (query) =>
    requester.get('/wh-receipt-voucher-customer/calculator', {
      ...query,
      endDate: setEndofDate(get(query, 'endDate', 0))
    }),

  getOnewhReportCustomers: (query) =>
    requester.get(`/wh-bill-item-customer/calculatorCustomer`, {
      ...query,
      endDate: setEndofDate(get(query, 'endDate', 0))
    })
};

export default whReportCustomer;
