import { Modal } from 'antd'
import { get } from 'lodash';
import React, { useEffect, useState } from 'react'
import api from '~/api';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { useCities } from '~/hooks';
import { useQuotation } from '~/hooks/quotations';
import FormUpdateQuotation from './FormUpdateQuotation';
import './wh-order-temp.scss'
export default function ModalUpdateQuotation({isSubmitLoadingSelector,isModalUpdateQuotationOpen,setIsModalUpdateQuotationOpen,updateQuotation,idSelect}) {
    const [quotation,isLoadings] = useQuotation(idSelect);
    const [whCategories,setWhCategories] = useState([])
    const [isLoadingCategory,setIsLoading] = useState(false)
    const [servicePlaces,setServicePlaces] = useState([])
    useEffect(() => {
      const fetchWhCategories = async () => {
        setIsLoading(true)
        const res = await api.whCategory.getWhCategoryAuthen({ status: 'ACTIVE' });
        if (res) {
          setWhCategories(res.map((item) => ({ ...item, id: get(item, '_id') })))
          setIsLoading(false)
        }
        else {
          setWhCategories([])
          setIsLoading(false)
        };
      };
        const fetchServicePlaces = async () => {
          setIsLoading(true)
          const res = await api.servicePlace.getAll({limit : MAX_PAGINATION_LIMIT , state : 'ACTIVE'})
          if(get(res,'docs')){
            setServicePlaces(get(res,'docs'))
            setIsLoading(false)
          }
          else{
            setServicePlaces([])
            setIsLoading(false)
          }
        }
        fetchWhCategories()
        fetchServicePlaces()
      },[])
    const closeModal = () => {
        setIsModalUpdateQuotationOpen(false);
    };
    const [cities,isLoadingCities] = useCities()
  return (
      <Modal width={600}  onCancel={closeModal} className="WhModal-modal" visible={isModalUpdateQuotationOpen} footer={null}>
      <h5>Cập nhật Đơn hàng tạm</h5>
      {quotation &&  <FormUpdateQuotation servicePlaces={servicePlaces} cities={cities} isLoadingCities={isLoadingCities} idSelect={idSelect} isLoadingQuotation={isLoadings} isLoadingCategory={isLoadingCategory} whCategories={whCategories} quotation={quotation} isSubmitLoadingSelector={isSubmitLoadingSelector} updateQuotation={updateQuotation} closeModal={closeModal}/>}
      </Modal>

  )
}
