import requester from './requester';

const rateConfig = {
  getAll: query => requester.get('/rate-config', query),
  getById: id => requester.get(`/rate-config/${id}?raw=true`),
  delete: id => requester.delete(`/rate-config/${id}`),
  create: rateConfig => requester.post('/rate-config', rateConfig),
  update: rateConfig =>
    requester.put(`/rate-config/${rateConfig.id}`, rateConfig)
};

export default rateConfig;
