export const GET_TIME_SHEETS_REQUEST =
  'GET_TIME_SHEETS_REQUEST';
export const GET_TIME_SHEETS_SUCCESS =
  'GET_TIME_SHEETS_SUCCESS';
export const GET_TIME_SHEETS_FAILED = 'GET_TIME_SHEETS_FAILED';

export const GET_TIME_SHEET_REQUEST = 'GET_TIME_SHEET_REQUEST';
export const GET_TIME_SHEET_SUCCESS = 'GET_TIME_SHEET_SUCCESS';
export const GET_TIME_SHEET_FAILED = 'GET_TIME_SHEET_FAILED';

export const CREATE_TIME_SHEET_REQUEST =
  'CREATE_TIME_SHEET_REQUEST';
export const CREATE_TIME_SHEET_SUCCESS =
  'CREATE_TIME_SHEET_SUCCESS';
export const CREATE_TIME_SHEET_FAILED =
  'CREATE_TIME_SHEET_FAILED';

export const UPDATE_TIME_SHEET_REQUEST =
  'UPDATE_TIME_SHEET_REQUEST';
export const UPDATE_TIME_SHEET_SUCCESS =
  'UPDATE_TIME_SHEET_SUCCESS';
export const UPDATE_TIME_SHEET_FAILED =
  'UPDATE_TIME_SHEET_FAILED';

export const UPDATE_COURSE_TRAINING_IN_TIME_SHEET_REQUEST =
  'UPDATE_COURSE_TRAINING_IN_TIME_SHEET_REQUEST';
export const UPDATE_COURSE_TRAINING_IN_TIME_SHEET_SUCCESS =
  'UPDATE_COURSE_TRAINING_IN_TIME_SHEET_SUCCESS';
export const UPDATE_COURSE_TRAINING_IN_TIME_SHEET_FAILED =
  'UPDATE_COURSE_TRAINING_IN_TIME_SHEET_FAILED';

export const DELETE_TIME_SHEET_REQUEST =
  'DELETE_TIME_SHEET_REQUEST';
export const DELETE_TIME_SHEET_SUCCESS =
  'DELETE_TIME_SHEET_SUCCESS';
export const DELETE_TIME_SHEET_FAILED =
  'DELETE_TIME_SHEET_FAILED';


export const RESET_TIME_SHEET_STATE = 'RESET_TIME_SHEET_STATE';
