import { DatePicker, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '~/api';
import {
  getSelectors,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createTimeSheet,
  deleteTimeSheet,
  getTimeSheet,
  getTimeSheets,
  resetTimeSheetState,
  updateTimeSheet,
  updateTrainingCoursePartnerInTimeSheet
} from '~/redux/action';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.timeSheet[key];
const pagingSelector = getSelector('paging');
export const useTimeSheetPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetTimeSheetSelector = getSelector('isGetTimeSheetLoading');
const getTimeSheetsFailedSelector = getSelector('getTimeSheetsFailed');
const getTimeSheetFailedSelector = getSelector('getTimeSheetFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const updateCourseFailedSelector = getSelector('updateFailedCourse');
const updateCourseSuccessSelector = getSelector('updateSuccessCourse');
const isUpdateCourseLoadingSelector = getSelector('loadingUpdateCourse');

const timeSheetsSelector = getSelector('timeSheets');
const timeSheetSelector = getSelector('timeSheet');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useTimeSheetQueryParams = (keyQuery) => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const tabs = '3';
  const key = query.get(keyQuery);
  // const keyword = query.get('keyword')
  const createTimeSheetSuccess = useSelector(createSuccessSelector);
  const updateTimeSheetSuccess = useSelector(updateSuccessSelector);
  const deleteTimeSheetSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      // keyword,
      tabs,
      [keyQuery]: key,
      startDate,
      endDate
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    createTimeSheetSuccess,
    updateTimeSheetSuccess,
    deleteTimeSheetSuccess,
    // keyword,
    tabs,
    key,
    startDate,
      endDate
  ]);
};

export const useUpdateTimeSheetParams = (query) => {
  const history = useHistory();
  // const [keyword, setKeyword] = useState(get(query,'keyword'));
  // useEffect(() => {
  //   setKeyword(get(query,'keyword'));
  // },[query.keyword])
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/wh-partner',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [{ onParamChange }];
};
export const useTimeSheets = (query) => {
  return useFetchByParam({
    action: getTimeSheets,
    loadingSelector,
    dataSelector: timeSheetsSelector,
    failedSelector: getTimeSheetsFailedSelector,
    param: query
  });
};
export const useTimeSheet = (id) => {
  return useFetchByParam({
    action: getTimeSheet,
    loadingSelector: loadinggetTimeSheetSelector,
    dataSelector: timeSheetSelector,
    failedSelector: getTimeSheetFailedSelector,
    param: id
  });
};
export const useCreateTimeSheet = (callback) => {
  useSuccess(
    createSuccessSelector,
    'Submit thời gian đào tạo thành công',
    callback
  );
  useFailed(createFailedSelector, 'Submit thời gian đào tạo thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createTimeSheet
  });
};
export const useUpdateTimeSheet = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhât thời gian đào tạo thành công',
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateTimeSheet
  });
};
export const useUpdateTrainingCoursePartnerInTimeSheet = (callback) => {
  useSuccess(
    updateCourseSuccessSelector,
    'Cập nhật Khoá đào tạo thành công',
    callback
  );
  useFailed(updateCourseFailedSelector);

  return useSubmit({
    loadingSelector: isUpdateCourseLoadingSelector,
    action: updateTrainingCoursePartnerInTimeSheet
  });
};
export const useLogCourseTrainingOfCourse = (id) => {
  // get log by Id Course
  const updateTimeSheetSuccess = useSelector(updateSuccessSelector);
  const updateCourseSuccess = useSelector(updateCourseSuccessSelector);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      const res = await api.LogCourseTraining.getAllLogAdmin(id);
      setLogs(res);
      setLoading(false);
    };
    if (!!id) {
      fetchLogs();
    }
  }, [updateTimeSheetSuccess,updateCourseSuccess, id]);
  return [logs, loading];
};
export const useLogCourseTrainingOfCourseAll = () => {// get log by Id Course
  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState({
    pageSize: 10,
    total: 0,
    current: 1
  });
  const onChangePage = (pageNumber, pageSize) => {
    setPaging({ ...paging, current: pageNumber, limit: pageSize });
  };
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      const res = await api.LogCourseTraining.getAllLog({ page: paging.current, limit: paging.pageSize });
      setLogs(res.docs);
      setPaging({...paging, total: res.totalDocs })
      setLoading(false);
    };
    fetchLogs();
  }, [paging.current, paging.pageSize]);
  return [logs, loading, paging, onChangePage];
};
export const useDeleteTimeSheet = (callback) => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá thời gian đào tạo thành công',
    callback
  );
  useFailed(deleteFailedSelector, 'Xoá nhật thời gian đào tạo thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteTimeSheet
  });
};

export const useResetTimeSheet = () => {
  useResetState(resetTimeSheetState);
};
