export const GET_STATUS_CONFIG_REQUEST = 'GET_STATUS_CONFIG_REQUEST';
export const GET_STATUS_CONFIG_SUCCESS = 'GET_STATUS_CONFIG_SUCCESS';
export const GET_STATUS_CONFIG_FAILED = 'GET_STATUS_CONFIG_FAILED';

export const GET_STATUS_CONFIG_BY_ID_REQUEST = 'GET_STATUS_CONFIG_BY_ID_REQUEST';
export const GET_STATUS_CONFIG_BY_ID_SUCCESS = 'GET_STATUS_CONFIG_BY_ID_SUCCESS';
export const GET_STATUS_CONFIG_BY_ID_FAILED = 'GET_STATUS_CONFIG_BY_ID_FAILED';

export const CREATE_STATUS_CONFIG_REQUEST = 'CREATE_STATUS_CONFIG_REQUEST';
export const CREATE_STATUS_CONFIG_SUCCESS = 'CREATE_STATUS_CONFIG_SUCCESS';
export const CREATE_STATUS_CONFIG_FAILED = 'CREATE_STATUS_CONFIG_FAILED';

export const UPDATE_STATUS_CONFIG_REQUEST = 'UPDATE_STATUS_CONFIG_REQUEST';
export const UPDATE_STATUS_CONFIG_SUCCESS = 'UPDATE_STATUS_CONFIG_SUCCESS';
export const UPDATE_STATUS_CONFIG_FAILED = 'UPDATE_STATUS_CONFIG_FAILED';

export const DELETE_STATUS_CONFIG_REQUEST = 'DELETE_STATUS_CONFIG_REQUEST';
export const DELETE_STATUS_CONFIG_SUCCESS = 'DELETE_STATUS_CONFIG_SUCCESS';
export const DELETE_STATUS_CONFIG_FAILED = 'DELETE_STATUS_CONFIG_FAILED';

