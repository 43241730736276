import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import { selectBranch } from '~/redux/action';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_WORKSPACE_REQUEST,
  LOGIN_WORKSPACE_SUCCESS,
  LOGIN_WORKSPACE_FAILED,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILED,
  GET_USER_POLICY_REQUEST,
  GET_USER_POLICY_SUCCESS,
  GET_USER_POLICY_FAILED,
  SUBSCRIBE_FCM_FIREBASE_REQUEST,
  SUBSCRIBE_FCM_FIREBASE_SUCCESS,
} from '~/constants/actionTypes';
import { setAxiosCompanyId, setAxiosToken } from '~/api/requester';
import { getTokenFCM } from '~/firebase';

function* login({ payload: user }) {
  try {
    const { token, branchId } = yield call(Api.user.login, user);
    setAxiosToken(token);
    setAxiosCompanyId(branchId);
    const profile = yield call(Api.user.getProfile, token);
    yield put({ type: LOGIN_SUCCESS, payload: { token, branchId, profile } });

    // SUBSCRIPTION  firebase FCM After a successful login
    yield put({ type: SUBSCRIBE_FCM_FIREBASE_REQUEST});

  } catch (error) {
    yield put({ type: LOGIN_FAILED, payload: error });
  }
}

function* subscribeFcmFirebase() {
  try {
    const tokenFcm = yield call(getTokenFCM);
    yield put({ type: SUBSCRIBE_FCM_FIREBASE_SUCCESS , payload : tokenFcm});

  } catch (error) {
    // yield put({ type: LOGIN_FAILED, payload: error });
  }
}

function* loginWorkspace({ payload: user }) {
  try {
    const { token, partner } = yield call(Api.user.loginWorkspace, user);
    setAxiosToken(token);
    const profile = partner;
    yield put({ type: LOGIN_WORKSPACE_SUCCESS, payload: { token, profile } });
  } catch (error) {
    yield put({ type: LOGIN_WORKSPACE_FAILED, payload: error });
  }
}

function* getProfile({ payload: token }) {
  try {
    const profile = yield call(Api.user.getProfile, token);
    yield put({ type: GET_PROFILE_SUCCESS, payload: profile });
  } catch (error) {
    yield put({ type: GET_PROFILE_FAILED, payload: error });
  }
}

function* getPolicy({ payload: branchId }) {
  try {
    setAxiosCompanyId(branchId);
    const policy = yield call(Api.user.getPolicy);
    yield put({ type: GET_USER_POLICY_SUCCESS, payload: policy });
    yield put(selectBranch(branchId));
  } catch (error) {
    setAxiosCompanyId(null);
    yield put({ type: GET_USER_POLICY_FAILED, payload: error });
  }
}

export default function* userSaga() {
  yield takeLatest(LOGIN_REQUEST, login);
  yield takeLatest(LOGIN_WORKSPACE_REQUEST, loginWorkspace);
  yield takeLatest(GET_PROFILE_REQUEST, getProfile);
  yield takeLatest(GET_USER_POLICY_REQUEST, getPolicy);
  yield takeLatest(SUBSCRIBE_FCM_FIREBASE_REQUEST, subscribeFcmFirebase);
}
