import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getMedicalEquipments({ payload: companyId }) {
  try {
    const data = yield call(Api.medicalEquipment.getAll, companyId);
    yield put({ type: Types.GET_MEDICAL_EQUIPMENTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICAL_EQUIPMENTS_FAILED,
      payload: error.message
    });
  }
}

function* getMedicalEquipment({ payload }) {
  try {
    const equipment = yield call(Api.medicalEquipment.getById, payload);
    yield put({
      type: Types.GET_MEDICAL_EQUIPMENT_SUCCESS,
      payload: equipment
    });
  } catch (error) {
    yield put({
      type: Types.GET_MEDICAL_EQUIPMENT_FAILED,
      payload: error.message
    });
  }
}

function* createMedicalEquipment(action) {
  try {
    const data = yield call(Api.medicalEquipment.create, action.payload);
    yield put({ type: Types.CREATE_MEDICAL_EQUIPMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_MEDICAL_EQUIPMENT_FAILED,
      payload: error.message
    });
  }
}

function* updateMedicalEquipment(action) {
  try {
    const data = yield call(Api.medicalEquipment.update, action.payload);
    yield put({ type: Types.UPDATE_MEDICAL_EQUIPMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_MEDICAL_EQUIPMENT_FAILED,
      payload: error.message
    });
  }
}

function* deleteMedicalEquipment({ payload }) {
  try {
    yield call(Api.medicalEquipment.delete, payload);
    yield put({ type: Types.DELETE_MEDICAL_EQUIPMENT_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_MEDICAL_EQUIPMENT_FAILED,
      payload: error.message
    });
  }
}

export default function* partner() {
  yield takeLatest(Types.GET_MEDICAL_EQUIPMENTS_REQUEST, getMedicalEquipments);
  yield takeLatest(Types.GET_MEDICAL_EQUIPMENT_REQUEST, getMedicalEquipment);
  yield takeLatest(Types.DELETE_MEDICAL_EQUIPMENT_REQUEST, deleteMedicalEquipment);
  yield takeLatest(Types.CREATE_MEDICAL_EQUIPMENT_REQUEST, createMedicalEquipment);
  yield takeLatest(Types.UPDATE_MEDICAL_EQUIPMENT_REQUEST, updateMedicalEquipment);
}
