import { Alert, Card } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { get } from 'lodash'
import React from 'react'
import { formatPhone } from '~/utils/helper';
import avatar from "~/assets/images/avatar.png"
import LoadingWhBill from './LoadingWhBill';

export default function CardCustomer({ data,loading }) {
    if(loading){
        return <LoadingWhBill open={loading}/>
    }
    return (
        data ?
            <Card
                style={{
                    display: 'flex'
                }}
                cover={<img style={{ width: 100, height: 100 }} alt="example" src={avatar} />}
            >
                <Meta title={get(data, 'fullName', '')} description={formatPhone(get(data, 'phoneNumber', ''))} />
            </Card>
            : <Alert message="Không tìm thấy khách hàng" type="warning" showIcon />

    )
}
