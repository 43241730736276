import React, { useMemo, useEffect, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { Menu, Skeleton, Table, Checkbox, Button, Popconfirm } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import WithPermission from '~/components/Common/WithPermission';
import POLICY, { ACTIONS } from '~/constants/policy';
import { get } from 'lodash';
import Search from 'antd/lib/input/Search';
import toastr from 'toastr';
import { Permissions } from '~/hooks/utils';
import GroupCategoryForm from './GroupCategoryForm';
import {
  getNewPolicies,
  onSearchPermissions,
  useDeleteGroupCategory,
  useGroupCategories,
  useGroupCategory,
  usePermission,
  usePermissionColumns,
  useResetGroupCategory,
  useUpdatePermission
} from '~/hooks/groupCategory';

const Permission = ({ isActive, onChange, disabled }) => {
  return (
    <Checkbox
      checked={isActive}
      onChange={onChange}
      disabled={disabled}
    ></Checkbox>
  );
};

const getNextPath = (url) => {
  const paths = url.split('/');
  const nextPath = paths
    .filter((x, index) => !!x && index !== paths.length - 1)
    .join('/');

  return `/${nextPath}`;
};

const GroupCategory = () => {
  const { id: branchId, groupId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const branchIdParam = useMemo(() => ({ branchId }), [branchId]);

  const { canUpdate } = Permissions('USERGROUPSERVICE');
  const [groups, isLoading] = useGroupCategories(branchIdParam);
  const [resources, isResourcesLoading] = usePermission();

  const [dataShow, setDataShow] = useState(null);

  useResetGroupCategory();
  useEffect(() => {
    if (!groupId && groups.length) {
      history.push(`${url}/${groups[0]._id}`);
    }
  }, [groups, history, url, groupId]);

  const params = useMemo(() => {
    return groupId ? { groupId, branchId } : null;
  }, [groupId, branchId]);

  const [group, isLoadingGroup, updateGroup] = useGroupCategory(params);

  const onSelectGroup = ({ key }) => {
    const nextPath = `${getNextPath(url)}/${key}`;
    history.push(nextPath);
  };

  const deleteCallback = () => {
    history.push(getNextPath(url));
  };

  const [isUpdateLoading, handleUpdate] = useUpdatePermission();
  const [_, deleteGroup] = useDeleteGroupCategory(deleteCallback);

  const [initGroup, setInitGroup] = useState(group);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const onOpenForm = (group) => {
    setInitGroup(group);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setInitGroup({});
    setIsOpenForm(false);
  };

  const onPermissionChange = (isAssgined, resource, action) => {
    try {
      if (!canUpdate) return;
      const newPolicies = getNewPolicies(group, isAssgined, resource, action);
      updateGroup({ ...group, policies: newPolicies }); // update Group in store redux
      handleUpdate({ isAssgined, resource, action, groupId });
    } catch (error) {
      toastr.error(get(error, 'message', 'Some error'));
    }
  };

  const renderPermission = (action, rc) => {
    const admin = group?.policies?.[rc.resource.key]?.['admin'];
    return (
      <Permission
        isActive={group?.policies?.[rc.resource.key]?.[action.key]}
        onChange={(event) =>
          onPermissionChange(event.target.checked, rc.resource.key, action.key)
        }
        disabled={(!canUpdate || admin) && action.key !== 'admin'}
      />
    );
  };

  const columns = usePermissionColumns(renderPermission);

  return (
    <Card>
      <CardBody>
        <div className="employee-group">
          <div className="employee-group__list">
            <h5 className="employee-group__list-title">Nhóm người dùng</h5>
            <Menu
              className="employee-group__list__menu"
              defaultSelectedKeys={['1']}
              selectedKeys={[groupId]}
              mode="inline"
              theme="light"
              onSelect={onSelectGroup}
            >
              {isLoading
                ? [1, 2, 3, 4].map((index) => (
                    <Skeleton.Input
                      active
                      key={index}
                      style={{ marginBottom: 10 }}
                    />
                  ))
                : groups.map(({ name, _id }) => (
                    <Menu.Item key={_id}>{name} </Menu.Item>
                  ))}
            </Menu>
          </div>

          <div className="employee-group__permission">
            <div className="employee-group__header">
              <h5 className="employee-group__list-title ">
                Thiết lập quyền {group && `nhóm dịch vụ ${group.name}`}
              </h5>
              <WithPermission permission={POLICY.DELETE_USERGROUPSERVICE}>
                <Popconfirm
                  title="Bạn muốn xoá nhóm dịch vụ này?"
                  onConfirm={() => deleteGroup(group._id)}
                  okText="Xoá"
                  cancelText="Huỷ"
                >
                  <Button size="small" type="danger">
                    <DeleteOutlined /> Xoá
                  </Button>
                </Popconfirm>{' '}
              </WithPermission>

              <WithPermission permission={POLICY.UPDATE_USERGROUPSERVICE}>
                <Button
                  size="small"
                  onClick={() => onOpenForm(group)}
                  type="primary"
                >
                  <EditOutlined /> Cập nhật
                </Button>
              </WithPermission>

              <WithPermission permission={POLICY.WRITE_USERGROUPSERVICE}>
                <Button
                  size="small"
                  onClick={() => onOpenForm({})}
                  type="primary"
                >
                  <PlusOutlined /> Tạo mới
                </Button>
              </WithPermission>
            </div>
            <div className="employee-group__search">
              <Search
                placeholder="Tìm tên quyền"
                enterButton
                allowClear
                onChange={(e) =>
                  onSearchPermissions(e.target.value, resources, setDataShow)
                }
              />
            </div>
            {isResourcesLoading ? (
              <SkeletonTable columns={columns} />
            ) : (
              <Table
                columns={columns}
                dataSource={dataShow ?? resources} // use dataShow when Search , Null will show resources , [] will show Empty
                size="small"
                scroll={{ x: 'auto', y: 700 }}
                rowKey={(rc) => rc.resource.key}
                pagination={{ hideOnSinglePage: true }}
              ></Table>
            )}
          </div>
        </div>
        <GroupCategoryForm
          isOpen={isOpenForm}
          onClose={onCloseForm}
          initGroup={initGroup}
        />
      </CardBody>
    </Card>
  );
};

export default GroupCategory;
