import { adapterStartAndEndDate } from '~/utils/helper';
import requester from './requester';
import { get, omit } from 'lodash';

const statusConfig = {
    getListStatusConfig: (query) => {
        return requester.get('/todo-status-config', query)
    },
    getById: (id) => {
        return requester.get(`/board-status-config/${id}`)
    },
    create: (data) => {
        return requester.post('/todo-status-config', data)
    },
    update: (decive) => {
        return requester.put(`/todo-status-config/${decive?.id}`, decive)
    },
    delete: ({id}) => {
        return requester.delete(`/todo-status-config/${id}`)
    }
}
export default statusConfig