import { Form, Input, Popover, Select, Slider } from 'antd'
import React, { useCallback, useState } from 'react'
import { initSelectHighlight } from '~/hooks/whHighlight'
import toastr from 'toastr'
import { useMemo } from 'react'
import { isArray } from 'lodash'
import  ColorPicker  from '@vtaits/react-color-picker';

function FormDetail({form,data,setData,setActive,language}) {
    let dataRef = useMemo(()=>([...initSelectHighlight(data,language)]),[data])
    const handleChangeValueForm  = ({keyId,rotate,size,backgroundCircle,backgroundTitle})=>{
        if(!form.getFieldValue('keyId')){
            toastr.clear()
            toastr.error('Hãy chọn icon cần thao tác!')
            return
        }
        if(keyId){
            let [key] = keyId?.split(/[-]/);
            let value = data[key].find(({keyId:_id})=>_id===keyId);
            let data_ = {...data}
            for (const key_ in data_) {
                if(['v1','v2','v3'].includes(key_)){
                    data_[key_] = data_[key_].map((element_)=>{
                        let element = {...element_}
                        element.active = element.keyId===keyId
                        return element
                     })
 
                }
            }
            setData({...data_})
            form.setFieldsValue({
              rotate: value.rotate,
              size: value.size,
              backgroundCircle: value.backgroundCircle,
              backgroundTitle: value.backgroundTitle,
            })
            setActive([keyId.split(/[-]/)[0]])
        }

        if(backgroundCircle){
            findItem('backgroundCircle',backgroundCircle)
        }

        if(backgroundTitle){
            findItem('backgroundTitle',backgroundTitle)
        } 
        if(rotate){
            findItem('rotate',rotate)
        }
        if(size){
            findItem('size',size)
        }
    }
    const findItem = useCallback((key_,value)=>{
        let valueItem = form.getFieldValue('keyId')
        if(valueItem){
            let [key] = valueItem?.split(/[-]/);
            let  data_ ={...data} 
            if(isArray(data_[key])){
                data_[key] = data_[key]?.map((element_)=>{
                    let element={...element_}
                    if(element.keyId===valueItem){
                        element[key_] = value
                    }
                    return element
                })

            }
            setData({...data_})
        }
        else{
            toastr.clear()
            toastr.error("hãy chọn đối tượng cần thao tác")
        }

    },[form,setData,data])

    return (
        <Form
            form={form}
            onValuesChange={(e)=>{ handleChangeValueForm(e)}}
            layout='horizontal'
            className='formControl'
            labelAlign='left'
            validateTrigger={'onchange'}
        >
            <Form.Item name={'keyId'} >
                <Select options={dataRef}></Select>
            </Form.Item>

            <Popover destroyTooltipOnHide content={<ColorPickerModule
              initval={form.getFieldValue('backgroundCircle')}
              disabled={!form.getFieldValue('keyId')||form.getFieldValue('keyId')==='v0'}
              onChange={(e)=>{
              form.setFieldsValue({backgroundCircle: e})
              handleChangeValueForm({backgroundCircle: e})
            }}/>} 
            title="Color" 
            trigger="click">
              <Form.Item shouldUpdate >
                {()=><Form.Item label={'Nền dưới'} name={'backgroundCircle'}>
                    <Input readOnly={!form.getFieldValue('keyId')||form.getFieldValue('keyId')==='v0'} addonBefore={<div style={{backgroundColor: form.getFieldValue('backgroundCircle')}} className={`form-color-item`} ></div>}/>
                </Form.Item>}                
              </Form.Item>
            </Popover>

            <Popover destroyTooltipOnHide content={<ColorPickerModule 
              initval={form.getFieldValue('backgroundTitle')}
              disabled={!form.getFieldValue('keyId')||form.getFieldValue('keyId')==='v0'}
              onChange={(e)=>{
                form.setFieldsValue({backgroundTitle:e})
                handleChangeValueForm({backgroundTitle: e})
                }}/>} 
                
              title="Color" 
              trigger="click">
              <Form.Item shouldUpdate>
                {()=><Form.Item shouldUpdate label={'Nền trên'} name={'backgroundTitle'}>
                  <Input readOnly={!form.getFieldValue('keyId')||form.getFieldValue('keyId')==='v0'} addonBefore={<div style={{backgroundColor: form.getFieldValue('backgroundTitle')}} className={`form-color-item`} ></div>}/>
              </Form.Item>}
              </Form.Item>
              
            </Popover>

            <Form.Item label={'Vị trí'} name={'rotate'}>
                <Slider disabled={!form.getFieldValue('keyId')||form.getFieldValue('keyId')==='v0'} min={0} max={360}></Slider>
            </Form.Item>
            <Form.Item label={'Kích thước'} name={'size'}>
                <Slider disabled={!form.getFieldValue('keyId') || form.getFieldValue('keyId')==='v0'} min={40} max={100}></Slider>
            </Form.Item>

        </Form>
    )
}

export default FormDetail

 export const ColorPickerModule=({onChange,initval,disabled})=>{
   const [color,setColor]=useState({picker:  (String(initval).length>7? initval?.slice(0,-2) :initval) ??'#ffffff',alpha:initval?.slice(-2)??'ff'})

  const onChangevalue = useCallback((value)=>{
    let _color = {...color,...value} 
        setColor((curr)=>({...curr,...value}))
      onChange(`${_color.picker}${_color.alpha}`)
  },[onChange,color,setColor]) 
  return <>
    <ColorPicker 
      onChange={(e)=>{onChangevalue({'picker':e})}} 
      saturationWidth={120} 
      saturationHeight={120} 
      hueWidth={20}
      defaultValue={initval}
    />
    <Slider 
      tooltipVisible={false} 
      disabled={disabled} 
      onChange={(e)=>{onChangevalue({'alpha':e.toString(16).padStart(2, '0')})}} 
      min={0} 
      defaultValue={parseInt(initval?.slice(-2), 16) ?? 255} 
      max={255}
    />
    </>
  }

