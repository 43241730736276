import * as Types from '../../constants/actionTypes';

export const getHospitalsList = (query) => ({
  type: Types.GET_HOSPITALS_LIST_REQUEST,
  payload: query
});

export const getHospitalList = (id) => ({
  type: Types.GET_HOSPITAL_LIST_REQUEST,
  payload: id
});

export const createHospitalList = (HospitalList) => ({
  type: Types.CREATE_HOSPITAL_LIST_REQUEST,
  payload: HospitalList
});

export const updateHospitalList = (HospitalList) => ({
  type: Types.UPDATE_HOSPITAL_LIST_REQUEST,
  payload: HospitalList
});

export const deleteHospitalList = (id) => ({
  type: Types.DELETE_HOSPITAL_LIST_REQUEST,
  payload: id
});

export const resetHospitalListState = () => ({
  type: Types.RESET_HOSPITAL_LIST_STATE
});
