export const GET_WH_CONTACTS_REQUEST = 'GET_WH_CONTACTS_REQUEST';
export const GET_WH_CONTACTS_SUCCESS = 'GET_WH_CONTACTS_SUCCESS';
export const GET_WH_CONTACTS_FAILED = 'GET_WH_CONTACTS_FAILED';

export const GET_WH_CONTACT_REQUEST = 'GET_WH_CONTACT_REQUEST';
export const GET_WH_CONTACT_SUCCESS = 'GET_WH_CONTACT_SUCCESS';
export const GET_WH_CONTACT_FAILED = 'GET_WH_CONTACT_FAILED';

export const CREATE_WH_CONTACT_REQUEST = 'CREATE_WH_CONTACT_REQUEST';
export const CREATE_WH_CONTACT_SUCCESS = 'CREATE_WH_CONTACT_SUCCESS';
export const CREATE_WH_CONTACT_FAILED = 'CREATE_WH_CONTACT_FAILED';

export const UPDATE_WH_CONTACT_REQUEST = 'UPDATE_WH_CONTACT_REQUEST';
export const UPDATE_WH_CONTACT_SUCCESS = 'UPDATE_WH_CONTACT_SUCCESS';
export const UPDATE_WH_CONTACT_FAILED = 'UPDATE_WH_CONTACT_FAILED';

export const DELETE_WH_CONTACT_REQUEST = 'DELETE_WH_CONTACT_REQUEST';
export const DELETE_WH_CONTACT_SUCCESS = 'DELETE_WH_CONTACT_SUCCESS';
export const DELETE_WH_CONTACT_FAILED = 'DELETE_WH_CONTACT_FAILED';

export const RESET_WH_CONTACT_STATE = 'RESET_WH_CONTACT_STATE';
