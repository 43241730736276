export const GET_WH_CUSTOMERS_MANAGEMENT_REQUEST = 'GET_WH_CUSTOMERS_MANAGEMENT_REQUEST';
export const GET_WH_CUSTOMERS_MANAGEMENT_SUCCESS = 'GET_WH_CUSTOMERS_MANAGEMENT_SUCCESS';
export const GET_WH_CUSTOMERS_MANAGEMENT_FAILED = 'GET_WH_CUSTOMERS_MANAGEMENT_FAILED';

export const GET_REF_WH_CUSTOMERS_MANAGEMENT_REQUEST = 'GET_REF_WH_CUSTOMERS_MANAGEMENT_REQUEST';
export const GET_REF_WH_CUSTOMERS_MANAGEMENT_SUCCESS = 'GET_REF_WH_CUSTOMERS_MANAGEMENT_SUCCESS';
export const GET_REF_WH_CUSTOMERS_MANAGEMENT_FAILED = 'GET_REF_WH_CUSTOMERS_MANAGEMENT_FAILED';

export const GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_REQUEST = 'GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_REQUEST';
export const GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_SUCCESS = 'GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_SUCCESS';
export const GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_FAILED = 'GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_FAILED';

export const GET_WH_CUSTOMER_MANAGEMENT_REQUEST = 'GET_WH_CUSTOMER_MANAGEMENT_REQUEST';
export const GET_WH_CUSTOMER_MANAGEMENT_SUCCESS = 'GET_WH_CUSTOMER_MANAGEMENT_SUCCESS';
export const GET_WH_CUSTOMER_MANAGEMENT_FAILED = 'GET_WH_CUSTOMER_MANAGEMENT_FAILED';

export const CREATE_WH_CUSTOMER_MANAGEMENT_REQUEST = 'CREATE_WH_CUSTOMER_MANAGEMENT_REQUEST';
export const CREATE_WH_CUSTOMER_MANAGEMENT_SUCCESS = 'CREATE_WH_CUSTOMER_MANAGEMENT_SUCCESS';
export const CREATE_WH_CUSTOMER_MANAGEMENT_FAILED = 'CREATE_WH_CUSTOMER_MANAGEMENT_FAILED';

export const UPDATE_WH_CUSTOMER_MANAGEMENT_REQUEST = 'UPDATE_WH_CUSTOMER_MANAGEMENT_REQUEST';
export const UPDATE_WH_CUSTOMER_MANAGEMENT_SUCCESS = 'UPDATE_WH_CUSTOMER_MANAGEMENT_SUCCESS';
export const UPDATE_WH_CUSTOMER_MANAGEMENT_FAILED = 'UPDATE_WH_CUSTOMER_MANAGEMENT_FAILED';

export const UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_REQUEST = 'UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_REQUEST';
export const UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_SUCCESS = 'UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_SUCCESS';
export const UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_FAILED = 'UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_FAILED';

export const DELETE_WH_CUSTOMER_MANAGEMENT_REQUEST = 'DELETE_WH_CUSTOMER_MANAGEMENT_REQUEST';
export const DELETE_WH_CUSTOMER_MANAGEMENT_SUCCESS = 'DELETE_WH_CUSTOMER_MANAGEMENT_SUCCESS';
export const DELETE_WH_CUSTOMER_MANAGEMENT_FAILED = 'DELETE_WH_CUSTOMER_MANAGEMENT_FAILED';

export const RESET_WH_CUSTOMER_MANAGEMENT_STATE = 'RESET_WH_CUSTOMER_MANAGEMENT_STATE';
export const RESET_NOTE_WH_CUSTOMER_MANAGEMENT_STATE = 'RESET_NOTE_WH_CUSTOMER_MANAGEMENT_STATE';

//Handle Address

export const GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_REQUEST = 'GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_REQUEST';
export const GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_SUCCESS = 'GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_SUCCESS';
export const GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_FAILED = 'GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_FAILED';

export const GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_REQUEST = 'GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_REQUEST';
export const GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_SUCCESS = 'GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_SUCCESS';
export const GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_FAILED = 'GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_FAILED';

export const UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_REQUEST = 'UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_REQUEST';
export const UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_SUCCESS = 'UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_SUCCESS';
export const UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_FAILED = 'UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_FAILED';

export const DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_REQUEST = 'DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_REQUEST';
export const DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_SUCCESS = 'DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_SUCCESS';
export const DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_FAILED = 'DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_FAILED';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
