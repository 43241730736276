import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getJobQuestionsFailed: null,
  getJobQuestionFailed: null,
  JobQuestions: [],
  JobQuestion: null,
  isSubmitLoading: false,
  isGetJobQuestionLoading: false,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_JOB_QUESTIONS_REQUEST:
      state.isLoading = true;
      state.getJobQuestionsFailed = null;
      return;

    case Types.GET_JOB_QUESTIONS_SUCCESS:
      state.isLoading = false;
      state.JobQuestions = get(payload, 'docs', []);
      state.getJobQuestionsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_JOB_QUESTIONS_FAILED:
      state.isLoading = false;
      state.JobQuestions = [];
      state.getJobQuestionsFailed = payload;
      return;
    case Types.GET_JOB_QUESTION_REQUEST:
      state.isGetJobQuestionLoading = true;
      state.JobQuestion = null
      state.getJobQuestionFailed = null;
      return;

    case Types.GET_JOB_QUESTION_SUCCESS:
      state.isGetJobQuestionLoading = false;
      state.JobQuestion = payload;
      state.getJobQuestionFailed = null;
      return;

    case Types.GET_JOB_QUESTION_FAILED:
      state.isGetJobQuestionLoading = false;
      state.JobQuestion = null;
      state.getJobQuestionFailed = payload;
      return;
    case Types.CREATE_JOB_QUESTION_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_JOB_QUESTION_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_JOB_QUESTION_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_JOB_QUESTION_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_JOB_QUESTION_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.JobQuestions = state.JobQuestions.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.JobQuestion = payload;
      return;

    case Types.UPDATE_JOB_QUESTION_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_JOB_QUESTION_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_JOB_QUESTION_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_JOB_QUESTION_STATE_ACTION:
      state.createSuccess = null;
      state.createFailed = null;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;
    case Types.RESET_STORE:
    case Types.RESET_JOB_QUESTION_STATE:
      return initState;

    default:
      return;
  }
}, initState);
