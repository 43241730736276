import {
  GET_WH_BILLS_REQUEST,
  GET_WH_BILL_REQUEST,
  UPDATE_WH_BILL_REQUEST,
  UPDATE_BILL_ITEM_OF_BILL_REQUEST,
  CONFIRM_PREPAYMENT_WH_BILL_REQUEST,
  CANCEL_WH_BILL_REQUEST,
  DELETE_WH_BILL_REQUEST,
  CUSTOMER_CANCEL_WH_BILL_REQUEST,
  RESET_WH_MEDICINE_STATE,
  RESET_WH_BILL_STATE,
  RESET_WH_BILL_CONFIRM_STATE,
  GET_DEPRECIATION_WH_BILLS_REQUEST,
} from '../../constants/actionTypes';

export const getWhBills = query => ({
  type: GET_WH_BILLS_REQUEST,
  payload: query
});

export const getDepreciationWhBills = query => ({
  type: GET_DEPRECIATION_WH_BILLS_REQUEST,
  payload: query
});

export const getWhBill = id => ({
  type: GET_WH_BILL_REQUEST,
  payload: id
});

export const updateWhBill = instance => ({
  type: UPDATE_WH_BILL_REQUEST,
  payload: instance
});

export const customerCancelWhBill = instance => ({
  type: CUSTOMER_CANCEL_WH_BILL_REQUEST,
  payload: instance
});

export const updateBillItemOfBill = instance => ({
  type: UPDATE_BILL_ITEM_OF_BILL_REQUEST,
  payload: instance
});

export const confirmPrepaymentWhBill = instance => ({
  type: CONFIRM_PREPAYMENT_WH_BILL_REQUEST,
  payload: instance
});

export const cancelWhBill = instance => ({
  type: CANCEL_WH_BILL_REQUEST,
  payload: instance
});

export const deleteWhBill = id => ({
  type: DELETE_WH_BILL_REQUEST,
  payload: id
});

export const resetWhBillConfirmState = () => ({
  type: RESET_WH_BILL_CONFIRM_STATE
});
