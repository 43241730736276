import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  checkInvalidDate,
  useBranchIdSessionStorage
} from '~/hooks/utils';

import {
  getProductList,
  createProductList,
  deleteProductList,
  getProductListById,
  resetProductListState,
  updateProductList,
  resetProductListStateAction,
  getProductListPartnerBorrowed,
  getProductListHistory,
  getProductListOfPartner,
  stopDeliveryProduct,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { clearQuerySearch, getExistProp } from '~/utils/helper';
import { clone, find, get, isString, omit, union, unionBy } from 'lodash';
import moment from 'moment';
import api from '~/api';
import { getSessionPrice } from '~/components/WhService/utils';
import { MAX_PAGINATION_LIMIT, TIME_UNIT, TYPE_USE_WAREHOUSE_VOUCHER, TYPE_VOUCHER_WAREHOUSE, WH_APPOINTMENT_STATUS, WH_LIST_PRODUCT_ACTION } from '~/constants/defaultValue';
import { CONFIRM_VOUCHER_STATUS } from '~/constants/confirmVoucherWarehouse';
import { STATUS_WAREHOUSE_VOUCHER } from '~/constants/warehouse';
import { useUser } from '../user';
const PRODUCT_LIST = 'productList';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(PRODUCT_LIST);

const getSelector = (key) => (state) => state[PRODUCT_LIST][key];

const pagingSelector = getSelector('paging');
const getProductListPartnerBorrowedSelector = getSelector('pagingPartnerBorrowed');
const listPartnerBorrowedSelector = getSelector('listPartnerBorrowed');
const isLoadingPartnerBorrowedSelector = getSelector('isLoadingPartnerBorrowed');
const getListPartnerBorrowedFailedSelector = getSelector('getListPartnerBorrowedFailed');
//History
const pagingHistorySelector = getSelector('pagingHistory');

const loadingHistorySelector = getSelector('isLoadingHistory');
const listHistorySelector = getSelector('listHistory');
const getListHistoryFailedSelector = getSelector('getListHistoryFailed');

const isLoadingStopDeliverySelector = getSelector('isLoadingStop');
const stopDeliverySuccessSelector = getSelector('stopDeliverySuccess');
const stopDeliveryFailedSelector = getSelector('stopDeliveryFailed');

export const useProductListQueryParams = (module, idWarehouse) => {
  const prevKeyword = useRef(null);
  const [branchId] = useBranchIdSessionStorage();
  const query = useQueryParams();
  const keyword = query.get('keyword');
  const code = query.get("code");
  const name = module?.name || query.get("name");
  const type = query.get("type");
  const limit = module?.limit || query.get('limit') || 10;
  const page = query.get('page') || 1
  const groupProductName = query.get("groupProductName");
  const manufacturer = query.get("manufacturer");
  const supplierName = query.get("supplierName");
  const dateManufacturer = query.get("dateManufacturer");
  const countDepreciation = query.get("countDepreciation");
  const valueDepreciation = query.get("valueDepreciation");
  const unitDepreciation = query.get("unitDepreciation");
  const countUsing = query.get("countUsing");
  const status = query.get("status");
  const startDate = query.get("startDate") || null;
  const endDate = query.get("endDate")|| null;
  const warehouseId = idWarehouse ? idWarehouse : null;
  const branchOfs = module?.branchOfs || null

  if (prevKeyword.current !== keyword && prevKeyword.pageSize !== keyword) {
    prevKeyword.current = keyword;
    prevKeyword.pageSize = keyword;
  }

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);
  const stopDeliverySuccess = useSelector(stopDeliverySuccessSelector);

  const createDeliverySuccess = useSelector((state) => state.deliveryVoucher.createSuccess);
  const updateDeliverySuccess = useSelector((state) => state.deliveryVoucher.updateSuccess);
  const deleteDeliverySuccess = useSelector((state) => state.deliveryVoucher.deleteSuccess);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      code,
      name,
      type,
      manufacturer,
      supplierName,
      dateManufacturer,
      countDepreciation,
      valueDepreciation,
      unitDepreciation,
      countUsing,
      status,
      startDate,
      endDate,
      groupProductName,
      warehouseId,
      branchOfs
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    name,
    type,
    manufacturer,
    supplierName,
    dateManufacturer,
    countDepreciation,
    valueDepreciation,
    unitDepreciation,
    countUsing,
    status,
    startDate,
    endDate,
    groupProductName,
    code,
    branchId,
    warehouseId,
    stopDeliverySuccess,
    createDeliverySuccess,
    updateDeliverySuccess,
    deleteDeliverySuccess,
    branchOfs
  ]);
};

export const useUpdateProductListParams = (query, id) => {
  const { path, url } = useRouteMatch()
  const newPath = id ? path.replace(':id', id) : path
  const newQuery = id ? omit(query, 'warehouseId') : query
  const history = useHistory();
  const [keyword, setKeyword] = useState(newQuery?.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    if(!param.code)
     for (const key in param) {
        param[key]=  isString(param[key]) ? (param[key].trim()).replace(/[.]/g,'') : param[key]
     }
    history.push({
      pathname: `${newPath}`,
      search: new URLSearchParams(
        getExistProp({
          ...newQuery,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useProductListHistoryQueryParams = (id) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  // const keyword = query.get('keyword');
  // const code = query.get("code");
  // const name = query.get("name");
  // const type = query.get("type");
  // const startDate = query.get("startDate") ?? moment().startOf('month').format('YYYY-MM-DD');
  // const endDate = query.get("endDate") ?? moment().endOf('month').format('YYYY-MM-DD');

  const [page, setPage] = useState(query.get('page') || 1);
  const onTableChange = ({ current }) => setPage(current);

  // if (prevKeyword.current !== keyword) {
  //   prevKeyword.current = keyword;
  // }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      // startDate,
      // endDate,
      id
    });

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    // startDate,
    // endDate,
    id
  ]);
};
export const useProductListHistoryPaging = () => useSelector(pagingHistorySelector);

//History of Product
export const useProductListPaging = () => useSelector(pagingSelector);
export const useProductListPartnerBorrowedPaging = () => useSelector(getProductListPartnerBorrowedSelector);

export const useProductListHistory = (params) => {
  return useFetchByParam({
    action: getProductListHistory,
    loadingSelector: loadingHistorySelector,
    dataSelector: listHistorySelector,
    failedSelector: getListHistoryFailedSelector,
    param: params
  });
};


//ProductList
export const useProductLists = (query) => {
  return useFetchByParam({
    action: getProductList,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useProductListsOfPartner = (query) => {
  return useFetchByParam({
    action: getProductListOfPartner,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};


export const useCreateProductList = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới sản phẩm thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới sản phẩm thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createProductList
  });
};

export const useUpdateProductList = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật sản phẩm thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật sản phẩm thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateProductList
  });
};

export const useStopDeliveryProduct = (callback) => {
  useSuccess(stopDeliverySuccessSelector, 'Rút thiết bị ra khỏi phiếu luân chuyển thành công', callback);
  useFailed(stopDeliveryFailedSelector, 'Rút thiết bị ra khỏi phiếu luân chuyển thất bại');

  return useSubmit({
    loadingSelector:isLoadingStopDeliverySelector,
    action: stopDeliveryProduct
  });
};

export const useDeleteProductList = () => {
  useSuccess(deleteSuccessSelector);
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector,
    action: deleteProductList
  });
};

export const useProductList = (params) => {
  return useFetchByParam({
    action: getProductListById,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useResetProductList = () => {
  useResetState(resetProductListState);
};
export const useResetProductListAction = () => {
  useResetState(resetProductListStateAction);
};


export const useProductListPartnerBorrowedQueryParams = (id) => {
  const query = useQueryParams();
  const limit = query.get('limit') || MAX_PAGINATION_LIMIT;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword')
  const groupProductName = query.get('groupProductName')
  const code = query.get('code')
  const name = query.get('name')
  const billNumber = query.get('billNumber')
  const codeWhAppointment = query.get('codeWhAppointment')

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      id,
      groupProductName,
      code,
      name,
      billNumber,
      codeWhAppointment,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    id,
    groupProductName,
    code,
    name,
    billNumber,
    codeWhAppointment,
  ]);
};

export const useUpdateProductListPartnerBorrowedParams = (query, listOptionSearch) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword])
  const onParamChange = (param) => {
    clearQuerySearch(listOptionSearch, query, param)
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/workspace/equipment',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};



export const useProductListPartnerBorrowed = (query) => {
  return useFetchByParam({
    action: getProductListPartnerBorrowed,
    loadingSelector: isLoadingPartnerBorrowedSelector,
    dataSelector: listPartnerBorrowedSelector,
    failedSelector: getListPartnerBorrowedFailedSelector,
    param: query
  });
};

export const useModalCalendarProduct = () => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [idSelectCalendar, setIdSelectCalendar] = useState(null);
  const handleOpenCalendar = (id) => {
    setIsOpenCalendar(true)
    setIdSelectCalendar(id)
  }
  const handleCloseCalendar = () => {
    setIsOpenCalendar(false)
    setIdSelectCalendar(null)
  }
  return { isOpenCalendar, idSelectCalendar, handleOpenCalendar, handleCloseCalendar }
}

const joinDateAndTime = (inputDate, inputTime) => {

  // Parse the input date using Moment.js
  const parsedDate = moment(inputDate);

  // Extract the date part from the input date and the time part from the input time
  const timePart = moment(inputTime, "HH:mm")
  parsedDate.set('h', timePart.hour());
  parsedDate.set('m', timePart.minute());

  // Output the result
  return parsedDate.format('YYYY-MM-DDTHH:mm')
}

class DataExpectCalendar {
  constructor(productId,preBookings = [],confirmVouchers = [],warehouseVouchers = []) {
    this.productId = productId;
    this.preBookings = preBookings;
    this.confirmVouchers = confirmVouchers;
    this.warehouseVouchers = warehouseVouchers;
    this.dataCalendar = [];
  }
  // INSTANCE FORMAT INPUT LIST
  getProductCheck(voucher){
    return get(voucher,'listProduct',[])?.find(item => get(item,'productId') === this.productId);
  }
  setProductCheckForList(list){
    return list?.map(item => ({...item,productCheck : this.getProductCheck(item)}));
  }
   formatList(){
    this.confirmVouchers = this.setProductCheckForList(this.confirmVouchers);
    this.warehouseVouchers = this.setProductCheckForList(this.warehouseVouchers);
    return this
  }
//

  // FILTER CONFIRM
  filterDataConfirm(item){
    return get(item,'status') === CONFIRM_VOUCHER_STATUS.CONFIRM;
  }
  filterDataCompleted(item){
    return get(item,'status') === CONFIRM_VOUCHER_STATUS.COMPLETED;
  }
  //
  // FILTER VOUCHER WAREHOUSE
  filterDataAssign(item){
    const {typeVoucher,status} = item;
    return typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG && status === STATUS_WAREHOUSE_VOUCHER.NEW;
  }
  filterDataDelivered(item){
    const {typeVoucher,status} = item;
    return typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG && status === STATUS_WAREHOUSE_VOUCHER.CONFIRM;
  }
  filterDataReturned(item){
    const {typeVoucher,status} = item;
    return typeVoucher === TYPE_VOUCHER_WAREHOUSE.PHT && status === STATUS_WAREHOUSE_VOUCHER.CONFIRM;
  }
  //

   // PRE_BOOKING
  removeDuplicatesPreBooking() {// remove duplicates Appointment in list preBooking , prevent BE send response Two Appointment duplicate
    this.preBookings = unionBy(this.preBookings, (e) => get(e, 'whAppointment._id'))
  }
  createDataPreBookingForCalendar() {
    this.preBookings.forEach((item) => {
      const duration = getDurationExpectTimeEnd(get(item,'whAppointment'));
      const expectTimeEnd = moment(get(item, 'whAppointment.time'), 'HH:mm').add(duration, 'minutes').format("HH:mm");
      this.dataCalendar.push({
        id: get(item, 'whAppointment._id'),
        title: get(item, 'whAppointment.code'),
        start: joinDateAndTime(get(item, 'whAppointment.date'), get(item, 'whAppointment.time')),
        end: joinDateAndTime(get(item, 'whAppointment.date'), expectTimeEnd),
        extendedProps: { data : item },
        color: WH_LIST_PRODUCT_ACTION.PRE_BOOKING.color,
        typeOfItem : WH_LIST_PRODUCT_ACTION.PRE_BOOKING.value,
        typeVoucher :'preBooking'
      });

    })

  }

  //
   getInstance(){
    this.formatList();
    return this;
  }
  // Create data Confirm needed for Full Calendar Component
  createItemConfirmForCalendar(voucher, type) {
    const productCheck = get(voucher,'productCheck');
    const whAppointment  = get(productCheck,'whAppointment');
    const whPartner  = get(productCheck,'whPartner');
    const duration = getDurationExpectTimeEnd(whAppointment)
    const expectTimeEnd = moment(get(whAppointment, 'time'), 'HH:mm').add(duration, 'minutes').format("HH:mm");
    this.dataCalendar.push({
      id: get(whAppointment, '_id'),
      title: get(whAppointment, 'code'),
      start: joinDateAndTime(get(whAppointment, 'date'), get(whAppointment, 'time')),
      end: joinDateAndTime(get(whAppointment, 'date'), expectTimeEnd),
      extendedProps: { data: {...voucher,whPartner}, },
      color: WH_LIST_PRODUCT_ACTION[type].color,
      typeOfItem : WH_LIST_PRODUCT_ACTION[type].value,
      typeVoucher : 'confirm',
    });
  }
  createDataConfirmForCalendar(){
    this.confirmVouchers.forEach(voucher => {
      const productCheck =  get(voucher,'productCheck');
      switch (true) {
        case this.filterDataConfirm(productCheck):
          this.createItemConfirmForCalendar(voucher,WH_LIST_PRODUCT_ACTION.CONFIRMED.value)
          break;
        case this.filterDataCompleted(productCheck):
          this.createItemConfirmForCalendar(voucher,WH_LIST_PRODUCT_ACTION.USED.value)
          break;
        default:
          // this.createItemDataWhAppointmentForCalendar(item,WH_LIST_PRODUCT_ACTION.unKnow.value)
          break;
      }
    })
    return this
  }

    // Create data Voucher Warehouse needed for Full Calendar Component
    createItemVoucherWarehouseForCalendar(item, type) {
      const {whPartner} = item;
      this.dataCalendar.push({
        id: get(item, '_id'),
        title: get(whPartner, 'name'),
        start: get(item,'createdAt'),
        extendedProps: { data: {...item,whPartner}, },
        color: WH_LIST_PRODUCT_ACTION[type].color,
        typeOfItem : WH_LIST_PRODUCT_ACTION[type].value,
        typeVoucher : 'warehouse'
      });
    };
    createDataWarehouseVoucherForCalendar(){
      this.warehouseVouchers.forEach(voucher => {
        switch (true) {
          case this.filterDataAssign(voucher):
            this.createItemVoucherWarehouseForCalendar(voucher,WH_LIST_PRODUCT_ACTION.ASSIGNED.value)
            break;
          case this.filterDataDelivered(voucher):
            this.createItemVoucherWarehouseForCalendar(voucher,WH_LIST_PRODUCT_ACTION.DELIVERED.value)
            break;
          case this.filterDataReturned(voucher):
            this.createItemVoucherWarehouseForCalendar(voucher,WH_LIST_PRODUCT_ACTION.RETURNED.value)
            break;
          default:
            // this.createItemDataWhAppointmentForCalendar(item,WH_LIST_PRODUCT_ACTION.unKnow.value)
            break;
        }
      })
      return this
    }
}

export const useGetCalendarProductById = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const queryFetch = useMemo(() => ({
    idProduct: id,
    startDate,
    endDate,
  }), [id, startDate, endDate])
  const fetchCalendar = useCallback(async (queryFetch) => {
    try {
      setLoading(true)
      const res = await api.productList.getCalendar(queryFetch);
      const listCalendar = new DataExpectCalendar(id,get(res, 'preBooking', []),get(res, 'confirmVoucher', []),get(res,'commercialPurchaseVoucher',[]));
      listCalendar.getInstance()
      .createDataConfirmForCalendar()
      .createDataWarehouseVoucherForCalendar()
      .createDataPreBookingForCalendar();
      setData(listCalendar.dataCalendar);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  },[id]);

  useEffect(() => {
    if (id && startDate && endDate) {
      fetchCalendar(queryFetch)
    }

  }, [queryFetch, id, startDate, endDate, fetchCalendar])
  const onChangeDate = useCallback((date) => {
    setStartDate(get(date, 'startDate'))
    setEndDate(get(date, 'endDate'))
  }, []);

  return { data, loading, onChangeDate }
}
/**
 * 
 * @param {Object} whAppointment 
 * @returns {Number} Pass WhAppointment and received a expected Time end Type Minute
 */
export const getDurationExpectTimeEnd = (whAppointment) => {
  const whPackageLevel = get(whAppointment, 'snapshotService.package')
  const whSessionOfDayId = get(whAppointment, 'whSessionOfDayId')
  const sessionPrices = get(whPackageLevel, "sessionPrices");
  // get whSessionOfDay 
  const whSessionOfDay = find(sessionPrices, { whSessionOfDayId })

  // get duration can be Minute or hour
  const duration = get(whSessionOfDay, "duration");

  const timeUnit = get(whSessionOfDay, "extraDuration");

  // convert duration to minutes
  const durationInMinutes = moment.duration(
    duration, timeUnit === TIME_UNIT.HOUR ? "hour" : "minute"
  ).asMinutes()
  return durationInMinutes
}

