import { Button, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect } from 'react'

export default function FormNote({initNote,onUpdate,loading}) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        onUpdate(values)
    };
    useEffect(() => {
        form.setFieldsValue({note : initNote})
    },[initNote])
  return (
    <Form form={form} onFinish={onFinish}>
        <Form.Item name={'note'}>
            <TextArea rows={4}/>
        </Form.Item>
        <Button loading={loading} htmlType='submit' type='primary' size='small'>
            Lưu
        </Button>
    </Form>
  )
}
