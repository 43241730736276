import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Input, Menu, Modal, Row, Table } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';
import POLICIY from '~/constants/policy';
import {
  useCreateTrainingCourse,
  useResetTrainingCourse,
  useTrainingCoursePaging,
  useTrainingCourseQueryParams,
  useTrainingCourses,
  useUpdateTrainingCourse,
  useUpdateTrainingCourseParams,
  useTrainingCourse,
  useDeleteTrainingCourse
} from '~/hooks/trainingCourse';
import { Breadcrumb, WithPermission } from '../Common';
import SkeletonTable from '../Utils/SkeletonTable';
import FormCreateCourse from './FormCreateCourse';
const { Search } = Input;

export default function WhTrainingCourse() {
  const [isOpenModalCreate, setIsOpenCreate] = useState(false);
  const [isOpenModalDelete, setIsOpenDelete] = useState(false);
  const [query] = useTrainingCourseQueryParams();
  const [keyword,{setKeyword, onParamChange }] = useUpdateTrainingCourseParams(query);
  const [id,setId] = useState(null);
  const [idDelete,setIdDelete] = useState(null);
  const onCancelDelete = () => {
    setIsOpenDelete(false);
    setIdDelete(null)
  };
  const [ ,deleteTrainingCourse] = useDeleteTrainingCourse(onCancelDelete)
  const onCancel = () => {
    setIsOpenCreate(false);
    setId(null)
  };
  const onOpenDelete = (id) => {
    setIsOpenDelete(true);
    setIdDelete(id)
  };
  const handleDelete = () => {
    deleteTrainingCourse(idDelete)
  };
  const refCreateTrainingCourse = useRef();
  const handleSubmitCreate = () => {
    onCancel();
    if (refCreateTrainingCourse.current.resetForm && typeof refCreateTrainingCourse.current.resetForm === 'function') {
      refCreateTrainingCourse.current.resetForm();
    }
    onParamChange({keyword : null})
  };
  const handleSubmitUpdate = () => {
    onCancel();
    setId(null);
    if (refCreateTrainingCourse.current.resetForm && typeof refCreateTrainingCourse.current.resetForm === 'function') {
      refCreateTrainingCourse.current.resetForm();
    }
  };
  useResetTrainingCourse();
  const [isLoading, createTrainingCourse] = useCreateTrainingCourse(handleSubmitCreate);
  const [ ,updateTrainingCourse] = useUpdateTrainingCourse(handleSubmitUpdate)
  const [trainingCourse,isloadingGetTrainingCourse] = useTrainingCourse(id);
  const [trainingCourses, isLoadingTraining] = useTrainingCourses(query);
  useEffect(() => {
    if(keyword === ''){
      onParamChange({keyword : null})
    }
  },[keyword])
  const paging = useTrainingCoursePaging();
  const column = useMemo(() => [
    {
      title: 'STT',
      key: 'null',
      align: 'center',
      render: (item, record, index) => index + 1
    },
    {
      title: 'ID khoá đào tạo',
      key: 'courseId',
      dataIndex: 'courseId',
      align: 'center',
      render: (item) => `WTC${item}` || ''
    },
    {
      title: 'Đối tác đảm nhận',
      key: 'partners',
      dataIndex: 'partners',
      align: 'center',
      render: (item) => item?.map(e => <p>{get(e, 'name','')}</p>)
    },
    {
      title: 'Người chịu trách nhiệm',
      key: 'user',
      dataIndex: 'user',
      align: 'center',
      render: (item) => get(item, '[0].fullName','s')
    },
    {
      title: 'Thao tác',
      key: null,
      dataIndex: null,
      align: 'center',
      render: (item, record, index) => (
        <Dropdown
          overlayStyle={{ width: 150 }}
          trigger={['click']}
          placement='bottomCenter'
          overlay={
            <Menu>
              <WithPermission permission={POLICIY.UPDATE_WHCOURSETRAINING}>
              <Menu.Item key={v4()} onClick={() => {
                setId(record._id)
                setIsOpenCreate(true)
                }}>
                <p>Cập nhật</p>
              </Menu.Item>
              </WithPermission>
              <WithPermission permission={POLICIY.DELETE_WHCOURSETRAINING}>
              <Menu.Item
                key={v4()}
                onClick={() =>onOpenDelete(record._id)}>
               
                <p>Xoá</p>
              </Menu.Item>
              </WithPermission>
            </Menu>
          }
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
      )
    }
  ],[]);
  return (
    <div className="page-wraper page-content">
      <Breadcrumb title="Quản lý khoá đào tạo" />
      <Modal
        closable={false}
        visible={isOpenModalCreate}
        onCancel={onCancel}
        footer={null}
      >
        <FormCreateCourse
        id={id}
          ref={refCreateTrainingCourse}
          trainingCourse={trainingCourse}
          createTrainingCourse={createTrainingCourse}
          updateTrainingCourse={updateTrainingCourse}
          isLoading={isLoading}
          isloadingGetTrainingCourse={isloadingGetTrainingCourse}
          onCancel={onCancel}
        />
      </Modal>
      <Modal style={{textAlign : 'center'}} visible={isOpenModalDelete} onCancel={onCancelDelete} closable={false} footer={null}>
        <h5>Bạn có muốn xoá ?</h5>
        <Row style={{width : '100%'}}>
          <Col span={12}><Button onClick={onCancelDelete} >Huỷ</Button></Col>
          <Col span={12}><Button loading={isLoading} type='danger' onClick={handleDelete}>Xoá</Button></Col>
        </Row>
      </Modal>
      <Row style={{ margin: '20px 0' }} justify="space-between">
        <Col>
          <Search
            style={{ width: 300 }}
            placeholder="Nội dung tìm kiếm"
            enterButton
            allowClear
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onSearch={() => onParamChange({ keyword : keyword?.toString().trim() })}
          />
        </Col>
        <Col>
        <WithPermission permission={POLICIY.WRITE_WHCOURSETRAINING}>
          <Button onClick={() => setIsOpenCreate(true)} type="primary">
            <PlusOutlined />
            Thêm mới
          </Button>
        </WithPermission>
        </Col>
      </Row>
      {isLoadingTraining ? (
        <SkeletonTable columns={column} rowCount={10} />
      ) : (
        <Table
          columns={column}
          dataSource={trainingCourses}
          onChange={({ current }) => {
            onParamChange({ page: current });
          }}
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
        />
      )}
    </div>
  );
}
