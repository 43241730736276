import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import toastr from 'toastr';
import { get, union, isInteger, isArray } from 'lodash';
import { PLACE_DID_SERVICE } from '~/constants/defaultValue';
import { queryFetchAll } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchPolicy, useProfile, useUser } from './user';
import moment, { isMoment } from 'moment';
import { setAxiosCompanyId } from '~/api/requester';
import { changeBranchIdSession } from '~/redux/action';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-bottom-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
};

export const ToastrCustom = (message, type = 'success', title = '', config = {}) => {
  toastr.remove()
  toastr[type](message, title, { ...config })
}

export const useSuccess = (successSelector, mess, onSuccess) => {
  const success = useSelector(successSelector);
  useEffect(() => {
    if (success) {
      if (mess) {
        toastr.success(mess);
      }

      if (onSuccess) onSuccess(success);
    }
    //eslint-disable-next-line
  }, [success, mess]);
};

export const useFailed = (failedSelector, mess, onFailed, mute = false) => {
  const failed = useSelector(failedSelector);
  useEffect(() => {
    if (failed && !mute) {
      ToastrCustom(
        mess || failed?.message || 'Something went wrong!', 'error'
      );
    }

    if (onFailed) onFailed(failed);
  }, [failed, mess, onFailed]);
};

export const useFetch = (props) => {
  const { action, dataSelector, failedSelector, loadingSelector, payload } = props;

  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const isLoading = useSelector(loadingSelector);
  // useEffect(()=>{
  //   console.log(payload,"ccdaddafc");
  // },[payload])
  useEffect(() => {
    dispatch(action());
  }, [dispatch, action, payload]);

  useFailed(failedSelector);

  return [data, isLoading];
};

export const useFetchByParam = (props) => {
  const {
    action,
    dataSelector,
    failedSelector,
    loadingSelector,
    param,
    muteOnFailed,
    actionUpdate
  } = props;
  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const isLoading = useSelector(loadingSelector);
  useEffect(() => {
    if (param) dispatch(action(param));
    // else dispatch(action(param));
  }, [dispatch, action, param]);

  useFailed(failedSelector, undefined, undefined, muteOnFailed);

  const useUpdateData = (dataUpdate) => { // Update Data To Redux
    if (actionUpdate && typeof actionUpdate === 'function') {
      dispatch(actionUpdate(dataUpdate));
    }
  }
  return [data, isLoading, useUpdateData];
};

export const useCustomFetch = (props) => {
  const { action, dataSelector, failedSelector, loadingSelector, param } =
    props;

  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const isLoading = useSelector(loadingSelector);

  const onRefresh = () => {
    dispatch(action(param));
  };

  useEffect(() => {
    dispatch(action(param));
  }, [dispatch, action, param]);

  useFailed(failedSelector);

  return [data, isLoading, onRefresh];
};

export const useSubmit = ({ loadingSelector, action }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);

  const handleSubmit = (values) => {
    dispatch(action(values));
  };

  return [isLoading, handleSubmit];
};


export const useAction = ({ action }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(action(values));
  };

  return [handleSubmit];
};

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const useResetState = (resetAction) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetAction());
    };
  }, [dispatch, resetAction]);
};

export const useAddDataLocally = ({ action }) => {
  const dispatch = useDispatch();

  const addDataLocally = (instances) => {
    dispatch(action(instances));
  };

  return [addDataLocally];
};

export const useRemoveDataLocally = ({ action }) => {
  const dispatch = useDispatch();

  const removeDataLocally = (func) => {
    dispatch(action(func));
  };

  return [removeDataLocally];
};

export function removeAccents(str) {
  return str
    .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
    .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
    .replace(/ì|í|ị|ỉ|ĩ/g, "i")
    .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
    .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
    .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
    .replace(/đ/g, "d")
}

export const filterAcrossAccents = (input, option) => {
  return (
    removeAccents(option.children.toLowerCase()).indexOf(removeAccents(input.toLowerCase())) >= 0
  );
};
export const filterAcrossAccentsByLabel = (input, option) => {
  return (
    removeAccents(option?.label?.toLowerCase()).indexOf(removeAccents(input.toLowerCase())) >= 0
  );
};

export const filterAcrossAccentsService = (input, option) => {
  const test = `${option?.code} - ${option?.name?.vi}`
  return (
    removeAccents(test?.toLowerCase() ?? '').indexOf(removeAccents(input.toLowerCase())) >= 0
  );
};

export const filterGroupAcrossAccents = (input, group) => {
  return {
    ...group,
    options: group.options.filter(
      (option) =>
        removeAccents(option.children.toLowerCase()).indexOf(
          removeAccents(input.toLowerCase())
        ) >= 0
    )
  };
};

export const useFormItemMargin = () => {
  const [margin, setMargin] = useState(-7);

  useEffect(() => {
    const onLarge = (media) => {
      if (media.matches) {
        setMargin(-7);
      } else {
        setMargin(0);
      }
    };

    const media = window.matchMedia('(min-width: 982px)');
    onLarge(media);

    media.addListener(onLarge);
    return () => {
      media.removeListener(onLarge);
    };
  }, []);

  return margin;
};

export const getSelectors = (moduleName) => {
  const getSelector = (key) => (state) => state[moduleName][key];

  return {
    loadingSelector: getSelector('isLoading'),
    listSelector: getSelector('list'),
    getListFailedSelector: getSelector('getListFailed'),

    getByIdLoadingSelector: getSelector('isGetByIdLoading'),
    getByIdSelector: getSelector('byId'),
    getByIdFailedSelector: getSelector('getByIdFailed'),

    deleteSuccessSelector: getSelector('deleteSuccess'),
    deleteFailedSelector: getSelector('deleteFailed'),

    isSubmitLoadingSelector: getSelector('isSubmitLoading'),
    createSuccessSelector: getSelector('createSuccess'),
    createFailedSelector: getSelector('createFailed'),

    updateSuccessSelector: getSelector('updateSuccess'),
    updateFailedSelector: getSelector('updateFailed'),
    pagingSelector: getSelector('paging')
  };
};

export const filterActiveInstances = (instances, attr = 'status') => {
  if (instances && instances.length) {
    return instances.filter((instance) => instance[attr] === 'ACTIVE');
  }
  return instances;
};

export const useTags = (initialState = []) => {
  const [state, setState] = useState(initialState);
  const setUiqueState = (newState) => {
    setState(union(newState));
  };
  return [state, setUiqueState];
};
export const formatNumberThreeComma = (num) => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");



 export const vietnamMoment =(v,formatTime)=> {
    if(v){
      const utcMoment = moment.utc(v);
      if(formatTime){
        return utcMoment.format(formatTime);
      }
      else{
        return utcMoment
      }
    }
    return null
   
  }
/**
 * 
 * @param {object} list 
 * @param {function} optionDisabled Function to Check value Disabled
 * @returns 
 */
export const useOptionsSelectAntd = (list,optionDisabled) => {
  return useMemo(()=>{
    if(Object.values.length === 0) return []
    let options = []
  for (const [key, value] of Object.entries(list)) {
    let disabled = false;
    if(optionDisabled && typeof optionDisabled === 'object'){
      const {callback,keyCheck} = optionDisabled;
      if(callback && typeof callback === 'function'){
        if(keyCheck === 'value'){
          disabled =  callback(value)
        }
        if(keyCheck === 'key'){
          disabled =  callback(key)
        }
      }
    }
    options.push({label : value,value : key,disabled})
  }
  return options
  },[list])
}

export function capitalizeFirstLetter(str) {
  if (str.length === 0) {
    return str; // return empty string if input is empty
  }

  var firstChar = str.charAt(0); // get the first character
  var capitalizedChar = firstChar.toUpperCase(); // convert it to uppercase
  var restOfString = str.slice(1); // get the rest of the string

  return capitalizedChar + restOfString; // return the capitalized string
}
export const getNamePlaceDidService = (record, locale = 'vi') => !get(record, 'placeDidService') ? " " : get(record, 'placeDidService.slug', '') === PLACE_DID_SERVICE.home.value ? PLACE_DID_SERVICE.home[locale] : `${get(record, `hospitalId.nameHealthCare.${locale}`, '')} - ${get(record, 'hospitalId.street', '')}`


export const filterSelectAntd = (input, option) =>
  option.value
    .toLowerCase()
    .indexOf(input.toLowerCase()) >= 0 ||
  option.label
    .toLowerCase()
    .indexOf(input.toLowerCase()) >= 0

export const useQueryFetchAll = ({ reFetch, moreField }) => {
  const query = useMemo(() => ({ ...queryFetchAll, ...moreField }), [reFetch])
  return query
}


export const Permissions = (key) => {
  const canCreate = useMatchPolicy(get(POLICIES, `WRITE_${key}`));
  const canUpdate = useMatchPolicy(get(POLICIES, `UPDATE_${key}`));
  const canDelete = useMatchPolicy(get(POLICIES, `DELETE_${key}`));
  const canRead = useMatchPolicy(get(POLICIES, `READ_${key}`));
  return {
    canCreate,
    canUpdate,
    canDelete,
    canRead,
  }
}
/**
 * 
 * @param {Number} num Number 
 * @param {Number} fixed Fixed value
 * @returns {String}
 */
export const formatNumberThreeCommaToFixed = (num, fixed = 2) => {
  if (!num) return 0
  const parsedNum = parseFloat(num);
  if (isInteger(parsedNum) && parsedNum === 0) {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else if (Number.isInteger(parsedNum)) {
    return parsedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return parsedNum.toFixed(fixed).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const checkInvalidDate = (time) => isMoment(time) ? moment(time).format('YYYY-MM-DD') : null ;

export const getPolicySuccessSelector = (state) => state.user['getPolicySuccess'];

export const useBranchIdSessionStorage = () => {
const branchId = useSelector((state) => state.user['branchIdSession']);
  const dispatch = useDispatch();
  const setBranch = (branchId) => {
    sessionStorage.setItem("branchId", branchId);
    setAxiosCompanyId(branchId);
    dispatch(changeBranchIdSession(branchId));
  }
  useEffect(() => {
    const branchIdSession = sessionStorage.getItem("branchId");
    dispatch(changeBranchIdSession(branchIdSession));
  },[]);
return [branchId,setBranch]
}

export const useGetInfoCurrentBranch = () => {
  const [profile]= useProfile();
  const [branchId] = useUser();
  const branch = useMemo(() => get(profile,'branches',[])?.find(br => parseInt(get(br,'_id')) === parseInt(branchId)),[profile,branchId]);
  return [branch]
}

/**
 *
 * @param {String} branchIdDefault - Priority default branch
 * @return {Array} - branchIds and method change branchIds
 * branchIds is a String join with , Like : 99999,10123,12345
 */
export const useBranchesDefault = (branchIdDefault) => {
  const branchIdStore = useSelector((state) => state.user['branchId']);
  const [branchIds, setBranchIds] = useState(branchIdDefault ?? branchIdStore); // Always String
  const setBranch = (value) => { // IF Branch empty is Get DefaultBranch in Redux

    if (value && value?.length) { // Change Branch
      if (isArray(value)) {
        const StringValue = value?.map(v => v?.toString());
        setBranchIds(StringValue.join(','))
      } else {
        setBranchIds(value?.toString());
      }
    } else { // Clear Branch
      setBranchIds(branchIdStore);
    }
  };
  return [branchIds, setBranch]
}


export const useFetchState = ({ api, query, useDocs = true, init = [], fieldGet,reFetch,nullNotFetch = false ,conditionRun = false,shouldRun=true}) => {
  const [data, setData] = useState(init);
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const req = useCallback(api, [api]);
  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await req(query);
      setTotalDocs(get(response,'totalDocs',0));
      if (fieldGet) {
        setData(get(response, fieldGet))
      } else {
        if (useDocs) {
          setData(get(response, 'docs', []));
        } else {
          setData(response);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [query,reFetch])
  useEffect(() => {
    if(shouldRun){
      if(conditionRun){
        fetch()
      }else{
        if(nullNotFetch){
          !!query && fetch();
        }else{
          fetch()
        }
      }
    }

  }, [fetch,nullNotFetch,query]);
  const dataReturn = useMemo(() => data, [data])
  return [dataReturn, loading,totalDocs]
};

 export const handleSubmit = async () => {
  try {
    await form.validateFields();
    form.submit()
  } catch (error) {
    const { errorFields } = error;
    if (errorFields) {
      form.scrollToField(get(head(errorFields), 'name'), {
        block: 'center'
      });
      console.log(errorFields);
      if (
        errorFields.some((field) => {
          const { name: namePath } = field;
          return namePath?.includes(
            language === LANGUAGE.VI ? LANGUAGE.EN : LANGUAGE.VI
          );
        })
      ) {
        notification.error({
          message: 'Bạn chưa nhập đủ các trường của form',
          description: `Có một số trường còn thiếu thuộc ngôn ngữ ${
            language === LANGUAGE.VI ? 'EN' : 'VI'
          }`,
          placement: 'bottom'
        });
      }
    }
  }
};
