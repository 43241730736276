export const GET_PARTNERS_REQUEST = 'GET_PARTNERS_REQUEST';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_FAILED = 'GET_PARTNERS_FAILED';

export const GET_PARTNER_REQUEST = 'GET_PARTNER_REQUEST';
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
export const GET_PARTNER_FAILED = 'GET_PARTNER_FAILED';

export const CREATE_PARTNER_REQUEST = 'CREATE_PARTNER_REQUEST';
export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
export const CREATE_PARTNER_FAILED = 'CREATE_PARTNER_FAILED';

export const CREATE_BRANCH_IN_PARTNER_REQUEST = 'CREATE_BRANCH_IN_PARTNER_REQUEST';
export const CREATE_BRANCH_IN_PARTNER_SUCCESS = 'CREATE_BRANCH_IN_PARTNER_SUCCESS';
export const CREATE_BRANCH_IN_PARTNER_FAILED = 'CREATE_BRANCH_IN_PARTNER_FAILED';

export const UPDATE_PARTNER_REQUEST = 'UPDATE_PARTNER_REQUEST';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_FAILED = 'UPDATE_PARTNER_FAILED';

export const UPDATE_BRANCH_IN_PARTNER_REQUEST = 'UPDATE_BRANCH_IN_PARTNER_REQUEST';
export const UPDATE_BRANCH_IN_PARTNER_SUCCESS = 'UPDATE_BRANCH_IN_PARTNER_SUCCESS';
export const UPDATE_BRANCH_IN_PARTNER_FAILED = 'UPDATE_BRANCH_IN_PARTNER_FAILED';

export const UPDATE_BRANCH_STATUS_REQUEST = 'UPDATE_BRANCH_STATUS_REQUEST';
export const UPDATE_BRANCH_STATUS_SUCCESS = 'UPDATE_BRANCH_STATUS_SUCCESS';
export const UPDATE_BRANCH_STATUS_FAILED = 'UPDATE_BRANCH_STATUS_FAILED';

export const DELETE_PARTNER_REQUEST = 'DELETE_PARTNER_REQUEST';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
export const DELETE_PARTNER_FAILED = 'DELETE_PARTNER_FAILED';

export const DELETE_BRANCH_IN_PARTNER_REQUEST = 'DELETE_BRANCH_IN_PARTNER_REQUEST';
export const DELETE_BRANCH_IN_PARTNER_SUCCESS = 'DELETE_BRANCH_IN_PARTNER_SUCCESS';
export const DELETE_BRANCH_IN_PARTNER_FAILED = 'DELETE_BRANCH_IN_PARTNER_FAILED';

export const GET_PARTNER_BRANCHES_REQUEST = 'GET_PARTNER_BRANCHES_REQUEST';
export const GET_PARTNER_BRANCHES_SUCCESS = 'GET_PARTNER_BRANCHES_SUCCESS';
export const GET_PARTNER_BRANCHES_FAILED = 'GET_PARTNER_BRANCHES_FAILED';

export const ACCESS_PERMISSION_PARTNER_REQUEST = 'ACCESS_PERMISSION_PARTNER_REQUEST';
export const ACCESS_PERMISSION_PARTNER_SUCCESS = 'ACCESS_PERMISSION_PARTNER_SUCCESS';
export const ACCESS_PERMISSION_PARTNER_FAILED = 'ACCESS_PERMISSION_PARTNER_FAILED';

export const DENY_PERMISSION_PARTNER_REQUEST = 'DENY_PERMISSION_PARTNER_REQUEST';
export const DENY_PERMISSION_PARTNER_SUCCESS = 'DENY_PERMISSION_PARTNER_SUCCESS';
export const DENY_PERMISSION_PARTNER_FAILED = 'DENY_PERMISSION_PARTNER_FAILED';

export const GET_LIST_ACCESS_PERMISSION_PARTNER_REQUEST = 'GET_LIST_ACCESS_PERMISSION_PARTNER_REQUEST';
export const GET_LIST_ACCESS_PERMISSION_PARTNER_SUCCESS = 'GET_LIST_ACCESS_PERMISSION_PARTNER_SUCCESS';
export const GET_LIST_ACCESS_PERMISSION_PARTNER_FAILED = 'GET_LIST_ACCESS_PERMISSION_PARTNER_FAILED';

export const GET_RESOURCE_PARTNER_REQUEST = 'GET_RESOURCE_PARTNER_REQUEST';
export const GET_RESOURCE_PARTNER_SUCCESS = 'GET_RESOURCE_PARTNER_SUCCESS';
export const GET_RESOURCE_PARTNER_FAILED = 'GET_RESOURCE_PARTNER_FAILED';

export const ACCESS_PERMISSION_CLINIC_REQUEST = 'ACCESS_PERMISSION_CLINIC_REQUEST';
export const ACCESS_PERMISSION_CLINIC_SUCCESS = 'ACCESS_PERMISSION_CLINIC_SUCCESS';
export const ACCESS_PERMISSION_CLINIC_FAILED = 'ACCESS_PERMISSION_CLINIC_FAILED';

export const DENY_PERMISSION_CLINIC_REQUEST = 'DENY_PERMISSION_CLINIC_REQUEST';
export const DENY_PERMISSION_CLINIC_SUCCESS = 'DENY_PERMISSION_CLINIC_SUCCESS';
export const DENY_PERMISSION_CLINIC_FAILED = 'DENY_PERMISSION_CLINIC_FAILED';

export const GET_LIST_ACCESS_PERMISSION_CLINIC_REQUEST = 'GET_LIST_ACCESS_PERMISSION_CLINIC_REQUEST';
export const GET_LIST_ACCESS_PERMISSION_CLINIC_SUCCESS = 'GET_LIST_ACCESS_PERMISSION_CLINIC_SUCCESS';
export const GET_LIST_ACCESS_PERMISSION_CLINIC_FAILED = 'GET_LIST_ACCESS_PERMISSION_CLINIC_FAILED';

export const GET_RESOURCE_CLINIC_REQUEST = 'GET_RESOURCE_CLINIC_REQUEST';
export const GET_RESOURCE_CLINIC_SUCCESS = 'GET_RESOURCE_CLINIC_SUCCESS';
export const GET_RESOURCE_CLINIC_FAILED = 'GET_RESOURCE_CLINIC_FAILED';

export const RESET_PARTNER_STATE = 'RESET_PARTNER_STATE';
