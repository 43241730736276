import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { forEach, forIn, get, groupBy, head } from 'lodash';
import { getExistProp } from '~/utils/helper';
import {
  useBranchIdSessionStorage,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhBills,
  deleteWhBill,
  getWhBill,
  updateWhBill,
  updateBillItemOfBill,
  confirmPrepaymentWhBill,
  cancelWhBill,
  customerCancelWhBill,
  resetWhBillConfirmState,
  getDepreciationWhBills
} from '~/redux/action';
import api from '~/api';
import { TYPE_WAREHOUSE } from '~/constants/defaultValue';
import moment from 'moment';
import { useUser } from '../user';

const getSelector = (key) => (state) => state.whBill[key];
const getSelectorConfirm = (key) => (state) => state.unConfirmWhBill[key];

const loadingSelector = getSelector('isLoading');
const whBillsSelector = getSelector('whBills');
const getWhBillsFailedSelector = getSelector('getWhBillsFailed');

const isGetDepreciationsLoadingSelector = getSelector('isGetDepreciationsLoading');
const depreciationsSelector = getSelector('depreciations');
const getDepreciationsFailedSelector = getSelector('getDepreciationsFailed');
const pagingDepreciationsSelector = getSelector('pagingDepreciations');


const isGetWhBillLoadingSelector = getSelector('isGetWhBillLoading');
const whBillSelector = getSelector('whBill');
const getWhBillFailedSelector = getSelector('getWhBillFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const isUpdatingBillItemOfBillSelector = getSelector('isUpdatingBillItemOfBill');
const updateBillItemOfBillSuccessSelector = getSelector('updateBillItemOfBillSuccess');
const updateBillItemOfBillFailedSelector = getSelector('updateBillItemOfBillFailed');

const isCustomerCancelLoadingSelector = getSelector('isCustomerCancelLoading');
const customerCancelSuccessSelector = getSelector('customerCancelSuccess');
const customerCancelFailedSelector = getSelector('customerCancelFailed');

const confirmPrepaymentSuccessSelector = getSelector('confirmPrepaymentSuccess');
const confirmPrepaymentFailedSelector = getSelector('confirmPrepaymentFailed');

const cancelWhBillSuccessSelector = getSelector('cancelWhBillSuccess');
const cancelWhBillFailedSelector = getSelector('cancelWhBillFailed');

const confirmWhBillSuccessSelector = getSelectorConfirm('confirmSuccess');
const pagingSelector = getSelector('paging');

export const useWhBillPaging = () => useSelector(pagingSelector);
export const useDepreciationWhBillPaging = () => useSelector(pagingDepreciationsSelector);

export const useWhBillsQueryParams = (options) => {
  const [branchId] = useBranchIdSessionStorage();
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const status = query.get('status');
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const startCompletedTime = query.get('startCompletedTime');
  const endCompletedTime = query.get('endCompletedTime');
  const startConfirmDate = query.get('startConfirmDate');
  const endConfirmDate = query.get('endConfirmDate');
  const whCategory = query.get('whCategory') || null;

  const startUpdateDate = query.get('startUpdateDate');
  const endUpdateDate = query.get('endUpdateDate');

  const managementArea = query.get('managementArea');
  const sortBy = query.get('sortBy') || 'confirmDate'; // default to 'confirmDate'
  // const [page, setPage] = useState(query.get('page') || 1);
  // const onTableChange = ({ current }) => setPage(current);
  
  const allBranch = query.get('allBranch');
  const deleteWhBillSuccess = useSelector(deleteSuccessSelector);
  const confirmWhBillSuccess = useSelector(confirmWhBillSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      status: status || null,
      isDashboard:true,
      // (options &&
      //   options.processing && ['NEW', 'PREPAYMENT_CONFIRMED', 'IN_PROGRESS']),
      startCompletedTime,
      endCompletedTime,
      startDate,
      endDate,
      managementArea,
      withBillItems: true,
      keyword: keyword || undefined,
      sortBy,
      startConfirmDate,
      endConfirmDate,

      startUpdateDate,
      endUpdateDate,

      allBranch,
      whCategory,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    status,
    startCompletedTime,
    endCompletedTime,
    startDate,
    endDate,
    managementArea,
    deleteWhBillSuccess,
    sortBy,
    branchId,
    confirmWhBillSuccess,
    startConfirmDate,
    endConfirmDate,

    startUpdateDate,
    endUpdateDate,

    allBranch,
    whCategory,
  ]);
};

export const useUpdateWhBillParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query?.keyword);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/wh-bill',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useWhBills = (query) => {
  return useFetchByParam({
    action: getWhBills,
    loadingSelector,
    dataSelector: whBillsSelector,
    failedSelector: getWhBillsFailedSelector,
    param: query
  });
};

export const useUpdateWhBill = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật đơn hàng thành công');
  useFailed(updateFailedSelector, 'Cập nhật đơn hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhBill
  });
};

export const useCustomerCancelWhBill = (callback) => {
  useSuccess(customerCancelSuccessSelector, 'Chấm dứt đơn hàng thành công', callback);
  useFailed(customerCancelFailedSelector);

  return useSubmit({
    loadingSelector: isCustomerCancelLoadingSelector,
    action: customerCancelWhBill
  });
};

export const useCustomerCancelWhBillStatus = () => {
  return [customerCancelSuccessSelector, customerCancelFailedSelector];
};

export const useUpdateBillItemOfBill = () => {
  useSuccess(
    updateBillItemOfBillSuccessSelector,
    'Cập nhật dịch vụ của đơn hàng thành công'
  );
  useFailed(
    updateBillItemOfBillFailedSelector,
    'Cập nhật dịch vụ của đơn hàng thất bại'
  );

  return useSubmit({
    loadingSelector: isUpdatingBillItemOfBillSelector,
    action: updateBillItemOfBill
  });
};

export const useUpdateBillItemOfBillStatus = () => {
  return [
    updateBillItemOfBillSuccessSelector,
    updateBillItemOfBillFailedSelector
  ];
};

export const useConfirmPrepaymentWhBill = (callback) => {
  useSuccess(
    confirmPrepaymentSuccessSelector,
    'Cập nhật số tiền tạm ứng thành công',
    callback
  );
  useFailed(
    confirmPrepaymentFailedSelector,
    'Cập nhật số tiền tạm ứng thất bại'
  );

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: confirmPrepaymentWhBill
  });
};

export const useConfirmPrepaymentWhBillStatus = () => {
  return [confirmPrepaymentSuccessSelector, confirmPrepaymentFailedSelector];
};

export const useCancelWhBill = () => {
  useSuccess(cancelWhBillSuccessSelector, 'Hủy đơn hàng thành công');
  useFailed(cancelWhBillFailedSelector, 'Hủy đơn hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: cancelWhBill
  });
};

export const useCancelWhBillStatus = () => {
  return [cancelWhBillSuccessSelector, cancelWhBillFailedSelector];
};

export const useDeleteWhBill = () => {
  useSuccess(deleteSuccessSelector, 'Xoá dịch vụ thành công');
  useFailed(deleteFailedSelector, 'Xoá dịch vụ thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhBill
  });
};

export const useWhBill = (id) => {
  return useFetchByParam({
    action: getWhBill,
    loadingSelector: isGetWhBillLoadingSelector,
    dataSelector: whBillSelector,
    failedSelector: getWhBillFailedSelector,
    param: id
  });
};
export const useGetNewIssueNumber = (whBill) => {
  const [branchId] = useUser();
  const queryGetOneReceiptLast = useMemo(() => ({ page: 1, limit: 1,branchIds : branchId,startDate : "2023-01-01",endDate : "2030-11-30" }), [whBill])
  const [issueNumber, setIsssueNumber] = useState('')
  useEffect(() => {
    const fetch = async () => {
      try {
        const { docs: whReceiptVoucher } = await api.whReceiptVoucher.getWhReceiptVouchers(queryGetOneReceiptLast)
        if (whReceiptVoucher?.length === 0) return setIsssueNumber('PT00001')
        const splitString = get(head(whReceiptVoucher), 'code', '')
        const Numbers = splitString.split("PT")
        if (Numbers[1]) {
          const String = splitString.split(Numbers[1] || '')
          if (head(String)) setIsssueNumber(`${head(String)}${parseInt(Numbers[1]) + 1}`)

        }
      } catch (error) {
        console.log(error);
      }
    }
    fetch()
  }, [whBill])
  return [issueNumber]
}
export const useResetWhBillConfirm = () => {
  useResetState(resetWhBillConfirmState);
};

export const useDepreciationWhBillsQueryParams = () => {
  const [branchId] = useBranchIdSessionStorage();
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const status = query.get('status');

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      status: status || null,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    status,
    branchId,
  ]);
};

export const useUpdateDepreciationWhBillParams = (query) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [keyword, setKeyword] = useState(query?.keyword);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: path,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useDepreciationWhBills = (query) => {
  return useFetchByParam({
    action: getDepreciationWhBills,
    loadingSelector: isGetDepreciationsLoadingSelector,
    dataSelector: depreciationsSelector,
    failedSelector: getDepreciationsFailedSelector,
    param: query
  });
};



export const useConvertTableDepreciations = (data) => {
  // Constructor Data of Detail Table is [{name,depreciation,percentDepreciation}]
  const [dataDeprecation, setDataDeprecation] = useState({
    whBill: [],
    whBillItem: [],
    blocks: [],
    whAppointments: [],
    listProduct: [],
  });

  // Func get Percent depreciation into grandTotal
  const percentDepreciation = useCallback((depreciation) => (depreciation / get(data, 'grandTotal', 0) * 100)?.toFixed(1), []);

  const constructorDetail = useCallback(({ name, depreciation,typeWarehouse }) => ({
    name,
    depreciation,
    percentDepreciation: percentDepreciation(depreciation),
    typeWarehouse
  }),[]);

  // Convert Data For WhBill
  const convertDataWhBill = useCallback(() => {
    return [
      constructorDetail({
        name: "Tổng khấu hao đơn hàng",
        depreciation: get(data, 'totalExtraction', 0),
      })
    ]
  }, []);

  // Convert Data For WhBillItem

  const convertDataWhBillItem = useCallback(() => {
    const columnsWhBillItem = get(data, 'billItems', [])?.map(item =>
      constructorDetail({
        name: get(item, 'code', ''),
        depreciation: get(item, 'totalExtraction', 0),
      })
    )
    return columnsWhBillItem;
  }, []);

  // Convert Data For Block

  const convertDataBlocks = useCallback(() => {
    let blocks = [];
    // Loop BillItem 
    get(data, 'billItems', [])?.forEach(item => {
      // Loop WhAppointment
      let groupByBlock = groupBy(get(item, 'appointments', []), (e) => get(e, 'blockNumber'));
      // Group By Block
      forIn(groupByBlock, (value, key) => {
        // Loop WhAppointment In Block
        const depreciation = value?.reduce((sum, curr) => sum + get(curr, 'totalExtraction', 0), 0);
        blocks.push(
          constructorDetail({
            name: `Block ${key}`,
            depreciation,
          })
        )
      });
    });
    return blocks;

  }, []);

  // Convert Data For WhAppointments

  const convertDataWhAppointments = useCallback(() => {
    let WhAppointments = [];
    get(data, 'billItems', [])?.forEach(item => {
      get(item, 'appointments', [])?.forEach(whAppointment => {
        WhAppointments.push(
          constructorDetail({
            name: get(whAppointment, 'code', ''),
            depreciation: get(whAppointment, 'totalExtraction', 0),
          })
        );
      });
    });
    return WhAppointments;

  }, []);

  const convertDataListProduct = useCallback(() => {
    let listProduct = [];
    get(data, 'billItems', [])?.forEach(item => {
      get(item, 'appointments', [])?.forEach(whAppointment => {
        get(whAppointment, 'listProduct', [])?.forEach(product => {
          if (get(product, 'batchId')) { // CLINIC HAVE BATCHID AND WORLDCCARE Product is not have BatchId
            listProduct.push(
              constructorDetail({
                name: `${get(product, 'ref.product.name', '')} (${get(whAppointment, 'code', '')})`,
                depreciation: get(product, 'ref.product.price', 0),
                typeWarehouse: TYPE_WAREHOUSE.CLINIC
              })
            );
          } else {
            listProduct.push(
              constructorDetail({
                name: `${get(product, 'ref.name', '')} (${get(whAppointment, 'code', '')})`,
                depreciation: get(product, 'ref.valueDepreciation', 0),
                typeWarehouse : TYPE_WAREHOUSE.WORLDHEALTH
              })
            );
          }

        })

      });
    });
    return listProduct;

  }, []);
  useEffect(() => {
    const whBill = convertDataWhBill();
    const whBillItem = convertDataWhBillItem();
    const blocks = convertDataBlocks();
    const whAppointments = convertDataWhAppointments();
    const listProduct = convertDataListProduct();
    setDataDeprecation({
      whBill,
      whBillItem,
      blocks,
      whAppointments,
      listProduct,
    });
  }, []);
  return dataDeprecation
}
