import { usePartnerQueryParams } from '~/hooks';
import Partners from '.';

function Clinic() {
  return (
    <>
      <Partners module="CLINIC" name={'phòng khám'} permission="PARTNER"/>
    </>
  );
}

export default Clinic;
