import { Popconfirm } from 'antd';
import React from 'react'
import WithPermission from './WithPermission';

export default function ColumnActions({ _id, onDelete, onOpenFormUpdate, name = "", policyUpdate, policyDelete, loading }) {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={policyUpdate}>
        <p onClick={() => onOpenFormUpdate(_id)}>Sửa</p>
      </WithPermission>
      <WithPermission permission={policyUpdate}>
        <WithPermission permission={policyDelete}>
          <p>|</p>
        </WithPermission>
      </WithPermission>
      <WithPermission permission={policyDelete}>
        <Popconfirm
          title={"Bạn muốn xoá" + name}
          onConfirm={() => onDelete(_id)}
          okText="Xoá"
          cancelText="Huỷ"
          okButtonProps={{
            loading,
          }}
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );


}
