import produce from 'immer';
import { get } from 'lodash';
import moment from 'moment';
import * as Types from '~/constants/actionTypes';
import { CONFIRM_VOUCHER_STATUS } from '~/constants/confirmVoucherWarehouse';
import { FORMAT_DATE_TIME } from '~/constants/defaultValue';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  changeStatusSuccess: null,
  changeStatusFailed: null,

  confirmSuccess: null,
  confirmFailed: null,

  cancelSuccess: null,
  cancelFailed: null,

  paging: null,

  getListWhPartnerFailed: undefined,
  listWhPartner: [],
  byIdPartner: null,
  getByIdFailed: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_CONFIRM_VOUCHER_WAREHOUSES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_CONFIRM_VOUCHER_WAREHOUSES_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_CONFIRM_VOUCHER_WAREHOUSES_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;
    // PARTNER_VOUCHER
    case Types.GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_REQUEST:
      state.isLoading = true;
      state.getListWhPartnerFailed = null;
      return;

    case Types.GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_SUCCESS:
      state.isLoading = false;
      state.listWhPartner = get(payload,'docs',[]);
      state.paging = getPaging(payload);
      return;

    case Types.GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_FAILED:
      state.isLoading = false;
      state.getListWhPartnerFailed = payload;
      state.listWhPartner = [];
      return;
    //
    case Types.GET_CONFIRM_VOUCHER_WAREHOUSE_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_CONFIRM_VOUCHER_WAREHOUSE_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

      // BY ID PARTNER
    case Types.GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_REQUEST:
      state.isGetByIdLoading = true;
      state.byIdPartner = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_SUCCESS:
      state.isGetByIdLoading = false;
      state.byIdPartner = payload;
      return;

    case Types.GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;
    //
    case Types.CREATE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_CONFIRM_VOUCHER_WAREHOUSE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_CONFIRM_VOUCHER_WAREHOUSE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;
    // CHANGE STATUS
    case Types.CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_REQUEST:
      state.isSubmitLoading = true;
      state.changeStatusSuccess = null;
      state.changeStatusFailed = null;
      return;

    case Types.CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS:
      state.isSubmitLoading = false;
      state.changeStatusSuccess = payload;
      state.byId = payload;
      return;

    case Types.CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_FAILED:
      state.isSubmitLoading = false;
      state.changeStatusFailed = payload;
      return;
    //
    // CONFIRM
    case Types.CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_REQUEST:
      state.isSubmitLoading = true;
      state.confirmSuccess = null;
      state.confirmFailed = null;
      return;

    case Types.CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS:
      state.isSubmitLoading = false;
      state.confirmSuccess = payload;
      state.byIdPartner = { ...state.byIdPartner,
         status: CONFIRM_VOUCHER_STATUS.CONFIRM,
         confirmPartner : true,
         dateConfirm : moment().format(FORMAT_DATE_TIME.YYYYMMDDHHmmss)
         };
      return;

    case Types.CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_FAILED:
      state.isSubmitLoading = false;
      state.confirmFailed = payload;
      return;
    //
    // CANCEL
    case Types.CANCEL_CONFIRM_VOUCHER_WAREHOUSE_REQUEST:
      state.isSubmitLoading = true;
      state.cancelSuccess = null;
      state.cancelFailed = null;
      return;

    case Types.CANCEL_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS:
      state.isSubmitLoading = false;
      state.cancelSuccess = payload;
      state.byIdPartner = { ...state.byIdPartner, 
        status: CONFIRM_VOUCHER_STATUS.CANCEL ,
        confirmPartner : true,
        dateConfirm : moment().format(FORMAT_DATE_TIME.YYYYMMDDHHmmss)
      };
      return;

    case Types.CANCEL_CONFIRM_VOUCHER_WAREHOUSE_FAILED:
      state.isSubmitLoading = false;
      state.cancelFailed = payload;
      return;
    //


    case Types.DELETE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_CONFIRM_VOUCHER_WAREHOUSE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_ACTION_CONFIRM_VOUCHER_WAREHOUSE_STATE:
      state.getListFailed = undefined;
      state.byId = null;
      state.getByIdFailed = null;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      state.createSuccess = null;
      state.createFailed = null;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.RESET_CONFIRM_VOUCHER_WAREHOUSE_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
