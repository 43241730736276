import React, { useState }  from 'react';
import { PATH_APP } from '~/routes/paths';
import {
  useWhSessionsOfDay,
  useDeleteWhSessionOfDay,
  useUpdateWhSessionOfDay,
  useWhSessionOfDayQueryParams,
  useResetWhSessionOfDay,
  useWhSessionOfDayPaging,
} from '~/hooks/whSessionOfDay';
import WhSessionOfDayForm from './Form';
import { Table, Button, Switch, Modal } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { get } from 'lodash';
import { LANGUAGE, MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { EditFilled } from '@ant-design/icons';
import { useSpecialityOptions } from '~/hooks/speciality';
import { useMatchPolicy } from '~/hooks';
import POLICY from '~/constants/policy';
import WithPermission from '~/components/Common/WithPermission';

const WhSessionsOfDay = () => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHTIMEREGULATION);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHTIMEREGULATION);
  const [querySessions, onPagingChange] = useWhSessionOfDayQueryParams();
  const [whSessionsOfDay, isLoading] = useWhSessionsOfDay(querySessions);
  const specialityOptions = useSpecialityOptions();
  // const [keyword, setKeyword] = useState(query.keyword);
  // const [, deleteWhSessionOfDay] = useDeleteWhSessionOfDay();
  const [isSubmitLoading, updateWhSessionOfDay] = useUpdateWhSessionOfDay();
  const [whSessionOfDayId, setWhSessionOfDayId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  useResetWhSessionOfDay();
  const paging = useWhSessionOfDayPaging();

  const ColumnActions = ({ id, status, deleteWhSessionOfDay, updateWhSessionOfDay, isSubmitLoading, ...restProps }) => {

    return (
      <div className="custom-table__actions">
        <WithPermission permission={POLICY.UPDATE_WHTIMEREGULATION}>
          <EditFilled onClick={() => {setIsOpen(true); setWhSessionOfDayId(id); }} style={{color:'#1f89e5'}}/>
        </WithPermission>
        <WithPermission permission={POLICY.UPDATE_WHTIMEREGULATION}>
          <WithPermission permission={POLICY.DELETE_WHTIMEREGULATION}>
            <p style={{marginLeft:'10px'}}>|</p>
          </WithPermission>
        </WithPermission>
        <WithPermission permission={POLICY.DELETE_WHTIMEREGULATION}>
          <Switch
            checked={status === 'ACTIVE'}
            onChange={(value) => updateWhSessionOfDay({ status: value ? 'ACTIVE' : 'INACTIVE', id })}
          />
        </WithPermission>
      </div>
    );
  };
  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '210px',
      render: (text, record, index) => {
        return (querySessions.page - 1) * (querySessions.limit) + index + 1;
      },
    },

    {
      title: 'Buổi',
      dataIndex: 'name',
      key: 'name',
      width: '210px',
      render: (name) => get(name, LANGUAGE.VI),
    },

    {
      title: 'Thời gian',
      key: 'time',
      render: (record) => { 
        return `${get(record, "startTime")} - ${get(record, "endTime")}`}
    
    },
    
    ... (canDelete || canUpdate) ? [{
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        record={
          ...record,
          status:record.status,
          id:record._id,
        }
        return (
          <ColumnActions {...record} updateWhSessionOfDay={updateWhSessionOfDay} />
        );
      }
    }] : []
  ];

  return (
    <div className="page-wraper page-content whSessionOfDay">
      <div className="container-fluid">
        <Breadcrumb title="Quy định thời gian" />
        <div className="page-content__main" style={{display:'block'}}>
            <div className="page-wraper__header">
              <WithPermission permission={POLICY.WRITE_WHTIMEREGULATION}>
                <Button 
                  type="primary" 
                  style={{ float: 'right' }} 
                  onClick={() => {setIsOpen(true); setWhSessionOfDayId(null); }}
                >
                  {' '}
                  Thêm mới
                </Button>
              </WithPermission>
            </div>
        
            {isLoading ? (
              <SkeletonTable
                rowCount={whSessionsOfDay.length}
                columns={columns}
                pagination={paging}
              />
            ) : (
              <Table
                className="wh-sesionOfDay-table-striped-rows"
                rowKey={(rc) => rc._id || rc.id}
                columns={columns}
                dataSource={whSessionsOfDay}
                onChange={onPagingChange}
                size="middle"
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
        </div>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <WhSessionOfDayForm
          specialityOptions={specialityOptions}
          whSessionOfDayId={whSessionOfDayId}
          onClose={() => setIsOpen(false)}
        />
      </Modal>
    </div>  
  );
};

export default WhSessionsOfDay;
