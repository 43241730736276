import moment from "moment";
import { get, has, isObject } from "lodash";

import { INSTANCE_STATUSES, WEEKDAYS } from "~/constants/defaultValue";

const getAvailableTime = (instance) => {
  const availableTime = {};
  get(instance, "availableTime")?.forEach((day, dayIdx) => {
    if (get(day, "status")) {
      availableTime[String(dayIdx)] = day.sessionsOfDay?.map(session => [session.startTime, session.endTime]);
    }
  });
  return availableTime;
}

/**
 * Convert an object received from API to the internal object that can work with Antd Form.
 *
 * @param {*} instance
 * @return {*} 
 */
const fromJSON = instance => {
  return {
    ...instance,
    id: get(instance, "_id") || get(instance, "id"),
    address: [
      {
        address: get(instance, "address"),
        lat: get(instance, "lat") || null,
        lng: get(instance, "lng") || null,
        placeId: get(instance, "placeId"),
      }
    ],
    availableTime: Object.keys(WEEKDAYS).map((key, index) => {
      const sessionsOfDay = get(instance, `workingTime.${key}`);
      if (!sessionsOfDay) return { sessionsOfDay: [], status: false }
      return {
        sessionsOfDay: sessionsOfDay?.map(item => {
          const [startTime, endTime] = item;
          return { startTime, endTime };
        }),
        status: true,
      }
    }),
    employeeGroupId: Array.isArray(get(instance, "employeeGroup"))
      ? get(instance, "employeeGroup.0._id")
      : get(instance, "employeeGroup"),
    employeeGroup: get(instance, "employeeGroup.0"),
    literacy: {
      ...(instance.literacy && { ...instance.literacy }),
      positionId: get(instance, "literacy.position.0._id"),
      position: get(instance, "literacy.position.0"),
      degreeId: get(instance, "literacy.degree.0._id"),
      degree: get(instance, "literacy.degree.0"),
      experienceId: get(instance, "literacy.experience.0._id"),
      experience: get(instance, "literacy.experience.0"),
      certificate: get(instance, "literacy.certificate")
        ? {
          ...get(instance, "literacy.certificate"),
          date: get(instance, "literacy.certificate.date")
            ? moment(instance.literacy.certificate.date)
            : null,
        }
        : {}
    },
    managementArea: get(instance, "managementArea.0"),
    operatingArea: get(instance, "operatingArea"),
    packageLevelId: get(instance, "packageLevel.0._id"),
    packageLevel: get(instance, "packageLevel.0"),
    specialityIds: get(instance, "speciality"),
    services: get(instance, "services")?.map(item => ({ whServiceId: item })),
    status: get(instance, "state") === INSTANCE_STATUSES.ACTIVE ? true : false,
  }
}
/**
 * Convert an internal object to the format of API.
 *
 * @param {*} instance
 * @return {*} 
 */
const toJSON = instance => {
  return {
    ...instance,
    availableTime: getAvailableTime(instance),
    address: get(instance, "address.0.address"),
    lat: get(instance, "address.0.lat"),
    lng: get(instance, "address.0.lng"),
    placeId: get(instance, "address.0.placeId"),
    employeeGroup: get(instance, "employeeGroupId"),
    literacy: {
      ...(instance.literacy && { ...instance.literacy }),
      position: get(instance, "literacy.positionId"),
      degree: get(instance, "literacy.degreeId"),
      experience: get(instance, "literacy.experienceId"),
      certificate: {
        ...instance.literacy.certificate,
        ...(get(instance, "literacy.certificate.date") && { date: instance.literacy.certificate.date.toDate() }),
      }
    },
    managementArea: [
      get(instance, "managementArea")
    ],
    operatingArea: ".",
    packageLevel: get(instance, "packageLevelId"),
    services: get(instance, "services")?.map(item => {
      const whServiceId = get(item, "whServiceId");
      return isObject(whServiceId)
        ? get(whServiceId, "value")
        : whServiceId
    }),
    speciality: get(instance, "specialityIds"),
    ...(has(instance, "status") && {
      state: get(instance, "status")
        ? INSTANCE_STATUSES.ACTIVE
        : INSTANCE_STATUSES.INACTIVE
    }),
  }
}

const toJSONInviteWhPartnerFormFieldsOnly = instance => {
  return {
    ...instance,
    // availableTime: getAvailableTime(instance),
    // address: get(instance, "address.0.address"),
    // lat: get(instance, "address.0.lat"),
    // lng: get(instance, "address.0.lng"),
    // placeId: get(instance, "address.0.placeId"),
    employeeGroup: get(instance, "employeeGroupId"),
    // literacy: {
    //   ...(instance.literacy && { ...instance.literacy }),
    //   position: get(instance, "literacy.positionId"),
    //   degree: get(instance, "literacy.degreeId"),
    //   experience: get(instance, "literacy.experienceId"),
    //   certificate: {
    //     ...instance.literacy.certificate,
    //     date: instance.literacy.certificate.date.toDate(),
    //   }
    // },
    // managementArea: [
    //   get(instance, "managementArea")
    // ],
    // operatingArea: ".",
    // packageLevel: get(instance, "packageLevelId"),
    // services: get(instance, "services")?.map(item => {
    //   const whServiceId = get(item, "whServiceId");
    //   return isObject(whServiceId)
    //     ? get(whServiceId, "value")
    //     : whServiceId
    // }),
    speciality: get(instance, "specialityIds"),
    // ...(has(instance, "status") && {
    //   state: get(instance, "status")
    //     ? INSTANCE_STATUSES.ACTIVE
    //     : INSTANCE_STATUSES.INACTIVE
    // }),
  }
}

const toJSONMiniFormFieldsOnly = instance => {
  return {
    ...instance,
    ...(has(instance, "availableTime") && {
      availableTime: getAvailableTime(instance)
    }),
    ...(has(instance, "employeeGroupId") && {
      employeeGroup: get(instance, "employeeGroupId"),
    }),
    ...(has(instance, "specialityIds") && {
      speciality: get(instance, "specialityIds"),
    }),
  }
}

const toJSONStatusOnly = instance => {
  return {
    ...instance,
    ...(has(instance, "status") && {
      state: get(instance, "status")
        ? INSTANCE_STATUSES.ACTIVE
        : INSTANCE_STATUSES.INACTIVE
    }),
  }
}

export {
  fromJSON,
  toJSON,
  toJSONInviteWhPartnerFormFieldsOnly,
  toJSONMiniFormFieldsOnly,
  toJSONStatusOnly,
}
