import { Button, message, Modal, Space, Upload } from 'antd';
import { get } from 'lodash';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import importExcel from '~/api/importExcel';
import importExcelPotentialPartner from '~/api/importExcelPotentialPartner';
import { BASE_URL } from '~/constants/defaultValue';
import { useWhPartnersImportQueryParams } from '~/hooks';

const ImportForm = ({
  isModalOpen,
  onClose,
  onModule,
  onParamChange,
  dispatchImport,
  title,
}) => {
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState();
  const [disabled, setDisabled] = useState(true);
  const [complete, setComplete] = useState(false);
  const [uploading, setUploading] = useState(false);
  const history = useHistory();

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('files', fileList[0]?.originFileObj);

    if (!fileList[0]) {
      message.destroy();
      message.error('Chưa có file để thực hiện thao tác');
      return;
    }
    setUploading(true);
    // You can use any AJAX library you like
    const response = await importExcelPotentialPartner.postFilePotential(
      formData
    );
    switch (response?.status) {
      case true:
        message.success('Thực hiện thành công');
        // onParamChange({ page: 1 });

        break;
      default:
        setErrors({ message: response.message });
        message.warning('File nhập tồn tại dữ liệu trùng hoặc trống');
        break;
    }
    setTimeout(() => {
      setUploading(false);
      setComplete(true);
    }, 2000);
  };

  const onFileChange = async ({ file, fileList }) => {
    setUploading(true);
    if (file.status === 'removed') {
      setErrors(null);
      setDisabled(true);
      setFileList([]);
      setUploading(false);
      return;
    }
    if (file.status !== 'uploading') {
      if (Array.isArray(fileList)) {
        const newFileList = fileList.map((item) => {
          if (!get(item, 'response')) return item;
          return {
            ...item,
            url: get(item, 'response.url')
          };
        });
        setFileList([...newFileList]);
      }
    } else {
      setFileList([...fileList]);
      setDisabled(false);
    }
    setUploading(false);
  };
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList
  };

  return (
    <Modal
      width={700}
      height={600}
      visible={isModalOpen}
      onCancel={onClose}
      onOk={() => onModule((value) => !value)}
      footer={[
        <Button
          key={'import'}
          type="primary"
          disabled={disabled}
          loading={uploading}
          onClick={handleUpload}
          htmlType="button"
        >
          Import
        </Button>,
        <Button
          key={'done'}
          hidden={!complete}
          type="primary"
          onClick={() => {
            setErrors(null);
            setDisabled(true);
            setFileList([]);
            onModule((value) => !value);
            dispatchImport();
          }}
          htmlType="button"
        >
          Hoàn Thành
        </Button>
      ]}
    >
      <div className="container-fluid">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '2rem'
          }}
        >
          <p style={{ fontSize: '22px', fontWeight: '500' }}>
            {title || 'Nhập đối tác tiềm năng từ file dữ liệu'}
          </p>
          <p>
            &nbsp; (Tải về file mẫu:
            <a
              target="_blank"
              style={{ color: '#1990ff' }}
              href={`${BASE_URL}/api/v1/exportExcelPartnerFile`}
              download
            >
              {' '}
              Excel file
            </a>
            )
          </p>
        </div>
        <Upload
          fileList={fileList}
          style={{ width: '60%' }}
          onChange={onFileChange}
          listType="xlsx"
          maxCount={1}
        >
          <Button key={'select'} type="primary" htmlType="submit">
            Chọn file dữ liệu
          </Button>
        </Upload>
        <i>
          *Hệ thống cho phép nhập tối đa <b>5000 đối tác </b>mỗi lần từ file
        </i>
        {errors?.message && (
          <>
            <Space style={{ color: 'red' }}>{errors?.message} </Space>
            <a href={`${BASE_URL}/api/v1/exportExcelPartner`} download>
              Tải về file
            </a>
          </>
        )}
      </div>
    </Modal>
  );
};
const mapState = () => {};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchImport: (param) =>
      dispatch({ type: 'GET_WH_PARTNERS_IMPORT_REQUEST', payload: param })
  };
};
export default connect(mapState, mapDispatchToProps)(ImportForm);
