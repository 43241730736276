import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhSessionsOfDay,
  createWhSessionOfDay,
  deleteWhSessionOfDay,
  getWhSessionOfDay,
  resetWhSessionOfDayState,
  updateWhSessionOfDay
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const getSelector = key => state => state.whSessionOfDay[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useWhSessionOfDayPaging = () => useSelector(pagingSelector);

export const useWhSessionOfDayQueryParams = (defaultLimit = 10) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || defaultLimit;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || defaultLimit);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteWhServiceSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteWhServiceSuccess]);
};

export const useWhSessionsOfDay = query => {
  return useFetchByParam({
    action: getWhSessionsOfDay,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateWhSessionOfDay = () => {
  const history = useHistory();
  const callback = () => history.push('/wh-session-of-day');
  // const callback = () =>{};

  useSuccess(
    createSuccessSelector,'Thêm mới Buổi thành công',callback
  );
  useFailed(createFailedSelector, 'Thêm mới Buổi thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhSessionOfDay
  });
};

export const useUpdateWhSessionOfDay = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật Buổi thành công');
  useFailed(updateFailedSelector, 'Cập nhật Buổi thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhSessionOfDay
  });
};

export const useDeleteWhSessionOfDay = () => {
  useSuccess(deleteSuccessSelector, 'Xoá Buổi thành công');
  useFailed(deleteFailedSelector, 'Xoá Buổi thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhSessionOfDay
  });
};

export const useWhSessionOfDay = id => {
  // console.log(id,"3333333333")
  return useFetchByParam({
    action: getWhSessionOfDay,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitWhSessionOfDay = whSessionOfDay =>{
    return useMemo(() => {
      if (!whSessionOfDay) {
        return {
          certification: [
            {
              name: '',
              certifiedBy: '',
              certifiedAt: moment()
            }
          ],
          employeeHistory: [
            {
              location: '',
              role: '',
              startTime: moment(),
              endTime: moment()
            }
          ]
        };
      }
  
      const initValues = {
        ...whSessionOfDay,
        startTime: moment(whSessionOfDay.startTime, "HH:mm"),
        endTime: moment(whSessionOfDay.endTime, "HH:mm"),
        certification: Array.isArray(whSessionOfDay?.certification) && whSessionOfDay?.certification.map(cer => ({
          ...cer,
          certifiedAt: moment(cer.certifiedAt)
        })),
      };
  
      return initValues;
  }, [whSessionOfDay]);
};

export const useResetWhSessionOfDay = () => {
  useResetState(resetWhSessionOfDayState);
};
