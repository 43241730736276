import { ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Row, Tabs, Tooltip } from 'antd';
import { concat, get, pick, some } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  checkTypeGroupToGetMessage,
  infoDisabledSaveButton,
  useResetUpdateListWhAppointment,
  useUpdateListProductAppointment
} from '~/hooks';
import TableEquipment from './TableEquipment';
import TableMaterial from './TableMaterial';

import confirm from 'antd/lib/modal/confirm';
import LoadingWhBill from '~/components/WhBill/LoadingWhBill';
import useAssignWarehouseAppointmentStore from '~/storeContext/AssignWarehouseWhAppointment';
// 
//  Data Provider for Clinic warehouse
//  {String} refId Id of Device in warehouse
//  {String} type type of warehouse [WORLDHEALTH , CLINIC]
//  {String} batchId if type is CLINIC will appear this field , Batch of Products
//  {String} groupEquipmentId if type is WORLDHEALTH will appear this field , id of Group equipment
//  {String} productId Dont care , Just field for Clinic to action SALE
//  
export default function AssignEquiment({ }) {
  const {
    dataWhAppointment,
    dataSourceMaterial,
    setDataSourceMaterial,
    dataSourceEquipment,
    whAppointment,
    isLoadingWhAppointment,
    medicalEquipmentsWorldHealth,
    medicalEquipmentsClinic,
    mutateAppointment,
    onCancel,
    whAppointmentId,
    loadingGetMaterial,
    missingMaterialList,
    listMaterialEmptyBatch,
  } = useAssignWarehouseAppointmentStore();
  const ref = useRef()
  const [tabs, setTabs] = useState('1')

  const disabledSave = useMemo(() => infoDisabledSaveButton(dataWhAppointment), [dataWhAppointment]);

  const onChangeTabs = (tabs) => setTabs(tabs);
  const callbackUpdateDevide = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    }
    if (mutateAppointment && typeof mutateAppointment === 'function') {
      mutateAppointment();
    }
  }
  const [loadingSubmit, updateDevideAppointment] = useUpdateListProductAppointment(callbackUpdateDevide);

  useEffect(() => {
    const { haveWorldhealth } = checkTypeGroupToGetMessage({ groupInitsEquipment: dataWhAppointment.groupProductInits })
    if (whAppointment && !haveWorldhealth) {
      setTabs('2');
    }
  }, [dataWhAppointment.groupProductInits, whAppointment])
  useResetUpdateListWhAppointment()
  const handleSave = async () => {
    const submitDataMaterial = dataSourceMaterial?.filter(e => get(e, 'batchId') && get(e, 'refId')).map(material => ({ ...pick(material, ['refId', 'type', 'quantity', 'batchId', 'cost', 'price', 'productId', 'ref']), medicalEquipmentTypeId: get(medicalEquipmentsClinic, '_id') }));
    const submitDataEquipment = dataSourceEquipment?.filter(e => get(e, 'refId')).map(item => ({ ...pick(item, ['refId', 'type', 'quantity','actualQuantity']), medicalEquipmentTypeId: get(medicalEquipmentsWorldHealth, '_id'), groupEquipmentId: get(item, 'groupEquipmentId._id'), }));
    const submitData = { listProduct: concat(submitDataMaterial, submitDataEquipment), whAppointmentId };
    updateDevideAppointment(submitData);
  }
  const showConfirm = () => {
    confirm({
      title: 'Có Nguyên vật liệu chưa chọn lô, sẽ không lưu những nguyên vật liệu này!',
      icon: <ExclamationCircleFilled />,
      content: 'Xác nhận lưu ?',
      onOk() {
        handleSave()
      },
      onCancel() {
      },
    });
  };
  const onBeforeSave = () => {
    const isHaveNoBatch = some(dataSourceMaterial, (e) => !get(e, 'batchId'))
    if (isHaveNoBatch) {
      showConfirm()
    }
    else {
      handleSave()
    }
  };
  return (
    <>
      <LoadingWhBill open={isLoadingWhAppointment || loadingGetMaterial} />
      {true && <Alert type="info" showIcon message={<i>Hệ thống sẽ tự động chọn trước 4 tiếng nếu chưa có công cụ dụng cụ nào được thiết lập</i>} />}
      {listMaterialEmptyBatch?.length ? <Alert style={{marginTop : 10}} banner closable message={`Có Thuốc đã hết lô hoặc lô không còn sử dụng được vui lòng nhập lô  (${listMaterialEmptyBatch?.map(item => get(item,'ref.product.name','')).join(",")})`} /> : null}
      <Tabs onChange={onChangeTabs} activeKey={tabs}>
        <Tabs.TabPane tab="Công cụ dụng cụ" key='1'>
          <TableEquipment
            whAppointmentId={whAppointmentId}
            ref={ref}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Nguyên vật liệu" key='2'>
          <TableMaterial
            dataSource={dataSourceMaterial}
            setDataSource={setDataSourceMaterial}
            whServiceId={get(whAppointment, 'whServiceId')}
            missingMaterialList={missingMaterialList}
          />

        </Tabs.TabPane>
      </Tabs>
      <Divider />
      <Row gutter={16} justify='end'>
        <Col align='end'><Button onClick={onCancel} style={{ marginLeft: ' auto' }}>Huỷ</Button></Col>
        <Col align='end'>
          <Tooltip zIndex={9999} title={disabledSave.msg}>
            <Button
              disabled={disabledSave.status}
              loading={loadingSubmit}
              onClick={onBeforeSave}
              type='primary'
              style={{ marginLeft: ' auto' }}>Lưu</Button>
          </Tooltip>
        </Col>
      </Row>
    </>
  )
}
