import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getActivityReferralsFailed: null,
  getActivityReferralFailed:null,
  ActivityReferrals: [],
  ActivityReferral: null,
  isSubmitLoading : false,
  isGetActivityReferralLoading: false,
  ActivityReferral: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_ACTIVITY_REFERRALS_REQUEST:
      state.isLoading = true;
      state.getActivityReferralsFailed = null;
      return;

    case Types.GET_ACTIVITY_REFERRALS_SUCCESS:
      state.isLoading = false;
      state.ActivityReferrals = payload.docs;
      state.getActivityReferralsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_ACTIVITY_REFERRALS_FAILED:
      state.isLoading = false;
      state.ActivityReferrals = [];
      state.getActivityReferralsFailed = payload;
      return;
    case Types.GET_ACTIVITY_REFERRAL_REQUEST:
      state.isGetActivityReferralLoading = true;
      state.ActivityReferral = null
      state.getActivityReferralFailed = null;
      return;

    case Types.GET_ACTIVITY_REFERRAL_SUCCESS:
      state.isGetActivityReferralLoading = false;
      state.ActivityReferral = payload;
      state.getActivityReferralFailed = null;
      return;

    case Types.GET_ACTIVITY_REFERRAL_FAILED:
      state.isGetActivityReferralLoading = false;
      state.ActivityReferral = null;
      state.getActivityReferralFailed = payload;
      return;
    case Types.CREATE_ACTIVITY_REFERRAL_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_ACTIVITY_REFERRAL_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_ACTIVITY_REFERRAL_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_ACTIVITY_REFERRAL_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_ACTIVITY_REFERRAL_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.ActivityReferral = get(payload,'data');
        return;
  
      case Types.UPDATE_ACTIVITY_REFERRAL_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_ACTIVITY_REFERRAL_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_ACTIVITY_REFERRAL_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_ACTIVITY_REFERRAL_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_ACTIVITY_REFERRAL_STATE:
      return initState;

    default:
      return;
  }
}, initState);
