import React, { useState, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle
} from 'reactstrap';
import CountUp from 'react-countup';
import {
  usePharmacyDaily,
  usePharmacyMonthly,
  usePharmacyQuarterly,
  usePharmacyYearly
} from '~/hooks';
import moment from 'moment';

const MONTHLY = 'monthly';
const WEEKLY = 'weekly';
const YEARLY = 'yearly';
const QUARTERLY = 'quarterly';

const SalesAnalyticsChart = () => {
  const [viewMode, setViewMode] = useState(MONTHLY);

  const [monthly] = usePharmacyMonthly();
  const [weekly] = usePharmacyDaily();
  const [yearly] = usePharmacyYearly();
  const [quarterly] = usePharmacyQuarterly();

  const data = useMemo(() => {
    const source =
      viewMode === MONTHLY
        ? monthly
        : viewMode === YEARLY
        ? yearly
        : viewMode === QUARTERLY
        ? quarterly
        : weekly;

    return JSON.parse(JSON.stringify(source)).reverse();
  }, [viewMode, monthly, weekly, quarterly, yearly]);

  const amountSum = data.reduce((acc, { totalAmount }) => acc + totalAmount, 0);
  const saleSum = data.reduce((acc, { totalSale }) => acc + totalSale, 0);

  const series = [
    {
      name: 'Doanh thu',
      type: 'column',
      data: data.map(({ totalAmount }) => totalAmount)
    },
    {
      name: 'Đơn hàng',
      type: 'line',
      data: data.map(({ totalSale }) => totalSale)
    }
  ];

  const options = {
    chart: {
      type: 'line',
      stacked: !1,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            }
          },
          svg: {
            filename: undefined
          },
          png: {
            filename: undefined
          }
        },
        autoSelected: 'zoom'
      }
    },
    stroke: {
      width: [0, 4]
    },
    plotOptions: {
      bar: {
        columnWidth: '30%'
      }
    },

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: !1,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },

    labels: data.map(({ month, date, year, quarter }) => {
      if (viewMode === WEEKLY) {
        const [day, monthNum, year] = date.split('-');
        return `${monthNum}/${day}/${year}`;
      }
      if (viewMode === MONTHLY) {
        const [monthNum, year] = month.split('-');
        return `${monthNum}/${1}/${year}`;
      }
      if (viewMode === YEARLY) {
        const [yearNum] = year.split('-');
        return `${1}/${1}/${yearNum}`;
      }
      if (viewMode === QUARTERLY) {
        return quarter;
      }
    }),

    markers: {
      size: 0
    },

    xaxis: {
      type: 'dateTime',
      labels: {
        formatter: (val) => {
          return viewMode === MONTHLY
            ? moment(val).format('MM-YYYY')
            : viewMode === YEARLY
            ? moment(val).format('YYYY')
            : viewMode === QUARTERLY
            ? 'Quý ' + val
            : moment(val).format('DD-MM-YYYY');
        }
      }
    },
    yaxis: [
      {
        title: {
          text: 'Doanh Thu'
        },
        labels: {
          formatter: (val) => {
            return String(val).replace(/(.)(?=(\d{3})+$)/g, '$1.');
          }
        }
      },
      {
        opposite: true,
        title: {
          text: 'Đơn Hàng'
        }
      }
    ],

    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y, { seriesIndex }) {
          if (typeof y !== 'undefined') {
            return (
              String(y).replace(/(.)(?=(\d{3})+$)/g, '$1.') +
              `${seriesIndex === 1 ? '' : ' vnđ'}`
            );
          }

          return y;
        }
      }
    },
    grid: {
      borderColor: '#f1f1f1'
    },

    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="float-end">
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="text-reset"
                id="dropdownMenuButton5"
                caret
                href="#"
              >
                <span className="fw-semibold">Dữ liệu theo:</span>{' '}
                <span className="text-muted">
                  {viewMode === MONTHLY
                    ? 'Tháng'
                    : viewMode === YEARLY
                    ? 'Năm'
                    : viewMode === QUARTERLY
                    ? 'Quý'
                    : 'Tuần'}
                  <i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#" onClick={() => setViewMode(YEARLY)}>
                  Năm
                </DropdownItem>
                <DropdownItem href="#" onClick={() => setViewMode(QUARTERLY)}>
                  Quý
                </DropdownItem>
                <DropdownItem href="#" onClick={() => setViewMode(MONTHLY)}>
                  Tháng
                </DropdownItem>
                <DropdownItem href="#" onClick={() => setViewMode(WEEKLY)}>
                  Tuần
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <CardTitle className="mb-4 h4">Phân tích dữ liệu bán hàng</CardTitle>
          <div className="mt-1">
            <ul className="list-inline main-chart mb-0">
              <li className="list-inline-item chart-border-left me-0 border-0">
                <h3 className="text-primary">
                  <span>
                    <CountUp end={amountSum} separator="." suffix=" vnđ" />
                  </span>
                  <span className="text-muted d-inline-block font-size-15 ms-3">
                    Doanh thu
                  </span>
                </h3>
              </li>{' '}
              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">
                    <CountUp end={saleSum} />
                  </span>
                  <span className="text-muted d-inline-block font-size-15 ms-3">
                    Số lượng bán
                  </span>
                </h3>
              </li>{' '}
              {/* <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">
                    <CountUp end={3.6} decimals={1} suffix="%" />
                  </span>
                  <span className="text-muted d-inline-block font-size-15 ms-3">
                    Conversation Ratio
                  </span>
                </h3>
              </li> */}
            </ul>
          </div>

          <div className="mt-3">
            <ReactApexChart
              options={options}
              series={series}
              height="339"
              type="line"
              className="apex-charts"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SalesAnalyticsChart;
