import { Table } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { formatNumberThreeComma } from '~/hooks/utils';
import { useReportCoureTrainingById, useReportWarehouse } from '~/hooks/whReport';
import TableDetail from './TableDetail'

export default function TableWarehouse({id,setTotalDetailAll,date}) {
  const [data,loading] = useReportWarehouse(id,date)
  useEffect(() => {
    setTotalDetailAll('warehouse',get(data,'sumTotalWareHouse',0))
  },[data,id])
  const columns = [
    {
      title: 'STT',
      key: null,
      dataIndex: null,
      width: 50,
      align : 'center',
      render: (item,record,index) => (
        <span>
          {index + 1}
        </span>
      )
    },
    {
      title: 'Tên thiết bị',
      key: 'name',
      dataIndex: 'name',
      align : 'center',
    },
    {
      title: 'Ngày xác nhận',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align : 'center',
      render: (item,record) => (
        <span>
           {moment(item || 0).format('DD/MM/YYYY HH:mm:ss')}
        </span>
      )
    },
    {
      title: 'Giá trị khấu hao',
      key: 'cost',
      dataIndex: 'cost',
      align : 'center',
      render: (item,record) => (
        <span>
         {formatNumberThreeComma(item) || 0}
        </span>
      )
    },
    {
      title: 'Số lượng',
      key: 'quantityUsed',
      dataIndex: 'quantityUsed',
      align : 'center',
      render: (item,record) => (
        <span>
         {item?.length || 0}
        </span>
      )
    },
    {
      title: 'Thành tiền',
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      align : 'center',
      render: (item,record) => formatNumberThreeComma(get(record,'cost',0) * get(record,'quantityUsed.length',0))
    }
  ];
      const summaryFooter = (datacurrent) =>  {
        const sumSummary = (datacurrent || [])?.reduce((sum,cur) => {
          const sumTotal = get(cur,'cost',0) * get(cur,'quantityUsed.length',0);
          return ({...sum ,
            sumCost : get(sum,'sumCost',0) + get(cur,'cost',0),
            sumQuantity : get(sum,'sumQuantity',0) + get(cur,'quantityUsed.length',0),
            sumTotal : get(sum,'sumTotal',0) + sumTotal,
           })
        },
           {
          sumCost : 0,
          sumQuantity : 0,
          sumTotal : 0,
        })
        return <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell align='center' >
          TC
          </Table.Summary.Cell>
          <Table.Summary.Cell align='center' >
          {datacurrent.length || 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell align='center'>
          {datacurrent.length || 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell align='center'>
          {formatNumberThreeComma(get(sumSummary,'sumCost',0))}
          </Table.Summary.Cell>
          <Table.Summary.Cell align='center'>
          {formatNumberThreeComma(get(sumSummary,'sumQuantity',0))}
          </Table.Summary.Cell>
          <Table.Summary.Cell align='center'>
          {formatNumberThreeComma(get(sumSummary,'sumTotal',0))}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
      }
  return (
    <div>
        <TableDetail scrollWidth={1000} loading={loading} pagination={false} dataSource={get(data,'checkProductPartner',[])} columns={columns} title={"Thu nhập sử dụng dụng cụ kho (I)"} footer={summaryFooter}/>
    </div>
  )
}
