import React, { useEffect } from 'react';
import { Row, Col, Container, CardBody, Card } from 'reactstrap';
import { Form } from 'antd';
import { Redirect } from 'react-router-dom';
import logo from '~/assets/images/logo.svg';
import logolight from '~/assets/images/logo.svg';
import { useUser, useProfile, useLogout, useUserPolicy } from '~/hooks';
import { Select, Button } from 'antd';

const Branches = () => {
  const [branchId, token] = useUser();
  const handleLogout = useLogout();
  const [isGetPolicyLoading, getPolicy] = useUserPolicy();

  const onSubmit = (values) => {
    getPolicy(values.branchId);
  };

  const [profile, isLoading] = useProfile(token);

  useEffect(() => {
    document.body.className = 'authentication-bg';

    return function cleanup() {
      document.body.className = '';
    };
  });

  if (branchId) {
    return <Redirect to={'/'} />;
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block"></div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <div className="mb-5 d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="42"
                    className="logo logo-dark"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="42"
                    className="logo logo-light"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Select your branch !</h5>
                    <p className="text-muted">
                      Select branch to continue to worldcare.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <Form
                      onFinish={onSubmit}
                      layout="vertical"
                      hideRequiredMark
                    >
                      <Form.Item
                        name="branchId"
                        rules={[
                          {
                            required: true,
                            message: 'Please select a branch!'
                          }
                        ]}
                        label="Branches"
                      >
                        <Select loading={isLoading}>
                          {profile?.branches?.map(({ name, _id }) => (
                            <Select.Option valuee={_id} key={_id}>
                              {name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <div style={{ paddingTop: 20 }}>
                        <Button
                          type="primary"
                          className="w-100"
                          htmlType="submit"
                          loading={isGetPolicyLoading}
                        >
                          Go To App
                        </Button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          Use another account ?{' '}
                          <span
                            className="fw-medium text-primary"
                            onClick={handleLogout}
                            style={{ cursor: 'pointer' }}
                          >
                            Login
                          </span>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Branches;
