import * as Types from '~/constants/actionTypes';

export const getConfirmVoucherWarehouses = (query) => ({
  type: Types.GET_CONFIRM_VOUCHER_WAREHOUSES_REQUEST,
  payload: query
});

export const getConfirmVoucherWarehousesWhPartner = (query) => ({
  type: Types.GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_REQUEST,
  payload: query
});

export const getConfirmVoucherWarehouseWhPartner = (query) => ({
  type: Types.GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_REQUEST,
  payload: query
});

export const getConfirmVoucherWarehouse = (id) => ({
  type: Types.GET_CONFIRM_VOUCHER_WAREHOUSE_REQUEST,
  payload: id
});

export const createConfirmVoucherWarehouse = (payload) => ({
  type: Types.CREATE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST,
  payload
});

export const updateConfirmVoucherWarehouse = (payload) => ({
  type: Types.UPDATE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST,
  payload
});

export const deleteConfirmVoucherWarehouse = (id) => ({
  type: Types.DELETE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST,
  payload: id
});

export const changeStatusConfirmVoucherWarehouse = (payload) => ({
  type: Types.CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_REQUEST,
  payload
});
export const confirmConfirmVoucherWarehouse = (payload) => ({
  type: Types.CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_REQUEST,
  payload
});
export const cancelConfirmVoucherWarehouse = (payload) => ({
  type: Types.CANCEL_CONFIRM_VOUCHER_WAREHOUSE_REQUEST,
  payload
});

export const resetConfirmVoucherWarehouseState = () => ({
  type: Types.RESET_CONFIRM_VOUCHER_WAREHOUSE_STATE
});
export const resetActionConfirmVoucherWarehouseState = () => ({
  type: Types.RESET_ACTION_CONFIRM_VOUCHER_WAREHOUSE_STATE
});
