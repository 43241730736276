import { Button, Col, Image, Modal, Popconfirm, Row, Switch, Table, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { WithPermission } from '~/components/Common';
import Breadcrumb from '~/components/Common/Breadcrumb';
import POLICIES from '~/constants/policy';
import { useAdvPaging, useAdvQueryParams, useAdvs, useDeleteAdv, useResetAdv, useUpdateAdv, useUpdateAdvParams } from '~/hooks/adv';
import FormAdv from './FormAdv';

const Adv = ({ }) => {
  const [id,setId] = useState();
  const [open,setOpen] = useState(false);
  const [query] = useAdvQueryParams();
  const [Advs, isLoading] = useAdvs(query);
  const [keyword, setKeyword, { onParamChange }] = useUpdateAdvParams(query);

  const onOpen = useCallback((identity) => {
    setOpen(true);
    if(identity) setId(identity);
  },[]);

  const onClose = useCallback(() => {
    setOpen(false);
    setId(null);
  },[]);

  const [, deleteAdv] = useDeleteAdv();
  const [isSubmitLoading, updateAdv] = useUpdateAdv();
  const paging = useAdvPaging();
  useResetAdv();

  const columns = [
    {
      title : 'Hình ảnh quảng cáo',
      dataIndex : 'image',
      key : 'image',
      render(h) {
        return <Image src={h} style={{ width: '100px', height: '100'}} />
      },
    },
    {
      title : 'Icon',
      dataIndex : 'icon',
      key : 'icon',
      render(h) {
        return <Image src={h} style={{ width: '100px', height: '100'}} />
      },
    },
    {
      title : 'Đường dẫn',
      dataIndex : 'link',
      key : 'link',
      render(link) {
        return <a href={link} target='_blank'>{link}</a>
      },
    },
    {
      title : 'Trạng thái',
      dataIndex : 'status',
      key : 'status',
      render(status,rc) {
        return <Switch loading={isSubmitLoading} checked={status === 'ACTIVE'} onChange={(checked) => updateAdv({_id : rc?._id,status : checked ? 'ACTIVE' : 'INACTIVE'})}/>
      },
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      key: '_id',
      width: 100,
      render: (_id) => <Row gutter={8} justify='space-between'>
        <WithPermission permission={POLICIES.UPDATE_BANNER}>
        <>
        <Col>
          <Typography.Link onClick={() => onOpen(_id)}>
            Sửa
          </Typography.Link>
        </Col>
        <Col>
          |
        </Col>
        </>
        </WithPermission>
        <WithPermission permission={POLICIES.DELETE_BANNER}>
        <Col>
          <Popconfirm
            title="Xác nhận xoá"
            onConfirm={() => deleteAdv(_id)}
          >
            <Typography.Link type='danger'>
              Xoá
            </Typography.Link>
          </Popconfirm>
        </Col>
        </WithPermission>
      </Row>
    },
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách quảng cáo" />

        <div className="page-wraper__header">

          <WithPermission permission={POLICIES.WRITE_BANNER}>
            <Button onClick={() => onOpen()} type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </WithPermission>
        </div>
        <Table 
            loading={isLoading}
            className="wh-category-table-striped-rows"
            rowKey={(rc) => rc._id || rc.id}
            columns={columns}
            dataSource={Advs}
            onChange={({ current, pageSize }) => onParamChange({ page: current, limit: pageSize })}
            pagination={{
              ...paging,
              showTotal: (total) => `Tổng cộng: ${total} `,
              showSizeChanger: true
            }}
            size='small'
            />

      </div>
      <Modal
        destroyOnClose
        title={id ? "Cập nhật" : "Tạo mới" + " quảng cáo"} 
        width={800} 
        centered 
        visible={open} 
        onCancel={onClose} 
        footer={null}>
        <FormAdv onCancel={onClose} id={id} onUpdate={updateAdv}/>
      </Modal>
    </div>
  );
};

export default Adv;
