import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useUser, useMatchPolicy, useReload } from '~/hooks';
import PolicyGate from './PolicyGate';

const RedirectTo = ({ location, path }) => {
  return <Redirect to={{ pathname: path, state: { from: location } }} />;
};

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  permission,
  permissionOr,
  ...rest
}) => {
  const [branchId, token] = useUser();
  // const isMatchPolicy = useMatchPolicy(permission);

  const isNotLoginYet = !token;
  const isNotSelectBranchYet = !branchId;
  // const isHasNotPermission = !isMatchPolicy;
  useReload();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected) {
          if (isNotLoginYet) {
            return <RedirectTo path="/login" {...props} />;
          }

          if (isNotSelectBranchYet) {
            return props.location.pathname === '/branches' ? (
              <Component {...props} />
            ) : (
              <RedirectTo path="/branches" {...props} />
            );
          }

          if (permission) {
            return (
              <PolicyGate permission={permission} layout={Layout}>
                <Layout>
                  <Component {...props} />
                </Layout>
              </PolicyGate>
            );
          }
          if(permissionOr){
            return (
              <PolicyGate permissionOr={permissionOr} layout={Layout}>
                <Layout>
                  <Component {...props} />
                </Layout>
              </PolicyGate>
            );
          }
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
};

export default Authmiddleware;
