import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { useLogout, useProfile } from '~/hooks';
//i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// users
import user4 from '../../../assets/images/users/avatar-4.jpg';
import UserForm from '~/components/UserEmployee/UserForm';
import { Modal } from 'antd';
import ModalProfile from './ModalProfile';

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [profile] = useProfile();

  const [isOpenForm, setIsOpenForm] = useState(false);
  const onCloseForm = () => {
    // setUserEmployeeId(null);
    setIsOpenForm(false);
  };

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
    }
  }, [props.success]);

  const handleLogout = useLogout();

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profile?.avatar || user4}
            alt="Header Avatar"
          />
          <span className="d-xl-inline-block ms-1 fw-medium font-size-15">
            {profile?.name || profile?.username}
          </span>{' '}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="#">
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {props.t('Settings')}
            <span className="badge bg-soft-success rounded-pill mt-1 ms-2">
              03
            </span>
          </DropdownItem>
          {!props.isWorkspace && <DropdownItem tag="a" href="#" onClick={() => setIsOpenForm(true)}>
            <i className="uil uil-user-md font-size-18 align-middle me-1 text-muted"></i>
            {props.t('Profile')}
          </DropdownItem>}
          {/* <DropdownItem tag='a' href='auth-lock-screen'>
            <i className='uil uil-lock-alt font-size-18 align-middle me-1 text-muted'></i>
            {props.t('Lock screen')}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <span style={{cursor:'pointer'}} className="dropdown-item " onClick={() => handleLogout()}>
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t('Logout')}</span>
          </span>
        </DropdownMenu>
      </Dropdown>
      {!props.isWorkspace &&
        <Modal
      visible={isOpenForm}
      onCancel={onCloseForm}
      destroyOnClose
      width={1020}
      footer={[]}
      className="form-modal"
      >

       <ModalProfile onCloseForm={onCloseForm} />
      </Modal>
      }
    
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = (state) => {
  const { error, success } = { success: true };
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
