import React, { useEffect, useMemo, useState } from 'react';

import Icon from '@ant-design/icons';
import { Modal, Row, Space, Table } from "antd";
import { get } from 'lodash';

import api from '~/api';

import { notifyHttpError } from '~/utils/helper';

import ServiceSvg from './ServiceSvg';
import WhPartnerBillItemView from './WhPartnerBillItemView';

const WhPartnerBillItemsView = ({
  status,
  statuses,
  whCategories,
  whPackageLevels,
  whPartner,
  whSessionsOfDay,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBillItem, setSelectedBillItem] = useState();

  const [whBillItems, setWhBillItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const pageSize = 10;

  const baseQuery = {
    ...(statuses && { statuses }),
    ...(status && { status }),
    whPartnerId: get(whPartner, "id"),
    populate: true,
    limit: pageSize,
    page: 1,
  }

  const getRecordsLocally = async (query) => {
    setIsLoading(true);
    try {
      const { docs, totalDocs } = await api.whBillItem.getWhBillItems({
        ...query,
      });
      setWhBillItems(docs);
      setTotal(totalDocs);
    } catch (error) {
      notifyHttpError(error);
      setWhBillItems([]);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagingChange = ({ current, pageSize }) => {
    setCurrent(current);
    getRecordsLocally({
      ...baseQuery,
      page: current,
      limit: pageSize,
    })
  }

  useEffect(() => {
    getRecordsLocally({ ...baseQuery });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whPartner]);

  const mergedWhBillItems = useMemo(() => whBillItems.map(item => ({
    ...item,
    snapshotService: {
      ...get(item, "snapshotService"),
      whCategory: whCategories.find(whCat => {
        return get(item, "snapshotService.categoryId") === whCat.id
      })
    },
  })), [whBillItems, whCategories])
  const nameService = (record) =>{
    if (get(record, "snapshotService.detailName.vi"))
      return `${get(record, "snapshotService.code")} - ${get(record, "snapshotService.name.vi")} - ${get(record, "snapshotService.detailName.vi")} : ${get(record, "whBill.billNumber")}`
    else 
      return `${get(record, "snapshotService.code")} - ${get(record, "snapshotService.name.vi")} : ${get(record, "whBill.billNumber")}`
  }
  const columns = [
    {
      title: '',
      key: 'whServiceAndWhBill',
      render: (text, record, index) =>
        {
          
        // return 
        
        <Row align='middle'>
          <Space>
            <Icon component={ServiceSvg} />
            <p className=''>
            {nameService(record)}
            </p>
          </Space>
        </Row>
        },
    },
    {
      title: '',
      key: 'whCategory',
      render: (text, record, index) => get(record, "snapshotService.whCategory.name.vi"),
    },
  ];

  return (
    <>
      <Table
        rowKey={(rc) => rc._id}
        columns={columns}
        dataSource={mergedWhBillItems}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              setSelectedBillItem(record);
              setIsOpen(true);
            },
          };
        }}
        onChange={onPagingChange}
        pagination={{
          total,
          pageSize,
          current,
          showTotal: (total) => `Tổng cộng: ${total} `
        }}
      />
      <Modal
        visible={isOpen}
        width={1200}
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <WhPartnerBillItemView
          whBillItem={selectedBillItem}
          whPackageLevels={whPackageLevels}
          whPartner={whPartner}
          whSessionsOfDay={whSessionsOfDay}
        />
      </Modal>
    </>
  );
}

export default WhPartnerBillItemsView;
