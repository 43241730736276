import { Modal, Table, Typography } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react'
import InfomationProduct from '~/components/WhAppointment/WhAppointmentsByBlockTable/components/InfomationProduct';
import { TYPE_WAREHOUSE } from '~/constants/defaultValue';

export default function ExpandableVoucher({ dataSource,handleByPartner=false }) {
  const [idViewProduct, setIdViewProduct] = useState(null)
  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);
  const handleOpenModalViewProduct = (id) => {
    setIsOpenViewProduct(true);
    setIdViewProduct(id);
  };
  const handleCloseModalViewProduct = () => {
    setIsOpenViewProduct(false);
    setIdViewProduct(null);
  };
  const columns = [
    {
      title: 'Mã định danh SP',
      align: 'center',
      dataIndex: 'product',
      key: 'code',
      render: (item, record) => {
        return get(record, 'typeMedicalDeviceInventory') === TYPE_WAREHOUSE.WORLDHEALTH ?
        handleByPartner 
        ? get(item, 'code', '')
        : <Typography.Link onClick={() => handleOpenModalViewProduct(get(record, 'productId'))}>
        {get(item, 'code', '')}
      </Typography.Link>
        : get(item, 'variantCode', '')
      }
    },
    {
      title: 'Tên sản phẩm',
      align: 'start',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số lượng',
      align: 'start',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Tình trạng',
      align: 'start',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Ghi chú',
      align: 'start',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Loại sản phẩm',
      align: 'start',
      dataIndex: 'typeMedicalDeviceInventory',
      key: 'typeMedicalDeviceInventory',
      render: (item) => item === TYPE_WAREHOUSE.CLINIC ? "Nguyên vật liệu" : "CCDC"
    },
  ];
  return (
    <>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="middle"
        scroll={{ x: '100%' }}
      />
      <Modal
        destroyOnClose
        title={`Thông tin thiết bị`}
        width={700}
        visible={isOpenViewProduct}
        closable={false}
        footer={null}
        onCancel={handleCloseModalViewProduct}>
        <InfomationProduct id={idViewProduct} />
      </Modal>
    </>
  )
}
