import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { memo, useMemo } from 'react'
import { Link } from "react-router-dom"
import { v4 } from 'uuid';
import { STATUS_READ, TYPE_NOTIFICATION_ICON } from '~/constants/myNotification';
import { vietnamMoment } from '~/hooks/utils';


 function NotificationItem({ data, onClickItem,updateStatus }) {
    const { createdAt, status, url, content, type } = data;
    const isRead = useMemo(() => STATUS_READ.read === status, [status]);
    const navigateUrl = () => {
        if (status === STATUS_READ.unread) {
            updateStatus({ id: get(data, '_id'), status: STATUS_READ.read })
        }
        if (onClickItem && typeof onClickItem === 'function') {
            onClickItem()
        }
    }
    const onChangeStatus = (status) => {
        updateStatus({ id: get(data, '_id'), status })
    }
    return (
        <Dropdown 
           overlay={
        <Menu className='notification-item--actionGroup__menu'>
            {isRead ? <Menu.Item key={v4()} onClick={() => onChangeStatus(STATUS_READ.unread)}  icon={<CloseOutlined />}>
                <span>Đánh dấu chưa đọc</span>
            </Menu.Item>
                :
                <Menu.Item  key={v4()} onClick={() => onChangeStatus(STATUS_READ.read)}  icon={<CheckOutlined />}>
                    <span>Đánh dấu đã đọc</span>
                </Menu.Item>
            }


        </Menu>
    } trigger={['contextMenu']}>
                <Link to={url} onClick={navigateUrl} className="text-reset notification-item">
            <div className="d-flex align-items-start">
                <div className="avatar-xs me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                        {TYPE_NOTIFICATION_ICON?.[type] ?? <i className="uil-shopping-basket"></i>}
                    </span>
                </div>
                <div className={`flex-1`}>
                    <h6 className={`mt-0 mb-1 ${isRead ? "text-muted" : ""}`}>
                        {content}
                    </h6>
                    <div className={`font-size-12 ${isRead ? "text-muted" : "text-primary fw-bold"}`}>
                        <p className="mb-0" >
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            {moment(vietnamMoment(createdAt), "YYYYMMDD").fromNow()}
                        </p>
                    </div>
                </div>
                <div className="notification-item--action d-flex align-items-center justify-content-center">
                    {!isRead && <span className="notification-item--action__circleBlue">
                    </span>}
                </div>

            </div>
          
            <hr style={{ margin: 0 }} />
        </Link>
        </Dropdown>
    
    )
}
export default memo(NotificationItem)
// <div onClick={(e) => {
//     e.stopPropagation();
//     e.preventDefault()
// }} className='notification-item--actionGroup'>
//     <Dropdown  className='notification-item--actionGroup__dropdown'
    
//      overlay={
//         <Menu className='notification-item--actionGroup__menu'>
//             {isRead ? <Menu.Item key={v4()} onClick={() => onChangeStatus(STATUS_READ.unread)}  icon={<CloseOutlined />}>
//                 <span>Đánh dấu chưa đọc</span>
//             </Menu.Item>
//                 :
//                 <Menu.Item  key={v4()} onClick={() => onChangeStatus(STATUS_READ.read)}  icon={<CheckOutlined />}>
//                     <span>Đánh dấu đã đọc</span>
//                 </Menu.Item>
//             }


//         </Menu>
//     } trigger={['click']}>
//         <div className='notification-item--actionGroup__btn'>
//             <EllipsisOutlined style={{ fontSize: 18 }} />
//         </div>
//     </Dropdown>
// </div>
