export const GET_GIVE_COUPONS_REQUEST =
  'GET_GIVE_COUPONS_REQUEST';
export const GET_GIVE_COUPONS_SUCCESS =
  'GET_GIVE_COUPONS_SUCCESS';
export const GET_GIVE_COUPONS_FAILED = 'GET_GIVE_COUPONS_FAILED';

export const GET_GIVE_COUPON_REQUEST = 'GET_GIVE_COUPON_REQUEST';
export const GET_GIVE_COUPON_SUCCESS = 'GET_GIVE_COUPON_SUCCESS';
export const GET_GIVE_COUPON_FAILED = 'GET_GIVE_COUPON_FAILED';

export const CREATE_GIVE_COUPON_REQUEST =
  'CREATE_GIVE_COUPON_REQUEST';
export const CREATE_GIVE_COUPON_SUCCESS =
  'CREATE_GIVE_COUPON_SUCCESS';
export const CREATE_GIVE_COUPON_FAILED =
  'CREATE_GIVE_COUPON_FAILED';

export const UPDATE_GIVE_COUPON_REQUEST =
  'UPDATE_GIVE_COUPON_REQUEST';
export const UPDATE_GIVE_COUPON_SUCCESS =
  'UPDATE_GIVE_COUPON_SUCCESS';
export const UPDATE_GIVE_COUPON_FAILED =
  'UPDATE_GIVE_COUPON_FAILED';


export const DELETE_GIVE_COUPON_REQUEST =
  'DELETE_GIVE_COUPON_REQUEST';
export const DELETE_GIVE_COUPON_SUCCESS =
  'DELETE_GIVE_COUPON_SUCCESS';
export const DELETE_GIVE_COUPON_FAILED =
  'DELETE_GIVE_COUPON_FAILED';


export const RESET_GIVE_COUPON_STATE = 'RESET_GIVE_COUPON_STATE';
