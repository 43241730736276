import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getEvaluates,
  createEvaluate,
  deleteEvaluate,
  getEvaluate,
  resetEvaluateState,
  updateEvaluate
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

const getSelector = (key) => (state) => state.evaluate[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useEvaluatePaging = () => useSelector(pagingSelector);

export const useEvaluateQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteEvaluateSuccess = useSelector(deleteSuccessSelector);
  const createEvaluateSuccess = useSelector(createSuccessSelector);
  const updateEvaluateSuccess = useSelector(updateSuccessSelector);
  
  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteEvaluateSuccess, createEvaluateSuccess, updateEvaluateSuccess]);
};

export const useEvaluates = (query) => {
  return useFetchByParam({
    action: getEvaluates,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param : query
  });
};

export const useCreateEvaluate = (callback) => {
  // const history = useHistory();
  // const callback = () => history.push('/evaluate');

  useSuccess(createSuccessSelector, 'Tạo mới đánh giá thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới đánh giá thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createEvaluate
  });
};

export const useUpdateEvaluate = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật đánh giá thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật đánh giá thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateEvaluate
  });
};

export const useDeleteEvaluate = () => {
  useSuccess(deleteSuccessSelector, 'Xoá đánh giá thành công');
  useFailed(deleteFailedSelector, 'Xoá đánh giá thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteEvaluate
  });
};

export const useEvaluate = (id) => {
  return useFetchByParam({
    action: getEvaluate,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitEvaluate = (evaluate) =>
  useMemo(() => {
    if (!evaluate) {
      return {
        image: {}
      };
    }

    return evaluate;
  }, [evaluate]);

export const useResetEvaluate = () => {
  useResetState(resetEvaluateState);
};
