import { adapterStartAndEndDate } from '~/utils/helper';
import requester from './requester';
import { get, omit } from 'lodash';
const workSprint={
  getSprints:(query)=>requester.get(`/board-sprint/${query?.boardId}`,omit(query,['boardId'])),
  createSprint:(body)=>requester.post(`/board-sprint/sprint-create`,body),
  updateSprint:(body)=>requester.put(`/board-sprint/update/${get(body,'_id',get(body,'id'))}`,body),
  getOne:(body)=>requester.get(`/board-sprint-info/${get(body,'_id',get(body,'id',body))}`),
  delete:(body)=>requester.delete(`/board-sprint/delete/${get(body,'_id',get(body,'id',body))}`),
}
export default workSprint
