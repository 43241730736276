import requester from './requester';

const PartnerCancelAppointment = {
  getById: (id) => requester.get(`/appointment-cancel-partner/${id}`),
  create: (config) => requester.post(`/appointment-cancel-partner`, config),
  update:(config) => requester.put(`/appointment-cancel-partner/${config._id}`,config),
  getAll : (query) => requester.get(`/appointment-cancel-partner`,query),
  delete: (id) => requester.delete(`/appointment-cancel-partner/${id}`),
  
};

export default PartnerCancelAppointment;
