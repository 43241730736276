import React, { useEffect, useState } from 'react'
import { Button, Modal, Table } from 'antd';
import WhVoucherStatusTag from './components/WhVoucherStatusTag';
import moment from 'moment';
import { get } from 'lodash';
import { useWhPaymentVoucherCancelPaging, useWhPaymentVoucherQueryParams, useWhPaymentVouchersCancel } from '~/hooks';
import SkeletonTable from '../Utils/SkeletonTable';
import WhPaymentVoucherForm from './Form/WhPaymentVoucherForm';
import ShowTotalMoney from './ShowTotalMoney';

const WhPaymentVouchersCancel = ({setQueryPaymentCancel,useTime, branchIds})=> {

  const [query, onTableChange] = useWhPaymentVoucherQueryParams(null,1, useTime,branchIds);
  const [whPaymentVouchersCancel, isLoading] = useWhPaymentVouchersCancel(query);
  const paging = useWhPaymentVoucherCancelPaging();

  const [whPaymentVoucherId, setWhPaymentVoucherId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [noBillId, setisNoBillId] = useState(null);
  const [typeVoucher, setTypeVoucher] = useState(null);
  const handleClosePaymentVoucherForm = () => {
    setIsOpen(false);
    setWhPaymentVoucherId(null)
    setisNoBillId(null)
    setTypeVoucher(null);
  }

  useEffect(() => {
    setQueryPaymentCancel(query);  
  },[query])
  const columns = [
    {
      title: 'Mã phiếu chi',
      dataIndex: 'code',
      key: 'code',
      render: (text, record, index) => {
        return (
          <Button
            type='link'
            onClick={() => {
              setTypeVoucher(get(record, 'typeVoucher'))
              setWhPaymentVoucherId(get(record, "_id"));
              setIsOpen(true);
              if(get(record,'whBillId')){
              setisNoBillId(false)
              }
              else{
                setisNoBillId(true)
              }
            }}
          >
            {text}
          </Button>
        )
      }
    },
    {
      title: 'Số chứng từ',
      dataIndex: 'issueNumber',
      key: 'issueNumber',
      render: text => text || "-",
    },
    {
      title: 'Mã đơn hàng',
      key: 'billNumber',
      align:'center',
      render: (text, record, index) => get(record, "whBill.billNumber")?get(record, "whBill.billNumber"):'-',
    },
    {
      title: 'Nội dung',
      // dataIndex: 'whBill.billNumber',
      key: 'reason',
      dataIndex : 'reason',
      // render: (text, record, index) => get(record, "whBill[0].billNumber") ? `Đơn hàng ${get(record, "whBill[0].billNumber",'')}` : get(record,"reason",''),
    },
    {
      title: 'ID dịch vụ',
      dataIndex: 'whServiceId',
      key: 'whServiceId',
      // render: (text, record, index) => {
      //   return get(record, "whBillItem.snapshotService") ? (nameService(record)) : "-"
      // }
    },
    {
      title: 'Số tiền',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text, record, index) => text?.toLocaleString(),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record, index) => moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    
    {
      title: 'Ngày duyệt',
      dataIndex: 'dateApproved',
      key: 'dateApproved',
      render: (text, record, index) => text && moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => <WhVoucherStatusTag status={text} />
    },
    {
      title: 'Lựa chọn',
      key: '_id',
      width: 80,
      align: 'center',
      // render: (item, record) =>
      // {
      //   const id = record._id
      //   return <Checkbox
      //   defaultChecked= {arrCheckBox.includes(id)}
      //   onChange={(e)=>onChangeCheckBox(e.target.checked, id)}
      // />}
    },
    
  ];
  const keySum = {
    totalAmount: "totalAmount",
    PaymentCancel: "PaymentCancel"
  };

  return (
    <div>
        <ShowTotalMoney branchIds={branchIds} totalMoneyPage={whPaymentVouchersCancel} keySumPage={keySum.totalAmount} keyPageName={keySum.PaymentCancel} queryProp={query} />
      {isLoading
        ? <SkeletonTable columns={columns} rowCount={5} /> :
        <Table
          columns={columns}
          dataSource={whPaymentVouchersCancel ?? []}
          onChange={onTableChange}
          size='middle'
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total}`,
          }}
        />}
      <Modal
      destroyOnClose
        centered
        footer={null}
        onCancel={handleClosePaymentVoucherForm}
        onOk={handleClosePaymentVoucherForm}
        title={`Phiếu chi (Đơn hàng đã chấm dứt)`}
        visible={isOpen}
        width={1366}
      >
        <WhPaymentVoucherForm
          noBillId={noBillId}
          whPaymentVoucherId={whPaymentVoucherId}
          onClose={handleClosePaymentVoucherForm}
          onCancel={handleClosePaymentVoucherForm}
          isShowBlock
        />
      </Modal>
    </div>
  )
}

export default WhPaymentVouchersCancel
