export const GET_NEWSES_REQUEST = 'GET_NEWSES_REQUEST';
export const GET_NEWSES_SUCCESS = 'GET_NEWSES_SUCCESS';
export const GET_NEWSES_FAILED = 'GET_NEWSES_CATEGORIES_FAILED';

export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILED = 'GET_NEWS_FAILED';

export const CREATE_NEWS_REQUEST = 'CREATE_NEWS_REQUEST';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAILED = 'CREATE_NEWS_FAILED';

export const UPDATE_NEWS_REQUEST = 'UPDATE_NEWS_REQUEST';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_FAILED = 'UPDATE_NEWS_FAILED';

export const UPDATE_NEWS_ID_REQUEST = 'UPDATE_NEWS_ID_REQUEST';
export const UPDATE_NEWS_ID_SUCCESS = 'UPDATE_NEWS_ID_SUCCESS';
export const UPDATE_NEWS_ID_FAILED = 'UPDATE_NEWS_ID_FAILED';

export const DELETE_NEWS_REQUEST = 'DELETE_NEWS_REQUEST';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAILED = 'DELETE_NEWS_FAILED';

export const FETCH_SEARCH_CATEGORY_REQUEST = 'FETCH_SEARCH_CATEGORY_REQUEST';
export const FETCH_SEARCH_CATEGORY_SUCCESS = 'FETCH_SEARCH_CATEGORY_SUCCESS';
export const FETCH_SEARCH_CATEGORY_FAILED = 'FETCH_SEARCH_CATEGORY_FAILED';

export const FETCH_SEARCH_AUTHOR_REQUEST = 'FETCH_SEARCH_AUTHOR_REQUEST';
export const FETCH_SEARCH_AUTHOR_SUCCESS = 'FETCH_SEARCH_AUTHOR_SUCCESS';
export const FETCH_SEARCH_AUTHOR_FAILED = 'FETCH_SEARCH_AUTHOR_FAILED';

export const RESET_NEWS_STATE = 'RESET_NEWS_STATE';
