import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  useBranchIdSessionStorage
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhCustomersManagement,
  createWhCustomerManagement,
  deleteWhCustomerManagement,
  getWhCustomerManagement,
  resetWhCustomerManagementState,
  updateWhCustomerManagement,
  getRefWhCustomersManagement,
  getWhCustomersManagementHistory,
  getWhCustomersManagementManyAddress,
  updateWhCustomerManagementAddress,
  deleteWhCustomerManagementAddress,
  getWhCustomersManagementAddress,
  updateNoteWhCustomerManagement,
  resetNoteWhCustomerManagementState,
  resetPasswordCustomer
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { get, last } from 'lodash';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';

const getSelector = (key) => (state) => state.whCustomerManagement[key];

const loadingRefSelector = getSelector('isLoadingRef');
const listRefSelector = getSelector('listRef');
const getListRefFailedSelector = getSelector('getListRefFailed');
const pagingRefSelector = getSelector('pagingRef');

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const loadingHistorySelector = getSelector('isLoadingHistory');
const listHistorySelector = getSelector('listHistory');
const getListHistoryFailedSelector = getSelector('getListHistoryFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const isSubmitUpdateNoteLoadingSelector = getSelector('isSubmitUpdateNoteLoading');
const updateNoteSuccessSelector = getSelector('updateNoteSuccess');
const updateNoteFailedSelector = getSelector('updateNoteFailed');

//Address
const loadingAddressSelector = getSelector('isLoadingAddress');
const listAddressSelector = getSelector('listAddress');
const getListAddressFailedSelector = getSelector('getListAddressFailed');

const getByIdAddressLoadingSelector = getSelector('isGetByIdAddressLoading');
const getByIdAddressSelector = getSelector('byIdAddress');
const getByIdAddressFailedSelector = getSelector('getByIdAddressFailed');

const isSubmitLoadingAddressSelector = getSelector('isSubmitLoadingAddress');
const updateAddressSuccessSelector = getSelector('updateAddressSuccess');
const updateAddressFailedSelector = getSelector('updateAddressFailed');

const deleteAddressSuccessSelector = getSelector('deleteAddressSuccess');
const deleteAddressFailedSelector = getSelector('deleteAddressFailed');

const resetPasswordSuccessSelector = getSelector('resetPasswordSuccess');
const resetPasswordFailedSelector = getSelector('resetPasswordFailed');

const pagingSelector = getSelector('paging');

export const useWhCustomerManagementPaging = () => useSelector(pagingSelector);

export const useWhCustomerManagement = (id) => {
  return useFetchByParam({
    action: getWhCustomerManagement,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useUpdateCustomerManagementParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    history.push({
      pathname: '/customer-account',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};
export const useUpdateCustomerManagementReferralParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    history.push({
      pathname: '/customer/referral',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useWhCustomersManagementQueryParams = (reFetch) => {
  const [branchId] = useBranchIdSessionStorage();
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const startTime = query.get('startTime');
  const endTime = query.get('endTime');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteWhServiceSuccess = useSelector(deleteSuccessSelector);
  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }
  // useEffect(()=>{
  //   console.log(keyword,"1111")
  // },[keyword])
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined,
      startTime,
      endTime,
      branchId,
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteWhServiceSuccess, startTime, endTime, branchId,reFetch]);
};

export const useWhCustomerManagementHistoryQueryParams = (idCustomer) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const keyword = query.get('keyword');
  // const startTime = query.get('startTime');
  // const endTime = query.get('endTime');
  const id = idCustomer;
  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }
  // useEffect(()=>{
  //   console.log(keyword,"1111")
  // },[keyword])
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      id,
      keyword: keyword || undefined,
      // startTime,
      // endTime
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page,id, limit, keyword]);
};




export const useWhCustomersManagement = (query) => {
  return useFetchByParam({
    action: getWhCustomersManagement,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query,
  });
};

export const useWhCustomersManagementHistory = (query) => {
  return useFetchByParam({
    action: getWhCustomersManagementHistory,
    loadingSelector:loadingHistorySelector,
    dataSelector: listHistorySelector,
    failedSelector: getListHistoryFailedSelector,
    param: query,
  });
};


export const useUpdateWhCustomerManagement = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật khách hàng thành công');
  useFailed(updateFailedSelector, 'Cập nhật khách hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhCustomerManagement
  });
};

export const useUpdateNoteWhCustomerManagement = (callback) => {
  useSuccess(updateNoteSuccessSelector, 'Cập nhật ghi chú khách hàng thành công', callback);
  useFailed(updateNoteFailedSelector, 'Cập nhật ghi chú khách hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitUpdateNoteLoadingSelector,
    action: updateNoteWhCustomerManagement
  });
};

export const useDeleteWhCustomerManagement = () => {
  useSuccess(deleteSuccessSelector, 'Xoá khách hàng thành công');
  useFailed(deleteFailedSelector, 'Xoá khách hàng thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhCustomerManagement
  });
};

export const useInitWhCustomerManagement = (whCustomerManagement) => {
  return useMemo(() => {
    if (!whCustomerManagement) {
      return {
        image: {}
      };
    }

    const initValues = {
      ...fromJSON(whCustomerManagement)
    };
    return initValues;
  }, [whCustomerManagement]);
};

export const useResetWhCustomerManagement = () => {
  useResetState(resetWhCustomerManagementState);
};

export const useResetNoteWhCustomerManagement = () => {
  useResetState(resetNoteWhCustomerManagementState);
};

export const useRefWhCustomerManagementQueryParams = (id,typeRef) => {
  const query = useQueryParams();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword,setKeyword] = useState(null)
  const onTableChange = ({ current }) => setPage(current);

  return useMemo(() => {
    const queryParams = {
      limit,
      page,
      id,
      typeRef,
      keyword,
    };

    return [queryParams, onTableChange,setKeyword];

    //eslint-disable-next-line
  }, [
    limit,
    page,
    id,
    typeRef,
    keyword,
  ]);
};

export const useRefWhCustomerManagement = query => {
  return useFetchByParam({
    action: getRefWhCustomersManagement,
    loadingSelector:loadingRefSelector,
    dataSelector: listRefSelector,
    failedSelector: getListRefFailedSelector,
    param: query
  });
};

export const useRefWhCustomerManagementPaging = () => useSelector(pagingRefSelector);

export const getRankingByPoint = (user,listRanking,localField = 'ranking') => {
  if(!get(user,localField)) return null
      const point = get(user,`${localField}.point`)
      const index = listRanking.findIndex(d => get(d,'startPoint') <= point && get(d,'endPoint') >= point)
      if(index === -1){
        let listLower = listRanking?.filter(e => get(e,'endPoint') <= point)
        const myRank = last(listLower)
        return {...myRank, point}
      }else{
        return {...listRanking[index],point}
      }
}

export const useMergeWhCustomerManagementWithRanking = (listCustomer,listRanking) =>{
  const [newListCustomerMerge,setNewListCustomerMerge] = useState([])
  useEffect(() => {
    const newListCustomer = listCustomer?.map(customer => {
      return {...customer,ranking :getRankingByPoint(customer,listRanking)}
    })
    setNewListCustomerMerge(newListCustomer)
  },[listCustomer,listRanking])
  return newListCustomerMerge
}

export const useWhCustomersManagementManyAddress = (query) => {
  return useFetchByParam({
    action: getWhCustomersManagementManyAddress,
    loadingSelector: loadingAddressSelector,
    dataSelector: listAddressSelector,
    failedSelector: getListAddressFailedSelector,
    param: query,
  });
};

export const useWhCustomerManagementAddress = (query) => {
  return useFetchByParam({
    action: getWhCustomersManagementAddress,
    loadingSelector: getByIdAddressLoadingSelector,
    dataSelector: getByIdAddressSelector,
    failedSelector: getByIdAddressFailedSelector,
    param: query,
  });
};

export const useUpdateWhCustomerManagementAddress = () => {
  useSuccess(updateAddressSuccessSelector, 'Cập nhật địa chỉ khách hàng thành công');
  useFailed(updateAddressFailedSelector,);

  return useSubmit({
    loadingSelector: isSubmitLoadingAddressSelector,
    action: updateWhCustomerManagementAddress
  });
};

export const useDeleteWhCustomerManagementAddress = () => {
  useSuccess(deleteAddressSuccessSelector, 'Xoá địa chỉ khách hàng thành công');
  useFailed(deleteAddressFailedSelector,);

  return useSubmit({
    loadingSelector: isSubmitLoadingAddressSelector,
    action: deleteWhCustomerManagementAddress
  });
};

export const useResetPasswordCustomer = () => {
  useSuccess(resetPasswordSuccessSelector, 'Thay đổi mật khẩu thành công');
  useFailed(resetPasswordFailedSelector,);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: resetPasswordCustomer
  });
};
