import React, { useEffect, useCallback} from 'react';
import { Form, Input, Row, Button, Skeleton, TimePicker } from 'antd';
import { get } from 'lodash';
import { LANGUAGE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import {
  useCreateWhSessionOfDay,
  useWhSessionOfDay,
  useInitWhSessionOfDay,
  useResetWhSessionOfDay,
  useUpdateWhSessionOfDay,
} from '~/hooks/whSessionOfDay';
import moment from 'moment';

const FormItem = Form.Item;
const format = 'HH:mm';

const WhSessionOfDayForm = ({ specialityOptions, whSessionOfDayId, onClose}) => {
  const [form] = Form.useForm();
  // const [query] = useWhSessionOfDayQueryParams();
  const [isSubmitLoading, handleCreate] = useCreateWhSessionOfDay();
  const [, handleUpdate] = useUpdateWhSessionOfDay();
  const [whSessionOfDay, isLoading] = useWhSessionOfDay(whSessionOfDayId);
  const initWhSessionOfDay = useInitWhSessionOfDay(whSessionOfDay);
  useResetWhSessionOfDay();
  useEffect(() => {
    if (!whSessionOfDayId) {
      form.resetFields();
    } else {
      form.setFieldsValue(initWhSessionOfDay);
    }
  }, [initWhSessionOfDay, whSessionOfDayId,form])

  const onFinish = useCallback(
    (values) => {
      if (whSessionOfDayId) {
        handleUpdate({
          ...values,
          startTime: moment(get(values, "startTime")).format("HH:mm"),
          endTime: moment(get(values, "endTime")).format("HH:mm"),
          id: whSessionOfDayId,
        });
      } else {
        handleCreate({
          ...values,
          startTime: moment(get(values, "startTime")).format("HH:mm"),
          endTime: moment(get(values, "endTime")).format("HH:mm"),
        });
      }
      onClose();
    },
    [handleCreate, handleUpdate, whSessionOfDayId]
  );

  const renderInput = (InputComponent) =>
  isLoading ? <Skeleton.Input active /> : InputComponent;
  
  return (
    <div className="wh-session-of-day page-wraper hospital-form-page-content">
     <h4 style={{margin: '20px 0 40px 20px'}}>{whSessionOfDayId ? ' Cập nhật' : 'Thêm mới'} buổi</h4>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          // initialValues={initWhSessionOfDay}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <FormItem label="Buổi (vi)" name={["name", LANGUAGE.VI]} rules={[{ required: true }]}>
          {renderInput(<Input />)}
          </FormItem>
          <FormItem label="Buổi (en)" name={["name", LANGUAGE.EN]} rules={[{ required: true }]}>
            {renderInput(<Input />)}
          </FormItem>
          
          {/* <Col sm={22} md={22}> */}
              <FormItem
                name={"startTime"}
                label="Bắt đầu"
                rules={[
                  // rule if using <Input />
                  // {
                  //     required: true,
                  //     pattern: new RegExp("^([0-1][0-9]|2[0-3]):[0-5][0-9]$"),
                  //     message: 'Vui lòng nhập thời gian bắt đầu. Ví dụ: 07:15',
                  // },
                ]}
              >
                {/* <Input /> */}
                <TimePicker format="HH:mm" />
              </FormItem>

              <FormItem
                name={"endTime"}
                label="Kết thúc"
                dependencies={['startTime']}
                rules={[
                  // rule if using <Input />
                  // {
                  //     required: true,
                  //     pattern: new RegExp("^([0-1][0-9]|2[0-3]):[0-5][0-9]$"),
                  //     message: 'Vui lòng nhập thời gian kết thúc. Ví dụ: 08:45',
                  // },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        value &&
                        value > getFieldValue('startTime')
                        // && moment.duration(value.diff(getFieldValue('startTime'))).asMinutes() >= 30
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Thời gian kết thúc phải lớn hơn thời gian bắt đầu'));
                      // return Promise.reject(new Error('Thời gian kết thúc phải lớn hơn thời gian bắt đầu ít nhất 120 phút'));
                    }
                  })
                ]}
              >
                {/* <Input /> */}
                <TimePicker format="HH:mm" />
              </FormItem>
            

            
          <Row className="form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.whSessionOfDay.root}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {whSessionOfDayId ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default WhSessionOfDayForm;
