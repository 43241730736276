import { Button, Form, Input } from 'antd'
import React, { useEffect } from 'react'
import { useConfigWhPartnerRegisterTypeForm, useCreateConfigWhPartnerRegisterTypeForm, useResetConfigWhPartnerRegisterTypeFormAction, useUpdateConfigWhPartnerRegisterTypeForm } from '~/hooks/configWhPartnerRegisterTypeForm';
import { renderLoading } from '~/utils/helper';

export default function FormRegisterWhPartnerTypeForm({id,onCancel}) {
    const [form] = Form.useForm();
    const [isSubmitLoading, update] = useUpdateConfigWhPartnerRegisterTypeForm(onCancel);
    const [, create] = useCreateConfigWhPartnerRegisterTypeForm(onCancel);
    const [initData,loading] = useConfigWhPartnerRegisterTypeForm(id);
    const onFinish = (values) => {
        if(!id){
            create({...values})
        }else{
            update({_id : id,...values})
        }
    };

    useEffect(() => {
        if(id){
            form.setFieldsValue({...initData})
        }else{
            form.resetFields()
        }
    },[id,initData]);
    useResetConfigWhPartnerRegisterTypeFormAction();
  return (
    <Form form={form} onFinish={onFinish}>
        <Form.Item rules={[{
            required: true,
            message : "Vui lòng nhập"
        }]} name={'name'} label="Tên">
        {renderLoading(<Input/>,loading)}
        </Form.Item>
        <Button loading={isSubmitLoading} type='primary' htmlType='submit'>
            {id ? "Cập nhật" : "Tạo mới"}
        </Button>
    </Form>
  )
}
