

import React from 'react';

import { Col, Row, Skeleton, Space } from "antd";
import { get, head } from "lodash";

const WhBillItemCategoryInfo = ({
  whBillItem,
  whCategory,
  isLoading
}) => {
  const loading = isLoading || !whCategory
  return (
    <Row justify='end' align='middle'>
      <Col flex={1}>
        <h2 className=''>
          {loading ? <Skeleton.Input style={{width : '200px'}} active/> :`${get(whBillItem, "snapshotService.whCategory.name.vi") || get(head(whCategory), "name.vi")}`}
        </h2>
      </Col>
    </Row>
  )
}

export default WhBillItemCategoryInfo;
