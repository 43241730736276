import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getServiceBookingInstructions({ payload: query }) {
  try {
    const data = yield call(Api.systemConfiguration.getAll, query);
    yield put({ type: Types.GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_FAILED,
      payload: error.message
    });
  }
}

function* getSystemConfiguration() {
  try {
    const systemConfiguration = yield call(Api.systemConfiguration.getAll);
    yield put({ type: Types.GET_SYSTEM_CONFIGURATION_SUCCESS, payload: systemConfiguration });
  } catch (error) {
    yield put({ type: Types.GET_SYSTEM_CONFIGURATION_FAILED, payload: error.message });
  }
}

function* createServiceBookingInstruction(action) {
  try {
    console.log("wow...");
    const data = yield call(Api.systemConfiguration.create, action.payload);
    yield put({ type: Types.CREATE_SYSTEM_CONFIGURATION_SUCCESS, payload: data });
  } catch (error) {
    console.log('error create...', error)
    yield put({
      type: Types.CREATE_SYSTEM_CONFIGURATION_FAILED,
      payload: error.message
    });
  }
}

function* updateSystemConfiguration(action) {
  try {
    console.log("huhu...");
    const data = yield call(Api.systemConfiguration.update, action.payload);
    yield put({ type: Types.UPDATE_SYSTEM_CONFIGURATION_SUCCESS, payload: data });
  } catch (error) {
    console.log('error', error)
    yield put({
      type: Types.UPDATE_SYSTEM_CONFIGURATION_FAILED,
      payload: error.message
    });
  }
}

function* deleteSystemConfiguration({ payload }) {
  try {
    yield call(Api.systemConfiguration.delete, payload);
    yield put({ type: Types.DELETE_SYSTEM_CONFIGURATION_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_SYSTEM_CONFIGURATION_FAILED,
      payload: error.message
    });
  }
}

export default function* serviceBookingInstruction() {
  yield takeLatest(Types.GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_REQUEST, getServiceBookingInstructions);
  yield takeLatest(Types.GET_SYSTEM_CONFIGURATION_REQUEST, getSystemConfiguration);
  yield takeLatest(Types.DELETE_SYSTEM_CONFIGURATION_REQUEST, deleteSystemConfiguration);
  yield takeLatest(Types.CREATE_SYSTEM_CONFIGURATION_REQUEST, createServiceBookingInstruction);
  yield takeLatest(Types.UPDATE_SYSTEM_CONFIGURATION_REQUEST, updateSystemConfiguration);
}
