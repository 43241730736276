import { Select } from 'antd'
import { get } from 'lodash'
import React, { useMemo } from 'react'
import api from '~/api'
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue'
import { useFetchState } from '~/hooks/utils'

export default function SelectTypeForm(props) {
    const query = useMemo(() => ({page : 1, limit : MAX_PAGINATION_LIMIT}),[]);
    const [data,loading] = useFetchState({api : api.configWhPartnerRegisterTypeForm.getAll,query});
    const options = useMemo(() => data?.map((item) => ({
        label : get(item,'name'),
        value : get(item,'_id'),
    })),[data])
  return (
    <Select options={options} {...props}/>
  )
}
