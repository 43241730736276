import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { get, pick } from 'lodash';
import { useEffect, useState } from 'react';
import { usePositionJob, useUpdatePositionJob } from '~/hooks/positionJob';
import FlexCenter from '../Common/FlexCenter';
import TableSource from './TableSource';
import TableTarget from './TableTarget';
const adapterData = (item) => pick(item, ['_id', 'question'])
export default function PickQuestion({ id, onCancel }) {
    const [data, setData] = useState([]);
    const [, update] = useUpdatePositionJob(onCancel);
    const [positionJob, loading] = usePositionJob(id);

    // Pick Data
    const onPick = (newData) => {
        setData([...data, adapterData(newData)]);
    }

    // Remove Data
    const onRemove = (idRemove) => {
        setData(data?.filter((item) => item?._id !== idRemove));
    }

    // Update PositionJob
    const onUpdate = () => {
        update({_id : id,jobQuestionIds : data?.map((item) => item?._id)})
    }

    // Init Data
    useEffect(() => {
        const initData = get(positionJob,'jobQuestionIds',[])?.map((item) =>  ({...adapterData(item)}));
        setData(initData)
    }, [positionJob]);
    
    return (
        <div>
            <Row justify='end'>
                <Button loading={loading} onClick={onUpdate} size='large' shape='round' className='mb-2' type='primary'><FlexCenter.Row><SaveOutlined /> Lưu</FlexCenter.Row></Button>
            </Row>
            <Row justify='space-between'>
                <Col span={11}>
                    <TableSource data={data} onPick={onPick} />
                </Col>
                <Col span={11}>
                    <TableTarget data={data} onRemove={onRemove} />
                </Col>
            </Row>
        </div>
    )
}
