import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Radio,
  Select,
  InputNumber,
  Button,
  Skeleton
} from 'antd';
import UploadImage from '~/components/Utils/UploadImage';
import PicturesWall from '~/components/Utils/PicturesWall';
import { LANGUAGE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import { useCities, useDistricts, useWards } from '~/hooks/geo';
import {
  useCreateHospital,
  useHospital,
  useInitHospital,
  useResetHopital,
  useUpdateHospital
} from '~/hooks/hospital';
import './index.scss';
import { useSpecialities } from '~/hooks/speciality';
import { removeAccents, useFormItemMargin } from '~/hooks/utils';
import { useParams } from 'react-router-dom';
import Services from './Services';
import WorkingHours from './WorkingHours';
import Breadcrumb from '~/components/Common/Breadcrumb';

const filterOption = (input, option) =>
  removeAccents(option.children.toLowerCase()).indexOf(input.toLowerCase()) >=
  0;

const { Option } = Select;

const FormItem = Form.Item;

const HospitalForm = () => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState(LANGUAGE.VI);
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState();

  const [selectedCityCode, setSelectedCityCode] = useState();
  const [selectedDistrictCode, setSelectedDistrictCode] = useState();

  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(selectedCityCode);
  const [wards, isWardsLoading] = useWards(selectedDistrictCode);
  const [specialities, isSpecialitiesLoading] = useSpecialities();

  const [isSubmitLoading, handleCreate] = useCreateHospital();
  const [, handleUpdate] = useUpdateHospital();

  const { id } = useParams();
  const [hospital, isGetHospitalLoading] = useHospital(id);
  const initHospital = useInitHospital(hospital);

  const margin = useFormItemMargin();

  useEffect(() => {
    form.resetFields();
    const { logo, photos, address } = initHospital;

    if (logo) {
      setLogo(logo);
    }

    if (photos && photos.length) {
      setImages(photos);
    }

    if (address) {
      setSelectedCityCode(address.city);
      setSelectedDistrictCode(address.district);
    }
  }, [initHospital, form]);

  useResetHopital();

  const onFinish = (values) => {
    const hospital = {
      ...values,
      workingHours: values.workingHours.map((hour) => ({
        ...hour,
        startTime: hour.startTime.format('HH:mm'),
        endTime: hour.endTime.format('HH:mm')
      })),
      logo,
      photos: images.filter((img) => !!img)
    };

    if (id) {
      handleUpdate({ ...hospital, id });
    } else {
      handleCreate(hospital);
    }
  };

  const onValuesChange = ({ address }) => {
    const shouldResetDistrictAndWards = address && address.city;
    if (shouldResetDistrictAndWards) {
      form.setFieldsValue({
        address: {
          district: null,
          ward: null
        }
      });
    }

    const shouldResetWards = address && address.district;
    if (shouldResetWards) {
      form.setFieldsValue({
        address: {
          ward: null
        }
      });
    }
  };

  const isLoading = isGetHospitalLoading;

  return (
    <div className="hospital-form page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} phòng khám`}
          routes={[
            { path: PATH_APP.hospital.root, title: 'Danh sách phòng khám' }
          ]}
        />

        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          scrollToFirstError
          requiredMark={false}
          initialValues={initHospital}
          validateTrigger="onChange"
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
        >
          <Row justify="end">
            <Radio.Group
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <Radio.Button
                className="hospital-form__select-langue-btn"
                value={LANGUAGE.VI}
              >
                VI
              </Radio.Button>
              <Radio.Button
                className="hospital-form__select-langue-btn"
                value={LANGUAGE.EN}
              >
                EN
              </Radio.Button>
            </Radio.Group>
          </Row>

          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="hospital-form__logo-row"
          >
            <Col span={12}>
              <FormItem
                label="Tên phòng khám"
                name={['name', LANGUAGE.VI]}
                className={`${language === LANGUAGE.EN && 'hiden'}`}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
              <FormItem
                label="Tên phòng khám"
                name={['name', LANGUAGE.EN]}
                className={`${language === LANGUAGE.VI && 'hiden'}`}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>

              <FormItem label="Đường dẫn" name="slug">
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12} className="hospital-form__upload-logo">
              <UploadImage onChange={setLogo} imgUrl={logo} />
            </Col>
          </Row>

          <Row style={{ marginLeft: margin }}>
            <FormItem
              label="Mô tả ngắn"
              name={['description', LANGUAGE.VI]}
              className={`hospital-form__description ${
                language === LANGUAGE.EN && 'hiden'
              }`}
              labelCol={{ sm: 24, md: 24, lg: 3 }}
              wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Input.TextArea rows={4} />
              )}
            </FormItem>

            <FormItem
              label="Mô tả ngắn"
              name={['description', LANGUAGE.EN]}
              className={`hospital-form__description ${
                language === LANGUAGE.VI && 'hiden'
              }`}
              labelCol={{ sm: 24, md: 24, lg: 3 }}
              wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Input.TextArea rows={4} />
              )}
            </FormItem>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Thành Phố/Tỉnh"
                name={['address', 'city']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    onChange={setSelectedCityCode}
                    disabled={isCitiesLoading}
                    loading={isCitiesLoading}
                    showSearch
                    autoComplete="off"
                    filterOption={filterOption}
                  >
                    {cities.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Quận/Huyện"
                name={['address', 'district']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Quận/Huyện!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isDistrictsLoading}
                    disabled={!form.getFieldValue(['address', 'city'])}
                    onChange={setSelectedDistrictCode}
                    showSearch
                    autoComplete="off"
                    filterOption={filterOption}
                  >
                    {districts.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Phường/Xã"
                name={['address', 'ward']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Phường/Xã!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isWardsLoading}
                    disabled={!form.getFieldValue(['address', 'district'])}
                    showSearch
                    autoComplete="off"
                    filterOption={filterOption}
                  >
                    {wards.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Đường phố" name={['address', 'street']}>
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Email bạn nhập không đúng định dạng'
                  }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Số điện thoại"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,11}$/),
                    message: 'Xin vui lòng nhập đúng số điện thoại!'
                  }
                ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48}>
            <Col span={12}>
              <FormItem
                label="Chuyên khoa"
                name="speciality"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn chuyên khoa!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    mode="multiple"
                    placeholder=""
                    style={{ width: '100%' }}
                    loading={isSpecialitiesLoading}
                    disabled={isSpecialitiesLoading}
                  >
                    {specialities.map(({ name, id }) => (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Thời gian khám bệnh (phút)"
                name={['hospitalSettings', 'slotTime']}
                labelCol={{ sm: 24, md: 24, lg: 12, xl: 9 }}
                wrapperCol={{ sm: 24, md: 24, lg: 12, xl: 15 }}
              >
                {isLoading ? <Skeleton.Input active /> : <InputNumber />}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Số lượng bệnh nhân tối đa"
                name={['hospitalSettings', 'capacityPerSlot']}
                labelCol={{ sm: 24, md: 24, lg: 12, xl: 9 }}
                wrapperCol={{ sm: 24, md: 24, lg: 12, xl: 15 }}
              >
                {isLoading ? <Skeleton.Input active /> : <InputNumber />}
              </FormItem>
            </Col>
          </Row>

          <Services />

          <WorkingHours form={form} />

          <Row className="hospital-form__images">
            <h3 className="hospital-form__images-title">Hình ảnh</h3>
            <PicturesWall
              onImagesChange={setImages}
              initFilelist={initHospital.photos}
            />
          </Row>

          <Row className="hospital-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.hospital.root}>
                <Button>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default HospitalForm;
