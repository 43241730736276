import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Table,
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  Typography,
  Popconfirm,
  AutoComplete
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import toastr from 'toastr';
import api from '~/api';
import { useProfile } from '~/hooks';
import { get, head, toLower } from 'lodash';

/**
 * This is the component that handles retrieving data from the PMS warehouse (https://clinic-dashboard.congtyso.com)
 * @async
 * @param {String} branchId That is branchId of user login
 * @param {Array} groupInitMaterial That is an array containing objects containing the type of material
 * @returns {
 * String: keyword
 * Function: setKeyword
 * Boolean: loading
 * Array: data
 * Function: onSearch
 * }
 */

export const useGetMaterialInService = (branchId, category) => {
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  /**
   * Perform a search for materials.
   *
   * @param {String} defaultKeyword - The default search keyword.
   */
  const onSearch = async (defaultKeyword) => {
    try {
      setLoading(true)
      const listMaterials = await api.wareHouse.getMaterials({ keyword: defaultKeyword ?? keyword, limit: 10, branchId, category: category })
      setData(listMaterials)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toastr.error(get(error, 'message', 'Lỗi khi lấy dữ liệu'))
    }
  }
  useEffect(() => {
    onSearch(0)
  }, [category]);
  return { keyword, setKeyword, loading, data, onSearch }
};
/**
 * This is the component that displays the table rows when updating
 * @returns {component}
 */
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  handleSave,
  selectOptions,
  children,
  disabled,
  language,
  required,
  onSearch,
  setKeyword,
  mapData,
  setIsDisable,
  isDisable,
  form,
  setCategoryName,
  handleSelectAutoComplete,
  ...restProps
}) => {
  const keyRule = {
    select: 'chọn',
    text: 'nhập',
    number: 'nhập',
    autocomplete: 'nhập'
  };
  let inputNode = <Input />;
  switch (inputType) {
    case 'number':
      inputNode = (
        <InputNumber
          disabled={disabled}
          min="1"
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          defaultValue={dataIndex === 'amount' && 1}
        />
      );
      break;
    case 'select':
      inputNode = (
        <Select
          disabled={disabled}
          options={selectOptions?.map((option) => {
            return {
              label: option.name[language],
              value: option._id
            };
          })}
          onSelect={(value) => {
            if (dataIndex === 'category') {
              setIsDisable(false);
              form.setFieldsValue({
                name: null
              });
              setCategoryName(value);
            };
          }}
          // onSearch={(e) => console.log(e, 'eeee')}
          // onClick={(e) => {
          //   console.log(e)
          // }}
        />
      );
      break;
    case 'autocomplete':
      inputNode =
        <AutoComplete
            style={{ width: '300px'}}
            onSearch={(e) => {
              if (e === '') {
              //Default keywork search is Number = 0;
              onSearch(0);
              } else {
                onSearch(e);
              }
            }}
            onSelect={(e)=> console.log(e)}
            dropdownMatchSelectWidth={false}
            // options={medicineOptions || []}
            // ref={inputEl}
            notFoundContent={<div></div>}
            // getPopupContainer={() =>
            //   document.getElementById('medicine-autocomple-container')
            // }
            dropdownRender={() => {
              return (
                <Table
                  scroll={{ x: 700 }}
                  className="autocomple-table"
                  size="small"
                  // loading={isLoading}
                  dataSource={mapData}
                  pagination={false}
                  rowKey={rc => rc?._id}
                  columns={[
                    {
                      title: 'Mã NVL',
                      dataIndex: 'variantCode',
                      key: 'variantCode',
                      width: '90px',
                    },
                    {
                      title: 'Tên thuốc/ hàng hoá',
                      dataIndex: 'name',
                      key: 'name',
                      width: '300px'
                    },
                    {
                      title: 'Đơn vị',
                      dataIndex: 'unit',
                      key: 'unit',
                      width: '150px'
                    },
                  ]}
                  onRow={record => {
                    return {
                      onClick: () => {
                        handleSelectAutoComplete(record);
                      },
                      style: record?.disabled === true ? { pointerEvents: 'none', color:'red', opacity: 0.7, hover: 'not-allowed',} : ''
                    };
                  }}
                />
              );
            }}
    ></AutoComplete>
      break;
      default:
          inputNode = <Input disabled={disabled}/>;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: required,
              message: `Vui lòng ${keyRule[inputType]} ${title?.toLowerCase()}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

/**
 * This is main component
 * @param {Object} props
 * @param {Function} setDataMedical
 * @param {Array} dataMedical
 * @returns 
 */
export default function TabMaterials({ props,setDataMedical,dataMedical, setIsTabClick, initWhService, whPackageLevelId,
  whServiceId }) {
  const { index, id, language, ...rest } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [defaultEditable, setDefaultEditable] = useState(false);
  const [isDisable, setIsDisable] = useState(form.getFieldValue('category') === '' ? false : true);
  const [profile] = useProfile();
  const [categoryName, setCategoryName] = useState('MEDICAL');
  const { keyword, setKeyword, loading, data, onSearch } = useGetMaterialInService((get(profile, 'branchId', '')), categoryName);
  const mapData = useMemo(() => {
    const newData = data?.map((item) => {
      const Item = {
        name: item.product?.name,
        unit: item.unit?.name,
        variantCode: item.variantCode,
        _id: item._id
      };
      return Item;
    });

    //Get value form editing
    const valueEditing = form.getFieldValue('name');
    const valueUnit = form.getFieldValue('unit');
    const newDataSource = [...dataMedical];

    //Disables elements that already exist in the tab
    const updatedData = newData.map((item) => {
      const matchingItem = newDataSource.find((dataMedicalItem) => {
        return (toLower(dataMedicalItem.name) === toLower(item.name) && toLower(dataMedicalItem.unit) === toLower(item.unit));
      });
      return {
        ...item,
        disabled: Boolean(matchingItem), // Change value become is boolean
      };
    });

      // Do not disable the element being edited
      const updatedNewData = updatedData.map((item) => {
        if (item.name === valueEditing && item.unit === valueUnit) {
          return { ...item, disabled: false };
        }
        return item;
      });
    return updatedNewData
  }, [data, editingKey,dataMedical]);
  

  const optionsClinicDefault = [{
    name: {
      vi: 'Thuốc',
      en: 'Medicine'
    },
    _id: 'MEDICINE',

  },
  {
    name: {
      vi: 'Hàng hoá',
      en: 'Product'
    },
    _id: 'PRODUCT'
    }];
  
  /**
   * Check the row editing conditions
   * @param {Object} record
   * @returns {Boolean}
   */
  const isEditing = (record) => record._id === editingKey; //

  /**
   * Remove empty elements from the array
   * @returns {Array} filterDataSource()
   */

  const filterDataSource = (data) => {
    const filteredData = data.filter((item) => item.category !== '');
    return filteredData;
  };

  function generateRandomFiveDigitNumber() {
    const min = 10000;
    const max = 99999;
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  /**
   * Handle cancel edit row
   * @param {String} _id
   */
  const handleCancel = (_id) => {
    setEditingKey('');
    setDefaultEditable(false);
    setDataMedical(() => filterDataSource(dataMedical));
  };

  /**
   * Handle edit row
   * @param {Object} record
   */
  const handleEdit = (record) => {
    const isExitsCategory = record?.category === '';
    setIsDisable(isExitsCategory);
    form.setFieldsValue({
      category: ``,
      name: ``,
      amount: 1,
      unit: ``,
      note: '',
      ...record
    });
    setEditingKey(record._id);
    setDefaultEditable(true);
  };
  /**
   * Handle save row
   * @param {Object} record
   */
  const handleSave = async (record) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataMedical];
      const index = newData.findIndex((item) => record?._id === item._id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        setDataMedical([...newData]);
        setEditingKey('');
      } else {
        newData.push(row);
        setDataMedical([...newData]);
        setEditingKey('');
      };
      setDefaultEditable(false);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  /**
   * Handle add row
   */
  const handleAdd = () => {
    const newData = {
      _id: String(generateRandomFiveDigitNumber()), // Requires the generated id to be 5 numeric characters long
      category: ``,
      amount: 1,
      unit: ``,
      note: ''
    };
    setDataMedical([newData, ...dataMedical]);
    handleEdit(newData);
  };

  /**
   * Handle delete row
   * @param {Object} record
   */
  const handleDelete = async (record) => {
    let isDevice = { status: false, messenger: '' }
    let checkMaterialInDataBase = initWhService?.packages[index]?.materialInits?.find(item => item?._id === record?._id); //Return object

    if(!checkMaterialInDataBase){
      const newData = dataMedical.filter((item, objIndex) => item._id !== record?._id);
      setDataMedical(newData);
    } else {
      const data = {
        type: 'NVL',
        infoClinic: {
          category: record?.category,
          unit: record?.unit,
          amount: record?.amount,
          name: record?.name,
        },
        whPackageLevelId,
        whServiceId
      };
      isDevice =  await api.whService.checkDevice(data); // Check whether the condition is creating new data or updating old data
        if (!isDevice?.status) {
        const newData = dataMedical.filter((item, objIndex) => item._id !== record?._id);
        setDataMedical(newData);
      } else {
        toastr.error((isDevice?.message) || 'Không thể xóa thiết bị này vì đang có lịch hẹn sử dụng nhóm nguyên vật liệu này')
      };
    }
  
  };

  const handleSelectAutoComplete = (record) => {
    form.setFieldsValue({
      name: record.name,
      unit: record.unit,
    });
  };

const columns = [
  {
    title: 'Nhóm nguyên vật liệu',
    key: 'category',
    dataIndex: 'category',
    width: '250px',
    disabled: false,
    editable: true,
    inputType: 'select',
    required: true,
    selectOption: optionsClinicDefault,
    render: (value, record) => {
      const findItem = optionsClinicDefault?.find((item)=> item._id === value)
      return findItem?.name[language];
    }
  },
  {
    title: 'Tên nguyên vật liệu',
    key: 'name',
    disabled:  true ,
    dataIndex: 'name',
    width: '200px',
    editable: true,
    inputType: 'autocomplete',
    required: true,
    // render: () => {}
  },
  {
    title: 'Số lượng',
    key: 'amount',
    dataIndex: 'amount',
    width: '100px',
    editable: true,
    inputType: 'number',
    required: true,
    // render: () => {}
  },
  {
    title: 'Đơn vị',
    key: 'unit',
    dataIndex: 'unit',
    disabled: false,
    width: '100px',
    editable: true,
    inputType: 'text',
    required: true,
    // render: () => {}
  },
  {
    title: 'Ghi chú',
    disabled: false,
    disabled: false,
    dataIndex: 'note',
    width: '300px',
    editable: true
  },
  {
    title: '',
    disabled: false,
    disabled: false,
    dataIndex: 'operation',
    width: '150px',
    fixed: 'right',
    render: (_, record, index) => {
      const editable = isEditing(record);
      setIsTabClick(!editable)
      return (
        editable ? (
          <span>
            <Typography.Link
              // disabled = {true}
              onClick={() => {
                handleSave(record);
              }}
              style={{
                marginRight: 8
              }}
            >
              Lưu
            </Typography.Link>
            <span style={{ margin: '0px 10px' }}>/</span>
            <Popconfirm
              title="Bạn có muốn huỷ chỉnh sửa?"
              onConfirm={() => handleCancel(record._id)}
            >
              <a>Huỷ</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => handleEdit(record)}
            >
              <EditOutlined />
            </Typography.Link>
            <span style={{ margin: '0px 10px' }}>/</span>
            <Popconfirm
              title="Bạn có chắc muốn xóa?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>
          </span>
        )
        )
    }
  }
];
  /**
   *
   * @returns {Array}
   */
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        required: col.required,
        editing: isEditing(record),
        selectOptions: col.selectOption,
        inputType: col.inputType,
        disabled: col.disabled,
        handleSave,
        onSearch,
        language,
        setKeyword,
        mapData,
        setIsDisable,
        isDisable,
        setCategoryName,
        form,
        handleSelectAutoComplete,
      })
    };
  });
  return (
      <Form form={form} component={false}>
        {isLoading ? (
          <SkeletonTable columns={columns} rowCount={5} />
        ) : (
          <>
            <Table
              columns={mergedColumns}
              dataSource={dataMedical}
              components={{
                body: {
                  cell: EditableCell
                }
              }}
              bordered
              rowClassName="editable-row"
              pagination={false}
              scroll={{ x: 'auto', y: 400 }}
            />
            {!defaultEditable && (
              <Button
                onClick={handleAdd}
                type="dashed"
                style={{
                  marginBottom: 16
                }}
              >
                <PlusOutlined /> Thêm nguyên vật liệu
              </Button>
            )}
          </>
        )}
      </Form>
  );
};




