import React, { useEffect, useCallback, useState } from 'react';
import { Form, Input, Row, Button, Skeleton, Col, Select } from 'antd';
import { cloneDeep } from 'lodash';
import { CUSTOMER_TYPE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';

import {
  useCustomerType,
  useCreateCustomerType,
  useUpdateCustomerType,
  useInitCustomerType,
  useResetCustomerType
} from '~/hooks/customerType';
import { get } from 'lodash';

const FormItem = Form.Item;
const { Option } = Select;
const CustomerTypeForm = ({ specialityOptions, onClose, passedCustomerType, customerTypeId }) => {
  const [form] = Form.useForm();
  const [isSubmitLoading, handleCreate] = useCreateCustomerType(onClose);
  const [, handleUpdate] = useUpdateCustomerType(onClose);
  // const [customerType, isLoading] = useCustomerType(customerTypeId);
  // const initCustomerType = useInitCustomerType(customerType);
  const initCustomerType = cloneDeep(passedCustomerType); // dùng trực tiếp data đã pass vào từ table danh sách bên ngoài
  useResetCustomerType();
  useEffect(() => {
    if (!customerTypeId) {
      form.resetFields();
    } else {
      form.setFieldsValue(initCustomerType);
    }
  }, [initCustomerType, customerTypeId, form]);

  const onFinish = useCallback(
    (values) => {
      if (customerTypeId) {
        handleUpdate({ ...values, id: customerTypeId });
      } else {
        handleCreate({ ...values });
      }
      onClose();
    },
    [handleCreate, handleUpdate, customerTypeId]
  );
  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;
  return (
    <div className="Customer-type page-wraper hospital-form-page-content">
    <h1 style={{ margin: '40px' }}>
    {customerTypeId ? ' Cập nhật' : 'Thêm mới'} loại khách hàng
    </h1>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
           onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          // initialValues={initDegree}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <FormItem
            label="Loại khách hàng"
            name="name"
            rules={[
              { required: true, message: 'Xin vui lòng nhập loại khách hàng' }
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="Số điểm"
            name="minScore"
            rules={[{ required: true, message: 'Xin vui lòng nhập điểm' }]}
          >
            <Input />
          </FormItem>

          <Row className="form__submit-box">
            <Button  type="primary" htmlType="submit">
              {customerTypeId ? 'Cập nhật' : 'Thêm mới'}
            </Button>

            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.customerType.root}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default CustomerTypeForm;
