import { useEffect, useMemo, useRef, useState } from 'react';

import { Col, Form, Input, Row, Skeleton, Space, Table, Tabs } from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import {
  useInitWhReceiptVoucher,
  useResetWhReceiptVoucher, useWhReceiptVoucher
} from '~/hooks/whReceiptVoucher';

import api from '~/api';
import { BaseBorderBox } from '~/components/Common';
import { ACCOUNTS } from '~/constants/defaultValue';


import './wh-report.scss';

const FormItem = Form.Item;
const { TabPane } = Tabs;

const DEFAULT_ACCOUNT = 1111;
const mainRowGutter = 24;
const columns = [

  {
    title: 'Diễn giải',
    dataIndex: 'content',
    key: 'content',
    width : '30%',
    render: (item,record) => item
  },
  {
    title: 'TK nợ',
    dataIndex: 'debitAccount',
    key: 'debitAccount',
    render: (item,record) => item + " - " +ACCOUNTS[item]
  },
  {
    title: 'TK có',
    dataIndex: 'creditAccount',
    key: 'creditAccount',
    render: (item,record) => item + " - " +ACCOUNTS[item]
  },
  {
    title: 'Số tiền',
    dataIndex: 'amountOfMoney',
    key: 'amountOfMoney',
    render: (item,record) => item
  },
];
const WhReceiptVoucherSaleSystem = ({
  isHandledByWhPartner,
  whBill,
  whBillItem,
  whReceiptVoucherId,
}) => {
  const [form] = Form.useForm();



  const id = whReceiptVoucherId;
  const [whReceiptVoucher, isGetWhReceiptVoucherLoading] = useWhReceiptVoucher(id); // since partner can not call this API, we want it to mute the toast when failed
  const initWhReceiptVoucher = useInitWhReceiptVoucher(whReceiptVoucher);


  const [initCustomerAccounts, setInitCustomerAccounts] = useState([]);
  const [initStaffs, setInitStaffs] = useState([]);

  useEffect(() => {
    const getInitCustomerAccount = async (customerId) => {
      try {
        const { data } = await api.whCustomerManagement.getById(customerId);
        setInitCustomerAccounts([data].map(item => {
          return {
            ...item,
            label: `${get(item, "customerIdFull")} - ${get(item, "fullName")}`,
          }
        }
        ));
      } catch (error) {
        console.error(error);
      }
    }
    const getInitStaff = async (employeeId) => {
      // try {
      //   let response =null
      //   response = await api.userEmployee.getById(employeeId);
      //   if(!!response.data){
      //     response = get(response,'data')
          
      //   }
      //   else{
      //     response = await api.employee.getById(employeeId);

      //   }
      //   form.setFieldsValue({employeeId : get(response,'fullName','')})
      //   setInitStaffs([response]);
      // } catch (error) {
      //   console.error(error);
      // }
      try {
        const response = await api.userEmployee.getById(employeeId);
        form.setFieldsValue({employeeId : get(response,'data.fullName','')})
        setInitStaffs([response]);
      } catch (error) {
        console.error(error);
      }
    }

    form.resetFields();

    let { customerId, employeeId } = initWhReceiptVoucher;
    if (customerId) getInitCustomerAccount(customerId);
    if (employeeId) getInitStaff(employeeId);
  }, [
    initWhReceiptVoucher,
    form,
  ]);

  // Check for the id, if the id is provided, this form is to display an existing wh-voucher.
  // Otherwise this form is to create a new wh-voucher,
  // then use the provided whBill and whBillItem to prefill some fields in the form.
  useEffect(() => {
    const getCustomerAccount = async (customerAccountId) => {
      try {
        const { data } = await api.whCustomerManagement.getById(customerAccountId);
        const { customerIdFull, fullName } = data; // customerId là Mã Khách Hàng theo số tự nhiên
        form.setFieldsValue({
          // ở phiếu thu chi wh-voucher thì Mã Khách Hàng lưu ở `customerNumber`
          customerNumber: {
            value: customerIdFull,
            label: `${customerIdFull} - ${fullName}`,
          },
          customerName: fullName,
        })
      } catch (error) {
        console.error("error...");
        console.error(error);
      }
    }

    let reason;

    if (id && initWhReceiptVoucher) {
      const { accountingDetails } = initWhReceiptVoucher;
      setDataSource(accountingDetails);
    } else {
      if (whBill) {
        const {
          customerAddress,
          customerAddressId,
          customerId,
        } = whBill;

        getCustomerAccount(customerId);

        form.setFieldsValue({
          accountingDate: get(whBill, "createdAt") ? moment(get(whBill, "createdAt")).format("DD-MM-YYYY") : null,
          customerAddress,
          customerAddressId,
          customerId,
          dateOfIssue: get(whBill, "createdAt") ? moment(get(whBill, "createdAt")).format("DD-MM-YYYY") : null,
        });
      }

      if (whBillItem) {
        const whServiceCode = get(whBillItem, "snapshotService.code") || "";
        const whServiceName = (get(whBillItem, "snapshotService.detailName.vi") ? `${get(whBillItem, "snapshotService.name.vi")} - ${get(whBillItem, "snapshotService.detailName.vi")}` : get(whBillItem, "snapshotService.name.vi") ) || "";
        reason = `Thanh toán đơn hàng ${get(whBill, "billNumber")}, dịch vụ ${whServiceCode} - ${whServiceName}`
      } else {
        reason = `Thanh toán đơn hàng ${get(whBill, "billNumber")}`;
      }

      form.setFieldsValue({ reason });
    }
  }, [
    id,
    initWhReceiptVoucher,
    whBill,
    whBillItem,
  ]);

  // use initWhReceiptVoucher to merge with other data that should be fetched from the API
  const mergedInitWhReceiptVoucher = useMemo(() => {
    if (!id) {
      return {
        ...initWhReceiptVoucher,
        accountingDate :  moment(get(initWhReceiptVoucher,'createdAt',0)).format("DD-MM-YYYY"),
      dateOfIssue :  moment(get(initWhReceiptVoucher,'dateOfIssue',0)).format("DD-MM-YYYY"),
        originalDocument: 0,
      }
    }

    return {
      ...initWhReceiptVoucher,
      accountingDate :  moment(get(initWhReceiptVoucher,'createdAt',0)).format("DD-MM-YYYY"),
      dateOfIssue :  moment(get(initWhReceiptVoucher,'dateOfIssue',0)).format("DD-MM-YYYY"),
    };
  }, [
    id,
    initWhReceiptVoucher,
    isHandledByWhPartner,
  ]);

  useEffect(() => {
    form.setFieldsValue(mergedInitWhReceiptVoucher);
  }, [form, mergedInitWhReceiptVoucher]);

  useResetWhReceiptVoucher();

  const [dataSource, setDataSource] = useState([
    {
      content: `Thanh toán đơn hàng ${get(whBill, "billNumber")}`,
      debitAccount: DEFAULT_ACCOUNT,
      creditAccount: null,
      // amountOfMoney: get(whBillItem, "remainingAmount") || get(whBill, "remainingAmount") || 0,
      amountOfMoney: 0,
    }
  ]);
  const isLoading = isGetWhReceiptVoucherLoading;


  return (
    <div className="page-wraper">
      <div className="container-fluid">
        <Form
          autoComplete="off"
          form={form}
          initialValues={mergedInitWhReceiptVoucher}
          labelAlign="left"
          labelCol={{ sm: 24, md: 24, lg: 4 }}
          requiredMark={false}
          scrollToFirstError
        >
          <Row
            align="top"
            className="staff-form__logo-row"
            gutter={mainRowGutter}
            justify="space-between"
          >
            <Col span={isHandledByWhPartner ? 24 : 16} order={isHandledByWhPartner ? 2 : null}>
              <BaseBorderBox title={"Thông tin chung"}>
                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      // label={<>Mã khách hàng{` `}<code>customerNumber</code></>}
                      // wrapperCol={{ lg: 20 }}
                      label={"Mã khách hàng"}
                      labelCol={{ lg: 8 }}
                      name="customerNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập mã khách hàng!',
                        }
                      ]}
                    >
                      {isLoading
                        ? <Skeleton.Input active />
                        : (
                          <Input style={{pointerEvents : 'none'}}  bordered={false}/>
                        )
                      }
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Tên khách hàng"
                      labelCol={12}
                      name="customerName"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn tên khách hàng!',
                        }
                      ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      label="Người nộp"
                      name="customerName"
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                  </Col>
                </Row>


                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      label="Địa chỉ"
                      name={["customerAddress", "street"]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      name="reason"
                      label="Lý do nộp"
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  {!isHandledByWhPartner && <Col span={12}>
                    <FormItem
                      name="employeeId"
                      label="Nhân viên thu"
                      // label={<>Nhân viên thu{` `}<code>employeeId</code></>}
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn nhân viên thu'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style={{pointerEvents : 'none'}}  bordered={false}/>
                      )}
                    </FormItem>
                  </Col>}
                  <Col span={12}>
                    <Space>
                      <FormItem
                        label="Kèm theo"
                        name="originalDocument"
                        labelCol={16}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập số chứng từ kèm theo!',
                          }
                        ]}
                      >
                        {isLoading
                          ? <Skeleton.Input active />
                          : (
                            <Input className='addonAfterInput' addonAfter="chứng từ gốc" style={{pointerEvents : 'none'}}  bordered={false}/>
                          )
                        }
                      </FormItem>
                      
                    </Space>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>

            <Col
              span={isHandledByWhPartner ? 24 : 8}
              order={isHandledByWhPartner ? 1 : null}
            >
              <BaseBorderBox title={"Chứng từ"}>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      label="Ngày hạch toán"
                      name="accountingDate"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày hạch toán!',
                        }
                      ]}
                    >
                      {isLoading
                        ? <Skeleton.Input active />
                        :<Input style={{pointerEvents : 'none'}}  bordered={false}/>
                      }
                    </FormItem>
                    <FormItem
                      label="Ngày chứng từ"
                      name="dateOfIssue"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày chứng từ!',
                        }
                      ]}
                    >
                      {isLoading
                        ? <Skeleton.Input active />
                        : <Input style={{pointerEvents : 'none'}}  bordered={false}/>
                      }
                    </FormItem>

                    <FormItem
                      label="Số chứng từ"
                      name="issueNumber"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập số chứng từ!',
                        }
                      ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>
          </Row>

          {!isHandledByWhPartner && <BaseBorderBox title={"Ghi chú"}>
            <Row>
              <Col span={24}>
                <FormItem
                  label="Ghi chú"
                  name={['note']}
                  labelCol={{ lg: 8 }}
                >
                  {isLoading ? <Skeleton.Input active /> : <Input style={{pointerEvents : 'none'}}  bordered={false}/>}
                </FormItem>
              </Col>
            </Row>
          </BaseBorderBox>}

          {!isHandledByWhPartner && (
            <Tabs
              defaultActiveKey={1}
              destroyInactiveTabPane
              onChange={() => { }}
            >
              <TabPane tab="Hạch toán" key={1}>
                <Table dataSource={dataSource} columns={columns}/>

              </TabPane>
            </Tabs>
          )}

          {isHandledByWhPartner && (
            <>
            <BaseBorderBox title={"Hạch toán"}>
              <Row>
                <Col span={24}>
                  <FormItem
                    label="Diễn giải"
                    name={['accountingDetails', '0', 'content']}
                    labelCol={{ lg: 8 }}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input disabled={isHandledByWhPartner} />}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItem
                    label="Số tiền"
                    name={['accountingDetails', '0', 'amountOfMoney']}
                    labelCol={{ lg: 8 }}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input disabled={isHandledByWhPartner} />}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItem
                    label="Tổng cộng"
                    name={['totalAmount']}
                    labelCol={{ lg: 8 }}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input disabled={isHandledByWhPartner} />}
                  </FormItem>
                </Col>
              </Row>
            </BaseBorderBox>

            <BaseBorderBox title={"Ghi chú"}>
              <Row>
                <Col span={24}>
                  <FormItem
                    label="Ghi chú"
                    name={['note']}
                    labelCol={{ lg: 8 }}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
              </Row>
            </BaseBorderBox>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default WhReceiptVoucherSaleSystem;
