import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'antd';
import api from '~/api';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { Breadcrumb } from '~/components/Common';
import { useProfile } from '~/hooks';
import moment from 'moment';
import { KEY_FIELDS_SERVICES } from './defaultKeyName';

export default function WhServiceHistory({ whServiceHistory }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [paging, setPaging] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const onChangeTable = ({ current, pageSize }) => {
    setPaging({
      current,
      pageSize,
      total: whServiceHistory?.length
    })
  }

  useEffect(() => {
    whServiceHistory?.length > 0 && setPaging({
      current: 1,
      pageSize: 10,
      total: whServiceHistory?.length
    });
  }, [whServiceHistory]);

  const columns = [
    {
      title: 'Hành động',
      key: 'message',
      dataIndex: 'message',
      render: (value, record) => value
    },
    {
      title: 'Mục bị thay đổi',
      key: 'innerName',
      dataIndex: 'innerName',
      render: (value, record) => value
    },
    // {
    //   title: 'Thời gian',
    //   key: 'createdAt',
    //   dataIndex: 'createdAt',
    //   render: (value)=> moment(value).format('YYYY-MM-DD HH:mm:ss')
    // },
    // {
    //   title: 'Nội dung chỉnh sửa',
    //   key: 'more',
    //   render: () => {
    //     return (
    //       <Button onClick={()=> handleOpenForm()}>Xem chi tiết</Button>
    //     )
    //   }
    // },
    {
      key: 'oldValue',
      title: 'Giá trị cũ',
      dataIndex: 'oldValue'
    },
    {
      key: 'newValue',
      title: 'Giá trị mới',
      dataIndex: 'newValue'
    }
  ];
  return (
    <>
      {isLoading ? (
        <SkeletonTable columns={columns} rowKey={3} />
      ) : (
        <Table
          dataSource={whServiceHistory}
          columns={columns}
          size="small"
            pagination={{
              ...paging,
              showTotal: (total) => `Tổng: ${total}`
            }}
          onChange={onChangeTable}
        />
      )}
    </>
  );
}
