import React from 'react';

import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import './index.scss';
import GroupCategory from './GroupCategory';

const GroupCategoryRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={GroupCategory} />
      <Route path={`${path}/:groupId`} component={GroupCategory} />
      <Redirect to={path} component={GroupCategory}></Redirect>
    </Switch>
  );
};

export default GroupCategoryRoutes;
