import * as Types from '~/constants/actionTypes';

export const getClinicOverViewReport = () => ({
  type: Types.GET_CLINIC_OVER_VIEW_REPORT_REQUEST
});

export const getClinicYearlyReport = () => ({
  type: Types.GET_CLINIC_YEARLY_REPORT_REQUEST
});

export const getClinicQuarterlyReport = () => ({
  type: Types.GET_CLINIC_QUARTERLY_REPORT_REQUEST
});

export const getClinicMonthlyReport = () => ({
  type: Types.GET_CLINIC_MONTHLY_REPORT_REQUEST
});

export const getClinicDailyReport = () => ({
  type: Types.GET_CLINIC_DAILY_REPORT_REQUEST
});

export const getMostDaily = () => ({
  type: Types.GET_MOST_DAILY_REQUEST
});

export const getMostMonthly = () => ({
  type: Types.GET_MOST_MONTHLY_REQUEST
});

export const getMostQuarterly = () => ({
  type: Types.GET_MOST_QUARTERLY_REQUEST
});

export const getMostYearly = () => ({
  type: Types.GET_MOST_YEARLY_REQUEST
});

export const getClinicOverviewToday = () => ({
  type: Types.GET_CLINIC_OVERVIEW_TODAY_REQUEST
})

export const getClinicOverviewDaily = () => ({
  type: Types.GET_CLINIC_OVERVIEW_DAILY_REQUEST
})

export const getClinicOverviewMonthly = () => ({
  type: Types.GET_CLINIC_OVERVIEW_MONTHLY_REQUEST
})

export const getClinicOverviewQuarterly = () => ({
  type: Types.GET_CLINIC_OVERVIEW_QUARTERLY_REQUEST
})

export const getClinicOverviewYearly = () => ({
  type: Types.GET_CLINIC_OVERVIEW_YEARLY_REQUEST
})

export const resetClinicDashboardState = () => ({
  type: Types.RESET_CLINIC_DASHBOARD_STATE
});
