import React, { useState, useMemo, useEffect } from 'react';

import { find, forIn, get, keys, transform, unset } from 'lodash';
import { json, Link } from 'react-router-dom';
import moment from 'moment';
import {
  Table,
  Popconfirm,
  Input,
  Modal,
  Button,
  Row,
  TreeSelect,
  Form,
  Checkbox,
  Select,
  Radio,
  Col,
  Tag,
  Tooltip
} from 'antd';

import WhBillConsole from './WhBillConsole';
import { FilterDatePicker } from '~/components/Common';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { MAP_WH_BILL_ITEM_STATUS_TAG, MAP_WH_BILL_STATUS_TAG, PAYMENT_METHOD, RELATIVE_POSITION, WH_APPOINTMENT_STATUS, WH_BILL_STATUS, WH_BILL_STATUS_EN, WH_PAYMENT_METHOD, WH_PAYMENT_METHOD_VI } from '~/constants/defaultValue';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import FilterWhBillStatus from './Filter/FilterWhBillStatus';
import CustomerAndBillStatus from './CustomerAndBillStatus';
import { WH_BILL_ITEM_STATUS } from '~/constants/defaultValue';
import POLICY from '~/constants/policy';
import { useMatchPolicy, useProfile, useUser } from '~/hooks';
import { GeoTreeSelect } from '~/components/Common';
import WithPermission from '~/components/Common/WithPermission';
import { useHistory } from 'react-router-dom'
import {
  useCancelWhBill,
  useCancelWhBillStatus,
  useConfirmPrepaymentWhBill,
  useConfirmPrepaymentWhBillStatus,
  useCustomerCancelWhBill,
  useCustomerCancelWhBillStatus,
  useDeleteWhBill,
  useUpdateWhBill,
  useUpdateWhBillParams,
  useWhBillPaging,
  useWhBills,
  useWhBillsQueryParams,
} from '~/hooks/whBill';

import './wh-bill.scss';
import ModalConfirm from '../Common/ModalConfirm';
import ExportExcelButton from '../Common/ExportExcel';
import { formatNumberThreeComma } from '~/hooks/utils';
import { formatter } from '~/utils/helper';
import TooltipTitle from '../Common/TooltipTitle';
import TabBranch from '../Common/TabBranch';
import UnConfirmWhBillTable from './UnConfirmWhBillTable';
import POLICIES from '~/constants/policy';
import NoteCustomer from './NoteCustomer';
import iconPen from '~/assets/images/icon_pen.png';
import DebounceSelectByCategory from '../WhCategory/SearchByCategory';

const { Search } = Input;

// const mapAppoinemtStatusTag = {
//   [MAP_WH_BILL_ITEM_STATUS_TAG.NEW]: { name: "Mới", color: "geekblue" },
//   [WH_APPOINTMENT_STATUS.IN_PROGRESS]: { name: "Đang thực hiện", color: "processing" },
//   [WH_APPOINTMENT_STATUS.COMPLETED]: { name: "Đã hoàn thành", color: "success" },
//   [WH_APPOINTMENT_STATUS.CANCELLED]: { name: "Đã hủy", color: "error" },
// }

const ColumnActions = ({ item, deleteBill, openDetails }) => {
  return (
    <div className="custom-table__actions">
      <a onClick={openDetails}>
        <p>Chi tiết</p>
      </a>
      <p>|</p>
      <Popconfirm
        title="Bạn muốn xoá đơn hàng này?"
        onConfirm={() => deleteBill(item._id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </div>
  );
};

function LinkComponent(data) {
  return (
    <div key={data._id}>
      {data?.status !== WH_BILL_ITEM_STATUS.NEW
        ? (
          data?.status !== WH_BILL_ITEM_STATUS.CANCELLED ?
        (<Link to={`wh-bill-item/${data._id}/update-appointments`}>
          {data.code}
            </Link>)
            : (
              <Link style={{ color: 'red' }} to={`wh-bill-item/${data._id}/update-appointments`}>
              {data.code}
            </Link>
            )
        )
        : (
        data.code
      )}
    </div>
  );
}

const modeFilterDate = {
  completedTime :{
    value :  'completedTime',
    fieldStartTime :'startCompletedTime',
    fieldEndTime :'endCompletedTime',
  },
  createdAt :{
    value :  'createdAt',
    fieldStartTime :'startDate',
    fieldEndTime :'endDate',
  },
  confirmDate :{
    value :  'confirmDate',
    fieldStartTime :'startConfirmDate',
    fieldEndTime :'endConfirmDate',
  },
  updateDate :{
    value :  'updateDate',
    fieldStartTime :'startUpdateDate',
    fieldEndTime :'endUpdateDate',
  },
  
}

const WhBills = () => {
  const [form] = Form.useForm();
  const isMatchPolicy = useMatchPolicy(POLICY.WHUPDATEBILLSTATUS);
  const [, token] = useUser();
  const history = useHistory();
  const [profile] = useProfile(token);
  const [selectedWhBill, setSelectedWhBill] = useState(null);
  const [query] = useWhBillsQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateWhBillParams(query);
  const [whBills, isLoading] = useWhBills(query);
  const [, deleteBill] = useDeleteWhBill();
  const paging = useWhBillPaging();
  const [isSubmitLoading, updateWhBill] = useUpdateWhBill();
  const [isCancelBillLoading, handleCancelBill] = useCancelWhBill();
  // const [isConfirmPrepaymentLoading, handleConfirmPrepayment] = useConfirmPrepaymentWhBill();
  const [cancelWhBillSuccess] = useCancelWhBillStatus();
  const [confirmPrepaymentWhBillSuccess] = useConfirmPrepaymentWhBillStatus();
  const [customerCancelWhBillSuccess,] = useCustomerCancelWhBillStatus();

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isOpenConfirmPrepaymentModal, setIsOpenConfirmPrepaymentModal] = useState(false);
  const [isOpenCustomerCancelWhBillModal, setIsOpenCustomerCancelWhBillModal] = useState(false);
  const [whCategory, setWhCategory] = useState(null);

  const [isOpenModalCustomerCancel, setIsOpenModalCustomerCancel] = useState(false)
  // in case we will allow admin confirm a random amount of prepayment,
  // there should be a modal with form using this `initPrepaymentValues`
  const [initPrepaymentValues, setInitPrepaymentValues] = useState({
    actualPrepaymentAmount: 0
    // otherFields: null,
  });
  const [initCancelValues, setInitCancelValues] = useState({});
  const canRead = useMatchPolicy(POLICY.READ_CUSTOMER);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_CUSTOMER);

  //Handle get list Id check box
  const [arrCheckBox, setArrCheckBox] = useState([])
  const onChangeCheckBox = (e, id) => {
    if (e) {
      setArrCheckBox([...arrCheckBox, id])
    } else {
      const getIndex = arrCheckBox.findIndex((itemId, index) => itemId === id)
      const newArr = arrCheckBox.toSpliced(getIndex, 1)
      setArrCheckBox(newArr)
    }
  }

  useEffect(() => {
    const selectedId = get(selectedWhBill, '_id');
    if (selectedId) {
      const updatedSelectedWhBill = find(whBills, { _id: selectedId });
      updatedSelectedWhBill && setSelectedWhBill(updatedSelectedWhBill);
    }
  }, [whBills]);

  useEffect(() => {
    if(!whCategory && query?.whCategory){
      setWhCategory(query?.whCategory);
    };
  }, [query]);

  const isRootAdmin = get(profile, 'isSuperAdmin') === true;

  const managementPaths = useMemo(() => {
    return get(profile, 'managementArea')?.map((item) => get(item, 'path'));
  }, [profile]);

  const managementAreaQuery = useMemo(() => {
    const result = JSON.parse(query.managementArea);

    return result ? result : [];
  }, [query]);

  const onSelectWhBill = (item, event) => {
    event?.preventDefault();
    setSelectedWhBill(item);
  };

  const onCloseWhBillModal = () => {
    setSelectedWhBill(null);
  };
  const onCloseModalCustomerCancel = () => {
    setIsOpenModalCustomerCancel(false)
  }
  const [isCustomerCancelWhBillLoading, handleCustomerCancel] = useCustomerCancelWhBill(onCloseModalCustomerCancel);
  const onWhBillStatusChange = (value) => {
    console.log('whBill status change...');
    console.log(value);
  };

  const columns = [
    {
      title: 'Mã đơn hàng',
      key: 'billNumber',
      width: 100,
      render: (item) => (
        <a href="#" onClick={(event) => onSelectWhBill(item, event)}>
          {item.billNumber}
        </a>
      )
    },
    {
      title: 'Lựa chọn',
      key: '_id',
      width: 80,
      align: 'center',
      render: (item, record) => {
        const id = record._id
        return <Checkbox
          defaultChecked={arrCheckBox.includes(id)}
          onChange={(e) => onChangeCheckBox(e.target.checked, id)}
        />
      }
    },
    {
      title: 'ID dịch vụ',
      dataIndex: 'billItems',
      key: 'code',
      width: 100,
      render: (value) => value.map((billItem) => {
        const {notEnoughAssign,notEnoughConfirm, checkExistCommercialVoucher} = billItem;
        let message = [];
        if(notEnoughAssign) message.push("Có cuộc hẹn chưa được gắn thiết bị");
        if (notEnoughConfirm) message.push("Có cuộc hẹn chưa có phiếu xác nhận");
        const isCheckVoucher = checkExistCommercialVoucher === false;
        if(isCheckVoucher) message.push('Có cuộc hẹn chưa có phiếu bàn giao')
        const findAppoitment = billItem?.appointmentCancelStatus;
        if(findAppoitment) message.push("Dịch vụ này có tồn tại buổi hẹn bị huỷ");
        return <TooltipTitle dot={notEnoughAssign || notEnoughConfirm || isCheckVoucher || findAppoitment} titleTooltip={message?.join(',')} content={ LinkComponent(billItem)}/>
      })
    },
    {
      title: 'Tên',
      dataIndex: 'customerAddress',
      key: 'name',
      render: (value, record) => (
        canRead ? 
          <div>
            <Tooltip
            color={'#E0F4FF'}
            destroyTooltipOnHide
            trigger={'click'}
            overlayStyle={{ width: '300px' }}
            title={
              <NoteCustomer
                id={record.customerId}
                canUpdate = {canUpdate}
              />}
            >
              <span>
                {value?.fullName}
                <img src={iconPen} alt="pen" style={{ cursor: 'pointer', width: '15px', height: '15px', marginLeft: '5px'}}/>
              </span>
            </Tooltip>
          </div>
          : (value?.fullName)
      )
    },
    {
      title: 'SĐT',
      dataIndex: 'customerAddress',
      key: 'phoneNumber',
      render: (value) => value?.phoneNumber?.replaceAll('+84', '0')
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'customerAddress',
      key: 'customerAddress',
      // width : '15%',
      render: (value) =>
        `${value.street}, ${value.ward}, ${value.district}, ${value.city}`
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      // width : '3%',
      align: 'center',
      render: (value) => (
        <span className={`bill-status ${value.toLowerCase()}`}>
          {WH_BILL_STATUS[value]}
        </span>
        )
    },
    {
      title: 'Trạng thái từng DV',
      dataIndex: 'billItems',
      key: 'statusNotCancel',
      render: (value, record) => {
        return (
          <div style={{display: 'flex', flexDirection: 'column',gap:10, width: 'max-content'}}>
              { value?.map((billItem) => (
                  <Tag
                  color={get(MAP_WH_BILL_ITEM_STATUS_TAG[(billItem?.statusNotCancel).toUpperCase()], "color")}
                >
                  {get(MAP_WH_BILL_ITEM_STATUS_TAG[(billItem?.statusNotCancel).toUpperCase()], "name")}
                </Tag>
                ))}
          </div>
        )}
    },
    {
      title: 'Giá trị đơn hàng',
      key: 'grandTotal',
      dataIndex: 'grandTotal',
      align: 'right',
      // width : '5%',

      render: (value) => value?.toLocaleString()
    },
    {
      title: 'Đã thanh toán và xác nhận',
      key: 'actualTotalAmountPaid',
      dataIndex: 'actualTotalAmountPaid',
      align: 'right',
      // width : '5%',
      render: (value, record) => formatter(value + get(record, 'actualPrepaymentAmount', 0) + get(record, 'totalReceiptVoucherBillAmount', 0) - get(record, 'actualPrepaymentAmount', 0) || 0)
    },

    {
      title: 'Tiền đã chi trả khách hàng',
      // width : 7,
      key: 'totalPaymentVoucherBillAmount',
      dataIndex: 'totalPaymentVoucherBillAmount',
      render: (text) => formatter(text) || 0
    },

    {
      title: 'Còn lại',
      key: 'remainingAmount',
      dataIndex: 'remainingAmount',
      align: 'center',
      // width : '3%',
      render: (value) => value?.toLocaleString()
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      // width: '150px',
      // width : '10%',
      render: (value) => moment(value).format('HH:mm DD/MM/YYYY')
    },

    {
      title: 'Ngày hoàn thành ĐH',
      key: 'completedTime',
      dataIndex: 'completedTime',
      // width: '150px',
      // width : '10%',
      render: (value, record) => [WH_BILL_STATUS_EN.CUSTOMER_CANCEL, WH_BILL_STATUS_EN.COMPLETED]?.includes(get(record, 'status')) && moment(value).format('HH:mm DD/MM/YYYY')
    },

    {
      title: 'Ngày tiếp nhận',
      key: 'confirmDate',
      dataIndex: 'confirmDate',
      render: (value, record) => value && moment(value).format('HH:mm DD/MM/YYYY')
    },

    {
      title: 'Ngày cập nhật gần nhất',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (value, record) => value && moment(value).format('HH:mm DD/MM/YYYY')
    },

    {
      title: 'Phương thức thanh toán',
      key: 'paymentMethod',
      dataIndex: 'paymentMethod',
      align: 'center',
      render: (value) => <span style={{ marginRight: 5 }}>{WH_PAYMENT_METHOD_VI?.[value]}</span>
    }
    // {
    //   title: 'Thao tác',
    //   key: 'action',
    //   width: '110px',
    //   render: (record) => {
    //     return (
    //       <ColumnActions
    //         item={record}
    //         deleteBill={deleteBill}
    //         openDetails={() => setSelectedWhBill(record.orderNumber)}
    //       />
    //     );
    //   }
    // }
  ];
  const onManagementAreaChange = (value, option) => {
    console.log(value);
    console.log(option);
  };

  const onValuesChange = (values) => {
      onParamChange({ managementArea: JSON.stringify(values.managementArea) });
  };
  const setValueSearch = history?.location?.search
  const parseQuery = (query) => {
    const managementArea = get(query, 'managementArea', '[]')
    // unset(query, 'managementArea')
    const _ = transform(JSON.parse(managementArea), function (result, value) {
      result.push(value.value);
    }, [])
    return {
      ...query,
      managementArea: JSON.stringify(_)
    }
  }
  // props for exportExcel Button 
  const setQueryExport = (query) => {
    let queryParams = Object.entries(query)
      .filter(([key, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`);
    let queryString = queryParams.join("&");
    return queryString
  };

  const propsButton = {
    url: "/wh-bill",
    keyExport: "ListBill",
    fileName_: 'Danh sách đơn hàng',
    searchBy: setQueryExport(parseQuery(query)),
    ids: arrCheckBox
  }
  const onChangeModeDate = (e) => { // Switch date
      let change = {};
      // Get Date Exist Before Change
      const keyBefore = get(query,'sortBy');

      // Switch Date Before With Date Changing
      forIn(modeFilterDate,(value,key) => {
        if(key === e.target.value){
            Object.assign(change,{
            [value?.fieldStartTime] : get(query,modeFilterDate[keyBefore]?.fieldStartTime),
            [value?.fieldEndTime] : get(query,modeFilterDate[keyBefore]?.fieldEndTime),
          })
        }else{
          Object.assign(change,{
            [value?.fieldStartTime] : null,
            [value?.fieldEndTime] : null,
          })
        }
      });
      onParamChange({...change,sortBy: e.target.value});
  };
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách đơn hàng" />
        <UnConfirmWhBillTable />
        <div className="page-content__main">
          <div className="page-content__left">
            <Form
              form={form}
              autoComplete="off"
              onFinish={() => { }}
              scrollToFirstError
              onValuesChange={onValuesChange}
              requiredMark={false}
              initialValues={{ managementArea: managementAreaQuery, sortBy: query?.sortBy ||  modeFilterDate.createdAt.value }}
            >
              {/* <FilterWhBillStatus
                value={query.status}
                onChange={(value) => onParamChange({ status: value })}
              /> */}
              <Form.Item
                label={`Trạng thái`}
                labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  value={query?.status ? get(query, 'status', []).split(',') : []}
                  style={{
                    width: '100%'
                  }}
                  placeholder="Vui lòng chọn trạng thái"
                  onChange={(value) => onParamChange({ status: value })}
                >
                  {transform(
                    MAP_WH_BILL_STATUS_TAG,
                    (result, value, key) => {
                      result.push({ label: get(value, 'name', ''), value: key, color: get(value, 'colorStyle', '') });
                    },
                    []
                  )?.map((e) => (
                    <Select.Option value={get(e, 'value')}>
                      <p style={{ color: `${e.color}` }}>{get(e, 'label')}</p>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            {query?.sortBy &&  <>
              <FilterDatePicker
                label="Từ"
                value={get(query,modeFilterDate?.[query?.sortBy]?.fieldStartTime)}
                onChange={(value) => onParamChange({ [modeFilterDate?.[query?.sortBy]?.fieldStartTime]: value })}
              />
            <FilterDatePicker
                label="Đến"
                value={get(query,modeFilterDate?.[query?.sortBy]?.fieldEndTime)}
                onChange={(value) => onParamChange({ [modeFilterDate?.[query?.sortBy]?.fieldEndTime]: value })}
              />
            </>}
              <Form.Item name={'sortBy'} label="Theo ngày" labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}>
                <Radio.Group onChange={onChangeModeDate}>
                  <Radio value={'createdAt'}> Ngày tạo </Radio>
                  <Radio value={'completedTime'}> Ngày hoàn thành </Radio>
                  <Radio value={'confirmDate'}> Ngày tiếp nhận </Radio>
                  <Radio value={'updateDate'}> Ngày cập nhật</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Khu vực"
                name={['managementArea']}
                labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
              >
                <GeoTreeSelect
                  autoClearSearchValue
                  enabledValues={isRootAdmin ? null : managementPaths}
                  labelInValue={true}
                  // listHeight={360}
                  listItemHeight={200}
                  multiple={true}
                  onSelect={onManagementAreaChange}
                  showCheckedStrategy={TreeSelect.SHOW_ALL}
                  showEnabledValuesOnly={true}
                  showSearch={true}
                  size="large"
                  treeCheckStrictly={true}
                  treeCheckable={true}
                  treeDefaultExpandedKeys={['1', '2', '3']}
                  checkablePositions={[RELATIVE_POSITION.IS_CHILD, RELATIVE_POSITION.IS_EQUAL]}
                />
              </Form.Item>
              <Form.Item
                label={`Nhóm dịch vụ`}
                labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
              >
                <DebounceSelectByCategory
                  onChange={(e) => {
                    onParamChange({ whCategory: e });
                    setWhCategory(e)
                  }}
                  value={whCategory}
                  isFirstFetch={!!whCategory}
                />
              </Form.Item>
            </Form>
          
          </div>
          <div className="page-content__right">
          <TabBranch useTabAll={true}>
              <div className="page-wraper__header">
                <Row align='center'>
                  <Col span = {20}>
                    <Search
                      style={{ width: 300 }}
                      placeholder="Tìm đơn hàng"
                      enterButton
                      allowClear
                      onSearch={() => onParamChange({ keyword })}
                      onChange={(e) => {
                        setKeyword(e.target.value)
                        if(e.target.value === ""){
                          onParamChange({ keyword : null})
                        }
                      }}
                      value={keyword}
                    />
                  </Col>
                <WithPermission permission={POLICIES.DOWNLOAD_WHBILL}>
                  <Col span = {4} align = 'end' >
                    <ExportExcelButton
                      propsButton={propsButton}
                    />
                    </Col>
                    </WithPermission>
                </Row>
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={whBills}
                scroll={{ x: 2500,y: 500 }}
                onChange={({ current, pageSize }) => onParamChange({ page: current, limit: pageSize })}
                pagination={{
                  ...paging,
                  // showSizeChanger: false,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                size="middle"
              />
            
            )}
          </TabBranch>
          </div>
        
        </div>
      </div>
      <Modal
        title={
          <Row align="middle" justify="space-between">
            <p>
              {`${selectedWhBill?.billNumber} - ${get(
                selectedWhBill,
                'customerAddress.fullName'
              )}`}
            </p>
            <CustomerAndBillStatus
              whBill={selectedWhBill}
              // editable={isMatchPolicy}
              editable={false}
              updateWhBill={updateWhBill}
            />
          </Row>
        }
        className="wh-bill-details-modal"
        visible={selectedWhBill !== null}
        onCancel={onCloseWhBillModal}
        // style={{ top: 40 }}
        width="95%"
        footer={[
          get(selectedWhBill, 'prePay') &&
          get(selectedWhBill, 'status') === 'NEW' && (
            <WithPermission permission={POLICY.UPDATE_WHBILL}>
              <Button
                onClick={() => {
                  setInitPrepaymentValues({
                    prepaymentAmount: get(selectedWhBill, 'prePayAmount')
                  });
                  setIsOpenConfirmPrepaymentModal(true);
                }}
              // disabled={isConfirmPrepaymentLoading}
              >
                {get(selectedWhBill, 'isPrepayFull') ? "Đã nhận thanh toán" : "Đã nhận tạm ứng"}
              </Button>
            </WithPermission>
          ),

          // TODO: Not implemented. Discussing with BE team whether a wh-bill can have status \"CUSTOMER_CANNCEL\" or not.
          get(selectedWhBill, 'paymentMethod') === PAYMENT_METHOD.COD && get(selectedWhBill, 'status') === 'COMPLETED' && (
            <WithPermission permission={POLICY.UPDATE_WHUPDATEBILLSTATUS}>
              <Button
                onClick={() => {
                  // console.log(selectedWhBill,"selectedWhBill")
                  setIsOpenModalCustomerCancel(true)

                  // setIsOpenCustomerCancelWhBillModal(true);
                  // alert("Not implemented. Discussing with BE team whether a wh-bill can have status \"CUSTOMER_CANNCEL\" or not.");
                }}
                danger
                disabled={isCustomerCancelWhBillLoading}
              >
                Chấm dứt
              </Button>
            </WithPermission>
          ),

          get(selectedWhBill, 'status') !== 'CANCELLED' && (
            <WithPermission permission={POLICY.UPDATE_WHBILL}>
              <Button
                danger
                onClick={() => {
                  setInitCancelValues(null);
                  setIsOpenCancelModal(true);
                }}
                disabled={isSubmitLoading}
              >
                Hủy
              </Button>
            </WithPermission>
          ),

          isSubmitLoading ? (
            <Button disabled>Đóng</Button>
          ) : (
            <Button onClick={onCloseWhBillModal}>Đóng</Button>
          )
        ]}
      >
        <WhBillConsole
          cancelWhBillSuccess={cancelWhBillSuccess}
          confirmPrepaymentWhBillSuccess={confirmPrepaymentWhBillSuccess}
          customerCancelWhBillSuccess={customerCancelWhBillSuccess}
          handleCancelBill={handleCancelBill}
          // handleConfirmPrepayment={handleConfirmPrepayment}
          // isConfirmPrepaymentLoading={isConfirmPrepaymentLoading}
          handleCustomerCancel={handleCustomerCancel}
          initCancelValues={initCancelValues}
          initPrepaymentValues={initPrepaymentValues}
          isCancelBillLoading={isCancelBillLoading}
          isCustomerCancelWhBillLoading={isCustomerCancelWhBillLoading}
          isOpenCancelModal={isOpenCancelModal}
          isOpenConfirmPrepaymentModal={isOpenConfirmPrepaymentModal}
          isOpenCustomerCancelWhBillModal={isOpenCustomerCancelWhBillModal}
          isSubmitLoading={isSubmitLoading}
          onWhBillStatusChange={onWhBillStatusChange}
          setInitPrepaymentValues={setInitPrepaymentValues}
          setIsOpenCancelModal={setIsOpenCancelModal}
          setIsOpenConfirmPrepaymentModal={setIsOpenConfirmPrepaymentModal}
          setIsOpenCustomerCancelWhBillModal={setIsOpenCustomerCancelWhBillModal}
          updateWhBill={updateWhBill}
          whBill={selectedWhBill}
          customerAddress={selectedWhBill?.customerAddress}
          isShowAddress
        />
      </Modal>
      <ModalConfirm
        text="Xác nhận chấm dứt đơn hàng"
        textOk="Xác nhận"
        type="delete"
        loading={isCustomerCancelWhBillLoading}
        visible={isOpenModalCustomerCancel}
        onCancel={onCloseModalCustomerCancel}
        handleOk={() => handleCustomerCancel({ _id: selectedWhBill._id })}
      />
    </div>
  );
};

export default WhBills;
