import { get } from "lodash";
import moment from "moment";

export default function TableRenderWord(listProduct) {
    const styleP = "font-family: serif;margin : 0 auto;";
    const styleFlex = "width : 100% ; display : flex;flex-direction : column;align-items:center;justify-content:center;gap : 1px;"
    const styleTd = "border: 1px solid black;padding: 8px;";
    const styleTrHeader = "border-color : black ; border-style : solid ; border-width : 1;font-family: serif;padding : 5px 0;";
    let listItem = ""
    listProduct?.forEach((item, index) => {
      const date = (d) =>d ? moment(d).format("DD-MM-YYYY") : ""
      const time = (t) =>t ? moment(t).format("HH:mm:ss") : ""
      listItem += `<tr>
      <td style="${styleTd}"><p style="${styleP}">${index + 1}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'name', '')}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'quantity', 1)}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'status', '')}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'note', '')}</p></td>
      <td style="${styleTd}">
      <div style="${styleFlex}">
      <p style="${styleP}">${date(get(item, 'r_date', ''))}</p>
      <p style="${styleP}">${time(get(item, 'r_date', ''))}</p>
      </div>
      </td>
      <td style="${styleTd}">
      <div style="${styleFlex}">
      <p style="${styleP}">${date(get(item, 'p_date', ''))}</p>
      <p style="${styleP}">${time(get(item, 'p_date', ''))}</p>
      </div>
      </td>
    </tr>`
    }
    )
  return `<table style="border-collapse: collapse;font-family: serif;width : 100%;">
  <thead >
    <tr >
    <th style="${styleTrHeader}"><p style="font-family: serif;">STT</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Tên tài sản</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">SL</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Tình trạng</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Ghi chú</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Ngày xuất</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Ngày trả</p></th>
    </tr>
    </thead>
    ${listItem}
    </table>`
}
