import React from 'react';

import { Tag } from 'antd';
import { get } from 'lodash';

import { MAP_WH_VOUCHER_STATUS_TAG, WH_VOUCHER_STATUS } from '~/constants/defaultValue';

const WhVoucherStatusTag = ({ status }) => {
  return (
    <Tag color={get(MAP_WH_VOUCHER_STATUS_TAG[status || WH_VOUCHER_STATUS.CREATED], "color")}>
      {get(MAP_WH_VOUCHER_STATUS_TAG[status || WH_VOUCHER_STATUS.CREATED], "name")}
    </Tag>
  )
}

export default WhVoucherStatusTag;
