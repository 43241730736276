
import {
  useFailed,
  useSubmit,
  useSuccess,
  useFetch,
  useBranchIdSessionStorage
} from '~/hooks/utils';

import {
  updateNotifyAccounts,
  getNotifyAccounts
} from '~/redux/action' 


const getSelector = (key) => (state) => state.notificationBotManagement[key];
const loadingGetSelector = getSelector('isGetLoading');
const loadingUpdateSelector = getSelector('isUpdateLoading');

const notifyAccountsSelector = getSelector('notifyAccounts');
const getNotifyAccountsFailedSelector = getSelector('getNotifyAccountsFailed');
const updateNotifyAccountsFailedSelector = getSelector('updateNotifyAccountsFailed');
const updateNotifyAccountsSuccessSelector = getSelector('updateNotifyAccountsSuccess');

const useNotifyAccounts = () => {
  const [branchId] = useBranchIdSessionStorage();
  return useFetch({
    action: getNotifyAccounts,
    dataSelector: notifyAccountsSelector,
    failedSelector: getNotifyAccountsFailedSelector,
    loadingSelector: loadingGetSelector,
    payload : branchId
  })
}

const useUpdateNotifyAccounts = () => {
  useSuccess(updateNotifyAccountsSuccessSelector, 'Update thành công');
  useFailed(updateNotifyAccountsFailedSelector, 'Update thất bại');

  return useSubmit({
    loadingSelector: loadingUpdateSelector,
    action: updateNotifyAccounts
  })
}

export {
  useNotifyAccounts,
  useUpdateNotifyAccounts
}
