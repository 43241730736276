import React, { forwardRef, useState, useEffect, useImperativeHandle, useMemo } from 'react';

import { debounce, get, parseInt } from 'lodash';
import { Button, Col, DatePicker, Modal, Row, Space, Table } from 'antd';

import { EditableCell, EditableRow } from '~/components/WhAppointment/WhAppointmentsByBlockTable/components/EditableComponents';
import { floorFormatter } from '~/utils/helper';
import { formatNumberThreeCommaToFixed, ToastrCustom, vietnamMoment } from '~/hooks/utils';
import { addEndDateForData, convertDateToSubmit, useSelectBatch } from '~/hooks/warehouseVoucher';
import TooltipTitle from '~/components/Common/TooltipTitle';
import { InfoCircleOutlined, MedicineBoxOutlined, MinusCircleTwoTone } from '@ant-design/icons';
import { FORMAT_DATE_TIME, TYPE_VOUCHER_WAREHOUSE, TYPE_WAREHOUSE } from '~/constants/defaultValue';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import moment from 'moment';
import { BaseBorderBox } from '~/components/Common';
// import SearchProductList from './SearchProductList';
import { adapterSelectMaterial, adapterSelectProduct } from '~/hooks/warehouseVoucher/hookListProduct';
// import FindMeterial from '~/components/WhAppointment/WhAppointmentsByBlockTable/components/FindMeterial';
// import SelectBatch from './SelectBatch';
import TableConfirmProduct from './TableConfirmProduct';
import SearchProductList from './SearchProductList';
const layoutFull = {
  md: 24,
  sm: 24,
  xs: 24,
}

const TableListProducts = forwardRef(({
  dataSource,
  disabled,
  typeVoucher,
  isLoading,
  handleByPartner,
  id,
  handleViewByPartner,
  idPartnerSelected,
  setForm,
  supplierId,
  handleCreateByPartner
}, ref) => {
  console.log(supplierId,'supplierId');
  const [clonedDataSource, setClonedDataSource] = useState([]);
  // const [visibleModalSelectMeterial, setVisibleModalSelectMeterial] = useState(false);
  // const { visibleSelectBatch, onOpenModalSelectBatch, onCloseModalSelectBatch, dataSelectBatch } = useSelectBatch();
  // const onOpenModalSelectMeterial = () => {
  //   setVisibleModalSelectMeterial(true);
  // }
  // const onCloseModalSelectMeterial = () => {
  //   setVisibleModalSelectMeterial(false)
  // }
  // const onCancelAfterSelectMeterial = () => {
  //   onCloseModalSelectMeterial();
  //   onCloseModalSelectBatch();
  // }
  useEffect(() => {
    if (Array.isArray(dataSource) && dataSource?.length > 0) {
      setClonedDataSource(dataSource?.map((item, index) => ({
        ...item,
        key: index,
      })))
    };
  }, [dataSource]);

  useEffect(() => {
    if (!clonedDataSource?.length) {
      // Clear Supplier When CloneData Source is empty
      setForm({
        supplier: null
      })
    }
  }, [clonedDataSource])
  const canEditableRentDate = useMemo(() => {
    // disabled edit Rent Date because When create PHT will Auto get Rent Date
    if (typeVoucher === TYPE_VOUCHER_WAREHOUSE.PHT || disabled || handleByPartner) return false;
    return true
  }, [typeVoucher, disabled, handleByPartner])

  const addData = (newData, type) => {
    try {
      let data;
      switch (type) {
        case TYPE_WAREHOUSE.WORLDHEALTH:
          data = adapterSelectProduct(newData, typeVoucher);
          break;
        case TYPE_WAREHOUSE.CLINIC:
          data = adapterSelectMaterial(newData);
          break;

        default:
          break;

      };
      const isExist = clonedDataSource?.some(item => get(item, 'productId') === get(data, 'productId'));
      if (isExist) {
        return ToastrCustom("Đã tồn tại tài sản này trong phiếu", 'error');
      }
      setClonedDataSource([...clonedDataSource, { ...data, key: clonedDataSource?.length + 1 }]);
    } catch (error) {
      ToastrCustom("Có lỗi xảy ra", 'error');
    }
  }
  const removeData = (index) => {
    const newDataSource = clonedDataSource?.filter((data, i) => i !== index)
    setClonedDataSource(newDataSource)
  }

  const columns = [
    {
      ...(handleViewByPartner) ?
        null :
        {
          title: '',
          key: 'action',
          render: (text, record, index) => <Button type='text' onClick={() => removeData(index)}>
            <MinusCircleTwoTone />
          </Button>
        }
    },
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Tên tài sản',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      // editable: (record) => {
      //   return get(record, 'typeMedicalDeviceInventory') === TYPE_WAREHOUSE.CLINIC;
      // },
      align: 'center',
      width: 100,
      render: (item) => formatNumberThreeCommaToFixed(item || 1),
      component: 'InputNumberOnBlurCapture',
      required: true,
      max: (record) => {
        const totalUsed = clonedDataSource?.reduce((sum, cur) => {
          // Find All product Same productIdCommon to sum quantity used
          if (get(cur, 'productIdCommon') === get(record, 'productIdCommon')) {
            return sum + (parseInt(get(cur, 'exchangeValue', 1)) * parseInt(get(cur, 'quantity', 1)));
          }
          return sum;
        }, 0);
        const max = Math.floor((parseInt(get(record, 'availableTotal', 0)) - totalUsed + parseInt(get(record, 'quantity', 1))) / parseInt(get(record, 'exchangeValue', 1)));
        return max;
      },
    },
    {
      title: 'Tình trạng',
      dataIndex: 'status',
      key: 'status',
      editable: disabled ? false : true,
      component: 'Input',
      required: false
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      editable: disabled ? false : true,
      component: 'Input',
      required: false
    },
    {
      title: 'Ngày nhận',
      dataIndex: 'r_date',
      key: 'r_date',
      component: 'DatePicker',
      required: true,
      render: (item) => item && moment(item).format("MM-DD-YYYY HH:mm:ss")
    },
    {
      title: <TooltipTitle count={<InfoCircleOutlined />} content={"Ngày trả"} titleTooltip="Tất cả CCDC đều phải trả cùng 1 ngày." />,
      dataIndex: 'p_date',
      key: 'p_date',
      component: 'DatePicker',
      required: true,
      render: (item) => item && moment(item).format("MM-DD-YYYY HH:mm:ss")
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const tableProps = {
    components,
    columns: true ?
      columns.filter(col => !col.display || col.display !== "viewOnly")
        .map((col) => {
          if (!col.editable) {
            return col;
          }
          return {
            ...col,
            onCell: (record, rowIndex) => {
              const editable = typeof col.editable === "function" ? col.editable(record) : !!col.editable
              const max = typeof col.max === "function" ? col.max(record) : null;
              return {
                record,
                rowIndex,
                editable,
                dataIndex: col.dataIndex,
                title: col.title,

                component: col.component,
                required: col.required,
                max,
                handleSave,
              }
            },
          };
        }) : columns.filter(col => !col.display || col.display !== "editOnly"),

    // use cloned data source so that it can be submitted when complete
    dataSource: clonedDataSource
  }
  const handleSave = (row) => {
    const newData = [...clonedDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    const computedRow = {
      ...row,
    }
    newData.splice(index, 1, { ...item, ...computedRow });
    setClonedDataSource(newData);
  };
  const handleSelectEndDate = (date) => {
    setClonedDataSource(addEndDateForData(clonedDataSource, date))
  }
  // const FooterTable = () => { // DONT DELTE THIS
  //   return <Button
  //     onClick={onOpenModalSelectMeterial}
  //     style={{
  //       marginTop: 16,
  //       display: 'flex',
  //       alignItems: 'center',
  //     }}
  //     type='dashed'
  //     icon={<MedicineBoxOutlined />}
  //     autoCapitalize
  //   >
  //     Thêm nguyên vật liệu
  //   </Button>
  // }
  useImperativeHandle(ref, () => ({
    getDataListProduct() {
      return convertDateToSubmit(clonedDataSource);
    }
  }));
  return (
    <Space style={{ width: '100%' }} direction='vertical'>
      <Row gutter={8} justify='space-between'>
        {!id && ((handleByPartner && typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG) || (!handleByPartner && typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG)) &&
          <Col lg={16} {...layoutFull} >
            <BaseBorderBox title={"Danh sách CCDC/NVL đã xác nhận"}>
              <TableConfirmProduct 
                handleByPartner={handleByPartner}
                setForm={setForm}
                clonedDataSource={clonedDataSource}
                handleSelect={addData}
                whPartnerId={idPartnerSelected}
                supplierId={supplierId}

              />
            </BaseBorderBox>
          </Col>}
        {(!id || (!id && !handleByPartner)) 
          && typeVoucher === TYPE_VOUCHER_WAREHOUSE.PHT
          &&
        <Col lg={12} {...layoutFull} >
          <BaseBorderBox title={"Thiết bị đối tác đã mượn"}>
            <SearchProductList 
            handleCreateByPartner={handleCreateByPartner}
             typeVoucher={typeVoucher}
             clonedDataSource={clonedDataSource}
             handleSelect={addData}
             idPartnerSelected={idPartnerSelected}
             setForm={setForm}
             supplierId={supplierId}
            />
          </BaseBorderBox>
        </Col>}


        <Col span={24}>
          {canEditableRentDate ? <Row justify='end' align='middle' gutter={16}>
            <Row align='middle'>
              <Col>Ngày trả: </Col>
              <Col>
                <DatePicker
                  disabled={disabled}
                  showTime={{ format: "HH:mm" }}
                  onChange={(date) => { handleSelectEndDate(date) }}
                  placeholder='Chọn ngày trả'
                  disabledDate={(current) => current <= moment().startOf('day')}
                />
              </Col>
            </Row>
          </Row> : null}
          <BaseBorderBox title={`Danh sách tài sản ${typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG ? "bàn giao" : "hoàn trả"}`}>
            {isLoading ?
              <SkeletonTable columns={columns} rowCount={5} />
              : <Table
                {...tableProps}
                // footer={typeVoucher === TYPE_VOUCHER_WAREHOUSE.PBG && !handleByPartner && FooterTable}
                pagination={false}
                scroll={{ x: '100%' }}
                size="small"
              // summary={renderSummary}
              />}
          </BaseBorderBox>
        </Col>
      </Row>

      {/* <Modal footer={null} width={'auto'} onCancel={onCloseModalSelectMeterial} destroyOnClose visible={visibleModalSelectMeterial}> // DONT DELETE THIs
        <FindMeterial  onCancel={onCloseModalSelectMeterial} handleSave={handleSelectMaterial} />
      </Modal>
      <Modal title={"Chọn lô sử dụng"} footer={null} closable={false} width={400} onCancel={onCloseModalSelectBatch} destroyOnClose visible={visibleSelectBatch}>
        <SelectBatch onCancel={onCancelAfterSelectMeterial} handleSave={addData} data={dataSelectBatch} />
      </Modal> */}
    </Space>
  )
})

export default TableListProducts;
