export const GET_ACTIVITY_REFERRALS_REQUEST =
  'GET_ACTIVITY_REFERRALS_REQUEST';
export const GET_ACTIVITY_REFERRALS_SUCCESS =
  'GET_ACTIVITY_REFERRALS_SUCCESS';
export const GET_ACTIVITY_REFERRALS_FAILED = 'GET_ACTIVITY_REFERRALS_FAILED';

export const GET_ACTIVITY_REFERRAL_REQUEST = 'GET_ACTIVITY_REFERRAL_REQUEST';
export const GET_ACTIVITY_REFERRAL_SUCCESS = 'GET_ACTIVITY_REFERRAL_SUCCESS';
export const GET_ACTIVITY_REFERRAL_FAILED = 'GET_ACTIVITY_REFERRAL_FAILED';

export const CREATE_ACTIVITY_REFERRAL_REQUEST =
  'CREATE_ACTIVITY_REFERRAL_REQUEST';
export const CREATE_ACTIVITY_REFERRAL_SUCCESS =
  'CREATE_ACTIVITY_REFERRAL_SUCCESS';
export const CREATE_ACTIVITY_REFERRAL_FAILED =
  'CREATE_ACTIVITY_REFERRAL_FAILED';

export const UPDATE_ACTIVITY_REFERRAL_REQUEST =
  'UPDATE_ACTIVITY_REFERRAL_REQUEST';
export const UPDATE_ACTIVITY_REFERRAL_SUCCESS =
  'UPDATE_ACTIVITY_REFERRAL_SUCCESS';
export const UPDATE_ACTIVITY_REFERRAL_FAILED =
  'UPDATE_ACTIVITY_REFERRAL_FAILED';

export const DELETE_ACTIVITY_REFERRAL_REQUEST =
  'DELETE_ACTIVITY_REFERRAL_REQUEST';
export const DELETE_ACTIVITY_REFERRAL_SUCCESS =
  'DELETE_ACTIVITY_REFERRAL_SUCCESS';
export const DELETE_ACTIVITY_REFERRAL_FAILED =
  'DELETE_ACTIVITY_REFERRAL_FAILED';


export const RESET_ACTIVITY_REFERRAL_STATE = 'RESET_ACTIVITY_REFERRAL_STATE';
