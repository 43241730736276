import { Button, Divider, Form } from 'antd';
import { get, pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LANGUAGE } from '~/constants/defaultValue';
import { useSettingTutorialJobs, useUpdateSettingTutorialJob } from '~/hooks/SettingTutorialJob';
import LangSelect from '../Common/LangSelect';
import FormItem from './FormItem';

export default function FormSettingTutorialJob({ }) {
  const [language,setLanguage] = useState(LANGUAGE.VI);
  const [form] = Form.useForm();
  const [isSubmitLoading, onUpdate] = useUpdateSettingTutorialJob();
  const query = useMemo(() => ({raw : true}),[]);
  const [SettingTutorialJob,loading] = useSettingTutorialJobs(query);
  const id = useMemo(() => get(SettingTutorialJob,'_id'),[SettingTutorialJob]);

  const onFinish = useCallback((values) => {
    onUpdate({_id : id,...values});
  }, [id]);
  
  useEffect(() => {
      if (SettingTutorialJob) {
          form.setFieldsValue({ ...pick(SettingTutorialJob,['admin','client']) })
      }
  }, [SettingTutorialJob]);

  return (
    <Form labelCol={{ span: 7 }} form={form} onFinish={onFinish}>
    <LangSelect language={language} setLanguage={setLanguage}/>
      <Divider>Hướng dẫn cho nhân viên hệ thống</Divider>
      <FormItem language={language} form={form} name='admin'/>
      <Divider>Hướng dẫn cho ứng viên</Divider>
      <FormItem language={language} form={form} name='client'/>
      <Button shape='round' className='mt-4' loading={isSubmitLoading} htmlType='submit' type='primary'>
        Cập nhật
      </Button>
    </Form>
  )
}
