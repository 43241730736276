import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getRankingsFailed: null,
  getRankingFailed:null,
  Rankings: [],
  Ranking: null,
  isSubmitLoading : false,
  isGetRankingLoading: false,
  Ranking: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_RANKINGS_REQUEST:
      state.isLoading = true;
      state.getRankingsFailed = null;
      return;

    case Types.GET_RANKINGS_SUCCESS:
      state.isLoading = false;
      state.Rankings = payload.docs;
      state.getRankingsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_RANKINGS_FAILED:
      state.isLoading = false;
      state.Rankings = [];
      state.getRankingsFailed = payload;
      return;
    case Types.GET_RANKING_REQUEST:
      state.isGetRankingLoading = true;
      state.Ranking = null
      state.getRankingFailed = null;
      return;

    case Types.GET_RANKING_SUCCESS:
      state.isGetRankingLoading = false;
      state.Ranking = payload;
      state.getRankingFailed = null;
      return;

    case Types.GET_RANKING_FAILED:
      state.isGetRankingLoading = false;
      state.Ranking = null;
      state.getRankingFailed = payload;
      return;
    case Types.CREATE_RANKING_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_RANKING_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_RANKING_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_RANKING_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_RANKING_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.Ranking = get(payload,'data');
        return;
  
      case Types.UPDATE_RANKING_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_RANKING_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_RANKING_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_RANKING_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;
      case Types.RESET_RANKING_STATE_ACTION:
        state.createSuccess = null;
        state.updateSuccess = null;
        state.deleteSuccess = null;
        return;

    case Types.RESET_STORE:
    case Types.RESET_RANKING_STATE:
      return initState;

    default:
      return;
  }
}, initState);
