import { Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import api from '~/api';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { WH_APPOINTMENT_STATUS_IN_HISTORY, WH_BILL_STATUS, WH_BILL_STATUS_EN, WH_PAYMENT_METHOD_VI } from '~/constants/defaultValue';
import './index.scss';
import toastr from 'toastr';
import { get } from 'lodash';
import { formatter } from '~/utils/helper';
export default function WhBill({ id , keyword, statusBill}) {
  //Handle when data is not yet exits
    const customerId = id;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  //Handle when data is exits
  const [paging, setPaging] = useState({
    current: page || 1,
    pageSize: limit || 10,
    total: 0
  });

  const query = useMemo(
    () => ({
      page: page || 1,
      limit: limit || 10,
      customerId: customerId,
      keyword: keyword || null,
      status: statusBill || null
    }),
    [customerId, page, limit, keyword,statusBill]
  );
    const getData = async () => {
    try {
      setIsLoading(true);
      const res = await api.whBill.getByIdCustomer(query);
      const data = res?.docs;
      setData(data);
      setPaging({
        current: res?.page,
        pageSize: res?.limit,
        total: res?.totalDocs
      });
      setIsLoading(false);
    } catch (error) {
      toastr.error('Không lấy được dữ liệu');
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [id, page, limit, keyword, statusBill]);

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      dataIndex: 'stt',
      render: (text, record, index) => {
        return (paging?.current - 1) * paging?.pageSize + index + 1;
      }
    },
    {
      title: 'Mã đơn hàng',
      key: 'billNumber',
      dataIndex: 'billNumber',
      render: (value) => value
    },
    {
      title: 'ID dịch vụ',
      dataIndex: 'billItems',
      key: 'code',
      width: 100,
      render: (value) => value?.map((item) => item?.code)?.join(', ')
    },
    {
      title: 'Giá trị đơn hàng',
      key: 'grandTotal',
      dataIndex: 'grandTotal',
      align: 'right',
      render: (value) => value?.toLocaleString()
    },
    {
      title: 'Đã thanh toán và xác nhận',
      key: 'actualTotalAmountPaid',
      dataIndex: 'actualTotalAmountPaid',
      align: 'right',
      render: (value, record) =>
        formatter(
          value +
            get(record, 'actualPrepaymentAmount', 0) +
            get(record, 'totalReceiptVoucherBillAmount', 0) -
            get(record, 'actualPrepaymentAmount', 0) || 0
        )
    },

    {
      title: 'Tiền đã chi trả khách hàng',
      key: 'totalPaymentVoucherBillAmount',
      dataIndex: 'totalPaymentVoucherBillAmount',
      align: 'right',
      render: (text) => formatter(text) || 0
    },

    {
      title: 'Còn lại',
      key: 'remainingAmount',
      dataIndex: 'remainingAmount',
      align: 'center',
      render: (value) => value?.toLocaleString()
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (value) => (
        <span className={`bill-status ${value.toLowerCase()}`}>
          {WH_BILL_STATUS[value]}
        </span>
      )
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value) => moment(value)?.format('HH:mm DD/MM/YYYY')
    },
    {
      title: 'Ngày hoàn thành ĐH',
      key: 'completedTime',
      dataIndex: 'completedTime',
      render: (value, record) => [WH_BILL_STATUS_EN.CUSTOMER_CANCEL, WH_BILL_STATUS_EN.COMPLETED]?.includes(get(record, 'status')) && moment(value).format('HH:mm DD/MM/YYYY')
    },
    {
      title: 'Ngày tiếp nhận',
      key: 'confirmDate',
      dataIndex: 'confirmDate',
      render: (value, record) => value && moment(value).format('HH:mm DD/MM/YYYY')
    },

    {
      title: 'Phương thức thanh toán',
      key: 'paymentMethod',
      dataIndex: 'paymentMethod',
      align: 'center',
      render: (value) => <span style={{ marginRight: 5 }}>{WH_PAYMENT_METHOD_VI?.[value]}</span>
    }
  ];
  return isLoading ? (
    <SkeletonTable columns={columns} rowCount={3} />
  ) : (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{ ...paging, showTotal: (total) => `Tổng cộng ${total}` }}
      size="small"
      onChange={({ current, pageSize }) => {
        setPage(current);
        setLimit(pageSize);
      }}
      scroll={{ x: 'max-content' }}
    />
  );
}
