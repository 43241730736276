export const GET_CORE_VALUES_REQUEST =
    'GET_CORE_VALUES_REQUEST';
export const GET_CORE_VALUES_SUCCESS =
    'GET_CORE_VALUES_SUCCESS';
export const GET_CORE_VALUES_FAILED = 'GET_CORE_VALUES_FAILED';

export const GET_CORE_VALUE_REQUEST = 'GET_CORE_VALUE_REQUEST';
export const GET_CORE_VALUE_SUCCESS = 'GET_CORE_VALUE_SUCCESS';
export const GET_CORE_VALUE_FAILED = 'GET_CORE_VALUE_FAILED';

export const CREATE_CORE_VALUE_REQUEST =
    'CREATE_CORE_VALUE_REQUEST';
export const CREATE_CORE_VALUE_SUCCESS =
    'CREATE_CORE_VALUE_SUCCESS';
export const CREATE_CORE_VALUE_FAILED =
    'CREATE_CORE_VALUE_FAILED';

export const UPDATE_CORE_VALUE_REQUEST =
    'UPDATE_CORE_VALUE_REQUEST';
export const UPDATE_CORE_VALUE_SUCCESS =
    'UPDATE_CORE_VALUE_SUCCESS';
export const UPDATE_CORE_VALUE_FAILED =
    'UPDATE_CORE_VALUE_FAILED';


export const DELETE_CORE_VALUE_REQUEST =
    'DELETE_CORE_VALUE_REQUEST';
export const DELETE_CORE_VALUE_SUCCESS =
    'DELETE_CORE_VALUE_SUCCESS';
export const DELETE_CORE_VALUE_FAILED =
    'DELETE_CORE_VALUE_FAILED';


export const RESET_CORE_VALUE_STATE = 'RESET_CORE_VALUE_STATE';
export const RESET_CORE_VALUE_ACTION = 'RESET_CORE_VALUE_ACTION';
