import { useEffect, useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  createWhReceiptVoucher,
  createWhReceiptVoucherByWhPartner,
  deleteWhReceiptVoucher,
  getWhReceiptVoucher,
  getWhReceiptVouchers,
  resetWhReceiptVoucherState,
  updateWhReceiptVoucher,
  updateWhReceiptVoucherByWhPartner,
  confirmWhReceiptVoucher,
  approveWhReceiptVoucher,
  rejectWhReceiptVoucher,
  resetWhReceiptVoucherStateAction,
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

import { fromJSON } from '~/components/WhVoucher/parser';
import { LANGUAGE, WH_VOUCHER_ACTION_NAME, WH_VOUCHER_STATUS } from '~/constants/defaultValue';
import { get } from 'lodash';
import moment from 'moment';

const getSelector = key => state => state.whReceiptVoucher[key];

const loadingSelector = getSelector('isLoading');
const whReceiptVouchersSelector = getSelector('whReceiptVouchers');
const getWhReceiptVouchersFailedSelector = getSelector('getWhReceiptVouchersFailed');

const isGetWhReceiptVoucherLoadingSelector = getSelector('isGetWhReceiptVoucherLoading');
const whReceiptVoucherSelector = getSelector('whReceiptVoucher');
const getWhReceiptVoucherFailedSelector = getSelector('getWhReceiptVoucherFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

export const isSubmitByWhPartnerLoadingSelector = getSelector('isSubmitByWhPartnerLoading');
export const createByWhPartnerSuccessSelector = getSelector('createByWhPartnerSuccess');
export const createByWhPartnerFailedSelector = getSelector('createByWhPartnerFailed');

const isConfirmLoadingSelector = getSelector('isConfirmLoading');
const confirmSuccessSelector = getSelector('confirmSuccess');
const confirmFailedSelector = getSelector('confirmFailed');

const isApproveLoadingSelector = getSelector('isApproveLoading');
const approveSuccessSelector = getSelector('approveSuccess');
const approveFailedSelector = getSelector('approveFailed');

const isRejectLoadingSelector = getSelector('isRejectLoading');
const rejectSuccessSelector = getSelector('rejectSuccess');
const rejectFailedSelector = getSelector('rejectFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

export const updateByWhPartnerSuccessSelector = getSelector('updateByWhPartnerSuccess');
export const updateByWhPartnerFailedSelector = getSelector('updateByWhPartnerFailed');

const pagingSelector = getSelector('paging');

export const useWhReceiptVoucherPaging = () => useSelector(pagingSelector);

export const useWhReceiptVoucherQueryParams = (defaultBillNumber, defaultPage, modeCustomerCancel,useTime,defaultBranchIds,useLocalPagination) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const billNumber = query.get('billNumber') || defaultBillNumber;
  const code = query.get('code');
  const keyword = query.get('keyword');
  // const limit = query.get('limit') || 10;
  const serviceCode = query.get('serviceCode');
  const status = !useLocalPagination ? query.get('status') : null;
  const startDate = useTime ? query.get('startDate') || moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss") : null;
  const endDate = useTime ? query.get('endDate') || moment().endOf('month').format("YYYY-MM-DDTHH:mm:ss") : null;
  const managementArea = query.get('managementArea');
  const branchIds = defaultBranchIds || query.get('branchIds');
  const whCategory = query.get('whCategory') || null;

  const [page, setPage] = useState(defaultPage || query.get('page') || 1);

  useEffect(() => {
    if (defaultPage) {
      setPage(defaultPage);
    }
  }, [defaultPage]);

  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteWhReceiptVoucherSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      billNumber,
      code,
      keyword: keyword ,
      limit,
      page,
      serviceCode,
      modeCustomerCancel,
      status,
      startDate,
      endDate,
      managementArea,
      branchIds,
      whCategory,
    };
    return [queryParams, onTableChange];

    //eslint-disable-next-line
  }, [
    billNumber,
    code,
    deleteWhReceiptVoucherSuccess,
    keyword,
    limit,
    page,
    serviceCode,
    modeCustomerCancel,
    status,
    startDate,
    endDate,
    managementArea,
    branchIds,
    whCategory,
  ]);
};

export const useWhReceiptVouchers = query => {
  return useFetchByParam({
    action: getWhReceiptVouchers,
    loadingSelector,
    dataSelector: whReceiptVouchersSelector,
    failedSelector: getWhReceiptVouchersFailedSelector,
    param: query
  });
};

export const useCreateWhReceiptVoucher = (callback) => {
  // const callback = () => history.push('/wh-voucher');

  useSuccess(createSuccessSelector, 'Tạo mới phiếu thu thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhReceiptVoucher
  });
};

export const useCreateWhReceiptVoucherByWhPartner = () => {
  const history = useHistory();
  const callback = () => {};

  useSuccess(createByWhPartnerSuccessSelector, 'Tạo mới phiếu thu thành công', callback);
  useFailed(createByWhPartnerFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitByWhPartnerLoadingSelector,
    action: createWhReceiptVoucherByWhPartner
  });
};

export const useUpdateWhReceiptVoucher = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật phiếu thu thành công');
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhReceiptVoucher
  });
};

export const useUpdateWhReceiptVoucherByWhPartner = () => {
  useSuccess(updateByWhPartnerSuccessSelector, 'Cập nhật phiếu thu thành công');
  useFailed(updateByWhPartnerFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitByWhPartnerLoadingSelector,
    action: updateWhReceiptVoucherByWhPartner,
  });
};

export const useConfirmWhReceiptVoucher = () => {
  useSuccess(confirmSuccessSelector, `${WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.CONFIRMED][LANGUAGE.VI]} phiếu thu thành công`);
  useFailed(confirmFailedSelector);

  return useSubmit({
    loadingSelector: isConfirmLoadingSelector,
    action: confirmWhReceiptVoucher
  });
};

export const useApproveWhReceiptVoucher = () => {
  useSuccess(approveSuccessSelector, `${WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.APPROVED][LANGUAGE.VI]} phiếu thu thành công`);
  useFailed(approveFailedSelector);

  return useSubmit({
    loadingSelector: isApproveLoadingSelector,
    action: approveWhReceiptVoucher
  });
};

export const useRejectWhReceiptVoucher = () => {
  useSuccess(rejectSuccessSelector, `${WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.REJECT][LANGUAGE.VI]} phiếu thu thành công`);
  useFailed(rejectFailedSelector);

  return useSubmit({
    loadingSelector: isRejectLoadingSelector,
    action: rejectWhReceiptVoucher
  });
};

export const useDeleteWhReceiptVoucher = () => {
  useSuccess(deleteSuccessSelector, 'Xoá phiếu thu thành công');
  useFailed(deleteFailedSelector, 'Xoá phiếu thu thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhReceiptVoucher
  });
};

export const useWhReceiptVoucher = (id, muteOnFailed = false) => {
  return useFetchByParam({
    action: getWhReceiptVoucher,
    loadingSelector: isGetWhReceiptVoucherLoadingSelector,
    dataSelector: whReceiptVoucherSelector,
    failedSelector: getWhReceiptVoucherFailedSelector,
    param: id,
    muteOnFailed,
  });
};

export const useInitWhReceiptVoucher = whReceiptVoucher => {
  return useMemo(() => {
    if (!whReceiptVoucher) {
      return {
      };
    }

    const initValues = {
      ...fromJSON(whReceiptVoucher),
    };

    return initValues;
  }, [whReceiptVoucher]);
};

export const useResetWhReceiptVoucher = () => {
  useResetState(resetWhReceiptVoucherState);
};

export const useResetWhReceiptVoucherAction = () => {
  useResetState(resetWhReceiptVoucherStateAction);
};
