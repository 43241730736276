import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  useEmployees,
  useDeleteEmployee,
  useEmployeeQueryParams,
  useEmployeePaging,
  useUpdateEmployeeParams,
  useResetEmployee
} from '~/hooks';
import { Table, Tag, Popconfirm, Input, Button } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { CARD_STYLE } from '~/constants/defaultValue';
import FilterCity from '~/components/Common/FilterCity';
import EmployeeForm from './Form';
import TabBranch from '../Common/TabBranch';

const { Search } = Input;

const ColumnActions = ({
  employeeNumber,
  deleteEmployee,
  shouldShowDevider,
  onOpenForm
}) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.UPDATE_EMPLOYEE}>
        <p onClick={() => onOpenForm(employeeNumber)}>Sửa</p>
      </WithPermission>
      {shouldShowDevider && <p>|</p>}

      <WithPermission permission={POLICY.DELETE_EMPLOYEE}>
        <Popconfirm
          title="Bạn muốn xoá nhân viên này?"
          onConfirm={() => deleteEmployee(employeeNumber)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const Employees = () => {
  const [query, onPagingChange] = useEmployeeQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateEmployeeParams(
    query
  );
  const [employees, isLoading] = useEmployees(query);

  const [employeeId, setEmployeeId] = useState(null);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const paging = useEmployeePaging();

  const hasUpdate = useMatchPolicy(POLICY.UPDATE_EMPLOYEE);
  const hasDelete = useMatchPolicy(POLICY.DELETE_EMPLOYEE);
  const shouldShowDevider = hasUpdate && hasDelete;

  const numberOfEmployees = employees.length;

  const onDeleteSuccess = () => {
    const isDeletingLastItemInPage = numberOfEmployees === 1;
    const canDecreasePage = query.page > 0;

    if (isDeletingLastItemInPage && canDecreasePage) {
      onParamChange({ page: query.page - 1 });
    }
  };

  const [, deleteEmployee] = useDeleteEmployee(onDeleteSuccess);

  useResetEmployee();

  const onOpenForm = (id) => {
    setEmployeeId(id);
    setIsOpenForm(true);
  };

  const onCloseForm = () => {
    setEmployeeId(null);
    setIsOpenForm(false);
  };
  const columns = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'employeeNumber',
      key: 'employeeNumber'
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: 'Chứng chỉ',
      dataIndex: 'certification',
      key: 'certification',
      render: (certification) => <p>{certification[0]?.name}</p>
    },
    // {
    //   title: 'Dịch Vụ',
    //   key: 'speciality',
    //   dataIndex: 'speciality',
    //   render: (speciality) => (
    //     <div className="speciality-tags">
    //       {speciality.map((spec, index) => {
    //         return (
    //           <Tag color="blue" key={index}>
    //             {spec.name}
    //           </Tag>
    //         );
    //       })}
    //     </div>
    //   )
    // },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return (
          <ColumnActions
            {...record}
            deleteEmployee={deleteEmployee}
            shouldShowDevider={shouldShowDevider}
            onOpenForm={onOpenForm}
          />
        );
      }
    }
  ];

  return (
    <div className="page-content__main">
      <div className="page-content__left" style={CARD_STYLE}>
        <FilterCity
          value={query.cityId}
          onChange={(value) => onParamChange({ cityId: value })}
          isDisabled={isLoading}
        />
      </div>
      <div className="page-content__right" style={CARD_STYLE}>
      <TabBranch>
        <div className="page-wraper__header">
          <Search
            style={{ width: 300 }}
            placeholder="Tìm nhân viên"
            enterButton
            onSearch={() => onParamChange({ keyword })}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

          <WithPermission permission={POLICY.WRITE_EMPLOYEE}>
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={() => onOpenForm()}
            >
              Thêm mới
            </Button>
          </WithPermission>
        </div>
        {isLoading ? (
          <SkeletonTable
            columns={columns}
            rowCount={employees.length}
            pagination={paging}
          />
        ) : (
          <Table
            rowKey={(rc) => rc._id}
            columns={columns}
            dataSource={employees}
            onChange={onPagingChange}
            pagination={{
              ...paging,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
            size="middle"
          />
        )}
      </TabBranch>
      </div>
      <EmployeeForm isOpen={isOpenForm} onClose={onCloseForm} id={employeeId} />
    </div>
  );
};

export default Employees;
