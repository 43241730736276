export const GET_WH_APPOINTMENTS_REQUEST = 'GET_WH_APPOINTMENTS_REQUEST';
export const GET_WH_APPOINTMENTS_SUCCESS = 'GET_WH_APPOINTMENTS_SUCCESS';
export const GET_WH_APPOINTMENTS_FAILED = 'GET_WH_APPOINTMENTS_FAILED';

export const DELETE_WH_APPOINTMENT_REQUEST = 'DELETE_WH_APPOINTMENT_REQUEST';
export const DELETE_WH_APPOINTMENT_SUCCESS = 'DELETE_WH_APPOINTMENT_SUCCESS';
export const DELETE_WH_APPOINTMENT_FAILED = 'DELETE_WH_APPOINTMENT_FAILED';

export const CREATE_WH_APPOINTMENT_REQUEST = 'CREATE_WH_APPOINTMENT_REQUEST';
export const CREATE_WH_APPOINTMENT_SUCCESS = 'CREATE_WH_APPOINTMENT_SUCCESS';
export const CREATE_WH_APPOINTMENT_FAILED = 'CREATE_WH_APPOINTMENT_FAILED';

export const ADD_WH_APPOINTMENTS_LOCALLY = 'ADD_WH_APPOINTMENTS_LOCALLY';
export const REMOVE_WH_APPOINTMENTS_LOCALLY = 'REMOVE_WH_APPOINTMENTS_LOCALLY';

export const UPDATE_WH_APPOINTMENT_REQUEST = 'UPDATE_WH_APPOINTMENT_REQUEST';
export const UPDATE_WH_APPOINTMENT_SUCCESS = 'UPDATE_WH_APPOINTMENT_SUCCESS';
export const UPDATE_WH_APPOINTMENT_FAILED = 'UPDATE_WH_APPOINTMENT_FAILED';

export const CHECK_IN_WH_APPOINTMENT_REQUEST = 'CHECK_IN_WH_APPOINTMENT_REQUEST';
// export const CHECK_IN_WH_APPOINTMENT_SUCCESS = 'CHECK_IN_WH_APPOINTMENT_SUCCESS';
// export const CHECK_IN_WH_APPOINTMENT_FAILED = 'CHECK_IN_WH_APPOINTMENT_FAILED';

export const CHECK_OUT_WH_APPOINTMENT_REQUEST = 'CHECK_OUT_WH_APPOINTMENT_REQUEST';
// export const CHECK_OUT_WH_APPOINTMENT_SUCCESS = 'CHECK_OUT_WH_APPOINTMENT_SUCCESS';
// export const CHECK_OUT_WH_APPOINTMENT_FAILED = 'CHECK_OUT_WH_APPOINTMENT_FAILED';

export const GET_WH_APPOINTMENT_REQUEST = 'GET_WH_APPOINTMENT_REQUEST';
export const GET_WH_APPOINTMENT_SUCCESS = 'GET_WH_APPOINTMENT_SUCCESS';
export const GET_WH_APPOINTMENT_FAILED = 'GET_WH_APPOINTMENT_FAILED';

export const RESET_WH_APPOINTMENT_STATE = 'RESET_WH_APPOINTMENT_STATE';
export const RESET_LIST_PRODUCT_WH_APPOINTMENT_STATE = 'RESET_LIST_PRODUCT_WH_APPOINTMENT_STATE';
