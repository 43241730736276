import { get, omit } from 'lodash';
import requester from './requester';

const Location = {
  getDistanceToPartner : query => requester.post(`/wh-partner/matching-order-partner/${query?.partnerId}`, { data: omit(query, ['partnerId']) }), // Calculate the distance from partner A to partner B providing medical equipment in stock
  getDistanceToWareHouse : query => requester.post(`warehouse-address-partner/${query?.partnerId}`, { data: omit(query, ['partnerId']) }), // Calculate the distance from partner A to partner B providing medical equipment in stock
  getDistanceWorkSpacePartner : query => requester.post(`/wh-partner/me/matching-order-partner/${query?.partnerId}`, { data: omit(query, ['partnerId']) }), // Calculate the distance from partner A to partner B or warehouse in workspace
};

export default Location;
