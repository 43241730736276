export const GET_WH_SESSIONS_OF_DAY_REQUEST = 'GET_WH_SESSIONS_OF_DAY_REQUEST';
export const GET_WH_SESSIONS_OF_DAY_SUCCESS = 'GET_WH_SESSIONS_OF_DAY_SUCCESS';
export const GET_WH_SESSIONS_OF_DAY_FAILED = 'GET_WH_SESSIONS_OF_DAY_FAILED';

export const GET_WH_SESSION_OF_DAY_REQUEST = 'GET_WH_SESSION_OF_DAY_REQUEST';
export const GET_WH_SESSION_OF_DAY_SUCCESS = 'GET_WH_SESSION_OF_DAY_SUCCESS';
export const GET_WH_SESSION_OF_DAY_FAILED = 'GET_WH_SESSION_OF_DAY_FAILED';

export const CREATE_WH_SESSION_OF_DAY_REQUEST = 'CREATE_WH_SESSION_OF_DAY_REQUEST';
export const CREATE_WH_SESSION_OF_DAY_SUCCESS = 'CREATE_WH_SESSION_OF_DAY_SUCCESS';
export const CREATE_WH_SESSION_OF_DAY_FAILED = 'CREATE_WH_SESSION_OF_DAY_FAILED';

export const UPDATE_WH_SESSION_OF_DAY_REQUEST = 'UPDATE_WH_SESSION_OF_DAY_REQUEST';
export const UPDATE_WH_SESSION_OF_DAY_SUCCESS = 'UPDATE_WH_SESSION_OF_DAY_SUCCESS';
export const UPDATE_WH_SESSION_OF_DAY_FAILED = 'UPDATE_WH_SESSION_OF_DAY_FAILED';

export const DELETE_WH_SESSION_OF_DAY_REQUEST = 'DELETE_WH_SESSION_OF_DAY_REQUEST';
export const DELETE_WH_SESSION_OF_DAY_SUCCESS = 'DELETE_WH_SESSION_OF_DAY_SUCCESS';
export const DELETE_WH_SESSION_OF_DAY_FAILED = 'DELETE_WH_SESSION_OF_DAY_FAILED';

export const RESET_WH_SESSION_OF_DAY_STATE = 'RESET_WH_SESSION_OF_DAY_STATE';
