import { DeleteFilled, InfoCircleTwoTone, MedicineBoxOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, InputNumber, List, Modal, Popconfirm, Popover, Row, Select, Table, Tag, Tooltip, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get, multiply, omit, parseInt, pick } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import toastr from 'toastr';
import { CATEGORY_TYPE_CLINIC } from '~/constants/defaultValue';
import { infoDisabledAddMedical, infoDisabledBatch } from '~/hooks';
import { formatNumberThreeComma, formatNumberThreeCommaToFixed } from '~/hooks/utils';
import { checkIsUnitOne } from '~/hooks/wareHouse';
import CardInFoMedical from './CardInFoMedical';
import FindMeterial from './FindMeterial';
import SelectBatch from './SelectBatch';
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  component,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      if (dataIndex === 'quantity') {
        const { quantity } = values
        if (get(record, 'quantityOfBatchSelect') && parseInt(quantity) * parseInt(get(record, 'exchangeValue', 0)) > (parseInt(get(record, 'quantityOfBatchSelect', 0)))) {
          toggleEdit();
          return toastr.error("Số lượng vượt quá số lượng trong lô còn lại")
        }
      }
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `Vui lòng nhập ${title}`,
          },
        ]}
      >
        {
          {
            'Input': (
              <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            ),
            'InputNumber': (
              <InputNumber
                ref={inputRef}
                onPressEnter={save}
                onBlur={save}
                min={0}
                formatter={value => formatNumberThreeComma(value)}
              />
            ),
          }[component]
        }
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
/**
 * @param {Object} props
 * @param {Array} props.dataSource Data source of table material
 */
export default function TableMaterial({ dataSource, setDataSource, whServiceId, missingMaterialList }) {
  // const [count, setCount] = useState(0);
  // const disabledAddMedical = useMemo(() => infoDisabledAddMedical({groupInitMaterial}),[groupInitMaterial])
  const disabledBatch = useMemo(() => infoDisabledBatch({}), [])
  const [indexSelect, setIndexSelect] = useState(null);
  const [visibleModalSelectBatch, setVisibleModalSelectBatch] = useState(false);
  const [isOpenMissing, setIsOpenMissing] = useState(false);
  // const [visibleModalSelectMeterial, setVisibleModalSelectMeterial] = useState(false)
  const handleOpenModalSelectBatch = (index) => {
    setVisibleModalSelectBatch(true)
    setIndexSelect(index)
  }
  const handleCloseModalSelectBatch = () => {
    setVisibleModalSelectBatch(false)
    setIndexSelect(null)
  }
  const toggleMissing = () => {
    setIsOpenMissing(!isOpenMissing)
  }
  // const handleOpenModalSelectMeterial = (index) => {
  //   setVisibleModalSelectMeterial(true)
  //   setIndexSelect(index)
  // }
  // const handleCloseModalSelectMeterial = () => {
  //   setVisibleModalSelectMeterial(false)
  //   setIndexSelect(null)
  // }
  // const handleAdd = () => {
  //   const newData = {
  //     key: count,
  //     quantity: 1,
  //   };
  //   setDataSource([...dataSource, newData]);
  //   setCount(count + 1);
  // };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  /**
   * 
   * @param {Object} newData New data to set DataSource
   * @param {String} action Action of Select ['material','unit','batch'] , Only set Ref if action is material
   * @param {Number} index Index to Replace newData with oldData
   * @returns new DataSource
   */
  const saveDataBySelect = (newData, action, index = indexSelect) => {
    // Check data Exists
    const indexDataExist = dataSource?.findIndex(e => get(e, 'refId') === get(newData, 'refId'))
    if (indexDataExist !== -1) return toastr.error("Đã tồn tại")
    //
    const cloneData = [...dataSource];
    const item = cloneData[index];
    const newCloneData = { ...item, ...newData, ...action === 'material' && { ref: newData } };
    cloneData.splice(index, 1, newCloneData);
    setDataSource(cloneData);
  };
  const handleClearBatch = (index) => {
    const newData = [...dataSource];
    const cloneDataSelect = omit({ ...newData[index] }, ['lotNumber', 'batchId', 'quantityOfBatchSelect'])
    newData.splice(index, 1, cloneDataSelect);
    setDataSource(newData);
  }
  const handleDeleteRow = (index) => {
    const newData = [...dataSource];
    newData.splice(index, 1);
    setDataSource(newData);
  };

  // useEffect(() => {
  //   setCount(dataSource?.length)
  // }, [dataSource])
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const defaultColumns = [
    {
      title: "Nguyên vật liệu",
      dataIndex: 'product',
      key: 'product',
      render: (item, record) => {
        // return item ? get(item, 'name') : <Typography.Link onClick={() => handleOpenModalSelectMeterial(get(record, 'key'))}>{"(Chưa chọn thuốc)"}</Typography.Link>
        return <Row align='middle'>
          <Col>{get(item, 'name', '')}</Col>
          {!get(record, 'isInGroupInit') && <Col>
            <Tooltip zIndex={9999} title={<span>
              Thuốc đã được gắn nhưng cấu hình thuốc ở dịch vụ đã thay đổi, vui lòng kiểm tra lại!
              <Typography.Link onClick={() => window.open(`/wh-service/edit/${whServiceId}`)}>Xem thêm</Typography.Link>
            </span>}>
              <InfoCircleTwoTone twoToneColor={'red'} />
            </Tooltip>
          </Col>}
        </Row>
      },
    },
    {
      title: "Mã Nguyên vật liệu",
      dataIndex: 'variantCode',
      key: 'variantCode',
    },
    {
      title: "Lô",
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      align: 'center',
      render: (item, record, index) => {
        if (!get(record, 'refId')) return ''
        return <Tooltip zIndex={9999} title={disabledBatch.msg}>
          <span>
            <Typography.Link disabled={disabledBatch.status} onClick={() => handleOpenModalSelectBatch(get(record, 'key'))}>{!!item ? item : "(Chưa chọn lô)"}</Typography.Link>
            <p>{!!item && `(Tồn kho ${formatNumberThreeCommaToFixed(get(record, 'quantityOfBatchSelect', 0) / get(record, 'exchangeValue', 0), 1)})`}</p>
            <Button disabled={disabledBatch.status} onClick={() => handleClearBatch(index)}><DeleteFilled /></Button>
          </span>
        </Tooltip>
      }
    },
    {
      title: "Số lượng",
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10%',
      // editable: true,
      align: 'center',
      component: "InputNumber"
      // render : (item,record) => 1
    },
    {
      title: "Số lượng còn lại",
      dataIndex: 'availableQuantity',
      key: 'availableQuantity',
      align: 'center',
      render: (availableQuantity, record) => availableQuantity && formatNumberThreeCommaToFixed((availableQuantity / get(record, 'exchangeValue', 0) - get(record, 'quantity', 0)), 1) + " " + get(record, 'unit.name')
    },
    {
      title: "Đơn vị",
      dataIndex: 'unit',
      key: 'unit',
      width: '15%',
      align: 'center',
      render: (item, record, index) => {
        return get(item, 'name');

        // Check Is One Unit To Can Change Unit

        // const { isOneUnit, options } = checkIsUnitOne({ relationVariants: get(record, 'relationVariants'), })
        // return !isOneUnit ? get(item, 'name') : 
        // <Tooltip zIndex={9999} title={disabledBatch.msg}>
        //   <Select disabled={disabledBatch.status} onSelect={(value) => {
        //   const productFind = get(record, 'relationVariants', [])?.find(e => get(e, 'unitId') === value)
        //   const { productUnit } = productFind
        //   const newChangeProduct = { ...pick(productFind, ['exchangeValue', 'price', 'unitId', 'variantCode', 'cost']), unit: productUnit, quantity: 1 }
        //   saveDataBySelect(newChangeProduct, 'unit', index)
        // }} options={options} value={get(item, '_id')} />
        // </Tooltip>
        //   {/* <p>{`1 ${get(item,'name','')} = ${get(record,'exchangeValue',0)} ${get(record,'defaultUnit',0)}`}</p> */}


      }
    },
    {
      title: "Giá khấu hao",
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      render: (item, record) => formatNumberThreeComma(item)
    },
    {
      title: "Tổng giá khấu hao",
      dataIndex: 'sumPrice',
      key: 'sumPrice',
      align: 'center',
      render: (item, record) => formatNumberThreeComma(multiply(get(record, 'price'), get(record, 'quantity', 0))),
    },
    {
      title: "",
      dataIndex: '',
      key: '',
      align: 'center',
      // render: (item, record,index) => <MinusCircleTwoTone onClick={() => handleDeleteRow(index)} style={{cursor : 'pointer'}} twoToneColor="#DD2A10" />
      render: (item, record, index) => {
        // If not Have in group init Can Remove Them
        return !get(record, 'isInGroupInit') && <Tooltip zIndex={9999} title={disabledBatch.msg}>
          <Popconfirm
            disabled={disabledBatch.status}
            zIndex={10000}
            title="Bạn có chắc muốn xóa?"
            onConfirm={() => handleDeleteRow(index)}
          >
            <a style={{ fontSize: 20 }} className={`remove-action ${disabledBatch.status ? "text-secondary" : "text-danger"}`}>
              <i className="mdi mdi-close-circle-outline" />
            </a>
          </Popconfirm>
        </Tooltip>
      }
    },
  ]
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        component: col.component,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const renderSummary = pageData => {
    const totalGrandTotal = pageData.reduce((sum, curr) => {
      return sum + get(curr, 'ref.price', 0) * get(curr, 'quantity', 0)
    }, 0);

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell align="right" colSpan={8}>
          <h5>Tổng giá khấu hao: {formatNumberThreeCommaToFixed(totalGrandTotal)}</h5>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }

  
  return (
    <>
      {!!missingMaterialList?.length && <Alert
        message="Không tìm thấy đủ nguyên vật liệu trong kho của bạn!"
        description={<>
          <Typography.Link onClick={toggleMissing}>
            (Danh sách bị thiếu)
          </Typography.Link>
          {isOpenMissing && <List>
            {missingMaterialList?.map((item, i) => <List.Item key={i}>
              {get(item, 'name', '')} - {get(item, 'unit', '')}
            </List.Item>)}
          </List>}
        </>}
        type="error"
      />}

      <Table
        // summary={renderSummary}
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowClassName={() => 'editable-row'}
        scroll={{ x: 1500 }}
      />
      {/* <Row align='middle' justify='space-between'>
        <Col>  
      <Tooltip zIndex={9999} title={disabledAddMedical.msg}>
      <Button
          onClick={handleAdd}
          style={{
            marginTop: 16,
            display: 'flex',
            alignItems: 'center',
          }}
          type='dashed'
          icon={<MedicineBoxOutlined />}
          autoCapitalize
          disabled={disabledAddMedical.status}
        >
          Thêm thuốc
        </Button>
      </Tooltip>
        </Col>
        <Col>
          {groupInitMaterial?.length === 0 ? <Text type='danger'>Không được chọn nguyên vật liệu</Text> : <> <Text strong>Chỉ được chọn: </Text>{groupInitMaterial?.map(e =>
            <Popover zIndex={9999} placement='left' content={<CardInFoMedical
              amount={get(e, 'amount', 0)}
              note={get(e, 'note', '')}
            />}>
              <Tag color='blue'>{get(CATEGORY_TYPE_CLINIC, `[${get(e, 'category')}].vi`)}</Tag>
            </Popover>
          )}</>}


        </Col>
      </Row> */}
      <Modal closable={false} footer={null} onCancel={handleCloseModalSelectBatch} destroyOnClose visible={visibleModalSelectBatch}>
        <SelectBatch exchangeValue={get(dataSource[indexSelect], 'exchangeValue', 0)} quantityInput={get(dataSource[indexSelect], 'quantity', 0) * get(dataSource[indexSelect], 'exchangeValue', 0)} onCancel={handleCloseModalSelectBatch} handleSave={saveDataBySelect} listBatchs={get(dataSource[indexSelect], 'batches', [])} />
      </Modal>
      {/* MODAL FIND MATERIAL */}
      {/* <Modal footer={null} width={'auto'} onCancel={handleCloseModalSelectMeterial} destroyOnClose visible={visibleModalSelectMeterial}>
        <FindMeterial closeOnSave={true} groupInitMaterial={groupInitMaterial} onCancel={handleCloseModalSelectMeterial} handleSave={saveDataBySelect} />
      </Modal> */}
    </>
  )
}

