import {
  GET_EVALUATES_REQUEST,
  GET_EVALUATE_REQUEST,
  CREATE_EVALUATE_REQUEST,
  UPDATE_EVALUATE_REQUEST,
  DELETE_EVALUATE_REQUEST,
  RESET_EVALUATE_STATE
} from '~/constants/actionTypes';

export const getEvaluates = (query) => ({
  type: GET_EVALUATES_REQUEST,
  payload: query
});

export const getEvaluate = (id) => ({
  type: GET_EVALUATE_REQUEST,
  payload: id
});

export const createEvaluate = (evaluate) => ({
  type: CREATE_EVALUATE_REQUEST,
  payload: evaluate
});

export const updateEvaluate = (evaluate) => ({
  type: UPDATE_EVALUATE_REQUEST,
  payload: evaluate
});

export const deleteEvaluate = (id) => ({
  type: DELETE_EVALUATE_REQUEST,
  payload: id
});

export const resetEvaluateState = () => ({
  type: RESET_EVALUATE_STATE,
});
