import { get, omit } from 'lodash';
import requester from './requester';

const whPartner = {
  getWhPartners: query => requester.get('/wh-partner', query),
  getWhPartnersReport: query => requester.get('/wh-partner-area', query),
  getServiceByIds: params => requester.get(`/wh-partner/${params.id}/checkService`,params.query),
  getById: id => requester.get(`/wh-partner?partnerId=${id}&raw=true`),
  getRatingById: id => requester.get(`/wh-partner/${id}/rating`),
  getChildren: (id, query) => requester.get(`/wh-partner/${id}/child-partner`, query),
  getMatchingOrder: (query) => requester.get(`wh-partner/matching-order`, query),
  delete: id => requester.delete(`/wh-partner/${id}`),
  deleteService: whPartner => requester.delete(`/wh-partner/delete-service/${whPartner.id}`, {serviceId:whPartner.serviceId}),
  create: whPartner => requester.post('/wh-partner', whPartner),
  createService: whPartner => requester.post(`/wh-partner/service/${whPartner.id}`, {serviceId:whPartner.serviceId}),
  invite: ({ id, newWhPartner }) => requester.post(`/wh-partner/${id}/invite`, newWhPartner),
  update: whPartner => requester.put(`/wh-partner/${whPartner.id}`, whPartner),
  updateTips: whPartner => requester.put(`/wh-partner-tips/${whPartner.id}`, whPartner),
  updateService: whPartner => requester.put(`/wh-partner/update-service/${whPartner.id}`, whPartner.param),
  approve: whPartner => requester.post(`/wh-partner/${whPartner.id}/approve`, whPartner),
  refreshLink: id => requester.put(`/wh-partner/${id}/refresh`),
  getListDevideBorrowSystem: (query) => requester.get(`/wh-partner-manager-borrowed-devices/${get(query,'id')}`,omit(query,['id'])),
  updateWorkingTime: query => requester.put(`/wh-partner/${get(query,'id')}/working-time`,query),
  validateUsername: query => requester.post(`/wh-partner-valid-username`, query),
  getAllReferral: query => requester.get(`/wh-partner-referral/${get(query,'id')}`, omit(query, ['id'])),
  getAllEquipment: query => requester.get(`/wh-partner-equipment/${get(query,'id')}`, omit(query, ['id'])),
  convertToPartner: whPartner => requester.put(`/wh-partner/convert-branch/${whPartner.id}`, whPartner),
  getAll: () => requester.get('/wh-partner-search'),
};

export default whPartner;
