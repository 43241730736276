import { useEffect, useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  createWhPaymentVoucher,
  deleteWhPaymentVoucher,
  getWhPaymentVoucher,
  getWhPaymentVouchers,
  resetWhPaymentVoucherState,
  updateWhPaymentVoucher,
  confirmWhPaymentVoucher,
  approveWhPaymentVoucher,
  rejectWhPaymentVoucher,
  getWhPaymentVoucherNotBillId,
  resetWhPaymentVoucherStateAction,
  getWhPaymentVouchersCancel,
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

import { fromJSON } from '~/components/WhVoucher/parser';
import { LANGUAGE, WH_VOUCHER_ACTION_NAME, WH_VOUCHER_STATUS } from '~/constants/defaultValue';
import { get, head } from 'lodash';
import api from '~/api';
import moment from 'moment';
import { useUser } from '../user';

const getSelector = key => state => state.whPaymentVoucher[key];

const loadingSelector = getSelector('isLoading');
const whPaymentVouchersSelector = getSelector('whPaymentVouchers');
const getWhPaymentVouchersFailedSelector = getSelector('getWhPaymentVouchersFailed');

const loadingCancelSelector = getSelector('isLoadingCancel');
const whPaymentVouchersCancelSelector = getSelector('whPaymentVouchersCancel');
const getWhPaymentVouchersCancelFailedSelector = getSelector('getWhPaymentVouchersCancelFailed');

const isGetWhPaymentVoucherLoadingSelector = getSelector('isGetWhPaymentVoucherLoading');
const whPaymentVoucherSelector = getSelector('whPaymentVoucher');
const getWhPaymentVoucherFailedSelector = getSelector('getWhPaymentVoucherFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const isConfirmLoadingSelector = getSelector('isConfirmLoading');
const confirmSuccessSelector = getSelector('confirmSuccess');
const confirmFailedSelector = getSelector('confirmFailed');

const isApproveLoadingSelector = getSelector('isApproveLoading');
const approveSuccessSelector = getSelector('approveSuccess');
const approveFailedSelector = getSelector('approveFailed');

const isRejectLoadingSelector = getSelector('isRejectLoading');
const rejectSuccessSelector = getSelector('rejectSuccess');
const rejectFailedSelector = getSelector('rejectFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');
const pagingCancelSelector = getSelector('pagingCancel');

export const useWhPaymentVoucherPaging = () => useSelector(pagingSelector);
export const useWhPaymentVoucherCancelPaging = () => useSelector(pagingCancelSelector);

export const useWhPaymentVoucherQueryParams = (defaultBillNumber, defaultPage,useTime,defaultBranchIds,useLocalPagination) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();

  const billNumber = query.get('billNumber') || defaultBillNumber;
  const code = query.get('code');
  const keyword = query.get('keyword');
  // const limit = query.get('limit') || 10;
  const serviceCode = query.get('serviceCode');
  const status = !useLocalPagination ? query.get('status') : null;
  const startDate = useTime ? query.get('startDate') || moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss") : null;
  const endDate = useTime ? query.get('endDate') || moment().endOf('month').format("YYYY-MM-DDTHH:mm:ss") : null;
  const managementArea = query.get('managementArea');
  const branchIds = defaultBranchIds || query.get('branchIds');
  const [page, setPage] = useState(defaultPage || query.get('page') || 1);
  const whCategory = query.get('whCategory') || null;

  useEffect(() => {
    if (defaultPage) {
      setPage(defaultPage);
    }
  }, [defaultPage]);

  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteWhPaymentVoucherSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      billNumber,
      code,
      keyword: keyword || undefined,
      limit,
      page,
      serviceCode,
      status,
      startDate,
      endDate,
      managementArea,
      branchIds,
      whCategory,
    };

    return [queryParams, onTableChange];

    //eslint-disable-next-line
  }, [
    billNumber,
    code,
    deleteWhPaymentVoucherSuccess,
    keyword,
    limit,
    page,
    serviceCode,
    status,
    startDate,
    endDate,
    managementArea,
    branchIds,
    whCategory,
  ]);
};

export const useWhPaymentVouchers = query => {
  return useFetchByParam({
    action: getWhPaymentVouchers,
    loadingSelector,
    dataSelector: whPaymentVouchersSelector,
    failedSelector: getWhPaymentVouchersFailedSelector,
    param: query
  });
};

export const useWhPaymentVouchersCancel = query => {
  return useFetchByParam({
    action: getWhPaymentVouchersCancel,
    loadingSelector: loadingCancelSelector,
    dataSelector: whPaymentVouchersCancelSelector,
    failedSelector: getWhPaymentVouchersCancelFailedSelector,
    param: query
  });
};

export const useCreateWhPaymentVoucher = (callback) => {
  const history = useHistory();
  const callbackAll = () => {
    if(callback && typeof callback === 'function'){
      callback()
    }
    history.push('/wh-voucher');
  }

  useSuccess(createSuccessSelector, 'Tạo mới phiếu chi thành công', callbackAll);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhPaymentVoucher
  });
};

export const useUpdateWhPaymentVoucher = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật phiếu chi thành công');
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhPaymentVoucher
  });
};

export const useConfirmWhPaymentVoucher = () => {
  useSuccess(confirmSuccessSelector, `${WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.CONFIRMED][LANGUAGE.VI]} phiếu chi thành công`);
  useFailed(confirmFailedSelector);

  return useSubmit({
    loadingSelector: isConfirmLoadingSelector,
    action: confirmWhPaymentVoucher
  });
};

export const useApproveWhPaymentVoucher = () => {
  useSuccess(approveSuccessSelector, `${WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.APPROVED][LANGUAGE.VI]} phiếu chi thành công`);
  useFailed(approveFailedSelector);

  return useSubmit({
    loadingSelector: isApproveLoadingSelector,
    action: approveWhPaymentVoucher
  });
};

export const useRejectWhPaymentVoucher = () => {
  useSuccess(rejectSuccessSelector, `${WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.REJECT][LANGUAGE.VI]} phiếu chi thành công`);
  useFailed(rejectFailedSelector);

  return useSubmit({
    loadingSelector: isRejectLoadingSelector,
    action: rejectWhPaymentVoucher
  });
};

export const useDeleteWhPaymentVoucher = () => {
  useSuccess(deleteSuccessSelector, 'Xoá phiếu chi thành công');
  useFailed(deleteFailedSelector, 'Xoá phiếu chi thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhPaymentVoucher
  });
};

export const useWhPaymentVoucher = (params) => {
  return useFetchByParam({
    action: getWhPaymentVoucher,
    loadingSelector: isGetWhPaymentVoucherLoadingSelector,
    dataSelector: whPaymentVoucherSelector,
    failedSelector: getWhPaymentVoucherFailedSelector,
    param: params
  });
};
// export const useWhPaymentVoucherNotBillId = (id) => {
//     return useFetchByParam({
//       action: getWhPaymentVoucherNotBillId,
//       loadingSelector: isGetWhPaymentVoucherLoadingSelector,
//       dataSelector: whPaymentVoucherSelector,
//       failedSelector: getWhPaymentVoucherFailedSelector,
//       param: id
//     });
// };
export const useInitWhPaymentVoucher = (whPaymentVoucher) => {
  return useMemo(() => {
    if (!whPaymentVoucher) {
      return {
      };
    }

    const initValues = {
      ...fromJSON(whPaymentVoucher),
    };
    return initValues;
  }, [whPaymentVoucher]);
};


export const useGetNewIssueNumberPayment = (id) => {
  const [branchId] = useUser();
  const query = useMemo(() => ({ page: 1, limit: 1,branchIds : branchId,startDate : "2023-01-01",endDate : "2030-11-30" }), [id])
  const [issueNumber,setIsssueNumber] = useState('')
  useEffect(() => {
const fetch = async() => {
  try {
    const {docs : whPaymentVoucher} = await api.whPaymentVoucher.getWhPaymentVouchers(query)
    if(whPaymentVoucher?.length === 0) return setIsssueNumber('PC00001')
  const splitString = get(head(whPaymentVoucher),'code','')
  const Numbers = splitString.split("PC")
  if(Numbers[1]){
    const String = splitString.split(Numbers[1] || '')
    if(head(String)) setIsssueNumber(`${head(String)}${parseInt(Numbers[1])+1}`)
    
  }
  } catch (error) {
    console.log(error);
  }
}
fetch()
  },[query])
  return  issueNumber
}
export const useResetWhPaymentVoucher = () => {
  useResetState(resetWhPaymentVoucherState);
};

export const useResetWhPaymentVoucherAction = () => {
  useResetState(resetWhPaymentVoucherStateAction);
};
