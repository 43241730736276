import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getSelectors,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createKeywordSearch,
  deleteKeywordSearch,
  getKeywordSearch,
  getKeywordSearchs,
  resetKeywordSearchState,
  updateKeywordSearch
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.keywordSearch[key];
const pagingSelector = getSelector('paging');
export const useKeywordSearchPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetKeywordSearchSelector = getSelector('isGetKeywordSearchLoading');
const getKeywordSearchsFailedSelector = getSelector('getKeywordSearchsFailed');
const getKeywordSearchFailedSelector = getSelector('getKeywordSearchFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const KeywordSearchsSelector = getSelector('KeywordSearchs');
const KeywordSearchSelector = getSelector('KeywordSearch');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useKeywordSearchQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;

  const keyword = query.get('keyword');
  const priority = query.get('priority');
  const sortCountClick = query.get('sortCountClick');
  const sortPriority = query.get('sortPriority');

  const createKeywordSearchSuccess = useSelector(createSuccessSelector);
  // const updateKeywordSearchSuccess = useSelector(updateSuccessSelector);
  const deleteKeywordSearchSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      priority,
      sortCountClick,
      sortPriority
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    createKeywordSearchSuccess,
    // updateKeywordSearchSuccess,
    deleteKeywordSearchSuccess,
    keyword,
    priority,
    sortCountClick,
    sortPriority
  ]);
};

export const useUpdateKeywordSearchParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || PATH_APP.keywordSearch.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};
export const useKeywordSearchs = (query) => {
  return useFetchByParam({
    action: getKeywordSearchs,
    loadingSelector,
    dataSelector: KeywordSearchsSelector,
    failedSelector: getKeywordSearchsFailedSelector,
    param: query
  });
};
export const useKeywordSearch = (id) => {
  return useFetchByParam({
    action: getKeywordSearch,
    loadingSelector: loadinggetKeywordSearchSelector,
    dataSelector: KeywordSearchSelector,
    failedSelector: getKeywordSearchFailedSelector,
    param: id
  });
};
export const useCreateKeywordSearch = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo Phân loại thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createKeywordSearch
  });
};
export const useUpdateKeywordSearch = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật Phân loại thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateKeywordSearch
  });
};
export const useDeleteKeywordSearch = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Phân loại thành công', callback);
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteKeywordSearch
  });
};

export const useResetKeywordSearch = () => {
  useResetState(resetKeywordSearchState);
};
