export const GET_SETTING_TUTORIAL_JOBS_REQUEST =
  'GET_SETTING_TUTORIAL_JOBS_REQUEST';
export const GET_SETTING_TUTORIAL_JOBS_SUCCESS =
  'GET_SETTING_TUTORIAL_JOBS_SUCCESS';
export const GET_SETTING_TUTORIAL_JOBS_FAILED = 'GET_SETTING_TUTORIAL_JOBS_FAILED';

export const UPDATE_SETTING_TUTORIAL_JOB_REQUEST =
  'UPDATE_SETTING_TUTORIAL_JOB_REQUEST';
export const UPDATE_SETTING_TUTORIAL_JOB_SUCCESS =
  'UPDATE_SETTING_TUTORIAL_JOB_SUCCESS';
export const UPDATE_SETTING_TUTORIAL_JOB_FAILED =
  'UPDATE_SETTING_TUTORIAL_JOB_FAILED';


export const RESET_SETTING_TUTORIAL_JOB_STATE = 'RESET_SETTING_TUTORIAL_JOB_STATE';
