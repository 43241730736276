import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getWhCategoriesShort() {
  try {
    const data = yield call(Api.whCategory.getAll);
    yield put({ type: Types.GET_WH_CATEGORIES_SHORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_CATEGORIES_SHORT_FAILED,
      payload: error.message
    });
  }
}

function* getWhCategoryShort({ payload: id }) {
  try {
    const data = yield call(Api.whCategoryShort.getById, id);
    yield put({
      type: Types.GET_WH_CATEGORY_SHORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_WH_CATEGORY_SHORT_FAILED,
      payload: error.message
    });
  }
}

function* createWhCategoryShort(action) {
  try {
    const data = yield call(Api.whCategoryShort.create, action.payload);

    yield put({ type: Types.CREATE_WH_CATEGORY_SHORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_WH_CATEGORY_SHORT_FAILED,
      payload: error.message
    });
  }
}

function* updateWhCategoryShort(action) {

  try {
    const data = yield call(Api.whCategoryShort.update, action.payload);
    yield put({ type: Types.UPDATE_WH_CATEGORY_SHORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_CATEGORY_SHORT_FAILED,
      payload: error.message
    });
  }
}

function* deleteWhCategoryShort({ payload }) {
  try {
    yield call(Api.whCategoryShort.delete, payload);
    yield put({ type: Types.DELETE_WH_CATEGORY_SHORT_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_CATEGORY_SHORT_FAILED,
      payload: error.message
    });
  }
}

export default function* whCategoryShort() {
  yield takeLatest(Types.GET_WH_CATEGORIES_SHORT_REQUEST, getWhCategoriesShort);
  yield takeLatest(Types.GET_WH_CATEGORY_SHORT_REQUEST, getWhCategoryShort);
  yield takeLatest(
    Types.DELETE_WH_CATEGORY_SHORT_REQUEST,
    deleteWhCategoryShort
  );
  yield takeLatest(
    Types.CREATE_WH_CATEGORY_SHORT_REQUEST,
    createWhCategoryShort
  );
  yield takeLatest(
    Types.UPDATE_WH_CATEGORY_SHORT_REQUEST,
    updateWhCategoryShort
  );
}
