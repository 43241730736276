import { omit } from 'lodash';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';
const getOldListAccessPartner = state => state.partner.getListAccessPermissionPartner;
const getOldListAccessClinic = state => state.partner.getListAccessPermissionClinic;

function* getPartners({ payload: companyId }) {
  try {
    const data = yield call(Api.partner.getAll, companyId);
    yield put({ type: Types.GET_PARTNERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PARTNERS_FAILED,
      payload: error.message
    });
  }
}

function* getPartner({ payload }) {
  try {
    const partner = yield call(Api.partner.getById, payload);
    yield put({
      type: Types.GET_PARTNER_SUCCESS,
      payload: partner
    });
  } catch (error) {
    yield put({
      type: Types.GET_PARTNER_FAILED,
      payload: error.message
    });
  }
}

function* createPartner(action) {
  try {
    const data = yield call(Api.partner.create, action.payload);
    yield put({ type: Types.CREATE_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PARTNER_FAILED,
      payload: error
    });
  }
}

function* createBranchInPartner(action) {
  try {
    const data = yield call(Api.branch.create, action.payload);
    yield put({ type: Types.CREATE_BRANCH_IN_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_BRANCH_IN_PARTNER_FAILED,
      payload: error
    });
  }
}

function* updatePartner(action) {
  try {
    const data = yield call(Api.partner.update, action.payload);
    yield put({ type: Types.UPDATE_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PARTNER_FAILED,
      payload: error
    });
  }
}

function* updateBranchInPartner(action) {
  try {
    const data = yield call(Api.branch.update, action.payload);
    yield put({ type: Types.UPDATE_BRANCH_IN_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_BRANCH_IN_PARTNER_FAILED,
      payload: error.message
    });
  }
}

function* updateBranchStatus(action) {
  try {
    const data = yield call(Api.branch.update, action.payload);
    yield put({ type: Types.UPDATE_BRANCH_STATUS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.UPDATE_BRANCH_STATUS_FAILED, payload: error });
  }
}

function* deletePartner({ payload }) {
  try {
    yield call(Api.partner.delete, payload);
    yield put({ type: Types.DELETE_PARTNER_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_PARTNER_FAILED,
      payload: error.message
    });
  }
}

function* deleteBranchInPartner({ payload }) {
  try {
    yield call(Api.branch.delete, payload);
    yield put({ type: Types.DELETE_BRANCH_IN_PARTNER_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_BRANCH_IN_PARTNER_FAILED,
      payload: error
    });
  }
}

function* getBranches({ payload }) {
  try {
    const {typeGet} = payload;
    console.log(typeGet,'typeGet');
    let branches;
    switch (typeGet) {
      case 'BRANCH':
         branches = yield call(Api.partner.getBranches, payload);
        
        break;
      case 'CLINIC':
         branches = yield call(Api.partner.getClinic, payload);
        
        break;
    
      default:
        break;
    }
    yield put({ type: Types.GET_PARTNER_BRANCHES_SUCCESS, payload: branches });
  } catch (error) {
    yield put({
      type: Types.GET_PARTNER_BRANCHES_FAILED,
      payload: error.message
    });
  }
}

function* getListAccessPermissionPartner({ payload: query }) {
  try {
    const response = yield call(Api.partner.getListAccessPermissionPartner, query);
    yield put({ type: Types.GET_LIST_ACCESS_PERMISSION_PARTNER_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_LIST_ACCESS_PERMISSION_PARTNER_FAILED, payload: error });
  }
};

function* accessPermissionPartner({payload}) {
  try {
    const data = yield call(Api.partner.accessPermissionPartner, payload);
    const oldListAccess = yield select(getOldListAccessPartner);
    
    const newListAccess = {
      ...oldListAccess,
    }
    payload?.resources?.forEach((rs) => newListAccess[rs] = rs);

    yield put({ type: Types.GET_LIST_ACCESS_PERMISSION_PARTNER_SUCCESS, payload: newListAccess });
    yield put({ type: Types.ACCESS_PERMISSION_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.ACCESS_PERMISSION_PARTNER_FAILED, payload: error });
  }
}

function* denyPermissionPartner({payload}) {
  try {
    const data = yield call(Api.partner.denyPermissionPartner, payload);
    const oldListAccess = yield select(getOldListAccessPartner);
    const newListAccess = omit(oldListAccess,payload?.resources?.map((rs) => rs));
    yield put({ type: Types.GET_LIST_ACCESS_PERMISSION_PARTNER_SUCCESS, payload: newListAccess });
    yield put({ type: Types.DENY_PERMISSION_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.DENY_PERMISSION_PARTNER_FAILED, payload: error });
  }
}

function* getResourcePartner({ payload: partnerId }) {
  try {
    const data = yield call(Api.partner.getResourcePartner, partnerId);
    yield put({ type: Types.GET_RESOURCE_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_RESOURCE_PARTNER_FAILED,
      payload: error
    });
  }
};


function* getListAccessPermissionClinic({ payload: query }) {
  try {
    const response = yield call(Api.partner.getListAccessPermissionClinic, query);
    yield put({ type: Types.GET_LIST_ACCESS_PERMISSION_CLINIC_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_LIST_ACCESS_PERMISSION_CLINIC_FAILED, payload: error });
  }
};

function* accessPermissionClinic({payload}) {
  try {
    const data = yield call(Api.partner.accessPermissionClinic, payload);
    const oldListAccess = yield select(getOldListAccessClinic);
    
    const newListAccess = {
      ...oldListAccess,
    }
    payload?.resources?.forEach((rs) => newListAccess[rs] = rs);

    yield put({ type: Types.GET_LIST_ACCESS_PERMISSION_CLINIC_SUCCESS, payload: newListAccess });
    yield put({ type: Types.ACCESS_PERMISSION_CLINIC_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.ACCESS_PERMISSION_CLINIC_FAILED, payload: error });
  }
}

function* denyPermissionClinic({payload}) {
  try {
    const data = yield call(Api.partner.denyPermissionClinic, payload);
    const oldListAccess = yield select(getOldListAccessClinic);
    const newListAccess = omit(oldListAccess,payload?.resources?.map((rs) => rs));
    yield put({ type: Types.GET_LIST_ACCESS_PERMISSION_CLINIC_SUCCESS, payload: newListAccess });
    yield put({ type: Types.DENY_PERMISSION_CLINIC_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.DENY_PERMISSION_CLINIC_FAILED, payload: error });
  }
}

function* getResourceClinic({ payload: partnerId }) {
  try {
    const data = yield call(Api.partner.getResourceClinic, partnerId);
    yield put({ type: Types.GET_RESOURCE_CLINIC_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_RESOURCE_CLINIC_FAILED,
      payload: error
    });
  }
}
export default function* partner() {
  yield takeLatest(Types.GET_PARTNERS_REQUEST, getPartners);
  yield takeLatest(Types.GET_PARTNER_REQUEST, getPartner);
  yield takeLatest(Types.DELETE_PARTNER_REQUEST, deletePartner);
  yield takeLatest(Types.DELETE_BRANCH_IN_PARTNER_REQUEST, deleteBranchInPartner);
  yield takeLatest(Types.CREATE_PARTNER_REQUEST, createPartner);
  yield takeLatest(Types.CREATE_BRANCH_IN_PARTNER_REQUEST, createBranchInPartner);
  yield takeLatest(Types.UPDATE_PARTNER_REQUEST, updatePartner);
  yield takeLatest(Types.UPDATE_BRANCH_IN_PARTNER_REQUEST, updateBranchInPartner);
  yield takeLatest(Types.GET_PARTNER_BRANCHES_REQUEST, getBranches);
  yield takeLatest(Types.UPDATE_BRANCH_STATUS_REQUEST, updateBranchStatus);
  yield takeLatest(Types.GET_RESOURCE_PARTNER_REQUEST, getResourcePartner);
  yield takeLatest(Types.GET_LIST_ACCESS_PERMISSION_PARTNER_REQUEST, getListAccessPermissionPartner);
  yield takeLatest(Types.ACCESS_PERMISSION_PARTNER_REQUEST, accessPermissionPartner);
  yield takeLatest(Types.DENY_PERMISSION_PARTNER_REQUEST, denyPermissionPartner);
  yield takeLatest(Types.GET_RESOURCE_CLINIC_REQUEST, getResourceClinic);
  yield takeLatest(Types.GET_LIST_ACCESS_PERMISSION_CLINIC_REQUEST, getListAccessPermissionClinic);
  yield takeLatest(Types.ACCESS_PERMISSION_CLINIC_REQUEST, accessPermissionClinic);
  yield takeLatest(Types.DENY_PERMISSION_CLINIC_REQUEST, denyPermissionClinic);

}
