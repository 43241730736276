import {
  GET_TRANSACTIONS_REQUEST,
  TRANSACTIONS_SUCCESS,
  TRANSACTIONS_FAILED,
  GET_TRANSACTIONS_PARTNER_FAILED,
  GET_TRANSACTIONS_PARTNER_SUCCESS,
  GET_TRANSACTIONS_PARTNER_REQUEST
} from '~/constants/actionTypes';

export const getTransactions = (transactions) => ({
  type: GET_TRANSACTIONS_REQUEST,
  payload: transactions
});
export const getTransactionsPartner = (transactions) => ({
  type: GET_TRANSACTIONS_PARTNER_REQUEST,
  payload: transactions
});
