import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
// import WhCustomerManagementDetails from '../WhCustomerManagementDetails';
import { FilterDatePicker } from '~/components/Common';
import {
  Popconfirm,
  Modal,
  Form,
  Col,
  Row,
  Spin
} from 'antd';
import { Label } from 'reactstrap';
import './Info.scss';

import { compact, get, isEmpty } from 'lodash';
import LinkReferralCustomer from '~/components/Workspace/WhPartner/LinkReferralCustomer';
import api from '~/api';
import SkeletonInput from 'antd/lib/skeleton/Input';
import Text from 'antd/lib/typography/Text';
import { floorFormatter } from '~/utils/helper';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ChangeAddressForm from './ChangeAddressForm';
import toastr from 'toastr'
import { useDeleteWhCustomerManagementAddress, useUpdateWhCustomerManagementAddress, useWhCustomersManagementManyAddress } from '~/hooks';
import { useDispatch } from 'react-redux';
import { getWhCustomersManagementManyAddress } from '~/redux/action';
const FormItem = Form.Item;

const Information = ({ data, parent, isLoading = false, id }) => {
  const [form] = Form.useForm();
  const [reFeatch, setReFeatch] = useState(false);
  const query = useMemo(() => ({ customerAccountId: id }), [id,reFeatch]);
  const [addressCustomer, isLoadingAddress] = useWhCustomersManagementManyAddress(query); 
  const [addressAccount, setAddressAccount] = useState({});
  const [addressSuggest, setAddressSuggest] = useState([addressAccount]);
  const [isLoadingDelete, deleteAddress] = useDeleteWhCustomerManagementAddress();
  const [isLoadingUpdate ,updateAddress] = useUpdateWhCustomerManagementAddress();
  const [idAddress, setIdAddress] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [typeAddressDefault, setTypeAddressDefault] = useState(null);
  const [addressDefault, setAddressDefault] = useState({});
  const dispath = useDispatch();
  const handleDelete = (idDelete) => {
    if (!typeAddressDefault) {
      deleteAddress(idDelete)
      dispath(getWhCustomersManagementManyAddress(query));
      setReFeatch(true);
    } else {
      const body = {
        customerId: id,
        address: {}
      };
      const updateAddressAccount = async () => {
        try {
          const res = await api.whCustomerManagement.updateAddressAccount(body);
          setAddressAccount({});
          toastr.success('Xoá địa chỉ thành công');
        } catch (error) {
          console.log(error, 'error');
          toastr.error(error.message || 'Xoá địa chỉ thất bại');
        };
      };
      updateAddressAccount()
    };
  };
  const handleUpdate = (value) => {

    const updateAddressAccount = async (values) => { // Address in  database table customer_account
      try {
        const res = await api.whCustomerManagement.updateAddressAccount(values);
        setAddressAccount(res?.address);
        toastr.success('Cập nhật địa chỉ thành công');
      } catch (error) {
        toastr.error( error.message || 'Cập nhật địa chỉ thất bại');
      };
      };
    if (!typeAddressDefault) {
      updateAddress(value); // Address in  database table customer_address
    } else {
      const body = {
        ...value,
        customerId: id
      };
      updateAddressAccount(body);
    };
      setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setIdAddress(null);
  };

  useEffect(() => {
    setAddressAccount(data?.address || {});
  }, [data]);

  useEffect(() => {
    const isCheck = addressAccount ? isEmpty(addressAccount) : true;
    const addressDefault = {
      type: 'default',
      ...addressAccount
    };
    if (addressCustomer && !isCheck) {
      setAddressSuggest([addressDefault, ...addressCustomer]);
    }else if(addressCustomer && isCheck){
      setAddressSuggest(addressCustomer);
    };
  }, [addressCustomer, addressAccount]);

  return (
    <div className="wh-customer-management page-wraper hospital-form-page-content">
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          // onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <Row gutter={36}>
            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'customerIdFull']}
              >
                <Label className="label">Mã Khách hàng: </Label>
                <div>{data?.customerIdFull}</div>
              </div>
              <hr/>
            </Col>

            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'fullName']}
              >
                <Label className="label">Tên Khách hàng: </Label>
                <div>{data?.fullName}</div>
              </div>
              <hr/>
            </Col>
            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'fullName']}
              >
                <Label className="label">Loại khách hàng: </Label>
                <div readOnly>
                {get(data,'ranking.name','Thành viên')}
                </div>
              </div>
              <hr/>
            </Col>
            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'phoneNumber']}
              >
                <Label className="label">Mã giới thiệu: </Label>
                <div readOnly>
                {  data?.phoneNumber&& ("0"+ data?.phoneNumber.slice(3))}
                </div>
              </div>
              <hr/>
            </Col>

            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'customerIdFull']}
              >
                <Label className="label">Điểm thưởng: </Label>
                <Row>
        <Col span={12}>
          <span>Điểm thưởng hoàn tiền: <Text strong>{floorFormatter(get(data,'typePoint.resultNoCashOut',0))}</Text></span>
        </Col>
        <Col span={12}>
          <span>Điểm thưởng rút tiền: <Text strong>{floorFormatter(get(data,'typePoint.resultListCashOut',0))}</Text></span>
        </Col>
      </Row>
              </div>
              <hr/>
            </Col>
          </Row>
          <Row>
          <Col span={24}>
              <div
                className="content-field"
                name={['customer-account', 'address']}
              >
                <Label className="label" style={{width: '15%'}}>Địa chỉ: </Label>
                <div>
                  {isLoadingAddress ? <Spin/> : addressSuggest?.map((item)=> (
                      <Row style={{width: '100%'}}>
                        <Col span={18}>
                          {compact([item?.street,item?.ward,item?.district,item?.city]).join(", ")}
                        </Col>
                        <Col span={4}>
                          <Row align='center'>
                              <Col span={10}>
                              <EditOutlined
                                style={{ cursor: 'pointer', color: '#50a5f1' }}
                                onClick={() => {
                                  setIdAddress(item?._id);
                                  setIsOpen(true);
                                  setTypeAddressDefault(item?.type);
                                  setAddressDefault(item?.type ? item : {});
                                }}/>
                            </Col>
                            <Col span={10}>
                              <Popconfirm
                                title="Bạn có chắc chắn xoá địa chỉ này của khách hàng ?"
                                onConfirm={() => {
                                  handleDelete(item?._id);
                                }}
                                >
                                  <DeleteOutlined
                                  style={{ cursor: 'pointer', color: 'red' }}
                                  onClick={() => {
                                    setIdAddress(item?._id);
                                    setTypeAddressDefault(item?.type);
                                    setAddressDefault(item?.type ? item : {});
                                  }}/>
                                </Popconfirm>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))
                    }
                </div>
              </div>
              <hr/>
            </Col>
          </Row>
          <LinkReferralCustomer fullWidth customer={data}/>
          <h5>Thông tin người giới thiệu</h5>
          <br />
          <Col span={12}>
            <div
              className="content-field"
              name={['customer-account', 'customerIdFull']}
            >
              <Label className="label"> Tên người giới thiệu: </Label>
              <div>
              {isLoading ? <SkeletonInput active/> : <Text strong>{get(data?.infoParent,'fullName',get(data?.infoParent,'name',''))}</Text>}
              </div>
            </div>
            <hr/>
          </Col>

          <Col span={12}>
            <div
              className="content-field"
              name={['customer-account', 'phoneNumber']}
            >
              <Label className="label">Mã giới thiệu: </Label>
              <div readOnly>
              {isLoading ? <SkeletonInput active/> : <Text strong>{get(data?.infoParent?.referralLink,'parent','')}</Text>}
              </div>
            </div>
          </Col>
        </Form>
      </div>
      <Modal
        destroyOnClose
        title="Địa chỉ"
        visible={isOpen}
        onOk={() => {
          setIsOpen(false);
          setIdAddress(null);
          setTypeAddressDefault(null);
        }}
        onCancel={() => {
          setIsOpen(false);
          setIdAddress(null);
          setTypeAddressDefault(null);
        }}
        footer={null}
      >
        <ChangeAddressForm
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
          id={idAddress}
          isLoadingUpdate={isLoadingUpdate}
          typeAddressDefault={typeAddressDefault}
          addressDefault={addressDefault}
        />
      </Modal>
    </div>
  );
};

export default Information;
