import * as Types from '../../constants/actionTypes';
  
  export const getRewardItems = query => ({
    type: Types.GET_REWARD_ITEMS_REQUEST,
    payload: query
  });
  export const getRewardItem = id => ({
    type: Types.GET_REWARD_ITEM_REQUEST,
    payload: id
  });
  export const createRewardItem = instance => ({
    type: Types.CREATE_REWARD_ITEM_REQUEST,
    payload: instance
  });
  export const updateRewardItem = instance => ({
    type: Types.UPDATE_REWARD_ITEM_REQUEST,
    payload: instance
  });
  export const deleteRewardItem = id => ({
    type: Types.DELETE_REWARD_ITEM_REQUEST,
    payload: id
  });
  export const resetRewardItemState = () => ({
    type: Types.RESET_REWARD_ITEM_STATE
  });
  export const resetRewardItemStateAction = () => ({
    type: Types.RESET_REWARD_ITEM_STATE_ACTION
  });
