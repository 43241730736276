import { get } from 'lodash'
import React from 'react'
import { CONFIG_CAMPAIGN_CONDITION } from '~/constants/defaultValue'
import ItemProcessBoolean from './ItemProcessBoolean'
import ItemProcessRange from './ItemProcessRange'
import ItemProcessRepeatAction from './ItemProcessRepeatAction'
import ItemProcessWorth from './ItemProcessWorth'

export default function ItemProcess({ process }) {
    const layout = {
        left : {
            lg : 18,
            md: 24,
            sm : 24,
            xs : 24
        },
        right : {
            lg : 6,
            md: 24,
            sm : 24,
            xs : 24
        }
        
    }
    const typeProcess = get(process,'condition.type')
    return (
        <>
            {typeProcess === CONFIG_CAMPAIGN_CONDITION.REPEAT_ACTION && <ItemProcessRepeatAction layout={layout} process={process}/>  }
            {typeProcess === CONFIG_CAMPAIGN_CONDITION.BOOLEAN && <ItemProcessBoolean layout={layout} process={process}/>  }
            {typeProcess === CONFIG_CAMPAIGN_CONDITION.RANGE && <ItemProcessRange layout={layout} process={process}/>  }
            {typeProcess === CONFIG_CAMPAIGN_CONDITION.WORTH && <ItemProcessWorth layout={layout} process={process}/>  }
            {typeProcess === CONFIG_CAMPAIGN_CONDITION.REPEAT_TIME && <ItemProcessBoolean layout={layout} process={process}/>  }
        </>
    )
}
