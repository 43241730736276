import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getConfirmVoucherWarehouses({ payload: query }) {
  try {
    const data = yield call(Api.confirmVoucherWarehouse.getAll, query);
    yield put({ type: Types.GET_CONFIRM_VOUCHER_WAREHOUSES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_CONFIRM_VOUCHER_WAREHOUSES_FAILED,
      payload: error
    });
  }
}

function* getConfirmVoucherWhPartnerWarehouses({ payload: query }) {
  try {
    const data = yield call(Api.confirmVoucherWarehouse.getAllByIdPartner, query);
    yield put({ type: Types.GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_FAILED,
      payload: error
    });
  }
}

function* getConfirmVoucherWhPartnerWarehouse({ payload: id }) {
  try {
    const data = yield call(Api.confirmVoucherWarehouse.getOneByIdPartner, id);
    yield put({ type: Types.GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_FAILED,
      payload: error
    });
  }
}

function* getConfirmVoucherWarehouse({ payload }) {
  try {
    const equipment = yield call(Api.confirmVoucherWarehouse.getById, payload);
    yield put({
      type: Types.GET_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS,
      payload: equipment
    });
  } catch (error) {
    yield put({
      type: Types.GET_CONFIRM_VOUCHER_WAREHOUSE_FAILED,
      payload: error
    });
  }
}

function* createConfirmVoucherWarehouse(action) {
  try {
    const data = yield call(Api.confirmVoucherWarehouse.create, action.payload);
    yield put({ type: Types.CREATE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_CONFIRM_VOUCHER_WAREHOUSE_FAILED,
      payload: error
    });
  }
}

function* updateConfirmVoucherWarehouse({payload}) {
  try {
    const data = yield call(Api.confirmVoucherWarehouse.update, payload);
    yield put({ type: Types.UPDATE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_CONFIRM_VOUCHER_WAREHOUSE_FAILED,
      payload: error
    });
  }
}

function* deleteConfirmVoucherWarehouse({ payload }) {
  try {
    yield call(Api.confirmVoucherWarehouse.delete, payload);
    yield put({ type: Types.DELETE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_CONFIRM_VOUCHER_WAREHOUSE_FAILED,
      payload: error
    });
  }
}

function* changeStatusConfirmVoucherWarehouse({ payload }) {
  try {
    yield call(Api.confirmVoucherWarehouse.changeStatus, payload);
    yield put({ type: Types.CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_FAILED,
      payload: error
    });
  }
}
function* confirmConfirmVoucherWarehouse({ payload : id }) {
  try {
  const data =  yield call(Api.confirmVoucherWarehouse.confirm, id);
  console.log(data,'data');
    yield put({ type: Types.CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS, payload : data });
  } catch (error) {
    yield put({
      type: Types.CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_FAILED,
      payload: error
    });
  }
}
function* cancelConfirmVoucherWarehouse({ payload : id }) {
  try {
  const data =  yield call(Api.confirmVoucherWarehouse.cancel, id);
  console.log(data,'data');
    yield put({ type: Types.CANCEL_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS, payload : data });
  } catch (error) {
    yield put({
      type: Types.CANCEL_CONFIRM_VOUCHER_WAREHOUSE_FAILED,
      payload: error
    });
  }
}

export default function* partner() {
  yield takeLatest(Types.GET_CONFIRM_VOUCHER_WAREHOUSES_REQUEST, getConfirmVoucherWarehouses);
  yield takeLatest(Types.GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_REQUEST, getConfirmVoucherWhPartnerWarehouses);
  yield takeLatest(Types.GET_CONFIRM_VOUCHER_WAREHOUSE_REQUEST, getConfirmVoucherWarehouse);
  yield takeLatest(Types.DELETE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST, deleteConfirmVoucherWarehouse);
  yield takeLatest(Types.CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_REQUEST, changeStatusConfirmVoucherWarehouse);
  yield takeLatest(Types.CREATE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST, createConfirmVoucherWarehouse);
  yield takeLatest(Types.UPDATE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST, updateConfirmVoucherWarehouse);
  yield takeLatest(Types.CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_REQUEST, confirmConfirmVoucherWarehouse);
  yield takeLatest(Types.CANCEL_CONFIRM_VOUCHER_WAREHOUSE_REQUEST, cancelConfirmVoucherWarehouse);
  yield takeLatest(Types.GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_REQUEST, getConfirmVoucherWhPartnerWarehouse);
}
