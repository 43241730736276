import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Row, Col, Select, Button, Skeleton, Modal, TreeSelect } from 'antd';
import UploadImage from '~/components/Utils/UploadImage';
import {
  useCreateUserEmployee,
  useUserEmployee,
  useInitUserEmployee,
  useUpdateUserEmployee,
  useEmployeeGroups,
  useEmployees,
  useProfile,
  useUser,
  autoCreateUsername,
  useResetActionUserEmployee,
  useGroupCategories,
} from '~/hooks';
import './form.scss';
import { filterAcrossAccents } from '~/hooks/utils';
import { useParams } from 'react-router-dom';
import AddressFormSection from '~/components/Common/AddressFormSection';
import AccountFormSection from './Account';
import { GeoTreeSelect } from '~/components/Common';
import { toJSON } from '../parser';
import { get, last } from 'lodash';
import { RELATIVE_POSITION } from '~/constants/defaultValue';
import { removeAccents } from '~/utils/helper';
import api from '~/api';
import toastr from 'toastr';
import GeoTreeSelectWarehouse from '~/components/Common/GeoTreeSelectWarehouse';
const { Option } = Select;

const FormItem = Form.Item;

const UserEmployeeForm = ({ onClose, id }) => {
  const { id: branchId } = useParams();

  const [, token] = useUser();
  const [profile] = useProfile(token);

  const [loadingValidateUsername, setLoadingValidateUsername] = useState(false)
  const isRootAdmin = get(profile, 'isSuperAdmin') === true;

  const managementPaths = useMemo(() => {
    return get(profile, "managementArea")?.map(item => get(item, "path"));
  }, [profile]);

  const [form] = Form.useForm();
  const [logo, setLogo] = useState();

  const [isSubmitLoading, handleCreate] = useCreateUserEmployee(onClose);
  const [, handleUpdate] = useUpdateUserEmployee(onClose);

  const [userDetail, isGetUserEmployeeLoading] = useUserEmployee(id);
  const initUserEmployee = useInitUserEmployee(userDetail, id);

  const branchIdParam = useMemo(() => ({ branchId, limit: 10000 }), [branchId]);
  const [employeeGroups, isEmployeeGroupsLoading] = useEmployeeGroups(
    branchIdParam
  );
    console.log(employeeGroups,'employeeGroups');
  const [employess, isEmployeesLoading] = useEmployees(branchIdParam);

  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();
  const [warehouseControlSelect, setWarehouseControlSelect] = useState([]);
  const [groupCategories, isGroupCategoriesLoading] = useGroupCategories(branchIdParam);

  useEffect(() => {
    form.resetFields();
    const { avatar, address, warehouseControl } = initUserEmployee;
    setWarehouseControlSelect(warehouseControl?.length ? warehouseControl : [] );
    if (avatar) {
      setLogo(avatar);
    }

    if (address) {
      setCityCode(address.cityId);
      setDistrictCode(address.districtId);
    }
  }, [initUserEmployee, form]);

  useResetActionUserEmployee()

  const onFinish = (values) => {
    const { groups, ...rest } = values;
    const user = toJSON({
      ...rest,
      avatar: logo,
      branchId,
    });
    console.log(user,'user')

    if (id) {
      handleUpdate({
        ...user,
        warehouseControl: warehouseControlSelect?.length ? [...warehouseControlSelect] : [],
        _id: id,
        userNumber: initUserEmployee.userNumber,
        groups: groups
      });
    } else {
      handleCreate({
        ...user,
        warehouseControl: warehouseControlSelect?.length ? [...warehouseControlSelect] : [],
        groups: groups?.length ? groups : []
      });
    }
  };

  const onValuesChange = async ({ address, fullName, ...rest }) => {
    const shouldResetDistrictAndWards = address && address.cityId;
    if (shouldResetDistrictAndWards) {
      form.setFieldsValue({
        address: {
          districtId: null,
          wardId: null
        }
      });
    }

    const shouldResetWards = address && address.districtId;
    if (shouldResetWards) {
      form.setFieldsValue({
        address: {
          wardId: null
        }
      });
    }


  };

  const onFocusOutFullName = async () => {
    const fullName = form.getFieldValue('fullName');
    if (!id && fullName) { // Only Create 
      try {
        setLoadingValidateUsername(true)
        const username = await autoCreateUsername({ fullName, callApi: (query) => api.userEmployee.validateUsername(query) })
        form.setFieldsValue({ username: removeAccents(username?.toLowerCase()) })
        setLoadingValidateUsername(false)
      } catch (error) {
        setLoadingValidateUsername(false)
        toastr.error("Lỗi khi lấy dữ liệu từ máy chủ")
      }
    }
  }

  const onManagementAreaChange = (value, option) => {
    console.log(value);
    console.log(option);
  }

  const isLoading = isGetUserEmployeeLoading;

  return (
      <div className="user-form">
        <h4 style={{ marginRight: 'auto', paddingLeft: 27 }}>
          {`${!id ? 'Tạo mới ' : 'Cập nhật'}`} nhân viên
        </h4>

        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          scrollToFirstError
          requiredMark={false}
          initialValues={initUserEmployee}
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="user-form__logo-row"
          >
            <Col span={12}>
              <Row gutter={36}>
                <Col span={24}>
                  <FormItem
                    label="Tên nhân viên"
                    name="fullName"
                    rules={[
                      { required: true, message: 'Xin mời nhập tên nhân viên!' }
                    ]}
                  >
                    {isLoading || loadingValidateUsername ? <Skeleton.Input active /> : <Input onBlur={onFocusOutFullName} />}
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem label="Giới tính" name="gender">
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select>
                        <Option value="M" key="male">
                          Nam
                        </Option>
                        <Option value="F" key="female">
                          Nữ
                        </Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem label="Mô tả ngắn" name="description">
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col span={12} className="user-form__upload-logo">
              <UploadImage onChange={setLogo} imgUrl={logo} title="Avatar" />
            </Col>
          </Row>

          <AddressFormSection
            isLoading={isLoading}
            form={form}
            cityCode={cityCode}
            setCityCode={setCityCode}
            districtCode={districtCode}
            setDistrictCode={setDistrictCode}
          />

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label="Nhân viên" name="employeeNumber">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isEmployeesLoading}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  // rules={[
                  //   { required: true, message: 'Xin mời chọn nhân viên!' }
                  // ]}
                  >
                    {employess?.map(({ employeeNumber, fullName }) => (
                      <Select.Option
                        value={employeeNumber}
                        key={employeeNumber}
                      >
                        {fullName}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              {' '}
              <Form.Item label="Nhóm người dùng" name="groups">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    mode="multiple"
                    loading={isEmployeeGroupsLoading}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Xin mời chọn nhóm người dùng!'
                  //   }
                  // ]}
                  >
                    {employeeGroups?.map(({ _id, name }) => (
                      <Select.Option value={_id} key={_id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={48} align="middle" justify="start">
            <Col span={24}>
            <FormItem label="Nhóm dịch vụ" name="groupServices">
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    mode="multiple"
                    loading={isGroupCategoriesLoading}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {groupCategories?.map(({ _id, name }) => (
                      <Select.Option value={_id} key={_id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={48} align="middle" justify="start">
            <Col span={24}>
              <FormItem
                label="Khu vực quản lý"
                name={["managementArea"]}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                rules={[
                  {
                    required: true, // ALl Account must be have managementArea
                    message: 'Xin mời nhập khu vực quản lý!',
                  }
                ]}
              >
                {isLoading
                  ? <Skeleton.Input active />
                  : <GeoTreeSelect
                    autoClearSearchValue
                    checkablePositions={[RELATIVE_POSITION.IS_CHILD]}
                    disabled={!isRootAdmin && id === get(profile, "_id")}
                    enabledValues={isRootAdmin ? null : managementPaths}
                    labelInValue={true}
                    // listHeight={360}
                    listItemHeight={200}
                    multiple={true}
                    onSelect={onManagementAreaChange}
                    showCheckedStrategy={TreeSelect.SHOW_ALL}
                    showEnabledValuesOnly={true}
                    showSearch={true}
                    size="large"
                    treeCheckStrictly={true}
                    treeCheckable={true}
                    treeDefaultExpandedKeys={["1", "2", "3"]}
                  />
                }
              </FormItem>
            </Col>
          </Row>
          <Row gutter={48} align="middle" justify="start">
            <Col span={24}>
            <FormItem
                label="Kho quản lý"
                name={"warehouseControl"}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
            >
              <GeoTreeSelectWarehouse
                branchId={branchId}
                setWarehouseControlSelect={setWarehouseControlSelect}
                warehouseControlSelect ={warehouseControlSelect}
                />
              </FormItem>
            </Col>
          </Row>

          <AccountFormSection isLoading={isLoading} required={id ? false : true} />

          <Row className="user-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
          </Row>
        </Form>
      </div>
  );
};

export default UserEmployeeForm;
