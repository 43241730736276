export const GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_REQUEST =
  'GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_REQUEST';
export const GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_SUCCESS =
  'GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_SUCCESS';
export const GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_FAILED = 'GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_FAILED';

export const GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST = 'GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST';
export const GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS = 'GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS';
export const GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED = 'GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED';

export const CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST =
  'CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST';
export const CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS =
  'CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS';
export const CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED =
  'CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED';

export const UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST =
  'UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST';
export const UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS =
  'UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS';
export const UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED =
  'UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED';


export const RESET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_STATE = 'RESET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_STATE';
