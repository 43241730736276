import { Button, Col, Collapse, Divider, Form, Input, Radio, Row, Select, Space, Upload } from 'antd'
import { useEffect, useState } from 'react'
import { useCalculatorRotate, useConvertHighlight, useGetRotateFirst, useStyleAction, useWhHighlight } from '~/hooks/whHighlight'
import NavbarHighlight from './NavbarHighlight'
import ServiceItem from './ServiceItem'
import { get, isNil, transform } from 'lodash'
import Editor from '../Utils/Editor'
import { PlusCircleOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { setDataHighlight, updateHighlight } from '~/redux/action'
import UploadImage from '../Utils/UploadImage'
import { WithOrPermission, WithPermission } from '../Common'
import POLICIES from '~/constants/policy'
import { LANGUAGE, ROLE_HIGHTLIGHT_VI, ROLE_HIGHTLIGHT_EN } from '~/constants/defaultValue'
import { handleSubmit } from '~/hooks/utils'
const { Panel } = Collapse
const FormItemDetail = (active) => ({ language }) => <Form.Item hidden={language !== active} label={`Mô tả chi tiết ${String(language).toUpperCase()}:`} name={['detail', language]}><Editor /></Form.Item>
const formItemTitle = (active) => ({ language }) => <Form.Item hidden={language !== active} labelCol={{ lg: 6 }} wrapperCol={{ lg: 12 }} label={`Tiêu đề ${String(language).toUpperCase()}:`} name={['title', language]}><Input.TextArea rows={2} /></Form.Item>
const formShortTitle = (active) => ({ language }) => <Form.Item hidden={language !== active} labelCol={{ lg: 6 }} wrapperCol={{ lg: 24 }} label={`Mô tả ngắn ${String(language).toUpperCase()}:`} name={['shortTitle', language]}><Input.TextArea rows={2} /></Form.Item>
function Highlight({ setData, updateData }) {
    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [form3] = Form.useForm()
    const [formCreate] = Form.useForm()
    const [language, setLanguage] = useState(LANGUAGE.VI)
    const onSubmit = handleSubmit();

    const [dataHighlight, isLoading] = useWhHighlight()
    let [data_, vSetting, setPause, setVSetting] = useConvertHighlight(dataHighlight)

    const [active, setActive] = useState([]);
    const [styleAction] =useStyleAction({vSetting})
    const [rotateFirst] =useGetRotateFirst(data_)
    const [calculator] =useCalculatorRotate(data_,vSetting)

    const selectItemHighlight =(item,data)=>{
        if(item){
            let trust = true
            let newData =   data? {...data } : {...data_}
            for (const key in newData) {
                if(['v1','v2','v3'].includes(key)){
                    newData[key] = newData[key].map((value_)=>{
                        let value = {...value_}
                        if(value.keyId === item?.keyId && value?.active === true ){
                            value.active = false
                            formCreate.resetFields()
                            form2.resetFields()
                            trust = false
                        }
                        else {
                            value.active = value.keyId === item?.keyId
                        }
                        return value
                    })
                }
            }
            if(item?.keyId==='v0'){
                if(newData?.v0?.keyId === item?.keyId && newData?.v0.active===true){
                    newData.v0={...newData?.v0, active : false  }
                }else newData.v0 = {...newData?.v0, active: true , keyId : item?.keyId  }
            }
            setActive([item?.keyId?.split(/[-]/)[0]])
            setData({...newData})
            if(trust){
                let setDataNew = {
                  role: null,
                    ...item,
                }
                formCreate.setFieldsValue(setDataNew)
                form2.setFieldsValue(setDataNew)
            }
        }
    }
    const onSaveHighlight =(values)=>{
        const [_key] = values.keyId.split(/[-]/)
        let newData ={...data_}
        for (const key in newData) {
            if(key===_key){
                if(['v1','v2','v3'].includes(key)){
                    newData[key] =  newData[key].map((item_)=>{
                        let item = {...item_}
                        if(item.keyId===values.keyId){
                            item.shortTitle = values.shortTitle;
                            item.title =values.title;
                            item.detail=values.detail
                            item.role=values.role
                        }
                        return item
                    })
                }
                if(key==='v0'){
                    newData[key]= { ...newData[key],...values }
                }
            }
        }
        updateData({...newData})
    }

    const FormDetail = FormItemDetail(language);
    const FormTitle = formItemTitle(language);
    const FormShortTitle = formShortTitle(language);

    const addNewItem =(id)=>{
        if (id) {
            let data = { ...data_ }
            let [key, index] = data?.[id]?.at(-1)?.keyId?.split(/[-]/) ?? ['v1', '0']
            const lang = { vi: '', en: '' }
            const newItem = {
                keyId: id.concat('-').concat(parseInt(index ?? '0') + 1),
                size: 60,
                rotate: 0,
                shortTitle: { vi: 'Mẫu', en: 'Sample' },
                title: lang,
                detail: lang,
                image: '',
                backgroundCircle: '#3A7CFFFF',
                backgroundTitle: '#ffa000ff',
                role: null,
            }
            data[id] = [...data[id], newItem]
            selectItemHighlight(newItem, data)
        }
    }
    const deleteItem = ()=>{
        const  id = get(formCreate.getFieldsValue(),'keyId',false)
        const [key] = id.split(/[-]/)
        const index= data_?.[key].findIndex((item)=>item.keyId===id)
        if(index!==-1){
            data_[key] = data_[key].filter((value,i)=>i!==index )
            updateData({...data_})
            formCreate.resetFields()
            form2.resetFields()
        }
    }
    const onUpload =(url)=>{
        const  id = get(formCreate.getFieldsValue(),'keyId',false)
        const [_key] = id.split(/[-]/)
        let newData ={...data_}
        for (const key in newData) {
            if(key===_key){
                if(['v1','v2','v3'].includes(key)){
                    newData[key] =  newData[key].map((item_)=>{
                        let item = {...item_}
                        if(item.keyId===id){
                            item.image = url
                            formCreate.setFieldsValue(item)
                        }
                        return item
                    })
                }
                if(_key==='v0'){
                    newData[key] = {...newData[key] , image: url}
                    formCreate.setFieldsValue(newData[key])
                }
            }
        }
        setData({...newData})
        
    }

    return (
        <div className='page-wrapper page-content '>
            <div className='container-fluid highlight-page' >
                <Row align={'top'} gutter={48} className='highlight-content-container'>
                    <Col sm={{span:24}} md={{span:10}} lg={{span:6}} className={'highlight-content'} >
                        <Collapse className='highlight-content-collapse'
                            activeKey={active} 
                            onChange={(e)=>{ setActive([e.at(-1)])}}
                        >
                            <Panel header={'v0'} key={'v0'}>
                                <ul>
                                    <li
                                        className={`${data_?.v0?.active ? 'active' : ''}`}
                                        onClick={() => { selectItemHighlight(!!data_?.v0?.shortTitle ? data_?.v0 : { keyId: 'v0', shortTitle: { vi: 'Mẫu', en: '' }, title: { vi: '', en: '' }, detail: { vi: '', en: '' } }) }}
                                        key={data_?.v0?.keyId ?? 'v0'}
                                    >
                                        {!!data_?.v0?.shortTitle?.[language] ? get(data_?.v0?.shortTitle, language, data_?.v0?.shortTitle) : 'Thêm mới'}
                                    </li>
                                </ul>
                            </Panel>
                            {
                                transform(data_,(result,value,key)=>{
                                    if(['v1','v2','v3'].includes(key)){
                                        result.push({key,data:value})        
                                    }
                                }, [])
                                    .map((values) => (
                                        <Panel header={values.key} key={values.key}>
                                            <ul>
                                                {values?.data?.map((item) => (
                                                    <li
                                                        className={`${item.active ? 'active' : ''}`}
                                                        onClick={() => { selectItemHighlight(item) }}
                                                        key={item.keyId}>{get(item.shortTitle, language, item.shortTitle)}
                                                    </li>))}
                                                <li onClick={() => { addNewItem(values.key) }}><PlusCircleOutlined />Thêm phần tử</li>
                                            </ul>
                                        </Panel>
                                    ))
                            }
                        </Collapse>
                    </Col>
                    <Col sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 18 }} className={'highlight-content-input'}>
                        <Row justify="end" style={{ marginBottom: 20 }}>
                            <Radio.Group
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                            >
                                <Radio.Button className="wh-service-form__select-langue-btn" value={LANGUAGE.VI} children={['VI']} />
                                <Radio.Button className="wh-service-form__select-langue-btn" value={LANGUAGE.EN} children={['EN']} />
                            </Radio.Group>
                        </Row>
                        <Form
                            form={formCreate}
                            onFinish={onSaveHighlight}
                            onFinishFailed={onSubmit}
                            labelCol={{ lg: 5 }}
                            layout='vertical'
                        >
                            <Form.Item hidden={true} name={'keyId'}>
                                <Input/>
                            </Form.Item>
                            <Row gutter={10}>
                                <Col sm={{ span: 24 }} md={12}>
                                    <FormShortTitle language={'vi'} />
                                    <FormShortTitle language={'en'} />
                                </Col>
                                <Col sm={{ span: 24 }} md={12} >
                                    <Form.Item name={'image'} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <UploadImage
                                            disabled={!get(formCreate.getFieldsValue(), 'keyId', false)}
                                            imgUrl={formCreate.getFieldValue('image')}
                                            onChange={(e) => { onUpload(e) }}>
                                            <WithPermission permission={POLICIES.DELETE_HIGHLIGHT}>
                                                <Button htmlType='button'
                                                    disabled={!get(formCreate.getFieldsValue(), 'image', false)}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        onUpload('')
                                                    }}
                                                    size='small'
                                                    style={{
                                                        position: 'absolute',
                                                        top: 'auto',
                                                        left: '110%',
                                                    }
                                                    }>Xóa</Button>
                                            </WithPermission>
                                        </UploadImage>

                                    </Form.Item>
                                </Col>
                            </Row>

                            <FormTitle language={'vi'} />
                            <FormTitle language={'en'} />

                            <Form.Item label={'Loại :'} name={'role'}>
                                <Select style={{ width: 200 }} allowClear options={Object.keys({ vi: ROLE_HIGHTLIGHT_VI, en: ROLE_HIGHTLIGHT_EN }[language]).map((key) => ({
                                    label: { vi: ROLE_HIGHTLIGHT_VI, en: ROLE_HIGHTLIGHT_EN }[language][key],
                                    value: key
                                }))} />
                            </Form.Item>
                            <FormDetail language={'vi'} />
                            <FormDetail language={'en'} />
                            <Divider>
                                <Button.Group style={{ gap: "8px" }}>
                                    <WithOrPermission permission={[POLICIES.UPDATE_HIGHLIGHT, POLICIES.WRITE_HIGHLIGHT]}>
                                        <Button type='primary' htmlType='submit' disabled={isNil(formCreate.getFieldValue('keyId'))} className='submit-btn' >Lưu</Button>
                                    </WithOrPermission>
                                    <WithPermission permission={POLICIES.DELETE_HIGHLIGHT}>
                                        <Button type='default' htmlType='button' disabled={isNil(formCreate.getFieldValue('keyId'))} onClick={() => deleteItem()} className='submit-btn delete' >Xóa</Button>
                                    </WithPermission>
                                </Button.Group>
                            </Divider>
                        </Form>
                    </Col>                    
                </Row>

                <Row gutter={12} align='middle'  className='highlight-setting-container' >
                    <Col flex={1} >
                        <div className='setting-container'>
                            <div className='setting-container-main-content' onClick={() => { selectItemHighlight(data_?.v0 ?? { keyId: 'v0' }) }} style={{ width: get(vSetting?.v0, 'size', 100), height: get(vSetting?.v0, 'size', 100) }}>
                                <img alt='image' src={data_?.v0?.image ?? ''} style={{ visibility: !data_?.v0?.image ? 'hidden' : 'visible' }} ></img>
                                <div className='main-content--background'></div>
                            </div>

                            {
                                transform(data_,(obj,value,key)=>{if(['v1','v2','v3'].includes(key)){obj.push({value,key}) }},[])
                                .map((values)=>{
                                    return <div key={values.key} className={`service-item ${ !vSetting.pause ? 'animation':''}`}
                                    style={styleAction(values.key)}>
                                    <svg style={{ '--rotateFirst': `${rotateFirst(values.key)}deg` }}>
                                    {
                                            (()=>{
                                                const size= !isNaN(vSetting?.[values.key]?.size) ? (vSetting?.[values.key]?.size / 2) : 0
                                                return <circle cx={size } cy={size} r={size}
                                                style={{
                                                    strokeDasharray: (2 * 3.14 * (size)),
                                                    strokeDashoffset: calculator(values.key),
                                                }}
                                            />
                                            })()
                                        }
                                    </svg>

                                            {get(values, 'value', []).map((value) => (<ServiceItem
                                                key={value.keyId}
                                                data={value}
                                                animation={!vSetting.pause}
                                                onClick={selectItemHighlight}
                                                rotate={value.rotate}
                                                title={get(value.shortTitle, language, value.shortTitle)}
                                                active={value.active}
                                                sizeComponent={value.size}
                                                backgroundCircle={value.backgroundCircle}
                                                backgroundTitle={value.backgroundTitle} />))}
                                        </div>
                                    })
                            }
                        </div>
                    </Col>

                    <Col sm={{ span: 24 }} lg={{ span: 10 }} md={{ span: 24 }} span={24} style={{ textAlign: 'right' }} >
                        <NavbarHighlight
                            language={language}
                            form={{ form3, form, form2 }}
                            control={{ data_, vSetting }}
                            set={{ setVSetting, setData, setActive, setPause }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const mapToState = (state) => {
    return {

    }
}
const mapToDispatch = (dispatch) => {

    return {
        setData: (data) => dispatch(setDataHighlight(data)),
        updateData: (data) => dispatch(updateHighlight(data))
    }
}

export default connect(mapToState, mapToDispatch)(Highlight)
