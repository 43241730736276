import { Drawer } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH_APP } from '~/routes/paths';
import MenuGlass from './MenuGlass';
const WIDTH_DRAW = 280;
const Drag = ({onClose}) => {
  useEffect(() => {
  const dragElement = document.getElementById("draw-custom--drag");
  const Draw = document.querySelector(".draw-custom .ant-drawer-content");
  const DrawMask = document.querySelector(".draw-custom .ant-drawer-content-wrapper");
  const minWidth = 100;
  const maxWidth = 600;
  if(dragElement){
    let isDragging = false;
    const handleDrag = (e) => {
      if(isDragging) {
        if(e.clientX > minWidth && e.clientX < maxWidth){
          Draw.style.width = e.clientX + 'px';
          DrawMask.style.width = e.clientX + 'px';
        }
        if(e.clientX <= minWidth + 1) {
          return onClose()
        }
      }
    }
    dragElement.addEventListener('mousedown',() => {
      isDragging = true
      document.addEventListener('mousemove' , handleDrag);
      document.addEventListener('mouseup',() => {
        isDragging = false;
        document.removeEventListener('mousemove',handleDrag);
      })
    });
  
  
  }
  },[])
  return <div id='draw-custom--drag'>
    <i id='bars-drag' class="uil uil-bars"></i>
  </div>
}

 function DrawerNavbar({}) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [keyItem, setKeyItem] = useState([]); // Initial active key
  useEffect(() => {
    setKeyItem([history.location.pathname]);
  }, [history.location.pathname]);
  const toggleDrawer = () => {
    const Draw = document.querySelector(".draw-custom .ant-drawer-content");
    const DrawMask = document.querySelector(".draw-custom .ant-drawer-content-wrapper");
    if(Draw){ // set Initial Draw
      Draw.style.width = `${WIDTH_DRAW}px`;
      DrawMask.style.width = `${WIDTH_DRAW}px`;
      
    }
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer
        placement={'left'}
        closable={false}
        onClose={onClose}
        visible={open}
        className='draw-custom'
        maskStyle={{backgroundColor : 'transparent'}}
        width={WIDTH_DRAW}
        zIndex={1003} // Higher header
      >
      <Drag onClose={onClose}/>
      <MenuGlass keyItem={keyItem}/>
      </Drawer>
      {history.location.pathname !== PATH_APP.main.home &&
        <button
          type="button"
          className="btn btn-sm px-3 font-size-16  header-item waves-effect waves-light hover-menu"
          data-toggle="collapse"
          onClick={toggleDrawer}
          data-target="#topnav-menu-content"
        >
          <i className="fa fa-fw fa-bars" />
        </button>

      }

    </>
  )
}
export default memo(DrawerNavbar)
