import { LoadingOutlined } from '@ant-design/icons';
import { Row, Space, Steps } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react'
import api from '~/api';
import { MAX_VALUE_CONFIG_CAMPAIGN } from '~/constants/defaultValue';
import { useGetMyRanking } from '~/hooks/memberRanking';
import { formatNumberThreeComma } from '~/hooks/utils';
// import { floorFormatter } from '~/utils/helper';

export default function WhPartnerRanking({rankings,loading,idPartner}) {
  const {data:myRanking,isLoading} = useGetMyRanking(idPartner)
  let point = get(myRanking,'point',0)
  let id = get(myRanking,'_id')
  const findIndex = rankings?.findIndex(e => get(e,'_id') === id)
  return (
    <Space direction="vertical" style={{ width: '100%' }} size="small">
      <Row>
        <span>Cấp bậc thân thiết: <strong>{get(myRanking,'name','Chưa có')}</strong></span>
        <Steps
        labelPlacement='vertical'
        responsive
          current={isLoading ? -1 : findIndex}
          percent={isLoading ? 0 :point / get(myRanking,'endPoint') * 100}
        >
          {rankings?.map((e,i) => <Steps.Step icon={loading || isLoading ? <LoadingOutlined /> : null}  description={i === findIndex ? `Điểm hiện tại ${formatNumberThreeComma(point)}` : ''} title={get(e,'name','')} subTitle={get(e,'endPoint',0) === MAX_VALUE_CONFIG_CAMPAIGN ? '' : formatNumberThreeComma(get(e,'startPoint',0))}/>)}
        </Steps>
      </Row>
      <Row></Row>
    </Space>
  )
}
