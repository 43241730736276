import { CheckOutlined, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Table } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { WithPermission } from '~/components/Common';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { TYPE_TIPS, TYPE_TIPS_VI } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useCreateTipsPartner, useDeleteTipsPartner, useResetTipsPartner, useTipsPartner, useTipsPartnerPaging, useTipsPartnerQueryParams, useTipsPartners, useUpdateTipsPartner, useUpdateTipsPartnerParams } from '~/hooks/tipsPartner';
import { formatNumberThreeComma } from '~/hooks/utils';
const EditableContext = createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  indexAction,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     message: `${title} is required.`,
        //   },
        // ]}
      >
        {dataIndex !== 'money' ? <Input ref={inputRef} 
        onPressEnter={save} onBlur={save} 
          
        /> : <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} formatter={(value) => formatNumberThreeComma(value)}/>}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={() => {
          if(indexAction === record.key )toggleEdit()
        }}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const Tips = ({id}) => {
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(1);
  const [countTips, setCountTips] = useState(1);
  const [indexAction,setIndexAction] = useState(null)
  const [action,setAction] = useState(null)
  const [initValue,setInitValue] = useState(null)
  const [date,setDate] = useState(null)
  const [query] = useTipsPartnerQueryParams(id,date,10,1)
  const [tips,isLoading] = useTipsPartners(query)
  const [{ onParamChange }] = useUpdateTipsPartnerParams(query)
  const handleOnParamChange = (value) => {
    onParamChange({...value,customPathName:`/wh-partner/edit/${id}`})
  }
  const paging = useTipsPartnerPaging()
  useResetTipsPartner()
  useEffect(() => {
    if(id) {
      let count = 1
      let newTips = tips?.map(e => ({...e,key:count++}))
      setDataSource(newTips)
      setCount(count)
    }
  }, [id,tips]);
  const handleChangeAction = (action,key,record) => {
    setAction(action)
    setIndexAction(key)
    if(action === 'update'){
      setInitValue(record)
    }
  }
  const handleResetAction = () => {
    setAction(null)
    setIndexAction(null)
    setInitValue(null)
  }
  const [isSubmitLoading,handleCreate] = useCreateTipsPartner(handleResetAction)
  const [,handleEdit] = useUpdateTipsPartner(handleResetAction)
  const [,handleDelete] = useDeleteTipsPartner(handleResetAction)
  const handleCancel = () => {
    handleSave(initValue)
    handleResetAction()
  }
  const handleCancelCreate = () => {
    const newDataSource = [...dataSource]
    newDataSource.shift()
    setDataSource(newDataSource)
    handleResetAction()
  }
  const handleSubmitEdit = (record) => {
    const submitData = {...record,id : record._id}
    handleEdit(submitData)
  }
  const handleSubmitCreate= (record) => {
    const submitData = {...record,partnerId : id}
    handleCreate(submitData)
    
  }
  const defaultColumns = [
    {
      title: 'Loại',
      dataIndex: 'type',
      width: '10%',
      render : (text) => TYPE_TIPS_VI[text] || '',
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      width: '50%',
      editable: true,
    },
    {
      title: 'Số tiền',
      dataIndex: 'money',
      width: '10%',
      editable: true,
    },
    {
      title: 'Số lần',
      dataIndex: 'count',
      width: '5%',
      editable: true,
    },
    {
      title: 'Ngày áp dụng',
      dataIndex: 'date',
      width: '15%',
      render : (item,record,index) => <DatePicker allowClear={false} disabled={record.key !== indexAction} value={moment(item)} onChange={(date,dateString) => {
          const newData = [...dataSource];
      newData.splice(index, 1, {
        ...record,
        date : dateString
      });
      setDataSource(newData);
        }}/>
      
    },
    {
      title: 'Thao tác',
      key: 'action',
      dataIndex: 'action',
      width: '25%',
      render : (item,record,index) => {
        if(action === 'create'){
          if(record.key === indexAction){
            return <Row>
            <Col span={12}><Button disabled={record.content === '' || record.money === ''} loading={isSubmitLoading} onClick={() => handleSubmitCreate(record)} style={{borderColor : '#52c41a'}}><CheckOutlined style={{color : "#52c41a"}}/></Button></Col>
            <Col span={12}><Button onClick={handleCancelCreate}>Huỷ</Button></Col>
            </Row>
          }
        }
        else if(action === 'update'){
          if(record.key === indexAction){
            return <Row>
            <Col span={12}><Button disabled={record.content === '' || record.money === ''} loading={isSubmitLoading} type='primary' onClick={() => handleSubmitEdit(record)}>Lưu</Button></Col>
            <Col span={12}><Button onClick={handleCancel}>Huỷ</Button></Col>
               
               
            </Row>
            
          }
        }
        if(!!!action && !!!indexAction) return  <Row>
        <Col span={12}>
        <WithPermission permission={POLICIES.UPDATE_WHTIPS}>
        <Button onClick={() => handleChangeAction('update',record.key,record)}><EditTwoTone /></Button>
        </WithPermission>
        </Col>
        <Col span={12}>
        <WithPermission permission={POLICIES.DELETE_WHTIPS}>
        <Button onClick={() => handleDelete(record._id)}><DeleteTwoTone /></Button>
        </WithPermission>
        </Col>
        </Row>
       
       
        
      }
      
    },
  ];
  const handleAdd = (type) => {
    const newData = {
      key: 1,
      type: type,
      count :1,
      content: '',
      money: 0,
      date: moment().format('YYYY-MM-DD'),
    };

    let newDataSource = dataSource?.map(e => ({...e,key : e.key + 1}));

    setDataSource([newData,...newDataSource]);
    setCount(count + 1);
    setIndexAction(1)
    setAction('create')
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {...col,
        onCell : (record) => ({
          record,
          indexAction,
        })};
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        indexAction,
        handleSave,
      }),
    };
  });
  return (
    <div>
<WithPermission permission={POLICIES.WRITE_WHTIPS}>
    <>
    <Button
        onClick={() => handleAdd(TYPE_TIPS.reward)}
        disabled={action !== null}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
       Thêm thưởng
      </Button>
      <Button
       disabled={action !== null}
        onClick={() => handleAdd(TYPE_TIPS.punish)}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Thêm phạt
      </Button>
    </>
    </WithPermission>
      {isLoading ? <SkeletonTable columns={columns} rowCount={5}/> :  <Table
       className='tableTips'
      size='small'
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        onChange={({current}) => handleOnParamChange({page : current})}
        pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
      />}
     
      {/* <Row justify='center'>
      <Button onClick={handleSubmit} type='primary'>Cập nhật</Button>
      </Row> */}
    </div>
  );
};
export default Tips;
