import React, { useRef, useState, useEffect, useMemo } from 'react';

import DocViewer from "react-doc-viewer";
import createReport from 'docx-templates';
import moment from 'moment';
import readNumber from 'read-vn-number'
import { Button, Col, Collapse, DatePicker, Form, Input, InputNumber, Modal, Row, Skeleton, Space, Tabs, Typography , Select } from 'antd';
import { CheckOutlined, CloseCircleOutlined, ExclamationCircleOutlined, EyeOutlined, FileWordOutlined, PrinterOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { get, head, sumBy, uniq } from 'lodash';

import {
  useCreateWhPaymentVoucher,
  useInitWhPaymentVoucher,
  useResetWhPaymentVoucher,
  useUpdateWhPaymentVoucher,
  useConfirmWhPaymentVoucher,
  useApproveWhPaymentVoucher,
  useRejectWhPaymentVoucher,
  useWhPaymentVoucher,
  useGetNewIssueNumberPayment,
  useResetWhPaymentVoucherAction,
} from '~/hooks/whPaymentVoucher';

import POLICY from '~/constants/policy';
import api from '~/api';
import myFile from '~/assets/templates/PC_Template_V2.docx';
import { BaseBorderBox, DebounceSelect, WithPermission } from '~/components/Common';
import { COMPONENT_MODES, CURRENCY, CURRENCY_STRING, LANGUAGE, MAX_PAGINATION_LIMIT, TYPE_PAYMENT_VOUCHER_CUSTOMER, TYPE_PAYMENT_VOUCHER_CUSTOMER_VI, WH_BILL_STATUS, WH_PAYMENT_METHOD, WH_PAYMENT_VOUCHER_ACTION, WH_VOUCHER_ACTION_NAME, WH_VOUCHER_STATUS } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';

import { toJSON } from '../parser';
import AccountingDetails from '../AccountingDetailsTable/AccountingDetailsTable';

import './index.scss';
import { useMatchPolicy, useProfile } from '~/hooks';
import { floorFormatter } from '~/utils/helper';
import FormSettingDocs from '../components/FormSettingDocs';
import { capitalizeFirstLetter } from '~/hooks/utils';

import toastr from 'toastr';

import HistoryLogs from '~/components/WhBill/HistoryLog';
const FormItem = Form.Item;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Option } = Select;

const DEFAULT_ACCOUNT = 1111;
const mainRowGutter = 24;

// const mainColLayout = {
//   md: { span: 24 },
//   lg: { span: 12 },
//   xl: { span: 10 }
// };

// const verticalLayout = {
//   wrapperCol: { sm: 24, md: 12 },
//   labelCol: { sm: 24, md: 5, lg: 8 }
// };

const Printer = ({
  url,
}) => {


  const docs = [
    { uri: url },
    // { uri: "https://url-to-my-pdf.pdf" },
    // { uri: require("./example-files/pdf.pdf") }, // Local File
  ];
 
  return <DocViewer documents={docs} />;
}

const WhPaymentVoucherForm = ({
  onCancel,
  whPaymentVoucherId,
  whBill,
  whBillItem,
  noBillId, // chi lương,rút tiền
  whAppointment,
  additional,
  cancelBill, // exits when action is CANCEL
  totalMoneyCancel,
  blocks,
  isShowBlock,
}) => {
    const isHaveUpdateVoucher = useMatchPolicy(POLICY.UPDATE_WHVOUCHERS)
  const isHaveCreateVoucher = useMatchPolicy(POLICY.WRITE_WHVOUCHERS)
  const [form] = Form.useForm();
  const ref = useRef();
  const [profile] = useProfile()
  const [isSubmitLoading, handleCreate] = useCreateWhPaymentVoucher();
  const [, handleUpdate] = useUpdateWhPaymentVoucher();
  const [, handleConfirm] = useConfirmWhPaymentVoucher();
  const [, handleApprove] = useApproveWhPaymentVoucher();
  const [, handleReject] = useRejectWhPaymentVoucher();
  const id = whPaymentVoucherId;
  const query = useMemo(() => whBill ? null : ({id,noBillId}),[id,noBillId])
  const [whPaymentVoucher, isGetWhPaymentVoucherLoading] = useWhPaymentVoucher(query)
  const initWhPaymentVoucher = useInitWhPaymentVoucher(whPaymentVoucher);
  const issueNumber = useGetNewIssueNumberPayment(id)
  const [activeKey, setActiveKey] = useState(1);
  const [isPrinting, setIsPrinting] = useState(false);
  const [initCustomerAccounts, setInitCustomerAccounts] = useState([]);
  const [initStaffs, setInitStaffs] = useState([]);
  const [isOpenViewer, setIsOpenViewer] = useState(false);
  const [url, setUrl] = useState();
  const [appointments, setAppointments] = useState([]);
  const [settingDocs,setSettingDocs] = useState({
    name : 'CÔNG TY TNHH WORLDCARE MIỀN TRUNG',
    address:'559 Lê Văn Hiến, P. Khuê Mỹ, Q. Ngũ Hành Sơn, TP Đà Nẵng',
    isVisibleSettings: false,
  });
  const onCancelModalSetting = () => {
    setSettingDocs({...settingDocs,isVisibleSettings : false})
  }
  const onOkModalSetting = (data) => {
    setSettingDocs({...data,isVisibleSettings : false})
  }
  const onOpenSettingDocs = () => {
    setSettingDocs({...settingDocs,isVisibleSettings : true})
  }

  const checkIsPartnerAndNoWhBill = noBillId && !get(initWhPaymentVoucher,'customerAccount.isCustomer')
  useEffect(() => {
    const getInitCustomerAccount = async (customerId) => {
      try {
        if(checkIsPartnerAndNoWhBill){
          
          const partner = await api.whPartner.getById(customerId);
          setInitCustomerAccounts(get(partner,'docs').map(item => {
          return {
            ...item,
            label: `${get(item, "partnerCode")} - ${get(item, "name")}`,
          }
        }
        ));
        }
        else{
          const { data } = await api.whCustomerManagement.getById(customerId);
        setInitCustomerAccounts([data].map(item => {
          return {
            ...item,
            label: `${get(item, "customerIdFull")} - ${get(item, "fullName")}`,
          }
        }
        ));
        }
      } catch (error) {
        console.error(error);
      }
    }
    const getInitStaff = async (employeeId) => {
      try {
        const response = await api.userEmployee.getById(employeeId);
        setInitStaffs([get(response,'data')]);
      } catch (error) {
        console.error(error);
      }
    }

    form.resetFields();

    let { customerId, employeeId } = initWhPaymentVoucher;

    if (customerId) getInitCustomerAccount(customerId);
    if (employeeId) {
      getInitStaff(employeeId);
    }
    else{
      getInitStaff(profile._id);
    }
  }, [
    initWhPaymentVoucher,
    form,
    id,
    noBillId,
    profile
  ]);

  // Check for the id, if the id is provided, this form is to display an existing wh-voucher.
  // Otherwise this form is to create a new wh-voucher,
  // then use the provided whBill and whBillItem to prefill some fields in the form.
  useEffect(() => {
    const getCustomerAccount = async (customerAccountId) => {
      try {
        const { data } = await api.whCustomerManagement.getById(customerAccountId);
        const { customerIdFull, fullName } = data; // customerId là Mã Khách Hàng theo số tự nhiên
        form.setFieldsValue({
          // ở phiếu thu chi wh-voucher thì Mã Khách Hàng lưu ở `customerNumber`
          customerNumber: {
            value: customerIdFull,
            label: `${customerIdFull} - ${fullName}`,
          },
          customerName: fullName,
        })
      } catch (error) {
        console.error("error...");
        console.error(error);
      }
    }

    let reason = 'Chi trả đơn hàng ';

    if (id && initWhPaymentVoucher) {
      const { accountingDetails } = initWhPaymentVoucher;
      setDataSource(accountingDetails);
    } else {
      if (whBill) {
        const {
          customerAddress,
          customerAddressId,
          customerId,
        } = whBill;

        getCustomerAccount(customerId);

        form.setFieldsValue({
          accountingDate: get(whBill, "createdAt") ? moment(get(whBill, "createdAt")) : null,
          customerAddress,
          customerAddressId,
          customerId,
          employeeId:profile._id,
          // customerName: get(customerAddress, "fullName"),
          /* customerNumber: {
            value: customerId,
            label: customerId.substring(customerId.length - 4, customerId.length),
          }, */
          dateOfIssue: get(whBill, "createdAt") ? moment(get(whBill, "createdAt")) : null,
        });
      }

      if (whBillItem) {
        const whServiceCode = get(whBillItem, "snapshotService.code") || "";
        const whServiceName = (get(whBillItem, "snapshotService.detailName.vi") ? `${get(whBillItem, "snapshotService.name.vi")} - ${get(whBillItem, "snapshotService.detailName.vi")}` : get(whBillItem, "snapshotService.name.vi") ) || "";
        reason += `${get(whBill, "billNumber")}, dịch vụ ${whServiceCode} - ${whServiceName}`
      } 
      if(whAppointment){
        reason += `, cuộc hẹn ${get(whAppointment, "code",'')}`
      }

      form.setFieldsValue({ reason });
    }
  }, [
    id,
    initWhPaymentVoucher,
    whBill,
    whBillItem,
  ]);

  // use initWhPaymentVoucher to merge with other data that should be fetched from the API
  const mergedInitWhPaymentVoucher = useMemo(() => {
    if (!id) {
      return {
        ...initWhPaymentVoucher,
        originalDocument: 0,
      }
    } else {
      // do nothing
    }
    return {
      ...initWhPaymentVoucher,
      arrAppointmentCancel: []
    };
  }, [
    id,
    initWhPaymentVoucher,
  ]);

  useEffect(() => {
    form.setFieldsValue(mergedInitWhPaymentVoucher);
    form.setFieldsValue({paymentMethod: mergedInitWhPaymentVoucher?.paymentMethod});
    if (id) {
      const arrAppointmentCancel = initWhPaymentVoucher?.arrAppointmentCancel;
      const newArrAppointmentCancel = arrAppointmentCancel?.map(item => item?.id)
      form.setFieldsValue({
        arrAppointmentCancel: newArrAppointmentCancel
      });
      setAppointments(arrAppointmentCancel);
    };
  }, [form, mergedInitWhPaymentVoucher,id]);

  // useResetWhPaymentVoucher();
  useResetWhPaymentVoucherAction()
  const [dataSource, setDataSource] = useState([
    {
      content: `Chi trả đơn hàng ${get(whBill, "billNumber")}`,
      debitAccount: null,
      creditAccount: DEFAULT_ACCOUNT,
      // amountOfMoney: get(whBillItem, "remainingAmount") || get(whBill, "remainingAmount") || 0,
      amountOfMoney: 0,
    }
  ]);

  const isLoading = isGetWhPaymentVoucherLoading;
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const getCustomerAccounts = async query => {
    let whCustomerAccounts
    if(checkIsPartnerAndNoWhBill){
      let listPartner = await api.whPartner.getWhPartners(query)
      whCustomerAccounts = get(listPartner, "docs").map(item => {
        return {
          ...item,
          label: `${get(item, "partnerCode")} - ${get(item, "name")}`
        }
      });
    }
    else{
       let response = await api.whCustomerManagement.getAll(query);
      whCustomerAccounts = get(response, "docs").map(item => {
        return {
          ...item,
          label: `${get(item, "customerIdFull")} - ${get(item, "fullName")}`
        }
      });
     }
    return whCustomerAccounts || [];
  }

  const getStaffs = async query => {
    const response = await api.userEmployee.getAll(query);
    const whCustomerAccounts = get(response, "docs").map(item => ({
      ...item,
    }));
    return whCustomerAccounts;
  }

  const onFinish = async (values) => {
    if(id){ // UPDATING
      if(!isHaveUpdateVoucher) return toastr.error("Bạn không có quyền chỉnh sửa")
    }else{ // CREATE
      if(!isHaveCreateVoucher) return toastr.error("Bạn không có quyền Tạo phiếu")
    };
  
    try {
      await form.validateFields();
      const fullValues = form.getFieldsValue(true);
      const { arrAppointmentCancel } = fullValues;
      const resultArray = appointments?.filter(itemArr1 => arrAppointmentCancel.includes(itemArr1._id));
      const newAppoitments = resultArray?.map((item) => ({
        name: item?.code,
        id: item?._id
      }));
      const whPaymentVoucher = toJSON({
        whBillId: get(whBill, "_id"),
        ...(whBillItem && { whBillItemId: get(whBillItem, '_id') }),
        ...(whAppointment && { whAppointmentId: get(whAppointment, '_id') }),
        ...(additional && { additional }), // add additional payment or receipt
        ...fullValues,
        accountingDetails: [...ref.current.getAccountingDetailsData()],
        action: WH_PAYMENT_VOUCHER_ACTION.PAYMENT_BILL, // add at 02/06
        arrAppointmentCancel: newAppoitments
      });
      if (id) {
        handleUpdate({ ...whPaymentVoucher, id: head(id.split("&")) });
      } else {
        handleCreate(whPaymentVoucher);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onTabClick = (key) => {
    setActiveKey(key);
  }
  const onPrint = async (viewOnly = false) => {
    const downloadURL = (data, fileName) => {
      const a = document.createElement('a');
      a.href = data;
      a.download = fileName;
      document.body.appendChild(a);
      a.style = 'display: none';
      a.click();
      a.remove();
    };

    const saveDataToFile = (data, fileName, mimeType) => {
      const blob = new Blob([data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      downloadURL(url, fileName, mimeType);
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    };

    const renderFile = async (data, fileName, mimeType) => {
      const blob = new Blob([data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);

      // const res = await api.whReceiptVoucher.upload(blob); // cái này okie
      // const res = await api.whReceiptVoucher.upload(url);
      // setUrl(get(res, "url"));
      setUrl("https://calibre-ebook.com/downloads/demos/demo.docx");
      setIsOpenViewer(true);
    };

    setIsPrinting(true);
    const template = await fetch(myFile).then(res => res.arrayBuffer());

    try {
      await form.validateFields();
      

      const {
        customerAddress,
        customerName,
        issueNumber,
        originalDocument,
        reason,
        accountingDate,
        employeeId
      } = form.getFieldsValue();
     
      const employee = await api.userEmployee.getById(employeeId)
      const totalAmountOfMoney = sumBy(dataSource, item => Number(get(item, "amountOfMoney")));
      const totalAmountOfMoneyString = `${readNumber(totalAmountOfMoney)} ${CURRENCY_STRING[CURRENCY.VND]}`;
      const debitAccounts = uniq(dataSource.map(item => get(item, "debitAccount"))).join(", ");
      const creditAccounts = uniq(dataSource.map(item => get(item, "creditAccount"))).join(", ");

      const accountingDateDD = get(initWhPaymentVoucher,'dateApproved') ?  moment(get(initWhPaymentVoucher,'dateApproved')).date() : moment(get(initWhPaymentVoucher,'createdAt')).date()
      const accountingDateMM = get(initWhPaymentVoucher,'dateApproved') ?  moment(get(initWhPaymentVoucher,'dateApproved')).month() + 1 : moment(get(initWhPaymentVoucher,'createdAt')).month() + 1
      const accountingDateYY = get(initWhPaymentVoucher,'dateApproved') ?  moment(get(initWhPaymentVoucher,'dateApproved')).year() : moment(get(initWhPaymentVoucher,'createdAt')).year()
      // const accountingDateDD = moment.isMoment(accountingDate) ? accountingDate.date() : moment(accountingDate).date();
      // const accountingDateMM = (moment.isMoment(accountingDate) ? accountingDate.month() : moment(accountingDate).month()) + 1; // month in Moment is zero-indexed
      // const accountingDateYY = moment.isMoment(accountingDate) ? accountingDate.year() : moment(accountingDate).year();
      const report = await createReport({
        template,
        cmdDelimiter: ['{#', '#}'],
        data: {
          accountingDateDD,
          accountingDateMM,
          accountingDateYY,
          creditAccounts,
          customerAddress: get(customerAddress, "street") || customerAddress,
          customerName,
          debitAccounts,
          issueNumber,
          originalDocument,
          reason,
          totalAmountOfMoney : floorFormatter(totalAmountOfMoney),
          totalAmountOfMoneyString : capitalizeFirstLetter(totalAmountOfMoneyString),
          EmployeeName:get(employee,'data.fullName',''),
          nameCompany : get(settingDocs,'name',''),
          addressCompany : get(settingDocs,'address','')
        },
      });

      if (viewOnly) {
        renderFile(
          report,
          `${issueNumber}.docx`,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      } else {
        saveDataToFile(
          report,
          `${issueNumber}.docx`,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsPrinting(false);
    }
  };

  const onCustomerIdChange = (value, option) => {
    const { id } = option;
    if (id) form.setFieldsValue({
      customerId: id,
    });
  }

  const onWhVoucherStatusChange = async status => {
    if(id){ // UPDATING
      if(!isHaveUpdateVoucher) return toastr.error("Bạn không có quyền chỉnh sửa")
    }else{ // CREATE
      if(!isHaveCreateVoucher) return toastr.error("Bạn không có quyền Tạo phiếu")
    }
    if(noBillId){ 
      confirm({
        title: `Bạn có muốn ${WH_VOUCHER_ACTION_NAME[status][LANGUAGE.VI]} phiếu này?`,
        icon: <ExclamationCircleOutlined />,
        content: '',
        onOk() {
          const fullValues = form.getFieldsValue(true);

          const whPaymentVoucher = toJSON({
            ...(whBill && { whBillId: get(whBill, '_id') }),
            ...(whBillItem && { whBillItemId: get(whBillItem, '_id') }),
            ...(whAppointment && { whAppointmentId: get(whAppointment, '_id') }),
            ...fullValues,
            accountingDetails: [...ref.current.getAccountingDetailsData()],
            totalAmount:sumBy([...ref.current.getAccountingDetailsData()],(item) => get(item,'amountOfMoney',0))
          });
          switch (status) {
            case WH_VOUCHER_STATUS.CONFIRMED:
              
              handleConfirm({ id: head(id.split("&")),...whPaymentVoucher,status : WH_VOUCHER_STATUS.CONFIRMED });
              break;
            case WH_VOUCHER_STATUS.APPROVED:
              handleApprove({ id: head(id.split("&")) ,...whPaymentVoucher,status : WH_VOUCHER_STATUS.APPROVED });
              break;
            case WH_VOUCHER_STATUS.REJECT:
              handleReject({ id: head(id.split("&")),...whPaymentVoucher,status : WH_VOUCHER_STATUS.REJECT });
              break;
            default:
              break;
          }
        },
        onCancel() {
        },
      });
    }
    else{
      confirm({
        
        title: `Bạn có muốn ${WH_VOUCHER_ACTION_NAME[status][LANGUAGE.VI]} phiếu này?`,
        icon: <ExclamationCircleOutlined />,
        content: '',
        onOk() {
          const fullValues = form.getFieldsValue(true);

          const whPaymentVoucher = toJSON({
            ...(whBill && { whBillId: get(whBill, '_id') }),
            ...(whBillItem && { whBillItemId: get(whBillItem, '_id') }),
            ...(whAppointment && { whAppointmentId: get(whAppointment, '_id') }),
            ...fullValues,
            accountingDetails: [...ref.current.getAccountingDetailsData()],
            totalAmount:sumBy([...ref.current.getAccountingDetailsData()],(item) => get(item,'amountOfMoney',0))
          });
          switch (status) {
            case WH_VOUCHER_STATUS.CONFIRMED:
              handleConfirm({ id: head(id.split("&")),...whPaymentVoucher,status : null });
              break;
            case WH_VOUCHER_STATUS.APPROVED:
              handleApprove({ id: head(id.split("&")),...whPaymentVoucher,status : null });
              break;
            case WH_VOUCHER_STATUS.REJECT:
              handleReject({ id: head(id.split("&")),...whPaymentVoucher,status : null });
              break;
            default:
              break;
          }
  
          // setIsUpdatingStatus(true);
          // try {
          //   await new Promise((resolve, reject) => {
          //     setTimeout(() => {
          //       // setIsUpdatingStatus(false);
          //       resolve(); // nếu không resolve ở đây thì `finally` không chạy
          //     }, 3000);
          //   });
          // } catch (error) {
          //   console.error("error...");
          //   console.error(error);
          // } finally {
          //   setIsUpdatingStatus(false);
          // }
        },
        onCancel() {
        },
      });
    }
  
  }

  const onValuesChange = (changes) => {
    const {
      customerNumber,
      employeeGroupId,
      typeVoucher
    } = changes;

    if (customerNumber) {
      const label = get(form.getFieldValue("customerNumber"), "label");
      const customerName = get(label.split(" - "), "1");
      form.setFieldsValue({
        // customerNumber: { value: get(customerNumber, "value"), label: get(sth.split(" - "), "0") },
        customerName,
      });
    }

    // if (address && head(address)) {
    //   const { lat, lng, address: fullAddress } = head(address);
    //   setDefaultCenter({ lat, lng });
    // }

    if (employeeGroupId) {
      // TODO: should refactor this code
      form.setFieldsValue({
        literacy: {
          ...form.getFieldValue("literacy"),
        }
      })
    };
    if (typeVoucher) {
      form.setFieldsValue({
        arrAppointmentCancel: []
      });
      setAppointments([]);
    };
  };
  const onChangeTypeVoucher = (value) => {
    if (value) {
    if (value === TYPE_PAYMENT_VOUCHER_CUSTOMER.CANCELLED) {
      const newAppoitments = whAppointment?.filter((item) => item?.status === 'CANCELLED');
      const _newAppointments = newAppoitments?.map((item) => ({
        status: item?.status,
        id: item?._id,
        name: item?.code,
      }));
      setAppointments(_newAppointments || []);
    } else {
      const newAppoitments = whAppointment?.filter((item) => item?.status === 'COMPLETED');
      const _newAppointments = newAppoitments?.map((item) => ({
        status: item?.status,
        id: item?._id,
        name: item?.code,
      }));
      setAppointments(_newAppointments || []);
      };
    } else {
      setAppointments([]);
      form.setFieldsValue({
        arrAppointmentCancel: []
      });
    }
  };
  return (
    // <div className="wh-service-form page-wraper page-content">
    <div className="page-wraper">
      <div className="container-fluid">
        {/* <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} phiếu chi`}
          routes={[{ path: PATH_APP.whVoucher.root, title: 'Danh sách phiếu thu chi' }]}
        /> */}
        <Form
          // initialValues={initWhPaymentVoucher}
          // wrapperCol={{ sm: 24, md: 24, lg: 18 }}
          autoComplete="off"
          form={form}
          initialValues={mergedInitWhPaymentVoucher}
          labelAlign="left"
          labelCol={{ sm: 24, md: 24, lg: 4 }}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          requiredMark={false}
          scrollToFirstError
        >
          <Row
            align="top"
            className="staff-form__logo-row"
            gutter={mainRowGutter}
            justify="space-between"
          >
            <Col span={16}>
              <BaseBorderBox title={"Thông tin chung"}>
                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      // label={<>Mã khách hàng{` `}<code>customerNumber</code></>}
                      // wrapperCol={{ lg: 20 }}
                      label={checkIsPartnerAndNoWhBill ? "Mã nhân viên" :"Mã khách hàng"}
                      labelCol={{ lg: 8 }}
                      name="customerNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập mã khách hàng!',
                        }
                      ]}
                    >
                      {isLoading
                        ? <Skeleton.Input active />
                        : (
                          <DebounceSelect
                            initOptions={initCustomerAccounts}
                            fetchOptions={query => getCustomerAccounts({ ...query, limit: MAX_PAGINATION_LIMIT })}
                            labelKey="label"
                            noWhBillId
                            searchKey="keyword"
                            valueKey={checkIsPartnerAndNoWhBill ? "partnerCode" : "customerIdFull"}
                            onSelect={onCustomerIdChange}
                          />
                        )
                      }
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      // label={<>Tên khách hàng{` `}<code>customerName</code></>}
                      label={checkIsPartnerAndNoWhBill ? "Tên nhân viên" : "Tên khách hàng"}
                      labelCol={12}
                      name="customerName"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn Tên nhân viên!',
                        }
                      ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input disabled />}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      // label={<>Tên khách hàng{` `}<code>customerName</code></>}
                      // labelCol={12}
                      label="Người nhận"
                      name="customerName"
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input disabled />}
                    </FormItem>
                  </Col>
                </Row>

                {/* <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      // label={<>Địa chỉ{` `}<code>customerAddressId</code></>}
                      // labelCol={12}
                      label="Địa chỉ"
                      name="customerAddressId"
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input disabled />}
                    </FormItem>
                  </Col>
                </Row> */}

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      // label={<>Địa chỉ{` `}<code>customerAddressId</code></>}
                      // labelCol={12}
                      label="Địa chỉ"
                      name={["customerAddress", "street"]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input disabled />}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      name="reason"
                      label="Lý do chi"
                    // label={<>Lý do nộp{` `}<code>reason</code></>}
                    // labelCol={12}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input />}
                    </FormItem>
                  </Col>
                </Row>
                {isShowBlock && <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      labelCol={{ lg: 8 }}
                      name="typeVoucher"
                      label="Loại phiếu chi"
                    >
                      {isLoading
                        ? <Skeleton.Input active />
                        : (
                          <Select
                            disabled = {id}
                          allowClear
                            onChange={onChangeTypeVoucher}
                          >
                            <Option key={TYPE_PAYMENT_VOUCHER_CUSTOMER.CANCELLED} value={TYPE_PAYMENT_VOUCHER_CUSTOMER.CANCELLED}>{TYPE_PAYMENT_VOUCHER_CUSTOMER_VI.PAY_CUSTOMER_CANCEL}</Option>
                            <Option key={TYPE_PAYMENT_VOUCHER_CUSTOMER.SUCCESS} value={TYPE_PAYMENT_VOUCHER_CUSTOMER.SUCCESS}>{ TYPE_PAYMENT_VOUCHER_CUSTOMER_VI.SUCCESS}</Option>
                          </Select>
                        )
                        }
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      labelCol={{ lg: 8 }}
                      name="arrAppointmentCancel"
                      label="Buổi hẹn"
                      isList 
                    >
                      {
                        isLoading 
                      ? <Skeleton.Input active /> 
                          :
                          <Select
                        disabled={id ? true : (!form.getFieldsValue()?.typeVoucher)}
                        mode = 'multiple'
                        allowClear
                        >
                          {
                            (appointments)?.map((item)=> <Option key={item?.id} value={(item?.id)}>{item?.name}</Option>)
                          }
                          </Select>
                      }
                    </FormItem>
                  </Col>
                </Row>}

                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      name="employeeId"
                      label="Nhân viên"
                      // label={<>Nhân viên{` `}<code>employeeId</code></>}
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn nhân viên'
                        }
                      ]}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <DebounceSelect
                          initOptions={initStaffs}
                          fetchOptions={query => getStaffs({ ...query, limit: MAX_PAGINATION_LIMIT })}
                          valueKey="_id"
                          labelKey="fullName"
                          searchKey="keyword"
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <Space>
                      <FormItem
                        label="Kèm theo"
                        name="originalDocument"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập số chứng từ kèm theo!',
                          }
                        ]}
                      >
                        {isLoading
                          ? <Skeleton.Input active />
                          : (
                            <InputNumber
                              min={0}
                            />
                          )
                        }
                      </FormItem>
                      <p>chứng từ gốc</p>
                    </Space>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>

            <Col span={8}>
              <BaseBorderBox title={"Chứng từ"}>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      label="Ngày hạch toán"
                      name="accountingDate"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày hạch toán!',
                        }
                      ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <DatePicker format={"DD/MM/YYYY"} placeholder="Ngày hạch toán" />}
                    </FormItem>
                    <FormItem
                      label="Ngày chứng từ"
                      name="dateOfIssue"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày chứng từ!',
                        }
                      ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <DatePicker format={"DD/MM/YYYY"} placeholder="Ngày chứng từ" />}
                    </FormItem>

                    <FormItem
                      label="Số chứng từ"
                      name="issueNumber"
                      labelCol={{ lg: 8 }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Vui lòng nhập số chứng từ!',
                      //   }
                      // ]}
                    >
                      {isLoading ? <Skeleton.Input active /> : <Input placeholder={issueNumber} disabled/>}
                    </FormItem>
                    <FormItem label="Phương thức thanh toán" name="paymentMethod" labelCol={{ lg: 12 }}>
                        <Select style={{ width: '100%' }}>
                        <Option value={WH_PAYMENT_METHOD.COD} key={WH_PAYMENT_METHOD.COD}>Tiền mặt</Option>
                        <Option value={WH_PAYMENT_METHOD.TRANSFER} key={WH_PAYMENT_METHOD.TRANSFER}>Chuyển khoản</Option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>
          </Row>

          <Tabs
            defaultActiveKey={1}
            destroyInactiveTabPane
            onChange={() => { }}
            onTabClick={onTabClick}
          >
            <TabPane tab="Hạch toán" key={1}>
              <Space
                direction='vertical'
                style={{ width: "100%" }}
              >
                <AccountingDetails
                  ref={ref}
                  mode={COMPONENT_MODES.EDIT}
                  dataSource={dataSource}
                  whAppointment={whAppointment}
                  isShowSuggest = {!id}
                />
              </Space>

            </TabPane>
            {/* <TabPane tab="Phiếu chi" key="2">
              <WhPaymentVouchers status={WH_PARTNER_STATUS.WAITING} />
            </TabPane> */}
          </Tabs>

          <WithPermission permission={POLICY.READ_WHHISTORYLOGVOUCHER}>
          <Collapse style={{backgroundColor : 'transparent'}} bordered={false}>
            <Collapse.Panel showArrow={false} style={{backgroundColor : 'transparent',borderBottomColor : 'transparent',marginTop : 0}} header={<Typography.Link>{"(Xem chi tiết Logs)"}</Typography.Link>} key="1">
            <HistoryLogs historyLogs={get(whPaymentVoucher,'historyLogs',[])}/>
            </Collapse.Panel>
          </Collapse>
          </WithPermission>
          
          <Row className="wh-service-form__submit-box">
            <Button
            disabled={(!isHaveUpdateVoucher && !isHaveCreateVoucher)}
              icon={<SaveOutlined />}
              onClick={onFinish}
              type='primary'
            >
              Lưu
            </Button>

            {(id && (!get(mergedInitWhPaymentVoucher, "status") || get(mergedInitWhPaymentVoucher, "status") === WH_VOUCHER_STATUS.CREATED)) && (
              <WithPermission permission={POLICY.UPDATE_WHUPDATERECEIPTANDPAYMENTVOUCHERSTATUS}>
                <Button
                  icon={<CheckOutlined />}
                  loading={isUpdatingStatus}
                  disabled={!isHaveUpdateVoucher}
                  onClick={() => onWhVoucherStatusChange(WH_VOUCHER_STATUS.CONFIRMED)}
                >
                  {WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.CONFIRMED][LANGUAGE.VI]}
                </Button>
              </WithPermission>
            )}

            {(id && get(mergedInitWhPaymentVoucher, "status") === WH_VOUCHER_STATUS.CONFIRMED) && (
              <Space>
                <WithPermission permission={POLICY.UPDATE_WHUPDATERECEIPTANDPAYMENTVOUCHERSTATUS}>
                  <Button
                    icon={<CheckOutlined />}
                    loading={isUpdatingStatus}
                    disabled={!isHaveUpdateVoucher}
                    onClick={() => onWhVoucherStatusChange(WH_VOUCHER_STATUS.APPROVED)}
                    >
                    {WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.APPROVED][LANGUAGE.VI]}
                  </Button>
                </WithPermission>
                <WithPermission permission={POLICY.UPDATE_WHUPDATERECEIPTANDPAYMENTVOUCHERSTATUS}>
                  <Button
                    icon={<CheckOutlined />}
                    loading={isUpdatingStatus}
                    disabled={!isHaveUpdateVoucher}
                    onClick={() => onWhVoucherStatusChange(WH_VOUCHER_STATUS.REJECT)}
                    >
                    {WH_VOUCHER_ACTION_NAME[WH_VOUCHER_STATUS.REJECT][LANGUAGE.VI]}
                  </Button>
                </WithPermission>
              </Space>
            )}

            <div className='buttonSaveFile'>
              <Button
                icon={<FileWordOutlined />}
                loading={isPrinting}
                onClick={() => onPrint()}
              >
                Tải về file Docx
              </Button>
              <Button onClick={onOpenSettingDocs} className='buttonSaveFile--addAfter'><SettingOutlined /></Button>
              </div>

            {isSubmitLoading ? (
              <Button disabled>Đóng</Button>
            ) : (
              onCancel
                ? <Button icon={<CloseCircleOutlined />} onClick={onCancel}>Đóng</Button>
                : (
                  <Link to={PATH_APP.whVoucher.root}>
                    <Button icon={<CloseCircleOutlined />}>Đóng</Button>
                  </Link>
                )
            )}

            {/* <Button
              icon={<EyeOutlined />}
              loading={isPrinting}
              onClick={() => onPrint(true)}
            >
              Xem trước file
            </Button> */}

          </Row>
        </Form>
      </div>
      <Modal
        visible={isOpenViewer}
        onCancel={() => setIsOpenViewer(false)}
      >
        <Printer url={url} />
      </Modal>
      <Modal width={600} footer={null} title='Thiếp lập file word' visible={get(settingDocs,'isVisibleSettings',false)} onOk={onOkModalSetting} onCancel={onCancelModalSetting}>
        <FormSettingDocs  onOk={onOkModalSetting} initialValues={{...settingDocs}}/>
      </Modal>
    </div>
  );
};

export default WhPaymentVoucherForm;
