export const GET_CONFIG_CAMPAIGNS_REQUEST =
  'GET_CONFIG_CAMPAIGNS_REQUEST';
export const GET_CONFIG_CAMPAIGNS_SUCCESS =
  'GET_CONFIG_CAMPAIGNS_SUCCESS';
export const GET_CONFIG_CAMPAIGNS_FAILED = 'GET_CONFIG_CAMPAIGNS_FAILED';

export const GET_CONFIG_CAMPAIGN_REQUEST = 'GET_CONFIG_CAMPAIGN_REQUEST';
export const GET_CONFIG_CAMPAIGN_SUCCESS = 'GET_CONFIG_CAMPAIGN_SUCCESS';
export const GET_CONFIG_CAMPAIGN_FAILED = 'GET_CONFIG_CAMPAIGN_FAILED';

export const CREATE_CONFIG_CAMPAIGN_REQUEST =
  'CREATE_CONFIG_CAMPAIGN_REQUEST';
export const CREATE_CONFIG_CAMPAIGN_SUCCESS =
  'CREATE_CONFIG_CAMPAIGN_SUCCESS';
export const CREATE_CONFIG_CAMPAIGN_FAILED =
  'CREATE_CONFIG_CAMPAIGN_FAILED';

export const UPDATE_CONFIG_CAMPAIGN_REQUEST =
  'UPDATE_CONFIG_CAMPAIGN_REQUEST';
export const UPDATE_CONFIG_CAMPAIGN_SUCCESS =
  'UPDATE_CONFIG_CAMPAIGN_SUCCESS';
export const UPDATE_CONFIG_CAMPAIGN_FAILED =
  'UPDATE_CONFIG_CAMPAIGN_FAILED';

export const DELETE_CONFIG_CAMPAIGN_REQUEST =
  'DELETE_CONFIG_CAMPAIGN_REQUEST';
export const DELETE_CONFIG_CAMPAIGN_SUCCESS =
  'DELETE_CONFIG_CAMPAIGN_SUCCESS';
export const DELETE_CONFIG_CAMPAIGN_FAILED =
  'DELETE_CONFIG_CAMPAIGN_FAILED';


export const RESET_CONFIG_CAMPAIGN_STATE = 'RESET_CONFIG_CAMPAIGN_STATE';
export const RESET_CONFIG_CAMPAIGN_STATE_ACTION = 'RESET_CONFIG_CAMPAIGN_STATE_ACTION';
