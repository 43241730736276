import { get } from 'lodash';
import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import {
  CREATE_WH_PAYMENT_VOUCHER_FAILED,
  CREATE_WH_PAYMENT_VOUCHER_REQUEST,
  CREATE_WH_PAYMENT_VOUCHER_SUCCESS,
  DELETE_WH_PAYMENT_VOUCHER_FAILED,
  DELETE_WH_PAYMENT_VOUCHER_REQUEST,
  DELETE_WH_PAYMENT_VOUCHER_SUCCESS,
  GET_WH_PAYMENT_VOUCHERS_FAILED,
  GET_WH_PAYMENT_VOUCHERS_REQUEST,
  GET_WH_PAYMENT_VOUCHERS_SUCCESS,
  GET_WH_PAYMENT_VOUCHER_FAILED,
  GET_WH_PAYMENT_VOUCHER_REQUEST,
  GET_WH_PAYMENT_VOUCHER_SUCCESS,
  UPDATE_WH_PAYMENT_VOUCHER_FAILED,
  UPDATE_WH_PAYMENT_VOUCHER_REQUEST,
  UPDATE_WH_PAYMENT_VOUCHER_SUCCESS,
  CONFIRM_WH_PAYMENT_VOUCHER_FAILED,
  CONFIRM_WH_PAYMENT_VOUCHER_REQUEST,
  CONFIRM_WH_PAYMENT_VOUCHER_SUCCESS,
  APPROVE_WH_PAYMENT_VOUCHER_FAILED,
  APPROVE_WH_PAYMENT_VOUCHER_REQUEST,
  APPROVE_WH_PAYMENT_VOUCHER_SUCCESS,
  REJECT_WH_PAYMENT_VOUCHER_FAILED,
  REJECT_WH_PAYMENT_VOUCHER_REQUEST,
  REJECT_WH_PAYMENT_VOUCHER_SUCCESS,
  GET_WH_PAYMENT_VOUCHER_NOT_BILLID_REQUEST,
  GET_WH_PAYMENT_VOUCHERS_CANCEL_REQUEST,
  GET_WH_PAYMENT_VOUCHERS_CANCEL_SUCCESS,
  GET_WH_PAYMENT_VOUCHERS_CANCEL_FAILED,
} from '../../constants/actionTypes';

function* getWhPaymentVouchers({ payload: query }) {
  try {
    const response = yield call(Api.whPaymentVoucher.getWhPaymentVouchers, query);
    yield put({ type: GET_WH_PAYMENT_VOUCHERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_PAYMENT_VOUCHERS_FAILED, payload: error.message });
  }
}

function* getWhPaymentVouchersCancel({ payload: query }) {
  try {
    const response = yield call(Api.whPaymentVoucher.getWhPaymentVouchersCancel, query);
    yield put({ type: GET_WH_PAYMENT_VOUCHERS_CANCEL_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_PAYMENT_VOUCHERS_CANCEL_FAILED, payload: error.message });
  }
}

function* getWhPaymentVoucher({ payload: query }) {
  
  try {
    let whPaymentVoucher
    if(get(query,'noBillId')){
       whPaymentVoucher = yield call(Api.whPaymentVoucher.getByIdNotBillId, get(query,'id'));
    }else{
      whPaymentVoucher = yield call(Api.whPaymentVoucher.getById, get(query,'id'));
    }
    yield put({ type: GET_WH_PAYMENT_VOUCHER_SUCCESS, payload: whPaymentVoucher });
  } catch (error) {
    yield put({ type: GET_WH_PAYMENT_VOUCHER_FAILED, payload: error });
  }
}
function* getWhPaymentVoucherNotBillId({ payload: id }) {
  try {
    const whPaymentVoucher = yield call(Api.whPaymentVoucher.getByIdNotBillId, id);
    yield put({ type: GET_WH_PAYMENT_VOUCHER_SUCCESS, payload: whPaymentVoucher });
  } catch (error) {
    yield put({ type: GET_WH_PAYMENT_VOUCHER_FAILED, payload: error.message });
  }
}

function* createWhPaymentVoucher(action) {
  try {
    const data = yield call(Api.whPaymentVoucher.create, action.payload);
    yield put({ type: CREATE_WH_PAYMENT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    console.error(error);
    yield put({ type: CREATE_WH_PAYMENT_VOUCHER_FAILED, payload: error });
  }
}

function* updateWhPaymentVoucher(action) {
  try {
    const data = yield call(Api.whPaymentVoucher.update, action.payload);
    yield put({ type: UPDATE_WH_PAYMENT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_PAYMENT_VOUCHER_FAILED, payload: error });
  }
}

function* confirmWhPaymentVoucher(action) {
  try {
    let data
    if(action.payload.status){
     data = yield call(Api.whPaymentVoucher.confirmNotBillId, action.payload);
    }
    else{
      data = yield call(Api.whPaymentVoucher.confirm, action.payload);
    }
    yield put({ type: CONFIRM_WH_PAYMENT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CONFIRM_WH_PAYMENT_VOUCHER_FAILED, payload: error });
  }
}

function* approveWhPaymentVoucher(action) {
  try {
    let data
    if(action.payload.status){
     data = yield call(Api.whPaymentVoucher.approveNotBillId,action.payload);
    }
    else{
      data = yield call(Api.whPaymentVoucher.approve, action.payload);
    }
    yield put({ type: APPROVE_WH_PAYMENT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: APPROVE_WH_PAYMENT_VOUCHER_FAILED, payload: error });
  }
}

function* rejectWhPaymentVoucher(action) {
  try {
    let data
    if(action.payload.status){
     data = yield call(Api.whPaymentVoucher.rejectNotBillId, action.payload);
    }
    else{
      data = yield call(Api.whPaymentVoucher.reject, action.payload);
    }
    yield put({ type: REJECT_WH_PAYMENT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: REJECT_WH_PAYMENT_VOUCHER_FAILED, payload: error });
  }
}

function* deleteWhPaymentVoucher({ payload }) {
  try {
    yield call(Api.whPaymentVoucher.delete, payload);
    yield put({ type: DELETE_WH_PAYMENT_VOUCHER_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_WH_PAYMENT_VOUCHER_FAILED, payload: error.message });
  }
}

export default function* whPaymentVoucher() {
  yield takeLatest(CREATE_WH_PAYMENT_VOUCHER_REQUEST, createWhPaymentVoucher);
  yield takeLatest(DELETE_WH_PAYMENT_VOUCHER_REQUEST, deleteWhPaymentVoucher);
  yield takeLatest(GET_WH_PAYMENT_VOUCHERS_REQUEST, getWhPaymentVouchers);
  yield takeLatest(GET_WH_PAYMENT_VOUCHER_REQUEST, getWhPaymentVoucher);
  yield takeLatest(GET_WH_PAYMENT_VOUCHER_NOT_BILLID_REQUEST, getWhPaymentVoucherNotBillId);
  yield takeLatest(UPDATE_WH_PAYMENT_VOUCHER_REQUEST, updateWhPaymentVoucher);
  yield takeLatest(CONFIRM_WH_PAYMENT_VOUCHER_REQUEST, confirmWhPaymentVoucher);
  yield takeLatest(APPROVE_WH_PAYMENT_VOUCHER_REQUEST, approveWhPaymentVoucher);
  yield takeLatest(REJECT_WH_PAYMENT_VOUCHER_REQUEST, rejectWhPaymentVoucher);
  yield takeLatest(GET_WH_PAYMENT_VOUCHERS_CANCEL_REQUEST, getWhPaymentVouchersCancel);
}
