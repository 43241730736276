import { getExistProp } from '~/utils/helper';
import requester from './requester';

const quotations = {
  getById: (id) => requester.get(`/quotations/${id}`),
  create: (order) => requester.post(`/quotations`, order),
  update:(order) => requester.put(`/quotations/${order._id}/update`,order),
  getAll : (query) => requester.get(`/search/quotations/all`,query),
  delete: (id) => requester.delete(`/quotations/${id}`),
  copy: (id) => requester.post(`/quotations/${id}/copy`),
  convert:(order) => requester.post(`/quotations/${order.idQuotation}/convertorder`,order),
  ChangeState:(order) => requester.post(`/quotations/${order._id}/update`,order),
  filter:(query) =>  requester.get(`/search/quotations/all${query}`)
  
};

export default quotations;
