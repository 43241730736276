import * as Types from '../../constants/actionTypes';
  
  export const getConfigCampaigns = query => ({
    type: Types.GET_CONFIG_CAMPAIGNS_REQUEST,
    payload: query
  });
  export const getConfigCampaign = id => ({
    type: Types.GET_CONFIG_CAMPAIGN_REQUEST,
    payload: id
  });
  export const createConfigCampaign = instance => ({
    type: Types.CREATE_CONFIG_CAMPAIGN_REQUEST,
    payload: instance
  });
  export const updateConfigCampaign = instance => ({
    type: Types.UPDATE_CONFIG_CAMPAIGN_REQUEST,
    payload: instance
  });
  export const deleteConfigCampaign = id => ({
    type: Types.DELETE_CONFIG_CAMPAIGN_REQUEST,
    payload: id
  });
  export const resetConfigCampaignState = () => ({
    type: Types.RESET_CONFIG_CAMPAIGN_STATE
  });
  export const resetConfigCampaignStateACtion = () => ({
    type: Types.RESET_CONFIG_CAMPAIGN_STATE_ACTION
  });
