import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  useFetch
} from '~/hooks/utils';

import {
  getCoupons,
  createCoupon,
  deleteCoupon,
  getCoupon,
  resetCouponState,
  updateCoupon,
  getCouponServiceCategories,
  resetCouponActionState
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import moment from 'moment';
import { forIn, get, groupBy, keys, map } from 'lodash';

const COUPON_MODULE = 'coupon';
const getSelector = (key) => (state) => state[COUPON_MODULE][key];
const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(COUPON_MODULE);

const couponServiceCategoriesSelector = getSelector('couponServiceCategories');
const getCouponServiceCategoriesFailedSelector = getSelector(
  'getCouponServiceCategoriesFailed'
);
const isGetCouponServiceCategoriesLoadingSelector = getSelector(
  'isGetCouponServiceCategoriesLoading'
);
export const useCoupons = (query) => {
  return useFetchByParam({
    action: getCoupons,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateCoupon = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới mã giảm giá thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createCoupon
  });
};

export const useUpdateCoupon = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật mã giảm giá thành công',
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateCoupon
  });
};

export const useDeleteCoupon = (onDeleteSuccess) => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá mã giảm giá thành công',
    onDeleteSuccess
  );
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector,
    action: deleteCoupon
  });
};

export const useCoupon = (params) => {
  return useFetchByParam({
    action: getCoupon,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitCoupon = (coupon, id) => {
  return useMemo(() => {
    if (!coupon || !id) {
      return {
        discountType: 'value'
      };
    }

    const initCoupon = {
      ...coupon,
      startTime: coupon.startTime ? moment(coupon.startTime) : null,
      endTime: coupon.endTime ? moment(coupon.endTime) : null,
      // createdAccountTimeRange: coupon?.createdAccountTimeRange ?  map(coupon?.createdAccountTimeRange, (el) => moment(el)) : undefined,
      createdAccountTimeRange: {
        // startTime: moment(get(coupon, 'createdAccountTimeRange')?.[0]),
        startTime: get(coupon, 'createdAccountTimeRange')?.[0] ? moment(get(coupon, 'createdAccountTimeRange')?.[0]) : null,
        endTime: get(coupon, 'createdAccountTimeRange')?.[1] ? moment(get(coupon, 'createdAccountTimeRange')?.[1]) : null
      },
      discountType:
        coupon.discountPercent === 0 || coupon.discountPercent
          ? 'percent'
          : 'value'
    };

    return initCoupon;
  }, [coupon, id]);
};

export const useUpdateCouponParams = (query,listOptionSearch) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState('');
  useEffect(() => {
    const groupByKey = groupBy(listOptionSearch , (e) => get(e,'value'))
  forIn(query,(values,key,obj) =>{
    if(groupByKey[key]){
      setKeyword(values)
    }
  })
  },[query])
  const onParamChange = (param) => {
    const groupByKey = groupBy(listOptionSearch , (e) => get(e,'value'))
    forIn(query,(values,key,obj) =>{
      if(groupByKey[key] && (keys(param)?.some(e => groupByKey[e]))){
        obj[key] = null
      }
    })
    history.push({
      pathname: '/coupon',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useCouponQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  // const page = query.get('page');
  const name = query.get('name');
  const code = query.get('code');
  const discountValue = query.get('discountValue');
  const discountPercent = query.get('discountPercent');
  const maxDiscount = query.get('maxDiscount');
  const minAmountOfBill = query.get('minAmountOfBill');
  const distributionType = query.get('distributionType');
  const serviceGroupType = query.get('serviceGroupType');
  const keyworstartTimed = query.get('keyworstartTimed');
  const endTime = query.get('endTime');
  const addressPath = query.get('addressPath') || null

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      cityId,
      name,
      code,
      discountValue,
      discountPercent,
      maxDiscount,
      minAmountOfBill,
      distributionType,
      serviceGroupType,
      keyworstartTimed,
      endTime,
      addressPath,
    });

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    cityId,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    name,
    code,
    discountValue,
    discountPercent,
    maxDiscount,
    minAmountOfBill,
    distributionType,
    serviceGroupType,
    keyworstartTimed,
    endTime,
    addressPath,
  ]);
};

export const useCouponPaging = () => useSelector(pagingSelector);

export const useCouponServiceCategories = (query = {}) => {
  return useFetch({
    action: getCouponServiceCategories,
    loadingSelector: isGetCouponServiceCategoriesLoadingSelector,
    dataSelector: couponServiceCategoriesSelector,
    failedSelector: getCouponServiceCategoriesFailedSelector,
    param: query
  });
};

export const useResetCoupon = () => {
  useResetState(resetCouponState);
};

export const useResetActionCoupon = () => {
  useResetState(resetCouponActionState);
};
