import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getWhReportMedicines({ payload: query }) {
    try {
      const response = yield call(Api.whReportMedicines.getAll, query);
      yield put({ type: Types.GET_WH_REPORT_MEDICINES_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WH_REPORT_MEDICINES_FAILED, payload: error });
    }
  }
function* getWhReportMedicine({ payload: id }) {
    try {
      const response = yield call(Api.whReportMedicines.getOne, id);
      yield put({ type: Types.GET_WH_REPORT_MEDICINE_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WH_REPORT_MEDICINE_FAILED, payload: error });
    }
  }
  function* createWhReportMedicine(action) {
    try {
      const data = yield call(Api.whReportMedicines.create, action.payload);
      yield put({ type: Types.CREATE_WH_REPORT_MEDICINE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_WH_REPORT_MEDICINE_FAILED, payload: error });
    }
  }
  function* updateWhReportMedicine(action) {
    try {
      const data = yield call(Api.whReportMedicines.update, action.payload);
      yield put({ type: Types.UPDATE_WH_REPORT_MEDICINE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_WH_REPORT_MEDICINE_FAILED, payload: error });
    }
  }
  function* deleteWhReportMedicine(action) {
    try {
      const data = yield call(Api.whReportMedicines.delete, action.payload);
      yield put({ type: Types.DELETE_WH_REPORT_MEDICINE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_WH_REPORT_MEDICINE_FAILED, payload: error });
    }
  }
export default function* WhReportMedicines() {
    yield takeLatest(Types.GET_WH_REPORT_MEDICINES_REQUEST, getWhReportMedicines);
    yield takeLatest(Types.GET_WH_REPORT_MEDICINE_REQUEST, getWhReportMedicine);
    yield takeLatest(Types.CREATE_WH_REPORT_MEDICINE_REQUEST, createWhReportMedicine);
    yield takeLatest(Types.UPDATE_WH_REPORT_MEDICINE_REQUEST, updateWhReportMedicine);
    yield takeLatest(Types.DELETE_WH_REPORT_MEDICINE_REQUEST, deleteWhReportMedicine);
  }