import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getServicesByLocation,
  createServiceByLocation,
  deleteServiceByLocation,
  getServiceByLocation,
  resetServiceByLocationState,
  updateServiceByLocation
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const getSelector = (key) => (state) => state.serviceByLocation[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');
const pagingDetailSelector = getSelector('pagingDetailLocation');

export const useServiceByLocationPaging = () => useSelector(pagingSelector);
export const useServiceByLocationDetailPaging = () => useSelector(pagingDetailSelector);

export const useServiceByLocationQueryParams = (defaultLimit) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || defaultLimit || 10;
  const keyword = query.get('keyword');
  const locationIndex= query.get('locationIndex')

  const [page, setPage] = useState(query.get('page') || 1);
  const onTableChange = ({ current }) => setPage(current);

  const deleteServiceByLocationSuccess = useSelector(deleteSuccessSelector);
  const updateServiceByLocationSuccess = useSelector(updateSuccessSelector);
  const createServiceByLocationSuccess = useSelector(createSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined,
      locationIndex: locationIndex
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, locationIndex, deleteServiceByLocationSuccess, createServiceByLocationSuccess, updateServiceByLocationSuccess]);
};

export const useServicesByLocation = (query) => {
  return useFetchByParam({
    action: getServicesByLocation,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};


export const useServiceByLocation = (query) => {

  return useFetchByParam({
    action: getServiceByLocation,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: query
  });
};

export const useCreateServiceByLocation = () => {
  const history = useHistory();
  // const callback = () => history.push('/service-by-location');

  useSuccess(
    createSuccessSelector,
    'Tạo mới thành công',
    // callback
  );
  useFailed(createFailedSelector, 'Tạo mới thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createServiceByLocation
  });
};

export const useUpdateServiceByLocation = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật thành công',
    callback
  );
  useFailed(updateFailedSelector, 'Cập nhật thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateServiceByLocation
  });
};

export const useDeleteServiceByLocation = () => {
  useSuccess(deleteSuccessSelector, 'Xoá thành công');
  useFailed(deleteFailedSelector, 'Xoá thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteServiceByLocation
  });
};

export const useUpdateServiceByLocationParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    history.push({
      pathname: '/service-by-location',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useInitServiceByLocation = (serviceByLocation) =>
  useMemo(() => {
    if (!serviceByLocation) {
      return {
        image: {}
      };
    }

    return serviceByLocation;
  }, [serviceByLocation]);

export const useResetServiceByLocation = () => {
  useResetState(resetServiceByLocationState);
};
