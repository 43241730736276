import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isOverViewLoading: false,
  overView: null,
  getOverViewFailed: null,

  isYearlyLoading: false,
  monthly: [],
  getYearlyFailed: null,

  isQuarterlyLoading: false,
  monthly: [],
  getQuarterlyFailed: null,

  isMonthlyLoading: false,
  monthly: [],
  getMonthlyFailed: null,

  isDailyLoading: false,
  daily: [],
  getDailyFailed: null,

  isMostServiceLoading: false,
  mostService: [],
  getMostServiceFailed: null,

  isMostRevenueServiceLoading: false,
  mostRevenueService: [],
  getMostRevenueServiceFailed: null,

  isMostPartnerAppointmentLoading: false,
  mostPartnerAppointment: [],
  getMostPartnerAppointmentFailed: null,

  isMostCustomerAppointmentLoading: false,
  mostCustomerAppointment: [],
  getMostCustomerAppointmentFailed: null,

  isOverviewTodayLoading: false,
  overviewToday: [],
  getOverviewTodayFailed: null,

  isOverviewDailyLoading: false,
  overviewDaily: [],
  getOverviewDailyFailed: null,

  isOverviewMonthlyLoading: false,
  overviewMonthly: [],
  getOverviewMonthlyFailed: null,

  isOverviewQuarterlyLoading: false,
  overviewQuarterly: [],
  getOverviewQuarterlyFailed: null,

  isOverviewYearlyLoading: false,
  overviewYearly: [],
  getOverviewYearlyFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_OVER_VIEW_REPORT_REQUEST:
      state.isOverViewLoading = true;
      return;

    case Types.GET_OVER_VIEW_REPORT_SUCCESS:
      state.isOverViewLoading = false;
      state.overView = payload;
      return;

    case Types.GET_OVER_VIEW_REPORT_FAILED:
      state.isOverViewLoading = false;
      state.getOverViewFailed = payload;
      state.overView = {};
      return;

    case Types.GET_YEARLY_REPORT_REQUEST:
      state.isYearlyLoading = true;
      return;

    case Types.GET_YEARLY_REPORT_SUCCESS:
      state.isYearlyLoading = false;
      state.yearly = payload;
      return;

    case Types.GET_YEARLY_REPORT_FAILED:
      state.isYearlyLoading = false;
      state.getYearlyFailed = payload;
      state.yearly = [];
      return;

    case Types.GET_QUARTERLY_REPORT_REQUEST:
      state.isQuarterlyLoading = true;
      return;

    case Types.GET_QUARTERLY_REPORT_SUCCESS:
      state.isQuarterlyLoading = false;
      state.quarterly = payload;
      return;

    case Types.GET_QUARTERLY_REPORT_FAILED:
      state.isQuarterlyLoading = false;
      state.getQuarterlyFailed = payload;
      state.quarterly = [];
      return;

    case Types.GET_MONTHLY_REPORT_REQUEST:
      state.isMonthlyLoading = true;
      return;

    case Types.GET_MONTHLY_REPORT_SUCCESS:
      state.isMonthlyLoading = false;
      state.monthly = payload;
      return;

    case Types.GET_MONTHLY_REPORT_FAILED:
      state.isMonthlyLoading = false;
      state.getMonthlyFailed = payload;
      state.monthly = [];
      return;

    case Types.GET_DAILY_REPORT_REQUEST:
      state.isDailyLoading = true;
      return;

    case Types.GET_DAILY_REPORT_SUCCESS:
      state.isDailyLoading = false;
      state.daily = payload;
      return;

    case Types.GET_DAILY_REPORT_FAILED:
      state.isDailyLoading = false;
      state.getDailyFailed = payload;
      state.daily = [];
      return;

    case Types.GET_MOST_SERVICE_REQUEST:
      state.isMostServiceLoading = true;
      return;

    case Types.GET_MOST_SERVICE_SUCCESS:
      state.isMostServiceLoading = false;
      state.mostService = payload;
      return;

    case Types.GET_MOST_SERVICE_FAILED:
      state.isMostServiceLoading = false;
      state.getMostServiceFailed = payload;
      state.mostService = [];
      return;

    case Types.GET_MOST_REVENUE_SERVICE_REQUEST:
      state.isMostRevenueServiceLoading = true;
      return;

    case Types.GET_MOST_REVENUE_SERVICE_SUCCESS:
      state.isMostRevenueServiceLoading = false;
      state.mostRevenueService = payload;
      return;

    case Types.GET_MOST_REVENUE_SERVICE_FAILED:
      state.isMostRevenueServiceLoading = false;
      state.getMostRevenueServiceFailed = payload;
      state.mostRevenueService = [];
      return;

    case Types.GET_MOST_PARTNER_APPOINTMENT_REQUEST:
      state.isMostPartnerAppointmentLoading = true;
      return;

    case Types.GET_MOST_PARTNER_APPOINTMENT_SUCCESS:
      state.isMostPartnerAppointmentLoading = false;
      state.mostPartnerAppointment = payload;
      return;

    case Types.GET_MOST_PARTNER_APPOINTMENT_FAILED:
      state.isMostPartnerAppointmentLoading = false;
      state.getMostPartnerAppointmentFailed = payload;
      state.mostPartnerAppointment = [];
      return;

    case Types.GET_MOST_CUSTOMER_APPOINTMENT_REQUEST:
      state.isMostCustomerAppointmentLoading = true;
      return;

    case Types.GET_MOST_CUSTOMER_APPOINTMENT_SUCCESS:
      state.isMostCustomerAppointmentLoading = false;
      state.mostCustomerAppointment = payload;
      return;

    case Types.GET_MOST_CUSTOMER_APPOINTMENT_FAILED:
      state.isMostCustomerAppointmentLoading = false;
      state.getMostCustomerAppointmentFailed = payload;
      state.mostCustomerAppointment = [];
      return;

    case Types.GET_OVERVIEW_TODAY_REQUEST:
      state.isOverviewTodayLoading = true;
      return;

    case Types.GET_OVERVIEW_TODAY_SUCCESS:
      state.isOverviewTodayLoading = false;
      state.overviewToday = payload;
      return;

    case Types.GET_OVERVIEW_TODAY_FAILED:
      state.isOverviewTodayLoading = false;
      state.getOverviewTodayFailed = payload;
      state.overviewToday = [];
      return;

    case Types.GET_OVERVIEW_DAILY_REQUEST:
      state.isOverviewDailyLoading = true;
      return;

    case Types.GET_OVERVIEW_DAILY_SUCCESS:
      state.isOverviewDailyLoading = false;
      state.overviewDaily = payload;
      return;

    case Types.GET_OVERVIEW_DAILY_FAILED:
      state.isOverviewDailyLoading = false;
      state.getOverviewDailyFailed = payload;
      state.overviewDaily = [];
      return;

    case Types.GET_OVERVIEW_MONTHLY_REQUEST:
      state.isOverviewMonthlyLoading = true;
      return;

    case Types.GET_OVERVIEW_MONTHLY_SUCCESS:
      state.isOverviewMonthlyLoading = false;
      state.overviewMonthly = payload;
      return;

    case Types.GET_OVERVIEW_MONTHLY_FAILED:
      state.isOverviewMonthlyLoading = false;
      state.getOverviewMonthlyFailed = payload;
      state.overviewMonthly = [];
      return;

    case Types.GET_OVERVIEW_QUARTERLY_REQUEST:
      state.isOverviewQuarterlyLoading = true;
      return;

    case Types.GET_OVERVIEW_QUARTERLY_SUCCESS:
      state.isOverviewQuarterlyLoading = false;
      state.overviewQuarterly = payload;
      return;

    case Types.GET_OVERVIEW_QUARTERLY_FAILED:
      state.isOverviewQuarterlyLoading = false;
      state.getOverviewQuarterlyFailed = payload;
      state.overviewQuarterly = [];
      return;

    case Types.GET_OVERVIEW_YEARLY_REQUEST:
      state.isOverviewYearlyLoading = true;
      return;

    case Types.GET_OVERVIEW_YEARLY_SUCCESS:
      state.isOverviewYearlyLoading = false;
      state.overviewYearly = payload;
      return;

    case Types.GET_OVERVIEW_YEARLY_FAILED:
      state.isOverviewYearlyLoading = false;
      state.getOverviewYearlyFailed = payload;
      state.overviewYearly = [];
      return;

    case Types.RESET_DASHBOARD_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
