import * as Types from '~/constants/actionTypes';

export const getProductCatalogues = (query) => ({
  type: Types.GET_PRODUCT_CATALOGUES_REQUEST,
  payload: query
});
export const getProductCatalogueStatus = (query) => ({
  type: Types.GET_PRODUCT_CATALOGUE_STATUS_REQUEST,
  payload: query
});

export const getProductCatalogue = (id) => ({
  type: Types.GET_PRODUCT_CATALOGUE_REQUEST,
  payload: id
});

export const createProductCatalogue = (pro) => ({
  type: Types.CREATE_PRODUCT_CATALOGUE_REQUEST,
  payload: pro
});

export const updateProductCatalogue = (pro) => ({
  type: Types.UPDATE_PRODUCT_CATALOGUE_REQUEST,
  payload: pro
});

export const updateProductCatalogueStatus = (pro) => ({
  type: Types.UPDATE_PRODUCT_CATALOGUE_STATUS_REQUEST,
  payload: pro
});

export const deleteProductCatalogue = (id) => ({
  type: Types.DELETE_PRODUCT_CATALOGUE_REQUEST,
  payload: id
});

export const resetProductCatalogueState = () => ({
  type: Types.RESET_PRODUCT_CATALOGUE_STATE
});

export const resetProductCatalogueFormState = () => ({
  type: Types.RESET_PRODUCT_CATALOGUE_FORM_STATE
});
