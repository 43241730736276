import { Button, Checkbox, Col, Row, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WithPermission } from '~/components/Common';
import TableStickyTop from '~/components/Common/TableStickyTop';
import POLICIES from '~/constants/policy';
import {
  findStringToSlug,
  useAccessPermissionPartner,
  useDenyPermissionPartner,
  useGetListAccessPermissionPartner,
  useGetResourcePartner,
  useAccessPermissionClinic,
  useDenyPermissionClinic,
  useGetListAccessPermissionClinic,
  useGetResourceClinic,
  useMatchPolicy,
} from '~/hooks';
const hook = {
    useGetResourceConstructor : {
        Branch : useGetResourcePartner,
        Clinic : useGetResourceClinic,
    },
    useGetListAccessPermission : {
        Branch : useGetListAccessPermissionPartner,
        Clinic : useGetListAccessPermissionClinic,
    },
    useAccessPermission : {
        Branch : useAccessPermissionPartner,
        Clinic : useAccessPermissionClinic,
    },
    useDenyPermission : {
        Branch : useDenyPermissionPartner,
        Clinic : useDenyPermissionClinic,
    },
};
export const POLICY = {
    update : {
        Branch : POLICIES.UPDATE_RESOURCEPARTNER,
        Clinic : POLICIES.UPDATE_RESOURCECLINIC,
    }
}
export default function PermissionAccess({ type }) {
    const { id } = useParams();
    const partnerId = useMemo(() => id, [id]);
    const [keyword,setKeyword] = useState();
    const [resources, isResourcesLoading] = hook.useGetResourceConstructor[type](partnerId);
    const [searchResource,setSearchResource] = useState([]);
    const [listAccess, isLoading] = hook.useGetListAccessPermission[type](partnerId);
    const canUpdate = useMatchPolicy(POLICY.update[type]);
    const dataSource = useMemo(() => {
        if(!keyword) return resources;
        return searchResource
    },[resources,searchResource,keyword]);

    const [isSubmitLoading, access] = hook.useAccessPermission[type]();
    const [, deny] = hook.useDenyPermission[type]();
    
    const onChange = (checked, key) => {
        if (checked) {
            access({ partnerId, resources: [key] });
        } else {
            deny({ partnerId, resources: [key] });
        }
    };

    const onCheckAll = () => {
        const dataNotYetChecked = dataSource?.filter((item) => !listAccess[item?.key]); // get all data not yet checked
        access({ partnerId, resources: dataNotYetChecked?.map((item) => item?.key) });
    };
    const onUnCheckAll = () => {
        const dataChecked = dataSource?.filter((item) => !!listAccess[item?.key]); // get all data  checked
        deny({ partnerId, resources: dataChecked?.map((item) => item?.key) });
    };
    const onSearch = (value) => setSearchResource(resources?.filter((item) => findStringToSlug(item?.name,value)));

    const columns = [
        {
            title: "Chức năng",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Cấp phép",
            dataIndex: "checked",
            key: "checked",
            width: 100,
            align: 'center',
            render: (checked, rc) => <Checkbox disabled={!canUpdate} checked={!!get(listAccess,get(rc,'key'))} onChange={(e) => onChange(e.target.checked, get(rc, 'key', ''))} />
        },
    ];

    return (
        <div className='white-box'>
            <Row style={{ marginBottom: 10 }} justify='space-between' align='middle'>
                <Col span={8}>
                    <Search 
                    placeholder='Tìm chức năng'
                    value={keyword} onChange={(e) => {
                        onSearch(e.target.value);
                        setKeyword(e.target.value)
                    }} 
                    enterButton 
                    allowClear    
                    />
                </Col>
                <WithPermission permission={POLICY.update[type]}>
                <Col>
                    <Button onClick={onCheckAll} ghost type='primary' size='small'>Chọn tất cả</Button>
                    <Button onClick={onUnCheckAll} danger size='small'>Bỏ tất cả</Button>
                </Col>
                </WithPermission>
            </Row>
            <TableStickyTop pagination={false} loading={isResourcesLoading} rowKey={rc => rc?.key}  columns={columns} dataSource={dataSource} size="small" />
        </div>
    )
}
