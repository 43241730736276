export const GET_BOARD_SPRINT_REQUEST = 'GET_BOARD_SPRINT_REQUEST';
export const GET_BOARD_SPRINT_SUCCESS = 'GET_BOARD_SPRINT_SUCCESS';
export const GET_BOARD_SPRINT_FAILED = 'GET_BOARD_SPRINT_FAILED';

export const GET_BOARD_SPRINT_BY_ID_REQUEST = 'GET_BOARD_SPRINT_BY_ID_REQUEST';
export const GET_BOARD_SPRINT_BY_ID_SUCCESS = 'GET_BOARD_SPRINT_BY_ID_SUCCESS';
export const GET_BOARD_SPRINT_BY_ID_FAILED = 'GET_BOARD_SPRINT_BY_ID_FAILED';


export const CREATE_BOARD_SPRINT_REQUEST = 'CREATE_BOARD_SPRINT_REQUEST';
export const CREATE_BOARD_SPRINT_SUCCESS = 'CREATE_BOARD_SPRINT_SUCCESS';
export const CREATE_BOARD_SPRINT_FAILED = 'CREATE_BOARD_SPRINT_FAILED';

export const UPDATE_BOARD_SPRINT_REQUEST = 'UPDATE_BOARD_SPRINT_REQUEST';
export const UPDATE_BOARD_SPRINT_SUCCESS = 'UPDATE_BOARD_SPRINT_SUCCESS';
export const UPDATE_BOARD_SPRINT_FAILED = 'UPDATE_BOARD_SPRINT_FAILED';

export const DELETE_BOARD_SPRINT_REQUEST = 'DELETE_BOARD_SPRINT_REQUEST';
export const DELETE_BOARD_SPRINT_SUCCESS = 'DELETE_BOARD_SPRINT_SUCCESS';
export const DELETE_BOARD_SPRINT_FAILED = 'DELETE_BOARD_SPRINT_FAILED';
