import * as Types from '../../constants/actionTypes';
  
  export const getCompanyJobs = query => ({
    type: Types.GET_COMPANY_JOBS_REQUEST,
    payload: query
  });
  export const getCompanyJob = id => ({
    type: Types.GET_COMPANY_JOB_REQUEST,
    payload: id
  });
  export const updateCompanyJob = instance => ({
    type: Types.UPDATE_COMPANY_JOB_REQUEST,
    payload: instance
  });
  export const createCompanyJob = instance => ({
    type: Types.CREATE_COMPANY_JOB_REQUEST,
    payload: instance
  });
  export const deleteCompanyJob = id => ({
    type: Types.DELETE_COMPANY_JOB_REQUEST,
    payload: id
  });
  export const resetCompanyJobState = () => ({
    type: Types.RESET_COMPANY_JOB_STATE
  });
  export const resetCompanyJobStateAction = () => ({
    type: Types.RESET_COMPANY_JOB_ACTION
  });
