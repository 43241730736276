import { useFetchByParam, useFetch } from '~/hooks/utils';

import { getAreas, getCities, getDistricts, getWards } from '~/redux/action';

const getSelector = key => state => state.geo[key];

const areasLoadingSelector = getSelector('isAreasLoading');
const areasSelector = getSelector('areas');
const getAreasFailedSelector = getSelector('getAreasFailed');

const citiesLoadingSelector = getSelector('isCitiesLoading');
const citiesSelector = getSelector('cities');
const getCitiesFailedSelector = getSelector('getCitiesFailed');

const districtsLoadingSelector = getSelector('isDistrictsLoading');
const districtsSelector = getSelector('districts');
const getDistrictsFailedSelector = getSelector('getDistrictsFailed');

const wardsLoadingSelector = getSelector('isWardsLoading');
const wardsSelector = getSelector('wards');
const getWardsFailedSelector = getSelector('getWardsFailed');

export const useAreas = () =>
  useFetch({
    action: getAreas,
    loadingSelector: areasLoadingSelector,
    dataSelector: areasSelector,
    failedSelector: getAreasFailedSelector
  });

export const useCities = () =>
  useFetch({
    action: getCities,
    loadingSelector: citiesLoadingSelector,
    dataSelector: citiesSelector,
    failedSelector: getCitiesFailedSelector
  });

export const useDistricts = cityCode =>
  useFetchByParam({
    action: getDistricts,
    loadingSelector: districtsLoadingSelector,
    dataSelector: districtsSelector,
    failedSelector: getDistrictsFailedSelector,
    param: cityCode
  });

export const useWards = districtCode =>
  useFetchByParam({
    action: getWards,
    loadingSelector: wardsLoadingSelector,
    dataSelector: wardsSelector,
    failedSelector: getWardsFailedSelector,
    param: districtCode
  });
