import requester from './requester';

const BonusIntroCustomer = {
  getById: (id) => requester.get(`/bonus-intro-customer/${id}`),
  create: (config) => requester.post(`/bonus-intro-customer`, config),
  update:(config) => requester.put(`/bonus-intro-customer/${config._id}`,config),
  getAll : (query) => requester.get(`/bonus-intro-customer`,query),
  delete: (id) => requester.delete(`/bonus-intro-customer/${id}`),
  
};

export default BonusIntroCustomer;
