import { Table } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { formatNumberThreeComma } from '~/hooks/utils';
import { useReportCoureTrainingById } from '~/hooks/whReport';
import TableDetail from './TableDetail'

export default function TableTraningSystem({id,setTotalDetailAll,date}) {
  const [data,loading] = useReportCoureTrainingById(id,date)
  const [total,setTotal] = useState(0)
  useEffect(() => {
    const totalData = data?.data?.reduce((total, item) => {
      return {
        countHour : total.countHour + item.time,
        // totalPrice : total.snapShortrateTraining * item.time
      }
    },{
      countHour:0,
      // totalPrice:0
    })
    setTotal(totalData)
    setTotalDetailAll('trainingSystem',get(data,'sumTranininng',0))
  },[data,id])
  const columns = [
    {
      title: 'STT',
      key: null,
      dataIndex: null,
      width: 50,
      align : 'center',
      render: (item,record,index) => (
        <span>
          {index + 1}
        </span>
      )
    },
    {
      title: 'Nhóm đối tác',
      key: 'employeeGroup',
      dataIndex: 'employeeGroup',
      align : 'center',
      render: (item,record) => (
        <span>
         {get(item,'name.vi')}
        </span>
      )
    },
    {
      title: 'Ngày đào tạo',
      key: 'date',
      dataIndex: 'date',
      align : 'center',
      render: (item,record) => (
        <span>
           {moment(item || 0).format('DD/MM/YYYY')}
        </span>
      )
    },
    {
      title: 'Số tiền',
      key: 'total',
      dataIndex: 'total',
      align : 'center',
      render: (item,record) => (
        <span>
         {formatNumberThreeComma(item) || 0}
        </span>
      )
    },
    {
      title: 'Số giờ',
      key: 'time',
      dataIndex: 'time',
      align : 'center',
      render: (item,record) => (
        <span>
         {item || 0}
        </span>
      )
    },
    {
      title: 'Thành tiền',
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      align : 'center',
      render: (item,record) => formatNumberThreeComma(get(record,'total')) || 0
    }
  ];
      const summaryFooter = (datacurrent) =>  <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell align='center' >
        TC
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center' >
        {datacurrent.length || 0}
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        {datacurrent.length || 0}
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        {total.countHour || 0}
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        {formatNumberThreeComma(get(data,'sumTranininng',0))}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  return (
    <div>
        <TableDetail scrollWidth={1000} loading={loading} pagination={false} dataSource={get(data,'data',[])} columns={columns} title={"Thu nhập từ đào tạo hệ thống (C)"} footer={summaryFooter}/>
    </div>
  )
}
