import React, { useEffect, useState } from 'react';

import { get } from 'lodash';
import { Card, Col, Divider, Empty, Image, Row, Space } from 'antd';

import { COMPONENT_MODES, GENDER } from '~/constants/defaultValue';

import AssignedServicesTable from './AssignedServicesTable';
import WorkingDaysTable from './WorkingDaysTable';

import love from "../../assets/images/emojis/love.svg";
import like from "../../assets/images/emojis/like.svg";
import cry from "../../assets/images/emojis/cry.svg";
import happy from "../../assets/images/emojis/happy.svg";
import angry from "../../assets/images/emojis/angry.svg";

import './index.scss';
import api from '~/api';
import { formatNumberThreeComma } from '~/hooks/utils';
import Rating from '../Workspace/Rating';

const WhPartnerDetails = ({ whPartner }) => {
  const [rating,setRating] = useState({})
  useEffect(() => {
    const getRatingById = async () => {
      const res = await api.whPartner.getRatingById(whPartner._id)
      setRating(res)
    }
    getRatingById()
  },[whPartner])
  return (
    <div className='wh-service-detail'>
      <Space direction='vertical' size="middle" style={{ width: "100%" }}>
        <h5 style={{ paddingLeft: '12px', marginBottom: '0px', marginTop: '12px' }}>Thông tin cá nhân</h5>
        <Row>
          <Col span={21}>
            <Space direction='vertical' size="middle" style={{ width: "100%" }}>
            <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
              <Col span={12}>
                <Row justify='space-between'>
                  <div class="wh-partner-detail__row--label">Tên đối tác:</div>
                  <div>{get(whPartner, 'name')}</div>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify='space-between'>
                  <div class="wh-partner-detail__row--label">Giới tính:</div>
                  <div>{get(GENDER[get(whPartner, 'gender')], "vi")}</div>
                </Row>
              </Col>
            </Row>
            <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
              <Col span={12}>
                <Row justify='space-between'>
                  <Col  class="wh-partner-detail__row--label"><b>Hình thức làm việc:</b></Col>
                  <Col ><span style={{textTransform : 'capitalize'}}>{get(whPartner, 'wayWorking','(Chưa có)')}</span></Col>
                </Row>
              </Col>
            </Row>

            <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
              <Col span={12}>
                <Row justify='space-between'>
                  <div class="wh-partner-detail__row--label">Địa chỉ:</div>
                  <div>{get(whPartner, 'address.0.address')}</div>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify='space-between'>
                  <div class="wh-partner-detail__row--label">Khu vực hoạt động:</div>
                  <div>{get(whPartner, 'managementArea.fullAddress')}</div>
                </Row>
              </Col>
            </Row>

            <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
              <Col span={12}>
                <Row justify='space-between'>
                  <div class="wh-partner-detail__row--label">Email:</div>
                  <div>{get(whPartner, 'email')}</div>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify='space-between'>
                  <div class="wh-partner-detail__row--label">Số điện thoại:</div>
                  <div>{get(whPartner, 'phone')}</div>
                </Row>
              </Col>
            </Row>
            </Space>
          </Col>
          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
            {get(whPartner, "avatar")
              ? <Image
                  src={get(whPartner, "avatar")}
                  fallback=""
                  style={{ maxHeight: 150 }}
                  />
              : <Empty description="Chưa có hình ảnh" />
            }
          </Col>
        </Row>

        <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />

        <h5 style={{ paddingLeft: '12px', marginBottom: '0px' }}>Thông tin nghiệp vụ</h5>
        <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Col span={12}>
            <Row justify='space-between'>
              <div class="wh-partner-detail__row--label">Nhóm nhân sự:</div>
              <div>{get(whPartner, 'employeeGroup.name.vi')}</div>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify='space-between'>
              <div class="wh-partner-detail__row--label">Chuyên môn:</div>
              <div>{get(whPartner, 'specialities').map(item => get(item, "name")).join(', ')}</div>
            </Row>
          </Col>
        </Row>

        <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Col span={12}>
            <Row justify='space-between'>
              <div class="wh-partner-detail__row--label">Chức vụ:</div>
              <div>{get(whPartner, 'literacy.position.name.vi')}</div>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify='space-between'>
              <div class="wh-partner-detail__row--label">Bằng cấp:</div>
              <div>{get(whPartner, 'literacy.degree.name.vi')}</div>
            </Row>
          </Col>
        </Row>

        <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Col span={12}>
            <Row justify='space-between'>
              <div class="wh-partner-detail__row--label">Kinh nghiệm:</div>
              <div>{get(whPartner, 'literacy.experience.value')} năm</div>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify='space-between'>
              <div class="wh-partner-detail__row--label">Gói dịch vụ:</div>
              <div>{get(whPartner, 'packageLevel.name.vi')}</div>
            </Row>
          </Col>
          {/* <Col span={12}>
            <Row justify='space-between'>
              <div>Bằng cấp:</div>
              <div>{get(whPartner, 'degree')}</div>
            </Row>
          </Col> */}
        </Row>

        <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Col span={12}>
            <Row justify='space-between'>
              <div class="wh-partner-detail__row--label">Hệ số chênh lệch:</div>
              <div>{get(whPartner, 'extraRate')}</div>
            </Row>
          </Col>
        </Row>

        <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Col span={12}>
            <Row justify='space-between'>
              <div class="wh-partner-detail__row--label">Hệ số:</div>
              <div>{get(whPartner, 'rate')}</div>
            </Row>
          </Col>
        </Row>
        <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Col span={12}>
            <Row justify='space-between'>
              <div class="wh-partner-detail__row--label">Rate đào tạo:</div>
              <div>{get(whPartner, 'rateTraining') ? `${formatNumberThreeComma(get(whPartner, 'rateTraining'))}đ/giờ` : 'Chưa có'}</div>
            </Row>
          </Col>
        </Row>

        <Row className='wh-service-detail__row' gutter={48}>
          <Col span={24}>
            <Card className='card'>
              <h3>Hình ảnh bằng cấp, chứng chỉ</h3>
              <Row gutter={36}>
                {whPartner.literacyImages?.map((item, index) => (
                  <Col key={index} sm={12} lg={6} style={{ display: "flex", justifyContent: "center" }}>
                    <Image height={150} src={item} />
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        </Row>

        <Row className='wh-service-detail__row' gutter={48}>
          <Col span={24}>
            <Card className='card'>
              <h3>Thời gian làm việc</h3>
              <Row gutter={36}>
                <Col span={24}>
                  <WorkingDaysTable
                    mode={COMPONENT_MODES.VIEW}
                    dataSource={get(whPartner, "availableTime")}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row className='wh-service-detail__row'>
          <Col span={24} lg={24}>
            <Card className='card'>
              {/* <Title level={2}> */}
              {/* <h3>
                Mô tả đối tác
              </h3> */}
              <Divider plain>
                <h3>
                  Thông tin khác
                </h3>
                
              </Divider>
              <p
                className='wh-service-detail__description--paragraph'
              >
              <Row className='wh-service-detail__row' gutter={48} style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Col span={24}>
            <Row style={{margin : '10px 0'}}>
              <div class="wh-partner-detail__row--label">Đặc điểm tính cách:</div>
              <div>{get(whPartner, 'genitive')}</div>
            </Row>
          </Col>
          <Col span={24}>
            <Row style={{margin : '10px 0'}}>
              <div class="wh-partner-detail__row--label">Note:</div>
              <div>{get(whPartner, 'note')}</div>
            </Row>
          </Col>
          <Col span={24}>
            <Row style={{margin : '10px 0'}}>
              <div class="wh-partner-detail__row--label">Thông tin giới thiệu:</div>
              <div>{get(whPartner, 'description')}</div>
            </Row>
          </Col>
        </Row>
                
              </p>
              
            </Card>
          </Col>
        </Row>

        <Row className='wh-service-detail__row' gutter={48}>
          <Col span={24}>
            <Card className='card'>
              <h3>Chỉ định dịch vụ</h3>
              <Row gutter={36}>
                <Col span={24}>
                  <AssignedServicesTable
                    mode={COMPONENT_MODES.VIEW}
                    dataSource={get(whPartner, "services")}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {/* <h3 className="wh-service-form__section-title">Điểm khách hàng đánh giá</h3> */}

        {/* <Row className='wh-service-detail__row' gutter={48}>
          <Col span={24}>
            <Card className='card'>
              <h3>Điểm khách hàng đánh giá</h3>
              <Space direction='vertical' size='middle' style={{ width: "100%" }}>
              <Row >
                <div style={{marginRight : '5px'}}>Rate:</div>
                <div>{get(rating, 'resultSum')}</div>
              </Row>
              <Row justify='space-between'>
                <Col span={3}>
                  <Row><Space>
                    <Image src={love} />
                    <div>{get(rating,'ratingLevel.LOVE')}</div>
                  </Space></Row>
                </Col>
                <Col span={3}>
                  <Row><Space>
                    <Image src={like} />
                    <div>{get(rating,'ratingLevel.LIKE')}</div>
                    </Space></Row>
                </Col>
                <Col span={3}>
                  <Row><Space>
                    <Image src={happy} />
                    <div>{get(rating,'ratingLevel.ACCEPTABLE')}</div>
                    </Space></Row>
                </Col>
                <Col span={3}>
                  <Row><Space>
                    <Image src={cry} />
                    <div>{get(rating,'ratingLevel.SAD')}</div>
                  </Space></Row>
                </Col>
                <Col span={3}>
                  <Row><Space>
                    <Image src={angry} />
                    <div>{get(rating,'ratingLevel.ANGRY')}</div>
                  </Space></Row>
                </Col>
              </Row>
              </Space>
            </Card>
          </Col>
        </Row> */}
        <Rating id={get(whPartner,'_id')}/>
        {/* <div className='boxCharacteristic'>
          <Row>
            <Col span={4}><b>Đặc điểm tính cách</b></Col>
            <Col span={20}>content</Col>
          </Row>
          <Row>
            <Col span={4}><b>Note</b></Col>
            <Col span={20}>content</Col>
          </Row>
          <Row>
            <Col span={4}><b>Thưởng</b></Col>
            <Col span={8}>nội dung thưởng</Col>
            <Col span={12}>50.000</Col>
          </Row>
          <Row>
            <Col span={4}><b>Phạt</b></Col>
            <Col span={8}>nội dung phạt</Col>
            <Col span={12}>50.000</Col>
          </Row>
        </div> */}
      </Space>
    </div>
  )
}

export default WhPartnerDetails;
