import requester from './requester';

const KeywordSearchItem = {
  getById: (id) => requester.get(`/suggest-keyword/${id}`),
  create: (config) => requester.post(`/suggest-keyword`, config),
  update:(config) => requester.put(`/suggest-keyword/${config._id}`,config),
  getAll : (query) => requester.get(`/suggest-keyword`,query),
  delete: (id) => requester.delete(`/suggest-keyword/${id}`),
  
};

export default KeywordSearchItem;
