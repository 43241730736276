import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isOverViewLoading: false,
  overView: null,
  getOverViewFailed: null,

  isYearlyLoading: false,
  monthly: [],
  getYearlyFailed: null,

  isQuarterlyLoading: false,
  monthly: [],
  getQuarterlyFailed: null,

  isMonthlyLoading: false,
  monthly: [],
  getMonthlyFailed: null,

  isDailyLoading: false,
  daily: [],
  getDailyFailed: null,

  isMostCustomerOrderLoading: false,
  mostCustomerOrder: [],
  getMostCustomerOrderFailed: null,

  isPharmacyOverviewTodayLoading: false,
  pharmacyOverviewToday: [],
  getPharmacyOverviewTodayFailed: null,

  isPharmacyOverviewDailyLoading: false,
  pharmacyOverviewDaily: [],
  getPharmacyOverviewDailyFailed: null,

  isPharmacyOverviewMonthlyLoading: false,
  pharmacyOverviewMonthly: [],
  getPharmacyOverviewMonthlyFailed: null,

  isPharmacyOverviewQuarterlyLoading: false,
  pharmacyOverviewQuarterly: [],
  getPharmacyOverviewQuarterlyFailed: null,

  isPharmacyOverviewYearlyLoading: false,
  pharmacyOverviewYearly: [],
  getPharmacyOverviewYearlyFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_PHARMACY_OVER_VIEW_REPORT_REQUEST:
      state.isOverViewLoading = true;
      return;

    case Types.GET_PHARMACY_OVER_VIEW_REPORT_SUCCESS:
      state.isOverViewLoading = false;
      state.overView = payload;
      return;

    case Types.GET_PHARMACY_OVER_VIEW_REPORT_FAILED:
      state.isOverViewLoading = false;
      state.getOverViewFailed = payload;
      state.overView = {};
      return;

    case Types.GET_PHARMACY_YEARLY_REPORT_REQUEST:
      state.isYearlyLoading = true;
      return;

    case Types.GET_PHARMACY_YEARLY_REPORT_SUCCESS:
      state.isYearlyLoading = false;
      state.yearly = payload;
      return;

    case Types.GET_PHARMACY_YEARLY_REPORT_FAILED:
      state.isYearlyLoading = false;
      state.getYearlyFailed = payload;
      state.yearly = [];
      return;

    case Types.GET_PHARMACY_QUARTERLY_REPORT_REQUEST:
      state.isQuarterlyLoading = true;
      return;

    case Types.GET_PHARMACY_QUARTERLY_REPORT_SUCCESS:
      state.isQuarterlyLoading = false;
      state.quarterly = payload;
      return;

    case Types.GET_PHARMACY_QUARTERLY_REPORT_FAILED:
      state.isQuarterlyLoading = false;
      state.getQuarterlyFailed = payload;
      state.quarterly = [];
      return;

    case Types.GET_PHARMACY_MONTHLY_REPORT_REQUEST:
      state.isMonthlyLoading = true;
      return;

    case Types.GET_PHARMACY_MONTHLY_REPORT_SUCCESS:
      state.isMonthlyLoading = false;
      state.monthly = payload;
      return;

    case Types.GET_PHARMACY_MONTHLY_REPORT_FAILED:
      state.isMonthlyLoading = false;
      state.getMonthlyFailed = payload;
      state.monthly = [];
      return;

    case Types.GET_PHARMACY_DAILY_REPORT_REQUEST:
      state.isDailyLoading = true;
      return;

    case Types.GET_PHARMACY_DAILY_REPORT_SUCCESS:
      state.isDailyLoading = false;
      state.daily = payload;
      return;

    case Types.GET_PHARMACY_DAILY_REPORT_FAILED:
      state.isDailyLoading = false;
      state.getDailyFailed = payload;
      state.daily = [];
      return;

    case Types.GET_PHARMACY_MOST_CUSTOMER_ORDER_REPORT_REQUEST:
      state.isMostCustomerOrderLoading = true;
      return;

    case Types.GET_PHARMACY_MOST_CUSTOMER_ORDER_REPORT_SUCCESS:
      state.isMostServiceLoading = false;
      state.mostCustomerOrder = payload;
      return;

    case Types.GET_PHARMACY_MOST_CUSTOMER_ORDER_REPORT_FAILED:
      state.isMostCustomerOrderLoading = false;
      state.getMostCustomerOrderFailed = payload;
      state.mostCustomerOrder = [];
      return;

    case Types.GET_PHARMACY_OVERVIEW_TODAY_REQUEST:
      state.isPharmacyOverviewTodayLoading = true;
      return;

    case Types.GET_PHARMACY_OVERVIEW_TODAY_SUCCESS:
      state.isPharmacyOverviewTodayLoading = false;
      state.pharmacyOverviewToday = payload;
      return;

    case Types.GET_PHARMACY_OVERVIEW_TODAY_FAILED:
      state.isPharmacyOverviewTodayLoading = false;
      state.getPharmacyOverviewTodayFailed = payload;
      state.pharmacyOverviewToday = [];
      return;

    case Types.GET_PHARMACY_OVERVIEW_DAILY_REQUEST:
      state.isPharmacyOverviewDailyLoading = true;
      return;

    case Types.GET_PHARMACY_OVERVIEW_DAILY_SUCCESS:
      state.isPharmacyOverviewDailyLoading = false;
      state.pharmacyOverviewDaily = payload;
      return;

    case Types.GET_PHARMACY_OVERVIEW_DAILY_FAILED:
      state.isPharmacyOverviewDailyLoading = false;
      state.getPharmacyOverviewDailyFailed = payload;
      state.pharmacyOverviewDaily = [];
      return;

    case Types.GET_PHARMACY_OVERVIEW_MONTHLY_REQUEST:
      state.isPharmacyOverviewMonthlyLoading = true;
      return;

    case Types.GET_PHARMACY_OVERVIEW_MONTHLY_SUCCESS:
      state.isPharmacyOverviewMonthlyLoading = false;
      state.pharmacyOverviewMonthly = payload;
      return;

    case Types.GET_PHARMACY_OVERVIEW_MONTHLY_FAILED:
      state.isPharmacyOverviewMonthlyLoading = false;
      state.getPharmacyOverviewMonthlyFailed = payload;
      state.pharmacyOverviewMonthly = [];
      return;

    case Types.GET_PHARMACY_OVERVIEW_QUARTERLY_REQUEST:
      state.isPharmacyOverviewQuarterlyLoading = true;
      return;

    case Types.GET_PHARMACY_OVERVIEW_QUARTERLY_SUCCESS:
      state.isPharmacyOverviewQuarterlyLoading = false;
      state.pharmacyOverviewQuarterly = payload;
      return;

    case Types.GET_PHARMACY_OVERVIEW_QUARTERLY_FAILED:
      state.isPharmacyOverviewQuarterlyLoading = false;
      state.getPharmacyOverviewQuarterlyFailed = payload;
      state.pharmacyOverviewQuarterly = [];
      return;

    case Types.GET_PHARMACY_OVERVIEW_YEARLY_REQUEST:
      state.isPharmacyOverviewYearlyLoading = true;
      return;

    case Types.GET_PHARMACY_OVERVIEW_YEARLY_SUCCESS:
      state.isPharmacyOverviewYearlyLoading = false;
      state.pharmacyOverviewYearly = payload;
      return;

    case Types.GET_PHARMACY_OVERVIEW_YEARLY_FAILED:
      state.isPharmacyOverviewYearlyLoading = false;
      state.getPharmacyOverviewYearlyFailed = payload;
      state.pharmacyOverviewYearly = [];
      return;

    case Types.RESET_DASHBOARD_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
