import React from 'react';

import { get } from 'lodash';

import { Col, Image, Space } from 'antd';

const WhServiceQuestionOptionView = ({
  className,
  language,
  option
}) => {
  const { title } = option;

  return (
    <Col className={`${className}`} sm={12} lg={6} flex={1}>
      <Space direction='vertical' size="middle">
        <Image
          src={option?.image}
        />
        <p style={{ textAlign: "center" }}>{get(title, language)}</p>
      </Space>
    </Col>
  )
}

export default WhServiceQuestionOptionView;
