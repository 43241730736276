import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Row, Col, Button, Skeleton, Switch } from 'antd';
import UploadImage from '~/components/Utils/UploadImage';
import { LANGUAGE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import {
  useCreateNewsCategory,
  useNewsCategory,
  useInitNewsCategory,
  useResetNewsCategory,
  useUpdateNewsCategory,
  SECURITY_VALUE
} from '~/hooks/newsCategory';
import { useFormItemMargin } from '~/hooks/utils';
import './form.scss';
import { useParams } from 'react-router-dom';
import SelectLanguage from '~/components/Utils/SelectLanguage';
import Breadcrumb from '~/components/Common/Breadcrumb';
const SECURITY = (key=true)=> !!key ? "Hiện" : 'Ẩn'

const FormItem = Form.Item;

const NewsCategoryForm = () => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState(LANGUAGE.VI);
  const [logo, setLogo] = useState();

  const [isSubmitLoading, handleCreate] = useCreateNewsCategory();
  const [, handleUpdate] = useUpdateNewsCategory();

  const { id } = useParams();
  const [newsCategory, isLoading] = useNewsCategory(id);
  const initNewsCategory = useInitNewsCategory(newsCategory);
  const margin = useFormItemMargin();

  useEffect(() => {
    form.resetFields();
    const { photo } = initNewsCategory;
    if (photo) {
      setLogo(photo);
    }
    form.setFieldsValue({security:initNewsCategory.security })
  }, [initNewsCategory, form]);

  useResetNewsCategory();

  const onFinish = useCallback(
    (values) => {
      const newsCategory = {
        ...values,
        security : SECURITY_VALUE(values.security),
        photo: logo
      };
      if (id) {
        handleUpdate({ ...newsCategory, id });
      } else {
        handleCreate(newsCategory);
      }

    },
    [handleCreate, handleUpdate, id, logo,form]
  );

  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="news-category-form page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} danh mục tin tức`}
          routes={[
            {
              path: PATH_APP.newsCategory.root,
              title: 'Danh sách danh mục tin tức'
            }
          ]}
        />

        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initNewsCategory}
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
        >
          <SelectLanguage value={language} onChange={setLanguage} />

          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="news-category-form__logo-row"
          >
            <Col span={12}>
              <FormItem
                label="Tên danh mục"
                name={['name', LANGUAGE.VI]}
                className={language === LANGUAGE.EN && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>

              <FormItem
                label="Tên danh mục"
                name={['name', LANGUAGE.EN]}
                className={language === LANGUAGE.VI && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>

              <FormItem label="Đường dẫn" name="slug">
                {renderInput(<Input />)}
              </FormItem>

              <FormItem label="Hiển thị" name="security" valuePropName="checked">
                 {renderInput(<Switch  checkedChildren={SECURITY(true)} unCheckedChildren={SECURITY(false)} />)}
              </FormItem>
    
            </Col>
            <Col span={12} className="news-category-form__upload-logo">
              <UploadImage onChange={setLogo} imgUrl={logo} title="Avatar" />
            </Col>
          </Row>

          <Row style={{ marginLeft: margin }}>
            <FormItem
              label="Mô tả ngắn"
              name={['description', LANGUAGE.VI]}
              className={`news-category-form__description ${
                language === LANGUAGE.EN && 'hiden'
              }`}
              labelCol={{ sm: 24, md: 24, lg: 3 }}
              wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            >
              {renderInput(<Input.TextArea rows={2} />)}
            </FormItem>

            <FormItem
              labelCol={{ sm: 24, md: 24, lg: 3 }}
              wrapperCol={{ sm: 24, md: 24, lg: 21 }}
              label="Mô tả ngắn"
              name={['description', LANGUAGE.EN]}
              className={`news-category-form__description ${
                language === LANGUAGE.VI && 'hiden'
              }`}
            >
              {renderInput(<Input.TextArea rows={2} />)}
            </FormItem>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Meta title"
                name={['metaTitle', LANGUAGE.VI]}
                className={language === LANGUAGE.EN && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>

              <FormItem
                label="Meta title"
                name={['metaTitle', LANGUAGE.EN]}
                className={language === LANGUAGE.VI && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Meta keywords"
                name={['metaKeywords', LANGUAGE.VI]}
                className={language === LANGUAGE.EN && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>

              <FormItem
                label="Meta keywords"
                name={['metaKeywords', LANGUAGE.EN]}
                className={language === LANGUAGE.VI && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>
            </Col>
          </Row>

          <Row style={{ marginLeft: margin }}>
            <FormItem
              label="Meta description"
              name={['metaDescription', LANGUAGE.VI]}
              className={`news-category-form__description ${
                language === LANGUAGE.EN && 'hiden'
              }`}
              labelCol={{ sm: 24, md: 24, lg: 3 }}
              wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            >
              {renderInput(<Input.TextArea rows={2} />)}
            </FormItem>

            <FormItem
              label="Meta description"
              name={['metaDescription', LANGUAGE.EN]}
              className={`news-category-form__description ${
                language === LANGUAGE.VI && 'hiden'
              }`}
              labelCol={{ sm: 24, md: 24, lg: 3 }}
              wrapperCol={{ sm: 24, md: 24, lg: 21 }}
            >
              {renderInput(<Input.TextArea rows={2} />)}
            </FormItem>
          </Row>

          <Row className="news-category-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.newsCategory.root}>
                <Button>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default NewsCategoryForm;
