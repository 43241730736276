import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import { selectBranch } from '~/redux/action';
import {
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_FAILED,
  GET_TRANSACTIONS_PARTNER_REQUEST,
  GET_TRANSACTIONS_PARTNER_SUCCESS,
  GET_TRANSACTIONS_PARTNER_FAILED,
} from '~/constants/actionTypes';

function* getTransactions({ payload: query }) {
  try {
    const res = yield call(Api.Transaction.getTransactions, query);
    yield put({ type: GET_TRANSACTIONS_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: GET_TRANSACTIONS_FAILED, payload: error });
  }
}
function* getTransactionsPartner({ payload: query }) {
  try {
    console.log('resSaga')
    const res = yield call(Api.Transaction.getTransactionById, query);
    yield put({ type: GET_TRANSACTIONS_PARTNER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: GET_TRANSACTIONS_PARTNER_FAILED, payload: error });
  }
}
export default function* userSaga() {
  yield takeLatest(GET_TRANSACTIONS_REQUEST, getTransactions);
  yield takeLatest(GET_TRANSACTIONS_PARTNER_REQUEST, getTransactionsPartner);
}
