import React, { useCallback, useState } from 'react'

export default function FieldItem({ value, FormInput,placeholder="-" }) {
    const [isEdit, setIsEdit] = useState(false);
    const onClose = useCallback(() => setIsEdit(false),[]);
    const onOpen = useCallback(() => setIsEdit(true),[]);
    return (
        <div className='FieldItem' onBlur={onClose} onClick={onOpen}>
            {
                isEdit
                    ? FormInput(onClose)
                    : <div className='FieldItem--value'>{value ?? placeholder ?? "-"}</div>
            }
        </div>
    )
}
