import { Col, Row, Typography } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { v4 } from 'uuid';
import api from '~/api';
import { useFetchState } from '~/hooks/utils';
import { PATH_APP } from '~/routes/paths';
import { Breadcrumb } from '../Common';
import EmptyList from '../Common/EmptyList';
import LinkFile from '../Common/TypeFile/LinkFileImage';
import YoutubeEmbed from '../Common/YoutubeEmbed';
import LoadingWhBill from '../WhBill/LoadingWhBill';

export default function TutorialJob() {
    const [data, loading] = useFetchState({ api: api.SettingTutorialJob.getTutorialJobs, query: 'admin', useDocs: false });
    console.log(data, 'data');
    return (
        <div className="page-wraper page-content">
            {loading && <LoadingWhBill open={loading} />}
            <div className="container-fluid">
                <Breadcrumb title="Hướng dẫn sử dụng ứng tuyển cho nhân viên hệ thống" />
                {get(data, 'files.length', 0) === 0 && !get(data, 'youtubeId') && <EmptyList>
                    <Typography.Text style={{textAlign : 'center'}} strong type='secondary'>Chưa được thiếp lập vui lòng vào <Typography.Link href={PATH_APP.settingTutorialJob.root}>Đây</Typography.Link> để thiếp lập!</Typography.Text>
                </EmptyList>}
                <Row>
                  {get(data, 'files.length') ?  <Col span={12}>
                        <h4>File đính kèm</h4>
                        <ul>
                            {get(data, 'files')?.map((item) => <li key={v4()}>
                                <div className='d-flex' style={{ gap: 5 }}>
                                    <span>{get(item, 'title')}: </span>
                                    <LinkFile
                                        href={get(item, 'file.response.url')}
                                        url={get(item, 'file.name')}
                                    />
                                </div>
                            </li>)}
                        </ul>
                    </Col> : null}
                    <Col span={12}>
                        {get(data, 'youtubeId') && <>
                            <h4>Video Hướng dẫn</h4>
                            <YoutubeEmbed id={get(data, 'youtubeId')} />
                        </>}
                    </Col>
                </Row>
            </div>
        </div>
    )
}
