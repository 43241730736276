import React from 'react';

import { get } from 'lodash';
import { Select, Tag } from 'antd';

import { WH_BILL_STATUS } from '~/constants/defaultValue';

const mapBillStatusTag = {
  NEW: { name: 'Mới', color: 'geekblue' },
  PREPAYMENT_CONFIRMED: { name: 'Xác nhận cọc', color: 'orange' },
  IN_PROGRESS: { name: 'Đang thực hiện', color: 'processing' },
  COMPLETED: { name: 'Đã hoàn thành', color: 'success' },
  CANCELLED: { name: 'Đã hủy', color: 'error' }
};

const CustomerAndBillStatus = ({ whBill, editable, updateWhBill }) => {
  const onChange = (value) => {
    updateWhBill({ status: value, _id: get(whBill, '_id') });
  };

  return !editable ? (
    <Tag color={get(mapBillStatusTag, `${get(whBill, 'status')}.color`)}>
      {get(mapBillStatusTag, `${get(whBill, 'status')}.name`)}
    </Tag>
  ) : (
    <Select
      style={{ minWidth: '200px' }}
      // defaultValue={get(whBill, "status")}
      value={get(whBill, 'status')}
      onChange={onChange}
    >
      {Object.keys(WH_BILL_STATUS).map((item, index) => (
        <Select.Option key={index} value={item}>
          {get(WH_BILL_STATUS, item)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CustomerAndBillStatus;
