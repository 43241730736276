import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  useBranchIdSessionStorage
} from '~/hooks/utils';

import {
  getDeliveryVouchers,
  createDeliveryVoucher,
  deleteDeliveryVoucher,
  getDeliveryVoucher,
  resetDeliveryVoucherState,
  updateDeliveryVoucher
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory, useParams,useRouteMatch } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import moment from 'moment';
import { get, isString } from 'lodash';

const DELIVERY_VOUCHER = 'deliveryVoucher';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(DELIVERY_VOUCHER);

const getSelector = (key) => (state) => state[DELIVERY_VOUCHER][key];
const pagingSelector = getSelector('paging');

export const useDeliveryVoucherQueryParams = (module) => {
  const prevKeyword = useRef(null);
  // const [branchId] = useBranchIdSessionStorage();
  const branchIds = module?.branchId || undefined;
  const query = useQueryParams();
  const keyword = query.get('keyword');
  const codeVoucher = query.get("codeVoucher");
  const from = query.get("from");
  const to = query.get("to");
  const name = module?.name || query.get("name");
  const [page, setPage] = useState(query.get('page') || 1)
  const [limit, setLimit] = useState(query.get('limit') || 10)
  const startDate = query.get("startDate") || null
  const endDate = query.get("endDate") || null
  const status = query.get("status") || null
  
  const onTableChange = ({ current, pageSize }) => {
    setPage(current);
    setLimit(pageSize);
  };

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      codeVoucher,
      name,
      startDate,
      endDate,
      branchIds,
      status,
      from,
      to
    });

    return [queryParams,onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    name,
    branchIds,
    startDate,
    endDate,
    codeVoucher,
    status,
    from,
    to
  ]);
};

export const useDeliveryVoucherParams = (query) => {
  const { path, url } = useRouteMatch()
  const history = useHistory();
  const [keyword, setKeyword] = useState(query?.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    if(!param.code)
     for (const key in param) {
        param[key]=  isString(param[key]) ? (param[key].trim()).replace(/[.]/g,'') : param[key]
     }
    history.push({
      pathname: `${path}`,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useDeliveryVouchers = (query) => {
  return useFetchByParam({
    action: getDeliveryVouchers,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useDeliveryVoucher = (params) => {
  return useFetchByParam({
    action: getDeliveryVoucher,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useCreatedeliveryVoucher = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới phiếu luân chuyển thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới phiếu luân chuyển thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createDeliveryVoucher
  });
};

export const useUpdatedeliveryVoucher = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật trạng thái phiếu luân chuyển thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật trạng thái phiếu luân chuyển thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateDeliveryVoucher
  });
};

export const useDeleteDeliveryVoucher = (callback) => {
  useSuccess(
    deleteSuccessSelector,
    'Huỷ phiếu luân chuyển thành công',
    callback
  );
  useFailed(deleteFailedSelector, 'Huỷ phiếu luân chuyển thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteDeliveryVoucher
  });
};

// export const useInitDeliveryVoucher = (data) => {
//   return useMemo(() => {
//     if (!data) {
//       return;
//     } else {
//       {
//         codeSequence: data.codeSequence,
//         dateUpdate,
//           note,
//           from:''
        
//         // from: {
//         //   branchId: undefined, warehouseId: undefined
//         // },
  
      
//         to
//         :
//         { warehouseId: undefined }
//       }
//     }
//   }, data)
// }


export const useDeliveryVoucherPaging = () => useSelector(pagingSelector);


export const useResetDeliveryVoucher = () => {
  useResetState(resetDeliveryVoucherState);
};
