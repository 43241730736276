import React from 'react';

import { Modal, Space } from 'antd';
import { get } from 'lodash';

import { useUserWorkspace } from '~/hooks';

import { COMPONENT_MODES, WH_BILL_ITEM_STATUS } from '~/constants/defaultValue';

import WhPartnerBillItemCardCollapse from './WhBillItem/WhPartnerBillItemCardCollapse/index';
import WhPartnerBillItemCardsList from './WhBillItem/WhPartnerBillItemCardsList';
import WhPartnerMiniForm from './WhPartner/WhPartnerMiniForm';
import FormReasonCancel from './WhBillItem/WhPartnerBillItemCardCollapse/FormReasonCancel';

const verticalLayout = {
  wrapperCol: { sm: 24 },
  labelCol: { xs: 8, sm: 8 },
};

const WorkspacePendingTasks = ({
}) => {
  const [profile] = useUserWorkspace();
  const id = get(profile, "_id");
  const itemComponentProps = {
    whPartnerId: id,
  }
  return (
    <div className='page-wraper page-content page-content-workSpace'>
      <Space direction='vertical' style={{ width: "100%" }} size="middle">
        <WhPartnerMiniForm
          whPartnerId={id}
          showAssignedServicesTable={true}
          showWorkingDaysTable={false}
          mode={COMPONENT_MODES.VIEW}
        />
        
        <WhPartnerBillItemCardsList
          showRequestCancel
          whPartnerId={id}
          status={WH_BILL_ITEM_STATUS.CONFIRMED}
          itemComponent={WhPartnerBillItemCardCollapse}
          itemComponentProps={itemComponentProps}
        />
      </Space>
     
    </div>
  )
}

export default WorkspacePendingTasks;
