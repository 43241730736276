export const GET_INTRO_USING_REF_CODES_REQUEST =
  'GET_INTRO_USING_REF_CODES_REQUEST';
export const GET_INTRO_USING_REF_CODES_SUCCESS =
  'GET_INTRO_USING_REF_CODES_SUCCESS';
export const GET_INTRO_USING_REF_CODES_FAILED = 'GET_INTRO_USING_REF_CODES_FAILED';

export const GET_INTRO_USING_REF_CODE_REQUEST = 'GET_INTRO_USING_REF_CODE_REQUEST';
export const GET_INTRO_USING_REF_CODE_SUCCESS = 'GET_INTRO_USING_REF_CODE_SUCCESS';
export const GET_INTRO_USING_REF_CODE_FAILED = 'GET_INTRO_USING_REF_CODE_FAILED';

export const CREATE_INTRO_USING_REF_CODE_REQUEST =
  'CREATE_INTRO_USING_REF_CODE_REQUEST';
export const CREATE_INTRO_USING_REF_CODE_SUCCESS =
  'CREATE_INTRO_USING_REF_CODE_SUCCESS';
export const CREATE_INTRO_USING_REF_CODE_FAILED =
  'CREATE_INTRO_USING_REF_CODE_FAILED';

export const UPDATE_INTRO_USING_REF_CODE_REQUEST =
  'UPDATE_INTRO_USING_REF_CODE_REQUEST';
export const UPDATE_INTRO_USING_REF_CODE_SUCCESS =
  'UPDATE_INTRO_USING_REF_CODE_SUCCESS';
export const UPDATE_INTRO_USING_REF_CODE_FAILED =
  'UPDATE_INTRO_USING_REF_CODE_FAILED';

export const DELETE_INTRO_USING_REF_CODE_REQUEST =
  'DELETE_INTRO_USING_REF_CODE_REQUEST';
export const DELETE_INTRO_USING_REF_CODE_SUCCESS =
  'DELETE_INTRO_USING_REF_CODE_SUCCESS';
export const DELETE_INTRO_USING_REF_CODE_FAILED =
  'DELETE_INTRO_USING_REF_CODE_FAILED';


export const RESET_INTRO_USING_REF_CODE_STATE = 'RESET_INTRO_USING_REF_CODE_STATE';
export const RESET_INTRO_USING_REF_CODE_STATE_ACTION = 'RESET_INTRO_USING_REF_CODE_STATE_ACTION';
