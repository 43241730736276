import { Button, Col, Form, Input, Modal, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import SkeletonInput from 'antd/lib/skeleton/Input';
import React, { useEffect } from 'react';
import {
  useCreateMedicalEquipment,
  useMedicalEquipment,
  useUpdateMedicalEquipment
} from '~/hooks/medicalEquipment';
import { formatNumberThreeComma } from '~/hooks/utils';
import '.././index.scss';
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    lg: { span: 10 },
    xl: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    lg: { span: 14 },
    xl: { span: 14 }
  }
};
const fullWidthStyle = {
  width: '100%'
};
export default function MedicalEquipmentForm({
  isOpenForm,
  setIsOpenForm,
  id,
  setId,
  handleCloseForm
}) {

  const [form] = Form.useForm();

  const [loadingSubmit, createMedicalEquipment] = useCreateMedicalEquipment(handleCloseForm);
  const [, updateMedicalEquipment] = useUpdateMedicalEquipment(handleCloseForm);
  const [equipment, loading] = useMedicalEquipment(id);

  useEffect(() => {
    form.resetFields();
    if (equipment) {
      form.setFieldsValue(equipment);
    }
  }, [equipment]);

  const onFinish = (values) => {
    if (id) {
      updateMedicalEquipment({ ...values, id });
    } else {
      createMedicalEquipment(values);
    }
  };

  return (
  
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError
        requiredMark={false}
        className="warehouse__form"
      >
        <FormItem
          {...formItemLayout}
          label="Tên loại thiết bị y tế(vi)"
          name={['name', 'vi']}
          rules={[
            {
              required: true,
              message: 'Xin mời nhập tên loại thiết bị y tế (tiếng việt)'
            }
          ]}
        >
          {loading ? (
            <SkeletonInput />
          ) : (
            <Input
              style={{ ...fullWidthStyle }}
              formatter={(value) => formatNumberThreeComma(value)}
            />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Tên loại thiết bị y tế(en)"
          name={['name', 'en']}
          rules={[
            { 
              required: true,
              message: 'Xin mời nhập tên loại thiết bị y tế (tiếng anh)',
            }
          ]}
        >
          {loading ? (
            <SkeletonInput />
          ) : (
              <Input
              // formatter={(value) => console.log(value)}
              style={{ ...fullWidthStyle }}
            />
          )}
        </FormItem>
        <Row
          align='center'
        >
          <Col
          align='center'
          span={4}>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Col>
          <Col
          align='center'
          span={4}>
            <Button onClick={handleCloseForm}>Huỷ</Button>
          </Col>
        </Row>
      </Form>
  );
}
