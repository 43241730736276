import produce from "immer"
import * as Types from '../../constants/actionTypes';
const initialState = {
  isGetLoading: false,
  isUpdateLoading: false,

  notifyAccounts: {},
  getNotifyAccountsFailed: null,

  updateNotifyAccountsFailed: null,
  updateNotifyAccountsSuccess: null, 


  

}

export default produce((state, {type, payload})=> {
  switch(type){
 
    case Types.GET_NOTIFY_ACCOUNTS_REQUEST: {
      state.isGetLoading = true;
      state.getNotifyAccountsFailed = null;
      return;
    };

    case Types.GET_NOTIFY_ACCOUNTS_FAILED: {
      state.isGetLoading = false;
      state.getNotifyAccountsFailed = payload;
      return;
    };

    case Types.GET_NOTIFY_ACCOUNTS_SUCCESS: {
      state.isGetLoading = false;
      state.getNotifyAccountsFailed = null;
      state.notifyAccounts = payload;
      return;
    }


    case Types.UPDATE_NOTIFY_ACCOUNTS_REQUEST: {
      state.isUpdateLoading = true;
      state.updateNotifyAccountsFailed = null;
      state.updateNotifyAccountsSuccess = null;
      return;
    };
    case Types.UPDATE_NOTIFY_ACCOUNTS_FAILED: {
      state.isUpdateLoading = false;
      state.updateNotifyAccountsFailed = payload;
      state.updateNotifyAccountsSuccess = null;
      return;
    };
    case Types.UPDATE_NOTIFY_ACCOUNTS_SUCCESS: {
      state.isUpdateLoading = false;
      state.updateNotifyAccountsFailed = null;
      state.updateNotifyAccountsSuccess = payload;
    }



  }
}, initialState);
