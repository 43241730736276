import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getGiveCoupons({ payload: query }) {
    try {
      const response = yield call(Api.giveCoupon.getAll, query);
      yield put({ type: Types.GET_GIVE_COUPONS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_GIVE_COUPONS_FAILED, payload: error });
    }
  }
function* getGiveCoupon({ payload: id }) {
    try {
      const response = yield call(Api.giveCoupon.getOne, id);
      yield put({ type: Types.GET_GIVE_COUPON_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_GIVE_COUPON_FAILED, payload: error });
    }
  }
  function* createGiveCoupon(action) {
    try {
      const data = yield call(Api.giveCoupon.create, action.payload);
      yield put({ type: Types.CREATE_GIVE_COUPON_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_GIVE_COUPON_FAILED, payload: error });
    }
  }
  function* updateGiveCoupon(action) {
    try {
      const data = yield call(Api.giveCoupon.update, action.payload);
      yield put({ type: Types.UPDATE_GIVE_COUPON_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_GIVE_COUPON_FAILED, payload: error });
    }
  }
  function* deleteGiveCoupon(action) {
    try {
      const data = yield call(Api.giveCoupon.delete, action.payload);
      yield put({ type: Types.DELETE_GIVE_COUPON_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_GIVE_COUPON_FAILED, payload: error });
    }
  }
export default function* GiveCoupons() {
    yield takeLatest(Types.GET_GIVE_COUPONS_REQUEST, getGiveCoupons);
    yield takeLatest(Types.GET_GIVE_COUPON_REQUEST, getGiveCoupon);
    yield takeLatest(Types.CREATE_GIVE_COUPON_REQUEST, createGiveCoupon);
    yield takeLatest(Types.UPDATE_GIVE_COUPON_REQUEST, updateGiveCoupon);
    yield takeLatest(Types.DELETE_GIVE_COUPON_REQUEST, deleteGiveCoupon);
  }
