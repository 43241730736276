import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import POLICIES from '~/constants/policy'
import { WithPermission } from '../Common'

export default function BtnAdd({onClick}) {
    return (
        <WithPermission permission={POLICIES.WRITE_BANNER}>
            <Button type='dashed' onClick={onClick} icon={<PlusOutlined />} />
        </WithPermission>
    )
}
