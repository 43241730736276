import {
  Button, Col, Form,
  Input, Radio, Row, Select, Skeleton
} from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useCreateJob, useJob, useResetJob,
  useUpdateJob
} from '../../hooks/job';
import { PATH_APP } from '../../routes/paths';

import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { useCities, useCustomEditorToolbar } from '~/hooks';
import { filterOptionSlug } from '~/utils/helper';
import Editor from '../../components/Utils/Editor';
import SelectCompanyJob from '../CompanyJob/SelectCompanyJob';
import SelectPositionJob from '../PositionJob/SelectPositionJob';
import UploadImage from '../Utils/UploadImage';
import { LANGUAGE } from '~/constants/defaultValue';
import LangSelect from '../Common/LangSelect';
import LangFormItem from '../Common/LangFormItem';
const layout = {
    labelCol: {
        span: 2,
    },
    wrapperCol: {
        span: 22,
    },
}

const FormItem = Form.Item;

const FormJob = () => {
    const [language,setLanguage] = useState(LANGUAGE.VI);
    const refEditor = useRef();
    useCustomEditorToolbar(refEditor);
    const [form] = Form.useForm();
    const [isSubmitLoading, handleCreate] = useCreateJob();
    const [, handleUpdate] = useUpdateJob();
    const [cities, isCitiesLoading] = useCities();
    const optionsCities = useMemo(() => cities?.map((item) => ({
        label : get(item,'name',''),
        value : get(item,'code',''),
    })),[cities]);
    const { id } = useParams();
    const [job, isLoading] = useJob(id);
    useEffect(() => {
        if (job) {
            form.setFieldsValue({
                ...job
            });
            console.log(form.getFieldsValue());
        }
    }, [job,id]);
    useResetJob();

    const onFinish = useCallback(
        (values) => {
            const job = {
                ...values,

            };

            if (id) {
                handleUpdate({ ...job, _id: id });
            } else {
                handleCreate(job);
            }
        },
        [handleCreate, handleUpdate, id, form]
    );
    const renderInput = (InputComponent) =>
        isLoading ? <Skeleton.Input active /> : InputComponent;

    return (
        <div className="news-category-form page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb
                    title={`${id ? 'Cập nhật' : 'Tạo mới'} tuyển dụng`}
                />
                <Form
                    form={form}
                    autoComplete="off"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={{ status: "ACTIVE" }}
                    requiredMark={false}
                    labelCol={{ sm: 24, md: 24, lg: 4 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 20 }}
                >
                    <LangSelect language={language} setLanguage={setLanguage}/>
                    <Row justify='space-between'>
                        <Col lg={12} xs={24}>
                            <Row gutter={12} align="start" justify="space-between"
                            >
                                <Col span={24}>
                                    <FormItem
                                        label="Trạng thái"
                                        name="status"
                                    >
                                        {renderInput(<Radio.Group buttonStyle="solid">
                                            <Radio.Button value="ACTIVE">Đang tuyển</Radio.Button>
                                            <Radio.Button value="INACTIVE">Ngừng tuyển</Radio.Button>
                                        </Radio.Group>)}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row
                                gutter={12}
                                align="middle"
                                justify="space-between"
                            >
                                <Col span={24}>
                                    <LangFormItem
                                        language={language}
                                        label="Tiêu đề"
                                        fieldName={'title'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Xin vui lòng nhập tiêu đề!'
                                            }
                                        ]}
                                    >
                                        {renderInput(<Input />)}
                                    </LangFormItem>

                                </Col>
                            </Row>

                            <Row gutter={12} align="start" justify="space-between">
                                <Col span={24}>
                                        <Form.Item shouldUpdate noStyle>
                                            {({getFieldValue}) => <SelectPositionJob value={getFieldValue('category')}/>}
                                        </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12} align="start" justify="space-between">
                                <Col span={24}>
                                        <SelectCompanyJob />
                                </Col>
                            </Row>
                            <Row gutter={12} align="start" justify="space-between">
                                <Col span={24}>
                                    <FormItem
                                        label="Khu vực"
                                        name="cityCode"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Xin vui lòng chọn Khu vực!'
                                            }
                                        ]}
                                    >
                                        <Select
                                            mode='multiple'
                                            loading={isCitiesLoading}
                                            showSearch
                                            autoComplete="disabled"
                                            filterOption={filterOptionSlug}
                                            options={optionsCities}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={11} xs={24}>
                        <Form.Item shouldUpdate noStyle>
                            {({ getFieldValue, setFieldsValue }) => 
                            <Form.Item noStyle name={'banner'}>
                                <UploadImage
                                className='banner-job'
                                imgUrl={getFieldValue('banner')} 
                                onChange={(url) => setFieldsValue({
                                    banner: url
                                })} title={'Ảnh Bìa'} />
                            </Form.Item>}
                        </Form.Item>
                        </Col>
                    </Row>

                    <div ref={refEditor}>
                    <LangFormItem
                        language={language}
                        {...layout}
                        label="Nội dung"
                        fieldName={'content'}
                    >
                        <Editor />
                    </LangFormItem>
                    </div>

                    <Row justify='center'>
                        <Link to={PATH_APP.job.root}>
                            <Button>Huỷ</Button>
                        </Link>
                        <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
                            {id ? 'Cập nhật' : 'Tạo mới'}
                        </Button>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default FormJob;
