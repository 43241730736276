import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Skeleton,
  Space,
  Tabs
} from 'antd';

import { get } from 'lodash';
import { BaseBorderBox } from '../Common';
import { formatNumberThreeComma } from '~/hooks/utils';

const Printer = ({ url }) => {
  const docs = [
    { uri: url }
    // { uri: "https://url-to-my-pdf.pdf" },
    // { uri: require("./example-files/pdf.pdf") }, // Local File
  ];

  return <DocViewer documents={docs} />;
};
const FormItem = Form.Item;
const { TabPane } = Tabs;
const { confirm } = Modal;

const DEFAULT_ACCOUNT = 1111;
const mainRowGutter = 24;

const WhTransactionBill = ({ whBill, employee, nameAccount, payer, amountOfMoney }) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState(null)

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(!whBill);
    form.setFieldsValue(whBill)
    form.setFieldsValue({employeeName: employee})
    form.setFieldsValue({nameAccount: nameAccount?.fullName})
    form.setFieldsValue({username: nameAccount?.username})
    form.setFieldsValue({payer: payer})
    form.setFieldsValue({amountOfMoney: amountOfMoney})
    form.setFieldsValue({note: whBill.note})
  },[whBill])
  return (
    <div className="page-wraper">
      <div className="container-fluid">
        <Form
          initialValues = {initialValues}
          // wrapperCol={{ sm: 24, md: 24, lg: 18 }}
          autoComplete="off"
          form={form}
          labelAlign="left"
          labelCol={{ sm: 24, md: 24, lg: 4 }}
          onValuesChange
          requiredMark={false}
          // scrollToFirstError
        >
          <Row
            align="top"
            className="staff-form__logo-row"
            justify="space-between"
          >
            <Col span={24}>
              <BaseBorderBox title={'Thông tin chung'}>
                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      // label={<>Mã khách hàng{` `}<code>customerNumber</code></>}
                      // wrapperCol={{ lg: 20 }}
                      label={'Mã khách hàng'}
                      labelCol={{ lg: 8 }}
                      name={"customerNumber"}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      // label={<>Tên khách hàng{` `}<code>customerName</code></>}
                      label="Tên khách hàng"
                      labelCol={12}
                      name={"customerName"}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem label="Người nộp/nhận" name="payer">
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      // label={<>Địa chỉ{` `}<code>customerAddressId</code></>}
                      // labelCol={12}
                      label="Địa chỉ"
                      name={['customerAddress', 'street']}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      name="reason"
                      label="Lý do nộp"
                      // label={<>Lý do nộp{` `}<code>reason</code></>}
                      // labelCol={12}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      name={"employeeName"}
                      label="Nhân viên thu/chi"
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <Space>
                      <FormItem
                        label="Kèm theo"
                        name="originalDocument"
                        labelCol={16}
                      >
                        <Input style ={{color: "#333"}} addonAfter="chứng từ gốc" disabled bordered={false}/>
                      </FormItem>
                    </Space>
                  </Col>
                </Row>
                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      name={"nameAccount"}
                      label="Tài khoản đăng nhập"
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      name={"username"}
                      label="Nhóm người dùng"
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>

            <Col span={24}>
              <BaseBorderBox title={'Chứng từ'}>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      label="Ngày hạch toán"
                      name="accountingDate"
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>
                    <FormItem
                      label="Ngày chứng từ"
                      name="dateOfIssue"
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>

                    <FormItem
                      label="Số chứng từ"
                      name="issueNumber"
                      labelCol={{ lg: 8 }}
                    >
                      {isLoading ? (
                        <Skeleton.Input active />
                      ) : (
                        <Input style ={{color: "#333"}} disabled bordered={false}/>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>
          </Row>

          <BaseBorderBox title={'Ghi chú'}>
            <Row>
              <Col span={24}>
                <FormItem label="Ghi chú" name={'note'} labelCol={{ lg: 8 }}>
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <Input style ={{color: "#333"}} disabled bordered={false}/>
                  )}
                </FormItem>
              </Col>
            </Row>
          </BaseBorderBox>
          <BaseBorderBox title={'Tổng'}>
            <Row>
              <Col span={24}>
                <FormItem label="Tổng" name={'totalAmount'} labelCol={{ lg: 8 }}>
                  {isLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    <InputNumber formatter={(value)=>formatNumberThreeComma(value)} style ={{color: "#333"}} disabled bordered={false}/>
                  )}
                </FormItem>
              </Col>
            </Row>
          </BaseBorderBox>
        </Form>
      </div>

      {/* <Modal
      visible={isOpenViewer}  
      // onCancel={() => setIsOpenViewer(false)}
    >
      <Printer url={url} />
    </Modal> */}
    </div>
  );
};
export default WhTransactionBill;
