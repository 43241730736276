import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhBanners,
  getWhBanner,
  createWhBanner,
  updateWhBanner,
  deleteWhBanner
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

const getSelector = (key) => (state) => state.banner[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useWhBannerPaging = () => useSelector(pagingSelector);

export const useWhBannerQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteWhServiceSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteWhServiceSuccess]);
};

export const useWhBanners = () => {
  return useFetch({
    action: getWhBanners,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector
  });
};

export const useCreateWhBanner = () => {
  const history = useHistory();
  const callback = () => history.push('/banner');
  useSuccess(createSuccessSelector, 'Tạo mới banner thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới banner thất bại');
  // console.log('useCreateWhBanner');
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhBanner
  });
};

export const useUpdateWhBanner = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật banner thành công');
  useFailed(updateFailedSelector, 'Cập nhật banner thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhBanner
  });
};

export const useDeleteWhBanner = () => {
  useSuccess(deleteSuccessSelector, 'Xoá banner thành công');
  useFailed(deleteFailedSelector, 'Xoá banner thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhBanner
  });
};
