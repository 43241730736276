import { Input, Table } from 'antd';
import { get } from 'lodash';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { useListTrialPaging, useListTrialQueryParams, useListTrials, useResetListTrial, useUpdateListTrialParams } from '~/hooks/listTrial';
const { Search } = Input;

const ListTrial = ({ }) => {
  const [query] = useListTrialQueryParams();
  const [ListTrials, isLoading] = useListTrials(query);
  const [keyword, setKeyword, { onParamChange }] = useUpdateListTrialParams(query);

  const paging = useListTrialPaging();
  useResetListTrial();

  const columns = [
    {
      title: 'Tên người dùng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tên đơn vị',
      dataIndex: 'unitName',
      key: 'unitName',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Giấy phép hoạt động',
      dataIndex: 'license',
      key: 'license',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phần mềm',
      dataIndex: 'software',
      key: 'software',
      render : (software) => get(software,['name', 'vi'])
    },
  ];
  const onSearch = (kw) => onParamChange({ keyword: kw?.trim() })
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách đăng ký dùng thử phần mềm" />

        <div className="page-wraper__header">
          <Search
            allowClear
            style={{ width: 200 }}
            placeholder="Tìm đăng ký dùng thử phần mềm"
            enterButton
            onSearch={onSearch}
          />
        </div>

        <Table
          loading={isLoading}
          className="wh-category-table-striped-rows"
          rowKey={(rc) => rc._id || rc.id}
          columns={columns}
          dataSource={ListTrials}
          onChange={({ current, pageSize }) => onParamChange({ page: current, limit: pageSize })}
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `,
            showSizeChanger: true
          }}
          size="small"
        />
      </div>
    </div>
  );
};

export default ListTrial;
