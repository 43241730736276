import React, { useEffect, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { Modal, Row, Space, Table } from "antd";
import api from '~/api';
import { get, identity } from 'lodash';
import { notifyHttpError } from '~/utils/helper';
import ServiceOfHistory from './ServiceOfHistory'

function callback(key) {
  console.log(key);
}

const History = ({ status, statuses, whBill, _id}) => {
    const [whBillItems, setWhBillItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(0);
    const pageSize = 10;
  
    const baseQuery = {
      ...(statuses && { statuses }),
      ...(status && { status }),
      customerId: _id,
      populate: true,
      // limit: pageSize,
      // page: 1,
    }
    const getRecordsLocally = async (query) => {
      setIsLoading(true);
      try {
        const { docs, totalDocs} = await api.whBill.getAll({
          ...query,
        });
        setWhBillItems(docs);
        setTotal(totalDocs);
        
      } catch (error) {
        notifyHttpError(error);
        setWhBillItems([]);
      } finally {
        setIsLoading(false);
      }
    }
    const onPagingChange = ({ current, pageSize }) => {
      setCurrent(current);
      getRecordsLocally({
        ...baseQuery,
        page: current,
        limit: pageSize,
      })
    }
  
    useEffect(() => {
      getRecordsLocally({ ...baseQuery });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [whBill]);

  const columns = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'billNumber',
      key: 'billNumber',
      width: '410px',
      render: (record) => {
        return  record;
      }
    },

    {
      title: 'Số tiền',
      dataIndex: 'grandTotal',
      key: 'grandTotal',
      width: '410px',
      render: (record) => { 
        return record?.toLocaleString()
      },
    },

    {
      title: 'Điểm thưởng',
      dataIndex: 'point',
      key: 'point',
      width: '310px'
      // render: (record) => record.phoneNumber
    }
  ];
  return (
    <div className="container-fluid" style={{ maxWidth: '100%' }}>
      <div className="page-content__main">
            <Table
            // style={{overflow:'auto'}}
            style={{ maxWidth: '100%' }}
              rowKey={(rc) => rc._id}
              columns={columns}
              dataSource={whBillItems}
              size="middle"
              onChange={onPagingChange}
              pagination={{
                total,
                pageSize,
                current,
                showTotal: (total) => `Tổng cộng: ${total} `
              }}
                expandable={{
                  expandedRowRender: (record) => 
                    <ServiceOfHistory
                      whBill={record}
                    > 
                    </ServiceOfHistory>
                }}
            />
      </div>
    </div>
  );
};

export default History;
