import React, { useContext, useEffect, useState } from 'react';

import { Button, Col, Form, Row, Select, Space, Spin, Table, Tag } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { compact, get, isObject } from 'lodash';

import api from '~/api';
import {
  COMPONENT_MODES,
  INSTANCE_STATUSES,
  MAX_PAGINATION_LIMIT
} from '~/constants/defaultValue';
import { useParams } from 'react-router-dom';
import {  useCreateWhPartnerService, useDeleteWhPartnerService, useUpdateWhPartnerService, useWhPartnerQueryParams } from '~/hooks';
import DebounceSelectService from './DebounceSelectService';

const FormItem = Form.Item;

const AssignedServicesTable = ({ dataSource, form, mode, showTagsOnly }) => {
  const params = useParams()
  const [initWhServices, setInitWhServices] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [numberService, setNumberService] = useState(0)
  const [numberServiceAll, setNumberServiceAll] = useState(0)
  const [idsForm, setIdsForm] = useState(0)
  const idUser = useParams()
  // SET NEW ID 
  let newId = get(idUser, "id", '')
  let IndexError = newId.indexOf("&")
  let idAfterSlice = (IndexError === -1) ? newId : newId.slice(0, IndexError)
  const [isDeleteLoading, deleteService] = useDeleteWhPartnerService()
  const [, updateService] = useUpdateWhPartnerService()
  const [, createService] = useCreateWhPartnerService()
  const [isLoadingSelect, setIsLoadingSelect] = useState(true)
  const [serviceExits, setServiceExtis] = useState([]);
  
  const onChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    setPagination({ current, pageSize });
  };

  //Set Pagination
  useEffect(() => {
    if (form && (form.getFieldValue('services')?.length > 0)) {
      const numberOfItems = form.getFieldValue('services')?.length;
      const maxNumberOfPages = Math.ceil(numberOfItems / pagination.pageSize);
      if (maxNumberOfPages < pagination.current) {
        setPagination({
          ...pagination,
          current: Math.max(maxNumberOfPages, 1)
        });
      }
    }
  }, [form, pagination]);

  //Call API get data to process select
  const getWhServices = async (query) => {
    const whServicesResponse = await api.whService.getWhServices(query);
    const whServices = get(whServicesResponse, 'docs').map((item) => ({
      ...item,
      whServiceId: get(item, 'id')
    }));
    return whServices;
  };
  //Get Data after Filted ID
  const getServicesByIds = async (param) => {
    const params = {
      id: idAfterSlice,
      // query: param
    }
    const whServicesResponse = await api.whPartner.getServiceByIds(params); // Get services by partnerId
    const whServices = get(whServicesResponse, 'docs',[])?.map((item) => ({
      ...item,
      whServiceId: get(item, '_id')
    }));
    if (whServicesResponse?.docsAvailable?.length) { 
      setServiceExtis(whServicesResponse?.docsAvailable);
    } else {
      setServiceExtis([]);
    };
    setIsLoadingSelect(whServices.length  >= 0 ? false : true)
    return whServices;
  };

  // Call API get data to load into the form
  useEffect(() => {
    const getInitWhServices = async () => {
      const getServices = await api.whService.getWhServicesBranch({ limit: 10, isAccess: 'ACCEPT' })
      // const filterTotalDocsByActive = getServices?.docs?.filter((item) => item.status === 'ACTIVE')
      setNumberServiceAll(getServices?.totalDocs);
      const ids = compact(
        form.getFieldValue('services')?.map((item) => item.whServiceId)
      );
      setIdsForm(ids?.length ? "0" : ids);
      if (ids && ids?.length) {
        const whServices = await getWhServices({
          ids,
          limit: MAX_PAGINATION_LIMIT
        });
        setInitWhServices(whServices);
        form.setFieldsValue({
          services: [...whServices]
        });
        setNumberService(whServices.length)
      }
    };

    if (mode === COMPONENT_MODES.EDIT) getInitWhServices();
  }, [form, mode, ]);

  const filterToIndex = (docs, index) => {
    return docs.filter(
      ({ _id, status }) =>
        !form
          .getFieldValue('services')
          .slice(0, index)
          .map((item) => {
            const whServiceId = get(item, 'whServiceId');
            return isObject(whServiceId)
              ? get(whServiceId, 'value')
              : whServiceId;
          })
          .includes(_id) && status === INSTANCE_STATUSES.ACTIVE
    );
  };

  const paginateIndex = (index) =>
    (pagination.current - 1) * pagination.pageSize + index;

  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '50px',
      render: (text, record, index) => paginateIndex(index) + 1
    },
    {
      title: 'Dịch vụ',
      key: 'whServiceId',
      render: (text, record, index) => (
        <Row align="middle"
          style={{maxWidth: '1100px'}}
        >
          <Col span={22}
            style={{ overflow: "hidden" }}>
            
            {
              {
                [COMPONENT_MODES.EDIT]: (
                  <FormItem
                    name={['services', paginateIndex(index), 'whServiceId']}
                  >
                    <DebounceSelectService
                      initOptions={initWhServices}
                      fetchOptions={(x) =>getServicesByIds()}
                      valueKey="whServiceId"
                      labelKey="name.vi"
                      searchKey="keyword"
                      dispatchUpdate
                      dispatchImport
                      createService = {createService}
                      updateService={updateService}
                      idAfterSlice={idAfterSlice}
                      isLoadingSelect = {isLoadingSelect}
                      serviceExits ={serviceExits}
                    />
                  </FormItem>
                ),

                [COMPONENT_MODES.VIEW]: (
                  <Tag color="blue">{get(record, 'name.vi')}</Tag>
                )
              }[mode]
            }
          </Col>

          <Col span={2}>
            {
              {
                [COMPONENT_MODES.EDIT]:
                  isDeleteLoading
                    ? <Spin spinning/>
                    : (
                      <MinusCircleOutlined
                        onClick={() => removeAssignedService(paginateIndex(index))}
                        className={index === 0 && 'top-3'}
                      />
                    )
              }[mode]
            }
          </Col>
        </Row>
      )
    }
  ];

  const blankService = {
    whServiceId: null
  };

  const addAssignedService = (service) => {
    const currentServiceIds = form.getFieldValue('services') || [];
    currentServiceIds.unshift(service);
    form.setFieldsValue({
      services: [...currentServiceIds]
    });
  };

  const removeAssignedService = (index) => {
    const getServiceId = form.getFieldValue('services')[index]?._id
    form.setFieldsValue({
      services: form.getFieldValue('services').filter((item, i) => i !== index)
    });
    const param = {
      id: idAfterSlice,
      serviceId: getServiceId
    }
    // dispatch(deleteWhPartnerService(param));
    deleteService(param)
  };
  const nameService = (item) =>{
    if (get(item, "detailName.vi"))
          return `${get(item, "name.vi")} - ${get(item, "detailName.vi")}`
        else 
          return get(item, "name.vi")
  }

  return (
    <>
      {mode === COMPONENT_MODES.EDIT && (
        <Row>
          <Col span={18}>
            <Button
              type="primary"
              // disabled={form.getFieldValue("services").length >= whServices?.length}
              onClick={() => addAssignedService(blankService)}
              style={{ marginBottom: 16 }}
            >
              Thêm dịch vụ
            </Button>
          
          </Col>
          <Col span={6}>
            <h5>Tổng dịch vụ: {idsForm} {numberServiceAll ? (`/${numberServiceAll}`) : ""} </h5>
          </Col>
        </Row>
      )}
      {mode === COMPONENT_MODES.VIEW && showTagsOnly && (
        <Space direction="vertical" style={{ width: '100%' }} size="small">
          <Row>Dịch vụ</Row>
          <Row className='serviceBox'>
            {dataSource?.map((item, index) => (
              <Tag key={index} color="blue">
                {nameService(item)}
              </Tag>
            ))}
          </Row>
        </Space>
      )}
      {mode === COMPONENT_MODES.CREATE && (
        <Space direction="vertical" style={{ width: '100%' }} size="small">
         <h5 style={{color: "rgba(192,192,192,0.6)"}}>Vui lòng tạo tài khoản đối tác thành công trước khi nhập dịch vụ</h5>
        </Space>
      )}
      {!showTagsOnly && (
        <Table
          rowKey={(rc, 
          index) => paginateIndex(index)}
          size="small"
          columns={columns}
          bordered
          dataSource={
            mode === COMPONENT_MODES.EDIT
              ? form.getFieldValue(['services'])
              : dataSource
          }
          onChange={onChange}
          pagination={{
            ...pagination,
            showTotal: (total) => (
              setIdsForm(total),
              `Tổng cộng: ${total} `
            )
          }}
        />
      )}
    </>
  );
};

export default AssignedServicesTable;
