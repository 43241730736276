import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getExchangePointsFailed: null,
  getExchangePointFailed:null,
  ExchangePoints: null,
  ExchangePoint: null,
  isSubmitLoading : false,
  isGetExchangePointLoading: false,
  ExchangePoint: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_EXCHANGEPOINTS_REQUEST:
      state.isLoading = true;
      state.getExchangePointsFailed = null;
      return;

    case Types.GET_EXCHANGEPOINTS_SUCCESS:
      state.isLoading = false;
      state.ExchangePoints = payload;
      state.getExchangePointsFailed = null;
      return;

    case Types.GET_EXCHANGEPOINTS_FAILED:
      state.isLoading = false;
      state.ExchangePoints = [];
      state.getExchangePointsFailed = payload;
      return;
    case Types.GET_EXCHANGEPOINT_REQUEST:
      state.isGetExchangePointLoading = true;
      state.ExchangePoint = null
      state.getExchangePointFailed = null;
      return;

    case Types.GET_EXCHANGEPOINT_SUCCESS:
      state.isGetExchangePointLoading = false;
      state.ExchangePoint = payload;
      state.getExchangePointFailed = null;
      return;

    case Types.GET_EXCHANGEPOINT_FAILED:
      state.isGetExchangePointLoading = false;
      state.ExchangePoint = null;
      state.getExchangePointFailed = payload;
      return;
    case Types.CREATE_EXCHANGEPOINT_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_EXCHANGEPOINT_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_EXCHANGEPOINT_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_EXCHANGEPOINT_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_EXCHANGEPOINT_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.ExchangePoint = get(payload,'data');
        return;
  
      case Types.UPDATE_EXCHANGEPOINT_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_EXCHANGEPOINT_STATE:
      return initState;

    default:
      return;
  }
}, initState);
