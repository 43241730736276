import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhPackageLevels,
  createWhPackageLevel,
  deleteWhPackageLevel,
  getWhPackageLevel,
  // resetWhPackageLevelState,
  updateWhPackageLevel
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

const getSelector = key => state => state.whPackageLevel[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useWhPackageLevelPaging = () => useSelector(pagingSelector);

export const useWhPackageLevelQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteWhServiceSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteWhServiceSuccess]);
};

export const useWhPackageLevels = () => {
  return useFetch({
    action: getWhPackageLevels,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector
  });
};

export const useCreateWhPackageLevel = () => {
  const history = useHistory();
  const callback = () => history.push('/wh-package-level');

  useSuccess(
    createSuccessSelector,
    'Tạo mới gói dịch vụ thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới gói dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhPackageLevel
  });
};

export const useUpdateWhPackageLevel = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật gói dịch vụ thành công');
  useFailed(updateFailedSelector, 'Cập nhật gói dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhPackageLevel
  });
};

export const useDeleteWhPackageLevel = () => {
  useSuccess(deleteSuccessSelector, 'Xoá gói dịch vụ thành công');
  useFailed(deleteFailedSelector, 'Xoá gói dịch vụ thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhPackageLevel
  });
};

export const useWhPackageLevel = id => {
  return useFetchByParam({
    action: getWhPackageLevel,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitWhPackageLevel = whPackageLevel =>
  useMemo(() => {
    if (!whPackageLevel) {
      return {
        image: {
        }
      };
    }

    return whPackageLevel;
  }, [whPackageLevel]);

// export const useResetWhPackageLevel = () => {
//   useResetState(resetWhPackageLevelState);
// };
