import * as Types from '../../constants/actionTypes';

export const addOrderSelect = (query) => ({
    type: Types.ADD_ORDER_SELECT,
    payload : query
  });
export const addUserSelect = (query) => ({
    type: Types.ADD_USER_SELECT,
    payload : query
  });
export const addAddressSelect = (query) => ({
    type: Types.ADD_ADDRESS_SELECT,
    payload : query
  });
export const resetStoreQuotation = () => ({
    type: Types.RESET_STORE,
  });
export const addQueryFetch = (query) => ({
    type: Types.ADD_QUERY_FETCH,
    payload : query
  });
export const addAllQueryFetch = (query) => ({
    type: Types.ADD_ALL_QUERY_FETCH,
    payload : query
  });



  
  export const getQuotations = query => ({
    type: Types.GET_QUOTATIONS_REQUEST,
    payload: query
  });
  
  export const getQuotation = id => ({
    type: Types.GET_QUOTATION_REQUEST,
    payload: id
  });
  
  export const updateQuotation = instance => ({
    type: Types.UPDATE_QUOTATION_REQUEST,
    payload: instance
  });
  export const createQuotation = instance => ({
    type: Types.CREATE_QUOTATION_REQUEST,
    payload: instance
  });
  export const convertQuotation = instance => ({
    type: Types.CONVERT_QUOTATION_REQUEST,
    payload: instance
  });
  export const cancelQuotation = instance => ({
    type: Types.CANCEL_QUOTATION_REQUEST,
    payload: instance
  });
  
  export const deleteQuotation = id => ({
    type: Types.DELETE_QUOTATION_REQUEST,
    payload: id
  });
  export const copyQuotation = id => ({
    type: Types.COPY_QUOTATION_REQUEST,
    payload: id
  });
  export const resetQuotationState = () => ({
    type: Types.RESET_QUOTATION_STATE
  });
