import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '~/api';
import LoadingWhBill from '~/components/WhBill/LoadingWhBill';
import { WH_COURSE_TRAINING_STATUS } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import {
  useCreateTrainingCoursePartner,
  useInitTrainingCoursePartner,
  useUpdateTrainingCoursePartner
} from '~/hooks/trainingCoursePartner';
import UploadFile from './UploadFile';
import './form.scss';
import toastr from 'toastr';
const FormItem = Form.Item;
function FormCreate({
  handleCloseModalCreate,
  isLoadingTraining,
  trainingCourses,
  OneTrainingCourse,
  loadingOneTrainingCourse,
  profile,
  id,
  onParamChange,
  typeOf
}) {
  const [form] = Form.useForm();
  const [date, setDate] = useState(null);
  const [files, setFiles] = useState([]);
  const [courseIdFull, setCourseIdFull] = useState(null);
  const [dateExist, setDateExist] = useState([]);
  const [loadingDateExist, setLoadingDateExist] = useState(false);
  const callbackUpdate = () => {
    handleCloseModalCreate();
  };
  const callbackCreate = () => {
    handleCloseModalCreate();
    setFiles([]);
    form.resetFields();
    onParamChange({ page: 1, limit: 10 });
  };
  const InitTrainingCoursePartner = useInitTrainingCoursePartner(
    OneTrainingCourse,
    id
  );
  const [isLoadingSubmitCreate, createTrainingCorsePartner] =
    useCreateTrainingCoursePartner(callbackCreate);
  const MonthNow = useMemo(() => new Date().getMonth() + 1, []);
  const getDateExist = useCallback(async (courseId) => {
    setLoadingDateExist(true);
    const res = await api.TrainingCoursePartner.getCourses({ courseId });
    let dateExist = res?.map((e) => {
      if (parseInt(moment(e.date).format('MM')) === MonthNow) {
        if (get(e, 'status') !== WH_COURSE_TRAINING_STATUS.DENIED)
          return moment(e.date).format('YYYY-MM-DD');
      }
    });
    setDateExist(dateExist);
    setLoadingDateExist(false);
  }, []);
  useEffect(() => {
    form.resetFields();
    setFiles([]);
    setDate(null);
    setDateExist([]);
    if (!!InitTrainingCoursePartner && !!id) {
      getDateExist(get(InitTrainingCoursePartner, 'courseId'));
      setOptionUser([
        {
          label: get(InitTrainingCoursePartner, 'userId.fullName'),
          value: get(InitTrainingCoursePartner, 'userId._id')
        }
      ]);
      setFiles(InitTrainingCoursePartner?.files);
      setDate(get(InitTrainingCoursePartner, 'date'));
      form.setFieldsValue({
        courseId: get(InitTrainingCoursePartner, 'courseId'),
        userId: get(InitTrainingCoursePartner, 'userId._id'),
        date: moment(get(InitTrainingCoursePartner, 'date')),
        time: get(InitTrainingCoursePartner, 'time'),
        content: get(InitTrainingCoursePartner, 'content'),
        files: get(InitTrainingCoursePartner, 'files')
      });
    }
  }, [InitTrainingCoursePartner, OneTrainingCourse, id]);
  const [, updateTrainingCoursePartner] =
    useUpdateTrainingCoursePartner(callbackUpdate);
  const onFinish = async (values) => {
    if(!get(profile,'rateTraining') || get(profile,'rateTraining',0) === 0){
      return toastr.error("Bạn chưa có điểm rate đào tạo, vui lòng liên hệ với cộng tác viên")
    }
    if (!!OneTrainingCourse && !!id) {
      const courseIdFullUpdate = trainingCourses?.find(
        (e) => e._id === get(InitTrainingCoursePartner, 'courseId')
      );
      const submitData = {
        ...values,
        date,
        files,
        partnerId: profile._id,
        userIdAction: profile._id,
        _id: OneTrainingCourse._id,
        courseIdFull: get(courseIdFullUpdate, 'courseIdFull'),
        typeOf
      };
      updateTrainingCoursePartner(submitData);
    } else {
      const submitData = {
        ...values,
        date,
        files,
        partnerId: profile._id,
        courseIdFull,
        userIdAction: profile._id,
        typeOf
      };
      createTrainingCorsePartner(submitData);
    }
  };
  const handleChangeDate = (date, dateString) => {
    setDate(date);
  };
  const [optionUser, setOptionUser] = useState([]);
  const handleChangeIdCourse = (value) => {
    getDateExist(value);
    const newOp = trainingCourses?.find((e) => e._id === value);
    setCourseIdFull(get(newOp, 'courseIdFull'));
    setOptionUser([
      {
        label: get(newOp, 'responsibleUser.fullName'),
        value: get(newOp, 'responsibleUser._id')
      }
    ]);
    form.setFieldsValue({ userId: get(newOp, 'responsibleUser._id') });
  };
  return (
    <>
      <LoadingWhBill open={loadingOneTrainingCourse || loadingDateExist} />
      <Form
        labelCol={{ sm: 10, md: 10, lg: 10, xl: 10 }}
        wrapperCol={{ sm: 14, md: 14, lg: 14, xl: 14 }}
        form={form}
        onFinish={onFinish}
      >
        <FormItem
          label="Khoá đào tạo"
          name="courseId"
          rules={[
            {
              required: true,
              message: 'Xin vui lòng chọn Khoá đào tạo'
            }
          ]}
        >
          <Select
            disabled={!!id}
            loading={isLoadingTraining}
            onChange={handleChangeIdCourse}
            showSearch
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={trainingCourses?.map((e) => ({
              label: e.courseIdFull,
              value: e._id
            }))}
          />
        </FormItem>
        <FormItem label="Người chịu trách nhiệm" name="userId">
          <Select disabled={true} options={optionUser} />
        </FormItem>
        <FormItem
          label="Ngày"
          name="date"
          rules={[
            {
              required: true,
              message: 'Xin vui lòng chọn ngày'
            }
          ]}
        >
          <DatePicker
            allowClear={false}
            onChange={handleChangeDate}
            className="home-service-form__form-item--date-picker custom-input-remove"
            disabled={!form.getFieldValue('courseId')}
            disabledDate={(current) =>
              current <= moment().startOf('month') ||
              current >= moment().endOf('month') ||
              dateExist?.some(
                (e) =>
                  moment(current).format('YYYY-MM-DD') ===
                  moment(e).format('YYYY-MM-DD')
              )
            }
            format="DD/MM/YYYY"
            placeholder="Chọn ngày"
          />
        </FormItem>
        <FormItem
          label="Số giờ"
          name="time"
          rules={[
            {
              required: true,
              message: 'Xin vui lòng nhập số giờ'
            }
          ]}
        >
          <InputNumber min={0} max={24} />
        </FormItem>
        <FormItem
          label="Nội dung"
          name="content"
          rules={[
            {
              required: true,
              message: 'Xin vui lòng nhập nội dung'
            }
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="File đính kèm"
          name="files"
          help="Vui lòng chọn file dưới 3MB"
        >
          <UploadFile
            files={files}
            setFiles={setFiles}
          />
        </FormItem>
        <Row className="group-buttonMy">
          <Col span={10}>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoadingSubmitCreate}
            >
              {!!id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Col>
          <Col span={10}>
            <Button onClick={handleCloseModalCreate}>Huỷ</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default FormCreate;
