import {
  GET_WH_BILL_ITEMS_REQUEST,
  GET_WH_BILL_ITEM_REQUEST,
  CREATE_WH_BILL_ITEM_REQUEST,
  UPDATE_WH_BILL_ITEM_REQUEST,
  CUSTOMER_CANCEL_WH_BILL_ITEM_REQUEST,
  UPDATE_WH_APPOINTMENTS_REQUEST,
  DELETE_WH_BILL_ITEM_REQUEST,
  RESET_WH_BILL_ITEM_STATE,
  CREATE_CANCEL_APPOINTMENT_REQUEST,
  CUSTOMER_COMPLETED_WH_BILL_ITEM_REQUEST,
  RESET_CREATE_CANCEL,
} from '../../constants/actionTypes';

export const getWhBillItems = query => ({
  type: GET_WH_BILL_ITEMS_REQUEST,
  payload: query
});

export const getWhBillItem = id => ({
  type: GET_WH_BILL_ITEM_REQUEST,
  payload: id
});

export const createWhBillItem = instance => ({
  type: CREATE_WH_BILL_ITEM_REQUEST,
  payload: instance
});

export const updateWhBillItem = instance => ({
  type: UPDATE_WH_BILL_ITEM_REQUEST,
  payload: instance
});

export const customerCancelWhBillItem = instance => ({
  type: CUSTOMER_CANCEL_WH_BILL_ITEM_REQUEST,
  payload: instance
});

export const customerCompletedWhBillItem = payload => ({
  type: CUSTOMER_COMPLETED_WH_BILL_ITEM_REQUEST,
  payload,
})
export const updateWhAppointments = payload => ({
  type: UPDATE_WH_APPOINTMENTS_REQUEST,
  payload,
});

export const deleteWhBillItem = id => ({
  type: DELETE_WH_BILL_ITEM_REQUEST,
  payload: id
});

export const resetWhBillItemState = () => ({
  type: RESET_WH_BILL_ITEM_STATE
});

export const createCancelAppointment = payload => ({
  type: CREATE_CANCEL_APPOINTMENT_REQUEST,
  payload
});

export const resetCreateCancel = () => ({
  type: RESET_CREATE_CANCEL
});
