import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getPartnerCancelAppointments({ payload: query }) {
    try {
      const response = yield call(Api.partnerCancelAppointment.getAll, query);
      yield put({ type: Types.GET_PARTNER_CANCEL_APPOINTMENTS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_PARTNER_CANCEL_APPOINTMENTS_FAILED, payload: error });
    }
  }
function* getPartnerCancelAppointment({ payload: id }) {
    try {
      const response = yield call(Api.partnerCancelAppointment.getById, id);
      yield put({ type: Types.GET_PARTNER_CANCEL_APPOINTMENT_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_PARTNER_CANCEL_APPOINTMENT_FAILED, payload: error });
    }
  }
  function* createPartnerCancelAppointment(action) {
    try {
      const data = yield call(Api.partnerCancelAppointment.create, action.payload);
      yield put({ type: Types.CREATE_PARTNER_CANCEL_APPOINTMENT_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_PARTNER_CANCEL_APPOINTMENT_FAILED, payload: error.message });
    }
  }
  function* updatePartnerCancelAppointment(action) {
    try {
      const data = yield call(Api.partnerCancelAppointment.update, action.payload);
      yield put({ type: Types.UPDATE_PARTNER_CANCEL_APPOINTMENT_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_PARTNER_CANCEL_APPOINTMENT_FAILED, payload: error.message });
    }
  }
  function* deletePartnerCancelAppointment(action) {
    try {
      const data = yield call(Api.partnerCancelAppointment.delete, action.payload);
      yield put({ type: Types.DELETE_PARTNER_CANCEL_APPOINTMENT_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_PARTNER_CANCEL_APPOINTMENT_FAILED, payload: error.message });
    }
  }
export default function* PartnerCancelAppointments() {
    yield takeLatest(Types.GET_PARTNER_CANCEL_APPOINTMENTS_REQUEST, getPartnerCancelAppointments);
    yield takeLatest(Types.GET_PARTNER_CANCEL_APPOINTMENT_REQUEST, getPartnerCancelAppointment);
    yield takeLatest(Types.CREATE_PARTNER_CANCEL_APPOINTMENT_REQUEST, createPartnerCancelAppointment);
    yield takeLatest(Types.UPDATE_PARTNER_CANCEL_APPOINTMENT_REQUEST, updatePartnerCancelAppointment);
    yield takeLatest(Types.DELETE_PARTNER_CANCEL_APPOINTMENT_REQUEST, deletePartnerCancelAppointment);
  }
