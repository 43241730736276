import React, { useEffect, useMemo, useState } from 'react';

import { EditFilled } from '@ant-design/icons';
import { Button, Modal, Space, Switch, Table } from 'antd';
import { find, get, merge, pick } from 'lodash';

import {
  useCreateStaffGroup,
  useStaffGroups,
  useUpdateStaffGroup,
} from '~/hooks/staffGroup';
import { useSpecialityOptions } from '~/hooks/speciality';
import { useWhPackageLevels } from '~/hooks/whPackageLevel';
import { useMatchPolicy } from '~/hooks';

import POLICY from '~/constants/policy';
import { INSTANCE_STATUSES } from '~/constants/defaultValue';

import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import TableRankingForm from './Form';
import WithPermission from '~/components/Common/WithPermission';

// function onChange(checked) {
//   console.log(`switch to ${checked}`);
// }

const TableRanking = () => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHPROFESSIONALSTAFF);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHPROFESSIONALSTAFF);
  const [tableRankingId, setTableRankingId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [staffGroups, isLoading] = useStaffGroups();
  const [whPackageLevels, isLoadingWhPackageLevels] = useWhPackageLevels();
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const [isSubmitting, handleUpdate] = useUpdateStaffGroup();
  const [, handleCreate] = useCreateStaffGroup();

  const ratesFromStaffGroups = useMemo(() => {
    return staffGroups?.reduce((prev, curr) => {
      const { rateForLevel } = curr;
      if (rateForLevel) {
        const levels = Object.entries(rateForLevel).map(([level, rateObj]) => {
          return {
            id: get(curr, "id"),
            name: get(curr, "name"),
            rate: get(rateObj, "value"),
            bonus: get(rateObj, "bonus"),
            percentIncome: get(rateObj, "percentIncome"),
            state: get(rateObj, "state"),
            whPackageLevel: find(whPackageLevels, { level: parseInt(level) }),
            key: `${get(curr, "id")}/${level}`
          }
        });
        return prev.concat(levels);
      }
      return prev;
    }, []);
  }, [
    staffGroups,
    whPackageLevels,
  ])

  useEffect(() => {
    if (isSubmitting === false) setIsOpen(false);
  }, [isSubmitting])

  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '70px',
      render: (text, record, index) => {return (+pagination.page - 1) * pagination.limit + index + 1;},
    },

    {
      title: 'Nhóm đối tác',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Hệ số',
      dataIndex: 'rate',
      key: 'rate',
      width: '200px',
    },

    {
      title: 'Gói dịch vụ',
      dataIndex: 'whPackageLevel',
      key: 'whPackageLevel',
      render: whPackageLevel => get(whPackageLevel, "name.vi"),
    },

    {
      title: 'Thưởng',
      dataIndex: 'bonus',
      key: 'bonus',
      width: '200px',
    },

    {
      title: 'Phần trăm thu nhập',
      dataIndex: 'percentIncome',
      key: 'percentIncome',
      width: '200px',
    },

    ... (canDelete || canUpdate) ? [{
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (text, record) => (
        <Space size="middle" >
          <WithPermission permission={POLICY.UPDATE_WHPROFESSIONALSTAFF}>
            <EditFilled
              onClick={() => {
                setInitialValues({
                  employeeGroupId: get(record, "id"),
                  rate: get(record, "rate"),
                  bonus: get(record, "bonus"),
                  percentIncome: get(record,"percentIncome"),
                  level: get(record, "whPackageLevel.level"),
                })
                setIsOpen(true);
                setTableRankingId(get(record, "id"));
              }}
              style={{ color: '#1f89e5' }}
            />
          </WithPermission>
          <WithPermission permission={POLICY.UPDATE_WHPROFESSIONALSTAFF}>
            <WithPermission permission={POLICY.DELETE_WHPROFESSIONALSTAFF}>
              <p style={{marginLeft:'10px'}}>|</p>
            </WithPermission>
          </WithPermission>
          <WithPermission permission={POLICY.DELETE_WHPROFESSIONALSTAFF}>
            <Switch
              checked={get(record, "state") === INSTANCE_STATUSES.ACTIVE}
              onChange={(value) => onChange(value, record)}
            />
          </WithPermission>
        </Space>
      ),
    }] : []
  ];

  const onFormSubmit = (values) => {
    const { employeeGroupId, level, rate, bonus, percentIncome} = values;
  
    const existingStaffGroup = find(staffGroups, { id: employeeGroupId });

    if (existingStaffGroup) {
      handleUpdate(pick(merge(
        {},
        existingStaffGroup,
        {
          rateForLevel: {
            [level]: {
              value: rate,
              bonus: bonus,
              percentIncome: percentIncome,
              state: INSTANCE_STATUSES.ACTIVE
            },
          }
        }
      ), ["id", "rateForLevel"]));
    }
  }

  const onChange = (value, record) => {
    const existingStaffGroup = find(staffGroups, { id: get(record, "id") });

    if (existingStaffGroup) {
      handleUpdate(pick(merge(
        {},
        existingStaffGroup,
        {
          rateForLevel: {
            ...get(existingStaffGroup, "rateForLevel"),
            [get(record, "whPackageLevel.level")]: {
              ...get(existingStaffGroup, `rateForLevel.${get(record, "whPackageLevel.level")}`),
              state: value
                ? INSTANCE_STATUSES.ACTIVE
                : INSTANCE_STATUSES.INACTIVE
            }
          }
        }
      ), ["id", "rateForLevel"]));
    }
  }

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Bảng xếp loại Cán bộ chuyên môn" />
        <div className='page-content__main' style={{ display: 'block' }}>
          <div className="page-wraper__header">
            <WithPermission permission={POLICY.WRITE_WHPROFESSIONALSTAFF}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => {
                  setInitialValues(null);
                  setIsOpen(true);
                }}
              >
                Thêm mới
              </Button>
            </WithPermission>
          </div>
          {false ? (
            <SkeletonTable
              rowCount={positions.length}
              columns={columns}
            />
          ) : (
            <Table
              className="wh-tableRanking-table-striped-rows"
              columns={columns}
              dataSource={ratesFromStaffGroups || []}
              // onChange={onPagingChange}
              size="middle"
              pagination={{
                // ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `,
                onChange: (page) => {
                  setPagination({ ...pagination, page: page });
                }
              }}
            />
          )}
        </div>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
        destroyOnClose
      >
        <TableRankingForm
          initialValues={initialValues}
          onClose={() => setIsOpen(false)}
          onFormSubmit={onFormSubmit}
          staffGroups={staffGroups}
          tableRankingId={tableRankingId}
          whPackageLevels={whPackageLevels}
          ratesFromStaffGroups={ratesFromStaffGroups}
        />
      </Modal>
    </div>
  );
};

export default TableRanking;
