import * as Types from '../../constants/actionTypes';

export const getWhBanners = () => ({
  type: Types.GET_WH_BANNERS_REQUEST
});

export const getWhBanner = (id) => ({
  type: Types.GET_WH_BANNER_REQUEST,
  payload: id
});

export const createWhBanner = (staffGroup) => ({
  type: Types.CREATE_WH_BANNER_REQUEST,
  payload: staffGroup
});

export const updateWhBanner = (staffGroup) => ({
  type: Types.UPDATE_WH_BANNER_REQUEST,
  payload: staffGroup
});

export const deleteWhBanner = (id) => ({
  type: Types.DELETE_WH_BANNER_REQUEST,
  payload: id
});

export const resetWhBannerState = () => ({
  type: Types.RESET_WH_BANNER_STATE
});
