import { useUpdateWhPackageLevel, useWhPackageLevels } from '~/hooks';
import CardItem from './CardItemPackageLevel/CardItem'
import "./index.scss"


const WhPackageLevel = ({}) =>{   
    const [whPackageLevels] = useWhPackageLevels();
    const [,handleUpdateWhPackageLevel]=useUpdateWhPackageLevel()

    return (
        <div className='page-wrapper page-content '>
            <div className='container-fluid whPackageLevel'>
                { whPackageLevels&& 
                whPackageLevels?.map((data,index)=>(
                    <CardItem 
                    key={index}
                    handleUpdateWhPackageLevel={handleUpdateWhPackageLevel}
                    index={index}
                     data={data} />
                ))
                }
            </div>
        </div>
    )
}


export default WhPackageLevel
