import { Form, Select, Tabs } from 'antd';
import Search from 'antd/lib/input/Search';
import { get, head } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserWorkspace } from '~/hooks';
import { useProductListPaging, useProductListQueryParams, useProductLists, useUpdateProductListParams } from '~/hooks/productList';
import SearchBorrowEquipment from './SearchBorrowEquipment';
import TableEquipmentBorrow from './TableEquipmentBorrow';
import TableMyEquipment from './TableMyEquipment';

export default function Equipment() {
  const history = useHistory()
  const [tabs, setTabs] = useState('1')
  const onChangeTabs = (tabs) => {
    setTabs(tabs)
    history.push('/workspace/equipment')
  }
  return (
    <div className="page-wraper page-content wh-bills-page page-content-workSpace">
      <div className="container-fluid">
        <h3>Trang thiết bị</h3>
        <div className="page-content__main">
          <div className="page-content__right" style={{ width: '100%' }}>
          
            <Tabs activeKey={tabs} onChange={onChangeTabs}>
              <Tabs.TabPane destroyInactiveTabPane key='1' tab='Trang thiết bị của tôi'>
                <TableMyEquipment  active={tabs === '1'}/> 
              </Tabs.TabPane>
              <Tabs.TabPane destroyInactiveTabPane key='2' tab='Trang thiết bị đang mượn'>
                <TableEquipmentBorrow active={tabs === '2'}/>
              </Tabs.TabPane>
            </Tabs>

          </div>
        </div>
      </div>
    </div>
  );
}
