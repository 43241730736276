import React from 'react';

import { Divider } from 'antd';
import { get } from 'lodash';

const HealthDeclaration = ({
  whBillItem,
  whService,
}) => {
  return (
    <>
      <Divider plain style={{ marginTop: 0, marginBottom: 0 }}>
        Khai báo tình trạng sức khỏe
      </Divider>
      <div
        className='wh-service-detail__description--paragraph'
      >
        <p>{get(whService, "questions.0.title.vi")}</p>
        {
          !get(whBillItem, "answers.0.options") ||
            !get(whBillItem, "answers.0.options").length
            ? <p>(chưa có câu trả lời)</p>
            : get(whBillItem, "answers.0.options").map((option, index) => {
              return <p key={index}>- {get(option, "title.vi")}</p>
            })
        }
      </div>
    </>
  )
}

export default HealthDeclaration;
