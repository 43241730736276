import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import {
  getNewses,
  createNews,
  deleteNews,
  getNews,
  resetNewsState,
  updateNews,
  updateNewsid,
  searchAuthorNewsState,
  searchCatogoryNewsState,
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

const getSelector = key => state => state.news[key];

const loadingSelector = getSelector('isLoading');
const loadingCatogorySelector = getSelector('isLoadingCatogory');
const loadingAuthorSelector = getSelector('isLoadingAuthor');
const listSelector = getSelector('list');
const listCategorySelector = getSelector('listCategory');
const listAuthorSelector = getSelector('listAuthor');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const isSubmitLoadingCategorySelector = getSelector('isSubmitLoadingCategory');
const isSubmitLoadingAuthorSelector = getSelector('isSubmitLoadingAuthor');
const isSubmitIdLoadingSelector = getSelector('isSubmitIdLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const updateIdSuccessSelector = getSelector('updateIdSuccess');
const updateIdFailedSelector = getSelector('updateIdFailed');

const pagingSelector = getSelector('paging');

export const useNewsQueryParams = () => {
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const status = query.get('status') || 'PUBLISHED,PRIVATE,EDITING';

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteNewsSuccess = useSelector(deleteSuccessSelector);
  const updateNewsSuccess = useSelector(updateIdSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined,
      status,
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteNewsSuccess, status,updateNewsSuccess]);
};

export const useNewses = query => {
  const localtion = useLocation()
  const state = useMemo(()=>({...query,...localtion.state}),[localtion,query])
  return useFetchByParam({
    action: getNewses,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: state
  });
};

export const useNewsPaging = () => useSelector(pagingSelector);

export const useCreateNews = () => {
  const history = useHistory();
  const callback = () => history.push('/news');

  useSuccess(createSuccessSelector, 'Tạo mới tin tức thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới tin tức thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createNews
  });
};

export const useUpdateNews = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật tin tức thành công');
  useFailed(updateFailedSelector, 'Cập nhật tin tức thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateNews
  });
};
export const useUpdateNewsId = () => {
  useSuccess(updateIdSuccessSelector, 'Cập nhật tin tức thành công');
  useFailed(updateIdFailedSelector, 'Cập nhật tin tức thất bại');

  return useSubmit({
    loadingSelector: isSubmitIdLoadingSelector,
    action: updateNewsid
  });
};

export const useDeleteNews = () => {
  useSuccess(deleteSuccessSelector, 'Xoá tin tức thành công');
  useFailed(deleteFailedSelector, 'Xoá tin tức thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteNews
  });
};

export const useNews = id => {
  return useFetchByParam({
    action: getNews,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

const initContentVi = '<p>Xin mời nhập nội dung tin tức</p>';
const initContentEn = '<p>Please input content</p>';

export const useInitNews = news =>
  useMemo(() => {
    if (!news) {
      return {
        content: {
          vi: initContentVi,
          en: initContentEn
        }
      };
    }
    return {
      ...news,
      content: {
        vi: news?.content?.vi || initContentVi,
        en: news?.content?.en || initContentEn
      }
    };
  }, [news]);

export const useResetNews = () => {
  useResetState(resetNewsState);
};

export const useInitOptionNewsSearchSelect=({dataAuthor,dataCategory})=>{
  const optionAuthor = useMemo(() => dataAuthor.map((e) => ({ label: e.fullName, value: e._id })), [dataAuthor]);
  const optionCategory = useMemo(() => dataCategory.map((e) => ({ label: e.name, value: e._id })), [dataCategory]);
  return [optionAuthor,optionCategory]
}

export const useSearchNews =(param)=>{
// const [,handleGetCategory]=  useSubmit({
//     loadingSelector: isSubmitLoadingCategorySelector,
//     action: searchCatogoryNewsState
//   });
// const [,handleGetAuthor]=   useSubmit({
//     loadingSelector: isSubmitLoadingAuthorSelector,
//     action: searchAuthorNewsState
//   });

  const [Category] = useFetchByParam({
    action: searchCatogoryNewsState,
    loadingSelector:loadingCatogorySelector,
    dataSelector: listCategorySelector,
    failedSelector: getListFailedSelector,
    param
  });
  
  const [Author] = useFetchByParam({
    action: searchAuthorNewsState,
    loadingSelector:loadingAuthorSelector,
    dataSelector: listAuthorSelector,
    failedSelector: getListFailedSelector,
    param
  });
  return [
    [Category??[],Author??[]],
    // [handleGetCategory,handleGetAuthor]
]
}
