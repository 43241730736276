import React, { useState, useEffect, useMemo } from 'react';

import { Button, Col, Form, Input, Row, Select, Upload, } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { get } from 'lodash';

import Breadcrumb from '~/components/Common/Breadcrumb';
import { BASE_URL, LANGUAGE, SYSTEM_CONFIGURATION_TYPES } from '~/constants/defaultValue';
import {
  useBookingInstructions,
  useCreateSystemConfiguration,
  useSystemConfigurationQueryParams,
  useUpdateSystemConfiguration
} from '~/hooks/systemConfiguration';

import './index.scss';
import { WithOrPermission, WithPermission } from '../Common';
import POLICIES from '~/constants/policy';
import { useMatchOrPolicy, useMatchPolicy } from '~/hooks';

const FormItem = Form.Item;

const ServiceBookingInstruction = ({ onClose, data }) => {

  const canUpdateOrCreate = useMatchOrPolicy([POLICIES.UPDATE_WHSERVICEBOOKINGINSTRUCTIONSSETUP,POLICIES.WRITE_WHSERVICEBOOKINGINSTRUCTIONSSETUP])
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_WHSERVICEBOOKINGINSTRUCTIONSSETUP)
  const canUpdateOrDelete = useMatchOrPolicy([POLICIES.UPDATE_WHSERVICEBOOKINGINSTRUCTIONSSETUP,POLICIES.DELETE_WHSERVICEBOOKINGINSTRUCTIONSSETUP])
  const [form] = Form.useForm();
  const [formStep] = Form.useForm();

  const [query, onPagingChange] = useSystemConfigurationQueryParams();
  const [bookingInstructions, isLoading] = useBookingInstructions(query);
  const [valueStepDefault, setValueStepDefault] = useState([]);
  const [, handleUpdate] = useUpdateSystemConfiguration();
  const [, handleCreate] = useCreateSystemConfiguration();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    form.resetFields();
  }, [bookingInstructions, form, formStep]);

  const mergedBookingInstruction = useMemo(() => {
    if (!bookingInstructions || !bookingInstructions.length) {
      return null;
    }
    if (Array.isArray(bookingInstructions)) {
      return bookingInstructions[0];
    }
  }, [bookingInstructions]);
  const indexEndDisabled = useMemo(() => {
    return get(mergedBookingInstruction,'value.steps',[])?.length
  },[mergedBookingInstruction])
  useEffect(() => {
    if (mergedBookingInstruction) {
      const { value: { videoUrl, pdfUrl, steps } } = mergedBookingInstruction;
      const { fileList } = pdfUrl;
      setFileList(fileList);
      form.setFieldsValue({ videoUrl, pdfUrl });
      formStep.setFieldsValue({ steps });
    }
  }, [form, formStep, mergedBookingInstruction])

  const onFileChange = async ({ file, fileList }) => {
    if (file.status !== 'uploading') {
      if (Array.isArray(fileList)) {
        const newFileList = fileList.map(item => {
          if (!get(item, "response")) return item;
          return {
            ...item,
            url: get(item, "response.url"),
          }
        });
        setFileList([...newFileList]);
      }
    } else {
      setFileList([...fileList]);
    }
  }

  const handleChangeStep = (valuesChange, allChanges) => {
    valueStepDefault.map((el) => {
      if (Object.keys(valuesChange)[0] == el.key) {
        return {
          key: el.key,
          value: valuesChange[Object.keys(valuesChange)[0]]
        };
      } else {
        return el;
      }
    });
  };

  const onFinish = (values) => {
    if (get(mergedBookingInstruction, "id")) {
      handleUpdate({
        ...mergedBookingInstruction,
        value: {
          ...form.getFieldsValue(),
          ...formStep.getFieldsValue(),
          pdfUrl: {
            fileList,
          }
        }
      });
    } else {
      handleCreate({
        key: SYSTEM_CONFIGURATION_TYPES.BOOKING_INSTRUCTIONS,
        value: {
          ...form.getFieldsValue(),
          ...formStep.getFieldsValue(),
          pdfUrl: {
            fileList,
          }
        }
      })
    }
  };

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Hướng dẫn đặt dịch vụ" />

        <Form
          form={form}
          autoComplete="off"
          scrollToFirstError
          requiredMark={false}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <FormItem
            label="Link video"
            name="videoUrl"
            rules={[
              //{ type: 'url', warningOnly: true },
              { type: 'string', min: 6 }
            ]}
          >
            <Input disabled={!canUpdateOrCreate}/>
          </FormItem>

          <FormItem
            label="Link file pdf"
            name="pdfUrl"
          >
            <Upload
              fileList={fileList}
              action={`${BASE_URL}/api/v1/file/bookingInstruction`}
              style={{ width: '60%' }}
              onChange={onFileChange}
              listType="pdf"
              maxCount={1}
              disabled={!canUpdateOrDelete}
            >
              <Button disabled={!canUpdateOrCreate} icon={<UploadOutlined />}>Upload file pdf</Button>
            </Upload>
          </FormItem>
        </Form>

        <Form
          form={formStep}
          onValuesChange={handleChangeStep}
        >
          <h4>Các bước đặt lịch</h4>
          <Form.List name="steps">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row key={index} style={{ width: '100%' }}>
                    <Col span={20}>
                      <Row style={{ width: '100%' }}>
                        <Form.Item
                          label={`Bước ${index + 1} (vi)`}
                          name={[index, LANGUAGE.VI]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Vui lòng nhập hoặc xoá bước.'
                            }
                          ]}
                        >
                          <Input
                            // defaultValue={field.value}
                            style={{ width: '100%' }}
                            disabled={ !canUpdate && index < indexEndDisabled}
                          />
                        </Form.Item>
                      </Row>
                      <Row style={{ width: '100%' }}>
                        <Form.Item
                          label={`Bước ${index + 1} (en)`}
                          name={[index, LANGUAGE.EN]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Vui lòng nhập hoặc xoá bước.'
                            }
                          ]}
                        >
                          <Input
                            // defaultValue={field.value}
                            style={{ width: '100%' }}
                            disabled={!canUpdate && index < indexEndDisabled}
                          />
                        </Form.Item>
                      </Row>
                    </Col>
                    <Col span={4}>
                          <WithOrPermission permission={[POLICIES.UPDATE_WHSERVICEBOOKINGINSTRUCTIONSSETUP,POLICIES.DELETE_WHSERVICEBOOKINGINSTRUCTIONSSETUP]}>
                          {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(index)}
                        />
                      ) : null}
                          </WithOrPermission>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                
                <WithOrPermission permission={[POLICIES.UPDATE_WHSERVICEBOOKINGINSTRUCTIONSSETUP,POLICIES.WRITE_WHSERVICEBOOKINGINSTRUCTIONSSETUP]}>
                <Button
                    type="dashed"
                    onClick={() => add({})}
                    style={{
                      width: '100%'
                    }}
                    icon={<PlusOutlined />}
                  >
                    Thêm bước
                  </Button>
                </WithOrPermission>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
          <WithOrPermission permission={[POLICIES.UPDATE_WHSERVICEBOOKINGINSTRUCTIONSSETUP,POLICIES.WRITE_WHSERVICEBOOKINGINSTRUCTIONSSETUP,POLICIES.DELETE_WHSERVICEBOOKINGINSTRUCTIONSSETUP]}>
          <Button type="primary" htmlType="submit" onClick={onFinish}>
              { mergedBookingInstruction ? 'Cập nhật' : 'Tạo cài đặt' }
            </Button>
          </WithOrPermission>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ServiceBookingInstruction;

