import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getCompanyJobsFailed: null,
  getCompanyJobFailed: null,
  CompanyJobs: [],
  CompanyJob: null,
  isSubmitLoading: false,
  isGetCompanyJobLoading: false,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,

  createSuccess: null,
  createFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_COMPANY_JOBS_REQUEST:
      state.isLoading = true;
      state.getCompanyJobsFailed = null;
      return;

    case Types.GET_COMPANY_JOBS_SUCCESS:
      state.isLoading = false;
      console.log(payload,'payload');
      state.CompanyJobs = get(payload, 'docs', []);
      state.getCompanyJobsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_COMPANY_JOBS_FAILED:
      state.isLoading = false;
      state.CompanyJobs = [];
      state.getCompanyJobsFailed = payload;
      return;
    case Types.GET_COMPANY_JOB_REQUEST:
      state.isGetCompanyJobLoading = true;
      state.CompanyJob = null
      state.getCompanyJobFailed = null;
      return;

    case Types.GET_COMPANY_JOB_SUCCESS:
      state.isGetCompanyJobLoading = false;
      state.CompanyJob = payload;
      state.getCompanyJobFailed = null;
      return;

    case Types.GET_COMPANY_JOB_FAILED:
      state.isGetCompanyJobLoading = false;
      state.CompanyJob = null;
      state.getCompanyJobFailed = payload;
      return;
    case Types.CREATE_COMPANY_JOB_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_COMPANY_JOB_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_COMPANY_JOB_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_COMPANY_JOB_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_COMPANY_JOB_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.CompanyJobs = state.CompanyJobs.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.CompanyJob = payload;
      return;

    case Types.UPDATE_COMPANY_JOB_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_COMPANY_JOB_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_COMPANY_JOB_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_COMPANY_JOB_ACTION:
      state.getCompanyJobsFailed = null;
      state.getCompanyJobFailed = null;
      state.CompanyJob = null;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      state.updateSuccess = null;
      state.updateFailed = null;
      state.createSuccess = null;
      state.createFailed = null;
      return ;

    case Types.RESET_STORE:
    case Types.RESET_COMPANY_JOB_STATE:
      return initState;

    default:
      return;
  }
}, initState);
