import * as Types from '../../constants/actionTypes';

export const getSystems = (query) => {
  return {
    type: Types.GET_SYSTEMS_REQUEST,
    payload: query
  };
};

export const getSystem = (id) => ({
  type: Types.GET_SYSTEM_REQUEST,
  payload: id
});


export const updateSystem = (id) => ({
  type: Types.UPDATE_SYSTEM_REQUEST,
  payload: id
});


export const resetSystemState = () => ({
  type: Types.RESET_SYSTEM_STATE
});
