import produce from 'immer';
import {
  GET_STAFFS_REQUEST,
  GET_STAFFS_SUCCESS,
  GET_STAFFS_FAILED,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAILED,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  CREATE_STAFF_FAILED,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  GET_STAFF_FAILED,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_FAILED,
  RESET_STAFF_STATE,
  RESET_STORE
} from '../../constants/actionTypes';
import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getStaffsFailed: undefined,
  staffs: [],

  isGetStaffLoading: false,
  staff: null,
  getStaffFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_STAFFS_REQUEST:
      state.isLoading = true;
      state.getStaffsFailed = null;
      return;

    case GET_STAFFS_SUCCESS:
      state.isLoading = false;
      state.staffs = payload.docs;
      state.getStaffsFailed = null;
      state.paging = getPaging(payload);
      return;

    case GET_STAFFS_FAILED:
      state.isLoading = false;
      state.staffs = [];
      state.getStaffsFailed = null;
      return;

    case GET_STAFF_REQUEST:
      state.isGetStaffLoading = true;
      state.staff = null;
      state.getStaffFailed = null;
      return;

    case GET_STAFF_SUCCESS:
      state.isGetStaffLoading = false;
      state.staff = payload;
      return;

    case GET_STAFF_FAILED:
      state.isGetStaffLoading = false;
      state.getStaffFailed = payload;
      return;

    case DELETE_STAFF_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_STAFF_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_STAFF_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_STAFF_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_STAFF_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_STAFF_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case UPDATE_STAFF_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_STAFF_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.staff = payload;
      return;

    case UPDATE_STAFF_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case RESET_STAFF_STATE:
    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
