import React from 'react';
import { PATH_APP } from '~/routes/paths';
import { useDegrees, useDeleteDegree } from '~/hooks/degree';
import { Table, Popconfirm, Button } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { Link } from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { WithPermission } from '../Common';
import POLICIES from '~/constants/policy';
import { get } from 'lodash';

const columns = [
  {
    title: 'Tên Chứng chỉ',
    dataIndex: 'name',
    key: 'name',
  },

  {
    title: 'Thao tác',
    key: 'action',
    width: '100px',
    render: (record) => <ColumnActions {...record} />
  }
];

const ColumnActions = ({ id }) => {
  const [, deleteDegree] = useDeleteDegree();

  return (
    <div className="custom-table__actions">
    <WithPermission permission={POLICIES.UPDATE_CONFIGURATION}>
      <Link to={`/degree/edit/${id}`}>
        <p>Sửa</p>
      </Link>
    </WithPermission>
    <WithPermission permission={POLICIES.UPDATE_CONFIGURATION}>
        <WithPermission permission={POLICIES.DELETE_CONFIGURATION}>
        <p>|</p>
        </WithPermission>
    </WithPermission>
    <WithPermission permission={POLICIES.DELETE_CONFIGURATION}>
      <Popconfirm
        title="Bạn muốn xoá phòng khám này?"
        onConfirm={() => deleteDegree(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </WithPermission>
    </div>
  );
};

const Degrees = () => {
  const [degrees, isLoading] = useDegrees();
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách chứng chỉ" />

        <div className="page-wraper__header page-wraper__header--flex-end">
        <WithPermission permission={POLICIES.WRITE_CONFIGURATION}>
        <Link to={PATH_APP.config.createDegree}>
            <Button type="primary">Tạo mới</Button>
          </Link>
        </WithPermission>
        </div>

        {isLoading ? (
          <SkeletonTable columns={columns} />
        ) : (
          <Table
            rowKey={(rc) => rc.id}
            columns={columns}
            dataSource={degrees}
            size="middle"
            pagination={{
              total: degrees.length,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Degrees;
