import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhCategories,
  createWhCategory,
  deleteWhCategory,
  getWhCategory,
  resetWhCategoryState,
  updateWhCategory,
  getWhCategoriesList
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import {
  createWhCategoryShort,
  getWhCategoriesShort,
  getWhCategoryShort,
  resetWhCategoryStateShort,
  updateWhCategoryShort
} from '~/redux/whCategoryShort/actions';

const getSelector = (key) => (state) => state.whCategory[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const listCategorysSelector = getSelector('listCategorys');
const getListCategorysFailedSelector = getSelector('getListCategorysFailed');

const isLoadingListSelector = getSelector('isLoadingList');
const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useWhCategoryPaging = () => useSelector(pagingSelector);

export const useWhCategory = (id) => {
  return useFetchByParam({
    action: getWhCategory,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};
export const useWhCategoryList = (query) => {
  return useFetchByParam({
    action: getWhCategoriesList,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
}
export const useUpdateWhCategoryParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    history.push({
      pathname: '/wh-category',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useWhCategoryQueryParams = (defaultValue) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || defaultValue || 10;
  const name = query.get('name');

  const [page, setPage] = useState(query.get('page') || 1);
 
  const onTableChange = ({ current }) => {
    setPage(current);
  };
  const deleteWhServiceSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== name) {
    prevKeyword.current = name;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      name: name || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, name, deleteWhServiceSuccess]);
};

export const useWhCategories = (query) => {
  return useFetchByParam({
    action: getWhCategories,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateWhCategory = () => {
  const history = useHistory();
  const callback = () => history.push('/wh-category');

  useSuccess(
    createSuccessSelector,
    'Tạo mới nhóm dịch vụ thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới nhóm dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhCategory
  });
};

//** */
export const useUpdateWhCategory = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật nhóm dịch vụ thành công');
  useFailed(updateFailedSelector, 'Cập nhật nhóm dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhCategory
  });
};

export const useDeleteWhCategory = () => {
  useSuccess(deleteSuccessSelector, 'Xoá nhóm dịch vụ thành công');
  useFailed(deleteFailedSelector, 'Xoá nhóm dịch vụ thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhCategory
  });
};

export const useInitWhCategory = (whCategory) =>
  useMemo(() => {
    if (!whCategory) {
      return {
        image: {}
      };
    }

    return whCategory;
  }, [whCategory]);

export const useResetWhCategory = () => {
  useResetState(resetWhCategoryState);
};
export const useResetWhCategoryShort = () => {
  useResetState(resetWhCategoryStateShort);
};
