
import * as Types from '../../constants/actionTypes';
import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import Api from '../../api';

function *getNotifyAccounts(){
   try{
     const response = yield call(Api.notificationBotManagement.getNotifyAccounts);
    yield put({
      type: Types.GET_NOTIFY_ACCOUNTS_SUCCESS,
      payload: response
    })
   }
   catch(err){
    yield put({
      type: Types.GET_NOTIFY_ACCOUNTS_FAILED,
      payload: err.message
    })
   }
}

function *updateNotifyAccounts(action){
  try{
    const response = yield call(Api.notificationBotManagement.updateNotifyAccounts, action.payload);
    yield put({
      type: Types.UPDATE_NOTIFY_ACCOUNTS_SUCCESS,
      payload: response
    })
  }catch(err){
    yield put({
      type: Types.UPDATE_NOTIFY_ACCOUNTS_FAILED,
      payload: err.message
    })
  }
}

// function *getNewOrderNotifyEmails(){
//   try{
    
//     const response = yield call(Api.notificationBotManagement.getNewOrderNotifyEmails);
//     yield put({type: Types.GET_EMAIL_NOTIFY_FOR_NEW_ORDER_SUCCESS, payload: response});

//   }catch(err){
//     yield put({type: Types.GET_EMAIL_NOTIFY_FOR_NEW_ORDER_FAILED, payload: err.message})
//   }
// }
// function *getNewOrderNotifyZaloAccounts(){
//   try{
    
//     const response = yield call(Api.notificationBotManagement.getNewOrderNotifyZaloAccounts);
//     yield put({type: Types.GET_ZALO_NOTIFY_ACCOUNTS_FOR_NEW_ORDER_SUCCESS, payload: response});

//   }catch(err){
//     yield put({type: Types.GET_ZALO_NOTIFY_ACCOUNTS_FOR_NEW_ORDER_FAILED, payload: err.message})
//   }
// }
// function *getAssignedPartnerNotifyEmails(){
//   try{
    
//     const response = yield call(Api.notificationBotManagement.getAssignedPartnerNotifyEmails);
//     yield put({type: Types.GET_EMAIL_NOTIFY_FOR_ASSIGNED_PARTNER_SUCCESS, payload: response});

//   }catch(err){
//     yield put({type: Types.GET_EMAIL_NOTIFY_FOR_ASSIGNED_PARTNER_FAILED, payload: err.message})
//   }
// }
// function *getAssignedPartnerNotifyZaloAccounts(){
//   try{
    
//     const response = yield call(Api.notificationBotManagement.getAssignedPartnerNotifyZaloAccounts);
//     yield put({type: Types.GET_ZALO_NOTIFY_ACCOUNTS_FOR_ASSIGNED_PARTNER_SUCCESS, payload: response});

//   }catch(err){
//     yield put({type: Types.GET_ZALO_NOTIFY_ACCOUNTS_FOR_ASSIGNED_PARTNER_FAILED, payload: err.message})
//   }
// }

// function *updateEmailNotifyForNewOrder(action){
//   try{
    
//     const response = yield call(Api.notificationBotManagement.updateEmailNotifyForNewOrder, action.payload);
//     yield put({type: Types.UPDATE_EMAIL_NOTIFY_FOR_NEW_ORDER_SUCCESS, payload: response});

//   }catch(err){
//     yield put({type: Types.UPDATE_EMAIL_NOTIFY_FOR_NEW_ORDER_FAILED, payload: err.message})
//   }
// }

// function *updateZaloNotifyForNewOrder(action){
//   try{
    
//     const response = yield call(Api.notificationBotManagement.updateZaloNotifyForNewOrder, action.payload);
//     yield put({type: Types.UPDATE_ZALO_NOTIFY_ACCOUNTS_FOR_NEW_ORDER_SUCCESS, payload: response});

//   }catch(err){
//     yield put({type: Types.UPDATE_ZALO_NOTIFY_ACCOUNTS_FOR_NEW_ORDER_FAILED, payload: err.message})
//   }
// }

// function *updateZaloNotifyForAssignedPartner(action){
//   try{
    
//     const response = yield call(Api.notificationBotManagement.updateZaloNotifyForAssignedPartner, action.payload);
//     yield put({type: Types.UPDATE_ZALO_NOTIFY_ACCOUNTS_FOR_ASSIGNED_PARTNER_SUCCESS, payload: response});

//   }catch(err){
//     yield put({type: Types.UPDATE_ZALO_NOTIFY_ACCOUNTS_FOR_ASSIGNED_PARTNER_FAILED, payload: err.message})
//   }
// }

// function *updateEmailNotifyForAssignedPartner(action){
//   try{
    
//     const response = yield call(Api.notificationBotManagement.updateEmailNotifyForAssignedPartner, action.payload);
//     yield put({type: Types.UPDATE_EMAIL_NOTIFY_FOR_ASSIGNED_PARTNER_SUCCESS, payload: response});

//   }catch(err){
//     yield put({type: Types.UPDATE_EMAIL_NOTIFY_FOR_ASSIGNED_PARTNER_FAILED, payload: err.message})
//   }
// }

export default function* notificationBotManagement() {
  yield takeLatest(Types.GET_NOTIFY_ACCOUNTS_REQUEST, getNotifyAccounts);
  yield takeEvery(Types.UPDATE_NOTIFY_ACCOUNTS_REQUEST, updateNotifyAccounts)
  // yield takeLatest(Types.GET_EMAIL_NOTIFY_FOR_NEW_ORDER_REQUEST, getNewOrderNotifyEmails);
  // yield takeLatest(Types.GET_EMAIL_NOTIFY_FOR_ASSIGNED_PARTNER_REQUEST, getAssignedPartnerNotifyEmails);
  // yield takeLatest(Types.GET_ZALO_NOTIFY_ACCOUNTS_FOR_ASSIGNED_PARTNER_REQUEST, getNewOrderNotifyZaloAccounts);
  // yield takeLatest(Types.GET_ZALO_NOTIFY_ACCOUNTS_FOR_NEW_ORDER_REQUEST, getNewOrderNotifyZaloAccounts);
  // yield takeEvery(Types.UPDATE_EMAIL_NOTIFY_FOR_ASSIGNED_PARTNER_REQUEST, updateEmailNotifyForAssignedPartner);
  // yield takeEvery(Types.UPDATE_EMAIL_NOTIFY_FOR_NEW_ORDER_REQUEST, updateEmailNotifyForNewOrder);
  // yield takeEvery(Types.UPDATE_ZALO_NOTIFY_ACCOUNTS_FOR_ASSIGNED_PARTNER_REQUEST, updateZaloNotifyForAssignedPartner);
  // yield takeEvery(Types.UPDATE_ZALO_NOTIFY_ACCOUNTS_FOR_NEW_ORDER_REQUEST, updateZaloNotifyForNewOrder);
}
