import { CopyOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import { get } from 'lodash';
import SkeletonInput from 'antd/lib/skeleton/Input';
import api from '~/api';

export default function LinkReferralCustomer({
  customer,
  isGetWhPartnerLoading,
  fullWidth
}) {
  const config = fullWidth
    ? { flex: 1 }
    : {
        lg: 8,
        md: 8,
        xl: 8,
        xs: 14
      };
  const [referralLink, setReferralLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleRefresh = async () => {
    setLoading(true);
    try {
        const res = await api.whCustomerManagement.refreshLink(get(customer, '_id', ''));
    if (res) {
      setReferralLink({
        partner: get(res, 'data.referralLink.partner', ''),
        customer: get(res, 'data.referralLink.customer', '')
      });
    }
    setLoading(false);
    } catch (error) {
        setLoading(false);
    }
    
   
  };
  useEffect(() => {
    if (customer){

      setReferralLink({
        partner: get(customer, 'referralLink.partner', ''),
        customer: get(customer, 'referralLink.customer', '')
      });
      if(!get(customer, 'referralLink')){
        handleRefresh()
      }
    }
  }, [customer]);

  const handleCopyPartner = () => {
    navigator.clipboard.writeText(get(referralLink, 'partner', ''));
    toastr.success('Đã copy mã giới thiệu cho đối tác');
  };
  const handleCopyCustomer = () => {
    navigator.clipboard.writeText(get(referralLink, 'customer', ''));
    toastr.success('Đã copy mã giới thiệu cho khách hàng');
  };
  return (
    <div className="referralLink">
      <Divider>Link giới thiệu</Divider>
      <Button
        type="primary"
        disabled={loading || isGetWhPartnerLoading}
        onClick={handleRefresh}
        className="referralLink--refresh"
      >
        Làm mới <RedoOutlined spin={loading} />
      </Button>
      <Row align="middle">
        <Col lg={8} md={8} xl={5} xs={10}>
          <Text strong>Mã giới thiệu cho khách hàng</Text>
        </Col>
        {/* <Col  lg={8} md={8} xl={8} xs={14}> */}
        <Col {...config}>
          {isGetWhPartnerLoading ? (
            <SkeletonInput />
          ) : (
            <Input
              value={get(referralLink, 'customer', '')}
              addonAfter={
                <Button onClick={handleCopyCustomer}>
                  <CopyOutlined />
                </Button>
              }
            />
          )}
        </Col>
      </Row>
      <Row align="middle">
        <Col lg={8} md={8} xl={5} xs={10}>
          <Text strong>Mã giới thiệu cho đối tác</Text>
        </Col>
        {/* <Col lg={8} md={8} xl={8} xs={14}> */}
        <Col {...config}>
          {isGetWhPartnerLoading ? (
            <SkeletonInput />
          ) : (
            <Input
              value={get(referralLink, 'partner', '')}
              addonAfter={
                <Button onClick={handleCopyPartner}>
                  <CopyOutlined />
                </Button>
              }
            />
          )}
        </Col>
      </Row>
      <Divider></Divider>
    </div>
  );
}
