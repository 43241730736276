import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getEmployeeGroups({ payload: branchId }) {
  try {
    const data = yield call(Api.employeeGroup.getAll, branchId);
    yield put({ type: Types.GET_EMPLOYEE_GROUPS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_EMPLOYEE_GROUPS_FAILED,
      payload: error.message
    });
  }
}

function* getEmployeeGroup({ payload }) {
  try {
    const employeeGroup = yield call(Api.employeeGroup.getById, payload);
    yield put({
      type: Types.GET_EMPLOYEE_GROUP_SUCCESS,
      payload: employeeGroup
    });
  } catch (error) {
    yield put({
      type: Types.GET_EMPLOYEE_GROUP_FAILED,
      payload: error.message
    });
  }
}

function* createEmployeeGroup(action) {
  try {
    const data = yield call(Api.employeeGroup.create, action.payload);
    yield put({ type: Types.CREATE_EMPLOYEE_GROUP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_EMPLOYEE_GROUP_FAILED,
      payload: error.message
    });
  }
}

function* updateEmployeeGroup(action) {
  try {
    const data = yield call(Api.employeeGroup.update, action.payload);
    yield put({ type: Types.UPDATE_EMPLOYEE_GROUP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_EMPLOYEE_GROUP_FAILED,
      payload: error.message
    });
  }
}

function* deleteEmployeeGroup({ payload }) {
  try {
    yield call(Api.employeeGroup.delete, payload);
    yield put({ type: Types.DELETE_EMPLOYEE_GROUP_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_EMPLOYEE_GROUP_FAILED,
      payload: error.message
    });
  }
}

function* getResources({ payload }) {
  try {
    const resources = yield call(Api.employeeGroup.getResources, payload);
    yield put({ type: Types.GET_RESOURCES_SUCCESS, payload: resources });
  } catch (error) {
    yield put({
      type: Types.GET_RESOURCES_FAILED,
      payload: error.message
    });
  }
}

function* updateGroupPermission({ payload }) {
  try {
    const { isAssgined, companyId, groupId, ...rest } = payload;
    const request = isAssgined
      ? Api.employeeGroup.assignPermission
      : Api.employeeGroup.removePermission;

    yield call(request, { ...rest, groupId });

    // I Remove Refetch because Now Dont need to ReFetch data because I Used CloneData
    // yield put({ 
    //   type: Types.GET_EMPLOYEE_GROUP_REQUEST,
    //   payload: { companyId, groupId }
    // });

    yield put({
      type: Types.UPDATE_GROUP_PERMISSION_SUCCESS
    });
  } catch (error) {
    yield put({
      type: Types.UPDATE_GROUP_PERMISSION_FAILED,
      payload: error.message
    });
  }
}

export default function* employeeGroup() {
  yield takeLatest(Types.GET_EMPLOYEE_GROUPS_REQUEST, getEmployeeGroups);
  yield takeLatest(Types.GET_EMPLOYEE_GROUP_REQUEST, getEmployeeGroup);
  yield takeLatest(Types.DELETE_EMPLOYEE_GROUP_REQUEST, deleteEmployeeGroup);
  yield takeLatest(Types.CREATE_EMPLOYEE_GROUP_REQUEST, createEmployeeGroup);
  yield takeLatest(Types.UPDATE_EMPLOYEE_GROUP_REQUEST, updateEmployeeGroup);
  yield takeLatest(Types.GET_RESOURCES_REQUEST, getResources);
  yield takeLatest(
    Types.UPDATE_GROUP_PERMISSION_REQUEST,
    updateGroupPermission
  );
}
