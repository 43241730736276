import { InfoCircleOutlined } from '@ant-design/icons';
import {  Badge, Table, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect,  useState } from 'react'
import { WH_PARTNER_WAY_WORKING } from '~/constants/defaultValue';
import { formatNumberThreeComma } from '~/hooks/utils';
import { useReportById } from '~/hooks/whReport';
import TableDetail from './TableDetail'

export default function TableDidService({id,type,setTotalDetailAll,date,dataPartnerSnapShort}) {
  const [data,loading] = useReportById(id,date)
  useEffect(() => {
    const totalInFulltime = get(data,'data',[])?.reduce((sum,cur) => {
      const isFullTime = dataPartnerSnapShort?.some(partner =>  
        get(partner,'partnerSnapshot.wayWorking') === WH_PARTNER_WAY_WORKING.fulltime && moment(get(cur,'receiptVoucher.dateApproved')).format("MM-YYYY") === moment(get(partner,'date')).format("MM-YYYY") ||
        get(partner,'partnerSnapshot.wayWorking') === WH_PARTNER_WAY_WORKING.fulltime && !get(cur,'receiptVoucher.dateApproved')
      )
        if(isFullTime){
          return sum + (get(cur,'totalMoney',0))
        }
        else 
          if(type === WH_PARTNER_WAY_WORKING.fulltime){
            return sum + get(cur,'totalMoney',0) 
          }
          else{
            return sum
          }
    },0 )
    setTotalDetailAll('didService',parseInt(get(data,'totalAllMoney',0)?.toFixed(0)) - totalInFulltime)
  },[type,id,data,date,dataPartnerSnapShort])
    const columns = [
        {
          title: 'STT',
          key: null,
          dataIndex: null,
          width: 50,
          align : 'center',
          render: (item,record,index) => (
            <span>
              {index + 1}
            </span>
          )
        },
        {
          title: 'Mã đơn hàng',
          key: 'whBill',
          width : '150px',
          dataIndex: 'whBill',
          align : 'center',
          render : (item,record,index) => ({
            children : get(item,'billNumber'),
            props : {
              rowSpan : get(record,'rowSpan',0) 
            }
          })
        },
        {
          title: 'Dịch vụ thực hiện',
          width : '200px',
          key: 'whBillItem',
          dataIndex: 'whBillItem',
          align : 'center',
          render: (item,record) => {
            return (
              <span>
                 {!!get(record,'snapshotService.detailName.vi') ? `${get(item,'snapshotService.code')} - ${get(item,'snapshotService.name.vi')}  - ${get(record,'snapshotService.detailName.vi')}` : `${get(item,'snapshotService.code')} - ${get(item,'snapshotService.name.vi')}`}
              </span>
            )
          }
        },
        {
          title: 'Gói DV',
          key: 'whBillItem',
          dataIndex: 'whBillItem',
          align : 'center',
          render: (item,record) => (
            <span>
             {get(item,'snapshotService.package.packageLevelName.vi')}
            </span>
          )
        },
        {
          title: 'Số lần thực hiện buổi hẹn',
          key: 'count',
          width : '200px',
          dataIndex: 'count',
          align : 'center',
          render: (item,record) => (
            <span>
             {item || 1}
            </span>
          )
        },
        {
          title: 'Đơn giá',
          key: 'whBillItem',
          dataIndex: 'whBillItem',
          align : 'center',
          render: (item,record) => (
            <span>
              {formatNumberThreeComma(get(record,'whBillItem.price',0))}
            </span>
          )
        },
        {
          title: 'Ngày thực hiện dịch vụ',
          key: 'date',
          width : '200px',
          dataIndex: 'whBillItem',
          align : 'center',
          render: (item,record) => (
            <div>
            <div>
              {moment(get(item,'startDate',0)).format("DD-MM-YYYY")}
            </div>
            <div>
              -
            </div>
            <div>
              {moment(get(item,'endDate',0)).format("DD-MM-YYYY")}
            </div>
            </div>
          )
        },
        {
          title: '% thu nhập',
          key: 'whPackageLevelsSnap',
          dataIndex: 'whPackageLevelsSnap',
          align : 'center',
          render: (item,record) => {
            return `${get(item,'percentIncome',0)}%`
            // for (const [key, value] of Object.entries(get(record,'partnerSnapShort.snapshortEmployeeGroup.rateForLevel',{}))) {
            //      if(parseInt(key) === parseInt(get(item,'level'))){
            //     return `${value?.percentIncome || 0}%`
            //   }
            // }
          }
        },
        {
          title: 'Thưởng',
          key: 'bouns',
          dataIndex: 'whPackageLevelsSnap',
          align : 'center',
          render: (item,record) => {
            // for (const [key, value] of Object.entries(get(record,'partnerSnapShort.snapshortEmployeeGroup.rateForLevel',{}))) {
            //      if(parseInt(key) === parseInt(get(item,'level'))){
            //     return `${value?.bonus || 0}%`
            //   }
            // }
            return `${get(item,'bonus',0)}%`
          }
          
        },
        {
          title: <Tooltip
          
          placement='leftTop'
          zIndex={2001}
          title={<span style={{fontSize: 'calc(0.2vw + 0.65rem)'}}>Thành tiền = (Số lần thực hiện buổi hẹn	* Đơn giá	* ((%thu nhập + %Thưởng)/100))</span>}
        >
          <Badge
            size="small"
            color="#9B9999"
            // offset={[9, 3]}
            count={<InfoCircleOutlined />}
          >
           <Text style={{color : 'black'}}>Thành tiền</Text>
          </Badge>
        </Tooltip>,
          key: 'totalPrice',
          dataIndex: 'totalMoney',
          align : 'center',
          render : (item,record) => formatNumberThreeComma(item.toFixed(0)) || 0
        }
      ];
      const summaryFooter = (currentData) =>  
    {
      return   <Table.Summary>
      <Table.Summary.Row style={{height : '10px'}}>
        <Table.Summary.Cell align='center' >
        TC
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center' >
        {currentData?.length || 0}
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        {currentData?.length || 0}
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        {/* {data?.totalPrice?.percentIncome || 0}% */}
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        {/* {data?.totalPrice?.bouns || 0}% */}
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
        {(formatNumberThreeComma(get(data,'totalAllMoney',0).toFixed(0))) || 0}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
    }
  return (
    <div>
 <TableDetail loading={loading} pagination={false} dataSource={Array.isArray(data?.data) && get(data,'data')} columns={columns} title={"Thu nhập tính theo số lần thực hiện dịch vụ (A)"} footer={summaryFooter}/>
       
    </div>
  )
}
