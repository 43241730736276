import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Spin, Tag } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Breadcrumb } from 'reactstrap';

import {
  ASSIGNED_PARTNER_NOTIFY_ZALO,
  NEW_DEV_CONFIG_CAMPAGIN_EMAIL,
  NEW_ORDER_NOTIFY_EMAIL,
  NEW_ORDER_NOTIFY_ZALO,
  NEW_REQUEST_VOUCHER,
  PARTNER_CHECKIN_NOTIFY_ZALO,
  PARTNER_CHECKOUT_NOTIFY_ZALO,
  PREPAY_CONFIRMED_ORDER_NOTIFY_ZALO,
  REQUEST_CONVERT_ACCOUNT_CUSTOMER_TO_PARTNER,
  UPDATE_WORKING_TIME, NEW_PARTNER_CANCEL_NOTIFY_ZALO,
  PARTNER_CANCEL_NOTIFY_EMAIL,
  NEW_APPOINTMENTS_NOTIFY_EMAIL,
  NEW_APPOINTMENTS_NOTIFY_ZALO,
  NEW_PARTNER_NOTIFY_EMAIL,
  NEW_PARTNER_NOTIFY_ZALO,
  WH_ERROR_OF_PHARMACY,
  NEW_DELIVERY_COUPON_NOTIFY_ZALO,
  REMINDER_TO_RETURN_THE_DEVICE_NOTIFY_EMAIL,
  REMINDER_TO_RETURN_THE_DEVICE_NOTIFY_ZALO,
  jobEmail,
  resetPassword_ZALO,
  resetPassword_EMAIL
} from '~/constants/defaultValue';


import { useMatchPolicy, useNotifyAccounts, useUpdateNotifyAccounts } from '~/hooks';
import { union } from 'lodash';
import { Permissions, useTags } from '~/hooks/utils';
import POLICIES from '~/constants/policy';
import TabBranch from '../Common/TabBranch';
import LoadingWhBill from '../WhBill/LoadingWhBill';
const FormItem = Form.Item;

const TagFormFragment = ({ form, tagInputName, defaultValue }) => {
  const { canCreate, canDelete } = Permissions("NOTIFICATIONBOTMANAGER")
  const [tags, setTags] = useTags(defaultValue);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, []);
  useEffect(() => {
      setTags(defaultValue);
  }, [defaultValue]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
    form.setFieldsValue({
      [tagInputName]: newTags
    });
  };

  const showInput = () => {
    setInputVisible(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
      form.setFieldsValue({
        [tagInputName]: newTags
      });
    }

    setInputVisible(false);
    setInputValue('');
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable={canDelete}
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block'
        }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(forMap);
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: 'from',
              duration: 100
            }}
            onEnd={(e) => {
              if (e.type === 'appear' || e.type === 'enter') {
                e.target.style = 'display: inline-block';
              }
            }}
            leave={{
              opacity: 0,
              width: 0,
              scale: 0,
              duration: 200
            }}
            appear={false}
          >
            {tagChild}
          </TweenOneGroup>
        </div>
        {inputVisible && (
          <Input
            ref={inputRef}
            autoFocus
            type="text"
            size="small"
            style={{
              minWidth: 50,
              maxWidth: 200
            }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        )}
        {canCreate ? !inputVisible && (
          <Tag
            onClick={() => {
              showInput();
            }}
            style={{ cursor: 'pointer' }}
            className="site-tag-plus"
          >
            <PlusOutlined />{' '}
            <span style={{ marginLeft: '5px', display: 'inline-block' }}>
              Thêm
            </span>
          </Tag>
        ) : null}
      </div>
    </>
  );
};
const ZaloBotManagement = ({ form, data }) => {
  return (
    <>
      <FormItem tooltip="Bao gồm: Tạo đơn hàng,tạo đơn hàng tạm, sao chép đơn hàng tạm, chuyển đổi đơn hàng tạm" label="Đơn hàng mới" name={NEW_ORDER_NOTIFY_ZALO}>
        <TagFormFragment
          form={form}
          defaultValue={data[NEW_ORDER_NOTIFY_ZALO]}
          tagInputName={NEW_ORDER_NOTIFY_ZALO}
        />
      </FormItem>

      <FormItem label="Đối tác mới" name={NEW_PARTNER_NOTIFY_ZALO}>
        <TagFormFragment
          form={form}
          defaultValue={data[NEW_PARTNER_NOTIFY_ZALO]}
          tagInputName={NEW_PARTNER_NOTIFY_ZALO}
        />
      </FormItem>

      <FormItem
        label="Partner được nhận lịch hẹn"
        name={ASSIGNED_PARTNER_NOTIFY_ZALO}
      >
        <TagFormFragment
          form={form}
          defaultValue={data[ASSIGNED_PARTNER_NOTIFY_ZALO]}
          tagInputName={ASSIGNED_PARTNER_NOTIFY_ZALO}
        />
      </FormItem>

      <FormItem label="Partner CheckIn" name={PARTNER_CHECKIN_NOTIFY_ZALO}>
        <TagFormFragment
          form={form}
          defaultValue={data[PARTNER_CHECKIN_NOTIFY_ZALO]}
          tagInputName={PARTNER_CHECKIN_NOTIFY_ZALO}
        />
      </FormItem>

      <FormItem label="Partner CheckOut" name={PARTNER_CHECKOUT_NOTIFY_ZALO}>
        <TagFormFragment
          form={form}
          defaultValue={data[PARTNER_CHECKOUT_NOTIFY_ZALO]}
          tagInputName={PARTNER_CHECKOUT_NOTIFY_ZALO}
        />
      </FormItem>

      <FormItem
        label="Prepay Confirmed"
        name={PREPAY_CONFIRMED_ORDER_NOTIFY_ZALO}
      >
        <TagFormFragment
          form={form}
          defaultValue={data[PREPAY_CONFIRMED_ORDER_NOTIFY_ZALO]}
          tagInputName={PREPAY_CONFIRMED_ORDER_NOTIFY_ZALO}
        />
      </FormItem>

      <FormItem
        label="Lịch hẹn mới"
        name={NEW_APPOINTMENTS_NOTIFY_ZALO}>
        <TagFormFragment form={form} defaultValue={data[NEW_APPOINTMENTS_NOTIFY_ZALO]} tagInputName={NEW_APPOINTMENTS_NOTIFY_ZALO} />
      </FormItem>

      <FormItem
        label="Yêu cầu huỷ cuộc hẹn"
        name={NEW_PARTNER_CANCEL_NOTIFY_ZALO}>
        <TagFormFragment form={form} defaultValue={data[NEW_PARTNER_CANCEL_NOTIFY_ZALO]} tagInputName={NEW_PARTNER_CANCEL_NOTIFY_ZALO} />
      </FormItem>
      <FormItem
        label="Luân chuyển thiết bị"
        name={NEW_DELIVERY_COUPON_NOTIFY_ZALO}
      >
        <TagFormFragment
          form={form}
          defaultValue={data[NEW_DELIVERY_COUPON_NOTIFY_ZALO]}
          tagInputName={NEW_DELIVERY_COUPON_NOTIFY_ZALO}
        />
      </FormItem>
      <FormItem
        label="Nhắc nhở trả thiết bị"
        name={REMINDER_TO_RETURN_THE_DEVICE_NOTIFY_ZALO}
      >
        <TagFormFragment
          form={form}
          defaultValue={data[REMINDER_TO_RETURN_THE_DEVICE_NOTIFY_ZALO]}
          tagInputName={REMINDER_TO_RETURN_THE_DEVICE_NOTIFY_ZALO}
        />
      </FormItem>

      <FormItem
        label="Yêu cầu đổi mật khẩu"
        name={resetPassword_ZALO}
      >
        <TagFormFragment
          form={form}
          defaultValue={data[resetPassword_ZALO]}
          tagInputName={resetPassword_ZALO}
        />
      </FormItem>
    </>

  );
}
const EmailBotManagement = ({form, data}) => {
  return (
    <>
      <FormItem tooltip="Bao gồm: Tạo đơn hàng,tạo đơn hàng tạm, sao chép đơn hàng tạm, chuyển đổi đơn hàng tạm" label={<p>Đơn hàng mới</p>} name={NEW_ORDER_NOTIFY_EMAIL}>
        <TagFormFragment
          form={form}
          defaultValue={data[NEW_ORDER_NOTIFY_EMAIL]}
          tagInputName={NEW_ORDER_NOTIFY_EMAIL}
        />
      </FormItem>
      <FormItem label={<p>Đối tác mới</p>} name={NEW_PARTNER_NOTIFY_EMAIL}>
        <TagFormFragment
          form={form}
          defaultValue={data[NEW_PARTNER_NOTIFY_EMAIL]}
          tagInputName={NEW_PARTNER_NOTIFY_EMAIL}
        />
      </FormItem>

      <FormItem
        label={<p>Cập nhật hoạt động referral</p>}
        name={NEW_DEV_CONFIG_CAMPAGIN_EMAIL}
      >
        <TagFormFragment
          form={form}
          defaultValue={data[NEW_DEV_CONFIG_CAMPAGIN_EMAIL]}
          tagInputName={NEW_DEV_CONFIG_CAMPAGIN_EMAIL}
        />
      </FormItem>

      <FormItem

        label={<p>Nhận yêu cầu rút tiền</p>}
        name={NEW_REQUEST_VOUCHER}>

        <TagFormFragment form={form} defaultValue={data[NEW_REQUEST_VOUCHER]} tagInputName={NEW_REQUEST_VOUCHER} />
      </FormItem>

      <FormItem
        
        label={<p>Yêu cầu huỷ cuộc hẹn</p>}
        name={PARTNER_CANCEL_NOTIFY_EMAIL}>

        <TagFormFragment form={form} defaultValue={data[PARTNER_CANCEL_NOTIFY_EMAIL]} tagInputName={PARTNER_CANCEL_NOTIFY_EMAIL} />
      </FormItem>

      <FormItem
        
        label={<p>Lịch hẹn mới</p>}
        name={NEW_APPOINTMENTS_NOTIFY_EMAIL}>

        <TagFormFragment form={form} defaultValue={data[NEW_APPOINTMENTS_NOTIFY_EMAIL]} tagInputName={NEW_APPOINTMENTS_NOTIFY_EMAIL} />
      </FormItem>
      
      <FormItem

        label={<p>Nhận yêu cầu chuyển đổi tài khoản</p>}
        name={REQUEST_CONVERT_ACCOUNT_CUSTOMER_TO_PARTNER}>

        <TagFormFragment form={form} defaultValue={data[REQUEST_CONVERT_ACCOUNT_CUSTOMER_TO_PARTNER]} tagInputName={REQUEST_CONVERT_ACCOUNT_CUSTOMER_TO_PARTNER} />
      </FormItem>

      <FormItem

        label={<p>Đối tác thay đổi khung giờ</p>}
        name={UPDATE_WORKING_TIME}>

        <TagFormFragment form={form} defaultValue={data[UPDATE_WORKING_TIME]} tagInputName={UPDATE_WORKING_TIME} />
      </FormItem>

      <FormItem
        tooltip="Báo lỗi khi tạo, cập nhật phiếu đặt hàng, bán hàng ở phía kho Clinic"
        label={<p>Hệ thống báo lỗi ở kho</p>}
        name={WH_ERROR_OF_PHARMACY}>

        <TagFormFragment form={form} defaultValue={data[WH_ERROR_OF_PHARMACY]} tagInputName={WH_ERROR_OF_PHARMACY} />
      </FormItem>

      <FormItem
        label={<p>Nhắc nhở trả thiết bị</p>}
        name={REMINDER_TO_RETURN_THE_DEVICE_NOTIFY_EMAIL}>
        <TagFormFragment form={form} defaultValue={data[REMINDER_TO_RETURN_THE_DEVICE_NOTIFY_EMAIL]} tagInputName={REMINDER_TO_RETURN_THE_DEVICE_NOTIFY_EMAIL} />
      </FormItem>
      
      <FormItem
        label={<p>Nhận hồ sơ tuyển dụng</p>}
        name={jobEmail}>
        <TagFormFragment form={form} defaultValue={data[jobEmail]} tagInputName={jobEmail} />
      </FormItem>

      <FormItem
        label={<p>Yêu cầu đổi mật khẩu</p>}
        name={resetPassword_EMAIL}>
        <TagFormFragment form={form} defaultValue={data[resetPassword_EMAIL]} tagInputName={resetPassword_EMAIL} />
      </FormItem>

    </>
  );
};
export default function NotificationBotManagement() {
  const { canUpdate } = Permissions("NOTIFICATIONBOTMANAGER")

  const [form] = Form.useForm();
  const [selected, setSelected] = useState('emailOption');
  const [data, isGetLoading] = useNotifyAccounts();
  const [isUpdateLoading, updateNotifyAccounts] = useUpdateNotifyAccounts();
  let NotifyManageOptions = {
    emailOption: <EmailBotManagement data={data} form={form} />,
    zaloOption: <ZaloBotManagement data={data} form={form} />
  };

  const onSubmit = (values) => {
    updateNotifyAccounts(values);
  };
  const handleChange = (value) => {
    setSelected(value);
  };
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
      <TabBranch useBoxShadow={false}>
      <LoadingWhBill open={isGetLoading}/>
      <Breadcrumb title="Quản lý thông báo tự động" />

<Row justify="space-between">
  <Col>
    {selected === 'emailOption' ? (
      <h1>Quản lý tài khoản Email nhận thông báo</h1>
    ) : (
      <h1>
        Quản lý tài khoản Zalo nhận thông báo (Nhập user id của zalo)
      </h1>
    )}
  </Col>
  <Col>
    <Select
      value={selected}
      style={{ width: 120 }}
      onChange={handleChange}
    >
      <Option value="emailOption">Email</Option>
      <Option value="zaloOption">Zalo</Option>
    </Select>
  </Col>
</Row>

<Form
  form={form}
  autoComplete="off"
  onFinish={onSubmit}
  scrollToFirstError
  requiredMark={false}
  // dataSource={serviceBookingInstructions}
  labelCol={{ sm: 24, md: 5, lg: 4 }}
  wrapperCol={{ sm: 24, md: 19, lg: 20 }}
>
  {selected === 'emailOption' ? (
    <EmailBotManagement data={data} form={form} />
  ) : (
    <ZaloBotManagement data={data} form={form} />
  )}

  {canUpdate && <Button
    onClick={() => {
      form.submit();
    }}
  >
    {isUpdateLoading && <Spin size="large" />}Cập nhật
  </Button>}
</Form>
      </TabBranch>
      </div>
    </div>
  );
}
