export const GET_WH_WORK_NORMS_REQUEST = 'GET_WH_WORK_NORMS_REQUEST';
export const GET_WH_WORK_NORMS_SUCCESS = 'GET_WH_WORK_NORMS_SUCCESS';
export const GET_WH_WORK_NORMS_FAILED = 'GET_WH_WORK_NORMS_FAILED';

export const GET_EMPLOYEE_GROUP_WH_WORK_NORMS_REQUEST = 'GET_EMPLOYEE_GROUP_WH_WORK_NORMS_REQUEST';
export const GET_EMPLOYEE_GROUP_WH_WORK_NORMS_SUCCESS = 'GET_EMPLOYEE_GROUP_WH_WORK_NORMS_SUCCESS';
export const GET_EMPLOYEE_GROUP_WH_WORK_NORMS_FAILED = 'GET_EMPLOYEE_GROUP_WH_WORK_NORMS_FAILED';

export const GET_WH_WORK_NORM_REQUEST = 'GET_WH_WORK_NORM_REQUEST';
export const GET_WH_WORK_NORM_SUCCESS = 'GET_WH_WORK_NORM_SUCCESS';
export const GET_WH_WORK_NORM_FAILED = 'GET_WH_WORK_NORM_FAILED';

export const CREATE_WH_WORK_NORM_REQUEST = 'CREATE_WH_WORK_NORM_REQUEST';
export const CREATE_WH_WORK_NORM_SUCCESS = 'CREATE_WH_WORK_NORM_SUCCESS';
export const CREATE_WH_WORK_NORM_FAILED = 'CREATE_WH_WORK_NORM_FAILED';

export const UPDATE_WH_WORK_NORM_REQUEST = 'UPDATE_WH_WORK_NORM_REQUEST';
export const UPDATE_WH_WORK_NORM_SUCCESS = 'UPDATE_WH_WORK_NORM_SUCCESS';
export const UPDATE_WH_WORK_NORM_FAILED = 'UPDATE_WH_WORK_NORM_FAILED';

export const DELETE_WH_WORK_NORM_REQUEST = 'DELETE_WH_WORK_NORM_REQUEST';
export const DELETE_WH_WORK_NORM_SUCCESS = 'DELETE_WH_WORK_NORM_SUCCESS';
export const DELETE_WH_WORK_NORM_FAILED = 'DELETE_WH_WORK_NORM_FAILED';

export const RESET_WH_WORK_NORM_STATE = 'RESET_WH_WORK_NORM_STATE';
