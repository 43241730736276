import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getImportWhCustomersExcel({ payload: query }) {
  try {
    const data = yield call(Api.importWhCustomerExcel.getAll, query);
    yield put({ type: Types.GET_IMPORT_WH_CUSTOMERS_EXCEL_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_IMPORT_WH_CUSTOMERS_EXCEL_FAILED,
      payload: error.message
    });
  }
}

function* getImportWhCustomerExcel() {
  try {
    const importWhCustomerExcel = yield call(Api.importWhCustomerExcel.getAll);
    yield put({ type: Types.GET_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS, payload: importWhCustomerExcel });
  } catch (error) {
    yield put({ type: Types.GET_IMPORT_WH_CUSTOMER_EXCEL_FAILED, payload: error.message });
  }
}

function* createImportWhCustomerExcel(action) {
  try {
    console.log("wow...");
    const data = yield call(Api.importWhCustomerExcel.create, action.payload);
    yield put({ type: Types.CREATE_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS, payload: data });
  } catch (error) {
    console.log('error create...', error)
    yield put({
      type: Types.CREATE_IMPORT_WH_CUSTOMER_EXCEL_FAILED,
      payload: error.message
    });
  }
}

function* updateImportWhCustomerExcel(action) {
  try {
    console.log("huhu...");
    const data = yield call(Api.importWhCustomerExcel.update, action.payload);
    yield put({ type: Types.UPDATE_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS, payload: data });
  } catch (error) {
    console.log('error', error)
    yield put({
      type: Types.UPDATE_IMPORT_WH_CUSTOMER_EXCEL_FAILED,
      payload: error.message
    });
  }
}

function* deleteImportWhCustomerExcel({ payload }) {
  try {
    yield call(Api.importWhCustomerExcel.delete, payload);
    yield put({ type: Types.DELETE_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_IMPORT_WH_CUSTOMER_EXCEL_FAILED,
      payload: error.message
    });
  }
}

export default function* importWhCustomerExcel() {
  yield takeLatest(Types.GET_IMPORT_WH_CUSTOMERS_EXCEL_REQUEST, getImportWhCustomersExcel);
  yield takeLatest(Types.GET_IMPORT_WH_CUSTOMER_EXCEL_REQUEST, getImportWhCustomerExcel);
  yield takeLatest(Types.DELETE_IMPORT_WH_CUSTOMER_EXCEL_REQUEST, deleteImportWhCustomerExcel);
  yield takeLatest(Types.CREATE_IMPORT_WH_CUSTOMER_EXCEL_REQUEST, createImportWhCustomerExcel);
  yield takeLatest(Types.UPDATE_IMPORT_WH_CUSTOMER_EXCEL_REQUEST, updateImportWhCustomerExcel);
}
