import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhWorkNorms,
  createWhWorkNorm,
  deleteWhWorkNorm,
  getWhWorkNorm,
  resetWhWorkNormState,
  updateWhWorkNorm,
  getAllEmployyGroup
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { get } from 'lodash';
import { getExistProp } from '~/utils/helper';
import api from '~/api';

const getSelector = key => state => state.whWorkNorm[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const isSubmitLoadingEmployySelector = getSelector('isLoadingListEmployee');
const listEmployeeSelector = getSelector('listEmployee');
const getListEmployeeFailedFailedSelector = getSelector('getListEmployeeFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useWhWorkNormPaging = () => useSelector(pagingSelector);

export const useWhWorkNormQueryParams = (defaultLimit = 10) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || defaultLimit;
  const keyword = query.get('keyword');
  const page = query.get('page') || 1;


  const deleteWhServiceSuccess = useSelector(deleteSuccessSelector);
  const updateWhServiceSuccess = useSelector(updateSuccessSelector);
  const createWhServiceSuccess = useSelector(createSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword
    };

    return [queryParams];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteWhServiceSuccess,createWhServiceSuccess,updateWhServiceSuccess]);
};
export const useUpdateWhWorkNormParams = (query) => {
  const history = useHistory();
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/wh-work-norm',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [{onParamChange }];
};
export const useGetWhWorkNorms = query => {
  return useFetchByParam({
    action: getWhWorkNorms,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  })
};
export const useGetEmployeeGroupWhWorkNorms = () => {
  return useFetchByParam({
    action: getAllEmployyGroup,
    loadingSelector:isSubmitLoadingEmployySelector,
    dataSelector: listEmployeeSelector,
    failedSelector: getListEmployeeFailedFailedSelector,
  })
};
export const useGetEmployeeGroupWhWorkNormsFetch = () => {
  const [data,setData] = useState([])
  const [loading,setLoading] = useState(false)
  useEffect(() => {
    const fetch = async() => {
      setLoading(true)
      const res = await api.staffGroup.getAllEmployyGroup()
      setData(res)
      setLoading(false)
    }
    fetch()
  },[])
  return [data,loading]
}
export const useCreateWhWorkNorm = (callback) => {
  useSuccess(
    createSuccessSelector,'Thêm mới định mức thành công',callback
  );
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhWorkNorm
  });
};

export const useUpdateWhWorkNorm = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật định mức thành công',callback);
  useFailed(updateFailedSelector, 'Cập nhật định mức thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhWorkNorm
  });
};


export const useWhWorkNorm = id => {
  return useFetchByParam({
    action: getWhWorkNorm,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitWhWorkNorm = whWorkNorm =>{
    return useMemo(() => {
      if (!whWorkNorm) {
        return {
          certification: [
            {
              name: '',
              certifiedBy: '',
              certifiedAt: moment()
            }
          ],
          employeeHistory: [
            {
              location: '',
              role: '',
              startTime: moment(),
              endTime: moment()
            }
          ]
        };
      }
  
      const initValues = {
        ...whWorkNorm,
        startTime: moment(whWorkNorm.startTime, "HH:mm"),
        endTime: moment(whWorkNorm.endTime, "HH:mm"),
        certification: Array.isArray(whWorkNorm?.certification) && whWorkNorm?.certification.map(cer => ({
          ...cer,
          certifiedAt: moment(cer.certifiedAt)
        })),
      };
  
      return initValues;
  }, [whWorkNorm]);
};

export const useResetWhWorkNorm = () => {
  useResetState(resetWhWorkNormState);
};
