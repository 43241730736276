import requester from './requester';

const positionJob = {
  getAll: (query) => requester.get('/Job-vacancy', query),
  getById: (id) => requester.get(`/Job-vacancy/${id}`),
  create: (payload) => requester.post('/Job-vacancy', payload),
  update: (payload) => requester.put(`/Job-vacancy/${payload._id}`, payload),
  delete: (id) => requester.delete(`/Job-vacancy/${id}`)
};

export default positionJob;
