import { useMemo, useState, useRef, useEffect } from 'react';
import {
  useBranchIdSessionStorage,
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhPartnerImport,
  getWhPartnersImport,
  createWhPartnerImport,
  updateWhPartnerImport,
  deleteWhPartnerImport,
  convertWhPartnerImport,
  resetWhPartnerImportState
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import moment from 'moment';
import { isArray, isString, toString } from 'lodash';
// import { fromJSON } from '~/components/UserEmployee/parser';

const getSelector = (key) => (state) => state.whPartnerImport[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const convertSuccessSelector = getSelector('convertSuccess');
const convertFailedSelector = getSelector('convertFailed');

const pagingSelector = getSelector('paging');

export const useWhPartnerImportPaging = () => useSelector(pagingSelector);

export const useUpdatePartnerImportParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);
  const onParamChange = (param) => {

    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

     for (const key in param) {
        param[key]=  isString(param[key]) ? param[key].trim() : param[key]
     }
    history.push({
      pathname: '/customer-partner',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useWhPartnersImportQueryParams = (param) => {
  const [branchId] = useBranchIdSessionStorage();
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const page = query.get('page') || 1;
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const search = query.get(param)
  const status = 'partner'
  const startTime = query.get('startTime');
  const endTime = query.get('endTime');

  // const [page, setPage] = useState(query.get('page') || 1);
  // const onTableChange = ({ current }) => page

  const deleteWhPartnerImportSuccess = useSelector(deleteSuccessSelector);
  const convertWhPartnerImportSuccess = useSelector(convertSuccessSelector);
  const createWhPartnerImportSuccess = useSelector(createSuccessSelector);
  const updateWhPartnerImportSuccess = useSelector(updateSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }
  useEffect(() => {}, [keyword]);
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      startTime,
      endTime,
      [param]: search,
      status,
      branchId,
    };

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    // keyword,
    deleteWhPartnerImportSuccess,
    startTime,
    endTime,
    search,
    keyword,
    convertWhPartnerImportSuccess,
    createWhPartnerImportSuccess,
    updateWhPartnerImportSuccess,
    branchId,
  ]);
};

export const useWhPartnersImport = (query) => {
  return useFetchByParam({
    action: getWhPartnersImport,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateWhPartnerImport = (callback) => {
  useSuccess(createSuccessSelector, 'Thêm mới khách hàng thành công', callback);
  useFailed(createFailedSelector, 'Thêm mới khách hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhPartnerImport
  });
};

//Import Excel
export const useWhPartnerImportExcel = () => {
  const history = useHistory();
  const callback = () => history.push('/customer-partner');
  useSuccess(createSuccessSelector, 'Thêm mới khách hàng thành công', callback);
  useFailed(createFailedSelector, 'Thêm mới khách hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhPartnerImport
  });
};

export const useUpdateWhPartnerImport = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật khách hàng thành công',callback);
  useFailed(updateFailedSelector, 'Cập nhật khách hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhPartnerImport
  });
};

export const useDeleteWhPartnerImport = () => {
  useSuccess(deleteSuccessSelector, 'Xoá khách hàng thành công');
  useFailed(deleteFailedSelector, 'Xoá khách hàng thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhPartnerImport
  });
};

export const useConvertWhPartnerImport = () => {
  useSuccess(convertSuccessSelector, 'Convert khách hàng thành công');
  useFailed(convertFailedSelector );

  return useSubmit({
    loadingSelector,
    action: convertWhPartnerImport
  });
};

export const useWhPartnerImport = (id) => {
  return useFetchByParam({
    action: getWhPartnerImport,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

// export const useInitWhPartnerImport = whPartnerImport =>
//   useMemo(() => {
//   if (!whPartnerImport ) {
//       return {};
//     }
//     // whPartnerImport:'whPartnerImport.data'
//     const initValues = {...whPartnerImport}
//     console.log(initValues,"initValues")
//     return initValues.data;
//   }, [whPartnerImport]);
export const useInitWhPartnerImport = whPartnerImport =>
  useMemo(() => {
  if (!whPartnerImport ) {
      return {};
  }
    const initValues = whPartnerImport?.data
    return initValues
  }, [whPartnerImport]);
export const useResetWhPartnerImport = () => {
  useResetState(resetWhPartnerImportState);
};
