import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhBillItems,
  createWhBillItem,
  deleteWhBillItem,
  getWhBillItem,
  resetWhBillItemState,
  updateWhBillItem,
  customerCancelWhBillItem,
  updateWhAppointments,
  createCancelAppointment,
  resetCreateCancel,
  customerCompletedWhBillItem,
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const getSelector = key => state => state.whBillItem[key];

const loadingSelector = getSelector('isLoading');
const whBillItemsSelector = getSelector('whBillItems');
const getWhBillItemsFailedSelector = getSelector('getWhBillItemsFailed');

const isGetWhBillItemLoadingSelector = getSelector('isGetWhBillItemLoading');
const whBillItemSelector = getSelector('whBillItem');
const getWhBillItemFailedSelector = getSelector('getWhBillItemFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const isCustomerCancelLoadingSelector = getSelector('isCustomerCancelLoading');
const customerCancelSuccessSelector = getSelector('customerCancelSuccess');
const customerCancelFailedSelector = getSelector('customerCancelFailed');

const isCustomerCompletedLoadingSelector = getSelector('isCustomerCompletedLoading');
const customerCompletedSuccessSelector = getSelector('customerCompletedSuccess');
const customerCompletedFailedSelector = getSelector('customerCompletedFailed');

const isUpdateWhAppointmentsLoadingSelector = getSelector('isUpdateWhAppointmentsLoading');
const updateWhAppointmentsSuccessSelector = getSelector('updateWhAppointmentsSuccess');
const updateWhAppointmentsFailedSelector = getSelector('updateWhAppointmentsFailed');

const isCancelWhAppointmentsLoadingSelector = getSelector('isCancelWhAppointmentsLoading');
const cancelWhAppointmentsSuccessSelector = getSelector('cancelWhAppointmentsSuccess');
const cancelWhAppointmentsFailedSelector = getSelector('cancelWhAppointmentsFailed');
const pagingSelector = getSelector('paging');

export const useWhBillItemPaging = () => useSelector(pagingSelector);

export const useWhBillItemQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteWhBillItemSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteWhBillItemSuccess]);
};

export const useWhBillItems = query => {
  return useFetchByParam({
    action: getWhBillItems,
    loadingSelector,
    dataSelector: whBillItemsSelector,
    failedSelector: getWhBillItemsFailedSelector,
    param: query
  });
};

export const useCreateWhBillItem = () => {
  const history = useHistory();
  const callback = () => history.push('/wh-bill-item');

  useSuccess(createSuccessSelector, 'Tạo mới món hàng thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới món hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhBillItem
  });
};

export const useUpdateWhBillItem = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật món hàng thành công');
  useFailed(updateFailedSelector, 'Cập nhật món hàng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhBillItem
  });
};

export const useCustomerCancelWhBillItem = () => {
  useSuccess(customerCancelSuccessSelector, 'Chấm dứt dịch vụ thành công');
  useFailed(customerCancelFailedSelector, 'Chấm dứt dịch vụ thất bại');

  return useSubmit({
    loadingSelector: isCustomerCancelLoadingSelector,
    action: customerCancelWhBillItem
  });
};

export const useCustomerCompletedWhBillItem = () => {
  useSuccess(customerCompletedSuccessSelector, 'Hoàn thành dịch vụ thành công');
  console.log(customerCompletedFailedSelector, 'customerCompletedFailedSelector');
  useFailed(customerCompletedFailedSelector, customerCompletedFailedSelector?.response?.data?.message || 'Hoàn chỉ dịch vụ thể bắt đầu');
  return useSubmit({
    loadingSelector: isCustomerCompletedLoadingSelector,
    action: customerCompletedWhBillItem
  });
}
export const useUpdateWhAppointments = (callback) => {
  useSuccess(updateWhAppointmentsSuccessSelector, 'Cập nhật lịch hẹn thành công',callback);
  useFailed(updateWhAppointmentsFailedSelector, useSelector(updateWhAppointmentsFailedSelector)?.response?.data?.message || 'Cập nhật lịch hẹn thất bại');

  return useSubmit({
    loadingSelector: isUpdateWhAppointmentsLoadingSelector,
    action: updateWhAppointments
  });
};

export const useUpdateWhAppointmentsSuccessSelector = () => {
  const updateWhAppointmentSuccess = useSelector(updateWhAppointmentsSuccessSelector);
  return updateWhAppointmentSuccess;
}

export const useCustomerCancelWhBillItemSuccessSelector = () => {
  const customerCancelWhBillItemSuccess = useSelector(customerCancelSuccessSelector);
  return customerCancelWhBillItemSuccess;
}

export const useDeleteWhBillItem = () => {
  useSuccess(deleteSuccessSelector, 'Xoá món hàng thành công');
  useFailed(deleteFailedSelector, 'Xoá món hàng thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhBillItem
  });
};

export const useWhBillItem = id => {
  return useFetchByParam({
    action: getWhBillItem,
    loadingSelector: isGetWhBillItemLoadingSelector,
    dataSelector: whBillItemSelector,
    failedSelector: getWhBillItemFailedSelector,
    param: id
  });
};

export const useInitWhBillItem = whBillItem => {
  return useMemo(() => {
    if (!whBillItem) {
      return {
      };
    }

    const initValues = {
      ...whBillItem,
    };

    return initValues;
  }, [whBillItem]);
};

export const useResetWhBillItem = () => {
  useResetState(resetWhBillItemState);
};

export const useCreateCancelAppointment = (callback) => {
  useSuccess(cancelWhAppointmentsSuccessSelector, 'Tạo Yêu cầu huỷ hẹn thành công',callback);
  useFailed(cancelWhAppointmentsFailedSelector, 'Tạo Yêu cầu huỷ hẹn thất bại');

  return useSubmit({
    loadingSelector: isCancelWhAppointmentsLoadingSelector,
    action: createCancelAppointment
  });
};
export const useResetCreateCancel = () => {
  useResetState(resetCreateCancel);
};
