import React, { useState, memo, useEffect, useCallback, useMemo } from 'react';
import {useHistory} from "react-router-dom"
import {
  Form,
  Select,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Skeleton,
  Cascader
} from 'antd';
import { filterAcrossAccents } from '~/hooks/utils';
import {
  useAppointment,
  useInitAppointment,
  useUpdateAppointment,
  useCreateAppointment,
  useResetAppointment
} from '~/hooks/appointment';
import moment from 'moment';
import { useHospitals, useHospitalAvailableTimes } from '~/hooks/hospital';
import {
  useBranch,
  useCities,
  useDistricts,
  useMatchPolicy,
  useProfile,
  useSelectBranch,
  useSpecialityOptionsByBranchId,
  useWhCustomersManagement
} from '~/hooks';
import { removeAccents } from '~/hooks/utils';
import { get, groupBy, max, transform } from 'lodash';
import dayjs from 'dayjs'
import { WithOrPermission } from '../Common';
import POLICY from '~/constants/policy';

const { Option } = Select;
const FormItem = Form.Item;

const FORMAT_DATE = 'YYYYMMDD';

export const AppointmentIsPartnerForm = ({
  specialityOptions,
  appointmentId,
  onClose,
  initStartTime,
  handleOkUpdate,
  handleUpdate,
  isSubmitLoading,
  isReload
}) => {
  const [brandId] = useSelectBranch();
  const id = brandId;
  const [branchInfo, loading] = useBranch(id);
  const [form] = Form.useForm();
  const [profile] = useProfile();
  const specialityOptionsById = useSpecialityOptionsByBranchId(id);
  const [appointment, isLoading] = useAppointment(appointmentId);
  const [selectedDistrictCode, setSelectedDistrictCode] = useState(null);
  const [selectedCityCode, setSelectedCityCode] = useState(null);
  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(selectedCityCode);
  const [selectedSpecialityId, setSelectedSpecialityId] = useState({
    specialityId: null,
    cityId: null,
    districtId: null
  });
  const [hospitals, isHospitalLoading] = useHospitals(selectedSpecialityId);
  const [valueSelectPartner, setValueSelectPartner] = useState('clinic');
  const [selectedHospitalId, setSelectedHospitalId] = useState(null);
  const [defaultDistrictId, setDefaultDistrictCode] = useState(null);
  const [availableTimes, isAvailableTimesLoading] =
    useHospitalAvailableTimes(selectedHospitalId);
  const [specialityId, setSpecialityId] = useState('');
  const [optionsPharmacies, setOptionsPharmacies] = useState([]);
  const [valueTime, setValueTime] = useState(null);
  const [disabled, setDisabled] = useState(false)
  const [valueDate,setValueDate] = useState('')
  const canUpdate = useMatchPolicy(POLICY.UPDATE_APPOINTMENT);
  const canWrite = useMatchPolicy(POLICY.WRITE_APPOINTMENT);
  const [phoneNumberSearch, setPhoneNumberSearch] = useState(null)
  const [customerId, setCustomerId] = useState(null)
  const queryFeatch = useMemo(() => ({
    page: 1,
    limit: 10,
    keyword: phoneNumberSearch
  }), [phoneNumberSearch]);
  const [customer, isLoadingCustomer] = useWhCustomersManagement(queryFeatch);

  const [availableTimesInDay, setAvailableTimesInDay] = useState([]);

  useResetAppointment();

  const createSuccesCallback = () => {
    form.resetFields();
    onClose();
  };
  const [, handleCreate] = useCreateAppointment(createSuccesCallback);
  const checkValueSelecterPartner = valueSelectPartner === 'clinic'

  //Set availibleTimesInDay when exit hospital

  useEffect(() => {
    const getDateLocal  = form.getFieldValue('dateLocal')
    setAvailableTimesInDay(availableTimes[getDateLocal?.format(FORMAT_DATE)]);
  }, [appointmentId, availableTimes])

  
  // Find pharmacies exit specialityId
  useEffect(() => {
    const newPharmacies = (hospitals??[]).map((item) => {
      item.speciality.filter((itemss) => itemss._id === specialityId);
      return item;
    });
    const filterByCity = groupBy(newPharmacies, 'address.city');
    const setNameOptions = transform(
      filterByCity,
      (result, value, key) => {
        result.push({
          label: key.toString(),
          value: key.toString(),
          children: value
        });
      },
      []
    );

    const result = (setNameOptions??[]).map((item) => {
      const filterByDistrict = groupBy(item.children, 'address.district');

      const handleOptions = transform(
        filterByDistrict,
        (result, values, key) => {
          const newV = values.map((el)=>({value: el._id,label:el.name}))
          result.push({
            label: key.toString(),
            value: key.toString(),
            children: newV
          });
        },
        []
      );
      return {
        label: item.label.toString(),
        value: item.value.toString(),
        children: handleOptions
      };
    });
    setOptionsPharmacies(result);
  }, [hospitals, specialityId]);

  // Set value for form
  useEffect(() => {
    // Find name branch pharmacy
    if (!!appointment && appointmentId) {
      // In case the appointment is converted from worldhealth to a partner
      const setValueBranch = get(appointment, 'makeByWorldhealth') === 'WH' ? 'World Health' : (get(appointment, 'branch.name', '') ||  'Website' )
      const setPhoneNumber = get(appointment, 'makeByWorldhealth') === 'WH' ? '***' : get(appointment,'phoneNumber')
      let { hospitalId, branchId, phoneNumber, ...rest } = appointment;

      form.setFieldsValue({
        ...rest,
        phoneNumber:setPhoneNumber,
        branch:  setValueBranch,
        specialityId: appointment?.speciality?._id,
        date: moment(get(appointment, 'date')).format('HH:mm'),
        dateLocal: moment(get(appointment, 'date')),
        hospitalId:[
          get(appointment, 'hospital.address.city', ''),
          get(appointment, 'hospital.address.district', ''),
          get(appointment, 'hospital._id', '')
        ],
          address: {
            cityId: get(appointment, "branchId") ?  get(appointment, 'address.cityId') : get(appointment, 'hospital.address.city'),
            districtId: get(appointment, "branchId") ? get(appointment, 'address.districtId') : get(appointment, 'hospital.address.district')
          },
        });
      setSelectedCityCode(get(appointment, 'address.cityId'));
      setSelectedDistrictCode(get(appointment, 'address.districtId'));
      setSelectedHospitalId(get(appointment, 'hospital.id'))
    
      // Set disabled when appointment created from WorldHealth to Partner
      get(appointment, 'makeByWorldhealth') === 'WH' ? setDisabled(true) : setDisabled(false)
      return;
    } else {
      form.resetFields();
      setDisabled(false);
      form.setFieldsValue({
        type: valueSelectPartner
      });
      setSelectedCityCode(get(branchInfo, 'address.cityId'));
      switch (valueSelectPartner) {
        case 'clinic':
          form.setFieldsValue({
            address: {
            city: get(branchInfo, 'address.city'),
            cityId: get(branchInfo, 'address.cityId'),
            district: get(branchInfo, 'address.district'),
            districtId: get(branchInfo, 'address.districtId'),
            },
            hospitalId: [  get(branchInfo, 'address.city', ''),
            get(branchInfo, 'address.district', ''),
            get(branchInfo, '_id', 'id')
            ],
            dateLocal: null,
            date: null
          });
          setSelectedHospitalId(get(branchInfo, '_id'));
          setDefaultDistrictCode(get(branchInfo, 'address.districtId'))
          break;
        case 'worldhealth':
          form.setFieldsValue({
            address: {
              cityId: null,
              districtId: null
            },
            hospitalId: null,
            dateLocal: null,
            date: null
          });
          break;
        default:
      };
      return;
    }
  }, [appointment, appointmentId, valueSelectPartner,isReload]);

  useEffect(() => {
    if (!!appointment && appointmentId) {
      setSelectedSpecialityId({
        specialityId,
        cityId: get(appointment, 'address.cityId'),
        districtId: get(appointment, 'address.districtId')
      });
    }
  }, [appointment, appointmentId]);
;
  // Featch list customer of branch
  const filterOptionNumberSearch = useCallback(() => {
    let options = []
    const newOption = customer.map((item) => ({
      value: item?.phoneNumber.replace('+84', '0'),
      label: `${item?.phoneNumber.replace('+84', '0')} - ${item?.fullName }`,
      _id: item?._id || '',
      fullName: item.fullName
    }))
    const optionDefault = {
      value: phoneNumberSearch,
      label: phoneNumberSearch
    }
    const findCustomer = newOption.some((item) => item.phoneNumber !== phoneNumberSearch)
    !findCustomer ? options = [...newOption, optionDefault] : options = [...newOption]
    return options
  }, [customer, phoneNumberSearch])

  const onValuesChange = ({
    specialityId,
    hospitalId,
    dateLocal,
    cityId,
    address,
    districtId,
    type,
  }) => {
    // if (type) {
    //   switch (type) {
    //     case 'clinic':
    //       console.log('Fetch by branchId')
    //       break;
    //     case 'worldhealth':
    //       console.log('Fetch all services')
    //       break;
    //     default:
    //   }
    // }
    if (specialityId) {
      switch (valueSelectPartner) {
        case 'clinic':
      setSelectedHospitalId(id);
          break;
        case 'partner':
          setSelectedSpecialityId({
            specialityId,
            cityId: selectedCityCode,
            districtId: selectedDistrictCode
          }),
            form.setFieldsValue({
              hospitalId: null,
              dateLocal: null,
              date: null,
              name: null
            })
          break;
        default:
      }
    }
    if (hospitalId) {
      setSelectedHospitalId(hospitalId.at(-1));
      form.setFieldsValue({
        dateLocal: null,
        date: null
      });
    }

    if (dateLocal) {
      setAvailableTimesInDay(availableTimes[dateLocal.format(FORMAT_DATE)]);
      form.setFieldsValue({
        date: null
      });
    };
    if (address) {
      const cityIdChange = address.cityId
      if (cityIdChange !== undefined) { 
        form.setFieldsValue({
          address: {
            districtId: null
          }
        })
      }
    }
    if (date) {
      form.setFieldsValue({
        // time: valueTime
      })
    }
  };

  const renderButton = () => {
    if (appointmentId) {
      if (canUpdate) {
        return 'Cập nhật'
      } else {
        return ''
      }
    } else{
      if (canWrite) {
        return 'Tạo mới'
      } else {
        return ''
      }
    }
  }

  const onFinish = (values) => {
    const {address, hospitalId,dateLocal, ...rest } = values;
    const setHospitalId = checkValueSelecterPartner ? get(branchInfo, '_id') : (hospitalId || []).at(-1);
    const localTime = values.dateLocal.format('YYYY-MM-DD') + 'T' + values.date;
    const utcTime = moment(localTime, 'YYYY-MM-DDTHH:mm');
    if (appointmentId) {
      const { type, branch, ...rests } = { ...rest }
      const dataUpdate = {
          ...rests,
          address: {
            cityId: selectedCityCode,
            districtId: selectedDistrictCode
          },
          hospitalId: hospitalId.at(-1),
          branchId:brandId,
          date: utcTime,
          id: appointmentId,
          // customerId: customerId || null
      };
      handleUpdate({
        ...dataUpdate
      });
      handleOkUpdate(false);
      setPhoneNumberSearch(null)
    } else {
      const { address,dateLocal, ...rest } = values;
      const newData = {
        ...values,
        address: {
          ...address,
          districtId: valueSelectPartner === "clinic" ? defaultDistrictId : form.getFieldValue(["address", "districtId"])
        },
        hospitalId: setHospitalId,
        branchId: (+brandId),
        date: utcTime,
        type: valueSelectPartner,
        customerId: customerId || null
      }
      handleCreate({...newData});
      setPhoneNumberSearch(null)
    }
  };

  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  const displayRender = (labels) => labels[labels.length - 1];
  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      className="appointment-form"
      requiredMark={false}
      // initialValues={initAppointment}
      onValuesChange={onValuesChange}
    >
      <Row gutter={36}>
        {
           !appointmentId ?
        <Col span={12}>
          <FormItem label="Tạo lịch hẹn cho ai?" name={'type'}>
            {
              renderInput(
              <Select
                onSelect={(value) => setValueSelectPartner(value)}
                defaultValue={valueSelectPartner}
              >
                <Option value="worldhealth" key="worldhealth">
                  Worldhealth
                </Option>
                <Option value="clinic" key="clinic">
                  Phòng khám của bạn
                </Option>
              </Select>
            )}
          </FormItem>
            </Col>
             :   
             <Col span={24}>
               <FormItem label="Nguồn" name={'branch'}>
                 {renderInput(
                   <Input disabled={disabled ? disabled : true} style={{color:'#333'}} />
                 )}
               </FormItem>
               </Col>
             
        }
      </Row>
      <Row gutter={36}>
        <Col span={12}>
          <FormItem
            label="Tên Khách hàng"
            name={'customerName'}
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập tên!'
              }
            ]}
          >
            {renderInput(<Input disabled={disabled} />)}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label="Số điện thoại"
            name={'phoneNumber'}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[0-9]{10,11}$/),
                message: 'Xin vui lòng nhập đúng số điện thoại!'
              }
            ]}
          >
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
                <Select
                showSearch
                placeholder="Nhập số điện thoại để tìm"
                optionFilterProp="children"
                maxLength={11}
                  filterOption={(input, option) => {
                    return (option?.label ?? '').toLowerCase()?.includes(input.toLowerCase())
                  }
                }
                  options={filterOptionNumberSearch()}
                onSearch={(e) => {
                  setPhoneNumberSearch(e)
                }}
                  onChange={(e, el) => {
                    el.fullName ? form.setFieldsValue({ customerName: el.fullName}) : ()=>{},
                    setCustomerId(el._id)
                  }}
                value={phoneNumberSearch}
              />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={48} align="middle" justify="space-between">
        <Col span={12}>
          <FormItem
            label="Thành Phố/Tỉnh"
            name={['address', 'cityId']}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
            //   }
            // ]}
          >
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                onChange={(value) => setSelectedCityCode(value)}
                disabled={isCitiesLoading ||disabled}
                loading={isCitiesLoading}
                showSearch
                autoComplete="off"
                  filterOption={filterAcrossAccents}
              >
                {cities.map(({ code, name }) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={12}>
        <FormItem shouldUpdate={(pre,next) => get(pre,'address.cityId') !== get(next,'address.cityId')} noStyle >
        {() =>   <FormItem
            label="Quận/Huyện"
            name={['address', 'districtId']}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Xin vui lòng chọn Quận/Huyện!'
            //   }
            // ]}
          >
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                loading={isDistrictsLoading}
                disabled={!form.getFieldValue(['address', 'cityId']) || disabled}
                onChange={(value) => setSelectedDistrictCode(value)}
                showSearch
                autoComplete="off"
                filterOption={filterAcrossAccents}
              >
                {districts.map(({ code, name }) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={36}>
        <Col span={12}>
          <FormItem
            label="Dịch vụ"
            name="specialityId"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng chọn dịch vụ!'
              }
            ]}
          >
            {renderInput(
              <Select disabled={disabled} onChange={(value) => setSpecialityId(value)} showSearch>
                {valueSelectPartner === 'worldhealth'  ? specialityOptions :  specialityOptionsById  }
              </Select>
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem noStyle shouldUpdate>
            {() => (
              <FormItem
                label="Ngày"
                name="dateLocal"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn ngày!'
                  }
                ]}
                shouldUpdate
              >
                {renderInput(
                  <DatePicker
                    onChange={(val)=> setValueDate(val)}
                    allowClear={false}
                    format="DD-MM-YYYY"
                    span={12}
                    loading={isAvailableTimesLoading}
                    disabled={
                      disabled ? disabled :
                        (valueSelectPartner === 'worldhealth' ? false : ((!form.getFieldValue('specialityId'))))
                    }
                    disabledDate={(current) =>
                    {
                      return valueSelectPartner === 'worldhealth' ?( current < dayjs().endOf('day') && !availableTimes[current?.format(FORMAT_DATE)]) : ( current && !availableTimes[current?.format(FORMAT_DATE)])
                    }
                    }
                  />
                )}
              </FormItem>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={36}>
        <Col span={12}>
          <FormItem noStyle shouldUpdate>
            {() => (
              <FormItem
                label="Giờ"
                name="date"
                rules={[
                valueSelectPartner === 'worldhealth' ? 
                  {
                    required: true,
                    message: 'Xin vui lòng chọn giờ!'
                  } :
                  {
                    required: true,
                    max: 5,
                    min: 5,
                    pattern: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$|(0[0-9]|1[0-9]|2[0-3]):(.*)$/i,
                    message: 'Xin vui nhập đúng định dạng thời gian'
                  },
                ]}
              >
                {renderInput(
                  (valueSelectPartner === 'worldhealth' )
                    ?
                    <Input
                      maxLength={5} 
                      value={valueTime}
                      onChange={(e) => {
                        let date = e.target.value;
                        form.setFieldsValue({date: date.replace(/:/i,'').replace(/(\d{2})(\d{1,2})/i,'$1:$2')})
                        setValueTime(moment(date,"HHmm").format("HH:mm"))
                      }}
                    placeholder='Vui lòng nhập thời gian 00:00' />
                       :
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                    loading={isHospitalLoading}
                    disabled={disabled ? disabled : !form.getFieldValue('dateLocal') }
                  >
                    {availableTimesInDay &&
                      availableTimesInDay.map((time) => {
                        const currentDate = new Date();
                        const optionTime = new Date(valueDate?._d?.toDateString() + ' ' + time);
                        const isDisabled = optionTime < currentDate;
                         return (
                        <Option disabled = {isDisabled} value={time} key={time}>
                          {time}
                        </Option>
                      )})}
                  </Select>
                )}
              </FormItem>
            )}
          </FormItem>
        </Col>
        <Col span={12}>
            <FormItem noStyle shouldUpdate>
              {() =>
              (<FormItem
                label="Phòng khám"
                name="hospitalId"
                dependencies={optionsPharmacies}
                rules={
                  [
                    {
                      required: form.getFieldValue("type") === 'clicic' || valueSelectPartner === 'worldhealth'? false : true,
                      message: 'Xin vui lòng chọn phòng khám!'
                    }
                  ]}
              >
                {renderInput(
                  <Cascader
                    disabled={
                      true
                      // valueSelectPartner === 'clinic'  || !form.getFieldValue('specialityId')
                    }
                    options={optionsPharmacies}
                    expandTrigger="hover"
                    displayRender={displayRender}
                  />
                )}
              </FormItem>)
              }
            </FormItem>
          </Col>
      </Row>

      <Row gutter={36}>
        <Col span={12}>
          <FormItem label="Nguồn" name="source">
            {renderInput(
              <Select  disabled = {disabled}>
                <Option value="CALL" key="CALL">
                  Gọi điện
                </Option>
                <Option value="WEBSITE" key="WEBSITE">
                  Website
                </Option>
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem label="Trạng thái" name="status">
            {renderInput(
              <Select disabled = {disabled}>
                <Option value="CREATED" key="CREATED">
                  Đã tạo
                </Option>
                <Option value="CONFIRMED" key="CONFIRMED">
                  Đã xác nhận
                </Option>
                <Option value="CANCEL" key="CANCEL">
                  Đã huỷ
                </Option>
                <Option value="ARRIVED" key="ARRIVED">
                  Đã hoàn thành
                </Option>
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>


      <Row>
        <Col span={24}>
          <FormItem name="message" label="Lời nhắn">
            <Input.TextArea  disabled = {disabled} rows={3}></Input.TextArea>
          </FormItem>
        </Col>
      </Row>
      {!disabled ? 
      <Row justify="end">
        <Button style={{ marginRight: 20 }} onClick={onClose}>
          Huỷ
        </Button>
        <WithOrPermission permission={[ POLICY.WRITE_APPOINTMENT, POLICY.UPDATE_APPOINTMENT]}>
        <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {renderButton()}
            </Button>
          </WithOrPermission>
        </Row>
        : () => {}
      }
    </Form>
  );
};

export default memo(AppointmentIsPartnerForm);
