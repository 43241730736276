import produce from 'immer';
import getPaging from '~/utils/getPaging';
import * as Types from '../../constants/actionTypes';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  isLoadingListEmployee: null,
  listEmployee: [],
  getListEmployeeFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WH_WORK_NORMS_REQUEST:
      state.isLoading = true;
      state.list = [];
      state.getListFailed = null;
      return;

    case Types.GET_WH_WORK_NORMS_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_WH_WORK_NORMS_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      return;
    case Types.GET_EMPLOYEE_GROUP_WH_WORK_NORMS_REQUEST:
      state.isLoadingListEmployee = true;
      state.listEmployee = [];
      state.getListEmployeeFailed = null;
      return;

    case Types.GET_EMPLOYEE_GROUP_WH_WORK_NORMS_SUCCESS:
      state.isLoadingListEmployee = false;
      state.listEmployee = payload;
      return;

    case Types.GET_EMPLOYEE_GROUP_WH_WORK_NORMS_FAILED:
      state.isLoadingListEmployee = false;
      state.getListEmployeeFailed = null;
      return;

    case Types.GET_WH_WORK_NORM_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_WH_WORK_NORM_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_WH_WORK_NORM_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_WH_WORK_NORM_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_WH_WORK_NORM_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_WH_WORK_NORM_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_WH_WORK_NORM_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_WH_WORK_NORM_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      state.list = state.list.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload };
        }
        return item;
      });
      return;

    case Types.UPDATE_WH_WORK_NORM_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_WH_WORK_NORM_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_WH_WORK_NORM_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_WH_WORK_NORM_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_WH_WORK_NORM_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
