export const GET_TIPS_PARTNERS_REQUEST =
  'GET_TIPS_PARTNERS_REQUEST';
export const GET_TIPS_PARTNERS_SUCCESS =
  'GET_TIPS_PARTNERS_SUCCESS';
export const GET_TIPS_PARTNERS_FAILED = 'GET_TIPS_PARTNERS_FAILED';

export const GET_TIPS_PARTNER_REQUEST = 'GET_TIPS_PARTNER_REQUEST';
export const GET_TIPS_PARTNER_SUCCESS = 'GET_TIPS_PARTNER_SUCCESS';
export const GET_TIPS_PARTNER_FAILED = 'GET_TIPS_PARTNER_FAILED';

export const GET_LOGS_TIPS_PARTNER_REQUEST = 'GET_LOGS_TIPS_PARTNER_REQUEST';
export const GET_LOGS_TIPS_PARTNER_SUCCESS = 'GET_LOGS_TIPS_PARTNER_SUCCESS';
export const GET_LOGS_TIPS_PARTNER_FAILED = 'GET_LOGS_TIPS_PARTNER_FAILED';

export const CREATE_TIPS_PARTNER_REQUEST =
  'CREATE_TIPS_PARTNER_REQUEST';
export const CREATE_TIPS_PARTNER_SUCCESS =
  'CREATE_TIPS_PARTNER_SUCCESS';
export const CREATE_TIPS_PARTNER_FAILED =
  'CREATE_TIPS_PARTNER_FAILED';

export const UPDATE_TIPS_PARTNER_REQUEST =
  'UPDATE_TIPS_PARTNER_REQUEST';
export const UPDATE_TIPS_PARTNER_SUCCESS =
  'UPDATE_TIPS_PARTNER_SUCCESS';
export const UPDATE_TIPS_PARTNER_FAILED =
  'UPDATE_TIPS_PARTNER_FAILED';

export const DELETE_TIPS_PARTNER_REQUEST =
  'DELETE_TIPS_PARTNER_REQUEST';
export const DELETE_TIPS_PARTNER_SUCCESS =
  'DELETE_TIPS_PARTNER_SUCCESS';
export const DELETE_TIPS_PARTNER_FAILED =
  'DELETE_TIPS_PARTNER_FAILED';


export const RESET_TIPS_PARTNER_STATE = 'RESET_TIPS_PARTNER_STATE';
