import { Button, Col, Descriptions, Modal, Row, Skeleton } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
  COMPONENT_MODES,
  WH_PARTNER_WAY_WORKING,
  WH_PARTNER_WAY_WORKING_EN
} from '~/constants/defaultValue';
import { useGetRatingOnePartner, useWhPartner } from '~/hooks';
import { get, head } from 'lodash';
import AllTableFulltimeOrParttime from '../Workspace/WhPartner/AllTableFulltimeOrParttime';
import RatingExistData from '../Workspace/RatingExistData';
import { useLogsTipsPartners } from '~/hooks/tipsPartner';
import LogsTipsPartner from './LogsTipsPartner';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useReportKpIById } from '~/hooks/whReport';
import { getAllDateOfWeek } from '~/hooks/date';
export default function ModalDetailRevenue({
  // setInitDate,
  // setId,
  // setIsModalReVenueOpen,
  // isModalReVenueOpen,
  id,
  date,
  title,
  // partnerSelected,
  // setIsModalMonthOpen,
  // setDate,
  setTitle,
  // filterDateBy,
  optionFilterDate,
  typeFilter
}) {
  // console.log(date,'date');
  // const [month, setMonth] = useState(moment().format('YYYY-MM-DD'));
  // useEffect(() => {
  //   setTitle(`Tháng ${moment(month).format('MM')}`);
  //   setMonth(moment(date).format('YYYY-MM-DD'))
  // },[])
  // const setPreMonth = () => {
  //   setMonth(moment(month).subtract(1, 'months').format('YYYY-MM-DD'));
  // };
  // const setNextMonth = () => {
  //   setMonth(moment(month).add(1, 'months').format('YYYY-MM-DD'));
  // };
  // useEffect(() => {
  //   return () => {
  //     setTitle(`Tháng ${moment(month).format('MM')}`);
  //   setDate({
  //     startDate: moment(month).startOf('month').format('YYYY-MM-DD'),
  //     endDate: moment(month).endOf('month').format('YYYY-MM-DD')
  //   });
  //   }
  // }, [month]);
  // const [typeFilter,setTypeFilter] = useState(filterDateBy) 

  const [dateDetail,setDateDetail] = useState({
    startDate: moment(date.startDate).format('YYYY-MM-DD'),
    endDate: moment(date.endDate).format('YYYY-MM-DD')
  })
  const arrDate = getAllDateOfWeek()
  const [disabledPreTime,setDisabledPreTime] = useState(false)
  const [disabledNextTime,setDisabledNextTime] = useState(false)
  useEffect(() => {
    if(typeFilter === 'weeks'){
      if(get(dateDetail,'startDate') === moment().startOf('month').format("YYYY-MM-DD")){
        setDisabledPreTime(true)
      }
      else{
        setDisabledPreTime(false)
      }
      if(get(dateDetail,'endDate') === moment().endOf('month').format("YYYY-MM-DD")){
        setDisabledNextTime(true)
      }
      else{
        setDisabledNextTime(false)
      }
    }
  },[dateDetail])
  const label = useMemo(() => {
    const type = optionFilterDate?.find(e => get(e,'value') === typeFilter)
    if(type) return get(type,'label','')
    return ''
  },[typeFilter])
  useEffect(() => {
    setTitle({
      startDate : moment(get(dateDetail,'startDate','')).format("DD-MM-YYYY"),
      endDate : moment(get(dateDetail,'endDate','')).format("DD-MM-YYYY"),
    });
  },[dateDetail])

  const setPreMonth = () => {
    
    if(typeFilter === 'weeks'){
      const findWeek = arrDate.findIndex(week => moment(get(week,'date.startDate')).format("DD") === moment(get(dateDetail,'startDate')).format("DD"))
      const findDate = arrDate?.[findWeek - 1]
      return setDateDetail({...get(findDate,'date')})
    }
   return setDateDetail({
      startDate: moment(get(dateDetail,'startDate')).subtract(1, typeFilter).startOf(typeFilter).format('YYYY-MM-DD'),
      endDate: moment(get(dateDetail,'endDate')).subtract(1, typeFilter).endOf(typeFilter).format('YYYY-MM-DD')
    });
  };
  const setNextMonth = () => {
    if(typeFilter === 'weeks'){
      const findWeek = arrDate.findIndex(week => moment(get(week,'date.startDate')).format("DD") === moment(get(dateDetail,'startDate')).format("DD"))
      const findDate = arrDate?.[findWeek+1]
      return setDateDetail({...get(findDate,'date')})
    }
   return setDateDetail({
      startDate: moment(get(dateDetail,'startDate')).add(1, typeFilter).startOf(typeFilter).format('YYYY-MM-DD'),
      endDate: moment(get(dateDetail,'endDate')).add(1, typeFilter).endOf(typeFilter).format('YYYY-MM-DD')
    });
  };
  const [ratingPartner, isLoadingRating] = useGetRatingOnePartner({
    id,
    ...dateDetail
  });
  const [partner, isLoadingPartner] = useWhPartner(id);
  const [logsTips, isLoadingLogs] = useLogsTipsPartners(id, dateDetail);
  const [dataPartnerSnapShort,loading] = useReportKpIById(id,dateDetail);

  const checkIsFullTimeAndInMonth = () => {
    if(typeFilter !== 'month' ) return partner
    if(moment(get(dateDetail,'startDate')).format("MM-YYYY") === moment().format("MM-YYYY")) return partner
    return get(head(dataPartnerSnapShort),'partnerSnapshot') ? get(head(dataPartnerSnapShort),'partnerSnapshot') : {wayWorking:WH_PARTNER_WAY_WORKING.parttime}
  }
  return (
    // <Modal
    //   title="Chi tiết thu nhập"
    //   width="90vw"
    //   visible={isModalReVenueOpen}
    //   footer={[]}
    //   destroyOnClose
    //   onCancel={handleCancel}
    //   closable={false}
    // >
      <div className="container-fluid">
        <Row justify="space-between">
          <Col span={12}>
            <h4>Chi tiết thu nhập</h4>
          </Col>
          <Col lg={8} sm={12} md={12} xs={12}>
            {/* {title.includes('Tháng') ? (
              <Row align="end" style={{ alignItems: 'center' }}>
                <Col span={8}>
                  <Button onClick={setPreMonth} icon={<LeftOutlined />}>
                    Tháng trước
                  </Button>
                </Col>
                <Col span={8}>
                  <h6 style={{ margin: 0, textAlign: 'center' }}>{title.startDate}</h6>
                  <h6 style={{ margin: 0, textAlign: 'center' }}>-</h6>
                  <h6 style={{ margin: 0, textAlign: 'center' }}>{title.endDate}</h6>
                </Col>
                <Col span={8}>
                  <Button onClick={setNextMonth} icon={<RightOutlined />}>
                    Tháng sau
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row align="end" style={{ alignItems: 'center' }}>
                <Col span={8}>
                  <h6 style={{ margin: 0, textAlign: 'center' }}>{title}</h6>
                </Col>
              </Row>
            )} */}
            <Row align="end" style={{ alignItems: 'center' }}>
                <Col span={8}>
                  <Button disabled={disabledPreTime} onClick={setPreMonth} icon={<LeftOutlined />}>
                    {label} trước
                  </Button>
                </Col>
                <Col span={8}>
                  <h6 style={{ margin: 0, textAlign: 'center' }}>{get(title,'startDate','')}</h6>
                  <h6 style={{ margin: 0, textAlign: 'center' }}>-</h6>
                  <h6 style={{ margin: 0, textAlign: 'center' }}>{get(title,'endDate','')}</h6>
                </Col>
                <Col span={8}>
                  <Button disabled={disabledNextTime} onClick={setNextMonth} icon={<RightOutlined />}>
                    {label} sau
                  </Button>
                </Col>
              </Row>
          </Col>
        </Row>
        {/* Title Info */}
        <Descriptions className="box">
          <Descriptions.Item
            contentStyle={{ fontWeight: 700 }}
            span={3}
            label="Tên"
          >
            {isLoadingPartner ? (
              <Skeleton.Button active={true} />
            ) : (
              get(partner, 'name')
            )}
          </Descriptions.Item>
          <Descriptions.Item
            contentStyle={{ fontWeight: 700 }}
            span={3}
            label="Nhóm đối tác"
          >
            {isLoadingPartner ? (
              <Skeleton.Button active={true} />
            ) : (
              get(partner, 'employeeGroup[0].name.vi')
            )}
          </Descriptions.Item>
          <Descriptions.Item
            contentStyle={{ fontWeight: 700 }}
            span={3}
            label="Gói dịch vụ"
          >
            {isLoadingPartner ? (
              <Skeleton.Button active={true} />
            ) : (
              get(partner, 'packageLevel[0].name.vi')
            )}
          </Descriptions.Item>
          <Descriptions.Item
            contentStyle={{ fontWeight: 700 }}
            span={3}
            label="Hình thức làm việc"
          >
            {isLoadingPartner ? (
              <Skeleton.Button active={true} />
            ) : (
              WH_PARTNER_WAY_WORKING_EN[get(checkIsFullTimeAndInMonth(), 'wayWorking')]
            )}
          </Descriptions.Item>
        </Descriptions>
        <RatingExistData
          loading={isLoadingRating}
          date={dateDetail}
          partner={partner}
          data={ratingPartner || {}}
        />
        <AllTableFulltimeOrParttime
          mode={COMPONENT_MODES.EDIT}
          id={id}
          partner={partner}
          date={dateDetail}
          filterDateBy={typeFilter}
          type={get(partner, 'wayWorking')}
          dataPartnerSnapShort={dataPartnerSnapShort}
        />
        <LogsTipsPartner notes={logsTips} LoadingNote={isLoadingLogs} />
      </div>
    // </Modal>
  );
}
