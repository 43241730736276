import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createListTrial,
  deleteListTrial,
  getListTrial,
  getListTrials,
  resetListTrialState,
  updateListTrial
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.listTrial[key];
const pagingSelector = getSelector('paging');
export const useListTrialPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetListTrialSelector = getSelector('isGetListTrialLoading');
const getListTrialsFailedSelector = getSelector('getListTrialsFailed');
const getListTrialFailedSelector = getSelector('getListTrialFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const ListTrialsSelector = getSelector('ListTrials');
const ListTrialSelector = getSelector('ListTrial');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useListTrialQueryParams = (reFetch) => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const createListTrialSuccess = useSelector(createSuccessSelector);
  const updateListTrialSuccess = useSelector(updateSuccessSelector);
  const deleteListTrialSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createListTrialSuccess,
    deleteListTrialSuccess,
    updateListTrialSuccess,
    reFetch,
  ]);
};

export const useUpdateListTrialParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.software.listTrial,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};

export const useCreateListTrial = (callback) => {
  
  useSuccess(createSuccessSelector, 'Tạo mới phần mềm thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới phần mềm thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createListTrial
  });
};

export const useListTrials = (query) => {
  return useFetchByParam({
    action: getListTrials,
    loadingSelector,
    dataSelector: ListTrialsSelector,
    failedSelector: getListTrialsFailedSelector,
    param: query
  });
};
export const useListTrial = (id) => {
  return useFetchByParam({
    action: getListTrial,
    loadingSelector: loadinggetListTrialSelector,
    dataSelector: ListTrialSelector,
    failedSelector: getListTrialFailedSelector,
    param: id
  });
};
export const useUpdateListTrial = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât Ứng tuyển thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateListTrial
  });
};
export const useDeleteListTrial = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Ứng tuyển thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật Ứng tuyển thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteListTrial
  });
};

export const useResetListTrial = () => {
  useResetState(resetListTrialState);
};
