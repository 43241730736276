export const GET_LIST_TRIALS_REQUEST =
    'GET_LIST_TRIALS_REQUEST';
export const GET_LIST_TRIALS_SUCCESS =
    'GET_LIST_TRIALS_SUCCESS';
export const GET_LIST_TRIALS_FAILED = 'GET_LIST_TRIALS_FAILED';

export const GET_LIST_TRIAL_REQUEST = 'GET_LIST_TRIAL_REQUEST';
export const GET_LIST_TRIAL_SUCCESS = 'GET_LIST_TRIAL_SUCCESS';
export const GET_LIST_TRIAL_FAILED = 'GET_LIST_TRIAL_FAILED';

export const CREATE_LIST_TRIAL_REQUEST =
    'CREATE_LIST_TRIAL_REQUEST';
export const CREATE_LIST_TRIAL_SUCCESS =
    'CREATE_LIST_TRIAL_SUCCESS';
export const CREATE_LIST_TRIAL_FAILED =
    'CREATE_LIST_TRIAL_FAILED';

export const UPDATE_LIST_TRIAL_REQUEST =
    'UPDATE_LIST_TRIAL_REQUEST';
export const UPDATE_LIST_TRIAL_SUCCESS =
    'UPDATE_LIST_TRIAL_SUCCESS';
export const UPDATE_LIST_TRIAL_FAILED =
    'UPDATE_LIST_TRIAL_FAILED';


export const DELETE_LIST_TRIAL_REQUEST =
    'DELETE_LIST_TRIAL_REQUEST';
export const DELETE_LIST_TRIAL_SUCCESS =
    'DELETE_LIST_TRIAL_SUCCESS';
export const DELETE_LIST_TRIAL_FAILED =
    'DELETE_LIST_TRIAL_FAILED';


export const RESET_LIST_TRIAL_STATE = 'RESET_LIST_TRIAL_STATE';
export const RESET_LIST_TRIAL_ACTION = 'RESET_LIST_TRIAL_ACTION';
