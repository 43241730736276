import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getPointSystems({ payload: query }) {
    try {
      const response = yield call(Api.pointSystem.getAll, query);
      yield put({ type: Types.GET_POINT_SYSTEMS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_POINT_SYSTEMS_FAILED, payload: error });
    }
  }
function* getPointSystemsPartner({ payload: query }) {
    try {
      const response = yield call(Api.pointSystem.getByIdPartner, query);
      yield put({ type: Types.GET_POINT_SYSTEMS_PARTNER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_POINT_SYSTEMS_PARTNER_FAILED, payload: error });
    }
  }
function* getPointSystem({ payload: id }) {
    try {
      const response = yield call(Api.pointSystem.getById, id);
      yield put({ type: Types.GET_POINT_SYSTEM_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_POINT_SYSTEM_FAILED, payload: error });
    }
  }
  function* createPointSystem(action) {
    try {
      const data = yield call(Api.pointSystem.create, action.payload);
      yield put({ type: Types.CREATE_POINT_SYSTEM_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_POINT_SYSTEM_FAILED, payload: error.message });
    }
  }
  function* updatePointSystem(action) {
    try {
      const data = yield call(Api.pointSystem.update, action.payload);
      yield put({ type: Types.UPDATE_POINT_SYSTEM_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_POINT_SYSTEM_FAILED, payload: error.message });
    }
  }
  function* deletePointSystem(action) {
    try {
      const data = yield call(Api.pointSystem.delete, action.payload);
      yield put({ type: Types.DELETE_POINT_SYSTEM_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_POINT_SYSTEM_FAILED, payload: error.message });
    }
  }
export default function* PointSystems() {
    yield takeLatest(Types.GET_POINT_SYSTEMS_REQUEST, getPointSystems);
    yield takeLatest(Types.GET_POINT_SYSTEMS_PARTNER_REQUEST, getPointSystemsPartner);
    yield takeLatest(Types.GET_POINT_SYSTEM_REQUEST, getPointSystem);
    yield takeLatest(Types.CREATE_POINT_SYSTEM_REQUEST, createPointSystem);
    yield takeLatest(Types.UPDATE_POINT_SYSTEM_REQUEST, updatePointSystem);
    yield takeLatest(Types.DELETE_POINT_SYSTEM_REQUEST, deletePointSystem);
  }