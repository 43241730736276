import { 
  GET_NOTIFY_ACCOUNTS_FAILED,
  GET_NOTIFY_ACCOUNTS_REQUEST,
  GET_NOTIFY_ACCOUNTS_SUCCESS,
  UPDATE_NOTIFY_ACCOUNTS_FAILED,
  UPDATE_NOTIFY_ACCOUNTS_REQUEST,
  UPDATE_NOTIFY_ACCOUNTS_SUCCESS
} from "~/constants/actionTypes/notificationBotManagement";

export const getNotifyAccounts = () => ({
  type: GET_NOTIFY_ACCOUNTS_REQUEST 
});

export const updateNotifyAccounts = (newNotifyAccounts) => ({
  type: UPDATE_NOTIFY_ACCOUNTS_REQUEST,
  payload: newNotifyAccounts
})


 