import * as Types from '../../constants/actionTypes';
  
  export const getPositionJobs = query => ({
    type: Types.GET_POSITION_JOBS_REQUEST,
    payload: query
  });
  export const getPositionJob = id => ({
    type: Types.GET_POSITION_JOB_REQUEST,
    payload: id
  });
  export const updatePositionJob = instance => ({
    type: Types.UPDATE_POSITION_JOB_REQUEST,
    payload: instance
  });
  export const createPositionJob = instance => ({
    type: Types.CREATE_POSITION_JOB_REQUEST,
    payload: instance
  });
  export const deletePositionJob = id => ({
    type: Types.DELETE_POSITION_JOB_REQUEST,
    payload: id
  });
  export const resetPositionJobState = () => ({
    type: Types.RESET_POSITION_JOB_STATE
  });
