import FullCalendar from '@fullcalendar/react'
import React from 'react'
import { CARD_STYLE, WH_LIST_PRODUCT_ACTION } from '~/constants/defaultValue'
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import viLocale from '@fullcalendar/core/locales/vi';
import './index.scss'
import { useGetCalendarProductById } from '~/hooks/productList';
import { get, keys } from 'lodash';
import { Card, Col, Descriptions, Popover, Row, Tooltip, Typography } from 'antd';
import LoadingWhBill from '~/components/WhBill/LoadingWhBill';
import Meta from 'antd/lib/card/Meta';
import { PATH_APP } from '~/routes/paths';

const CardInFoCalendar = ({ avatar, codeWhAppointment, codeWhBill, nameWhPartner, typeOfItem }) => {
    return <>
        <Card
            style={{
                width: 240,
                borderRadius: 20
            }}
        >
            <Meta description={
                <Descriptions column={1} title="Thông tin đơn hàng">
                    {nameWhPartner && <Descriptions.Item label="Đối tác">{nameWhPartner}</Descriptions.Item>}
                    <Descriptions.Item label="Mã đơn hàng">{codeWhBill}</Descriptions.Item>
                    {/* <Descriptions.Item label="Mã cuộc hẹn">{codeWhAppointment}</Descriptions.Item> */}
                    {/* <Descriptions.Item label="Trạng thái">{typeOfItem}</Descriptions.Item> */}
                </Descriptions>
            } />
        </Card>
    </>
}
const CardInfoWarehouse = ({ data }) => {
    const { codeCommerPurchVoucher, employee } = data;
    return <>
        <Card
            style={{
                width: 240,
                borderRadius: 20
            }}
        >
            <Meta description={
                <Descriptions column={1} title="Thông tin phiếu">
                    <Descriptions.Item label="Mã phiếu">
                        <Typography.Text strong
                        >{codeCommerPurchVoucher}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Nhân viên">
                        <Typography.Text strong>
                            {get(employee, 'fullName', '')}
                        </Typography.Text>
                    </Descriptions.Item>
                </Descriptions>
            } />
        </Card>
    </>
}
const CardInfoConfirm = ({ data }) => {
    const { codeSequence, productCheck,whPartner } = data;
    return <>
        <Card
            style={{
                width: 240,
                borderRadius: 20
            }}
        >
            <Meta description={
                <Descriptions column={1} title="Phiếu xác nhận">
                      <Descriptions.Item label="Mã phiếu xác nhận">
                        <Typography.Text strong
                        >{codeSequence}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Mã đơn hàng">
                        <Typography.Text strong
                        >{get(productCheck,'whBill.billNumber','')}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Đối tác">
                        <Typography.Text strong
                        >{get(whPartner,'name','')}</Typography.Text>
                    </Descriptions.Item>
                </Descriptions>
            } />
        </Card>
    </>
}
function renderEventContent(eventInfo) {
    const { appointment,typeOfItem, typeVoucher, data } = eventInfo.event.extendedProps;
    let component = () => <></>;
    switch (typeVoucher) {
        case 'warehouse':
            component = <CardInfoWarehouse data={data} />;
            break;
        case 'confirm':
            component = <CardInfoConfirm data={data} />;
            break;
        case 'preBooking':
            component = <CardInFoCalendar
                // nameWhPartner={get(appointment, 'whPartner.name', '')}
                // codeWhAppointment={get(appointment, 'code', '')}
                codeWhBill={get(data, 'whBill.billNumber', '')}
                typeOfItem={get(WH_LIST_PRODUCT_ACTION, `${typeOfItem}.name`, '')}
            />;
            break;

        default:
            break;
    }
    return (
        <Popover zIndex={9999} placement='left' content={component}>

            <div className="apponintment-item">
                <div className="apponintment-item__title-box">
                    <b>{eventInfo.timeText}</b>
                </div>
                <p className="apponintment-item__title">{eventInfo.event.title}</p>
                <p className="apponintment-item__hospital-name">
                    {get(appointment, 'whPartner.name', '')}
                </p>
                <p className="apponintment-item__hospital-name">
                    ({get(WH_LIST_PRODUCT_ACTION, `${typeOfItem}.name`, '')})
                </p>
            </div>
        </Popover>

    );
}

export default function CalendarProduct({ productId }) {
    const onAppointmentClick = (clickInfo) => {
        const { typeVoucher, data } = clickInfo.event.extendedProps;
        let link;
        switch (typeVoucher) {
            case 'warehouse':
                const { codeCommerPurchVoucher } = data;
                link = `${PATH_APP.voucherWareHouse.root}?codeCommerPurchVoucher=${codeCommerPurchVoucher}`;
                break;
            case 'confirm':
                const { codeSequence } = data;
                link = `${PATH_APP.confirmVoucherWareHouse.root}?codeSequence=${codeSequence}`;
                break;
            case 'preBooking':
                console.log(data,'data');
                link = `wh-bill-item/${get(data,'whBillItem._id')}/update-appointments`;
                break;

            default:
                break;
        }

        window.open(link)
    }
    const { data, loading, onChangeDate } = useGetCalendarProductById(productId);
    const onChangeDateCalendar = (dateInfo) => {
        const date = {
            startDate: moment(get(dateInfo, 'startStr')).format('YYYY-MM-DD'),
            endDate: moment(get(dateInfo, 'endStr')).format('YYYY-MM-DD'),
        }
        onChangeDate(date)
    }
    return (
        <>
            <LoadingWhBill open={loading} />
            <div className='appointment calendar-product' style={CARD_STYLE}>
                <FullCalendar
                    headerToolbar={{
                        left: 'dayGridMonth,timeGridWeek,listWeek',
                        center: 'title',
                        right: 'prev,next'
                    }}
                    plugins={[
                        listPlugin,
                        dayGridPlugin,
                        timelinePlugin,
                        timeGridPlugin,
                        interactionPlugin
                    ]}
                    locales={[viLocale]}
                    locale="vi"
                    initialView="dayGridMonth"
                    dayMaxEvents={true}
                    events={data}
                    displayEventEnd
                    eventClassNames={['FullCalendar-event-product']}
                    eventContent={renderEventContent}
                    eventClick={onAppointmentClick}
                    datesSet={onChangeDateCalendar}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: 'short'
                    }}
                    eventDisplay={"block"}
                />
                <div>
                    {
                        keys(WH_LIST_PRODUCT_ACTION).map((stt) => {
                            return (
                                <Row className='appointment-bottom-note__row'>
                                    <Col className='appointment-bottom-note__col' span={4}>
                                        <Tooltip zIndex={9999} title={get(WH_LIST_PRODUCT_ACTION, [stt, 'desc'], '')}>
                                            <div className={`appointment-bottom-note__${stt.toLowerCase()}`}></div>
                                        </Tooltip>
                                    </Col>
                                    <Col className='appointment-bottom-note__wrapper' span={12}>
                                        <p className='appointment-bottom-note__title'>{WH_LIST_PRODUCT_ACTION[stt]?.name}</p>
                                    </Col>
                                </Row>)
                        })
                    }
                </div>
            </div>
        </>
    )
}
