import { SwapOutlined } from '@ant-design/icons'
import { Button, Col } from 'antd'
import React from 'react'
import { formatNumberThreeCommaToFixed } from '~/hooks/utils';
import { floorFormatter } from '~/utils/helper';

export default function ExchangePointRefundItem({active,value,onClick,coefficient}) {

  return (
    <>
        <Col span={11}>
            <Button style={{borderRadius : '10px' , fontWeight : '400' , boxShadow : 'rgb(9 9 9 / 16%) 0px 0px 8px 0px' ,borderWidth : '.5px'}} onClick={onClick} className={`Group-content__exchange__item ${active ? 'active' : 'unActive' }`} >
            <p className='Group-content__exchange__item__text'>{formatNumberThreeCommaToFixed(value)} Điểm</p>
            <span className='Group-content__exchange__item__text'><SwapOutlined style={{marginRight : '5px'}}/>{floorFormatter(coefficient * value)} đ</span>
            </Button>
        </Col>
    </>
  )
}
