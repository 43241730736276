import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  deleteInfoCompany,
  getInfoCompany,
  getInfoCompanys,
  resetInfoCompanyState,
  updateInfoCompany,
  createInfoCompany,
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.infoCompany[key];
const pagingSelector = getSelector('paging');
export const useInfoCompanyPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const isGetInfoCompanyLoading = getSelector('isGetInfoCompanyLoading');
const getInfoCompanysFailedSelector = getSelector('getInfoCompanysFailed');
const getInfoCompanyFailedSelector = getSelector('getInfoCompanyFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const InfoCompanysSelector = getSelector('InfoCompanys');
const InfoCompanySelector = getSelector('InfoCompany');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useInfoCompanyQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const createInfoCompanySuccess = useSelector(createSuccessSelector);
  const deleteInfoCompanySuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createInfoCompanySuccess,
    deleteInfoCompanySuccess,
  ]);
};

export const useUpdateInfoCompanyParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.infoCompany.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};
export const useInfoCompanys = (query) => {
  return useFetchByParam({
    action: getInfoCompanys,
    loadingSelector,
    dataSelector: InfoCompanysSelector,
    failedSelector: getInfoCompanysFailedSelector,
    param: query
  });
};
export const useInfoCompany = (id) => {
  return useFetchByParam({
    action: getInfoCompany,
    loadingSelector: isGetInfoCompanyLoading,
    dataSelector: InfoCompanySelector,
    failedSelector: getInfoCompanyFailedSelector,
    param: id
  });
};
export const useUpdateInfoCompany = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât công ty thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateInfoCompany
  });
};

export const useCreateInfoCompany = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới công ty thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createInfoCompany
  });
};
export const useDeleteInfoCompany = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá công ty thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật công ty thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteInfoCompany
  });
};

export const useResetInfoCompany = () => {
  useResetState(resetInfoCompanyState);
};
