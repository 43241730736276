import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { get, pick } from 'lodash';

import { useInitWhPartner, useUpdateWhPartner, useUpdateWorkingTimeWhPartner, useWhPartner } from '~/hooks/whPartner';
import { useSpecialities } from '~/hooks/speciality';
import { useStaffGroups } from '~/hooks/staffGroup';
import { useWhServices } from '~/hooks';

import AssignedServicesTable from '~/components/WhPartner/AssignedServicesTable';
import WorkingDaysTable from '~/components/WhPartner/WorkingDaysTable';
import { COMPONENT_MODES, MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { toJSONMiniFormFieldsOnly } from '~/components/WhPartner/parser';

import WhPartnerMiniFormSection from './WhPartnerMiniFormSection';
import Text from 'antd/lib/typography/Text';
import { CopyOutlined } from '@ant-design/icons';
import LinkReferralPartner from './LinkReferralPartner';
// import { useRankings } from '~/hooks/ranking';
import WhPartnerRanking from './WhPartnerRanking';
import { useRankings } from '~/hooks/ranking';
import toastr from 'toastr';
const verticalLayout = {
  wrapperCol: { sm: 24 },
  labelCol: { xs: 8, sm: 8 },
};

const WhPartnerMiniForm = ({
  showAssignedServicesTable,
  showWorkingDaysTable,
  whPartnerId,
  mode,
  showLinkRefferral,
  isShowRanking
}) => {
  const [form] = Form.useForm();
  const [sessionForm] = Form.useForm();

  const [isOpenModal, setIsOpenModal] = useState(false); // refactor this
  const [dayIdx, setDayIdx] = useState();
  const [sessionIdx, setSessionIdx] = useState();

  const [whPartner, isGetWhPartnerLoading] = useWhPartner(whPartnerId);
  const initWhPartner = useInitWhPartner(whPartner);
  const [initSession, setInitSession] = useState({
    startTime: null,
    endTime: null,
  });
  // const [, handleUpdate] = useUpdateWhPartner();
  const [submitLoading, handleUpdate] = useUpdateWorkingTimeWhPartner();

  const [specialities, isSpecialitiesLoading] = useSpecialities();
  const [staffGroups, isStaffGroupLoading] = useStaffGroups();

  const [queryWhServices, setQueryWhServices] = useState();
  const [whServices, isWhServicesLoading] = useWhServices(queryWhServices);
  const queryFetchRanking = useMemo(() => ({page : 1 ,limit : MAX_PAGINATION_LIMIT,sortPoint : 1}),[])
  const [rankings,isLoadingRankings] = useRankings(queryFetchRanking)

  useEffect(() => {
    const ids = get(whPartner, "services");
    setQueryWhServices({ ids });
  }, [whPartner]);

  useEffect(() => {
    form.resetFields();
  }, [
    initWhPartner,
    form,
  ]);

  const mergedInitWhPartner = useMemo(() => {
    if (!whPartnerId) {
      return {
        ...initWhPartner,
      }
    }
    return {
      ...initWhPartner,
      services: get(initWhPartner, "services")?.map(whService => ({
        ...whService,
        ...whServices.find(item => item.id === whService.whServiceId)
      }))
    };
  }, [
    whPartnerId,
    initWhPartner,
    whServices,
  ])
  const onValuesChange = (changes) => {
    const whPartnerUpdate = toJSONMiniFormFieldsOnly({ ...form.getFieldsValue() })
    const employeeGroup = [get(whPartner,'employeeGroup.[0]._id',get(whPartner,'employeeGroup',''))]
    const submitData = {...whPartnerUpdate, id: whPartnerId,...pick(whPartner,['speciality']),employeeGroup }
    handleUpdate(submitData);
  }

  const onFinish = (values) => {
  }

  // FIXME: refactor this function as it is duplicated code
  const checkTime = (times,newTimes) => {
    const find = newTimes?.find((e,index) => {
      const start = moment(e.startTime, 'HH:mm');
      const end = moment(e.endTime, 'HH:mm');
      const timeStart = moment(times.startTime, 'HH:mm');
      const timeEnd = moment(times.endTime, 'HH:mm');
      if(e.startTime === times.startTime && e.endTime === times.endTime) {
        return false
      }
      const isExist = ((timeStart.isBetween(start, end) || timeEnd.isBetween(start, end)) || (start.isBetween(timeStart, timeEnd) || end.isBetween(timeStart, timeEnd)));
      return isExist
    })
    return find
  }
  const setTime = (action) => {
    // format data get from sessionForm to string format "HH:mm"
    const sessionFormFieldsValue = {
      startTime: moment.isMoment(sessionForm.getFieldValue('startTime'))
        ? moment(sessionForm.getFieldValue('startTime')).format('HH:mm')
        : sessionForm.getFieldValue('startTime'),
      endTime: moment.isMoment(sessionForm.getFieldValue('endTime'))
        ? moment(sessionForm.getFieldValue('endTime')).format('HH:mm')
        : sessionForm.getFieldValue('endTime')
    };
  let newAvailabelTime = [...form.getFieldValue('availableTime')?.[dayIdx]?.sessionsOfDay]
  if(sessionIdx !== -1) {
    newAvailabelTime.splice(sessionIdx,1)
  }
  
   const isExist = checkTime(sessionFormFieldsValue,newAvailabelTime)
   if(!(action && action.remove)){
    if(isExist){
      toastr.error(`Thêm thất bại , trùng giờ ${get(isExist,'startTime','')} : ${get(isExist,'endTime','')}`)
      return 
     }
   }
    form.setFieldsValue({
      availableTime: form.getFieldValue('availableTime').map((day, dIdx) => ({
        ...day,
        sessionsOfDay:
          sessionIdx === -1
            ? // add new session on the matching day
              dIdx === dayIdx
              ? day.sessionsOfDay.concat({ ...sessionFormFieldsValue })
              : day.sessionsOfDay
            : action && action.remove
            ? // remove session on the matching day only
              day.sessionsOfDay.filter((session, sIdx) =>
                dIdx === dayIdx ? sIdx !== sessionIdx : true
              )
            : // update session on the matching day only
              day.sessionsOfDay.map((session, sIdx) => ({
                ...session,
                ...(dIdx === dayIdx &&
                  sIdx === sessionIdx && { ...sessionFormFieldsValue })
              }))
      }))
    });
    // onValuesChange()
  };

  return (
    <Form
      form={form}
      autoComplete="off"
      onFinish={onFinish}
      // onValuesChange={onValuesChange}
      scrollToFirstError
      requiredMark={true}
      initialValues={mergedInitWhPartner}
      {...verticalLayout}
    >
      <Space direction='vertical' style={{ width: "100%" }}>
        <WhPartnerMiniFormSection
          isSpecialitiesLoading={isSpecialitiesLoading}
          isStaffGroupLoading={isStaffGroupLoading}
          specialities={specialities}
          staffGroups={staffGroups}
          mode={mode}
          whPartner={mergedInitWhPartner}
         
          // whServices={whServices}
        />
        {isShowRanking && <WhPartnerRanking idPartner={whPartnerId} loading={isLoadingRankings}  rankings={rankings}/>}
        {showLinkRefferral && <LinkReferralPartner whPartner={mergedInitWhPartner} isGetWhPartnerLoading={isGetWhPartnerLoading}/>}
        {showAssignedServicesTable && (
          <Row>
            <Col span={24}>
              <AssignedServicesTable
                mode={COMPONENT_MODES.VIEW}
                dataSource={get(mergedInitWhPartner, "services")}
                showTagsOnly={true}
              />
            </Col>
          </Row>
        )}

        {showWorkingDaysTable && <Row>
          <Col span={24}>
            <WorkingDaysTable
              form={form}
              initSession={initSession}
              isOpenModal={isOpenModal}
              mode={COMPONENT_MODES.EDIT}
              sessionForm={sessionForm}
              sessionIdx={sessionIdx}
              setDayIdx={setDayIdx}
              setIsOpenModal={setIsOpenModal}
              setSessionIdx={setSessionIdx}
              setTime={setTime}
            />
          </Col>
          <Col style={{marginTop : 10}} span={24} align='center'>
          <Button type='primary' loading={submitLoading} onClick={onValuesChange}>Cập nhật</Button>
          </Col>
        </Row>}
      </Space>
    </Form>
  )
}

export default WhPartnerMiniForm;
