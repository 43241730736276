import * as Types from '../../constants/actionTypes';
  
  export const getJobs = query => ({
    type: Types.GET_JOBS_REQUEST,
    payload: query
  });
  export const getJob = id => ({
    type: Types.GET_JOB_REQUEST,
    payload: id
  });
  export const createJob = instance => ({
    type: Types.CREATE_JOB_REQUEST,
    payload: instance
  });
  export const updateJob = instance => ({
    type: Types.UPDATE_JOB_REQUEST,
    payload: instance
  });
  export const deleteJob = id => ({
    type: Types.DELETE_JOB_REQUEST,
    payload: id
  });
  export const resetJobState = () => ({
    type: Types.RESET_JOB_STATE
  });
