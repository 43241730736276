import produce from 'immer';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  isLoadingTrainingCourse: false,
  getTrainingCoursesPartnerFailed: null,
  getTrainingCoursesOfPartnerFailed: null,
  getTrainingCoursePartnerFailed:null,
  trainingCoursesPartner: [],
  trainingCoursesOfPartner: [],
  trainingCoursePartner: null,
  isSubmitLoading : false,
  isGetTrainingCoursePartnerLoading: false,
  TrainingCourse: null,

  isLoadingReady: false,
  TrainingCourseReady: [],
  getTrainingCoursesReadyFailed: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  submitSuccess: null,
  submitFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

  notes:null,
  loadingNote:null,
  getNoteFailed:null,
  getNoteSuccess:null,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_TRAINING_COURSES_PARTNER_REQUEST:
      state.isLoadingTrainingCourse = true;
      state.getTrainingCoursesPartnerFailed = null;
      return;

    case Types.GET_TRAINING_COURSES_PARTNER_SUCCESS:
      state.isLoadingTrainingCourse = false;
      state.trainingCoursesPartner = payload.docs;
      state.getTrainingCoursesPartnerFailed = null;
      // state.paging = getPaging(payload);
      return;

    case Types.GET_TRAINING_COURSES_PARTNER_FAILED:
      state.isLoadingTrainingCourse = false;
      state.trainingCoursesPartner = [];
      state.getTrainingCoursesPartnerFailed = payload;
      return;
    case Types.GET_TRAINING_COURSES_OF_PARTNER_READY_REQUEST:
      state.isLoadingReady = true;
      state.getTrainingCoursesReadyFailed = null;
      return;

    case Types.GET_TRAINING_COURSES_OF_PARTNER_READY_SUCCESS:
      state.isLoadingReady = false;
      state.TrainingCourseReady = payload;
      state.getTrainingCoursesReadyFailed = null;
      // state.paging = getPaging(payload);
      return;

    case Types.GET_TRAINING_COURSES_OF_PARTNER_READY_FAILED:
      state.isLoadingReady = false;
      state.TrainingCourseReady = [];
      state.getTrainingCoursesReadyFailed = payload;
      return;
    case Types.GET_TRAINING_COURSES_OF_PARTNER_REQUEST:
      state.isLoading = true;
      state.getTrainingCoursesOfPartnerFailed = null;
      return;

    case Types.GET_TRAINING_COURSES_OF_PARTNER_SUCCESS:
      state.isLoading = false;
      state.trainingCoursesOfPartner = payload.docs;
      state.getTrainingCoursesOfPartnerFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_TRAINING_COURSES_OF_PARTNER_FAILED:
      state.isLoading = false;
      state.trainingCoursesOfPartner = [];
      state.getTrainingCoursesOfPartnerFailed = payload;
      return;
    case Types.GET_TRAINING_COURSE_PARTNER_REQUEST:
      state.isGetTrainingCoursePartnerLoading = true;
      state.trainingCoursePartner = null
      state.getTrainingCoursePartnerFailed = null;
      return;

    case Types.GET_TRAINING_COURSE_PARTNER_SUCCESS:
      state.isGetTrainingCoursePartnerLoading = false;
      state.trainingCoursePartner = payload;
      state.getTrainingCoursePartnerFailed = null;
      return;

    case Types.GET_TRAINING_COURSE_PARTNER_FAILED:
      state.isGetTrainingCoursePartnerLoading = false;
      state.trainingCoursePartner = null;
      state.getTrainingCoursePartnerFailed = payload;
      return;
    case Types.GET_NOTE_TIMESHEET_REQUEST:
      state.loadingNote = true;
      state.notes = null;
      state.getNoteFailed = null;
      return;

    case Types.GET_NOTE_TIMESHEET_SUCCESS:
      state.loadingNote = false;
      state.notes = payload;
      state.getNoteFailed = null;
      return;

    case Types.GET_NOTE_TIMESHEET_FAILED:
      state.loadingNote = false;
      state.notes = null;
      state.getNoteFailed = payload;
      return;
    case Types.CREATE_TRAINING_COURSE_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_TRAINING_COURSE_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_TRAINING_COURSE_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_TRAINING_COURSE_PARTNER_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_TRAINING_COURSE_PARTNER_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.trainingCoursePartner = null;
        return;
  
      case Types.UPDATE_TRAINING_COURSE_PARTNER_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_TRAINING_COURSE_PARTNER_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_TRAINING_COURSE_PARTNER_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_TRAINING_COURSE_PARTNER_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;
      case Types.SUBMIT_TRAINING_COURSE_PARTNER_REQUEST:
        state.isSubmitLoading = true;
        state.submitSuccess = null;
        state.submitFailed = null;
        return;
  
      case Types.SUBMIT_TRAINING_COURSE_PARTNER_SUCCESS:
        state.isSubmitLoading = false;
        state.submitSuccess = payload;
        return;
  
      case Types.SUBMIT_TRAINING_COURSE_PARTNER_FAILED:
        state.isSubmitLoading = false;
        state.submitFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_TRAINING_COURSE_PARTNER_STATE:
      return initState;

    default:
      return;
  }
}, initState);
