import { EditTwoTone, EyeInvisibleOutlined, EyeTwoTone, KeyOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, Col, Input, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import api from '~/api'
import toastr from 'toastr'
import { get } from 'lodash'
import { v4 } from 'uuid'

export default function ApiKeyForm({branchId,apiKey : apiKeyWareHouse}) {
    const [codeConfirm,setCodeConfirm]=useState(v4().slice(0,6))
    const [changeApi,setChangeApi] = useState(false)
    const [apiKey,setApiKey] = useState('')
    const [loading,setLoading] = useState(false)
    const [confirmText,setConfirmText] = useState('')
    const [visibleModalConfirm,setVisibleModalConfirm] = useState(false)
    const handleOpenModalConfirm = () => {
        setVisibleModalConfirm(true)
    }
    const handleCloseModalConfirm = () => {
        setVisibleModalConfirm(false)
    }
    const handleOpenChangeApi = () => {
        setChangeApi(true)
    }
    const handleCloseChangeApi = () => {
        setChangeApi(false)
    }
    const checkCodeConfirm = () => codeConfirm === confirmText
    const changeCodeConfirm = () => setCodeConfirm(v4().slice(0,6))
    const handleSaveApiKey = async() => {
        try {
            if(!checkCodeConfirm) return
            setLoading(true)
            await api.apiKeyWareHouse.update({branchId,apiKey})
            handleCloseChangeApi()
            handleCloseModalConfirm()
            setLoading(false)
            toastr.success("Cập nhật Api key thành công")
            changeCodeConfirm()
        } catch (error) {
            setLoading(false)
            toastr.error(get(error,'response.data.message','Some error'))
        }
    }
    useEffect(() => {
        setApiKey(apiKeyWareHouse)
    },[apiKeyWareHouse])
    return (
        <Row align='middle' className="branch-form__images">
            <Col span={4}><h6 className="branch-form__images-title">Api KEY Kho <KeyOutlined /></h6></Col>
            <Col span={8}>            
            <Input.Password
                readOnly={!changeApi}
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder="API KEY kho"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
            </Col>
            {changeApi ? <>
            <Col><Button onClick={handleCloseChangeApi}>Huỷ</Button></Col>
            <Col><Button onClick={handleOpenModalConfirm} type='primary'>Lưu</Button></Col>
            </> : <Button onClick={handleOpenChangeApi}><EditTwoTone /></Button> 
            }
        <Modal confirmLoading={loading} okText="Xác nhận" cancelText="Huỷ" okButtonProps={{style : {display : checkCodeConfirm() ? 'unset' : 'none'}}} visible={visibleModalConfirm} onCancel={handleCloseModalConfirm} onOk={handleSaveApiKey}>
                <h5 style={{textAlign : 'center'}}>Xác nhận đổi mã liên kết kho</h5>
                <Row>
                    <Col flex={1}><Input style={{userSelect :'none'}}  value={confirmText} onChange={(e) => setConfirmText(e.target.value)} placeholder='Nhập mã xác nhận' addonAfter={<span><code>{codeConfirm}</code></span>}/></Col>
                    <Col><Button disabled={loading} onClick={changeCodeConfirm}><RedoOutlined /></Button></Col>
                </Row>
            </Modal>
        </Row>
    )
}
