import { useMemo, useState, useRef } from 'react';
import {
  useFetchByParam,
  useQueryParams,

} from '../utils';
import { useSelector } from 'react-redux';
import { getHistories } from '~/redux/history/actions';

const getSelector = key => state => state.history[key];

const loadingSelector = getSelector('isLoading');
const historiesSelector = getSelector('histories');
const getHistoryFailedSelector = getSelector('getHistoryFailed');

const pagingSelector = getSelector('paging');
export const useHistoriesPaging = () => useSelector(pagingSelector);

export const useHistoriesQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current);
    setLimit(pageSize);
  };

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined,
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword]);
};

export const useHistories = query => {
  return useFetchByParam({
    action: getHistories,
    loadingSelector,
    dataSelector: historiesSelector,
    failedSelector: getHistoryFailedSelector,
    param: query,
  });
};
