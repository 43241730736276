
//task
//get all task
export const GET_ALL_TASKS_REQUEST = 'GET_ALL_TASKS_REQUEST';
export const GET_ALL_TASKS_SUCCESS = 'GET_ALL_TASKS_SUCCESS';
export const GET_ALL_TASKS_FAILED = 'GET_ALL_TASKS_FAILED';
//getById
export const GET_TASK_BY_ID_REQUEST = 'GET_TASK_BY_ID_REQUEST';
export const GET_TASK_BY_ID_SUCCESS = 'GET_TASK_BY_ID_SUCCESS';
export const GET_TASK_BY_ID_FAILED = 'GET_TASK_BY_ID_FAILED';
//create task
export const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILED = 'CREATE_TASK_FAILED';
//update task
//task
//update task
export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAILED = 'UPDATE_TASK_FAILED';

export const UPDATE_TASK_CHANGE_PROSITION = 'UPDATE_TASK_CHANGE_PROSITION';

export const UPDATE_TASK_INIT_REQUEST = 'UPDATE_TASK_INIT_REQUEST';
export const UPDATE_TASK_INIT_SUCCESS = 'UPDATE_TASK_INIT_SUCCESS';
export const UPDATE_TASK_INIT_FAILED = 'UPDATE_TASK_INIT_FAILED';
//update Progress task
export const UPDATE_PROGRESS_TASK_REQUEST = 'UPDATE_PROGRESS_TASK_REQUEST';
export const UPDATE_PROGRESS_TASK_SUCCESS = 'UPDATE_PROGRESS_TASK_SUCCESS';
export const UPDATE_PROGRESS_TASK_FAILED = 'UPDATE_PROGRESS_TASK_FAILED';

//delete task
export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILED = 'DELETE_TASK_FAILED';

//historyLogs
export const GET_HISTORY_ACTIVITY_REQUEST = 'GET_HISTORY_ACTIVITY_REQUEST';
export const GET_HISTORY_ACTIVITY_SUCCESS = 'GET_HISTORY_ACTIVITY_SUCCESS';
export const GET_HISTORY_ACTIVITY_FAILED = 'GET_HISTORY_ACTIVITY_FAILED';

//Handle Assign
export const ASSIGN_TASK_REQUEST = 'ASSIGN_TASK_REQUEST';
export const ASSIGN_TASK_SUCCESS = 'ASSIGN_TASK_SUCCESS';
export const ASSIGN_TASK_FAILED = 'ASSIGN_TASK_FAILED';
//copy task
export const COPY_TASK_REQUEST = 'COPY_TASK_REQUEST';
export const COPY_TASK_SUCCESS = 'COPY_TASK_SUCCESS';
export const COPY_TASK_FAILED = 'COPY_TASK_FAILED';

//Handle COMMENT_LIST
export const COMMENT_LIST_REQUEST = 'COMMENT_LIST_REQUEST';
export const COMMENT_LIST_SAVE = 'COMMENT_LIST_SAVE';
export const COMMENT_LIST_PUSH = 'COMMENT_LIST_PUSH';
export const COMMENT_LIST_EMOTION = 'COMMENT_LIST_EMOTION';
export const COMMENT_LIST_SUCCESS = 'COMMENT_LIST_SUCCESS';
export const COMMENT_LIST_FAILED = 'COMMENT_LIST_FAILED';

//Handle COMMENT
export const COMMENT_REQUEST = 'COMMENT_REQUEST';
export const COMMENT_RESET = 'COMMENT_RESET';
export const COMMENT_SUCCESS = 'COMMENT_SUCCESS';
export const COMMENT_FAILED = 'COMMENT_FAILED';


export const COMMENT_DELETE = 'COMMENT_DELETE';
export const COMMENT_DELETE_REQUEST = 'COMMENT_DELETE_REQUEST';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_FAILED = 'COMMENT_DELETE_FAILED';

export const COMMENT_UPDATE = 'COMMENT_UPDATE';
export const COMMENT_UPDATE_REQUEST = 'COMMENT_UPDATE_REQUEST';
export const COMMENT_UPDATE_SUCCESS = 'COMMENT_UPDATE_SUCCESS';
export const COMMENT_UPDATE_FAILED = 'COMMENT_UPDATE_FAILED';

//Handle EMOTION
export const EMOTION_REQUEST = 'EMOTION_REQUEST';
export const EMOTION_SUCCESS = 'EMOTION_SUCCESS';
export const EMOTION_FAILED = 'EMOTION_FAILED';


//Handle Search task
export const SEARCH_TASK_REQUEST = 'SEARCH_TASK_REQUEST';
export const SEARCH_TASK_SUCCESS = 'SEARCH_TASK_SUCCESS';
export const SEARCH_TASK_FAILED = 'SEARCH_TASK_FAILED';
//Handle update relation
export const UPDATE_RELATION_REQUEST = 'UPDATE_RELATION_REQUEST';
export const UPDATE_RELATION_SUCCESS = 'UPDATE_RELATION_SUCCESS';
export const UPDATE_RELATION_FAILED = 'UPDATE_RELATION_FAILED';
//Handle get relation
export const GET_RELATION_REQUEST = 'GET_RELATION_REQUEST';
export const GET_RELATION_SUCCESS = 'GET_RELATION_SUCCESS';
export const GET_RELATION_FAILED = 'GET_RELATION_FAILED';
