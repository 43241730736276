import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getPharmacyOverViewReport() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyOverViewReport);
    yield put({type: Types.GET_PHARMACY_OVER_VIEW_REPORT_SUCCESS, payload: data});
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_OVER_VIEW_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getPharmacyYearlyReport() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyYearlyReport);
    yield put({type: Types.GET_PHARMACY_YEARLY_REPORT_SUCCESS, payload: data});
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_YEARLY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getPharmacyQuarterlyReport() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyQuarterlyReport);
    yield put({type: Types.GET_PHARMACY_QUARTERLY_REPORT_SUCCESS, payload: data});
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_QUARTERLY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getPharmacyMonthlyReport() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyMonthlyReport);
    yield put({
      type: Types.GET_PHARMACY_MONTHLY_REPORT_SUCCESS, 
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_MONTHLY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getPharmacyDailyReport() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyDailyReport);
    yield put({
      type: Types.GET_PHARMACY_DAILY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_DAILY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getPharmacyMostCustomerOrder() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyMostCustomerOrderReport);
    yield put({
      type: Types.GET_PHARMACY_MOST_CUSTOMER_ORDER_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_MOST_CUSTOMER_ORDER_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getPharmacyOverviewToday() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyOverviewToday);
    yield put({
      type: Types.GET_PHARMACY_OVERVIEW_TODAY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_OVERVIEW_TODAY_FAILED,
      payload: error.message
    });
  }
}

function* getPharmacyOverviewDaily() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyOverviewDaily);
    yield put({
      type: Types.GET_PHARMACY_OVERVIEW_DAILY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_OVERVIEW_DAILY_FAILED,
      payload: error.message
    });
  }
}

function* getPharmacyOverviewMonthly() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyOverviewMonthly);
    yield put({
      type: Types.GET_PHARMACY_OVERVIEW_MONTHLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_OVERVIEW_MONTHLY_FAILED,
      payload: error.message
    });
  }
}

function* getPharmacyOverviewQuarterly() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyOverviewQuarterly);
    yield put({
      type: Types.GET_PHARMACY_OVERVIEW_QUARTERLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_OVERVIEW_QUARTERLY_FAILED,
      payload: error.message
    });
  }
}

function* getPharmacyOverviewYearly() {
  try {
    const data = yield call(Api.pharmacyOnlineDashboard.getPharmacyOverviewYearly);
    yield put({
      type: Types.GET_PHARMACY_OVERVIEW_YEARLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_PHARMACY_OVERVIEW_YEARLY_FAILED,
      payload: error.message
    });
  }
}

export default function* pharmacyOnlineDashboard() {
  yield takeLatest(Types.GET_PHARMACY_OVER_VIEW_REPORT_REQUEST, getPharmacyOverViewReport);
  yield takeLatest(Types.GET_PHARMACY_YEARLY_REPORT_REQUEST, getPharmacyYearlyReport);
  yield takeLatest(Types.GET_PHARMACY_QUARTERLY_REPORT_REQUEST, getPharmacyQuarterlyReport);
  yield takeLatest(Types.GET_PHARMACY_MONTHLY_REPORT_REQUEST, getPharmacyMonthlyReport);
  yield takeLatest(Types.GET_PHARMACY_DAILY_REPORT_REQUEST, getPharmacyDailyReport);
  yield takeLatest(Types.GET_PHARMACY_MOST_CUSTOMER_ORDER_REPORT_REQUEST, getPharmacyMostCustomerOrder);
  yield takeLatest(Types.GET_PHARMACY_OVERVIEW_TODAY_REQUEST, getPharmacyOverviewToday);
  yield takeLatest(Types.GET_PHARMACY_OVERVIEW_DAILY_REQUEST, getPharmacyOverviewDaily);
  yield takeLatest(Types.GET_PHARMACY_OVERVIEW_MONTHLY_REQUEST, getPharmacyOverviewMonthly);
  yield takeLatest(Types.GET_PHARMACY_OVERVIEW_QUARTERLY_REQUEST, getPharmacyOverviewQuarterly);
  yield takeLatest(Types.GET_PHARMACY_OVERVIEW_YEARLY_REQUEST, getPharmacyOverviewYearly);
}
