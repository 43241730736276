import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getUiClients({ payload: query }) {
    try {
      const response = yield call(Api.uiClient.getUiClients, query);
      yield put({ type: Types.GET_UI_CLIENTS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_UI_CLIENTS_FAILED, payload: error });
    }
  }
  function* updateUiClient(action) {
    try {
      const data = yield call(Api.uiClient.updateUiClient, action.payload);
      yield put({ type: Types.UPDATE_UI_CLIENT_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_UI_CLIENT_FAILED, payload: error });
    }
  }
export default function* UiClients() {
    yield takeLatest(Types.GET_UI_CLIENTS_REQUEST, getUiClients);
    yield takeLatest(Types.UPDATE_UI_CLIENT_REQUEST, updateUiClient);
  }
