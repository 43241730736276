import requester from './requester';

const WhMedicine = {
  create: (course) => requester.post('/medical-equipment', course),
  update: (query) => {
    return requester.put(`/medical-equipment/${query.id}`,query)
  },
  delete: (id) => requester.delete(`/medical-equipment/${id}`),
  getAll: (query) => requester.get('/medical-equipment', query),
  getOne: (id) => requester.get(`/medical-equipment/${id}`),
  createReportMedicines :  (medicine) => requester.post('/partner-medical-equipment', medicine),
  getReportMedicines :  (query) => requester.get('/partner-medical-equipment', query),
  getOneReportMedicines :  (id) => requester.get(`/partner-medical-equipment/${id}`),
  editReportMedicines :  (medicine) => requester.put(`/partner-medical-equipment/${medicine._id}`, medicine),
  deleteReportMedicines :  (id) => requester.delete(`/partner-medical-equipment${id}`),
};
export default WhMedicine;

