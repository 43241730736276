export const GET_SERVICES_BY_LOCATION_REQUEST = 'GET_SERVICES_BY_LOCATION_REQUEST';
export const GET_SERVICES_BY_LOCATION_SUCCESS = 'GET_SERVICES_BY_LOCATION_SUCCESS';
export const GET_SERVICES_BY_LOCATION_FAILED = 'GET_SERVICES_BY_LOCATION_FAILED';

export const GET_SERVICE_BY_LOCATION_REQUEST = 'GET_SERVICE_BY_LOCATION_REQUEST';
export const GET_SERVICE_BY_LOCATION_SUCCESS = 'GET_SERVICE_BY_LOCATION_SUCCESS';
export const GET_SERVICE_BY_LOCATION_FAILED = 'GET_SERVICE_BY_LOCATION_FAILED';

export const CREATE_SERVICE_BY_LOCATION_REQUEST = 'CREATE_SERVICE_BY_LOCATION_REQUEST';
export const CREATE_SERVICE_BY_LOCATION_SUCCESS = 'CREATE_SERVICE_BY_LOCATION_SUCCESS';
export const CREATE_SERVICE_BY_LOCATION_FAILED = 'CREATE_SERVICE_BY_LOCATION_FAILED';

export const UPDATE_SERVICE_BY_LOCATION_REQUEST = 'UPDATE_SERVICE_BY_LOCATION_REQUEST';
export const UPDATE_SERVICE_BY_LOCATION_SUCCESS = 'UPDATE_SERVICE_BY_LOCATION_SUCCESS';
export const UPDATE_SERVICE_BY_LOCATION_FAILED = 'UPDATE_SERVICE_BY_LOCATION_FAILED';

export const DELETE_SERVICE_BY_LOCATION_REQUEST = 'DELETE_SERVICE_BY_LOCATION_REQUEST';
export const DELETE_SERVICE_BY_LOCATION_SUCCESS = 'DELETE_SERVICE_BY_LOCATION_SUCCESS';
export const DELETE_SERVICE_BY_LOCATION_FAILED = 'DELETE_SERVICE_BY_LOCATION_FAILED';

export const RESET_SERVICE_BY_LOCATION_STATE = 'RESET_SERVICE_BY_LOCATION_STATE';
