import React, { useEffect, useState } from 'react';

import { Col, Form, Input, Space, Select, Popconfirm } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

import UploadImage from './BannerUploadImg';
import './index.scss';
import { useWhBanners } from '~/hooks';
import { WithOrPermission } from '../Common';
import POLICIES from '~/constants/policy';

const BannerAddImage = ({
  image,
  form,
  index,
  item,
  type,
  remove,
  deleteItemBanner,
  handleChangeImage,
  handleChangeType,
  setWhServiceQuestionOptionImg,
  disabled
}) => {
  const [valueSelect, setValueSelect] = useState(type ?? 'Web');
  const handleChangeSelect = (value) => {
    setValueSelect(value);
  };
  return (
    <Col className={'wh-service-form__question--option '} sm={12} lg={6}>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <UploadImage
          disabled={disabled}
          style={{ width: '100%' }}
          title="Hình ảnh"
          imgUrl={image && image}
          onChange={(url) => {
            setWhServiceQuestionOptionImg(url, valueSelect, index);
          }}
        >
          <WithOrPermission permission={[POLICIES.DELETE_BANNER]}>
          <MinusCircleOutlined
            onClick={(e) => {
              e.stopPropagation();
              remove(index);
              deleteItemBanner(index);
              return;
            }}
            style={{ position: 'absolute', right: 5, top: 5 }}
          />
          </WithOrPermission>
        </UploadImage>
        <div>
          <Select
            // defaultValue={'Web'}
            disabled={disabled}
            value={valueSelect}
            style={{
              width: 120
            }}
            onChange={(value) => {
              handleChangeSelect(value);
              handleChangeType(index, value);
            }}
            options={[
              {
                value: 'Web',
                label: 'Web'
              },
              {
                value: 'Mobile',
                label: 'Mobile'
              }
            ]}
          />
        </div>
      </Space>
    </Col>
  );
};

export default BannerAddImage;
