import { Fragment, useMemo, useState } from 'react';

import { SnippetsFilled } from '@ant-design/icons';
import { Col, Divider, Row, Space } from 'antd';
import { get, groupBy } from 'lodash';

import { COMPONENT_MODES, MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { useWhAppointments } from '~/hooks/whAppointment';
import { useWhService } from '~/hooks/whService';

import WhAppointmentsByBlockTable from '~/components/WhAppointment/WhAppointmentsByBlockTable/index';
import WhBillItemCategoryInfo from '~/components/WhBillItem/WhBillItemCategoryInfo';
import WhBillItemCustomerInfo from '~/components/WhBillItem/WhBillItemCustomerInfo';
import WhBillItemServiceInfo from '~/components/WhBillItem/WhBillItemServiceInfo';

const WhPartnerBillItemView = ({
  whBillItem,
  whPackageLevels,
  whPartner,
  whSessionsOfDay,
}) => {
  const [query, setQuery] = useState({
    // status: "NEW", // FIXME: 
    whPartnerId: whPartner.id,
    limit: MAX_PAGINATION_LIMIT,
    page: 1,
  })
  const [whAppointments, isWhAppointmentsLoading] = useWhAppointments(query);
  const [whService, isGetWhServiceLoading] = useWhService(get(whBillItem, "whServiceId"));

  const mergedWhAppointments = useMemo(() => {
    return whAppointments.map(item => ({
      ...item,
      whPackageLevel: whPackageLevels?.find(wPL => item.whPackageLevelId === wPL._id),
      whSessionOfDay: whSessionsOfDay?.find(wSD => item.whSessionOfDayId === wSD._id),
    }))
  }, [whAppointments, whPackageLevels, whSessionsOfDay]);

  const blocks = useMemo(() => {
    return groupBy(mergedWhAppointments, 'blockNumber')
  }, [mergedWhAppointments]);

  return (
    <div className=''>
      <Space direction='vertical' size="large" style={{ width: "100%" }}>
        <Row className='wh-service-detail__row'>
          <Col span={16}>
            <Space direction='vertical' size="small" style={{ width: "100%" }}>
              <WhBillItemServiceInfo whBillItem={whBillItem} />
              <WhBillItemCustomerInfo whBillItem={whBillItem} />
            </Space>
          </Col>

          <Col span={8}>
            <Space direction='vertical' size='small' style={{ width: "100%" }}>
              <WhBillItemCategoryInfo whBillItem={whBillItem} />
              <Row justify='end' align='middle'>
                <Space>
                  <p className='' style={{ fontWeight: "bold" }}>{get(whPartner, "name")}</p>
                </Space>
              </Row>
              <Row justify='end' align='top'>
                <Col span={3}>
                  <SnippetsFilled color='#3481FF' style={{ fontSize: '24px', color: '#3481FF' }} />
                </Col>
                <Col span={21}>
                  <Divider plain style={{ marginTop: 0 }}>
                    Khai báo tình trạng sức khỏe
                  </Divider>
                  <div
                    className='wh-service-detail__description--paragraph'
                  >
                    {/* <p>{get(whService, "questions.0.title.vi")}</p> */}
                    <p>{get(whBillItem, "answers.0.title.vi")}</p>
                    {
                      !get(whBillItem, "answers") ||
                      !get(whBillItem, "answers").length
                        ? <p>(chưa có câu trả lời)</p>
                        : get(whBillItem, "answers.0.options").map(item => {
                          return <p>- {get(item, "title.vi")}</p>
                        })
                    }
                  </div>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>

        <Row className='wh-service-detail__row'>
          <Col span={24}>
            <Space direction='vertical' style={{ width: "100%" }} size="small">
              {Object.keys(blocks).map((blockNumber, index) => (
                <Fragment key={index}>
                  <h3>Block {blockNumber}</h3>
                  <WhAppointmentsByBlockTable
                    mode={COMPONENT_MODES.VIEW}
                    dataSource={blocks[blockNumber]}
                  />
                </Fragment>
              ))}
            </Space>
          </Col>
        </Row>
      </Space>
    </div>
  )
}

export default WhPartnerBillItemView;
