import produce from 'immer';
import * as Types from '../../constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: [],
  getByIdFailed: undefined,

  paging: {},
  pagingDetailLocation: {},

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_SERVICES_BY_LOCATION_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_SERVICES_BY_LOCATION_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_SERVICES_BY_LOCATION_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_SERVICE_BY_LOCATION_REQUEST:
      state.isGetByIdLoading = true;
      // state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_SERVICE_BY_LOCATION_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload.docs;
      state.pagingDetailLocation = getPaging(payload);
      return;

    case Types.GET_SERVICE_BY_LOCATION_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      state.byId = [];
      return;

    case Types.CREATE_SERVICE_BY_LOCATION_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_SERVICE_BY_LOCATION_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      // state.list = state.list.concat(payload);
      // state.list = [payload, ...state.list];
      return;

    case Types.CREATE_SERVICE_BY_LOCATION_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_SERVICE_BY_LOCATION_REQUEST:
      state.isSubmitLoading = true;
      state.byId = payload;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_SERVICE_BY_LOCATION_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      state.list = state.list.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload };
        }
        return item;
      });
      return;

    case Types.UPDATE_SERVICE_BY_LOCATION_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_SERVICE_BY_LOCATION_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_SERVICE_BY_LOCATION_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_SERVICE_BY_LOCATION_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_SERVICE_BY_LOCATION_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
