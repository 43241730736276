import { Col, Descriptions, Row } from 'antd';
import { get, head } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import TableDidService from '~/components/WhReport/TableDidService';
import TableExtraIncome from '~/components/WhReport/TableExtraIncome';
import TableIntroCustomer from '~/components/WhReport/TableIntroCustomer';
import TableIntroducePartner from '~/components/WhReport/TableIntroducePartner';
import TableKpi from '~/components/WhReport/TableKpi';
import TableMedicines from '~/components/WhReport/TableMedicines';
import TableTipsPartner from '~/components/WhReport/TableTipsPartner';
import TableTraningSystem from '~/components/WhReport/TableTraningSystem';
import TableWarehouse from '~/components/WhReport/TableWarehouse';
import {
  WH_PARTNER_WAY_WORKING
} from '~/constants/defaultValue';
import { formatNumberThreeComma } from '~/hooks/utils';
const descriptionItemProps = {
  contentStyle: {
    fontWeight: 'bold',
    textAlign: 'right',
    wordBreak:'initial'
  },
  labelStyle: {
    fontWeight: '500'
  }
};

export default function AllTableFulltimeOrParttime({ id, date, mode,type,partner,filterDateBy,dataPartnerSnapShort }) {
  const [totalDetail, setTotalDetail] = useState({
    didService: 0, // this state not render exactly
    introPartner: 0,
    trainingSystem: 0,
    extraIncome: 0,
    kpi: 0,
    medicines:0,
    introCustomer : 0,
    warehouse : 0
  });
  const [didService,setDidService] = useState(0) // i create this state to resolve render totalDetail not render exacly
  useEffect(() => {
  return  () => setTotalDetail({
      didService: 0,
      introPartner: 0,
      trainingSystem: 0,
      extraIncome: 0,
      kpi: 0,
      medicines:0,
      introCustomer : 0,
      warehouse : 0
    })
  },[date,dataPartnerSnapShort,id])
  // const [totalDidService, setTotalAllDidService] = useState(0);
  const setTotalDetailAll = (key, value) =>
   {
      setTotalDetail({ ...totalDetail, [key]: value });
      if(key === 'didService'){
        setDidService(value)
      }
   }
  // const setTotalDidService = (value) => setTotalAllDidService(value);
   const isHaveFulltime = dataPartnerSnapShort?.some(e => get(e,'partnerSnapshot.wayWorking') === WH_PARTNER_WAY_WORKING.fulltime)
  const checkIsFullTimeAndInMonth = (component) => filterDateBy !== 'month' 
  ? component
  : moment(get(date,'startDate')).format("MM-YYYY") === moment().format("MM-YYYY") && type === WH_PARTNER_WAY_WORKING.fulltime
  ?  component
   : isHaveFulltime ? component : null
  
  return (
    <div>
      <TableDidService
        date={date}
        setTotalDetailAll={setTotalDetailAll}
        type={type}
        // setTotalDidService={setTotalDidService}
        id={id}
        dataPartnerSnapShort={dataPartnerSnapShort}
      />
      <TableIntroducePartner
        date={date}
        setTotalDetailAll={setTotalDetailAll}
        id={id}
      />
      <TableTraningSystem
        date={date}
        setTotalDetailAll={setTotalDetailAll}
        id={id}
      />
      {/* <TableExtraIncome
       date={date}
        setTotalDetailAll={setTotalDetailAll}
        id={id}
        mode={mode}
      /> */}
      <TableTipsPartner  date={date}
        setTotalDetailAll={setTotalDetailAll}
        id={id}
        mode={mode}/>
      <TableIntroCustomer keyTotal = 'introCustomer'  setTotalDetailAll={setTotalDetailAll} mode={mode} id={id} date={date} type={type} />
      {/* {type === WH_PARTNER_WAY_WORKING.fulltime ? <TableKpi
        setTotalDetailAll={setTotalDetailAll}
        totalDidService={totalDidService}
        date={date}
        id={id}
        partnerSnapShort={partnerSnapShort}
      /> :null} */}
     {checkIsFullTimeAndInMonth(<TableKpi
        setTotalDetailAll={setTotalDetailAll}
        // totalDidService={totalDidService}
        date={date}
        id={id}
        dataPartnerSnapShort={dataPartnerSnapShort}
        partner={partner}
        filterDateBy={filterDateBy}
      />)}
      <TableMedicines
        partner={partner}
        date={date}
        setTotalDetailAll={setTotalDetailAll}
        id={id}
        mode={mode}/>

      <TableWarehouse
        date={date}
        setTotalDetailAll={setTotalDetailAll}
        id={id}
        />

      <div>
        <h5 style={{ alignSelf: 'start' }}>Chi tiết thu nhập</h5>
        <Row style={{ width: '100%', margin: '10px 0' }} align="bottom">
          <Col span={24}>
            <Descriptions bordered column={1}>
            
            {/* {type === WH_PARTNER_WAY_WORKING.parttime ?  <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập theo số lần thực hiện dịch vụ (A)"
              >
                {formatNumberThreeComma(get(totalDetail,'didService',0).toFixed(0))}
              </Descriptions.Item> :
             null} */}
             <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập theo số lần thực hiện dịch vụ (A)"
              >
                {formatNumberThreeComma(didService.toFixed(0))}
              </Descriptions.Item>
           



              <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập theo giới thiệu đối tác (B)"
              >
                {formatNumberThreeComma(get(totalDetail,'introPartner',0))}
              </Descriptions.Item>
              <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập từ đào tạo hệ thống (C)"
              >
                {formatNumberThreeComma(get(totalDetail,'trainingSystem',0))}
              </Descriptions.Item>
              <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập thêm/giảm: thưởng/phạt (D)"
              >
                {formatNumberThreeComma(get(totalDetail,'extraIncome',0))}
              </Descriptions.Item>
              <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập theo giới thiệu khách hàng (E)"
              >
                {formatNumberThreeComma(get(totalDetail,'introCustomer',0))}
              </Descriptions.Item>
              {/* {type === WH_PARTNER_WAY_WORKING.fulltime ? <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập theo KPI (F)"
              >
                {formatNumberThreeComma(totalDetail.kpi)}
              </Descriptions.Item> :
             null} */}
             {/* {filterDateBy !== 'month' 
             ? <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập theo KPI (F)"
              >
                {formatNumberThreeComma(totalDetail.kpi)}
              </Descriptions.Item> 
             : moment(get(date,'startDate')).format("MM-YYYY") === moment().format("MM-YYYY") && type === WH_PARTNER_WAY_WORKING.fulltime
             ?  <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập theo KPI (F)"
              >
                {formatNumberThreeComma(totalDetail.kpi)}
              </Descriptions.Item> 
              : isHaveFulltime ? <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập theo KPI (F)"
              >
                {formatNumberThreeComma(totalDetail.kpi)}
              </Descriptions.Item>  : null
             } */}
             {checkIsFullTimeAndInMonth(<Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập theo KPI (F)"
              >
                {formatNumberThreeComma(get(totalDetail,'kpi',0))}
              </Descriptions.Item> )}
             <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập bán thiết bị y tế (H)"
              >
                {formatNumberThreeComma(get(totalDetail,'medicines',0))}
              </Descriptions.Item>
             <Descriptions.Item
                {...descriptionItemProps}
                label="Thu nhập sử dụng dụng cụ kho (I)"
              >
                {formatNumberThreeComma(get(totalDetail,'warehouse',0))}
              </Descriptions.Item>
              <Descriptions.Item
                {...descriptionItemProps}
                label={
                  <div>
                    <code style={{fontSize : '1.5rem'}}>Tổng thu nhập</code>
                  </div>
                }
              >
                <code style={{fontSize : '1.5rem'}}>
                  {/* {type === WH_PARTNER_WAY_WORKING.fulltime ? formatNumberThreeComma(
                    get(totalDetail,'kpi',0)+
                      get(totalDetail,'extraIncome',0) +
                      get(totalDetail,'introPartner',0) +
                      get(totalDetail,'trainingSystem',0) + 
                      get(totalDetail,'medicines',0) 
                  ) : formatNumberThreeComma(
                    (get(totalDetail,'didService',0) +
                    get(totalDetail,'extraIncome',0) +
                      get(totalDetail,'introPartner',0) +
                      get(totalDetail,'trainingSystem',0) +
                      get(totalDetail,'medicines',0) 
                      ).toFixed(0)
                  )} */}
                  {formatNumberThreeComma(
                    ( get(totalDetail,'kpi',0) +
                    didService +
                    get(totalDetail,'extraIncome',0) +
                      get(totalDetail,'introPartner',0) +
                      get(totalDetail,'trainingSystem',0) +
                      get(totalDetail,'medicines',0) +
                      get(totalDetail,'introCustomer',0) 
                    +  get(totalDetail,'warehouse',0) 
                      ).toFixed(0)
                  )}
                </code>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </div>
    </div>
  );
}
