import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getMedicalEquipments,
  createMedicalEquipment,
  deleteMedicalEquipment,
  getMedicalEquipment,
  resetMedicalEquipmentState,
  updateMedicalEquipment,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const MEDICAL_EQUIPMENT = 'medicalEquipment';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(MEDICAL_EQUIPMENT);

const getSelector = (key) => (state) => state[MEDICAL_EQUIPMENT][key];

const pagingSelector = getSelector('paging');


export const useMedicalEquipmentQueryParams = (module) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const name = query.get('name');
  const page = query.get('page')|| 1;
  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      name,
    });

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    name,
    createSuccess,
    updateSuccess,
    deleteSuccess
  ]);
};

export const useUpdateMedicalEquipmentParams = (query) => {
  const {path, url} = useRouteMatch()
  const history = useHistory();
  const [keyword, setKeyword] = useState(query?.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    
    history.push({
      pathname: `${path}`,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};


export const useMedicalEquipmentPaging = () => useSelector(pagingSelector);

export const useMedicalEquipments = (query) => {
  return useFetchByParam({
    action: getMedicalEquipments,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateMedicalEquipment = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới thiết bị y tế thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới thiết bị y tế thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createMedicalEquipment
  });
};

export const useUpdateMedicalEquipment = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật thiết bị y tế thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật thiết bị y tế thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateMedicalEquipment
  });
};

export const useDeleteMedicalEquipment = () => {
  useSuccess(deleteSuccessSelector);
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector,
    action: deleteMedicalEquipment
  });
};

export const useMedicalEquipment = (params) => {
  return useFetchByParam({
    action: getMedicalEquipment,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useResetMedicalEquipment = () => {
  useResetState(resetMedicalEquipmentState);
};
