import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import toastr from 'toastr';
import { Button, Col, DatePicker, Descriptions, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { find, get, merge, omit } from 'lodash';
import { useHistory } from 'react-router-dom';

import api from '~/api';
import { COMPONENT_MODES, INSTANCE_STATUSES, PLACE_DID_SERVICE, WH_BILL_ITEM_STATUS } from '~/constants/defaultValue';
import { useUpdateBillItemOfBill, useUpdateBillItemOfBillStatus, useWhService } from '~/hooks';
import { getPackageLevelOfService, getSessionPrice } from '../WhService/utils';
import { fromJSON } from './parser';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { filterAcrossAccents, getNamePlaceDidService } from '~/hooks/utils';

const descriptionItemProps = {
  contentStyle: {
    fontWeight: "bold",
    textAlign: "right",
  },
  labelStyle : {
    maxWidth : 200
  }
}

const WhBillItemModal = ({
  onCancel,
  visible,
  whBillItem, // whBillItem with `whSessionOfDay` included,
  whBillItemIdx,
  whSessionsOfDay,
  whPackageLevels,
  isCitiesLoading,
  cities,
  servicePlaces,
  isLoadingPlace
}) => {
  const optionPlace = useMemo(() => {
    return servicePlaces?.map(e => ({
      label : get(e,'name.vi'),
      value : get(e,'_id')
    }))
  },[servicePlaces])
  const viewCity  = useMemo(() => {
    const findOneCity = cities?.find(e => get(e,'code') === get(whBillItem,'hospitalId.cityId'))
    return get(findOneCity,'name','')
  },[whBillItem,cities])
  const isEditable = useMatchPolicy(POLICY.UPDATE_WHBILL);
  
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [selectCity,setSelectCity] = useState(null);
  const [optionHospital,setOptionHospital] = useState([]);
  const [isAtHospital,setIsAtHospital] = useState(false)
  const [loadingGetHospitals,setLoadingGetHospitals] = useState(false)
  const history = useHistory();

  const [isUpdatingWhBillItem, handleUpdateBillItem] = useUpdateBillItemOfBill();
  const [updateBillItemOfBillSuccess, updateBillItemOfBillFailed] = useUpdateBillItemOfBillStatus();

  const [isConfirmingWhBillItem, setIsConfirmingWhBillItem] = useState(false);
  // const [isUpdatingWhBillItem, setIsUpdatingWhBillItem] = useState(false);
  // const [whService, isGetWhServiceLoading] = useWhService(get(whBillItem, "whServiceId"));
  const isCSNB = get(whBillItem, "placeDidService")
  const [whService, setWhService] = useState();
  const [isWhServiceLoading, setIsWhServiceLoading] = useState(false);

  const [sessionPrice, setSessionPrice] = useState();
  useEffect(() => {
    const fetch = async() => {
     try {
      setLoadingGetHospitals(true)
      const res = await api.hospitalList.getByAddress({addressHospital : selectCity ,healthcareFacilityId : form.getFieldValue(['placeDidService']) })
      if(res){
        const options = res?.map(e => ({
          value : get(e,'_id'),
          label : `${get(e,'nameHealthCare.vi','')} - ${get(e,'street','')}`
        }))
       setOptionHospital(options)
      }
      setLoadingGetHospitals(false)
     } catch (error) {
      console.log(error,'error');
      setLoadingGetHospitals(false)
     }
    }
    selectCity && fetch()
  },[whBillItem,selectCity])
  const mode = useMemo(() => {
    return get(whBillItem, "status") === WH_BILL_ITEM_STATUS.NEW && isEditable
      ? COMPONENT_MODES.EDIT
      : COMPONENT_MODES.VIEW
  }, [whBillItem]);

  useEffect(() => {
    const getWhService = async whServiceId => {
      setIsWhServiceLoading(true);
      setWhService(null);
      const whService = await api.whService.getById(whServiceId);
      setWhService(whService);
      setIsWhServiceLoading(false);
    }
    if (whBillItem) {
      if(get(whBillItem,'placeDidService')){
        form.setFieldsValue({placeDidService : get(whBillItem,'placeDidService._id'),hospitalId : get(whBillItem,'hospitalId._id','')})
        if(get(whBillItem,'placeDidService.slug','') !== PLACE_DID_SERVICE.home.value){
          setSelectCity(get(whBillItem,'hospitalId.cityId'))
          setIsAtHospital(true)
        }
        else{
          setSelectCity(null)
          setIsAtHospital(false)
        }
      
      }
      form.setFieldsValue(fromJSON(omit(whBillItem,['placeDidService','hospitalId'])));

      getWhService(get(whBillItem, "whServiceId"))
    };
  }, [whBillItem,cities]);

  useEffect(() => {
    if (
      whBillItem &&
      whService &&
      get(whBillItem, "whServiceId") === get(whService, "_id")
    ) {
      const { whPackageLevelId, whSessionOfDayId } = whBillItem;
      setSessionPrice(getSessionPrice(whService, whPackageLevelId, whSessionOfDayId));
    }
  }, [
    whBillItem,
    whService
  ]);

  useEffect(() => {
    if (!isUpdatingWhBillItem && updateBillItemOfBillSuccess) {
      setIsDirty(false);
    }
  }, [isUpdatingWhBillItem, updateBillItemOfBillSuccess])

  const onValuesChange = (changes) => {
    setIsDirty(true);
    const { whPackageLevelId, whSessionOfDayId,placeDidService } = changes;
    if(placeDidService){
      const findPlace = servicePlaces?.find(e => e._id === placeDidService)
      if(get(findPlace,'slug','') === PLACE_DID_SERVICE.home.value){
        setIsAtHospital(false)
        setSelectCity(null)
        form.setFieldsValue({hospitalId : null})
      }
      else{
        setIsAtHospital(true)
        form.setFieldsValue({hospitalId : null})
      }
    }

    const whPackageLevel = getPackageLevelOfService(whService, whPackageLevelId || form.getFieldValue('whPackageLevelId'));

    const availableWhSessionsOfDay = get(
      whPackageLevel,
      "sessionPrices"
    )
      ?.filter(item => get(item, "status") === INSTANCE_STATUSES.ACTIVE);

    const validWhSessionOfDayId = find(
      availableWhSessionsOfDay,
      { whSessionOfDayId: whSessionOfDayId || form.getFieldValue('whSessionOfDayId') }
    )
      ? (whSessionOfDayId || form.getFieldValue('whSessionOfDayId'))
      : null

      if (validWhSessionOfDayId !== null) {
      const sessionPrice = getSessionPrice(
        whService,
        whPackageLevelId || form.getFieldValue('whPackageLevelId'),
        validWhSessionOfDayId,
      )
      setSessionPrice(sessionPrice);
    } else {
      form.setFieldsValue({ whSessionOfDayId: null });
    }
  }

  const onFinish = async values => {
    const { whPackageLevelId, whSessionOfDayId } = values;

    const whPackageLevel = getPackageLevelOfService(whService, whPackageLevelId);
    const whPackageId = get(whPackageLevel, "_id")
    const whSessionPriceItemId = get(sessionPrice, "_id")

    try {
      // setIsUpdatingWhBillItem(true);
      // const response = await api.whBillItem.update({
      //   ...values,
      //   whPackageId,
      //   whSessionPriceItemId,
      //   whBillId: get(whBillItem, "whBillId"),
      //   _id: get(whBillItem, "_id"),
      // });
      // console.log("response...");
      // console.log(response);
      // const updatedWhBillItem = { ...whBillItem, ...response };
      const submitData = {
        ...values,
        whPackageId,
        whSessionPriceItemId,
        whBillId: get(whBillItem, "whBillId"),
        _id: get(whBillItem, "_id"),
      }
      handleUpdateBillItem(submitData)
    } catch (error) {
      console.error(error);
    } finally {
      // setIsUpdatingWhBillItem(false);
    }

  }
  const handleChangeCity = (value) => {
    setIsDirty(true)
    setSelectCity(value)
  }
  const navigateToUpdateWhAppointmentsPage = () => {
    history.push(`/wh-bill-item/${get(whBillItem, "_id")}/update-appointments`)
  }

  const onConfirmWhBillItem = async () => {
    if (get(whBillItem, "status") === WH_BILL_ITEM_STATUS.NEW) {
      setIsConfirmingWhBillItem(true);
      try {
        const response = await api.whBillItem.confirm(whBillItem);
        navigateToUpdateWhAppointmentsPage();
      } catch (error) {
        console.error(error?.response?.data?.message);
        toastr.error(error?.response?.data?.message);
      } finally {
        setIsConfirmingWhBillItem(false);
      }
    } else {
      navigateToUpdateWhAppointmentsPage()
    }
  }
  const nameService = (whBillItem) =>{
    if (get(whBillItem, "snapshotService.detailName.vi"))
          return `${get(whBillItem, "snapshotService.name.vi")} - ${get(whBillItem, "snapshotService.detailName.vi")}`
        else 
          return get(whBillItem, "snapshotService.name.vi")
  }
  return (
    <Modal
      confirmLoading={isConfirmingWhBillItem}
      destroyOnClose
      okText={"Xử lý"}
      onCancel={onCancel}
      onOk={onConfirmWhBillItem}
      title={"Tùy chọn đặt dịch vụ"}
      visible={visible}
      width='max-content'
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Hủy
        </Button>,
        mode === COMPONENT_MODES.EDIT && <WithPermission permission={POLICY.UPDATE_WHBILL}>
          <Button
            key="save"
            type="primary"
            disabled={!isDirty}
            loading={isUpdatingWhBillItem}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </WithPermission>,
        mode === COMPONENT_MODES.EDIT && <WithPermission permission={POLICY.UPDATE_WHBILL}>
          <Button
            key="ok"
            type="primary"
            disabled={isDirty}
            loading={isConfirmingWhBillItem}
            onClick={onConfirmWhBillItem}
          >
            Xử lý
          </Button>
          </WithPermission>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Form
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
          >
          <Descriptions size='small' bordered column={1}>
            <Descriptions.Item
              label="Dịch vụ"
              {...descriptionItemProps}
            >
              {nameService(whBillItem)}
            </Descriptions.Item>

            <Descriptions.Item
              label="Gói dịch vụ"
              {...descriptionItemProps}
            >
              {
                {
                  [COMPONENT_MODES.VIEW]: get(whBillItem, "snapshotService.package.packageLevelName.vi"),
                  [COMPONENT_MODES.EDIT]: (
                    <Form.Item name="whPackageLevelId">
                      <Select>
                        {get(whService, 'packages')?.map((item, index) => (
                          <Select.Option
                            key={index}
                            value={get(item, "whPackageLevelId")}
                          >
                            {get(item, 'whPackageLevel.name.vi')}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }[mode]
              }
            </Descriptions.Item>

            <Descriptions.Item
              label="Thời gian thực hiện"
              {...descriptionItemProps}
            >
              {
                {
                  [COMPONENT_MODES.VIEW]: get(whBillItem, "whSessionOfDay.name.vi"),
                  [COMPONENT_MODES.EDIT]: (
                    <Form.Item noStyle shouldUpdate>
                      {() => {
                        const whPackageLevel = getPackageLevelOfService(whService, form.getFieldValue('whPackageLevelId'));
                        const sessionPrices = get(whPackageLevel, "sessionPrices");
                        return (
                          <Form.Item
                            name="whSessionOfDayId"
                          >
                            <Select>
                              {sessionPrices?.map((item, index) => (
                                <Select.Option
                                  key={index}
                                  value={get(item, 'whSessionOfDayId')}
                                >
                                  {get(item, 'whSessionOfDay.name')}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )
                      }}
                    </Form.Item>
                  )
                }[mode]
              }
            </Descriptions.Item>

           {isCSNB &&  <Descriptions.Item
              label="Nơi thực hiện dịch vụ"
              {...descriptionItemProps}
            >
              {
                {
                  [COMPONENT_MODES.VIEW]: get(whBillItem,'placeDidService.name.vi',''),
                  [COMPONENT_MODES.EDIT]: (
                    <Form.Item
                      name="placeDidService"
                    >
                      <Select loading={isLoadingPlace} options={optionPlace}/>
                    </Form.Item>
                  )
                }[mode]
              }
            </Descriptions.Item>}
           {isCSNB && isAtHospital && 
           <>
           <Descriptions.Item
              label="Tỉnh, thành phố"
              {...descriptionItemProps}
            >
              {
                {
                  [COMPONENT_MODES.VIEW]: viewCity,
                  [COMPONENT_MODES.EDIT]: (
                    <Select
                    value={selectCity}
                    style={{width : '100%'}}
                onChange={handleChangeCity}
                disabled={isCitiesLoading}
                loading={isCitiesLoading}
                showSearch
                autoComplete="disabled"
                filterOption={filterAcrossAccents}
              >
                {cities.map(({ code, name }) => (
                  <Select.Option key={code} value={code}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
                  )
                }[mode]
              }
            </Descriptions.Item>
            <Descriptions.Item
              label="Bệnh viện"
              {...descriptionItemProps}
            >
              {
                {
                  [COMPONENT_MODES.VIEW]: `${get(whBillItem,'hospitalId.nameHealthCare.vi')} - ${get(whBillItem,'hospitalId.street')} `,
                  [COMPONENT_MODES.EDIT]: (
                    <Form.Item
                      name="hospitalId"
                      rules={[{ required: true, message: 'Xin vui lòng chọn bệnh viện' }]}
                    >

                       <Select loading={loadingGetHospitals}  style={{width : '100%'}} options={optionHospital || []}/>
                    </Form.Item>
                   
                   
                  )
                }[mode]
              }
            </Descriptions.Item>
           </>}
            <Descriptions.Item
              label="Số lượng"
              {...descriptionItemProps}
            >
              {
                {
                  [COMPONENT_MODES.VIEW]: get(whBillItem, "quantity"),
                  [COMPONENT_MODES.EDIT]: (
                    <Form.Item
                      name="quantity"
                    >
                      <InputNumber />
                    </Form.Item>
                  )
                }[mode]
              }
            </Descriptions.Item>

            <Descriptions.Item
              label="Số lần tối thiểu thực hiện dịch vụ này"
              {...descriptionItemProps}
            >
              {
                {
                  [COMPONENT_MODES.VIEW]: get(whBillItem, "minimumQuantity"),
                  [COMPONENT_MODES.EDIT]: (
                    <Form.Item shouldUpdate>
                      {() => get(sessionPrice, 'minimumQuantity')}
                    </Form.Item>
                  )
                }[mode]
              }
            </Descriptions.Item>

            <Descriptions.Item
              label="Tổng số lần"
              {...descriptionItemProps}
            >
              {
                {
                  [COMPONENT_MODES.VIEW]: get(whBillItem, "numberOfTimes"),
                  [COMPONENT_MODES.EDIT]: (
                    <Form.Item shouldUpdate>
                      {() => get(sessionPrice, 'minimumQuantity') * form.getFieldValue('quantity')}
                    </Form.Item>
                  )
                }[mode]
              }
            </Descriptions.Item>

            <Descriptions.Item
              label="Ngày bắt đầu"
              {...descriptionItemProps}
            >
              {
                {
                  [COMPONENT_MODES.VIEW]: moment(get(whBillItem, 'startDate')).format('DD/MM/YYYY'),
                  [COMPONENT_MODES.EDIT]: (
                    <Form.Item name="startDate">
                      <DatePicker
                        format="DD/MM/YYYY"
                        allowClear={false}
                        disabledDate={(current) => current < moment().startOf('day')}
                      />
                    </Form.Item>
                  )
                }[mode]
              }
            </Descriptions.Item>

            <Descriptions.Item
              label="Ngày kết thúc"
              {...descriptionItemProps}
            >
              {
                {
                  [COMPONENT_MODES.VIEW]: moment(get(whBillItem, 'endDate')).format('DD/MM/YYYY'),
                  [COMPONENT_MODES.EDIT]: (
                    <Form.Item name="endDate">
                      <DatePicker
                        format="DD/MM/YYYY"
                        allowClear={false}
                        disabledDate={(current) => current < moment().startOf('day')}
                      />
                    </Form.Item>
                  )
                }[mode]
              }
            </Descriptions.Item>
          </Descriptions>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export default WhBillItemModal;
