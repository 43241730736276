import { InfoCircleOutlined } from '@ant-design/icons'
import { Badge, Button, Col, Divider, Form, Input, InputNumber, Row, Select, Switch, Tooltip } from 'antd'
import SkeletonInput from 'antd/lib/skeleton/Input'
import { get, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { PRIORITY_KEYWORD_SEARCH, TYPE_RANKING_REFERRAL_VI } from '~/constants/defaultValue'
import { useCreateKeywordSearch, useKeywordSearch, useUpdateKeywordSearch } from '~/hooks/keywordSearch'
import { formatNumberThreeComma, useOptionsSelectAntd } from '~/hooks/utils'
let listOptionPriority = []
for (const [key, value] of Object.entries(PRIORITY_KEYWORD_SEARCH)) {
  listOptionPriority.push({label : value,value : key})
}
export default function KeywordSearchForm({id,handleCloseModal,updateKeywordSearch}) {
    const [form] = Form.useForm()
    const handleFinish = () => {
        handleCloseModal()
        form.resetFields()
    }
    // HOOK
    const [submitLoading,createKeywordSearch] = useCreateKeywordSearch(handleFinish)
    const [KeywordSearch,loading] = useKeywordSearch(id)
    //
    const onFinish = (values) => {
      
      if(id){
        updateKeywordSearch({...values,_id : id})
      }
      else{
        createKeywordSearch({...values})
      }
    }
   
    useEffect(() => {
      form.resetFields()
      if(!!id){
        form.setFieldsValue({
         ...KeywordSearch
        })
      }
    },[id,KeywordSearch])
    const renderInput = InputComponent =>
  loading ? <SkeletonInput active/> : InputComponent;
  return (
    <Form
    className='form-config-campaign'
    form={form}
    autoComplete="off"
    onFinish={onFinish}
    scrollToFirstError
    requiredMark={false}
    labelCol={{ sm: 24, md: 24, lg: 6 }}
    wrapperCol={{ sm: 24, md: 24, lg: 18 }}
  >
    <Form.Item label='Từ khoá' name='keyword'>
        {renderInput( <Input/>)}
    </Form.Item>
    <Form.Item shouldUpdate noStyle>
    {() => <Form.Item label=<Tooltip
          placement='topRight'
          zIndex={2001}
          title={<span >Ưu tiên để đưa lên đầu danh sách tìm kiếm</span>}
        >
          <Badge
            size="small"
            color="#9B9999"
            offset={[14, 4]}
            count={<InfoCircleOutlined />}
          >
           <span>Ưu tiên</span>
          </Badge>
        </Tooltip> name='onTheTop'>
         {renderInput(<Switch defaultChecked={!!form.getFieldValue(['onTheTop'])} />)}
    </Form.Item>}
    </Form.Item>
    <Row justify='space-around' className='groupButtonSubmit'>
      <Col  span={8}>
      <Button onClick={handleCloseModal}>Huỷ</Button>
      </Col>
      <Col span={8}>
      <Button loading={submitLoading} type='primary' htmlType='submit'>{id ? "Cập nhật" : "Tạo mới"}</Button>
      </Col>
    </Row>
  </Form>
  )
}
