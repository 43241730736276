
import { InfoCircleTwoTone, RedoOutlined, ReloadOutlined } from '@ant-design/icons';
import { Badge, Button, Divider, Modal, Popconfirm, Table, Tag, Tooltip, Typography } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { BaseBorderBox } from '~/components/Common';
import TooltipTitle from '~/components/Common/TooltipTitle';

import SkeletonTable from '~/components/Utils/SkeletonTable';
import { useMatchPolicy, useProfile } from '~/hooks';
import { useCreateUnConfirmWhBill, useGetIsNewBill, useModalListBranchConfirm, useSetNewBill, useUnConfirmWhBillPaging, useUnConfirmWhBills } from '~/hooks/unConfirmWhBill';
import { vietnamMoment } from '~/hooks/utils';
import { formatPhone, getExistProp } from '~/utils/helper';
import WhBillConsole from '../WhBillConsole';
import TableSelectBranch from './TableSelectBranch';
import './index.scss';
import POLICIES from '~/constants/policy';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { WH_BILL_STATUS,WH_BILL_STATUS_EN } from '~/constants/defaultValue';


export default function UnConfirmWhBillTable({ mutate }) {
  const history = useHistory();
  const { path } = useRouteMatch();

  const urlParams = new URLSearchParams(window.location.search);
  const newBill = urlParams.get('newBill');
  const canWrite = useMatchPolicy(POLICIES.WRITE_WHBILL);
  const [query, setQuery] = useState({ page: 1, limit: 5 });
  const [profile] = useProfile();
  const [selectedWhBill, setSelectedWhBill] = useState(null);
  const [isOpenWhBillConsole, setIsOpenWhBillConsole] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const query_ = useMemo(() => ({
    ...query,
    newBill
  }), [query, newBill, reFetch]);
  const [dataSource, isLoading] = useUnConfirmWhBills(query_);
  const { isOpenModalBranchConfirm, onCancelModalBranchConfirm, onOpenModalBranchConfirm, whBillId } = useModalListBranchConfirm();
  const paging = useUnConfirmWhBillPaging();
  const isNewBill =   useGetIsNewBill();
  const [setNewBill] = useSetNewBill();
  const triggerRefetch = () => {
    let queryParams = {};
    // Inherit from Url 
    for (const [key, value] of urlParams.entries()) {
      if(key !== 'newBill'){
        queryParams[key] = value;
      }

    }
    // Clear query newBill
    history.push({
      pathname: path,
      search: new URLSearchParams(
        getExistProp(queryParams)
        ).toString()
      });
      setRefetch(!reFetch);
      setNewBill(false);
  }
  const callbackAfterConfirm = () => {
    if (mutate && typeof mutate === 'function') {
      mutate();
    };
    onCancelModalBranchConfirm();
    setQuery({ page: 1, limit: 5 });
  }
  const [confirmLoading, handleConfirm] = useCreateUnConfirmWhBill(callbackAfterConfirm);
  const onOpenWhBillConsole = (bill) => {
    setIsOpenWhBillConsole(true);
    setSelectedWhBill(bill);
  }
  const onCloseWhBillConsole = () => {
    setIsOpenWhBillConsole(false);
    setSelectedWhBill(null);
  }
  const onConfirm = ({ whBillId, branchId }) => handleConfirm({ whBillId, branchId })
  const onChangeQuery = (newQuery) => {
    setQuery({ ...query, ...newQuery });
  }
  const columns = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'billNumber',
      key: 'billNumber',
      align : 'center',
      render: (item, whBill) =>
        <Typography.Link onClick={() => onOpenWhBillConsole(whBill)}>
          {item}
        </Typography.Link>
    },
    {
      title: 'Tên Khách hàng',
      dataIndex: 'customer',
      key: 'customerName',
      render: (customer) => get(customer, 'fullName', ''),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customer',
      key: 'phoneNumber',
      render: (customer) => formatPhone(get(customer, 'phoneNumber', '')),
    },
    {
      title: 'Địa chỉ thực hiện',
      dataIndex: 'customerAddress',
      key: 'customerAddress',
      render: (address) => `${get(address, 'street', '')}, ${get(address, 'ward', '')}, ${get(address, 'district', '')}, ${get(address, 'city', '')}`,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (time) => {
        return <div>
          <p>
            {moment(time).format("DD-MM-YYYY HH:mm:ss")}
          </p>
          <p>
            <Tag>
              {moment(vietnamMoment(time), "YYYYMMDD").fromNow()}
            </Tag>
          </p>
        </div>
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <span className={`bill-status ${status.toLowerCase()}`}>
      {WH_BILL_STATUS[status]}
    </span>
    },
    {
      title: 'Thao tác',
      key: 'action',
      align: 'center',
      render: (item, record) => {
        const { listErrorService,status } = record;
        const isAvailable = !listErrorService?.length;

        return get(profile, 'isSuperAdmin')
          ? <Button
            disabled={status === WH_BILL_STATUS_EN.CANCELLED}
            onClick={() => onOpenModalBranchConfirm(get(record, '_id'))}
            loading={confirmLoading} style={{ borderRadius: '8px' }} type='primary'>Chọn chi nhánh</Button>
          :
          <Tooltip zIndex={9999} title={!canWrite && "Bạn không có quyền tiếp nhận!"}>
            <Popconfirm
              title="Bạn muốn xác nhận xử lí đơn hàng?"
              onConfirm={() => onConfirm(
                {
                  whBillId: get(record, '_id'),
                  branchId: get(profile, 'branchId'),
                }
              )}
              okText="Xác nhận"
              cancelText="Huỷ"
              disabled={!canWrite || !isAvailable || status === WH_BILL_STATUS_EN.CANCELLED}
            >
              <Button loading={confirmLoading} disabled={!canWrite || !isAvailable || status === WH_BILL_STATUS_EN.CANCELLED} style={{ borderRadius: '8px' }} type='primary'>Tiếp nhận</Button>
            </Popconfirm>
          </Tooltip>

      }
    },
  ];
  const Footer = ({ onCancel }) => {
    return <Button onClick={onCancel}>
      Đóng
    </Button>
  }
  const InfoReject = () => {
    return <TooltipTitle offset={[0, 0]} count={<InfoCircleTwoTone />} titleTooltip={
      <div>
        Lý do không được tiếp nhận
        <Divider className='m-1' />
        Hệ thống ngừng hoạt động dịch vụ.
        <br />
        Dịch vụ chưa được đăng ký.
        <br />
        Dịch vụ không khả dụng trong khu vực của bạn.
        <br />
        Dịch vụ đang ngừng hoạt động tại chi nhánh của bạn.
        <br />
      </div>

    } />
  }
  return (
    <>

      <BaseBorderBox title={
        <>

          <Badge dot={isNewBill} offset={[-18,0]}>
          <Button onClick={triggerRefetch} className='btn-reload' type='primary'>
            <i class="uil uil-sync"></i>
          </Button>
          </Badge>
          <TooltipTitle content={"Đơn hàng chưa tiếp nhận"} titleTooltip="Đơn hàng chưa có chi nhánh nào tiếp nhận!" count={<InfoCircleTwoTone />} />
        </>

      }>
        {isLoading ?
          <SkeletonTable rowCount={5} columns={columns} />
          : <Table
          loading={isLoading}
            rowKey={(rc) => rc._id}
            rowClassName={(rc) => {
              if (get(rc, 'billNumber') === parseInt(newBill)) return 'highlightBill'
              return ""
            }}
            columns={columns}
            dataSource={dataSource}
            onChange={({ current }) => { onChangeQuery({ page: current }) }}
            pagination={{
              ...paging,
              pageSize: get(query, 'limit', 5),
              current: get(query, 'page', 5),
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
            size="small"
          />}
      </BaseBorderBox>
      <Modal
        visible={isOpenWhBillConsole}
        onCancel={onCloseWhBillConsole}
        title={"Chi tiết đơn hàng"}
        width={1200}
        footer={<Footer onCancel={onCloseWhBillConsole} />}
      >
        <WhBillConsole
          whBill={selectedWhBill}
          isHandlingConfirmWhBill={true}
        />
      </Modal>

      <Modal
        destroyOnClose
        visible={isOpenModalBranchConfirm}
        onCancel={onCancelModalBranchConfirm}
        title={"Danh sách chi nhánh tiếp nhận"}
        width={500}

        footer={
          <div className='d-flex justify-content-between align-items-center w-100'>
            <InfoReject key='info' />
            <Footer key={'back'} onCancel={onCancelModalBranchConfirm} />
          </div>
        }
      >
        <TableSelectBranch id={whBillId} onConfirm={onConfirm} isLoadingConfirm={confirmLoading} />
      </Modal>

    </>
  )
}
