import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getServicePlaces({ payload: query }) {
  try {
    const data = yield call(Api.servicePlace.getAll, query);
    yield put({ type: Types.GET_SERVICE_PLACES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_SERVICE_PLACES_FAILED,
      payload: error.message
    });
  }
}

function* getServicePlace({ payload: id }) {
  try {
    const servicePlace = yield call(Api.servicePlace.getById, id);
    yield put({ type: Types.GET_SERVICE_PLACE_SUCCESS, payload: servicePlace });
  } catch (error) {
    yield put({ type: Types.GET_SERVICE_PLACE_FAILED, payload: error.message });
  }
}

function* createServicePlace(action) {
  try {
    const {data} = yield call(Api.servicePlace.create, action.payload);
    yield put({ type: Types.CREATE_SERVICE_PLACE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_SERVICE_PLACE_FAILED,
      payload: error.message
    });
  }
}

function* updateServicePlace(action) {
  try {
    const {data} = yield call(Api.servicePlace.update, action.payload);
    yield put({ type: Types.UPDATE_SERVICE_PLACE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_SERVICE_PLACE_FAILED,
      payload: error.message
    });
  }
}

function* deleteServicePlace({ payload }) {
  try {
    yield call(Api.servicePlace.delete, payload);
    yield put({ type: Types.DELETE_SERVICE_PLACE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_SERVICE_PLACE_FAILED,
      payload: error.message
    });
  }
}

export default function* servicePlace() {
  yield takeLatest(Types.GET_SERVICE_PLACES_REQUEST, getServicePlaces);
  yield takeLatest(Types.GET_SERVICE_PLACE_REQUEST, getServicePlace);
  yield takeLatest(Types.DELETE_SERVICE_PLACE_REQUEST, deleteServicePlace);
  yield takeLatest(Types.CREATE_SERVICE_PLACE_REQUEST, createServicePlace);
  yield takeLatest(Types.UPDATE_SERVICE_PLACE_REQUEST, updateServicePlace);
}
