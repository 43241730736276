import requester from './requester';

const reportSystem = {
  getWhReceiptVoucherReports : (query) => requester.get(`/wh-receipt-voucher-partner`,query),
  getWhPaymentVoucherReports : (query) => requester.get(`/wh-payment-voucher-partner/all`,query),
  getWhReceiptCustomerReports : (query) => requester.get(`/wh-receipt-voucher-customer`,query),
  getWhPaymentCustomerReports : (query) => requester.get(`/wh-payment-voucher-customer`,query),
  getWhPaymentCustomerCancelReports : (query) => requester.get(`/wh-receipt-voucher-customer/customer-cancel`,query),
  getAllSale : (query) => requester.get(`/voucher-partner`,query),
  getFund:(query) => requester.get(`/system-revenue`,query),
  createFund:(fund) => requester.post(`/system-revenue`,fund),
  updateFund:(fund) => requester.put(`/system-revenue`,fund),
};

export default reportSystem;
