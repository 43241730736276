import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory,useRouteMatch } from 'react-router-dom';
import {
  useAction,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import { confirmUnConfirmWhBill, getListBranchConfirm, getUnConfirmWhBill, getUnConfirmWhBills, resetUnConfirmWhBillState, setNewBill } from '~/redux/action';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.unConfirmWhBill[key];
const pagingSelector = getSelector('paging');
const isNewBillSelector = getSelector('isNewBill');
export const useUnConfirmWhBillPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetUnConfirmWhBillSelector = getSelector('isGetUnConfirmWhBillLoading');
const getUnConfirmWhBillsFailedSelector = getSelector('getUnConfirmWhBillsFailed');
const getUnConfirmWhBillFailedSelector = getSelector('getUnConfirmWhBillFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const unConfirmWhBillsSelector = getSelector('unConfirmWhBills');
const unConfirmWhBillSelector = getSelector('unConfirmWhBill');

const confirmSuccessSelector = getSelector('confirmSuccess');
const confirmFailedSelector = getSelector('confirmFailed');

// LIST BRANCH CONFIRM
const loadingListBranchSelector = getSelector('isLoadingListBranchConfirm');
const getListBranchConfirmFailedSelector = getSelector('getListBranchConfirmFailed');
const listBranchConfirmSelector = getSelector('listBranchConfirm');

//
export const useUnConfirmWhBillQueryParams = () => {
    const query = useQueryParams();
    const limit = query.get('limit') || 10;
    const page = query.get('page') || 1;
    const keyword = query.get('keyword');
    const confirmTrainingCorseSuccess = useSelector(confirmSuccessSelector);
  
    return useMemo(() => {
      const queryParams = {
        page,
        limit,
        keyword
      };
      return [queryParams];
      //eslint-disable-next-line
    }, [
      page,
      limit,
      confirmTrainingCorseSuccess,
      keyword,
    ]);
  };

  export const useUpdateUnConfirmWhBillParams = (query) => {
    const history = useHistory();
    const {path} = useRouteMatch()
    const [keyword, setKeyword] = useState(get(query,'keyword'));
    useEffect(() => {
      setKeyword(get(query,'keyword'));
    },[query.keyword])
    const onParamChange = (param) => {
      if (!param.page) {
        query.page = 1;
      }
      history.push({
        pathname: path,
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword ,{setKeyword, onParamChange }];
  };
  export const useUnConfirmWhBills = (query) => {
    return useFetchByParam({
      action: getUnConfirmWhBills,
      loadingSelector,
      dataSelector: unConfirmWhBillsSelector,
      failedSelector: getUnConfirmWhBillsFailedSelector,
      param: query
    });
  };
  export const useUnConfirmWhBill = (id) => {
    return useFetchByParam({
      action: getUnConfirmWhBill,
      loadingSelector:loadinggetUnConfirmWhBillSelector,
      dataSelector: unConfirmWhBillSelector,
      failedSelector: getUnConfirmWhBillFailedSelector,
      param: id
    });
  };

  export const useListBranchConfirm = (query) => {
    return useFetchByParam({
      action: getListBranchConfirm,
      loadingSelector:loadingListBranchSelector,
      dataSelector: listBranchConfirmSelector,
      failedSelector: getListBranchConfirmFailedSelector,
      param: query
    });
  };
export const useCreateUnConfirmWhBill = (callback) => {
    useSuccess(confirmSuccessSelector, 'Xác nhận Đơn hàng thành công',callback);
    useFailed(confirmFailedSelector, 'Xác nhận Đơn hàng thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: confirmUnConfirmWhBill
    });
  };

  export const useResetUnConfirmWhBill = () => {
    useResetState(resetUnConfirmWhBillState);
  };

  export const useModalListBranchConfirm = () => {
    const [isOpenModalBranchConfirm,setIsOpenModalBranchConfirm] = useState(false);
    const [whBillId,setWhBillId] = useState(null);
    const onOpenModalBranchConfirm = (whBillId) => {
      setIsOpenModalBranchConfirm(true);
      setWhBillId(whBillId);
    }
    const onCancelModalBranchConfirm = () => {
      setIsOpenModalBranchConfirm(false);
      setWhBillId(null);
    }
    return {
      whBillId,
      isOpenModalBranchConfirm,
      onOpenModalBranchConfirm,
      onCancelModalBranchConfirm,
    }
  }
  export const useGetIsNewBill = () => useSelector(isNewBillSelector);
  export const useSetNewBill = () => {
    return useAction({
      action : setNewBill
    })
  }
