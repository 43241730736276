import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getAreas() {
  try {
    const data = yield call(Api.geo.getAreas);
    yield put({ type: Types.GET_AREAS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_AREAS_FAILED, payload: error.message });
  }
}

function* getCities() {
  try {
    const data = yield call(Api.geo.getCities);
    yield put({ type: Types.GET_CITIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_CITIES_FAILED, payload: error.message });
  }
}

function* getDistricts({ payload }) {
  try {
    const data = yield call(Api.geo.getDistricts, payload);
    yield put({ type: Types.GET_DISTRICTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_DISTRICTS_FAILED, payload: error.message });
  }
}

function* getWards({ payload }) {
  try {
    const data = yield call(Api.geo.getWards, payload);
    yield put({ type: Types.GET_WARDS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_WARDS_FAILED, payload: error.message });
  }
}

export default function* geo() {
  yield takeLatest(Types.GET_AREAS_REQUEST, getAreas);
  yield takeLatest(Types.GET_CITIES_REQUEST, getCities);
  yield takeLatest(Types.GET_DISTRICTS_REQUEST, getDistricts);
  yield takeLatest(Types.GET_WARDS_REQUEST, getWards);
}
