import { useSelector, useDispatch } from 'react-redux';
import { useFailed, useSubmit, useFetchByParam, useBranchIdSessionStorage } from '~/hooks/utils';
import {
  loginRequest,
  logoutRequest,
  loginWorkspaceRequest,
  logoutWorkspaceRequest,
  getProfile,
  resetStore,
  getPolicy,
  getNavbar,
  pinedNavbar
} from '~/redux/action';
import { useMemo, useEffect, useRef, useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import instanceNavbar from '~/components/Home/constructor';
import api from '~/api';
import { get } from 'lodash';

const getSelector = (key) => (state) => state.user[key];

const loadingSelector = getSelector('isLoading');
const tokenSelector = getSelector('token');
const loginFailedSelector = getSelector('loginFailed');
const branchIdSelector = getSelector('branchId');

const profileSelector = getSelector('profile');
const isGetProfileLoadingSelector = getSelector('isGetProfileLoading');
const getProfileFailedSelector = getSelector('getProfileFailed');

const policySelector = getSelector('policy');
const isGetPolicyLoadingSelector = getSelector('isGetPolicyLoading');
const getPolicyFailedSelector = getSelector('getPolicyFailed');
const getPolicySuccessSelector = getSelector('getPolicySuccess');

const getNavbarSelector = getSelector('dataNavbar');
const getAccessKeyPinedSelector = getSelector('accessKeyPined');

const isMatchPolicy = (policies, requiredPermission) => {
  return !!requiredPermission?.reduce((policy, permission) => {
    return policy?.[permission];
  }, policies);
};

export const useMatchPolicy = (requiredPermission) => {
  const policies = useSelector(policySelector);
  const profile = useSelector(profileSelector);
  const isMatch = useMemo(() => {
    if (profile?.isSuperAdmin) {
      return true;
    }
    if (!requiredPermission) return true;

    if (Array.isArray(requiredPermission[0])) {
      return requiredPermission.reduce((isMatch, permissionItem) => {
        return isMatch && isMatchPolicy(policies, permissionItem);
      }, true);
    }

    return isMatchPolicy(policies, requiredPermission);
  }, [requiredPermission, policies]);
  return isMatch;
};

export const useMatchOrPolicy = (requiredPermission) => {
  const policies = useSelector(policySelector);
  const profile = useSelector(profileSelector);
  const isMatch = useMemo(() => {
    if (profile?.isSuperAdmin) {
      return true;
    }
    if (!requiredPermission) return true;

    for (const permissionItem of requiredPermission) {
        if (isMatchPolicy(policies, permissionItem)) {
          return true;
        }
    }

    return isMatchPolicy(policies, requiredPermission);
  }, [requiredPermission, policies]);

  return isMatch;
};

export const useResetStore = () => {
  const dispatch = useDispatch();
  const handleResetStore = useCallback(() => dispatch(resetStore()), [
    dispatch
  ]);

  return handleResetStore;
};

export const useUser = () => {
  const token = useSelector(tokenSelector);
  const branchId = useSelector(branchIdSelector);
  const [branchIdSession] = useBranchIdSessionStorage();
  return [branchIdSession ?? branchId, token];
};

export const useUserWorkspace = () => {
  const token = useSelector(tokenSelector);
  const profile = useSelector(profileSelector);

  return [profile, token];
};

export const useLogin = () => {
  const [isLoading, handleSubmit] = useSubmit({
    loadingSelector,
    action: loginRequest
  });

  // useSuccess(tokenSelector, 'Login success');
  useFailed(loginFailedSelector, 'Login Failed');

  return [isLoading, handleSubmit];
};

export const useLogout = () => {
  const [, handleLogout] = useSubmit({
    loadingSelector,
    action: logoutRequest
  });
  const handleResetStore = useResetStore();
  return () => {
    handleResetStore();
    handleLogout();
  };
};
export const useLogoutWhenInvalidSign = () => {
  const handleResetStore = useResetStore();
  const [, handleLogout] = useSubmit({
    loadingSelector,
    action: logoutRequest
  });
  // console.log();
  handleLogout()
    handleResetStore();
  return () => {
    handleLogout()
    handleResetStore();
  }
    
};
export const useLoginWorkspace = () => {
  const [isLoading, handleSubmit] = useSubmit({
    loadingSelector,
    action: loginWorkspaceRequest
  });

  // useSuccess(tokenSelector, 'Login success');
  useFailed(loginFailedSelector, 'Login workspace Failed');

  return [isLoading, handleSubmit];
};

export const useLogoutWorkspace = () => {
  const [, handleLogout] = useSubmit({
    loadingSelector,
    action: logoutWorkspaceRequest
  });
  const handleResetStore = useResetStore();

  return () => {
    handleResetStore();
    handleLogout();
  };
};

export const useSelectBranch = () => {
  const branchId = useSelector(branchIdSelector);

  return [branchId];
};

export const useProfile = (token) => {
  return useFetchByParam({
    action: getProfile,
    loadingSelector: isGetProfileLoadingSelector,
    dataSelector: profileSelector,
    failedSelector: getProfileFailedSelector,
    param: token
  });
};

export const useUserPolicy = () => {
  const [isLoading, handleGetPolicy] = useSubmit({
    loadingSelector: isGetPolicyLoadingSelector,
    action: getPolicy
  });

  const policies = useSelector(policySelector);
  const branchId = useSelector(branchIdSelector);
  const [branchIdSession] = useBranchIdSessionStorage();

  useEffect(() => {
    if (!Object.keys(policies || {}).length && branchId && !isLoading) {
      handleGetPolicy(branchIdSession ?? branchId);
    }
    //eslint-disable-next-line
  }, []);

  useFailed(getPolicyFailedSelector);

  return [isLoading, handleGetPolicy, policies];
};

export const useReload = () => {
  const getPolicySuccess = useSelector(getPolicySuccessSelector);
  const handleResetStore = useResetStore();
  const moutedRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (moutedRef && getPolicySuccess) {
      handleResetStore();
      history.push('/dashboard'); // Comment to use TabBranch , Added in Header
    }

    if (!moutedRef.current) {
      moutedRef.current = true;
    }
  }, [getPolicySuccess, history, handleResetStore]);
};

export const useMatchNavbarPolicy = () => {
  // Get Data Navbar from Instance
  const dataNavbar = instanceNavbar.list;
  const policies = useSelector(policySelector);
  const profile = useSelector(profileSelector);
  const dispatch = useDispatch();
  const checkPermission = useCallback((requiredPermission) => {
    if (profile?.isSuperAdmin) {
      return true;
    }
    if (!requiredPermission) return true;

    for (const permissionItem of requiredPermission) {
        if (isMatchPolicy(policies, permissionItem)) {
          return true;
        }
    }

    return isMatchPolicy(policies, requiredPermission);
  },[policies, profile?.isSuperAdmin])
  useEffect(() => {
    
    if(policies){
      // Create Data All Path of Child
      let paths = {}; // Use to Get Quickly AccessKey of Parent
      const newDataNavbar = dataNavbar?.map(data => {
        const {permissions:permissionsGroup,children:children1,path:path1,accessKey} = data;

        const isMatchPolicy = checkPermission(permissionsGroup);

        // if No Have Children will Return Data
        if(!children1?.length){
          Object.assign(paths,{[path1]:accessKey}) // Update Data All Path of Child
          return {...data,isMatchPolicy}
        }
        // Have Children
        const newChildren1 = (children1 || [])?.map(child1 => {
          const {permissions:permissionsChild1,children:children2,path:path2} = child1;
          const isMatchPolicy2 = checkPermission(permissionsChild1);
          if(!children2?.length){   // if No Have Children will Return Data
            Object.assign(paths,{[path2]:accessKey}) // Update Data All Path of Child
            return {...child1,isMatchPolicy:isMatchPolicy2}
          }
          const newChildren2 = (children2 || [])?.map(child2 => {
            const {permissions:permissionsChild2,children:children3,path : path3} = child2;
            const isMatchPolicy3 = checkPermission(permissionsChild2);
            if(!children3?.length){   // if No Have Children will Return Data

              Object.assign(paths,{[path3]:accessKey}) // Update Data All Path of Child

              return {...child2,isMatchPolicy:isMatchPolicy3}
            }
            
          });
          return {...child1,isMatchPolicy:isMatchPolicy2,children:newChildren2} 
        });
        return {...data,isMatchPolicy,children:newChildren1}
      });
      dispatch(getNavbar({list:newDataNavbar,paths}))
    }
  
  },[policies])
};

export const useGetDataNavbar = () => {
  const {list,paths} = useSelector(getNavbarSelector);
  return {list,paths}
}

export const unSubscribeToken = () => {
  let tokenFcm = get(JSON.parse(localStorage.getItem("persist:user")),'tokenFcm',JSON.stringify(''));
  if(JSON.parse(tokenFcm)){
    api.MyNotification.unSubscribeToken(JSON.parse(tokenFcm))
  }
}

export const subscribeToken = (tokenFcm) => { // NOT NEED TO ASYNC
  api.MyNotification.subscribeToken(tokenFcm)
}

export const usePinedNavbar = () => {
  const dispatch = useDispatch();
  const accessKeyPined = useSelector(getAccessKeyPinedSelector);
  const onPined = (accessKey) => {
    dispatch(pinedNavbar(accessKey))
  }
  return [accessKeyPined,onPined];
}
