import { put, call, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import Api from '../../api';
import {
  DELETE_WH_BILL_REQUEST,
  DELETE_WH_BILL_SUCCESS,
  DELETE_WH_BILL_FAILED,
  GET_WH_BILLS_REQUEST,
  GET_WH_BILLS_SUCCESS,
  GET_WH_BILLS_FAILED,
  GET_WH_BILL_REQUEST,
  GET_WH_BILL_SUCCESS,
  GET_WH_BILL_FAILED,
  UPDATE_WH_BILL_REQUEST,
  UPDATE_WH_BILL_SUCCESS,
  UPDATE_WH_BILL_FAILED,
  CUSTOMER_CANCEL_WH_BILL_REQUEST,
  CUSTOMER_CANCEL_WH_BILL_SUCCESS,
  CUSTOMER_CANCEL_WH_BILL_FAILED,
  UPDATE_BILL_ITEM_OF_BILL_REQUEST,
  UPDATE_BILL_ITEM_OF_BILL_SUCCESS,
  UPDATE_BILL_ITEM_OF_BILL_FAILED,
  CONFIRM_PREPAYMENT_WH_BILL_REQUEST,
  CONFIRM_PREPAYMENT_WH_BILL_SUCCESS,
  CONFIRM_PREPAYMENT_WH_BILL_FAILED,
  CANCEL_WH_BILL_REQUEST,
  CANCEL_WH_BILL_SUCCESS,
  CANCEL_WH_BILL_FAILED,
  GET_DEPRECIATION_WH_BILLS_SUCCESS,
  GET_DEPRECIATION_WH_BILLS_FAILED,
  GET_DEPRECIATION_WH_BILLS_REQUEST
} from '../../constants/actionTypes';

function* getWhBills({ payload: query }) {
  try {
    const response = yield call(Api.whBill.getAll, query);
    yield put({ type: GET_WH_BILLS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_BILLS_FAILED, payload: error });
  }
}

function* getDepreciations({ payload: query }) {
  try {
    const response = yield call(Api.whBill.getDepreciations, query);
    yield put({ type: GET_DEPRECIATION_WH_BILLS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_DEPRECIATION_WH_BILLS_FAILED, payload: error });
  }
}

function* getWhBill({ payload: id }) {
  try {
    const whBill = yield call(Api.whBill.getById, id);
    yield put({ type: GET_WH_BILL_SUCCESS, payload: whBill });
  } catch (error) {
    yield put({ type: GET_WH_BILL_FAILED, payload: error.message });
  }
}

function* updateWhBill(action) {
  try {
    const data = yield call(Api.whBill.update, action.payload);
    yield put({ type: UPDATE_WH_BILL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_BILL_FAILED, payload: error.message });
  }
}

function* customerCancelWhBill(action) {
  try {
    const data = yield call(Api.whBill.customerCancel, action.payload);
    yield put({ type: CUSTOMER_CANCEL_WH_BILL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CUSTOMER_CANCEL_WH_BILL_FAILED, payload: error });
  }
}

function* updateBillItemOfBill(action) {
  try {
    const data = yield call(Api.whBillItem.update, action.payload);
    yield put({ type: UPDATE_BILL_ITEM_OF_BILL_SUCCESS, payload: data });
  } catch (error) {
    console.error("blah blew...");
    console.error(error);
    yield put({ type: UPDATE_BILL_ITEM_OF_BILL_FAILED, payload: error.message });
  }
}

function* confirmPrepaymentWhBill(action) {
  try {
    const data = yield call(Api.whBill.confirmPrepayment, action.payload);
    yield put({ type: CONFIRM_PREPAYMENT_WH_BILL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CONFIRM_PREPAYMENT_WH_BILL_FAILED, payload: error.message });
  }
}

function* cancelWhBill(action) {
  try {
    const data = yield call(Api.whBill.cancel, action.payload);
    yield put({ type: CANCEL_WH_BILL_SUCCESS, payload: { ...data, _id: get(action.payload, "_id") } });
  } catch (error) {
    yield put({ type: CANCEL_WH_BILL_FAILED, payload: error.message });
  }
}

function* deleteHopital({ payload }) {
  try {
    yield call(Api.whBill.delete, payload);
    yield put({ type: DELETE_WH_BILL_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_WH_BILL_FAILED, payload: error.message });
  }
}

export default function* whBill() {
  yield takeLatest(GET_WH_BILLS_REQUEST, getWhBills);
  yield takeLatest(GET_DEPRECIATION_WH_BILLS_REQUEST, getDepreciations);
  yield takeLatest(GET_WH_BILL_REQUEST, getWhBill);
  yield takeLatest(DELETE_WH_BILL_REQUEST, deleteHopital);
  yield takeLatest(UPDATE_WH_BILL_REQUEST, updateWhBill);
  yield takeLatest(UPDATE_BILL_ITEM_OF_BILL_REQUEST, updateBillItemOfBill);
  yield takeLatest(CONFIRM_PREPAYMENT_WH_BILL_REQUEST, confirmPrepaymentWhBill);
  yield takeLatest(CANCEL_WH_BILL_REQUEST, cancelWhBill);
  yield takeLatest(CUSTOMER_CANCEL_WH_BILL_REQUEST, customerCancelWhBill);
}
