import { Modal, Row } from 'antd';
import { getAllDateOfWeek } from '~/hooks/date';

export default function ModalMonth({setIsModalMonthOpen,isModalMonthOpen,setDate,setIsModalReVenueOpen,setTitle,setInitDate,setIsModalDetailCustomerOpen,setIdCustomer,setTypeFilter}) {
  const handleCancel = () => {
    setIsModalMonthOpen(false);
    setInitDate()
    if(setIdCustomer)setIdCustomer(null)
  };
  const arrDate = getAllDateOfWeek()
  return (
    <Modal  width={200} visible={isModalMonthOpen} footer={null} onCancel={handleCancel} closable={false}>
       <Row style={{flexDirection : 'column' }}>
       {arrDate.map((e,index) => <a onClick={() => {
        setDate(e.date)
        setIsModalReVenueOpen(true)
        if(setTitle) setTitle(`Tuần ${e.index}`)
        setIsModalMonthOpen(false);
       if(setIsModalDetailCustomerOpen) setIsModalDetailCustomerOpen(true)
       if(setTypeFilter){
       setTypeFilter('weeks')
       }
        }} href='#'>Tuần {e.index}</a>)}
       </Row>
      </Modal>

  )
}
