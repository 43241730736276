import React, { useState, useCallback } from 'react';
import { Progress, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import imageCompression from 'browser-image-compression';
import {
  DEFAULT_UPLOAD_ACTION,
  MAX_UPLOAD_FILE_SIZE_IN_MB
} from '~/constants/defaultValue';

import './UploadServiceImage.scss';

const BYTES_PER_MB = 1024 * 1024;

const allowedImageExtensions = ['image/jpeg', 'image/png'];

function beforeUpload(file, setIsCompressing, onProgress) {
  const isImage = allowedImageExtensions.includes(file.type);
  if (!isImage) {
    message.error(`
      You can only upload ${allowedImageExtensions
        .map((item) => item.split('/')[1].toUpperCase())
        .join('/')} file!
    `);
    return false;
  }
  const isLtMaxFileSize = file.size / BYTES_PER_MB < MAX_UPLOAD_FILE_SIZE_IN_MB;
  if (!isLtMaxFileSize && isImage) {
    // message.error(`Image must smaller than ${MAX_UPLOAD_FILE_SIZE_IN_MB}MB!`);

    const options = {
      maxSizeMB: MAX_UPLOAD_FILE_SIZE_IN_MB,
      onProgress: onProgress,
      useWebWorker: true
    };

    return new Promise((resolve, reject) => {
      (async () => {
        try {
          setIsCompressing(true);
          const compressedFile = await imageCompression(file, options);
          resolve(compressedFile);
          setIsCompressing(false);
        } catch (error) {
          reject(error);
          setIsCompressing(false);
        }
      })();
    });
  }

  return isImage && isLtMaxFileSize;
}

const DEFAULT_RESOURCE = `clinic`;

const UploadServiceImage = (
  props,
  {
    onChange,
    imgUrl,
    title,
    action = `${DEFAULT_UPLOAD_ACTION}/${DEFAULT_RESOURCE}`,
    children
  }
) => {
  const [isCompressing, setIsCompressing] = useState(false);
  const [compressPercent, setCompressPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = useCallback(
    (info) => {
      if (info.file.status === 'uploading') {
        setIsLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        const imageUrl = info.file?.response?.url;
        setIsLoading(false);
        onChange(imageUrl);
      }
      props.handleChange();
    },
    [onChange]
  );

  const uploadButton = (
    <div>
      {isCompressing || isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {isCompressing ? (
          <>
            <p>Đang nén hình ảnh</p>
            <Progress percent={compressPercent} status="active" />
          </>
        ) : isLoading ? (
          'Đang tải lên'
        ) : (
          title || 'Hình ảnh'
        )}
      </div>
    </div>
  );

  return (
    <Upload
      name="file"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={action}
      beforeUpload={(file) =>
        beforeUpload(file, setIsCompressing, setCompressPercent)
      }
      onChange={handleChange}
    >
      {imgUrl && !isCompressing && !isLoading ? (
        <img
          src={imgUrl}
          alt="avatar"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      ) : (
        uploadButton
      )}
      {children}
    </Upload>
  );
};

export default UploadServiceImage;
