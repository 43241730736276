import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhContacts,
  createWhContact,
  deleteWhContact,
  getWhContact,
  resetWhContactState,
  updateWhContact
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { get, isString } from 'lodash';
import { getExistProp } from '~/utils/helper';

const getSelector = (key) => (state) => state.whContact[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useWhContactPaging = () => useSelector(pagingSelector);

// export const useWhContact = (id) => {
//   return useFetchByParam({
//     action: getWhContact,
//     loadingSelector: getByIdLoadingSelector,
//     dataSelector: getByIdSelector,
//     failedSelector: getByIdFailedSelector,
//     param: id
//   });
// };

export const useWhContactQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const name = query.get('name');
  const phoneNumber = query.get('phoneNumber');
  const email = query.get('email');
  const keyword = query.get('keyword')

  const [limit, setLimit] =useState(query.get('limit') || 10)

  const [page, setPage] = useState(query.get('page') || 1);
  const onTableChange = ({ current, pageSize }) => {setPage(current), setLimit(pageSize)};

  const regex = /[0-9.]/g
  let newKeyword = keyword
  if (regex?.test(keyword)) {
    newKeyword= newKeyword?.replace(/[. ]/g,'')
  }
  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    // if (page !== 1) {
    //   setPage(1);
    // }
  }
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      name: name || undefined,
      phoneNumber: phoneNumber || undefined,
      email: email || undefined,
      keyword,
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, name,phoneNumber,email, keyword]);
};


export const useContactParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query?.keyword);
  const onParamChange = (param) => {

    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

     for (const key in param) {
        param[key]=  isString(param[key]) ? param[key].trim() : param[key]
     }

    history.push({
      pathname: get(param,'/wh-contact'),
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useWhContacts = (query) => {
  return useFetchByParam({
    action: getWhContacts,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};


// export const useCreateWhContact = () => {
//   const history = useHistory();
//   const callback = () => history.push('/wh-category');

//   useSuccess(
//     createSuccessSelector,
//     'Tạo mới nhóm dịch vụ thành công',
//     callback
//   );
//   useFailed(createFailedSelector, 'Tạo mới nhóm dịch vụ thất bại');

//   return useSubmit({
//     loadingSelector: isSubmitLoadingSelector,
//     action: createWhContact
//   });
// };

// //** */
// export const useUpdateWhContact = () => {
//   useSuccess(updateSuccessSelector, 'Cập nhật nhóm dịch vụ thành công');
//   useFailed(updateFailedSelector, 'Cập nhật nhóm dịch vụ thất bại');

//   return useSubmit({
//     loadingSelector: isSubmitLoadingSelector,
//     action: updateWhContact
//   });
// };

// export const useDeleteWhContact = () => {
//   useSuccess(deleteSuccessSelector, 'Xoá nhóm dịch vụ thành công');
//   useFailed(deleteFailedSelector, 'Xoá nhóm dịch vụ thất bại');

//   return useSubmit({
//     loadingSelector,
//     action: deleteWhContact
//   });
// };

// export const useInitWhContact = (whContact) =>
//   useMemo(() => {
//     if (!whContact) {
//       return {
//         image: {}
//       };
//     }

//     return whContact;
//   }, [whContact]);

// export const useResetWhContact = () => {
//   useResetState(resetWhContactState);
// };
// export const useResetWhContactShort = () => {
//   useResetState(resetWhContactStateShort);
// };
