import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

const initState = {
  isLoading: false,
  getUIsFailed: null,
  UIs: null,
  isSubmitLoading : false,
  isGetUILoading: false,

  updateFailed : null,
  updateSuccess : null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_UIS_REQUEST:
      state.isLoading = true;
      state.getUIsFailed = null;
      return;

    case Types.GET_UIS_SUCCESS:
      state.isLoading = false;
      state.UIs = payload;
      state.getUIsFailed = null;
      return;

    case Types.GET_UIS_FAILED:
      state.isLoading = false;
      state.UIs = null;
      state.getUIsFailed = payload;
      return;
      case Types.UPDATE_UI_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_UI_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.UIs = payload;
        return;
  
      case Types.UPDATE_UI_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_UI_STATE:
      return initState;

    default:
      return;
  }
}, initState);
