import * as Types from '../../constants/actionTypes';
  
  export const getUnConfirmWhBills = query => ({
    type: Types.GET_UNCONFIRM_WH_BILLS_REQUEST,
    payload: query
  });
  export const getUnConfirmWhBillsEventStream = query => ({
    type: Types.GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_REQUEST,
    payload: query
  });
  export const getListBranchConfirm = query => ({
    type: Types.GET_LIST_BRANCH_CONFIRM_REQUEST,
    payload: query
  });
  export const getUnConfirmWhBill = id => ({
    type: Types.GET_UNCONFIRM_WH_BILL_REQUEST,
    payload: id
  });
  export const confirmUnConfirmWhBill = id => ({
    type: Types.CONFIRM_UNCONFIRM_WH_BILL_REQUEST,
    payload: id
  });
  export const resetUnConfirmWhBillState = () => ({
    type: Types.RESET_UNCONFIRM_WH_BILL_STATE
  });

  export const setNewBill = (status) => ({
    type: Types.SET_NEW_BILL,
    payload : status
  });

