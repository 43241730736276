import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Typography
} from 'antd';
import { compact, get, isArray, uniq, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '~/api';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { useUser, useWhCategories } from '~/hooks';
import { BaseBorderBox } from '../Common';

const ServiceGroup = ({
  category,
  data = [],
  setData,
  onSetServiceSelect,
  onRemoveServiceSelectAll
}) => {
  
  const [branchId] = useUser();
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState([]);

  const onCheck = useCallback(
    (e, item) => {
      const newId = get(item, '_id');
      const isChecked = e.target.checked;
      if (isChecked) {
        setData(data?.concat(newId));
        onSetServiceSelect(item);
      } else {
        setData(data?.filter((id) => id !== newId));
        onRemoveServiceSelectAll(item);
      }
    },
    [data, onRemoveServiceSelectAll, onSetServiceSelect, setData]
  );

  useEffect(() => {
    const fetchService = async () => {
      try {
        setLoading(true);
        const res = await api.whService.getWhServiceAvailableByBranch({
          status: 'ACTIVE',
          branchId: parseInt(branchId),
          whCategoryId: get(category, '_id')
        });
        setService(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    category && fetchService();
  }, [branchId, category]);

  const onSelectAll = () => {
    const ids = service?.map((item) => get(item, '_id'));
    setData(uniq(compact(data?.concat(ids))));
    onSetServiceSelect(service);
  };

  const onRemoveAll = () => {
    const ids = service?.map((item) => get(item, '_id'));
    setData(data?.filter((id) => !ids.includes(id)));
    onRemoveServiceSelectAll(service);
  };

  const dataInCategory = useMemo(
    () => service?.filter((item) => data?.includes(get(item, '_id'))),
    [service, data]
  );

  return (
    <div className="ServiceGroup">
      <Row align="middle" className="my-2">
        <Col span={14}>
          <Typography.Text className="mx-1" strong>
            {get(category, 'name.vi')}
          </Typography.Text>
          <p>
            Đã chọn: {dataInCategory?.length}/{service?.length}
          </p>
        </Col>
        <Col flex={1}>
          <Button
            disabled={loading}
            onClick={onSelectAll}
            className="d-flex align-items-center"
            icon={<CheckCircleOutlined />}
            type="primary"
          >
            Chọn tất cả
          </Button>
        </Col>
        <Col flex={1}>
          <Button
            disabled={loading}
            onClick={onRemoveAll}
            className="d-flex align-items-center"
            icon={<CloseCircleOutlined />}
            danger
          >
            Bỏ chọn tất cả
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Spin spinning />
      ) : (
        <Row>
          {service?.map((item) => (
            <Col className="mb-1" span={24}>
              <Checkbox
                onChange={(e) => onCheck(e, item)}
                checked={data?.includes(get(item, '_id'))}
              >
                {get(item, 'code')} - {get(item, 'name.vi')}
              </Checkbox>
            </Col>
          ))}
          {!service?.length && (
            <Typography.Text strong>Không có dịch vụ</Typography.Text>
          )}
        </Row>
      )}
    </div>
  );
};
export default function AssignService({
  data,
  setData,
  defaultData,
  onCancel,
  titleModal = 'Chọn dịch vụ',
}) {
  const [servicesSelected, setServicesSelected] = useState([]);
  const [categorySelectId, setCategorySelectId] = useState();
  const queryGetCategories = useMemo(
    () => ({ page: 1, limit: MAX_PAGINATION_LIMIT }),
    []
  );
  const [categories, isLoadingCate] = useWhCategories(queryGetCategories);
  const optionsCategory = useMemo(
    () =>
      categories
        ?.filter((item) => get(item, 'status') === 'ACTIVE')
        ?.map((item) => ({
          label: get(item, 'name.vi'),
          value: get(item, '_id')
        })),
    [categories]
  );

  useEffect(() => {
    if (!categorySelectId && categories?.length) {
      const newCategory = categories?.filter(
        (item) => get(item, 'status') === 'ACTIVE'
      );
      setCategorySelectId(get(newCategory, '[0]._id'));
    }
  }, [categories, categorySelectId]);

  useEffect(() => {
    if (defaultData && defaultData?.length) {
      setServicesSelected(defaultData);
    }
  }, [defaultData]);
  const categorySelect = useMemo(
    () => categories?.find((item) => get(item, '_id') === categorySelectId),
    [categorySelectId, categories]
  );

  const onSetServiceSelect = useCallback(
    (newServices) => {
      setServicesSelected(
        uniqBy(compact(servicesSelected?.concat(newServices)), '_id')
      );
    },
    [servicesSelected]
  );

  const onRemoveServiceSelectAll = useCallback(
    (newServices) => {
      if (isArray(newServices)) {
        setServicesSelected(
          servicesSelected?.filter(
            (service) =>
              !newServices?.some(
                (item) => get(item, '_id') === get(service, '_id')
              )
          )
        );
      } else {
        setServicesSelected(
          servicesSelected?.filter(
            (service) => get(service, '_id') !== get(newServices, '_id')
          )
        );
      }
    },
    [servicesSelected]
  );
  

  const columns = useMemo(
    () => [
      {
        title: 'Mã dịch vụ',
        dataIndex: 'code',
        key: 'code',
        render: (item) => <Typography.Text strong>{item}</Typography.Text>
      },
      {
        title: 'Tên dịch vụ',
        dataIndex: 'name',
        key: 'name',
        render: (item) => get(item, 'vi')
      }
    ],
    []
  );
  const filterServiceByCategory = (categoryId) =>
    servicesSelected?.filter(
      (item) => get(item, 'whCategoryId') === categoryId
    );
  return (
    <div className="AssignService">
      <h4 className="text-center">{titleModal}</h4>
      <div className="d-flex justify-content-center align-items-center ml-1 mb-1 gap-2">
        <Typography.Text strong>Chọn nhóm dịch vụ:</Typography.Text>
        <Select
          options={optionsCategory}
          style={{ minWidth: 300 }}
          value={categorySelectId}
          onChange={(value) => setCategorySelectId(value)}
        />
        <Button
          onClick={onCancel}
          style={{ marginLeft: 'auto' }}
          type="primary"
        >
          Xong
        </Button>
      </div>
      <Row gutter={16} wrap={false}>
        <Col span={12}>
          <BaseBorderBox title={'Dịch vụ sẵn sàng'}>
            <ServiceGroup
              category={categorySelect}
              data={data}
              setData={setData}
              onSetServiceSelect={onSetServiceSelect}
              onRemoveServiceSelectAll={onRemoveServiceSelectAll}
            />
          </BaseBorderBox>
        </Col>
        <Col span={12}>
          <BaseBorderBox title={'Dịch vụ đã chọn'}>
            <Tabs type="card">
              {categories?.map(
                (category) =>
                  filterServiceByCategory(get(category, '_id'))?.length && (
                    <Tabs.TabPane
                      tab={
                        <Row>
                          <Badge
                            overflowCount={9999}
                            count={
                              filterServiceByCategory(get(category, '_id'))
                                ?.length
                            }
                          ></Badge>
                          <Typography.Text className="ml-5">
                            {get(category, 'name.vi')}
                          </Typography.Text>
                        </Row>
                      }
                      key={get(category, '_id')}
                    >
                      <Table
                        dataSource={filterServiceByCategory(
                          get(category, '_id')
                        )}
                        columns={columns}
                        pagination={{
                          showTotal: (total) => `Đã chọn ${total}:`,
                          showSizeChanger: true
                        }}
                        size='small'
                      />
                    </Tabs.TabPane>
                  )
              )}
            </Tabs>
          </BaseBorderBox>
        </Col>
      </Row>
    </div>
  );
}
