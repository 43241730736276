import { Col, Descriptions, Row } from 'antd';
import SkeletonInput from 'antd/lib/skeleton/Input';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react'
import { WAREHOUSE_STATUS } from '~/constants/defaultValue';
import { useProductList } from '~/hooks/productList'

export default function InfomationProduct({id}) {
    const [product,loading] = useProductList(id)
    const descriptionItemProps = {
        contentStyle: {
          fontWeight: "bold",
          textAlign: "right",
        },
        labelStyle:{
            width : '200px'
        }
      }
      const renderLoading = (component) => loading ? <SkeletonInput style={{width : '200px'}}/> : component
  return (
    <>
          <Row style={{width : '100%', margin : '10px 0'}} align='bottom'>
         
         <Col span={24}>
           <Descriptions bordered column={1}>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Tên sản phẩm</span> 
               </div>}
             >
              {renderLoading(get(product,'name',''))}
             </Descriptions.Item>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Mã sản phẩm</span> 
               </div>}
             >
              {renderLoading(get(product,'code',''))}
             </Descriptions.Item>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Hãng</span> 
               </div>}
             >
              {renderLoading(get(product,'manufacturer',''))}
             </Descriptions.Item>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Đơn vị cung cấp</span> 
               </div>}
             >
              {renderLoading(get(product,'supplierInfo.name',get(product,'supplierId')))}
             </Descriptions.Item>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Tình trạng sản phẩm</span> 
               </div>}
             >
              {renderLoading(get(product,'note',''))}
             </Descriptions.Item>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Số lần khấu hao</span> 
               </div>}
             >
              {renderLoading(get(product,'countDepreciation',0))}
             </Descriptions.Item>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Số lần sử dụng</span> 
               </div>}
             >
              {renderLoading(get(product,'countUsing',0))}
             </Descriptions.Item>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Ngày sản xuất</span> 
               </div>}
             >
              {renderLoading(moment(get(product,'dateManufacturer',0)).format("DD/MM/YYYY"))}
             </Descriptions.Item>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Trạng thái</span> 
               </div>}
             >
                {renderLoading(<span style={{ color: WAREHOUSE_STATUS[get(product,'status',0)]?.colorStyle }}>
            {WAREHOUSE_STATUS[get(product,'status',0)]?.name}
          </span>)}
             </Descriptions.Item>
           </Descriptions>
         </Col>
       </Row>
    </>
  )
}
