import React, { useState } from 'react';

import { PATH_APP } from '../../routes/paths';
import {
  useHospitals,
  useDeleteHospital,
  useHospitalQueryParams,
  useHospitalPaging
} from '../../hooks/hospital';
import { Table, Tag, Popconfirm, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import SkeletonTable from '../../components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';

const { Search } = Input;

const ColumnActions = ({ id, deleteHospital }) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICY.WRITE_COMPANY}>
        <Link to={`/hospital/edit/${id}`}>
          <p>Sửa</p>
        </Link>
      </WithPermission>
      <p>|</p>
      <WithPermission permission={POLICY.DELETE_COMPANY}>
        <Popconfirm
          title="Bạn muốn xoá phòng khám này?"
          onConfirm={() => deleteHospital(id)}
          okText="Xoá"
          cancelText="Huỷ"
        >
          <p>Xóa</p>
        </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const Hospitals = ({ history }) => {
  const [query, onPagingChange] = useHospitalQueryParams();
  const [hospitals, isLoading] = useHospitals(query);
  const [keyword, setKeyword] = useState(query.keyword);
  const [, deleteHopital] = useDeleteHospital();
  const paging = useHospitalPaging();

  const hasUpdate = useMatchPolicy(POLICY.WRITE_COMPANY);
  const hasDelete = useMatchPolicy(POLICY.DELETE_COMPANY);

  const onSearch = () => {
    let searchParams = `?page=${query.page}&limit=${query.limit}`;
    if (keyword) searchParams += `&keyword=${keyword}`;

    history.push({
      pathname: '/hospital',
      search: searchParams
    });
  };

  const columns = [
    {
      title: 'Tên Phòng Khám',
      dataIndex: 'name',
      key: 'name'
    },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      render: (address) => (
        <p>{address && `${Object.values(address).join(', ')}`}</p>
      )
    },
    {
      title: 'Dịch Vụ',
      key: 'speciality',
      dataIndex: 'speciality',
      render: (speciality) => (
        <div className="speciality-tags">
          {speciality.map((spec, index) => {
            return (
              <Tag color="blue" key={index}>
                {spec.name}
              </Tag>
            );
          })}
        </div>
      )
    }
  ];

  if (hasUpdate || hasDelete) {
    columns.push({
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return <ColumnActions {...record} deleteHospital={deleteHopital} />;
      }
    });
  }

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách phòng khám" />
        <div className="page-wraper__header">
          <Search
            style={{ width: 200 }}
            placeholder="Tìm phòng khám"
            enterButton
            onSearch={onSearch}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

          <WithPermission permission={POLICY.WRITE_COMPANY}>
            <Link to={PATH_APP.hospital.create}>
              <Button type="primary" style={{ float: 'right' }}>
                {' '}
                Thêm mới
              </Button>
            </Link>
          </WithPermission>
        </div>

        {isLoading ? (
          <SkeletonTable
            rowCount={hospitals.length}
            columns={columns}
            pagination={paging}
          />
        ) : (
          <Table
            rowKey={(rc) => rc._id}
            columns={columns}
            dataSource={hospitals}
            onChange={onPagingChange}
            // pagination={paging}
            size="middle"
            pagination={{
              ...paging,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Hospitals;
