import { omit } from 'lodash';
import { STATUS_WAREHOUSE_VOUCHER } from '~/constants/warehouse';
import requester from './requester';
const formatPayload = submitData => omit(submitData, ['codeCommerPurchVoucher','createdAt']);
const WareHouseVoucher = {
  create: (course) => requester.post('/commercial-purchase-voucher', formatPayload(course)),
  createByPartner: (course) => requester.post('/commercial-purchase-voucher-partner', formatPayload(course)),
  update: (query) => requester.put(`/commercial-purchase-voucher/${query.id}`,formatPayload(course)),
  delete: (id) => requester.delete(`/commercial-purchase-voucher/${id}`),
  getAll: (query) => requester.get('/commercial-purchase-voucher', query),
  getAllByPartner: (query) => requester.get('/commercial-purchase-voucher-partner', query),
  getOne: (id) => requester.get(`/commercial-purchase-voucher/${id}`),
  getOneByPartner: (id) => requester.get(`/commercial-purchase-voucher-partner/${id}`),
  employeeConfirm: (id) => requester.put(`/commercial-purchase-voucher-update-status-employee/${id}/${STATUS_WAREHOUSE_VOUCHER.CONFIRM}`),
  employeeReject: (id) => requester.put(`/commercial-purchase-voucher-update-status-employee/${id}/${STATUS_WAREHOUSE_VOUCHER.REJECT}`),
  partnerConfirm: (id) => requester.put(`/commercial-purchase-voucher-update-status-partner/${id}/${STATUS_WAREHOUSE_VOUCHER.CONFIRM}`),
  partnerReject: (id) => requester.put(`/commercial-purchase-voucher-update-status-partner/${id}/${STATUS_WAREHOUSE_VOUCHER.REJECT}`),
  supplierConfirm: (id) => requester.put(`/commercial-purchase-voucher-update-status-supplier/${id}/${STATUS_WAREHOUSE_VOUCHER.CONFIRM}`),
  supplierReject: (id) => requester.put(`/commercial-purchase-voucher-update-status-supplier/${id}/${STATUS_WAREHOUSE_VOUCHER.REJECT}`),
  getVoucherByIdProduct: (id) => requester.get(`/commercial-purchase-voucher-device-last/${id}`),
  getLastSeq: () => requester.get(`/commercial-purchase-voucher-last-sequence`),
};
export default WareHouseVoucher;

