import { Modal, Table, Typography } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react'
import InfomationProduct from '~/components/WhAppointment/WhAppointmentsByBlockTable/components/InfomationProduct';
import { CONFIRM_VOUCHER_PRODUCT_STATUS_VI, CONFIRM_VOUCHER_STATUS_VI } from '~/constants/confirmVoucherWarehouse';
import { TYPE_WAREHOUSE } from '~/constants/defaultValue';

export default function ExpandableVoucher({ dataSource }) {
  const [idViewProduct, setIdViewProduct] = useState(null)
  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);
  const handleOpenModalViewProduct = (id) => {
      setIsOpenViewProduct(true);
      setIdViewProduct(id);
  };
  const handleCloseModalViewProduct = () => {
      setIsOpenViewProduct(false);
      setIdViewProduct(null);
  };
  const columns = [
    {
      title: 'Mã định danh SP',
      align: 'center',
      dataIndex: 'product',
      key: 'code',
      render: (item,record) =>  get(record,'medicalEquipmentType.type') === TYPE_WAREHOUSE.WORLDHEALTH ?
      <Typography.Link onClick={() => handleOpenModalViewProduct(get(record,'productId'))}>
      {get(item, 'code', '')}
    </Typography.Link>
      : get(item, 'variantCode', '')
    },
    {
      title: 'Tên sản phẩm',
      align: 'start',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số lượng',
      align: 'start',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (item, record, index) => <span className={`confirm-voucher-status ${item?.toLowerCase()}`}>
        {CONFIRM_VOUCHER_PRODUCT_STATUS_VI[item]}
      </span>
    },
    {
      title: 'Loại sản phẩm',
      align: 'start',
      dataIndex: 'medicalEquipmentType',
      key: 'medicalEquipmentType',
      render: (item) => get(item,'type') === TYPE_WAREHOUSE.CLINIC ? "Nguyên vật liệu" : "Công cụ dụng cụ"
    },
    {
      title: 'Mã cuộc hẹn',
      dataIndex: 'whAppointment',
      key: 'whAppointment',
      align : 'center',
      render: (whAppointment, record, index) => {
            return <p>
               <Typography.Link onClick={() => window.open(`wh-bill-item/${get(whAppointment,'whBillItemId')}/update-appointments`)} strong>{get(whAppointment,'code','')}</Typography.Link>
            </p>
      
      }
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="middle"
        scroll={{ x: '100%' }}
      />
      <Modal
        destroyOnClose
        title={`Thông tin thiết bị`}
        width={700}
        visible={isOpenViewProduct}
        closable={false}
        footer={null}
        onCancel={handleCloseModalViewProduct}>
        <InfomationProduct id={idViewProduct} />
      </Modal>
    </>
  )
}
