import React from 'react';

const WorkspaceDashboard = () => {
  console.log("WorkspaceDashboard...");

  return (
    <p>Hello from dashboard</p>
  )
}

export default WorkspaceDashboard;
