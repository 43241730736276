import requester from './requester';
const whCategoryShort = {
  getById: (id) => requester.get(`/wh-category/${id}`),
  delete: (id) => requester.delete(`/wh-category/${id}`),
  create: (whCategoryShort) => requester.post('/wh-category', whCategoryShort),
  update: (data) => {
    return requester.put(`/wh-category/${data.id}`, data);
  }
};
export default whCategoryShort;
