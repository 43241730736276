import produce from 'immer';
import {
  GET_HOSPITALS_REQUEST,
  GET_HOSPITALS_SUCCESS,
  GET_HOSPITALS_FAILED,
  DELETE_HOSPITAL_REQUEST,
  DELETE_HOSPITAL_SUCCESS,
  DELETE_HOSPITAL_FAILED,
  CREATE_HOSPITAL_REQUEST,
  CREATE_HOSPITAL_SUCCESS,
  CREATE_HOSPITAL_FAILED,
  GET_HOSPITAL_REQUEST,
  GET_HOSPITAL_SUCCESS,
  GET_HOSPITAL_FAILED,
  UPDATE_HOSPITAL_REQUEST,
  UPDATE_HOSPITAL_SUCCESS,
  UPDATE_HOSPITAL_FAILED,
  RESET_HOSPITAL_STATE,
  GET_HOSPITAL_AVAILABLE_TIME_REQUEST,
  GET_HOSPITAL_AVAILABLE_TIME_SUCCESS,
  GET_HOSPITAL_AVAILABLE_TIME_FAILED,
  GET_ALL_HOSPITALS_REQUEST,
  GET_ALL_HOSPITALS_SUCCESS,
  GET_ALL_HOSPITALS_FAILED,
  RESET_STORE,
  GET_SERVICE_HOSPITAL_REQUEST,
  GET_SERVICE_HOSPITAL_SUCCESS,
  GET_SERVICE_HOSPITAL_FAILED
} from '../../constants/actionTypes';
import getPaging from '../../utils/getPaging';

const INIT_STATE = {
  isLoading: false,
  getHospitalsFailed: undefined,
  hospitals: [],

  isGetHospitalLoading: false,
  hospital: null,
  getHospitalFailed: null,

  isGetServiceHospitalLoading: false,
  ServiceHospital: null,
  getServiceHospitalFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  availableTimes: [],
  isGetAvailableTimesLoading: false,
  getAvailableTimesFailed: null,

  allHospitals: [],
  isGetAllHospitalsLoading: false,
  getAllHospitalsFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_HOSPITALS_REQUEST:
      state.isLoading = true;
      state.getHospitalsFailed = null;
      return;

    case GET_HOSPITALS_SUCCESS:
      state.isLoading = false;
      state.hospitals = payload.docs;
      state.paging = getPaging(payload);
      return;

    case GET_HOSPITALS_FAILED:
      state.isLoading = false;
      state.getHospitalsFailed = null;
      return;

    case GET_HOSPITAL_REQUEST:
      state.isGetHospitalLoading = true;
      state.hospital = null;
      state.getHospitalFailed = null;
      return;
    
    case GET_SERVICE_HOSPITAL_REQUEST:
      state.isGetServiceHospitalLoading = true;
      state.ServiceHospital = null;
      state.getServiceHospitalFailed = null;
      return;

    case GET_SERVICE_HOSPITAL_SUCCESS:
      state.isGetServiceHospitalLoading = false;
      state.ServiceHospital = payload;
      return;

    case GET_SERVICE_HOSPITAL_FAILED:
      state.isGetServiceHospitalLoading = false;
      state.getServiceHospitalFailed = payload;
      return;

    case DELETE_HOSPITAL_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_HOSPITAL_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_HOSPITAL_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_HOSPITAL_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_HOSPITAL_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_HOSPITAL_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case UPDATE_HOSPITAL_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_HOSPITAL_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.hospital = payload;
      return;

    case UPDATE_HOSPITAL_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case GET_HOSPITAL_AVAILABLE_TIME_REQUEST:
      state.isGetAvailableTimesLoading = true;
      state.availableTimes = [];
      state.getAvailableTimesFailed = null;
      return;

    case GET_HOSPITAL_AVAILABLE_TIME_SUCCESS:
      state.isGetAvailableTimesLoading = false;
      state.availableTimes = payload;
      return;

    case GET_HOSPITAL_AVAILABLE_TIME_FAILED:
      state.isGetAvailableTimesLoading = false;
      state.getAvailableTimesFailed = payload;
      return;

    case GET_ALL_HOSPITALS_REQUEST:
      state.isGetAllHospitalsLoading = true;
      state.allHospitals = [];
      state.getAvailableTimesFailed = null;
      return;

    case GET_ALL_HOSPITALS_SUCCESS:
      state.isGetAllHospitalsLoading = false;
      state.allHospitals = payload;
      return;

    case GET_ALL_HOSPITALS_FAILED:
      state.isGetAllHospitalsLoading = false;
      state.getAvailableTimesFailed = payload;
      return;

    case RESET_HOSPITAL_STATE:
    case RESET_STORE:
      return INIT_STATE;

    default:
      return;
  }
}, INIT_STATE);
