import { get } from 'lodash';
import { adapterStartAndEndDate } from '~/utils/helper';
import requester from './requester';

const listWareHouse = {
  getAll: (query) => requester.get(`warehouse-address`, query),
  getProductListByWareHouseId: (id) => requester.get(`product-list/${id}`),
//   getAllOfPartner: (query) => requester.get(`product-list-partner-device`, query),
//   getAllHistory: (query) => requester.get(`product-list-history/${query.id}`,query),
  getById: (id) => requester.get(`warehouse-address/${id}`),
  create: (device) => requester.post('warehouse-address', device),
  update: (device) => requester.put(`warehouse-address/${device.id}`, device),
  delete: ({id}) => requester.delete(`warehouse-address/${id}`),

  
//   getCalendar: (query) => requester.get(`/calendar-pre-booking`,adapterStartAndEndDate(query)),
};

export default listWareHouse;
