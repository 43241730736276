import React from 'react';
import { Form, Input, Row, Col, DatePicker, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

let onAdd = () => {};

const Certification = ({ name, verticalLayout }) => {
  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        className="employee-form__section-title"
      >
        <h4>Các chứng chỉ khác</h4>

        <Col>
          <Button
            type="dashed"
            onClick={() => onAdd()}
            block
            icon={<PlusOutlined />}
          >
            Thêm chứng chỉ
          </Button>
        </Col>
      </Row>

      <Form.List name={name}>
        {(fields, { add, remove }) => {
          onAdd = add;
          return (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Row gutter={48} align={index === 0 && 'middle'} key={key}>
                  <Col sm={12} lg={6}>
                    <Form.Item
                      {...restField}
                      label={index === 0 && 'Chứng chỉ'}
                      name={[name, 'name']}
                      fieldKey={[fieldKey, 'name']}
                      {...verticalLayout}
                    >
                      <Input placeholder="Chứng chỉ" />
                    </Form.Item>
                  </Col>

                  <Col sm={12} lg={6}>
                    {' '}
                    <Form.Item
                      {...restField}
                      label={index === 0 && 'Nơi cấp'}
                      name={[name, 'certifiedBy']}
                      fieldKey={[fieldKey, 'certifiedBy']}
                      {...verticalLayout}
                    >
                      <Input placeholder="Nơi cấp" />
                    </Form.Item>
                  </Col>

                  <Col sm={12} lg={6}>
                    <Form.Item
                      {...restField}
                      label={index === 0 && 'Thời gian cấp'}
                      name={[name, 'certifiedAt']}
                      fieldKey={[fieldKey, 'certifiedAt']}
                      {...verticalLayout}
                    >
                      <DatePicker placeholder="Thời gian cấp" />
                    </Form.Item>
                  </Col>

                  <Col span={2} style={{ paddingTop: index == 0 ? 20 : 5 }}>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      className={index === 0 && 'top-5'}
                    />
                  </Col>
                </Row>
              ))}
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default Certification;
