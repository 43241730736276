import {
  GET_BRANCHES_REQUEST,
  GET_BRANCH_REQUEST,
  CREATE_BRANCH_REQUEST,
  UPDATE_BRANCH_REQUEST,
  DELETE_BRANCH_REQUEST,
  RESET_BRANCH_STATE,
  GET_RESOURCE_BRANCH_REQUEST,
  GET_RESOURCE_ACCESSED_BRANCH_REQUEST,
  ACCESS_RESOURCE_BRANCH_REQUEST,
  DENY_RESOURCE_BRANCH_REQUEST,
} from '~/constants/actionTypes';

export const getBranches = (query) => ({
  type: GET_BRANCHES_REQUEST,
  payload: query
});

export const getBranch = (id) => ({
  type: GET_BRANCH_REQUEST,
  payload: id
});

export const createBranch = (company) => ({
  type: CREATE_BRANCH_REQUEST,
  payload: company
});

export const updateBranch = (company) => ({
  type: UPDATE_BRANCH_REQUEST,
  payload: company
});


export const deleteBranch = (id) => ({
  type: DELETE_BRANCH_REQUEST,
  payload: id
});

export const resetBranchState = () => ({
  type: RESET_BRANCH_STATE
});

export const getResourceBranch = (payload) => ({
  type: GET_RESOURCE_BRANCH_REQUEST,
  payload
});

export const getResourceAccessedBranch = (id) => ({
  type: GET_RESOURCE_ACCESSED_BRANCH_REQUEST,
  payload: id
});

export const accessResourceBranch = (payload) => ({
  type: ACCESS_RESOURCE_BRANCH_REQUEST,
  payload,
});

export const denyResourceBranch = (payload) => ({
  type: DENY_RESOURCE_BRANCH_REQUEST,
  payload,
});
