import React from 'react';

import Icon from '@ant-design/icons';
import { Row, Space } from 'antd';
import { get } from 'lodash';

import LocationSvg from '~/components/WhPartner/LocationSvg';
import PhoneSvg from '~/components/WhPartner/PhoneSvg';

const WhBillItemCustomerInfo = ({
  whBill,
  whBillItem,
}) => {
  return (
    <>
      <Row align='middle'>
        <Space>
          <Icon component={LocationSvg} color="#3481FF" style={{ transform: 'scale(0.65)' }} />
          <p className=''>{
            get(whBill, "customerAddress.street") ||
            get(whBillItem, "whBill.customerAddress.street")
          }</p>
        </Space>
      </Row>
      <Row align='middle'>
        <Space>
          <Icon component={PhoneSvg} style={{ transform: 'scale(0.65)' }} />
          <p className=''>{
            get(whBill, "customerAddress.phoneNumber") ||
            get(whBillItem, "whBill.customerAddress.phoneNumber")
          }</p>
        </Space>
      </Row>
    </>
  )
}

export default WhBillItemCustomerInfo;
