import produce from 'immer';
import { RATE_CONFIG_REDUCER_MAP } from '~/constants/defaultValue';
import * as Types from '../../constants/actionTypes';

const initState = {
  certificates: [],
  isCertificatesLoading: false,
  getCertificatesFailed: null,

  degrees: [],
  isDegreesLoading: false,
  getDegreesFailed: null,

  experiences: [],
  isExperiencesLoading: false,
  getExperiencesFailed: null,

  positions: [],
  isPositionsLoading: false,
  getPositionsFailed: null,

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    // CERTIFICATE
    case Types.GET_RATE_CONFIGS_OF_CERTIFICATE_REQUEST:
      state.isCertificatesLoading = true;
      state.certificates = [];
      state.getCertificatesFailed = null;
      return;

    case Types.GET_RATE_CONFIGS_OF_CERTIFICATE_SUCCESS:
      state.isCertificatesLoading = false;
      state.certificates = payload;
      return;

    case Types.GET_RATE_CONFIGS_OF_CERTIFICATE_FAILED:
      state.isCertificatesLoading = false;
      state.getCertificatesFailed = payload;
      return;

    // DEGREE
    case Types.GET_RATE_CONFIGS_OF_DEGREE_REQUEST:
      state.isDegreesLoading = true;
      state.degrees = [];
      state.getDegreesFailed = null;
      return;

    case Types.GET_RATE_CONFIGS_OF_DEGREE_SUCCESS:
      state.isDegreesLoading = false;
      state.degrees = payload;
      return;

    case Types.GET_RATE_CONFIGS_OF_DEGREE_FAILED:
      state.isDegreesLoading = false;
      state.degrees = payload;
      return;

    // EXPERIENCE
    case Types.GET_RATE_CONFIGS_OF_EXPERIENCE_REQUEST:
      state.isExperiencesLoading = true;
      state.experiences = [];
      state.getExperiencesFailed = null;
      return;

    case Types.GET_RATE_CONFIGS_OF_EXPERIENCE_SUCCESS:
      state.isExperiencesLoading = false;
      state.experiences = payload;
      return;

    case Types.GET_RATE_CONFIGS_OF_EXPERIENCE_FAILED:
      state.isExperiencesLoading = false;
      state.experiences = payload;
      return;

    // POSITION
    case Types.GET_RATE_CONFIGS_OF_POSITION_REQUEST:
      state.isPositionsLoading = true;
      state.positions = [];
      state.getPositionsFailed = null;
      return;

    case Types.GET_RATE_CONFIGS_OF_POSITION_SUCCESS:
      state.isPositionsLoading = false;
      state.positions = payload;
      return;

    case Types.GET_RATE_CONFIGS_OF_POSITION_FAILED:
      state.isPositionsLoading = false;
      state.positions = payload;
      return;

    // single item
    case Types.GET_RATE_CONFIG_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_RATE_CONFIG_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_RATE_CONFIG_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_RATE_CONFIG_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_RATE_CONFIG_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;

      const { configType } = payload;
      const branch = RATE_CONFIG_REDUCER_MAP[configType];

      state[branch] = state[branch].concat(payload)
      return;

    case Types.CREATE_RATE_CONFIG_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_RATE_CONFIG_REQUEST:
      state.isSubmitLoading = true;
      state.byId = payload;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_RATE_CONFIG_SUCCESS: {
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;

      const { configType } = payload;
      const branch = RATE_CONFIG_REDUCER_MAP[configType];
      state[branch] = state[branch].map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;
    }

    case Types.UPDATE_RATE_CONFIG_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_RATE_CONFIG_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_RATE_CONFIG_SUCCESS: {
      state.deleteSuccess = payload;

      const { configType } = payload;
      const branch = RATE_CONFIG_REDUCER_MAP[configType];
      state[branch] = state[branch].map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;
    }

    case Types.DELETE_RATE_CONFIG_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_RATE_CONFIG_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
