import requester from './requester';

const Wallet = {
  create: (params) => requester.post('/wallet', params),
  // update: (query) => requester.post(`/wallet/${query.id}`,query),
  // delete: (id) => requester.delete(`/wallet/${id}`),
  // getAll: (query) => requester.get('/wallet', query),
  getOneUser: (userId) => requester.get(`/wallet`,userId),
};
export default Wallet;

