import {
  // APPROVE_WH_PAYMENT_VOUCHER_REQUEST,
  // GET_WH_PAYMENT_VOUCHER_GROUPS_REQUEST
  // INVITE_WH_PAYMENT_VOUCHER_REQUEST,
  CREATE_WH_PAYMENT_VOUCHER_REQUEST,
  DELETE_WH_PAYMENT_VOUCHER_REQUEST,
  GET_WH_PAYMENT_VOUCHERS_REQUEST,
  GET_WH_PAYMENT_VOUCHER_REQUEST,
  RESET_WH_PAYMENT_VOUCHER_STATE,
  UPDATE_WH_PAYMENT_VOUCHER_REQUEST,
  CONFIRM_WH_PAYMENT_VOUCHER_REQUEST,
  APPROVE_WH_PAYMENT_VOUCHER_REQUEST,
  REJECT_WH_PAYMENT_VOUCHER_REQUEST,
  GET_WH_PAYMENT_VOUCHER_NOT_BILLID_REQUEST,
  RESET_WH_PAYMENT_VOUCHER_STATE_ACTION,
  GET_WH_PAYMENT_VOUCHERS_CANCEL_REQUEST,
} from '../../constants/actionTypes';

export const getWhPaymentVouchers = query => ({
  type: GET_WH_PAYMENT_VOUCHERS_REQUEST,
  payload: query
});

export const getWhPaymentVouchersCancel = query => ({
  type: GET_WH_PAYMENT_VOUCHERS_CANCEL_REQUEST,
  payload: query
});

export const getWhPaymentVoucher = params => ({
  type: GET_WH_PAYMENT_VOUCHER_REQUEST,
  payload: params
});
export const getWhPaymentVoucherNotBillId = id => ({
  type: GET_WH_PAYMENT_VOUCHER_NOT_BILLID_REQUEST,
  payload: id
});

export const createWhPaymentVoucher = instance => ({
  type: CREATE_WH_PAYMENT_VOUCHER_REQUEST,
  payload: instance
});

// export const inviteWhPaymentVoucher = ({ id, newWhPaymentVoucher }) => ({
//   type: INVITE_WH_PAYMENT_VOUCHER_REQUEST,
//   payload: { id, newWhPaymentVoucher },
// });

export const updateWhPaymentVoucher = instance => ({
  type: UPDATE_WH_PAYMENT_VOUCHER_REQUEST,
  payload: instance
});

export const confirmWhPaymentVoucher = instance => ({
  type: CONFIRM_WH_PAYMENT_VOUCHER_REQUEST,
  payload: instance
});

export const approveWhPaymentVoucher = instance => ({
  type: APPROVE_WH_PAYMENT_VOUCHER_REQUEST,
  payload: instance
});

export const rejectWhPaymentVoucher = instance => ({
  type: REJECT_WH_PAYMENT_VOUCHER_REQUEST,
  payload: instance
});

export const deleteWhPaymentVoucher = id => ({
  type: DELETE_WH_PAYMENT_VOUCHER_REQUEST,
  payload: id
});

export const resetWhPaymentVoucherState = () => ({
  type: RESET_WH_PAYMENT_VOUCHER_STATE
});

// export const getWhPaymentVoucherGroups = () => ({
//   type: GET_WH_PAYMENT_VOUCHER_GROUPS_REQUEST
// });

export const resetWhPaymentVoucherStateAction = () => ({
  type: RESET_WH_PAYMENT_VOUCHER_STATE_ACTION
});
