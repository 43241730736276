import { initializeApp} from "firebase/app";
import { getMessaging, getToken, onMessage,isSupported } from 'firebase/messaging';
import { get } from "lodash";
import MyNotification from "~/api/myNotification";
import { postMessageNewWhBillFirebase } from "./broadCastChanel/firebaseChanel";
import firebaseConfig, { vapidKeyFirebase } from "./config";

let messaging = null;
let checkSupportFirebaseBrowser = async() => {
    const isSupportedBrowser = await isSupported();
    console.log("-[Firebase]-: Checking... support firebase");
    if(isSupportedBrowser){
        console.log("-[Firebase]-: Supported");
        // if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
          const app = initializeApp(firebaseConfig);
          messaging = getMessaging(app);
          requestPermission();
          onMessageListener();
        // }
    }else{
        console.log("-[Firebase]-: Not Supported");
    }

}
checkSupportFirebaseBrowser();

function requestPermission() {
    if ("Notification" in window) {
        console.log("-[Firebase]-: Have Notification In Window");
        if (Notification.permission === 'granted') {
            console.log("-[Firebase]-: Notification permission is granted");
            return getTokenFCM().then((currentToken) => {
                if (currentToken) {
                    if(window.location.pathname === '/login' || window.location.pathname === '/login-workspace'){
                        // Not Doing Anything
                    }else{
                      console.log("HAVE TOKEN");
                      MyNotification.subscribeToken(currentToken);
                    }
                } else {
                    console.log('-[Firebase]-: No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
                console.log('-[Firebase]-: An error occurred while retrieving token. ', err);
            });
        } else {
            console.log('-[Firebase]-: Notification permission is not granted.');
        }
    } else {
        console.log('-[Firebase]-: Notifications not supported in this browser.');
    }
}
function onMessageListener () {
    if(!messaging) return 
    onMessage(messaging, (payload) => {
        console.log('-[Firebase]-: reiceiver from firebase');
      // CORE
      const getDataPayload = (key,defaulted='') =>  JSON.parse(get(payload,['data',[`gcm.notification.${key}`]],JSON.stringify(defaulted)));
      // Post to Broadcast
      switch (true) {
          case !!getDataPayload('bill'):
            postMessageNewWhBillFirebase({...get(payload, 'notification'),data:getDataPayload('bill',{})})
              break;
      
          case !!getDataPayload('productDelivery'):
            postMessageNewWhBillFirebase({...get(payload, 'notification'),data:getDataPayload('productDelivery',{})})
              break;
          case !!getDataPayload('taskItem'):
            postMessageNewWhBillFirebase({...get(payload, 'notification'),data:getDataPayload('taskItem',{})})
              break;
      
          default:
              break;
      }
      console.log('-[Firebase]-: Message received. ', payload);
      // ...
  });
  };
export function getTokenFCM (msg=messaging) {
    // Check support for Firebase
    console.log('-[Firebase]-: Check support for Firebase getTokenFCM');
    if(!msg) {
        console.log('-[Firebase]-: Not Have msg',msg);
        return
    }
    // Check support for Notification
    if ("Notification" in window && Notification.permission === 'denied') {
        console.log("-[Firebase]-: Check Denied Notification GetTokenFCM");
        return
    } 
    return  getToken(msg, { vapidKey: vapidKeyFirebase.vapidKey }).then((currentToken) => {
        console.log("-[Firebase]-:doing in GetToken Firebase");
          if (currentToken) {
            console.log("-[Firebase]-: got token");
              return currentToken;
          } else {
            console.log("-[Firebase]-: Not got token");
              console.log('-[Firebase]-: No registration token available. Request permission to generate one.');
          }
      }).catch((err) => {
        console.log(err,'-[Firebase]-: Erro In Get Token Firebase');
      })
  };

  



