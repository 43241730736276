import React, { useMemo } from 'react'
import { useAppointmentOfHospitalDeletedQueryParams, useAppointmentsOfHistoryDeleted } from '~/hooks';
import { Breadcrumb } from '../Common';
import SkeletonTable from '../Utils/SkeletonTable';
import { Table } from 'antd';

export default function AppointmentsOfHospitalDeleted() {
  const [query] = useAppointmentOfHospitalDeletedQueryParams();
  const [appointments, isLoading] = useAppointmentsOfHistoryDeleted(query);
  const columns = [
    {
      title: 'Họ và tên khách hàng',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Phòng khám',
      dataIndex: 'hospital',
      key: 'hospital',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
    },
    // {
    //   title: '',
    //   dataIndex: '',
    //   key: '',
    // },
    // {
    //   title: '',
    //   dataIndex: '',
    //   key: '',
    // },
  ];
  return (
    <div className="page-wraper page-content">
        <Breadcrumb title="Danh sách lịch hẹn đã xoá" />
      <div className="page-wraper__header">
      {
            isLoading ?
                <SkeletonTable columns={columns} rowCount={5} />
                :<Table
              dataSource={appointments ?? []}
              columns={columns}
              // onSearch={() => onParamChange({ [searchBy]: keyword })}
              // onChange={({ current }) => {
              //   onParamChange({ page: current });
              // }}
              size="large"
              scroll={{
                x: 1500
              }}
              // pagination={{
              //   ...paging,
              //   showTotal: (total) => `Tổng cộng: ${total} `
              // }}
            />
            }
      </div>
    </div>
  )
}
