import { get, omit } from 'lodash';
import requester from './requester';

const coupon = {
  getAll: (query) => requester.get('/coupon', query),
  getById: (id) => requester.get(`/coupon/${id}`),
  create: (coupon) => requester.post('/coupon', coupon),
  update: (coupon) => requester.put(`/coupon/${coupon._id}`, coupon),
  delete: (id) => requester.delete(`/coupon/${id}`),
  getCouponServiceCategories: (query) => requester.get('/coupon-service-category', query),
  getListcouponForCustomer: (query) => requester.post('/free-coupon-admin',query),
  checkCoupon: (query) => requester.post(`/free-coupon/${get(query,'code','')}`,omit(query,['code'])),
};

export default coupon;
