import { Select } from 'antd'
import { get, last } from 'lodash';
import React, { useMemo } from 'react'
import { useProfile } from '~/hooks'
// Need to know : Outside use With Hook useBranchesDefault to set default branchId and Change BranchId (Search useBranchesDefault to find hook)
export default function SelectMultiBranch({value,defaultValue,onChange,...props}) { // value is Always String
    const [profile] = useProfile();
    const options = useMemo(() => {
      let branches = get(profile,'branches',[])?.map(branch => ({
        label : get(branch, 'name',''),
        value : get(branch, '_id','')?.toString(),
    }));
    branches.unshift({
      value : 'all',
      label : 'Chọn tất cả',
    });
    return branches
    },[profile]);
    
    const handleChange = (value) => {
      const valueSelect = last(value);
      if(valueSelect === 'all') {
      return  onChange(options?.filter(item => get(item,'value') !== 'all')?.map(branch => get(branch,'value')));
      }
      if(onChange && typeof onChange === 'function'){
        onChange(value);
      }
    };

  return (
    <Select
      mode="multiple"
      allowClear
      placeholder="Chọn chi nhánh..."
      onChange={handleChange}
      options={options}
      // dropdownClassName="custom-select-all"
      defaultValue={defaultValue}
      value={value && value?.split(',')} // Value always is Array
      {...props}
    />
  )
}
