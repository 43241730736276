import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { useMedicalEquipment } from '~/hooks/medicalEquipment';


function Information ({product,form}) {
  const [isLoading, setIsLoading] = useState(true);
  const [groupProductId, setGroupProductId] = useState(null);
  const [data, setData] = useState([])
  useEffect(() => {
    setIsLoading(false)
  }, [product]);

  useEffect(() => {
    const newData = {
      medicaEquipment: product?.groupProduct?.medicalEquipment?.name?.vi,
      code: product?.groupProduct?.code,
      type: product?.groupProduct?.name?.vi,
      note: product?.note
    }
    setData([{...newData}])
  },[product])
  const columns = [
    {
      title: 'Loại thiết bị y tế',
      align: 'start',
      dataIndex: 'medicaEquipment',
      key: 'medicaEquipment',
      width: '25%',
    },
    {
      title: 'Mã nhóm sản phẩm',
      align: 'start',
      dataIndex: 'code',
      key: 'code',
      width: '25%',

    },
    {
      title: 'Nhóm sản phẩm',
      align: 'start',
      dataIndex: 'type',
      key: 'type',
      width: '25%',
    },
    {
      title: 'Tình trạng',
      align: 'start',
      dataIndex: 'note',
      key: 'note',
      width: '25%',
    }
  ];

  return (
    <React.Fragment>
      {isLoading ? (
        <SkeletonTable
          // rowCount={Products.length}
          columns={columns}
          // pagination={paging}
        />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          dataSource={data}
            size="middle"
            pagination = {false}
        
        />
      )}
    </React.Fragment>
  );
}

export default Information;
