import React, { useEffect } from 'react';
import { Form, Input, Row, Button, Skeleton, Modal } from 'antd';

import { useCreateEmployeeGroup, useUpdateEmployeeGroup } from '~/hooks';
import { useParams } from 'react-router-dom';
import './groupForm.scss';

const FormItem = Form.Item;

const EmployeeGroupForm = ({ isOpen, onClose, initGroup }) => {
  const { id: branchId } = useParams();
  const { _id } = initGroup || {};
  const [form] = Form.useForm();
  const [isSubmitLoading, handleCreate] = useCreateEmployeeGroup(onClose);
  const [, handleUpdate] = useUpdateEmployeeGroup(onClose);
  const isLoading = false;

  useEffect(() => {
    form.resetFields();
  }, [initGroup, form]);

  const onFinish = (values) => {
    const employeeGroup = {
      ...values,
      branchId
    };

    if (_id) {
      handleUpdate({
        ...employeeGroup,
        _id
      });
    } else {
      handleCreate(employeeGroup);
    }
  };

  return (
    <Modal
      visible={isOpen}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="employee-group-form">
        <h4 style={{ marginRight: 'auto' }}>
          {`${!_id ? 'Tạo mới ' : 'Cập nhật'}`} nhóm nhân viên
        </h4>

        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initGroup}
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row
            align="middle"
            justify="space-between"
            className="employee-group-form__logo-row"
          >
            <FormItem
              label="Tên nhóm nhân viên"
              name="name"
              rules={[
                { required: true, message: 'Xin mời nhập tên nhóm nhân viên!' }
              ]}
            >
              {isLoading ? <Skeleton.Input active /> : <Input />}
            </FormItem>
            <FormItem label="Mô tả ngắn" name="description">
              {isLoading ? <Skeleton.Input active /> : <Input />}
            </FormItem>
          </Row>

          <Row className="employee-group-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {_id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EmployeeGroupForm;
