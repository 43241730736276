import React, {  useMemo, useState } from 'react';

import { EditFilled } from '@ant-design/icons';
import { Button, Modal, Space, Switch, Table } from 'antd';
import { get} from 'lodash';

import {  useCreateWhWorkNorm, useGetWhWorkNorms, useMatchPolicy, useResetWhWorkNorm, useUpdateWhWorkNorm, useUpdateWhWorkNormParams, useWhWorkNormPaging, useWhWorkNormQueryParams } from '~/hooks';

import POLICY from '~/constants/policy';
import { INSTANCE_STATUSES } from '~/constants/defaultValue';

import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import WhWorkNormForm from './Form';
import WithPermission from '~/components/Common/WithPermission';
import { formatNumberThreeComma } from '~/hooks/utils';

const WhWorkNorm = () => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHJOBSTANDARD);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHJOBSTANDARD);
  const [isOpen, setIsOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [query] = useWhWorkNormQueryParams()
  useResetWhWorkNorm()
  const [Norms,isLoading] = useGetWhWorkNorms(query)
  const [loading,update] = useUpdateWhWorkNorm(() => {
    setInitialValues(null);
    setIsOpen(false);
  })
  const [,handleCreate] = useCreateWhWorkNorm(() => setIsOpen(false))
  const paging =  useWhWorkNormPaging()
  const[{onParamChange}] = useUpdateWhWorkNormParams(query)
  const onChangeStatus = (value,record) => {
    const { _id,maximumRating,minimumNorm } = record;
    const submitData = {
      id:_id,
      JobQuota: {
          JobQuotaDetail: {
              maximumRating,
              minimumNorm,
              state : value ? INSTANCE_STATUSES.ACTIVE : INSTANCE_STATUSES.INACTIVE
          }
      }
  }
  update(submitData)
  }
  const ratesFromNorms = useMemo(() => {
    return Norms?.reduce((prev, curr) => {
      const { JobQuota } = curr ?? {JobQuota:{JobQuotaDetail:{
        state:"",
      maximumRating:"",
      minimumNorm:"",}}};
      if (JobQuota) {
         return  [...prev,{
          _id: get(curr, "_id",""),
          key:get(curr, "name.vi",{}),
          name: get(curr, "name.vi",{}),
          state: get(curr, "JobQuota.JobQuotaDetail.state",''),
          maximumRating: get(curr, ["JobQuota", "JobQuotaDetail" , "maximumRating"],""),
          minimumNorm: get(curr, ["JobQuota","JobQuotaDetail","minimumNorm"],""),

        }]
      }
      return  [...prev,{
        _id: get(curr, "_id",""),
        key:get(curr, "name.vi",{}),
        name: get(curr, "name.vi",{}),
        state: '',
        maximumRating: 0,
        minimumNorm: 0,
      }]
    }, []);
  }, [
    Norms,
  ])
  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '70px',
      render: (text, record, index) => index + 1,
    },

    {
      title: 'Nhóm nhân viên',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Định mức tối đa',
      dataIndex: 'maximumRating',
      key: 'maximumRating',
      width: '200px',
      render : (item) => formatNumberThreeComma(item)
    },

    // {
    //   title: 'Định mức tối thiểu',
    //   dataIndex: 'minimumNorm',
    //   key: 'minimumNorm',
    //  width: '200px',

    // },

    ... (canDelete || canUpdate) ? [{
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (text, record) => (
        <Space size="middle" >
          <WithPermission permission={POLICY.UPDATE_WHJOBSTANDARD}>
            <EditFilled
              onClick={() => {
                setInitialValues({
                  employeeGroupId: get(record, "_id",""),
                  maximumRating: get(record, "maximumRating",""),
                  minimumNorm: get(record, "minimumNorm",""),
                  state: get(record, "state",""),
                })
                setIsOpen(true);
              }}
              style={{ color: '#1f89e5' }}
            />
          </WithPermission>
          <WithPermission permission={POLICY.UPDATE_WHJOBSTANDARD}>
            <WithPermission permission={POLICY.DELETE_WHJOBSTANDARD}>
              <p style={{marginLeft:'10px', color: '#1f89e5'}}>|</p>
            </WithPermission>
          </WithPermission>
          <WithPermission permission={POLICY.DELETE_WHJOBSTANDARD}>
            <Switch
            loading={loading}
              checked={get(record, "state") === INSTANCE_STATUSES.ACTIVE}
              onChange={(value) => onChangeStatus(value, record)}
            />
          </WithPermission>
        </Space>
      ),
    }] : []
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Định mức công việc" />
        <div className='page-content__main' style={{ display: 'block' }}>
          <div className="page-wraper__header">
            <WithPermission permission={POLICY.WRITE_WHJOBSTANDARD}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => {
                  setInitialValues(null);
                  setIsOpen(true);
                }}
              >
                Thêm mới
              </Button>
            </WithPermission>
          </div>
          {isLoading ? (
            <SkeletonTable
              rowCount={10}
              columns={columns}
            />
          ) : (
            <Table
              className="wh-whWorkNorm-table-striped-rows"
              rowKey={(rc) => rc._id}
              columns={columns}
              dataSource={ratesFromNorms}
              size="middle"
              onChange={({ current }) => onParamChange({ page: current })}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
            />
          )}
        </div>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
        destroyOnClose
      >
        <WhWorkNormForm
        Norms={Norms}
          initialValues={initialValues}
          update={update}
          handleCreate={handleCreate}
          loading={loading}
          onClose={() => {setIsOpen(false)
            setInitialValues(null)}}
        />
      </Modal>
    </div>
  );
};

export default WhWorkNorm;
