import { ClockCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, InputNumber } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BaseBorderBox } from '~/components/Common';
import FlexCenter from '~/components/Common/FlexCenter';
import FieldItem from '../common/FieldItem';

export default function EstimateAndDate({ data, onUpdate }) {
    const [estimateTime,setEstimateTime] = useState();

    const handleUpdateEstimateTime = (onClose) => {
        onClose();
        onUpdate({
            estimatedTime_V2 : estimateTime,
        })
    };

    const handleUpdateStartDate = (date) => {
        onUpdate({
            startDate : date ? moment(date).format("YYYY-MM-DD") : null,
        });
    };

    const handleUpdateEndDate = (date) => {
        console.log(date,'date');
        onUpdate({
            endDate : date ? moment(date).format("YYYY-MM-DD") : null,
        });
    };

    useEffect(() => {
        setEstimateTime(get(data,'estimatedTime_V2'))
    },[data]);

    const disabledEndDate = (current) => {
        return current < moment(data?.startDate).endOf('day');
      };

    const disabledStartDate = (current) => {
        return current > moment(data?.endDate).endOf('day');
      };
    return (
      <BaseBorderBox
        title={
          <span>
            <ClockCircleOutlined /> <Text strong>Thời gian ước lượng</Text>
          </span>
        }
      >
        <Form>
          <FlexCenter.Row>
            <Text style={{ width: 65 }}>Thời gian: </Text>
            <FieldItem
              value={estimateTime}
              FormInput={(onClose) => (
                <InputNumber
                  value={estimateTime}
                  onChange={(v) => setEstimateTime(v)}
                  onPressEnter={() => handleUpdateEstimateTime(onClose)}
                  autoFocus
                />
              )}
            />
          </FlexCenter.Row>
          <FlexCenter.Row>
            <Text style={{ width: 65 }}>Ngày: </Text>
            <FieldItem
              placeholder='Chưa có'
              value={
                data?.startDate && moment(data?.startDate).format('DD/MM/YYYY')
              }
              FormInput={() => (
                <DatePicker
                  disabledDate={disabledStartDate}
                  style={{width : 125}}
                  value={data?.startDate && moment(data?.startDate)}
                  onChange={handleUpdateStartDate}
                  autoFocus
                />
              )}
            />
            {'-'}
            <FieldItem
              placeholder='Chưa có'
              value={
                data?.endDate && moment(data?.endDate).format('DD/MM/YYYY')
              }
              FormInput={() => (
                <DatePicker
                  style={{width : 125}}
                  disabledDate={disabledEndDate}
                  value={data?.endDate && moment(data?.endDate)}
                  onChange={handleUpdateEndDate}
                  autoFocus
                />
              )}
            />
          </FlexCenter.Row>
        </Form>
      </BaseBorderBox>
    );
}
