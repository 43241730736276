import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  createSystemConfiguration,
  deleteSystemConfiguration,
  getSystemConfiguration,
  getSystemConfigurationBookingInstructions,
  getSystemConfigurationChangeBanner,
  resetSystemConfigurationState,
  updateSystemConfiguration
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const getSelector = (key) => (state) => state.systemConfiguration[key];

const bookingInstructionsLoadingSelector = getSelector(
  'isBookingInstructionsLoading'
);
const bookingInstructionsSelector = getSelector('bookingInstructions');
const getBookingInstructionsFailedSelector = getSelector(
  'getBookingInstructionsFailed'
);

const changeBannerLoadingSelector = getSelector('ischangeBannerLoading');
const changeBannerSelector = getSelector('changeBanner');
const getChangeBannerFailedSelector = getSelector('getChangeBannerFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useSystemConfigurationPaging = () => useSelector(pagingSelector);

export const useSystemConfigurationQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteSystemConfigurationSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteSystemConfigurationSuccess]);
};

export const useBookingInstructions = (query) => {
  return useFetchByParam({
    action: getSystemConfigurationChangeBanner,
    loadingSelector: bookingInstructionsLoadingSelector,
    dataSelector: bookingInstructionsSelector,
    failedSelector: getBookingInstructionsFailedSelector,
    param: query
  });
};
export const useChangeBanner = (query) => {
  return useFetchByParam({
    action: getSystemConfigurationChangeBanner,
    loadingSelector: changeBannerLoadingSelector,
    dataSelector: changeBannerSelector,
    failedSelector: getChangeBannerFailedSelector,
    param: query
  });
};

export const useCreateSystemConfiguration = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createSystemConfiguration
  });
};

export const useUpdateSystemConfiguration = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật thành công');
  useFailed(updateFailedSelector, 'Cập nhật thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateSystemConfiguration
  });
};

export const useDeleteSystemConfiguration = () => {
  useSuccess(deleteSuccessSelector, 'Xoá thành công');
  useFailed(deleteFailedSelector, 'Xoá thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteSystemConfiguration
  });
};

export const useSystemConfiguration = (id) => {
  return useFetchByParam({
    action: getSystemConfiguration,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitSystemConfiguration = (systemConfiguration) => {
  return useMemo(() => {
    if (!systemConfiguration) {
      return {};
    }

    const initValues = {
      ...systemConfiguration
    };

    return initValues;
  }, [systemConfiguration]);
};

export const useResetSystemConfiguration = () => {
  useResetState(resetSystemConfigurationState);
};
