import { DatePicker, Divider, Form, InputNumber, Radio, Switch } from 'antd'
import { forIn, get } from 'lodash';
import React, { useEffect, useMemo } from 'react'
import { Input } from 'reactstrap';

export default function RepeatAction({rules, renderInput, form,CONFIG_CAMPAIGN_ACTIVITY_ALL,activity,id}) {
    let activityName = useMemo(() => {
        let result = ''
        forIn(CONFIG_CAMPAIGN_ACTIVITY_ALL,(value,key) => {
            if(key === activity){
                result =   value.vi
            }
        })
        return result
    },[CONFIG_CAMPAIGN_ACTIVITY_ALL,activity])
  
      const onChangeRadio = ({target}) => {
        const count = form.getFieldValue(['condition', 'value', 'maxCountBonus']);
        const checked = target.value
        if (!checked) {
          form.setFieldsValue({
              condition: {
                description: `Nhận Sau mỗi lần ${activityName},tối đa ${count || 0 } lần`
              }
            });
      }
      else{
          form.setFieldsValue({
              condition: {
                description: `Nhận Sau mỗi lần ${activityName}`
              }
            });  
      }
      }
      const onBlurValueMaxcount = () => {
        const count = form.getFieldValue(['condition', 'value', 'maxCountBonus']);
        if (count > 0 || count) {
            form.setFieldsValue({
                condition: {
                  description: `Nhận Sau mỗi lần ${activityName},tối đa ${count} lần`
                }
              });
        }
        else{
            form.setFieldsValue({
                condition: {
                  description: `Nhận Sau mỗi lần ${activityName}`
                }
              });  
        }
      };
      useEffect(() => {
        if(!id){
          form.setFieldsValue({condition : {
            value : {
              isInfiniteCount : true
            },
            description: `Nhận Sau mỗi lần ${activityName}`
          }})
        }
      },[id,activity])
  return (
    <>
      <Form.Item
        label={'Giới hạn số lần nhận thưởng'}
        name={['condition', 'value', 'isInfiniteCount']}
      >
    {renderInput(  <Radio.Group onChange={onChangeRadio} options={[{label : "Giới hạn",value : false},{label : "Không giới hạn",value : true}]}/>)}
      </Form.Item>
  <Form.Item shouldUpdate={(prevValue,curValue) => get(prevValue,'condition.value.isInfiniteCount') !== get(curValue,'condition.value.isInfiniteCount')} noStyle>
    {() => !form.getFieldValue(['condition', 'value', 'isInfiniteCount']) &&  <Form.Item
        label={'Số lần nhận thưởng tối đa'}
        name={['condition', 'value', 'maxCountBonus']}
        rules={rules(`nhập tần suất lặp lại`)}
      >
    {renderInput(  <InputNumber onBlur={onBlurValueMaxcount} precision={0} min={1} step={1}/>)}
      </Form.Item>}
  </Form.Item>
      <Form.Item shouldUpdate noStyle>
        <Form.Item name={['condition', 'description']} label="Mô tả điều kiện">
          {renderInput(<Input disabled />)}
        </Form.Item>
      </Form.Item>
      <Divider orientation="left" dashed>
        Thời gian chạy chương trình
      </Divider>
      <Form.Item
        label="Ngày bắt đầu chương trình"
        name={['condition', 'startDate']}
        rules={rules('chọn ngày bắt đầu')}
      >
        {renderInput(
          <DatePicker placeholder="Chọn ngày" format="DD-MM-YYYY" />
        )}
      </Form.Item>
      <Form.Item
        label="Ngày kết thúc chương trình"
        name={['condition', 'endDate']}
      >
        {renderInput(
          <DatePicker placeholder="Chọn ngày" format={'DD-MM-YYYY'} />
        )}
      </Form.Item>
    </>
  )
}
