import * as Types from '../../constants/actionTypes';
  
  export const getTrainingCourses = query => ({
    type: Types.GET_TRAINING_COURSES_REQUEST,
    payload: query
  });
  export const getTrainingCourse = id => ({
    type: Types.GET_TRAINING_COURSE_REQUEST,
    payload: id
  });
  export const createTrainingCourse = instance => ({
    type: Types.CREATE_TRAINING_COURSE_REQUEST,
    payload: instance
  });
  export const updateTrainingCourse = instance => ({
    type: Types.UPDATE_TRAINING_COURSE_REQUEST,
    payload: instance
  });
  export const deleteTrainingCourse = id => ({
    type: Types.DELETE_TRAINING_COURSE_REQUEST,
    payload: id
  });
  export const resetTrainingCourseState = () => ({
    type: Types.RESET_TRAINING_COURSE_STATE
  });