import requester from './requester';

const ActivityReferral = {
  getById: (id) => requester.get(`/activity/${id}`),
  create: (point) => requester.post(`/activity`, point),
  update:(point) => requester.put(`/activity/${point._id}`,point),
  getAll : (query) => requester.get(`/activity`,query),
  delete: (id) => requester.delete(`/activity/${id}`),
  
};

export default ActivityReferral;
