import requester from './requester';

const GiveCoupon = {
  create: (course) => requester.post('/give-coupon', course),
  update: (query) => requester.put(`/give-coupon/${query._id}`,query),
  delete: (id) => requester.delete(`/give-coupon/${id}`),
  getAll: (query) => requester.get('/give-coupon', query),
  getOne: (id) => requester.get(`/give-coupon/${id}`),
};
export default GiveCoupon;

