import { Avatar, Button, Col, Form, Image, Input, Row, Select, Skeleton, Space, Switch, Upload, message } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BaseBorderBox from '~/components/Common/BaseBorderBox'
import { useFetchProfile, useInitUserEmployee, useProfile, useResetActionUserEmployee, useUpdateProfile, useUser, useUserEmployee } from '~/hooks';
import AddressFormSection from '~/components/Common/AddressFormSection';
import { UploadOutlined } from '@ant-design/icons';
import { DEFAULT_UPLOAD_ACTION, MAX_UPLOAD_FILE_SIZE_IN_MB } from '~/constants/defaultValue';
import imageCompression from 'browser-image-compression';
import { get, unset } from 'lodash';
import { getShortName } from '~/components/WorkFlow/constants';
const { Option } = Select;
function ModalProfile({onCloseForm}) {
  const [form] =Form.useForm() 
  const [, token] = useUser();
  const [profile] = useProfile(token);  
  const [logo, setLogo] = useState();
  const [stateUpPassword, setStateUpPassword] = useState(false);
  const [isLoadingAvatar, setIsLoading] = useState(false);
  const id =useMemo(()=> profile?._id,[profile?._id]);

  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();
  const [, handleUpdate] = useUpdateProfile(onCloseForm);


  const [userDetail, isGetUserEmployeeLoading] = useFetchProfile();
  const initUserEmployee = useInitUserEmployee(userDetail, id);
  useResetActionUserEmployee()

  const onValuesChange = async ({ address, fullName, ...rest }) => {
    const shouldResetDistrictAndWards = address && address.cityId;
    if (shouldResetDistrictAndWards) {
      form.setFieldsValue({
        address: {
          districtId: null,
          wardId: null
        }
      });
    }
    const shouldResetWards = address && address.districtId;
    if (shouldResetWards) {
      form.setFieldsValue({
        address: {
          wardId: null
        }
      });
    }
  };
  const onFinish = (values) => {
    const { groups, ...rest } = values;
    const user = {
      ...rest,
      avatar: logo,
      // branchId,
    };
    if(!stateUpPassword){
      unset(user,'password')
      unset(user,'passwordCofirm')
    }
      handleUpdate({
        ...user,
        _id: id,
        userNumber: initUserEmployee.userNumber,
      });
      // onCloseForm()
  };


  const handleChange = useCallback(
    (info) => {
      if (info.file.status === 'uploading') {
        setIsLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        const imageUrl = info.file?.response?.url;
        setIsLoading(false);
        setLogo(imageUrl);
      }
    },
    [setLogo]
  );
  const BYTES_PER_MB = 1024 * 1024;

const allowedImageExtensions = ['image/jpeg', 'image/png'];

function beforeUpload(file) {
  const isImage = allowedImageExtensions.includes(file.type);
  if (!isImage) {
    message.error(`
      You can only upload ${allowedImageExtensions
        .map((item) => item.split('/')[1].toUpperCase())
        .join('/')} file!
    `);
    return false;
  }
  const isLtMaxFileSize = file.size / BYTES_PER_MB < MAX_UPLOAD_FILE_SIZE_IN_MB;
  if (!isLtMaxFileSize && isImage) {
    const options = {
      maxSizeMB: MAX_UPLOAD_FILE_SIZE_IN_MB,
      useWebWorker: true
    };

    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const compressedFile = await imageCompression(file, options);
          resolve(compressedFile);
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  return isImage && isLtMaxFileSize;
}
  useEffect(() => {
    form.resetFields();
    const { avatar, address } = initUserEmployee;
    if (avatar) {
      setLogo(avatar);
    }

    if (address) {
      setCityCode(address.cityId);
      setDistrictCode(address.districtId);
    }
  }, [initUserEmployee, form]);
  const DEFAULT_RESOURCE = `clinic`
  return (
    <div className='modal-profile'>
      <Row align='top' justify='center' wrap={false}>
          <Col sm={{span:24}} md={{span:9}} style={{alignSelf:'center',textAlign:'center'}} > 
            <Avatar src={logo} shape='circle' style={{objectFit:'contain',fontSize:80,textTransform:'uppercase'}} size={280}  >
              {getShortName(get(initUserEmployee, 'fullName',''))}
            </Avatar>
            &nbsp;&nbsp;
            <Upload
              maxCount={1}
              className='upLoadAvatar'
              action={`${DEFAULT_UPLOAD_ACTION}/${DEFAULT_RESOURCE}`}
              onChange={handleChange}
              beforeUpload={beforeUpload}
              name="file">
              <Button loading={isLoadingAvatar} type='text' style={{backgroundColor:'#c8c8c8',borderRadius:10}} icon={<UploadOutlined />}></Button>
            </Upload>
          </Col>
          <Col flex={1}>
           <Form 
            form={form}
            labelCol={{md:{span:6}}}
            requiredMark={false}
            scrollToFirstError
            onValuesChange={onValuesChange}
            autoComplete="off"
            onFinish={onFinish}
            initialValues={initUserEmployee}
           >
            <BaseBorderBox style={{paddingBottom:8}} title={<h5>Thông tin người dùng</h5>}>
              <Form.Item name={'fullName'} label='Tên nhân viên'>
                <Input></Input>
              </Form.Item>
              <Form.Item name={'gender'} label='Giới tính'>
              {isGetUserEmployeeLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select>
                        <Option value="M" key="male">
                          Nam
                        </Option>
                        <Option value="F" key="female">
                          Nữ
                        </Option>
                      </Select>
                    )}
              </Form.Item>
      
              <AddressFormSection
                span ={24}
                isLoading={isGetUserEmployeeLoading}
                form={form}
                cityCode={cityCode}
                setCityCode={setCityCode}
                districtCode={districtCode}
                setDistrictCode={setDistrictCode}
              />
            </BaseBorderBox>
            
            <BaseBorderBox title={<h5>Thông tin tài khoản  &nbsp;&nbsp; <Switch title='Cập nhật thông tin tài khoản' onChange={setStateUpPassword} unCheckedChildren='Cập nhật'></Switch></h5>} >
              <Form.Item name={'username'} label='Tài khoản'>
                <Input readOnly></Input>
              </Form.Item>
              <Form.Item 
                rules={[
                  {
                    required:stateUpPassword,
                    message: 'Xin mời nhập mật khẩu!'
                  },
                  { min: 6, message: 'Mật khẩu phải dài ít nhất 6 ký tự' }
                ]} 
                name={'password'} 
                label='Mật khẩu mới'>
                <Input.Password autoComplete='off' readOnly={!stateUpPassword}></Input.Password>
              </Form.Item>
              
              <Form.Item noStyle shouldUpdate={(old,now)=>!!(get(now,'password'))}>
                {
                  ({getFieldValue})=>{
                    return getFieldValue('password') ?
                      <Form.Item  
                        name={'passwordCofirm'} 
                        dependencies={['password']} 
                        label='Xác nhận mật khẩu'
                        rules={[
                          {
                            required:stateUpPassword,
                            message: 'Xin mời nhập lại mật khẩu!'
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Mật khẩu không khớp!'));
                            }
                          })
                        ]}
                        >
                        <Input.Password autoComplete='off'></Input.Password>
                      </Form.Item>:<></>
                      }
                }
              </Form.Item>
            
            </BaseBorderBox>
            <Space style={{width:'100%' ,justifyContent:'flex-end',marginTop:14}}>
              <Button htmlType='submit' type='primary'>Cập nhật</Button>
              <Button htmlType='button'>Huỷ</Button>
            </Space>
           </Form>
          </Col>
      </Row>
    </div>
  )
}

export default ModalProfile
