import { get } from 'lodash';
import { CONFIRM_VOUCHER_STATUS } from '~/constants/confirmVoucherWarehouse';
import requester from './requester';

const ConfirmVoucherWarehouse = {
  getById: (id) => requester.get(`/confirm-voucher/${id}`),
  create: (config) => requester.post(`/confirm-voucher`, config),
  update:(config) => requester.put(`/confirm-voucher-update/${config?.id}`,config),
  getAll : (query) => requester.get(`/confirm-voucher`,query),
  delete: (id) => requester.delete(`/confirm-voucher/${id}`),
  changeStatus: (payload) => requester.put(`/confirm-voucher/${payload?.id}/${payload?.status}`),
  confirm: (id) => requester.put(`/confirm-voucher/${id}/${CONFIRM_VOUCHER_STATUS.CONFIRM}`),
  cancel: (id) => requester.put(`/confirm-voucher/${id}/${CONFIRM_VOUCHER_STATUS.CANCEL}`),
  getAllByIdPartner: (query) => requester.get(`/confirm-voucher-partner/${get(query,'whPartnerId')}`,query),
  getOneByIdPartner: (id) => requester.get(`/confirm-voucher-one-partner/${id}`),
  
};

export default ConfirmVoucherWarehouse;
