
import { Button, Form, Input, Row, Switch, Typography } from 'antd';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { LANGUAGE } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useUiClients, useUpdateUiClient } from '~/hooks/UiClient';
import { Breadcrumb, WithOrPermission } from '../Common';
import LangFormItem from '../Common/LangFormItem';
import LangSelect from '../Common/LangSelect';
import UploadImage from '../Utils/UploadImage';
import LoadingWhBill from '../WhBill/LoadingWhBill';
const key = 'jobBanner';
const JobBanner = () => {
    const [language,setLanguage] = useState(LANGUAGE.VI);
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_JOB);
    const [form] = Form.useForm();
    const [UiClient, isLoading] = useUiClients(key);
    const [isSubmitLoading, update] = useUpdateUiClient();
    const initValue = useMemo(() => get(UiClient, ['value', key]), [UiClient])
    const disabled = useMemo(() => !canUpdate, [canUpdate]);
    const onFinish = (values) => {
        update({ key, ...values })
    };

    useEffect(() => {
        form.setFieldsValue({
            ...initValue
        })
    }, [initValue]);

    return (
        <div className="wh-service-form page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb title={'Tiêu đề tuyển dụng'} routes={[{ path: '', title: 'CMS' }]} />
                {isLoading && <LoadingWhBill open={true} />}
                <Form
                    form={form}
                    autoComplete="off"
                    initialValues={{}}
                    scrollToFirstError
                    requiredMark={false}
                    onFinish={onFinish}
                    labelCol={{ sm: 24, md: 24, lg: 2 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 22 }}
                >
                    {/* <Form.Item label="Hiển thị" valuePropName="checked" name={['active']} >
                        <Switch disabled={disabled} />
                    </Form.Item> */}
                    <LangSelect language={language} setLanguage={setLanguage}/>
                    <LangFormItem fieldName={["title"]} label="Tiêu đề" language={language}>
                        <Input disabled={disabled} placeholder='Nhập Tiêu đề' />
                    </LangFormItem>
                    <Form.Item shouldUpdate={(pre,next) => pre?.banner !== next?.banner} noStyle>
                        {({getFieldValue,setFieldsValue}) => <Form.Item name={["banner"]} label="Banner">
                        <UploadImage title={<Typography.Text strong type='secondary'>Banner (~1200-1400 X ~500-600)</Typography.Text>} className='jobBanner--banner' imgUrl={getFieldValue('banner')} onChange={(url) => setFieldsValue({ banner: url })} />
                    </Form.Item>}
                    </Form.Item>

                    <WithOrPermission permission={[POLICIES.UPDATE_JOB, POLICIES.WRITE_JOB, POLICIES.DELETE_JOB]}>
                        <Row justify='center'>
                            <Button
                                loading={isSubmitLoading}
                                type="primary"
                                htmlType="submit"
                            >
                                Cập nhật
                            </Button>
                        </Row>
                    </WithOrPermission>
                </Form>
            </div>
        </div>
    );
};
export default JobBanner;
