
export const GET_NOTIFY_ACCOUNTS_REQUEST = 'GET_NOTIFY_ACCOUNTS_REQUEST';
export const GET_NOTIFY_ACCOUNTS_SUCCESS = 'GET_NOTIFY_ACCOUNTS_SUCCESS';
export const GET_NOTIFY_ACCOUNTS_FAILED = 'GET_NOTIFY_ACCOUNTS_FAILED';

export const UPDATE_NOTIFY_ACCOUNTS_REQUEST = 'UPDATE_NOTIFY_ACCOUNTS_REQUEST';
export const UPDATE_NOTIFY_ACCOUNTS_FAILED = 'UPDATE_NOTIFY_ACCOUNTS_FAILED';
export const UPDATE_NOTIFY_ACCOUNTS_SUCCESS = 'UPDATE_NOTIFY_ACCOUNTS_SUCCESS';


