import requester from './requester';

const whPartnerRegister = {
  getAll: (query) => requester.get('/whPartnerRegister', query),
  getById: (id) => requester.get(`/whPartnerRegister/${id}`),
  create: (payload) => requester.post('/whPartnerRegister', payload),
  update: (payload) => requester.put(`/whPartnerRegister/${payload._id}`, payload),
  delete: (id) => requester.delete(`/whPartnerRegister/${id}`)
};

export default whPartnerRegister;
