import { Col, Descriptions, Row } from 'antd';
import SkeletonInput from 'antd/lib/skeleton/Input';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react'
import { useWhAppointment } from '~/hooks';

export default function InfomationWhAppointment({id}) {
    const [whAppointment,loading] = useWhAppointment(id)
    const descriptionItemProps = {
        contentStyle: {
          fontWeight: "bold",
          textAlign: "right",
        },
        labelStyle:{
            width : '200px'
        }
      }
      const renderLoading = (component) => loading ? <SkeletonInput style={{width : '200px'}}/> : component
  return (
    <>
          <Row style={{width : '100%', margin : '10px 0'}} align='bottom'>
         <Col span={24}>
           <Descriptions bordered column={1}>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Mã buổi hẹn</span> 
               </div>}
             >
              {renderLoading(get(whAppointment,'code',''))}
             </Descriptions.Item>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Ngày thực hiện</span> 
               </div>}
             >
              {renderLoading(moment(get(whAppointment,'date','')).format("DD-MM-YYYY"))}
             </Descriptions.Item>
             <Descriptions.Item
               {...descriptionItemProps}
               label={<div style={{ display: "flex", justifyContent: "space-between" }}><span>Giờ thực hiện</span> 
               </div>}
             >
              {renderLoading(get(whAppointment,'time',''))}
             </Descriptions.Item>
           </Descriptions>
         </Col>
       </Row>
    </>
  )
}
