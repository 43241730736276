import React, { useEffect, useMemo, useState } from 'react';
import { List, Spin, Table, Tabs } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import api from '~/api';
import { formatNumberThreeCommaToFixed } from '~/hooks/utils';
import { get } from 'lodash';
import { useWhAppointment } from '~/hooks';
import toastr from 'toastr';
import { useWindowSize } from '~/utils/helper';
import TooltipTitle from '~/components/Common/TooltipTitle';

export const checkAddress = (address) => {
  return typeof address === 'string'
    ? address
    : `${address?.street},${address?.ward},${address?.district},${address?.city}`;
};

export const MobileRenderTab1 = ({ data, isLoading, whPartnerId }) => {
  const renderField = (record, whPartnerId) => {
    const checkIsPartner = record?.supplierId === whPartnerId;
    return !!checkIsPartner;
  };
  return isLoading ? (
    <Spin />
  ) : (
    <List
      itemLayout="horizontal"
      dataSource={data || []}
      renderItem={(item) => {
        let message = 'Dịch vụ này sử dụng thiết bị của bạn';
        const showMessenger = renderField(item);
        return !renderField(item, whPartnerId) ? (
          <List.Item>
            <List.Item.Meta
              title={
                <h5>{`${item?.product?.name} - ${formatNumberThreeCommaToFixed(
                  item?.distanceKm
                )}km`}</h5>
              }
              description={`${
                item?.supplier?.name?.vi || item?.supplier?.name
              } - ${checkAddress(item?.supplier?.address)}`}
            />
          </List.Item>
        ) : (
          <List.Item>
            <List.Item.Meta
              title={
                <TooltipTitle
                  dot={!showMessenger}
                  titleTooltip={!showMessenger ? message : ''}
                  content={item?.product?.name}
                />
              }
              description={`${
                item?.supplier?.name?.vi || item?.supplier?.name
              }`}
            />
          </List.Item>
        );
      }}
    />
  );
};
export const MobileRenderTab2 = ({ data, isLoading, whPartnerId }) => {
  return isLoading ? (
    <Spin />
  ) : (
    <List
      itemLayout="horizontal"
      dataSource={data || []}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={
              <h5>{`Khoảng cách đến kho ${
                item?.branch?.name
              } : ${formatNumberThreeCommaToFixed(item?.distanceKm)}km`}</h5>
            }
            description={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {item?.listProduct?.map((item) => (
                  <h6>
                    {item?.product?.name} - {item?.totalQuantity}{' '}
                    {item?.unit?.name}{' '}
                  </h6>
                ))}
                <span>{checkAddress(item?.branch?.address)}</span>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};
/**
 *
 * @param {Object} partner Info of partner
 * @param {String} whAppointmentId Info of partner
 * @param {Array} whPartners Info of partner
 * @param {Object} row data in row of table
 * @returns
 */
export default function Distance({ whAppointmentCurrent }) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [activeKey, setActiveKey] = useState('1');
  const [width, height] = useWindowSize();

  const { whPartnerId, listConfirmed, _id, ...rest } = whAppointmentCurrent;
  const filterEquipment = listConfirmed?.filter((item) => !item.batchId);
  // const filterPartner = filterEquipment.filter(
  //   (item) => item.supplierId !== whPartnerId
  // );
  const filterMaterial = listConfirmed?.filter((item) => item.batchId);
  const filterMaterialPartner = filterMaterial?.map((item) => ({
    batchId: item?.batchId,
    quantity: item?.quantity,
    productId: item?.productId
  }));

  const listInfo =
    filterEquipment?.length > 0
      ? filterEquipment?.map((item) => ({
          productId: item?.productId,
          supplierId:
            item.supplierId === 'worldhealth' ? '99999' : item.supplierId
        }))
      : [];
  /**
   * @param {String} partnerId
   * @param {Array} listInfo This is a list of tools and their suppliers
   */
  const dataBody = useMemo(
    () => ({
      whAppointmentId: _id,
      listInfo: activeKey === '1' ? [...listInfo] : [...filterMaterialPartner],
      type: activeKey === '1' ? 'CCDC' : 'NVL',
      partnerId: whPartnerId
    }),
    [whAppointmentCurrent, activeKey]
  );

  /**
   * Get distance calculation data from partners to warehouses and from partners to equipment lending partners
   * @param {Object} dataBody
   */

  const getDistance = async () => {
    try {
      setDataSource([]);
      setIsLoading(true);
      const res = await api.Location.getDistanceWorkSpacePartner(dataBody);
      setDataSource(res ?? []);
      setIsLoading(false);
    } catch (error) {
      toastr.error(error);
      setIsLoading(false);
    }
  };

  const titleColumn = useMemo(
    () => (activeKey === '1' ? 'Công cụ dụng cụ' : 'Nguyên vật liệu'),
    [activeKey]
  );

  const handleChangeTab = (e) => {
    setActiveKey(e);
  };

  useEffect(() => {
    whAppointmentCurrent && getDistance();
  }, [whAppointmentCurrent, activeKey]);

  const renderField = (record) => {
    const checkIsPartner = record?.supplierId === whPartnerId;
    return !!!checkIsPartner;
  };

  const columns = [
    {
      title: 'Tên đơn vị cung cấp',
      dataIndex: 'name',
      render: (value, record) => {
        let message = 'Dịch vụ này sử dụng thiết bị của bạn';
        const showMessenger = renderField(record);
        return (
          <TooltipTitle
            dot={!showMessenger}
            titleTooltip={!showMessenger ? message : ''}
            content={record?.supplier?.name?.vi || record?.supplier?.name}
          />
        );
        // return renderField(record) ? (record?.supplier?.name?.vi || record?.supplier?.name) : <span>Chính bạn</span>
      }
    },
    {
      title: 'Khoảng cách',
      dataIndex: 'distanceKm',
      render: (value, record) =>
        renderField(record)
          ? `${formatNumberThreeCommaToFixed(get(record, 'distanceKm'))}km`
          : ''
    },
    {
      title: 'Địa chỉ nơi cung cấp',
      dataIndex: 'address',
      render: (value, record) =>
        renderField(record) ? checkAddress(record?.supplier?.address) : ''
    },
    {
      title: `Tên ${titleColumn?.toLocaleLowerCase()}`,
      dataIndex: 'product',
      render: (value, record) => record?.product?.name
    }
  ];

  const columnsTab2 = [
    {
      title: 'Tên đơn vị cung cấp',
      dataIndex: 'name',
      render: (value, record) =>
        record?.branch?.name?.vi ?? record?.branch?.name
    },
    {
      title: 'Khoảng cách',
      dataIndex: 'distanceKm',
      render: (value, record) =>
        `${formatNumberThreeCommaToFixed(get(record, 'distanceKm'))}km`
    },
    {
      title: 'Địa chỉ nơi cung cấp',
      dataIndex: 'address',
      render: (value, record) => checkAddress(record?.branch?.address)
    },
    {
      title: `Tên ${titleColumn?.toLocaleLowerCase()}`,
      dataIndex: 'product',
      render: (value, record) => {
        return record?.listProduct
          ?.map(
            (item) =>
              `${item?.product?.name} - ${item?.totalQuantity}  ${item?.unit?.name}`
          )
          ?.toString();
      }
    }
  ];

  const renderLoading = (prop) => {
    return isLoading ? <Spin /> : prop;
  };
  const renderComponent = (desktop, mobile) => {
    if (dataSource.length > 0) {
      return width > 768 ? desktop : mobile;
    } else {
      return (
        <h5>
          {' '}
          Không có {activeKey === '1'
            ? 'công cụ dụng cụ'
            : 'nguyên vật liệu'}{' '}
          được cung cấp
        </h5>
      );
    }
  };
  return (
    <Tabs onChange={handleChangeTab}>
      <Tabs.TabPane tab="Công cụ dụng cụ" key="1">
        {renderLoading(
          renderComponent(
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />,
            <MobileRenderTab1
              data={dataSource}
              isLoading={isLoading}
              whPartnerId={whPartnerId}
            />
          )
        )}
      </Tabs.TabPane>
      <Tabs.TabPane tab="Nguyên vật liệu" key="2">
        {renderLoading(
          renderComponent(
            <Table
              loading={isLoading}
              columns={columnsTab2}
              dataSource={dataSource}
              pagination={false}
            />,
            <MobileRenderTab2
              data={dataSource}
              isLoading={isLoading}
              whPartnerId={whPartnerId}
            />
          )
        )}
      </Tabs.TabPane>
    </Tabs>
    // isLoading
    //   ? <SkeletonTable columns={columns} rowCount={3} />
    //   : <Table columns={columns} dataSource={dataSource} pagination={false} />
  );
}
