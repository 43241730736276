import { Modal, Table, Typography } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import api from '~/api';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { useMedicalEquipment } from '~/hooks/medicalEquipment';
import { useProductCatalogueStatus } from '~/hooks/productCatalogue';
import { useModalVoucherWarehouse } from '~/hooks/warehouseVoucher';
import FormVoucherWarehouse from '../FormVoucher/FormVoucherWarehouse';


function ListProductByStatus({ id, status}) {
  const _id = id
  const [page, setPage] = useState(1)
  const groupProductId =useMemo(
    () => ({
      page: page,
      limit: 10,
      id: _id,
      status
    }),
    [_id, page, status]
  );
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paging, setPaging] = useState({});
  const {handleCloseModal,handleOpenModal,id:idVoucher,isOpenModal} = useModalVoucherWarehouse()
  const featchData = async() => {
    const res = await api.productCatalogue.getByStatus(groupProductId)
    setData(res.docs)
    setIsLoading(!res)
    setPaging({
      page: res?.docs?.data,
      limit: res?.docs?.limit,
      totalDocs: res?.docs?.totalDocs,
    })
    
  }
  
  useEffect(() => {
    featchData()
  },[ groupProductId])
  const columns = [
    {
      title: 'STT',
      align: 'start',
      key: 'stt',
      width: '25%',
      render: (item, record, index) => (
        <span>
          {get(paging, 'pageSize', 0) * (get(paging, 'current', 0) - 1) +
            index + 1}
        </span>
      )
    },
    {
      title: 'Nhóm sản phẩm',
      align: 'start',
      dataIndex: 'groupProductName',
      key: 'groupProductName',
      width: '25%',
      render: (value, record) => get(record, 'productPortfolio.name.vi', '')

    },
    {
      title:'Tên thiết bị',
      align: 'start',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title:'Phiếu bàn giao',
      align: 'start',
      dataIndex: 'commercialPurchaseVoucherInfo',
      key: 'commercialPurchaseVoucherInfo',
      width: '25%',
      render : (value, record) => {
        const voucher = get(value,'[0]');
        return value && <Typography.Link
        onClick={() => handleOpenModal(get(voucher,'_id'))}
        >
          {get(voucher,'codeCommerPurchVoucher')}
        </Typography.Link>
      }
    },
  ];

  return (
    <React.Fragment>
      {isLoading ? (
        <SkeletonTable
          rowCount={data.length}
          columns={columns}
          pagination={paging}
        />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          dataSource={data}
          size="middle"
          onChange={(e) => setPage(e)}
          pagination={{
              ...paging,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
        />
      )}
      <Modal
        width={'auto'}
        visible={isOpenModal}
        footer={null}
        onCancel={handleCloseModal}
        destroyOnClose
        className='custom-modal-responsive'
      >
        <FormVoucherWarehouse id={idVoucher} onCancel={handleCloseModal}  />
      </Modal>
    </React.Fragment>
  );
}

export default ListProductByStatus;
