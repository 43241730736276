import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Popover, Row } from 'antd';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import api from '~/api';
import DebounceSelectV2 from '../Common/DebouceSelectV2';
import FormPositionJob from './FormPositionJob';
export default function SelectPositionJob({ canCreate = true, required = true, value, ...props }) {
    const count = useRef(0);
    const [initOptions, setInitOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const hide = () => {
        setOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const fetch = async (keyword) => {
        try {
            const query = { page: 1, limit: 10, keyword };
            const optionsWith = {
                ids: value
            };
            if (value) {
                query.optionsWith = optionsWith;
            }
            const res = await api.positionJob.getAll(query);
            return get(res, 'options', []);
        } catch (error) {
            return []
        }
    };
    ////
    useEffect(() => {
        const initFetch = async () => {
            const options = await fetch('');
            setInitOptions(options);
            count.current++;
        };
        count.current < 2 && initFetch();
    }, [value]);


    return (
        <Row>
            <Col flex={1}>
                <Form.Item
                    label="Vị trí"
                    name="category"
                    rules={[
                        {
                            required,
                            message: 'Xin vui lòng chọn Vị trí!'
                        }
                    ]}
                >
                    {/* <Select {...props} allowClear  options={options} loading={loading} placeholder="Vui lòng chọn vị trí" /> */}
                    <DebounceSelectV2
                        fetchOptions={fetch}
                        initOptions={initOptions}
                        value={value}
                        allowClear
                        {...props}
                    />
                </Form.Item>
            </Col>
            {canCreate && <Col>
                <Popover visible={open} onVisibleChange={handleOpenChange} trigger="click" destroyTooltipOnHide content={<FormPositionJob onCancel={() => {
                    hide();
                }} />}>
                    <Button icon={<PlusOutlined />} />
                </Popover>
            </Col>}
        </Row>

    )
}
