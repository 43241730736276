import React from 'react';

import {
  COMPONENT_MODES,
  MAX_VALUE_CONFIG_CAMPAIGN,
  WH_PARTNER_WAY_WORKING_EN
} from '~/constants/defaultValue';
import { Col, Form, Progress, Row, Select, Space, Steps, Tag } from 'antd';
import { get } from 'lodash';
import Text from 'antd/lib/typography/Text';

const FormItem = Form.Item;
const { Option } = Select;

const verticalLayout = {
  wrapperCol: { sm: 24 },
  labelCol: { xs: 8, sm: 8 }
};

const WhPartnerMiniFormSection = ({
  isSpecialitiesLoading,
  isStaffGroupLoading,
  specialities,
  staffGroups,
  mode,
  whPartner,
}) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }} size="middle">
      <Row>
        <Col span={24}>
          {
            {
              [COMPONENT_MODES.VIEW]: (
                <Row justify="start">
                  <Space>
                    <span>Nhóm nhân sự:</span>
                    <strong>
                      {get(
                        staffGroups.find(
                          (item) =>
                            (get(item, '_id') || get(item, 'id')) ===
                            get(whPartner, 'employeeGroupId')
                        ),
                        'name'
                      )}
                    </strong>
                  </Space>
                </Row>
              ),

              [COMPONENT_MODES.EDIT]: (
                <FormItem
                  label="Nhóm nhân sự"
                  name="employeeGroupId"
                  {...verticalLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn nhóm nhân sự'
                    }
                  ]}
                >
                  {/* {isLoading ? ( */}
                  {false ? (
                    <Skeleton.Input active />
                  ) : (
                    <Select
                      loading={isStaffGroupLoading}
                      showSearch
                      autoComplete="off"
                      disabled={
                        isStaffGroupLoading || mode === COMPONENT_MODES.VIEW
                      }
                      // filterOption={filterAcrossAccents}
                    >
                      {staffGroups.map(({ id, _id, name }) => (
                        <Option key={id || _id} value={id || _id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              )
            }[mode]
          }
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {
            {
              [COMPONENT_MODES.VIEW]: (
                <Space
                  direction="vertical"
                  style={{ width: '100%' }}
                  size="small"
                >
                  <Row justify="space-between">
                    <span>Chuyên môn:</span>
                  </Row>
                  <Row>
                    {get(whPartner, 'specialityIds')?.map(
                      (specialityId, index) => (
                        <Tag key={index} color="blue">
                          {get(
                            specialities.find(
                              (item) => get(item, 'id') === specialityId
                            ),
                            'name'
                          )}
                        </Tag>
                      )
                    )}
                  </Row>
                </Space>
              ),

              [COMPONENT_MODES.EDIT]: (
                <FormItem
                  label="Chuyên môn"
                  name="specialityIds"
                  {...verticalLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn chuyên môn'
                    }
                  ]}
                >
                  {false ? (
                    <Skeleton.Input active />
                  ) : (
                    <Select
                      mode="multiple"
                      placeholder=""
                      style={{ width: '100%' }}
                      loading={isSpecialitiesLoading}
                      disabled={
                        isSpecialitiesLoading || mode === COMPONENT_MODES.VIEW
                      }
                    >
                      {specialities.map(({ name, id }) => (
                        <Option value={id} key={id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              )
            }[mode]
          }
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {
            {
              [COMPONENT_MODES.VIEW]: (
                <Space
                  direction="vertical"
                  style={{ width: '100%' }}
                  size="small"
                >
                  <Row>
                    <span>Hình thức làm việc:</span>
                    <Text style={{ margin: '0 10px' }} strong>
                      {
                        WH_PARTNER_WAY_WORKING_EN[
                          get(whPartner, 'wayWorking', '')
                        ]
                      }
                    </Text>
                  </Row>
                  <Row></Row>
                </Space>
              ),

              [COMPONENT_MODES.EDIT]: (
                <FormItem
                  label="Chuyên môn"
                  name="specialityIds"
                  {...verticalLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn chuyên môn'
                    }
                  ]}
                >
                  {false ? (
                    <Skeleton.Input active />
                  ) : (
                    <Select
                      mode="multiple"
                      placeholder=""
                      style={{ width: '100%' }}
                      loading={isSpecialitiesLoading}
                      disabled={
                        isSpecialitiesLoading || mode === COMPONENT_MODES.VIEW
                      }
                    >
                      {specialities.map(({ name, id }) => (
                        <Option value={id} key={id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              )
            }[mode]
          }
        </Col>
       
      </Row>
    </Space>
  );
};

export default WhPartnerMiniFormSection;
