import * as Types from '../../constants/actionTypes';

export const getRateConfigCertificates = () => ({
  type: Types.GET_RATE_CONFIGS_OF_CERTIFICATE_REQUEST
});

export const getRateConfigDegrees = query => ({
  type: Types.GET_RATE_CONFIGS_OF_DEGREE_REQUEST,
  payload : query
});

export const getRateConfigExperiences = query => ({
  type: Types.GET_RATE_CONFIGS_OF_EXPERIENCE_REQUEST,
  payload : query
});

export const getRateConfigPositions = query => ({
  type: Types.GET_RATE_CONFIGS_OF_POSITION_REQUEST,
  payload : query
});

export const getRateConfig = id => ({
  type: Types.GET_RATE_CONFIG_REQUEST,
  payload: id
});

export const createRateConfig = staffGroup => ({
  type: Types.CREATE_RATE_CONFIG_REQUEST,
  payload: staffGroup
});

export const updateRateConfig = staffGroup => ({
  type: Types.UPDATE_RATE_CONFIG_REQUEST,
  payload: staffGroup
});

export const deleteRateConfig = id => ({
  type: Types.DELETE_RATE_CONFIG_REQUEST,
  payload: id
});

export const resetRateConfigState = () => ({
  type: Types.RESET_RATE_CONFIG_STATE
});
