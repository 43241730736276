export const GET_WH_PACKAGE_LEVELS_REQUEST = 'GET_WH_PACKAGE_LEVELS_REQUEST';
export const GET_WH_PACKAGE_LEVELS_SUCCESS = 'GET_WH_PACKAGE_LEVELS_SUCCESS';
export const GET_WH_PACKAGE_LEVELS_FAILED = 'GET_WH_PACKAGE_LEVELS_CATEGORIES_FAILED';

export const GET_WH_PACKAGE_LEVEL_REQUEST = 'GET_WH_PACKAGE_LEVEL_REQUEST';
export const GET_WH_PACKAGE_LEVEL_SUCCESS = 'GET_WH_PACKAGE_LEVEL_SUCCESS';
export const GET_WH_PACKAGE_LEVEL_FAILED = 'GET_WH_PACKAGE_LEVEL_FAILED';

export const CREATE_WH_PACKAGE_LEVEL_REQUEST = 'CREATE_WH_PACKAGE_LEVEL_REQUEST';
export const CREATE_WH_PACKAGE_LEVEL_SUCCESS = 'CREATE_WH_PACKAGE_LEVEL_SUCCESS';
export const CREATE_WH_PACKAGE_LEVEL_FAILED = 'CREATE_WH_PACKAGE_LEVEL_FAILED';

export const UPDATE_WH_PACKAGE_LEVEL_REQUEST = 'UPDATE_WH_PACKAGE_LEVEL_REQUEST';
export const UPDATE_WH_PACKAGE_LEVEL_SUCCESS = 'UPDATE_WH_PACKAGE_LEVEL_SUCCESS';
export const UPDATE_WH_PACKAGE_LEVEL_FAILED = 'UPDATE_WH_PACKAGE_LEVEL_FAILED';

export const DELETE_WH_PACKAGE_LEVEL_REQUEST = 'DELETE_WH_PACKAGE_LEVEL_REQUEST';
export const DELETE_WH_PACKAGE_LEVEL_SUCCESS = 'DELETE_WH_PACKAGE_LEVEL_SUCCESS';
export const DELETE_WH_PACKAGE_LEVEL_FAILED = 'DELETE_WH_PACKAGE_LEVEL_FAILED';

// export const RESET_WH_PACKAGE_LEVEL_STATE = 'RESET_WH_PACKAGE_LEVEL_STATE';
