import * as Types from '../../constants/actionTypes';
  
  export const getJobForms = query => ({
    type: Types.GET_JOB_FORMS_REQUEST,
    payload: query
  });
  export const getJobForm = id => ({
    type: Types.GET_JOB_FORM_REQUEST,
    payload: id
  });
  export const createJobForm = instance => ({
    type: Types.CREATE_JOB_FORM_REQUEST,
    payload: instance
  });
  export const updateJobForm = instance => ({
    type: Types.UPDATE_JOB_FORM_REQUEST,
    payload: instance
  });
  export const deleteJobForm = id => ({
    type: Types.DELETE_JOB_FORM_REQUEST,
    payload: id
  });
  export const resetJobFormState = () => ({
    type: Types.RESET_JOB_FORM_STATE
  });
