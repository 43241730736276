export const GET_OVER_VIEW_REPORT_REQUEST = 'GET_OVER_VIEW_REPORT_REQUEST';
export const GET_OVER_VIEW_REPORT_SUCCESS = 'GET_OVER_VIEW_REPORT_SUCCESS';
export const GET_OVER_VIEW_REPORT_FAILED = 'GET_OVER_VIEW_REPORT_FAILED';

export const GET_YEARLY_REPORT_REQUEST = 'GET_YEARLY_REPORT_REQUEST';
export const GET_YEARLY_REPORT_SUCCESS = 'GET_YEARLY_REPORT_SUCCESS';
export const GET_YEARLY_REPORT_FAILED = 'GET_YEARLY_REPORT_FAILED';

export const GET_QUARTERLY_REPORT_REQUEST = 'GET_QUARTERLY_REPORT_REQUEST';
export const GET_QUARTERLY_REPORT_SUCCESS = 'GET_QUARTERLY_REPORT_SUCCESS';
export const GET_QUARTERLY_REPORT_FAILED = 'GET_QUARTERLY_REPORT_FAILED';

export const GET_MONTHLY_REPORT_REQUEST = 'GET_MONTHLY_REPORT_REQUEST';
export const GET_MONTHLY_REPORT_SUCCESS = 'GET_MONTHLY_REPORT_SUCCESS';
export const GET_MONTHLY_REPORT_FAILED = 'GET_MONTHLY_REPORT_FAILED';

export const GET_DAILY_REPORT_REQUEST = 'GET_DAILY_REPORT_REQUEST';
export const GET_DAILY_REPORT_SUCCESS = 'GET_DAILY_REPORT_SUCCESS';
export const GET_DAILY_REPORT_FAILED = 'GET_DAILY_REPORT_FAILED';

export const GET_MOST_SERVICE_REQUEST = 'GET_MOST_SERVICE_REQUEST';
export const GET_MOST_SERVICE_SUCCESS = 'GET_MOST_SERVICE_SUCCESS';
export const GET_MOST_SERVICE_FAILED = 'GET_MOST_SERVICE_FAILED';

export const GET_MOST_REVENUE_SERVICE_REQUEST = 'GET_MOST_REVENUE_SERVICE_REQUEST';
export const GET_MOST_REVENUE_SERVICE_SUCCESS = 'GET_MOST_REVENUE_SERVICE_SUCCESS';
export const GET_MOST_REVENUE_SERVICE_FAILED = 'GET_MOST_REVENUE_SERVICE_FAILED';

export const GET_MOST_PARTNER_APPOINTMENT_REQUEST = 'GET_MOST_PARTNER_APPOINTMENT_REQUEST';
export const GET_MOST_PARTNER_APPOINTMENT_SUCCESS = 'GET_MOST_PARTNER_APPOINTMENT_SUCCESS';
export const GET_MOST_PARTNER_APPOINTMENT_FAILED = 'GET_MOST_PARTNER_APPOINTMENT_FAILED';

export const GET_MOST_CUSTOMER_APPOINTMENT_REQUEST = 'GET_MOST_CUSTOMER_APPOINTMENT_REQUEST';
export const GET_MOST_CUSTOMER_APPOINTMENT_SUCCESS = 'GET_MOST_CUSTOMER_APPOINTMENT_SUCCESS';
export const GET_MOST_CUSTOMER_APPOINTMENT_FAILED = 'GET_MOST_CUSTOMER_APPOINTMENT_FAILED';

export const GET_OVERVIEW_TODAY_REQUEST = 'GET_OVERVIEW_TODAY_REQUEST';
export const GET_OVERVIEW_TODAY_SUCCESS = 'GET_OVERVIEW_TODAY_SUCCESS';
export const GET_OVERVIEW_TODAY_FAILED = 'GET_OVERVIEW_TODAY_FAILED';

export const GET_OVERVIEW_DAILY_REQUEST = 'GET_OVERVIEW_DAILY_REQUEST';
export const GET_OVERVIEW_DAILY_SUCCESS = 'GET_OVERVIEW_DAILY_SUCCESS';
export const GET_OVERVIEW_DAILY_FAILED = 'GET_OVERVIEW_DAILY_FAILED';

export const GET_OVERVIEW_MONTHLY_REQUEST = 'GET_OVERVIEW_MONTHLY_REQUEST';
export const GET_OVERVIEW_MONTHLY_SUCCESS = 'GET_OVERVIEW_MONTHLY_SUCCESS';
export const GET_OVERVIEW_MONTHLY_FAILED = 'GET_OVERVIEW_MONTHLY_FAILED';

export const GET_OVERVIEW_QUARTERLY_REQUEST = 'GET_OVERVIEW_QUARTERLY_REQUEST';
export const GET_OVERVIEW_QUARTERLY_SUCCESS = 'GET_OVERVIEW_QUARTERLY_SUCCESS';
export const GET_OVERVIEW_QUARTERLY_FAILED = 'GET_OVERVIEW_QUARTERLY_FAILED';

export const GET_OVERVIEW_YEARLY_REQUEST = 'GET_OVERVIEW_YEARLY_REQUEST';
export const GET_OVERVIEW_YEARLY_SUCCESS = 'GET_OVERVIEW_YEARLY_SUCCESS';
export const GET_OVERVIEW_YEARLY_FAILED = 'GET_OVERVIEW_YEARLY_FAILED';

export const RESET_DASHBOARD_STATE = 'RESET_DASHBOARD_STATE';
