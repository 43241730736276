import requester from './requester';

const PercentChangeCustomerToPartner = {
  getAll: (query) => requester.get(`/percent-change-customer-to-partner`, query),
  getById: (id) => requester.get(`/percent-change-customer-to-partner/${id}`),
  create: (query) => requester.post('/percent-change-customer-to-partner', query),
  update: (query) => requester.put(`/percent-change-customer-to-partner/${query._id}`, query),
  delete: (id) => requester.delete(`/percent-change-customer-to-partner/${id}`),
  convert: (id) => requester.post(`/convert-account-customer-to-partner/${id}`),
};

export default PercentChangeCustomerToPartner;
