import produce from 'immer';
import { compact, forEach, forIn, get, groupBy, head, isObject, keys, uniqBy } from 'lodash';
import {
  GET_WH_REPORT_CUSTOMERS_FAILED,
  GET_WH_REPORT_CUSTOMERS_REQUEST,
  GET_WH_REPORT_CUSTOMERS_SUCCESS,
  GET_WH_REPORT_CUSTOMER_FAILED,
  GET_WH_REPORT_CUSTOMER_REQUEST,
  GET_WH_REPORT_CUSTOMER_SUCCESS,
  RESET_STORE,
  RESET_WH_REPORT_CUSTOMER_STATE,
} from '../../constants/actionTypes';
import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getWhReportCustomersFailed: null,
  whReportCustomers: [],

  isLoadingWhReportCustomer: false,
  getWhReportCustomerFailed: null,
  whReportCustomer: null,


  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_WH_REPORT_CUSTOMERS_REQUEST:
      state.isLoading = true;
      state.getWhReportCustomersFailed = null;
      return;

    case GET_WH_REPORT_CUSTOMERS_SUCCESS:
      state.isLoading = false;
      const newMergeAddress = get(payload,'docs',[]).map(e => ({
          ...e,address :get(get(payload,'arrStreetFullResult',[]).find(value => get(value,'customerId','') === get(e,'customerId','')),'address','')
        })
      )
      state.whReportCustomers = newMergeAddress;
      state.getWhReportCustomersFailed = null;
      state.paging = getPaging(payload);
      return;

    case GET_WH_REPORT_CUSTOMERS_FAILED:
      state.isLoading = false;
      state.whReportCustomers = [];
      state.getWhReportCustomersFailed = null;
      return;
    case GET_WH_REPORT_CUSTOMER_REQUEST:
      state.isLoadingWhReportCustomer = true;
      state.getWhReportCustomerFailed = null;
      state.whReportCustomer = null;
      return;

    case GET_WH_REPORT_CUSTOMER_SUCCESS:
      state.isLoadingWhReportCustomer = false;
      const removeDuplicate = uniqBy(payload,(e) => get(e,'_id'))
      const groupByWhBill = groupBy(removeDuplicate,(e) => get(e,'whBillId'))
      let dataSource = []
      forIn(groupByWhBill,(value,key,obj) => {
        if(key === 'undefined') return
        forEach(value,(e,index) => {
          if(index === 0){
            dataSource.push({...e,rowSpan : value.length})
          }else{
            dataSource.push({...e,rowSpan : 0})
          }
        })
      })
      const newDataSource = dataSource?.map(e => {
        const totalRemain = dataSource?.reduce((sum,cur) => {
          if(get(cur,'whBillId') === get(e,'whBillId')){
          return  sum + get(cur,'remainingAmount')
          }
        },0)
        return {...e,totalRemainingAmount : totalRemain}
      })
      
      state.whReportCustomer = newDataSource
      return;

    case GET_WH_REPORT_CUSTOMER_FAILED:
      state.isLoadingWhReportCustomer = false;
      state.getWhReportCustomerFailed = payload;
      return;


    case RESET_WH_REPORT_CUSTOMER_STATE:
    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
