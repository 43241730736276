import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getBranches,
  createBranch,
  deleteBranch,
  getBranch,
  resetBranchState,
  updateBranch,
  updateBranchStatus,
  getResourceBranch,
  getResourceAccessedBranch,
  accessResourceBranch,
  denyResourceBranch
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { initWorkingHours } from '~/hooks/hospital';
import moment from 'moment';
import { ACTION } from '~/constants/policy';

const BRANCH_MODULE = 'branch';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(BRANCH_MODULE);

const getSelector = (key) => (state) => state[BRANCH_MODULE][key];
const pagingSelector = getSelector('paging');
const resourceBranchSelector = getSelector('resourceBranch');
const isGetResourceBranchLoadingSelector = getSelector('isGetResourceBranchLoading');
const getResourceBranchFailedSelector = getSelector('getResourceBranchFailed');

const resourceAccessedBranchSelector = getSelector('resourceAccessedBranch');
const isGetResourceAccessedBranchLoadingSelector = getSelector('isGetResourceAccessedBranchLoading');
const getResourceAccessedBranchFailedSelector = getSelector('getResourceAccessedBranchFailed');

const accessResourceSuccessSelector = getSelector('accessResourceSuccess');
const accessResourceFailedSelector = getSelector('accessResourceFailed');

const denyResourceSuccessSelector = getSelector('denyResourceSuccess');
const denyResourceFailedSelector = getSelector('denyResourceFailed');

export const useBranches = (query) => {
  return useFetchByParam({
    action: getBranches,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateBranch = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới chi nhánh thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới chi nhánh thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createBranch
  });
};

export const useUpdateBranch = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật chi nhánh thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector,
    action: updateBranch
  });
};


export const useDeleteBranch = (onDeleteSuccess) => {
  useSuccess(deleteSuccessSelector, 'Xoá chi nhánh thành công', onDeleteSuccess);
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector,
    action: deleteBranch
  });
};

export const useBranch = (params) => {
  return useFetchByParam({
    action: getBranch,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitBranch = (branch, id, isOpen,branchType) => {
  return useMemo(() => {
    if (!branch || !id || !isOpen) {
      return {
        workingHours: initWorkingHours,
        services: [
          {
            name: '',
            description: '',
            items: []
          }
        ]
      };
    }

    const initBranch = {
      ...branch,
      workingHours: branch?.workingHours?.map((hour) => ({
        ...hour,
        startTime: moment(hour.startTime, 'HH:mm'),
        endTime: moment(hour.endTime, 'HH:mm')
      })),
      branchType,
    };

    return initBranch;
  }, [branch, id, isOpen]);
};

export const useBranchBranches = (id) => {
  return useFetchByParam({
    action: getBranches,
    loadingSelector: getBranchesLoadingSelector,
    dataSelector: branchesSelector,
    failedSelector: getBranchesFailedSelector,
    param: id
  });
};

export const useUpdateBranchParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

    history.push({
      pathname: '/branch',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useBranchQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  const modules = query.get('modules');

  const onTableChange = ({ current }) => setPage(current);

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      keyword,
      cityId,
      modules
    });

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [keyword, cityId, modules, createSuccess, updateSuccess, deleteSuccess]);
};

export const useBranchPaging = () => useSelector(pagingSelector);

export const useBranchTree = (branches) => {
  return useMemo(() => {
    if (!branches) {
      return [];
    }

    const list = JSON.parse(JSON.stringify(branches));

    const keyIndexDict = list.reduce(
      (dict, { _id }, index) => ({ ...dict, [_id]: index }),
      {}
    );

    const tree = [];

    list.forEach((item) => {
      if (item?.parentId) {
        const parentIndex = keyIndexDict[item?.parentId];

        if (parentIndex || parentIndex === 0) {
          list[parentIndex].children = [
            ...(list[parentIndex]?.children || []),
            item
          ];
        }
      } else {
        tree.push(item);
      }
    });
    if(tree?.length){
      return tree
    }else{
      return list
    }
  }, [branches]);
};

export const useResetBranch = () => {
  useResetState(resetBranchState);
};

export const useResourceBranchColumns = (RenderPermission) => {
  const actionColumns = ACTION.map(({ name, key }) => {
    return ({
      title: name,
      key: key,
      width : 100,
      align:'center',
      render: (item,rc) => RenderPermission(key.toLowerCase(),rc)
    })
  });

  return [
    {
      title: 'Chức năng',
      dataIndex: 'name',
      key: 'name',
      width : 'auto',
    },
    ...actionColumns
  ];
};

export const useResourceBranch = (param) => {
  return useFetchByParam({
    action: getResourceBranch,
    loadingSelector : isGetResourceBranchLoadingSelector,
    dataSelector: resourceBranchSelector,
    failedSelector: getResourceBranchFailedSelector,
    param
  });
}

export const useResourceAccessedBranch = (branchId) => {
  return useFetchByParam({
    action: getResourceAccessedBranch,
    loadingSelector : isGetResourceAccessedBranchLoadingSelector,
    dataSelector: resourceAccessedBranchSelector,
    failedSelector: getResourceAccessedBranchFailedSelector,
    param: branchId
  });
}

export const useAccessResourceBranch = (callback) => {
  useSuccess(accessResourceSuccessSelector, '', callback);
  useFailed(accessResourceFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: accessResourceBranch
  });
};

export const useDenyResourceBranch = (callback) => {
  useSuccess(denyResourceSuccessSelector, '', callback);
  useFailed(denyResourceFailedSelector);

  return useSubmit({
    loadingSelector : isSubmitLoadingSelector,
    action: denyResourceBranch
  });
};
