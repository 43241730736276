import React from 'react';
import {
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
  Redirect,
  useParams
} from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { PATH_APP } from '~/routes/paths';
import { NavItem, Nav } from 'reactstrap';
import WhCustomersManagement from './WhCustomerManagement';
import WhCustomersImport from './WhCustomerImport';
import PermissionGate from '~/routes/middleware/PermissionGate';
import WithPermission from '~/components/Common/WithPermission';
import POLICY from '~/constants/policy';

const WhManagement = () => {
  const { path, url } = useRouteMatch();

  const {
    location: { pathname }
  } = useHistory();

  const className = (path) => {
    return `nav-link ${pathname.split('/').includes(path) && 'active'}`;
  };

  return (
    <div className="management-detail page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          routes={[{ path: PATH_APP.whManagement.root, title: 'Danh sách khách hàng' }]}
        />

        <div className="management-detail__navbar">
          <Nav
            tabs
            className="nav-tabs-custom nav-justified mb-4"
            style={{
              // width: 700,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
          <WithPermission>
            <NavItem>
              <Link to={`${url}/customer-account`} className={className('customer-account')}>
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Quản lý khách hàng</span>
              </Link>
            </NavItem>
            </WithPermission>

            <WithPermission >
              <NavItem>
                <Link to={`${url}/potential-customer`} className={className('potential-customer')}>
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Import khách hàng</span>
                </Link>
              </NavItem>
            </WithPermission>
          </Nav>
        </div>

        <Switch>
          {/* <Route
            path={`${path}/customer-account`}
            component={() => <WhCustomersManagement />}
          /> */}
          <PermissionGate
            path={`${path}/customer-account`}
            component={WhCustomersManagement}
            // permission={POLICY.READ_USER}
          />

          <PermissionGate
            path={`${path}/potential-customer`}
            component={WhCustomersImport}
            // permission={POLICY.READ_BRANCH}
          />
          <Redirect to={`${path}/customer-account`}></Redirect>
        </Switch>
      </div>
    </div>
  );
};

export default WhManagement;
