import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getPercentChangeCustomerToPartners({ payload: query }) {
    try {
      const response = yield call(Api.percentChangeCustomerToPartner.getAll, query);
      yield put({ type: Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_FAILED, payload: error });
    }
  }
function* getPercentChangeCustomerToPartner({ payload: id }) {
    try {
      const response = yield call(Api.percentChangeCustomerToPartner.getById, id);
      yield put({ type: Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED, payload: error });
    }
  }
  function* createPercentChangeCustomerToPartner(action) {
    try {
      const data = yield call(Api.percentChangeCustomerToPartner.create, action.payload);
      yield put({ type: Types.CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED, payload: error });
    }
  }
  function* updatePercentChangeCustomerToPartner(action) {
    try {
      const data = yield call(Api.percentChangeCustomerToPartner.update, action.payload);
      yield put({ type: Types.UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED, payload: error });
    }
  }
export default function* PercentChangeCustomerToPartners() {
    yield takeLatest(Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_REQUEST, getPercentChangeCustomerToPartners);
    yield takeLatest(Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST, getPercentChangeCustomerToPartner);
    yield takeLatest(Types.CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST, createPercentChangeCustomerToPartner);
    yield takeLatest(Types.UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST, updatePercentChangeCustomerToPartner);
  }
