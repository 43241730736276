import React from 'react';

import { Col, Form, Input, Space, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

import UploadImage from '~/components/Utils/UploadImage';
import { LANGUAGE } from '~/constants/defaultValue';

import './index.scss';

const WhServiceQuestionOptionEdit = ({
  className = '',
  fieldKey,
  form,
  index,
  language,
  name,
  optionIdx,
  remove,
  setWhServiceQuestionOptionImg,
  select
}) => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <Col
      className={`wh-service-form__question--option ${className}`}
      sm={12}
      lg={6}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <UploadImage
          style={{ width: '100%' }}
          title="Hình ảnh"
          imgUrl={form.getFieldValue([
            'questions',
            index,
            'options',
            optionIdx,
            'image'
          ])}
          onChange={(img) =>
            setWhServiceQuestionOptionImg(img, index, optionIdx)
          }
        >
          <MinusCircleOutlined
            onClick={(e) => {
              e.stopPropagation();
              remove(optionIdx);
            }}
            style={{ position: 'absolute', right: 5, top: 5 }}
          />
        </UploadImage>
        <div>
          {select ? (
            <Select
              defaultValue="Web"
              style={{
                width: 120
              }}
              onChange={handleChange}
              options={[
                {
                  value: 'Web',
                  label: 'Web'
                },
                {
                  value: 'Mobile',
                  label: 'Mobile'
                }
              ]}
            />
          ) : (
            <div>
              <Form.Item
                // {...restField}
                // label={index === 0 && 'Lựa chọn'}
                wrapperCol={{ sm: 24 }}
                name={[name, 'title', LANGUAGE.VI]}
                fieldKey={[fieldKey, 'title', LANGUAGE.VI]}
                className={`${language !== LANGUAGE.VI && 'hiden'}`}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập lựa chọn'
                  }
                ]}
                // {...verticalLayout}
              >
                <Input width="100" placeholder="Lựa chọn" />
              </Form.Item>
              <Form.Item
                // {...restField}
                // label={index === 0 && 'Lựa chọn'}
                wrapperCol={{ sm: 24 }}
                name={[name, 'title', LANGUAGE.EN]}
                fieldKey={[fieldKey, 'title', LANGUAGE.EN]}
                className={`${language !== LANGUAGE.EN && 'hiden'}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter select'
                  }
                ]}
                // {...verticalLayout}
              >
                <Input width="100" placeholder="Select" />
              </Form.Item>
            </div>
          )}
        </div>
      </Space>
    </Col>
  );
};

export default WhServiceQuestionOptionEdit;
