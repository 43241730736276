
import { Col, Layout, Menu, Row } from 'antd';
import { find, get } from 'lodash';
import React, { isValidElement, useCallback, useEffect, useState } from 'react';
import { useGetDataNavbar } from '~/hooks';
import Content from './Content';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import './index.scss';
import TitleSubmenu from './TitleSubmenu';
const {  Sider } = Layout;

const Drag = () => {
  useEffect(() => {
    
  const dragElement = document.getElementById("homepage--drag");
  const menu = document.querySelector(".homepage--left");
  const minWidth = 230;
  const maxWidth = 600;
  
  if(dragElement){
    let isDragging = false;
    const handleDrag = (e) => {
      if(isDragging) {
        if(e.clientX > minWidth && e.clientX < maxWidth){
          menu.style.width = e.clientX + 'px';
        }
      }
    }
    dragElement.addEventListener('mousedown',() => {
      isDragging = true;
      document.addEventListener('mousemove' , handleDrag);
      document.addEventListener('mouseup',() => {
        isDragging = false;
        document.removeEventListener('mousemove',handleDrag)
      });
    });
  }
  },[])
  return <div id='homepage--drag'>
    <i id='bars-drag' class="uil uil-bars"></i>
  </div>
}
export default function Home() {
  const [activeKey, setActiveKey] = useState('worldhealth');
  const WithPermissions = useCallback((component, isMatchPolicy) => isMatchPolicy ? component : null,[]);
  const {list:dataNavbar} = useGetDataNavbar();
  useEffect(() => {
    const firstGroupActive = find(dataNavbar,(item => get(item,'isMatchPolicy')))
    setActiveKey(get(firstGroupActive,'accessKey'));
  },[dataNavbar])
  const validIcon = (icon) => isValidElement(icon) ? icon : <i className="uil-apps me-2"></i>;
  return (
    <div className='page-content homepage'>
      <div className='overlay-home'></div>
      {/* <div className='overlayBlack-home'></div> */}
      <Row wrap={false} gutter={16}>
          <Col className='homepage--left'>
        <Sider > 
        <Drag/>
            <Menu mode='inline' selectedKeys={[activeKey]} 
      defaultOpenKeys={[activeKey]}  className='menu-homepage'
            >
              {dataNavbar?.map((group) => {
            const { accessKey, children, titleGroup, icon, isMatchPolicy } = group;
            {/* If Not have Child will Render Item Else will Render Submenu */ }
            return WithPermissions(<Menu.SubMenu onTitleClick={({key}) => setActiveKey(key)}  popupClassName='draw-custom--menu-draw__submenu' key={accessKey} icon={validIcon(icon)} title={<TitleSubmenu accessKey={accessKey} group={group}/>}>
              {group?.children?.map((item) => {
                const childrens = get(item, 'children', []);
                const isMatchPolicy2 = get(item, 'isMatchPolicy');
                const { title, path } = item;
                {/* If Not have children will Render Item  */ }
                if (!childrens.length) {
                  return WithPermissions(<Menu.Item
                    key={path}>
                    <Link to={path}>
                      {title}
                    </Link>

                  </Menu.Item>, isMatchPolicy2)
                }
                else {
                  {/* If Not have Child will Render Item Else will Render Submenu */ }
                  return WithPermissions(<Menu.SubMenu title={title}>
                    {
                      childrens?.map(({ title, path: pathItem, isMatchPolicy: isMatchPolicy3 }) =>
                        WithPermissions(<Menu.Item
                          key={pathItem}
                        >
                          <Link to={pathItem}>
                            {title}
                          </Link>

                        </Menu.Item>, isMatchPolicy3)
                      )}
                  </Menu.SubMenu>, isMatchPolicy2)
                }
              })}
            </Menu.SubMenu>, isMatchPolicy)
          })}
            </Menu>
        </Sider>
          </Col>
        <Col flex={1} className='homepage--right'  style={{maxWidth : '100%'}}>
          <Content activeKey={activeKey}/>
        </Col>
      </Row>
    </div>

  )
}

