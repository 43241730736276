import { omit } from 'lodash';
import requester from './requester';

const deliveryVoucher = {
  getAll: query => requester.get('/product-delivery',query),
  getById: id => requester.get(`product-delivery/${id}`),
  // delete: id => requester.delete(`product-delivery/${id}`),
  create: param => requester.post('/product-delivery/create', param),
  update: (body) => requester.put(`/product-delivery/update/${body?.id}`, omit(body, 'id')),
  // update: (body) => requester.put(`/product-delivery/updateStatus/${body?.id}`, omit(body, 'id')),
  getAllBranch: (query) => requester.post('/product-delivery/search-branch', query),
};

export default deliveryVoucher;
