import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getWhSessionsOfDay({ payload: query }) {
  try {
    const data = yield call(Api.whSessionOfDay.getAll, query);
    yield put({ type: Types.GET_WH_SESSIONS_OF_DAY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_SESSIONS_OF_DAY_FAILED,
      payload: error.message
    });
  }
}

function* getWhSessionOfDay({ payload: id }) {
  try {
    const whSessionOfDay = yield call(Api.whSessionOfDay.getById, id);
    yield put({ type: Types.GET_WH_SESSION_OF_DAY_SUCCESS, payload: whSessionOfDay });
  } catch (error) {
    yield put({ type: Types.GET_WH_SESSION_OF_DAY_FAILED, payload: error.message });
  }
}

function* createWhSessionOfDay(action) {
  try {
    const data = yield call(Api.whSessionOfDay.create, action.payload);
    yield put({ type: Types.CREATE_WH_SESSION_OF_DAY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_WH_SESSION_OF_DAY_FAILED,
      payload: error.message
    });
  }
}

function* updateWhSessionOfDay(action) {
  try {
    const data = yield call(Api.whSessionOfDay.update, action.payload);
    yield put({ type: Types.UPDATE_WH_SESSION_OF_DAY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_SESSION_OF_DAY_FAILED,
      payload: error.message
    });
  }
}

function* deleteWhSessionOfDay({ payload }) {
  try {
    yield call(Api.whSessionOfDay.delete, payload);
    yield put({ type: Types.DELETE_WH_SESSION_OF_DAY_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_SESSION_OF_DAY_FAILED,
      payload: error.message
    });
  }
}

export default function* whSessionOfDay() {
  yield takeLatest(Types.GET_WH_SESSIONS_OF_DAY_REQUEST, getWhSessionsOfDay);
  yield takeLatest(Types.GET_WH_SESSION_OF_DAY_REQUEST, getWhSessionOfDay);
  yield takeLatest(Types.DELETE_WH_SESSION_OF_DAY_REQUEST, deleteWhSessionOfDay);
  yield takeLatest(Types.CREATE_WH_SESSION_OF_DAY_REQUEST, createWhSessionOfDay);
  yield takeLatest(Types.UPDATE_WH_SESSION_OF_DAY_REQUEST, updateWhSessionOfDay);
}
