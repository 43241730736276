import { CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { get } from 'lodash';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePinedNavbar, useUserPolicy } from '~/hooks';
import { PATH_APP } from '~/routes/paths';
import RowMenu from '../Home/RowMenu'

export default function NavbarPined() {
  const [accessKeyPined,setPined] = usePinedNavbar();
  const history = useHistory();
  const policies = useSelector((state) => state.user?.policy);
  const handleClearPined = () => {
    setPined(null);
  }
  useEffect(() => {
    const pageContent = document.querySelector('.main-content > div.page-content');
    if(pageContent){
      if(accessKeyPined && get(history,'location.pathname') !== PATH_APP.main.home){
        pageContent.style.marginTop = '130px';
        pageContent.style.setProperty('--flexBody','130px')
      }else{
        pageContent.style.marginTop = '70px';
        pageContent.style.setProperty('--flexBody','70px')

        // pageContent.style['--flexBody'] = '70px'
      }
    }
  },[accessKeyPined,policies])
  return (
     <div className='navbarPined'>
      {get(history,'location.pathname') !== PATH_APP.main.home && accessKeyPined && <>
        <RowMenu 
    accessKey={accessKeyPined}
    style={{
        padding : 0,
        maxWidth : '98%',
        overflowX : 'scroll',
        paddingLeft : 20,
        margin : 0
        }} />
      <Button onClick={handleClearPined} className='navbarPined--button' type='text'>
        <CloseCircleOutlined />
      </Button>
      </>}
     </div>
  )
}
