export const GET_EMPLOYEE_GROUPS_REQUEST = 'GET_EMPLOYEE_GROUPS_REQUEST';
export const GET_EMPLOYEE_GROUPS_SUCCESS = 'GET_EMPLOYEE_GROUPS_SUCCESS';
export const GET_EMPLOYEE_GROUPS_FAILED = 'GET_EMPLOYEE_GROUPS_FAILED';

export const GET_EMPLOYEE_GROUP_REQUEST = 'GET_EMPLOYEE_GROUP_REQUEST';
export const GET_EMPLOYEE_GROUP_SUCCESS = 'GET_EMPLOYEE_GROUP_SUCCESS';
export const GET_EMPLOYEE_GROUP_FAILED = 'GET_EMPLOYEE_GROUP_FAILED';

export const CREATE_EMPLOYEE_GROUP_REQUEST = 'CREATE_EMPLOYEE_GROUP_REQUEST';
export const CREATE_EMPLOYEE_GROUP_SUCCESS = 'CREATE_EMPLOYEE_GROUP_SUCCESS';
export const CREATE_EMPLOYEE_GROUP_FAILED = 'CREATE_EMPLOYEE_GROUP_FAILED';

export const UPDATE_EMPLOYEE_GROUP_REQUEST = 'UPDATE_EMPLOYEE_GROUP_REQUEST';
export const UPDATE_EMPLOYEE_GROUP_SUCCESS = 'UPDATE_EMPLOYEE_GROUP_SUCCESS';
export const UPDATE_EMPLOYEE_GROUP_FAILED = 'UPDATE_EMPLOYEE_GROUP_FAILED';

export const DELETE_EMPLOYEE_GROUP_REQUEST = 'DELETE_EMPLOYEE_GROUP_REQUEST';
export const DELETE_EMPLOYEE_GROUP_SUCCESS = 'DELETE_EMPLOYEE_GROUP_SUCCESS';
export const DELETE_EMPLOYEE_GROUP_FAILED = 'DELETE_EMPLOYEE_GROUP_FAILED';

export const UPDATE_GROUP_PERMISSION_REQUEST =
  'UPDATE_GROUP_PERMISSION_REQUEST';
export const UPDATE_GROUP_PERMISSION_SUCCESS =
  'UPDATE_GROUP_PERMISSION_SUCCESS';
export const UPDATE_GROUP_PERMISSION_FAILED = 'UPDATE_GROUP_PERMISSION_FAILED';

export const GET_RESOURCES_REQUEST = 'GET_RESOURCES_REQUEST';
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
export const GET_RESOURCES_FAILED = 'GET_RESOURCES_FAILED';

export const RESET_EMPLOYEE_GROUP_STATE = 'RESET_EMPLOYEE_GROUP_STATE';

export const UPDATE_EMPLOYEE_GROUP_REDUX = 'UPDATE_EMPLOYEE_GROUP_REDUX';

export const UPDATE_RESOURCES_REDUX = 'UPDATE_RESOURCES_REDUX';
