export const GET_WH_BANNERS_REQUEST = 'GET_WH_BANNERS_REQUEST';
export const GET_WH_BANNERS_SUCCESS = 'GET_WH_BANNERS_SUCCESS';
export const GET_WH_BANNERS_FAILED = 'GET_WH_BANNERS_BANNERS_FAILED';

export const GET_WH_BANNER_REQUEST = 'GET_WH_BANNER_REQUEST';
export const GET_WH_BANNER_SUCCESS = 'GET_WH_BANNER_SUCCESS';
export const GET_WH_BANNER_FAILED = 'GET_WH_BANNER_FAILED';

export const CREATE_WH_BANNER_REQUEST = 'CREATE_WH_BANNER_REQUEST';
export const CREATE_WH_BANNER_SUCCESS = 'CREATE_WH_BANNER_SUCCESS';
export const CREATE_WH_BANNER_FAILED = 'CREATE_WH_BANNER_FAILED';

export const UPDATE_WH_BANNER_REQUEST = 'UPDATE_WH_BANNER_REQUEST';
export const UPDATE_WH_BANNER_SUCCESS = 'UPDATE_WH_BANNER_SUCCESS';
export const UPDATE_WH_BANNER_FAILED = 'UPDATE_WH_BANNER_FAILED';

export const DELETE_WH_BANNER_REQUEST = 'DELETE_WH_BANNER_REQUEST';
export const DELETE_WH_BANNER_SUCCESS = 'DELETE_WH_BANNER_SUCCESS';
export const DELETE_WH_BANNER_FAILED = 'DELETE_WH_BANNER_FAILED';

export const RESET_WH_BANNER_STATE = 'RESET_WH_BANNER_STATE';
