import produce from 'immer';
import * as Types from '~/constants/actionTypes';

const initState = {
  isOverViewLoading: false,
  overView: null,
  getOverViewFailed: null,

  isYearlyLoading: false,
  yearly: [],
  getYearlyFailed: null,

  isQuarterlyLoading: false,
  quarterly: [],
  getQuarterlyFailed: null,

  isMonthlyLoading: false,
  monthly: [],
  getMonthlyFailed: null,

  isDailyLoading: false,
  daily: [],
  getDailyFailed: null,

  isMostYearlyLoading: false,
  mostYearly: [],
  getMostYearlyFailed: null,

  isMostQuarterlyLoading: false,
  mostQuarterly: [],
  getMostQuarterlyFailed: null,

  isMostMonthlyLoading: false,
  mostMonthly: [],
  getMostMonthlyFailed: null,

  isMostDailyLoading: false,
  mostDaily: [],
  getMostDailyFailed: null,

  isClinicOverviewTodayLoading: false,
  clinicOverviewToday: [],
  getClinicOverviewTodayFailed: null,

  isClinicOverviewDailyLoading: false,
  clinicOverviewDaily: [],
  getClinicOverviewDailyFailed: null,

  isClinicOverviewMonthlyLoading: false,
  clinicOverviewMonthly: [],
  getClinicOverviewMonthlyFailed: null,

  isClinicOverviewQuarterlyLoading: false,
  clinicOverviewQuarterly: [],
  getClinicOverviewQuarterlyFailed: null,

  isClinicOverviewYearlyLoading: false,
  clinicOverviewYearly: [],
  getClinicOverviewYearlyFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_CLINIC_OVER_VIEW_REPORT_REQUEST:
      state.isOverViewLoading = true;
      return;

    case Types.GET_CLINIC_OVER_VIEW_REPORT_SUCCESS:
      state.isOverViewLoading = false;
      state.overView = payload;
      return;

    case Types.GET_CLINIC_OVER_VIEW_REPORT_FAILED:
      state.isOverViewLoading = false;
      state.getOverViewFailed = payload;
      state.overView = {};
      return;

    case Types.GET_CLINIC_YEARLY_REPORT_REQUEST:
      state.isYearlyLoading = true;
      return;

    case Types.GET_CLINIC_YEARLY_REPORT_SUCCESS:
      state.isYearlyLoading = false;
      state.yearly = payload;
      return;

    case Types.GET_CLINIC_YEARLY_REPORT_FAILED:
      state.isYearlyLoading = false;
      state.getYearlyFailed = payload;
      state.yearly = [];
      return;

    case Types.GET_CLINIC_QUARTERLY_REPORT_REQUEST:
      state.isQuarterlyLoading = true;
      return;

    case Types.GET_CLINIC_QUARTERLY_REPORT_SUCCESS:
      state.isQuarterlyLoading = false;
      state.quarterly = payload;
      return;

    case Types.GET_CLINIC_QUARTERLY_REPORT_FAILED:
      state.isQuarterlyLoading = false;
      state.getQuarterlyFailed = payload;
      state.quarterly = [];
      return;

    case Types.GET_CLINIC_MONTHLY_REPORT_REQUEST:
      state.isMonthlyLoading = true;
      return;

    case Types.GET_CLINIC_MONTHLY_REPORT_SUCCESS:
      state.isMonthlyLoading = false;
      state.monthly = payload;
      return;

    case Types.GET_CLINIC_MONTHLY_REPORT_FAILED:
      state.isMonthlyLoading = false;
      state.getMonthlyFailed = payload;
      state.monthly = [];
      return;

    case Types.GET_CLINIC_DAILY_REPORT_REQUEST:
      state.isDailyLoading = true;
      return;

    case Types.GET_CLINIC_DAILY_REPORT_SUCCESS:
      state.isDailyLoading = false;
      state.daily = payload;
      return;

    case Types.GET_CLINIC_DAILY_REPORT_FAILED:
      state.isDailyLoading = false;
      state.getDailyFailed = payload;
      state.daily = [];
      return;

    case Types.GET_MOST_DAILY_REQUEST:
      state.isMostDailyLoading = true;
      return;

    case Types.GET_MOST_DAILY_SUCCESS:
      state.isMostDailyLoading = false;
      state.mostDaily = payload;
      return;

    case Types.GET_MOST_DAILY_FAILED:
      state.isMostDailyLoading = false;
      state.getMostDailyFailed = payload;
      state.mostDaily = [];
      return;

    case Types.GET_MOST_MONTHLY_REQUEST:
      state.isMostMonthlyLoading = true;
      return;

    case Types.GET_MOST_MONTHLY_SUCCESS:
      state.isMostMonthlyLoading = false;
      state.mostMonthly = payload;
      return;

    case Types.GET_MOST_MONTHLY_FAILED:
      state.isMostMonthlyLoading = false;
      state.getMostMonthlyFailed = payload;
      state.mostMonthly = [];
      return;

    case Types.GET_MOST_QUARTERLY_REQUEST:
      state.isMostQuarterlyLoading = true;
      return;

    case Types.GET_MOST_QUARTERLY_SUCCESS:
      state.isMostQuarterlyLoading = false;
      state.mostQuarterly = payload;
      return;

    case Types.GET_MOST_QUARTERLY_FAILED:
      state.isMostQuarterlyLoading = false;
      state.getMostQuarterlyFailed = payload;
      state.mostQuarterly = [];
      return;

    case Types.GET_MOST_YEARLY_REQUEST:
      state.isMostYearlyLoading = true;
      return;

    case Types.GET_MOST_YEARLY_SUCCESS:
      state.isMostYearlyLoading = false;
      state.mostYearly = payload;
      return;

    case Types.GET_MOST_YEARLY_FAILED:
      state.isMostYearlyLoading = false;
      state.getMostYearlyFailed = payload;
      state.mostYearly = [];
      return;

    case Types.GET_CLINIC_OVERVIEW_TODAY_REQUEST:
      state.isClinicOverviewTodayLoading = true;
      return;

    case Types.GET_CLINIC_OVERVIEW_TODAY_SUCCESS:
      state.isClinicOverviewTodayLoading = false;
      state.clinicOverviewToday = payload;
      return;

    case Types.GET_CLINIC_OVERVIEW_TODAY_FAILED:
      state.isClinicOverviewTodayLoading = false;
      state.getClinicOverviewTodayFailed = payload;
      state.clinicOverviewToday = [];
      return;

    case Types.GET_CLINIC_OVERVIEW_DAILY_REQUEST:
      state.isClinicOverviewDailyLoading = true;
      return;

    case Types.GET_CLINIC_OVERVIEW_DAILY_SUCCESS:
      state.isClinicOverviewDailyLoading = false;
      state.clinicOverviewDaily = payload;
      return;

    case Types.GET_CLINIC_OVERVIEW_DAILY_FAILED:
      state.isClinicOverviewDailyLoading = false;
      state.getClinicOverviewDailyFailed = payload;
      state.clinicOverviewDaily = [];
      return;

    case Types.GET_CLINIC_OVERVIEW_MONTHLY_REQUEST:
      state.isClinicOverviewMonthlyLoading = true;
      return;

    case Types.GET_CLINIC_OVERVIEW_MONTHLY_SUCCESS:
      state.isClinicOverviewMonthlyLoading = false;
      state.clinicOverviewMonthly = payload;
      return;

    case Types.GET_CLINIC_OVERVIEW_MONTHLY_FAILED:
      state.isClinicOverviewMonthlyLoading = false;
      state.getClinicOverviewMonthlyFailed = payload;
      state.clinicOverviewMonthly = [];
      return;

    case Types.GET_CLINIC_OVERVIEW_QUARTERLY_REQUEST:
      state.isClinicOverviewQuarterlyLoading = true;
      return;

    case Types.GET_CLINIC_OVERVIEW_QUARTERLY_SUCCESS:
      state.isClinicOverviewQuarterlyLoading = false;
      state.clinicOverviewQuarterly = payload;
      return;

    case Types.GET_CLINIC_OVERVIEW_QUARTERLY_FAILED:
      state.isClinicOverviewQuarterlyLoading = false;
      state.getClinicOverviewQuarterlyFailed = payload;
      state.clinicOverviewQuarterly = [];
      return;

    case Types.GET_CLINIC_OVERVIEW_YEARLY_REQUEST:
      state.isClinicOverviewYearlyLoading = true;
      return;

    case Types.GET_CLINIC_OVERVIEW_YEARLY_SUCCESS:
      state.isClinicOverviewYearlyLoading = false;
      state.clinicOverviewYearly = payload;
      return;

    case Types.GET_CLINIC_OVERVIEW_YEARLY_FAILED:
      state.isClinicOverviewYearlyLoading = false;
      state.getClinicOverviewYearlyFailed = payload;
      state.clinicOverviewYearly = [];
      return;

    case Types.RESET_CLINIC_DASHBOARD_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
