import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'antd';
import api from '~/api';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { Breadcrumb } from '~/components/Common';
import { useProfile } from '~/hooks';
import moment from 'moment';
import WhServiceHistory from './WhServiceHistory';
import {HISTORY_KEY} from '~/constants/defaultValue';
import { useHistories, useHistoriesPaging, useHistoriesQueryParams } from '~/hooks/history';

export default function History() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keywork, setKeywork] = useState(null);
  const [searchBy, setSearchBy] = useState('fullName');
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [whServiceHistory, setWhServiceHistory] = useState([]);
  const [query, onTableChange] = useHistoriesQueryParams()
  const [histories, isLoading] = useHistories(query)
  const paging = useHistoriesPaging();

  const handleOpenForm = () => {
    setIsOpen(true);
  };

  const columns = [
    {
      title: 'Người chỉnh sửa',
      key: 'fullName',
      dataIndex: 'fullName',
      render: (value, record) => record?.User?.fullName
    },
    {
      title: 'Nội dung',
      key: 'typeHistory',
      dataIndex: 'typeHistory',
      render: (value, record) => HISTORY_KEY[record?.typeHistory] ?? ''
    },
    {
      title: 'Thời gian',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'Dữ liệu bị thay đổi',
      key: 'data',
      dataIndex: 'data',
      render: (value, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              handleOpenForm();
              setWhServiceHistory(record?.historyLog);
            }}
          >
            Xem chi tiết
          </Button>
        );
      }
    }
  ];
  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Lịch sử chỉnh sửa" />

        {isLoading ? (
          <SkeletonTable columns={columns} rowKey={3} />
        ) : (
          <Table 
              dataSource={histories} 
              columns={columns}
              size='middle'
              onChange={onTableChange}
              pagination={{
                ...paging,
                showTotal: (total)=> `Tổng: ${total}`
              }}
          />
        )}
      </div>
      <Modal
        width={'900px'}
        title="Lịch sử chỉnh sửa"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <WhServiceHistory whServiceHistory={whServiceHistory} />
      </Modal>
    </div>
  );
}
