import requester from './requester';

const applyJob = {
  getAll: (query) => requester.get('/job-posting', query),
  getById: (id) => requester.get(`/job-posting/${id}`),
  update: (payload) => requester.put(`/job-posting/${payload._id}`, payload),
  delete: (id) => requester.delete(`/job-posting/${id}`),
  sendMail : (payload) => requester.post('/job-posting-sendMail',payload),
};

export default applyJob;
