import { Table } from 'antd';
import React from 'react';
import SkeletonTable from '../Utils/SkeletonTable';
import './index.scss';
export default function TableDetail({
  columns,
  dataSource,
  title,
  pagination,
  footer,
  loading,
  scrollWidth,
  isRowExpandable,
  ExpandedRowRender,
  rowClassName,
  notScrollY,
  components
}) {
  return (
    <div style={{ margin: '20px 0' }}>
      {loading ? <SkeletonTable columns={columns} rowCount={5}/> :<Table
      className='tableReport'
        scroll={{x : scrollWidth ? scrollWidth : 1400 , y : !notScrollY ? 600 : null}}
        pagination={pagination}
        bordered
        components={components}
        rowClassName={() => rowClassName || ''}
        title={() => title}
        columns={columns}
        dataSource={dataSource || []}
        summary={footer}
        rowKey={rc => rc._id}
        expandable={isRowExpandable ? {
      expandedRowRender: (record) => ExpandedRowRender(record),
      rowExpandable: (record) => isRowExpandable,
    } : {}}
      />}
    </div>
  );
}
