import React from 'react';
import { PATH_APP } from '~/routes/paths';
import {
  useNewsCategories,
  useDeleteNewsCategory,
  useNewsCategoryQueryParams,
  SECURITY_BOOLEAN,
  useUpdateSecurityNewsCategory,
  SECURITY_VALUE
} from '~/hooks/newsCategory';
import { Table, Popconfirm, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import moment from 'moment';
import toastr from 'toastr';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { WithPermission } from '../Common';
import POLICIES from '~/constants/policy';
import { Permissions } from '~/hooks/utils';

const ColumnActions = ({ id, deleteNewsCategory }) => {
  return (
    <div className="custom-table__actions">
    <WithPermission permission={POLICIES.UPDATE_ANNOUNCEMENT}>
      <Link to={`/news-category/edit/${id}`}>
        <p>Sửa</p>
      </Link>
    </WithPermission>
    <WithPermission permission={POLICIES.UPDATE_ANNOUNCEMENT}>
        <WithPermission permission={POLICIES.DELETE_ANNOUNCEMENT}>
        <p>|</p>
        </WithPermission>
    </WithPermission>
    <WithPermission permission={POLICIES.DELETE_ANNOUNCEMENT}>
    <Popconfirm
        title="Bạn muốn xoá danh mục tin tức này?"
        onConfirm={() => deleteNewsCategory(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
    </WithPermission>
    </div>
  );
};

const NewsCategories = () => {
  const {canUpdate} = Permissions("ANNOUNCEMENT")
  const query = useNewsCategoryQueryParams();
  const [newsCategories, isLoading] = useNewsCategories(query);
  const [, deleteNewsCategory] = useDeleteNewsCategory();
  const [, updateSecurityNewsCategory] = useUpdateSecurityNewsCategory();

  const columns = [
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      key: 'name'
    },

    {
      title: 'Đường dẫn',
      dataIndex: 'slug',
      key: 'slug'
    },
    {
      title: 'mô tả',
      key: 'description',
      dataIndex: 'description'
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => moment(date).format('DD-MM-YYYY')
    },
    {
      title: 'Hiển thị',
      dataIndex: 'security',
      key: 'security',
      align: 'center',
      render: (security,record) => <Checkbox checked={SECURITY_BOOLEAN(security)} 
        onChange={({target})=>{ 
          toastr.clear()
          updateSecurityNewsCategory( { id:record._id, security: SECURITY_VALUE(target.checked)})
        }} 
        disabled={!canUpdate}
        />
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        return (
          <ColumnActions {...record} deleteNewsCategory={deleteNewsCategory} />
        );
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách danh mục tin tức" />

        <div className="page-wraper__header">
        <WithPermission permission={POLICIES.WRITE_ANNOUNCEMENT}>
        <Link to={PATH_APP.newsCategory.create}>
            <Button type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </Link>
        </WithPermission>
        </div>

        {isLoading ? (
          <SkeletonTable rowCount={newsCategories.length} columns={columns} />
        ) : (
          <Table
            rowKey={(rc) => rc._id}
            columns={columns}
            dataSource={newsCategories}
            size="middle"
            pagination={{
              total: newsCategories.length,
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NewsCategories;
