import { AppstoreOutlined, DashOutlined } from '@ant-design/icons';
import { Radio, Row, Typography } from 'antd';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { getModeNavbar, MODE_NAVBAR, setModeNavbar } from '~/constants/keyLocalStorage';
import { useGetDataNavbar } from '~/hooks';
import './index.scss';
import RowMenu from './RowMenu';
const keyNavbar = MODE_NAVBAR.modeRowHome
export default function Content({ activeKey,showHeader = true }) {
  const {list:dataNavbar} = useGetDataNavbar();
  const [mode,setMode] = useState();
  // const handleClickMenu = () => {
  //   const element = document.querySelector('.home--row');
  //   const observer = new IntersectionObserver(
  //     (entry) => {
  //     const elementEarly =  entry.find(item => get(item,'intersectionRatio',0) > 0);
  //     // Get element early intersectionRatio > 0 and get Space must to scroll to left
  //     const width = get(elementEarly,'intersectionRect.width',0) + 0.5;
  //     if (element.scrollWidth > element.clientWidth) {
  //       element.scrollLeft += width;
  //       for (let index = 0; index < element.children.length; index++) {
  //         const item = element.children[index];
  //         observer.unobserve(item);
  //       }
  //       return ;
  //     } 
  //     },
  //     { rootMargin: "0px 0px 0px 0px", root: element }
  //   );
  //   for (let index = 0; index < element.children.length; index++) {
  //     const item = element.children[index];
  //     observer.observe(item);
  //   }
  // }
  const handleChangeModeRowHome = (e) => {
    const newMode = e.target.value;
    setMode(newMode);
    setModeNavbar(keyNavbar,newMode)
  }
  useEffect(() => {
    const {data,isNew} = getModeNavbar();
      setMode(get(data,keyNavbar));
  },[]);
  return (
    <div className="page-wraper Content">
      {dataNavbar?.map((group, index) => {
        return activeKey === get(group, 'accessKey', '') && 
        <div >
            {showHeader && <Row justify='space-between' className='Content--header'>
            <Typography.Title className='home--title' level={4}>{get(group, 'titleGroup', '')}</Typography.Title>
            <Radio.Group value={mode} onChange={handleChangeModeRowHome}>
              <Radio.Button value={'inline'}><DashOutlined /></Radio.Button>
              <Radio.Button value={'wrap'}><AppstoreOutlined /></Radio.Button>
            </Radio.Group>
            </Row>}
          {/*MENU */}
          <RowMenu accessKey={activeKey} mode={mode}/>
          </div>
      }

      )}

    </div>
  )
}
