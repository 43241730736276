import requester from './requester';

const ConfigCampaign = {
  getById: (id) => requester.get(`/config-campaign/${id}`),
  create: (config) => requester.post(`/config-campaign`, config),
  update:(config) => requester.put(`/config-campaign/${config._id}`,config),
  getAll : (query) => requester.get(`/config-campaign`,query),
  delete: (id) => requester.delete(`/config-campaign/${id}`),
  
};

export default ConfigCampaign;
