import * as Types from '../../constants/actionTypes';
export const getAllTask = (id) => ({
  type: Types.GET_ALL_TASKS_REQUEST,
  payload: id
})
//getById

export const getTaskById = (id) => ({
  type: Types.GET_TASK_BY_ID_REQUEST,
  payload: id
})
// create task
export const createTask = (params) => ({
  type: Types.CREATE_TASK_REQUEST,
  payload: params
})

// update task
export const updateTask = (data) => ({
  type: Types.UPDATE_TASK_REQUEST,
  payload: data
})

export const updatePosition = (data) => ({
  type: Types.UPDATE_TASK_CHANGE_PROSITION,
  payload: data
})

// update Progress Task
export const updateProgressTask = (data) => ({
  type: Types.UPDATE_PROGRESS_TASK_REQUEST,
  payload: data
})
export const updateTaskInit = (data) => ({
  type: Types.UPDATE_TASK_INIT_REQUEST,
  payload: data
})
//delete task
export const deleteTask = (id) => ({
  type: Types.DELETE_TASK_REQUEST,
  payload: id
})
//copy task

//Assign task
export const assignTask = (payload) => ({
  type: Types.ASSIGN_TASK_REQUEST,
  payload
})

//getHistorySpentTime 
export const getHistoryActivityTaskById = (query) => ({
  type: Types.GET_HISTORY_ACTIVITY_REQUEST,
  payload: query
})
export const copyTask = (data) => ({
  type: Types.COPY_TASK_REQUEST,
  payload: data
})

export const commentList = (data) => ({
  type: Types.COMMENT_LIST_SAVE,
  payload: data
})
export const commentPush = (data) => ({
  type: Types.COMMENT_LIST_PUSH,
  payload: data
})
export const commentEmotion = (data) => ({
  type: Types.COMMENT_LIST_EMOTION,
  payload: data
})

export const commentRequet = (data) => ({
  type: Types.COMMENT_REQUEST,
  payload: data
})
export const deleteComment = (data) => ({
  type: Types.COMMENT_DELETE,
  payload: data
})
export const deleteCommentRequest = (data) => ({
  type: Types.COMMENT_DELETE_REQUEST,
  payload: data
})
export const updateCommentRequest = (data) => ({
  type: Types.COMMENT_UPDATE_REQUEST,
  payload: data
})
export const updateComment = (data) => ({
  type: Types.COMMENT_UPDATE,
  payload: data
})

export const resetComment = (data) => ({
  type: Types.COMMENT_RESET,
  payload: data
})

export const pushEmotionRequest = (data) => ({
  type: Types.EMOTION_REQUEST,
  payload: data
})
export const searchTaskRequest = (data) => ({
  type: Types.SEARCH_TASK_REQUEST,
  payload: data,
})

export const updateTaskRelationRequest = (data) => ({
  type: Types.UPDATE_RELATION_REQUEST,
  payload: data,
})

export const getTaskRelationRequest = (data) => ({
  type: Types.GET_RELATION_REQUEST,
  payload: data,
})
