import { Button, Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import { get, head } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import api from '~/api'
import { formatNumberThreeCommaToFixed } from '~/hooks/utils'

export default function TableListDiscount({ submitData, setCoupons, coupons, onCancel, handleSelectCoupon, total , calculatePriceUseCoupon }) {
  const [loadingGetCoupon, setLoadingGetCoupon] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const checkDisabled = (record) => get(record,'minAmountOfBill') && get(record, 'minAmountOfBill') > total
  const fetchCoupon = useCallback(async () => {
    try {
      setLoadingGetCoupon(true)
      const listCouponCanUse = await api.coupon.getListcouponForCustomer(submitData)
      if (get(listCouponCanUse, 'status')) {
        const sortPrice = get(listCouponCanUse, 'data',[])?.sort((before,after) =>  calculatePriceUseCoupon(after) - calculatePriceUseCoupon(before))
        const cloneSortPrice = [...sortPrice]
        const sortDisabled = cloneSortPrice?.sort((before,after) => checkDisabled(before) - checkDisabled(after))
        setCoupons(sortDisabled?.map(e => ({
          label: get(e, 'name', ''),
          value: get(e, 'code', ''),
          discountValue: get(e, 'discountValue'),
          discountPercent: get(e, 'discountPercent'),
          maxDiscount: get(e, 'maxDiscount'),
          minAmountOfBill: get(e, 'minAmountOfBill'),
        })))
      }
      setLoadingGetCoupon(false)
    } catch (error) {
      setLoadingGetCoupon(false)
      console.log(error);
    }
  }, [submitData])
  useEffect(() => {
    fetchCoupon()
  }, [])
  const columns = [
    {
      title: 'Tên mã giảm giá',
      dataIndex: 'label',
      key: 'label',
      render : (item,record) => {
        const minAmountOfBill = get(record,'minAmountOfBill')
        if(minAmountOfBill && minAmountOfBill > total){
          return <>
            <Text>{item}</Text>
            <p style={{color : 'red'}}>{`(Đặt thêm ${formatNumberThreeCommaToFixed(minAmountOfBill - total)} để sử dụng mã giảm giá)`}</p>
          </>
        }
        return item
      }
    },
    {
      title: 'Giảm giá',
      dataIndex: 'discountValue',
      key: 'discountValue',
      align: 'center',
      render: (item, record) => <Text strong>{item ? formatNumberThreeCommaToFixed(item) : get(record, 'discountPercent', 0) + '%'}</Text>,
    },
    {
      title: 'Giảm giá tối đa',
      dataIndex: 'maxDiscount',
      key: 'maxDiscount',
      align: 'center',
      render: (item) => <Text strong>{item && formatNumberThreeCommaToFixed(item)}</Text>
    },
    {
      title: 'Giá trị đơn hàng tối thiểu',
      dataIndex: 'minAmountOfBill',
      key: 'minAmountOfBill',
      align: 'center',
      render: (item) => <Text strong>{item && formatNumberThreeCommaToFixed(item)}</Text>
    },
  ]
  return (
    <>
      <Table
        pagination={false}
        columns={columns}
        loading={loadingGetCoupon}
        dataSource={coupons}
        rowKey={(rc) => get(rc, 'value', '')}
        size="middle"
        rowClassName={(record) => checkDisabled(record) && 'disabledRowDiscount'}
        rowSelection={{
          type: 'radio',
          selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
          getCheckboxProps: (record) => {
            return {
              disabled: checkDisabled(record)
            }
          },
          
        }}
      />
      <Button style={{ marginLeft: 'auto', marginTop: 20 }} onClick={() => {
        const selected = head(selectedRows)
        handleSelectCoupon(get(selected, 'value', ''));
        onCancel()

      }}
        type='primary'
      >Chọn mã</Button>
    </>
  )
}
