export const GET_WH_CATEGORIES_REQUEST = 'GET_WH_CATEGORIES_REQUEST';
export const GET_WH_CATEGORIES_SUCCESS = 'GET_WH_CATEGORIES_SUCCESS';
export const GET_WH_CATEGORIES_FAILED = 'GET_WH_CATEGORIES_CATEGORIES_FAILED';

export const GET_WH_CATEGORIES_LIST_SUCCESS = 'GET_WH_CATEGORIES_LIST_SUCCESS';
export const GET_WH_CATEGORIES_LIST_REQUEST = 'GET_WH_CATEGORIES_LIST_REQUEST';
export const GET_WH_CATEGORIES_LIST_FAILED = 'GET_WH_CATEGORIES_LIST_FAILED';

export const GET_WH_CATEGORY_REQUEST = 'GET_WH_CATEGORY_REQUEST';
export const GET_WH_CATEGORY_SUCCESS = 'GET_WH_CATEGORY_SUCCESS';
export const GET_WH_CATEGORY_FAILED = 'GET_WH_CATEGORY_FAILED';

export const CREATE_WH_CATEGORY_REQUEST = 'CREATE_WH_CATEGORY_REQUEST';
export const CREATE_WH_CATEGORY_SUCCESS = 'CREATE_WH_CATEGORY_SUCCESS';
export const CREATE_WH_CATEGORY_FAILED = 'CREATE_WH_CATEGORY_FAILED';

export const UPDATE_WH_CATEGORY_REQUEST = 'UPDATE_WH_CATEGORY_REQUEST';
export const UPDATE_WH_CATEGORY_SUCCESS = 'UPDATE_WH_CATEGORY_SUCCESS';
export const UPDATE_WH_CATEGORY_FAILED = 'UPDATE_WH_CATEGORY_FAILED';

export const DELETE_WH_CATEGORY_REQUEST = 'DELETE_WH_CATEGORY_REQUEST';
export const DELETE_WH_CATEGORY_SUCCESS = 'DELETE_WH_CATEGORY_SUCCESS';
export const DELETE_WH_CATEGORY_FAILED = 'DELETE_WH_CATEGORY_FAILED';

export const RESET_WH_CATEGORY_STATE = 'RESET_WH_CATEGORY_STATE';
