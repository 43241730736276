import { ArrowUpOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Badge, Card, Col, Descriptions, InputNumber, Row, Spin, Statistic, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get, head } from 'lodash';
import moment from 'moment';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {useHistory} from 'react-router-dom'
import { useAllSaleReportSystems, useCategoryAll, useCreateFund, useGetFund, useUpdateFund } from '~/hooks/reportSystem';
import { formatNumberThreeComma, useBranchesDefault } from '~/hooks/utils';
import { Breadcrumb, WithPermission } from '../Common';
import ExportExcelButton from '../Common/ExportExcel';
import TabTable from './TabTable';
import POLICY from '~/constants/policy';
import toastr from 'toastr'
import './wh-report.scss';
import { useMatchPolicy, useProfile } from '~/hooks';
import { useSelector } from 'react-redux';
import TooltipTitle from '../Common/TooltipTitle';
import POLICIES from '~/constants/policy';
const ItemDescription = ({children,title,value,color,loading}) => <Col md={6} lg={3} xs={12}>
<Card className='boxStatistc'>
        {children ? children :<Statistic
        loading={loading}
          title={title}
          value={value}
          precision={2}
          valueStyle={{ color:color ? color : 'black'}}
          // prefix={<ArrowUpOutlined />}
          // suffix="%"
        />}
      </Card>
</Col>
export default function WhReportSystem() {
  const [reserveFund,setReserveFund] = useState(0)
  const [beginFund,setBeginFund] = useState(0)
  const [Url, setUrl] = useState({
    url: '', 
    keyExport :'',
    fileName_: '',
  })
  const history = useHistory()
  // const ref = useRef()
  // const [tabName, setTabName] = useState('receipts')
  const [arrCheckBox, setArrCheckBox] = useState([]);
  
  
  const [branchIds,setBranchIds] = useBranchesDefault();
  const branchIdStore = useSelector((state) => state.user['branchId']);
  const [fund,loadingFund] = useGetFund(branchIds);
  //POLYCY//
  const isHaveUpdatePOLYCY = useMatchPolicy(POLICY.UPDATE_WHBEGINNINFUND)
  const isHaveReadPOLYCY = useMatchPolicy(POLICY.READ_WHBEGINNINFUND)

  // This is Not a Permission, Only Can Change if branchIds === my branch
  const canUpdateOrCreate = useMemo(() => branchIds?.toString() === branchIdStore?.toString(),[branchIdStore, branchIds]) 
  const [date,setDate] = useState({
    startDate : moment().startOf('month').format("YYYY-MM-DD"),
    endDate : moment().endOf('month').format("YYYY-MM-DD"),
  });
  const query = useMemo(() => ({...date,branchIds}),[date,branchIds]);

  const [whCategories,loadingCategory] = useCategoryAll();
  useEffect(() => {
    if(!date.startDate){
      setDate({...date,startDate : moment().startOf('month').format("YYYY-MM-DD")})
    }
    if(!date.endDate){
      setDate({...date,endDate : moment().endOf('month').format("YYYY-MM-DD")})
    }
  }, [date])
  
  const [searchParams, setSearchParams] = useState(`?page=1&limit=10&startDate=${date?.startDate}&endDate=${date?.endDate}`)
  const [allSale,loading] = useAllSaleReportSystems(query)
  const [loadingSubmitFund,createFund] = useCreateFund()
  const [,updateFund] = useUpdateFund()

  const sumTotal = () => {
    if(!isHaveUpdatePOLYCY) return toastr.error("Bạn không có quyền sửa");
    if(!canUpdateOrCreate) return toastr.error("Vui lòng chọn chi nhánh của chính bạn để sửa.");
    let sumFund = beginFund + get(allSale,'resultTotalReceipt.resultTotal',0) - get(allSale,'resultTotalPayment.resultTotal',0)
    if(fund){
      if(canUpdateOrCreate){
        updateFund({...date,beginFund,reserveFund : sumFund})
      }
    }
    else{
      createFund({...date,beginFund,reserveFund : sumFund})
    }
    setReserveFund(sumFund)
  }
  
  const changeHistory = history.location.search
  useEffect(() => {
    setReserveFund(get(fund,'systemRevenue',0) + get(allSale,'resultTotalReceipt.resultTotal',0) - get(allSale,'resultTotalPayment.resultTotal',0))
  },[allSale,fund])
  
  // Props for Button Export Excel

    const setUrlExport =  useCallback((tabName) => {
      setUrl(() => {
        switch (tabName) {
          case '1':
            return {url : '/wh-receipt-voucher-partner',
            keyExport : 'TotalReceiptSystem',
            fileName_ : 'Tổng thu'}
          case '2':
            return {url : '/wh-payment-voucher-partner/all',
            keyExport : 'TotalPaymentSystem',
            fileName_ : 'Tổng chi'}
          case '3':
            return {url : '/wh-receipt-voucher-customer',
            keyExport : 'CustomerReceiptSystem',
            fileName_ : 'Khoản phải thu'}
          case '4':
            return {url : '/wh-payment-voucher-customer',
            keyExport : 'CustomerPaymentSystem',
            fileName_ : 'Khoản phải chi'}
          case '5':
            return {url : '/wh-receipt-voucher-customer/customer-cancel',
            keyExport : 'ReceiptFinishSystem',
            fileName_ : 'Phiếu thu(Đơn hàng đã chấm dứt)'}
          default:
            return {url: '', 
            keyExport :'',
            fileName_: '',}
        }
      })
    },[])
  useEffect(() => {
    const historyType = history.location.search?.split(/[?&]/i);
    let valueTabs = historyType.find(value => value.includes('tabs'))?.replace('tabs=', '') ?? '1'
    setSearchParams(get(history, 'location.search') || searchParams)
    setUrlExport(valueTabs)
  }, [changeHistory, setUrlExport, date])
  const propsButton = {
    ...Url,
    searchBy: searchParams ,
    ids: arrCheckBox  || ''
  }
  // useEffect(() => {
  //   const updateFunds = () => {
  //     let sumFund = beginFund + get(allSale,'resultTotalReceipt.resultTotal',0) - get(allSale,'resultTotalPayment.resultTotal',0);
  //     if(canUpdateOrCreate){
  //       updateFund({...date,beginFund:get(fund,'systemRevenue',0),reserveFund : sumFund})
  //     }
  //   }
  //   // if(isHaveUpdatePOLYCY && !isRuned  && allSale && allSale.length !== 0 && fund && canUpdateOrCreate){ // only run First time
  //   //   updateFunds()
  //   //   setIsRuned(true)
  //   // }
  // },[beginFund,allSale,fund]);
  
  const messageDisabledFund = useMemo(() => {
    switch (true) {
      case !isHaveReadPOLYCY :
        return "Bạn không có quyền"
      case !canUpdateOrCreate :
        return "Vui lòng chỉ chọn chi nhánh của bạn để chỉnh sửa!"
    
      default:
        break;
    }
  },[canUpdateOrCreate, isHaveReadPOLYCY]);
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Báo cáo doanh thu hệ thống" />
        <Row style={{width: '100%'}}>
        <WithPermission permission={POLICIES.DOWNLOAD_WHREVENUESYSTEM}>
          <Col align ='end' span={24}>
              <ExportExcelButton
              propsButton = {propsButton}
            />
          </Col>
          </WithPermission>
        </Row>
        <div className="page-content__main">
          <Row style={{width : '100%',background : '#F0F1F6', padding : '20px 0'}} align="end">
            <Col span={24}>
            <Row wrap justify='space-around'>
          <ItemDescription loading={loading}>
          <div>Quỹ đầu kỳ</div>
           <TooltipTitle 
            titleTooltip={messageDisabledFund}
            content={loadingFund || loadingSubmitFund ? <Spin spinning/> :<InputNumber disabled={!isHaveReadPOLYCY || !canUpdateOrCreate} style={{width : '100%'}} onPressEnter={sumTotal}  min={0} formatter={(value) => formatNumberThreeComma(value)} value={get(fund,'systemRevenue',0)} onChange={(value) => setBeginFund(value)}/>}
           />
          </ItemDescription>
          <ItemDescription loading={loading}  color='#F9AC15' title="Tổng thu" value={formatNumberThreeComma(get(allSale,'resultTotalReceipt.resultTotal',0))}/>
          <ItemDescription loading={loading} title="Tổng chi" value={formatNumberThreeComma(get(allSale,'resultTotalPayment.resultTotal',0))}/>
          <ItemDescription loading={loading} title={ <Tooltip
                      placement="topLeft"
                      title={'Tồn quỹ = Quỹ đầu kỳ + Tổng thu - Tổng chi'}
                    >
                      <Badge
                      
                        size="small"
                        color="#9B9999"
                        offset={[9, 3]}
                        count={<InfoCircleOutlined />}
                      >
                       <Text type='secondary'> Tồn quỹ</Text>
                      </Badge>
                    </Tooltip>} value={formatNumberThreeComma(reserveFund)}/>
          <ItemDescription loading={loading} color='#cf1322' title="Phải thu từ KH" value={formatNumberThreeComma(get(allSale,'resultTotalCustomerReceipt.resultTotal',0))}/>
          <ItemDescription loading={loading} title="Phải chi trả KH" value={formatNumberThreeComma(get(allSale,'resultTotalPaymentCustomer.resultTotal',0))}/>
          <ItemDescription loading={loading} title="Đơn hàng đã chấm dứt" value={formatNumberThreeComma(get(allSale,'resultTotalCustomerCancel.resultTotal',0))}/>
            </Row>
            </Col>
          </Row>
        </div>
        <TabTable 
        setBranchIds={setBranchIds} 
        branchIds={branchIds} 
        whCategories={whCategories} 
        date={date} 
        setDate={setDate} 
        callback={setUrlExport} 
        setArrCheckBox={setArrCheckBox} 
        arrCheckBox={ arrCheckBox} />
      </div>
    </div>
  );
}
