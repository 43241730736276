import { get } from "lodash";

import { humanize } from "~/utils/helper";

export const getExtraQuantityAndExtraDurationAndExtraTimeUnit = (record) => {
  const extraQuantity = get(record, "extraQuantity");
  const whSessionPriceItemId = get(record, "whSessionPriceItemId")
  const sessionPrices = get(record, "snapshotService.package.sessionPrices")

  const sessionPrice = sessionPrices.find(item => get(item, "_id") === whSessionPriceItemId);
  if (sessionPrice) {
    const extraDuration = get(sessionPrice, "extraDuration")
    const extraTimeUnit = get(sessionPrice, "extraTimeUnit")
    return humanize(extraTimeUnit, extraQuantity * extraDuration, true)
  }
  return ""
}
