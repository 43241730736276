import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Menu, Row, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import Text from 'antd/lib/typography/Text';
import { get, parseInt } from 'lodash';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import POLICIES from '~/constants/policy';
import {
  useDeleteMedicine,
  useMedicinePaging,
  useMedicineQueryParams,
  useMedicines,
  useUpdateMedicineParams
} from '~/hooks';
import { formatNumberThreeComma, Permissions } from '~/hooks/utils';
import { Breadcrumb, WithPermission } from '../Common';
import ModalConfirm from '../Common/ModalConfirm';
import SkeletonTable from '../Utils/SkeletonTable';
import Medicine from './Form/Medicine';
import './index.scss';
import TabBranch from '../Common/TabBranch';

const listOptionSearch = [
  {
    value: 'name',
    label: 'Tên thiết bị'
  },
  {
    value: 'price',
    label: 'Giá bán'
  }
];
const WhMedicines = () => {
  const { canCreate, canDelete, canUpdate } = Permissions('WHMEDICALEQUIPMENT');

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenFormDelete, setIsOpenFormDelete] = useState(false);
  const [selectSearch, setSelectSearch] = useState('name');
  const [selectSort, setSelectSort] = useState('sortPrice');
  const [keyword, setKeyword] = useState('');
  const [id, setId] = useState(null);
  const [query] = useMedicineQueryParams();
  const [{ onParamChange }] = useUpdateMedicineParams(query);
  const [medicines, isLoading] = useMedicines(query);
  useEffect(() => {
    for (const key in query) {
      if (['name', 'price'].includes(key)) {
        if (query[key]) {
          setSelectSearch(key);
          setKeyword(query[key]);
        }
      }
    }
  }, []);
  const [loadingSubmit, deleteMedicine] = useDeleteMedicine();
  const paging = useMedicinePaging();
  const handleOpenForm = () => {
    setIsOpenForm(true);
  };
  const handleOpenFormUpdate = (id) => {
    setIsOpenForm(true);
    setId(id);
  };
  const handleOpenFormDelete = (id) => {
    setIsOpenFormDelete(true);
    setId(id);
  };
  const handleDeleteMedicine = () => {
    deleteMedicine(id);
    setIsOpenFormDelete(false);
  };
  const closeDeleteMedicine = () => {
    setId(null);
    setIsOpenFormDelete(false);
  };
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
  };
  const columns = [
    {
      title: 'STT',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
      render: (item, record, index) => (
        <Text>
          {get(paging, 'pageSize', 0) * (get(paging, 'current', 0) - 1) +
            index +
            1}
        </Text>
      )
    },
    {
      title: 'Tên thiết bị',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
      render: (item, record, index) => <Text>{item || ''}</Text>
    },
    {
      title: 'Giá bán',
      align: 'center',
      dataIndex: 'price',
      key: 'price',
      render: (item, record, index) => (
        <Text>{formatNumberThreeComma(item) || 0}</Text>
      )
    },
    {
      title: 'Phần trăm chiết khấu',
      align: 'center',
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      render: (item, record, index) => <Text>{item}%</Text>
    },
    {
      title: 'Thao tác',
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      render: (item, record, index) => (
        <Dropdown
          overlayStyle={{ width: '140px' }}
          trigger={['click']}
          overlay={
            <Menu>
              <WithPermission permission={POLICIES.UPDATE_WHMEDICALEQUIPMENT}>
                <Menu.Item
                  onClick={() => handleOpenFormUpdate(get(record, '_id'))}
                  key={v4()}
                >
                  <p>Cập nhật</p>
                </Menu.Item>
              </WithPermission>

              <WithPermission permission={POLICIES.DELETE_WHMEDICALEQUIPMENT}>
                <Menu.Item
                  onClick={() => handleOpenFormDelete(get(record, '_id'))}
                  key={v4()}
                >
                  <p>Xoá</p>
                </Menu.Item>
              </WithPermission>
            </Menu>
          }
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
      )
    }
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <TabBranch>
          <Breadcrumb title="Thiết bị y tế" />
          <div className="page-content__main">
            <div className="page-content__left">
              <p>Giá bán</p>
              <Select
                allowClear
                value={
                  isNaN(parseInt(get(query, 'sortPrice')))
                    ? null
                    : parseInt(get(query, 'sortPrice'))
                }
                style={{ width: '100%' }}
                options={[
                  { label: 'Tăng dần', value: 1 },
                  { label: 'Giảm dần', value: -1 }
                ]}
                onChange={(value) =>
                  onParamChange({ sortPrice: value, sortDiscount: null })
                }
              />
              <p>Phần trăm chiết khấu</p>
              <Select
                allowClear
                value={
                  isNaN(parseInt(get(query, 'sortDiscount')))
                    ? null
                    : parseInt(get(query, 'sortDiscount'))
                }
                style={{ width: '100%' }}
                options={[
                  { label: 'Tăng dần', value: 1 },
                  { label: 'Giảm dần', value: -1 }
                ]}
                onChange={(value) =>
                  onParamChange({ sortPrice: null, sortDiscount: value })
                }
              />
            </div>

            <div className="page-content__right">
              <div className="page-wraper__header">
                <Row justify="space-between">
                  <Col span={12}>
                    <div>
                      <Select
                        // defaultValue={'name'}
                        style={{
                          width: 170
                        }}
                        onChange={handleChangeSelectSearch}
                        options={listOptionSearch}
                        value={selectSearch}
                      />
                      <Search
                        style={{ width: 300 }}
                        placeholder={`Tìm ${
                          listOptionSearch.find((e) => e.value === selectSearch)
                            .label
                        }`}
                        enterButton
                        allowClear
                        onSearch={async (value) => {
                          onParamChange({ [selectSearch]: value });
                        }}
                        onChange={(e) => setKeyword(e.target.value)}
                        value={keyword}
                      />
                    </div>
                  </Col>
                  <Col span={4}>
                    <Button
                      disabled={!canCreate}
                      onClick={handleOpenForm}
                      style={{ width: '100%' }}
                      type="primary"
                    >
                      <PlusOutlined /> Thêm sản phẩm
                    </Button>
                  </Col>
                </Row>
              </div>

              {isLoading ? (
                <SkeletonTable columns={columns} rowCount={10} />
              ) : (
                <Table
                  rowKey={(rc) => rc._id}
                  columns={columns}
                  dataSource={medicines}
                  onChange={({ current }) => onParamChange({ page: current })}
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                  size="middle"
                />
              )}
            </div>
          </div>
        </TabBranch>
      </div>
      <Medicine
        setIsOpenForm={setIsOpenForm}
        isOpenForm={isOpenForm}
        id={id}
        setId={setId}
      />
      <ModalConfirm
        text="Xác nhận xoá thiết bị y tế"
        textOk="Xác nhận xoá"
        type="delete"
        loading={loadingSubmit}
        visible={isOpenFormDelete}
        onCancel={closeDeleteMedicine}
        handleOk={handleDeleteMedicine}
      />
    </div>
  );
};

export default WhMedicines;
