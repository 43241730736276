import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getKeywordSearchs({ payload: query }) {
    try {
      const response = yield call(Api.keywordSearch.getAll, query);
      yield put({ type: Types.GET_KEYWORD_SEARCHS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_KEYWORD_SEARCHS_FAILED, payload: error });
    }
  }
function* getKeywordSearch({ payload: id }) {
    try {
      const response = yield call(Api.keywordSearch.getById, id);
      yield put({ type: Types.GET_KEYWORD_SEARCH_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_KEYWORD_SEARCH_FAILED, payload: error });
    }
  }
  function* createKeywordSearch(action) {
    try {
      const data = yield call(Api.keywordSearch.create, action.payload);
      yield put({ type: Types.CREATE_KEYWORD_SEARCH_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_KEYWORD_SEARCH_FAILED, payload: error });
    }
  }
  function* updateKeywordSearch(action) {
    try {
      const data = yield call(Api.keywordSearch.update, action.payload);
      yield put({ type: Types.UPDATE_KEYWORD_SEARCH_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_KEYWORD_SEARCH_FAILED, payload: error });
    }
  }
  function* deleteKeywordSearch(action) {
    try {
      const data = yield call(Api.keywordSearch.delete, action.payload);
      yield put({ type: Types.DELETE_KEYWORD_SEARCH_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_KEYWORD_SEARCH_FAILED, payload: error });
    }
  }
export default function* KeywordSearchs() {
    yield takeLatest(Types.GET_KEYWORD_SEARCHS_REQUEST, getKeywordSearchs);
    yield takeLatest(Types.GET_KEYWORD_SEARCH_REQUEST, getKeywordSearch);
    yield takeLatest(Types.CREATE_KEYWORD_SEARCH_REQUEST, createKeywordSearch);
    yield takeLatest(Types.UPDATE_KEYWORD_SEARCH_REQUEST, updateKeywordSearch);
    yield takeLatest(Types.DELETE_KEYWORD_SEARCH_REQUEST, deleteKeywordSearch);
  }
