import React, { useEffect, useMemo, useState } from 'react';
import { Spin, Table, Tabs } from 'antd';
import api from '~/api';
import { formatNumberThreeCommaToFixed } from '~/hooks/utils';
import { get } from 'lodash';
import toastr from 'toastr';

export const checkAddress = (address) => {
  return (typeof (address) === 'string'
    ? address
    : `${address?.street},${address?.ward},${address?.district},${address?.city}`)
};
/**
 * 
 * @param {Object} partner Info of partner
 * @param {String} whAppointmentId Info of partner
 * @param {Array} whPartners Info of partner
 * @param {Object} row data in row of table
 * @returns 
 */
export default function DistanceModal({ partner, whAppointmentId, whPartners, row }) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [activeKey, setActiveKey] = useState('1');
  
  // Filter get equipments 
  // const getListConfirm = row?.listConfirmed?.filter((item) => !item.batchId);
  // const getFilterListConfirm = getListConfirm?.filter((item) => item.supplierId !== partner._id);

  // const data = getFilterListConfirm?.map((item) => ({
  //   supplierId: item.supplierId !== 'worldhealth' ? item.supplierId :  '99999',
  //   productId: item.productId
  // }));
  const listPartners = whPartners?.map(item => item?._id);
  const filterPartner = listPartners?.filter((item) => item !== partner._id);
  /**
   * @param {String} partnerId
   * @param {Array} listInfo This is a list of tools and their suppliers
   */
  const dataBody = useMemo(()=>({
    whAppointmentId: whAppointmentId,
    listInfo: [...filterPartner],
    partnerId: partner._id,
    warehouse: activeKey === '1' ? undefined : true 
  }), [partner, whAppointmentId, activeKey]);

  /**
   * Get distance calculation data from partners to warehouses and from partners to equipment lending partners
   * @param {Object} dataBody
   */

  const getDistance = async () => {
    try {
      setDataSource([]);
      setIsLoading(true);
      const res = await api.Location.getDistanceToPartner(dataBody);
      setDataSource(res ?? []);
      setIsLoading(false);
    } catch (error) {
      toastr.error(error)
      setDataSource([]);
      setIsLoading(false);
    }
  };

  const handleChangeTab = (e) => {
    setActiveKey(e);
  };

  useEffect(() => {
    whPartners && getDistance()
  }, [partner, activeKey]);

  const columnsTab1 = [
    {
      title: 'Tên đối tác cung cấp',
      dataIndex: 'name',
      render: (value, record) => record?.partner?.name
    },
    {
      title: 'Khoảng cách',
      dataIndex: 'distanceKm',
      render: (value, record) => value === 0 ? '' : `${formatNumberThreeCommaToFixed(get(record, 'distanceKm'))}km`
    },
    {
      title: 'Địa chỉ nơi cung cấp',
      dataIndex: 'address',
      render: (value, record) => record?.partner?.address
    },
    {
      title: 'Tên thiết bị',
      dataIndex: 'product',
      render: (value, record) => {
        return record.listProduct?.map((item) => <span>{item?.name}</span>)
      },
    },
  ];
  const columnsTab2 = [
    {
      title: 'Tên kho cung cấp',
      dataIndex: 'name',
      render: (value, record) => record?.warehouse?.name || record?.warehouse?.name?.vi
    },
    {
      title: 'Khoảng cách',
      dataIndex: 'distanceKm',
      render: (value, record) => `${formatNumberThreeCommaToFixed(get(record, 'distanceKm'))}km`
    },
    {
      title: 'Địa chỉ nơi cung cấp',
      dataIndex: 'address',
      render: (value, record) => checkAddress(record?.warehouse?.address)
    },
    {
      title: 'Tên thiết bị',
      dataIndex: 'product',
      render: (value, record) => {
        return (record?.listProduct?.map((item) => (item?.name)))?.toString()
      },
    },
  ];
  const renderIsLoading = (component, columns) => {
    return isLoading ? <Spin /> : (dataSource.length > 0 ? component
      : <div style={{margin: '0 auto'}}>
        <h5>Không có {activeKey === '1' ? 'đối tác' : 'kho'} nào cung cấp thiết bị này!</h5>
      </div>
    )
  }
  return (
    <Tabs defaultActiveKey='1' onChange={handleChangeTab}>
      <Tabs.TabPane tab="Đến đối tác" key="1">
        {renderIsLoading(
          (
            <Table columns={columnsTab1} dataSource={dataSource ?? []} pagination={false} />
          ), columnsTab1
          )}
      </Tabs.TabPane>
      <Tabs.TabPane tab="Đến kho" key="2">
      {renderIsLoading(
        (
          <Table columns={columnsTab2} dataSource={dataSource ?? []} pagination={false} />
        ), columnsTab2
          )}
      </Tabs.TabPane>
    </Tabs>
  ); 
}
