export const GET_WH_BILLS_REQUEST = 'GET_WH_BILLS_REQUEST';
export const GET_WH_BILLS_SUCCESS = 'GET_WH_BILLS_SUCCESS';
export const GET_WH_BILLS_FAILED = 'GET_WH_BILLS_FAILED';

export const DELETE_WH_BILL_REQUEST = 'DELETE_WH_BILL_REQUEST';
export const DELETE_WH_BILL_SUCCESS = 'DELETE_WH_BILL_SUCCESS';
export const DELETE_WH_BILL_FAILED = 'DELETE_WH_BILL_FAILED';

export const CREATE_WH_BILL_REQUEST = 'CREATE_WH_BILL_REQUEST';
export const CREATE_WH_BILL_SUCCESS = 'CREATE_WH_BILL_SUCCESS';
export const CREATE_WH_BILL_FAILED = 'CREATE_WH_BILL_FAILED';

export const UPDATE_WH_BILL_REQUEST = 'UPDATE_WH_BILL_REQUEST';
export const UPDATE_WH_BILL_SUCCESS = 'UPDATE_WH_BILL_SUCCESS';
export const UPDATE_WH_BILL_FAILED = 'UPDATE_WH_BILL_FAILED';

export const CUSTOMER_CANCEL_WH_BILL_REQUEST = 'CUSTOMER_CANCEL_WH_BILL_REQUEST';
export const CUSTOMER_CANCEL_WH_BILL_SUCCESS = 'CUSTOMER_CANCEL_WH_BILL_SUCCESS';
export const CUSTOMER_CANCEL_WH_BILL_FAILED = 'CUSTOMER_CANCEL_WH_BILL_FAILED';

export const UPDATE_BILL_ITEM_OF_BILL_REQUEST = 'UPDATE_BILL_ITEM_OF_BILL_REQUEST';
export const UPDATE_BILL_ITEM_OF_BILL_SUCCESS = 'UPDATE_BILL_ITEM_OF_BILL_S;UCCESS';
export const UPDATE_BILL_ITEM_OF_BILL_FAILED = 'UPDATE_BILL_ITEM_OF_BILL_FAILED'

export const CONFIRM_PREPAYMENT_WH_BILL_REQUEST = 'CONFIRM_PREPAYMENT_WH_BILL_REQUEST';
export const CONFIRM_PREPAYMENT_WH_BILL_SUCCESS = 'CONFIRM_PREPAYMENT_WH_BILL_SUCCESS';
export const CONFIRM_PREPAYMENT_WH_BILL_FAILED = 'CONFIRM_PREPAYMENT_WH_BILL_FAILED';

export const CANCEL_WH_BILL_REQUEST = 'CANCEL_WH_BILL_REQUEST';
export const CANCEL_WH_BILL_SUCCESS = 'CANCEL_WH_BILL_SUCCESS';
export const CANCEL_WH_BILL_FAILED = 'CANCEL_WH_BILL_FAILED';

export const GET_WH_BILL_REQUEST = 'GET_WH_BILL_REQUEST';
export const GET_WH_BILL_SUCCESS = 'GET_WH_BILL_SUCCESS';
export const GET_WH_BILL_FAILED = 'GET_WH_BILL_FAILED';

export const RESET_WH_BILL_STATE = 'RESET_WH_BILL_STATE';
export const RESET_WH_BILL_CONFIRM_STATE = 'RESET_WH_BILL_CONFIRM_STATE';

export const GET_DEPRECIATION_WH_BILLS_REQUEST = 'GET_DEPRECIATION_WH_BILLS_REQUEST';
export const GET_DEPRECIATION_WH_BILLS_SUCCESS = 'GET_DEPRECIATION_WH_BILLS_SUCCESS';
export const GET_DEPRECIATION_WH_BILLS_FAILED = 'GET_DEPRECIATION_WH_BILLS_FAILED';
