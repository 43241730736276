import { clone, get, parseInt } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import api from '~/api';
import * as Types from '~/constants/actionTypes';
function* getUnConfirmWhBills({ payload: query }) {
    try {
      const response = yield call(api.unConfirmWhBill.getAll, query);
      const branchId = yield select((state) => get(state,'user.profile.branchId'));
      yield put({ type: Types.GET_UNCONFIRM_WH_BILLS_SUCCESS, payload: {...response,branchId} });
    } catch (error) {
      yield put({ type: Types.GET_UNCONFIRM_WH_BILLS_FAILED, payload: error });
    }
  }
function* getUnConfirmWhBillsEventStream({ payload: query }) {
    try {
      const response = yield call(api.unConfirmWhBill.getAll, query);
      const branchId = yield select((state) => get(state,'user.profile.branchId'));
      yield put({ type: Types.GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_SUCCESS, payload: {...response,branchId} });
    } catch (error) {
      yield put({ type: Types.GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_FAILED, payload: error });
    }
  }
function* getUnConfirmWhBill({ payload: id }) {
    try {
      const response = yield call(api.unConfirmWhBill.getOne, id);
      yield put({ type: Types.GET_UNCONFIRM_WH_BILL_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_UNCONFIRM_WH_BILL_FAILED, payload: error });
    }
  }
  function* confirmUnConfirmWhBill(action) {
    try {
      const data = yield call(api.unConfirmWhBill.confirm, action.payload);
      yield put({ type: Types.CONFIRM_UNCONFIRM_WH_BILL_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CONFIRM_UNCONFIRM_WH_BILL_FAILED, payload: error });
    }
  }

  function* getListBranchConfirm({ payload: query }) {
    try {
      const branches = yield select((state) => get(state,'user.profile.branches',[]));
      const response = yield call(api.unConfirmWhBill.getBranchConfirm, query);
      const compareResponseWithBranches = branches?.map(branch => {
        const isAvailable = response?.some(b => parseInt(get(b,'branchId')) === parseInt(get(branch,'_id')));
        return {...branch,isAvailable};
      });
      const cloneCompare = clone(compareResponseWithBranches);
      cloneCompare.sort((a, b) => get(b,'isAvailable') - get(a,'isAvailable'));
      yield put({ type: Types.GET_LIST_BRANCH_CONFIRM_SUCCESS, payload: cloneCompare });
    } catch (error) {
      yield put({ type: Types.GET_LIST_BRANCH_CONFIRM_FAILED, payload: error });
    }
  }

export default function* UnConfirmWhBills() {
    yield takeLatest(Types.GET_UNCONFIRM_WH_BILLS_REQUEST, getUnConfirmWhBills);
    yield takeLatest(Types.GET_UNCONFIRM_WH_BILLS_EVENT_STREAM_REQUEST, getUnConfirmWhBillsEventStream);
    yield takeLatest(Types.GET_UNCONFIRM_WH_BILL_REQUEST, getUnConfirmWhBill);
    yield takeLatest(Types.CONFIRM_UNCONFIRM_WH_BILL_REQUEST, confirmUnConfirmWhBill);
    yield takeLatest(Types.GET_LIST_BRANCH_CONFIRM_REQUEST, getListBranchConfirm);
  }
