import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getWhWorkNorms({payload}) {
  try {
    const data = yield call(Api.staffGroup.getAllJobQuota,payload);
    yield put({ type: Types.GET_WH_WORK_NORMS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_WORK_NORMS_FAILED,
      payload: error.message
    });
  }
}

function* getAllEmployyGroup() {
  try {
    const data = yield call(Api.staffGroup.getAllEmployyGroup);
    yield put({ type: Types.GET_EMPLOYEE_GROUP_WH_WORK_NORMS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_EMPLOYEE_GROUP_WH_WORK_NORMS_FAILED,
      payload: error
    });
  }
}

function* getWhWorkNorm({ payload: id }) {
  try {
    const whWorkNorm = yield call(Api.whWorkNorm.getById, id);
    yield put({ type: Types.GET_WH_WORK_NORM_SUCCESS, payload: whWorkNorm });
  } catch (error) {
    yield put({ type: Types.GET_WH_WORK_NORM_FAILED, payload: error });
  }
}

function* createWhWorkNorm(action) {
  try {
    const data = yield call(Api.staffGroup.createJobQuota, action.payload);
    yield put({ type: Types.CREATE_WH_WORK_NORM_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_WH_WORK_NORM_FAILED,
      payload: error
    });
  }
}

function* updateWhWorkNorm(action) {
  try {
    const data = yield call(Api.staffGroup.updateJobQuota, action.payload);
    yield put({ type: Types.UPDATE_WH_WORK_NORM_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_WORK_NORM_FAILED,
      payload: error
    });
  }
}

function* deleteWhWorkNorm({ payload }) {
  try {
    yield call(Api.whWorkNorm.delete, payload);
    yield put({ type: Types.DELETE_WH_WORK_NORM_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_WORK_NORM_FAILED,
      payload: error.message
    });
  }
}

export default function* whWorkNorm() {
  yield takeLatest(Types.GET_WH_WORK_NORMS_REQUEST, getWhWorkNorms);
  yield takeLatest(Types.GET_EMPLOYEE_GROUP_WH_WORK_NORMS_REQUEST, getAllEmployyGroup);
  yield takeLatest(Types.GET_WH_WORK_NORM_REQUEST, getWhWorkNorm);
  yield takeLatest(Types.DELETE_WH_WORK_NORM_REQUEST, deleteWhWorkNorm);
  yield takeLatest(Types.CREATE_WH_WORK_NORM_REQUEST, createWhWorkNorm);
  yield takeLatest(Types.UPDATE_WH_WORK_NORM_REQUEST, updateWhWorkNorm);
}
