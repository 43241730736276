import { Tabs } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TabConfirmVoucher from './TabConfirmVoucher';
import TabWarehouseVoucher from './TabWarehouseVoucher';

export default function WarehouseVoucherWhPartner() {
  const [tabs, setTabs] = useState('1')
  const onChangeTabs = (tabs) => {
    setTabs(tabs)
  }
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <h3>Phiếu công cụ dụng cụ</h3>
        <div className="page-content__main">
          <div className="page-content__right" style={{ width: '100%' }}>
          
            <Tabs activeKey={tabs} onChange={onChangeTabs}>
              <Tabs.TabPane destroyInactiveTabPane key='1' tab='Phiếu xác nhận sử dụng'>
                <TabConfirmVoucher active={tabs === '1'}/>
              </Tabs.TabPane>
              <Tabs.TabPane destroyInactiveTabPane key='2' tab='Phiếu bàn giao'>
                <TabWarehouseVoucher  active={tabs === '2'}/> 
              </Tabs.TabPane>
            </Tabs>

          </div>
        </div>
      </div>
    </div>
  );
}
