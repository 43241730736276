import requester from './requester';

const whCustomerImport = {
  getAll: query => requester.get(`/potential-customer`, query),
  getById: id => requester.get(`/potential-customer/${id}?raw=true`),
  delete: customerId => requester.delete(`/potential-customer/${customerId}`),
  create: whCustomerImport => requester.post(`/potential-customer`, whCustomerImport),
  update: customerId =>
    requester.put(`/potential-customer/${customerId.id}`,customerId),
  convert: customerId => requester.post(`/convert-to-user/${customerId}`)
};

export default whCustomerImport;

