import { Form } from 'antd'
import { isArray } from 'lodash'
import React, { useMemo } from 'react'
import { LANGUAGE } from '~/constants/defaultValue'

export default function LangFormItem({ fieldName, language, ...props }) {
    const fieldName_ = useMemo(() => isArray(fieldName) ? [...fieldName] : [fieldName],[fieldName])
    return (
        <>
            <Form.Item hidden={language !== LANGUAGE.VI} name={[...fieldName_,LANGUAGE.VI]} {...props}>
                {props.children}
            </Form.Item>
            <Form.Item hidden={language !== LANGUAGE.EN} name={[...fieldName_,LANGUAGE.EN]} {...props}>
                {props.children}
            </Form.Item>
        </>
    )
}
