import * as Types from '../../constants/actionTypes';

export const getDegrees = () => ({
  type: Types.GET_DEGREES_REQUEST
});

export const getDegree = id => ({
  type: Types.GET_DEGREE_REQUEST,
  payload: id
});

export const createDegree = degree => ({
  type: Types.CREATE_DEGREE_REQUEST,
  payload: degree
});

export const updateDegree = degree => ({
  type: Types.UPDATE_DEGREE_REQUEST,
  payload: degree
});

export const deleteDegree = id => ({
  type: Types.DELETE_DEGREE_REQUEST,
  payload: id
});

export const resetDegreeState = () => ({
  type: Types.RESET_DEGREE_STATE
});
