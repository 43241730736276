import { get } from 'lodash';
import { put, call, takeLatest } from 'redux-saga/effects';
import { GET_WH_ALL_VOUCHERS_FAILED, GET_WH_ALL_VOUCHERS_REQUEST, GET_WH_ALL_VOUCHERS_SUCCESS } from '~/constants/actionTypes';
import Api from '../../api';

function* getWhAllVouchers({ payload: query }) {
  try {
    const response = yield call(Api.whAllVoucher.getAll, query);
    yield put({ type: GET_WH_ALL_VOUCHERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_ALL_VOUCHERS_FAILED, payload: error.message });
  }
}


export default function* whAllVouchers() { 
  yield takeLatest(GET_WH_ALL_VOUCHERS_REQUEST, getWhAllVouchers);
}