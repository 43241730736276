import { Button, Drawer,  Modal, Space, Spin} from 'antd';
import { createContext, useContext, useEffect, useMemo, useRef, useState, lazy, Suspense } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  useCreateBoardWork,
  useCreateTask,
  useDeleteBoardWork,
  useDeleteTask,
  useGetBoardById,
  useListBoardConfig,
  useListBoardConfigItem,
  useUpdatePosition,
  useUpdateTask,
} from '~/hooks/workFlow';
// import BoardConfig from './BoardConfig';
import { get } from 'lodash';
// import TaskForm from './TaskForm/TaskForm';
import { WithOrPermission } from '../Common';
import POLICIES from '~/constants/policy';
import { ResizableBox } from 'react-resizable';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
// import MenuListBoard from './WorkList/MenuListBoard';
// import TaskTabDetail from './TaskTabs/TaskTabDetail';
import { useGetSprintInfo } from '~/hooks/workSprint';
// import MenuFilter from './MenuFilter';
import Text from 'antd/lib/typography/Text';
import { useWorkListQueryParams } from '~/hooks/workList';
import BoardConfig from './BoardConfig';
// import TodoList from './Detail/TodoList';
// import Detail from './Detail';
const MenuListBoard = lazy(()=>import('./WorkList/MenuListBoard.js'))
// const BoardConfig = lazy(()=>import('./BoardConfig.js'))
const TaskForm = lazy(()=>import('./TaskForm/TaskForm.js'))
const TaskTabDetail = lazy(()=>import('./TaskTabs/TaskTabDetail.js'))
const MenuFilter = lazy(()=>import('./MenuFilter.js'))
const FormTaskContext = createContext();
export const useFormTaskContext = () => useContext(FormTaskContext);

const WorkList = () => {
  const workflowRef = useRef()
  const { sprintId: sprintId_ } = useParams();
  const sprintId = useMemo(() => (sprintId_), [sprintId_]);
  const [sprintInfo,] = useGetSprintInfo(sprintId);
  const idBoard = useMemo(() => (sprintInfo?.boardId), [sprintInfo]);
  const [query]=useWorkListQueryParams(sprintId)
  const [visibleModal, setVisibleModal] = useState(false);
  const [boardConfig] = useListBoardConfig(query);
  const boardConfigMemo = useMemo(() => (boardConfig ?? []).map(({ name, _id }) => ({ name, _id })), [boardConfig])
  const [data] = useListBoardConfigItem();
 
  const [propsModal, setPropsModal] = useState({});
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [langthList, setLan] = useState(workflowRef?.current?.offsetWidth ?? window.innerWidth);
  const [taskData, setTaskData] = useState('');
  const [visibleListBoard, setVisibleListBoard] = useState(false)
  const [idVisibleInfo, setIdVisibleInfo] = useState('');
  const [, updateTask] = useUpdateTask();
  const [, updatePosition] = useUpdatePosition();
  const [, handleCreateTask] = useCreateTask();
  const [, handleDeleteTask] = useDeleteTask();
  const [, handleCreateWork] = useCreateBoardWork();
  const [, handleDeleteWork] = useDeleteBoardWork();
  const [tasksAllBoard, setTasksAllBoard] = useState({});
  //data board 
  const [boardData] = useGetBoardById(idBoard);
  const showDrawer = (param) => {
    setVisibleListBoard((val) => param ?? (!val));
  };
  const openFormTask = (id, data) => {
    setPropsModal({ boardConfigId: id })
    setVisibleModal(true)
  }
  useEffect(() => {
    if (!visibleInfo) {
      setLan(workflowRef?.current?.offsetWidth ?? window.innerWidth)
    } else {
      setLan(workflowRef?.current?.offsetWidth * 0.7)
    }
  }, [visibleInfo])
  const onDragEndv2 = (result) => {
    let colBefore = result?.source?.droppableId,
      indexBefore = result?.source?.index,

      colAfter = result?.destination?.droppableId,
      indexAfter = result?.destination?.index;
    if (!colAfter) {
      return
    }
    let dataBefore = [... { ...data }[colBefore]];
    let dataAfter = [...data[colAfter]];
    if (colBefore === colAfter) {
      if (indexBefore === indexAfter) {
        return
      }
      dataAfter.splice(indexBefore, 1);
    }

    let valueIdxUp = get(dataAfter[indexAfter - 1], 'ordinal', 0);
    let valueIdxDown = get(dataAfter[indexAfter], 'ordinal', valueIdxUp + 10);

    let newOrdinal = (valueIdxUp + valueIdxDown) / 2;

    let [{ ...itemBeRemove }] = dataBefore.splice(indexBefore, 1);
    Object.assign(itemBeRemove ?? {}, { ordinal: newOrdinal });
    updateTask({ id: itemBeRemove._id, ordinal: newOrdinal, boardConfigId: colAfter });
    updatePosition({
      colBefore,
      indexBefore,
      colAfter,
      indexAfter,
    })

  }
  return (
    <div className="branch-detail page-wraper page-content page-workflow">
      <FormTaskContext.Provider
        value={{
          ...propsModal,
          setPropsModal,
          openForm: openFormTask,
          boardId: idBoard,
          boardData,
          sprintId: sprintId_,
          updateTask,
          handleCreateTask,
          handleDeleteWork,
          setVisibleModal,
          handleDeleteTask,
          setVisibleInfo,
          setIdVisibleInfo,
          setTaskData,
          taskData
        }}
      >
        <Space style={{ width: '100%', height: 40, justifyContent: 'start',alignItems: 'center' }}>
          {/* <Space style={{ padding: '0 20px', alignItems: 'center' }}> */}
            <Button htmlType='button' type='primary' onClick={(e) => {
              e.preventDefault();
              showDrawer()
            }}>=</Button>

            {!get(sprintInfo, 'name') ? (
              <Spin></Spin>
            ) : (
              <Text ellipsis={{rows:1}} style={{fontSize:28, width:'92vw'}}>{get(sprintInfo, 'name')}</Text>
              // <h2 style={{ margin: 0 }}>{get(sprintInfo, 'name')}</h2>
            )}
          {/* </Space> */}
        </Space>
        <Suspense fallback={<p>...</p>}>
          <MenuFilter />
        </Suspense>
          
        <hr />
        <div className="workflow" ref={workflowRef}>
          <ResizableBox
            className={'react-resizable_custom ' + (visibleInfo ? 'active' : '')}
            resizeHandles={['e']}
            minConstraints={[(workflowRef?.current?.offsetWidth ?? window.innerWidth) * 0.2, Infinity]}//fix window >> document (ref)
            maxConstraints={[(workflowRef?.current?.offsetWidth ?? window.innerWidth) * 0.7, Infinity]}//fix window >> document (ref)
            height={Infinity}
            draggableOpts={{ grid: [8, 8] }}
            width={langthList}
          >
            <div className="work-list">
              <div className="work-list-body">
                <DragDropContext onDragEnd={onDragEndv2}>
                  {boardConfigMemo?.map(({ name, _id }) => {
                    return (
                        <BoardConfig
                          key={_id}
                          dataBoardConfigItem={data[_id] ?? []}
                          tasksAllBoard={tasksAllBoard}
                          setTasksAllBoard={setTasksAllBoard}
                          name={name}
                          id={_id}
                        />
                    );
                  })}
                  <WithOrPermission permission={[POLICIES.WRITE_TODOLIST, POLICIES.ADMIN_TODOLIST]}>
                    <Button
                      type="ghost"
                      htmlType="button"
                      onClick={() =>
                        handleCreateWork({ boardId: idBoard, sprintId, name: 'Default' })
                      }
                    >
                      + Thêm danh mục
                    </Button>

                  </WithOrPermission>
                </DragDropContext>
              </div>
            </div>
          </ResizableBox>
          <div className="view-port-info-container" >
            {visibleInfo && (
              <div className="view-port-info-body">
                <Suspense fallback={<p>...loading</p>}>
                <TaskTabDetail taskData={taskData} setVisibleInfo={setVisibleInfo} idTask={idVisibleInfo} />
                </Suspense>
              </div>//
            )}
          </div>
        </div>
        <Drawer 
          title={<p style={{whiteSpace:'nowrap', textOverflow:'ellipsis'}}> <Button type='dashed' href={'/work-flow/sprint/'+idBoard} ><ArrowLeftOutlined style={{color:'black',fontSize:16}} /></Button> &nbsp; Danh sách nhóm</p>}
          extra={<Button type='text' onClick={()=>showDrawer(false)}><CloseOutlined /></Button> }
          placement="left"
          mask={true}
          maskStyle={{ background: 'transparent', width: '100vw' }}
          drawerStyle={{ background: '#333739', }}
          bodyStyle={{
            paddingLeft:0,
            paddingRight:0
          }}
          width={'max-content'}
          closable={false}
          maskClosable={true}
          onClose={() => showDrawer(false)}
          visible={visibleListBoard}
          getContainer={false}

          headerStyle={{
            padding:'10px 8px 10px 3px'
          }}
          style={{
            position: 'absolute',
            boxShadow: '0px 3px 3px #333'
          }}>
          <ResizableBox
            className={'react-resizable_custom react-resizable-list_board'}
            resizeHandles={['e']}
            minConstraints={[256, Infinity]}//fix window >> document (ref)
            maxConstraints={[500, Infinity]}//fix window >> document (ref)
            height={Infinity}
            width={300}
          >
            <Suspense fallback={<p>...</p>}>
             <MenuListBoard /> 
            </Suspense>
          </ResizableBox>
        </Drawer>
        <Modal
          visible={visibleModal}
          onCancel={() => setVisibleModal(false)}
          footer={false}
          closable={false}
          destroyOnClose
        >
          <Suspense fallback={<p>...</p>}>
          <TaskForm />
          </Suspense>
        </Modal>
      </FormTaskContext.Provider>
    </div>
  );
};

export default WorkList;
