export const GET_TRAINING_COURSES_REQUEST =
  'GET_TRAINING_COURSES_REQUEST';
export const GET_TRAINING_COURSES_SUCCESS =
  'GET_TRAINING_COURSES_SUCCESS';
export const GET_TRAINING_COURSES_FAILED = 'GET_TRAINING_COURSES_FAILED';

export const GET_TRAINING_COURSE_REQUEST = 'GET_TRAINING_COURSE_REQUEST';
export const GET_TRAINING_COURSE_SUCCESS = 'GET_TRAINING_COURSE_SUCCESS';
export const GET_TRAINING_COURSE_FAILED = 'GET_TRAINING_COURSE_FAILED';

export const CREATE_TRAINING_COURSE_REQUEST =
  'CREATE_TRAINING_COURSE_REQUEST';
export const CREATE_TRAINING_COURSE_SUCCESS =
  'CREATE_TRAINING_COURSE_SUCCESS';
export const CREATE_TRAINING_COURSE_FAILED =
  'CREATE_TRAINING_COURSE_FAILED';

export const UPDATE_TRAINING_COURSE_REQUEST =
  'UPDATE_TRAINING_COURSE_REQUEST';
export const UPDATE_TRAINING_COURSE_SUCCESS =
  'UPDATE_TRAINING_COURSE_SUCCESS';
export const UPDATE_TRAINING_COURSE_FAILED =
  'UPDATE_TRAINING_COURSE_FAILED';

export const DELETE_TRAINING_COURSE_REQUEST =
  'DELETE_TRAINING_COURSE_REQUEST';
export const DELETE_TRAINING_COURSE_SUCCESS =
  'DELETE_TRAINING_COURSE_SUCCESS';
export const DELETE_TRAINING_COURSE_FAILED =
  'DELETE_TRAINING_COURSE_FAILED';

export const CONVERT_TRAINING_COURSE_REQUEST =
  'CONVERT_TRAINING_COURSE_REQUEST';
export const CONVERT_TRAINING_COURSE_SUCCESS =
  'CONVERT_TRAINING_COURSE_SUCCESS';
export const CONVERT_TRAINING_COURSE_FAILED =
  'CONVERT_TRAINING_COURSE_FAILED';

export const RESET_TRAINING_COURSE_STATE = 'RESET_TRAINING_COURSE_STATE';
