export const GET_WHPARTNERREGISTERS_REQUEST =
    'GET_WHPARTNERREGISTERS_REQUEST';
export const GET_WHPARTNERREGISTERS_SUCCESS =
    'GET_WHPARTNERREGISTERS_SUCCESS';
export const GET_WHPARTNERREGISTERS_FAILED = 'GET_WHPARTNERREGISTERS_FAILED';

export const GET_WHPARTNERREGISTER_REQUEST = 'GET_WHPARTNERREGISTER_REQUEST';
export const GET_WHPARTNERREGISTER_SUCCESS = 'GET_WHPARTNERREGISTER_SUCCESS';
export const GET_WHPARTNERREGISTER_FAILED = 'GET_WHPARTNERREGISTER_FAILED';

export const CREATE_WHPARTNERREGISTER_REQUEST =
    'CREATE_WHPARTNERREGISTER_REQUEST';
export const CREATE_WHPARTNERREGISTER_SUCCESS =
    'CREATE_WHPARTNERREGISTER_SUCCESS';
export const CREATE_WHPARTNERREGISTER_FAILED =
    'CREATE_WHPARTNERREGISTER_FAILED';

export const UPDATE_WHPARTNERREGISTER_REQUEST =
    'UPDATE_WHPARTNERREGISTER_REQUEST';
export const UPDATE_WHPARTNERREGISTER_SUCCESS =
    'UPDATE_WHPARTNERREGISTER_SUCCESS';
export const UPDATE_WHPARTNERREGISTER_FAILED =
    'UPDATE_WHPARTNERREGISTER_FAILED';


export const DELETE_WHPARTNERREGISTER_REQUEST =
    'DELETE_WHPARTNERREGISTER_REQUEST';
export const DELETE_WHPARTNERREGISTER_SUCCESS =
    'DELETE_WHPARTNERREGISTER_SUCCESS';
export const DELETE_WHPARTNERREGISTER_FAILED =
    'DELETE_WHPARTNERREGISTER_FAILED';


export const RESET_WHPARTNERREGISTER_STATE = 'RESET_WHPARTNERREGISTER_STATE';
export const RESET_WHPARTNERREGISTER_STATE_ACTION = 'RESET_WHPARTNERREGISTER_STATE_ACTION';
