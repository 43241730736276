import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '~/api';
import {
  getSelectors,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createTipsPartner,
  deleteTipsPartner,
  getLogsTipsPartner,
  getTipsPartner,
  getTipsPartners,
  resetTipsPartnerState,
  updateTipsPartner
} from '~/redux/action';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.tipsPartner[key];
const pagingSelector = getSelector('paging');
export const useTipsPartnerPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');

const loadingLogsSelector = getSelector('isLoadingLogs');
const logsTipsPartnersSelector = getSelector('logsTipsPartners');
const getLogsTipsPartnerFailedSelector = getSelector('getLogsTipsPartnersFailed');


const loadinggetTipsPartnerSelector = getSelector('isGetTipsPartnerLoading');
const getTipsPartnersFailedSelector = getSelector('getTipsPartnersFailed');
const getTipsPartnerFailedSelector = getSelector('getTipsPartnerFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const tipsPartnersSelector = getSelector('tipsPartners');
const tipsPartnerSelector = getSelector('tipsPartner');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useTipsPartnerQueryParams = (partnerId,date,limitDefault,pageDefault) => {
  const query = useQueryParams();
  const limit = query.get('limit')  || limitDefault ;
  const page = query.get('page') || pageDefault;
  const startDate = query.get('startDate') || date?.startDate || null;
  const endDate = query.get('endDate') || date?.endDate || null;
  // const keyword = query.get('keyword')
  const createSuccess = useSelector(createSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      partnerId,
      startDate,
      endDate,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    partnerId,
    createSuccess,
    deleteSuccess,
    updateSuccess,
    startDate,
    endDate
  ]);
};
export const useTipsPartnerAllQueryParams = (partnerId,date) => {
  const query = useQueryParams();
  const limit = null;
  const page = null;
  const startDate = query.get('startDate') || date?.startDate || null;
  const endDate = query.get('endDate') || date?.endDate || null;
  // const keyword = query.get('keyword')
  const createSuccess = useSelector(createSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      partnerId,
      startDate,
      endDate,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    partnerId,
    createSuccess,
    deleteSuccess,
    updateSuccess,
    startDate,
    endDate
  ]);
};

export const useUpdateTipsPartnerParams = (query) => {
  const history = useHistory();
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName'),
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [{ onParamChange }];
};
export const useTipsPartners = (query) => {
  return useFetchByParam({
    action: getTipsPartners,
    loadingSelector,
    dataSelector: tipsPartnersSelector,
    failedSelector: getTipsPartnersFailedSelector,
    param: query
  });
};
export const useTipsPartner = (id) => {
  return useFetchByParam({
    action: getTipsPartner,
    loadingSelector: loadinggetTipsPartnerSelector,
    dataSelector: tipsPartnerSelector,
    failedSelector: getTipsPartnerFailedSelector,
    param: id
  });
};
// export const useLogTipsPartner = (id) => {
//   return useFetchByParam({
//     action: getLogsTipsPartner,
//     loadingSelector: loadingLogsSelector,
//     dataSelector: logsTipsPartnersSelector,
//     failedSelector: getLogsTipsPartnerFailedSelector,
//     param: id
//   });
// }
export const useLogsTipsPartners = (id,date) => {
  const [data,setData] = useState([])
  const [loading,setLoading] = useState(false)
  const createSuccess = useSelector(createSuccessSelector);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await api.TipsPartner.getAllLogsTips({startDate:date.startDate,endDate:date.endDate,id})
      setData(res)
      setLoading(false)
    }
    if(id && date)fetchData()
  },[id,createSuccess])
return [data,loading]
}
export const useCreateTipsPartner = (callback) => {
  useSuccess(
    createSuccessSelector,
    'Tạo thưởng phạt thành công',
    callback
  );
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createTipsPartner
  });
};
export const useUpdateTipsPartner = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật thưởng phạt thành công',
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateTipsPartner
  });
};
export const useDeleteTipsPartner = (callback) => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá thưởng phạt thành công',
    callback
  );
  useFailed(deleteFailedSelector, 'Xoá nhật thưởng phạt thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteTipsPartner
  });
};

export const useResetTipsPartner = () => {
  useResetState(resetTipsPartnerState);
};
