import * as Types from '../../constants/actionTypes';
  
  export const getGiveCoupons = query => ({
    type: Types.GET_GIVE_COUPONS_REQUEST,
    payload: query
  });
  export const getGiveCoupon = id => ({
    type: Types.GET_GIVE_COUPON_REQUEST,
    payload: id
  });
  export const createGiveCoupon = instance => ({
    type: Types.CREATE_GIVE_COUPON_REQUEST,
    payload: instance
  });
  export const updateGiveCoupon = instance => ({
    type: Types.UPDATE_GIVE_COUPON_REQUEST,
    payload: instance
  });
  export const deleteGiveCoupon = id => ({
    type: Types.DELETE_GIVE_COUPON_REQUEST,
    payload: id
  });
  export const resetGiveCouponState = () => ({
    type: Types.RESET_GIVE_COUPON_STATE
  });
