import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createJobQuestion,
  deleteJobQuestion,
  getJobQuestion,
  getJobQuestions,
  resetJobQuestionState,
  updateJobQuestion
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.jobQuestion[key];
const pagingSelector = getSelector('paging');
export const useJobQuestionPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetJobQuestionSelector = getSelector('isGetJobQuestionLoading');
const getJobQuestionsFailedSelector = getSelector('getJobQuestionsFailed');
const getJobQuestionFailedSelector = getSelector('getJobQuestionFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const JobQuestionsSelector = getSelector('JobQuestions');
const JobQuestionSelector = getSelector('JobQuestion');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useJobQuestionQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const active = query.get('active');
  const createJobQuestionSuccess = useSelector(createSuccessSelector);
  const deleteJobQuestionSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      active,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    active,
    createJobQuestionSuccess,
    deleteJobQuestionSuccess,
  ]);
};

export const useUpdateJobQuestionParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.jobQuestion.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};
export const useJobQuestions = (query) => {
  return useFetchByParam({
    action: getJobQuestions,
    loadingSelector,
    dataSelector: JobQuestionsSelector,
    failedSelector: getJobQuestionsFailedSelector,
    param: query
  });
};
export const useJobQuestion = (id) => {
  return useFetchByParam({
    action: getJobQuestion,
    loadingSelector: loadinggetJobQuestionSelector,
    dataSelector: JobQuestionSelector,
    failedSelector: getJobQuestionFailedSelector,
    param: id
  });
};
export const useCreateJobQuestion = (callback) => {
  
  useSuccess(createSuccessSelector, 'Tạo mới câu hỏi thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới câu hỏi thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createJobQuestion
  });
};
export const useUpdateJobQuestion = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât câu hỏi thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateJobQuestion
  });
};
export const useDeleteJobQuestion = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá câu hỏi thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật câu hỏi thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteJobQuestion
  });
};

export const useResetJobQuestion = () => {
  useResetState(resetJobQuestionState);
};
