import { get, head } from 'lodash'
import { useCallback,useMemo } from 'react'

export const ConvertDescripton = (descriptions) => {
    return useMemo(()=>((v) =>{
        const _descriptions ={}
        const _price ={}
        for (let i = 0; i < v.length ;i++) {
            const key =v[i]
            const valueArr=descriptions[key] ?? ""
            if(typeof valueArr !=="string"){
                _descriptions[key] = valueArr.reduce((a,b,i)=>{
                    switch (i) {
                        case 0: return  (!isNaN(b) ) ? a :  a + `- ${b}\n`
                        case 1: return a + `- ${b}`
                        default: return a + `\n- ${b}`
                    }
                    } ,"") 
                const check = head(valueArr)
                _price[key]= !isNaN(check) ? check : ""
            }
            else if(valueArr===""){
                _price[key] =  ""
                _descriptions[key] = ""
            }else {
                const fIndex = valueArr.search(/\n/)
                const check =  get(descriptions,key , "" ).slice(0, fIndex)
                _price[key] = !isNaN(check) ? check : ""
                _descriptions[key] = valueArr.replaceAll('\n',`\n- `).slice(fIndex+1)
            }
        }
        return [_descriptions,_price]
    })(["vi","en"])     
    ,[descriptions])
}

export const HandleCheckValue =()=>{
    const handleCheck = useCallback((dataRoot,valueForm,callback,callbackUpdate)=>{
        const {name:name_v1,description:description_v1,price:price_v1}=valueForm
        const {name:name_v2,description:description_v2,price:price_v2}=dataRoot

        const arr=["vi","en"]
        let trust =[]
        for (let i = 0; i < arr.length; i++) {    
            let key = arr[i]
            if(name_v1?.[key] ===name_v2?.[key]
            && description_v1?.[key] ===description_v2?.[key]
            &&price_v1?.[key] ===price_v2?.[key]){
                trust.push("true")
            }else{
                trust.push("false")
            }            
        }
        if(trust.every((e)=>e==="true")){
            callback((cur)=>!cur)
            return true
        }else{
            const description  = {}
            const name  = {
              "vi":name_v1?.['vi'],
              "en":name_v1?.['en']
            }
            description["vi"] = (price_v1?.["vi"] +"\n"+description_v1?.["vi"]).replaceAll(/ + +- +|-/g,"")
            description["en"] = (price_v1?.["en"] +"\n"+description_v1?.["en"]).replaceAll(/ + +- +|-/g,"")
            const newData = {...dataRoot,name, description}
            delete newData.price
            callbackUpdate(newData)
            return newData
        }
    },[]) 

    return [handleCheck]
}

