import produce from 'immer';
import * as Types from '../../constants/actionTypes';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: {},

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WH_HIGHLIGHT_REQUEST:
      state.isLoading = true;
      state.list = {};
      state.getListFailed = null;
      return;

    case Types.GET_WH_HIGHLIGHT_SUCCESS:
      state.isLoading = false;
      state.list = payload;
      return;

    case Types.GET_WH_HIGHLIGHT_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      return;

    case Types.CREATE_WH_HIGHLIGHT_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_WH_HIGHLIGHT_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_WH_HIGHLIGHT_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_WH_HIGHLIGHT_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_WH_HIGHLIGHT_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      for (const keyList in state.list) {
        for (const keyPayload in payload) {
          if(keyList===keyPayload){
            if(['v1','v2','v3','vSetting','v0'].includes(keyPayload)){
              state.list[keyList] = payload[keyPayload]
            }
          }
        }
      }
      return;

    case Types.UPDATE_WH_HIGHLIGHT_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_WH_HIGHLIGHT_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_WH_HIGHLIGHT_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_WH_HIGHLIGHT_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;
    case Types.SET_WH_HIGHLIGHT:
      state.list = payload
      // for (const keyList in state.list) {
      //   for (const keyPayload in payload) {
      //     if(keyList===keyPayload){
      //       if(['v1','v2','v3','vSetting','v0'].includes(keyPayload)){
      //         state.list[keyList] = [...payload[keyPayload]]
      //       }
      //     }
      //   }
      // }
      
      return;

    default:
      return;
  }
}, initState);
