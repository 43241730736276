import { GET_WH_REPORT_CUSTOMERS_REQUEST, GET_WH_REPORT_CUSTOMER_REQUEST, RESET_WH_REPORT_CUSTOMER_STATE } from '../../constants/actionTypes';

export const getWhReportCustomers = query => ({
  type: GET_WH_REPORT_CUSTOMERS_REQUEST,
  payload: query
});
export const getWhReportCustomer = query => ({
  type: GET_WH_REPORT_CUSTOMER_REQUEST,
  payload: query
});


export const resetWhReportCustomerState = () => ({
  type: RESET_WH_REPORT_CUSTOMER_STATE
});

// export const getWhReceiptVoucherGroups = () => ({
//   type: GET_WH_RECEIPT_VOUCHER_GROUPS_REQUEST
// });
