import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Menu, Modal, Select, Table, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import Text from 'antd/lib/typography/Text';
import { forIn, get, head, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { CONFIG_CAMPAIGN_CONDITION_VI, MAX_VALUE_CONFIG_CAMPAIGN, TYPE_ACTION_PERMISSIONS } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import {
  useResetActivityReferral,
  useActivityReferralPaging,
  useActivityReferralQueryParams,
  useActivityReferrals,
  useUpdateActivityReferralParams,
  useDeleteActivityReferral
} from '~/hooks/activityReferral';
import { Breadcrumb } from '../Common';
import ModalConfirm from '../Common/ModalConfirm';
import PermissionAction from '../Utils/PermissionAction';
import PermissionToolTip from '../Utils/PermissionToolTip';
import SkeletonTable from '../Utils/SkeletonTable';
import FormAddActivity from './Form/FormAddActivity';
const listOptionSearch = [
  {
    value: 'vi',
    label: 'Tên hoạt động'
  },
  {
    value: 'value',
    label: 'Mã hoạt động'
  },
];
export default function ActivityReferral() {
    // const [form] = Form.useForm()
  const [selectSearch, setSelectSearch] = useState(
    head(listOptionSearch).value
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [id, setId] = useState(null);
  const handleOpenModal = (id) => {
    setIsOpenModal(true);
    if (id) {
      setId(id);
    }
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setId(null);
  };
  // hook
  
  const [query] = useActivityReferralQueryParams();
  const [activityReferrals, isLoading] = useActivityReferrals(query);
  // console.log(activityReferrals,'activityReferrals');
  const paging = useActivityReferralPaging(query);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateActivityReferralParams(query,listOptionSearch);
    useResetActivityReferral();
  //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('');
  };
////////////////////////////////
// useEffect(() => {
//   const {sortPercentDiscount,sortPoint} = query
//   form.setFieldsValue({sortPercentDiscount:parseInt(sortPercentDiscount) || null,sortPoint:parseInt(sortPoint || 0)  || null})
// },[query])
useEffect(() => {
  let value = listOptionSearch?.find(e => query[e.value])
  setSelectSearch(get(value,'value',head(listOptionSearch).value))
  setKeyword(query[get(value,'value','')])
},[])
const handleOpenModalDelete = (id) => {
    setId(id)
    setIsOpenModalDelete(true)
  }
  const handleCloseModalDelete = () => {
    setId(null)
    setIsOpenModalDelete(false)
  }
  const handleDelete = () => {
    deleteActivity(id);
  }
  const [isSubmitLoading, deleteActivity] = useDeleteActivityReferral(handleCloseModalDelete);
////////////////////////////////
//   const options = useOptionsSelectAntd(TYPE_RANKING_REFERRAL_VI)
const onValuesChange = (value,allValues) => {
  // forIn(allValues,(values,key,obj) =>{obj[key] = null})
  // onParamChange({...allValues,...value})
}

  const columns = [
    {
      title: 'Tên hoạt động',
      dataIndex: 'vi',
      key: 'vi',
      render: (item, record, index) => item || ''
    },
    {
      title: 'Mã hoạt động',
      dataIndex: 'value',
      key: 'value',
      render: (item, record, index) => item || ''
    },
    {
      title: 'Kiểu điều kiện',
      dataIndex: 'typeCondition',
      key: 'typeCondition',
      render: (item, record, index) => CONFIG_CAMPAIGN_CONDITION_VI[item] || ''
    },
    {
      title: 'Điều kiện phụ',
      dataIndex: 'subCondition',
      key: 'subCondition',
      render: (item, record, index) => get(record,'subCondition',[]).length !== 0 && get(record,'subCondition',[])?.map(e => <Tag>{get(e,'desc','')}</Tag>)
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      width: '100px',
      key: 'action',
      render: (item, record, index) => (
        <Dropdown
          overlayStyle={{ width: 150 }}
          trigger={['click']}
          placement="bottomCenter"
          overlay={
            <Menu>
              <PermissionAction key={v4()} type={TYPE_ACTION_PERMISSIONS.UPDATE.value} handle={() => {handleOpenModal(get(record,'_id'))}} permission={POLICIES.UPDATE_REFERRAL}  />
              <PermissionAction key={v4()} type={TYPE_ACTION_PERMISSIONS.DELETE.value} handle={() => {handleOpenModalDelete(get(record,'_id'))}} permission={POLICIES.DELETE_REFERRAL} />
            </Menu>
          }
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
      )
    }
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Hoạt động cấu hình chiến dịch" />
        <div className="page-content__main">
          {/* <div className="page-content__left">
            <Form
             form={form}
             autoComplete="off"
             onFinish={() => {}}
             scrollToFirstError
             onValuesChange={onValuesChange}
             requiredMark={false}
             labelCol={{ sm: 24, md: 24, lg: 24 }}
           >
             <Form.Item  label='Sắp xếp Điểm' name='sortPoint'>
                <Select allowClear  options={[
                    {label:'Tăng dần',value : 1},
                    {label:'Giảm dần',value : -1},
                ]}/>
             </Form.Item>
             <Form.Item label='Sắp xếp chiết khấu' name='sortPercentDiscount'>
                <Select allowClear options={[
                    {label:'Tăng dần',value : 1},
                    {label:'Giảm dần',value : -1},
                ]}/>
             </Form.Item>
           </Form>
          </div> */}
          <div className="page-content__right" style={{ width: '100%' }}>
            <div
              className="page-wraper__header"
              style={{ justifyContent: 'space-between', display: 'flex' }}
            >
              <div>
                <Select
                  value={selectSearch}
                  style={{
                    width: 170
                  }}
                  onChange={handleChangeSelectSearch}
                  options={listOptionSearch}
                />
                
                  <Search
                    style={{ width: 300 }}
                    placeholder={`Tìm ${
                      get(listOptionSearch.find((e) => e.value === selectSearch),'label','')
                    }`}
                    enterButton
                    allowClear
                    onSearch={(value) => {
                      onParamChange({ [selectSearch]: value?.trim() });
                    }}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                  />
                
              </div>
              <PermissionToolTip permission={POLICIES.WRITE_REFERRAL} textButton={"Thêm Hoạt động"} textPermission={"Bạn không có quyền tạo"} >
              <Button onClick={() => handleOpenModal()} type="primary">
                Thêm Hoạt động
              </Button>
              </PermissionToolTip>
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={activityReferrals}
                size="middle"
                onChange={({current}) => onParamChange({page : current})}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={isOpenModal}
        closable={false}
        footer={null}
        onCancel={handleCloseModal}
        width={600}
      >
        <FormAddActivity id={id} handleCloseAddActivity={handleCloseModal} />
      </Modal>
      <ModalConfirm
        text="Xác nhận Xoá"
        textOk="Xác nhận"
        type="delete"
        loading={isSubmitLoading}
        visible={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        handleOk={handleDelete}
      />
    </div>
  );
}
