import produce from 'immer';
import { RATE_CONFIG_REDUCER_MAP } from '~/constants/defaultValue';
import getPaging from '~/utils/getPaging';
import * as Types from '../../constants/actionTypes';

const initState = {
  isLoading: false,

  isSubmitLoading: false,
  createFundSuccess:null,
  createFundFailed:null,

  updateFundSuccess:null,
  updateFundFailed:null,

  reportSystems: [],
  getReportSystemFailed: null,

  allSale : null,
  isLoadingAllSale : null,
  getAllSaleReportSystemFailed:null,
  // reportSystemsPayment: [],
  // getReportSystemFailedPayment: null,
  
  paging: null,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    // CERTIFICATE
    case Types.GET_REPORT_SYSTEMS_REQUEST:
      state.isLoading = true;
      state.reportSystems = [];
      state.getReportSystemFailed = null;
      return;

    case Types.GET_REPORT_SYSTEMS_SUCCESS:
      state.isLoading = false;
      state.reportSystems = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_REPORT_SYSTEMS_FAILED:
      state.isLoading = false;
      state.getReportSystemFailed = payload;
      return;

    case Types.GET_ALL_SALE_REPORT_SYSTEMS_REQUEST:
      state.isLoadingAllSale = true;
      state.allSale = [];
      state.getAllSaleReportSystemFailed = null;
      return;

    case Types.GET_ALL_SALE_REPORT_SYSTEMS_SUCCESS:
      state.isLoadingAllSale = false;
      state.allSale = payload;
      return;

    case Types.GET_ALL_SALE_REPORT_SYSTEMS_FAILED:
      state.isLoadingAllSale = false;
      state.getAllSaleReportSystemFailed = payload;
      return;
    case Types.CREATE_REPORT_SYSTEMS_FUND_REQUEST:
      state.isSubmitLoading = true;
      state.createFundSuccess = null;
      state.createFundFailed = null;
      return;

    case Types.CREATE_REPORT_SYSTEMS_FUND_SUCCESS:
      state.isSubmitLoading = true;
      state.createFundSuccess = payload;
      return;

    case Types.CREATE_REPORT_SYSTEMS_FUND_FAILED:
      state.isSubmitLoading = true;
      state.createFundFailed = payload;
      return;
    case Types.UPDATE_REPORT_SYSTEMS_FUND_REQUEST:
      state.isSubmitLoading = true;
      state.updateFundSuccess = null;
      state.updateFundFailed = null;
      return;

    case Types.UPDATE_REPORT_SYSTEMS_FUND_SUCCESS:
      state.isSubmitLoading = false;
      state.updateFundSuccess = payload;
      return;

    case Types.UPDATE_REPORT_SYSTEMS_FUND_FAILED:
      state.isSubmitLoading = false;
      state.updateFundFailed = payload;
      return;
    // case Types.GET_REPORT_SYSTEMS_PAYMENT_REQUEST:
    //   state.isLoading = true;
    //   state.reportSystemsPayment = [];
    //   state.getReportSystemFailedPayment = null;
    //   return;

    // case Types.GET_REPORT_SYSTEMS_PAYMENT_SUCCESS:
    //   state.isLoading = false;
    //   state.reportSystemsPayment = payload.docs;
    //   state.paging = getPaging(payload);
    //   return;

    // case Types.GET_REPORT_SYSTEMS_PAYMENT_FAILED:
    //   state.isLoading = false;
    //   state.getReportSystemFailedPayment = payload;
    //   return;

    case Types.RESET_REPORT_SYSTEM_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
