import { Col, Row, Skeleton, Tag } from 'antd'
import { get } from 'lodash';
import React from 'react'
import { v4 } from 'uuid';
import { useUserWorkspace } from '~/hooks';
import { useSpecialityWorkSpace } from '~/hooks/trainingCoursePartner';

export default function InfoPartner({profile}) {
    const [speciality,isLoading] = useSpecialityWorkSpace(get(profile, 'speciality'));
  return (
    <>
        <Row gutter={16} className="rowInfo">
        <Col span={{ xs: 8, sm: 8, md: 8, lg: 4 }}>Nhóm nhân sự : </Col>
        <Col span={{ xs: 16, sm: 16, md: 16, lg: 20 }}>
          <h6 style={{margin : 0}}>{get(profile, 'employeeGroup[0].name.vi')}</h6>
        </Col>
      </Row>
      <Row gutter={16} className="rowInfo">
      <Col span={{ xs: 8, sm: 8, md: 8, lg: 4 }}>Chuyên môn: </Col>
      <Col span={{ xs: 16, sm: 16, md: 16, lg: 20 }}>
      {isLoading ? <>
        <Skeleton.Button  />
      </> : speciality?.map((e) => (
          <Tag key={v4()} color="blue">
            {get(e, 'name.vi')}
          </Tag>
        ))}
        
      </Col>
    </Row>
      <Row gutter={16} className="rowInfo">
        <Col span={{ xs: 8, sm: 8, md: 8, lg: 4 }}>Hình thức làm việc</Col>
        <Col span={{ xs: 16, sm: 16, md: 16, lg: 20 }}>
          <h6 style={{margin : 0,textTransform : 'capitalize'}}>
            {get(profile, 'wayWorking', '(chưa cập nhật Hình thức làm việc)')}
          </h6>
        </Col>
      </Row>
    </>
  )
}
