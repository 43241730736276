import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getOverViewReport() {
  try {
    const data = yield call(Api.dashboard.getOverViewReport);
    yield put({ type: Types.GET_OVER_VIEW_REPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_OVER_VIEW_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getYearlyReport() {
  try {
    const data = yield call(Api.dashboard.getYearlyReport);
    yield put({
      type: Types.GET_YEARLY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_YEARLY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getQuarterlyReport() {
  try {
    const data = yield call(Api.dashboard.getQuarterlyReport);
    yield put({
      type: Types.GET_QUARTERLY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_QUARTERLY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getMonthlyReport() {
  try {
    const data = yield call(Api.dashboard.getMonthlyReport);
    yield put({
      type: Types.GET_MONTHLY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MONTHLY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getDailyReport() {
  try {
    const data = yield call(Api.dashboard.getDailyReport);
    yield put({
      type: Types.GET_DAILY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_DAILY_REPORT_FAILED,
      payload: error.message
    });
  }
}
function* getMostService() {
  try {
    const data = yield call(Api.dashboard.getMostService);
    yield put({
      type: Types.GET_MOST_SERVICE_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MOST_SERVICE_FAILED,
      payload: error.message
    });
  }
}
function* getMostRevenueService() {
  try {
    const data = yield call(Api.dashboard.getMostRevenueService);
    yield put({
      type: Types.GET_MOST_REVENUE_SERVICE_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MOST_REVENUE_SERVICE_FAILED,
      payload: error.message
    });
  }
}
function* getMostPartnerAppointment() {
  try {
    const data = yield call(Api.dashboard.getMostPartnerAppointment);
    yield put({
      type: Types.GET_MOST_PARTNER_APPOINTMENT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MOST_PARTNER_APPOINTMENT_FAILED,
      payload: error.message
    });
  }
}
function* getMostCustomerAppointment() {
  try {
    const data = yield call(Api.dashboard.getMostCustomerAppointment);
    yield put({
      type: Types.GET_MOST_CUSTOMER_APPOINTMENT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MOST_CUSTOMER_APPOINTMENT_FAILED,
      payload: error.message
    });
  }
}

function* getOverviewToday() {
  try {
    const data = yield call(Api.dashboard.getOverviewToday);
    yield put({
      type: Types.GET_OVERVIEW_TODAY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_OVERVIEW_TODAY_FAILED,
      payload: error.message
    });
  }
}

function* getOverviewDaily() {
  try {
    const data = yield call(Api.dashboard.getOverviewDaily);
    yield put({
      type: Types.GET_OVERVIEW_DAILY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_OVERVIEW_DAILY_FAILED,
      payload: error.message
    });
  }
}

function* getOverviewMonthly() {
  try {
    const data = yield call(Api.dashboard.getOverviewMonthly);
    yield put({
      type: Types.GET_OVERVIEW_MONTHLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_OVERVIEW_MONTHLY_FAILED,
      payload: error.message
    });
  }
}

function* getOverviewQuarterly() {
  try {
    const data = yield call(Api.dashboard.getOverviewQuarterly);
    yield put({
      type: Types.GET_OVERVIEW_QUARTERLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_OVERVIEW_QUARTERLY_FAILED,
      payload: error.message
    });
  }
}

function* getOverviewYearly() {
  try {
    const data = yield call(Api.dashboard.getOverviewYearly);
    yield put({
      type: Types.GET_OVERVIEW_YEARLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_OVERVIEW_YEARLY_FAILED,
      payload: error.message
    });
  }
}

export default function* coupon() {
  yield takeLatest(Types.GET_OVER_VIEW_REPORT_REQUEST, getOverViewReport);
  yield takeLatest(Types.GET_YEARLY_REPORT_REQUEST, getYearlyReport);
  yield takeLatest(Types.GET_QUARTERLY_REPORT_REQUEST, getQuarterlyReport);
  yield takeLatest(Types.GET_MONTHLY_REPORT_REQUEST, getMonthlyReport);
  yield takeLatest(Types.GET_DAILY_REPORT_REQUEST, getDailyReport);
  yield takeLatest(Types.GET_MOST_SERVICE_REQUEST, getMostService);
  yield takeLatest(Types.GET_MOST_REVENUE_SERVICE_REQUEST, getMostRevenueService);
  yield takeLatest(Types.GET_MOST_PARTNER_APPOINTMENT_REQUEST, getMostPartnerAppointment);
  yield takeLatest(Types.GET_MOST_CUSTOMER_APPOINTMENT_REQUEST, getMostCustomerAppointment);

  yield takeLatest(Types.GET_OVERVIEW_TODAY_REQUEST, getOverviewToday);
  yield takeLatest(Types.GET_OVERVIEW_DAILY_REQUEST, getOverviewDaily);
  yield takeLatest(Types.GET_OVERVIEW_MONTHLY_REQUEST, getOverviewMonthly);
  yield takeLatest(Types.GET_OVERVIEW_QUARTERLY_REQUEST, getOverviewQuarterly);
  yield takeLatest(Types.GET_OVERVIEW_YEARLY_REQUEST, getOverviewYearly);

}
