import { Button, Tooltip } from 'antd'
import React from 'react'

export default function ToolTipButton({text,titleToolTip,style,type,children}) {
  return (
    <Tooltip title={titleToolTip}>
        {type === 'button' && <Button style={{...style}} disabled>{text}</Button>}
        {type === 'text' && children}
    </Tooltip>
  )
}
