import { Button, Form, Input, Popover} from 'antd';
import React, { useCallback, useState } from 'react'
import ColorPicker from '@vtaits/react-color-picker';
import { useGetStatusConfigById,useUpdateStatusConfig, useCreateStatusConfig, useDeleteStatusConfig } from '~/hooks/statusConfig'
import { Row } from 'antd';
export default function StatusForm({ handleCloseForm, form,id }) {
    const [selectedColor, setSelectedColor] = useState('');
    const [, createStatusConfig] = useCreateStatusConfig();
    const [, updateStatusConfig] = useUpdateStatusConfig();
    const [statusConfigById] = useGetStatusConfigById(id);
    const onFinish = (values) => {
      if (id) {
        updateStatusConfig({ ...values, id });
      } else {
        createStatusConfig(values);
      }
      handleCloseForm();
    };
  return (
    <div>
                <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 500 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item label="Tên" name="name">
            <Input />
          </Form.Item>
          <Popover
          style={{borderRadius: 20}}
          destroyTooltipOnHide content={<ColorPickerModule
              initval={form.getFieldValue('background')}
              onChange={(e)=>{
              form.setFieldsValue({background: e})
            }}/>} 
            title="Color" 
            trigger="click">
              <Form.Item shouldUpdate >
                {()=><Form.Item label='Màu nền' name='background'>
                    <Input readOnly addonBefore={<div style={{backgroundColor: form.getFieldValue('background')||'blue', width: 30, height: 30,marginLeft: 26,border: '1px solid #c1bdbde8',cursor:'pointer'}} ></div>}/>
                </Form.Item>}                
              </Form.Item>
            </Popover>
          <Popover destroyTooltipOnHide content={<ColorPickerModule
              initval={form.getFieldValue('color')}
              onChange={(e)=>{
              form.setFieldsValue({color: e})
            }}/>} 
            title="Color" 
            trigger="click">
              <Form.Item shouldUpdate >
                {()=><Form.Item label='Màu chữ' name='color'>
                    <Input readOnly addonBefore={<div style={{backgroundColor: form.getFieldValue('color')||'black', width: 30, height: 30,marginLeft: 26,border: '1px solid #c1bdbde8',cursor:'pointer'}} ></div>}/>
                </Form.Item>}                
              </Form.Item>
            </Popover>
          <Form.Item>
            <Row align="end" >
                <Button htmlType="submit" type="primary">
                  {id ? 'Cập nhật' : 'Thêm mới'}
                </Button>
            </Row>
          </Form.Item>
        </Form>
    </div>
  )
}

export const ColorPickerModule=({onChange,initval,disabled})=>{
  const [color,setColor]=useState({picker:  (String(initval).length>7? initval?.slice(0,-2) :initval) ??'#ffffff',alpha:initval?.slice(-2)??'ff'})

 const onChangevalue = useCallback((value)=>{
   let _color = {...color,...value} 
       setColor((curr)=>({...curr,...value}))
     onChange(`${_color.picker}${_color.alpha}`)
 },[onChange,color,setColor]) 
 return <>
   <ColorPicker 
     onChange={(e)=>{onChangevalue({'picker':e})}} 
     saturationWidth={120} 
     saturationHeight={120} 
     hueWidth={20}
     defaultValue={initval}
   />
   </>
 }
