import { Breadcrumb } from "antd";
import FormSettingTutorialJob from "./FormSettingTutorialJob";

const SettingTutorialJob = ({ }) => {

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Thiếp lập hướng dẫn sử dụng ứng tuyển" />
        <FormSettingTutorialJob />
      </div>
    </div>
  );
};

export default SettingTutorialJob;
