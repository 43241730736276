import { concat, get, head, isArray, keys } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ACTION_LIST_PRODUCT, TYPE_WAREHOUSE, WH_APPOINTMENT_STATUS } from '~/constants/defaultValue';
import { CONFIRM_VOUCHER_PRODUCT_STATUS } from '~/constants/confirmVoucherWarehouse';
import {
  useAddDataLocally, useFailed,
  useFetchByParam,
  useQueryParams, useRemoveDataLocally, useResetState, useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  addWhAppointmentsLocally, checkInWhAppointment,
  checkOutWhAppointment, createWhAppointment, deleteWhAppointment,
  getWhAppointment, getWhAppointments, removeWhAppointmentsLocally, resetListProductWhAppointmentState, resetWhAppointmentState, updateListProductAppointment, updateWhAppointment
} from '~/redux/action';
import Error from './Error';
import moment from 'moment';
const getSelector = key => state => state.whAppointment[key];

const loadingSelector = getSelector('isLoading');
const whAppointmentsSelector = getSelector('whAppointments');
const getWhAppointmentsFailedSelector = getSelector('getWhAppointmentsFailed');

const isGetWhAppointmentLoadingSelector = getSelector('isGetWhAppointmentLoading');
const whAppointmentSelector = getSelector('whAppointment');
const getWhAppointmentFailedSelector = getSelector('getWhAppointmentFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const updateListProductSuccessSelector = getSelector('updateListProductSuccess');
const updateListProductFailedSelector = getSelector('updateListProductFailed');
const isSubmitLoadingListProductSelector = getSelector('loadingUpdateListproduct');

const pagingSelector = getSelector('paging');

export const useWhAppointmentPaging = () => useSelector(pagingSelector);

export const useWhAppointmentQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteWhAppointmentSuccess = useSelector(deleteSuccessSelector);
  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteWhAppointmentSuccess]);
};

export const useWhAppointments = query => {
  return useFetchByParam({
    action: getWhAppointments,
    loadingSelector,
    dataSelector: whAppointmentsSelector,
    failedSelector: getWhAppointmentsFailedSelector,
    param: query
  });
};

export const useCreateWhAppointment = () => {
  const history = useHistory();
  const callback = () => history.push('/wh-appointment');

  useSuccess(createSuccessSelector, 'Tạo mới buổi hẹn thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới buổi hẹn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createWhAppointment
  });
};

export const useAddWhAppointmentsLocally = () => {
  return useAddDataLocally({
    action: addWhAppointmentsLocally,
  })
};

export const useRemoveWhAppointmentsLocally = () => {
  return useRemoveDataLocally({
    action: removeWhAppointmentsLocally,
  })
};

export const useUpdateWhAppointment = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật buổi hẹn thành công');
  useFailed(updateFailedSelector, 'Cập nhật buổi hẹn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateWhAppointment
  });
};

export const useCheckInWhAppointment = () => {
  useSuccess(updateSuccessSelector, 'Check in buổi hẹn thành công');
  useFailed(updateFailedSelector, 'Check in buổi hẹn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: checkInWhAppointment,
  });
};

export const useCheckOutWhAppointment = () => {
  useSuccess(updateSuccessSelector, 'Check out buổi hẹn thành công');
  useFailed(updateFailedSelector, 'Check out buổi hẹn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: checkOutWhAppointment,
  });
};

export const useDeleteWhAppointment = () => {
  useSuccess(deleteSuccessSelector, 'Xoá buổi hẹn thành công');
  useFailed(deleteFailedSelector, 'Xoá buổi hẹn thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteWhAppointment
  });
};

export const useWhAppointment = id => {
  return useFetchByParam({
    action: getWhAppointment,
    loadingSelector: isGetWhAppointmentLoadingSelector,
    dataSelector: whAppointmentSelector,
    failedSelector: getWhAppointmentFailedSelector,
    param: id
  });
};

export const useInitWhAppointment = whAppointment => {
  return useMemo(() => {
    if (!whAppointment) {
      return {
      };
    }

    const initValues = {
      ...whAppointment,
    };

    return initValues;
  }, [whAppointment]);
};

export const useResetWhAppointment = () => {
  useResetState(resetWhAppointmentState);
};
export const useUpdateListProductAppointment = callback => {
  useSuccess(updateListProductSuccessSelector, 'Cập nhật Danh sách thiết bị thành công', callback);
  useFailed(updateListProductFailedSelector);
  return useSubmit({
    loadingSelector: isSubmitLoadingListProductSelector,
    action: updateListProductAppointment
  });
};

export const useResetUpdateListWhAppointment = () => {
  useResetState(resetListProductWhAppointmentState);
};
/**
 * @param {Array} data Data of list Product
 * @return {Object} 
 */
export const SplitDataWarehouse = (data) => {
  // Check data is Error
  if (!data || !isArray(data) || keys(data).length === 0 || keys(head(data)).length === 0) return {
    dataMaterial: [],
    dataEquipment: [],
  }
  let isHaveProductBlocked = false;
  const dataSplit = data?.reduce((sum, curr) => {
    let isBlock = false;
    switch (get(curr, 'medicalEquipmentTypeId.type')) {
      case TYPE_WAREHOUSE.CLINIC:
        // check is group Equipment , or medicalEquipmentType  of Product is Blocked 
        isBlock = get(curr, 'medicalEquipmentTypeId.action') === ACTION_LIST_PRODUCT.INACTIVE;

        return {
          ...sum, dataMaterial: concat(sum.dataMaterial,
            {
              ...curr, isBlock,
            })
        }
      case TYPE_WAREHOUSE.WORLDHEALTH:
        // check is group Equipment , or medicalEquipmentType  of Product is Blocked 
        isBlock = get(curr, 'medicalEquipmentTypeId.action') === ACTION_LIST_PRODUCT.INACTIVE
          || get(curr, 'groupEquipmentId.action') === ACTION_LIST_PRODUCT.INACTIVE;
        if (isBlock) {
          isHaveProductBlocked = true;
        }
        return { ...sum, dataEquipment: concat(sum.dataEquipment, { ...curr, isBlock }) }

      default:
        break;
    }
  }, {
    dataMaterial: [],
    dataEquipment: [],
  });
  return { ...dataSplit, isHaveProductBlocked }
}
/**
 * @enum {string}
 */
const typeEnum = {
  WORLDHEALTH: "WORLDHEALTH",
  CLINIC: "CLINIC",
}

// /**
//  * 
//  * @param {Object} whAppointment 
//  * @param {typeEnum} type  Type Medical Equipment To Get Data [enum:["WORLDHEALTH","CLINIC"]]
//  * @returns {Array} Get Group product Init by type
//  */
// export const getDataGroupInit = (whAppointment, type = TYPE_WAREHOUSE.WORLDHEALTH) => {
//   // Find Package use 
//   const packageUsed = get(whAppointment, 'whService.packages', [])?.find(item => get(item, 'whPackageLevelId') === get(whAppointment, 'whPackageLevelId'))
//   // If not send type will return all Group product Init
//   if (!type) return get(packageUsed, 'groupProductInits', [])
//   return get(packageUsed, 'groupProductInits', [])?.filter(item => 
//     !get(item,'medicalEquipmentTypeId')
//     || get(item, 'medicalEquipmentTypeId.type') === type)
// }
export const getDataGroupInit = (whAppointment) => {
  return get(whAppointment, 'dataGroupInits',[]);
}


/**
 * 
 * @param {Object} whAppointment 
 * @returns {Array} Get getDataGroupInitMaterial 
 */
export const getDataGroupInitMaterial = (whAppointment) => {
  // Find Package use 
  const packageUsed = get(whAppointment, 'whService.packages', [])?.find(item => get(item, 'whPackageLevelId') === get(whAppointment, 'whPackageLevelId'));
  return get(packageUsed, 'materialInits', []);
}
/**
 * 
 * @param {Array} listProduct 
 * @returns {Number}
 */
export const calculateTotalValueDepreciation = (listProduct) => {
  // calculate total value by list product
  if (!listProduct || listProduct?.length === 0) return 0
  const totalPrice = listProduct?.reduce((sum, arr) => {
    switch (get(arr, 'medicalEquipmentTypeId.type')) {
      case TYPE_WAREHOUSE.CLINIC:
        return sum + get(arr, 'actualQuantity', 0) * get(arr, 'ref.price', 0)
      case TYPE_WAREHOUSE.WORLDHEALTH:
        return sum + get(arr, 'actualQuantity', 0) * get(arr, 'ref.valueDepreciation', 0)

      default:
        break;
    }
  }, 0)
  return totalPrice
}

export const getStatusConfirmProduct = (listProduct, productId) => {
  const productCheck = find(listProduct, (item) => get(item, 'productId') === productId);
  return get(productCheck, 'statusConfirm')
}


/**
 *  CONTROLLER GROUP INIT 
 * @param {Array} data Data Init GroupInit 
 * @param {String} statusWhAppointment status of the appointment
 * @param {Array} preBooking Data of preBooking 
 * @returns {Object}
 */
export const SplitGroupInitWarehouse = (data, statusWhAppointment, preBooking) => {
  const dataSplit = data?.reduce((sum, curr) => {
    const isBlock = get(curr, 'medicalEquipmentTypeId.action') === ACTION_LIST_PRODUCT.INACTIVE
      || get(curr, 'groupEquipmentId.action') === ACTION_LIST_PRODUCT.INACTIVE
    const findPreBooking = preBooking?.find(item => get(curr, 'groupEquipmentId._id') === get(item, 'groupEquipmentId'))
    return {
      ...sum,
      groupInitEquipment: concat(sum.groupInitEquipment,
        {
          ...curr,
          isBlock,
          statusWhAppointment,
          preBooking: findPreBooking,
          quantityGroupInit : get(curr,'quantity'), // Rename to resolve conflicts with listProductInit
        })
    }
  }, {
    groupInitEquipment: [],
  });
  return dataSplit
}



/**
 * 
 * @param {Array} groupInitsEquipment 
 * @returns {Object}
 */
export const checkTypeGroupToGetMessage = ({ groupInitsEquipment = [],groupInitMaterial=[] }) => {
  let haveClinic = false;
  let haveWorldhealth = false;
  let isHaveGroupInit = false;
  let message = "Xem chi tiết ";

  if (groupInitsEquipment?.length) {
    haveWorldhealth = true
    isHaveGroupInit = true
  }
  if (groupInitMaterial?.length) {
    haveClinic = true
    isHaveGroupInit = true
  }

  if (haveWorldhealth && haveClinic) {
    message += "CCDC/NVL"
  }
  else {
    if (haveWorldhealth) message += "CCDC "
    if (haveClinic) message += "NVL "
  }
  return {
    haveClinic,
    haveWorldhealth,
    isHaveGroupInit,
    message,
  }
}

export const removeBatchEmpty = (batchs,quantityInput = 0) => {
  return batchs?.filter(batch => get(batch, 'availableTotal', 0) >= quantityInput && moment(get(batch,'expirationDate')).isAfter(moment()))
}



export const getQuantityConfirm = (listProduct) => {
  const quantity = listProduct?.reduce((sum, curr) => {
    if ([CONFIRM_VOUCHER_PRODUCT_STATUS.COMPLETED, CONFIRM_VOUCHER_PRODUCT_STATUS.CONFIRM].includes(get(curr, 'statusConfirm'))) {
      return sum + 1;
    }
    return sum
  }, 0);
  return quantity
}

export const useModalConfirmVoucher = () => {
  const [visibleConfirmVoucher, setVisibleConfirmVoucher] = useState(false);
  const [idConfirmVoucher, setIdConfirmVoucher] = useState(null);
  const onOpenConfirmVoucher = (id) => {
    if (id) {
      setIdConfirmVoucher(id)
    };
    setVisibleConfirmVoucher(true)
  };
  const onCloseConfirmVoucher = () => {
    setVisibleConfirmVoucher(false);
    setIdConfirmVoucher(null);
  };
  return {
    visibleConfirmVoucher,
    idConfirmVoucher,
    onOpenConfirmVoucher,
    onCloseConfirmVoucher,
  }
}

export const infoDisabledTitleEquipmentAssigned = (record) => {
  let error = { status: false, msg: "" };
  switch (true) {
    case get(record, 'isDelivering'):
      error = new Error.isDelivering()
      break;
    case get(record, 'isBlock'):
      error = new Error.GroupInitIsBlock()
      break;
    case get(record, 'statusWhAppointment') === WH_APPOINTMENT_STATUS.COMPLETED:
      error = new Error.WhAppointmentCompleted();
      break;
    case !!get(record, 'statusConfirm'):
      error = new Error.haveConfirmVoucher();
      break;
    default:
      break;
  }
  return error
}

export const infoDisabledRemoveEquipment = (record) => {
  let error = { status: false, msg: "" };
  switch (true) {
    case get(record, 'isDelivering'):
      error = new Error.isDelivering();
      break;
    case get(record, 'statusWhAppointment') === WH_APPOINTMENT_STATUS.COMPLETED && get(record, 'isDelivering'):
      error = new Error.WhAppointmentCompletedAndDelivery();
      break;
    case !!get(record, 'statusConfirm'):
      error = new Error.haveConfirmVoucher();
      break;
    default:
      break;
  }
  return error
}

export const infoDisabledRefundEquipmentAction = (record) => {
  let error = { status: false, msg: "" };
  switch (true) {
    case get(record, 'statusAppointment') === WH_APPOINTMENT_STATUS.COMPLETED:
      error = new Error.WhAppointmentCompleted();
      break;
    default:
      break;
  }
  return error
}

export const infoDisabledAddMedical = (record) => {
  let error = { status: false, msg: "" };
  switch (true) {
    case get(record, 'groupInitMaterial')?.length === 0:
      error = new Error.NotHaveGroupMedical();
      break;
    default:
      break;
  }
  return error
}
export const infoDisabledBatch = (record) => {
  let error = { status: false, msg: "" };
  switch (true) {
    default:
      break;
  }
  return error
}

export const infoDisabledSaveButton = (record) => {
  let error = { status: false, msg: "" };
  switch (true) {
    case record.statusAppointment === WH_APPOINTMENT_STATUS.COMPLETED:
      // && (record?.listProductUse?.length === record?.listProduct?.length): // Check if All product in list Assign is Delivered
      error = new Error.WhAppointmentCompleted();
      break;
    default:
      break;
  }
  return error
}
