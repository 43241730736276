import requester from './requester';

const medicalEquipment = {
  getAll: (query) => requester.get(`medical-device-inventory`, query),
  getById: (id) => requester.get(`medical-device-inventory/${id}?raw=true`),
  create: (device) => requester.post('medical-device-inventory', device),
  update: (payload) => requester.put(`medical-device-inventory/${payload.id}`, payload),
  delete: (id) => requester.delete(`medical-device-inventory/${id}`),
};

export default medicalEquipment;
