import produce from 'immer';
import { clone, get, sortBy, uniqBy } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null

};
export default produce((state, { type, payload }) => {
  switch (type) {

    //board
    case Types.GET_BOARD_SPRINT_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;
    case Types.GET_BOARD_SPRINT_SUCCESS:
      state.isLoading = false;
      state.list = payload;
      // state.paging = getPaging(payload);
      return;
    case Types.GET_BOARD_SPRINT_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_BOARD_SPRINT_BY_ID_REQUEST:
      state.isGetByIdLoading = true;
      state.getByIdFailed = null;
      return;
    case Types.GET_BOARD_SPRINT_BY_ID_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;
    case Types.GET_BOARD_SPRINT_BY_ID_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      state.byId = null;
      return;
      

      //Create
      case Types.CREATE_BOARD_SPRINT_REQUEST:
        state.isSubmitLoading = true;
        state.createFailed = null;
        return;
      case Types.CREATE_BOARD_SPRINT_SUCCESS:
        state.isSubmitLoading = false;
        state.createSuccess = payload;

        state.list=[...state.list,payload]
        state.createFailed = null;
        return;
      case Types.CREATE_BOARD_SPRINT_FAILED:
        state.isSubmitLoading = false;
        state.createFailed = payload;
        state.createSuccess = null;
        return;


        case Types.UPDATE_BOARD_SPRINT_REQUEST:
          state.isSubmitLoading = true;
          state.updateFailed = null;
          return;
        case Types.UPDATE_BOARD_SPRINT_SUCCESS:
          state.isSubmitLoading = false;
          state.updateSuccess = payload;
          var findIndexItemUpdated= [...state.list].findIndex((sprint)=>sprint._id===payload._id)
          state.list[findIndexItemUpdated]= {...state.list[findIndexItemUpdated],name:payload.name,note:payload.note}
          state.updateFailed = null;
          return;
        case Types.UPDATE_BOARD_SPRINT_FAILED:
          state.isSubmitLoading = false;
          state.updateFailed = payload;
          state.updateSuccess = null;
          return;
        case Types.DELETE_BOARD_SPRINT_REQUEST:
          state.isSubmitLoading = true;
          state.deleteFailed = null;
          return;
        case Types.DELETE_BOARD_SPRINT_SUCCESS:
          state.isSubmitLoading = false;
          state.deleteSuccess = payload;
          state.list= state.list.filter(({_id})=>_id!==payload._id)
          state.deleteFailed = null;
          return;
        case Types.DELETE_BOARD_SPRINT_FAILED:
          state.isSubmitLoading = false;
          state.deleteFailed = payload;
          state.deleteSuccess = null;
          return;

   
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
