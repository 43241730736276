import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
// Import Routes all
import { userRoutes, whPartnerRoutes, authRoutes } from './routes/allRoutes';

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';

// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import HorizontalLayout from './components/HorizontalLayout/';
import WorkspaceLayout from './components/WorkspaceLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import { setupAxios, setAxiosToken, setAxiosCompanyId,setAxiosPartnerHost } from './api/requester';
// Import scss
import './assets/scss/theme.scss';

import 'antd/dist/antd.css';
import 'toastr/build/toastr.min.css';
import './app.scss';

import { useMatchNavbarPolicy, useUser } from './hooks';
import './firebase';
import NavbarIcon from './components/HorizontalLayout/NavbarIcon';

const packageJson = require('../package.json');

const App = (props) => {
  const [branchId, token] = useUser();
  setupAxios();
  setAxiosPartnerHost();
  setAxiosToken(token);
  setAxiosCompanyId(branchId);

  useMatchNavbarPolicy()

  function getLayout() {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  console.log("Hello World");
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              {...route}
              layout={NonAuthLayout}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {whPartnerRoutes.map((route, idx) => (
            <Authmiddleware
              {...route}
              layout={WorkspaceLayout}
              key={idx}
              isAuthProtected={true}
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              {...route}
              layout={Layout}
              key={idx}
              isAuthProtected={true}
            />
          ))}
          
        </Switch>
        
        <NavbarIcon />

        <div
          style={{
            position: "fixed",
            bottom: "16px",
            right:0,
            width:'max-content'
          }}
          >
          <p
            style={{
              textAlign: "right",
              marginRight: "16px",
              color: "rgba(0, 0, 0, 0.1)"
            }}
          >
            Version: {packageJson.version}
          </p>
        </div>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    layout: state.layout
  };
};

export default connect(mapStateToProps, null)(App);
