import React, { useState, useEffect, useMemo } from 'react';

import moment from 'moment';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Tabs,
  Tooltip
} from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PlusCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { get, head, mapValues, pickBy, sortBy, toPairs } from 'lodash';

import {
  useApproveWhPartner,
  useCreateWhPartner,
  useInitWhPartner,
  useResetWhPartner,
  useUpdateTipsWhPartner,
  useUpdateWhPartner,
  useWhPartner
} from '~/hooks/whPartner';
import {
  useDegrees,
  useExperiences,
  usePositions,
  useRateConfigQueryParams
} from '~/hooks/rateConfig';
import { autoCreateUsername, useProfile } from '~/hooks';
import { useSpecialities } from '~/hooks/speciality';
import { useStaffGroups } from '~/hooks/staffGroup';
import { useWhPackageLevels } from '~/hooks/whPackageLevel';
import { useMatchPolicy, useUser } from '~/hooks/user';

import UploadImage from '~/components/Utils/UploadImage';
import {
  Breadcrumb,
  GeoMap,
  GeoSuggest,
  GeoTreeSelect
} from '~/components/Common';
import {
  COMPONENT_MODES,
  INSTANCE_STATUSES,
  KIND_WH_PARTNER_VI,
  LANGUAGE,
  MAX_PAGINATION_LIMIT,
  RELATIVE_POSITION
} from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { filterAcrossAccents, useFormItemMargin, useOptionsSelectAntd } from '~/hooks/utils';

import AssignedServicesTable from '../AssignedServicesTable';
import WhPartnerCertificateEdit from './WhPartnerCertificateEdit';
import WorkingDaysTable from '../WorkingDaysTable';
import api from '~/api';
import { toJSON } from '../parser';

import Happy from '~/components/Icon/Happy';
import { WH_RATE_VALUE, WH_RATE } from '~/constants/bill';
import Love from '~/components/Icon/Love';
import Like from '~/components/Icon/Like';
import Sad from '~/components/Icon/Sad';
import Angry from '~/components/Icon/Angry';

import './index.scss';
import RowRating from './RowRating';
import Tips from './Tips';
import TrainingCoursePartnerForm from './TrainingCoursePartnerForm';
import Rating from '~/components/Workspace/Rating';
import LinkReferralPartner from '~/components/Workspace/WhPartner/LinkReferralPartner';

import toastr from 'toastr'
import POLICIES from '~/constants/policy';
import { removeAccents } from '~/utils/helper';
import DebounceSelectHereMap from '~/components/Common/HereMapSuggest';
import HereMap from '~/components/Common/HereMap';
const FormItem = Form.Item;
const { Option } = Select;

const mainRowGutter = 24;
const mainColLayout = {
  md: { span: 24 },
  lg: { span: 12 },
  xl: { span: 10 }
};

const verticalLayout = {
  wrapperCol: { sm: 24, md: 12 },
  labelCol: { sm: 24, md: 5, lg: 8 }
};
const { TabPane } = Tabs;

const WhPartnerForm = () => {
  useResetWhPartner();
  const canReadWhTips = useMatchPolicy(POLICIES.READ_WHTIPS)
  const params = useParams()
  const [form] = Form.useForm();
  const history = useHistory()
  const [sessionForm] = Form.useForm();
  const [rating, setRating] = useState([]);
  const [language, setLanguage] = useState(LANGUAGE.VI);
  const [avatar, setAvatar] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dayIdx, setDayIdx] = useState();
  const [sessionIdx, setSessionIdx] = useState();
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 10.7844655,
    lng: 106.6945057
  });
  const [activeKey, setActiveKey] = useState('1');
  const [defaultValue, setDefaultValue] = useState([]);
  const [parent, setParent] = useState();
  const [isOpenApproveConfirmation, setIsOpenApproveConfirmation] =
    useState(false);
  const [query, onPagingChange] =
    useRateConfigQueryParams(MAX_PAGINATION_LIMIT);
  const [degrees, isDegreesLoading] = useDegrees(query);
  const [experiences, isExperiencesLoading] = useExperiences(query);
  const [positions, isPositionsLoading] = usePositions(query);
  const [specialities, isSpecialitiesLoading] = useSpecialities();
  const [staffGroups, isStaffGroupLoading] = useStaffGroups();
  const [whPackageLevels, isWhPackageLevelsLoading] = useWhPackageLevels();
  const [, token] = useUser();
  const [profile] = useProfile(token);

  const [isSubmitLoading, handleCreate] = useCreateWhPartner();
  const [, handleUpdate] = useUpdateWhPartner();
  const [, handleUpdateTip] = useUpdateTipsWhPartner();
  const [isApproveLoading, handleApprove] = useApproveWhPartner(() => {
    history.push({
    pathname: `/wh-partner`
  })});

  const { id } = useParams();
  const [whPartner, isGetWhPartnerLoading] = useWhPartner(id);
  const initWhPartner = useInitWhPartner(whPartner);
  const [initSession, setInitSession] = useState({
    startTime: null,
    endTime: null
  });

  const [filteredDegrees, setFilteredDegrees] = useState([]);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const [filteredExperiences, setFilteredExperiences] = useState([]);
  const [loadingValidateUsername, setLoadingValidateUsername] = useState(false)
  const [valueSearchHereMap, setValueSearchHereMap] = useState('');
  const [position, setPosition] = useState({});
  const [positionProp, setPositionProp] = useState();
  const margin = useFormItemMargin();

  const optionsKindWhPartner = useOptionsSelectAntd(KIND_WH_PARTNER_VI)
  const blankCertificate = '';

  const isRootAdmin = get(profile, 'isSuperAdmin') === true;

  const managementPaths = useMemo(() => {
    return get(profile, 'managementArea')?.map((item) => get(item, 'path'));
  }, [profile]);

  const getParent = async (initWhPartner) => {
    const response = await api.whPartner.getById(get(initWhPartner, 'parent'));
    setParent(get(response, 'docs.0'));
  };
  const onTabClick = (key) => {
    setActiveKey(key);
    history.replace(`/wh-partner/edit/${id}`)
  };
  useEffect(() => {
    form.resetFields();

    let { avatar, address, employeeGroupId } = initWhPartner;

    if (avatar) setAvatar(avatar);

    if (head(address)) {
      const { lat, lng } = head(address);
      setDefaultCenter({ lat, lng });
    }

    setFilteredPositions(
      positions.filter(
        (item) => get(item, 'employeeGroup.[0]._id') === employeeGroupId
      )
    );
    setFilteredDegrees(
      degrees.filter(
        (item) => get(item, 'employeeGroup.[0]._id') === employeeGroupId
      )
    );
    setFilteredExperiences(
      experiences.filter(
        (item) => get(item, 'employeeGroup.[0]._id') === employeeGroupId
      )
    );

    // if (get(initWhPartner, "parent")) {
    //   getParent(initWhPartner);
    // }
    // const testAddress = [ // test address , pls remove when api gg map fixed
    //   {
    //     address : '118 Nguyễn Hữu Dật, Hoà Cường Bắc, Hải Châu, Đà Nẵng 550000, Việt Nam',
    //     lat:16.0410024,
    //     lng:108.2134692,
    //     // placeId:"ChIJb96xTpUZQjERlIlCchkvKxk"
    //   }
    // ]
    // setPositionProp({
    //   lat: initWhPartner?.lat,
    //   lng: initWhPartner?.lng,
    // })
    // setDefaultValue(testAddress)// test address , pls remove when api gg map fixed
  }, [initWhPartner, form, positions, degrees, experiences]);
  useEffect(() => {
    const fetchPartnerRating = async () => {
      const response = await api.whPartner.getRatingById(id);
      setRating(response);
    };
    id && fetchPartnerRating();
  }, [id]);
  const mergedInitWhPartner = useMemo(() => {
    if (!id) {
      return {
        ...initWhPartner
      };
    } else {
      if (!get(initWhPartner, 'waitingApproval')) {
        setIsOpenApproveConfirmation(false);
      }
    }
    return {
      ...initWhPartner
    };
  }, [id, initWhPartner]);

  useEffect(() => {
    if (whPartner?.address) {
      setPositionProp({
        lat: whPartner?.lat,
        lng: whPartner?.lng,
      });
      setValueSearchHereMap(whPartner?.address)
    } else {
      const defaultPosition = { lat:16.0410024, lng:108.2134692};
      const defaultAddress = '118 Nguyễn Hữu Dật, Hoà Cường Bắc, Hải Châu, Đà Nẵng 550000, Việt Nam'
      setPositionProp(defaultPosition);
      setPosition(defaultPosition);
      setValueSearchHereMap(defaultAddress)
      form.setFieldsValue({
        address: defaultAddress
      });
    };
  }, [whPartner, id])

  const onFocusOutFullName = async () => {
    const fullName = form.getFieldValue('name');
    const usernameAvailable = get(mergedInitWhPartner,'username')
    if ((!id && fullName) || (!usernameAvailable && fullName)) { // Only Create or update partner no userName
      try {
        setLoadingValidateUsername(true)
        const username = await autoCreateUsername({ fullName, callApi: (query) => api.whPartner.validateUsername(query) })
        form.setFieldsValue({ username: removeAccents(username?.toLowerCase()) })
        setLoadingValidateUsername(false)
      } catch (error) {
        setLoadingValidateUsername(false)
        toastr.error("Lỗi khi lấy dữ liệu từ máy chủ")
      }
    }
  }

  useEffect(() => {
    form.setFieldsValue(mergedInitWhPartner);
    onFocusOutFullName()
  }, [form, mergedInitWhPartner]);

  useResetWhPartner();

  const setWhPartnerLiteracyImg = (image, certificateIdx) => {
    form.setFieldsValue({
      literacyImages: form
        .getFieldValue('literacyImages')
        .map((certificate, cIdx) =>
          cIdx === certificateIdx ? image : certificate
        )
    });
  };

  const onFinish = (values) => {
    const fullValues = form.getFieldsValue(true);
    const { address, lat, lng, ...rest } = fullValues;
    const whPartner = toJSON({
      ...rest,
      avatar,
    });
    if (id) {
      handleUpdate({
        ...whPartner,
        id: head(id.split('&')),
        address: valueSearchHereMap,
        lat: position?.lat,
        lng:  position?.lng
      });
    } else {
      handleCreate(
        {
        ...whPartner,
        address: valueSearchHereMap,
        lat: position?.lat,
        lng:  position?.lng
      });
    }
  };

  const onApproveWhPartner = () => {
    const whPartner = toJSON({
      ...form.getFieldsValue(),
      avatar
    });
    const { services, ...rest } = whPartner
    const filterService = services?.filter((item) => item !== null || item !== undefined)
    const checkService = filterService ? filterService : []
    const querySent = {
      ...rest,
    id: head(id.split('&')) ,
      services: checkService
    }
      if (id) {
         handleApprove(querySent);
      }
  };
  const checkTime = (times,arr) => {
    const find = arr?.find((e,index) => {
      const start = moment(e.startTime, 'HH:mm');
      const end = moment(e.endTime, 'HH:mm');
      const timeStart = moment(times.startTime, 'HH:mm');
      const timeEnd = moment(times.endTime, 'HH:mm');
      if(e.startTime === times.startTime && e.endTime === times.endTime) {
        return false
      }
      // let isExist = false;
      // let startAdd = 0
      const isExist = ((timeStart.isBetween(start, end) || timeEnd.isBetween(start, end)) || (start.isBetween(timeStart, timeEnd) || end.isBetween(timeStart, timeEnd)));
      // for (let i = parseInt(timeStart.format('HH')); i <= parseInt(timeEnd.format("HH")); i++) {
      //   let time = moment(times.startTime, 'HH:mm').add(startAdd,'hours')
      //   let HH = moment(time).format("HH:mm")
      //   time._i = HH
      //   startAdd++
      //   if(time.isBetween(start, end)) {
      //     isExist = true; break
      //   }
      // }
      return isExist
      
   
    })
    return find
  }
  const setTime = (action) => {
    // format data get from sessionForm to string format "HH:mm"
    const sessionFormFieldsValue = {
      startTime: moment.isMoment(sessionForm.getFieldValue('startTime'))
        ? moment(sessionForm.getFieldValue('startTime')).format('HH:mm')
        : sessionForm.getFieldValue('startTime'),
      endTime: moment.isMoment(sessionForm.getFieldValue('endTime'))
        ? moment(sessionForm.getFieldValue('endTime')).format('HH:mm')
        : sessionForm.getFieldValue('endTime')
    };
  let newAvailabelTime = [...form.getFieldValue('availableTime')?.[dayIdx]?.sessionsOfDay]
  if(sessionIdx !== -1) {
    newAvailabelTime.splice(sessionIdx,1)
  }
  
   const isExist = checkTime(sessionFormFieldsValue,newAvailabelTime)
   if(!(action && action.remove)){
    if(isExist){
      toastr.error(`Thêm thất bại , trùng giờ ${get(isExist,'startTime','')} : ${get(isExist,'endTime','')}`)
      return 
     }
   }
  
    form.setFieldsValue({
      availableTime: form.getFieldValue('availableTime').map((day, dIdx) => ({
        ...day,
        sessionsOfDay:
          sessionIdx === -1
            ? // add new session on the matching day
              dIdx === dayIdx
              ? day.sessionsOfDay.concat({ ...sessionFormFieldsValue })
              : day.sessionsOfDay
            : action && action.remove
            ? // remove session on the matching day only
              day.sessionsOfDay.filter((session, sIdx) =>
                dIdx === dayIdx ? sIdx !== sessionIdx : true
              )
            : // update session on the matching day only
              day.sessionsOfDay.map((session, sIdx) => ({
                ...session,
                ...(dIdx === dayIdx &&
                  sIdx === sessionIdx && { ...sessionFormFieldsValue })
              }))
      }))
    });
  };

  const calculatePackageLevel = () => {
    const rate = parseFloat(form.getFieldValue('rate'));
    // const extraRate = parseFloat(form.getFieldValue("extraRate")) // extraRate is already included in rate, no need to sum it here. See recalculateRate() method

    const matchingEmployeeGroup = staffGroups.find(
      (item) => item.id === form.getFieldValue('employeeGroupId')
    );
    // console.log("matchingEmployeeGroup...");
    // console.log(matchingEmployeeGroup);

    if (matchingEmployeeGroup && get(matchingEmployeeGroup, 'rateForLevel')) {
      const { rateForLevel } = matchingEmployeeGroup;
      // console.log("rateForLevel...");
      // console.log(rateForLevel);

      const activeRateForLevel = mapValues(
        pickBy(
          rateForLevel,
          (value) => get(value, 'state') === INSTANCE_STATUSES.ACTIVE
        ),
        (item) => get(item, 'value')
      );

      // create the level-rate pairs and sort them by rate descending
      const reversedPairs = sortBy(toPairs(activeRateForLevel), 1).reverse();
      // console.log("reversedPairs...");
      // console.log(reversedPairs);

      // get the first pair that rate still be greater than or equal to
      const matchingPair = reversedPairs.find((pair) => rate >= pair[1]);
      // console.log("matchingPair...");
      // console.log(matchingPair);

      // if a matching pair exists, the level of the pair should be appropriate for this whPartner
      if (matchingPair) {
        const packageLevel = whPackageLevels.find(
          (item) => parseInt(get(item, 'level')) === parseInt(matchingPair[0])
        );
        // console.log("packageLevel...");
        // console.log(packageLevel);

        // form.setFieldsValue({ packageLevelId: matchingPair[0] })
        form.setFieldsValue({ packageLevelId: get(packageLevel, '_id') });
      } else {
        form.setFieldsValue({ packageLevelId: null });
      }
    }
  };

  const onValuesChange = (changes) => {
    const { address, employeeGroupId, extraRate, literacy } = changes;
    // if (address && head(address)) {
    //   const { lat, lng, address: fullAddress } = head(address);
    //   setDefaultCenter({ lat, lng });
    // }

    if (employeeGroupId) {
      // TODO: should refactor this code
      form.setFieldsValue({
        literacy: {
          ...form.getFieldValue('literacy'),
          positionId: undefined,
          degreeId: null,
          experienceId: null
        }
      });
      setFilteredPositions(
        positions.filter(
          (item) => get(item, 'employeeGroup[0]_id') === employeeGroupId
        )
      );
      setFilteredDegrees(
        degrees.filter(
          (item) => get(item, 'employeeGroup[0]_id') === employeeGroupId
        )
      );
      setFilteredExperiences(
        experiences.filter(
          (item) => get(item, 'employeeGroup[0]_id') === employeeGroupId
        )
      );
    }

    const recalculateRate = () => {
      // recalculate the rate: rate is equal to sum of rates from extraRate, position, degree, experience
      const initialRate = extraRate || form.getFieldValue('extraRate') || 0;

      const rate = [...positions, ...degrees, ...experiences]
        .filter((item) =>
          [
            form.getFieldValue(['literacy', 'positionId']),
            form.getFieldValue(['literacy', 'degreeId']),
            form.getFieldValue(['literacy', 'experienceId'])
          ].includes(get(item, '_id'))
        )
        .reduce((prev, curr) => prev + get(curr, 'rate'), initialRate);

      form.setFieldsValue({ rate: parseFloat(rate).toFixed(3) });
    };

    if (literacy || extraRate || extraRate === 0 || extraRate === null) {
      recalculateRate();
      calculatePackageLevel();
    }
  };

  const onManagementAreaChange = (value, option) => {
    form.setFieldsValue({
      managementArea: {
        fullAddress: get(option, 'full_name') || get(option, 'name')
      }
    });
  };
  // const onPositionChange = (position) => {
  //   const address = get(position, 'formatted_address'); // human readable address
  //   const location = get(position, 'geometry.location'); // lat lng
  //   const placeId = get(position, 'place_id');

  //   // const defaultValue = [
  //   //   {
  //   //     address,
  //   //     gmaps: position,
  //   //     placeId,
  //   //     ...location
  //   //   }
  //   // ];
  //   const testAddress = [ // test address , pls remove when api gg map fixed
  //     {
  //       address : '118 Nguyễn Hữu Dật, Hoà Cường Bắc, Hải Châu, Đà Nẵng 550000, Việt Nam',
  //       lat:16.0410024,
  //       lng:108.2134692,
  //       placeId:"ChIJb96xTpUZQjERlIlCchkvKxk"
  //     }
  //   ]
  //   form.setFieldsValue({
  //     address: testAddress
  //   });
  //   // form.setFieldsValue({
  //   //   address: defaultValue
  //   // });

  //   setDefaultValue(testAddress);
  //   // setDefaultValue(defaultValue);
  // };

  const isLoading = isGetWhPartnerLoading;

  // const test = async () => {
  //   const dataTest = await api.whPartner.getChildren(id);
  //   console.log('dataTest', dataTest);
  // };
  // test();
  return (
    <div className="wh-service-form page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} đối tác`}
          routes={[
            { path: PATH_APP.whPartner.root, title: 'Danh sách đối tác' }
          ]}
        />
        <Tabs
          onTabClick={onTabClick}
          defaultActiveKey={'1'}
          destroyInactiveTabPane
          onChange={() => {}}
        >
          <TabPane tab="Thông tin đối tác" key="1">
          <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          scrollToFirstError
          requiredMark={false}
          // initialValues={initWhPartner}
          initialValues={mergedInitWhPartner}
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
          style={{padding : '0px 20px'}}
        >
          <Row
            gutter={mainRowGutter}
            // align="middle"
            justify="space-between"
            className="staff-form__logo-row"
          >
            <Col span={20}>
              <Row >
                <Col span={12}>
                  <FormItem
                    label="Tên đối tác"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập tên đối tác!'
                      }
                    ]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input onBlur={onFocusOutFullName}/>}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                  className='spaceLeft'
                    label="Giới tính"
                    name="gender"
                    labelAlign='end'
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn giới tính!'
                      }
                    ]}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select>
                        <Option value="M" key="male">
                          Nam
                        </Option>
                        <Option value="F" key="female">
                          Nữ
                        </Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row >
                <Col span={12}>
                  <FormItem
                  // labelCol={{ md: 3 }}
                  // wrapperCol={{ md: 21 }}
                    label="Hình thức làm việc"
                    name="wayWorking"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn hình thức làm việc'
                      }
                    ]}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select
                        options={[
                          { label: 'Parttime', value: 'parttime' },
                          { label: 'Fulltime', value: 'fulltime' }
                        ]}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Loại đối tác"
                    name="kindPartner"
                    className='spaceLeft'
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn Loại đối tác'
                      }
                    ]}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select
                        options={optionsKindWhPartner}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row >
                <Col span={12}>
                  <FormItem
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập email!'
                      }
                    ]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Số điện thoại"
                    name="phone"
                    className='spaceLeft'
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[0-9]{10,11}$/),
                        message: 'Xin vui lòng nhập đúng số điện thoại!'
                      }
                    ]}
                  >
                    {isLoading ? <Skeleton.Input active /> : <Input />}
                  </FormItem>
                </Col>
              </Row>

              <Row >
                <Col span={12}>
                  <FormItem
                    label="Nhóm nhân sự"
                    name="employeeGroupId"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn nhóm nhân sự'
                      }
                    ]}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select
                        loading={isStaffGroupLoading}
                        showSearch
                        autoComplete="off"
                        filterOption={filterAcrossAccents}
                      >
                        {staffGroups.map(({ id, _id, name }) => (
                          <Option key={id || _id} value={id || _id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Chuyên môn"
                    name="specialityIds"
                    className='spaceLeft'
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn chuyên môn'
                      }
                    ]}
                  >
                    {isLoading ? (
                      <Skeleton.Input active />
                    ) : (
                      <Select
                        mode="multiple"
                        placeholder=""
                        style={{ width: '100%' }}
                        loading={isSpecialitiesLoading}
                        disabled={isSpecialitiesLoading}
                      >
                        {specialities.map(({ name, id }) => (
                          <Option value={id} key={id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                  <Col span={12}>
                        <FormItem
                          label="ZaloId"
                          name={'zaloId'}
                          rules={[
                            {
                              required: false,
                              message: 'Vui lòng nhập đúng ZaloId là kí tự 19 số',
                              // max: 19,
                              // min: 19,
                              pattern: new RegExp(/^[0-9]{19}$/),
                            }
                          ]}
                        >
                          <Input/>
                    </FormItem>
                  </Col>
              </Row>
                <Col span={24}>
                <LinkReferralPartner fullWidth whPartner={whPartner}/>
                </Col>
              <Row >
            <Col span={24}>
              <FormItem
                label="Địa chỉ"
                name="address"
                wrapperCol={{ sm: 24, md: 12, lg: 21 }}
                labelCol={{ sm: 24, md: 5, lg: 3 }}
                rules={[
                  {
                    required: !valueSearchHereMap,
                    message: 'Vui lòng nhập địa chỉ!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <DebounceSelectHereMap
                    addressDefault = {valueSearchHereMap}
                    placeholder="Vui lòng nhập địa chỉ"
                    onChange={(newValue) => {
                    setPositionProp(position)
                    setValueSearchHereMap(newValue);
                    }}
                    setPosition = {setPosition}
                    style={{
                      width: '100%',
                      }}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
            </Col>
            <Col span={4} className="staff-form__upload-logo">
              <UploadImage
                onChange={setAvatar}
                imgUrl={avatar}
                title="Avatar"
              />
            </Col>
          </Row>

          <Row gutter={mainRowGutter}>
                <Col span={24}>
                  <HereMap address={positionProp} />
           </Col>
            {/* <Col span={2}>
              <Button onClick={() => alert(JSON.stringify(form.getFieldValue("address")))}>dev</Button>
            </Col> */}
          </Row>

          <Row gutter={mainRowGutter}>
            <Col span={24} md={{ offset: 0 }}>
              <FormItem
                label="Khu vực hoạt động"
                name={['managementArea', 'path']}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn khu vực hoạt động!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <GeoTreeSelect
                    autoClearSearchValue
                    checkablePositions={[
                      RELATIVE_POSITION.IS_CHILD,
                      RELATIVE_POSITION.IS_EQUAL
                    ]}
                    enabledValues={isRootAdmin ? null : managementPaths}
                    // listHeight={360}
                    listItemHeight={200}
                    onSelect={onManagementAreaChange}
                    showEnabledValuesOnly={true}
                    showSearch={true}
                    size="large"
                    treeDefaultExpandedKeys={['1', '2', '3']}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label="Khu vực hoạt động (đầy đủ)"
                name={['managementArea', 'fullAddress']}
              >
                {isLoading ? <Skeleton.Input active /> : <Input disabled />}
              </FormItem>
            </Col>
          </Row>

          <h3 className="wh-service-form__section-title">Bằng cấp - Chức vụ</h3>

          <Row gutter={mainRowGutter} align="middle" justify="space-between">
            <Col {...mainColLayout}>
              <FormItem
                label="Chức vụ"
                name={['literacy', 'positionId']}
                {...verticalLayout}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn chức vụ'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select>
                    {filteredPositions.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {get(item, 'name.vi')}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={mainRowGutter} align="middle" justify="space-between">
            <Col {...mainColLayout}>
              <FormItem
                label="Bằng cấp"
                name={['literacy', 'degreeId']}
                {...verticalLayout}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn bằng cấp'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select>
                    {filteredDegrees.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {get(item, 'name.vi')}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={mainRowGutter} align="middle" justify="space-between">
            <Col {...mainColLayout}>
              <FormItem
                label="Kinh nghiệm"
                name={['literacy', 'experienceId']}
                {...verticalLayout}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn kinh nghiệm'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select>
                    {filteredExperiences.map((item, index) => (
                      <Option key={index} value={item._id}>{`${
                        get(item, 'name.vi') || get(item, 'value')
                      } năm`}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={mainRowGutter} align="middle" justify="space-between">
            <Col {...mainColLayout}>
              <FormItem
                label="Chứng chỉ"
                name={['literacy', 'certificate', 'name']}
                {...verticalLayout}
                rules={[
                  {
                    required: false,
                    message: 'Vui lòng nhập tên chứng chỉ'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input placeholder="Chứng chỉ" />
                )}
              </FormItem>
            </Col>
            <Col {...mainColLayout} xl={{ span: 7 }}>
              <FormItem
                label="Nơi cấp"
                name={['literacy', 'certificate', 'issuedBy']}
                {...verticalLayout}
                rules={[
                  {
                    required: false,
                    message: 'Vui lòng nhập nơi cấp chứng chỉ'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input placeholder="Nơi cấp" />
                )}
              </FormItem>
            </Col>
            <Col {...mainColLayout} xl={{ span: 7 }}>
              <Form.Item
                label="Thời gian cấp"
                name={['literacy', 'certificate', 'date']}
                {...verticalLayout}
                rules={[
                  {
                    required: false,
                    message: 'Vui lòng nhập thời gian cấp'
                  }
                ]}
              >
                <DatePicker placeholder="Thời gian cấp" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={mainRowGutter} align="middle" justify="space-between">
            <Col {...mainColLayout}>
              <FormItem
                label="Hệ số chênh lệch"
                name="extraRate"
                {...verticalLayout}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber step={0.1} />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={mainRowGutter} align="middle" justify="space-between">
            <Col {...mainColLayout}>
              <FormItem label="Hệ số" name="rate" {...verticalLayout}>
                {isLoading ? <Skeleton.Input active /> : <Input disabled />}
              </FormItem>
            </Col>
            <Col {...mainColLayout} xl={{ span: 14 }}>
              <FormItem
                label="Gói dịch vụ đảm nhận"
                name={['packageLevelId']}
                // {...verticalLayout}
                wrapperCol={{ sm: 24, md: 12, xl: 15 }}
                labelCol={{ sm: 24, md: 5, lg: 8 }}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select disabled>
                    {whPackageLevels.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {get(item, 'name.vi') || get(item, 'value')}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={mainRowGutter} align="middle" justify="space-between">
            <Col {...mainColLayout}>
              <FormItem
                label="Rate đào tạo"
                name="rateTraining"
                {...verticalLayout}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <h3 className="wh-service-form__section-title">
            Hình ảnh bằng cấp, chứng chỉ
          </h3>
          <Form.List name="literacyImages">
            {(fields, { add, remove }) => (
              <>
                <Row>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => {
                      const props = {
                        fieldKey,
                        form,
                        index,
                        key,
                        language,
                        name,
                        remove,
                        setWhPartnerLiteracyImg
                      };
                      return <WhPartnerCertificateEdit key={key} {...props} />;
                    }
                  )}
                  {fields.length < 4 && (
                    <Col span={6}>
                      <Button
                        className="wh-service-form__question--btn-add-option"
                        type="dashed"
                        onClick={() => add(blankCertificate)}
                        block
                        icon={<PlusOutlined />}
                      >
                        Thêm hình ảnh
                      </Button>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </Form.List>

          <h3 className="wh-service-form__section-title">Thời gian làm việc</h3>
          <Row gutter={mainRowGutter} align="middle" justify="space-between">
            <Col span={24}>
              <WorkingDaysTable
                form={form}
                initSession={initSession}
                isOpenModal={isOpenModal}
                mode={COMPONENT_MODES.EDIT}
                sessionForm={sessionForm}
                sessionIdx={sessionIdx}
                setDayIdx={setDayIdx}
                setIsOpenModal={setIsOpenModal}
                setSessionIdx={setSessionIdx}
                setTime={setTime}
              />
            </Col>
          </Row>

          <h3 className="wh-service-form__section-title">Thông tin khác</h3>
          <Row gutter={mainRowGutter} style={{ marginLeft: margin }}>
            <Col span={24}>
              <FormItem
                name={['description']}
                className={`wh-service-form__description`}
                labelCol={{ sm: 24, md: 24, lg: 3 }}
                wrapperCol={{ sm: 24, md: 24, lg: 21 }}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Input.TextArea rows={4} />
                )}
              </FormItem>
            </Col>
          </Row>

          <h3 className="wh-service-form__section-title">Chỉ định dịch vụ</h3>
          <Row gutter={mainRowGutter} align="middle" justify="space-between">
            <Col span={24}>
              <FormItem shouldUpdate noStyle>
                {() => (
                  <AssignedServicesTable
                    form={form}
                    mode={id ? COMPONENT_MODES.EDIT : COMPONENT_MODES.CREATE} 
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          {/* <h3 className="wh-service-form__section-title">
            Điểm khách hàng đánh giá
          </h3>
          <p style={{ marginBottom: '10px' }}>
            Rate : <b>{rating?.resultSum}</b>
          </p>
          <Row gutter={mainRowGutter} align="middle" justify="space-between">
            <RowRating icon={<Love />} value={rating?.ratingLevel?.LOVE} />
            <RowRating icon={<Like />} value={rating?.ratingLevel?.LIKE} />
            <RowRating
              icon={<Happy />}
              value={rating?.ratingLevel?.ACCEPTABLE}
            />
            <RowRating icon={<Sad />} value={rating?.ratingLevel?.SAD} />
            <RowRating icon={<Angry />} value={rating?.ratingLevel?.ANGRY} />
          </Row> */}
          <Rating id={id}/>
          <h3 className="wh-service-form__section-title">Mã giới thiệu</h3>
          <Row gutter={mainRowGutter}>
            <Col span={12}>
              {/* <Tooltip title={get(parent, "name")}> */}
              <FormItem label="Mã giới thiệu" shouldUpdate>
                {() => {
                  // return isLoading
                  //   ? <Skeleton.Input />
                  //   : <Input disabled value={get(parent, "phone")} />

                  return <Input disabled value={form.getFieldValue('phone')} />;
                }}
              </FormItem>
              {/* </Tooltip> */}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem labelCol={{offset:0,span: 3}} wrapperCol={{offset:0,span: 21}}
                label="Đặc điểm tính cách"
                name="genitive"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Vui lòng nhập Đặc điểm tính cách'
                //   }
                // ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          
          <Row>
            <Col span={24}>
              <FormItem  labelCol={{offset:0,span: 3}} wrapperCol={{offset:0,span: 21}}
                label="Note"
                name="note"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Vui lòng nhập Note'
                //   }
                // ]}
              >
                {isLoading ? <Skeleton.Input active /> : <Input />}
              </FormItem>
            </Col>
          </Row>

          <h3 className="wh-service-form__section-title">
            Thiết lập tài khoản
          </h3>
          <Row gutter={mainRowGutter}>
            <Col span={12}>
              <FormItem
                label="Tài khoản"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Xin mời nhập tên tài khoản!',
                    whitespace: true
                  }
                ]}
              >
                {isLoading || loadingValidateUsername ? (
                  <Skeleton.Input active />
                ) : (
                  <Input autoComplete="off" disabled/>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={mainRowGutter}>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Mật khẩu"
                rules={[
                  {
                    required:
                      !id,
                    message: 'Xin mời nhập mật khẩu!'
                  },
                  { min: 6, message: 'Mật khẩu phải dài ít nhất 6 ký tự' }
                ]}
              >
                <Input.Password
                  autoComplete="off"
                  disabled={!!id && get(mergedInitWhPartner, 'waitingApproval')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirmPassword"
                label="Nhập lại mật khẩu"
                dependencies={['password']}
                rules={[
                  {
                    required:
                      !id,
                    message: 'Xin mời nhập lại mật khẩu!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Mật khẩu không khớp!'));
                    }
                  })
                ]}
              >
                <Input.Password
                  autoComplete="new-password"
                  disabled={!!id && get(mergedInitWhPartner, 'waitingApproval')}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* <h3 className="wh-service-form__section-title">Mô tả đối tác</h3>
          <Form.List name="packages">
            {(fields, { add, remove }) => (
              <Space direction="vertical" size={64} style={{ width: "100%" }}>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                  const props = { fieldKey, form, id, index, isLoading, isWhPackageLevelsLoading, isWhSessionsOfDayLoading, key, language, name, remove, whPackageLevels, whSessionsOfDay }
                  return <WhPartnerPackageLevelEdit key={key} {...props} />
                })}
                {fields.length < whPackageLevels.length && <Row>
                  <Col>
                    <Button
                      type="dashed"
                      onClick={() => add(blankPackageLevel)}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm gói đối tác
                    </Button>
                  </Col>
                </Row>}
              </Space>
            )}
          </Form.List> */}

          <Row className="wh-service-form__submit-box">
            {get(mergedInitWhPartner, 'waitingApproval') && (
              <Button onClick={() => setIsOpenApproveConfirmation(true)}>
                Xác nhận duyệt
              </Button>
            )}

            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.whPartner.root}>
                <Button>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
        <Modal
          confirmLoading={isApproveLoading}
          onCancel={() => setIsOpenApproveConfirmation(false)}
          onOk={onApproveWhPartner}
          title={'Bạn có chắc chắn muốn duyệt đối tác này?'}
          visible={isOpenApproveConfirmation}
        >
          Mọi thay đổi sẽ được lưu khi thực hiện xét duyệt.
        </Modal>
          </TabPane>
          <TabPane disabled={!canReadWhTips || !id || id.includes("&waitingApproval")} tab="Thưởng & phạt" key="2">
            <Tips isGetWhPartnerLoading={isGetWhPartnerLoading} punish={get(whPartner,'punish',[])} reward={get(whPartner,'reward',[])} id={id} handleUpdateTip={handleUpdateTip}/>
          </TabPane>
          <TabPane disabled={!id || id.includes("&waitingApproval")} tab="Thời gian đào tạo" key="3">
            <TrainingCoursePartnerForm />
          </TabPane>
        </Tabs>

      </div>
    </div>
  );
};

export default WhPartnerForm;
