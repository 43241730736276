import { Button, Modal, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import POLICY from '~/constants/policy';
import { useDeleteIntroUsingRefCode, useIntroUsingRefCodePaging, useIntroUsingRefCodeQueryParams, useIntroUsingRefCodes, useResetIntroUsingRefCode, useUpdateIntroUsingRefCodeParams } from '~/hooks/introUsingRefCode';
import { Breadcrumb } from '../Common';
import ColumnActions from '../Common/ColumnActions';
import PermissionToolTip from '../Utils/PermissionToolTip';
import SkeletonTable from '../Utils/SkeletonTable';
import IntroUsingRefCodeForm from './Form/IntroUsingRefCodeForm';
const listOptionSearch = [
  {
    value: 'name',
    label: 'Tên hướng dẫn'
  },
];
export default function IntroUsingRefCode() {
  
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [id, setId] = useState(null)
  const handleOpenModal = (id) => {
    setIsOpenModal(true)
    if (id) {
      setId(id)
    }
  }
  const handleCloseModal = () => {
    setId(null)
    setIsOpenModal(false)
  }
  // hook

  const [query] = useIntroUsingRefCodeQueryParams();
  const [keyword,setKeyword] = useState(query.keyword);
  const [introUsingRefCodes, isLoading] = useIntroUsingRefCodes(query);
  const [onParamChange ] = useUpdateIntroUsingRefCodeParams(query, listOptionSearch)
  const paging = useIntroUsingRefCodePaging(query)
  useResetIntroUsingRefCode()
  //


  ///Delete////

  const [isSubmitLoading, deleteIntroUsingRefCode] = useDeleteIntroUsingRefCode();

  ///Delete///
  const columns = [
    {
      title: 'Tên',
      key: 'title',
      dataIndex: 'title',
      render : (title) => get(title,'vi','')
    },

    {
      title: 'Thứ tự hiển thị',
      dataIndex: 'index',
      key: 'index',
      align: 'center'
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD-MM-YYYY')
    }
  ];

  columns.push({
    title: 'Thao tác',
    key: 'action',
    width: '110px',
    render: (record) => {
      const { _id } = record;
      return (
        <ColumnActions
          onDelete={deleteIntroUsingRefCode}
          _id={_id}
          name="Hướng dẫn này?"
          onOpenFormUpdate={handleOpenModal}
          policyUpdate={POLICY.UPDATE_REFERRAL}
          policyDelete={POLICY.DELETE_REFERRAL}
          loading={isSubmitLoading}
        />
      );
    }
  });


  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">

        <Breadcrumb title="Hướng dẫn sử dụng mã giới thiệu (App)" />
        <div className="page-content__main">
          {/* <div className="page-content__left">
         </div> */}
          <div className="page-content__right" style={{ flex: 1 }}>
            <div
              className="page-wraper__header"
              style={{ justifyContent: 'space-between', display: 'flex' }}
            >
              <div>
                <Search
                  style={{ width: 300 }}
                  placeholder={`Tìm bất kì...`}
                  enterButton
                  allowClear
                  onSearch={(value) => {
                    onParamChange({ keyword: value?.trim() });
                  }}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              </div>
              <PermissionToolTip permission={POLICY.WRITE_REFERRAL} textButton={"Thêm mới"} textPermission={"Bạn không có quyền tạo"} >
                <Button onClick={() => handleOpenModal()} type='primary'>Thêm mới</Button>
              </PermissionToolTip>
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={introUsingRefCodes}
                size="small"
                onChange={({ current }) => onParamChange({ page: current })}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal destroyOnClose width={1000} title={<Typography.Title level={5} style={{ textAlign: 'center' }}>{id ? "Cập nhật hướng dẫn sử dụng mã giới thiệu" : "Tạo hướng dẫn sử dụng mã giới thiệu"}</Typography.Title>} visible={isOpenModal} closable={false} footer={null} onCancel={handleCloseModal}>
        <IntroUsingRefCodeForm  id={id} onClose={handleCloseModal} />
      </Modal>
    </div>
  )
}
