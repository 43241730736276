export default function CalendarPreColorsDashboard({ className }) {
    return (
      <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 7.9483H24V19.5345C24 20.4125 23.6512 21.2545 23.0304 21.8753C22.4096 22.4961 21.5676 22.8449 20.6897 22.8449H1.65517C1.21619 22.8449 0.795194 22.6705 0.484789 22.3601C0.174384 22.0497 0 21.6287 0 21.1897V7.9483Z" fill="#FEC230" />
        <path d="M23.1729 7.9483V11.2586C23.1729 14.112 22.0394 16.8485 20.0217 18.8661C18.0041 20.8838 15.2676 22.0173 12.4142 22.0173H0.828035C0.593782 22.0167 0.362377 21.9659 0.149414 21.8683C0.2797 22.1589 0.491167 22.4057 0.758377 22.5789C1.02559 22.7521 1.33716 22.8445 1.65562 22.8449H20.6901C21.5681 22.8449 22.4101 22.4961 23.0309 21.8753C23.6517 21.2545 24.0004 20.4125 24.0004 19.5345V7.9483H23.1729Z" fill="#FEC230" />
        <path d="M22.3448 2.98279H1.65517C1.21619 2.98279 0.795194 3.15717 0.484789 3.46758C0.174384 3.77798 0 4.19898 0 4.63796V7.94831H24V4.63796C24 4.19898 23.8256 3.77798 23.5152 3.46758C23.2048 3.15717 22.7838 2.98279 22.3448 2.98279Z" fill="#3481FF" />
        <path d="M18.6203 5.46552C18.9495 5.46552 19.2653 5.33473 19.4981 5.10193C19.7309 4.86912 19.8617 4.55337 19.8617 4.22414V1.74138C19.8617 1.41215 19.7309 1.0964 19.4981 0.863592C19.2653 0.630788 18.9495 0.5 18.6203 0.5C18.2911 0.5 17.9753 0.630788 17.7425 0.863592C17.5097 1.0964 17.3789 1.41215 17.3789 1.74138V4.22414C17.3789 4.55337 17.5097 4.86912 17.7425 5.10193C17.9753 5.33473 18.2911 5.46552 18.6203 5.46552Z" fill="#FF3939" />
        <path d="M12.0002 5.46552C12.3294 5.46552 12.6452 5.33473 12.878 5.10193C13.1108 4.86912 13.2415 4.55337 13.2415 4.22414V1.74138C13.2415 1.41215 13.1108 1.0964 12.878 0.863592C12.6452 0.630788 12.3294 0.5 12.0002 0.5C11.6709 0.5 11.3552 0.630788 11.1224 0.863592C10.8896 1.0964 10.7588 1.41215 10.7588 1.74138V4.22414C10.7588 4.55337 10.8896 4.86912 11.1224 5.10193C11.3552 5.33473 11.6709 5.46552 12.0002 5.46552Z" fill="#FF3939" />
        <path d="M5.37907 5.46552C5.70831 5.46552 6.02406 5.33473 6.25686 5.10193C6.48967 4.86912 6.62045 4.55337 6.62045 4.22414V1.74138C6.62045 1.41215 6.48967 1.0964 6.25686 0.863592C6.02406 0.630788 5.70831 0.5 5.37907 0.5C5.04984 0.5 4.73409 0.630788 4.50129 0.863592C4.26848 1.0964 4.1377 1.41215 4.1377 1.74138V4.22414C4.1377 4.55337 4.26848 4.86912 4.50129 5.10193C4.73409 5.33473 5.04984 5.46552 5.37907 5.46552Z" fill="#FF3939" />
        <path d="M10.3444 10.8448H8.68918C8.46065 10.8448 8.27539 11.0301 8.27539 11.2586V12.5C8.27539 12.7286 8.46065 12.9138 8.68918 12.9138H10.3444C10.5729 12.9138 10.7581 12.7286 10.7581 12.5V11.2586C10.7581 11.0301 10.5729 10.8448 10.3444 10.8448Z" fill="#3481FF" />
        <path d="M5.37951 10.8448H3.72434C3.49581 10.8448 3.31055 11.0301 3.31055 11.2586V12.5C3.31055 12.7286 3.49581 12.9138 3.72434 12.9138H5.37951C5.60804 12.9138 5.79331 12.7286 5.79331 12.5V11.2586C5.79331 11.0301 5.60804 10.8448 5.37951 10.8448Z" fill="#3481FF" />
        <path d="M20.276 10.8448H18.6208C18.3923 10.8448 18.207 11.0301 18.207 11.2586V12.5C18.207 12.7286 18.3923 12.9138 18.6208 12.9138H20.276C20.5045 12.9138 20.6898 12.7286 20.6898 12.5V11.2586C20.6898 11.0301 20.5045 10.8448 20.276 10.8448Z" fill="#3481FF" />
        <path d="M10.3444 14.569H8.68918C8.46065 14.569 8.27539 14.7542 8.27539 14.9828V16.2241C8.27539 16.4527 8.46065 16.6379 8.68918 16.6379H10.3444C10.5729 16.6379 10.7581 16.4527 10.7581 16.2241V14.9828C10.7581 14.7542 10.5729 14.569 10.3444 14.569Z" fill="#3481FF" />
        <path d="M5.37951 14.569H3.72434C3.49581 14.569 3.31055 14.7542 3.31055 14.9828V16.2241C3.31055 16.4527 3.49581 16.6379 3.72434 16.6379H5.37951C5.60804 16.6379 5.79331 16.4527 5.79331 16.2241V14.9828C5.79331 14.7542 5.60804 14.569 5.37951 14.569Z" fill="#3481FF" />
        <path d="M10.3444 18.2931H8.68918C8.46065 18.2931 8.27539 18.4784 8.27539 18.7069V19.9483C8.27539 20.1768 8.46065 20.3621 8.68918 20.3621H10.3444C10.5729 20.3621 10.7581 20.1768 10.7581 19.9483V18.7069C10.7581 18.4784 10.5729 18.2931 10.3444 18.2931Z" fill="#3481FF" />
        <path d="M15.3102 10.8448H13.655C13.4265 10.8448 13.2412 11.0301 13.2412 11.2586V12.5C13.2412 12.7286 13.4265 12.9138 13.655 12.9138H15.3102C15.5387 12.9138 15.724 12.7286 15.724 12.5V11.2586C15.724 11.0301 15.5387 10.8448 15.3102 10.8448Z" fill="#3481FF" />
        <path d="M15.3102 14.569H13.655C13.4265 14.569 13.2412 14.7542 13.2412 14.9828V16.2241C13.2412 16.4527 13.4265 16.6379 13.655 16.6379H15.3102C15.5387 16.6379 15.724 16.4527 15.724 16.2241V14.9828C15.724 14.7542 15.5387 14.569 15.3102 14.569Z" fill="#3481FF" />
        <path d="M5.37951 18.2931H3.72434C3.49581 18.2931 3.31055 18.4784 3.31055 18.7069V19.9483C3.31055 20.1768 3.49581 20.3621 3.72434 20.3621H5.37951C5.60804 20.3621 5.79331 20.1768 5.79331 19.9483V18.7069C5.79331 18.4784 5.60804 18.2931 5.37951 18.2931Z" fill="#3481FF" />
        <path d="M19.0339 24.5C21.7763 24.5 23.9994 22.2769 23.9994 19.5345C23.9994 16.7921 21.7763 14.569 19.0339 14.569C16.2915 14.569 14.0684 16.7921 14.0684 19.5345C14.0684 22.2769 16.2915 24.5 19.0339 24.5Z" fill="#35D2B9" />
        <path d="M19.0349 14.569C18.895 14.569 18.7581 14.5785 18.6211 14.5897C19.8618 14.6925 21.0185 15.2581 21.8617 16.174C22.7049 17.09 23.1729 18.2895 23.1729 19.5345C23.1729 20.7795 22.7049 21.979 21.8617 22.8949C21.0185 23.8109 19.8618 24.3764 18.6211 24.4793C18.7581 24.4905 18.895 24.5 19.0349 24.5C20.3518 24.5 21.6148 23.9769 22.546 23.0456C23.4773 22.1144 24.0004 20.8514 24.0004 19.5345C24.0004 18.2175 23.4773 16.9546 22.546 16.0233C21.6148 15.0921 20.3518 14.569 19.0349 14.569Z" fill="url(#paint0_linear_356_185)" />
        <path d="M21.8791 18.3593V20.2959H20.0026V22.1724H18.066V20.2959H16.1895V18.3593H18.066V16.4828H20.0026V18.3593H21.8791Z" fill="#F0F7FF" />
        <path d="M21.88 18.3593V20.2959H20.0034V22.1724H19.0352V16.4828H20.0034V18.3593H21.88Z" fill="#D7E9FC" />
        <defs>
          <linearGradient id="paint0_linear_356_185" x1="21.3001" y1="13.8784" x2="21.3027" y2="23.5837" gradientUnits="userSpaceOnUse">
            <stop stopColor="#35D2B9" />
            <stop offset="0.45625" stopColor="#5ECAC6" />
            <stop offset="1" stopColor="#109BAE" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
  