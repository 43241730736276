import { get } from 'lodash';
import POLICIES, { CORE_ACTION, GROUP_POLICY } from '~/constants/policy';
import { PATH_APP } from '~/routes/paths';
import NavbarItem from './NavbarItem';
class GroupHome {
    instance = null;
    constructor() {
        this.list = [];
    }
    convert(data) {
        // get list permissions of children to push into policies parent
        let policiesOfChild = []
        get(data, 'children', [])?.forEach(child => {
            // if have one child No permission will always show parent
            return get(child, 'permissions', [])?.forEach(permission => {
                policiesOfChild.push(permission)
            })
        })
        return {
            ...data,
            policiesOfChild,
        }
    }
    register(groups, adapter = this.convert) {
        const dataConvert = groups?.map(group => adapter(group));
        this.list = dataConvert;
    }
    getInstance() {
        if (!GroupHome.instance) {
            GroupHome.instance = new GroupHome();
        }
        return GroupHome.instance
    }
}
const instance = new GroupHome().getInstance();

instance.register([
    {
    titleGroup: "WorldHealth",
    children: NavbarItem.buttonsDashboard,
    accessKey: "worldhealth",
    icon: <i className="uil-apps me-2"></i>,
    permissions:  GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_WORLDHEALTH
    },
    {
        titleGroup: "Phòng khám",
        children: NavbarItem.buttonsClinic,
        accessKey: "clinic",
        icon: <i className="uil uil-clinic-medical"></i>,
        permissions: GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_CLINIC,
    },
    {
        titleGroup: "Đối tác phần mềm",
        children: NavbarItem.buttonsTenant,
        accessKey: "tenant",
        icon: <i className="uil uil-clinic-medical"></i>,
        permissions: GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_CLINIC,
    },

    {
        titleGroup: "Nhà thuốc online",
        children: NavbarItem.buttonsPharmacyOnline,
        accessKey: "pharmacyOnline",
        icon: <i className="uil-apps me-2"></i>,
        permissions: GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_PHARMACY,
    },

    {
        titleGroup: "Chi nhánh",
        children: NavbarItem.buttonsBranch,
        accessKey: "branch",
        path: PATH_APP.branch.root,
        icon: <i className="uil uil-hospital "></i>,
        permissions: GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_BRANCH,
    },

    {
        titleGroup: "Nhân viên",
        children: [],
        accessKey: "staff",
        path: PATH_APP.employee.root,
        icon: <i className="uil uil-users-alt "></i>,
        permissions: [POLICIES.READ_EMPLOYEE],
    },

    {
        titleGroup: "Người dùng",
        children: [],
        accessKey: "user",
        path: PATH_APP.user.root,
        icon: <i className="uil uil-user"></i>,
        permissions: GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_USER,
    },

    {
        titleGroup: "CMS",
        children: NavbarItem.buttonsDashCMS,
        accessKey: "CMS",
        icon: <i className="uil-apps me-2"></i>,
        permissions: GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_CMS,
    },

    {
        titleGroup: "Quản lý hệ thống (App)",
        children: NavbarItem.buttonSystem,
        accessKey: "system",
        icon: <i className="uil-apps me-2"></i>,
        permissions: GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_SYSTEM,
    },
    {
      titleGroup: "Quản lý công việc",
      children: NavbarItem.buttonsTodo,
      path: PATH_APP.workFlow.root,
      accessKey: "workFlow",
      // icon: <i className="uil-apps me-2"></i>,
      permissions: GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_TODO,
    },

]);
export default instance;
