import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Table, TreeSelect } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { get, head } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import api from '~/api';
import { GeoTreeSelect } from '~/components/Common';
import { useContextBranch } from '~/components/Common/TabBranch';
import {
  MAX_PAGINATION_LIMIT,
  RELATIVE_POSITION
} from '~/constants/defaultValue';
import {
  useBranchBranches,
  useBranches,
  useCreateWhServiceBranch,
  useProfile,
  useResetWhService,
  useResetWhServiceBranch,
  useUser,
  useWhServices,
  useWhServicesAvailable
} from '~/hooks';
import { useBranchIdSessionStorage } from '~/hooks/utils';
import { StringToSlug } from '~/utils/helper';

const {Option} = Select
export default function FormRegisterService({
  setIsOpen,
  branches,
  whServices,
  service,
  updateWhService,
  handleClose
}) {
  const [form] = Form.useForm();
  const [keywordService, setKeywordService] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [branchIdLocal, token] = useUser();
  const [branchId, setBranchId] = useState(branchIdLocal);
  const [profile] = useProfile(token);
  const [isLoadingServiceBranch, createService] = useCreateWhServiceBranch(handleClose);
  const isRootAdmin = get(profile, 'isSuperAdmin') === true;
  const [optionBranch, setOptionBranch] = useState([])
  const [dataTable, setDataTable] = useState([])
  const managementPaths = useMemo(() => {
    return get(profile, 'managementArea')?.map((item) => get(item, 'path'));
  }, [profile]);
  const [listServices, setListServices] = useState([]);
  const [paging, setPaging] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  // Handle services
  const query = useMemo(
    () => ({
      branchId: String(branchId)
    }),
    [branchId]
  );
  const [services, isLoading] = useWhServicesAvailable(query);
  // const [isLoadingOptionService, setIsLoadingOptionService] = useState(false)
  const filterOption = () => {
    // setIsLoadingOptionService(true);
    const filter = services?.map((item) => {
      const Item = {
        value: item._id,
        label: `${get(item,'code','')} - ${get(item, 'name.vi', '')} - ${get(
          item,
          'detailName.vi',
          ''
        )}`
      };
      return Item;
    });
    const listId = whServices.map((value) => value.serviceId);
    const mapListService = listServices.map((value) => value.serviceId);
    const filterService = filter?.filter(
      (item) => !listId.includes(item.value)
    );
    const _filterService = filterService?.filter(
      (item) => !mapListService.includes(item.value)
    );
    // filters existing services but does not exclude services that are being updated
    let newArrService = []
    if (service) {
      const Item = {
        value: service?.service._id,
        label: `${get(service?.service, 'name.vi', '')} - ${get(
          service?.service,
          'description.vi',
          ''
        )}`
      }
      newArrService = [ Item,..._filterService]
    } else {
      newArrService = [..._filterService]
    }
    // setIsLoadingOptionService(false);
    return newArrService
  }
  //

  //Handle Branch
  useEffect(() => {
    const mapBranches = branches?.map((item) => ({
      value: item?._id,
      label: item?.name ?? item?.name?.vi
    }));
    const findOptionBranch = () => {
      const newBranch = parseInt(branchIdLocal) ?? branchId
      const find = branches?.find((item) => item._id == newBranch);
      const options = {
        value: find?._id,
        label: find?.name ?? find?.name?.vi
      };
      return options;
    }; 
    if (branchIdLocal === '99999') {
      setOptionBranch(mapBranches);
      setInitialValues({
        branchId: head(mapBranches)?.value
      })
      form.setFieldsValue({
        // branchId: head(mapBranches)?.value
        branchId: 99999 // default value is WorldCare Viet Nam
      });
    } else {
      setOptionBranch([findOptionBranch()]);
      form.setFieldsValue({
        branchId: findOptionBranch()?.value
      });
    }
  }, [branches,form, branchIdLocal]);
  // Handle table
    //render table
  
  const filterNameService = useCallback(() => {
    const mapService = listServices?.map((item) => item?.serviceId);
    const newListService = services?.filter((item) =>
      mapService.includes(item._id)
    );
    const result = [];
    for (const item2 of listServices) { // Get in state
      for (const item1 of newListService) {
        if (item1._id === item2.serviceId) {
          const combinedItem = {
            ...item1,
            areaAvailble: item2.areaAvailble?.map((add) => add.fullAddress)
          };
          result.push(combinedItem);
        }
      }
    };
    setDataTable(result)
    return result;
  }, [listServices, services]);
  
  const handleDeleteService = (id) => {
    const filterService = listServices?.filter((item) => item.serviceId !== id);
    setListServices([...filterService]);
  };
  
  useEffect(() => {
    filterNameService()
  }, [listServices, services]);

  useEffect(() => {
    if (service) {
      form.setFieldsValue({
        serviceId: service?.serviceId,
      });
      const filterArea = service?.areaAvailble?.map((item) => ({
        value: item?.path,
        label: item?.fullAddress,
      }));
      form.setFieldsValue({
        areaAvailble: filterArea
      });
    } else {
      form.resetFields();
    }
  }, [service]);
  
  useEffect(() => {
    setPaging((item) => ({ ...item, total: listServices?.length }))
  }, [listServices]);

  const onValuesChange = ({ branchId }) => {
    if (branchId) {
      form.setFieldsValue({
        serviceId: [],
        areaAvailble:[],
      });
      setListServices([]);
    };
  };

  const onFinish = (values) => {
    const { areaAvailble, serviceId, ...rest } = values;
    const newAddress = areaAvailble?.map((item) => {
      const newItem = {
        fullAddress: item.label,
        path: item.value
      };
      return newItem;
    });
    const query = serviceId?.map(idService => ({
      areaAvailble: newAddress ?? [],
      serviceId: idService,
      ...rest
    }));
    setListServices(...listServices, query);
    form.setFieldsValue({
      serviceId: [],
      // areaAvailble: []
    })
  };

  const handleSubmit = () => {
    const filterArea = form?.getFieldValue('areaAvailble')?.map((area) => ({
      fullAddress: area.label,
      path: area.value
    }))
    const data = service ? filterArea : listServices
    if (service) {
      updateWhService({
        areaAvailble: data,
        id: service?.serviceId
      });
    } else {
    createService({ data: data });
    };
  };

  useResetWhServiceBranch();
  const columns = [
    {
      title: 'STT ',
      key: 'index',
      width: '50px',
      render: (value, record, index) => ((paging.current - 1) * (paging.pageSize) + (index + 1))
    },
    {
      title: 'Tên dịch vụ ',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => get(record, 'name.vi', '')
    },
    // {
    //   title: 'Mô tả dịch vụ',
    //   key: 'decription',
    //   render: (value, record) => record?.description?.vi ?? record?.description
    // },
    {
      title: 'Khu vực hoạt động',
      dataIndex: 'areaAvailble',
      key: 'areaAvailble',
      render: (value, record) => record?.areaAvailble.toString()
    },
    {
      // title: 'Hành động',
      dataIndex: 'action',
      width: '20px',
      align: 'center',
      key: 'action',
      render: (value, record) => {
        return (
          <DeleteOutlined style={{color: 'red'}} onClick={() => handleDeleteService(record?._id)} />
        );
      }
    }
  ];
  return (
    <div>
      <Form
        form={form}
        onFinish={(value) => onFinish(value)}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        labelCol={{ sm: 9, md: 9, lg: 9 }}
        wrapperCol={{ sm: 15, md: 15, lg: 15 }}
      >
        <Form.Item
          label={`Chi nhánh được ${service ? 'cập nhật' : 'đăng ký'}`} name={'branchId'}
        >
          <Select
            disabled = {service}
            onChange={e => setBranchId(String(e))}>
            {optionBranch?.map((item) => (
              <Option key={item?.value} value={item?.value}>{item?.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={`Dịch vụ ${service ? 'cập nhật' : 'đăng ký'}`}
          name={'serviceId'}
          rules={[{ required: true, message: 'Vui lòng chọn dịch vụ!' }]}
        >
          <Select
            disabled = {service}
            showSearch
            loading ={isLoading}
            // style={{width:'340px'}}
            allowClear
            mode='multiple'
            placeholder="Tìm dịch vụ"
            options={filterOption()}
            filterOption={(input, option) =>
              StringToSlug(option?.label?.trim()?.toLowerCase())?.includes(
                StringToSlug(input?.trim()?.toLowerCase())
              )
            }
          />
        </Form.Item>
        <Form.Item
          label="Khu vực hoạt động"
          name={['areaAvailble']}
        >
          <GeoTreeSelect
            autoClearSearchValue
            checkablePositions={[RELATIVE_POSITION.IS_CHILD]}
            // disabled={!isRootAdmin && id === get(profile, '_id')}
            enabledValues={isRootAdmin ? null : managementPaths}
            labelInValue={true}
            // listHeight={360}
            listItemHeight={200}
            multiple={true}
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            showEnabledValuesOnly={true}
            showSearch={true}
            onSelect={(e) => console.log(e)}
            size="large"
            treeCheckStrictly={true}
            treeCheckable={true}
            treeDefaultExpandedKeys={['1', '2', '3']}
            value={[]}
          />
        </Form.Item>
        {!service && <Row align="end">
          <Col span={8} align="end">
            <Button type='primary' htmlType="submit">Thêm dịch vụ</Button>
          </Col>
        </Row>}
      </Form>
      {!service  && <Table
        style={{ marginTop: '10px' }}
        columns={columns}
        dataSource={dataTable}
        pagination={
          {
            ...paging,
            showTotal:  (total) => `Tổng cộng: ${total} `
          }
        }
        onChange={(e) => {
          setPaging((item) => ({
            ...item , current: e.current }) )
        }}
      />}
      <Row style={{ marginTop: '10px' }} align="center">
        <Col span={4} align="center">
          <Button onClick={() => setIsOpen(false)}>Huỷ</Button>
        </Col>
        <Col span={4} align="center">
          <Button
            disabled = {!service && !listServices.length > 0}
            onClick={() => handleSubmit()}
            htmlType="submit"
            type={'primary'}
            loading={isLoadingServiceBranch}
          >
            {service ? 'Cập nhật' : 'Đăng ký'}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
