import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getStaffs,
  createStaff,
  deleteStaff,
  getStaff,
  resetStaffState,
  updateStaff
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const getSelector = key => state => state.staff[key];

const loadingSelector = getSelector('isLoading');
const staffsSelector = getSelector('staffs');
const getStaffsFailedSelector = getSelector('getStaffsFailed');

const isGetStaffLoadingSelector = getSelector('isGetStaffLoading');
const staffSelector = getSelector('staff');
const getStaffFailedSelector = getSelector('getStaffFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useStaffPaging = () => useSelector(pagingSelector);

export const useStaffQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteStaffSuccess = useSelector(deleteSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteStaffSuccess,updateSuccess]);
};

export const useStaffs = query => {
  return useFetchByParam({
    action: getStaffs,
    loadingSelector,
    dataSelector: staffsSelector,
    failedSelector: getStaffsFailedSelector,
    param: query
  });
};

export const useCreateStaff = () => {
  const history = useHistory();
  const callback = () => history.push('/staff');

  useSuccess(createSuccessSelector, 'Tạo mới nhân viên thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createStaff
  });
};

export const useUpdateStaff = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật nhân viên thành công');
  useFailed(updateFailedSelector, 'Cập nhật nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateStaff
  });
};

export const useDeleteStaff = () => {
  useSuccess(deleteSuccessSelector, 'Xoá nhân viên thành công');
  useFailed(deleteFailedSelector, 'Xoá nhân viên thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteStaff
  });
};

export const useStaff = id => {
  return useFetchByParam({
    action: getStaff,
    loadingSelector: isGetStaffLoadingSelector,
    dataSelector: staffSelector,
    failedSelector: getStaffFailedSelector,
    param: id
  });
};

export const useInitStaff = staff => {
  return useMemo(() => {
    if (!staff) {
      return {
        certification: [
          {
            name: '',
            certifiedBy: '',
            certifiedAt: moment()
          }
        ],
        employeeHistory: [
          {
            location: '',
            role: '',
            startTime: moment(),
            endTime: moment()
          }
        ]
      };
    }

    const initValues = {
      ...staff,
      certification: staff?.certification?.map(cer => ({
        ...cer,
        certifiedAt: moment(cer.certifiedAt)
      })),

      degree: {
        degreeId: staff?.degree?.degreeId,
        issuedAt: moment(staff.degree?.issuedAt)
      },

      employeeHistory: staff?.employeeHistory?.map(history => ({
        ...history,
        startTime: moment(history.startTime),
        endTime: moment(history.endTime)
      })),
    };

    return initValues;
  }, [staff]);
};

export const useResetStaff = () => {
  useResetState(resetStaffState);
};
