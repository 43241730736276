import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import FormItem from 'antd/lib/form/FormItem';
import { get, head, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import api from '~/api';
import { useUser } from '~/hooks';
import { formatter } from '~/utils/helper';
import toastr from 'toastr';
import LoadingWhBill from '../LoadingWhBill';

const initialValues = {

};
const formRowGutter = 48;
const formColSpan = 24;
const widthInput = 300;

export default function SelectService({ listCategory, setBonusWhAppointments, onCancel, bonusWhAppointments, whCategoryIdQuotation }) {
    console.log(listCategory,'listCategory')
    const [form] = Form.useForm();
    const [branchId] = useUser();
    const [loading, setLoading] = useState(false);

    const [AllSelect, setAllSelect] = useState({
        categorySelect: {
            value: null,
            order: 1,
            listOption: listCategory
        },
        seriveSelect: {
            value: null,
            order: 2,
            listOption: []
        },
        packageLevelSelect: {
            value: null,
            order: 3,
            listOption: []
        },
        whSessionOfDaySelect: {
            value: null,
            order: 4,
            listOption: []
        }
    });
    const handleChange = ({ item, value, listNext }) => {
        form.setFieldsValue({ prePayAmount: 0 });
        const itemSelect = AllSelect[item];
        let nextitem = '';
        let AllSelectTemp = { ...AllSelect };
        for (const key in AllSelect) {
            if (AllSelect[key].order === itemSelect.order + 1) nextitem = key;
            if (AllSelect[key].order > itemSelect.order) {
                AllSelectTemp[key].value = null;
                form.setFieldsValue({ [key]: null });
            }
        }
        if (nextitem !== '') {
            let next = AllSelectTemp[nextitem];
            setAllSelect({
                ...AllSelectTemp,
                [item]: { ...itemSelect, value },
                [nextitem]: { ...next, listOption: listNext }
            });
        } else {
            setAllSelect({ ...AllSelectTemp, [item]: { ...itemSelect, value } });
        }
    };
    // Change whCategoryId Select
    const handleChangeCategory = async (value) => {
        //
        try {
            setLoading(true);
            const res = await api.whService.getWhServiceAvailableByBranch({
                status: 'ACTIVE',
                branchId: parseInt(branchId),
                whCategoryId: value
            });
            const listService = res?.map((e) => ({
                value: JSON.stringify(e),
                label: e.name.vi
            }));
            setLoading(false);
            handleChange({ item: 'categorySelect', value, listNext: listService });
        } catch (error) {
            toastr.error('Lấy dữ liệu dịch vụ bị lỗi')
            setLoading(false);
        }
    };

    // Change Service Select

    const handleChangeService = async (value) => {
        try {
            setLoading(true);
            const res = await api.whService.getById(JSON.parse(value)._id);
            const listNext = res.packages?.map((item) => ({
                value: JSON.stringify(item),
                label: get(item, 'whPackageLevel.name.vi', '')
            }));
            handleChange({ item: 'seriveSelect', value, listNext });
            setLoading(false);
        } catch (error) {
            toastr.error('Lấy dữ liệu dịch vụ bị lỗi')
            setLoading(false);
        }
    };

    // Change Package Select
    const handleChangePackageLevel = (value) => {
        const sessionDay = AllSelect.packageLevelSelect.listOption.find(
            (e) => JSON.stringify(e.value) === JSON.stringify(value)
        );
        const listNext = JSON.parse(sessionDay.value).sessionPrices.map((item) => ({
            value: JSON.stringify(item),
            label: get(item, 'whSessionOfDay.name', '')
        }));
        handleChange({ item: 'packageLevelSelect', value, listNext });
    };

    // Change Days Select
    const handleChangeDays = (value) => {
        handleChange({ item: 'whSessionOfDaySelect', value });
    };

    // Submit
    const onFinish = (values) => {
        const { packageLevelSelect, seriveSelect, whSessionOfDaySelect, minimumQuantity, whCategoryId } = values;
        const packageLevel = JSON.parse(packageLevelSelect);
        const service = JSON.parse(seriveSelect);
        const whSessionOfDay = JSON.parse(whSessionOfDaySelect);
        const bonusWhAppointment = {
            ...pick(whSessionOfDay, [
                'displaySession',
                'duration',
                'timeUnit',
            ]),
            minimumQuantity,
            code: get(service, 'code'),
            whCategoryId,
            whServiceId: get(service, '_id'),
            whService: service,
            whPackageId: get(packageLevel, '_id'),
            whPackageLevelId: get(packageLevel, 'whPackageLevelId'),
            whPackageLevel: get(packageLevel, 'whPackageLevel'),
            whSessionPriceItemId: get(whSessionOfDay, '_id'),
            whSessionOfDayId: get(whSessionOfDay, 'whSessionOfDayId'),
        }
        setBonusWhAppointments(bonusWhAppointment);
        console.log(bonusWhAppointment, 'bonusWhAppointment');
        onCancel();
    };


    // Init Data
    const fetchData = useCallback(async () => {

        setLoading(true)
        const res = await api.whService.getWhServiceAvailableByBranch({
            status: 'ACTIVE',
            branchId: parseInt(branchId),
            whCategoryId: bonusWhAppointments?.whCategoryId
        });
        const listService = res?.map((e) => ({
            value: JSON.stringify(e),
            label: e.name.vi
        }));

        const service = listService.find(
            (e) => get(JSON.parse(e.value), '_id') === get(bonusWhAppointments, 'whServiceId')
        );
        if (!service) {
            setLoading(false)
            return;
        }
        const { packages } = await api.whService.getById(
            JSON.parse(service.value)._id
        );
        const listPackages = packages.map((e) => ({
            value: JSON.stringify(e),
            label: e.whPackageLevel.name.vi
        }));
        const packageLevel = packages.find(
            (e) => e.whPackageLevelId === bonusWhAppointments.whPackageLevelId
        );
        const whSessionOfDayList = packageLevel.sessionPrices.map((item) => ({
            value: JSON.stringify(item),
            label: item.whSessionOfDay.name
        }));
        const whSessionOfDay = packageLevel.sessionPrices.find(
            (e) => e.whSessionOfDayId === bonusWhAppointments.whSessionOfDayId
        );

        setAllSelect({
            categorySelect: {
                value: bonusWhAppointments.whCategoryId,
                order: 1,
                listOption: listCategory
            },
            seriveSelect: {
                value: service.value,
                order: 2,
                listOption: listService
            },
            packageLevelSelect: {
                value: JSON.stringify(packageLevel),
                order: 3,
                listOption: listPackages
            },
            whSessionOfDaySelect: {
                value: JSON.stringify(whSessionOfDay),
                order: 4,
                listOption: whSessionOfDayList
            }
        });
        form.setFieldsValue({
            ...bonusWhAppointments,
            seriveSelect: service.value,
            packageLevelSelect: JSON.stringify(packageLevel),
            whSessionOfDaySelect: JSON.stringify(whSessionOfDay),
        });
        console.log(bonusWhAppointments,'bonusWhAppointments');
        setLoading(false);
    }, [bonusWhAppointments, listCategory, whCategoryIdQuotation]);
    useEffect(() => {
        if(bonusWhAppointments){
          listCategory && fetchData();
        } else {
            const findWhCategoryInList = listCategory.find(e => e.id === whCategoryIdQuotation);
            form.setFieldsValue({ whCategoryId: findWhCategoryInList ?  whCategoryIdQuotation :  head(listCategory)?.id});
            handleChangeCategory(whCategoryIdQuotation)
        }
    }, [fetchData,bonusWhAppointments]);
    return (
        <Form
            colon={false}
            className="home-service-form"
            autoComplete="off"
            form={form}
            initialValues={initialValues}
            labelCol={{ sm: 8, md: 8, lg: 8, xl: 8 }}
            onFinish={onFinish}
            requiredMark={false}
            scrollToFirstError
            wrapperCol={{ sm: 16, md: 16, lg: 16, xl: 16 }}
        >
            <LoadingWhBill open={loading} />
            <Row
                align="middle"
                className="home-service-form__row"
                gutter={formRowGutter}
                justify="start"
            >
                <Col span={formColSpan}>
                    <FormItem
                        className="home-service-form__form-item"
                        label="Chọn nhóm dịch vụ"
                        name="whCategoryId"
                        labelCol={{ span: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Xin vui lòng chọn nhóm dịch vụ'
                            }
                        ]}
                    >
                        <Select
                            placeholder="Chọn nhóm dịch vụ muốn tặng"
                            style={{ width: widthInput }}
                            onChange={handleChangeCategory}
                            options={listCategory.map((e) => ({
                                value: e?.id,
                                label: e?.name?.vi
                            }))}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                align="middle"
                className="home-service-form__row"
                gutter={formRowGutter}
                justify="start"
            >
                <Col span={formColSpan}>
                    <FormItem
                        className="home-service-form__form-item"
                        label="Chọn dịch vụ"
                        name="seriveSelect"
                        labelCol={{ span: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Xin vui lòng chọn dịch vụ'
                            }
                        ]}
                    >
                        <Select
                            value={AllSelect.seriveSelect.value}
                            showSearch
                            placeholder="Chọn dịch vụ muốn tặng"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.value
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: widthInput }}
                            onChange={handleChangeService}
                            options={AllSelect.seriveSelect.listOption}
                            loading={loading}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                align="middle"
                className="home-service-form__row"
                gutter={formRowGutter}
                justify="start"
            >
                <Col span={formColSpan}>
                    <FormItem
                        className="home-service-form__form-item"
                        label="Gói dịch vụ"
                        name="packageLevelSelect"
                        labelCol={{ span: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Xin vui lòng chọn gói dịch vụ'
                            }
                        ]}
                    >
                        <Select
                            value={AllSelect.packageLevelSelect.value}
                            style={{ width: widthInput }}
                            onChange={handleChangePackageLevel}
                            options={AllSelect.packageLevelSelect.listOption}
                            placeholder="Chọn gói dịch vụ muốn tặng"
                            loading={loading}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                align="middle"
                className="home-service-form__row"
                gutter={formRowGutter}
                justify="start"
            >
                <Col span={formColSpan}>
                    <FormItem
                        className="home-service-form__form-item"
                        label="Thời gian thực hiện"
                        name="whSessionOfDaySelect"
                        labelCol={{ span: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Xin vui lòng chọn thời gian thực hiện'
                            }
                        ]}
                    >
                        <Select
                            value={AllSelect.whSessionOfDaySelect.value}
                            style={{ width: widthInput }}
                            onChange={handleChangeDays}
                            options={AllSelect.whSessionOfDaySelect.listOption}
                            placeholder="Chọn thời gian thực hiện muốn tặng"
                            loading={loading}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                align="middle"
                className="home-service-form__row"
                gutter={formRowGutter}
                justify="start"
            >
                <Col span={formColSpan}>
                    <FormItem
                        className="home-service-form__form-item"
                        label="Số lượng"
                        name="minimumQuantity"
                        labelCol={{ span: 8 }}
                        rules={[
                            {
                                required: true,
                                message: 'Xin vui lòng chọn thời gian thực hiện'
                            }
                        ]}
                    >
                        <InputNumber formatter={formatter} addonAfter={<div>Buổi</div>} />
                    </FormItem>
                </Col>
            </Row>
            <Row justify='end'>
                <Col>
                    <Button htmlType='submit' type='primary'>
                        Xác nhận
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}
