import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import { get } from 'lodash';
import React, { useEffect } from 'react'
import POLICIES from '~/constants/policy';
import { useUIs, useUpdateUI } from '~/hooks/UI';
import { Breadcrumb, WithPermission } from '../Common'
import UploadImage from '../Utils/UploadImage';
import LoadingWhBill from '../WhBill/LoadingWhBill';
import SelectTypeForm from './SelectTypeForm';
const rulesRequire = [{
    require : true,
    message : "Vui lòng nhập"
}]
export default function RegisterWhPartnerConfig() {
    const [form] = Form.useForm();
    const [UI, isLoading] = useUIs('homepageWhPartnerRegister');
    const [isSubmitLoading, update] = useUpdateUI();
    const onFinish = (values) => {
        update({ key: 'homepageWhPartnerRegister', ...values })
    };

    useEffect(() => {
        form.setFieldsValue({
            ...UI?.UI?.homepageWhPartnerRegister,
        })
    }, [UI]);
    if(isLoading) return <LoadingWhBill open={true}/>
    return (
        <div className="page-wraper page-content">
            <div className="container-fluid registerWhPartnerConfig">
                <Breadcrumb title="Thiết lập đăng ký đối tác" />
                <Form form={form} onFinish={onFinish}>
                <Form.Item label="Hiển thị" valuePropName="checked" name={['active']} >
                        <Switch />
                    </Form.Item>
                    <Form.Item label='Tiêu đề' name={'title'}>
                        <Input placeholder="Tiêu đề" />
                    </Form.Item>
                    <h4>Thiết lập Form</h4>
                    <Form.Item shouldUpdate={(pre, next) => pre?.forms !== next?.forms} noStyle>
                        {() => <Form.List name={['forms']}>
                            {(fields, { add, remove }) => (
                                <>
                                    <Row gutter={8}>
                                        {fields.map((field, index) => {
                                            const image = form.getFieldValue(['forms', index, 'image']);
                                            return (
                                                <Col span={6}>
                                                    <div className='registerWhPartnerConfig--item'>
                                                        <div className='registerWhPartnerConfig--item__close'>
                                                        <Button onClick={() => remove(index)} type='text' danger><CloseOutlined /></Button>
                                                        </div>
                                                        <Form.Item rules={rulesRequire} name={[index, 'image']}>
                                                            <UploadImage imgUrl={image} className='registerWhPartnerConfig--item__image' title={""} />
                                                        </Form.Item>
                                                        <Form.Item rules={rulesRequire} name={[index, 'subTitle']}>
                                                            <Input placeholder='Tiêu đề phụ' />
                                                        </Form.Item>
                                                        <Form.Item rules={rulesRequire} name={[index, 'title']}>
                                                            <TextArea placeholder='Tiêu đề' rows={2} />
                                                        </Form.Item>
                                                        <Form.Item rules={rulesRequire} name={[index, 'typeFormId']}>
                                                            <SelectTypeForm style={{ width: '100%' }} placeholder='Loại form' />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                      <Col span={5}>
                                            <Button onClick={() => add()} type='dashed'>+ Thêm</Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Form.List>}
                    </Form.Item>
                    <WithPermission permission={POLICIES.UPDATE_CONFIGWHPARTNERREGISTER}>
                    <Button loading={isSubmitLoading} className='mt-2' type='primary' htmlType='submit'>Cập nhật</Button>
                    </WithPermission>
                </Form>
            </div>
        </div>
    )
}
