import { PATH_APP } from '../../routes/paths';
import {
  useNewses,
  useDeleteNews,
  useNewsQueryParams,
  useNewsPaging,
  useUpdateNewsId,
} from '../../hooks/news';
import { WithPermission } from '../Common';
import POLICIES from '~/constants/policy';
import { Permissions } from '~/hooks/utils';
import { Button, Checkbox, Popconfirm, Select, Space, Table, Tag, Tooltip, notification } from 'antd';
import { map, pick } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '../../components/Utils/SkeletonTable';
import FormSearch from './FormSearch';
import { CASE_UPDATE } from './constant';
const options=[
  {
    value : 'PUBLISHED',
    label : <span style={{color: 'green'}}>Công khai</span>,
  },
  {
    value : 'PRIVATE',
    label : <span style={{color: 'blue'}}>Không công khai</span>,
  },
  {
    value : 'EDITING',
    label : <span style={{color: 'red'}}>Đang sửa</span>,
  }
]

const ColumnActions = ({ id, deleteNews }) => {
  return (
    <div className="custom-table__actions">
      <WithPermission permission={POLICIES.UPDATE_ANNOUNCEMENT}>
      <Link to={`/news/edit/${id}`}>
        <p>Sửa</p>
      </Link>
    </WithPermission>
    <WithPermission permission={POLICIES.UPDATE_ANNOUNCEMENT}>
        <WithPermission permission={POLICIES.DELETE_ANNOUNCEMENT}>
        <p>|</p>
        </WithPermission>
    </WithPermission>
    <WithPermission permission={POLICIES.DELETE_ANNOUNCEMENT}>
      <Popconfirm
        title="Bạn muốn xoá tin tức này?"
        onConfirm={() => deleteNews(id)}
        okText="Xoá"
        cancelText="Huỷ"
      >
        <p>Xóa</p>
      </Popconfirm>{' '}
      </WithPermission>
    </div>
  );
};

const News = () => {
  const {canUpdate} = Permissions("ANNOUNCEMENT");
  const [query, onTableChange] = useNewsQueryParams();
  const [news, isLoading] = useNewses(query);
  const [, deleteNews] = useDeleteNews();
  const [, handleUpdate] = useUpdateNewsId();

  const paging = useNewsPaging();
  const handleChangeItemNews = (key,record,value)=>{
  if(!canUpdate) {
    notification.error({message:'Bạn không có quyền thực hiện thao tác này!',})
    return;
  }
    let args ={[key]: value}
    handleUpdate({...pick(record,['_id','category']) ,...args});
  }

  const columns = [
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title'
    },

    {
      title: 'Danh mục',
      dataIndex: 'category',
      key: 'category',
      render: (category) => {
        return (
          <Space direction="vertical">
            {map(category, (e) => (
              <Tag color="#2b9dff">{e.name}</Tag>
            ))}
          </Space>
        );
      }
    },

    {
      title: 'Đường dẫn',
      dataIndex: 'slug',
      key: 'slug'
    },
    {
      title: 'Tác giả',
      key: 'author',
      dataIndex: ['author','fullName']
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('DD-MM-YYYY')
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status, record) => (
        <Select
          disabled={!canUpdate}
          bordered={false}
          defaultValue={status}
          onChange={(e) => {
            handleChangeItemNews(CASE_UPDATE.status, record, e);
          }}
          options={options}
        ></Select>
      )
    },

    {
      title: 'Highlight',
      dataIndex: 'highlight',
      key: 'highlight',
      align: 'center',
      render: (highlight, record) => {
      const checkField = ['PRIVATE', 'EDITING'].includes(record.status);
      return (
        <TooltipRowNews checkField={checkField}>
          <Checkbox
            disabled={checkField}
            onChange={(e) =>
              handleChangeItemNews(
                CASE_UPDATE.highlight,
                record,
                e.target.checked
              )
            }
            checked={highlight}
          />
        </TooltipRowNews>
      );
      }
    },
    {
      title: 'Nổi bật',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
      align: 'center',
      render: (isFeatured,record) => {
        const checkField = ['PRIVATE', 'EDITING'].includes(record.status);
        return (
          <TooltipRowNews checkField={checkField}>
            <Checkbox
              disabled={checkField}
              onChange={(e) =>
                handleChangeItemNews(CASE_UPDATE.isFeatured, record, e.target.checked)
              }
              checked={isFeatured}
            />
          </TooltipRowNews>
        );
      }
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (item,record) => {
        return <ColumnActions {...record} deleteNews={deleteNews} />;
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid layoutNews">
        <Breadcrumb title="Danh sách tin tức" />

        <div className="page-wraper__header">
        <WithPermission permission={POLICIES.WRITE_ANNOUNCEMENT}>
        <Link to={PATH_APP.news.create}>
            <Button type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </Link>
        </WithPermission>
        </div>
        <div className="page-content__main">
          <div className="page-content__left" >  
            <FormSearch onTableChange={onTableChange}/>
          </div>

          <div className="page-content__right" >
            { (!news.length&& isLoading) ? (
                <SkeletonTable
                  rowCount={news.length}
                  columns={columns}
                  pagination={paging}
                />
              ) : (
                <Table
                  rowKey={(rc) => rc._id}
                  columns={columns}
                  dataSource={news}
                  onChange={onTableChange}
                  pagination={{
                    ...paging,
                    showTotal: (total) => `Tổng cộng: ${total} `
                  }}
                  size="middle"
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;


function TooltipRowNews ({children,checkField}) {
  return (
    <Tooltip
      color="#bebebe"
      overlayInnerStyle={{ maxWidth: 200, textAlign: 'center' }}
      style={{ maxWidth: '180px' }}
      title={
        checkField
          ? 'Không thể thiết lập với bài viết không công khai và đang sửa'
          : ''
      }
    >
      {children}
    </Tooltip>
  );
};
