export const GET_ADVS_REQUEST =
    'GET_ADVS_REQUEST';
export const GET_ADVS_SUCCESS =
    'GET_ADVS_SUCCESS';
export const GET_ADVS_FAILED = 'GET_ADVS_FAILED';

export const GET_ADV_REQUEST = 'GET_ADV_REQUEST';
export const GET_ADV_SUCCESS = 'GET_ADV_SUCCESS';
export const GET_ADV_FAILED = 'GET_ADV_FAILED';

export const CREATE_ADV_REQUEST =
    'CREATE_ADV_REQUEST';
export const CREATE_ADV_SUCCESS =
    'CREATE_ADV_SUCCESS';
export const CREATE_ADV_FAILED =
    'CREATE_ADV_FAILED';

export const UPDATE_ADV_REQUEST =
    'UPDATE_ADV_REQUEST';
export const UPDATE_ADV_SUCCESS =
    'UPDATE_ADV_SUCCESS';
export const UPDATE_ADV_FAILED =
    'UPDATE_ADV_FAILED';


export const DELETE_ADV_REQUEST =
    'DELETE_ADV_REQUEST';
export const DELETE_ADV_SUCCESS =
    'DELETE_ADV_SUCCESS';
export const DELETE_ADV_FAILED =
    'DELETE_ADV_FAILED';


export const RESET_ADV_STATE = 'RESET_ADV_STATE';
export const RESET_ADV_ACTION = 'RESET_ADV_ACTION';
