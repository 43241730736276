import { Radio, Row } from 'antd'
import React from 'react'
import { LANGUAGE } from '~/constants/defaultValue'

export default function LangSelect({ language, setLanguage }) {
    return (
        <Row justify="end" style={{ marginBottom: 20 }}>
            <Radio.Group
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
            >
                <Radio.Button
                    className="wh-service-form__select-langue-btn"
                    value={LANGUAGE.VI}
                >
                    VI
                </Radio.Button>
                <Radio.Button
                    className="wh-service-form__select-langue-btn"
                    value={LANGUAGE.EN}
                >
                    EN
                </Radio.Button>
            </Radio.Group>
        </Row>
    )
}
