import { Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { Form, Select, Tabs } from 'antd';
import Search from 'antd/lib/input/Search';
import { get, head } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useUserWorkspace } from '~/hooks';
import { useProductListPaging, useProductListQueryParams, useProductLists, useProductListsOfPartner, useUpdateProductListParams } from '~/hooks/productList';
const listOptionSearch = [
  {
    value: 'groupProductName',
    label: 'Nhóm sản phẩm'
  },
  {
    value: 'code',
    label: 'Mã sản phẩm'
  },
  {
    value: 'name',
    label: 'Tên sản phẩm'
  }
];
export default function TableMyEquipment({active}) { //active to fetch
  const [profile] = useUserWorkspace()
  const [selectSearch, setSelectSearch] = useState(
    head(listOptionSearch).value
  );
  const [query, onTableChange] = useProductListQueryParams();
  const queryFetch = useMemo(() => active ? ({ ...query, supplierId: get(profile, '_id') }) : null, [query, profile , active]);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateProductListParams(query);
  const [products, isLoading] = useProductListsOfPartner(queryFetch);
  const paging = useProductListPaging();
  //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('');
  };
  ////////////////////////////////
  useEffect(() => {
    let value = listOptionSearch?.find(e => query[e.value])
    setSelectSearch(get(value, 'value', head(listOptionSearch).value))
    setKeyword(query[get(value, 'value', '')])
  }, [])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (item, record, index) => index + 1
    },
    {
      title: 'Nhóm sản phẩm',
      dataIndex: 'groupProduct',
      key: 'groupProduct',
      render: (item, record, index) => get(item, 'name.vi')
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      render: (item, record, index) => <Text strong>{item}</Text>
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      key: 'name',
    },
  ];
  return (
    <>
      <div
        className="page-wraper__header"
        style={{ justifyContent: 'space-between', display: 'flex' }}
      >
        <div>
          <Select
            value={selectSearch}
            style={{
              width: 170
            }}
            onChange={handleChangeSelectSearch}
            options={listOptionSearch}
          />

          <Search
            style={{ width: 300 }}
            placeholder={`Tìm ${get(listOptionSearch.find((e) => e.value === selectSearch), 'label', '')
              }`}
            enterButton
            allowClear
            onSearch={(value) => {
              onParamChange({ [selectSearch]: value });
            }}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

        </div>
      </div>
      {isLoading ? (
        <SkeletonTable columns={columns} rowCount={10} />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          scroll={{x : '100%'}}
          dataSource={products}
          onChange={onTableChange}
          size="middle"
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
        />
      )}
    </>
  )
}
