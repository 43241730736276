import { get } from 'lodash';
import { getExistProp, setEndofDate } from '~/utils/helper';
import requester from './requester';

const reportPartner = {
  getCountDidServiceById: (query) => {
    const {startDate,endDate} = {...query.date,endDate:setEndofDate(get(query,'date.endDate',0))}
    return requester.get(`/log-service-complete/${query.id}`,{startDate,endDate})
  }, //  get Repport Did service of partner V2
  getCountDidServiceOfIntroPartnerById: (query) => {
    const {startDate,endDate} = {...query.date,endDate:setEndofDate(get(query,'date.endDate',0))}
    // const {}
    return requester.get(`/log-service-complete-parent/${query.id}`,{startDate,endDate})
  }, //  get Repport Did service of parent partner V2
  // postKpi: (total) => requester.post(`/service-total-partner`,total), // cia nay chưa xài
  getKpi: () => requester.get(`/service-total-partner`), // get all kpi
  getKpiById: (id,date) => requester.get(`/total-kpis/${id}`,{...date,endDate : setEndofDate(get(date,'endDate',0))}), // get kpi by id
  getIntroSystemById: (id) => requester.get(`/wh-partner-allCal-child/${id}/child-partner`),// get Reports child of Partner // cai nay  ko can nua
  getChildOfPartner: (id) => requester.get(`/wh-partner-all-child/${id}/child-partner`), // get List child of Partner
  getReportCourseTraining: (query) => {
    return requester.get(`/course-training-approved/${query.id}`,{...query,endDate : setEndofDate(get(query,'endDate',0))})
  }, // get Report Training course of Partner
  getReportMedicines: (query) => {
    return requester.get(`/course-training-approved/${query.id}`,{...query,endDate : setEndofDate(get(query,'endDate',0))})
  }, // get Report Sale Medicines of Partner
  getAllRatingPartner: (query) => requester.get(`/wh-all-partner-rating`,{...query,endDate : setEndofDate(get(query,'endDate',0))}), 
  getRatingPartner: (query) => requester.post(`/wh-partner/${query.id}/ratingSum`,{...query,endDate : setEndofDate(get(query,'endDate',0))}), // get Rating of One partner
  getAllRatingPartnerDetail: (query) => {
    return requester.post(`/wh-partner/${query.id}/rating/detail`,{...query,endDate : setEndofDate(get(query,'endDate',0))})
  }, // get Report Training course of Partner
  getAlltotalSale: (query) => {
    const newQuery =  new URLSearchParams(
      getExistProp({
        ...query,endDate : setEndofDate(get(query,'endDate',0)),
      })
      ).toString()
    return requester.get(`/total-fulltime-parttime?${newQuery}`) // get All total Report Partners
  },
  getSaleIntroCustomer : (query) => requester.get(`/bonus-intro-customer-list-customer`,{...query,endDate : setEndofDate(get(query,'endDate',0))}),
  getReportWarehouse : (query) => requester.get(`/wh-partner-device-warehouse`,{...query,endDate : setEndofDate(get(query,'endDate',0))}),
};

export default reportPartner;
