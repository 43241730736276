import produce from 'immer';
import * as Types from '../../constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  isSubmitLoading: false,

  updateSuccess: null,
  updateFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_SYSTEMS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_SYSTEMS_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_SYSTEMS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_SYSTEM_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_SYSTEM_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_SYSTEM_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.UPDATE_SYSTEM_REQUEST:
      state.isSubmitLoading = true;
      state.byId = payload;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_SYSTEM_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      state.list = state.list.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload };
        }
        return item;
      });
      return;

    case Types.UPDATE_SYSTEM_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.RESET_SYSTEM_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
