import React from 'react'
import { memo } from 'react'

function ServiceItem({rotate,title,sizeComponent,backgroundTitle,backgroundCircle,active,onClick,data,children,animation}) {
    return (
        <div className={`service-item-bg`}  style={{'--rotate':`${rotate}deg` ,'--sizeComponent':`${sizeComponent}px`}}>
            {children}
            <div className='service-item-bg_main'>
                <div onClick={()=>onClick(data)} className={`service-item-content ${!!active?"active":""} ${animation?'animation':''}`}>
                    <div className='circle' style={{backgroundColor:backgroundCircle ??'white'}}>
                    </div>
                    {
                        data.image?
                            <div className='image'>
                                <img alt='image' src={data.image}></img>
                            </div>:
                        <div className='block' style={{backgroundColor:backgroundTitle ??'orange'}}>
                            <p
                              dangerouslySetInnerHTML={{__html:(new String(title)).replace(/\n/g,'<br/>' ) }}>
                            </p>
                        </div>  
                    }
                </div>
            </div>
        </div>
    )
}
ServiceItem.defaultProps = {
    rotate:0,
    title:"",
    sizeComponent:80,
    backgroundCircle:'#3A7CFF',
    backgroundTitle: 'orange',
    animation:false,
  }

export default memo(ServiceItem) 
