import React, { useState, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Tooltip, Badge } from 'antd';
import Text from 'antd/lib/typography/Text';
import { InfoCircleOutlined } from '@ant-design/icons';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import MiniWidget from './mini-widget';
import SalesAnalyticsChart from './salesanalytics-chart';
import TopProduct from './topselling-product';
import TopUser from './topuser';
import RecentActivity from './recent-activity';
import SocialSource from './socialsource';

//Import Image
import setupanalytics from '../../assets/images/setup-analytics-amico.svg';
import {
  useOverviewToday,
  useOverviewDaily,
  useOverviewMonthly,
  useOverviewQuarterly,
  useOverviewYearly,
  useChartDashboardQueryParams,
} from '~/hooks';
import MostService from './mostService';
import MostRevenueService from './mostRevenueService';
import MostPartnerAppointment from './mostPartnerAppointment';
import MostCustomerAppointment from './mostCustomerAppointment';
import './index.scss';
import TabBranch from '~/components/Common/TabBranch';

const series1 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
  }
];

const options1 = {
  fill: {
    colors: ['#3481ff']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    }
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series2 = [64];

const options2 = {
  fill: {
    colors: ['#35d2b9']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series3 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
  }
];

const options3 = {
  fill: {
    colors: ['#3481ff']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    }
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series4 = [55];

const options4 = {
  fill: {
    colors: ['#35d2b9']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series5 = [
  {
    data: [63, 25, 44, 20, 36, 25, 66, 41, 89, 40, 54]
  }
];

const options5 = {
  fill: {
    colors: ['#3481ff']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    }
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series6 = [75];

const options6 = {
  fill: {
    colors: ['#35d2b9']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const TODAY = 'today';
const MONTHLY = 'monthly';
const WEEKLY = 'weekly';
const YEARLY = 'yearly';
const QUARTERLY = 'quarterly';

const Dashboard = () => {
  const [viewMode, setViewMode] = useState(WEEKLY);
  const [query] = useChartDashboardQueryParams();
  const [overviewToday] = useOverviewToday(query);
  const [overviewDaily] = useOverviewDaily(query);
  const [overviewMonthly] = useOverviewMonthly(query);
  const [overviewQuarterly] = useOverviewQuarterly(query);
  const [overviewYearly] = useOverviewYearly(query);

  const data = useMemo(() => {
    const source =
      viewMode === TODAY
        ? overviewToday
        : viewMode === WEEKLY
        ? overviewDaily
        : viewMode === MONTHLY
        ? overviewMonthly
        : viewMode === QUARTERLY
        ? overviewQuarterly
        : overviewYearly;
    return source;
  }, [
    viewMode,
    overviewToday,
    overviewDaily,
    overviewMonthly,
    overviewQuarterly,
    overviewYearly
  ]);

  const reports = [
    {
      id: 1,
      title: (
        <Tooltip
          placement="rightTop"
          overlayClassName="ant-tooltip-inner__case-chart"
          title={'Doanh thu đơn hàng ở trạng thái Đã hoàn thành'}
        >
          <Badge
            size="small"
            color="#9B9999"
            offset={[9, 3]}
            count={<InfoCircleOutlined style={{ color: '#9B9999' }} />}
          >
            <Text type="secondary">Doanh thu hệ thống</Text>
          </Badge>
        </Tooltip>
      ),
      value: data?.totalRevenueComplete?.totalAmount || 0,
      charttype: 'bar',
      chartheight: 40,
      chartwidth: 70,
      prefix: '',
      suffix: ' VND',
      series: series1,
      options: options1
    },
    {
      id: 2,
      title: (
        <Tooltip
          placement="rightTop"
          overlayClassName="ant-tooltip-inner__case-chart"
          title={
            'Đơn hàng ở tất cả trạng thái (Mới, Xác nhận cọc, Đang thực hiện, Đã huỷ, Đã chấm dứt, Đã hoàn thành)'
          }
        >
          <Badge
            size="small"
            color="#9B9999"
            offset={[9, 3]}
            count={<InfoCircleOutlined style={{ color: '#9B9999' }} />}
          >
            <Text type="secondary">Tổng số đơn hàng</Text>
          </Badge>
        </Tooltip>
      ),
      value: data?.totalAllStatusBill?.totalSale || 0,
      charttype: 'radialBar',
      chartheight: 45,
      chartwidth: 45,
      prefix: '',
      suffix: ' đơn hàng',
      series: series2,
      options: options2
    },
    {
      id: 3,
      title: (
        <Tooltip
          placement="rightTop"
          overlayClassName="ant-tooltip-inner__case-chart"
          title={'Đơn hàng ở trạng thái Mới, Xác nhận cọc'}
        >
          <Badge
            size="small"
            color="#9B9999"
            offset={[9, 3]}
            count={<InfoCircleOutlined style={{ color: '#9B9999' }} />}
          >
            <Text type="secondary">Tổng số đơn hàng Mới</Text>
          </Badge>
        </Tooltip>
      ),
      value: data?.totalNewBill?.totalSale || 0,
      prefix: '',
      suffix: ' đơn hàng',
      charttype: 'bar',
      chartheight: 40,
      chartwidth: 70,
      series: series3,
      options: options3
    },
    {
      id: 4,
      title: (
        <Tooltip
          placement="rightTop"
          overlayClassName="ant-tooltip-inner__case-chart"
          title={'Đơn hàng ở trạng thái Đang thực hiện'}
        >
          <Badge
            size="small"
            color="#9B9999"
            offset={[9, 3]}
            count={<InfoCircleOutlined style={{ color: '#9B9999' }} />}
          >
            <Text type="secondary">Tổng số ĐH Đang thực hiện</Text>
          </Badge>
        </Tooltip>
      ),
      value: data?.totalInprogress?.totalSale || 0,
      prefix: '',
      suffix: ' đơn hàng',
      charttype: 'radialBar',
      chartheight: 45,
      chartwidth: 45,
      series: series4,
      options: options4
    },
    // {
    //   id: 5,
    //   title: (
    //     <Tooltip
    //       placement="rightTop"
    //       overlayClassName="ant-tooltip-inner__case-chart"
    //       title={'Thiết bị y tế Sẵn sàng, Đặt trước'}
    //     >
    //       <Badge
    //         size="small"
    //         color="#9B9999"
    //         offset={[9, 3]}
    //         count={<InfoCircleOutlined style={{ color: '#9B9999' }} />}
    //       >
    //         <Text type="secondary">Tổng số TBYT Sẵn sàng </Text>
    //       </Badge>
    //     </Tooltip>
    //   ),
    //   value: data?.totalMedicalEquipmentWaitingToBeUsed?.totalSale || 0,
    //   charttype: 'bar',
    //   chartheight: 40,
    //   chartwidth: 70,
    //   prefix: '',
    //   suffix: ' thiết bị',
    //   series: series5,
    //   options: options5
    // },
    // {
    //   id: 6,
    //   title: (
    //     <Tooltip placement="topRight" title={'Thiết bị y tế Đang sử dụng'} overlayClassName="ant-tooltip-inner__case-chart">
    //       <Badge
    //         size="small"
    //         color="#9B9999"
    //         offset={[9, 3]}
    //         count={<InfoCircleOutlined style={{ color: '#9B9999' }} />}
    //       >
    //         <Text type="secondary">Tổng số TBYT Đang sử dụng</Text>
    //       </Badge>
    //     </Tooltip>
    //   ),
    //   value: data?.totalMedicalEquipmentInUse?.totalSale || 0,
    //   charttype: 'radialBar',
    //   chartheight: 45,
    //   chartwidth: 45,
    //   prefix: '',
    //   suffix: ' thiết bị',
    //   series: series6,
    //   options: options6
    // }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" />
          <TabBranch useBoxShadow={false}>
            <Row>
              <div className="float-end">
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    className="text-reset"
                    id="dropdownMenuButton5"
                    caret
                    href="#"
                    style={{ float: 'right', marginBottom: '1.5rem' }}
                  >
                    <span className="fw-semibold" style={{ color: '#3481FF' }}>
                      Dữ liệu theo:
                    </span>{' '}
                    <span className="text-muted">
                      {viewMode === TODAY
                        ? 'Hôm nay'
                        : viewMode === WEEKLY
                        ? 'Tuần'
                        : viewMode === MONTHLY
                        ? 'Tháng'
                        : viewMode === QUARTERLY
                        ? 'Quý'
                        : 'Năm'}
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#" onClick={() => setViewMode(TODAY)}>
                      Hôm nay
                    </DropdownItem>
                    <DropdownItem href="#" onClick={() => setViewMode(WEEKLY)}>
                      Tuần
                    </DropdownItem>
                    <DropdownItem href="#" onClick={() => setViewMode(MONTHLY)}>
                      Tháng
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => setViewMode(QUARTERLY)}
                    >
                      Quý
                    </DropdownItem>
                    <DropdownItem href="#" onClick={() => setViewMode(YEARLY)}>
                      Năm
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <MiniWidget reports={reports} />
            </Row>
            <Row>
              <Col xl={12}>
                <SalesAnalyticsChart query={query}/>
              </Col>
            </Row>
            <Row>
              <MostService query={query} />
              <MostPartnerAppointment query={query}/>
            </Row>
            <Row>
              <MostRevenueService query={query}/>
              <MostCustomerAppointment query={query}/>
            </Row>
          </TabBranch>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
