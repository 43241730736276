import { Button, Image, Modal } from 'antd'
import React, { useCallback } from 'react'
import GetYoutubeIdImg from '~/assets/images/GetYoutubeId.png'

export default function HowToGetYoutubeId() {
    const OpenModal = useCallback(
        () => {
            Modal.confirm({
                title: "Cách lấy YoutubeId: Copy đoạn mã sau ?v= Sau đó Dán vào Ô Mã Video Youtube",
                content: <Image src={GetYoutubeIdImg} />,
                centered: true,
                icon: null,
                closable: true,
                width: 1000
            })
        },
        [],
    )
    return (
        <Button type='link' onClick={OpenModal}>
            Cách lấy Mã Youtube
        </Button>
    )
}
