import produce from 'immer';
import { get, merge } from 'lodash';
import {
  GET_WH_BILLS_REQUEST,
  GET_WH_BILLS_SUCCESS,
  GET_WH_BILLS_FAILED,
  DELETE_WH_BILL_REQUEST,
  DELETE_WH_BILL_SUCCESS,
  DELETE_WH_BILL_FAILED,
  GET_WH_BILL_REQUEST,
  GET_WH_BILL_SUCCESS,
  GET_WH_BILL_FAILED,
  UPDATE_WH_BILL_REQUEST,
  UPDATE_WH_BILL_SUCCESS,
  UPDATE_WH_BILL_FAILED,
  CUSTOMER_CANCEL_WH_BILL_REQUEST,
  CUSTOMER_CANCEL_WH_BILL_SUCCESS,
  CUSTOMER_CANCEL_WH_BILL_FAILED,
  UPDATE_BILL_ITEM_OF_BILL_REQUEST,
  UPDATE_BILL_ITEM_OF_BILL_SUCCESS,
  UPDATE_BILL_ITEM_OF_BILL_FAILED,
  CONFIRM_PREPAYMENT_WH_BILL_REQUEST,
  CONFIRM_PREPAYMENT_WH_BILL_SUCCESS,
  CONFIRM_PREPAYMENT_WH_BILL_FAILED,
  CANCEL_WH_BILL_REQUEST,
  CANCEL_WH_BILL_SUCCESS,
  CANCEL_WH_BILL_FAILED,
  RESET_STORE,
  RESET_WH_BILL_STATE,
  RESET_WH_BILL_CONFIRM_STATE,
  GET_DEPRECIATION_WH_BILLS_REQUEST,
  GET_DEPRECIATION_WH_BILLS_SUCCESS,
  GET_DEPRECIATION_WH_BILLS_FAILED
} from '../../constants/actionTypes';
import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getWhBillsFailed: undefined,
  whBills: [],


  isGetWhBillLoading: false,
  whBill: null,
  getWhBillFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  updateSuccess: null,
  updateFailed: null,

  confirmPrepaymentSuccess: null,
  confirmPrepaymentFailed: null,

  cancelWhBillSuccess: null,
  cancelWhBillFailed: null,

  isCustomerCancelLoading: false,
  customerCancelSuccess: null,
  customerCancelFailed: null,

  paging: null,

  getDepreciationsFailed: undefined,
  depreciations: [],
  isGetDepreciationsLoading : false,
  pagingDepreciations : false,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_WH_BILLS_REQUEST:
      state.isLoading = true;
      state.getWhBillsFailed = null;
      return;

    case GET_WH_BILLS_SUCCESS:
      state.isLoading = false;
      state.whBills = get(payload,'docs',[])?.map(bill => ({
        ...bill,
        isPrepayFull : get(bill,'prePayAmount',0) >= get(bill,'grandTotal',0)
      }));
      state.getWhBillsFailed = null;
      state.paging = getPaging(payload);
      return;

    case GET_WH_BILLS_FAILED:
      state.isLoading = false;
      state.whBills = [];
      state.getWhBillsFailed = payload;
      return;

      // Depreciation
    case GET_DEPRECIATION_WH_BILLS_REQUEST:
      state.isGetDepreciationsLoading = true;
      state.getDepreciationsFailed = null;
      return;

    case GET_DEPRECIATION_WH_BILLS_SUCCESS:
      state.isGetDepreciationsLoading = false;
      state.depreciations = payload.docs;
      state.getDepreciationsFailed = null;
      state.pagingDepreciations = getPaging(payload);
      return;

    case GET_DEPRECIATION_WH_BILLS_FAILED:
      state.isGetDepreciationsLoading = false;
      state.depreciations = [];
      state.getDepreciationsFailed = payload;
      return;

    case GET_WH_BILL_REQUEST:
      state.isGetWhBillLoading = true;
      state.whBill = null;
      state.getWhBillFailed = null;
      return;

    case GET_WH_BILL_SUCCESS:
      state.isGetWhBillLoading = false;
      state.whBill = payload;
      return;

    case GET_WH_BILL_FAILED:
      state.isGetWhBillLoading = false;
      state.getWhBillFailed = payload;
      return;

    case DELETE_WH_BILL_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_WH_BILL_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_WH_BILL_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case UPDATE_WH_BILL_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_WH_BILL_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.whBill = payload;
      state.whBills = state.whBills.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case UPDATE_WH_BILL_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case CUSTOMER_CANCEL_WH_BILL_REQUEST:
      state.isCustomerCancelLoading = true;
      state.customerCancelSuccess = null;
      state.customerCancelFailed = null;
      return;

    case CUSTOMER_CANCEL_WH_BILL_SUCCESS:
      state.isCustomerCancelLoading = false;
      state.customerCancelSuccess = payload;
      state.whBill = payload;
      state.whBills = state.whBills.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case CUSTOMER_CANCEL_WH_BILL_FAILED:
      state.isCustomerCancelLoading = false;
      state.customerCancelFailed = payload;
      return;

    case UPDATE_BILL_ITEM_OF_BILL_REQUEST:
      state.isUpdatingBillItemOfBill = true;
      state.updateBillItemOfBillSuccess = null;
      state.updateBillItemOfBillFailed = null;
      return;

    case UPDATE_BILL_ITEM_OF_BILL_SUCCESS:
      state.isUpdatingBillItemOfBill = false;
      state.updateBillItemOfBillSuccess = payload;
      // state.whBill = payload;
      state.whBills = state.whBills.map(item => {
        if (item._id === payload.whBillId) {
          return {
            ...item,
            billItems: get(item, "billItems")?.map(item => {
              if (item._id === payload._id) {
                return {
                  ...item,
                  ...payload,
                }
              }
              return item;
            })
          };
        }
        return item
      })
      return;

    case UPDATE_BILL_ITEM_OF_BILL_FAILED:
      state.isUpdatingBillItemOfBill = false;
      state.updateBillItemOfBillFailed = payload;
      return;

    case CONFIRM_PREPAYMENT_WH_BILL_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case CONFIRM_PREPAYMENT_WH_BILL_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.confirmPrepaymentSuccess=payload
      state.whBill = payload;
      state.whBills = state.whBills.map(item => {
        if (item._id === payload._id) {
          return merge({}, item, payload);
        }
        return item
      })
      return;

    case CONFIRM_PREPAYMENT_WH_BILL_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case CANCEL_WH_BILL_REQUEST:
      state.isSubmitLoading = true;
      state.cancelWhBillSuccess = null;
      state.cancelWhBillFailed = null;
      return;

    case CANCEL_WH_BILL_SUCCESS:
      state.isSubmitLoading = false;
      state.cancelWhBillSuccess = payload;
      // state.whBill = payload;
      state.whBills = state.whBills.map(item => {
        if (item._id === payload._id) {
          return { ...item, status: 'CANCELLED' };
        }
        return item
      })
      return;

    case CANCEL_WH_BILL_FAILED:
      state.isSubmitLoading = false;
      state.cancelWhBillFailed = payload;
      return;
    case RESET_WH_BILL_CONFIRM_STATE:
      state.updateSuccess = null;
      state.confirmPrepaymentSuccess=null;
      return;

    case RESET_STORE:
    case RESET_WH_BILL_STATE:
      return initState;

    default:
      return;
  }
}, initState);
