import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createGiveCoupon,
  deleteGiveCoupon,
  getGiveCoupon,
  getGiveCoupons,
  resetGiveCouponState,
  updateGiveCoupon
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.giveCoupon[key];
const pagingSelector = getSelector('paging');
export const useGiveCouponPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetGiveCouponSelector = getSelector('isGetGiveCouponLoading');
const getGiveCouponsFailedSelector = getSelector('getGiveCouponsFailed');
const getGiveCouponFailedSelector = getSelector('getGiveCouponFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const GiveCouponsSelector = getSelector('GiveCoupons');
const GiveCouponSelector = getSelector('GiveCoupon');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useGiveCouponQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const activity = query.get('activity');
  const coupon = query.get('coupon');
  const code = query.get('code');
  const mode = query.get('mode');
  // const keyword = query.get('keyword')
  const createGiveCouponSuccess = useSelector(createSuccessSelector);
  // const updateGiveCouponSuccess = useSelector(updateSuccessSelector);
  const deleteGiveCouponSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      // keyword,
      startDate,
      endDate,
      activity,
      coupon,
      code,
      mode
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    createGiveCouponSuccess,
    // updateGiveCouponSuccess,
    deleteGiveCouponSuccess,
    // keyword,
    startDate,
    endDate,
    activity,
    coupon,
    code
  ]);
};

export const useUpdateGiveCouponParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.coupon.give || '/give-coupon',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};
export const useGiveCoupons = (query) => {
  return useFetchByParam({
    action: getGiveCoupons,
    loadingSelector,
    dataSelector: GiveCouponsSelector,
    failedSelector: getGiveCouponsFailedSelector,
    param: query
  });
};
export const useGiveCoupon = (id) => {
  return useFetchByParam({
    action: getGiveCoupon,
    loadingSelector: loadinggetGiveCouponSelector,
    dataSelector: GiveCouponSelector,
    failedSelector: getGiveCouponFailedSelector,
    param: id
  });
};
export const useCreateGiveCoupon = (callback) => {
  
  useSuccess(createSuccessSelector, 'Tạo mới Chương trình khuyến mãi thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới Chương trình khuyến mãi thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createGiveCoupon
  });
};
export const useUpdateGiveCoupon = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât Chương trình khuyến mãi thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateGiveCoupon
  });
};
export const useDeleteGiveCoupon = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Chương trình khuyến mãi thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật Chương trình khuyến mãi thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteGiveCoupon
  });
};

export const useResetGiveCoupon = () => {
  useResetState(resetGiveCouponState);
};
