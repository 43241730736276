
import { Col, Form, Input, Radio, Row, Switch } from 'antd';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { LANGUAGE } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useUiClients, useUpdateUiClient } from '~/hooks/UiClient';
import { handleSubmit } from '~/hooks/utils';
import { Breadcrumb } from '../Common';
import UploadImage from '../Utils/UploadImage';
import LoadingWhBill from '../WhBill/LoadingWhBill';
import BtnAdd from './BtnAdd';
import BtnRemove from './BtnRemove';
import BtnUpdate from './BtnUpdate';
const key = 'softwareTarget';
const SoftwareTarget = () => {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_BANNER);
    const [form] = Form.useForm();
    const [UiClient, isLoading] = useUiClients(key);
    const [isSubmitLoading, update] = useUpdateUiClient();
    const initValue = useMemo(() => get(UiClient, ['value', key]), [UiClient])
    const disabled = useMemo(() => !canUpdate, [canUpdate]);
    const [language, setLanguage] = useState(LANGUAGE.VI);
    const onSubmit = handleSubmit();
    const onFinish = (values) => {
        update({ key, ...values })
    };

    useEffect(() => {
        form.setFieldsValue({
            ...initValue
        })
    }, [initValue]);
    return (
        <div className="wh-service-form page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb title={'Đối tượng sử dụng phần mềm'} routes={[{ path: '', title: 'CMS' }]} />
                {isLoading && <LoadingWhBill open={true} />}
                <Form
                    form={form}
                    autoComplete="off"
                    initialValues={{}}
                    scrollToFirstError
                    requiredMark={false}
                    onFinish={onFinish}
                    onFinishFailed={onSubmit}
                    labelCol={{ sm: 24, md: 24, lg: 2 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 22 }}
                >
                    <Row justify="end" style={{ marginBottom: 20 }}>
                        <Radio.Group
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.VI}
                            >
                                VI
                            </Radio.Button>
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.EN}
                            >
                                EN
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                    <Form.Item label="Hiển thị" valuePropName="checked" name={['active']} >
                        <Switch disabled={disabled} />
                    </Form.Item>
                    <Form.Item name={["title", LANGUAGE.VI]} hidden={language === LANGUAGE.EN} label="Tiêu đề VI" >
                        <Input disabled={disabled} placeholder='Nhập tiêu đề' />
                    </Form.Item>
                    <Form.Item name={["title", LANGUAGE.EN]} hidden={language === LANGUAGE.VI} label="Tiêu đề EN" >
                        <Input disabled={disabled} placeholder='Nhập tiêu đề' />
                    </Form.Item>
                    <Form.Item label="Nội dung">
                        <Form.List name={["content"]}>
                            {(fields, { add, remove }) => {
                                return <Row gutter={16}>
                                    {fields?.map((field, index) => {
                                        const image = form.getFieldValue(['content', index, 'image']);
                                        const contentAtIndex = form.getFieldValue(['content', index]);
                                        const onChange = (url) => {
                                            const content = [...form.getFieldValue(['content'])];
                                            content?.splice(index, 1, { ...contentAtIndex, image: url });
                                            form.setFieldsValue({ content });
                                        }
                                        return <Col span={24}>
                                            <Row gutter={16}>
                                                <Col span={6}>
                                                    <Form.Item name={[index, 'image']} >
                                                        <UploadImage disabled={(initValue?.content?.length > index) && disabled} onChange={onChange} imgUrl={image} title={''} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item name={[index, 'description', LANGUAGE.VI]} hidden={language === LANGUAGE.EN} >
                                                        <Input disabled={(initValue?.content?.length > index) && disabled} placeholder='Mô tả VI' />
                                                    </Form.Item>
                                                    <Form.Item name={[index, 'description', LANGUAGE.EN]} hidden={language === LANGUAGE.VI} >
                                                        <Input disabled={(initValue?.content?.length > index) && disabled} placeholder='Mô tả EN' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <BtnRemove onClick={() => remove(index)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    })}
                                    <Col span={6}>
                                        <BtnAdd onClick={() => add()} />
                                    </Col>
                                </Row>
                            }}
                        </Form.List>
                    </Form.Item>

                    <BtnUpdate loading={isSubmitLoading} />
                </Form>
            </div>
        </div>
    );
};
export default SoftwareTarget;
