import { Button } from 'antd'
import React from 'react'
import POLICIES from '~/constants/policy'
import { WithPermission } from '../Common'

export default function BtnRemove({ onClick }) {
    return (
        <WithPermission permission={POLICIES.DELETE_BANNER}>
            <Button type='primary' danger onClick={onClick}>Xoá</Button>
        </WithPermission>
    )
}
