export const GET_LIST_SOFTWARES_REQUEST =
    'GET_LIST_SOFTWARES_REQUEST';
export const GET_LIST_SOFTWARES_SUCCESS =
    'GET_LIST_SOFTWARES_SUCCESS';
export const GET_LIST_SOFTWARES_FAILED = 'GET_LIST_SOFTWARES_FAILED';

export const GET_LIST_SOFTWARE_REQUEST = 'GET_LIST_SOFTWARE_REQUEST';
export const GET_LIST_SOFTWARE_SUCCESS = 'GET_LIST_SOFTWARE_SUCCESS';
export const GET_LIST_SOFTWARE_FAILED = 'GET_LIST_SOFTWARE_FAILED';

export const CREATE_LIST_SOFTWARE_REQUEST =
    'CREATE_LIST_SOFTWARE_REQUEST';
export const CREATE_LIST_SOFTWARE_SUCCESS =
    'CREATE_LIST_SOFTWARE_SUCCESS';
export const CREATE_LIST_SOFTWARE_FAILED =
    'CREATE_LIST_SOFTWARE_FAILED';

export const UPDATE_LIST_SOFTWARE_REQUEST =
    'UPDATE_LIST_SOFTWARE_REQUEST';
export const UPDATE_LIST_SOFTWARE_SUCCESS =
    'UPDATE_LIST_SOFTWARE_SUCCESS';
export const UPDATE_LIST_SOFTWARE_FAILED =
    'UPDATE_LIST_SOFTWARE_FAILED';


export const DELETE_LIST_SOFTWARE_REQUEST =
    'DELETE_LIST_SOFTWARE_REQUEST';
export const DELETE_LIST_SOFTWARE_SUCCESS =
    'DELETE_LIST_SOFTWARE_SUCCESS';
export const DELETE_LIST_SOFTWARE_FAILED =
    'DELETE_LIST_SOFTWARE_FAILED';


export const RESET_LIST_SOFTWARE_STATE = 'RESET_LIST_SOFTWARE_STATE';
export const RESET_LIST_SOFTWARE_ACTION = 'RESET_LIST_SOFTWARE_ACTION';
