import { CloseCircleFilled, InfoCircleOutlined, RollbackOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Popconfirm, Row, Table, Tooltip } from 'antd';
import TooltipTitle from '~/components/Common/TooltipTitle';
import React, { useState } from 'react';
import './index.scss'
import { BaseBorderBox } from '~/components/Common';
import { get } from 'lodash';
import { TYPE_WAREHOUSE } from '~/constants/defaultValue';
import InfomationProduct from '~/components/WhAppointment/WhAppointmentsByBlockTable/components/InfomationProduct';
export default function TableListProduct({ listProduct, setListProduct, id,loading,handleByPartner }) {
    const [idViewProduct, setIdViewProduct] = useState(null)
    const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);
    const handleOpenModalViewProduct = (id) => {
        setIsOpenViewProduct(true);
        setIdViewProduct(id);
    };
    const handleCloseModalViewProduct = () => {
        setIsOpenViewProduct(false);
        setIdViewProduct(null);
    };
    const removeProduct = (index) => {
        const cloneListProduct = [...listProduct];
        cloneListProduct.splice(index, 1);
        setListProduct(cloneListProduct);
    }
    const columns = [
        {
            title: 'Tên thiết bị',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mã cuộc hẹn',
            dataIndex: 'whAppointmentCode',
            key: 'whAppointmentCode',
        },
        {
            title: 'Số lần',
            dataIndex: 'actualQuantity',
            key: 'actualQuantity',
            align : 'center',
        },
    ]
    if(!handleByPartner){
        columns.push({
            title: 'Thao tác',
            key: 'action',
            align: 'center',
            dataIndex: 'productId',
            render: (productId, record, index) => <Row wrap={false} justify='space-between'>
            { get(record,'medicalDeviceInventory.type') === TYPE_WAREHOUSE.WORLDHEALTH &&  <Col>
                <Tooltip zIndex={9999} title="Xem chi tiết thiết bị">
                    <Button onClick={() => handleOpenModalViewProduct(productId)} type='text' className='d-flex align-items-center'>
                        <InfoCircleOutlined />
                    </Button>
                </Tooltip>

            </Col>}
            <Col style={{marginLeft : 'auto'}}>
                <Tooltip zIndex={9999} title="Hoàn trả công cụ">
                    <Popconfirm
                        disabled={!id}
                        zIndex={9999}
                        title="Bạn có chắc muốn hoàn trả công cụ?"
                        onConfirm={() => removeProduct(index)}
                    >
                        <Button type={'text'} className='d-flex align-items-center'>
                            <CloseCircleFilled style={{ color: !id ? "#c4c4c4" :'red' }} />
                        </Button>
                    </Popconfirm>
                </Tooltip>
            </Col>

        </Row>
        })
    };
    console.log(listProduct,'listProduct');
    return (
        <>

            <BaseBorderBox
                title={<TooltipTitle count={<InfoCircleOutlined />} titleTooltip='Danh sách máy từ những buổi hẹn được chọn' content='Danh sách CCDC/NVL xác nhận' />}
            >
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={listProduct}
                    size='small'
                    loading={loading}
                />
            </BaseBorderBox>
            <Modal
                destroyOnClose
                title={`Thông tin thiết bị`}
                width={700}
                visible={isOpenViewProduct}
                closable={false}
                footer={null}
                onCancel={handleCloseModalViewProduct}>
                <InfomationProduct id={idViewProduct} />
            </Modal>
        </>
    )
}
