import produce from 'immer';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getBonusIntroCustomersFailed: null,
  getBonusIntroCustomerFailed:null,
  bonusIntroCustomers: [],
  bonusIntroCustomer: null,
  isSubmitLoading : false,
  isGetBonusIntroCustomerLoading: false,
  BonusIntroCustomer: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};
export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_BONUS_INTRO_CUSTOMERS_REQUEST:
      state.isLoading = true;
      state.getBonusIntroCustomersFailed = null;
      return;

    case Types.GET_BONUS_INTRO_CUSTOMERS_SUCCESS:
      state.isLoading = false;
      state.bonusIntroCustomers = payload.docs;
      state.getBonusIntroCustomersFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_BONUS_INTRO_CUSTOMERS_FAILED:
      state.isLoading = false;
      state.bonusIntroCustomers = [];
      state.getBonusIntroCustomersFailed = payload;
      return;
    case Types.GET_BONUS_INTRO_CUSTOMER_REQUEST:
      state.isGetBonusIntroCustomerLoading = true;
      state.bonusIntroCustomer = null
      state.getBonusIntroCustomerFailed = null;
      return;

    case Types.GET_BONUS_INTRO_CUSTOMER_SUCCESS:
      state.isGetBonusIntroCustomerLoading = false;
      state.bonusIntroCustomer = payload;
      state.getBonusIntroCustomerFailed = null;
      return;

    case Types.GET_BONUS_INTRO_CUSTOMER_FAILED:
      state.isGetBonusIntroCustomerLoading = false;
      state.bonusIntroCustomer = null;
      state.getBonusIntroCustomerFailed = payload;
      return;
    case Types.CREATE_BONUS_INTRO_CUSTOMER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_BONUS_INTRO_CUSTOMER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_BONUS_INTRO_CUSTOMER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_BONUS_INTRO_CUSTOMER_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_BONUS_INTRO_CUSTOMER_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.bonusIntroCustomer = payload;
        return;
  
      case Types.UPDATE_BONUS_INTRO_CUSTOMER_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_BONUS_INTRO_CUSTOMER_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_BONUS_INTRO_CUSTOMER_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_BONUS_INTRO_CUSTOMER_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_BONUS_INTRO_CUSTOMER_STATE:
      return initState;

    default:
      return;
  }
}, initState);
