import {
  GET_WH_SERVICES_REQUEST,
  GET_WH_SERVICE_REQUEST,
  CREATE_WH_SERVICE_REQUEST,
  UPDATE_WH_SERVICE_REQUEST,
  DELETE_WH_SERVICE_REQUEST,
  RESET_WH_SERVICE_STATE,
  COPY_WH_SERVICE_REQUEST,
  GET_WH_SERVICES_BRANCH_REQUEST,
  GET_WH_SERVICES_AVAILABLE_REQUEST,
  UPDATE_WH_SERVICE_BRANCH_REQUEST,
  CREATE_WH_SERVICE_BRANCH_REQUEST,
  DELETE_WH_SERVICE_BRANCH_REQUEST,
  RESET_WH_SERVICE_BRANCH_STATE,
  ACCEPT_WH_SERVICE_BRANCH_REQUEST,
  GET_WH_SERVICES_DASHBOARD_REQUEST,
  // GET_WH_SERVICE_GROUPS_REQUEST
} from '../../constants/actionTypes';

export const getWhServices = query => ({
  type: GET_WH_SERVICES_REQUEST,
  payload: query
});

export const getWhServicesBranch = query => ({
  type: GET_WH_SERVICES_BRANCH_REQUEST,
  payload: query
});
export const getWhServicesDasboard = query => ({
  type: GET_WH_SERVICES_DASHBOARD_REQUEST,
  payload: query
})
export const getWhServicesAvailable = query => ({
  type: GET_WH_SERVICES_AVAILABLE_REQUEST,
  payload: query
});

export const getWhService = id => ({
  type: GET_WH_SERVICE_REQUEST,
  payload: id
});

export const createWhService = instance => ({
  type: CREATE_WH_SERVICE_REQUEST,
  payload: instance
});

export const createWhServiceBranch = instance => ({
  type: CREATE_WH_SERVICE_BRANCH_REQUEST,
  payload: instance
});

export const updateWhService = instance => ({
  type: UPDATE_WH_SERVICE_REQUEST,
  payload: instance
});

export const copyWhService = instance => ({
  type: COPY_WH_SERVICE_REQUEST,
  payload: instance
});

export const updateWhServiceBranch = (instance) => ({
  type: UPDATE_WH_SERVICE_BRANCH_REQUEST,
  payload: instance
});

export const acceptWhServiceBranch = (payload) => ({
  type: ACCEPT_WH_SERVICE_BRANCH_REQUEST,
  payload
});

export const deleteWhService = id => ({
  type: DELETE_WH_SERVICE_REQUEST,
  payload: id
});

export const deleteWhServiceBranch = id => ({
  type: DELETE_WH_SERVICE_BRANCH_REQUEST,
  payload: id
});

export const resetWhServiceState = () => ({
  type: RESET_WH_SERVICE_STATE
});

export const resetWhServiceBranchState = () => ({
  type: RESET_WH_SERVICE_BRANCH_STATE
});

// export const getWhServiceGroups = () => ({
//   type: GET_WH_SERVICE_GROUPS_REQUEST
// });
