import requester from './requester';

const customerType = {
  getAll: query => requester.get('/customer-level', query),
  getById: id => {
    return requester.get(`/customer-level/${id}?raw=true`)
  },
  delete: id => requester.delete(`/customer-level/${id}`),
  create: customerType => requester.post('/customer-level', customerType),
  update: customerType =>
    requester.put(`/customer-level/${customerType.id}`, customerType)
};

export default customerType;
