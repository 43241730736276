import PropTypes from 'prop-types';
import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import ReactApexChart from 'react-apexcharts';
import { Statistic } from 'antd';
const MiniWidget = (props) => {
  return (
    <React.Fragment>
      {props.reports.map((report, key) => (
        <Col key={key}>
          <Card className="boxStatistc">
            <Statistic
              loading={report.loading}
              title={report.title}
              value={report.value}
              valueStyle={{ color: report.color ? report.color : '#495057' }}
              prefix={report.prefix}
              suffix={report.suffix}
            />
            <div className="float-end mt-2" style={{margin: "auto"}}>
              <ReactApexChart
                options={report.options}
                series={report.series}
                type={report.charttype}
                height={report.chartheight}
                width={report.chartwidth}
              />
            </div>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default MiniWidget;

MiniWidget.propTypes = {
  reports: PropTypes.array
};
