import produce from 'immer';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  branches: null,
  isGetBranchesLoading: false,
  getBranchesFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_EVALUATES_REQUEST:
      state.isLoading = true;
      state.list = [];
      state.getListFailed = null;
      return;

    case Types.GET_EVALUATES_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;  //add .docs
      state.paging = getPaging(payload);
      return;

    case Types.GET_EVALUATES_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      // state.list = [];
      return;

    case Types.GET_EVALUATE_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_EVALUATE_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_EVALUATE_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_EVALUATE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_EVALUATE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      // state.list = state.list.concat(payload);
      return;

    case Types.CREATE_EVALUATE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_EVALUATE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_EVALUATE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      state.list = state.list.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      });
      return;

    case Types.UPDATE_EVALUATE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_EVALUATE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_EVALUATE_SUCCESS:
      state.deleteSuccess = payload;
      state.isLoading = false;
      return;

    case Types.DELETE_EVALUATE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_EVALUATE_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
