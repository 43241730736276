import { get, omit, pick } from 'lodash';
import requester from './requester';

const workFlow ={
    // getAllList : (query) => requester.get(`/api/v1/board`,query),
    // createWorkList : (device) => requester.post('/api/v1/board', device),
    // deleteWorkList : ({id}) => requester.delete(`/api/v1/board/${id}`),
    getListWorkFlow : (query) => requester.get(`/board-config`,query),
    getListBoardConfig:(query)=> requester.get(`board-config-parent-board/${query.sprintId}`),
    getListWorkFlowById : (id) => requester.get(`/board-config/${id}`),
    createWorkFlow : (device) => requester.post('/board-config', device),
    updateWorkFlow : (device) => requester.put(`/board-config/${device.id}`, device),
    deleteWorkFlow : (id) => requester.delete(`/board-config/${id}`),
    //broad
    getAll: (query) => requester.get('/board',query),
    getById: (id) => requester.get(`/board/${id}`),
    create: (device) => requester.post('/board', device),
    update: (device) => requester.put(`/board/${device.id}`, device),
    delete: ({id}) => requester.delete(`/board/${id}`),
    //task APi
    getAllTask: (query) => requester.get(`/task-item-board-config/${query.id}`,omit(query,['id'])),
    
    getByIdTask: (id) => requester.get(`/task-item/${id}`),
    deleteTask: (id) => requester.delete(`/task-item/${id}`),
    createTask: (device) => requester.post('/task-item', device),
    updateTask: ({id,...data}) => requester.put(`/task-item/${id}`, data),
    updateProgressTask: ({id,...data}) => requester.put(`/task-item-progress/${id}`, data),
    // task detail
    // getAllTaskDetail: (id) => requester.get(`/spent-time/${id}`),
    // getListHistoryTaskDetail: (query) => requester.get(`/spent-time`,query),
    // getByIdTaskDetail: (id) => requester.get(`/spent-time/${id}`),
    // deleteTaskDetail: (id) => requester.delete(`/spent-time/${id}`),
    // createTaskDetail: (device) => requester.post('/spent-time', device),
    // updateTaskDetail: (device) => requester.put(`/spent-time/${device.id}`, device),
    //managers||staffs
    getAllManagers: (query) => requester.get(`/manager-user-board`,query),
    getAllStaffs: (query) => requester.get(`/member-user-board`,query),
    getAllManagersByIdBoard: (id) => requester.get(`/auth-todo-list-role-admin/${id}`),
    getAllStaffsByIdBoard: (id) => requester.get(`/auth-todo-list-role-user/${id}`),
    //historyActivity
    // getAllHistory: (query) => requester.get(`/history-activity`,query),
    getHistoryTaskById: (id) => requester.get(`history-todo-task/${id}`),

    getBoardGroup: (id) => requester.get(`/group-board/`),

    //get task iin sprint
    getTaskInSprints: (query) => requester.get(`/task-item-board-config-all-sprint/${get(query,'id')}`, omit(query, ['id'])),
    getTaskInSprint: (id) => requester.get(`/task-item-board-config-all-sprint/${id}`),
    copyTask: ({id,...data}) => requester.post(`/task-item-copy/${id}`,data),
    //filter
    filterTask: (query) => {
       return requester.get(`task-item-board-config-search/${get(query,'sprintId')}`, omit(query, ['sprintId']))},

    pushComment:({taskId,content})=> requester.post(`/process-add-comment-task-item/${taskId}`,{content}),
    pushEmotion:({taskId,user,commentId})=> requester.put(`/process-emotion-comment-task-item/${taskId}?commentId=${commentId}`,user),
    deleteComment:({taskId,commentId})=> requester.delete(`/process-delete-comment-task-item/${taskId}?commentId=${commentId}`),
    updateCommentById:({taskId,content,commentId})=> requester.put(`/process-update-comment-task-item/${taskId}?commentId=${commentId}`,{content}),
    
    
    //search Task
    
    searchTaskByBoardId:({boardId,keyword,taskId})=> requester.post(`/search-board-task-item/${boardId}`,{keyword,taskId}),
    getRelationTask: (taskId) => requester.get(`/relation-task-item/${taskId}`),
    //taskId in body main task relation with task current
    updateRelationTask: (body) => requester.put(`/update-relation-task-item/${body.taskItemId}`,pick(body,['typeRequest','taskId'])),
}

export default workFlow;
