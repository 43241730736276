import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import {
  GET_USER_EMPLOYEES_REQUEST,
  GET_USER_EMPLOYEES_SUCCESS,
  GET_USER_EMPLOYEES_FAILED,
  GET_USER_EMPLOYEE_REQUEST,
  GET_USER_EMPLOYEE_SUCCESS,
  GET_USER_EMPLOYEE_FAILED,
  GET_PROFILE_ME_REQUEST,
  GET_PROFILE_ME_SUCCESS,
  GET_PROFILE_ME_FAILED,
  CREATE_USER_EMPLOYEE_REQUEST,
  CREATE_USER_EMPLOYEE_SUCCESS,
  CREATE_USER_EMPLOYEE_FAILED,
  UPDATE_USER_EMPLOYEE_REQUEST,
  UPDATE_USER_EMPLOYEE_SUCCESS,
  UPDATE_USER_EMPLOYEE_FAILED,
  DELETE_USER_EMPLOYEE_REQUEST,
  DELETE_USER_EMPLOYEE_SUCCESS,
  DELETE_USER_EMPLOYEE_FAILED,
  GET_ADDRESS_USER_REQUEST,
  GET_ADDRESS_USER_SUCCESS,
  GET_ADDRESS_USER_FAILED,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE_REQUEST,
} from '~/constants/actionTypes';

function* getUserEmployees({ payload: query }) {
  try {
    const response = yield call(Api.userEmployee.getAll, query);
    yield put({ type: GET_USER_EMPLOYEES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_USER_EMPLOYEES_FAILED, payload: error });
  }
}

function* getUserEmployee({ payload: id }) {
  try {
    const { data } = yield call(Api.userEmployee.getById, id ?? '');
    yield put({ type: GET_USER_EMPLOYEE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_USER_EMPLOYEE_FAILED, payload: error });
  }
}
function* getProfileMe() {
  try {
    const { data } = yield call(Api.userEmployee.getProfileMe);
    yield put({ type: GET_PROFILE_ME_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_PROFILE_ME_FAILED, payload: error });
  }
}

function* getAddressUser({ payload: id }) {
  try {
    const data = yield call(Api.userEmployee.getAddressUserById, id ?? '');
    yield put({ type: GET_ADDRESS_USER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_ADDRESS_USER_FAILED, payload: error });
  }
}

function* createUserEmployee(action) {
  try {
    const data = yield call(Api.userEmployee.create, action.payload);
    yield put({ type: CREATE_USER_EMPLOYEE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_USER_EMPLOYEE_FAILED, payload: error });
  }
}

function* updateUserEmployee(action) {
  try {
    const data = yield call(Api.userEmployee.update, action.payload);
    yield put({ type: UPDATE_USER_EMPLOYEE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_USER_EMPLOYEE_FAILED, payload: error });
  }
}

function* updateProfile(action) {
  try {
    const data = yield call(Api.userEmployee.updateProfile, action.payload);
    yield put({ type: UPDATE_PROFILE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_PROFILE_FAILED, payload: error });
  }
}

function* deleteUserEmployee({ payload }) {
  try {
    yield call(Api.userEmployee.delete, payload);
    yield put({ type: DELETE_USER_EMPLOYEE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_USER_EMPLOYEE_FAILED, payload: error });
  }
}

export default function* userEmployee() {
  yield takeLatest(GET_USER_EMPLOYEES_REQUEST, getUserEmployees);
  yield takeLatest(GET_USER_EMPLOYEE_REQUEST, getUserEmployee);
  yield takeLatest(DELETE_USER_EMPLOYEE_REQUEST, deleteUserEmployee);
  yield takeLatest(CREATE_USER_EMPLOYEE_REQUEST, createUserEmployee);
  yield takeLatest(UPDATE_USER_EMPLOYEE_REQUEST, updateUserEmployee);
  yield takeLatest(GET_ADDRESS_USER_REQUEST, getAddressUser);
  yield takeLatest(GET_PROFILE_ME_REQUEST, getProfileMe);
  yield takeLatest(UPDATE_PROFILE_REQUEST, updateProfile);
}
