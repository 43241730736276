import { DeleteOutlined } from '@ant-design/icons';
import { Button, Empty, Table } from 'antd'
import { get } from 'lodash'
import React from 'react'
import { BaseBorderBox } from '~/components/Common';

export default function TableSelectWhAppointment({ dataSource, handleRemove, id, loading }) {
    const columns = [
        {
            title: "Mã cuộc hẹn",
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: "Tên đối tác",
            dataIndex: 'whPartner',
            key: 'whPartner',
            render: (whPartner) => get(whPartner, 'name', '')
        },
        {
            title: "",
            key: 'action',
            render: (item, record, index) => <Button disabled={id} type='text' onClick={() => handleRemove(index)}><DeleteOutlined /></Button>
        }
    ]
    return (
        <div style={{ marginTop: 20 }}>
            <BaseBorderBox title={"Danh sách cuộc hẹn đã chọn"}>
                <Table
                    dataSource={dataSource}
                    pagination={false}
                    columns={columns}
                    size='small'
                    loading={loading}
                    locale={{
                        emptyText: <Empty description='Không có dữ liệu được chọn'>
                        </Empty>
                    }}
                />
            </BaseBorderBox>
        </div>
    )
}
