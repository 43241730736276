import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getMedicines({ payload: query }) {
    try {
      const response = yield call(Api.whMedicines.getAll, query);
      yield put({ type: Types.GET_WH_MEDICINES_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WH_MEDICINES_FAILED, payload: error });
    }
  }
function* getMedicine({ payload: id }) {
    try {
      const response = yield call(Api.whMedicines.getOne, id);
      yield put({ type: Types.GET_WH_MEDICINE_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WH_MEDICINE_FAILED, payload: error });
    }
  }
  function* createMedicine(action) {
    try {
      const data = yield call(Api.whMedicines.create, action.payload);
      yield put({ type: Types.CREATE_WH_MEDICINE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_WH_MEDICINE_FAILED, payload: error });
    }
  }
  function* updateMedicine(action) {
    try {
      const data = yield call(Api.whMedicines.update, action.payload);
      yield put({ type: Types.UPDATE_WH_MEDICINE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_WH_MEDICINE_FAILED, payload: error });
    }
  }
  function* deleteMedicine(action) {
    try {
      const data = yield call(Api.whMedicines.delete, action.payload);
      yield put({ type: Types.DELETE_WH_MEDICINE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_WH_MEDICINE_FAILED, payload: error });
    }
  }
export default function* Medicines() {
    yield takeLatest(Types.GET_WH_MEDICINES_REQUEST, getMedicines);
    yield takeLatest(Types.GET_WH_MEDICINE_REQUEST, getMedicine);
    yield takeLatest(Types.CREATE_WH_MEDICINE_REQUEST, createMedicine);
    yield takeLatest(Types.UPDATE_WH_MEDICINE_REQUEST, updateMedicine);
    yield takeLatest(Types.DELETE_WH_MEDICINE_REQUEST, deleteMedicine);
  }