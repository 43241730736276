import { HomeTwoTone } from '@ant-design/icons'
import { Button, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { PATH_APP } from '~/routes/paths'

export default function Page403() {

  return (
    <div className="page-wraper page-content">
      <div style={{ textAlign: 'center' }} className="container-fluid">
        <Result
          status="403"
          title="403"
          subTitle="Xin lỗi bạn không có quyền vào trang này"
        />
        <Link to={PATH_APP.main.home}>
        <Button type='primary' icon={<HomeTwoTone />}>
          Trở về trang chủ
        </Button>
        </Link>
      </div>
    </div>

  )
}
