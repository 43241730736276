import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import moment from 'moment';
import { Button, Col, Modal, Row, Space, Tag, notification } from 'antd';
import { CheckCircleFilled, ExclamationCircleOutlined, PictureOutlined } from '@ant-design/icons';
import { get, isEmpty, set } from 'lodash';

import api from '~/api';
import { notifyHttpError } from '~/utils/helper';

import UploadImage from '~/components/Utils/UploadImage';

import "./index.scss";
import confirm from 'antd/lib/modal/confirm';
import { WH_APPOINTMENT_STATUS } from '~/constants/defaultValue';
import { ToastrCustom } from '~/hooks/utils';

const CheckInOut = forwardRef(({
  record, onWhAppointmentCheckInOut
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState({
    checkIn: get(record, "checkIn"),
    checkOut: get(record, "checkOut"),
  });

  useEffect(() => {
    setData({
      checkIn: get(record, "checkIn"),
      checkOut: get(record, "checkOut"),
    })
  }, [record]);

  useImperativeHandle(ref, () => ({
    openCheckInOutModal(type) {
      // Validate 
      if (get(record, "status") === WH_APPOINTMENT_STATUS.CANCELLED) {
        ToastrCustom("Buổi hẹn đã bị huỷ", 'error');
        return;
      };
    
      switch (type) {
        case 'checkIn':

          break;
        case 'checkOut':
          // Check if Partner not Used enough QuantityUsed
          const listNotEnough = get(record, 'listConfirmed', [])?.filter(product => get(product, 'quantityUsed', [])?.length < get(product, 'actualQuantity', 0));
          if(listNotEnough?.length > 0){
            confirm({
              title:
                'Những công cụ sau chưa sử dụng đủ số lần, bạn có chắc là đã Check sử dụng sản phầm hay chưa?',
              content: listNotEnough?.map(item => get(item,'product.name',''))?.join(' , '),
              okText: 'Có, Tiếp tục CheckOut',
              icon: <ExclamationCircleOutlined />,
              cancelText: 'Không, Quay trở lại',
              onOk() {
                setIsOpen(true);
              },
              onCancel(){
  
              },
            });
            return;
          }


        default:
          break;
      }
      setIsOpen(true);
    }
  }));

  const formatTime = (time) => moment(time).format("HH:mm - DD/MM/YYYY")

  const checkInOutWhAppointment = async (whAppointment, action) => {
    let response;
    try {
      switch (action) {
        case "checkIn":
          response = await api.whAppointment.checkIn(whAppointment);
          break;
        case "checkOut":
          response = await api.whAppointment.checkOut(whAppointment);
          break;
      }
      onWhAppointmentCheckInOut(response, action);
    } catch (error) {
      notifyHttpError(error);
    }
  }

  const showError = (error) => {
    if (error.code) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          notification.error({
            message: "User denied the request for Geolocation.",
            placement: "bottomRight",
          })
          break;
        case error.POSITION_UNAVAILABLE:
          notification.error({
            message: "Location information is unavailable.",
            placement: "bottomRight",
          })
          break;
        case error.TIMEOUT:
          notification.error({
            message: "The request to get user location timed out.",
            placement: "bottomRight",
          })
          break;
        case error.UNKNOWN_ERROR:
          notification.error({
            message: "An unknown error occurred.",
            placement: "bottomRight",
          })
          break;
      }
    } else if (error.message) {
      notification.error({
        message: error.message,
        placement: "bottomRight",
      })
    }
  }

  const getLocation = async () => new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
        ,
        (error) => reject(error)
      );
    } else {
      reject({ message: "Geolocation is not supported by this browser." })
    }
  })

  // FIXME: refactor this function
  const onCheckIn = async () => {

    const checkedWhAppointment = {
      ...record,
      checkIn: get(data, "checkIn"),
    }
    try {
      const position = await getLocation();
      set(checkedWhAppointment, "checkIn.latitude", position.lat);
      set(checkedWhAppointment, "checkIn.longitude", position.lng);
      checkInOutWhAppointment(checkedWhAppointment, "checkIn")
    } catch (error) {
      // if position can not be retrieved, just check in with picture
      showError(error);
      checkInOutWhAppointment(checkedWhAppointment, "checkIn")
    } finally {
      setIsOpen(false);
    }
  }

  // FIXME: refactor this function
  const onCheckOut = async () => {
    const checkedWhAppointment = {
      ...record,
      checkOut: get(data, "checkOut")
    }
    try {
      const position = await getLocation();
      set(checkedWhAppointment, "checkOut.latitude", position.lat);
      set(checkedWhAppointment, "checkOut.longitude", position.lng);
      checkInOutWhAppointment(checkedWhAppointment, "checkOut")
    } catch (error) {
      // if position can not be retrieved, just check out with picture
      showError(error);
      checkInOutWhAppointment(checkedWhAppointment, "checkOut")
    } finally {
      setIsOpen(false);
    }
  }

  return (
    <Row align='middle'>
      <Col span={20}>
        {get(record, "checkIn.time") && <p>{formatTime(get(record, "checkIn.time"))}</p>}
        {get(record, "checkOut.time") && <p>{formatTime(get(record, "checkOut.time"))}</p>}
      </Col>
      <Col span={4}>
        <PictureOutlined onClick={() => setIsOpen(true)} color='#3481FF' style={{ fontSize: '20px', color: '#3481FF' }} />
        <Modal
          className='check-in-check-out-modal'
          visible={isOpen}
          onOk={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}
          footer={null}
        >
          <Row justify='center'>
            {/* {get(record, "code")} */}
            <Col span={24} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Space direction='vertical' size="large">
                <Space direction='vertical' size="small" style={{ width: "100%" }}>
                  {get(data, "checkIn.time") && (
                    <Tag color="success" icon={<CheckCircleFilled />} style={{ display: "flex", alignItems: "center" }}>
                      Check in: {formatTime(get(data, "checkIn.time"))}
                    </Tag>
                  )}
                  <UploadImage
                    style={{ width: "100%" }}
                    title="Hình ảnh"
                    imgUrl={get(data, "checkIn.picture")}
                    onChange={(picture) => setData({ ...data, checkIn: { ...get(data, "checkIn"), picture } })}
                  />
                  {/* <Image width={200} src={get(record, "checkInPicture")} style={{ marginLeft: "auto", marginRight: "auto"}} /> */}
                  <Row justify='center' align='middle'>
                    {/* <Button
                      type='link'
                      icon={
                        <LocationSvg
                          style={{ width: '16px', height: '16px', marginRight: '4px', marginBottom: '2px' }}
                        />
                      }
                      onClick={()=> window.open(`https://maps.google.com/?q=${get(record, "checkInLat")},${get(record, "checkInLng")}`, "_blank")}
                    >
                      Vị trí check in
                    </Button> */}
                    {!get(record, "checkIn") && (
                      <Button
                        type="primary"
                        disabled={!get(data, "checkIn.picture")}
                        onClick={onCheckIn}
                      // loading={isCheckInLoading}
                      >
                        Check in
                      </Button>
                    )}
                  </Row>
                </Space>

                {get(record, "checkIn") && (
                  <Space direction='vertical' size="small" style={{ width: "100%" }}>
                    {get(data, "checkOut.time") && (
                      <Tag color="success" icon={<CheckCircleFilled />} style={{ display: "flex", alignItems: "center" }}>
                        Check out: {formatTime(get(data, "checkOut.time"))}
                      </Tag>
                    )}
                    <UploadImage
                      style={{ width: "100%" }}
                      title="Hình ảnh"
                      imgUrl={get(data, "checkOut.picture")}
                      onChange={(picture) => setData({ ...data, checkOut: { ...get(data, "checkOut"), picture } })}
                    />
                    {/* <Image width={200} src={get(record, "checkOutPicture")} style={{ marginLeft: "auto", marginRight: "auto"}} /> */}
                    <Row justify='center' align='middle'>
                      {/* <Button
                        type='link'
                        icon={
                          <LocationSvg
                            style={{ width: '16px', height: '16px', marginRight: '4px', marginBottom: '4px' }}
                          />
                        }
                        onClick={()=> window.open(`https://maps.google.com/?q=${get(record, "checkOutLat")},${get(record, "checkOutLng")}`, "_blank")}
                      >
                        Vị trí check out
                      </Button> */}
                      {(!get(record, "checkOut") || isEmpty(get(record, "checkOut"))) && (
                        <Button
                          type="primary"
                          disabled={!get(data, "checkOut.picture")}
                          onClick={onCheckOut}
                        // loading={isCheckOutLoading}
                        >
                          Check out
                        </Button>
                      )}
                    </Row>
                  </Space>
                )}
              </Space>
            </Col>
          </Row>
        </Modal>
      </Col>
    </Row>
  )
});

export default CheckInOut;
