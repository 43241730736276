import { Col, Row, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react'
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { REQUEST_VOUCHER_STATUS_VI } from '~/constants/defaultValue';
import {  useRequestVoucherPaging, useRequestVoucherQueryParams, useRequestVouchers, useUpdateRequestVoucherParams } from '~/hooks/requestVoucher';
import { formatNumberThreeComma } from '~/hooks/utils';
import { PATH_APP } from '~/routes/paths';

export default function TabsRequestVoucher({userId,reFetch}) {
    const [query] = useRequestVoucherQueryParams(userId,reFetch);
    const [requestVouchers, isLoading] = useRequestVouchers(query);
    const paging = useRequestVoucherPaging(query);
    const [keyword, { setKeyword, onParamChange }] =
      useUpdateRequestVoucherParams(query);
      const columns = [
        {
            title:'Số tiền',
            dataIndex:'totalAmount',
            key:'totalAmount',
            align:'center',
            render : (value,record) => <Row>
            <Col span={24}>
            <Text strong>{`${formatNumberThreeComma(value || 0)}đ`}</Text>
            </Col>
            <Col span={24}>
            <span className={`bill-status ${get(record,'status')?.toLowerCase()}`}>{REQUEST_VOUCHER_STATUS_VI[get(record,'status')]}</span>
            </Col>
            <Col span={24}>
            <Text type='secondary'>{moment(get(record,'createdAt')).format('DD-MM-YYYY HH:mm:ss')}</Text>
            </Col>
          </Row>
            // render : (value,record)=> <Text strong>{`${floorFormatter(value || 0)}đ`}</Text>
        },
        {
            title:'Mã số yêu cầu',
            dataIndex:'requestNumber',
            key:'requestNumber',
            align:'center',
            render : (value) => value || ""
        },
        // {
        //     title:'Trạng thái',
        //     dataIndex:'status',
        //     key:'status',
        //     render: (item, record, index) =>  <span className={`bill-status ${item?.toLowerCase()}`}>{REQUEST_VOUCHER_STATUS_VI[item]}</span>
        // },
        {
            title:'Lý do từ chối',
            dataIndex:'reasonDeny',
            key:'reasonDeny',
        },
        // {
        //     title:'Ngày tạo',
        //     dataIndex:'createdAt',
        //     key:'createdAt',
        //     render:(date,record) => moment(date).format("DD-MM-YYYY HH:mm:ss")
        // },
      ]
  return (
    <>
        {isLoading ? (
        <SkeletonTable columns={columns} rowCount={10} />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          dataSource={requestVouchers}
          size="middle"
          onChange={({ current }) => onParamChange({ page: current,customPathName : PATH_APP.whPartnerWorkspace.wallet })}
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
        />
      )}
    </>
  )
}
