import produce from 'immer';
import * as Types from '~/constants/actionTypes';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  permission: [],
  isGetPermissionLoading: false,
  getPermissionFailed: null,
  actions: [],

  isUpdatePermissionLoading: false,
  updatePermissionSuccess: null,
  updatePermissionFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_GROUP_CATEGORIES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_GROUP_CATEGORIES_SUCCESS:
      state.isLoading = false;
      state.list = payload;
      return;

    case Types.GET_GROUP_CATEGORIES_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      state.list = [];
      return;

    case Types.GET_GROUP_CATEGORY_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_GROUP_CATEGORY_SUCCESS:
      state.isGetByIdLoading = false;
      const group = {
        ...payload,
        policies: Object.keys(payload.policies).reduce((acc, policyKey) => {
          return {
            ...acc,
            [policyKey]: payload.policies[policyKey].reduce(
              (actionAcc, action) => ({
                ...actionAcc,
                [action]: true
              }),
              {}
            )
          };
        }, {})
      };
      state.byId = group;
      return;

    case Types.GET_GROUP_CATEGORY_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_GROUP_CATEGORY_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_GROUP_CATEGORY_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_GROUP_CATEGORY_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_GROUP_CATEGORY_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_GROUP_CATEGORY_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_GROUP_CATEGORY_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_GROUP_CATEGORY_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_GROUP_CATEGORY_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_GROUP_CATEGORY_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.GET_PERMISSION_REQUEST:
      state.isGetResourcesLoading = true;
      state.getResourcesFailed = null;
      return;

    case Types.GET_PERMISSION_SUCCESS:
      const { actions, resources } = payload;

      const nextResources = resources.map((resource) => {
        const resourceWithKey = { resource };
        const nextResource = actions.reduce((acc, action) => {
          const actionWithKey = {
            [action.key]: action
          };

          return {
            ...acc,
            ...actionWithKey
          };
        }, resourceWithKey);

        return nextResource;
      });

      state.isGetResourcesLoading = false;
      state.resources = nextResources;
      state.actions = actions;
      return;

    case Types.GET_PERMISSION_FAILED:
      state.isGetResourcesLoading = false;
      state.getResourcesFailed = payload;
      return;

    case Types.UPDATE_PERMISSION_REQUEST:
      state.isUpdatePermissionLoading = true;
      state.updatePermissionFailed = false;
      return;

    case Types.UPDATE_PERMISSION_SUCCESS:
      state.isUpdatePermissionLoading = false;
      state.updatePermissionSuccess = payload;
      return;

    case Types.UPDATE_PERMISSION_FAILED:
      state.isUpdatePermissionLoading = false;
      state.updatePermissionFailed = payload;
      return;

    case Types.UPDATE_GROUP_CATEGORY_REDUX:
      state.byId = payload;
      return;

    case Types.UPDATE_PERMISSION_REDUX:
      state.resources = payload;
      return;

    case Types.RESET_GROUP_CATEGORY_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
