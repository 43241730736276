import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getCoreValuesFailed: null,
  getCoreValueFailed: null,
  CoreValues: [],
  CoreValue: null,
  isSubmitLoading: false,
  isGetCoreValueLoading: false,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,

  createSuccess: null,
  createFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_CORE_VALUES_REQUEST:
      state.isLoading = true;
      state.getCoreValuesFailed = null;
      return;

    case Types.GET_CORE_VALUES_SUCCESS:
      state.isLoading = false;
      state.CoreValues = get(payload, 'docs', []);
      state.getCoreValuesFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_CORE_VALUES_FAILED:
      state.isLoading = false;
      state.CoreValues = [];
      state.getCoreValuesFailed = payload;
      return;
    case Types.GET_CORE_VALUE_REQUEST:
      state.isGetCoreValueLoading = true;
      state.CoreValue = null
      state.getCoreValueFailed = null;
      return;

    case Types.GET_CORE_VALUE_SUCCESS:
      state.isGetCoreValueLoading = false;
      state.CoreValue = payload;
      state.getCoreValueFailed = null;
      return;

    case Types.GET_CORE_VALUE_FAILED:
      state.isGetCoreValueLoading = false;
      state.CoreValue = null;
      state.getCoreValueFailed = payload;
      return;
    case Types.CREATE_CORE_VALUE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_CORE_VALUE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_CORE_VALUE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_CORE_VALUE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_CORE_VALUE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.CoreValues = state.CoreValues.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.CoreValue = payload;
      return;

    case Types.UPDATE_CORE_VALUE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_CORE_VALUE_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_CORE_VALUE_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_CORE_VALUE_ACTION:
      state.getCoreValuesFailed = null;
      state.getCoreValueFailed = null;
      state.CoreValue = null;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      state.updateSuccess = null;
      state.updateFailed = null;
      state.createSuccess = null;
      state.createFailed = null;
      return ;

    case Types.RESET_STORE:
    case Types.RESET_CORE_VALUE_STATE:
      return initState;

    default:
      return;
  }
}, initState);
