const LocationSvg = ({
  style = {},
}) => (
  <svg width="23" height="32" {...style} style={style} viewBox="0 0 23 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3502 0C5.02857 0 0 4.96 0 11.2C0 19.52 11.3502 32 11.3502 32C11.3502 32 22.7004 19.52 22.7004 11.2C22.7004 4.96 17.6718 0 11.3502 0ZM11.3502 15.2C9.08082 15.2 7.29796 13.44 7.29796 11.2C7.29796 8.96 9.08408 7.2 11.3502 7.2C13.6196 7.2 15.4024 8.96 15.4024 11.2C15.4024 13.44 13.6196 15.2 11.3502 15.2Z"
      fill="#3481FF"
    />
  </svg>
)

export default LocationSvg;
