import * as Types from '../../constants/actionTypes';
  
  export const getAdvs = query => ({
    type: Types.GET_ADVS_REQUEST,
    payload: query
  });
  export const getAdv = id => ({
    type: Types.GET_ADV_REQUEST,
    payload: id
  });
  export const updateAdv = instance => ({
    type: Types.UPDATE_ADV_REQUEST,
    payload: instance
  });
  export const createAdv = instance => ({
    type: Types.CREATE_ADV_REQUEST,
    payload: instance
  });
  export const deleteAdv = id => ({
    type: Types.DELETE_ADV_REQUEST,
    payload: id
  });
  export const resetAdvState = () => ({
    type: Types.RESET_ADV_STATE
  });
