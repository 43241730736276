import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams,
  useFetch
} from '~/hooks/utils';

import {
  getUserEmployees,
  createUserEmployee,
  deleteUserEmployee,
  getUserEmployee,
  resetUserEmployeeState,
  updateUserEmployee,
  resetActionUserEmployeeState,
  getAddressUser,
  updateProfile,
  getProfileMe
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { fromJSON } from '~/components/UserEmployee/parser';

const USER_EMPLOYEE_MODULE = 'userEmployee';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(USER_EMPLOYEE_MODULE);

const getSelector = (key) => (state) => state[USER_EMPLOYEE_MODULE][key];
const pagingSelector = getSelector('paging');
const getAddressByIdLoadingSelector = getSelector('isGetAddressByIdLoading');
const byIdAddressSelector = getSelector('byIdAddress');
const getByIdAddressFailed = getSelector('getByIdAddressFailed');
const getProfileMeSelector = getSelector('profileMe');
const getLoadingProfileMeSelector = getSelector('isGetProfileMeLoading');
const getProfileMeFailedSelector = getSelector('getProfileMeFailed');

export const useUserEmployees = (query) => {
  return useFetchByParam({
    action: getUserEmployees,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateUserEmployee = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới người dùng thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createUserEmployee
  });
};

export const useUpdateUserEmployee = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật người dùng thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateUserEmployee
  });
};
export const useUpdateProfile = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật người dùng thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateProfile
  });
};

export const useDeleteUserEmployee = (onDeleteSuccess) => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá người dùng thành công',
    onDeleteSuccess
  );
  useFailed(deleteFailedSelector, 'Xoá người dùng thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteUserEmployee
  });
};

export const useUserEmployee = (params) => {
  return useFetchByParam({
    action: getUserEmployee,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};
export const useFetchProfile = () => {
  return useFetch({
    action: getProfileMe,
    loadingSelector: getLoadingProfileMeSelector,
    dataSelector: getProfileMeSelector,
    failedSelector: getProfileMeFailedSelector,
  });
};

export const useUpdateUserEmployeeParams = (query) => {
  const history = useHistory();
  const { id: branchId } = useParams();
  const pathName = branchId
    ? `/branch/detail/${branchId}/userEmployee`
    : '/userEmployee';

  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

    history.push({
      pathName,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param,
          branchId
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useUserEmployeeQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  // const page = query.get('page') || 1;
  const managementArea = query.get('managementArea') || '[]';
  const groupsIds = query.get('groupsIds');
  const { id: branchId } = useParams();

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      cityId,
      branchId,
      managementArea,
      groupsIds,
    });

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    cityId,
    branchId,
    managementArea,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    groupsIds,
  ]);
};

export const useUserEmployeePaging = () => useSelector(pagingSelector);

export const useInitUserEmployee = (userEmployee, id) => {
  return useMemo(() => {
    if (!userEmployee || !id) {
      return {};
    }

    const initValues = {
      ...fromJSON(userEmployee),
    };

    return initValues;
  }, [userEmployee, id]);
};

export const useResetUserEmployee = () => {
  useResetState(resetUserEmployeeState);
};

export const useResetActionUserEmployee = () => {
  useResetState(resetActionUserEmployeeState);
};

export const useGetAddressUser = (query) => {
  return useFetchByParam({
    action: getAddressUser,
    loadingSelector: getAddressByIdLoadingSelector,
    dataSelector: byIdAddressSelector,
    failedSelector: getByIdAddressFailed,
    param: query,
  });
};
