import { put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';
import { get} from 'lodash';
import { Empty } from 'antd';

function* getListStatusConfig() {
    try {
      const data = yield call(Api.statusConfig.getListStatusConfig);
      yield put({ type: Types.GET_STATUS_CONFIG_SUCCESS, payload: data });
    } catch (error) {
      yield put({
        type: Types.GET_STATUS_CONFIG_FAILED,
        payload: error.message
      });
    }
  }
function* getStatusConfigById({ payload: id }) {
    try {
      const data = yield call(Api.statusConfig.getById, id);
      yield put({ type: Types.GET_STATUS_CONFIG_BY_ID_SUCCESS, payload: data });
    } catch (error) {
      yield put({
        type: Types.GET_STATUS_CONFIG_BY_ID_FAILED,
        payload: error.message
      });
    }
}
function* createStatusConfig({payload: data}) {
    try {
        const res = yield call(Api.statusConfig.create, data);
        yield put({ type: Types.CREATE_STATUS_CONFIG_SUCCESS, payload: res });
    } catch (error) {
        yield put({ type: Types.CREATE_STATUS_CONFIG_FAILED, payload: error.message });
    }
}
function* updateStatusConfig({payload: data}) {
  console.log(data)
    try {
        const res = yield call(Api.statusConfig.update, data);
        yield put({ type: Types.UPDATE_STATUS_CONFIG_SUCCESS, payload: res });
    } catch (error) {
        yield put({ type: Types.UPDATE_STATUS_CONFIG_FAILED, payload: error.message });
    }
}
function* deleteStatusConfig({payload: id}) {
    try {
        const res = yield call(Api.statusConfig.delete, id);
        yield put({ type: Types.DELETE_STATUS_CONFIG_SUCCESS, payload: res });
    } catch (error) {
        yield put({ type: Types.DELETE_STATUS_CONFIG_FAILED, payload: error.message });
    }
}
  export default function* root() {
    yield takeLatest(Types.GET_STATUS_CONFIG_REQUEST, getListStatusConfig);
    yield takeLatest(Types.GET_STATUS_CONFIG_BY_ID_REQUEST, getStatusConfigById);
    yield takeLatest(Types.CREATE_STATUS_CONFIG_REQUEST, createStatusConfig);
    yield takeLatest(Types.UPDATE_STATUS_CONFIG_REQUEST, updateStatusConfig);
    yield takeLatest(Types.DELETE_STATUS_CONFIG_REQUEST, deleteStatusConfig);
  }