import React, { useEffect, useRef, useState } from 'react';
import H from '@here/maps-api-for-javascript';
import doctor from "../../assets/images/hereMap/doctor2.png"
import { HERE_MAPS_API_KEY } from '~/config';

// const apikey = 'b6DcBtwUvQfj8G62pxp_66jAUO84Izk4mnUnujaDT0Q';
export default function HereMap({ address }) {
  const apiKey = HERE_MAPS_API_KEY
  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const [position, setPosition] = useState();
  const [optionMap, setOptionMap] = useState([]);
  const markers = useRef([]); // Mảng lưu trữ các marker

  const partnerIcon = new H.map.Icon(doctor, {
    size: { w: 40, h: 40 }, // Thiết lập kích thước của biểu tượng
    anchor: { x: 10, y: 10 } 
  });

  function renderCenter(rasterTileLayer, position) {
    const newMap = new H.Map(mapRef.current, rasterTileLayer, {
      pixelRatio: window.devicePixelRatio,
      center: position,
      zoom: 14
    });
    // Add panning and zooming behavior to the map
    const behavior = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(newMap)
    );

    // Set the map object to the reference
    map.current = newMap;
  };

  useEffect(() => {
    if (address) {
      setPosition(address)
    }
  }, [address]);

  useEffect(() => {
    // Check if the map object has already been created
    platform.current = new H.service.Platform({apiKey});
    // Create a new Raster Tile service instance
    const rasterTileService = platform.current.getRasterTileService({
      queryParams: {
        style: 'explore.day',
        size: 512
      }
    });
    // Creates a new instance of the H.service.rasterTile.Provider class
    // The class provides raster tiles for a given tile layer ID and pixel format
    const rasterTileProvider = new H.service.rasterTile.Provider(
      rasterTileService
    );
    // Create a new Tile layer with the Raster Tile provider
    const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);
    if (!map.current) {
      renderCenter(rasterTileLayer, position);
    };
    // Kiểm tra xem có đối tượng nào trong nhóm markers không
    if (markers.current.length > 0) {
      // Xoá tất cả các đối tượng trong nhóm markers
      markers.current.forEach((marker) => {
        try {
          // Kiểm tra xem marker có tồn tại trong nhóm không
          if (map.current.getObjects().indexOf(marker) !== -1) {
            // Xoá marker khỏi nhóm
            map.current.removeObject(marker);
          }
        } catch (error) {
          console.error('Lỗi khi xoá đối tượng:', error);
        }
      });

      // Xoá các đối tượng khỏi mảng markers
      markers.current = [];
    }

    // Tạo marker mới và thêm vào nhóm markers
    if (position) {
      const newMarker = new H.map.Marker(position, {
        icon: partnerIcon,
      });
  
      // Thêm marker mới vào bản đồ và mảng markers
      map.current.addObject(newMarker);
      markers.current.push(newMarker);
      // Đặt vị trí mới làm trung tâm của bản đồ
      map.current.setCenter(position, false);
    };
  }, [address, position]);

  // The icon displays the highlighted position and the input value must be an img string

  return (
    <div
      style={{ width: '100%', height: '300px', margin: '0 auto' }}
      ref={mapRef}
    ></div>
  );
}
