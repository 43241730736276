export const GET_WH_PARTNERS_REQUEST = 'GET_WH_PARTNERS_REQUEST';
export const GET_WH_PARTNERS_SUCCESS = 'GET_WH_PARTNERS_SUCCESS';
export const GET_WH_PARTNERS_FAILED = 'GET_WH_PARTNERS_FAILED';

export const GET_WH_PARTNERS_FOR_REPORT_REQUEST = 'GET_WH_PARTNERS_FOR_REPORT_REQUEST';
export const GET_WH_PARTNERS_FOR_REPORT_SUCCESS = 'GET_WH_PARTNERS_FOR_REPORT_SUCCESS';
export const GET_WH_PARTNERS_FOR_REPORT_FAILED = 'GET_WH_PARTNERS_FOR_REPORT_FAILED';

export const DELETE_WH_PARTNER_REQUEST = 'DELETE_WH_PARTNER_REQUEST';
export const DELETE_WH_PARTNER_SUCCESS = 'DELETE_WH_PARTNER_SUCCESS';
export const DELETE_WH_PARTNER_FAILED = 'DELETE_WH_PARTNER_FAILED';

export const DELETE_WH_PARTNER_SERVICE_REQUEST = 'DELETE_WH_PARTNER_SERVICE_REQUEST';
export const DELETE_WH_PARTNER_SERVICE_SUCCESS = 'DELETE_WH_PARTNER_SERVICE_SUCCESS';
export const DELETE_WH_PARTNER_SERVICE_FAILED = 'DELETE_WH_PARTNER_SERVICE_FAILED';

export const CREATE_WH_PARTNER_REQUEST = 'CREATE_WH_PARTNER_REQUEST';
export const CREATE_WH_PARTNER_SUCCESS = 'CREATE_WH_PARTNER_SUCCESS';
export const CREATE_WH_PARTNER_FAILED = 'CREATE_WH_PARTNER_FAILED';

export const CREATE_WH_PARTNER_SERVICE_REQUEST = 'CREATE_WH_PARTNER_SERVICE_REQUEST';
export const CREATE_WH_PARTNER_SERVICE_SUCCESS = 'CREATE_WH_PARTNER_SERVICE_SUCCESS';
export const CREATE_WH_PARTNER_SERVICE_FAILED = 'CREATE_WH_PARTNER_SERVICE_FAILED';

export const INVITE_WH_PARTNER_REQUEST = 'INVITE_WH_PARTNER_REQUEST';
export const INVITE_WH_PARTNER_SUCCESS = 'INVITE_WH_PARTNER_SUCCESS';
export const INVITE_WH_PARTNER_FAILED = 'INVITE_WH_PARTNER_FAILED';

export const UPDATE_WH_PARTNER_REQUEST = 'UPDATE_WH_PARTNER_REQUEST';
export const UPDATE_WH_PARTNER_SUCCESS = 'UPDATE_WH_PARTNER_SUCCESS';
export const UPDATE_WH_PARTNER_FAILED = 'UPDATE_WH_PARTNER_FAILED';

export const UPDATE_WH_PARTNER_TIPS_REQUEST = 'UPDATE_WH_PARTNER_TIPS_REQUEST';
export const UPDATE_WH_PARTNER_TIPS_SUCCESS = 'UPDATE_WH_PARTNER_TIPS_SUCCESS';
export const UPDATE_WH_PARTNER_TIPS_FAILED = 'UPDATE_WH_PARTNER_TIPS_FAILED';

export const UPDATE_WH_PARTNER_SERVICE_REQUEST = 'UPDATE_WH_PARTNER_SERVICE_REQUEST';
export const UPDATE_WH_PARTNER_SERVICE_SUCCESS = 'UPDATE_WH_PARTNER_SERVICE_SUCCESS';
export const UPDATE_WH_PARTNER_SERVICE_FAILED = 'UPDATE_WH_PARTNER_SERVICE_FAILED';

export const APPROVE_WH_PARTNER_REQUEST = 'APPROVE_WH_PARTNER_REQUEST';
export const APPROVE_WH_PARTNER_SUCCESS = 'APPROVE_WH_PARTNER_SUCCESS';
export const APPROVE_WH_PARTNER_FAILED = 'APPROVE_WH_PARTNER_FAILED';

export const GET_WH_PARTNER_REQUEST = 'GET_WH_PARTNER_REQUEST';
export const GET_WH_PARTNER_SUCCESS = 'GET_WH_PARTNER_SUCCESS';
export const GET_WH_PARTNER_FAILED = 'GET_WH_PARTNER_FAILED';

export const RESET_WH_PARTNER_STATE = 'RESET_WH_PARTNER_STATE';

export const UPDATE_WORKING_TIME_WH_PARTNER_REQUEST = 'UPDATE_WORKING_TIME_WH_PARTNER_REQUEST';
export const UPDATE_WORKING_TIME_WH_PARTNER_SUCCESS = 'UPDATE_WORKING_TIME_WH_PARTNER_SUCCESS';
export const UPDATE_WORKING_TIME_WH_PARTNER_FAILED = 'UPDATE_WORKING_TIME_WH_PARTNER_FAILED';

export const CONVERT_TO_PARTNER_REQUEST = 'CONVERT_TO_PARTNER_REQUEST';
export const CONVERT_TO_PARTNER_SUCCESS = 'CONVERT_TO_PARTNER_SUCCESS';
export const CONVERT_TO_PARTNER_FAILED = 'CONVERT_TO_PARTNER_FAILED';
