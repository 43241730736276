import requester from './requester';

const ListTrial = {
  create: (payload) => requester.post('/register-software', payload),
  update: (query) => requester.put(`/register-software/${query?._id}`,query),
  delete: (id) => requester.delete(`/register-software/${id}`),
  getAll: (query) => requester.get('/register-software', query),
  getOne: (id) => requester.get(`/register-software/${id}`),
};
export default ListTrial;

