import { Button, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import ConfirmCouponProForm from '../ProductList/ConfirmCouponProForm';
import { WAREHOUSE_STATUS } from '~/constants/defaultValue';
import { formatNumberThreeComma } from '~/hooks/utils';
import { Link } from 'react-router-dom';

export default function ExpandableDeliveryVoucher({ data }) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (data?.deliveryVoucher.length) {
      const mapData = data?.deliveryVoucher?.map((item) => ({
        code: item?.product?.code,
        status: item?.status,
        name: item?.product?.name,
        valueDepreciation: item?.product?.valueDepreciation,
        codeProductOld: item?.codeProductOld,
        warehouseFromId: data?.from?.warehouseId,
        warehouseToId: data?.to?.warehouseId
      }));
      setDataSource(mapData);
    };
  }, [data]);

  const columns = [
    {
      title: 'Mã định danh sản phẩm	',
      align: 'start',
      dataIndex: 'code',
      width: '200px',
      render: (value, record) => {
        const checkWarehouse = (record.status === 'COMPLETED'? (record?.warehouseToId) : (record?.warehouseFromId));
        const checkCode = (record.status === 'COMPLETED'? (record?.code) : (record?.codeProductOld));
        return(
          <Link target='_blank' to={`/product-list?page=1&limit=10&code=${checkCode}&warehouseId=${checkWarehouse}`}>
            {checkCode}
        </Link>
      )}
    },
    {
      title: 'Tên sản phẩm',
      align: 'start',
      dataIndex: 'name',
      width: '300px'
    },
    {
      title: 'Giá khấu hao',
      align: 'start',
      dataIndex: 'valueDepreciation',
      key: 'valueDepreciation',
      render: (value)=> formatNumberThreeComma(value),
    },
    {
      title: 'Trạng thái thiết bị',
      align: 'start',
      dataIndex: 'status',
      width: '120px',
      render: (value, record, index) => (
        <span style={{ color: WAREHOUSE_STATUS[value]?.colorStyle }}>
            {WAREHOUSE_STATUS[value]?.name}
        </span>
      )
    },
  ];
  return (
    <>
        <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="middle"
        style={{width:'1000px'}}
      />
      {/* <Modal>
        <ConfirmCouponProForm/>
      </Modal> */}
    </>
  )
}
