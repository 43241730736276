import React, { useState } from 'react';
import ReferralPartner from './ReferralPartner';
import ReferralCustomer from './ReferralCustomer';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

export default function Expandable({whCustomer}) {
  const [active, setActive] = useState('1');
  const id = whCustomer?._id
  const onChangeTabs = (key) => {
    setActive(key);
  };
  return (
    <div className="page-wraper__content">
        <Tabs defaultActiveKey= {active} onChange={onChangeTabs}>
          <TabPane tab="Đối tác" key="1">
          <ReferralPartner whCustomerId={id} />
          </TabPane>
          <TabPane tab="Khách hàng" key="2">
            <ReferralCustomer whCustomerId={id}  />
          </TabPane>
        </Tabs>
    </div>
  );
}
