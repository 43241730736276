import { UserAddOutlined } from '@ant-design/icons';
import { Row, Table, Tabs } from "antd";
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { ROLE_REF } from '~/constants/defaultValue';
import { useRefWhCustomerManagement, useRefWhCustomerManagementPaging, useRefWhCustomerManagementQueryParams } from '~/hooks';
const TYPE_REF = {
  customer: 'customer',
  partner: 'partner',
}

const ListRef = ({ userId, openFetch, hidePartner, width,showTotal }) => {
  const [typeRef, setTypeRef] = useState(TYPE_REF.customer)
  const [query, onPagingChange, setKeyword] = useRefWhCustomerManagementQueryParams(userId, typeRef);
  const [valueSearch,setValueSearch] = useState('')
  const [data, isLoading] = useRefWhCustomerManagement(openFetch ? query : null);
  const paging = useRefWhCustomerManagementPaging()
  const onSearch = () => {
    setKeyword(valueSearch ? valueSearch?.trim() : '')
  }
  const onChangeTabs = (tabs) => {
    setTypeRef(tabs)
    setKeyword(null)
    setValueSearch('')
  }
  const columns = [
    {
      title: 'Tên',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (phone, record) => get(record, 'phoneNumber', '').replaceAll("+84", 0)
    },
    {
      title: 'Loại người dùng',
      dataIndex: 'role',
      key: 'role',
      render: (item, record, idex) => ROLE_REF[item].vi
    },
    {
      title: 'Ngày giới thiệu',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (item, record, idex) => moment(item).format('DD/MM/YYYY')
    },
  ];
  return (
    <div className="container-fluid" style={{ margin: '0 auto'}}>
      {/* <div className="page-content__main" style={{ width: '100%' }}> */}
      {/* <div style={{ width: '100%' }}> */}
      <Search
          allowClear
          style={{ width: "300px" }}
          placeholder="Tìm theo tên"
          enterButton
          onSearch={onSearch}
          onChange={(e) => setValueSearch(e.target.value)}
          value={valueSearch}
        />
        <Tabs onChange={onChangeTabs} style={{ width: width ? width : '80%' }} defaultActiveKey={TYPE_REF.customer}>
          <Tabs.TabPane tab={<Row align='middle'><UserAddOutlined /> Khách hàng</Row>} key={TYPE_REF.customer}>
            {isLoading ? <SkeletonTable columns={columns} rowCount={5} /> : <Table
              style={{ width: '90%' }}
              rowKey={(rc) => rc._id}
              columns={columns}
              dataSource={data}
              size="middle"
              onChange={onPagingChange}
              pagination={{
                ...paging,
                showTotal: (total) =>  showTotal ? `Tổng cộng: ${total} ` : ''
              }}
            />}
          </Tabs.TabPane>
          {!hidePartner && <Tabs.TabPane tab={<Row align='middle'><i style={{ marginRight: 10 }} className='uil uil-user-nurse'></i> Đối tác</Row>} key={TYPE_REF.partner}>
            {isLoading ? <SkeletonTable columns={columns} rowCount={5} /> : <Table
              style={{ width: '90%' }}
              rowKey={(rc) => rc._id}
              columns={columns}
              dataSource={data}
              size="middle"
              onChange={onPagingChange}
              pagination={{
                ...paging,
                showTotal: (total) =>  showTotal ? `Tổng cộng: ${total} ` : ''
              }}
            />}
          </Tabs.TabPane>}
        </Tabs>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default ListRef;
