import React, { useState } from 'react';
import { Table, Button, Input, Switch, Modal } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { EditFilled } from '@ant-design/icons';
import ServicePlaceForm from './Form';
import {
  useServicePlaces,
  useUpdateServicePlace,
  useServicePlaceQueryParams,
  useUpdateServicePlaceParams,
  useServicePlacePaging
} from '~/hooks/servicePlace';
import { useMatchPolicy } from '~/hooks';
import POLICY from '~/constants/policy';
import { useSpecialityOptions } from '~/hooks/speciality';
import WithPermission from '~/components/Common/WithPermission';

const { Search } = Input;
const ServicePlace = ({ history }) => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHHEALTHCAREFACILITY);
  const canCreate = useMatchPolicy(POLICY.WRITE_WHHEALTHCAREFACILITY);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHHEALTHCAREFACILITY);
  const [query, onPagingChange] = useServicePlaceQueryParams();
  const [servicePlaces, isLoading] = useServicePlaces(query);
  // console.log(servicePlaces, 'servicePlaces');
  const specialityOptions = useSpecialityOptions();
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateServicePlaceParams(query);
  const [isSubmitLoading, updateServicePlace] = useUpdateServicePlace();
  const [servicePlaceId, setServicePlaceId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const paging = useServicePlacePaging();

  const ColumnActions = ({
    id,
    state,
    useDeleteServicePlace,
    updateServicePlace,
    isSubmitLoading,
    name,
    ...restProps
  }) => {
    return (
      <div className="custom-table__actions">
        <WithPermission permission={POLICY.UPDATE_WHHEALTHCAREFACILITY}>
          <EditFilled
            onClick={() => {
              setIsOpen(true);
              setServicePlaceId(id);
              // setServicePlace({ id, state, ...restProps });
            }}
            style={{ color: '#1f89e5' }}
          />
        </WithPermission>
            <p style={{ marginLeft: '10px' }}>|</p>
        <WithPermission permission={POLICY.UPDATE_WHHEALTHCAREFACILITY}>
        <Switch
          checked={state === 'ACTIVE'}
          onChange={(value) =>
            updateServicePlace({
              state: value ? 'ACTIVE' : 'INACTIVE',
              id,
              name
            })
          }
          // loading={isSubmitLoading}
        />
        </WithPermission>
      </div>
    );
  };

  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '70px',
      render: (text, record, index) => {
        return (query.page - 1) * query.limit + index + 1;
      }
    },

    {
      title: 'Tên nơi thực hiện dịch vụ (vi)',
      dataIndex: 'name',
      key: 'name',
      width: '250px',
      render: (name) => name.vi
    },

    {
      title: 'Tên nơi thực hiện dịch vụ (en)',
      dataIndex: 'name',
      key: 'name',
      width: '250px',
      render: (name) => name.en
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        record = {
          ...record,
          state: record.state,
          id: record._id,
          name: record.name
        };
        return (
          <ColumnActions {...record} updateServicePlace={updateServicePlace} />
        );
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Nơi thực hiện dịch vụ" />
        <div className="page-content__main" style={{ display: 'block' }}>
          <div className="page-wraper__header">
            <Search
              style={{ width: 520 }}
              placeholder="Tìm nơi thực hiện"
              enterButton
              allowClear
              onSearch={() => onParamChange({ keyword })}
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />

            <WithPermission permission={POLICY.WRITE_WHHEALTHCAREFACILITY}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => {
                  setIsOpen(true);
                  setServicePlaceId(null);
                }}
              >
                {' '}
                Thêm mới
              </Button>
            </WithPermission>
          </div>
          {isLoading ? (
            <SkeletonTable
              rowCount={servicePlaces.length}
              columns={columns}
              pagination={paging}
            />
          ) : (
            <Table
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={servicePlaces}
              onChange={onPagingChange}
              size="middle"
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `
              }}
            />
          )}
        </div>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <ServicePlaceForm
          specialityOptions={specialityOptions}
          servicePlaceId={servicePlaceId}
          onClose={() => setIsOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default ServicePlace;
