import produce from 'immer';
import * as Types from '../../constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  convertSuccess: null,
  convertFailed: null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WH_CUSTOMERS_IMPORT_REQUEST:
      state.isLoading = true;
      state.list = [];
      state.getListFailed = null;
      return;

    case Types.GET_WH_CUSTOMERS_IMPORT_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_WH_CUSTOMERS_IMPORT_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_WH_CUSTOMER_IMPORT_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_WH_CUSTOMER_IMPORT_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_WH_CUSTOMER_IMPORT_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_WH_CUSTOMER_IMPORT_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_WH_CUSTOMER_IMPORT_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      state.list = state.list.concat(payload);
      return;

    case Types.CREATE_WH_CUSTOMER_IMPORT_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_WH_CUSTOMER_IMPORT_REQUEST:
      state.isSubmitLoading = true;
      state.byId = payload;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_WH_CUSTOMER_IMPORT_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      state.list = state.list.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload };
        }
        return item;
      });
      return;

    case Types.UPDATE_WH_CUSTOMER_IMPORT_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_WH_CUSTOMER_IMPORT_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_WH_CUSTOMER_IMPORT_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_WH_CUSTOMER_IMPORT_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.CONVERT_WH_CUSTOMER_IMPORT_REQUEST:
      state.isLoading = true;
      state.convertSuccess = null;
      state.convertFailed = null;
      return;

    case Types.CONVERT_WH_CUSTOMER_IMPORT_SUCCESS:
      state.convertSuccess = payload;
      state.isLoading = false;
      state.list = state.list.map((item) => {
        if (item._id === payload.data._id) {
          return { ...item, customerId: payload.data.customerId };
        }
        return item;
      });
      return;

    case Types.CONVERT_WH_CUSTOMER_IMPORT_FAILED:
      state.isLoading = false;
      state.convertFailed = payload;
      return;

    case Types.RESET_WH_CUSTOMER_IMPORT_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
