import { Col, List, Row, Tag } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import SkeletonTable from '~/components/Utils/SkeletonTable'
import { IconAction } from '~/hooks/trainingCoursePartner'

export default function LogsTipsPartner({notes,LoadingNote}) {
  return (
    <div style={{ position: 'relative',padding : '10px' }}>
        {LoadingNote ? (
            <SkeletonTable
                columns={[1]}
                rowCount={10}
              />
        ) : (
      <>
      <h5>Ghi chú</h5>
          <List
          style={{maxHeight : '500px' , overflowY : 'scroll'}}
      bordered
      dataSource={notes || []}
      renderItem={e => (
        <List.Item >
          <Row style={{width : '100%'}} justify="space-between">
                <Col span={4}>
                  <Tag color="blue">
                    {moment(get(e, 'date')).format('HH:mm DD/MM/YYYY')}
                  </Tag>
                </Col>
                <Col span={4}>
                 
                  <Tag color="magenta">
                       admin
                  </Tag>
                </Col>
                <Col span={16}>
                  <span>{IconAction[get(e,'action')]}{get(e, 'description')}</span>
                </Col>
              </Row>  
        </List.Item>
      )}
    ></List>
      </>
        )}
      </div>
  )
}
