import {Button, Col, Form, Row} from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import React from 'react'

export default function FormDeny({onSubmit,onCancel,submitLoading}) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        onSubmit(values)
    }
  return (
    <Form
    form={form}
    onFinish={onFinish}
    >
    <Form.Item name={'note'}>
    <TextArea placeholder='Lý do từ chối'/>
    </Form.Item>
    <Row justify="space-between" className="groupButtonSubmit">
        <Col span={8}>
            <Button onClick={onCancel}>Huỷ</Button>
        </Col>
        <Col span={8}>
            <Button loading={submitLoading} htmlType='submit' danger>Xác nhận</Button>
        </Col>
    </Row>
    </Form>
  )
}
