import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  deleteCoreValue,
  getCoreValue,
  getCoreValues,
  resetCoreValueState,
  updateCoreValue,
  createCoreValue,
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.coreValue[key];
const pagingSelector = getSelector('paging');
export const useCoreValuePaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const isGetCoreValueLoading = getSelector('isGetCoreValueLoading');
const getCoreValuesFailedSelector = getSelector('getCoreValuesFailed');
const getCoreValueFailedSelector = getSelector('getCoreValueFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const CoreValuesSelector = getSelector('CoreValues');
const CoreValueSelector = getSelector('CoreValue');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useCoreValueQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const createCoreValueSuccess = useSelector(createSuccessSelector);
  const deleteCoreValueSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createCoreValueSuccess,
    deleteCoreValueSuccess,
  ]);
};

export const useUpdateCoreValueParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.coreValue.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};
export const useCoreValues = (query) => {
  return useFetchByParam({
    action: getCoreValues,
    loadingSelector,
    dataSelector: CoreValuesSelector,
    failedSelector: getCoreValuesFailedSelector,
    param: query
  });
};
export const useCoreValue = (id) => {
  return useFetchByParam({
    action: getCoreValue,
    loadingSelector: isGetCoreValueLoading,
    dataSelector: CoreValueSelector,
    failedSelector: getCoreValueFailedSelector,
    param: id
  });
};
export const useUpdateCoreValue = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât Giá trị cốt lõi thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateCoreValue
  });
};

export const useCreateCoreValue = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới Giá trị cốt lõi thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createCoreValue
  });
};
export const useDeleteCoreValue = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Giá trị cốt lõi thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật Giá trị cốt lõi thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteCoreValue
  });
};

export const useResetCoreValue = () => {
  useResetState(resetCoreValueState);
};
