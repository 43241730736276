import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import moment from 'moment';
import { DatePicker, Form, Input, InputNumber, Select, TimePicker }from 'antd';
import { filter, get, head, keys, parseInt, range } from 'lodash';

import { DebounceSelect, DurationInputNumber } from '~/components/Common';
import { floorFormatter } from '~/utils/helper';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  children,
  dataIndex,
  editable,
  handleSave,
  record,
  rowIndex,
  title,

  component,
  options,
  optionsLoading,
  triggerFetchingOptions,
  availableTime,
  required,
  max,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (component === 'Select' && !editing && optionsLoading === false) {
      triggerFetchingOptions(record, rowIndex)
    }
    setEditing(!editing);

    let value;
    switch (component) {
      case 'DatePicker':
        value = moment.isMoment(record[dataIndex])
          ? value
          : moment(record[dataIndex])
        break;

      case 'TimePicker':
        value = moment.isMoment(record[dataIndex])
          ? value
          : moment(record[dataIndex], 'HH:mm')
        break;
    
      default:
        value = record[dataIndex];
        break;
    }

    form.setFieldsValue({
      [dataIndex]: value,
    });
  };

  const toJSON = values => {
    const { date, time } = values; // `dataIndex`
    const json = {
      ...values,
      ...(date && { date: date.toISOString() }), // example: 2022-03-22T00:00:00.000Z
      ...(time && { time: time.format("HH:mm") }),
    }
    return json;
  }

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if(max){ // Handle max values
        const key = head(keys(values));
        if(parseInt(values[key]) > max){
          values[key] = max;
        }
      }
      handleSave({ ...record, ...toJSON(values) }, dataIndex);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: required === false ? false : true,
            message: `Vui lòng nhập ${title}.`,
          },
        ]}
      >
        {
          {
            'Input': (
              <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            ),
            'InputNumber': (
              <InputNumber
                ref={inputRef}
                onPressEnter={save}
                onBlur={save}
                formatter={value => floorFormatter(value)}
              />
            ),

            'InputNumberOnBlurCapture': (
              <InputNumber
                ref={inputRef}
                onPressEnter={save}
                onBlurCapture={save}
                formatter={value => floorFormatter(value)}
              />
            ),

            'DurationInputNumber': (
              <DurationInputNumber
                ref={inputRef}
                duration={get(record.sessionPrice, "extraDuration")}
                timeUnit={get(record.sessionPrice, "extraTimeUnit")}
                onPressEnter={save}
                onBlur={save}
                style={{ width: "70px" }}
              />
            ),
            'DatePicker': (
              <DatePicker ref={inputRef} onChange={save} onBlur={save} />
            ),
            'TimePicker': (
              <TimePicker
                ref={inputRef}
                format="HH:mm"
                
                onChange={save}
                onBlur={save}
                disabledHours={() => {
                  if (availableTime) {
                    const { startTime, endTime } = availableTime;
                    const startHr = moment(startTime, 'HH:mm').hour()
                    const endHr = moment(endTime, 'HH:mm').hour()
                    const res = filter(
                      range(24),
                      hr => hr < startHr || hr > endHr
                    );
                    return res;
                  }

                  return [];
                }}
                disabledMinutes={(selectedHr) => {
                  if (availableTime) {
                    const { startTime, endTime } = availableTime;
                    const startHr = moment(startTime, 'HH:mm').hour()
                    const endHr = moment(endTime, 'HH:mm').hour()
                    const startMin = moment(startTime, 'HH:mm').minute()
                    const endMin = moment(endTime, 'HH:mm').minute()

                    switch (selectedHr) {
                      case startHr:
                        return filter(range(60), min => min < startMin)

                      case endHr:
                        return filter(range(60), min => min > endMin)

                      default:
                        return [];
                    }
                  }
                  return [];
                }}
              />
            ),
            'Select': (
              <Select
                ref={inputRef}
                options={options}
                loading={optionsLoading}
                onChange={save}
                onBlur={save}
              />
            ),
            'DebounceSelect': (
              <DebounceSelect
                ref={inputRef}
                fetchOptions={() => triggerFetchingOptions(record, rowIndex)}
                labelKey="name"
                valueKey="_id"
                onChange={save}
                onBlur={save}
              />
            ),
          }[component]
        }
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export {
  EditableCell,
  EditableRow,
};
