import * as Types from '../../constants/actionTypes';
  
  export const getActivityReferrals = query => ({
    type: Types.GET_ACTIVITY_REFERRALS_REQUEST,
    payload: query
  });
  export const getActivityReferral = id => ({
    type: Types.GET_ACTIVITY_REFERRAL_REQUEST,
    payload: id
  });
  export const createActivityReferral = instance => ({
    type: Types.CREATE_ACTIVITY_REFERRAL_REQUEST,
    payload: instance
  });
  export const updateActivityReferral = instance => ({
    type: Types.UPDATE_ACTIVITY_REFERRAL_REQUEST,
    payload: instance
  });
  export const deleteActivityReferral = id => ({
    type: Types.DELETE_ACTIVITY_REFERRAL_REQUEST,
    payload: id
  });
  export const resetActivityReferralState = () => ({
    type: Types.RESET_ACTIVITY_REFERRAL_STATE
  });
