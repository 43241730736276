import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState ,useMemo } from 'react';

import { Alert, Badge, Button, Checkbox, Col, Modal, notification, Row, Table, Tag, Tooltip, Typography } from 'antd';
import { find, forIn, get, head, keys, last, merge, set } from 'lodash';
import moment from 'moment';
import toastr from 'toastr';
import api from '~/api';
import { ADDITIONAL_VOUCHER, COMPONENT_MODES, WH_APPOINTMENT_GIVE_AWAY, INSTANCE_STATUSES, MODE_VOUCHER_WAREHOUSE, TIME_UNIT, TYPE_VOUCHER_WAREHOUSE_VI, TYPE_WAREHOUSE, UPDATABLE_WH_APPOINTMENT_STATUSES, WEEKDAYS, WH_APPOINTMENT_STATUS } from '~/constants/defaultValue';
import POLICY, { CORE_ACTION, GROUP_POLICY } from '~/constants/policy';
import { calculateTotalValueDepreciation, checkTypeGroupToGetMessage, getDataGroupInit, getDataGroupInitMaterial, getQuantityConfirm, SplitDataWarehouse, useMatchPolicy } from '~/hooks';
import { floorFormatter, formatter, humanize } from '~/utils/helper';

import { convertWhAppointmentProcessing, getExpectEndTime, getPackageLevelOfService, getSessionPrice, isRangeTimeIsBetweenRangeTime } from '~/components/WhService/utils';
import WhVoucherStatusTag from '~/components/WhVoucher/components/WhVoucherStatusTag';
import WhReceiptVoucherForm from '~/components/WhVoucher/Form/WhReceiptVoucherForm';
import { PATH_APP } from '~/routes/paths';
import { getExtraQuantityAndExtraDurationAndExtraTimeUnit } from '../utils';
import CheckInOut from './components/CheckInOut';
import { EditableCell, EditableRow } from './components/EditableComponents';

import { InfoCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { WithOrPermission } from '~/components/Common';
import { formatNumberThreeComma, formatNumberThreeCommaToFixed } from '~/hooks/utils';
import AssignEquiment from './components/AssignEquiment';
import TableDiscount from './components/TableDiscount';
import "./index.scss";
import WhPaymentVoucherForm from '~/components/WhVoucher/Form/WhPaymentVoucherForm';
import HereMapPosition from '~/components/Common/HereMapPosition';
import { AssignWarehouseAppointmentProvider } from '~/storeContext/AssignWarehouseWhAppointment';
import DistanceModal from './components/DistanceModal';
import DistanceModalToWareHouse from './components/DistanceModalToWareHouse';
import TooltipTitle from '~/components/Common/TooltipTitle';
import { Link } from 'react-router-dom';
import ControlRating from './components/ControlRating';
const mapAppoinemtStatusTag = {
  [WH_APPOINTMENT_STATUS.NEW]: { name: "Mới", color: "geekblue" },
  [WH_APPOINTMENT_STATUS.IN_PROGRESS]: { name: "Đang thực hiện", color: "processing" },
  [WH_APPOINTMENT_STATUS.COMPLETED]: { name: "Đã hoàn thành", color: "success" },
  [WH_APPOINTMENT_STATUS.CANCELLED]: { name: "Đã hủy", color: "error" },
}
const Add15Min = (time) => moment(time,'HH:mm').add(15,'minutes').format("HH:mm")
const WhPartners = forwardRef(({
  // onOk,
  // saveOnChange = true,
  row,
  rowIdx,
  updateMatchingWhPartners,
  whBill,
}, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [whPartners, setWhPartners] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const pageSize = 10;
  const [positionPartner, setPositionPartner] = useState([]);
  const [isOpenDistanceModal, setIsOpenDistanceModal] = useState(false);
  const [isOpenDistanceModalToWareHouse, setIsOpenDistanceModalToWareHouse] = useState(false);
  const [whAppointmentId, setWhAppointmentId] = useState(null);
  const [whPartner,setWhPartner] = useState(false);

  const getData = async (query) => {
    try {
      setIsLoading(true);
      const { docs: whPartners, totalDocs } = await updateMatchingWhPartners(row, rowIdx, true, query);
      setWhPartners(whPartners);
      setTotal(totalDocs);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Lỗi lấy dữ liệu từ máy chủ",
      });
    } finally {
      setIsLoading(false);
    }
  }

  const onPagingChange = ({ current, pageSize }) => {
    setCurrent(current);
    getData({ page: current, limit: pageSize })
  }

  const handleOpenModalDistance = (record) => {
    setIsOpenDistanceModal(true);
    setWhPartner(record);
    document.body.classList.add('ant-modal-wrap'); 
  };
  const handleCloseModalDistance = () => {
    setIsOpenDistanceModal(false);
    setWhPartner(null);
    document.body.classList.remove('ant-modal-wrap')
  };

  const handleOpenModalDistanceToWareHouse = (record) => {
    setIsOpenDistanceModalToWareHouse(true);
    setWhPartner(record);
  }

  useEffect(() => {
    if (get(row, "whPartnerId")) setSelectedRowKeys([get(row, "whPartnerId")]);
    getData();
  }, [row, rowIdx]);

  useEffect(() => {
    const checkPosition = whPartners?.filter((item) => (item.lat && item.lng));
    const mapInfoPartners = checkPosition?.length > 0 && checkPosition?.map((item) => ({ lat: item?.lat, lng: item?.lng, distanceKm: item?.distanceKm, name: item?.name, address: item?.address }));
    mapInfoPartners.length > 0 && setPositionPartner(mapInfoPartners);
  }, [whPartners]);

  // useEffect(() => {
  //   if (saveOnChange) onOk();
  // }, [selectedRowKeys])

  useImperativeHandle(ref, () => ({
    getSelectedWhPartner() {
      return head(selectedRows);
    }
  }));
  const customerAddress = get(whBill, "customerAddress");
  const dayOfWeek = moment(get(row, "date")).isoWeekday() - 1;
  const from = get(row, "time");
  const to = get(row, "expectedEndTime") && moment(get(row, "expectedEndTime")).format("HH:mm");

  function LinkComponent(data) {
    return (
      <Link to = {`${PATH_APP.whPartner.root}/edit/${get(data, "_id")}`}>
        {data?.name}
      </Link>
    );
  }

  const columns = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'partnerCode',
      key: 'partnerCode'
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        let message = 'Đối tác này có công cụ dụng cụ phù hợp';
        const showMessenger = record?.productList?.length;
        return <TooltipTitle dot={!!showMessenger} titleTooltip={showMessenger ? message : ''} content={LinkComponent(record)} />

      }
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Gói dịch vụ',
      key: 'packageLevel',
      render: (text, record, index) => {
        return get(record, "packageLevel.0.name.vi");
      }
    },
    {
      title: `Thời gian trong ngày (${get(WEEKDAYS, `${dayOfWeek}.name.vi`)})`,
      key: 'workingTime',
      render: (text, record, index) => {
        let result = [];
        let isContinue = true;
        let sortWorkingTime = [...get(record, `workingTime.${dayOfWeek}`)];
        sortWorkingTime?.sort((a,b) => moment(get(a,'[1]'),'HH:mm').hour() - moment(get(b,'[1]'),'HH:mm').hour());
        let maxEndTime = last(sortWorkingTime)?.[1];
        const isStop =  (res,endTime) =>  res.some(i => moment(i.endTime,'HH:mm').hour() > moment(endTime,'HH:mm').hour());
        const resultPush = ({startTime,endTime}) => {
           isContinue = !isStop(result,endTime);
           if(!isContinue) return;
            result.push({startTime,endTime})
        };
        const convertTimeInDateRow= convertWhAppointmentProcessing({appointments : get(record, 'appointments', []),row,maxEndTime})

        sortWorkingTime?.some((item, index) => {
          if(!isContinue) return true; // break
          const startTime = get(item, 0);
          const endTime = get(item, 1);
        
          const appointmentInWorkingHouse = convertTimeInDateRow.filter((e) => {
            const startTimeRange = moment(get(e, 'time'), 'HH:mm');
            const endTimeRange = moment(get(e, 'endTime'), 'HH:mm');
            const momentStartTime = moment(startTime, 'HH:mm');
            const momentEndTime = moment(endTime, 'HH:mm');
            
            const isWithinRange = isRangeTimeIsBetweenRangeTime(startTimeRange,endTimeRange,momentStartTime,momentEndTime);
            return isWithinRange
            
          });
          if (appointmentInWorkingHouse.length > 0) {
            appointmentInWorkingHouse.sort((a, b) => {
              const time1 = moment(a.time, 'HH:mm');
              const time2 = moment(b.time, 'HH:mm');
              if (time1.isBefore(time2)) {
                return -1
              } else if (time1.isSame(time2)) {
                return -1
              } else {
                return 1
              }

            })
            let first = { startTime, endTime } // case 1 workingtime have many appointment
            appointmentInWorkingHouse.map((one, i) => {
              if (get(one, 'time') !== get(first, 'startTime')) { // double case
                resultPush({ startTime: get(first, 'startTime'), endTime: get(one, 'time') });
              }
              resultPush({ startTime: get(one, 'time'), endTime: get(one, 'endTime') })
              if (get(one, 'endTime') !== get(first, 'endTime')) { // double case // remove (10:45-10:30)
                if (i === appointmentInWorkingHouse.length - 1) {
                  resultPush({ startTime: Add15Min(get(one, 'endTime')), endTime: get(first, 'endTime') })
                }
              }
              first.startTime = Add15Min(get(one, 'endTime'))
              first.endTime = get(first, 'endTime')
            })
          }
          else {
            if (convertTimeInDateRow.length !== 0) {
              const some = convertTimeInDateRow?.findIndex(e => e.time === startTime && e.endTime === endTime)
              if (some !== -1) {
                resultPush({ startTime, endTime })
              }
              else {
                if (index === 0) {
                  resultPush({ startTime, endTime })
                }
                else {
                  if (startTime !== endTime) {
                    if (startTime === get(record, `workingTime.${dayOfWeek}$[{index - 1}][0]`)) { // first not + 15m
                      resultPush({ startTime: Add15Min(startTime), endTime }) // add 15 minutes to break
                    }
                    else {
                      resultPush({ startTime, endTime })
                      
                    }
                  }
                }
              }
            }
            else {
              resultPush({ startTime, endTime })
            }
          }
         
          
        });
        const resultRemoveDoubleCase = result.filter(res => get(res, 'startTime') !== get(res, 'endTime'));
        return resultRemoveDoubleCase?.map(e => {
          const start = get(e, 'startTime');
          const end = get(e, 'endTime');
          const isAppropriate = start <= from && end >= to;
          const isProcessing = convertTimeInDateRow?.some(e => e.time === start && e.endTime === end);
          const isInWhAppointmentProcessing = convertTimeInDateRow?.some(whAppointmentOfPartner => {
            const startTime_ = moment(get(whAppointmentOfPartner,'time'));
            const endTime = moment(get(whAppointmentOfPartner,'endTime'));
            return isRangeTimeIsBetweenRangeTime(moment(start),moment(end),startTime_,endTime)
          });

          const processing = isProcessing || isInWhAppointmentProcessing;
          return (
            <Tag color={get(record, 'appointments') ? processing ? 'error' : 'warning' : isAppropriate ? "blue" : 'default'}>
              {`${start} - ${end}`}
            </Tag>
          )
        })
      }
    },
    {
      title: 'Khoảng cách đến đơn hàng',
      key: 'distanceKm',
      dataIndex: 'distanceKm',
      align: 'center',
      render: (value, record, index) => value === 0 ? '' : (`${formatNumberThreeCommaToFixed(get(record, 'distanceKm'))}km`)
    },
    {
      title: 'CCDC/NVL',
      key: 'more',
      align: 'center',
      dataIndex: 'more',
      render: (text, record, index) => {
        const checkDistance = get(record, 'distanceKm') ? (`${formatNumberThreeCommaToFixed(get(record, 'distanceKm'))}km`) : '';
        return (
          <Button type='link'
            onClick={() => {
              handleOpenModalDistance(record)
            }}
          >
            Xem thêm
          </Button>
        );
      }
    },
    // {
    //   title: 'Khoảng cách đến kho',
    //   key: 'distanceKmWh',
    //   dataIndex: 'distanceKmWh',
    //   render: (text, record, index) => {
    //     const checkDistance = get(record, 'distanceKmWh') ? (`${formatNumberThreeCommaToFixed(get(record, 'distanceKmWh'))}km`) : '';
    //     return (
    //     <Button type='link'
    //         // onClick={() => {
    //         //   handleOpenModalDistanceToWareHouse(record)
    //         // }}
    //       >
    //     Xem thêm
    //   </Button>)
    //   }
    // }
  ];

  return (
    <div>
      <HereMapPosition
        partnerInfo={positionPartner}
        orderPosition={{
          lat: whBill?.lat,
          lng: whBill?.lng
        }}
      />
      <Table
        onChange={onPagingChange}
        pagination={{
          total,
          pageSize,
          current,
          showTotal: (total) => `Tổng cộng: ${total} `
        }}
        title={() => (
          <>
            <span>DS đối tác phù hợp ngày </span>{" "}
            <strong>{moment(get(row, "date")).format("DD/MM/YYYY")}</strong>{" "}
            <span>từ </span><strong>{get(row, "time")}</strong>{" "}
            <span>đến </span><strong>{moment(get(row, "expectedEndTime")).format("HH:mm")}</strong>{" "}
            <span>tại </span><strong>{`${get(customerAddress, "ward")}, ${get(customerAddress, "district")}, ${get(customerAddress, "city")}`}</strong>
          </>
        )}
        columns={columns}
        dataSource={whPartners}
        loading={isLoading}
        rowKey={(rc) => rc._id}
        size="middle"
        rowSelection={{
          type: 'radio',
          selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
      />
      <Modal
        title={(
          <span>
              Khoảng cách từ đối tác <span style={{ color: '#3481ff' }}>{whPartner?.name || ''}</span> đến nơi cung cấp thiết bị
            </span>
          )}
        visible={isOpenDistanceModal}
        width={800}
        onCancel={() => handleCloseModalDistance()}
        onOk={()=>setIsOpenDistanceModal(false)}
        footer={null}
        style={{
          zIndex: '2109'
        }}
      >
        <DistanceModal
          row = {row}
          partner = {whPartner}
          whPartners = {whPartners}
          whAppointmentId = {row?._id}
        />
      </Modal>
      {/* <Modal
        title={(
          <span>
              Khoảng cách từ đối tác <span style={{ color: '#3481ff' }}>{whPartner?.name || ''}</span> đến kho cung cấp thiết bị
            </span>
          )}
        visible={isOpenDistanceModalToWareHouse}
        width={700}
        onCancel={() => setIsOpenDistanceModalToWareHouse(false)}
        onOk={()=>setIsOpenDistanceModalToWareHouse(false)}
        footer = {null}
      >
        <DistanceModalToWareHouse
          row = {row}
          partner = {whPartner}
          whPartners = {whPartners}
          whAppointmentId = {row?._id}
        />
      </Modal> */}
    </div>
  )
})

// This component represents a table showing all whAppointments of a block of a single whBillItem
const WhAppointmentsByBlockTable = forwardRef(({
  dataSource,
  mode,
  onWhAppointmentActualCostChange,
  optionWhPackageLevels,
  infoRating,
  onUpdate,
  // According to business logic, when user changes something leading to a change in actual cost of a single whAppointment,
  // it leads to the change of grandTotal of the whAppointment itself, the whBillItem, and the whBill.
  // Therefore, the remaining actual cost the the bill item will be recalculated and devided proportionally and allocated
  // to each single new or in-progress whAppointment.
  computedWhBillItem,

  whBill,
  whService,
  mutateAppointment,
  whBillItem,
  isWhAppointmentsLoading,
  mergedWhAppointments,
}, ref) => {
  const isMatchPolicy = useMatchPolicy(POLICY.UPDATE_WHAPPOINTMENT);
  const modalRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRowIdx, setSelectedRowIdx] = useState();
  const [visibleModalWareHouse, setVisibleModalWareHouse] = useState(false)
  const [idVisibleModalWareHouse, setIdVisibleModalWareHouse] = useState(null)

  const [isOpenReceiptVoucherModal, setIsOpenReceiptVoucherModal] = useState(false);
  const [whReceiptVoucher, setWhReceiptVoucher] = useState();

  const [isOpenPaymentVoucherModal, setIsOpenPaymentVoucherModal] = useState(false);
  const [isOpenCreateReceiptVoucherModal, setIsOpenCreateReceiptVoucherModal] = useState(false);
  const [isOpenDiscountModal, setIsOpenDiscountModal] = useState(false);
  const [selectAppointment, setSelectAppointment] = useState(null);
  const totalReduced = useCallback((reduced) => {
    if(!reduced || keys(reduced)?.length === 0) return 0
      let total = 0;
     forIn(reduced,(value,key) => key !== 'prepay' ? total += parseInt(value) : total) // pre pay is not a Discounted Discount
    return Math.floor(total)
  },[])
  const onOpenPaymentVouchersModal = (data) => {
    setIsOpenPaymentVoucherModal(true)
    setSelectAppointment(data)
  }
  const onClosePaymentVouchersModal = () => {
    setIsOpenPaymentVoucherModal(false)
    setSelectAppointment(null)
  }

  const onOpenCreateReceiptVouchersModal = (data) => {
    setIsOpenCreateReceiptVoucherModal(true)
    setSelectAppointment(data)
  }
  const onCloseCreateReceiptVouchersModal = () => {
    setIsOpenCreateReceiptVoucherModal(false)
    setSelectAppointment(null)
  }
  const onOpenDiscountModal = (data) => {
    setIsOpenDiscountModal(true)
    setSelectAppointment(data)
  }
  const onCloseDiscountModal = () => {
    setIsOpenDiscountModal(false)
    setSelectAppointment(null)
  }
  useEffect(() => {
    if (computedWhBillItem && Object.values(computedWhBillItem).every(x => !!x || x === 0)) {

      const newDataSource = clonedDataSource?.map(row => {
        if (UPDATABLE_WH_APPOINTMENT_STATUSES.includes(get(row, "status"))) {
          const {
            grandTotalInProgress,
            remainingAmount,
            totalAmountPaid
          } = computedWhBillItem;
          const newActualCost = get(row, "grandTotal") * remainingAmount / (grandTotalInProgress)
          row.actualCost = newActualCost;
        }
        return row;
      });
      //  const newDataSourceExistTime = newDataSource?.map(e => {
      //     if(!get(e,'whPartnerId')){
      //       const findOne = newDataSource?.some(data => moment(get(data,'expectedEndTime')).hours() === moment(get(e,'expectedEndTime')).hours())
      //       if(findOne) return {...e,isExist : true}
      //     }
      //     return {...e,isExist : false}
      //   } )
      //   console.log(newDataSourceExistTime,"newDataSourceExistTime");
      setClonedDataSource(newDataSource);
    }
  }, [computedWhBillItem]);

  const getComputedRowProps = (row, exclude) => {
    const whPackageLevel = getPackageLevelOfService(
      whService,
      get(row, "whPackageLevelId"),
    )

    // since the `whPackageLevelId` can be changed, the availabel sessions of day may differ
    // so we update the `optionWhSessionsOfDay`
    const availableWhSessionsOfDay = get(
      whPackageLevel,
      "sessionPrices"
    )
      ?.filter(item => get(item, "status") === INSTANCE_STATUSES.ACTIVE)

    const optionWhSessionsOfDay = availableWhSessionsOfDay
      ?.map(item => ({
        label: get(item, "whSessionOfDay.name"),
        value: get(item, "whSessionOfDayId")
      }))

    // check whether the current whSessionOfDayId is still available in the optionWhSessionsOfDay
    // as optionWhSessionsOfDay depends on whPackageLevelId change
    // and may not contain the current whSessionOfDayId anymore.
    // in that case it should be set to null.
    const whSessionOfDay = find(availableWhSessionsOfDay, { whSessionOfDayId: get(row, "whSessionOfDayId") })
    const whSessionOfDayId = find(optionWhSessionsOfDay, { value: get(row, "whSessionOfDayId") })
      ? get(row, "whSessionOfDayId")
      : null

    // as the `whPackageLevelId` and `whSessionOfDayId` can be changed
    const sessionPrice = whSessionOfDayId
      ? getSessionPrice(
        whService,
        get(row, "whPackageLevelId"),
        whSessionOfDayId,
      )
      : {}

    // recompute all price-related values
    const price = get(sessionPrice, "price");
    const extraQuantity = get(row, "extraQuantity");
    const extraPrice = get(sessionPrice, "extraPrice");
    const extraAmount = extraQuantity * extraPrice;
    const subTotal = price + extraAmount;
    const vat = get(row, "vat");
    const vatAmount = subTotal * vat;
    const grandTotal = row?.Type !== WH_APPOINTMENT_GIVE_AWAY ?  subTotal + vatAmount + get(row,'bonus',0) + get(row,'dealPrice',0) : 0; //If appointment type is Giveaway then grandTotal = 0

    let actualCost;
    let shouldUpdateActualCost;
    let badges = merge({}, get(row, "badges"));
    if (get(row, "status") === WH_APPOINTMENT_STATUS.NEW) {
      // By default, always try to update the actualCost
      let tryUpdateActualCost = true;

      // If 'actualCost' is excluded, don't try to update it
      if (Array.isArray(exclude)) {
        if (exclude.includes('actualCost')) {
          tryUpdateActualCost = false;
        }
      }

      if (tryUpdateActualCost) {
        // Check whether actualCost should be updated: it should be updated if the grandTotal is changed
        const oldActualCost = get(row, "actualCost");
        shouldUpdateActualCost = grandTotal !== oldActualCost;

        if (shouldUpdateActualCost) {
          // const reasonForUpdate = "Vì \"Tạm tính\" thay đổi, hệ thống vừa điều chỉnh lại số tiền phải thu. Bạn vẫn có thể thay đổi số tiền phải thu này."
          // const color = "gold"

          // actualCost = grandTotal;
          // badges = merge({}, badges, {
          //   actualCost: {
          //     color,
          //     title: reasonForUpdate,
          //   }
          // });
        }
      } else {
        delete badges.actualCost
      }
    }

    const { expectedEndTime, expectedEndDate,totalDurationInMinutes} =  getExpectEndTime({sessionPrice,whAppointment:row})

    const whPartner = get(row, "whPartner");

    return {
      shouldUpdateActualCost,
      data: {
        sessionPrice,
        optionWhSessionsOfDay,
        whSessionOfDay,
        whSessionOfDayId,

        whPackageLevel: get(whPackageLevel, "whPackageLevel"),

        // update the ids of package level and session of day that mapped to the whService also
        whPackageId: get(whPackageLevel, "_id"),
        whSessionPriceItemId: get(sessionPrice, "_id"),

        extraPrice,
        extraAmount,
        subTotal,
        vatAmount,
        grandTotal,
        ...(actualCost && { actualCost }),
        badges,

        totalDurationInMinutes,
        expectedEndTime,
        expectedEndDate,

        whPartnerId: get(whPartner, "_id") || get(whPartner, "value"),
      }
    }
  }

  const handleOpenModalWareHouse = (id) => {
    setVisibleModalWareHouse(true)
    setIdVisibleModalWareHouse(id)
  }
  const handleCloseModalWareHouse = () => {
    setVisibleModalWareHouse(false)
    setIdVisibleModalWareHouse(null)
  }

  const [clonedDataSource, setClonedDataSource] = useState(dataSource.map(item => {
    const submitData = ({
      ...item,
      ...getComputedRowProps(item, ['actualCost']).data, // on the first load, leave everything intact and don't try to manually update the actualCost
      key: get(item, "_id"),
    })
    if (!get(item, 'whPartnerId')) {
      const findOne = dataSource?.some(data => {

        return get(data, 'time') === get(item, 'time')
      })
      if (findOne) return { ...submitData, isExist: true }
    }
    return ({
      ...submitData,
      isExist: false
    })
  }));
  useEffect(() => { // Because Error in Open next WhBillItem , So i set It Again to avoid error
    setClonedDataSource(dataSource.map(item => {
      const submitData = ({
        ...item,
        ...getComputedRowProps(item, ['actualCost']).data, // on the first load, leave everything intact and don't try to manually update the actualCost
        key: get(item, "_id"),
      })
      if (!get(item, 'whPartnerId')) {
        const findOne = dataSource?.some(data => {
  
          return get(data, 'time') === get(item, 'time')
        })
        if (findOne) return { ...submitData, isExist: true }
      }
      return ({
        ...submitData,
        isExist: false
      })
    }))
  },[dataSource]);
  const [computedAsyncDataSource, setComputedAsyncDataSource] = useState([]);

  useImperativeHandle(ref, () => ({
    getBlockData() {
      return clonedDataSource;
    }
  }));

  const updateMatchingWhPartners = async (row, index, raw, query) => {
    set(computedAsyncDataSource, `${index}.optionWhPartnersLoading`, true);

    const computedRow = getComputedRowProps(row); // to calculate the expectedEndTime for calling API
    const response = await api.whPartner.getMatchingOrder({
      serviceId: row?.Type === WH_APPOINTMENT_GIVE_AWAY ? get(row, "whServiceId") : get(whService, "_id"),
      date: get(row, 'date'),
      // worldcare system use 0 ~ 6 for Monday ~ Sunday
      // but moment use 1 ~ 7 for Monday ~ Sunday
      dayOfWeek: moment(get(row, "date")).isoWeekday() - 1,
      from: get(row, "time"),
      to: get(computedRow.data, "expectedEndTime") && moment(get(computedRow.data, "expectedEndTime")).format("HH:mm"),

      addressPath: get(whBill, "addressPath"),
      packageLevel: get(row, "whPackageLevel.level"),
      whAppointmentId: row?._id,

      page: get(query, "page") || 1,
      ...(get(query, "limit") && { limit: get(query, "limit") }),
    });
    const whPartners = get(response, "docs");
    const appointments = get(response, "appointment");
    const whPartnersMergeProcessing = whPartners?.map(e => {
      const filterAppoinents = appointments?.filter(item => item.whPartnerId === e._id)
      if (filterAppoinents.length > 0) {
        const newTimes = filterAppoinents?.map(appointment => {
          const whSessionOfDayId = get(appointment, "whSessionOfDayId");
          const findSessionPrice = find(get(appointment,'snapshotService.package.sessionPrices'),{whSessionOfDayId});
          const {expectedEndDate,expectedEndTime,startDate}  = getExpectEndTime({sessionPrice : findSessionPrice,whAppointment : appointment});
          return ({
            time: get(appointment, 'time', ''),
            endTime: moment(expectedEndTime).format('HH:mm'),
            expectedEndDate : moment(expectedEndDate).format('YYYY-MM-DD HH:mm'),
            startDate:startDate.format("YYYY-MM-DD HH:mm"),
          })
        })
        return { ...e, appointments: newTimes }
      }
      return e
    })
    const optionWhPartners = whPartnersMergeProcessing.map(item => {
      return ({
        // ...item,
        name: get(item, "name"),
        _id: get(item, "_id"),
        
      })
    })

    const totalDocs = get(response, "totalDocs");

    // WARNING: do not call setClonedDataSource here because updateMatchingWhPartners is not triggered from a useEffect hook
    // therefore the `clonedDataSource` here may contain the old data before it was updated with `row`
    // we also don't need to map the whole object `clonedDataSource` when we already have exactly the index or the row
    // that we want to update. Just use `set` of lodash instead.
    // setClonedDataSource(clonedDataSource.map(item => {
    //   if (row.key !== item.key) return item;
    //   return {
    //     ...item,
    //     optionWhPartners
    //   }
    // }))

    // WARNING: this line does not work
    // set(clonedDataSource, `${index}.optionWhPartners`, optionWhPartners);

    setComputedAsyncDataSource(computedAsyncDataSource.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          optionWhPartners,
          optionWhPartnersLoading: false,
        }
      }
      return item
    }))
    return raw ? { docs: whPartnersMergeProcessing, totalDocs } : optionWhPartners;
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const LoadingInput = (component) => isWhAppointmentsLoading ? <LoadingOutlined spin/> : component;
  
  const canChangePackageLevel = useMemo(() => clonedDataSource?.some(item => !get(item,'dataGroupInits.length',null)),[clonedDataSource]);

  const columns = [
    {
      title: 'Số buổi',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'ID buổi hẹn',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        const time = moment(get(record, 'time'), 'HH:mm')
        const exist = clonedDataSource?.some(e => {
          if (get(record, 'whPartnerId') || JSON.stringify(get(e, '_id')) === JSON.stringify(get(record, '_id'))) return false
          const startTime = moment(get(e, 'time'), 'HH:mm')
          const endTime = moment(get(e, 'time'), 'HH:mm').add(get(e, 'totalDurationInMinutes'), 'minute')
          const isExist = time.isBetween(startTime, endTime, null, '[)')
          const isSamedate = moment(get(record, 'date')).isSame(moment(get(e, 'date')))
          return isExist && isSamedate

        })
        return <span style={{ color: exist ? 'red' : 'black' }}>{moment(text).format("DD/MM/YYYY")}</span>
      },
      editable: isMatchPolicy,
      component: 'DatePicker',
    },

    {
      display: "viewOnly",
      title: 'Gói dịch vụ',
      dataIndex: 'snapshotService',
      key: 'snapshotService',
      render: (snapshotService) => get(snapshotService, "package.packageLevelName.vi"),
    },
    {
      display: "editOnly",
      width: 200,
      title: 'Gói dịch vụ',
      dataIndex: 'whPackageLevelId',
      key: 'whPackageLevelId',
      render: (text) => <Typography.Text onClick={() => !canChangePackageLevel && toastr.error("Dịch vụ có sử dụng CCDC/NVL không được phép đổi gói dịch vụ!")}>{get(find(optionWhPackageLevels, { value: text }), "label")}</Typography.Text>,
      editable: isMatchPolicy && canChangePackageLevel,
      component: 'Select',
      options: optionWhPackageLevels,
    },

    {
      title: 'Giờ',
      width: 240,
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => {
        const time = moment(text, 'HH:mm')
        const exist = clonedDataSource?.some(e => {
          if (get(record, 'whPartnerId') || JSON.stringify(get(e, '_id')) === JSON.stringify(get(record, '_id'))) return false
          const startTime = moment(get(e, 'time'), 'HH:mm')
          const endTime = moment(get(e, 'time'), 'HH:mm').add(get(e, 'totalDurationInMinutes'), 'minute')
          const isExist = time.isBetween(startTime, endTime, null, '[)')
          const isSamedate = moment(get(record, 'date')).isSame(moment(get(e, 'date')))
          return isExist && isSamedate

        })
        return <span style={{ color: exist ? 'red' : 'black' }}>{text}</span>
      },
      editable: isMatchPolicy,
      component: 'TimePicker',

    },

    {
      display: "viewOnly",
      title: 'Thêm giờ',
      key: 'extraQuantityAndExtraDurationAndExtraTimeUnit',
      render: (text, record, index) => getExtraQuantityAndExtraDurationAndExtraTimeUnit(record),
    },
    {
      display: "editOnly",
      width: 240,
      title: 'Thêm giờ',
      key: 'extraQuantity',
      dataIndex: 'extraQuantity',
      render: (text, record, index) => {
        if (!get(record.sessionPrice, "extraTimeUnit")) {
          return "(Dv không thể thêm giờ)";
        }
        return humanize(
          get(record.sessionPrice, "extraTimeUnit"),
          get(record.sessionPrice, "extraDuration") * get(record, "extraQuantity"),
          true,
        )
      },
      editable: isMatchPolicy,
      component: 'DurationInputNumber',
    },
    {
      display: "editOnly",
      width: 180,
      title: 'Tổng thời lượng',
      key: 'totalDurationInMinutes',
      dataIndex: 'totalDurationInMinutes',
      render: (text, record, index) => {
        const timeUnit = get(record,'sessionPrice.timeUnit');
        return humanize(
          timeUnit,
          get(record, "totalDurationInMinutes"),
          true,
          true
        )
      },
    },
    {
      display: "editOnly",
      width: 240,
      title: 'Thời điểm dự kiến xong việc',
      key: 'expectedEndTime',
      dataIndex: 'expectedEndTime',
      render: (text, record, index) => {
        return <span>{moment(text).format("HH:mm")} <Typography.Text type='secondary'>({moment(get(record,'expectedEndDate')).format("DD/MM/YYYY")})</Typography.Text></span>
      },
    },

    {
      display: "viewOnly",
      title: 'Buổi',
      dataIndex: 'whSessionOfDay',
      key: 'whSessionOfDay',
      render: (wSD) => get(wSD, "name.vi"),
    },
    {
      display: "editOnly",
      title: 'Buổi',
      width: 120,
      dataIndex: 'whSessionOfDayId',
      key: 'whSessionOfDayId',
      render: (text, record, index) => get(find(record.optionWhSessionsOfDay, { value: text }), "label") || "(Chưa chọn buổi)",
      editable: isMatchPolicy,
      component: 'Select',
      // options: it is record-based because it depends on whPackageLevelId of the record
      // (since each whPackageLevel can have different available sessions of day)
      // so the options are not passed here but we use computedOptions instead.
      computedOptions: 'optionWhSessionsOfDay',
    },

    {
      display: 'viewOnly',
      width: 250,
      title: 'Giá ban đầu',
      align: 'right',
      key: 'priceAndVat',
      render: (text, record, index) => floorFormatter(get(record, "price") * (1 + get(record, "vat")) + get(record, "dealPrice",0)),
    },

    {
      display: 'viewOnly',
      width: 250,
      title: 'Chiết khấu',
      align: 'center',
      key: 'reduced',
      dataIndex : 'reduced',
      render: (item, record, index) => {
        return <>
        <p>{formatNumberThreeComma(totalReduced(item))}</p>
          <Typography.Link onClick={() => onOpenDiscountModal(record)}>{"(Xem chi tiết chiết khấu)"}</Typography.Link>
        </>
      }
    },
    {
      display: 'editOnly',
      width: 250,
      title: 'Chiết khấu',
      align: 'center',
      key: 'reduced',
      dataIndex : 'reduced',
      render: (item, record, index) => {
        return <>
        <p>{formatNumberThreeComma(totalReduced(item))}</p>
          <Typography.Link onClick={() => onOpenDiscountModal(record)}>{"(Xem chi tiết chiết khấu)"}</Typography.Link>
        </>
      }
    },

    {
      display: 'editOnly',
      width: 250,
      title: 'Trả trước',
      align: 'center',
      key: 'prePay',
      dataIndex: 'reduced',
      render: (reduced, record, index) => {
        return <p>{formatNumberThreeComma(Math.floor(get(reduced,'prepay',0)))}</p>
      }
    },
    {
      display: 'viewOnly',
      width: 250,
      title: 'Trả trước',
      align: 'center',
      key: 'prePay',
      dataIndex: 'reduced',
      render: (reduced, record, index) => {
        return <p>{formatNumberThreeComma(Math.floor(get(reduced,'prepay',0)))}</p>
      }
    },

    {
      display: 'editOnly',
      width: 200,
      title: 'Giá ban đầu',
      align: 'right',
      key: 'priceAndVat',
      render: (text, record, index) => floorFormatter(get(record.sessionPrice, "price") * (1 + get(record, "vat")) + get(record, "dealPrice",0)),
    },

    {
      display: "viewOnly",
      width: 250,
      title: 'Giá cộng thêm',
      align: 'right',
      key: 'extraPriceAndExtraQuantityAndVat',
      render: (text, record, index) => floorFormatter(get(record, "extraPrice") * get(record, "extraQuantity") * (1 + get(record, "vat"))),
    },
    {
      display: "editOnly",
      width: 200,
      title: 'Giá cộng thêm',
      align: 'right',
      key: 'extraPriceAndExtraQuantityAndVat',
      render: (text, record, index) => floorFormatter(get(record.sessionPrice, "extraPrice") * get(record, "extraQuantity") * (1 + get(record, "vat"))),
    },

    {
      // display: "editOnly",
      title: 'Phụ thu',
      width: 300,
      align: 'right',
      dataIndex: 'bonus',
      key: 'bonus',
      render: (value) => formatter(value),
    },

    {
      // display: "editOnly",
      title: 'Tạm tính',
      width: 300,
      align: 'right',
      dataIndex: 'grandTotal',
      key: 'grandTotal',
      render: (value, rc) => {
        return formatter(value)
      },
    },

  

    {
      display: "viewOnly",
      width: 300,
      title: 'Số tiền phải thu (mỗi buổi)',
      align: 'right',
      dataIndex: 'actualCost',
      key: 'actualCost',
      render: (value) => formatter(value),
    },
    {
      display: "editOnly",
      width: 300,
      title: 'Số tiền phải thu (mỗi buổi)',
      align: 'right',
      dataIndex: 'actualCost',
      key: 'actualCost',
      render: (text, record) => {
        const badges = get(record, "badges");
        return badges && badges.actualCost
          ? <Tooltip title={badges.actualCost.title}>
            <Badge color={badges.actualCost.color}>
              {floorFormatter(text)}
            </Badge>
          </Tooltip>
          : floorFormatter(text)
      },
      // editable: isMatchPolicy,
      component: 'InputNumber',
    },

    {
      title: 'Thời gian checkin/checkout',
      width: 240,
      key: 'checkInOut',
      render: (text, record, index) => (
        <CheckInOut record={record} />
      )
    },

    {
      display: "editOnly",
      width: 200,
      title: 'Đối tác',
      dataIndex: 'whPartner',
      key: 'whPartner',
      render: (text, record, index) => {
        const processing = get(record, 'checkIn')
        const whPartnerId = get(record, "whPartnerId")
        const matchingPartner = get(computedAsyncDataSource, `${index}.optionWhPartners`)?.find(item => get(item, "_id") === whPartnerId);
        const optionWhPartnersLoading = get(computedAsyncDataSource, `${index}.optionWhPartnersLoading`);

        const whPartnerName = get(text, "name") || get(text, "label")
        return (
          <Typography.Link
            style={{
              color: (
                !matchingPartner
                && get(record, "status") === WH_APPOINTMENT_STATUS.NEW
                && typeof optionWhPartnersLoading === "boolean"
              ) ? 'red' : ''
            }}
            // href={'#'}
            onClick={() => {
              if (processing) return
              if (isMatchPolicy) {
                setSelectedRowIdx(index);
                setIsOpen(true);
              }
            }}
          >
            {whPartnerName || "(Chưa chọn đối tác)"}
          </Typography.Link>
        )
      },
      // editable: isMatchPolicy, // disable using component DebounceSelect here as we changed to the Modal to select the partners
      editable: false,
      component: 'DebounceSelect',
      computedAsyncOptions: 'optionWhPartners',
      computedAsyncOptionsLoading: 'optionWhPartnersLoading',
      triggerFetchingOptions: updateMatchingWhPartners,
      required: false,
    },

    {
      title: 'CCDC/NVL',
      width: 1000,
      dataIndex: 'Equiment',
      key: 'Equiment',
      render: (item, record, index) => {
        const groupInitsEquipment = getDataGroupInit(record);
        const groupInitMaterial = getDataGroupInitMaterial(record);
        const quantityConfirmed = getQuantityConfirm(get(record, 'listProduct', []));
        const totalGroupInitAll = get(groupInitsEquipment,'length',0) + get(groupInitMaterial,'length',0)
        const { isHaveGroupInit, message, haveWorldhealth, haveClinic } = checkTypeGroupToGetMessage({ groupInitsEquipment,groupInitMaterial });
        const { dataEquipment, isHaveProductBlocked, dataMaterial } = SplitDataWarehouse(get(record, 'listProduct', []));
        return isHaveGroupInit ? 
        <Row>
          {haveWorldhealth ? <Col span={24}>Đã gắn {LoadingInput(dataEquipment?.length.toString())}/{groupInitsEquipment?.length?.toString()} CCDC {isHaveProductBlocked && <Tooltip title={'Có thiết bị hoặc nhóm thiết bị hoặc danh mục ngừng hoạt động, vui lòng kiểm tra lại'}>
            <InfoCircleTwoTone twoToneColor={'red'} />
          </Tooltip>}</Col> : null}
          {haveClinic ? <Col span={24}>Đã gắn {LoadingInput(dataMaterial?.length.toString())}/{groupInitMaterial?.length?.toString()} NVL </Col> : null}
          <Col span={24}>
            <Typography.Link onClick={() => handleOpenModalWareHouse(get(record, '_id'))}>({message})</Typography.Link>
          </Col>
        <Tag className='no-border' color={quantityConfirmed === totalGroupInitAll ? 'success' : 'warning'}>Đã xác nhận {quantityConfirmed}/{totalGroupInitAll}</Tag>
        </Row> : null

      }
    },
    {
      title: 'Giá khấu hao',
      width: 250,
      dataIndex: 'listProduct',
      key: 'listProduct',
      align: 'center',
      render: (text, record, index) => {
        const totalPrice = calculateTotalValueDepreciation(get(record, 'listProduct', []))
        return LoadingInput(<Text strong>{formatNumberThreeCommaToFixed(totalPrice || 0)}</Text>)
      }
    },

    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'humanizedStatus',
      render: (status) => (
        <Tag
          color={get(mapAppoinemtStatusTag[status], "color")}
        >
          {get(mapAppoinemtStatusTag[status], "name")}
        </Tag>
      )
    },

    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <Checkbox checked={status === "COMPLETED"} />
    },
    {
      width: 300,
      title: 'Thông tin đính kèm',
      dataIndex: 'attachments',
      key: 'attachments',
      render: (data, record, index) => {
        if (Array.isArray(data)) {
          return data.map((file, idx) => (
            <Button
              target='_blank'
              type='link'
              href={get(file, "url")}
            >
              {get(file, "name")}
            </Button>
          ))
        };
        return (
          <></>
        );
      }
    },
    {
      width: 300,
      title: 'Chứng từ',
      // dataIndex: 'vouchers',
      key: 'vouchers',
      render: (data, record, index) => {
        if (get(record, "whReceiptVoucher.length")) {
          return (
            <WithOrPermission permission={GROUP_POLICY(CORE_ACTION.READ)?.MANAGE_VOUCHER_IN_WHBILL}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setWhReceiptVoucher(get(record, "whReceiptVoucher.0"));
                setIsOpenReceiptVoucherModal(true);
              }}
            >
              Phiếu thu
            </Button>
            </WithOrPermission>
          )
        }
        return <></>;
      }
    },
    {
      width: 300,
      title: 'Tạo Chứng từ',
      // dataIndex: 'vouchers',
      key: 'vouchers',
      render: (data, record, index) => {
        return (
          <Row>
            <WithOrPermission permission={GROUP_POLICY(CORE_ACTION.WRITE)?.MANAGE_VOUCHER_IN_WHBILL}>
            <Col>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenCreateReceiptVouchersModal(record);
                }}
              >
                Phiếu thu
              </Button>
            </Col>
            <Col>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenPaymentVouchersModal(record);
                }}
              >
                Phiếu chi
              </Button>
            </Col>
            </WithOrPermission>
          </Row>
        )
      }
    },
    {
      width: 300,
      title: 'Trạng thái chứng từ',
      // dataIndex: 'vouchers',
      key: 'voucherStatus',
      render: (text, record, index) => {
        if (get(record, "whReceiptVoucher.0.status")) {
          return <WhVoucherStatusTag status={get(record, "whReceiptVoucher.0.status")} />
        }
        return <></>;
      }
    }
    ,
    {
      width: 300,
      title: 'Đánh Giá',
      key: 'rating',
      render: (text, record, index) => {
        if (record.rating) {
        return <ControlRating billId={get(record,'whBillId')} billItemId={get(record,'whBillItemId')} mutateAppointment={mutateAppointment} rating={get(record,'rating')} id={get(record,'_id')} infoRating={infoRating}/>
        }
        return <></>;
      }
    }
  ];

  const renderSummary = pageData => {
    console.log(pageData,'pageData');
    const totalGrandTotal = pageData.reduce((prev, curr) => {
      return prev + get(curr, "grandTotal");
    }, 0);
    const totalActualCost = pageData.reduce((prev, curr) => {
      return prev + get(curr, "actualCost");
    }, 0);
    const totalDepreciation = pageData.reduce((prev, curr) => {
      const totalPrice = calculateTotalValueDepreciation(get(curr, 'listProduct', []))
      return prev + totalPrice;
    }, 0);

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell align="right" colSpan={mode === COMPONENT_MODES.EDIT ? 12 : 10}>
          <h5>Tổng tạm tính</h5>
          <h5>{floorFormatter(totalGrandTotal)}</h5>
        </Table.Summary.Cell>
        {/* <Table.Summary.Cell align="right" colSpan={1}><h4></h4></Table.Summary.Cell> */}
        <Table.Summary.Cell align="right" colSpan={1}>
          <h5>Tổng phải thu</h5>
          <h5>{floorFormatter(totalActualCost)}</h5>
        </Table.Summary.Cell>
        <Table.Summary.Cell align="right" colSpan={4}>
          <h5>Tổng giá khấu hao</h5>
          <h5>{floorFormatter(totalDepreciation)}</h5>
        </Table.Summary.Cell>
        {/* <Table.Summary.Cell align="left" colSpan={1}><h4></h4></Table.Summary.Cell> */}
      </Table.Summary.Row>
    )
  }

  const handleSave = (row, dataIndex) => {
    const newData = [...clonedDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    let exclude = [];
    switch (dataIndex) {
      // in case that user changes date/time or even the actualCost,
      // don't try to update it and exclude it from getComputedRowProps
      case 'actualCost':
      case 'date':
      case 'time':
      case 'whPartner':
        exclude = ['actualCost']
        break;

      default:
        break;
    }

    const { data: computedRowData, shouldUpdateActualCost } = getComputedRowProps(row, exclude)
    const computedRow = {
      ...row,
      ...computedRowData,
    }
    newData.splice(index, 1, { ...item, ...computedRow });
    setClonedDataSource(newData);

    let newActualCost;
    if (shouldUpdateActualCost) {
      onWhAppointmentActualCostChange();
    };

    // WARNING: be aware that this method is trigger not inside a useEffect hook
    // therefore it should not call any `setClonedDataSource` inside.
    // it should use `set` of lodash instead.
    updateMatchingWhPartners(row, index);
  };

  const onOk = () => {
    const { time, expectedEndTime } = clonedDataSource[selectedRowIdx]
    const endTimeExpected = moment(expectedEndTime).format("HH:mm")
    const selectedWhPartner = modalRef.current.getSelectedWhPartner();
    const dayOfWeek = moment(get(clonedDataSource[selectedRowIdx], "date")).isoWeekday() - 1;
    let processing = false;
    if(selectedWhPartner){
      let sortWorkingTime = [...get(selectedWhPartner, `workingTime.${dayOfWeek}`)];
      sortWorkingTime?.sort((a,b) => moment(get(a,'[1]'),'HH:mm').hour() - moment(get(b,'[1]'),'HH:mm').hour());
      let maxEndTime = last(sortWorkingTime)?.[1];
      const appointments = get(selectedWhPartner,'appointments');
      const convertTimeInDateRow= convertWhAppointmentProcessing({appointments,row:clonedDataSource[selectedRowIdx],maxEndTime})
      // const isProcess = appointments?.some(e =>{
      //   if(!appointments || appointments?.length === 0) return false
      //   const startTime = moment(get(e,'time'),'HH:mm')
      //   const endTime = moment(get(e,'endTime'),'HH:mm')
      //   const start = moment(time,'HH:mm')
      //   const end = moment(endTimeExpected,'HH:mm')
      //   const isProcessing = start.isBetween(startTime,endTime,undefined,'[]') || end.isBetween(startTime,endTime,undefined,'[]')
      //   return isProcessing
      // })
      const isProcessing = convertTimeInDateRow?.some(e => e.time === time && e.endTime === endTimeExpected);
            const isInWhAppointmentProcessing = convertTimeInDateRow?.some(whAppointmentOfPartner => {
              const startTime_ = moment(get(whAppointmentOfPartner,'time'),'HH:mm');
              const endTime = moment(get(whAppointmentOfPartner,'endTime'),'HH:mm');
              return isRangeTimeIsBetweenRangeTime(moment(time,'HH:mm'),moment(endTimeExpected,'HH:mm'),startTime_,endTime)
            });
  
             processing = isProcessing || isInWhAppointmentProcessing;
    }
  
    if (processing) {
      
      toastr.error("Đối tác đang thực hiện lịch hẹn ở khung giờ bạn chọn, vui lòng chọn lại!")
    }
    else {
      clonedDataSource[selectedRowIdx].whPartner = selectedWhPartner;
      clonedDataSource.forEach(e => e.isSelect = false)
      clonedDataSource[selectedRowIdx].isSelect = true;
      handleSave(clonedDataSource[selectedRowIdx], 'whPartner');
      setIsOpen(false);
      setTimeout(() => {
        onUpdate()
      }, 100);
    }
  }

  const tableProps = {
    // override default components to editable components in edit mode
    ...(mode === COMPONENT_MODES.EDIT && { components }),

    // pass extra props to every single editable cell in edit mode
    columns: mode === COMPONENT_MODES.EDIT ?
      columns.filter(col => !col.display || col.display !== "viewOnly")
        .map((col) => {
          if (!col.editable) {
            return col;
          }
          return {
            ...col,
            onCell: (record, rowIndex) => {
              let options = null;
              let optionsLoading = null;
              let triggerFetchingOptions = null;
              let availableTime = null;
              if (col.options) {
                options = col.options;
              }
              if (col.computedOptions) {
                options = get(record, col.computedOptions)
              }
              if (col.computedAsyncOptions) {
                optionsLoading = get(
                  computedAsyncDataSource[rowIndex],
                  col.computedAsyncOptionsLoading
                ) || false;
                options = get(computedAsyncDataSource[rowIndex], col.computedAsyncOptions);
                triggerFetchingOptions = col.triggerFetchingOptions
              }
              if (col.component === 'TimePicker') {
                availableTime = {
                  ...get(record, "whSessionOfDay.whSessionOfDay"),
                }
              }

              let disabled = false;
              if (col.dataIndex === "extraQuantity" && !get(record.sessionPrice, "extraTimeUnit")) {
                disabled = true;
              }

              return {
                record,
                rowIndex,
                editable: col.editable && !disabled && ![
                  WH_APPOINTMENT_STATUS.IN_PROGRESS,
                  WH_APPOINTMENT_STATUS.COMPLETED,
                ].includes(get(record, "status")),
                dataIndex: col.dataIndex,
                title: col.title,

                component: col.component,
                options,
                optionsLoading,
                triggerFetchingOptions,
                availableTime,
                required: col.required,

                handleSave,
              }
            },
          };
        }) : columns.filter(col => !col.display || col.display !== "editOnly"),

    // use cloned data source so that it can be submitted when complete
    dataSource: mode === COMPONENT_MODES.EDIT
      ? clonedDataSource
      : dataSource,
  };

  const whAppointmentSelected = useMemo(() => clonedDataSource?.find(e => get(e, '_id') === idVisibleModalWareHouse), [clonedDataSource, idVisibleModalWareHouse]);
  return (
    <>
      <Table
        {...tableProps}
        className="wh-appointments-by-block-table"
        footer={null}
        pagination={false}
        rowClassName={(record, index) => {
          let className = '';
          switch (get(record, "status")) {
            case WH_APPOINTMENT_STATUS.COMPLETED:
              className = "completed-row"
              break;

            case WH_APPOINTMENT_STATUS.IN_PROGRESS:
              className = "in-progress-row"
              break;

            case WH_APPOINTMENT_STATUS.CANCELLED:
              className = "cancelled-row"
              break;

            default:
              className = "oneRow"
              break;
          }
          return className;
        }}
        rowKey={(rc) => rc._id}
        size="small"
        summary={renderSummary}
        scroll={{ x: 3500 }}
      />
      <Modal
        destroyOnClose // Warning: this is important since it's needed to load updated `row` to this modal
        onCancel={() => setIsOpen(false)}
        onOk={onOk}
        visible={isOpen}
        width={1200}
        footer={
          <Row align='center' className='desciptionBox' justify="space-between">
            <Col className='desciptionBox-title' lg={18} md={24}>
              <Row><Tag color="processing"></Tag>: Thời gian ĐT có thể nhận dịch vụ  (ĐT chưa nhận đơn hàng trong ngày)</Row>
              <Row><Tag color="warning"></Tag>: Thời gian ĐT có thể nhận dịch vụ (ĐT đã nhận đơn hàng trong ngày)</Row>
              <Row><Tag></Tag>: Thời gian ĐT không thể nhận dịch vụ</Row>
              <Row><Tag color="error"></Tag>: Thời gian ĐT đang thực hiện dịch vụ</Row>
            </Col>
            <Row className='desciptionBox-action'>
              <Button key="back" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button key="submit" type="primary" onClick={onOk}>
                OK
              </Button>
            </Row>
          </Row>
        }
      >
        <WhPartners
          onOk={onOk}
          ref={modalRef}
          row={clonedDataSource[selectedRowIdx]}
          rowIdx={selectedRowIdx}
          updateMatchingWhPartners={updateMatchingWhPartners}
          whBill={whBill}
        />
      </Modal>
      <Modal
        centered
        footer={null}
        onCancel={() => setIsOpenReceiptVoucherModal(false)}
        onOk={() => setIsOpenReceiptVoucherModal(false)}
        title={`Phiếu thu`}
        visible={isOpenReceiptVoucherModal}
        width={1366}
      >
        <WhReceiptVoucherForm
          whReceiptVoucherId={get(whReceiptVoucher, "_id")}
          onClose={() => setIsOpenReceiptVoucherModal(false)}
          onCancel={() => setIsOpenReceiptVoucherModal(false)}
        />
      </Modal>

      <Modal className='custom-modal--closeButton' closeIcon={null} destroyOnClose width={'auto'} title={"Danh sách thiết bị, vật liệu được gắn cho buổi hẹn"} visible={visibleModalWareHouse} onCancel={handleCloseModalWareHouse} footer={null}>
      <AssignWarehouseAppointmentProvider
      mutateAppointment={mutateAppointment}
          onCancel={handleCloseModalWareHouse}
          whAppointmentId={idVisibleModalWareHouse} 
          dataSourceWhBillItem={dataSource}
          whAppointmentSelected={whAppointmentSelected}
          listProduct={get(whAppointmentSelected,'listProduct',[])}
          mergedWhAppointments={mergedWhAppointments}
      >
        <AssignEquiment />
      </AssignWarehouseAppointmentProvider>
      </Modal>
      <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={onClosePaymentVouchersModal}
        onOk={() => { }}
        title={`Phiếu chi - Đơn hàng ${get(whBill, "billNumber")} - Dịch vụ ${get(selectAppointment, 'code', '')} - ${get(whBill, 'customerAddress.fullName')}`}
        width={1500}
        visible={isOpenPaymentVoucherModal}
      >
        <WhPaymentVoucherForm
          whBill={whBill}
          whBillItem={whBillItem}
          onCancel={onClosePaymentVouchersModal}
          whAppointment={selectAppointment}
          additional={ADDITIONAL_VOUCHER.whBillItem}
        />
      </Modal>
      <Modal
        centered
        destroyOnClose
        footer={false}
        onCancel={onCloseCreateReceiptVouchersModal}
        onOk={() => { }}
        title={`Phiếu thu - Đơn hàng ${get(whBill, "billNumber")} - Dịch vụ ${get(selectAppointment, 'code', '')} - ${get(whBill, 'customerAddress.fullName')}`}
        width={1500}
        visible={isOpenCreateReceiptVoucherModal}
      >
        <WhReceiptVoucherForm
          whBill={whBill}
          whBillItem={whBillItem}
          whAppointment={selectAppointment}
          onCancel={onCloseCreateReceiptVouchersModal}
          additional={ADDITIONAL_VOUCHER.whBillItem}
        />
      </Modal>

      <Modal centered
        destroyOnClose
        footer={false}
        onCancel={onCloseDiscountModal}
        title={`Chi tiết chiết khấu dịch vụ ${get(selectAppointment,'whBillItem.code','')}`}
        visible={isOpenDiscountModal} >
        <TableDiscount whAppointment={selectAppointment} />
      </Modal>

    </>
  )
})

export default WhAppointmentsByBlockTable;
