import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';
import { dataHighlight } from '~/api/highlight';
import toastr from 'toastr';

function* getWhHighlight() {
  try {
    const data = yield call(Api.highlight.getAll);
    yield put({ type: Types.GET_WH_HIGHLIGHT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_HIGHLIGHT_FAILED,
      payload: error.message
    });
  }
}

function* createWhHighlight(action) {
  try {
    const data = yield call(Api.highlight.create, action.payload);
    yield put({ type: Types.CREATE_WH_HIGHLIGHT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_WH_HIGHLIGHT_FAILED,
      payload: error.message
    });
  }
}

function* updateWhHighlight(action) {
  try {
    let data = yield call(Api.highlight.update, action.payload);
    yield put({ type: Types.UPDATE_WH_HIGHLIGHT_SUCCESS, payload: data.data });
    toastr.success("Cập nhật thành công")

  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_HIGHLIGHT_FAILED,
      payload: error.message
    });
  }
}

function* deleteWhHighlight({ payload }) {
  try {
    yield call(Api.highlight.delete, payload);
    yield put({ type: Types.DELETE_WH_HIGHLIGHT_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_HIGHLIGHT_FAILED,
      payload: error.message
    });
  }
}

export default function* whHighlight() {
  yield takeLatest(Types.GET_WH_HIGHLIGHT_REQUEST, getWhHighlight);
  yield takeLatest(Types.DELETE_WH_HIGHLIGHT_REQUEST, deleteWhHighlight);
  yield takeLatest(Types.CREATE_WH_HIGHLIGHT_REQUEST, createWhHighlight);
  yield takeLatest(Types.UPDATE_WH_HIGHLIGHT_REQUEST, updateWhHighlight);
}
