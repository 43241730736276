import axios from 'axios';
import { logout } from '~/utils/helper';
import { BASE_URL } from '../constants/defaultValue';


class ErrorRequest {
  constructor(msg, type, code, httpCode, additionalProperties) {
    this.message = msg || 'Internal server error';
    this.httpCode = httpCode || 500;
    this.type = type || 'internal_server_error';
    this.code = String(code || this.type).toUpperCase();
    this.additionalProperties = additionalProperties || {};
  }
}

export function setupAxios() {
  axios.defaults.baseURL = `${BASE_URL}/api/v1/`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
}

export function setAxiosToken(token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token;
}

export function setAxiosPartnerHost() {
  let url = window.location.origin
  if(process.env.NODE_ENV==='development'){
    url = 'https://dashboard.congtyso.com'
  }
  axios.defaults.headers.common.partnerhost = url
}

export function setAxiosCompanyId(companyId) {
  axios.defaults.headers.common['companyId'] = companyId;
}

export function removeAxiosToken() {
  axios.defaults.headers.common.Authorization = '';
}

const responseBody = (res) => res.data;
const responseCatch = (err) => {
  if(err?.response?.status === 401){
    // handle 401 status Not Authenticated;
    logout();
  }
  if(err?.response){
    const { message, httpCode, type, code, additionalProperties } = err?.response?.data??{}
    throw new ErrorRequest(message,type,code,httpCode, additionalProperties)
  }
  throw new Error(err)
}
const requester = {
  get: (url, params, config = {}) =>
    axios
      .get(url, {
        params,
        ...config,
      })
      .then(responseBody)
      .catch(responseCatch)
      ,

  post: (url, data, config = {}) =>
    axios.post(url, data, config).then(responseBody).catch(responseCatch),
  postFormData: (url, data, config = {}) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', data);
    return axios({
      method: 'post',
      url,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(responseBody).catch(responseCatch);
  },
  put: (url, data, config = {}) =>
    axios.put(url, data, config).then(responseBody).catch(responseCatch),
  delete: (url, data) => axios.delete(url, { data }).then(responseBody).catch(responseCatch)
};

export default requester;
