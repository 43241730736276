import React, { useEffect, useMemo, useState } from 'react';

import { get, keys } from 'lodash';
import { Button, Checkbox, Col, Modal, Row, Table, Tag } from 'antd';

import {
  useDeleteWhPaymentVoucher,
  useResetWhPaymentVoucher,
  useUpdateWhPaymentVoucher,
  useWhPaymentVoucherPaging,
  useWhPaymentVoucherQueryParams,
  useWhPaymentVouchers,
} from '~/hooks/whPaymentVoucher';

import SkeletonTable from '~/components/Utils/SkeletonTable';

import { fromJSON } from './parser';
import WhPaymentVoucherForm from './Form/WhPaymentVoucherForm';
import WhVoucherStatusTag from './components/WhVoucherStatusTag';
import moment from 'moment';
import ShowTotalMoney from './ShowTotalMoney';
import { useProfile } from '~/hooks';

const WhPaymentVouchers = ({
  history,
  status,
  whBill,
  useLocalPagination,
  setPaging,
  setArrIds,
  setQueryPayment,
  noShowTitle,
  useTime,
  billNumber, //
  branchIds,
  // to avoid conflict the `page` url param when another component is using it
}) => {
  const [current, setCurrent] = useState(1);
  const [arrCheckBox, setArrCheckBox] = useState([])

  const [profile] = useProfile();
  const [query, onPagingChange] = useWhPaymentVoucherQueryParams(
    get(whBill, "billNumber"),
    useLocalPagination && current,
    useTime,
    branchIds,
    useLocalPagination,
  );
  const [whPaymentVouchers, isLoading] = useWhPaymentVouchers(query);
  const [, deleteWhPaymentVoucher] = useDeleteWhPaymentVoucher();
  const [isSubmitLoading, updateWhPaymentVoucher] = useUpdateWhPaymentVoucher();
  const paging = useWhPaymentVoucherPaging();

  const [isOpen, setIsOpen] = useState(false);
  const [noBillId,setisNoBillId] = useState(null);
  const [whPaymentVoucherId, setWhPaymentVoucherId] = useState();
  const [typeVoucher, setTypeVoucher] = useState(null);

    const handleClosePaymentVoucherForm = () => {
      setIsOpen(false);
      setWhPaymentVoucherId(null)
      setisNoBillId(null)
      setTypeVoucher(null);
    }
  const onPagingChangeLocal = ({ current, pageSize, total }, someObj) => {
    setCurrent(current);
  };
  // useEffect(() => { // Render Infinity
  //   setPaging(paging)
  // }, [paging])
  
    useEffect(() => {
      setQueryPayment(query)
    },[query])

  useResetWhPaymentVoucher();
  const mergedWhPaymentVouchers = useMemo(() => {
    return whPaymentVouchers.map(item => {

      // Data flow: API => redux reducer => hook => parser "fromJSON" => this component.
      // The parser is to help dynamically adapt to API changes.
      // Please consider changing data flow to: API => redux reducer => parser "fromJSON" => hook => this component
      // so it should be no need to implement parser in every single component.
      const whPaymentVoucher = fromJSON(item);

      return {
        ...whPaymentVoucher,
      }
    })
  }, [
    whPaymentVouchers,
  ])

  // const onSearch = () => {
  //   let searchParams = `?page=${query.page}&limit=${query.limit}`;
  //   if (keyword) searchParams += `&keyword=${keyword}`;

  //   history.push({
  //     pathname: '/wh-partner/list',
  //     search: searchParams
  //   });
  // };

  const nameService = (record) =>{
    if (get(record, "whBillItem.snapshotService.detailName.vi"))
          return `${get(record, "whBillItem.snapshotService.code")} - ${get(record, "whBillItem.snapshotService.name.vi")} - ${get(record, "whBillItem.snapshotService.detailName.vi")}`
        else 
          return `${get(record, "whBillItem.snapshotService.code")} - ${get(record, "whBillItem.snapshotService.name.vi")}`
  }

  // Get list Ids
  const onChangeCheckBox = (e, id) => {
    if (e) {
    setArrCheckBox([...arrCheckBox, id])
    } else {
      const getIndex = arrCheckBox.findIndex((itemId, index) => itemId === id)
      const newArr = arrCheckBox.toSpliced(getIndex, 1)
      setArrCheckBox(newArr)
    }
  } 
  useEffect(() => {
    if (typeof setArrIds === 'function') {
      setArrIds(arrCheckBox)
    }
  }, [arrCheckBox, setArrIds])

  const columns = [
    {
      title: 'Mã phiếu chi',
      dataIndex: 'code',
      key: 'code',
      render: (text, record, index) => {
        return (
          <Button
            type='link'
            onClick={() => {
              setWhPaymentVoucherId(get(record, "_id"));
              setIsOpen(true);
              setTypeVoucher(get(record, 'typeVoucher'))
              if(get(record,'whBillId')){
              setisNoBillId(false)
              }
              else{
                setisNoBillId(true)
              }
            }}
          >
            {text}
          </Button>
        )
      }
    },
    {
      title: 'Số chứng từ',
      dataIndex: 'issueNumber',
      key: 'issueNumber',
      render: text => text || "-",
    },
    {
      title: 'Mã đơn hàng',
      key: 'billNumber',
      align:'center',
      render: (text, record, index) => get(record, "whBill.billNumber")?get(record, "whBill.billNumber"):'-',
    },
    {
      title: 'Nội dung',
      // dataIndex: 'whBill.billNumber',
      key: 'reason',
      dataIndex : 'reason',
      // render: (text, record, index) => get(record, "whBill[0].billNumber") ? `Đơn hàng ${get(record, "whBill[0].billNumber",'')}` : get(record,"reason",''),
    },
    {
      title: 'ID dịch vụ',
      dataIndex: 'whServiceId',
      key: 'whServiceId',
      render: (text, record, index) => {
        return get(record, "whBillItem.snapshotService") ? (nameService(record)) : "-"
      }
    },
    {
      title: 'Số tiền',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text, record, index) => text?.toLocaleString(),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record, index) => moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    
    {
      title: 'Ngày duyệt',
      dataIndex: 'dateApproved',
      key: 'dateApproved',
      render: (text, record, index) => text && moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => <WhVoucherStatusTag status={text} />
    },
    {
      title: 'Lựa chọn',
      key: '_id',
      width: 80,
      align: 'center',
      render: (item, record) =>
      {
        const id = record._id
        return <Checkbox
        defaultChecked= {arrCheckBox.includes(id)}
        onChange={(e)=>onChangeCheckBox(e.target.checked, id)}
      />}
    },
    
  ];
  
  if(get(profile,'isSuperAdmin')){
    columns.push({
      title: 'Chi nhánh',
      dataIndex: 'branch',
      key: 'branch',
      align : 'center',
      fixed:'right',
      render: (branch, record, index) => get(branch,'name',''),
    },)
  }

  const keySum = {
    totalAmount: 'totalAmount',
    Payment: 'Payment'
  }
  
  return (
    // <div className="page-wraper page-content">
    <div>
      <div className="container-fluid">
        {/* <Breadcrumb title="Danh sách đối tác" /> */}

        {/* <div className="page-wraper__header">
          <Search
            style={{ width: 200 }}
            placeholder="Tìm đối tác"
            enterButton
            onSearch={onSearch}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />

          <Link to={PATH_APP.whPaymentVoucher.create}>
            <Button type="primary" style={{ float: 'right' }}>
              {' '}
              Thêm mới
            </Button>
          </Link>
        </div> */}
        <ShowTotalMoney 
        branchIds={branchIds}  
        billNumber = {billNumber} 
        totalMoneyPage ={whPaymentVouchers}
         keySumPage ={keySum.totalAmount} 
         keyPageName= {keySum.Payment}
          useLocalPagination={useLocalPagination}
          queryProp = {query}
         />
        {isLoading ? (
          <SkeletonTable
            rowCount={whPaymentVouchers.length}
            columns={columns}
            pagination={paging}
          />
        ) : (
            <>
            
            
            <Table
            // title={()=> !noShowTitle &&  ShowTotalMoney(whPaymentVouchers, keySum.totalAmount, keySum.Payment) }
            rowKey={(rc) => rc._id}
            columns={columns}
            dataSource={mergedWhPaymentVouchers}
            // onChange={onPagingChange}
            onChange={useLocalPagination
              ? onPagingChangeLocal
              : onPagingChange
            }
            size="middle"
            pagination={{
              ...paging,
              // ...(useLocalPagination && { current }),
              showTotal: (total) => `Tổng cộng: ${total} `
            }}
          />
            </>
        )}
      </div>
      <Modal
      destroyOnClose
        centered
        footer={null}
        onCancel={handleClosePaymentVoucherForm}
        onOk={handleClosePaymentVoucherForm}
        title={`Phiếu chi`}
        visible={isOpen}
        width={1366}
      >
        <WhPaymentVoucherForm
          noBillId={noBillId}
          whPaymentVoucherId={whPaymentVoucherId}
          onClose={handleClosePaymentVoucherForm}
          onCancel={handleClosePaymentVoucherForm}
          isShowBlock={!!typeVoucher}
        />
      </Modal>
    </div>
  );
};

export default WhPaymentVouchers;
