import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './redux/store';
import toastr from 'toastr';
import vi_VN from 'antd/lib/locale-provider/vi_VN';
import { ConfigProvider } from 'antd';
import 'moment/locale/vi';// Import the Vietnamese locale

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-bottom-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
};



const customLocale = {
  ...vi_VN, // Copy the existing Vietnamese locale
  DatePicker: {
    ...vi_VN.DatePicker,
    lang: {
      ...vi_VN.DatePicker.lang,
      monthFormat: 'MMMM', // Customize the month format
    }
  }
};
const app = (
  <Suspense fallback={'...'}>
    <Provider store={store}>
      <ConfigProvider locale={customLocale}>
        <PersistGate loading={'...'} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </ConfigProvider>
    </Provider>
  </Suspense>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
