import { Col, Row } from 'antd'
import Text from 'antd/lib/typography/Text'
import { get } from 'lodash'
import { formatNumberThreeComma } from '~/hooks/utils'
import RewardItem from './RewardItem'
const NotInfinityCount = (count) => <span>{count}/&#8734;</span>
export default function ItemProcessRepeatAction({ process,layout }) {
  const maxCountReceive = get(process, 'condition.value.maxCountBonus')
  return (
    <Row justify='space-between' gutter={16}>
      <Col {...layout.left}>
        <Text strong>{get(process, 'name', '')}</Text>
        <RewardItem process={process} />
      </Col>
      <Col {...layout.right}>
        <Text>
          {maxCountReceive && `${formatNumberThreeComma(get(process, 'countProcess', 0))}/${maxCountReceive}`}
          {!maxCountReceive && NotInfinityCount(get(process, 'countProcess', 0))}
        </Text>
      </Col>
    </Row>
  )
}
