import * as Types from '../../constants/actionTypes';
  
  export const getCoreValues = query => ({
    type: Types.GET_CORE_VALUES_REQUEST,
    payload: query
  });
  export const getCoreValue = id => ({
    type: Types.GET_CORE_VALUE_REQUEST,
    payload: id
  });
  export const updateCoreValue = instance => ({
    type: Types.UPDATE_CORE_VALUE_REQUEST,
    payload: instance
  });
  export const createCoreValue = instance => ({
    type: Types.CREATE_CORE_VALUE_REQUEST,
    payload: instance
  });
  export const deleteCoreValue = id => ({
    type: Types.DELETE_CORE_VALUE_REQUEST,
    payload: id
  });
  export const resetCoreValueState = () => ({
    type: Types.RESET_CORE_VALUE_STATE
  });
