export const GET_WH_REPORT_MEDICINES_REQUEST =
  'GET_WH_REPORT_MEDICINES_REQUEST';
export const GET_WH_REPORT_MEDICINES_SUCCESS =
  'GET_WH_REPORT_MEDICINES_SUCCESS';
export const GET_WH_REPORT_MEDICINES_FAILED = 'GET_WH_REPORT_MEDICINES_FAILED';

export const GET_WH_REPORT_MEDICINE_REQUEST = 'GET_WH_REPORT_MEDICINE_REQUEST';
export const GET_WH_REPORT_MEDICINE_SUCCESS = 'GET_WH_REPORT_MEDICINE_SUCCESS';
export const GET_WH_REPORT_MEDICINE_FAILED = 'GET_WH_REPORT_MEDICINE_FAILED';

export const CREATE_WH_REPORT_MEDICINE_REQUEST =
  'CREATE_WH_REPORT_MEDICINE_REQUEST';
export const CREATE_WH_REPORT_MEDICINE_SUCCESS =
  'CREATE_WH_REPORT_MEDICINE_SUCCESS';
export const CREATE_WH_REPORT_MEDICINE_FAILED =
  'CREATE_WH_REPORT_MEDICINE_FAILED';

export const UPDATE_WH_REPORT_MEDICINE_REQUEST =
  'UPDATE_WH_REPORT_MEDICINE_REQUEST';
export const UPDATE_WH_REPORT_MEDICINE_SUCCESS =
  'UPDATE_WH_REPORT_MEDICINE_SUCCESS';
export const UPDATE_WH_REPORT_MEDICINE_FAILED =
  'UPDATE_WH_REPORT_MEDICINE_FAILED';

export const UPDATE_COURSE_TRAINING_IN_WH_REPORT_MEDICINE_REQUEST =
  'UPDATE_COURSE_TRAINING_IN_WH_REPORT_MEDICINE_REQUEST';
export const UPDATE_COURSE_TRAINING_IN_WH_REPORT_MEDICINE_SUCCESS =
  'UPDATE_COURSE_TRAINING_IN_WH_REPORT_MEDICINE_SUCCESS';
export const UPDATE_COURSE_TRAINING_IN_WH_REPORT_MEDICINE_FAILED =
  'UPDATE_COURSE_TRAINING_IN_WH_REPORT_MEDICINE_FAILED';

export const DELETE_WH_REPORT_MEDICINE_REQUEST =
  'DELETE_WH_REPORT_MEDICINE_REQUEST';
export const DELETE_WH_REPORT_MEDICINE_SUCCESS =
  'DELETE_WH_REPORT_MEDICINE_SUCCESS';
export const DELETE_WH_REPORT_MEDICINE_FAILED =
  'DELETE_WH_REPORT_MEDICINE_FAILED';


export const RESET_WH_REPORT_MEDICINE_STATE = 'RESET_WH_REPORT_MEDICINE_STATE';
