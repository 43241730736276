import { FileWordOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Skeleton, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import TextArea from 'antd/lib/input/TextArea';
import { debounce, get } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '~/api';
import { BaseBorderBox } from '~/components/Common';
import TooltipTitle from '~/components/Common/TooltipTitle';
import { useProfile, useUser } from '~/hooks';
import { adapterInitFetchListProduct, adapterSelectListProduct, useConfirmVoucherWarehouse, useConfirmVoucherWarehousePartner, useCreateConfirmVoucherWarehouse, useResetActionConfirmVoucherWarehouse, useUpdateConfirmVoucherWarehouse } from '~/hooks/confirmVoucherWarehouse';
import { onPrint } from '~/hooks/print';
import { ToastrCustom } from '~/hooks/utils';
import { validateFieldsListProductConfirmVoucher } from '~/hooks/warehouseVoucher';
import ConfirmBox from './ConfirmBox';
import HistoryLog from './HistoryLog';
import TableFetchWhAppointment from './TableFetchWhAppointment';
import TableListProduct from './TableListProduct';
import TableSelectWhAppointment from './TableSelectWhAppointment';
import template from '~/assets/templates/Biên_bản_xác_nhận_template.docx';
import TableRenderWordConfirmVoucher from '../../FormVoucher/TableRenderWordConfirmVoucher';

export default function FormConfirmVoucherWarehouse({ onCancel, id,handleByPartner,mutate }) {
  const [branchId] = useUser();
  const [form] = Form.useForm();
  const [profile] = useProfile();
  const [mockData, setMockData] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [whPartner, setWhPartner] = useState();
  const [loading, setLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const callbackHandle = () => {
    if(mutate && typeof mutate === 'function'){
      mutate();
    };
    if(onCancel && typeof onCancel === 'function'){
      onCancel();
    };
  }
  const [loadingSubmit, createConfirmVoucher] = useCreateConfirmVoucherWarehouse(callbackHandle);
  const [, updateConfirmVoucher] = useUpdateConfirmVoucherWarehouse(callbackHandle);
  const [confirmVoucher, isLoading] = useConfirmVoucherWarehouse(!handleByPartner && id);
  const [confirmVoucherPartner, isLoadingPartner] = useConfirmVoucherWarehousePartner(handleByPartner && id);
  const [voucher,loadingVoucher] = useMemo(() => {
    if(handleByPartner){
      return [confirmVoucherPartner, isLoadingPartner]
    }else{
      return [confirmVoucher, isLoading]
    }
  },[confirmVoucher, confirmVoucherPartner, handleByPartner, isLoading, isLoadingPartner])
  useResetActionConfirmVoucherWarehouse()
  const handleSelect = (record) => {
    setSelectList([...selectList, record]);
  }
  const handleRemove = (indexRemove) => {
    const cloneSelectList = [...selectList];
    cloneSelectList.splice(indexRemove, 1);
    setSelectList(cloneSelectList);
  }
  const handleFetchWhAppointment = useCallback(async (query, callback) => {
    try {
      setLoading(true);
      let handle;
      if(handleByPartner){
        handle = api.whAppointment.getWhAppointmentReadyToConfirmWarehouseOfPartner
      }else{
        handle = api.whAppointment.getWhAppointmentReadyToConfirmWarehouse
      }
      const resultSearch = await handle({ ...query,isGetCurrentBrachId : branchId });
      setMockData(resultSearch?.filter(result => !!get(result,'listAvailableConfirm',[])?.length));
      setLoading(false);
      if (callback && typeof callback === 'function') {
        callback(resultSearch)
      }
    } catch (error) {
      setLoading(false);
      setMockData([]);
      ToastrCustom('Lỗi khi lấy dữ liệu', 'error')
    }
  }, [handleByPartner]);
  const handleSearchDebounce = debounce((value) => handleFetchWhAppointment({ code: value }), 500);
  const handlePrint = async() => {
    const {codeSequence,User,createdAt} = voucher;
    const nameFile = `PhieuXacNhan_${codeSequence}`;
    const date = !id ? moment() : createdAt
    const dataSource = 
      {
        employeeName: !id ? get(profile, 'fullName', '') : get(User, 'fullName', ''),
        clientName: get(whPartner, 'name', ''),
        date: moment(date).format("DD"),
        month: moment(date).format("MM"),
        year: moment(date).format("YYYY"),
        table: `<meta charset="UTF-8">
        <body>
        ${TableRenderWordConfirmVoucher(listProduct)}
        </body>`
      }
     onPrint({nameFile,dataSource,template,setIsPrinting})
  }
  const onFinish = (values) => {
    const { msg, status } = validateFieldsListProductConfirmVoucher(listProduct,id);
    if (status) {
      return ToastrCustom(msg,'error')
    };
    if (!id) {
      const submitData = {
        partnerId: get(whPartner, '_id'),
        employeeId: get(profile, '_id'),
        listProduct,
        note: get(values, 'note', ''),
      }
      createConfirmVoucher(submitData);
    } else {
      const submitData = {
        listProduct,
        note: get(values, 'note', ''),
        id,
      };
      updateConfirmVoucher(submitData);
    }

  }
  useEffect(() => {
  if (id) {
    if (voucher) {
      const { whPartner, createdAt, listProduct } = voucher;
      setListProduct(adapterInitFetchListProduct(listProduct));
      setWhPartner(whPartner);
      form.setFieldsValue({ ...voucher, dateConfirm: moment(createdAt).format("DD-MM-YYYY HH:mm:ss") });
    }
  } else {
    form.setFieldsValue({ dateConfirm: moment().format("DD-MM-YYYY HH:mm:ss") });
    handleFetchWhAppointment();
  }
}, [handleFetchWhAppointment, id, form, handleByPartner, voucher]);
  useEffect(() => {
    // get listProduct then convert to listProduct
    let newListProduct = [];
    selectList?.forEach((itemSelected) => get(itemSelected, 'listAvailableConfirm', [])?.forEach((product) =>
      newListProduct.push(
        {
          ...product,
          whAppointmentCode: get(itemSelected, 'code'),
          whAppointmentId: get(itemSelected, '_id'),
        }
      )));
    setListProduct(adapterSelectListProduct(newListProduct));

    if (selectList?.length === 1) { // Just get partner the first selectList
      setWhPartner(get(selectList, '[0].whPartner'))
    } else {
      if (!selectList?.length) {
        setWhPartner(null)
      }
    }
  }, [selectList]);

  const renderLoading = (component) => isLoading ? (
    <Skeleton.Input active />
  ) : (
    component
  );

  return (
    <div className='flex-column-center'>
      <Typography.Title level={4}>{id ? `Cập nhật phiếu ${get(confirmVoucher,'codeSequence','')}` :"Tạo phiếu"}</Typography.Title>
      <Divider className='m-0'/>
      <Form form={form}
        onFinish={onFinish}
      >
        <BaseBorderBox title='Thông tin chung'>
          <Row justify='space-between'>
            <Col span={12}>
              <span>Tên đối tác</span>: {renderLoading(<Input placeholder='Tự động' value={get(whPartner, 'name', '')} style={{ width: 200 }} disabled />)}
            </Col>
            <Col>
              <Form.Item name='dateConfirm' label='Ngày tạo phiếu'>
                {renderLoading(<Input disabled bordered={false} />)}
              </Form.Item>
            </Col>
          </Row>
        </BaseBorderBox>

        <BaseBorderBox style={{marginTop : 15}}  title={
          <TooltipTitle content='Thông tin xác nhận' titleTooltip='Chọn dịch vụ sử dụng máy để lấy danh sách máy cần xác nhận' count={<InfoCircleOutlined />} />}
        >
          <Row gutter={8}  justify='space-between'>
            {!id && <Col lg={15} md={24}>

              {!handleByPartner && <Search
                allowClear
                style={{ width: '100%' }}
                placeholder="Tìm theo mã đơn hàng, mã cuộc hẹn, mã DV"
                enterButton
                onSearch={(value) => handleFetchWhAppointment({ code: value?.trim() })}
                onChange={(e) => handleSearchDebounce(e.target.value)}
              />}
              <Row gutter={16} justify='space-between'>
                <Col lg={11} md={24} xs={24}>
                  <TableFetchWhAppointment whPartner={whPartner} loading={loadingVoucher || loading} dataSource={mockData} handleSelect={handleSelect} selectList={selectList} />
                </Col>
                <Col lg={12} md={24} xs={24}>
                  <TableSelectWhAppointment loading={loadingVoucher} id={id} dataSource={selectList} handleRemove={handleRemove} />
                </Col>
              </Row>
            </Col>}
            <Col flex={1}>
              <TableListProduct handleByPartner={handleByPartner} loading={loadingVoucher} id={id} setListProduct={setListProduct} listProduct={listProduct} />
            </Col>
          </Row>
        </BaseBorderBox>
        <BaseBorderBox style={{marginTop : 15}} title={'Thông tin ghi chú'} >
          {renderLoading(<Form.Item name='note' label='Ghi chú'>
            <TextArea disabled={handleByPartner}/>
          </Form.Item>)}
        </BaseBorderBox>

        {id && <BaseBorderBox style={{marginTop : 15}} title={'Thông tin xác thực'} >
        <ConfirmBox 
        loading={isLoading}
        status={get(voucher,'status')}
        id={id}
        infoEmployee={{
          name : get(voucher,'User.fullName',''),
          dateConfirm: moment(get(voucher,'createdAt')).format('DD-MM-YYYY HH:mm:ss'),
          confirmStatus : get(voucher,'confirmEmployee')
        }}
        infoWhPartner={{
          name : get(voucher,'whPartner.name',''),
          dateConfirm: get(voucher,'dateConfirm') ? moment(get(voucher,'dateConfirm')).format('DD-MM-YYYY HH:mm:ss') : null,
          confirmStatus : get(voucher,'confirmPartner')
        }}
        handleByPartner={handleByPartner}
        />
        </BaseBorderBox>}

        {!handleByPartner && <HistoryLog dataSource={get(voucher,'historyLogs',[])}/> }
        <Row justify='center' style={{ alignSelf: 'center', marginTop: 20 }}>
          <Col>
            <Button onClick={onCancel}>Huỷ</Button>
            {id && <Button
              icon={<FileWordOutlined />}
              loading={isPrinting}
              onClick={() => handlePrint()}
            >
              Tải về file Docx
            </Button>}
            {!handleByPartner && <Button loading={loadingSubmit} onClick={() => form.submit()} type='primary'>{id ? "Cập nhật" : "Tạo mới"}</Button>}
          </Col>
        </Row>
      </Form>

    </div>
  );
}

