export const GET_IMPORT_WH_CUSTOMERS_EXCEL_REQUEST = 'GET_IMPORT_WH_CUSTOMERS_EXCEL_REQUEST';
export const GET_IMPORT_WH_CUSTOMERS_EXCEL_SUCCESS = 'GET_IMPORT_WH_CUSTOMERS_EXCEL_SUCCESS';
export const GET_IMPORT_WH_CUSTOMERS_EXCEL_FAILED = 'GET_IMPORT_WH_CUSTOMERS_EXCEL_FAILED';

export const GET_IMPORT_WH_CUSTOMER_EXCEL_REQUEST = 'GET_IMPORT_WH_CUSTOMER_EXCEL_REQUEST';
export const GET_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS = 'GET_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS';
export const GET_IMPORT_WH_CUSTOMER_EXCEL_FAILED = 'GET_IMPORT_WH_CUSTOMER_EXCEL_FAILED';

export const CREATE_IMPORT_WH_CUSTOMER_EXCEL_REQUEST = 'CREATE_IMPORT_WH_CUSTOMER_EXCEL_REQUEST';
export const CREATE_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS = 'CREATE_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS';
export const CREATE_IMPORT_WH_CUSTOMER_EXCEL_FAILED = 'CREATE_IMPORT_WH_CUSTOMER_EXCEL_FAILED';

export const UPDATE_IMPORT_WH_CUSTOMER_EXCEL_REQUEST = 'UPDATE_IMPORT_WH_CUSTOMER_EXCEL_REQUEST';
export const UPDATE_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS = 'UPDATE_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS';
export const UPDATE_IMPORT_WH_CUSTOMER_EXCEL_FAILED = 'UPDATE_IMPORT_WH_CUSTOMER_EXCEL_FAILED';

export const DELETE_IMPORT_WH_CUSTOMER_EXCEL_REQUEST = 'DELETE_IMPORT_WH_CUSTOMER_EXCEL_REQUEST';
export const DELETE_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS = 'DELETE_IMPORT_WH_CUSTOMER_EXCEL_SUCCESS';
export const DELETE_IMPORT_WH_CUSTOMER_EXCEL_FAILED = 'DELETE_IMPORT_WH_CUSTOMER_EXCEL_FAILED';

export const RESET_IMPORT_WH_CUSTOMER_EXCEL_STATE = 'RESET_IMPORT_WH_CUSTOMER_EXCEL_STATE';
