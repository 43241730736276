import React, { useEffect } from 'react';
import { Row, Col, Alert, Container, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Redirect } from 'react-router-dom';
// import images
import logo from '../../assets/images/logo.svg';
import logolight from '../../assets/images/logo.svg';
import { useLogin, useUser } from '../../hooks/user';

const Login = (props) => {
  const [, handleSubmit] = useLogin();
  const [, token] = useUser();
  const handleValidSubmit = (event, values) => {
    handleSubmit(values);
  };

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  if (token) {
    return <Redirect to={'/'} />;
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="42"
                    className="logo logo-dark"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="42"
                    className="logo logo-light"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">
                      Sign in to continue to worldcare.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.error && typeof props.error === 'string' ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="login"
                          label="User Name"
                          value=""
                          className="form-control"
                          placeholder="Enter user name"
                          type="login"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          Are you a partner ?{' '}
                          <a href="/login-workspace" className="fw-medium text-primary">
                            {' '}
                            Signin here{' '}
                          </a>{' '}
                        </p>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          Don't have an account ?{' '}
                          <a href="/" className="fw-medium text-primary">
                            {' '}
                            Signup now{' '}
                          </a>{' '}
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              {/* <div className='mt-5 text-center'>
                <p>
                  © {new Date().getFullYear()} Minible. Crafted with{' '}
                  <i className='mdi mdi-heart text-danger'></i> by Themesbrand
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
