import React, { useMemo } from 'react';
import {
  useFailed,
  useFetch,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';

import {
  getSpecialities,
  createSpeciality,
  deleteSpeciality,
  getSpeciality,
  resetSpecialityState,
  updateSpeciality
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { Select } from 'antd';
import { SERVICES_TYPES, SERVICES_NAME } from '../../constants/defaultValue';
import {  useServiceOfHospital } from '../hospital';
import { get, groupBy } from 'lodash';

const { Option, OptGroup } = Select;

const getSelector = key => state => state.speciality[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

export const useSpecialities = () => {
  return useFetch({
    action: getSpecialities,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector
  });
};

export const useCreateSpeciality = () => {
  const history = useHistory();
  const callback = () => history.push('/speciality');

  useSuccess(createSuccessSelector, 'Tạo mới chuyên môn thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới chuyên môn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createSpeciality
  });
};

export const useUpdateSpeciality = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật chuyên môn thành công');
  useFailed(updateFailedSelector, 'Cập nhật chuyên môn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateSpeciality
  });
};

export const useDeleteSpeciality = () => {
  useSuccess(deleteSuccessSelector, 'Xoá chuyên môn thành công');
  useFailed(deleteFailedSelector, 'Xoá chuyên môn thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteSpeciality
  });
};

export const useSpeciality = id => {
  return useFetchByParam({
    action: getSpeciality,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useInitSpeciality = speciality =>
  useMemo(() => {
    if (!speciality) {
      return {};
    }

    return speciality;
  }, [speciality]);

export const useResetSpeciality = () => {
  useResetState(resetSpecialityState);
};

export const useSpecialityOptions = () => {
  const [specialities] = useSpecialities();
  const optionGroups = useMemo(
    () =>
      specialities.reduce(
        (options, { service, name, id }) => ({
          ...options,
          [service]: [...(options[service] || []), { text: name, value: id }]
        }),
        {}
      ),
    [specialities]
  );
  return useMemo(
    () =>
      SERVICES_TYPES.map(service => (
        <OptGroup key={service} label={SERVICES_NAME[service]}>
          {(optionGroups[service] || []).map(({ value, text }) => (
            <Option value={value} key={value}>
              {text}
            </Option>
          ))}
        </OptGroup>
      )),
    [optionGroups]
  );
};
export const useSpecialityOptionsByBranchId = (id) => {
  const [hospital, loading] = useServiceOfHospital(id)
  const specialities = get(hospital, 'speciality')
  const newSpecialities = specialities?.map(item => 
  {
    const Item = {}
    Item.id = item._id,
    Item.name = item.name,
    Item.service = item.service
    return Item
  })
  const optionGroups = useMemo(
    () =>
    (newSpecialities || []).reduce(
        (options, { service, name, id }) => ({
          ...options,
          [service]: [...(options[service] || []), { text: name, value: id }]
        }),
        {}
      ),
    [newSpecialities]
  );
  
  return useMemo(
    () =>
      SERVICES_TYPES.map(service => (
        <OptGroup key={service} label={SERVICES_NAME[service]}>
          {(optionGroups[service] || []).map(({ value, text }) => (
            <Option value={value} key={value}>
              {text}
            </Option>
          ))}
        </OptGroup>
      )),
    [optionGroups]
  );
};
