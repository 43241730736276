import { ArrowLeftOutlined, EditOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Divider, Empty, Form, List, Modal, Radio, Row, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '~/api';
import { addAddressSelect, getWhBills } from '~/redux/action';
import ItemListAddressUser from './ItemListAddressUser';
import LoadingWhBill from './LoadingWhBill';
import ModalAddAddress from './ModalAddAddress';
import './wh-order-temp.scss';
import toastr from 'toastr';
import TooltipTitle from '../Common/TooltipTitle';
import { useQueryParams } from '~/hooks/utils';
import { useGetAddressUser, useResetUserEmployee, useResetWhCustomerManagement, useUpdateWhCustomerManagementAddress, useWhBillsQueryParams } from '~/hooks';
import ModalUpdateAddress from './ModalUpdateAddress';
import { isEmpty } from 'lodash';
export default function AddressCustomerBill({customerId, whBill, setCustomerAddress, newCustomerAddress, setIsOpenModalUpdateAddress}) {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [listAddress, setListAddress] = useState([]);
  const [value, setValue] = useState(1);
  const [query] = useWhBillsQueryParams();
  const [isOpen, setIsOpen] = useState(false);
  const [visibleModalAddAddress, setVisibleModalAddAddress] = useState(false);
  const [idAddress, setIdAddress] = useState();
  const [addressItem, setAddressItem] = useState();
  const [isLoadingUpdate, updateAddress] = useUpdateWhCustomerManagementAddress();
  const [reFeatch, setReFeatch] = useState(false);
  const queryCustomer = useMemo(() => ({
    id: customerId,
  }),[customerId, reFeatch])
  const [addressCustomer, isLoading] = useGetAddressUser(queryCustomer);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const handleUpdateAddress = async () => {
    const body = {
      whBillId: whBill?._id,
      cityId: value?.cityId,
      districtId: value?.districtId,
      wardId: value?.wardId,
      street: value?.street
    };
    try {
      const data = await api.whBillItem.updateAddress(body);
      const address = {
        city: data?.customerAddress?.city,
        cityId: data?.customerAddress?.cityId,
        districtId: data?.customerAddress?.districtId,
        district: data?.customerAddress?.district,
        wardId: data?.customerAddress?.wardId,
        ward: data?.customerAddress?.ward,
        street: data?.customerAddress?.street,
      };
      setCustomerAddress(address);
      setIsOpenModalUpdateAddress(false);
      dispatch(getWhBills(query));
      toastr.success('Cập nhật địa chỉ đơn hàng thành công');
    } catch (error) {
      toastr.error("Cập nhật địa chỉ đơn hàng thất bại");
    };
  };
  useResetUserEmployee();
  const handleCloseEdit = () => {
    setIdAddress(null);
    setIsOpen(false);
    setAddressItem(null);
  };
  // const fetchData = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const res = await api.userEmployee.getAddressUserById(customerId);
  //     if (res.length !== 0) {
  //       setAddressCustomer(res);
  //       setLoading(false);
  //       dispatch(addAddressSelect(res[0]));
  //       setReFeatch(!reFeatch);
  //     }else {
  //       setLoading(false);
  //     };
  //   } catch (error) {
  //     toastr.error("Something went wrong");
  //     setLoading(false);
  //   };
  // }, [status]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  useEffect(() => {
    const addressAccount = whBill?.customer?.address;
    const isCheck = addressAccount ? isEmpty(addressAccount) : true;
    if (!isCheck) {
      let newAddress = {};
      if (addressAccount) {
        newAddress = {
          ...addressAccount,
          type: 'default'
        };
        if(addressCustomer){
          setListAddress([newAddress, ...addressCustomer]);
        } else {
          setListAddress([newAddress]);
        };
      } else {
        if(addressCustomer){
          setListAddress(addressCustomer);
        } else {
          setListAddress([]);
        }
      };
    };
    
  }, [whBill, addressCustomer ]);

  useEffect(() => {
    const foundAddress = listAddress?.find(address => (
      address.cityId === newCustomerAddress.cityId &&
      address.districtId === newCustomerAddress.districtId &&
      address.wardId === newCustomerAddress.wardId &&
      address.street === newCustomerAddress.street
    ));
    if (foundAddress) {
      setValue(foundAddress);
    };
  }, [listAddress, newCustomerAddress]);
  const reFetch = () => {
    setStatus(!status);
  };
  return (
    <>
      <div className="page-content__main">
          <LoadingWhBill  open={isLoading}/>
        <div className="wh-orderTemp-Box" style={{width : '95%'}}>
          {/* <LoadingWhBill open={loading} /> */}
          <h3>Thông tin địa chỉ</h3>
          <Row style={{width : '100%'}} justify='space-between'>
          {/* <Button onClick={() => setStep(1)}><ArrowLeftOutlined /></Button> */}
          <Button onClick={() => setVisibleModalAddAddress(true)} icon={<PlusCircleTwoTone />}  type='primary'>Thêm địa chỉ mới</Button>
          </Row>
          <Radio.Group style={{padding : '20px',width : '100%'}} onChange={onChange} value={value}>
            <Space direction='vertical' style={{width : '100%'}} align={!!listAddress?.length  ? 'start' : 'center'}>
            
            {
              !!listAddress?.length 
                  ? listAddress?.map((item, index) => (
                    <Radio 
                    key={index} 
                    style={{ padding: '10px 0' }} 
                      value={item}
                    >
                      <div style={{width : '100%', display: 'flex', flexDirection: 'row'}}>
                        <h6>{`${item.street}, ${item.ward}, ${item.district}, ${item.city}`}</h6>
                        <EditOutlined
                          style={{ marginLeft: '10px', color: '#1890ff' }}
                          onClick={() => {
                            setIsOpen(true);
                            setIdAddress(item._id);
                            setAddressItem(item);
                          }}
                        />
                      </div>
                    </Radio>))
              : <Empty />
              }
          
            </Space>
          </Radio.Group>
            <Button disabled={!listAddress?.length} onClick={() => handleUpdateAddress()} type='primary'>Cập nhật</Button>
        </div>
        <ModalAddAddress
          reFetch={reFetch}
          visibleModalAddAddress={visibleModalAddAddress}
          setVisibleModalAddAddress={setVisibleModalAddAddress}
          isWhBill customerId={customerId}
          setReFeatch={setReFeatch}
          reFeatch={reFeatch} />

        <Modal
          footer={null}
          visible={isOpen}
          onCancel={handleCloseEdit}
          onOk={handleCloseEdit}
        >
          <ModalUpdateAddress
            customerId = {whBill?.customerId}
            idAddress = {idAddress}
            isLoadingUpdate = {isLoadingUpdate}
            addressItem = {addressItem}
            updateAddress = {updateAddress}
            setIsOpen={setIsOpen}
            setListAddress={setListAddress}
            listAddress={listAddress}
            handleCloseEdit={handleCloseEdit}
            reFeatch={reFeatch}
            setReFeatch={setReFeatch}
            setIsOpenModalUpdateAddress = {setIsOpenModalUpdateAddress}
          />
        </Modal>
      </div>
    </>
  );
}
