import * as Types from '../../constants/actionTypes';
  
  export const getSettingTutorialJobs = query => ({
    type: Types.GET_SETTING_TUTORIAL_JOBS_REQUEST,
    payload: query
  });
  export const updateSettingTutorialJob = instance => ({
    type: Types.UPDATE_SETTING_TUTORIAL_JOB_REQUEST,
    payload: instance
  });
  export const resetSettingTutorialJobState = () => ({
    type: Types.RESET_SETTING_TUTORIAL_JOB_STATE
  });
