export const GET_WH_BILL_ITEMS_REQUEST = 'GET_WH_BILL_ITEMS_REQUEST';
export const GET_WH_BILL_ITEMS_SUCCESS = 'GET_WH_BILL_ITEMS_SUCCESS';
export const GET_WH_BILL_ITEMS_FAILED = 'GET_WH_BILL_ITEMS_FAILED';

export const DELETE_WH_BILL_ITEM_REQUEST = 'DELETE_WH_BILL_ITEM_REQUEST';
export const DELETE_WH_BILL_ITEM_SUCCESS = 'DELETE_WH_BILL_ITEM_SUCCESS';
export const DELETE_WH_BILL_ITEM_FAILED = 'DELETE_WH_BILL_ITEM_FAILED';

export const CREATE_WH_BILL_ITEM_REQUEST = 'CREATE_WH_BILL_ITEM_REQUEST';
export const CREATE_WH_BILL_ITEM_SUCCESS = 'CREATE_WH_BILL_ITEM_SUCCESS';
export const CREATE_WH_BILL_ITEM_FAILED = 'CREATE_WH_BILL_ITEM_FAILED';

export const UPDATE_WH_BILL_ITEM_REQUEST = 'UPDATE_WH_BILL_ITEM_REQUEST';
export const UPDATE_WH_BILL_ITEM_SUCCESS = 'UPDATE_WH_BILL_ITEM_SUCCESS';
export const UPDATE_WH_BILL_ITEM_FAILED = 'UPDATE_WH_BILL_ITEM_FAILED';

export const CUSTOMER_COMPLETED_WH_BILL_ITEM_REQUEST = 'CUSTOMER_COMPLETED_WH_BILL_ITEM_REQUEST';
export const CUSTOMER_COMPLETED_WH_BILL_ITEM_SUCCESS = 'CUSTOMER_COMPLETED_WH_BILL_ITEM_SUCCESS';
export const CUSTOMER_COMPLETED_WH_BILL_ITEM_FAILED = 'CUSTOMER_COMPLETED_WH_BILL_ITEM_FAILED';

export const CUSTOMER_CANCEL_WH_BILL_ITEM_REQUEST = 'CUSTOMER_CANCEL_WH_BILL_ITEM_REQUEST';
export const CUSTOMER_CANCEL_WH_BILL_ITEM_SUCCESS = 'CUSTOMER_CANCEL_WH_BILL_ITEM_SUCCESS';
export const CUSTOMER_CANCEL_WH_BILL_ITEM_FAILED = 'CUSTOMER_CANCEL_WH_BILL_ITEM_FAILED';

export const UPDATE_WH_APPOINTMENTS_REQUEST = 'UPDATE_WH_APPOINTMENTS_REQUEST';
export const UPDATE_WH_APPOINTMENTS_SUCCESS = 'UPDATE_WH_APPOINTMENTS_SUCCESS';
export const UPDATE_WH_APPOINTMENTS_FAILED = 'UPDATE_WH_APPOINTMENTS_FAILED';

export const GET_WH_BILL_ITEM_REQUEST = 'GET_WH_BILL_ITEM_REQUEST';
export const GET_WH_BILL_ITEM_SUCCESS = 'GET_WH_BILL_ITEM_SUCCESS';
export const GET_WH_BILL_ITEM_FAILED = 'GET_WH_BILL_ITEM_FAILED';

export const RESET_WH_BILL_ITEM_STATE = 'RESET_WH_BILL_ITEM_STATE';
export const RESET_CREATE_CANCEL = 'RESET_CREATE_CANCEL';

export const CREATE_CANCEL_APPOINTMENT_REQUEST = 'CREATE_CANCEL_APPOINTMENT_REQUEST';
export const CREATE_CANCEL_APPOINTMENT_SUCCESS = 'CREATE_CANCEL_APPOINTMENT_SUCCESS';
export const CREATE_CANCEL_APPOINTMENT_FAILED = 'CREATE_CANCEL_APPOINTMENT_FAILED';
