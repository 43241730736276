import requester from './requester';

const whPackageLevel = {
  getAll: query => requester.get('/package-level', query),
  getById: id => requester.get(`/package-level/${id}?raw=true`),
  update: (data) => requester.put(`/package-level/${data._id}`,data),
  // delete: id => requester.delete(`/package-level/${id}`),
  // create: whPackageLevel => requester.post('/package-level', whPackageLevel),
  // update: whPackageLevel => requester.put(`/package-level/${whPackageLevel.id}`, whPackageLevel)
};

export default whPackageLevel;
