import React, { useEffect, useCallback, useState } from 'react';
import { Form, Input, Row, Button, Skeleton, Col, Select } from 'antd';
import { cloneDeep } from 'lodash';
import { LANGUAGE } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';

import {
  useCreateServicePlace,
  useUpdateServicePlace,
  useResetServicePlace,
  useInitServicePlace,
  useServicePlace
} from '~/hooks/servicePlace';

const FormItem = Form.Item;

const ServicePlaceForm = ({ specialityOptions, onClose, servicePlaceId }) => {
  const [form] = Form.useForm();
  const [isSubmitLoading, handleCreate] = useCreateServicePlace();

  const [, handleUpdate] = useUpdateServicePlace();
  const [servicePlace, isLoading] = useServicePlace(servicePlaceId);
  const initServicePlace = useInitServicePlace(servicePlace);
  useResetServicePlace();

  useEffect(() => {
    if (!servicePlaceId) {
      form.resetFields();
    } else {
      form.setFieldsValue(initServicePlace);
    }
  }, [initServicePlace, servicePlaceId, form]);

  const onFinish = useCallback(
    (values) => {
      if (servicePlaceId) {
        handleUpdate({ ...values, id: servicePlaceId });
      } else {
        handleCreate({ ...values });
      }
      onClose();
      form.resetFields();
    },
    [handleCreate, handleUpdate, servicePlaceId]
  );
  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="Customer-type page-wraper hospital-form-page-content">
      <h1 style={{ margin: '40px' }}>
        {servicePlaceId ? ' Cập nhật' : 'Thêm mới'} nơi thực hiện
      </h1>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <FormItem
            label="Nơi thực hiện (vi)"
            name={['name', LANGUAGE.VI]}
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập tên nơi thực hiện (vi)'
              }
            ]}
          >
            {renderInput(<Input />)}
          </FormItem>

          <FormItem
            label="Nơi thực hiện (en)"
            name={['name', LANGUAGE.EN]}
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập tên nơi thực hiện (en)'
              }
            ]}
          >
            {renderInput(<Input />)}
          </FormItem>

          <Row className="form__submit-box">
            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {servicePlaceId ? 'Cập nhật' : 'Thêm mới'}
            </Button>

            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.servicePlace.root}>
                <Button onClick={onClose}>Huỷ</Button>
              </Link>
            )}
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ServicePlaceForm;
