import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Col, Row, Skeleton, Table,Button } from 'antd';
import {
  useProductListQueryParams,
  useProductLists
} from '~/hooks/productList';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import Search from 'antd/lib/input/Search';
import toastr from 'toastr'
import api from '~/api';
import { formatNumberThreeComma } from '~/hooks/utils';
export const ListProductDelivery = ({
  warehouseId,
  setDataListProductDelivery,
  setIsOpenModalSelectDevice,
  dataListProductDelivery,
}) => {
  const [keyword, setKeyword] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [keywordChange, setKeywordChange] = useState(null);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [arrCheckBox, setArrCheckBox] = useState([]);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [paging, setPaging] = useState({
    current: 1,
    pageSize: 0,
    total: 0,
  });
  const query = useMemo(() => ({
    page: page,
    limit: limit,
    name: keyword?.trim(),
    status: "READY,UNREADY",
    warehouseId: warehouseId,
    delivery: true
  }), [keyword, warehouseId,page, limit]);

  const getProduct = async () => {
    setLoading(true);
    const res = await api.productList.getAll(query);
    setPaging({
      current: res.page,
      pageSize: res.limit,
      total: res.totalDocs,
    });
    // setData(res.docs);
    setList(res.docs);
    setInitLoading(false);
    setLoading(false);
  };

  const onChangeCheckBox = (e, record) => {
    if (e) {
      setArrCheckBox([...arrCheckBox, record]);
    } else {
      const filterData = arrCheckBox.filter((item) => item._id !== record._id);
      setArrCheckBox(filterData);
    }
  };
  const handleSubmit = () => {
    if(arrCheckBox.length > 0){
      setDataListProductDelivery(arrCheckBox);
      setIsOpenModalSelectDevice(false);
    } else {
      toastr.error('Không có sản phẩm nào được chọn');
      setDataListProductDelivery([]);
    }
  };
  
  useEffect(() => {
    getProduct();
  },[keyword,warehouseId, page, limit])

  useEffect(() => {
    setArrCheckBox(dataListProductDelivery);
  }, [dataListProductDelivery]);

  const columns = [
    {
      title: 'Mã sản phẩm',
      align: 'start',
      dataIndex: 'code',
      width: '120px',
      key: 'code'
    },
    {
      title: 'Tên sản phẩm',
      align: 'start',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Giá khấu hao',
      align: 'start',
      dataIndex: 'valueDepreciation',
      key: 'valueDepreciation',
      render: (value)=> formatNumberThreeComma(value),
    },
    {
      title: 'Chọn sản phẩm',
      align: 'center',
      dataIndex: 'status',
      width: '150px',
      key: 'status',
      render: (value, record) => {
        const id = record._id;
        const idsDevice = arrCheckBox?.map(({ _id }) => _id);
        return (
          <Checkbox
            checked={idsDevice.includes(id)} // Use the isRowChecked function
            onChange={(e) => onChangeCheckBox(e.target.checked, record)}
          />
        );
      }
    },
  ];

  return (
    <div>
      <Row>
        <Col span={20}>
          <Search
            allowClear
            style={{ maxWidth: '500px' }}
            enterButton
            placeholder= 'Nhập tên để tìm sản phẩm'
            onSearch={() => {
              setKeyword(keywordChange);
              setPage(1);
            }}
            onChange={(e) => {
              setKeywordChange(e.target.value);
              if (e.target.value === '') {
                setKeyword(null);
              };
            }}
            value={keywordChange}
          />
        </Col>
        <Col span={4}>
          <Button type = 'primary' onClick={handleSubmit} span={24}>Thêm tài sản</Button>
        </Col>
      </Row>
      <Table
        loading={loading}
        columns={columns}
        dataSource={list}
        pagination={{
          ...paging,
          showTotal: (total)=> `Tổng cộng: ${total}`
        }}
        onChange={({ current, pageSize }) => {
          setPage(current);
          setLimit(pageSize);
        }}
        size='middle'
        scroll={{ y: 'auto' }} />
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
      </div>
    </div>
  );
};
