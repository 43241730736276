export const GET_CUSTOMERS_TYPE_REQUEST = 'GET_CUSTOMERS_TYPE_REQUEST';
export const GET_CUSTOMERS_TYPE_SUCCESS = 'GET_CUSTOMERS_TYPE_SUCCESS';
export const GET_CUSTOMERS_TYPE_FAILED = 'GET_CUSTOMERS_TYPE_FAILED';

export const GET_CUSTOMER_TYPE_REQUEST = 'GET_CUSTOMER_TYPE_REQUEST';
export const GET_CUSTOMER_TYPE_SUCCESS = 'GET_CUSTOMER_TYPE_SUCCESS';
export const GET_CUSTOMER_TYPE_FAILED = 'GET_CUSTOMER_TYPE_FAILED';

export const CREATE_CUSTOMER_TYPE_REQUEST = 'CREATE_CUSTOMER_TYPE_REQUEST';
export const CREATE_CUSTOMER_TYPE_SUCCESS = 'CREATE_CUSTOMER_TYPE_SUCCESS';
export const CREATE_CUSTOMER_TYPE_FAILED = 'CREATE_CUSTOMER_TYPE_FAILED';

export const UPDATE_CUSTOMER_TYPE_REQUEST = 'UPDATE_CUSTOMER_TYPE_REQUEST';
export const UPDATE_CUSTOMER_TYPE_SUCCESS = 'UPDATE_CUSTOMER_TYPE_SUCCESS';
export const UPDATE_CUSTOMER_TYPE_FAILED = 'UPDATE_CUSTOMER_TYPE_FAILED';

export const DELETE_CUSTOMER_TYPE_REQUEST = 'DELETE_CUSTOMER_TYPE_REQUEST';
export const DELETE_CUSTOMER_TYPE_SUCCESS = 'DELETE_CUSTOMER_TYPE_SUCCESS';
export const DELETE_CUSTOMER_TYPE_FAILED = 'DELETE_CUSTOMER_TYPE_FAILED';

export const RESET_CUSTOMER_TYPE_STATE = 'RESET_CUSTOMER_TYPE_STATE';
