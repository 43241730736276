import React from 'react';

import { Form, Row, Col, Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { PATH_APP } from '~/routes/paths';
import { useCreateWhService } from '~/hooks/whService';
import Breadcrumb from '~/components/Common/Breadcrumb';
import FamilyHealthyCart from './FamilyHealthyCart';
import './index.scss';
function ServiceFamilyHealthyCare() {
  const FormItem = Form.Item;
  const [form] = Form.useForm();

  const [isSubmitLoading, handleCreate] = useCreateWhService();
  const onValuesChange = (changes) => {};
  // const onFinish = () => {
  //   console.log('onFinish');
  // };

  return (
    <div className="wh-service-form page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={'Chăm sóc sức khoẻ gia đình'}
          routes={[
            { path: PATH_APP.serviceFamilyHealthyCare.root, title: 'CMS' }
          ]}
        />
        <Form
          form={form}
          // onFinish={onFinish} onValuesChange={onValuesChange}
        >
          <Row gutter={0} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem label={<h2>Title</h2>}>
                <Input />
              </FormItem>
            </Col>
          </Row>
          <FamilyHealthyCart />
          <Form.List name={'carts'}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((index) => {
                  const props = { form, index };
                  return <FamilyHealthyCart key={index} {...props} />;
                })}
                <Row>
                  <Col span={24}>
                    <Button
                      className="service-cart-button"
                      type="solid"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm dịch vụ
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
          <Row className="service-button">
            <Button
              className="service-button-update"
              type="primary"
              onClick={() => alert('Post')}
              loading={isSubmitLoading}
            >
              Cập nhật
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default ServiceFamilyHealthyCare;
