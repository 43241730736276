import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getApplyJobsFailed: null,
  getApplyJobFailed: null,
  ApplyJobs: [],
  ApplyJob: null,
  isSubmitLoading: false,
  isGetApplyJobLoading: false,

  loadingUpdateCourse: false,
  updateFailedCourse: null,
  updateSuccessCourse: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_APPLY_JOBS_REQUEST:
      state.isLoading = true;
      state.getApplyJobsFailed = null;
      return;

    case Types.GET_APPLY_JOBS_SUCCESS:
      state.isLoading = false;
      state.ApplyJobs = get(payload, 'docs', []);
      state.getApplyJobsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_APPLY_JOBS_FAILED:
      state.isLoading = false;
      state.ApplyJobs = [];
      state.getApplyJobsFailed = payload;
      return;
    case Types.GET_APPLY_JOB_REQUEST:
      state.isGetApplyJobLoading = true;
      state.ApplyJob = null
      state.getApplyJobFailed = null;
      return;

    case Types.GET_APPLY_JOB_SUCCESS:
      state.isGetApplyJobLoading = false;
      state.ApplyJob = payload;
      state.getApplyJobFailed = null;
      return;

    case Types.GET_APPLY_JOB_FAILED:
      state.isGetApplyJobLoading = false;
      state.ApplyJob = null;
      state.getApplyJobFailed = payload;
      return;
    case Types.UPDATE_APPLY_JOB_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_APPLY_JOB_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      // state.ApplyJobs = state.ApplyJobs.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.ApplyJob = payload;
      return;

    case Types.UPDATE_APPLY_JOB_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_APPLY_JOB_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_APPLY_JOB_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_STORE:
    case Types.RESET_APPLY_JOB_STATE:
      return initState;

    default:
      return;
  }
}, initState);
