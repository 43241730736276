import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PATH_APP } from '~/routes/paths';
import DrawerNavbar from './DrawerNavbar';
export default function NavbarIcon() {

    const history = useHistory();
    if ([
        '/',
        PATH_APP.main.home,
        '/login',
        '/login-workspace',
        ...Object.values(PATH_APP.whPartnerWorkspace)
    ].includes(history.location.pathname)) return <></>
    return (
        <div className='navbarIcon'>
            <DrawerNavbar  />
        </div>
    )
}
