import * as Types from '../../constants/actionTypes';

export const getWhWorkNorms = (payload) => ({
  type: Types.GET_WH_WORK_NORMS_REQUEST,
  payload
});
export const getAllEmployyGroup = () => ({
  type: Types.GET_EMPLOYEE_GROUP_WH_WORK_NORMS_REQUEST,
  
});

export const getWhWorkNorm = id => ({
  type: Types.GET_WH_WORK_NORM_REQUEST,
  payload: id
});

export const createWhWorkNorm = staffGroup => ({
  type: Types.CREATE_WH_WORK_NORM_REQUEST,
  payload: staffGroup
});

export const updateWhWorkNorm = staffGroup => ({
  type: Types.UPDATE_WH_WORK_NORM_REQUEST,
  payload: staffGroup
});

export const deleteWhWorkNorm = id => ({
  type: Types.DELETE_WH_WORK_NORM_REQUEST,
  payload: id
});

export const resetWhWorkNormState = () => ({
  type: Types.RESET_WH_WORK_NORM_STATE
});
