import { useEffect, useMemo, useRef, useState } from 'react';
import {
    useFailed,
    useSubmit,
    useSuccess,
    useResetState,
    useFetchByParam,
    getSelectors,
    useQueryParams,
    checkInvalidDate,
    useBranchIdSessionStorage,
    useFetch
  } from '~/hooks/utils';
  import {

    getBoards,
    getBoardById,
    createBoard,
    updateBoard,
    deleteBoard,

    getWorkList,
    createWork,
    deleteWork,
    getWorkById,
    
    updateWork,



getAllTask,
getTaskById,
createTask,
updateTask,
deleteTask,
getListBoardConfig,
assignTask,
getListManagers,
getListStaffs,
getListManagersByIdBoard,
getListStaffsByIdBoard,
updatePosition,
getBoardGroup,
getListTaskBySprints,
copyTask,
filterBoardConfigItem

  } from '~/redux/action';


import { isString, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
// import moment from 'moment';
// import api from '~/api';
// import { getSessionPrice } from '~/components/WhService/utils';
const WORK_LIST = 'workList';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
} = getSelectors(WORK_LIST);

const getSelector = (key) => (state) => state[WORK_LIST][key];

const listBoardGroupSelector = getSelector('listBoardGroup');
const loadingBoardGroupSelector = getSelector('isLoadingBoardGroup');
const getFailBoardGroupSelector = getSelector('getBoardGroupFailed');


const listTaskSelector = getSelector('listTask');
const listBoardSelector = getSelector('listBoard');
const listBoardConfigItemSelector = getSelector('dataBoardConfig');
const getTaskByIdSelector = getSelector('taskById');
const listManagerSelector = getSelector('listManagers');
const listStaffSelector = getSelector('listStaffs');
const isLoadingAssignSelector = getSelector('isLoadingAssign');
const assignFailedSelector = getSelector('assignFailed');
const loadingManagerSelector = getSelector('loadingManager');
const loadingStaffSelector = getSelector('loadingStaff');
const listManagerByIdBoardSelector = getSelector('listManagersByBoard');
const listStaffsByIdBoardSelector = getSelector('listStaffsByIdBoard');
const isLoadingStaffByBoard = getSelector('isLoadingStaffByBoard');
const isLoadingManagerByBoard = getSelector('isLoadingManagerByBoard');
const pagingSelector = getSelector('paging');
const loadingTaskBySprintsSelector = getSelector('loadingTaskBySprints');
const listTaskBySprintsSelector = getSelector('listTaskBySprints');
const listTaskFilterSelector = getSelector('listTaskFilter');
export const useWorkFlowQueryParams = (module) => {
  
    const prevKeyword = useRef(null);
    const [branchId] = useBranchIdSessionStorage();
    const query = useQueryParams();
    const keyword = query.get('keyword');
    const page = query.get('page') || 1;
    const limit = query.get('limit') || 10;
    const key = query.get('key');
    const tabs = query.get('tabs');
    const taskId = query.get('taskId')
    const status = query.get("status");
    const startDate = query.get("startDate") || null;
    const endDate = query.get("endDate")|| null;

    
      const createSuccess = useSelector(createSuccessSelector);
      const updateSuccess = useSelector(updateSuccessSelector);
      const deleteSuccess = useSelector(deleteSuccessSelector);
     
      return useMemo(() => {
          const queryParams = getExistProp({
            keyword,
            status,
            key,
            tabs,
            taskId,
            keyword,
            page,
            limit,
            branchId,
            startDate,
            endDate
          }); 
          return [queryParams]
      }, [keyword,branchId, status,key,keyword,page,limit,taskId,tabs, startDate, endDate, createSuccess, updateSuccess, deleteSuccess]);
     
}

export const useUpdateWorkFlowParams = (query) => {

    const history = useHistory();
    const [keyword, setKeyword] = useState(query?.keyword);
    const { path, url } = useRouteMatch()
    const onParamChange = (param) => {
      if (param['modules'] && !param['modules'].length) {
        param = { modules: undefined };
      }
      if(!param.code)
       for (const key in param) {
          param[key]=  isString(param[key]) ? (param[key].trim()).replace(/[.]/g,'') : param[key]
       }
      history.push({
        pathname: `${path}`,
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword, { setKeyword, onParamChange }];
  };
  export const useGetPagingTodoList = () => useSelector(pagingSelector);
  export const useGetAllBoard = (query) => {
    return useFetchByParam({
      action: getBoards,
      loadingSelector,
      dataSelector: listBoardSelector,
      failedSelector: getListFailedSelector,
      param: query
    })
    
  }
  export const useGetBoardById = (params) => {
    return useFetchByParam({
      action: getBoardById,
      loadingSelector: getByIdLoadingSelector,
      dataSelector: getByIdSelector,
      failedSelector: getByIdFailedSelector,
      param: params
    })
  }
  
  export const useGetGroupBoard = () => {
    return useFetch({
      action: getBoardGroup,
      loadingSelector: loadingBoardGroupSelector,
      dataSelector: listBoardGroupSelector,
      failedSelector: getFailBoardGroupSelector,
    })
  }

  export const useCreateBoard = () => {
    useSuccess(createSuccessSelector, 'Tạo mới công việc thành công');
    useFailed(createFailedSelector, 'Tạo mới công việc thất bại');
  
      return useSubmit({
        loadingSelector: isSubmitLoadingSelector,
        action: createBoard
      })
  }
  export const useUpdateBoard = () => {
    useSuccess(updateSuccessSelector, 'Cập nhật thành công ');
    useFailed(updateFailedSelector, 'Cập nhật thất bại');
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateBoard,
    });
  }
  export const useDeleteBoard = () => {
    useSuccess(deleteSuccessSelector,'Xóa công việc thành công' );
    useFailed(deleteFailedSelector,'Xóa công việc thất bại');
  
    return useSubmit({ 
      loadingSelector,
      action: deleteBoard,
    });
  };
  //workFlow
  export const useWorkLists = (query) => {
      return useFetchByParam({
        action: getWorkList,
          loadingSelector,
          dataSelector: listSelector,
          failedSelector: getListFailedSelector,
          param: query
      }
      )
  }
export const useListBoardConfig = (query) => {
    return useFetchByParam({
      action: getListBoardConfig,
        loadingSelector,
        dataSelector: listSelector,
        failedSelector: getListFailedSelector,
        param: query
    }
    )
}
export const useListBoardConfigItem = (query) => {
  const data = useSelector(listBoardConfigItemSelector);
  // const _clone = useMemo(()=>{
  //   let clonedata = {};
  //   for (const key in data) {
  //     clonedata[key] = sortBy(data[key],[function(o){return o.ordinal}])
  //   }
  //   return clonedata
  // },[data])
   return [data]
}


  export const useWorkListById = (params) => {
      return useFetchByParam({
          action: getWorkById,
          loadingSelector: getByIdLoadingSelector,
          dataSelector: getByIdSelector,
          failedSelector: getByIdFailedSelector,
          param: params
      })
  }

  export const useCreatrWorkList = (callback) => {
    useSuccess(createSuccessSelector, 'Tạo mới công việc thành công', callback);
    useFailed(createFailedSelector, 'Tạo mới công việc thất bại');
  
      return useSubmit({
        loadingSelector: isSubmitLoadingSelector,
        action: createWork
      })
  }

  export const useUpdateWorkList = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhật công việc thành công', callback);
    useFailed(updateFailedSelector, 'Cập nhật công việc thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateWork,
    });
  };


  export const useDeleteWorkList = () => {
    useSuccess(deleteSuccessSelector,'Xóa thành công');
    useFailed(deleteFailedSelector,'Xóa công việc thất bại');
  
    return useSubmit({
      loadingSelector,
      action: deleteWork,
    });
  };




  //task
  export const useGetAllTask = (query) => {
    return useFetchByParam({
      action: getAllTask,
      loadingSelector,
      dataSelector: listTaskSelector,
      failedSelector: getListFailedSelector,
      param: query
    })
    
  }

  export const useGetTaskById = (params) => {
    return useFetchByParam({
      action: getTaskById,
      loadingSelector: getByIdLoadingSelector,
      dataSelector: getByIdSelector,
      failedSelector: getByIdFailedSelector,
      param: params
    })
  }
  export const useCreateTask = (callback) => {
    useSuccess(createSuccessSelector, 'Tạo mới công việc thành công', callback);
    useFailed(createFailedSelector, 'Tạo mới công việc thất bại');
  
      return useSubmit({
        loadingSelector: isSubmitLoadingSelector,
        action: createTask
      })
  }
  export const useCreateBoardWork = (callback) => {
    // useSuccess(createSuccessSelector, 'Tạo mới nhóm công việc thành công', callback);
    useFailed(createFailedSelector, 'Tạo mới nhóm công việc thất bại');
  
      return useSubmit({
        loadingSelector: isSubmitLoadingSelector,
        action: createWork
      })
  }
  export const useDeleteBoardWork = (callback) => {
    // useSuccess(createSuccessSelector, 'Tạo mới nhóm công việc thành công', callback);
    useFailed(createFailedSelector, 'Tạo mới nhóm công việc thất bại');
  
      return useSubmit({
        loadingSelector: isSubmitLoadingSelector,
        action: deleteWork
      })
  }

  export const useUpdateTask = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhật công việc thành công', callback);
    useFailed(updateFailedSelector, 'Cập nhật này việc thất baị');
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateTask,
    })
  }
  export  const useUpdateTaskInit =()=>{

    return useSubmit({
      loadingSelector: ()=>{},
      action: updateTask,
    })
  }
  export const useUpdatePosition = () => {
    return useSubmit({
      loadingSelector: ()=>{},
      action: updatePosition,
    })
  }

  export const useDeleteTask = (callback) => {
    // useSuccess(deleteSuccessSelector,'Xóa thành công này việc',callback);
    useFailed(deleteFailedSelector,'Xóa công việc thất bại');
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: deleteTask
    })
  };

export const useHandleAssign = () => {
  useFailed(assignFailedSelector);
  return useSubmit({
    loadingSelector: isLoadingAssignSelector,
    action: assignTask
  })
}


//get list manager
export const useGetListManagers = (query) => {
  return useFetch({
    action: getListManagers,
    loadingSelector:loadingManagerSelector,
    dataSelector: listManagerSelector,
    failedSelector: getListFailedSelector,
    // param: query
  })
  
}
export const useGetListStaffs = (query) => {
  return useFetch({
    action: getListStaffs,
    loadingSelector:loadingStaffSelector,
    dataSelector: listStaffSelector,
    failedSelector: getListFailedSelector,
  
  })
  
}
export const useGetListManagersByIdBoard = (params) => {
  return useFetchByParam({
    action: getListManagersByIdBoard,
    loadingSelector:isLoadingManagerByBoard,
    dataSelector: listManagerByIdBoardSelector,
    failedSelector: getListFailedSelector,
    param: params
  })
}

export const useGetListStaffsByIdBoard = (params) => {
  return useFetchByParam({
    action: getListStaffsByIdBoard,
    loadingSelector:isLoadingStaffByBoard,
    dataSelector: listStaffsByIdBoardSelector,
    failedSelector: getListFailedSelector,
    param: params
  })
}
//filter
export const useGetListTaskBySprints = (query) => {
  return useFetchByParam({
    action: getListTaskBySprints,
    loadingSelector:loadingTaskBySprintsSelector,
    dataSelector: listTaskBySprintsSelector,
    failedSelector: getListFailedSelector,
    // actionUpdate:getListTaskBySprints,
    param: query
  })
}
export const useFilterTask = (query) => {
  return useFetchByParam({
    action: filterBoardConfigItem,
    loadingSelector,
    dataSelector: listBoardConfigItemSelector,
    failedSelector: getListFailedSelector,
    param: query
  })
}