import { SendOutlined } from '@ant-design/icons';
import { Button, List, Popconfirm } from 'antd';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useListBranchConfirm } from '~/hooks/unConfirmWhBill';

export default function TableSelectBranch({ id, onConfirm, isLoadingConfirm }) {
    const query = useMemo(() => {
        return {
            whBillId: id,
        }
    }, [id])
    const [dataSource, isLoading] = useListBranchConfirm(query);
    return (
        <List
            className='list-branch'
            size='small' loading={isLoading}>
            {dataSource?.map((item, index) => {
                const isAvailable = get(item, 'isAvailable');
                return (
                <List.Item
                    className={!isAvailable&& 'disabled-row'}
                    key={index}
                    actions={[
                        <Popconfirm
                            title="Bạn muốn xác nhận xử lí đơn hàng?"
                            onConfirm={() => onConfirm(
                                {
                                    whBillId: id,
                                    branchId: get(item, '_id')
                                })}
                            okText="Xác nhận"
                            cancelText="Huỷ"
                            disabled={!isAvailable}
                            zIndex={9999}
                        >
                            <Button
                            disabled={!isAvailable}
                            className='rounded'
                            type='primary'
                                loading={isLoadingConfirm}
                            >
                                Xác nhận cho chi nhánh
                            </Button>
                        </Popconfirm>

                    ]}
                >
                    {get(item, 'name')}
                </List.Item>
            )
            })}
        </List>
    )
}
