import { Suspense, createContext, lazy, memo, useCallback, useContext, useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetAllBoard } from '~/hooks';
import { clone } from 'lodash';

const NavBoard = lazy(()=>import('./NavBoard.js'))
const SprintPageProvider = createContext();
/**
 * Custom hook that returns the value of the SprintPageProvider context.
 *
 * @returns {{
 * showDrawer:function,
* visibleListBoard:boolean,
* board:Array,
 * }} The value of the SprintPageProvider context.
 */
export const useSprintContext =()=>useContext(SprintPageProvider);

const showDeeply=(rusult,now)=>{
  let clone_ =clone(now)
  rusult.push(clone_)
  if(clone_?.children){
    clone_?.children.reduce(showDeeply,rusult)
  }
  return rusult
}

const  Sprint =()=> {
  let { path } = useRouteMatch();
  const query = useMemo(()=>({page:1,limit:20}),[])
  const [visibleListBoard,setVisibleListBoard]=useState(false)
  const showDrawer = useCallback((param) => {
    setVisibleListBoard((val)=>param??(!val));
  },[]) ;
  const [board, isLoadingList] = useGetAllBoard(query);
  const memoizedProps = useMemo(() => ({
    showDrawer,
    visibleListBoard,
    board: board.reduce(showDeeply,[])
  }), [showDrawer, visibleListBoard, board]);
  return (
    <div className="branch-detail page-wraper page-content page-workflow">
      <div className="sprint-page">
        <SprintPageProvider.Provider value={memoizedProps}>
        <Suspense fallback={<div>...</div>}>
        <NavBoard/>
        </Suspense>
        <SwitchRouter path={path}/>
       
        </SprintPageProvider.Provider>
       
      </div>
    </div>
  );
}

export default Sprint;

const SprintList =lazy(()=>import('./SprintList.js'))
const SwitchRouter = memo(({path})=>{
  return<Switch>
  <Route path={path} render={()=>{
    return <Switch>
      <Route path={path+'/:boardId'} key={'boardId'} component={()=><Suspense fallback={<div>...</div>}><SprintList/></Suspense> } ></Route>
    </Switch>
  }} ></Route>

</Switch>
})
