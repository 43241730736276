import { useEffect, useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getWhAllVoucher,
  getWhAllVouchers,
  rejectWhAllVoucher,
} from '~/redux/action';
import { useHistory } from 'react-router-dom';

import { fromJSON } from '~/components/WhVoucher/parser';
import { get, head } from 'lodash';
import api from '~/api';
import moment from 'moment';

const getSelector = key => state => state.whAllVouchers[key];

const loadingSelector = getSelector('isLoading');
const whAllVouchersSelector = getSelector('whAllVouchers');
const getWhAllVouchersFailedSelector = getSelector('getWhAllVouchersFailed');

const pagingSelector = getSelector('paging');

export const useWhAllVoucherPaging = () => useSelector(pagingSelector);

export const useWhAllVoucherQueryParams = ({defaultBillNumber, defaultPage,branchIdsDefault}) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();

  const billNumber = query.get('billNumber') || defaultBillNumber;
  const code = query.get('code');
  const keyword = query.get('keyword');
  const serviceCode = query.get('serviceCode');
  const managementArea = query.get('managementArea');
  const status = query.get('status');
  const startDate =  query.get('startDate') || moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss") ;
  const endDate =  query.get('endDate') || moment().endOf('month').format("YYYY-MM-DDTHH:mm:ss") ;
  const [limit, setLimit] = useState( query.get('limit') || 10);
  const [page, setPage] = useState(defaultPage || query.get('page') || 1);
  const whCategory = query.get('whCategory') || null;
  
  const branchIds = branchIdsDefault || query.get('branchIds');
  useEffect(() => {
    if (defaultPage) {
      setPage(defaultPage);
    }
  }, [defaultPage]);

  const onTableChange = ({ current, pageSize }) => {
    setPage(current)
    setLimit(pageSize)
  };

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      billNumber,
      code,
      keyword: keyword || undefined,
      limit,
      page,
      serviceCode,
      managementArea,
      status, 
      startDate,
      endDate,
      branchIds,
      whCategory,
    };

    return [queryParams, onTableChange];

    //eslint-disable-next-line
  }, [
    billNumber,
    code,
    keyword,
    limit,
    page,
    serviceCode,
    managementArea,
    status,
    startDate,
    endDate,
    branchIds,
    whCategory,
  ]);
};

export const useWhAllVouchers = query => {
  return useFetchByParam({
    action: getWhAllVouchers,
    loadingSelector,
    dataSelector: whAllVouchersSelector,
    failedSelector: getWhAllVouchersFailedSelector,
    param: query
  });
};


