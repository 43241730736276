import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getWhPartnerRegistersFailed: null,
  getWhPartnerRegisterFailed: null,
  WhPartnerRegisters: [],
  WhPartnerRegister: null,
  isSubmitLoading: false,
  isGetWhPartnerRegisterLoading: false,

  loadingUpdateCourse: false,
  updateFailedCourse: null,
  updateSuccessCourse: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WHPARTNERREGISTERS_REQUEST:
      state.isLoading = true;
      state.getWhPartnerRegistersFailed = null;
      return;

    case Types.GET_WHPARTNERREGISTERS_SUCCESS:
      state.isLoading = false;
      state.WhPartnerRegisters = get(payload, 'docs', []);
      state.getWhPartnerRegistersFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_WHPARTNERREGISTERS_FAILED:
      state.isLoading = false;
      state.WhPartnerRegisters = [];
      state.getWhPartnerRegistersFailed = payload;
      return;
    case Types.GET_WHPARTNERREGISTER_REQUEST:
      state.isGetWhPartnerRegisterLoading = true;
      state.WhPartnerRegister = null
      state.getWhPartnerRegisterFailed = null;
      return;

    case Types.GET_WHPARTNERREGISTER_SUCCESS:
      state.isGetWhPartnerRegisterLoading = false;
      state.WhPartnerRegister = payload;
      state.getWhPartnerRegisterFailed = null;
      return;

    case Types.GET_WHPARTNERREGISTER_FAILED:
      state.isGetWhPartnerRegisterLoading = false;
      state.WhPartnerRegister = null;
      state.getWhPartnerRegisterFailed = payload;
      return;
    case Types.CREATE_WHPARTNERREGISTER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_WHPARTNERREGISTER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_WHPARTNERREGISTER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_WHPARTNERREGISTER_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_WHPARTNERREGISTER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.WhPartnerRegisters = state.WhPartnerRegisters.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.WhPartnerRegister = payload;
      return;

    case Types.UPDATE_WHPARTNERREGISTER_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_WHPARTNERREGISTER_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_WHPARTNERREGISTER_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_STORE:
    case Types.RESET_WHPARTNERREGISTER_STATE:
      return initState;
    case Types.RESET_WHPARTNERREGISTER_STATE_ACTION:
      state.createSuccess = null;
      state.updateFailed = null;
      state.WhPartnerRegister = null;
      state.updateSuccess = null;

      return;
    default:
      return;
  }
}, initState);
