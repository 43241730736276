import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getConfigWhPartnerRegisterTypeForms({ payload: query }) {
    try {
      const response = yield call(Api.configWhPartnerRegisterTypeForm.getAll, query);
      yield put({ type: Types.GET_CONFIGWHPARTNERREGISTERTYPEFORMS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_CONFIGWHPARTNERREGISTERTYPEFORMS_FAILED, payload: error });
    }
  }
function* getConfigWhPartnerRegisterTypeForm({ payload: id }) {
    try {
      const response = yield call(Api.configWhPartnerRegisterTypeForm.getById, id);
      yield put({ type: Types.GET_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED, payload: error });
    }
  }
  function* createConfigWhPartnerRegisterTypeForm(action) {
    try {
      const data = yield call(Api.configWhPartnerRegisterTypeForm.create, action.payload);
      yield put({ type: Types.CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED, payload: error });
    }
  }
  function* updateConfigWhPartnerRegisterTypeForm(action) {
    try {
      const data = yield call(Api.configWhPartnerRegisterTypeForm.update, action.payload);
      yield put({ type: Types.UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED, payload: error });
    }
  }
  function* deleteConfigWhPartnerRegisterTypeForm(action) {
    try {
      const data = yield call(Api.configWhPartnerRegisterTypeForm.delete, action.payload);
      yield put({ type: Types.DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED, payload: error });
    }
  }
export default function* ConfigWhPartnerRegisterTypeForms() {
    yield takeLatest(Types.GET_CONFIGWHPARTNERREGISTERTYPEFORMS_REQUEST, getConfigWhPartnerRegisterTypeForms);
    yield takeLatest(Types.GET_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST, getConfigWhPartnerRegisterTypeForm);
    yield takeLatest(Types.CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST, createConfigWhPartnerRegisterTypeForm);
    yield takeLatest(Types.UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST, updateConfigWhPartnerRegisterTypeForm);
    yield takeLatest(Types.DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST, deleteConfigWhPartnerRegisterTypeForm);
  }
