//get data
export const GET_WAREHOUSE_LISTS_REQUEST = 'GET_WAREHOUSE_LISTS_REQUEST';
export const GET_WAREHOUSE_LISTS_SUCCESS = 'GET_WAREHOUSE_LISTS_SUCCESS';
export const GET_WAREHOUSE_LISTS_FAILED = 'GET_WAREHOUSE_LISTS_FAILED';
//get byid
export const GET_WAREHOUSE_BYID_REQUEST = 'GET_WAREHOUSE_BYID_REQUEST';
export const GET_WAREHOUSE_BYID_SUCCESS = 'GET_WAREHOUSE_BYID_SUCCESS';
export const GET_WAREHOUSE_BYID_FAILED = 'GET_WAREHOUSE_BYID_FAILED';
//create
export const CREATE_WAREHOUSE_REQUEST = 'CREATE_WAREHOUSE_REQUEST';
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
export const CREATE_WAREHOUSE_FAILED = 'CREATE_WAREHOUSE_FAILED';
//update
export const UPDATE_WAREHOUSE_REQUEST = 'UPDATE_WAREHOUSE_REQUEST';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_FAILED = 'UPDATE_WAREHOUSE_FAILED';
//delete
export const DELETE_WAREHOUSE_REQUEST = 'DELETE_WAREHOUSE_REQUEST';
export const DELETE_WAREHOUSE_SUCCESS = 'DELETE_WAREHOUSE_SUCCESS';
export const DELETE_WAREHOUSE_FAILED = 'DELETE_WAREHOUSE_FAILED';
// get productList by idWareHouse
export const GET_WAREHOUSE_PRODUCTLIST_REQUEST = 'GET_WAREHOUSE_PRODUCTLIST_REQUEST';
export const GET_WAREHOUSE_PRODUCTLIST_SUCCESS = 'GET_WAREHOUSE_PRODUCTLIST_SUCCESS';
export const GET_WAREHOUSE_PRODUCTLIST_FAILED = 'GET_WAREHOUSE_PRODUCTLIST_FAILED';
