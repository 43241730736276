import { Button, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect, useState } from 'react';
import { LANGUAGE, requireRules } from '~/constants/defaultValue';
import { useInfoCompany, useCreateInfoCompany, useUpdateInfoCompany } from '~/hooks/infoCompany';
import { renderLoading } from '~/utils/helper';
import LangFormItem from '../Common/LangFormItem';
import LangSelect from '../Common/LangSelect';
import UploadImage from '../Utils/UploadImage';

export default function InfoCompanyForm({ id, onCancel }) {
    const [language,setLanguage] = useState(LANGUAGE.VI);
    const [form] = Form.useForm();
    const [isSubmitLoading, onCreate] = useCreateInfoCompany(onCancel);
    const [, onUpdate] = useUpdateInfoCompany(onCancel);
    const [infoCompany,loading] = useInfoCompany(id);
    const onFinish = useCallback((values) => {
        if (id) {
            onUpdate({
                ...values,
                _id: id
            });
        } else {
            onCreate(values)
        }
    }, [id]);
    useEffect(() => {
        if (id && infoCompany) {
            form.setFieldsValue({ ...infoCompany })
        }
    }, [infoCompany,id]);

    return (
        <Form labelCol={{ span: 8 }} form={form} onFinish={onFinish}>
        <LangSelect language={language} setLanguage={setLanguage}/>
            <LangFormItem label="Tiêu đề" fieldName={"name"} language={language}>
                {renderLoading(<Input />,loading)}
            </LangFormItem>
            <LangFormItem label="Nội dung" fieldName={"description"} language={language}>
                {renderLoading(<TextArea />,loading)}
            </LangFormItem>
            <Form.Item shouldUpdate noStyle>
                {() => <Form.Item label="Hình ảnh" name={"avatar"}>
                {renderLoading(<UploadImage imgUrl={form.getFieldValue('avatar')} onChange={(avatar) => form.setFieldsValue({avatar})} />,loading)}
            </Form.Item>}
            </Form.Item>
            <Row justify='end'>
                <Button loading={isSubmitLoading} htmlType='submit' type='primary'>
                    {id ? "Cập nhật" : "Tạo mới"}
                </Button>
            </Row>
        </Form>
    )
}
