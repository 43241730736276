import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useResetState, useFetchByParam } from '~/hooks/utils';

import {
  getClinicOverViewReport,
  getClinicDailyReport,
  getClinicMonthlyReport,
  getClinicYearlyReport,
  getClinicQuarterlyReport,
  getMostDaily,
  getMostMonthly,
  getMostQuarterly,
  getMostYearly,
  getClinicOverviewToday,
  getClinicOverviewDaily,
  getClinicOverviewMonthly,
  getClinicOverviewQuarterly,
  getClinicOverviewYearly,
  resetClinicDashboardState
} from '~/redux/action';

const CLINIC_DASHBOARD_MODULE = 'clinicDashboard';
const getSelector = (key) => (state) => state[CLINIC_DASHBOARD_MODULE][key];

const overViewSelector = getSelector('overView');
const overViewLoadingSelector = getSelector('isOverViewLoading');
const getOverViewFailedSelector = getSelector('getOverViewFailed');

const yearlySelector = getSelector('yearly');
const yearlyLoadingSelector = getSelector('isYearlyLoading');
const getYearlyFailedSelector = getSelector('getYearlyFailed');

const quarterlySelector = getSelector('quarterly');
const quarterlyLoadingSelector = getSelector('isQuarterlyLoading');
const getQuarterlyFailedSelector = getSelector('getQuarterlyFailed');

const monthlySelector = getSelector('monthly');
const monthlyLoadingSelector = getSelector('isMonthlyLoading');
const getMonthlyFailedSelector = getSelector('getMonthlyFailed');

const dailySelector = getSelector('daily');
const dailyLoadingSelector = getSelector('isDailyLoading');
const getDailyFailedSelector = getSelector('getDailyFailed');

const mostDailySelector = getSelector('mostDaily');
const mostDailyLoadingSelector = getSelector('isMostDailyLoading');
const getMostDailyFailedSelector = getSelector('getMostDailyFailed');

const mostMonthlySelector = getSelector('mostMonthly');
const mostMonthlyLoadingSelector = getSelector('isMostMonthlyLoading');
const getMostMonthlyFailedSelector = getSelector('getMostMonthlyFailed');

const mostQuarterlySelector = getSelector('mostQuarterly');
const mostQuarterlyLoadingSelector = getSelector('isMostQuarterlyLoading');
const getMostQuarterlyFailedSelector = getSelector('getMostQuarterlyFailed');

const mostYearlySelector = getSelector('mostYearly');
const mostYearlyLoadingSelector = getSelector('isMostYearlyLoading');
const getMostYearlyFailedSelector = getSelector('getMostYearlyFailed');

const clinicOverviewTodaySelector = getSelector('clinicOverviewToday');
const clinicOverviewTodayLoadingSelector = getSelector('isClinicOverviewTodayLoading');
const getClinicOverviewTodayFailedSelector = getSelector('getClinicOverviewTodayFailed');

const clinicOverviewDailySelector = getSelector('clinicOverviewDaily');
const clinicOverviewDailyLoadingSelector = getSelector('isClinicOverviewDailyLoading');
const getClinicOverviewDailyFailedSelector = getSelector('getClinicOverviewDailyFailed');

const clinicOverviewMonthlySelector = getSelector('clinicOverviewMonthly');
const clinicOverviewMonthlyLoadingSelector = getSelector('isClinicOverviewMonthlyLoading');
const getClinicOverviewMonthlyFailedSelector = getSelector('getClinicOverviewMonthlyFailed');

const clinicOverviewQuarterlySelector = getSelector('clinicOverviewQuarterly');
const clinicOverviewQuarterlyLoadingSelector = getSelector('isClinicOverviewQuarterlyLoading');
const getClinicOverviewQuarterlyFailedSelector = getSelector('getClinicOverviewQuarterlyFailed');

const clinicOverviewYearlySelector = getSelector('clinicOverviewYearly');
const clinicOverviewYearlyLoadingSelector = getSelector('isClinicOverviewYearlyLoading');
const getClinicOverviewYearlyFailedSelector = getSelector('getClinicOverviewYearlyFailed');

const policiesSelector = (state) => state.user.policy;

export const useClinicOverView = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getClinicOverViewReport,
    loadingSelector: overViewLoadingSelector,
    dataSelector: overViewSelector,
    failedSelector: getOverViewFailedSelector,
    param
  });
};

export const useClinicYearly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getClinicYearlyReport,
    loadingSelector: yearlyLoadingSelector,
    dataSelector: yearlySelector,
    failedSelector: getYearlyFailedSelector,
    param
  });
};

export const useClinicQuarterly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getClinicQuarterlyReport,
    loadingSelector: quarterlyLoadingSelector,
    dataSelector: quarterlySelector,
    failedSelector: getQuarterlyFailedSelector,
    param
  });
};

export const useClinicMonthly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getClinicMonthlyReport,
    loadingSelector: monthlyLoadingSelector,
    dataSelector: monthlySelector,
    failedSelector: getMonthlyFailedSelector,
    param
  });
};

export const useClinicDaily = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getClinicDailyReport,
    loadingSelector: dailyLoadingSelector,
    dataSelector: dailySelector,
    failedSelector: getDailyFailedSelector,
    param
  });
};

export const useMostDaily = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);
  return useFetchByParam({
    action: getMostDaily,
    loadingSelector: mostDailyLoadingSelector,
    dataSelector: mostDailySelector,
    failedSelector: getMostDailyFailedSelector,
    param
  });
};

export const useMostMonthly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getMostMonthly,
    loadingSelector: mostMonthlyLoadingSelector,
    dataSelector: mostMonthlySelector,
    failedSelector: getMostMonthlyFailedSelector,
    param
  });
};

export const useMostQuarterly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getMostQuarterly,
    loadingSelector: mostQuarterlyLoadingSelector,
    dataSelector: mostQuarterlySelector,
    failedSelector: getMostQuarterlyFailedSelector,
    param
  });
};

export const useMostYearly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getMostYearly,
    loadingSelector: mostYearlyLoadingSelector,
    dataSelector: mostYearlySelector,
    failedSelector: getMostYearlyFailedSelector,
    param
  });
};

export const useClinicOverviewToday = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getClinicOverviewToday,
    loadingSelector: clinicOverviewTodayLoadingSelector,
    dataSelector: clinicOverviewTodaySelector,
    failedSelector: getClinicOverviewTodayFailedSelector,
    param
  })
}

export const useClinicOverviewDaily = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getClinicOverviewDaily,
    loadingSelector: clinicOverviewDailyLoadingSelector,
    dataSelector: clinicOverviewDailySelector,
    failedSelector: getClinicOverviewDailyFailedSelector,
    param
  })
}

export const useClinicOverviewMonthly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getClinicOverviewMonthly,
    loadingSelector: clinicOverviewMonthlyLoadingSelector,
    dataSelector: clinicOverviewMonthlySelector,
    failedSelector: getClinicOverviewMonthlyFailedSelector,
    param
  })
}

export const useClinicOverviewQuarterly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getClinicOverviewQuarterly,
    loadingSelector: clinicOverviewQuarterlyLoadingSelector,
    dataSelector: clinicOverviewQuarterlySelector,
    failedSelector: getClinicOverviewQuarterlyFailedSelector,
    param
  })
}

export const useClinicOverviewYearly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getClinicOverviewYearly,
    loadingSelector: clinicOverviewYearlyLoadingSelector,
    dataSelector: clinicOverviewYearlySelector,
    failedSelector: getClinicOverviewYearlyFailedSelector,
    param
  })
}

export const useClinicResetDashboard = () => {
  useResetState(resetClinicDashboardState);
};
