import { ArrowLeftOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Divider, Empty, Form, List, Radio, Row, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '~/api';
import { addAddressSelect } from '~/redux/action';
import ItemListAddressUser from './ItemListAddressUser';
import LoadingWhBill from './LoadingWhBill';
import ModalAddAddress from './ModalAddAddress';
import './wh-order-temp.scss';
import toastr from 'toastr';
import TooltipTitle from '../Common/TooltipTitle';
import { isEmpty } from 'lodash';
export default function ConvertQuotationStepTwo({ setStep, step, address }) {
  const userSelect = useSelector(state => state.quotation.userSelect)
  const dispatch = useDispatch()
  const [status, setStatus] = useState(false)
  const [addressCustomer, setAddressCustomer] = useState([]);
  const [listAddress, setListAddress] = useState([])
  const [value, setValue] = useState(1);
  useEffect(() => {
    setValue(listAddress[0])
  }, [listAddress])
  const onChange = (e) => {
    setValue(e.target.value);
    dispatch(addAddressSelect(e.target.value))
  };
  const [loading,setLoading] = useState(false)
  const fetchData = useCallback(async() => {
    try {
      setLoading(true)
    const res = await api.userEmployee.getAddressUserById(userSelect._id)
   if(res.length !== 0){
    setAddressCustomer(res)
    setLoading(false)
    dispatch(addAddressSelect(res[0]))
   }
   else{
    setLoading(false)
   }
    } catch (error) {
      toastr.error("Something went wrong")
      setLoading(false)
    }
  },[status])
  useEffect(() => {
    fetchData()
  }, [fetchData]);

  useEffect(() => {
    const isCheck = address ? isEmpty(address) : true;
    if (!isCheck) {
      setListAddress([address, ...addressCustomer]);
    } else {
      setListAddress(addressCustomer);
    }
  }, [address, addressCustomer]);
  const reFetch = () => {
    setStatus(!status)
  }
  const [visibleModalAddAddress,setVisibleModalAddAddress] = useState(false)
  return (
    <>
      <div className="page-content__main">
          <LoadingWhBill  open={loading}/>
        <div className="wh-orderTemp-Box" style={{width : '95%'}}>
          {/* <LoadingWhBill open={loading} /> */}
          <h3>Thông tin địa chỉ</h3>
          <Row style={{width : '100%'}} justify='space-between'>
          <Button onClick={() => setStep(1)}><ArrowLeftOutlined /></Button>
          <Button onClick={() => setVisibleModalAddAddress(true)} icon={<PlusCircleTwoTone />}  type='primary'>Thêm địa chỉ mới</Button>
          </Row>
          <ModalAddAddress reFetch={reFetch} visibleModalAddAddress={visibleModalAddAddress} setVisibleModalAddAddress={setVisibleModalAddAddress}/>
          <Radio.Group style={{padding : '20px',width : '100%'}} onChange={onChange} value={value}>
      <Space direction='vertical' style={{width : '100%'}} align={!!listAddress?.length  ? 'start' : 'center'}>
      
      {
        !!listAddress?.length 
        ? listAddress?.map((item,index) => <Radio  key={index} style={{padding : '10px 0'}} value={item}><h6>{`${item.street}, ${item.ward}, ${item.district}, ${item.city}`}</h6></Radio>)
        : <Empty />
        }
    
      </Space>
    </Radio.Group>
          {/* <List
            style={{width : '800px'}}
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={listAddress}
            renderItem={(item) => <ItemListAddressUser item={`${item.street}, ${item.ward}, ${item.district}, ${item.city}`}/>}
          /> */}
          <TooltipTitle titleTooltip={!listAddress.length ? "Bạn phải chọn địa chỉ" : ""} content={
            <Button disabled={!listAddress?.length} onClick={() => setStep(3)} type='primary'>Tiếp theo</Button>
          }/>
        </div>
      </div>
    </>
  );
}
