import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getKeywordSearchsFailed: null,
  getKeywordSearchFailed:null,
  KeywordSearchs: [],
  KeywordSearch: null,
  isSubmitLoading : false,
  isGetKeywordSearchLoading: false,
  KeywordSearch: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_KEYWORD_SEARCHS_REQUEST:
      state.isLoading = true;
      state.getKeywordSearchsFailed = null;
      return;

    case Types.GET_KEYWORD_SEARCHS_SUCCESS:
      state.isLoading = false;
      state.KeywordSearchs = payload.docs;
      state.getKeywordSearchsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_KEYWORD_SEARCHS_FAILED:
      state.isLoading = false;
      state.KeywordSearchs = [];
      state.getKeywordSearchsFailed = payload;
      return;
    case Types.GET_KEYWORD_SEARCH_REQUEST:
      state.isGetKeywordSearchLoading = true;
      state.KeywordSearch = null
      state.getKeywordSearchFailed = null;
      return;

    case Types.GET_KEYWORD_SEARCH_SUCCESS:
      state.isGetKeywordSearchLoading = false;
      state.KeywordSearch = payload;
      state.getKeywordSearchFailed = null;
      return;

    case Types.GET_KEYWORD_SEARCH_FAILED:
      state.isGetKeywordSearchLoading = false;
      state.KeywordSearch = null;
      state.getKeywordSearchFailed = payload;
      return;
    case Types.CREATE_KEYWORD_SEARCH_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_KEYWORD_SEARCH_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_KEYWORD_SEARCH_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_KEYWORD_SEARCH_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_KEYWORD_SEARCH_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.KeywordSearch = payload;
        state.KeywordSearchs = state.KeywordSearchs.map(e => get(e,'_id') === get(payload,'_id') ? payload : e);

        return;
  
      case Types.UPDATE_KEYWORD_SEARCH_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_KEYWORD_SEARCH_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_KEYWORD_SEARCH_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_KEYWORD_SEARCH_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_KEYWORD_SEARCH_STATE:
      return initState;

    default:
      return;
  }
}, initState);
