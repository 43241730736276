import { Menu } from 'antd'
import React from 'react'
import { v4 } from 'uuid'
import { TYPE_ACTION_PERMISSIONS } from '~/constants/defaultValue'
import PermissionToolTip from './PermissionToolTip'

export default function PermissionAction({ permission, type = "UNKNOW", handle }) {
    const title = TYPE_ACTION_PERMISSIONS?.[type]?.vi
    return (
        <PermissionToolTip 
            type='text'
            childrenText={<Menu.Item disabled ><p>{title}</p></Menu.Item>}
            permission={permission}
            textButton={title}
            textPermission={`Bạn không có quyền ${title}`} >
            <Menu.Item  onClick={handle}>
                <p>{title}</p>
            </Menu.Item>
        </PermissionToolTip>
    )
}
