import { get } from 'lodash';
import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import {
  CREATE_WH_RECEIPT_VOUCHER_FAILED,
  CREATE_WH_RECEIPT_VOUCHER_REQUEST,
  CREATE_WH_RECEIPT_VOUCHER_SUCCESS,
  CREATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_FAILED,
  CREATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_REQUEST,
  CREATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_SUCCESS,
  DELETE_WH_RECEIPT_VOUCHER_FAILED,
  DELETE_WH_RECEIPT_VOUCHER_REQUEST,
  DELETE_WH_RECEIPT_VOUCHER_SUCCESS,
  GET_WH_RECEIPT_VOUCHERS_FAILED,
  GET_WH_RECEIPT_VOUCHERS_REQUEST,
  GET_WH_RECEIPT_VOUCHERS_SUCCESS,
  GET_WH_RECEIPT_VOUCHER_FAILED,
  GET_WH_RECEIPT_VOUCHER_REQUEST,
  GET_WH_RECEIPT_VOUCHER_SUCCESS,
  UPDATE_WH_RECEIPT_VOUCHER_FAILED,
  UPDATE_WH_RECEIPT_VOUCHER_REQUEST,
  UPDATE_WH_RECEIPT_VOUCHER_SUCCESS,
  UPDATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_FAILED,
  UPDATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_REQUEST,
  UPDATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_SUCCESS,
  CONFIRM_WH_RECEIPT_VOUCHER_FAILED,
  CONFIRM_WH_RECEIPT_VOUCHER_REQUEST,
  CONFIRM_WH_RECEIPT_VOUCHER_SUCCESS,
  APPROVE_WH_RECEIPT_VOUCHER_FAILED,
  APPROVE_WH_RECEIPT_VOUCHER_REQUEST,
  APPROVE_WH_RECEIPT_VOUCHER_SUCCESS,
  REJECT_WH_RECEIPT_VOUCHER_FAILED,
  REJECT_WH_RECEIPT_VOUCHER_REQUEST,
  REJECT_WH_RECEIPT_VOUCHER_SUCCESS,
} from '../../constants/actionTypes';

function* getWhReceiptVouchers({ payload: query }) {
  try {
    let response
    if(get(query,'modeCustomerCancel')){
      response = yield call(Api.reportSystem.getWhPaymentCustomerCancelReports, query);
    }else{
      response = yield call(Api.whReceiptVoucher.getWhReceiptVouchers, query);
    }
    yield put({ type: GET_WH_RECEIPT_VOUCHERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_RECEIPT_VOUCHERS_FAILED, payload: error.message });
  }
}

function* getWhReceiptVoucher({ payload: id }) {
  try {
    const whReceiptVoucher = yield call(Api.whReceiptVoucher.getById, id);
    yield put({ type: GET_WH_RECEIPT_VOUCHER_SUCCESS, payload: whReceiptVoucher });
  } catch (error) {
    yield put({ type: GET_WH_RECEIPT_VOUCHER_FAILED, payload: error });
  }
}

function* createWhReceiptVoucher(action) {
  try {
    const data = yield call(Api.whReceiptVoucher.create, action.payload);
    yield put({ type: CREATE_WH_RECEIPT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    console.error(error);
    yield put({ type: CREATE_WH_RECEIPT_VOUCHER_FAILED, payload: error });
  }
}

function* createWhReceiptVoucherByWhPartner(action) {
  try {
    const data = yield call(Api.whReceiptVoucher.createByWhPartner, action.payload);
    yield put({ type: CREATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    console.error(error);
    yield put({ type: CREATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_FAILED, payload: error });
  }
}

function* updateWhReceiptVoucher(action) {
  try {
    const data = yield call(Api.whReceiptVoucher.update, action.payload);
    yield put({ type: UPDATE_WH_RECEIPT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_RECEIPT_VOUCHER_FAILED, payload: error });
  }
}

function* updateWhReceiptVoucherByWhPartner(action) {
  try {
    const data = yield call(Api.whReceiptVoucher.updateByWhPartner, action.payload);
    yield put({ type: UPDATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_FAILED, payload: error });
  }
}

function* confirmWhReceiptVoucher(action) {
  try {
    const data = yield call(Api.whReceiptVoucher.confirm, action.payload);
    yield put({ type: CONFIRM_WH_RECEIPT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CONFIRM_WH_RECEIPT_VOUCHER_FAILED, payload: error });
  }
}

function* approveWhReceiptVoucher(action) {
  try {
    const data = yield call(Api.whReceiptVoucher.approve, action.payload);
    yield put({ type: APPROVE_WH_RECEIPT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: APPROVE_WH_RECEIPT_VOUCHER_FAILED, payload: error });
  }
}

function* rejectWhReceiptVoucher(action) {
  try {
    const data = yield call(Api.whReceiptVoucher.reject, action.payload);
    yield put({ type: REJECT_WH_RECEIPT_VOUCHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: REJECT_WH_RECEIPT_VOUCHER_FAILED, payload: error });
  }
}

function* deleteWhReceiptVoucher({ payload }) {
  try {
    yield call(Api.whReceiptVoucher.delete, payload);
    yield put({ type: DELETE_WH_RECEIPT_VOUCHER_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_WH_RECEIPT_VOUCHER_FAILED, payload: error.message });
  }
}

export default function* whReceiptVoucher() {
  yield takeLatest(CREATE_WH_RECEIPT_VOUCHER_REQUEST, createWhReceiptVoucher);
  yield takeLatest(CREATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_REQUEST, createWhReceiptVoucherByWhPartner);
  yield takeLatest(DELETE_WH_RECEIPT_VOUCHER_REQUEST, deleteWhReceiptVoucher);
  yield takeLatest(GET_WH_RECEIPT_VOUCHERS_REQUEST, getWhReceiptVouchers);
  yield takeLatest(GET_WH_RECEIPT_VOUCHER_REQUEST, getWhReceiptVoucher);
  yield takeLatest(UPDATE_WH_RECEIPT_VOUCHER_REQUEST, updateWhReceiptVoucher);
  yield takeLatest(UPDATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_REQUEST, updateWhReceiptVoucherByWhPartner);
  yield takeLatest(CONFIRM_WH_RECEIPT_VOUCHER_REQUEST, confirmWhReceiptVoucher);
  yield takeLatest(APPROVE_WH_RECEIPT_VOUCHER_REQUEST, approveWhReceiptVoucher);
  yield takeLatest(REJECT_WH_RECEIPT_VOUCHER_REQUEST, rejectWhReceiptVoucher);
}
