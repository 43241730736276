import { Button, Row } from 'antd'
import React from 'react'
import POLICIES from '~/constants/policy'
import { WithOrPermission } from '../Common'

export default function BtnUpdate({ loading }) {
    return (
        <WithOrPermission permission={[POLICIES.UPDATE_BANNER,POLICIES.WRITE_BANNER,POLICIES.DELETE_BANNER]}>
            <Row justify='center'>
            <Button
                loading={loading}
                type="primary"
                htmlType="submit"
            >
                Cập nhật
            </Button>
        </Row>
        </WithOrPermission>
    )
}
