import requester from './requester';

const dashboard = {
  getOverViewReport: () => requester.get('/chart-dashboard-world-health/overview'),

  getOverviewToday: () => requester.get('/chart-dashboard-world-health/overviewToday'),
  getOverviewDaily: () => requester.get('/chart-dashboard-world-health/overviewDaily'),
  getOverviewMonthly: () => requester.get('/chart-dashboard-world-health/overviewMonthly'),
  getOverviewQuarterly: () => requester.get('/chart-dashboard-world-health/overviewPrecious'),
  getOverviewYearly: () => requester.get('/chart-dashboard-world-health/overviewYearly'),

  getDailyReport: () => requester.get('/chart-dashboard-world-health/daily'),
  getMonthlyReport: () => requester.get('/chart-dashboard-world-health/monthly'),
  getQuarterlyReport: () => requester.get('/chart-dashboard-world-health/precious'),
  getYearlyReport: () => requester.get('/chart-dashboard-world-health/yearly'),
  
  getMostService: () => requester.get('/chart-dashboard-world-health/mostService'),
  getMostRevenueService: () => requester.get('/chart-dashboard-world-health/mostRevenueService'),
  getMostPartnerAppointment: () => requester.get('/chart-dashboard-world-health/mostPartnerAppointment'),
  getMostCustomerAppointment: () => requester.get('/chart-dashboard-world-health/mostCustomerAppointment')
};

export default dashboard;
