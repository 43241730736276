import { Button, Col, Form, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React from 'react'

export default function ModalDeny({onClose,handleDeny,isLoading}) {
    const [form] = Form.useForm()
    const onFinish = (values) => {
        handleDeny(values) 
    }
  return (
    <Form form={form} onFinish={onFinish}>
    <Form.Item name='reasonDeny' label="Lý do từ chối">
        <TextArea autoSize/>
    </Form.Item>
    <Row justify='center' gutter={16}>
        <Col span={7}>
            <Button block onClick={onClose}>Huỷ</Button>
        </Col>
        <Col span={7}>
            <Button loading={isLoading} block danger htmlType='submit'>Từ chối</Button>
        </Col>
    </Row>
    </Form>
  )
}
