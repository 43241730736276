import React, { useEffect, useCallback} from 'react';
import { Form, Input, Row, Button, Skeleton, Col, Select } from 'antd';
import { RATE_CONFIG_TYPES } from '~/constants/defaultValue';
import { PATH_APP } from '~/routes/paths';
import { Link } from 'react-router-dom';
import {
  useRateConfig,
  useCreateRateConfig,
  useUpdateRateConfig,
  useInitRateConfig,
  useResetRateConfig,
} from '~/hooks/rateConfig';
import { useStaffGroups } from '~/hooks';
import { get } from 'lodash';

const FormItem = Form.Item;
const { Option } = Select;

const DegreeListForm = ({ specialityOptions, onClose, degreeListId }) => {
  const [form] = Form.useForm();
  const [staffGroups, isLoading] = useStaffGroups();
  const [isSubmitLoading, handleCreate] = useCreateRateConfig();
  const [, handleUpdate] = useUpdateRateConfig(onClose);
  const [degreeList] = useRateConfig(degreeListId);
  const initDegreeList = useInitRateConfig(degreeList);
  useResetRateConfig();
  useEffect(() => {
    if (!degreeListId) {
      form.resetFields();
    } else {
      const tmp={
        ...initDegreeList,
        employeeGroup: get(initDegreeList, "employeeGroup.0._id"),
      }
      form.setFieldsValue(tmp);
    }
  }, [initDegreeList, degreeListId, form]);

  const onFinish = useCallback(
    (values) => {
      if (degreeListId) {
        handleUpdate({ ...values, id: degreeListId });
      } else {
        handleCreate({ ...values, configType: RATE_CONFIG_TYPES.DEGREE });
      }
      onClose();
    },
    [handleCreate, handleUpdate, degreeListId]
  );

  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="degree-list page-wraper hospital-form-page-content">
    <h4 style={{margin: '20px 0 40px 20px'}}>{ degreeListId ? ' Cập nhật' : 'Thêm mới'} bằng cấp</h4>
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          // initialValues={initDegree}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
        <Col span={24}>
              <FormItem
                label="Nhóm đối tác"
                name="employeeGroup"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn nhóm đối tác'
                  }
                ]}
              >
                {false ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    // loading=  {isLoading}
                    showSearch
                    allowClear
                    autoComplete="off"
                    placeholder="Nhóm đối tác"
                    style={{width:'100%'}}
                    // filterOption={filterAcrossAccents}
                  >
                    {staffGroups?.map(({ id, _id, name }) => (
                      <Option key={id || _id} value={id || _id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
        </Col>
          <FormItem label="Loại bằng cấp (vi)" name={["name", "vi"]} rules={[{ required: true, message: 'Xin vui lòng nhập loại bằng cấp'}]}>
            <Input/>
          </FormItem>
          <FormItem label="Loại bằng cấp (en)" name={["name", "en"]} rules={[{ required: true, message: 'Xin vui lòng nhập loại bằng cấp'}]}>
            <Input/>
          </FormItem>
          <FormItem label="Hệ số" name="rate" rules={[{ required: true, message: 'Xin vui lòng nhập hệ số' }]}>
          <Input/>
          </FormItem>
          <Row className="form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.rateConfig.degreeList}>
                <Button onClick={onClose}>Huỷ</Button>
                </Link>
            )}
            
            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {degreeListId ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default DegreeListForm;
