import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext
} from 'react';

import { notification, Select, Spin } from 'antd';
import { debounce, get, isObject, set, transform } from 'lodash';
import { connect } from 'echarts';
const DebounceSelect = (
  {
    fetchOptions,
    debounceTimeout = 500,
    labelKey = 'name',
    valueKey = 'id',
    searchKey = 'keyword',
    initOptions,
    actionDispatch,
    reload,
    noWhBillId,
    value,
    dispatchImport,
    dispatchUpdate,
    createService,
    updateService,
    idAfterSlice,
    listService,
    ...props
  },
  ref
) => {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueServiceBefore, setValueServiceBefore] = React.useState(null);
  const fetchRef = React.useRef(0);

  useEffect(() => {
    if (initOptions && props.value) {
      const newOptions = initOptions.map((option) => ({
        label: get(option, [labelKey, 'vi']),
        value: get(option, valueKey)
      }));
      setOptions(newOptions);
    }
  }, [initOptions]);

  const transformValue = (value) => {
    if (!isObject(value) && initOptions) {
      const currentOption = initOptions.find(
        (item) => get(item, valueKey) === value
      );
      if (currentOption)
        return {
          value,
          label: currentOption.description
            ? `${get(currentOption, labelKey)} 
               ${!noWhBillId ? ( get(currentOption,'description.vi',"")) : ""}`
            : get(currentOption, labelKey)
        };
      return value;
    }
    return value;
  };
  // Fetch API select
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions({ [searchKey]: value || '' }).then((response) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        const newOptions = response.map((option) => {
          const showOption =  listService ? {label: option.detailName
          ? `${get(option, "code")} - ${get(option, labelKey)} (${get(option, 'detailName.vi',"")}) `
          : `${get(option, "code")} - ${get(option, labelKey)}`,
            value: get(option, valueKey) }
            :
            {label: option.detailName
        ? `${get(option, labelKey)} (${get(option, 'detailName.vi',"")}) `
        : get(option, labelKey),
      value: get(option, valueKey)}
          return {
            ...option,
            ...showOption
          };
        });
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  // Handle Post Service By ID
  const handlePostService = dispatchImport
    ? (e) => {
        const param = {
          id: idAfterSlice,
          serviceId: e.value
        };
        if (!!!valueServiceBefore) {
          createService(param);
        } else {
          const data = {
            id: idAfterSlice,
            param: {
              serviceId: valueServiceBefore.value ?? valueServiceBefore,
              serviceIdNew: e.value
            }
          };
          updateService(data);
        }
      }
    : () => {};
  return (
    <Select
      ref={ref}
      showSearch
      labelInValue
      onSelect={(e, index) => {
        handlePostService(e, index);
        // setValueServiceAfter(e)
      }}
      filterOption={false}
      onSearch={debounceFetcher}
      onFocus={() => {
        setValueServiceBefore(value);
        debounceFetcher();
      }}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      value={transformValue(value)}
      options={(options.length === 0 && listService) ? [{
        label: "Dịch vụ không tồn tại!",
        value: 0,
        disabled: true,
      }]: options}
    />
  );
};

export default forwardRef(DebounceSelect);
