import { Col, Row } from 'antd';
import { get } from 'lodash';
import { useMemo } from 'react';
import { v4 } from 'uuid';
import ListExpand from '../Common/ListExpand';
import LinkFile from '../Common/TypeFile/LinkFileImage';
import Editor from '../Utils/Editor';
export default function FormSendMail({ initData }) {
    const content = useMemo(() => {
        const base64 = get(initData, 'content', '');
        const buffer = Buffer.from(base64, 'base64');
        const originalStr = buffer.toString('utf-8');
        return originalStr
    }, [initData]);
    return (
        <div>
            <Row>
                <Col span={4}>
                    <span>Nội dung: </span>
                </Col>
                <Col span={20}>
                    <Editor value={content} />
                </Col>
            </Row>

            <Row>
                <Col span={4}>
                    <span>File : </span>
                </Col>
                <Col span={20}>
                        <ListExpand dataSource={get(initData,'files')} itemRender={(item) => <li>
                            <LinkFile
                                key={v4()}
                                href={get(item, 'response.url')}
                                url={get(item, 'name')}
                            />
                        </li>} />
                </Col>
            </Row>


        </div>
    )
}
