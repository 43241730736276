import React, { useState } from 'react';
import { Input, Row, Select, Tabs } from 'antd';
import WhAppointment from './WhAppointment';
import { useParams, useHistory } from 'react-router-dom';
import WhBill from './WhBill';
import WhOrder from './WhOrder';
import { Col } from 'reactstrap';
import { APPOINTMENT_STATUS_SEARCH_CUSTOMER, MAP_WH_BILL_STATUS_TAG, ORDER_STATUS_KEY_SEARCH_COLOR } from '~/constants/defaultValue';
import { get, transform } from 'lodash';
const { Search } = Input;

const { TabPane } = Tabs;
export default function HistoryCustomer() {
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState('1');
  const [data, setData] = useState([]);
  const [keywordOrder, setKeywordOrder] = useState(null);
  const [keywordBill, setKeywordBill] = useState(null);
  const [keywordAppointment, setKeywordAppointment] = useState(null);
  const [valueSearch, setValueSearch] = useState(null);

  const [optionSelectStatus, setOptionSelectStatus] = useState(MAP_WH_BILL_STATUS_TAG);
  const [statusBill, setStatusBill] = useState([]);
  const [statusAppointment, setStatusAppointment] = useState([]);
  const [statusOrder, setStatusOrder] = useState([]);

  const history = useHistory();

  const onSearch = (valueSearch) => {
    const regex = /[0-9.]/g;
    let newKeyword = valueSearch;
    if (regex.test(valueSearch)) {
      newKeyword = newKeyword.replace(/[.|| , ]/g, '');
    }
    switch (activeKey) {
      case '1':
        setKeywordBill(newKeyword);
        break;
      case '2':
        setKeywordAppointment(newKeyword);
        break;
      case '3':
        setKeywordOrder(newKeyword);
        break;
    };
  };
  const onSearchStatus = (value) => {
    const status = (value)?.toString();
    switch (activeKey) {
      case '1':
        setStatusBill(status);
        break;
      case '2':
        setStatusAppointment(status);
        break;
      case '3':
        setStatusOrder(status);
        break;
    };
  };

  const onChangeTab = (key) => {
    setActiveKey(key);
    setValueSearch(null);

    setKeywordAppointment(null);
    setKeywordBill(null);
    setKeywordOrder(null);

    setKeywordOrder(null);
    setKeywordBill(null);
    setKeywordAppointment(null);

    switch (key) {
      case '1':
        setOptionSelectStatus(MAP_WH_BILL_STATUS_TAG);
        break;
      case '2':
        setOptionSelectStatus(APPOINTMENT_STATUS_SEARCH_CUSTOMER);
        break;
      case '3':
        setOptionSelectStatus(ORDER_STATUS_KEY_SEARCH_COLOR);
        break;
      default: MAP_WH_BILL_STATUS_TAG;
        break;
    };
  };

  return (
    <>
      <div className="page-wrapper__header">
        <Row style={{width: '50%'}}>
          <Col span={6}>
            <Search
              style={{ width: 300 }}
              placeholder="Tìm bất kỳ"
              enterButton
              allowClear
              onSearch={(e) =>onSearch(e)}
              onChange={(e) => setValueSearch(e.target.value)}
              value={valueSearch}
            />
          </Col>
          <Col span={6}>
            <Select
                mode="multiple"
                allowClear
                // value={query?.status ? get(query, 'status', []).split(',') : []}
                style={{
                  width: '100%'
                }}
                placeholder="Vui lòng chọn trạng thái"
                onChange={(value) => onSearchStatus(value)}
            >
                {transform(
                    optionSelectStatus,
                    (result, value, key) => {
                      result.push({ label: get(value, 'name', ''), value: key, color: get(value, 'colorStyle', '') });
                    },
                    []
                  )?.map((e) => (
                    <Select.Option value={get(e, 'value')}>
                      <p style={{ color: `${e.color}` }}>{get(e, 'label')}</p>
                    </Select.Option>
                  ))}
            </Select>
          </Col>
        </Row>
      </div>
      <Tabs activeKey={activeKey} onChange={onChangeTab}>
        <TabPane
          key={'1'}
          tab={
            <>
              <i className="uil-apps me-2"></i>
              WorldHealth
            </>
          }
        >
          <WhBill
            id={id}
            keyword={keywordBill}
            statusBill = {statusBill}
          />
        </TabPane>
        <TabPane
          key={'2'}
          tab={
            <>
              <i className="uil uil-clinic-medical"></i>
              Phòng khám
            </>
          }
        >
          <WhAppointment
            id={id}
            keyword={keywordAppointment}
            statusAppointment = {statusAppointment}
          />
        </TabPane>
        <TabPane
          key={'3'}
          tab={
            <>
              <i className="uil-apps me-2"></i>
              Nhà thuốc
            </>
          }
        >
          <WhOrder
            id={id}
            keyword={keywordOrder}
            statusOrder = {statusOrder}
          />
        </TabPane>
      </Tabs>
    </>
  );
}
