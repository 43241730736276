import requester from './requester';

const UnConfirmWhBill = {
  confirm: (query) => {
    const {whBillId,branchId} = query;
    return requester.put(`/wh-bill/confirm/${whBillId}`,{branchId})
  },
  getAll: (query) => requester.get('/wh-bill/unconfimred', query),
  getOne: (id) => requester.get(`/wh-bill/${id}`),
  getBranchConfirm: ({whBillId}) => requester.post(`/wh-service-branch/valid-confirm`,{whBillId,getDisabled : true}), // USE POST BECAUSE: IN THE FUTURE branch CAN use location to Find Branch
};
export default UnConfirmWhBill;
