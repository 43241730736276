import { get } from "lodash";

export default function TableRenderWordConfirmVoucher(listProduct) {
    const styleP = "font-family: serif;margin : 0 auto;";
    const styleFlex = "width : 100% ; display : flex;flex-direction : column;align-items:center;justify-content:center;gap : 1px;"
    const styleTd = "border: 1px solid black;padding: 8px;";
    const styleTrHeader = "border-color : black ; border-style : solid ; border-width : 1;font-family: serif;padding : 5px 0;";
    let listItem = ""
    listProduct?.forEach((item, index) => {
      listItem += `<tr>
      <td style="${styleTd}"><p style="${styleP}">${index + 1}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'name', '')}</p></td>
      <td style="${styleTd}"><p style="${styleP}">${get(item, 'whAppointmentCode', '')}</p></td>
    </tr>`
    }
    )
  return `<table style="border-collapse: collapse;font-family: serif;width : 100%;">
  <thead >
    <tr >
    <th style="${styleTrHeader}"><p style="font-family: serif;">STT</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Tên tài sản</p></th>
    <th style="${styleTrHeader}"><p style="font-family: serif;">Mã buổi hẹn</p></th>
    </tr>
    </thead>
    ${listItem}
    </table>`
}
