import { Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import api from '~/api';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { SOURCE_BOOKING, WH_APPOINTMENT_STATUS_IN_HISTORY } from '~/constants/defaultValue';
import './index.scss';
import toastr from 'toastr';

export default function WhAppointment({ id, keyword, statusAppointment }) {
  //Handle when data is not yet exits
  const customerId = id;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  //Handle when data is exits
  const [paging, setPaging] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const query = useMemo(
    () => ({
      page: page || 1,
      limit: limit || 10,
      customerId: customerId,
      keyword: keyword || null,
      status: statusAppointment || null
    }),
    [customerId, page, limit,keyword,statusAppointment]
  );
  const getData = async () => {
    setIsLoading(true);
    const res = await api.appointment.getByIdCustomer(query);
    const data = res?.docs;
    setData(data);
    setPaging({
      current: res?.page,
      pageSize: res?.limit,
      total: res?.totalDocs
    });
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, [id, keyword, page, limit,statusAppointment]);

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      dataIndex: 'stt',
      render: (text, record, index) => {
        return  (paging?.current - 1) * paging?.pageSize +  index + 1;
      }
    },
    {
      title: 'Nguồn tạo lịch hẹn',
      key: 'branch',
      dataIndex: 'branch',
      render: (value)=> value ? value?.name?.vi : 'Website'
    },
    {
      title: 'Phòng khám thực hiện',
      key: 'hospital',
      dataIndex: 'hospital',
      render: (value)=> value?.name?.vi
    },
    {
      title: 'Dịch vụ',
      dataIndex: 'speciality',
      key: 'name',
      render: (value)=> value?.name?.vi
    },
    {
      title: 'Ngày',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value)=> moment(value)?.format('HH:mm DD/MM/YYYY')
    },
    {
      title: 'Trạng thái buổi hẹn',
      key: 'status',
      dataIndex: 'status',
      render: (value) => {
        return (
          <span className={`wh-appointment-status ${(value)?.toLowerCase()}`}>
          {WH_APPOINTMENT_STATUS_IN_HISTORY[value]?.name}
        </span>)
      }
    },
    {
      title: 'Hình thức tạo lịch hẹn',
      key: 'source',
      dataIndex: 'source',
      render: (value)=> SOURCE_BOOKING[value]
    },
  ];
  return isLoading ? (
    <SkeletonTable columns={columns} rowCount={3} />
  ) : (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{ ...paging, showTotal: (total) => `Tổng cộng ${total}` }}
        size="small"
        onChange={({current,pageSize}) => {
          setPage(current);
          setLimit(pageSize);
        }}
        scroll={{ x: 'max-content' }}
    />
  );
}
