import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createJobForm,
  deleteJobForm,
  getJobForm,
  getJobForms,
  resetJobFormState,
  updateJobForm
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.jobForm[key];
const pagingSelector = getSelector('paging');
export const useJobFormPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetJobFormSelector = getSelector('isGetJobFormLoading');
const getJobFormsFailedSelector = getSelector('getJobFormsFailed');
const getJobFormFailedSelector = getSelector('getJobFormFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const JobFormsSelector = getSelector('JobForms');
const JobFormSelector = getSelector('JobForm');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useJobFormQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const active = query.get('active');
  const createJobFormSuccess = useSelector(createSuccessSelector);
  const deleteJobFormSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      active,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    active,
    createJobFormSuccess,
    deleteJobFormSuccess,
  ]);
};

export const useUpdateJobFormParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.jobForm.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};
export const useJobForms = (query) => {
  return useFetchByParam({
    action: getJobForms,
    loadingSelector,
    dataSelector: JobFormsSelector,
    failedSelector: getJobFormsFailedSelector,
    param: query
  });
};
export const useJobForm = (id) => {
  return useFetchByParam({
    action: getJobForm,
    loadingSelector: loadinggetJobFormSelector,
    dataSelector: JobFormSelector,
    failedSelector: getJobFormFailedSelector,
    param: id
  });
};
export const useCreateJobForm = (callback) => {
  
  useSuccess(createSuccessSelector, 'Tạo mới câu hỏi thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới câu hỏi thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createJobForm
  });
};
export const useUpdateJobForm = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât câu hỏi thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateJobForm
  });
};
export const useDeleteJobForm = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá câu hỏi thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật câu hỏi thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteJobForm
  });
};

export const useResetJobForm = () => {
  useResetState(resetJobFormState);
};
