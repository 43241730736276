import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useResetState, useFetchByParam } from '~/hooks/utils';

import {
  getPharmacyOverViewReport,
  getPharmacyDailyReport,
  getPharmacyMonthlyReport,
  getPharmacyQuarterlyReport,
  getPharmacyYearlyReport,
  getPharmacyMostCustomerOrder,
  getPharmacyOverviewToday,
  getPharmacyOverviewDaily,
  getPharmacyOverviewMonthly,
  getPharmacyOverviewQuarterly,
  getPharmacyOverviewYearly,
  resetPharmacyDashboardState
} from '~/redux/action';

const PHARMACY_DASHBOARD_MODULE = 'pharmacyOnlineDashboard';
const getSelector = (key) => (state) => state[PHARMACY_DASHBOARD_MODULE][key];

const overViewSelector = getSelector('overView');
const overViewLoadingSelector = getSelector('isOverViewLoading');
const getOverViewFailedSelector = getSelector('getOverViewFailed');

const yearlySelector = getSelector('yearly');
const yearlyLoadingSelector = getSelector('isYearlyLoading');
const getYearlyFailedSelector = getSelector('getYearlyFailed');

const quarterlySelector = getSelector('quarterly');
const quarterlyLoadingSelector = getSelector('isQuarterlyLoading');
const getQuarterlyFailedSelector = getSelector('getQuarterlyFailed');

const monthlySelector = getSelector('monthly');
const monthlyLoadingSelector = getSelector('isMonthlyLoading');
const getMonthlyFailedSelector = getSelector('getMonthlyFailed');

const dailySelector = getSelector('daily');
const dailyLoadingSelector = getSelector('isDailyLoading');
const getDailyFailedSelector = getSelector('getDailyFailed');

const mostCustomerOrderSelector = getSelector('mostCustomerOrder');
const mostCustomerOrderLoadingSelector = getSelector('isMostCustomerOrderLoading');
const getMostCustomerOrderFailedSelector = getSelector('getMostCustomerOrderFailed');

const pharmacyOverviewTodaySelector = getSelector('pharmacyOverviewToday');
const pharmacyOverviewTodayLoadingSelector = getSelector('isPharmacyOverviewTodayLoading');
const getPharmacyOverviewTodayFailedSelector = getSelector('getPharmacyOverviewTodayFailed');

const pharmacyOverviewDailySelector = getSelector('pharmacyOverviewDaily');
const pharmacyOverviewDailyLoadingSelector = getSelector('isPharmacyOverviewDailyLoading');
const getPharmacyOverviewDailyFailedSelector = getSelector('getPharmacyOverviewDailyFailed');

const pharmacyOverviewMonthlySelector = getSelector('pharmacyOverviewMonthly');
const pharmacyOverviewMonthlyLoadingSelector = getSelector('isPharmacyOverviewMonthlyLoading');
const getPharmacyOverviewMonthlyFailedSelector = getSelector('getPharmacyOverviewMonthlyFailed');

const pharmacyOverviewQuarterlySelector = getSelector('pharmacyOverviewQuarterly');
const pharmacyOverviewQuarterlyLoadingSelector = getSelector('isPharmacyOverviewQuarterlyLoading');
const getPharmacyOverviewQuarterlyFailedSelector = getSelector('getPharmacyOverviewQuarterlyFailed');

const pharmacyOverviewYearlySelector = getSelector('pharmacyOverviewYearly');
const pharmacyOverviewYearlyLoadingSelector = getSelector('isPharmacyOverviewYearlyLoading');
const getPharmacyOverviewYearlyFailedSelector = getSelector('getPharmacyOverviewYearlyFailed');

const policiesSelector = (state) => state.user.policy;

export const usePharmacyOverView = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getPharmacyOverViewReport,
    loadingSelector: overViewLoadingSelector,
    dataSelector: overViewSelector,
    failedSelector: getOverViewFailedSelector,
    param
  });
};

export const usePharmacyYearly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getPharmacyYearlyReport,
    loadingSelector: yearlyLoadingSelector,
    dataSelector: yearlySelector,
    failedSelector: getYearlyFailedSelector,
    param
  });
};

export const usePharmacyQuarterly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getPharmacyQuarterlyReport,
    loadingSelector: quarterlyLoadingSelector,
    dataSelector: quarterlySelector,
    failedSelector: getQuarterlyFailedSelector,
    param
  });
};

export const usePharmacyMonthly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getPharmacyMonthlyReport,
    loadingSelector: monthlyLoadingSelector,
    dataSelector: monthlySelector,
    failedSelector: getMonthlyFailedSelector,
    param
  });
};

export const usePharmacyDaily = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getPharmacyDailyReport,
    loadingSelector: dailyLoadingSelector,
    dataSelector: dailySelector,
    failedSelector: getDailyFailedSelector,
    param
  });
};

export const usePharmacyMostCustomerOrder = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getPharmacyMostCustomerOrder,
    loadingSelector: mostCustomerOrderLoadingSelector,
    dataSelector: mostCustomerOrderSelector,
    failedSelector: getMostCustomerOrderFailedSelector,
    param
  });
};

export const usePharmacyOverviewToday = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getPharmacyOverviewToday,
    loadingSelector: pharmacyOverviewTodayLoadingSelector,
    dataSelector: pharmacyOverviewTodaySelector,
    failedSelector: getPharmacyOverviewTodayFailedSelector,
    param
  })
}

export const usePharmacyOverviewDaily = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getPharmacyOverviewDaily,
    loadingSelector: pharmacyOverviewDailyLoadingSelector,
    dataSelector: pharmacyOverviewDailySelector,
    failedSelector: getPharmacyOverviewDailyFailedSelector,
    param
  })
}

export const usePharmacyOverviewMonthly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getPharmacyOverviewMonthly,
    loadingSelector: pharmacyOverviewMonthlyLoadingSelector,
    dataSelector: pharmacyOverviewMonthlySelector,
    failedSelector: getPharmacyOverviewMonthlyFailedSelector,
    param
  })
}

export const usePharmacyOverviewQuarterly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getPharmacyOverviewQuarterly,
    loadingSelector: pharmacyOverviewQuarterlyLoadingSelector,
    dataSelector: pharmacyOverviewQuarterlySelector,
    failedSelector: getPharmacyOverviewQuarterlyFailedSelector,
    param
  })
}

export const usePharmacyOverviewYearly = () => {
  const policies = useSelector(policiesSelector);
  const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getPharmacyOverviewYearly,
    loadingSelector: pharmacyOverviewYearlyLoadingSelector,
    dataSelector: pharmacyOverviewYearlySelector,
    failedSelector: getPharmacyOverviewYearlyFailedSelector,
    param
  })
}



export const usePharmacyResetDashboard = () => {
  useResetState(resetPharmacyDashboardState);
};
