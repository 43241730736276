import React, { useEffect, useState } from 'react';

import { get, set, union } from 'lodash';
import {
  Col,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Tabs,
  Button
} from 'antd';

import Api from '~/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ModalPartnerTransaction from './ModalPartnerTransaction';
import { TRANSACTIONS, TRANSFER } from '~/constants/defaultValue';

const { Search } = Input;
const WhPartnerTransaction = ({ parent, size }) => {
  const id = parent._id;
  const [searchBy, setSearchBy] = useState('transactionCode');
  const [keyword, setKeyword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [transaction, setTransaction] = useState([]);

  const [paging, setPaging] = useState();
  const [total, setTotal] = useState(0);

  const getTransactions = async (value) => {
    const query = value ? value : { partnerId: id };
    const res = await Api.Transaction.getTransactionById(query);
    setTransaction(res?.docs);
    setTotal(get(res, 'totalDocs'));
  };
  useEffect(() => {
    getTransactions();
  }, []);

  const onPagingChange = ({ current, pageSize, total }, someObj) => {
    setCurrent(current);
    getTransactions(id, { page: current, limit: pageSize });
  };
//Search 
  const onSearch = (value) => {
    let searchParams = `?page=${paging?.current}&limit=${paging?.limit}`;
    if (keyword) searchParams += `&${searchBy}=${keyword}`;
    const regex = /[0-9.]/g
    let newKeyword = keyword
    if (regex.test(keyword)) {
      newKeyword= newKeyword.replace(/[. ]/g,'')
    }
    const queryParams = {
      partnerId: id,
      page: paging?.current || 1,
      limit: paging?.limit ||10,
      [searchBy]: newKeyword || value,
    }
    getTransactions(queryParams)

  };
  const keyOption = {
    transactionCode: 'mã giao dịch',
    typePartner: 'loại thu chi',
    tag: 'nhãn',
    status: 'trạng thái'
  };

  const testData = [
    {
      transactionCodes: '111',
      typePartner: '1111'
    }
  ];
  //--------------Props of Table--------------
  const columns = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transactionCodes',
      key: 'transactionCodes',
      render: (value) => (
        <Button
          type="link"
          // onClick={() => setIsOpen(true) }
        >
          {value}
        </Button>
      )
    },
    {
      title: 'Thời gian',
      key: 'date',
      dataIndex: 'date',
      render: (value) => moment(value).format('HH:mm DD/MM/YYYY')
    },
    {
      title: 'Số tiền',
      dataIndex: 'amountOfMoney',
      key: 'amountOfMoney'
    },
    {
      title: 'Loại thu chi',
      dataIndex: 'typePartner',
      key: 'typePartner',
      render: (value) => TRANSFER[value]
    },
    {
      title: 'Nhãn',
      dataIndex: 'receiptVoucher',
      key: 'receiptVoucher',
      render: (value, record) =>
        record?.whPaymentVoucher?.reason ??
        record?.whReceiptVoucher?.reason
    },
    {
      title: 'Trạng thái  ',
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        return (
          <span
            className={`partner__transaction-status ${value?.toLowerCase()}`}
          >
            {TRANSACTIONS[value]}
          </span>
        );
      }
    }
  ];
  return (
    <div className="container-fluid">
      {/* <Row>
        <Col sm={12} md={6}>
          <Select
            value={searchBy}
            style={{ width: '100%' }}
            onChange={(val) => {
              setSearchBy(val);
            }}
          >
            <Option value="transactionCode">Mã giao dịch</Option>
            <Option value="typePartner">Loại thu chi</Option>
            <Option value="status">Trạng thái</Option>
          </Select>
        </Col>
        <Col sm={12} md={6}>
          {
            {
              [searchBy]: (
                <Search
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`Tìm theo ${keyOption[searchBy]}`}
                  enterButton
                  onSearch={onSearch}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              )
            }[searchBy]
          }
        </Col>
      </Row> */}
      <Table
        dataSource={transaction}
        columns={columns}
        scroll={{ x: 'auto', y: '1000' }}
        size={size}
        pagination={{
          ...paging,
          showTotal: (total) => `Tổng cộng: ${total} `
        }}
        onChange={onPagingChange}
      />
      <Modal
        visible={isOpen}
        width={1366}
        footer={null}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
      >
        <ModalPartnerTransaction
          onClose={() => setIsOpen(false)}
          onCancel={() => {
            setIsOpen(false);
          }}
          // transaction={transaction}
        />
      </Modal>
    </div>
  );
};
export default WhPartnerTransaction;
