import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getWhPartnerRegisters({ payload: query }) {
    try {
      const response = yield call(Api.whPartnerRegister.getAll, query);
      yield put({ type: Types.GET_WHPARTNERREGISTERS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WHPARTNERREGISTERS_FAILED, payload: error });
    }
  }
function* getWhPartnerRegister({ payload: id }) {
    try {
      const response = yield call(Api.whPartnerRegister.getById, id);
      yield put({ type: Types.GET_WHPARTNERREGISTER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WHPARTNERREGISTER_FAILED, payload: error });
    }
  }
  function* createWhPartnerRegister(action) {
    try {
      const data = yield call(Api.whPartnerRegister.create, action.payload);
      yield put({ type: Types.CREATE_WHPARTNERREGISTER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_WHPARTNERREGISTER_FAILED, payload: error });
    }
  }
  function* updateWhPartnerRegister(action) {
    try {
      const data = yield call(Api.whPartnerRegister.update, action.payload);
      yield put({ type: Types.UPDATE_WHPARTNERREGISTER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_WHPARTNERREGISTER_FAILED, payload: error });
    }
  }
  function* deleteWhPartnerRegister(action) {
    try {
      const data = yield call(Api.whPartnerRegister.delete, action.payload);
      yield put({ type: Types.DELETE_WHPARTNERREGISTER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_WHPARTNERREGISTER_FAILED, payload: error });
    }
  }
export default function* WhPartnerRegisters() {
    yield takeLatest(Types.GET_WHPARTNERREGISTERS_REQUEST, getWhPartnerRegisters);
    yield takeLatest(Types.GET_WHPARTNERREGISTER_REQUEST, getWhPartnerRegister);
    yield takeLatest(Types.CREATE_WHPARTNERREGISTER_REQUEST, createWhPartnerRegister);
    yield takeLatest(Types.UPDATE_WHPARTNERREGISTER_REQUEST, updateWhPartnerRegister);
    yield takeLatest(Types.DELETE_WHPARTNERREGISTER_REQUEST, deleteWhPartnerRegister);
  }
