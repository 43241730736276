import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getSelectors,
    useFailed,
    useFetchByParam,
    useQueryParams,
    useResetState,
    useSubmit,
    useSuccess
} from '~/hooks/utils';
import { createTrainingCourse, deleteTrainingCourse, getTrainingCourse, getTrainingCourses, resetTrainingCourseState, updateTrainingCourse } from '~/redux/action';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.trainingCourse[key];
const pagingSelector = getSelector('paging');
export const useTrainingCoursePaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetTrainingCourseSelector = getSelector('isGetTrainingCourseLoading');
const getTrainingCoursesFailedSelector = getSelector('getTrainingCoursesFailed');
const getTrainingCourseFailedSelector = getSelector('getTrainingCourseFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const trainingCoursesSelector = getSelector('trainingCourses');
const trainingCourseSelector = getSelector('trainingCourse');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useTrainingCourseQueryParams = () => {
    const query = useQueryParams();
    const limit = query.get('limit') || 10;
    const page = query.get('page') || 1;
    const keyword = query.get('keyword')
    const createTrainingCorseSuccess = useSelector(createSuccessSelector);
    const updateTrainingCorseSuccess = useSelector(updateSuccessSelector);
    const deleteTrainingCorseSuccess = useSelector(deleteSuccessSelector);
  
    return useMemo(() => {
      const queryParams = {
        page,
        limit,
        keyword
      };
      return [queryParams];
      //eslint-disable-next-line
    }, [
      page,
      limit,
      createTrainingCorseSuccess,
      updateTrainingCorseSuccess,
      deleteTrainingCorseSuccess,
      keyword
    ]);
  };

  export const useUpdateTrainingCourseParams = (query) => {
    const history = useHistory();
    const [keyword, setKeyword] = useState(get(query,'keyword'));
    useEffect(() => {
      setKeyword(get(query,'keyword'));
    },[query.keyword])
    const onParamChange = (param) => {
      if (!param.page) {
        query.page = 1;
      }
      history.push({
        pathname: get(param, 'customPathName') || '/wh-training-course',
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword ,{setKeyword, onParamChange }];
  };
  export const useTrainingCourses = (query) => {
    return useFetchByParam({
      action: getTrainingCourses,
      loadingSelector,
      dataSelector: trainingCoursesSelector,
      failedSelector: getTrainingCoursesFailedSelector,
      param: query
    });
  };
  export const useTrainingCourse = (id) => {
    return useFetchByParam({
      action: getTrainingCourse,
      loadingSelector:loadinggetTrainingCourseSelector,
      dataSelector: trainingCourseSelector,
      failedSelector: getTrainingCourseFailedSelector,
      param: id
    });
  };
export const useCreateTrainingCourse = (callback) => {
    useSuccess(createSuccessSelector, 'Tạo Khoá đào tạo thành công',callback);
    useFailed(createFailedSelector, 'Tạo Khoá đào tạo thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: createTrainingCourse
    });
  };
export const useUpdateTrainingCourse = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhât Khoá đào tạo thành công',callback);
    useFailed(updateFailedSelector, 'Cập nhật Khoá đào tạo thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateTrainingCourse
    });
  };
export const useDeleteTrainingCourse = (callback) => {
    useSuccess(deleteSuccessSelector, 'Xoá Khoá đào tạo thành công',callback);
    useFailed(deleteFailedSelector);
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: deleteTrainingCourse
    });
  };

  export const useResetTrainingCourse = () => {
    useResetState(resetTrainingCourseState);
  };