export const GET_JOBS_REQUEST =
    'GET_JOBS_REQUEST';
export const GET_JOBS_SUCCESS =
    'GET_JOBS_SUCCESS';
export const GET_JOBS_FAILED = 'GET_JOBS_FAILED';

export const GET_JOB_REQUEST = 'GET_JOB_REQUEST';
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';
export const GET_JOB_FAILED = 'GET_JOB_FAILED';

export const CREATE_JOB_REQUEST =
    'CREATE_JOB_REQUEST';
export const CREATE_JOB_SUCCESS =
    'CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAILED =
    'CREATE_JOB_FAILED';

export const UPDATE_JOB_REQUEST =
    'UPDATE_JOB_REQUEST';
export const UPDATE_JOB_SUCCESS =
    'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAILED =
    'UPDATE_JOB_FAILED';


export const DELETE_JOB_REQUEST =
    'DELETE_JOB_REQUEST';
export const DELETE_JOB_SUCCESS =
    'DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAILED =
    'DELETE_JOB_FAILED';


export const RESET_JOB_STATE = 'RESET_JOB_STATE';
