import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getHospitalsList,
  createHospitalList,
  deleteHospitalList,
  getHospitalList,
  resetHospitalListState,
  updateHospitalList
} from '~/redux/action';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const getSelector = (key) => (state) => state.hospitalList[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useHospitalListPaging = () => useSelector(pagingSelector);

export const useHospitalListQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const name = query.get('name');

  const [page, setPage] = useState(query.get('page') || 1);
  const onTableChange = ({ current }) => setPage(current);

  const deleteHospitalListSuccess = useSelector(deleteSuccessSelector);
  const createHospitalListSuccess = useSelector(createSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined,
      name
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteHospitalListSuccess,name,createHospitalListSuccess]);
};

export const useHospitalsList = (query) => {
  return useFetchByParam({
    action: getHospitalsList,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreateHospitalList = (callback) => {

  useSuccess(createSuccessSelector, 'Tạo mới địa chỉ thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới địa chỉ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createHospitalList
  });
};

export const useUpdateHospitalList = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật địa chỉ thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật địa chỉ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateHospitalList
  });
};

export const useDeleteHospitalList = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá địa chỉ thành công',callback);
  useFailed(deleteFailedSelector, 'Xoá địa chỉ thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteHospitalList
  });
};

export const useHospitalList = (id) => {
  return useFetchByParam({
    action: getHospitalList,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useUpdateHospitalListParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }
    history.push({
      pathname: '/hospital-list',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useInitHospitalList = (hospitalList) =>
  useMemo(() => {
    if (!hospitalList) {
      return {
        image: {}
      };
    }

    return hospitalList;
  }, [hospitalList]);

export const useResetHospitalList = () => {
  useResetState(resetHospitalListState);
};
