import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Select, Row, Col, Table, Button, Modal } from 'antd';
import {
  MAX_PAGINATION_LIMIT,
  STATUS_DELIVERY_PRODUCT_KEY,
  TYPE_DELIVERY_WAREHOUSE_PRODUCT
} from '~/constants/defaultValue';
import { BaseBorderBox, Breadcrumb } from '~/components/Common';
import TabBranch from '~/components/Common/TabBranch';
import { get, head, transform } from 'lodash';
import {
  useDeliveryVoucherPaging,
  useDeliveryVoucherParams,
  useDeliveryVoucherQueryParams,
  useDeliveryVouchers
} from '~/hooks/deliveryVoucher';
import ExpandableDeliveryVoucher from './ExpandableDeliveryVoucher';
import ConfirmCouponProForm from '../ProductList/ConfirmCouponProForm';
import Search from 'antd/lib/input/Search';
import POLICY from '~/components/Policy';

import { useMatchPolicy, useProfile, useUser } from '~/hooks';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import moment from 'moment';
import api from '~/api';
const listOptionsSearch = [
  {
    label: 'Mã phiếu',
    value: 'codeVoucher'
  },
  {
    label: 'Người xử lý phiếu',
    value: 'keyword'
  },
  {
    label: 'Trạng thái',
    value: 'status'
  },
  {
    label: 'Ngày tạo phiếu',
    value: 'createdAt'
  },
  {
    label: 'Kho chuyển',
    value: 'from'
  },
  {
    label: 'Kho nhận',
    value: 'to'
  },

];
export default function DeliveryCoupon() {
  // const headValue = get(head(listoptenSearch), 'value');
  const [form] = Form.useForm();
  const [profile] = useProfile();
  const [branchIdUser] = useUser();
  const [branchIdSearch, setBranchIdSearch] = useState('all');

  /**
   *
   */
  const idBranch = useMemo(() => {
    let branchId = undefined;
    if (branchIdUser === '99999') {
      if (branchIdSearch === 'all') {
        branchId = undefined;
      } else {
        branchId = branchIdSearch;
      }
    } else {
      branchId = branchIdUser;
    }
    return { branchId };
  }, [branchIdSearch, branchIdUser]);

  const [query, onTableChange] = useDeliveryVoucherQueryParams(idBranch);
  const [searchBy, setSearchBy] = useState('codeVoucher');
  const [keyword, { setKeyword, onParamChange }] =
    useDeliveryVoucherParams(query);
  const [listVouchers, isLoading] = useDeliveryVouchers(query);
  const paging = useDeliveryVoucherPaging();
  const [optionsBranch, setOptionsBranch] = useState([]);
  const canReadDeliveryLists = useMatchPolicy(POLICY.READ_PRODUCTDELIVERY);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);

  const [id, setId] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [itemActive, setItemActive] = useState(null);

  const handleModalOpen = (id) => {
    setIsOpenModal(true);
    setId(id);
  };
  const handleCloseFormDeliveryVoucher = () => {
    setIsOpenModal(false);
    setId(null);
  };

  const handleChangeSelectSearch = (value) => {
    console.log(value,'value')
    setSearchBy(value);
    setKeyword('');
  };

  /**
   * Get a list of branches according to warehouse authorization
   */
  const getBranches = async () => {
    setIsLoadingBranches(true);
    const res = await api.deliveryVoucher.getAllBranch();
    const mapRes = res.map((item) => {
      return {
        label: item.name,
        value: item._id
      };
    });
    const optionAll = {
      label: 'Tất cả chi nhánh',
      value: 'all'
    };
    setOptionsBranch([optionAll, ...mapRes]);
    setIsLoadingBranches(false);
  };

  useEffect(() => {
    getBranches();
  }, []);
  const columns = [
    {
      title: 'Mã phiếu luân chuyển',
      dataIndex: 'code',
      key: 'code',
      render: (value, record) => {
        return (
          <Button type="link" onClick={() => handleModalOpen(record._id)}>
            {value}
          </Button>
        );
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (item, record, index) => (
        <span className={`delivery-voucher-status ${item?.toLowerCase()}`}>
          {STATUS_DELIVERY_PRODUCT_KEY[item]?.name}
        </span>
      )
    },
    {
      title: 'Kho chuyển',
      dataIndex: 'from',
      render: (item, record, index) =>
        get(record, 'from.warehouseInfo.name', '')
    },
    {
      title: 'Kho nhận',
      dataIndex: 'index',
      key: 'index',
      render: (item, record, index) => get(record, 'to.warehouseInfo.name', '')
    },
    {
      title: 'Ngày tạo phiếu',
      dataIndex: 'createdAt',
      render: (item, record, index) => moment(item)?.format('YYYY-MM-DD HH:mm')
    },
    {
      title: 'Người tạo phiếu',
      dataIndex: 'createBy',
      render: (item, record, index) => item?.fullName
    },
    {
      title: 'Người xác nhận',
      dataIndex: 'confirmBy',
      render: (item, record, index) => item?.fullName
    },
    {
      title: 'Người nhận thiết bị',
      dataIndex: 'completedBy',
      render: (item, record, index) => item?.fullName
    },
    {
      title: 'Người huỷ ( nếu có )',
      dataIndex: 'cancelBy',
      render: (item, record, index) => item?.fullName
    },
    // {
    //   title: '',
    //   dataIndex: 'index',
    //   key: 'index',
    // },
  ];
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <TabBranch useBoxShadow={false}>
          <Breadcrumb title="Quản lý phiếu luân chuyển" />
          <div
            className="page-wraper__header"
            style={{ justifyContent: 'space-between', display: 'flex' }}
          >
            <Row style={{ width: '100%' }}>
              <Col span={4}>
                <Select
                  value={searchBy}
                  style={{
                    width: '100%'
                  }}
                  options={listOptionsSearch}
                  onChange={handleChangeSelectSearch}
                />
              </Col>
              <Col span={8}>
                {searchBy === 'status' ? (
                  <Select
                  mode="multiple"
                  style={{
                    width: '100%'
                  }}
                  placeholder="Vui lòng chọn trạng thái"
                  optionLabelProp="label"
                  allowClear
                  onChange={(e) => onParamChange({ [searchBy]: e, page: 1 })}
                >
                  {transform(
                    STATUS_DELIVERY_PRODUCT_KEY,
                    (result, value, key) => {
                      result.push({
                        label: get(value, 'name', ''),
                        value: key,
                        color: get(value, 'color', '')
                      });
                    },
                    []
                  )?.map((e) => {
                    return (
                      <Option value={get(e, 'value')} label={get(e, 'label')}>
                        <p style={{ color: `${e.color}` }}>{get(e, 'label')}</p>
                      </Option>
                    );
                  })}
                </Select>
                ) : (
                    <Search
                    style={{
                      width: '100%'
                    }}
                    placeholder={`Tìm ${get(
                      listOptionsSearch.find((e) => e.value === searchBy),
                      'label',
                      ''
                    )?.toLowerCase()}`}
                    enterButton
                    allowClear
                    onSearch={(value) => {
                      onParamChange({[searchBy]: value });
                    }}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                        if (!e.target.value) {
                          onParamChange({ [searchBy]: '' });
                        }
                      }}
                    value={keyword}
                  />
                  )
                }
              </Col>
              {profile?.branchId === 99999 && branchIdUser === '99999' && canReadDeliveryLists ? (
                !isLoadingBranches && (
                <Col span={8}>
                  <Select
                    value={branchIdSearch}
                    style={{
                      width: 270
                    }}
                    options={optionsBranch}
                    onChange={(e) => setBranchIdSearch(e)}
                  />
                </Col>
                )
              ) : null}
            </Row>
          </div>
          {isLoading ? (
            <SkeletonTable columns={columns} rowCount={3} />
          ) : (
            <Table
              scroll={{ x: 'max-content' }}
              rowKey={(rc) => rc._id}
              columns={columns}
              dataSource={listVouchers || []}
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total}`
              }}
                onChange={onTableChange}
              size="middle"
              onExpand={(expanded, record) => {
                expanded ? setItemActive(record._id) : setItemActive(null);
              }}
              expandable={{
                expandedRowRender: (record) => (
                  <ExpandableDeliveryVoucher data={record} />
                ),
                expandedRowKeys: [itemActive]
              }}
            />
          )}
        </TabBranch>
      </div>
      <Modal
        visible={isOpenModal}
        onCancel={handleCloseFormDeliveryVoucher}
        footer={null}
        width={1000}
        destroyOnClose
        title="Phiếu luân chuyển"
      >
        <ConfirmCouponProForm
          id={id}
          handleCloseFormDeliveryVoucher={handleCloseFormDeliveryVoucher}
        />
      </Modal>
    </div>
  );
}
