import { get, omit } from 'lodash';
import { adapterStartAndEndDate } from '~/utils/helper';
import requester from './requester';

const productList = {
  getAll: (query) => requester.get(`product-list`, query),
  getAllOfPartner: (query) => requester.get(`product-list-partner-device`, query),
  getAllHistory: (query) => requester.get(`product-list-history/${query.id}`,query),
  getById: (id) => requester.get(`product-list/${id}?raw=true`),
  create: (device) => requester.post('product-list', device),
  update: (device) => requester.put(`product-list/${device.id}`, device),
  delete: ({id}) => requester.delete(`product-list/${id}`),
  getCalendar: (query) => requester.get(`/calendar-pre-booking`,adapterStartAndEndDate(query)),
  getDeliveryVoucherInfo: (id) => requester.get(`/product-delivery/getByProduct/${id}`),
  updateDeliveryVoucherInfo: ({id, note}) => requester.put(`/product-delivery/cancelProductInVoucher/${id}`, {note: note}),
};

export default productList;
