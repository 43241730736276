import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';
const destructData = (newdata) => ({...newdata,
  ...newdata.snapShotMedical,
  totalPrice:
    parseInt((get(newdata,'snapShotMedical.price',0) *get(newdata,'snapShotMedical.discountPercentage',0) /100 *get(newdata,'times',0)).toFixed()),
  _id:newdata._id})
const initState = {
  isLoading: false,
  getWhReportMedicinesFailed: null,
  getWhReportMedicineFailed: null,
  whReportMedicines: [],
  whReportMedicine: null,
  isSubmitLoading: false,
  isGetWhReportMedicineLoading: false,
  WhReportMedicine: null,

  loadingUpdateCourse: false,
  updateFailedCourse: null,
  updateSuccessCourse: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WH_REPORT_MEDICINES_REQUEST:
      state.isLoading = true;
      state.getWhReportMedicinesFailed = null;
      return;

    case Types.GET_WH_REPORT_MEDICINES_SUCCESS:
      state.isLoading = false;
      state.whReportMedicines = payload?.map((e) => destructData(e));
      state.getWhReportMedicinesFailed = null;
      return;

    case Types.GET_WH_REPORT_MEDICINES_FAILED:
      state.isLoading = false;
      state.whReportMedicines = [];
      state.getWhReportMedicinesFailed = payload;
      return;
    case Types.GET_WH_REPORT_MEDICINE_REQUEST:
      state.isGetWhReportMedicineLoading = true;
      state.whReportMedicine = null;
      state.getWhReportMedicineFailed = null;
      return;

    case Types.GET_WH_REPORT_MEDICINE_SUCCESS:
      state.isGetWhReportMedicineLoading = false;
      state.whReportMedicine = payload;
      state.getWhReportMedicineFailed = null;
      return;

    case Types.GET_WH_REPORT_MEDICINE_FAILED:
      state.isGetWhReportMedicineLoading = false;
      state.whReportMedicine = null;
      state.getWhReportMedicineFailed = payload;
      return;
    case Types.CREATE_WH_REPORT_MEDICINE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_WH_REPORT_MEDICINE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      const newdata = get(payload,'data',{})
      state.whReportMedicines = [destructData(newdata),...state.whReportMedicines,]
      return;

    case Types.CREATE_WH_REPORT_MEDICINE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_WH_REPORT_MEDICINE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_WH_REPORT_MEDICINE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.whReportMedicine = payload;
      state.whReportMedicines = state.whReportMedicines.map(e => {
        if(e._id === get(payload,'data._id')){
          return destructData(get(payload,'data'))
        }
        return e
      });
      return;

    case Types.UPDATE_WH_REPORT_MEDICINE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_WH_REPORT_MEDICINE_REQUEST:
      state.isSubmitLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_WH_REPORT_MEDICINE_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      state.whReportMedicines = state.whReportMedicines.filter(e => e._id !== get(payload,'data._id'));
      return;

    case Types.DELETE_WH_REPORT_MEDICINE_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_STORE:
    case Types.RESET_WH_REPORT_MEDICINE_STATE:
      return initState;

    default:
      return;
  }
}, initState);
