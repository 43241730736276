import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal, Popconfirm } from 'antd';
import React, { useState } from 'react';
import { v4 } from 'uuid';
import { ORDER_QUOTATION_STATUS } from '~/constants/defaultValue';
import { WithPermission, WithPermissionDisabled } from '../Common';
import WhPartnersImportForm from './Form';
import POLICY from '~/constants/policy';


export default function MenuAction({
  updateAction,
  convertAction,
  deleteAction,
  record,
  titleConverter,
  titleDelete,
  copyOrder
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConvertOpen, setIsModalConvertOpen] = useState(false);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteAction();
    setIsModalOpen(false);
  };
  const showModalConvert = () => {
    setIsModalConvertOpen(true);
  };
  const handleOkConvert = () => {
    convertAction();
    setIsModalConvertOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelConvert = () => {
    setIsModalConvertOpen(false);
  };
  const showModalUpdate = () => {
    updateAction();
    setIsModalUpdateOpen(true);
  };
  const handleCancelUpdate = () => {
    setIsModalUpdateOpen(false);
  };
  

  // const disableConvert =
  //   record?.state === ORDER_QUOTATION_STATUS.CONVERTED ||
  //   record?.state === ORDER_QUOTATION_STATUS.CANCELLED;
  // const disableDelete =
  //   record?.state?.includes(ORDER_QUOTATION_STATUS.CREATE) ||
  //   record?.state?.includes(ORDER_QUOTATION_STATUS.PROCESSING);
  const disableConvert = record.partnerId ? true : false;
  const menu = (
    <Menu>
      <WithPermissionDisabled permission={POLICY.UPDATE_POTENTIALPARTNER}>
        <Menu.Item disabled={disableConvert} onClick={showModalConvert}>
          <p>Chuyển đổi</p>
        </Menu.Item>
      </WithPermissionDisabled>
      <WithPermissionDisabled permission={POLICY.UPDATE_POTENTIALPARTNER}>
        <Menu.Item disabled={disableConvert} onClick={showModalUpdate}>
          <p>Cập nhật</p>
        </Menu.Item>
      </WithPermissionDisabled>
      <WithPermissionDisabled permission={POLICY.DELETE_POTENTIALPARTNER}>
        <Menu.Item onClick={showModal}>
          <p>Xoá</p>
        </Menu.Item>
      </WithPermissionDisabled>
    </Menu>
  );
  return (
    <>
      <Modal
        style={{ textAlign: 'center' }}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h5>{titleDelete}</h5>
      </Modal>
      <Modal
        style={{ textAlign: 'center' }}
        visible={isModalConvertOpen}
        onOk={handleOkConvert}
        onCancel={handleCancelConvert}
      >
        <h5>{ titleConverter}</h5>
      </Modal>

      <Dropdown
        overlayStyle={{ width: '140px' }}
        trigger={['click']}
        overlay={menu}
      >
        <a className="ant-dropdown-link" href="#">
          Action <CaretDownOutlined />
        </a>
      </Dropdown>
    </>
  );
}
