import React, { useState, useMemo, useEffect, useContext } from 'react';
import get from 'lodash/get';
import {
  Button,
  Form,
  Input,
  Radio,
  Select,
  Table,
  Col,
  Row,
  TreeSelect,
  Skeleton,
  Modal,
  Spin
} from 'antd';
import {
  useBranch,
  useBranches,
  useMatchPolicy,
  useProfile,
  useSelectBranch
} from '~/hooks';
import { filterAcrossAccents, formatNumberThreeComma } from '~/hooks/utils';
import {
  BaseBorderBox,
  WithOrPermission,
  WithPermission
} from '~/components/Common';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { useGetWareHouseById, useWarehouseLists } from '~/hooks/listWareHouse';
import api from '~/api';
import moment from 'moment';
import { ListProductDelivery } from './ListProductDelivery';
import {
  DeleteOutlined,
  FileWordOutlined,
  SettingOutlined
} from '@ant-design/icons';
import {
  useCreatedeliveryVoucher,
  useDeleteDeliveryVoucher,
  useDeliveryVoucher,
  useResetDeliveryVoucher,
  useUpdatedeliveryVoucher
} from '~/hooks/deliveryVoucher';
import POLICY from '~/constants/policy';
import { useParams } from 'react-router-dom';
import { onPrint } from '~/hooks/print';
import toastr from 'toastr'
import { Link } from 'react-router-dom';
import { PATH_APP } from '~/routes/paths';
import TableRenderWordConfirmDeliveryVoucher from './TableRenderWordConfirmDelivery';
import template from '~/assets/templates/phieu_luan_chuyen.docx'; 

const { Option } = Select;
const { TextArea } = Input;
export default function ConfirmCouponProForm({
  id,
  handleCloseFormDeliveryVoucher
}) {
  const [form] = Form.useForm();
  const [profile] = useProfile();
  const [branchId, setBranchId] = useState(null);
  const { id: idWarehouse } = useParams();
  const [deliveryVoucher, isLoadingGetOne] = useDeliveryVoucher(id); // Get one delivery voucher

  const [branches, setBranches] = useState([]);
  const [isLoadingBranches, setIsLoadingBranches] = useState(false);
  const getBranches = async () => {
    setIsLoadingBranches(true);
    const res = await api.deliveryVoucher.getAllBranch();
    setBranches(res);
    setIsLoadingBranches(false);
  };

  const queryWareHouse = useMemo(
    () => ({
      page: 1,
      limit: MAX_PAGINATION_LIMIT,
      branchIds: branchId,
      status: 'ACTIVE'
    }),
    [branchId]
  );

  const [warehouseLists, isLoadingWareHouse] =
    useWarehouseLists(queryWareHouse);
  const [warehouse, setWarehouse] = useState([]);
  const [wareHouseInfo, isLoadingInfo] = useGetWareHouseById(idWarehouse); // Get list warehouse info of branch
  const [warehouseFrom, setWarehouseIdFrom] = useState(null);
  const [warehouseIdTo, setWarehouseIdTo] = useState(null);
  const [warehouseIdToList, setWarehouseIdToList] = useState([]);
  const [isLoadingWarehouseIdToList, setIsLoadingWarehouseIdToList] =
    useState(false);

  const [isOpenModalSelectDevice, setIsOpenModalSelectDevice] = useState(false);
  const [dataListProductDelivery, setDataListProductDelivery] = useState([]);
  const [isLoadingCreate, createVoucher] = useCreatedeliveryVoucher(
    handleCloseFormDeliveryVoucher
  );
  const [isLoadingUpdate, updateStatusVoucher] = useUpdatedeliveryVoucher(
    handleCloseFormDeliveryVoucher
  );
  const [isLoadingCancel, cancelVoucher] = useDeleteDeliveryVoucher(
    handleCloseFormDeliveryVoucher
  );
  const isAdmin = useMatchPolicy(POLICY.ADMIN_PRODUCTDELIVERY);
  const canWrite = useMatchPolicy(POLICY.WRITE_PRODUCTDELIVERY);
  const canRead = useMatchPolicy(POLICY.READ_PRODUCTDELIVERY);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_PRODUCTDELIVERY);
  const canDelete = useMatchPolicy(POLICY.DELETE_PRODUCTDELIVERY);

  const [button, setButton] = useState('');
  const [statusSend, setStatusSend] = useState(null);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isShowButtonCancel, setIsShowButtonCancel] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [isDisableButtonSubmit, setIsDisableButtonSubmit] = useState(false);
  const [isDisableButtonCancel, setIsDisableButtonCancel] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isSubmitCancel, setIsSubmitCancel] = useState(false);
  const [requiredNote, setRequiredNote] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  useResetDeliveryVoucher();
  /**
   * Checks if the user has all the necessary permissions.
   *
   * @return {boolean} Returns true if the user has all the necessary permissions, otherwise false.
   */

  const filterOptionWareHouse = warehouse?.map((item) => ({
    label: item.name,
    value: item._id
  }));
  const filterWarehouseTo = warehouseIdToList?.filter(
    (item) => item.value !== form.getFieldValue(['from', 'warehouseId'])
  );

  const handlePrint = async () => {
    const checkAddress = (address) => {
      if (typeof address === 'string') {
        return address
      };
      if (typeof address === 'object') {
        const street = address?.street;
        const ward = address?.ward;
        const district = address?.district;
        const city = address?.city;
        const addressString = [street, ward, district, city].filter(item => item).join(', ');
        return addressString
      };
    };
    const { from, to, note, code, createBy, createdAt, completedBy } = deliveryVoucher;
    const listProduct = deliveryVoucher?.deliveryVoucher;
    const nameFile = `PhieuLuanChuyen_${code}`;
    const dataSource = {
      createByName: createBy?.fullName,
      warehouseFrom: from?.warehouseInfo?.name,
      addressWarehouseFrom: checkAddress(from?.warehouseInfo?.address),
      DateDD: moment(createdAt)?.format('DD'),
      DateMM: moment(createdAt)?.format('MM'),
      DateYYYY: moment(createdAt)?.format('YYYY'),
      code: code,
      confirmByName: get(completedBy, 'fullName', ''),
      warehouseTo: to?.warehouseInfo?.name,
      note: note,
      addressWarehouseTo: checkAddress(to?.warehouseInfo?.address),
      table: `<meta charset="UTF-8">
              <body>
             ${TableRenderWordConfirmDeliveryVoucher(listProduct)}
             </body>`,
    };
    onPrint({ nameFile, dataSource, template, setIsPrinting })
  };
  const getMoreWarehouseTo = async () => {
    setIsLoadingWarehouseIdToList(true);
    const res = await api.listWareHouse.getAll({
      limit: MAX_PAGINATION_LIMIT,
      page: 1,
      branchIds: branchId,
      getMore: true
    });
    const filter = res?.docs?.map((item) => ({
      label: item.name,
      value: item._id
    }));
    setWarehouseIdToList(filter);
    setIsLoadingWarehouseIdToList(false);
  };
  const handleDelete = (record) => {
    const newData = [...dataListProductDelivery];
    const filterData = newData?.filter((item) => item._id !== record._id);
    setDataListProductDelivery([...filterData]);
  };

  const handleCancelVoucher = (data) => {
    cancelVoucher(data);
  };
  /**
   * Checks if the user has all the necessary permissions when ID is exits
   * @param {Object} warehouseFrom
   * @param {Object} warehouseTo
   * @constant {Array} warehouseControl
   * NEW: trạng thái phiếu mới tạo
   * IN_PROGRESS:  phiếu đã được duyệt
   * COMPLETED: phiếu đã được bên nhận xác nhận
   * CANCEL:   huỷ
   */

  const checkPermissionIsExitsId = (warehouseFrom, warehouseTo) => {
    const isSuperAdmin = profile?.isSuperAdmin;
    let button = String;
    let isAccept = Boolean;
    let statusSend = String;
    let isCanCancel = false;
    const warehouseControl = profile?.warehouseControl;
    const isHavePermissionWarehouseFrom =
      warehouseControl[0] === 'admin'
        ? true
        : warehouseControl.includes(warehouseFrom); // Check whether the login account has the right to manage transfer tickets and the right to manage the depository
    const isHavePermissionWarehouseTo =
      warehouseControl[0] === 'admin'
        ? true
        : warehouseControl.includes(warehouseTo); //Check whether the login account has the right to manage transfer tickets and the right to manage receiving warehouses
    const isAdminInFrom = isAdmin && isHavePermissionWarehouseFrom;
    const isAdminInTo = isAdmin && isHavePermissionWarehouseTo;
    const status = get(deliveryVoucher, 'status');
    const isUserFrom =
      warehouseControl[0] === 'admin'
        ? true
        : warehouseControl.includes(warehouseFrom);
    const isUserTo =
      warehouseControl[0] === 'admin'
        ? true
        : warehouseControl.includes(warehouseTo);
    isCanCancel = !!warehouseControl.includes(warehouseFrom);

    /**
     * Processed according to the authorization of the logged in user group
     */
    switch (status) {
      case 'NEW': // If delivery voucher have status is NEW
        isAccept = true;
        statusSend = 'IN_PROGRESS';
        switch (true) {
          case isSuperAdmin:
            setIsDisableButtonSubmit(false);
            setIsShowButtonCancel(true);
            button = 'Duyệt';
            break;
          case isAdminInFrom:
            setIsDisableButtonSubmit(false);
            setIsShowButtonCancel(false);
            button = 'Duyệt';
            break;
          case isUserFrom:
            setIsShowButtonCancel(isCanCancel);
            setIsDisableButtonSubmit(true);
            button = 'Chờ duyệt';
            break;
          case isUserTo:
            setIsShowButtonCancel(false);
            setIsDisableButtonSubmit(true);
            button = 'Chờ duyệt';
            break;
          default:
            break;
        }
        break;
      case 'IN_PROGRESS':
        button = 'Nhập kho';
        isAccept = true;
        statusSend = 'COMPLETED';
        switch (true) {
          case isSuperAdmin:
            setIsShowButtonCancel(false);
            setIsDisableButtonSubmit(false);
            button = 'Nhập kho';
            break;
          case isAdminInTo:
            setIsShowButtonCancel(false);
            setIsDisableButtonSubmit(false);
            button = 'Nhập kho';
            break;
          case isUserFrom:
            setIsShowButtonCancel(false);
            setIsDisableButtonSubmit(true);
            button = 'Chờ nhập kho';
            break;
          case isUserTo:
            setIsShowButtonCancel(false);
            button = 'Nhập kho';
            if (canRead && !canUpdate) {
              setIsDisableButtonSubmit(true);
            } else if (canUpdate) {
              setIsDisableButtonSubmit(false);
            }
            break;
          default:
            break;
        }
        break;
      case 'CANCEL':
        button = 'Đã huỷ';
        setIsDisableButtonSubmit(true);
        setIsDisableButtonCancel(true);
        setIsShowButtonCancel(false);
        break;
      case 'COMPLETED':
        button = 'Đã hoàn thành';
        setIsDisableButtonSubmit(true);
        setIsShowButtonCancel(false);
        break;
      default:
        break;
    }
    return {
      button,
      isAccept,
      statusSend,
      isCanCancel
    };
  };

  /**
   * Checks if the user has all the necessary permissions when ID is non-exits
   * @@param {Object} warehouseFrom
   * @param {Object} warehouseTo
   * @constant {Array} warehouseControl
   */
  const checkPermissionIsNonExitsId = (warehouseFrom) => {
    const isSuperAdmin = profile?.isSuperAdmin;
    const warehouseControl = profile?.warehouseControl;
    const isHavePermissionWarehouse =
      warehouseControl[0] === 'admin'
        ? true
        : warehouseControl.includes(warehouseFrom);

    let button = String;
    let isAccept = Boolean;
    let status = String;
    if (isSuperAdmin || (isAdmin && !!isHavePermissionWarehouse)) {
      button = 'Xác nhận chuyển';
      isAccept = true;
      status = 'IN_PROGRESS';
    } else if (canWrite) {
      button = 'Gửi yêu cầu luân chuyển';
      isAccept = false;
      status = 'NEW';
    }
    return {
      button,
      isAccept,
      status
    };
  };

  useEffect(() => {
    getBranches();
  }, []);
  useEffect(() => {
    if (id && deliveryVoucher) {
      /**
       * Set initial value
       */
      form.setFieldsValue({
        ...deliveryVoucher,
        from: {
          branchId: deliveryVoucher?.from?.warehouseInfo?.branchId,
          warehouseId: deliveryVoucher?.from?.warehouseId
        },
        createdAt: moment(deliveryVoucher?.createdAt)?.format(
          'YYYY-MM-DD HH:mm:ss'
        )
      });
      const filterWarehouse = deliveryVoucher?.deliveryVoucher?.map((item) => ({
        name: item?.product?.name,
        _id: item?.product?._id,
        code: item?.product?.code,
        valueDepreciation: item?.product?.valueDepreciation,
        status: item?.status,
        note: item?.note
      })); //

      setDataListProductDelivery(() =>
        filterWarehouse.length ? filterWarehouse : []
      );
      const warehouseFrom = deliveryVoucher?.from?.warehouseInfo;
      const warehouseTo = deliveryVoucher?.to?.warehouseInfo;
      setWarehouse([warehouseFrom, warehouseTo]); //Set list warehouse

      //  Check permission and show button
      const { button, isAccept, statusSend, isCanCancel } =
        checkPermissionIsExitsId(warehouseFrom?._id, warehouseTo?._id);
      setButton(button);
      setStatusSend(statusSend);
      setIsAccept(isAccept);
      setIsDisable(true); //
      if (!warehouseIdToList?.length) {
        const warehouseToInfo = {
          label: deliveryVoucher?.to?.warehouseInfo?.name,
          value: deliveryVoucher?.to?.warehouseInfo?._id
        };
        setWarehouseIdToList([warehouseToInfo]);
      }

      //Set value field createBy, confirmBy, completeBy
      form.setFieldsValue({
        createBy: get(deliveryVoucher, 'createBy.fullName'),
        confirmBy: get(deliveryVoucher, 'confirmBy.fullName'),
        completeBy: get(deliveryVoucher, 'completedBy.fullName')
      });
    } else {
      const branchId = get(wareHouseInfo, 'branchId');
      setWarehouse(warehouseLists); //Set list warehouse
      setBranchId(branchId);
      const warehouseId = get(wareHouseInfo, '_id');
      setWarehouseIdFrom(warehouseId);
      form.setFieldsValue({
        createBy: get(profile, 'fullName')
      });
      const warehouseControl = profile?.warehouseControl;
      const isHavePermissionWarehouse =
        warehouseControl[0] === 'admin'
          ? true
          : warehouseControl.includes(warehouseFrom);
      if (profile?.isSuperAdmin || (isAdmin && isHavePermissionWarehouse)) {
        form.setFieldsValue({
          confirmBy: get(profile, 'fullName')
        });
      }

      /**
       * Set initial value
       */
      form.setFieldsValue({
        from: {
          branchId,
          warehouseId
        },
        createdAt: moment(new Date()).format('YYYY-MM-DD HH:mm')
      }); //

      //  Check permission and show button
      const { button, isAccept, status } =
        checkPermissionIsNonExitsId(warehouseId);
      setButton(button);
      setIsAccept(isAccept);
      setStatusSend(status); //
    }
  }, [deliveryVoucher, id, wareHouseInfo, warehouseLists]);

  const onValuesChange = ({ from, to }) => {
    if (from) {
      if (from?.branchId) {
        form.setFieldsValue({
          from: { warehouseId: null },
          to: { warehouseId: null }
        });
      }
      if (from?.warehouseId) {
        const isExits = from?.warehouseId === warehouseIdTo;
        isExits ? form.setFieldsValue({ to: { warehouseId: null } }) : null;
      }
    }
  };

  const handleActionCancelVoucher = () => {
    const values = form.getFieldsValue();
    const {
      from,
      to,
      codeSequence,
      dateUpdate,
      createBy,
      completeBy,
      confirmBy,
      ...rest
    } = values;
    const productsIds = dataListProductDelivery?.map((item) => {
      const findIndex = item?.code?.indexOf('.');
      const codeSequence = item?.code?.slice(findIndex + 1);
      return {
        _id: item?._id,
        code: item?.code,
        codeSequence: Number(codeSequence)
      };
    });
    const data = {
      ...rest,
      productIds: productsIds,
      from: {
        status: 'ACCEPT',
        warehouseId: from?.warehouseId
      },
      to: {
        status: 'ACCEPT',
        warehouseId: to.warehouseId
      }
    };
    if (values?.note) {
      handleCancelVoucher({ id, ...data, status: 'CANCEL' }); //is submit cancel _ status is CANCEL
    } else {
      toastr.error('Vui lòng nhập ghi chú trước khi huỷ phiếu này !')
    };
  };

  const onFinish = (values) => {
    const {
      from,
      to,
      codeSequence,
      dateUpdate,
      createBy,
      completeBy,
      confirmBy,
      ...rest
    } = values;
    const productsIds = dataListProductDelivery?.map((item) => {
      const findIndex = item?.code?.indexOf('.');
      const codeSequence = item?.code?.slice(findIndex + 1);
      return {
        _id: item?._id,
        code: item?.code,
        codeSequence: Number(codeSequence)
      };
    });
    const data = {
      ...rest,
      productIds: productsIds,
      from: {
        status: 'ACCEPT',
        warehouseId: from?.warehouseId
      },
      to: {
        status: 'ACCEPT',
        warehouseId: to.warehouseId
      }
    };
    if (id) {
        updateStatusVoucher({ id, ...data, status: statusSend });
    } else {
      const newData = {
        ...data,
        isAccept: isAccept,
        status: statusSend
      };
      createVoucher(newData); // isCreate
    }
    // isSuccess && handleCloseFormDeliveryVoucher();
  };

  /**
   *  Set value when open form
   * @param {Object} deliveryVoucher
   * @param {String} id
   * @constant {String} warehouseFrom
   * @constant {String} warehouseTo
   * @constant {String} button
   * @constant {Boolean} isAccept
   * @constant {String} statusSend
   * @constant {Boolean} isCanCancel
   *
   */

  const colums = [
    {
      title: 'STT',
      align: 'start',
      dataIndex: 'stt',
      width: '10px',
      render: (value, record, index) => <p>{index + 1}</p>
    },
    {
      title: 'Mã sản phẩm',
      align: 'start',
      width: '120px',
      dataIndex: 'code',
      key: 'code',
      render: (value) => <Link target='_blank' to={`${PATH_APP.productList.root}?page=1&limit=10&code=${value}`}>{value}</Link>
    },
    {
      title: 'Tên sản phẩm',
      align: 'center',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Giá khấu hao',
      align: 'start',
      width: '100px',
      dataIndex: 'valueDepreciation',
      key: 'valueDepreciation',
      render: (value) => formatNumberThreeComma(value)
    },
    deliveryVoucher
      ? {
          title: 'Ghi chú',
          align: 'center',
          dataIndex: 'note',
          key: 'note',
          width: '200px',
          render: (value, record) => {
            let render = String;
            if (record?.status === 'CANCEL') {
              render = `Thiết bị đã bị rút ra khỏi phiếu với lý do: ${value ? value : 'không tồn tại lý do'}`;
            }
            return render;
          }
        }
      : [],
    {
      title: '',
      align: 'start',
      dataIndex: 'delete',
      render: (value, record) =>
        !isDisable ? (
          <DeleteOutlined
            style={{ color: 'red' }}
            onClick={() => {
              handleDelete(record);
            }}
          />
        ) : (
          ''
        )
    }
  ];

  const renderLoading = (component) =>
    isLoadingGetOne ? <Skeleton.Input active /> : component;
  return (
    <div>
      <Form
        labelCol={{ sm: 24, md: 24, lg: 6 }}
        wrapperCol={{ sm: 24, md: 24, lg: 18 }}
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        layout="horizontal"
        requiredMark={false}
      >
        <BaseBorderBox>
          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <h4>Thông tin chung</h4>
              <Form.Item
                label="Tên chi nhánh"
                name={['from', 'branchId']}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn chi nhánh'
                  }
                ]}
              >
                {branches?.length === 0 ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    disabled={true}
                    showSearch
                    autoComplete="off"
                    // filterOption={filterAcrossAccents}
                    onChange={(value) => {
                      setBranchId(value);
                    }}
                  >
                    {branches?.map((item) => (
                      <Option key={item?._id} value={item._id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label="Tên kho sở hữu"
                name={['from', 'warehouseId']}
                // disabled={form.getFieldValue(['from', 'branchId']) && !warehouse}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn kho sở hữu'
                  }
                ]}
              >
                {warehouse.length === 0 ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    disabled={true}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                    onChange={(e) => setWarehouseIdFrom(e)}
                  >
                    {isLoadingWareHouse ? (
                      <Spin />
                    ) : (
                      filterOptionWareHouse?.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))
                    )}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Người tạo" name="createBy">
                {renderLoading(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <h4>Chứng từ</h4>
              <Form.Item label="Mã phiếu xuất" name="codeSequence">
                {renderLoading(<Input disabled />)}
              </Form.Item>

              <Form.Item label="Ngày tạo phiếu" name="createdAt">
                {renderLoading(<Input disabled />)}
              </Form.Item>

              <Form.Item label="Người xác nhận" name="confirmBy">
                {renderLoading(<Input disabled />)}
              </Form.Item>
            </Col>
          </Row>
        </BaseBorderBox>
        <BaseBorderBox>
          <Row guter={48}>
            <Col span={24}>
              <Form.Item
                label="Ghi chú"
                name="note"
                rules={[{
                  required: requiredNote,
                  message:'Vui lòng nhập lý do huỷ'
                }]}
              >
                {renderLoading(
                  <TextArea
                  disabled={!deliveryVoucher ? false : ((deliveryVoucher?.status === 'CANCEL' || deliveryVoucher?.status === 'COMPLETED') ? true : false)}
                  rows={4} />)}
              </Form.Item>
            </Col>
          </Row>
        </BaseBorderBox>
        <BaseBorderBox>
          <Row>
            <Col span={20}>
              <h3>Thông tin tài sản luân chuyển</h3>
            </Col>
            <Col span={4}>
              {renderLoading(
                !isDisable && (
                  <Button
                    type="dashed"
                    disabled={!form.getFieldValue(['from', 'warehouseId'])}
                    onClick={() => setIsOpenModalSelectDevice(true)}
                  >
                    Chọn tài sản
                  </Button>
                )
              )}
            </Col>
          </Row>
          <Row style={{ width: '100%' }} align="middle" justify="space-between">
            <Col span={24}>
              {/* <BaseBorderBox> */}
              {renderLoading(
                <Table
                  style={{ width: '100%' }}
                  columns={colums}
                  pagination={false}
                  size="middle"
                  dataSource={dataListProductDelivery}
                />
              )}
              {/* </BaseBorderBox> */}
            </Col>
          </Row>
        </BaseBorderBox>
        <BaseBorderBox>
          <h3>Chọn kho nhận</h3>
          <Row>
            <Col span={12}>
              <Form.Item
                labelCol={{ sm: 24, md: 24, lg: 8 }}
                wrapperCol={{ sm: 24, md: 24, lg: 15 }}
                name={['to', 'warehouseId']}
                label="Kho nhận sản phẩm"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn kho nhận'
                  }
                ]}
              >
                {warehouse?.length === 0 ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    disabled={isDisable}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                    onChange={(e) => {
                      setWarehouseIdTo(e);
                    }}
                    onMouseDown={() => getMoreWarehouseTo()}
                    loading={isLoadingWarehouseIdToList}
                    options={filterWarehouseTo}
                  >
                    {/* {isLoadingWarehouseIdToList ? (
                        <Spin/>
                      ):(
                        filterWarehouseTo?.map((item) => (
                          <Option key={item.value} value={item.value}>{item.label}</Option>
                        ))
                      )} */}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Người nhập kho"
                name="completeBy"
                labelCol={{ sm: 24, md: 24, lg: 8 }}
                wrapperCol={{ sm: 24, md: 24, lg: 16 }}
              >
                {renderLoading(<Input disabled />)}
              </Form.Item>
            </Col>
          </Row>
        </BaseBorderBox>
        {!isLoadingGetOne && (
          <Row style={{ marginTop: 20, width: '100%' }} align="center">
            {/* <WithOrPermission permission= {permission || [false]}> */}
            {
              <Col span={6} align="end">
                <Button
                  loading={id ? isLoadingUpdate : isLoadingCreate}
                  disabled={
                    !id
                      ? !(dataListProductDelivery?.length > 0)
                      : isDisableButtonSubmit
                  }
                  htmlType="submit"
                  type="primary"
                >
                  {button}
                </Button>
              </Col>
            }
            {/* </WithOrPermission> */}
            {isShowButtonCancel && (
              <Col span={4} align="center">
                <Button
                  loading={isLoadingCancel}
                  disabled={isDisableButtonCancel}
                  onClick={() => {
                    handleActionCancelVoucher()
                  }}
                  type="primary"
                >
                  Huỷ phiếu
                </Button>
              </Col>
            )}
            {id && <WithPermission permission={POLICY.DOWNLOAD_PRODUCTDELIVERY}>
          <Col span={4}  align="center">
            <div className='buttonSaveFile'>
                  <Button
                  icon={<FileWordOutlined />}
                  loading={isPrinting}
                  onClick={handlePrint}
                >
                  Tải về file Docx
                </Button>
              </div>
            </Col>
          </WithPermission>
          }
          </Row>
        )}
      </Form>
      <Modal
        destroyOnClose
        title="Danh sách sản phẩm"
        visible={isOpenModalSelectDevice}
        onCancel={() => {
          setIsOpenModalSelectDevice(false);
        }}
        footer={null}
        width={700}
      >
        <ListProductDelivery
          warehouseId={warehouseFrom}
          setDataListProductDelivery={setDataListProductDelivery}
          setIsOpenModalSelectDevice={setIsOpenModalSelectDevice}
          dataListProductDelivery={dataListProductDelivery}
        />
      </Modal>
    </div>
  );
}
