import React from 'react';

import { Space } from 'antd';
import { get } from 'lodash';

import { useUserWorkspace } from '~/hooks';

import { COMPONENT_MODES } from '~/constants/defaultValue';

import WhPartnerBillItemCardCompact from './WhBillItem/WhPartnerBillItemCardCompact';
import WhPartnerBillItemCardsList from './WhBillItem/WhPartnerBillItemCardsList';
import WhPartnerMiniForm from './WhPartner/WhPartnerMiniForm';

const WorkspaceAllTasks = ({
}) => {
  const [profile] = useUserWorkspace();
  const id = get(profile, "_id");

  return (
    <div className='page-wraper page-content page-content-workSpace'>
      <Space direction='vertical' style={{ width: "100%" }} size="middle">
        <WhPartnerMiniForm
          whPartnerId={id}
          showAssignedServicesTable={true}
          showWorkingDaysTable={false}
          mode={COMPONENT_MODES.VIEW}
        />

        <WhPartnerBillItemCardsList
          whPartnerId={id}
          status={null}
          itemComponent={WhPartnerBillItemCardCompact}
        />
      </Space>
    </div>
  )
}

export default WorkspaceAllTasks;
