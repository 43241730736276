import { InputNumber, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import React from 'react'
import { formatNumberThreeComma } from '~/hooks/utils';


export default function SearchCustom({ listOptionSearch, selectSearch, onParamChange, setKeyword, keyword }) {
    const SearchInput = () => <Search
        style={{ width: 300 }}
        placeholder={`Tìm ${listOptionSearch.find((e) => e.value === selectSearch).label}`}
        enterButton
        allowClear
        onSearch={(value) => {
            onParamChange({ [selectSearch]: value?.trim() });
        }}
        // onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
    />
    const SearchSelect = ({ options }) => {
        return <Select 
        allowClear
        value={keyword} 
        style={{ width: 300 }}
         options={options} 
         onChange={(value) => {
        onParamChange({ [selectSearch]: value?.trim() });
        }} />
    }
    const SearchNumber = () => <InputNumber
        style={{ width: 300 }}
        value={keyword}
        onPressEnter={(e) => {
            onParamChange({ [selectSearch]: e.target.value?.replaceAll(",", '')?.trim() });
        }}
        formatter={value => formatNumberThreeComma(value)}
    />
    const componentsCheck = () => {
        const findSelectSearch = listOptionSearch?.find(e => e.value === selectSearch)
        switch (findSelectSearch.component) {
            case 'Input':
                return <SearchInput />
            case 'Select':
                return <SearchSelect options={findSelectSearch.optionsInput} />
            case 'InputNumber':
                return <SearchNumber />
            default:
                return <SearchInput />
        }
    }
    return (
        componentsCheck()
    )
}
