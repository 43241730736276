import requester from './requester';

const whPartnerImport = {
  getAll: (query) => requester.get('/potential-partner/allpartner', query),
  getById: (id) => requester.get(`/potential-customer/partner/${id}?raw=true`),
  delete: (partnerId) =>
    requester.delete(`/potential-customer/delete-partner/${partnerId}`),
  create: (whPartnerImport) =>
    requester.post(`/potential-customer-dashboard/partner`, whPartnerImport),
    update: (partnerId) =>
    requester.put(`/update-potential-partner/${partnerId.id}`, partnerId),
  convert: (partnerId) =>
    requester.post(`/potential-customer/convert-to-partner/${partnerId}`)
};
export default whPartnerImport;
