import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  useFailed,
  useFetch,
  useFetchByParam, useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  getSettingTutorialJobs,
  resetSettingTutorialJobState,
  updateSettingTutorialJob
} from '~/redux/action';
const getSelector = (key) => (state) => state.SettingTutorialJob[key];

const loadingSelector = getSelector('isLoading');
const getSettingTutorialJobsFailedSelector = getSelector('getSettingTutorialJobsFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const SettingTutorialJobsSelector = getSelector('SettingTutorialJobs');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
export const useSettingTutorialJobQueryParams = () => {
    const updateSettingTutorialJobSuccess = useSelector(updateSuccessSelector);

    return useMemo(() => {
        const queryParams = {
        };
        return [queryParams];
        //eslint-disable-next-line
    }, [
        updateSettingTutorialJobSuccess,
    ]);
};


export const useSettingTutorialJobs = (param) => {
    return useFetchByParam({
        action: getSettingTutorialJobs,
        loadingSelector,
        dataSelector: SettingTutorialJobsSelector,
        failedSelector: getSettingTutorialJobsFailedSelector,
        param ,
        
    });
};

export const useUpdateSettingTutorialJob = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhât thành công', callback);
    useFailed(updateFailedSelector);

    return useSubmit({
        loadingSelector: isSubmitLoadingSelector,
        action: updateSettingTutorialJob
    });
};

export const useResetSettingTutorialJob = () => {
    useResetState(resetSettingTutorialJobState);
};
