import { forIn, get, groupBy, keys } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getSelectors,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createRanking,
  deleteRanking,
  getRanking,
  getRankings,
  resetRankingState,
  resetRankingStateAction,
  updateRanking
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.ranking[key];
const pagingSelector = getSelector('paging');
export const useRankingPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetRankingSelector = getSelector('isGetRankingLoading');
const getRankingsFailedSelector = getSelector('getRankingsFailed');
const getRankingFailedSelector = getSelector('getRankingFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const RankingsSelector = getSelector('Rankings');
const RankingSelector = getSelector('Ranking');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useRankingQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const name = query.get('name');
  const point = query.get('point');
  const percentDiscount = query.get('percentDiscount');
  const sortPoint = query.get('sortPoint');
  const sortPercentDiscount = query.get('sortPercentDiscount');

  const keyword = query.get('keyword');
  const createRankingSuccess = useSelector(createSuccessSelector);
  const updateRankingSuccess = useSelector(updateSuccessSelector);
  const deleteRankingSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      name,
      point,
      percentDiscount,
      sortPoint,
      sortPercentDiscount
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    createRankingSuccess,
    updateRankingSuccess,
    deleteRankingSuccess,
    keyword,
    name,
    point,
    percentDiscount,
    sortPoint,
    sortPercentDiscount
  ]);
};

export const useUpdateRankingParams = (query,listOptionSearch) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    const groupByKey = groupBy(listOptionSearch , (e) => get(e,'value'))
      forIn(query,(values,key,obj) =>{
        if(groupByKey[key] && (keys(param)?.some(e => groupByKey[e]))){
          obj[key] = null
        }
      })
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || PATH_APP.referral.Ranking,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};
export const useRankings = (query) => {
  return useFetchByParam({
    action: getRankings,
    loadingSelector,
    dataSelector: RankingsSelector,
    failedSelector: getRankingsFailedSelector,
    param: query
  });
};
export const useRanking = (id) => {
  return useFetchByParam({
    action: getRanking,
    loadingSelector: loadinggetRankingSelector,
    dataSelector: RankingSelector,
    failedSelector: getRankingFailedSelector,
    param: id
  });
};
export const useCreateRanking = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo Phân loại thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createRanking
  });
};
export const useUpdateRanking = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật Phân loại thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateRanking
  });
};
export const useDeleteRanking = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Phân loại thành công', callback);
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteRanking
  });
};

export const useResetRanking = () => {
  useResetState(resetRankingState);
};

export const useResetRankingAction = () => {
  useResetState(resetRankingStateAction);
};
