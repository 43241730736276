import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import api from '~/api';
import { useResetNoteWhCustomerManagement, useUpdateNoteWhCustomerManagement } from '~/hooks';
import toastr from 'toastr';

export default function NoteCustomer({id,canUpdate}) {
  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  useResetNoteWhCustomerManagement();
  const getNote = async () => {
    try {
      setLoading(true)
      const res = await api.whCustomerManagement.getNote({ id });
      setItems(res?.note);
      setLoading(false);
    } catch (error) {
      toastr.error('Lấy ghi chú người dùng thất bại');
      setLoading(false);
    };
  };
  const [isLoading, updateNote] = useUpdateNoteWhCustomerManagement(getNote);

  const handleSubmit = () => {
    const body = {
      id: id,
      note: items.length > 0 ? [...items] : []
    };
    updateNote(body);
  }
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleEnterPress = () => {
    if (inputValue.trim() !== '') {
      setItems([inputValue,...items]);
      setInputValue('');
    }
  };
  const handleDelete = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  useEffect(() => {
    id && getNote();
  }, [id]);

  const ulItem = useMemo(() => {
    return items?.map((item, index) => {
      return (
        <li style={{ color: 'rgba(0,0,0,0.85)' }} key={index}>
          {item}
          {
            canUpdate && <span
            className='note-customer__delete'
            onClick={() => {
              handleDelete(index);
            }}
          >X</span>
          }
        </li>
      );
    });
  }, [items]);
  return (
  <div className='note-customer'>
      {isLoading
        ? <Spin style={{ width: '200px' }}/> :
        <>
          {
          canUpdate && <Input
            allowClear
          className='note-customer__input'
          value={inputValue}
          onChange={handleInputChange}
          onPressEnter={handleEnterPress}
          placeholder="Nhập ghi chú cho khách hàng"
        />
          }
          <ul className='note-customer__list'>{
            loading? <Spin style={{ width: '200px' }}/> :
            items?.length > 0 ? ulItem : <p style={{marginTop: '10px'}}>Không tồn tại ghi chú nào</p>}
          </ul>
          {
          canUpdate && <Button loading={isLoading} className='note-customer__btn' type='primary' onClick={handleSubmit}>Gửi</Button>}
        </>
      }
    </div>
  );
};