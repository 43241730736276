import { useEffect, useMemo } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors
} from '~/hooks/utils';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ACTIONS } from '~/constants/policy';
import { get, isNil, last } from 'lodash';
import { StringToSlug, removeAccents } from '~/utils/helper';
import {
  createGroupCategory,
  deleteGroupCategory,
  getGroupCategories,
  getGroupCategory,
  getPermission,
  resetGroupCategoryState,
  updateGroupCategory,
  updateGroupCategoryRedux,
  updatePermission,
  updatePermissionRedux
} from '~/redux/action';

const STORE_MODULE = 'groupCategory';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(STORE_MODULE);

const getSelector = (key) => (state) => state[STORE_MODULE][key];

const getResourcesLoadingSelector = getSelector('isGetResourcesLoading');
const resourcesSelector = getSelector('resources');
const getResourcesFailedSelector = getSelector('getResourcesFailed');

const isUpdatePermissionLoadingSelector = getSelector(
  'isUpdatePermissionLoading'
);
const actionsSelector = getSelector('actions');

export const usePermission = () => {
  const { id: branchId } = useParams();

  const branchParam = useMemo(() => ({ branchId }), [branchId]);
  return useFetchByParam({
    action: getPermission,
    loadingSelector: getResourcesLoadingSelector,
    dataSelector: resourcesSelector,
    failedSelector: getResourcesFailedSelector,
    param: branchParam,
    actionUpdate : updatePermissionRedux,
  });
};

export const usePermissionColumns = (renderPermission) => {
  const actions = useSelector(actionsSelector);
  const actionColumns = actions.map(({ name, key }, index) => ({
    title: name,
    dataIndex: key,
    key: key,
    width: '13%',
    align: 'center',
    render: renderPermission
  }));

  return [
    {
      title: 'Chức năng',
      dataIndex: ['resource', 'name'],
      key: 'resource',
      width: 'auto'
    },
    ...actionColumns
  ];
};

export const useGroupCategories = (param) => {
  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  const memoParam = useMemo(() => {
    return { ...param };
    //eslint-disable-next-line
  }, [createSuccess, updateSuccess, deleteSuccess, param]);

  return useFetchByParam({
    action: getGroupCategories,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: memoParam
  });
};

export const useGroupCategory = (params) => {
  return useFetchByParam({
    action: getGroupCategory,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params,
    actionUpdate : updateGroupCategoryRedux
  });
};

export const useCreateGroupCategory = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới nhóm dịch vụ người dùng thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới nhóm dịch vụ người dùng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createGroupCategory
  });
};

export const useUpdateGroupCategory = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật nhóm dịch vụ người dùng thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật nhóm dịch vụ người dùng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateGroupCategory
  });
};

export const useDeleteGroupCategory = (deleteCallback) => {
  useSuccess(deleteSuccessSelector, 'Xoá nhóm dịch vụ người dùng thành công', deleteCallback);
  useFailed(deleteFailedSelector, 'Xoá nhóm dịch vụ người dùng thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteGroupCategory
  });
};

export const useInitGroupCategory = (groupCategory, id) =>
  useMemo(() => {
    if (!groupCategory || !id) {
      return {};
    }

    return groupCategory;
  }, [groupCategory, id]);

export const useResetGroupCategory = () => {
  useResetState(resetGroupCategoryState);
};

export const useUpdatePermission = () => {
  return useSubmit({
    loadingSelector: isUpdatePermissionLoadingSelector,
    action: updatePermission
  });
};

export const getNewPolicies = (group, isAssgined, resource, action) => {
  let newPolicies = {};
  if (action === 'admin') {
    // if choose Quản lý
    if (isAssgined) {
      // On checked
      let allowAllPermission = { admin: true };
      ACTIONS?.forEach((permission) => (allowAllPermission[permission] = true)); // Allow all permissions Form Action in POLYCY default
      newPolicies = { ...group?.policies, [resource]: allowAllPermission }; // allow all policies
    } else {
      // UnChecked
      newPolicies = { ...group?.policies, [resource]: {} }; // remove all policies
    }
  } else {
    newPolicies = {
      ...group?.policies,
      [resource]: {
        ...get(group, `policies.${resource}`), // Find Resource Action and Update
        [action]: isAssgined // Find Action and Update
      }
    };
  }
  return newPolicies;
};
/**
 *
 * @param {String} keyword
 * @param {Array} resource
 * @param {Function} updateResources
 */
export const onSearchPermissions = (
  keyword = '',
  resource = [],
  updateResources = () => {}
) => {
  if (isNil(keyword) || keyword === '') return updateResources(resource);
  // keywords are not exists will return all
  const resultSearch = resource?.filter((item) => {
    return StringToSlug(
      get(item, 'resource.name', '')?.toLowerCase()
    )?.includes(StringToSlug(keyword?.trim()?.toLowerCase()));
  });
  updateResources(resultSearch);
};
