import React, { useEffect } from 'react';
import { Form, Input, Row, Button, Skeleton, Modal } from 'antd';
import { useCreateGroupCategory, useUpdateGroupCategory } from '~/hooks';
import { useParams } from 'react-router-dom';
import './GroupCategoryForm.scss';

const FormItem = Form.Item;

const GroupCategoryForm = ({ isOpen, onClose, initGroup }) => {
  const { id: branchId } = useParams();
  const { _id } = initGroup || {};
  const [form] = Form.useForm();
  const [isSubmitLoading, handleCreate] = useCreateGroupCategory(onClose);
  const [, handleUpdate] = useUpdateGroupCategory(onClose);
  const isLoading = false;

  useEffect(() => {
    form.resetFields();
  }, [initGroup, form]);

  const onFinish = (values) => {
    const groupCategory = {
      ...values,
      branchId
    };

    if (_id) {
      handleUpdate({
        ...groupCategory,
        _id
      });
    } else {
      handleCreate(groupCategory);
    }
  };

  return (
    <Modal
      visible={isOpen}
      footer={[]}
      onCancel={onClose}
      className="form-modal"
    >
      <div className="employee-group-form">
        <h4 style={{ marginRight: 'auto' }}>
          {`${!_id ? 'Tạo mới ' : 'Cập nhật'}`} nhóm dịch vụ cho người dùng
        </h4>

        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initGroup}
          labelCol={{ sm: 24, md: 24, lg: 8, xl: 8 }}
          wrapperCol={{ sm: 24, md: 24, lg: 16, xl: 16 }}
        >
          <Row
            align="middle"
            justify="space-between"
            className="employee-group-form__logo-row"
          >
            <FormItem
              label="Tên nhóm dịch vụ"
              name="name"
              rules={[
                { required: true, message: 'Xin mời nhập tên nhóm dịch vụ!' }
              ]}
            >
              {isLoading ? <Skeleton.Input active /> : <Input />}
            </FormItem>
            <FormItem label="Mô tả ngắn" name="description">
              {isLoading ? <Skeleton.Input active /> : <Input />}
            </FormItem>
          </Row>

          <Row className="employee-group-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Button onClick={onClose}>Huỷ</Button>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {_id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default GroupCategoryForm;
