import React from 'react';

import { get } from 'lodash';

import { useUserWorkspace } from '~/hooks';

import { COMPONENT_MODES } from '~/constants/defaultValue';

import WhPartnerMiniForm from './WhPartner/WhPartnerMiniForm';

const WorkspaceManageTasks = () => {
  const [profile] = useUserWorkspace();
  const id = get(profile, "_id");

  return (
    <div className='page-wraper page-content page-content-workSpace'>
      <WhPartnerMiniForm
      showLinkRefferral
      isShowRanking
        whPartnerId={id}
        showAssignedServicesTable={true}
        showWorkingDaysTable={true}
        mode={COMPONENT_MODES.VIEW}
      />
    </div>
  )
}

export default WorkspaceManageTasks;
