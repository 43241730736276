import React, { useEffect } from 'react';

import { Divider } from 'antd';
import { get } from 'lodash';
import { WH_BILL_ITEM_NOTES } from '~/constants/defaultValue';

const WhBillItemNoteCancel = ({
  whBillItem,
  note, 
  setNote,
  noteShow,
  setNoteShow,
  id
}) => {
  const findItemCancel = whBillItem?.whAppointments?.find(item => {
    return item?.status === 'CANCELLED';
  });
  useEffect(() => {
    const noteWhBillItem = findItemCancel?.reasonOfStatuses?.CANCELLED
    setNoteShow(noteWhBillItem ? noteWhBillItem : noteShow);
  }, [whBillItem, id]);
  return (
    <>
      <Divider plain style={{ marginTop: 0, marginBottom: 0 }}>
        Lý do huỷ buổi hẹn
      </Divider>
      <div
        className='wh-service-detail__description--paragraph'
      >
        {
          !noteShow ? <p>(không có ghi chú)</p> : <p>{noteShow}</p>
        }
      </div>
    </>
  )
}

export default WhBillItemNoteCancel;
