import { get } from 'lodash';
import { setEndofDate } from '~/utils/helper';
import requester from './requester';

const TipsPartner = {
  create: (tips) => requester.post('/partner-tips', tips),
  update: (query) => requester.put(`/partner-tips/${query.id}`,query),
  delete: (id) => requester.delete(`/partner-tips/${id}`),
  getAll: (query) => requester.get('/partner-tips', {...query,endDate : setEndofDate(get(query,'endDate',0))}),
  getOne: (id) => requester.get(`/partner-tips/${id}`),
  getAllLogsTips : (query) => requester.get(`/partner-snapshot/${query.id}`,{...query,endDate : setEndofDate(get(query,'endDate',0))}),
};
export default TipsPartner;

