import { Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import { compact, get, keys } from 'lodash'
import React, { useMemo } from 'react'
import { formatNumberThreeComma } from '~/hooks/utils'
const reducedVi = {
    refund : "Điểm thưởng",
    discount : "Mã giảm giá",
    // prepay : "Trả trước",
    discountRate : "Chiết khấu thành viên thân thiết",
    "" : '',
}
export default function TableDiscount({ whAppointment }) {
    const dataSource = useMemo(() => keys(get(whAppointment,'reduced',{}))?.map(e =>reducedVi[e] &&  ({name : e,value : get(whAppointment,'reduced',{})[e]})),[whAppointment])
    const columns = [
        {
            title: 'Tên chiết khấu',
            dataIndex: 'name',
            key: 'name',
            render : (text) => reducedVi[text || ""]
        },
        {
            title: 'Giá trị chiết khấu',
            dataIndex: 'value',
            key: 'value',
            render: (value) => formatNumberThreeComma(Math.floor(value) || 0) 
        },
    ]
    const summaryFooter = (current) => {
        const total = current?.reduce((sum,cur) => sum + parseInt(get(cur,'value',0)),0)
        return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={1} align="start">Tổng cộng</Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1} align="start"><Text strong>{formatNumberThreeComma(total)}</Text></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )
    };
    return (
        <Table summary={summaryFooter} columns={columns} dataSource={compact(dataSource)} />
    )
}
