import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  getUIs,
  resetUIState,
  updateUI
} from '~/redux/action';
const getSelector = (key) => (state) => state.ui[key];

const loadingSelector = getSelector('isLoading');
const getUIsFailedSelector = getSelector('getUIsFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const UIsSelector = getSelector('UIs');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
export const useUIQueryParams = () => {
    const query = useQueryParams();
    const key = query.get('key');
    const updateUISuccess = useSelector(updateSuccessSelector);

    return useMemo(() => {
        const queryParams = {
            key,
        };
        return [queryParams];
        //eslint-disable-next-line
    }, [
        key,
        updateUISuccess,
    ]);
};


export const useUIs = (query) => {
    return useFetchByParam({
        action: getUIs,
        loadingSelector,
        dataSelector: UIsSelector,
        failedSelector: getUIsFailedSelector,
        param: query
    });
};

export const useUpdateUI = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhât thành công', callback);
    useFailed(updateFailedSelector);

    return useSubmit({
        loadingSelector: isSubmitLoadingSelector,
        action: updateUI
    });
};

export const useResetUI = () => {
    useResetState(resetUIState);
};
