import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import {
  GET_BRANCHES_REQUEST,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAILED,
  GET_BRANCH_REQUEST,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAILED,
  CREATE_BRANCH_REQUEST,
  CREATE_BRANCH_SUCCESS,
  CREATE_BRANCH_FAILED,
  UPDATE_BRANCH_REQUEST,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAILED,
  DELETE_BRANCH_REQUEST,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAILED,
  UPDATE_BRANCH_STATUS_SUCCESS,
  UPDATE_BRANCH_STATUS_FAILED,
  GET_RESOURCE_BRANCH_REQUEST,
  GET_RESOURCE_BRANCH_SUCCESS,
  GET_RESOURCE_BRANCH_FAILED,
  GET_RESOURCE_ACCESSED_BRANCH_FAILED,
  GET_RESOURCE_ACCESSED_BRANCH_REQUEST,
  ACCESS_RESOURCE_BRANCH_SUCCESS,
  ACCESS_RESOURCE_BRANCH_FAILED,
  DENY_RESOURCE_BRANCH_SUCCESS,
  DENY_RESOURCE_BRANCH_FAILED,
  ACCESS_RESOURCE_BRANCH_REQUEST,
  DENY_RESOURCE_BRANCH_REQUEST,
  GET_RESOURCE_ACCESSED_BRANCH_SUCCESS,
} from '~/constants/actionTypes';

function* getBranches({ payload: query }) {
  try {
    const response = yield call(Api.branch.getAll, query);
    yield put({ type: GET_BRANCHES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_BRANCHES_FAILED, payload: error.message });
  }
}

function* getBranch({ payload: id }) {
  try {
    const branch = yield call(Api.branch.getById, id);
    yield put({ type: GET_BRANCH_SUCCESS, payload: branch });
  } catch (error) {
    yield put({ type: GET_BRANCH_FAILED, payload: error.message });
  }
}

function* createBranch(action) {
  try {
    const data = yield call(Api.branch.create, action.payload);
    yield put({ type: CREATE_BRANCH_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_BRANCH_FAILED, payload: error.message });
  }
}

function* updateBranch(action) {
  try {
    const data = yield call(Api.branch.update, action.payload);
    yield put({ type: UPDATE_BRANCH_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_BRANCH_FAILED, payload: error });
  }
}


function* deleteBranch({ payload }) {
  try {
    yield call(Api.branch.delete, payload);
    yield put({ type: DELETE_BRANCH_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_BRANCH_FAILED, payload: error });
  }
}
function* getResourceBranch({ payload }) {
  try {
    const data = yield call(Api.branch.getResource, payload);
    yield put({ type: GET_RESOURCE_BRANCH_SUCCESS, payload : data });
  } catch (error) {
    yield put({ type: GET_RESOURCE_BRANCH_FAILED, payload: error });
  }
}

function* getResourceAccessedBranch({ payload }) {
  try {
    const data = yield call(Api.branch.getResourceAccessed, payload);
    yield put({ type: GET_RESOURCE_ACCESSED_BRANCH_SUCCESS, payload : data });
  } catch (error) {
    yield put({ type: GET_RESOURCE_ACCESSED_BRANCH_FAILED, payload: error });
  }
}

function* accessResource({payload}) {
  try {
    const data = yield call(Api.branch.accessResource, payload);
    yield put({ type: ACCESS_RESOURCE_BRANCH_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: ACCESS_RESOURCE_BRANCH_FAILED, payload: error });
  }
}

function* denyResource({payload}) {
  try {
    const data = yield call(Api.branch.denyResource, payload);
    yield put({ type: DENY_RESOURCE_BRANCH_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: DENY_RESOURCE_BRANCH_FAILED, payload: error });
  }
}

export default function* branch() {
  yield takeLatest(GET_BRANCHES_REQUEST, getBranches);
  yield takeLatest(GET_BRANCH_REQUEST, getBranch);
  yield takeLatest(DELETE_BRANCH_REQUEST, deleteBranch);
  yield takeLatest(CREATE_BRANCH_REQUEST, createBranch);
  yield takeLatest(UPDATE_BRANCH_REQUEST, updateBranch);
  yield takeLatest(GET_RESOURCE_BRANCH_REQUEST, getResourceBranch);
  yield takeLatest(GET_RESOURCE_ACCESSED_BRANCH_REQUEST, getResourceAccessedBranch);
  yield takeLatest(ACCESS_RESOURCE_BRANCH_REQUEST, accessResource);
  yield takeLatest(DENY_RESOURCE_BRANCH_REQUEST, denyResource);
}
