// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/',
  app: ''
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    loginWorkspace: path(ROOTS.auth, '/login-workspace'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify')
  },

  comingSoon: '/coming-soon',
  maintenance: '/maintenance'
};

export const PATH_APP = {
  root: ROOTS.app,

  main: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    home: path(ROOTS.app, '/home'),
  },

  phippingVendor: {
    root: path(ROOTS.app, '/shipping-vendor')
  },

  policy: {
    root: path(ROOTS.app, '/policy')
  },

  coupon: {
    root: path(ROOTS.app, '/coupon'),
    give: path(ROOTS.app, '/give-coupon')
  },

  partnership: {
    root: path(ROOTS.app, '/partnership')
  },

  employee: {
    root: path(ROOTS.app, '/employee')
  },

  user: {
    root: path(ROOTS.app, '/user')
  },

  partner: {
    root: path(ROOTS.app, '/partner'),
    create: path(ROOTS.app, '/partner/create'),
    edit: path(ROOTS.app, '/partner/edit/:id'),
    detail: path(ROOTS.app, '/partner/detail/:id')
  },
  clinics: {
    root: path(ROOTS.app, '/clinics'),
    create: path(ROOTS.app, '/clinics/create'),
    edit: path(ROOTS.app, '/clinics/edit/:id'),
    detail: path(ROOTS.app, '/clinics/detail/:id')
  },
  pharmacies: {
    root: path(ROOTS.app, '/pharmacies'),
    create: path(ROOTS.app, '/pharmacies/create'),
    edit: path(ROOTS.app, '/pharmacies/edit/:id'),
    detail: path(ROOTS.app, '/pharmacies/detail/:id')
  },

  branch: {
    root: path(ROOTS.app, '/branch'),
    clinicDetail: path(ROOTS.app, '/branch/detail/CLINIC/:id'),
    branchDetail: path(ROOTS.app, '/branch/detail/BRANCH/:id'),
  },

  hospital: {
    root: path(ROOTS.app, '/hospital'),
    create: path(ROOTS.app, '/hospital/create'),
    edit: path(ROOTS.app, '/hospital/edit/:id')
  },

  staff: {
    root: path(ROOTS.app, '/staff'),
    create: path(ROOTS.app, '/staff/create'),
    edit: path(ROOTS.app, '/staff/edit/:id')
  },

  appointment: {
    root: path(ROOTS.app, '/appointment'),
    createAppointment: path(ROOTS.app, '/appointment/create'),
    deletedAppointment: path(ROOTS.app, '/appointment/deleted')
  },
  associateApointment: {
    root: path(ROOTS.app, '/appointment/associate'),
    createassociateApointment: path(ROOTS.app, '/appointment/associate/create')
  },

  news: {
    root: path(ROOTS.app, '/news'),
    create: path(ROOTS.app, '/news/create'),
    edit: path(ROOTS.app, '/news/edit/:id')
  },

  newsCategory: {
    root: path(ROOTS.app, '/news-category'),
    create: path(ROOTS.app, '/news-category/create'),
    edit: path(ROOTS.app, '/news-category/edit/:id')
  },

  order: {
    pendingOrder: path(ROOTS.app, '/pending-orders'),
    allOrder: path(ROOTS.app, '/all-orders')
  },

  whBill: {
    root: path(ROOTS.app, '/wh-bill'),
    processing: path(ROOTS.app, '/wh-bill/processing'),
    orderTemp: path(ROOTS.app, '/wh-quotation'),
    depreciation: path(ROOTS.app, '/depreciation'),
  },

  whBillItem: {
    root: path(ROOTS.app, '/wh-bill-item'),
    updateAppointments: path(ROOTS.app, '/wh-bill-item/:id/update-appointments')
  },

  whCategory: {
    root: path(ROOTS.app, '/wh-category'),
    create: path(ROOTS.app, '/wh-category/create'),
    edit: path(ROOTS.app, '/wh-category/edit/:id')
  },

  whService: {
    root: path(ROOTS.app, '/wh-service'),
    create: path(ROOTS.app, '/wh-service/create'),
    edit: path(ROOTS.app, '/wh-service/edit/:id')
  },

  whPartner: {
    root: path(ROOTS.app, '/wh-partner'),
    create: path(ROOTS.app, '/wh-partner/create'),
    edit: path(ROOTS.app, '/wh-partner/edit/:id'),
    referral: path(ROOTS.app, '/wh-partner/referral'),
    equipment: path(ROOTS.app, '/wh-partner/equipment'),
  },
  whTransaction: {
    root: path(ROOTS.app, '/wh-transactions')
  },
  whVoucher: {
    root: path(ROOTS.app, '/wh-voucher'),
    createWhReceiptVoucher: path(
      ROOTS.app,
      '/wh-voucher/create-receipt-voucher'
    ),
    editWhReceiptVoucher: path(
      ROOTS.app,
      '/wh-voucher/edit-receipt-voucher/:id'
    ),
    createWhPaymentVoucher: path(
      ROOTS.app,
      '/wh-voucher/create-payment-voucher'
    ),
    editWhPaymentVoucher: path(
      ROOTS.app,
      '/wh-voucher/edit-payment-voucher/:id'
    )
  },

  whReport: {
    root: path(ROOTS.app, '/wh-report'),
    partner: path(ROOTS.app, '/wh-report-partner'),
    customer: path(ROOTS.app, '/wh-report-customer'),
    system: path(ROOTS.app, '/wh-report-system')
  },
  whMedicines: {
    root: path(ROOTS.app, '/wh-medicines')
  },

  whContact: {
  root: path(ROOTS.app, '/wh-contact')
  },

  whPartnerWorkspace: {
    root: path(ROOTS.app, '/workspace'),
    registerWorkingTime: path(ROOTS.app, '/workspace/registerWorkingTime'),
    allTasks: path(ROOTS.app, '/workspace/allTasks'),
    pendingTasks: path(ROOTS.app, '/workspace/pendingTasks'),
    manageTasks: path(ROOTS.app, '/workspace/manageTasks'),
    myWorkforce: path(ROOTS.app, '/workspace/myWorkforce'),
    inviteWhPartner: path(ROOTS.app, '/workspace/myWorkforce/invite'),
    timeTrain: path(ROOTS.app, '/workspace/timeTrain'),
    wallet: path(ROOTS.app, '/workspace/wallet'),
    jobsHistory: path(ROOTS.app, '/workspace/job-history'),
    transactions: path(ROOTS.app, '/workspace/transactions'),
    equipment: path(ROOTS.app, '/workspace/equipment'),
    warehouseVoucher: path(ROOTS.app, '/workspace/warehouseVoucher'),
  },

  config: {
    degree: path(ROOTS.app, '/degree'),
    createDegree: path(ROOTS.app, '/degree/create'),
    editDegree: path(ROOTS.app, '/degree/edit/:id'),

    speciality: path(ROOTS.app, '/speciality'),
    createSpeciality: path(ROOTS.app, '/speciality/create'),
    editSpeciality: path(ROOTS.app, '/speciality/edit/:id'),

    staffGroup: path(ROOTS.app, '/staff-group'),
    createStaffGroup: path(ROOTS.app, '/staff-group/create'),
    editStaffGroup: path(ROOTS.app, '/staff-group/edit/:id')
  },

  whSessionOfDay: {
    root: path(ROOTS.app, '/wh-session-of-day'),
    createWhSessionOfDay: path(ROOTS.app, '/wh-session-of-day/create'),
    editWhSessionOfDay: path(ROOTS.app, '/wh-session-of-day/edit/:id')
  },

  rateConfig: {
    degreeList: path(ROOTS.app, '/degreeList'),
    createDegreeList: path(ROOTS.app, '/degreeList/create'),
    editDegreeList: path(ROOTS.app, '/degreeList/edit/:id'),

    position: path(ROOTS.app, '/position'),
    createPosition: path(ROOTS.app, '/position/create'),
    editPosition: path(ROOTS.app, '/position/edit/:id'),

    experience: path(ROOTS.app, '/experience'),
    createExperience: path(ROOTS.app, '/experience/create'),
    editExperience: path(ROOTS.app, '/experience/edit/:id')
  },

  tableRanking: {
    root: path(ROOTS.app, '/tableRanking'),
    createTableRanking: path(ROOTS.app, '/tableRanking/create'),
    editTableRanking: path(ROOTS.app, '/tableRanking/:id')
  },

  evaluate: {
    root: path(ROOTS.app, '/evaluate')
  },

  customerType: {
    root: path(ROOTS.app, '/customerType'),
    create: path(ROOTS.app, '/customerType/create'),
    edit: path(ROOTS.app, '/customerType/:id')
  },
  serviceBookingInstruction: {
    root: path(ROOTS.app, '/serviceBookingInstruction'),
    create: path(ROOTS.app, '/serviceBookingInstruction/create'),
    edit: path(ROOTS.app, '/serviceBookingInstruction/:id')
  },
  banner: {
    root: path(ROOTS.app, '/banner'),
    create: path(ROOTS.app, '/banner/create'),
    edit: path(ROOTS.app, '/banner/:id')
  },

  gallery: {
    root: path(ROOTS.app, '/gallery'),
  },
  addYoutube: {
    root: path(ROOTS.app, '/addYoutube'),
  },
  sayAboutUs: {
    root: path(ROOTS.app, '/sayAboutUs'),
  },
  appDownload: {
    root: path(ROOTS.app, '/appDownload'),
  },
  
  job: {
    root: path(ROOTS.app, '/job'),
    edit: path(ROOTS.app, '/job-edit/:id'),
    create: path(ROOTS.app, '/job-create'),
    
  },
  registerWhPartner: {
    config: path(ROOTS.app, '/registerWhPartner-config'),
    list: path(ROOTS.app, '/registerWhPartner-list'),
    typeForm: path(ROOTS.app, '/registerWhPartner-typeForm'),
  },

  positionJob: {
    root: path(ROOTS.app, '/positionJob'),
    edit: path(ROOTS.app, '/positionJob-edit/:id'),
    create: path(ROOTS.app, '/positionJob-create'),
  },

  applyJob: {
    root: path(ROOTS.app, '/applyJob'),
  },
  tutorialJob: {
    root: path(ROOTS.app, '/tutorialJob'),
  },
  settingTutorialJob: {
    root: path(ROOTS.app, '/settingTutorialJob'),
  },
  companyJob: {
    root: path(ROOTS.app, '/companyJob'),
  },
  jobQuestion: {
    root: path(ROOTS.app, '/jobQuestion'),
  },
  jobForm: {
    root: path(ROOTS.app, '/jobForm'),
  },
  infoCompany: {
    root: path(ROOTS.app, '/infoCompany'),
  },
  coreValue: {
    root: path(ROOTS.app, '/coreValue'),
  },

  software : {
    listSoftWare: path(ROOTS.app, '/software-list'),
    listTrial: path(ROOTS.app, '/trial-list'),

  },
  uiClient : {
    footer :  path(ROOTS.app, '/ui-client/footer'),
    softwareBenefit :  path(ROOTS.app, '/ui-client/softwareBenefit'),
    softwareFeature :  path(ROOTS.app, '/ui-client/softwareFeature'),
    softwareTarget :  path(ROOTS.app, '/ui-client/softwareTarget'),
    jobBanner :  path(ROOTS.app, '/ui-client/jobBanner'),
    adv :  path(ROOTS.app, '/ui-client/adv'),
  },
  serviceFamilyHealthyCare: {
    root: path(ROOTS.app, '/serviceFamilyHealthyCare'),
    create: path(ROOTS.app, '/serviceFamilyHealthyCare/create'),
    edit: path(ROOTS.app, '/serviceFamilyHealthyCare/:id')
  },
  whCustomerManagement: {
    root: path(ROOTS.app, '/customer-account'),
    info: path(ROOTS.app, '/customer-info/:id'),//
    referral: path(ROOTS.app, '/customer/referral')//
  },

  whCustomerImport: {
    root: path(ROOTS.app, '/potential-customer'),
    create: path(ROOTS.app, '/potential-customer/create'),
    edit: path(ROOTS.app, '/potential-customer/:id')
  },

  whPartnerImport: {
    root: path(ROOTS.app, '/customer-partner'),
    create: path(ROOTS.app, '/customer-partner/create'),
    edit: path(ROOTS.app, '/customer-partner/:id')
  },

  whManagement: {
    root: path(ROOTS.app, '/wh-management')
  },
  highlight: {
    root: path(ROOTS.app, '/highlight')
  },
  whPackageLevel: {
    root: path(ROOTS.app, '/wh-package-level')
  },

  whNotificationBotManagement: {
    root: path(ROOTS.app, '/notification-bot-management')
  },

  myNotification: {
    root: path(ROOTS.app, '/my-notification')
  },

  whTrainingCourse: {
    root: path(ROOTS.app, '/wh-training-course')
  },
  referral: {
    root: path(ROOTS.app, '/referral'),
    ConfigCampaign: path(ROOTS.app, '/config-campaign'),
    PointSystem: path(ROOTS.app, '/point-system'),
    RewardItem: path(ROOTS.app, '/reward-item'),
    Ranking: path(ROOTS.app, '/ranking-referral'),
    ExchangePoint: path(ROOTS.app, '/exchange-point'),
    Activity: path(ROOTS.app, '/activity-config-campaign'),
    RequestVoucher: path(ROOTS.app, '/request-Voucher'),
    IntroUsingRefCode: path(ROOTS.app, '/huong-dan-su-dung-ma-gioi-thieu'),
  },
  whWorkNorm: {
    root: path(ROOTS.app, '/wh-work-norm'),
    create: path(ROOTS.app, '/wh-work-norm/create'),
    edit: path(ROOTS.app, '/wh-work-norm/:id')
  },

  keywordSearch: {
    root: path(ROOTS.app, '/keyword-search')
  },
  
  hospitalList: {
    root: path(ROOTS.app, '/hospital-list'),
    create: path(ROOTS.app, '/hospital-list/create'),
    edit: path(ROOTS.app, '/hospital-list/:id')
  },
  servicePlace: {
    root: path(ROOTS.app, '/service-place'),
    create: path(ROOTS.app, '/service-place/create'),
    edit: path(ROOTS.app, '/service-place/:id')
  },

  system: {
    root: path(ROOTS.app, '/system'),
  },


  clinicDashboard: {
    root: path(ROOTS.app, '/clinicDashboard')
  },
  pharmacyOnlineDashboard: {
    root: path(ROOTS.app, '/pharmacyOnlineDashboard')
  },
  medicalEquipments: {
    root: path(ROOTS.app, '/medical-equipments'),
    create: path(ROOTS.app, '/medical-equipments/create'),
    edit: path(ROOTS.app, '/medical-equipments/:id')
  },
  productCatalogue: {
    root: path(ROOTS.app, '/product-catalogue'),
    create: path(ROOTS.app, '/product-catalogue/create'),
    edit: path(ROOTS.app, '/product-catalogue/:id')
  },
  listWareHouse:{
    root: path(ROOTS.app, '/list-warehouse'),
    create: path(ROOTS.app, '/list-warehouse/create'),
    edit: path(ROOTS.app, '/list-warehouse/:id')
  },

  productList: {
    root: path(ROOTS.app, '/product-list'),
    create: path(ROOTS.app, '/product-list/create'),
    edit: path(ROOTS.app, '/product-list/:id')
  },
  productListByWareHouse: {
    root: path(ROOTS.app, '/list-warehouse/products'),
    edit: path(ROOTS.app, '/list-warehouse/products/:id'),
  },
  voucherWareHouse: {
    root: path(ROOTS.app, '/voucher-warehouse'),
  },

  partnerCancelAppointment: {
    root: path(ROOTS.app, '/partner-cancel-appointment'),
  },

  //History whService
  history: {
    root: path(ROOTS.app, '/history'),
  },
  errorAccess : {
    unauthorized :  path(ROOTS.app, '/unauthorized'),
    pageNotFound :  path(ROOTS.app, '/*'),
  },
  
  confirmVoucherWareHouse: {
    root: path(ROOTS.app, '/confirm-voucher-warehouse'),
  },
  
  //History whService
  historyWhService: {
    root: path(ROOTS.app, '/history-wh-service'),
  },

  whServiceBranch: {
    root: path(ROOTS.app, '/wh-service-branch'),
  },
  deliveryCoupon:{
    root: path(ROOTS.app, '/delivery-coupon'),
    create: path(ROOTS.app, '/delivery-coupon/create'),
    edit: path(ROOTS.app, '/delivery-coupon/:id')
  },
  workFlow: {
    root: path(ROOTS.app, '/work-flow'),
    sprint: path(ROOTS.app, '/work-flow/sprint'),
    // sprintById: path(ROOTS.app, '/work-flow/sprint/:boardId'),

    // create: path(ROOTS.app, '/work-flow/create'),
    // edit: path(ROOTS.app, '/work-flow/:id')
  },
  statusConfig: {
    root: path(ROOTS.app, '/status-config'),
    edit: path(ROOTS.app, '/status-config/:id')
  },
  workFlowDetail: {
    root: path(ROOTS.app, '/work-flow/detail'),
    edit: path(ROOTS.app, '/work-flow/detail/:sprintId')
  },
  workTaskItem: {
    root: path(ROOTS.app, '/work-task-item'),
    edit: path(ROOTS.app, '/work-task-item/:id')
  },
  workTaskHistory: {
    edit: path(ROOTS.app, '/work-task-item/history/:id')
  },
  serviceByLocation: {
    root: path(ROOTS.app, '/service-by-location'),
    edit: path(ROOTS.app, '/service-by-location/:id')
  }
};
