import { Col, Row, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react'
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { TYPE_OF_MONEY_REWARD_ITEM_VI, WALLET_ACTION } from '~/constants/defaultValue';
import { formatNumberThreeComma } from '~/hooks/utils';
import { useUpdateWalletParams, useWalletPaging, useWalletQueryParams, useWallets } from '~/hooks/wallet'

export default function TabsWallet({userId,reFetch,mode = 'query',width,allowSearch = false}) {
    const [query] = useWalletQueryParams(userId,reFetch)
    const [page,setPage] = useState(1)
    const [limit,setLimit] = useState(10)
    const [keywordState,setKeywordState] = useState(null)
    const queryState = useMemo(() => ({page,limit,userId,keyword:keywordState}),[page,limit,userId,keywordState])
    const [wallets,isLoading] = useWallets(mode === 'query' ? query : queryState)
    const paging = useWalletPaging(query);
  const [{onParamChange }] =useUpdateWalletParams(query);
    const columns = [
        {
          title: 'Số tiền',
          dataIndex: 'action',
          key: 'action',
          render: (item, record, index) =>
          <Row>
            <Col span={24}>
            {item === WALLET_ACTION.PAY_IN ? (
              <Text type="success" strong>
                + {formatNumberThreeComma(get(record,'money',0))}
              </Text>
            ) : (
              <Text type="danger" strong>
               - {formatNumberThreeComma(get(record,'money',0))}
              </Text>
            )}
            </Col>
            <Col span={24}>
              <Text>{TYPE_OF_MONEY_REWARD_ITEM_VI[get(record,'type','')]}</Text>
            </Col>
            <Col span={24}>
            <Text type='secondary'>{moment(get(record,'createdAt')).format('DD-MM-YYYY HH:mm:ss')}</Text>
            </Col>
          </Row>
        },
        {
          title: 'Miêu tả',
          dataIndex: 'desc',
          key: 'desc',
          render: (item, record, index) => item || ''
        },
      ];
  return (
   <>
   {allowSearch && <Search
          allowClear
          style={{ width: "300px" }}
          placeholder="Tìm bất kì..."
          enterButton
          onSearch={(value) => setKeywordState(value ? value?.trim() : '')}
        />}
    {isLoading ? (
        <SkeletonTable columns={columns} rowCount={10} />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          style={{width : width ?  width : '100%'}}
          dataSource={wallets}
          size="middle"
          onChange={({ current }) => {
            if(  mode=== 'query'){
            onParamChange({ page: current })
            }
            if(mode === 'state'){
              setPage(current)
            }
            }}
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
        />
      )}
   </>
  )
}
