import React, { useState, memo, useEffect, useCallback, useMemo } from 'react';
import {useHistory} from "react-router-dom"
import {
  Form,
  Select,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Skeleton,
  Cascader
} from 'antd';
import { filterAcrossAccents, useFetch } from '~/hooks/utils';
import dayjs from 'dayjs'
import {
  useAppointment,
  useInitAppointment,
  useUpdateAppointment,
  useCreateAppointment,
  useResetAppointment,
} from '~/hooks/appointment';
import moment from 'moment';
import { useHospitals, useHospitalAvailableTimes, useServiceOfHospital } from '~/hooks/hospital';
import {
  useBranch,
  useCities,
  useDistricts,
  useMatchPolicy,
  useProfile,
  useSelectBranch,
  useSpecialityOptionsByBranchId,
  useWhCustomersManagement
} from '~/hooks';
import { removeAccents } from '~/hooks/utils';
import { forIn, get, groupBy, isNil, isString, rest, transform } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import { WithOrPermission } from '../Common';
import POLICY from '~/components/Policy'
import api from '~/api';
import toastr from 'toastr';

const { Option } = Select;
const FormItem = Form.Item;

const FORMAT_DATE = 'YYYYMMDD';
const CHANGE_FORMAT_DATE = 'YYYY-MM-DD'

export const AppointmentIsSytemForm = ({
  specialityOptions,
  appointmentId,
  onClose,
  initStartTime,
  isOpen,
  handleUpdate,
  isSubmitLoading,
  isReload
}) => {
  const [appointment, isLoading] = useAppointment(appointmentId);
  const [brandId] = useSelectBranch();
  const [branchInfo, loading] = useBranch(brandId); 
  const [form] = Form.useForm();
  // const id = brandId;
  const [idQuery, setIdQuery] = useState(brandId)
  const id = useMemo(() => {
    if (appointmentId) {
      return appointment?.hospital?._id;
    } else {
      if (form.getFieldValue('type') === 'clinic') {
        return brandId
      } else {
        return 99999;
      };
    }
  }, [appointmentId, brandId,appointment]);

  const specialityOptionsById = useSpecialityOptionsByBranchId(id);
  const [selectedDistrictCode, setSelectedDistrictCode] = useState(null);
  const [selectedCityCode, setSelectedCityCode] = useState(null);
  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(selectedCityCode);
  const [selectedSpecialityId, setSelectedSpecialityId] = useState({
    specialityId: null,
    cityId: null,
    districtId: null
  });
  const [hospitals, isHospitalLoading] = useHospitals(selectedSpecialityId);
  const [valueSelectPartner, setValueSelectPartner] = useState('clinic');
  const [selectedHospitalId, setSelectedHospitalId] = useState(null);
  const [availableTimes, isAvailableTimesLoading] =
    useHospitalAvailableTimes(selectedHospitalId);
  const [specialityId, setSpecialityId] = useState('');
  const [optionsPharmacies, setOptionsPharmacies] = useState([]);
  const [valueDate, setValueDate] = useState('');
  const [valueTime, setValueTime] = useState('');
  const [phoneNumberSearch, setPhoneNumberSearch] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [isCheckUpdate, setIsCheckUpdate] = useState(false);
  /**
   * Check if specialization exists in the clinic 
   * @constant {String} speciality 
   * @enum {Object} hospital 
   */
  const checkServiceIsExits =  useCallback((appointment) => {
    const serviceInHospital = appointment?.hospital?.speciality;
    const findSpeciality = serviceInHospital?.find((item) => item === appointment?.speciality?._id);
    if (typeof(findSpeciality) === 'undefined') {
      form.setFieldsValue({
        specialityId: get(appointment?.speciality, 'name.vi', '').concat('- Dịch vụ đã bị xoá')
      });
      setIsCheckUpdate(true);
    } else {
      form.setFieldsValue({
        specialityId: appointment?.speciality._id
      });
      setIsCheckUpdate(false);
    }
  },[form, appointment]);


  const queryFeatch = useMemo(() => ({
    page: 1,
    limit: 10,
    keyword: phoneNumberSearch
  }), [phoneNumberSearch]);
  const [customer, isLoadingCustomer] = useWhCustomersManagement(queryFeatch);

  //Featch list Customer 
  const filterOptionNumberSearch = useCallback(() => {
    let options = []
    const newOption = customer.map((item) => ({
      value: item?.phoneNumber.replace('+84', '0'),
      label: `${item?.phoneNumber.replace('+84', '0')} - ${item?.fullName}`,
      _id: item?._id || '',
      fullName: item.fullName
    }));
    const optionDefault = {
      value: phoneNumberSearch,
      label: phoneNumberSearch
    };
    const findCustomer = newOption.some((item) => item.phoneNumber !== phoneNumberSearch)
    !findCustomer ? options = [...newOption, optionDefault] : options = [...newOption];
    return options
  }, [customer, phoneNumberSearch])
  const initAppointment = useInitAppointment(
    form,
    appointment,
    appointmentId,
    initStartTime,
    setSelectedHospitalId,
    setSelectedSpecialityId
  );

  const [availableTimesInDay, setAvailableTimesInDay] = useState([]);

  useResetAppointment();

  const createSuccesCallback = () => {
    form.resetFields();
    onClose();
  };
  const [, handleCreate] = useCreateAppointment(createSuccesCallback);
  const checkValueSelecterPartner = valueSelectPartner === 'clinic';

  /**
   * Find pharmacies exit specialityId
   * 
   */
  useEffect(() => {
    let newPharmacies = (hospitals??[]).map((item) => {
      item.speciality.filter((itemss) => itemss._id === specialityId);
      return item;
    });
    if (appointmentId && appointment?.hospital?._id === 99999) {
      newPharmacies.push(appointment?.hospital)
    };

    //Add a clinic when its service has been removed
    if (appointmentId) {
      const serviceInHospital = appointment?.hospital?.speciality;
      const findSpeciality = serviceInHospital?.find((item) => item === appointment?.speciality?._id);
      if (typeof(findSpeciality) === 'undefined') {
        const hospital = get(appointment, 'hospital');
        hospital && newPharmacies?.push(hospital);
      };
    };
    const timeExitInForm =  form.getFieldValue('dateLocal')
    if (timeExitInForm) {
      const timeAppointment = appointment ?  new Date(get(appointment, 'date', '')) : new Date(timeExitInForm)
      const getDayOfWeek = timeAppointment.getDay()
      const filterIsOpen = newPharmacies.filter((clinic) => {
        let checkTime = true
        if (valueTime) {
          const startTime = clinic?.workingHours[getDayOfWeek]?.startTime
          const startDate = new Date(`2000-01-01T${startTime}:00Z`);
          const endTime = clinic?.workingHours[getDayOfWeek]?.endTime
          const endDate = new Date(`2000-01-01T${endTime}:00Z`);
          const currentDate = new Date(`2000-01-01T${valueTime}:00Z`)
          if (startDate <= currentDate && endDate >= currentDate) {
            checkTime = true
          } else {
            checkTime = false
          }
        };
        return clinic?.workingHours[getDayOfWeek]?.isOpen === true && checkTime
      });
      newPharmacies = filterIsOpen;
    };
    const filterByCity = groupBy(newPharmacies, 'address.city');
    const setNameOptions = transform(
      filterByCity,
      (result, value, key) => {
        result.push({
          label:  String(key),
          value:  String(key),
          children: value
        });
      },
      []
    );

    const result = (setNameOptions??[]).map((item) => {
      const filterByDistrict = groupBy(item.children, 'address.district');

      const handleOptions = transform(
        filterByDistrict,
        (result, values, key) => {
          const newV = values?.map((el) => {
            return ({ value: el._id, label: el.name })
          })
          result.push({
            label: key?.toString(),
            value: key?.toString(),
            children: newV
          });
        },
        []
      );
      return {
        label: item.label.toString(),
        value: item.value.toString(),
        children: handleOptions
      };
    });
    setOptionsPharmacies(result);
    // }
    
  }, [hospitals, appointmentId, valueDate, selectedHospitalId]);
  
  // Set value for form when appointment is exited
  useEffect(() => {
    const getDateLocal = form.getFieldValue('dateLocal')
    setAvailableTimesInDay(availableTimes[getDateLocal?.format(FORMAT_DATE)]);
  }, [appointmentId, availableTimes]);

  /**
   * Set form value when appointment is exits
   */
  useEffect(() => {
    // Find name branch pharmacy
    if (!!appointment && appointmentId) {
      form.setFieldsValue({
        ...appointment,
        branchId: appointment?.branch?.name || "Website",
        type: get(appointment, 'type'),
        specialityId: get(appointment, 'speciality._id',''),
        date: moment(get(appointment, 'date')).format('HH:mm'),
        dateLocal: moment(get(appointment, 'date')),
        hospitalId:  [
            get(appointment, 'hospital.address.city', ''),
            get(appointment, 'hospital.address.district', ''),
            get(appointment, 'hospital._id', '')
          ],
        address: {
          cityId:  get(appointment, 'hospital.address.cityId'),
          districtId: get(appointment, 'hospital.address.districtId')
        },
      });
      setSelectedCityCode(get(appointment, 'address.cityId'));
      setSelectedDistrictCode(get(appointment, 'address.districtId'));
      setSelectedHospitalId(String(get(appointment, 'hospital._id')));
      setSpecialityId(String(get(appointment, 'speciality._id')));
      checkServiceIsExits(appointment);
      return;
    }
    else if (!appointmentId) {
      form.resetFields();
      const setHospitalId = branchInfo?._id === 99999 ? [branchInfo?.name?.vi] : [
        get(branchInfo, 'address.city'),
        get(branchInfo, 'address.district'),
        get(branchInfo, '_id', '')
      ];
      form.setFieldsValue({
        type: valueSelectPartner,
      });
      switch (valueSelectPartner) {
        case 'clinic':
          form.setFieldsValue({
            address: {
            cityId: get(branchInfo, 'address.cityId'),
            districtId: get(branchInfo, 'address.districtId'),
            },
            hospitalId: setHospitalId,
            dateLocal: null,
            date: null
          });
          setSelectedCityCode(get(branchInfo, 'address.cityId'));
          setSelectedHospitalId(get(branchInfo, '_id'));
          break;
        case 'partner':
          form.setFieldsValue({
            address: {
              cityId: null,
              districtId: null
            },
            hospitalId: null,
            dateLocal: null,
            date: null
          });
          setSelectedHospitalId(null);
          break;
        default:
      }
      return;
    }
  }, [appointment, appointmentId, valueSelectPartner, isOpen,isReload]);

  useEffect(() => {
    if (appointment && appointmentId) {
      setSelectedSpecialityId({
        specialityId: get(appointment, 'speciality._id'),
        cityId: get(appointment, 'address.cityId'),
        districtId: get(appointment, 'address.districtId')
      });
    };
  }, [appointment, appointmentId, brandId]);

  // useEffect(() => {
  //   form.getFieldValue('type') === 'clinic' ? form.setFieldsValue({
  //     hospitalId: 
  //   }) :   form.getFieldValue('type') === 'clinic' ? form.setFieldsValue({
  //     hospitalId:
  //   }) 
  // },[])

  // useEffect(() => {
  //   !appointmentId && setValueSelectPartner('clinic')
  // }, [isOpen, onClose]);

  // Default the value when the form changes
  const onValuesChange = ({
    specialityId,
    hospitalId,
    dateLocal,
    cityId,
    address,
    districtId,
  }) => {
    if (specialityId) {
      switch (valueSelectPartner) {
        case 'clinic':
      setSelectedHospitalId(id);
          break;
        case 'partner':
          setSelectedSpecialityId({
            specialityId,
            cityId: selectedCityCode,
            districtId: selectedDistrictCode
          }),
            form.setFieldsValue({
              hospitalId: null,
              // dateLocal: null,
              // date: null,
              name: null
            })
          break;
        default:
      }
    };
    if (hospitalId) {
      setSelectedHospitalId(hospitalId.at(-1));
      // form.setFieldsValue({
      //   dateLocal: null,
      //   date: null
      // });
    };

    if (dateLocal) {
      setAvailableTimesInDay(availableTimes[dateLocal.format(FORMAT_DATE)]);
      form.setFieldsValue({
        date: null
      });
    };
    if (address) {
      setSelectedSpecialityId({
        specialityId: form.getFieldValue(['specialityId']),
        cityId: form.getFieldValue(['address', 'cityId']),
        districtId: form.getFieldValue(['address', 'districtId'])
      });
      switch (valueSelectPartner) {
        case "clinic":
          if (appointmentId) {
            form.setFieldsValue({
            hospitalId: null,
            })
          }
          break
        case 'partner':
          form.setFieldsValue({
            hospitalId: null,
          });
          break
        default:
      };
  
      if (address.cityId) {
        form.setFieldsValue({ address:{districtId: null}})
      };
      // else { 
      // return
      // };
    }
  };

  const onFinish = (values) => {
    const {address, hospitalId,branch,dateLocal, ...rest } = values;
    const setHospitalId = checkValueSelecterPartner ? get(branchInfo, '_id') : hospitalId.at(-1);
    const localTime = values.dateLocal.format('YYYY-MM-DD') + 'T' + values.date;
    const utcTime = moment(localTime, 'YYYY-MM-DDTHH:mm');
    const dataUpdate = {
      ...rest,
      address: {
        cityId: selectedCityCode,
        districtId: selectedDistrictCode
      },
      hospitalId: Number(values.hospitalId.at(-1)),
      branchId: Number(setHospitalId),
      date: utcTime,
      specialityId: get(values, 'specialityId'),
      customerId: customerId || null
    };
    if (appointmentId) {
      if (!isCheckUpdate) {
        handleUpdate({
          ...dataUpdate,
          id: appointmentId,
        });
        setPhoneNumberSearch(null)
        onClose();
      } else {
        toastr.error('Dịch vụ đang chọn đã bị xoá, vui lòng chọn lại dịch vụ')
      };
    } else {
      const dataCreate = {
        ...rest,
        address,
        hospitalId: Number(setHospitalId),
        branchId: Number(brandId),
        date: utcTime,
        makeByWorldhealth: valueSelectPartner === 'partner' ? "WH" : "",
        customerId: customerId || null
      };
      handleCreate({
        ...dataCreate,
      });
      onClose();
      setPhoneNumberSearch(null)
    }
  };
  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  const displayRender = (labels) => labels[labels.length - 1];
  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      className="appointment-form"
      requiredMark={false}
      initialValues={initAppointment}
      onValuesChange={onValuesChange}
    >
        {!appointmentId 
          ?
         <Row gutter={36}>
          <Col span={12}>
            <FormItem label="Tạo lịch hẹn cho ai?" name={'type'}>
              {renderInput(
                (<Select
                  onSelect={(value) => setValueSelectPartner(value)}
                  defaultValue={valueSelectPartner}
                >
                  {/* <Option value="worldhealth" key="worldhealth">
                    Worldhealth
                  </Option> */}
                  <Option value="clinic" key="clinic">
                    Phòng khám của bạn
                  </Option>
                    <Option value="partner" key="partner">
                      Đối tác
                    </Option>
                </Select>)
              )}
        
            </FormItem>
          </Col>
          </Row>
        : 
        <Row gutter={36}>
          <Col span={24}>
            <FormItem label="Nguồn" name={'branchId'}>
              {renderInput(
                <Input disabled style={{color: '#333'}}/>
              )}
            </FormItem>
            </Col>
          </Row>
        }
      <Row gutter={36}>
        <Col span={12}>
          <FormItem
            label="Tên Khách hàng"
            name={'customerName'}
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập tên!'
              }
            ]}
          >
            {renderInput(<Input />)}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label="Số điện thoại"
            name={'phoneNumber'}
            rules={[
              { 
                required: true,
                // pattern: new RegExp(/^[0-9]{10,11}$/),
                // message: 'Xin vui lòng nhập đúng số điện thoại!'
              }
            ]}
          >
              {isLoading ? (
              <Skeleton.Input active />
            ) : (
                <Select
                showSearch
                placeholder="Nhập số điện thoại để tìm"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (option?.label ?? '').toLowerCase()?.includes(input.toLowerCase())
                  }
                }
                options={filterOptionNumberSearch()}
                onSearch={(e) => {
                  setPhoneNumberSearch(e)
                }}
                  onChange={(e, el) => {
                    el.fullName ? form.setFieldsValue({ customerName: el.fullName}) : ()=>{},
                    setCustomerId(el._id)
                  }}
                value={phoneNumberSearch}
              />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={48} align="middle" justify="space-between">
        <Col span={12}>
          <FormItem
            label="Thành Phố/Tỉnh"
            name={['address', 'cityId']}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
            //   }
            // ]}
          >
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
                <Select
                  allowClear
                onChange={setSelectedCityCode}
                disabled={isCitiesLoading || ( appointmentId && (get(appointment, 'type') === "worldhealth"))}
                loading={isCitiesLoading}
                showSearch
                autoComplete="off"
                  filterOption={filterAcrossAccents}
              >
                {cities.map(({ code, name }) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label="Quận/Huyện"
            name={['address', 'districtId']}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Xin vui lòng chọn Quận/Huyện!'
            //   }
            // ]}
          >
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                loading={isDistrictsLoading}
                disabled={!form.getFieldValue(['address', 'cityId']) || (appointmentId && get(appointment, 'type') === "worldhealth")}
                  onChange={(value) => {
                    setSelectedDistrictCode(value)
                  }}
                allowClear
                showSearch
                autoComplete="off"
                filterOption={filterAcrossAccents}
              >
                  {districts.map(({ code, name }) => {
                  return  (
                      <Option key={code} value={code}>
                      {name} 
                      </Option>
                    )
                  })}
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={36}>
        <Col span={12}>
          <FormItem
            label="Dịch vụ"
            name="specialityId"
            rules={[
              {
                required: true,
                message: 'Xin vui lòng chọn dịch vụ!'
              }
            ]}
          >
            {renderInput(
              <Select
                style={{color: isCheckUpdate ? 'red' : '#333'}}
                onChange={(value) => {
                  setSpecialityId(value);
                  setIsCheckUpdate(false);
                }}
                showSearch
              >
                {
                  (valueSelectPartner === 'partner' ? specialityOptions : specialityOptionsById)
                }
              </Select>
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem noStyle shouldUpdate>
            {() => (
              <FormItem
                label="Ngày"
                name="dateLocal"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn ngày!'
                  }
                ]}
                shouldUpdate
              >
                {renderInput(
                  <DatePicker
                    allowClear={false}
                    format="DD-MM-YYYY"
                    span={12}
                    loading={isAvailableTimesLoading}
                    // disabled={(!form.getFieldValue('hospitalId'))}
                    onChange={((value) => {
                      setValueDate(value)
                      setValueTime(null)
                    })}
                    disabledDate={(current) =>
                    {
                      return !selectedHospitalId  ? ( current < dayjs().endOf('day') && !availableTimes[current?.format(FORMAT_DATE)]) : ( current && !availableTimes[current?.format(FORMAT_DATE)]);
                    }
                    }
                  />
                )}
              </FormItem>
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={36}>
        <Col span={12}>
          <FormItem noStyle shouldUpdate>
            {() => (
              <FormItem
                label="Giờ"
                name="date"
                rules={[
                  selectedHospitalId ?
                  {
                    required: true,
                    message: 'Xin vui lòng chọn giờ!'
                  } :
                  {
                    required: true,
                    max: 5,
                    min: 5,
                    pattern: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$|(0[0-9]|1[0-9]|2[0-3]):(.*)$/i,
                    message: 'Xin vui nhập đúng định dạng thời gian'
                  },
                ]}
              >
                {renderInput(
                  (!selectedHospitalId )
                    ?
                    <Input
                    maxLength={5} 
                    value={valueTime}
                    onChange={(e) => {
                      let date = e.target.value;
                      form.setFieldsValue({date: date.replace(/:/i,'').replace(/(\d{2})(\d{1,2})/i,'$1:$2')})
                      setValueTime(moment(date,"HHmm").format("HH:mm"))
                    }}
                  placeholder='Vui lòng nhập thời gian 00:00' /> :
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                    loading={isHospitalLoading}
                    disabled={!form.getFieldValue('dateLocal')}
                  >
                    {availableTimesInDay &&
                      availableTimesInDay.map((time) => {
                        const currentDate = new Date();
                        const optionTime = new Date(valueDate._d?.toDateString() + ' ' + time);
                        const isDisabled = optionTime < currentDate;
                         return (
                        <Option disabled = {isDisabled} value={time} key={time}>
                          {time}
                        </Option>
                      )})}
                  </Select>
                )}
              </FormItem>
            )}
          </FormItem>
        </Col>
        <Col span={12}>
            <FormItem noStyle shouldUpdate>
              {() =>
              (<FormItem
                label="Phòng khám"
                name="hospitalId"
                dependencies={optionsPharmacies}
                rules={
                  [
                    {
                      required: form.getFieldValue("type") === 'clicic' ? false : true,
                      message: 'Xin vui lòng chọn phòng khám!'
                    }
                  ]}
              >
                {renderInput(
                  <Cascader
                    disabled={
                      !form.getFieldValue('specialityId') || (!appointmentId && valueSelectPartner === 'clinic')
                    }
                    options={optionsPharmacies}
                    expandTrigger="hover"
                    displayRender={displayRender}
                  />
                )}
              </FormItem>)
              }
            </FormItem>
          </Col>
      </Row>
      <Row gutter={36}>
        <Col span={12}>
          <FormItem label="Nguồn" name="source">
            {renderInput(
              <Select>
                <Option value="CALL" key="CALL">
                  Gọi điện
                </Option>
                <Option value="WEBSITE" key="WEBSITE">
                  Website
                </Option>
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem label="Trạng thái" name="status">
            {renderInput(
              <Select>
                <Option value="CREATED" key="CREATED">
                  Đã tạo
                </Option>
                <Option value="CONFIRMED" key="CONFIRMED">
                  Đã xác nhận
                </Option>
                <Option value="CANCEL" key="CANCEL">
                  Đã huỷ
                </Option>
                <Option value="ARRIVED" key="ARRIVED">
                  Đã hoàn thành
                </Option>
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <FormItem name="message" label="Lời nhắn">
            <Input.TextArea rows={3}></Input.TextArea>
          </FormItem>
        </Col>
      </Row>

      <Row justify="end">
        <Button style={{ marginRight: 20 }} onClick={onClose}>
          Huỷ
        </Button>
        <WithOrPermission permission={[ POLICY.WRITE_APPOINTMENT, POLICY.UPDATE_APPOINTMENT]}>
        <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
          {appointmentId ? 'Cập nhật' : 'Tạo mới'}
        </Button>
          </WithOrPermission>
      </Row>
    </Form>
  );
};

export default memo(AppointmentIsSytemForm);
