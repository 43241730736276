import * as Types from '../../constants/actionTypes';
  
  export const getExchangePoints = query => ({
    type: Types.GET_EXCHANGEPOINTS_REQUEST,
    payload: query
  });
  export const getExchangePoint = id => ({
    type: Types.GET_EXCHANGEPOINT_REQUEST,
    payload: id
  });
  export const createExchangePoint = instance => ({
    type: Types.CREATE_EXCHANGEPOINT_REQUEST,
    payload: instance
  });
  export const updateExchangePoint = instance => ({
    type: Types.UPDATE_EXCHANGEPOINT_REQUEST,
    payload: instance
  });
  export const resetExchangePointState = () => ({
    type: Types.RESET_EXCHANGEPOINT_STATE
  });
