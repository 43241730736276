import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createJob,
  deleteJob,
  getJob,
  getJobs,
  resetJobState,
  updateJob
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.job[key];
const pagingSelector = getSelector('paging');
export const useJobPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetJobSelector = getSelector('isGetJobLoading');
const getJobsFailedSelector = getSelector('getJobsFailed');
const getJobFailedSelector = getSelector('getJobFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const JobsSelector = getSelector('Jobs');
const JobSelector = getSelector('Job');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useJobQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const createJobSuccess = useSelector(createSuccessSelector);
  const deleteJobSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createJobSuccess,
    deleteJobSuccess,
  ]);
};

export const useUpdateJobParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.job.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};
export const useJobs = (query) => {
  return useFetchByParam({
    action: getJobs,
    loadingSelector,
    dataSelector: JobsSelector,
    failedSelector: getJobsFailedSelector,
    param: query
  });
};
export const useJob = (id) => {
  return useFetchByParam({
    action: getJob,
    loadingSelector: loadinggetJobSelector,
    dataSelector: JobSelector,
    failedSelector: getJobFailedSelector,
    param: id
  });
};
export const useCreateJob = (callback) => {
  
  useSuccess(createSuccessSelector, 'Tạo mới tuyển dụng thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới tuyển dụng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createJob
  });
};
export const useUpdateJob = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât tuyển dụng thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateJob
  });
};
export const useDeleteJob = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá tuyển dụng thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật tuyển dụng thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteJob
  });
};

export const useResetJob = () => {
  useResetState(resetJobState);
};
