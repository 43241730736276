import { UploadOutlined } from '@ant-design/icons';
import { Button, Spin, Upload } from 'antd';
import { get } from 'lodash';
import { BASE_URL } from '~/constants/defaultValue';
import toastr from 'toastr';
import { useState } from 'react';
import './index.scss'
const DEFAULT_ACTION = `${BASE_URL}/api/v1/course-training-partner/file`;
const UploadVideo = ({ action = DEFAULT_ACTION, value, onChange,...props }) => {
  const [loading, setLoading] = useState(false);
  const beforeUpload = (file) => {
    const isLtMaxFileSize = file.size > 520000; // 500kb
    if (isLtMaxFileSize) {
      toastr.error("File quá lớn vui lòng tải file < 500kb");
      return false;
    }
  }
  const handleChange = (info) => {
    setLoading(true);
    if (info.file.status === 'done') {
      setLoading(false);
      onChange(get(info.file.response, 'url'))
    } else if (info.file.status === 'error') {
      setLoading(false);
      console.error('File upload failed:', info.file.error);
    }
    setLoading(false);
  };

  const handleRemove = (e) => {
    e.stopPropagation();
    onChange(null)
  }
  return (
    <Upload
      multiple={false}
      beforeUpload={beforeUpload}
      accept="video/*"
      action={action}
      onChange={handleChange}
      showUploadList={false}
      {...props}
    >
      {
        loading ? <Spin spinning /> : value ? <div className="upload-video">
          <Button size='small' onClick={handleRemove} danger className='upload-video--action'>X</Button>
          <video style={{ width: 600, height: 350, objectFit: 'cover' }} controls>
            <source src={value} type="video/mp4" />
            <Button style={{ position: 'absolute', top: 0, right: 0, zIndex: 5 }}>X</Button>
          </video>
        </div> : <Button loading={loading} icon={<UploadOutlined />}>Tải Video lên {"(<500kb)"}</Button>
      }


    </Upload>
  );
};

export default UploadVideo;
