import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getSelectors,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createExchangePoint,
  getExchangePoint,
  getExchangePoints,
  resetExchangePointState,
  updateExchangePoint
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.exchangePoint[key];
const pagingSelector = getSelector('paging');
export const useExchangePointPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetExchangePointSelector = getSelector('isGetExchangePointLoading');
const getExchangePointsFailedSelector = getSelector('getExchangePointsFailed');
const getExchangePointFailedSelector = getSelector('getExchangePointFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const ExchangePointsSelector = getSelector('ExchangePoints');
const ExchangePointSelector = getSelector('ExchangePoint');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
export const useExchangePointQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;

  const keyword = query.get('keyword');
  const createExchangePointSuccess = useSelector(createSuccessSelector);
  const updateExchangePointSuccess = useSelector(updateSuccessSelector);
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createExchangePointSuccess,
    updateExchangePointSuccess
  ]);
};

export const useUpdateExchangePointParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/exchange-point',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};
export const useExchangePoints = (query) => {
  return useFetchByParam({
    action: getExchangePoints,
    loadingSelector,
    dataSelector: ExchangePointsSelector,
    failedSelector: getExchangePointsFailedSelector,
    param: query
  });
};
export const useExchangePoint = (id) => {
  return useFetchByParam({
    action: getExchangePoint,
    loadingSelector: loadinggetExchangePointSelector,
    dataSelector: ExchangePointSelector,
    failedSelector: getExchangePointFailedSelector,
    param: id
  });
};
export const useCreateExchangePoint = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo Điểm quy đổi thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createExchangePoint
  });
};
export const useUpdateExchangePoint = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật Điểm quy đổi thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateExchangePoint
  });
};

export const useResetExchangePoint = () => {
  useResetState(resetExchangePointState);
};
