import * as Types from '../../constants/actionTypes';


 //board
export const getBoards = (query) => ({
  type: Types.GET_BOARDS_REQUEST,
  payload: query
})
//get by id
export const getBoardById = (id) => ({
  type: Types.GET_BOARD_BY_ID_REQUEST,
  payload: id
})
export const getBoardGroup = (id) => ({
  type: Types.GET_BOARD_GROUP_REQUEST,
  payload: id
})
//create
export const createBoard = (params) => ({
  type: Types.CREATE_BOARD_REQUEST,
  payload: params
})
//update
export const updateBoard = (params) => ({
  type: Types.UPDATE_BOARD_REQUEST,
  payload: params
})
//delete
export const deleteBoard = (id) => ({
  type: Types.DELETE_BOARD_REQUEST,
  payload: id
})
//work Flow
export const getWorkList = (query) => ({
    type: Types.GET_WORK_LIST_REQUEST,
    payload: query
  });
export const getListBoardConfig = (query) => ({
    type: Types.GET_LIST_BOARD_CONFIG_REQUEST,
    payload: query
  });
  export const filterBoardConfigItem = (query) => ({
    type: Types.FILTER_BOARD_CONFIG_ITEM_REQUEST,
    payload: query
  })
  export const getWorkById = (id) => ({
    type: Types.GET_WORK_LIST_BY_ID_REQUEST,
    payload: id
  });
  
  export const createWork = (params) => ({
    type: Types.CREATE_WORK_REQUEST,
    payload: params
  });
  
  export const updateWork = (params) => ({
    type: Types.UPDATE_WORK_REQUEST,
    payload: params
  });
  export const deleteWork = (id) => ({
    type: Types.DELETE_WORK_REQUEST,
    payload: id
  })


// manager||staff 

export const getListManagers = (query) => (
  {
  type: Types.GET_LIST_MANAGERS_REQUEST,
  payload: query
})
export const getListStaffs = (query) => ({
  type: Types.GET_LIST_STAFFS_REQUEST,
  payload: query
})

export const getListManagersByIdBoard = (id) => ({
  type: Types.GET_LIST_MANAGERS_BY_ID_BOARD_REQUEST,
  payload: id
})
export const getListStaffsByIdBoard = (id) => ({
  type: Types.GET_LIST_STAFFS_BY_ID_BOARD_REQUEST,
  payload: id
})
export const getListTaskBySprints = (query) => ({
  type: Types.GET_LIST_TASK_BY_SPRINTS_REQUEST,
  payload: query
})
