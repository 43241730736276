import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getTimeSheetsFailed: null,
  getTimeSheetFailed:null,
  timeSheets: [],
  timeSheet: null,
  isSubmitLoading : false,
  isGetTimeSheetLoading: false,
  TimeSheet: null,

  loadingUpdateCourse : false,
  updateFailedCourse : null,
  updateSuccessCourse : null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_TIME_SHEETS_REQUEST:
      state.isLoading = true;
      state.getTimeSheetsFailed = null;
      return;

    case Types.GET_TIME_SHEETS_SUCCESS:
      state.isLoading = false;
      const mergeDocsCourse = get(payload,'docs',[]).map(doc => {
        let result = []
        get(doc,'ids',[]).forEach(id => get(payload,'courseTraning',[]).forEach(course => {
          if(get(course,'_id','') === id){
            result.push(course)
          }
        }))
        return {...doc,course : result}
      })
      state.timeSheets = mergeDocsCourse;
      state.getTimeSheetsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_TIME_SHEETS_FAILED:
      state.isLoading = false;
      state.timeSheets = [];
      state.getTimeSheetsFailed = payload;
      return;
    case Types.GET_TIME_SHEET_REQUEST:
      state.isGetTimeSheetLoading = true;
      state.timeSheet = null
      state.getTimeSheetFailed = null;
      return;

    case Types.GET_TIME_SHEET_SUCCESS:
      state.isGetTimeSheetLoading = false;
      state.timeSheet = payload;
      state.getTimeSheetFailed = null;
      return;

    case Types.GET_TIME_SHEET_FAILED:
      state.isGetTimeSheetLoading = false;
      state.timeSheet = null;
      state.getTimeSheetFailed = payload;
      return;
    case Types.CREATE_TIME_SHEET_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_TIME_SHEET_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_TIME_SHEET_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_TIME_SHEET_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_TIME_SHEET_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.timeSheet = payload;
        return;
  
      case Types.UPDATE_TIME_SHEET_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;

      case Types.UPDATE_COURSE_TRAINING_IN_TIME_SHEET_REQUEST:
        state.loadingUpdateCourse = true;
        state.updateFailedCourse = null;
        state.updateSuccessCourse = null;
        return;
  
      case Types.UPDATE_COURSE_TRAINING_IN_TIME_SHEET_SUCCESS:
        state.loadingUpdateCourse = false;
        state.updateSuccessCourse = payload
        state.timeSheets = state.timeSheets.map(e => {
          let newTimeSheet = null;
          const newCourse = get(e,'course',[]).map(course => {
            if(course._id === payload.data._id){
              newTimeSheet = get(payload,'data.timeSheetUpdate')
              return payload.data
            }
            return course
          })
          if(newTimeSheet){
            return {...e,totalReceived:get(newTimeSheet,'totalReceived',0),totalHours : get(newTimeSheet,'totalHours',0),course: newCourse}
          }
          return {...e,course: newCourse}
        })
        return;
  
      case Types.UPDATE_COURSE_TRAINING_IN_TIME_SHEET_FAILED:
        state.loadingUpdateCourse = false;
        state.updateFailedCourse = payload;
        return;
      case Types.DELETE_TIME_SHEET_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_TIME_SHEET_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_TIME_SHEET_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_TIME_SHEET_STATE:
      return initState;

    default:
      return;
  }
}, initState);
