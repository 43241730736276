import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createListSoftware,
  deleteListSoftware,
  getListSoftware,
  getListSoftwares,
  resetListSoftwareState,
  updateListSoftware
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.listSoftware[key];
const pagingSelector = getSelector('paging');
export const useListSoftwarePaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetListSoftwareSelector = getSelector('isGetListSoftwareLoading');
const getListSoftwaresFailedSelector = getSelector('getListSoftwaresFailed');
const getListSoftwareFailedSelector = getSelector('getListSoftwareFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const ListSoftwaresSelector = getSelector('ListSoftwares');
const ListSoftwareSelector = getSelector('ListSoftware');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useListSoftwareQueryParams = (reFetch) => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const createListSoftwareSuccess = useSelector(createSuccessSelector);
  const updateListSoftwareSuccess = useSelector(updateSuccessSelector);
  const deleteListSoftwareSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createListSoftwareSuccess,
    deleteListSoftwareSuccess,
    updateListSoftwareSuccess,
    reFetch,
  ]);
};

export const useUpdateListSoftwareParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.software.listSoftWare,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};

export const useCreateListSoftware = (callback) => {
  
  useSuccess(createSuccessSelector, 'Tạo mới phần mềm thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới phần mềm thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createListSoftware
  });
};

export const useListSoftwares = (query) => {
  return useFetchByParam({
    action: getListSoftwares,
    loadingSelector,
    dataSelector: ListSoftwaresSelector,
    failedSelector: getListSoftwaresFailedSelector,
    param: query
  });
};
export const useListSoftware = (id) => {
  return useFetchByParam({
    action: getListSoftware,
    loadingSelector: loadinggetListSoftwareSelector,
    dataSelector: ListSoftwareSelector,
    failedSelector: getListSoftwareFailedSelector,
    param: id
  });
};
export const useUpdateListSoftware = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât phần mềm thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateListSoftware
  });
};
export const useDeleteListSoftware = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá phần mềm thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật phần mềm thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteListSoftware
  });
};

export const useResetListSoftware = () => {
  useResetState(resetListSoftwareState);
};
