import * as Types from '../../constants/actionTypes';
  
  export const getIntroUsingRefCodes = query => ({
    type: Types.GET_INTRO_USING_REF_CODES_REQUEST,
    payload: query
  });
  export const getIntroUsingRefCode = id => ({
    type: Types.GET_INTRO_USING_REF_CODE_REQUEST,
    payload: id
  });
  export const createIntroUsingRefCode = instance => ({
    type: Types.CREATE_INTRO_USING_REF_CODE_REQUEST,
    payload: instance
  });
  export const updateIntroUsingRefCode = instance => ({
    type: Types.UPDATE_INTRO_USING_REF_CODE_REQUEST,
    payload: instance
  });
  export const deleteIntroUsingRefCode = id => ({
    type: Types.DELETE_INTRO_USING_REF_CODE_REQUEST,
    payload: id
  });
  export const resetIntroUsingRefCodeState = () => ({
    type: Types.RESET_INTRO_USING_REF_CODE_STATE
  });
  export const resetIntroUsingRefCodeStateACtion = () => ({
    type: Types.RESET_INTRO_USING_REF_CODE_STATE_ACTION
  });
