import {
  // APPROVE_WH_RECEIPT_VOUCHER_REQUEST,
  // GET_WH_RECEIPT_VOUCHER_GROUPS_REQUEST
  // INVITE_WH_RECEIPT_VOUCHER_REQUEST,
  CREATE_WH_RECEIPT_VOUCHER_REQUEST,
  CREATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_REQUEST,
  DELETE_WH_RECEIPT_VOUCHER_REQUEST,
  GET_WH_RECEIPT_VOUCHERS_REQUEST,
  GET_WH_RECEIPT_VOUCHER_REQUEST,
  RESET_WH_RECEIPT_VOUCHER_STATE,
  UPDATE_WH_RECEIPT_VOUCHER_REQUEST,
  UPDATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_REQUEST,
  CONFIRM_WH_RECEIPT_VOUCHER_REQUEST,
  APPROVE_WH_RECEIPT_VOUCHER_REQUEST,
  REJECT_WH_RECEIPT_VOUCHER_REQUEST,
  RESET_WH_RECEIPT_VOUCHER_STATE_ACTION,
} from '../../constants/actionTypes';

export const getWhReceiptVouchers = query => ({
  type: GET_WH_RECEIPT_VOUCHERS_REQUEST,
  payload: query
});

export const getWhReceiptVoucher = id => ({
  type: GET_WH_RECEIPT_VOUCHER_REQUEST,
  payload: id
});

export const createWhReceiptVoucher = instance => ({
  type: CREATE_WH_RECEIPT_VOUCHER_REQUEST,
  payload: instance
});

export const createWhReceiptVoucherByWhPartner = instance => ({
  type: CREATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_REQUEST,
  payload: instance
});

// export const inviteWhReceiptVoucher = ({ id, newWhReceiptVoucher }) => ({
//   type: INVITE_WH_RECEIPT_VOUCHER_REQUEST,
//   payload: { id, newWhReceiptVoucher },
// });

export const updateWhReceiptVoucher = instance => ({
  type: UPDATE_WH_RECEIPT_VOUCHER_REQUEST,
  payload: instance
});

export const updateWhReceiptVoucherByWhPartner = instance => ({
  type: UPDATE_WH_RECEIPT_VOUCHER_BY_WH_PARTNER_REQUEST,
  payload: instance
});

export const confirmWhReceiptVoucher = instance => ({
  type: CONFIRM_WH_RECEIPT_VOUCHER_REQUEST,
  payload: instance
});

export const approveWhReceiptVoucher = instance => ({
  type: APPROVE_WH_RECEIPT_VOUCHER_REQUEST,
  payload: instance
});

export const rejectWhReceiptVoucher = instance => ({
  type: REJECT_WH_RECEIPT_VOUCHER_REQUEST,
  payload: instance
});

export const deleteWhReceiptVoucher = id => ({
  type: DELETE_WH_RECEIPT_VOUCHER_REQUEST,
  payload: id
});

export const resetWhReceiptVoucherState = () => ({
  type: RESET_WH_RECEIPT_VOUCHER_STATE
});

// export const getWhReceiptVoucherGroups = () => ({
//   type: GET_WH_RECEIPT_VOUCHER_GROUPS_REQUEST
// });

export const resetWhReceiptVoucherStateAction = () => ({
  type: RESET_WH_RECEIPT_VOUCHER_STATE_ACTION
});
