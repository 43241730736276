import { CheckCircleOutlined, CheckOutlined, SendOutlined, SmileOutlined } from '@ant-design/icons';
import { Button, Empty, Table, Tag, Tooltip, Typography } from 'antd'
import { get } from 'lodash'
import React, { useCallback } from 'react'
import { BaseBorderBox } from '~/components/Common';
import { checkErrorSelectWhAppointment } from '~/hooks/confirmVoucherWarehouse';
export default function TableFetchWhAppointment({ dataSource, handleSelect, loading, selectList = [],whPartner }) {
    const checkSelected = useCallback((record) => {
        return selectList?.some(item => get(item, '_id') === get(record, '_id'));
    }, [selectList]);
    const columns = [
        {
            title: "Mã cuộc hẹn",
            dataIndex: 'code',
            key: 'code',
            render : (code,record) =><Typography.Link onClick={() => window.open(`wh-bill-item/${get(record,'whBillItemId')}/update-appointments`)} strong>{code}</Typography.Link>
        },
        {
            title: "Tên đối tác",
            dataIndex: 'whPartner',
            key: 'whPartner',
            render: (whPartner) => get(whPartner, 'name', '')
        },
        {
            title: "Thao tác",
            key: 'action',
            align: 'center',
            render: (_, record) =>{
                const {status,msg} = checkErrorSelectWhAppointment({...record,whPartnerSelected:whPartner});
                return  checkSelected(record) 
                ?  <Tag className='tagChecked' icon={<CheckCircleOutlined className='checked'/>} color="success">Đã chọn</Tag> 
                : 
                <Tooltip zIndex={9999} title={msg}>
                <Button disabled={status} onClick={() => handleSelect(record)}><SendOutlined /></Button>
                </Tooltip>
            }
        }
    ]
    return (
        <div style={{ marginTop: 20 }}>
            <BaseBorderBox title={"Danh sách cuộc hẹn sẵn sàng"}>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    size='small'
                    loading={loading}
                    locale={{
                        emptyText: <Empty description='Không có dữ liệu'>
                        </Empty>
                    }}
                />
            </BaseBorderBox>
        </div>
    )
}
