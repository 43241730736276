import { get, has } from "lodash";

import { INSTANCE_STATUSES } from "~/constants/defaultValue";

/**
 * Convert an object received from API to the internal object that can work with Antd Form.
 *
 * @param {*} instance
 * @return {*} 
 */
const fromJSON = instance => {
  return {
    ...instance,
    id: get(instance, "_id") || get(instance, "id"),
  }
}

/**
 * Convert an internal object to the format of API.
 *
 * @param {*} instance
 * @return {*} 
 */
const toJSON = instance => {
  return {
    ...instance,
    packages: get(instance, 'packages')?.map(item => ({
      ...item,
      status: get(item, 'status')
        ? INSTANCE_STATUSES.ACTIVE
        : INSTANCE_STATUSES.INACTIVE,
      sessionPrices: get(item, 'sessionPrices')
        ?.filter(item => get(item, 'status') || get(item, '_id'))
        ?.map(item => ({
          ...item,
          status: get(item, 'status')
            ? INSTANCE_STATUSES.ACTIVE
            : INSTANCE_STATUSES.INACTIVE,
        }))
    })),
    ...(has(instance, "status") && {
      status: get(instance, "status")
        ? INSTANCE_STATUSES.ACTIVE
        : INSTANCE_STATUSES.INACTIVE
    }),
  }
}

export {
  fromJSON,
  toJSON,
}
