import { CaretDownOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  List,
  Menu,
  Modal,
  Row,
  Table,
  Tag,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, {  useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import LinkFile from '~/components/Common/TypeFile/LinkFileImage';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import LoadingWhBill from '~/components/WhBill/LoadingWhBill';
import FormCreate from '~/components/Workspace/WhManagerTrainingCourse.js/FormCreate';
import { TYPE_FILE, WH_COURSE_TRAINING_STATUS } from '~/constants/defaultValue';
import { useUserWorkspace } from '~/hooks';
import {
  IconAction,
  useDeleteTrainingCoursePartner,
  useLogCourseTraining,
  useResetTrainingCoursePartner,
  useTrainingCoursePartner,
  useTrainingCoursePartnerPaging,
  useTrainingCoursePartnerQueryParams,
  useTrainingCoursesOfPartner,
  useTrainingCoursesPartner,
  useUpdateTrainingCoursePartnerParams
} from '~/hooks/trainingCoursePartner';
export default function TrainingCoursePartnerForm() {
  const {id : idPartner} = useParams()
  const [profile] = useUserWorkspace();
  const [id, setId] = useState(null);
  const [OneTrainingCourse, loadingOneTrainingCourse] =
    useTrainingCoursePartner(id);
  const [notes, LoadingNote] = useLogCourseTraining(idPartner);
  const [idDelete, setIdDelete] = useState(null);
  const [query] = useTrainingCoursePartnerQueryParams(idPartner);
  const paging = useTrainingCoursePartnerPaging(query);
  useResetTrainingCoursePartner();
  const [{ onParamChange }] = useUpdateTrainingCoursePartnerParams(query);
  const [trainingCourses, isLoadingTraining] = useTrainingCoursesPartner(
    idPartner
  );
  const [trainingCoursesOfPartner, isLoadingTrainingOfPartner] =
    useTrainingCoursesOfPartner(query);
  const [ismodalCreateVisible, setIsmodalCreateVisible] = useState(false);
  const [isOpenModalDelete, setIsOpenDelete] = useState(false);

  const onCancelDelete = () => {
    setIsOpenDelete(false);
    setIdDelete(null);
  };

  const [loadingDelete, deleteTrainingCoursePartner] =
    useDeleteTrainingCoursePartner(onCancelDelete);
  const onConfirmDelete = () => {
    deleteTrainingCoursePartner({id:idDelete,typeOf : 'admin',userIdAction : get(profile,'_id')});
  };
  const handleCloseModalCreate = () => {
    setIsmodalCreateVisible(false);
    setId(null);
  };
  const onOpenDelete = (id) => {
    setIsOpenDelete(true);
    setIdDelete(id);
  };
  const onchangeParamCustomePath = (param) => {
    onParamChange({customPathName:`/wh-partner/edit/${idPartner}`,...param})
  }
  const onChangeSearchMonth = (date, dateString) => {
    onchangeParamCustomePath({ month: dateString,date:null });
  };
  const onChangeSearchDate = (date, dateString) => {
    onchangeParamCustomePath({ date:dateString,month:null });
  };
  const columns = [
    {
      title: 'STT',
      dataIndex: null,
      key: null,
      render: (text, record, index) => <p>{index + 1}</p>
    },
    {
      title: 'ID khoá đào tạo',
      dataIndex: 'courseId',
      key: 'courseId',
      render: (text, record, index) => <p>{get(text, 'courseIdFull', '')}</p>
    },
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => (
        <p>{moment(text).format('DD/MM/YYYY') || ''}</p>
      )
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
      render: (text, record, index) => <p>{text || ''}</p>
    },
    {
      title: 'File đính kèm',
      dataIndex: 'files',
      key: 'files',
      render: (text, record, index) => (
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'start'
          }}
        >
          {text?.map((e) => {
                return (
                  <LinkFile
                    key={v4()}
                    href={get(e, 'response.url')}
                    url={get(e, 'name')}
                    // type={get(e, 'type')}
                    // image={key}
                  />
                );
            {/* for (const [key, value] of Object.entries(TYPE_FILE)) {
              if (e.type.includes(value)) {
              }
            } */}
          })}
        </div>
      )
    },
    {
      title: 'Số giờ',
      dataIndex: 'time',
      align: 'center',
      key: 'time',
      render: (text, record, index) => <h6>{text || ''}</h6>
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record, index) => (
        <span className={`course-status ${text}`}>{text || ''}</span>
      )
    },
    {
      title: 'Thao tác',
      dataIndex: null,
      key: null,
      render: (text, record, index) => (
        <Dropdown
          overlayStyle={{ width: 150 }}
          trigger={['click']}
          placement="bottomCenter"
          overlay={
            <Menu>
              <Menu.Item
                disabled={record.status !== WH_COURSE_TRAINING_STATUS.NEW}
                key={v4()}
                onClick={() => {
                  setId(record._id);
                  setIsmodalCreateVisible(true);
                }}
              >
                <p>Cập nhật</p>
              </Menu.Item>
              <Menu.Item
                disabled={record.status !== WH_COURSE_TRAINING_STATUS.NEW}
                key={v4()}
                onClick={() => onOpenDelete(record._id)}
              >
                <p>Xoá</p>
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
      )
    }
  ];
  return (
    <div>
    <Modal
        footer={null}
        visible={ismodalCreateVisible}
        closable={false}
        onCancel={handleCloseModalCreate}
      >
        <FormCreate
          id={id}
          profile={profile}
          onParamChange={onchangeParamCustomePath}
          OneTrainingCourse={OneTrainingCourse}
          loadingOneTrainingCourse={loadingOneTrainingCourse}
          trainingCourses={trainingCourses}
          isLoadingTraining={isLoadingTraining}
          handleCloseModalCreate={handleCloseModalCreate}
          typeOf='admin'
        />
      </Modal>
      <Modal
        style={{ textAlign: 'center' }}
        visible={isOpenModalDelete}
        onCancel={onCancelDelete}
        closable={false}
        footer={null}
      >
        <h5>Bạn có muốn xoá ?</h5>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <Button onClick={onCancelDelete}>Huỷ</Button>
          </Col>
          <Col span={12}>
            <Button
              loading={loadingDelete}
              onClick={onConfirmDelete}
              type="danger"
            >
              Xoá
            </Button>
          </Col>
        </Row>
      </Modal>
      <Row style={{ alignItems: 'center',width:'350px', margin: '10px 0',justifyContent : 'space-between' }}>
        <h6 style={{ marginRight: '10px' }}>Tìm Theo Tháng</h6>
        <DatePicker
          onChange={onChangeSearchMonth}
          value={get(query,'month')  ? moment(get(query,'month')) : null}
          placeholder="Chọn tháng"
          picker="month"
          disabledDate={(current) => parseInt(moment(current).format("YYYY")) !== moment().year()}
        />
      </Row>
      <Row style={{ alignItems: 'center',width:'350px', margin: '10px 0',justifyContent : 'space-between' }}>
        <h6 style={{ marginRight: '10px' }}>Tìm Theo ngày</h6>
        <DatePicker
          onChange={onChangeSearchDate}
          placeholder="Chọn ngày"
          value={get(query,'date')  ? moment(get(query,'date')) : null}
          picker="date"
          disabledDate={(current) => parseInt(moment(current).format("YYYY")) !== moment().year()}
        />
      </Row>
      {isLoadingTrainingOfPartner ? (
        <SkeletonTable columns={columns} rowCount={10} />
      ) : (
        <Table
          rowKey={(rc) => rc._id}
          columns={columns}
          dataSource={trainingCoursesOfPartner || []}
          onChange={({ current, pageSize }) =>
            onParamChange({ page: current, limit: pageSize })
          }
          pagination={{
            ...paging,
            showTotal: (total) => `Tổng cộng: ${total} `
          }}
          size="middle"
        />
      )}
      <div style={{ position: 'relative' }}>
        <h5>Ghi chú</h5>
        {LoadingNote ? (
          <LoadingWhBill open={LoadingNote} />
        ) : (
          
          <List
          style={{maxHeight : '500px' , overflowY : 'scroll'}}
      bordered
      dataSource={notes || []}
      renderItem={e => (
        <List.Item >
          <Row style={{width : '100%'}} justify="space-between">
                <Col span={4}>
                  <Tag color="blue">
                    {moment(get(e, 'date')).format('HH:mm DD/MM/YYYY')}
                  </Tag>
                </Col>
                <Col span={4}>
                  {!!get(e,'desc') ? <Tag color="red">
                       {get(e, 'desc.name')}
                  </Tag> :
                  <Tag color="magenta">
                       {get(e, 'userId.fullName')}-admin
                  </Tag>}
                </Col>
                <Col span={16}>
                  <span>{IconAction[get(e,'action')]}{get(e, 'message')}</span>
                </Col>
              </Row>  
        </List.Item>
      )}
    ></List>
        )}
      </div>
    </div>
  );
}
