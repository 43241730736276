import { Button, Col, Form, Input, Row, Switch } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { get, head } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { ButtonGroup } from 'reactstrap'
import { WithPermission } from '~/components/Common'
import POLICIES from '~/constants/policy'
import { ConvertDescripton, HandleCheckValue } from '../Handler'

const ItemInput = ({label,title,type=true,disabled})=>(
    <Form.Item name={title} label={label}>
        {type?
        <Input disabled={!disabled}/>:
        <TextArea  rows={13} disabled={!disabled}className='textarea' />}
    </Form.Item>)

function CardItem({data,index,handleUpdateWhPackageLevel}) {
    // value to data alway valid in first render 
    const { name,description } = data
    const [form]=Form.useForm()
    const [_description,_price] = ConvertDescripton(description)
    const [valueForm,setValueForm]= useState({name:name,description:_description, price:_price})
    const [isChange,setIsChange]=useState(false)
    const [language,setCheckLanguage]=useState("vi")
    const [handleCheck]=HandleCheckValue()

    const onSubmit = useCallback((value)=>{
        const dataCheck = { ... data, description:_description,price:_price}
        const check = handleCheck(dataCheck,valueForm,setIsChange,handleUpdateWhPackageLevel)
        if(check===true){ return }
        else{
            setIsChange((cur)=>!cur)
        }

    },[_description,_price,name,valueForm])
    const handleChangeField = useCallback(([_data])=>{
            const _name = head(get(_data,"name"))
            const _value= get(_data,"value")
            
            setValueForm((currentValue)=>({ 
                ...currentValue , 
                [_name] :{
                    ...currentValue[_name],
                    [language]:_value
                }
            }))
    },[language])

    const switchLanguage=useCallback((e)=>{
        setCheckLanguage(!e?"vi":"en")
    },[])

    
    const handleSetField = useCallback((callback)=>{
        // note  -- callback -- : is a func to action reset field
        //  case 1 callback valid  
        //    - action 1.1 setvalueForm  to  props.data 
        //    - action 1.2 setFieldvForm  to props.data
        
        //  case 2 callback inValid
        //      case 2.1. first time render  
        //      -  action 1 setFieldForm with value to props.data
        //      case 2.2. change language
        //      -  action 1 setFieldForm with value for valueForm

        if(!!callback){
            setValueForm((current)=>({...current,name:name,description:_description, price:_price}))
            form.setFieldsValue({   
                    name: name[language] , 
                    description: _description?.[language] ,
                    price:_price[language]
                })
            callback()
        }else{
            form.setFieldsValue({ 
                    name: valueForm?.name?.[language],
                    description: valueForm?.description?.[language], 
                    price: valueForm?.price?.[language]
                })
        } 
    },[language,valueForm,_price,_description,name])  

//auto set value form 
    useEffect(()=>{
        handleSetField()
    },[data,language])     
    return (
        <div className='cardItemPackage' style={{"--inDelay":`${index*0.5}s`}}>
            <Form
            form={ form }
            onFinish={ onSubmit }
            layout="vertical"
            onFieldsChange={ handleChangeField }
            >
                <Row>
                    <Col span={24}>
                        <Input.Group compact size="default">
                                <Col span={14}>
                                    <ItemInput  disabled={isChange} title={"name"} label={"Tên gói :"}></ItemInput>
                                </Col>
                                <Col span={10}>
                                    <ItemInput disabled={isChange} title={"price"} label={"Giá :"}></ItemInput>
                                </Col>
                        </Input.Group>
                    </Col>
                </Row>
                <ItemInput disabled={isChange} title={"description"} label={"Thông tin :"} type={false}></ItemInput>
                <Switch onClick={ switchLanguage } checkedChildren="EN" unCheckedChildren="VI" />
                <WithPermission permission={POLICIES.UPDATE_WHPACKAGELEVEL}>
                    <ButtonGroup className='cardItemPackage-btn'>
                        {
                            !isChange  ? 
                                <Button type='primary'  onClick={()=>{setIsChange(!isChange)}} htmlType='button'>Sửa</Button>
                                :<>
                                    <Button onClick={()=>{handleSetField(()=>setIsChange(!isChange))}} htmlType='button'>Hủy</Button>
                                    <Button type='primary' htmlType='submit'>Lưu</Button>
                                </>
                        }
                    </ButtonGroup>
                </WithPermission>
            </Form>
        </div>
    )
}

export default CardItem
