import { useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useCustomFetch
} from '~/hooks/utils';
import {
  getAllOrders,
  getPendingOrders,
  addMedicineToOrder,
  updateOrderMedicine,
  deleteOrderMedicine,
  getOrder,
  resetOrderState,
  deleteOrder,
  getShippingVendor
} from '~/redux/action';
import { getExistProp } from '~/utils/helper';

const getSelector = (key) => (state) => state.order[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('allOrders');
const getListFailedSelector = getSelector('getListFailed');

const pendingOrdersLoadingSelector = getSelector('isGetPendingOrdersLoading');
const pendingOrdersSelector = getSelector('pendingOrders');
const getPendingOrderFailedSelector = getSelector('getPendingOrdersFailed');

const getByIdLoadingSelector = getSelector('isGetByIdLoading');
const getByIdSelector = getSelector('byId');
const getByIdFailedSelector = getSelector('getByIdFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitMedicineLoadingSelector = getSelector('isSubmitMedicineLoading');

const shippingVendorsLoadingSelector = getSelector(
  'isGetShippingVendorsLoading'
);
const shippingVendorsSelector = getSelector('shippingVendors');
const getShippingVendorsFailedSelector = getSelector(
  'getshippingVendorsFailed'
);

const pagingSelector = getSelector('paging');

export const useAllOrdersQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const status = query.get('status');
  const startTime = query.get('startTime');
  const endTime = query.get('endTime');
  // const page = query.get('page');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };
  
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      status,
      startTime,
      endTime
    });

    return [queryParams, onTableChange];
  }, [page, limit, keyword, status, startTime, endTime, deleteSuccess]);
};

export const useUpdateOrderParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    history.push({
      pathname: '/all-orders',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useAllOrders = (query) => {
  return useFetchByParam({
    action: getAllOrders,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const usePendingOrders = () => {
  return useCustomFetch({
    action: getPendingOrders,
    loadingSelector: pendingOrdersLoadingSelector,
    dataSelector: pendingOrdersSelector,
    failedSelector: getPendingOrderFailedSelector
  });
};

export const useOrderPaging = () => useSelector(pagingSelector);

export const useAddMedicineToOrder = () => {
  // useSuccess(addMedicineSuccessSelector, 'Thêm thuốc thành công');
  // useFailed(addMedicineFailedSelector, 'Thêm thuốc thất bại');

  return useSubmit({
    loadingSelector: isSubmitMedicineLoadingSelector,
    action: addMedicineToOrder
  });
};

export const useUpdateOrderMedicine = () => {
  // useSuccess(updateMedicineSuccessSelector, 'Cập nhật thuốc thành công');
  // useFailed(updateMedicineFailedSelector, 'Cập nhật thuốc thất bại');

  return useSubmit({
    loadingSelector: isSubmitMedicineLoadingSelector,
    action: updateOrderMedicine
  });
};

export const useDeleteOrder = () => {
  useSuccess(deleteSuccessSelector, 'Xoá đơn hàng thành công');
  useFailed(deleteFailedSelector, 'Xoá đơn hàng thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteOrder
  });
};

export const useDeleteOrderMedicine = () => {
  // useSuccess(deleteOrderMedicineSuccessSelector, 'Xoá thuốc thành công');
  // useFailed(deleteOrderMedicineFailedSelector, 'Xoá thuốc thất bại');

  return useSubmit({
    loadingSelector: isSubmitMedicineLoadingSelector,
    action: deleteOrderMedicine
  });
};

export const useOrder = (id) => {
  return useCustomFetch({
    action: getOrder,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id
  });
};

export const useShippingVendor = (query) => {
  return useFetchByParam({
    action: getShippingVendor,
    loadingSelector: shippingVendorsLoadingSelector,
    dataSelector: shippingVendorsSelector,
    failedSelector: getShippingVendorsFailedSelector,
    param: query
  });
};

export const useResetOrder = () => {
  useResetState(resetOrderState);
};
