import produce from 'immer';
import { clone, get, sortBy, uniqBy } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoadingHistory: false,
  getListHistoryFailed: undefined,
  listHistory: [],


  isLoading: false,
  getListFailed: undefined,
  list: [],
  listBoard: [],


  isLoadingBoardGroup:false,
  listBoardGroup: [],
  getBoardGroupFailed: undefined,

  dataBoardConfig: {},

  isLoadingTask: false,
  getListTaskFailed: undefined,
  listTask: [],

  listManagers: [],
  isLoadingManager: false,
  getListManagerFailed: undefined,

  listManagersByBoard: [],
  isLoadingManagerByBoard: false,
  getListManagerByBoardFailed: undefined,

  listTaskBySprints: [],
  isLoadingTaskBySprints: false,
  getListTaskBySprintsFailed: undefined, 

  listTaskFilter:[],
  isLoadingFilterTask: false,
  getListTaskFilter:undefined,

  listStaffs: [],
  isLoadingStaff: false,
  getListStaffFailed: undefined,

  listStaffsByIdBoard: [],
  isLoadingStaffByBoard: false,
  getListStaffByBoardFailed: undefined,
  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null




};
export default produce((state, { type, payload }) => {
  switch (type) {

    //board
    case Types.GET_BOARDS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;
    case Types.GET_BOARDS_SUCCESS:
      state.isLoading = false;
      state.listBoard = payload;
      // state.paging = getPaging(payload);
      return;
    case Types.GET_BOARDS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.listBoard = [];
      return;
    //board
    case Types.GET_BOARD_GROUP_REQUEST:
      state.isLoadingBoardGroup = true;
      state.getBoardGroupFailed = null;
      return;
    case Types.GET_BOARD_GROUP_SUCCESS:
      state.isLoadingBoardGroup = false;
      state.listBoardGroup = payload;
      // state.paging = getPaging(payload);
      return;
    case Types.GET_BOARD_GROUP_FAILED:
      state.isLoadingBoardGroup = false;
      state.getBoardGroupFailed = payload;
      state.listBoardGroup = [];
      return;
    case Types.GET_BOARD_BY_ID_REQUEST:
      state.isGetByIdLoading = true;
      state.getByIdFailed = null;
      return;
    case Types.GET_BOARD_BY_ID_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      state.getByIdFailed = null;
      return;
    case Types.GET_BOARD_BY_ID_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      state.byId = null;
      return;
    case Types.CREATE_BOARD_REQUEST:
      state.isSubmitLoading = true;
      state.createFailed = null;
      return;
    case Types.CREATE_BOARD_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      state.createFailed = null;
      return;
    case Types.CREATE_BOARD_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      state.createSuccess = null;
      return;
    case Types.UPDATE_BOARD_REQUEST:
      state.isSubmitLoading = true;
      state.updateFailed = null;
      return;
    case Types.UPDATE_BOARD_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.updateFailed = null;
      return;
    case Types.UPDATE_BOARD_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      state.updateSuccess = null;
      return;
    case Types.DELETE_BOARD_REQUEST:
      state.isSubmitLoading = true;
      state.deleteFailed = null;
      return;
    case Types.DELETE_BOARD_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      state.deleteFailed = null;
      return;
    case Types.DELETE_BOARD_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      state.deleteSuccess = null;
      return;
      //end board

 
    case Types.ADD_BOARD_CONFIG_ITEM_SUCCESS:
      state.dataBoardConfig[payload.id] = sortBy(payload.data, [function (o) { return o.ordinal }]);
      return;
    case Types.ADD_BOARD_CONFIG_ITEM_FAILED:
      state.dataBoardConfig[payload.id] = [];
      return;
    case Types.UPDATE_TASK_INIT_SUCCESS:
      state.isSubmitLoading = false;
      const { idTask, dataTask, boardId } = payload;
      state.dataBoardConfig[boardId] = state.dataBoardConfig[boardId]?.map((item) => {
        if (item._id === idTask) {
          return dataTask;
        }
        return item;
      });
      return;
    case Types.UPDATE_TASK_INIT_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;
    case Types.UPDATE_TASK_CHANGE_PROSITION:
      var {
        colBefore,
        indexBefore,
        colAfter,
        indexAfter,
      } = payload;

      if (!colAfter) {
        return
      }
      if (colBefore === colAfter) {
        if (indexBefore === indexAfter) {
          return
        }

      }
      var valueIdxUp = get(state.dataBoardConfig[colAfter][indexAfter - 1], 'ordinal', 0);
      var valueIdxDown = get(state.dataBoardConfig[colAfter][indexAfter], 'ordinal', valueIdxUp + 10);
      var newOrdinal = (valueIdxUp + valueIdxDown) / 2;

      var [{ ...itemBeRemove }] = state.dataBoardConfig[colBefore].splice(indexBefore, 1);

      Object.assign(itemBeRemove ?? {}, { ordinal: newOrdinal });


      state.dataBoardConfig[colAfter].splice(indexAfter, 0, itemBeRemove);

      return;
    // case Types.DELETE_WORK_FAILED:
    //   state.isSubmitLoading = false;
    //   state.deleteFailed = payload;

    case Types.GET_WORK_LIST_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;
    case Types.GET_WORK_LIST_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;
    case Types.GET_WORK_LIST_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;
    case Types.GET_LIST_BOARD_CONFIG_REQUEST:
      state.isLoading = true;
      state.getListBoardConfigFailed = null;
      return;
    case Types.GET_LIST_BOARD_CONFIG_SUCCESS:
      state.isLoading = false;
      state.list = payload;
      state.getListBoardConfigFailed = null;
      return;
    case Types.GET_LIST_BOARD_CONFIG_FAILED:
      state.isLoading = false;
      state.getListBoardConfigFailed = payload;
      state.list = [];
      return;
    case Types.GET_WORK_LIST_BY_ID_REQUEST:
      state.isGetByIdLoading = true;
      state.getByIdFailed = null;
      return;
    case Types.GET_WORK_LIST_BY_ID_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      state.getByIdFailed = null;
      return;
    case Types.GET_WORK_LIST_BY_ID_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      state.byId = null;
      return;
    case Types.CREATE_WORK_REQUEST:
      state.isSubmitLoading = true;
      state.createFailed = null;
      return;
    case Types.CREATE_WORK_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;
    case Types.CREATE_WORK_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_WORK_REQUEST:
      state.isSubmitLoading = true;
      state.updateFailed = null;
      return;
    case Types.UPDATE_WORK_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      return;
    case Types.UPDATE_WORK_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;
    case Types.DELETE_WORK_REQUEST:
      state.isSubmitLoading = true;
      state.deleteFailed = null;
      return;
    case Types.DELETE_WORK_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;
    case Types.DELETE_WORK_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;

      return;
    // updateTaskInlIst
    case Types.CHANGE_POSITION_TASK_ITEM:
      // state.isSubmitLoading = false;
      // state.deleteSuccess = payload;
      return;
    // case Types.DELETE_WORK_FAILED:
    //   state.isSubmitLoading = false;
    //   state.deleteFailed = payload;

    //   return;
    //manager||staff
    case Types.GET_LIST_MANAGERS_REQUEST:
      state.isLoadingManager = true;
      state.getListFailed = null;
      return;
    case Types.GET_LIST_MANAGERS_SUCCESS:
      state.isLoadingManager = false;
      state.listManagers = payload;
      state.getListFailed = null;
      return;
    case Types.GET_LIST_MANAGERS_FAILED:
      state.isLoadingManager = false;
      state.getListFailed = payload;
      state.listManagers = [];
      return;
    case Types.GET_LIST_MANAGERS_BY_ID_BOARD_REQUEST:
      state.isLoadingManagerByBoard = true;
      state.getListFailed = null;
      return;
    case Types.GET_LIST_MANAGERS_BY_ID_BOARD_SUCCESS:
      state.isLoadingManagerByBoard = false;
      state.listManagersByBoard = payload;
      state.getListFailed = null;
      return;
    case Types.GET_LIST_MANAGERS_BY_ID_BOARD_FAILED:
      state.isLoadingManagerByBoard = false;
      state.getListFailed = payload;
      state.listManagersByBoard = [];
      return;
    case Types.GET_LIST_STAFFS_REQUEST:
      state.isLoadingStaff = true;
      state.getListFailed = null;
      return;
    case Types.GET_LIST_STAFFS_SUCCESS:
      state.isLoadingStaff = false;
      state.listStaffs = payload;
      state.getListFailed = null;
      return;
    case Types.GET_LIST_STAFFS_FAILED:
      state.isLoadingStaff = false;
      state.getListFailed = payload;
      state.listStaffs = [];
      return;

    case Types.GET_LIST_STAFFS_BY_ID_BOARD_REQUEST:
      state.isLoadingStaffByBoard = true;
      state.getListFailed = null;
      return;
    case Types.GET_LIST_STAFFS_BY_ID_BOARD_SUCCESS:
      state.isLoadingStaffByBoard = false;
      state.listStaffsByIdBoard = payload;
      state.getListFailed = null;
      return;
    case Types.GET_LIST_STAFFS_BY_ID_BOARD_FAILED:
      state.isLoadingStaffByBoard = false;
      state.getListFailed = payload;
      state.listStaffsByIdBoard = [];
      return;
      case Types.GET_LIST_TASK_BY_SPRINTS_REQUEST:
      state.isLoadingTaskBySprints = true;
      state.getListFailed = null;
      return;
    case Types.GET_LIST_TASK_BY_SPRINTS_SUCCESS:
      state.isLoadingTaskBySprints = false;
      state.listTaskBySprints = payload;
      state.getListFailed = null;
      return;
    case Types.GET_LIST_TASK_BY_SPRINTS_FAILED:
      state.isLoadingTaskBySprints = false;
      state.getListFailed = payload;
      state.listTaskBySprints = [];
      return;
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
