export const GET_APPOINTMENTS_REQUEST = 'GET_APPOINTMENTS_REQUEST';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_FAILED = 'GET_APPOINTMENTS_FAILED';

export const GET_APPOINTMENTS_OF_HOSPITAL_DELETED_REQUEST = 'GET_APPOINTMENTS_OF_HOSPITAL_DELETED_REQUEST';
export const GET_APPOINTMENTS_OF_HOSPITAL_DELETED_SUCCESS = 'GET_APPOINTMENTS_OF_HOSPITAL_DELETED_SUCCESS';
export const GET_APPOINTMENTS_OF_HOSPITAL_DELETED_FAILED = 'GET_APPOINTMENTS_OF_HOSPITAL_DELETED_FAILED';

export const GET_APPOINTMENT_REQUEST = 'GET_APPOINTMENT_REQUEST';
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_FAILED = 'GET_APPOINTMENT_FAILED';

export const CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAILED = 'CREATE_APPOINTMENT_FAILED';

export const UPDATE_APPOINTMENT_REQUEST = 'UPDATE_APPOINTMENT_REQUEST';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILED = 'UPDATE_APPOINTMENT_FAILED';

export const UPDATE_LIST_PRODUCT_APPOINTMENT_REQUEST = 'UPDATE_LIST_PRODUCT_APPOINTMENT_REQUEST';
export const UPDATE_LIST_PRODUCT_APPOINTMENT_SUCCESS = 'UPDATE_LIST_PRODUCT_APPOINTMENT_SUCCESS';
export const UPDATE_LIST_PRODUCT_APPOINTMENT_FAILED = 'UPDATE_LIST_PRODUCT_APPOINTMENT_FAILED';

export const DELETE_APPOINTMENT_REQUEST = 'DELETE_APPOINTMENT_REQUEST';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAILED = 'DELETE_APPOINTMENT_FAILED';

export const RESET_APPOINTMENT = 'RESET_APPOINTMENT';
