import * as Types from '../../constants/actionTypes';
  
  export const getRequestVouchers = query => ({
    type: Types.GET_REQUEST_VOUCHERS_REQUEST,
    payload: query
  });
  export const getRequestVoucher = id => ({
    type: Types.GET_REQUEST_VOUCHER_REQUEST,
    payload: id
  });
  export const createRequestVoucher = instance => ({
    type: Types.CREATE_REQUEST_VOUCHER_REQUEST,
    payload: instance
  });
  export const updateRequestVoucher = instance => ({
    type: Types.UPDATE_REQUEST_VOUCHER_REQUEST,
    payload: instance
  });
  export const deleteRequestVoucher = id => ({
    type: Types.DELETE_REQUEST_VOUCHER_REQUEST,
    payload: id
  });
  export const resetRequestVoucherState = () => ({
    type: Types.RESET_REQUEST_VOUCHER_STATE
  });
