export const GET_JOB_QUESTIONS_REQUEST =
    'GET_JOB_QUESTIONS_REQUEST';
export const GET_JOB_QUESTIONS_SUCCESS =
    'GET_JOB_QUESTIONS_SUCCESS';
export const GET_JOB_QUESTIONS_FAILED = 'GET_JOB_QUESTIONS_FAILED';

export const GET_JOB_QUESTION_REQUEST = 'GET_JOB_QUESTION_REQUEST';
export const GET_JOB_QUESTION_SUCCESS = 'GET_JOB_QUESTION_SUCCESS';
export const GET_JOB_QUESTION_FAILED = 'GET_JOB_QUESTION_FAILED';

export const CREATE_JOB_QUESTION_REQUEST =
    'CREATE_JOB_QUESTION_REQUEST';
export const CREATE_JOB_QUESTION_SUCCESS =
    'CREATE_JOB_QUESTION_SUCCESS';
export const CREATE_JOB_QUESTION_FAILED =
    'CREATE_JOB_QUESTION_FAILED';

export const UPDATE_JOB_QUESTION_REQUEST =
    'UPDATE_JOB_QUESTION_REQUEST';
export const UPDATE_JOB_QUESTION_SUCCESS =
    'UPDATE_JOB_QUESTION_SUCCESS';
export const UPDATE_JOB_QUESTION_FAILED =
    'UPDATE_JOB_QUESTION_FAILED';


export const DELETE_JOB_QUESTION_REQUEST =
    'DELETE_JOB_QUESTION_REQUEST';
export const DELETE_JOB_QUESTION_SUCCESS =
    'DELETE_JOB_QUESTION_SUCCESS';
export const DELETE_JOB_QUESTION_FAILED =
    'DELETE_JOB_QUESTION_FAILED';


export const RESET_JOB_QUESTION_STATE = 'RESET_JOB_QUESTION_STATE';
export const RESET_JOB_QUESTION_STATE_ACTION = 'RESET_JOB_QUESTION_STATE_ACTION';
