import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Skeleton,
  Select,
  Checkbox,
  Switch,
  Collapse,
  Divider,
  notification,
  Radio
} from 'antd';
import UploadImage from '../../components/Utils/UploadImage';
import { LANGUAGE } from '../../constants/defaultValue';
import { PATH_APP } from '../../routes/paths';
import { Link } from 'react-router-dom';
import {
  useCreateNews,
  useNews,
  useInitNews,
  useResetNews,
  useUpdateNews
} from '../../hooks/news';

import {
  useCustomEditorToolbar,
  useNewsCategories,
  useNewsCategoryQueryParams
} from '../../hooks/newsCategory';
import { useFormItemMargin } from '~/hooks/utils';
import './form.scss';
import { useParams } from 'react-router-dom';
import SelectLanguage from '../../components/Utils/SelectLanguage';
import Editor from '../../components/Utils/Editor';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { SnippetsOutlined } from '@ant-design/icons';
import { devconfig } from '~/config';


const FormItem = Form.Item;

const maxWidthLayout = {
  labelCol: { sm: 24, md: 24, lg: 3 },
  wrapperCol: { sm: 24, md: 24, lg: 21 }
};

const NewsForm = () => {
  const query = useNewsCategoryQueryParams();
  const [newsCategories, isCategoriesLoading] = useNewsCategories(query);
  const [form] = Form.useForm();
  const [language, setLanguage] = useState(LANGUAGE.VI);
  const [logo, setLogo] = useState();
  const refEditor = useRef();
  useCustomEditorToolbar(refEditor);

  const [isSubmitLoading, handleCreate] = useCreateNews();
  const [, handleUpdate] = useUpdateNews();

  const { id } = useParams();
  const [news, isLoading] = useNews(id);
  const initNews = useInitNews(news);
  const margin = useFormItemMargin();

  useEffect(() => {
    form.resetFields();
    const { coverPhoto } = initNews;

    if (coverPhoto) {
      setLogo(coverPhoto);
    }
  }, [initNews, form]);

  useResetNews();

  const onFinish = useCallback(
    (values) => {
      form.validateFields();

      const news = {
        ...values,
        coverPhoto: logo,
        // status: values,

      };

      if (id) {
        handleUpdate({ ...news, id });
      } else {
        handleCreate(news);
      }
    },
    [handleCreate, handleUpdate, id, logo, form]
  );
  const slugClipboard = (devconfig.appDomain).replace('dashboard.','').concat('/rieng-tu/',initNews?.slug)
  const handleCopyClipBoard =(slug)=>{
    navigator.clipboard.writeText(slugClipboard)
    .then(()=>{
      notification.success({message: 'Sao chép đường dẫn thành công'})
    })
  }

  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;

  return (
    <div className="news-category-form page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb
          title={`${id ? 'Cập nhật' : 'Tạo mới'} tin tức`}
          routes={[
            {
              path: PATH_APP.news.root,
              title: 'Danh sách tin tức'
            }
          ]}
        />
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          initialValues={initNews}
          labelCol={{ sm: 24, md: 24, lg: 6 }}
          wrapperCol={{ sm: 24, md: 24, lg: 18 }}
        >
          <SelectLanguage value={language} onChange={setLanguage} />
          <Row gutter={48} align="start" justify="space-between"
              className="news-category-form__logo-row"
          >
            <Col span={12}>
            <FormItem
              label="Trạng thái"
              name="status"
            >
            {renderInput( <Radio.Group defaultValue={'EDITING'}  buttonStyle="solid">
                <Radio.Button value="EDITING">Đang sửa</Radio.Button>
                <Radio.Button value="PUBLISHED">Công khai</Radio.Button>
                <Radio.Button value="PRIVATE">Không Công khai</Radio.Button>
              </Radio.Group>)}
            </FormItem>
            <FormItem
              label="Nổi bật"
              name="isFeatured"
              valuePropName="checked"
              labelCol={{span:6}}
            >
              {renderInput(<Checkbox />)}
            </FormItem>
            </Col>
          </Row>
          <Row
            gutter={48}
            align="middle"
            justify="space-between"
            className="news-category-form__logo-row"
          >
            <Col span={12}>
              <FormItem
                label="Tiêu đề"
                name={['title', LANGUAGE.VI]}
                className={language === LANGUAGE.EN && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>

              <FormItem
                label="Tiêu đề"
                name={['title', LANGUAGE.EN]}
                className={language === LANGUAGE.VI && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>

              <FormItem  label="Đường dẫn" name="slug">
                {renderInput(<Input readOnly/>)}
              </FormItem>
              {
                initNews?.status=== 'PRIVATE'?
                <>
                  <Collapse>
                  <Collapse.Panel  header="Xem link chia sẻ" key={'1'}>
                    <p style={{marginBottom:8}}> 
                      <SnippetsOutlined 
                        onClick={()=>handleCopyClipBoard()} 
                        style={{fontSize:18,color: 'green',marginRight:8}}
                      />
                      {slugClipboard}
                    </p>
                  </Collapse.Panel>
                  </Collapse>
                  <Divider/>
                </> :<></>
              }
      
            </Col>
            <Col span={12} className="news-category-form__upload-logo">
              <UploadImage onChange={setLogo} imgUrl={logo} title="Avatar" />
            </Col>
          </Row>

          <Row gutter={48} align="start" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Danh mục tin tức"
                name="category"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn danh mục tin tức!'
                  }
                ]}
              >
                <Select multiple mode="multiple" loading={isCategoriesLoading}>
                  {newsCategories.map(({ name, _id }) => (
                    <Select.Option key={_id} value={_id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            {/* <Col span={12}>
              <Row gutter={48} align="middle" justify="space-between">
                <Col span={12}>
                  <FormItem
                    label="Trạng thái"
                    name="status"
                    valuePropName="checked"
                    labelCol={12}
                    wrapperCol={12}
                  >
                    {renderInput(<Switch />)}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label="Nổi bật"
                    name="isFeatured"
                    valuePropName="checked"
                    labelCol={12}
                    wrapperCol={12}
                  >
                    {renderInput(<Checkbox />)}
                  </FormItem>
                </Col>
              </Row>
            </Col> */}
          </Row>

          <Row style={{ marginLeft: margin }}>
            <FormItem
              label="Mô tả ngắn"
              name={['description', LANGUAGE.VI]}
              className={`news-category-form__description ${
                language === LANGUAGE.EN && 'hiden'
              }`}
              {...maxWidthLayout}
            >
              {renderInput(<Input.TextArea rows={2} />)}
            </FormItem>

            <FormItem
              label="Mô tả ngắn"
              name={['description', LANGUAGE.EN]}
              className={`news-category-form__description ${
                language === LANGUAGE.VI && 'hiden'
              }`}
              {...maxWidthLayout}
            >
              {renderInput(<Input.TextArea rows={2} />)}
            </FormItem>
          </Row>

          <Row
            className="news-category-form__editor"
            style={{ marginLeft: margin }}
            ref={refEditor}
          >
            <FormItem
              label="Nội dung"
              name={['content', LANGUAGE.VI]}
              className={`news-category-form__description ${
                language === LANGUAGE.EN && 'hiden'
              }`}
              {...maxWidthLayout}
            >
              <Editor />
            </FormItem>

            <FormItem
              label="Nội dung"
              name={['content', LANGUAGE.EN]}
              className={`news-category-form__description ${
                language === LANGUAGE.VI && 'hiden'
              }`}
              {...maxWidthLayout}
            >
              <Editor />
            </FormItem>
          </Row>

          <Row gutter={48} align="middle" justify="space-between">
            <Col span={12}>
              <FormItem
                label="Meta title"
                name={['metaTitle', LANGUAGE.VI]}
                className={language === LANGUAGE.EN && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>

              <FormItem
                label="Meta title"
                name={['metaTitle', LANGUAGE.EN]}
                className={language === LANGUAGE.VI && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Meta keywords"
                name={['metaKeywords', LANGUAGE.VI]}
                className={language === LANGUAGE.EN && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>

              <FormItem
                label="Meta keywords"
                name={['metaKeywords', LANGUAGE.EN]}
                className={language === LANGUAGE.VI && 'hiden'}
              >
                {renderInput(<Input />)}
              </FormItem>
            </Col>
          </Row>

          <Row style={{ marginLeft: margin }}>
            <FormItem
              label="Meta description"
              name={['metaDescription', LANGUAGE.VI]}
              className={`news-category-form__description ${
                language === LANGUAGE.EN && 'hiden'
              }`}
              {...maxWidthLayout}
            >
              {renderInput(<Input.TextArea rows={2} />)}
            </FormItem>

            <FormItem
              label="Meta description"
              name={['metaDescription', LANGUAGE.EN]}
              className={`news-category-form__description ${
                language === LANGUAGE.VI && 'hiden'
              }`}
              {...maxWidthLayout}
            >
              {renderInput(<Input.TextArea rows={2} />)}
            </FormItem>
          </Row>

          <Row className="news-category-form__submit-box">
            {isSubmitLoading ? (
              <Button disabled>Huỷ</Button>
            ) : (
              <Link to={PATH_APP.news.root}>
                <Button>Huỷ</Button>
              </Link>
            )}

            <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
              {id ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default NewsForm;
