import { filter, forEach, get, head, some } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import api from "~/api"
import { ACTION_LIST_PRODUCT, TYPE_WAREHOUSE, UNIT_PROVIDE, WAREHOUSE_STATUS } from "~/constants/defaultValue"
import toastr from "toastr"
import { useBranchIdSessionStorage } from "../utils"
import { useUser } from "../user"
export const useGetMaterial = (groupInitMaterial) => {
    const [keyword,setKeyword] = useState('')
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState([])
    const category = groupInitMaterial?.map(e => get(e,'category'));
    const [branchId] = useUser();
    const onSearch = async() => {
        try {
            setLoading(true)
            const listMaterials = await api.wareHouse.getMaterials({keyword: keyword === '' ? 0 : keyword,branchId,...category?.length === 1 && {category : head(category)} })
            setData(listMaterials)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toastr.error(get(error,'message','Lỗi khi lấy dữ liệu'))
        }
    }
    useEffect(() => {
        onSearch('')
    },[])
    return {keyword,setKeyword,loading,data,onSearch}
}
export const checkIsUnitOne = ({relationVariants}) => {
    const isOneUnit  = relationVariants?.length > 1
    const options = relationVariants?.map(e => ({value : get(e,'unitId'),label : get(e,'productUnit.name','')}))
    return {isOneUnit,options}
}
export const getProductUsedInAnotherAppointment = (dataSourceWhBillItem) => {
    let idProduct = [];
    forEach(dataSourceWhBillItem,(appointment) => forEach(get(appointment,'listProduct',[]),(product) => {
        if(get(product,'refId') && get(product,'medicalEquipmentTypeId.type') === TYPE_WAREHOUSE.WORLDHEALTH){
            idProduct.push(get(product,'refId'))
        }
    }))
    return idProduct
}

const listOptionSearch = [
    {
      value: 'name',
      label: 'Tên thiết bị'
    },
    {
      value: 'code',
      label: 'Mã thiết bị'
    },
  ];

export const useGetEquipment = (idGroup,modePreBooking,dataSourceWhBillItem,whAppointmentId) => {
    const [keyword,setKeyword] = useState('');
    const [selectSearch, setSelectSearch] = useState(get(head(listOptionSearch),'value',''));
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState([])
    const onSearch = useCallback(async() => {
        try {
            setLoading(true)
            const listMaterials = await api.productList.getAll({groupProductId : idGroup
            ,...modePreBooking ? {status : `${WAREHOUSE_STATUS.ASSIGNED.value},${WAREHOUSE_STATUS.PROCESSING.value}` } : {status : `${WAREHOUSE_STATUS.READY.value},${WAREHOUSE_STATUS.ASSIGNED.value},${WAREHOUSE_STATUS.PROCESSING.value}` }
            ,action : ACTION_LIST_PRODUCT.ACTIVE,
            ...!!keyword && {[selectSearch]:keyword},
            isGetCurrentBrachId : get(head(dataSourceWhBillItem),'branchId'),
            ...modePreBooking ? {isFetchPreBooking : true} : {isFetchAssign : true},
            whAppointmentId
        });
        // const listProductCanUseMore = getProductUsedInAnotherAppointment(dataSourceWhBillItem)
        // const filterListMaterials = filter(get(listMaterials,'docs',[]),(e) => {
        //     // GET DATA status READY AND $IN listProductCanUseMore
        //     // if(get(e,'status') === WAREHOUSE_STATUS.READY.value){
        //     //     return true
        //     // }
        //     return some(listProductCanUseMore,(productId) => productId === get(e,'_id'))
  
        // } )
            setData(get(listMaterials,'docs',[]))
            setLoading(false)
            if(modePreBooking && !get(listMaterials,'docs',[])?.length) {
                toastr.warning("Không có máy nào có thể đặt trước")
            }
        } catch (error) {
            setLoading(false)
            toastr.error(get(error,'message','Lỗi khi lấy dữ liệu'))
        }
    },[dataSourceWhBillItem, idGroup, keyword, modePreBooking, selectSearch])
    useEffect(() => {
        idGroup &&  onSearch()
    },[idGroup,modePreBooking,keyword,onSearch])
    return {keyword,setKeyword,loading,data,onSearch,setSelectSearch,selectSearch,listOptionSearch}
}

export const handlePreBooking = async(submitData,callback) => {
    try {
      const res =  await api.preBooking.create(submitData)
      callback({...get(res,'data'),groupProductId : get(res,'data.groupEquipmentId')})
      toastr.success("Đặt trước máy thành công")
    } catch (error) {
        console.log(error);
        toastr.error(error,'response.data.message','Something went wrong')
    }
}

export const handleDeletePreBooking = async(id,callback) => {
    try {
      const res =  await api.preBooking.delete(id)
      callback({...get(res,'data'),groupProductId : get(res,'data.groupEquipmentId')})
      toastr.success("Xoá thiết bị đặt trước thành công")
    } catch (error) {
        console.log(error);
        toastr.error(error,'response.data.message','Something went wrong')
    }
}
