import React, { useRef, useState } from 'react'
import FormDetail from './Form/FormDetail';
import FormDuration from './Form/FormDuration';
import FormSize from './Form/FormSize';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { updateHighlight } from '~/redux/action';

function NavbarHighlight({ language, form: { form, form3, form2 }, control: { data_: data, vSetting }, set: { setVSetting, setData, setActive, setPause }, updateVSetting }) {

    const [active, setSelectActive] = useState({
        sizeItem:false,
        timeDuration:false,
        detail:false,
    });
    const onSelectCategory =(value)=>{
       for (const key in active) {
        if(key===value){
            active[key] = !active[key]
            continue
        }
        active[key] = false
       }
       setSelectActive({...active})
    }
    const onSubmitSetting =()=>{
        const {pause,...newvSetting} =vSetting
        const  {v1,v2, v3 ,_id} = data
        updateVSetting({vSetting: newvSetting, _id, v1, v2, v3})
    }
    return (
        <div className='highlight-navbar-container'>
            <div  className={`highlight-navbar-item `}> 
                <div onClick={()=>onSelectCategory('sizeItem')} className={`highlight-navbar-item--title ${active.sizeItem ? 'active': ''}`}>
                    <p>Kích thước</p> 
                </div>

                <NavbarHighlightModal onClick={onSubmitSetting} className={`${active.sizeItem ? 'active': ''}`}>
                    <FormSize form={form3}  vSetting={vSetting} setVSetting={setVSetting} ></FormSize>
                </NavbarHighlightModal> 
            </div>

            <div className={`highlight-navbar-item `}> 
                <div onClick={()=>onSelectCategory('timeDuration')} className={`highlight-navbar-item--title ${active.timeDuration ? 'active': ''}`}>
                    <p>Thời gian</p> 
                </div> 
                
                <NavbarHighlightModal onClick={onSubmitSetting} className={`${active.timeDuration ? 'active': ''}`}>
                    <FormDuration 
                    form={form} 
                        vSetting={vSetting}
                        pause={vSetting?.pause}
                        setVSetting={setVSetting}
                        setPause={setPause}
                    ></FormDuration>
                </NavbarHighlightModal>
            </div>
            <div className={`highlight-navbar-item `}>
                <div onClick={()=>onSelectCategory('detail')} className={`highlight-navbar-item--title ${active.detail ? 'active': ''}`}>
                    <p>Chi tiết</p> 
                </div>

                <NavbarHighlightModal onClick={onSubmitSetting} className={`${active.detail ? 'active' : ''}`}>
                    <FormDetail form={form2} data={data} setData={setData} setActive={setActive} language={language} ></FormDetail>
                </NavbarHighlightModal>
            </div>
        </div>
    )
}
const mapDispatchToProps =(dispatch)=>{
    return {
        updateVSetting:(data)=>(dispatch(updateHighlight(data)))
    }
}

export default connect(()=>({}),mapDispatchToProps)(NavbarHighlight) 



function  NavbarHighlightModal ({className,children,onClick}) {
    return (
        <div className={`highlight-navbar-modal ${className}`}>  
           {children}
           <Button className='highlight-navbar-modal-btn' type='primary' htmlType='button' onClick={onClick}>Lưu</Button>
        </div>
    )
}




