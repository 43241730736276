import { Input, Row, Col, Select, Skeleton, Form, Modal, Button } from 'antd';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import api from '~/api';
import toastr from 'toastr';
import { useAreas, useCities, useDistricts, useWards } from '~/hooks';
import { filterAcrossAccents } from '~/hooks/utils';
import LoadingWhBill from './LoadingWhBill';
import { get } from 'lodash';
export default function ModalAddAddress({ visibleModalAddAddress, setVisibleModalAddAddress, reFetch, isWhBill, customerId,setReFeatch, reFeatch }) {
  const [form] = Form.useForm()
  const FormItem = Form.Item;
  const { Option } = Select;
  const [areas, isAreasLoading] = useAreas();
  const userSelect = useSelector(state => state.quotation.userSelect)
  const [cityCode, setCityCode] = useState();
  const [loading, setLoading] = useState(false)
  const [districtCode, setDistrictCode] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [cities, isCitiesLoading] = useCities();
  const [districts, isDistrictsLoading] = useDistricts(cityCode);
  const [wards, isWardsLoading] = useWards(districtCode);
  const onFinish = async (values) => {
    const city = cities.find(item => get(item, "code") === get(values, 'address.cityId'));
    const areaId = get(city, "area_code");
    setLoading(true)
    const address = { ...values.address, areaId, idCustomer:isWhBill ? customerId : userSelect.id }
    const res = await api.userEmployee.updateAddress(address)
    if (res.status) {
      toastr.success(res.message)
      setVisibleModalAddAddress(false)
      reFetch();
      setReFeatch && setReFeatch(!reFeatch);
    }
    else {
      toastr.error(res.message)
    }
    setLoading(false)
  }
  const wrapperCol = 24
  return (
    <Modal closable={false} width={1000} visible={visibleModalAddAddress} footer={null} onCancel={() => setVisibleModalAddAddress(false)}>
      <LoadingWhBill open={loading} />
      <Form name="basic"
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off">
        <Row gutter={48} align="middle" justify="space-between">
          <Col span={wrapperCol}>
            <FormItem
              label="Thành Phố/Tỉnh"
              name={['address', 'cityId']}
              rules={[
                {
                  required: true,
                  message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
                }
              ]}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  onChange={setCityCode}
                  disabled={isCitiesLoading}
                  loading={isCitiesLoading}
                  showSearch
                  autoComplete="disabled"
                  filterOption={filterAcrossAccents}
                >
                  {cities.map(({ code, name }) => (
                    <Option key={code} value={code}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>

          <Col span={wrapperCol}>
            <FormItem
              label="Quận/Huyện"
              name={['address', 'districtId']}
              rules={[
                {
                  required: true,
                  message: 'Xin vui lòng chọn Quận/Huyện!'
                }
              ]}
            >
              {isLoading ? (
                <Skeleton.Input active />
              ) : (
                <Select
                  loading={isDistrictsLoading}
                  disabled={!form.getFieldValue(['address', 'cityId'])}
                  onChange={setDistrictCode}
                  showSearch
                  autoComplete="off"
                  filterOption={filterAcrossAccents}
                >
                  {districts.map(({ code, name }) => (
                    <Option key={code} value={code}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={48} align="middle" justify="space-between">
          <Col span={wrapperCol}>
            <FormItem shouldUpdate={(pre, next) => (get(pre, 'address.cityId') !== get(next, 'address.cityId')) || (get(pre, 'address.districtId') !== get(next, 'address.districtId'))} noStyle >


              {() => <FormItem
                label="Phường/Xã"
                name={['address', 'wardId']}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn Phường/Xã!'
                  }
                ]}
              >
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Select
                    loading={isWardsLoading}
                    disabled={!form.getFieldValue(['address', 'districtId'])}
                    showSearch
                    autoComplete="off"
                    filterOption={filterAcrossAccents}
                  >
                    {wards.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>}
            </FormItem>
          </Col>
          <Col span={wrapperCol}>
            <FormItem label="Đường phố" name={['address', 'street']} rules={[
              {
                required: true,
                message: 'Xin vui lòng Nhập tên đường'
              }
            ]}>
              {isLoading ? <Skeleton.Input active /> : <Input />}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={48} align="middle" justify="space-between">
          <Button style={{ margin: '0 auto' }} type='primary' htmlType='submit'>Xác nhận</Button>
        </Row>

      </Form>
    </Modal>
  )
}
