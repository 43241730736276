import requester from './requester';

const serviceByLocation = {
  getAll: (query) => requester.get('/list-location', query),
  getById: (query) => requester.get(`/location-services`, query),
  create: (location) => requester.post('/location-service', location),
  update: (location) => requester.put(`/service-by-location/${location._id}`, location),
  delete: (location) => requester.delete(`/location-service`, location)
};

export default serviceByLocation;
