export const GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_REQUEST = 'GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_REQUEST';
export const GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_SUCCESS = 'GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_SUCCESS';
export const GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_FAILED = 'GET_SYSTEM_CONFIGURATIONS_OF_BOOKING_INSTRUCTION_FAILED';

export const GET_SYSTEM_CONFIGURATIONS_OF_VAT_REQUEST = 'GET_SYSTEM_CONFIGURATIONS_OF_VAT_REQUEST';
export const GET_SYSTEM_CONFIGURATIONS_OF_VAT_SUCCESS = 'GET_SYSTEM_CONFIGURATIONS_OF_VAT_SUCCESS';
export const GET_SYSTEM_CONFIGURATIONS_OF_VAT_FAILED = 'GET_SYSTEM_CONFIGURATIONS_OF_VAT_FAILED';

export const GET_SYSTEM_CONFIGURATION_REQUEST = 'GET_SYSTEM_CONFIGURATION_REQUEST';
export const GET_SYSTEM_CONFIGURATION_SUCCESS = 'GET_SYSTEM_CONFIGURATION_SUCCESS';
export const GET_SYSTEM_CONFIGURATION_FAILED = 'GET_SYSTEM_CONFIGURATION_FAILED';

export const CREATE_SYSTEM_CONFIGURATION_REQUEST = 'CREATE_SYSTEM_CONFIGURATION_REQUEST';
export const CREATE_SYSTEM_CONFIGURATION_SUCCESS = 'CREATE_SYSTEM_CONFIGURATION_SUCCESS';
export const CREATE_SYSTEM_CONFIGURATION_FAILED = 'CREATE_SYSTEM_CONFIGURATION_FAILED';

export const UPDATE_SYSTEM_CONFIGURATION_REQUEST = 'UPDATE_SYSTEM_CONFIGURATION_REQUEST';
export const UPDATE_SYSTEM_CONFIGURATION_SUCCESS = 'UPDATE_SYSTEM_CONFIGURATION_SUCCESS';
export const UPDATE_SYSTEM_CONFIGURATION_FAILED = 'UPDATE_SYSTEM_CONFIGURATION_FAILED';

export const DELETE_SYSTEM_CONFIGURATION_REQUEST = 'DELETE_SYSTEM_CONFIGURATION_REQUEST';
export const DELETE_SYSTEM_CONFIGURATION_SUCCESS = 'DELETE_SYSTEM_CONFIGURATION_SUCCESS';
export const DELETE_SYSTEM_CONFIGURATION_FAILED = 'DELETE_SYSTEM_CONFIGURATION_FAILED';

export const RESET_SYSTEM_CONFIGURATION_STATE = 'RESET_SYSTEM_CONFIGURATION_STATE';
