import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '~/api';
import { addUserSelect } from '~/redux/action';
import AddressFormSection from '../Common/AddressFormSection';
import toastr from 'toastr';
import LoadingWhBill from './LoadingWhBill';
const ModalRegisterUserTemp = ({
  isModalRegisterOpen,
  setIsModalRegisterOpen,
  quotation
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [cityCode, setCityCode] = useState();
  const [districtCode, setDistrictCode] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async(values) => {
    setLoading(true)
    try {
      const res = await api.userEmployee.register({...values,...values.address,billNumber:quotation._id,password:values.phoneNumber,phoneNumber:`+84${(values.phoneNumber).slice(1)}`})
    if(res.status){
      dispatch(addUserSelect(res.data))
      setIsModalRegisterOpen(false)
      toastr.success(res.message)
    }
    else{
      toastr.error(res.message)
    }
    } catch (error) {
      toastr.error("Some thing went wrong")
    }
    setLoading(false)
  }
  return (
    <Modal footer={null} visible={isModalRegisterOpen} width={1000}>
    <LoadingWhBill open={loading}/>
      <h4 style={{textAlign : 'center'}}>Đăng ký</h4>
      <Form
      form={form}
      autoComplete='disable'
      initialValues={{
        phoneNumber :( quotation?.phoneNumber),
        fullName:quotation?.fullName
      }}
      onFinish={onFinish}
        labelCol={{
          span: 8
        }}
        wrapperCol={{
          span: 48
        }}
        layout="horizontal"
        style={{
          maxWidth: 1000
        }}
      >
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item  name="fullName" label="Họ và tên">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <AddressFormSection
          isLoading={loading}
          form={form}
          cityCode={cityCode}
          setCityCode={setCityCode}
          districtCode={districtCode}
          setDistrictCode={setDistrictCode}
        />
        {/* <Row gutter={48}>
          <Col span={12}>
            <Form.Item name="password" label="Mật khẩu">
              <Input />
            </Form.Item>
          </Col>
        </Row> */}

        <Row justify="center">
          <Button htmlType='submit' type="primary">Đăng ký</Button>
          <Button onClick={() => setIsModalRegisterOpen(false)}>Huỷ</Button>
        </Row>
      </Form>
    </Modal>
  );
};
export default ModalRegisterUserTemp;
