import * as Types from '../../constants/actionTypes';

export const getWhCustomersManagement = (query) => ({
  type: Types.GET_WH_CUSTOMERS_MANAGEMENT_REQUEST,
  payload: query
});

export const getWhCustomersManagementHistory = (query) => ({
  type: Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_HISTORY_REQUEST,
  payload: query
});

export const getRefWhCustomersManagement = (query) => ({
  type: Types.GET_REF_WH_CUSTOMERS_MANAGEMENT_REQUEST,
  payload: query
});

export const getWhCustomerManagement = id => ({
  type: Types.GET_WH_CUSTOMER_MANAGEMENT_REQUEST,
  payload: id
});

export const createWhCustomerManagement = whCustomerManagement => ({
  type: Types.CREATE_WH_CUSTOMER_MANAGEMENT_REQUEST,
  payload: whCustomerManagement
});

export const updateWhCustomerManagement = whCustomerManagement => ({
  type: Types.UPDATE_WH_CUSTOMER_MANAGEMENT_REQUEST,
  payload: whCustomerManagement
});

export const updateNoteWhCustomerManagement = whCustomerManagement => ({
  type: Types.UPDATE_NOTE_WH_CUSTOMER_MANAGEMENT_REQUEST,
  payload: whCustomerManagement
});

export const deleteWhCustomerManagement = id => ({
  type: Types.DELETE_WH_CUSTOMER_MANAGEMENT_REQUEST,
  payload: id
});

export const resetWhCustomerManagementState = () => ({
  type: Types.RESET_WH_CUSTOMER_MANAGEMENT_STATE
});

export const resetNoteWhCustomerManagementState = () => ({
  type: Types.RESET_NOTE_WH_CUSTOMER_MANAGEMENT_STATE
});

//Handle address
export const getWhCustomersManagementManyAddress = (query) => ({
  type: Types.GET_WH_CUSTOMERS_MANAGEMENT_MANY_ADDRESS_REQUEST,
  payload: query
});

export const getWhCustomersManagementAddress = (query) => ({
  type: Types.GET_WH_CUSTOMERS_MANAGEMENT_ADDRESS_REQUEST,
  payload: query
});

export const updateWhCustomerManagementAddress = whCustomerManagement => ({
  type: Types.UPDATE_WH_CUSTOMER_MANAGEMENT_ADDRESS_REQUEST,
  payload: whCustomerManagement
});

export const deleteWhCustomerManagementAddress = id => ({
  type: Types.DELETE_WH_CUSTOMER_MANAGEMENT_ADDRESS_REQUEST,
  payload: id
});
export const resetPasswordCustomer = query => ({
  type: Types.RESET_PASSWORD_REQUEST,
  payload: query
});
