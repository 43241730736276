import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { CaretDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useMatchPolicy } from '~/hooks';
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Dropdown,
  Table,
  Modal,
  Menu,
  DatePicker
} from 'antd';
import WhCustomersImportForm from './Form';
import ImportForm from './ImportForm';
import { Link, useHistory } from 'react-router-dom';
import WithPermission from '~/components/Common/WithPermission';
import { ORDER_QUOTATION_STATUS_NAME } from '~/constants/defaultValue';
import {
  useWhCustomersImport,
  useDeleteWhCustomerImport,
  useUpdateWhCustomerImport,
  useWhCustomersImportQueryParams,
  useUpdateCustomerImportParams,
  useWhCustomerImportPaging,
  useConvertWhCustomerImport,
  useResetWhCustomerImport
} from '~/hooks';
import { useSpecialityOptions } from '~/hooks/speciality';
import './index.scss';
import { useQueryParams } from '~/hooks/utils';
import POLICY from '~/constants/policy';
import { WithPermissionDisabled } from '../Common';
import TabBranch from '../Common/TabBranch';
const { Search } = Input;
const { confirm } = Modal;

const genders = [
  {
    id: 'F',
    gender: 'Nữ'
  },
  {
    id: 'M',
    gender: 'Nam'
  }
];
const keySearch = {
  keyword: 'Tìm tất cả',
  customerId: 'Tìm theo mã khách hàng',
  fullName: 'Tìm theo tên',
  phoneNumber: 'Tìm theo số điện thoại',
  dateOfBirth: 'Tìm theo năm sinh',
  gender: 'Tìm theo giới tính',
  mainDisease: 'Tìm theo bệnh lý chính',
  acceptedDisease: 'Tìm theo bệnh lý kèm theo',
  genitive: 'Tìm theo tính cách',
  address: 'Tìm theo địa chỉ',
  dateImport: 'Tìm theo ngày tạo',
  healthInsuranceCard: 'Tìm theo thẻ bảo hiểm y tế',
  familyName: 'Tìm theo tên nhân thân',
  familyPhone: 'Tìm theo số điện thoại thân nhân',
  addressOfFamily: 'Tìm theo địa chỉ thân nhân',
  relationship: 'Tìm theo mối quan hệ'
};

const CheckParam = () => {
  const query = useQueryParams();
  return useMemo(() => {
    const listKey = Object.getOwnPropertyNames(keySearch);
    const queryKey = listKey.map((e) => ({ [e]: query.get(e.toString()) }));
    const [sout] = queryKey.filter((e) => Object.values(e)[0] !== null);
    if (!!sout) {
      return [Object.keys(sout)[0], Object.values(sout)[0]];
    }
    return [];
  }, []);
};

const WhCustomersImport = () => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_POTENTIALCUSTOMER);
  const canDelete = useMatchPolicy(POLICY.DELETE_POTENTIALCUSTOMER);
  const [key, valu] = CheckParam();
  const [timeOut, setTiming] = useState();
  const history = useHistory();
  const [searchBy, setSearchBy] = useState(key);
  const [query, onPagingChange] = useWhCustomersImportQueryParams(
    searchBy ?? ''
  );
  const [, convertWhCustomerImport] = useConvertWhCustomerImport();
  const [, { onParamChange }] = useUpdateCustomerImportParams(query);
  const specialityOptions = useSpecialityOptions();
  const [, updateCustomer] = useUpdateWhCustomerImport();
  const [, deleteCustomer] = useDeleteWhCustomerImport();
  const [whCustomersImport, isLoading] = useWhCustomersImport(query);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [importId, setImportId] = useState(null);
  const paging = useWhCustomerImportPaging();

  const ColumnActions = ({
    _id,
    onConvertWhCustomerImport,
    onDeleteWhCustomerImport,
    customerId
  }) => {
    const menu = (
      <Menu>
        <WithPermissionDisabled permission={POLICY.UPDATE_POTENTIALCUSTOMER}>
          <Menu.Item
            onClick={() => {
              setIsOpen(true);
              setImportId(_id);
            }}
          >
            <p>Cập nhật</p>
          </Menu.Item>
        </WithPermissionDisabled>

        <WithPermissionDisabled permission={POLICY.UPDATE_POTENTIALCUSTOMER}>
          <Menu.Item
            disabled={customerId}
            onClick={() => onConvertWhCustomerImport(_id)}
          >
            <p>Chuyển đổi</p>
          </Menu.Item>
        </WithPermissionDisabled>

        <WithPermissionDisabled permission={POLICY.DELETE_POTENTIALCUSTOMER}>
          <Menu.Item
            disabled={customerId}
            onClick={() => {
              onDeleteWhCustomerImport(_id);
            }}
          >
            <p>Xoá</p>
          </Menu.Item>
        </WithPermissionDisabled>
      </Menu>
    );
    return (
      <>
        <Dropdown
          overlayStyle={{ width: '140px', textAlign: 'center' }}
          trigger={['click']}
          overlay={menu}
        >
          <a className="ant-dropdown-link" href="#">
            Action <CaretDownOutlined />
          </a>
        </Dropdown>
      </>
    );
  };

  const onConvertWhCustomerImport = (id) => {
    confirm({
      title:
        'Bạn muốn chuyển đổi thông tin này thành khách hàng chính thống trên hệ thống?',
      content: '',
      okText: 'Có',
      cancelText: 'Không',
      onOk() {
        convertWhCustomerImport(id);
      }
    });
  };

  const onDeleteWhCustomerImport = (id) => {
    confirm({
      title: 'Bạn muốn xoá khách hàng này trên hệ thống?',
      content: '',
      okText: 'Có',
      cancelText: 'Không',
      onOk() {
        deleteCustomer(id);
      }
    });
  };

  const onParamChanges = useCallback(
    (value) => {
      onParamChange({ [searchBy ?? 'keyword']: value.trim() });
    },
    [searchBy]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const onChangeSearch = (e) => {
    timeOut && clearTimeout(timeOut);
    if (!e.target.value) {
      setTiming(
        setTimeout(() => {
          onParamChanges('');
        }, 1200)
      );
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setImportId(null);
    setIsOpen(false);
  };

  const onSelectCustomer = (id) => {
    setIsOpen(true);
    setImportId(id);
  };

  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '70px',
      align: 'center',
      render: (text, record, index) => {
        return (query.page - 1) * query.limit + index + 1;
      }
    },
    {
      title: 'Mã khách hàng tạm',
      key: 'code',
      dataIndex: 'code',
      width: '150px',
      align: 'center',
      render: (text, record, index) => {
        return text ? 'WH' + text : '';
      }
    },

    {
      title: 'Mã khách hàng',
      key: 'customerId',
      width: '200',
      render: (record) => (
        <WithPermission permission={POLICY.READ_CUSTOMER}>
          <Button
            href="#"
            onClick={(event) =>
              history.push({
                pathname: '/customer-account',
                search: `?page=1&limit=10&keyword=WHC${record.customerId}`
              })
            }
            style={{
              background: 'none',
              border: 'none',
              padding: '0!important',
              textDecoration: 'underline',
              cursor: 'pointer',
              color: '#1890ff'
            }}
          >
            {record.customerId ? 'WHC' + record.customerId : ''}
          </Button>
        </WithPermission>
      )
    },

    {
      title: 'Họ và tên',
      key: 'fullName',
      width: '180px',
      render: (item) => item.fullName
    },

    {
      title: 'Số điện thoại',
      key: 'phoneNumber',
      width: '200',
      render: (record) => {
        return record.phoneNumber;
      }
    },

    {
      title: 'Email',
      key: 'email',
      width: '200',
      render: (record) => {
        return record.email;
      }
    },

    {
      title: 'Ngày sinh',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      width: '350',
      render: (record) => {
        if (record === 'Invalid Date' || !!!record) {
          return '';
        } else {
          return moment(record).format('DD/MM/YYYY');
        }
      }
    },

    {
      title: 'Giới tính',
      key: 'gender',
      align: 'center',
      width: 100,
      render: (record) => (record.gender === 'F' ? 'Nữ' : 'Nam')
    },

    {
      title: 'Bệnh lý chính',
      key: 'mainDisease',
      width: 400,
      render: (record) => record.mainDisease
    },

    {
      title: 'Bệnh lý kèm theo',
      key: 'acceptedDisease',
      width: 800,
      render: (record) => record.acceptedDisease
    },

    {
      title: 'Tính cách',
      key: 'genitive',
      width: '500',
      render: (record) => record.genitive
    },

    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      width: '400',
      render: (value) => {
        var str = '';
        if (value) {
          var strStreet = value.street ? value.street : ' ';
          str = strStreet;
        } else {
          return '';
        }
        return str;
      }
    },

    {
      title: 'Phường/ Xã',
      dataIndex: 'address',
      key: 'address',
      width: '300',
      render: (value) => {
        var str = '';
        if (value) {
          var strWard = value.ward ? value.ward : ' ';
          str = strWard;
        } else {
          return '';
        }
        return str;
      }
    },

    {
      title: 'Quận/ Huyện',
      dataIndex: 'address',
      key: 'address',
      width: '300',
      render: (value) => {
        var str = '';
        if (value) {
          var strDistrict = value.district ? value.district : ' ';
          str = strDistrict;
        } else {
          return '';
        }
        return str;
      }
    },

    {
      title: 'Tỉnh/ TP',
      dataIndex: 'address',
      key: 'address',
      width: '300',
      render: (value) => {
        var str = '';
        if (value) {
          var strCity = value.city ? value.city : ' ';
          str = strCity;
        } else {
          return '';
        }
        return str;
      }
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'dateImport',
      key: 'dateImport',
      width: '200',
      render: (record) => {
        return moment(record).format('DD/MM/YYYY');
      }
    },

    {
      title: 'Thẻ BHYT',
      key: 'healthInsuranceCard',
      width: '470',
      render: (record) => record.healthInsuranceCard
    },

    {
      title: 'Tên nhân thân',
      key: 'familyName',
      width: '470',
      render: (record) => record.familyName
    },

    {
      title: 'Điện thoại nhân thân',
      key: 'familyPhone',
      width: '300',
      render: (record) => record.familyPhone
    },

    {
      title: 'Địa chỉ nhân thân',
      key: 'addressOfFamily',
      width: '570',
      render: (record) => record.addressOfFamily
    },

    {
      title: 'Mối quan hệ',
      key: 'relationship',
      width: '270',
      align: 'center',
      render: (record) => record.relationship
    },

    {
      title: 'Ghi chú',
      key: 'note',
      width: '200',
      render: (record) => record.note
    },
    {
      title: 'Trạng thái',
      key: 'state',
      align: 'center',
      width: '270',
      render: (record) => {
        return record.customerId ? (
          <span
            className="customer-status converted"
            style={{
              height: '22px',
              minWidth: '22px',
              lineHeight: 0,
              borderRadius: '8px',
              cursor: 'default',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              display: 'inline-flex',
              justifyContent: 'center',
              padding: '0px 8px',
              fontSize: '0.75rem',
              fontWeight: '700',
              textTransform: 'uppercase',
              marginBottom: '8px',
              backgroundColor: '#52c41a',
              color: '#fff'
            }}
          >
            {ORDER_QUOTATION_STATUS_NAME.CONVERTED}
          </span>
        ) : (
          <span
            className="customer-status create"
            style={{
              height: '22px',
              minWidth: '22px',
              lineHeight: 0,
              borderRadius: '8px',
              cursor: 'default',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              display: 'inline-flex',
              justifyContent: 'center',
              padding: '0px 8px',
              fontSize: '0.75rem',
              fontWeight: '700',
              textTransform: 'uppercase',
              marginBottom: '8px',
              backgroundColor: '#f0f0f0',
              color: '#717171'
            }}
          >
            {ORDER_QUOTATION_STATUS_NAME.CREATE}
          </span>
        );
      }
    },

    ...(canDelete || canUpdate
      ? [
          {
            title: 'Hành động',
            key: 'action',
            width: '170px',
            fixed: 'right',
            render: (record) => {
              record = {
                ...record,
                id: record._id
              };
              return (
                <ColumnActions
                  {...record}
                  customerId={record.customerId}
                  onConvertWhCustomerImport={onConvertWhCustomerImport}
                  onDeleteWhCustomerImport={onDeleteWhCustomerImport}
                  updateCustomer={updateCustomer}
                />
              );
            }
          }
        ]
      : [])
  ];
  return (
    <div className="page-wrapper page-content whCustomerImports-page">
      <div className="container-fluid">
        <TabBranch useBoxShadow={false}>
          <Breadcrumb title="Khách hàng tiềm năng" />
          <div className="page-wraper__header">
            <Row justify="start" gutter={36}>
              <Col sm={24} md={6}>
                <Select
                  value={searchBy ?? 'keyword'}
                  style={{ width: '100%' }}
                  onChange={(val) => {
                    setSearchBy(val);
                    if (val === 'gender') {
                      onParamChange({ gender: 'M' });
                    } else {
                      onParamChanges('');
                    }
                  }}
                >
                  <Option value="keyword">Tìm tất cả</Option>
                  <Option value="customerId">Mã khách hàng</Option>
                  <Option value="fullName">Họ và tên</Option>
                  <Option value="phoneNumber">Số điện thoại</Option>
                  <Option value="dateOfBirth">Năm sinh</Option>
                  <Option value="gender">Giới tính</Option>
                  <Option value="mainDisease">Bệnh lý chính</Option>
                  <Option value="acceptedDisease">Bệnh lý kèm theo</Option>
                  <Option value="genitive">Tính cách</Option>
                  <Option value="address">Địa chỉ</Option>
                  <Option value="dateImport">Ngày tạo</Option>
                  <Option value="healthInsuranceCard">Thẻ BHYT</Option>
                  <Option value="familyName">Tên nhân thân</Option>
                  <Option value="familyPhone">SĐT thân nhân</Option>
                  <Option value="addressOfFamily">Địa chỉ thân nhân</Option>
                  <Option value="relationship">Mối quan hệ</Option>
                </Select>
              </Col>

              <Col sm={24} md={6}>
                {searchBy !== 'gender' ? (
                  ['dateImport', 'dateOfBirth'].includes(searchBy) ? (
                    <DatePicker
                      showNow
                      inputReadOnly
                      format={'DD/MM/YYYY'}
                      onChange={(date, dataToString) => {
                        onParamChanges(dataToString);
                      }}
                    />
                  ) : (
                    <Search
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={keySearch[searchBy ?? 'keyword']}
                      defaultValue={valu ?? ''}
                      enterButton
                      onSearch={onParamChanges}
                      onChange={onChangeSearch}
                    />
                  )
                ) : (
                  <Select
                    style={{ width: '100%' }}
                    defaultValue={'M'}
                    placeholder={keySearch[searchBy]}
                    autoComplete="off"
                    onChange={(value) => {
                      onParamChanges(value);
                    }}
                  >
                    {genders.map(({ id, gender }, index) => (
                      <Option key={index} value={id}>
                        {gender}
                      </Option>
                    ))}
                  </Select>
                )}
              </Col>
              <Col sm={24} md={12}>
                <WithPermission permission={POLICY.WRITE_POTENTIALCUSTOMER}>
                  <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                      setImportId(null);
                      setIsOpen(true);
                    }}
                  >
                    {' '}
                    Thêm mới
                  </Button>
                </WithPermission>
                <WithPermission permission={POLICY.WRITE_POTENTIALCUSTOMER}>
                  <Button
                    type="primary"
                    style={{ float: 'right', marginRight: '10px' }}
                    onClick={showModal}
                  >
                    {' '}
                    Import
                  </Button>
                </WithPermission>
              </Col>
            </Row>
          </div>

          {isLoading ? (
            <SkeletonTable
              columns={columns}
              rowCount={whCustomersImport.length}
              pagination={paging}
            />
          ) : (
            <Table
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={whCustomersImport}
              onChange={onPagingChange}
              size="middle"
              scroll={{ x: 4000 }}
              pagination={{
                ...paging,
                showTotal: (total) => `Tổng cộng: ${total} `
              }}
            />
          )}
        </TabBranch>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={handleClose}
        onOk={handleClose}
        footer={null}
      >
        <WhCustomersImportForm
          specialityOptions={specialityOptions}
          id={importId}
          onClose={handleClose}
          onCancel={handleClose}
        />
      </Modal>

      <ImportForm
        onModule={handleOk}
        isModalOpen={isModalOpen}
        onClose={handleCancel}
      />
    </div>
  );
};

export default WhCustomersImport;
