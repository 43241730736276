import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState,
  useFetch,
} from '../utils';
import { useSelector } from 'react-redux';
import {
  getHospitals,
  createHospital,
  deleteHospital,
  getHospital,
  resetHopitalState,
  updateHospital,
  getHospitalAvailableTime,
  getAllHospitals,
  getServiceHospital,
} from '../../redux/hospital/action';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const getSelector = key => state => state.hospital[key];

const loadingSelector = getSelector('isLoading');
const hospitalsSelector = getSelector('hospitals');
const getHospitalsFailedSelector = getSelector('getHospitalsFailed');

const isGetHospitalLoadingSelector = getSelector('isGetHospitalLoading');
const hospitalSelector = getSelector('hospital');
const getHospitalFailedSelector = getSelector('getHospitalFailed');

const isGetServiceHospitalLoadingSelector = getSelector('isGetServiceHospitalLoading');
const ServiceHospitalSelector = getSelector('ServiceHospital');
const getServiceHospitalFailedSelector = getSelector('getServiceHospitalFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const isGetAvaiableTimesLoadingSelector = getSelector(
  'isGetAvailableTimesLoading',
);
const availableTimesSelector = getSelector('availableTimes');
const getAvailableTimesFailedSelector = getSelector('getAvailableTimesFailed');

const getAllLoadingSelector = getSelector('isGetAllHospitalsLoading');
const allHospitalsSelector = getSelector('allHospitals');
const getAllHospitalsFailedSelector = getSelector('getAllHospitalsFailed');

const pagingSelector = getSelector('paging');
export const useHospitalPaging = () => useSelector(pagingSelector);

export const useHospitalQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const deleteHopitalSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;

    if (page !== 1) {
      setPage(1);
    }
  }

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword: keyword || undefined,
    };

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [page, limit, keyword, deleteHopitalSuccess]);
};

export const useHospitals = query => {
  return useFetchByParam({
    action: getHospitals,
    loadingSelector,
    dataSelector: hospitalsSelector,
    failedSelector: getHospitalsFailedSelector,
    param: query,
  });
};

export const useAllHospitals = () => {
  return useFetch({
    action: getAllHospitals,
    loadingSelector: getAllLoadingSelector,
    dataSelector: allHospitalsSelector,
    failedSelector: getAllHospitalsFailedSelector,
  });
};

export const useHospitalOptions = () => {
  return useFetch({
    action: getHospitals,
    loadingSelector,
    dataSelector: hospitalsSelector,
    failedSelector: getHospitalsFailedSelector,
  });
};

export const useHospitalAvailableTimes = hospitalId => {
  return useFetchByParam({
    action: getHospitalAvailableTime,
    loadingSelector: isGetAvaiableTimesLoadingSelector,
    dataSelector: availableTimesSelector,
    failedSelector: getAvailableTimesFailedSelector,
    param: hospitalId,
  });
};

export const useCreateHospital = () => {
  const history = useHistory();
  const callback = () => history.push('/hospital');

  useSuccess(createSuccessSelector, 'Tạo mới phòng khám thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới phòng khám thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createHospital,
  });
};

export const useUpdateHospital = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật phòng khám thành công');
  useFailed(updateFailedSelector, 'Cập nhật phòng khám thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateHospital,
  });
};

export const useDeleteHospital = () => {
  useSuccess(deleteSuccessSelector, 'Xoá phòng khám thành công');
  useFailed(deleteFailedSelector, 'Xoá phòng khám thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteHospital,
  });
};

export const useHospital = id => {
  return useFetchByParam({
    action: getHospital,
    loadingSelector: isGetHospitalLoadingSelector,
    dataSelector: hospitalSelector,
    failedSelector: getHospitalFailedSelector,
    param: id,
  });
};
export const useServiceOfHospital = id => {
  return useFetchByParam({
    action: getServiceHospital,
    loadingSelector: isGetServiceHospitalLoadingSelector,
    dataSelector: ServiceHospitalSelector,
    failedSelector: getServiceHospitalFailedSelector,
    param: id,
  });
};

export const initWorkingHours = [
  {
    weekDay: 0,
    isOpen: true,
    startTime: moment('08:00', 'HH:mm'),
    endTime: moment('17:00', 'HH:mm'),
  },
  {
    weekDay: 1,
    isOpen: false,
    startTime: moment('08:00', 'HH:mm'),
    endTime: moment('17:00', 'HH:mm'),
  },
  {
    weekDay: 2,
    isOpen: true,
    startTime: moment('08:00', 'HH:mm'),
    endTime: moment('17:00', 'HH:mm'),
  },
  {
    weekDay: 3,
    isOpen: false,
    startTime: moment('08:00', 'HH:mm'),
    endTime: moment('17:00', 'HH:mm'),
  },
  {
    weekDay: 4,
    isOpen: true,
    startTime: moment('08:00', 'HH:mm'),
    endTime: moment('17:00', 'HH:mm'),
  },
  {
    weekDay: 5,
    isOpen: false,
    startTime: moment('08:00', 'HH:mm'),
    endTime: moment('17:00', 'HH:mm'),
  },
  {
    weekDay: 6,
    isOpen: false,
    startTime: moment('08:00', 'HH:mm'),
    endTime: moment('17:00', 'HH:mm'),
  },
];

export const useInitHospital = hospital => {
  return useMemo(() => {
    if (!hospital) {
      return {
        workingHours: initWorkingHours,
        services: [
          {
            name: '',
            description: '',
            items: [],
          },
        ],
      };
    }

    return {
      ...hospital,
      workingHours: hospital.workingHours.map(hour => ({
        ...hour,
        startTime: moment(hour.startTime, 'HH:mm'),
        endTime: moment(hour.endTime, 'HH:mm'),
      })),
      speciality: hospital.speciality,
    };
  }, [hospital]);
};

export const useResetHopital = () => {
  useResetState(resetHopitalState);
};
