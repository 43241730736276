import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useResetState, useFetchByParam, useBranchIdSessionStorage } from '~/hooks/utils';

import {
  getOverViewReport,
  getYearlyReport,
  getQuarterlyReport,
  getMonthlyReport,
  getDailyReport,
  getMostService,
  getMostRevenueService,
  getMostPartnerAppointment,
  getMostCustomerAppointment,
  getOverviewToday,
  getOverviewDaily,
  getOverviewMonthly,
  getOverviewQuarterly,
  getOverviewYearly,
  resetDashboardState
} from '~/redux/action';

const DASHBOARD_MODULE = 'dashboard';
const getSelector = (key) => (state) => state[DASHBOARD_MODULE][key];

const overViewSelector = getSelector('overView');
const overViewLoadingSelector = getSelector('isOverViewLoading');
const getOverViewFailedSelector = getSelector('getOverViewFailed');

const yearlySelector = getSelector('yearly');
const yearlyLoadingSelector = getSelector('isYearlyLoading');
const getYearlyFailedSelector = getSelector('getYearlyFailed');

const quarterlySelector = getSelector('quarterly');
const quarterlyLoadingSelector = getSelector('isQuarterlyLoading');
const getQuarterlyFailedSelector = getSelector('getQuarterlyFailed');

const monthlySelector = getSelector('monthly');
const monthlyLoadingSelector = getSelector('isMonthlyLoading');
const getMonthlyFailedSelector = getSelector('getMonthlyFailed');

const dailySelector = getSelector('daily');
const dailyLoadingSelector = getSelector('isDailyLoading');
const getDailyFailedSelector = getSelector('getDailyFailed');

const mostServiceSelector = getSelector('mostService');
const mostServiceLoadingSelector = getSelector('isMostServiceLoading');
const getMostServiceFailedSelector = getSelector('getMostServiceFailed');

const mostRevenueServiceSelector = getSelector('mostRevenueService');
const mostRevenueServiceLoadingSelector = getSelector('isMostRevenueServiceLoading');
const getMostRevenueServiceFailedSelector = getSelector('getMostRevenueServiceFailed');

const mostPartnerAppointmentSelector = getSelector('mostPartnerAppointment');
const mostPartnerAppointmentLoadingSelector = getSelector('isMostPartnerAppointmentLoading');
const getMostPartnerAppointmentFailedSelector = getSelector('getMostPartnerAppointmentFailed');

const mostCustomerAppointmentSelector = getSelector('mostCustomerAppointment');
const mostCustomerAppointmentLoadingSelector = getSelector('isMostCustomerAppointmentLoading');
const getMostCustomerAppointmentFailedSelector = getSelector('getMostCustomerAppointmentFailed');

const overviewTodaySelector = getSelector('overviewToday');
const overviewTodayLoadingSelector = getSelector('isOverviewTodayLoading');
const getOverviewTodayFailedSelector = getSelector('getOverviewTodayFailed');

const overviewDailySelector = getSelector('overviewDaily');
const overviewDailyLoadingSelector = getSelector('isOverviewDailyLoading');
const getOverviewDailyFailedSelector = getSelector('getOverviewDailyFailed');

const overviewMonthlySelector = getSelector('overviewMonthly');
const overviewMonthlyLoadingSelector = getSelector('isOverviewMonthlyLoading');
const getOverviewMonthlyFailedSelector = getSelector('getOverviewMonthlyFailed');

const overviewQuarterlySelector = getSelector('overviewQuarterly');
const overviewQuarterlyLoadingSelector = getSelector('isOverviewQuarterlyLoading');
const getOverviewQuarterlyFailedSelector = getSelector('getOverviewQuarterlyFailed');

const overviewYearlySelector = getSelector('overviewYearly');
const overviewYearlyLoadingSelector = getSelector('isOverviewYearlyLoading');
const getOverviewYearlyFailedSelector = getSelector('getOverviewYearlyFailed');

const policiesSelector = (state) => state.user.policy;

export const useChartDashboardQueryParams = () => {
  const [branchId] = useBranchIdSessionStorage();
  return useMemo(() => {
    const queryParams = {
      branchId
    };

    return [queryParams];
    //eslint-disable-next-line
  }, [branchId]);
};

export const useOverView = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getOverViewReport,
    loadingSelector: overViewLoadingSelector,
    dataSelector: overViewSelector,
    failedSelector: getOverViewFailedSelector,
    param: query
  });
};

export const useYearly = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getYearlyReport,
    loadingSelector: yearlyLoadingSelector,
    dataSelector: yearlySelector,
    failedSelector: getYearlyFailedSelector,
    param: query
  });
};

export const useQuarterly = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getQuarterlyReport,
    loadingSelector: quarterlyLoadingSelector,
    dataSelector: quarterlySelector,
    failedSelector: getQuarterlyFailedSelector,
    param: query
  });
};

export const useMonthly = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getMonthlyReport,
    loadingSelector: monthlyLoadingSelector,
    dataSelector: monthlySelector,
    failedSelector: getMonthlyFailedSelector,
    param: query
  });
};

export const useDaily = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getDailyReport,
    loadingSelector: dailyLoadingSelector,
    dataSelector: dailySelector,
    failedSelector: getDailyFailedSelector,
    param: query
  });
};

export const useMostService = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getMostService,
    loadingSelector: mostServiceLoadingSelector,
    dataSelector: mostServiceSelector,
    failedSelector: getMostServiceFailedSelector,
    param: query
  });
};

export const useMostRevenueService = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getMostRevenueService,
    loadingSelector: mostRevenueServiceLoadingSelector,
    dataSelector: mostRevenueServiceSelector,
    failedSelector: getMostRevenueServiceFailedSelector,
    param: query
  });
};

export const useMostPartnerAppointment = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getMostPartnerAppointment,
    loadingSelector: mostPartnerAppointmentLoadingSelector,
    dataSelector: mostPartnerAppointmentSelector,
    failedSelector: getMostPartnerAppointmentFailedSelector,
    param: query
  });
};

export const useMostCustomerAppointment = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(() => policies, [policies]);

  return useFetchByParam({
    action: getMostCustomerAppointment,
    loadingSelector: mostCustomerAppointmentLoadingSelector,
    dataSelector: mostCustomerAppointmentSelector,
    failedSelector: getMostCustomerAppointmentFailedSelector,
    param : query
  });
};

export const useOverviewToday = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getOverviewToday,
    loadingSelector: overviewTodayLoadingSelector,
    dataSelector: overviewTodaySelector,
    failedSelector: getOverviewTodayFailedSelector,
    param : query
  })
}

export const useOverviewDaily = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getOverviewDaily,
    loadingSelector: overviewDailyLoadingSelector,
    dataSelector: overviewDailySelector,
    failedSelector: getOverviewDailyFailedSelector,
    param : query
  })
}

export const useOverviewMonthly = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getOverviewMonthly,
    loadingSelector: overviewMonthlyLoadingSelector,
    dataSelector: overviewMonthlySelector,
    failedSelector: getOverviewMonthlyFailedSelector,
    param: query
  })
}

export const useOverviewQuarterly = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getOverviewQuarterly,
    loadingSelector: overviewQuarterlyLoadingSelector,
    dataSelector: overviewQuarterlySelector,
    failedSelector: getOverviewQuarterlyFailedSelector,
    param: query
  })
}

export const useOverviewYearly = (query) => {
  // const policies = useSelector(policiesSelector);
  // const param = useMemo(()=> policies, [policies]);

  return useFetchByParam({
    action: getOverviewYearly,
    loadingSelector: overviewYearlyLoadingSelector,
    dataSelector: overviewYearlySelector,
    failedSelector: getOverviewYearlyFailedSelector,
    param: query
  })
}

export const useResetDashboard = () => {
  useResetState(resetDashboardState);
};
