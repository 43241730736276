import requester from './requester';

const JobForm = {
  create: (course) => requester.post('/job-form', course),
  update: (query) => requester.put(`/job-form/${query?._id}`,query),
  delete: (id) => requester.delete(`/job-form/${id}`),
  getAll: (query) => requester.get('/job-form', query),
  getOne: (id) => requester.get(`/job-form/${id}`),
};
export default JobForm;

