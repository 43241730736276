import React from 'react';

import { Col, Form, InputNumber, Row, Select, Skeleton, Space } from 'antd';
import { get } from 'lodash';

import { CURRENCY } from '~/constants/defaultValue';
import { formatter, humanize } from '~/utils/helper';

import './index.scss';

const FormItem = Form.Item;
const { Option } = Select;

const timeUnits = [
  { text: { vi: "Phút", en: "Minute" }, value: "Phút" },
  { text: { vi: "Giờ", en: "Hour" }, value: "Giờ" },
];

const WhServiceSessionEdit = ({
  className,
  form,
  index,
  isLoading,
  language,
  name,
  sessionIndex,
  // setWhServiceQuestionOptionImg,
}) => {
  const getBlockInfo = (packageIdx, sessionIdx) => {
    const session = form.getFieldValue(["packages", packageIdx, "sessionPrices", sessionIdx])
    if (session.price && session.minimumQuantity && session.timeUnit) {
      const blockPrice = session.price * session.minimumQuantity
      const blockDuration = session.duration * session.minimumQuantity;
      const timeUnit = get(timeUnits.find(item => item.value === session.timeUnit), "text.vi")
      return `${formatter(blockPrice)} ${CURRENCY.VND} / ${humanize(timeUnit, blockDuration)}`
    }
  }

  const myValidator = ({ getFieldValue }) => ({
    validator(_, value) {
      const status = getFieldValue(['packages', index, 'sessionPrices', sessionIndex, 'status'])
      const sessionPriceItemId = getFieldValue(['packages', index, 'sessionPrices', sessionIndex, '_id'])

      // only reject when:
      //   value is blank and the session status is `ACTIVE`
      //   value is blank and the session status is `INACTIVE` and has existing sessionPriceItemId
      if ((!status && !sessionPriceItemId) || (!!value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Vui lòng nhập/chọn giá trị'));
    }
  })

  return (
    <div className={className}>
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <Row gutter={48} justify="start">
          <Col sm={24} lg={8}>
            <FormItem
              labelAlign='left'
              label="Thời gian thực hiện (phút)"
              name={[name, 'duration']}
              labelCol={{ sm: 24, md: 24, lg: 16 }}
              wrapperCol={{ sm: 24, md: 24, lg: 8 }}
              rules={[myValidator]}
            >
              {isLoading ? <Skeleton.Input active /> : <InputNumber />}
            </FormItem>
          </Col>
          <Col sm={24} lg={8}>
            <FormItem
              labelAlign='left'
              label="Đơn vị tính"
              name={[name, 'timeUnit']}
              labelCol={{ sm: 24, md: 24, lg: 12 }}
              wrapperCol={{ sm: 24, md: 24, lg: 12 }}
              rules={[myValidator]}
            >
              {isLoading ? (
                <Skeleton.Input active /> 
              ) : (
                <Select
                  // disabled={isWhCategoriesLoading}
                  // filterOption={filterAcrossAccents}
                  // loading={isWhCategoriesLoading}
                  // onChange={setSelectedCityCode}
                  // showSearch
                  // style={{width: "100%"}}
                  autoComplete="off"
                  placeholder="Đơn vị tính"
                >
                  {/* {form.getFieldValue(["packages", index, "sessionPrices"])?.map(({ _id, whSessionOfDay }) => (
                    <Option key={_id} value={_id}>
                      {get(whSessionOfDay, 'name')}
                    </Option>
                  ))} */}
                  {timeUnits.map(({ _id, text, value }) => (
                    <Option key={value} value={value}>
                      {get(text, language)}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col sm={24} lg={8}>
            <FormItem
              labelAlign='left'
              label="Số lần tối thiểu"
              name={[name, 'minimumQuantity']}
              labelCol={{ sm: 24, md: 24, lg: 16 }}
              wrapperCol={{ sm: 24, md: 24, lg: 8 }}
              rules={[myValidator]}
            >
              {isLoading ? <Skeleton.Input active /> : <InputNumber />}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={48} justify="start">
          <Col sm={24} lg={24}>
            <FormItem
              labelAlign='left'
              label="Đơn giá (chưa VAT)"
              name={[name, 'price']}
              labelCol={{ sm: 24, md: 24, lg: 8 }}
              wrapperCol={{ sm: 24, md: 24, lg: 24 }}
              rules={[myValidator]}
            >
              {isLoading
                ? <Skeleton.Input active />
                : <InputNumber
                    formatter={formatter}
                  />
              }
            </FormItem>
          </Col>
        </Row>
        <Row gutter={48} justify="start">
          <Col sm={24} lg={24}>
            <FormItem
              labelAlign='left'
              label="Block"
              // noStyle
              labelCol={{ sm: 24, md: 24, lg: 8 }}
              shouldUpdate
            >
              {() => getBlockInfo(index, sessionIndex)}
            </FormItem>
            {/* <FormItem
              labelAlign='left'
              label="Số lần tối thiểu"
              name={[name, 'price']}
              labelCol={{ sm: 24, md: 24, lg: 8 }}
              wrapperCol={{ sm: 24, md: 24, lg: 24 }}
            >
              {isLoading ? <Skeleton.Input active /> : <InputNumber />}
            </FormItem> */}
          </Col>
        </Row>
        <Row gutter={48} justify="start">
          <Col sm={24} lg={24}>
            <h5>Thời gian tăng thêm mỗi buổi</h5>
          </Col>
        </Row>
        <Row gutter={48} justify="start">
          <Col sm={24} lg={8}>
            <FormItem
              labelAlign='left'
              label="Thời gian"
              name={[name, 'extraDuration']}
              labelCol={{ sm: 24, md: 24, lg: 16 }}
              wrapperCol={{ sm: 24, md: 24, lg: 8 }}
              rules={[]}
            >
              {isLoading ? <Skeleton.Input active /> : <InputNumber />}
            </FormItem>
          </Col>
          <Col sm={24} lg={8}>
            <FormItem
              labelAlign='left'
              label="Đơn vị tính"
              name={[name, 'extraTimeUnit']}
              labelCol={{ sm: 24, md: 24, lg: 12 }}
              wrapperCol={{ sm: 24, md: 24, lg: 12 }}
              rules={[]}
            >
              {isLoading ? (
                <Skeleton.Input active /> 
              ) : (
                <Select
                  // disabled={isWhCategoriesLoading}
                  // filterOption={filterAcrossAccents}
                  // loading={isWhCategoriesLoading}
                  // onChange={setSelectedCityCode}
                  // showSearch
                  // style={{width: "100%"}}
                  autoComplete="off"
                  placeholder="Đơn vị tính"
                >
                  {timeUnits.map(({ _id, text, value }) => (
                    <Option key={value} value={value}>
                      {get(text, language)}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col sm={24} lg={8}>
            <FormItem
              labelAlign='left'
              label="Số tiền"
              name={[name, 'extraPrice']}
              labelCol={{ sm: 24, md: 24, lg: 16 }}
              wrapperCol={{ sm: 24, md: 24, lg: 8 }}
              rules={[]}
            >
              {isLoading
                ? <Skeleton.Input active />
                : <InputNumber
                    formatter={formatter}
                  />
              }
            </FormItem>
          </Col>
        </Row>
        <Row gutter={48} justify="start">
          <Col sm={24} lg={24}>
            <h5>Media</h5>
          </Col>
        </Row>
      </Space>
    </div>
  )
}

export default WhServiceSessionEdit;
