import React, { useState } from 'react';
import { Button, Dropdown, Menu, Space } from 'antd';
import { ApiFilled, DownloadOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import Api from '~/api';
import { BASE_URL } from '~/constants/defaultValue';
import { forIn } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import './index.scss'
import { useFailed } from '~/hooks/utils';
import toastr from 'toastr';

const ExportExcelButton = ({ size, stylesButton, propsButton }) => {
  
  const defaultStyles = {
    position: 'absolute',
    top: '200px',
    right: "100px",
    width: '150px',
  } 
  const handleOnClick = async (obj) => {
    if (propsButton || propsButton?.fileName_ || propsButton?.url || propsButton?.exportOption) {
      const { fileName_ } = propsButton
      const query = {
        exportOption: propsButton?.keyExport
      }
      const { searchBy } = propsButton
      // let a = !searchBy ? `?exportByCase=${obj}&` : `&exportByCase=${obj}&`
      let a =  `?` 
      forIn({ ...query }, (value, key) => {
        a = a + key + '=' + value 
      })
      const dateNow = moment(Date.now()).format("DD-MM-YYYY HH:mm")
      const keyExportUrl = '/api/v1/export';
      const linkUrl = keyExportUrl.concat(propsButton.url)
      // Handle value search when it exist
      if (searchBy) {
        let params = searchBy?.split('?');
        let newSearch = `&${params.join('&')}`;
        a = (a.concat(newSearch))
        a = a.replace('?&', '&') || a.replace('&&', '&') 
      }
      if (a.includes('&&')) {
        a = a.replace('&&', '&') 
      }
      if (a.includes('?&')) {
        a = a.replace('?&', '&') 
      }
      switch (obj) {
        case '1':
          // ?.filter(string => !string?.includes("page=") && !string?.includes("limit="));
          let setQuery = a.split("&")?.filter(string => !string?.includes("page=") && !string?.includes("limit="));
          a = setQuery.join("&")
          break;
        case '2':
          if (!propsButton?.ids.length) {
            toastr.error('Không tồn tại lựa chọn nào!')
           a = ''
          } else {
            a = a.concat(`&ids=${propsButton?.ids || ''}`)
          }
          break;
        case '3':
          let newA = ''
          const newKeyExport = a.split('&')[0].concat("Page&")
          searchBy ?  newA = searchBy.replace('?', '') : ()=>{}
          a = newKeyExport.concat(newA)
          a = a.includes("&&") ? a.replace("&&", "&") : a 
          break;
        default:
          break;
      }
      const temp = BASE_URL.concat(linkUrl,a )
     a !== '' ?   axios.get(temp, {
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target',"_blank");
        link.setAttribute('download', `${fileName_}_${dateNow}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }) : ()=>{};
    } else {
      console.log('cannot export excel file')
    }
  }
  
  return (
    <Dropdown.Button
      overlay={<MenuButton handle={ handleOnClick} />}
      onClick = {()=>handleOnClick( "1" )}
      trigger={['hover']}
      style={{
        margin: '0px 10px '
      }}
      // style={{
      // position: `${stylesButton?.position || defaultStyles.position}`,
      // top: `${stylesButton?.top || defaultStyles.top}`,
      //   right: `${stylesButton?.right || defaultStyles.right}`,
      // width: `${defaultStyles.width}`
      // }}
      type="primary"
      size={size ? size : "middle"}
    >
      Tải về
      </Dropdown.Button>
      
  )
    
};
export default ExportExcelButton;


function MenuButton({ handle }) {
  return (
    <Menu>
      <Menu.Item>
        <Button className='button-export__children'  type='primary' size='small' onClick={()=>handle("2")} >Theo lựa chọn</Button>
      </Menu.Item>
      <Menu.Item>
        <Button className='button-export__children' type='primary' size='small'  onClick={()=>handle( "3")}>Theo trang hiện tại</Button>
      </Menu.Item>
    </Menu>
  )
}