import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getInfoCompanysFailed: null,
  getInfoCompanyFailed: null,
  InfoCompanys: [],
  InfoCompany: null,
  isSubmitLoading: false,
  isGetInfoCompanyLoading: false,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,

  createSuccess: null,
  createFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_INFO_COMPANYS_REQUEST:
      state.isLoading = true;
      state.getInfoCompanysFailed = null;
      return;

    case Types.GET_INFO_COMPANYS_SUCCESS:
      state.isLoading = false;
      state.InfoCompanys = get(payload, 'docs', []);
      state.getInfoCompanysFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_INFO_COMPANYS_FAILED:
      state.isLoading = false;
      state.InfoCompanys = [];
      state.getInfoCompanysFailed = payload;
      return;
    case Types.GET_INFO_COMPANY_REQUEST:
      state.isGetInfoCompanyLoading = true;
      state.InfoCompany = null
      state.getInfoCompanyFailed = null;
      return;

    case Types.GET_INFO_COMPANY_SUCCESS:
      state.isGetInfoCompanyLoading = false;
      state.InfoCompany = payload;
      state.getInfoCompanyFailed = null;
      return;

    case Types.GET_INFO_COMPANY_FAILED:
      state.isGetInfoCompanyLoading = false;
      state.InfoCompany = null;
      state.getInfoCompanyFailed = payload;
      return;
    case Types.CREATE_INFO_COMPANY_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_INFO_COMPANY_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_INFO_COMPANY_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_INFO_COMPANY_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_INFO_COMPANY_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.InfoCompanys = state.InfoCompanys.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.InfoCompany = payload;
      return;

    case Types.UPDATE_INFO_COMPANY_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_INFO_COMPANY_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_INFO_COMPANY_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_INFO_COMPANY_ACTION:
      state.getInfoCompanysFailed = null;
      state.getInfoCompanyFailed = null;
      state.InfoCompany = null;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      state.updateSuccess = null;
      state.updateFailed = null;
      state.createSuccess = null;
      state.createFailed = null;
      return ;

    case Types.RESET_STORE:
    case Types.RESET_INFO_COMPANY_STATE:
      return initState;

    default:
      return;
  }
}, initState);
