import { DatePicker, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '~/api';
import {
  getSelectors,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createWallet,
  deleteWallet,
  getWallet,
  getWallets,
  resetWalletState,
  updateWallet,
  updateTrainingCoursePartnerInWallet
} from '~/redux/action';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.wallet[key];
const pagingSelector = getSelector('paging');
export const useWalletPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const getWalletsFailedSelector = getSelector('getWalletsFailed');

const walletsSelector = getSelector('wallets');

export const useWalletQueryParams = (userId,reFetch) => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      userId
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    userId,
    reFetch
  ]);
};

export const useUpdateWalletParams = (query) => {
  const history = useHistory();
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || '/workspace/wallet',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [{ onParamChange }];
};
export const useWallets = (query) => {
  return useFetchByParam({
    action: getWallets,
    loadingSelector,
    dataSelector: walletsSelector,
    failedSelector: getWalletsFailedSelector,
    param: query
  });
};


export const useResetWallet = () => {
  useResetState(resetWalletState);
};

export const useWalletParnter = (userId,reFetch) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const res = await api.wallet.getOneUser({ userId });
        setData(get(res,'totalAmount.totalCash',0));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetch();
  }, [userId,reFetch]);
  return [data, loading];
};
export const useWalletCustomer = (userId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reFetch,setRefetch] = useState(false);
  const mutate = () => setRefetch(!reFetch);
  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const res = await api.wallet.getOneUser({ userId });
        setData({
          totalRefund : get(res,'totalAmount.totalRefund',0)
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetch();
  }, [userId,reFetch]);
  return [data, loading,mutate];
};
