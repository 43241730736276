export const TOGGLE_THEME = 'TOGGLE_THEME';
export const TOGGLE_THEME_SUCCESS = 'TOGGLE_THEME_SUCCESS';
export const TOGGLE_THEME_FAILED = 'TOGGLE_THEME_FAILED';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const SUBSCRIBE_FCM_FIREBASE_REQUEST = 'SUBSCRIBE_FCM_FIREBASE_REQUEST';
export const SUBSCRIBE_FCM_FIREBASE_SUCCESS = 'SUBSCRIBE_FCM_FIREBASE_SUCCESS';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const LOGIN_WORKSPACE_REQUEST = 'LOGIN_WORKSPACE_REQUEST';
export const LOGIN_WORKSPACE_SUCCESS = 'LOGIN_WORKSPACE_SUCCESS';
export const LOGIN_WORKSPACE_FAILED = 'LOGIN_WORKSPACE_FAILED';

export const LOGOUT_WORKSPACE_REQUEST = 'LOGOUT_WORKSPACE_REQUEST';

export const SELECT_BRANCH = 'SELECT_BRANCH';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const GET_USER_POLICY_REQUEST = 'GET_USER_POLICY_REQUEST';
export const GET_USER_POLICY_SUCCESS = 'GET_USER_POLICY_SUCCESS';
export const GET_USER_POLICY_FAILED = 'GET_USER_POLICY_FAILED';

export const RESET_STORE = 'RESET_STORE';
export const GET_DATA_FOR_NAVBAR = 'GET_DATA_FOR_NAVBAR';

export const CHANGE_BRANCH_ID_SESSION = 'CHANGE_BRANCH_ID_SESSION';
export const PINED_NAVBAR = 'PINED_NAVBAR';
