import { Button, Form, Modal, Select, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { get, head, keys } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import FormVoucherWarehouse from '~/components/WareHouse/FormVoucher/FormVoucherWarehouse';
import ExpandableVoucher from '~/components/WareHouse/VoucherWareHouse/ExpandableVoucher';
import { MODE_VOUCHER_WAREHOUSE, REF_COLLECTION_VOUCHER_WAREHOUSE, TYPE_VOUCHER_WAREHOUSE, TYPE_VOUCHER_WAREHOUSE_VI } from '~/constants/defaultValue';
import { STATUS_WAREHOUSE_VOUCHER_VI } from '~/constants/warehouse';
import { useResetWarehouseVoucher, useUpdateWarehouseVoucherParams, useWarehouseVoucher, useWarehouseVoucherPaging, useWarehouseVoucherQueryParams, useWarehouseVouchersOfPartner } from '~/hooks/warehouseVoucher';
const listOptionSearch = [
  {
    value: 'codeCommerPurchVoucher',
    label: 'Mã phiếu'
  },
  // {
  //   value: 'User',
  //   label: 'Nhân viên'
  // },
  // {
  //   value: 'client',
  //   label: 'Đối tượng'
  // },
  // {
  //   value: 'note',
  //   label: 'Ghi chú'
  // },
];
export default function TabWarehouseVoucher() {
  const [form] = Form.useForm();
  const [selectSearch, setSelectSearch] = useState(
    head(listOptionSearch).value
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [typeVoucher, setTypeVoucher] = useState(null);
  const [id, setId] = useState(null);
  const [itemActive, setItemActive] = useState(null);
  const handleOpenModal = (id) => {
    setIsOpenModal(true);
    if (id) {
      setId(id);
    }
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setId(null);
    setTypeVoucher(null);
  };
  // hook

  const [query] = useWarehouseVoucherQueryParams();
  const [warehouseVouchers, isLoading] = useWarehouseVouchersOfPartner(query);
  const paging = useWarehouseVoucherPaging(query);
  const [keyword, { setKeyword, onParamChange }] =
    useUpdateWarehouseVoucherParams(query, listOptionSearch);
  useResetWarehouseVoucher();
  //
  const handleChangeSelectSearch = (value) => {
    setSelectSearch(value);
    setKeyword('');
  };
  ////////////////////////////////
  useEffect(() => {
    const { typeVoucher } = query
    form.setFieldsValue({ typeVoucher: typeVoucher || null })
  }, [query])
  useEffect(() => {
    let value = listOptionSearch?.find(e => query[e.value])
    setSelectSearch(get(value, 'value', head(listOptionSearch).value))
    setKeyword(query[get(value, 'value', '')])
  }, [])
  const onValuesChange = (value, allValues) => {
    onParamChange({ ...allValues, ...value })
  }

  const columns = [
    {
      title: 'Mã phiếu',
      dataIndex: 'codeCommerPurchVoucher',
      key: 'codeCommerPurchVoucher',
      align: 'center',
      render: (item, record, index) => <div className='d-flex flex-column align-items-center'>
        <Typography.Link onClick={() => handleOpenModal(record._id)}>{get(record, 'typeVoucher', '') + item}</Typography.Link>
        <span className={`confirm-voucher-status ${get(record, 'status').toLowerCase()}`}>
          {STATUS_WAREHOUSE_VOUCHER_VI[get(record, 'status')]}
        </span>
        <p>{moment(get(record, 'createdAt')).format("DD-MM-YYYY HH:mm:ss")}</p>
      </div>

    },
    // {
    //   title: 'Nhân viên',
    //   dataIndex: 'User',
    //   key: 'User',
    //   render: (item, record) => get(item, 'fullName', '')
    // },
  ];
  return (
    <div >
      <div className="container-fluid">
        <div className="page-content__main">
          {/* <div className="page-content__left">
            <Form
              form={form}
              autoComplete="off"
              onFinish={() => { }}
              scrollToFirstError
              onValuesChange={onValuesChange}
              requiredMark={false}
              labelCol={{ sm: 24, md: 24, lg: 24 }}
            >
              <Form.Item label='Loại phiếu' name='typeVoucher'>
                <Select allowClear options={keys(TYPE_VOUCHER_WAREHOUSE)?.map(option => ({ label: TYPE_VOUCHER_WAREHOUSE_VI[option], value: option }))} />
              </Form.Item>
            </Form>
          </div> */}
          <div className="page-content__right" style={{ flex: 1 }}>
            <div
              className="page-wraper__header headerPartner"
              style={{ justifyContent: 'space-between', display: 'flex'}}
            >
              <div>
                <Select
                  value={selectSearch}
                  style={{
                    width: 120
                  }}
                  onChange={handleChangeSelectSearch}
                  options={listOptionSearch}
                />

                <Search
                  style={{ width: 250 }}
                  placeholder={`Tìm ${get(listOptionSearch.find((e) => e.value === selectSearch), 'label', '')
                    }`}
                  enterButton
                  allowClear
                  onSearch={(value) => {
                    onParamChange({ [selectSearch]: value });
                  }}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />

              </div>
              <Button onClick={() => {
                handleOpenModal();
                setTypeVoucher(TYPE_VOUCHER_WAREHOUSE.PHT)
              }} type='primary'>
                Tạo phiếu hoàn trả
              </Button>
            </div>
            {isLoading ? (
              <SkeletonTable columns={columns} rowCount={10} />
            ) : (
              <Table
                rowKey={(rc) => rc._id}
                columns={columns}
                dataSource={warehouseVouchers}
                size="middle"
                onChange={({ current }) => onParamChange({ page: current })}
                pagination={{
                  ...paging,
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}

              />
            )}
          </div>
        </div>
      </div>
      <Modal
        width={'auto'}
        visible={isOpenModal}
        footer={null}
        onCancel={handleCloseModal}
        destroyOnClose
        className='custom-modal-responsive'
      >
        <FormVoucherWarehouse handleByPartner={true} id={id} typeVoucher={typeVoucher} onCancel={handleCloseModal} />
      </Modal>
    </div>
  );
}
