import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Table,
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  Typography,
  Popconfirm
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import { MAX_PAGINATION_LIMIT } from '~/constants/defaultValue';
import { useProductCatalogues } from '~/hooks/productCatalogue';
import toastr from 'toastr';
import { filterAcrossAccents, filterAcrossAccentsByLabel } from '~/hooks/utils';
import api from '~/api';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  handleSave,
  selectOptions,
  children,
  disabled,
  language,
  required,
  defaultUnit,
  setKeyWord,
  ...restProps
}) => {
  const keyRule = {
    select: 'chọn',
    text: 'nhập',
    number: 'nhập'
  };
  let inputNode = <Input />;
  switch (inputType) {
    case 'number':
      inputNode = (
        <InputNumber
          disabled={disabled}
          min="1"
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          defaultValue={dataIndex === 'amount' && 1}
        />
      );
      break;
    case 'select':
      const options = selectOptions?.map((option) => (
          {
            label: option?.name[language],
            value: option?._id
          }
        ));
      inputNode = (
        <Select
          disabled={disabled}
        
          showSearch = {dataIndex === 'groupEquipmentId' ? true : false}
          onSelect={(value) => {
            console.log(value);
          }}
          filterOption= {filterAcrossAccentsByLabel}
          options={options}
          // onSearch={(e) => { 
          //   if (dataIndex === 'groupEquipmentId') {
          //     setKeyWord(e);
          //   }
          // }}
        />
      );
      break;
    default:
          inputNode = <Input disabled={disabled}/>;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: required,
              message: `Vui lòng ${keyRule[inputType]} ${title?.toLowerCase()}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function TabEquipment({ props, setDataEquipment,dataEquipment, medicalEquipmentTypeId, setIsTabClick, initWhService, whPackageLevelId, whServiceId }) {
  const { index, id, language, ...rest } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  // const [dataEquipment, setDataEquipment] = useState([]);
  const [defaultEditable, setDefaultEditable] = useState(false);
  const [keyword, setKeyWord] = useState(null);
  const defaultUnit = {
    vi: 'Cái',
    en: 'Piece'
  };
  const queryGroupProducts = useMemo(
    () => ({
      page: 1,
      limit: 200,
      name: keyword,
      action: 'ACTIVE',
      isAll : true
    }),
    [keyword]
  );

  const [groupProducts, isGroupProductsLoading] = useProductCatalogues(queryGroupProducts);
  const [optionsGroupProducts, setOptionsGroupProducts] = useState(groupProducts);
  const [otionsGroupProductsFilter, setOtionsGroupProductsFilter] = useState(optionsGroupProducts);

  /**
   * Check the row editing conditions
   * @param {Object} record
   * @returns {Boolean}
   */
  const isEditing = (record) => record._id === editingKey; //

  const filterDataSource = (data) => {
    const filteredData = data.filter((item) => item.groupEquipmentId !== '');
    return filteredData;
  };

  const handleCancel = (_id) => {
    setIsTabClick(true);
    setEditingKey('');
    setDefaultEditable(false);
    setDataEquipment(() => filterDataSource(dataEquipment));
  };
  const handleEdit = (record) => {
    form.setFieldsValue({
      groupEquipmentId: ``,
      amount: 1,
      unit: ``,
      note: '',
      ...record
    });
    setEditingKey(record._id);
    setDefaultEditable(true);
  };
  const handleSave = async (record) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataEquipment];
      const index = newData.findIndex((item) => record?._id === item._id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        setDataEquipment([...newData]);
        setEditingKey('');
      } else {
        newData.push(row);
        setDataEquipment([...newData]);
        setEditingKey('');
      };
      setDefaultEditable(false);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  function generateRandomFiveDigitNumber() {
    const min = 10000;
    const max = 99999;
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  /**
   * Handle add row
   */
  const handleAdd = () => {
    const newData = {
      _id: String(generateRandomFiveDigitNumber()), // Requires the generated id to be 5 numeric characters long
      groupEquipmentId: ``,
      medicalEquipmentTypeId: '',
      amount: 1,
      unit: defaultUnit[language],
      note: ''
    };
    setDataEquipment([newData,...dataEquipment]);
    handleEdit(newData);
  };

  const handleCheckDelete = async (record) => {
    let isDevice = { status: false, messenger: '' }
    let checkGroupProductInDataBase = initWhService?.packages[index]?.groupProductInits?.find(item => item?._id === record?._id);
    if (!checkGroupProductInDataBase) {
      const newData = dataEquipment.filter((item, objIndex) => item._id !== record?._id);
      setDataEquipment(newData);
    } else {
      const data = {
        type: 'CCDC',
        medicalEquipmentTypeId: medicalEquipmentTypeId,
        groupEquipmentId: record?.groupEquipmentId,
        whPackageLevelId: whPackageLevelId,
        whServiceId: whServiceId,
      };
      isDevice =  await api.whService.checkDevice(data);
        if (!isDevice?.status) {
        const newData = dataEquipment.filter((item, objIndex) => item._id !== record?._id);
        setDataEquipment(newData);
      } else {
        toastr.error((isDevice?.message) || 'Không thể xóa thiết bị này vì đang có lịch hẹn sử dụng nhóm công cụ dụng cụ này')
      };
    };
  };
  /**
   * Set option select for group equipment 
   */
  useEffect(() => { setOptionsGroupProducts(groupProducts) }, [groupProducts]);

  /**
  * Set value for Tab when dataEquipment change
  * @param {String} medicalEquipmentTypeId
  */

  useEffect(() => {
    const newDataSource = [...dataEquipment];
    const valueEditing = form.getFieldValue('groupEquipmentId');

    let newData = optionsGroupProducts.filter(item => {
      return newDataSource?.every(e => (e.groupEquipmentId !== item._id));
    });
    if (valueEditing !== '') {
      const item = optionsGroupProducts?.find((e) => e._id === valueEditing)
      newData.unshift(item);
    };
    setOtionsGroupProductsFilter(newData);
  }, [dataEquipment, optionsGroupProducts, editingKey])

  const columns = [
    {
      title: 'Tên nhóm thiết bị',
      key: 'groupEquipmentId',
      dataIndex: 'groupEquipmentId',
      width: '300px',
      editable: true,
      disabled: false,
      inputType: 'select',
      required: true,
      selectOptions: otionsGroupProductsFilter,
      render: (value, record) => {
        const findItem = optionsGroupProducts?.find((item)=> item?._id === value)
        return findItem?.name[language]
      }
    },
    {
      title: 'Số lượng',
      key: 'amount',
      width: '100px',
      dataIndex: 'amount',
      disabled: false,
      editable: true,
      inputType: 'number',
      required: true
    },
    {
      title: 'Số lần',
      key: 'quantity',
      width: '100px',
      dataIndex: 'quantity',
      disabled: false,
      editable: true,
      inputType: 'number',
      required: true
    },
    {
      title: 'Đơn vị',
      key: 'unit',
      width: '150px',
      dataIndex: 'unit',
      disabled: true,
      editable: true,
      inputType: 'text',
      required: false,
    },
    {
      title: 'Ghi chú',
      disabled: false,
      width: '200px',
      inputType: 'text',
      dataIndex: 'note',
      editable: true,
      required: false
    },
    {
      title: '',
      disabled: false,
      dataIndex: 'operation',
      width: '150px',
      fixed: 'right',
      render: (_, record, index) => {
        const editable = isEditing(record);
        setIsTabClick(!editable)
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                handleSave(record);
              }}
              style={{
                marginRight: 8
              }}
            >
              Lưu
            </Typography.Link>
            <span style={{ margin: '0px 10px' }}>/</span>
            <Popconfirm
              title="Bạn có muốn huỷ chỉnh sửa?"
              onConfirm={() => handleCancel(record._id)}
            >
              <a>Huỷ</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => handleEdit(record)}
            >
              <EditOutlined />
            </Typography.Link>
            <span style={{ margin: '0px 10px' }}>/</span>
            <Popconfirm
              title="Bạn có chắc muốn xóa?"
              onConfirm={() => handleCheckDelete(record)}
            >
              <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  /**
   *
   * @returns {Array}
   */
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        required: col.required,
        editing: isEditing(record),
        selectOptions: col.selectOptions,
        inputType: col.inputType,
        disabled: col.disabled,
        handleSave,
        language,
        defaultUnit,
        setKeyWord,
        form
      })
    };
  });
  return (
      <Form form={form} component={false}>
        {isLoading ? (
          <SkeletonTable columns={columns} rowCount={5} />
        ) : (
          <>
            <Table
              columns={mergedColumns}
              dataSource={dataEquipment}
              components={{
                body: {
                  cell: EditableCell
                }
              }}
              bordered
              rowClassName="editable-row"
              pagination={false}
              scroll={{ x: 'auto', y: 400 }}
            />
            {!defaultEditable && (
              <Button
                onClick={handleAdd}
                type="dashed"
                style={{
                  marginBottom: 16
                }}
              >
                <PlusOutlined /> Thêm công cụ dụng cụ
              </Button>
            )}
          </>
        )}
      </Form>
  );
}
