import requester from './requester';

const hospitalList = {
  getAll: (query) => requester.get(`/healthcare-facility-address-list`, query),
  getById: (id) => requester.get(`/healthcare-facility-address/${id}?raw=true`),
  getByAddress: (query) => requester.get(`/healthcare-facility-address`,{...query,state:"ACTIVE"}),
  create: (hospitalList) =>
    requester.post('/healthcare-facility-address', hospitalList),
  update: (hospitalList) =>requester.put(
    `/healthcare-facility-address/${hospitalList._id}`,
    hospitalList
  )
    ,
  delete: (id) => requester.delete(`/healthcare-facility-address/${id}`),
  getByHealthcareFacilityId: (healthcareFacilityId) =>
    requester.get(`/healthcare-facility-address/parent/${healthcareFacilityId}`)
};

export default hospitalList;
