import requester from './requester';

const staffGroup = {
  getAll: query => requester.get('/employee-group', query),
  getAllJobQuota: query => requester.get('/employee-group-jobQuota/jobQuota',query),
  getById: id => requester.get(`/employee-group/${id}?raw=true`),
  delete: id => requester.delete(`/employee-group/${id}`),
  create: staffGroup => requester.post('/employee-group', staffGroup),
  createJobQuota: staffGroup => requester.post('/employee-group-jobQuota/jobQuota', staffGroup),
  getAllEmployyGroup: () => requester.get('/employee-group-jobQuota-all/jobQuota'),
  update: staffGroup =>
    requester.put(`/employee-group/${staffGroup.id}`, staffGroup),
  updateJobQuota: staffGroup =>
    requester.put(`/employee-group-jobQuota/jobQuota/${staffGroup.id}`, staffGroup)
};

export default staffGroup;
