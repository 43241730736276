import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getSpecialities() {
  try {
    const data = yield call(Api.speciality.getAll);
    yield put({ type: Types.GET_SPECIALITIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: Types.GET_SPECIALITIES_FAILED, payload: error.message });
  }
}

function* getSpeciality({ payload: id }) {
  try {
    const speciality = yield call(Api.speciality.getById, id);
    yield put({ type: Types.GET_SPECIALITY_SUCCESS, payload: speciality });
  } catch (error) {
    yield put({ type: Types.GET_SPECIALITY_FAILED, payload: error.message });
  }
}

function* createSpeciality(action) {
  try {
    const data = yield call(Api.speciality.create, action.payload);
    yield put({ type: Types.CREATE_SPECIALITY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_SPECIALITY_FAILED,
      payload: error.message
    });
  }
}

function* updateSpeciality(action) {
  try {
    const data = yield call(Api.speciality.update, action.payload);
    yield put({ type: Types.UPDATE_SPECIALITY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_SPECIALITY_FAILED,
      payload: error.message
    });
  }
}

function* deleteSpeciality({ payload }) {
  try {
    yield call(Api.speciality.delete, payload);
    yield put({ type: Types.DELETE_SPECIALITY_SUCCESS, payload });
  } catch (error) {
    yield put({ type: Types.DELETE_SPECIALITY_FAILED, payload: error.message });
  }
}

export default function* speciality() {
  yield takeLatest(Types.GET_SPECIALITIES_REQUEST, getSpecialities);
  yield takeLatest(Types.DELETE_SPECIALITY_REQUEST, deleteSpeciality);
  yield takeLatest(Types.GET_SPECIALITY_REQUEST, getSpeciality);
  yield takeLatest(Types.CREATE_SPECIALITY_REQUEST, createSpeciality);
  yield takeLatest(Types.UPDATE_SPECIALITY_REQUEST, updateSpeciality);
}
