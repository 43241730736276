import * as Types from '../../constants/actionTypes';
  
  export const getWhPartnerRegisters = query => ({
    type: Types.GET_WHPARTNERREGISTERS_REQUEST,
    payload: query
  });
  export const getWhPartnerRegister = id => ({
    type: Types.GET_WHPARTNERREGISTER_REQUEST,
    payload: id
  });
  export const createWhPartnerRegister = instance => ({
    type: Types.CREATE_WHPARTNERREGISTER_REQUEST,
    payload: instance
  });
  export const updateWhPartnerRegister = instance => ({
    type: Types.UPDATE_WHPARTNERREGISTER_REQUEST,
    payload: instance
  });
  export const deleteWhPartnerRegister = id => ({
    type: Types.DELETE_WHPARTNERREGISTER_REQUEST,
    payload: id
  });
  export const resetWhPartnerRegisterState = () => ({
    type: Types.RESET_WHPARTNERREGISTER_STATE
  });
  export const resetWhPartnerRegisterStateAction = () => ({
    type: Types.RESET_WHPARTNERREGISTER_STATE_ACTION
  });
