import { useEffect, useState } from 'react';

import { get, groupBy, head, keys, uniqBy } from 'lodash';

import moment from 'moment';
import api from '~/api';
import { ACTOR_REFERRAL, TYPE_OF_MONEY_REWARD_ITEM_VI, TYPE_OF_POINT_REWARD_ITEM_VI, TYPE_REWARD_ITEM_REFERRAL, TYPE_REWARD_ITEM_REFERRAL_VI } from '~/constants/defaultValue';
export const useGetMyProcessCampaign = (ranking) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const checkEndDate = (date) => date ? moment().isBefore(moment(date)) : true
    const checkStartDate = (date) => moment().isAfter(moment(date))
    const checkRanking = (requestRanking) => head(requestRanking) === "all" ? true : requestRanking?.some(e => e===get(ranking,'_id'))
    useEffect(() => {
      const fetch = async () => {
        setLoading(true)
        const process = await api.processCampaign.getByToken()

        // const removeUniqKeyActionProcess = uniqBy(process,'keyAction')
        
        const configCampaigns = await api.configCampaign.getAll({page : 1, limit : 200,actor:ACTOR_REFERRAL.partner})
  
        const configCampaignsCanUse = get(configCampaigns, 'docs', [])?.filter(config => get(config, 'status') === 'ACTIVE' && checkEndDate(get(config, 'condition.endDate')) && checkStartDate(get(config, 'condition.startDate')) && checkRanking(get(config,'rankings'))) // get Activity can use
  
          const groupByProcess = groupBy(process,(e) => get(e,'configCampaignId')) // group process By configCampaignId
        const mergeConfigCampaignsWithProcess = configCampaignsCanUse?.map(e => {
          const countProcess = groupByProcess[get(e,'_id')]?.reduce((sum,curr) => sum + get(curr,'value',0),0)
          return {...e,countProcess}
        })
        // const groupByProcess = groupBy(removeUniqKeyActionProcess,(e) => get(e,'configCampaignSnapshot.activity')) // group process By activity
  
        // const MergeConFigCanUseWithProcess = configCampaignsCanUse?.map(configCampaign => { // merge config campaign with Activity to get Total process for Each Activity
        //   let countProcess = 0
        //   if(groupByProcess[get(configCampaign,'activity')]){
        //     const filterDate = groupByProcess[get(configCampaign,'activity')]?.filter(processOne => moment(get(processOne,'createdAt')).isBetween(moment(get(configCampaign,'condition.startDate')),moment(get(configCampaign,'condition.endDate')),null,"()"))
        //     countProcess = filterDate?.reduce((sum,currently) => sum + get(currently,'value'),0)
        //   }
        //   return {...configCampaign,countProcess : countProcess ?? 0}
        // })
  
        // const groupByConfigCampaignByActivity = groupBy(MergeConFigCanUseWithProcess,(e) => get(e,'activity')) // Group config campaign by activity to Sort activity
        
        // let sortConfigCampaignByActivity = []
        // keys(groupByConfigCampaignByActivity)?.map(key => sortConfigCampaignByActivity = [...sortConfigCampaignByActivity,...groupByConfigCampaignByActivity[key]])
        setData(mergeConfigCampaignsWithProcess)
        setLoading(false)
  
      }
      fetch()
    }, [ranking])
    return {
      data,
      isLoading: loading,
    };
  }

  export const getNameReward = (item) => {
  if(get(item, 'types', '')=== TYPE_REWARD_ITEM_REFERRAL.point){
    return TYPE_OF_POINT_REWARD_ITEM_VI[get(item, 'typesOfPoint', '')] 
  }
  if(get(item, 'types', '')=== TYPE_REWARD_ITEM_REFERRAL.money){
    return TYPE_OF_MONEY_REWARD_ITEM_VI[get(item, 'typesOfMoney', '')] 
  }
    return  TYPE_REWARD_ITEM_REFERRAL_VI[get(item,'types','')]
  }
