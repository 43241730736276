import * as Types from '../../constants/actionTypes';

export const getListStatusConfig = (query) => ({
    type: Types.GET_STATUS_CONFIG_REQUEST,
    payload: query
})
export const getStatusConfigById = (id) => ({
    type: Types.GET_STATUS_CONFIG_BY_ID_REQUEST,
    payload: id
})
export const deleteStatusConfig = (id) => ({
    type: Types.DELETE_STATUS_CONFIG_REQUEST,
    payload: id
})
export const updateStatusConfig = (data) => ({
    type: Types.UPDATE_STATUS_CONFIG_REQUEST,
    payload: data
})
export const createStatusConfig = (data) => ({
    type: Types.CREATE_STATUS_CONFIG_REQUEST,
    payload: data
})