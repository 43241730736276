import { Badge, Button, Col, Form, Modal, Row, Select, Skeleton } from 'antd';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  useCities,
  useInitServiceByLocation,
  useResetServiceByLocation,
  useServiceByLocation
} from '~/hooks';
import { filterAcrossAccents } from '~/hooks/utils';
import AssignService from './AssignService';

const { Option } = Select;
const FormItem = Form.Item;
const ServiceByLocationForm = ({
  onClose,
  id,
  query,
  handleCreate,
  isSubmitLoading
}) => {
  const [serviceByLocation, isLoading] = useServiceByLocation(query);
  const [form] = Form.useForm();

  const initServiceByLocation = useInitServiceByLocation(serviceByLocation);
  const [openSelectService, setOpenSelectService] = useState(false);

  const onOpenService = useCallback(() => setOpenSelectService(true), []);
  const onCloseService = useCallback(() => setOpenSelectService(false), []);
  const [cities, isCitiesLoading] = useCities();
  useResetServiceByLocation();
  useEffect(() => {
    if (!id) {
      form.resetFields();
    } else {
      form.setFieldsValue({initServiceByLocation, locationIndex: id});
    }
  }, [initServiceByLocation, form, id]);
  const onFinish = useCallback(
    (values) => {
      const serviceByLocation = {
        ...values
      };

      if (id) {
        handleCreate({ ...serviceByLocation, locationIndex: id });
      } else {
        handleCreate(serviceByLocation);
      }
      onClose();
      form.resetFields();
    },
    [handleCreate, id, form]
  );
  const renderInput = (InputComponent) =>
    isLoading ? <Skeleton.Input active /> : InputComponent;
  

  return (
    <div className="position page-wraper hospital-form-page-content">
      <h4 style={{ margin: '20px 0 40px 20px' }}>
        {id ? ' Cập nhật' : 'Thêm mới'} dịch vụ theo tỉnh/ thành phố
      </h4>
      <div className="container-fluid"></div>

      <Form
        form={form}
        scrollToFirstError
        initialValues={{ status: 'ACTIVE' }}
        requiredMark={false}
        labelCol={{ sm: 24, md: 24, lg: 4 }}
        wrapperCol={{ sm: 24, md: 24, lg: 20 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <FormItem
          label="Thành Phố/Tỉnh"
          name={'locationIndex'}
          rules={[
            {
              required: true,
              message: 'Xin vui lòng chọn Thành Phố/Tỉnh!'
            }
          ]}
          hidden={id ? true : false}
        >
          {isLoading ? (
            <Skeleton.Input active />
          ) : (
            <Select
              allowClear
              // onChange={(value) => setSelectedCityCode(value)}
              loading={isCitiesLoading}
              showSearch
              autoComplete="off"
              filterOption={filterAcrossAccents}
            >
              {cities.map(({ code, name }) => (
                <Option key={code} value={code}>
                  {name}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>
        <h3 className="wh-service-form__section-title">Chỉ định dịch vụ</h3>
        <Row gutter={24} align="middle" justify="space-between">
          <Col span={24}>
            <FormItem
              shouldUpdate
              noStyle
              label="Dịch vụ sử dụng"
              name="serviceIds"
            >
              <Badge
                showZero
                overflowCount={9999}
                count={form.getFieldValue('serviceIds')?.length || 0}
              >
                <Button type="primary" onClick={onOpenService} block>
                  Xem chi tiết
                </Button>
              </Badge>
              <Modal
                style={{ top: 10 }}
                visible={openSelectService}
                onCancel={onCloseService}
                footer={null}
                width={1200}
              >
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue, setFieldsValue }) => (
                    <AssignService
                      data={getFieldValue('serviceIds')}
                      setData={(ids) => setFieldsValue({ serviceIds: ids })}
                      defaultData={serviceByLocation}
                      onCancel={onCloseService}
                      titleModal="Chọn dịch vụ cho tỉnh/ thành phố"
                    />
                  )}
                </Form.Item>
              </Modal>
            </FormItem>
          </Col>
        </Row>
        <Row className="coupon-form__submit-box">
          {isSubmitLoading ? (
            <Button disabled>Huỷ</Button>
          ) : (
            <Button onClick={onClose}>Huỷ</Button>
          )}

          <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
            {id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default ServiceByLocationForm;
