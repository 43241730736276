import { Empty, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import api from '~/api';
import { filterAcrossAccents, filterAcrossAccentsByLabel } from '~/hooks/utils';

function DebounceSelectByLocation({
  // fetchOptions,
  debounceTimeout = 500,
  initOptions,
  value,
  isFirstFetch,
  style = { minWidth: '200px' },
  defaultValue = [],
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const fetchOptions = async (value) => {
    const res = await api.geo.getCities({ keyword: value });
    return res?.map((item) => ({ label: item?.name, value: item?.code }));
  };
 
  useEffect(() => {
    if (isFirstFetch) {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
      });
    }
  }, [isFirstFetch]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        // For fetch Have Options
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      mode="multiple"
      allowClear
      // filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
      {...props}
      options={options}
      {...(value && { value })}
      showSearch
      style={{ ...style }}
      onFocus={() => {
        if (!initOptions) {
          debounceFetcher();
        }
      }}
      defaultValue={defaultValue}
      autoComplete="off"
      filterOption={filterAcrossAccentsByLabel}
    />
  );
}
export default DebounceSelectByLocation;
