import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getJobsFailed: null,
  getJobFailed: null,
  Jobs: [],
  Job: null,
  isSubmitLoading: false,
  isGetJobLoading: false,

  loadingUpdateCourse: false,
  updateFailedCourse: null,
  updateSuccessCourse: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_JOBS_REQUEST:
      state.isLoading = true;
      state.getJobsFailed = null;
      return;

    case Types.GET_JOBS_SUCCESS:
      state.isLoading = false;
      state.Jobs = get(payload, 'docs', []);
      state.getJobsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_JOBS_FAILED:
      state.isLoading = false;
      state.Jobs = [];
      state.getJobsFailed = payload;
      return;
    case Types.GET_JOB_REQUEST:
      state.isGetJobLoading = true;
      state.Job = null
      state.getJobFailed = null;
      return;

    case Types.GET_JOB_SUCCESS:
      state.isGetJobLoading = false;
      state.Job = payload;
      state.getJobFailed = null;
      return;

    case Types.GET_JOB_FAILED:
      state.isGetJobLoading = false;
      state.Job = null;
      state.getJobFailed = payload;
      return;
    case Types.CREATE_JOB_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_JOB_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_JOB_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_JOB_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_JOB_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.Jobs = state.Jobs.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.Job = payload;
      return;

    case Types.UPDATE_JOB_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_JOB_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_JOB_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_STORE:
    case Types.RESET_JOB_STATE:
      return initState;

    default:
      return;
  }
}, initState);
