import produce from 'immer';
import { get, omit } from 'lodash';
import { STATUS_READ } from '~/constants/myNotification';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getMyNotificationsFailed: null,
  myNotifications: [],

  paging: null,

  countUnread: 0,

  isSubmitLoading: false,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_MY_NOTIFICATIONS_REQUEST:
      state.isLoading = true;
      state.getMyNotificationsFailed = null;
      return;

    case Types.GET_MY_NOTIFICATIONS_SUCCESS:
      state.isLoading = false;
      state.myNotifications = get(payload, 'docs', []);
      state.getMyNotificationsFailed = null;
      state.paging = getPaging(payload);
      state.countUnread = get(payload, 'countUnread', 0);
      return;

    case Types.GET_MY_NOTIFICATIONS_FAILED:
      state.isLoading = false;
      state.myNotifications = [];
      state.getMyNotificationsFailed = payload;
      return;

    case Types.CHANGE_STATUS_NOTIFICATION_REQUEST:
      state.isSubmitLoading = true;
      return;

    case Types.CHANGE_STATUS_NOTIFICATION_SUCCESS:
      state.isSubmitLoading = false;
      state.myNotifications = state.myNotifications?.map(notification =>
        get(notification, 'notifyId') === get(payload, 'notifyId')
          ? { ...notification, status: get(payload, 'status') }
          : notification)

      return;

    case Types.CHANGE_STATUS_NOTIFICATION_FAILED:
      state.isSubmitLoading = false;
      return;

    case Types.CHANGE_MANY_STATUS_NOTIFICATION_SUCCESS:
      console.log(payload,'payload');
      state.myNotifications = state.myNotifications?.map(notification => {
        const isExist = payload?.includes(get(notification, '_id'))
        if (isExist) {
          return { ...notification, status: STATUS_READ.read }
        } else {
          return notification
        }
      }
      )

      return;

    case Types.RESET_STORE:
    case Types.RESET_MY_NOTIFICATION_STATE:
      return initState;

    default:
      return;
  }
}, initState);
