import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getWarehouseVouchers({ payload: query }) {
    try {
      const response = yield call(Api.wareHouseVoucher.getAll, query);
      yield put({ type: Types.GET_WAREHOUSE_VOUCHERS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WAREHOUSE_VOUCHERS_FAILED, payload: error });
    }
  }
function* getWarehouseVouchersOfPartner({ payload: query }) {
    try {
      const response = yield call(Api.wareHouseVoucher.getAllByPartner, query);
      yield put({ type: Types.GET_WAREHOUSE_VOUCHERS_PARTNER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WAREHOUSE_VOUCHERS_PARTNER_FAILED, payload: error });
    }
  }
function* getWarehouseVoucher({ payload: id }) {
    try {
      const response = yield call(Api.wareHouseVoucher.getOne, id);
      yield put({ type: Types.GET_WAREHOUSE_VOUCHER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WAREHOUSE_VOUCHER_FAILED, payload: error });
    }
  }

function* getWarehouseVoucherOfPartner({ payload: id }) {
    try {
      const response = yield call(Api.wareHouseVoucher.getOneByPartner, id);
      yield put({ type: Types.GET_WAREHOUSE_VOUCHER_PARTNER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_WAREHOUSE_VOUCHER_PARTNER_FAILED, payload: error });
    }
  }

  function* createWarehouseVoucher(action) {
    try {
      const data = yield call(Api.wareHouseVoucher.create, action.payload);
      yield put({ type: Types.CREATE_WAREHOUSE_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_WAREHOUSE_VOUCHER_FAILED, payload: error });
    }
  }
  function* createWarehouseVoucherOfPartner(action) {
    try {
      const data = yield call(Api.wareHouseVoucher.createByPartner, action.payload);
      yield put({ type: Types.CREATE_WAREHOUSE_VOUCHER_PARTNER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_WAREHOUSE_VOUCHER_PARTNER_FAILED, payload: error });
    }
  }
  function* updateWarehouseVoucher(action) {
    try {
      const data = yield call(Api.wareHouseVoucher.update, action.payload);
      yield put({ type: Types.UPDATE_WAREHOUSE_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_WAREHOUSE_VOUCHER_FAILED, payload: error });
    }
  }
  function* deleteWarehouseVoucher(action) {
    try {
      const data = yield call(Api.wareHouseVoucher.delete, action.payload);
      yield put({ type: Types.DELETE_WAREHOUSE_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_WAREHOUSE_VOUCHER_FAILED, payload: error });
    }
  }

  function* partnerConfirmWarehouseVoucher({payload : id}) {
    try {
      const data = yield call(Api.wareHouseVoucher.partnerConfirm,id);
      yield put({ type: Types.PARTNER_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.PARTNER_CONFIRM_WAREHOUSE_VOUCHER_FAILED, payload: error });
    }
  }
  function* partnerRejectWarehouseVoucher({payload : id}) {
    try {
      const data = yield call(Api.wareHouseVoucher.partnerReject,id);
      yield put({ type: Types.PARTNER_REJECT_WAREHOUSE_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.PARTNER_REJECT_WAREHOUSE_VOUCHER_FAILED, payload: error });
    }
  }

  function* employeeConfirmWarehouseVoucher({payload : id}) {
    try {
      const data = yield call(Api.wareHouseVoucher.employeeConfirm,id);
      yield put({ type: Types.EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_FAILED, payload: error });
    }
  }
  function* employeeRejectWarehouseVoucher({payload : id}) {
    try {
      const data = yield call(Api.wareHouseVoucher.employeeReject,id);
      yield put({ type: Types.EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_FAILED, payload: error });
    }
  }


  function* supplierConfirmWarehouseVoucher({payload : id}) {
    try {
      const data = yield call(Api.wareHouseVoucher.supplierConfirm,id);
      yield put({ type: Types.SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_FAILED, payload: error });
    }
  }
  function* supplierRejectWarehouseVoucher({payload : id}) {
    try {
      const data = yield call(Api.wareHouseVoucher.supplierReject,id);
      yield put({ type: Types.SUPPLIER_REJECT_WAREHOUSE_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.SUPPLIER_REJECT_WAREHOUSE_VOUCHER_FAILED, payload: error });
    }
  }
export default function* WarehouseVouchers() {
    yield takeLatest(Types.GET_WAREHOUSE_VOUCHERS_REQUEST, getWarehouseVouchers);
    yield takeLatest(Types.GET_WAREHOUSE_VOUCHERS_PARTNER_REQUEST, getWarehouseVouchersOfPartner);
    yield takeLatest(Types.GET_WAREHOUSE_VOUCHER_REQUEST, getWarehouseVoucher);
    yield takeLatest(Types.GET_WAREHOUSE_VOUCHER_PARTNER_REQUEST, getWarehouseVoucherOfPartner);
    yield takeLatest(Types.CREATE_WAREHOUSE_VOUCHER_REQUEST, createWarehouseVoucher);
    yield takeLatest(Types.CREATE_WAREHOUSE_VOUCHER_PARTNER_REQUEST, createWarehouseVoucherOfPartner);
    yield takeLatest(Types.UPDATE_WAREHOUSE_VOUCHER_REQUEST, updateWarehouseVoucher);
    yield takeLatest(Types.DELETE_WAREHOUSE_VOUCHER_REQUEST, deleteWarehouseVoucher);
    yield takeLatest(Types.PARTNER_CONFIRM_WAREHOUSE_VOUCHER_REQUEST, partnerConfirmWarehouseVoucher);
    yield takeLatest(Types.PARTNER_REJECT_WAREHOUSE_VOUCHER_REQUEST, partnerRejectWarehouseVoucher);
    yield takeLatest(Types.EMPLOYEE_CONFIRM_WAREHOUSE_VOUCHER_REQUEST, employeeConfirmWarehouseVoucher);
    yield takeLatest(Types.EMPLOYEE_REJECT_WAREHOUSE_VOUCHER_REQUEST, employeeRejectWarehouseVoucher);
    yield takeLatest(Types.SUPPLIER_CONFIRM_WAREHOUSE_VOUCHER_REQUEST, supplierConfirmWarehouseVoucher);
    yield takeLatest(Types.SUPPLIER_REJECT_WAREHOUSE_VOUCHER_REQUEST, supplierRejectWarehouseVoucher);
  }
