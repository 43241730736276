import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getPolicies,
  createPolicy,
  deletePolicy,
  getPolicy,
  resetPolicyState,
  updatePolicy
} from '~/redux/policy/actions';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';
import moment from 'moment';

const POLICY_MODULE = 'policy';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(POLICY_MODULE);

export const usePolicies = (query) => {
  return useFetchByParam({
    action: getPolicies,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreatePolicy = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới chính sách thành công', callback);
  useFailed(createFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createPolicy
  });
};

export const useUpdatePolicy = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật chính sách thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updatePolicy
  });
};

export const useDeletePolicy = (onDeleteSuccess) => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá chính sách thành công',
    onDeleteSuccess
  );
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector,
    action: deletePolicy
  });
};

export const usePolicy = (params) => {
  return useFetchByParam({
    action: getPolicy,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitPolicy = (policy, id) => {
  return useMemo(() => {
    if (!policy || !id) {
      return {
        discountType: 'value'
      };
    }

    const initPolicy = {
      ...policy,
      startTime: moment(policy.startTime),
      endTime: moment(policy.endTime),
      discountType:
        policy.discountPercent === 0 || policy.discountPercent
          ? 'percent'
          : 'value'
    };

    return initPolicy;
  }, [policy, id]);
};

export const useUpdatePolicyParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    history.push({
      pathname: '/policy',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const usePolicyQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  // const page = query.get('page');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      cityId
    });

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    cityId,
    createSuccess,
    updateSuccess,
    deleteSuccess
  ]);
};

export const usePolicyPaging = () => useSelector(pagingSelector);

export const useResetPolicy = () => {
  useResetState(resetPolicyState);
};
