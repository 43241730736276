import produce from 'immer';
import {
  GET_WH_SERVICES_REQUEST,
  GET_WH_SERVICES_SUCCESS,
  GET_WH_SERVICES_FAILED,
  GET_WH_SERVICES_AVAILABLE_REQUEST,
  GET_WH_SERVICES_AVAILABLE_SUCCESS,
  GET_WH_SERVICES_AVAILABLE_FAILED,
  DELETE_WH_SERVICE_REQUEST,
  DELETE_WH_SERVICE_SUCCESS,
  DELETE_WH_SERVICE_FAILED,
  CREATE_WH_SERVICE_REQUEST,
  CREATE_WH_SERVICE_SUCCESS,
  CREATE_WH_SERVICE_FAILED,
  GET_WH_SERVICE_REQUEST,
  GET_WH_SERVICE_SUCCESS,
  GET_WH_SERVICE_FAILED,
  UPDATE_WH_SERVICE_REQUEST,
  UPDATE_WH_SERVICE_SUCCESS,
  UPDATE_WH_SERVICE_FAILED,
  RESET_WH_SERVICE_STATE,
  RESET_STORE,
  COPY_WH_SERVICE_REQUEST,
  COPY_WH_SERVICE_SUCCESS,
  COPY_WH_SERVICE_FAILED,
  GET_WH_SERVICES_BRANCH_REQUEST,
  GET_WH_SERVICES_BRANCH_SUCCESS,
  GET_WH_SERVICES_BRANCH_FAILED,
  UPDATE_WH_SERVICE_BRANCH_REQUEST,
  UPDATE_WH_SERVICE_BRANCH_SUCCESS,
  UPDATE_WH_SERVICE_BRANCH_FAILED,
  CREATE_WH_SERVICE_BRANCH_REQUEST,
  CREATE_WH_SERVICE_BRANCH_SUCCESS,
  CREATE_WH_SERVICE_BRANCH_FAILED,
  DELETE_WH_SERVICE_BRANCH_REQUEST,
  DELETE_WH_SERVICE_BRANCH_SUCCESS,
  DELETE_WH_SERVICE_BRANCH_FAILED,
  RESET_WH_SERVICE_BRANCH_STATE,
  ACCEPT_WH_SERVICE_BRANCH_REQUEST,
  ACCEPT_WH_SERVICE_BRANCH_SUCCESS,
  ACCEPT_WH_SERVICE_BRANCH_FAILED,
  GET_WH_SERVICES_DASHBOARD_REQUEST,
  GET_WH_SERVICES_DASHBOARD_SUCCESS,
  GET_WH_SERVICES_DASHBOARD_FAILED,
} from '../../constants/actionTypes';
import getPaging from '../../utils/getPaging';
import { omit } from 'lodash';

const initState = {
  isLoading: false,
  getWhServicesFailed: undefined,
  whServices: [],

  isLoadingServiceBranch: false,
  getWhServicesBranchFailed: undefined,
  whServicesBranch: [],

  isLoadingServiceDashboard: false,
  getWhServicesDashboardFailed: undefined,
  whServicesDashboard: [],
  isGetWhServiceLoading: false,
  whService: null,
  getWhServiceFailed: null,

  deleteSuccess: null,
  deleteFailed: null,
  
  deleteServiceBranchSuccess: null,
  deleteServiceBranchFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  isSubmitServiceBranchLoading: false,
  createServiceBranchSuccess: null,
  createServiceBranchFailed: null,

  updateSuccess: null,
  updateFailed: null,
  
  copySuccess: null,
  copyFailed: null,

  updateServiceBranchSuccess: null,
  updateServiceBranchFailed: null,

  acceptServiceBranchSuccess: null,
  acceptServiceBranchFailed: null,

  paging: null,
  pagingBranch: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_WH_SERVICES_REQUEST:
      state.isLoading = true;
      state.getWhServicesFailed = null;
      return;

    case GET_WH_SERVICES_SUCCESS:
      state.isLoading = false;
      state.whServices = payload.docs;
      state.getWhServicesFailed = null;
      state.paging = getPaging(payload);
      return;

    case GET_WH_SERVICES_FAILED:
      state.isLoading = false;
      state.whServices = [];
      state.getWhServicesFailed = null;
      return;
    case GET_WH_SERVICES_DASHBOARD_REQUEST:
      state.isLoading = true;
      state.getWhServicesDashboardFailed = null;
      return;
    case GET_WH_SERVICES_DASHBOARD_SUCCESS:
      state.isLoading = false;
      state.whServices = payload.docs;
      state.getwhServicesFailed = null;
      state.paging = getPaging(payload);
      return;
    case GET_WH_SERVICES_DASHBOARD_FAILED:
      state.isLoading = false;
      state.whServices = [];
      state.getwhServicesFailed = null;
      return;
    case GET_WH_SERVICES_AVAILABLE_REQUEST:
      state.isLoading = true;
      state.getWhServicesFailed = null;
      return;

    case GET_WH_SERVICES_AVAILABLE_SUCCESS:
      state.isLoading = false;
      state.whServices = payload;
      state.getWhServicesFailed = null;
      return;

    case GET_WH_SERVICES_AVAILABLE_FAILED:
      state.isLoading = false;
      state.whServices = [];
      state.getWhServicesFailed = null;
      return;
    
    // Service by branch

    case GET_WH_SERVICES_BRANCH_REQUEST:
      state.isLoadingServiceBranch = true;
      state.getWhServicesBranchFailed = null;
      return;

    case GET_WH_SERVICES_BRANCH_SUCCESS:
      state.isLoadingServiceBranch = false;
      state.whServicesBranch = payload.docs;
      state.getWhServicesBranchFailed = null;
      state.pagingBranch = getPaging(payload);
      return;

    case GET_WH_SERVICES_BRANCH_FAILED:
      state.isLoadingServiceBranch = false;
      state.whServicesBranch = [];
      state.getWhServicesBranchFailed = null;
      return;

    case GET_WH_SERVICE_REQUEST:
      state.isGetWhServiceLoading = true;
      state.whService = null;
      state.getWhServiceFailed = null;
      return;

    case GET_WH_SERVICE_SUCCESS:
      state.isGetWhServiceLoading = false;
      state.whService = payload;
      return;

    case GET_WH_SERVICE_FAILED:
      state.isGetWhServiceLoading = false;
      state.getWhServiceFailed = payload;
      return;

    case DELETE_WH_SERVICE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_WH_SERVICE_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_WH_SERVICE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_WH_SERVICE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    
    case DELETE_WH_SERVICE_BRANCH_REQUEST:
      state.isSubmitServiceBranchLoading = true;
      state.deleteServiceBranchSuccess = null;
      state.deleteServiceBranchFailed = null;
      return;

    case DELETE_WH_SERVICE_BRANCH_SUCCESS:
      state.deleteServiceBranchSuccess = payload;
      return;

    case DELETE_WH_SERVICE_BRANCH_FAILED:
      state.isSubmitServiceBranchLoading = false;
      state.deleteServiceBranchFailed = payload;
      return;

    case CREATE_WH_SERVICE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_WH_SERVICE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_WH_SERVICE_FAILED:
      state.isSubmitLoading = false;
      state.copyFailed = payload;
      return;
    
    case COPY_WH_SERVICE_REQUEST:
      state.isSubmitLoading = true;
      state.copySuccess = null;
      state.copyFailed = null;
      return;

    case COPY_WH_SERVICE_SUCCESS:
      state.isSubmitLoading = false;
      state.copySuccess = payload;
      return;

    case COPY_WH_SERVICE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    //CREATE SERVICE FOR BRANCH
    case CREATE_WH_SERVICE_BRANCH_REQUEST:
      state.isSubmitServiceBranchLoading = true;
      state.createServiceBranchSuccess = null;
      state.createServiceBranchFailed = null;
      return;

    case CREATE_WH_SERVICE_BRANCH_SUCCESS:
      state.isSubmitServiceBranchLoading = false;
      state.createServiceBranchSuccess = payload;
      return;

    case CREATE_WH_SERVICE_BRANCH_FAILED:
      state.isSubmitServiceBranchLoading = false;
      state.createServiceBranchFailed = payload;
      return;
    //

    case UPDATE_WH_SERVICE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_WH_SERVICE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.whService = payload;
      state.whServices = state.whServices.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case UPDATE_WH_SERVICE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;
    
    //Update WhService for branch
    case UPDATE_WH_SERVICE_BRANCH_REQUEST:
      state.isSubmitLoading = true;
      state.updateServiceBranchSuccess = null;
      state.updateServiceBranchFailed = null;
      return;

    case UPDATE_WH_SERVICE_BRANCH_SUCCESS:
      state.isSubmitLoading = false;
      state.updateServiceBranchSuccess = payload;
      state.whServicesBranch = state.whServicesBranch.map(item => {
        if (item._id === payload?.data._id) {
          item.status = payload?.data?.status
          const {_id,id, ...rest} = payload.data
          return {...item, ...rest};
        }
        return item
      })
      return;

    case UPDATE_WH_SERVICE_BRANCH_FAILED:
      state.isSubmitLoading = false;
      state.updateServiceBranchFailed = payload;
      return;

    //Accept WhService for branch
    case ACCEPT_WH_SERVICE_BRANCH_REQUEST:
      state.isSubmitLoading = true;
      state.acceptServiceBranchSuccess = null;
      state.acceptServiceBranchFailed = null;
      return;

    case ACCEPT_WH_SERVICE_BRANCH_SUCCESS:
      state.isSubmitLoading = false;
      state.acceptServiceBranchSuccess = payload;
      state.whServicesBranch = state.whServicesBranch.map(item => {
        if (item._id === payload?.data._id) {
          item.status = payload?.data?.status
          const {_id,id, ...rest} = payload.data
          return {...item, ...rest};
        }
        return item
      })
      return;

    case ACCEPT_WH_SERVICE_BRANCH_FAILED:
      state.isSubmitLoading = false;
      state.acceptServiceBranchFailed = payload;
      return;

    case RESET_WH_SERVICE_STATE:
    case RESET_STORE:
      return initState;
    
    case RESET_WH_SERVICE_BRANCH_STATE:
        state.isLoadingServiceBranch= false;
        state.getWhServicesBranchFailed= undefined;
        state.deleteServiceBranchSuccess= null;
        state.deleteServiceBranchFailed= null;
        state.isSubmitServiceBranchLoading= false;
        state.createServiceBranchSuccess= null;
        state.createServiceBranchFailed= null;
        state.updateServiceBranchSuccess= null;
        state.updateServiceBranchFailed= null;
      return
      
    default:
      return;
  }
}, initState);
