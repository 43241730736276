import { Button, Col, Form, Input, Row, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Text from 'antd/lib/typography/Text'
import { get, head } from 'lodash'
import moment from 'moment'
import React from 'react'
import { STATUS_PARTNER_CANCEL_BILL, TOTAL_HOURS_TO_LATE, TYPE_CANCEL_PARTNER } from '~/constants/defaultValue'
import { useCreateCancelAppointment, useResetCreateCancel, useResetWhBillItem } from '~/hooks'
// import { useCreatePartnerCancelAppointment, useResetPartnerCancelAppointment } from '~/hooks/partnerCancelAppointment'
const codeCancel = "HUY"
export default function FormReasonCancel({data,typeCancel,whBillItem,whPartnerId,handleClose}) {
    const [form] = Form.useForm()
    const [isSubmitLoading,createRequestCancel] = useCreateCancelAppointment(handleClose)   
    useResetCreateCancel() 
    const mergeTimeAndDate = (date,time) => {
        const dateStart = moment(date)
        const hour = moment(time,'HH:mm').hours()
        const minute = moment(time,'HH:mm').minutes()
        dateStart.set({ hour: hour, minute: minute, second: 0, millisecond: 0 });
        return dateStart
      }
      const checkAppointmentIsLate = (appointment) => {
        const {time} = appointment
        const now = moment()
        const dateStart= mergeTimeAndDate(get(appointment,'date'),time)
        const diffHours = dateStart.diff(now, 'hours');
        return diffHours <= TOTAL_HOURS_TO_LATE
      }
      const getAppointmentNearestDateInArray = (arrAppointment) => {
        let dateNearest = head(arrAppointment)
        arrAppointment?.forEach(e => {
          if(get(e,'checkIn')) return // is processing
          const date = mergeTimeAndDate(get(e,'date'),get(e,'time'))
          if(date.diff(mergeTimeAndDate(get(dateNearest,'date'),get(dateNearest,'time')),'minutes') < 0){
            dateNearest = {...e}
          }
        })
        return dateNearest
      }
    const onFinish = (values) => {
        const {reason} = values
           let isLate = false
           switch (typeCancel) {
            case TYPE_CANCEL_PARTNER.appointment.value:
              isLate =  checkAppointmentIsLate(data)
              break;
            case TYPE_CANCEL_PARTNER.block.value:
              const {blocks , selectedBlock} = data
              const date =  getAppointmentNearestDateInArray(blocks[selectedBlock]) // get date nearest to block
              isLate =  checkAppointmentIsLate(date) // check date is late
              break;
            case TYPE_CANCEL_PARTNER.bill.value:
              const dateBill =  getAppointmentNearestDateInArray(get(data,'whAppointments',[]))
              isLate =  checkAppointmentIsLate(dateBill)
              break;
           
            default:
              break;
           }
          
            const submitData = {
              partnerId : whPartnerId,
              whBillItemId : get(whBillItem,'_id'),
              whBillId : get(whBillItem,'whBillId'),
              ...typeCancel === TYPE_CANCEL_PARTNER.block.value && {blockNumber : get(data,'selectedBlock')}, 
              ...typeCancel === TYPE_CANCEL_PARTNER.appointment.value && {whAppointmentId : get(data,'_id')}, 
              reason,
              status : STATUS_PARTNER_CANCEL_BILL.CREATE.value,
              isLate,
              type : typeCancel
            }
            createRequestCancel(submitData)
    }
  return (
    <>
    <Typography.Title level={5}>Mã xác nhận là <Text code>{codeCancel}</Text></Typography.Title>
        <Form 
        labelCol={{span : 8}}
        form={form} onFinish={onFinish}>
            <Form.Item  rules={[
              {
                required: true,
                message: 'Xin mời nhập lý do huỷ!'
              },]}  name='reason' label='Lý do huỷ'>
                <TextArea autoSize/>
            </Form.Item>
            <Form.Item rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(
                          new Error('Xin vui lòng nhập Mã xác nhận!'),
                        );
                      }
                      if (value !== codeCancel) {
                        return Promise.reject(
                          new Error('Xin vui lòng nhập đúng Mã xác nhận!'),
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]} name='codeCheck' label='Nhập mã xác nhận'>
               <Input placeholder={`Nhập ${codeCancel} để huỷ`}/>
            </Form.Item>
        <Row justify='center' gutter={16}>
           <Col span={11}>
           <Button onClick={handleClose} block>Huỷ thao tác</Button>
           </Col>
           <Col span={11}>
           <Button htmlType='submit' danger block loading={isSubmitLoading}>Xác nhận huỷ</Button>
           </Col>
        </Row>
        </Form>
       
    </>
  )
}
