import { put, call, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import Api from '../../api';
import {
  DELETE_WH_BILL_ITEM_REQUEST,
  DELETE_WH_BILL_ITEM_SUCCESS,
  DELETE_WH_BILL_ITEM_FAILED,
  GET_WH_BILL_ITEMS_REQUEST,
  GET_WH_BILL_ITEMS_SUCCESS,
  GET_WH_BILL_ITEMS_FAILED,
  CREATE_WH_BILL_ITEM_REQUEST,
  CREATE_WH_BILL_ITEM_SUCCESS,
  CREATE_WH_BILL_ITEM_FAILED,
  GET_WH_BILL_ITEM_REQUEST,
  GET_WH_BILL_ITEM_SUCCESS,
  GET_WH_BILL_ITEM_FAILED,
  UPDATE_WH_BILL_ITEM_REQUEST,
  UPDATE_WH_BILL_ITEM_SUCCESS,
  UPDATE_WH_BILL_ITEM_FAILED,
  CUSTOMER_CANCEL_WH_BILL_ITEM_REQUEST,
  CUSTOMER_CANCEL_WH_BILL_ITEM_SUCCESS,
  CUSTOMER_CANCEL_WH_BILL_ITEM_FAILED,
  CUSTOMER_COMPLETED_WH_BILL_ITEM_REQUEST,
  CUSTOMER_COMPLETED_WH_BILL_ITEM_SUCCESS,
  CUSTOMER_COMPLETED_WH_BILL_ITEM_FAILED,
  UPDATE_WH_APPOINTMENTS_REQUEST,
  UPDATE_WH_APPOINTMENTS_SUCCESS,
  UPDATE_WH_APPOINTMENTS_FAILED,
  CREATE_CANCEL_APPOINTMENT_REQUEST,
  CREATE_CANCEL_APPOINTMENT_SUCCESS,
  CREATE_CANCEL_APPOINTMENT_FAILED,
} from '../../constants/actionTypes';

function* getWhBillItems({ payload: query }) {
  try {
    const forCurrentWhPartnerOnly = get(query, "forCurrentWhPartnerOnly");
    const response = yield call(
      forCurrentWhPartnerOnly
        ? Api.whBillItem.getWhBillItemsForCurrentWhPartnerOnly
        : Api.whBillItem.getWhBillItems,
      query
    );
    yield put({ type: GET_WH_BILL_ITEMS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_BILL_ITEMS_FAILED, payload: error });
  }
}

function* getWhBillItem({ payload: id }) {
  try {
    const whBillItem = yield call(Api.whBillItem.getById, id);
    yield put({ type: GET_WH_BILL_ITEM_SUCCESS, payload: whBillItem });
  } catch (error) {
    yield put({ type: GET_WH_BILL_ITEM_FAILED, payload: error.message });
  }
}

function* createWhBillItem(action) {
  try {
    const data = yield call(Api.whBillItem.create, action.payload);
    yield put({ type: CREATE_WH_BILL_ITEM_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CREATE_WH_BILL_ITEM_FAILED, payload: error.message });
  }
}

function* updateWhBillItem(action) {
  try {
    const data = yield call(Api.whBillItem.update, action.payload);
    yield put({ type: UPDATE_WH_BILL_ITEM_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_BILL_ITEM_FAILED, payload: error.message });
  }
}

function* customerCancelWhBillItem(action) {
  try {
    const data = yield call(Api.whBillItem.customerCancel, action.payload);
    yield put({ type: CUSTOMER_CANCEL_WH_BILL_ITEM_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CUSTOMER_CANCEL_WH_BILL_ITEM_FAILED, payload: error.message });
  }
}

function* customerCompletedWhBillItem(action) {
  // console.log('action.payload', action.payload);
  try {
    const dataCompleted = yield call(Api.whBillItem.updateCompletedWhBillItem, action.payload);
    console.log('data', data);
    yield put({ type: CUSTOMER_COMPLETED_WH_BILL_ITEM_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: CUSTOMER_COMPLETED_WH_BILL_ITEM_FAILED, payload: error.message });
  }
}
function* updateWhAppointments(action) {
  try {
    const data = yield call(Api.whBillItem.updateWhAppointments, action.payload);
    yield put({ type: UPDATE_WH_APPOINTMENTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: UPDATE_WH_APPOINTMENTS_FAILED, payload: error });
  }
}

function* deleteHopital({ payload }) {
  try {
    yield call(Api.whBillItem.delete, payload);
    yield put({ type: DELETE_WH_BILL_ITEM_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_WH_BILL_ITEM_FAILED, payload: error.message });
  }
}

function* createCancelAppointment({ payload }) {
  try {
    yield call(Api.partnerCancelAppointment.create, payload);
    yield put({ type: CREATE_CANCEL_APPOINTMENT_SUCCESS, payload });
  } catch (error) {
    yield put({ type: CREATE_CANCEL_APPOINTMENT_FAILED, payload: error });
  }
}

export default function* whBillItem() {
  yield takeLatest(GET_WH_BILL_ITEMS_REQUEST, getWhBillItems);
  yield takeLatest(GET_WH_BILL_ITEM_REQUEST, getWhBillItem);
  yield takeLatest(DELETE_WH_BILL_ITEM_REQUEST, deleteHopital);
  yield takeLatest(CREATE_WH_BILL_ITEM_REQUEST, createWhBillItem);
  yield takeLatest(UPDATE_WH_BILL_ITEM_REQUEST, updateWhBillItem);
  yield takeLatest(CUSTOMER_CANCEL_WH_BILL_ITEM_REQUEST, customerCancelWhBillItem);
  yield takeLatest(CUSTOMER_COMPLETED_WH_BILL_ITEM_REQUEST, customerCompletedWhBillItem);
  yield takeLatest(UPDATE_WH_APPOINTMENTS_REQUEST, updateWhAppointments);
  yield takeLatest(CREATE_CANCEL_APPOINTMENT_REQUEST, createCancelAppointment);
}
