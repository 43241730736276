import React, { useEffect, useMemo, useRef, useState } from 'react';
import H from '@here/maps-api-for-javascript';
import { formatNumberThreeCommaToFixed } from '~/hooks/utils';
import { HERE_MAPS_API_KEY } from '~/config';
import card from '../../assets/images/hereMap/card.png';
import doctor from '../../assets/images/hereMap/doctor2.png';

const HereMapPosition = ({ orderPosition, partnerInfo }) => {
  const apiKey = HERE_MAPS_API_KEY;
  const partnerPosition = partnerInfo?.map(({ lat, lng }) => ({
    lat: lat,
    lng: lng
  }));
  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const [position, setPosition] = useState(orderPosition);

  const partnerIcon = new H.map.Icon(doctor, {
    size: { w: 40, h: 40 }, // Thiết lập kích thước của biểu tượng
    anchor: { x: 10, y: 10 }
  });
  const orderIcon = new H.map.Icon(card, {
    size: { w: 40, h: 40 }, // Thiết lập kích thước của biểu tượng
    anchor: { x: 10, y: 10 }
  });

  //Render map
  useEffect(
    () => {
      // Check if the map object has already been created
      if (!map.current) {
        // Create a platform object with the API key
        platform.current = new H.service.Platform({ apiKey });
        // Create a new Raster Tile service instance
        const rasterTileService = platform.current.getRasterTileService({
          queryParams: {
            style: 'explore.day',
            size: 10
          }
        });
        // Creates a new instance of the H.service.rasterTile.Provider class
        // The class provides raster tiles for a given tile layer ID and pixel format
        const rasterTileProvider = new H.service.rasterTile.Provider(
          rasterTileService
        );
        // Create a new Tile layer with the Raster Tile provider
        const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);

        const newMap = new H.Map(mapRef.current, rasterTileLayer, {
          pixelRatio: window.devicePixelRatio,
          center: orderPosition,
          zoom: 12.5
          // pixelRatio: window.devicePixelRatio || 1
        });

        // window.addEventListener('resize', () => newMap.getViewPort().resize());
        // Add panning and zooming behavior to the map
        const behavior = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(newMap)
        );

        // Set the map object to the reference
        map.current = newMap;
      };
    },
    // Dependencies array
    [orderPosition, partnerPosition, position]
  );

  // Handle in map
  useEffect(() => {
    const newMap = map?.current;
    orderPosition && newMap.addObjects([
      // Add a marker of order bill
      new H.map.Marker(orderPosition, {
        icon: orderIcon
      })
    ]);

    // Handle partners
    let activeMarker = null;
    let newArrPositionPartner = [];

    partnerInfo.length > 0 &&
      partnerInfo?.forEach((element) => {
        const position = {
          lat: element?.lat,
          lng: element?.lng
        };
        const address = element?.address;
        if (position) {
          position && newArrPositionPartner.push(position);
          const distanceKm = formatNumberThreeCommaToFixed(element?.distanceKm);
          const partnerName = element?.name;
          const marker = new H.map.Marker(position, { icon: partnerIcon });
          marker.addEventListener('pointerenter', (event) => {
            // Hiển thị thông tin thêm khi con trỏ chuột vào biểu tượng
            const popover = document.getElementById('popover');
            popover.style.display = 'block';
            popover.style.left = `${event.target.getGeometry().x}px`;
            popover.style.top = `${event.target.getGeometry().y}px`;
            popover.innerText = `Khoảng cách từ đơn hàng đến đối tác:`;

            const divName = document.createElement("div");
            divName.textContent = `${partnerName} là : ~${distanceKm}km`;
            popover.appendChild(divName);

            const div = document.createElement("div");
            div.textContent = `Địa chỉ đối tác: ${address}`;
            popover.appendChild(div);
            // Đặt kiểu con trỏ thành 'pointer' (mũi tên trỏ)
            // event.target.style.cursor = 'pointer';
            // Lưu trạng thái đối tượng Marker đang được hover
            activeMarker = marker;
          });
  
          marker.addEventListener('pointerleave', (event) => {
            // Ẩn thông tin thêm khi con trỏ chuột rời khỏi biểu tượng
            const popover = document.getElementById('popover');
            popover.style.display = 'none';
  
            // Đặt lại kiểu con trỏ mặc định
            // event.target.style.cursor = 'default';
  
            // Đặt lại trạng thái đối tượng Marker
            activeMarker = null;
          });
  
          newMap.addObject(marker, {
            icon: partnerIcon
          });
        };
      });

    // Đặt sự kiện cho thông tin thêm (Popover) để ẩn khi con trỏ chuột rời khỏi nó
    const popover = document.getElementById('popover');
    popover.addEventListener('pointerleave', () => {
      popover.style.display = 'none';

      // Đặt lại trạng thái đối tượng Marker
      activeMarker = null;
    });

    // Đảm bảo rằng thông tin thêm (Popover) được ẩn khi di chuyển khỏi bản đồ
    newMap.addEventListener('pointerleave', () => {
      popover.style.display = 'none';

      // Đặt lại trạng thái đối tượng Marker
      activeMarker = null;
    });
  }, [orderPosition, partnerInfo, partnerPosition, map]);

  return (
    <div
      style={{ width: '100%', height: '500px', position: 'relative' }}
      ref={mapRef}
    >
      <div
        id="popover"
        style={{
          display: 'none',
          position: 'absolute',
          zIndex: 999,
          width: '320px',
          // height: '50px',
          backgroundColor: '#F1EFEF',
          color: 'black',
          border: 'solid 1px green',
          borderRadius: '10px',
          padding: '5px 10px',
          margin: '5px'
        }}
      >
        <div className="popover-content"></div>
      </div>
    </div>
  );
};

export default HereMapPosition;
