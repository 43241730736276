import requester from './requester';

const coreValue = {
  getAll: (query) => requester.get(`/success-company`, query),
  getById: (id) => requester.get(`/success-company/${id}?raw=true`),
  create: (coreValue) => requester.post(`/success-company`, coreValue),
  update: (coreValue) => requester.put(`/success-company/${coreValue._id}`, coreValue),
  delete: (id) => requester.delete(`/success-company/${id}`)
};

export default coreValue;
