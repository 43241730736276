import requester from './requester';

const LogCourseTraining = {
  create: (course) => requester.post('/wh-time-sheet', course),
  getAll: (id) => requester.get(`/log-time-sheet/${id}`),
  getAllLogAdmin: (id) => requester.get(`/log-time-admin/${id}`),
  getAllLog: (query) => requester.get(`/log-time-all`,query),
};
export default LogCourseTraining;

