export const GET_MEDICAL_EQUIPMENTS_REQUEST = 'GET_MEDICAL_EQUIPMENTS_REQUEST';
export const GET_MEDICAL_EQUIPMENTS_SUCCESS = 'GET_MEDICAL_EQUIPMENTS_SUCCESS';
export const GET_MEDICAL_EQUIPMENTS_FAILED = 'GET_MEDICAL_EQUIPMENTS_FAILED';

export const GET_MEDICAL_EQUIPMENT_REQUEST = 'GET_MEDICAL_EQUIPMENT_REQUEST';
export const GET_MEDICAL_EQUIPMENT_SUCCESS = 'GET_MEDICAL_EQUIPMENT_SUCCESS';
export const GET_MEDICAL_EQUIPMENT_FAILED = 'GET_MEDICAL_EQUIPMENT_FAILED';

export const CREATE_MEDICAL_EQUIPMENT_REQUEST = 'CREATE_MEDICAL_EQUIPMENT_REQUEST';
export const CREATE_MEDICAL_EQUIPMENT_SUCCESS = 'CREATE_MEDICAL_EQUIPMENT_SUCCESS';
export const CREATE_MEDICAL_EQUIPMENT_FAILED = 'CREATE_MEDICAL_EQUIPMENT_FAILED';

export const UPDATE_MEDICAL_EQUIPMENT_REQUEST = 'UPDATE_MEDICAL_EQUIPMENT_REQUEST';
export const UPDATE_MEDICAL_EQUIPMENT_SUCCESS = 'UPDATE_MEDICAL_EQUIPMENT_SUCCESS';
export const UPDATE_MEDICAL_EQUIPMENT_FAILED = 'UPDATE_MEDICAL_EQUIPMENT_FAILED';

export const DELETE_MEDICAL_EQUIPMENT_REQUEST = 'DELETE_MEDICAL_EQUIPMENT_REQUEST';
export const DELETE_MEDICAL_EQUIPMENT_SUCCESS = 'DELETE_MEDICAL_EQUIPMENT_SUCCESS';
export const DELETE_MEDICAL_EQUIPMENT_FAILED = 'DELETE_MEDICAL_EQUIPMENT_FAILED';

export const RESET_MEDICAL_EQUIPMENT_STATE = 'RESET_MEDICAL_EQUIPMENT_STATE';
