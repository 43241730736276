import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getSystems({ payload: query }) {
  try {
    const data = yield call(Api.system.getAll, query);
    yield put({ type: Types.GET_SYSTEMS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_SYSTEMS_FAILED,
      payload: error.message
    });
  }
}

function* getSystem({ payload: id }) {
  try {
    const system = yield call(Api.system.getById, id);
    yield put({ type: Types.GET_SYSTEM_SUCCESS, payload: system });
  } catch (error) {
    yield put({ type: Types.GET_SYSTEM_FAILED, payload: error.message });
  }
}


function* updateSystem(action) {
  try {
    const {data} = yield call(Api.system.update, action.payload);
    yield put({ type: Types.UPDATE_SYSTEM_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_SYSTEM_FAILED,
      payload: error.message
    });
  }
}


export default function* system() {
  yield takeLatest(Types.GET_SYSTEMS_REQUEST, getSystems);
  yield takeLatest(Types.GET_SYSTEM_REQUEST, getSystem);
  yield takeLatest(Types.UPDATE_SYSTEM_REQUEST, updateSystem);
}
