import { Button, Col, InputNumber, Row } from 'antd';
import SkeletonInput from 'antd/lib/skeleton/Input';
import Text from 'antd/lib/typography/Text';
import { get, head, isNaN, parseInt } from 'lodash';
import { useEffect, useState } from 'react';
import {
  useCreateExchangePoint,
  useExchangePointQueryParams,
  useExchangePoints,
  useResetExchangePoint,
  useUpdateExchangePoint,
} from '~/hooks/exchangePoint';
import { floorFormatter } from '~/utils/helper';
import { Breadcrumb } from '../Common';
import toastr from 'toastr'
import { formatNumberThreeComma, Permissions } from '~/hooks/utils';
import PercentChangeCustomerToPartner from './component/PercentChangeCustomerToPartner';
export default function ExchangePoint() {
  const {canCreate,canUpdate} = Permissions("REFERRAL")
  const [valueExchange,setValue] = useState(1)
  const [valueExchangePoint,setValueExchangePoint] = useState(1)
  // hook

  const [query] = useExchangePointQueryParams();
  const [exchangePoints, isLoading] = useExchangePoints(query);
  const [loadingSubmit, createExchangePoint] = useCreateExchangePoint();
  const [, updateExchangePoint] = useUpdateExchangePoint();
  useResetExchangePoint();
  //
  const exchangePoint = head(exchangePoints);
  const handleUpdate = ({ target },type) => {
    const coefficient = parseInt(get(target, 'value', 1).replaceAll(",",''));
    // const coefficient = parseInt(get(target, 'value', 1));
    if(target.value <= 0 || isNaN(target.value)){
     return toastr.error("Vui lòng nhập số > 0")
    }
  if(type === 'VND'){
    updateExchangePoint({ _id: get(exchangePoint, '_id'), coefficient });
  } 
  if(type === 'POINT'){
    updateExchangePoint({ _id: get(exchangePoint, '_id'), coefficient : 1/coefficient });
  }
  };
  const handleClickUpdate = () => {
    if(valueExchange <= 0 || isNaN(valueExchange)){
     return toastr.error("Vui lòng nhập số > 0")
    }
    if(valueExchangePoint > 1){
      updateExchangePoint({ _id: get(exchangePoint, '_id'), coefficient : 1/valueExchangePoint });
    }
    else{
      updateExchangePoint({ _id: get(exchangePoint, '_id'), coefficient : valueExchange });
    }
  };
  useEffect(() => {
    if (exchangePoints?.length === 0) {
      createExchangePoint({ coefficient: 1 });
    }
    else{
      if(get(head(exchangePoints),'coefficient') > 1){
        setValue(get(head(exchangePoints),'coefficient'))
        setValueExchangePoint(1)
      }
      else{
        setValueExchangePoint(1/get(head(exchangePoints),'coefficient'))
        setValue(1)
      }
    }
  }, [exchangePoints]);
  return (
    <div className="page-wraper page-content wh-bills-page">
      <div className="container-fluid">
        <Breadcrumb title="Hệ số quy đổi điểm" />
        <div className="page-content__main">
          <div className="page-content__right" style={{ width: '100%' }}>
            <Row align="middle">
              <Col span={8}>
                <Text strong>Tỉ lệ quy đổi điểm thưởng</Text>
              </Col>
              <Col span={2}>
                {isLoading ? (
                  <SkeletonInput active />
                ) : (
                  <InputNumber
                  disabled={!canUpdate}
                  bordered={false}
                    onPressEnter={(e) => handleUpdate(e,'VND')}
                    // min={1}
                    onChange={value => {
                      setValue(value)
                      setValueExchangePoint(1)
                    }}
                    value={valueExchange}
                    formatter={(value) => formatNumberThreeComma(value)}
                    style={{ width: '95%',borderBottom : '1px solid #999' }}
                  />
                )}
              </Col>
              
              <Col span={1}>
                  <span className='VND-Exchange'>VNĐ</span>
              </Col>
              <span style={{margin : '0 10px'}}>
                  =
              </span>
              <Col span={2} style={{justifySelf : 'flex-end'}}>
                {isLoading ? (
                  <SkeletonInput active />
                ) : (
                  <InputNumber
                    disabled={!canUpdate}
                  bordered={false}
                    onPressEnter={(e) => handleUpdate(e,'POINT')}
                    // min={1}
                    onChange={value => {
                      setValueExchangePoint(value)
                      setValue(1)
                    }}
                    value={valueExchangePoint}
                    formatter={(value) => formatNumberThreeComma(value)}
                    style={{ width: '95%',borderBottom : '1px solid #999' }}
                  />
                )}
              </Col>
              <Col span={1}>
                  <span className='POINT-Exchange'>Điểm</span>
              </Col>
              <Col span={4}>
                <Button   disabled={!canUpdate} loading={loadingSubmit} onClick={handleClickUpdate}>
                 Cập nhật
                </Button>
              </Col>
             
            </Row>
            
          </div>
        </div>
        <PercentChangeCustomerToPartner canCreate={canCreate} canUpdate={canUpdate}/>
      </div>
    </div>
  );
}
