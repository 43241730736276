import React, { useState } from 'react';
import { Table, Button, Space, Input, Switch, Modal } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import moment from 'moment';
import Breadcrumb from '~/components/Common/Breadcrumb';
import  { EditFilled } from '@ant-design/icons';
import CustomerTypeForm from './Form';
import {
  useCustomersType,
  useUpdateCustomerType,
  useCustomerTypeQueryParams,
  useUpdateCustomerTypeParams
} from '~/hooks/customerType';
import { useMatchPolicy } from '~/hooks';
import POLICY from '~/constants/policy';
import { useSpecialityOptions } from '~/hooks/speciality';
import WithPermission from '~/components/Common/WithPermission';

function onChange(checked) {
  console.log(`switch to ${checked}`);
}
const { Search } = Input;
const CustomerType = ({ history }) => {
  const canUpdate = useMatchPolicy(POLICY.UPDATE_WHSETTING);
  const canDelete = useMatchPolicy(POLICY.DELETE_WHSETTING);
  const [query, onPagingChange] = useCustomerTypeQueryParams();
  const [customersType, isLoading] = useCustomersType(query);
  const specialityOptions = useSpecialityOptions();
  // const [keyword, setKeyword] = useState(query.keyword);
  const [keyword, { setKeyword, onParamChange }] = useUpdateCustomerTypeParams(query);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [isSubmitLoading, updateCustomerType] = useUpdateCustomerType();
  const [customerTypeId, setCustomerTypeId] = useState(null);
  const [customerType, setCustomerType] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  
  const ColumnActions = ({
    id,
    state,
    useDeleteCustomerType,
    updateCustomerType,
    isSubmitLoading,
    ...restProps
  }) => {
    return (
      <div className="custom-table__actions">
        <WithPermission permission={POLICY.UPDATE_WHSETTING}>
          <EditFilled
            onClick={() => {
              setIsOpen(true);
              setCustomerTypeId(id);
              setCustomerType({ id, state, ...restProps })
            }}
            style={{ color: '#1f89e5' }}
          />
        </WithPermission>
        <WithPermission permission={POLICY.UPDATE_WHSETTING}>
          <WithPermission permission={POLICY.DELETE_WHSETTING}>
            <p style={{ marginLeft: '10px' }}>|</p>
          </WithPermission>
        </WithPermission>
        <WithPermission permission={POLICY.DELETE_WHSETTING}>
          <Switch
            checked={state === 'ACTIVE'}
            onChange={(value) =>
              updateCustomerType({ state: value ? 'ACTIVE' : 'INACTIVE', id })
            }
            // loading={isSubmitLoading}
          />
        </WithPermission>
      </div>
    );
  };
  const onPagingChangeLocal = ({ current, pageSize, total }, someObj) => {
    setCurrent(current);
  };
 
  const columns = [
    {
      title: 'STT',
      key: 'index',
      width: '70px',
      render: (text, record, index) => {
        return (+pagination.page - 1) * pagination.limit + index + 1;
      }
    },

    {
      title: 'Loại khách hàng',
      dataIndex: 'name',
      key: 'name',
      width: '250px',
      render: (record) => {
        return record;
      }
    },

    {
      title: 'Điểm',
      dataIndex: 'minScore',
      key: 'minScore',
      width: '250px',
      render: (record) => {
        return record;
      }
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: '110px',
      render: (record) => {
        record = {
          ...record,
          state: record.state,
          id: record._id
        };
        return (
          <ColumnActions {...record} updateCustomerType={updateCustomerType} />
        );
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Phân loại khách hàng" />
        <div className="page-content__main" style={{ display: 'block' }}>
          <div className="page-wraper__header">
            <Search
              style={{ width: 520 }}
              placeholder="Tìm loại khách hàng"
              enterButton
              allowClear
              onSearch={() => onParamChange({ keyword })}
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />

            <WithPermission permission={POLICY.WRITE_WHSETTING}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => {
                  setIsOpen(true);
                  setCustomerTypeId(null);
                }}
              >
                {' '}
                Thêm mới
              </Button>
            </WithPermission>
          </div>
          {isLoading ? (
            <SkeletonTable rowCount={customersType.length} columns={columns} />
          ) : (
            <Table
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={customersType}
              onChange={onPagingChangeLocal}
              size="middle"
              pagination={{
                // ...paging,
                // total: positions.length,
                current,
                showTotal: (total) => `Tổng cộng: ${total} `,
                onChange: (page) => {
                  setPagination({ ...pagination, page: page });
                }
              }}
            />
          )}
          ;
        </div>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <CustomerTypeForm
          specialityOptions={specialityOptions}
          customerTypeId={customerTypeId}
          onClose={() => setIsOpen(false)}
          passedCustomerType={customerType}
        />
      </Modal>
    </div>
  );
};

export default CustomerType;
