import { useEffect, useMemo } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors
} from '~/hooks/utils';

import {
  getEmployeeGroups,
  createEmployeeGroup,
  deleteEmployeeGroup,
  getEmployeeGroup,
  resetEmployeeGroupState,
  updateEmployeeGroup,
  getResources,
  updateGroupPermission,
  updateEmployeeGroupRedux,
  updateResourceRedux,
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ACTIONS } from '~/constants/policy';
import { get, isNil,last } from 'lodash';
import { StringToSlug,removeAccents } from '~/utils/helper';

const STORE_MODULE = 'employeeGroup';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector
} = getSelectors(STORE_MODULE);

const getSelector = (key) => (state) => state[STORE_MODULE][key];

const getResourcesLoadingSelector = getSelector('isGetResourcesLoading');
const resourcesSelector = getSelector('resources');
const getResourcesFailedSelector = getSelector('getResourcesFailed');

const isUpdatePermissionLoadingSelector = getSelector(
  'isUpdatePermissionLoading'
);
const actionsSelector = getSelector('actions');

export const useResources = () => {
  const { id: branchId } = useParams();

  const branchParam = useMemo(() => ({ branchId }), [branchId]);

  return useFetchByParam({
    action: getResources,
    loadingSelector: getResourcesLoadingSelector,
    dataSelector: resourcesSelector,
    failedSelector: getResourcesFailedSelector,
    param: branchParam,
    actionUpdate : updateResourceRedux,
  });
};

export const useResourceColumns = (renderPermission) => {
  const actions = useSelector(actionsSelector);
  const actionColumns = actions.map(({ name, key },index) => ({
    title: name,
    dataIndex: key,
    key: key,
    width : '13%',
    align:'center',
    render: renderPermission
  }));

  return [
    {
      title: 'Chức năng',
      dataIndex: ['resource', 'name'],
      key: 'resource',
      width : 'auto',
    },
    ...actionColumns
  ];
};

export const useEmployeeGroups = (param) => {
  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  const memoParam = useMemo(() => {
    return { ...param };
    //eslint-disable-next-line
  }, [createSuccess, updateSuccess, deleteSuccess, param]);

  return useFetchByParam({
    action: getEmployeeGroups,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: memoParam
  });
};

export const useCreateEmployeeGroup = (callback) => {
  useSuccess(
    createSuccessSelector,
    'Tạo mới nhóm nhân viên thành công',
    callback
  );
  useFailed(createFailedSelector, 'Tạo mới nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createEmployeeGroup
  });
};

export const useUpdateEmployeeGroup = (callback) => {
  useSuccess(
    updateSuccessSelector,
    'Cập nhật nhóm nhân viên thành công',
    callback
  );
  useFailed(updateFailedSelector, 'Cập nhật nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateEmployeeGroup
  });
};

export const useDeleteEmployeeGroup = (deleteCallback) => {
  useSuccess(
    deleteSuccessSelector,
    'Xoá nhóm nhân viên thành công',
    deleteCallback
  );
  useFailed(deleteFailedSelector, 'Xoá nhóm nhân viên thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteEmployeeGroup
  });
};

export const useEmployeeGroup = (params) => {
  return useFetchByParam({
    action: getEmployeeGroup,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params,
    actionUpdate : updateEmployeeGroupRedux
  });
};

export const useInitEmployeeGroup = (employeeGroup, id) =>
  useMemo(() => {
    if (!employeeGroup || !id) {
      return {};
    }

    return employeeGroup;
  }, [employeeGroup, id]);

export const useResetEmployeeGroup = () => {
  useResetState(resetEmployeeGroupState);
};

export const useUpdateGroupPermission = () => {
  return useSubmit({
    loadingSelector: isUpdatePermissionLoadingSelector,
    action: updateGroupPermission
  });
};

export const getNewPolicies = (group,isAssgined, resource, action) => {
  let newPolicies = {}
    if(action === 'admin'){ // if choose Quản lý
      if(isAssgined){ // On checked
        let allowAllPermission = {admin : true}
        ACTIONS?.forEach(permission => allowAllPermission[permission] = true) // Allow all permissions Form Action in POLYCY default 
        newPolicies = {...group?.policies,[resource]:allowAllPermission} // allow all policies
      }else{ // UnChecked
        newPolicies = {...group?.policies,[resource] : {}} // remove all policies
      }
    }
    else{
      newPolicies = {...group?.policies,
        [resource] : {...get(group,`policies.${resource}`), // Find Resource Action and Update
        [action] : isAssgined // Find Action and Update
      }}
    }
    return newPolicies
}

export const findStringToSlug = (source = '',target = '') => StringToSlug(source?.toLowerCase())?.includes(StringToSlug(target?.trim()?.toLowerCase()));
/**
 * 
 * @param {String} keyword 
 * @param {Array} resource 
 * @param {Function} updateResources 
 */
export const onSearchPermissions = (keyword='',resource=[],updateResources = () => {}) => {
  if(isNil(keyword) || keyword === '') return updateResources(resource)
  // keywords are not exists will return all
  const resultSearch = resource?.filter(item => {
  return  StringToSlug(get(item,'resource.name','')?.toLowerCase())?.includes(StringToSlug(keyword?.trim()?.toLowerCase()));
  })
  updateResources(resultSearch)
}

const adapterValidateUsername = async(username,callApi) => {
  const res = await callApi({username : removeAccents(username?.toLowerCase())}) // API Get username
  return get(res,'username','')
}
export const  autoCreateUsername = async({fullName,callApi}) => {
  const splitFullName = fullName?.trim()?.split(' ')
  let username = last(splitFullName)
  for(let i = 0  ; i <= splitFullName?.length - 2 ; i++){ // Lê Tất Chính => chinhlt
    const value = get(splitFullName,`${i}.[0]`,'') // last + first character each sentence
      username += value
  }
    const newUserName = await adapterValidateUsername(username,callApi)
  return  newUserName
}
