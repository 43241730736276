import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import {
    useFailed,
    useSubmit,
    useSuccess,
    useResetState,
    useFetchByParam,
    getSelectors,
    useQueryParams,
    checkInvalidDate,
    useBranchIdSessionStorage
  } from '~/hooks/utils';
  import {




getAllTask,
getTaskById,
createTask,
updateTask,
deleteTask,
getListBoardConfig,
getHistorySpentTime,
assignTask,
updateProgressTask,
updateTaskInit,
getHistoryActivity,
getHistoryActivityTaskById,
copyTask,
commentRequet,
pushEmotionRequest,
commentList,
commentPush,
commentEmotion,
deleteComment,
resetComment,
deleteCommentRequest,
updateCommentRequest,
updateComment,
searchTaskRequest,
updateTaskRelationRequest,
getTaskRelationRequest,

  } from '~/redux/action';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { clearQuerySearch, getExistProp } from '~/utils/helper';
import { clone, find, get, isArray, isString, omit, union, unionBy } from 'lodash';
import moment from 'moment';
import api from '~/api';
import { getSessionPrice } from '~/components/WhService/utils';
import { BASE_URL } from '~/constants/defaultValue';
const WORK_TASK = 'workListTask';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  
} = getSelectors(WORK_TASK);

const getSelector = (key) => (state) => state[WORK_TASK][key];
const pagingSelector = getSelector('paging');
const listTaskSelector = getSelector('listTask');
const listComment = getSelector('listComment');
const getTaskByIdSelector = getSelector('taskById');
const ListHistoryActivity = getSelector('listHistory')
const listRelation = getSelector('listTaskRelation');
const getSearchListTaskFailed = getSelector('getSearchListTaskFailed');
const getListTaskRelationFailed = getSelector('getListTaskRelationFailed')
const loadingHistoryActivity = getSelector('isLoadingHistory')
const loadingGetTaskRelation = getSelector('isloadingRelationTask')
const isLoadingComment = getSelector('isLoadingComment')
const getListHistoryFailed = getSelector('getListHistoryFailed');

const assignFailedSelector = getSelector('assignFailed');
const isLoadingAssignSelector = getSelector('isLoadingAssign');
const updateProgressSuccessSelector = getSelector('updateProgressSuccess');
const updateProgressFailedSelector = getSelector('updateProgressFailed');
// const updateTaskInitSelector = getSelector('listTask');
export const useWorkTaskQueryParams = (module) => {
  
    const prevKeyword = useRef(null);
    const [branchId] = useBranchIdSessionStorage();
    const query = useQueryParams();
    const keyword = query.get('keyword');
    const key = query.get('key');
    const status = query.get("status");
    const startDate = query.get("startDate") || null;
    const endDate = query.get("endDate")|| null;
    if (prevKeyword.current !== keyword && prevKeyword.pageSize !== keyword) {
        prevKeyword.current = keyword;
        prevKeyword.pageSize = keyword;
      }
      const createSuccess = useSelector(createSuccessSelector);
      const updateSuccess = useSelector(updateSuccessSelector);
      const deleteSuccess = useSelector(deleteSuccessSelector);
      return useMemo(() => {
          const queryParams = getExistProp({
            keyword,
            status,
            key,
            startDate,
            endDate
          }); 
          return [queryParams]
      }, [keyword, status,key, startDate, endDate, createSuccess, updateSuccess, deleteSuccess]);
     
}
export const useGetHistorySpentTimePaging =()=> useSelector(pagingSelector)
export const useUpdateWorkTaskParams = (query) => {

    const history = useHistory();
    const [keyword, setKeyword] = useState(query?.keyword);
  
    const onParamChange = (param) => {
      if (param['modules'] && !param['modules'].length) {
        param = { modules: undefined };
      }
      if(!param.code)
       for (const key in param) {
          param[key]=  isString(param[key]) ? (param[key].trim()).replace(/[.]/g,'') : param[key]
       }
      history.push({
        pathname: `${newPath}`,
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword, { setKeyword, onParamChange }];
  };
  //task
  export const useGetAllTask = (query) => {
    return useFetchByParam({
      action: getAllTask,
      loadingSelector,
      dataSelector: listTaskSelector,
      failedSelector: getListFailedSelector,
      param: query
    })
    
  }

  export const useGetTaskById = (params) => {
    return useFetchByParam({
      action: getTaskById,
      loadingSelector: getByIdLoadingSelector,
      dataSelector: getByIdSelector,
      failedSelector: getByIdFailedSelector,
      param: params
    })
  }
  export const useCreateTask = (callback) => {
    useSuccess(createSuccessSelector, 'Tạo mới công việc thành công', callback);
    useFailed(createFailedSelector, 'Tạo mới công việc thất bại');
  
      return useSubmit({
        loadingSelector: isSubmitLoadingSelector,
        action: createTask
      })
  }

  export const useUpdateTask = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhật công việc thành công', callback);
    useFailed(updateFailedSelector, 'Cập nhật này việc thất baị');
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updateTask,
    })
  }

  export const useDeleteTask = (callback) => {
    useSuccess(deleteSuccessSelector,'Xóa thành công này việc',callback);
    useFailed(deleteFailedSelector,'Xóa công việc thất bại');
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: deleteTask
    })
  }
//historySpentTime 
export const useGetHistoryActivityTaskById = (query) => {
  return useFetchByParam({
    action: getHistoryActivityTaskById,
    loadingSelector:loadingHistoryActivity,
    dataSelector: ListHistoryActivity,
    failedSelector: getListHistoryFailed,
    param: query
  })
}

export const useHandleAssign = () => {
  useFailed(assignFailedSelector);
  return useSubmit({
    loadingSelector: isLoadingAssignSelector,
    action: assignTask
  })
}

export const useUpdateProgress = (callback) => {
  useSuccess(updateProgressSuccessSelector, 'Cập nhật công việc thành công',callback);
  useFailed(updateProgressFailedSelector);
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateProgressTask,
  })
}
export const useCopyTask = () => {
  useSuccess(createSuccessSelector);
  useFailed(createFailedSelector, 'Đã thêm công việc thất bại');

    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: copyTask
    })
}
export const TASK_ITEM_API = {
  FETCH: 'FETCH',
  PUSH: 'PUSH',
  EMOTION: 'EMOTION',
  COMMENT: 'COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
}
export const useListenComment= (taskId)=>{
  const taskIdmemo =useMemo(()=>taskId,[taskId]);
  const dispatch = useDispatch();
  const data = useSelector(listComment); 
  useEffect(()=>{
    let eventListener
    if(taskIdmemo){
      eventListener = new EventSource(BASE_URL+'/api/v1/process-task-item-listen?taskItemId='+taskIdmemo);

      eventListener.onmessage = function(event){
        const parsedData = JSON.parse(event.data);

        switch (parsedData?.case) {
          case TASK_ITEM_API['FETCH']:{
              if(isArray(get(parsedData?.data,'comment',parsedData?.data))){
                dispatch(commentList(get(parsedData?.data,'comment',parsedData?.data)));
              }
              break;
            }
            case TASK_ITEM_API['PUSH']:{
              dispatch(commentPush(parsedData?.data));
            break;
          }
          case TASK_ITEM_API['EMOTION']:{
            dispatch(commentEmotion(parsedData?.data));
            break;
          }
          case TASK_ITEM_API['DELETE_COMMENT']:{
            dispatch(deleteComment(parsedData?.data));
            break;
          }
          case TASK_ITEM_API['COMMENT']:{
            dispatch(updateComment(parsedData?.data));
            break;
          }
          default:
            break;
        }
      }

      eventListener.addEventListener('error', e => {
        eventListener.close();
      });
    }
    return () => {
      if(eventListener){
        eventListener.close();
      }
    };
  },[taskIdmemo,dispatch])
  return [data]
}

export const useSendComment =()=>{
  return useSubmit({
    loadingSelector:isLoadingComment,
    action :commentRequet,
  })
}
export const useUpdateEmotionComment =()=>{
  return useSubmit({
    loadingSelector:()=>{},
    action :pushEmotionRequest,
  })
}
export const useDeleteComment =()=>{
  return useSubmit({
    loadingSelector:()=>{},
    action :deleteCommentRequest,
  })
}
export const useUpdateComment =()=>{
  return useSubmit({
    loadingSelector:()=>{},
    action :updateCommentRequest,
  })
}

export const useResetComment =()=>{
  return useSubmit({
    loadingSelector:()=>{},
    action :resetComment,
  })
}

export const useSearchTask =()=>{
  useFailed(getSearchListTaskFailed, 'Tìm kiếm liên kết bị lỗi!');
  return useSubmit({
    loadingSelector:()=>{},
    action :searchTaskRequest,
  })
}

export const useUpdateRelationTask =()=>{
  return useSubmit({
    loadingSelector:()=>{},
    action :updateTaskRelationRequest,
  })
}

export const useGetRelationTask =(query)=>{
  return useFetchByParam({
    action: getTaskRelationRequest,
    loadingSelector:loadingGetTaskRelation,
    dataSelector: listRelation,
    failedSelector: getListTaskRelationFailed,
    param: query
  })
}
