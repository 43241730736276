import { useMemo, useState } from 'react';

import { Col, Row, Space } from 'antd';
import { get } from 'lodash';

import { useResetCreateCancel, useResetWhBillItem, useWhBillItems } from '~/hooks/whBillItem';
import { useWhCategories, useWhCategoryQueryParams } from '~/hooks/whCategory';
import { useWhSessionOfDayQueryParams, useWhSessionsOfDay } from '~/hooks/whSessionOfDay';

import { MAX_PAGINATION_LIMIT, STATUS_PARTNER_CANCEL_BILL, TYPE_CANCEL_PARTNER } from '~/constants/defaultValue';


const WhPartnerBillItemCardsList = ({
  itemComponent,
  itemComponentProps,
  status,
  statuses,
  whPartnerId,
  showRequestCancel
}) => {
  const [queryBillItems, setQueryBillItem] = useState({
    ...(status && { status }),
    ...(statuses && { statuses }),
    whPartnerId,
    populate: true,
    limit: MAX_PAGINATION_LIMIT,
    page: 1,
    forCurrentWhPartnerOnly: whPartnerId,
  });
  const [whBillItems, isLoading] = useWhBillItems(queryBillItems);
  
  const [queryCategories] = useWhCategoryQueryParams(MAX_PAGINATION_LIMIT);
  const [whCategories, isWhCategoriesLoading] = useWhCategories(queryCategories);
  const [querySessions,] = useWhSessionOfDayQueryParams(MAX_PAGINATION_LIMIT);
  const [whSessionsOfDay, isWhSessionsOfDayLoading] = useWhSessionsOfDay(querySessions);

  const mergedWhBillItems = useMemo(() => whBillItems.map(item => ({
    ...item,
    snapshotService: {
      ...get(item, "snapshotService"),
      whCategory: whCategories.find(whCat => {
        return get(item, "snapshotService.categoryId") === whCat.id
      })
    },
  })), [whBillItems, whCategories]);

  const ItemComponent = itemComponent;
  const checkIsHaveQuestAndConfirm =   (whBillItem) => {
    let isHaveConfirmBill = false 
    let isHaveConfirmAppointment = false
    let isHaveConfirmBlock = false
    let isHaveCanceledTypeBlock = []
    let isHaveCanceledTypeAppointment = []
    let isHaveCanceledTypeBill = false
    get(whBillItem,'appointmentCancelPartner',[])?.forEach(appointment => {
      switch (get(appointment,'type')) {
        case TYPE_CANCEL_PARTNER.block.value:
          isHaveCanceledTypeBlock.push(appointment)
          break;
        case TYPE_CANCEL_PARTNER.appointment.value:
          isHaveCanceledTypeAppointment.push(appointment)
          break;
        case TYPE_CANCEL_PARTNER.bill.value:
          isHaveCanceledTypeBill = true
          break;
      
        default:
          break;
      }
      if([STATUS_PARTNER_CANCEL_BILL.APPROVED.value,STATUS_PARTNER_CANCEL_BILL.REFUSE.value].includes(get(appointment,'status'))){
        if(get(appointment,'type') === TYPE_CANCEL_PARTNER.block.value){
          isHaveConfirmBlock = true
        } 
        if(get(appointment,'type') === TYPE_CANCEL_PARTNER.appointment.value){
          isHaveConfirmAppointment = true
        } 
        if(get(appointment,'type') === TYPE_CANCEL_PARTNER.bill.value){
          isHaveConfirmBill = true
        } 
      }
    })
    return {isHaveConfirmBill,
      isHaveConfirmAppointment,
      isHaveConfirmBlock,
      isHaveCanceledTypeBlock,
      isHaveCanceledTypeAppointment,
      isHaveCanceledTypeBill}
  }
  return (
    <Row>
      <Col span={24}>
        <Space direction='vertical' style={{ width: "100%" }}>
          {/* <p className="blink-me" style={{ color: "orange" }}><Badge color="orange" />Warning: listing các bill item của tất cả trạng thái, tất cả đối tác trên hệ thống</p> */}
          {mergedWhBillItems.map((whBillItem, index) => {
            return (
              <ItemComponent
                key={index}
                whPartner={null}
                whBillItem={whBillItem}
                whSessionsOfDay={whSessionsOfDay}
                showRequestCancel={showRequestCancel}
                checkIsHaveQuestAndConfirm={checkIsHaveQuestAndConfirm(whBillItem)}
                {...itemComponentProps}
              />
            )
          })}
        </Space>
      </Col>
    </Row>
  )
}

export default WhPartnerBillItemCardsList;
