export const GET_TRAINING_COURSES_PARTNER_REQUEST =
  'GET_TRAINING_COURSES_PARTNER_REQUEST';
export const GET_TRAINING_COURSES_PARTNER_SUCCESS =
  'GET_TRAINING_COURSES_PARTNER_SUCCESS';
export const GET_TRAINING_COURSES_PARTNER_FAILED = 'GET_TRAINING_COURSES_PARTNER_FAILED';

export const GET_TRAINING_COURSE_PARTNER_REQUEST = 'GET_TRAINING_COURSE_PARTNER_REQUEST';
export const GET_TRAINING_COURSE_PARTNER_SUCCESS = 'GET_TRAINING_COURSE_PARTNER_SUCCESS';
export const GET_TRAINING_COURSE_PARTNER_FAILED = 'GET_TRAINING_COURSE_PARTNER_FAILED';

export const GET_TRAINING_COURSES_OF_PARTNER_REQUEST = 'GET_TRAINING_COURSES_OF_PARTNER_REQUEST';
export const GET_TRAINING_COURSES_OF_PARTNER_SUCCESS = 'GET_TRAINING_COURSES_OF_PARTNER_SUCCESS';
export const GET_TRAINING_COURSES_OF_PARTNER_FAILED = 'GET_TRAINING_COURSES_OF_PARTNER_FAILED';

export const GET_TRAINING_COURSES_OF_PARTNER_READY_REQUEST = 'GET_TRAINING_COURSES_OF_PARTNER_READY_REQUEST';
export const GET_TRAINING_COURSES_OF_PARTNER_READY_SUCCESS = 'GET_TRAINING_COURSES_OF_PARTNER_READY_SUCCESS';
export const GET_TRAINING_COURSES_OF_PARTNER_READY_FAILED = 'GET_TRAINING_COURSES_OF_PARTNER_READY_FAILED';

export const CREATE_TRAINING_COURSE_PARTNER_REQUEST =
  'CREATE_TRAINING_COURSE_PARTNER_REQUEST';
export const CREATE_TRAINING_COURSE_PARTNER_SUCCESS =
  'CREATE_TRAINING_COURSE_PARTNER_SUCCESS';
export const CREATE_TRAINING_COURSE_PARTNER_FAILED =
  'CREATE_TRAINING_COURSE_PARTNER_FAILED';

export const UPDATE_TRAINING_COURSE_PARTNER_REQUEST =
  'UPDATE_TRAINING_COURSE_PARTNER_REQUEST';
export const UPDATE_TRAINING_COURSE_PARTNER_SUCCESS =
  'UPDATE_TRAINING_COURSE_PARTNER_SUCCESS';
export const UPDATE_TRAINING_COURSE_PARTNER_FAILED =
  'UPDATE_TRAINING_COURSE_PARTNER_FAILED';

export const DELETE_TRAINING_COURSE_PARTNER_REQUEST =
  'DELETE_TRAINING_COURSE_PARTNER_REQUEST';
export const DELETE_TRAINING_COURSE_PARTNER_SUCCESS =
  'DELETE_TRAINING_COURSE_PARTNER_SUCCESS';
export const DELETE_TRAINING_COURSE_PARTNER_FAILED =
  'DELETE_TRAINING_COURSE_PARTNER_FAILED';

export const CONVERT_TRAINING_COURSE_PARTNER_REQUEST =
  'CONVERT_TRAINING_COURSE_PARTNER_REQUEST';
export const CONVERT_TRAINING_COURSE_PARTNER_SUCCESS =
  'CONVERT_TRAINING_COURSE_PARTNER_SUCCESS';
export const CONVERT_TRAINING_COURSE_PARTNER_FAILED =
  'CONVERT_TRAINING_COURSE_PARTNER_FAILED';
export const SUBMIT_TRAINING_COURSE_PARTNER_REQUEST =
  'SUBMIT_TRAINING_COURSE_PARTNER_REQUEST';
export const SUBMIT_TRAINING_COURSE_PARTNER_SUCCESS =
  'SUBMIT_TRAINING_COURSE_PARTNER_SUCCESS';
export const SUBMIT_TRAINING_COURSE_PARTNER_FAILED =
  'SUBMIT_TRAINING_COURSE_PARTNER_FAILED';

export const GET_NOTE_TIMESHEET_REQUEST =
  'GET_NOTE_TIMESHEET_REQUEST';
export const GET_NOTE_TIMESHEET_SUCCESS =
  'GET_NOTE_TIMESHEET_SUCCESS';
export const GET_NOTE_TIMESHEET_FAILED =
  'GET_NOTE_TIMESHEET_FAILED';

export const RESET_TRAINING_COURSE_PARTNER_STATE = 'RESET_TRAINING_COURSE_PARTNER_STATE';
