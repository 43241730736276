import { get, omit } from 'lodash';

import requester from './requester';

const whAppointment = {
  getWhAppointments: query => requester.get('/wh-appointment', query),
  getWhAppointmentsForCurrentWhPartnerOnly: query => requester.get('/partner/wh-appointment', query),
  getById: id => requester.get(`/wh-appointment/${id}`),
  delete: id => requester.delete(`/wh-appointment/${id}`),
  create: whAppointment => requester.post('/wh-appointment', whAppointment),
  update: whAppointment => requester.put(`/wh-appointment/${whAppointment.id}`, whAppointment),
  updateRating: ({id,...data}) => requester.put(`/wh-appointment/${id}/rating`, data),
  checkIn: whAppointment => requester.put(
    `/wh-bill/${whAppointment.whBillId}/wh-bill-item/${whAppointment.whBillItemId}/wh-appointment/${whAppointment.id}/check-in`,
    {
      // checkInPicture: get(whAppointment, "checkInPicture"),
      picture: get(whAppointment, "checkIn.picture"),
      latitude: get(whAppointment, "checkIn.latitude"),
      longitude: get(whAppointment, "checkIn.longitude"),
    }
  ),
  checkOut: whAppointment => requester.put(
    `/wh-bill/${whAppointment.whBillId}/wh-bill-item/${whAppointment.whBillItemId}/wh-appointment/${whAppointment.id}/check-out`,
    {
      // checkOutPicture: get(whAppointment, "checkOutPicture"),
      picture: get(whAppointment, "checkOut.picture"),
      latitude: get(whAppointment, "checkOut.latitude"),
      longitude: get(whAppointment, "checkOut.longitude"),
    }
  ),
  updateAttachments: whAppointment => requester.put(
    `/partner/wh-appointment/${whAppointment.id}/attachments`,
    whAppointment,
  ),
  updateListProduct: appointment => requester.put(`/partner/wh-appointment/${appointment.whAppointmentId}/updateDevices`, omit(appointment,['whAppointmentId'])),
  getWhAppointmentReadyToConfirmWarehouse : query => requester.get('/wh-appointment-check-assign-device', query),
  getWhAppointmentReadyToConfirmWarehouseOfPartner : query => requester.get('/wh-appointment-check-assign-device-partner', query),
  checkOutProduct : data => requester.put('/confirm-voucher-product-used', data),

};

export default whAppointment;
