import React from 'react';

import { Divider } from 'antd';
import { get } from 'lodash';
import { WH_BILL_ITEM_NOTES } from '~/constants/defaultValue';

const WhBillItemNote = ({
  whBillItem,
}) => {
  return (
    <>
      <Divider plain style={{ marginTop: 0, marginBottom: 0 }}>
        Gia tăng thời gian sử dụng
      </Divider>
      <div
        className='wh-service-detail__description--paragraph'
      >
        {
          !get(whBillItem, "note") ||
            !get(whBillItem, "note").length
            ? <p>(không có ghi chú)</p>
            : get(whBillItem, "note").map((note, index) => {
              return <p key={index}>- {get(WH_BILL_ITEM_NOTES, `${note}.vi`)}</p>
            })
        }
      </div>
    </>
  )
}

export default WhBillItemNote;
