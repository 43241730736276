import { Button, Col, Modal, Row } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'

export default function ModalConfirm({onCancel,handleOk,visible,loading,text,type,textOk,subText}) {
  return (
    <Modal destroyOnClose visible={visible} style={{textAlign : 'center'}} onCancel={onCancel} footer={null} closable={false}>
        <h5>{text || ""}</h5>
        <Text strong>{subText || ""}</Text>
      <Row >
        <Col span={12}><Button onClick={onCancel}>Huỷ</Button></Col>
        <Col span={12}><Button loading={loading} onClick={handleOk} type={type === 'delete' ?'danger':'primary'}>{textOk}</Button></Col>
      </Row>
    </Modal>
  )
}
