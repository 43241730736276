import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  deleteApplyJob,
  getApplyJob,
  getApplyJobs,
  resetApplyJobState,
  updateApplyJob
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.applyJob[key];
const pagingSelector = getSelector('paging');
export const useApplyJobPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetApplyJobSelector = getSelector('isGetApplyJobLoading');
const getApplyJobsFailedSelector = getSelector('getApplyJobsFailed');
const getApplyJobFailedSelector = getSelector('getApplyJobFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');

const ApplyJobsSelector = getSelector('ApplyJobs');
const ApplyJobSelector = getSelector('ApplyJob');

const createSuccessSelector = getSelector('createSuccess');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useApplyJobQueryParams = (reFetch) => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword');
  const status = query.get('status');
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const managementArea = query.get('managementArea');
  const category = query.get('category');
  const companyJobId = query.get('companyJobId');
  const createApplyJobSuccess = useSelector(createSuccessSelector);
  const updateApplyJobSuccess = useSelector(updateSuccessSelector);
  const deleteApplyJobSuccess = useSelector(deleteSuccessSelector);

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      status,
      startDate,
      endDate,
      managementArea,
      category,
      companyJobId,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    createApplyJobSuccess,
    deleteApplyJobSuccess,
    updateApplyJobSuccess,
    reFetch,
    status,
    startDate,
    endDate,
    managementArea,
    category,
    companyJobId,
  ]);
};

export const useUpdateApplyJobParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname:
        get(param, 'customPathName') || PATH_APP.applyJob.root,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, setKeyword, { onParamChange }];
};
export const useApplyJobs = (query) => {
  return useFetchByParam({
    action: getApplyJobs,
    loadingSelector,
    dataSelector: ApplyJobsSelector,
    failedSelector: getApplyJobsFailedSelector,
    param: query
  });
};
export const useApplyJob = (id) => {
  return useFetchByParam({
    action: getApplyJob,
    loadingSelector: loadinggetApplyJobSelector,
    dataSelector: ApplyJobSelector,
    failedSelector: getApplyJobFailedSelector,
    param: id
  });
};
export const useUpdateApplyJob = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhât Ứng tuyển thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateApplyJob
  });
};
export const useDeleteApplyJob = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Ứng tuyển thành công', callback);
  useFailed(deleteFailedSelector, 'Xoá nhật Ứng tuyển thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteApplyJob
  });
};

export const useResetApplyJob = () => {
  useResetState(resetApplyJobState);
};
