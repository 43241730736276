import { omit } from 'lodash';
import requester from './requester';

const whBillItem = {
  getWhBillItems: query => requester.get('/wh-bill-item', query),
  getWhBillItemsForCurrentWhPartnerOnly: query => requester.get('/partner/wh-bill-item', query),
  // getById: id => requester.get(`/wh-bill-item/${id}?raw=true`),
  getById: id => requester.get(`/wh-bill/bill-id/wh-bill-item/${id}?populate=true`),
  delete: id => requester.delete(`/wh-bill-item/${id}`),
  create: whBillItem => requester.post('/wh-bill-item', whBillItem),

  // update: whBillItem => requester.put(`/wh-bill-item/${whBillItem.id}`, whBillItem),
  update: whBillItem => requester.put(
    `/wh-bill/${whBillItem.whBillId}/wh-bill-item/${whBillItem._id}`,
    whBillItem
  ),

  // confirm bill item so BE can generate initial appointments for this bill item
  confirm: whBillItem => requester.put(
    `/wh-bill/${whBillItem.whBillId}/wh-bill-item/${whBillItem._id}/confirm`,
    whBillItem
  ),

  //
  customerCancel: whBillItem => requester.put(
    `/wh-bill/${whBillItem.whBillId}/wh-bill-item/${whBillItem._id}/customer-cancel`,
    whBillItem
  ),

  // update the existing appointments of this bill item
  updateWhAppointments: ({ whBillItem, whAppointments }) => requester.put(
    `/wh-bill/${whBillItem.whBillId}/wh-bill-item/${whBillItem._id}/update-appointments`,
    whAppointments,
  ),

  // generate more block(s) for this bill item
  createBlock: whBillItem => requester.post(
    `/wh-bill/${whBillItem.whBillId}/wh-bill-item/${whBillItem._id}/appointment-block`,
    {
      ...whBillItem,
      billId: whBillItem.whBillId,
      id: whBillItem._id,
    },
  ),

  // delete a single block from this bill item by blockNumber
  deleteBlock: ({ whBillItem, blockNumber }) => requester.delete(
    `/wh-bill/${whBillItem.whBillId}/wh-bill-item/${whBillItem._id}/appointment-block/${blockNumber}`
  ),
  //Cancel whApppointments
  cancelWhAppointments: (whBillItem) => requester.put(`/wh-bill/${whBillItem?.whBillId}/wh-bill-item/${whBillItem?.whBillItemId}/wh-appointment/all-cancel`, {message:whBillItem?.message}),
  updateAddress: (query) => requester.put(`/bill-partner/${query.whBillId}`, omit(query, ['whBillId'])),
  updateCompletedWhBillItem : (query) => requester.put(`/wh-bill-item/complete/${query.whBillId}`, omit(query, ['whBillId'])),

};

export default whBillItem;
