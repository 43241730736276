import {
  Button, Col, Form,
  Input, InputNumber, Radio, Row, Skeleton
} from 'antd';
import { get, head } from 'lodash';
import { useEffect, useState } from 'react';
import Editor from '~/components/Utils/Editor';
import { LANGUAGE } from '~/constants/defaultValue';
import { useCreateIntroUsingRefCode, useIntroUsingRefCode, useResetIntroUsingRefCodeAction, useUpdateIntroUsingRefCode } from '~/hooks/introUsingRefCode';

const FormItem = Form.Item;

const maxWidthLayout = {
  labelCol: { sm: 24, md: 24, lg: 3 },
  wrapperCol: { sm: 24, md: 24, lg: 21 }
};

const IntroUsingRefCodeForm = ({ onClose, id }) => {
  const [form] = Form.useForm();

  const [isSubmitLoading, handleCreate] = useCreateIntroUsingRefCode(onClose);
  const [, handleUpdate] = useUpdateIntroUsingRefCode(onClose);
  const [dataInit, isLoading] = useIntroUsingRefCode(id);
  const [language, setLanguage] = useState(LANGUAGE.VI);


  const onFinish = (values) => {
    if (id) {
      handleUpdate({ ...values, _id: id });
    } else {
      handleCreate(values);
    }
  };
  const onFinishFailed = (error) => {
    const {errorFields} = error;
    const firstError = get(head(errorFields),'name');
    if(firstError){
      if(firstError?.[1] === 'vi' && language === 'en'){
        setLanguage(LANGUAGE.VI)
      }
      if(firstError?.[1] === 'en' && language === 'vi'){
        setLanguage(LANGUAGE.EN)
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue(dataInit);
  },[dataInit,form,id]);

  useResetIntroUsingRefCodeAction();
  return (
    <div className="policy-form">
      <Form
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        requiredMark={false}
        validateTrigger="onChange"
        labelCol={{ sm: 24, md: 24, lg: 6, xl: 6 }}
        wrapperCol={{ sm: 24, md: 24, lg: 18, xl: 18 }}
      >
        <Row style={{ marginBottom: 20, marginTop: 20, justifyContent: 'flex-end' }}>
          <Radio.Group
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <Radio.Button
              className="policy-form__select-langue-btn"
              value={LANGUAGE.VI}
            >
              VI
            </Radio.Button>
            <Radio.Button
              className="policy-form__select-langue-btn"
              value={LANGUAGE.EN}
            >
              EN
            </Radio.Button>
          </Radio.Group>
        </Row>

        <Row>
          <Col span={12}>
            <FormItem
              label="Tiêu đề (vi)"
              name={['title', LANGUAGE.VI]}
              className={language === LANGUAGE.EN && 'hiden'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập'
                }
              ]}
            >
              {isLoading ? <Skeleton.Input active /> : <Input />}
            </FormItem>

            <FormItem
              label="Tiêu đề (en)"
              name={['title', LANGUAGE.EN]}
              className={language === LANGUAGE.VI && 'hiden'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập'
                }
              ]}
            >
              {isLoading ? <Skeleton.Input active /> : <Input />}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <FormItem label="Thứ tự" name="index">
              {isLoading ? <Skeleton.Input active /> : <InputNumber />}
            </FormItem>
          </Col>
        </Row>

        <Row className="news-category-form__editor">
          <FormItem
            label="Nội dung"
            name={['content', LANGUAGE.VI]}
            className={`news-category-form__description ${language === LANGUAGE.EN && 'hiden'
              }`}
            {...maxWidthLayout}
          >
            <Editor />
          </FormItem>

          <FormItem
            label="Nội dung"
            name={['content', LANGUAGE.EN]}
            className={`news-category-form__description ${language === LANGUAGE.VI && 'hiden'
              }`}
            {...maxWidthLayout}
          >
            <Editor />
          </FormItem>
        </Row>

        <Row className="policy-form__submit-box">
          {isSubmitLoading ? (
            <Button disabled>Huỷ</Button>
          ) : (
            <Button onClick={onClose}>Huỷ</Button>
          )}

          <Button type="primary" htmlType="submit" loading={isSubmitLoading}>
            {id ? 'Cập nhật' : 'Tạo mới'}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default IntroUsingRefCodeForm;
