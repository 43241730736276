import { useMemo, useEffect, useRef, useState } from 'react';
import {
  useFailed,
  useFetchByParam,
  useQueryParams,
  useSubmit,
  useSuccess,
  useResetState
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import {
  getAppointmentsAssociate,
  getAppointmentAssociate,
  createAppointmentAssociate,
  updateAppointmentAssociate,
  deleteAppointmentAssociate,
  resetAppointmentAssociateState
} from '~/redux/action';
import moment from 'moment';
import {useHistory} from 'react-router-dom'
import { isString } from 'lodash';
import { getExistProp } from '~/utils/helper';

const getSelector = key => state => state.appointmentAssociate[key];

const loadingSelector = getSelector('isLoading');
const appointmentsAssociateSelector = getSelector('appointmentsAssociate');
const getAppointmentsAssociateFailedSelector = getSelector('getAppointmentsAssociateFailed');

const isGetAppointmentAssociateSelector = getSelector('isGetAppointmentAssociate');
const appointmentSelector = getSelector('appointment');
const getAppointmentAssociateSelector = getSelector('getAppointmentAssociate');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');

const pagingSelector = getSelector('paging');

export const useAppointmentAssociatePaging = () => useSelector(pagingSelector);

export const useAppointmentAssociateQueryParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(null);
  const onParamChange = (param) => {

    if (param['modules'] && !param['modules'].length) {
      param = { modules: undefined };
    }

     for (const key in param) {
        param[key]=  isString(param[key]) ? param[key].trim() : param[key]
     }
    history.push({
      pathname: '/appointment/associate',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const useAssociateQueryParams = (param) => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  const page = query.get('page') || 1;
  const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const search = query.get(param)
  const fullName = query.get('fullName')
  const startTime = query.get('startTime');
  const endTime = query.get('endTime');

  const deleteAssociateSuccess = useSelector(deleteSuccessSelector);
  const updateAssociateSuccess = useSelector(updateSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      startTime,
      endTime,
      [param]: search,
    };

    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    // keyword,
    deleteAssociateSuccess,
    startTime,
    endTime,
    search,
    keyword,
    updateAssociateSuccess
    // convertWhPartnerImportSuccess,
  ]);
};
export const useAppointmentsAssociate = query => {
  return useFetchByParam({
    action: getAppointmentsAssociate,
    loadingSelector,
    dataSelector: appointmentsAssociateSelector,
    failedSelector: getAppointmentsAssociateFailedSelector,
    param: query
  });
};

export const useAppointmentAssociate = id => {
  return useFetchByParam({
    action: getAppointmentAssociate,
    loadingSelector: isGetAppointmentAssociateSelector,
    dataSelector: appointmentSelector,
    failedSelector: getAppointmentAssociateSelector,
    param: id
  });
};

export const useCreateAppointmentAssociate = callBack => {
  useSuccess(createSuccessSelector, 'Tạo mới lịch hẹn thành công', callBack);
  useFailed(createFailedSelector, 'Tạo mới lịch hẹn thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createAppointmentAssociate
  });
};

export const useUpdateAppointmentAssociate = callBack => {
  useSuccess(updateSuccessSelector, 'Cập nhật lịch hẹn liên kết thành công', callBack);
  useFailed(updateFailedSelector, 'Cập nhật lịch hẹn liên kết thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateAppointmentAssociate,

  });
};

export const useDeleteAppointmentAssociate = () => {
  useSuccess(deleteSuccessSelector, 'Xoá lịch hẹn thành công');
  useFailed(deleteFailedSelector, 'Xoá lịch hẹn thất bại');

  return useSubmit({
    loadingSelector,
    action: deleteAppointmentAssociate
  });
};

export const useInitAppointmentAssociate = (
  form,
  appointmentAssociate,
  appointmentAssociateId,
  initStartTime,
  setSelectedHospitalId,
  setSelectedSpecialityId
) => {
  const { hospital, speciality } = appointmentAssociate || {};

  const initAppointment = useMemo(() => {
    const {
      customer,
      source,
      status,
      time: dateTime,
      hospital,
      speciality,
      message
    } = appointmentAssociate || {};

    const localDateTime = moment(dateTime).toISOString(true);
    const [date, time] = (localDateTime || '').split('T');

    return {
      customer,
      source,
      status,
      date: moment(date, 'YYYY-MM-DD'),
      time: moment(time, 'HH:mm:zzsS').format('HH:mm'),
      hospitalId: hospital?.id,
      specialityId: speciality?.id,
      message
    };
  }, [appointmentAssociate]);

  useEffect(() => {
    form.resetFields();
    setSelectedHospitalId(hospital?.id);
    setSelectedSpecialityId({ specialityId: speciality?.id });
  }, [
    initAppointment,
    form,
    appointmentAssociateId,
    initStartTime,
    setSelectedHospitalId,
    hospital,
    speciality,
    setSelectedSpecialityId
  ]);

  return appointmentAssociateId ? initAppointment : { date: null };
};

export const useResetAppointmentAssociate = () => {
  useResetState(resetAppointmentAssociateState);
};
