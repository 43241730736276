import { CheckCircleOutlined, CloseCircleFilled, ExclamationCircleTwoTone, SyncOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tag, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { get } from 'lodash';
import React from 'react';
import { BaseBorderBox, WithPermission } from '~/components/Common';
import POLICIES from '~/constants/policy';
import { STATUS_CONFIRM_WAREHOUSE_VOUCHER, STATUS_WAREHOUSE_VOUCHER } from '~/constants/warehouse';
import { useMatchPolicy } from '~/hooks';
import { useEmployeeConfirmVoucherWarehouse, useEmployeeRejectVoucherWarehouse, usePartnerConfirmVoucherWarehouse, usePartnerRejectVoucherWarehouse, useSupplierConfirmVoucherWarehouse, useSupplierRejectVoucherWarehouse } from '~/hooks/warehouseVoucher';

const layout = {
    md : 24,
    sm : 24,
    xs : 24,
    xl : 8,
    lg : 8
  }
const RowInfo = ({ title, content }) => {
    return <Row style={{ margin: 5 }} justify='space-between'>
        <Col>
            <span>{title}: </span>
        </Col>
        <Col>
            {content}
        </Col>
    </Row>
};
const TagConfirm = ({ status }) => {
    if (status === STATUS_WAREHOUSE_VOUCHER.NEW) {
        return <Tag className='tagConfirm' icon={<SyncOutlined spin />} color="processing">
            Đang chờ xác nhận
        </Tag>
    }
    if (status === STATUS_WAREHOUSE_VOUCHER.CONFIRM) {
        return <Tag className='tagConfirm' icon={<CheckCircleOutlined />} color="success">
            Đã xác nhận
        </Tag>
    }
    if (status === STATUS_WAREHOUSE_VOUCHER.REJECT) {
        return <Tag className='tagConfirm' icon={<CloseCircleFilled />} color="error">
            Đã từ chối
        </Tag>
    }
    return null
}
export default function ConfirmBox({ infoEmployee, infoWhPartner, id, handleByPartner,infoSupplier,handleConfirmByClient,handleConfirmBySupplier,status,handleConfirmBySupplierPartner }) {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_WAREHOUSE);
    const [submitLoading, handleConfirm] = usePartnerConfirmVoucherWarehouse();
    const [, handleReject] = usePartnerRejectVoucherWarehouse();
    const [, handleConfirmEmployee] = useEmployeeConfirmVoucherWarehouse();
    const [, handleRejectEmployee] = useEmployeeRejectVoucherWarehouse();
    const [, handleConfirmSupplier] = useSupplierConfirmVoucherWarehouse();
    const [, handleRejectSupplier] = useSupplierRejectVoucherWarehouse();
    const showConfirm = (status, from) => {
        confirm({
            title: `${status === STATUS_WAREHOUSE_VOUCHER.CONFIRM ? "Xác nhận" : "Từ chối"} Phiếu?`,
            icon: <ExclamationCircleTwoTone />,
            content: 'Xác nhận lưu ?',
            onOk() {
                switch (status) {
                    case STATUS_WAREHOUSE_VOUCHER.CONFIRM:
                        if (from === 'partner') {
                            handleConfirm(id);
                        }
                        if (from === 'employee') {
                            handleConfirmEmployee(id)
                        }
                        if (from === 'supplier') {
                            handleConfirmSupplier(id)
                        }
                        break;
                    case STATUS_WAREHOUSE_VOUCHER.REJECT:
                        if (from === 'partner') {
                            handleReject(id);
                        }
                        if (from === 'employee') {
                            handleRejectEmployee(id)
                        }
                        if (from === 'supplier') {
                            handleRejectSupplier(id)
                        }
                        break;

                    default:
                        break;
                }
            },
            okText: "Xác nhận",
            cancelText: "Huỷ"
        });
    };
    return (
        <Row justify='space-between' gutter={8}>
            <Col style={{marginTop : 20}} {...layout}>
                <BaseBorderBox title={'Xác nhận từ nhân viên'}>
                    <RowInfo
                        title={"Trạng thái"}
                        content={<TagConfirm status={get(infoEmployee, 'confirmStatus')} />}
                    />
                    <RowInfo
                        title={"Tên nhân viên"}
                        content={<Typography.Text strong>{get(infoEmployee, 'name', '')}</Typography.Text>}
                    />
                    <RowInfo
                        title={"Ngày xác nhận"}
                        content={
                            <Tag className='tagConfirm' bordered={false} color="blue">{get(infoEmployee, 'dateConfirm', '')}</Tag>
                        }
                    />
                    {
                        !handleByPartner
                        && canUpdate
                        && status !== STATUS_CONFIRM_WAREHOUSE_VOUCHER.REJECT
                        && get(infoEmployee, 'confirmStatus') === STATUS_CONFIRM_WAREHOUSE_VOUCHER.NEW
                        && <div className='action'>
                            <Button
                                onClick={() => { showConfirm(STATUS_WAREHOUSE_VOUCHER.CONFIRM,'employee') }}
                                loading={submitLoading}
                                type='primary'>
                                Xác nhận
                            </Button>
                            <Button
                                onClick={() => { showConfirm(STATUS_WAREHOUSE_VOUCHER.REJECT,'employee') }}
                                loading={submitLoading}
                                danger
                            >
                                Từ chối
                            </Button>
                        </div>}
                </BaseBorderBox>
            </Col>
            <Col style={{marginTop : 20}} {...layout}>
                <BaseBorderBox title={'Xác nhận từ đơn vị cung cấp'}>
                    <RowInfo
                        title={"Trạng thái"}
                        content={<TagConfirm status={get(infoSupplier, 'confirmStatus')} />}
                    />
                    <RowInfo
                        title={"Tên đơn vị cung cấp"}
                        content={<Typography.Text strong>{get(infoSupplier, 'name', '')}</Typography.Text>}
                    />
                    <RowInfo
                        title={"Ngày xác nhận"}
                        content={
                            get(infoSupplier, 'dateConfirm') && <Tag className='tagConfirm' bordered={false} color="blue">{get(infoSupplier, 'dateConfirm', '')}</Tag>
                        }
                    />
                    {
                        (handleConfirmBySupplier || handleConfirmBySupplierPartner)
                        && status !== STATUS_CONFIRM_WAREHOUSE_VOUCHER.REJECT
                        && get(infoSupplier, 'confirmStatus') === STATUS_CONFIRM_WAREHOUSE_VOUCHER.NEW
                        && <WithPermission permission={!handleByPartner && POLICIES.UPDATE_WAREHOUSE}>
                        <div className='action'>
                            <Button
                                onClick={() => { showConfirm(STATUS_WAREHOUSE_VOUCHER.CONFIRM,'supplier') }}
                                loading={submitLoading}
                                type='primary'>
                                Xác nhận
                            </Button>
                            <Button
                                onClick={() => { showConfirm(STATUS_WAREHOUSE_VOUCHER.REJECT,'supplier') }}
                                loading={submitLoading}
                                danger
                            >
                                Từ chối
                            </Button>
                        </div>
                        </WithPermission>}
                </BaseBorderBox>
            </Col>
            <Col style={{marginTop : 20}} {...layout}>
                <BaseBorderBox title={'Xác nhận từ đối tác'}>
                    <RowInfo
                        title={"Trạng thái"}
                        content={<TagConfirm status={get(infoWhPartner, 'confirmStatus')} />}
                    />
                    <RowInfo
                        title={"Tên nhân viên"}
                        content={<Typography.Text strong>{get(infoWhPartner, 'name', '')}</Typography.Text>}
                    />
                    <RowInfo
                        title={"Ngày xác nhận"}
                        content={
                            get(infoWhPartner, 'dateConfirm') && <Tag className='tagConfirm' bordered={false} color="blue">{get(infoWhPartner, 'dateConfirm', '')}</Tag>
                        }
                    />
                    {
                        handleConfirmByClient
                        && status !== STATUS_CONFIRM_WAREHOUSE_VOUCHER.REJECT
                        && get(infoWhPartner, 'confirmStatus') === STATUS_CONFIRM_WAREHOUSE_VOUCHER.NEW
                        && <div className='action'>
                            <Button
                                onClick={() => { showConfirm(STATUS_WAREHOUSE_VOUCHER.CONFIRM,'partner') }}
                                loading={submitLoading}
                                type='primary'>
                                Xác nhận
                            </Button>
                            <Button
                                onClick={() => { showConfirm(STATUS_WAREHOUSE_VOUCHER.REJECT,'partner') }}
                                loading={submitLoading}
                                danger
                            >
                                Từ chối
                            </Button>
                        </div>}
                </BaseBorderBox>
            </Col>
        </Row>
    )
}
