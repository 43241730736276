import {
  GET_APPOINTMENTS_ASSOCIATE_REQUEST,
  DELETE_APPOINTMENT_ASSOCIATE_REQUEST,
  CREATE_APPOINTMENT_ASSOCIATE_REQUEST,
  UPDATE_APPOINTMENT_ASSOCIATE_REQUEST,
  GET_APPOINTMENT_ASSOCIATE_REQUEST,
  RESET_APPOINTMENT_ASSOCIATE
} from '../../constants/actionTypes';

export const getAppointmentsAssociate = query => ({
  type: GET_APPOINTMENTS_ASSOCIATE_REQUEST,
  payload: query
});

export const getAppointmentAssociate = id => ({
  type: GET_APPOINTMENT_ASSOCIATE_REQUEST,
  payload: id
});

export const createAppointmentAssociate = appointment => ({
  type: CREATE_APPOINTMENT_ASSOCIATE_REQUEST,
  payload: appointment
});

export const updateAppointmentAssociate = appointment => ({
  type: UPDATE_APPOINTMENT_ASSOCIATE_REQUEST,
  payload: appointment
});

export const deleteAppointmentAssociate = id => ({
  type: DELETE_APPOINTMENT_ASSOCIATE_REQUEST,
  payload: id
});

export const resetAppointmentAssociateState = () => ({
  type: RESET_APPOINTMENT_ASSOCIATE
});
