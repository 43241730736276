import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getPartnerCancelAppointmentsFailed: null,
  getPartnerCancelAppointmentFailed:null,
  partnerCancelAppointments: [],
  partnerCancelAppointment: null,
  isSubmitLoading : false,
  isGetPartnerCancelAppointmentLoading: false,
  PartnerCancelAppointment: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,



};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_PARTNER_CANCEL_APPOINTMENTS_REQUEST:
      state.isLoading = true;
      state.getPartnerCancelAppointmentsFailed = null;
      return;

    case Types.GET_PARTNER_CANCEL_APPOINTMENTS_SUCCESS:
      state.isLoading = false;
      state.partnerCancelAppointments = payload.docs;
      state.getPartnerCancelAppointmentsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_PARTNER_CANCEL_APPOINTMENTS_FAILED:
      state.isLoading = false;
      state.partnerCancelAppointments = [];
      state.getPartnerCancelAppointmentsFailed = payload;
      return;

    case Types.GET_PARTNER_CANCEL_APPOINTMENT_REQUEST:
      state.isGetPartnerCancelAppointmentLoading = true;
      state.partnerCancelAppointment = null
      state.getPartnerCancelAppointmentFailed = null;
      return;

    case Types.GET_PARTNER_CANCEL_APPOINTMENT_SUCCESS:
      state.isGetPartnerCancelAppointmentLoading = false;
      state.partnerCancelAppointment = payload;
      state.getPartnerCancelAppointmentFailed = null;
      return;

    case Types.GET_PARTNER_CANCEL_APPOINTMENT_FAILED:
      state.isGetPartnerCancelAppointmentLoading = false;
      state.partnerCancelAppointment = null;
      state.getPartnerCancelAppointmentFailed = payload;
      return;
    case Types.CREATE_PARTNER_CANCEL_APPOINTMENT_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_PARTNER_CANCEL_APPOINTMENT_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_PARTNER_CANCEL_APPOINTMENT_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_PARTNER_CANCEL_APPOINTMENT_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_PARTNER_CANCEL_APPOINTMENT_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.partnerCancelAppointments = state.partnerCancelAppointments?.map(e => {
          if(get(e,'_id') === get(payload,'_id')) return {...payload}
          return e
        })
        state.partnerCancelAppointment = payload;
        return;
  
      case Types.UPDATE_PARTNER_CANCEL_APPOINTMENT_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;
      case Types.DELETE_PARTNER_CANCEL_APPOINTMENT_REQUEST:
        state.isSubmitLoading = true;
        state.deleteSuccess = null;
        state.deleteFailed = null;
        return;
  
      case Types.DELETE_PARTNER_CANCEL_APPOINTMENT_SUCCESS:
        state.isSubmitLoading = false;
        state.deleteSuccess = payload;
        return;
  
      case Types.DELETE_PARTNER_CANCEL_APPOINTMENT_FAILED:
        state.isSubmitLoading = false;
        state.deleteFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_PARTNER_CANCEL_APPOINTMENT_STATE:
      return initState;

    default:
      return;
  }
}, initState);
