import {
  GET_DELIVERY_VOUCHERS_REQUEST,
  GET_DELIVERY_VOUCHER_REQUEST,
  CREATE_DELIVERY_VOUCHER_REQUEST,
  UPDATE_DELIVERY_VOUCHER_REQUEST,
  DELETE_DELIVERY_VOUCHER_REQUEST,
  RESET_DELIVERY_VOUCHER_STATE
} from '~/constants/actionTypes';

export const getDeliveryVouchers = (query) => ({
  type: GET_DELIVERY_VOUCHERS_REQUEST,
  payload: query
});

export const getDeliveryVoucher = (id) => ({
  type: GET_DELIVERY_VOUCHER_REQUEST,
  payload: id
});

export const createDeliveryVoucher = (params) => ({
  type: CREATE_DELIVERY_VOUCHER_REQUEST,
  payload: params
});

export const updateDeliveryVoucher = (params) => ({
  type: UPDATE_DELIVERY_VOUCHER_REQUEST,
  payload: params
});

export const deleteDeliveryVoucher = (id) => ({
  type: DELETE_DELIVERY_VOUCHER_REQUEST,
  payload: id
});

export const resetDeliveryVoucherState = () => ({
  type: RESET_DELIVERY_VOUCHER_STATE
});
