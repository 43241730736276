import { Form, InputNumber, Switch } from 'antd'
import React from 'react'
import { useEffect } from 'react'

function FormDuration({form,setVSetting,vSetting,setPause}) {
    useEffect(()=>{
        form.setFieldsValue(vSetting)
    },[form,vSetting])
    return (
        <Form
            form={form}
            onValuesChange={(e) => { 
                if(Object.keys(e)[0]==='pause'){
                    setPause(Object.values(e)[0])
                    return
                }
                setVSetting((value) => {
                const  ky = Object.keys(e)[0]
                return {...value, [ky]: { ...value[ky] ,...e[ky]}}
            }) }}
            layout='horizontal'
            style={{width:150}}
            labelCol={4}
            
        >
            <Form.Item label={'Pause'} name={'pause'}><Switch defaultChecked={vSetting.pause} title='pause'></Switch></Form.Item>
            <Form.Item label={'v1'} name={['v1','duration']}><InputNumber step={10}></InputNumber></Form.Item>
            <Form.Item label={'v2'} name={['v2','duration']}><InputNumber step={10}></InputNumber></Form.Item>
            <Form.Item label={'v3'} name={['v3','duration']}><InputNumber step={10}></InputNumber></Form.Item>
        </Form>
    )
}

export default FormDuration
