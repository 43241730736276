import { get } from 'lodash';
import api from '~/api';
import { REF_COLLECTION_VOUCHER_WAREHOUSE } from '~/constants/defaultValue';



    export const getInitAccountClient = async (client,setListClientsInit) => {
        const {userId,refCollection} = client;
      try {
        if (!userId) return;
        if (refCollection === REF_COLLECTION_VOUCHER_WAREHOUSE.wh_partner) {

          const partner = await api.whPartner.getById(userId);
          setListClientsInit(get(partner, 'docs').map(item => {
            return {
              ...item,
              label: `${get(item, "partnerCode")} - ${get(item, "name")}`,
            }
          }
          ))
          const client = {
            clientName: get(partner, 'docs[0].name'),
            userId: get(partner, 'docs[0]._id')
          }
        }
        else {
          const { data } = await api.whCustomerManagement.getById(userId);
          setListClientsInit([data].map(item => {
            return {
              ...item,
              label: `${get(item, "customerIdFull")} - ${get(item, "fullName")}`,
            }
          }
          ));
        }
      }
      catch (error) {
        console.error(error);
      }
    }
  
  export const setEmployee = (funcSet,user) => funcSet([user].map(item => {
    return {
      ...item,
      label: `${get(item, "fullName",'')}`,
    }
  }
  ));
  export const setClient = (funcSet,user) => funcSet([user].map(item => {
    return {
      ...item,
      label: `${get(item, "partnerCode",'')} - ${get(item, "name",'')}`,
    }
  }
  ));

  export const setInitStaff = async (employeeId,setState,form) => {
    if (!employeeId) return
    try {
      const response = await api.userEmployee.getById(employeeId);
      setState([get(response, 'data')].map(item => {
        return {
          ...item,
          label: `${get(item, "fullName",'')}`,
        }
      }
      ));
      if(form){
        form.setFieldsValue({ employeeId: get(response, 'data._id') });
      }
    } catch (error) {
      console.error(error);
    }
  }
