import produce from 'immer';
import { clone, get, uniqBy } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoadingHistory: false,
  getListHistoryFailed: undefined,
  listHistory: [],

  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,
  
  isLoadingStop: false,
  stopDeliverySuccess: null,
  stopDeliveryFailed: null,

  paging: null,

  listPartnerBorrowed: [],
  isLoadingPartnerBorrowed: false,
  getListPartnerBorrowedFailed: undefined,
  pagingPartnerBorrowed: null,
  pagingHistory: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_PRODUCT_LISTS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_PRODUCT_LISTS_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_PRODUCT_LISTS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_PRODUCT_OF_WHPARTNER_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_PRODUCT_OF_WHPARTNER_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_PRODUCT_OF_WHPARTNER_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;
    
      case Types.GET_PRODUCT_LIST_HISTORY_REQUEST:
      state.isLoadingHistory = true;
      state.getListHistoryFailed = null;
      return;

    case Types.GET_PRODUCT_LIST_HISTORY_SUCCESS:
      state.isLoadingHistory = false;
      state.listHistory = payload.docs;
      state.pagingHistory = getPaging(payload);
      return;

    case Types.GET_PRODUCT_LIST_HISTORY_FAILED:
      state.isLoadingHistory = false;
      state.getListHistoryFailed = payload;
      state.listHistory = [];
      return;

      case Types.GET_PRODUCT_LISTS_PARTNER_BORROWED_REQUEST:
        state.isLoadingPartnerBorrowed = true;
        state.getListPartnerBorrowedFailed = null;
        return;
  
      case Types.GET_PRODUCT_LISTS_PARTNER_BORROWED_SUCCESS:
        state.isLoadingPartnerBorrowed = false;
        // Clone > reverse > uniqBy To get Last Element (want this)
        const cloneList = clone(get(payload,'docs',[]));
        cloneList.reverse();
        const removeDuplicate = uniqBy(cloneList,(e) => get(e,'_id'));
        state.listPartnerBorrowed = removeDuplicate;
        state.pagingPartnerBorrowed = getPaging(payload);
        return;
  
      case Types.GET_PRODUCT_LISTS_PARTNER_BORROWED_FAILED:
        state.isLoadingPartnerBorrowed = false;
        state.getListPartnerBorrowedFailed = payload;
        state.listPartnerBorrowed = [];
        return;

    case Types.GET_PRODUCT_LIST_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_PRODUCT_LIST_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_PRODUCT_LIST_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;
    
    case Types.CREATE_PRODUCT_LIST_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_PRODUCT_LIST_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_PRODUCT_LIST_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_PRODUCT_LIST_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_PRODUCT_LIST_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_PRODUCT_LIST_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_PRODUCT_LIST_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_PRODUCT_LIST_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_PRODUCT_LIST_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;
    
    //Stop delivery product
    case Types.STOP_DELIVERY_PRODUCT_REQUEST:
      state.isLoadingStop = true;
      state.stopDeliverySuccess = null;
      state.stopDeliveryFailed = null;
      return;

    case Types.STOP_DELIVERY_PRODUCT_SUCCESS:
      state.stopDeliverySuccess = payload;
      return;

    case Types.STOP_DELIVERY_PRODUCT_FAILED:
      state.isLoadingStop = false;
      state.stopDeliveryFailed = payload;
      return;

    case Types.RESET_PRODUCT_LIST_STATE_ACTION:
      state.listPartnerBorrowed = [];
      state.createSuccess = null
      return 
    case Types.RESET_PRODUCT_LIST_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
