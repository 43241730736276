import produce from 'immer';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getQuotationsFailed: undefined,
  quotations: [],

  isGetQuotationLoading: false,
  Quotation: null,
  getQuotationFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  updateSuccess: null,
  updateFailed: null,

  createSuccess: null,
  createFailed: null,

  convertSuccess: null,
  convertFailed: null,

  paging: null,

  // orther me
  orderSelect: null,
  userSelect: null,
  addressSelect: null,

  copySuccess: null,
  copyFailed: null,
  isLoadingCopy: false
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_QUOTATIONS_REQUEST:
      state.isLoading = true;
      state.getQuotationsFailed = null;
      return;

    case Types.GET_QUOTATIONS_SUCCESS:
      state.isLoading = false;
      state.quotations = payload.docs;
      state.getQuotationsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_QUOTATIONS_FAILED:
      state.isLoading = false;
      state.quotations = [];
      state.getQuotationsFailed = payload;
      return;

    case Types.GET_QUOTATION_REQUEST:
      state.isGetQuotationLoading = true;
      state.quotation = null;
      state.getQuotationFailed = null;
      return;

    case Types.GET_QUOTATION_SUCCESS:
      state.isGetQuotationLoading = false;
      state.quotation = payload;
      return;

    case Types.GET_QUOTATION_FAILED:
      state.isGetQuotationLoading = false;
      state.getQuotationFailed = payload;
      return;

    case Types.DELETE_QUOTATION_REQUEST:
      state.isSubmitLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_QUOTATION_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_QUOTATION_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;
    case Types.COPY_QUOTATION_REQUEST:
      state.isLoadingCopy = true;
      state.copySuccess = null;
      state.copyFailed = null;
      return;

    case Types.COPY_QUOTATION_SUCCESS:
      state.isLoadingCopy = false;
      state.copySuccess = payload;
      return;

    case Types.COPY_QUOTATION_FAILED:
      state.isLoadingCopy = false;
      state.copyFailed = payload;
      return;

    case Types.CONVERT_QUOTATION_REQUEST:
      state.isSubmitLoading = true;
      state.convertSuccess = null;
      state.convertFailed = null;
      return;

    case Types.CONVERT_QUOTATION_SUCCESS:
      state.isSubmitLoading = false;
      state.convertSuccess = payload;
      return;

    case Types.CONVERT_QUOTATION_FAILED:
      state.isSubmitLoading = false;
      state.convertFailed = payload;
      return;
    case Types.CREATE_QUOTATION_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_QUOTATION_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_QUOTATION_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_QUOTATION_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_QUOTATION_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.Quotation = payload;
      return;

    case Types.UPDATE_QUOTATION_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    // orther me
    case Types.ADD_ORDER_SELECT:
      state.orderSelect = payload;
      return;
    case Types.ADD_USER_SELECT:
      state.userSelect = payload;
      return;
    case Types.ADD_ADDRESS_SELECT:
      state.addressSelect = payload;
      return;
    //
    case Types.RESET_QUOTATION_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
