import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getRankings({ payload: query }) {
    try {
      const response = yield call(Api.ranking.getAll, query);
      yield put({ type: Types.GET_RANKINGS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_RANKINGS_FAILED, payload: error });
    }
  }
function* getRanking({ payload: id }) {
    try {
      const response = yield call(Api.ranking.getById, id);
      yield put({ type: Types.GET_RANKING_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_RANKING_FAILED, payload: error });
    }
  }
  function* createRanking(action) {
    try {
      const data = yield call(Api.ranking.create, action.payload);
      yield put({ type: Types.CREATE_RANKING_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_RANKING_FAILED, payload: error });
    }
  }
  function* updateRanking(action) {
    try {
      const data = yield call(Api.ranking.update, action.payload);
      yield put({ type: Types.UPDATE_RANKING_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_RANKING_FAILED, payload: error });
    }
  }
  function* deleteRanking(action) {
    try {
      const data = yield call(Api.ranking.delete, action.payload);
      yield put({ type: Types.DELETE_RANKING_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_RANKING_FAILED, payload: error });
    }
  }
export default function* Rankings() {
    yield takeLatest(Types.GET_RANKINGS_REQUEST, getRankings);
    yield takeLatest(Types.GET_RANKING_REQUEST, getRanking);
    yield takeLatest(Types.CREATE_RANKING_REQUEST, createRanking);
    yield takeLatest(Types.UPDATE_RANKING_REQUEST, updateRanking);
    yield takeLatest(Types.DELETE_RANKING_REQUEST, deleteRanking);
  }