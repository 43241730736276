import requester from './requester';

const degree = {
  getAll: query => requester.get('/degree', query),
  getById: id => requester.get(`/degree/${id}?raw=true`),
  create: staffGroup => requester.post('/degree', staffGroup),
  update: staffGroup => requester.put(`/degree/${staffGroup.id}`, staffGroup),
  delete: id => requester.delete(`degree/${id}`)
};

export default degree;
