export const GET_EXCHANGEPOINTS_REQUEST =
  'GET_EXCHANGEPOINTS_REQUEST';
export const GET_EXCHANGEPOINTS_SUCCESS =
  'GET_EXCHANGEPOINTS_SUCCESS';
export const GET_EXCHANGEPOINTS_FAILED = 'GET_EXCHANGEPOINTS_FAILED';

export const GET_EXCHANGEPOINT_REQUEST = 'GET_EXCHANGEPOINT_REQUEST';
export const GET_EXCHANGEPOINT_SUCCESS = 'GET_EXCHANGEPOINT_SUCCESS';
export const GET_EXCHANGEPOINT_FAILED = 'GET_EXCHANGEPOINT_FAILED';

export const CREATE_EXCHANGEPOINT_REQUEST =
  'CREATE_EXCHANGEPOINT_REQUEST';
export const CREATE_EXCHANGEPOINT_SUCCESS =
  'CREATE_EXCHANGEPOINT_SUCCESS';
export const CREATE_EXCHANGEPOINT_FAILED =
  'CREATE_EXCHANGEPOINT_FAILED';

export const UPDATE_EXCHANGEPOINT_REQUEST =
  'UPDATE_EXCHANGEPOINT_REQUEST';
export const UPDATE_EXCHANGEPOINT_SUCCESS =
  'UPDATE_EXCHANGEPOINT_SUCCESS';
export const UPDATE_EXCHANGEPOINT_FAILED =
  'UPDATE_EXCHANGEPOINT_FAILED';


export const RESET_EXCHANGEPOINT_STATE = 'RESET_EXCHANGEPOINT_STATE';
