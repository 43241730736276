import requester from './requester';
const banner = {
  getAll: (banner) => requester.get(`/banner`, banner),
  getById: (id) => requester.get(`/banner/${id}?raw=true`),
  delete: (id) => requester.delete(`/banner/${id}`),
  create: (banner) => requester.post(`/banner`, banner),
  update: (banner) => requester.put(`/banner/${banner.id}`, banner)
};

export default banner;
