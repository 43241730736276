import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Skeleton } from 'antd';
import './index.scss';

const index = ({ partner, isLoading }) => {
  const address = partner?.address || {};

  return (
    <Card>
      <CardBody>
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className="partner-info">
            <div className="partner-info__main">
              <h5 className="font-size-18 mb-2">{partner?.name}</h5>

              <div className="text-muted">
                <p className="mb-1">
                  <i className=" uil-location-pin-alt me-1" />{' '}
                  {`${address.street} - ${address.ward} - ${address.district} - ${address.city}`}
                </p>
                <p className="mb-1">
                  <i className="uil uil-envelope-alt me-1"></i> {partner?.email}
                </p>
                <p>
                  <i className="uil uil-phone me-1"></i> {partner?.phoneNumber}
                </p>

                <p>
                  <i className="uil uil-clinic-medical me-1"></i>{' '}
                  {partner?.modules?.join(',')}
                </p>
              </div>
            </div>

            <div className="partner-info__avatar">
              <img src={partner?.logo} alt='logo' />
            </div>
          </div>
        )}
        <hr className="my-4" />
      </CardBody>
    </Card>
  );
};

export default index;
