import { DeleteFilled, DeleteOutlined, InfoCircleFilled, InfoCircleTwoTone, LockOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, Modal, Popconfirm, Row, Table, Tag, Tooltip, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get, pick } from 'lodash';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import toastr from 'toastr';
import { infoDisabledRemoveEquipment, infoDisabledTitleEquipmentAssigned, useModalConfirmVoucher } from '~/hooks';
import { formatNumberThreeCommaToFixed } from '~/hooks/utils';
import { handleDeletePreBooking } from '~/hooks/wareHouse';
import FindEquiment from './FindEquiment';
import InfomationProduct from './InfomationProduct';
import { CONFIRM_VOUCHER_STATUS_VI } from '~/constants/confirmVoucherWarehouse';
import FormConfirmVoucherWarehouse from '~/components/WareHouse/ConfirmVoucherWarehouse/FormConfirmVoucherWarehouse/FormConfirmVoucherWarehouse';
import { PATH_APP } from '~/routes/paths';
import useAssignWarehouseAppointmentStore from '~/storeContext/AssignWarehouseWhAppointment';
import { STATUS_CONFIRM_WAREHOUSE_VOUCHER } from '~/constants/warehouse';
import ToolTipButton from '~/components/Utils/ToolTipButtonPermission';

const TagProduct = ({ isSelected, product, onClick }) => <Tag onClick={onClick} className={`${isSelected ? "" : 'cursor-pointer opacity-50'} tagProduct`} color={isSelected ? 'processing' : 'default'}>{get(product, 'code', '')} - {get(product, 'name', '')}</Tag>

const TableEquipment = forwardRef(({ whAppointmentId }, ref) => {
  const {
    handleSetNewDataSourceEquipment,
    handleClearRowDataSourceEquipment,
    dataSourceEquipment,
    includesProductSelected,
    isFullEquipmentAndMaterial,
    whAppointmentSelected,
    mutateAppointment,
    totalQuantityUsedEachGroup,
    setTotalQuantityUsedEachGroup,
  } = useAssignWarehouseAppointmentStore();
  const mergedDataSourceWithWhAppointment = useMemo(() => {
    const newDataSource = dataSourceEquipment?.map(data => {
      return { ...data, whAppointmentSelected }
    })
    return newDataSource
  }, [dataSourceEquipment, whAppointmentSelected]);
  const [visibleModalFindEquiment, setVisibleModalFindEquiment] = useState(false);
  //Modal Confirm Voucher
  const { idConfirmVoucher, onCloseConfirmVoucher, onOpenConfirmVoucher, visibleConfirmVoucher } = useModalConfirmVoucher();
  //
  const [idViewProduct, setIdViewProduct] = useState(null);
  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useImperativeHandle(ref, () => ({
    getDataListProduct() {
      const listProduct = mergedDataSourceWithWhAppointment?.filter(v => {
        return selectedRowKeys?.some(e => e === get(v, '_id'))
      })
      return listProduct;
    }
  }));
  const handleOpenModalViewProduct = (id) => {
    setIsOpenViewProduct(true);
    setIdViewProduct(id);
  };
  const handleCloseModalViewProduct = () => {
    setIsOpenViewProduct(false);
    setIdViewProduct(null);
  };
  /// VOUCHER WAREHOUSE
  const [idSelect, setIdSelect] = useState(null);
  const handleOpenModalFindEquiment = (id) => {
    setVisibleModalFindEquiment(true)
    setIdSelect(id)
  }
  const handleCloseModalFindEquiment = () => {
    setVisibleModalFindEquiment(false)
    setIdSelect(null)
  }
  const handleSetNewDataSource = (data, mode = 'assign', isConfirm) => {
    if (isConfirm) {
      return toastr.error("Công cụ đã được xác nhận, vui lòng hoàn trả ở phiếu xác nhận để đổi máy")
    }
    handleSetNewDataSourceEquipment(data, mode, handleCloseModalFindEquiment)
  }
  const handleClearRow = (groupEquipmentId) => {
    handleClearRowDataSourceEquipment(groupEquipmentId)
  }
  const callbackRemovePreBooking = (dataRemove) => {
    handleSetNewDataSource(dataRemove, 'removePreBooking')
  }
  const handleRemovePreBooking = (id) => {
    handleDeletePreBooking(id, callbackRemovePreBooking)

  }
  const columns = [
    {
      title: "Nhóm Công cụ",
      dataIndex: 'groupEquipmentId',
      key: 'groupEquipmentId',
      render: (item, record) => {
        return <Row align='middle' gutter={16} >
          {get(record, 'isBlock') && <Tooltip zIndex={9999} title={'nhóm Công cụ hoặc danh mục ngừng hoạt động, vui lòng kiểm tra lại'}>
            <InfoCircleTwoTone twoToneColor={'red'} />
          </Tooltip>}
          <Col><Text> {get(item, 'name.vi', '')} </Text> </Col>
        </Row>
      }
    },

    {
      title: "Công cụ chuẩn",
      dataIndex: 'priorityProduct',
      key: 'priorityProduct',
      width: '15%',
      align: 'center',
      render: (item, record) => {
        const isSelected = includesProductSelected(get(item, '_id'));
        const isConfirm = get(record, 'statusConfirm') === STATUS_CONFIRM_WAREHOUSE_VOUCHER.CONFIRM;
        return item && <Row align='middle' justify='space-between'>
          <Col span={2}> <InfoCircleFilled onClick={() => handleOpenModalViewProduct(get(item, '_id'))} /></Col>
          <Col span={21}>
            <TagProduct disabled={isConfirm} onClick={() => handleSetNewDataSource(item, 'assign', isConfirm)} isSelected={isSelected} product={item} />
          </Col>
        </Row>
      }
    },
    {
      title: "Công cụ của đối tác",
      dataIndex: 'priorityPartner',
      key: 'priorityPartner',
      width: '15%',
      align: 'center',
      render: (item, record) => {
        const isSelected = includesProductSelected(get(item, '_id'));
        const isConfirm = get(record, 'statusConfirm') === STATUS_CONFIRM_WAREHOUSE_VOUCHER.CONFIRM;

        return item && <Row justify='space-between' align='middle'>
          <Col span={2}><InfoCircleFilled onClick={() => handleOpenModalViewProduct(get(item, '_id'))} /></Col>
          <Col span={21}>
            <TagProduct disabled={isConfirm} onClick={() => handleSetNewDataSource(item, 'assign', isConfirm)} isSelected={isSelected} product={item} />
          </Col>
        </Row>
      }
    },
    {
      title: "Công cụ đã chọn",
      dataIndex: 'ref',
      key: 'ref',
      align: 'center',
      width: '30%',
      render: (item, record) => {
        const { status: statusEquipment, msg: msgEquipment } = infoDisabledTitleEquipmentAssigned(record);
        const { status, msg } = infoDisabledRemoveEquipment(record);

        return <Row align='middle'>
          <Col span={2}>  {get(record, 'refId') && <InfoCircleFilled onClick={() => handleOpenModalViewProduct(get(record, 'refId'))} />}</Col>
          <Col span={18}>
            <div className='d-flex justify-content-between align-items-center'>
              <Typography.Link disabled={statusEquipment} onClick={() => handleOpenModalFindEquiment(get(record, 'groupEquipmentId._id'))}>
                {get(record, 'refId') ?
                  <Tooltip zIndex={9999} title={msgEquipment}>
                    <span className='name-product'>
                      {get(item, 'name', '')}
                      {statusEquipment && <LockOutlined />}
                    </span>
                  </Tooltip>
                  : "(Chưa chọn Công cụ)"}
              </Typography.Link>

              {/* Tag Confirm */}
              {get(record, 'refId') && <Tooltip zIndex={9999} title="Phiếu xác nhận">
                {
                  get(record, 'statusConfirm') && <Tag className={'cursor-pointer'} onClick={() => onOpenConfirmVoucher(get(record, 'confirmVoucherId'))} color={'success'}>
                    {CONFIRM_VOUCHER_STATUS_VI?.[get(record, 'statusConfirm')]}
                  </Tag>
                }
                {
                  !get(record, 'statusConfirm') && isFullEquipmentAndMaterial && <Tag className='tagConfirmVoucher' color={'warning'}>
                    <span>
                      Chưa có phiếu xác nhận <Typography.Link className='d-block' onClick={() => window.open(PATH_APP.confirmVoucherWareHouse.root)}>(tạo phiếu)</Typography.Link>
                    </span>
                  </Tag>
                }
              </Tooltip>}
            </div>
          </Col>
          <Col span={4}>
            <Tooltip zIndex={9999} title={msg}>
              {get(record, 'refId') && <Button disabled={status} onClick={() => handleClearRow(get(record, 'groupEquipmentId._id'))}><DeleteFilled /></Button>}
            </Tooltip>
          </Col>
        </Row>
      }


    },
    {
      title: "Số lần sử dụng",
      dataIndex: 'actualQuantity',
      key: 'actualQuantity',
      width: '12%',
      align: 'center',
      render: (actualQuantity, record,index) => {
        const idGroup = get(record,'groupEquipment');
        const totalQuantityUsed = totalQuantityUsedEachGroup?.[idGroup] || 0 ;
        const remainingQuantity =  get(record,'quantityGroupInit') - totalQuantityUsed ;
        const { status: statusEquipment, msg: msgEquipment } = infoDisabledTitleEquipmentAssigned(record);
        index === 2 && console.log(record,'record2')
        index === 1 && console.log(record,'record1')
        const changeQuantity = (newValue) => {
          const floorValue = Math.floor(newValue);
          const valueChange = floorValue - actualQuantity;
          const newQuantityUsed = totalQuantityUsedEachGroup[idGroup] + valueChange;
          if(floorValue < 0 || isNaN(floorValue) || (newQuantityUsed >  get(record,'quantityGroupInit')) ) return;
          handleSetNewDataSource({
            groupProductId: get(record, 'groupEquipmentId._id'),
            actualQuantity: floorValue
          }
            , 'quantity');
          setTotalQuantityUsedEachGroup({
            ...totalQuantityUsedEachGroup,
            [idGroup] : totalQuantityUsedEachGroup[idGroup] + valueChange
          })
        }
        const onChange = (isIncrease) => {
          const newValue =  isIncrease ? get(record, 'actualQuantity', 0) + 1 : get(record, 'actualQuantity', 0) - 1;
          changeQuantity(newValue);
        }
        return get(record, 'ref') && <div>
          <Row wrap={false} justify='space-between' align='middle'>
            <Col>
              <Tooltip zIndex={9999} title={msgEquipment}>
                <Button style={{ borderRadius: 10 }} disabled={actualQuantity <= 0 || statusEquipment} onClick={() => onChange(false)}>-</Button>
              </Tooltip>
            </Col>
            <Col style={{ paddingRight: 5, paddingLeft: 5 }}><InputNumber  disabled={statusEquipment} onPressEnter={(e) => changeQuantity(Number(e.target.value))} onBlur={(e) => changeQuantity(Number(e.target.value))} maxLength={3} style={{width : 50}} value={actualQuantity || 0}/></Col>
            <Col>
            <Tooltip zIndex={9999} title={msgEquipment}>
              <Button disabled={statusEquipment || (remainingQuantity <= 0)} style={{ borderRadius: 10 }} type='primary' onClick={() => onChange(true)}>+</Button>
            </Tooltip>
            </Col>
          </Row>
          <Typography.Text type='secondary' style={{ fontSize: 10 }}>(Tổng số lần còn lại: {remainingQuantity}/{get(record,'quantityGroupInit')})</Typography.Text>
        </div>
      }
    },

    {
      title: "Giá khấu hao",
      dataIndex: 'ref',
      key: 'valueDepreciation',
      width: '5%',
      align: 'center',
      render: (item, record) => <Text strong>{formatNumberThreeCommaToFixed(get(item, 'valueDepreciation', 0)* get(record, 'actualQuantity', 0)) }</Text>
    },
    {
      title: "Công cụ đặt trước",
      dataIndex: 'preBooking',
      key: 'preBooking',
      align: 'center',
      render: (item) => item && <Row justify='space-between' align='middle'>
        <Col span={2}>  <InfoCircleFilled onClick={() => handleOpenModalViewProduct(get(item, 'productId'))} /></Col>
        <Col span={16}>
          {get(item, 'product.name', '')}
        </Col>
        <Col span={4}>
          <Popconfirm
            zIndex={10000}
            title="Bạn có chắc muốn xóa công cụ đặt trước?"
            onConfirm={() => handleRemovePreBooking(get(item, '_id'))}
          >
            <Button><DeleteOutlined /></Button>
          </Popconfirm>
        </Col>
      </Row>
    },
  ]
  const renderSummary = pageData => {
    const totalGrandTotal = pageData.reduce((sum, curr) => {
      return sum + get(curr, 'ref.valueDepreciation', 0) * get(curr, 'quantity', 0)
    }, 0);

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell align="right" colSpan={3}>
          <h5>Tổng giá khấu hao: {formatNumberThreeCommaToFixed(totalGrandTotal)}</h5>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }
  return (
    <>
      <Table
        // summary={renderSummary} 
        rowKey={(rc => rc._id)}
        // rowSelection={action === ACTION_ASSIGN_EQUIPMENT.refund && rowSelection} 
        columns={columns}
        dataSource={mergedDataSourceWithWhAppointment}
        pagination={false}

      />

      <Modal closable={null} destroyOnClose footer={null} width={'auto'} onCancel={handleCloseModalFindEquiment} visible={visibleModalFindEquiment}>
        <FindEquiment
          dataSource={dataSourceEquipment}
          whAppointmentId={whAppointmentId}
          mutateAppointment={mutateAppointment}
          onCancel={handleCloseModalFindEquiment}
          setDataSource={handleSetNewDataSource}
          id={idSelect}
        />
      </Modal>
      <Modal
        destroyOnClose
        title={`Thông tin công cụ`}
        width={700}
        visible={isOpenViewProduct}
        closable={false}
        footer={null}
        onCancel={handleCloseModalViewProduct}>
        <InfomationProduct id={idViewProduct} />
      </Modal>

      <Modal
        destroyOnClose
        width={'auto'}
        visible={visibleConfirmVoucher}
        footer={null}
        onCancel={onCloseConfirmVoucher}
      >
        <FormConfirmVoucherWarehouse mutate={mutateAppointment} id={idConfirmVoucher} onCancel={onCloseConfirmVoucher} />
      </Modal>
    </>
  )
})

export default TableEquipment
