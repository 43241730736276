import requester from './requester';

const appointment = {
  getAll: query => requester.get('/appointment',query),
  getAllOfHospitalDeleted: query => requester.get('/appointment-detele-branch',query),
  getById: id => requester.get(`appointment/${id}`),
  delete: id => requester.delete(`appointment/${id}`),
  create: appointment => requester.post('appointment', appointment),
  update: appointment => requester.put(`appointment/${appointment.id}`, appointment),
  getByIdCustomer: (query) => requester.get(`/appointment-customer`, query)
};

export default appointment;
