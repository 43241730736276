import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getJobFormsFailed: null,
  getJobFormFailed: null,
  JobForms: [],
  JobForm: null,
  isSubmitLoading: false,
  isGetJobFormLoading: false,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_JOB_FORMS_REQUEST:
      state.isLoading = true;
      state.getJobFormsFailed = null;
      return;

    case Types.GET_JOB_FORMS_SUCCESS:
      state.isLoading = false;
      state.JobForms = get(payload, 'docs', []);
      state.getJobFormsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_JOB_FORMS_FAILED:
      state.isLoading = false;
      state.JobForms = [];
      state.getJobFormsFailed = payload;
      return;
    case Types.GET_JOB_FORM_REQUEST:
      state.isGetJobFormLoading = true;
      state.JobForm = null
      state.getJobFormFailed = null;
      return;

    case Types.GET_JOB_FORM_SUCCESS:
      state.isGetJobFormLoading = false;
      state.JobForm = payload;
      state.getJobFormFailed = null;
      return;

    case Types.GET_JOB_FORM_FAILED:
      state.isGetJobFormLoading = false;
      state.JobForm = null;
      state.getJobFormFailed = payload;
      return;
    case Types.CREATE_JOB_FORM_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_JOB_FORM_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_JOB_FORM_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_JOB_FORM_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_JOB_FORM_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.JobForms = state.JobForms.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.JobForm = payload;
      return;

    case Types.UPDATE_JOB_FORM_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_JOB_FORM_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_JOB_FORM_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_JOB_FORM_STATE_ACTION:
      state.createSuccess = null;
      state.createFailed = null;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;
    case Types.RESET_STORE:
    case Types.RESET_JOB_FORM_STATE:
      return initState;

    default:
      return;
  }
}, initState);
