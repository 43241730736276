import * as Types from '../../constants/actionTypes';
  
  export const getBonusIntroCustomers = query => ({
    type: Types.GET_BONUS_INTRO_CUSTOMERS_REQUEST,
    payload: query
  });
  export const getBonusIntroCustomer = id => ({
    type: Types.GET_BONUS_INTRO_CUSTOMER_REQUEST,
    payload: id
  });
  export const createBonusIntroCustomer = instance => ({
    type: Types.CREATE_BONUS_INTRO_CUSTOMER_REQUEST,
    payload: instance
  });
  export const updateBonusIntroCustomer = instance => ({
    type: Types.UPDATE_BONUS_INTRO_CUSTOMER_REQUEST,
    payload: instance
  });
  export const deleteBonusIntroCustomer = id => ({
    type: Types.DELETE_BONUS_INTRO_CUSTOMER_REQUEST,
    payload: id
  });
  export const resetBonusIntroCustomerState = () => ({
    type: Types.RESET_BONUS_INTRO_CUSTOMER_STATE
  });
