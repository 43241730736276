import requester from './requester';

const whBill = {
  getAll: (query) =>
    requester.get('/wh-bill', {
      ...query,
      managementArea: query.managementArea
        ? JSON.stringify(
            JSON.parse(query.managementArea).map((item) => item.value)
          )
        : []
    }),
  getDepreciations: (query) =>
    requester.get('/wh-bill-extraction', query),
  getById: (id) => requester.get(`/wh-bill/${id}`),
  getByCustomerId : (query) =>requester.get(`/wh-bill?customerId`,query),
  update: (whBill) => requester.put(`/wh-bill/${whBill._id}`, whBill),
  confirmPrepayment: (whBill) =>
    requester.put(`/wh-bill/${whBill._id}/confirm-prepayment`, whBill),
  cancel: (whBill) =>
    requester.put(`/wh-bill/${whBill._id}/cancel`, whBill),
  customerCancel: (whBill) =>
    requester.put(`/wh-bill/${whBill._id}/customer-cancel`, whBill),
  getByIdCustomer: (query) => requester.get(`/wh-bill-customer`, query)
};

export default whBill;
