import moment from 'moment';
export const getAllDateOfWeek = () => {
  const date = new Date();
  const numberOfWeek =  Math.ceil((new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()) / 7)
    let startDateMonth = moment().startOf('month');
    let endDateMonth = moment().endOf('month');
    let arrDate = new Array(numberOfWeek)?.fill(0).map((item, index) => {
    let  endDate =  moment(startDateMonth).add(6, 'days')
    let startDate =  moment(startDateMonth).format("YYYY-MM-DD")
    startDateMonth =  moment(startDateMonth).add(7, 'days')
    if(index === new Array(numberOfWeek)?.fill(0).length - 1) // last week
    {
      return {
      index : index +1,
      date : {startDate,endDate : moment(endDateMonth).format("YYYY-MM-DD")}
    }
    }
    return {
      index : index +1,
      date : {startDate,endDate : moment(endDate).format("YYYY-MM-DD")}
    }
  })
  return arrDate
}
