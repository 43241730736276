import { Button, Col, Form, InputNumber, Row, Switch } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { useEffect } from 'react'
import { useCreatePercentChangeCustomerToPartner, usePercentChangeCustomerToPartnerQueryParams, usePercentChangeCustomerToPartners, useResetPercentChangeCustomerToPartner, useUpdatePercentChangeCustomerToPartner } from '~/hooks/PercentChangeCustomToPartner'
import SkeletonInput from 'antd/lib/skeleton/Input'
import { get, head } from 'lodash'
import { formatNumberThreeComma } from '~/hooks/utils'
import { PERCENT_CHANGE_STATE } from '~/constants/defaultValue'

export default function PercentChangeCustomerToPartner({canUpdate,canCreate}) {
    const [form] = Form.useForm()
    const [query] = usePercentChangeCustomerToPartnerQueryParams();
  const [percentChangeCustomerToPartners, isLoading] = usePercentChangeCustomerToPartners(query);
  const [loadingSubmit, createPercentChangeCustomerToPartner] = useCreatePercentChangeCustomerToPartner();
  const [, updatePercentChangeCustomerToPartner] = useUpdatePercentChangeCustomerToPartner();
  useResetPercentChangeCustomerToPartner();
  const isFirst = percentChangeCustomerToPartners?.length === 0 && percentChangeCustomerToPartners !== null
  const renderInput = InputComponent =>
    isLoading ? <SkeletonInput style={{width : '100px'}} active /> : InputComponent;
    const onFinish = (values) => {
        if(isFirst){
            createPercentChangeCustomerToPartner({...values,state : get(values, 'state') ? PERCENT_CHANGE_STATE.ACTIVE : PERCENT_CHANGE_STATE.INACTIVE});
        }
        else{
            updatePercentChangeCustomerToPartner({...values,_id : get(head(percentChangeCustomerToPartners),'_id'),state : get(values, 'state') ? PERCENT_CHANGE_STATE.ACTIVE : PERCENT_CHANGE_STATE.INACTIVE})
        }
    }
    useEffect(() => {
        const firstPercent = head(percentChangeCustomerToPartners)
        if(firstPercent){
          form.setFieldsValue({...firstPercent,state : get(firstPercent,'state') === PERCENT_CHANGE_STATE.ACTIVE})
        }
    },[percentChangeCustomerToPartners])
  return (
    <div className="page-content__main">
          <div className="page-content__right" style={{ width: '100%' }}>
            <Row align="middle">
              <Col span={8}>
                <Text strong>Tỉ lệ chuyển đổi tài khoản khách hàng thành đối tác</Text>
              </Col>
              <Form onFinish={onFinish} form={form} layout='inline'>
                <Form.Item name='percentChange' label="Phần trăm chuyển đổi">
                  {renderInput(<InputNumber disabled={!canUpdate} min={0} max={100}/>)}
                </Form.Item>
                <Form.Item name='minPointToChange' label="Điểm tối thiểu để đổi">
                    {renderInput(<InputNumber disabled={!canUpdate} formatter={value => formatNumberThreeComma(value)}  min={0}/>)}
                </Form.Item>
                <Form.Item valuePropName="checked" name='state' label="Trạng thái">
                  {renderInput(  <Switch  disabled={!canUpdate} />)}
                </Form.Item>
                {isFirst ? <Button disabled={!canCreate} loading={isLoading || loadingSubmit} htmlType='submit'>Tạo mới</Button> : <Button disabled={!canUpdate} loading={isLoading || loadingSubmit} htmlType='submit'>Cập nhật</Button>}
              </Form>
              <Col span={4}>
              </Col>
             
            </Row>
            
          </div>
        </div>
  )
}
