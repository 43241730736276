export const GET_EVALUATES_REQUEST = 'GET_EVALUATES_REQUEST';
export const GET_EVALUATES_SUCCESS = 'GET_EVALUATES_SUCCESS';
export const GET_EVALUATES_FAILED = 'GET_EVALUATES_FAILED';

export const GET_EVALUATE_REQUEST = 'GET_EVALUATE_REQUEST';
export const GET_EVALUATE_SUCCESS = 'GET_EVALUATE_SUCCESS';
export const GET_EVALUATE_FAILED = 'GET_EVALUATE_FAILED';

export const CREATE_EVALUATE_REQUEST = 'CREATE_EVALUATE_REQUEST';
export const CREATE_EVALUATE_SUCCESS = 'CREATE_EVALUATE_SUCCESS';
export const CREATE_EVALUATE_FAILED = 'CREATE_EVALUATE_FAILED';

export const UPDATE_EVALUATE_REQUEST = 'UPDATE_EVALUATE_REQUEST';
export const UPDATE_EVALUATE_SUCCESS = 'UPDATE_EVALUATE_SUCCESS';
export const UPDATE_EVALUATE_FAILED = 'UPDATE_EVALUATE_FAILED';

export const DELETE_EVALUATE_REQUEST = 'DELETE_EVALUATE_REQUEST';
export const DELETE_EVALUATE_SUCCESS = 'DELETE_EVALUATE_SUCCESS';
export const DELETE_EVALUATE_FAILED = 'DELETE_EVALUATE_FAILED';

export const RESET_EVALUATE_STATE = 'RESET_EVALUATE_STATE';
