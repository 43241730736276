import { Button, Form, Input, Row, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import { get, keys } from 'lodash';
import React, { useEffect } from 'react'
import { KEY_SYSTEM, KEY_SYSTEM_VI } from '~/constants/defaultValue';
import { useSystem } from '~/hooks/system';
import { renderLoading } from '~/utils/helper';
const rule = {
  rules: [
    {
      required: true,
      message: "Vui lòng nhập"
    }
  ]
};

const layout = {
  labelCol: {
    span: 8
  }
}
export default function SystemForm({ id, updateSystem, isSubmitLoading, onCancel }) {
  const [form] = Form.useForm();

  // Get Initialized System
  const [system, isLoading] = useSystem(id);

  // handleUpdate
  const onFinish = (values) => {
    const { value } = values;
    const submitData = {
      ...values,
      value: {
        ...get(system, 'value'),
        ...value,
      },
      id
    }
    updateSystem(submitData);
  }

  // Initialize Form
  useEffect(() => {
    if (system) {
      return form.setFieldsValue(system);
    }
    form.resetFields();
  }, [system, id]);
  return (
    <div>
      <h4 style={{ textAlign: 'center' }}>Cập nhật phiên bản</h4>
      <Form
        form={form}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item {...layout} label="Hệ điều hành" name={"key"} {...rule}>
          {renderLoading(<Select disabled options={keys(KEY_SYSTEM)?.map(k => ({ label: KEY_SYSTEM_VI[k], value: k }))} />, isLoading)}
        </Form.Item>
        <Form.Item {...layout} label="Tên phiên bản" name={"name"} {...rule}>
          {renderLoading(<Input bordered={false} readOnly placeholder='Tên phiên bản' />, isLoading)}
        </Form.Item>
        <Form.Item {...layout} label="Mô tả" name={"description"} {...rule}>
          {renderLoading(<TextArea placeholder='Mô tả' />, isLoading)}
        </Form.Item>
        <Form.Item {...layout} label="Phiên bản" name={["value", "version"]} rules={[
          {
            pattern: /^[0-9|.]+$/,
            message: 'Vui lòng chỉ nhập số và dấu chấm(.)',
          },
          {
            required: true,
            message: "Vui lòng nhập"
          }
        ]}>
          {renderLoading(<Input placeholder='Phiên bản' />, isLoading)}
        </Form.Item>
        <Row justify='center' align='center'>
          <Button onClick={onCancel}>
            Huỷ
          </Button>
          <Button loading={isSubmitLoading} htmlType='submit' type='primary'>
            Cập nhật
          </Button>
        </Row>
      </Form>
    </div>
  )
}
