import { EditFilled, EditOutlined, FilterOutlined, MailOutlined } from '@ant-design/icons';
import { Badge, Button, Col, DatePicker, Form, Input, Modal, Popconfirm, Popover, Row, Select, Switch, Table, Tag, Typography } from 'antd';
import { get, truncate } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import Breadcrumb from '~/components/Common/Breadcrumb';
import WithPermission from '~/components/Common/WithPermission';
import { STATUS_APPLY_JOB_OPTION, STATUS_APPLY_JOB_VI } from '~/constants/detaultValueJob';
import POLICY from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import {
  useDeleteApplyJob, useApplyJobPaging, useApplyJobQueryParams, useApplyJobs, useResetApplyJob, useUpdateApplyJob, useUpdateApplyJobParams
} from '~/hooks/applyJob';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
import FlexCenter from '../Common/FlexCenter';
import ListExpand from '../Common/ListExpand';
import TableStickyTop from '../Common/TableStickyTop';
import LinkFile from '../Common/TypeFile/LinkFileImage';
import FormNote from './FormNote';
import FormSendMail from './FormSendMail';
import HistoryMail from './HistoryMail';
import HistoryTimeLine from './HistoryTimeLine';
import SearchDraw from './SearchDraw';
const { Search } = Input;

const ApplyJob = ({ }) => {
  const [open, setOpen] = useState(false);

  const [openSendMail, setOpenSendMail] = useState(false);
  const [idJobPosting, setIdJobPosting] = useState();
  const [reFetch, setReFetch] = useState(false);
  const [query] = useApplyJobQueryParams(reFetch);
  const isSearching = useMemo(() => Object.keys(getExistProp(query))?.some((k) => [
    'keyword',
    'status',
    'startDate',
    'endDate',
    'managementArea',
    'category',
    'companyJobId']
    .includes(k)), [query]);
  console.log(isSearching, 'isSearching');
  const [ApplyJobs, isLoading] = useApplyJobs(query);
  const canUpdate = useMatchPolicy(POLICY.UPDATE_JOB)
  const [keyword, setKeyword, { onParamChange }] = useUpdateApplyJobParams(query);

  const [, deleteApplyJob] = useDeleteApplyJob();
  const [isSubmitLoading, updateApplyJob] = useUpdateApplyJob();
  const paging = useApplyJobPaging();
  useResetApplyJob();

  const onOpenSendMail = useCallback((id) => {
    setOpenSendMail(true);
    setIdJobPosting(id);
  }, []);

  const onCloseSendMail = useCallback(() => {
    setOpenSendMail(false);
    setIdJobPosting(null);
  }, []);

  const onOpenSearch = useCallback(() => {
    setOpen(true);
  }, []);

  const onCloseSearch = useCallback(() => {
    setOpen(false);
  }, []);

  const mutate = useCallback(() => setReFetch(!reFetch), [reFetch]);
  const columns = [
    {
      title: 'Tên ứng viên',
      dataIndex: 'name',
      key: 'name',
      fixed : 'left'
    },
    {
      title: 'Mã ứng viên',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: 120,
      render: (code) => <Typography.Paragraph copyable>{code}</Typography.Paragraph>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width : 200,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Vị trí',
      dataIndex: 'dataJob',
      key: 'dataJob',
      width: 150,
      align: 'center',
      render: (dataJob) => get(dataJob, 'jobVacancy.title', '')
    },
    {
      title: 'Công ty',
      dataIndex: 'dataJob',
      key: 'companyJob',
      align: 'center',
      render: (dataJob) => get(dataJob, 'companyJob.name', '')
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (status, rc) => <Select
        disabled={!canUpdate}
        listHeight={'auto'}
        dropdownMatchSelectWidth={150}
        value={status} onChange={(value) => {
          updateApplyJob({ _id: get(rc, '_id'), status: value })
        }}>
        {STATUS_APPLY_JOB_OPTION.map(({ label, value, color }) => <Select.Option value={value}><Badge status={color} />{label}</Select.Option>)}
      </Select>
    },
    {
      title: 'Khu vực',
      dataIndex: 'dataJob',
      key: 'city',
      width: '15%',
      render: (dataJob) => get(dataJob, 'city', [])?.map((name) => <Tag style={{ marginBottom: 2 }} key={name} color='processing'>{name}</Tag>)
    },
    {
      title: 'CV của ứng viên',
      dataIndex: 'cv',
      key: 'cv',
      width: 200,
      render: (cv) => (
        <ListExpand numberShow={3} dataSource={cv || []} itemRender={(item) => <li>
          <LinkFile
            key={v4()}
            href={get(item, 'response.url')}
            url={get(item, 'name')}
          />
        </li>} />
      )
    },
    {
      title: 'File ứng viên đính kèm',
      dataIndex: 'files',
      key: 'files',
      width: 200,
      render: (files, record, index) => (
        <ListExpand numberShow={3} dataSource={files} itemRender={(item) => <li>
          <LinkFile
            key={v4()}
            href={get(item, 'response.url')}
            url={get(item, 'name')}
          />
        </li>} />
      )
    },
    {
      title: 'Lịch sử trạng thái',
      dataIndex: 'history',
      key: 'history',
      align: 'center',
      width: 180,
      render: (history) => <Popover trigger={'click'} content={<HistoryTimeLine data={history} />}>
        <Typography.Link>Xem lịch sử</Typography.Link>
      </Popover>
    },
    {
      title: 'Mail đã gửi',
      dataIndex: 'historyEmail',
      key: 'historyEmail',
      align: 'center',
      width: 150,
      render: (historyEmail) => <Popover trigger={'click'} content={<HistoryMail data={historyEmail} />}>
        <Typography.Link>Mail đã gửi</Typography.Link>
      </Popover>
    },
    {
      title: 'Ngày Ứng tuyển',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 180,
      render: (createdAt) => <FlexCenter.Columns>
        <Typography.Text type='secondary'>{moment(createdAt).format("DD-MM-YYYY")}</Typography.Text>
        <Typography.Text type='secondary'>{moment(createdAt).format("HH:mm:ss")}</Typography.Text>
      </FlexCenter.Columns>
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      width: 200,
      render: (note, rc) =>
        <>
          <Typography.Paragraph style={{ marginBottom: 'unset' }} ellipsis={{
            rows: 3,
            tooltip: true
          }}>
            {note}
          </Typography.Paragraph>
          <WithPermission permission={POLICY.UPDATE_JOB}>
            <Popover trigger={'click'} content={<div style={{ width: 300 }}>
              <FormNote loading={isSubmitLoading} initNote={note} onUpdate={(values) => {
                updateApplyJob({ _id: get(rc, '_id'), ...values })
              }} />
            </div>}>
              <EditOutlined />
            </Popover>
          </WithPermission>
        </>
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      fixed: 'right',
      width: 200,
      render: (_id) => <Row align='middle' >
        <WithPermission permission={POLICY.DELETE_JOB}>
          <Col>
            <Popconfirm title="Xác nhận Xoá" onConfirm={() => deleteApplyJob(_id)}>
              <Button danger type='text'>Xoá</Button>
            </Popconfirm>
          </Col>
          <Col>
            |
          </Col>
        </WithPermission>
        <Col>
          <Button onClick={() => onOpenSendMail(_id)} type='text' icon={<MailOutlined />}>
            Gửi Email
          </Button>
        </Col>
      </Row>
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Danh sách ứng tuyển" />

        <div className="page-wraper__header">
          <Badge dot={isSearching}>
            <Button icon={<FilterOutlined />} type='primary' onClick={onOpenSearch}>Lọc</Button>
          </Badge>
        </div>
        <TableStickyTop 
            loading={isLoading}
            className="wh-category-table-striped-rows"
            rowKey={(rc) => rc._id || rc.id}
            columns={columns}
            dataSource={ApplyJobs}
            scroll={{ x: 2800 }}
            onChange={({ current, pageSize }) => onParamChange({ page: current, limit: pageSize })}
            pagination={{
              ...paging,
              showTotal: (total) => `Tổng cộng: ${total} `,
              showSizeChanger: true
            }}
            />

      </div>
      <Modal title="Gửi Email cho ứng viên" width={800} visible={openSendMail} onCancel={onCloseSendMail} footer={null} closable destroyOnClose centered>
        <FormSendMail id={idJobPosting} onCancel={() => {
          onCloseSendMail();
          mutate();
        }} />
      </Modal>
      <SearchDraw query={query} onParamChange={onParamChange} onCancel={onCloseSearch} open={open} />
    </div>
  );
};

export default ApplyJob;
