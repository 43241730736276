import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getIntroUsingRefCodes({ payload: query }) {
    try {
      const response = yield call(Api.introUsingRefCode.getAll, query);
      yield put({ type: Types.GET_INTRO_USING_REF_CODES_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_INTRO_USING_REF_CODES_FAILED, payload: error });
    }
  }
function* getIntroUsingRefCode({ payload: id }) {
    try {
      const response = yield call(Api.introUsingRefCode.getById, id);
      yield put({ type: Types.GET_INTRO_USING_REF_CODE_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_INTRO_USING_REF_CODE_FAILED, payload: error });
    }
  }
  function* createIntroUsingRefCode(action) {
    try {
      const data = yield call(Api.introUsingRefCode.create, action.payload);
      yield put({ type: Types.CREATE_INTRO_USING_REF_CODE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_INTRO_USING_REF_CODE_FAILED, payload: error });
    }
  }
  function* updateIntroUsingRefCode(action) {
    try {
      const data = yield call(Api.introUsingRefCode.update, action.payload);
      yield put({ type: Types.UPDATE_INTRO_USING_REF_CODE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_INTRO_USING_REF_CODE_FAILED, payload: error });
    }
  }
  function* deleteIntroUsingRefCode(action) {
    try {
      const data = yield call(Api.introUsingRefCode.delete, action.payload);
      yield put({ type: Types.DELETE_INTRO_USING_REF_CODE_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_INTRO_USING_REF_CODE_FAILED, payload: error.message });
    }
  }
export default function* IntroUsingRefCodes() {
    yield takeLatest(Types.GET_INTRO_USING_REF_CODES_REQUEST, getIntroUsingRefCodes);
    yield takeLatest(Types.GET_INTRO_USING_REF_CODE_REQUEST, getIntroUsingRefCode);
    yield takeLatest(Types.CREATE_INTRO_USING_REF_CODE_REQUEST, createIntroUsingRefCode);
    yield takeLatest(Types.UPDATE_INTRO_USING_REF_CODE_REQUEST, updateIntroUsingRefCode);
    yield takeLatest(Types.DELETE_INTRO_USING_REF_CODE_REQUEST, deleteIntroUsingRefCode);
  }
