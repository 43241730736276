import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Popconfirm, Table, Tag, Typography } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react'
import api from '~/api';
import { ToastrCustom } from '~/hooks/utils';

export default function CheckOutProduct({ whAppointmentCurrent,setwhAppointmentCurrent,setSelectedWhAppointment,selectedWhAppointment }) {
    const [isLoading,setIsLoading] = useState(false);
    const onCheckOut = useCallback(async (productId) => {
        try {
            setIsLoading(true);
            const submitData = {
                productId,
                whAppointmentId: get(whAppointmentCurrent, '_id'),
            };
            const res = await api.whAppointment.checkOutProduct(submitData);
            const listProduct = get(res,'data.listProduct',[]);
            const productFromResponse = listProduct?.find(product => get(product,'productId') === productId);
            const newQuantityUsed = get(productFromResponse,'quantityUsed',[]);

            const newListProductConfirm = get(whAppointmentCurrent,'listConfirmed',[])?.map((product) => get(product,'productId') === productId ? {
                ...product,
                quantityUsed : newQuantityUsed,
            } : product);
            console.log(newListProductConfirm,'newListProductConfirm');
            setwhAppointmentCurrent({
                ...whAppointmentCurrent,
                listConfirmed : newListProductConfirm,
            });
            setSelectedWhAppointment({
                ...selectedWhAppointment,
                listConfirmed : newListProductConfirm,
            })
            setIsLoading(false);
            ToastrCustom("Thành công");
        } catch (error) {
            setIsLoading(false);
            ToastrCustom(error?.response?.data?.message || "Có lỗi xảy ra vui lòng thử lại!", 'error');
        }
    }, [setwhAppointmentCurrent, whAppointmentCurrent,setSelectedWhAppointment,selectedWhAppointment]);
    const dataSource = useMemo(() => get(whAppointmentCurrent, 'listConfirmed', [])?.filter(item => !get(item, 'batchId')), [whAppointmentCurrent]);
    const columns = useMemo(() => [
        {
            title: "Tên công cụ",
            key: 'product',
            dataIndex: 'product',
            align: 'center',
            render: (product,rc) => <div>
                {get(product, 'name', '')}
                <p>(Đã SD: <Typography.Text code>{get(rc,'quantityUsed',[])?.length || 0}</Typography.Text> lần)</p>
                <p>(Yêu cầu SD: <Typography.Text code>{get(rc,'actualQuantity',0) || 0}</Typography.Text> lần)</p>
            </div>
        },
        {
            title: "Lịch sử sử dụng",
            key: 'quantityUsed',
            dataIndex: 'quantityUsed',
            render: (quantityUsed) => quantityUsed?.map(item => <Tag color='success'>{moment(get(item, 'date')).format("DD/MM/YYYY HH:mm")}</Tag>)
        },
        {
            title: "Thao tác",
            key: 'action',
            align: 'center',
            render: (item, record) =>
                <Popconfirm
                    title="Bạn muốn sử dụng ?"
                    onConfirm={() => onCheckOut(get(record, 'productId'))}
                    okText="OK"
                    cancelText="Huỷ"
                    zIndex={9999}
                >
                    <Button disabled={!get(whAppointmentCurrent,'checkIn.time')} size='small' className='d-flex align-items-center' loading={isLoading} type='primary' style={{ borderRadius: 5 }}>
                    <CheckCircleFilled />
                        Sử dụng
                    </Button>
                </Popconfirm>

        },

    ], [isLoading, onCheckOut]);

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            bordered
            rowKey={(record) => get(record, '_id')}
            size='small'
        />
    )
}
