import { getExistProp } from '~/utils/helper';
import requester from './requester';

const whReceiptVoucher = {
  create: whReceiptVoucher => requester.post('/wh-receipt-voucher', whReceiptVoucher),
  createByWhPartner: whReceiptVoucher => requester.post('/partner/wh-receipt-voucher', whReceiptVoucher),
  delete: id => requester.delete(`/wh-receipt-voucher/${id}`),
  getById: id => requester.get(`/wh-receipt-voucher/${id}`),
  getWhReceiptVouchers: query => requester.get('/wh-receipt-voucher?populate=true', query),
  update: whReceiptVoucher => requester.put(`/wh-receipt-voucher/${whReceiptVoucher.id}`, whReceiptVoucher),
  updateByWhPartner: whReceiptVoucher => requester.put(`/partner/wh-receipt-voucher/${whReceiptVoucher.id}`, whReceiptVoucher),
  upload: file => requester.postFormData(`/file/vouchers`, file),
  confirm: whReceiptVoucher => requester.put(`/wh-receipt-voucher/${whReceiptVoucher.id}/confirm`, whReceiptVoucher),
  approve: whReceiptVoucher => requester.put(`/wh-receipt-voucher/${whReceiptVoucher.id}/approved`, whReceiptVoucher),
  reject: whReceiptVoucher => requester.put(`/wh-receipt-voucher/${whReceiptVoucher.id}/reject`, whReceiptVoucher),
  getTotalMoney: (query) => requester.get('/wh-total-voucher', getExistProp(query))
};

export default whReceiptVoucher;
