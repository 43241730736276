
import { get } from "lodash";

const firebaseJson = '{"REACT_APP_FIREBASE_API_KEY": "AIzaSyBgx_qgU4AL2k3Ar50PX50FnwO693RAnXY", "REACT_APP_FIREBASE_AUTH_DOMAIN": "worldcare-dev.firebaseapp.com", "REACT_APP_FIREBASE_DATABASE_URL": "https://worldcare-dev-default-rtdb.asia-southeast1.firebasedatabase.app", "REACT_APP_FIREBASE_PROJECT_ID": "worldcare-dev", "REACT_APP_FIREBASE_STORAGE_BUCKET": "worldcare-dev.appspot.com", "REACT_APP_FIREBASE_MESSAGING_SENDER_ID": "248818858491", "REACT_APP_FIREBASE_APP_ID": "1:248818858491:web:505a7969cb194e586cf35e","REACT_APP_PUBLIC_VAPID_KEY":"BEzTVb8cdnBLuiKdisZbrJBARyA3xf2S3BySG-EjBvWElDemguJqmdim_1IzLPjE-0sYgS02pjIIqkR63cvfAwI"}';
let config = {};
// cheat the minifier
try {
  config = JSON.parse(firebaseJson);
} catch {}
  const firebaseConfig = {
    apiKey: get(config,'REACT_APP_FIREBASE_API_KEY','config.REACT_APP_FIREBASE_API_KEY') ,
    authDomain: get(config,'REACT_APP_FIREBASE_AUTH_DOMAIN','config.REACT_APP_FIREBASE_AUTH_DOMAIN') ,
    databaseURL:get(config,'REACT_APP_FIREBASE_DATABASE_URL','config.REACT_APP_FIREBASE_DATABASE_URL') ,
    projectId:get(config,'REACT_APP_FIREBASE_PROJECT_ID','config.REACT_APP_FIREBASE_PROJECT_ID'),
    storageBucket:get(config,'REACT_APP_FIREBASE_STORAGE_BUCKET','config.REACT_APP_FIREBASE_STORAGE_BUCKET'),
    messagingSenderId:get(config,'REACT_APP_FIREBASE_MESSAGING_SENDER_ID','config.REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
    appId:get(config,'REACT_APP_FIREBASE_APP_ID','config.REACT_APP_FIREBASE_APP_ID'),
  };
  export const vapidKeyFirebase = {
    vapidKey : get(config,'REACT_APP_PUBLIC_VAPID_KEY','config.REACT_APP_PUBLIC_VAPID_KEY'),
  }
export default firebaseConfig;

