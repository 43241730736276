export const GET_WH_PAYMENT_VOUCHERS_REQUEST = 'GET_WH_PAYMENT_VOUCHERS_REQUEST';
export const GET_WH_PAYMENT_VOUCHERS_SUCCESS = 'GET_WH_PAYMENT_VOUCHERS_SUCCESS';
export const GET_WH_PAYMENT_VOUCHERS_FAILED = 'GET_WH_PAYMENT_VOUCHERS_FAILED';

export const GET_WH_PAYMENT_VOUCHERS_CANCEL_REQUEST = 'GET_WH_PAYMENT_VOUCHERS_CANCEL_REQUEST';
export const GET_WH_PAYMENT_VOUCHERS_CANCEL_SUCCESS = 'GET_WH_PAYMENT_VOUCHERS_CANCEL_SUCCESS';
export const GET_WH_PAYMENT_VOUCHERS_CANCEL_FAILED = 'GET_WH_PAYMENT_VOUCHERS_CANCEL_FAILED';

export const DELETE_WH_PAYMENT_VOUCHER_REQUEST = 'DELETE_WH_PAYMENT_VOUCHER_REQUEST';
export const DELETE_WH_PAYMENT_VOUCHER_SUCCESS = 'DELETE_WH_PAYMENT_VOUCHER_SUCCESS';
export const DELETE_WH_PAYMENT_VOUCHER_FAILED = 'DELETE_WH_PAYMENT_VOUCHER_FAILED';

export const CREATE_WH_PAYMENT_VOUCHER_REQUEST = 'CREATE_WH_PAYMENT_VOUCHER_REQUEST';
export const CREATE_WH_PAYMENT_VOUCHER_SUCCESS = 'CREATE_WH_PAYMENT_VOUCHER_SUCCESS';
export const CREATE_WH_PAYMENT_VOUCHER_FAILED = 'CREATE_WH_PAYMENT_VOUCHER_FAILED';

export const CONFIRM_WH_PAYMENT_VOUCHER_REQUEST = 'CONFIRM_WH_PAYMENT_VOUCHER_REQUEST';
export const CONFIRM_WH_PAYMENT_VOUCHER_SUCCESS = 'CONFIRM_WH_PAYMENT_VOUCHER_SUCCESS';
export const CONFIRM_WH_PAYMENT_VOUCHER_FAILED = 'CONFIRM_WH_PAYMENT_VOUCHER_FAILED';

export const APPROVE_WH_PAYMENT_VOUCHER_REQUEST = 'APPROVE_WH_PAYMENT_VOUCHER_REQUEST';
export const APPROVE_WH_PAYMENT_VOUCHER_SUCCESS = 'APPROVE_WH_PAYMENT_VOUCHER_SUCCESS';
export const APPROVE_WH_PAYMENT_VOUCHER_FAILED = 'APPROVE_WH_PAYMENT_VOUCHER_FAILED';

export const REJECT_WH_PAYMENT_VOUCHER_REQUEST = 'REJECT_WH_PAYMENT_VOUCHER_REQUEST';
export const REJECT_WH_PAYMENT_VOUCHER_SUCCESS = 'REJECT_WH_PAYMENT_VOUCHER_SUCCESS';
export const REJECT_WH_PAYMENT_VOUCHER_FAILED = 'REJECT_WH_PAYMENT_VOUCHER_FAILED';

export const UPDATE_WH_PAYMENT_VOUCHER_REQUEST = 'UPDATE_WH_PAYMENT_VOUCHER_REQUEST';
export const UPDATE_WH_PAYMENT_VOUCHER_SUCCESS = 'UPDATE_WH_PAYMENT_VOUCHER_SUCCESS';
export const UPDATE_WH_PAYMENT_VOUCHER_FAILED = 'UPDATE_WH_PAYMENT_VOUCHER_FAILED';

export const GET_WH_PAYMENT_VOUCHER_REQUEST = 'GET_WH_PAYMENT_VOUCHER_REQUEST';
export const GET_WH_PAYMENT_VOUCHER_NOT_BILLID_REQUEST = 'GET_WH_PAYMENT_VOUCHER_NOT_BILLID_REQUEST';
export const GET_WH_PAYMENT_VOUCHER_SUCCESS = 'GET_WH_PAYMENT_VOUCHER_SUCCESS';
export const GET_WH_PAYMENT_VOUCHER_FAILED = 'GET_WH_PAYMENT_VOUCHER_FAILED';

export const RESET_WH_PAYMENT_VOUCHER_STATE = 'RESET_WH_PAYMENT_VOUCHER_STATE';
export const RESET_WH_PAYMENT_VOUCHER_STATE_ACTION = 'RESET_WH_PAYMENT_VOUCHER_STATE_ACTION';
