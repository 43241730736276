import { Button, Form, Input, Radio, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { LANGUAGE, requireRules } from '~/constants/defaultValue';
import { useCreateListSoftware, useListSoftware, useUpdateListSoftware } from '~/hooks/listSoftware';
import { handleSubmit } from '~/hooks/utils';
import { renderLoading } from '~/utils/helper';
import Editor from '../Utils/Editor';

export default function FormSoftware({ id, onCancel }) {
    const [form] = Form.useForm();
    const [isSubmitLoading, onCreate] = useCreateListSoftware(onCancel);
    const [, onUpdate] = useUpdateListSoftware(onCancel);
    const [software, loading] = useListSoftware(id);
    const [language, setLanguage] = useState(LANGUAGE.VI);
    const onSubmit = handleSubmit();
    const onFinish = useCallback((values) => {
        if (id) {
            onUpdate({
                ...values,
                _id: id
            });
        } else {
            onCreate(values)
        }
    }, [id]);
    useEffect(() => {
        if (id && software) {
            form.setFieldsValue({ ...software })
        }
    }, [software, id]);
    return (
        <Form labelCol={{ span: 8 }} form={form} onFinish={onFinish} onFinishFailed={onSubmit}>
            <Row justify="end" style={{ marginBottom: 20 }}>
                <Radio.Group
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                >
                    <Radio.Button
                        className="wh-service-form__select-langue-btn"
                        value={LANGUAGE.VI}
                    >
                        VI
                    </Radio.Button>
                    <Radio.Button
                        className="wh-service-form__select-langue-btn"
                        value={LANGUAGE.EN}
                    >
                        EN
                    </Radio.Button>
                </Radio.Group>
            </Row>
            <Form.Item rules={requireRules} hidden={language === LANGUAGE.EN} label="Tên phần mềm VI" name={["name", LANGUAGE.VI]}>
                {renderLoading(<Input />, loading)}
            </Form.Item>
            <Form.Item rules={requireRules} hidden={language === LANGUAGE.VI} label="Tên phần mềm EN" name={["name", LANGUAGE.EN]}>
                {renderLoading(<Input />, loading)}
            </Form.Item>
            <Form.Item rules={requireRules} hidden={language === LANGUAGE.EN} label="Mô tả VI" name={["description", LANGUAGE.VI]}>
                <Editor />
            </Form.Item>
            <Form.Item rules={requireRules} hidden={language === LANGUAGE.VI} label="Mô tả EN" name={["description", LANGUAGE.EN]}>
                <Editor />
            </Form.Item>

            <Row justify='end'>
                <Button loading={isSubmitLoading} htmlType='submit' type='primary'>
                    {id ? "Cập nhật" : "Tạo mới"}
                </Button>
            </Row>
        </Form>
    )
}
