export const GET_PRODUCT_CATALOGUES_REQUEST = 'GET_PRODUCT_CATALOGUES_REQUEST';
export const GET_PRODUCT_CATALOGUES_SUCCESS = 'GET_PRODUCT_CATALOGUES_SUCCESS';
export const GET_PRODUCT_CATALOGUES_FAILED = 'GET_PRODUCT_CATALOGUES_FAILED';

export const GET_PRODUCT_CATALOGUE_REQUEST = 'GET_PRODUCT_CATALOGUE_REQUEST';
export const GET_PRODUCT_CATALOGUE_SUCCESS = 'GET_PRODUCT_CATALOGUE_SUCCESS';
export const GET_PRODUCT_CATALOGUE_FAILED = 'GET_PRODUCT_CATALOGUE_FAILED';

export const GET_PRODUCT_CATALOGUE_STATUS_REQUEST = 'GET_PRODUCT_CATALOGUE_STATUS_REQUEST';
export const GET_PRODUCT_CATALOGUE_STATUS_SUCCESS = 'GET_PRODUCT_CATALOGUE_STATUS_SUCCESS';
export const GET_PRODUCT_CATALOGUE_STATUS_FAILED = 'GET_PRODUCT_CATALOGUE_STATUS_FAILED';

export const CREATE_PRODUCT_CATALOGUE_REQUEST = 'CREATE_PRODUCT_CATALOGUE_REQUEST';
export const CREATE_PRODUCT_CATALOGUE_SUCCESS = 'CREATE_PRODUCT_CATALOGUE_SUCCESS';
export const CREATE_PRODUCT_CATALOGUE_FAILED = 'CREATE_PRODUCT_CATALOGUE_FAILED';

export const UPDATE_PRODUCT_CATALOGUE_REQUEST = 'UPDATE_PRODUCT_CATALOGUE_REQUEST';
export const UPDATE_PRODUCT_CATALOGUE_SUCCESS = 'UPDATE_PRODUCT_CATALOGUE_SUCCESS';
export const UPDATE_PRODUCT_CATALOGUE_FAILED = 'UPDATE_PRODUCT_CATALOGUE_FAILED';

export const UPDATE_PRODUCT_CATALOGUE_STATUS_REQUEST = 'UPDATE_PRODUCT_CATALOGUE_STATUS_REQUEST';
export const UPDATE_PRODUCT_CATALOGUE_STATUS_SUCCESS = 'UPDATE_PRODUCT_CATALOGUE_STATUS_SUCCESS';
export const UPDATE_PRODUCT_CATALOGUE_STATUS_FAILED = 'UPDATE_PRODUCT_CATALOGUE_STATUS_FAILED';

export const DELETE_PRODUCT_CATALOGUE_REQUEST = 'DELETE_PRODUCT_CATALOGUE_REQUEST';
export const DELETE_PRODUCT_CATALOGUE_SUCCESS = 'DELETE_PRODUCT_CATALOGUE_SUCCESS';
export const DELETE_PRODUCT_CATALOGUE_FAILED = 'DELETE_PRODUCT_CATALOGUE_FAILED';

export const RESET_PRODUCT_CATALOGUE_STATE = 'RESET_PRODUCT_CATALOGUE_STATE';
export const RESET_PRODUCT_CATALOGUE_FORM_STATE = 'RESET_PRODUCT_CATALOGUE_FORM_STATE';
