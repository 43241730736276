export const GET_REQUEST_VOUCHERS_REQUEST =
  'GET_REQUEST_VOUCHERS_REQUEST';
export const GET_REQUEST_VOUCHERS_SUCCESS =
  'GET_REQUEST_VOUCHERS_SUCCESS';
export const GET_REQUEST_VOUCHERS_FAILED = 'GET_REQUEST_VOUCHERS_FAILED';

export const GET_REQUEST_VOUCHER_REQUEST = 'GET_REQUEST_VOUCHER_REQUEST';
export const GET_REQUEST_VOUCHER_SUCCESS = 'GET_REQUEST_VOUCHER_SUCCESS';
export const GET_REQUEST_VOUCHER_FAILED = 'GET_REQUEST_VOUCHER_FAILED';

export const CREATE_REQUEST_VOUCHER_REQUEST =
  'CREATE_REQUEST_VOUCHER_REQUEST';
export const CREATE_REQUEST_VOUCHER_SUCCESS =
  'CREATE_REQUEST_VOUCHER_SUCCESS';
export const CREATE_REQUEST_VOUCHER_FAILED =
  'CREATE_REQUEST_VOUCHER_FAILED';

export const UPDATE_REQUEST_VOUCHER_REQUEST =
  'UPDATE_REQUEST_VOUCHER_REQUEST';
export const UPDATE_REQUEST_VOUCHER_SUCCESS =
  'UPDATE_REQUEST_VOUCHER_SUCCESS';
export const UPDATE_REQUEST_VOUCHER_FAILED =
  'UPDATE_REQUEST_VOUCHER_FAILED';

export const DELETE_REQUEST_VOUCHER_REQUEST =
  'DELETE_REQUEST_VOUCHER_REQUEST';
export const DELETE_REQUEST_VOUCHER_SUCCESS =
  'DELETE_REQUEST_VOUCHER_SUCCESS';
export const DELETE_REQUEST_VOUCHER_FAILED =
  'DELETE_REQUEST_VOUCHER_FAILED';


export const RESET_REQUEST_VOUCHER_STATE = 'RESET_REQUEST_VOUCHER_STATE';
