import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, Row, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { COMPONENT_MODES, WH_PARTNER_WAY_WORKING } from '~/constants/defaultValue';
import { useBonusIntroCustomers,useCreateBonusIntroCustomer, useUpdateBonusIntroCustomer } from '~/hooks/bonusIntroCustomer';
import { formatNumberThreeComma } from '~/hooks/utils';
import { useReportIntroCustomerById } from '~/hooks/whReport';
import { setEndofDate } from '~/utils/helper';
import TableDetail from './TableDetail';

export default function TableIntroCustomer({type,mode,date,id,setTotalDetailAll,keyTotal}) {
  const [data,isLoading] = useReportIntroCustomerById(id,date)
  const [bonus,setBonus] = useState(0)
  const [reFetch,setRefetch] = useState(false)
  const [percentIntroIncome,setPercentIntroIncome] = useState(0)
  const [isSubmitLoadng,createBonusIntroCustomer] = useCreateBonusIntroCustomer(() => setRefetch(!reFetch))
  const [,updateBonusIntroCustomer] = useUpdateBonusIntroCustomer(() => setRefetch(!reFetch))
  const [bonusIntroCustomerOne,setBonusIntroCustomerOne] = useState(null)
  const query = useMemo(() => ({partnerId : id,...date,endDate : setEndofDate(get(date,'endDate'))}),[id,date,reFetch])
  const [bonusIntroCustomer,loading] = useBonusIntroCustomers(query)
  const newBonusIntroCustomer = useMemo(() => {
    
    const timeNow = moment();
const startDate = moment(get(date, 'startDate')).startOf('day');
const endDate = moment(get(date, 'endDate')).startOf('day');
    if(timeNow.isBetween(startDate, endDate, null, '[]')){
      if(bonusIntroCustomer?.length === 0){ // filter in now and Not have bounus
        return [...bonusIntroCustomer,{
          partnerId : id,
          bonus : 0,
          percentIntroIncome : 0,
          day : moment().format("YYYY-MM-DD")
        }]
      }
      const findBonusNow = bonusIntroCustomer?.some(e => moment(get(e,'day')).month() === moment(timeNow).month())
      if(!findBonusNow){
        return [...bonusIntroCustomer,{ // filter in list bonusIntroCustomer and not have bonus now
          partnerId : id,
          bonus : 0,
          percentIntroIncome : 0,
          day : moment().format("YYYY-MM-DD")
        }]
      }
    }
    return bonusIntroCustomer
    // const checkDateFilterIsHaveNow = 
  },[bonusIntroCustomer,date,id])
  const mergeDataSourceWithBonus = useMemo(() => {
    const newData = data?.map(e => {
      const findData = newBonusIntroCustomer?.find(key => {
        return moment(get(e,'date')).isBetween(moment(get(key,'day')).startOf('month'),moment(get(key,'day')).endOf('month'),null,'[]') 
      })
      if(findData) {
        return {...e,dataBonus : findData,_id : v4()}
      }
      else{
        return {...e,_id : v4()}
      }
    })
    return newData
  },[data,newBonusIntroCustomer])
  const handleCreateorUpdate = () => {
    if(bonusIntroCustomerOne){
      updateBonusIntroCustomer({bonus,percentIntroIncome,_id:get(bonusIntroCustomerOne,'_id')})
    }
    else{
      createBonusIntroCustomer({bonus,percentIntroIncome,partnerId : id,day : moment().format("YYYY-MM-DD")})
    }
  }
  useEffect(() => {
    const findMonth = bonusIntroCustomer?.find(e => moment().isBetween(moment(get(e,'day')).startOf('month'),moment(get(e,'day')).endOf('month'),null,'[]') )
    if(findMonth){
      setBonusIntroCustomerOne(findMonth)
      setPercentIntroIncome(get(findMonth,'percentIntroIncome',0))
      setBonus(get(findMonth,'bonus',0))
    }else{
      setBonusIntroCustomerOne(null)
      setPercentIntroIncome(null)
      setBonus(null)
    }
  },[bonusIntroCustomer])
  useEffect(() => {
    const sumTotalAmount = mergeDataSourceWithBonus?.reduce((sum,curr) => {
      return sum + get(curr,'listCustomer.length',0) * get(curr,'dataBonus.bonus',0) +  get(curr,'totalSum',0) * get(curr,'dataBonus.percentIntroIncome',0) / 100
    },0)
    setTotalDetailAll(keyTotal,sumTotalAmount || 0)
  },[mergeDataSourceWithBonus,keyTotal])
  const columns = [
    {
      title: 'Tháng',
      key: 'date',
      dataIndex: 'date',
      align: 'center',
      render: (item, record,index) => `${moment(item).format("MM")}`
    },
    {
      title: 'Số lượng KH phát triển',
      key: 'listCustomer',
      dataIndex: 'listCustomer',
      align: 'center',
      render: (item, record) => get(item,'length',0)
    },
    {
      title: 'Thưởng',
      key: 'dataBonus',
      dataIndex: 'dataBonus',
      align: 'center',
      width: 150,
      render: (item, record) => moment().isBetween(moment(get(record,'date')).startOf('month'),moment(get(record,'date')).endOf('month'),null,'[]')  ? <InputNumber onPressEnter={handleCreateorUpdate} value={bonus} onChange={(value) => setBonus(value)} disabled={mode === COMPONENT_MODES.VIEW} formatter={(value) => formatNumberThreeComma(value)} /> : <Text strong>{get(item,'bonus',0)}</Text>
    },
    {
      title: 'Doanh thu KH trong tháng',
      key: 'totalSum',
      dataIndex: 'totalSum',
      align: 'center',
      render: (item, record) => <Text strong>{formatNumberThreeComma(item)}</Text>
    },
    {
      title: '% thu nhập',
      key: 'dataBonus',
      dataIndex: 'dataBonus',
      align: 'center',
      width: 150,
      render: (item, record) => moment().isBetween(moment(get(record,'date')).startOf('month'),moment(get(record,'date')).endOf('month'),null,'[]') ?  <InputNumber onPressEnter={handleCreateorUpdate} value={percentIntroIncome} onChange={(value) => setPercentIntroIncome(value)} disabled={mode === COMPONENT_MODES.VIEW} formatter={(value) => formatNumberThreeComma(value)}  /> : formatNumberThreeComma(get(item,'percentIntroIncome',0))
    },
    {
      title: 'Thành tiền',
      key: 'totalSum',
      align: 'center',
      render: (item, record) => <span><Text strong>{formatNumberThreeComma(get(record,'listCustomer.length',0) * get(record,'dataBonus.bonus',0) + get(record,'totalSum',0) * get(record,'dataBonus.percentIntroIncome',0) / 100)}</Text></span>
    }
  ];
  const summaryFooter = (current) => {
    const totalSum = current?.reduce((sum,curr) => get(curr,'totalSum',0),0)
    const totalAmount = current?.reduce((sum,curr) => {
      const total = get(curr,'listCustomer.length',0) * get(curr,'dataBonus.bonus',0) + get(curr,'totalSum',0) * get(curr,'dataBonus.percentIntroIncome',0) / 100
      return sum + total
    },0)
    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell  align="center"></Table.Summary.Cell>
          <Table.Summary.Cell  align="center"></Table.Summary.Cell>
          <Table.Summary.Cell  align="center"></Table.Summary.Cell>
          <Table.Summary.Cell  align="center"></Table.Summary.Cell>
            <Table.Summary.Cell  align="center">{formatNumberThreeComma(totalSum)}</Table.Summary.Cell>
          <Table.Summary.Cell  align="center"></Table.Summary.Cell>
          <Table.Summary.Cell align="center">{formatNumberThreeComma(totalAmount)}</Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    )
  };
    const columnExpan = [
      {
        title: 'STT',
        key: 'null',
        width: 50,
        align : 'center',
        render: (item,record,index) => (
          <span>
            {index + 1}
          </span>
        )
      },
      {
        title: 'Tên',
        key: 'fullName',
        dataIndex: 'fullName',
        align : 'center',
        render: (item,record,index) => (
          <span>
            {item || ''}
          </span>
        )
      },
      {
        title: 'Thời gian giới thiệu',
        key: 'createdAt',
        dataIndex: 'createdAt',
        align : 'center',
        render: (item,record,index) => (
          <span>
            {moment(item).format("DD-MM-YYYY") || ''}
          </span>
        )
      },
      {
        title: 'Doanh thu',
        key: 'totalAmount',
        dataIndex: 'totalAmount',
        align : 'center',
        render: (item,record,index) => {
          return (
            <span>
              {formatNumberThreeComma(item)}
            </span>
          )
        }
      },
    ]
  const ExpandedRowRender = (record) =>{
    return ( <Table
      columns={columnExpan}
      dataSource={get(record,'listCustomer',[])}
      pagination={false}
    />)
  }
  return (
    <div>
      <TableDetail
      ExpandedRowRender={ExpandedRowRender}
      isRowExpandable
      scrollWidth={1000}
        pagination={false}
        dataSource={mergeDataSourceWithBonus}
        columns={columns}
        title={'Thu nhập theo giới thiệu khách hàng (E)'}
        footer={summaryFooter}
      />
    </div>
  );
}
