import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getCustomersType({payload: query}) {
  try {
    const data = yield call(Api.customerType.getAll,query);
    yield put({ type: Types.GET_CUSTOMERS_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_CUSTOMERS_TYPE_FAILED,
      payload: error.message
    });
  }
}

function* getCustomerType({ payload: id }) {
  try {
    const customerType = yield call(Api.customerType.getById, id);
    console.log("customerType");
    yield put({ type: Types.GET_CUSTOMER_TYPE_SUCCESS, payload: customerType });
  } catch (error) {
    yield put({ type: Types.GET_CUSTOMER_TYPE_FAILED, payload: error.message });
  }
}

function* createCustomerType(action) {
  try {
    const data = yield call(Api.customerType.create, action.payload);
    yield put({ type: Types.CREATE_CUSTOMER_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_CUSTOMER_TYPE_FAILED,
      payload: error.message
    });
  }
}

function* updateCustomerType(action) {
  try {
    const data = yield call(Api.customerType.update, action.payload);
    yield put({ type: Types.UPDATE_CUSTOMER_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_CUSTOMER_TYPE_FAILED,
      payload: error.message
    });
  }
}

function* deleteCustomerType({ payload }) {
  try {
    yield call(Api.customerType.delete, payload);
    yield put({ type: Types.DELETE_CUSTOMER_TYPE_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_CUSTOMER_TYPE_FAILED,
      payload: error.message
    });
  }
}

export default function* customerType() {
  yield takeLatest(Types.GET_CUSTOMERS_TYPE_REQUEST, getCustomersType);
  yield takeLatest(Types.GET_CUSTOMER_TYPE_REQUEST, getCustomerType);
  yield takeLatest(Types.DELETE_CUSTOMER_TYPE_REQUEST, deleteCustomerType);
  yield takeLatest(Types.CREATE_CUSTOMER_TYPE_REQUEST, createCustomerType);
  yield takeLatest(Types.UPDATE_CUSTOMER_TYPE_REQUEST, updateCustomerType);
}
