import { get, last } from "lodash"
import { useEffect, useState } from "react"
import api from "~/api"
import { MAX_PAGINATION_LIMIT } from "~/constants/defaultValue"

export function useGetMyRanking(id) {
    const [data,setData] = useState(null)
    const [loading,setLoading] = useState(false)
    useEffect(() => {
      const fetch = async () => {
        setLoading(true)
        const myRanking = await api.memberRanking.getById(id)
        const allRankings = await api.ranking.getAll({page : 1, limit : MAX_PAGINATION_LIMIT,sortPoint:1})
        const index = get(allRankings,'docs',[]).findIndex(d => get(d,'startPoint') <= get(myRanking,'point') && get(d,'endPoint') >= get(myRanking,'point'))
        if(index === -1){
          let listLower = get(allRankings,'docs',[])?.filter(e => get(e,'endPoint') <= get(myRanking,'point'))
          const myRank = last(listLower)
          setData({...myRank,point:get(myRanking,'point',0)})
          setLoading(false)
        }else{
          setData({...get(allRankings,'docs',[])[index],point:get(myRanking,'point',0)})
          setLoading(false)
        }
        
      }
      fetch()
    },[id])
    return {
      data,
      isLoading: loading,
    }
}
