
import { Button, Form, Input, Radio, Row, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { LANGUAGE, requireRules } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useUIs, useUpdateUI } from '~/hooks/UI';
import { handleSubmit } from '~/hooks/utils';
import FormItemYoutube from '../Banner/FormItemYoutube';
import { Breadcrumb, WithOrPermission } from '../Common';
import LoadingWhBill from '../WhBill/LoadingWhBill';
import AutoGetCodeYoutube from './AutoGetCodeYoutube';
import HowToGetYoutubeId from './HowToGetYoutubeId';
const AddYoutube = () => {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_BANNER);
    const [form] = Form.useForm();
    const [UI, isLoading] = useUIs('homepageYoutube');
    const [isSubmitLoading, update] = useUpdateUI();
    const [language, setLanguage] = useState(LANGUAGE.VI);
    const onSubmit = handleSubmit();
    const onFinish = (values) => {
        update({ key: 'homepageYoutube', ...values })
    };

    useEffect(() => {
        form.setFieldsValue({
            ...get(UI, 'UI.homepageYoutube')
        })
    }, [UI]);

    return (
        <div className="wh-service-form page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb title={'Ấn phẩm WorldCareVn'} routes={[{ path: '', title: 'CMS' }]} />
                {isLoading && <LoadingWhBill open={true} />}
                <Form
                    form={form}
                    autoComplete="off"
                    initialValues={{}}
                    scrollToFirstError
                    requiredMark={false}
                    onFinish={onFinish}
                    onFinishFailed={onSubmit}
                    labelCol={{ sm: 24, md: 24, lg: 6 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 18 }}
                >
                    <Row justify="end" style={{ marginBottom: 20 }}>
                        <Radio.Group
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.VI}
                            >
                                VI
                            </Radio.Button>
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.EN}
                            >
                                EN
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                    <Form.Item label="Hiển thị" valuePropName="checked" name={['active']} >
                        <Switch disabled={!canUpdate} />
                    </Form.Item>
                    <Form.Item name={["title", LANGUAGE.VI]} hidden={language === LANGUAGE.EN} label="Tiêu đề VI">
                        <Input disabled={!canUpdate} placeholder='Nhập tiêu đề' />
                    </Form.Item>
                    <Form.Item name={["title", LANGUAGE.EN]} hidden={language === LANGUAGE.VI} label="Tiêu đề EN">
                        <Input disabled={!canUpdate} placeholder='Nhập tiêu đề' />
                    </Form.Item>
                    <Form.Item name={["content", LANGUAGE.VI]} hidden={language === LANGUAGE.EN} label="Mô tả VI">
                        <TextArea disabled={!canUpdate} rows={3} placeholder='Mô tả' />
                    </Form.Item>
                    <Form.Item name={["content", LANGUAGE.EN]} hidden={language === LANGUAGE.VI} label="Mô tả EN">
                        <TextArea disabled={!canUpdate} rows={3} placeholder='Mô tả' />
                    </Form.Item>
                    <Form.Item name={["address"]} label="Địa chỉ">
                        <Input disabled={!canUpdate} placeholder='Địa chỉ' />
                    </Form.Item>
                    <Form.Item name={["hotline"]} label="Hot Line">
                        <Input disabled={!canUpdate} placeholder='Hot Line' />
                    </Form.Item>
                    <Form.Item name={["mail"]} label="Mail">
                        <Input disabled={!canUpdate} placeholder='Mail' />
                    </Form.Item>
                    <FormItemYoutube disabled={!canUpdate} />
                    <HowToGetYoutubeId />
                    <Row className="wh-service-form__submit-box">
                        <WithOrPermission permission={[POLICIES.UPDATE_BANNER, POLICIES.WRITE_BANNER]}>
                            <Button
                                loading={isSubmitLoading}
                                type="primary"
                                htmlType="submit"
                            >
                                Cập nhật
                            </Button>
                        </WithOrPermission>
                    </Row>
                </Form>
            </div>
        </div>
    );
};
export default AddYoutube;
