import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getExchangePoints({ payload: query }) {
    try {
      const response = yield call(Api.exchangePoint.getAll, query);
      yield put({ type: Types.GET_EXCHANGEPOINTS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_EXCHANGEPOINTS_FAILED, payload: error });
    }
  }
function* getExchangePoint({ payload: id }) {
    try {
      const response = yield call(Api.exchangePoint.getById, id);
      yield put({ type: Types.GET_EXCHANGEPOINT_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_EXCHANGEPOINT_FAILED, payload: error });
    }
  }
  function* createExchangePoint(action) {
    try {
      const data = yield call(Api.exchangePoint.create, action.payload);
      yield put({ type: Types.CREATE_EXCHANGEPOINT_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_EXCHANGEPOINT_FAILED, payload: error });
    }
  }
  function* updateExchangePoint(action) {
    try {
      const data = yield call(Api.exchangePoint.update, action.payload);
      yield put({ type: Types.UPDATE_EXCHANGEPOINT_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_EXCHANGEPOINT_FAILED, payload: error });
    }
  }
export default function* ExchangePoints() {
    yield takeLatest(Types.GET_EXCHANGEPOINTS_REQUEST, getExchangePoints);
    yield takeLatest(Types.GET_EXCHANGEPOINT_REQUEST, getExchangePoint);
    yield takeLatest(Types.CREATE_EXCHANGEPOINT_REQUEST, createExchangePoint);
    yield takeLatest(Types.UPDATE_EXCHANGEPOINT_REQUEST, updateExchangePoint);
  }
