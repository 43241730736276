import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '~/api';
import {
  getSelectors,
  useBranchIdSessionStorage,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import {
  createRequestVoucher,
  deleteRequestVoucher,
  getRequestVoucher,
  getRequestVouchers,
  resetRequestVoucherState,
  updateRequestVoucher
} from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.requestVoucher[key];
const pagingSelector = getSelector('paging');
export const useRequestVoucherPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetRequestVoucherSelector = getSelector('isGetRequestVoucherLoading');
const getRequestVouchersFailedSelector = getSelector('getRequestVouchersFailed');
const getRequestVoucherFailedSelector = getSelector('getRequestVoucherFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const RequestVouchersSelector = getSelector('RequestVouchers');
const RequestVoucherSelector = getSelector('RequestVoucher');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');
export const useRequestVoucherQueryParams = (userId,reFetch) => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const name = query.get('name');
  const requestNumber = query.get('requestNumber');
  const status = query.get('status');
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const createRequestVoucherSuccess = useSelector(createSuccessSelector);
  const updateRequestVoucherSuccess = useSelector(updateSuccessSelector);
  const deleteRequestVoucherSuccess = useSelector(deleteSuccessSelector);
  const [branchId] = useBranchIdSessionStorage();

  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      name,
      userId,
      status,
      requestNumber,
      startDate,
      endDate
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    createRequestVoucherSuccess,
    updateRequestVoucherSuccess,
    deleteRequestVoucherSuccess,
    name,
    userId,
    reFetch,
    status,
    requestNumber,
    startDate,
    endDate,
    branchId
  ]);
};

export const useUpdateRequestVoucherParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query, 'keyword'));
  useEffect(() => {
    setKeyword(get(query, 'keyword'));
  }, [query.keyword]);
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }
    history.push({
      pathname: get(param, 'customPathName') || PATH_APP.referral.RequestVoucher,
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};
export const useRequestVouchers = (query) => {
  return useFetchByParam({
    action: getRequestVouchers,
    loadingSelector,
    dataSelector: RequestVouchersSelector,
    failedSelector: getRequestVouchersFailedSelector,
    param: query
  });
};
export const useRequestVoucher = (id) => {
  return useFetchByParam({
    action: getRequestVoucher,
    loadingSelector: loadinggetRequestVoucherSelector,
    dataSelector: RequestVoucherSelector,
    failedSelector: getRequestVoucherFailedSelector,
    param: id
  });
};
// export const useCreateRequestVoucher = (callback) => {
//   useSuccess(createSuccessSelector, 'Tạo Phân loại thành công', callback);
//   useFailed(createFailedSelector);

//   return useSubmit({
//     loadingSelector: isSubmitLoadingSelector,
//     action: createRequestVoucher
//   });
// };
export const useUpdateRequestVoucher = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật Phân loại thành công', callback);
  useFailed(updateFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateRequestVoucher
  });
};
export const useDeleteRequestVoucher = (callback) => {
  useSuccess(deleteSuccessSelector, 'Xoá Phân loại thành công', callback);
  useFailed(deleteFailedSelector);

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: deleteRequestVoucher
  });
};

export const useResetRequestVoucher = () => {
  useResetState(resetRequestVoucherState);
};
export const useRequestVoucherParnter = (userId,reFetch) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const res = await api.requestVoucher.getAll({userId})
        setData(get(res,'sumAmount.totalAmountNew',0));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetch();
  }, [userId,reFetch]);
  return [data, loading];
};
