import { put, call, takeLatest } from 'redux-saga/effects';
import * as Types from '../../constants/actionTypes';
import api from '../../api';

function* getServicesByLocation({ payload: query }) {
  try {
    const data = yield call(api.serviceByLocation.getAll, query);
    yield put({ type: Types.GET_SERVICES_BY_LOCATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_SERVICES_BY_LOCATION_FAILED,
      payload: error.message
    });
  }
}

function* getServiceByLocation({ payload: query }) {
  try {
    const data = yield call(api.serviceByLocation.getById, query);
    yield put({ type: Types.GET_SERVICE_BY_LOCATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_SERVICE_BY_LOCATION_FAILED,
      payload: error.message
    });
  }
}

function* createServiceByLocation( action ) {
  try {
    const data = yield call(api.serviceByLocation.create, action.payload);
    yield put({ type: Types.CREATE_SERVICE_BY_LOCATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_SERVICE_BY_LOCATION_FAILED,
      payload: error.message
    });
  }
}

function* updateServiceByLocation( action ) {
  try {
    const data = yield call(api.serviceByLocation.update, action.payload);
    yield put({ type: Types.UPDATE_SERVICE_BY_LOCATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_SERVICE_BY_LOCATION_FAILED,
      payload: error.message
    });
  }
}
function* deleteServiceByLocation(action) {
  try {
    const { locationIndex, serviceId } = action.payload;
    const data = yield call(api.serviceByLocation.delete, {
      locationIndex: locationIndex || undefined,
      serviceId: serviceId || undefined,
    });
    yield put({ type: Types.DELETE_SERVICE_BY_LOCATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.DELETE_SERVICE_BY_LOCATION_FAILED,
      payload: error.message,
    });
  }
}


export default function* service() {
  yield takeLatest(Types.GET_SERVICES_BY_LOCATION_REQUEST, getServicesByLocation);
  yield takeLatest(Types.GET_SERVICE_BY_LOCATION_REQUEST, getServiceByLocation);
  yield takeLatest(Types.CREATE_SERVICE_BY_LOCATION_REQUEST, createServiceByLocation);
  yield takeLatest(Types.UPDATE_SERVICE_BY_LOCATION_REQUEST, updateServiceByLocation);
  yield takeLatest(Types.DELETE_SERVICE_BY_LOCATION_REQUEST, deleteServiceByLocation);
}
