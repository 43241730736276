
import { useMemo, useRef, useState } from 'react';
import {
    useBranchIdSessionStorage,
    useQueryParams
} from '~/hooks/utils';


import { isString } from 'lodash';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const WORK_LIST = 'workList';


export const useWorkListQueryParams = (sprintId) => {
  
    const prevKeyword = useRef(null);
    const [branchId] = useBranchIdSessionStorage();
    const query = useQueryParams();
    const keyword = query.get('keyword');
    const code = query.get('code');
    const name = query.get('name');
    const createdAt = query.get('createdAt');
    const taskId = query.get('taskId')
    const statusId = query.get("statusId");
    const assignUser = query.get("assignUser");
    const startDate = query.get("startDate");
    const endDate = query.get("endDate");


     
      return useMemo(() => {
        const queryParams = getExistProp({
          keyword,
          statusId,
          code,
          createdAt,
          taskId,
          keyword,
          name,
          branchId,
          assignUser,
          startDate,
          endDate,
          sprintId
        });
        return [queryParams];
      }, [keyword, 
        branchId, 
        statusId, 
        code, 
        name, 
        taskId, 
        createdAt,
        assignUser,
        startDate,
        endDate,
        sprintId]);
     
}

export const useUpdateWorkListParams = (query) => {

    const history = useHistory();
    const [keyword, setKeyword] = useState(query?.keyword);
    const onParamChange = (param) => {
      console.log(param);
       for (const key in param) {
          param[key]=  isString(param[key]) ? (param[key].trim()).replace(/[.]/g,'') : param[key]
       }
      history.push({
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword, { setKeyword, onParamChange }];
  };
