import { Select } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useFetchWhPartner } from '~/hooks';
import { StringToSlug } from '~/utils/helper';

export default function SelectPartner({ onChange, value }) {
    const [data, loading] = useFetchWhPartner();
    const options = useMemo(
        () =>
            data?.map((item) => ({
                label: 'Đối tác: ' + get(item, 'name'),
                value: get(item, 'phone')
            })),
        [data]
    );
    const filterOptions = useCallback((input, option) => {
        const labelSlug = StringToSlug(get(option, 'label', '')?.toLowerCase());
        const valueSlug = StringToSlug(get(option, 'value', '')?.toLowerCase());
        const inputSlug = StringToSlug(input?.toLowerCase());
        return labelSlug?.includes(inputSlug) || valueSlug?.includes(inputSlug);
    }, [])
    return (
        <Select
            value={value}
            onChange={onChange}
            style={{ minWidth: 250 }}
            options={options}
            loading={loading}
            showSearch
            filterOption={filterOptions}
        />
    );
}
