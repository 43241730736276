import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getClinicOverViewReport() {
  try {
    const data = yield call(Api.clinicDashboard.getOverViewReport);
    yield put({
      type: Types.GET_CLINIC_OVER_VIEW_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CLINIC_OVER_VIEW_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getClinicYearlyReport() {
  try {
    const data = yield call(Api.clinicDashboard.getYearlyReport);
    yield put({
      type: Types.GET_CLINIC_YEARLY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CLINIC_YEARLY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getClinicQuarterlyReport() {
  try {
    const data = yield call(Api.clinicDashboard.getQuarterlyReport);
    yield put({
      type: Types.GET_CLINIC_QUARTERLY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CLINIC_QUARTERLY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getClinicMonthlyReport() {
  try {
    const data = yield call(Api.clinicDashboard.getMonthlyReport);
    yield put({
      type: Types.GET_CLINIC_MONTHLY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CLINIC_MONTHLY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getClinicDailyReport() {
  try {
    const data = yield call(Api.clinicDashboard.getDailyReport);
    yield put({
      type: Types.GET_CLINIC_DAILY_REPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CLINIC_DAILY_REPORT_FAILED,
      payload: error.message
    });
  }
}

function* getMostDaily() {
  try {
    const data = yield call(Api.clinicDashboard.getMostDaily);
    yield put({
      type: Types.GET_MOST_DAILY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MOST_DAILY_FAILED,
      payload: error.message
    });
  }
}

function* getMostMonthly() {
  try {
    const data = yield call(Api.clinicDashboard.getMostMonthly);
    yield put({
      type: Types.GET_MOST_MONTHLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MOST_MONTHLY_FAILED,
      payload: error.message
    });
  }
}

function* getMostQuarterly() {
  try {
    const data = yield call(Api.clinicDashboard.getMostQuarterly);
    yield put({
      type: Types.GET_MOST_QUARTERLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MOST_QUARTERLY_FAILED,
      payload: error.message
    });
  }
}

function* getMostYearly() {
  try {
    const data = yield call(Api.clinicDashboard.getMostYearly);
    yield put({
      type: Types.GET_MOST_YEARLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_MOST_YEARLY_FAILED,
      payload: error.message
    });
  }
}

function* getClinicOverviewToday() {
  try {
    const data = yield call(Api.clinicDashboard.getClinicOverviewToday);
    yield put({
      type: Types.GET_CLINIC_OVERVIEW_TODAY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CLINIC_OVERVIEW_TODAY_FAILED,
      payload: error.message
    });
  }
}

function* getClinicOverviewDaily() {
  try {
    const data = yield call(Api.clinicDashboard.getClinicOverviewDaily);
    yield put({
      type: Types.GET_CLINIC_OVERVIEW_DAILY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CLINIC_OVERVIEW_DAILY_FAILED,
      payload: error.message
    });
  }
}

function* getClinicOverviewMonthly() {
  try {
    const data = yield call(Api.clinicDashboard.getClinicOverviewMonthly);
    yield put({
      type: Types.GET_CLINIC_OVERVIEW_MONTHLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CLINIC_OVERVIEW_MONTHLY_FAILED,
      payload: error.message
    });
  }
}

function* getClinicOverviewQuarterly() {
  try {
    const data = yield call(Api.clinicDashboard.getClinicOverviewQuarterly);
    yield put({
      type: Types.GET_CLINIC_OVERVIEW_QUARTERLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CLINIC_OVERVIEW_QUARTERLY_FAILED,
      payload: error.message
    });
  }
}

function* getClinicOverviewYearly() {
  try {
    const data = yield call(Api.clinicDashboard.getClinicOverviewYearly);
    yield put({
      type: Types.GET_CLINIC_OVERVIEW_YEARLY_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: Types.GET_CLINIC_OVERVIEW_YEARLY_FAILED,
      payload: error.message
    });
  }
}

export default function* coupon() {
  yield takeLatest(Types.GET_CLINIC_OVER_VIEW_REPORT_REQUEST, getClinicOverViewReport);
  yield takeLatest(Types.GET_CLINIC_YEARLY_REPORT_REQUEST, getClinicYearlyReport);
  yield takeLatest(Types.GET_CLINIC_QUARTERLY_REPORT_REQUEST, getClinicQuarterlyReport);
  yield takeLatest(Types.GET_CLINIC_MONTHLY_REPORT_REQUEST, getClinicMonthlyReport);
  yield takeLatest(Types.GET_CLINIC_DAILY_REPORT_REQUEST, getClinicDailyReport);
  yield takeLatest(Types.GET_MOST_DAILY_REQUEST, getMostDaily),
  yield takeLatest(Types.GET_MOST_MONTHLY_REQUEST, getMostMonthly),
  yield takeLatest(Types.GET_MOST_QUARTERLY_REQUEST, getMostQuarterly),
  yield takeLatest(Types.GET_MOST_YEARLY_REQUEST, getMostYearly);
  yield takeLatest(Types.GET_CLINIC_OVERVIEW_TODAY_REQUEST, getClinicOverviewToday);
  yield takeLatest(Types.GET_CLINIC_OVERVIEW_DAILY_REQUEST, getClinicOverviewDaily);
  yield takeLatest(Types.GET_CLINIC_OVERVIEW_MONTHLY_REQUEST, getClinicOverviewMonthly);
  yield takeLatest(Types.GET_CLINIC_OVERVIEW_QUARTERLY_REQUEST, getClinicOverviewQuarterly);
  yield takeLatest(Types.GET_CLINIC_OVERVIEW_YEARLY_REQUEST, getClinicOverviewYearly);
}
