import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import SkeletonInput from 'antd/lib/skeleton/Input';
import React, { useEffect } from 'react';
import { useCreateMedicine, useMedicine, useUpdateMedicine } from '~/hooks';
import { formatNumberThreeComma } from '~/hooks/utils';
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    lg: { span: 14 },
    xl: { span: 14 },
  },
};
const fullWidthStyle = {
  width: '100%',
};
export default function Medicine({isOpenForm,setIsOpenForm,id,setId}) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if(id){
      updateMedicine({...values,id})
    }
    else{
      createMedicine(values)
    }
  };
  const handleCloseForm = () => {
    setIsOpenForm(false)
    setId(null);
    form.resetFields()
  }
  const [medicine,loading] = useMedicine(id)
  useEffect(() => {
    form.resetFields()
    if(medicine){
      form.setFieldsValue(medicine)
    }
  },[medicine])
  const [loadingSubmit,createMedicine] = useCreateMedicine(handleCloseForm)
  const [,updateMedicine] = useUpdateMedicine(handleCloseForm)
  return (
    <Modal visible={isOpenForm} closable={false} footer={null} onCancel={handleCloseForm}>
      <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError
      requiredMark={false}
    >
      <FormItem
      {...formItemLayout}
        label="Tên Thiết bị"
        name="name"
        rules={[{ required: true, message: 'Xin mời nhập tên thiết bị' }]}
      >
        {loading ? <SkeletonInput /> :  <Input formatter={(value) => formatNumberThreeComma(value)}/>}
      </FormItem>
      <FormItem
        {...formItemLayout}
        label="Giá bán"
        name="price"
        rules={[{ required: true, message: 'Xin mời nhập giá bán' }]}
      >
        {loading ? <SkeletonInput /> : <InputNumber formatter={(value) => formatNumberThreeComma(value)} style={{...fullWidthStyle}} min={0} max={1000000000}/>}
      </FormItem>
      
      <FormItem
        {...formItemLayout}
        label="Phần trăm chiết khấu"
        name="discountPercentage"
        rules={[{ required: true, message: 'Xin mời nhập Phần trăm chiết khấu'}]}
      >
        {loading ? <SkeletonInput /> : <InputNumber style={{...fullWidthStyle}} min={0} max={100}/>}
      </FormItem>
      <Row className='groupButtonForm' justify='space-around'>
        <Col span={8}><Button loading={loadingSubmit} htmlType='submit' type='primary'>{id ? "Cập nhật" : "Tạo mới"}</Button></Col>
        <Col span={8}><Button onClick={handleCloseForm}>Huỷ</Button></Col>
      </Row>
    </Form>
    </Modal>
  );
}
