import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import {
  GET_WH_REPORT_CUSTOMERS_SUCCESS,
  GET_WH_REPORT_CUSTOMERS_FAILED,
  GET_WH_REPORT_CUSTOMERS_REQUEST,
  GET_WH_REPORT_CUSTOMER_SUCCESS,
  GET_WH_REPORT_CUSTOMER_FAILED,
  GET_WH_REPORT_CUSTOMER_REQUEST,
} from '../../constants/actionTypes';

function* getWhReportCustomers({ payload: query }) {
  try {
    const response = yield call(Api.whReportCustomer.getwhReportCustomers, query);
    yield put({ type: GET_WH_REPORT_CUSTOMERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_REPORT_CUSTOMERS_FAILED, payload: error });
  }
}
function* getWhReportCustomer({ payload: query }) {
  try {
    const response = yield call(Api.whReportCustomer.getOnewhReportCustomers, query);
    yield put({ type: GET_WH_REPORT_CUSTOMER_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_WH_REPORT_CUSTOMER_FAILED, payload: error });
  }
}



export default function* whReceiptVoucher() {
  yield takeLatest(GET_WH_REPORT_CUSTOMERS_REQUEST, getWhReportCustomers);
  yield takeLatest(GET_WH_REPORT_CUSTOMER_REQUEST, getWhReportCustomer);
}
