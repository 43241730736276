
import { Col, Form, Input, Radio, Row, Switch } from 'antd';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useUiClients, useUpdateUiClient } from '~/hooks/UiClient';
import { Breadcrumb } from '../Common';
import LoadingWhBill from '../WhBill/LoadingWhBill';
import BtnAdd from './BtnAdd';
import BtnRemove from './BtnRemove';
import BtnUpdate from './BtnUpdate';
import { LANGUAGE } from '~/constants/defaultValue';
import { handleSubmit } from '~/hooks/utils';
const key = 'footer';
const Footer = () => {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_BANNER)

    const [form] = Form.useForm();
    const [UiClient, isLoading] = useUiClients(key);
    const [isSubmitLoading, update] = useUpdateUiClient();
    const initValue = useMemo(() => get(UiClient, ['value', key]),[UiClient])
    const disabled = useMemo(() =>  !canUpdate,[canUpdate]);
    const [language, setLanguage] = useState(LANGUAGE.VI);
    const onSubmit = handleSubmit();
    const onFinish = (values) => {
      update({ key, ...values })
    };
    
    useEffect(() => {
        form.setFieldsValue({
            ...initValue
        })
    }, [initValue]);

    return (
        <div className="wh-service-form page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb title={'Cuối trang (Footer)'} routes={[{ path: '', title: 'CMS' }]} />
                {isLoading && <LoadingWhBill open={true} />}
                <Form
                    form={form}
                    autoComplete="off"
                    initialValues={{}}
                    scrollToFirstError
                    requiredMark={false}
                    onFinish={onFinish}
                    labelCol={{ sm: 24, md: 24, lg: 4 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 20 }}
                >
                    <Row justify="end" style={{ marginBottom: 20 }}>
                        <Radio.Group
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.VI}
                            >
                                VI
                            </Radio.Button>
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.EN}
                            >
                                EN
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                    <Form.Item label="Hiển thị" valuePropName="checked" name={['active']} >
                        <Switch disabled={disabled}/>
                    </Form.Item>
                    <Form.Item name={["address", LANGUAGE.VI]} hidden={language === LANGUAGE.EN} label="Địa chỉ VI">
                        <Input disabled={disabled} placeholder='Nhập Địa chỉ VI' />
                    </Form.Item>
                    <Form.Item name={["address", LANGUAGE.EN]} hidden={language === LANGUAGE.VI} label="Địa chỉ EN">
                        <Input disabled={disabled} placeholder='Nhập Địa chỉ EN' />
                    </Form.Item>
                    <Form.Item label={`Số điện thoại ${language.toUpperCase()}`}>
                        <Form.List name={['phoneNumber']}>
                            {(fields, { add, remove }) => (
                                <>
                                    <Row>
                                        {fields.map((field, index) => {
                                            return (
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={16}>
                                                            <Form.Item name={[index, LANGUAGE.VI]} hidden={language === LANGUAGE.EN}>
                                                                <Input disabled={initValue?.phoneNumber?.length > index && disabled}/>
                                                            </Form.Item>
                                                            <Form.Item name={[index, LANGUAGE.EN]} hidden={language === LANGUAGE.VI}>
                                                                <Input disabled={initValue?.phoneNumber?.length > index && disabled}/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={2}>
                                                            <BtnRemove onClick={() => remove(index)} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })}
                                        {fields?.length < 3 && <Col span={8}>
                                            <BtnAdd onClick={() => add()} />
                                        </Col>}
                                    </Row>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item name={["email", LANGUAGE.VI]} label="Email VI" hidden={language === LANGUAGE.EN}>
                        <Input disabled={disabled} placeholder='Nhập Email VI' />
                    </Form.Item>
                    <Form.Item name={["email", LANGUAGE.EN]} label="Email EN" hidden={language === LANGUAGE.VI}>
                        <Input disabled={disabled} placeholder='Nhập Email EN' />
                    </Form.Item>

                    <BtnUpdate loading={isSubmitLoading} />
                </Form>
            </div>
        </div>
    );
};
export default Footer;
