import requester from './requester';

const RequestVoucher = {
  getById: (id) => requester.get(`/request-voucher/${id}`),
  create: (params) => requester.post(`/request-voucher`, params),
  update:(params) => requester.put(`/request-voucher/${params._id}`,params),
  getAll : (query) => requester.get(`/request-voucher`,query),
  delete: (id) => requester.delete(`/request-voucher/${id}`),
  
};

export default RequestVoucher;
