export const GET_UIS_REQUEST =
  'GET_UIS_REQUEST';
export const GET_UIS_SUCCESS =
  'GET_UIS_SUCCESS';
export const GET_UIS_FAILED = 'GET_UIS_FAILED';

export const UPDATE_UI_REQUEST =
  'UPDATE_UI_REQUEST';
export const UPDATE_UI_SUCCESS =
  'UPDATE_UI_SUCCESS';
export const UPDATE_UI_FAILED =
  'UPDATE_UI_FAILED';


export const RESET_UI_STATE = 'RESET_UI_STATE';
