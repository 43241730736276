export const GET_BOARDS_REQUEST = 'GET_BOARDS_REQUEST';
export const GET_BOARDS_SUCCESS = 'GET_BOARDS_SUCCESS';
export const GET_BOARDS_FAILED = 'GET_BOARDS_FAILED';

//GET BOARD GROUP
export const GET_BOARD_GROUP_REQUEST = 'GET_BOARD_GROUP_REQUEST';
export const GET_BOARD_GROUP_SUCCESS = 'GET_BOARD_GROUP_SUCCESS';
export const GET_BOARD_GROUP_FAILED = 'GET_BOARD_GROUP_FAILED';
//get board by id
export const GET_BOARD_BY_ID_REQUEST = 'GET_BOARD_BY_ID_REQUEST';
export const GET_BOARD_BY_ID_SUCCESS = 'GET_BOARD_BY_ID_SUCCESS';
export const GET_BOARD_BY_ID_FAILED = 'GET_BOARD_BY_ID_FAILED';
//create
export const CREATE_BOARD_REQUEST = 'CREATE_BOARD_REQUEST';
export const CREATE_BOARD_SUCCESS = 'CREATE_BOARD_SUCCESS';
export const CREATE_BOARD_FAILED = 'CREATE_BOARD_FAILED';
//update
export const UPDATE_BOARD_REQUEST = 'UPDATE_BOARD_REQUEST';
export const UPDATE_BOARD_SUCCESS = 'UPDATE_BOARD_SUCCESS';
export const UPDATE_BOARD_FAILED = 'UPDATE_BOARD_FAILED';
//delete
export const DELETE_BOARD_REQUEST = 'DELETE_BOARD_REQUEST';
export const DELETE_BOARD_SUCCESS = 'DELETE_BOARD_SUCCESS';
export const DELETE_BOARD_FAILED = 'DELETE_BOARD_FAILED';
//end board
export const GET_WORK_LIST_REQUEST = 'GET_WORK_LIST_REQUEST';
export const GET_WORK_LIST_SUCCESS = 'GET_WORK_LIST_SUCCESS';
export const GET_WORK_LIST_FAILED = 'GET_WORK_LIST_FAILED';

//get board config
export const GET_LIST_BOARD_CONFIG_REQUEST = 'GET_LIST_BOARD_CONFIG_REQUEST';
export const GET_LIST_BOARD_CONFIG_SUCCESS = 'GET_LIST_BOARD_CONFIG_SUCCESS';
export const GET_LIST_BOARD_CONFIG_FAILED = 'GET_LIST_BOARD_CONFIG_FAILED';

//getById
export const GET_WORK_LIST_BY_ID_REQUEST = 'GET_WORK_LIST_BY_ID_REQUEST';
export const GET_WORK_LIST_BY_ID_SUCCESS = 'GET_WORK_LIST_BY_ID_SUCCESS';
export const GET_WORK_LIST_BY_ID_FAILED = 'GET_WORK_LIST_BY_ID_FAILED';
//create
export const CREATE_WORK_REQUEST = 'CREATE_WORK_REQUEST';
export const CREATE_WORK_SUCCESS = 'CREATE_WORK_SUCCESS';
export const CREATE_WORK_FAILED = 'CREATE_WORK_FAILED';
//update
export const UPDATE_WORK_REQUEST = 'UPDATE_WORK_REQUEST';
export const UPDATE_WORK_SUCCESS = 'UPDATE_WORK_SUCCESS';
export const UPDATE_WORK_FAILED = 'UPDATE_WORK_FAILED';
//delete
export const DELETE_WORK_REQUEST = 'DELETE_WORK_REQUEST';
export const DELETE_WORK_SUCCESS = 'DELETE_WORK_SUCCESS';
export const DELETE_WORK_FAILED = 'DELETE_WORK_FAILED';

//get boardConfigItems

export const ADD_BOARD_CONFIG_ITEM_REQUEST = 'ADD_BOARD_CONFIG_ITEM_REQUEST';
export const ADD_BOARD_CONFIG_ITEM_SUCCESS = 'ADD_BOARD_CONFIG_ITEM_SUCCESS';
export const ADD_BOARD_CONFIG_ITEM_FAILED = 'ADD_BOARD_CONFIG_ITEM_FAILED';
// filter boardConfigItems
export const FILTER_BOARD_CONFIG_ITEM_REQUEST = 'FILTER_BOARD_CONFIG_ITEM_REQUEST';
export const FILTER_BOARD_CONFIG_ITEM_SUCCESS = 'FILTER_BOARD_CONFIG_ITEM_SUCCESS';
export const CHANGE_POSITION_TASK_ITEM = 'CHANGE_POSITION_TASK_ITEM';

//manager||staff
export const GET_LIST_MANAGERS_REQUEST = 'GET_LIST_MANAGERS_REQUEST';
export const GET_LIST_MANAGERS_SUCCESS = 'GET_LIST_MANAGERS_SUCCESS';
export const GET_LIST_MANAGERS_FAILED = 'GET_LIST_MANAGERS_FAILED';
export const GET_LIST_MANAGERS_BY_ID_BOARD_REQUEST = 'GET_LIST_MANAGERS_BY_ID_BOARD_REQUEST';
export const GET_LIST_MANAGERS_BY_ID_BOARD_SUCCESS = 'GET_LIST_MANAGERS_BY_ID_BOARD_SUCCESS';
export const GET_LIST_MANAGERS_BY_ID_BOARD_FAILED = 'GET_LIST_MANAGERS_BY_ID_BOARD_FAILED';
export const GET_LIST_STAFFS_REQUEST = 'GET_LIST_STAFFS_REQUEST';
export const GET_LIST_STAFFS_SUCCESS = 'GET_LIST_STAFFS_SUCCESS';
export const GET_LIST_STAFFS_FAILED = 'GET_LIST_STAFFS_FAILED';
export const GET_LIST_STAFFS_BY_ID_BOARD_REQUEST = 'GET_LIST_STAFFS_BY_ID_BOARD_REQUEST';
export const GET_LIST_STAFFS_BY_ID_BOARD_SUCCESS = 'GET_LIST_STAFFS_BY_ID_BOARD_SUCCESS';
export const GET_LIST_STAFFS_BY_ID_BOARD_FAILED = 'GET_LIST_STAFFS_BY_ID_BOARD_FAILED';
export const GET_LIST_TASK_BY_SPRINTS_REQUEST = 'GET_LIST_TASK_BY_SPRINTS_REQUEST';
export const GET_LIST_TASK_BY_SPRINTS_SUCCESS = 'GET_LIST_TASK_BY_SPRINTS_SUCCESS';
export const GET_LIST_TASK_BY_SPRINTS_FAILED = 'GET_LIST_TASK_BY_SPRINTS_FAILED';

