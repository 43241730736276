import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { assign, compact, concat, get, groupBy, head, keys, pick, sortBy, unionBy, uniqBy, xorBy } from 'lodash';
import { getDataGroupInit, getDataGroupInitMaterial, removeBatchEmpty, SplitDataWarehouse, SplitGroupInitWarehouse, useUser, useWhAppointment } from '~/hooks';
import { MAX_PAGINATION_LIMIT, TYPE_WAREHOUSE } from '~/constants/defaultValue';
import { useMedicalEquipments } from '~/hooks/medicalEquipment';
import toastr from 'toastr';
import api from '~/api';
const AssignWarehouseAppointment = createContext({
    groupInitEquipment: null,
    dataWhAppointment: null,
    productSelected: [],
    dataSourceMaterial: [],
    setDataSourceMaterial: () => { },
    listMaterialEmptyBatch: [],
    setListMaterialEmptyBatch: () => { },
    dataSourceEquipment: [],
    setDataSourceEquipment: () => { },
    whAppointment: null,
    isLoadingWhAppointment: false,
    handleSetNewDataSourceEquipment: () => { },
    handleClearRowDataSourceEquipment: () => { },
    includesProductSelected: () => { },
    onCancel: () => { },
    medicalEquipmentsWorldHealth: null,
    medicalEquipmentsClinic: null,

    isFullEquipmentAndMaterial: false,

    whAppointmentSelected: null,
    dataSourceWhBillItem: null,
    whAppointmentId: null,
    missingMaterialList: [],
    loadingGetMaterial: false,
    totalQuantityUsedEachGroup : {},
    setTotalQuantityUsedEachGroup : {},
});

export function AssignWarehouseAppointmentProvider({
    mutateAppointment,
    onCancel,
    whAppointmentId,
    dataSourceWhBillItem,
    whAppointmentSelected,
    listProduct,
    children,
    mergedWhAppointments,
}) {
    const [whAppointment, isLoadingWhAppointment] = useWhAppointment(whAppointmentId);
    const branchId = get(head(dataSourceWhBillItem), 'branchId');
    const dataWhAppointment = useMemo(() =>
    ({
        statusAppointment: get(whAppointment, 'status'),
        whPartnerId: get(whAppointment, 'whPartnerId'),
        groupProductInits: getDataGroupInit(whAppointmentSelected),
        groupMaterialInits: getDataGroupInitMaterial(whAppointmentSelected),
        preBooking: get(whAppointmentSelected, 'preBooking', []),
        _id: whAppointmentId
    }), [whAppointment, whAppointmentId, whAppointmentSelected]);
    const [groupInitEquipment, setGroupInitEquipment] = useState([]);
    const [dataSourceMaterial, setDataSourceMaterial] = useState([]); // listProduct Assign Material (Controller in table)
    const [dataSourceEquipment, setDataSourceEquipment] = useState([]); // listProduct Assign Equipment (Controller in table)
    const [productSelected, setProductSelected] = useState([]);
    const [missingMaterialList, setMissingMaterialList] = useState([]); // List Missing From BE WHEN CALL API CLINIC
    const [listMaterialEmptyBatch, setListMaterialEmptyBatch] = useState([]); // LIST MATERIAL EMPTY BATCH CAN USE
    const [loadingGetMaterial, setLoadingGetMaterial] = useState(false);
    const queryGetData = useMemo(() => ({ page: 1, limit: MAX_PAGINATION_LIMIT }), []);
    const [medicalEquipments] = useMedicalEquipments(queryGetData);
    const [totalQuantityUsedEachGroup,setTotalQuantityUsedEachGroup] = useState({});
    const medicalEquipmentsClinic = useMemo(() => {
        return medicalEquipments?.find(e => get(e, 'type') === TYPE_WAREHOUSE.CLINIC)
    }, [medicalEquipments])

    const medicalEquipmentsWorldHealth = useMemo(() => {
        return medicalEquipments?.find(e => get(e, 'type') === TYPE_WAREHOUSE.WORLDHEALTH)
    }, [medicalEquipments]);

    useEffect(() => {
        let group = {};
        mergedWhAppointments?.forEach(billItem => {
            get(billItem,'listProduct',[])?.forEach(product => {
                const idGroup = get(product,'groupEquipmentId._id');
                const actualQuantity = get(product,'actualQuantity',0);
                if(group[idGroup]){
                    group[idGroup] += actualQuantity
                }else{
                    group[idGroup] = actualQuantity
                }
            })
        });
        setTotalQuantityUsedEachGroup(group);
    },[mergedWhAppointments])
    useEffect(() => {
        const { dataMaterial: dataMaterial_, dataEquipment: dataEquipment_ } = SplitDataWarehouse(listProduct);
        const { groupInitEquipment: groupInitEquipment_ } = SplitGroupInitWarehouse(dataWhAppointment.groupProductInits, dataWhAppointment.statusAppointment, dataWhAppointment.preBooking);
        setGroupInitEquipment(groupInitEquipment_);
        const fetchMaterialInit = async () => {
            try {
                // Pending
                setLoadingGetMaterial(true);

                // Get init Data Material from WhAppointment 
                const dataInitMaterial = dataMaterial_?.map((material, index) => {
                    if (get(material, 'batchId')) {
                        const findBatch = get(material, 'ref.batches', [])?.find(batch => get(batch, '_id') === get(material, 'batchId'));
                        return ({ ...material, key: index, ...get(material, 'ref'), lotNumber: get(findBatch, 'lotNumber', ''), quantityOfBatchSelect: get(findBatch, 'availableTotal', 0) });
                    }
                    else {
                        return ({ ...material, key: index, ...get(material, 'ref') });
                    }
                });
                const groupInitMaterial__ = get(dataWhAppointment, 'groupMaterialInits', []);

                // Get Data Material From Call Api from Clinic (Use groupInitMaterial__)
                const response = await api.wareHouse.getMaterialsInit({ sample: groupInitMaterial__, branchId });
                const sortByAvailable = (response || [])?.sort((a,b) => get(b,'availableQuantity') - get(a,'availableQuantity'))
                
                const uniqByMaterial = uniqBy(sortByAvailable, (e) => [get(e,'product.name'),get(e,'unit.name')].join());
                
                // Get REsponse to Compare MaterialInit to get List missing
                const listMissing = groupInitMaterial__?.filter(groupInit => {
                    return !uniqByMaterial?.some(material => filterGroupMaterial_core(material, groupInit));
                })
                setMissingMaterialList(listMissing);

                // Transfer Array to Use in Table Material
                const materialFromInit = uniqByMaterial?.map(e => ({ ...e, ref: e, refId: get(e, '_id') }));

                // Join 2 Array : List Material assigned and Material From init
                const concatMaterial = [...dataInitMaterial, ...materialFromInit];

                // Remove Exist Material From init
                const removeExist = unionBy(concatMaterial, (e) => get(e, 'refId'));
                const AddFieldForRemoveExist = removeExist?.map((material, index) => {
                    const materialInInitGroup = groupInitMaterial__?.find(item =>
                        filterGroupMaterial_core(material, item)
                    );
                    return {
                        ...material,
                        quantity: get(materialInInitGroup, 'amount', 1),
                        isInGroupInit: !!materialInInitGroup, // Check If Material was Assigned But Group init is Remove in service
                        key: index
                    }
                });

                setDataSourceMaterial(AddFieldForRemoveExist);
                setLoadingGetMaterial(false)
            } catch (error) {
                setLoadingGetMaterial(false);
                toastr.error("Có lỗi gì đó xảy ra")
            }
        }
        fetchMaterialInit()
        // Filter data material in ListProduct of Appointments
        // Group and Create a Array DataSource from Group Init and ListProduct WorldHeath of Appointments
        const groupByGroupEquipmentId = groupBy(concat(groupInitEquipment_, dataEquipment_), (e) => get(e, 'groupEquipmentId._id'));
        const dataInitEquipment = keys(groupByGroupEquipmentId)?.map(e => {
            let dataAssign = {};
            groupByGroupEquipmentId[e].forEach(item => {
                assign(dataAssign, { ...item });
            })
            return { ...dataAssign, medicalEquipmentTypeId: get(medicalEquipmentsWorldHealth, '_id'), type: TYPE_WAREHOUSE.WORLDHEALTH }
        });
        setDataSourceEquipment(dataInitEquipment);



    }, []);

    // WATCH PRODUCT SELECT
    useEffect(() => {
        setProductSelected(dataSourceEquipment?.map(data => ({
            productId: get(data, 'refId'),
            groupEquipmentId: get(data, 'groupEquipmentId._id')
        })));
    }, [dataSourceEquipment]);

    // WATCH MATERIAL BATCH EMPTY
    useEffect(() => {
        const findMaterialEmptyBatch = dataSourceMaterial?.filter(data => {
            const checkBatchCanActive = removeBatchEmpty(get(data,'batches',[]));
            if(!checkBatchCanActive || !checkBatchCanActive.length){
                return true;
            }
            return false;
        });
        setListMaterialEmptyBatch(findMaterialEmptyBatch);
    }, [dataSourceMaterial]);


    // WATCH is full Assignments
    const isFullEquipmentAndMaterial = useMemo(() => {
        const isExistEquipment = groupInitEquipment?.filter(group => {
            return productSelected?.some(item => get(item, 'groupEquipmentId') === get(group, 'groupEquipmentId._id'));
        });

        const isExistMaterial = get(dataWhAppointment, 'groupMaterialInits', [])?.filter(item => {
            return dataSourceMaterial?.some(material => {
                return !!get(material, 'batchId') && filterGroupMaterial_core(material, item)
            });
        });
        const isFullEquipment = isExistEquipment?.length === groupInitEquipment?.length;
        const isFullMaterial = isExistMaterial?.length === get(dataWhAppointment, 'groupMaterialInits', [])?.length;
        return isFullEquipment && isFullMaterial
    }, [groupInitEquipment, productSelected, dataWhAppointment, dataSourceMaterial]);
    const handleSetNewDataSourceEquipment = (data, mode = 'assign', callback = () => { }) => {
        const cloneDataSource = [...dataSourceEquipment];
        const index = cloneDataSource?.findIndex(e => get(e, 'groupEquipmentId._id') === get(data, 'groupProductId'));
        if (index === -1) return toastr.error("Không tìm thấy nhóm sản phẩm");
        const dataFind = { ...cloneDataSource[index] };
        let newData = {};
        if (mode === 'assign') {
            newData = { ...dataFind, refId: get(data, '_id'), ref: data, quantity: get(dataFind, 'amount'),actualQuantity : get(dataFind, 'actualQuantity',0) };
        }
        if (mode === 'quantity') {
            newData = { ...dataFind,actualQuantity :  get(data, 'actualQuantity')};
        }
        if (mode === 'preBooking') {
            newData = { ...dataFind, preBooking: data };
        }
        if (mode === 'removePreBooking') {
            newData = { ...dataFind, preBooking: null };
        }
        cloneDataSource.splice(index, 1, newData);
        setDataSourceEquipment(cloneDataSource);
        if (callback && typeof callback === 'function') {
            callback();
        };

    };
    const handleClearRowDataSourceEquipment = (groupEquipmentId) => {
        // FInd data group Init and Replace it
        const cloneDataSource = [...dataSourceEquipment]
        const dataInitReplace = dataWhAppointment?.groupProductInits?.find(e => get(e, 'groupEquipmentId._id') === groupEquipmentId)
        const index = cloneDataSource?.findIndex(e => get(e, 'groupEquipmentId._id') === groupEquipmentId)
        // Find Index to remove and Replace Data Init group + Data Keep
        if (index !== -1) {
            const dataKeep = pick(cloneDataSource[index], ['preBooking']);
            cloneDataSource.splice(index, 1, { ...dataInitReplace, ...dataKeep });
            setDataSourceEquipment(cloneDataSource);

        } else {
            toastr.error("Có lỗi gì đó xảy ra");
        }
    }

    const includesProductSelected = (idProduct) => productSelected?.some(p => get(p, 'productId') === idProduct);

    function filterGroupMaterial_core(material, itemGroup) {
        return (get(material, 'product.name') === get(itemGroup, 'name')) 
        && (get(material, 'unit.name') === get(itemGroup, 'unit'))
        // && (get(material,'availableQuantity',0) >= get(itemGroup, 'amount',0))
    }
    return (
        <AssignWarehouseAppointment.Provider
            value={{
                groupInitEquipment,
                dataWhAppointment,
                productSelected,
                dataSourceMaterial,
                dataSourceEquipment,
                setDataSourceMaterial,
                setDataSourceEquipment,
                whAppointment,
                isLoadingWhAppointment,
                handleSetNewDataSourceEquipment,
                handleClearRowDataSourceEquipment,
                includesProductSelected,
                medicalEquipmentsWorldHealth,
                medicalEquipmentsClinic,
                isFullEquipmentAndMaterial,
                whAppointmentSelected,
                dataSourceWhBillItem,
                mutateAppointment,
                onCancel,
                whAppointmentId,
                missingMaterialList,
                loadingGetMaterial,
                listMaterialEmptyBatch,
                totalQuantityUsedEachGroup,
                setTotalQuantityUsedEachGroup,
            }}
        >
            {children}
        </AssignWarehouseAppointment.Provider>
    );
}

const useAssignWarehouseAppointmentStore = () => useContext(AssignWarehouseAppointment);

export default useAssignWarehouseAppointmentStore;
