import { get } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
/**
 * When Submenu is Out of view Right screen will appear reverse
 */
export const useOutInterView = () => {
  const getSelector = (key) => (state) => state.user[key];
  const policySelector = getSelector('policy');
  const policies = useSelector(policySelector);
  useEffect(() => {
      // When Element subMenu is Over flow Screen , parent will be add Class to Move element
      const elementDropDown = document.querySelectorAll('div.dropdown-menu div.dropdown-menu')
      const observer = new IntersectionObserver(
        (entry) => {
          entry.forEach(item => {
            // Check is Element is in 100% in Screen
            if ( item.isIntersecting && item.intersectionRatio < 1) {
              item.target.parentElement.parentElement.classList.add("dropdown-menu-In-left")
            }
          })
        },
        { rootMargin: "0px 10px 1000px 0px", root: null } // top right Bottom left
      );
      if (elementDropDown?.length > 0) {
        elementDropDown?.forEach(dropDown => observer.observe(dropDown))
      }
      return () => {
        if (elementDropDown?.length > 0) {
          elementDropDown?.forEach(dropDown => observer.unobserve(dropDown))
        }
      }
  
  }, [policies]); // Navbar will render when have POLICI
}
/**
 * When Element is Over view screen will translate in the screen
 * If want Register element just add element Class registerOutView
 */
export const useMoveElementWhenOverFlow = () => {
  const getSelector = (key) => (state) => state.user[key];
  const policySelector = getSelector('policy');
  const policies = useSelector(policySelector);
  useEffect(() => {
      const elementDropDown = document.querySelectorAll('.registerOutView')
      const observer = new IntersectionObserver(
        (entry) => {
          entry.forEach(item => {
            // Check is Element is in 100% in Screen
            if (item.isIntersecting && item.intersectionRatio < 1) {
              let widthScreen = window.innerWidth
              let positionElementLeft = get(item, 'boundingClientRect.left')
              let positionElementRight = get(item, 'boundingClientRect.right')
              const distanceOutScreen = positionElementRight - widthScreen
              item.target.style.transition = '0.5s'
              if (positionElementLeft < 0) { // out side left screen
                item.target.style.marginRight = `${positionElementLeft}px` 
              }
              else {
                if (distanceOutScreen > 0) {// out side left screen
                  item.target.style.marginLeft = `-${distanceOutScreen}px` 
                }
              }
            }
          })
        },
        { rootMargin: "0px 0px 1000px 0px", root: null } // top right Bottom left
      );
      if (elementDropDown?.length > 0) {
        elementDropDown?.forEach(dropDown => observer.observe(dropDown))
      }
      return () => {
        if (elementDropDown?.length > 0) {
          elementDropDown?.forEach(dropDown => observer.unobserve(dropDown))
        }
      }

  }, [policies]);
}
