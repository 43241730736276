import { Tabs } from 'antd';
import { get } from 'lodash';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useProfile, useSelectBranch } from '~/hooks';
import { useBranchIdSessionStorage, useQueryParams } from '~/hooks/utils';
const TabBranchContext = createContext();
export const useContextBranch = () => useContext(TabBranchContext);

export default function TabBranch({ children,useBoxShadow = true,isRender = true,useTabAll = false }) {
  const [profile] = useProfile();
  const allQuery = useQueryParams();

  const history = useHistory();
  const {path} = useRouteMatch();
  const [brandId] = useSelectBranch();
  const [branchIdSession, handChangeBranchId] = useBranchIdSessionStorage();
  const [activeKey, setActiveKey] = useState(null);
  const sortBranches = useMemo(() => {
    const cloneBranches = [...get(profile, 'branches', [])];
    cloneBranches?.sort((pre,next) => get(next,'_id') - get(pre,'_id'));
    if(useTabAll){
      return [{
        name : "Tất cả"
      },...cloneBranches]
    }else{
      return cloneBranches
    }
    
  },[profile])
  useEffect(() => {
    if(useTabAll){
      const isHaveQueryGetAll = allQuery.get("allBranch");
    if(!!isHaveQueryGetAll){
      setActiveKey('null'); // Set Active To Tab All
    }else{
      setActiveKey(branchIdSession ?? brandId); // Set Active To Tab Current Branch
    }
  }else{
      setActiveKey(branchIdSession ?? brandId);// Set Active To Tab Current Branch And Delete QueryAllBranch
      allQuery.delete("allBranch");
    }
    
  }, [brandId, branchIdSession,useTabAll]);
  const onChange = (key) => {
    if(!key || key === 'null'){ // Select All Branch
      history.push({
        pathname : path,
        search : new URLSearchParams(
          {allBranch : true}
        ).toString()
      });
      setActiveKey(key);
    }else{
      handChangeBranchId(key);
      setActiveKey(key);
      history.replace(path); // reset query param
    }
  };
  if(!isRender){
    return children
  }
  return (
    <TabBranchContext.Provider value={{ branches: profile?.branches ?? [] }}>
      {get(profile, 'isSuperAdmin') ? (
        <Tabs
          className={`tab-branch ${useBoxShadow && "useBoxShadow"}`}
          onChange={onChange}
          type="card"
          activeKey={activeKey}
          destroyInactiveTabPane
        >
          {sortBranches?.map((_) => {
            const id = get(_, '_id');
            const name = get(_, 'name', '');
            return (
              <Tabs.TabPane style={{height:'100%'}}tab={name} key={id}>
                {children}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      ) : (
        children
      )}
    </TabBranchContext.Provider>
  );
}
