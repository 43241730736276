
import { Button, Form, Input, Radio, Row, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { useUIs, useUpdateUI } from '~/hooks/UI';
import AutoGetCodeYoutube from '../AddYoutube/AutoGetCodeYoutube';
import HowToGetYoutubeId from '../AddYoutube/HowToGetYoutubeId';
import { Breadcrumb, WithOrPermission } from '../Common';
import LoadingWhBill from '../WhBill/LoadingWhBill';
import { LANGUAGE } from '~/constants/defaultValue';
import { handleSubmit } from '~/hooks/utils';
import FormItemYoutube from '../Banner/FormItemYoutube';
const SayAboutUs = () => {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_BANNER);
    const [form] = Form.useForm();
    const [UI, isLoading] = useUIs('homepageSayAboutUs');
    const [isSubmitLoading, update] = useUpdateUI();
    const [language, setLanguage] = useState(LANGUAGE.VI);
    const onSubmit = handleSubmit();
    const onFinish = (values) => {
        update({ key: 'homepageSayAboutUs', ...values })
    };

    useEffect(() => {
        form.setFieldsValue({
            ...get(UI, 'UI.homepageSayAboutUs')
        })
    }, [UI]);

    return (
        <div className="wh-service-form page-wraper page-content">
            <div className="container-fluid">
                <Breadcrumb title={'Truyền thông báo chí'} routes={[{ path: '', title: 'CMS' }]} />
                {isLoading && <LoadingWhBill open={true} />}
                <Form
                    form={form}
                    autoComplete="off"
                    initialValues={{}}
                    scrollToFirstError
                    requiredMark={false}
                    onFinish={onFinish}
                    onFinishFailed={onSubmit}
                    labelCol={{ sm: 24, md: 24, lg: 6 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 18 }}
                >
                    <Row justify="end" style={{ marginBottom: 20 }}>
                        <Radio.Group
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.VI}
                            >
                                VI
                            </Radio.Button>
                            <Radio.Button
                                className="wh-service-form__select-langue-btn"
                                value={LANGUAGE.EN}
                            >
                                EN
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                    <Form.Item label="Hiển thị" valuePropName="checked" name={['active']} >
                        <Switch disabled={!canUpdate}/>
                    </Form.Item>
                    <Form.Item name={["subTitle", LANGUAGE.VI]} label="Tiêu đề phụ VI" hidden={language === LANGUAGE.EN}>
                        <Input disabled={!canUpdate} placeholder='Nhập tiêu đề phụ' />
                    </Form.Item>
                    <Form.Item name={["subTitle", LANGUAGE.EN]} label="Tiêu đề phụ EN" hidden={language === LANGUAGE.VI}>
                        <Input disabled={!canUpdate} placeholder='Nhập tiêu đề phụ' />
                    </Form.Item>
                    <Form.Item name={["title", LANGUAGE.VI]} label="Tiêu đề VI" hidden={language === LANGUAGE.EN}>
                        <Input disabled={!canUpdate} placeholder='Tiêu đề' />
                    </Form.Item>
                    <Form.Item name={["title", LANGUAGE.EN]} label="Tiêu đề EN" hidden={language === LANGUAGE.VI}>
                        <Input disabled={!canUpdate} placeholder='Tiêu đề' />
                    </Form.Item>
                    <Form.Item name={["content", LANGUAGE.VI]} label="Nội dung VI" hidden={language === LANGUAGE.EN}>
                        <TextArea disabled={!canUpdate} rows={3} placeholder='Nội dung' />
                    </Form.Item>
                    <Form.Item name={["content", LANGUAGE.EN]} label="Nội dung EN" hidden={language === LANGUAGE.VI}>
                        <TextArea disabled={!canUpdate} rows={3} placeholder='Nội dung' />
                    </Form.Item>
                    <FormItemYoutube disabled={!canUpdate} />
                    <HowToGetYoutubeId />
                    <Row className="wh-service-form__submit-box">
                        <WithOrPermission permission={[POLICIES.UPDATE_BANNER, POLICIES.WRITE_BANNER]}>
                            <Button
                                loading={isSubmitLoading}
                                type="primary"
                                htmlType="submit"
                            >
                                Cập nhật
                            </Button>
                        </WithOrPermission>
                    </Row>
                </Form>
            </div>
        </div>
    );
};
export default SayAboutUs;
