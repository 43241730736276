import { get, has } from "lodash";
import moment from "moment";

import { INSTANCE_STATUSES } from "~/constants/defaultValue";

/**
 * Convert an object received from API to the internal object that can work with Antd Form.
 *
 * @param {*} instance
 * @return {*} 
 */
const fromJSON = instance => {
  return {
    ...instance,
    id: get(instance, "_id") || get(instance, "id"),
    ...(get(instance, "startDate") && { startDate: moment(get(instance, "startDate")) }),
    ...(get(instance, "endDate") && { endDate: moment(get(instance, "endDate")) }),
  }
}

export {
  fromJSON,
}
