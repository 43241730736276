import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getAdvsFailed: null,
  getAdvFailed: null,
  Advs: [],
  Adv: null,
  isSubmitLoading: false,
  isGetAdvLoading: false,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,

  createSuccess: null,
  createFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_ADVS_REQUEST:
      state.isLoading = true;
      state.getAdvsFailed = null;
      return;

    case Types.GET_ADVS_SUCCESS:
      state.isLoading = false;
      state.Advs = get(payload, 'docs', []);
      state.getAdvsFailed = null;
      state.paging = getPaging(payload);
      return;

    case Types.GET_ADVS_FAILED:
      state.isLoading = false;
      state.Advs = [];
      state.getAdvsFailed = payload;
      return;
    case Types.GET_ADV_REQUEST:
      state.isGetAdvLoading = true;
      state.Adv = null
      state.getAdvFailed = null;
      return;

    case Types.GET_ADV_SUCCESS:
      state.isGetAdvLoading = false;
      state.Adv = payload;
      state.getAdvFailed = null;
      return;

    case Types.GET_ADV_FAILED:
      state.isGetAdvLoading = false;
      state.Adv = null;
      state.getAdvFailed = payload;
      return;
    case Types.CREATE_ADV_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_ADV_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_ADV_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
    case Types.UPDATE_ADV_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_ADV_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.Advs = state.Advs.map(e => get(e, '_id') === get(payload, '_id', {}) ? payload : e)
      state.Adv = payload;
      return;

    case Types.UPDATE_ADV_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;


    case Types.DELETE_ADV_SUCCESS:
      state.isSubmitLoading = false;
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_ADV_FAILED:
      state.isSubmitLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.RESET_ADV_ACTION:
      state.getAdvsFailed = null;
      state.getAdvFailed = null;
      state.Adv = null;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      state.updateSuccess = null;
      state.updateFailed = null;
      state.createSuccess = null;
      state.createFailed = null;
      return ;

    case Types.RESET_STORE:
    case Types.RESET_ADV_STATE:
      return initState;

    default:
      return;
  }
}, initState);
