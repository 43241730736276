import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '~/api';
import * as Types from '~/constants/actionTypes';

function* getProductLists({ payload: products }) {
  try {
    const data = yield call(Api.productList.getAll, products);
    yield put({ type: Types.GET_PRODUCT_LISTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_LISTS_FAILED,
      payload: error.message
    });
  }
}

function* getProductListsOfPartner({ payload: products }) {
  try {
    const data = yield call(Api.productList.getAllOfPartner, products);
    yield put({ type: Types.GET_PRODUCT_OF_WHPARTNER_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_OF_WHPARTNER_FAILED,
      payload: error.message
    });
  }
}

function* getProductListsPartnerBorrowed({ payload: query }) {
  try {
    const data = yield call(Api.whPartner.getListDevideBorrowSystem, query);
    yield put({ type: Types.GET_PRODUCT_LISTS_PARTNER_BORROWED_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_LISTS_PARTNER_BORROWED_FAILED,
      payload: error
    });
  }
}

function* getProductList({ payload }) {
  try {
    const res = yield call(Api.productList.getById, payload);
    yield put({
      type: Types.GET_PRODUCT_LIST_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_LIST_FAILED,
      payload: error.message
    });
  }
}
function* getProductListHistory({ payload }) {
  try {
    const res = yield call(Api.productList.getAllHistory, payload);
    yield put({
      type: Types.GET_PRODUCT_LIST_HISTORY_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCT_LIST_HISTORY_FAILED,
      payload: error.message
    });
  }
}

function* createProductList(action) {
  try {
    const data = yield call(Api.productList.create, action.payload);
    yield put({ type: Types.CREATE_PRODUCT_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_PRODUCT_LIST_FAILED,
      payload: error.message
    });
  }
}

function* updateProductList(action) {
  try {
    const data = yield call(Api.productList.update, action.payload);
    yield put({ type: Types.UPDATE_PRODUCT_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_PRODUCT_LIST_FAILED,
      payload: error.message
    });
  }
}

function* deleteProductList({payload}) {
  try {
    yield call(Api.productList.delete, payload);
    yield put({ type: Types.DELETE_PRODUCT_LIST_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_PRODUCT_LIST_FAILED,
      payload: error.message
    });
  }
}

function* stopDeliveryProduct({ payload }) {
  console.log(payload)
  try {
    yield call(Api.productList.updateDeliveryVoucherInfo, payload);
    yield put({ type: Types.STOP_DELIVERY_PRODUCT_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.STOP_DELIVERY_PRODUCT_FAILED,
      payload: error.message
    });
  }
}

export default function* partner() {
  yield takeLatest(Types.GET_PRODUCT_LISTS_REQUEST, getProductLists);
  yield takeLatest(Types.GET_PRODUCT_OF_WHPARTNER_REQUEST, getProductListsOfPartner);
  yield takeLatest(Types.GET_PRODUCT_LIST_REQUEST, getProductList);
  yield takeLatest(Types.GET_PRODUCT_LIST_HISTORY_REQUEST, getProductListHistory);
  yield takeLatest(Types.DELETE_PRODUCT_LIST_REQUEST, deleteProductList);
  yield takeLatest(Types.CREATE_PRODUCT_LIST_REQUEST, createProductList);
  yield takeLatest(Types.UPDATE_PRODUCT_LIST_REQUEST, updateProductList);
  yield takeLatest(Types.GET_PRODUCT_LISTS_PARTNER_BORROWED_REQUEST, getProductListsPartnerBorrowed);
  yield takeLatest(Types.STOP_DELIVERY_PRODUCT_REQUEST, stopDeliveryProduct);
}
