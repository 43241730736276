export const GET_GROUP_CATEGORIES_REQUEST = 'GET_GROUP_CATEGORIES_REQUEST';
export const GET_GROUP_CATEGORIES_SUCCESS = 'GET_GROUP_CATEGORIES_SUCCESS';
export const GET_GROUP_CATEGORIES_FAILED = 'GET_GROUP_CATEGORIES_FAILED';

export const GET_GROUP_CATEGORY_REQUEST = 'GET_GROUP_CATEGORY_REQUEST';
export const GET_GROUP_CATEGORY_SUCCESS = 'GET_GROUP_CATEGORY_SUCCESS';
export const GET_GROUP_CATEGORY_FAILED = 'GET_GROUP_CATEGORY_FAILED';

export const CREATE_GROUP_CATEGORY_REQUEST = 'CREATE_GROUP_CATEGORY_REQUEST';
export const CREATE_GROUP_CATEGORY_SUCCESS = 'CREATE_GROUP_CATEGORY_SUCCESS';
export const CREATE_GROUP_CATEGORY_FAILED = 'CREATE_GROUP_CATEGORY_FAILED';

export const UPDATE_GROUP_CATEGORY_REQUEST = 'UPDATE_GROUP_CATEGORY_REQUEST';
export const UPDATE_GROUP_CATEGORY_SUCCESS = 'UPDATE_GROUP_CATEGORY_SUCCESS';
export const UPDATE_GROUP_CATEGORY_FAILED = 'UPDATE_GROUP_CATEGORY_FAILED';

export const DELETE_GROUP_CATEGORY_REQUEST = 'DELETE_GROUP_CATEGORY_REQUEST';
export const DELETE_GROUP_CATEGORY_SUCCESS = 'DELETE_GROUP_CATEGORY_SUCCESS';
export const DELETE_GROUP_CATEGORY_FAILED = 'DELETE_GROUP_CATEGORY_FAILED';

export const GET_PERMISSION_REQUEST = 'GET_PERMISSION_REQUEST';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAILED = 'GET_PERMISSION_FAILED';

export const UPDATE_PERMISSION_REQUEST = 'UPDATE_PERMISSION_REQUEST';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAILED = 'UPDATE_PERMISSION_FAILED';

export const DELETE_PERMISSION_REQUEST = 'DELETE_PERMISSION_REQUEST';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_FAILED = 'DELETE_PERMISSION_FAILED';

export const UPDATE_GROUP_CATEGORY_REDUX = 'UPDATE_GROUP_CATEGORY_REDUX';
export const UPDATE_PERMISSION_REDUX = 'UPDATE_PERMISSION_REDUX';

export const RESET_GROUP_CATEGORY_STATE = 'RESET_GROUP_CATEGORY_STATE';
