import ErrorBase from "~/utils/ErrorBase";


export class DifferenceWhPartner extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Khác đối tác được chỉ định";
    }
}
export class listProductNotAvailable extends ErrorBase {
    constructor(msg) {
        super()
        this.msg = msg || "Tất cả thiết bị đã được xác nhận";
    }
}

