import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { HERE_MAPS_API_KEY } from '~/config';

export default function DebounceSelectHereMap({ fetchOptions, debounceTimeout = 400, setPosition, ...props }) {
  const apiKey = HERE_MAPS_API_KEY
  const { value,addressDefault, ...rest } = props;
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(addressDefault);

  // Handle get Coordinates of order address

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      setOptions([]);
      setFetching(true);
      const getCoordinates = async (address ) => {
        fetch(`https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(address)}&apiKey=${apiKey}`)
        .then((response) => response.json())
          .then((data) => {
          if (data.items && data.items.length > 0) {
            const firstItem = data.items[0];
            const latitude = firstItem.position.lat;
            const longitude = firstItem.position.lng;
            setPosition({lat: latitude, lng: longitude})
            const Item = data?.items?.map((item) => ({
              label: item?.title,
              value: item?.title
            }));
            setOptions(Item);
            setFetching(false);
          } else {
            console.error('Không tìm thấy địa điểm.');
          }
        })
      };
      value?.length > 0 && getCoordinates(value);
    };
    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout, setPosition]);

  const newOption = useMemo(() => {
    let option = options;
    if (options?.length === 0 && addressDefault) {
      option = [{
        label: addressDefault,
        value: addressDefault,
      }];
    };
    return option
  }, [addressDefault, options]);
  return (
    <Select
      defaultValue={addressDefault}
      showSearch
      labelInValue = {false}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      // onChange={(newValue) => setSelectedValue(newValue)}
      // options={newOption}
    >
      {newOption?.map((item) => (
        <Option value={item?.value} key={item?.value}>{item?.label}</Option>
      ))}
    </Select>
  );
}
