export const GET_CONFIRM_VOUCHER_WAREHOUSES_REQUEST = 'GET_CONFIRM_VOUCHER_WAREHOUSES_REQUEST';
export const GET_CONFIRM_VOUCHER_WAREHOUSES_SUCCESS = 'GET_CONFIRM_VOUCHER_WAREHOUSES_SUCCESS';
export const GET_CONFIRM_VOUCHER_WAREHOUSES_FAILED = 'GET_CONFIRM_VOUCHER_WAREHOUSES_FAILED';

export const GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_REQUEST = 'GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_REQUEST';
export const GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_SUCCESS = 'GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_SUCCESS';
export const GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_FAILED = 'GET_CONFIRM_VOUCHER_WAREHOUSES_WH_PARTNER_FAILED';

export const GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_REQUEST = 'GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_REQUEST';
export const GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_SUCCESS = 'GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_SUCCESS';
export const GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_FAILED = 'GET_CONFIRM_VOUCHER_WAREHOUSE_WH_PARTNER_FAILED';

export const GET_CONFIRM_VOUCHER_WAREHOUSE_REQUEST = 'GET_CONFIRM_VOUCHER_WAREHOUSE_REQUEST';
export const GET_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS = 'GET_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS';
export const GET_CONFIRM_VOUCHER_WAREHOUSE_FAILED = 'GET_CONFIRM_VOUCHER_WAREHOUSE_FAILED';

export const CREATE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST = 'CREATE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST';
export const CREATE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS = 'CREATE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS';
export const CREATE_CONFIRM_VOUCHER_WAREHOUSE_FAILED = 'CREATE_CONFIRM_VOUCHER_WAREHOUSE_FAILED';

export const UPDATE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST = 'UPDATE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST';
export const UPDATE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS = 'UPDATE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS';
export const UPDATE_CONFIRM_VOUCHER_WAREHOUSE_FAILED = 'UPDATE_CONFIRM_VOUCHER_WAREHOUSE_FAILED';

export const DELETE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST = 'DELETE_CONFIRM_VOUCHER_WAREHOUSE_REQUEST';
export const DELETE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS = 'DELETE_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS';
export const DELETE_CONFIRM_VOUCHER_WAREHOUSE_FAILED = 'DELETE_CONFIRM_VOUCHER_WAREHOUSE_FAILED';

export const CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_REQUEST = 'CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_REQUEST';
export const CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS = 'CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS';
export const CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_FAILED = 'CHANGE_STATUS_CONFIRM_VOUCHER_WAREHOUSE_FAILED';

export const CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_REQUEST = 'CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_REQUEST';
export const CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS = 'CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS';
export const CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_FAILED = 'CONFIRM_CONFIRM_VOUCHER_WAREHOUSE_FAILED';

export const CANCEL_CONFIRM_VOUCHER_WAREHOUSE_REQUEST = 'CANCEL_CONFIRM_VOUCHER_WAREHOUSE_REQUEST';
export const CANCEL_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS = 'CANCEL_CONFIRM_VOUCHER_WAREHOUSE_SUCCESS';
export const CANCEL_CONFIRM_VOUCHER_WAREHOUSE_FAILED = 'CANCEL_CONFIRM_VOUCHER_WAREHOUSE_FAILED';

export const RESET_CONFIRM_VOUCHER_WAREHOUSE_STATE = 'RESET_CONFIRM_VOUCHER_WAREHOUSE_STATE';
export const RESET_ACTION_CONFIRM_VOUCHER_WAREHOUSE_STATE = 'RESET_ACTION_CONFIRM_VOUCHER_WAREHOUSE_STATE';
