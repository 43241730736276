import produce from 'immer';
import * as Types from '../../constants/actionTypes';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_WH_PACKAGE_LEVELS_REQUEST:
      state.isLoading = true;
      state.list = [];
      state.getListFailed = null;
      return;

    case Types.GET_WH_PACKAGE_LEVELS_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs
        .map(item => ({ ...item, id: item?._id }))
        .sort((a, b) => a?.level < b?.level ? -1 : 1);
      return;

    case Types.GET_WH_PACKAGE_LEVELS_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      return;

    case Types.GET_WH_PACKAGE_LEVEL_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_WH_PACKAGE_LEVEL_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_WH_PACKAGE_LEVEL_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_WH_PACKAGE_LEVEL_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_WH_PACKAGE_LEVEL_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_WH_PACKAGE_LEVEL_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.UPDATE_WH_PACKAGE_LEVEL_REQUEST:
      state.isSubmitLoading = true;
      state.byId = payload;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_WH_PACKAGE_LEVEL_SUCCESS:
      console.log("reducer...");
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      state.list = state.list.map(item => {
        if (item._id === payload._id) {
          return {...item, ...payload};
        }
        return item
      })
      return;

    case Types.UPDATE_WH_PACKAGE_LEVEL_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case Types.DELETE_WH_PACKAGE_LEVEL_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_WH_PACKAGE_LEVEL_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_WH_PACKAGE_LEVEL_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    // case Types.RESET_WH_PACKAGE_LEVEL_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
