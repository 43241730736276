import { get, isNil, transform } from "lodash"
import { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import {
  useFailed,
  useFetch,
  useQueryParams,
  useSubmit,
  useSuccess
} from '~/hooks/utils';
import { useSelector } from 'react-redux';
import { getHighlights, updateHighlight } from "~/redux/whHighlight/actions";
const getSelector = (key) => (state) => state.highlight[key];

const loadingSelector = getSelector('isLoading');
const listSelector = getSelector('list');
const getListFailedSelector = getSelector('getListFailed');

const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');

const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');


export const useWhHighlight = () => {
  return useFetch({
    action: getHighlights,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector
  });
};

export const useConvertHighlight=(data)=>{
  const [pause, setPause] = useState(true);
  const [_vSetting, setVSetting] = useState({});
  let result =  useMemo(()=>{
    if(Object.values({...data}).length){
        let {v0,...newData} = data
        return {v0,...newData}
    }
    //Init value
  return {
    v0: {
      "keyId": "v0",
      "shortTitle": "",
      "image": "",
      "title": "",
      "detail": ""
    },
    v1: [],
    v2: [],
    v3: [],
    vSetting:{
      "v0": {
          "size": 100
      },
      "v1": {
          "size": 100,
          "duration": 0
      },
      "v2": {
          "size": 100,
          "duration": 0
      },
      "v3": {
          "size": 100,
          "duration": 0
      }
  }
 }
  },[data])
  useEffect(()=>{
    setVSetting(data?.vSetting)
  },[data.vSetting])
  
  return [result,{..._vSetting,pause},setPause,setVSetting]
}
export const useUpdateWhBanner = () => {
  useSuccess(updateSuccessSelector, 'Cập nhật thành công');
  useFailed(updateFailedSelector, 'Cập nhật thất bại');
  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updateHighlight
  });
};


export const initSelectHighlight =(data,language)=>{
    let data_ =  transform( data , (result,value,key)=>{
      if(['v1','v2','v3',].includes(key)){
        result.push({
                label:key, 
                options : (value??[])?.map((e)=>({label: get(e.shortTitle,language,e.shortTitle), value : e.keyId })) ??[]
            })
      }},[])
    return data_
}

export const useStyleAction =({vSetting})=>{
    const styleAction = useCallback(v=>{
        return {
            '--action': `${!vSetting.pause ? get(vSetting?.[v] ,'duration',0 )  : 0 }s`,
            '--size': `${vSetting?.[v]?.size}px`
        }
    },[vSetting])
    return[styleAction]
}
export const useGetRotateFirst =(data)=>{
    const rotateFirst= useCallback((v)=>{
         return  get(data,[v],[]).reduce((a, b, i) => (a >= b.rotate || i === 0) ? b.rotate : a, 0) ?? 0
    },[data])
    return[rotateFirst]
}
export const useCalculatorRotate =(data,vSetting)=>{
    const calculator = useCallback((v = 'v1') => {
        let rotateEnd = get(data,[v],[]).reduce((a, b, i) => (a <= b.rotate || i === 0) ? b.rotate : a, 0)
        let rotateFirst = get(data,[v],[]).reduce((a, b, i) => (a >= b.rotate || i === 0) ? b.rotate : a, 0)

        return (2 * 3.14 * ((vSetting)?.[v]?.size / 2) - 10 - 2 * 3.14 * ((vSetting)?.[v]?.size / 2) * (rotateEnd / 360) + 2 * 3.14 * ((vSetting)?.[v]?.size / 2) * (rotateFirst / 360))
    }, [ data,vSetting])
    return [calculator]
}
