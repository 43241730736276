import requester from './requester';

const Adv = {
  getById: (id) => requester.get(`/adv/${id}`),
  create: (point) => requester.post(`/adv`, point),
  update:(point) => requester.put(`/adv/${point._id}`,point),
  getAll : (query) => requester.get(`/adv`,query),
  delete: (id) => requester.delete(`/adv/${id}`),
  
};

export default Adv;
