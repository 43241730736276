import React from 'react';

import { Col, Row, Space } from 'antd';
import { get } from 'lodash';

import { LANGUAGE, TIME_UNITS, VAT } from '~/constants/defaultValue';
import { floorFormatter, formatter, humanize } from '~/utils/helper';

const DataRow = ({ label, value, language = LANGUAGE.VI }) => {
  return (
    <Row>
      <Space>
        <p>{label}: </p>
        <p>{value}</p>
      </Space>
    </Row>
  )
}

const WhServiceSessionView = ({ className, session, }) => {
  const { duration, extraDuration, extraPrice, extraTimeUnit, minimumQuantity, price, timeUnit } = session;

  return (
    <div className={className}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row gutter={48} justify="start">
          <Col sm={24} lg={12}>
            <DataRow
              label="Thời gian thực hiện (phút)"
              value={`${duration} ${timeUnit}`}
            />
          </Col>
          <Col sm={24} lg={12}>
            <DataRow
              label="Số lần tối thiểu"
              value={minimumQuantity}
            />
          </Col>
        </Row>

        <Row gutter={48} justify="start">
          <Col sm={24} lg={12}>
            <DataRow
              label="Đơn giá (chưa VAT)"
              value={formatter(price)}
            />
          </Col>
          <Col sm={24} lg={12}>
            <DataRow
              label="Đơn giá (đã VAT)"
              value={floorFormatter(price * (1 + VAT))}
            />
          </Col>
        </Row>

        <Row gutter={48} justify="start">
          <Col sm={24} lg={24}>
            <DataRow
              label="Block"
              value={price && minimumQuantity && timeUnit && `
                ${formatter(price * minimumQuantity)} / ${humanize(timeUnit, duration * minimumQuantity)}
              `}
            />
          </Col>
        </Row>

        <Row gutter={48} justify="start">
          <Col sm={24} lg={24}>
            <h5>Thời gian tăng thêm mỗi buổi</h5>
          </Col>
        </Row>

        <Row gutter={48} justify="start">
          <Col sm={24} lg={12}>
            <DataRow
              label="Thời gian"
              value={`${extraDuration ? extraDuration : ''} ${extraTimeUnit ? extraTimeUnit : ''}`}
            />
          </Col>
          <Col sm={24} lg={12}>
            <DataRow
              label="Số tiền"
              value={ extraPrice ? (formatter(extraPrice)) : ''}
            />
          </Col>
        </Row>
      </Space>
    </div>
  )
}

export default WhServiceSessionView;
