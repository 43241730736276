import produce from 'immer';
import {
  GET_USER_EMPLOYEES_REQUEST,
  GET_USER_EMPLOYEES_SUCCESS,
  GET_USER_EMPLOYEES_FAILED,
  GET_USER_EMPLOYEE_REQUEST,
  GET_USER_EMPLOYEE_SUCCESS,
  GET_USER_EMPLOYEE_FAILED,

  GET_PROFILE_ME_REQUEST,
  GET_PROFILE_ME_SUCCESS,
  GET_PROFILE_ME_FAILED,
  
  CREATE_USER_EMPLOYEE_REQUEST,
  CREATE_USER_EMPLOYEE_SUCCESS,
  CREATE_USER_EMPLOYEE_FAILED,
  DELETE_USER_EMPLOYEE_REQUEST,
  DELETE_USER_EMPLOYEE_SUCCESS,
  DELETE_USER_EMPLOYEE_FAILED,
  UPDATE_USER_EMPLOYEE_REQUEST,
  UPDATE_USER_EMPLOYEE_SUCCESS,
  UPDATE_USER_EMPLOYEE_FAILED,
  RESET_USER_EMPLOYEE_STATE,
  RESET_STORE,
  RESET_ACTION_USER_EMPLOYEE_STATE,
  GET_ADDRESS_USER_REQUEST,
  GET_ADDRESS_USER_SUCCESS,
  GET_ADDRESS_USER_FAILED,
  
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED
} from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,
  profileMe:null,
  isGetProfileMeLoading: false,
  getProfileMeFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  updateSuccess: null,
  updateFailed: null,

  paging: null,

  isGetAddressByIdLoading: false,
  byIdAddress: [],
  getByIdAddressFailed: null,
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case GET_USER_EMPLOYEES_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case GET_USER_EMPLOYEES_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case GET_USER_EMPLOYEES_FAILED:
      state.isLoading = false;
      state.getListFailed = null;
      return;

    case GET_USER_EMPLOYEE_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case GET_USER_EMPLOYEE_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case GET_USER_EMPLOYEE_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

      case GET_ADDRESS_USER_REQUEST:
        state.isGetAddressByIdLoading = true;
        state.byIdAddress = null;
        state.getByIdAddressFailed = null;
        return;
  
      case GET_ADDRESS_USER_SUCCESS:
      state.isGetAddressByIdLoading = false;
        state.byIdAddress = payload;
        return;
  
      case GET_ADDRESS_USER_FAILED:
        state.isGetAddressByIdLoading = false;
        state.getByIdAddressFailed = payload;
      return;
    
    case DELETE_USER_EMPLOYEE_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case DELETE_USER_EMPLOYEE_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case DELETE_USER_EMPLOYEE_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case CREATE_USER_EMPLOYEE_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case CREATE_USER_EMPLOYEE_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case CREATE_USER_EMPLOYEE_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case UPDATE_USER_EMPLOYEE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_PROFILE_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case UPDATE_USER_EMPLOYEE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      return;
    case UPDATE_PROFILE_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      return;

    case UPDATE_USER_EMPLOYEE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    case UPDATE_PROFILE_FAILED:
      state.isSubmitLoading = false;
      state.updateFailed = payload;
      return;

    // PROFILE_ME
    case GET_PROFILE_ME_REQUEST:
      state.isGetProfileMeLoading = true;
      state.getProfileMeFailed = null;
      return;
    case GET_PROFILE_ME_SUCCESS:
      state.isGetProfileMeLoading = false;
      state.profileMe = payload;
      return;

    case GET_PROFILE_ME_FAILED:
      state.isGetProfileMeLoading = false;
      state.getProfileMeFailed = payload;
      return;
      
    case RESET_ACTION_USER_EMPLOYEE_STATE:
      state.byId = null;
      state.createSuccess = null;
      state.updateSuccess = null;
      state.deleteSuccess = null;
      return;
      
    case RESET_USER_EMPLOYEE_STATE:
    case RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
