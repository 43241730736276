export const WH_RATE_VALUE = {
    LOVE: 0.5,
    LIKE: 0.4,
    ACCEPTABLE: 0.3,
    SAD: 0.2,
    ANGRY: 0.1
  }
export const WH_RATE = {
    LOVE: "LOVE",
    LIKE:"LIKE",
    ACCEPTABLE:"ACCEPTABLE",
    SAD:"SAD",
    ANGRY: "ANGRY"
  }