export const GET_SYSTEMS_REQUEST = 'GET_SYSTEMS_REQUEST';
export const GET_SYSTEMS_SUCCESS = 'GET_SYSTEMS_SUCCESS';
export const GET_SYSTEMS_FAILED = 'GET_SYSTEMS_FAILED';

export const GET_SYSTEM_REQUEST = 'GET_SYSTEM_REQUEST';
export const GET_SYSTEM_SUCCESS = 'GET_SYSTEM_SUCCESS';
export const GET_SYSTEM_FAILED = 'GET_SYSTEM_FAILED';


export const UPDATE_SYSTEM_REQUEST = 'UPDATE_SYSTEM_REQUEST';
export const UPDATE_SYSTEM_SUCCESS = 'UPDATE_SYSTEM_SUCCESS';
export const UPDATE_SYSTEM_FAILED = 'UPDATE_SYSTEM_FAILED';


export const RESET_SYSTEM_STATE = 'RESET_SYSTEM_STATE';

