import produce from 'immer';
import { isArray, isObject } from 'lodash';
import * as Types from '~/constants/actionTypes';
import getPaging from '~/utils/getPaging';

const initState = {
  isLoading: false,
  getListFailed: undefined,
  list: [],

  isGetByIdLoading: false,
  byId: null,
  getByIdFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  deleteBranchSuccess: null,
  deleteBranchFailed: null,

  isSubmitLoading: false,
  createSuccess: null,
  createFailed: null,

  isSubmitBranchLoading: false,
  createBranchSuccess: null,
  createBranchFailed: null,

  updateSuccess: null,
  updateFailed: null,

  updateBranchStatusSuccess: null,
  updateBranchStatusFailed: null,

  updateBranchSuccess: null,
  updateBranchFailed: null,

  branches: null,
  isGetBranchesLoading: false,
  getBranchesFailed: null,

  getListAccessPermissionPartner : null,
  loadingGetListAccessPermissionPartner : false,
  getListAccessPermissionPartnerFailed : null,

  accessPermissionPartnerSuccess: null,
  accessPermissionPartnerFailed: null,

  denyPermissionPartnerSuccess: null,
  denyPermissionPartnerFailed: null,

  resourcePartner : null,
  loadingGetResourcePartner : false,
  getResourcePartnerFailed : null,

  getListAccessPermissionClinic : null,
  loadingGetListAccessPermissionClinic : false,
  getListAccessPermissionClinicFailed : null,

  accessPermissionClinicSuccess: null,
  accessPermissionClinicFailed: null,

  denyPermissionClinicSuccess: null,
  denyPermissionClinicFailed: null,

  resourceClinic : null,
  loadingGetResourceClinic : false,
  getResourceClinicFailed : null,

  paging: null
};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_PARTNERS_REQUEST:
      state.isLoading = true;
      state.getListFailed = null;
      return;

    case Types.GET_PARTNERS_SUCCESS:
      state.isLoading = false;
      state.list = payload.docs;
      state.paging = getPaging(payload);
      return;

    case Types.GET_PARTNERS_FAILED:
      state.isLoading = false;
      state.getListFailed = payload;
      state.list = [];
      return;

    case Types.GET_PARTNER_REQUEST:
      state.isGetByIdLoading = true;
      state.byId = null;
      state.getByIdFailed = null;
      return;

    case Types.GET_PARTNER_SUCCESS:
      state.isGetByIdLoading = false;
      state.byId = payload;
      return;

    case Types.GET_PARTNER_FAILED:
      state.isGetByIdLoading = false;
      state.getByIdFailed = payload;
      return;

    case Types.CREATE_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;

    case Types.CREATE_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;

    case Types.CREATE_BRANCH_IN_PARTNER_REQUEST:
      state.isSubmitBranchLoading = true;
      state.createBranchSuccess = null;
      state.createBranchFailed = null;
      return;

    case Types.CREATE_BRANCH_IN_PARTNER_SUCCESS:
      state.isSubmitBranchLoading = false;
      state.createBranchSuccess = payload;
      return;

    case Types.CREATE_BRANCH_IN_PARTNER_FAILED:
      state.isSubmitBranchLoading = false;
      state.createBranchFailed = payload;
      return;

    case Types.UPDATE_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.updateSuccess = null;
      state.updateFailed = null;
      return;

    case Types.UPDATE_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.updateSuccess = payload;
      state.byId = payload;
      return;

    case Types.UPDATE_PARTNER_FAILED:
      state.updateBranchFailed = payload;
      return;

    case Types.UPDATE_BRANCH_IN_PARTNER_REQUEST:
      state.updateBranchSuccess = null;
      state.updateBranchFailed = null;
      return;

    case Types.UPDATE_BRANCH_IN_PARTNER_SUCCESS:
      state.updateBranchSuccess = payload;
      state.list = state.list.map(item => {
        if (item._id === payload._id) {
          return { ...item, ...payload };
        }
        return item
      })
      return;
    case Types.UPDATE_BRANCH_IN_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.updateBranchFailed = payload;
      return;

    case Types.UPDATE_BRANCH_STATUS_REQUEST:
      state.updateBranchStatusSuccess = null;
      state.updateBranchStatusFailed = null;
      return;

    case Types.UPDATE_BRANCH_STATUS_SUCCESS:
      state.updateBranchStatusSuccess = payload;
      state.branches = !isArray(payload) ? (console.log(1), state?.branches?.map(item => {
        if (item?._id === payload?._id) {
          return { ...item, ...payload };
        }
        return item
      })) : ([...payload])
      return

    case Types.UPDATE_BRANCH_STATUS_FAILED:
      state.updateBranchStatusFailed = payload;
      return;

    case Types.DELETE_PARTNER_REQUEST:
      state.isLoading = true;
      state.deleteSuccess = null;
      state.deleteFailed = null;
      return;

    case Types.DELETE_PARTNER_SUCCESS:
      state.deleteSuccess = payload;
      return;

    case Types.DELETE_PARTNER_FAILED:
      state.isLoading = false;
      state.deleteFailed = payload;
      return;

    case Types.DELETE_BRANCH_IN_PARTNER_REQUEST:
      state.isLoading = true;
      state.deleteBranchSuccess = null;
      state.deleteBranchFailed = null;
      return;

    case Types.DELETE_BRANCH_IN_PARTNER_SUCCESS:
      state.deleteBranchSuccess = payload;
      return;

    case Types.DELETE_BRANCH_IN_PARTNER_FAILED:
      state.isLoading = false;
      state.deleteBranchFailed = payload;
      return;

    case Types.GET_PARTNER_BRANCHES_REQUEST:
      state.isGetBranchesLoading = true;
      state.getBranchesFailed = null;
      return;

    case Types.GET_PARTNER_BRANCHES_SUCCESS:
      state.isGetBranchesLoading = false;
      state.branches = payload;
      return;

    case Types.GET_PARTNER_BRANCHES_FAILED:
      state.isGetBranchesLoading = false;
      state.getBranchesFailed = payload;
      return;

    case Types.GET_RESOURCE_PARTNER_REQUEST:
      state.loadingGetResourcePartner = true;
      state.getResourcePartnerFailed = null;
      return;

    case Types.GET_RESOURCE_PARTNER_SUCCESS:
      state.loadingGetResourcePartner = false;
      state.resourcePartner = payload;
      return;

    case Types.GET_RESOURCE_PARTNER_FAILED:
      state.loadingGetResourcePartner = false;
      state.getResourcePartnerFailed = payload;
      return;

    case Types.GET_LIST_ACCESS_PERMISSION_PARTNER_REQUEST:
      state.loadingGetListAccessPermissionPartner = true;
      state.getListAccessPermissionPartnerFailed = null;
      return;

    case Types.GET_LIST_ACCESS_PERMISSION_PARTNER_SUCCESS:
      state.loadingGetListAccessPermissionPartner = false;
      state.getListAccessPermissionPartner = payload;
      return;

    case Types.GET_LIST_ACCESS_PERMISSION_PARTNER_FAILED:
      state.loadingGetListAccessPermissionPartner = false;
      state.getListAccessPermissionPartnerFailed = payload;
      return;

    case Types.ACCESS_PERMISSION_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.accessPermissionPartnerSuccess = null;
      state.accessPermissionPartnerFailed = null;
      return;

    case Types.ACCESS_PERMISSION_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.accessPermissionPartnerSuccess = payload;
      return;

    case Types.ACCESS_PERMISSION_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.accessPermissionPartnerFailed = payload;
      return;

    case Types.DENY_PERMISSION_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.denyPermissionPartnerSuccess = null;
      state.denyPermissionPartnerFailed = null;
      return;

    case Types.DENY_PERMISSION_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.denyPermissionPartnerSuccess = payload;
      return;

    case Types.DENY_PERMISSION_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.denyPermissionPartnerFailed = payload;
      return;


    case Types.GET_RESOURCE_CLINIC_REQUEST:
      state.loadingGetResourceClinic = true;
      state.getResourceClinicFailed = null;
      return;

    case Types.GET_RESOURCE_CLINIC_SUCCESS:
      state.loadingGetResourceClinic = false;
      state.resourceClinic = payload;
      return;

    case Types.GET_RESOURCE_CLINIC_FAILED:
      state.loadingGetResourceClinic = false;
      state.getResourceClinicFailed = payload;
      return;

    case Types.GET_LIST_ACCESS_PERMISSION_CLINIC_REQUEST:
      state.loadingGetListAccessPermissionClinic = true;
      state.getListAccessPermissionClinicFailed = null;
      return;

    case Types.GET_LIST_ACCESS_PERMISSION_CLINIC_SUCCESS:
      state.loadingGetListAccessPermissionClinic = false;
      state.getListAccessPermissionClinic = payload;
      return;

    case Types.GET_LIST_ACCESS_PERMISSION_CLINIC_FAILED:
      state.loadingGetListAccessPermissionClinic = false;
      state.getListAccessPermissionClinicFailed = payload;
      return;

    case Types.ACCESS_PERMISSION_CLINIC_REQUEST:
      state.isSubmitLoading = true;
      state.accessPermissionClinicSuccess = null;
      state.accessPermissionClinicFailed = null;
      return;

    case Types.ACCESS_PERMISSION_CLINIC_SUCCESS:
      state.isSubmitLoading = false;
      state.accessPermissionClinicSuccess = payload;
      return;

    case Types.ACCESS_PERMISSION_CLINIC_FAILED:
      state.isSubmitLoading = false;
      state.accessPermissionClinicFailed = payload;
      return;

    case Types.DENY_PERMISSION_CLINIC_REQUEST:
      state.isSubmitLoading = true;
      state.denyPermissionClinicSuccess = null;
      state.denyPermissionClinicFailed = null;
      return;

    case Types.DENY_PERMISSION_CLINIC_SUCCESS:
      state.isSubmitLoading = false;
      state.denyPermissionClinicSuccess = payload;
      return;

    case Types.DENY_PERMISSION_CLINIC_FAILED:
      state.isSubmitLoading = false;
      state.denyPermissionClinicFailed = payload;
      return;


    case Types.RESET_PARTNER_STATE:
    case Types.RESET_STORE:
      return initState;

    default:
      return;
  }
}, initState);
