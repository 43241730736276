import * as Types from '../../constants/actionTypes';
  
  export const getJobQuestions = query => ({
    type: Types.GET_JOB_QUESTIONS_REQUEST,
    payload: query
  });
  export const getJobQuestion = id => ({
    type: Types.GET_JOB_QUESTION_REQUEST,
    payload: id
  });
  export const createJobQuestion = instance => ({
    type: Types.CREATE_JOB_QUESTION_REQUEST,
    payload: instance
  });
  export const updateJobQuestion = instance => ({
    type: Types.UPDATE_JOB_QUESTION_REQUEST,
    payload: instance
  });
  export const deleteJobQuestion = id => ({
    type: Types.DELETE_JOB_QUESTION_REQUEST,
    payload: id
  });
  export const resetJobQuestionState = () => ({
    type: Types.RESET_JOB_QUESTION_STATE
  });
