export const GET_HOSPITALS_REQUEST = 'GET_HOSPITALS_REQUEST';
export const GET_HOSPITALS_SUCCESS = 'GET_HOSPITALS_SUCCESS';
export const GET_HOSPITALS_FAILED = 'GET_HOSPITALS_FAILED';

export const DELETE_HOSPITAL_REQUEST = 'DELETE_HOSPITAL_REQUEST';
export const DELETE_HOSPITAL_SUCCESS = 'DELETE_HOSPITAL_SUCCESS';
export const DELETE_HOSPITAL_FAILED = 'DELETE_HOSPITAL_FAILED';

export const CREATE_HOSPITAL_REQUEST = 'CREATE_HOSPITAL_REQUEST';
export const CREATE_HOSPITAL_SUCCESS = 'CREATE_HOSPITAL_SUCCESS';
export const CREATE_HOSPITAL_FAILED = 'CREATE_HOSPITAL_FAILED';

export const UPDATE_HOSPITAL_REQUEST = 'UPDATE_HOSPITAL_REQUEST';
export const UPDATE_HOSPITAL_SUCCESS = 'UPDATE_HOSPITAL_SUCCESS';
export const UPDATE_HOSPITAL_FAILED = 'UPDATE_HOSPITAL_FAILED';

export const GET_HOSPITAL_REQUEST = 'GET_HOSPITAL_REQUEST';
export const GET_HOSPITAL_SUCCESS = 'GET_HOSPITAL_SUCCESS';
export const GET_HOSPITAL_FAILED = 'GET_HOSPITAL_FAILED';

export const GET_SERVICE_HOSPITAL_REQUEST = 'GET_SERVICE_HOSPITAL_REQUEST';
export const GET_SERVICE_HOSPITAL_SUCCESS = 'GET_SERVICE_HOSPITAL_SUCCESS';
export const GET_SERVICE_HOSPITAL_FAILED = 'GET_SERVICE_HOSPITAL_FAILED';

export const GET_ALL_HOSPITALS_REQUEST = 'GET_ALL_HOSPITALS_REQUEST';
export const GET_ALL_HOSPITALS_SUCCESS = 'GET_ALL_HOSPITALS_SUCCESS';
export const GET_ALL_HOSPITALS_FAILED = 'GET_ALL_HOSPITALS_FAILED';

export const GET_HOSPITAL_AVAILABLE_TIME_REQUEST =
  'GET_HOSPITAL_AVAILABLE_TIME_REQUEST';
export const GET_HOSPITAL_AVAILABLE_TIME_SUCCESS =
  'GET_HOSPITAL_AVAILABLE_TIME_SUCCESS';
export const GET_HOSPITAL_AVAILABLE_TIME_FAILED = 'GET_HOSPITAL_TIMES_FAILED';

export const RESET_HOSPITAL_STATE = 'RESET_HOSPITAL_STATE';
