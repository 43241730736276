import requester from './requester';

const evaluate = {
  getAll: query => requester.get('/rate', query),
  getAllRating: () => requester.get('/rate'),
  getById: id => {
    return requester.get(`/rate/${id}?raw=true`)
  },
  delete: id => requester.delete(`/rate/${id}`),
  create: evaluate => requester.post('/rate', evaluate),
  update: evaluate =>
    requester.put(`/rate/${evaluate.id}`, evaluate)
};

export default evaluate;
