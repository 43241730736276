import {
  GET_USER_EMPLOYEES_REQUEST,
  GET_USER_EMPLOYEE_REQUEST,
  CREATE_USER_EMPLOYEE_REQUEST,
  UPDATE_USER_EMPLOYEE_REQUEST,
  DELETE_USER_EMPLOYEE_REQUEST,
  RESET_USER_EMPLOYEE_STATE,
  RESET_ACTION_USER_EMPLOYEE_STATE,
  GET_ADDRESS_USER_REQUEST,
  UPDATE_PROFILE_REQUEST,
  GET_PROFILE_ME_REQUEST,
} from '~/constants/actionTypes';

export const getUserEmployees = (query) => ({
  type: GET_USER_EMPLOYEES_REQUEST,
  payload: query
});

export const getUserEmployee = (id) => ({
  type: GET_USER_EMPLOYEE_REQUEST,
  payload: id
});
export const getProfileMe = () => ({
  type: GET_PROFILE_ME_REQUEST,
});

export const createUserEmployee = (userEmployee) => ({
  type: CREATE_USER_EMPLOYEE_REQUEST,
  payload: userEmployee
});

export const updateUserEmployee = (userEmployee) => ({
  type: UPDATE_USER_EMPLOYEE_REQUEST,
  payload: userEmployee
});

export const deleteUserEmployee = (id) => ({
  type: DELETE_USER_EMPLOYEE_REQUEST,
  payload: id
});

export const resetUserEmployeeState = () => ({
  type: RESET_USER_EMPLOYEE_STATE
});

export const resetActionUserEmployeeState = () => ({
  type: RESET_ACTION_USER_EMPLOYEE_STATE
});

export const getAddressUser = ({id}) => ({
  type: GET_ADDRESS_USER_REQUEST,
  payload: id
});

export const updateProfile = (payload) => ({
  type: UPDATE_PROFILE_REQUEST,
  payload
});
