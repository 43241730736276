import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

import getPaging from '../../utils/getPaging';

const initState = {
  isLoading: false,
  getPercentChangeCustomerToPartnersFailed: null,
  getPercentChangeCustomerToPartnerFailed:null,
  PercentChangeCustomerToPartners: null,
  PercentChangeCustomerToPartner: null,
  isSubmitLoading : false,
  isGetPercentChangeCustomerToPartnerLoading: false,
  PercentChangeCustomerToPartner: null,

  createSuccess: null,
  createFailed: null,

  deleteSuccess: null,
  deleteFailed: null,

  updateSuccess: null,
  updateFailed: null,
  paging: null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_REQUEST:
      state.isLoading = true;
      state.getPercentChangeCustomerToPartnersFailed = null;
      return;

    case Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_SUCCESS:
      state.isLoading = false;
      state.PercentChangeCustomerToPartners = payload;
      state.getPercentChangeCustomerToPartnersFailed = null;
      return;

    case Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNERS_FAILED:
      state.isLoading = false;
      state.PercentChangeCustomerToPartners = [];
      state.getPercentChangeCustomerToPartnersFailed = payload;
      return;
    case Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST:
      state.isGetPercentChangeCustomerToPartnerLoading = true;
      state.PercentChangeCustomerToPartner = null
      state.getPercentChangeCustomerToPartnerFailed = null;
      return;

    case Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS:
      state.isGetPercentChangeCustomerToPartnerLoading = false;
      state.PercentChangeCustomerToPartner = payload;
      state.getPercentChangeCustomerToPartnerFailed = null;
      return;

    case Types.GET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED:
      state.isGetPercentChangeCustomerToPartnerLoading = false;
      state.PercentChangeCustomerToPartner = null;
      state.getPercentChangeCustomerToPartnerFailed = payload;
      return;
    case Types.CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST:
      state.isSubmitLoading = true;
      state.createSuccess = null;
      state.createFailed = null;
      return;
    case Types.CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS:
      state.isSubmitLoading = false;
      state.createSuccess = payload;
      return;

    case Types.CREATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED:
      state.isSubmitLoading = false;
      state.createFailed = payload;
      return;
      case Types.UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.PercentChangeCustomerToPartner = get(payload,'data');
        return;
  
      case Types.UPDATE_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_PERCENT_CHANGE_CUSTOMER_TO_PARTNER_STATE:
      return initState;

    default:
      return;
  }
}, initState);
