import { forIn, get, groupBy, keys, omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '~/api';
import {
    getSelectors,
    useFailed,
    useFetchByParam,
    useQueryParams,
    useResetState,
    useSubmit,
    useSuccess
} from '~/hooks/utils';
import { createPointSystem, deletePointSystem, getPointSystem, getPointSystems, getPointSystemsPartner, resetPointSystemState, updatePointSystem } from '~/redux/action';
import { PATH_APP } from '~/routes/paths';
import { getExistProp } from '~/utils/helper';
const getSelector = (key) => (state) => state.pointSystem[key];
const pagingSelector = getSelector('paging');
export const usePointSystemPaging = () => useSelector(pagingSelector);

const loadingSelector = getSelector('isLoading');
const loadinggetPointSystemSelector = getSelector('isGetPointSystemLoading');
const getPointSystemsFailedSelector = getSelector('getPointSystemsFailed');
const getPointSystemFailedSelector = getSelector('getPointSystemFailed');
const isSubmitLoadingSelector = getSelector('isSubmitLoading');
const pointSystemsSelector = getSelector('pointSystems');
const pointSystemSelector = getSelector('pointSystem');

const createSuccessSelector = getSelector('createSuccess');
const createFailedSelector = getSelector('createFailed');
const updateSuccessSelector = getSelector('updateSuccess');
const updateFailedSelector = getSelector('updateFailed');
const deleteSuccessSelector = getSelector('deleteSuccess');
const deleteFailedSelector = getSelector('deleteFailed');

const loadingPartnerSelector = getSelector('isLoadingPartner');
const getPointSystemsPartnerFailedSelector = getSelector('getPointSystemsPartnerFailed');
const pointSystemsPartnerSelector = getSelector('pointSystemsPartner');


export const usePointSystemQueryParams = () => {
    const query = useQueryParams();
    const limit = query.get('limit') || 10;
    const page = query.get('page') || 1;
    const types = query.get('types')
    const point = query.get('point')
    const userId = query.get('userId')
    const action = query.get('action')
    const money = query.get('money')
    const desc = query.get('desc')
    const createPointSystemSuccess = useSelector(createSuccessSelector);
    const updatePointSystemSuccess = useSelector(updateSuccessSelector);
    const deletePointSystemSuccess = useSelector(deleteSuccessSelector);
  
    return useMemo(() => {
      const queryParams = {
        page,
        limit,
        types,
        point,
        userId,
        action,
        money,
        desc
      };
      return [queryParams];
      //eslint-disable-next-line
    }, [
      page,
      limit,
      createPointSystemSuccess,
      updatePointSystemSuccess,
      deletePointSystemSuccess,
      types,
      point,
      userId,
      action,
      money,
      desc
    ]);
  };

  export const useUpdatePointSystemParams = (query,listOptionSearch) => {
    const history = useHistory();
    const [keyword, setKeyword] = useState(get(query,'keyword'));
    useEffect(() => {
      setKeyword(get(query,'keyword'));
    },[query.keyword])
    const onParamChange = (param) => {
      const groupByKey = groupBy(listOptionSearch , (e) => get(e,'value'))
      forIn(query,(values,key,obj) =>{
        if(groupByKey[key] && (keys(param)?.some(e => groupByKey[e]))){
          obj[key] = null
        }
      })
      if (!param.page) {
        query.page = 1;
      }
      history.push({
        pathname: get(param, 'customPathName') || PATH_APP.referral.PointSystem,
        search: new URLSearchParams(
          getExistProp({
            ...query,
            ...param
          })
        ).toString()
      });
    };
  
    return [keyword ,{setKeyword, onParamChange }];
  };
  ////////////////////////////////////////////////////////////////

export const usePointSystemPartnerQueryParams = (id,reFetch) => {
  const query = useQueryParams();
  const limit = query.get('limit') || 10;
  const page = query.get('page') || 1;
  const keyword = query.get('keyword')
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      id
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    id,
    reFetch
  ]);
};

export const useUpdatePointSystemPartnerParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(get(query,'keyword'));
  useEffect(() => {
    setKeyword(get(query,'keyword'));
  },[query.keyword])
  const onParamChange = (param) => {
    if (!param.page) {
      query.page = 1;
    }

    const newQuery = omit(query, 'id')
    history.push({
      pathname: get(param, 'customPathName') || '/workspace/wallet',
      search: new URLSearchParams(
        getExistProp({
          ...newQuery,
          ...param
        })
      ).toString()
    });
  };

  return [keyword ,{setKeyword, onParamChange }];
};
  ////////////////////////////////
  export const usePointSystems = (query) => {
    return useFetchByParam({
      action: getPointSystems,
      loadingSelector,
      dataSelector: pointSystemsSelector,
      failedSelector: getPointSystemsFailedSelector,
      param: query
    });
  };
  export const usePointSystemsPartner = (query) => {
    return useFetchByParam({
      action: getPointSystemsPartner,
      loadingSelector:loadingPartnerSelector,
      dataSelector: pointSystemsPartnerSelector,
      failedSelector: getPointSystemsPartnerFailedSelector,
      param: query
    });
  };
  export const usePointSystem = (id) => {
    return useFetchByParam({
      action: getPointSystem,
      loadingSelector:loadinggetPointSystemSelector,
      dataSelector: pointSystemSelector,
      failedSelector: getPointSystemFailedSelector,
      param: id
    });
  };
export const useCreatePointSystem = (callback) => {
    useSuccess(createSuccessSelector, 'Tạo điểm thưởng hệ thống thành công',callback);
    useFailed(createFailedSelector, 'Tạo điểm thưởng hệ thống thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: createPointSystem
    });
  };
export const useUpdatePointSystem = (callback) => {
    useSuccess(updateSuccessSelector, 'Cập nhật điểm thưởng hệ thống thành công',callback);
    useFailed(updateFailedSelector, 'Cập nhật điểm thưởng hệ thống thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: updatePointSystem
    });
  };
export const useDeletePointSystem = (callback) => {
    useSuccess(deleteSuccessSelector, 'Xoá điểm thưởng hệ thống thành công',callback);
    useFailed(deleteFailedSelector, 'Xoá điểm thưởng hệ thống thất bại');
  
    return useSubmit({
      loadingSelector: isSubmitLoadingSelector,
      action: deletePointSystem
    });
  };

  export const useResetPointSystem = () => {
    useResetState(resetPointSystemState);
  };


  export const useGetMyPointCanUse = (dataPoint,maxRefund = 100) => { // confirm partner is useMaxRefund to exchangePoint
    const [data,setData] = useState(null)
    const [loading,setLoading] = useState(false)
    const [reFetch,setReFetch] = useState(false)
    const mutate = () => setReFetch(!reFetch)
    useEffect(() => {
      const fetch = async () => {
        setLoading(true)
        const pointCanUse  =  get(dataPoint,'transactions.credit',0) * maxRefund / 100
        const pointUsed = Math.abs(get(dataPoint,'transactions.debit',0))
        setData({
          totalPoint : get(dataPoint,'transactions.credit',0),
          pointUsed ,
          pointCanUse,
          remainingPointCanUse : pointCanUse - pointUsed
        })
      }
      setLoading(false)
      dataPoint &&  fetch()
    },[dataPoint,maxRefund,reFetch])
    return {
      data,
      isLoading: loading,
      mutate
    };
  }
  export const useGetMyPointCanUseModeFetch = (id,maxRefund) => {
    const [data,setData] = useState(null)
    const [loading,setLoading] = useState(false)
    const [reFetch,setReFetch] = useState(false)
    const mutate = () => setReFetch(!reFetch)
    useEffect(() => {
      const fetch = async () => {
        setLoading(true)
        const dataPoint = await api.pointSystem.getPointSystemCustomerById(id)
          setLoading(false)
            const totalNoCashOut = get(dataPoint, 'listNoCashOut', []).reduce((sum, curr) => get(curr, 'type') === 'credit' ? {...sum,totalCredit :sum.totalCredit + get(curr, 'amount', 0)} : {...sum,totalDebit :sum.totalDebit + Math.abs(get(curr, 'amount', 0))}, {totalDebit : 0,totalCredit : 0})
            const totalCashOut = get(dataPoint, 'listCashOut', []).reduce((sum, curr) => get(curr, 'type') === 'credit' ? sum + get(curr, 'amount', 0) : sum, 0)
            const pointCanUse = get(totalNoCashOut,'totalCredit',0) * maxRefund / 100
            const pointUsed = get(totalNoCashOut,'totalDebit',0)
            const remainingPointCanUse = pointCanUse - pointUsed
            setData({ totalNoCashOut:get(totalNoCashOut,'totalCredit',0), totalCashOut, remainingPointCanUse,pointUsed })
        
      }
      id &&  fetch()
    },[id,maxRefund,reFetch])
    return {
      data,
      isLoading: loading,
      mutate
    };
  }
