import React from 'react'
import { useMatchPolicy } from '~/hooks'
import ToolTipButtonPermission from './ToolTipButtonPermission'
export default function PermissionToolTip({permission,children,textPermission,textButton,type='button',childrenText}) {
    const isMatchPolicy = useMatchPolicy(permission)
  return (
    <>
         {isMatchPolicy ? children : <ToolTipButtonPermission children={childrenText ? childrenText : null} type={type} titleToolTip={textPermission} text={textButton}/>}
    </>
  )
}
