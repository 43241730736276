import requester from './requester';

const IntroUsingRefCode = {
  getById: (id) => requester.get(`/intro-using-ref-code/${id}`),
  create: (config) => requester.post(`/intro-using-ref-code`, config),
  update:(config) => requester.put(`/intro-using-ref-code/${config._id}`,config),
  getAll : (query) => requester.get(`/intro-using-ref-code`,query),
  delete: (id) => requester.delete(`/intro-using-ref-code/${id}`),
  
};

export default IntroUsingRefCode;
