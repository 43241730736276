import React, { useCallback, useEffect } from 'react';
import Breadcrumb from '~/components/Common/Breadcrumb';
import SkeletonTable from '~/components/Utils/SkeletonTable';
// import WhCustomerManagementDetails from '../WhCustomerManagementDetails';
import { FilterDatePicker } from '~/components/Common';
import {
  Table,
  Popconfirm,
  Button,
  Input,
  Modal,
  Switch,
  Form,
  Col,
  Row,
  Mentions
} from 'antd';
import { ORDER_STATUS_NAME } from '~/constants/defaultValue';
import { Card, CardBody, Label } from 'reactstrap';
import { Skeleton } from 'antd';
import './Info.scss';

import {
  useWhCustomersManagement,
  useDeleteWhCustomerManagement,
  useUpdateWhCustomerManagement,
  useWhCustomerManagementQueryParams,
  useCreateWhCustomerManagement,
  useInitWhCustomerManagement
} from '~/hooks/whCustomerManagement';
const FormItem = Form.Item;

const Information = ({ data, whCustomerManagementId, props }) => {
  // console.log(props.data,"history");

  const address = data?.address || {};
  const [form] = Form.useForm();
  return (
    <div className="wh-customer-management page-wraper hospital-form-page-content">
      <div className="container-fluid">
        <Form
          form={form}
          autoComplete="off"
          // onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          labelCol={{ sm: 24, md: 5, lg: 4 }}
          wrapperCol={{ sm: 24, md: 19, lg: 20 }}
        >
          <Row gutter={36}>
            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'customerIdFull']}
              >
                <Label className="label">Mã Khách hàng: </Label>
                <div>{data?.customerIdFull}</div>
              </div>
              <hr/>
            </Col>

            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'fullName']}
              >
                <Label className="label">Tên Khách hàng: </Label>
                <div>{data?.fullName}</div>
              </div>
              <hr/>
            </Col>

            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'address']}
              >
                <Label className="label">Địa chỉ: </Label>
                <div>
                  {`${address.street}, ${address.ward}, ${address.district}, ${address.city}`}
                </div>
              </div>
              <hr/>
            </Col>

            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'fullName']}
              >
                <Label className="label">Loại khách hàng: </Label>
                <div readOnly>
                {/* {data?.fullName} */}
                </div>
              </div>
              <hr/>
            </Col>

            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'customerIdFull']}
              >
                <Label className="label">Điểm thưởng: </Label>
                <div readOnly>
                {/* {data?.customerIdFull} */}
                </div>
              </div>
              <hr/>
            </Col>

            <Col span={12}>
              <div
                className="content-field"
                name={['customer-account', 'phoneNumber']}
              >
                <Label className="label">Mã giới thiệu: </Label>
                <div readOnly>
                {/* {data?.phoneNumber} */}
                </div>
              </div>
              <hr/>
            </Col>
          </Row>
          <h5>Thông tin người giới thiệu</h5>
          <br />
          <Col span={12}>
            <div
              className="content-field"
              name={['customer-account', 'customerIdFull']}
            >
              <Label className="label"> Tên người giới thiệu: </Label>
              <div>
              {/* {data?.customerIdFull} */}
              </div>
            </div>
            <hr/>
          </Col>

          <Col span={12}>
            <div
              className="content-field"
              name={['customer-account', 'phoneNumber']}
            >
              <Label className="label">Mã giới thiệu: </Label>
              <div readOnly>
              {/* {data?.phoneNumber} */}
              </div>
            </div>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default Information;
