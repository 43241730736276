import { get } from "lodash";

import { getExtraQuantityAndExtraDurationAndExtraTimeUnit } from "./utils";

/**
 * Convert an object received from API to the internal object that can work with Antd Form.
 *
 * @param {*} instance
 * @return {*} 
 */
const fromJSON = instance => {

  return {
    ...instance,
    id: get(instance, "_id") || get(instance, "id"),
    extraQuantityAndExtraDurationAndExtraTimeUnit: getExtraQuantityAndExtraDurationAndExtraTimeUnit(instance),
    priceAndVat: get(instance, "price") * (1 + get(instance, "vat")) + get(instance, "dealPrice",0), // Giá ban đầu
    extraPriceAndExtraQuantityAndVat: get(instance, "extraPrice") * get(instance, "extraQuantity") * (1 + get(instance, "vat")), // Giá cộng thêm
  }
}
/**
 * Convert an internal object to the format of API.
 *
 * @param {*} instance
 * @return {*} 
 */
const toJSON = instance => {
  return {
    ...instance,
  }
}

export {
  fromJSON,
  toJSON,
}
