import { useMemo, useState, useRef } from 'react';
import {
  useFailed,
  useSubmit,
  useSuccess,
  useResetState,
  useFetchByParam,
  getSelectors,
  useQueryParams
} from '~/hooks/utils';

import {
  getPartnerships,
  createPartnership,
  deletePartnership,
  getPartnership,
  resetPartnershipState,
  updatePartnership
} from '~/redux/action';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getExistProp } from '~/utils/helper';

const PARTNER_SHIP_MODULE = 'partnership';

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector
} = getSelectors(PARTNER_SHIP_MODULE);

export const usePartnerships = (query) => {
  return useFetchByParam({
    action: getPartnerships,
    loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param: query
  });
};

export const useCreatePartnership = (callback) => {
  useSuccess(createSuccessSelector, 'Tạo mới cơ sở y tế thành công', callback);
  useFailed(createFailedSelector, 'Tạo mới cơ sở y tế thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: createPartnership
  });
};

export const useUpdatePartnership = (callback) => {
  useSuccess(updateSuccessSelector, 'Cập nhật cơ sở y tế thành công', callback);
  useFailed(updateFailedSelector, 'Cập nhật cơ sở y tế thất bại');

  return useSubmit({
    loadingSelector: isSubmitLoadingSelector,
    action: updatePartnership
  });
};

export const useDeletePartnership = (onDeleteSuccess) => {
  useSuccess(deleteSuccessSelector, 'Xoá cơ sở y tế thành công', onDeleteSuccess);
  useFailed(deleteFailedSelector, 'Xoá cơ sở y tế thất bại');

  return useSubmit({
    loadingSelector,
    action: deletePartnership
  });
};

export const usePartnership = (params) => {
  return useFetchByParam({
    action: getPartnership,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: params
  });
};

export const useInitPartnership = (partnership, id) => {
  return useMemo(() => {
    if (!partnership || !id) {
      return {};
    }

    const initCompany = {
      ...partnership
    };

    return initCompany;
  }, [partnership, id]);
};

export const useUpdatePartnershipParams = (query) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(query.keyword);

  const onParamChange = (param) => {
    history.push({
      pathname: '/partnership',
      search: new URLSearchParams(
        getExistProp({
          ...query,
          ...param
        })
      ).toString()
    });
  };

  return [keyword, { setKeyword, onParamChange }];
};

export const usePartnershipQueryParams = () => {
  const prevKeyword = useRef(null);
  const query = useQueryParams();
  // const limit = query.get('limit') || 10;
  const keyword = query.get('keyword');
  const cityId = query.get('cityId');
  // const page = query.get('page');

  const [page, setPage] = useState(query.get('page') || 1);
  const [limit, setLimit] = useState(query.get('limit') || 10);
  const onTableChange = ({ current, pageSize }) => {
    setPage(current), setLimit(pageSize);
  };

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);

  if (prevKeyword.current !== keyword) {
    prevKeyword.current = keyword;
  }

  return useMemo(() => {
    const queryParams = getExistProp({
      page,
      limit,
      keyword,
      cityId
    });

    return [queryParams, onTableChange];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    cityId,
    createSuccess,
    updateSuccess,
    deleteSuccess
  ]);
};

export const usePartnershipPaging = () => useSelector(pagingSelector);

export const useResetPartnership = () => {
  useResetState(resetPartnershipState);
};
