import createReport from "docx-templates";
/**
 * 
 * @param {file} template modal docs 
 * @param {*} nameFile name file save
 * @param {*} dataSource 
 * @param {*} setIsPrinting created in the code file using the onPrint component, this is the loading value
 */
export const onPrint = async ({template,nameFile,dataSource,setIsPrinting}) => {
    const downloadURL = (data, fileName) => {
      const a = document.createElement('a');
      a.href = data;
      a.download = fileName;
      document.body.appendChild(a);
      a.style = 'display: none';
      a.click();
      a.remove();
    };

    const saveDataToFile = (data, fileName, mimeType) => {
      const blob = new Blob([data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      downloadURL(url, fileName, mimeType);
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    };

    setIsPrinting(true);
    const template_ = await fetch(template).then(res => res.arrayBuffer());

    try {
      const report = await createReport({
        template:template_,
        cmdDelimiter: ['{#', '#}'],
        data: dataSource,
      });
      saveDataToFile(
        report,
        `${nameFile}.docx`,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      );

    } catch (error) {
      console.error(error);
    } finally {
      setIsPrinting(false);
    }
  };
