import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu, Row } from 'antd';
import { get } from 'lodash';
import { isValidElement, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useGetDataNavbar } from '~/hooks';
import './index.scss';

export default function RowMenu({mode = 'inline',accessKey,...props}) {
    const {list:dataNavbar} = useGetDataNavbar();
    const group = dataNavbar?.find(e => get(e,'accessKey') === accessKey);
    const WithPermissions = useCallback((component, isMatchPolicy) => isMatchPolicy ? component : null,[]);

  return (
    <Row {...props} className={`home--row ${mode}`} gutter={16} wrap={false} align='middle'>
    {/*If No children will Render One element parent*/}
      {get(group, 'children', [])?.length ?
        get(group, 'children', []).map(({isMatchPolicy,...btn}) => (
          WithPermissions(<Col  className={`${mode === 'inline' ? 'm-0 p-2' : ''} home--row__item`} >
          <Dropdown
                trigger={['hover']}
                placement="bottomLeft"
                // Submenu
                overlay={get(btn, 'children', [])?.length > 0 ?
                  <Menu className='home--row__item__dropdownMenu'>
                  {get(btn, 'children', [])?.map(({isMatchPolicy:isMatchPolicy2,...child}, index) => 
                WithPermissions(  <Menu.Item className='home--row__item__dropdownItem' key={index}>
                    <Link to={child.path}>
                      <span className='Content-homepage__title'>
                      {`${child.title}`}
                      </span>
                    </Link>
                  </Menu.Item>,isMatchPolicy2)
                  )}

                </Menu>
              
                :
                <div></div>
                }
              >
              {/* MenuItem */}
              <Link to={btn.path}>
              <Row 
              // onClick={handleClickMenu} 
              className='Content-homepage' gutter={8}> 
                  <Col ><span className='Content-homepage__title'>
                  {isValidElement(btn.icon) ? btn.icon : null}
                  </span></Col>
                  <Col ><span className='Content-homepage__title'>
                  {btn.title}
                  </span></Col>
                {get(btn, 'children', [])?.length > 0 &&  <Col ><span className='Content-homepage__title'>
                  <DownOutlined />
                  </span></Col>}
                </Row>
              </Link>
              </Dropdown>
            </Col>,isMatchPolicy)
        ))
        :  <Link to={get(group,'path')}>
              <Row 
              style={{marginLeft : 0}}
              // onClick={handleClickMenu} 
              className='Content-homepage' gutter={8}> 
                  <Col ><span className='Content-homepage__title'>
                  {isValidElement(get(group,'icon')) ? get(group,'icon') : null}
                  </span></Col>
                  <Col ><span className='Content-homepage__title'>
                  {get(group,'titleGroup')}
                  </span></Col>
                </Row>
              </Link>
      }
    </Row>
  )
}
