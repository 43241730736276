export const GET_CONFIGWHPARTNERREGISTERTYPEFORMS_REQUEST =
    'GET_CONFIGWHPARTNERREGISTERTYPEFORMS_REQUEST';
export const GET_CONFIGWHPARTNERREGISTERTYPEFORMS_SUCCESS =
    'GET_CONFIGWHPARTNERREGISTERTYPEFORMS_SUCCESS';
export const GET_CONFIGWHPARTNERREGISTERTYPEFORMS_FAILED = 'GET_CONFIGWHPARTNERREGISTERTYPEFORMS_FAILED';

export const GET_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST = 'GET_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST';
export const GET_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS = 'GET_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS';
export const GET_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED = 'GET_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED';

export const CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST =
    'CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST';
export const CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS =
    'CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS';
export const CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED =
    'CREATE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED';

export const UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST =
    'UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST';
export const UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS =
    'UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS';
export const UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED =
    'UPDATE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED';


export const DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST =
    'DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_REQUEST';
export const DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS =
    'DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_SUCCESS';
export const DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED =
    'DELETE_CONFIGWHPARTNERREGISTERTYPEFORM_FAILED';


export const RESET_CONFIGWHPARTNERREGISTERTYPEFORM_STATE = 'RESET_CONFIGWHPARTNERREGISTERTYPEFORM_STATE';
export const RESET_CONFIGWHPARTNERREGISTERTYPEFORM_STATE_ACTION = 'RESET_CONFIGWHPARTNERREGISTERTYPEFORM_STATE_ACTION';
