import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Table,
  Tag,
  TreeSelect,
  Typography
} from 'antd';
import { BaseBorderBox, GeoTreeSelect } from '~/components/Common';
import {
  MAX_PAGINATION_LIMIT,
  RELATIVE_POSITION,
  ROOT_COMPANYID
} from '~/constants/defaultValue';
import { get, head, set } from 'lodash';
import { useUser, useWhCategories } from '~/hooks';
import api from '~/api';
import Search from 'antd/lib/input/Search';
import { removeAccents, StringToSlug } from '~/utils/helper';
import { DeleteOutlined } from '@ant-design/icons';

const RegisterServiceSelectV2 = ({
  dataSource,
  onCancel,
  branches,
  updateWhService,
  createWhService
}) => {
  const [form] = Form.useForm();
  const [branchIdLocal, token] = useUser();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const [filterDataSelected, setFilterDataSelected] = useState(null);
  const [areaAvailble, setareaAvailble] = useState([]);
  const [loading, setLoading] = useState(false);

  const [branchId, setBranchId] = useState(null);
  const [whCategoryId, setWhCategoryId] = useState(null);
  const [services, setServices] = useState([]);
  const [servicesFiltered, setServicesFiltered] = useState([]);
  const queryGetCategories = useMemo(
    () => ({ page: 1, limit: MAX_PAGINATION_LIMIT }),
    []
  );

  const [categories, isLoadingCategory] = useWhCategories(queryGetCategories);
  const optionsCategory = useMemo(
    () =>
      categories
        ?.filter((item) => get(item, 'status') === 'ACTIVE')
        ?.map((item) => ({
          label: get(item, 'name.vi'),
          value: get(item, '_id')
        })),
    [categories]
  );

  const listBranch = useMemo(() => {
    const res = branches?.filter((item) => {
    return dataSource && dataSource?.branchId === ROOT_COMPANYID ? branches :  get(item, '_id') !== ROOT_COMPANYID
    });
    return res?.map((item) => ({
      label: get(item, 'name'),
      value: get(item, '_id')
    }));
  }, [branches]);
  useEffect(() => {
    if (categories && !dataSource) {
      setWhCategoryId(head(categories)?._id);
      form.setFieldsValue({ whCategoryId: head(categories)?._id });
    }
  }, [categories, dataSource]);


  useEffect(() => {
    setBranchId(branchIdLocal);
    form.setFieldsValue({ branchId: Number(branchIdLocal) });
  }, [branchIdLocal]);

  useEffect(() => {
    const fetchService = async () => {
      try {
        setLoading(true);
        const res = await api.whService.getWhServiceAvailableByBranchV2({
          status: 'ACTIVE',
          branchId: parseInt(branchId),
          listCategory: [whCategoryId]
        });
        setServices(res);
        setServicesFiltered(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    whCategoryId && fetchService();
  }, [branchId, whCategoryId]);
console.log(dataSource,'dataSource')
  useEffect(() => {
    if (dataSource) {
      form.setFieldsValue({
        branchId: dataSource?.branchId,
        whCategoryId: dataSource?.service?.whCategoryId,
        areaAvailble: dataSource?.areaAvailble
      });
      setSelectedRowKeys([dataSource?.serviceId]);
      setBranchId(dataSource?.branchId);
      setDataSelected([dataSource?.service]);
      setFilterDataSelected([dataSource?.service]);
      setWhCategoryId(dataSource?.service?.whCategoryId);
    }
  }, [dataSource]);
  const onSelectChange = (newSelectedRowKeys, e) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (e?.length) {
      setDataSelected([...e]);
      setFilterDataSelected([...e]);
    } else {
      setDataSelected([]);
      setFilterDataSelected([]);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  const SearchTable = (e, options, callback) => {
    const searchText = e.target.value;
    const data = options?.filter((option) => {
      return (
        StringToSlug(get(option, 'code', '')?.toLowerCase())?.includes(
          StringToSlug(searchText?.trim()?.toLowerCase())
        ) ||
        StringToSlug(get(option?.name, 'vi', '')?.toLowerCase())?.includes(
          StringToSlug(searchText?.trim()?.toLowerCase())
        )
      );
    });
    typeof callback === 'function' && callback(data);
  };

  const onRemove = (type, id) => {
    if (type === 'one') {
      setFilterDataSelected((pre) => pre?.filter((item) => item?._id !== id));
      setDataSelected((pre) => pre?.filter((item) => item?._id !== id));
      setSelectedRowKeys((pre) => pre?.filter((item) => item !== id));
    }
    if (type === 'all') {
      setDataSelected([]);
      setSelectedRowKeys([]);
    }
  };

  const onFinish = (values) => {
    form.validateFields();
    try {
      const { areaAvailble, branchId } = values;
      const newAddress = areaAvailble?.map((item) => {
        const newItem = {
          fullAddress: item.label,
          path: item.value
        };
        return newItem;
      });
      const dataSubmit = selectedRowKeys?.map((item) => {
        return {
          branchId: String(branchId),
          areaAvailble: newAddress ?? [],
          serviceId: item
        };
      });
      if (dataSource) {
        updateWhService({
          areaAvailble: newAddress,
          id: dataSource?.serviceId
        });
      } else {
        createWhService({ data: dataSubmit });
      }
    } catch (error) {}
    console.log(values);
  };
  const onValuesChange = (values) => {
    if (values?.branchId) {
      setBranchId(values?.branchId);
      setWhCategoryId(head(categories)?._id);
      setDataSelected([]);
      setSelectedRowKeys([]);
      setFilterDataSelected([]);
    }
    if (values?.whCategoryId) {
      setWhCategoryId(values?.whCategoryId);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: 'Mã dịch vụ',
        dataIndex: 'code',
        key: 'code',
        width: 100,
        render: (item) => <Typography.Text strong>{item}</Typography.Text>
      },
      {
        title: 'Tên dịch vụ',
        dataIndex: 'name',
        key: 'name',
        render: (item) => get(item, 'vi')
      }
    ],
    []
  );

  const addColumns = [
    {
      title: (
        <Tag
          onClick={() => onRemove('all')}
          style={{ cursor: 'pointer' }}
          color="red"
        >
          Gỡ
        </Tag>
      ),
      dataIndex: '_id',
      key: '_id',
      width: 100,
      render: (value) => (
        <DeleteOutlined
          onClick={() => onRemove('one', value)}
          style={{ cursor: 'pointer', color: 'red' }}
        />
      )
    }
  ];

  return (
    <>
      <Form
        name="whServiceBranch"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        form={form}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Chi nhánh được đăng ký"
              name="branchId"
              labelAlign="left"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn chi nhánh!'
                }
              ]}
            >
              <Select options={listBranch} disabled = {!!dataSource} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Khu vực hoạt động"
              name="areaAvailble"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn khu vực!'
                }
              ]}
            >
              <GeoTreeSelect
                className="register-service"
                style={{ maxWidth: '100%', height: '32p' }}
                autoClearSearchValue
                checkablePositions={[RELATIVE_POSITION.IS_CHILD]}
                labelInValue={true}
                listItemHeight={200}
                multiple={true}
                showCheckedStrategy={TreeSelect.SHOW_ALL}
                showEnabledValuesOnly={true}
                showSearch={true}
                onSelect={(e) => console.log(e)}
                size="large"
                treeCheckStrictly={true}
                treeCheckable={true}
                treeDefaultExpandedKeys={['1', '2', '3']}
                value={[...areaAvailble]}
                onChange={(e) => {
                  setareaAvailble(e);
                }}
                onClear={() => setareaAvailble([])}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Chọn nhóm dịch vụ"
              name="whCategoryId"
              labelAlign="left"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <Select
                disabled={!!dataSource}
                options={optionsCategory}
                loading={isLoadingCategory}
                defaultValue={whCategoryId}
              />
            </Form.Item>
          </Col>
        </Row>
        {dataSource && (
          <Row
            style={{ opacity: 0.7, color: 'red', marginBottom: '10px' }}
            justify='center'
          >
            Dịch vụ đã đăng kí hoạt động chỉ được cập nhật khu
            vực hoạt động
          </Row>
        )}
        <Row gutter={24}>
          <Col span={12}>
            <BaseBorderBox title={'Dịch vụ sẵn sàng'}>
              <Search
                placeholder="Tìm kiếm dịch vụ sẵn có"
                onChange={(e) => {
                  SearchTable(e, services, setServicesFiltered);
                }}
                style={{
                  width: '100%'
                }}
                allowClear
                disabled={!!dataSource}
              />
              <Table
                loading={loading}
                rowKey={(row) => row._id}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={servicesFiltered}
                scroll={{ x: 800 }}
                size="small"
                pagination={{
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
                className={`${
                  !!dataSource ? 'register-service__disable-row' : ''
                }`}
              />
            </BaseBorderBox>
          </Col>
          <Col span={12}>
            <BaseBorderBox title={'Dịch vụ đã chọn'}>
              <Search
                placeholder="Tìm kiếm dịch vụ đã chọn"
                onChange={(e) => {
                  SearchTable(e, dataSelected, setFilterDataSelected);
                }}
                style={{
                  width: '100%'
                }}
                allowClear
                disabled={!!dataSource}
              />
              <Table
                className={`${
                  !!dataSource ? 'register-service__disable-row' : ''
                }`}
                rowKey={(row) => row._id}
                columns={[...addColumns, ...columns]}
                dataSource={filterDataSelected}
                scroll={{ x: 800 }}
                size="small"
                pagination={{
                  showTotal: (total) => `Tổng cộng: ${total} `
                }}
              />
            </BaseBorderBox>
          </Col>
        </Row>
        <Row className="mt-2" justify="center" align="middle">
          <Button type="primary" htmlType="submit">
            {dataSource ? 'Cập nhật' : 'Đăng ký'}
          </Button>
        </Row>
      </Form>
    </>
  );
};
export default RegisterServiceSelectV2;
