import * as Types from '../../constants/actionTypes';
  
  export const getApplyJobs = query => ({
    type: Types.GET_APPLY_JOBS_REQUEST,
    payload: query
  });
  export const getApplyJob = id => ({
    type: Types.GET_APPLY_JOB_REQUEST,
    payload: id
  });
  export const updateApplyJob = instance => ({
    type: Types.UPDATE_APPLY_JOB_REQUEST,
    payload: instance
  });
  export const deleteApplyJob = id => ({
    type: Types.DELETE_APPLY_JOB_REQUEST,
    payload: id
  });
  export const resetApplyJobState = () => ({
    type: Types.RESET_APPLY_JOB_STATE
  });
