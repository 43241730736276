import requester from './requester';

const RankingItem = {
  getById: (id) => requester.get(`/ranking/${id}`),
  create: (config) => requester.post(`/ranking`, config),
  update:(config) => requester.put(`/ranking/${config._id}`,config),
  getAll : (query) => requester.get(`/ranking`,query),
  delete: (id) => requester.delete(`/ranking/${id}`),
  
};

export default RankingItem;
