import * as Types from '../../constants/actionTypes';

export const getCustomersType = query => {
  return ({
    type: Types.GET_CUSTOMERS_TYPE_REQUEST,
    payload : query
  })
  
};

export const getCustomerType = id => ({
  type: Types.GET_CUSTOMER_TYPE_REQUEST,
  payload: id
});

export const createCustomerType = id => ({
  type: Types.CREATE_CUSTOMER_TYPE_REQUEST,
  payload: id
});

export const updateCustomerType = id => ({
  type: Types.UPDATE_CUSTOMER_TYPE_REQUEST,
  payload: id
});

export const deleteCustomerType = id => ({
  type: Types.DELETE_CUSTOMER_TYPE_REQUEST,
  payload: id
});

export const resetCustomerTypeState = () => ({
  type: Types.RESET_CUSTOMER_TYPE_STATE
});
