import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getBonusIntroCustomers({ payload: query }) {
    try {
      const response = yield call(Api.bonusIntroCustomer.getAll, query);
      yield put({ type: Types.GET_BONUS_INTRO_CUSTOMERS_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_BONUS_INTRO_CUSTOMERS_FAILED, payload: error });
    }
  }
function* getBonusIntroCustomer({ payload: id }) {
    try {
      const response = yield call(Api.bonusIntroCustomer.getById, id);
      yield put({ type: Types.GET_BONUS_INTRO_CUSTOMER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_BONUS_INTRO_CUSTOMER_FAILED, payload: error });
    }
  }
  function* createBonusIntroCustomer(action) {
    try {
      const data = yield call(Api.bonusIntroCustomer.create, action.payload);
      yield put({ type: Types.CREATE_BONUS_INTRO_CUSTOMER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_BONUS_INTRO_CUSTOMER_FAILED, payload: error.message });
    }
  }
  function* updateBonusIntroCustomer(action) {
    try {
      const data = yield call(Api.bonusIntroCustomer.update, action.payload);
      yield put({ type: Types.UPDATE_BONUS_INTRO_CUSTOMER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_BONUS_INTRO_CUSTOMER_FAILED, payload: error.message });
    }
  }
  function* deleteBonusIntroCustomer(action) {
    try {
      const data = yield call(Api.bonusIntroCustomer.delete, action.payload);
      yield put({ type: Types.DELETE_BONUS_INTRO_CUSTOMER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_BONUS_INTRO_CUSTOMER_FAILED, payload: error.message });
    }
  }
export default function* BonusIntroCustomers() {
    yield takeLatest(Types.GET_BONUS_INTRO_CUSTOMERS_REQUEST, getBonusIntroCustomers);
    yield takeLatest(Types.GET_BONUS_INTRO_CUSTOMER_REQUEST, getBonusIntroCustomer);
    yield takeLatest(Types.CREATE_BONUS_INTRO_CUSTOMER_REQUEST, createBonusIntroCustomer);
    yield takeLatest(Types.UPDATE_BONUS_INTRO_CUSTOMER_REQUEST, updateBonusIntroCustomer);
    yield takeLatest(Types.DELETE_BONUS_INTRO_CUSTOMER_REQUEST, deleteBonusIntroCustomer);
  }
