import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Popconfirm, Row } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { v4 } from 'uuid';
import { ORDER_QUOTATION_STATUS } from '~/constants/defaultValue';
import POLICIES from '~/constants/policy';
import { useMatchPolicy } from '~/hooks';
import { WithPermission } from '../Common';

 function MenuAction({updateAction,convertAction,record,copyOrder,setIsModalOpen,setIdDelete}) {
  const permissionWrite = useMatchPolicy(POLICIES.WRITE_WHBILL)
  const disableConvert = record?.state === ORDER_QUOTATION_STATUS.CONVERTED || record?.state === ORDER_QUOTATION_STATUS.CANCELLED || !permissionWrite;
  const disableDelete = record.state.includes(ORDER_QUOTATION_STATUS.CREATE) || record.state.includes(ORDER_QUOTATION_STATUS.PROCESSING)
      const menu = <Menu>
    <WithPermission permission={POLICIES.UPDATE_QUOTATIONS}>
    <Menu.Item disabled={record?.state === ORDER_QUOTATION_STATUS.CONVERTED} key={v4()} onClick={updateAction}>
        <p>Cập nhật</p>
    </Menu.Item>
    </WithPermission>
   {/* <WithPermission permission={POLICIES.WRITE_WHBILL}> */}
    <Menu.Item disabled={disableConvert} key={v4()} onClick={convertAction}>
        <p>Chuyển đổi</p>
    </Menu.Item>
    {/* </WithPermission> */}
    <WithPermission permission={POLICIES.DELETE_QUOTATIONS}>
    <Menu.Item disabled={disableDelete} key={v4()} onClick={() => {
      setIdDelete(record.id)
      setIsModalOpen(true)}}>
        <p>Xoá</p>
    </Menu.Item>
    </WithPermission>
    <WithPermission permission={POLICIES.WRITE_QUOTATIONS}>
      
    <Menu.Item key={v4()} onClick={copyOrder}>
        <p>Sao chép</p>
    </Menu.Item>
    </WithPermission>
   
</Menu>
  return (
    <>
<Dropdown overlayStyle={{width : '140px'}} trigger={['click']} overlay={menu}>

    <a className="ant-dropdown-link" href="#">
      Action <CaretDownOutlined />
    </a>
</Dropdown>
    </>

  )
}
export default MenuAction
