import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getWhCustomersImport({ payload: query }) {
  try {
    const data = yield call(Api.whCustomerImport.getAll,query );
    yield put({ type: Types.GET_WH_CUSTOMERS_IMPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_WH_CUSTOMERS_IMPORT_FAILED,
      payload: error.message
    });
  }
}

function* getWhCustomerImport({ payload: id }) {
  try {
    const whCustomerImport = yield call(Api.whCustomerImport.getById, id);
    yield put({ type: Types.GET_WH_CUSTOMER_IMPORT_SUCCESS, payload: whCustomerImport });
  } catch (error) {
    yield put({ type: Types.GET_WH_CUSTOMER_IMPORT_FAILED, payload: error.message });
  }
}

function* createWhCustomerImport(action) {
  try {
    const data = yield call(Api.whCustomerImport.create, action.payload);
    yield put({ type: Types.CREATE_WH_CUSTOMER_IMPORT_SUCCESS, payload: data.data });
  } catch (error) {
    yield put({
      type: Types.CREATE_WH_CUSTOMER_IMPORT_FAILED,
      payload: error.message
    });
  }
}

function* updateWhCustomerImport(action) {
  try {
    const {data} = yield call(Api.whCustomerImport.update, action.payload);
    yield put({ type: Types.UPDATE_WH_CUSTOMER_IMPORT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_WH_CUSTOMER_IMPORT_FAILED,
      payload: error.message
    });
  }
}

function* deleteWhCustomerImport({ payload }) {
  try {
    yield call(Api.whCustomerImport.delete, payload);
    yield put({ type: Types.DELETE_WH_CUSTOMER_IMPORT_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_WH_CUSTOMER_IMPORT_FAILED,
      payload: error.message
    });
  }
}

function* convertWhCustomerImport(action) {
  try {
    const data = yield call(Api.whCustomerImport.convert, action.payload);
    yield put({ type: Types.CONVERT_WH_CUSTOMER_IMPORT_SUCCESS, payload :data});
  } catch (error) {
    yield put({
      type: Types.CONVERT_WH_CUSTOMER_IMPORT_FAILED,
      payload: error
    });
  }
}

export default function* whCustomerImport() {
  yield takeLatest(Types.GET_WH_CUSTOMERS_IMPORT_REQUEST, getWhCustomersImport);
  yield takeLatest(Types.GET_WH_CUSTOMER_IMPORT_REQUEST, getWhCustomerImport);
  yield takeLatest(Types.DELETE_WH_CUSTOMER_IMPORT_REQUEST, deleteWhCustomerImport);
  yield takeLatest(Types.CREATE_WH_CUSTOMER_IMPORT_REQUEST, createWhCustomerImport);
  yield takeLatest(Types.UPDATE_WH_CUSTOMER_IMPORT_REQUEST, updateWhCustomerImport);
  yield takeLatest(Types.CONVERT_WH_CUSTOMER_IMPORT_REQUEST, convertWhCustomerImport);
}
