export const GET_WH_REPORT_CUSTOMERS_REQUEST = 'GET_WH_REPORT_CUSTOMERS_REQUEST';
export const GET_WH_REPORT_CUSTOMERS_SUCCESS = 'GET_WH_REPORT_CUSTOMERS_SUCCESS';
export const GET_WH_REPORT_CUSTOMERS_FAILED = 'GET_WH_REPORT_CUSTOMERS_FAILED';

export const GET_WH_REPORT_CUSTOMER_REQUEST = 'GET_WH_REPORT_CUSTOMER_REQUEST';
export const GET_WH_REPORT_CUSTOMER_SUCCESS = 'GET_WH_REPORT_CUSTOMER_SUCCESS';
export const GET_WH_REPORT_CUSTOMER_FAILED = 'GET_WH_REPORT_CUSTOMER_FAILED';


export const RESET_WH_REPORT_CUSTOMER_STATE = 'RESET_WH_REPORT_CUSTOMER_STATE';
