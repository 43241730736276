import { put, call, takeLatest } from 'redux-saga/effects';
import { RATE_CONFIG_TYPES } from '~/constants/defaultValue';
import Api from '../../api';
import * as Types from '../../constants/actionTypes';

function* getCertificates({ payload }) {
  try {
    const data = yield call(Api.rateConfig.getAll, { ...payload, configType: RATE_CONFIG_TYPES.CERTIFICATE });
    yield put({ type: Types.GET_RATE_CONFIGS_OF_CERTIFICATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_RATE_CONFIGS_OF_CERTIFICATE_FAILED,
      payload: error.message
    });
  }
}

function* getDegrees({ payload }) {
  try {
    const data = yield call(Api.rateConfig.getAll, { ...payload, configType: RATE_CONFIG_TYPES.DEGREE });
    yield put({ type: Types.GET_RATE_CONFIGS_OF_DEGREE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_RATE_CONFIGS_OF_DEGREE_FAILED,
      payload: error.message
    });
  }
}
function* getExperiences({ payload }) {
  try {
    const data = yield call(Api.rateConfig.getAll, { ...payload, configType: RATE_CONFIG_TYPES.EXPERIENCE });
    yield put({ type: Types.GET_RATE_CONFIGS_OF_EXPERIENCE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_RATE_CONFIGS_OF_EXPERIENCE_FAILED,
      payload: error.message
    });
  }
}
function* getPositions({ payload }) {
  try {
    const data = yield call(Api.rateConfig.getAll, { ...payload, configType: RATE_CONFIG_TYPES.POSITION });
    yield put({ type: Types.GET_RATE_CONFIGS_OF_POSITION_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.GET_RATE_CONFIGS_OF_POSITION_FAILED,
      payload: error.message
    });
  }
}

function* getRateConfig({ payload: id }) {
  try {
    const rateConfig = yield call(Api.rateConfig.getById, id);
    yield put({ type: Types.GET_RATE_CONFIG_SUCCESS, payload: rateConfig });
  } catch (error) {
    yield put({ type: Types.GET_RATE_CONFIG_FAILED, payload: error.message });
  }
}

function* createRateConfig(action) {
  try {
    const data = yield call(Api.rateConfig.create, action.payload);
    yield put({ type: Types.CREATE_RATE_CONFIG_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.CREATE_RATE_CONFIG_FAILED,
      payload: error.message
    });
  }
}

function* updateRateConfig(action) {
  try {
    const data = yield call(Api.rateConfig.update, action.payload);
    yield put({ type: Types.UPDATE_RATE_CONFIG_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: Types.UPDATE_RATE_CONFIG_FAILED,
      payload: error.message
    });
  }
}

function* deleteRateConfig({ payload }) {
  try {
    yield call(Api.rateConfig.delete, payload);
    yield put({ type: Types.DELETE_RATE_CONFIG_SUCCESS, payload });
  } catch (error) {
    yield put({
      type: Types.DELETE_RATE_CONFIG_FAILED,
      payload: error.message
    });
  }
}

export default function* rateConfig() {
  yield takeLatest(Types.GET_RATE_CONFIGS_OF_CERTIFICATE_REQUEST, getCertificates);
  yield takeLatest(Types.GET_RATE_CONFIGS_OF_DEGREE_REQUEST, getDegrees);
  yield takeLatest(Types.GET_RATE_CONFIGS_OF_EXPERIENCE_REQUEST, getExperiences);
  yield takeLatest(Types.GET_RATE_CONFIGS_OF_POSITION_REQUEST, getPositions);
  yield takeLatest(Types.GET_RATE_CONFIG_REQUEST, getRateConfig);
  yield takeLatest(Types.DELETE_RATE_CONFIG_REQUEST, deleteRateConfig);
  yield takeLatest(Types.CREATE_RATE_CONFIG_REQUEST, createRateConfig);
  yield takeLatest(Types.UPDATE_RATE_CONFIG_REQUEST, updateRateConfig);
}
