import * as Types from '../../constants/actionTypes';

export const getWhCategoriesShort = (query) => ({
  type: Types.GET_WH_CATEGORIES_SHORT_REQUEST,
  payload: query
});

export const getWhCategoryShort = (id) => ({
  type: Types.GET_WH_CATEGORY_SHORT_REQUEST,
  payload: id
});

export const createWhCategoryShort = (staffGroup) => (
  {
    type: Types.CREATE_WH_CATEGORY_SHORT_REQUEST,
    payload: staffGroup
  }
);

export const updateWhCategoryShort = (staffGroup) => (
  {
    type: Types.UPDATE_WH_CATEGORY_SHORT_REQUEST,
    payload: staffGroup
  }
);

export const deleteWhCategoryShort = (id) => ({
  type: Types.DELETE_WH_CATEGORY_SHORT_REQUEST,
  payload: id
});

export const resetWhCategoryStateShort = () => ({
  type: Types.RESET_WH_CATEGORY_SHORT_STATE
});
