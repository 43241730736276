import { Button, Col, Form, Input, InputNumber, Radio, Row, Select } from 'antd'
import SkeletonInput from 'antd/lib/skeleton/Input'
import { get, keys } from 'lodash'
import React, { useEffect, useState } from 'react'
import { TYPE_OF_MONEY_REWARD_ITEM_VI, TYPE_REWARD_ITEM, TYPE_OF_POINT_REWARD_ITEM_VI, TYPE_REWARD_ITEM_REFERRAL, TYPE_REWARD_ITEM_REFERRAL_VI } from '~/constants/defaultValue'
import { useCreateRewardItem, useResetRewardItemAction, useRewardItem, useUpdateRewardItem } from '~/hooks/rewardItem'
import { formatNumberThreeComma, useOptionsSelectAntd } from '~/hooks/utils'

export default function RewardItemForm({ id, handleCloseModal }) {
  const options = useOptionsSelectAntd(TYPE_REWARD_ITEM_REFERRAL_VI)
  const [form] = Form.useForm()
  const [types, setTypes] = useState('point')
  const callBackFinish = () => {
    handleCloseModal()
    form.resetFields()
  }
  // HOOK
  const [submitLoading, createRewardItem] = useCreateRewardItem(callBackFinish)
  const [, updateRewardItem] = useUpdateRewardItem(callBackFinish)
  const [RewardItem, loading] = useRewardItem(id)
  useResetRewardItemAction()
  //
  const onFinish = (values) => {
    const { value, typesOfPoint ,typesOfMoney} = values
    const submitData = {
      [types]: value,
      types,
      ...form.getFieldValue(['types']) === TYPE_REWARD_ITEM_REFERRAL.point && { typesOfPoint },
      ...form.getFieldValue(['types']) === TYPE_REWARD_ITEM_REFERRAL.money && { typesOfMoney },
    }
    if (id) {
      updateRewardItem({ ...submitData, _id: get(RewardItem, '_id') })
    }
    else {
      createRewardItem(submitData)
    }
  }

  useEffect(() => {
    form.resetFields()
    if (id && RewardItem) {
      const { types, typesOfPoint,typesOfMoney } = RewardItem
      form.setFieldsValue({
        types,
        value: get(RewardItem, 'point') ?? get(RewardItem, 'item') ?? get(RewardItem, 'money'),
        typesOfPoint,
        typesOfMoney
      })
    }
  }, [RewardItem, id,form])
  return (
    <Form
      form={form}
      autoComplete="off"
      onFinish={onFinish}
      scrollToFirstError
      requiredMark={false}
      labelCol={{ sm: 24, md: 24, lg: 6 }}
      wrapperCol={{ sm: 24, md: 24, lg: 18 }}
      initialValues={{ types: 'point' }}
    >
      <Form.Item label='Loại' name='types' rules={[
        {
          required: true,
          message: 'Vui lòng nhập Chọn loại!',
        }
      ]}>
        <Select loading={loading} onChange={(value) => setTypes(value)} options={options} />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() => form.getFieldValue(['types']) === TYPE_REWARD_ITEM_REFERRAL.point  && <Form.Item rules={[
          {
            required: true,
            message: 'Vui lòng nhập chọn loại điểm!',
          }
        ]} label='Loại điểm' name='typesOfPoint'>
          <Radio.Group options={keys(TYPE_REWARD_ITEM)?.map(type => ({ value: type, label: TYPE_OF_POINT_REWARD_ITEM_VI[type] }))} />
        </Form.Item>}
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() => form.getFieldValue(['types']) === TYPE_REWARD_ITEM_REFERRAL.money  && <Form.Item rules={[
          {
            required: true,
            message: 'Vui lòng nhập chọn loại tiền!',
          }
        ]} label='Loại tiền' name='typesOfMoney'>
          <Radio.Group options={keys(TYPE_REWARD_ITEM)?.map(type => ({ value: type, label: TYPE_OF_MONEY_REWARD_ITEM_VI[type] }))} />
        </Form.Item>}
      </Form.Item>
      <Form.Item rules={[
        {
          required: true,
          message: 'Vui lòng nhập nhập giá trị!',
        }
      ]} label={types === TYPE_REWARD_ITEM_REFERRAL.point ? "Điểm" : types === TYPE_REWARD_ITEM_REFERRAL.money ? "Số tiền" : "Tên vật phẩm"} name='value'>
        {loading ? <SkeletonInput /> : types === TYPE_REWARD_ITEM_REFERRAL.item ? <Input /> : <InputNumber formatter={(value) => formatNumberThreeComma(value)} style={{ width: 'max-content' }} />}
      </Form.Item>
      <Row justify='space-around' className='groupButtonSubmit'>
        <Col span={8}>
          <Button onClick={handleCloseModal}>Huỷ</Button>
        </Col>
        <Col span={8}>
          <Button loading={submitLoading} type='primary' htmlType='submit'>{!!id ? "Cập nhật" : "Tạo mới"}</Button>
        </Col>
      </Row>
    </Form>
  )
}
