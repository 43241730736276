import {
  FileDoneOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { get } from 'lodash';
import { useGetRatingOnePartner, useUserWorkspace } from '~/hooks';
import './WorkSpaceTimeTrain.scss';
import Rating from './Rating';
import { COMPONENT_MODES } from '~/constants/defaultValue';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import AllTableFulltimeOrParttime from './WhPartner/AllTableFulltimeOrParttime';
import InfoPartner from './WhPartner/InfoPartner';
import api from '~/api';
import RatingExistData from './RatingExistData';
import { useReportKpIById } from '~/hooks/whReport';

export default function WorkspaceJobsHistory() {
  const [profile] = useUserWorkspace();
  // const [mergeRatingProfile, setMergeRatingProfile] = useState(null);
  // const [loading, setLoading] = useState(false);

  const initDate = useMemo(
    () => ({
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD')
    }),
    []
  );
  const [date, setDate] = useState(initDate);
  const [month, setMonth] = useState(moment(date).format('YYYY-MM-DD'));
  const setPreMonth = () => {
    setMonth(moment(month).subtract(1, 'months').format('YYYY-MM-DD'));
  };
  const setNextMonth = () => {
    setMonth(moment(month).add(1, 'months').format('YYYY-MM-DD'));
  };
  useEffect(() => {
    setDate({
      startDate: moment(month).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(month).endOf('month').format('YYYY-MM-DD')
    });
  }, [month]);
  const [ratingPartner, isLoadingRating] = useGetRatingOnePartner({
    id : get(profile, '_id', ''),
    ...date
  });
  // useEffect(() => {
  //   const fetchRating = async () => {
  //     setLoading(true);
  //     const res = await api.reportPartner.getAllRatingPartner(date);
  //     const findOne = res?.find(
  //       (e) => get(e, 'partnerId', '') === get(profile, '_id')
  //     );
  //     setMergeRatingProfile({ ...profile, rating: findOne });
      
  //     setLoading(false);
  //   };
  //   fetchRating();
  // }, []);
  const [dataPartnerSnapShort,loadingKPI] = useReportKpIById(get(profile, '_id'),date)
  return (
    <div className="page-wraper page-content page-content-workSpace">
      <InfoPartner profile={profile} />
      <Row justify='space-between'>
        <Col xs={24} md={12} xl={12} lg={12}>
          <Row style={{ alignItems: 'center' }}>
            <Col style={{ marginRight: '20px' }}>
              <FileDoneOutlined style={{ fontSize: '16px' }} />
            </Col>
            <Col>
              <h5 style={{ marginBottom: 0 }}>Lịch sử công việc</h5>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={8} xl={8} lg={12}>
          <Row align="end" style={{ alignItems: 'center' }}>
            <Col span={8}>
              <Button onClick={setPreMonth} icon={<LeftOutlined />}>
                Tháng trước
              </Button>
            </Col>
            <Col span={8}>
              <h6 style={{ margin: 0, textAlign: 'center' }}>
                Tháng {moment(month).format('MM-YYYY')}
              </h6>
            </Col>
            <Col span={8}>
              <Button onClick={setNextMonth} icon={<RightOutlined />}>
                Tháng sau
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Rating id={get(profile, '_id','')}/> */}
      <RatingExistData
        isPartnerHandle
        loading={isLoadingRating}
        date={date}
        partner={profile}
        data={ratingPartner}
      />
      <AllTableFulltimeOrParttime
        mode={COMPONENT_MODES.VIEW}
        date={date}
        id={get(profile, '_id')}
        type={get(profile, 'wayWorking')}
        filterDateBy='month'
        partner={profile}
        dataPartnerSnapShort={dataPartnerSnapShort}
      />
    </div>
  );
}
