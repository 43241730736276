import React, { useState } from 'react';
import { Table, Modal, Image, Button, Switch, Popconfirm } from 'antd';
import SkeletonTable from '~/components/Utils/SkeletonTable';
import {
  useEvaluates,
  useUpdateEvaluate,
  useEvaluateQueryParams,
  useDeleteEvaluate
} from '~/hooks';
import Breadcrumb from '~/components/Common/Breadcrumb';
import { EditFilled } from '@ant-design/icons';
import { useSpecialityOptions } from '~/hooks/speciality';
import EvaluateForm from './Form';
import { WithPermission } from '../Common';
import POLICY from '~/constants/policy';

const Evaluate = () => {
  const [query, onPagingChange] = useEvaluateQueryParams();
  const [evaluates, isLoading] = useEvaluates(query);
  const specialityOptions = useSpecialityOptions();
  const [keyword, setKeyword] = useState(query.keyword);
  const [evaluateId, setEvaluateId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitLoading, updateEvaluate] = useUpdateEvaluate();
  const [, deleteEvaluate] = useDeleteEvaluate();
  const newsEvaluatesSort = [...evaluates]
  newsEvaluatesSort.length !== 0 &&  newsEvaluatesSort?.sort((a,b) => a.rate - b.rate);

  const ColumnActions = ({
    id,
    isSubmitLoading,
    isActive,
    updateEvaluate,
    deleteEvaluate,
    ...restProps
  }) => {
    return (
      <div className="custom-table__actions">
        <WithPermission permission={POLICY.UPDATE_WHCUSTOMEREVALUATION}>
          <EditFilled
            onClick={() => {
              setIsOpen(true);
              setEvaluateId(id);
            }}
            style={{ color: '#1f89e5' }}
          />
        </WithPermission>
        <WithPermission permission={POLICY.UPDATE_WHCUSTOMEREVALUATION}>
          <WithPermission permission={POLICY.DELETE_WHCUSTOMEREVALUATION}>
            <p style={{ marginLeft: '10px' }}>|</p>
          </WithPermission>
        </WithPermission>
        <WithPermission permission={POLICY.DELETE_WHCUSTOMEREVALUATION}>
          <Popconfirm
            title="Bạn muốn xoá đánh giá này?"
            onConfirm={() => deleteEvaluate(id)}
            okText="Xoá"
            cancelText="Huỷ"
          >
            <p>Xóa</p>
          </Popconfirm>{' '}
        </WithPermission>
      </div>
    );
  };
  const columns = [
    {
      title: 'STT',
      // dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => {
        return (+query.page - 1) * query.limit + index + 1;
      }
    },

    {
      title: 'Đánh giá',
      dataIndex: 'iconLink',
      key: 'iconLink',
      render: (record) => {
        return (
          <>
            <Image src={record} style={{ width: 50, height: 50 }} />
          </>
        );
      }
    },

    {
      title: 'Hệ số',
      dataIndex: 'rate',
      key: 'rate',
      render: (record) => {
        return record;
      }
    },
    {
      title: 'Thao tác',
      // dataIndex: 'action',
      key: 'action',
      width: '110px',
      render: (record) => {
        record = {
          ...record,
          id: record.id
        };
        return (
          <ColumnActions
            {...record}
            updateEvaluate={updateEvaluate}
            isSubmitLoading={isSubmitLoading}
            deleteEvaluate={deleteEvaluate}
          />
        );
      }
    }
  ];

  return (
    <div className="page-wraper page-content">
      <div className="container-fluid">
        <Breadcrumb title="Bảng quy đổi điểm khách hàng đánh giá" />
        <div className="page-content__main" style={{ display: 'block' }}>
          <div className="page-wraper__header">
            <WithPermission permission={POLICY.WRITE_WHCUSTOMEREVALUATION}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                onClick={() => {
                  setIsOpen(true);
                  setEvaluateId(null);
                }}
              >
                {' '}
                Thêm mới
              </Button>
            </WithPermission>
          </div>
          {isLoading ? (
            <SkeletonTable rowCount={evaluates.length} columns={columns} />
          ) : (
            <Table
              rowKey={(rc) => rc._id || rc.id}
              columns={columns}
              dataSource={newsEvaluatesSort || []}
              onChange={onPagingChange}
              size="middle"
              pagination={{
                // ...paging,
                // total: evaluates.length,
                showTotal: (total) => `Tổng cộng: ${total} `
              }}
            />
          )}
        </div>
      </div>
      <Modal
        width={1000}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
        // destroyOnClose
      >
        <EvaluateForm
          specialityOptions={specialityOptions}
          evaluateId={evaluateId}
          onClose={() => setIsOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default Evaluate;
