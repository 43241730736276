import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import toastr from 'toastr';
import { Row, Button, Form, Modal, Select, Divider } from 'antd';

import './wh-order-temp.scss';
import LoadingWhBill from './LoadingWhBill';
import ConvertQuotationStepTwo from './ConvertQuotationStepTwo';
import ModalRegisterUserTemp from './ModalRegisterUserTemp';
import api from '~/api';
import ConvertQuotationStepThree from './ConvertQuotationStepThree';
import { useDispatch, useSelector } from 'react-redux';
import { addUserSelect } from '~/redux/action';
import { useQuotation } from '~/hooks/quotations';
import { debounce, get } from 'lodash';
import { ToastrCustom } from '~/hooks/utils';

const WhBillOrderTemp = (
  {
    isModalConfirmInfomationOpen,
    setIsModalConfirmInfomationOpen,
    isSubmitLoadingSelector,
    convertQuotation,
    quotation,
    idSelect,
    setIdSelect
  },
  ref
) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { fullName, phoneNumber } = quotation;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const userSelects = useSelector((state) => state.quotation.userSelect);
  const closeModal = () => {
    setStep(1);
    setIsModalConfirmInfomationOpen(false);
    setIdSelect(null)
    dispatch(addUserSelect(null));
  };
  useImperativeHandle(ref, () => ({
    closeModal
  }));
  const [step, setStep] = useState(1);

  const [nextStep, setNextStep] = useState(false);
  const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false);
  const [listOptions, setListOptions] = useState({
    listOptionsName: [],
    listOptionsPhoneNumber: []
  });
  const [selectInfo, setSelectInfo] = useState({
    selectName: null,
    selectPhone: null
  });
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState({});

  const onFinish = () => {
    setStep(2);
  };

  const handleChangeName = (value) => {
    setSelectInfo({ ...selectInfo, selectName: value });
    form.setFieldsValue({ name: value, phone: value });
    dispatch(addUserSelect(JSON.parse(value)));
    setNextStep(true);
  };
  const handleChangePhone = (value) => {
    form.setFieldsValue({ name: value, phone: value });
    setSelectInfo({ ...selectInfo, selectPhone: value });
    dispatch(addUserSelect(JSON.parse(value)));
    setNextStep(true);
  };
  const fetch = useCallback(async () => {
    if (quotation) {
      setLoading(true);
      const res = await api.userEmployee.getUserByNameorPhone({
        phoneNumber: (quotation?.phoneNumber).substring(1) || ''
      });
      if (res.data.length !== 0) {
        setAddress(get(res,'data.[0].address'))
        let userFinded = get(res,'data.[0]')
        ToastrCustom('Đã tìm thấy người dùng ' + get(userFinded,'fullName',''))
        dispatch(addUserSelect(userFinded));
        setSelectInfo({ selectName: userFinded, selectPhone: userFinded });
        form.setFieldsValue({
          name: JSON.stringify(userFinded),
          phone: JSON.stringify(userFinded)
        });
        setNextStep(true);
        const newOptionsName = res.data?.map((e) => ({
          value: JSON.stringify(e),
          label: e.fullName
        }));
        const newOptionsPhone = res.data?.map((e) => ({
          value: JSON.stringify(e),
          label: e.phoneNumber.replace('+84', '0')
        }));
        setListOptions({
          listOptionsName: newOptionsName,
          listOptionsPhoneNumber: newOptionsPhone
        });
      } else {
        dispatch(addUserSelect(null));
        toastr.error('Không tìm thấy user');
        setNextStep(false);
        setSelectInfo({ selectName: fullName, selectPhone: phoneNumber });
        const newOptionsName = [{ value: fullName, label: fullName }];
        const newOptionsPhone = [
          { value: phoneNumber, label: phoneNumber.replace('+84', '0') }
        ];
        setListOptions({
          listOptionsName: newOptionsName,
          listOptionsPhoneNumber: newOptionsPhone
        });
        form.setFieldsValue({ name: fullName, phone: phoneNumber });
      }

      setLoading(false);
    }
  }, [idSelect,dispatch,quotation]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  useEffect(() => {
    if (userSelects) {
      const newOptionsName = [
        { value: JSON.stringify(userSelects), label: userSelects.fullName }
      ];
      const newOptionsPhone = [
        {
          value: JSON.stringify(userSelects),
          label: userSelects.phoneNumber.replace('+84', '0')
        }
      ];
      setListOptions({
        listOptionsName: newOptionsName,
        listOptionsPhoneNumber: newOptionsPhone
      });
      form.setFieldsValue({
        name: JSON.stringify(userSelects),
        phone: JSON.stringify(userSelects)
      });
      setNextStep(true);
    } else {
      const newOptionsName = [{ value: fullName, label: fullName }];
      const newOptionsPhone = [
        { value: phoneNumber, label: phoneNumber.replace('+84', '0') }
      ];
      setListOptions({
        listOptionsName: newOptionsName,
        listOptionsPhoneNumber: newOptionsPhone
      });
      form.setFieldsValue({ name: fullName, phone: phoneNumber });
      setNextStep(false);
    }
  }, [userSelects]);
  const onchangeName = debounce(async (value) => {
    setLoadingSearch(true);
    const res = await api.userEmployee.getUserByNameorPhone({
      fullName: value
    });
    const newOptionsName = res.data?.map((e) => ({
      value: JSON.stringify(e),
      label: e.fullName
    }));
    const newOptionsPhone = res.data?.map((e) => ({
      value: JSON.stringify(e),
      label: e.phoneNumber.replace('+84', '0')
    }));
    setListOptions({
      listOptionsName: newOptionsName,
      listOptionsPhoneNumber: newOptionsPhone
    });
    setLoadingSearch(false);
  }, 500);
  const onchangePhone = debounce(async (value) => {
    setLoadingSearch(true);
    let formatPhone = value; // Example formatPhoneing with multiple '0's
    formatPhone = formatPhone.replace(/^0/, '84'); // Use a regular expression to replace the first '0'
    const res = await api.userEmployee.getUserByNameorPhone({
      phoneNumber: formatPhone
    });
    const newOptionsName = res.data?.map((e) => ({
      value: JSON.stringify(e),
      label: e.fullName
    }));
    const newOptionsPhone = res.data?.map((e) => ({
      value: JSON.stringify(e),
      label: e.phoneNumber.replace('+84', '0')
    }));
    setListOptions({
      listOptionsName: newOptionsName,
      listOptionsPhoneNumber: newOptionsPhone
    });
    setLoadingSearch(false);
  }, 500);
  return (
    <Modal
      width={1000}
      onCancel={closeModal}
      className="WhModal-modal"
      visible={isModalConfirmInfomationOpen}
      footer={null}
      // destroyOnClose
    >
      <div
        className="page-wraper wh-bills-processing-page"
        style={{ padding: 0, margin: 0 }}
      >
        <LoadingWhBill open={loading} />
        {step === 1 && (
          <div className="container-fluid">
            <div className="page-content__main">
              <div className="wh-orderTemp-Box">
                <h4>Tìm kiếm khách hàng</h4>
                <Form
                  className="wh-orderTemp-Form"
                  form={form}
                  onFinish={onFinish}
                  name="basic"
                  initialValues={{
                    name: selectInfo.selectName,
                    phone: selectInfo.selectPhone
                  }}
                  labelCol={{
                    span: 8
                  }}
                  wrapperCol={{
                    span: 16
                  }}
                  style={{
                    maxWidth: 1000
                  }}
                  autoComplete="off"
                >
                  <Row justify="space-between" style={{ width: '100%' }}>
                    <Form.Item
                      style={{ width: '45%' }}
                      label="Họ và tên"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập tên!'
                        }
                      ]}
                    >
                      <Select
                        onSearch={onchangeName}
                        onChange={handleChangeName}
                        showSearch
                        loading={loadingSearch}
                        value={selectInfo.selectName}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={listOptions.listOptionsName}
                      />
                    </Form.Item>
                    <Divider style={{ height: '1.8rem' }} type="vertical" />
                    <Form.Item
                      style={{ width: '45%' }}
                      label="Số điện thoại"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập số điện thoại!'
                        }
                      ]}
                    >
                      <Select
                        onSearch={onchangePhone}
                        onChange={handleChangePhone}
                        showSearch
                        loading={loadingSearch}
                        value={selectInfo.selectPhone}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={listOptions.listOptionsPhoneNumber}
                      />
                    </Form.Item>
                  </Row>
                  <Button disabled={!nextStep} htmlType="submit" type="primary">
                    Tiếp theo
                  </Button>
                </Form>
                <Divider />
                <Button
                  onClick={() => setIsModalRegisterOpen(true)}
                  type="primary"
                  disabled={!!userSelects}
                >
                  Đăng kí tài khoản mới
                </Button>
                <ModalRegisterUserTemp
                  quotation={quotation}
                  setStep={setStep}
                  isModalRegisterOpen={isModalRegisterOpen}
                  setIsModalRegisterOpen={setIsModalRegisterOpen}
                />
              </div>
            </div>
          </div>
        )}
        {step === 2 && <ConvertQuotationStepTwo setStep={setStep}  address = {address} />}
        {step === 3 && (
          <ConvertQuotationStepThree
            quotation={quotation}
            isSubmitLoadingSelector={isSubmitLoadingSelector}
            convertQuotation={convertQuotation}
            closeModal={closeModal}
          
          />
        )}
      </div>
    </Modal>
  );
};

export default forwardRef(WhBillOrderTemp);
