export const GET_QUOTATIONS_REQUEST = 'GET_QUOTATIONS_REQUEST';
export const GET_QUOTATIONS_SUCCESS = 'GET_QUOTATIONS_SUCCESS';
export const GET_QUOTATIONS_FAILED = 'GET_QUOTATIONS_FAILED';

export const DELETE_QUOTATION_REQUEST = 'DELETE_QUOTATION_REQUEST';
export const DELETE_QUOTATION_SUCCESS = 'DELETE_QUOTATION_SUCCESS';
export const DELETE_QUOTATION_FAILED = 'DELETE_QUOTATION_FAILED';

export const CONVERT_QUOTATION_REQUEST = 'CONVERT_QUOTATION_REQUEST';
export const CONVERT_QUOTATION_SUCCESS = 'CONVERT_QUOTATION_SUCCESS';
export const CONVERT_QUOTATION_FAILED = 'CONVERT_QUOTATION_FAILED';

export const CREATE_QUOTATION_REQUEST = 'CREATE_QUOTATION_REQUEST';
export const CREATE_QUOTATION_SUCCESS = 'CREATE_QUOTATION_SUCCESS';
export const CREATE_QUOTATION_FAILED = 'CREATE_QUOTATION_FAILED';

export const UPDATE_QUOTATION_REQUEST = 'UPDATE_QUOTATION_REQUEST';
export const UPDATE_QUOTATION_SUCCESS = 'UPDATE_QUOTATION_SUCCESS';
export const UPDATE_QUOTATION_FAILED = 'UPDATE_QUOTATION_FAILED';

export const CUSTOMER_CANCEL_QUOTATION_REQUEST = 'CUSTOMER_CANCEL_QUOTATION_REQUEST';
export const CUSTOMER_CANCEL_QUOTATION_SUCCESS = 'CUSTOMER_CANCEL_QUOTATION_SUCCESS';
export const CUSTOMER_CANCEL_QUOTATION_FAILED = 'CUSTOMER_CANCEL_QUOTATION_FAILED';

export const UPDATE_QUOTATION_ITEM_REQUEST = 'UPDATE_QUOTATION_ITEM_REQUEST';
export const UPDATE_QUOTATION_ITEM_SUCCESS = 'UPDATE_QUOTATION_ITEM_S;UCCESS';
export const UPDATE_QUOTATION_ITEM_FAILED = 'UPDATE_QUOTATION_ITEM_FAILED'

export const CONFIRM_PREPAYMENT_QUOTATION_REQUEST = 'CONFIRM_PREPAYMENT_QUOTATION_REQUEST';
export const CONFIRM_PREPAYMENT_QUOTATION_SUCCESS = 'CONFIRM_PREPAYMENT_QUOTATION_SUCCESS';
export const CONFIRM_PREPAYMENT_QUOTATION_FAILED = 'CONFIRM_PREPAYMENT_QUOTATION_FAILED';

export const CANCEL_QUOTATION_REQUEST = 'CANCEL_QUOTATION_REQUEST';
export const CANCEL_QUOTATION_SUCCESS = 'CANCEL_QUOTATION_SUCCESS';
export const CANCEL_QUOTATION_FAILED = 'CANCEL_QUOTATION_FAILED';

export const GET_QUOTATION_REQUEST = 'GET_QUOTATION_REQUEST';
export const GET_QUOTATION_SUCCESS = 'GET_QUOTATION_SUCCESS';
export const GET_QUOTATION_FAILED = 'GET_QUOTATION_FAILED';

export const RESET_QUOTATION_STATE = 'RESET_QUOTATION_STATE';
export const COPY_QUOTATION_REQUEST = 'COPY_QUOTATION_REQUEST';
export const COPY_QUOTATION_SUCCESS = 'COPY_QUOTATION_SUCCESS';
export const COPY_QUOTATION_FAILED = 'COPY_QUOTATION_FAILED';
