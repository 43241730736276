import produce from 'immer';
import { get } from 'lodash';
import * as Types from '../../constants/actionTypes';

const initState = {
  isLoading: false,
  getSettingTutorialJobsFailed: null,
  SettingTutorialJobs: null,
  isSubmitLoading : false,
  isGetSettingTutorialJobLoading: false,

  updateFailed : null,
  updateSuccess : null,

};

export default produce((state, { type, payload }) => {
  switch (type) {
    case Types.GET_SETTING_TUTORIAL_JOBS_REQUEST:
      state.isLoading = true;
      state.getSettingTutorialJobsFailed = null;
      return;

    case Types.GET_SETTING_TUTORIAL_JOBS_SUCCESS:
      state.isLoading = false;
      state.SettingTutorialJobs = payload;
      state.getSettingTutorialJobsFailed = null;
      return;

    case Types.GET_SETTING_TUTORIAL_JOBS_FAILED:
      state.isLoading = false;
      state.SettingTutorialJobs = null;
      state.getSettingTutorialJobsFailed = payload;
      return;
      case Types.UPDATE_SETTING_TUTORIAL_JOB_REQUEST:
        state.isSubmitLoading = true;
        state.updateSuccess = null;
        state.updateFailed = null;
        return;
  
      case Types.UPDATE_SETTING_TUTORIAL_JOB_SUCCESS:
        state.isSubmitLoading = false;
        state.updateSuccess = payload;
        state.SettingTutorialJobs = payload;
        return;
  
      case Types.UPDATE_SETTING_TUTORIAL_JOB_FAILED:
        state.isSubmitLoading = false;
        state.updateFailed = payload;
        return;

    case Types.RESET_STORE:
    case Types.RESET_SETTING_TUTORIAL_JOB_STATE:
      return initState;

    default:
      return;
  }
}, initState);
