import {
  GET_AREAS_REQUEST,
  GET_CITIES_REQUEST,
  GET_DISTRICTS_REQUEST,
  GET_WARDS_REQUEST
} from '../../constants/actionTypes';

export const getAreas = () => ({
  type: GET_AREAS_REQUEST
});

export const getCities = () => ({
  type: GET_CITIES_REQUEST
});

export const getDistricts = cityCode => ({
  type: GET_DISTRICTS_REQUEST,
  payload: cityCode
});

export const getWards = dictricCode => ({
  type: GET_WARDS_REQUEST,
  payload: dictricCode
});
