import * as Types from '../../constants/actionTypes';
  
  export const getTrainingCoursesPartner = query => ({
    type: Types.GET_TRAINING_COURSES_PARTNER_REQUEST,
    payload: query
  });
  export const getTrainingCoursesOfPartner = query => ({
    type: Types.GET_TRAINING_COURSES_OF_PARTNER_REQUEST,
    payload: query
  });
  export const getTrainingCoursesOfPartnerReady = query => ({
    type: Types.GET_TRAINING_COURSES_OF_PARTNER_READY_REQUEST,
    payload: query
  });
  export const getTrainingCoursePartner = id => ({
    type: Types.GET_TRAINING_COURSE_PARTNER_REQUEST,
    payload: id
  });
  export const getNoteByIdPartner = id => ({
    type: Types.GET_NOTE_TIMESHEET_REQUEST,
    payload: id
  });
  export const createTrainingCoursePartner = instance => ({
    type: Types.CREATE_TRAINING_COURSE_PARTNER_REQUEST,
    payload: instance
  });
  export const submitTrainingCoursePartner = instance => ({
    type: Types.SUBMIT_TRAINING_COURSE_PARTNER_REQUEST,
    payload: instance
  });
  export const updateTrainingCoursePartner = instance => ({
    type: Types.UPDATE_TRAINING_COURSE_PARTNER_REQUEST,
    payload: instance
  });
  export const deleteTrainingCoursePartner = id => ({
    type: Types.DELETE_TRAINING_COURSE_PARTNER_REQUEST,
    payload: id
  });
  export const resetTrainingCoursePartnerState = () => ({
    type: Types.RESET_TRAINING_COURSE_PARTNER_STATE
  });