import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../api';
import * as Types from '~/constants/actionTypes';
function* getTrainingCoursesPartner({ payload: query }) {
    try {
      const response = yield call(Api.TrainingCoursePartner.getAll, query);
      yield put({ type: Types.GET_TRAINING_COURSES_PARTNER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_TRAINING_COURSES_PARTNER_FAILED, payload: error });
    }
  }
function* getTrainingCoursesPartnerReady({ payload: id }) {
    try {
      const response = yield call(Api.TrainingCoursePartner.getAllCourseReadySubmit, id);
      yield put({ type: Types.GET_TRAINING_COURSES_OF_PARTNER_READY_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_TRAINING_COURSES_OF_PARTNER_READY_FAILED, payload: error });
    }
  }
function* getTrainingCoursesOfPartner({ payload: query }) {
    try {
      const response = yield call(Api.TrainingCoursePartner.getAllTrainingCourseOfPartner, query);
      yield put({ type: Types.GET_TRAINING_COURSES_OF_PARTNER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_TRAINING_COURSES_OF_PARTNER_FAILED, payload: error });
    }
  }
function* getTrainingCoursePartner({ payload: id }) {
    try {
      const response = yield call(Api.TrainingCoursePartner.getOne, id);
      yield put({ type: Types.GET_TRAINING_COURSE_PARTNER_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_TRAINING_COURSE_PARTNER_FAILED, payload: error });
    }
  }
function* getNoteByIdPartner({ payload: id }) {
    try {
      const response = yield call(Api.TrainingCoursePartner.getNoteByIdPartner, id);
      yield put({ type: Types.GET_NOTE_TIMESHEET_SUCCESS, payload: response });
    } catch (error) {
      yield put({ type: Types.GET_NOTE_TIMESHEET_FAILED, payload: error });
    }
  }
  function* createTrainingCoursePartner(action) {
    try {
      const data = yield call(Api.TrainingCoursePartner.create, action.payload);
      yield put({ type: Types.CREATE_TRAINING_COURSE_PARTNER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.CREATE_TRAINING_COURSE_PARTNER_FAILED, payload: error });
    }
  }
  function* updateTrainingCoursePartner(action) {
    try {
      const data = yield call(Api.TrainingCoursePartner.update, action.payload);
      yield put({ type: Types.UPDATE_TRAINING_COURSE_PARTNER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.UPDATE_TRAINING_COURSE_PARTNER_FAILED, payload: error });
    }
  }
  function* deleteTrainingCoursePartner(action) {
    try {
      const data = yield call(Api.TrainingCoursePartner.delete, action.payload);
      yield put({ type: Types.DELETE_TRAINING_COURSE_PARTNER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.DELETE_TRAINING_COURSE_PARTNER_FAILED, payload: error });
    }
  }
  function* submitTrainingCoursePartner(action) {
    try {
      const data = yield call(Api.TrainingCoursePartner.submit, action.payload);
      yield put({ type: Types.SUBMIT_TRAINING_COURSE_PARTNER_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: Types.SUBMIT_TRAINING_COURSE_PARTNER_FAILED, payload: error });
    }
  }
export default function* TrainingCoursesPartner() {
    yield takeLatest(Types.GET_TRAINING_COURSES_PARTNER_REQUEST, getTrainingCoursesPartner);
    yield takeLatest(Types.GET_TRAINING_COURSES_OF_PARTNER_READY_REQUEST, getTrainingCoursesPartnerReady);
    yield takeLatest(Types.GET_NOTE_TIMESHEET_REQUEST, getNoteByIdPartner);
    yield takeLatest(Types.GET_TRAINING_COURSES_OF_PARTNER_REQUEST, getTrainingCoursesOfPartner);
    yield takeLatest(Types.GET_TRAINING_COURSE_PARTNER_REQUEST, getTrainingCoursePartner);
    yield takeLatest(Types.CREATE_TRAINING_COURSE_PARTNER_REQUEST, createTrainingCoursePartner);
    yield takeLatest(Types.UPDATE_TRAINING_COURSE_PARTNER_REQUEST, updateTrainingCoursePartner);
    yield takeLatest(Types.DELETE_TRAINING_COURSE_PARTNER_REQUEST, deleteTrainingCoursePartner);
    yield takeLatest(Types.SUBMIT_TRAINING_COURSE_PARTNER_REQUEST, submitTrainingCoursePartner);
  }