import * as Types from '../../constants/actionTypes';

export const getReportSystems = query => ({
  type: Types.GET_REPORT_SYSTEMS_REQUEST,
  payload : query
});
export const getAllSaleReportSystems= query => ({
  type: Types.GET_ALL_SALE_REPORT_SYSTEMS_REQUEST,
  payload : query
});
export const getFund = query => ({
  type: Types.GET_REPORT_SYSTEMS_FUND_REQUEST,
  payload : query
});
export const createFund = query => ({
  type: Types.CREATE_REPORT_SYSTEMS_FUND_REQUEST,
  payload : query
});
export const updateFund = query => ({
  type: Types.UPDATE_REPORT_SYSTEMS_FUND_REQUEST,
  payload : query
});


export const resetRateConfigState = () => ({
  type: Types.RESET_REPORT_SYSTEM_STATE
});
